import { makeStyles } from "@material-ui/styles";
import React from "react";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  computeWrappedCell,
  computeWrappedCellWithError,
  parseBoolean,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import _ from "lodash";
import { ReceivedWasteArrayProps, ReceivedWasteStorage } from "./types";
import {
  WASTE_CODE_DISPLAY_COL_WIDTH,
  WASTE_LIBELLE_DISPLAY_COL_WIDTH,
} from "../../utils/Constants";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  PATH_DECHET_PRODUCTION_LIST,
  PATH_DECHET_RECEPTION_LIST,
} from "common/path/path18Now";
import { displayFillingSource, formatWasteLabel } from "../../utils/formater";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const WasteReceptionArray = ({
  isBlocValidate,
  wasteContainer,
  deleteWaste,
  openModal,
  referentiels,
}: ReceivedWasteArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const computeLocationStr = (waste: ReceivedWasteStorage) => {
    if (!waste.data.departementID && !waste.data.paysID) {
      return computeWrappedCellWithError("Site", classes, undefined);
    } else if (waste.data.departementID && !waste.data.paysID) {
      const departementElement = findElementMatchingTemplate(
        { uuid: waste.data.departementID },
        referentiels.departements
      );
      return computeWrappedCellWithError(
        departementElement ? departementElement.nom : waste.data.departementID,
        classes,
        waste.errors.departementID
      );
    } else if (!waste.data.departementID && waste.data.paysID) {
      const paysElement = findElementMatchingTemplate(
        { uuid: waste.data.paysID },
        referentiels.pays
      );
      return computeWrappedCellWithError(
        <p>{paysElement ? paysElement.designation : waste.data.paysID}</p>,
        classes,
        waste.errors.paysID
      );
    } else {
      return computeWrappedCellWithError("", classes, "invalid data");
    }
  };

  const computeSinglePolluantLine = (
    polluant: ReceivedWasteStorage,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const wasteCode = polluant.data.codeID;
    const wasteElement = wasteCode
      ? findElementMatchingTemplate({ uuid: wasteCode }, referentiels.polluants)
      : null;

    const wasteName = formatWasteLabel(wasteElement, "");

    const isGeneratedObject =
      !polluant.data.paysID &&
      !polluant.data.departementID &&
      _.isEqual(polluant.errors, {}); // location is null only on generated object and objects with errors
    const editEnabled = !isBlocValidate && !isGeneratedObject;
    const denomination = wasteElement ? wasteElement.libelle : "";

    const firstCellContent = computeFirstLineContentCell(
      () => {
        openModal(polluant);
      },
      wasteName,
      !editEnabled,
      !_.isEmpty(polluant.errors)
    );
    const basics = [
      computeWrappedCellWithError(
        firstCellContent,
        classes,
        polluant.errors.codeID
      ),
      computeWrappedCellWithError(
        <p title={denomination}>{denomination}</p>,
        classes,
        undefined
      ),
      computeWrappedCell(
        <p title={polluant.data.fillingSource || undefined}>
          {displayFillingSource(polluant.data.fillingSource)}
        </p>,
        classes
      ),
      computeLocationStr(polluant),

      computeWrappedCellWithError(
        computeNumberWithSeparator(
          polluant.data.quantiteAdmise !== null
            ? polluant.data.quantiteAdmise
            : 0
        ),
        classes,
        polluant.errors.quantiteAdmise
      ),

      computeWrappedCellWithError(
        computeNumberWithSeparator(
          polluant.data.quantiteTraitee !== null
            ? polluant.data.quantiteTraitee
            : 0
        ),
        classes,
        polluant.errors.quantiteTraitee
      ),
      computeWrappedCellWithError(
        <p title={polluant.data.operationTraitement || undefined}>
          {polluant.data.operationTraitement}
        </p>,
        classes,
        polluant.errors.operationTraitement
      ),
      computeWrappedCellWithError(
        !_.isNil(polluant.data.numeroNotification)
          ? polluant.data.numeroNotification
          : "",
        classes,
        polluant.errors.numeroNotification
      ),
      computeWrappedCellWithError(
        parseBoolean(polluant.data.sortiDuStatutDeDechet),
        classes,
        polluant.errors.sortiDuStatutDeDechet
      ),
    ];
    return [
      ...basics,
      // todo: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={() => {
          openModal(polluant);
        }}
        handlerSuppress={() => {
          deleteWaste(polluant);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer ce déchet ?"}
        commentPath={
          !isGeneratedObject
            ? `${PATH_DECHET_RECEPTION_LIST}/${polluant.data.id}`
            : `${PATH_DECHET_PRODUCTION_LIST}/${polluant.data.id}`
        }
        isValidated={!editEnabled}
      />,
    ];
  };

  const arrayPolluantsLines = wasteContainer.map(singlePolluantInArray => {
    return computeSinglePolluantLine(singlePolluantInArray, classes);
  });

  //  TODO : add a loader and a loadingState if errors is still to undefined
  return (
    <CommonEntityTable
      header={[
        <p>Code déchet</p>,
        <p>Dénomination</p>,
        <p>Source - Dernière modification</p>,
        <p>Origine du déchet</p>,
        <p>Quantité admise (t/an)</p>,
        <p>Quantité traitée (t/an)</p>,
        <p>Opération d'élimination ou de valorisation</p>,
        <p>Numéro de notification</p>,
        <p>Sortie du statut de déchet</p>,
        <p>Actions</p>,
      ]}
      lines={arrayPolluantsLines}
      preferredColWidths={[
        WASTE_CODE_DISPLAY_COL_WIDTH,
        WASTE_LIBELLE_DISPLAY_COL_WIDTH,
        150,
        100,
        100,
        100,
        225,
        100,
        100,
        ACTION_COLUMN_DEFAULT_WIDTH,
      ]}
    />
  );
};

export default WasteReceptionArray;
