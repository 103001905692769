import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";

const useStyles = makeStyles({
  statusContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  statusDescription: {
    color: PINK,
    marginRight: "45px",
  },
  informationDeclaration: {
    padding: "5px",
    display: "inline-block",
    border: "2px solid " + PINK,
    borderRadius: "5px",
    color: PINK,
  },
});

const DeclarationStatus = ({
  statusDescription,
  declarationStatus,
}: {
  statusDescription: string;
  declarationStatus: string;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.statusContainer}>
      <p className={classes.statusDescription}>{statusDescription} </p>
      {!!declarationStatus && (
        <p className={classes.informationDeclaration}>{declarationStatus}</p>
      )}
    </div>
  );
};

export default DeclarationStatus;
