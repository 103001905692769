import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { DISABLED_BUTTON_HOVER_BACKGROUND, LINK_STYLE, PINK } from "theme";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import aideTrouverCodeEtablissementImg from "common/footer/icons/aideTrouverCodeEtablissement.png";
import aideConfigurerDroitsImg from "common/footer/icons/aideConfigurerDroits.png";
import aideTrouverAnneeImg from "common/footer/icons/aideTrouverAnnee.png";
import aideTrouverBoutonDeclarationImg from "common/footer/icons/aideTrouverBoutonDeclaration.png";

const useStyles = makeStyles({
  sectionTitle: {
    color: PINK,
    fontSize: 22,
    fontWeight: "bold",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
  bold: {
    fontWeight: "bold",
  },
  link: LINK_STYLE,
  modaleList: {
    marginLeft: "20px",
    marginRight: "20px",
  },
  img: {
    width: "80%",
    height: "auto",
  },
});

interface ModalBienvenueProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalBienvenue = ({
  isOpen,
  onRequestClose,
}: ModalBienvenueProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div>
        <h1>
          Bienvenue sur l'application GEREP ! Ce message a pour vocation de vous
          donner quelques informations utiles dans le cadre de l’utilisation de
          l’application. Une fois fermé, il pourra être rouvert à tout moment en
          cliquant sur le
          <span className={classes.bold}> bouton « Informations » </span>
          situé en pied de page.
          <br /> <br />
        </h1>
      </div>
      <p>
        <span className={classes.sectionTitle}>
          Présentation générale de l'application :{" "}
        </span>
        <br />
        <br />
        L’application GEREP vous permet de procéder à la déclaration annuelle
        des émissions polluantes et des déchets de votre ou de vos
        établissements conformément aux prescriptions de l’arrêté du 31 janvier
        2008 modifié (
        <a
          className={classes.link}
          href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000018276495"
        >
          https://www.legifrance.gouv.fr/loda/id/JORFTEXT000018276495
        </a>
        ).
        <br />
        <br />
        L'application GEREP permet également de procéder : <br />
        <ul className={classes.modaleList}>
          <li>
            À la déclaration des niveaux d’activité et des émissions soumises à
            quotas, pour les établissements relevant de la directive quotas
            (liste des établissements concernés :{" "}
            <a
              className={classes.link}
              href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044537559"
            >
              https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044537559
            </a>
            ) ;<br />
          </li>
          <li>
            À la déclaration de l’enquête annuelle carrières, pour les
            établissements relevant de la rubrique 2510-1 de la nomenclature des
            installations classées.
          </li>
        </ul>
        <br /> <br />
        <span className={classes.sectionTitle}>
          Date de dernier dépôt des déclarations :
        </span>
        <br />
        <br />
        Les déclarations GEREP, niveaux d’activité quotas et émissions quotas
        sont <span className={classes.bold}> indépendantes </span> l’une de
        l’autre. Elles doivent donc être validées de façon distincte, dans le
        respect du calendrier fixé par la réglementation :
        <br /> <br />
        <ul className={classes.modaleList}>
          <li>
            <span className={classes.bold}> 28 février 2025 </span> pour la
            déclaration des émissions quotas,
          </li>
          <li>
            <span className={classes.bold}> 31 mars 2025 </span> pour la
            déclaration des niveaux d’activité quotas,
          </li>
          <li>
            <span className={classes.bold}> 31 mars 2025 </span> pour la
            déclaration GEREP (hors quotas).
          </li>
        </ul>
        <br />
        <br />
        <span className={classes.sectionTitle}>
          Fonctionnement de l'application :
        </span>
        <br />
        <br />
        Si vous avez déjà déclaré dans GEREP, vous serez automatiquement
        redirigé vers le tableau de bord de votre dernière déclaration. Pour
        créer votre déclaration pour la campagne en cours, cliquez sur le bouton
        « Déclarer l’année en cours » dans le bandeau supérieur :
        <br />
        <br />
        <br />
        <img
          src={aideTrouverBoutonDeclarationImg}
          alt="capture du bouton Déclarer l'année en cours"
          className={classes.img}
        />
        <br />
        <br />
        <br />
        Vous pourrez à tout moment comparer vos déclarations des différentes
        années en naviguant avec le menu déroulant « Année déclarée » :
        <br />
        <br />
        <br />
        <img
          src={aideTrouverAnneeImg}
          alt="capture du header du site, avec le texte année déclarée encadré en rouge"
          className={classes.img}
        />
        <br />
        <br />
        <br />
        L’accès aux différents pavés et blocs de l’application est régi par les
        <span className={classes.bold}>
          {" "}
          types d’activités renseignés dans le pavé « Informations générales ».{" "}
        </span>
        Il est donc nécessaire de bien le vérifier avant de commencer la
        déclaration. En cas d’erreur manifeste sur les informations de ce pavé,
        vous devez contacter le service d’inspection en charge de votre
        établissement (car vous avez la main sur toutes les parties de
        l’application sauf celle-ci). Pour ce faire, ou en cas de difficultés
        rencontrées lors de la déclaration, vous pouvez cliquer sur l’icône en
        forme d’enveloppe disponible en haut à gauche.
        <br /> <br />
        <span className={classes.bold}>Le pavé « Air »</span>
        , l'un des plus complexes de l'application, doit être complété comme
        suit :
        <br />
        <ul className={classes.modaleList}>
          <li>
            Vous devez dans un premier temps compléter et valider les blocs
            actifs parmi les cinq blocs « Combustion / Incinération », «
            Procédés / Émissions diffuses », « Solvants / PGS », « ISDND » et «
            Élevage » (il est possible de les valider à vide sauf si une
            activité particulière impose leur remplissage) ;
          </li>
          <li>
            Vous devez ensuite compléter et valider le bloc « Synthèse »
            reprenant l’ensemble des émissions déclarées dans les blocs actifs
            listés au point précédent en précisant : la part éventuelle des
            émissions accidentelles parmi les émissions déclarées, la méthode
            principale utilisée pour déterminer les émissions (mesure, calcul ou
            estimation) et la norme associée (si mesure ou calcul) ;
          </li>
          <li>
            En cas de déclaration de gaz fluorés (HFC/PFC) dans le bloc «
            Synthèse », le bloc « Gaz fluorés » devient actif. À partir de 100
            kg émis de HFC et/ou de PFC, le remplissage du bloc « Gaz fluorés »
            est obligatoire.
          </li>
        </ul>
        <br /> <br />
        <span className={classes.sectionTitle}>Gestion des droits : </span>
        <br />
        <br />
        Vous pouvez attribuer le droit « Exploitant » à un ou plusieurs de vos
        collègues, en passant par le module d’attribution des droits du portail
        MonAIOT (
        <a
          className={classes.link}
          href="https://monaiot.developpement-durable.gouv.fr/ajout-droit"
        >
          https://monaiot.developpement-durable.gouv.fr/ajout-droit
        </a>
        ).
        <br />
        <br />
        Dans le cas où vous disposez d’un droit « Exploitant » pour plusieurs
        établissements, assurez-vous d’être sur la déclaration du bon
        établissement en vérifiant le code et le nom de l’établissement affichés
        (en haut à droite de la page, en-dessous du bouton « Mes droits ») :
        <br />
        <br />
        <br />
        <img
          src={aideTrouverCodeEtablissementImg}
          alt="capture du header du site, avec le code et nom de l'établissement entourés"
          className={classes.img}
        />
        <br />
        <br />
        <br />
        Vous pouvez également contrôler les informations présentes dans le pavé
        « Informations générales ». Si l’établissement n’est pas le bon, vous
        pouvez basculer sur le bon établissement en cliquant sur le bouton « Mes
        droits » (en haut à droite de la page) et en sélectionnant
        l’établissement désiré.
        <br />
        <br />
        <br />
        <img
          src={aideConfigurerDroitsImg}
          alt="capture de la liste déroulante des droits"
          className={classes.img}
        />
        <br />
        <br />
        <br />
        Pour les{" "}
        <span className={classes.bold}> établissements soumis à quotas</span>,
        le vérificateur agréé doit disposer d’un droit « Prestataire » (avec
        éventuellement la distinction niveaux d’activité/émissions). S’il ne
        dispose pas de ce droit, vous pouvez le lui attribuer par
        l’intermédiaire du portail MonAIOT (
        <a
          className={classes.link}
          href="https://monaiot.developpement-durable.gouv.fr/ajout-droit"
        >
          https://monaiot.developpement-durable.gouv.fr/ajout-droit
        </a>
        ).
        <br />
        <br />
        <span className={classes.sectionTitle}>Informations diverses :</span>
        <br />
        <br />
        <ul className={classes.modaleList}>
          <li>
            Des guides généraux et sectoriels sont mis à disposition dans la
            rubrique « GUIDES » accessible en cliquant sur le bouton situé en
            pied de page.
          </li>
          <li>
            Le Citepa propose également des formations approfondies sur le
            sujet.
          </li>
        </ul>
      </p>
      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
      />
    </CustomModal>
  );
};

export default ModalBienvenue;
