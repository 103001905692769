import { QuotasBlocEmissionsBilanTotalDto20Now } from "api/gen";
import { getValueOrZero } from "common/utils/numberUtils";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

export const computeTotalEmission = (
  values: QuotasBlocEmissionsBilanTotalDto20Now
): number => {
  return (
    getValueOrZero(values.emissionsCO2) +
    getValueOrZero(values.emissionsN2O) +
    getValueOrZero(values.emissionsPFC)
  );
};

export const updateHandler = (
  declaration: Declaration2020,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration2020 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};
