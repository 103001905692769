import { CommonEntityTableFirstNStickyProps } from "./types";
import React, { ReactElement } from "react";
import CommonEntityTable from "./index";
import { flattenNFirstElemsOfList } from "./utils";
import GroupedCell from "./GroupedCell";

const CommonEntityTableFirstNSticky = ({
  header,
  lines,

  preferredColWidths,
  nbColSticky,
}: CommonEntityTableFirstNStickyProps): React.ReactElement => {
  const flattenCellContent = (listElems: ReactElement[]) => (
    <GroupedCell
      listCells={listElems}
      sizeCells={preferredColWidths?.slice(0, nbColSticky)}
    ></GroupedCell>
  );

  const headerWithNfirstColGrouped = flattenNFirstElemsOfList(
    header,
    nbColSticky,
    flattenCellContent
  );

  const linesWithNfirstColGrouped: ReactElement[][] = lines.map(singleLine => {
    return flattenNFirstElemsOfList(
      singleLine,
      nbColSticky,
      flattenCellContent
    );
  });

  const colWidthWithNfirstColGrouped = flattenNFirstElemsOfList(
    preferredColWidths,
    nbColSticky,
    listNumber => listNumber.reduce((acc, val) => acc + val)
  );

  return (
    <CommonEntityTable
      header={headerWithNfirstColGrouped}
      lines={linesWithNfirstColGrouped}
      preferredColWidths={colWidthWithNfirstColGrouped}
    />
  );
};

export default CommonEntityTableFirstNSticky;
