import React from "react";
import { DummyChoiceSelectWTOPProps } from "../types/dummyTypes";
import { ValueType } from "libAdapter/ReactSelect/TypesPatternAdapter";
import SelectAdapter from "libAdapter/ReactSelect/SelectAdapter";

interface SelectOptionsType {
  label: string;
  value: string;
}

function DummyChoiceSelectWithoutOptionProps<T, IsMulti extends boolean>({
  disabled,
  options,
  additionalClassName,
  id,
  value,
  isMulti,
  placeholder,
  error,
  name, // because for whatever reason IF we pass a name AND a isMulti to the Select component, its style is destroyed. So we put the name out of the remaining props to be given to the Select.
  hideValue,
  onChange,
  computeLabel,
  tooltipLabel,
  enableReset = true,
  ...props
}: DummyChoiceSelectWTOPProps<T, IsMulti>): React.ReactElement {
  const actualOptionProps: SelectOptionsType[] = options.map(
    (singleOption, index) => {
      return {
        label: computeLabel(singleOption),
        value: index.toString(),
      };
    }
  );

  return (
    <SelectAdapter<SelectOptionsType, IsMulti>
      value={
        hideValue
          ? null
          : computeDependencyOptionsSelected(
              value,
              options,
              computeLabel,
              !!isMulti
            )
      }
      error={error}
      isDisabled={disabled}
      options={actualOptionProps}
      isClearable={enableReset}
      tooltipLabel={tooltipLabel}
      id={id}
      isMulti={isMulti}
      placeholder={placeholder}
      openMenuOnFocus={true}
      onChange={(selectTypeParam: ValueType<SelectOptionsType, IsMulti>) =>
        onChange &&
        onChange(
          computeLocalOptionsSelected<T, IsMulti>(
            selectTypeParam,
            options,
            // as justified because it's the compression of our type that makes ts unable to recognise isMulti is ALWAYS of type IsMulti
            !!isMulti as IsMulti
          )
        )
      }
      additionalClassName={additionalClassName}
      {...props}
    />
  );
}

export default DummyChoiceSelectWithoutOptionProps;

function computeDependencyOptionsSelected<T, IsMulti extends boolean>(
  selectedValue: ValueType<T, IsMulti>,
  options: T[],
  computeLabel: (selectedObject: T) => string,
  isMulti: IsMulti
): ValueType<SelectOptionsType, IsMulti> {
  if (isMulti === true) {
    const valueToPass: ValueType<T, true> = Array.isArray(selectedValue)
      ? selectedValue
      : [];
    // as are justified by the if we just made, and the type system we have, we JUST checked in code the IsMulti actual value
    return computeDependencyOptionsSelectedWithIsMulti(
      valueToPass,
      options,
      computeLabel
    ) as ValueType<SelectOptionsType, IsMulti>;
  } else {
    const valueToPass: T | null = !Array.isArray(selectedValue)
      ? (selectedValue as ValueType<T, false>) // thanks https://github.com/microsoft/TypeScript/issues/17002#ref-pullrequest-758575329 for the bug
      : null;
    return computeDependencyOptionsSelectedWithoutIsMulti(
      valueToPass,
      options,
      computeLabel
    ) as ValueType<SelectOptionsType, IsMulti>;
  }
}

function computeDependencyOptionsSelectedWithIsMulti<T>(
  selectedValue: ValueType<T, true>,
  options: T[],
  computeLabel: (selectedObject: T) => string
): ValueType<SelectOptionsType, true> {
  return selectedValue.map(singleValue => {
    return {
      label: computeLabel(singleValue),
      value: options.indexOf(singleValue).toString(),
    };
  });
}

function computeDependencyOptionsSelectedWithoutIsMulti<T>(
  selectedValue: ValueType<T, false>,
  options: T[],
  computeLabel: (selectedObject: T) => string
): ValueType<SelectOptionsType, false> {
  if (selectedValue === null) {
    return null;
  }
  return {
    label: computeLabel(selectedValue),
    value: options.indexOf(selectedValue).toString(),
  };
}

function computeLocalOptionsSelected<T, IsMulti extends boolean>(
  selectedValue: ValueType<SelectOptionsType, IsMulti>,
  options: T[],
  isMulti: IsMulti
): ValueType<T, IsMulti> {
  if (isMulti === true) {
    // as are justified by the if we just made, and the type system we have, we JUST checked in code the IsMulti actual value
    return computeLocalOptionsSelectedWithIsMulti(
      selectedValue as ValueType<SelectOptionsType, true>,
      options
    ) as ValueType<T, IsMulti>;
  } else {
    return computeLocalOptionsSelectedWithoutIsMulti(
      selectedValue as ValueType<SelectOptionsType, false>,
      options
    ) as ValueType<T, IsMulti>;
  }
}

function computeLocalOptionsSelectedWithIsMulti<T>(
  selectedValue: ValueType<SelectOptionsType, true>,
  options: T[]
): ValueType<T, true> {
  // the as is justified as value being a ValueType of SelectOptionsType, IF it is an array, it MUST be an array of SelectOptionsType.
  // (for ts bug, see https://github.com/microsoft/TypeScript/pull/39258)
  const computedArray = selectedValue.map(singleValue => {
    const valToRet = options.find(
      (singleOptionValue, index) => index.toString() === singleValue.value
    );

    if (valToRet === undefined) {
      return null;
    }

    return valToRet;
  });

  return computedArray.filter(item => item !== null) as T[];
}

function computeLocalOptionsSelectedWithoutIsMulti<T>(
  selectedValue: ValueType<SelectOptionsType, false>,
  options: T[]
): ValueType<T, false> {
  const selectedOption = options.find(
    (singleOptionValue, index) => index.toString() === selectedValue?.value
  );

  return selectedOption !== undefined ? selectedOption : null;
}
