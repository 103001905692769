import React from "react";
import { MethodeAlternativeDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import PrecisionMethodeAlternative from "./PrecisionMethodeAlternative";
import MethodeAlternativeArray from "./MethodeAlternativeArray";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
});

interface MethodeAlternativeSectionProps {
  methodeAlternativeDto: MethodeAlternativeDto;
}

const MethodeAlternativeSection = ({
  methodeAlternativeDto,
}: MethodeAlternativeSectionProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <MethodeAlternativeArray methodeAlternativeDto={methodeAlternativeDto} />

      <PrecisionMethodeAlternative
        descriptions={methodeAlternativeDto.descriptions}
      />
    </div>
  );
};

export default MethodeAlternativeSection;
