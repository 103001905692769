import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { InstallationInArray, InstallationInModale } from "../types";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  preventSubmitWithErrors,
  singleInstallationValidationSchema,
} from "./validation";
import {
  useBooleanCheckBoxGenerator,
  useDateFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { ReferenceItemActiviteQuotaDto } from "api/gen";
import Row from "common/presentational/Row";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
  separator: {
    height: "20px",
  },
});

interface FormSingleInstallationProps {
  onClose: () => void;
  isEdit: boolean;
  onSubmit: (
    values: InstallationInModale,
    formikActions: FormikActions<InstallationInModale>
  ) => void;
  initialInstallation: InstallationInModale;
  installationsInArray: InstallationInArray[];
  possibleActivitesValues: ReferenceItemActiviteQuotaDto[];
}

const FormSingleInstallation = ({
  onClose,
  onSubmit,
  initialInstallation,
  installationsInArray,
  isEdit,
  possibleActivitesValues,
}: FormSingleInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-quotas-installation-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN NIM"
      closeFunction={onClose}
      onSubmit={onSubmit}
      isEdit={isEdit}
      initialEntity={initialInstallation}
      validationSchema={singleInstallationValidationSchema}
      validate={values =>
        preventSubmitWithErrors(
          values,
          installationsInArray,
          initialInstallation
        )
      }
      renderField={({ values }) => (
        <>
          <TextField
            name="nim"
            label="Numéro NIM *"
            tooltipContent={
              <ul className={classes.list}>
                <li>FR + 15 chiffres (ex : FR000000000000171)</li>
                <li>
                  FR-new- + 8 chiffres (correspondant au code établissement
                  actuel de GEREP sans le point) (ex : FR-new-05803742)
                </li>
              </ul>
            }
          />

          <TextField name="nomInstallation" label="Nom de l’installation *" />

          <DateField name="entreeSEQE" label="Date d’entrée dans le SEQE *" />

          <DateField name="sortieSEQE" label="Date de sortie du SEQE" />

          <WrappedChoiceSelect
            name="codesActivite"
            label="Activités de l'installation (1 à 5) *"
            options={possibleActivitesValues}
            isMulti
            computeLabel={activite => activite.description}
            commonProps={commonProps}
          />

          <CheckBox
            name="isNotAllocationGratuit"
            label="L’installation n’est pas admissible ou a renoncé au bénéfice d’une allocation quotas à titre gratuit"
          />

          <Row additionalClassname={classes.separator} />

          <CheckBox
            name="isNouvelEntrant"
            label="L’installation est un nouvel entrant et ne souhaite pas fournir
                ses niveaux d’activité la première année suivant son début
                d’exploitation normale"
            tooltipContent={
              <ul className={classes.list}>
                <li>
                  "nouvel entrant" : Toute installation exerçant une ou
                  plusieurs activité(s) de l’annexe 1 de la Directive
                  2003/87/CE, et ayant obtenu son permis d’émission de gaz à
                  effet de serre après le 30 juin 2019.
                </li>
                <li>
                  "début d’exploitation normale" : Premier jour de
                  fonctionnement.
                </li>
              </ul>
            }
          />
        </>
      )}
    />
  );
};

export default FormSingleInstallation;
