import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSynthese from "./FormSynthese";
import uuid from "uuid";
import { ReferenceItemPolluantDto, ReferenceNormeAirDto } from "api/gen";
import { SyntheseInArray, SyntheseInModale } from "./utils/types";

interface SyntheseModaleProps {
  syntheseInModale: SyntheseInArray;
  setSyntheseInModale: Dispatch<SetStateAction<SyntheseInArray | null>>;
  synthesesInPage: SyntheseInArray[];
  setSynthesesInPage: React.Dispatch<
    React.SetStateAction<SyntheseInArray[] | null>
  >;
  referentialNORME: ReferenceNormeAirDto;
  referentialCO2: ReferenceItemPolluantDto;
}

const SyntheseModale = ({
  syntheseInModale,
  setSyntheseInModale,
  synthesesInPage,
  setSynthesesInPage,
  referentialNORME,
  referentialCO2,
}: SyntheseModaleProps): React.ReactElement => {
  const onClose = () => {
    setSyntheseInModale(null);
  };

  return (
    <CustomModal
      isOpen={true}
      contentLabel="Modale avec le formulaire d'ajout de syntheses."
      onRequestClose={onClose}
    >
      <FormSynthese
        referentialNORME={referentialNORME}
        referentielCO2={referentialCO2}
        closeFunction={onClose}
        onSubmit={values => {
          const newSynthese: SyntheseInModale = {
            substance: values.substance,
            emissionsAlreadyDeclared: values.emissionsAlreadyDeclared,
            withAccidentalEmissions: values.withAccidentalEmissions,
            additionalAccidentalEmissions: values.additionalAccidentalEmissions,
            origin: values.origin,
            totalEmissions: values.totalEmissions,
            totalEmissionsUsualUnit: values.totalEmissionsUsualUnit,
            uniteUsuelle: values.uniteUsuelle,
            method: values.method,
            methodReferenceM: values.methodReferenceM,
            methodReferenceC: values.methodReferenceC,
            normMINT: values.normMINT,
            normMPER: values.normMPER,
            normMNRO: values.normMNRO,
            normCINT: values.normCINT,
            description: values.description,
            factor: values.factor,
            isBiomasse: values.isBiomasse,
          };
          if (!synthesesInPage) {
            return;
          }
          //TODO put the called method in a utils file, as it is duplicated in quite a bit of files...
          //https://dl.polyconseil.fr/jira/browse/GEREP-657
          if (!syntheseInModale) {
            setSynthesesInPage(
              //TODO: compute errors
              synthesesInPage.concat({
                data: { ...newSynthese, id: uuid() },
                errors: {},
              })
            );
          } else {
            setSynthesesInPage(
              synthesesInPage.map(singleElderSynthese => {
                if (
                  singleElderSynthese.data.id === syntheseInModale.data.id &&
                  syntheseInModale.data.isBiomasse ===
                    singleElderSynthese.data.isBiomasse
                ) {
                  singleElderSynthese.data = {
                    ...newSynthese,
                    id: syntheseInModale.data.id,
                  };
                }
                return singleElderSynthese;
              })
            );
          }
          onClose();
        }}
        initialSynthese={syntheseInModale.data}
      />
    </CustomModal>
  );
};

export default SyntheseModale;
