import { QuotasBlocInfoGeneralesDto1819 } from "api/gen/api";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

export const updateHandler = (
  declaration: Declaration1919,
  values: QuotasBlocInfoGeneralesDto1819 | null
) => {
  if (values) {
    declaration.body.sections.quotas.blocInfoGenerales = values;
  }
  return declaration;
};
