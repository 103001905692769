import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import * as Yup from "yup";
import { StringSchema } from "yup";
import {
  commonArrayOfObjectFields,
  commonBooleanFieldsNullable,
  commonNullableStringFields,
  commonObjectFields,
  commonPercentageFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStringFields,
  MAX_NB_HOURS_YEAR,
  subFieldValidationMultipleANDScheme,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import {
  combustionInstallationTooMuchTimeMessage,
  partMessage,
} from "common/declarant/formik/formikMessages";
import { EmissionsInModale } from "../utils/types";

export const anySubPartActivated = (type: OptionProps | null): boolean => {
  return type?.value === 1 || type?.value === 2 || type?.value === 3;
};

export const isCo2 = (
  type: OptionProps | null,
  referentialCO2: ReferenceItemPolluantDto
): boolean => {
  return type?.label === referentialCO2.nom;
};

export const facteurSubPartActivated = (type: OptionProps | null): boolean => {
  return type?.value === 1;
};

export const mesureSubPartActivated = (type: OptionProps | null): boolean => {
  return type?.value === 2;
};

export const matiereSubPartActivated = (
  emissionType: OptionProps | null
): boolean => {
  return emissionType?.value === 3;
};

export const mesureContinueDebitSubPartActivated = (
  debitType: boolean | null
): boolean => {
  return !debitType;
};

export const mesureContinueConcentrationSubPartActivated = (
  concentrationType: boolean | null
): boolean => {
  return !concentrationType;
};

export const epurationSubPartActivated = (
  concentrationType: boolean | null
): boolean => {
  return !!concentrationType;
};

export const singleEmissionOverwriteDependantFields = (
  emission: EmissionsInModale,
  referentialCO2: ReferenceItemPolluantDto
): void => {
  if (!facteurSubPartActivated(emission.methods)) {
    emission.combustible = null;
    emission.referenceCombustible = null;
    emission.consoAnnuelle = null;
    emission.unite = null;
    emission.facteur = null;
    emission.ecart = null;
    emission.provenance = null;
    emission.facteurOxydation = null;
    emission.provenanceFacteurOxydation = null;
  }
  if (
    facteurSubPartActivated(emission.methods) &&
    !isCo2(emission.substance, referentialCO2)
  ) {
    emission.facteurOxydation = null;
    emission.provenanceFacteurOxydation = null;
  }
  if (!mesureSubPartActivated(emission.methods)) {
    emission.heures = null;
    emission.debit = null;
    emission.continuDebit = null;
    emission.frequenceDebit = null;
    emission.concentrationDebit = null;
    emission.continuConcentration = null;
    emission.frequenceConcentration = null;
    emission.biomasseMesure = null;
  }
  if (mesureSubPartActivated(emission.methods)) {
    if (!isCo2(emission.substance, referentialCO2)) {
      emission.biomasseMesure = null;
    }
    if (!mesureContinueDebitSubPartActivated(emission.continuDebit)) {
      emission.frequenceDebit = null;
    }
    if (
      !mesureContinueConcentrationSubPartActivated(
        emission.continuConcentration
      )
    ) {
      emission.frequenceConcentration = null;
    }
  }

  if (!matiereSubPartActivated(emission.methods)) {
    emission.intrants = null;
    emission.entrant = null;
    emission.sortant = null;
    emission.element = null;
    emission.teneur = null;
    emission.part = null;
    emission.biomasseMatiere = null;
  }
  if (matiereSubPartActivated(emission.methods)) {
    if (!isCo2(emission.substance, referentialCO2)) {
      emission.biomasseMesure = null;
    }
  }
  if (!epurationSubPartActivated(emission.epuration)) {
    emission.epuration = false;
    emission.nature = null;
    emission.rendement = null;
  }
};

export const singleEmissionValidationSchema = (
  referentialCO2: ReferenceItemPolluantDto
) => {
  return Yup.object().shape({
    nameInstallation: commonNullableStringFields,
    substance: commonObjectFields,
    methods: commonObjectFields,
    epuration: commonBooleanFieldsNullable,
    appareils: subFieldValidationScheme(
      "methods",
      anySubPartActivated,
      commonArrayOfObjectFields,
      Yup.array()
    ),
    nature: subFieldValidationMultipleANDScheme<
      StringSchema<string | null | undefined>,
      OptionProps | null,
      boolean
    >(
      ["methods", "epuration"],
      anySubPartActivated,
      epurationSubPartActivated,
      commonStringFields,
      Yup.string()
    ),
    rendement: subFieldValidationMultipleANDScheme(
      ["methods", "epuration"],
      anySubPartActivated,
      epurationSubPartActivated,
      commonPercentageFields,
      Yup.number()
    ),
    emissionsAnnuelles: commonPositiveNumberFieldsNullable,
    //FacteurEmission
    combustible: subFieldValidationScheme(
      "methods",
      facteurSubPartActivated,
      commonObjectFields,
      Yup.object()
    ),
    referenceCombustible: commonNullableStringFields,
    consoAnnuelle: commonNullableStringFields,
    unite: commonNullableStringFields,
    facteur: subFieldValidationScheme(
      "methods",
      facteurSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    ecart: commonNullableStringFields,
    provenance: subFieldValidationScheme<
      StringSchema<string | null | undefined>,
      OptionProps
    >("methods", facteurSubPartActivated, commonStringFields, Yup.string()),
    facteurOxydation: subFieldValidationMultipleANDScheme(
      ["methods", "substance"],
      facteurSubPartActivated,
      (type: OptionPropsWithObject<ReferenceItemPolluantDto> | null) =>
        isCo2(type, referentialCO2) || false,
      commonPercentageFields,
      Yup.number()
    ),
    provenanceFacteurOxydation: commonNullableStringFields,
    //MesureEmission
    heures: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      commonPositiveNumberFields.max(
        MAX_NB_HOURS_YEAR,
        combustionInstallationTooMuchTimeMessage
      ),
      Yup.number()
    ),
    debit: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    continuDebit: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      Yup.boolean(),
      Yup.boolean()
    ),
    frequenceDebit: subFieldValidationMultipleANDScheme(
      ["methods", "continuDebit"],
      mesureSubPartActivated,
      mesureContinueDebitSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    concentrationDebit: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    continuConcentration: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      Yup.boolean(),
      Yup.boolean()
    ),
    frequenceConcentration: subFieldValidationMultipleANDScheme(
      ["methods", "continuConcentration"],
      mesureSubPartActivated,
      mesureContinueConcentrationSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    biomasseMesure: subFieldValidationMultipleANDScheme(
      ["methods", "substance"],
      mesureSubPartActivated,
      (type: OptionPropsWithObject<ReferenceItemPolluantDto> | null) =>
        isCo2(type, referentialCO2) || false,
      commonPercentageFields,
      Yup.number()
    ),
    //MatiereEmission
    intrants: subFieldValidationScheme<
      StringSchema<string | null | undefined>,
      OptionProps
    >("methods", matiereSubPartActivated, commonStringFields, Yup.string()),
    entrant: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    sortant: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    element: subFieldValidationScheme<
      StringSchema<string | null | undefined>,
      OptionProps
    >("methods", matiereSubPartActivated, commonStringFields, Yup.string()),
    teneur: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      commonPercentageFields,
      Yup.number()
    ),
    part: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      Yup.number()
        .min(1, partMessage)
        .max(100, partMessage),
      Yup.number()
    ),
    biomasseMatiere: subFieldValidationMultipleANDScheme(
      ["methods", "substance"],
      matiereSubPartActivated,
      (type: OptionPropsWithObject<ReferenceItemPolluantDto> | null) =>
        isCo2(type, referentialCO2) || false,
      commonPercentageFields,
      Yup.number()
    ),
  });
};
