import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import React from "react";
import danger from "icons/danger.svg";
import MessageBox from "common/presentational/MessageBox";
import { WorkflowTarget } from "common/utils/types";

const useStyles = makeStyles({
  fullWidth: {
    width: "100%",
  },
});

interface InReviewCommentDisplayerProps {
  workflowTarget: WorkflowTarget;
  isInReviewState: boolean;
  comment: string | null;
}

const InReviewCommentDisplayer = ({
  workflowTarget,
  isInReviewState,
  comment,
}: InReviewCommentDisplayerProps): React.ReactElement | null => {
  const classes = useStyles();

  const titleInReview = `Déclaration ${
    workflowTarget === WorkflowTarget.GLOBAL ? "" : "de Quotas"
  } mise en révision par l'inspection`;
  const titleComment =
    "Rappel du commentaire de mise en révision par l'inspection";
  const subject = "Commentaire de l'inspection";

  if (!isInReviewState && comment === null) {
    return null;
  }

  return (
    <>
      <Row additionalStyle={{ justifyContent: "flex-end" }} height={"auto"}>
        <MessageBox
          title={isInReviewState ? titleInReview : titleComment}
          subject={subject}
          comment={comment || ""}
          icon={
            isInReviewState
              ? { src: danger, alt: "un panneau attention" }
              : undefined
          }
          additionalClassname={classes.fullWidth}
        />
      </Row>
    </>
  );
};

export default InReviewCommentDisplayer;
