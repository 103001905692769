import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BlocInfoGeneralesFormValues } from "./types";
import { updateHandlerInfoGenerales } from "../utils";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import {
  PATH_EAU_INFOGENERALE,
  PATH_EAU_INFOGENERALE_MEGATHERMIE,
  PATH_EAU_INFOGENERALE_SEUIL,
} from "common/path/path18Now";
import BlocFullContext20Now from "../../../toNow/versionedElements/BlocFullContext20Now";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface BlocInfoGeneralesProps {
  values: BlocInfoGeneralesFormValues;
  setValues: (values: BlocInfoGeneralesFormValues) => void;
  hasChanges: boolean;
  cancelAction: () => void;
}

const BlocInfoGenerales = ({
  values,
  setValues,
  hasChanges,
  cancelAction,
}: BlocInfoGeneralesProps): JSX.Element => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-eau-info-generales",
  };

  const BooleanSelectField = useDummyBooleanCheckboxFieldGenerator(commonProps);

  return (
    <BlocFullContext20Now
      title="informations complémentaires"
      hasModification={hasChanges}
      isValidateButtonAvailable={true} // No validation needed (only two boolean, all combinaisons authorized)
      path={PATH_EAU_INFOGENERALE}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration =>
        updateHandlerInfoGenerales(declaration, values)
      }
      cancelAction={cancelAction}
      renderContent={shouldDisabledFields => (
        <>
          <BooleanSelectField
            name="depassementSeuil"
            label="Dépassement des seuils de prélèvement"
            disabled={shouldDisabledFields}
            value={values.depassementSeuil}
            onChange={checked =>
              setValues({ ...values, depassementSeuil: checked })
            }
            tooltipContent={
              "La case doit être cochée dès lors que le volume provenant d'un réseau d'adduction est supérieur à 50 000 m³/an ou que le volume prélevé dans le milieu naturel est supérieur à 7 000 m³/an."
            }
            commentPath={PATH_EAU_INFOGENERALE_SEUIL}
          />
          <BooleanSelectField
            name="megathermie"
            label="Dépassement des seuils de chaleur rejetée"
            disabled={shouldDisabledFields}
            value={values.megathermie}
            onChange={checked => setValues({ ...values, megathermie: checked })}
            tooltipContent={
              "La case doit être cochée dès lors que la chaleur rejetée (en mégathermie) est supérieure à 100 Mth/an pour les rejets en mer et 10 Mth/an pour les rejets en rivière pour la période allant du 1er avril au 31 décembre."
            }
            commentPath={PATH_EAU_INFOGENERALE_MEGATHERMIE}
          />
        </>
      )}
    />
  );
};

export default BlocInfoGenerales;
