import React from "react";
import { WorkflowTarget } from "common/utils/types";
import { useDeclaration21Now } from "../../../versionedElements/declarationHooks21Now";
import NimFilesLoaderDisplayer from "../FilesRelatedToNim/NimFilesLoaderDisplayer";
import RapportOfficeLoader from "./RapportOfficeLoader";

interface QuotasDeclarationOfficeUploadProps {
  workflowTarget: WorkflowTarget;
  isDisabled: boolean;
}

const QuotasDeclarationOfficeUpload = ({
  workflowTarget,
  isDisabled,
}: QuotasDeclarationOfficeUploadProps): React.ReactElement => {
  const declaration = useDeclaration21Now();
  const installations = declaration.body.typeActivite.quotas.installations;

  return (
    <>
      {installations.map(installation => (
        <NimFilesLoaderDisplayer installation={installation}>
          <RapportOfficeLoader
            workflowTarget={workflowTarget}
            installationId={installation.id}
            shouldDisableFields={isDisabled}
          />
        </NimFilesLoaderDisplayer>
      ))}
    </>
  );
};

export default QuotasDeclarationOfficeUpload;
