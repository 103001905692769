import { useCallback } from "react";
import {
  createTechniqueGlobalDto,
  overrideUncoherentValuesTechniqueDto,
} from "./handlers";
import {
  useDeclarationStateHandlers21Now,
  useUpdateDeclarationTypeActiviteGlobal21Now,
} from "../../versionedElements/declarationHooks21Now";
import { TechniqueGlobalValues } from "./utils";

export const useTechniqueGlobalSaveHandler = (): ((
  values: TechniqueGlobalValues
) => Promise<void>) => {
  const setTypeActivite = useUpdateDeclarationTypeActiviteGlobal21Now();

  const update = useCallback(
    async (values: TechniqueGlobalValues) => {
      const newTechniqueDto = overrideUncoherentValuesTechniqueDto(
        createTechniqueGlobalDto(values)
      );
      await setTypeActivite(newTechniqueDto);
    },
    [setTypeActivite]
  );
  return update;
};

export const useTechniqueGlobalValidateHandler = (): ((
  values: TechniqueGlobalValues
) => Promise<void>) => {
  const { startGlobal } = useDeclarationStateHandlers21Now();

  const startCall = useCallback(
    async (values: TechniqueGlobalValues) => {
      const newTechniqueDto = overrideUncoherentValuesTechniqueDto(
        createTechniqueGlobalDto(values)
      );
      await startGlobal(newTechniqueDto);
    },
    [startGlobal]
  );
  return startCall;
};

export const useTechniqueGlobalEditHandler = (): (() => Promise<void>) => {
  const { resetGlobal } = useDeclarationStateHandlers21Now();

  const resetCall = useCallback(async () => {
    await resetGlobal();
  }, [resetGlobal]);
  return resetCall;
};
