import React, { useState } from "react";
import DustFalloutArray from "./DustFalloutArray";
import DustFalloutModal from "./DustFalloutModal";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyBooleanCheckboxFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { DustFalloutForm, DustFalloutPointInArray } from "./utils/types";
import {
  Declaration1919,
  useDeclaration1919,
} from "../../../versionedElements/declarationHooks1919";
import Button from "common/button";
import {
  convertDtoToDustFalloutForm,
  convertDustFalloutPointToDisplayed,
} from "./utils/converters";
import _ from "lodash";
import { convertDeclarationDtoUsingDustFalloutForm } from "../utils/converters";
import { GeneralInformationsForm } from "../BlocInfosGenerales/utils/types";
import { selectPossibleValues as selectPossibleValuesInfosGenerales } from "../BlocInfosGenerales/utils/selectPossibleValues";
import { validationSchema } from "./utils/validation";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import {
  PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_EFFECTUEE,
} from "common/path/path18Now";
import { LEFT_WITHDRAW_STYLE } from "theme";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

interface BlocRetombeesPoussiereProps {
  dustFalloutFormProps: FormikBlocRefAndState<DustFalloutForm>;
  generalInformationsFormProps: FormikBlocRefAndState<GeneralInformationsForm>;
  initialEmissionPoints: DustFalloutPointInArray[];
  emissionPointsInArray: DustFalloutPointInArray[];
  setEmissionPointsInArray: (points: DustFalloutPointInArray[]) => void;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  buttonModale: {
    padding: "5px",
    marginBottom: "20px",
    marginLeft: "70%",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const BlocRetombeesPoussiere = ({
  dustFalloutFormProps,
  generalInformationsFormProps,
  initialEmissionPoints,
  emissionPointsInArray,
  setEmissionPointsInArray,
}: BlocRetombeesPoussiereProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "environnement-bloc-retombees-poussieres",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DummyCheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    emissionPointInModal,
    setEmissionPointInModal,
  ] = useState<DustFalloutPointInArray | null>(null);

  const initialValues = convertDtoToDustFalloutForm(declaration);

  let inheritedCareer = false;

  if (
    generalInformationsFormProps.formikRef &&
    generalInformationsFormProps.formikRef.current
  ) {
    const values = generalInformationsFormProps.formikRef.current.state.values;
    if (
      ((values.maxProductionPerYear && values.maxProductionPerYear >= 150) ||
        (values.averageProductionPerYear &&
          values.averageProductionPerYear >= 150)) &&
      values.eau === selectPossibleValuesInfosGenerales.careerTypeEau[1]
    ) {
      inheritedCareer = true;
    }
  }

  return (
    <FormikBlocFullContext1919
      hasChanges={dustFalloutFormProps.hasChanges}
      setHasChanges={dustFalloutFormProps.setHasChanges}
      formikRef={dustFalloutFormProps.formikRef}
      title="MESURES DE RETOMBÉES DE POUSSIÈRES DANS L'ENVIRONNEMENT (TE3)"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE}
      hasFormChanges={(values: DustFalloutForm) => {
        const isDoneMeasuresUpdated =
          initialValues.doneMeasures !== values.doneMeasures;
        const isArrayUpdated =
          values.doneMeasures &&
          !_.isEqual(initialEmissionPoints, emissionPointsInArray);
        return isDoneMeasuresUpdated || isArrayUpdated;
      }}
      additionalValidationAllowed={values => {
        // TODO : display error message
        if (values.doneMeasures) {
          return (
            emissionPointsInArray.length === 0 ||
            emissionPointsInArray.some(
              point =>
                point.data.locationType === selectPossibleValues.locationType[0]
            )
          );
        }
        return true;
      }}
      cancelAction={() => {
        setEmissionPointsInArray(
          convertDustFalloutPointToDisplayed(
            declaration.body.sections.carriere.environnement
              .poussiereAtmospherique.mesuresDesRetombee
          )
        );
        return initialValues;
      }}
      updateHandler={(declaration: Declaration1919, values: DustFalloutForm) =>
        convertDeclarationDtoUsingDustFalloutForm(
          declaration,
          values,
          emissionPointsInArray
        )
      }
      renderContent={({ values }, shouldDisabledFields: boolean) => (
        <>
          <DummyCheckBox
            name="inheritedCareer"
            label="Carrière soumise à des prescriptions de mesures de retombées de poussières atmosphériques"
            disabled
            value={inheritedCareer}
          />

          {inheritedCareer && (
            <>
              <div className={classes.withdrawLeft}>
                <CheckBox
                  name="doneMeasures"
                  label="Mesures réalisées"
                  disabled={shouldDisabledFields}
                  commentPath={
                    PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_EFFECTUEE
                  }
                />
              </div>

              {values.doneMeasures && (
                <>
                  <Button
                    text="AJOUTER UN POINT D'ÉMISSION"
                    additionalClassname={classes.buttonModale}
                    onClick={() => setIsModalOpen(true)}
                    isDisabled={shouldDisabledFields}
                  />

                  <DustFalloutArray
                    isDisabled={shouldDisabledFields}
                    emissionPointsInArray={emissionPointsInArray}
                    setEmissionPointsInArray={setEmissionPointsInArray}
                    onEdit={emissionPoint => {
                      setEmissionPointInModal(emissionPoint);
                      setIsModalOpen(true);
                    }}
                  />

                  <DustFalloutModal
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    emissionPointInModal={emissionPointInModal}
                    setEmissionPointInModal={setEmissionPointInModal}
                    emissionPointsInArray={emissionPointsInArray}
                    setEmissionPointsInArray={setEmissionPointsInArray}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    />
  );
};

export default BlocRetombeesPoussiere;
