import { makeStyles } from "@material-ui/styles";
import React from "react";

const useStyles = makeStyles({
  wrapper: {
    margin: "10px 0",
  },
  ul: {
    "& li": {
      marginLeft: 20,
      marginTop: 10,
      marginBottom: 10,
    },
    "&>li>ul": {
      marginTop: 30,
      "&>li": {
        marginBottom: 20,
      },
    },
  },
});

const SeparateSubmissionWarning = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      Les déclarations suivantes doivent être transmises à l'inspection (et à la
      vérification le cas échéant) de façon indépendante :
      <ul className={classes.ul}>
        <li>GEREP hors quotas</li>
        <li>Quotas émissions</li>
        <li>Quotas niveaux d'activité</li>
      </ul>
      Assurez-vous donc de transmettre l'ensemble des déclarations que vous
      devez soumettre dans les délais impartis, eux-même spécifiques à chacune
      d'entre elles.
    </div>
  );
};

export default SeparateSubmissionWarning;
