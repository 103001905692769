import React from "react";
import { TEXT_INPUT_STYLE } from "theme";
import { makeStyles } from "@material-ui/styles";
import { computeClassname } from "./utils";
import { DummyTextAreaFieldProps } from "../types/dummyTypes";

const useStyles = makeStyles(TEXT_INPUT_STYLE);

const DummyTextArea = ({
  additionalClassName,
  disabled,
  value,
  name,
  placeholder,
  rows,
  error,
  onChange,
  ...props
}: DummyTextAreaFieldProps): React.ReactElement => {
  const classes = useStyles();

  const inputClassName = computeClassname(
    // todo decide of the right way to pass errors to compute the classname, and eventually overriding style with a second className.
    classes,
    error,
    disabled,
    additionalClassName
  );
  return (
    <textarea
      name={name}
      style={{
        resize: "none",
      }}
      className={inputClassName}
      disabled={disabled}
      placeholder={placeholder}
      {...props}
      value={value || ""}
      rows={rows}
      onChange={event => {
        event.stopPropagation();
        // we don't wanna save an empty string when user is actually writing nothing
        onChange && onChange(event.target.value || null);
      }}
      onBlur={event => {
        props.onBlur && props.onBlur(event);
        // we don't wanna save an empty string when user is actually writing nothing
        onChange && onChange(event.target.value.trim() || null);
      }}
    />
  );
};

export default DummyTextArea;
