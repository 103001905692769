import React, { useEffect, useState } from "react";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import Row from "common/presentational/Row";
import { useDeclaration24Now } from "../../../toNow/versionedElements/declarationHooks24Now";
import { fetchGunNotification } from "./utils";
import { isDeclarationLinkedToGun } from "../CompanyActivity/validation";

const NotificationGun = (): React.ReactElement => {
  const { referenceInfoGeneralController } = useAuthenticatedApi();
  const declaration = useDeclaration24Now();

  const [notificationGun, setNotificationGun] = useState<string | null>(null);

  useEffect(() => {
    let ignore = false;

    const fetchNotification = async () => {
      const notification = await fetchGunNotification(
        declaration.etablissementCode,
        declaration.annee,
        referenceInfoGeneralController
      );

      if (!ignore) {
        setNotificationGun(notification);
      }
    };

    if (isDeclarationLinkedToGun(declaration.etablissementCode)) {
      fetchNotification();
    }
    return () => {
      ignore = true;
    };
  }, [
    declaration.etablissementCode,
    declaration.annee,
    referenceInfoGeneralController,
  ]);

  if (notificationGun === null) {
    return <></>;
  } else {
    return (
      <>
        <>{notificationGun}</>
        <Row />
      </>
    );
  }
};

export default NotificationGun;
