import React, { useCallback, useEffect, useState } from "react";
import {
  FormikProps,
  FormikValues,
} from "libAdapter/Formik/TypesPatternAdaptater";
import DummyFormikBloc from "./DummyFormikBloc";
import { FormikBlocProps } from "./types";
import _ from "lodash";

/**
 *  wrapper to check and ward if Bloc has changes and default Formik cancel action
 *
 * use case : wrapped in FormikBlocInUserContext, (should not be used)
 *
 * @param formikRef: Formik ref
 * @param hasChanges: initial value to know if there is currently changes in the Bloc
 * @param setHasChanges: callback triggered on changes or no changes switch
 * all other props : see DummyFormikBloc
 */
function FormikBloc<T extends FormikValues>({
  //    FormikBlocRefAndState
  formikRef,
  hasChanges,
  setHasChanges,
  //    DummyFormikBlocDisplayProps
  initialValues,
  validationSchema,
  title,
  isSingle,
  //  spinner
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  //    DummyFormikBlocControlProps
  saveAction = async () => {
    return;
  },
  validateAction = async () => {
    return;
  },
  editAction = async () => {
    return;
  },
  cancelAction = (props: FormikProps<T>) => {
    props.resetForm(initialValues);
  },
  onChange,
  //    DummyFormikBlocAdditionProps
  additionalValidationAllowed,
  additionalFieldValidation,
  //    additional
  isBlocValidated,
  actionsAllowed,
  hasFormChanges,
  renderContent,
  preventSubmitWithErrors,
  enableReinitialize,
  validationMessage,
  validationTitle,
  hasComments,
  areGlobalCommentsAllowed,
  commentAction,
  validationToolTipContent,
  icon,
}: FormikBlocProps<T>) {
  // to avoid inifinite re-render when using enableReinitialize without custom hasFormChanges
  const memoHasFormChanges = useCallback(
    hasFormChanges
      ? hasFormChanges
      : (values: T) => !_.isEqual(values, initialValues),
    [hasFormChanges, initialValues]
  );

  //  state to trigger useEffect bellow
  const [hasCalculatedchanges, setHasCalculatedchanges] = useState<boolean>(
    hasChanges
  );
  //  triggered on changes or no changes switch to warn it's parent component
  useEffect(() => {
    setHasChanges(hasCalculatedchanges);
  }, [hasCalculatedchanges, setHasChanges]);
  return (
    <DummyFormikBloc
      //    FormikBlocRefAndState
      formikRef={formikRef}
      isBlocValidated={isBlocValidated}
      hasChanges={values => {
        const hasModification = memoHasFormChanges(values);
        setHasCalculatedchanges(hasModification);
        return hasModification;
      }}
      actionsAllowed={actionsAllowed}
      renderContent={renderContent}
      //    DummyFormikBlocDisplayProps
      initialValues={initialValues}
      validationSchema={validationSchema}
      title={title}
      isSingle={isSingle}
      //  spinner
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      //    DummyFormikBlocControlProps
      saveAction={saveAction}
      validateAction={validateAction}
      editAction={editAction}
      cancelAction={cancelAction}
      onChange={onChange}
      additionalValidationAllowed={additionalValidationAllowed}
      additionalFieldValidation={additionalFieldValidation}
      preventSubmitWithErrors={preventSubmitWithErrors}
      enableReinitialize={enableReinitialize}
      validationTitle={validationTitle}
      validationMessage={validationMessage}
      validationToolTipContent={validationToolTipContent}
      commentAction={commentAction}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed}
      hasComments={hasComments}
      icon={icon}
    />
  );
}

export default FormikBloc;
