import React from "react";
import { useDeclarationHelpers2020 } from "../../versionedElements/declarationHooks2020";
import DashboardCardsContainer from "./DashboardCardsContainer";
import AllDashboardCards from "./AllDashboardCards";
import QuotasDashboardCards from "./QuotasDashboardCards";
import LecteurDashboardCards from "./LecteurDashboardCards";

interface DeclarationSummaryProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  isVerificateur?: boolean;

  isLecteur?: boolean;
}

const DeclarationSummary = ({
  declarationAnnee,
  declarationEtablissement,
  isVerificateur,
  isLecteur = false,
}: DeclarationSummaryProps): React.ReactElement => {
  const declarationApi = useDeclarationHelpers2020();

  let displayedCards;
  if (isVerificateur) {
    displayedCards = (
      <QuotasDashboardCards
        declarationApi={declarationApi}
        declarationEtablissement={declarationEtablissement}
        declarationAnnee={declarationAnnee}
        canSeeProgression={!isVerificateur}
      />
    );
  } else if (isLecteur) {
    displayedCards = (
      <LecteurDashboardCards
        declarationApi={declarationApi}
        declarationEtablissement={declarationEtablissement}
        declarationAnnee={declarationAnnee}
      />
    );
  } else {
    displayedCards = (
      <AllDashboardCards
        declarationApi={declarationApi}
        declarationEtablissement={declarationEtablissement}
        declarationAnnee={declarationAnnee}
      />
    );
  }

  return (
    <DashboardCardsContainer
      declarationAnnee={declarationAnnee}
      declarationEtablissement={declarationEtablissement}
      declarationApi={declarationApi}
      isVerificateur={isVerificateur}
      isLecteur={isLecteur}
    >
      {displayedCards}
    </DashboardCardsContainer>
  );
};

export default DeclarationSummary;
