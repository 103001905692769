import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BORDER_GREY,
  HELP_TEXT_FONT_SIZE,
  SUB_SECTION_FONT_SIZE,
} from "theme";
import classNames from "classnames";

interface DashboardContainerProps {
  title: string;
  informationMessage?: string;
  additionalClassname?: string;
  children: ReactNode;
}

const useStyles = makeStyles({
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
    marginBottom: "30px",
  },
  title: {
    fontSize: SUB_SECTION_FONT_SIZE,
    textTransform: "uppercase",
    fontWeight: "normal",
    marginBottom: "20px",
  },
  informationMessage: {
    fontSize: HELP_TEXT_FONT_SIZE,
    textAlign: "center",
    marginBottom: "20px",
  },
});

const SubDashboardContainer = ({
  title,
  informationMessage,
  children,
  additionalClassname,
}: DashboardContainerProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, additionalClassname)}>
      {informationMessage && (
        <p className={classes.informationMessage}>{informationMessage}</p>
      )}
      <h2 className={classes.title}>{title}</h2>
      {children}
    </div>
  );
};

export default SubDashboardContainer;
