import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import Row from "common/presentational/Row";
import { FluidInArray } from "../utils/types";
import HFCArray from "../HFCArray";
import Button from "common/button";
import HFCModale from "../HFCModale";
import _ from "lodash";
import { ReferenceItemGazDto } from "api/gen";
import BlocFullContext19Now from "../../../versionedElements/BlocFullContext19Now";
import { convertHfcInArrayToDto } from "../utils/converter";
import { compareFluidFunction } from "../utils/utils";

const useStyles = makeStyles({
  separator: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  text: {
    margin: "12px 0",
  },
});

interface BlocHFCProps {
  validationPath: string;
  fluidInArray: FluidInArray[];
  setFluidInArray: Dispatch<SetStateAction<FluidInArray[]>>;
  initialFluidInArray: FluidInArray[];
  possibleValues: ReferenceItemGazDto[];
}

const BlocHFC = ({
  validationPath,
  fluidInArray,
  setFluidInArray,
  initialFluidInArray,
  possibleValues,
}: BlocHFCProps): React.ReactElement => {
  const classes = useStyles();
  const [fluidModaleOpen, setFluidModaleOpen] = useState(false);
  const [fluidInModale, setFluidInModale] = useState<FluidInArray | null>(null);

  return (
    <>
      <BlocFullContext19Now
        title="TABLEAU DES ÉMISSIONS DE HYDROFLUOROCARBURES (HFC)"
        hasModification={!_.isEqual(initialFluidInArray, fluidInArray)}
        isValidateButtonAvailable={true}
        path={validationPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => {
          declaration.body.sections.air.gaz.hfc = convertHfcInArrayToDto(
            fluidInArray
          );
          return declaration;
        }}
        cancelAction={() => {
          setFluidInArray(initialFluidInArray);
        }}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <p className={classes.text}>
                Ce tableau peut être rempli en indiquant les quantités de
                fluides commerciaux émises. Si le fluide commercial que vous
                avez utilisé n’apparaît pas, choisissez ‘Autre’ et définissez
                les proportions des différents fluides primaires de ce fluide
                commercial.
              </p>

              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UN FLUIDE"
                  type="button"
                  onClick={() => setFluidModaleOpen(true)}
                  isDisabled={shouldDisabledFields}
                />
              </Row>

              <Row additionalClassname={classes.separator} />

              <HFCArray
                fluidInArray={_.cloneDeep(fluidInArray).sort(
                  compareFluidFunction
                )}
                setFluidInArray={setFluidInArray}
                setFluidInModale={setFluidInModale}
                setFluidModaleOpen={setFluidModaleOpen}
                isValidated={shouldDisabledFields}
                commentPath={validationPath}
              />
            </>
          );
        }}
      />

      <HFCModale
        isOpen={fluidModaleOpen}
        fluidInArray={fluidInArray}
        fluidInModale={fluidInModale}
        setFluidInArray={setFluidInArray}
        setFluidInModale={setFluidInModale}
        setModaleOpen={setFluidModaleOpen}
        selectPossibleValue={possibleValues}
        declaredFluids={fluidInArray}
      />
    </>
  );
};

export default BlocHFC;
