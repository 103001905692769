import {
  DeclarationDto2020,
  DeclarationSectionsDto2020,
  QuotasAllocationsBlocVerificationDto20Now,
  QuotasBlocVerificationEmissionsDto20Now,
  TypeActiviteDto2020,
} from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  backMessageApproveDeclaration,
  backMessageOnResetDeclaration,
  backMessageOnStartDeclaration,
  backMessageOnUpdateTypeActivite,
  backMessageOnYieldQuotas,
  backMessageSetVerification,
  backSubmitSimplifiedQuotas,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";
import { DeclarationApiContext } from "pages/CompanySpace/DeclarationApiContext/DeclarationApiContext";
import { useCallback, useContext } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationState,
  useDeclarationStateHandlers,
  useQuotasState,
  useUpdateDeclarationTypeActivite,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  AnyDeclarationDto,
  BasicDeclarationHandlers,
  DeclarationState,
  DeclarationStateHandlers18Now,
} from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2020;
export const MAX_YEAR = 2020;

// Define all the DTO types for this version.
export type Declaration2020 = DeclarationDto2020;
export type TypeActivite2020 = TypeActiviteDto2020;

type DeclarationStateHandlers2020 = DeclarationStateHandlers18Now & {
  submitSimplifiedQuota: (target: QuotaWorkflowTarget) => Promise<boolean>;
  approve: (targets: WorkflowTarget[]) => Promise<boolean>;
  yieldQuotaAllocationsVerif(): Promise<boolean>;
  yieldQuotaEmissionsVerif(): Promise<boolean>;
  updateQuotaAllocationsVerif: (
    newQuotaVerif: QuotasAllocationsBlocVerificationDto20Now
  ) => Promise<boolean>;
  updateQuotaEmissionsVerif: (
    newQuotaVerif: QuotasBlocVerificationEmissionsDto20Now
  ) => Promise<boolean>;
  start: (newActiviteType: TypeActiviteDto2020) => Promise<boolean>;
  reset: () => Promise<boolean>;
};

export const useDeclaration2020 = (
  shouldThrowIfWrongYear?: boolean
): Declaration2020 => {
  return useDeclaration<Declaration2020>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationState2020 = (): DeclarationState => {
  const getDeclarationState = useCallback(
    (declarationDto: Declaration2020) =>
      declarationDto.body.workflowStatus.general.state,
    []
  );

  return useDeclarationState<Declaration2020>(
    getDeclarationState,
    MIN_YEAR,
    MAX_YEAR
  );
};

export const useQuotaAllocationsState2020 = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration2020) =>
      declarationDto.body.workflowStatus.quotaAllocations.state,
    []
  );

  return useQuotasState<Declaration2020>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useQuotaEmissionsState2020 = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration2020) =>
      declarationDto.body.workflowStatus.quotaEmissions.state,
    []
  );

  return useQuotasState<Declaration2020>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useDeclarationHelpers2020 = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers2020 = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration2020> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration2020): DeclarationSectionsDto2020 =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration2020>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationStateHandlers2020 = (): DeclarationStateHandlers2020 => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const approve = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.approveDeclaration20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageApproveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteDto2020
    ): Promise<Declaration2020> => {
      // the as is justified because the back returns AbstractDeclarationsDto, and so far there is only one route.
      return declarationController.setTypeActivite2020UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateQuotaAllocationsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasAllocationsBlocVerificationDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration2020 = await declarationController.setVerificationAllocations20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const updateQuotaEmissionsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasBlocVerificationEmissionsDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration2020 = await declarationController.setVerificationEmissions20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const submitSimplifiedQuotaHandler = useCallback(
    async (target: QuotaWorkflowTarget): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.submitSimplifiedQuotas2020UsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          [target]
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backSubmitSimplifiedQuotas));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const yieldQuotaAllocationsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_ALLOCATIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const yieldQuotaEmissionsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_EMISSIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const start = useCallback(
    async (newActiviteType: TypeActiviteDto2020): Promise<boolean> => {
      let returnedResult: Declaration2020;
      try {
        returnedResult = await updateTypeActivite(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        //as justified because we threw at the beginning in case the year passed didn't match the declaration given, and
        // caller is responsible for passing year and type parameter in a sensible way
        returnedResult = await declarationController.startDeclaration2020UsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale, updateTypeActivite]
  );

  const reset = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetDeclaration2020UsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const stateHandlers18Now = useDeclarationStateHandlers(MIN_YEAR, MAX_YEAR);

  return {
    ...stateHandlers18Now,
    submitSimplifiedQuota: submitSimplifiedQuotaHandler,
    approve,
    yieldQuotaAllocationsVerif,
    yieldQuotaEmissionsVerif,
    updateQuotaAllocationsVerif,
    updateQuotaEmissionsVerif,
    start,
    reset,
  };
};

export const useUpdateDeclarationTypeActivite2020 = (): ((
  newActiviteType: TypeActiviteDto2020
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteDto2020
    ): Promise<Declaration2020> => {
      return declarationController.setTypeActivite2020UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  return useUpdateDeclarationTypeActivite<TypeActiviteDto2020, Declaration2020>(
    updateTypeActivite,
    MIN_YEAR,
    MAX_YEAR
  );
};
