import React, { ReactChild, ReactNode } from "react";
import SimpleInputDisplayer from "./SimpleInputDisplayer";
import BasicLabel from "../label/BasicLabel";
import { CommonProps, ErrorDataProps } from "common/form/utils";

interface FieldWrapperWithTextLabelProps {
  label: string | ((classname: string) => ReactChild);
  name: string;
  renderInput: (id: string) => React.ReactElement;
  commonProps: CommonProps;
  errorData: ErrorDataProps;
  disabled?: boolean;
  hideLabelComponentIfEmptyText?: boolean;
  tooltipContent?: ReactNode;
}

const SimpleInputWithTextLabelDisplayer = ({
  label,
  name,
  renderInput,
  commonProps,
  errorData,
  disabled,
  hideLabelComponentIfEmptyText,
  tooltipContent,
}: FieldWrapperWithTextLabelProps): React.ReactElement => {
  return (
    <SimpleInputDisplayer
      renderLabel={id => (
        <BasicLabel
          content={label}
          htmlFor={id}
          width={commonProps.labelWidth}
          disabled={disabled}
          hideIfEmpty={hideLabelComponentIfEmptyText}
          tooltipContent={tooltipContent}
        />
      )}
      name={name}
      renderInput={renderInput}
      commonProps={commonProps}
      errorData={errorData}
    />
  );
};

export default SimpleInputWithTextLabelDisplayer;
