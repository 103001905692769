import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { AlcDto20Now, QuotasInstallationDto20Now } from "api/gen";
import FilesLoader from "common/FilesLoader/FilesLoader";
import {
  useAlcPreliminaireFileHandler2122,
  useDeclaration2122,
} from "pages/CompanySpace/from21/to22/versionedElements/declarationHooks2122";
import { oneFileRelatedToNimToFileDto } from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/utils";

interface AlcUploaderProps {
  installation: QuotasInstallationDto20Now;
  filesPath: string;
  shouldDisableFields: boolean;
}

const AlcRapportFileLoader = ({
  installation,
  filesPath,
  shouldDisableFields,
}: AlcUploaderProps): React.ReactElement => {
  const declaration = useDeclaration2122();
  const openAlertModale = useAlertModale();
  const alcPreliminaireFileHandler = useAlcPreliminaireFileHandler2122(
    installation.id
  );

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE RAPPORT ALC PRELIMINAIRE"}
      filesPath={filesPath}
      section={FileSectionEnum.QUOTAS_RAPPORT_ALC_PRELIMINAIRE}
      fileHandlers={alcPreliminaireFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      max={1}
      transformFunction={(alcs: Record<string, unknown>[]) =>
        oneFileRelatedToNimToFileDto(
          installation.id,
          (alcs as unknown) as AlcDto20Now[]
        )
      }
    />
  );
};

export default AlcRapportFileLoader;
