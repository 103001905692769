import { FileDto } from "api/gen";

export type ExtensionObj =
  | { state: "disabled" }
  | { state: "loading" }
  | { state: "failed" }
  | { state: "success"; result: string[] };

export type ExtensionAction =
  | { type: "launchLoad" }
  | { type: "loadResult"; backResult: string[] | null };

export enum FileSectionEnum {
  "DOCUMENTS_DIVERS" = "DOCUMENTS_DIVERS",
  "SOLVANT_PLAN_GESTION" = "SOLVANT_PLAN_GESTION",
  "ELEVAGE_FEUILLE_CALCUL" = "ELEVAGE_FEUILLE_CALCUL",
  "QUOTAS_PLAN_SURVEILLANCE" = "QUOTAS_PLAN_SURVEILLANCE",
  "QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE" = "QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE",
  "QUOTAS_RAPPORT_EMISSIONS" = "QUOTAS_RAPPORT_EMISSIONS",
  "QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS" = "QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS",
  "QUOTAS_RAPPORT_VERIFICATION" = "QUOTAS_RAPPORT_VERIFICATION",
  "QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS" = "QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS",
  "QUOTAS_RAPPORT_VERIFICATION_EMISSIONS" = "QUOTAS_RAPPORT_VERIFICATION_EMISSIONS",
  "QUOTAS_RAPPORT_DECLARATION_D_OFFICE" = "QUOTAS_RAPPORT_DECLARATION_D_OFFICE",
  "QUOTAS_RAPPORT_ALC_PRELIMINAIRE" = "QUOTAS_RAPPORT_ALC_PRELIMINAIRE",
  "QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE" = "QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE",
  "QUOTAS_RAPPORT_ALC_VERIFIE" = "QUOTAS_RAPPORT_ALC_VERIFIE",
  "QUOTAS_JUSTIFICATIFS_ALC_VERIFIE" = "QUOTAS_JUSTIFICATIFS_ALC_VERIFIE",
  "QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE" = "QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE",
  "QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS" = "QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS",
  "QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE" = "QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE",
  "QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE" = "QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE",
}

export enum FILE_STATE {
  FILE_UPLOADING,
  FILE_UPLOADED,
  FILE_DELETING,
}
export interface HandledFile {
  localFile: File | null;
  uploadedFile: FileDto | null;
  state: FILE_STATE;
}

export interface FileHandlers {
  uploadHandlerWithEvent: (file: File) => Promise<boolean | null>;
  deleteHandlerWithEvent: (fileDto: FileDto) => Promise<boolean | null>;
}
