import { OptionProps } from "common/form/fields/types/basicTypes";

export const natures: OptionProps[] = [
  { value: 1, label: "BR-Briques" },
  { value: 2, label: "BE-Béton" },
  { value: 3, label: "CI-Ciment" },
  { value: 4, label: "FI-Fines" },
  { value: 5, label: "GR-Granulats" },
  { value: 6, label: "PA-Pavés" },
  { value: 7, label: "PL-Plâtre" },
  { value: 8, label: "TE-Terres" },
  { value: 9, label: "TU-Tuiles" },
  { value: 10, label: "AU-Autre" },
];
