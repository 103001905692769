import { AirElevageDetailNh320NowEspecesDeclareesEnum } from "api/gen";
import { commonArrayOfMixedFields } from "common/declarant/formik/formikHelper";
import {
  elevageAtLeastOneEmissionMessage,
  elevageUniqueBuildingMessage,
  positiveMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { isBlankString } from "common/declarant/formik/formikValidationHelper";
import { Errors } from "common/form/utils";
import { getValueOrZero } from "common/utils/numberUtils";
import * as Yup from "yup";
import { BuildingInArray, BuildingInModale } from "../../utils/types";
import { computeKeysOfSelectedSpecies } from "./utils";

export const specieValidationSchema = Yup.object().shape({
  species: commonArrayOfMixedFields<
    AirElevageDetailNh320NowEspecesDeclareesEnum
  >(),
});

export const validateNh3Emissions = (
  values: BuildingInModale,
  buildingInModal: BuildingInArray | null,
  buildingInPage: BuildingInArray[],
  species: AirElevageDetailNh320NowEspecesDeclareesEnum[]
): Errors<BuildingInModale> => {
  const errors: Errors<BuildingInModale> = {};

  // Check if the building name is not empty or already used
  if (isBlankString(values.name)) {
    errors.name = requiredMessage;
  } else if (
    buildingInPage.some(b => b.data.name === values.name) &&
    (!buildingInModal || buildingInModal.data.name !== values.name)
  ) {
    errors.name = elevageUniqueBuildingMessage;
  }

  // Check on the NH3 emission fields
  const selectedSpeciesField = computeKeysOfSelectedSpecies(species);
  if (
    !selectedSpeciesField.some(field => getValueOrZero(values[field]) !== 0)
  ) {
    selectedSpeciesField.forEach(field => {
      errors[field] = elevageAtLeastOneEmissionMessage;
    });
  } else {
    selectedSpeciesField.forEach(field => {
      if (getValueOrZero(values[field]) < 0) {
        errors[field] = positiveMessage;
      }
    });
  }

  return errors;
};
