import * as Yup from "yup";
import {
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveNumberFields,
} from "common/declarant/formik/formikHelper";

export const validationSchema = Yup.object().shape({
  authorizedArea: commonStrictlyPositiveNumberFields,
  remainingArea: commonPositiveNumberFields,
  exploitedThisYearArea: commonPositiveNumberFields,
  restoredArea: commonPositiveNumberFieldsNullable,
});
