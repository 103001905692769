import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_GREY } from "theme";
import Button from "common/button";
import AlternativeEmissionsArray from "./AlternativeEmissionsArray";
import AlternativeEmissionsModal from "./AlternativeEmissionsModal";
import { AlternativeEmissionInArray } from "../types";
import { QuotasInstallationDto20Now } from "api/gen";

interface AlternativeMethodProps {
  shouldDisabledFields: boolean;
  alternativeEmissionsInArray: AlternativeEmissionInArray[];
  setAlternativeEmissionsInArray: (
    callback: (
      previousEmissions: AlternativeEmissionInArray[]
    ) => AlternativeEmissionInArray[]
  ) => void;
  installations: QuotasInstallationDto20Now[];
}

const useStyles = makeStyles({
  header: {
    color: SECTION_TITLE_GREY,
    fontWeight: "bold",
    marginBottom: "30px",
  },
  subHeader: {
    color: SECTION_TITLE_GREY,
    marginLeft: "10px",
    marginBottom: "20px",
  },
  insertButton: {
    marginLeft: "80%",
    padding: "5px",
  },
});

const AlternativeMethod = ({
  shouldDisabledFields,
  alternativeEmissionsInArray,
  setAlternativeEmissionsInArray,
  installations,
}: AlternativeMethodProps): React.ReactElement => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    alternativeEmissionInModal,
    setAlternativeEmissionInModal,
  ] = useState<AlternativeEmissionInArray | null>(null);

  return (
    <>
      <h3 className={classes.header}>Méthode alternative</h3>

      <h4 className={classes.subHeader}>
        Émissions déterminées par méthode alternative
      </h4>

      <Button
        text="AJOUTER UNE ÉMISSION"
        additionalClassname={classes.insertButton}
        onClick={() => setIsModalOpen(true)}
        isDisabled={shouldDisabledFields}
      />

      <AlternativeEmissionsArray
        isDisabled={shouldDisabledFields}
        onEdit={emission => {
          setAlternativeEmissionInModal(emission);
          setIsModalOpen(true);
        }}
        alternativeEmissionsInArray={alternativeEmissionsInArray}
        setAlternativeEmissionsInArray={setAlternativeEmissionsInArray}
      />

      <AlternativeEmissionsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        setAlternativeEmissionsInArray={setAlternativeEmissionsInArray}
        alternativeEmissionInModal={alternativeEmissionInModal}
        setAlternativeEmissionInModal={setAlternativeEmissionInModal}
        installations={installations}
      />
    </>
  );
};

export default AlternativeMethod;
