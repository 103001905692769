import React from "react";
import { makeStyles } from "@material-ui/styles";
import DummyCheckBox from "common/form/fields/dumbInput/DummyCheckBox";
import SimpleInputWithTextLabelDisplayer from "common/form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { areActionsAllowedInUserContext } from "common/utils/methods";
import { useUserData } from "Authenticator/UserData";
import {
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
} from "../../../versionedElements/declarationHooks2020";
import Spinner from "common/presentational/Spinner";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useStatusCampaignCurrentWorkflow,
  wrapWithWorkflowTargetProvider,
} from "../../../../../WorkflowTargetProviderContext";

const useStyles = makeStyles({
  container: {
    margin: "auto",
    width: "100%",
  },
  inputField: {
    width: "100%",
    display: "flex",
  },
  alignLeft: {
    justifyContent: "start",
  },
});

const NothingToDeclare = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const { update } = useBasicDeclarationHandlers2020();
  const userData = useUserData();
  const currentCampaignStatus = useStatusCampaignCurrentWorkflow();

  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "rien-a-declarer",
  };

  const onChange = (value: boolean) => {
    triggerSpinner(
      update(declaration => {
        declaration.body.sections.rienADeclarer = value;
        return declaration;
      })
    );
  };

  const { typeActivite } = declaration.body;

  const isVisible =
    !typeActivite.estSousQuotaCo2 &&
    !typeActivite.elevage &&
    !typeActivite.combustion20MW &&
    !typeActivite.recepDechet &&
    !typeActivite.estConsommateurSolvant &&
    !typeActivite.carriere;
  const isDisabled = !areActionsAllowedInUserContext(
    userData,
    declaration.body.workflowStatus.general.state,
    currentCampaignStatus,
    declaration.etablissementCode,
    true,
    false,
    false,
    false
  );

  return isVisible ? (
    <div className={classes.container}>
      <SimpleInputWithTextLabelDisplayer
        label="Rien à déclarer"
        name="rienADeclarer"
        errorData={{}}
        commonProps={commonProps}
        disabled={isDisabled}
        renderInput={id => (
          <div className={classes.alignLeft}>
            {isSpinnerVisible ? (
              <Spinner />
            ) : (
              <DummyCheckBox
                id={id}
                name="rienADeclarer"
                error={null}
                value={declaration.body.sections.rienADeclarer}
                onChange={onChange}
                disabled={isDisabled}
              />
            )}
          </div>
        )}
      />
    </div>
  ) : (
    <></>
  );
};

export default wrapWithWorkflowTargetProvider(NothingToDeclare, "GLOBAL");
