import { Nullable } from "common/utils/types";
import { DeclarationWasteFormData } from "../types";
import { computeRefMethodForMethod } from "../utils/Referentiels";
import {
  dechetsNotSurSite,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";
import { DechetProduitDto1819LieuOperationEnum } from "api/gen";

export const isDepartementAccessibleForProduction = (
  values: Nullable<DeclarationWasteFormData> | Partial<DeclarationWasteFormData>
): boolean => {
  if (values.location) {
    return values.location.isDepartmentAvailable;
  }

  return false;
};
export const isCountryAccessibleForProduction = (
  values: Nullable<DeclarationWasteFormData> | Partial<DeclarationWasteFormData>
): boolean => {
  if (values.location) {
    return values.location.isCountryAvailable;
  }

  return false;
};
export const isReceptionFacilityAccessibleForProduction = (
  values: Nullable<DeclarationWasteFormData> | Partial<DeclarationWasteFormData>
): boolean => {
  if (values.location) {
    return (
      values.location.isDepartmentAvailable ||
      values.location.isCountryAvailable
    );
  }

  return false;
};
export const isTreatmentFacilityAccessibleForProduction = (
  values: Nullable<DeclarationWasteFormData> | Partial<DeclarationWasteFormData>
): boolean => {
  if (values.location) {
    return values.location.isCountryAvailable;
  }

  return false;
};
export const isNotificationNumberAccessibleForProduction = (
  values: Nullable<DeclarationWasteFormData> | Partial<DeclarationWasteFormData>
): boolean => {
  return isCountryAccessibleForProduction(values);
};

export const generateErrorsInModal = (
  values:
    | Nullable<DeclarationWasteFormData>
    | Partial<DeclarationWasteFormData>,
  isOnSiteOptionsAvailable: boolean
): Errors<DeclarationWasteFormData> => {
  const errors: Errors<DeclarationWasteFormData> = {};
  if (typeof values.wasteId !== "object" || values.wasteId === null) {
    errors.wasteId = requiredMessage;
  }

  if (!values.method) {
    errors.method = requiredMessage;
  } else {
    const possibleMethodRed = computeRefMethodForMethod(values.method);
    if (
      possibleMethodRed.length > 0 &&
      (!values.methorRef || !possibleMethodRed.includes(values.methorRef))
    ) {
      errors.methorRef = requiredMessage;
    }
  }

  if (!values.operation) {
    errors.operation = requiredMessage;
  }

  if (!values.location) {
    errors.location = requiredMessage;
  } else if (
    !isOnSiteOptionsAvailable &&
    values.location.backCode === DechetProduitDto1819LieuOperationEnum.SITE
  ) {
    errors.location = dechetsNotSurSite;
  }

  if (
    isDepartementAccessibleForProduction(values) &&
    (typeof values.departement !== "object" || values.departement === null)
  ) {
    errors.departement = requiredMessage;
  }

  if (
    isCountryAccessibleForProduction(values) &&
    (typeof values.pays !== "object" || values.pays === null)
  ) {
    errors.pays = requiredMessage;
  }

  if (isReceptionFacilityAccessibleForProduction(values)) {
    if (!values.receptionName) {
      errors.receptionName = requiredMessage;
    }
    if (!values.receptionAdress) {
      errors.receptionAdress = requiredMessage;
    }
  }

  if (isTreatmentFacilityAccessibleForProduction(values)) {
    if (!values.treatmentName) {
      errors.treatmentName = requiredMessage;
    }
    if (!values.treatmentAdress) {
      errors.treatmentAdress = requiredMessage;
    }
  }

  return errors;
};
