import React, { Dispatch, SetStateAction } from "react";
import _ from "lodash";
import { ClassesType } from "common/utils/types";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { computeNumberWithSeparatorAndVariableDecimal } from "common/utils/numberUtils";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { EmissionsInArray, MesureEmissionInArray } from "../utils/types";
import { computeBiomasseTotal } from "../../../combustionProcedeUtils/biomasse/helpers";
import { createProcedeDiv } from "../../utils/utils";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import {
  arrayFugitivesTypeMesureLabel,
  unitConcentrationMoyLabels,
} from "../../utils/selectPossibleUnits";
import { computeMesureEmissionsAnnuelles } from "../utils/calculus";

const NUMBER_DECIMAL_INF_ONE = 12;
const NUMBER_DECIMAL_SUP_ONE = 4;

function suppressEmission(
  singleEmissionInPage: MesureEmissionInArray,
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>
) {
  setMesureEmissionsInPage(elderEmissions => {
    return elderEmissions.filter(elderEmissionInPage => {
      return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
    });
  });
}

// a method to compute lines that takes as param any action or data that needs to be deduced from another data
const dummyComputeSingleEmissionLine = (
  emission: MesureEmissionInArray,
  emissionAnnuelle: number | null,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  editAction: () => void,
  suppressAction: () => void,
  substanceName: string | null,
  isValidated: boolean,
  validationMesuresPath: string,
  pathSuffix: string,
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const firstCellContent = computeFirstLineContentCell(
    editAction,
    substanceName || undefined,
    isValidated,
    !_.isEmpty(emission.errors)
  );

  return [
    computeWrappedCellWithError(firstCellContent, classes),
    computeWrappedCellWithError(
      emission.data.procedes &&
        createProcedeDiv(emission.data.procedes, procedesInPageMap),
      classes
    ),
    computeWrappedCellWithError(
      <p>
        {emission.data.typeMesure &&
          arrayFugitivesTypeMesureLabel[emission.data.typeMesure]}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.debitHoraire,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.heures,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      emission.data.debit && emission.data.debit.continu ? "Oui" : "Non",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.debit !== null
        ? computeNumberWithSeparatorAndVariableDecimal(
            emission.data.debit.frequence,
            NUMBER_DECIMAL_INF_ONE,
            NUMBER_DECIMAL_SUP_ONE
          )
        : "",
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.concentrationMoy,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      <p>
        {emission.data.concentrationMoyUnite &&
          unitConcentrationMoyLabels[emission.data.concentrationMoyUnite]}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      emission.data.concentration && emission.data.concentration.continu
        ? "Oui"
        : "Non",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.concentration
        ? computeNumberWithSeparatorAndVariableDecimal(
            emission.data.concentration.frequence
          )
        : "",
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(emissionAnnuelle),
      classes
    ),
    computeWrappedCellWithError(
      emission.data.epuration ? "Oui" : "Non",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.natureEquipement || ""}>
        {emission.data.natureEquipement}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(emission.data.rendement),
      classes
    ),
    <CommonEntityButton
      handlerEdit={editAction}
      handlerSuppress={() => {
        suppressEmission(emission, setMesureEmissionsInPage);
      }}
      suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
      isValidated={isValidated}
      commentPath={`${validationMesuresPath}/${pathSuffix}`}
    />,
  ];
};

// method to compute the lines for a "standard" emission. Handles the actions and data that is "deduced" from the base emission given
export const computeSingleEmissionLine = (
  emission: MesureEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>,
  isValidated: boolean,
  validationMesuresPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMesureEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const emissionAnnuelle = computeMesureEmissionsAnnuelles(emission.data);

  return dummyComputeSingleEmissionLine(
    emission,
    emissionAnnuelle,
    procedesInPageMap,
    classes,
    editAction,
    suppressAction,
    substanceName,
    isValidated,
    validationMesuresPath,
    emission.data.id,
    setMesureEmissionsInPage,
    computeFirstLineContentCell
  );
};

// method to compute the lines for a co2 emission. Handles the duplication of line in 2,
// and the different name in first cell, as well as the different total. Also creates the
// actions and data that is "deduced" from the base emission given
export const computeCo2EmissionLine = (
  emission: MesureEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>,
  isValidated: boolean,
  validationMesuresPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[][] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMesureEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const emissionAnnuelle = computeMesureEmissionsAnnuelles(emission.data);

  const {
    emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse,
  } = computeBiomasseTotal(emissionAnnuelle, emission.data.biomasse);

  return [
    dummyComputeSingleEmissionLine(
      emission,
      emissionAnnuellesBiomasse,
      procedesInPageMap,
      classes,
      editAction,
      suppressAction,
      `${substanceName} biomasse`,
      isValidated,
      validationMesuresPath,
      emission.data.id + "/biomasse",
      setMesureEmissionsInPage,
      computeFirstLineContentCell
    ),
    dummyComputeSingleEmissionLine(
      emission,
      emissionAnnuellesNonBiomasse,
      procedesInPageMap,
      classes,
      editAction,
      suppressAction,
      `${substanceName} non biomasse`,
      isValidated,
      validationMesuresPath,
      emission.data.id + "/nonBiomasse",
      setMesureEmissionsInPage,
      computeFirstLineContentCell
    ),
  ];
};
