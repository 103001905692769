import React from "react";
import logoProcede from "icons/pageLogos/chemical.svg";
import MainProcedeForm from "./MainForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Procedes = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Procédés",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoProcede,
    alt: "un tube a essais",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Procédés / Émissions diffuses",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_POLLUANTS_AIR", "REFERENCE_CO2"]}
      render={referentials => {
        return (
          <MainProcedeForm
            referential={referentials.REFERENCE_POLLUANTS_AIR}
            referentialCO2={referentials.REFERENCE_CO2}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Procedes, "GLOBAL");
