import {
  AirElevageDetailNh31819EspecesDeclareesEnum,
  AirElevageNh3BatimentDto1819,
} from "api/gen";
import { PopulatedBuildingInArray } from "../../utils/types";
import { selectPossibleValues } from "./selectPossibleValues";
import { computeKeysOfUnselectedSpecies } from "./computers";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const convertSpecieEnumToOptionProps = (
  species: AirElevageDetailNh31819EspecesDeclareesEnum[]
): CleanOptionProps[] => {
  return species.map(specie => {
    switch (specie) {
      case AirElevageDetailNh31819EspecesDeclareesEnum.CAILLES:
        return selectPossibleValues.speciesDropdown[0];

      case AirElevageDetailNh31819EspecesDeclareesEnum.CANARDS:
        return selectPossibleValues.speciesDropdown[1];

      case AirElevageDetailNh31819EspecesDeclareesEnum.DINDES_ET_DINDONS:
        return selectPossibleValues.speciesDropdown[2];

      case AirElevageDetailNh31819EspecesDeclareesEnum.PINTADES:
        return selectPossibleValues.speciesDropdown[3];

      case AirElevageDetailNh31819EspecesDeclareesEnum.POULES_PONDEUSES:
        return selectPossibleValues.speciesDropdown[4];

      case AirElevageDetailNh31819EspecesDeclareesEnum.POULETS_DE_CHAIR:
        return selectPossibleValues.speciesDropdown[5];

      case AirElevageDetailNh31819EspecesDeclareesEnum.POULETTES:
        return selectPossibleValues.speciesDropdown[6];

      case AirElevageDetailNh31819EspecesDeclareesEnum.AUTRES_VOLAILLES:
        return selectPossibleValues.speciesDropdown[7];

      case AirElevageDetailNh31819EspecesDeclareesEnum.PORCELETS_EN_POST_SEVRAGE:
        return selectPossibleValues.speciesDropdown[8];

      case AirElevageDetailNh31819EspecesDeclareesEnum.PORCS_DE_PRODUCTION:
        return selectPossibleValues.speciesDropdown[9];

      case AirElevageDetailNh31819EspecesDeclareesEnum.COCHETTES:
        return selectPossibleValues.speciesDropdown[10];

      case AirElevageDetailNh31819EspecesDeclareesEnum.TRUIES_EN_MATERNITE:
        return selectPossibleValues.speciesDropdown[11];

      case AirElevageDetailNh31819EspecesDeclareesEnum.TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES:
        return selectPossibleValues.speciesDropdown[12];

      case AirElevageDetailNh31819EspecesDeclareesEnum.VERRATS:
        return selectPossibleValues.speciesDropdown[13];

      default:
        throw new ShouldNotHappen(specie);
    }
  });
};

export const convertSpecieOptionPropsToEnum = (
  species: CleanOptionProps[]
): AirElevageDetailNh31819EspecesDeclareesEnum[] => {
  return species.map(specie => {
    switch (specie.value) {
      case 1:
        return AirElevageDetailNh31819EspecesDeclareesEnum.CAILLES;

      case 2:
        return AirElevageDetailNh31819EspecesDeclareesEnum.CANARDS;

      case 3:
        return AirElevageDetailNh31819EspecesDeclareesEnum.DINDES_ET_DINDONS;

      case 4:
        return AirElevageDetailNh31819EspecesDeclareesEnum.PINTADES;

      case 5:
        return AirElevageDetailNh31819EspecesDeclareesEnum.POULES_PONDEUSES;

      case 6:
        return AirElevageDetailNh31819EspecesDeclareesEnum.POULETS_DE_CHAIR;

      case 7:
        return AirElevageDetailNh31819EspecesDeclareesEnum.POULETTES;

      case 8:
        return AirElevageDetailNh31819EspecesDeclareesEnum.AUTRES_VOLAILLES;

      case 9:
        return AirElevageDetailNh31819EspecesDeclareesEnum.PORCELETS_EN_POST_SEVRAGE;

      case 10:
        return AirElevageDetailNh31819EspecesDeclareesEnum.PORCS_DE_PRODUCTION;

      case 11:
        return AirElevageDetailNh31819EspecesDeclareesEnum.COCHETTES;

      case 12:
        return AirElevageDetailNh31819EspecesDeclareesEnum.TRUIES_EN_MATERNITE;

      case 13:
        return AirElevageDetailNh31819EspecesDeclareesEnum.TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES;

      case 14:
        return AirElevageDetailNh31819EspecesDeclareesEnum.VERRATS;

      default:
        throw Error(
          `Error converting ${specie} to AirElevageDetailNh31819EspecesDeclareesEnum`
        );
    }
  });
};

export const convertBuildingDtoToPopulatedBuilding = (
  arrayBuildingDto: AirElevageNh3BatimentDto1819[]
): PopulatedBuildingInArray[] => {
  return arrayBuildingDto.map(singleBuildingDto => {
    const singlePopulatedBuilding: PopulatedBuildingInArray = {
      id: singleBuildingDto.id,
      displayedBuilding: {
        buildingName: singleBuildingDto.name,
        caillesEmission: singleBuildingDto.caillesEmission,
        canardsEmission: singleBuildingDto.canardsEmission,
        cochettesEmission: singleBuildingDto.cochettesEmission,
        pouletsDeChairEmission: singleBuildingDto.pouletsDeChairEmission,
        pintadesEmission: singleBuildingDto.pintadesEmission,
        porceletsEnPostSevrageEmission:
          singleBuildingDto.porceletsEnPostSevrageEmission,
        porcsDeProductionEmission: singleBuildingDto.porcsDeProductionEmission,
        poulesPondeusesEmission: singleBuildingDto.poulesPondeusesEmission,
        poulettesEmission: singleBuildingDto.poulettesEmission,
        dindesDindonsEmission: singleBuildingDto.dindesDindonsEmission,
        truiesEnAttenteSaillieEtGestantesEmission:
          singleBuildingDto.truiesEnAttenteSaillieEtGestantesEmission,
        truiesEnMaterniteEmission: singleBuildingDto.truiesEnMaterniteEmission,
        autresVolaillesEmission: singleBuildingDto.autresVolaillesEmission,
        verratsEmission: singleBuildingDto.verratsEmission,
      },
    };
    return singlePopulatedBuilding;
  });
};

export const convertPopulatedBuildingToBuildingDto = (
  arrayPopulatedBuilding: PopulatedBuildingInArray[],
  selectedSpecies: CleanOptionProps[]
): AirElevageNh3BatimentDto1819[] => {
  const keysOfUnselectedSpecies = computeKeysOfUnselectedSpecies(
    selectedSpecies
  );
  return arrayPopulatedBuilding.map(singlePopulatedBuilding => {
    keysOfUnselectedSpecies.forEach(
      key => (singlePopulatedBuilding.displayedBuilding[key] = 0)
    );

    const singleBuildingDto: AirElevageNh3BatimentDto1819 = {
      id: singlePopulatedBuilding.id,
      name: singlePopulatedBuilding.displayedBuilding.buildingName,
      caillesEmission:
        singlePopulatedBuilding.displayedBuilding.caillesEmission,
      canardsEmission:
        singlePopulatedBuilding.displayedBuilding.canardsEmission,
      cochettesEmission:
        singlePopulatedBuilding.displayedBuilding.cochettesEmission,
      pouletsDeChairEmission:
        singlePopulatedBuilding.displayedBuilding.pouletsDeChairEmission,
      pintadesEmission:
        singlePopulatedBuilding.displayedBuilding.pintadesEmission,
      porceletsEnPostSevrageEmission:
        singlePopulatedBuilding.displayedBuilding
          .porceletsEnPostSevrageEmission,
      porcsDeProductionEmission:
        singlePopulatedBuilding.displayedBuilding.porcsDeProductionEmission,
      poulesPondeusesEmission:
        singlePopulatedBuilding.displayedBuilding.poulesPondeusesEmission,
      poulettesEmission:
        singlePopulatedBuilding.displayedBuilding.poulettesEmission,
      dindesDindonsEmission:
        singlePopulatedBuilding.displayedBuilding.dindesDindonsEmission,
      truiesEnAttenteSaillieEtGestantesEmission:
        singlePopulatedBuilding.displayedBuilding
          .truiesEnAttenteSaillieEtGestantesEmission,
      truiesEnMaterniteEmission:
        singlePopulatedBuilding.displayedBuilding.truiesEnMaterniteEmission,
      autresVolaillesEmission:
        singlePopulatedBuilding.displayedBuilding.autresVolaillesEmission,
      verratsEmission:
        singlePopulatedBuilding.displayedBuilding.verratsEmission,
    };
    return singleBuildingDto;
  });
};
