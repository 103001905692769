import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { DISABLED_BUTTON_HOVER_BACKGROUND, SECTION_TITLE_GREY } from "theme";
import Row from "../../presentational/Row";
import Button from "../../button";
import {
  FormikErrors,
  FormikProps,
} from "libAdapter/Formik/TypesPatternAdaptater";
import { commonShouldDisableSubmitButton } from "./commonShouldDisableSubmitButton";

interface FormikFieldToucherProps<T> {
  formikProps: FormikProps<T>;
  closeFunction: () => void;
  isEdit: boolean;
  renderField: (props: FormikProps<T>) => React.ReactNode;
  validate?: (values: T) => void | object | Promise<FormikErrors<T>>;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancel: {
    color: SECTION_TITLE_GREY,
    width: "auto",
    boxShadow: "none",
    background: "none",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
  },
});

function FormikFieldToucher<T>({
  closeFunction,
  renderField,
  isEdit,
  formikProps,
}: FormikFieldToucherProps<T>): React.ReactElement {
  // we extract the values we need from FormikProps
  const {
    touched,
    handleSubmit,
    errors,
    validateForm,
    submitForm,
  } = formikProps;

  const classes = useStyles();

  // Hook to initially compute errors when on edit mode and force displaying errors by submitting (submit will fail since errors is not empty)
  useEffect(() => {
    if (isEdit) {
      validateForm().then(errors => {
        if (Object.keys(errors).length !== 0 && isEdit) {
          submitForm();
        }
      });
    }
  }, [isEdit, validateForm, submitForm]);

  return (
    <form onSubmit={handleSubmit}>
      {renderField(formikProps)}
      <Row
        additionalStyle={{
          justifyContent: "flex-end",
          marginTop: "5%",
        }}
      >
        <div className={classes.buttonContainer}>
          <Button
            text="ANNULER"
            type="button"
            additionalClassname={classes.cancel}
            onClick={() => closeFunction()}
          />
          <Button
            text="VALIDER"
            type="submit"
            isDisabled={commonShouldDisableSubmitButton({ errors, touched })}
          />
        </div>
      </Row>
    </form>
  );
}

export default FormikFieldToucher;
