import { QuotasInstallationDto20Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_STYLE } from "theme";
import Row from "common/presentational/Row";
import React from "react";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

interface NimFilesLoaderDisplayerProps {
  installation: QuotasInstallationDto20Now;
  commentPath?: string;
  children: React.ReactNode;
  justificatifLoader?: boolean;
}

const useStyles = makeStyles({
  twoColsChildren: {
    height: "auto",
    "&>div": {
      width: "50%",
    },
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    height: "auto",
  },
  ...SECTION_TITLE_STYLE,
});

const NimFilesLoaderDisplayer = ({
  installation,
  commentPath,
  children,
  justificatifLoader,
}: NimFilesLoaderDisplayerProps): React.ReactElement => {
  const classes = useStyles();
  const rowClasses = justificatifLoader
    ? classes.flexRow
    : classes.twoColsChildren;

  return (
    <>
      <Row additionalClassname={rowClasses} key={installation.id}>
        <div className={classes.sectionTitle}>
          NIM à remplir : {installation.nim}
          {commentPath && (
            <CommentButton path={`${commentPath}/${installation.id}`} />
          )}
        </div>
        {children}
      </Row>
    </>
  );
};

export default NimFilesLoaderDisplayer;
