import React from "react";
import RapportEmissionLoader from "./RapportEmissionLoader";
import { makeStyles } from "@material-ui/styles";
import { FILE_BUTTONS_STYLE, SECTION_TITLE_GREY } from "theme";
import { PATH_QUOTAS_EMISSIONS_BLOC } from "common/path/path20Now";
import Row from "common/presentational/Row";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import BlocFullContext21Now from "pages/CompanySpace/from21/toNow/versionedElements/BlocFullContext21Now";
import { useDeclaration2121 } from "../../../versionedElements/declarationHooks2121";
import NimFilesLoaderDisplayer from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/NimFilesLoaderDisplayer";
import JustificatifRapportLoader from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/JustificatifRapportLoader";
import RapportEmissionData from "./RapportData/RapportEmissionData";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  ...FILE_BUTTONS_STYLE,
});

const RapportEmissionsForm = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2121();
  const installations = declaration.body.typeActivite.quotas.installations;
  const rapportAerList =
    declaration.body.externalSections.quotas.emissions.blocEmissions
      .rapportEmissionsList;

  return (
    <BlocFullContext21Now
      hasModification={false}
      isValidateButtonAvailable={true}
      title={"DÉCLARATION DES ÉMISSIONS"}
      path={PATH_QUOTAS_EMISSIONS_BLOC}
      updateHandler={declaration => {
        return declaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          <div className={classes.text}>
            <p>À faire dans ce bloc :</p>
            <ul>
              <li>
                Télécharger si besoin le modèle du rapport d'émission annuel
                quotas,
              </li>
              <li>
                Déposer un rapport d'émission annuel quotas complété par numéro
                NIM.
              </li>
              <li>
                Veillez à bien compléter le tableau des méthodes de surveillance
                (onglet B cellule I44 à I49 du rapport AER), afin de permettre
                l'affichage des émissions associées dans le déversement.
              </li>
            </ul>
          </div>
          <Row height={"20px"} />
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODÈLE RAPPORT D'ÉMISSION (version du 16/12/2021)"
            isDisabled={shouldDisabledFields}
            fileName="RapportEmissions_211216.xls"
            additionalClassname={classes.fileButtons}
          />

          {installations.map(installation => (
            <>
              <NimFilesLoaderDisplayer
                installation={installation}
                justificatifLoader
              >
                <RapportEmissionLoader
                  installationId={installation.id}
                  shouldDisableFields={shouldDisabledFields}
                />
                <JustificatifRapportLoader
                  installationId={installation.id}
                  shouldDisableFields={shouldDisabledFields}
                />
              </NimFilesLoaderDisplayer>

              <Row height={"20px"} />
              <RapportEmissionData
                installation={installation}
                rapportAerList={rapportAerList}
              />
            </>
          ))}
        </>
      )}
    />
  );
};

export default RapportEmissionsForm;
