import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  FormikActions,
  FormikErrors,
  FormikProps,
} from "libAdapter/Formik/TypesPatternAdaptater";
import { Nullable } from "common/utils/types";
import { FIELD_FONT_SIZE, SECTION_TITLE_GREY } from "theme";
import FormikFieldToucher from "./formik/FormikFieldToucher";
import _ from "lodash";
import FormikAdapter from "libAdapter/Formik/FormikAdapter";

interface CommonFormSingleEntityProps<
  FinalType,
  InitialType extends FinalType = FinalType
> {
  title: string;
  closeFunction: () => void;

  onSubmit: (
    values: FinalType,
    formikActions: FormikActions<FinalType>
  ) => void;
  preventSubmitWithErrors?: (
    values: Nullable<InitialType>
  ) => FormikErrors<InitialType>;
  initialEntity: InitialType;

  isEdit: boolean;
  validationSchema?: any;
  renderField: (props: FormikProps<Nullable<InitialType>>) => React.ReactNode;
  validate?: (
    values: Nullable<InitialType>
  ) => void | object | Promise<FormikErrors<Nullable<InitialType>>>;
}

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: FIELD_FONT_SIZE,
    color: SECTION_TITLE_GREY,
    fontWeight: 400,
    marginBottom: "5%",
  },
});

//this component is used to have similar behavior across the different modal having a form for adding an entity :
// it creates an initial values object based on the initialEntity given AND the validation schema (taking fields asked by validation schema, and filling them with the initialEntity value if there is one, and null or boolean if it's not present, based on field type specified by validation schema
// it finally ensure the different modale use the FormikFieldToucher enabling a common touching behavior onLoad (touching the fields if there is an initial entity), and disabling the submit button if there is a displayed error.
function CommonFormSingleEntity<
  FinalType,
  InitialType extends FinalType = FinalType
>({
  closeFunction,
  onSubmit,
  validationSchema,
  renderField,
  initialEntity,
  title,
  isEdit,
  validate,
  preventSubmitWithErrors,
}: CommonFormSingleEntityProps<FinalType, InitialType>): React.ReactElement {
  const classes = useStyles();

  return (
    <>
      <h3 className={classes.title}>{title}</h3>
      <FormikAdapter
        onSubmit={(
          values: Nullable<InitialType>,
          formikActions: FormikActions<InitialType>
        ) => {
          if (preventSubmitWithErrors) {
            const additionalErrors = preventSubmitWithErrors(values);
            if (!_.isEqual(additionalErrors, {})) {
              formikActions.setErrors(additionalErrors);
              return;
            }
          }
          // the as coercion is because it should have NO nullable field unless the validation schema isn't done in the right way.
          onSubmit(
            values as FinalType,
            formikActions as FormikActions<FinalType>
          );
        }}
        initialValues={initialEntity}
        validationSchema={validationSchema}
        validate={validate}
        render={props => {
          return (
            <FormikFieldToucher
              formikProps={props}
              closeFunction={closeFunction}
              isEdit={isEdit}
              renderField={renderField}
            />
          );
        }}
      />
    </>
  );
}

export default CommonFormSingleEntity;
