import { cloneDeep } from "lodash";
import { CovInArray } from "../CovDanger/utils/types";
import { EmissionsInArray } from "../Emission/utils/types";
import { ProcedeInArray } from "../ListProcede/utils/types";
import { getProcedeName } from "./utils";
import { compareString } from "../../../../../../../common/utils/methods";

export const compareProcedeFunction = (
  a: ProcedeInArray,
  b: ProcedeInArray
): number => {
  return compareString(a.data.nom, b.data.nom);
};

export const compareCovFunction = (
  a: CovInArray,
  b: CovInArray,
  procedesInPageMap: Map<string, ProcedeInArray>
): number => {
  if (
    a.data.substance &&
    b.data.substance &&
    a.data.substance !== b.data.substance
  ) {
    return a.data.substance < b.data.substance ? -1 : 1;
  }

  if (
    a.data.procedes &&
    b.data.procedes &&
    a.data.procedes !== b.data.procedes
  ) {
    return compareProcedeIdsListByName(
      a.data.procedes,
      b.data.procedes,
      procedesInPageMap
    );
  }

  return 0;
};

export const compareEmissionFunction = (
  a: EmissionsInArray,
  b: EmissionsInArray,
  procedesInPageMap: Map<string, ProcedeInArray>
): number => {
  if (
    a.data.substance &&
    b.data.substance &&
    a.data.substance.nom !== b.data.substance.nom
  ) {
    return a.data.substance.nom < b.data.substance.nom ? -1 : 1;
  }

  if (
    a.data.procedes &&
    b.data.procedes &&
    a.data.procedes !== b.data.procedes
  ) {
    return compareProcedeIdsListByName(
      a.data.procedes,
      b.data.procedes,
      procedesInPageMap
    );
  }

  return 0;
};

/**
 * Compare two arrays of procede id with the name of the corresponding procedes
 * @param a array of procede id to compare to b
 * @param b array of procede id to compare to a
 * @param procedesInPageMap Map to get the corresponding procede name
 * @returns number that will be used to sort
 */
const compareProcedeIdsListByName = (
  a: string[],
  b: string[],
  procedesInPageMap: Map<string, ProcedeInArray>
): number => {
  // We make a deep copy of each array to avoid modifiying the arrays to compare with the method Array.Prototype.shif()
  const procedeIdsA = cloneDeep(a);
  const procedeIdsB = cloneDeep(b);

  // We retrieve the first element of each array
  const idA = procedeIdsA.shift();
  const idB = procedeIdsB.shift();

  // If one of them is undefined we can conclude because that means at least one of the array is empty
  if (idA === undefined && idB === undefined) {
    return 0;
  }

  if (idA === undefined) {
    return 1;
  }

  if (idB === undefined) {
    return -1;
  }

  let result = 0;

  // If idA !== idB we compute and compare the corresponding procede names
  if (idA !== idB) {
    const procedeNameA = getProcedeName(idA, procedesInPageMap);
    const procedeNameB = getProcedeName(idB, procedesInPageMap);

    result = compareString(procedeNameA, procedeNameB);
  }

  // If the first element of each array are the same we compare the following element of each array using recursivity
  return result === 0
    ? compareProcedeIdsListByName(procedeIdsA, procedeIdsB, procedesInPageMap)
    : result;
};
