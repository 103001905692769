import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PATH_ROOT } from "common/path/path18Now";
import { useDeclarationErrorAndWarnings2020 } from "../../../versionedElements/errorAndWarningDisplayer2020/utils";
import NothingToDeclare from "./NothingToDeclare";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  container: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  errorContainer: {
    margin: "auto",
    width: "500px",
  },
});

/**
 * For general fields not included in any section, or global error displaying (error related with path "/")
 * Currently only contains field "Rien à déclarer"
 */
const MainForm = (): React.ReactElement => {
  const classes = useStyles();
  const { errors, warnings } = useDeclarationErrorAndWarnings2020(
    PATH_ROOT,
    true,
    true
  );

  return (
    <div className={classes.container}>
      <NothingToDeclare />
      <div className={classes.errorContainer}>
        <ViolationProcessor errors={errors} warnings={warnings} />
      </div>
    </div>
  );
};

export default MainForm;
