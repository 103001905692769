import React from "react";
import * as Yup from "yup";
import {
  ComputedEmissionInArray,
  ComputedEmissionInModale,
  ComputedMethodOptions,
  FlowDeclarationProps,
  GasOptions,
} from "../types";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import _ from "lodash";
import {
  useChoiceSelectFieldGenerator,
  useDummyChoiceSelectFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { selectPossibleValues } from "../selectPossibleValues";
import uuid from "uuid";
import {
  commonNumberFields,
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";

interface ComputedEmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  setComputedEmissionInModal: (
    emission: ComputedEmissionInArray | null
  ) => void;
  computedEmissionInModal: ComputedEmissionInArray | null;
  flowDeclarations: FlowDeclarationProps[];
  allowedMethods: OptionPropsWithObject<ComputedMethodOptions>[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  method: commonObjectFields,
  flow: commonObjectFields,
  fossilEmission: Yup.number().when("method", {
    is: (method: OptionProps | null) =>
      !method || method.object !== ComputedMethodOptions.BilanMassique,
    then: commonPositiveNumberFields,
    otherwise: commonNumberFields,
  }),
  biomassEmission: Yup.number().when("method", {
    is: (method: OptionProps | null) =>
      !method || method.object !== ComputedMethodOptions.BilanMassique,
    then: commonPositiveNumberFields,
    otherwise: commonNumberFields,
  }),
});

const ComputedEmissionsModal = ({
  isOpen,
  setIsOpen,
  setComputedEmissionsInArray,
  setComputedEmissionInModal,
  computedEmissionInModal,
  flowDeclarations,
  allowedMethods,
}: ComputedEmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emissions-modale-calculees",
  };

  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const DummyChoiceSelect = useDummyChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);

  const gasOptions = selectPossibleValues.gas.filter(
    gas => gas.object === GasOptions.CO2
  );
  const flowOptions = flowDeclarations
    .filter(flow => flow.name)
    .map(flow => ({
      value: (flow.nim && flow.nim.label) || "",
      label: flow.name || "",
      object: flow.id,
    }));

  const onClose = () => {
    setComputedEmissionInModal(null);
    setIsOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UNE ÉMISSION"
        closeFunction={onClose}
        isEdit={computedEmissionInModal !== null}
        initialEntity={_.get(computedEmissionInModal, "data", {
          method: null,
          flow: null,
          gas: null,
          fossilEmission: null,
          biomassEmission: null,
          otherEmission: null,
        })}
        validationSchema={validationSchema}
        onSubmit={(values: ComputedEmissionInModale) => {
          const emission: ComputedEmissionInArray = {
            //TODO compute errors
            data: {
              id: computedEmissionInModal
                ? computedEmissionInModal.data.id
                : uuid(),
              method: values.method,
              flow: values.flow,
              gas: gasOptions[0],
              fossilEmission: values.fossilEmission,
              biomassEmission: values.biomassEmission,
              otherEmission: null,
            },
            errors: {},
          };
          setComputedEmissionsInArray(currentComputedEmissionsInArray => {
            const newComputedEmissionsInArray = _.cloneDeep(
              currentComputedEmissionsInArray
            );
            let indexToReplace = newComputedEmissionsInArray.length;
            if (computedEmissionInModal) {
              indexToReplace = _.findIndex(
                newComputedEmissionsInArray,
                computedEmissionInModal
              );
              setComputedEmissionInModal(null);
            }
            newComputedEmissionsInArray[indexToReplace] = emission;
            return newComputedEmissionsInArray;
          });
          setIsOpen(false);
        }}
        renderField={({ values }) => (
          <>
            <ChoiceSelect
              label="Méthode utilisée *"
              name="method"
              isMulti={false}
              options={allowedMethods}
            />

            <ChoiceSelect
              label="Nom du flux *"
              name="flow"
              isMulti={false}
              options={flowOptions}
            />

            <DummyTextField
              label="Numéro NIM"
              name="nim"
              disabled
              value={values.flow ? values.flow.value : ""}
            />

            <DummyChoiceSelect
              label="Gaz émis"
              name="gas"
              isMulti={false}
              options={gasOptions}
              value={gasOptions[0]}
              disabled
            />

            <NumberField
              label="Émission totale d'origine non biomasse *"
              name="fossilEmission"
              unit={computeUnitTCO2PerYear}
            />

            <NumberField
              label="Émission totale d'origine biomasse *"
              name="biomassEmission"
              unit={computeUnitTCO2PerYear}
            />
          </>
        )}
      />
    </CustomModal>
  );
};

export default ComputedEmissionsModal;
