import React from "react";
import { PopulatedRecyclingProductInArray } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import Button from "common/button";
import {
  computeFamiliesArrayCells,
  computeFamiliesArrayHeader,
} from "../Families/computers";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_PRODUCTION_RECYCLAGE } from "common/path/path18Now";
import { PRODUCTS_NATURE } from "./utils/languages";
import Row from "common/presentational/Row";

interface RecyclingProductArrayProps {
  recyclingProductsInArray: PopulatedRecyclingProductInArray[];
  setRecyclingProductsInArray: (
    products: PopulatedRecyclingProductInArray[]
  ) => void;
  onEdit: (product: PopulatedRecyclingProductInArray | undefined) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  table: {
    marginTop: "20px",
  },
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const RecyclingProductArray = ({
  recyclingProductsInArray,
  setRecyclingProductsInArray,
  onEdit,
  isDisabled,
}: RecyclingProductArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>{PRODUCTS_NATURE}</p>,
    ...computeFamiliesArrayHeader(),
    <p>Quantitée annuelle (ktonnes)</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = recyclingProductsInArray.map(
    product => {
      const render = [
        <p
          className={isDisabled ? classes.disabledLink : classes.link}
          onClick={() => {
            if (!isDisabled) {
              onEdit(product);
            }
          }}
        >
          {product.displayedProduct.nature.label}
        </p>,
      ];
      render.push(
        ...computeFamiliesArrayCells(
          product.displayedProduct.usageFamily,
          product.displayedProduct.usageSubFamiliesLvl2,
          product.displayedProduct.usageFamilyPrecision,
          product.displayedProduct.usageSubFamily,
          product.displayedProduct.usageSubFamilyPrecision,
          product.displayedProduct.usageSubFamiliesLvl2Precisions
        )
      );
      render.push(
        <p>
          {computeNumberWithSeparator(
            product.displayedProduct.productionQuantity || 0
          )}
        </p>
      );
      render.push(
        <CommonEntityButton
          handlerEdit={() => onEdit(product)}
          handlerSuppress={() =>
            setRecyclingProductsInArray(
              recyclingProductsInArray.filter(p => p.id !== product.id)
            )
          }
          commentPath={`${PATH_CARRIERE_PRODUCTION_RECYCLAGE}/${product.id}`}
          isValidated={isDisabled}
        />
      );
      return render;
    }
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredColWidths={[
          250,
          150,
          150,
          175,
          160,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
        additionalClassName={classes.table}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          onClick={() => setRecyclingProductsInArray([])}
          isReversed
          isDisabled={isDisabled}
        />
      </Row>
    </>
  );
};

export default RecyclingProductArray;
