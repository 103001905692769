import {
  AirCombustionCombustibleDto20NowUniteEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
} from "api/gen";

export interface H32info {
  uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum;
  convertionFactorCombustible: number;
  unitePci: AirCombustionCombustiblePciDtoUniteEnum | null;
  convertionFactorPci: number;
  operationMasseVolumique: "MULTIPLY" | "DIVIDE" | null;
  multiplyByPciAndEmissionFactor: boolean;
}

export const multiply = "MULTIPLY";
export const divide = "DIVIDE";

export const h32infos: H32info[] = [
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.T,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KG,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.HL,
    convertionFactorCombustible: 0.1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.L,
    convertionFactorCombustible: 0.001,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.K_M3,
    convertionFactorCombustible: 1000,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.M3,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.TEP,
    convertionFactorCombustible: 42,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GJ,
    convertionFactorCombustible: 1,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI,
    convertionFactorCombustible: 0.0036,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI,
    convertionFactorCombustible: 3.6,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI,
    convertionFactorCombustible: 3600,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS,
    convertionFactorCombustible: 0.0036,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS,
    convertionFactorCombustible: 3.6,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS,
    convertionFactorCombustible: 3600,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
];
