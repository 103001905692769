import {
  AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum,
  AirCombustionEmissionMesureDto24NowTypeMesureEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import * as Yup from "yup";
import { StringSchema } from "yup";
import {
  commonArrayOfObjectFields,
  commonBooleanFieldsNullable,
  commonMixedFields,
  commonNullableStringFields,
  commonNumberFieldsNullable,
  commonPercentageFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStringFields,
  MAX_NB_HOURS_YEAR,
  subFieldValidationMultipleANDScheme,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import {
  combustionInstallationTooMuchTimeMessage,
  partMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { emissionFieldMatcher, EmissionsInModale } from "../utils/types";
import { intrantSortantValidationSchema } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/intrantsSortants/validationUtils";
import { isCo2 } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/biomasse/helpers";
import { Errors, parseToUndefinedIfNull } from "common/form/utils";
import {
  shouldElementNameBeFilled,
  shouldPartBeFilled,
} from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/bilanMatiere/elementsHelpers";
import { MethodCombustion } from "../utils/selectPossibleValues";
import { shouldDisplayEcartEmission } from "../utils/utils";
import { ReferentialSinglePolluants } from "../../../utils/types";

export const anySubPartActivated = (type: MethodCombustion | null): boolean => {
  return (
    !!type &&
    (type === MethodCombustion.FACTEUR_EMISSION ||
      type === MethodCombustion.MESURE ||
      type === MethodCombustion.BILAN_MATIERE)
  );
};

export const facteurSubPartActivated = (
  type: MethodCombustion | null
): boolean => {
  return !!type && type === MethodCombustion.FACTEUR_EMISSION;
};

export const mesureSubPartActivated = (
  type: MethodCombustion | null
): boolean => {
  return !!type && type === MethodCombustion.MESURE;
};

export const matiereSubPartActivated = (
  emissionType: MethodCombustion | null
): boolean => {
  return !!emissionType && emissionType === MethodCombustion.BILAN_MATIERE;
};

export const mesureContinueDebitSubPartActivated = (
  debitType: boolean | null
): boolean => {
  return !debitType;
};

export const mesureContinueConcentrationSubPartActivated = (
  concentrationType: boolean | null
): boolean => {
  return !concentrationType;
};

export const epurationSubPartActivated = (
  concentrationType: boolean | null
): boolean => {
  return !!concentrationType;
};

export const singleEmissionOverwriteDependantFields = (
  emission: EmissionsInModale,
  referentialCO2: ReferenceItemPolluantDto
): void => {
  if (!facteurSubPartActivated(emission.methods)) {
    emission.combustible = null;
    emission.referenceCombustible = null;
    emission.consoAnnuelle = null;
    emission.unite = null;
    emission.facteur = null;
    emission.ecart = null;
    emission.provenance = null;
    emission.facteurOxydation = null;
    emission.provenanceFacteurOxydation = null;
  }
  if (
    facteurSubPartActivated(emission.methods) &&
    !isCo2(emission.substance, referentialCO2)
  ) {
    emission.facteurOxydation = null;
    emission.provenanceFacteurOxydation = null;
  }
  if (!mesureSubPartActivated(emission.methods)) {
    emission.heures = null;
    emission.debit = null;
    emission.continuDebit = null;
    emission.frequenceDebit = null;
    emission.concentrationDebit = null;
    emission.continuConcentration = null;
    emission.frequenceConcentration = null;
    emission.biomasseMesure = null;
    emission.typeMesure = null;
  }
  if (mesureSubPartActivated(emission.methods)) {
    if (!isCo2(emission.substance, referentialCO2)) {
      emission.biomasseMesure = null;
    }
    if (!mesureContinueDebitSubPartActivated(emission.continuDebit)) {
      emission.frequenceDebit = null;
    }
    if (
      !mesureContinueConcentrationSubPartActivated(
        emission.continuConcentration
      )
    ) {
      emission.frequenceConcentration = null;
    }
  }

  if (!matiereSubPartActivated(emission.methods)) {
    emission.intrants = [];
    emission.sortants = [];
    emission.elementProps = null;
    emission.elementName = null;
    emission.partElement = null;
    emission.biomasseMatiere = null;
  }
  if (matiereSubPartActivated(emission.methods)) {
    if (!isCo2(emission.substance, referentialCO2)) {
      emission.biomasseMesure = null;
    }
  }
  if (!epurationSubPartActivated(emission.epuration)) {
    emission.epuration = false;
    emission.nature = null;
    emission.rendement = null;
  }
};

export const singleEmissionValidationSchema = (
  referentialCO2: ReferenceItemPolluantDto
): Yup.SchemaOf<Partial<EmissionsInModale>> => {
  return Yup.object().shape({
    nameInstallation: commonNullableStringFields,
    substance: commonMixedFields(),
    methods: Yup.mixed<MethodCombustion>()
      .required(requiredMessage)
      .transform(parseToUndefinedIfNull),
    epuration: commonBooleanFieldsNullable,
    appareils: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      anySubPartActivated,
      commonArrayOfObjectFields,
      Yup.array()
    ),
    nature: subFieldValidationMultipleANDScheme<
      StringSchema<string | null | undefined>,
      MethodCombustion | null,
      boolean | null
    >(
      [emissionFieldMatcher.methods, emissionFieldMatcher.epuration],
      anySubPartActivated,
      epurationSubPartActivated,
      commonStringFields,
      Yup.string()
    ),
    rendement: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.epuration],
      anySubPartActivated,
      epurationSubPartActivated,
      commonPercentageFields,
      Yup.number()
    ),
    emissionsAnnuelles: commonPositiveNumberFieldsNullable,
    //FacteurEmission
    combustible: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      facteurSubPartActivated,
      Yup.object()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull),
      Yup.object()
    ),
    referenceCombustible: commonNullableStringFields,
    consoAnnuelle: commonNumberFieldsNullable,
    unite: commonNullableStringFields,
    facteur: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      facteurSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    ecart: commonNullableStringFields,
    provenance: subFieldValidationScheme<
      StringSchema<string | null | undefined>,
      MethodCombustion | null
    >(
      emissionFieldMatcher.methods,
      facteurSubPartActivated,
      commonStringFields,
      Yup.string()
    ),
    facteurOxydation: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.substance],
      facteurSubPartActivated,
      (type: ReferenceItemPolluantDto | null) => isCo2(type, referentialCO2),
      commonPercentageFields,
      Yup.number()
    ),
    provenanceFacteurOxydation: commonNullableStringFields,
    //MesureEmission
    typeMesure: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      Yup.mixed<AirCombustionEmissionMesureDto24NowTypeMesureEnum>()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull),
      Yup.mixed<AirCombustionEmissionMesureDto24NowTypeMesureEnum>()
    ),
    heures: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      commonPositiveNumberFields.max(
        MAX_NB_HOURS_YEAR,
        combustionInstallationTooMuchTimeMessage
      ),
      Yup.number()
    ),
    debit: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    continuDebit: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      Yup.boolean(),
      Yup.boolean()
    ),
    frequenceDebit: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.continuDebit],
      mesureSubPartActivated,
      mesureContinueDebitSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    concentrationDebit: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    concentrationDebitUnit: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      Yup.mixed<
        AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum
      >()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull),
      Yup.mixed<
        AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum
      >()
    ),
    continuConcentration: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      Yup.boolean(),
      Yup.boolean()
    ),
    frequenceConcentration: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.continuConcentration],
      mesureSubPartActivated,
      mesureContinueConcentrationSubPartActivated,
      commonPositiveNumberFields,
      Yup.number()
    ),
    biomasseMesure: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.substance],
      mesureSubPartActivated,
      (type: ReferenceItemPolluantDto | null) => isCo2(type, referentialCO2),
      commonPercentageFields,
      Yup.number()
    ),
    //MatiereEmission
    elementName: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.string().nullable(),
      Yup.string()
    ),
    elementProps: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.object().nullable(),
      Yup.object()
    ),
    intrants: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.array().of(intrantSortantValidationSchema),
      Yup.array()
    ),
    sortants: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.array().of(intrantSortantValidationSchema),
      Yup.array()
    ),
    partElement: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.number()
        .nullable()
        .min(1, partMessage)
        .max(100, partMessage),
      Yup.number()
    ),
    biomasseMatiere: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.substance],
      matiereSubPartActivated,
      (type: ReferenceItemPolluantDto | null) => isCo2(type, referentialCO2),
      commonPercentageFields,
      Yup.number()
    ),
  });
};

export const validateEmission = (
  values: EmissionsInModale,
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>,
  autreElementUuid: string,
  referentialSinglePolluants: ReferentialSinglePolluants
): Errors<EmissionsInModale> => {
  let errors = {};

  if (
    values.combustible?.data?.type &&
    values.substance &&
    shouldDisplayEcartEmission(values, referentialSinglePolluants) &&
    !values.ecart
  ) {
    errors = { ...errors, ecart: requiredMessage };
  }

  if (matiereSubPartActivated(values.methods)) {
    errors = {
      ...errors,
      ...validateMatierePolluantElement(
        values,
        polluantElementMap,
        autreElementUuid
      ),
    };
  }

  return errors;
};

const validateMatierePolluantElement = (
  values: EmissionsInModale,
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>,
  autreElementUuid: string
): Errors<EmissionsInModale> => {
  let errors = {};

  if (
    !shouldElementNameBeFilled(values, polluantElementMap) &&
    !values.elementProps
  ) {
    errors = { ...errors, elementProps: requiredMessage };
  }
  if (
    shouldPartBeFilled(values, polluantElementMap, autreElementUuid) &&
    (!values.elementName || !values.elementName.trim())
  ) {
    errors = { ...errors, elementName: requiredMessage };
  }
  if (
    shouldPartBeFilled(values, polluantElementMap, autreElementUuid) &&
    values.partElement === null
  ) {
    errors = { ...errors, partElement: requiredMessage };
  }

  return errors;
};
