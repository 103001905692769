import { makeStyles } from "@material-ui/styles";
import React from "react";
import { BACKGROUND_PINK } from "theme";
import classNames from "classnames";
import editLogo from "icons/edit.svg";
import Button from "common/button";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "space-evenly",
    width: "200px",
    height: "28px",
  },
  icon: {
    height: "16px",
    width: "16px",
  },
  button: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "6px 6px",
    "&:hover": {
      background: BACKGROUND_PINK,
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
  buttonDisabled: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "6px 6px",
  },
  disabled: {
    height: "16px",
    width: "16px",
    filter: "grayscale(100%)",
  },
  commentButton: {
    padding: "4px 4px",
    height: "auto",
    "&:hover": {
      background: BACKGROUND_PINK,
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
  buttonSize: {
    width: "120px",
    height: "100%",
  },
});

interface ArrayButtonsProps {
  handlerEdit: () => void;
  commentPath?: string;
  additionalClassName?: string;
  isValidated?: boolean;
  isSingleValidated: boolean;
}

const ArrayButtons = ({
  handlerEdit,
  commentPath,
  additionalClassName,
  isValidated,
  isSingleValidated,
}: ArrayButtonsProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.row, additionalClassName)}>
      {!isSingleValidated ? (
        <Button
          text="À renseigner"
          onClick={handlerEdit}
          isDisabled={isValidated}
          additionalClassname={classes.buttonSize}
        />
      ) : (
        <button
          type="button"
          onClick={handlerEdit}
          className={isValidated ? classes.buttonDisabled : classes.button}
          disabled={isValidated}
        >
          <img
            src={editLogo}
            alt="edit logo"
            className={isValidated ? classes.disabled : classes.icon}
          />
        </button>
      )}

      {commentPath && (
        <CommentButton
          path={commentPath}
          additionalClassName={classes.commentButton}
        />
      )}
    </div>
  );
};

export default ArrayButtons;
