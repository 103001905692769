import React from "react";
import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import { GenericFormikBlocInContextProps } from "./types";
import FormikBlocInUserContext from "./FormikBlocInUserContext";
import {
  useButtonStateWithRedirection,
  useSpinnerState,
} from "common/button/loadingAndRedirectionHelpers";
import { Redirect } from "react-router";
import { useConfirmationModale } from "common/modale/hooks";
import { AnyDeclarationDto } from "../../pages/CompanySpace/DeclarationApiContext/utils/types";
import { isPathCommented } from "../comment/commentUtils";

// TODO : the FormikBlocs should override hidden values to avoid bad saving

/**
 * Bloc wrapped in declaration context to handle validation, edit, save actions and validation life cycle
 *
 * use case : should be used on any Bloc that handle Formik data in the declaration
 *
 * @param pathToValidate:     path to validate on the server
 * @param updateHandler:      update to perform in the declaration to save or update data
 * @param updateCompletion:   callback on save bloc completion
 * all other param : see DormikBlocInUserContext
 * @param declarationState: delcaration state (may be either global state or quotas state - if not given, global state will be used)
 * @param editValidationMessage: if provided, display a confirmation modale before edit action
 * @param isAvailableToDeclarant:    are declarant allowed to use this bloc (default: true)
 * @param isAvailableToPrestataire:  are prestataire allowed to use this bloc (default: false)
 * @param getDeclarationState: function to get the default declaration sate (called if no declarationState is given)
 * @param useDeclaration: version of the useDeclaration hook used to get the declaration for the years the bloc is used in
 * @param useDeclarationHelpers: version of the useDeclarationHelpers hook used to get the declaration helpers for the years the bloc is used in
 * @param useBasicDeclarationHandlers: version of the useBasicDeclarationHandlers hook used to get the declaration basic handlers for the years the bloc is used in
 * @param useDeclarationErrorAndWarnings: hook used to get the errors and warnings for the year the bloc is used in
 * @param displayErrorAndWarning: function returning a JSX element to display the errors and warnings for the year the bloc is used in
 */
function GenericFormikBlocFullContext<
  T extends FormikValues,
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationConstraintViolationDto extends object
>({
  //    FormikBlocRefAndState
  formikRef,
  hasChanges,
  setHasChanges,
  //    DummyFormikBlocDisplayProps
  initialValues,
  validationSchema,
  title,
  isSingle,
  //  DummyFormikBlocAdditionProps
  additionalValidationAllowed,
  additionalFieldValidation,
  //    additional
  pathToValidate,
  updateHandler,
  updateCompletion,
  hasFormChanges,
  cancelAction,
  renderContent,
  onChange,
  declarationState,
  isAvailableToDeclarant,
  isAvailableToPrestataire,
  preventSubmitWithErrors,
  enableReinitialize,
  editValidationMessage,
  validationMessage,
  validationTitle,
  validationToolTipContent,
  getDeclarationState,
  useDeclaration,
  useDeclarationHelpers,
  useBasicDeclarationHandlers,
  useDeclarationErrorAndWarnings,
  displayErrorAndWarning,
  areGlobalCommentsAllowed,
  useComments,
  shouldFetchInCaseOfError,
  icon,
}: GenericFormikBlocInContextProps<
  T,
  VersionedDeclarationDto,
  VersionedDeclarationConstraintViolationDto
>): React.ReactElement {
  const declaration = useDeclaration();
  const {
    pathToDashboard,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers();
  const { update, validate, cancelValidate } = useBasicDeclarationHandlers();
  const openConfirmationModale = useConfirmationModale();

  const [
    isValidateSpinnerVisible,
    triggerValidateSpinner,
    shouldRedirectToDashboard,
    isCurrentlyValidated,
  ] = useButtonStateWithRedirection(
    pathToValidate,
    isPathValidatedInDeclaration
  );
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState();

  const {
    warnings,
    hasUncommentedWarning,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings(
    pathToValidate,
    false,
    isPathValidatedInDeclaration(pathToValidate)
  );

  const commentsContext = useComments();

  const hasComments = isPathCommented(
    commentsContext.comments,
    pathToValidate,
    false
  );

  if (isSingle && shouldRedirectToDashboard) {
    return <Redirect to={pathToDashboard} />;
  }

  return (
    <FormikBlocInUserContext
      //    FormikBlocRefAndState
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      //    DummyFormikBlocDisplayProps
      initialValues={initialValues}
      validationSchema={validationSchema}
      title={title}
      isSingle={isSingle}
      //  spinner
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      //    DummyFormikBlocControlProps
      saveAction={values =>
        triggerSaveSpinner(
          update(
            declaration => updateHandler(declaration, values),
            updateCompletion
          )
        )
      }
      validateAction={values =>
        triggerErrorAndWarningDisplay(
          triggerValidateSpinner(
            validate(
              declaration => updateHandler(declaration, values),
              pathToValidate,
              shouldFetchInCaseOfError
            )
          )
        )
      }
      editAction={async () => {
        if (editValidationMessage) {
          openConfirmationModale(editValidationMessage, () =>
            triggerEditSpinner(cancelValidate(pathToValidate))
          );
        } else {
          triggerEditSpinner(cancelValidate(pathToValidate));
        }
      }}
      cancelAction={
        cancelAction
          ? props => {
              props.resetForm(cancelAction());
            }
          : undefined
      }
      //    DummyFormikBlocAdditionProps
      additionalValidationAllowed={values => {
        const parentValidation = additionalValidationAllowed
          ? additionalValidationAllowed(values)
          : true;
        return (
          parentValidation &&
          ((!hasUncommentedWarning && !hasError) || hasChanges)
        );
      }}
      additionalFieldValidation={additionalFieldValidation}
      //    additional
      isBlocValidated={isCurrentlyValidated}
      hasFormChanges={hasFormChanges}
      renderContent={(props, shouldDisableFields) => {
        return (
          <>
            {renderContent(props, isCurrentlyValidated || shouldDisableFields)}
            {(errors.length > 0 || warnings.length > 0) &&
              displayErrorAndWarning(errors, warnings)}
          </>
        );
      }}
      onChange={onChange}
      declarationState={
        declarationState !== undefined
          ? declarationState
          : getDeclarationState(declaration)
      }
      declarationEtablissement={declaration.etablissementCode}
      isAvailableToDeclarant={isAvailableToDeclarant}
      isAvailableToAnyPrestataire={isAvailableToPrestataire}
      preventSubmitWithErrors={preventSubmitWithErrors}
      enableReinitialize={enableReinitialize}
      validationMessage={validationMessage}
      validationTitle={validationTitle}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed || false}
      hasComments={hasComments}
      commentAction={e => {
        e.preventDefault();
        commentsContext.openModal(pathToValidate, false);
      }}
      validationToolTipContent={validationToolTipContent}
      icon={icon}
    />
  );
}

export default GenericFormikBlocFullContext;
