import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { PINK } from "theme";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { EmissionsInArray, MesureEmissionInArray } from "../utils/types";
import { useComputeFirstLineContentCell } from "common/utils/computeWrappedCell";
import { ReferenceItemPolluantDto } from "api/gen";
import { isCo2 } from "../../../combustionProcedeUtils/biomasse/helpers";
import {
  computeCo2EmissionLine,
  computeSingleEmissionLine,
} from "./arrayUtils";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import { MethodEnum } from "../utils/selectPossibleValues";

const useStyles = makeStyles({
  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    backgroundColor: "red",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface EmissionArrayProps {
  mesureEmissionsInPage: MesureEmissionInArray[];
  procedesInPageMap: Map<string, ProcedeInArray>;
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationEmissionPath: string;
  referentialCO2: ReferenceItemPolluantDto;
}

const EmissionMesureArray = ({
  mesureEmissionsInPage,
  procedesInPageMap,
  setMesureEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
  referentialCO2,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const arraySubstancesLines = mesureEmissionsInPage
    .filter(mesure => {
      const methods = mesure.data.methods;
      return methods === MethodEnum.MESURE;
    })
    .reduce(
      (arrayLines: React.ReactElement[][], mesure: MesureEmissionInArray) => {
        const isEmissionCo2 = isCo2(mesure.data.substance, referentialCO2);

        if (isEmissionCo2) {
          arrayLines.push(
            ...computeCo2EmissionLine(
              mesure,
              procedesInPageMap,
              classes,
              setEmissionInModale,
              setEmissionModaleOpen,
              setMesureEmissionsInPage,
              isValidated,
              validationEmissionPath,
              computeFirstLineContentCell
            )
          );
          return arrayLines;
        }
        arrayLines.push(
          computeSingleEmissionLine(
            mesure,
            procedesInPageMap,
            classes,
            setEmissionInModale,
            setEmissionModaleOpen,
            setMesureEmissionsInPage,
            isValidated,
            validationEmissionPath,
            computeFirstLineContentCell
          )
        );

        return arrayLines;
      },
      []
    );

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Procédé(s)</p>,
          <p>Type d'émission mesurée</p>,
          <p>Débit horaire (Nm3/h)</p>,
          <p>Nombre d'heures de fonctionnement (h/an)</p>,
          <p>Mesure en continu du débit</p>,
          <p>Nombre de mesures (mesures/an)</p>,
          <p>Concentration moyenne</p>,
          <p>Unité de la concentration moyenne</p>,
          <p>Mesure en continu de la concentration</p>,
          <p>Nombre de mesures (mesures/an)</p>,
          <p>Émissions annuelles (kg/an)</p>,
          <p>Les émissions font-elles l'object d'épuration ?</p>,
          <p>Nature des équipements</p>,
          <p>Rendement de l'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          250,
          130,
          130,
          130,
          170,
          130,
          200,
          130,
          130,
          200,
          170,
          130,
          200,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionMesureArray;
