import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { RouteIdProps } from "common/declarant/type";
import { useUserData } from "Authenticator/UserData";
import DashboardInspecteur from "./Dashboard/DashboardInspecteur";
import DashboardDeclarant from "./Dashboard/DashboardDeclarant";
import PublicPortal from "../../../PublicPortal";
import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_ELEVAGE,
  PATH_AIR_GAZ,
  PATH_AIR_ISDND,
  PATH_AIR_PROCEDES,
  PATH_AIR_SOLVANTS,
  PATH_AIR_SYNTHESE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_DASHBOARD,
  PATH_DECHET,
  PATH_EAU,
  PATH_INFO_GENERALE,
  PATH_QUOTAS,
  PATH_SOL,
  PATH_SPFO,
} from "common/path/path18Now";
import Quotas from "./Quotas";
import { DeclarationPageHeaderAutoUpdater19Now } from "../toNow/versionedElements/DeclarationPageHeaderAutoUpdater19Now";
import CampaignDisabled from "../../../CampaignDisabled";
import GazFluores from "../toNow/Air/GazFluores";
import Environnement from "./Carriere/Environnement";
import Production from "./Carriere/Production";
import CarriereSanteSecurite from "./Carriere/SanteSecurite";
import SPFO from "../toNow/SPFO";
import Eau from "./Eau";
import Sol from "./Sol";
import GeneralInformations from "./GeneralInformations";
import WasteDeclaration from "./WasteDeclaration";
import Breeding from "./Air/Breeding";
import Synthese from "./Air/Synthese";
import Combustion from "./Air/Combustion";
import Solvants from "../toNow/Air/Solvants";
import ISDND from "../toNow/Air/ISDND";
import Procedes from "./Air/Procedes";
import { useDeclarationHelpers1919 } from "./versionedElements/declarationHooks1919";
import {
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_EMISSIONS,
} from "common/path/path20Now";
import { computeDeclarationUrl } from "../../../CommonSpace/AppPage/Redirect/computePath";
import RedirectDashboard from "pages/CommonSpace/AppPage/Redirect/RedirectDashboard";
import { ROUTER_PATH_TYPE_ACTIVITE } from "common/path/path21Now";
import DashboardLecteur from "./Dashboard/DashboardLecteur";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../api/gen";

interface RouterProps {
  route: RouteIdProps;
}

const Router1919 = ({ route }: RouterProps): React.ReactElement => {
  const userData = useUserData();
  const { getIsActiveSection } = useDeclarationHelpers1919();

  // Global access pages -> everyone excepted "prestataire" can access these pages
  const hasGlobalAccess = userData.isDeclarant || userData.isInspecteur;
  // Quotas access pages -> everyone can access these pages
  const hasQuotasAccess =
    userData.isDeclarant ||
    userData.isAnyPrestataire ||
    userData.isInspecteur ||
    userData.isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);

  const hasCarriereAccessLecteur = userData.isLecteurOnSection(
    LecteurDroitDtoAllowedSectionsEnum.CARRIERE
  );
  const hasEauAccessLecteur = userData.isLecteurOnSection(
    LecteurDroitDtoAllowedSectionsEnum.EAU
  );
  const pathQuotas = `${route.match.path}${PATH_QUOTAS}`;

  const redirectToQuota = () => {
    return (
      <Redirect
        to={computeDeclarationUrl(
          route.match.params.etablissement,
          PATH_QUOTAS.replace("/", ""),
          parseInt(route.match.params.annee)
        )}
      />
    );
  };

  const redirectToInfoGenerale = () => {
    return (
      <Redirect
        to={computeDeclarationUrl(
          route.match.params.etablissement,
          PATH_INFO_GENERALE.replace("/", ""),
          parseInt(route.match.params.annee)
        )}
      />
    );
  };

  let Dashboard;
  if (userData.isInspecteur) {
    Dashboard = DashboardInspecteur;
  } else if (userData.isDeclarant) {
    Dashboard = DashboardDeclarant;
  } else if (userData.isLecteur) {
    Dashboard = DashboardLecteur;
  } else if (userData.isAnyPrestataire) {
    Dashboard = redirectToQuota;
  } else {
    Dashboard = PublicPortal;
  }

  let QuotasComponent;

  if (!hasQuotasAccess) {
    QuotasComponent = Dashboard;
  } else if (getIsActiveSection(PATH_QUOTAS)) {
    QuotasComponent = Quotas;
  } else {
    QuotasComponent = () => <p>Cette déclaration n'a pas de section quotas.</p>;
  }

  return (
    <DeclarationPageHeaderAutoUpdater19Now>
      <Switch>
        <Route
          path={`${route.match.path}/campagne_inactive`}
          component={CampaignDisabled}
        />
        <Route
          path={`${route.match.path}${PATH_AIR_GAZ}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_GAZ)
              ? GazFluores
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_SOLVANTS}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_SOLVANTS)
              ? Solvants
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_ISDND}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_ISDND)
              ? ISDND
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_COMBUSTION}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_COMBUSTION)
              ? Combustion
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_PROCEDES}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_PROCEDES)
              ? Procedes
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_SYNTHESE}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_SYNTHESE)
              ? Synthese
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_ELEVAGE}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_ELEVAGE)
              ? Breeding
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_DECHET}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_DECHET)
              ? WasteDeclaration
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_INFO_GENERALE}`}
          component={
            hasGlobalAccess || hasCarriereAccessLecteur || hasEauAccessLecteur
              ? GeneralInformations
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${ROUTER_PATH_TYPE_ACTIVITE}`}
          component={redirectToInfoGenerale}
        />

        <Route
          path={`${route.match.path}${PATH_SOL}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_SOL)
              ? Sol
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_EAU}`}
          component={
            (hasGlobalAccess || hasEauAccessLecteur) &&
            getIsActiveSection(PATH_EAU)
              ? Eau
              : RedirectDashboard
          }
        />

        {/*these 2 routes are here to ensure a proper redirection from quota allocation or emission path to quotas path*/}
        <Route
          path={`${route.match.path}${PATH_QUOTAS_ALLOCATIONS}`}
          component={redirectToQuota}
        />

        <Route
          path={`${route.match.path}${PATH_QUOTAS_EMISSIONS}`}
          component={redirectToQuota}
        />

        <Route path={pathQuotas} component={QuotasComponent} />

        <Route
          path={`${route.match.path}${PATH_SPFO}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_SPFO)
              ? SPFO
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_CARRIERE_SANTE}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_SANTE)
              ? CarriereSanteSecurite
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_CARRIERE_PRODUCTION}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_PRODUCTION)
              ? Production
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_CARRIERE_ENVIRONNEMENT}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_ENVIRONNEMENT)
              ? Environnement
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_DASHBOARD}`}
          component={Dashboard}
        />

        <Route path="/" component={PublicPortal} />
      </Switch>
    </DeclarationPageHeaderAutoUpdater19Now>
  );
};

export default Router1919;
