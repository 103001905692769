import * as Yup from "yup";
import { ProcedeInModale } from "../utils/types";
import { parseToUndefinedIfNull } from "common/form/utils";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStringFieldsNoPipeAllowed,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ObjectUnite } from "../utils/selectPossibleValues";

export const singleProcedeHasSubPartActivated = (
  procedeType: OptionPropsWithObject<ObjectUnite> | null | undefined
): boolean => {
  return procedeType?.label === "Autre";
};

export const singleProcedeOverwriteDependantFields = (
  procede: ProcedeInModale
): void => {
  if (!singleProcedeHasSubPartActivated(procede.unit)) {
    procede.unitPrecision = null;
  }
};

export const singleProcedeValidationSchema = Yup.object().shape({
  name: commonStringFieldsNoPipeAllowed,
  quantity: commonPositiveNumberFields,
  unit: commonObjectFields,
  unitPrecision: subStringFieldValidationScheme(
    "unit",
    singleProcedeHasSubPartActivated
  ),
  density: commonPositiveNumberFieldsNullable,
  comment: Yup.string().transform(parseToUndefinedIfNull),
});
