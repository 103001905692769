import { CombustibleInModale } from "./types";
import {
  ReferenceItemCombustibleDto,
  ReferenceItemCombustibleDtoBiomasseEnum,
} from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const shouldDisplayBiogazEcart = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): boolean => {
  if (values.type && values.biogazFraction !== null) {
    if (combustible && combustible.biomasse) {
      switch (combustible.biomasse) {
        case ReferenceItemCombustibleDtoBiomasseEnum.NON:
          return values.biogazFraction > 0;
        case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
          return values.biogazFraction < 100;
        case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
          return false;
        default:
          throw new ShouldNotHappen(combustible.biomasse);
      }
    }
  }

  return false;
};

export const shouldDisplayPreciserMasseVolumique = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): boolean => {
  return !!(
    values.masse !== null &&
    combustible &&
    combustible.masseVolumiqueInf !== null &&
    combustible.masseVolumiqueSup !== null &&
    (values.masse < combustible.masseVolumiqueInf ||
      values.masse > combustible.masseVolumiqueSup)
  );
};

export const getMessagePreciserMasseVolumique = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): string => {
  if (combustible && combustible.masseVolumique) {
    return `La masse volumique renseignée est éloignée de la valeur communément admise pour ce combustible, à savoir ${combustible.masseVolumique} kg/L ou t/m3.`;
  }
  return "";
};

export const getMessageBiogazEcart = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): string => {
  if (values.type && values.biogazFraction !== null) {
    if (combustible && combustible.biomasse) {
      switch (combustible.biomasse) {
        case ReferenceItemCombustibleDtoBiomasseEnum.NON:
          return `${combustible.designation} est un combustible non biomasse. La fraction biomasse déclarée devrait être 0%.`;
        case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
          return `${combustible.designation} est un combustible totalement biomasse. La fraction biomasse déclarée devrait être 100%.`;
        case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
          return `${combustible.designation} est un combustible partiellement biomasse. La fraction biomasse déclarée devrait être comprise entre 0 et 100%.`;
        default:
          throw new ShouldNotHappen(combustible.biomasse);
      }
    }
    return "";
  }
  return "";
};
