import React from "react";
import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import { FormikBlocInUserContextProps } from "./types";
import FormikBloc from "./FormikBloc";
import { useUserData } from "Authenticator/UserData";
import { areActionsAllowedInUserContext } from "../utils/methods";
import { useStatusCampaignCurrentWorkflow } from "../../pages/CompanySpace/WorkflowTargetProviderContext";

/**
 * Bloc wrapped in user context to check user modification rights
 * this bloc only compute actionsAllowed prop of FormikBloc according to his current rights
 *
 * use case : wrapped in FormikBlocFullContext, (should not be used)
 *
 * @param isAvailableToDeclarant: are declarant allowed to use this bloc (default: true)
 * @param isAvailableToPrestataire: are prestataire allowed to use this bloc (default: false)
 * @param declarationState: current declaration state (may be either global state or quotas state)
 */
function FormikBlocInUserContext<T extends FormikValues>({
  //    FormikBlocRefAndState
  formikRef,
  hasChanges,
  setHasChanges,
  //    DummyFormikBlocDisplayProps
  initialValues,
  validationSchema,
  title,
  isSingle,
  //  spinner
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  //  DummyFormikBlocAdditionProps
  additionalValidationAllowed,
  additionalFieldValidation,
  //    DummyFormikBlocControlProps
  saveAction,
  validateAction,
  editAction,
  cancelAction,
  onChange,
  //    additional
  isBlocValidated,
  hasFormChanges,
  renderContent,
  declarationState,
  declarationEtablissement,
  isAvailableToDeclarant = true,
  isAvailableToAnyPrestataire = false,
  isAvailableToPrestataireEmissions = false,
  isAvailableToPrestataireNiveauxActivite = false,
  preventSubmitWithErrors,
  enableReinitialize,
  validationMessage,
  validationTitle,
  areGlobalCommentsAllowed,
  commentAction,
  hasComments,
  validationToolTipContent,
  icon,
}: FormikBlocInUserContextProps<T>): React.ReactElement {
  const userData = useUserData();
  const currentCampaignStatus = useStatusCampaignCurrentWorkflow();
  const areActionsAllowed = areActionsAllowedInUserContext(
    userData,
    declarationState,
    currentCampaignStatus,
    declarationEtablissement,
    isAvailableToDeclarant,
    isAvailableToAnyPrestataire,
    isAvailableToPrestataireEmissions,
    isAvailableToPrestataireNiveauxActivite
  );

  return (
    <FormikBloc
      //    FormikBlocRefAndState
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      //    DummyFormikBlocDisplayProps
      initialValues={initialValues}
      validationSchema={validationSchema}
      title={title}
      isSingle={isSingle}
      //  spinner
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      //  DummyFormikBlocAdditionProps
      additionalValidationAllowed={additionalValidationAllowed}
      additionalFieldValidation={additionalFieldValidation}
      //    DummyFormikBlocControlProps
      saveAction={saveAction}
      validateAction={validateAction}
      editAction={editAction}
      cancelAction={cancelAction}
      onChange={onChange}
      //    additional
      isBlocValidated={isBlocValidated}
      actionsAllowed={areActionsAllowed}
      hasFormChanges={hasFormChanges}
      renderContent={props => {
        return renderContent(props, !areActionsAllowed);
      }}
      preventSubmitWithErrors={preventSubmitWithErrors}
      enableReinitialize={enableReinitialize}
      validationMessage={validationMessage}
      validationTitle={validationTitle}
      hasComments={hasComments}
      commentAction={commentAction}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed}
      validationToolTipContent={validationToolTipContent}
      icon={icon}
    />
  );
}

export default FormikBlocInUserContext;
