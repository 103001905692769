import React from "react";
import { makeStyles } from "@material-ui/styles";
import Footer from "common/footer";
import Banner from "common/Header/Banner";
import Button from "common/button";
import Row from "common/presentational/Row";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
  container: {
    padding: "60px 0",
  },
  line: {
    display: "flex",
    justifyContent: "center",
    padding: "12px 0",
    fontSize: "20px",
    textAlign: "center",
    minHeight: "30px",
    height: "auto",
  },
  button: {
    marginLeft: "12px",
  },
});

interface failedToLogProps {
  message: string;
}
const FailedToLog = ({ message }: failedToLogProps) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.main}>
        <Banner />
        <div className={classes.container}>
          <Row additionalClassname={classes.line}>
            <span>{message}</span>
          </Row>
          <Row additionalClassname={classes.line}>
            <Button
              additionalClassname={classes.button}
              text={"Retour au portail"}
              onClick={() => {
                window.location.href =
                  "https://monaiot.developpement-durable.gouv.fr/page/demande-droits";
              }}
            />
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default FailedToLog;
