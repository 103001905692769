import { BlocRejetEauOptions } from "./types";
import {
  ReferenceItemStationEpurationDto,
  ReferenceSandreDto,
  ReferenceStationEpurationDto,
} from "api/gen";

const OTHER_STATION_UUID = "autre_station";
const ALIMENTATION_INDUSTRIELLE_UUID = "alimentation_industrielle";

export const otherStationOption: ReferenceItemStationEpurationDto = {
  nomOuvrageDepollution: "AUTRE STATION",
  codeDepartement: "",
  gid: -1,
  restorationCode: -1,
  codeOuvrageDepollution: "",
  uuid: OTHER_STATION_UUID,
};

export const alimentationIndustrielleOption: ReferenceItemStationEpurationDto = {
  nomOuvrageDepollution: "ALIMENTATION EN EAU INDUSTRIELLE (SITE TIERS)",
  codeDepartement: "",
  gid: -2,
  restorationCode: -2,
  codeOuvrageDepollution: "",
  uuid: ALIMENTATION_INDUSTRIELLE_UUID,
};
const arrayReleasingStations = (
  referential: ReferenceStationEpurationDto
): ReferenceItemStationEpurationDto[] => {
  return [
    ...referential.referenceItemStationEpurationDtoList,
    otherStationOption,
    alimentationIndustrielleOption,
  ];
};

export const selectPossibleValues = (
  referentialSandres: ReferenceSandreDto,
  referentialStationsEpuration: ReferenceStationEpurationDto
): BlocRejetEauOptions => {
  return {
    locations: referentialSandres.referenceItemSandreDtoList,
    stations: arrayReleasingStations(referentialStationsEpuration),
  };
};

export const isOtherStationSelected = (
  selectedStation: ReferenceItemStationEpurationDto | null
): boolean => {
  return !!selectedStation && selectedStation.uuid === OTHER_STATION_UUID;
};
export const isAlimentationIndustrielleSelected = (
  selectedStation: ReferenceItemStationEpurationDto | null
): boolean => {
  return (
    !!selectedStation && selectedStation.uuid === ALIMENTATION_INDUSTRIELLE_UUID
  );
};
