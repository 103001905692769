import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useTextAreaGenerator,
} from "common/form/fields/helpers/generators";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  PolluantInArray,
  PolluantInModale,
  SolCommonSelectOptions,
} from "./utils/types";
import {
  additionalCalcErrors,
  singlePolluantDescriptionAvailable,
  singlePolluantDescritionRequired,
  singlePolluantHasSubPartActivated,
  singlePolluantOverwriteDependantFields,
  singlePolluantRefMethodRequired,
  singlePolluantValidationSchema,
} from "./validation/validation";
import {
  computeNormesForMethod,
  computeRefMethodForMethod,
  methodLabel,
} from "./utils/selectPossibleValues";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { ReferenceNormeSolDto } from "api/gen";
import { isSearchStringInCollection } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { methodsMessage } from "common/infoBulle/messages";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { singlePolluantRefMethodAvailable } from "pages/CompanySpace/from20/to23/Sol/validation/validation";
import { convertNormeItemToLabel } from "./utils/converter";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";

interface FormSinglePolluantProps {
  initialPolluant: PolluantInModale;
  referentiels: SolCommonSelectOptions;
  closeFunction: () => void;
  onSubmit: (values: PolluantInModale) => void;
  referentialNormes: ReferenceNormeSolDto;
  polluantsInArray: PolluantInArray[];
  changedElemId: string | null;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  select: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormSinglePolluant = ({
  initialPolluant,
  referentiels,
  closeFunction,
  onSubmit,
  referentialNormes,
  polluantsInArray,
  changedElemId,
}: FormSinglePolluantProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-sol-polluant-individuel",
  };

  const TextArea = useTextAreaGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity<PolluantInModale>
      title="AJOUTER UNE SUBSTANCE"
      closeFunction={closeFunction}
      onSubmit={values => {
        singlePolluantOverwriteDependantFields(values);
        onSubmit(values);
      }}
      isEdit={changedElemId !== null}
      initialEntity={initialPolluant}
      validationSchema={singlePolluantValidationSchema}
      validate={values => {
        return additionalCalcErrors(
          values,
          referentiels,
          polluantsInArray,
          changedElemId
        );
      }}
      renderField={props => {
        return (
          <>
            <WrappedChoiceSelectModale
              name="pollutingSubstance"
              label="Substance *"
              title="SUBSTANCES DANS LE SOL"
              header={["CAS", "Libellé", "Seuil (kg/an)"]}
              linesData={referentiels.polluantName}
              formatLine={substance => [
                substance.cas || "",
                substance.nom,
                substance.seuilKgAn !== null
                  ? computeNumberWithSeparator(substance.seuilKgAn)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.nom}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.cas, lineData.nom],
                  searchedStr
                )
              }
              commonProps={commonProps}
              isFirstSticky={false}
            />

            <NumberField name="emission" label="Émission *" unit="kg/an" />

            <NumberField
              name="includingAccidental"
              label="Dont masse accidentelle *"
              unit="kg/an"
            />

            <WrappedChoiceSelect
              name={"usedMethod"}
              label={"Méthode utilisée *"}
              isMulti={false}
              options={referentiels.polluantMethod}
              additionalOnChange={value => {
                if (
                  !Array.isArray(value) &&
                  value !== null &&
                  value !== undefined
                ) {
                  if (
                    props.values.methodReference !== null &&
                    !singlePolluantRefMethodAvailable(
                      props.values.methodReference,
                      value
                    )
                  ) {
                    //  reset methode ref if invalide one is currently selected
                    props.setFieldValue("methodReference", null);
                  }
                }
              }}
              computeLabel={method => methodLabel[method]}
              commonProps={commonProps}
            />

            {singlePolluantHasSubPartActivated(props.values.usedMethod) && (
              <WrappedChoiceSelect
                name="methodReference"
                label="Référence de la méthode *"
                isMulti={false}
                options={computeRefMethodForMethod(props.values.usedMethod)}
                computeLabel={value => value.toString()}
                additionalOnChange={() => {
                  props.setFieldValue("polluantNorme", null);
                }}
                tooltipContent={methodsMessage}
                commonProps={commonProps}
              />
            )}
            {props.values.methodReference &&
              singlePolluantDescriptionAvailable(
                props.values.methodReference
              ) && (
                <TextArea
                  name="methodDescription"
                  label={
                    singlePolluantDescritionRequired(
                      props.values.methodReference
                    )
                      ? "Description/Désignation *"
                      : "Description/Désignation"
                  }
                  style={{
                    height: "50px",
                  }}
                />
              )}
            {props.values.methodReference &&
              singlePolluantRefMethodRequired(props.values.methodReference) && (
                <WrappedChoiceSelect
                  isMulti={true}
                  name={"polluantNorme"}
                  label={"Norme appliquée *"}
                  options={computeNormesForMethod(
                    props.values.methodReference,
                    referentialNormes
                  )}
                  computeLabel={convertNormeItemToLabel}
                  commonProps={commonProps}
                />
              )}
          </>
        );
      }}
    />
  );
};

export default FormSinglePolluant;
