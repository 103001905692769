export const PATH_ROOT = "/";
export const PATH_DASHBOARD = "/dashboard";

//INFO GENERALE
export const PATH_INFO_GENERALE = "/infoGenerale";
export const PATH_INFO_GENERALE_DECLARANT = `${PATH_INFO_GENERALE}/declarants`;
export const PATH_INFO_GENERALE_ADMINISTRATIF = `${PATH_INFO_GENERALE}/administratif`;
export const PATH_INFO_GENERALE_ACTIVITE = `${PATH_INFO_GENERALE}/activite`;

//DECHETS
export const PATH_DECHET = "/dechets";
//DECHET PRODUCTION
export const PATH_DECHET_PRODUCTION = `${PATH_DECHET}/production`;
export const PATH_DECHET_PRODUCTION_LIST = `${PATH_DECHET_PRODUCTION}/productionDechet`;
export const PATH_DECHET_PRODUCTION_DANGEREUX = `${PATH_DECHET_PRODUCTION}/productionDechetDangereuxSup2000`;
export const PATH_DECHET_PRODUCTION_NON_DANGEREUX = `${PATH_DECHET_PRODUCTION}/productionDechetNonDangereuxSup2000`;
//DECHET RECEPTION
export const PATH_DECHET_RECEPTION = `${PATH_DECHET}/reception`;
export const PATH_DECHET_RECEPTION_LIST = `${PATH_DECHET_RECEPTION}/receptionDechets`;
//DECHET RESUME
export const PATH_DECHET_RESUME = `${PATH_DECHET}/resume`;
export const PATH_RESUME_DANGER_PATH = `${PATH_DECHET_RESUME}/capaciteDechetsDangereuxInitiale`;
export const PATH_DECHET_RESUME_NON_DANGER = `${PATH_DECHET_RESUME}/capaciteDechetsNonDangereuxInitiale`;

//EAU
export const PATH_EAU = "/eau";
//EAU INFOGENERALE
export const PATH_EAU_INFOGENERALE = `${PATH_EAU}/infoGenerales`;
export const PATH_EAU_INFOGENERALE_SEUIL = `${PATH_EAU_INFOGENERALE}/depassementSeuilPrelevement`;
export const PATH_EAU_INFOGENERALE_MEGATHERMIE = `${PATH_EAU_INFOGENERALE}/megathermie`;
//EAU PRELEVEMENT
export const PATH_EAU_PRELEVEMENT = `${PATH_EAU}/prelevement`;
export const PATH_EAU_PRELEVEMENT_JOURS = `${PATH_EAU_PRELEVEMENT}/nombreJoursTravailles`;
export const PATH_EAU_PRELEVEMENT_SOUTERRAIN = `${PATH_EAU_PRELEVEMENT}/volumeEauxSouterraines`;
export const PATH_EAU_PRELEVEMENT_MER_OCEAN = `${PATH_EAU_PRELEVEMENT}/volumeMerOcean`;
//EAU REJET EAU
export const PATH_EAU_REJETEAU = `${PATH_EAU}/rejetEau`;
export const PATH_EAU_REJETEAU_RACCORDE = `${PATH_EAU_REJETEAU}/rejetRaccorde`;
export const PATH_EAU_REJETEAU_RACCORDE_CHALEUR = `${PATH_EAU_REJETEAU_RACCORDE}/chaleurRejetee`;
export const PATH_EAU_REJETEAU_ISOLE = `${PATH_EAU_REJETEAU}/rejetIsole`;
export const PATH_EAU_REJETEAU_ISOLE_CHALEUR = `${PATH_EAU_REJETEAU_ISOLE}/chaleurRejetee`;
//EAU REJET POLLUANT
export const PATH_EAU_REJETPOLLUANT = `${PATH_EAU}/rejetPolluants`;
export const PATH_EAU_REJETPOLLUANT_REJETPOLLUANT = `${PATH_EAU_REJETPOLLUANT}/rejetPolluants`;

//SOL
export const PATH_SOL = "/sol";
export const PATH_SOL_REJETS = `${PATH_SOL}/rejets`;
export const PATH_SOL_DOIT_REMPLIR = `${PATH_SOL}/doitRemplirSol`;
export const PATH_SOL_VOLUMEDECHETS = `${PATH_SOL}/volumeDechets`;

//AIR
export const PATH_AIR = "/air";
//COMBUSTION
export const PATH_AIR_COMBUSTION = `${PATH_AIR}/combustion`;
export const PATH_AIR_COMBUSTION_INSTALLATION = `${PATH_AIR_COMBUSTION}/installations`;
export const PATH_AIR_COMBUSTION_RECAPITULATIF = `${PATH_AIR_COMBUSTION}/recapitulatif`;
export const PATH_AIR_COMBUSTION_CONTENT = `${PATH_AIR_COMBUSTION}/content`;
export const PATH_AIR_COMBUSTION_CONTENT_APPAREILS = `${PATH_AIR_COMBUSTION_CONTENT}/appareils`;
export const PATH_AIR_COMBUSTION_CONTENT_COMBUSTIBLES = `${PATH_AIR_COMBUSTION_CONTENT}/combustibles`;
export const PATH_AIR_COMBUSTION_CONTENT_EMISSIONS = `${PATH_AIR_COMBUSTION_CONTENT}/emissions`;

//PROCEDES-FUGITIVES
export const PATH_AIR_PROCEDES = `${PATH_AIR}/fugitives`;
export const PATH_AIR_PROCEDES_PROCEDES = `${PATH_AIR_PROCEDES}/procedes`;
export const PATH_AIR_PROCEDES_EMISSION = `${PATH_AIR_PROCEDES}/emission`;
export const PATH_AIR_PROCEDES_FLUX = `${PATH_AIR_PROCEDES}/flux`;
export const PATH_AIR_PROCEDES_MENTIONDANGER = `${PATH_AIR_PROCEDES}/mentionDanger`;

//SOLVANTS
export const PATH_AIR_SOLVANTS = `${PATH_AIR}/solvants`;
export const PATH_AIR_SOLVANTS_INFORMATION = `${PATH_AIR_SOLVANTS}/informations`;
export const PATH_AIR_SOLVANTS_INSTALLATION = `${PATH_AIR_SOLVANTS}/installations`;
export const PATH_AIR_SOLVANTS_COVNM = `${PATH_AIR_SOLVANTS}/covnms`;
export const PATH_AIR_SOLVANTS_COVS = `${PATH_AIR_SOLVANTS}/covs`;
export const PATH_AIR_SOLVANTS_EMISSION = `${PATH_AIR_SOLVANTS}/emissions`;

//ISDND
export const PATH_AIR_ISDND = `${PATH_AIR}/isdnd`;
export const PATH_AIR_ISDND_BASIC = `${PATH_AIR_ISDND}/basic`;
export const PATH_AIR_ISDND_EMISSIONS = `${PATH_AIR_ISDND}/emissionsCalculees`;

//ELEVAGE
export const PATH_AIR_ELEVAGE = `${PATH_AIR}/elevage`;
export const PATH_AIR_ELEVAGE_EMISSION = `${PATH_AIR_ELEVAGE}/emissions`;
export const PATH_AIR_ELEVAGE_EMISSION_NH3 = `${PATH_AIR_ELEVAGE_EMISSION}/nh3`;
export const PATH_AIR_ELEVAGE_EMISSION_N2O = `${PATH_AIR_ELEVAGE_EMISSION}/n2o`;
export const PATH_AIR_ELEVAGE_EMISSION_CH4 = `${PATH_AIR_ELEVAGE_EMISSION}/ch4`;
export const PATH_AIR_ELEVAGE_EMISSION_TSP = `${PATH_AIR_ELEVAGE_EMISSION}/tsp`;
export const PATH_AIR_ELEVAGE_EMISSION_PM10 = `${PATH_AIR_ELEVAGE_EMISSION}/pm10`;
export const PATH_AIR_ELEVAGE_MTD = `${PATH_AIR_ELEVAGE}/mtd`;
export const PATH_AIR_ELEVAGE_DETAIL = `${PATH_AIR_ELEVAGE}/detailNh3`;
export const PATH_AIR_ELEVAGE_DETAIL_ESPECES = `${PATH_AIR_ELEVAGE_DETAIL}/especesDeclarees`;
export const PATH_AIR_ELEVAGE_DETAIL_AUTRE_NH3 = `${PATH_AIR_ELEVAGE_DETAIL}/autreNh3`;
export const PATH_AIR_ELEVAGE_DETAIL_BATIMENT = `${PATH_AIR_ELEVAGE_DETAIL}/batiments`;

//SYNTHESE
export const PATH_AIR_SYNTHESE = `${PATH_AIR}/synthese`;
export const PATH_AIR_SYNTHESE_EMISSION = `${PATH_AIR_SYNTHESE}/emissions`;

//GAZ
export const PATH_AIR_GAZ = `${PATH_AIR}/gaz`;
export const PATH_AIR_GAZ_PFC = `${PATH_AIR_GAZ}/pfc`;
export const PATH_AIR_GAZ_HFC = `${PATH_AIR_GAZ}/hfc`;

//QUOTAS
export const PATH_QUOTAS = "/quotas";

//CARRIERE
export const PATH_CARRIERE = "/carriere";
//CARRIERE ENVIRONNEMENT
export const PATH_CARRIERE_ENVIRONNEMENT = `${PATH_CARRIERE}/environnement`;

//CARRIERE ENVIROMMENTMENT GENERAL
export const PATH_CARRIERE_ENVIRONNEMENT_GENERAL = `${PATH_CARRIERE_ENVIRONNEMENT}/general`;
export const PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMAX = `${PATH_CARRIERE_ENVIRONNEMENT_GENERAL}/prodMax`;
export const PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMOY = `${PATH_CARRIERE_ENVIRONNEMENT_GENERAL}/prodMoy`;
export const PATH_CARRIERE_ENVIRONNEMENT_GENERAL_DATEFIN = `${PATH_CARRIERE_ENVIRONNEMENT_GENERAL}/dateFin`;
export const PATH_CARRIERE_ENVIRONNEMENT_GENERAL_EAU = `${PATH_CARRIERE_ENVIRONNEMENT_GENERAL}/eau`;

//CARRIERE ENVIRONNEMENT DECHETS INERTES
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES = `${PATH_CARRIERE_ENVIRONNEMENT}/dechetsInertes`;
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_APPORTS = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES}/aDesApportsDechetsInertes`;
export const PATH_CARRIERE_ENVIRONNEMENT_CAPACITE_RESTANTE_TERME_ANNEE_REF = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES}/capaciteRestanteTermeAnneeRef`;
//CARRIERE ENVIRONNEMENT DECHETS INERTES TABLEAU
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES}/tableauDechetsInertes`;
//CARRIERE ENVIRONNEMENT DECHETS INERTES TABLEAU A REMBLAYER
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU}/aRemblayer`;
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_TERRECAILLOUX = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER}/terreEtCailloux`;
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_MATERIAUX = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER}/autresMateriaux`;
//CARRIERE ENVIRONNEMENT DECHETS INERTES TABLEAU A RECYCLER
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU}/aRecycler`;
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_TERRECAILLOUX = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER}/terreEtCailloux`;
export const PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_MATERIAUX = `${PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER}/autresMateriaux`;

//CARRIERE ENVIRONNEMENT SUPERFICIE
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE = `${PATH_CARRIERE_ENVIRONNEMENT}/superficie`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE}/aRemisEnEtat`;

//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE PRECEDENTE
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE}/remisEnEtatAnneePrecedentes`;
//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE PRECEDENTE TERRE
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE}/remiseEtatTerre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_FORET = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/zoneForestiere`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AGRICOLE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/zoneAgricole`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECONOMIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/zoneEconomique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECOLOGIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/zoneEcologique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AUTRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/autre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_REMISE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE}/typeRemiseEtatAutre`;
//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE PRECEDENTE EAU
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE}/remiseEtatEau`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_FORET = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/zoneForestiere`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_AGRICOLE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/zoneAgricole`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECONOMIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/zoneEconomique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECOLOGIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/zoneEcologique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_AUTRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/autre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_REMISE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU}/typeRemiseEtatAutre`;

//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE ECOULEE
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE}/remisEnEtatAnneeEcoulee`;
//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE ECOULEE TERRE
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE}/remiseEtatTerre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_FORET = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/zoneForestiere`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AGRICOLE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/zoneAgricole`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECONOMIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/zoneEconomique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECOLOGIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/zoneEcologique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AUTRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/autre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_REMISE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE}/typeRemiseEtatAutre`;
//CARRIERE ENVIRONNEMENT SUPERFICIE ANNEE ECOULEE EAU
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE}/remiseEtatEau`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_FORET = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/zoneForestiere`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_AGRICOLE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/zoneAgricole`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECONOMIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/zoneEconomique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECOLOGIQUE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/zoneEcologique`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_AUTRE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/autre`;
export const PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_REMISE = `${PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU}/typeRemiseEtatAutre`;

//CARRIERE ENVIRONNEMENT AUTRE RENSEIGNEMENTS SUPERFICIE
export const PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE = `${PATH_CARRIERE_ENVIRONNEMENT}/autresRenseignementsSuperficie`;
export const PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_AUTORISEE = `${PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE}/superficieAutorisee`;
export const PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTANT_EXPLOITER = `${PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE}/superficieRestantAExploiter`;
export const PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_EXPLOITEE = `${PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE}/superficieExploitee`;
export const PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTITUEE = `${PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE}/superficieRestituee`;

//CARRIERE ENVIRONNEMENT POUSSIERE ATMOSPHERIQUE
export const PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE = `${PATH_CARRIERE_ENVIRONNEMENT}/poussiereAtmospherique`;
export const PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_RETOMBEE = `${PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE}/mesuresDesRetombee`;
export const PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_EFFECTUEE = `${PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE}/mesureEffectuee`;

//CARRIERE ENVIRONNEMENT POUSSIERE CANALISEE
export const PATH_CARRIERE_ENVIRONNEMENT_CANALISEE = `${PATH_CARRIERE_ENVIRONNEMENT}/poussiereCanalisee`;
export const PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_MESURE_REJETS = `${PATH_CARRIERE_ENVIRONNEMENT_CANALISEE}/mesuresDesRejets`;
export const PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_DOIT_MESURER = `${PATH_CARRIERE_ENVIRONNEMENT_CANALISEE}/doitEffectuerMesures`;
export const PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_MESURE_EFFECTUEES = `${PATH_CARRIERE_ENVIRONNEMENT_CANALISEE}/mesureEffectuee`;

//CARRIERE ENVIRONNEMENT BRUIT
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT = `${PATH_CARRIERE_ENVIRONNEMENT}/bruit`;

export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_ENVIRONNEMENT = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT}/mesuresSpecifiquesDansEnvironnement`;

//CARRIERE ENVIRONNEMENT BRUIT MESURES
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT}/mesures`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_EFFECUTEE = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES}/mesureEffectuee`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_POINTS = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES}/nbPointsMesure`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_CAMPAGNES = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES}/nbCampagnesMesure`;

//CARRIERE ENVIRONNEMENT MESURES SPECIFIQUES
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT}/mesuresSpecifiques`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_EFFECUTEE = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES}/mesureEffectuee`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_POINTS = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES}/nbPointsMesure`;
export const PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_CAMPAGNES = `${PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES}/nbCampagnesMesure`;

//CARRIERE ENVIRONNEMENT VIBRATION
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION = `${PATH_CARRIERE_ENVIRONNEMENT}/vibration`;

export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_SOUMIS_MESURE_VIBRATION = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION}/soumisMesureVibrations`;

export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_CAMPAGNE_EXPLOSION = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION}/campagneExplosion`;

export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_CAMPAGNE_VIBRATION = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION}/campagneVibration`;

export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION}/mesuresExplosion`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION_EFFECTUEE = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION}/mesureEffectuee`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION_NB_POINTS = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION}/nbPointsMesure`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION_NB_CAMPAGNES = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_EXPLOSION}/nbCampagnesMesure`;

export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION}/mesuresVibration`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION_EFFECTUEE = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION}/mesureEffectuee`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION_NB_POINTS = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION}/nbPointsMesure`;
export const PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION_NB_CAMPAGNES = `${PATH_CARRIERE_ENVIRONNEMENT_VIBRATION_MESURE_VIBRATION}/nbCampagnesMesure`;

//PRODUCTION
export const PATH_CARRIERE_PRODUCTION = `${PATH_CARRIERE}/production`;
export const PATH_CARRIERE_PRODUCTION_EXTRACTION = `${PATH_CARRIERE_PRODUCTION}/extraction`;
export const PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_RESTANTE = `${PATH_CARRIERE_PRODUCTION_EXTRACTION}/quantiteRestante`;
export const PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_STERILES = `${PATH_CARRIERE_PRODUCTION_EXTRACTION}/quantiteSterilesGeneres`;
export const PATH_CARRIERE_PRODUCTION_EXTRACTION_SUBSTANCES = `${PATH_CARRIERE_PRODUCTION_EXTRACTION}/substances`;

export const PATH_CARRIERE_PRODUCTION_TRANSFORMATION = `${PATH_CARRIERE_PRODUCTION}/transformation`;
export const PATH_CARRIERE_PRODUCTION_TRANSFORMATION_ACTIVITE = `${PATH_CARRIERE_PRODUCTION_TRANSFORMATION}/activiteTransformations`;
export const PATH_CARRIERE_PRODUCTION_TRANSFORMATION_REMPLIR_TABLEAU = `${PATH_CARRIERE_PRODUCTION_TRANSFORMATION}/doitRemplirTableau`;

export const PATH_CARRIERE_PRODUCTION_RECYCLAGE = `${PATH_CARRIERE_PRODUCTION}/recyclage`;
export const PATH_CARRIERE_PRODUCTION_RECYCLAGE_TABLEAU = `${PATH_CARRIERE_PRODUCTION_RECYCLAGE}/tableauRecyclage`;
export const PATH_CARRIERE_PRODUCTION_RECYCLAGE_REMPLIR_TABLEAU = `${PATH_CARRIERE_PRODUCTION_RECYCLAGE}/doitRemplirTableau`;
export const PATH_CARRIERE_PRODUCTION_RECYCLAGE_QUANTITE_ENTRANTE = `${PATH_CARRIERE_PRODUCTION_RECYCLAGE}/quantiteEntrante`;

export const PATH_CARRIERE_PRODUCTION_TRANSPORT = `${PATH_CARRIERE_PRODUCTION}/transport`;

export const PATH_CARRIERE_PRODUCTION_TRANSPORT_TABLEAU = `${PATH_CARRIERE_PRODUCTION_TRANSPORT}/tableauDestinations`;

export const PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE = `${PATH_CARRIERE_PRODUCTION_TRANSPORT}/modaliteTransports`;

//SANTE
export const PATH_CARRIERE_SANTE = `${PATH_CARRIERE}/sante`;
export const PATH_CARRIERE_SANTE_PREVENTION = `${PATH_CARRIERE_SANTE}/prevention`;
export const PATH_CARRIERE_SANTE_PREVENTION_NB_VISITES = `${PATH_CARRIERE_SANTE_PREVENTION}/nbVisites`;
export const PATH_CARRIERE_SANTE_PREVENTION_ACTIVITE_SAISONNIERE = `${PATH_CARRIERE_SANTE_PREVENTION}/activiteSaisonniere`;
export const PATH_CARRIERE_SANTE_PREVENTION_STRUCTURE_FONCTIONNELLE = `${PATH_CARRIERE_SANTE_PREVENTION}/structureFonctionnelle`;
export const PATH_CARRIERE_SANTE_PREVENTION_ORGANISME_PREVENTION = `${PATH_CARRIERE_SANTE_PREVENTION}/organismeDePreventionID`;

export const PATH_CARRIERE_SANTE_ACCIDENT = `${PATH_CARRIERE_SANTE}/accident`;
export const PATH_CARRIERE_SANTE_ACCIDENT_ARRET_TRAVAIL = `${PATH_CARRIERE_SANTE_ACCIDENT}/accidentAvecArretTravail`;
export const PATH_CARRIERE_SANTE_ACCIDENT_ACCIDENT = `${PATH_CARRIERE_SANTE_ACCIDENT}/accidents`;

export const PATH_CARRIERE_SANTE_EMPOUSSIERAGE = `${PATH_CARRIERE_SANTE}/empoussierage`;
export const PATH_CARRIERE_SANTE_EMPOUSSIERAGE_MESURE_EFFECTUEE = `${PATH_CARRIERE_SANTE_EMPOUSSIERAGE}/mesureEffectuee`;
export const PATH_CARRIERE_SANTE_EMPOUSSIERAGE_NBGEH = `${PATH_CARRIERE_SANTE_EMPOUSSIERAGE}/nbreGEH`;
export const PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_QUARTZ = `${PATH_CARRIERE_SANTE_EMPOUSSIERAGE}/tableauEmpoussierageAlveolaireQuartz`;
export const PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_TOTAL = `${PATH_CARRIERE_SANTE_EMPOUSSIERAGE}/tableauEmpoussierageAlveolaireTotal`;

//SPFO
export const PATH_SPFO = "/spfo";
export const PATH_SPFO_STOCKEE = `${PATH_SPFO}/quantiteStockeeSurSite`;
export const PATH_SPFO_UTILISEE = `${PATH_SPFO}/quantiteUtilisee`;
export const PATH_SPFO_ELIMIEE = `${PATH_SPFO}/quantiteEliminee`;
export const PATH_SPFO_ACTIVITE_TRAITEMENT_SURFACE = `${PATH_SPFO}/activiteTraitementSurface`;
export const PATH_SPFO_NATURE_PROCEDE_TRAITEMENT_SURFACE = `${PATH_SPFO}/natureProcedeTraitementSurface`;
