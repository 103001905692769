import {
  ReferenceGeoCodeInseeDto,
  ReferenceItemGeoCodeInseeDto,
} from "api/gen";
import { ActiviteTransformationType, france } from "./types";
import {
  ActiviteTransformationDto,
  ReferencePaysDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { convertStringCountryToReferenceItem } from "../../utils/converters";
import { findElementMatchingTemplate } from "common/utils/methods";

export const generateFranceReferenceItemPays = (): ReferenceItemPaysDto => {
  return {
    codeInsee: 0,
    designation: france,
    restorationCode: 0,
    ue: true,
    uuid: "",
  };
};

const getPaysNameById = (paysRef: ReferenceItemPaysDto[], paysID: string) => {
  const pays = findElementMatchingTemplate({ uuid: paysID }, paysRef);
  return pays ? pays.designation : null;
};

const getCityById = (
  cityRef: ReferenceItemGeoCodeInseeDto[],
  cityID: string
): ReferenceItemGeoCodeInseeDto | null => {
  const city = findElementMatchingTemplate({ uuid: cityID }, cityRef);
  return city;
};

export const convertActiviteTransformationDtoToDisplayed = (
  activitesTransformationDto: ActiviteTransformationDto[],
  referentialCountries: ReferencePaysDto,
  referentialCities: ReferenceGeoCodeInseeDto
): ActiviteTransformationType[] => {
  return activitesTransformationDto.map(activite => {
    const paysID =
      activite.paysID && activite.paysID !== france
        ? getPaysNameById(
            referentialCountries.referenceItemPaysDtoList,
            activite.paysID
          )
        : activite.paysID;
    const ville =
      activite.ville !== null && activite.paysID === france
        ? getCityById(
            referentialCities.referenceItemGeoCodeInseeDtoList,
            activite.ville
          )
        : activite.ville;
    return {
      id: activite.id,
      raisonSocialeUsine: activite.raisonSocialeUsine,
      codePostal: activite.codePostal,
      paysID: convertStringCountryToReferenceItem(
        paysID || "",
        referentialCountries
      ),
      ville: ville,
      distanceUsineCarriere: activite.distanceUsineCarriere,
      alimenteeParCarrieres: activite.alimenteeParCarrieres,
    };
  });
};

export const convertDisplayedToActiviteTransformationDto = (
  factoriesInArray: ActiviteTransformationType[]
): ActiviteTransformationDto[] => {
  return factoriesInArray.map(populatedFactory => {
    let countryName: string | null;
    if (!populatedFactory.paysID) {
      countryName = null;
    } else {
      countryName =
        populatedFactory.paysID.designation !== france
          ? populatedFactory.paysID.uuid
          : populatedFactory.paysID.designation;
    }

    const cityName =
      populatedFactory.ville && typeof populatedFactory.ville !== "string"
        ? populatedFactory.ville.uuid
        : populatedFactory.ville;
    return {
      id: populatedFactory.id,
      raisonSocialeUsine: populatedFactory.raisonSocialeUsine,
      codePostal: populatedFactory.codePostal,
      ville: cityName,
      paysID: countryName,
      distanceUsineCarriere: populatedFactory.distanceUsineCarriere,
      alimenteeParCarrieres: !!populatedFactory.alimenteeParCarrieres,
    };
  });
};
