import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { ClassesType } from "common/utils/types";
import { ReactElement } from "react";

export const computeEmissionCell = (emission: number | null): string =>
  getRoundedFixedNumberWithSeparatorOrNull(emission);

export const computeEmissionWrappedCell = (
  emission: string | null,
  classes: ClassesType<"full">
): ReactElement =>
  computeWrappedCell(
    getRoundedFixedNumberWithSeparatorOrNull(Number(emission)),
    classes
  );
