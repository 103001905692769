import CommonEntityButton from "common/presentational/CommonEntityButton";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { computeBiomasseTotal } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/biomasse/helpers";
import { EmissionsInArray, FacteurEmissionInArray } from "../utils/types";
import { createAppareilDiv } from "../utils/utils";

const suppressEmission = (
  singleEmissionInArray: FacteurEmissionInArray,
  setFacteurEmissionsInArray: Dispatch<SetStateAction<FacteurEmissionInArray[]>>
): void => {
  setFacteurEmissionsInArray(elderEmissions => {
    return elderEmissions.filter(elderEmissionInArray => {
      return singleEmissionInArray.data.id !== elderEmissionInArray.data.id;
    });
  });
};

// a method to compute lines that takes as param any action or data that needs to be deduced from another data
const dummyComputeSingleEmissionLine = (
  emission: FacteurEmissionInArray,
  classes: ClassesType<"full" | "error">,
  editAction: () => void,
  suppressAction: () => void,
  substanceName: string | null,
  combustibleName: string | null,
  isValidated: boolean,
  validationFacteursPath: string,
  pathSuffix: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const firstCellContent = computeFirstLineContentCell(
    editAction,
    substanceName || undefined,
    isValidated,
    !_.isEmpty(emission.errors)
  );

  return [
    computeWrappedCellWithError(firstCellContent, classes),
    computeWrappedCellWithError(
      emission.data.appareils && createAppareilDiv(emission.data.appareils),
      classes
    ),
    computeWrappedCellWithError(
      <p title={combustibleName || undefined}>{combustibleName}</p>,
      classes
    ),
    computeWrappedCellWithError(emission.data.referenceCombustible, classes),
    computeWrappedCellWithError(
      emission.data.consoAnnuelle !== null
        ? computeNumberWithSeparator(emission.data.consoAnnuelle)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.unite || undefined}>{emission.data.unite}</p>,
      classes
    ),
    computeWrappedCellWithError(
      emission.data.facteur !== null
        ? computeNumberWithSeparator(emission.data.facteur)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.ecart || undefined}>{emission.data.ecart}</p>,
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.provenance || undefined}>
        {emission.data.provenance}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      emission.data.facteurOxydation !== null
        ? computeNumberWithSeparator(emission.data.facteurOxydation)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.provenanceFacteurOxydation || undefined}>
        {emission.data.provenanceFacteurOxydation}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      !emission.data.epuration ? "Non" : "Oui",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.nature || undefined}>{emission.data.nature}</p>,
      classes
    ),
    computeWrappedCellWithError(emission.data.rendement, classes),
    computeWrappedCellWithError(
      emission.data.emissionsAnnuelles !== null
        ? computeNumberWithSeparator(Number(emission.data.emissionsAnnuelles))
        : "",
      classes
    ),

    <CommonEntityButton
      handlerEdit={editAction}
      suppressMessage={"Êtes vous sûr de vouloir supprimer cette emission ?"}
      handlerSuppress={suppressAction}
      isValidated={isValidated}
      commentPath={`${validationFacteursPath}/${pathSuffix}`}
    />,
  ];
};

// method to compute the lines for a "standard" emission. Handles the actions and data that is "deduced" from the base emission given
export const computeSingleEmissionLine = (
  emission: FacteurEmissionInArray,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setFacteurEmissionsInArray: Dispatch<
    SetStateAction<FacteurEmissionInArray[]>
  >,
  isValidated: boolean,
  validationFacteursPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setFacteurEmissionsInArray);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const combustibleName =
    emission.data.combustible &&
    emission.data.combustible.data.type &&
    emission.data.combustible.data.type.designation;

  return dummyComputeSingleEmissionLine(
    emission,
    classes,
    editAction,
    suppressAction,
    substanceName,
    combustibleName,
    isValidated,
    validationFacteursPath,
    emission.data.id,
    computeFirstLineContentCell
  );
};

// method to compute the lines for a co2 emission. Handles the duplication of line in 2,
// and the different name in first cell, as well as the different total. Also creates the
// actions and data that is "deduced" from the base emission given
export const computeCo2EmissionLine = (
  emission: FacteurEmissionInArray,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setFacteurEmissionsInArray: Dispatch<
    SetStateAction<FacteurEmissionInArray[]>
  >,
  isValidated: boolean,
  validationFacteursPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[][] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setFacteurEmissionsInArray);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const combustibleName =
    emission.data.combustible &&
    emission.data.combustible.data.type &&
    emission.data.combustible.data.type.designation;

  const {
    emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse,
  } = computeBiomasseTotal(
    emission.data.emissionsAnnuelles,
    emission.data.combustible && emission.data.combustible.data.biogazFraction
  );

  return [
    dummyComputeSingleEmissionLine(
      {
        data: {
          ...emission.data,
          emissionsAnnuelles: emissionAnnuellesBiomasse,
        },
        errors: emission.errors,
      },
      classes,
      editAction,
      suppressAction,
      `${substanceName} biomasse`,
      combustibleName,
      isValidated,
      validationFacteursPath,
      emission.data.id + "/biomasse",
      computeFirstLineContentCell
    ),
    dummyComputeSingleEmissionLine(
      {
        data: {
          ...emission.data,
          emissionsAnnuelles: emissionAnnuellesNonBiomasse,
        },
        errors: emission.errors,
      },
      classes,
      editAction,
      suppressAction,
      `${substanceName} non biomasse`,
      combustibleName,
      isValidated,
      validationFacteursPath,
      emission.data.id + "/nonBiomasse",
      computeFirstLineContentCell
    ),
  ];
};
