import { SpeciesMapper } from "../../utils/types";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";

// This object is used to make the link between the species in the dropdown and in the back-end
const speciesMapper: SpeciesMapper = {
  1: "caillesEmission",
  2: "canardsEmission",
  3: "dindesDindonsEmission",
  4: "pintadesEmission",
  5: "poulesPondeusesEmission",
  6: "pouletsDeChairEmission",
  7: "poulettesEmission",
  8: "autresVolaillesEmission",
  9: "porceletsEnPostSevrageEmission",
  10: "porcsDeProductionEmission",
  11: "cochettesEmission",
  12: "truiesEnMaterniteEmission",
  13: "truiesEnAttenteSaillieEtGestantesEmission",
  14: "verratsEmission",
};

const speciesDropdown: CleanOptionProps[] = [
  { value: 1, label: "Cailles" },
  { value: 2, label: "Canards" },
  { value: 3, label: "Dindes et dindons" },
  { value: 4, label: "Pintades" },
  { value: 5, label: "Poules pondeuses" },
  { value: 6, label: "Poulets de chair" },
  { value: 7, label: "Poulettes" },
  { value: 8, label: "Autres volailles" },
  { value: 9, label: "Porcelets en post-sevrage" },
  { value: 10, label: "Porcs de production" },
  { value: 11, label: "Cochettes" },
  { value: 12, label: "Truies en maternité" },
  {
    value: 13,
    label: "Truies en attente de saillie et truies gestantes",
  },
  { value: 14, label: "Verrats" },
];

export const selectPossibleValues = {
  speciesDropdown: speciesDropdown,
  speciesMapper: speciesMapper,
};
