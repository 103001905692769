import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import Spinner from "common/presentational/Spinner";
import Button from "common/button";
import classNames from "classnames";
import Row from "common/presentational/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FileDto } from "api/gen";
import { FileSectionEnum, HandledFile } from "../types";
import { handledFileTitle, isFileSpinnerVisible } from "../utils";
import DownloadFileButton from "./DownloadFileButton";

const useStyle = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-start",
  },
  spacing: {
    marginLeft: "3px",
  },
  title: {
    alignSelf: "center",
    marginRight: "12px",
  },
  row: {
    width: "auto",
  },
});

interface FileRowProps {
  file: HandledFile;
  section: FileSectionEnum;
  isDisabled: boolean;
  addFileToDelete: (fileDto: FileDto) => Promise<void>;
}

export const FileRow = ({
  file,
  section,
  isDisabled,
  addFileToDelete,
}: FileRowProps): React.ReactElement => {
  const classes = useStyle();

  const isSpinnerVisible = isFileSpinnerVisible(file);

  return (
    <Row additionalClassname={classes.container}>
      <div className={classes.title}>{handledFileTitle(file)}</div>
      {isSpinnerVisible ? (
        <Spinner
          additionalClassname={classNames(classes.spacing, classes.row)}
          height={20}
        />
      ) : (
        <>
          <DownloadFileButton file={file} section={section} />
          <Button
            additionalClassname={classNames(classes.spacing)}
            isDisabled={isDisabled}
            isReversed
            text={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() =>
              file.uploadedFile && addFileToDelete(file.uploadedFile)
            }
          />
        </>
      )}
    </Row>
  );
};
