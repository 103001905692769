import { useCallback, useEffect, useRef, useState } from "react";
import {
  AuthenticatedApiContextInterface,
  useAuthenticatedApi,
} from "Authenticator/AuthenticatedApi";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageReferential } from "common/backErrors/errorMessages";
import {
  FetchedReferentialPerYear,
  FetchedReferentials,
  ReferentialKeyType,
  ReferentialsObject,
} from "pages/CommonSpace/ReferentialsContext/types";
import { useReferentialsAndSetter } from "pages/CommonSpace/ReferentialsContext";
import {
  DeclarationPageHeaderProps,
  useDeclarationPageHeaderInfo,
} from "../AppPage/DeclarationPageHeaderContext";
import { fetchReferentials } from "./fetchReferentials";
import _ from "lodash";

const getYearToFetch = async (
  headerInfo: DeclarationPageHeaderProps | null,
  controllers: AuthenticatedApiContextInterface
): Promise<number> => {
  if (headerInfo === null) {
    // if the headerInfo is null, we're in an inspector page and should take the current or next campaign year to fetch referentials
    return (
      await controllers.campaignController.getAdministrableCampaignUsingGET()
    ).annee;
  }
  return parseInt(headerInfo.declarationAnnee);
};

export function useReferentials<Subset extends ReferentialKeyType>(
  wanted: Subset[]
): FetchedReferentials<Subset> | null {
  const controllers: AuthenticatedApiContextInterface = useAuthenticatedApi();
  const headerInfo = useDeclarationPageHeaderInfo();
  const openModale = useAlertModale();

  const {
    referentials: sharedReferentials,
    setter: setSharedReferentials,
  } = useReferentialsAndSetter();
  const [localReferentials, setLocalReferentials] = useState<
    Partial<FetchedReferentials<Subset>> | undefined
  >(undefined);

  const isMounted = useRef<boolean>(true);
  const isFetching = useRef<boolean>(false);
  const [yearToFetch, setYearToFetch] = useState<number | null>(null);

  const changeReferentials = useCallback(
    (newReferential: Partial<ReferentialsObject>) => {
      if (yearToFetch !== null) {
        setLocalReferentials(newReferential);
        setSharedReferentials((oldReferentials: FetchedReferentialPerYear) => {
          const oldReferentialsCurrentYear = oldReferentials[yearToFetch];
          oldReferentials[yearToFetch] = {
            ...oldReferentialsCurrentYear,
            ...newReferential,
          };
          return oldReferentials;
        });
      }
    },
    [setSharedReferentials, yearToFetch]
  );

  useEffect(() => {
    let shouldUpdateYear = true;

    getYearToFetch(headerInfo, controllers).then(newYearToFetch => {
      if (shouldUpdateYear) {
        setYearToFetch(newYearToFetch);
      }
    });

    return () => {
      shouldUpdateYear = false;
    };
  }, [controllers, headerInfo, setYearToFetch]);

  useEffect(() => {
    isFetching.current = false;
  }, [yearToFetch]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // fetch exactly the referentials we need, and put them in our state (both shared across pages and local)
  useEffect(() => {
    let shouldUpdateReferentials = true;
    const yearFetched = yearToFetch;

    if (isFetching.current || !yearFetched) {
      return;
    }
    isFetching.current = true;

    let listReferentialsToFetch = wanted;

    if (sharedReferentials[yearFetched] === undefined) {
      sharedReferentials[yearFetched] = {};
    } else {
      const alreadyFetchedReferentials: string[] = Object.keys(
        sharedReferentials[yearFetched]
      );
      //the as is justified because wanted is a Subset[], so we can't get something which won't be anymore
      listReferentialsToFetch = _.difference(
        wanted,
        alreadyFetchedReferentials
      ) as Subset[];
    }

    const onException = async (exception: ExceptionInformation) => {
      await openModale(backAlertMessage(exception, backMessageReferential));
    };

    fetchReferentials(
      yearFetched,
      listReferentialsToFetch,
      controllers,
      onException
    ).then(newlyFetchedReferentials => {
      if (isMounted.current && shouldUpdateReferentials) {
        // reminder : updates only successfully fetched referentials
        changeReferentials({
          ...sharedReferentials[yearFetched],
          ...newlyFetchedReferentials,
        });
        // we have finished trying to fetch everything
        isFetching.current = false;
      }
    });

    return () => {
      //taking the reverse of the condition for the early return
      if (!isFetching.current && yearToFetch !== null) {
        shouldUpdateReferentials = false;
      }
    };
  }, [
    changeReferentials,
    controllers,
    openModale,
    sharedReferentials,
    wanted,
    yearToFetch,
  ]);

  // the as is justified because wanted is a Subset of ReferentialsObject, which  has for keys... ReferentialKeyType
  if (
    localReferentials !== undefined &&
    wanted.every(
      key =>
        localReferentials[key] !== null && localReferentials[key] !== undefined
    )
  ) {
    return wanted.reduce((acc, wantedKey) => {
      return {
        ...acc,
        [wantedKey]: localReferentials[wantedKey],
      };
    }, {}) as FetchedReferentials<Subset>;
  }

  return null;
}
