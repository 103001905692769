import { makeStyles } from "@material-ui/styles";
import { computeClassname } from "./utils";
import DatePicker from "react-date-picker";
import classNames from "classnames";
import { DummyDateInputFieldProps } from "../types/dummyTypes";
import React from "react";
import { TEXT_INPUT_STYLE } from "../../../../theme";

const useStyles = makeStyles({
  ...TEXT_INPUT_STYLE,
  subContainer: {
    "& > div": {
      border: "none",
      width: "100%",
    },
  },
});

const DummyDateInput = ({
  additionalClassName,
  disabled,
  value,
  name,
  error,
  onChange,
}: DummyDateInputFieldProps): React.ReactElement => {
  const classes = useStyles();

  const inputClassName = computeClassname(
    // todo decide of the right way to pass errors to compute the classname, and eventually overriding style with a second className.
    classes,
    error,
    disabled,
    additionalClassName
  );
  return (
    <DatePicker
      disabled={disabled}
      className={classNames(inputClassName, classes.subContainer)}
      value={value || undefined}
      name={name}
      maxDate={new Date(9999, 11, 31)}
      onChange={(newDate: Date | [Date] | [Date, Date]) => {
        if (Array.isArray(newDate)) {
          throw Error("should not be an array of dates");
        } else {
          onChange && onChange(newDate);
        }
      }}
      locale="fr-FR"
      // according to the doc https://www.npmjs.com/package/react-date-picker, the following fields should be alright. I suspect this is because the ts file used is the one of
      // https://github.com/Hacker0x01/react-datepicker (because looking for react-datepicker type returns references to the second one and not the first)
      // dayPlaceholder="JJ"
      // monthPlaceholder="MM"
      // yearPlaceholder="AAAA"
    />
  );
};

export default DummyDateInput;
