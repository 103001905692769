import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import { PopulatedTransportProductInArray } from "./utils/types";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import Button from "common/button";
import { convertInFranceStringToBoolean } from "./utils/converters";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_PRODUCTION_TRANSPORT_TABLEAU } from "common/path/path18Now";
import Row from "common/presentational/Row";

interface TransportProductArrayProps {
  transportProductsInArray: PopulatedTransportProductInArray[];
  setTransportProductsInArray: (
    products: PopulatedTransportProductInArray[]
  ) => void;
  onEdit: (factory: PopulatedTransportProductInArray | undefined) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  table: {
    marginTop: "20px",
  },
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const TransportProductArray = ({
  transportProductsInArray,
  setTransportProductsInArray,
  onEdit,
  isDisabled,
}: TransportProductArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>Famille de rattachement</p>,
    <p>Type de produits expédiés</p>,
    <p>Destination</p>,
    <p>Tonnage (ktonnes)</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = transportProductsInArray.map(
    transportProductInArray => [
      <p
        className={isDisabled ? classes.disabledLink : classes.link}
        onClick={() => {
          if (!isDisabled) {
            onEdit(transportProductInArray);
          }
        }}
      >
        {transportProductInArray.displayedProduct.usageFamily &&
        transportProductInArray.displayedProduct.usageFamily.object.precision
          ? transportProductInArray.displayedProduct.usageFamilyPrecision
          : transportProductInArray.displayedProduct.usageFamily &&
            transportProductInArray.displayedProduct.usageFamily.label}
      </p>,
      <p>{transportProductInArray.displayedProduct.productType.label}</p>,
      <p>
        {convertInFranceStringToBoolean(
          transportProductInArray.displayedProduct.inFrance
        )
          ? transportProductInArray.displayedProduct.department &&
            transportProductInArray.displayedProduct.department.label
          : transportProductInArray.displayedProduct.country &&
            transportProductInArray.displayedProduct.country.label}
      </p>,
      <p>
        {computeNumberWithSeparator(
          transportProductInArray.displayedProduct.tonnage
        )}
      </p>,
      <CommonEntityButton
        handlerEdit={() => onEdit(transportProductInArray)}
        commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_TABLEAU}/${transportProductInArray.id}`}
        handlerSuppress={() =>
          setTransportProductsInArray(
            transportProductsInArray.filter(
              t => t.id !== transportProductInArray.id
            )
          )
        }
        isValidated={isDisabled}
      />,
    ]
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        additionalClassName={classes.table}
        preferredColWidths={[250, 140, 140, 140, ACTION_COLUMN_DEFAULT_WIDTH]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setTransportProductsInArray([])}
        />
      </Row>
    </>
  );
};

export default TransportProductArray;
