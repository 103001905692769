import React, { ReactNode } from "react";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BORDER_GREY,
  FOUR_COLUMNS_CARD_GRID,
  PINK,
  SECTION_FONT_SIZE,
} from "theme";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";

import DashboardProgressBar from "./components/ProgressBar";
import ExportButtons from "./components/ExportButtons";
import MainForm from "./MainForm";

interface DashboardCardsContainerProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
  isVerificateur?: boolean;
  isLecteur?: boolean;
  children: ReactNode;
}

const useStyles = makeStyles({
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
  },
  title: {
    color: PINK,
    fontSize: SECTION_FONT_SIZE,
    textTransform: "uppercase",
    fontWeight: "normal",
  },
  containerProgressBar: {
    width: "70%",
    height: "100%",
    margin: "auto",
  },
  mediaQueries: FOUR_COLUMNS_CARD_GRID,
});

const DashboardCardsContainer = ({
  declarationApi,
  declarationAnnee,
  declarationEtablissement,
  isVerificateur,
  isLecteur,
  children,
}: DashboardCardsContainerProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {!isVerificateur && !isLecteur && (
        <DashboardProgressBar declarationApi={declarationApi} />
      )}

      <MainForm />
      <Row
        additionalStyle={{
          display: "grid",
          gridGap: "20px",
          height: "auto",
        }}
        additionalClassname={classes.mediaQueries}
      >
        {children}
      </Row>
      {!isVerificateur && !isLecteur && (
        <ExportButtons
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
        />
      )}
    </div>
  );
};

export default DashboardCardsContainer;
