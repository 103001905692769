import React, { ReactElement } from "react";
import {
  CREATED,
  FORCED,
  RECALLED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import { useUserData } from "Authenticator/UserData";
import ActionButtonsContainer from "../../../toNow/Dashboard/Components/Container/ActionButtonsContainer";
import CancelTakeoverButton from "../../../toNow/Actions/Global/CancelTakeoverButton";
import QuotaReviewButton from "../../../toNow/Actions/Quotas/QuotaReviewButton";
import QuotaApproveButton from "../Quotas/QuotaApproveButton";
import QuotaTakeoverButton from "../../../toNow/Actions/Quotas/QuotaTakeoverButton";
import { AuthDroitDtoAuthProfileEnum } from "api/gen";
import { QuotasActionButtonsProps } from "../../../toNow/Actions/types";

const InspecteurQuotasActionButtons = ({
  quotaState,
  workflowTarget,
  path,
  isQuotaCampaignActive,
}: QuotasActionButtonsProps): ReactElement => {
  const { isSuperAdmin, userInfo } = useUserData();
  const isAdminBqa =
    userInfo.droits[0].authProfile ===
    AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA;

  return (
    <ActionButtonsContainer>
      {quotaState.isDeclarationStateContaining([FORCED]) &&
        isQuotaCampaignActive && (
          <CancelTakeoverButton workflowTarget={workflowTarget} />
        )}

      {!quotaState.isDeclarationStateContaining([FORCED, CREATED]) &&
        (isSuperAdmin || isAdminBqa || isQuotaCampaignActive) && (
          <QuotaTakeoverButton workflowTarget={workflowTarget} path={path} />
        )}

      {quotaState.isDeclarationStateContaining([
        SUBMITTED,
        RECALLED,
        VALIDATED,
      ]) &&
        isQuotaCampaignActive && (
          <QuotaReviewButton
            text={
              quotaState.isDeclarationStateContaining([RECALLED])
                ? "Accepter demande de modification"
                : "Mettre en révision quotas"
            }
            workflowTarget={workflowTarget}
            path={path}
          />
        )}

      {quotaState.isDeclarationStateContaining([SUBMITTED, FORCED]) && (
        <QuotaApproveButton workflowTarget={workflowTarget} path={path} />
      )}
    </ActionButtonsContainer>
  );
};

export default InspecteurQuotasActionButtons;
