import React, { ReactElement } from "react";
import { useUserData } from "Authenticator/UserData";
import SectionNextActionCard from "../Cards/SectionNextActionCard";
import { useTypeActivitePercentageProgress } from "../utils/progressionUtils";

const TypeActiviteNextActionCard = (): ReactElement => {
  const { isDeclarant } = useUserData();

  const percentageProgress = useTypeActivitePercentageProgress();
  const isValidated = percentageProgress === 100;

  if (!isDeclarant || isValidated) {
    return <></>;
  }

  return (
    <SectionNextActionCard>
      Veuillez attendre que l’inspection ait complété et validé la page “Types
      d’activités” pour pouvoir accéder à la déclaration.
    </SectionNextActionCard>
  );
};

export default TypeActiviteNextActionCard;
