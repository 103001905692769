import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import {
  BACKGROUND_GREY,
  DISABLED_BUTTON_HOVER_BACKGROUND,
  SELECT_DEFAULT_WIDTH,
} from "theme";
import { declarantFieldMatcher, Declarants, DeclarantType } from "./types";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import Button from "common/button";
import uuid from "uuid";
import SimpleInputWithTextLabelDisplayer from "common/form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { useConfirmationModale } from "common/modale/hooks";
import { validate, validationSchema } from "./validation";
import FormikBlocFullContext24Now from "../../../toNow/versionedElements/FormikBlocFullContext24Now";
import { Declaration24Now } from "../../../toNow/versionedElements/declarationHooks24Now";

//to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  addButton: {
    borderRadius: "50%",
    border: "none",
    color: "white",
    outline: "none",
    padding: "0px",
    marginLeft: "20px",
    backgroundColor: BACKGROUND_GREY,
    width: "40px",
    fontSize: "35px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
  },
  removeButton: {
    padding: "3px 4px",
  },
});

export const DeclarantIdentityList = (
  props: CommonConnectedFormikBlocProps<Declarants, Declaration24Now>
): React.ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "declarant-identity",
  };

  const TextField = useTextFieldGenerator(commonProps);

  return (
    <FormikBlocFullContext24Now
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      setHasChanges={props.setHasChanges}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      additionalFieldValidation={validate}
      title={"identité des déclarants"}
      pathToValidate={props.path}
      areGlobalCommentsAllowed={true}
      updateHandler={props.updateHandler}
      cancelAction={props.cancelAction}
      renderContent={(props, shouldDisabledFields) => {
        return (
          <>
            <FieldArray
              name="declarants"
              render={arrayHelpers => (
                <div>
                  {props.values.declarants.map((_, index) => (
                    <div key={index} style={{ position: "relative" }}>
                      <TextField
                        label={`Nom *`}
                        name={`declarants.${index}.${declarantFieldMatcher.lastName}`}
                        disabled={shouldDisabledFields}
                      />
                      <TextField
                        label={`Prénom *`}
                        name={`declarants.${index}.${declarantFieldMatcher.firstname}`}
                        disabled={shouldDisabledFields}
                      />
                      <TextField
                        label={`Adresse email *`}
                        name={`declarants.${index}.${declarantFieldMatcher.email}`}
                        disabled={shouldDisabledFields}
                      />
                      <TextField
                        label={`Téléphone *`}
                        name={`declarants.${index}.${declarantFieldMatcher.phone}`}
                        disabled={shouldDisabledFields}
                      />
                      <TextField
                        label={`Fonction au sein de l'entreprise *`}
                        name={`declarants.${index}.${declarantFieldMatcher.declarantRoleInEnterprise}`}
                        disabled={shouldDisabledFields}
                      />
                      {index >= 1 && (
                        <Row>
                          <div style={{ width: "50%" }}></div>
                          <div style={{ width: "50%" }}>
                            <div
                              style={{
                                width: SELECT_DEFAULT_WIDTH,
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                                height: "100%",
                              }}
                            >
                              <Button
                                type="button"
                                onClick={() => {
                                  openConfirmationModale(
                                    "Êtes vous sûr de vouloir supprimer ce déclarant ?",
                                    () => arrayHelpers.remove(index)
                                  );
                                }}
                                isDisabled={shouldDisabledFields}
                                additionalClassname={classes.removeButton}
                                text="Supprimer le déclarant"
                              />
                            </div>
                          </div>
                        </Row>
                      )}

                      <Row />
                    </div>
                  ))}
                  <SimpleInputWithTextLabelDisplayer
                    label="Ajouter une autre personne à contacter"
                    name="new-contact"
                    disabled={shouldDisabledFields}
                    errorData={{}}
                    commonProps={commonProps}
                    renderInput={() => (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            const newDeclarant: DeclarantType = {
                              id: uuid(),
                              nom: "",
                              prenom: "",
                              email: "",
                              telephone: "",
                              role: "",
                            };
                            arrayHelpers.push(newDeclarant);
                          }}
                          disabled={shouldDisabledFields}
                          className={classes.addButton}
                        >
                          +
                        </button>
                      </>
                    )}
                  />
                </div>
              )}
            />
          </>
        );
      }}
    />
  );
};
