import { Declaration1919 } from "../../../versionedElements/declarationHooks1919";
import { GeneralInformationsForm } from "../BlocInfosGenerales/utils/types";
import { InertWasteForm } from "../BlocDechetsInertes/utils/types";
import { computeTotal } from "../BlocDechetsInertes/utils/computers";
import { NoiseMeasuresForm } from "../BlocMesuresBruit/utils/types";
import { VibrationMeasuresForm } from "../BlocMesuresVibrations/utils/types";
import {
  DustRejectionForm,
  DustRejectionPointInArray,
} from "../BlocRejetPoussieres/utils/types";
import { convertDisplayedToDustRejectionPoint } from "../BlocRejetPoussieres/utils/converters";
import {
  DustFalloutForm,
  DustFalloutPointInArray,
} from "../BlocRetombeesPoussieres/utils/types";
import { convertDisplayedToDustFalloutPoint } from "../BlocRetombeesPoussieres/utils/converters";
import { AreasForm } from "../BlocSuperficies/utils/types";
import { AreasOtherInfosForm } from "../BlocSuperficiesAutresRenseignements/utils/types";
import { Nullable } from "common/utils/types";

export const convertDeclarationDtoUsingGeneralInformationsForm = (
  declaration: Declaration1919,
  values: GeneralInformationsForm
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.general.prodMax =
    values.maxProductionPerYear;
  declaration.body.sections.carriere.environnement.general.prodMoy =
    values.averageProductionPerYear;
  declaration.body.sections.carriere.environnement.general.dateFin =
    values.endAuthorizationDate &&
    values.endAuthorizationDate.toISOString().split(".")[0] + "Z";
  declaration.body.sections.carriere.environnement.general.eau =
    values.eau && values.eau.value === 0;
  declaration.body.sections.carriere.environnement.general.souterrain =
    values.souterrain && values.souterrain.value === 0;
  return declaration;
};

export const convertDeclarationDtoUsingInertWasteForm = (
  declaration: Declaration1919,
  values: InertWasteForm
): Declaration1919 => {
  const inertWaste =
    declaration.body.sections.carriere.environnement.dechetsInertes;
  inertWaste.aDesApportsDechetsInertes = values.inertWasteBring;
  if (inertWaste.tableauDechetsInertes && values.inertWasteBring) {
    inertWaste.capaciteRestanteTermeAnneeRef =
      values.remainingCapacityEndRefYear;
    // TODO [GEREP-1133] rewrite the zero management in the following fields
    inertWaste.tableauDechetsInertes.aRemblayer.terreEtCailloux =
      values.groundAndStoneToBackfill || 0;
    inertWaste.tableauDechetsInertes.aRemblayer.autresMateriaux =
      values.otherStuffToBackfill || 0;
    inertWaste.tableauDechetsInertes.aRecycler.terreEtCailloux =
      values.groundAndStoneToRecycle || 0;
    inertWaste.tableauDechetsInertes.aRecycler.autresMateriaux =
      values.otherStuffToRecycle || 0;
    inertWaste.tableauDechetsInertes.totalMateriauxEntrant = computeTotal(
      values
    );
  } else {
    inertWaste.tableauDechetsInertes.aRemblayer.terreEtCailloux = 0;
    inertWaste.tableauDechetsInertes.aRemblayer.autresMateriaux = 0;
    inertWaste.tableauDechetsInertes.aRecycler.terreEtCailloux = 0;
    inertWaste.tableauDechetsInertes.aRecycler.autresMateriaux = 0;
    inertWaste.tableauDechetsInertes.totalMateriauxEntrant = 0;
  }
  return declaration;
};

export const convertDeclarationDtoUsingNoiseMeasuresForm = (
  declaration: Declaration1919,
  values: NoiseMeasuresForm
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.bruit.mesures.mesureEffectuee =
    values.noiseMeasuresDone;
  if (values.noiseMeasuresDone) {
    declaration.body.sections.carriere.environnement.bruit.mesures.nbPointsMesure =
      values.noiseMeasuresPointsNumber;
    declaration.body.sections.carriere.environnement.bruit.mesures.nbCampagnesMesure =
      values.noiseMeasuresCampaignNumber;
  } else {
    declaration.body.sections.carriere.environnement.bruit.mesures.nbPointsMesure = null;
    declaration.body.sections.carriere.environnement.bruit.mesures.nbCampagnesMesure = null;
  }
  declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiquesDansEnvironnement =
    values.specificNoisesMeasures;
  if (values.specificNoisesMeasures) {
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.mesureEffectuee =
      values.specificNoisesMeasuresDone;
  } else {
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.mesureEffectuee = null;
  }
  if (values.specificNoisesMeasures && values.specificNoisesMeasuresDone) {
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.nbPointsMesure =
      values.specificNoiseMeasuresPointsNumber;
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.nbCampagnesMesure =
      values.specificNoiseMeasuresCampaignNumber;
  } else {
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.nbPointsMesure = null;
    declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques.nbCampagnesMesure = null;
  }
  return declaration;
};

export const convertDeclarationDtoUsingVibrationMeasuresForm = (
  declaration: Declaration1919,
  values: VibrationMeasuresForm
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.vibration.soumisMesureVibrations =
    values.vibrationMeasuresSubmitted;
  if (values.vibrationMeasuresSubmitted) {
    declaration.body.sections.carriere.environnement.vibration.campagneExplosion =
      values.explosiveUtilisation;
  } else {
    declaration.body.sections.carriere.environnement.vibration.campagneExplosion = null;
  }
  if (values.vibrationMeasuresSubmitted && values.explosiveUtilisation) {
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.mesureEffectuee =
      values.explosiveMeasuresDone;
  } else {
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.mesureEffectuee = null;
  }
  if (
    values.vibrationMeasuresSubmitted &&
    values.explosiveUtilisation &&
    values.explosiveMeasuresDone
  ) {
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.nbPointsMesure =
      values.explosiveMeasurePointsNumber;
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.nbCampagnesMesure =
      values.explosiveMeasureCampaignsNumber;
  } else {
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.nbPointsMesure = null;
    declaration.body.sections.carriere.environnement.vibration.mesuresExplosion.nbCampagnesMesure = null;
  }
  if (values.vibrationMeasuresSubmitted) {
    declaration.body.sections.carriere.environnement.vibration.campagneVibration =
      values.vibrationUtilisation;
  } else {
    declaration.body.sections.carriere.environnement.vibration.campagneVibration = null;
  }
  if (values.vibrationMeasuresSubmitted && values.vibrationUtilisation) {
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.mesureEffectuee =
      values.vibrationMeasuresDone;
  } else {
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.mesureEffectuee = null;
  }
  if (
    values.vibrationMeasuresSubmitted &&
    values.vibrationUtilisation &&
    values.vibrationMeasuresDone
  ) {
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.nbPointsMesure =
      values.vibrationMeasurePointsNumber;
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.nbCampagnesMesure =
      values.vibrationMeasureCampaignsNumber;
  } else {
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.nbPointsMesure = null;
    declaration.body.sections.carriere.environnement.vibration.mesuresVibration.nbCampagnesMesure = null;
  }
  return declaration;
};

export const convertDeclarationDtoUsingDustRejectionForm = (
  declaration: Declaration1919,
  values: DustRejectionForm,
  emissionPointsInArray: DustRejectionPointInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.poussiereCanalisee.doitEffectuerMesures =
    values.inheritedCareer;
  if (values.inheritedCareer) {
    declaration.body.sections.carriere.environnement.poussiereCanalisee.mesureEffectuee =
      values.doneMeasures;
  } else {
    declaration.body.sections.carriere.environnement.poussiereCanalisee.mesureEffectuee = null;
  }
  if (values.doneMeasures) {
    declaration.body.sections.carriere.environnement.poussiereCanalisee.mesuresDesRejets = convertDisplayedToDustRejectionPoint(
      emissionPointsInArray
    );
  } else {
    declaration.body.sections.carriere.environnement.poussiereCanalisee.mesuresDesRejets = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingDustFalloutForm = (
  declaration: Declaration1919,
  values: DustFalloutForm,
  emissionPointsInArray: DustFalloutPointInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.poussiereAtmospherique.mesureEffectuee =
    values.doneMeasures;
  if (values.doneMeasures) {
    declaration.body.sections.carriere.environnement.poussiereAtmospherique.mesuresDesRetombee = convertDisplayedToDustFalloutPoint(
      emissionPointsInArray
    );
  } else {
    declaration.body.sections.carriere.environnement.poussiereAtmospherique.mesuresDesRetombee = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingAreasForm = (
  declaration: Declaration1919,
  values: AreasForm
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.superficie.aRemisEnEtat =
    values.isRestoredThisYear;
  if (values.isRestoredThisYear) {
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneAgricole =
      values.agriculturalZonePreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneForestiere =
      values.forestryZonePreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEcologique =
      values.ecologicalZoneGroundPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEconomique =
      values.economicZoneGroundPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.autre =
      values.otherGroundPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.typeRemiseEtatAutre =
      values.repairTypeGroundPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEcologique =
      values.ecologicalZoneWaterPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEconomique =
      values.economicZoneWaterPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.autre =
      values.otherWaterPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.typeRemiseEtatAutre =
      values.repairTypeWaterPreviousYear;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneAgricole =
      values.agriculturalZone;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneForestiere =
      values.forestryZone;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEcologique =
      values.ecologicalZoneGround;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEconomique =
      values.economicZoneGround;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.autre =
      values.otherGround;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.typeRemiseEtatAutre =
      values.repairTypeGround;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEcologique =
      values.ecologicalZoneWater;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEconomique =
      values.economicZoneWater;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.autre =
      values.otherWater;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.typeRemiseEtatAutre =
      values.repairTypeWater;
  } else {
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneAgricole = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneForestiere = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEcologique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEconomique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.autre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatTerre.typeRemiseEtatAutre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEcologique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEconomique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.autre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneePrecedentes.remiseEtatEau.typeRemiseEtatAutre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneAgricole = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneForestiere = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEcologique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEconomique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.autre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatTerre.typeRemiseEtatAutre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEcologique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEconomique = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.autre = null;
    declaration.body.sections.carriere.environnement.superficie.remisEnEtatAnneeEcoulee.remiseEtatEau.typeRemiseEtatAutre = null;
  }
  return declaration;
};

export const convertDeclarationDtoUsingAreasOtherInfosForm = (
  declaration: Declaration1919,
  values: Nullable<AreasOtherInfosForm>
): Declaration1919 => {
  declaration.body.sections.carriere.environnement.autresRenseignementsSuperficie.superficieAutorisee =
    values.authorizedArea;
  declaration.body.sections.carriere.environnement.autresRenseignementsSuperficie.superficieRestantAExploiter =
    values.remainingArea;
  declaration.body.sections.carriere.environnement.autresRenseignementsSuperficie.superficieExploitee =
    values.exploitedThisYearArea;
  declaration.body.sections.carriere.environnement.autresRenseignementsSuperficie.superficieRestituee =
    values.restoredArea;
  return declaration;
};
