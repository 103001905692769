import AlcModeleAnneePrecedenteButton from "./AlcModeleAnneePrecedenteButton";
import { AlcModeleViergeButton } from "./AlcModeleViergeButton";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import classNames from "classnames";
import { useDeclaration2122 } from "pages/CompanySpace/from21/to22/versionedElements/declarationHooks2122";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
});

export const AlcModeleDownloader = (): React.ReactElement => {
  const classes = useStyles();

  const declaration = useDeclaration2122();
  const {
    lastYearAlcVerifieUuidFileMap,
    installations,
  } = declaration.computed.sections.quotas.allocations;

  const isAtLeastOneAlcAvailable = installations.some(
    ({ id }) => id && id in lastYearAlcVerifieUuidFileMap
  );

  return (
    <>
      {installations.map(({ id, nim }) => (
        <div className={classes.container}>
          <AlcModeleAnneePrecedenteButton
            fileDto={lastYearAlcVerifieUuidFileMap[id]}
            installationNim={nim}
          />
        </div>
      ))}

      <p className={classNames(classes.container, classes.bold)}>
        Ci-dessous, le modèle vierge de rapport ALC est disponible au
        téléchargement
      </p>

      <div className={classes.container}>
        <AlcModeleViergeButton
          lastYearModeleAvailable={isAtLeastOneAlcAvailable}
        />
      </div>
    </>
  );
};
