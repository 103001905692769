import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";
import arrowImg from "icons/arrow.svg";
import classNames from "classnames";

const useStyles = makeStyles({
  header: {
    cursor: "pointer",
    padding: "10px",
    color: PINK,
    fontSize: "1.125em",
  },
  arrow: {
    transition: ".2s",
    width: "10px",
    marginLeft: "20px",
  },
  arrowOpen: {
    transform: "rotate(90deg) scale(-1)",
  },
  arrowClose: {
    transform: "rotate(90deg)",
  },
});

interface CollapsibleProps {
  title: string;
  children: React.ReactElement;
  initiallyOpen?: boolean;
  additionalTitleClassname?: string;
}

const Collapsible = ({
  title,
  children,
  initiallyOpen = true,
  additionalTitleClassname,
}: CollapsibleProps): React.ReactElement => {
  const [open, setOpen] = useState(initiallyOpen);

  const classes = useStyles();

  const togglePanel = () => {
    setOpen(!open);
  };

  return (
    <div>
      <h5
        onClick={togglePanel}
        className={classNames(classes.header, additionalTitleClassname)}
      >
        {title}
        <img
          src={arrowImg}
          alt="arrow"
          className={classNames(
            classes.arrow,
            { [classes.arrowOpen]: open },
            { [classes.arrowClose]: !open }
          )}
        />
      </h5>
      {open && <div>{children}</div>}
    </div>
  );
};

export default Collapsible;
