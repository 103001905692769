import { DustRejectionForm, DustRejectionPointInArray } from "./types";
import { MesurePoussiereCanaliseeDto20Now } from "api/gen";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";

export const convertDustRejectionPointToDisplayed = (
  emissionPointsDto: MesurePoussiereCanaliseeDto20Now[]
): DustRejectionPointInArray[] => {
  return emissionPointsDto.map(singleEmissionPointDto => {
    return {
      data: {
        id: singleEmissionPointDto.id,
        name: singleEmissionPointDto.libelle,
        date: singleEmissionPointDto.date
          ? new Date(singleEmissionPointDto.date)
          : null,
        dustConcentration: singleEmissionPointDto.concentrationPoussiere,
        averageFlow: singleEmissionPointDto.debitMoyenCampagne,
        measuredPm10: singleEmissionPointDto.percentagePM10,
      },
      errors: {},
    };
  });
};

export const convertDisplayedToDustRejectionPoint = (
  populatedEmissionPoints: DustRejectionPointInArray[]
): MesurePoussiereCanaliseeDto20Now[] => {
  return populatedEmissionPoints.map(singlePopulatedEmissionPoint => {
    return {
      id: singlePopulatedEmissionPoint.data.id,
      libelle: singlePopulatedEmissionPoint.data.name,
      date: singlePopulatedEmissionPoint.data.date
        ? singlePopulatedEmissionPoint.data.date.toISOString().split(".")[0] +
          "Z"
        : null,
      concentrationPoussiere:
        singlePopulatedEmissionPoint.data.dustConcentration,
      debitMoyenCampagne: singlePopulatedEmissionPoint.data.averageFlow,
      percentagePM10: singlePopulatedEmissionPoint.data.measuredPm10,
    };
  });
};

export const convertDtoToDustRejectionForm = (
  declaration: Declaration20Now
): DustRejectionForm => {
  return {
    inheritedCareer:
      declaration.body.sections.carriere.environnement.poussiereCanalisee
        .doitEffectuerMesures,
    doneMeasures: !!declaration.body.sections.carriere.environnement
      .poussiereCanalisee.mesureEffectuee,
  };
};
