import { makeStyles } from "@material-ui/styles";
import React from "react";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import _ from "lodash";
import { ProductedWasteArrayProps, ProductedWasteStorage } from "./types";
import { DechetProduitDto1819LieuOperationEnum } from "api/gen";
import {
  WASTE_CODE_DISPLAY_COL_WIDTH,
  WASTE_LIBELLE_DISPLAY_COL_WIDTH,
} from "../utils/Constants";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { productionLocation } from "../utils/Referentiels";
import { findElementMatchingTemplate } from "common/utils/methods";
import { WasteReferentiels } from "../types";
import { PATH_DECHET_PRODUCTION_LIST } from "common/path/path18Now";
import { formatWasteLabel } from "../utils/formater";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const computeCellForCountryOrDep = (
  waste: ProductedWasteStorage,
  classes: Record<string, string>,
  referentiels: WasteReferentiels
): React.ReactElement => {
  if (waste.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE) {
    return computeWrappedCellWithError(
      <p>{productionLocation[0].label}</p>,
      classes,
      undefined
    );
  } else if (
    waste.data.lieuOperation ===
    DechetProduitDto1819LieuOperationEnum.DEPARTEMENT
  ) {
    const departementElement =
      waste.data.departementID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: waste.data.departementID },
            referentiels.departements
          );

    return computeWrappedCellWithError(
      <p>
        {departementElement ? departementElement.nom : waste.data.departementID}
      </p>,
      classes,
      waste.errors.departementID
    );
  } else if (
    waste.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.PAYS
  ) {
    const paysElement =
      waste.data.paysID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: waste.data.paysID },
            referentiels.pays
          );
    return computeWrappedCellWithError(
      <p>{paysElement ? paysElement.designation : waste.data.paysID}</p>,
      classes,
      waste.errors.paysID
    );
  }
  return computeWrappedCellWithError(<p></p>, classes, "");
};

const WasteProductionArray = ({
  isBlocValidated,
  wasteContainer,
  deleteWaste,
  openModal,
  referentiels,
}: ProductedWasteArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const computeSinglePolluantLine = (
    polluant: ProductedWasteStorage,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const wasteCode = polluant.data.codeID;

    const wasteElement = wasteCode
      ? findElementMatchingTemplate({ uuid: wasteCode }, referentiels.polluants)
      : null;

    const wasteName = formatWasteLabel(wasteElement, "");

    const methodCode = polluant.data.method;

    const methodOption = methodCode
      ? findElementMatchingTemplate(
          { backOfficeCode: methodCode },
          referentiels.methods
        )
      : null;

    const methodName = methodOption ? methodOption.frontCode : methodCode;

    const denomination = wasteElement ? wasteElement.libelle : "";

    const firstCellContent = computeFirstLineContentCell(
      () => {
        openModal(polluant);
      },
      wasteName,
      isBlocValidated,
      !_.isEmpty(polluant.errors)
    );
    return [
      computeWrappedCellWithError(
        firstCellContent,
        classes,
        polluant.errors.codeID
      ),
      computeWrappedCellWithError(
        <p title={denomination}>{denomination}</p>,
        classes,
        undefined
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparator(polluant.data.quantite),
        classes,
        polluant.errors.quantite
      ),
      computeWrappedCellWithError(methodName, classes, polluant.errors.method),
      computeWrappedCellWithError(
        !_.isNil(polluant.data.methodReference)
          ? polluant.data.methodReference
          : "",
        classes,
        polluant.errors.methodReference
      ),
      computeWrappedCellWithError(
        !_.isNil(polluant.data.operationElimination) ? (
          <p title={polluant.data.operationElimination || undefined}>
            {polluant.data.operationElimination}
          </p>
        ) : (
          ""
        ),
        classes,
        polluant.errors.operationElimination
      ),
      computeCellForCountryOrDep(polluant, classes, referentiels),
      computeWrappedCellWithError(
        <p title={polluant.data.nomEtablissementReception || undefined}>
          {polluant.data.nomEtablissementReception}
        </p>,
        classes,
        polluant.errors.nomEtablissementReception
      ),
      computeWrappedCellWithError(
        <p title={polluant.data.adresseEtablissementReception || undefined}>
          {polluant.data.adresseEtablissementReception}
        </p>,
        classes,
        polluant.errors.adresseEtablissementReception
      ),
      computeWrappedCellWithError(
        <p title={polluant.data.nomEtablissementElimination || undefined}>
          {polluant.data.nomEtablissementElimination}
        </p>,
        classes,
        polluant.errors.nomEtablissementElimination
      ),
      computeWrappedCellWithError(
        <p title={polluant.data.adresseEtablissementElimination || undefined}>
          {polluant.data.adresseEtablissementElimination}
        </p>,
        classes,
        polluant.errors.adresseEtablissementElimination
      ),
      computeWrappedCellWithError(
        <p>{polluant.data.numeroNotification}</p>,
        classes,
        polluant.errors.numeroNotification
      ),
      // todo: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={() => {
          openModal(polluant);
        }}
        handlerSuppress={() => {
          deleteWaste(polluant);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer ce déchet ?"}
        commentPath={`${PATH_DECHET_PRODUCTION_LIST}/${polluant.data.id}`}
        isValidated={isBlocValidated}
      />,
    ];
  };

  const arrayPolluantsLines = wasteContainer.map(singlePolluantInArray => {
    return computeSinglePolluantLine(singlePolluantInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Code déchet</p>,
        <p>Dénomination</p>,
        <p>Quantité de déchets produite (t/an)</p>,
        <p>Méthode</p>,
        <p>Référence de la méthode</p>,
        <p>Première opération d'élimination ou de valorisation</p>,
        <p>Lieu de l'opération de traitement final</p>,
        <p>Nom du premier établissement réceptionnant le déchet</p>,
        <p>Adresse du premier établissement réceptionnant le déchet</p>,
        <p>Nom de l'établissement assurant l'opération de traitement final</p>,
        <p>
          Adresse de l'établissement assurant l'opération de traitement final
        </p>,
        <p>Numéro de notification</p>,
        <p>Actions</p>,
      ]}
      lines={arrayPolluantsLines}
      preferredColWidths={[
        WASTE_CODE_DISPLAY_COL_WIDTH,
        WASTE_LIBELLE_DISPLAY_COL_WIDTH,
        100,
        100,
        100,
        250,
        200,
        200,
        200,
        250,
        250,
        100,
        ACTION_COLUMN_DEFAULT_WIDTH,
      ]}
    />
  );
};

export default WasteProductionArray;
