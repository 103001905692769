import React, { useCallback } from "react";
import {
  CommonDeclarationPageHeaderAutoUpdaterProps,
  GenericDeclarationPageHeaderAutoUpdater,
} from "../../../../CommonSpace/AppPage/DeclarationPageHeaderContext";
import { Declaration19Now, useDeclaration19Now } from "./declarationHooks19Now";

type DeclarationPageHeaderAutoUpdaterProps = CommonDeclarationPageHeaderAutoUpdaterProps;

export const DeclarationPageHeaderAutoUpdater19Now = ({
  children,
}: DeclarationPageHeaderAutoUpdaterProps) => {
  const useDeclaration = useDeclaration19Now;

  const getEtablissementName = useCallback(
    (declarationDto: Declaration19Now) =>
      declarationDto.header.etablissementName,
    []
  );

  const computeIsDeclarantEmailAvailable = useCallback(
    (declarationDto: Declaration19Now) =>
      declarationDto.body.sections.infoGenerale.declarants
        .map(declarant => declarant.email)
        .some(email => !!email),
    []
  );

  return (
    <GenericDeclarationPageHeaderAutoUpdater<Declaration19Now>
      useDeclaration={useDeclaration}
      computeIsDeclarantEmailAvailable={computeIsDeclarantEmailAvailable}
      getEtablissementName={getEtablissementName}
      children={children}
    />
  );
};
