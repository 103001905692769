import * as Yup from "yup";
import { BaseSchema, SchemaOf as Schema } from "yup";
import { RequiredArraySchema } from "yup/lib/array";
import { MixedSchema } from "yup/lib/mixed";
import { ObjectShape, OptionalObjectSchema } from "yup/lib/object";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import {
  parseToUndefinedIfEmptyString,
  parseToUndefinedIfNull,
} from "../../form/utils";
import {
  generateLessThanNDecimalsMessage,
  integerMessage,
  noPipeMessage,
  percentageMessage,
  positiveMessage,
  requiredMessage,
  strictlyPositiveMessage,
  tooBigMessage,
} from "./formikMessages";
import { MAX_NUMBER } from "./formikValidationHelper";

export function subFieldValidationScheme<
  YupSchema extends BaseSchema<any>,
  PartType
>(
  property: string,
  subPartActivated: (type: PartType | null) => boolean | null | undefined,
  commonField: YupSchema,
  yupFunction: YupSchema
): MixedSchema {
  return yupFunction.when(property, {
    is: (type: PartType) => subPartActivated(type),
    then: commonField,
    otherwise: yupFunction.nullable(),
  });
}

export function subFieldValidationSchemeMultipleProperties<
  YupSchema extends BaseSchema<any>,
  PartType1,
  PartType2,
  PartType3
>(
  properties: [string, string, string],
  subPartActivated: (
    type1: PartType1 | null,
    type2: PartType2 | null,
    type3: PartType3 | null
  ) => boolean | null | undefined,
  commonField: YupSchema,
  yupFunction: YupSchema
): MixedSchema {
  return yupFunction.when(properties, {
    is: (
      type1: PartType1 | null,
      type2: PartType2 | null,
      type3: PartType3 | null
    ) => subPartActivated(type1, type2, type3),
    then: commonField,
    otherwise: yupFunction.nullable(),
  });
}

export function subFieldValidationMultipleORScheme<
  YupSchema extends BaseSchema<any>,
  PartType1,
  PartType2
>(
  properties: [string, string],
  subPartActivated1: (type1: PartType1 | null) => boolean | null | undefined,
  subPartActivated2: (type2: PartType2 | null) => boolean | null | undefined,
  commonField: YupSchema,
  yupFunction: YupSchema
): MixedSchema {
  return yupFunction.when(properties, {
    is: (type1: PartType1 | null, type2: PartType2 | null) =>
      subPartActivated1(type1) || subPartActivated2(type2),
    then: commonField,
    otherwise: yupFunction.nullable(),
  });
}

export function subFieldValidationMultipleANDScheme<
  YupSchema extends BaseSchema<any>,
  PartType1,
  PartType2
>(
  properties: [string, string],
  subPartActivated1: (type1: PartType1 | null) => boolean | null | undefined,
  subPartActivated2: (type2: PartType2 | null) => boolean | null | undefined,
  commonField: YupSchema,
  yupFunction: YupSchema
): MixedSchema {
  return yupFunction.when(properties, {
    is: (type1: PartType1 | null, type2: PartType2 | null) =>
      subPartActivated1(type1) && subPartActivated2(type2),
    then: commonField,
    otherwise: yupFunction.nullable(),
  });
}

const commonPositiveNumberHelper = (allowFloat: boolean) => {
  const defaultValidationScheme = Yup.number()
    .transform(parseToUndefinedIfNull)
    .transform(parseToUndefinedIfEmptyString)
    .min(0, positiveMessage)
    .max(MAX_NUMBER, tooBigMessage);

  if (!allowFloat) {
    return defaultValidationScheme.integer(integerMessage);
  }

  return defaultValidationScheme;
};

const commonNumberHelper = (allowFloat: boolean) => {
  const defaultValidationScheme = Yup.number()
    .transform(parseToUndefinedIfNull)
    .transform(parseToUndefinedIfEmptyString)
    .max(MAX_NUMBER, tooBigMessage);

  if (!allowFloat) {
    return defaultValidationScheme.integer(integerMessage);
  }

  return defaultValidationScheme;
};

//yup helpers
export const commonBooleanFields = Yup.boolean()
  .transform(parseToUndefinedIfNull)
  .required(requiredMessage);

export const commonBooleanFieldsNullable = Yup.boolean()
  .transform(parseToUndefinedIfNull)
  .nullable();

export const commonPositiveNumberFields = commonPositiveNumberHelper(
  true
).required(requiredMessage);

export const commonNumberFields = commonNumberHelper(true).required(
  requiredMessage
);

export const commonNumberFieldsNullable = commonNumberHelper(true).nullable();

export const commonPositiveNumberFieldsNullable = commonPositiveNumberHelper(
  true
).nullable();

export const commonStrictlyPositiveNumberFields = commonPositiveNumberFields.moreThan(
  0,
  strictlyPositiveMessage
);

export const commonStrictlyPositiveNumberLessThanNDecimalsFields = (
  nbDecimal: number
): Schema<number> =>
  commonStrictlyPositiveNumberFields.test(
    `less-than-${nbDecimal}-decimals`,
    generateLessThanNDecimalsMessage(nbDecimal),
    (value: number | undefined): boolean =>
      // "\d*" is any number of digits
      // "\.?" is 0 or 1 dot
      // "\d{0,2}" is between 0 and 2 digits
      value === undefined ||
      new RegExp(`^\\d*\\.?\\d{0,${nbDecimal}}$`).test(value.toString())
  );

export const commonPositiveNumberLessThanNDecimalsFields = (
  nbDecimal: number
): Schema<number> =>
  commonPositiveNumberFields.test(
    `less-than-${nbDecimal}-decimals`,
    generateLessThanNDecimalsMessage(nbDecimal),
    (value: number | undefined): boolean =>
      // "\d*" is any number of digits
      // "\.?" is 0 or 1 dot
      // "\d{0,2}" is between 0 and 2 digits
      value === undefined ||
      new RegExp(`^\\d*\\.?\\d{0,${nbDecimal}}$`).test(value.toString())
  );

export const commonStrictlyPositiveNumberFieldsNullable = commonPositiveNumberFieldsNullable.moreThan(
  0,
  strictlyPositiveMessage
);

export const commonPositiveIntegerNumberFields = commonPositiveNumberHelper(
  false
).required(requiredMessage);

export const commonPositiveIntegerNumberFieldsNullable = commonPositiveNumberHelper(
  false
).nullable();

export const commonStrictlyPositiveIntegerNumberFields = commonPositiveIntegerNumberFields.moreThan(
  0,
  strictlyPositiveMessage
);

export const commonStrictlyPositiveIntegerNumberFieldsNullable = commonPositiveIntegerNumberFieldsNullable.moreThan(
  0,
  strictlyPositiveMessage
);

export const commonPercentageFields = commonNumberFields
  .min(0, percentageMessage)
  .max(100, percentageMessage);

export const commonPercentageFieldsNullable = commonNumberFieldsNullable
  .min(0, percentageMessage)
  .max(100, percentageMessage);

export const commonNullableStringFields = Yup.string()
  .trim()
  .nullable()
  .transform(parseToUndefinedIfNull);

export const commonNumberPercentFields = Yup.number()
  .max(100, percentageMessage)
  .min(0, positiveMessage)
  .required(requiredMessage)
  .transform(parseToUndefinedIfNull);

const commonDateFields = Yup.date()
  .min(
    1800,
    "Veuillez saisir une date au format JJ/MM/YYYY ou mettre une année cohérente"
  )
  .transform(parseToUndefinedIfNull);

export const commonDateFieldsRequired = commonDateFields.required(
  requiredMessage
);

export const commonDateFieldsNullable = commonDateFields.nullable();

export const commonStringFields: RequiredStringSchema<
  string | null | undefined
> = Yup.string()
  .trim()
  .required(requiredMessage)
  .transform(parseToUndefinedIfNull);

export const containsPipe = (testedString: string): boolean =>
  /\|/.test(testedString);

export const commonStringFieldsNoPipeAllowed = Yup.string()
  .trim()
  .required(requiredMessage)
  .test(
    "Should not contain a pipe",
    noPipeMessage,
    value => !containsPipe(value || "")
  )
  .transform(parseToUndefinedIfNull);

export const commonMixedFields = <T>(): MixedSchema<
  T | undefined,
  Record<string, any>,
  NonNullable<T>
> => {
  return Yup.mixed<T>()
    .required(requiredMessage)
    .transform(parseToUndefinedIfNull);
};

export const commonMixedFieldsNullable = <T>(): MixedSchema<
  T | null | undefined,
  Record<string, any>
> => {
  return Yup.mixed<T>()
    .nullable()
    .transform(parseToUndefinedIfNull);
};

export const commonArrayOfMixedFields = <T>(): RequiredArraySchema<
  MixedSchema<T | undefined, Record<string, any>, NonNullable<T>>,
  AnyObject,
  (T | undefined)[] | undefined
> => {
  return Yup.array()
    .of(
      Yup.mixed<T>()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull)
    )
    .required(requiredMessage)
    .transform(parseToUndefinedIfNull);
};

export const commonObjectFields = Yup.object()
  .required(requiredMessage)
  .transform(parseToUndefinedIfNull);

export const commonObjectFieldsNullable = Yup.object()
  .nullable()
  .transform(parseToUndefinedIfNull);

export const commonArrayOfObjectFields = Yup.array()
  .of(
    Yup.object()
      .required(requiredMessage)
      .transform(parseToUndefinedIfNull)
  )
  .min(1, requiredMessage)
  .required(requiredMessage)
  .transform(parseToUndefinedIfNull);

export const commonArrayOfStringFields = Yup.array()
  .of(
    Yup.string()
      .required(requiredMessage)
      .transform(parseToUndefinedIfNull)
  )
  .min(1, requiredMessage)
  .required(requiredMessage)
  .transform(parseToUndefinedIfNull);

export function subNumberFieldValidationMultipleScheme<T, U>(
  properties: [string, string],
  subPartActivated1: (type1: T | null) => boolean | null | undefined,
  subPartActivated2: (type2: U | null) => boolean | null | undefined
): Yup.NumberSchema<number | undefined> {
  return Yup.number().when(properties, {
    is: (type1: T | null, type2: U | null) =>
      subPartActivated1(type1 || null) && subPartActivated2(type2 || null),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  });
}

//TODO pas confiance dans ce qu'on aura avec un type booleen qui risque de se retrouver à null au lieu de false...
export function subNumberFieldValidationScheme<T>(
  property: string,
  subPartActivated: (type: T | null) => boolean | null | undefined,
  isInteger: boolean = false
): Yup.NumberSchema<number | undefined> {
  return isInteger
    ? Yup.number().when(property, {
        is: (type: T | null) => subPartActivated(type || null),
        then: commonPositiveIntegerNumberFields,
        otherwise: Yup.number().nullable(),
      })
    : Yup.number().when(property, {
        is: (type: T | null) => subPartActivated(type || null),
        then: commonPositiveNumberFields,
        otherwise: Yup.number().nullable(),
      });
}

export function subObjectFieldValidationScheme<T>(
  property: string,
  subPartActivated: (type: T | null) => boolean | null | undefined
): OptionalObjectSchema<ObjectShape> {
  return Yup.object().when(property, {
    is: (emissionType: T | null) => subPartActivated(emissionType || null),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  });
}

export function subOptionPropsFieldValidationMultipleScheme<T, U>(
  properties: [string, string],
  subPartActivated1: (type1: T | null) => boolean | null | undefined,
  subPartActivated2: (type1: U | null) => boolean | null | undefined
): OptionalObjectSchema<ObjectShape> {
  return Yup.object().when(properties, {
    is: (type1: T | null, type2: U | null) =>
      subPartActivated1(type1 || null) && subPartActivated2(type2 || null),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  });
}

export function subStringFieldValidationMultipleCombinedScheme<T, U>(
  properties: [string, string],
  subPartActivated: (
    type1: T | null,
    type2: U | null
  ) => boolean | null | undefined
): Yup.StringSchema<string | undefined> {
  return Yup.string().when(properties, {
    is: (type1: T | null, type2: U | null) =>
      subPartActivated(type1 || null, type2 || null),
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  });
}

export function subStringFieldValidationMultipleScheme<T, U>(
  properties: [string, string],
  subPartActivated1: (type1: T | null) => boolean | null | undefined,
  subPartActivated2: (type2: U | null) => boolean | null | undefined
): Yup.StringSchema<string | undefined> {
  return subStringFieldValidationMultipleCombinedScheme<T, U>(
    properties,
    (type1, type2) => subPartActivated1(type1) && subPartActivated2(type2)
  );
}

export function subStringFieldValidationMultipleORScheme<T, U>(
  properties: [string, string],
  subPartActivated1: (type1: T | null) => boolean | null | undefined,
  subPartActivated2: (type2: U | null) => boolean | null | undefined
): Yup.StringSchema<string | undefined> {
  return subStringFieldValidationMultipleCombinedScheme<T, U>(
    properties,
    (type1, type2) => subPartActivated1(type1) || subPartActivated2(type2)
  );
}

export function subStringFieldValidationScheme<T>(
  property: string,
  subPartActivated: (type: T | null) => boolean | null
): Yup.StringSchema<string | undefined> {
  return Yup.string().when(property, {
    is: (type: T | null) => subPartActivated(type || null),
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  });
}

export function subBooleanFieldValidationScheme<T>(
  property: string,
  subPartActivated: (type: T | null) => boolean | null | undefined
): Yup.BooleanSchema<boolean | undefined> {
  return Yup.boolean().when(property, {
    is: (type: T | null) => subPartActivated(type || null),
    then: Yup.boolean().required(requiredMessage),
    otherwise: Yup.boolean().nullable(),
  });
}

export function subArrayOfObjectFieldValidationScheme<T>(
  property: string,
  subPartActivated: (type: T | null) => boolean | null | undefined
): Yup.ArraySchema<Yup.SchemaOf<object>> {
  return Yup.array()
    .of(
      Yup.object()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull)
    )
    .when(property, {
      is: (type: T | null) => subPartActivated(type || null),
      then: commonArrayOfObjectFields,
      otherwise: Yup.array()
        .of(Yup.object().nullable())
        .nullable(),
    });
}

export function subArrayOfObjectFieldValidationMultipleScheme<T, U>(
  properties: [string, string],
  subPartActivated1: (type1: T | null) => boolean | null | undefined,
  subPartActivated2: (type2: U | null) => boolean | null | undefined
): Yup.ArraySchema<Yup.SchemaOf<object>> {
  return Yup.array()
    .of(
      Yup.object()
        .required(requiredMessage)
        .transform(parseToUndefinedIfNull)
    )
    .when(properties, {
      is: (type1: T | null, type2: U | null) =>
        subPartActivated1(type1 || null) && subPartActivated2(type2 || null),
      then: commonArrayOfObjectFields,
      otherwise: Yup.array()
        .of(Yup.object().nullable())
        .nullable(),
    });
}

// Custom validation function to check each email in a string of shape : 'email1; email2; email3 ...'
export const multipleEmailsValidation = Yup.string()
  .nullable()
  .required(requiredMessage)
  .test("multiple-emails", "Format d'email invalide", function(value) {
    if (!value) {
      return true; // Skip validation if the value is empty
    }

    const emails = value.split(/[\s,;]+/).map(email => email.trim());
    const invalidEmails: string[] = [];

    emails.forEach(email => {
      try {
        Yup.string()
          .email()
          .validateSync(email);
      } catch (err) {
        invalidEmails.push(email);
      }
    });

    if (invalidEmails.length > 0) {
      // Construct error message with invalid emails
      const invalidEmailList = invalidEmails.join(", ");
      return this.createError({
        path: this.path,
        message: `Format d'email invalide pour : ${invalidEmailList}`,
      });
    }

    return true;
  });

export const MAX_NB_HOURS_YEAR = 8784; // (because 24 * 366 = 8784, or so I guess..)
