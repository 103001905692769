import co2GasImg from "icons/dashboardLogos/co2-gas.png";
import { PATH_QUOTAS } from "common/path/path18Now";
import { isEveryQuotaValidated, isQuotaDisabled } from "./utils";
import calculatorImg from "icons/dashboardLogos/calculator.svg";
import {
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_EMISSIONS,
} from "common/path/path20Now";
import { computeDisabledMessageForProfileForConditionalSection } from "./disabledMessageUtils";
import smogImg from "icons/dashboardLogos/smog.svg";
import BigCard from "./cards/BigCard";
import React from "react";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { useUserData } from "Authenticator/UserData";
import {
  useQuotaAllocationsState2020,
  useQuotaEmissionsState2020,
} from "../../versionedElements/declarationHooks2020";

interface QuotasCardsProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
  canSeeProgression?: boolean;
}

const QuotasDashboardCards = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
  canSeeProgression = true,
}: QuotasCardsProps): React.ReactElement => {
  const {
    isAnyPrestataire,
    isPrestataireNiveauxActiviteForEtablissement,
  } = useUserData();
  const isPrestataireEmissionsOnly =
    isAnyPrestataire &&
    !isPrestataireNiveauxActiviteForEtablissement(declarationEtablissement);
  const quotasAllocationsState = useQuotaAllocationsState2020();
  const quotasEmissionsState = useQuotaEmissionsState2020();

  return (
    <BigCard
      isClickable={false}
      picture={{ src: co2GasImg, alt: "" }}
      text="Quotas"
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS}`}
      percentageProgress={
        canSeeProgression
          ? declarationApi.getPathProgress(PATH_QUOTAS)
          : undefined
      }
      isDisabled={isQuotaDisabled(declarationApi)}
      isValidated={isEveryQuotaValidated(declarationApi)}
      subCards={[
        {
          text: "Niveaux d'activité",
          picture: { src: calculatorImg, alt: "" },
          percentageProgress: canSeeProgression
            ? declarationApi.getPathProgress(PATH_QUOTAS_ALLOCATIONS)
            : undefined,
          link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS_ALLOCATIONS}`,
          isDisabled: !declarationApi.getIsActiveSection(
            PATH_QUOTAS_ALLOCATIONS
          ),
          isValidated: declarationApi.isPathValidatedInDeclaration(
            PATH_QUOTAS_ALLOCATIONS
          ),
          disabledMessage: isPrestataireEmissionsOnly
            ? "La section niveaux d'activité n'est pas accessible pour les vérificateurs émissions quotas."
            : computeDisabledMessageForProfileForConditionalSection(
                declarationApi.isPathValidatedInDeclaration,
                isAnyPrestataire
              ),
          note: declarationApi.getIsActiveSection(PATH_QUOTAS_ALLOCATIONS)
            ? quotasAllocationsState.stateMessage
            : undefined,
        },
        {
          text: "Emissions",
          picture: { src: smogImg, alt: "" },
          percentageProgress: canSeeProgression
            ? declarationApi.getPathProgress(PATH_QUOTAS_EMISSIONS)
            : undefined,
          link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS_EMISSIONS}`,
          isDisabled: !declarationApi.getIsActiveSection(PATH_QUOTAS_EMISSIONS),
          isValidated: declarationApi.isPathValidatedInDeclaration(
            PATH_QUOTAS_EMISSIONS
          ),
          disabledMessage: computeDisabledMessageForProfileForConditionalSection(
            declarationApi.isPathValidatedInDeclaration,
            isAnyPrestataire
          ),
          note: declarationApi.getIsActiveSection(PATH_QUOTAS_EMISSIONS)
            ? quotasEmissionsState.stateMessage
            : undefined,
        },
      ]}
    />
  );
};

export default QuotasDashboardCards;
