import { TechniqueQuotasValues } from "./utils";
import { useCallback } from "react";
import { createTechniqueQuotaDto } from "./handlers";
import { InstallationInArray } from "./Installations/types";
import { useUpdateDeclarationTypeActiviteQuota2122 } from "../../versionedElements/declarationHooks2122";
import { useDeclarationStateHandlers21Now } from "../../../toNow/versionedElements/declarationHooks21Now";

export const useTechniqueQuotaSaveHandler = (): ((
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
) => Promise<void>) => {
  const setTypeActiviteQuota = useUpdateDeclarationTypeActiviteQuota2122();

  const update = useCallback(
    async (
      values: TechniqueQuotasValues,
      installationsInArray: InstallationInArray[]
    ) => {
      const newTechniqueDto = createTechniqueQuotaDto(
        values,
        installationsInArray
      );
      await setTypeActiviteQuota(newTechniqueDto);
    },
    [setTypeActiviteQuota]
  );
  return update;
};

export const useTechniqueQuotaValidateHandler = (): ((
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
) => Promise<void>) => {
  const { startQuota } = useDeclarationStateHandlers21Now();

  const startCall = useCallback(
    async (
      values: TechniqueQuotasValues,
      installationsInArray: InstallationInArray[]
    ) => {
      const newTechniqueDto = createTechniqueQuotaDto(
        values,
        installationsInArray
      );
      await startQuota(newTechniqueDto);
    },
    [startQuota]
  );
  return startCall;
};

export const useTechniqueQuotaEditHandler = (): (() => Promise<void>) => {
  const { resetQuota } = useDeclarationStateHandlers21Now();

  const resetCall = useCallback(async () => {
    await resetQuota();
  }, [resetQuota]);
  return resetCall;
};
