import {
  AccreditedOrganismInterveningType,
  otherOrganismAccreditedOption,
} from "../MesuresEmpoussierage/utils";
import {
  ReferenceItemCarriereAccidentDto,
  ReferenceOrganismeAccrediteDto,
  ReferenceOrganismeExterieurPreventionDto,
} from "api/gen";
import { OutsidePreventionOrganismType } from "../StructureFonctionnelle/utils";
import { AccidentCauseType } from "../AccidentsTravail/utils";

export interface OptionPropsListWrapper {
  externalPreventionOrganismList: OutsidePreventionOrganismType[];
  mainAccidentCauseList: AccidentCauseType[];
  organismeMeasuringEmpoussierageList: AccreditedOrganismInterveningType[];
}

export const computeOptionPropsListFromReferential = (
  referentialOrganismeExterieurPrevention: ReferenceOrganismeExterieurPreventionDto,
  referentialCauseAccident: ReferenceItemCarriereAccidentDto,
  referentialOrganismeAccredite: ReferenceOrganismeAccrediteDto
): OptionPropsListWrapper => {
  const externalPreventionOrganismList: OutsidePreventionOrganismType[] = referentialOrganismeExterieurPrevention.referenceItemOrganismeExterieurPreventionDtoList
    .filter(singleOrganismExterieur => singleOrganismExterieur.actif)
    .map((singleOrganismExterieur, index) => {
      return {
        label: singleOrganismExterieur.libelle,
        value: index,
        object: singleOrganismExterieur,
      };
    });

  const mainAccidentCauseList: AccidentCauseType[] = referentialCauseAccident.causes.map(
    (singleCauseStr, index) => {
      return {
        label: singleCauseStr,
        value: index,
        object: { cause: singleCauseStr },
      };
    }
  );

  const organismeMeasuringEmpoussierageList: AccreditedOrganismInterveningType[] = referentialOrganismeAccredite.referenceItemOrganismeAccrediteDtoList
    .filter(singleOrganismAccredite => singleOrganismAccredite.actif)
    .map(
      (singleOrganismAccredite, index): AccreditedOrganismInterveningType => {
        return {
          label: singleOrganismAccredite.libelle,
          value: index,
          object: singleOrganismAccredite,
        };
      }
    )
    .concat(otherOrganismAccreditedOption);

  return {
    externalPreventionOrganismList,
    mainAccidentCauseList,
    organismeMeasuringEmpoussierageList,
  };
};
