import React, { ReactElement } from "react";
import { SousInstallationRefProduitDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { EMPHASIZED_LAST_HEADER_CELL } from "common/presentational/CommonEntityTable/cssRules";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import {
  computeAnneeWithDonneesHistoriquesLine,
  computeSousInstallationReferentielProduitLines,
} from "../tableLineComputer";
import { computePreferredColWidths } from "../../InfosGenerales/tableLineComputer";
import { computeTableWidth } from "theme";
import { sortObjectKeysAndValues } from "common/utils/methods";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  table: (object: Record<string, unknown>) => ({
    ...EMPHASIZED_LAST_HEADER_CELL,
    width: computeTableWidth(object),
  }),
  full: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    marginLeft: "10px",
  },
});

interface SousInstallationReferentielProduitTableProps {
  sousInstallationRefProduit: SousInstallationRefProduitDto;
}

const SousInstallationReferentielProduitTable = ({
  sousInstallationRefProduit,
}: SousInstallationReferentielProduitTableProps): ReactElement => {
  const {
    title,
    unite,
    yearToSousInstallationDataMap,
  } = sousInstallationRefProduit;
  const classes = useStyles(yearToSousInstallationDataMap);

  const {
    sortedKeys,
    sortedValues: sortedSousInstallationDataList,
  } = sortObjectKeysAndValues(yearToSousInstallationDataMap);

  return (
    <div className={classes.container}>
      <ArrayTitle>
        Sous-installation avec référentiel de produit {title}
      </ArrayTitle>

      <CommonEntityTable
        header={computeAnneeWithDonneesHistoriquesLine(sortedKeys, classes)}
        lines={computeSousInstallationReferentielProduitLines(
          unite,
          sortedSousInstallationDataList,
          classes
        )}
        isFirstColSticky={false}
        isLastColSticky={false}
        additionalClassName={classes.table}
        preferredColWidths={computePreferredColWidths(
          sortedSousInstallationDataList
        )}
      />
    </div>
  );
};

export default SousInstallationReferentielProduitTable;
