import React from "react";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeEmissionCell } from "./arrayUtils";
import { MethodeCalculPfcDto } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
  arrayContainer: {
    width: "65%",
  },
});

interface MethodeCalculPfcArrayProps {
  methodeCalculPfcDtoList: MethodeCalculPfcDto[];
}

const MethodeCalculPfcArray = ({
  methodeCalculPfcDtoList,
}: MethodeCalculPfcArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleFluxLine = (
    methodeCalculPfcDto: MethodeCalculPfcDto,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(methodeCalculPfcDto.fluxName, classes),
      computeWrappedCell(methodeCalculPfcDto.methode, classes),
      computeWrappedCell(
        computeEmissionCell(methodeCalculPfcDto.emissionsPfc),
        classes
      ),
    ];
  };

  const fluxLines = methodeCalculPfcDtoList.map(methodeCalculPfcDto =>
    computeSingleFluxLine(methodeCalculPfcDto, classes)
  );
  return (
    <div className={classes.container}>
      <ArrayTitle>
        Méthode de calcul pour la détermination des émissions de PFC
      </ArrayTitle>

      <CommonEntityTable
        header={[<p>Nom du flux</p>, <p>Méthodes</p>, <p>Émissions de PFC</p>]}
        lines={fluxLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[1, 1, 1]}
        additionalClassName={classes.arrayContainer}
      />
    </div>
  );
};

export default MethodeCalculPfcArray;
