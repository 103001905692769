import {
  AirSolvantsCovnmPgsDto,
  AirSolvantsCovPgsDto,
  ReferenceItemPolluantDto,
} from "api/gen";
import {
  computeConsommationSolvant,
  computeEmissionDiffuse,
  computeEmissionTotale,
  computeUtilisationSolvant,
} from "./calculus";
import { findElementMatchingTemplate } from "common/utils/methods";
import { EmissionsInArray } from "./types";

const getSubstancById = (
  referentialCOVNM: ReferenceItemPolluantDto,
  substances: ReferenceItemPolluantDto[],
  substanceId: string
): ReferenceItemPolluantDto | null => {
  let substance = findElementMatchingTemplate(
    { uuid: substanceId },
    substances
  );
  if (!substance) {
    substance = substanceId === referentialCOVNM.uuid ? referentialCOVNM : null;
  }
  return substance;
};

export const createAirSolvantEmissionDto = (
  EmissionInArray: EmissionsInArray[]
): (AirSolvantsCovPgsDto | AirSolvantsCovnmPgsDto)[] => {
  const emissions = EmissionInArray.map(singlePopulatedInstallation => {
    const singleEmission = singlePopulatedInstallation.data;

    const singleSolvant: AirSolvantsCovPgsDto | AirSolvantsCovnmPgsDto = {
      id: singlePopulatedInstallation.data.id,
      epuration: !!singleEmission.epuration,
      i1: singleEmission.i1,
      i2: singleEmission.i2,
      installations:
        singleEmission.installations !== null
          ? singleEmission.installations
          : [],
      nature: singleEmission.natureEquipement,
      o1: singleEmission.o1,
      o2: singleEmission.o2,
      o3: singleEmission.o3,
      o4: singleEmission.o4,
      o5: singleEmission.o5,
      o6: singleEmission.o6,
      o7: singleEmission.o7,
      o8: singleEmission.o8,
      o9: singleEmission.o9,
      rendement: singleEmission.rendement,
      simplifie: !!singleEmission.pgsSimple,
      substanceID: singleEmission.substance
        ? singleEmission.substance.uuid
        : null,
    };
    return singleSolvant;
  });

  return emissions;
};

export const convertEmissionsToDisplayed = (
  referentialCOVNM: ReferenceItemPolluantDto,
  emissions: AirSolvantsCovPgsDto | AirSolvantsCovnmPgsDto,
  substances: ReferenceItemPolluantDto[]
): EmissionsInArray => {
  const substance = getSubstancById(
    referentialCOVNM,
    substances,
    emissions.substanceID || ""
  );
  //TODO : compute errors
  return {
    data: {
      id: emissions.id,
      epuration: emissions.epuration,
      i1: emissions.i1,
      i2: emissions.i2,
      installations: emissions.installations,
      natureEquipement: emissions.nature,
      o1: emissions.o1,
      o2: emissions.o2,
      o3: emissions.o3,
      o4: emissions.o4,
      o5: emissions.o5,
      o6: emissions.o6,
      o7: emissions.o7,
      o8: emissions.o8,
      o9: emissions.o9,
      rendement: emissions.rendement,
      pgsSimple: emissions.simplifie,
      substance: substance,
      emission: emissions.simplifie ? null : computeEmissionDiffuse(emissions),
      emissionTotale: computeEmissionTotale(emissions),
      consommationSolvant: computeConsommationSolvant(emissions),
      utilisationSolvant: computeUtilisationSolvant(emissions),
    },
    errors: {},
  };
};

export const compareEmissionFunction = (
  a: EmissionsInArray,
  b: EmissionsInArray
): number => {
  if (
    a.data.substance &&
    b.data.substance &&
    a.data.substance.nom !== b.data.substance.nom
  ) {
    return a.data.substance.nom < b.data.substance.nom ? -1 : 1;
  }

  if (
    a.data.installations &&
    b.data.installations &&
    a.data.installations !== b.data.installations
  ) {
    return a.data.installations
      .map(installations => {
        return installations.nom;
      })
      .join(", ") <
      b.data.installations
        .map(installations => {
          return installations.nom;
        })
        .join(", ")
      ? -1
      : 1;
  }
  return 0;
};
