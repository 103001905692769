import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
  global: {
    display: "flex",
    flexDirection: "column",
  },
  date: {
    fontSize: "0.8em",
    marginLeft: "1em",
  },
  inputDisabled: {
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px",
    minHeight: "3.6em",
    fontFamily: "arial,helvetica,sans-serif",
  },
  container: {
    display: "flex",
  },
});
