import { TechniqueValues } from "./utils";
import { useCallback } from "react";
import {
  createTechniqueDto,
  overrideUncoherentValuesTechniqueDto,
} from "./handlers";
import {
  useDeclarationStateHandlers1919,
  useUpdateDeclarationTypeActivite1919,
} from "../../../CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const useTechniqueSaveHandler = (): ((
  values: TechniqueValues
) => Promise<void>) => {
  const setTypeActivite = useUpdateDeclarationTypeActivite1919();

  const update = useCallback(
    async (values: TechniqueValues) => {
      const newTechniqueDto = overrideUncoherentValuesTechniqueDto(
        createTechniqueDto(values)
      );
      await setTypeActivite(newTechniqueDto);
    },
    [setTypeActivite]
  );
  return update;
};

export const useTechniqueValidateHandler = (): ((
  values: TechniqueValues
) => Promise<void>) => {
  const { start } = useDeclarationStateHandlers1919();

  const startCall = useCallback(
    async (values: TechniqueValues) => {
      const newTechniqueDto = overrideUncoherentValuesTechniqueDto(
        createTechniqueDto(values)
      );
      await start(newTechniqueDto);
    },
    [start]
  );
  return startCall;
};

export const useTechniqueEditHandler = (): (() => Promise<void>) => {
  const { reset } = useDeclarationStateHandlers1919();

  const resetCall = useCallback(async () => {
    await reset();
  }, [reset]);
  return resetCall;
};
