import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import DummyNumberInput from "common/form/fields/dumbInput/DummyNumberInput";
import { SECTION_TITLE_GREY } from "theme";
import {
  WasteBasicStorage,
  WasteSumUpAction,
  WasteSumUpArrayProps,
} from "./types";
import InfoBulle from "common/infoBulle/InfoBulle";
import Row from "common/presentational/Row";

const useStyles = makeStyles({
  link: {
    color: SECTION_TITLE_GREY,
    fontSize: "14px",
    textAlign: "left",
    wordBreak: "break-word",
  },
  input: {
    width: "80%",
  },
  cellWidth: {
    width: "25%",
    margin: "0 4px",
  },
});

const WasteSumUpArray = ({
  dangerous,
  normal,
  isReceptionAvailable,
}: WasteSumUpArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleWasteLine = (children: React.ReactNode) => {
    return (
      <Row
        height={"auto"}
        additionalStyle={{ justifyContent: "flex-start", padding: "3px 0" }}
      >
        {children}
      </Row>
    );
  };
  const computeSingleWasteCell = (children: React.ReactNode) => {
    return (
      <div style={{ display: "flex" }} className={classes.cellWidth}>
        {children}
      </div>
    );
  };
  const computeSingleWasteTextCell = (
    title: string,
    tooltipContent: ReactNode = null
  ) => {
    return (
      <div style={{ display: "flex" }} className={classes.cellWidth}>
        {tooltipContent !== null && <InfoBulle content={tooltipContent} />}
        <div className={classes.link}>{title}</div>
      </div>
    );
  };
  const computeSingleWasteNumbercell = (
    value: number | null,
    unit: string,
    isDisabled = true,
    onChange: WasteSumUpAction = () => {
      return;
    }
  ) => {
    return computeSingleWasteCell(
      <DummyNumberInput
        value={value}
        unit={unit}
        id={""}
        name={""}
        error={null}
        disabled={isDisabled}
        onChange={value => {
          onChange && onChange(value);
        }}
        additionalClassNameInput={classes.input}
      />
    );
  };
  const computeSingleLineForBasicStorage = (
    title: string,
    storage: WasteBasicStorage
  ) => {
    return computeSingleWasteLine(
      <>
        {computeSingleWasteTextCell(title)}
        {computeSingleWasteNumbercell(storage.made.toNumber(), "t/an")}
        {isReceptionAvailable &&
          computeSingleWasteNumbercell(storage.received.toNumber(), "t/an")}
        {isReceptionAvailable &&
          computeSingleWasteNumbercell(storage.treated.toNumber(), "t/an")}
        {computeSingleWasteNumbercell(storage.send.toNumber(), "t/an")}
      </>
    );
  };

  return (
    <>
      {computeSingleWasteLine(
        <>
          <div className={classes.cellWidth} />
          {computeSingleWasteTextCell(
            "Quantité totale produite",
            'Correspond à la quantité totale de déchets produits par l\'établissement déclarés dans la feuille "Production".'
          )}
          {isReceptionAvailable &&
            computeSingleWasteTextCell(
              "Quantité totale admise",
              'Correspond à la quantité totale de déchets admis par l\'établissement déclarés dans la feuille "Réception".'
            )}
          {isReceptionAvailable &&
            computeSingleWasteTextCell(
              "Quantité totale traitée",
              'Correspond à la quantité totale de déchets traités par l\'établissement déclarés dans la feuille "Réception".'
            )}
          {computeSingleWasteTextCell(
            "Quantité totale expédiée",
            "Correspond à la quantité totale de déchets expédiés par l'établissement déclarés dans la feuille \"Production\" (toutes destinations confondues à l'exception du traitement sur site)."
          )}
        </>
      )}
      {dangerous &&
        computeSingleLineForBasicStorage("Déchets dangereux", dangerous)}
      {normal &&
        computeSingleLineForBasicStorage("Déchets non dangereux", normal)}
    </>
  );
};

export default WasteSumUpArray;
