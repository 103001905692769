import {
  computeOutsideOrganismOrFunctionalStructureLabel,
  isOrganismExterieur,
  StructureFonctionnelleData,
  structureFonctionnelleLabel,
} from "./utils";
import { Nullable } from "common/utils/types";
import {
  PreventionDto,
  ReferenceItemOrganismeExterieurPreventionDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";

export const overrideUncoherentValuesStructureFonctionnelle = (
  currentValues: Nullable<StructureFonctionnelleData>
): Nullable<StructureFonctionnelleData> => {
  if (
    !isOrganismExterieur(currentValues.outsideOrganismOrFunctionalStructure)
  ) {
    return {
      ...currentValues,
      numberVisitsMadeInYear: null,
      outsidePreventionOrganism: null,
    };
  }

  return currentValues;
};

const getOrganismeExterieurNameById = (
  organismeExterieursReferentiel: ReferenceItemOrganismeExterieurPreventionDto[],
  organismeExterieurId: string
) => {
  const organismeExterieur = findElementMatchingTemplate(
    { uuid: organismeExterieurId },
    organismeExterieursReferentiel
  );
  return organismeExterieur ? organismeExterieur.libelle : undefined;
};

const getOrganismeExterieurIdByName = (
  organismeExterieursReferentiel: ReferenceItemOrganismeExterieurPreventionDto[],
  organismeExterieurName: string
) => {
  const organismeExterieur = findElementMatchingTemplate(
    { libelle: organismeExterieurName },
    organismeExterieursReferentiel
  );
  return organismeExterieur ? organismeExterieur.uuid : null;
};

export const convertStructureFonctionnelleDataToDeclarationPreventionDto = (
  values: Nullable<StructureFonctionnelleData>,
  referenceItemOrganismeExterieurPreventionDtoList: ReferenceItemOrganismeExterieurPreventionDto[]
): PreventionDto => {
  return {
    nbVisites: values.numberVisitsMadeInYear,
    organismeDePreventionID: values.outsidePreventionOrganism
      ? getOrganismeExterieurIdByName(
          referenceItemOrganismeExterieurPreventionDtoList,
          values.outsidePreventionOrganism.libelle
        )
      : null,
    structureFonctionnelle:
      values.outsideOrganismOrFunctionalStructure ===
      structureFonctionnelleLabel,
    activiteSaisonniere: !!values.isSeasonalActivity,
  };
};

export const convertDeclarationPreventionDtoToStructureFonctionnelleData = (
  declarationData: PreventionDto,
  referenceItemOrganismeExterieurPreventionDtoList: ReferenceItemOrganismeExterieurPreventionDto[]
): Nullable<StructureFonctionnelleData> => {
  const outsidePreventionOrganismName = getOrganismeExterieurNameById(
    referenceItemOrganismeExterieurPreventionDtoList,
    declarationData.organismeDePreventionID || ""
  );
  const outsidePreventionOrganism = findElementMatchingTemplate(
    { libelle: outsidePreventionOrganismName },
    referenceItemOrganismeExterieurPreventionDtoList
  );

  return {
    numberVisitsMadeInYear: declarationData.nbVisites,
    outsideOrganismOrFunctionalStructure: computeOutsideOrganismOrFunctionalStructureLabel(
      declarationData.structureFonctionnelle
    ),
    outsidePreventionOrganism,
    isSeasonalActivity: declarationData.activiteSaisonniere,
  };
};
