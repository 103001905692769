import React from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { PopulatedTransportProductInArray } from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import uuid from "uuid";
import {
  useChoiceRadioFieldGenerator,
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  departments,
  franceOrOtherCountry,
  possibleProductTypesForFamilies,
  ProductType,
} from "./utils/selectPossibleValues";
import { convertInFranceStringToBoolean } from "./utils/converters";
import {
  commonNullableStringFields,
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceItemFamilleUsageProductionDto,
  ReferencePaysDto,
} from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  productionProductAlreadyDefinedForDepMessage,
  productionProductAlreadyDefinedForStateMessage,
} from "common/declarant/formik/formikMessages";
import { isSearchStringInCollection } from "common/utils/methods";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";
import { familiesProduction } from "../Families/selectPossibleValues";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

interface TransportProductModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  transportProductInModal: PopulatedTransportProductInArray | undefined;
  setTransportProductInModal: (
    product: PopulatedTransportProductInArray | undefined
  ) => void;
  transportProductsInArray: PopulatedTransportProductInArray[];
  setTransportProductsInArray: (
    substances: PopulatedTransportProductInArray[]
  ) => void;
  referentialCountries: ReferencePaysDto;
  referentialDepartements: ReferenceDepartementDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  usageFamily: commonObjectFields,
  usageFamilyPrecision: Yup.string().when("usageFamily", {
    is: (
      usageFamily: OptionPropsWithObject<
        ReferenceItemFamilleUsageProductionDto
      > | null
    ) => usageFamily && usageFamily.object.precision,
    then: commonStringFields,
    otherwise: commonNullableStringFields,
  }),
  productType: commonObjectFields,
  inFrance: commonStringFields,
  department: Yup.object().when("inFrance", {
    is: (inFrance: string | null) =>
      inFrance && convertInFranceStringToBoolean(inFrance),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  country: Yup.object().when("inFrance", {
    is: (inFrance: string | null) =>
      inFrance && !convertInFranceStringToBoolean(inFrance),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  tonnage: commonPositiveNumberFields,
});

const TransportProductModal = ({
  isOpen,
  setIsModalOpen,
  transportProductInModal,
  setTransportProductInModal,
  transportProductsInArray,
  setTransportProductsInArray,
  referentialCountries,
  referentialDepartements,
  referentialFamilies,
  referentialFamiliesCodeProduction,
}: TransportProductModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-transport-produits",
  };

  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);

  const onClose = () => {
    setTransportProductInModal(undefined);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <DeprecatedCommonFormSingleEntity
        title="AJOUTER UN PRODUIT EXPÉDIÉ"
        validationSchema={validationSchema}
        initialEntity={_.get(transportProductInModal, "displayedProduct", {})}
        closeFunction={onClose}
        onSubmit={values => {
          const transportProduct: PopulatedTransportProductInArray = {
            id: transportProductInModal ? transportProductInModal.id : uuid(),
            displayedProduct: {
              usageFamily: values.usageFamily,
              usageFamilyPrecision:
                values.usageFamily && values.usageFamily.object.precision
                  ? values.usageFamilyPrecision
                  : null,
              productType: values.productType,
              inFrance: values.inFrance,
              department: values.department,
              country: values.country
                ? values.country
                : {
                    label: "",
                    value: -1,
                    object: null,
                  },
              tonnage: values.tonnage,
              isFranceEntiere:
                // to leave because deprecatedCommonFormSingleEntity doesn't show it's nullable when truth is it MAY be null.
                values.department && values.department.object === null,
            },
          };
          const newTransportProductsInArray = _.cloneDeep(
            transportProductsInArray
          );
          let indexToReplace = newTransportProductsInArray.length;
          if (transportProductInModal) {
            indexToReplace = _.findIndex(
              newTransportProductsInArray,
              transportProductInModal
            );
            setTransportProductInModal(undefined);
          }
          newTransportProductsInArray[indexToReplace] = transportProduct;
          setTransportProductsInArray(newTransportProductsInArray);
          setIsModalOpen(false);
        }}
        validate={values => {
          if (values.inFrance) {
            if (
              convertInFranceStringToBoolean(values.inFrance) &&
              values.department &&
              transportProductsInArray.some(
                product =>
                  product.displayedProduct.department === values.department
              )
            ) {
              return {
                productType: productionProductAlreadyDefinedForDepMessage,
              };
            }

            if (
              !convertInFranceStringToBoolean(values.inFrance) &&
              values.country &&
              transportProductsInArray.some(
                product => product.displayedProduct.country === values.country
              )
            ) {
              return {
                productType: productionProductAlreadyDefinedForStateMessage,
              };
            }
          }
        }}
        renderField={({ values, setFieldValue }) => {
          let productTypes: OptionPropsWithObject<ProductType, string>[] = [];
          if (values.usageFamily !== null) {
            productTypes = possibleProductTypesForFamilies(
              [values.usageFamily.object],
              referentialFamiliesCodeProduction
            );
          }

          return (
            <>
              <ChoiceSelect
                name="usageFamily"
                label="Famille d'usage *"
                isMulti={false}
                options={familiesProduction(referentialFamilies)}
                additionalOnChange={() => setFieldValue("productType", null)}
              />

              {values.usageFamily && values.usageFamily.object.precision && (
                <TextField
                  name="usageFamilyPrecision"
                  label="Précision autre"
                />
              )}

              <ChoiceSelect
                name="productType"
                label="Produit *"
                isMulti={false}
                options={productTypes}
              />

              <ChoiceRadioField
                name="inFrance"
                label="Destination *"
                choices={franceOrOtherCountry}
                additionalOnChange={() => {
                  setFieldValue("department", null);
                  setFieldValue("country", null);
                }}
              />

              {values.inFrance &&
                convertInFranceStringToBoolean(values.inFrance) && (
                  <WrappedChoiceSelectModale
                    name="department"
                    label=""
                    title=""
                    header={["Département"]}
                    linesData={departments(referentialDepartements)}
                    formatLine={lineData => [lineData.label]}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection([lineData.label], searchedStr)
                    }
                    formatSelectedTitle={lineData => lineData.label}
                    commonProps={commonProps}
                  />
                )}

              {values.inFrance &&
                !convertInFranceStringToBoolean(values.inFrance) && (
                  <WrappedChoiceSelectModale
                    label=""
                    name="country"
                    title=""
                    header={["Pays"]}
                    linesData={referentialCountries.referenceItemPaysDtoList.map(
                      (item, index) => ({
                        value: index,
                        label: item.designation,
                        object: item,
                      })
                    )}
                    formatLine={lineData => [lineData.object.designation]}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection([lineData.label], searchedStr)
                    }
                    formatSelectedTitle={lineData => lineData.label}
                    commonProps={commonProps}
                  />
                )}

              <NumberField name="tonnage" label="Tonnage *" unit="ktonnes" />
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default TransportProductModal;
