import { AirIsdndCh4DtoMethodeEnum, AirIsdndCo2DtoMethodeEnum } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export interface MainFormValues {
  volumeBiogazExported: number | null;
  ch4Content: number | null;
}

export interface BlocCalculatedEmissionFormType {
  amountCo2Generated: number | null;
  amountCo2Caught: number | null;
  amountCo2Total: number | null;
  co2Method: AirIsdndCo2DtoMethodeEnum | null;
  co2MethodDescription: string | null;
  amountCh4Generated: number | null;
  amountCh4Caught: number | null;
  amountCh4Total: number | null;
  ch4Method: AirIsdndCh4DtoMethodeEnum | null;
  ch4MethodDescription: string | null;
  errorCo2OrCh4Required?: string | null;
}

export const mainFormValuesFieldMatcher = fieldMatcherBuilder<
  MainFormValues
>().build({
  volumeBiogazExported: "volumeBiogazExported",
  ch4Content: "ch4Content",
});

export const blocCalculatedEmissionFieldMatcher = fieldMatcherBuilder<
  BlocCalculatedEmissionFormType
>().build({
  amountCo2Generated: "amountCo2Generated",
  amountCo2Caught: "amountCo2Caught",
  amountCo2Total: "amountCo2Total",
  co2Method: "co2Method",
  co2MethodDescription: "co2MethodDescription",
  amountCh4Generated: "amountCh4Generated",
  amountCh4Caught: "amountCh4Caught",
  amountCh4Total: "amountCh4Total",
  ch4Method: "ch4Method",
  ch4MethodDescription: "ch4MethodDescription",
  errorCo2OrCh4Required: "errorCo2OrCh4Required",
});
