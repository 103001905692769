import {
  Declaration2023,
  useBasicDeclarationHandlers2023,
  useDeclaration2023,
  useDeclarationHelpers2023,
} from "./declarationHooks2023";
import { CommonGlobalFormActionsInFullContextProps } from "common/actions/types";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "../../../DeclarationApiContext/utils/types";
import { DeclarationStateDtoStateEnum } from "api/gen";
import React from "react";
import GenericGlobalFormActionInFullContext from "common/actions/GenericGlobalFormActionInFullContext";
import { DeclarationConstraintViolation19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import { useDeclarationErrorAndWarnings20Now } from "../../toNow/versionedElements/errorAndWarningDisplayer20Now/utils";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

type GlobalFormActionFullContext20NowProps = CommonGlobalFormActionsInFullContextProps<
  Declaration2023
>;

export const GlobalFormActionFullContext2023 = (
  props: GlobalFormActionFullContext20NowProps
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration2023 = useDeclaration2023;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers2023;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration2023
  > = useBasicDeclarationHandlers2023;
  const getDeclarationState: (
    declarationDto: Declaration2023
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings20Now;
  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );
  return (
    <GenericGlobalFormActionInFullContext<
      Declaration2023,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      {...props}
    />
  );
};

export default GlobalFormActionFullContext2023;
