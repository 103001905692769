import { QuotasAllocationsEmailsVerificateursDto20Now } from "api/gen";
import { Declaration21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import { Declaration2323 } from "../../../versionedElements/declarationHooks2323";

export const updateHandlerEmailsAddressAllocations21Now = (
  declaration: Declaration21Now,
  values: QuotasAllocationsEmailsVerificateursDto20Now | null
): Declaration21Now => {
  if (values) {
    declaration.body.sections.quotas.allocations.blocEmails = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddressAllocations2323 = (
  declaration: Declaration2323,
  values: QuotasAllocationsEmailsVerificateursDto20Now | null
): Declaration2323 => {
  if (values) {
    declaration.body.sections.quotas.allocations.blocEmails = values;
  }
  return declaration;
};
