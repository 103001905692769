import React, { ReactElement } from "react";

import { makeStyles } from "@material-ui/styles";
import { DASHBOARD_BACKGROUND_GREY } from "theme";
import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  generateDeclarationTransitionWarningMessage,
  STARTED,
} from "common/messages/dashboardMessage";
import { WorkflowTarget } from "common/utils/types";
import { useDeclarationStateHandlers21Now } from "../../versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  takeoverButton: {
    background: DASHBOARD_BACKGROUND_GREY,
    color: "black",
  },
});

const CancelTakeoverButton = ({
  workflowTarget,
}: {
  workflowTarget: WorkflowTarget;
}): ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const { cancelTakeover } = useDeclarationStateHandlers21Now();

  return (
    <Button
      onClick={() =>
        openConfirmationModale(
          generateDeclarationTransitionWarningMessage(workflowTarget, STARTED),
          () => triggerSpinner(cancelTakeover([workflowTarget]))
        )
      }
      text={"Annuler déclaration d'office"}
      isSpinnerVisible={isSpinnerVisible}
      additionalClassname={classes.takeoverButton}
    />
  );
};

export default CancelTakeoverButton;
