import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { Pds2121 } from "api/gen";
import { useDeclaration2121 } from "../../../versionedElements/declarationHooks2121";
import { usePdsFileHandler2121 } from "../../../versionedElements/filesLoaderDisplayer2121/sectionFilesHooks2121";
import FilesLoader from "common/FilesLoader/FilesLoader";
import {
  filesRelatedToNimDtoToFileDto,
  NimFilesLoaderProps,
} from "../../../../toNow/Quotas/common/FilesRelatedToNim/utils";

const PdsLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2121();
  const openAlertModale = useAlertModale();
  const pdsFileHandler = usePdsFileHandler2121(installationId);

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE PLAN DE SURVEILLANCE DES ÉMISSIONS"}
      filesPath={
        "body.externalSections.quotas.emissions.blocPlanSurveillance.planSurveillanceList"
      }
      section={FileSectionEnum.QUOTAS_PLAN_SURVEILLANCE}
      fileHandlers={pdsFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(pdsList: Record<string, unknown>[]) =>
        filesRelatedToNimDtoToFileDto(
          installationId,
          (pdsList as unknown) as Pds2121[]
        )
      }
    />
  );
};

export default PdsLoader;
