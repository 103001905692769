import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { Information } from "./utils/types";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";
import { isEqual } from "lodash";
import { createAirCombustionsInformationDto } from "./utils/utils";
import Row from "common/presentational/Row";
import { PATH_AIR_COMBUSTION_RECAPITULATIF } from "common/path/path18Now";
import BlocFullContext2020 from "../../../versionedElements/BlocFullContext2020";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface InformationProps {
  validationInformationPath: string;
  initialInformations: Information;
  informationsInPage: Information;
  setInformationsInPage: Dispatch<SetStateAction<Information>>;
  lcpDisabled: boolean;
  wiCowiDisabled: boolean;
}

const InformationBloc = ({
  validationInformationPath,
  initialInformations,
  informationsInPage,
  setInformationsInPage,
}: InformationProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-information-combustion",
  };

  const updateHandler = (declaration: Declaration2020) => {
    declaration.body.sections.air.combustion.recapitulatif = createAirCombustionsInformationDto(
      informationsInPage
    );
    return declaration;
  };

  const CheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);
  return (
    <BlocFullContext2020
      title={"Informations Complémentaires"}
      hasModification={!isEqual(initialInformations, informationsInPage)}
      isValidateButtonAvailable={true}
      path={validationInformationPath}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration => {
        return updateHandler(declaration);
      }}
      cancelAction={() => {
        setInformationsInPage(initialInformations);
      }}
      renderContent={shouldDisabledFields => {
        return (
          <>
            <CheckBox
              name="lcp"
              label="L'établissement possède une ou plusieurs grandes installations de combustion LCP (chapitre III de la directive IED)"
              disabled={shouldDisabledFields}
              onChange={selected => {
                setInformationsInPage(informations => {
                  return {
                    lcp: selected,
                    wiCoWi: informations.wiCoWi,
                  };
                });
              }}
              value={informationsInPage.lcp}
              tooltipContent="Une installation de combustion unique est considérée pour tout groupe d'appareils de combustion exploités par un même opérateur et situés sur un même site (enceinte de l'établissement) sauf à ce que l'exploitant démontre que les appareils ne pourraient pas être techniquement et économiquement raccordés à une cheminée commune. Une installation de combustion est concernée par le chapitre III de la directive IED si la somme des puissances des appareils de combustion composant l'installation de combustion, à l'exception de celles des appareils de puissance strictement inférieure à 15 MW, est supérieure ou égale à 50 MW."
              commentPath={`${PATH_AIR_COMBUSTION_RECAPITULATIF}/lcp`}
            />
            <Row />
            <CheckBox
              name="wiCoWi"
              label="L'établissement possède une ou plusieurs installations d'incinération ou de co-incinération de déchets WI/CoWI (chapitre IV de la directive IED)"
              disabled={shouldDisabledFields}
              onChange={selected => {
                setInformationsInPage(informations => {
                  return {
                    lcp: informations.lcp,
                    wiCoWi: selected,
                  };
                });
              }}
              value={informationsInPage.wiCoWi}
              commentPath={`${PATH_AIR_COMBUSTION_RECAPITULATIF}/wicowi`}
            />
          </>
        );
      }}
    />
  );
};

export default InformationBloc;
