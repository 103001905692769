import { AirSolvantsEmissionDto } from "api/gen";
import { CovInArray } from "./types";
import { arrayMentionOptions } from "./selectPossibleValues";

export const createAirSolvantsCovDto = (
  covInArray: CovInArray[]
): AirSolvantsEmissionDto[] => {
  return covInArray.map(singlePopulatedCov => {
    return {
      ...singlePopulatedCov.data,
      installations: singlePopulatedCov.data.installations || [],
    };
  });
};

export const convertCovDangerToDisplayed = (
  covDanger: AirSolvantsEmissionDto
): CovInArray => {
  return {
    data: {
      ...covDanger,
      installations: covDanger.installations,
    },
    errors: {},
  };
};

export const compareCovFunction = (a: CovInArray, b: CovInArray): number => {
  if (
    a.data.danger &&
    b.data.danger &&
    arrayMentionOptions.indexOf(a.data.danger) !==
      arrayMentionOptions.indexOf(b.data.danger)
  ) {
    return arrayMentionOptions.indexOf(a.data.danger) <
      arrayMentionOptions.indexOf(b.data.danger)
      ? -1
      : 1;
  }

  if (
    a.data.installations !== null &&
    b.data.installations !== null &&
    a.data.installations !== b.data.installations
  ) {
    return a.data.installations
      .map(installations => {
        return installations.nom;
      })
      .join(", ") <
      b.data.installations
        .map(installations => {
          return installations.nom;
        })
        .join(", ")
      ? -1
      : 1;
  }
  return 0;
};
