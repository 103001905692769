import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { useComputeFirstLineContentCell } from "common/utils/computeWrappedCell";
import { CorrelationEmissionInArray, EmissionsInArray } from "../utils/types";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  computeCo2EmissionLine,
  computeSingleEmissionLine,
} from "./arrayUtils";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import { MethodEnum } from "../utils/selectPossibleValues";
import { isCo2 } from "../../../combustionProcedeUtils/biomasse/helpers";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface EmissionArrayProps {
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  procedesInPageMap: Map<string, ProcedeInArray>;
  isValidated: boolean;
  validationEmissionPath: string;
  referentialCO2: ReferenceItemPolluantDto;
}

const EmissionCorrelationArray = ({
  correlationEmissionsInPage,
  setCorrelationEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
  referentialCO2,
  procedesInPageMap,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const arraySubstancesLines = correlationEmissionsInPage
    .filter(correlation => {
      const methods = correlation.data.methods;
      return methods === MethodEnum.FACTEUR_CORRELATION;
    })
    .reduce(
      (
        arrayLines: React.ReactElement[][],
        correlation: CorrelationEmissionInArray
      ) => {
        const isEmissionCo2 = isCo2(correlation.data.substance, referentialCO2);

        if (isEmissionCo2) {
          arrayLines.push(
            ...computeCo2EmissionLine(
              correlation,
              procedesInPageMap,
              classes,
              setEmissionInModale,
              setEmissionModaleOpen,
              setCorrelationEmissionsInPage,
              isValidated,
              validationEmissionPath,
              computeFirstLineContentCell
            )
          );
          return arrayLines;
        }
        arrayLines.push(
          computeSingleEmissionLine(
            correlation,
            procedesInPageMap,
            classes,
            setEmissionInModale,
            setEmissionModaleOpen,
            setCorrelationEmissionsInPage,
            isValidated,
            validationEmissionPath,
            computeFirstLineContentCell
          )
        );

        return arrayLines;
      },
      []
    );

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Procédé</p>,
          <p>Quantité de matière première ou volume d'activité</p>,
          <p>Unité</p>,
          <p>Nature de la corrélation</p>,
          <p>Facteur de la corrélation</p>,
          <p>Unité du facteur de corrélation</p>,
          <p>
            Masse volumique de la quantité de matière première ou
            production(kg/l)
          </p>,
          <p>Provenance du facteur de corrélation</p>,
          <p>Émissions annuelles (kg/an)</p>,
          <p>Les émissions font-elles l'objet d'épuration ?</p>,
          <p>Nature des équipements</p>,
          <p>Rendement de l'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          250,
          150,
          200,
          130,
          130,
          130,
          130,
          250,
          200,
          130,
          200,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionCorrelationArray;
