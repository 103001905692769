import React, { ReactElement } from "react";
import { Pds22Now, QuotasInstallationDto20Now } from "api/gen";
import Collapsible from "common/presentational/Collapsible";
import PdsInfosGenerales from "./InfosGenerales/PdsInfosGenerales";
import PdsBlocMethodeSurveillance from "./BlocMethodeSurveillance/PdsBlocMethodeSurveillance";
import PdsBlocDonneeSurveillance from "./BlocDonneeSurveillance/PdsBlocDonneeSurveillance";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  collapsideTitle: {
    textAlign: "center",
  },
});

interface PlanSurveillanceDataProps {
  installation: QuotasInstallationDto20Now;
  planSurveillanceList: Pds22Now[];
}

const PlanSurveillanceData = ({
  installation,
  planSurveillanceList,
}: PlanSurveillanceDataProps): ReactElement => {
  const classes = useStyles();

  const planSurveillance = planSurveillanceList.find(
    ({ nimDynId }) => nimDynId === installation.id
  );
  if (planSurveillance === undefined) {
    return <></>;
  }

  const {
    infosGeneralesPdsDto,
    methodeSurveillanceDto,
    blocDonneeSurveillanceDto,
  } = planSurveillance.pdsData;
  return (
    <Collapsible
      title="SYNTHÈSE DU PLAN DE SURVEILLANCE"
      additionalTitleClassname={classes.collapsideTitle}
    >
      <>
        <PdsInfosGenerales infosGenerales={infosGeneralesPdsDto} />
        <PdsBlocMethodeSurveillance
          methodeSurveillance={methodeSurveillanceDto}
          blocDonneeSurveillance={blocDonneeSurveillanceDto}
        />
        <PdsBlocDonneeSurveillance
          blocDonneeSurveillance={blocDonneeSurveillanceDto}
          methodeSurveillance={methodeSurveillanceDto}
        />
      </>
    </Collapsible>
  );
};

export default PlanSurveillanceData;
