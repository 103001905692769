import * as Yup from "yup";
import {
  requiredMessage,
  santeSecuriteInvalidMaxValueMessage,
  tooLongMessage,
} from "common/declarant/formik/formikMessages";
import {
  commonNullableStringFields,
  commonObjectFields,
  commonPositiveIntegerNumberFields,
  commonPositiveIntegerNumberFieldsNullable,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveIntegerNumberFields,
  commonStrictlyPositiveIntegerNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  AccreditedOrganismInterveningType,
  wasOtherOrganismSelected,
} from "./utils";

//to be used to compare with another field or 0 if this other field is empty
const moreThanRefOr0Builder = (invalidMessage: string) => (
  minimalValue: number | undefined,
  schema: Yup.NumberSchema
) => {
  const compareValue = minimalValue !== undefined ? minimalValue : 0;
  return schema.moreThan(compareValue, invalidMessage);
};

//because the 2 blocs have common validation rules for a lot of parts.
const CommonPoussiereBlocDataFields = {
  hasShownNotWeakRiskOnly: Yup.boolean(),
  accreditedOrganismIntervening: Yup.object().when("hasShownNotWeakRiskOnly", {
    is: true,
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  precision: Yup.string().when(
    ["accreditedOrganismIntervening", "hasShownNotWeakRiskOnly"],
    {
      is: (
        accreditedOrganismIntervening: AccreditedOrganismInterveningType | null,
        hasShownNotWeakRiskOnly: boolean | null
      ) =>
        hasShownNotWeakRiskOnly &&
        wasOtherOrganismSelected(accreditedOrganismIntervening || null),
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: commonNullableStringFields.max(250, tooLongMessage),
    }
  ),
  nbTotalPrelevement: Yup.number().when("hasShownNotWeakRiskOnly", {
    is: true,
    then: commonStrictlyPositiveIntegerNumberFields,
    otherwise: commonStrictlyPositiveIntegerNumberFieldsNullable,
  }),
};

export const poussiereAlveolaireQuartzTridimiteSchema = Yup.object().shape({
  ...CommonPoussiereBlocDataFields,
  nbGEHLessThan10PercentVLEP: Yup.number().when("hasShownNotWeakRiskOnly", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
});

export const poussiereAlveolaireTotaleSchema = Yup.object().shape({
  ...CommonPoussiereBlocDataFields,
  minimalValue: Yup.number().when(
    ["nbTotalPrelevement", "hasShownNotWeakRiskOnly"],
    {
      // the or, though not written in spec, is here justified because if hasShownNotWeakRiskOnly is true,  nbTotalPrelevement is required and must be strictly positive.
      is: (
        nbTotalPrelevement: number | null,
        hasShownNotWeakRiskOnly: boolean | null
      ) =>
        hasShownNotWeakRiskOnly ||
        (nbTotalPrelevement !== null && nbTotalPrelevement > 0),
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  maximalValue: Yup.number()
    .when(["nbTotalPrelevement", "hasShownNotWeakRiskOnly"], {
      is: (
        nbTotalPrelevement: number | null,
        hasShownNotWeakRiskOnly: boolean | null
      ) =>
        // the or, though not written in spec, is here justified because if hasShownNotWeakRiskOnly is true,  nbTotalPrelevement is required and must be strictly positive.
        hasShownNotWeakRiskOnly ||
        (nbTotalPrelevement !== null && nbTotalPrelevement > 0),
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    })
    .when(
      "minimalValue",
      moreThanRefOr0Builder(santeSecuriteInvalidMaxValueMessage)
    ),
  nbGEHBiggerThanVLEP: Yup.number().when("hasShownNotWeakRiskOnly", {
    is: true,
    then: commonPositiveIntegerNumberFields,
    otherwise: commonPositiveIntegerNumberFieldsNullable,
  }),
});

export const validationSchema = Yup.object().shape({
  wasRiskEvaluationRealised: Yup.boolean().required(requiredMessage),
  nbGEH: Yup.number().when("wasRiskEvaluationRealised", {
    is: true,
    then: commonStrictlyPositiveIntegerNumberFields,
    otherwise: commonStrictlyPositiveIntegerNumberFieldsNullable,
  }),
  poussiereAlveolaireQuartzTridimite: Yup.object().when(
    "wasRiskEvaluationRealised",
    {
      is: true,
      then: poussiereAlveolaireQuartzTridimiteSchema,
      otherwise: Yup.object().nullable(),
    }
  ),
  poussiereAlveolaireTotale: Yup.object().when("wasRiskEvaluationRealised", {
    is: true,
    then: poussiereAlveolaireTotaleSchema,
    otherwise: Yup.object().nullable(),
  }),
});
