import { AreasForm } from "./types";

export const computeTotalGroundPreviousYear = (values: AreasForm): number => {
  return (
    (values.agriculturalZonePreviousYear || 0) +
    (values.forestryZonePreviousYear || 0) +
    (values.economicZoneGroundPreviousYear || 0) +
    (values.ecologicalZoneGroundPreviousYear || 0) +
    (values.otherGroundPreviousYear || 0)
  );
};

export const computeTotalWaterPreviousYear = (values: AreasForm): number => {
  return (
    (values.economicZoneWaterPreviousYear || 0) +
    (values.ecologicalZoneWaterPreviousYear || 0) +
    (values.otherWaterPreviousYear || 0)
  );
};

export const computeTotalPreviousYear = (values: AreasForm): number => {
  return (
    computeTotalGroundPreviousYear(values) +
    computeTotalWaterPreviousYear(values)
  );
};

export const computeTotalGround = (values: AreasForm): number => {
  return (
    (values.agriculturalZone || 0) +
    (values.forestryZone || 0) +
    (values.economicZoneGround || 0) +
    (values.ecologicalZoneGround || 0) +
    (values.otherGround || 0)
  );
};

export const computeTotalWater = (values: AreasForm): number => {
  return (
    (values.economicZoneWater || 0) +
    (values.ecologicalZoneWater || 0) +
    (values.otherWater || 0)
  );
};

export const computeTotal = (values: AreasForm): number => {
  return computeTotalGround(values) + computeTotalWater(values);
};
