import React, { ReactElement } from "react";

import Button from "common/button";
import { useConfirmationModaleWithReason } from "common/modale/hooks";
import {
  generateTransitionWarningMessage,
  IN_REVIEW,
} from "common/messages/dashboardMessage";
import { useDeclarationStateHandlers21Now } from "../../versionedElements/declarationHooks21Now";
import { WorkflowTarget } from "common/utils/types";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";
import { useSpinnerState } from "../../../../../../common/button/loadingAndRedirectionHelpers";

const useStyles = makeStyles({
  reviewButton: {
    background: "white",
    color: PINK,
  },
});

const ReviewButton = ({ text }: { text: string }): ReactElement => {
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const classes = useStyles();
  const openConfirmationModaleWithReason = useConfirmationModaleWithReason();
  const { review } = useDeclarationStateHandlers21Now();

  return (
    <Button
      text={text}
      onClick={() => {
        openConfirmationModaleWithReason(
          generateTransitionWarningMessage(IN_REVIEW),
          "Votre message",
          message => {
            triggerSpinner(
              review([WorkflowTarget.GLOBAL], message ? message : "")
            );
          }
        );
      }}
      additionalClassname={classes.reviewButton}
      isSpinnerVisible={isSpinnerVisible}
    />
  );
};

export default ReviewButton;
