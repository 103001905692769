import { AirFugitivesProcedesDtoUniteEnum } from "api/gen";

export const unitLabels: {
  [key in AirFugitivesProcedesDtoUniteEnum]: string;
} = {
  KG: "kg (kilogramme)",
  T: "t (tonne)",
  HL: "hl (hectolitre)",
  L: "l (litre)",
  KM3: "1000 m³ (mille mètres cubes)",
  M3: "m³ (mètre cube)",
  AUTRE: "Autre",
};

export const unitList: AirFugitivesProcedesDtoUniteEnum[] = Object.keys(
  unitLabels
) as AirFugitivesProcedesDtoUniteEnum[];
