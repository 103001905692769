import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { BlocInstallationProps, InstallationInArray } from "./types";
import InstallationsArray from "./InstallationsArray";
import Row from "common/presentational/Row";
import Button from "common/button";
import { SECTION_TITLE_GREY } from "theme";
import InstallationModale from "./InstallationModale";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "spaceBetween",
    marginBottom: "15px",
  },
  subTitle: {
    color: SECTION_TITLE_GREY,
  },
});

const Installations = ({
  values,
  setValues,
  possibleActivitesValues,
  shouldDisableFields,
}: BlocInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    installationInModal,
    setInstallationInModal,
  ] = useState<InstallationInArray | null>(null);

  const openModal = (installation: InstallationInArray | null) => {
    setInstallationInModal(installation);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInstallationInModal(null);
  };

  return (
    <>
      <Row additionalClassname={classes.row}>
        <p className={classes.subTitle}>
          Déclaration et description des installations quotas
        </p>
        <Button
          text="AJOUTER UN NIM"
          onClick={() => {
            setIsModalOpen(true);
          }}
          isDisabled={shouldDisableFields}
        />
      </Row>
      <InstallationsArray
        installationsInArray={values}
        setInstallationsInArray={setValues}
        openModal={openModal}
        isDisabled={shouldDisableFields}
      />

      <InstallationModale
        isOpen={isModalOpen}
        installationInModal={installationInModal}
        installationsInArray={values}
        setInstallationsInArray={setValues}
        closeModal={closeModal}
        possibleActivitesValues={possibleActivitesValues}
      />
    </>
  );
};

export default Installations;
