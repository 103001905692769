import React, { ReactElement } from "react";
import Button from "common/button";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useConfirmationModale } from "common/modale/hooks";
import SeparateSubmissionWarning from "common/messages/SeparateSubmissionWarning";
import { useDeclarationStateHandlers21Now } from "../../versionedElements/declarationHooks21Now";
import { QuotaWorkflowTarget } from "common/utils/types";

const QuotaSubmitButton = ({
  workflowTarget,
}: {
  workflowTarget: QuotaWorkflowTarget;
}): ReactElement => {
  const { submit } = useDeclarationStateHandlers21Now();
  const openConfirmationModale = useConfirmationModale();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  return (
    <Button
      text={"TRANSMETTRE À L'INSPECTION"}
      onClick={() =>
        openConfirmationModale(<SeparateSubmissionWarning />, () =>
          triggerSpinner(submit([workflowTarget]))
        )
      }
      isSpinnerVisible={isSpinnerVisible}
    />
  );
};

export default QuotaSubmitButton;
