import React from "react";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ProcedeInArray } from "../listprocede/utils/types";

//TODO https://dl.polyconseil.fr/jira/browse/GEREP-906 : these function could most likely be factorised in a single method.
export const createProcedeDiv = (
  procedes: OptionPropsWithObject<ProcedeInArray, string>[]
) => {
  const procedesText = procedes
    .map(procede => {
      return procede.object.data.name;
    })
    .join(", ");

  return <div title={procedesText}>{procedesText}</div>;
};

export const getProcedesLabels = (
  procedes: OptionPropsWithObject<ProcedeInArray, string>[]
) => {
  return procedes.map(procede => {
    return procede.label;
  });
};

export const getSingleProcede = (
  procedeName: string,
  procedes: ProcedeInArray[]
): OptionPropsWithObject<ProcedeInArray, string>[] => {
  const procede = procedes.find(procede => {
    return procede.data.name === procedeName;
  });
  if (procede) {
    return [{ value: procedeName, label: procedeName, object: { ...procede } }];
  }

  throw Error(
    "this method should be called with a procedeName that is in the Procede array given"
  );
};

export const getProcedes = (
  procedeNames: string[],
  procedes: ProcedeInArray[]
): OptionPropsWithObject<ProcedeInArray, string>[] => {
  const procedeArray: OptionPropsWithObject<ProcedeInArray, string>[] = [];
  procedes
    .filter(procede => {
      return !!procede.data.name && procedeNames.includes(procede.data.name);
    })
    .forEach(procede => {
      if (procede.data.name) {
        procedeArray.push({
          value: procede.data.name,
          label: procede.data.name,
          object: { ...procede },
        });
      }
    });
  return procedeArray;
};
