import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { RapportEmissionDto22Now } from "api/gen";
import FilesLoader from "common/FilesLoader/FilesLoader";
import {
  NimFilesLoaderProps,
  oneFileRelatedToNimToFileDto,
} from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/utils";
import { useRapportEmissionsFileHandler22Now } from "../../../../../from22/toNow/versionedElements/filesLoaderDisplayer22Now/sectionFilesHooks22Now";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";

const RapportEmissionLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const openAlertModale = useAlertModale();
  const rapportEmissionsFileHandler = useRapportEmissionsFileHandler22Now(
    installationId
  );

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE RAPPORT D'EMISSIONS"}
      filesPath={
        "body.externalSections.quotas.emissions.blocEmissions.rapportEmissionsList"
      }
      section={FileSectionEnum.QUOTAS_RAPPORT_EMISSIONS}
      fileHandlers={rapportEmissionsFileHandler}
      isDisabled={shouldDisableFields}
      max={1}
      onReject={() =>
        openAlertModale(
          "Le format attendu pour le rapport d'émission est le format EXCEL."
        )
      }
      transformFunction={(rapports: Record<string, unknown>[]) =>
        oneFileRelatedToNimToFileDto(
          installationId,
          (rapports as unknown) as RapportEmissionDto22Now[]
        )
      }
    />
  );
};

export default RapportEmissionLoader;
