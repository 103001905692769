import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { createProcedeDiv } from "../utils/utils";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { EmissionsInArray, MatiereEmissionInArray } from "./utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface EmissionArrayProps {
  matiereEmissionsInPage: MatiereEmissionInArray[];
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationEmissionPath: string;
}

const EmissionMatiereArray = ({
  matiereEmissionsInPage,
  setMatiereEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInPage: MatiereEmissionInArray) {
    setMatiereEmissionsInPage(elderEmissions => {
      return elderEmissions.filter(elderEmissionInPage => {
        return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: MatiereEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const emissionTitleString =
      emission.data.substance !== null ? emission.data.substance.label : "";

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      emissionTitleString,
      isValidated,
      !_.isEmpty(emission.errors)
    );

    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.procede !== null
          ? createProcedeDiv(emission.data.procede)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.descIntrant || undefined}>
          {emission.data.descIntrant}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.quantityIn !== null
          ? computeNumberWithSeparator(emission.data.quantityIn)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.quantityOut !== null
          ? computeNumberWithSeparator(emission.data.quantityOut)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.elementIndexe || undefined}>
          {" "}
          {emission.data.elementIndexe}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.teneurMoyenne !== null
          ? computeNumberWithSeparator(emission.data.teneurMoyenne)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.partElement !== null
          ? computeNumberWithSeparator(emission.data.partElement)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.emissionAnnuelle !== null
          ? computeNumberWithSeparator(emission.data.emissionAnnuelle)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.biomasse !== null
          ? computeNumberWithSeparator(emission.data.biomasse)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.natureEquipement || undefined}>
          {emission.data.natureEquipement}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.rendementEpuration !== null
          ? computeNumberWithSeparator(emission.data.rendementEpuration)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        isValidated={isValidated}
        commentPath={`${validationEmissionPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines: React.ReactElement[][] = matiereEmissionsInPage
    .filter(matiere => {
      const methods = matiere.data.methods;
      return methods && methods.value === 3;
    })
    .map(matiere => {
      return computeSingleEmissionLine(matiere, classes);
    });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Procédé(s)</p>,
          <p>Description des intrants</p>,
          <p>Quantité entrante (kg/an)</p>,
          <p>Quantité sortante (kg/an)</p>,
          <p>Élément sur lequel est indexé le bilan matière</p>,
          <p>Teneur moyenne de l'élément dans les intrants (%)</p>,
          <p>Part de l'élément dans la substance émise (%)</p>,
          <p>Émissions annuelles (kg/an)</p>,
          <p>Fraction de la biomasse (%)</p>,
          <p>Les émissions font-elles l'objet d'épuration ?</p>,
          <p>Nature des équipements</p>,
          <p>Rendement de l'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          300,
          130,
          130,
          130,
          130,
          200,
          200,
          200,
          130,
          130,
          200,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionMatiereArray;
