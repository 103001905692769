import React from "react";
import {
  AirCombustionEmissionBilanDto20Now,
  AirCombustionEmissionDto2023,
  AirCombustionEmissionFacteurDto,
  AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum,
  AirCombustionEmissionMesureDto2023,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
  ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum,
} from "api/gen";
import { computeH32 } from "./calculus";
import { transformNameAppareilsInAppareilsInArray } from "../../../utils/utils";
import {
  CombustibleInArray,
  CombustibleInModale,
} from "../../BlocCombustibles/utils/types";
import {
  EmissionsInArray,
  EmissionsInModale,
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { MethodCombustion } from "./selectPossibleValues";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import { ReferentialSinglePolluants } from "../../../utils/types";
import { singleEmissionOverwriteDependantFields } from "../validation/validation";
import cloneDeep from "lodash.clonedeep";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants } from "../../../../../../toNow/Air/combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import { arrayUniteLabel } from "../../BlocCombustibles/utils/selectPossibleValues";
import { getValueOrZero } from "common/utils/numberUtils";

export const createAppareilDiv = (
  appareils: AppareilInArray[]
): React.ReactElement => {
  const appareilsName = appareils
    .map(appareil => {
      return appareil.data.nom;
    })
    .join(", ");
  return <p title={appareilsName}>{appareilsName}</p>;
};

//=============================================================
// EMISSIONS
//==========FACTEUR
//=============================================================
const getCombustible = (
  facteur: AirCombustionEmissionFacteurDto,
  combustibles: CombustibleInArray[],
  installationName: string | null
): CombustibleInArray | null => {
  const combustible = combustibles.find(combustible => {
    return (
      combustible.data.id === facteur.combustibleDynId &&
      combustible.data.nameInstallation !== null &&
      installationName === combustible.data.nameInstallation
    );
  });
  if (combustible) {
    return combustible;
  }
  return null;
};

const computeFacteurEmissionsAnnuelles = (
  facteur: number | null,
  facteurOxydation: number | null,
  combustible: CombustibleInModale | null
): number | null => {
  if (combustible && facteur !== null) {
    return computeH32(facteur, facteurOxydation, combustible);
  }
  return null;
};

const convertFacteurToDisplayed = (
  facteurs: AirCombustionEmissionFacteurDto[],
  combustibles: CombustibleInArray[],
  substanceReferential: ReferenceItemPolluantDto[],
  appareilsReferential: AppareilInArray[]
): FacteurEmissionInArray[] => {
  return facteurs.map(facteur => {
    const combustible = getCombustible(
      facteur,
      combustibles,
      facteur.installation
    );

    const substance =
      facteur.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: facteur.substanceID },
            substanceReferential
          );

    const appareils = transformNameAppareilsInAppareilsInArray(
      facteur.appareils,
      appareilsReferential
    );

    const method = MethodCombustion.FACTEUR_EMISSION;

    return {
      //TODO : compute errors
      data: {
        id: facteur.id,
        nameInstallation: facteur.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: facteur.epuration,
        nature: facteur.nature,
        rendement: facteur.rendement,
        emissionsAnnuelles: computeFacteurEmissionsAnnuelles(
          facteur.facteur,
          facteur.facteurOxydation,
          combustible && combustible.data
        ),
        // specific
        combustible: combustible,
        consoAnnuelle: combustible ? combustible.data.consommation : null,
        referenceCombustible: combustible ? combustible.data.code : null,
        unite:
          combustible &&
          combustible.data.unite &&
          arrayUniteLabel[combustible.data.unite],
        facteur: facteur.facteur,
        ecart: facteur.ecart,
        provenance: facteur.provenance,
        facteurOxydation: facteur.facteurOxydation,
        provenanceFacteurOxydation: facteur.provenanceFacteurOxydation,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionFacteurDto = (
  facteursInPage: FacteurEmissionInArray[]
): AirCombustionEmissionFacteurDto[] => {
  return facteursInPage.map(singlePopulatedFacteur => {
    const singleFacteur = singlePopulatedFacteur.data;
    const singleFacteurDto: AirCombustionEmissionFacteurDto = {
      id: singlePopulatedFacteur.data.id,
      installation: singleFacteur.nameInstallation,
      appareils:
        singleFacteur.appareils === null
          ? []
          : singleFacteur.appareils.map(
              singleAppareil => singleAppareil.data.nom || ""
            ),
      combustibleDynId:
        singleFacteur.combustible !== null
          ? singleFacteur.combustible.data.id
          : "",
      ecart: singleFacteur.ecart,
      epuration: !!singleFacteur.epuration,
      facteur: singleFacteur.facteur,
      facteurOxydation: singleFacteur.facteurOxydation,
      nature: singleFacteur.nature,
      provenance: singleFacteur.provenance,
      provenanceFacteurOxydation: singleFacteur.provenanceFacteurOxydation,
      rendement: singleFacteur.rendement,
      substanceID: singleFacteur.substance && singleFacteur.substance.uuid,
    };
    return singleFacteurDto;
  });
};

//=============================================================
// EMISSIONS
//==========MESURE
//=============================================================
export const convertMesureToDisplayed = (
  mesures: AirCombustionEmissionMesureDto2023[],
  substanceReferential: ReferenceItemPolluantDto[],
  appareilsReferential: AppareilInArray[]
): MesureEmissionInArray[] => {
  return mesures.map(mesure => {
    const substance =
      mesure.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: mesure.substanceID },
            substanceReferential
          );

    const appareils = transformNameAppareilsInAppareilsInArray(
      mesure.appareils,
      appareilsReferential
    );

    const method = MethodCombustion.MESURE;

    return {
      data: {
        //general
        nameInstallation: mesure.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: mesure.epuration,
        nature: mesure.nature,
        rendement: mesure.rendement,
        emissionsAnnuelles: computeMesureEmissionsAnnuelles(
          mesure.debit.debit,
          mesure.heures,
          mesure.debit.concentration,
          mesure.debit.concentrationUnite
        ),
        //specific
        heures: mesure.heures,
        debit: mesure.debit.debit,
        continuDebit: mesure.debit.continu,
        frequenceDebit: mesure.debit.frequence,
        concentrationDebit: mesure.debit.concentration,
        concentrationDebitUnit:
          mesure.debit.concentrationUnite ||
          AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.KG_NM3,
        continuConcentration: mesure.concentration.continu,
        frequenceConcentration: mesure.concentration.frequence,
        biomasseMesure: mesure.biomasse,
        id: mesure.id,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionMesureDto = (
  mesuresInPage: MesureEmissionInArray[]
): AirCombustionEmissionMesureDto2023[] => {
  return mesuresInPage.map(singlePopulatedMesure => {
    const singleMesure = singlePopulatedMesure.data;

    const singleMesureDto: AirCombustionEmissionMesureDto2023 = {
      id: singlePopulatedMesure.data.id,
      installation: singleMesure.nameInstallation,
      appareils:
        singleMesure.appareils === null
          ? []
          : singleMesure.appareils.map(
              singleAppareil => singleAppareil.data.nom || ""
            ),
      biomasse: singleMesure.biomasseMesure,
      concentration: {
        continu: !!singleMesure.continuConcentration,
        frequence: singleMesure.frequenceConcentration,
      },
      debit: {
        concentration: singleMesure.concentrationDebit,
        concentrationUnite: singleMesure.concentrationDebitUnit,
        continu: !!singleMesure.continuDebit,
        debit: singleMesure.debit,
        frequence: singleMesure.frequenceDebit,
      },
      epuration: !!singleMesure.epuration,
      heures: singleMesure.heures,
      nature: singleMesure.nature,
      rendement: singleMesure.rendement,
      substanceID: singleMesure.substance && singleMesure.substance.uuid,
    };
    return singleMesureDto;
  });
};

//=============================================================
// EMISSIONS
//==========MATIERE
//=============================================================

export const elementTooltip = (
  <span>
    Exemple : Dans le cas de la détermination des émissions de SO<sub>2</sub>{" "}
    issues de la combustion de fuel lourd ou de charbon, l'élément sur lequel
    est indexé le calcul est la teneur en soufre du combustible.{" "}
  </span>
);

export const convertMatiereToDisplayed = (
  matieres: AirCombustionEmissionBilanDto20Now[],
  substanceReferential: ReferenceItemPolluantDto[],
  appareilsReferential: AppareilInArray[],
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >
): MatiereEmissionInArray[] => {
  return matieres.map(matiere => {
    const substance =
      matiere.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: matiere.substanceID },
            substanceReferential
          );

    const appareils = transformNameAppareilsInAppareilsInArray(
      matiere.appareils,
      appareilsReferential
    );

    const method = MethodCombustion.BILAN_MATIERE;

    const polluantElementsInReferential: Map<
      string | null,
      ReferenceItemPolluantElementDto
    > | null =
      polluantElementMap.get(substance && substance.restorationCode) || null;

    const polluantElementInReferential: ReferenceItemPolluantElementDto | null =
      (polluantElementsInReferential &&
        polluantElementsInReferential.get(matiere.elementID)) ||
      null;

    return {
      data: {
        //general
        nameInstallation: matiere.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: matiere.epuration,
        nature: matiere.nature,
        rendement: matiere.rendement,
        emissionsAnnuelles: computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants(
          matiere.intrants,
          matiere.sortants,
          matiere.part
        ),
        //specific
        intrants: matiere.intrants,
        sortants: matiere.sortants,
        elementProps: polluantElementInReferential,
        elementName: matiere.elementName,
        partElement: matiere.part,
        biomasseMatiere: matiere.biomasse,
        id: matiere.id,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionMatiereDto = (
  matieresInPage: MatiereEmissionInArray[]
): AirCombustionEmissionBilanDto20Now[] => {
  return matieresInPage.map(singlePopulatedMatiere => {
    const singleMatiere = singlePopulatedMatiere.data;

    const singleMatiereDto: AirCombustionEmissionBilanDto20Now = {
      id: singlePopulatedMatiere.data.id,
      installation: singleMatiere.nameInstallation,
      appareils:
        singleMatiere.appareils === null
          ? []
          : singleMatiere.appareils.map(
              singleAppareil => singleAppareil.data.nom || ""
            ),
      biomasse: singleMatiere.biomasseMatiere,
      elementID:
        singleMatiere.elementProps &&
        singleMatiere.elementProps.referenceItemElementIndexDto.uuid,
      elementName: singleMatiere.elementName,
      epuration: !!singleMatiere.epuration,
      intrants: singleMatiere.intrants || [],
      sortants: singleMatiere.sortants || [],
      nature: singleMatiere.nature,
      part: singleMatiere.partElement,
      rendement: singleMatiere.rendement,
      substanceID: singleMatiere.substance && singleMatiere.substance.uuid,
    };
    return singleMatiereDto;
  });
};

//=============================================================
// EMISSIONS
//=============================================================
type ExportedEmissions = [
  FacteurEmissionInArray[],
  MesureEmissionInArray[],
  MatiereEmissionInArray[]
];

export const convertEmissionsToDisplayed = (
  emissions: AirCombustionEmissionDto2023,
  combustible: CombustibleInArray[],
  substanceReferential: ReferenceItemPolluantDto[],
  appareilsReferential: AppareilInArray[],
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>
): ExportedEmissions => {
  const facteurs = convertFacteurToDisplayed(
    emissions.facteurs,
    combustible,
    substanceReferential,

    appareilsReferential
  );
  const mesures = convertMesureToDisplayed(
    emissions.mesures,
    substanceReferential,
    appareilsReferential
  );
  const matieres = convertMatiereToDisplayed(
    emissions.bilans,
    substanceReferential,
    appareilsReferential,
    polluantElementMap
  );
  return [facteurs, mesures, matieres];
};

export const createAirCombustionEmissionDto = ([
  facteursInPage,
  mesuresInPage,
  matieresInPage,
]: ExportedEmissions): AirCombustionEmissionDto2023 => {
  return {
    facteurs: createAirCombustionEmissionFacteurDto(facteursInPage),
    mesures: createAirCombustionEmissionMesureDto(mesuresInPage),
    bilans: createAirCombustionEmissionMatiereDto(matieresInPage),
  };
};

//=============================================================
// EMISSIONS
//==========UPDATES
//=============================================================

export const defaultEmissionInModaleInitialValue: EmissionsInModale = {
  appareils: null,
  biomasseMatiere: null,
  biomasseMesure: null,
  combustible: null,
  concentrationDebit: null,
  concentrationDebitUnit: null,
  consoAnnuelle: null,
  continuConcentration: false,
  continuDebit: false,
  debit: null,
  ecart: null,
  elementName: null,
  elementProps: null,
  emissionsAnnuelles: null,
  epuration: false,
  facteur: null,
  facteurOxydation: null,
  frequenceConcentration: null,
  frequenceDebit: null,
  heures: null,
  methods: null,
  nameInstallation: null,
  nature: null,
  partElement: null,
  provenance: null,
  provenanceFacteurOxydation: null,
  referenceCombustible: null,
  rendement: null,
  substance: null,
  unite: null,
  intrants: [],
  sortants: [],
};

export const computeEmissionsAnnuelles = (
  values: EmissionsInModale,
  autreElementUuid: string
): number | null => {
  if (values.methods && values.methods === MethodCombustion.FACTEUR_EMISSION) {
    return computeFacteurEmissionsAnnuelles(
      values.facteur,
      values.facteurOxydation,
      values.combustible && values.combustible.data
    );
  } else if (values.methods && values.methods === MethodCombustion.MESURE) {
    return computeMesureEmissionsAnnuelles(
      values.debit,
      values.heures,
      values.concentrationDebit,
      values.concentrationDebitUnit
    );
  } else {
    const part: number | null =
      values.elementProps === null ||
      values.elementProps.referenceItemElementIndexDto.uuid === autreElementUuid
        ? values.partElement
        : 100 * values.elementProps.partElement;

    return computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants(
      values.intrants,
      values.sortants,
      part
    );
  }
};

export const shouldDisplayEcartEmission = (
  values: EmissionsInModale,
  referentialSinglePolluants: ReferentialSinglePolluants
): boolean => {
  if (
    values.combustible &&
    values.combustible.data !== null &&
    values.combustible.data.type &&
    values.substance
  ) {
    const combustible = values.combustible.data.type;
    return (
      (values.substance.uuid === referentialSinglePolluants.CO2.uuid &&
        combustible.infCO2 !== null &&
        combustible.supCO2 !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infCO2 ||
          values.facteur > combustible.supCO2)) ||
      (values.substance.uuid === referentialSinglePolluants.SOX.uuid &&
        combustible.infSOX !== null &&
        combustible.supSOX !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infSOX ||
          values.facteur > combustible.supSOX)) ||
      (values.substance.uuid === referentialSinglePolluants.NOX.uuid &&
        combustible.infNOX !== null &&
        combustible.supNOX !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infNOX ||
          values.facteur > combustible.supNOX))
    );
  } else {
    return false;
  }
};

export const updateEmission = (
  emission: EmissionsInModale,
  installationName: string | null,
  referentialSinglePolluants: ReferentialSinglePolluants,
  autreElementUuid: string
): EmissionsInModale => {
  const newEmission = cloneDeep(emission);
  newEmission.emissionsAnnuelles = computeEmissionsAnnuelles(
    emission,
    autreElementUuid
  );
  newEmission.nameInstallation = installationName || "";
  newEmission.referenceCombustible = emission.combustible
    ? emission.combustible.data.code
    : "";
  newEmission.consoAnnuelle =
    emission.combustible && emission.combustible.data.consommation;
  newEmission.unite =
    emission.combustible && emission.combustible.data.unite
      ? emission.combustible.data.unite
      : "";
  if (!shouldDisplayEcartEmission(emission, referentialSinglePolluants)) {
    newEmission.ecart = null;
  }
  singleEmissionOverwriteDependantFields(
    newEmission,
    referentialSinglePolluants.CO2
  );
  return newEmission;
};

const computeMesureEmissionsAnnuelles = (
  debit: number | null,
  heures: number | null,
  concentrationDebit: number | null,
  concentrationDebitUnit: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum | null
): number | null => {
  return (
    getValueOrZero(debit) *
    getValueOrZero(heures) *
    getValueOrZero(
      convertConcentrationToKgNM3(concentrationDebit, concentrationDebitUnit)
    )
  );
};

export const convertConcentrationToTargetUnit = (
  concentrationBase: number,
  concentrationBaseUnit: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum,
  concentrationTargetUnit: ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum
): number => {
  const intermediaryValue = convertConcentrationToKgNM3(
    concentrationBase,
    concentrationBaseUnit
  );
  if (!intermediaryValue) {
    throw new Error(
      "Failed to convert " +
        concentrationBaseUnit +
        " into " +
        concentrationTargetUnit
    );
  }
  switch (concentrationTargetUnit) {
    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.KG_NM3:
      return intermediaryValue;

    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.G_NM3:
      return intermediaryValue * 1e3;

    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.MG_NM3:
      return intermediaryValue * 1e6;

    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.UG_NM3:
      return intermediaryValue * 1e9;

    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.NG_NM3:
      return intermediaryValue * 1e12;

    case ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum.PG_NM3:
      return intermediaryValue * 1e15;

    default:
      throw new ShouldNotHappen(concentrationTargetUnit);
  }
};
const convertConcentrationToKgNM3 = (
  concentration: number | null,
  concentrationUnit: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum | null
): number | null => {
  if (concentration === null || concentrationUnit === null) {
    return null;
  }

  switch (concentrationUnit) {
    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.KG_NM3:
      return concentration;

    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.G_NM3:
      return concentration * 1e-3;

    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.MG_NM3:
      return concentration * 1e-6;

    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.UG_NM3:
      return concentration * 1e-9;

    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.NG_NM3:
      return concentration * 1e-12;

    case AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum.PG_NM3:
      return concentration * 1e-15;

    default:
      throw new ShouldNotHappen(concentrationUnit);
  }
};

export const compareEmissions = (
  a: EmissionsInArray,
  b: EmissionsInArray
): number => {
  if (
    a.data.substance &&
    b.data.substance &&
    a.data.substance.nom !== b.data.substance.nom
  ) {
    return a.data.substance.nom < b.data.substance.nom ? -1 : 1;
  }

  if (
    a.data.appareils &&
    b.data.appareils &&
    a.data.appareils !== b.data.appareils
  ) {
    return a.data.appareils < b.data.appareils ? -1 : 1;
  }
  return 0;
};
