export interface BackErrorMessages {
  [key: number]: string;
  default: string;
}

const unknowErrorOccuredStr =
  "Une erreur est survenue. Veuillez vérifier votre connexion internet et ré-essayer ultérieurement.";

export const backAlertMessage = (
  exception: any,
  messages: BackErrorMessages
): string => {
  let errorStr: string;
  if (exception instanceof Response) {
    errorStr = messages[exception.status] || messages["default"];
  } else {
    errorStr = unknowErrorOccuredStr;
  }

  return errorStr;
};
