import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useAccuseTransmissionPdfExport } from "pages/CompanySpace/DeclarationApiContext/utils/exportHooks";
import Row from "common/presentational/Row";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    marginBottom: "20px",
  },
});

const AccuseTransmissionDownloadButton = ({
  declarationAnnee,
  declarationEtablissement,
  isDeclarationDone,
  suffixFileName,
  exportRequest,
}: {
  declarationAnnee: string;
  declarationEtablissement: string;
  isDeclarationDone: boolean;
  suffixFileName: string;
  exportRequest: (
    annee: number,
    etablissement: string,
    options?: any
  ) => Promise<Response>;
}): ReactElement => {
  const classes = useStyles();

  const exportAccuseTransmissionPdf = useAccuseTransmissionPdfExport(
    suffixFileName,
    exportRequest
  );
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  return (
    <Row additionalClassname={classes.container}>
      <Button
        text={
          <>
            <FontAwesomeIcon icon="download" /> Télécharger l'accusé de
            transmission
          </>
        }
        isReversed
        onClick={() =>
          triggerSpinner(
            exportAccuseTransmissionPdf(
              Number(declarationAnnee),
              declarationEtablissement
            )
          )
        }
        isDisabled={!isDeclarationDone}
        isSpinnerVisible={isSpinnerVisible}
      />
    </Row>
  );
};

export default AccuseTransmissionDownloadButton;
