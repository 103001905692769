import React from "react";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import uuid from "uuid";
import { gasOptions, methodLabels } from "../selectPossibleValues";
import _ from "lodash";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { getInstallationOptions } from "../converter";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import {
  AlternativeEmissionInArray,
  AlternativeEmissionInModale,
  GasEnum,
  MethodEnum,
} from "../types";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
  overWriteEmissionDependantFields,
} from "../utils/utils";
import { validationSchema } from "./validation";
import { QuotasInstallationDto20Now } from "api/gen";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface AlternativeEmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setAlternativeEmissionsInArray: (
    callback: (
      previousEmissions: AlternativeEmissionInArray[]
    ) => AlternativeEmissionInArray[]
  ) => void;
  setAlternativeEmissionInModal: (
    emission: AlternativeEmissionInArray | null
  ) => void;
  alternativeEmissionInModal: AlternativeEmissionInArray | null;
  installations: QuotasInstallationDto20Now[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const AlternativeEmissionsModal = ({
  isOpen,
  setIsOpen,
  setAlternativeEmissionsInArray,
  setAlternativeEmissionInModal,
  alternativeEmissionInModal,
  installations,
}: AlternativeEmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emissions-modale-mesurees",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);

  const onClose = () => {
    setAlternativeEmissionInModal(null);
    setIsOpen(false);
  };

  const defaultMethodDummyValue = MethodEnum.ALTERNATIVE;

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UNE ÉMISSION"
        closeFunction={onClose}
        isEdit={alternativeEmissionInModal !== null}
        initialEntity={_.get(alternativeEmissionInModal, "data", {
          method: null,
          nimInstallation: null,
          methodDescription: null,
          gas: null,
          fossilEmission: null,
          biomassEmission: null,
          otherEmission: null,
        })}
        validationSchema={validationSchema}
        onSubmit={(values: AlternativeEmissionInModale) => {
          const emission: AlternativeEmissionInArray = {
            //TODO: compute errors
            data: {
              id: alternativeEmissionInModal
                ? alternativeEmissionInModal.data.id
                : uuid(),
              method: defaultMethodDummyValue,
              nimInstallation: values.nimInstallation,
              methodDescription: values.methodDescription,
              gas: values.gas,
              fossilEmission: values.fossilEmission,
              biomassEmission: values.biomassEmission,
              otherEmission: values.otherEmission,
            },
            errors: {},
          };
          overWriteEmissionDependantFields(emission);
          setAlternativeEmissionsInArray(
            currentAlternativesEmissionsInArray => {
              const newAlternativesEmissionsInArray = _.cloneDeep(
                currentAlternativesEmissionsInArray
              );
              let indexToReplace = newAlternativesEmissionsInArray.length;
              if (alternativeEmissionInModal) {
                indexToReplace = _.findIndex(
                  newAlternativesEmissionsInArray,
                  alternativeEmissionInModal
                );
                setAlternativeEmissionInModal(null);
              }
              newAlternativesEmissionsInArray[indexToReplace] = emission;
              return newAlternativesEmissionsInArray;
            }
          );
          setIsOpen(false);
        }}
        renderField={({ values }) => (
          <>
            <DummyChoiceSelectWithLabel<MethodEnum, false>
              label="Méthode utilisée *"
              name="method"
              isMulti={false}
              options={[defaultMethodDummyValue]}
              value={defaultMethodDummyValue}
              disabled
              computeLabel={method => methodLabels[method]}
              commonProps={commonProps}
            />

            <TextField
              label="Description de la méthode *"
              name="methodDescription"
            />

            <WrappedChoiceSelect
              label="Numéro NIM *"
              name="nimInstallation"
              isMulti={false}
              options={getInstallationOptions(installations)}
              computeLabel={installation => installation.nim || ""}
              commonProps={commonProps}
            />

            <WrappedChoiceSelect
              label="Gaz émis *"
              name="gas"
              isMulti={false}
              options={gasOptions}
              computeLabel={gas => gas.toString()}
              commonProps={commonProps}
            />

            {values.gas && isOtherEmissionSubPartActivated(values.gas) && (
              <NumberField
                label={`Émission de ${
                  values.gas === GasEnum.PFC ? "PFC" : "N2O"
                } *`}
                name="otherEmission"
                unit={computeUnitTCO2PerYear}
              />
            )}

            {isBiomasseSubPartActivated(values.gas) && (
              <>
                <NumberField
                  label="Émission totale d'origine non biomasse *"
                  name="fossilEmission"
                  unit={computeUnitTCO2PerYear}
                />

                <NumberField
                  label="Émission totale d'origine biomasse *"
                  name="biomassEmission"
                  unit={computeUnitTCO2PerYear}
                />
              </>
            )}
          </>
        )}
      />
    </CustomModal>
  );
};

export default AlternativeEmissionsModal;
