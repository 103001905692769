import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ComputedMethodOptions, GasOptions } from "./types";

const gas: OptionPropsWithObject<GasOptions>[] = [
  {
    value: 1,
    label: "CO2",
    object: GasOptions.CO2,
  },
  {
    value: 2,
    label: "PFC",
    object: GasOptions.PFC,
  },
  {
    value: 3,
    label: "N2O",
    object: GasOptions.N2O,
  },
];

const methods = [
  {
    value: 1,
    label: "Méthode alternative/PFC",
  },
  {
    value: 2,
    label: "Méthode par mesure (SMCE)",
  },
];

const computedMethods: OptionPropsWithObject<ComputedMethodOptions>[] = [
  {
    value: 1,
    label: "Facteur d'oxydation",
    object: ComputedMethodOptions.FacteurOxydation,
  },
  {
    value: 2,
    label: "Facteur de conversion",
    object: ComputedMethodOptions.FacteurConversion,
  },
  {
    value: 3,
    label: "Bilan massique",
    object: ComputedMethodOptions.BilanMassique,
  },
];

export const selectPossibleValues = {
  gas: gas,
  computedMethods: computedMethods,
  methods: methods,
};
