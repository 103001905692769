import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { administratifFieldMatcher, AdministratifType } from "../utils/types";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import { ReferenceItemGeoCodeInseeDto, ReferenceItemPaysDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import FormikBlocFullContext23Now from "../../../toNow/versionedElements/FormikBlocFullContext23Now";
import { Declaration23Now } from "../../../toNow/versionedElements/declarationHooks23Now";
import { companyIdentityValidation, isCountryFrance } from "./validation";
import { getRefCityInseeByNomCommune } from "../utils/converter";

//  to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface CompanyIdentityProps
  extends CommonConnectedFormikBlocProps<AdministratifType, Declaration23Now> {
  referentielINSEE: ReferenceItemGeoCodeInseeDto[];
  referentielPays: ReferenceItemPaysDto[];
}

export const CompanyIdentity = ({
  formikRef,
  hasChanges,
  setHasChanges,
  initialValues,
  path,
  updateHandler,
  referentielINSEE,
  referentielPays,
}: CompanyIdentityProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-identity",
  };

  const TextField = useTextFieldGenerator(commonProps);

  return (
    <FormikBlocFullContext23Now
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      additionalFieldValidation={companyIdentityValidation}
      title={"identité de l'entreprise"}
      pathToValidate={path}
      areGlobalCommentsAllowed={true}
      updateHandler={updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <TextField
              label="Raison sociale *"
              name={administratifFieldMatcher.socialReason}
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Société mère"
              name={administratifFieldMatcher.parentCompany}
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Forme juridique *"
              name={administratifFieldMatcher.legalStatus}
              disabled={shouldDisabledFields}
              tooltipContent={"EARL, GAEC, SA, SARL, SAS, etc."}
            />
            <TextField
              label="Numéro SIREN"
              name={administratifFieldMatcher.SIRENNumber}
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={administratifFieldMatcher.country}
              label={"Pays *"}
              disabled={shouldDisabledFields}
              title={""}
              header={["Pays"]}
              linesData={referentielPays}
              formatLine={option => [option.designation]}
              commonProps={commonProps}
              isFirstSticky={false}
              formatSelectedTitle={lineData => lineData.designation}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection([lineData.designation], searchedStr)
              }
              onSelect={newValue => {
                if (newValue) {
                  if (
                    formProps.values.pays &&
                    isCountryFrance(formProps.values.pays.designation) &&
                    !isCountryFrance(newValue.designation) &&
                    typeof formProps.values.commune !== "string"
                  ) {
                    formProps.setFieldValue(
                      administratifFieldMatcher.city,
                      formProps.values.commune
                        ? formProps.values.commune.nomCommune
                        : null
                    );
                  }
                  if (
                    formProps.values.pays &&
                    !isCountryFrance(formProps.values.pays.designation) &&
                    isCountryFrance(newValue.designation) &&
                    (typeof formProps.values.commune === "string" ||
                      formProps.values.commune === null)
                  ) {
                    formProps.setFieldValue(
                      administratifFieldMatcher.city,
                      getRefCityInseeByNomCommune(
                        formProps.values.commune,
                        referentielINSEE
                      )
                    );
                  }
                } else {
                  formProps.setFieldValue(administratifFieldMatcher.city, null);
                }
              }}
            />
            {formProps.values.pays ? (
              isCountryFrance(formProps.values.pays.designation) ? (
                <>
                  <TextField
                    label="Adresse *"
                    name={administratifFieldMatcher.adress}
                    disabled={shouldDisabledFields}
                  />
                  <WrappedChoiceSelectModale
                    name={administratifFieldMatcher.city}
                    label={"Commune *"}
                    disabled={shouldDisabledFields}
                    title="Commune"
                    header={[
                      "Code INSEE",
                      "Code postal",
                      "Commune",
                      "Département",
                    ]}
                    linesData={referentielINSEE}
                    formatLine={insee => [
                      insee.codeInsee,
                      insee.codePostal,
                      insee.nomCommune,
                      insee.numeroDepartement,
                    ]}
                    formatSelectedTitle={(
                      lineData: ReferenceItemGeoCodeInseeDto
                    ) => {
                      return lineData.nomCommune;
                    }}
                    isFirstSticky={false}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection(
                        [
                          lineData.codeInsee,
                          lineData.codePostal,
                          lineData.nomCommune,
                          lineData.numeroDepartement,
                        ],
                        searchedStr
                      )
                    }
                    preferredColWidths={[100, 100, 250, 80]}
                    onSelect={data => {
                      formProps.setFieldValue(
                        administratifFieldMatcher.postalCode,
                        data ? data.codePostal : data
                      );
                    }}
                    commonProps={commonProps}
                  />
                  <TextField
                    label="Code postal *"
                    name={administratifFieldMatcher.postalCode}
                    disabled={shouldDisabledFields}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label="Adresse *"
                    name={administratifFieldMatcher.adress}
                    disabled={shouldDisabledFields}
                  />
                  <TextField
                    name={administratifFieldMatcher.city}
                    label="Commune *"
                    disabled={shouldDisabledFields}
                    tooltipContent={
                      "Saisir le nom de la commune en mettant des tirets (-) entre les mots pour une commune à noms composés et sans saisir l’article par lequel commence éventuellement le nom de la commune. Exemple pour « Les Sables d’Olonne », écrire « Sables- d’Olonne (les)», ou pour « Le Havre » écrire « Havre (Le) »"
                    }
                  />
                  <TextField
                    label="Code postal *"
                    name={administratifFieldMatcher.postalCode}
                    disabled={shouldDisabledFields}
                  />
                </>
              )
            ) : (
              <></>
            )}
          </>
        );
      }}
    />
  );
};
