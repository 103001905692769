import React from "react";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import { SubBlocEmpoussierageProps } from "./props";
import CommonPoussiere from "./CommonPoussiere";

const useStyles = makeStyles({
  longInput: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  ...LEFT_WITHDRAW_STYLE,
});

const fieldNamePrefix = "poussiereAlveolaireQuartzTridimite";

const PoussiereAlveolaireQuartzTridimiteSubPart = ({
  commonProps,
  formikProps,
  shouldDisableFields,
  organismeMeasuringEmpoussierageList,
  commentBasePath,
}: SubBlocEmpoussierageProps) => {
  const classes = useStyles();

  const NumberField = useNumberFieldGenerator(commonProps, classes.longInput);

  return (
    <>
      <CommonPoussiere
        shouldDisableFields={shouldDisableFields}
        subTitle={"Poussières alvéolaires de Quartz, Cristobalite, Tridimite"}
        fieldNamePrefix={fieldNamePrefix}
        parenthesisText={"article R. 4412-13 du code du travail"}
        commonProps={commonProps}
        formikProps={formikProps}
        longInputClassName={classes.longInput}
        organismeMeasuringEmpoussierageList={
          organismeMeasuringEmpoussierageList
        }
        commentBasePath={commentBasePath}
      />

      {formikProps.values.poussiereAlveolaireQuartzTridimite &&
        formikProps.values.poussiereAlveolaireQuartzTridimite
          .hasShownNotWeakRiskOnly && (
          <div className={classes.withdrawLeft}>
            <NumberField
              disabled={shouldDisableFields}
              name={`${fieldNamePrefix}.nbGEHLessThan10PercentVLEP`}
              label={
                "Nombre de GEH < 10% VLEP (Valeur Limite d'Exposition Professionnelle)"
              }
              unit={""}
              tooltipContent={
                "Nombre de GEH dont tous les prélèvements sont <10% à un au moins des 3 VLEP possibles. Si c’est le cas pour plusieurs VLEP, inscrire le nombre total de GEH inférieur à 10% des VLEP."
              }
              commentPath={`${commentBasePath}/nbGEHMoinsDe10PourcentVLEP`}
            />
          </div>
        )}
    </>
  );
};

export default PoussiereAlveolaireQuartzTridimiteSubPart;
