import {
  ActiviteDto1819,
  AdministratifDto1819,
  DeclarantDto,
  ReferenceItemCodeApeDto,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { ActivityValues, AdministrationValues } from "./types";
import { computeRightOptionProps } from "common/form/fields/connectedInput/utils";
import { units } from "../CompanyActivity";
import { Declarants, DeclarantType } from "../DeclarantIdentityList/types";
import uuid from "uuid";
import { Nullable } from "common/utils/types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

const getCityNameById = (
  cityRef: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[],
  cityId: string
) => {
  const city = findElementMatchingTemplate(
    { object: { uuid: cityId } },
    cityRef
  );
  return city && city.object ? city.object.nomCommune : null;
};

const getApeNameById = (
  apeRef: OptionPropsWithObject<ReferenceItemCodeApeDto>[],
  apeId: string
) => {
  const ape = findElementMatchingTemplate({ object: { uuid: apeId } }, apeRef);
  return ape && ape.object ? ape.object.codeApe : null;
};

//  helper functions
const computeFrenchCityWithReferential = (
  commune: string | null,
  referentiel: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[]
): OptionPropsWithObject<ReferenceItemGeoCodeInseeDto> | null => {
  return computeRightOptionProps(commune, referentiel) || null;
};

const computeActiviteInitialAPECodeValue = (
  codeAPE: string | null,
  referentiel: OptionPropsWithObject<ReferenceItemCodeApeDto>[]
): OptionPropsWithObject<ReferenceItemCodeApeDto> | null => {
  return computeRightOptionProps(codeAPE, referentiel) || null;
};

export const createDeclarantsDto = (values: DeclarantType[]) => {
  const returnedDto: DeclarantDto[] = values.map(declarant => {
    if (declarant.id === null) {
      throw Error("Should not happen");
    }

    const result: DeclarantDto = {
      id: declarant.id,
      prenom: declarant.firstName,
      nom: declarant.lastName,
      telephone: declarant.phone,
      email: declarant.email,
      role: declarant.declarantRoleInEnterprise,
    };
    return result;
  });
  return returnedDto;
};

export const createActivityDto = (values: Nullable<ActivityValues>) => {
  if (values.inspectionCode) {
    const returnedDto: ActiviteDto1819 = {
      codeInspection: values.inspectionCode,
      nomEtablissement: values.establishmentName,
      installationAdresse: values.installationAdress,
      installationCity:
        values.installationCity && values.installationCity.object.uuid,
      installationCodePostal: values.installationPostalCode,
      siret: values.SIRETNumber,
      codeNafID: values.NAFCode && values.NAFCode.object.uuid,
      abscisse: values.longitude,
      ordonnee: values.latitude,
      volumeProduction: values.productionVolume || null,
      unite: (values.unit && values.unit.label) || null,
      matiereProduite: values.producedMatter,
      nombreHeureDeFonctionnement: values.workDuration,
      employe: values.employee || null,
      website: values.website || null,
      informationsComplementaires: values.additionalInfo || null,
    };

    return returnedDto;
  }
  throw Error("Code inspection is missing.");
};

export const isCountryFrance = (country: string): boolean => {
  return country.toLowerCase() === "france";
};

export const createAdministratifDto = (
  values: Nullable<AdministrationValues>
) => {
  // Country
  const isFrance =
    values.country !== null && isCountryFrance(values.country.label);
  const countryId = isFrance
    ? "FRANCE"
    : values.country && values.country.object && values.country.object.uuid;

  // City
  let cityId: string | null = null;
  let cityString: string | null = null;
  if (typeof values.city === "string") {
    cityString = values.city;
  } else {
    cityId = values.city && values.city.object.uuid;
  }
  // France's communes should have an id. Other communes should be pure string.
  const commune = isFrance ? cityId : cityString;

  const returnedDto: AdministratifDto1819 = {
    raisonSociale: values.socialReason,
    nomUsuel: values.usualName,
    formeJuridique: values.legalStatus,
    societeMere: values.parentCompany,
    adresse: values.adress,
    codePostal: values.postalCode,
    commune: commune,
    pays: countryId,
    siren: values.SIRENNumber,
  };

  return returnedDto;
};

export const computeDeclarantInitialValues: (
  declarationDto: Declaration1919
) => Declarants = (declarationDto: Declaration1919) => {
  const returnedResult: Declarants = {
    declarants: [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        declarantRoleInEnterprise: "",
        id: uuid(),
      },
    ],
  };

  if (declarationDto.body.sections.infoGenerale.declarants.length !== 0) {
    returnedResult.declarants = declarationDto.body.sections.infoGenerale.declarants.map(
      declarant => {
        const result: DeclarantType = {
          id: declarant.id,
          firstName: declarant.prenom,
          lastName: declarant.nom,
          email: declarant.email,
          phone: declarant.telephone,
          declarantRoleInEnterprise: declarant.role,
        };
        return result;
      }
    );
  }

  return returnedResult;
};

export const computeActiviteInitialValues = (
  declarationDto: Declaration1919,
  inspectionCode: string,
  cityReferentiels: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[],
  apeCodeReferentiels: OptionPropsWithObject<ReferenceItemCodeApeDto>[]
) => {
  const cityName = getCityNameById(
    cityReferentiels,
    declarationDto.body.sections.infoGenerale.activite.installationCity || ""
  );

  const city = computeFrenchCityWithReferential(cityName, cityReferentiels);

  const NAFCodeName = getApeNameById(
    apeCodeReferentiels,
    declarationDto.body.sections.infoGenerale.activite.codeNafID || ""
  );

  const NAFCode = computeActiviteInitialAPECodeValue(
    NAFCodeName,
    apeCodeReferentiels
  );

  const returnedResult: Nullable<ActivityValues> = {
    inspectionCode: inspectionCode,
    establishmentName:
      declarationDto.body.sections.infoGenerale.activite.nomEtablissement,
    installationAdress:
      declarationDto.body.sections.infoGenerale.activite.installationAdresse,
    installationPostalCode:
      declarationDto.body.sections.infoGenerale.activite.installationCodePostal,
    installationCity: city,
    SIRETNumber: declarationDto.body.sections.infoGenerale.activite.siret,
    NAFCode: NAFCode,
    longitude: declarationDto.body.sections.infoGenerale.activite.abscisse,
    latitude: declarationDto.body.sections.infoGenerale.activite.ordonnee,
    productionVolume:
      declarationDto.body.sections.infoGenerale.activite.volumeProduction ||
      undefined,
    unit: computeRightOptionProps(
      declarationDto.body.sections.infoGenerale.activite.unite,
      units
    ),
    producedMatter:
      declarationDto.body.sections.infoGenerale.activite.matiereProduite,
    workDuration:
      declarationDto.body.sections.infoGenerale.activite
        .nombreHeureDeFonctionnement,
    employee:
      declarationDto.body.sections.infoGenerale.activite.employe || undefined,
    website:
      declarationDto.body.sections.infoGenerale.activite.website || undefined,
    additionalInfo:
      declarationDto.body.sections.infoGenerale.activite
        .informationsComplementaires || undefined,
  };
  return returnedResult;
};

export const computeAdminInitialValues = (
  declarationDto: Declaration1919,
  countryReferentiels: OptionPropsWithObject<ReferenceItemPaysDto | null>[],
  cityReferentiels: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[]
): Nullable<AdministrationValues> => {
  const administratif = declarationDto.body.sections.infoGenerale.administratif;

  // Country
  const isFrance =
    administratif.pays !== null && isCountryFrance(administratif.pays);
  let country = null;

  if (administratif.pays !== null) {
    country = isFrance
      ? // France has no id, should be found by its label
        findElementMatchingTemplate({ label: "FRANCE" }, countryReferentiels)
      : // Other countries should have an id
        findElementMatchingTemplate(
          { object: { uuid: administratif.pays } },
          countryReferentiels
        );
  }

  // City
  const commune = administratif.commune;
  let city = null;

  if (commune !== null) {
    city = isFrance
      ? // France's cities should be given as an id
        findElementMatchingTemplate(
          { object: { uuid: commune } },
          cityReferentiels
        )
      : // Cities from another country should be pure string
        commune;
  }

  const returnedResult: Nullable<AdministrationValues> = {
    socialReason: administratif.raisonSociale,
    usualName: administratif.nomUsuel,
    adress: administratif.adresse,
    postalCode: administratif.codePostal,
    parentCompany: administratif.societeMere,
    legalStatus: administratif.formeJuridique,
    city: city,
    country: country,
    SIRENNumber: administratif.siren,
  };
  return returnedResult;
};
