import * as Yup from "yup";
import { NumberSchema, ObjectSchema } from "yup";
import {
  commonArrayOfObjectFields,
  commonNullableStringFields,
  commonNumberFields,
  commonObjectFields,
  commonObjectFieldsNullable,
  commonPercentageFields,
  commonPercentageFieldsNullable,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveNumberFieldsNullable,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { CombustibleInModale } from "../utils/types";
import { ReferenceItemCombustibleDto } from "api/gen";

export const torchereSubPartActivated = (
  type: OptionPropsWithObject<ReferenceItemCombustibleDto, string> | null,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
): boolean => {
  return !!(
    type &&
    (type.object.codeCombustible === "309" ||
      type.object.codeCombustible === "310") &&
    (isTorchereBiogaz || isValorisationBiogaz)
  );
};

//TODO use the OptionPropsWithObject to know whether a subPart is activated
export const ch4MesureSubPartActivated = (
  type: OptionProps | null
): boolean => {
  return type?.label === "Mesure";
};

export const biogazMesureSubPartActivated = (
  type: OptionProps | null
): boolean => {
  return type?.label === "Mesure";
};

export const singleCombustibleOverwriteDependantFields = (
  combustible: CombustibleInModale,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
) => {
  if (
    !torchereSubPartActivated(
      combustible.type || null,
      isTorchereBiogaz,
      isValorisationBiogaz
    )
  ) {
    combustible.biogazDebit = null;
    combustible.biogazMesure = null;
    combustible.biogazFrequence = null;
    combustible.biogazDuree = null;
    combustible.ch4Teneur = null;
    combustible.ch4Mesure = null;
    combustible.ch4Frequence = null;
    combustible.quantiteMethaneOxyde = null;
  }
  if (!ch4MesureSubPartActivated(combustible.ch4Mesure)) {
    combustible.ch4Frequence = null;
  }
  if (!biogazMesureSubPartActivated(combustible.biogazMesure)) {
    combustible.biogazFrequence = null;
  }
};

export const singleCombustibleValidationSchema = (
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
) =>
  Yup.object().shape({
    type: commonObjectFields,
    nameInstallation: commonNullableStringFields,
    consommation: commonNumberFields,
    unite: commonObjectFields,
    appareils: commonArrayOfObjectFields,
    masse: commonStrictlyPositiveNumberFieldsNullable,
    eau: commonPercentageFieldsNullable,
    carbone: commonPercentageFieldsNullable,
    soufre: commonPercentageFieldsNullable,
    surCendres: Yup.object().nullable(),
    cendres: commonPercentageFieldsNullable,
    chlore: commonPercentageFieldsNullable,
    pciValeur: commonPositiveNumberFieldsNullable,
    pciUnite: commonObjectFieldsNullable,
    pciEcart: commonNullableStringFields,
    pciBrut: Yup.object().nullable(),
    pciProvenance: commonNullableStringFields,
    biogazFraction: commonPercentageFields,
    biogazEcart: commonNullableStringFields,
    biogazDebit: subFieldValidationScheme<
      NumberSchema,
      OptionPropsWithObject<ReferenceItemCombustibleDto, string>
    >(
      "type",
      type =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    biogazMesure: subFieldValidationScheme<
      ObjectSchema<any>,
      OptionPropsWithObject<ReferenceItemCombustibleDto, string>
    >(
      "type",
      type =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonObjectFields,
      Yup.object()
    ),
    biogazFrequence: commonPositiveNumberFieldsNullable,
    biogazDuree: subFieldValidationScheme<
      NumberSchema,
      OptionPropsWithObject<ReferenceItemCombustibleDto, string>
    >(
      "type",
      type =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Teneur: subFieldValidationScheme<
      NumberSchema,
      OptionPropsWithObject<ReferenceItemCombustibleDto, string>
    >(
      "type",
      type =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Mesure: subFieldValidationScheme<
      ObjectSchema<any>,
      OptionPropsWithObject<ReferenceItemCombustibleDto, string>
    >(
      "type",
      type =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonObjectFields,
      Yup.object()
    ),
    ch4Frequence: commonPositiveNumberFieldsNullable,
    quantiteMethaneOxyde: commonPositiveNumberFieldsNullable,
  });
