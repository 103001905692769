import { QuotasBlocEmissionsEmailsVerificateursDto20Now } from "api/gen";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

export const updateHandlerEmailsAddress = (
  declaration: Declaration2020,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration2020 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
