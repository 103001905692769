import { makeStyles } from "@material-ui/styles";
import React from "react";
import Row from "common/presentational/Row";
import { LINK_STYLE } from "theme";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { useElevageFileHandler1919 } from "../../../versionedElements/filesLoaderDisplayer1919/sectionFilesHooks1919";
import FilesLoader from "common/FilesLoader/FilesLoader";

interface BlocInformationsProps {
  shouldDisabledFields: boolean;
}

const useStyles = makeStyles({
  bloc: {
    marginBottom: "50px",
    marginTop: "50px",
  },
  informationsToDo: {
    marginTop: "10px",
  },
  informationsToDoElement: {
    marginLeft: "50px",
    "& a": LINK_STYLE,
  },
  filesPart: {
    marginTop: "50px",
  },
  uploadModuleButton: {
    marginLeft: "10%",
  },
});

const BlocInformations = ({
  shouldDisabledFields,
}: BlocInformationsProps): React.ReactElement => {
  const classes = useStyles();
  const openAlertModale = useAlertModale();
  const declaration = useDeclaration1919();
  const sectionFileHandler = useElevageFileHandler1919();

  return (
    <div className={classes.bloc}>
      <span>
        Pour remplir le bloc élevage, les étapes suivantes sont nécessaires :
      </span>

      <ul className={classes.informationsToDo}>
        <li className={classes.informationsToDoElement}>
          Télécharger le(s) module(s) de calcul Porcins et/ou Volailles
        </li>
        <li className={classes.informationsToDoElement}>Les compléter</li>
        <li className={classes.informationsToDoElement}>
          Déposer le(s) module(s) de calcul rempli(s) dans GEREP
        </li>
        <li className={classes.informationsToDoElement}>
          Reporter les valeurs d'émissions dans les tableaux ci-dessous
        </li>
      </ul>

      <div className={classes.filesPart}>
        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULES PORCINS (FORMAT EXCEL)"
            fileName="ModulePorcinsV3.10.xlsx"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE PORCINS (FORMAT OPEN OFFICE)"
            fileName="ModulePorcinsV3.10.ods"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULES VOLAILLES (FORMAT EXCEL)"
            fileName="ModuleVolaillesV3.7.xlsx"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE VOLAILLES (FORMAT OPEN OFFICE)"
            fileName="ModuleVolaillesV3.7.ods"
            isDisabled={shouldDisabledFields}
          />

          <FilesLoader
            declaration={declaration}
            text={"DÉPOSER MODULE"}
            filesPath={"body.sections.air.elevage.fichiers"}
            section={FileSectionEnum.ELEVAGE_FEUILLE_CALCUL}
            fileHandlers={sectionFileHandler}
            //
            isDisabled={shouldDisabledFields}
            //
            additionalClassnameButton={classes.uploadModuleButton}
            onReject={() =>
              openAlertModale("Le format du fichier uploadé est incorrect.")
            }
          />
        </Row>
      </div>
    </div>
  );
};

export default BlocInformations;
