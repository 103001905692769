import * as Yup from "yup";
import { commonPositiveNumberFields } from "common/declarant/formik/formikHelper";
import {
  ReceivedWasteFormData,
  ReceivedWasteImport,
  ReceivedWasteImportArrayWithMessage,
} from "./types";
import {
  DechetRecuDto1822,
  ReferenceItemDechetDto,
  TypeActiviteDto1819,
} from "api/gen/api";
import { WasteReferentiels } from "../types";
import { Errors } from "common/form/utils";
import { Nullable } from "common/utils/types";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import {
  findElementMatchingTemplate,
  normalizeLabel,
} from "common/utils/methods";

const computeIsOutOfWasteStatus = (userInput: string): boolean | null => {
  if (normalizeLabel(userInput) === "oui") {
    return true;
  }
  if (normalizeLabel(userInput) === "non") {
    return false;
  }

  return null;
};

export function filterCSVArray(
  arrayResults: string[][]
): ReceivedWasteImportArrayWithMessage {
  const results: Nullable<ReceivedWasteImport>[] = [];
  arrayResults.forEach(ele => {
    let hasData = false;
    for (let i = 0; i < 8; i++) {
      if (ele[i] !== undefined && ele[i] !== "") {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      return;
    }

    const wasteId = ele[0] !== undefined ? ele[0].replace("*", "") : null;

    const receivedQuantity =
      ele[5] !== undefined && ele[5] !== ""
        ? parseFloat(ele[5].replace(",", "."))
        : null;
    const treatedQuantity =
      ele[6] !== undefined && ele[6] !== ""
        ? parseFloat(ele[6].replace(",", "."))
        : null;

    let department: string | null = ele[2] || null;
    if (ele[2] && ele[2].length === 1) {
      department = `0${ele[2]}`;
    }

    const value: Nullable<ReceivedWasteImport> = {
      wasteId: wasteId,
      isOutOfWasteStatut: computeIsOutOfWasteStatus(ele[1]),
      departement: department,
      pays: ele[3] || null,
      notificationNumber: ele[4] || null,
      receivedQuantity: receivedQuantity,
      treatedQuantity: treatedQuantity,
      operation: ele[7] || null,
    };
    results.push(value);
  });
  return { filteredArray: results, errorMessage: null };
}

export const validationSchema = (
  isISDI: boolean,
  isISDND: boolean
): Yup.ObjectSchema<any> => {
  if (!!isISDI || !!isISDND) {
    return Yup.object().shape({
      remainingCapacity: commonPositiveNumberFields,
    });
  }
  return Yup.object().shape({
    remainingCapacity: Yup.number().nullable(),
  });
};

const findWasteInReferentiel = (
  waste: DechetRecuDto1822,
  referentiel: ReferenceItemDechetDto[]
) => {
  if (!waste.codeID) {
    return undefined;
  }

  return referentiel.find(element => element.uuid === waste.codeID);
};

export const generateReceivedWasteErrors = (
  waste: DechetRecuDto1822,
  referentiel: WasteReferentiels,
  typeActivite: TypeActiviteDto1819,
  receptionDechetsData: ReceivedWasteFormData | null
): Errors<DechetRecuDto1822> => {
  const errors: Errors<DechetRecuDto1822> = {};
  const itemWaste = findWasteInReferentiel(waste, referentiel.polluants);
  if (itemWaste === undefined) {
    errors.codeID = requiredMessage;
  } else if (receptionDechetsData) {
    const isIsdi: boolean =
      typeActivite.isdi !== null ? typeActivite.isdi : false;
    const aCasierAmiante: boolean =
      receptionDechetsData.doesFacilityHaveAsbestosCases !== null
        ? receptionDechetsData.doesFacilityHaveAsbestosCases
        : false;

    if (
      !isDechetAllowed(aCasierAmiante, isIsdi, !!typeActivite.isdnd, itemWaste)
    ) {
      errors.codeID =
        "Ce déchet n'est pas autorisé dans la liste des déchets reçus avec ces valeurs pour ISDND et ISDI.";
    }
  }
  if (waste.sortiDuStatutDeDechet === null) {
    errors.sortiDuStatutDeDechet = requiredMessage;
  }
  if (
    waste.operationTraitement === null ||
    !findElementMatchingTemplate(
      { code: waste.operationTraitement },
      referentiel.operationOrValorisationForReception
    )
  ) {
    errors.operationTraitement = requiredMessage;
  }
  if (waste.quantiteAdmise === null) {
    errors.quantiteAdmise = requiredMessage;
  }
  if (waste.quantiteTraitee === null) {
    errors.quantiteTraitee = requiredMessage;
  }

  if (waste.estTraiteDansUnAutrePays === null) {
    errors.estTraiteDansUnAutrePays = requiredMessage;
  } else if (!waste.estTraiteDansUnAutrePays) {
    if (!waste.departementID) {
      errors.departementID = requiredMessage;
    } else if (
      !findElementMatchingTemplate(
        { uuid: waste.departementID },
        referentiel.departements
      )
    ) {
      errors.departementID = requiredMessage;
    }
  } else {
    if (!waste.paysID) {
      errors.paysID = requiredMessage;
    } else {
      const optionCountry = findElementMatchingTemplate(
        {
          uuid: waste.paysID,
        },
        referentiel.pays
      );
      if (!optionCountry) {
        errors.paysID = requiredMessage;
      }
    }
  }

  return errors;
};

export const isDechetAllowed = (
  aCasierAmiante: boolean,
  isISDIChecked: boolean,
  isISDNDChecked: boolean,
  itemDechet: ReferenceItemDechetDto
): boolean => {
  const isNothing: boolean = !isISDNDChecked && !isISDIChecked;
  const isdndAndAllowed: boolean =
    isISDNDChecked && itemDechet.avecAmiante && aCasierAmiante;
  const isdiAndAllowed: boolean = isISDIChecked && itemDechet.dechetInerte;
  const isNotDangereux = !itemDechet.dangereux;

  return isNothing || isdndAndAllowed || isdiAndAllowed || isNotDangereux;
};
