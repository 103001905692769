import {
  QuotasBlocEmissionsDto1819,
  QuotasBlocVerificationDto1819,
  QuotasDto1819,
} from "api/gen";
import _ from "lodash";
import { Declaration1919 } from "../../../versionedElements/declarationHooks1919";

const applyNimChangeToFlux = (
  paramBlocEmissions: QuotasBlocEmissionsDto1819,
  elderNimNumber: string,
  newerNimNumber: string
): QuotasBlocEmissionsDto1819 => {
  const blocEmissions = _.cloneDeep(paramBlocEmissions);
  blocEmissions.flux = blocEmissions.flux.map(singleFlux => {
    if (singleFlux.nim === elderNimNumber) {
      // we change the emissionsCalculees that use the flux we are going to change
      blocEmissions.emissionsCalculees = blocEmissions.emissionsCalculees.map(
        singleEmission => {
          if (singleEmission.flux === singleFlux.nom) {
            singleEmission.nim = newerNimNumber;
          }
          return singleEmission;
        }
      );
      singleFlux.nim = newerNimNumber;
    }
    return singleFlux;
  });

  return blocEmissions;
};

const applyNimChangeToPointsMesures = (
  paramBlocEmissions: QuotasBlocEmissionsDto1819,
  elderNimNumber: string,
  newerNimNumber: string
): QuotasBlocEmissionsDto1819 => {
  const blocEmissions = _.cloneDeep(paramBlocEmissions);
  blocEmissions.pointsMesure = blocEmissions.pointsMesure.map(singlePoint => {
    if (singlePoint.nim === elderNimNumber) {
      // we change the emissionsMesurees that use the measurePoint we are going to change
      blocEmissions.emissionsMesurees = blocEmissions.emissionsMesurees.map(
        singleEmission => {
          if (singleEmission.pointMesure === singlePoint.intitule) {
            singleEmission.nim = newerNimNumber;
          }
          return singleEmission;
        }
      );
      singlePoint.nim = newerNimNumber;
    }
    return singlePoint;
  });

  return blocEmissions;
};

const applyNimChangeToMethodesAlternative = (
  paramBlocEmissions: QuotasBlocEmissionsDto1819,
  elderNimNumber: string,
  newerNimNumber: string
): QuotasBlocEmissionsDto1819 => {
  const blocEmissions = _.cloneDeep(paramBlocEmissions);

  blocEmissions.emissionsMethodeAlternative.forEach(singleEmission => {
    if (singleEmission.nim === elderNimNumber) {
      singleEmission.nim = newerNimNumber;
    }
  });

  return blocEmissions;
};

const applyNimChangeToBlocVerif = (
  paramBlocVerif: QuotasBlocVerificationDto1819,
  elderNimNumber: string,
  newerNimNumber: string
): QuotasBlocVerificationDto1819 => {
  const blocVerif = _.cloneDeep(paramBlocVerif);

  blocVerif.verifications.forEach(singleBlocVerif => {
    if (singleBlocVerif.nim === elderNimNumber) {
      singleBlocVerif.nim = newerNimNumber;
    }
    return singleBlocVerif;
  });

  return blocVerif;
};

const applyNimChange = (
  quotasBlocParam: QuotasDto1819,
  elderNimNumber: string,
  newerNimNumber: string
): QuotasDto1819 => {
  const usedQuotasBloc = _.cloneDeep(quotasBlocParam);
  usedQuotasBloc.blocEmissions = applyNimChangeToFlux(
    usedQuotasBloc.blocEmissions,
    elderNimNumber,
    newerNimNumber
  );
  usedQuotasBloc.blocEmissions = applyNimChangeToPointsMesures(
    usedQuotasBloc.blocEmissions,
    elderNimNumber,
    newerNimNumber
  );
  usedQuotasBloc.blocEmissions = applyNimChangeToMethodesAlternative(
    usedQuotasBloc.blocEmissions,
    elderNimNumber,
    newerNimNumber
  );
  usedQuotasBloc.blocVerification = applyNimChangeToBlocVerif(
    usedQuotasBloc.blocVerification,
    elderNimNumber,
    newerNimNumber
  );
  // TODO GEREP-1469 maybe cleaning should be made through useEffect instead of manually here ? Unless a reducer was actually what we want ?

  return usedQuotasBloc;
};

const applyNimDeletion = (
  quotasBloc: QuotasDto1819,
  nimNumberDeleted: string
): QuotasDto1819 => {
  const usedQuotasBloc = _.cloneDeep(quotasBloc);

  usedQuotasBloc.blocEmissions.flux = usedQuotasBloc.blocEmissions.flux.filter(
    singleFlux => {
      if (singleFlux.nim === nimNumberDeleted) {
        // we delete the emissionsCalculees and fluxSoumisADerogation that used the flux we are going to delete
        usedQuotasBloc.blocEmissions.emissionsCalculees = usedQuotasBloc.blocEmissions.emissionsCalculees.filter(
          singleEmission => singleEmission.flux !== singleFlux.nom
        );
        usedQuotasBloc.blocEmissions.fluxSoumisADerogation = usedQuotasBloc.blocEmissions.fluxSoumisADerogation.filter(
          singleFluxSoumisDerogation =>
            singleFluxSoumisDerogation !== singleFlux.nom
        );
        return false;
      }
      return true;
    }
  );

  usedQuotasBloc.blocEmissions.pointsMesure = usedQuotasBloc.blocEmissions.pointsMesure.filter(
    singlePoint => {
      if (singlePoint.nim === nimNumberDeleted) {
        // we delete the emissionsMesurees that used the measurePoint we are going to delete
        usedQuotasBloc.blocEmissions.emissionsMesurees = usedQuotasBloc.blocEmissions.emissionsMesurees.filter(
          singleEmission => singleEmission.pointMesure !== singlePoint.intitule
        );
        return false;
      }
      return true;
    }
  );

  usedQuotasBloc.blocEmissions.emissionsMethodeAlternative = usedQuotasBloc.blocEmissions.emissionsMethodeAlternative.filter(
    singleEmission => singleEmission.nim !== nimNumberDeleted
  );

  usedQuotasBloc.blocVerification.verifications = usedQuotasBloc.blocVerification.verifications.filter(
    singleVerif => singleVerif.nim !== nimNumberDeleted
  );

  // TODO GEREP-1469 maybe cleaning should be made through useEffect instead of manually here ? Unless a reducer was actually what we want ?

  return usedQuotasBloc;
};

interface NewModifToApply {
  elderNim: string;
  newerNim: string;
}

export const reconcileDeclarationEmission = (
  elderDeclaration: Declaration1919,
  newerDeclaration: Declaration1919
): Declaration1919 => {
  // for EVERY deleted num nim, change things in newerDecl
  // for EVERY modified num nim, change things in newerDecl

  //to avoid a bug when we change the nim from an installation, we need an array of nim we'll need to RE modify after all
  //modification were made to the nim
  const arrayRemodification: NewModifToApply[] = [];

  elderDeclaration.body.sections.quotas.blocInstallations.installations.forEach(
    elderInstallation => {
      if (elderInstallation.nim) {
        const changedInstallation = newerDeclaration.body.sections.quotas.blocInstallations.installations.find(
          newInstallation => newInstallation.id === elderInstallation.id
        );
        if (!changedInstallation) {
          // we cannot find an installation with same id than the old one, ergo, the old installation was deleted
          newerDeclaration.body.sections.quotas = applyNimDeletion(
            newerDeclaration.body.sections.quotas,
            elderInstallation.nim
          );
        } else if (changedInstallation.nim !== elderInstallation.nim) {
          if (!changedInstallation.nim) {
            throw Error("the modale should ONLY let you add data with a nim.");
          }
          // we can find an installation with same id than the old one, and its nim changed, so the linked data using that nim HAVE to be updated
          // To avoid RE editing declaration with a nim that was just changed (think inverting nim between declarations),
          // we use a tricky tricks that adds a custom string in the newerNim, and we'll then RE change the nim from the nim with custom string to the real nim
          const sneakyString = "prefixNimForDebug";
          const newerNimNumber = `${sneakyString}${changedInstallation.nim}`;
          arrayRemodification.push({
            elderNim: newerNimNumber,
            newerNim: changedInstallation.nim,
          });

          newerDeclaration.body.sections.quotas = applyNimChange(
            newerDeclaration.body.sections.quotas,
            elderInstallation.nim,
            newerNimNumber
          );
        }
      }
    }
  );

  arrayRemodification.forEach(modifToDo => {
    newerDeclaration.body.sections.quotas = applyNimChange(
      newerDeclaration.body.sections.quotas,
      modifToDo.elderNim,
      modifToDo.newerNim
    );
  });

  return newerDeclaration;
};
