import React from "react";
import { DummyChoiceSelectProps } from "../types/dummyTypes";
import { OptionProps } from "../types/basicTypes";
import SelectAdapter from "libAdapter/ReactSelect/SelectAdapter";

function DummyChoiceSelect<T extends OptionProps, IsMulti extends boolean>({
  disabled,
  options,
  additionalClassName,
  id,
  value,
  isMulti,
  placeholder,
  error,
  name, // because for whatever reason IF we pass a name AND a isMulti to the Select component, its style is destroyed. So we put the name out of the remaining props to be given to the Select.
  hideValue,
  onChange,
  enableReset = true,
  ...props
}: DummyChoiceSelectProps<T, IsMulti>): React.ReactElement {
  return (
    <SelectAdapter<T, IsMulti>
      value={hideValue ? null : value}
      error={error}
      isDisabled={disabled}
      options={options}
      isClearable={enableReset}
      id={id}
      isMulti={isMulti}
      placeholder={placeholder}
      openMenuOnFocus={true}
      onChange={onChange}
      {...props}
      additionalClassName={additionalClassName}
    />
  );
}

export default DummyChoiceSelect;
