import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";
import React from "react";
import { useDeclarationState2020 } from "../../../versionedElements/declarationHooks2020";

const useStyles = makeStyles({
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
});

const DeclarationStatut = () => {
  const classes = useStyles();

  const declarationState = useDeclarationState2020();

  return (
    <>
      <span className={classes.color}>Statut de la déclaration : </span>
      <p className={classes.informationDeclaration}>
        {declarationState.stateMessage}
      </p>
    </>
  );
};

export default DeclarationStatut;
