import React from "react";
import makeStyles from "@material-ui/styles/makeStyles";
import classNames from "classnames";
import Row from "../Row";
import arrowDown from "icons/arrow-down.svg";
import arrowUp from "icons/arrow-up.svg";

const useStyles = makeStyles({
  header: {
    justifyContent: "center",
    // padding: "4px",
    width: "90%",
  },
  title: {
    alignSelf: "center",
  },
  sortIcon: {
    width: "15px",
    marginLeft: "4px",
  },
  transparent: {
    opacity: 0.15,
  },
  smallCol: {
    width: "100px",
  },
});

export interface SortableTableHeaderViewContent<T> {
  field: T | null;
  name: string;
  additionalClassName?: string;
}

export interface SortableTableHeaderViewProps<T> {
  columns: SortableTableHeaderViewContent<T>[];
  sortField: T;
  isSortReversed: boolean | null;
  setSortFieldAndIsReversed: (
    sortField: T,
    isSortReversed: boolean | null
  ) => void;
}

function SortableTableHeaderView<T>({
  columns,
  sortField,
  isSortReversed,
  setSortFieldAndIsReversed,
}: SortableTableHeaderViewProps<T>): React.ReactElement[] {
  const classes = useStyles();

  const buildSingleHeader = ({
    field,
    name,
    additionalClassName,
  }: SortableTableHeaderViewContent<T>) => {
    return (
      <Row
        additionalClassname={classNames(classes.header, additionalClassName)}
      >
        <span className={classes.title}>{name}</span>
        {field && (
          <input
            type="image"
            src={sortField === field && isSortReversed ? arrowUp : arrowDown}
            alt={sortField === field && isSortReversed ? "Λ" : "V"}
            onClick={() => {
              setSortFieldAndIsReversed(
                field,
                sortField === field ? !isSortReversed : false
              );
            }}
            className={classNames(classes.sortIcon, {
              [classes.transparent]: sortField !== field,
            })}
          />
        )}
      </Row>
    );
  };

  return [
    ...columns.map(content => {
      return buildSingleHeader(content);
    }),
    buildSingleHeader({
      field: null,
      name: "Actions",
      additionalClassName: classes.smallCol,
    }),
  ];
}

export default SortableTableHeaderView;
