import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "./CommonSpace/AppPage/PageHeaderContext";
import { PINK } from "theme";
import { CampaignDto } from "api/gen";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "100px",
  },
  title: {
    fontSize: "2rem",
    color: PINK,
  },
});

interface CampaignDisabledProps {
  campaign: CampaignDto | null;
}

const CampaignDisabled = ({ campaign }: CampaignDisabledProps) => {
  const classes = useStyles();
  const paths = usePathToDeclarationDashboard(null, null);

  paths.push({ label: "Campagne inactive", to: window.location.pathname });

  const headerData: PageHeaderProps = {
    arianeString: paths,
    title: "",
  };

  usePageHeaderSetterOnce(headerData);
  return (
    <div className={classes.content}>
      <h1 className={classes.title}>
        {campaign === null
          ? "La campagne demandée n'a pas été trouvée."
          : `La saisie pour la campagne ${campaign.annee} démarre le ${new Date(
              campaign.start
            ).toLocaleDateString()}.`}
      </h1>
    </div>
  );
};

export default CampaignDisabled;
