import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BORDER_GREY,
  PINK,
  SECTION_FONT_SIZE,
  FOUR_COLUMNS_CARD_GRID,
} from "theme";
import Row from "common/presentational/Row";
import ProgressBar from "common/progressBar";
import Button from "common/button";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { roundPathProgression } from "./utils";
import { PATH_ROOT } from "common/path/path18Now";
import MainForm from "./MainForm";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useAccuseTransmissionPdfExport,
  useDeclarationExcelExport,
  useDeclarationPdfExport,
} from "../../../../DeclarationApiContext/utils/exportHooks";
import { useDeclarationHelpers1919 } from "../../versionedElements/declarationHooks1919";
import AllDashboardCards from "./AllDashboardCards";
import { useUserData } from "../../../../../../Authenticator/UserData";
import LecteurDashboardCards from "./LecteurDashboardCards";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";

const useStyles = makeStyles({
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
  },
  title: {
    color: PINK,
    fontSize: SECTION_FONT_SIZE,
    textTransform: "uppercase",
    fontWeight: "normal",
  },
  containerProgressBar: {
    width: "70%",
    height: "100%",
    margin: "auto",
  },
  mediaQueries: FOUR_COLUMNS_CARD_GRID,
});

interface DeclarationSummaryProps {
  declarationAnnee: string;
  declarationEtablissement: string;
}

const DeclarationSummary = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationSummaryProps): React.ReactElement => {
  const classes = useStyles();
  const declarationApi = useDeclarationHelpers1919();
  const { declarationController } = useAuthenticatedApi();
  const excelExportDeclaration = useDeclarationExcelExport();
  const pdfExportDeclaration = useDeclarationPdfExport();
  const [
    isDownloadingAccuseTransmission,
    triggerDownloadAccuseTransmission,
  ] = useSpinnerState();
  const [isExcelExportLoading, triggerExcelExportLoading] = useSpinnerState();
  const [isPdfExportLoading, triggerPdfExportLoading] = useSpinnerState();
  const anonymousExportAccuseTransmission = (
    annee: number,
    etablissement: string
  ) =>
    declarationController.exportAccuseTransmissionRegistreGerepAsPdfUsingGET(
      annee,
      etablissement
    );

  const exportAccuseTransmissionPdf = useAccuseTransmissionPdfExport(
    "registre_gerep_",
    anonymousExportAccuseTransmission
  );

  const isDeclarationDone = declarationApi.getPathProgress(PATH_ROOT) === 100;
  const { isLecteur } = useUserData();
  return (
    <div className={classes.container}>
      <Row
        additionalStyle={{
          height: "30px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <h3 className={classes.title}>État d'avancement</h3>
        <div className={classNames(classes.containerProgressBar)}>
          <ProgressBar
            percentageProgress={roundPathProgression(
              declarationApi.getPathProgress(PATH_ROOT)
            )}
            isValidated={declarationApi.isPathValidatedInDeclaration(PATH_ROOT)}
          />
        </div>
      </Row>
      <MainForm />
      <Row
        additionalStyle={{
          display: "grid",
          gridGap: "20px",
          height: "auto",
        }}
        additionalClassname={classes.mediaQueries}
      >
        {isLecteur ? (
          <LecteurDashboardCards
            declarationAnnee={declarationAnnee}
            declarationEtablissement={declarationEtablissement}
            declarationApi={declarationApi}
          />
        ) : (
          <AllDashboardCards
            declarationAnnee={declarationAnnee}
            declarationEtablissement={declarationEtablissement}
            declarationApi={declarationApi}
          />
        )}
      </Row>
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text={
            <>
              <FontAwesomeIcon icon="download" /> Télécharger l'accusé de
              transmission
            </>
          }
          isReversed
          onClick={() =>
            triggerDownloadAccuseTransmission(
              exportAccuseTransmissionPdf(
                Number(declarationAnnee),
                declarationEtablissement
              )
            )
          }
          isDisabled={!isDeclarationDone || isLecteur}
          isSpinnerVisible={isDownloadingAccuseTransmission}
        />
        <Button
          text={
            <>
              <FontAwesomeIcon icon="download" /> Télécharger la déclaration
              Excel
            </>
          }
          onClick={() =>
            triggerExcelExportLoading(
              excelExportDeclaration(
                Number(declarationAnnee),
                declarationEtablissement
              )
            )
          }
          isReversed
          isSpinnerVisible={isExcelExportLoading}
          isDisabled={isLecteur}
        />
        <Button
          text={
            <>
              <FontAwesomeIcon icon="download" /> Télécharger la déclaration Pdf
            </>
          }
          onClick={() =>
            triggerPdfExportLoading(
              pdfExportDeclaration(
                Number(declarationAnnee),
                declarationEtablissement
              )
            )
          }
          isReversed
          isSpinnerVisible={isPdfExportLoading}
          isDisabled={isLecteur}
        />
      </Row>
    </div>
  );
};
export default DeclarationSummary;
