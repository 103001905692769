import {
  AerDonneeSurveillanceDtoNiveauAppliqueEnum,
  ClassementFluxDtoClassementEnum,
  DonneeActiviteDtoNiveauAppliqueEnum,
  DonneeActiviteDtoNiveauRequisEnum,
  DonneeSurveillanceDtoFrequenceAnalyseEnum,
  DonneeSurveillanceDtoNiveauAppliqueEnum,
  DonneeSurveillanceDtoNiveauRequisEnum,
  MethodeMesureEmissionDto22NowNiveauAppliqueEnum,
  PointMesureDtoNiveauAppliqueEnum,
  PointMesureDtoNiveauRequisEnum,
  SurveillanceEmissionDtoNiveauAppliqueEnum,
  SurveillanceEmissionDtoNiveauRequisEnum,
} from "api/gen";

export function formatNiveauFlux(
  niveauFlux:
    | AerDonneeSurveillanceDtoNiveauAppliqueEnum
    | DonneeActiviteDtoNiveauAppliqueEnum
    | DonneeActiviteDtoNiveauRequisEnum
    | DonneeSurveillanceDtoNiveauAppliqueEnum
    | DonneeSurveillanceDtoNiveauRequisEnum
    | MethodeMesureEmissionDto22NowNiveauAppliqueEnum
    | PointMesureDtoNiveauAppliqueEnum
    | PointMesureDtoNiveauRequisEnum
    | SurveillanceEmissionDtoNiveauAppliqueEnum
    | SurveillanceEmissionDtoNiveauRequisEnum
    | null
): string | null {
  switch (niveauFlux) {
    case null:
      return null;
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.ONE:
    case DonneeActiviteDtoNiveauAppliqueEnum.ONE:
    case DonneeActiviteDtoNiveauRequisEnum.ONE:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.ONE:
    case DonneeSurveillanceDtoNiveauRequisEnum.ONE:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.ONE:
    case PointMesureDtoNiveauAppliqueEnum.ONE:
    case PointMesureDtoNiveauRequisEnum.ONE:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.ONE:
    case SurveillanceEmissionDtoNiveauRequisEnum.ONE:
      return "1";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.TWO:
    case DonneeActiviteDtoNiveauAppliqueEnum.TWO:
    case DonneeActiviteDtoNiveauRequisEnum.TWO:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.TWO:
    case DonneeSurveillanceDtoNiveauRequisEnum.TWO:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.TWO:
    case PointMesureDtoNiveauAppliqueEnum.TWO:
    case PointMesureDtoNiveauRequisEnum.TWO:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.TWO:
    case SurveillanceEmissionDtoNiveauRequisEnum.TWO:
      return "2";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.TWO_A:
    case DonneeActiviteDtoNiveauAppliqueEnum.TWO_A:
    case DonneeActiviteDtoNiveauRequisEnum.TWO_A:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.TWO_A:
    case DonneeSurveillanceDtoNiveauRequisEnum.TWO_A:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.TWO_A:
    case PointMesureDtoNiveauAppliqueEnum.TWO_A:
    case PointMesureDtoNiveauRequisEnum.TWO_A:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.TWO_A:
    case SurveillanceEmissionDtoNiveauRequisEnum.TWO_A:
      return "2a";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.TWO_B:
    case DonneeActiviteDtoNiveauAppliqueEnum.TWO_B:
    case DonneeActiviteDtoNiveauRequisEnum.TWO_B:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.TWO_B:
    case DonneeSurveillanceDtoNiveauRequisEnum.TWO_B:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.TWO_B:
    case PointMesureDtoNiveauAppliqueEnum.TWO_B:
    case PointMesureDtoNiveauRequisEnum.TWO_B:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.TWO_B:
    case SurveillanceEmissionDtoNiveauRequisEnum.TWO_B:
      return "2b";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.TWO_A_TWO_B:
    case DonneeActiviteDtoNiveauAppliqueEnum.TWO_A_TWO_B:
    case DonneeActiviteDtoNiveauRequisEnum.TWO_A_TWO_B:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.TWO_A_TWO_B:
    case DonneeSurveillanceDtoNiveauRequisEnum.TWO_A_TWO_B:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.TWO_A_TWO_B:
    case PointMesureDtoNiveauAppliqueEnum.TWO_A_TWO_B:
    case PointMesureDtoNiveauRequisEnum.TWO_A_TWO_B:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.TWO_A_TWO_B:
    case SurveillanceEmissionDtoNiveauRequisEnum.TWO_A_TWO_B:
      return "2a/2b";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.THREE:
    case DonneeActiviteDtoNiveauAppliqueEnum.THREE:
    case DonneeActiviteDtoNiveauRequisEnum.THREE:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.THREE:
    case DonneeSurveillanceDtoNiveauRequisEnum.THREE:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.THREE:
    case PointMesureDtoNiveauAppliqueEnum.THREE:
    case PointMesureDtoNiveauRequisEnum.THREE:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.THREE:
    case SurveillanceEmissionDtoNiveauRequisEnum.THREE:
      return "3";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.FOUR:
    case DonneeActiviteDtoNiveauAppliqueEnum.FOUR:
    case DonneeActiviteDtoNiveauRequisEnum.FOUR:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.FOUR:
    case DonneeSurveillanceDtoNiveauRequisEnum.FOUR:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.FOUR:
    case PointMesureDtoNiveauAppliqueEnum.FOUR:
    case PointMesureDtoNiveauRequisEnum.FOUR:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.FOUR:
    case SurveillanceEmissionDtoNiveauRequisEnum.FOUR:
      return "4";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.S_O:
    case DonneeActiviteDtoNiveauAppliqueEnum.S_O:
    case DonneeActiviteDtoNiveauRequisEnum.S_O:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.S_O:
    case DonneeSurveillanceDtoNiveauRequisEnum.S_O:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.S_O:
    case PointMesureDtoNiveauAppliqueEnum.S_O:
    case PointMesureDtoNiveauRequisEnum.S_O:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.S_O:
    case SurveillanceEmissionDtoNiveauRequisEnum.S_O:
      return "s.o";
    case AerDonneeSurveillanceDtoNiveauAppliqueEnum.NO_LEVEL:
    case DonneeActiviteDtoNiveauAppliqueEnum.NO_LEVEL:
    case DonneeActiviteDtoNiveauRequisEnum.NO_LEVEL:
    case DonneeSurveillanceDtoNiveauAppliqueEnum.NO_LEVEL:
    case DonneeSurveillanceDtoNiveauRequisEnum.NO_LEVEL:
    case MethodeMesureEmissionDto22NowNiveauAppliqueEnum.NO_LEVEL:
    case PointMesureDtoNiveauAppliqueEnum.NO_LEVEL:
    case PointMesureDtoNiveauRequisEnum.NO_LEVEL:
    case SurveillanceEmissionDtoNiveauAppliqueEnum.NO_LEVEL:
    case SurveillanceEmissionDtoNiveauRequisEnum.NO_LEVEL:
      return "aucun niveau";
    default:
      return "";
  }
}

export function formatFrequenceAnalyse(
  frequenceAnalyse: DonneeSurveillanceDtoFrequenceAnalyseEnum | null
): string | null {
  switch (frequenceAnalyse) {
    case null:
      return null;
    case DonneeSurveillanceDtoFrequenceAnalyseEnum.HEBDOMADAIRE:
      return "toutes les semaines";
    case DonneeSurveillanceDtoFrequenceAnalyseEnum.S_O:
      return "s.o";
    default:
      return frequenceAnalyse.toLowerCase();
  }
}

export function formatClassementFlux(
  classement: ClassementFluxDtoClassementEnum | null
) {
  if (classement == null) {
    return null;
  }
  switch (classement) {
    case ClassementFluxDtoClassementEnum.FLUX_MAJEUR:
      return "Flux majeur";
    case ClassementFluxDtoClassementEnum.MINEURE:
      return "Mineure";
    case ClassementFluxDtoClassementEnum.DE_MINIMIS:
      return "De minimis";
    default:
      return "";
  }
}
