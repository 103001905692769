import {
  AirCombustionAppareilDtoNatureEnum,
  AirCombustionAppareilDtoTypeFoyerEnum,
} from "api/gen";

export const arrayNatureLabel: {
  [key in AirCombustionAppareilDtoNatureEnum]: string;
} = {
  CHAUDIERE: "Chaudière",
  TURBINE_GAZ: "Turbine gaz",
  FOUR: "Four",
  MOTEUR_GAZ: "Moteur gaz",
  MOTEUR_DIESEL: "Moteur diesel",
  AUTRE: "Autre",
};

export const arrayNature: AirCombustionAppareilDtoNatureEnum[] = Object.keys(
  arrayNatureLabel
) as AirCombustionAppareilDtoNatureEnum[];

export const arrayTypeFoyerLabel: {
  [key in AirCombustionAppareilDtoTypeFoyerEnum]: string;
} = {
  CHARBON:
    "Charbon pulvérisé (chauffe frontale ou tangentielle, cendres fondues)",
  GRILLE: "Foyer(s) à grille (grille classique, chaînes de projection)",
  LIT_CHAUD: "Lit(s) fluidisé(s) chaud(s)",
  LIST_DENSE: "Lit(s) fluidisé(s) dense(s) ou circulant(s)",
  AUTRE: "Autre(s)",
};

export const arrayTypeFoyer: AirCombustionAppareilDtoTypeFoyerEnum[] = Object.keys(
  arrayTypeFoyerLabel
) as AirCombustionAppareilDtoTypeFoyerEnum[];
