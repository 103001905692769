import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import { AlcModeleViergeModale } from "./AlcModeleViergeModale";
import { FILE_BUTTONS_STYLE } from "theme";

const useStyles = makeStyles({
  fileButtons: { ...FILE_BUTTONS_STYLE, marginBottom: "0" },
  link: {
    textDecoration: "none",
  },
});

interface AlcModeleViergeButtonProps {
  lastYearModeleAvailable: boolean;
}

export const AlcModeleViergeButton = ({
  lastYearModeleAvailable,
}: AlcModeleViergeButtonProps): React.ReactElement => {
  const classes = useStyles();
  const [
    isAlcModeleViergeModaleOpen,
    setIsAlcModeleViergeModaleOpen,
  ] = useState<boolean>(false);

  const button = (
    <Button
      text={
        <>
          TÉLÉCHARGER UN MODÈLE VIERGE DE RAPPORT ALC <br />
          (version du 30/01/2023) <br />
          <FontAwesomeIcon icon="download" />
        </>
      }
      onClick={() => {
        if (lastYearModeleAvailable) {
          setIsAlcModeleViergeModaleOpen(true);
        }
      }}
      isReversed
      additionalClassname={classes.fileButtons}
    />
  );

  return (
    <>
      {lastYearModeleAvailable ? (
        <>
          {button}
          {isAlcModeleViergeModaleOpen && (
            <AlcModeleViergeModale
              onRequestClose={() => setIsAlcModeleViergeModaleOpen(false)}
            />
          )}
        </>
      ) : (
        <a
          href={`${process.env.PUBLIC_URL}/download/ALC_template_COM_fr_2023-01-30.xls`}
          className={classes.link}
          download
        >
          {button}
        </a>
      )}
    </>
  );
};
