import * as Yup from "yup";
import {
  commonObjectFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { FlowDeclarationProps, MeasureDeclarationProps } from "./types";
import {
  quotasFluxNamesMessage,
  quotasPointMesureNamesMessage,
} from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  flowDeclarations: Yup.array().of(
    Yup.object().shape({
      name: commonStringFields,
      nim: commonObjectFields.nullable(),
    })
  ),
  measureDeclarations: Yup.array().of(
    Yup.object().shape({
      name: commonStringFields,
      nim: commonObjectFields.nullable(),
    })
  ),
});

export const additionalValidation = (
  measurePoints: MeasureDeclarationProps[],
  flows: FlowDeclarationProps[]
) => {
  const errors: { [k: string]: string } = {};

  // checking for unique names for measure points and flows

  // this one is handled in additionalFieldValidation to
  // display the error without a submit click because it makes a button disabled

  measurePoints.forEach((point, i) =>
    measurePoints.forEach((otherPoint, j) => {
      if (otherPoint.name === point.name && otherPoint.id !== point.id) {
        errors[`measureDeclarations.${i}.name`] = quotasPointMesureNamesMessage;
        errors[`measureDeclarations.${j}.name`] = quotasPointMesureNamesMessage;
      }
    })
  );

  flows.forEach((flow, i) =>
    flows.forEach((otherFlow, j) => {
      if (otherFlow.name === flow.name && otherFlow.id !== flow.id) {
        errors[`flowDeclarations.${i}.name`] = quotasFluxNamesMessage;
        errors[`flowDeclarations.${j}.name`] = quotasFluxNamesMessage;
      }
    })
  );

  return errors;
};
