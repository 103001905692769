import React from "react";
import { wasOtherOrganismSelected } from "../utils";
import {
  useBooleanCheckBoxGenerator,
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { LEFT_WITHDRAW_STYLE, SUBTITLE_STYLE } from "theme";
import { SubBlocEmpoussierageProps } from "./props";

const useStyles = makeStyles({
  subTitle: { ...SUBTITLE_STYLE, textTransform: "none" },
  ...LEFT_WITHDRAW_STYLE,
});

interface CommonPoussiereProps extends SubBlocEmpoussierageProps {
  subTitle: string;
  fieldNamePrefix:
    | "poussiereAlveolaireQuartzTridimite"
    | "poussiereAlveolaireTotale";
  parenthesisText: string;
  longInputClassName: string;
}

const CommonPoussiere = ({
  commonProps,
  formikProps,
  subTitle,
  fieldNamePrefix,
  parenthesisText,
  longInputClassName,
  shouldDisableFields,
  organismeMeasuringEmpoussierageList,
  commentBasePath,
}: CommonPoussiereProps): React.ReactElement => {
  const classes = useStyles();

  const CheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(
    commonProps,
    longInputClassName
  );
  const NumberField = useNumberFieldGenerator(commonProps, longInputClassName);
  const TextField = useTextFieldGenerator(commonProps, longInputClassName);

  const values = formikProps.values[fieldNamePrefix];

  if (!values) {
    throw Error(
      `This component shouldn't be called if ${fieldNamePrefix} isn't filled in values`
    );
  }

  return (
    <>
      <h4 className={classes.subTitle}>{subTitle}</h4>

      <CheckboxField
        name={`${fieldNamePrefix}.hasShownNotWeakRiskOnly`}
        disabled={shouldDisableFields}
        label={`L'évaluation des risques révèle un risque non faible (${parenthesisText})`}
        commentPath={`${commentBasePath}/evaluationReveleRisqueNonFaible`}
      />

      {((fieldNamePrefix === "poussiereAlveolaireQuartzTridimite" &&
        formikProps.values.poussiereAlveolaireQuartzTridimite &&
        formikProps.values.poussiereAlveolaireQuartzTridimite
          .hasShownNotWeakRiskOnly) ||
        (fieldNamePrefix === "poussiereAlveolaireTotale" &&
          formikProps.values.poussiereAlveolaireTotale &&
          formikProps.values.poussiereAlveolaireTotale
            .hasShownNotWeakRiskOnly)) && (
        <div className={classes.withdrawLeft}>
          <ChoiceSelectField
            name={`${fieldNamePrefix}.accreditedOrganismIntervening`}
            label="Organisme accrédité intervenant"
            isMulti={false}
            options={organismeMeasuringEmpoussierageList}
            disabled={shouldDisableFields}
            commentPath={`${commentBasePath}/organismeAccrediteID`}
          />
          {wasOtherOrganismSelected(
            values.accreditedOrganismIntervening || null
          ) && (
            <TextField
              name={`${fieldNamePrefix}.precision`}
              label="Préciser *"
              disabled={shouldDisableFields}
              commentPath={`${commentBasePath}/precisionSiAutre`}
            />
          )}
          <NumberField
            name={`${fieldNamePrefix}.nbTotalPrelevement`}
            label="Nombre total de prélèvements"
            unit=""
            disabled={shouldDisableFields}
            commentPath={`${commentBasePath}/nbTotalPrelevement`}
          />
        </div>
      )}
    </>
  );
};

export default CommonPoussiere;
