import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageRecyclageDto,
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const familiesProduction = (
  referential: ReferenceFamilleUsageProductionDto
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>[] =>
  referential.referenceItemFamilleUsageProductionDtoList.map((item, i) => ({
    value: i,
    label: item.libelleCourt,
    object: item,
  }));

export const subFamiliesProduction = (
  family: OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>[] =>
  family.object.referenceItemFamilleUsageDtos.map((subFamily, i) => ({
    value: i,
    label: subFamily.libelleCourt,
    object: subFamily,
  }));

export const subFamiliesProductionLvl2 = (
  subFamily: OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>[] =>
  subFamily.object.referenceItemFamilleUsageDtos.map((subFamilyLvl2, i) => ({
    value: i,
    label: subFamilyLvl2.libelleCourt,
    object: subFamilyLvl2,
  }));

export const familiesRecycling = (
  referential: ReferenceFamilleUsageRecyclageDto
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto>[] =>
  referential.referenceItemFamilleUsageRecyclageDtoList.map((item, i) => ({
    value: i,
    label: item.libelle,
    object: item,
  }));

export const subFamiliesRecycling = (
  family: OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto>
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto>[] =>
  family.object.referenceItemFamilleUsageDtos.map((subFamily, i) => ({
    value: i,
    label: subFamily.libelle,
    object: subFamily,
  }));

export const subFamiliesRecyclingLvl2 = (
  subFamily: OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto>
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto>[] =>
  subFamily.object.referenceItemFamilleUsageDtos.map((subFamilyLvl2, i) => ({
    value: i,
    label: subFamilyLvl2.libelle,
    object: subFamilyLvl2,
  }));
