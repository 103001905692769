import React, { useEffect, useState } from "react";
import { PINK } from "theme";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import arrowLeft from "icons/arrow-left.svg";
import arrowLeftDouble from "icons/arrow-left-double.svg";
import arrowRight from "icons/arrow-right.svg";
import arrowRightDouble from "icons/arrow-right-double.svg";

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  page: {
    margin: "0 10px",
    width: "15px",
    textSize: "15px",
    color: PINK,
  },
  arrow: {
    margin: "0 10px",
    width: "20px",
  },
  selectable: {
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  selected: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
  hidden: {
    visibility: "hidden",
  },
  visible: {
    visibility: "visible",
  },
});

/**
 * Pages are indexed from 0 to nbPagesTotal - 1
 * However, they will be displayed for user as (currentPage + 1) (so virtually indexed from 1)
 */
export interface PageSelectionProps {
  currentPage: number;
  setPage: (newPage: number) => void;
  nbPagesTotal: number;
  nbPagesToDisplay: number;
}

const PageSelection = ({
  currentPage,
  setPage,
  nbPagesTotal,
  nbPagesToDisplay,
}: PageSelectionProps) => {
  const classes = useStyles();

  const [pagesToDisplay, setPagesToDisplay] = useState<number[]>([]);
  const [morePagesUnder, setMorePagesUnder] = useState<boolean>(false);
  const [morePagesOver, setMorePagesOver] = useState<boolean>(false);

  const createSelection = (page: number) => {
    return (
      <div
        key={page}
        onClick={() => {
          if (page !== currentPage) {
            setPage(page);
          }
        }}
        className={classNames(
          {
            [classes.selectable]: page !== currentPage,
            [classes.selected]: page === currentPage,
          },
          classes.page
        )}
      >
        {page + 1}
      </div>
    );
  };

  useEffect(() => {
    const computePagesToDisplay = () => {
      // Try to use as much pages as possible arround current page
      const firstPage = Math.max(
        0,
        Math.min(
          currentPage - Math.floor(nbPagesToDisplay / 2),
          nbPagesTotal - nbPagesToDisplay
        )
      );
      const lastPage = Math.min(
        nbPagesTotal - 1,
        firstPage + nbPagesToDisplay - 1
      );
      const pages = [];
      for (let page = firstPage; page <= lastPage; page++) {
        pages.push(page);
      }

      // If there are more pages under first page in list, this first page is removed (will be replaced by "...")
      if (pages.length > 0 && firstPage > 0) {
        setMorePagesUnder(true);
        pages.shift();
      } else {
        setMorePagesUnder(false);
      }

      // If there are more pages over last page in list, this last page is removed (will be replaced by "...")
      if (pages.length > 0 && lastPage < nbPagesTotal - 1) {
        setMorePagesOver(true);
        pages.pop();
      } else {
        setMorePagesOver(false);
      }

      setPagesToDisplay(pages);
    };

    computePagesToDisplay();
  }, [currentPage, nbPagesTotal, nbPagesToDisplay]);

  return (
    <main className={classes.main}>
      <input
        className={classNames(
          classes.arrow,
          { [classes.hidden]: currentPage === 0 },
          { [classes.visible]: currentPage !== 0 }
        )}
        type="image"
        src={arrowLeftDouble}
        alt="Première page"
        onClick={() => setPage(0)}
      />
      <input
        className={classNames(
          classes.arrow,
          { [classes.hidden]: currentPage === 0 },
          { [classes.visible]: currentPage !== 0 }
        )}
        type="image"
        src={arrowLeft}
        alt="Page précédente"
        onClick={() => setPage(Math.max(0, currentPage - 1))}
      />
      {morePagesUnder && <div className={classes.page}>...</div>}
      {pagesToDisplay.map(page => createSelection(page))}
      {morePagesOver && <div className={classes.page}>...</div>}
      <input
        className={classNames(
          classes.arrow,
          { [classes.hidden]: currentPage === nbPagesTotal - 1 },
          { [classes.visible]: currentPage !== nbPagesTotal - 1 }
        )}
        style={{
          visibility: currentPage === nbPagesTotal - 1 ? "hidden" : "visible",
        }}
        type="image"
        src={arrowRight}
        alt="Page suivante"
        onClick={() => setPage(Math.min(nbPagesTotal - 1, currentPage + 1))}
      />
      <input
        className={classNames(
          classes.arrow,
          { [classes.hidden]: currentPage === nbPagesTotal - 1 },
          { [classes.visible]: currentPage !== nbPagesTotal - 1 }
        )}
        type="image"
        src={arrowRightDouble}
        alt="Dernière page"
        onClick={() => setPage(nbPagesTotal - 1)}
      />
    </main>
  );
};

export default PageSelection;
