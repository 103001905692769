import { arrayUnite, isUnitePCS } from "./selectPossibleValues";
import {
  AirCombustionCombustibleDto20NowUniteEnum,
  ReferenceItemCombustibleDto,
} from "api/gen";

const GAZ_NATUREL_CODE = "301";

export const isCombustibleGazNaturel = (
  combustible: ReferenceItemCombustibleDto | null
): boolean => {
  if (combustible === null) {
    return false;
  }
  return combustible.codeCombustible === GAZ_NATUREL_CODE;
};

export const getNonPCSUnits = (): AirCombustionCombustibleDto20NowUniteEnum[] =>
  arrayUnite.filter(u => !isUnitePCS(u));
