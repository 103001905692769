import { CampaignDto } from "api/gen";

export const isCampaignActive = (campaign: CampaignDto): boolean => {
  const today = new Date().getTime();
  const startDate = new Date(campaign.start).getTime();
  const endDate = new Date(campaign.endGlobal).getTime();

  if (startDate <= today && today <= endDate) {
    return true;
  } else {
    return false;
  }
};
