import * as Yup from "yup";
import {
  commonPositiveNumberFields,
  commonStringFields,
  commonStringFieldsNoPipeAllowed,
  MAX_NB_HOURS_YEAR,
} from "common/declarant/formik/formikHelper";
import { parseToUndefinedIfNull } from "common/form/utils";
import { combustionInstallationTooMuchTimeMessage } from "common/declarant/formik/formikMessages";

export const singleInstallationValidationSchema = Yup.object().shape({
  nom: commonStringFieldsNoPipeAllowed,
  heures: commonPositiveNumberFields.max(
    MAX_NB_HOURS_YEAR,
    combustionInstallationTooMuchTimeMessage
  ),
  volume: commonPositiveNumberFields,
  unite: commonStringFields,
  produit: Yup.string().transform(parseToUndefinedIfNull),
});
