import Row from "common/presentational/Row";
import {
  FORCED,
  generateTransitionWarningMessage,
  IN_REVIEW,
  RECALLED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import Button from "common/button";
import { isEveryRequiredSectionValidated } from "pages/CompanySpace/from19/to19/Dashboard/DeclarationSummary/utils";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";
import {
  useDeclaration2020,
  useDeclarationHelpers2020,
  useDeclarationState2020,
  useDeclarationStateHandlers2020,
} from "../../../versionedElements/declarationHooks2020";
import { useConfirmationModale } from "common/modale/hooks";
import SeparateSubmissionWarning from "common/messages/SeparateSubmissionWarning";
import { WorkflowTarget } from "common/utils/types";

const useStyles = makeStyles({
  button: {
    margin: "0 22px 0 0",
  },
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
  warningTransmission: {
    textAlign: "justify",
    marginRight: "15px",
  },
});

const DeclarantGlobalActionButtons = (): React.ReactElement => {
  const classes = useStyles();

  const declarationState = useDeclarationState2020();
  const openConfirmationModale = useConfirmationModale();
  const { cancelSubmit, submit } = useDeclarationStateHandlers2020();
  const declarationApi = useDeclarationHelpers2020();
  const declaration = useDeclaration2020();
  const typeActiviteQuotas = declaration.body.typeActivite;

  return (
    <Row additionalStyle={{ justifyContent: "flex-end" }}>
      {(declarationState.isDeclarationStateContaining([SUBMITTED]) ||
        declarationState.isDeclarationStateContaining([VALIDATED])) && (
        <Button
          text={"Demander modification"}
          isReversed
          additionalClassname={classes.button}
          additionalStyle={{ fontSize: "18px" }}
          onClick={() => {
            openConfirmationModale(
              generateTransitionWarningMessage(IN_REVIEW),
              () => {
                cancelSubmit([WorkflowTarget.GLOBAL]);
              }
            );
          }}
        />
      )}
      {declarationState.isDeclarationStateContaining([RECALLED]) && (
        <p className={classes.color}>
          En attente de l'acceptation de modification par l'inspecteur
        </p>
      )}
      {declarationState.isDeclarationStateContaining([FORCED]) && (
        <p className={classes.color}>
          Un inspecteur a pris la main sur votre déclaration
        </p>
      )}
      {!declarationState.isDeclarationStateContaining([
        SUBMITTED,
        RECALLED,
        VALIDATED,
        FORCED,
      ]) && (
        <>
          {typeActiviteQuotas.estSousQuotaCo2 && (
            <p className={classes.warningTransmission}>
              Attention, ce bouton ne permet pas de transmettre les déclarations
              des niveaux d'activité et des émissions quotas qui doivent être
              transmises par l'intermédiaire des boutons "Transmettre à
              l'inspection" disponibles dans les blocs Niveaux d'activité et
              Émissions du pavé Quotas.
            </p>
          )}
          <Button
            text={"TRANSMETTRE"}
            additionalClassname={classes.button}
            onClick={() => {
              openConfirmationModale(
                <div>
                  {generateTransitionWarningMessage(SUBMITTED)}
                  {typeActiviteQuotas.estSousQuotaCo2 && (
                    <SeparateSubmissionWarning />
                  )}
                </div>,
                () => {
                  submit([WorkflowTarget.GLOBAL]);
                }
              );
            }}
            isDisabled={!isEveryRequiredSectionValidated(declarationApi)}
          />
        </>
      )}
    </Row>
  );
};

export default DeclarantGlobalActionButtons;
