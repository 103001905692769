import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Button from "common/button";
import Row from "common/presentational/Row";
import { RouteIdProps } from "common/declarant/type";
import { DASHBOARD_BACKGROUND_GREY, PINK } from "theme";
import writing from "icons/writing.svg";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import {
  FORCED,
  generateDeletionWarningMessage,
  generateTransitionWarningMessage,
  IN_REVIEW,
  RECALLED,
  STARTED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import {
  useAlertModale,
  useConfirmationModale,
  useConfirmationModaleWithReason,
} from "common/modale/hooks";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { CampaignDtoStateGlobalEnum } from "api/gen";
import { useCurrentDeclarationCampaign } from "../../../../../CommonSpace/CampaignContext";
import { useUserData } from "Authenticator/UserData";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageDeleteEtablissement } from "common/backErrors/errorMessages";
import { Redirect } from "react-router-dom";
import DeclarationSummary from "../DeclarationSummary";
import { isEveryRequiredSectionValidated } from "../DeclarationSummary/utils";
import {
  useDeclaration2020,
  useDeclarationHelpers2020,
  useDeclarationState2020,
  useDeclarationStateHandlers2020,
} from "../../versionedElements/declarationHooks2020";
import { WorkflowTarget } from "common/utils/types";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";

const useStyles = makeStyles({
  button: {
    margin: "0 22px 0 0",
  },
  flex: {
    flex: 1,
  },
  statutContainer: {
    marginRight: "auto",
  },
  imgIcon: {
    width: "18px",
    verticalAlign: "text-bottom",
  },
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
  paddingLeft: {
    paddingLeft: "5px",
  },
});

const DashboardInspecteur = ({ match }: RouteIdProps): React.ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();
  const openConfirmationModaleWithReason = useConfirmationModaleWithReason();
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const declaration = useDeclaration2020();
  const {
    cancelTakeover,
    takeover,
    review,
    approve,
  } = useDeclarationStateHandlers2020();

  const declarationApi = useDeclarationHelpers2020();

  const { isAdmin, isSuperAdmin } = useUserData();
  const { etablissementController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const deleteEtablissement = async (etablissement: string) => {
    try {
      await etablissementController.deleteEtablissementUsingDELETE(
        etablissement
      );
      setShouldRedirect(true);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageDeleteEtablissement));
      return false;
    }
  };

  const [isTakeOverLoading, triggerIsTakeOverLoading] = useSpinnerState();
  const [isCancelLoading, triggerIsCancelLoading] = useSpinnerState();
  const [isDeleteLoading, triggerIsDeleteLoading] = useSpinnerState();

  const declarationAnnee = match.params.annee;
  const currentCampaign = useCurrentDeclarationCampaign();
  const isGlobalCampaignActive =
    currentCampaign.stateGlobal !== CampaignDtoStateGlobalEnum.ENDED;
  const declarationEtablissement = match.params.etablissement;
  const declarationStateComment =
    declaration.body.workflowStatus.general.message;
  const declarationState = useDeclarationState2020();

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };

  usePageHeaderSetterOnce(headerData);

  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateGlobal
  );

  if (shouldRedirect) {
    return <Redirect to={"/inspecteur/declarations"} />;
  }

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />
      <Row additionalStyle={{ justifyContent: "flex-end" }} height={"auto"}>
        <div className={classes.statutContainer}>
          <span className={classes.color}>Statut de la déclaration : </span>
          <p className={classes.informationDeclaration}>
            {declarationState.stateMessage}
          </p>
        </div>
        {isSuperAdmin && (
          <Button
            additionalClassname={classes.button}
            additionalStyle={{
              backgroundColor: DASHBOARD_BACKGROUND_GREY,
              color: "black",
              marginLeft: "20px",
            }}
            onClick={() =>
              openConfirmationModale(generateDeletionWarningMessage(), () =>
                triggerIsDeleteLoading(
                  deleteEtablissement(declaration.etablissementCode)
                )
              )
            }
            text="Effacer l'etablissement"
            isSpinnerVisible={isDeleteLoading}
          />
        )}
        {declarationState.isDeclarationStateContaining([FORCED]) &&
          isGlobalCampaignActive && (
            <Button
              additionalClassname={classes.button}
              additionalStyle={{
                backgroundColor: DASHBOARD_BACKGROUND_GREY,
                color: "black",
                marginLeft: "20px",
              }}
              onClick={() =>
                openConfirmationModale(
                  generateTransitionWarningMessage(STARTED),
                  () =>
                    triggerIsCancelLoading(
                      cancelTakeover([WorkflowTarget.GLOBAL])
                    )
                )
              }
              text={
                <>
                  <img src={writing} alt={""} className={classes.imgIcon} />
                  <span className={classes.paddingLeft}>
                    Annuler déclaration d'office
                  </span>
                </>
              }
              isSpinnerVisible={isCancelLoading}
            />
          )}

        {!declarationState.isDeclarationStateContaining([FORCED]) &&
          (isAdmin || isGlobalCampaignActive) && (
            <Button
              additionalClassname={classes.button}
              additionalStyle={{
                backgroundColor: DASHBOARD_BACKGROUND_GREY,
                color: "black",
                marginLeft: "20px",
              }}
              onClick={() =>
                openConfirmationModale(
                  generateTransitionWarningMessage(FORCED),
                  () =>
                    triggerIsTakeOverLoading(takeover([WorkflowTarget.GLOBAL]))
                )
              }
              text={
                <>
                  <img src={writing} alt={""} className={classes.imgIcon} />
                  <span className={classes.paddingLeft}>
                    Déclaration d'office
                  </span>
                </>
              }
              isSpinnerVisible={isTakeOverLoading}
            />
          )}
      </Row>

      <Row height={"20px"} />
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.GLOBAL}
        isInReviewState={declarationState.isDeclarationStateContaining([
          "IN_REVIEW",
        ])}
        comment={declarationStateComment}
      />

      <Row height={"20px"} />

      <DeclarationSummary
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />

      <Row height={"20px"} />

      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <div className={classes.flex} />
        {declarationState.isDeclarationStateContaining([RECALLED]) &&
          isGlobalCampaignActive && (
            <Button
              text={"Accepter la demande de modification"}
              isReversed
              additionalClassname={classes.button}
              onClick={() => {
                openConfirmationModaleWithReason(
                  generateTransitionWarningMessage(IN_REVIEW),
                  "Votre message",
                  message => {
                    review([WorkflowTarget.GLOBAL], message ? message : "");
                  }
                );
              }}
            />
          )}

        {declarationState.isDeclarationStateContaining([SUBMITTED]) &&
          isGlobalCampaignActive && (
            <Button
              text={"Mettre en révision"}
              isReversed
              additionalClassname={classes.button}
              onClick={() => {
                openConfirmationModaleWithReason(
                  generateTransitionWarningMessage(IN_REVIEW),
                  "Votre message",
                  message => {
                    review([WorkflowTarget.GLOBAL], message ? message : "");
                  }
                );
              }}
            />
          )}

        {declarationState.isDeclarationStateContaining([SUBMITTED, FORCED]) && (
          <Button
            text={"VALIDER"}
            additionalClassname={classes.button}
            onClick={() => {
              openConfirmationModale(
                generateTransitionWarningMessage(VALIDATED),
                () => {
                  approve([WorkflowTarget.GLOBAL]);
                }
              );
            }}
            isDisabled={!isEveryRequiredSectionValidated(declarationApi)}
          />
        )}

        {declarationState.isDeclarationStateContaining([VALIDATED]) &&
          isGlobalCampaignActive && (
            <Button
              text={"INVALIDER"}
              additionalClassname={classes.button}
              onClick={() => {
                openConfirmationModaleWithReason(
                  generateTransitionWarningMessage(IN_REVIEW),
                  "Votre message",
                  message => {
                    review([WorkflowTarget.GLOBAL], message ? message : "");
                  }
                );
              }}
            />
          )}
      </Row>

      <Row height={"20px"} />
    </>
  );
};

export default DashboardInspecteur;
