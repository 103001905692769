import {
  CampaignDtoStateAllocationsEnum,
  CampaignDtoStateEmissionsEnum,
  CampaignDtoStateGlobalEnum,
} from "api/gen";
import { WorkflowTarget } from "../utils/types";

export const generateEndedCampaignMessage = (
  declarationAnnee: string,
  stateCampaign:
    | CampaignDtoStateGlobalEnum
    | CampaignDtoStateAllocationsEnum
    | CampaignDtoStateEmissionsEnum,
  workflowTarget?: WorkflowTarget
): string => {
  if (stateCampaign === CampaignDtoStateAllocationsEnum.ENDED_FOR_DECLARANTS) {
    return `La période de saisie déclarant relative à la campagne est désormais terminée. Seuls les
  inspecteurs et les déclarants dont la déclaration a été mise en révision ont le droit d'effectuer des
  actions sur les déclarations.`;
  }
  if (
    stateCampaign === CampaignDtoStateAllocationsEnum.ENDED &&
    workflowTarget === WorkflowTarget.QUOTA_ALLOCATIONS
  ) {
    return "La campagne est désormais terminée pour les niveaux d’activité, les déclarations ne sont accessibles qu'en lecture seule";
  }
  if (
    stateCampaign === CampaignDtoStateAllocationsEnum.ENDED &&
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
  ) {
    return "La campagne est désormais terminée pour les émissions, les déclarations ne sont accessibles qu'en lecture seule.";
  }
  if (stateCampaign === CampaignDtoStateAllocationsEnum.ENDED) {
    return `La campagne est désormais terminée, les déclarations ne sont accessibles qu'en lecture seule.`;
  }

  return "";
};
