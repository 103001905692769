import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { InstallationInArray, InstallationInModale } from "../types";
import FormSingleInstallation from "./FormSingleInstallation";
import { ReferenceItemActiviteQuotaDto } from "api/gen";
import { addOrModifyArray } from "common/declarant/array/utils";
import { CallbackSetter } from "common/utils/types";

interface InstallationModaleProps {
  isOpen: boolean;
  installationInModal: InstallationInArray | null;
  installationsInArray: InstallationInArray[];
  setInstallationsInArray: CallbackSetter<InstallationInArray[]>;
  closeModal: () => void;
  possibleActivitesValues: ReferenceItemActiviteQuotaDto[];
}

const InstallationModale = ({
  isOpen,
  installationInModal,
  installationsInArray,
  setInstallationsInArray,
  closeModal,
  possibleActivitesValues,
}: InstallationModaleProps): React.ReactElement => {
  const onSubmit = (newInstallation: InstallationInModale) => {
    addOrModifyArray(
      setInstallationsInArray,
      installationInModal && installationInModal.data.id,
      newInstallation
    );
    closeModal();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'installations."
      onRequestClose={closeModal}
    >
      <FormSingleInstallation
        onClose={closeModal}
        onSubmit={onSubmit}
        initialInstallation={
          installationInModal !== null
            ? installationInModal.data
            : {
                nim: null,
                nomInstallation: null,
                entreeSEQE: null,
                sortieSEQE: null,
                codesActivite: [],
                isNotAllocationGratuit: false,
                isNouvelEntrant: false,
              }
        }
        isEdit={installationInModal !== null}
        installationsInArray={installationsInArray}
        possibleActivitesValues={possibleActivitesValues}
      />
    </CustomModal>
  );
};

export default InstallationModale;
