import { AuthJwtDto, DeclarationControllerApi } from "api/gen";
import * as HttpStatus from "http-status-codes";
import { refreshMonAIOTToken } from "Authenticator/utils";
import { AnyDeclarationDto } from "./types";

interface GetDeclarationResponse {
  declaration: AnyDeclarationDto | null;
  jwt: AuthJwtDto | null;
  status: number | null;
}
export const getDeclaration = async (
  declarationController: DeclarationControllerApi,
  annee: number,
  establishment: string,
  authJwt: AuthJwtDto,
  isRecoverAvailable: boolean = true
): Promise<GetDeclarationResponse> => {
  try {
    // by definition, AnyDeclarationDto MUST contain every class that extends AbstractDeclarationDto
    const declarationDto: AnyDeclarationDto = await declarationController.findDeclarationByIdUsingPOST(
      annee,
      { monAiotToken: authJwt.monAIOTJWT },
      establishment
    );
    return {
      declaration: declarationDto,
      jwt: authJwt,
      status: null,
    };
  } catch (excp) {
    if (
      isRecoverAvailable &&
      excp instanceof Response &&
      excp.status === HttpStatus.UNAUTHORIZED
    ) {
      const newJwt = await refreshMonAIOTToken(authJwt);
      if (newJwt === null) {
        return { declaration: null, jwt: null, status: excp.status };
      }

      return getDeclaration(
        declarationController,
        annee,
        establishment,
        newJwt,
        false
      );
    }

    return { declaration: null, jwt: null, status: excp.status };
  }
};

export const throwIfWrongYear = (
  declarationDto: AnyDeclarationDto,
  minYear?: number,
  maxYear?: number
): void => {
  const declarationYear = declarationDto.annee;
  if (
    (minYear !== undefined && minYear > declarationYear) ||
    (maxYear !== undefined && maxYear < declarationYear)
  ) {
    throw new Error(
      `trying to use a dto of year ${declarationYear} when expecting a dto between minimum ${minYear} and maximum ${maxYear}`
    );
  }
};
