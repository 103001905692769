import React from "react";
import {
  FilterFormValues,
  lessThanValidation,
  moreThanValidation,
} from "../types";
import Chip from "common/presentational/Chip";
import {
  computeDepartementLabel,
  computeRegionLabel,
} from "./helpers/locations";
import { computeStateLabel } from "./helpers/declarationStates";
import { getTimeRightFormat } from "../../../../common/utils/DateUtils";

interface FilterChip {
  key: string;
  text: string;
  onRemove: () => void;
}

interface FilterListProps {
  filters: FilterFormValues;
  setFilters: (filters: FilterFormValues) => void;
  additionalClassname?: string;
}

const FilterList = ({
  filters,
  setFilters,
  additionalClassname,
}: FilterListProps): React.ReactElement => {
  const filterChips: FilterChip[] = [];

  // region
  if (filters.region !== null) {
    filterChips.push({
      key: "region",
      text: `Région : ${computeRegionLabel(filters.region)}`,
      onRemove: () => setFilters({ ...filters, region: null }),
    });
  }
  // departement
  if (filters.departement !== null) {
    filterChips.push({
      key: "departement",
      text: `Département : ${computeDepartementLabel(filters.departement)}`,
      onRemove: () => setFilters({ ...filters, departement: null }),
    });
  }
  // declarationState
  if (filters.declarationGlobalState !== null) {
    filterChips.push({
      key: "declarationState",
      text: `État : ${computeStateLabel(filters.declarationGlobalState)}`,
      onRemove: () => setFilters({ ...filters, declarationGlobalState: null }),
    });
  }
  // quotas emissions state
  if (filters.quotasEmissionsState !== null) {
    filterChips.push({
      key: "quotasEmissionsState",
      text: `État - Quotas émissions: ${computeStateLabel(
        filters.quotasEmissionsState
      )}`,
      onRemove: () => setFilters({ ...filters, quotasEmissionsState: null }),
    });
  }
  // quotas allocations state
  if (filters.quotasAllocationsState !== null) {
    filterChips.push({
      key: "quotasAllocationsState",
      text: `État - Quotas niveaux d'activité: ${computeStateLabel(
        filters.quotasAllocationsState
      )}`,
      onRemove: () => setFilters({ ...filters, quotasAllocationsState: null }),
    });
  }
  // declarationYear
  if (filters.annees !== null && filters.annees.length !== 0) {
    filterChips.push({
      key: "declarationYear",
      text: `Année(s) : ${filters.annees.map(
        yearProps => " " + yearProps.toString()
      )}`,
      onRemove: () => setFilters({ ...filters, annees: null }),
    });
  }
  // declarationValidationPercent + moreThanValidationPercent
  if (
    filters.declarationValidationPercent !== null &&
    filters.moreOrLessThanValidationPercent !== null
  ) {
    filterChips.push({
      key: "declarationValidationPercent",
      text: `${
        filters.moreOrLessThanValidationPercent === moreThanValidation
          ? ">"
          : "<"
      } ${filters.declarationValidationPercent} %`,
      onRemove: () =>
        setFilters({
          ...filters,
          declarationValidationPercent: null,
          moreOrLessThanValidationPercent: lessThanValidation,
        }),
    });
  }
  // lastModificationTime + beforeLastModification
  if (
    filters.lastModificationTimeDeclaring !== null &&
    filters.beforeLastModificationDeclaring !== null
  ) {
    filterChips.push({
      key: "lastModificationTime",
      text: `${
        filters.beforeLastModificationDeclaring ? "Avant" : "Après"
      } le ${getTimeRightFormat(filters.lastModificationTimeDeclaring)}`,
      onRemove: () =>
        setFilters({
          ...filters,
          lastModificationTimeDeclaring: null,
          beforeLastModificationDeclaring: false,
        }),
    });
  }
  // carriere
  if (filters.carriere) {
    filterChips.push({
      key: "carriere",
      text: "Carrière",
      onRemove: () => setFilters({ ...filters, carriere: false }),
    });
  }
  // elevage
  if (filters.elevage) {
    filterChips.push({
      key: "elevage",
      text: "Élevage",
      onRemove: () => setFilters({ ...filters, elevage: false }),
    });
  }
  // eprtr
  if (filters.eprtr) {
    filterChips.push({
      key: "eprtr",
      text: "E-PRTR",
      onRemove: () => setFilters({ ...filters, eprtr: false }),
    });
  }
  // quotas emissions
  if (filters.quotasEmissions) {
    filterChips.push({
      key: "quotasEmissions",
      text: "Quotas - Emissions",
      onRemove: () => setFilters({ ...filters, quotasEmissions: false }),
    });
  }
  // quotas niveaux d'activité
  if (filters.quotasAllocations) {
    filterChips.push({
      key: "quotasAllocations",
      text: "Quotas - Niveaux d’activité",
      onRemove: () => setFilters({ ...filters, quotasAllocations: false }),
    });
  }
  // quotas exclusions, incinérateur
  if (filters.quotasWithAnyExclusions) {
    filterChips.push({
      key: "quotasWithAnyExclusions",
      text: "Quotas - Exclusions, incinérateur",
      onRemove: () =>
        setFilters({ ...filters, quotasWithAnyExclusions: false }),
    });
  }
  // consoOfSolvant
  if (filters.consoOfSolvant) {
    filterChips.push({
      key: "consoOfSolvant",
      text: "Consommation solvant",
      onRemove: () =>
        setFilters({
          ...filters,
          consoOfSolvant: false,
        }),
    });
  }
  //  isISDI
  if (filters.isdi) {
    filterChips.push({
      key: "isdi",
      text: "ISDI",
      onRemove: () =>
        setFilters({
          ...filters,
          isdi: false,
        }),
    });
  }
  //  isISDND
  if (filters.isdnd) {
    filterChips.push({
      key: "isdnd",
      text: "ISDND",
      onRemove: () =>
        setFilters({
          ...filters,
          isdnd: false,
        }),
    });
  }

  return (
    <div className={additionalClassname}>
      {filterChips.map(chip => (
        <Chip key={chip.key} onRemove={chip.onRemove}>
          {chip.text}
        </Chip>
      ))}
    </div>
  );
};

export default FilterList;
