import React, { useState } from "react";
import { PageHeaderContext, PageHeaderProps } from "./PageHeaderContext";
import Header from "common/Header";
import { Route, Switch } from "react-router-dom";
import CompanySpace from "pages/CompanySpace";
import Home from "pages/CommonSpace/Home";
import DeclarationsDashboard from "pages/InspectorSpace/DeclarationsDashboard/index";
import makeStyles from "@material-ui/styles/makeStyles";
import { CompanyManagement } from "pages/InspectorSpace/CompanyManagement";
import PublicPortal from "pages/PublicPortal";
import { useUserData } from "Authenticator/UserData";
import { ActiveOrLastCampaignContextProvider } from "../CampaignContext";
import Page404 from "pages/errors/errorPages/Page404";
import Maintenance from "pages/Maintenance";
import {
  DeclarationPageHeaderContext,
  DeclarationPageHeaderProps,
} from "./DeclarationPageHeaderContext";
import ReferentialsContextProvider from "../ReferentialsContext";
import ReferentialsManagement from "pages/AdminSpace/ReferentialsManagement";
import CampaignsManagement from "pages/AdminSpace/CampaignsManagement";
import Banner from "common/Header/Banner";
import SectionTitle from "common/presentational/SectionTitle";
import DeclarationRedirect from "pages/CommonSpace/AppPage/Redirect/DeclarationRedirect";
import DeclarationsDashboardContext from "./DeclarationsDashboardContext";
import VerificateurDashboard from "pages/VerificateurSpace/VerificateurDashboard";
import ExportDownload from "pages/ExportDownload/ExportDownload";
import NoDeclaration from "../../errors/errorPages/NoDeclaration";

const IS_MAINTENANCE = process.env.REACT_APP_IS_MAINTENANCE === "1";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
});

const AppPage = (): React.ReactElement => {
  const classes = useStyles();
  const userData = useUserData();

  const [
    pageHeaderProps,
    setPageHeaderProps,
  ] = useState<PageHeaderProps | null>(null);
  const [
    declarationPageHeaderProps,
    setDeclarationPageHeaderProps,
  ] = useState<DeclarationPageHeaderProps | null>(null);

  return (
    <ReferentialsContextProvider>
      <ActiveOrLastCampaignContextProvider>
        <DeclarationsDashboardContext>
          <PageHeaderContext.Provider
            value={{ headerInfo: pageHeaderProps, setter: setPageHeaderProps }}
          >
            <DeclarationPageHeaderContext.Provider
              value={{
                headerInfo: declarationPageHeaderProps,
                setter: setDeclarationPageHeaderProps,
              }}
            >
              <div className={classes.main}>
                <Banner />
                <Header />
                <SectionTitle />
                {IS_MAINTENANCE ? (
                  <Maintenance />
                ) : (
                  <main>
                    <Switch>
                      <Route path="/404" component={Page404} />
                      <Route
                        path="/aucune-declaration"
                        component={NoDeclaration}
                      />

                      <Route
                        path="/declaration/:etablissement"
                        component={DeclarationRedirect}
                        exact={true}
                      />

                      <Route
                        path="/declaration/:annee/:etablissement"
                        component={CompanySpace}
                      />

                      <Route
                        exact
                        path="/accueil"
                        component={userData.isInspecteur ? Home : PublicPortal}
                      />

                      <Route
                        path="/inspecteur/declarations"
                        component={
                          userData.isInspecteur
                            ? DeclarationsDashboard
                            : PublicPortal
                        }
                      />

                      <Route
                        path="/verificateur/declarations"
                        component={
                          userData.isAnyPrestataire
                            ? VerificateurDashboard
                            : PublicPortal
                        }
                      />

                      <Route
                        path="/lecteur/declarations"
                        component={
                          userData.isLecteur
                            ? DeclarationsDashboard
                            : PublicPortal
                        }
                      />
                      <Route
                        path="/inspecteur/etablissements"
                        component={
                          userData.isInspecteur
                            ? CompanyManagement
                            : PublicPortal
                        }
                      />

                      <Route
                        path="/superadmin/campagnes"
                        component={
                          userData.isSuperAdmin
                            ? CampaignsManagement
                            : PublicPortal
                        }
                      />

                      <Route
                        path="/admin/referentiels"
                        component={
                          userData.isAdmin
                            ? ReferentialsManagement
                            : PublicPortal
                        }
                      />

                      <Route
                        path="/download/export/:exportId"
                        component={ExportDownload}
                      />

                      <Route path="/" component={PublicPortal} />
                    </Switch>
                  </main>
                )}
              </div>
            </DeclarationPageHeaderContext.Provider>
          </PageHeaderContext.Provider>
        </DeclarationsDashboardContext>
      </ActiveOrLastCampaignContextProvider>
    </ReferentialsContextProvider>
  );
};

export default AppPage;
