import CommonEntityButton from "common/presentational/CommonEntityButton";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { computeBiomasseTotal } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/biomasse/helpers";
import { EmissionsInArray, MesureEmissionInArray } from "../utils/types";
import { createAppareilDiv } from "../utils/utils";
import {
  arrayCombustionConcentrationUnitsLabel,
  arrayCombustionTypeMesureLabel,
} from "../../../utils/selectPossibleUnits";

const suppressEmission = (
  singleEmissionInArray: MesureEmissionInArray,
  setMesureEmissionsInArray: Dispatch<SetStateAction<MesureEmissionInArray[]>>
) => {
  setMesureEmissionsInArray(elderEmissions => {
    return elderEmissions.filter(elderEmissionInArray => {
      return singleEmissionInArray.data.id !== elderEmissionInArray.data.id;
    });
  });
};

// a method to compute lines that takes as param any action or data that needs to be deduced from another data
const dummyComputeSingleEmissionLine = (
  emission: MesureEmissionInArray,
  classes: ClassesType<"full" | "error">,
  editAction: () => void,
  suppressAction: () => void,
  substanceName: string | null,
  isValidated: boolean,
  validationMesuresPath: string,
  pathSuffix: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const firstCellContent = computeFirstLineContentCell(
    editAction,
    substanceName || undefined,
    isValidated,
    !_.isEmpty(emission.errors)
  );

  return [
    computeWrappedCellWithError(firstCellContent, classes),
    computeWrappedCellWithError(
      emission.data.appareils && createAppareilDiv(emission.data.appareils),
      classes
    ),
    computeWrappedCellWithError(
      <p>
        {emission.data.typeMesure &&
          arrayCombustionTypeMesureLabel[emission.data.typeMesure]}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      emission.data.heures !== null
        ? computeNumberWithSeparator(emission.data.heures)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.debit !== null
        ? computeNumberWithSeparator(emission.data.debit)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      !emission.data.continuDebit ? "Non" : "Oui",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.frequenceDebit !== null
        ? computeNumberWithSeparator(emission.data.frequenceDebit)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.concentrationDebit !== null
        ? computeNumberWithSeparator(emission.data.concentrationDebit)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p>
        {emission.data.concentrationDebitUnit &&
          arrayCombustionConcentrationUnitsLabel[
            emission.data.concentrationDebitUnit
          ]}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      !emission.data.continuConcentration ? "Non" : "Oui",
      classes
    ),
    computeWrappedCellWithError(
      emission.data.frequenceConcentration !== null
        ? computeNumberWithSeparator(emission.data.frequenceConcentration)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      !emission.data.epuration ? "Non" : "Oui",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.nature || undefined}>{emission.data.nature}</p>,
      classes
    ),
    computeWrappedCellWithError(emission.data.rendement, classes),
    computeWrappedCellWithError(
      emission.data.emissionsAnnuelles !== null
        ? computeNumberWithSeparator(Number(emission.data.emissionsAnnuelles))
        : null,
      classes
    ),
    <CommonEntityButton
      handlerEdit={editAction}
      suppressMessage={"Êtes vous sûr de vouloir supprimer cette emission ?"}
      handlerSuppress={suppressAction}
      isValidated={isValidated}
      commentPath={`${validationMesuresPath}/${pathSuffix}`}
    />,
  ];
};

// method to compute the lines for a "standard" emission. Handles the actions and data that is "deduced" from the base emission given
export const computeSingleEmissionLine = (
  emission: MesureEmissionInArray,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMesureEmissionsInArray: Dispatch<SetStateAction<MesureEmissionInArray[]>>,
  isValidated: boolean,
  validationMesuresPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMesureEmissionsInArray);
  };

  const substanceName: string | null =
    emission.data.substance && emission.data.substance.nom;

  return dummyComputeSingleEmissionLine(
    emission,
    classes,
    editAction,
    suppressAction,
    substanceName,
    isValidated,
    validationMesuresPath,
    emission.data.id,
    computeFirstLineContentCell
  );
};

// method to compute the lines for a co2 emission. Handles the duplication of line in 2,
// and the different name in first cell, as well as the different total. Also creates the
// actions and data that is "deduced" from the base emission given
export const computeCo2EmissionLine = (
  emission: MesureEmissionInArray,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMesureEmissionsInArray: Dispatch<SetStateAction<MesureEmissionInArray[]>>,
  isValidated: boolean,
  validationMesuresPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[][] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMesureEmissionsInArray);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;

  const {
    emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse,
  } = computeBiomasseTotal(
    emission.data.emissionsAnnuelles,
    emission.data.biomasseMesure
  );

  return [
    dummyComputeSingleEmissionLine(
      {
        data: {
          ...emission.data,
          emissionsAnnuelles: emissionAnnuellesBiomasse,
        },
        errors: emission.errors,
      },
      classes,
      editAction,
      suppressAction,
      `${substanceName} biomasse`,
      isValidated,
      validationMesuresPath,
      emission.data.id + "/biomasse",
      computeFirstLineContentCell
    ),
    dummyComputeSingleEmissionLine(
      {
        data: {
          ...emission.data,
          emissionsAnnuelles: emissionAnnuellesNonBiomasse,
        },
        errors: emission.errors,
      },
      classes,
      editAction,
      suppressAction,
      `${substanceName} non biomasse`,
      isValidated,
      validationMesuresPath,
      emission.data.id + "/nonBiomasse",
      computeFirstLineContentCell
    ),
  ];
};
