import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { useComputeFirstLineContentCell } from "common/utils/computeWrappedCell";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../../utils/utils";
import { EmissionsInArray, MatiereEmissionInArray } from "../utils/types";
import { ERROR } from "theme";
import { ReferenceItemPolluantElementDto } from "api/gen";
import { ReferentialSinglePolluants } from "../../../utils/types";
import { isCo2 } from "../../../../../../toNow/Air/combustionProcedeUtils/biomasse/helpers";
import {
  computeCo2EmissionLine,
  computeSingleEmissionLine,
} from "./arrayUtils";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  matiereEmissionsInArray: MatiereEmissionInArray[];
  setMatiereEmissionsInArray: Dispatch<
    SetStateAction<MatiereEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationMatieresPath: string;
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >;
  autreElementUuid: string;
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const EmissionMatiereArray = ({
  matiereEmissionsInArray,
  setMatiereEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationMatieresPath,
  polluantElementMap,
  autreElementUuid,
  referentialSinglePolluants,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const arraySubstancesLines = filterByInstallation(
    matiereEmissionsInArray,
    installationName || ""
  ).reduce(
    (arrayLines: React.ReactElement[][], matiere: MatiereEmissionInArray) => {
      const isEmissionCo2 = isCo2(
        matiere.data.substance,
        referentialSinglePolluants.CO2
      );

      if (isEmissionCo2) {
        arrayLines.push(
          ...computeCo2EmissionLine(
            matiere,
            classes,
            setEmissionInModale,
            setEmissionModaleOpen,
            setMatiereEmissionsInArray,
            polluantElementMap,
            autreElementUuid,
            isValidated,
            referentialSinglePolluants,
            validationMatieresPath,
            computeFirstLineContentCell
          )
        );
        return arrayLines;
      }
      arrayLines.push(
        computeSingleEmissionLine(
          matiere,
          classes,
          setEmissionInModale,
          setEmissionModaleOpen,
          setMatiereEmissionsInArray,
          polluantElementMap,
          autreElementUuid,
          isValidated,
          referentialSinglePolluants,
          validationMatieresPath,
          computeFirstLineContentCell
        )
      );

      return arrayLines;
    },
    []
  );

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Élément sur lequel est indexé le bilan matière</p>,
        <p>Part de l'élément dans la substance émise (%)</p>,
        <p>Description des intrants</p>,
        <p>Quantité totale de l'élément dans les intrants (kg)</p>,
        <p>Teneur moyenne de l’élément dans les intrants (%)</p>,
        <p>Description des sortants</p>,
        <p>Quantité totale de l'élément dans les sortants (kg)</p>,
        <p>Teneur moyenne de l’élément dans les sortants (%)</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        130,
        130,
        130,
        130,
        130,
        200,
        130,
        130,
        200,
        130,
        130,
        ACTION_COLUMN_DEFAULT_WIDTH,
      ]}
    />
  );
};

export default EmissionMatiereArray;
