import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/styles";
import { DEFAULT_LINE_HEIGHT } from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  row: props => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: props.height,
  }),
});

interface RowProps {
  children?: React.ReactNode;
  height?: string;
  additionalStyle?: CSSProperties;
  additionalClassname?: string;
}

const Row = ({
  children,
  height = DEFAULT_LINE_HEIGHT,
  additionalStyle,
  additionalClassname,
}: RowProps): React.ReactElement => {
  const classes = useStyles({ height: height });

  return (
    <div
      className={classNames(classes.row, additionalClassname)}
      style={{
        ...additionalStyle,
      }}
    >
      {children}
    </div>
  );
};

export default Row;
