import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { CardProps } from "common/Card/type";
import Card from "common/Card";
import ProgressBar from "common/progressBar";
import classNames from "classnames";
import arrowDown from "icons/arrow-down.svg";
import arrowUp from "icons/arrow-up.svg";
import {
  BIG_CARD_NOTE,
  CARD_PADDING_DEFAULT_PX_VALUE,
  DASHBOARD_DISABLED_BACKGROUND_GREY,
  DASHBOARD_DISABLED_FONT_GREY,
  DEFAULT_BORDER_RADIUS,
} from "theme";
import { ImageProps } from "common/utils/types";
import { Link } from "react-router-dom";
import { SubCard } from "./types";

import checked from "icons/checked.svg";
import comment from "icons/comment.svg";
import jaugeLogo from "icons/jauge.svg";

const useStyles = makeStyles({
  note: BIG_CARD_NOTE,
  container: {
    zIndex: 10,
    width: "auto",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  containerImgAndText: {
    height: "100%",
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 5fr",
    gridTemplateRows: "3fr 1fr",
  },
  containerComment: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  containerProgressBar: {
    height: "30%",
    width: "80%",
    margin: "auto",
    gridColumn: "1 / 3",
    gridRow: "2 / 2",
  },
  notificationIcon: {
    transform: "scaleX(-1)",
  },
  notificationNumber: {
    borderRadius: "50%",
    width: "12px",
    height: "12px",
    background: "#DA2F68",
    position: "absolute",
    top: "-3px",
    right: "-3px",
    color: "#fff",
    fontSize: "11px",
    textAlign: "center",
  },
  checkedIcon: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
  },
  text: {
    width: "auto",
    marginLeft: "15px",
    display: "flex",
    alignItems: "center",
  },
  progressBar: {
    margin: " 8px auto auto auto",
    height: "5px",
    background: "#DBBCC4",
    borderRadius: DEFAULT_BORDER_RADIUS,
  },
  disabled: {
    filter: "grayscale(100%)",
  },
  disabledBackground: {
    backgroundColor: DASHBOARD_DISABLED_BACKGROUND_GREY,
  },
  disabledText: {
    color: DASHBOARD_DISABLED_FONT_GREY,
  },
  isSubContainer: {
    height: "54px",
    width: `calc(100% - ${2 * CARD_PADDING_DEFAULT_PX_VALUE}px)`,
  },
  tree: {
    marginLeft: `${2 * CARD_PADDING_DEFAULT_PX_VALUE}px`,
    marginRight: `-${CARD_PADDING_DEFAULT_PX_VALUE}px`,
    "& li": {
      listStyleType: "none",
      margin: "15px 0 10px 10px",
      position: "relative",
      zIndex: 1,
      "&:before": {
        content: "''",
        position: "absolute",
        top: "-15px",
        left: "-20px",
        borderLeft: "2px solid #ddd",
        borderBottom: "2px solid #ddd",
        width: "20px",
        height: "45px",
      },
      "&:after": {
        position: "absolute",
        content: "''",
        top: "30px",
        left: "-20px",
        borderLeft: "2px solid #ddd",
        borderTop: "2px solid #ddd",
        width: "20px",
        height: "100%",
      },
      "&:last-child:after": {
        display: "none",
      },
    },
  },
  imgSub: {
    gridRow: "1 / 3",
    width: "31px",
  },
  containerProgressBarSub: {
    gridColumn: "2 / 3",
    marginTop: "0px",
  },
  textSub: {
    display: "inline",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingRight: "17px",
    paddingBottom: "20px",
    width: "100%",
    fontSize: "15px",
  },
  link: {
    textDecoration: "none",
    color: "#000",
  },
  smallText: {
    fontSize: "12px",
  },
  dropdownIcon: {
    position: "absolute",
    width: "20px",
    right: "15px",
    top: "10px",
  },
  cursorOnActiveDropdowns: {
    cursor: "pointer",
  },
  jaugeIcon: {
    position: "absolute",
    width: "20px",
    right: "40px",
    top: "10px",
  },
});

interface BigCardProps extends CardProps {
  picture: ImageProps;
  text: string;
  percentageProgress?: number;
  nbComments?: number;
  children?: undefined;
  isDisabled?: boolean;
  subCards?: SubCard[];
  isSubCard?: boolean;
  isDone?: boolean;
  link: string;
  note?: string;
  colorNote?: string;
  isValidated: boolean;
  disabledMessage?: string;
  isClickable?: boolean;
  isDepassementDeSeuil?: boolean;
}

const BigCard = ({
  picture,
  text,
  percentageProgress,
  nbComments,
  isDisabled,
  disabledMessage,
  additionalClassname,
  subCards,
  isSubCard,
  isDone,
  link,
  note,
  colorNote,
  isValidated,
  isClickable = true,
  isDepassementDeSeuil = false,
}: BigCardProps): React.ReactElement => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const card = (
    <Card
      isDisabled={isDisabled}
      disabledMessage={disabledMessage}
      additionalClassname={classNames(additionalClassname, classes.container, {
        [classes.isSubContainer]: isSubCard,
        [classes.disabledBackground]: isDone,
        [classes.smallText]: isSubCard,
      })}
    >
      <div className={classes.containerImgAndText}>
        <img
          {...picture}
          alt={picture.alt || ""}
          width="60px"
          className={classNames({
            [classes.disabled]: isDisabled || isDone,
            [classes.imgSub]: isSubCard,
          })}
        />
        {isDepassementDeSeuil && (
          <img
            src={jaugeLogo}
            alt="Depassement de Seuil"
            className={classes.jaugeIcon}
          />
        )}
        {subCards && subCards.length > 0 && (
          <img
            src={isExpanded ? arrowUp : arrowDown}
            alt="arrow"
            className={classes.dropdownIcon}
          />
        )}

        <div
          className={classNames(classes.text, {
            [classes.disabledText]: isDone,
            [classes.textSub]: isSubCard,
          })}
        >
          {text}
        </div>
        <div
          className={classNames(classes.containerProgressBar, {
            [classes.containerProgressBarSub]: isSubCard,
          })}
        >
          {!isDisabled && percentageProgress !== undefined && (
            <ProgressBar
              percentageProgress={percentageProgress}
              isSmall={true}
              additionalClassname={classes.progressBar}
              isValidated={isValidated}
            />
          )}
        </div>
      </div>
      {!isDisabled && (
        <>
          {nbComments !== undefined && nbComments > 0 && (
            <div className={classes.containerComment}>
              <img
                width="20px"
                className={classes.notificationIcon}
                src={comment}
                alt="comment icon"
              />
              <div className={classes.notificationNumber}>{nbComments}</div>
            </div>
          )}
          {isValidated && percentageProgress !== undefined && (
            <img
              width="15px"
              className={classes.checkedIcon}
              src={checked}
              alt="checked icon"
            />
          )}
        </>
      )}
    </Card>
  );

  return (
    <div>
      {isClickable && !isDisabled ? (
        <Link
          to={link}
          className={classes.link}
          onClick={e => {
            if (isDisabled || (subCards && subCards.length > 0)) {
              e.preventDefault();
            }
          }}
        >
          {card}
        </Link>
      ) : (
        <div
          className={isDisabled ? "" : classes.cursorOnActiveDropdowns}
          onClick={toggleExpand}
        >
          {card}
        </div>
      )}
      {note && (
        <div className={classNames(colorNote, classes.note)}>{note}</div>
      )}

      {subCards && subCards.length > 0 && isExpanded && (
        <ul className={classes.tree}>
          {subCards.map((e, key) => (
            <li key={key}>
              <BigCard
                picture={{
                  src: e.picture.src,
                  alt: e.picture.alt,
                }}
                text={e.text}
                percentageProgress={e.percentageProgress}
                nbComments={e.nbComments}
                isSubCard
                isDone={isDone}
                link={e.link}
                isDisabled={e.isDisabled}
                isValidated={e.isValidated}
                disabledMessage={e.disabledMessage}
                note={e.note}
                isDepassementDeSeuil={e.isDepassementDeSeuil}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default BigCard;
