import { AirSolvantsInformationDtoDerogationEnum } from "api/gen";

const activiteOptions: string[] = [
  "Impression sur rotative offset à sécheur thermique (>15 t/an)",
  "Héliogravure d’édition (>25 t/an)",
  "Autres unités d’héliogravure, flexographie, impression sérigraphique en rotative, contrecollage ou vernissage (>15 t/an) impres­sion sérigraphique en rotative sur textiles/cartons (>30 t/an)",
  "Nettoyage de surface à l’aide des composés indiqués à l’article 59, paragraphe 5 (>1 t/an)",
  "Autres nettoyages de surface (>2 t/an)",
  "Revêtement (< 15 t/an) et retouche de véhicules",
  "Laquage en continu (>25 t/an)",
  "Autres revêtements, y compris le revête­ment de métaux, de plastiques, de texti­les (5), de feuilles et de papier (>5 t/an)",
  "Revêtement de fil de bobinage (>5 t/an)",
  "Revêtement de surfaces en bois (>15 t/an)",
  "Nettoyage à sec",
  "Imprégnation du bois (>25 t/an)",
  "Revêtement du cuir (>10 t/an)",
  "Fabrication de chaussures (>5 t/an)",
  "Stratification de bois et de plastique (>5 t/an)",
  "Revêtement adhésif (>5 t/an)",
  "Fabrication de mélanges pour revêtements, de vernis, d’encres et de colles (>100 t/an)",
  "Conversion de caoutchouc (>15 t/an)",
  "Extraction d’huiles végétales et de graisses animales et activités de raffinage d’huile végétale (>10 t/an)",
  "Fabrication de produits pharmaceutiques (>50 t/an)",
  "Industrie de revêtement de véhicules",
];

export const getActiviteOptions = (values: string[]): string[] => {
  if (values.length > 2) {
    return [];
  }
  return activiteOptions;
};

export const derogationLabels: {
  [key in AirSolvantsInformationDtoDerogationEnum]: string;
} = {
  ARTICLE59_2: "Article 59, paragraphe 2, de la directive IED",
  ARTICLE59_3: "Article 59, paragraphe 3, de la directive IED",
  NONE: "Non",
};

export const derogationOptions = Object.keys(
  derogationLabels
) as AirSolvantsInformationDtoDerogationEnum[];
