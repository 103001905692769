import { makeStyles } from "@material-ui/styles";
import React from "react";
import classNames from "classnames";
import { CustomButtonProps } from "./type";

const useStyles = makeStyles({
  container: {
    width: "31px",
    height: "31px",
    background: "white",
    color: "white",
    marginLeft: "15px",
    cursor: "pointer",
  },
});

const ImgButton = ({
  additionalClassname,
  children,
  onClick,
}: CustomButtonProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <button
      onClick={onClick}
      className={classNames(classes.container, additionalClassname)}
    >
      {children}
    </button>
  );
};

export default ImgButton;
