import React from "react";
import { computeErrorObject } from "./utils";
import DummyTextInput from "../dumbInput/DummyTextInput";
import { TextInputFieldProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form,
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: TextInputFieldProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });
  const fieldValue = field.value === null ? "" : field.value;

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyTextInput
      {...field}
      {...props}
      value={externalValue !== undefined ? externalValue : fieldValue}
      disabled={externalValue !== undefined ? true : disabled}
      error={error}
      onChange={newValue => {
        form.setFieldValue(field.name, newValue);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newValue);
        }
      }}
    />
  );
};

export default TextInput;
