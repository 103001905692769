import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { InstallationInArray } from "./utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface InstallationsArrayProps {
  installationsInPage: InstallationInArray[];
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  setInstallationInModale: Dispatch<SetStateAction<InstallationInArray | null>>;
  setInstallationModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationInstallationPath: string;
}

const InstallationsArray = ({
  installationsInPage,
  setInstallationsInPage,
  setInstallationInModale,
  setInstallationModaleOpen,
  isValidated,
  validationInstallationPath,
}: InstallationsArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressInstallation(singleInstallationInPage: InstallationInArray) {
    setInstallationsInPage(elderInstallations => {
      return elderInstallations.filter(elderInstallationInPage => {
        return (
          singleInstallationInPage.data.id !== elderInstallationInPage.data.id
        );
      });
    });
  }

  const computeSingleInstallationLine = (
    installation: InstallationInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setInstallationInModale(installation);
      setInstallationModaleOpen(true);
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      installation.data.nom || undefined,
      isValidated,
      !_.isEmpty(installation.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        installation.data.heures !== null
          ? computeNumberWithSeparator(installation.data.heures)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        installation.data.volume !== null
          ? computeNumberWithSeparator(installation.data.volume)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={installation.data.unite || undefined}>
          {installation.data.unite}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={installation.data.produit || undefined}>
          {installation.data.produit}
        </p>,
        classes
      ),

      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressInstallation(installation);
        }}
        suppressMessage={
          "Êtes vous sûr de vouloir supprimer cette installation ? Les émissions et cov liées à cette installation seront supprimées"
        }
        isValidated={isValidated}
        commentPath={`${validationInstallationPath}/${installation.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = installationsInPage.map(
    singleInstallationInPage => {
      return computeSingleInstallationLine(singleInstallationInPage, classes);
    }
  );

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Installation(s)</p>,
          <p>Nombre d'heures de fonctionnement (h/an)</p>,
          <p>Volume d'activité</p>,
          <p>Unité</p>,
          <p>Type de produit(s)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[200, 180, 180, 180, 180, 90]}
      />
    </>
  );
};

export default InstallationsArray;
