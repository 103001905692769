import { DeclarationConstraintViolationDto } from "api/gen/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDeclaration2122 } from "../../../versionedElements/declarationHooks2122";

interface VerificationErrors {
  hasValidateOnce: boolean;
  triggerValidation: (promess: Promise<void>) => Promise<void>;
  errors: DeclarationConstraintViolationDto[];
  hasError: boolean;
}

/**
 * Use this hook to find errors for blocVerification to display in a ViolationProcessor.
 * Similar to useDeclarationErrorAndWarnings, but since backend does not give us specific errors
 * for this bloc in declaration's error, we have to build those errors by ourselves in frontend.
 */
export const useVerificationErrors = (): VerificationErrors => {
  const declaration = useDeclaration2122();
  const isMounted = useRef<boolean>(false);
  const [hasValidateOnce, setHasValidateOnce] = useState(false);

  const errors = useMemo(() => {
    const currentErrors: DeclarationConstraintViolationDto[] = [];
    // Must have exactly one uploaded file per nim
    const hasVerificationWithoutFile = declaration.body.sections.quotas.allocations.blocVerification.verifications.find(
      verification => !verification.rapport
    );

    if (hasVerificationWithoutFile) {
      currentErrors.push({
        message: "Un rapport de vérification doit être importé par NIM.",
        preventSubmit: false,
        path: "",
        commentRequired: false,
      });
    }
    return currentErrors;
  }, [
    declaration.body.sections.quotas.allocations.blocVerification.verifications,
  ]);

  const hasError = useMemo(() => errors.length > 0, [errors]);

  const triggerValidation = useCallback(
    async (promess: Promise<void>) => {
      await promess;
      if (!isMounted.current) {
        return;
      }
      setHasValidateOnce(true);
    },
    [setHasValidateOnce, isMounted]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return {
    hasValidateOnce,
    triggerValidation,
    errors,
    hasError,
  };
};
