import React from "react";
import {
  CampaignDtoStateEmissionsEnum,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
  QuotasEmissionsBlocPlanSurveillanceDto2121,
} from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import { PATH_QUOTAS_EMISSIONS } from "common/path/path20Now";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { ReferentialActiviteQuotasProps } from "../common/BlocRappelTypeActivite/types";
import {
  useBasicDeclarationHandlers24Now,
  useDeclaration24Now,
  useDeclarationHelpers24Now,
  useDeclarationStateHandlers24Now,
} from "../../versionedElements/declarationHooks24Now";
import { isFullDeclarationActive } from "pages/CompanySpace/from20/toNow/Quotas/utils";
import { areAllEmissionsBlocsValidated } from "../utils/validation";
import QuotasGlobalFormActions from "pages/CompanySpace/from21/toNow/Quotas/QuotasGlobalFormActions";
import QuotasDeclarationOfficeDownload from "pages/CompanySpace/from21/toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import BlocRappelTypeActivite from "pages/CompanySpace/from21/toNow/Quotas/common/BlocRappelTypeActivite";
import PlanSurveillanceForm from "./BlocPlanSurveillance/PlanSurveillanceForm";
import RapportEmissionsForm from "./BlocRapportEmissions/RapportEmissionsForm";
import BlocEmailsVerificateurs from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocEmailsVerificateurs";
import BlocVerification from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocVerification";
import BlocBilanTotal from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocBilanTotal";
import BlocInspecteur from "pages/CompanySpace/from21/to23/Quotas/common/BlocInspecteur";
import {
  updateHandlerBilanTotal24Now,
  updateHandlerEmailsAddressEmissions24Now,
} from "../../versionedElements/updateHandlers24Now";

const EmissionsForm = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const currentCampaign = useCurrentDeclarationCampaign();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();
  const { update } = useBasicDeclarationHandlers24Now();
  const { yieldQuotaEmissionsVerif } = useDeclarationStateHandlers24Now();

  const blocPlanDeSurveillanceFormProps = useFormikBloc<
    QuotasEmissionsBlocPlanSurveillanceDto2121
  >();

  const blocBilanTotalProps = useFormikBloc<
    QuotasBlocEmissionsBilanTotalDto20Now
  >();
  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasBlocEmissionsEmailsVerificateursDto20Now
  >();

  const isFullForm = isFullDeclarationActive(declaration);

  const pageHasChanges =
    blocEmailsVerificateursProps.hasChanges ||
    blocPlanDeSurveillanceFormProps.hasChanges;

  // Are blocs validated?
  const allDeclarantBlocsValidated = areAllEmissionsBlocsValidated(
    isPathValidatedInDeclaration,
    isFullForm
  );

  // TODO [GEREP-2565] use actions buttons instead
  const GlobalFormActions = () => (
    <QuotasGlobalFormActions
      pathToValidate={PATH_QUOTAS_EMISSIONS}
      target={WorkflowTarget.QUOTA_EMISSIONS}
      quotaState={declaration.body.workflowStatus.quotaEmissions.state}
      yieldQuotaVerif={yieldQuotaEmissionsVerif}
      isSimplifiedQuota={!isFullForm}
      saveAction={() =>
        update(declaration => {
          if (isFullForm) {
            declaration = updateHandlerBilanTotal24Now(declaration, null);
            declaration = updateHandlerEmailsAddressEmissions24Now(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
          } else {
            declaration = updateHandlerBilanTotal24Now(
              declaration,
              blocBilanTotalProps.getValues()
            );
            declaration = updateHandlerEmailsAddressEmissions24Now(
              declaration,
              null
            );
          }
          return declaration;
        })
      }
      cancelAction={() => {
        blocBilanTotalProps.resetForm();
        blocEmailsVerificateursProps.resetForm();
        blocPlanDeSurveillanceFormProps.resetForm();
      }}
      hasChanges={pageHasChanges}
      declarantBlocsValidated={allDeclarantBlocsValidated}
    />
  );

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
      />
      <Row />

      <BlocRappelTypeActivite referentialActivites={referentialActivites} />
      <Row />

      {isFullForm ? (
        <>
          <PlanSurveillanceForm />
          <Row />

          <RapportEmissionsForm />
          <Row />

          <BlocEmailsVerificateurs
            formikRef={blocEmailsVerificateursProps.formikRef}
            hasChanges={blocEmailsVerificateursProps.hasChanges}
            setHasChanges={blocEmailsVerificateursProps.setHasChanges}
          />

          <GlobalFormActions />
          <Row />

          <BlocVerification />
        </>
      ) : (
        <>
          <Row />

          <BlocBilanTotal
            formikRef={blocBilanTotalProps.formikRef}
            hasChanges={blocBilanTotalProps.hasChanges}
            setHasChanges={blocBilanTotalProps.setHasChanges}
          />
          <Row />

          <GlobalFormActions />
        </>
      )}
      <Row />

      <BlocInspecteur
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
        currentState={declaration.body.workflowStatus.quotaEmissions.state}
        path={PATH_QUOTAS_EMISSIONS}
        isCampaignEnded={
          currentCampaign.stateEmissions === CampaignDtoStateEmissionsEnum.ENDED
        }
      />
      <Row height={"25px"} />
    </>
  );
};

export default EmissionsForm;
