import React, { useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import BasicLabel from "common/form/fields/label/BasicLabel";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import { dtoToBlocValues } from "./converter";
import { BlocRejetEauFormValues } from "./types";
import { validationSchema } from "./validation";
import {
  isAlimentationIndustrielleSelected,
  isOtherStationSelected,
  selectPossibleValues,
} from "./selectPossibleValues";
import { updateHandlerRejetEau } from "../utils";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { ReferenceSandreDto, ReferenceStationEpurationDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import { PolluantInArray } from "../BlocRejetPolluant/types";
import {
  PATH_EAU_REJETEAU,
  PATH_EAU_REJETEAU_ISOLE,
  PATH_EAU_REJETEAU_RACCORDE,
} from "common/path/path18Now";
import { validationMilieuAndStation } from "./validationMilieuAndStation";
import { FormikBlocFullContext23Now } from "pages/CompanySpace/from23/toNow/versionedElements/FormikBlocFullContext23Now";
import { useDeclaration23Now } from "../../../toNow/versionedElements/declarationHooks23Now";
import { FormikBlocFullContext2323 } from "../../versionedElements/FormikBlocFullContext2323";

const useStyles = makeStyles({
  inputField: {
    display: "flex",
  },
  row: {
    marginBottom: "10px",
  },
  field: {
    width: "100%",
  },
  cellWidth: {
    textAlign: "center",
    width: "22%",
    margin: "0 4px",
    "&:first-child": {
      width: "12%",
    },
  },
  title: {
    fontSize: "1em",
    fontWeight: "bold",
  },
});

interface BlocRejetEauProps
  extends FormikBlocRefAndState<BlocRejetEauFormValues> {
  isOver5000Treshold: boolean;
  isMegathermie: boolean;
  referentialSandres: ReferenceSandreDto;
  referentialStationsEpuration: ReferenceStationEpurationDto;
  polluantsInArray: PolluantInArray[];
}

const BlocRejetEau = ({
  hasChanges,
  setHasChanges,
  formikRef,
  isOver5000Treshold,
  isMegathermie,
  referentialSandres,
  referentialStationsEpuration,
  polluantsInArray,
}: BlocRejetEauProps): JSX.Element => {
  const classes = useStyles();
  const declaration = useDeclaration23Now();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "0",
    formPrefix: "bloc-eau-rejets",
  };

  const NumberFieldNoLabel = useNumberFieldGenerator(commonProps);
  const possibleValues = selectPossibleValues(
    referentialSandres,
    referentialStationsEpuration
  );

  return (
    <FormikBlocFullContext2323
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={dtoToBlocValues(
        declaration.body.sections.eau.rejetEau,
        possibleValues,
        referentialSandres,
        referentialStationsEpuration
      )}
      validationSchema={validationSchema(
        isOver5000Treshold,
        isMegathermie,
        polluantsInArray.length
      )}
      additionalFieldValidation={values => validationMilieuAndStation(values)}
      title="tableau des volumes et chaleurs rejetés"
      pathToValidate={PATH_EAU_REJETEAU}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) =>
        updateHandlerRejetEau(declaration, values, referentialStationsEpuration)
      }
      renderContent={(props, shouldDisabledFields) => (
        <>
          <Row additionalClassname={classes.row} height="auto">
            <div className={classes.cellWidth} />
            <div className={classNames(classes.cellWidth, classes.title)}>
              <BasicLabel
                content="Station d'épuration / autre&nbsp;usage"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classNames(classes.cellWidth, classes.title)}>
              <BasicLabel
                content="Milieu récepteur"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classNames(classes.cellWidth, classes.title)}>
              <BasicLabel
                content="Volume rejeté"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classNames(classes.cellWidth, classes.title)}>
              <BasicLabel
                content="Chaleur rejetée"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
          </Row>

          <Row additionalClassname={classes.row} height="auto">
            <div className={classes.cellWidth}>
              <BasicLabel
                content="Rejet raccordé"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classes.cellWidth}>
              <WrappedChoiceSelectModale
                name="stationRaccorde"
                label=""
                title="STATIONS D'ÉPURATION"
                header={["Code", "Nom", "Code département"]}
                linesData={possibleValues.stations}
                formatLine={station =>
                  !isOtherStationSelected(station)
                    ? [
                        station.codeOuvrageDepollution,
                        station.nomOuvrageDepollution,
                        station.codeDepartement.length === 1
                          ? `0${station.codeDepartement}`
                          : station.codeDepartement,
                      ]
                    : ["", station.nomOuvrageDepollution, ""]
                }
                formatSelectedTitle={formatedLine =>
                  formatedLine.nomOuvrageDepollution
                }
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [
                      lineData.codeOuvrageDepollution,
                      lineData.nomOuvrageDepollution,
                    ],
                    searchedStr
                  )
                }
                placeholder="Station d'épuration"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
                commonProps={commonProps}
              />
            </div>
            <div className={classes.cellWidth}>
              <WrappedChoiceSelectModale
                name="milieuRaccorde"
                label=""
                title="MILIEU RÉCEPTEUR"
                header={["Code", "Libellé", "Indice du bassin"]}
                linesData={possibleValues.locations}
                formatLine={location => [
                  location.code,
                  location.libelle,
                  location.bassindce,
                ]}
                formatSelectedTitle={formatedLine => formatedLine.libelle}
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [lineData.code, lineData.libelle],
                    searchedStr
                  )
                }
                placeholder="Milieu récepteur"
                hideValue={isAlimentationIndustrielleSelected(
                  formikRef.current?.state.values.stationRaccorde ?? null
                )}
                disabled={
                  shouldDisabledFields ||
                  (formikRef.current?.state.values.stationRaccorde
                    ? isAlimentationIndustrielleSelected(
                        formikRef.current?.state.values.stationRaccorde ?? null
                      )
                    : declaration.body.sections.eau.rejetEau.rejetRaccorde
                        .alimentationEauIndustrielle)
                }
                additionalClassName={classes.field}
                commonProps={commonProps}
              />
            </div>
            <div className={classes.cellWidth}>
              <NumberFieldNoLabel
                name="volumeRaccorde"
                label=""
                unit="m³/an"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
              />
            </div>
            <div className={classes.cellWidth}>
              <NumberFieldNoLabel
                name="chaleurRaccorde"
                label=""
                unit="Mth/an"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
                commentPath={PATH_EAU_REJETEAU_RACCORDE}
              />
            </div>
          </Row>

          <Row additionalClassname={classes.row} height="auto">
            <div className={classes.cellWidth}>
              <BasicLabel
                content="Rejet isolé"
                htmlFor=""
                width="100%"
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classes.cellWidth} />
            <div className={classes.cellWidth}>
              <WrappedChoiceSelectModale
                name="milieuIsole"
                label=""
                title="MILIEU RÉCEPTEUR"
                header={["Code", "Libellé", "Indice du bassin"]}
                linesData={possibleValues.locations}
                formatLine={location => [
                  location.code,
                  location.libelle,
                  location.bassindce,
                ]}
                formatSelectedTitle={formatedLine => formatedLine.libelle}
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [lineData.code, lineData.libelle],
                    searchedStr
                  )
                }
                placeholder="Milieu récepteur"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
                commonProps={commonProps}
              />
            </div>
            <div className={classes.cellWidth}>
              <NumberFieldNoLabel
                name="volumeIsole"
                label=""
                unit="m³/an"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
              />
            </div>
            <div className={classes.cellWidth}>
              <NumberFieldNoLabel
                name="chaleurIsole"
                label=""
                unit="Mth/an"
                disabled={shouldDisabledFields}
                additionalClassName={classes.field}
                commentPath={PATH_EAU_REJETEAU_ISOLE}
              />
            </div>
          </Row>
        </>
      )}
    />
  );
};

export default BlocRejetEau;
