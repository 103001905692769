import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import {
  ProductedWasteStorage,
  ProductionLocationProps,
} from "../production/types";
import { DeclarationWasteFormData, WasteReferentiels } from "../types";
import {
  computeRefMethodForMethod,
  productionLocation,
} from "../utils/Referentiels";
import {
  DechetProduitDto23Now,
  DechetProduitDto23NowFillingSourceEnum,
  DechetProduitDto23NowLieuOperationEnum,
} from "api/gen";
import uuid from "uuid";
import { isNotificationNumberAccessibleForProduction } from "../production/submitHandler";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Nullable } from "common/utils/types";

const convertLieuOperationEnumToProductionLocationProps = (
  lieuOperation: DechetProduitDto23NowLieuOperationEnum
): ProductionLocationProps => {
  let toReturn: ProductionLocationProps | null = null;
  switch (lieuOperation) {
    case DechetProduitDto23NowLieuOperationEnum.SITE:
      toReturn = findElementMatchingTemplate(
        { isDepartmentAvailable: false, isCountryAvailable: false },
        productionLocation
      );
      break;
    case DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT:
      toReturn = findElementMatchingTemplate(
        { isDepartmentAvailable: true, isCountryAvailable: false },
        productionLocation
      );
      break;
    case DechetProduitDto23NowLieuOperationEnum.PAYS:
      toReturn = findElementMatchingTemplate(
        { isDepartmentAvailable: false, isCountryAvailable: true },
        productionLocation
      );
      break;
    default:
      throw new ShouldNotHappen(lieuOperation);
  }

  if (!toReturn) {
    throw Error("Did not find operation location in referential");
  }

  return toReturn;
};

export const convertProductionStoredToModalData = (
  updatingWaste: Nullable<DechetProduitDto23Now> | null,
  referentials: WasteReferentiels
): Partial<DeclarationWasteFormData> => {
  const initialValues: Partial<DeclarationWasteFormData> = {};
  if (updatingWaste) {
    if (updatingWaste.codeID) {
      initialValues.wasteId =
        findElementMatchingTemplate(
          {
            uuid: updatingWaste.codeID,
          },
          referentials.polluants
        ) || updatingWaste.codeID;
    }

    initialValues.wasteAmount =
      updatingWaste.quantite !== null ? updatingWaste.quantite : undefined;

    if (updatingWaste.method) {
      initialValues.method = findElementMatchingTemplate(
        { backOfficeCode: updatingWaste.method },
        referentials.methods
      );
    }

    if (updatingWaste.methodReference) {
      initialValues.methorRef = updatingWaste.methodReference;
    }

    initialValues.description = updatingWaste.methodPrecision || undefined;

    if (updatingWaste.operationElimination) {
      initialValues.operation = findElementMatchingTemplate(
        { code: updatingWaste.operationElimination.replace(/ /g, "") }, // remove eventual space
        referentials.operationOrValorisationForProduction
      );
    }

    if (updatingWaste.lieuOperation) {
      initialValues.location = convertLieuOperationEnumToProductionLocationProps(
        updatingWaste.lieuOperation
      );
    }

    if (updatingWaste.departementID) {
      initialValues.departement =
        findElementMatchingTemplate(
          { uuid: updatingWaste.departementID },
          referentials.departements
        ) || updatingWaste.departementID;
    }

    if (updatingWaste.paysID) {
      initialValues.pays =
        findElementMatchingTemplate(
          { uuid: updatingWaste.paysID },
          referentials.pays
        ) || updatingWaste.paysID;
    }

    initialValues.isFirstReceptionFrance =
      updatingWaste.firstReceptionFrance || undefined;

    initialValues.receptionName =
      updatingWaste.nomEtablissementReception || undefined;
    initialValues.receptionAdress =
      updatingWaste.streetAdresseEtablissementReception || undefined;
    initialValues.receptionPostalCode =
      updatingWaste.postalCodeEtablissementReception || undefined;
    initialValues.receptionCommune =
      updatingWaste.communeEtablissementReception || undefined;
    initialValues.treatmentName =
      updatingWaste.nomEtablissementElimination || undefined;
    initialValues.treatmentAdress =
      updatingWaste.streetAdresseEtablissementElimination || undefined;
    initialValues.treatmentPostalCode =
      updatingWaste.postalCodeEtablissementElimination || undefined;
    initialValues.treatmentCommune =
      updatingWaste.communeEtablissementElimination || undefined;
    initialValues.notification = updatingWaste.numeroNotification || undefined;
    initialValues.isNoLongerWaste = updatingWaste.sortiDuStatutDeDechet;
  }
  return initialValues;
};

export const convertModalToStoredData = (
  values: DeclarationWasteFormData,
  updatingWaste: ProductedWasteStorage | null
): DechetProduitDto23Now => {
  if (!values.operation) {
    throw Error("Unknonw operation selected");
  } else if (!values.operation.isAvailableForProduction) {
    throw Error("Selected operation is not available for production");
  }

  const method = values.method ? values.method.backOfficeCode : null;
  const lieuOperation = values.location.backCode;
  const data = {
    id: updatingWaste ? updatingWaste.data.id : uuid(),
    codeID:
      typeof values.wasteId === "object" && values.wasteId !== null
        ? values.wasteId.uuid
        : null,
    quantite: values.wasteAmount,
    method: method,
    methodReference:
      computeRefMethodForMethod(values.method).length > 0
        ? values.methorRef
        : null,
    methodPrecision: !values.description
      ? null
      : values.description.trim() === ""
      ? null
      : values.description,
    operationElimination: values.operation.code,
    fillingSource: DechetProduitDto23NowFillingSourceEnum.USER_INPUT,
  };
  if (lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE) {
    const toAddOrUpdate: DechetProduitDto23Now = {
      ...data,
      lieuOperation: lieuOperation,
      firstReceptionFrance: null,
      departementID: null,
      paysID: null,
      nomEtablissementReception: null,
      streetAdresseEtablissementReception: null,
      postalCodeEtablissementReception: null,
      communeEtablissementReception: null,
      nomEtablissementElimination: null,
      streetAdresseEtablissementElimination: null,
      postalCodeEtablissementElimination: null,
      communeEtablissementElimination: null,
      numeroNotification: null,
      sortiDuStatutDeDechet: values.isNoLongerWaste || false,
    };
    return toAddOrUpdate;
  } else if (
    lieuOperation === DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT
  ) {
    if (typeof values.departement !== "object" || values.departement === null) {
      throw Error("This department does not exist in referential.");
    }

    const toAddOrUpdate: DechetProduitDto23Now = {
      ...data,
      lieuOperation: lieuOperation,
      firstReceptionFrance: null,
      departementID: values.departement.uuid,
      paysID: null,
      nomEtablissementReception: values.receptionName,
      streetAdresseEtablissementReception: values.receptionAdress,
      postalCodeEtablissementReception: values.receptionPostalCode.trim(),
      communeEtablissementReception: values.receptionCommune,
      nomEtablissementElimination: null,
      streetAdresseEtablissementElimination: null,
      postalCodeEtablissementElimination: null,
      communeEtablissementElimination: null,
      numeroNotification: values.notification,
      sortiDuStatutDeDechet: null,
    };
    return toAddOrUpdate;
  } else if (lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS) {
    if (typeof values.pays !== "object" || values.pays === null) {
      throw Error("This country does not exist in referential.");
    }

    const toAddOrUpdate: DechetProduitDto23Now = {
      ...data,
      lieuOperation: lieuOperation,
      firstReceptionFrance: values.isFirstReceptionFrance,
      departementID: null,
      paysID: values.pays.uuid,
      nomEtablissementReception: values.receptionName,
      streetAdresseEtablissementReception: values.receptionAdress,
      postalCodeEtablissementReception: values.receptionPostalCode.trim(),
      communeEtablissementReception: values.receptionCommune,
      nomEtablissementElimination: values.treatmentName,
      streetAdresseEtablissementElimination: values.treatmentAdress,
      postalCodeEtablissementElimination: values.treatmentPostalCode.trim(),
      communeEtablissementElimination: values.treatmentCommune,
      numeroNotification: isNotificationNumberAccessibleForProduction(values)
        ? values.notification
        : null,
      sortiDuStatutDeDechet: null,
    };
    return toAddOrUpdate;
  }

  throw Error("lieu operation is missing");
};
