import React from "react";
import {
  ActiviteTransformationType,
  france,
  fieldActiviteTransformationMatcher,
} from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  useTextFieldGenerator,
  useNumberFieldGenerator,
  useBooleanCheckBoxGenerator,
} from "common/form/fields/helpers/generators";
import {
  ReferenceItemPaysDto,
  ReferenceItemGeoCodeInseeDto,
  ReferencePaysDto,
  ReferenceGeoCodeInseeDto,
} from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { FormComponentProps } from "common/form/utils";
import { validateFactory } from "./utils/validate";
import { generateFranceReferenceItemPays } from "./utils/converters";

interface FormFactoryProps
  extends FormComponentProps<ActiviteTransformationType> {
  referentialCountries: ReferencePaysDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const countryIsFilled = (country: ReferenceItemPaysDto | null) => {
  return country !== null;
};

const FormFactory = ({
  closeFunction,
  onSubmit,
  initialObject,
  referentialCountries,
  referentialInsee,
  isEdit,
}: FormFactoryProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-informations-transformation",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const getCityReferenceItem = (
    city: string | null
  ): ReferenceItemGeoCodeInseeDto | null => {
    if (city) {
      const result = referentialInsee.referenceItemGeoCodeInseeDtoList.find(
        ref => ref.nomCommune === city
      );
      return result ?? null;
    }
    return null;
  };

  return (
    <CommonFormSingleEntity
      isEdit={isEdit}
      title="AJOUTER UNE USINE/ATELIER"
      validate={validateFactory}
      initialEntity={initialObject}
      closeFunction={closeFunction}
      onSubmit={(values: ActiviteTransformationType, formikActions) => {
        onSubmit(values, formikActions);
      }}
      renderField={formProps => (
        <>
          <TextField
            label="Raison sociale *"
            name={fieldActiviteTransformationMatcher.socialReason}
          />

          <TextField
            label="Code postal *"
            name={fieldActiviteTransformationMatcher.zipCode}
          />

          <WrappedChoiceSelectModale<ReferenceItemPaysDto>
            label="Pays *"
            name={fieldActiviteTransformationMatcher.country}
            title=""
            header={["Pays"]}
            linesData={[
              generateFranceReferenceItemPays(),
              ...referentialCountries.referenceItemPaysDtoList,
            ]}
            formatLine={lineData => [lineData.designation]}
            formatSelectedTitle={lineData => lineData.designation}
            isLineInSearch={(lineData, searchedStr) =>
              isSearchStringInCollection([lineData.designation], searchedStr)
            }
            commonProps={commonProps}
            onSelect={newValue => {
              if (newValue) {
                // because city is doubly typed, we must do the conversion from a string to a ReferenceItemGeoCodeInseeDto if we're in France, or just use the city as string if we're in foreign country
                if (
                  newValue.designation !== france &&
                  typeof formProps.values.ville !== "string"
                ) {
                  formProps.setFieldValue(
                    "ville",
                    formProps.values.ville
                      ? formProps.values.ville.nomCommune
                      : null
                  );
                }
                if (
                  newValue.designation === france &&
                  typeof formProps.values.ville === "string"
                ) {
                  formProps.setFieldValue(
                    "ville",
                    getCityReferenceItem(formProps.values.ville)
                  );
                }
              }
            }}
          />

          {countryIsFilled(formProps.values.paysID) && (
            <>
              {formProps.values.paysID &&
              formProps.values.paysID.designation === france ? (
                <WrappedChoiceSelectModale<ReferenceItemGeoCodeInseeDto>
                  label="Ville *"
                  name={fieldActiviteTransformationMatcher.city}
                  title=""
                  header={["Ville"]}
                  linesData={[
                    ...referentialInsee.referenceItemGeoCodeInseeDtoList,
                  ]}
                  formatLine={lineData => [lineData.nomCommune]}
                  formatSelectedTitle={lineData => lineData.nomCommune}
                  isLineInSearch={(lineData, searchedStr) =>
                    isSearchStringInCollection(
                      [lineData.nomCommune],
                      searchedStr
                    )
                  }
                  commonProps={commonProps}
                />
              ) : (
                <TextField
                  label="Ville *"
                  name={fieldActiviteTransformationMatcher.city}
                  tooltipContent="Saisir le nom de la commune en mettant des tirets (-) entre les mots pour une commune à noms composés et sans saisir l’article par lequel commence éventuellement le nom de la commune. Exemple pour « Les Sables d’Olonne », écrire « Sables- d’Olonne (les)», ou pour « Le Havre » écrire « Havre (Le) »"
                />
              )}
            </>
          )}

          <NumberField
            label="Distance carrière-usine/atelier *"
            name={fieldActiviteTransformationMatcher.careerRange}
            unit="km"
            tooltipContent="Concerne notamment les carrières de minéraux industriels (dont calcaires cimentiers, chaux, gypse/anhydrite, argiles communes pour tuiles & briques) ou de roches ornementales ou de construction. Ne concerne pas le plus souvent les carrières de granulats - celles équipées seulement d'installation(s) de concassage et/ou criblage dans la carrière - ou de matériaux > 80 mm. Sauf si, outre les opérations de concassage et/ou criblage, le granulat est traité dans une usine pour être lavé, roulé, trié automatiquement et/ou séché. La distance carrière - usine/atelier est mesurée entre la sortie de la carrière et l'entrée de l’usine/atelier où les matériaux sont transformés. Si l’usine ou l’atelier est juxtaposé(e) à l’enceinte du périmètre de la carrière, il y a lieu de considérer que cette distance est égale à 0."
          />

          <CheckBox
            label="Usine/atelier alimenté par d'autres carrières"
            name={fieldActiviteTransformationMatcher.poweredByOtherCareers}
          />
        </>
      )}
    />
  );
};

export default FormFactory;
