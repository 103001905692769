import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import * as Yup from "yup";
import {
  commonPositiveNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { PopulatedBuildingInArray } from "../utils/types";
import _ from "lodash";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";
import {
  elevageAtLeastOneEmissionMessage,
  elevageUniqueBuildingMessage,
} from "common/declarant/formik/formikMessages";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";

interface EmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  buildingsInArray: PopulatedBuildingInArray[];
  setBuildingsInArray: (buildings: PopulatedBuildingInArray[]) => void;
  setBuildingInModal: (building?: PopulatedBuildingInArray) => void;
  buildingInModal?: PopulatedBuildingInArray;
  species: CleanOptionProps[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const validationSchema = (species: CleanOptionProps[]) => {
  // A validation schema can have any type as value (see yup)
  const schema: { [k: string]: any } = {
    buildingName: commonStringFields,
  };
  species.forEach(specie => {
    const name = selectPossibleValues.speciesMapper[specie.value];
    schema[name] = commonPositiveNumberFieldsNullable;
  });
  return Yup.object().shape(schema);
};

const EmissionsModal = ({
  isOpen,
  setIsOpen,
  species,
  buildingsInArray,
  setBuildingsInArray,
  setBuildingInModal,
  buildingInModal,
}: EmissionsModalProps) => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-emissions-nh3",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);

  const fields = species.map((specie, i) => {
    const name = selectPossibleValues.speciesMapper[specie.value];
    return (
      <NumberField
        label={specie.label}
        name={name}
        key={i}
        unit="kg/an/emplacement"
      />
    );
  });

  const onClose = () => {
    setBuildingInModal(undefined);
    setIsOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <DeprecatedCommonFormSingleEntity
        title="AJOUTER UN BÂTIMENT ET DES ÉMISSIONS DE NH3"
        closeFunction={onClose}
        preventSubmitWithErrors={values => {
          // Checking for an unique name
          if (
            buildingsInArray.some(
              b => b.displayedBuilding.buildingName === values.buildingName
            )
          ) {
            if (
              !buildingInModal ||
              buildingInModal.displayedBuilding.buildingName !==
                values.buildingName
            ) {
              return {
                buildingName: elevageUniqueBuildingMessage,
              };
            }
          }
          // Checking for at least one emission declared
          let declared = false;
          for (const specie of species) {
            const name = selectPossibleValues.speciesMapper[specie.value];
            if (values[name]) {
              declared = true;
              break;
            }
          }
          if (!declared) {
            return {
              buildingName: elevageAtLeastOneEmissionMessage,
            };
          }
          return {};
        }}
        onSubmit={values => {
          const building: PopulatedBuildingInArray = {
            id: values.buildingName,
            displayedBuilding: {
              buildingName: values.buildingName,
              caillesEmission: values.caillesEmission || 0,
              autresVolaillesEmission: values.autresVolaillesEmission || 0,
              canardsEmission: values.canardsEmission || 0,
              dindesDindonsEmission: values.dindesDindonsEmission || 0,
              pintadesEmission: values.pintadesEmission || 0,
              poulesPondeusesEmission: values.poulesPondeusesEmission || 0,
              pouletsDeChairEmission: values.pouletsDeChairEmission || 0,
              poulettesEmission: values.poulettesEmission || 0,
              porceletsEnPostSevrageEmission:
                values.porceletsEnPostSevrageEmission || 0,
              porcsDeProductionEmission: values.porcsDeProductionEmission || 0,
              cochettesEmission: values.cochettesEmission || 0,
              truiesEnMaterniteEmission: values.truiesEnMaterniteEmission || 0,
              truiesEnAttenteSaillieEtGestantesEmission:
                values.truiesEnAttenteSaillieEtGestantesEmission || 0,
              verratsEmission: values.verratsEmission || 0,
            },
          };
          const newBuildingsInArray = buildingsInArray;
          let indexToReplace = newBuildingsInArray.length;
          if (buildingInModal) {
            indexToReplace = newBuildingsInArray.indexOf(buildingInModal);
            setBuildingInModal(undefined);
          }
          newBuildingsInArray[indexToReplace] = building;
          setBuildingsInArray(newBuildingsInArray);
          setIsOpen(false);
        }}
        initialEntity={_.get(buildingInModal, "displayedBuilding", {})}
        validationSchema={validationSchema(species)}
        renderField={() => {
          return (
            <>
              <TextField name="buildingName" label="Nom du bâtiment *" />

              {fields}
            </>
          );
        }}
      />

      <span>* Champs obligatoires</span>
    </CustomModal>
  );
};

export default EmissionsModal;
