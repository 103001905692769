import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_ELEVAGE,
  PATH_AIR_GAZ,
  PATH_AIR_ISDND,
  PATH_AIR_PROCEDES,
  PATH_AIR_SOLVANTS,
  PATH_AIR_SYNTHESE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_DECHET,
  PATH_EAU,
  PATH_INFO_GENERALE,
  PATH_SOL,
  PATH_SPFO,
} from "common/path/path18Now";

export const isAirDisabled = (declarationApi: DeclarationHelpers): boolean => {
  return !(
    declarationApi.getIsActiveSection(PATH_AIR_COMBUSTION) ||
    declarationApi.getIsActiveSection(PATH_AIR_PROCEDES) ||
    declarationApi.getIsActiveSection(PATH_AIR_SOLVANTS) ||
    declarationApi.getIsActiveSection(PATH_AIR_ISDND) ||
    declarationApi.getIsActiveSection(PATH_AIR_ELEVAGE) ||
    declarationApi.getIsActiveSection(PATH_AIR_GAZ) ||
    declarationApi.getIsActiveSection(PATH_AIR_SYNTHESE)
  );
};

export const isCarriereDisabled = (
  declarationApi: DeclarationHelpers
): boolean => {
  return !(
    declarationApi.getIsActiveSection(PATH_CARRIERE_ENVIRONNEMENT) ||
    declarationApi.getIsActiveSection(PATH_CARRIERE_PRODUCTION) ||
    declarationApi.getIsActiveSection(PATH_CARRIERE_SANTE)
  );
};

export const isSectionValidatedIfRequired = (
  declarationApi: DeclarationHelpers,
  sectionPath: string
): boolean => {
  return (
    (declarationApi.getIsActiveSection(sectionPath) &&
      declarationApi.isPathValidatedInDeclaration(sectionPath)) ||
    !declarationApi.getIsActiveSection(sectionPath)
  );
};

export const isEveryAirValidated = (
  declarationApi: DeclarationHelpers
): boolean => {
  return (
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_COMBUSTION) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_PROCEDES) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_SOLVANTS) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_ISDND) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_ELEVAGE) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_GAZ) &&
    isSectionValidatedIfRequired(declarationApi, PATH_AIR_SYNTHESE)
  );
};

export const isEveryCarriereValidated = (
  declarationApi: DeclarationHelpers
): boolean => {
  return (
    isSectionValidatedIfRequired(declarationApi, PATH_CARRIERE_ENVIRONNEMENT) &&
    isSectionValidatedIfRequired(declarationApi, PATH_CARRIERE_PRODUCTION) &&
    isSectionValidatedIfRequired(declarationApi, PATH_CARRIERE_SANTE)
  );
};

export const isEveryRequiredSectionValidated = (
  declarationApi: DeclarationHelpers
): boolean => {
  return (
    isEveryAirValidated(declarationApi) &&
    isEveryCarriereValidated(declarationApi) &&
    isSectionValidatedIfRequired(declarationApi, PATH_INFO_GENERALE) &&
    isSectionValidatedIfRequired(declarationApi, PATH_DECHET) &&
    isSectionValidatedIfRequired(declarationApi, PATH_EAU) &&
    isSectionValidatedIfRequired(declarationApi, PATH_SOL) &&
    isSectionValidatedIfRequired(declarationApi, PATH_SPFO)
  );
};
