import { PATH_INFO_GENERALE } from "./path18Now";

//TYPE ACTIVITE
const PATH_TYPE_ACTIVITE = "/body/typeActivite";
export const PATH_TYPE_ACTIVITE_GLOBAL = `${PATH_TYPE_ACTIVITE}/global`;
export const PATH_TYPE_ACTIVITE_QUOTA = `${PATH_TYPE_ACTIVITE}/quotas`;

//INFO GENERALE
export const PATH_INFO_GENERALE_DOCUMENTS = `${PATH_INFO_GENERALE}/documents`;

/**
 * TypeActiviteDto n'étant pas dans les sections classiques sa route back a un traitement à part.
 * Pour éviter un couplage trop important on utilise une route spécifique pour le routage front
 */
export const ROUTER_PATH_TYPE_ACTIVITE = "/typeActivite";
