import { CombustibleInArray } from "../../BlocCombustibles/utils/types";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import { EmissionsInModale } from "./types";
import { ReferentialSinglePolluants } from "../../../utils/types";

export const getAppareilsForInstallation = (
  allAppareils: AppareilInArray[],
  installationName: string
): AppareilInArray[] =>
  allAppareils.filter(appareil => {
    return appareil.data.nameInstallation === installationName;
  });

export const getAppareilsForCombustible = (
  combustible: CombustibleInArray | null
): AppareilInArray[] => {
  if (combustible === null || combustible.data.appareils === null) {
    return [];
  }

  return combustible.data.appareils;
};

export const getCombustiblesForInstallation = (
  allCombustibles: CombustibleInArray[],
  installationName: string
): CombustibleInArray[] =>
  allCombustibles.filter(combustible => {
    return combustible.data.nameInstallation === installationName;
  });

export const computeCombustibleLabel = (
  combustible: CombustibleInArray
): string => {
  let label = "";
  if (combustible.data.type) {
    label = combustible.data.type.designation;
    if (combustible.data.appareils) {
      label +=
        " (utilisé par " +
        combustible.data.appareils.map(ap => ap.data.nom).join(", ") +
        ")";
    }
  }

  return label;
};

export const getMessageEcartEmission = (
  values: EmissionsInModale,
  referentialSinglePolluants: ReferentialSinglePolluants
): string => {
  if (
    values.combustible &&
    values.combustible.data !== null &&
    values.combustible.data.type &&
    values.substance
  ) {
    const referenceItemCombustibleDto = values.combustible.data.type;
    let bornText: string;
    if (
      referenceItemCombustibleDto.infCO2 !== null &&
      referenceItemCombustibleDto.supCO2 !== null &&
      values.substance.uuid === referentialSinglePolluants.CO2.uuid
    ) {
      bornText = `${referenceItemCombustibleDto.infCO2} et ${referenceItemCombustibleDto.supCO2}`;
    } else if (
      referenceItemCombustibleDto.infSOX !== null &&
      referenceItemCombustibleDto.supSOX !== null &&
      values.substance.uuid === referentialSinglePolluants.SOX.uuid
    ) {
      bornText = `${referenceItemCombustibleDto.infSOX} et ${referenceItemCombustibleDto.supSOX}`;
    } else if (
      referenceItemCombustibleDto.infNOX !== null &&
      referenceItemCombustibleDto.supNOX !== null &&
      values.substance.uuid === referentialSinglePolluants.NOX.uuid
    ) {
      bornText = `${referenceItemCombustibleDto.infNOX} et ${referenceItemCombustibleDto.supNOX}`;
    } else {
      return "";
    }
    return `La valeur du facteur d’émission du ${values.substance.nom} déclarée pour le combustible ${values.combustible.data.type.designation} ne correspond pas aux valeurs usuellement rencontrées qui sont comprises entre ${bornText}`;
  }
  return "";
};
