import React from "react";
import { DeclarationStateDtoStateEnum } from "api/gen";
import { CommonBlocInContextProps } from "common/bloc/types";
import GenericBlocFullContext from "common/bloc/GenericBlocFullContext";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import {
  Declaration23Now,
  useBasicDeclarationHandlers23Now,
  useDeclaration23Now,
  useDeclarationHelpers23Now,
} from "./declarationHooks23Now";
import { DeclarationConstraintViolation19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import { useDeclarationErrorAndWarnings21Now } from "../../../from21/toNow/versionedElements/errorAndWarningDisplayer21Now/utils";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "../../../DeclarationApiContext/utils/types";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

type BlocInContext23NowProps = CommonBlocInContextProps<Declaration23Now>;

export const BlocFullContext23Now = (
  props: BlocInContext23NowProps
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration23Now = useDeclaration23Now;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers23Now;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration23Now
  > = useBasicDeclarationHandlers23Now;
  const getDeclarationState: (
    declarationDto: Declaration23Now
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings21Now;

  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );
  return (
    <GenericBlocFullContext<
      Declaration23Now,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      areGlobalCommentsAllowed={props.areGlobalCommentsAllowed}
      {...props}
    />
  );
};

export default BlocFullContext23Now;
