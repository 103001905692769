import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  arrayBrutOuSec,
  arrayPciUnite,
  arraySurCendres,
  arrayUnite,
  ch4AndBiogazPossibleValues,
  ObjectPciUnite,
} from "./utils/selectPossibleValues";
import {
  biogazMesureSubPartActivated,
  ch4MesureSubPartActivated,
  singleCombustibleOverwriteDependantFields,
  singleCombustibleValidationSchema,
  torchereSubPartActivated,
} from "./validation/validation";
import { computeQuantiteMethaneOxyde } from "./utils/calculus";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  useChoiceSelectFieldGenerator,
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  ReferenceCombustibleDto,
  ReferenceItemCombustibleDto,
  ReferenceItemCombustibleDtoBiomasseEnum,
} from "api/gen";
import MessageInfoField from "common/form/MessageInfoField";
import {
  nullFrequenceMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { isSearchStringInCollection } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { AppareilInArray } from "../blocAppareils/utils/types";
import { CombustibleInModale } from "./utils/types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { FormComponentProps } from "common/form/utils";
import { PCI_CONVERSION_FACTOR } from "./utils/utils";

interface FormCombustibleProps extends FormComponentProps<CombustibleInModale> {
  installationName: string | null;
  combustibleReferential: ReferenceCombustibleDto;
  appareilsInArray: AppareilInArray[];
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  warning: {
    color: "red",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    minHeight: "38px",
    "& > div": {
      justifyContent: "center",
    },
  },
  heightAuto: {
    height: "auto",
  },
});

const FormSingleCombustible = ({
  closeFunction,
  onSubmit,
  initialObject,
  installationName,
  combustibleReferential,
  appareilsInArray,
  isTorchereBiogaz,
  isValorisationBiogaz,
  isEdit,
}: FormCombustibleProps) => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-combustible-combustible-individuelle",
  };
  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);

  const getAppareilsForInstallation = (
    allAppareils: AppareilInArray[],
    installationName: string
  ): AppareilInArray[] => {
    if (!allAppareils) {
      return [];
    }
    return (
      allAppareils.filter(appareil => {
        return appareil.data.nameInstallation === installationName;
      }) || []
    );
  };

  const getAppareilAsOptionProps = (
    installationName: string
  ): OptionPropsWithObject<AppareilInArray, string>[] => {
    const currentAppareils = getAppareilsForInstallation(
      appareilsInArray,
      installationName
    );
    return currentAppareils
      .filter(appareil => appareil.data.nom !== null)
      .map(appareil => {
        // The as is justified because we filtered elem where appareil.data.nom was null on the precedent line.
        const name = appareil.data.nom as string;
        return {
          value: name,
          label: name,
          object: appareil,
        };
      });
  };

  const substancesInChoiceSelectModal: OptionPropsWithObject<
    ReferenceItemCombustibleDto
  >[] = useMemo(() => {
    return combustibleReferential.referenceItemCombustibleDtoList.map(
      (combustible, index) => {
        const optionProps: OptionPropsWithObject<ReferenceItemCombustibleDto> = {
          value: index,
          label: combustible.designation,
          object: combustible,
        };
        return optionProps;
      }
    );
  }, [combustibleReferential]);

  const getCombustible = (designation: string) => {
    return combustibleReferential.referenceItemCombustibleDtoList.find(
      combustible => {
        return combustible.designation === designation;
      }
    );
  };

  const isPciInBounds = (
    sup: number,
    inf: number,
    value: number,
    masse: number | null | undefined,
    pciUnite: OptionPropsWithObject<ObjectPciUnite>
  ) => {
    const valueInGJt = pciUnite.object.calcul(value, masse);
    return inf <= valueInGJt && valueInGJt <= sup;
  };

  const shouldDisplayPreciserPci = (
    values: CombustibleInModale,
    combustible: ReferenceItemCombustibleDto | undefined
  ): boolean => {
    return (
      (values &&
        values.consommation !== null &&
        values.unite &&
        values.pciValeur !== null &&
        values.pciUnite &&
        combustible &&
        values.consommation > 0 &&
        (values.unite.object.isUnitMasse ||
          (values.unite.object.isUnitVolume && values.masse)) &&
        combustible.pciReferenceInf &&
        combustible.pciReferenceSup &&
        !isPciInBounds(
          combustible.pciReferenceSup,
          combustible.pciReferenceInf,
          values.pciValeur,
          values.masse,
          values.pciUnite
        )) ||
      false
    );
  };

  const shouldDisplayBiogazEcart = (
    values: CombustibleInModale,
    combustible: ReferenceItemCombustibleDto | undefined
  ): boolean => {
    if (values.type && values.biogazFraction !== null) {
      if (combustible && combustible.biomasse) {
        switch (combustible.biomasse) {
          case ReferenceItemCombustibleDtoBiomasseEnum.NON:
            return values.biogazFraction > 0;
          case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
            return values.biogazFraction < 100;
          case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
            return false;
          default:
            throw new ShouldNotHappen(combustible.biomasse);
        }
      }
    }

    return false;
  };

  const getMessagePreciserPci = (
    values: CombustibleInModale,
    combustible: ReferenceItemCombustibleDto | undefined
  ) => {
    if (values.pciValeur !== null && values.pciUnite && values.type) {
      if (combustible) {
        return `La valeur du PCI déclarée pour ce combustible (${
          values.pciValeur
        } ${values.pciUnite.label} soit ${values.pciUnite.object.calcul(
          values.pciValeur,
          values.masse
        )} GJ/t) ne correspond pas aux valeurs usuellement rencontrées qui sont comprises entre ${
          combustible.pciReferenceInf
        } GJ/t et ${combustible.pciReferenceSup} GJ/t.`;
      }
      return "";
    }
    return "";
  };

  const getMessageBiogazEcart = (
    values: CombustibleInModale,
    combustible: ReferenceItemCombustibleDto | undefined
  ) => {
    if (values.type && values.biogazFraction !== null) {
      if (combustible && combustible.biomasse) {
        switch (combustible.biomasse) {
          case ReferenceItemCombustibleDtoBiomasseEnum.NON:
            return `${combustible.designation} est un combustible non biomasse. La fraction biomasse déclarée devrait être 0%.`;
          case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
            return `${combustible.designation} est un combustible totalement biomasse. La fraction biomasse déclarée devrait être 100%.`;
          case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
            return `${combustible.designation} est un combustible partiellement biomasse. La fraction biomasse déclarée devrait être comprise entre 0 et 100%.`;
          default:
            throw new ShouldNotHappen(combustible.biomasse);
        }
      }
      return "";
    }
    return "";
  };

  const isUnitPCIEnergyByMasse = (unitPCI: string | null) => {
    if (!unitPCI) {
      return false;
    }
    return arrayPciUnite.some(
      unit => unit.label === unitPCI && unit.object.isEnergyByMasse
    );
  };

  const isMasseRequired = (values: CombustibleInModale) => {
    if (values.consommation && values.unite && values.pciUnite) {
      return (
        values.consommation > 0 &&
        ((values.unite.object.isUnitMasse &&
          !isUnitPCIEnergyByMasse(values.pciUnite.label)) ||
          (values.unite.object.isUnitVolume &&
            isUnitPCIEnergyByMasse(values.pciUnite.label)))
      );
    }
    return false;
  };

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN COMBUSTIBLE"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: CombustibleInModale, formikBag) => {
        values.quantiteMethaneOxyde = computeQuantiteMethaneOxyde(values);
        singleCombustibleOverwriteDependantFields(
          values,
          isTorchereBiogaz,
          isValorisationBiogaz
        );
        if (values.type) {
          if (
            !shouldDisplayPreciserPci(values, getCombustible(values.type.label))
          ) {
            values.pciEcart = null;
          }
          if (
            !shouldDisplayBiogazEcart(values, getCombustible(values.type.label))
          ) {
            values.biogazEcart = null;
          }
        }
        onSubmit(values, formikBag);
      }}
      initialEntity={initialObject}
      validationSchema={singleCombustibleValidationSchema(
        isTorchereBiogaz,
        isValorisationBiogaz
      )}
      validate={values => {
        let errors = {};
        if (
          values.type &&
          shouldDisplayPreciserPci(values, getCombustible(values.type.label)) &&
          !values.pciEcart
        ) {
          errors = { ...errors, pciEcart: requiredMessage };
        }
        if (
          values.type &&
          shouldDisplayBiogazEcart(values, getCombustible(values.type.label)) &&
          !values.biogazEcart
        ) {
          errors = { ...errors, biogazEcart: requiredMessage };
        }
        if (isMasseRequired(values) && values.masse === null) {
          errors = { ...errors, masse: requiredMessage };
        }
        if (values.unite && !values.unite.object.isEnergy) {
          if (values.pciValeur === null) {
            errors = { ...errors, pciValeur: requiredMessage };
          }
          if (values.pciUnite === null) {
            errors = { ...errors, pciUnite: requiredMessage };
          }
          if (values.pciProvenance === null) {
            errors = { ...errors, pciProvenance: requiredMessage };
          }
        }
        return errors;
      }}
      renderField={({ values }) => {
        let combustible;
        const isGazNaturel =
          values.type && values.type.object.codeCombustible === "301";
        const unite = isGazNaturel
          ? arrayUnite
          : Array.from(arrayUnite.filter(u => !u.object.isPCS));
        const isPCS = values.unite && values.unite.object.isPCS;
        const isNotMandatory = values.unite && values.unite.object.isEnergy;
        if (values.type) {
          combustible = getCombustible(values.type.label);
        }
        if (values.consommation && isGazNaturel && isPCS) {
          values.consommationPCI = values.consommation * PCI_CONVERSION_FACTOR;
        } else {
          values.consommationPCI = null;
        }
        return (
          <>
            {initialObject.type && (
              <MessageInfoField
                message={
                  "Après avoir modifié ce combustible, pensez à vérifier la cohérence de toutes les émissions liées a celui-ci"
                }
                additionalClassname={classes.warning}
              />
            )}
            <WrappedChoiceSelectModale
              name="type"
              label="Type de combustible *"
              title="TYPE DE COMBUSTIBLE"
              header={[
                "Code NAPFUE",
                "Désignation",
                "PCI réference (GJ/t)",
                "Biomasse",
              ]}
              linesData={substancesInChoiceSelectModal}
              formatLine={combustible => [
                combustible.object.codeCombustible,
                combustible.object.designation,
                combustible.object.pciReference !== null
                  ? computeNumberWithSeparator(combustible.object.pciReference)
                  : "",
                combustible.object.biomasse,
              ]}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData.object.codeCombustible,
                    lineData.object.designation,
                  ],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData.object.designation}
              commonProps={commonProps}
              preferredColWidths={[80, 300, 80, 80]}
            />
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation *"
              value={installationName}
              disabled
            />
            <NumberField
              name="consommation"
              label="Consommation annuelle *"
              unit=""
              tooltipContent="Pour les installations concernées par la déclaration de flux sortants, il convient d'utiliser des valeurs négatives."
            />
            <ChoiceSelectField
              name="unite"
              label="Unité *"
              options={unite}
              isMulti={false}
            />
            {values.consommationPCI && (
              <NumberField
                disabled={true}
                name="consommationPCI"
                label="Consommation annuelle en PCI"
                unit=""
              />
            )}
            <ChoiceSelectField
              name="appareils"
              label="Appareil(s) consommateur(s) *"
              isMulti
              options={getAppareilAsOptionProps(installationName || "")}
            />
            <NumberField
              name="masse"
              label={`Masse volumique du combustible${
                isMasseRequired(values) ? " *" : ""
              }`}
              unit="kg/l = t/m³"
              tooltipContent="L'application réalisant un calcul automatique de vos émissions, il est obligatoire de remplir ce champ si vous utilisez, dans le tableau des «Emissions de l'installation», des unités de volume ou de contenance pour la caractérisation des émissions."
            />
            <NumberField
              name="eau"
              label="Teneur en eau"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            <NumberField
              name="carbone"
              label="Teneur en carbone"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            <NumberField
              name="soufre"
              label="Teneur en soufre"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            <ChoiceSelectField
              name="surCendres"
              label="Sur cendres ou hors cendres"
              isMulti={false}
              options={arraySurCendres}
            />
            <NumberField
              name="cendres"
              label="Teneur en cendre"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            <NumberField
              name="chlore"
              label="Teneur en chlore"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            <NumberField
              name="pciValeur"
              label={
                isNotMandatory
                  ? "PCI (Pouvoir Calorifique Inférieur)"
                  : "PCI (Pouvoir Calorifique Inférieur) *"
              }
              unit=""
              tooltipContent="Si le combustible renseigné est le gaz naturel, alors il peut être nécessaire de convertir le PCS (mentionné sur les factures) en PCI. La formule à utiliser est la suivante :  « Consommation (MWhPCI) » = « Consommation (MWhPCS) » x « ratio PCI/PCS ». Le ratio PCI/PCS pour le gaz naturel est 0,901."
            />
            <ChoiceSelectField
              name="pciUnite"
              label={isNotMandatory ? "Unité PCI" : "Unité PCI *"}
              isMulti={false}
              options={arrayPciUnite}
            />
            {shouldDisplayPreciserPci(values, combustible) && (
              <>
                <MessageInfoField
                  message={getMessagePreciserPci(values, combustible)}
                  additionalClassname={classes.heightAuto}
                />
                <TextField name="pciEcart" label="Préciser écart PCI *" />
              </>
            )}
            <ChoiceSelectField
              name="pciBrut"
              label="Brut ou sec"
              isMulti={false}
              options={arrayBrutOuSec}
            />
            <TextField
              name="pciProvenance"
              label={
                isNotMandatory ? "Provenance du PCI" : "Provenance du PCI *"
              }
            />
            <NumberField
              name="biogazFraction"
              label="Fraction de la biomasse (%) *"
              unit="%"
            />
            {shouldDisplayBiogazEcart(values, combustible) && (
              <>
                <MessageInfoField
                  message={getMessageBiogazEcart(values, combustible)}
                  additionalClassname={classes.heightAuto}
                />
                <TextField
                  name="biogazEcart"
                  label="Préciser écart fraction biomasse *"
                />
              </>
            )}
            {torchereSubPartActivated(
              values.type,
              isTorchereBiogaz,
              isValorisationBiogaz
            ) && (
              <>
                <NumberField
                  name="biogazDebit"
                  label="Débit du biogaz *"
                  unit="m³/h"
                />
                <ChoiceSelectField
                  name="biogazMesure"
                  label="Méthode d'estimation du débit *"
                  isMulti={false}
                  options={ch4AndBiogazPossibleValues}
                />
                {biogazMesureSubPartActivated(values.biogazMesure) && (
                  <>
                    <NumberField
                      name="biogazFrequence"
                      label="Fréquence de la mesure du débit"
                      tooltipContent="Préciser Fréquence de la mesure du débit est sur mesure en continu ou sur au moins une fois par semaine ou au moins une fois par mois ou au moins une fois par trimestre ou au moins une fois par an."
                      unit=""
                    />
                    {values.biogazFrequence == null && (
                      <MessageInfoField
                        message={nullFrequenceMessage}
                        additionalClassname={classes.heightAuto}
                      />
                    )}
                  </>
                )}
                <NumberField
                  name="biogazDuree"
                  label="Temps de fonctionnement *"
                  unit="h/an"
                />
                <NumberField
                  name="ch4Teneur"
                  label={() => (
                    <>
                      Teneur en CH<sub>4</sub>
                    </>
                  )}
                  unit="%"
                />
                <ChoiceSelectField
                  name="ch4Mesure"
                  label={() => (
                    <>
                      Méthode d'estimation de la teneur en CH<sub>4</sub> *
                    </>
                  )}
                  isMulti={false}
                  options={ch4AndBiogazPossibleValues}
                />
                {ch4MesureSubPartActivated(values.ch4Mesure) && (
                  <>
                    <NumberField
                      name="ch4Frequence"
                      label={() => (
                        <>
                          Fréquence de la mesure en CH<sub>4</sub>
                        </>
                      )}
                      tooltipContent={
                        <span>
                          Préciser Fréquence de la mesure de la teneur en CH
                          <sub>4</sub> est sur mesure en continu ou sur au moins
                          une fois par semaine ou au moins une fois par mois ou
                          au moins une fois par trimestre ou au moins une fois
                          par an.
                        </span>
                      }
                      unit=""
                    />
                    {values.ch4Frequence == null && (
                      <MessageInfoField
                        message={nullFrequenceMessage}
                        additionalClassname={classes.heightAuto}
                      />
                    )}
                  </>
                )}
                <DummyNumberField
                  name="quantiteMethaneOxyde"
                  label="Quantité de méthane oxydé par combustion *"
                  unit="1000 m³"
                  value={computeQuantiteMethaneOxyde(values)}
                  disabled
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleCombustible;
