import React, { useCallback } from "react";
import { ButtonProps } from "./type";
import Button from "./index";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  fileLoader: {
    height: "100%",
    outline: "none",
  },
});

interface FileLoaderProps extends ButtonProps {
  onFileHandler: (file: File) => void;
  onReject?: () => void;
  acceptedFileType?: string | string[];
  onClick?: undefined; // a bit hacky, but adds some utils safety. Found on https://stackoverflow.com/a/51162199/7059810
}

const FileLoader = ({
  onFileHandler,
  acceptedFileType,
  onReject,
  additionalStyle,
  additionalClassname,
  isDisabled,
  ...otherButtonProps
}: FileLoaderProps): React.ReactElement => {
  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach((file: File) => onFileHandler(file));
    },
    [onFileHandler]
  );

  const classes = useStyles();

  const { open, getRootProps, getInputProps } = useDropzone({
    accept: acceptedFileType,
    multiple: false,
    onDropRejected: onReject,
    onDropAccepted: onDrop,
  });

  return (
    <div
      {...getRootProps()}
      className={additionalClassname}
      style={additionalStyle}
      onClick={e => e.preventDefault()}
    >
      <input {...getInputProps()} disabled={isDisabled} />
      <Button
        {...otherButtonProps}
        onClick={open}
        isDisabled={isDisabled}
        type="button"
        additionalClassname={classes.fileLoader}
      />
    </div>
  );
};

export default FileLoader;
