import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonObjectFields,
  commonStringFields,
  containsPipe,
} from "common/declarant/formik/formikHelper";
import {
  PopulatedSubstanceExtractionInArray,
  SubstanceExtractionProps,
} from "../BlocActiviteExtractive/utils/types";
import {
  PopulatedRecyclingProductInArray,
  RecyclingProductProps,
} from "../BlocActiviteRecyclage/utils/types";
import { Nullable } from "common/utils/types";
import { FamilyProductionProps, FamilyRecyclingProps } from "./types";
import {
  noPipeMessage,
  productionFamiliesCombinationSubstanceMessage,
  productionFamiliesCominationProductMessage,
  requiredMessage,
  tooLongMessage,
} from "common/declarant/formik/formikMessages";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const familiesProductionValidationSchema = (additionalProps: object) => {
  return Yup.object().shape({
    ...additionalProps,

    usageFamily: commonObjectFields,

    usageFamilyPrecision: Yup.string().when("usageFamily", {
      is: (
        usageFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageProductionDto
        >
      ) => usageFamily && usageFamily.object.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    usageSubFamily: Yup.object().when("usageFamily", {
      is: (
        usageFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageProductionDto
        >
      ) =>
        usageFamily &&
        usageFamily.object.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    usageSubFamilyPrecision: Yup.string().when("usageSubFamily", {
      is: (
        usageSubFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageProductionDto
        >
      ) => usageSubFamily && usageSubFamily.object.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    usageSubFamiliesLvl2: Yup.array().when("usageSubFamily", {
      is: (
        usageSubFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageProductionDto
        >
      ) =>
        usageSubFamily &&
        usageSubFamily.object.referenceItemFamilleUsageDtos.length > 0,
      then: commonArrayOfObjectFields,
      otherwise: Yup.array().nullable(),
    }),

    usageSubFamiliesLvl2Precisions: Yup.array().of(
      Yup.object().shape({
        precision: Yup.string()
          .nullable()
          .test(
            "Should not contain a pipe",
            noPipeMessage,
            value =>
              value !== null && value !== undefined && !containsPipe(value)
          ),
      })
    ),
  });
};

export const familiesRecyclingValidationSchema = (additionProps: object) => {
  return Yup.object().shape({
    ...additionProps,

    usageFamily: commonObjectFields,

    usageFamilyPrecision: Yup.string().when("usageFamily", {
      is: (
        usageFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageRecyclageDto
        >
      ) => usageFamily && usageFamily.object.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    usageSubFamily: Yup.object().when("usageFamily", {
      is: (
        usageFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageRecyclageDto
        >
      ) =>
        usageFamily &&
        usageFamily.object.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    usageSubFamilyPrecision: Yup.string().when("usageSubFamily", {
      is: (
        usageSubFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageRecyclageDto
        >
      ) => usageSubFamily && usageSubFamily.object.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    usageSubFamiliesLvl2: Yup.array().when("usageSubFamily", {
      is: (
        usageSubFamily: OptionPropsWithObject<
          ReferenceItemFamilleUsageRecyclageDto
        >
      ) =>
        usageSubFamily &&
        usageSubFamily.object.referenceItemFamilleUsageDtos.length > 0,
      then: commonArrayOfObjectFields,
      otherwise: Yup.array().nullable(),
    }),

    usageSubFamiliesLvl2Precisions: Yup.array().of(
      Yup.object().shape({
        precision: Yup.string()
          .nullable()
          .test(
            "Should not contain a pipe",
            noPipeMessage,
            value =>
              value !== null && value !== undefined && !containsPipe(value)
          ),
      })
    ),
  });
};

const validateRequieredPrecisionLvl2Recycling = (
  values: Nullable<FamilyRecyclingProps>
): { [k: string]: string } => {
  const errors: { [k: string]: string } = {};
  if (!values.usageSubFamiliesLvl2) {
    return errors;
  }
  values.usageSubFamiliesLvl2
    .filter(
      subFamilyLvl2 => subFamilyLvl2.object && subFamilyLvl2.object.precision
    )
    .forEach((_, i) => {
      if (
        !values.usageSubFamiliesLvl2Precisions ||
        !values.usageSubFamiliesLvl2Precisions[i] ||
        !values.usageSubFamiliesLvl2Precisions[i].precision
      ) {
        errors[
          `usageSubFamiliesLvl2Precisions.${i}.precision`
        ] = requiredMessage;
      }
    });
  return errors;
};

const validateRequieredPrecisionLvl2Production = (
  values: Nullable<FamilyProductionProps>
): { [k: string]: string } => {
  const errors: { [k: string]: string } = {};
  if (!values.usageSubFamiliesLvl2) {
    return errors;
  }
  values.usageSubFamiliesLvl2
    .filter(
      subFamilyLvl2 => subFamilyLvl2.object && subFamilyLvl2.object.precision
    )
    .forEach((_, i) => {
      if (
        !values.usageSubFamiliesLvl2Precisions ||
        !values.usageSubFamiliesLvl2Precisions[i] ||
        !values.usageSubFamiliesLvl2Precisions[i].precision
      ) {
        errors[
          `usageSubFamiliesLvl2Precisions.${i}.precision`
        ] = requiredMessage;
      }
    });
  return errors;
};

export const validateFamiliesForSubstanceExtractionInArray = (
  values: Nullable<SubstanceExtractionProps>,
  substancesExtractionInArray: PopulatedSubstanceExtractionInArray[]
): object | void => {
  const errorsRequired = validateRequieredPrecisionLvl2Production(values);
  if (Object.keys(errorsRequired).length > 0) {
    return errorsRequired;
  }

  for (const substance of substancesExtractionInArray) {
    if (substance.displayedSubstance.substance !== values.substance) {
      continue;
    }

    if (
      values.usageFamily !== substance.displayedSubstance.usageFamily ||
      values.usageSubFamily !== substance.displayedSubstance.usageSubFamily
    ) {
      continue;
    }

    return {
      usageFamily: productionFamiliesCombinationSubstanceMessage,
      usageSubFamily: productionFamiliesCombinationSubstanceMessage,
    };
  }
};

export const validateFamiliesForRecyclingProductsInArray = (
  values: Nullable<RecyclingProductProps>,
  recyclingProductsInArray: PopulatedRecyclingProductInArray[]
): object | void => {
  const errorsRequired = validateRequieredPrecisionLvl2Recycling(values);
  if (Object.keys(errorsRequired).length > 0) {
    return errorsRequired;
  }

  for (const product of recyclingProductsInArray) {
    if (product.displayedProduct.nature !== values.nature) {
      continue;
    }

    if (
      values.usageFamily !== product.displayedProduct.usageFamily ||
      values.usageSubFamily !== product.displayedProduct.usageSubFamily
    ) {
      continue;
    }

    return {
      usageFamily: productionFamiliesCominationProductMessage,
      usageSubFamily: productionFamiliesCominationProductMessage,
    };
  }
};
