import {
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
} from "api/gen";
import { Declaration2121 } from "./declarationHooks2121";

export const updateHandlerBilanTotal2121 = (
  declaration: Declaration2121,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration2121 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddressEmissions2121 = (
  declaration: Declaration2121,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration2121 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
