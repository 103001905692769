import {
  FileDto,
  QuotasBlocVerificationEmissionsDto20Now,
  QuotasEmissionsVerificationDto20Now,
  QuotasInstallationDto20Now,
} from "api/gen/api";
import _ from "lodash";
import {
  BlocVerificationFormValues,
  BlocVerificationFormValuesProps,
} from "./types";
import { Declaration21Now } from "../../../versionedElements/declarationHooks21Now";

const QuotasEmissionsVerificationDto20NowToBlocVerificationFormValuesProps = (
  quotasVerification: QuotasEmissionsVerificationDto20Now
): BlocVerificationFormValuesProps => {
  const verificationWithTooMuchKey = {
    ...quotasVerification,
    dateDerniereVisite: quotasVerification.dateDerniereVisite
      ? new Date(quotasVerification.dateDerniereVisite)
      : null,
  };
  const { rapport, ...frontVerification } = verificationWithTooMuchKey;
  return frontVerification;
};

const blocVerificationFormValuesPropsToQuotasEmissionsVerificationDto20Now = (
  blocVerificationFormValuesPropsParam: BlocVerificationFormValuesProps,
  rapport: FileDto | null
): QuotasEmissionsVerificationDto20Now => {
  const blocVerificationFormValuesProps = _.cloneDeep(
    blocVerificationFormValuesPropsParam
  );
  return {
    ...blocVerificationFormValuesProps,
    dateDerniereVisite: blocVerificationFormValuesProps.dateDerniereVisite
      ? blocVerificationFormValuesProps.dateDerniereVisite
          .toISOString()
          .split(".")[0] + "Z"
      : null,
    rapport,
  };
};

export const dtoToBlocValues = (
  dto: QuotasBlocVerificationEmissionsDto20Now,
  installationsInArray: QuotasInstallationDto20Now[]
): BlocVerificationFormValues => {
  return {
    verifications: installationsInArray.map(installation => {
      const verification: QuotasEmissionsVerificationDto20Now | null =
        dto.verifications.find(v => v.nimDynId === installation.id) || null;

      if (verification) {
        return QuotasEmissionsVerificationDto20NowToBlocVerificationFormValuesProps(
          verification
        );
      } else {
        return {
          id: installation.id,
          nimDynId: installation.id,
          organisme: null,
          referenceDernierPlanSurveillance: null,
          dispenseVisiteSite: false,
          conditionDispense: null,
          precisionDispense: null,
          dateDerniereVisite: null,
          rapportAmeliorationRequis: false,
          conclusion: null,
          motifsStatisfaisantAvecRemarque: [],
          motifsNonSatisfaisant: [],
        };
      }
    }),
  };
};

export const blocValuesToDto = (
  verificationsForm: BlocVerificationFormValues,
  declaration: Declaration21Now
): QuotasBlocVerificationEmissionsDto20Now => {
  return {
    verifications: verificationsForm.verifications.map(installationForm => {
      const declarationInstallationVerification = declaration.body.sections.quotas.emissions.blocVerification.verifications.find(
        verification => verification.nimDynId === installationForm.nimDynId
      );
      const rapport = declarationInstallationVerification
        ? declarationInstallationVerification.rapport
        : null;
      return blocVerificationFormValuesPropsToQuotasEmissionsVerificationDto20Now(
        installationForm,
        rapport
      );
    }),
  };
};
