import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { InstallationInArray } from "./utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface InstallationArrayProps {
  installationsInPage: InstallationInArray[];
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  setInstallationInModale: Dispatch<SetStateAction<InstallationInArray | null>>;
  setInstallationModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationInstallationPath: string;
}

const InstallationArray = ({
  installationsInPage,
  setInstallationsInPage,
  setInstallationInModale,
  setInstallationModaleOpen,
  isValidated,
  validationInstallationPath,
}: InstallationArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressInstallation(singleInstallation: InstallationInArray) {
    setInstallationsInPage(elderInstallations => {
      return elderInstallations.filter(elderInstallationInPage => {
        return singleInstallation.data.id !== elderInstallationInPage.data.id;
      });
    });
  }

  const computeSingleInstallationLine = (
    installation: InstallationInArray,
    classes: ClassesType<"error" | "full">
  ): React.ReactElement[] => {
    const editAction = () => {
      setInstallationInModale(installation);
      setInstallationModaleOpen(true);
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      installation.data.nom || undefined,
      isValidated,
      !_.isEmpty(installation.errors)
    );

    return [
      computeWrappedCellWithError(
        firstCellContent,
        classes,
        installation.errors && installation.errors.nom
      ),
      computeWrappedCellWithError(
        installation.data.type !== null ? installation.data.type.label : "",
        classes,
        installation.errors && installation.errors.type
      ),
      computeWrappedCellWithError(
        installation.data.heure !== null
          ? computeNumberWithSeparator(installation.data.heure)
          : "",
        classes,
        installation.errors && installation.errors.heure
      ),
      computeWrappedCellWithError(
        installation.data.volumeActivite !== null
          ? computeNumberWithSeparator(installation.data.volumeActivite)
          : "",
        classes,
        installation.errors && installation.errors.volumeActivite
      ),
      computeWrappedCellWithError(
        <p title={installation.data.unite || undefined}>
          {installation.data.unite}
        </p>,
        classes,
        installation.errors && installation.errors.unite
      ),
      computeWrappedCellWithError(
        <p title={installation.data.typeProduit || undefined}>
          {installation.data.typeProduit}
        </p>,
        classes,
        installation.errors && installation.errors.typeProduit
      ),
      computeWrappedCellWithError(
        installation.data.quantiteChaleur !== null
          ? computeNumberWithSeparator(installation.data.quantiteChaleur)
          : "",
        classes,
        installation.errors && installation.errors.quantiteChaleur
      ),
      computeWrappedCellWithError(
        installation.data.quantiteElectricite !== null
          ? computeNumberWithSeparator(installation.data.quantiteElectricite)
          : "",
        classes,
        installation.errors && installation.errors.quantiteElectricite
      ),
      computeWrappedCellWithError(
        installation.data.rendementChaleur !== null
          ? computeNumberWithSeparator(installation.data.rendementChaleur)
          : "",
        classes,
        installation.errors && installation.errors.rendementChaleur
      ),
      computeWrappedCellWithError(
        installation.data.rendementElectricite !== null
          ? computeNumberWithSeparator(installation.data.rendementElectricite)
          : "",
        classes,
        installation.errors && installation.errors.rendementElectricite
      ),
      // todo: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={
          "Êtes vous sûr de vouloir supprimer cette installation ?"
        }
        handlerSuppress={() => {
          suppressInstallation(installation);
        }}
        isValidated={isValidated}
        commentPath={`${validationInstallationPath}/${installation.data.id}`}
      />,
    ];
  };

  const arrayPolluantsLines = installationsInPage.map(
    singleInstallationInPage => {
      return computeSingleInstallationLine(singleInstallationInPage, classes);
    }
  );

  return (
    <CommonEntityTable
      header={[
        <p>Nom</p>,
        <p>Type d'installation</p>,
        <p>Heures de fonctionnement</p>,
        <p>Volume d'activité</p>,
        <p>Unité</p>,
        <p>Type(s) de produit(s)</p>,
        <p>Quantité de chaleur produite (GJ)</p>,
        <p>Quantité d'électricité produite (GJ)</p>,
        <p>Rendement de chaleur (%)</p>,
        <p>Rendement d'électricité (%)</p>,
        <p>Actions</p>,
      ]}
      lines={arrayPolluantsLines}
      preferredHeaderHeight={75}
      preferredColWidths={[
        200,
        130,
        130,
        130,
        130,
        130,
        150,
        150,
        150,
        150,
        120,
      ]}
    />
  );
};

export default InstallationArray;
