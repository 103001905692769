import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import Button from "common/button";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { AlternativeEmissionInArray } from "../types";
import { PATH_QUOTAS_EMISSIONS_BLOC_METHODES_ALTERNATIVES } from "common/path/path20Now";
import { useConfirmationModale } from "common/modale/hooks";
import Row from "common/presentational/Row";
import _ from "lodash";

interface AlternativeEmissionsArrayProps {
  alternativeEmissionsInArray: AlternativeEmissionInArray[];
  setAlternativeEmissionsInArray: (
    callback: (
      previousEmissions: AlternativeEmissionInArray[]
    ) => AlternativeEmissionInArray[]
  ) => void;
  onEdit: (emission: AlternativeEmissionInArray) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  array: {
    marginTop: "20px",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const AlternativeEmissionsArray = ({
  alternativeEmissionsInArray,
  setAlternativeEmissionsInArray,
  isDisabled,
  onEdit,
}: AlternativeEmissionsArrayProps): React.ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const header: React.ReactElement[] = [
    <p>Méthode</p>,
    <p>Gaz émis</p>,
    <p>NIM</p>,
    <p>Émission de CO2 d'origine non biomasse (tCO2e/an)</p>,
    <p>Émission de CO2 d'origine biomasse (tCO2e/an)</p>,
    <p>Émission de N2O ou PFC (tCO2e/an)</p>,
    <p>Actions</p>,
  ];

  const computeLine = (
    emission: AlternativeEmissionInArray
  ): React.ReactElement[] => {
    const firstCellContent = computeFirstLineContentCell(
      () => onEdit(emission),
      emission.data.methodDescription || undefined,
      isDisabled,
      !_.isEmpty(emission.errors)
    );

    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p>{emission.data.gas && emission.data.gas.toString()}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.nimInstallation && emission.data.nimInstallation.nim}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.fossilEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.fossilEmission
              )
            : ""}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.biomassEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.biomassEmission
              )
            : ""}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.otherEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.otherEmission
              )
            : ""}
        </p>,
        classes
      ),
      <CommonEntityButton
        handlerEdit={() => onEdit(emission)}
        commentPath={`${PATH_QUOTAS_EMISSIONS_BLOC_METHODES_ALTERNATIVES}/${emission.data.id}`}
        handlerSuppress={() =>
          setAlternativeEmissionsInArray(() =>
            alternativeEmissionsInArray.filter(
              e => e.data.id !== emission.data.id
            )
          )
        }
        isValidated={isDisabled}
        suppressMessage="Êtes-vous sûr de vouloir supprimer ces informations ?"
      />,
    ];
  };

  return (
    <>
      <CommonEntityTable
        header={header}
        preferredHeaderHeight={100}
        lines={alternativeEmissionsInArray.map(computeLine)}
        additionalClassName={classes.array}
        preferredColWidths={[100, 80, 150, 80, 80, 80, 100]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() =>
            openConfirmationModale(
              "Êtes-vous sûr de vouloir supprimer toutes les informations de ce tableau ?",
              () => setAlternativeEmissionsInArray(() => [])
            )
          }
        />
      </Row>
    </>
  );
};

export default AlternativeEmissionsArray;
