import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  InstallationInArray,
  InstallationInModale,
  InstallationSelectOptions,
} from "../types";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  preventSubmitWithErrors,
  singleInstallationValidationSchema,
} from "./validation";
import {
  useBooleanCheckBoxGenerator,
  useChoiceSelectFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { QuotasInstallationDto1819CategorieEnum } from "api/gen";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
});

interface FormSingleInstallationProps {
  onClose: () => void;
  isEdit: boolean;
  onSubmit: (
    values: InstallationInModale,
    formikActions: FormikActions<InstallationInModale>
  ) => void;
  initialInstallation: InstallationInModale;
  installationsInArray: InstallationInArray[];
  possibleValues: InstallationSelectOptions;
}

const FormSingleInstallation = ({
  onClose,
  onSubmit,
  initialInstallation,
  installationsInArray,
  isEdit,
  possibleValues,
}: FormSingleInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-quotas-installation-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN NIM"
      closeFunction={onClose}
      onSubmit={onSubmit}
      isEdit={isEdit}
      initialEntity={initialInstallation}
      validationSchema={singleInstallationValidationSchema}
      validate={values =>
        preventSubmitWithErrors(
          values,
          installationsInArray,
          initialInstallation
        )
      }
      renderField={({ values }) => (
        <>
          <TextField
            name="nim"
            label="Numéro NIM *"
            tooltipContent={
              <ul className={classes.list}>
                <li>FR + 15 chiffres (ex : FR000000000000171)</li>
                <li>
                  FR-new- + 8 chiffres (correspondant au code établissement
                  actuel de GEREP sans le point) (ex : FR-new-05803742)
                </li>
              </ul>
            }
          />

          <ChoiceSelectField
            name="categorie"
            label="Catégorie de l'installation *"
            isMulti={false}
            options={possibleValues.categories}
          />

          {values.categorie &&
            values.categorie.label ===
              QuotasInstallationDto1819CategorieEnum.A && (
              <CheckBox
                name="faiblementEmettrice"
                label="Installation faiblement émettrice"
              />
            )}

          <ChoiceSelectField
            name="activites"
            label="Activités de l'installation (1 à 5) *"
            options={possibleValues.activites}
            isMulti
          />
          <p>* Champs obligatoires</p>
        </>
      )}
    />
  );
};

export default FormSingleInstallation;
