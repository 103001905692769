import { ActivityValues, CoordonneesDepartementMap } from "./types";
import {
  ActiviteDto2020SystemeCoordonneesEnum,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
} from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const getAssociatedSystemCoordinates = (
  values: ActivityValues,
  coordonneesDepartementMap: CoordonneesDepartementMap
): ReferenceItemCoordonneesDepartementDto[] => {
  const numeroDepartement: string | null =
    values.installationCity && values.installationCity.numeroDepartement;

  if (numeroDepartement === null) {
    return [];
  }

  const possibleSystems: Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  > = coordonneesDepartementMap.get(numeroDepartement) || new Map();

  return Array.from(possibleSystems.values());
};

export const transformActiviteToReferenceEnum = (
  activiteDtoSystem: ActiviteDto2020SystemeCoordonneesEnum
): ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum => {
  switch (activiteDtoSystem) {
    case ActiviteDto2020SystemeCoordonneesEnum.WGS84:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.WGS84;
    case ActiviteDto2020SystemeCoordonneesEnum.LAMBERT93:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.LAMBERT93;
    case ActiviteDto2020SystemeCoordonneesEnum.UTM_20N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_20N;
    case ActiviteDto2020SystemeCoordonneesEnum.UTM_21N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_21N;
    case ActiviteDto2020SystemeCoordonneesEnum.UTM_22N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_22N;
    case ActiviteDto2020SystemeCoordonneesEnum.UTM_38S:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_38S;
    case ActiviteDto2020SystemeCoordonneesEnum.UTM_40S:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_40S;
    default:
      throw new ShouldNotHappen(activiteDtoSystem);
  }
};

export const transformReferenceToActiviteEnum = (
  referenceItemSystem: ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum
): ActiviteDto2020SystemeCoordonneesEnum => {
  switch (referenceItemSystem) {
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.WGS84:
      return ActiviteDto2020SystemeCoordonneesEnum.WGS84;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.LAMBERT93:
      return ActiviteDto2020SystemeCoordonneesEnum.LAMBERT93;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_20N:
      return ActiviteDto2020SystemeCoordonneesEnum.UTM_20N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_21N:
      return ActiviteDto2020SystemeCoordonneesEnum.UTM_21N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_22N:
      return ActiviteDto2020SystemeCoordonneesEnum.UTM_22N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_38S:
      return ActiviteDto2020SystemeCoordonneesEnum.UTM_38S;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_40S:
      return ActiviteDto2020SystemeCoordonneesEnum.UTM_40S;
    default:
      throw new ShouldNotHappen(referenceItemSystem);
  }
};
