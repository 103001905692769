import { FiltresDeclarationDtoSortByEnum } from "api/gen";
import SortableTableHeaderView from "common/presentational/tableHeaderSorted/SortableTableHeaderView";
import React from "react";

export const useVerificateurTableauHeader = (
  sortField: FiltresDeclarationDtoSortByEnum | null,
  isSortReversed: boolean | null,
  updateFilter: (
    field: FiltresDeclarationDtoSortByEnum | null,
    reversed: boolean | null
  ) => void
): React.ReactElement[] => {
  const content = [
    {
      field: FiltresDeclarationDtoSortByEnum.NIMS,
      name: "NIM",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.ID,
      name: "N° d'établissement associé",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.ANNEE,
      name: "Année",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.ETABLISSEMENT_NAME,
      name: "Nom de l'établissement associé",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.INSTALLATION_NAME,
      name: "Nom d'installation",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.QUOTAS_ALLOCATIONS_STATE,
      name: "Niveaux d'activité État",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_ALLOCATION_DECLARING,
      name: "Niveaux d'activité - Dernière action déclarant",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR,
      name: "Niveaux d'activité - Dernière action inspecteur",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.QUOTAS_EMISSIONS_STATE,
      name: "Émissions - État",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_EMISSION_DECLARING,
      name: "Emission - Dernière action déclarant",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_EMISSION_INSPECTOR,
      name: "Emission - Dernière action inspecteur",
    },
  ];

  return SortableTableHeaderView({
    columns: content,
    sortField: sortField,
    isSortReversed: isSortReversed,
    setSortFieldAndIsReversed: (sortField, isSortReversed) =>
      updateFilter(sortField, isSortReversed),
  });
};
