import { DescriptionAccidentDto } from "api/gen";
import { cleanComputeRightOptionProps } from "common/form/fields/connectedInput/utils";
import {
  AccidentCauseType,
  AccidentInArray,
  ConsolidatedType,
  deadlyLabel,
  noLabel,
  yesLabel,
} from "../utils";

export const convertPopulatedAccidentToDeclarationDescriptionAccidentDto = (
  values: AccidentInArray
): DescriptionAccidentDto => {
  return {
    accidentDate:
      values.data.date && values.data.date.toISOString().split(".")[0] + "Z",
    causePrincipale:
      values.data.accidentCause && values.data.accidentCause.object.cause,
    estConsolide: values.data.isConsolidated === yesLabel,
    estMortel: values.data.isConsolidated === deadlyLabel,
    id: values.data.id,
    nbJoursArretTravail: values.data.nbDaysStopped,
    precisionSiAutre: values.data.accidentCausePrecision,
  };
};

export const convertDeclarationDescriptionAccidentDtoToPopulatedAccident = (
  accidentData: DescriptionAccidentDto,
  causesList: AccidentCauseType[]
): AccidentInArray => {
  let isConsolidated: ConsolidatedType = noLabel;

  if (accidentData.estConsolide) {
    isConsolidated = yesLabel;
  } else if (accidentData.estMortel) {
    isConsolidated = deadlyLabel;
  }

  return {
    data: {
      id: accidentData.id,
      date:
        accidentData.accidentDate !== null
          ? new Date(accidentData.accidentDate)
          : null,
      accidentCause: cleanComputeRightOptionProps(
        accidentData.causePrincipale,
        causesList
      ),
      nbDaysStopped: accidentData.nbJoursArretTravail,
      accidentCausePrecision: accidentData.precisionSiAutre,
      isConsolidated: isConsolidated,
    },
    errors: {},
  };
};
