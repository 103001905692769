import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { AlcVerifieFilesDto24Now, QuotasInstallationDto20Now } from "api/gen";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useDeclaration24Now } from "../../../../versionedElements/declarationHooks24Now";
import { useAlcVerifieFileHandler21Now } from "../../../../../../from21/toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import { oneFileRelatedToNimToFileDto } from "../../../../../../from21/toNow/Quotas/common/FilesRelatedToNim/utils";

interface AlcUploaderProps {
  installation: QuotasInstallationDto20Now;
  shouldDisableFields: boolean;
}

const AlcRapportFileLoader = ({
  installation,
  shouldDisableFields,
}: AlcUploaderProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const openAlertModale = useAlertModale();
  const alcVerifieFileHandler = useAlcVerifieFileHandler21Now(installation.id);

  return (
    <FilesLoader
      declaration={declaration}
      text={`DÉPOSER LE RAPPORT ALC VERIFIE`}
      filesPath={
        "body.externalSections.quotas.allocations.blocAlc.alcVerifie.alcVerifieFiles"
      }
      section={FileSectionEnum.QUOTAS_RAPPORT_ALC_VERIFIE}
      fileHandlers={alcVerifieFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      max={1}
      transformFunction={(alcs: Record<string, unknown>[]) =>
        oneFileRelatedToNimToFileDto(
          installation.id,
          (alcs as unknown) as AlcVerifieFilesDto24Now[]
        )
      }
    />
  );
};

export default AlcRapportFileLoader;
