import { QuotasEmissionsBlocPlanSurveillanceDto2121 } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export type BlocPlanDeSurveillanceFormValues = QuotasEmissionsBlocPlanSurveillanceDto2121;

export const planSurveillanceFieldMatcher = fieldMatcherBuilder<
  BlocPlanDeSurveillanceFormValues
>().build({
  infosComplementairesInstallations: "infosComplementairesInstallations",
  fluxDerogationInstallations: "fluxDerogationInstallations",
});
