import { makeStyles } from "@material-ui/styles";
import {
  QuotasAllocationsVerificationDto20NowConditionDispenseEnum,
  QuotasInstallationDto20Now,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import {
  useBooleanCheckBoxGenerator,
  useDateFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import FormikBlocInUserContext from "common/formikBloc/FormikBlocInUserContext";
import { useFormikBloc } from "common/formikBloc/utils";
import { useConfirmationModale } from "common/modale/hooks";
import { PATH_QUOTAS_ALLOCATIONS_VERIFICATION_BLOC } from "common/path/path20Now";
import { WorkflowTarget } from "common/utils/types";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import { VERIFIED_ALERT_MESSAGE } from "pages/CompanySpace/from19/to19/Quotas/helpers";
import React from "react";
import { FILE_BUTTONS_STYLE, SECTION_TITLE_STYLE } from "theme";
import {
  blockDtoToBlockFormValues,
  getInaccessibleVerificationMessage,
  quotasStateToBlockVerifState,
  verificationFormToDTO,
} from "./converter";
import { useVerificationErrors } from "./hook";
import {
  exemptionConditionLabels,
  exemptionConditionOptions,
  isReportUnsatisfying,
  isSatisfyingWithRequests,
  organismesLabels,
  organismesValues,
  satisfyingDeclarationReasonLabels,
  satisfyingDeclarationReasonOptions,
  unsatisfyingDeclarationReasonLabels,
  unsatisfyingDeclarationReasonOptions,
  verificationReportConclusionLabels,
  verificationReportConclusionOptions,
} from "./selectPossibleValues";
import { BlocVerificationFormValues, BlocVerifState } from "./types";
import { additionalFieldValidation, validationSchema } from "./validation";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import { ViolationProcessor } from "common/violations/ViolationProcessor";
import DownloadFilesButton from "pages/CompanySpace/from21/toNow/Quotas/common/DownloadFilesButton";
import RapportVerificationLoader from "pages/CompanySpace/from21/toNow/Quotas/common/BlocVerification/RapportVerificationLoader";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";
import { useDeclarationStateHandlers21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
  disabledMessage: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: "bold",
    fontSize: "22px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...SECTION_TITLE_STYLE,
});

const Verification = (): React.ReactElement => {
  const user = useUserData();
  const declaration = useDeclaration24Now();
  const form = useFormikBloc<BlocVerificationFormValues>();
  const openModale = useConfirmationModale();
  const {
    updateQuotaAllocationsVerif,
    submitVerif,
  } = useDeclarationStateHandlers21Now();
  const [isSaveSpinnerVisible, triggerSaveSpinnerVisible] = useSpinnerState<
    boolean
  >();
  const [
    isValidateSpinnerVisible,
    triggerValidateSpinnerVisible,
  ] = useSpinnerState<void>();
  const {
    hasValidateOnce,
    triggerValidation,
    errors,
    hasError,
  } = useVerificationErrors();
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    labelWidth: "50%",
    className: "",
    formPrefix: "bloc-quotas-verification",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const installations: QuotasInstallationDto20Now[] =
    declaration.computed.sections.quotas.allocations.installations;
  const initialValues = blockDtoToBlockFormValues(
    declaration.body.sections.quotas.allocations.blocVerification,
    installations
  );
  const installationsList = installations.filter(
    installation => installation.nim !== null
  );
  const quotasAllocationState =
    declaration.body.workflowStatus.quotaAllocations.state;
  const blocVerifStatus = quotasStateToBlockVerifState(quotasAllocationState);

  const isVerificateur = user.isPrestataireNiveauxActiviteForEtablissement(
    declaration.etablissementCode
  );

  return (
    <FormikBlocInUserContext
      enableReinitialize={true}
      formikRef={form.formikRef}
      hasChanges={form.hasChanges}
      setHasChanges={form.setHasChanges}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title="ESPACE VERIFICATION - NIVEAUX D'ACTIVITE"
      additionalFieldValidation={additionalFieldValidation}
      additionalValidationAllowed={() =>
        blocVerifStatus === BlocVerifState.IN_PROGRESS
      }
      isAvailableToDeclarant={false}
      isAvailableToPrestataireNiveauxActivite={true}
      declarationState={quotasAllocationState}
      declarationEtablissement={declaration.etablissementCode}
      // Special worflow action for this bloc, must define specific callbacks
      saveAction={values => {
        return triggerSaveSpinnerVisible(
          updateQuotaAllocationsVerif(
            verificationFormToDTO(values, declaration)
          )
        );
      }}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      validateAction={values => {
        openModale(VERIFIED_ALERT_MESSAGE, () => {
          const validate = async () => {
            await updateQuotaAllocationsVerif(
              verificationFormToDTO(values, declaration)
            );
            await submitVerif([WorkflowTarget.QUOTA_ALLOCATIONS]);
          };
          triggerValidateSpinnerVisible(triggerValidation(validate()));
        });
      }}
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      // Edit button should never be displayed. Instead, other button will be disabled when actions are no longer possible
      isBlocValidated={
        blocVerifStatus === BlocVerifState.COMPLETED ||
        blocVerifStatus === BlocVerifState.IGNORED
      }
      isEditSpinnerVisible={false}
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      renderContent={({ values }, shouldDisabledFields) => (
        <>
          {!isVerificateur && (
            <div className={classes.disabledMessage}>
              <span>{getInaccessibleVerificationMessage(blocVerifStatus)}</span>
            </div>
          )}
          <div>
            <DownloadFilesButton />
            <GreyFilesDownloader
              text="TÉLÉCHARGER UN MODÈLE DE RAPPORT DE VÉRIFICATION (version du 23/02/2021) - NIVEAUX D'ACTIVITÉ"
              fileName="RapportVerificationNiveauxActivites.xlsx"
              isDisabled={shouldDisabledFields}
              additionalClassname={classes.fileButtons}
            />
            <FieldArray
              name="installations"
              render={() =>
                values.installations.map((installation, index) => {
                  return (
                    <React.Fragment key={installation.installationId}>
                      <div className={classes.sectionTitle}>
                        NIM à remplir :{" "}
                        {index < installationsList.length
                          ? installationsList[index].nim
                          : ""}
                        <CommentButton
                          path={`${PATH_QUOTAS_ALLOCATIONS_VERIFICATION_BLOC}/verifications/${installation.installationId}`}
                        />
                      </div>
                      <RapportVerificationLoader
                        installationId={installationsList[index].id}
                        shouldDisableFields={shouldDisabledFields}
                        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
                      />
                      <WrappedChoiceSelect
                        label="Nom de l'organisme vérificateur *"
                        name={`installations.${index}.organisme`}
                        isMulti={false}
                        options={organismesValues}
                        computeLabel={organisme => organismesLabels[organisme]}
                        commonProps={commonProps}
                        disabled={shouldDisabledFields}
                      />
                      <TextField
                        name={`installations.${index}.monitoringPlanReference`}
                        label="Référence du dernier PMS ayant servi à la vérification *"
                        disabled={shouldDisabledFields}
                      />
                      <CheckBox
                        name={`installations.${index}.visitExemption`}
                        label="Dispense de visite de site"
                        disabled={shouldDisabledFields}
                      />
                      {installation.visitExemption && (
                        <>
                          <WrappedChoiceSelect
                            name={`installations.${index}.conditionExemption`}
                            label="Condition de dispense de visite de site (ART 32 AVR) *"
                            isMulti={false}
                            options={exemptionConditionOptions}
                            computeLabel={exemption =>
                              exemptionConditionLabels[exemption]
                            }
                            disabled={shouldDisabledFields}
                            commonProps={commonProps}
                          />
                          {installation.conditionExemption ===
                            QuotasAllocationsVerificationDto20NowConditionDispenseEnum.AUTRE && (
                            <TextField
                              name={`installations.${index}.exemptionDetail`}
                              label="Préciser autre *"
                              disabled={shouldDisabledFields}
                            />
                          )}
                        </>
                      )}
                      <DateField
                        name={`installations.${index}.lastVisitDate`}
                        label="Date de la dernière visite de site *"
                        disabled={shouldDisabledFields}
                      />
                      <CheckBox
                        name={`installations.${index}.activityAndEmissionVisit`}
                        label="Visite combinée émissions / niveaux d’activité"
                        disabled={shouldDisabledFields}
                      />
                      <WrappedChoiceSelect
                        name={`installations.${index}.verificationReportConclusion`}
                        label="Conclusion du rapport de vérification *"
                        isMulti={false}
                        options={verificationReportConclusionOptions}
                        computeLabel={verification =>
                          verificationReportConclusionLabels[verification]
                        }
                        disabled={shouldDisabledFields}
                        commonProps={commonProps}
                      />
                      {isSatisfyingWithRequests(
                        installation.verificationReportConclusion
                      ) && (
                        <WrappedChoiceSelect
                          name={`installations.${index}.satisfyingDeclarationReason`}
                          label={
                            "Motifs de déclaration reconnue satisfaisante avec remarques *"
                          }
                          options={satisfyingDeclarationReasonOptions}
                          computeLabel={reason =>
                            satisfyingDeclarationReasonLabels[reason]
                          }
                          disabled={shouldDisabledFields}
                          isMulti
                          commonProps={commonProps}
                        />
                      )}
                      {isReportUnsatisfying(
                        installation.verificationReportConclusion
                      ) && (
                        <WrappedChoiceSelect
                          name={`installations.${index}.unsatisfyingDeclarationReason`}
                          label={
                            "Motifs de déclaration reconnue non satisfaisante *"
                          }
                          options={unsatisfyingDeclarationReasonOptions}
                          computeLabel={reason =>
                            unsatisfyingDeclarationReasonLabels[reason]
                          }
                          disabled={shouldDisabledFields}
                          isMulti
                          commonProps={commonProps}
                        />
                      )}
                    </React.Fragment>
                  );
                })
              }
            />
            {hasError && hasValidateOnce && (
              <ViolationProcessor errors={errors} warnings={[]} />
            )}
          </div>
        </>
      )}
    />
  );
};

export default Verification;
