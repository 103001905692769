import { FileDto, PmsDto2020 } from "api/gen";

export const pmsDtoToPmsFileDto = (
  installationId: string,
  listPms: PmsDto2020[]
): FileDto[] => {
  const currentPms = listPms.find(pms => pms.nimDynId === installationId);
  if (currentPms !== undefined) {
    return currentPms.fichiers;
  } else {
    return [];
  }
};
