import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { AirCombustionInstallationsDto1819TypeEnum } from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export interface ObjectInstallations {
  code: AirCombustionInstallationsDto1819TypeEnum;
  isLCPAvailable: boolean;
  isWicowiAvailable: boolean;
  isOther: boolean;
}

export const arrayInstallations: OptionPropsWithObject<
  ObjectInstallations
>[] = [
  {
    value: 1,
    label: "LCP - grande installation de combustion",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.LCP,
      isLCPAvailable: true,
      isWicowiAvailable: false,
      isOther: false,
    },
  },
  {
    value: 2,
    label: "WI - incinération",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.WI,
      isLCPAvailable: false,
      isWicowiAvailable: true,
      isOther: false,
    },
  },
  {
    value: 3,
    label: "coWI - co-incinération",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.CoWI,
      isLCPAvailable: false,
      isWicowiAvailable: true,
      isOther: false,
    },
  },
  {
    value: 4,
    label: "LCP/WI",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.LCP_WI,
      isLCPAvailable: true,
      isWicowiAvailable: true,
      isOther: false,
    },
  },
  {
    value: 5,
    label: "LCP/CoWI",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.LCP_COWI,
      isLCPAvailable: true,
      isWicowiAvailable: true,
      isOther: false,
    },
  },
  {
    value: 6,
    label: "Autre (non LCP, non WI/CoWI)",
    object: {
      code: AirCombustionInstallationsDto1819TypeEnum.AUTRE,
      isLCPAvailable: false,
      isWicowiAvailable: false,
      isOther: true,
    },
  },
];

export const getOptionsInstallations = (
  which: "lcpAndWi" | "noLcpNoWi" | "lcp" | "wi"
) => {
  switch (which) {
    case "lcpAndWi":
      return arrayInstallations;
    case "noLcpNoWi":
      return arrayInstallations.filter(
        installation =>
          installation.object.isOther ||
          (!installation.object.isLCPAvailable &&
            !installation.object.isWicowiAvailable)
      );
    case "lcp":
      return arrayInstallations.filter(
        installation =>
          installation.object.isOther ||
          (installation.object.isLCPAvailable &&
            !installation.object.isWicowiAvailable)
      );
    case "wi":
      return arrayInstallations.filter(
        installation =>
          installation.object.isOther ||
          (!installation.object.isLCPAvailable &&
            installation.object.isWicowiAvailable)
      );
    default: {
      throw new ShouldNotHappen(which);
    }
  }
};
