import { Nullable } from "common/utils/types";
import { DeclarationWasteReceptionFormData } from "../../types";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";

export const isDepartementAccessibleForReception = (
  values: Nullable<DeclarationWasteReceptionFormData>
): boolean => {
  if (values.location) {
    return values.location.isDepartmentAccessible;
  }

  return false;
};
export const isCountryAccessibleForReception = (
  values:
    | Nullable<DeclarationWasteReceptionFormData>
    | Partial<DeclarationWasteReceptionFormData> //  on remerciera le typage de CommonFormSingleEntity
): boolean => {
  if (values.location) {
    return values.location.isCountryAccessible;
  }

  return false;
};
export const isNotificationNumberAccessibleForReception = (
  values:
    | Nullable<DeclarationWasteReceptionFormData>
    | Partial<DeclarationWasteReceptionFormData> //  on remerciera le typage de CommonFormSingleEntity
): boolean => {
  return isCountryAccessibleForReception(values);
};

export const generateErrorsInModal = (
  values: Nullable<DeclarationWasteReceptionFormData>
): Errors<DeclarationWasteReceptionFormData> => {
  const errors: Errors<DeclarationWasteReceptionFormData> = {};
  if (typeof values.wasteId !== "object" || values.wasteId === null) {
    errors.wasteId = requiredMessage;
  }

  if (!values.operation) {
    errors.operation = requiredMessage;
  }

  if (!values.location) {
    errors.location = requiredMessage;
  }

  if (
    isDepartementAccessibleForReception(values) &&
    (typeof values.departement !== "object" || values.departement === null)
  ) {
    errors.departement = requiredMessage;
  }

  if (
    isCountryAccessibleForReception(values) &&
    (typeof values.pays !== "object" || values.pays === null)
  ) {
    errors.pays = requiredMessage;
  }

  return errors;
};
