import React, { ReactElement } from "react";
import Button from "common/button";
import {
  generateTransitionWarningMessage,
  SUBMITTED,
} from "common/messages/dashboardMessage";
import SeparateSubmissionWarning from "common/messages/SeparateSubmissionWarning";
import { WorkflowTarget } from "common/utils/types";
import { isEveryRequiredSectionValidated } from "../../Dashboard/Components/utils/validationUtils";
import { useConfirmationModale } from "common/modale/hooks";
import {
  useDeclaration21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../../toNow/versionedElements/declarationHooks21Now";

const SubmitButton = (): ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const declarationApi = useDeclarationHelpers21Now();
  const { submit } = useDeclarationStateHandlers21Now();
  const declaration = useDeclaration21Now();
  const typeActiviteQuotas = declaration.body.typeActivite.quotas;

  return (
    <Button
      text={"TRANSMETTRE"}
      onClick={() => {
        openConfirmationModale(
          <div>
            {generateTransitionWarningMessage(SUBMITTED)}
            {typeActiviteQuotas.estSousQuotaCo2 && (
              <SeparateSubmissionWarning />
            )}
          </div>,
          () => {
            submit([WorkflowTarget.GLOBAL]);
          }
        );
      }}
      isDisabled={!isEveryRequiredSectionValidated(declarationApi)}
    />
  );
};

export default SubmitButton;
