import { QuotasInstallationDto20Now } from "api/gen";
import { getValueOrZero } from "common/utils/numberUtils";
import { findElementByIdOrNull } from "common/utils/methods";
import {
  AlternativeEmissionInArray,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasEnum,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "../BlocEmissions/types";

const isCO2 = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas === GasEnum.CO2;

const isN2O = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas === GasEnum.N2O;

const isPFC = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas === GasEnum.PFC;

const filterAlternativeEmissionsByNim = (
  emission: AlternativeEmissionInArray,
  installation: QuotasInstallationDto20Now
) =>
  emission.data.nimInstallation &&
  emission.data.nimInstallation.id === installation.id;

const filterMeasuredEmissionsByNim = (
  emission: MeasureEmissionInArray,
  installation: QuotasInstallationDto20Now,
  measureDeclarations: MeasureDeclarationProps[]
) => {
  const measure = findElementByIdOrNull(
    emission.data.measure && emission.data.measure.id,
    measureDeclarations
  );
  return (
    measure &&
    measure.nimInstallation &&
    installation.id === measure.nimInstallation.id
  );
};

const filterComputedEmissionsByNim = (
  emission: ComputedEmissionInArray,
  installation: QuotasInstallationDto20Now,
  flowDeclarations: FlowDeclarationProps[]
) => {
  const flow = findElementByIdOrNull(
    emission.data.flow && emission.data.flow.id,
    flowDeclarations
  );
  return (
    flow && flow.nimInstallation && installation.id === flow.nimInstallation.id
  );
};

const getGasEmissionToIncrement = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray,
  onlyBiomass: boolean
) => {
  if (isCO2(emission)) {
    return onlyBiomass
      ? getValueOrZero(emission.data.biomassEmission)
      : getValueOrZero(emission.data.fossilEmission);
  } else {
    return getValueOrZero(emission.data.otherEmission);
  }
};

export const computeCO2Emissions = (
  installation: QuotasInstallationDto20Now,
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  computedEmissions
    .filter(em =>
      filterComputedEmissionsByNim(em, installation, flowDeclarations)
    )
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  measuredEmissions
    .filter(em =>
      filterMeasuredEmissionsByNim(em, installation, measureDeclarations)
    )
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computeN2OEmissions = (
  installation: QuotasInstallationDto20Now,
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  measuredEmissions
    .filter(em =>
      filterMeasuredEmissionsByNim(em, installation, measureDeclarations)
    )
    .filter(isN2O)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isN2O)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computePFCEmissions = (
  installation: QuotasInstallationDto20Now,
  alternativeEmissions: AlternativeEmissionInArray[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isPFC)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computeTotalEmissions = (
  installation: QuotasInstallationDto20Now,
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
): number => {
  return (
    computeCO2Emissions(
      installation,
      computedEmissions,
      measuredEmissions,
      alternativeEmissions,
      measureDeclarations,
      flowDeclarations,
      onlyBiomass
    ) +
    computeN2OEmissions(
      installation,
      measuredEmissions,
      alternativeEmissions,
      measureDeclarations,
      onlyBiomass
    ) +
    computePFCEmissions(installation, alternativeEmissions, onlyBiomass)
  );
};

export const computeCO2EmissionsForAllInstallations = (
  installations: QuotasInstallationDto20Now[],
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  installations.forEach(
    installation =>
      (total += computeCO2Emissions(
        installation,
        computedEmissions,
        measuredEmissions,
        alternativeEmissions,
        measureDeclarations,
        flowDeclarations,
        onlyBiomass
      ))
  );
  return total;
};
