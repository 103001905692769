import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { usePageHeaderInfo } from "pages/CommonSpace/AppPage/PageHeaderContext";
import { Redirect } from "react-router-dom";
import SimpleInputWithTextLabelDisplayer from "../../form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { DeclarationPageHeaderProps } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";
import DummyChoiceSelectWithoutOptionProps from "common/form/fields/dumbInput/DummyChoiceSelectWithoutOptionProps";
import { PATH_DASHBOARD, PATH_DECHET } from "common/path/path18Now";

const useStyles = makeStyles({
  inputField: {
    marginTop: "10px",
    marginBottom: "20px",
    width: "400px",
    display: "flex",
    "& label": {
      color: "black",
      fontWeight: "bold",
    },
  },
});

interface DropdownYearsMenuProps {
  yearsList: number[];
  headerInfo: DeclarationPageHeaderProps;
}

const DropdownYearsMenu = ({
  yearsList,
  headerInfo,
}: DropdownYearsMenuProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "year-choice",
  };

  const headerContext = usePageHeaderInfo();
  const [redirectionLink, setRedirectionLink] = useState<string | null>(null);

  useEffect(() => {
    return () => setRedirectionLink(null);
  });

  const replaceYearInLink = (year: number, link: string): string => {
    const yearToReplace = /\/20[0-9][0-9]\//;
    const newYear = "/" + year.toString() + "/";
    return link.replace(yearToReplace, newYear);
  };

  const changeRedirectForDechets = (
    redirectionLink: string,
    year: number
  ): string => {
    const pathToRedirect = year >= 2024 ? PATH_DASHBOARD : PATH_DECHET;
    return (redirectionLink =
      redirectionLink.split(PATH_DECHET)[0] + pathToRedirect);
  };

  const changeToYear = (year: number): void => {
    if (headerContext === null || headerContext.arianeString === null) {
      return;
    } else {
      let redirectionLink = replaceYearInLink(
        year,
        headerContext.arianeString[headerContext.arianeString.length - 1].to
      );
      redirectionLink = changeRedirectForDechets(redirectionLink, year);
      setRedirectionLink(redirectionLink);
    }
  };

  if (redirectionLink) {
    return <Redirect to={redirectionLink} />;
  }

  const viewedDeclarationYear = parseInt(headerInfo.declarationAnnee);

  return (
    <div>
      <SimpleInputWithTextLabelDisplayer
        label={"Année déclarée :"}
        name={"select-year"}
        renderInput={id => (
          <DummyChoiceSelectWithoutOptionProps<number, false>
            disabled={false}
            name={"select-year"}
            id={id}
            isMulti={false}
            options={yearsList}
            onChange={year => {
              year !== undefined &&
                year !== null &&
                !Array.isArray(year) &&
                // we're sure the year is a number as our options list is of this type,
                // and we filtered out null and undefined as well as array
                changeToYear(year);
            }}
            computeLabel={year => year.toString()}
            enableReset={false}
            error={null}
            value={viewedDeclarationYear}
          />
        )}
        commonProps={commonProps}
        errorData={{}}
      />
    </div>
  );
};

export default DropdownYearsMenu;
