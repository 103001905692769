import { ReferenceItemOrganismeAccrediteDto } from "api/gen";

interface CommonPoussiereData {
  hasShownNotWeakRiskOnly: boolean;
  accreditedOrganismIntervening: ReferenceItemOrganismeAccrediteDto | null;
  precision: string | null;
  nbTotalPrelevement: number | null;
}

export interface PoussiereAlveolaireQuartzTridimiteData
  extends CommonPoussiereData {
  nbGEHLessThan10PercentVLEP: number | null;
}

export interface PoussiereAlveolaireTotaleData extends CommonPoussiereData {
  minimalValue: number | null;
  maximalValue: number | null;
  nbGEHBiggerThanVLEP: number | null;
}

export interface MesureEmpoussierageData {
  wasRiskEvaluationRealised: boolean;
  nbGEH: number | null;
  poussiereAlveolaireQuartzTridimite: PoussiereAlveolaireQuartzTridimiteData | null;
  poussiereAlveolaireTotale: PoussiereAlveolaireTotaleData | null;
}

export const otherOrganismAccreditedOption: ReferenceItemOrganismeAccrediteDto = {
  libelle: "Autre organisme",
  actif: true,
  code: "",
  restorationCode: 0,
  uuid: "",
};

export const wasOtherOrganismSelected = (
  interveningOrganism: ReferenceItemOrganismeAccrediteDto | null
): boolean =>
  interveningOrganism !== null &&
  interveningOrganism.libelle === otherOrganismAccreditedOption.libelle;
