import { BlocRejetPolluantOptions } from "./types";
import {
  PolluantEauDtoMethodeEnum,
  PolluantEauDtoReferenceMethodeEnum,
  PolluantEauDtoTypeRejetEnum,
  ReferenceNormeEauDto,
  ReferencePolluantDto,
} from "api/gen";

export const typeRejetLabel: {
  [key in PolluantEauDtoTypeRejetEnum]: string;
} = {
  RACCORDE: "raccordé",
  ISOLE: "isolé",
};

export const arrayTypeRejet: PolluantEauDtoTypeRejetEnum[] = Object.keys(
  typeRejetLabel
) as PolluantEauDtoTypeRejetEnum[];

export const methodLabel: { [key in PolluantEauDtoMethodeEnum]: string } = {
  MESURE: "Mesure",
  CALCUL: "Calcul",
  ESTIMATION: "Estimation",
};
export const arrayMethods: PolluantEauDtoMethodeEnum[] = Object.keys(
  methodLabel
) as PolluantEauDtoMethodeEnum[];

export const arrayRefMethod: PolluantEauDtoReferenceMethodeEnum[] = [
  PolluantEauDtoReferenceMethodeEnum.INT,
  PolluantEauDtoReferenceMethodeEnum.PER,
  PolluantEauDtoReferenceMethodeEnum.NRO,
  PolluantEauDtoReferenceMethodeEnum.ALT,
  PolluantEauDtoReferenceMethodeEnum.MRC,
  PolluantEauDtoReferenceMethodeEnum.BMA,
  PolluantEauDtoReferenceMethodeEnum.CSS,
  PolluantEauDtoReferenceMethodeEnum.AUT,
];

export interface EnumTypeRejet {
  code: PolluantEauDtoTypeRejetEnum;
  isFieldIncludingImportedActive: boolean;
  isPurificationYieldActive: boolean;
}

export const selectPossibleValues = (
  referentialPolluants: ReferencePolluantDto,
  referentialNormes: ReferenceNormeEauDto
): BlocRejetPolluantOptions => ({
  polluantSubstances: referentialPolluants.polluants,
  polluantNormes: referentialNormes.referenceItemNormeEauDtoList,
  polluantTypes: arrayTypeRejet,
  polluantMethods: arrayMethods,
  polluantRefMethods: arrayRefMethod,
});
