import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import dangerIcon from "icons/danger.svg";
import DummyTextArea from "common/form/fields/dumbInput/DummyTextArea";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  title: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "unset",
  },
  picture: {
    width: "50px",
    marginRight: "5%",
  },
  textArea: {
    width: "100%",
    position: "relative",
    right: "15px",
    margin: "25px 0 0 0",
    //TODO the !important here is because we didn't fix https://dl.polyconseil.fr/jira/browse/GEREP-1611
    height: "300px !important",
  },
});

interface ReasonModaleContentProps {
  onValidation: (reason: string) => void;
  onCancel: () => void;
  message: string;
  reasonPlaceHolder: string;
}

export const ReasonModaleContent = ({
  onValidation,
  onCancel,
  message,
  reasonPlaceHolder,
}: ReasonModaleContentProps): React.ReactElement => {
  const classes = useStyles();

  const [reasonText, setReasonText] = useState<string | null>(null);

  return (
    <>
      <div className={classes.container}>
        <img src={dangerIcon} alt={"Attention"} className={classes.picture} />

        <p className={classes.title}>{message}</p>
      </div>

      <DummyTextArea
        additionalClassName={classes.textArea}
        id="field-confirmationModalTextArea"
        name="confirmationModalTextArea"
        error={null}
        value={reasonText}
        onChange={setReasonText}
        placeholder={reasonPlaceHolder}
      />

      <DummyGlobalFormActions
        validateButton={{
          title: "Valider",
          isVisible: true,
          isEnabled: reasonText !== null,
          onClick: () => {
            if (reasonText === null) {
              throw Error("button should be disabled");
            }

            onValidation(reasonText);
          },
          isSpinnerVisible: false,
        }}
        cancelButton={{
          title: "Annuler",
          isVisible: true,
          isEnabled: true,
          onClick: onCancel,
        }}
      />
    </>
  );
};
