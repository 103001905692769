import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/button";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useAlertModale } from "common/modale/hooks";

const useStyles = makeStyles({
  aggregationButton: {
    padding: "5px",
  },
});

export interface AdminExportButtonsProps {
  isDisabled: boolean;
  annees: number[] | null;
  setIsAggregationModaleOpen: (value: React.SetStateAction<boolean>) => void;
}

export const AdminExportButtons = ({
  isDisabled,
  annees,
  setIsAggregationModaleOpen,
}: AdminExportButtonsProps): React.ReactElement => {
  const classes = useStyles();
  const openAlertModale = useAlertModale();
  return (
    <Button
      text={
        <>
          <FontAwesomeIcon icon="download" /> Télécharger les déclarations
        </>
      }
      isReversed
      onClick={() => {
        if (annees === null || annees.length !== 1) {
          openAlertModale(`L'export n'est possible que sur une seule année. Assurez-vous
              de n'avoir qu'une année sélectionnée dans les filtres
              appliqués sur le tableau des déclarations.`);
        } else {
          setIsAggregationModaleOpen(true);
        }
      }}
      additionalClassname={classes.aggregationButton}
      isDisabled={isDisabled}
    />
  );
};
