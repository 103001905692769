import {
  DeclarationBodyDto2121,
  DeclarationDto2121,
  DeclarationSectionsDto2121,
  MethodeCalculCo2Dto2121,
  MethodeCalculCo2Dto22Now,
  MethodeCalculPfcDto2121,
  MethodeCalculPfcDto22Now,
  MethodeMesureEmissionDto2121,
  MethodeMesureEmissionDto22Now,
  QuotasAllocationsBlocVerificationDto20Now,
  QuotasBlocVerificationEmissionsDto20Now,
  TypeActiviteGlobalDto21Now,
  TypeActiviteQuotaDto2122,
  TypeActiviteQuotaDto23Now,
} from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useCallback, useContext } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationState,
  useDeclarationStateHandlers,
  useQuotasState,
  useUpdateDeclarationTypeActivite,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  AnyDeclarationDto,
  BasicDeclarationHandlers,
  DeclarationState,
  DeclarationStateHandlers18Now,
} from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import {
  Declaration22Now,
  DeclarationBody22Now,
  DeclarationSections22Now,
} from "../../../from22/toNow/versionedElements/declarationHooks22Now";
import { DeclarationApiContext } from "../../../DeclarationApiContext/DeclarationApiContext";
import { useAlertModale } from "../../../../../common/modale/hooks";
import {
  QuotaWorkflowTarget,
  WorkflowTarget,
} from "../../../../../common/utils/types";
import { backAlertMessage } from "../../../../../common/backErrors/utils";
import {
  backMessageApproveDeclaration,
  backMessageOnResetDeclaration,
  backMessageOnStartDeclaration,
  backMessageOnUpdateTypeActivite,
  backMessageOnYieldQuotas,
  backMessageSetVerification,
  backSubmitSimplifiedQuotas,
} from "../../../../../common/backErrors/errorMessages";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2021;
export const MAX_YEAR = undefined;

// Define all the DTO types for this version.
export type Declaration21Now = DeclarationDto2121 | Declaration22Now;
export type DeclarationBody21Now =
  | DeclarationBodyDto2121
  | DeclarationBody22Now;
export type DeclarationSections21Now =
  | DeclarationSectionsDto2121
  | DeclarationSections22Now;
export type TypeActiviteQuota21Now =
  | TypeActiviteQuotaDto2122
  | TypeActiviteQuotaDto23Now;
export type TypeActiviteGlobal21Now = TypeActiviteGlobalDto21Now;

export type MethodeCalculCo2 =
  | MethodeCalculCo2Dto2121
  | MethodeCalculCo2Dto22Now;
export type MethodeMesureEmission =
  | MethodeMesureEmissionDto2121
  | MethodeMesureEmissionDto22Now;
export type MethodeCalculPfcDto =
  | MethodeCalculPfcDto2121
  | MethodeCalculPfcDto22Now;

type DeclarationStateHandlers21Now = DeclarationStateHandlers18Now & {
  submitSimplifiedQuota: (target: QuotaWorkflowTarget) => Promise<boolean>;
  approve: (targets: WorkflowTarget[]) => Promise<boolean>;
  yieldQuotaAllocationsVerif(): Promise<boolean>;
  yieldQuotaEmissionsVerif(): Promise<boolean>;
  updateQuotaAllocationsVerif: (
    newQuotaVerif: QuotasAllocationsBlocVerificationDto20Now
  ) => Promise<boolean>;
  updateQuotaEmissionsVerif: (
    newQuotaVerif: QuotasBlocVerificationEmissionsDto20Now
  ) => Promise<boolean>;
  startGlobal: (newActiviteType: TypeActiviteGlobal21Now) => Promise<boolean>;
  startQuota: (newActiviteType: TypeActiviteQuota21Now) => Promise<boolean>;
  resetGlobal: () => Promise<boolean>;
  resetQuota: () => Promise<boolean>;
};
export const useDeclaration21Now = (
  shouldThrowIfWrongYear?: boolean
): Declaration21Now => {
  return useDeclaration<Declaration21Now>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationGlobalState21Now = (): DeclarationState => {
  const getDeclarationState = useCallback(
    (declarationDto: Declaration21Now) =>
      declarationDto.body.workflowStatus.general.state,
    []
  );

  return useDeclarationState<Declaration21Now>(
    getDeclarationState,
    MIN_YEAR,
    MAX_YEAR
  );
};

export const useQuotaAllocationsState21Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration21Now) =>
      declarationDto.body.workflowStatus.quotaAllocations.state,
    []
  );

  return useQuotasState<Declaration21Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useQuotaEmissionsState21Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration21Now) =>
      declarationDto.body.workflowStatus.quotaEmissions.state,
    []
  );

  return useQuotasState<Declaration21Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useDeclarationHelpers21Now = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers21Now = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration21Now> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration21Now): DeclarationSections21Now =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration21Now>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useUpdateDeclarationTypeActiviteGlobal21Now = (): ((
  newActiviteType: TypeActiviteGlobal21Now
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteGlobal21Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteGlobal21NowUsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  return useUpdateDeclarationTypeActivite<
    TypeActiviteGlobal21Now,
    Declaration21Now
  >(updateTypeActivite, MIN_YEAR, MAX_YEAR);
};
export const useDeclarationStateHandlers21Now = (): DeclarationStateHandlers21Now => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const approve = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.approveDeclaration20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageApproveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const updateTypeActiviteGlobal = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteGlobal21Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteGlobal21NowUsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateTypeActiviteQuota = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteQuota21Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteQuota2122UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateQuotaAllocationsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasAllocationsBlocVerificationDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration21Now = await declarationController.setVerificationAllocations20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const updateQuotaEmissionsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasBlocVerificationEmissionsDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration21Now = await declarationController.setVerificationEmissions20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const submitSimplifiedQuotaHandler = useCallback(
    async (target: QuotaWorkflowTarget): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.submitSimplifiedQuotas21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          [target]
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backSubmitSimplifiedQuotas));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const yieldQuotaAllocationsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_ALLOCATIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const yieldQuotaEmissionsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_EMISSIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const startGlobal = useCallback(
    async (newActiviteType: TypeActiviteGlobal21Now): Promise<boolean> => {
      let returnedResult: Declaration21Now;
      try {
        returnedResult = await updateTypeActiviteGlobal(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        returnedResult = await declarationController.startGlobalDeclaration21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [
      declarationController,
      declarationApi,
      openModale,
      updateTypeActiviteGlobal,
    ]
  );

  const startQuota = useCallback(
    async (newActiviteType: TypeActiviteQuota21Now): Promise<boolean> => {
      let returnedResult: Declaration21Now;
      try {
        returnedResult = await updateTypeActiviteQuota(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        returnedResult = await declarationController.startQuotaDeclaration21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale, updateTypeActiviteQuota]
  );

  const resetGlobal = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetGlobalDeclaration21NowUsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const resetQuota = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetQuotaDeclaration21NowUsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const stateHandlers18Now = useDeclarationStateHandlers(MIN_YEAR, MAX_YEAR);

  return {
    ...stateHandlers18Now,
    submitSimplifiedQuota: submitSimplifiedQuotaHandler,
    approve,
    yieldQuotaAllocationsVerif,
    yieldQuotaEmissionsVerif,
    updateQuotaAllocationsVerif,
    updateQuotaEmissionsVerif,
    startGlobal,
    startQuota,
    resetGlobal,
    resetQuota,
  };
};
