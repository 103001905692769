import { AirFugitivesFluxDto } from "api/gen";
import { CovInArray } from "./types";
import { getProcedesIds, getProcedesNames } from "../../utils/utils";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import { mentionDangerList } from "./selectPossibleValues";

export const convertCovInArrayToDto = (
  CovInPage: CovInArray[],
  procedesInPageMap: Map<string, ProcedeInArray>
): AirFugitivesFluxDto[] => {
  return CovInPage.map(singlePopulatedCov => {
    const singleCov = singlePopulatedCov.data;
    return {
      ...singleCov,
      procedes:
        singleCov.procedes !== null
          ? getProcedesNames(singleCov.procedes, procedesInPageMap)
          : [],
    };
  });
};

//TODO : calculate and handle errors
export const convertDtoToCovInArray = (
  cov: AirFugitivesFluxDto,
  procedes: ProcedeInArray[]
): CovInArray => {
  return {
    data: {
      ...cov,
      substance:
        mentionDangerList.find(mention => mention === cov.substance) || null,
      procedes: getProcedesIds(cov.procedes, procedes),
    },
    errors: {},
  };
};
