import {
  QuotasAllocationsEmailsVerificateursDto20Now,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
} from "api/gen";
import { Declaration24Now } from "./declarationHooks24Now";

export const updateHandlerBilanTotal24Now = (
  declaration: Declaration24Now,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration24Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddressEmissions24Now = (
  declaration: Declaration24Now,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration24Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
export const updateHandlerEmailsAddressAllocations24Now = (
  declaration: Declaration24Now,
  values: QuotasAllocationsEmailsVerificateursDto20Now | null
): Declaration24Now => {
  if (values) {
    declaration.body.sections.quotas.allocations.blocEmails = values;
  }
  return declaration;
};
