import * as Yup from "yup";
import cloneDeep from "lodash.clonedeep";
import { CommonFormEntityType } from "./type";
import { Nullable } from "common/utils/types";

type ListFieldType<T> = {
  [K in keyof T]: Yup.SchemaOf<any>;
};

/**
 * @deprecated it is bad practice to base our form content on a yup schema, as the schema should
 * just be a tool used for validation, and it shouldn't be mandatory the schema has a key for
 * each field.
 */
export const computeInitialFormValuesFromValidationSchema = <
  T extends CommonFormEntityType
>(
  initialEntity: Partial<T>,
  listFields: ListFieldType<T>
): Nullable<T> => {
  if (!listFields) {
    const nullCopy: any = {};
    Object.keys(initialEntity).forEach(key => {
      nullCopy[key] = null;
    });
    return nullCopy;
  }

  // we parse it as a Nullable of T because we just filled any keys it doesn't have OR is set to undefined to null (or eventually a boolean)
  const initialValues = cloneDeep(initialEntity) as Nullable<T>;

  Object.keys(listFields).forEach((key: keyof T) => {
    let defaultInitialValue = null;

    if (listFields[key].isType(true)) {
      defaultInitialValue = false as any;
    } else if (listFields[key].isType([])) {
      const recur = (listFields[key] as any)._subType;
      if (recur) {
        // note that if you want a value initialized as an empty array you should not provide a sub-validation schema
        defaultInitialValue = [
          computeInitialFormValuesFromValidationSchema({}, recur.fields),
        ];
        if (Object.keys(defaultInitialValue[0]).length === 0) {
          defaultInitialValue = null;
        }
      }
    }

    initialValues[key] =
      initialValues[key] === undefined
        ? defaultInitialValue
        : initialValues[key];
  });

  return initialValues;
};
