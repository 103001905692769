import React, { useState } from "react";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { DustRejectionForm, DustRejectionPointInArray } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import Button from "common/button";
import DustRejectionArray from "./DustRejectionArray";
import DustRejectionModal from "./DustRejectionModal";
import _ from "lodash";
import {
  convertDtoToDustRejectionForm,
  convertDustRejectionPointToDisplayed,
} from "./utils/converters";
import { convertDeclarationDtoUsingDustRejectionForm } from "../utils/converters";
import { validationSchema } from "./utils/validation";
import {
  PATH_CARRIERE_ENVIRONNEMENT_CANALISEE,
  PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_DOIT_MESURER,
  PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_MESURE_EFFECTUEES,
} from "common/path/path18Now";
import { LEFT_WITHDRAW_STYLE } from "theme";
import {
  Declaration20Now,
  useDeclaration20Now,
} from "../../../versionedElements/declarationHooks20Now";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";

interface BlocRejetPoussieresProps {
  dustRejectionFormProps: FormikBlocRefAndState<DustRejectionForm>;
  initialEmissionPoints: DustRejectionPointInArray[];
  emissionPointsInArray: DustRejectionPointInArray[];
  setEmissionPointsInArray: (points: DustRejectionPointInArray[]) => void;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  buttonModale: {
    padding: "5px",
    marginBottom: "20px",
    marginLeft: "70%",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const BlocRejetPoussieres = ({
  dustRejectionFormProps,
  initialEmissionPoints,
  emissionPointsInArray,
  setEmissionPointsInArray,
}: BlocRejetPoussieresProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration20Now();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "environnement-bloc-rejet-poussieres",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    emissionPointInModal,
    setEmissionPointInModal,
  ] = useState<DustRejectionPointInArray | null>(null);

  const initialValues = convertDtoToDustRejectionForm(declaration);

  return (
    <FormikBlocFullContext20Now
      hasChanges={dustRejectionFormProps.hasChanges}
      setHasChanges={dustRejectionFormProps.setHasChanges}
      formikRef={dustRejectionFormProps.formikRef}
      title="MESURES DE REJETS CANALISÉS DE POUSSIÈRES (TE4)"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_CANALISEE}
      areGlobalCommentsAllowed={true}
      hasFormChanges={(values: DustRejectionForm) => {
        const isInheritedCareerUpdated =
          initialValues.inheritedCareer !== values.inheritedCareer;
        const isDoneMeasuresUpdated =
          initialValues.doneMeasures !== values.doneMeasures;
        const isArrayUpdated =
          values.doneMeasures &&
          !_.isEqual(initialEmissionPoints, emissionPointsInArray);
        return (
          isDoneMeasuresUpdated || isArrayUpdated || isInheritedCareerUpdated
        );
      }}
      cancelAction={() => {
        setEmissionPointsInArray(
          convertDustRejectionPointToDisplayed(
            declaration.body.sections.carriere.environnement.poussiereCanalisee
              .mesuresDesRejets || []
          )
        );
        return initialValues;
      }}
      updateHandler={(
        declaration: Declaration20Now,
        values: DustRejectionForm
      ) =>
        convertDeclarationDtoUsingDustRejectionForm(
          declaration,
          values,
          emissionPointsInArray
        )
      }
      renderContent={({ values }, shouldDisabledFields: boolean) => (
        <>
          <CheckBox
            name="inheritedCareer"
            label="Carrière soumise à des prescriptions de mesures de rejets de poussières canalisés ou ayant effectué une campagne d'analyse"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_DOIT_MESURER}
          />

          {values.inheritedCareer && (
            <>
              <div className={classes.withdrawLeft}>
                <CheckBox
                  name="doneMeasures"
                  label="Mesures réalisées"
                  disabled={shouldDisabledFields}
                  commentPath={
                    PATH_CARRIERE_ENVIRONNEMENT_CANALISEE_MESURE_EFFECTUEES
                  }
                />
              </div>

              {values.doneMeasures && (
                <>
                  <Button
                    text="AJOUTER UN POINT D'ÉMISSION"
                    additionalClassname={classes.buttonModale}
                    onClick={() => setIsModalOpen(true)}
                    isDisabled={shouldDisabledFields}
                  />

                  <DustRejectionArray
                    isDisabled={shouldDisabledFields}
                    emissionPointsInArray={emissionPointsInArray}
                    setEmissionPointsInArray={setEmissionPointsInArray}
                    onEdit={(emissionPoint: DustRejectionPointInArray) => {
                      setEmissionPointInModal(emissionPoint);
                      setIsModalOpen(true);
                    }}
                  />

                  <DustRejectionModal
                    isOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    emissionPointsInArray={emissionPointsInArray}
                    setEmissionPointsInArray={setEmissionPointsInArray}
                    emissionPointInModal={emissionPointInModal}
                    setEmissionPointInModal={setEmissionPointInModal}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    />
  );
};

export default BlocRejetPoussieres;
