import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  singleProcedeHasSubPartActivated,
  singleProcedeOverwriteDependantFields,
  singleProcedeValidationSchema,
} from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { unitLabels, unitList } from "./utils/selectPossibleValues";
import { procedesUniqueMessage } from "common/declarant/formik/formikMessages";
import { procedeFieldMatcher, ProcedeInModale } from "./utils/types";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface FormSingleProcedeProps {
  closeFunction: () => void;
  isEdit: boolean;

  onSubmit: (
    values: ProcedeInModale,
    formikActions: FormikActions<ProcedeInModale>
  ) => void;
  initialProcede: ProcedeInModale;
  forbiddenProcedeName: string[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormSingleProcede = ({
  closeFunction,
  onSubmit,
  initialProcede,
  isEdit,
  forbiddenProcedeName,
}: FormSingleProcedeProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-procedes",
  };
  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN PROCÉDÉ"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: ProcedeInModale, formikBag) => {
        singleProcedeOverwriteDependantFields(values);
        onSubmit(values, formikBag);
      }}
      initialEntity={initialProcede}
      validationSchema={singleProcedeValidationSchema}
      validate={values => {
        if (
          forbiddenProcedeName.some(
            singleProcedeName => singleProcedeName === values.nom
          )
        ) {
          return { name: procedesUniqueMessage };
        }
        return {};
      }}
      renderField={({ values }) => {
        return (
          <>
            <TextField
              name={procedeFieldMatcher.name}
              label="Nom du procédé *"
            />
            <NumberField
              name={procedeFieldMatcher.quantity}
              label="Quantité de matière ou volume d'activité *"
              unit=""
            />
            <WrappedChoiceSelect
              name={procedeFieldMatcher.unit}
              label="Unité *"
              isMulti={false}
              options={unitList}
              computeLabel={unit => unitLabels[unit]}
              commonProps={commonProps}
            />
            {singleProcedeHasSubPartActivated(values.unite) && (
              <TextField
                name={procedeFieldMatcher.unitPrecision}
                label="Preciser *"
              />
            )}
            <NumberField
              name={procedeFieldMatcher.density}
              label="Masse volumique de la matière"
              unit={(labelClassname: string) => <>kg/l = t/m³</>}
            />
          </>
        );
      }}
    />
  );
};

export default FormSingleProcede;
