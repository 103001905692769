import React from "react";
import DeclarationSummary from "../DeclarationSummary";
import Row from "common/presentational/Row";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { useUserData } from "Authenticator/UserData";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";

const DashboardLecteur = ({ match }: RouteIdProps): React.ReactElement => {
  const userData = useUserData();

  const declarationAnnee = match.params.annee;
  const currentCampaign = useCurrentDeclarationCampaign();
  const declarationEtablissement = match.params.etablissement;

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };

  usePageHeaderSetterOnce(headerData);

  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateGlobal
  );

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />

      <DeclarationSummary
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        isLecteur={userData.isLecteur}
      />
    </>
  );
};

export default DashboardLecteur;
