import { BlocPrelevementEauFormValues } from "./types";
import { EauBlocPrelevementDto1822, ReferenceSandreDto } from "api/gen/api";
import {
  arraySeaWaterTakingLocationLabel,
  selectPossibleMethods,
} from "./selectPossibleValues";

export const dtoToBlocValues = (
  dto: EauBlocPrelevementDto1822,
  referentialSandres: ReferenceSandreDto
): BlocPrelevementEauFormValues => {
  return {
    // Full declaration fields
    nbJoursTravailles: dto.nombreJoursTravailles,
    volumeEauSurface: dto.volumeEauxSurface,
    volumeEauSouterraine: dto.volumeEauxSouterraines,
    volumeEauDistribution: dto.volumeReseauDistribution,
    volumeEauMer: dto.volumeMerOcean,
    lieuEauSurface:
      selectPossibleMethods(referentialSandres).surface.find(
        item => item.uuid === dto.codeSandreSurfaceID
      ) || null,
    lieuEauSouterraine:
      selectPossibleMethods(referentialSandres).underground.find(
        item => item.uuid === dto.codeSandreSouterrainID
      ) || null,
    lieuEauMer:
      selectPossibleMethods(referentialSandres).sea.find(
        sea =>
          arraySeaWaterTakingLocationLabel[sea].toLocaleLowerCase() ===
          dto.codeSandreMerOcean?.toLocaleLowerCase()
      ) || null,
    prelevementTotal: dto.prelevementTotal,
  };
};

export const blocValuesToDto = (
  values: BlocPrelevementEauFormValues,
  isDepassementSeuil: boolean
): EauBlocPrelevementDto1822 => {
  return {
    nombreJoursTravailles: values.nbJoursTravailles,
    volumeEauxSurface: values.volumeEauSurface,
    volumeEauxSouterraines: values.volumeEauSouterraine,
    volumeReseauDistribution: values.volumeEauDistribution,
    volumeMerOcean: values.volumeEauMer,
    codeSandreSurfaceID: values.lieuEauSurface
      ? values.lieuEauSurface.uuid
      : null,
    codeSandreSouterrainID: values.lieuEauSouterraine
      ? values.lieuEauSouterraine.uuid
      : null,
    codeSandreMerOcean:
      values.lieuEauMer && arraySeaWaterTakingLocationLabel[values.lieuEauMer],
    prelevementTotal: !isDepassementSeuil ? values.prelevementTotal : null,
  };
};
