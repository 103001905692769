import {
  ActiviteDto2122,
  ActiviteDto2122SystemeCoordonneesEnum,
  AdministratifDto20Now,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ServiceGunItemDto,
} from "api/gen";
import {
  ActivityType,
  AdministratifType,
  CoordonneesDepartementMap,
} from "./types";
import { Declarants } from "../DeclarantIdentityList/types";
import uuid from "uuid";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  transformActiviteToReferenceEnum,
  transformReferenceToActiviteEnum,
} from "./systemCoordinatesUtils";
import { getRefApeDtoById, getRefCityInseeById } from "./converter";
import { isCountryFrance } from "../CompanyIdentity/validation";
import { Declaration2122 } from "../../versionedElements/declarationHooks2122";

export const createActivityDto = (values: ActivityType): ActiviteDto2122 => {
  const {
    codeNaf,
    installationCity,
    systemeCoordonnees,
    ...filteredOutValues
  } = values;
  if (values.codeInspection) {
    return {
      ...filteredOutValues,
      installationCity: values.installationCity && values.installationCity.uuid,
      codeNafID: values.codeNaf && values.codeNaf.uuid,
      systemeCoordonnees:
        values.systemeCoordonnees &&
        transformReferenceToActiviteEnum(
          values.systemeCoordonnees.systemeCoordonnees
        ),
    };
  }
  throw Error("Code inspection is missing.");
};

export const createAdministratifDto = (
  values: AdministratifType
): AdministratifDto20Now => {
  // Country
  const isFrance =
    values.pays !== null && isCountryFrance(values.pays.designation);
  const countryId = isFrance ? "FRANCE" : values.pays?.uuid;

  // City
  let cityId: string | undefined = undefined;
  let cityString: string | undefined = undefined;
  if (typeof values.commune === "string") {
    cityString = values.commune;
  } else {
    cityId = values.commune?.uuid;
  }

  const { commune, pays, ...filteredOutValues } = values;
  // France's communes should have an id. Other communes should be pure string.
  const communeValue = isFrance ? cityId : cityString;
  return {
    ...filteredOutValues,
    commune: communeValue || null,
    pays: countryId || null,
  };
};

export const computeDeclarantInitialValues = (
  declarationDto: Declaration2122
): Declarants => {
  const returnedResult: Declarants = {
    declarants: [
      {
        prenom: "",
        nom: "",
        email: "",
        telephone: "",
        role: "",
        id: uuid(),
      },
    ],
  };

  if (declarationDto.body.sections.infoGenerale.declarants.length !== 0) {
    returnedResult.declarants =
      declarationDto.body.sections.infoGenerale.declarants;
  }

  return returnedResult;
};

export const computeActiviteInitialValues = (
  declarationDto: Declaration2122,
  serviceGunReferentiel: ServiceGunItemDto[],
  cityReferentiels: ReferenceItemGeoCodeInseeDto[],
  apeCodeReferentiels: ReferenceItemCodeApeDto[],
  coordonneesDepartementMap: CoordonneesDepartementMap
): ActivityType => {
  const activiteDto = declarationDto.body.sections.infoGenerale.activite;

  const city = getRefCityInseeById(
    cityReferentiels,
    activiteDto.installationCity || ""
  );

  const systemeCoordonnees: ActiviteDto2122SystemeCoordonneesEnum | null =
    activiteDto.systemeCoordonnees;

  const numeroDepartement: string | null = city && city.numeroDepartement;

  const availableReferenceItems: Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  > =
    (numeroDepartement && coordonneesDepartementMap.get(numeroDepartement)) ||
    new Map();

  const referenceItem: ReferenceItemCoordonneesDepartementDto | null =
    (systemeCoordonnees &&
      availableReferenceItems.get(
        transformActiviteToReferenceEnum(systemeCoordonnees)
      )) ||
    null;

  return {
    ...activiteDto,
    installationCity: city,
    codeNaf: getRefApeDtoById(apeCodeReferentiels, activiteDto.codeNafID || ""),
    systemeCoordonnees: referenceItem,
  };
};

export const computeAdminInitialValues = (
  declarationDto: Declaration2122,
  countryReferentiels: ReferenceItemPaysDto[],
  cityReferentiels: ReferenceItemGeoCodeInseeDto[]
): AdministratifType => {
  const administratif = declarationDto.body.sections.infoGenerale.administratif;

  // Country
  const isFrance =
    administratif.pays !== null && isCountryFrance(administratif.pays);
  let country = null;

  if (administratif.pays !== null) {
    country = isFrance
      ? // France has no id, should be found by its label
        findElementMatchingTemplate(
          { designation: "FRANCE" },
          countryReferentiels
        )
      : // Other countries should have an id
        findElementMatchingTemplate(
          { uuid: administratif.pays },
          countryReferentiels
        );
  }

  // City
  const commune = administratif.commune;
  let city = null;

  if (commune !== null) {
    city = isFrance
      ? // France's cities should be given as an id
        findElementMatchingTemplate({ uuid: commune }, cityReferentiels)
      : // Cities from another country should be pure string
        commune;
  }

  return {
    ...administratif,
    commune: city,
    pays: country,
  };
};
