import React from "react";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import Row from "common/presentational/Row";
import { useUserData } from "Authenticator/UserData";
import { makeStyles } from "@material-ui/styles";
import { useAllDeclarationFilesExport } from "pages/CompanySpace/DeclarationApiContext/utils/filesHooks";
import { useDeclaration21Now } from "../../versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  button: {
    padding: "5px",
  },
});

const DownloadFilesButton = (): React.ReactElement => {
  const classes = useStyles();

  const user = useUserData();
  const declaration = useDeclaration21Now();
  const [isDownloadingVisible, triggerDownloading] = useSpinnerState<boolean>();
  const exportDeclarationFiles = useAllDeclarationFilesExport();

  return (
    <Row>
      <Button
        text={
          <>
            TÉLÉCHARGER TOUS LES FICHIERS QUOTAS DÉPOSÉS PAR LE DÉCLARANT&nbsp;
            <FontAwesomeIcon icon="download" />
          </>
        }
        isReversed
        onClick={() =>
          triggerDownloading(
            exportDeclarationFiles(
              declaration.annee,
              declaration.etablissementCode
            )
          )
        }
        additionalClassname={classes.button}
        isDisabled={!user.isAnyPrestataire}
        isSpinnerVisible={isDownloadingVisible}
      />
    </Row>
  );
};

export default DownloadFilesButton;
