import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import {
  CARD_PADDING_DEFAULT_PX_VALUE,
  PINK,
  SUB_SECTION_FONT_SIZE,
} from "theme";
import { CardProps } from "common/Card/type";
import Card from "common/Card";
import { ImageProps } from "common/utils/types";
import { Redirect } from "react-router";

const useStyles = makeStyles({
  container: {
    justifyContent: "flex-start",
    margin: "auto",
    display: "flex",
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    padding: `${CARD_PADDING_DEFAULT_PX_VALUE}px`,
    width: `calc(100% - ${2 * CARD_PADDING_DEFAULT_PX_VALUE}px)`,
    height: `calc(100% - ${2 * CARD_PADDING_DEFAULT_PX_VALUE}px)`,
    cursor: "pointer",
  },

  customCard: {
    width: "300px",
    position: "relative",
    zIndex: 1,
  },

  title: {
    color: PINK,
    fontSize: SUB_SECTION_FONT_SIZE,
    fontWeight: "normal",
  },

  divImage: {
    float: "left",
    height: "100%",
    width: "100%",
  },

  divText: {
    float: "left",
    height: "100%",
    marginLeft: "15px",
  },
});

interface BigCardProps extends CardProps {
  picture: ImageProps;
  text: string;
  titre: string;
  link: string;
  nbComments?: number;
  children?: undefined;
}

const BigCard = ({
  picture,
  text,
  titre,
  link,
  isDisabled,
  additionalClassname,
}: BigCardProps): React.ReactElement => {
  const classes = useStyles();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  return (
    <>
      {shouldRedirect && <Redirect to={link} />}
      <Card
        isDisabled={isDisabled}
        additionalClassname={classNames(
          classes.customCard,
          additionalClassname
        )}
      >
        <div
          className={classes.container}
          onClick={() => {
            setShouldRedirect(true);
          }}
        >
          <div className={classNames(classes.divImage)}>
            <img {...picture} alt={picture.alt} height="100%" />
          </div>

          <div className={classNames(classes.divText)}>
            <h3 className={classes.title}>{titre}</h3>
            <p>{text}</p>
          </div>
        </div>
      </Card>
    </>
  );
};
export default BigCard;
