/// <reference path="./custom.d.ts" />
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AccidentDto
 */
export interface AccidentDto {
    /**
     * 
     * @type {boolean}
     * @memberof AccidentDto
     */
    accidentAvecArretTravail: boolean;
    /**
     * 
     * @type {Array<DescriptionAccidentDto>}
     * @memberof AccidentDto
     */
    accidents: Array<DescriptionAccidentDto>;
}
/**
 * 
 * @export
 * @interface ActiviteDto1819
 */
export interface ActiviteDto1819 {
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    abscisse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    codeNafID: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto1819
     */
    employe: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    informationsComplementaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    installationAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    installationCity: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    installationCodePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    nomEtablissement: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto1819
     */
    nombreHeureDeFonctionnement: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    siret: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto1819
     */
    volumeProduction: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto1819
     */
    website: string | null;
}
/**
 * 
 * @export
 * @interface ActiviteDto2020
 */
export interface ActiviteDto2020 {
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    abscisse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    codeNafID: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2020
     */
    employe: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    informationsComplementaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    installationAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    installationCity: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    installationCodePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    nomEtablissement: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2020
     */
    nombreHeureDeFonctionnement: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    siret: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    systemeCoordonnees: ActiviteDto2020SystemeCoordonneesEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2020
     */
    volumeProduction: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2020
     */
    website: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ActiviteDto2020SystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}

/**
 * 
 * @export
 * @interface ActiviteDto2122
 */
export interface ActiviteDto2122 {
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    abscisse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    autreMatiereProduitePrecision: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    codeNafID: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2122
     */
    employe: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    identifiantService: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    informationsComplementaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    installationAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    installationCity: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    installationCodePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    nomEtablissement: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2122
     */
    nombreHeureDeFonctionnement: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2122
     */
    nombreInstallations: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    siret: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    systemeCoordonnees: ActiviteDto2122SystemeCoordonneesEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto2122
     */
    volumeProduction: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto2122
     */
    website: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ActiviteDto2122SystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}

/**
 * 
 * @export
 * @interface ActiviteDto23Now
 */
export interface ActiviteDto23Now {
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    abscisse: string | null;
    /**
     * 
     * @type {Adresse23NowDto}
     * @memberof ActiviteDto23Now
     */
    adresse: Adresse23NowDto;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    autreMatiereProduitePrecision: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    codeNafID: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    dateFermeture: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    etatAdministratif: ActiviteDto23NowEtatAdministratifEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    identifiantService: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    informationsComplementaires: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    nomEtablissement: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto23Now
     */
    nombreHeureDeFonctionnement: number | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto23Now
     */
    nombreInstallations: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    siret: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    systemeCoordonnees: ActiviteDto23NowSystemeCoordonneesEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    trancheEmploye: ActiviteDto23NowTrancheEmployeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteDto23Now
     */
    volumeProduction: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteDto23Now
     */
    website: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ActiviteDto23NowEtatAdministratifEnum {
    'CLOSED' = 'CLOSED',
    'IN_ACTIVITY' = 'IN_ACTIVITY'
}
/**
    * @export
    * @enum {string}
    */
export enum ActiviteDto23NowSystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}
/**
    * @export
    * @enum {string}
    */
export enum ActiviteDto23NowTrancheEmployeEnum {
    'FIFTY_TO_NINETY_NINE' = 'FIFTY_TO_NINETY_NINE',
    'FIVE_HUNDRED_TO_ONE_THOUSAND_EXCL' = 'FIVE_HUNDRED_TO_ONE_THOUSAND_EXCL',
    'FIVE_THOUSAND_TO_TEN_THOUSAND_EXCL' = 'FIVE_THOUSAND_TO_TEN_THOUSAND_EXCL',
    'NN' = 'NN',
    'ONE_HUNDRED_TO_TWO_HUNDRED_EXCL' = 'ONE_HUNDRED_TO_TWO_HUNDRED_EXCL',
    'ONE_OR_TWO' = 'ONE_OR_TWO',
    'ONE_THOUSAND_TO_TWO_THOUSAND_EXCL' = 'ONE_THOUSAND_TO_TWO_THOUSAND_EXCL',
    'SIX_TO_NINE' = 'SIX_TO_NINE',
    'TEN_THOUSAND_AND_MORE' = 'TEN_THOUSAND_AND_MORE',
    'TEN_TO_NINETEEN' = 'TEN_TO_NINETEEN',
    'THREE_TO_FIVE' = 'THREE_TO_FIVE',
    'TWENTY_TO_FORTY_NINE' = 'TWENTY_TO_FORTY_NINE',
    'TWO_FIFTY_TO_FIVE_HUNDRED_EXCL' = 'TWO_FIFTY_TO_FIVE_HUNDRED_EXCL',
    'TWO_HUNDRED_TO_TWO_HUNDRED_FIFTY_EXCL' = 'TWO_HUNDRED_TO_TWO_HUNDRED_FIFTY_EXCL',
    'TWO_THOUSAND_TO_FIVE_THOUSAND_EXCL' = 'TWO_THOUSAND_TO_FIVE_THOUSAND_EXCL',
    'ZERO' = 'ZERO'
}

/**
 * 
 * @export
 * @interface ActiviteTransformationDto
 */
export interface ActiviteTransformationDto {
    /**
     * 
     * @type {boolean}
     * @memberof ActiviteTransformationDto
     */
    alimenteeParCarrieres: boolean;
    /**
     * 
     * @type {string}
     * @memberof ActiviteTransformationDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {number}
     * @memberof ActiviteTransformationDto
     */
    distanceUsineCarriere: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteTransformationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActiviteTransformationDto
     */
    paysID: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteTransformationDto
     */
    raisonSocialeUsine: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActiviteTransformationDto
     */
    ville: string | null;
}
/**
 * 
 * @export
 * @interface AdminEmailDto
 */
export interface AdminEmailDto {
    /**
     * 
     * @type {string}
     * @memberof AdminEmailDto
     */
    email: string;
}
/**
 * 
 * @export
 * @interface AdministratifDto1819
 */
export interface AdministratifDto1819 {
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    formeJuridique: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    nomUsuel: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    pays: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    siren: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto1819
     */
    societeMere: string | null;
}
/**
 * 
 * @export
 * @interface AdministratifDto20Now
 */
export interface AdministratifDto20Now {
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    adresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    formeJuridique: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    pays: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    raisonSociale: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    siren: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdministratifDto20Now
     */
    societeMere: string | null;
}
/**
 * 
 * @export
 * @interface Adresse23NowDto
 */
export interface Adresse23NowDto {
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    adresseComplement: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    codePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    libelleCommune: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    libelleVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    numeroVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    typeVoie: string | null;
    /**
     * 
     * @type {string}
     * @memberof Adresse23NowDto
     */
    uuidCommune: string | null;
}
/**
 * 
 * @export
 * @interface AerDonneeSurveillanceDto
 */
export interface AerDonneeSurveillanceDto {
    /**
     * 
     * @type {string}
     * @memberof AerDonneeSurveillanceDto
     */
    niveauApplique: AerDonneeSurveillanceDtoNiveauAppliqueEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AerDonneeSurveillanceDto
     */
    unite: string | null;
    /**
     * 
     * @type {string}
     * @memberof AerDonneeSurveillanceDto
     */
    valeur: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AerDonneeSurveillanceDtoNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface AirCombustionAppareilDto
 */
export interface AirCombustionAppareilDto {
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    activite: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionAppareilDto
     */
    capacite: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    date: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    foyerAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionAppareilDto
     */
    hauteurRejets: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    localisation: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionAppareilDto
     */
    lowestThan1500: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionAppareilDto
     */
    modification: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    nature: AirCombustionAppareilDtoNatureEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    natureAutre: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    typeFoyer: AirCombustionAppareilDtoTypeFoyerEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionAppareilDto
     */
    unite: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionAppareilDtoNatureEnum {
    'AUTRE' = 'AUTRE',
    'CHAUDIERE' = 'CHAUDIERE',
    'FOUR' = 'FOUR',
    'MOTEUR_DIESEL' = 'MOTEUR_DIESEL',
    'MOTEUR_GAZ' = 'MOTEUR_GAZ',
    'TURBINE_GAZ' = 'TURBINE_GAZ'
}
/**
    * @export
    * @enum {string}
    */
export enum AirCombustionAppareilDtoTypeFoyerEnum {
    'AUTRE' = 'AUTRE',
    'CHARBON' = 'CHARBON',
    'GRILLE' = 'GRILLE',
    'LIST_DENSE' = 'LIST_DENSE',
    'LIT_CHAUD' = 'LIT_CHAUD'
}

/**
 * 
 * @export
 * @interface AirCombustionCombustibleBiogazCh4Dto
 */
export interface AirCombustionCombustibleBiogazCh4Dto {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazCh4Dto
     */
    frequence: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionCombustibleBiogazCh4Dto
     */
    mesure: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazCh4Dto
     */
    teneur: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionCombustibleBiogazDto
 */
export interface AirCombustionCombustibleBiogazDto {
    /**
     * 
     * @type {AirCombustionCombustibleBiogazCh4Dto}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    ch4: AirCombustionCombustibleBiogazCh4Dto;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    debit: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    duree: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    ecart: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    fraction: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    frequence: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionCombustibleBiogazDto
     */
    mesure: boolean | null;
}
/**
 * 
 * @export
 * @interface AirCombustionCombustibleDto1819
 */
export interface AirCombustionCombustibleDto1819 {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionCombustibleDto1819
     */
    appareils: Array<string>;
    /**
     * 
     * @type {AirCombustionCombustibleBiogazDto}
     * @memberof AirCombustionCombustibleDto1819
     */
    biogaz: AirCombustionCombustibleBiogazDto;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    carbone: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    cendres: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    chlore: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    code: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    consommation: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    designationID: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    eau: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    installation: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    masseVolumique: number | null;
    /**
     * 
     * @type {AirCombustionCombustiblePciDto}
     * @memberof AirCombustionCombustibleDto1819
     */
    pci: AirCombustionCombustiblePciDto;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto1819
     */
    soufre: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    surCendres: AirCombustionCombustibleDto1819SurCendresEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto1819
     */
    unite: AirCombustionCombustibleDto1819UniteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustibleDto1819SurCendresEnum {
    'HORS_CENDRES' = 'HORS_CENDRES',
    'SUR_CENDRES' = 'SUR_CENDRES'
}
/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustibleDto1819UniteEnum {
    'GJ' = 'GJ',
    'GWH_PCI' = 'GWH_PCI',
    'GWH_PCS' = 'GWH_PCS',
    'HL' = 'HL',
    'KG' = 'KG',
    'KWH_PCI' = 'KWH_PCI',
    'KWH_PCS' = 'KWH_PCS',
    'K_M3' = 'K_M3',
    'L' = 'L',
    'M3' = 'M3',
    'MWH_PCI' = 'MWH_PCI',
    'MWH_PCS' = 'MWH_PCS',
    'T' = 'T',
    'TEP' = 'TEP'
}

/**
 * 
 * @export
 * @interface AirCombustionCombustibleDto20Now
 */
export interface AirCombustionCombustibleDto20Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionCombustibleDto20Now
     */
    appareils: Array<string>;
    /**
     * 
     * @type {AirCombustionCombustibleBiogazDto}
     * @memberof AirCombustionCombustibleDto20Now
     */
    biogaz: AirCombustionCombustibleBiogazDto;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    carbone: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    cendres: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    chlore: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    code: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    consommation: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    designationID: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    eau: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    installation: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    masseVolumique: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    masseVolumiqueEcart: string | null;
    /**
     * 
     * @type {AirCombustionCombustiblePciDto}
     * @memberof AirCombustionCombustibleDto20Now
     */
    pci: AirCombustionCombustiblePciDto;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustibleDto20Now
     */
    soufre: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    surCendres: AirCombustionCombustibleDto20NowSurCendresEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustibleDto20Now
     */
    unite: AirCombustionCombustibleDto20NowUniteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustibleDto20NowSurCendresEnum {
    'HORS_CENDRES' = 'HORS_CENDRES',
    'SUR_CENDRES' = 'SUR_CENDRES'
}
/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustibleDto20NowUniteEnum {
    'GJ' = 'GJ',
    'GWH_PCI' = 'GWH_PCI',
    'GWH_PCS' = 'GWH_PCS',
    'HL' = 'HL',
    'KG' = 'KG',
    'KWH_PCI' = 'KWH_PCI',
    'KWH_PCS' = 'KWH_PCS',
    'K_M3' = 'K_M3',
    'L' = 'L',
    'M3' = 'M3',
    'MWH_PCI' = 'MWH_PCI',
    'MWH_PCS' = 'MWH_PCS',
    'T' = 'T',
    'TEP' = 'TEP'
}

/**
 * 
 * @export
 * @interface AirCombustionCombustiblePciDto
 */
export interface AirCombustionCombustiblePciDto {
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustiblePciDto
     */
    brut: AirCombustionCombustiblePciDtoBrutEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustiblePciDto
     */
    ecart: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustiblePciDto
     */
    provenance: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionCombustiblePciDto
     */
    unite: AirCombustionCombustiblePciDtoUniteEnum | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionCombustiblePciDto
     */
    valeur: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustiblePciDtoBrutEnum {
    'BRUT' = 'BRUT',
    'SEC' = 'SEC'
}
/**
    * @export
    * @enum {string}
    */
export enum AirCombustionCombustiblePciDtoUniteEnum {
    'GJ_HL' = 'GJ_HL',
    'GJ_M3' = 'GJ_M3',
    'GJ_T' = 'GJ_T',
    'KWH_M3' = 'KWH_M3',
    'MJ_M3' = 'MJ_M3',
    'MWH_HL' = 'MWH_HL',
    'MWH_M3' = 'MWH_M3',
    'MWH_T' = 'MWH_T',
    'TEP_1000M3' = 'TEP_1000M3',
    'TEP_HL' = 'TEP_HL',
    'TEP_M3' = 'TEP_M3',
    'TEP_T' = 'TEP_T',
    'TH_KG' = 'TH_KG',
    'TH_M3' = 'TH_M3'
}

/**
 * 
 * @export
 * @interface AirCombustionContentDto1819
 */
export interface AirCombustionContentDto1819 {
    /**
     * 
     * @type {Array<AirCombustionAppareilDto>}
     * @memberof AirCombustionContentDto1819
     */
    appareils: Array<AirCombustionAppareilDto>;
    /**
     * 
     * @type {Array<AirCombustionCombustibleDto1819>}
     * @memberof AirCombustionContentDto1819
     */
    combustibles: Array<AirCombustionCombustibleDto1819>;
    /**
     * 
     * @type {AirCombustionEmissionDto1819}
     * @memberof AirCombustionContentDto1819
     */
    emissions: AirCombustionEmissionDto1819;
}
/**
 * 
 * @export
 * @interface AirCombustionContentDto2023
 */
export interface AirCombustionContentDto2023 {
    /**
     * 
     * @type {Array<AirCombustionAppareilDto>}
     * @memberof AirCombustionContentDto2023
     */
    appareils: Array<AirCombustionAppareilDto>;
    /**
     * 
     * @type {Array<AirCombustionCombustibleDto20Now>}
     * @memberof AirCombustionContentDto2023
     */
    combustibles: Array<AirCombustionCombustibleDto20Now>;
    /**
     * 
     * @type {AirCombustionEmissionDto2023}
     * @memberof AirCombustionContentDto2023
     */
    emissions: AirCombustionEmissionDto2023;
}
/**
 * 
 * @export
 * @interface AirCombustionContentDto24Now
 */
export interface AirCombustionContentDto24Now {
    /**
     * 
     * @type {Array<AirCombustionAppareilDto>}
     * @memberof AirCombustionContentDto24Now
     */
    appareils: Array<AirCombustionAppareilDto>;
    /**
     * 
     * @type {Array<AirCombustionCombustibleDto20Now>}
     * @memberof AirCombustionContentDto24Now
     */
    combustibles: Array<AirCombustionCombustibleDto20Now>;
    /**
     * 
     * @type {AirCombustionEmissionDto24Now}
     * @memberof AirCombustionContentDto24Now
     */
    emissions: AirCombustionEmissionDto24Now;
}
/**
 * 
 * @export
 * @interface AirCombustionDto1819
 */
export interface AirCombustionDto1819 {
    /**
     * 
     * @type {AirCombustionContentDto1819}
     * @memberof AirCombustionDto1819
     */
    content: AirCombustionContentDto1819;
    /**
     * 
     * @type {Array<AirCombustionInstallationsDto1819>}
     * @memberof AirCombustionDto1819
     */
    installations: Array<AirCombustionInstallationsDto1819>;
    /**
     * 
     * @type {AirCombustionRecapitulatifDto}
     * @memberof AirCombustionDto1819
     */
    recapitulatif: AirCombustionRecapitulatifDto;
}
/**
 * 
 * @export
 * @interface AirCombustionDto2023
 */
export interface AirCombustionDto2023 {
    /**
     * 
     * @type {AirCombustionContentDto2023}
     * @memberof AirCombustionDto2023
     */
    content: AirCombustionContentDto2023;
    /**
     * 
     * @type {Array<AirCombustionInstallationsDto20Now>}
     * @memberof AirCombustionDto2023
     */
    installations: Array<AirCombustionInstallationsDto20Now>;
    /**
     * 
     * @type {AirCombustionRecapitulatifDto}
     * @memberof AirCombustionDto2023
     */
    recapitulatif: AirCombustionRecapitulatifDto;
}
/**
 * 
 * @export
 * @interface AirCombustionDto24Now
 */
export interface AirCombustionDto24Now {
    /**
     * 
     * @type {AirCombustionContentDto24Now}
     * @memberof AirCombustionDto24Now
     */
    content: AirCombustionContentDto24Now;
    /**
     * 
     * @type {Array<AirCombustionInstallationsDto20Now>}
     * @memberof AirCombustionDto24Now
     */
    installations: Array<AirCombustionInstallationsDto20Now>;
    /**
     * 
     * @type {AirCombustionRecapitulatifDto}
     * @memberof AirCombustionDto24Now
     */
    recapitulatif: AirCombustionRecapitulatifDto;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionBilanDto1819
 */
export interface AirCombustionEmissionBilanDto1819 {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    appareils: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    biomasse: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    element: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    entrant: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    epuration: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    intrants: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    part: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    rendement: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    sortant: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    substanceID: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto1819
     */
    teneur: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionBilanDto20Now
 */
export interface AirCombustionEmissionBilanDto20Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    appareils: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    biomasse: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    elementID: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    elementName: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    epuration: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    installation: string | null;
    /**
     * 
     * @type {Array<AirCombustionEmissionBilanIntrantsDto20Now>}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    intrants: Array<AirCombustionEmissionBilanIntrantsDto20Now>;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    part: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    rendement: number | null;
    /**
     * 
     * @type {Array<AirCombustionEmissionBilanSortantsDto20Now>}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    sortants: Array<AirCombustionEmissionBilanSortantsDto20Now>;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanDto20Now
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionBilanIntrantsDto20Now
 */
export interface AirCombustionEmissionBilanIntrantsDto20Now {
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanIntrantsDto20Now
     */
    descriptif: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanIntrantsDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanIntrantsDto20Now
     */
    quantite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanIntrantsDto20Now
     */
    teneurMoyenne: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionBilanSortantsDto20Now
 */
export interface AirCombustionEmissionBilanSortantsDto20Now {
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanSortantsDto20Now
     */
    descriptif: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionBilanSortantsDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanSortantsDto20Now
     */
    quantite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionBilanSortantsDto20Now
     */
    teneurMoyenne: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionDto1819
 */
export interface AirCombustionEmissionDto1819 {
    /**
     * 
     * @type {Array<AirCombustionEmissionBilanDto1819>}
     * @memberof AirCombustionEmissionDto1819
     */
    bilans: Array<AirCombustionEmissionBilanDto1819>;
    /**
     * 
     * @type {Array<AirCombustionEmissionFacteurDto>}
     * @memberof AirCombustionEmissionDto1819
     */
    facteurs: Array<AirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<AirCombustionEmissionMesureDto1819>}
     * @memberof AirCombustionEmissionDto1819
     */
    mesures: Array<AirCombustionEmissionMesureDto1819>;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionDto2023
 */
export interface AirCombustionEmissionDto2023 {
    /**
     * 
     * @type {Array<AirCombustionEmissionBilanDto20Now>}
     * @memberof AirCombustionEmissionDto2023
     */
    bilans: Array<AirCombustionEmissionBilanDto20Now>;
    /**
     * 
     * @type {Array<AirCombustionEmissionFacteurDto>}
     * @memberof AirCombustionEmissionDto2023
     */
    facteurs: Array<AirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<AirCombustionEmissionMesureDto2023>}
     * @memberof AirCombustionEmissionDto2023
     */
    mesures: Array<AirCombustionEmissionMesureDto2023>;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionDto24Now
 */
export interface AirCombustionEmissionDto24Now {
    /**
     * 
     * @type {Array<AirCombustionEmissionBilanDto20Now>}
     * @memberof AirCombustionEmissionDto24Now
     */
    bilans: Array<AirCombustionEmissionBilanDto20Now>;
    /**
     * 
     * @type {Array<AirCombustionEmissionFacteurDto>}
     * @memberof AirCombustionEmissionDto24Now
     */
    facteurs: Array<AirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<AirCombustionEmissionMesureDto24Now>}
     * @memberof AirCombustionEmissionDto24Now
     */
    mesures: Array<AirCombustionEmissionMesureDto24Now>;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionFacteurDto
 */
export interface AirCombustionEmissionFacteurDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionFacteurDto
     */
    appareils: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    combustibleDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    ecart: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionFacteurDto
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionFacteurDto
     */
    facteur: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionFacteurDto
     */
    facteurOxydation: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    nature: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    provenance: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    provenanceFacteurOxydation: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionFacteurDto
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionFacteurDto
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureConcentrationDto
 */
export interface AirCombustionEmissionMesureConcentrationDto {
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureConcentrationDto
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureConcentrationDto
     */
    frequence: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDebitDto1819
 */
export interface AirCombustionEmissionMesureDebitDto1819 {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto1819
     */
    concentration: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDebitDto1819
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto1819
     */
    debit: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto1819
     */
    frequence: number | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDebitDto2023
 */
export interface AirCombustionEmissionMesureDebitDto2023 {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto2023
     */
    concentration: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDebitDto2023
     */
    concentrationUnite: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDebitDto2023
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto2023
     */
    debit: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto2023
     */
    frequence: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum {
    'G_NM3' = 'G_NM3',
    'KG_NM3' = 'KG_NM3',
    'MG_NM3' = 'MG_NM3',
    'NG_NM3' = 'NG_NM3',
    'PG_NM3' = 'PG_NM3',
    'UG_NM3' = 'UG_NM3'
}

/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDebitDto24Now
 */
export interface AirCombustionEmissionMesureDebitDto24Now {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto24Now
     */
    concentration: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDebitDto24Now
     */
    concentrationUnite: AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDebitDto24Now
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto24Now
     */
    debit: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDebitDto24Now
     */
    frequence: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum {
    'G_NM3' = 'G_NM3',
    'MG_NM3' = 'MG_NM3',
    'NG_NM3' = 'NG_NM3',
    'PG_NM3' = 'PG_NM3',
    'UG_NM3' = 'UG_NM3'
}

/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDto1819
 */
export interface AirCombustionEmissionMesureDto1819 {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    appareils: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirCombustionEmissionMesureConcentrationDto}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    concentration: AirCombustionEmissionMesureConcentrationDto;
    /**
     * 
     * @type {AirCombustionEmissionMesureDebitDto1819}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    debit: AirCombustionEmissionMesureDebitDto1819;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto1819
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDto2023
 */
export interface AirCombustionEmissionMesureDto2023 {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    appareils: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirCombustionEmissionMesureConcentrationDto}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    concentration: AirCombustionEmissionMesureConcentrationDto;
    /**
     * 
     * @type {AirCombustionEmissionMesureDebitDto2023}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    debit: AirCombustionEmissionMesureDebitDto2023;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto2023
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirCombustionEmissionMesureDto24Now
 */
export interface AirCombustionEmissionMesureDto24Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    appareils: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirCombustionEmissionMesureConcentrationDto}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    concentration: AirCombustionEmissionMesureConcentrationDto;
    /**
     * 
     * @type {AirCombustionEmissionMesureDebitDto24Now}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    debit: AirCombustionEmissionMesureDebitDto24Now;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    installation: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    substanceID: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionEmissionMesureDto24Now
     */
    typeMesure: AirCombustionEmissionMesureDto24NowTypeMesureEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionEmissionMesureDto24NowTypeMesureEnum {
    'CANALISEES' = 'CANALISEES',
    'DIFFUSES' = 'DIFFUSES',
    'FUGITIVES' = 'FUGITIVES'
}

/**
 * 
 * @export
 * @interface AirCombustionInstallationsDto1819
 */
export interface AirCombustionInstallationsDto1819 {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    heure: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto1819
     */
    nom: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    quantiteChaleur: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    quantiteElectricite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    rendementChaleur: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    rendementElectricite: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto1819
     */
    type: AirCombustionInstallationsDto1819TypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto1819
     */
    typeProduit: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto1819
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto1819
     */
    volumeActivite: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionInstallationsDto1819TypeEnum {
    'AUTRE' = 'AUTRE',
    'CoWI' = 'CoWI',
    'LCP' = 'LCP',
    'LCP_COWI' = 'LCP_COWI',
    'LCP_WI' = 'LCP_WI',
    'WI' = 'WI'
}

/**
 * 
 * @export
 * @interface AirCombustionInstallationsDto20Now
 */
export interface AirCombustionInstallationsDto20Now {
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    heure: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto20Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto20Now
     */
    nom: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    quantiteChaleur: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    quantiteElectricite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    rendementChaleur: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    rendementElectricite: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto20Now
     */
    type: AirCombustionInstallationsDto20NowTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto20Now
     */
    typeProduit: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirCombustionInstallationsDto20Now
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirCombustionInstallationsDto20Now
     */
    volumeActivite: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirCombustionInstallationsDto20NowTypeEnum {
    'CoWI' = 'CoWI',
    'INCINERATION' = 'INCINERATION',
    'INFERIEUR_20MW' = 'INFERIEUR_20MW',
    'LCP' = 'LCP',
    'LCP_COWI' = 'LCP_COWI',
    'LCP_WI' = 'LCP_WI',
    'SUPERIEUR_20MW' = 'SUPERIEUR_20MW',
    'TORCHERE' = 'TORCHERE',
    'VALORISATION' = 'VALORISATION',
    'WI' = 'WI'
}

/**
 * 
 * @export
 * @interface AirCombustionRecapitulatifDto
 */
export interface AirCombustionRecapitulatifDto {
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionRecapitulatifDto
     */
    lcp: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirCombustionRecapitulatifDto
     */
    wicowi: boolean | null;
}
/**
 * 
 * @export
 * @interface AirDto1819
 */
export interface AirDto1819 {
    /**
     * 
     * @type {AirCombustionDto1819}
     * @memberof AirDto1819
     */
    combustion: AirCombustionDto1819;
    /**
     * 
     * @type {AirElevageDto1819}
     * @memberof AirDto1819
     */
    elevage: AirElevageDto1819;
    /**
     * 
     * @type {AirFugitivesDto1819}
     * @memberof AirDto1819
     */
    fugitives: AirFugitivesDto1819;
    /**
     * 
     * @type {AirGazDto}
     * @memberof AirDto1819
     */
    gaz: AirGazDto;
    /**
     * 
     * @type {AirIsdndDto}
     * @memberof AirDto1819
     */
    isdnd: AirIsdndDto;
    /**
     * 
     * @type {AirSolvantsDto}
     * @memberof AirDto1819
     */
    solvants: AirSolvantsDto;
    /**
     * 
     * @type {AirSyntheseDto}
     * @memberof AirDto1819
     */
    synthese: AirSyntheseDto;
}
/**
 * 
 * @export
 * @interface AirDto2023
 */
export interface AirDto2023 {
    /**
     * 
     * @type {AirCombustionDto2023}
     * @memberof AirDto2023
     */
    combustion: AirCombustionDto2023;
    /**
     * 
     * @type {AirElevageDto20Now}
     * @memberof AirDto2023
     */
    elevage: AirElevageDto20Now;
    /**
     * 
     * @type {AirFugitivesDto2023}
     * @memberof AirDto2023
     */
    fugitives: AirFugitivesDto2023;
    /**
     * 
     * @type {AirGazDto}
     * @memberof AirDto2023
     */
    gaz: AirGazDto;
    /**
     * 
     * @type {AirIsdndDto}
     * @memberof AirDto2023
     */
    isdnd: AirIsdndDto;
    /**
     * 
     * @type {AirSolvantsDto}
     * @memberof AirDto2023
     */
    solvants: AirSolvantsDto;
    /**
     * 
     * @type {AirSyntheseDto}
     * @memberof AirDto2023
     */
    synthese: AirSyntheseDto;
}
/**
 * 
 * @export
 * @interface AirDto24Now
 */
export interface AirDto24Now {
    /**
     * 
     * @type {AirCombustionDto24Now}
     * @memberof AirDto24Now
     */
    combustion: AirCombustionDto24Now;
    /**
     * 
     * @type {AirElevageDto20Now}
     * @memberof AirDto24Now
     */
    elevage: AirElevageDto20Now;
    /**
     * 
     * @type {AirFugitivesDto24Now}
     * @memberof AirDto24Now
     */
    fugitives: AirFugitivesDto24Now;
    /**
     * 
     * @type {AirGazDto}
     * @memberof AirDto24Now
     */
    gaz: AirGazDto;
    /**
     * 
     * @type {AirIsdndDto}
     * @memberof AirDto24Now
     */
    isdnd: AirIsdndDto;
    /**
     * 
     * @type {AirSolvantsDto}
     * @memberof AirDto24Now
     */
    solvants: AirSolvantsDto;
    /**
     * 
     * @type {AirSyntheseDto}
     * @memberof AirDto24Now
     */
    synthese: AirSyntheseDto;
}
/**
 * 
 * @export
 * @interface AirElevageDetailNh31819
 */
export interface AirElevageDetailNh31819 {
    /**
     * 
     * @type {number}
     * @memberof AirElevageDetailNh31819
     */
    autreNh3: number;
    /**
     * 
     * @type {Array<AirElevageNh3BatimentDto1819>}
     * @memberof AirElevageDetailNh31819
     */
    batiments: Array<AirElevageNh3BatimentDto1819>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirElevageDetailNh31819
     */
    especesDeclarees: Array<AirElevageDetailNh31819EspecesDeclareesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum AirElevageDetailNh31819EspecesDeclareesEnum {
    'AUTRES_VOLAILLES' = 'AUTRES_VOLAILLES',
    'CAILLES' = 'CAILLES',
    'CANARDS' = 'CANARDS',
    'COCHETTES' = 'COCHETTES',
    'DINDES_ET_DINDONS' = 'DINDES_ET_DINDONS',
    'PINTADES' = 'PINTADES',
    'PORCELETS_EN_POST_SEVRAGE' = 'PORCELETS_EN_POST_SEVRAGE',
    'PORCS_DE_PRODUCTION' = 'PORCS_DE_PRODUCTION',
    'POULES_PONDEUSES' = 'POULES_PONDEUSES',
    'POULETS_DE_CHAIR' = 'POULETS_DE_CHAIR',
    'POULETTES' = 'POULETTES',
    'TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES' = 'TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES',
    'TRUIES_EN_MATERNITE' = 'TRUIES_EN_MATERNITE',
    'VERRATS' = 'VERRATS'
}

/**
 * 
 * @export
 * @interface AirElevageDetailNh320Now
 */
export interface AirElevageDetailNh320Now {
    /**
     * 
     * @type {number}
     * @memberof AirElevageDetailNh320Now
     */
    autreNh3: number;
    /**
     * 
     * @type {Array<AirElevageNh3BatimentDto20Now>}
     * @memberof AirElevageDetailNh320Now
     */
    batiments: Array<AirElevageNh3BatimentDto20Now>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirElevageDetailNh320Now
     */
    especesDeclarees: Array<AirElevageDetailNh320NowEspecesDeclareesEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum AirElevageDetailNh320NowEspecesDeclareesEnum {
    'APPROCHE_GLOBALE_PORCS' = 'APPROCHE_GLOBALE_PORCS',
    'AUTRES_VOLAILLES' = 'AUTRES_VOLAILLES',
    'CAILLES' = 'CAILLES',
    'CANARDS' = 'CANARDS',
    'COCHETTES' = 'COCHETTES',
    'DINDES_ET_DINDONS' = 'DINDES_ET_DINDONS',
    'PINTADES' = 'PINTADES',
    'PORCELETS_EN_POST_SEVRAGE' = 'PORCELETS_EN_POST_SEVRAGE',
    'PORCS_DE_PRODUCTION' = 'PORCS_DE_PRODUCTION',
    'POULES_PONDEUSES' = 'POULES_PONDEUSES',
    'POULETS_DE_CHAIR' = 'POULETS_DE_CHAIR',
    'POULETTES' = 'POULETTES',
    'TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES' = 'TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES',
    'TRUIES_EN_MATERNITE' = 'TRUIES_EN_MATERNITE',
    'VERRATS' = 'VERRATS'
}

/**
 * 
 * @export
 * @interface AirElevageDto1819
 */
export interface AirElevageDto1819 {
    /**
     * 
     * @type {AirElevageDetailNh31819}
     * @memberof AirElevageDto1819
     */
    detailNh3: AirElevageDetailNh31819;
    /**
     * 
     * @type {AirElevageEmissionsDto}
     * @memberof AirElevageDto1819
     */
    emissions: AirElevageEmissionsDto;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AirElevageDto1819
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AirElevageDto1819
     */
    mtd: boolean | null;
}
/**
 * 
 * @export
 * @interface AirElevageDto20Now
 */
export interface AirElevageDto20Now {
    /**
     * 
     * @type {AirElevageDetailNh320Now}
     * @memberof AirElevageDto20Now
     */
    detailNh3: AirElevageDetailNh320Now;
    /**
     * 
     * @type {AirElevageEmissionsDto}
     * @memberof AirElevageDto20Now
     */
    emissions: AirElevageEmissionsDto;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AirElevageDto20Now
     */
    fichiers: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface AirElevageEmissionsDto
 */
export interface AirElevageEmissionsDto {
    /**
     * 
     * @type {number}
     * @memberof AirElevageEmissionsDto
     */
    ch4: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirElevageEmissionsDto
     */
    n2o: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirElevageEmissionsDto
     */
    nh3: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirElevageEmissionsDto
     */
    pm10: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirElevageEmissionsDto
     */
    tsp: number | null;
}
/**
 * 
 * @export
 * @interface AirElevageNh3BatimentDto1819
 */
export interface AirElevageNh3BatimentDto1819 {
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    autresVolaillesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    caillesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    canardsEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    cochettesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    dindesDindonsEmission: number;
    /**
     * 
     * @type {string}
     * @memberof AirElevageNh3BatimentDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirElevageNh3BatimentDto1819
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    pintadesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    porceletsEnPostSevrageEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    porcsDeProductionEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    poulesPondeusesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    pouletsDeChairEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    poulettesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    truiesEnAttenteSaillieEtGestantesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    truiesEnMaterniteEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto1819
     */
    verratsEmission: number;
}
/**
 * 
 * @export
 * @interface AirElevageNh3BatimentDto20Now
 */
export interface AirElevageNh3BatimentDto20Now {
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    approcheGlobalePorcsEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    autresVolaillesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    caillesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    canardsEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    cochettesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    dindesDindonsEmission: number;
    /**
     * 
     * @type {string}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    pintadesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    porceletsEnPostSevrageEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    porcsDeProductionEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    poulesPondeusesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    pouletsDeChairEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    poulettesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    truiesEnAttenteSaillieEtGestantesEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    truiesEnMaterniteEmission: number;
    /**
     * 
     * @type {number}
     * @memberof AirElevageNh3BatimentDto20Now
     */
    verratsEmission: number;
}
/**
 * 
 * @export
 * @interface AirFugitivesDto1819
 */
export interface AirFugitivesDto1819 {
    /**
     * 
     * @type {AirFugitivesEmissionDto1819}
     * @memberof AirFugitivesDto1819
     */
    emission: AirFugitivesEmissionDto1819;
    /**
     * 
     * @type {Array<AirFugitivesFluxDto>}
     * @memberof AirFugitivesDto1819
     */
    flux: Array<AirFugitivesFluxDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesDto1819
     */
    mentionDanger: boolean;
    /**
     * 
     * @type {Array<AirFugitivesProcedesDto>}
     * @memberof AirFugitivesDto1819
     */
    procedes: Array<AirFugitivesProcedesDto>;
}
/**
 * 
 * @export
 * @interface AirFugitivesDto2023
 */
export interface AirFugitivesDto2023 {
    /**
     * 
     * @type {AirFugitivesEmissionDto2023}
     * @memberof AirFugitivesDto2023
     */
    emission: AirFugitivesEmissionDto2023;
    /**
     * 
     * @type {Array<AirFugitivesFluxDto>}
     * @memberof AirFugitivesDto2023
     */
    flux: Array<AirFugitivesFluxDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesDto2023
     */
    mentionDanger: boolean;
    /**
     * 
     * @type {Array<AirFugitivesProcedesDto>}
     * @memberof AirFugitivesDto2023
     */
    procedes: Array<AirFugitivesProcedesDto>;
}
/**
 * 
 * @export
 * @interface AirFugitivesDto24Now
 */
export interface AirFugitivesDto24Now {
    /**
     * 
     * @type {AirFugitivesEmissionDto24Now}
     * @memberof AirFugitivesDto24Now
     */
    emission: AirFugitivesEmissionDto24Now;
    /**
     * 
     * @type {Array<AirFugitivesFluxDto>}
     * @memberof AirFugitivesDto24Now
     */
    flux: Array<AirFugitivesFluxDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesDto24Now
     */
    mentionDanger: boolean;
    /**
     * 
     * @type {Array<AirFugitivesProcedesDto>}
     * @memberof AirFugitivesDto24Now
     */
    procedes: Array<AirFugitivesProcedesDto>;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionBilanDto1819
 */
export interface AirFugitivesEmissionBilanDto1819 {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    biomasse: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    element: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    entrant: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    epuration: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    intrant: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    part: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    procedes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    rendement: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    sortant: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    substanceID: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto1819
     */
    teneur: number | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionBilanDto20Now
 */
export interface AirFugitivesEmissionBilanDto20Now {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    biomasse: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    elementID: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    elementName: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    epuration: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    id: string;
    /**
     * 
     * @type {Array<AirFugitivesEmissionBilanIntrantsDto20Now>}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    intrants: Array<AirFugitivesEmissionBilanIntrantsDto20Now>;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    part: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    procedes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    rendement: number | null;
    /**
     * 
     * @type {Array<AirFugitivesEmissionBilanSortantsDto20Now>}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    sortants: Array<AirFugitivesEmissionBilanSortantsDto20Now>;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanDto20Now
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionBilanIntrantsDto20Now
 */
export interface AirFugitivesEmissionBilanIntrantsDto20Now {
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanIntrantsDto20Now
     */
    descriptif: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanIntrantsDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanIntrantsDto20Now
     */
    quantite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanIntrantsDto20Now
     */
    teneurMoyenne: number | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionBilanSortantsDto20Now
 */
export interface AirFugitivesEmissionBilanSortantsDto20Now {
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanSortantsDto20Now
     */
    descriptif: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionBilanSortantsDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanSortantsDto20Now
     */
    quantite: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionBilanSortantsDto20Now
     */
    teneurMoyenne: number | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionCorrelationDto
 */
export interface AirFugitivesEmissionCorrelationDto {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    biomasse: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    facteur: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    nature: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    natureEquipement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    precision: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    procede: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    provenance: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    substanceID: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionCorrelationDto
     */
    unite: AirFugitivesEmissionCorrelationDtoUniteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirFugitivesEmissionCorrelationDtoUniteEnum {
    'AUTRE' = 'AUTRE',
    'HL' = 'HL',
    'KG' = 'KG',
    'KM3' = 'KM3',
    'L' = 'L',
    'M3' = 'M3',
    'T' = 'T'
}

/**
 * 
 * @export
 * @interface AirFugitivesEmissionDto1819
 */
export interface AirFugitivesEmissionDto1819 {
    /**
     * 
     * @type {Array<AirFugitivesEmissionBilanDto1819>}
     * @memberof AirFugitivesEmissionDto1819
     */
    bilan: Array<AirFugitivesEmissionBilanDto1819>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionCorrelationDto>}
     * @memberof AirFugitivesEmissionDto1819
     */
    correlation: Array<AirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionMesureDto1819>}
     * @memberof AirFugitivesEmissionDto1819
     */
    mesure: Array<AirFugitivesEmissionMesureDto1819>;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionDto2023
 */
export interface AirFugitivesEmissionDto2023 {
    /**
     * 
     * @type {Array<AirFugitivesEmissionBilanDto20Now>}
     * @memberof AirFugitivesEmissionDto2023
     */
    bilan: Array<AirFugitivesEmissionBilanDto20Now>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionCorrelationDto>}
     * @memberof AirFugitivesEmissionDto2023
     */
    correlation: Array<AirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionMesureDto2023>}
     * @memberof AirFugitivesEmissionDto2023
     */
    mesure: Array<AirFugitivesEmissionMesureDto2023>;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionDto24Now
 */
export interface AirFugitivesEmissionDto24Now {
    /**
     * 
     * @type {Array<AirFugitivesEmissionBilanDto20Now>}
     * @memberof AirFugitivesEmissionDto24Now
     */
    bilan: Array<AirFugitivesEmissionBilanDto20Now>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionCorrelationDto>}
     * @memberof AirFugitivesEmissionDto24Now
     */
    correlation: Array<AirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<AirFugitivesEmissionMesureDto24Now>}
     * @memberof AirFugitivesEmissionDto24Now
     */
    mesure: Array<AirFugitivesEmissionMesureDto24Now>;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionMesureConcentration
 */
export interface AirFugitivesEmissionMesureConcentration {
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionMesureConcentration
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureConcentration
     */
    frequence: number | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionMesureDebit
 */
export interface AirFugitivesEmissionMesureDebit {
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionMesureDebit
     */
    continu: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDebit
     */
    frequence: number | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionMesureDto1819
 */
export interface AirFugitivesEmissionMesureDto1819 {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureConcentration}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    concentration: AirFugitivesEmissionMesureConcentration;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    concentrationMoy: number | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDebit}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    debit: AirFugitivesEmissionMesureDebit;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    debitHoraire: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    nature: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    procedes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto1819
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesEmissionMesureDto2023
 */
export interface AirFugitivesEmissionMesureDto2023 {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureConcentration}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    concentration: AirFugitivesEmissionMesureConcentration;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    concentrationMoy: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    concentrationMoyUnite: AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDebit}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    debit: AirFugitivesEmissionMesureDebit;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    debitHoraire: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    nature: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    procedes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto2023
     */
    substanceID: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum {
    'G_NM3' = 'G_NM3',
    'KG_NM3' = 'KG_NM3',
    'MG_NM3' = 'MG_NM3',
    'NG_NM3' = 'NG_NM3',
    'PG_NM3' = 'PG_NM3',
    'UG_NM3' = 'UG_NM3'
}

/**
 * 
 * @export
 * @interface AirFugitivesEmissionMesureDto24Now
 */
export interface AirFugitivesEmissionMesureDto24Now {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    biomasse: number | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureConcentration}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    concentration: AirFugitivesEmissionMesureConcentration;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    concentrationMoy: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    concentrationMoyUnite: AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum | null;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDebit}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    debit: AirFugitivesEmissionMesureDebit;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    debitHoraire: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    mesureType: AirFugitivesEmissionMesureDto24NowMesureTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    nature: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    procedes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    rendement: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesEmissionMesureDto24Now
     */
    substanceID: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum {
    'G_NM3' = 'G_NM3',
    'MG_NM3' = 'MG_NM3',
    'NG_NM3' = 'NG_NM3',
    'PG_NM3' = 'PG_NM3',
    'UG_NM3' = 'UG_NM3'
}
/**
    * @export
    * @enum {string}
    */
export enum AirFugitivesEmissionMesureDto24NowMesureTypeEnum {
    'CANALISEES' = 'CANALISEES',
    'DIFFUSES' = 'DIFFUSES',
    'FUGITIVES' = 'FUGITIVES'
}

/**
 * 
 * @export
 * @interface AirFugitivesFluxDto
 */
export interface AirFugitivesFluxDto {
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesFluxDto
     */
    flux: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesFluxDto
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirFugitivesFluxDto
     */
    procedes: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesFluxDto
     */
    substance: string | null;
}
/**
 * 
 * @export
 * @interface AirFugitivesProcedesDto
 */
export interface AirFugitivesProcedesDto {
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesProcedesDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesProcedesDto
     */
    masse: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesProcedesDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesProcedesDto
     */
    precision: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirFugitivesProcedesDto
     */
    quantite: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirFugitivesProcedesDto
     */
    unite: AirFugitivesProcedesDtoUniteEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirFugitivesProcedesDtoUniteEnum {
    'AUTRE' = 'AUTRE',
    'HL' = 'HL',
    'KG' = 'KG',
    'KM3' = 'KM3',
    'L' = 'L',
    'M3' = 'M3',
    'T' = 'T'
}

/**
 * 
 * @export
 * @interface AirGazDto
 */
export interface AirGazDto {
    /**
     * 
     * @type {Array<AirGazHfcDto>}
     * @memberof AirGazDto
     */
    hfc: Array<AirGazHfcDto>;
    /**
     * 
     * @type {AirGazPfcDto}
     * @memberof AirGazDto
     */
    pfc: AirGazPfcDto;
}
/**
 * 
 * @export
 * @interface AirGazHfcDto
 */
export interface AirGazHfcDto {
    /**
     * 
     * @type {boolean}
     * @memberof AirGazHfcDto
     */
    autre: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    capacite: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirGazHfcDto
     */
    gazID: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc1233zd: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc1234yf: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc1234ze: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc125: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc134: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc134a: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc143: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc143a: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc152a: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc227ea: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc23: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc236fa: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc245ca: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc245fa: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc32: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc365mfc: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc41: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    hfc4310mee: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirGazHfcDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirGazHfcDto
     */
    justification: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazHfcDto
     */
    quantite: number | null;
}
/**
 * 
 * @export
 * @interface AirGazPfcDto
 */
export interface AirGazPfcDto {
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c2f6: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c3f8: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c4f10: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c4f8: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c5f12: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    c6f14: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirGazPfcDto
     */
    cf4: number | null;
}
/**
 * 
 * @export
 * @interface AirIsdndBasicDto
 */
export interface AirIsdndBasicDto {
    /**
     * 
     * @type {number}
     * @memberof AirIsdndBasicDto
     */
    biogaz: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirIsdndBasicDto
     */
    ch4: number | null;
}
/**
 * 
 * @export
 * @interface AirIsdndCh4Dto
 */
export interface AirIsdndCh4Dto {
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCh4Dto
     */
    capte: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCh4Dto
     */
    genere: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirIsdndCh4Dto
     */
    methode: AirIsdndCh4DtoMethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirIsdndCh4Dto
     */
    methodeDescription: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCh4Dto
     */
    total: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirIsdndCh4DtoMethodeEnum {
    'ADEME' = 'ADEME',
    'AUTRE_METHODE' = 'AUTRE_METHODE'
}

/**
 * 
 * @export
 * @interface AirIsdndCo2Dto
 */
export interface AirIsdndCo2Dto {
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCo2Dto
     */
    capte: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCo2Dto
     */
    genere: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirIsdndCo2Dto
     */
    methode: AirIsdndCo2DtoMethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirIsdndCo2Dto
     */
    methodeDescription: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirIsdndCo2Dto
     */
    total: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirIsdndCo2DtoMethodeEnum {
    'ADEME' = 'ADEME',
    'AUTRE_METHODE' = 'AUTRE_METHODE'
}

/**
 * 
 * @export
 * @interface AirIsdndDto
 */
export interface AirIsdndDto {
    /**
     * 
     * @type {AirIsdndBasicDto}
     * @memberof AirIsdndDto
     */
    basic: AirIsdndBasicDto;
    /**
     * 
     * @type {AirIsdndEmissionsCalculeesDto}
     * @memberof AirIsdndDto
     */
    emissionsCalculees: AirIsdndEmissionsCalculeesDto;
}
/**
 * 
 * @export
 * @interface AirIsdndEmissionsCalculeesDto
 */
export interface AirIsdndEmissionsCalculeesDto {
    /**
     * 
     * @type {AirIsdndCh4Dto}
     * @memberof AirIsdndEmissionsCalculeesDto
     */
    calculCh4: AirIsdndCh4Dto;
    /**
     * 
     * @type {AirIsdndCo2Dto}
     * @memberof AirIsdndEmissionsCalculeesDto
     */
    calculCo2: AirIsdndCo2Dto;
}
/**
 * 
 * @export
 * @interface AirSolvantsCovPgsDto
 */
export interface AirSolvantsCovPgsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsCovPgsDto
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    i1: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    i2: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovPgsDto
     */
    id: string;
    /**
     * 
     * @type {Array<AirSolvantsInstallationDto>}
     * @memberof AirSolvantsCovPgsDto
     */
    installations: Array<AirSolvantsInstallationDto>;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovPgsDto
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o1: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o2: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o3: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o4: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o5: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o6: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o7: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o8: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    o9: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovPgsDto
     */
    rendement: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsCovPgsDto
     */
    simplifie: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovPgsDto
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirSolvantsCovnmPgsDto
 */
export interface AirSolvantsCovnmPgsDto {
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsCovnmPgsDto
     */
    epuration: boolean;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    i1: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    i2: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovnmPgsDto
     */
    id: string;
    /**
     * 
     * @type {Array<AirSolvantsInstallationDto>}
     * @memberof AirSolvantsCovnmPgsDto
     */
    installations: Array<AirSolvantsInstallationDto>;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovnmPgsDto
     */
    nature: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o1: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o2: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o3: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o4: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o5: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o6: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o7: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o8: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    o9: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsCovnmPgsDto
     */
    rendement: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsCovnmPgsDto
     */
    simplifie: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsCovnmPgsDto
     */
    substanceID: string | null;
}
/**
 * 
 * @export
 * @interface AirSolvantsDto
 */
export interface AirSolvantsDto {
    /**
     * 
     * @type {Array<AirSolvantsCovnmPgsDto>}
     * @memberof AirSolvantsDto
     */
    covnms: Array<AirSolvantsCovnmPgsDto>;
    /**
     * 
     * @type {Array<AirSolvantsCovPgsDto>}
     * @memberof AirSolvantsDto
     */
    covs: Array<AirSolvantsCovPgsDto>;
    /**
     * 
     * @type {Array<AirSolvantsEmissionDto>}
     * @memberof AirSolvantsDto
     */
    emissions: Array<AirSolvantsEmissionDto>;
    /**
     * 
     * @type {AirSolvantsInformationDto}
     * @memberof AirSolvantsDto
     */
    informations: AirSolvantsInformationDto;
    /**
     * 
     * @type {Array<AirSolvantsInstallationDto>}
     * @memberof AirSolvantsDto
     */
    installations: Array<AirSolvantsInstallationDto>;
}
/**
 * 
 * @export
 * @interface AirSolvantsEmissionDto
 */
export interface AirSolvantsEmissionDto {
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsEmissionDto
     */
    danger: AirSolvantsEmissionDtoDangerEnum | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsEmissionDto
     */
    flux: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsEmissionDto
     */
    id: string;
    /**
     * 
     * @type {Array<AirSolvantsInstallationDto>}
     * @memberof AirSolvantsEmissionDto
     */
    installations: Array<AirSolvantsInstallationDto>;
}

/**
    * @export
    * @enum {string}
    */
export enum AirSolvantsEmissionDtoDangerEnum {
    'H340' = 'H340',
    'H341' = 'H341',
    'H350' = 'H350',
    'H350I' = 'H350I',
    'H351' = 'H351',
    'H360D' = 'H360D',
    'H360F' = 'H360F'
}

/**
 * 
 * @export
 * @interface AirSolvantsInformationDto
 */
export interface AirSolvantsInformationDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof AirSolvantsInformationDto
     */
    activites: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsInformationDto
     */
    conforme: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsInformationDto
     */
    consommation: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsInformationDto
     */
    danger: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsInformationDto
     */
    derogation: AirSolvantsInformationDtoDerogationEnum | null;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AirSolvantsInformationDto
     */
    files: Array<FileDto>;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsInformationDto
     */
    ied: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AirSolvantsInformationDto
     */
    sme: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum AirSolvantsInformationDtoDerogationEnum {
    'ARTICLE59_2' = 'ARTICLE59_2',
    'ARTICLE59_3' = 'ARTICLE59_3',
    'NONE' = 'NONE'
}

/**
 * 
 * @export
 * @interface AirSolvantsInstallationDto
 */
export interface AirSolvantsInstallationDto {
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsInstallationDto
     */
    heures: number | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsInstallationDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsInstallationDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsInstallationDto
     */
    produit: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirSolvantsInstallationDto
     */
    unite: string | null;
    /**
     * 
     * @type {number}
     * @memberof AirSolvantsInstallationDto
     */
    volume: number | null;
}
/**
 * 
 * @export
 * @interface AirSyntheseDto
 */
export interface AirSyntheseDto {
    /**
     * 
     * @type {Array<AirSyntheseEmissionDto>}
     * @memberof AirSyntheseDto
     */
    emissions: Array<AirSyntheseEmissionDto>;
}
/**
 * 
 * @export
 * @interface AirSyntheseEmissionDto
 */
export interface AirSyntheseEmissionDto {
    /**
     * 
     * @type {number}
     * @memberof AirSyntheseEmissionDto
     */
    accidentel: number | null;
    /**
     * 
     * @type {number}
     * @memberof AirSyntheseEmissionDto
     */
    additionnel: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AirSyntheseEmissionDto
     */
    biomasse: boolean;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    description: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    methode: AirSyntheseEmissionDtoMethodeEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AirSyntheseEmissionDto
     */
    normesID: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    origine: string | null;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    referenceCalcule: AirSyntheseEmissionDtoReferenceCalculeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    referenceMesure: AirSyntheseEmissionDtoReferenceMesureEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AirSyntheseEmissionDto
     */
    substanceID: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AirSyntheseEmissionDtoMethodeEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE'
}
/**
    * @export
    * @enum {string}
    */
export enum AirSyntheseEmissionDtoReferenceCalculeEnum {
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'NRO' = 'NRO',
    'PER' = 'PER'
}
/**
    * @export
    * @enum {string}
    */
export enum AirSyntheseEmissionDtoReferenceMesureEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}

/**
 * 
 * @export
 * @interface AlcDto20Now
 */
export interface AlcDto20Now {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AlcDto20Now
     */
    documentsJustificatifs: Array<FileDto>;
    /**
     * 
     * @type {FileDto}
     * @memberof AlcDto20Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlcDto20Now
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface AlcPreliminaireDto20Now
 */
export interface AlcPreliminaireDto20Now {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AlcPreliminaireDto20Now
     */
    documentsJustificatifs: Array<FileDto>;
    /**
     * 
     * @type {FileDto}
     * @memberof AlcPreliminaireDto20Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlcPreliminaireDto20Now
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface AlcVerifieDto2020
 */
export interface AlcVerifieDto2020 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AlcVerifieDto2020
     */
    documentsJustificatifs: Array<FileDto>;
    /**
     * 
     * @type {FileDto}
     * @memberof AlcVerifieDto2020
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlcVerifieDto2020
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface AlcVerifieDto2123
 */
export interface AlcVerifieDto2123 {
    /**
     * 
     * @type {Array<AlcVerifieFilesDto2123>}
     * @memberof AlcVerifieDto2123
     */
    alcVerifieFiles: Array<AlcVerifieFilesDto2123>;
    /**
     * 
     * @type {Array<AlcVerifieJustificatifFilesDto>}
     * @memberof AlcVerifieDto2123
     */
    documentsJustificatifs: Array<AlcVerifieJustificatifFilesDto>;
}
/**
 * 
 * @export
 * @interface AlcVerifieDto24Now
 */
export interface AlcVerifieDto24Now {
    /**
     * 
     * @type {Array<AlcVerifieFilesDto24Now>}
     * @memberof AlcVerifieDto24Now
     */
    alcVerifieFiles: Array<AlcVerifieFilesDto24Now>;
    /**
     * 
     * @type {Array<AlcVerifieJustificatifFilesDto>}
     * @memberof AlcVerifieDto24Now
     */
    documentsJustificatifs: Array<AlcVerifieJustificatifFilesDto>;
}
/**
 * 
 * @export
 * @interface AlcVerifieFilesDto2123
 */
export interface AlcVerifieFilesDto2123 {
    /**
     * 
     * @type {FileDto}
     * @memberof AlcVerifieFilesDto2123
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlcVerifieFilesDto2123
     */
    nimDynId: string;
    /**
     * 
     * @type {RapportAlcDataDto2123}
     * @memberof AlcVerifieFilesDto2123
     */
    rapportAlcData: RapportAlcDataDto2123;
}
/**
 * 
 * @export
 * @interface AlcVerifieFilesDto24Now
 */
export interface AlcVerifieFilesDto24Now {
    /**
     * 
     * @type {FileDto}
     * @memberof AlcVerifieFilesDto24Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof AlcVerifieFilesDto24Now
     */
    nimDynId: string;
    /**
     * 
     * @type {RapportAlcDataDto24Now}
     * @memberof AlcVerifieFilesDto24Now
     */
    rapportAlcData: RapportAlcDataDto24Now;
}
/**
 * 
 * @export
 * @interface AlcVerifieJustificatifFilesDto
 */
export interface AlcVerifieJustificatifFilesDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof AlcVerifieJustificatifFilesDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof AlcVerifieJustificatifFilesDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface Annotation
 */
export interface Annotation {
}
/**
 * 
 * @export
 * @interface ApplicationContext
 */
export interface ApplicationContext {
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    applicationName: string;
    /**
     * 
     * @type {AutowireCapableBeanFactory}
     * @memberof ApplicationContext
     */
    autowireCapableBeanFactory: AutowireCapableBeanFactory;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    beanDefinitionCount: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApplicationContext
     */
    beanDefinitionNames: Array<string>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ApplicationContext
     */
    classLoader: ClassLoader | null;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    displayName: string;
    /**
     * 
     * @type {Environment}
     * @memberof ApplicationContext
     */
    environment: Environment;
    /**
     * 
     * @type {string}
     * @memberof ApplicationContext
     */
    id: string | null;
    /**
     * 
     * @type {ApplicationContext}
     * @memberof ApplicationContext
     */
    parent: ApplicationContext | null;
    /**
     * 
     * @type {BeanFactory}
     * @memberof ApplicationContext
     */
    parentBeanFactory: BeanFactory | null;
    /**
     * 
     * @type {number}
     * @memberof ApplicationContext
     */
    startupDate: number;
}
/**
 * 
 * @export
 * @interface AreaRange
 */
export interface AreaRange {
    /**
     * 
     * @type {Rangebigdecimal}
     * @memberof AreaRange
     */
    xRange: Rangebigdecimal;
    /**
     * 
     * @type {Rangebigdecimal}
     * @memberof AreaRange
     */
    yRange: Rangebigdecimal;
}
/**
 * 
 * @export
 * @interface AuthDroitDto
 */
export interface AuthDroitDto {
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    application: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    authProfile: AuthDroitDtoAuthProfileEnum;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    bassin: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    codeEtablissement: string | null;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof AuthDroitDto
     */
    codeEtablissementPrestataireDroitMap: { [key: string]: Array<string>; } | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    codeRegion: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    codeSandre: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    commune: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    departement: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    droitsEtendus: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    equipeGunId: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    etablissementNew: string | null;
    /**
     * 
     * @type {number}
     * @memberof AuthDroitDto
     */
    idApplication: number | null;
    /**
     * 
     * @type {LecteurDroitDto}
     * @memberof AuthDroitDto
     */
    lecteurRights: LecteurDroitDto | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    nameEtablissement: string | null;
    /**
     * 
     * @type {MonAiotNatureServiceDto}
     * @memberof AuthDroitDto
     */
    natureService: MonAiotNatureServiceDto | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    perimetreIc: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthDroitDto
     */
    stationEpuration: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum AuthDroitDtoAuthProfileEnum {
    'ADMINISTRATEUR' = 'ADMINISTRATEUR',
    'ADMINISTRATEUR_BQA' = 'ADMINISTRATEUR_BQA',
    'EXPLOITANT' = 'EXPLOITANT',
    'GESTIONNAIRE' = 'GESTIONNAIRE',
    'LECTEUR' = 'LECTEUR',
    'PRESTATAIRE' = 'PRESTATAIRE',
    'SUPER_ADMIN' = 'SUPER_ADMIN'
}

/**
 * 
 * @export
 * @interface AuthJwtDto
 */
export interface AuthJwtDto {
    /**
     * 
     * @type {string}
     * @memberof AuthJwtDto
     */
    jwt: string;
    /**
     * 
     * @type {string}
     * @memberof AuthJwtDto
     */
    monAIOTJWT: string;
}
/**
 * 
 * @export
 * @interface AuthUserInfoDto
 */
export interface AuthUserInfoDto {
    /**
     * 
     * @type {Array<AuthDroitDto>}
     * @memberof AuthUserInfoDto
     */
    droits: Array<AuthDroitDto>;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    familyName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    givenName: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    preferredUsername: string;
    /**
     * 
     * @type {string}
     * @memberof AuthUserInfoDto
     */
    siren: string;
}
/**
 * 
 * @export
 * @interface AuthenticateWithRightRequestBodyDto
 */
export interface AuthenticateWithRightRequestBodyDto {
    /**
     * 
     * @type {AuthDroitDto}
     * @memberof AuthenticateWithRightRequestBodyDto
     */
    requireDroitDto: AuthDroitDto;
}
/**
 * 
 * @export
 * @interface AutowireCapableBeanFactory
 */
export interface AutowireCapableBeanFactory {
}
/**
 * 
 * @export
 * @interface AutresRenseignementsSuperficieDto
 */
export interface AutresRenseignementsSuperficieDto {
    /**
     * 
     * @type {number}
     * @memberof AutresRenseignementsSuperficieDto
     */
    superficieAutorisee: number | null;
    /**
     * 
     * @type {number}
     * @memberof AutresRenseignementsSuperficieDto
     */
    superficieExploitee: number | null;
    /**
     * 
     * @type {number}
     * @memberof AutresRenseignementsSuperficieDto
     */
    superficieRestantAExploiter: number | null;
    /**
     * 
     * @type {number}
     * @memberof AutresRenseignementsSuperficieDto
     */
    superficieRestituee: number | null;
}
/**
 * 
 * @export
 * @interface BeanFactory
 */
export interface BeanFactory {
}
/**
 * 
 * @export
 * @interface BilanTotalEmissionDto
 */
export interface BilanTotalEmissionDto {
    /**
     * 
     * @type {number}
     * @memberof BilanTotalEmissionDto
     */
    emissionsCo2BiomasseDurable: number | null;
    /**
     * 
     * @type {number}
     * @memberof BilanTotalEmissionDto
     */
    emissionsCo2BiomasseNonDurable: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof BilanTotalEmissionDto
     */
    emissionsCo2NonBiomasseList: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof BilanTotalEmissionDto
     */
    emissionsN2oNonBiomasseList: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof BilanTotalEmissionDto
     */
    emissionsPfcNonBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof BilanTotalEmissionDto
     */
    emissionsTotalesNonBiomasse: number | null;
}
/**
 * 
 * @export
 * @interface BlocAlcDto2020
 */
export interface BlocAlcDto2020 {
    /**
     * 
     * @type {Array<AlcPreliminaireDto20Now>}
     * @memberof BlocAlcDto2020
     */
    alcPreliminaire: Array<AlcPreliminaireDto20Now>;
    /**
     * 
     * @type {Array<AlcVerifieDto2020>}
     * @memberof BlocAlcDto2020
     */
    alcVerifie: Array<AlcVerifieDto2020>;
}
/**
 * 
 * @export
 * @interface BlocAlcDto21Now
 */
export interface BlocAlcDto21Now {
    /**
     * 
     * @type {Array<AlcPreliminaireDto20Now>}
     * @memberof BlocAlcDto21Now
     */
    alcPreliminaire: Array<AlcPreliminaireDto20Now>;
}
/**
 * 
 * @export
 * @interface BlocDocumentsDto
 */
export interface BlocDocumentsDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof BlocDocumentsDto
     */
    fichiers: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface BlocDonneeSurveillanceDto
 */
export interface BlocDonneeSurveillanceDto {
    /**
     * 
     * @type {Array<PointMesureDto>}
     * @memberof BlocDonneeSurveillanceDto
     */
    mesureSourceEmissionList: Array<PointMesureDto>;
    /**
     * 
     * @type {Array<MethodeCalculExceptPfcDto>}
     * @memberof BlocDonneeSurveillanceDto
     */
    methodeCalculExceptPfcList: Array<MethodeCalculExceptPfcDto>;
    /**
     * 
     * @type {Array<PdsMethodeCalculPfcDto>}
     * @memberof BlocDonneeSurveillanceDto
     */
    surveillanceEmissionPfcList: Array<PdsMethodeCalculPfcDto>;
    /**
     * 
     * @type {SurveillanceMethodeAlternativeDto}
     * @memberof BlocDonneeSurveillanceDto
     */
    surveillanceMethodeAlternativeDto: SurveillanceMethodeAlternativeDto;
    /**
     * 
     * @type {Array<SurveillanceTransfereDto>}
     * @memberof BlocDonneeSurveillanceDto
     */
    surveillanceTransfereList: Array<SurveillanceTransfereDto>;
}
/**
 * 
 * @export
 * @interface BodyFindDeclarationByIdDto
 */
export interface BodyFindDeclarationByIdDto {
    /**
     * 
     * @type {string}
     * @memberof BodyFindDeclarationByIdDto
     */
    monAiotToken: string;
}
/**
 * 
 * @export
 * @interface BruitDto
 */
export interface BruitDto {
    /**
     * 
     * @type {MesureBasiqueBruitEnvironnementDto}
     * @memberof BruitDto
     */
    mesures: MesureBasiqueBruitEnvironnementDto;
    /**
     * 
     * @type {MesureBasiqueBruitEnvironnementSpecifiqueDto}
     * @memberof BruitDto
     */
    mesuresSpecifiques: MesureBasiqueBruitEnvironnementSpecifiqueDto;
    /**
     * 
     * @type {boolean}
     * @memberof BruitDto
     */
    mesuresSpecifiquesDansEnvironnement: boolean;
}
/**
 * 
 * @export
 * @interface CampaignDto
 */
export interface CampaignDto {
    /**
     * 
     * @type {number}
     * @memberof CampaignDto
     */
    annee: number;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endAllocations: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endAllocationsDeclarants: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endEmissions: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endEmissionsDeclarants: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endGlobal: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    endGlobalDeclarants: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    start: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    stateAllocations: CampaignDtoStateAllocationsEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    stateEmissions: CampaignDtoStateEmissionsEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignDto
     */
    stateGlobal: CampaignDtoStateGlobalEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoStateAllocationsEnum {
    'ENDED' = 'ENDED',
    'ENDED_FOR_DECLARANTS' = 'ENDED_FOR_DECLARANTS',
    'PLANNED' = 'PLANNED',
    'READY' = 'READY',
    'STARTED' = 'STARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoStateEmissionsEnum {
    'ENDED' = 'ENDED',
    'ENDED_FOR_DECLARANTS' = 'ENDED_FOR_DECLARANTS',
    'PLANNED' = 'PLANNED',
    'READY' = 'READY',
    'STARTED' = 'STARTED'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignDtoStateGlobalEnum {
    'ENDED' = 'ENDED',
    'ENDED_FOR_DECLARANTS' = 'ENDED_FOR_DECLARANTS',
    'PLANNED' = 'PLANNED',
    'READY' = 'READY',
    'STARTED' = 'STARTED'
}

/**
 * 
 * @export
 * @interface CarriereDto1819
 */
export interface CarriereDto1819 {
    /**
     * 
     * @type {EnvironnementDto1819}
     * @memberof CarriereDto1819
     */
    environnement: EnvironnementDto1819;
    /**
     * 
     * @type {ProductionDto1822}
     * @memberof CarriereDto1819
     */
    production: ProductionDto1822;
    /**
     * 
     * @type {SanteDto}
     * @memberof CarriereDto1819
     */
    sante: SanteDto;
}
/**
 * 
 * @export
 * @interface CarriereDto2022
 */
export interface CarriereDto2022 {
    /**
     * 
     * @type {EnvironnementDto20Now}
     * @memberof CarriereDto2022
     */
    environnement: EnvironnementDto20Now;
    /**
     * 
     * @type {ProductionDto1822}
     * @memberof CarriereDto2022
     */
    production: ProductionDto1822;
    /**
     * 
     * @type {SanteDto}
     * @memberof CarriereDto2022
     */
    sante: SanteDto;
}
/**
 * 
 * @export
 * @interface CarriereDto23Now
 */
export interface CarriereDto23Now {
    /**
     * 
     * @type {EnvironnementDto20Now}
     * @memberof CarriereDto23Now
     */
    environnement: EnvironnementDto20Now;
    /**
     * 
     * @type {ProductionDto23Now}
     * @memberof CarriereDto23Now
     */
    production: ProductionDto23Now;
    /**
     * 
     * @type {SanteDto}
     * @memberof CarriereDto23Now
     */
    sante: SanteDto;
}
/**
 * 
 * @export
 * @interface CategorieInstallationDto
 */
export interface CategorieInstallationDto {
    /**
     * 
     * @type {string}
     * @memberof CategorieInstallationDto
     */
    categorie: CategorieInstallationDtoCategorieEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CategorieInstallationDto
     */
    faiblementEmettrice: boolean;
    /**
     * 
     * @type {number}
     * @memberof CategorieInstallationDto
     */
    yearlyEmissionEstimate: number;
}

/**
    * @export
    * @enum {string}
    */
export enum CategorieInstallationDtoCategorieEnum {
    'A' = 'A',
    'B' = 'B',
    'C' = 'C'
}

/**
 * 
 * @export
 * @interface ChangeRightRequestBodyDto
 */
export interface ChangeRightRequestBodyDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeRightRequestBodyDto
     */
    monAIOTAccessToken: string;
    /**
     * 
     * @type {AuthDroitDto}
     * @memberof ChangeRightRequestBodyDto
     */
    targetRight: AuthDroitDto;
}
/**
 * 
 * @export
 * @interface ClassLoader
 */
export interface ClassLoader {
    /**
     * 
     * @type {Array<ModelPackage>}
     * @memberof ClassLoader
     */
    definedPackages: Array<ModelPackage>;
    /**
     * 
     * @type {string}
     * @memberof ClassLoader
     */
    name: string;
    /**
     * 
     * @type {ClassLoader}
     * @memberof ClassLoader
     */
    parent: ClassLoader;
    /**
     * 
     * @type {boolean}
     * @memberof ClassLoader
     */
    registeredAsParallelCapable: boolean;
    /**
     * 
     * @type {Module}
     * @memberof ClassLoader
     */
    unnamedModule: Module;
}
/**
 * 
 * @export
 * @interface ClassementFluxDto
 */
export interface ClassementFluxDto {
    /**
     * 
     * @type {string}
     * @memberof ClassementFluxDto
     */
    classement: ClassementFluxDtoClassementEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ClassementFluxDto
     */
    emissionEstimation: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassementFluxDto
     */
    fluxFullName: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassementFluxDto
     */
    referenceFlux: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ClassementFluxDtoClassementEnum {
    'DE_MINIMIS' = 'DE_MINIMIS',
    'FLUX_MAJEUR' = 'FLUX_MAJEUR',
    'MINEURE' = 'MINEURE'
}

/**
 * 
 * @export
 * @interface Co2N2oTransferDto
 */
export interface Co2N2oTransferDto {
    /**
     * 
     * @type {string}
     * @memberof Co2N2oTransferDto
     */
    exploitantName: string;
    /**
     * 
     * @type {string}
     * @memberof Co2N2oTransferDto
     */
    installationName: string;
    /**
     * 
     * @type {string}
     * @memberof Co2N2oTransferDto
     */
    numeroNim: string;
}
/**
 * 
 * @export
 * @interface CommentDto
 */
export interface CommentDto {
    /**
     * 
     * @type {boolean}
     * @memberof CommentDto
     */
    alert: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommentDto
     */
    dynamic: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof CommentDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface Comparatorbigdecimal
 */
export interface Comparatorbigdecimal {
}
/**
 * 
 * @export
 * @interface ComputedAirCombustionCombustibleDto
 */
export interface ComputedAirCombustionCombustibleDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedAirCombustionCombustibleDto
     */
    consommationPCI: number;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirCombustionCombustibleDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirCombustionCombustibleDto
     */
    methaneOxyde: number;
}
/**
 * 
 * @export
 * @interface ComputedAirCombustionDto1819
 */
export interface ComputedAirCombustionDto1819 {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819>}
     * @memberof ComputedAirCombustionDto1819
     */
    bilan: Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>}
     * @memberof ComputedAirCombustionDto1819
     */
    facteur: Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<ComputedAirCombustionInstallationDto>}
     * @memberof ComputedAirCombustionDto1819
     */
    installations: Array<ComputedAirCombustionInstallationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819>}
     * @memberof ComputedAirCombustionDto1819
     */
    mesure: Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819>;
}
/**
 * 
 * @export
 * @interface ComputedAirCombustionDto2023
 */
export interface ComputedAirCombustionDto2023 {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now>}
     * @memberof ComputedAirCombustionDto2023
     */
    bilan: Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now>;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionCombustionBilanDto20Now; }}
     * @memberof ComputedAirCombustionDto2023
     */
    bilanIntrantsSortants: { [key: string]: ComputedAirEmissionCombustionBilanDto20Now; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ComputedAirCombustionDto2023
     */
    bilanPartsElement: { [key: string]: number; };
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>}
     * @memberof ComputedAirCombustionDto2023
     */
    facteur: Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<ComputedAirCombustionInstallationDto>}
     * @memberof ComputedAirCombustionDto2023
     */
    installations: Array<ComputedAirCombustionInstallationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023>}
     * @memberof ComputedAirCombustionDto2023
     */
    mesure: Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023>;
}
/**
 * 
 * @export
 * @interface ComputedAirCombustionDto24Now
 */
export interface ComputedAirCombustionDto24Now {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now>}
     * @memberof ComputedAirCombustionDto24Now
     */
    bilan: Array<ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now>;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionCombustionBilanDto20Now; }}
     * @memberof ComputedAirCombustionDto24Now
     */
    bilanIntrantsSortants: { [key: string]: ComputedAirEmissionCombustionBilanDto20Now; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ComputedAirCombustionDto24Now
     */
    bilanPartsElement: { [key: string]: number; };
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>}
     * @memberof ComputedAirCombustionDto24Now
     */
    facteur: Array<ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto>;
    /**
     * 
     * @type {Array<ComputedAirCombustionInstallationDto>}
     * @memberof ComputedAirCombustionDto24Now
     */
    installations: Array<ComputedAirCombustionInstallationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now>}
     * @memberof ComputedAirCombustionDto24Now
     */
    mesure: Array<ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now>;
}
/**
 * 
 * @export
 * @interface ComputedAirCombustionInstallationDto
 */
export interface ComputedAirCombustionInstallationDto {
    /**
     * 
     * @type {Array<ComputedAirCombustionCombustibleDto>}
     * @memberof ComputedAirCombustionInstallationDto
     */
    combustibles: Array<ComputedAirCombustionCombustibleDto>;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirCombustionInstallationDto
     */
    id: string;
}
/**
 * 
 * @export
 * @interface ComputedAirDto1819
 */
export interface ComputedAirDto1819 {
    /**
     * 
     * @type {ComputedAirCombustionDto1819}
     * @memberof ComputedAirDto1819
     */
    combustion: ComputedAirCombustionDto1819;
    /**
     * 
     * @type {ComputedAirElevageDto}
     * @memberof ComputedAirDto1819
     */
    elevage: ComputedAirElevageDto;
    /**
     * 
     * @type {ComputedAirFugitivesDto1819}
     * @memberof ComputedAirDto1819
     */
    fugitives: ComputedAirFugitivesDto1819;
    /**
     * 
     * @type {ComputedAirGazDto}
     * @memberof ComputedAirDto1819
     */
    gaz: ComputedAirGazDto;
    /**
     * 
     * @type {ComputedAirIsdndDto}
     * @memberof ComputedAirDto1819
     */
    isdnd: ComputedAirIsdndDto;
    /**
     * 
     * @type {ComputedAirSolvantsDto}
     * @memberof ComputedAirDto1819
     */
    solvants: ComputedAirSolvantsDto;
    /**
     * 
     * @type {ComputedAirSyntheseDto1823}
     * @memberof ComputedAirDto1819
     */
    synthese: ComputedAirSyntheseDto1823;
}
/**
 * 
 * @export
 * @interface ComputedAirDto2023
 */
export interface ComputedAirDto2023 {
    /**
     * 
     * @type {ComputedAirCombustionDto2023}
     * @memberof ComputedAirDto2023
     */
    combustion: ComputedAirCombustionDto2023;
    /**
     * 
     * @type {ComputedAirElevageDto}
     * @memberof ComputedAirDto2023
     */
    elevage: ComputedAirElevageDto;
    /**
     * 
     * @type {ComputedAirFugitivesDto2023}
     * @memberof ComputedAirDto2023
     */
    fugitives: ComputedAirFugitivesDto2023;
    /**
     * 
     * @type {ComputedAirGazDto}
     * @memberof ComputedAirDto2023
     */
    gaz: ComputedAirGazDto;
    /**
     * 
     * @type {ComputedAirIsdndDto}
     * @memberof ComputedAirDto2023
     */
    isdnd: ComputedAirIsdndDto;
    /**
     * 
     * @type {ComputedAirSolvantsDto}
     * @memberof ComputedAirDto2023
     */
    solvants: ComputedAirSolvantsDto;
    /**
     * 
     * @type {ComputedAirSyntheseDto1823}
     * @memberof ComputedAirDto2023
     */
    synthese: ComputedAirSyntheseDto1823;
}
/**
 * 
 * @export
 * @interface ComputedAirDto24Now
 */
export interface ComputedAirDto24Now {
    /**
     * 
     * @type {ComputedAirCombustionDto24Now}
     * @memberof ComputedAirDto24Now
     */
    combustion: ComputedAirCombustionDto24Now;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirDto24Now
     */
    depassementDeSeuilGlobal: boolean;
    /**
     * 
     * @type {ComputedAirElevageDto}
     * @memberof ComputedAirDto24Now
     */
    elevage: ComputedAirElevageDto;
    /**
     * 
     * @type {ComputedAirFugitivesDto24Now}
     * @memberof ComputedAirDto24Now
     */
    fugitives: ComputedAirFugitivesDto24Now;
    /**
     * 
     * @type {ComputedAirGazDto}
     * @memberof ComputedAirDto24Now
     */
    gaz: ComputedAirGazDto;
    /**
     * 
     * @type {ComputedAirIsdndDto}
     * @memberof ComputedAirDto24Now
     */
    isdnd: ComputedAirIsdndDto;
    /**
     * 
     * @type {ComputedAirSolvantsDto}
     * @memberof ComputedAirDto24Now
     */
    solvants: ComputedAirSolvantsDto;
    /**
     * 
     * @type {ComputedAirSyntheseDto24Now}
     * @memberof ComputedAirDto24Now
     */
    synthese: ComputedAirSyntheseDto24Now;
}
/**
 * 
 * @export
 * @interface ComputedAirElevageDto
 */
export interface ComputedAirElevageDto {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto>}
     * @memberof ComputedAirElevageDto
     */
    emissions: Array<ComputedAirEmissionDto>;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionCombustionBilanDto20Now
 */
export interface ComputedAirEmissionCombustionBilanDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    id: string;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionCombustionBilanIntrantDto20Now; }}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    intrants: { [key: string]: ComputedAirEmissionCombustionBilanIntrantDto20Now; };
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    moyenneTeneurIntrant: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    moyenneTeneurSortant: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    quantiteElementDansIntrants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    quantiteElementDansSortants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    quantiteIntrants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    quantiteSortants: number;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionCombustionBilanSortantDto20Now; }}
     * @memberof ComputedAirEmissionCombustionBilanDto20Now
     */
    sortants: { [key: string]: ComputedAirEmissionCombustionBilanSortantDto20Now; };
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionCombustionBilanIntrantDto20Now
 */
export interface ComputedAirEmissionCombustionBilanIntrantDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionCombustionBilanIntrantDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanIntrantDto20Now
     */
    quantiteElement: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionCombustionBilanSortantDto20Now
 */
export interface ComputedAirEmissionCombustionBilanSortantDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionCombustionBilanSortantDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionCombustionBilanSortantDto20Now
     */
    quantiteElement: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto
 */
export interface ComputedAirEmissionDto {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionDto
     */
    substance: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionBilanDto1819}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819
     */
    originalEmission: AirCombustionEmissionBilanDto1819;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto1819
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionBilanDto20Now}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now
     */
    originalEmission: AirCombustionEmissionBilanDto20Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionBilanDto20Now
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionFacteurDto}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto
     */
    originalEmission: AirCombustionEmissionFacteurDto;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionFacteurDto
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionMesureDto1819}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819
     */
    originalEmission: AirCombustionEmissionMesureDto1819;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto1819
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionMesureDto2023}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023
     */
    originalEmission: AirCombustionEmissionMesureDto2023;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto2023
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now
 */
export interface ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirCombustionEmissionMesureDto24Now}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now
     */
    originalEmission: AirCombustionEmissionMesureDto24Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirCombustionEmissionMesureDto24Now
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionBilanDto1819}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819
     */
    originalEmission: AirFugitivesEmissionBilanDto1819;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionBilanDto20Now}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now
     */
    originalEmission: AirFugitivesEmissionBilanDto20Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionCorrelationDto}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto
     */
    originalEmission: AirFugitivesEmissionCorrelationDto;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDto1819}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819
     */
    originalEmission: AirFugitivesEmissionMesureDto1819;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDto2023}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023
     */
    originalEmission: AirFugitivesEmissionMesureDto2023;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now
 */
export interface ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now
     */
    biomasse: boolean;
    /**
     * 
     * @type {AirFugitivesEmissionMesureDto24Now}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now
     */
    originalEmission: AirFugitivesEmissionMesureDto24Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now
     */
    totalEmission: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionFugitivesBilanDto20Now
 */
export interface ComputedAirEmissionFugitivesBilanDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    id: string;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionFugitivesBilanIntrantDto20Now; }}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    intrants: { [key: string]: ComputedAirEmissionFugitivesBilanIntrantDto20Now; };
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    moyenneTeneurIntrant: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    moyenneTeneurSortant: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    quantiteElementDansIntrants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    quantiteElementDansSortants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    quantiteIntrants: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    quantiteSortants: number;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionFugitivesBilanSortantDto20Now; }}
     * @memberof ComputedAirEmissionFugitivesBilanDto20Now
     */
    sortants: { [key: string]: ComputedAirEmissionFugitivesBilanSortantDto20Now; };
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionFugitivesBilanIntrantDto20Now
 */
export interface ComputedAirEmissionFugitivesBilanIntrantDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionFugitivesBilanIntrantDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanIntrantDto20Now
     */
    quantiteElement: number;
}
/**
 * 
 * @export
 * @interface ComputedAirEmissionFugitivesBilanSortantDto20Now
 */
export interface ComputedAirEmissionFugitivesBilanSortantDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedAirEmissionFugitivesBilanSortantDto20Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirEmissionFugitivesBilanSortantDto20Now
     */
    quantiteElement: number;
}
/**
 * 
 * @export
 * @interface ComputedAirFugitivesDto1819
 */
export interface ComputedAirFugitivesDto1819 {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819>}
     * @memberof ComputedAirFugitivesDto1819
     */
    bilan: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto1819>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>}
     * @memberof ComputedAirFugitivesDto1819
     */
    correlation: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819>}
     * @memberof ComputedAirFugitivesDto1819
     */
    mesure: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto1819>;
}
/**
 * 
 * @export
 * @interface ComputedAirFugitivesDto2023
 */
export interface ComputedAirFugitivesDto2023 {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now>}
     * @memberof ComputedAirFugitivesDto2023
     */
    bilan: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now>;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionFugitivesBilanDto20Now; }}
     * @memberof ComputedAirFugitivesDto2023
     */
    bilanIntrantsSortants: { [key: string]: ComputedAirEmissionFugitivesBilanDto20Now; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ComputedAirFugitivesDto2023
     */
    bilanPartsElement: { [key: string]: number; };
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>}
     * @memberof ComputedAirFugitivesDto2023
     */
    correlation: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023>}
     * @memberof ComputedAirFugitivesDto2023
     */
    mesure: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto2023>;
}
/**
 * 
 * @export
 * @interface ComputedAirFugitivesDto24Now
 */
export interface ComputedAirFugitivesDto24Now {
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now>}
     * @memberof ComputedAirFugitivesDto24Now
     */
    bilan: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionBilanDto20Now>;
    /**
     * 
     * @type {{ [key: string]: ComputedAirEmissionFugitivesBilanDto20Now; }}
     * @memberof ComputedAirFugitivesDto24Now
     */
    bilanIntrantsSortants: { [key: string]: ComputedAirEmissionFugitivesBilanDto20Now; };
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ComputedAirFugitivesDto24Now
     */
    bilanPartsElement: { [key: string]: number; };
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>}
     * @memberof ComputedAirFugitivesDto24Now
     */
    correlation: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionCorrelationDto>;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now>}
     * @memberof ComputedAirFugitivesDto24Now
     */
    mesure: Array<ComputedAirEmissionDto18NowAirFugitivesEmissionMesureDto24Now>;
}
/**
 * 
 * @export
 * @interface ComputedAirGazDto
 */
export interface ComputedAirGazDto {
    /**
     * 
     * @type {Array<ComputedAirGazHfcDto>}
     * @memberof ComputedAirGazDto
     */
    hfc: Array<ComputedAirGazHfcDto>;
}
/**
 * 
 * @export
 * @interface ComputedAirGazHfcDto
 */
export interface ComputedAirGazHfcDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedAirGazHfcDto
     */
    autre: number;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirGazHfcDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirGazHfcDto
     */
    nom: string;
}
/**
 * 
 * @export
 * @interface ComputedAirIsdndDto
 */
export interface ComputedAirIsdndDto {
    /**
     * 
     * @type {ComputedAirEmissionDto}
     * @memberof ComputedAirIsdndDto
     */
    biomasse: ComputedAirEmissionDto;
    /**
     * 
     * @type {Array<ComputedAirEmissionDto>}
     * @memberof ComputedAirIsdndDto
     */
    emissions: Array<ComputedAirEmissionDto>;
}
/**
 * 
 * @export
 * @interface ComputedAirSolvantsCovDto
 */
export interface ComputedAirSolvantsCovDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsCovDto
     */
    consommation: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsCovDto
     */
    diffuse: number;
    /**
     * 
     * @type {ComputedAirEmissionDto}
     * @memberof ComputedAirSolvantsCovDto
     */
    emission: ComputedAirEmissionDto;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsCovDto
     */
    utilisation: number;
}
/**
 * 
 * @export
 * @interface ComputedAirSolvantsDto
 */
export interface ComputedAirSolvantsDto {
    /**
     * 
     * @type {Array<ComputedAirSolvantsCovDto>}
     * @memberof ComputedAirSolvantsDto
     */
    covnms: Array<ComputedAirSolvantsCovDto>;
    /**
     * 
     * @type {Array<ComputedAirSolvantsCovDto>}
     * @memberof ComputedAirSolvantsDto
     */
    covs: Array<ComputedAirSolvantsCovDto>;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsDto
     */
    totalCov: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsDto
     */
    totalCovDanger: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSolvantsDto
     */
    totalCovnm: number;
}
/**
 * 
 * @export
 * @interface ComputedAirSyntheseDto1823
 */
export interface ComputedAirSyntheseDto1823 {
    /**
     * 
     * @type {Array<ComputedAirSyntheseEmissionDto1823>}
     * @memberof ComputedAirSyntheseDto1823
     */
    emissions: Array<ComputedAirSyntheseEmissionDto1823>;
}
/**
 * 
 * @export
 * @interface ComputedAirSyntheseDto24Now
 */
export interface ComputedAirSyntheseDto24Now {
    /**
     * 
     * @type {Array<ComputedAirSyntheseEmissionDto24Now>}
     * @memberof ComputedAirSyntheseDto24Now
     */
    emissions: Array<ComputedAirSyntheseEmissionDto24Now>;
}
/**
 * 
 * @export
 * @interface ComputedAirSyntheseEmissionDto1823
 */
export interface ComputedAirSyntheseEmissionDto1823 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirSyntheseEmissionDto1823
     */
    biomasse: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSyntheseEmissionDto1823
     */
    declare: number;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirSyntheseEmissionDto1823
     */
    substanceID: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSyntheseEmissionDto1823
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedAirSyntheseEmissionDto24Now
 */
export interface ComputedAirSyntheseEmissionDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    biomasse: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    declare: number;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    depassementDeSeuilDeclare: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    depassementDeSeuilTotal: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    substanceID: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedAirSyntheseEmissionDto24Now
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedCarriereDto
 */
export interface ComputedCarriereDto {
    /**
     * 
     * @type {ComputedEnvironnementDto}
     * @memberof ComputedCarriereDto
     */
    environnement: ComputedEnvironnementDto;
    /**
     * 
     * @type {ComputedProductionDto}
     * @memberof ComputedCarriereDto
     */
    production: ComputedProductionDto;
}
/**
 * 
 * @export
 * @interface ComputedDechetTotalDto1823
 */
export interface ComputedDechetTotalDto1823 {
    /**
     * 
     * @type {ComputedDechetTotalElementDto1823}
     * @memberof ComputedDechetTotalDto1823
     */
    totalAdmis: ComputedDechetTotalElementDto1823;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalDto1823
     */
    totalEntrantTransfontalier: number | null;
    /**
     * 
     * @type {ComputedDechetTotalElementDto1823}
     * @memberof ComputedDechetTotalDto1823
     */
    totalExpedie: ComputedDechetTotalElementDto1823;
    /**
     * 
     * @type {ComputedDechetTotalElementDto1823}
     * @memberof ComputedDechetTotalDto1823
     */
    totalProduit: ComputedDechetTotalElementDto1823;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalDto1823
     */
    totalSortantTransfontalier: number | null;
    /**
     * 
     * @type {ComputedDechetTotalElementDto1823}
     * @memberof ComputedDechetTotalDto1823
     */
    totalTraite: ComputedDechetTotalElementDto1823;
}
/**
 * 
 * @export
 * @interface ComputedDechetTotalDto24Now
 */
export interface ComputedDechetTotalDto24Now {
    /**
     * 
     * @type {ComputedDechetTotalElementDto24Now}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalAdmis: ComputedDechetTotalElementDto24Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalEntrantTransfontalier: number | null;
    /**
     * 
     * @type {ComputedDechetTotalElementDto24Now}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalExpedie: ComputedDechetTotalElementDto24Now;
    /**
     * 
     * @type {ComputedDechetTotalElementDto24Now}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalProduit: ComputedDechetTotalElementDto24Now;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalSortantTransfontalier: number | null;
    /**
     * 
     * @type {ComputedDechetTotalElementDto24Now}
     * @memberof ComputedDechetTotalDto24Now
     */
    totalTraite: ComputedDechetTotalElementDto24Now;
}
/**
 * 
 * @export
 * @interface ComputedDechetTotalElementDto1823
 */
export interface ComputedDechetTotalElementDto1823 {
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalElementDto1823
     */
    dangereux: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalElementDto1823
     */
    nonDangereux: number | null;
}
/**
 * 
 * @export
 * @interface ComputedDechetTotalElementDto24Now
 */
export interface ComputedDechetTotalElementDto24Now {
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalElementDto24Now
     */
    dangereux: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedDechetTotalElementDto24Now
     */
    depassementDeSeuilDangereux: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedDechetTotalElementDto24Now
     */
    depassementDeSeuilNonDangereux: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetTotalElementDto24Now
     */
    nonDangereux: number | null;
}
/**
 * 
 * @export
 * @interface ComputedDechetsDto1822
 */
export interface ComputedDechetsDto1822 {
    /**
     * 
     * @type {Array<ComputedSyntheseElementDto>}
     * @memberof ComputedDechetsDto1822
     */
    synthese: Array<ComputedSyntheseElementDto>;
    /**
     * 
     * @type {ComputedDechetTotalDto1823}
     * @memberof ComputedDechetsDto1822
     */
    totaux: ComputedDechetTotalDto1823;
}
/**
 * 
 * @export
 * @interface ComputedDechetsDto2323
 */
export interface ComputedDechetsDto2323 {
    /**
     * 
     * @type {Array<ComputedSyntheseElementDto>}
     * @memberof ComputedDechetsDto2323
     */
    synthese: Array<ComputedSyntheseElementDto>;
    /**
     * 
     * @type {ComputedDechetTotalDto1823}
     * @memberof ComputedDechetsDto2323
     */
    totaux: ComputedDechetTotalDto1823;
}
/**
 * 
 * @export
 * @interface ComputedDechetsDto24Now
 */
export interface ComputedDechetsDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedDechetsDto24Now
     */
    depassementDeSeuilGlobal: boolean;
    /**
     * 
     * @type {Array<ComputedSyntheseElementDto>}
     * @memberof ComputedDechetsDto24Now
     */
    synthese: Array<ComputedSyntheseElementDto>;
    /**
     * 
     * @type {ComputedDechetTotalDto24Now}
     * @memberof ComputedDechetsDto24Now
     */
    totaux: ComputedDechetTotalDto24Now | null;
}
/**
 * 
 * @export
 * @interface ComputedDechetsInertesDto
 */
export interface ComputedDechetsInertesDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetsInertesDto
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetsInertesDto
     */
    totalRecycle: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedDechetsInertesDto
     */
    totalRemblaye: number;
}
/**
 * 
 * @export
 * @interface ComputedEauDto1823
 */
export interface ComputedEauDto1823 {
    /**
     * 
     * @type {Array<ComputedPolluantEauDto1823>}
     * @memberof ComputedEauDto1823
     */
    rejetPolluants: Array<ComputedPolluantEauDto1823>;
    /**
     * 
     * @type {number}
     * @memberof ComputedEauDto1823
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedEauDto24Now
 */
export interface ComputedEauDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedEauDto24Now
     */
    depassementDeSeuilGlobal: boolean;
    /**
     * 
     * @type {PrelevementEnEau}
     * @memberof ComputedEauDto24Now
     */
    prelevementEnEau: PrelevementEnEau;
    /**
     * 
     * @type {Array<ComputedPolluantEauDto24Now>}
     * @memberof ComputedEauDto24Now
     */
    rejetPolluants: Array<ComputedPolluantEauDto24Now>;
    /**
     * 
     * @type {number}
     * @memberof ComputedEauDto24Now
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedEnvironnementDto
 */
export interface ComputedEnvironnementDto {
    /**
     * 
     * @type {ComputedDechetsInertesDto}
     * @memberof ComputedEnvironnementDto
     */
    dechetsInertes: ComputedDechetsInertesDto;
    /**
     * 
     * @type {ComputedPoussiereAtmospheriqueDto}
     * @memberof ComputedEnvironnementDto
     */
    poussiereAtmospherique: ComputedPoussiereAtmospheriqueDto;
    /**
     * 
     * @type {ComputedSuperficieDto}
     * @memberof ComputedEnvironnementDto
     */
    superficie: ComputedSuperficieDto;
}
/**
 * 
 * @export
 * @interface ComputedExtractionDto
 */
export interface ComputedExtractionDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedExtractionDto
     */
    totalDontSteriles: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedExtractionDto
     */
    totalSansSteriles: number;
    /**
     * 
     * @type {Array<ComputedSubstanceExtractionDto>}
     * @memberof ComputedExtractionDto
     */
    totalSubstances: Array<ComputedSubstanceExtractionDto>;
}
/**
 * 
 * @export
 * @interface ComputedPolluantEauDto1823
 */
export interface ComputedPolluantEauDto1823 {
    /**
     * 
     * @type {string}
     * @memberof ComputedPolluantEauDto1823
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedPolluantEauDto1823
     */
    masseEmiseRetenue: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedPolluantEauDto1823
     */
    polluant: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedPolluantEauDto1823
     */
    rejetFinal: number | null;
}
/**
 * 
 * @export
 * @interface ComputedPolluantEauDto24Now
 */
export interface ComputedPolluantEauDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedPolluantEauDto24Now
     */
    depassementDeSeuil: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComputedPolluantEauDto24Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedPolluantEauDto24Now
     */
    masseEmiseRetenue: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComputedPolluantEauDto24Now
     */
    polluant: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedPolluantEauDto24Now
     */
    rejetFinal: number | null;
}
/**
 * 
 * @export
 * @interface ComputedPoussiereAtmospheriqueDto
 */
export interface ComputedPoussiereAtmospheriqueDto {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedPoussiereAtmospheriqueDto
     */
    soumisePrescription: boolean;
}
/**
 * 
 * @export
 * @interface ComputedProductionDto
 */
export interface ComputedProductionDto {
    /**
     * 
     * @type {ComputedExtractionDto}
     * @memberof ComputedProductionDto
     */
    extraction: ComputedExtractionDto;
    /**
     * 
     * @type {ComputedRecyclageDto}
     * @memberof ComputedProductionDto
     */
    recyclage: ComputedRecyclageDto;
    /**
     * 
     * @type {ComputedTransportDto}
     * @memberof ComputedProductionDto
     */
    transport: ComputedTransportDto;
}
/**
 * 
 * @export
 * @interface ComputedQuotasAllocationsDto2020
 */
export interface ComputedQuotasAllocationsDto2020 {
    /**
     * 
     * @type {Array<QuotasInstallationDto20Now>}
     * @memberof ComputedQuotasAllocationsDto2020
     */
    installations: Array<QuotasInstallationDto20Now>;
}
/**
 * 
 * @export
 * @interface ComputedQuotasAllocationsDto21Now
 */
export interface ComputedQuotasAllocationsDto21Now {
    /**
     * 
     * @type {Array<QuotasInstallationDto20Now>}
     * @memberof ComputedQuotasAllocationsDto21Now
     */
    installations: Array<QuotasInstallationDto20Now>;
    /**
     * 
     * @type {{ [key: string]: FileDto; }}
     * @memberof ComputedQuotasAllocationsDto21Now
     */
    lastYearAlcVerifieUuidFileMap: { [key: string]: FileDto; };
}
/**
 * 
 * @export
 * @interface ComputedQuotasDto1819
 */
export interface ComputedQuotasDto1819 {
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasDto1819
     */
    totalCO2Biomass: number;
    /**
     * 
     * @type {Array<ComputedQuotasElementDto1819>}
     * @memberof ComputedQuotasDto1819
     */
    totaux: Array<ComputedQuotasElementDto1819>;
}
/**
 * 
 * @export
 * @interface ComputedQuotasDto2020
 */
export interface ComputedQuotasDto2020 {
    /**
     * 
     * @type {ComputedQuotasAllocationsDto2020}
     * @memberof ComputedQuotasDto2020
     */
    allocations: ComputedQuotasAllocationsDto2020;
    /**
     * 
     * @type {ComputedQuotasEmissionsDto2021}
     * @memberof ComputedQuotasDto2020
     */
    emissions: ComputedQuotasEmissionsDto2021;
}
/**
 * 
 * @export
 * @interface ComputedQuotasDto2121
 */
export interface ComputedQuotasDto2121 {
    /**
     * 
     * @type {ComputedQuotasAllocationsDto21Now}
     * @memberof ComputedQuotasDto2121
     */
    allocations: ComputedQuotasAllocationsDto21Now;
    /**
     * 
     * @type {ComputedQuotasEmissionsDto2021}
     * @memberof ComputedQuotasDto2121
     */
    emissions: ComputedQuotasEmissionsDto2021;
}
/**
 * 
 * @export
 * @interface ComputedQuotasDto22Now
 */
export interface ComputedQuotasDto22Now {
    /**
     * 
     * @type {ComputedQuotasAllocationsDto21Now}
     * @memberof ComputedQuotasDto22Now
     */
    allocations: ComputedQuotasAllocationsDto21Now;
    /**
     * 
     * @type {ComputedQuotasEmissionsDto22Now}
     * @memberof ComputedQuotasDto22Now
     */
    emissions: ComputedQuotasEmissionsDto22Now;
}
/**
 * 
 * @export
 * @interface ComputedQuotasElementDto1819
 */
export interface ComputedQuotasElementDto1819 {
    /**
     * 
     * @type {string}
     * @memberof ComputedQuotasElementDto1819
     */
    nim: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto1819
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto1819
     */
    totalCO2NonBiomass: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto1819
     */
    totalN2O: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto1819
     */
    totalPFC: number;
}
/**
 * 
 * @export
 * @interface ComputedQuotasElementDto20Now
 */
export interface ComputedQuotasElementDto20Now {
    /**
     * 
     * @type {string}
     * @memberof ComputedQuotasElementDto20Now
     */
    nimDynID: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto20Now
     */
    total: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto20Now
     */
    totalCO2NonBiomass: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto20Now
     */
    totalN2O: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasElementDto20Now
     */
    totalPFC: number;
}
/**
 * 
 * @export
 * @interface ComputedQuotasEmissionsDto2021
 */
export interface ComputedQuotasEmissionsDto2021 {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedQuotasEmissionsDto2021
     */
    simplified: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasEmissionsDto2021
     */
    totalBlocBilanTotal: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasEmissionsDto2021
     */
    totalCO2Biomass: number;
    /**
     * 
     * @type {Array<ComputedQuotasElementDto20Now>}
     * @memberof ComputedQuotasEmissionsDto2021
     */
    totaux: Array<ComputedQuotasElementDto20Now>;
}
/**
 * 
 * @export
 * @interface ComputedQuotasEmissionsDto22Now
 */
export interface ComputedQuotasEmissionsDto22Now {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedQuotasEmissionsDto22Now
     */
    simplified: boolean;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasEmissionsDto22Now
     */
    totalBlocBilanTotal: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasEmissionsDto22Now
     */
    totalCO2BiomassDurable: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedQuotasEmissionsDto22Now
     */
    totalCO2BiomassNonDurable: number;
    /**
     * 
     * @type {Array<ComputedQuotasElementDto20Now>}
     * @memberof ComputedQuotasEmissionsDto22Now
     */
    totaux: Array<ComputedQuotasElementDto20Now>;
}
/**
 * 
 * @export
 * @interface ComputedRecyclageDto
 */
export interface ComputedRecyclageDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedRecyclageDto
     */
    total: number;
}
/**
 * 
 * @export
 * @interface ComputedSolDto24Now
 */
export interface ComputedSolDto24Now {
    /**
     * 
     * @type {Array<ComputedSolSeuilsDto>}
     * @memberof ComputedSolDto24Now
     */
    computedSolSeuilsDtoList: Array<ComputedSolSeuilsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof ComputedSolDto24Now
     */
    depassementDeSeuilGlobal: boolean;
}
/**
 * 
 * @export
 * @interface ComputedSolSeuilsDto
 */
export interface ComputedSolSeuilsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ComputedSolSeuilsDto
     */
    depassementDeSeuil: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComputedSolSeuilsDto
     */
    substanceID: string;
}
/**
 * 
 * @export
 * @interface ComputedSubstanceExtractionDto
 */
export interface ComputedSubstanceExtractionDto {
    /**
     * 
     * @type {string}
     * @memberof ComputedSubstanceExtractionDto
     */
    substanceID: string;
    /**
     * 
     * @type {number}
     * @memberof ComputedSubstanceExtractionDto
     */
    totalExtrait: number;
}
/**
 * 
 * @export
 * @interface ComputedSuperficieDto
 */
export interface ComputedSuperficieDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    superficieEauEcoulee: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    superficieEauPrecedentes: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    superficieTerreEcoulee: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    superficieTerrePrecedentes: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    totalEcoulee: number;
    /**
     * 
     * @type {number}
     * @memberof ComputedSuperficieDto
     */
    totalPrecedentes: number;
}
/**
 * 
 * @export
 * @interface ComputedSyntheseElementDto
 */
export interface ComputedSyntheseElementDto {
    /**
     * 
     * @type {string}
     * @memberof ComputedSyntheseElementDto
     */
    codeID: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedSyntheseElementDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ComputedSyntheseElementDto
     */
    operation: ComputedSyntheseElementDtoOperationEnum;
    /**
     * 
     * @type {number}
     * @memberof ComputedSyntheseElementDto
     */
    quantite: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ComputedSyntheseElementDtoOperationEnum {
    'PRODUCTION' = 'PRODUCTION',
    'TRAITEMENT' = 'TRAITEMENT'
}

/**
 * 
 * @export
 * @interface ComputedTransportDto
 */
export interface ComputedTransportDto {
    /**
     * 
     * @type {number}
     * @memberof ComputedTransportDto
     */
    totalExpedie: number;
}
/**
 * 
 * @export
 * @interface CourrielStatusDto
 */
export interface CourrielStatusDto {
    /**
     * 
     * @type {string}
     * @memberof CourrielStatusDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof CourrielStatusDto
     */
    status: CourrielStatusDtoStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum CourrielStatusDtoStatusEnum {
    'ALREADY_SUBSCRIBED' = 'ALREADY_SUBSCRIBED',
    'ALREADY_UNSUBSCRIBED' = 'ALREADY_UNSUBSCRIBED',
    'SUBSCRIBED' = 'SUBSCRIBED',
    'UNSUBSCRIBED' = 'UNSUBSCRIBED'
}

/**
 * 
 * @export
 * @interface DechetProduitDto1819
 */
export interface DechetProduitDto1819 {
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    adresseEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    adresseEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    codeID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    departementID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    lieuOperation: DechetProduitDto1819LieuOperationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    method: DechetProduitDto1819MethodEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    methodPrecision: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    methodReference: DechetProduitDto1819MethodReferenceEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    nomEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    nomEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    numeroNotification: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    operationElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto1819
     */
    paysID: string | null;
    /**
     * 
     * @type {number}
     * @memberof DechetProduitDto1819
     */
    quantite: number | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto1819LieuOperationEnum {
    'DEPARTEMENT' = 'DEPARTEMENT',
    'PAYS' = 'PAYS',
    'SITE' = 'SITE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto1819MethodEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE',
    'PESAGE' = 'PESAGE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto1819MethodReferenceEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}

/**
 * 
 * @export
 * @interface DechetProduitDto2022
 */
export interface DechetProduitDto2022 {
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    codeID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    communeEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    communeEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    departementID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetProduitDto2022
     */
    firstReceptionFrance: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    lieuOperation: DechetProduitDto2022LieuOperationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    method: DechetProduitDto2022MethodEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    methodPrecision: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    methodReference: DechetProduitDto2022MethodReferenceEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    nomEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    nomEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    numeroNotification: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    operationElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    paysID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    postalCodeEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    postalCodeEtablissementReception: string | null;
    /**
     * 
     * @type {number}
     * @memberof DechetProduitDto2022
     */
    quantite: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetProduitDto2022
     */
    sortiDuStatutDeDechet: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    streetAdresseEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto2022
     */
    streetAdresseEtablissementReception: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto2022LieuOperationEnum {
    'DEPARTEMENT' = 'DEPARTEMENT',
    'PAYS' = 'PAYS',
    'SITE' = 'SITE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto2022MethodEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE',
    'PESAGE' = 'PESAGE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto2022MethodReferenceEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}

/**
 * 
 * @export
 * @interface DechetProduitDto23Now
 */
export interface DechetProduitDto23Now {
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    codeID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    communeEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    communeEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    departementID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    fillingSource: DechetProduitDto23NowFillingSourceEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetProduitDto23Now
     */
    firstReceptionFrance: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    lieuOperation: DechetProduitDto23NowLieuOperationEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    method: DechetProduitDto23NowMethodEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    methodPrecision: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    methodReference: DechetProduitDto23NowMethodReferenceEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    nomEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    nomEtablissementReception: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    numeroNotification: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    operationElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    paysID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    postalCodeEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    postalCodeEtablissementReception: string | null;
    /**
     * 
     * @type {number}
     * @memberof DechetProduitDto23Now
     */
    quantite: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetProduitDto23Now
     */
    sortiDuStatutDeDechet: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    streetAdresseEtablissementElimination: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetProduitDto23Now
     */
    streetAdresseEtablissementReception: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto23NowFillingSourceEnum {
    'EXTERNAL_INPUT' = 'EXTERNAL_INPUT',
    'USER_INPUT' = 'USER_INPUT'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto23NowLieuOperationEnum {
    'DEPARTEMENT' = 'DEPARTEMENT',
    'PAYS' = 'PAYS',
    'SITE' = 'SITE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto23NowMethodEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE',
    'PESAGE' = 'PESAGE'
}
/**
    * @export
    * @enum {string}
    */
export enum DechetProduitDto23NowMethodReferenceEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}

/**
 * 
 * @export
 * @interface DechetRecuDto1822
 */
export interface DechetRecuDto1822 {
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    codeID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    departementID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetRecuDto1822
     */
    estTraiteDansUnAutrePays: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    numeroNotification: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    operationTraitement: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto1822
     */
    paysID: string | null;
    /**
     * 
     * @type {number}
     * @memberof DechetRecuDto1822
     */
    quantiteAdmise: number | null;
    /**
     * 
     * @type {number}
     * @memberof DechetRecuDto1822
     */
    quantiteTraitee: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetRecuDto1822
     */
    sortiDuStatutDeDechet: boolean | null;
}
/**
 * 
 * @export
 * @interface DechetRecuDto23Now
 */
export interface DechetRecuDto23Now {
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    codeID: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    departementID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetRecuDto23Now
     */
    estTraiteDansUnAutrePays: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    fillingSource: DechetRecuDto23NowFillingSourceEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    numeroNotification: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    operationTraitement: string | null;
    /**
     * 
     * @type {string}
     * @memberof DechetRecuDto23Now
     */
    paysID: string | null;
    /**
     * 
     * @type {number}
     * @memberof DechetRecuDto23Now
     */
    quantiteAdmise: number | null;
    /**
     * 
     * @type {number}
     * @memberof DechetRecuDto23Now
     */
    quantiteTraitee: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DechetRecuDto23Now
     */
    sortiDuStatutDeDechet: boolean | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DechetRecuDto23NowFillingSourceEnum {
    'EXTERNAL_INPUT' = 'EXTERNAL_INPUT',
    'USER_INPUT' = 'USER_INPUT'
}

/**
 * 
 * @export
 * @interface DechetsDto1819
 */
export interface DechetsDto1819 {
    /**
     * 
     * @type {ProductionDechetsDto1819}
     * @memberof DechetsDto1819
     */
    production: ProductionDechetsDto1819;
    /**
     * 
     * @type {ReceptionDechetsDto1822}
     * @memberof DechetsDto1819
     */
    reception: ReceptionDechetsDto1822;
}
/**
 * 
 * @export
 * @interface DechetsDto2022
 */
export interface DechetsDto2022 {
    /**
     * 
     * @type {ProductionDechetsDto2022}
     * @memberof DechetsDto2022
     */
    production: ProductionDechetsDto2022;
    /**
     * 
     * @type {ReceptionDechetsDto1822}
     * @memberof DechetsDto2022
     */
    reception: ReceptionDechetsDto1822;
}
/**
 * 
 * @export
 * @interface DechetsDto2323
 */
export interface DechetsDto2323 {
    /**
     * 
     * @type {ProductionDechetsDto23Now}
     * @memberof DechetsDto2323
     */
    production: ProductionDechetsDto23Now;
    /**
     * 
     * @type {ReceptionDechetsDto23Now}
     * @memberof DechetsDto2323
     */
    reception: ReceptionDechetsDto23Now;
}
/**
 * 
 * @export
 * @interface DechetsDto24Now
 */
export interface DechetsDto24Now {
    /**
     * 
     * @type {ProductionDechetsDto23Now}
     * @memberof DechetsDto24Now
     */
    production: ProductionDechetsDto23Now;
    /**
     * 
     * @type {ReceptionDechetsDto23Now}
     * @memberof DechetsDto24Now
     */
    reception: ReceptionDechetsDto23Now;
    /**
     * 
     * @type {DechetsSynthese24Now}
     * @memberof DechetsDto24Now
     */
    synthese: DechetsSynthese24Now;
}
/**
 * 
 * @export
 * @interface DechetsInertesDto
 */
export interface DechetsInertesDto {
    /**
     * 
     * @type {boolean}
     * @memberof DechetsInertesDto
     */
    aDesApportsDechetsInertes: boolean;
    /**
     * 
     * @type {number}
     * @memberof DechetsInertesDto
     */
    capaciteRestanteTermeAnneeRef: number | null;
    /**
     * 
     * @type {TableauDechetsInertesDto}
     * @memberof DechetsInertesDto
     */
    tableauDechetsInertes: TableauDechetsInertesDto;
}
/**
 * 
 * @export
 * @interface DechetsSynthese24Now
 */
export interface DechetsSynthese24Now {
}
/**
 * 
 * @export
 * @interface DeclarantDto
 */
export interface DeclarantDto {
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    nom: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    prenom: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    role: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarantDto
     */
    telephone: string | null;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto1819
 */
export interface DeclarationBodyDto1819 {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto1819
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {DeclarationSectionsDto1819}
     * @memberof DeclarationBodyDto1819
     */
    sections: DeclarationSectionsDto1819;
    /**
     * 
     * @type {TypeActiviteDto1819}
     * @memberof DeclarationBodyDto1819
     */
    typeActivite: TypeActiviteDto1819;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto1819
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto1819
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto2020
 */
export interface DeclarationBodyDto2020 {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto2020
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {DeclarationSectionsDto2020}
     * @memberof DeclarationBodyDto2020
     */
    sections: DeclarationSectionsDto2020;
    /**
     * 
     * @type {TypeActiviteDto2020}
     * @memberof DeclarationBodyDto2020
     */
    typeActivite: TypeActiviteDto2020;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto2020
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto2020
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto2121
 */
export interface DeclarationBodyDto2121 {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto2121
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {ExternalSectionsDto2121}
     * @memberof DeclarationBodyDto2121
     */
    externalSections: ExternalSectionsDto2121;
    /**
     * 
     * @type {DeclarationSectionsDto2121}
     * @memberof DeclarationBodyDto2121
     */
    sections: DeclarationSectionsDto2121;
    /**
     * 
     * @type {TypeActiviteDto2122}
     * @memberof DeclarationBodyDto2121
     */
    typeActivite: TypeActiviteDto2122;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto2121
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto2121
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto2222
 */
export interface DeclarationBodyDto2222 {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto2222
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {ExternalSectionsDto2222}
     * @memberof DeclarationBodyDto2222
     */
    externalSections: ExternalSectionsDto2222;
    /**
     * 
     * @type {DeclarationSectionsDto2222}
     * @memberof DeclarationBodyDto2222
     */
    sections: DeclarationSectionsDto2222;
    /**
     * 
     * @type {TypeActiviteDto2122}
     * @memberof DeclarationBodyDto2222
     */
    typeActivite: TypeActiviteDto2122;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto2222
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto2222
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto2323
 */
export interface DeclarationBodyDto2323 {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto2323
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {ExternalSectionsDto2323}
     * @memberof DeclarationBodyDto2323
     */
    externalSections: ExternalSectionsDto2323;
    /**
     * 
     * @type {DeclarationSectionsDto2323}
     * @memberof DeclarationBodyDto2323
     */
    sections: DeclarationSectionsDto2323;
    /**
     * 
     * @type {TypeActiviteDto23Now}
     * @memberof DeclarationBodyDto2323
     */
    typeActivite: TypeActiviteDto23Now;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto2323
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto2323
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationBodyDto24Now
 */
export interface DeclarationBodyDto24Now {
    /**
     * 
     * @type {Array<DeclarationCommentDto>}
     * @memberof DeclarationBodyDto24Now
     */
    comments: Array<DeclarationCommentDto>;
    /**
     * 
     * @type {ExternalSectionsDto24Now}
     * @memberof DeclarationBodyDto24Now
     */
    externalSections: ExternalSectionsDto24Now;
    /**
     * 
     * @type {DeclarationSectionsDto24Now}
     * @memberof DeclarationBodyDto24Now
     */
    sections: DeclarationSectionsDto24Now;
    /**
     * 
     * @type {TypeActiviteDto23Now}
     * @memberof DeclarationBodyDto24Now
     */
    typeActivite: TypeActiviteDto23Now;
    /**
     * 
     * @type {Array<DeclarationUserValidationDto>}
     * @memberof DeclarationBodyDto24Now
     */
    userValidation: Array<DeclarationUserValidationDto>;
    /**
     * 
     * @type {DeclarationWorkflowStatusDto}
     * @memberof DeclarationBodyDto24Now
     */
    workflowStatus: DeclarationWorkflowStatusDto;
}
/**
 * 
 * @export
 * @interface DeclarationCommentDto
 */
export interface DeclarationCommentDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationCommentDto
     */
    alert: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    creationDate: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationCommentDto
     */
    dynamic: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    user: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    userDroit: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    userFamilyName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    userName: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarationCommentDto
     */
    value: string;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto1819
 */
export interface DeclarationComputedDto1819 {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto1819
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto1819
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto1819
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto1819}
     * @memberof DeclarationComputedDto1819
     */
    sections: DeclarationComputedSectionsDto1819;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto1819
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto1819
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto2020
 */
export interface DeclarationComputedDto2020 {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto2020
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2020
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto2020
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto2020}
     * @memberof DeclarationComputedDto2020
     */
    sections: DeclarationComputedSectionsDto2020;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2020
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2020
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto2121
 */
export interface DeclarationComputedDto2121 {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto2121
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2121
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto2121
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto2121}
     * @memberof DeclarationComputedDto2121
     */
    sections: DeclarationComputedSectionsDto2121;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2121
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2121
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto2222
 */
export interface DeclarationComputedDto2222 {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto2222
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2222
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto2222
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto2222}
     * @memberof DeclarationComputedDto2222
     */
    sections: DeclarationComputedSectionsDto2222;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2222
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2222
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto2323
 */
export interface DeclarationComputedDto2323 {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto2323
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2323
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto2323
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto2323}
     * @memberof DeclarationComputedDto2323
     */
    sections: DeclarationComputedSectionsDto2323;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2323
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto2323
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedDto24Now
 */
export interface DeclarationComputedDto24Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeclarationComputedDto24Now
     */
    activeSections: Array<string>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto24Now
     */
    errors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {DeclarationProgressionDto}
     * @memberof DeclarationComputedDto24Now
     */
    progression: DeclarationProgressionDto;
    /**
     * 
     * @type {DeclarationComputedSectionsDto24Now}
     * @memberof DeclarationComputedDto24Now
     */
    sections: DeclarationComputedSectionsDto24Now;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto24Now
     */
    verificateurErrors: Array<DeclarationConstraintViolationDto>;
    /**
     * 
     * @type {Array<DeclarationConstraintViolationDto>}
     * @memberof DeclarationComputedDto24Now
     */
    warnings: Array<DeclarationConstraintViolationDto>;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto1819
 */
export interface DeclarationComputedSectionsDto1819 {
    /**
     * 
     * @type {ComputedAirDto1819}
     * @memberof DeclarationComputedSectionsDto1819
     */
    air: ComputedAirDto1819;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto1819
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto1822}
     * @memberof DeclarationComputedSectionsDto1819
     */
    dechets: ComputedDechetsDto1822;
    /**
     * 
     * @type {ComputedEauDto1823}
     * @memberof DeclarationComputedSectionsDto1819
     */
    eau: ComputedEauDto1823;
    /**
     * 
     * @type {ComputedQuotasDto1819}
     * @memberof DeclarationComputedSectionsDto1819
     */
    quotas: ComputedQuotasDto1819;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto2020
 */
export interface DeclarationComputedSectionsDto2020 {
    /**
     * 
     * @type {ComputedAirDto2023}
     * @memberof DeclarationComputedSectionsDto2020
     */
    air: ComputedAirDto2023;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto2020
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto1822}
     * @memberof DeclarationComputedSectionsDto2020
     */
    dechets: ComputedDechetsDto1822;
    /**
     * 
     * @type {ComputedEauDto1823}
     * @memberof DeclarationComputedSectionsDto2020
     */
    eau: ComputedEauDto1823;
    /**
     * 
     * @type {ComputedQuotasDto2020}
     * @memberof DeclarationComputedSectionsDto2020
     */
    quotas: ComputedQuotasDto2020;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto2121
 */
export interface DeclarationComputedSectionsDto2121 {
    /**
     * 
     * @type {ComputedAirDto2023}
     * @memberof DeclarationComputedSectionsDto2121
     */
    air: ComputedAirDto2023;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto2121
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto1822}
     * @memberof DeclarationComputedSectionsDto2121
     */
    dechets: ComputedDechetsDto1822;
    /**
     * 
     * @type {ComputedEauDto1823}
     * @memberof DeclarationComputedSectionsDto2121
     */
    eau: ComputedEauDto1823;
    /**
     * 
     * @type {ComputedQuotasDto2121}
     * @memberof DeclarationComputedSectionsDto2121
     */
    quotas: ComputedQuotasDto2121;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto2222
 */
export interface DeclarationComputedSectionsDto2222 {
    /**
     * 
     * @type {ComputedAirDto2023}
     * @memberof DeclarationComputedSectionsDto2222
     */
    air: ComputedAirDto2023;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto2222
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto1822}
     * @memberof DeclarationComputedSectionsDto2222
     */
    dechets: ComputedDechetsDto1822;
    /**
     * 
     * @type {ComputedEauDto1823}
     * @memberof DeclarationComputedSectionsDto2222
     */
    eau: ComputedEauDto1823;
    /**
     * 
     * @type {ComputedQuotasDto22Now}
     * @memberof DeclarationComputedSectionsDto2222
     */
    quotas: ComputedQuotasDto22Now;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto2323
 */
export interface DeclarationComputedSectionsDto2323 {
    /**
     * 
     * @type {ComputedAirDto2023}
     * @memberof DeclarationComputedSectionsDto2323
     */
    air: ComputedAirDto2023;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto2323
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto2323}
     * @memberof DeclarationComputedSectionsDto2323
     */
    dechets: ComputedDechetsDto2323;
    /**
     * 
     * @type {ComputedEauDto1823}
     * @memberof DeclarationComputedSectionsDto2323
     */
    eau: ComputedEauDto1823;
    /**
     * 
     * @type {ComputedQuotasDto22Now}
     * @memberof DeclarationComputedSectionsDto2323
     */
    quotas: ComputedQuotasDto22Now;
}
/**
 * 
 * @export
 * @interface DeclarationComputedSectionsDto24Now
 */
export interface DeclarationComputedSectionsDto24Now {
    /**
     * 
     * @type {ComputedAirDto24Now}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    air: ComputedAirDto24Now;
    /**
     * 
     * @type {ComputedCarriereDto}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    carriere: ComputedCarriereDto;
    /**
     * 
     * @type {ComputedDechetsDto24Now}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    dechets: ComputedDechetsDto24Now;
    /**
     * 
     * @type {ComputedEauDto24Now}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    eau: ComputedEauDto24Now;
    /**
     * 
     * @type {ComputedQuotasDto22Now}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    quotas: ComputedQuotasDto22Now;
    /**
     * 
     * @type {ComputedSolDto24Now}
     * @memberof DeclarationComputedSectionsDto24Now
     */
    sol: ComputedSolDto24Now;
}
/**
 * 
 * @export
 * @interface DeclarationConstraintViolationDto
 */
export interface DeclarationConstraintViolationDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationConstraintViolationDto
     */
    commentRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeclarationConstraintViolationDto
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationConstraintViolationDto
     */
    path: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationConstraintViolationDto
     */
    preventSubmit: boolean;
}
/**
 * 
 * @export
 * @interface DeclarationDto1819
 */
export interface DeclarationDto1819 {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto1819
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto1819}
     * @memberof DeclarationDto1819
     */
    body: DeclarationBodyDto1819;
    /**
     * 
     * @type {DeclarationComputedDto1819}
     * @memberof DeclarationDto1819
     */
    computed: DeclarationComputedDto1819;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto1819
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto1819
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationDto2020
 */
export interface DeclarationDto2020 {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto2020
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto2020}
     * @memberof DeclarationDto2020
     */
    body: DeclarationBodyDto2020;
    /**
     * 
     * @type {DeclarationComputedDto2020}
     * @memberof DeclarationDto2020
     */
    computed: DeclarationComputedDto2020;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto2020
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto2020
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationDto2121
 */
export interface DeclarationDto2121 {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto2121
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto2121}
     * @memberof DeclarationDto2121
     */
    body: DeclarationBodyDto2121;
    /**
     * 
     * @type {DeclarationComputedDto2121}
     * @memberof DeclarationDto2121
     */
    computed: DeclarationComputedDto2121;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto2121
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto2121
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationDto2222
 */
export interface DeclarationDto2222 {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto2222
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto2222}
     * @memberof DeclarationDto2222
     */
    body: DeclarationBodyDto2222;
    /**
     * 
     * @type {DeclarationComputedDto2222}
     * @memberof DeclarationDto2222
     */
    computed: DeclarationComputedDto2222;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto2222
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto2222
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationDto2323
 */
export interface DeclarationDto2323 {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto2323
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto2323}
     * @memberof DeclarationDto2323
     */
    body: DeclarationBodyDto2323;
    /**
     * 
     * @type {DeclarationComputedDto2323}
     * @memberof DeclarationDto2323
     */
    computed: DeclarationComputedDto2323;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto2323
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto2323
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationDto24Now
 */
export interface DeclarationDto24Now {
    /**
     * 
     * @type {number}
     * @memberof DeclarationDto24Now
     */
    annee: number;
    /**
     * 
     * @type {DeclarationBodyDto24Now}
     * @memberof DeclarationDto24Now
     */
    body: DeclarationBodyDto24Now;
    /**
     * 
     * @type {DeclarationComputedDto24Now}
     * @memberof DeclarationDto24Now
     */
    computed: DeclarationComputedDto24Now;
    /**
     * 
     * @type {string}
     * @memberof DeclarationDto24Now
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof DeclarationDto24Now
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface DeclarationHeaderDto
 */
export interface DeclarationHeaderDto {
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationHeaderDto
     */
    allocation: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    codeRegion: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    creationDate: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    departement: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    equipeGunId: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    etablissementName: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    lastTimeEditedDeclaring: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    lastTimeEditedInspector: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationHeaderDto
     */
    natureService: DeclarationHeaderDtoNatureServiceEnum;
    /**
     * 
     * @type {number}
     * @memberof DeclarationHeaderDto
     */
    progression: number;
}

/**
    * @export
    * @enum {string}
    */
export enum DeclarationHeaderDtoNatureServiceEnum {
    'ASNR' = 'ASNR',
    'BQA' = 'BQA',
    'CGA' = 'CGA',
    'DDCSPP' = 'DDCSPP',
    'DDTM' = 'DDTM',
    'DREAL' = 'DREAL',
    'DSND' = 'DSND'
}

/**
 * 
 * @export
 * @interface DeclarationInvalidationPathDto
 */
export interface DeclarationInvalidationPathDto {
    /**
     * 
     * @type {string}
     * @memberof DeclarationInvalidationPathDto
     */
    path: string;
}
/**
 * 
 * @export
 * @interface DeclarationProgressionDto
 */
export interface DeclarationProgressionDto {
    /**
     * 
     * @type {Array<IndividualProgressionDto>}
     * @memberof DeclarationProgressionDto
     */
    progressions: Array<IndividualProgressionDto>;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto1819
 */
export interface DeclarationSectionsDto1819 {
    /**
     * 
     * @type {AirDto1819}
     * @memberof DeclarationSectionsDto1819
     */
    air: AirDto1819;
    /**
     * 
     * @type {CarriereDto1819}
     * @memberof DeclarationSectionsDto1819
     */
    carriere: CarriereDto1819;
    /**
     * 
     * @type {DechetsDto1819}
     * @memberof DeclarationSectionsDto1819
     */
    dechets: DechetsDto1819;
    /**
     * 
     * @type {EauDto1822}
     * @memberof DeclarationSectionsDto1819
     */
    eau: EauDto1822;
    /**
     * 
     * @type {InfoGeneraleDto1819}
     * @memberof DeclarationSectionsDto1819
     */
    infoGenerale: InfoGeneraleDto1819;
    /**
     * 
     * @type {QuotasDto1819}
     * @memberof DeclarationSectionsDto1819
     */
    quotas: QuotasDto1819;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto1819
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto1819
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto1819
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto2020
 */
export interface DeclarationSectionsDto2020 {
    /**
     * 
     * @type {AirDto2023}
     * @memberof DeclarationSectionsDto2020
     */
    air: AirDto2023;
    /**
     * 
     * @type {CarriereDto2022}
     * @memberof DeclarationSectionsDto2020
     */
    carriere: CarriereDto2022;
    /**
     * 
     * @type {DechetsDto2022}
     * @memberof DeclarationSectionsDto2020
     */
    dechets: DechetsDto2022;
    /**
     * 
     * @type {EauDto1822}
     * @memberof DeclarationSectionsDto2020
     */
    eau: EauDto1822;
    /**
     * 
     * @type {InfoGeneraleDto2020}
     * @memberof DeclarationSectionsDto2020
     */
    infoGenerale: InfoGeneraleDto2020;
    /**
     * 
     * @type {QuotasDto2020}
     * @memberof DeclarationSectionsDto2020
     */
    quotas: QuotasDto2020;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto2020
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto2020
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto2020
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto2121
 */
export interface DeclarationSectionsDto2121 {
    /**
     * 
     * @type {AirDto2023}
     * @memberof DeclarationSectionsDto2121
     */
    air: AirDto2023;
    /**
     * 
     * @type {CarriereDto2022}
     * @memberof DeclarationSectionsDto2121
     */
    carriere: CarriereDto2022;
    /**
     * 
     * @type {DechetsDto2022}
     * @memberof DeclarationSectionsDto2121
     */
    dechets: DechetsDto2022;
    /**
     * 
     * @type {EauDto1822}
     * @memberof DeclarationSectionsDto2121
     */
    eau: EauDto1822;
    /**
     * 
     * @type {InfoGeneraleDto2122}
     * @memberof DeclarationSectionsDto2121
     */
    infoGenerale: InfoGeneraleDto2122;
    /**
     * 
     * @type {QuotasDto2121}
     * @memberof DeclarationSectionsDto2121
     */
    quotas: QuotasDto2121;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto2121
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto2121
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto2121
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto2222
 */
export interface DeclarationSectionsDto2222 {
    /**
     * 
     * @type {AirDto2023}
     * @memberof DeclarationSectionsDto2222
     */
    air: AirDto2023;
    /**
     * 
     * @type {CarriereDto2022}
     * @memberof DeclarationSectionsDto2222
     */
    carriere: CarriereDto2022;
    /**
     * 
     * @type {DechetsDto2022}
     * @memberof DeclarationSectionsDto2222
     */
    dechets: DechetsDto2022;
    /**
     * 
     * @type {EauDto1822}
     * @memberof DeclarationSectionsDto2222
     */
    eau: EauDto1822;
    /**
     * 
     * @type {InfoGeneraleDto2122}
     * @memberof DeclarationSectionsDto2222
     */
    infoGenerale: InfoGeneraleDto2122;
    /**
     * 
     * @type {QuotasDto22Now}
     * @memberof DeclarationSectionsDto2222
     */
    quotas: QuotasDto22Now;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto2222
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto2222
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto2222
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto2323
 */
export interface DeclarationSectionsDto2323 {
    /**
     * 
     * @type {AirDto2023}
     * @memberof DeclarationSectionsDto2323
     */
    air: AirDto2023;
    /**
     * 
     * @type {CarriereDto23Now}
     * @memberof DeclarationSectionsDto2323
     */
    carriere: CarriereDto23Now;
    /**
     * 
     * @type {DechetsDto2323}
     * @memberof DeclarationSectionsDto2323
     */
    dechets: DechetsDto2323;
    /**
     * 
     * @type {EauDto2323}
     * @memberof DeclarationSectionsDto2323
     */
    eau: EauDto2323;
    /**
     * 
     * @type {InfoGeneraleDto23Now}
     * @memberof DeclarationSectionsDto2323
     */
    infoGenerale: InfoGeneraleDto23Now;
    /**
     * 
     * @type {QuotasDto22Now}
     * @memberof DeclarationSectionsDto2323
     */
    quotas: QuotasDto22Now;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto2323
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto2323
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto2323
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationSectionsDto24Now
 */
export interface DeclarationSectionsDto24Now {
    /**
     * 
     * @type {AirDto24Now}
     * @memberof DeclarationSectionsDto24Now
     */
    air: AirDto24Now;
    /**
     * 
     * @type {CarriereDto23Now}
     * @memberof DeclarationSectionsDto24Now
     */
    carriere: CarriereDto23Now;
    /**
     * 
     * @type {DechetsDto24Now}
     * @memberof DeclarationSectionsDto24Now
     */
    dechets: DechetsDto24Now;
    /**
     * 
     * @type {EauDto24Now}
     * @memberof DeclarationSectionsDto24Now
     */
    eau: EauDto24Now;
    /**
     * 
     * @type {InfoGeneraleDto23Now}
     * @memberof DeclarationSectionsDto24Now
     */
    infoGenerale: InfoGeneraleDto23Now;
    /**
     * 
     * @type {QuotasDto22Now}
     * @memberof DeclarationSectionsDto24Now
     */
    quotas: QuotasDto22Now;
    /**
     * 
     * @type {boolean}
     * @memberof DeclarationSectionsDto24Now
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {SolDto}
     * @memberof DeclarationSectionsDto24Now
     */
    sol: SolDto;
    /**
     * 
     * @type {SpfoDto}
     * @memberof DeclarationSectionsDto24Now
     */
    spfo: SpfoDto;
}
/**
 * 
 * @export
 * @interface DeclarationStateDto
 */
export interface DeclarationStateDto {
    /**
     * 
     * @type {string}
     * @memberof DeclarationStateDto
     */
    checkpoint: DeclarationStateDtoCheckpointEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarationStateDto
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationStateDto
     */
    message: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeclarationStateDto
     */
    state: DeclarationStateDtoStateEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DeclarationStateDtoCheckpointEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum DeclarationStateDtoStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}

/**
 * 
 * @export
 * @interface DeclarationUserValidationDto
 */
export interface DeclarationUserValidationDto {
    /**
     * 
     * @type {string}
     * @memberof DeclarationUserValidationDto
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof DeclarationUserValidationDto
     */
    userValidationPath: string;
}
/**
 * 
 * @export
 * @interface DeclarationValidationPathDto
 */
export interface DeclarationValidationPathDto {
    /**
     * 
     * @type {string}
     * @memberof DeclarationValidationPathDto
     */
    path: string;
}
/**
 * 
 * @export
 * @interface DeclarationWorkflowStatusDto
 */
export interface DeclarationWorkflowStatusDto {
    /**
     * 
     * @type {DeclarationStateDto}
     * @memberof DeclarationWorkflowStatusDto
     */
    general: DeclarationStateDto;
    /**
     * 
     * @type {DeclarationStateDto}
     * @memberof DeclarationWorkflowStatusDto
     */
    quotaAllocations: DeclarationStateDto;
    /**
     * 
     * @type {DeclarationStateDto}
     * @memberof DeclarationWorkflowStatusDto
     */
    quotaEmissions: DeclarationStateDto;
}
/**
 * 
 * @export
 * @interface DeleteCommentDto
 */
export interface DeleteCommentDto {
    /**
     * 
     * @type {string}
     * @memberof DeleteCommentDto
     */
    creationDate: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCommentDto
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof DeleteCommentDto
     */
    user: string;
}
/**
 * 
 * @export
 * @interface DescriptionAccidentDto
 */
export interface DescriptionAccidentDto {
    /**
     * 
     * @type {string}
     * @memberof DescriptionAccidentDto
     */
    accidentDate: string | null;
    /**
     * 
     * @type {string}
     * @memberof DescriptionAccidentDto
     */
    causePrincipale: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DescriptionAccidentDto
     */
    estConsolide: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DescriptionAccidentDto
     */
    estMortel: boolean;
    /**
     * 
     * @type {string}
     * @memberof DescriptionAccidentDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof DescriptionAccidentDto
     */
    nbJoursArretTravail: number | null;
    /**
     * 
     * @type {string}
     * @memberof DescriptionAccidentDto
     */
    precisionSiAutre: string | null;
}
/**
 * 
 * @export
 * @interface DetailsSuperficieEcouleeDto
 */
export interface DetailsSuperficieEcouleeDto {
    /**
     * 
     * @type {SuperficieEauEcouleeDto}
     * @memberof DetailsSuperficieEcouleeDto
     */
    remiseEtatEau: SuperficieEauEcouleeDto;
    /**
     * 
     * @type {SuperficieTerreEcouleeDto}
     * @memberof DetailsSuperficieEcouleeDto
     */
    remiseEtatTerre: SuperficieTerreEcouleeDto;
}
/**
 * 
 * @export
 * @interface DetailsSuperficiePrecedenteDto
 */
export interface DetailsSuperficiePrecedenteDto {
    /**
     * 
     * @type {SuperficieEauPrecedenteDto}
     * @memberof DetailsSuperficiePrecedenteDto
     */
    remiseEtatEau: SuperficieEauPrecedenteDto;
    /**
     * 
     * @type {SuperficieTerrePrecedenteDto}
     * @memberof DetailsSuperficiePrecedenteDto
     */
    remiseEtatTerre: SuperficieTerrePrecedenteDto;
}
/**
 * 
 * @export
 * @interface DonneeActiviteDto
 */
export interface DonneeActiviteDto {
    /**
     * 
     * @type {string}
     * @memberof DonneeActiviteDto
     */
    niveauApplique: DonneeActiviteDtoNiveauAppliqueEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeActiviteDto
     */
    niveauIncertitude: string | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeActiviteDto
     */
    niveauRequis: DonneeActiviteDtoNiveauRequisEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DonneeActiviteDtoNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}
/**
    * @export
    * @enum {string}
    */
export enum DonneeActiviteDtoNiveauRequisEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface DonneeSurveillanceDto
 */
export interface DonneeSurveillanceDto {
    /**
     * 
     * @type {string}
     * @memberof DonneeSurveillanceDto
     */
    frequenceAnalyse: DonneeSurveillanceDtoFrequenceAnalyseEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeSurveillanceDto
     */
    niveauApplique: DonneeSurveillanceDtoNiveauAppliqueEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeSurveillanceDto
     */
    niveauRequis: DonneeSurveillanceDtoNiveauRequisEnum | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeSurveillanceDto
     */
    unite: string | null;
    /**
     * 
     * @type {string}
     * @memberof DonneeSurveillanceDto
     */
    valeurDefaut: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum DonneeSurveillanceDtoFrequenceAnalyseEnum {
    'ANNUELLE' = 'ANNUELLE',
    'CONTINUE' = 'CONTINUE',
    'HEBDOMADAIRE' = 'HEBDOMADAIRE',
    'MENSUELLE' = 'MENSUELLE',
    'QUOTIDIENNE' = 'QUOTIDIENNE',
    'SEMESTRIELLE' = 'SEMESTRIELLE',
    'S_O' = 'S_O'
}
/**
    * @export
    * @enum {string}
    */
export enum DonneeSurveillanceDtoNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}
/**
    * @export
    * @enum {string}
    */
export enum DonneeSurveillanceDtoNiveauRequisEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface EauBlocInfoGeneralesDto
 */
export interface EauBlocInfoGeneralesDto {
    /**
     * 
     * @type {boolean}
     * @memberof EauBlocInfoGeneralesDto
     */
    depassementSeuilPrelevement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EauBlocInfoGeneralesDto
     */
    megathermie: boolean;
}
/**
 * 
 * @export
 * @interface EauBlocPrelevementDto1822
 */
export interface EauBlocPrelevementDto1822 {
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto1822
     */
    codeSandreMerOcean: string | null;
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto1822
     */
    codeSandreSouterrainID: string | null;
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto1822
     */
    codeSandreSurfaceID: string | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    nombreJoursTravailles: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    prelevementTotal: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    volumeEauxSouterraines: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    volumeEauxSurface: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    volumeMerOcean: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto1822
     */
    volumeReseauDistribution: number | null;
}
/**
 * 
 * @export
 * @interface EauBlocPrelevementDto23Now
 */
export interface EauBlocPrelevementDto23Now {
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto23Now
     */
    codeSandreMerOcean: string | null;
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto23Now
     */
    codeSandreSouterrainID: string | null;
    /**
     * 
     * @type {string}
     * @memberof EauBlocPrelevementDto23Now
     */
    codeSandreSurfaceID: string | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    nombreJoursTravailles: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    prelevementTotal: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    volumeEauxSouterraines: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    volumeEauxSuperficielles: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    volumeMerOcean: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    volumeReseauAdductionAutreAEP: number | null;
    /**
     * 
     * @type {number}
     * @memberof EauBlocPrelevementDto23Now
     */
    volumeReseauPotableAEP: number | null;
}
/**
 * 
 * @export
 * @interface EauBlocRejetEauDto1822
 */
export interface EauBlocRejetEauDto1822 {
    /**
     * 
     * @type {RejetIsoleEauDto}
     * @memberof EauBlocRejetEauDto1822
     */
    rejetIsole: RejetIsoleEauDto;
    /**
     * 
     * @type {RejetRaccordeEauDto1822}
     * @memberof EauBlocRejetEauDto1822
     */
    rejetRaccorde: RejetRaccordeEauDto1822;
}
/**
 * 
 * @export
 * @interface EauBlocRejetEauDto23Now
 */
export interface EauBlocRejetEauDto23Now {
    /**
     * 
     * @type {RejetIsoleEauDto}
     * @memberof EauBlocRejetEauDto23Now
     */
    rejetIsole: RejetIsoleEauDto;
    /**
     * 
     * @type {RejetRaccordeEauDto23Now}
     * @memberof EauBlocRejetEauDto23Now
     */
    rejetRaccorde: RejetRaccordeEauDto23Now;
}
/**
 * 
 * @export
 * @interface EauBlocRejetPolluantsDto
 */
export interface EauBlocRejetPolluantsDto {
    /**
     * 
     * @type {Array<PolluantEauDto>}
     * @memberof EauBlocRejetPolluantsDto
     */
    rejetPolluants: Array<PolluantEauDto>;
}
/**
 * 
 * @export
 * @interface EauDto1822
 */
export interface EauDto1822 {
    /**
     * 
     * @type {EauBlocInfoGeneralesDto}
     * @memberof EauDto1822
     */
    infoGenerales: EauBlocInfoGeneralesDto;
    /**
     * 
     * @type {EauBlocPrelevementDto1822}
     * @memberof EauDto1822
     */
    prelevement: EauBlocPrelevementDto1822;
    /**
     * 
     * @type {EauBlocRejetEauDto1822}
     * @memberof EauDto1822
     */
    rejetEau: EauBlocRejetEauDto1822;
    /**
     * 
     * @type {EauBlocRejetPolluantsDto}
     * @memberof EauDto1822
     */
    rejetPolluants: EauBlocRejetPolluantsDto;
}
/**
 * 
 * @export
 * @interface EauDto2323
 */
export interface EauDto2323 {
    /**
     * 
     * @type {EauBlocInfoGeneralesDto}
     * @memberof EauDto2323
     */
    infoGenerales: EauBlocInfoGeneralesDto;
    /**
     * 
     * @type {EauBlocPrelevementDto23Now}
     * @memberof EauDto2323
     */
    prelevement: EauBlocPrelevementDto23Now;
    /**
     * 
     * @type {EauBlocRejetEauDto23Now}
     * @memberof EauDto2323
     */
    rejetEau: EauBlocRejetEauDto23Now;
    /**
     * 
     * @type {EauBlocRejetPolluantsDto}
     * @memberof EauDto2323
     */
    rejetPolluants: EauBlocRejetPolluantsDto;
}
/**
 * 
 * @export
 * @interface EauDto24Now
 */
export interface EauDto24Now {
    /**
     * 
     * @type {EauBlocInfoGeneralesDto}
     * @memberof EauDto24Now
     */
    infoGenerales: EauBlocInfoGeneralesDto;
    /**
     * 
     * @type {EauBlocPrelevementDto23Now}
     * @memberof EauDto24Now
     */
    prelevement: EauBlocPrelevementDto23Now;
    /**
     * 
     * @type {EauBlocRejetEauDto23Now}
     * @memberof EauDto24Now
     */
    rejetEau: EauBlocRejetEauDto23Now;
    /**
     * 
     * @type {EauBlocRejetPolluantsDto}
     * @memberof EauDto24Now
     */
    rejetPolluants: EauBlocRejetPolluantsDto;
}
/**
 * 
 * @export
 * @interface ElementBasiqueDto
 */
export interface ElementBasiqueDto {
    /**
     * 
     * @type {string}
     * @memberof ElementBasiqueDto
     */
    identifier: string;
    /**
     * 
     * @type {string}
     * @memberof ElementBasiqueDto
     */
    precisionSiAutre: string;
}
/**
 * 
 * @export
 * @interface EmailUnsubscriptionDto
 */
export interface EmailUnsubscriptionDto {
    /**
     * 
     * @type {string}
     * @memberof EmailUnsubscriptionDto
     */
    creationDate: string;
    /**
     * 
     * @type {string}
     * @memberof EmailUnsubscriptionDto
     */
    emailDeclarant: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailUnsubscriptionDto
     */
    initiateReminderDisabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailUnsubscriptionDto
     */
    newElement: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EmailUnsubscriptionDto
     */
    validationReminderDisabled: boolean;
}
/**
 * 
 * @export
 * @interface EmissionsTotalesCO2eDto
 */
export interface EmissionsTotalesCO2eDto {
    /**
     * 
     * @type {number}
     * @memberof EmissionsTotalesCO2eDto
     */
    emission: number | null;
}
/**
 * 
 * @export
 * @interface EmpoussierageDto
 */
export interface EmpoussierageDto {
    /**
     * 
     * @type {boolean}
     * @memberof EmpoussierageDto
     */
    mesureEffectuee: boolean;
    /**
     * 
     * @type {number}
     * @memberof EmpoussierageDto
     */
    nbreGEH: number | null;
    /**
     * 
     * @type {TableauQuartzEmpoussierageDto}
     * @memberof EmpoussierageDto
     */
    tableauEmpoussierageAlveolaireQuartz: TableauQuartzEmpoussierageDto;
    /**
     * 
     * @type {TableauTotalEmpoussierageDto}
     * @memberof EmpoussierageDto
     */
    tableauEmpoussierageAlveolaireTotal: TableauTotalEmpoussierageDto;
}
/**
 * 
 * @export
 * @interface Environment
 */
export interface Environment {
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    activeProfiles: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Environment
     */
    defaultProfiles: Array<string>;
}
/**
 * 
 * @export
 * @interface EnvironnementDto1819
 */
export interface EnvironnementDto1819 {
    /**
     * 
     * @type {AutresRenseignementsSuperficieDto}
     * @memberof EnvironnementDto1819
     */
    autresRenseignementsSuperficie: AutresRenseignementsSuperficieDto;
    /**
     * 
     * @type {BruitDto}
     * @memberof EnvironnementDto1819
     */
    bruit: BruitDto;
    /**
     * 
     * @type {DechetsInertesDto}
     * @memberof EnvironnementDto1819
     */
    dechetsInertes: DechetsInertesDto;
    /**
     * 
     * @type {EnvironnementGeneralDto}
     * @memberof EnvironnementDto1819
     */
    general: EnvironnementGeneralDto;
    /**
     * 
     * @type {PoussiereAtmospheriqueDto1819}
     * @memberof EnvironnementDto1819
     */
    poussiereAtmospherique: PoussiereAtmospheriqueDto1819;
    /**
     * 
     * @type {PoussiereCanaliseeDto1819}
     * @memberof EnvironnementDto1819
     */
    poussiereCanalisee: PoussiereCanaliseeDto1819;
    /**
     * 
     * @type {SuperficieDto}
     * @memberof EnvironnementDto1819
     */
    superficie: SuperficieDto;
    /**
     * 
     * @type {VibrationDto}
     * @memberof EnvironnementDto1819
     */
    vibration: VibrationDto;
}
/**
 * 
 * @export
 * @interface EnvironnementDto20Now
 */
export interface EnvironnementDto20Now {
    /**
     * 
     * @type {AutresRenseignementsSuperficieDto}
     * @memberof EnvironnementDto20Now
     */
    autresRenseignementsSuperficie: AutresRenseignementsSuperficieDto;
    /**
     * 
     * @type {BruitDto}
     * @memberof EnvironnementDto20Now
     */
    bruit: BruitDto;
    /**
     * 
     * @type {DechetsInertesDto}
     * @memberof EnvironnementDto20Now
     */
    dechetsInertes: DechetsInertesDto;
    /**
     * 
     * @type {EnvironnementGeneralDto}
     * @memberof EnvironnementDto20Now
     */
    general: EnvironnementGeneralDto;
    /**
     * 
     * @type {PoussiereAtmospheriqueDto20Now}
     * @memberof EnvironnementDto20Now
     */
    poussiereAtmospherique: PoussiereAtmospheriqueDto20Now;
    /**
     * 
     * @type {PoussiereCanaliseeDto20Now}
     * @memberof EnvironnementDto20Now
     */
    poussiereCanalisee: PoussiereCanaliseeDto20Now;
    /**
     * 
     * @type {SuperficieDto}
     * @memberof EnvironnementDto20Now
     */
    superficie: SuperficieDto;
    /**
     * 
     * @type {VibrationDto}
     * @memberof EnvironnementDto20Now
     */
    vibration: VibrationDto;
}
/**
 * 
 * @export
 * @interface EnvironnementGeneralDto
 */
export interface EnvironnementGeneralDto {
    /**
     * 
     * @type {string}
     * @memberof EnvironnementGeneralDto
     */
    dateFin: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironnementGeneralDto
     */
    eau: boolean;
    /**
     * 
     * @type {number}
     * @memberof EnvironnementGeneralDto
     */
    prodMax: number | null;
    /**
     * 
     * @type {number}
     * @memberof EnvironnementGeneralDto
     */
    prodMoy: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof EnvironnementGeneralDto
     */
    souterrain: boolean;
}
/**
 * 
 * @export
 * @interface EquipeGunItemDto
 */
export interface EquipeGunItemDto {
    /**
     * 
     * @type {string}
     * @memberof EquipeGunItemDto
     */
    dateDerniereModification: string;
    /**
     * 
     * @type {string}
     * @memberof EquipeGunItemDto
     */
    identifiantEquipe: string;
    /**
     * 
     * @type {string}
     * @memberof EquipeGunItemDto
     */
    nomEquipe: string;
}
/**
 * 
 * @export
 * @interface EtablissementDataDto
 */
export interface EtablissementDataDto {
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    codeRegion: string;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    departement: string;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    equipeGunId: string | null;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    etablissementCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    etablissementName: string;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDataDto
     */
    natureService: EtablissementDataDtoNatureServiceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum EtablissementDataDtoNatureServiceEnum {
    'ASNR' = 'ASNR',
    'BQA' = 'BQA',
    'CGA' = 'CGA',
    'DDCSPP' = 'DDCSPP',
    'DDTM' = 'DDTM',
    'DREAL' = 'DREAL',
    'DSND' = 'DSND'
}

/**
 * 
 * @export
 * @interface EtablissementDto
 */
export interface EtablissementDto {
    /**
     * 
     * @type {number}
     * @memberof EtablissementDto
     */
    annee: number;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDto
     */
    codeRegion: string;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDto
     */
    departement: string;
    /**
     * 
     * @type {MonAiotNatureServiceDto}
     * @memberof EtablissementDto
     */
    natureService: MonAiotNatureServiceDto;
    /**
     * 
     * @type {string}
     * @memberof EtablissementDto
     */
    nom: string;
}
/**
 * 
 * @export
 * @interface EtablissementListDto
 */
export interface EtablissementListDto {
    /**
     * 
     * @type {number}
     * @memberof EtablissementListDto
     */
    count: number;
    /**
     * 
     * @type {Array<EtablissementDto>}
     * @memberof EtablissementListDto
     */
    etablissements: Array<EtablissementDto>;
}
/**
 * 
 * @export
 * @interface EventCommentsDto
 */
export interface EventCommentsDto {
    /**
     * 
     * @type {Array<CommentDto>}
     * @memberof EventCommentsDto
     */
    comments: Array<CommentDto>;
}
/**
 * 
 * @export
 * @interface EventMessageDto
 */
export interface EventMessageDto {
    /**
     * 
     * @type {string}
     * @memberof EventMessageDto
     */
    message: string;
}
/**
 * 
 * @export
 * @interface ExternalActiviteDto2122
 */
export interface ExternalActiviteDto2122 {
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    abscisse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    codeNafID: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    identifiantEquipe: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    identifiantService: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    installationAdresse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    installationCity: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    installationCodePostal: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    nomEtablissement: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    siret: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    systemeCoordonnees: ExternalActiviteDto2122SystemeCoordonneesEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto2122
     */
    unite: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ExternalActiviteDto2122SystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}

/**
 * 
 * @export
 * @interface ExternalActiviteDto23Now
 */
export interface ExternalActiviteDto23Now {
    /**
     * 
     * @type {ExternalActiviteGunDto23Now}
     * @memberof ExternalActiviteDto23Now
     */
    gunDto: ExternalActiviteGunDto23Now;
    /**
     * 
     * @type {ExternalActiviteInseeDto23Now}
     * @memberof ExternalActiviteDto23Now
     */
    inseeDto: ExternalActiviteInseeDto23Now;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto23Now
     */
    matiereProduite: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteDto23Now
     */
    unite: string | null;
}
/**
 * 
 * @export
 * @interface ExternalActiviteGunDto23Now
 */
export interface ExternalActiviteGunDto23Now {
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    abscisse: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    codeInspection: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    identifiantEquipe: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    identifiantService: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    ordonnee: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteGunDto23Now
     */
    systemeCoordonnees: ExternalActiviteGunDto23NowSystemeCoordonneesEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ExternalActiviteGunDto23NowSystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}

/**
 * 
 * @export
 * @interface ExternalActiviteInseeDto23Now
 */
export interface ExternalActiviteInseeDto23Now {
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteInseeDto23Now
     */
    activitePrincipale: string | null;
    /**
     * 
     * @type {Adresse23NowDto}
     * @memberof ExternalActiviteInseeDto23Now
     */
    adresse: Adresse23NowDto;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteInseeDto23Now
     */
    codeNafID: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteInseeDto23Now
     */
    dateFermeture: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteInseeDto23Now
     */
    etatAdministratif: ExternalActiviteInseeDto23NowEtatAdministratifEnum | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalActiviteInseeDto23Now
     */
    nomEtablissement: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum ExternalActiviteInseeDto23NowEtatAdministratifEnum {
    'CLOSED' = 'CLOSED',
    'IN_ACTIVITY' = 'IN_ACTIVITY'
}

/**
 * 
 * @export
 * @interface ExternalBlocAlcDto2123
 */
export interface ExternalBlocAlcDto2123 {
    /**
     * 
     * @type {AlcVerifieDto2123}
     * @memberof ExternalBlocAlcDto2123
     */
    alcVerifie: AlcVerifieDto2123;
}
/**
 * 
 * @export
 * @interface ExternalBlocAlcDto24Now
 */
export interface ExternalBlocAlcDto24Now {
    /**
     * 
     * @type {AlcVerifieDto24Now}
     * @memberof ExternalBlocAlcDto24Now
     */
    alcVerifie: AlcVerifieDto24Now;
}
/**
 * 
 * @export
 * @interface ExternalBlocEmissionsDto2121
 */
export interface ExternalBlocEmissionsDto2121 {
    /**
     * 
     * @type {Array<RapportEmissionJustificatifFilesDto>}
     * @memberof ExternalBlocEmissionsDto2121
     */
    documentsJustificatifs: Array<RapportEmissionJustificatifFilesDto>;
    /**
     * 
     * @type {Array<RapportEmissionDto2121>}
     * @memberof ExternalBlocEmissionsDto2121
     */
    rapportEmissionsList: Array<RapportEmissionDto2121>;
}
/**
 * 
 * @export
 * @interface ExternalBlocEmissionsDto22Now
 */
export interface ExternalBlocEmissionsDto22Now {
    /**
     * 
     * @type {Array<RapportEmissionJustificatifFilesDto>}
     * @memberof ExternalBlocEmissionsDto22Now
     */
    documentsJustificatifs: Array<RapportEmissionJustificatifFilesDto>;
    /**
     * 
     * @type {Array<RapportEmissionDto22Now>}
     * @memberof ExternalBlocEmissionsDto22Now
     */
    rapportEmissionsList: Array<RapportEmissionDto22Now>;
}
/**
 * 
 * @export
 * @interface ExternalBlocPlanMethodSurveillanceDto21Now
 */
export interface ExternalBlocPlanMethodSurveillanceDto21Now {
    /**
     * 
     * @type {Array<PmsJustificatifFilesDto>}
     * @memberof ExternalBlocPlanMethodSurveillanceDto21Now
     */
    documentsJustificatifs: Array<PmsJustificatifFilesDto>;
    /**
     * 
     * @type {Array<PmsDto21Now>}
     * @memberof ExternalBlocPlanMethodSurveillanceDto21Now
     */
    listPms: Array<PmsDto21Now>;
}
/**
 * 
 * @export
 * @interface ExternalBlocPlanSurveillanceDto2121
 */
export interface ExternalBlocPlanSurveillanceDto2121 {
    /**
     * 
     * @type {Array<PdsJustificatifFilesDto>}
     * @memberof ExternalBlocPlanSurveillanceDto2121
     */
    documentsJustificatifs: Array<PdsJustificatifFilesDto>;
    /**
     * 
     * @type {Array<Pds2121>}
     * @memberof ExternalBlocPlanSurveillanceDto2121
     */
    planSurveillanceList: Array<Pds2121>;
}
/**
 * 
 * @export
 * @interface ExternalBlocPlanSurveillanceDto22Now
 */
export interface ExternalBlocPlanSurveillanceDto22Now {
    /**
     * 
     * @type {Array<PdsJustificatifFilesDto>}
     * @memberof ExternalBlocPlanSurveillanceDto22Now
     */
    documentsJustificatifs: Array<PdsJustificatifFilesDto>;
    /**
     * 
     * @type {Array<Pds22Now>}
     * @memberof ExternalBlocPlanSurveillanceDto22Now
     */
    planSurveillanceList: Array<Pds22Now>;
}
/**
 * 
 * @export
 * @interface ExternalDechetsDto23Now
 */
export interface ExternalDechetsDto23Now {
    /**
     * 
     * @type {string}
     * @memberof ExternalDechetsDto23Now
     */
    test: string;
}
/**
 * 
 * @export
 * @interface ExternalEauDto24Now
 */
export interface ExternalEauDto24Now {
    /**
     * 
     * @type {string}
     * @memberof ExternalEauDto24Now
     */
    onlyForJacksonToCompileEmptyClass: string;
}
/**
 * 
 * @export
 * @interface ExternalInfoGeneraleDto2122
 */
export interface ExternalInfoGeneraleDto2122 {
    /**
     * 
     * @type {ExternalActiviteDto2122}
     * @memberof ExternalInfoGeneraleDto2122
     */
    activite: ExternalActiviteDto2122;
    /**
     * 
     * @type {BlocDocumentsDto}
     * @memberof ExternalInfoGeneraleDto2122
     */
    documents: BlocDocumentsDto;
}
/**
 * 
 * @export
 * @interface ExternalInfoGeneraleDto23Now
 */
export interface ExternalInfoGeneraleDto23Now {
    /**
     * 
     * @type {ExternalActiviteDto23Now}
     * @memberof ExternalInfoGeneraleDto23Now
     */
    activite: ExternalActiviteDto23Now;
    /**
     * 
     * @type {BlocDocumentsDto}
     * @memberof ExternalInfoGeneraleDto23Now
     */
    documents: BlocDocumentsDto;
}
/**
 * 
 * @export
 * @interface ExternalQuotasAllocationsDto2123
 */
export interface ExternalQuotasAllocationsDto2123 {
    /**
     * 
     * @type {ExternalBlocAlcDto2123}
     * @memberof ExternalQuotasAllocationsDto2123
     */
    blocAlc: ExternalBlocAlcDto2123;
    /**
     * 
     * @type {ExternalBlocPlanMethodSurveillanceDto21Now}
     * @memberof ExternalQuotasAllocationsDto2123
     */
    blocPlanMethodSurveillance: ExternalBlocPlanMethodSurveillanceDto21Now;
}
/**
 * 
 * @export
 * @interface ExternalQuotasAllocationsDto24Now
 */
export interface ExternalQuotasAllocationsDto24Now {
    /**
     * 
     * @type {ExternalBlocAlcDto24Now}
     * @memberof ExternalQuotasAllocationsDto24Now
     */
    blocAlc: ExternalBlocAlcDto24Now;
    /**
     * 
     * @type {ExternalBlocPlanMethodSurveillanceDto21Now}
     * @memberof ExternalQuotasAllocationsDto24Now
     */
    blocPlanMethodSurveillance: ExternalBlocPlanMethodSurveillanceDto21Now;
}
/**
 * 
 * @export
 * @interface ExternalQuotasDto2121
 */
export interface ExternalQuotasDto2121 {
    /**
     * 
     * @type {ExternalQuotasAllocationsDto2123}
     * @memberof ExternalQuotasDto2121
     */
    allocations: ExternalQuotasAllocationsDto2123;
    /**
     * 
     * @type {ExternalQuotasEmissionsDto2121}
     * @memberof ExternalQuotasDto2121
     */
    emissions: ExternalQuotasEmissionsDto2121;
}
/**
 * 
 * @export
 * @interface ExternalQuotasDto2223
 */
export interface ExternalQuotasDto2223 {
    /**
     * 
     * @type {ExternalQuotasAllocationsDto2123}
     * @memberof ExternalQuotasDto2223
     */
    allocations: ExternalQuotasAllocationsDto2123;
    /**
     * 
     * @type {ExternalQuotasEmissionsDto22Now}
     * @memberof ExternalQuotasDto2223
     */
    emissions: ExternalQuotasEmissionsDto22Now;
}
/**
 * 
 * @export
 * @interface ExternalQuotasDto24Now
 */
export interface ExternalQuotasDto24Now {
    /**
     * 
     * @type {ExternalQuotasAllocationsDto24Now}
     * @memberof ExternalQuotasDto24Now
     */
    allocations: ExternalQuotasAllocationsDto24Now;
    /**
     * 
     * @type {ExternalQuotasEmissionsDto22Now}
     * @memberof ExternalQuotasDto24Now
     */
    emissions: ExternalQuotasEmissionsDto22Now;
}
/**
 * 
 * @export
 * @interface ExternalQuotasEmissionsDto2121
 */
export interface ExternalQuotasEmissionsDto2121 {
    /**
     * 
     * @type {ExternalBlocEmissionsDto2121}
     * @memberof ExternalQuotasEmissionsDto2121
     */
    blocEmissions: ExternalBlocEmissionsDto2121;
    /**
     * 
     * @type {ExternalBlocPlanSurveillanceDto2121}
     * @memberof ExternalQuotasEmissionsDto2121
     */
    blocPlanSurveillance: ExternalBlocPlanSurveillanceDto2121;
}
/**
 * 
 * @export
 * @interface ExternalQuotasEmissionsDto22Now
 */
export interface ExternalQuotasEmissionsDto22Now {
    /**
     * 
     * @type {ExternalBlocEmissionsDto22Now}
     * @memberof ExternalQuotasEmissionsDto22Now
     */
    blocEmissions: ExternalBlocEmissionsDto22Now;
    /**
     * 
     * @type {ExternalBlocPlanSurveillanceDto22Now}
     * @memberof ExternalQuotasEmissionsDto22Now
     */
    blocPlanSurveillance: ExternalBlocPlanSurveillanceDto22Now;
}
/**
 * 
 * @export
 * @interface ExternalSectionsDto2121
 */
export interface ExternalSectionsDto2121 {
    /**
     * 
     * @type {ExternalInfoGeneraleDto2122}
     * @memberof ExternalSectionsDto2121
     */
    infoGenerale: ExternalInfoGeneraleDto2122;
    /**
     * 
     * @type {ExternalQuotasDto2121}
     * @memberof ExternalSectionsDto2121
     */
    quotas: ExternalQuotasDto2121;
}
/**
 * 
 * @export
 * @interface ExternalSectionsDto2222
 */
export interface ExternalSectionsDto2222 {
    /**
     * 
     * @type {ExternalInfoGeneraleDto2122}
     * @memberof ExternalSectionsDto2222
     */
    infoGenerale: ExternalInfoGeneraleDto2122;
    /**
     * 
     * @type {ExternalQuotasDto2223}
     * @memberof ExternalSectionsDto2222
     */
    quotas: ExternalQuotasDto2223;
}
/**
 * 
 * @export
 * @interface ExternalSectionsDto2323
 */
export interface ExternalSectionsDto2323 {
    /**
     * 
     * @type {ExternalDechetsDto23Now}
     * @memberof ExternalSectionsDto2323
     */
    dechets: ExternalDechetsDto23Now;
    /**
     * 
     * @type {ExternalInfoGeneraleDto23Now}
     * @memberof ExternalSectionsDto2323
     */
    infoGenerale: ExternalInfoGeneraleDto23Now;
    /**
     * 
     * @type {ExternalQuotasDto2223}
     * @memberof ExternalSectionsDto2323
     */
    quotas: ExternalQuotasDto2223;
}
/**
 * 
 * @export
 * @interface ExternalSectionsDto24Now
 */
export interface ExternalSectionsDto24Now {
    /**
     * 
     * @type {ExternalDechetsDto23Now}
     * @memberof ExternalSectionsDto24Now
     */
    dechets: ExternalDechetsDto23Now;
    /**
     * 
     * @type {ExternalEauDto24Now}
     * @memberof ExternalSectionsDto24Now
     */
    eau: ExternalEauDto24Now;
    /**
     * 
     * @type {ExternalInfoGeneraleDto23Now}
     * @memberof ExternalSectionsDto24Now
     */
    infoGenerale: ExternalInfoGeneraleDto23Now;
    /**
     * 
     * @type {ExternalQuotasDto24Now}
     * @memberof ExternalSectionsDto24Now
     */
    quotas: ExternalQuotasDto24Now;
}
/**
 * 
 * @export
 * @interface ExtractionDto1822
 */
export interface ExtractionDto1822 {
    /**
     * 
     * @type {number}
     * @memberof ExtractionDto1822
     */
    quantiteRestante: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtractionDto1822
     */
    quantiteSterilesGeneres: number | null;
    /**
     * 
     * @type {Array<SubstanceExtractionDto1822>}
     * @memberof ExtractionDto1822
     */
    substances: Array<SubstanceExtractionDto1822>;
}
/**
 * 
 * @export
 * @interface ExtractionDto23Now
 */
export interface ExtractionDto23Now {
    /**
     * 
     * @type {number}
     * @memberof ExtractionDto23Now
     */
    quantiteRestante: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExtractionDto23Now
     */
    quantiteSterilesGeneres: number | null;
    /**
     * 
     * @type {Array<SubstanceExtractionDto23Now>}
     * @memberof ExtractionDto23Now
     */
    substances: Array<SubstanceExtractionDto23Now>;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    extension: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    size: number;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    type: string;
}
/**
 * 
 * @export
 * @interface FilesRelatedToNimDto
 */
export interface FilesRelatedToNimDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof FilesRelatedToNimDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof FilesRelatedToNimDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface FiltresDeclarationDto
 */
export interface FiltresDeclarationDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltresDeclarationDto
     */
    annees: Array<number> | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    ascending: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    beforeLastModificationDeclaring: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    beforeLastModificationInspector: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    carriere: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    consoOfSolvant: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    declarationGlobalState: FiltresDeclarationDtoDeclarationGlobalStateEnum | null;
    /**
     * 
     * @type {number}
     * @memberof FiltresDeclarationDto
     */
    declarationValidationPercent: number | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    departement: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    elevage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    eprtr: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    isdi: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    isdnd: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    lastModificationTimeDeclaring: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    lastModificationTimeInspector: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    moreThanValidationPercent: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    quotasAllocations: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    quotasAllocationsState: FiltresDeclarationDtoQuotasAllocationsStateEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    quotasEmissions: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    quotasEmissionsState: FiltresDeclarationDtoQuotasEmissionsStateEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    quotasWithAnyExclusions: boolean;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    region: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof FiltresDeclarationDto
     */
    rienADeclarer: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    searchByIdOrNameOrSiret: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    searchByNimNumber: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltresDeclarationDto
     */
    selectedFolderSections: Array<FiltresDeclarationDtoSelectedFolderSectionsEnum> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltresDeclarationDto
     */
    selectedSections: Array<FiltresDeclarationDtoSelectedSectionsEnum> | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresDeclarationDto
     */
    sortBy: FiltresDeclarationDtoSortByEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoDeclarationGlobalStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoQuotasAllocationsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoQuotasEmissionsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoSelectedFolderSectionsEnum {
    'DOCUMENTS_DIVERS' = 'DOCUMENTS_DIVERS',
    'ELEVAGE_FEUILLE_CALCUL' = 'ELEVAGE_FEUILLE_CALCUL',
    'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' = 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS',
    'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' = 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE',
    'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' = 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE',
    'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' = 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE',
    'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' = 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS',
    'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' = 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE',
    'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' = 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE',
    'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' = 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE',
    'QUOTAS_PLAN_SURVEILLANCE' = 'QUOTAS_PLAN_SURVEILLANCE',
    'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' = 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE',
    'QUOTAS_RAPPORT_ALC_VERIFIE' = 'QUOTAS_RAPPORT_ALC_VERIFIE',
    'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' = 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE',
    'QUOTAS_RAPPORT_EMISSIONS' = 'QUOTAS_RAPPORT_EMISSIONS',
    'QUOTAS_RAPPORT_VERIFICATION' = 'QUOTAS_RAPPORT_VERIFICATION',
    'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' = 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS',
    'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' = 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS',
    'SOLVANT_PLAN_GESTION' = 'SOLVANT_PLAN_GESTION'
}
/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoSelectedSectionsEnum {
    'AIR_COMBUSTION' = 'AIR_COMBUSTION',
    'AIR_ELEVAGE' = 'AIR_ELEVAGE',
    'AIR_FUGITIVES' = 'AIR_FUGITIVES',
    'AIR_GAZ_FLUORES' = 'AIR_GAZ_FLUORES',
    'AIR_ISDND' = 'AIR_ISDND',
    'AIR_SOLVANTS' = 'AIR_SOLVANTS',
    'AIR_SYNTHESE' = 'AIR_SYNTHESE',
    'CARRIERE_ENVIRONNEMENT' = 'CARRIERE_ENVIRONNEMENT',
    'CARRIERE_PRODUCTION' = 'CARRIERE_PRODUCTION',
    'CARRIERE_SANTE_SECURITE' = 'CARRIERE_SANTE_SECURITE',
    'DECHETS' = 'DECHETS',
    'EAU' = 'EAU',
    'INFOS_GENERALES' = 'INFOS_GENERALES',
    'QUOTAS_ALLOCATIONS' = 'QUOTAS_ALLOCATIONS',
    'QUOTAS_EMISSIONS' = 'QUOTAS_EMISSIONS',
    'QUOTAS_WITH_ANY_EXCLUSIONS' = 'QUOTAS_WITH_ANY_EXCLUSIONS',
    'RESUME_DECLARATIONS' = 'RESUME_DECLARATIONS',
    'SOL' = 'SOL',
    'SPFO' = 'SPFO',
    'TYPE_ACTIVITE_GLOBAL' = 'TYPE_ACTIVITE_GLOBAL',
    'TYPE_ACTIVITE_QUOTAS' = 'TYPE_ACTIVITE_QUOTAS'
}
/**
    * @export
    * @enum {string}
    */
export enum FiltresDeclarationDtoSortByEnum {
    'ANNEE' = 'ANNEE',
    'COMMUNE' = 'COMMUNE',
    'DEPARTEMENT' = 'DEPARTEMENT',
    'DEPASSEMENT_SEUIL' = 'DEPASSEMENT_SEUIL',
    'ETABLISSEMENT_NAME' = 'ETABLISSEMENT_NAME',
    'GENERAL_STATE' = 'GENERAL_STATE',
    'ID' = 'ID',
    'INSTALLATION_NAME' = 'INSTALLATION_NAME',
    'LAST_MODIFICATION_TIME_ALLOCATION_DECLARING' = 'LAST_MODIFICATION_TIME_ALLOCATION_DECLARING',
    'LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR' = 'LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR',
    'LAST_MODIFICATION_TIME_DECLARING' = 'LAST_MODIFICATION_TIME_DECLARING',
    'LAST_MODIFICATION_TIME_EMISSION_DECLARING' = 'LAST_MODIFICATION_TIME_EMISSION_DECLARING',
    'LAST_MODIFICATION_TIME_EMISSION_INSPECTOR' = 'LAST_MODIFICATION_TIME_EMISSION_INSPECTOR',
    'LAST_MODIFICATION_TIME_INSPECTOR' = 'LAST_MODIFICATION_TIME_INSPECTOR',
    'NATURE_SERVICE' = 'NATURE_SERVICE',
    'NIMS' = 'NIMS',
    'QUOTAS_ALLOCATIONS_STATE' = 'QUOTAS_ALLOCATIONS_STATE',
    'QUOTAS_EMISSIONS_STATE' = 'QUOTAS_EMISSIONS_STATE',
    'REGION' = 'REGION',
    'VALIDATION_PERCENT' = 'VALIDATION_PERCENT'
}

/**
 * 
 * @export
 * @interface FiltresEtablissementDto
 */
export interface FiltresEtablissementDto {
    /**
     * 
     * @type {boolean}
     * @memberof FiltresEtablissementDto
     */
    ascending: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresEtablissementDto
     */
    searchByIdOrNameOrSiret: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresEtablissementDto
     */
    searchByNimNumber: string | null;
    /**
     * 
     * @type {string}
     * @memberof FiltresEtablissementDto
     */
    sortBy: FiltresEtablissementDtoSortByEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum FiltresEtablissementDtoSortByEnum {
    'ANNEE' = 'ANNEE',
    'COMMUNE' = 'COMMUNE',
    'DEPARTEMENT' = 'DEPARTEMENT',
    'DEPASSEMENT_SEUIL' = 'DEPASSEMENT_SEUIL',
    'ETABLISSEMENT_NAME' = 'ETABLISSEMENT_NAME',
    'GENERAL_STATE' = 'GENERAL_STATE',
    'ID' = 'ID',
    'INSTALLATION_NAME' = 'INSTALLATION_NAME',
    'LAST_MODIFICATION_TIME_ALLOCATION_DECLARING' = 'LAST_MODIFICATION_TIME_ALLOCATION_DECLARING',
    'LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR' = 'LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR',
    'LAST_MODIFICATION_TIME_DECLARING' = 'LAST_MODIFICATION_TIME_DECLARING',
    'LAST_MODIFICATION_TIME_EMISSION_DECLARING' = 'LAST_MODIFICATION_TIME_EMISSION_DECLARING',
    'LAST_MODIFICATION_TIME_EMISSION_INSPECTOR' = 'LAST_MODIFICATION_TIME_EMISSION_INSPECTOR',
    'LAST_MODIFICATION_TIME_INSPECTOR' = 'LAST_MODIFICATION_TIME_INSPECTOR',
    'NATURE_SERVICE' = 'NATURE_SERVICE',
    'NIMS' = 'NIMS',
    'QUOTAS_ALLOCATIONS_STATE' = 'QUOTAS_ALLOCATIONS_STATE',
    'QUOTAS_EMISSIONS_STATE' = 'QUOTAS_EMISSIONS_STATE',
    'REGION' = 'REGION',
    'VALIDATION_PERCENT' = 'VALIDATION_PERCENT'
}

/**
 * 
 * @export
 * @interface IDeclarationDto18Nowobjectobject
 */
export interface IDeclarationDto18Nowobjectobject {
    /**
     * 
     * @type {number}
     * @memberof IDeclarationDto18Nowobjectobject
     */
    annee: number;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto18Nowobjectobject
     */
    body: any;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto18Nowobjectobject
     */
    computed: any;
    /**
     * 
     * @type {string}
     * @memberof IDeclarationDto18Nowobjectobject
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof IDeclarationDto18Nowobjectobject
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface IDeclarationDto20Nowobjectobject
 */
export interface IDeclarationDto20Nowobjectobject {
    /**
     * 
     * @type {number}
     * @memberof IDeclarationDto20Nowobjectobject
     */
    annee: number;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto20Nowobjectobject
     */
    body: any;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto20Nowobjectobject
     */
    computed: any;
    /**
     * 
     * @type {string}
     * @memberof IDeclarationDto20Nowobjectobject
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof IDeclarationDto20Nowobjectobject
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface IDeclarationDto21Nowobjectobject
 */
export interface IDeclarationDto21Nowobjectobject {
    /**
     * 
     * @type {number}
     * @memberof IDeclarationDto21Nowobjectobject
     */
    annee: number;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto21Nowobjectobject
     */
    body: any;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto21Nowobjectobject
     */
    computed: any;
    /**
     * 
     * @type {string}
     * @memberof IDeclarationDto21Nowobjectobject
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof IDeclarationDto21Nowobjectobject
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface IDeclarationDto22Nowobjectobject
 */
export interface IDeclarationDto22Nowobjectobject {
    /**
     * 
     * @type {number}
     * @memberof IDeclarationDto22Nowobjectobject
     */
    annee: number;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto22Nowobjectobject
     */
    body: any;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto22Nowobjectobject
     */
    computed: any;
    /**
     * 
     * @type {string}
     * @memberof IDeclarationDto22Nowobjectobject
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof IDeclarationDto22Nowobjectobject
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface IDeclarationDto23Nowobjectobject
 */
export interface IDeclarationDto23Nowobjectobject {
    /**
     * 
     * @type {number}
     * @memberof IDeclarationDto23Nowobjectobject
     */
    annee: number;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto23Nowobjectobject
     */
    body: any;
    /**
     * 
     * @type {any}
     * @memberof IDeclarationDto23Nowobjectobject
     */
    computed: any;
    /**
     * 
     * @type {string}
     * @memberof IDeclarationDto23Nowobjectobject
     */
    etablissementCode: string;
    /**
     * 
     * @type {DeclarationHeaderDto}
     * @memberof IDeclarationDto23Nowobjectobject
     */
    header: DeclarationHeaderDto;
}
/**
 * 
 * @export
 * @interface IndividualProgressionDto
 */
export interface IndividualProgressionDto {
    /**
     * 
     * @type {string}
     * @memberof IndividualProgressionDto
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof IndividualProgressionDto
     */
    percentage: number;
}
/**
 * 
 * @export
 * @interface InfoDto
 */
export interface InfoDto {
    /**
     * 
     * @type {string}
     * @memberof InfoDto
     */
    version: string;
}
/**
 * 
 * @export
 * @interface InfoGeneraleDto1819
 */
export interface InfoGeneraleDto1819 {
    /**
     * 
     * @type {ActiviteDto1819}
     * @memberof InfoGeneraleDto1819
     */
    activite: ActiviteDto1819;
    /**
     * 
     * @type {AdministratifDto1819}
     * @memberof InfoGeneraleDto1819
     */
    administratif: AdministratifDto1819;
    /**
     * 
     * @type {Array<DeclarantDto>}
     * @memberof InfoGeneraleDto1819
     */
    declarants: Array<DeclarantDto>;
}
/**
 * 
 * @export
 * @interface InfoGeneraleDto2020
 */
export interface InfoGeneraleDto2020 {
    /**
     * 
     * @type {ActiviteDto2020}
     * @memberof InfoGeneraleDto2020
     */
    activite: ActiviteDto2020;
    /**
     * 
     * @type {AdministratifDto20Now}
     * @memberof InfoGeneraleDto2020
     */
    administratif: AdministratifDto20Now;
    /**
     * 
     * @type {Array<DeclarantDto>}
     * @memberof InfoGeneraleDto2020
     */
    declarants: Array<DeclarantDto>;
}
/**
 * 
 * @export
 * @interface InfoGeneraleDto2122
 */
export interface InfoGeneraleDto2122 {
    /**
     * 
     * @type {ActiviteDto2122}
     * @memberof InfoGeneraleDto2122
     */
    activite: ActiviteDto2122;
    /**
     * 
     * @type {AdministratifDto20Now}
     * @memberof InfoGeneraleDto2122
     */
    administratif: AdministratifDto20Now;
    /**
     * 
     * @type {Array<DeclarantDto>}
     * @memberof InfoGeneraleDto2122
     */
    declarants: Array<DeclarantDto>;
}
/**
 * 
 * @export
 * @interface InfoGeneraleDto23Now
 */
export interface InfoGeneraleDto23Now {
    /**
     * 
     * @type {ActiviteDto23Now}
     * @memberof InfoGeneraleDto23Now
     */
    activite: ActiviteDto23Now;
    /**
     * 
     * @type {AdministratifDto20Now}
     * @memberof InfoGeneraleDto23Now
     */
    administratif: AdministratifDto20Now;
    /**
     * 
     * @type {Array<DeclarantDto>}
     * @memberof InfoGeneraleDto23Now
     */
    declarants: Array<DeclarantDto>;
}
/**
 * 
 * @export
 * @interface InfosGeneralesAlcDto
 */
export interface InfosGeneralesAlcDto {
    /**
     * 
     * @type {number}
     * @memberof InfosGeneralesAlcDto
     */
    annee: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InfosGeneralesAlcDto
     */
    areCriteresAdmissibiliteFilled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InfosGeneralesAlcDto
     */
    atLeastOneSubInstallationStoppedActivites: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InfosGeneralesAlcDto
     */
    isInstallationEnPlace: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InfosGeneralesAlcDto
     */
    listDateCessationActivites: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InfosGeneralesAlcDto
     */
    numeroNim: string | null;
    /**
     * 
     * @type {{ [key: string]: EmissionsTotalesCO2eDto; }}
     * @memberof InfosGeneralesAlcDto
     */
    yearToEmissionsTotalesCO2eMap: { [key: string]: EmissionsTotalesCO2eDto; };
    /**
     * 
     * @type {{ [key: string]: ResteVentilationDto; }}
     * @memberof InfosGeneralesAlcDto
     */
    yearToResteVentilationMap: { [key: string]: ResteVentilationDto; };
}
/**
 * 
 * @export
 * @interface InfosGeneralesEmissionDto
 */
export interface InfosGeneralesEmissionDto {
    /**
     * 
     * @type {number}
     * @memberof InfosGeneralesEmissionDto
     */
    annee: number | null;
    /**
     * 
     * @type {string}
     * @memberof InfosGeneralesEmissionDto
     */
    numeroNim: string | null;
}
/**
 * 
 * @export
 * @interface InfosGeneralesPdsDto
 */
export interface InfosGeneralesPdsDto {
    /**
     * 
     * @type {CategorieInstallationDto}
     * @memberof InfosGeneralesPdsDto
     */
    categorieInstallationDto: CategorieInstallationDto;
    /**
     * 
     * @type {string}
     * @memberof InfosGeneralesPdsDto
     */
    numeroNim: string | null;
}
/**
 * 
 * @export
 * @interface InfosInstallationDto2123
 */
export interface InfosInstallationDto2123 {
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefAlternatifDto2123; }}
     * @memberof InfosInstallationDto2123
     */
    positionToSousInstallationRefAlternatifMap: { [key: string]: SousInstallationRefAlternatifDto2123; };
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefProduitDto; }}
     * @memberof InfosInstallationDto2123
     */
    positionToSousInstallationRefProduitMap: { [key: string]: SousInstallationRefProduitDto; };
}
/**
 * 
 * @export
 * @interface InfosInstallationDto24Now
 */
export interface InfosInstallationDto24Now {
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefAlternatifDto24Now; }}
     * @memberof InfosInstallationDto24Now
     */
    positionToSousInstallationRefAlternatifMap: { [key: string]: SousInstallationRefAlternatifDto24Now; };
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefProduitDto; }}
     * @memberof InfosInstallationDto24Now
     */
    positionToSousInstallationRefProduitMap: { [key: string]: SousInstallationRefProduitDto; };
}
/**
 * 
 * @export
 * @interface JsonNode
 */
export interface JsonNode {
}
/**
 * 
 * @export
 * @interface JsonPatchDto
 */
export interface JsonPatchDto {
    /**
     * 
     * @type {Array<JsonPatchOperationDto>}
     * @memberof JsonPatchDto
     */
    patch: Array<JsonPatchOperationDto>;
}
/**
 * 
 * @export
 * @interface JsonPatchOperationDto
 */
export interface JsonPatchOperationDto {
    /**
     * 
     * @type {string}
     * @memberof JsonPatchOperationDto
     */
    op: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPatchOperationDto
     */
    path: string;
    /**
     * 
     * @type {any}
     * @memberof JsonPatchOperationDto
     */
    value: any | null;
}
/**
 * 
 * @export
 * @interface LecteurDroitDto
 */
export interface LecteurDroitDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof LecteurDroitDto
     */
    allowedSections: Array<LecteurDroitDtoAllowedSectionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum LecteurDroitDtoAllowedSectionsEnum {
    'CARRIERE' = 'CARRIERE',
    'EAU' = 'EAU',
    'QUOTAS' = 'QUOTAS'
}

/**
 * 
 * @export
 * @interface MateriauxDechetsInertesARecyclerDto
 */
export interface MateriauxDechetsInertesARecyclerDto {
    /**
     * 
     * @type {number}
     * @memberof MateriauxDechetsInertesARecyclerDto
     */
    autresMateriaux: number;
    /**
     * 
     * @type {number}
     * @memberof MateriauxDechetsInertesARecyclerDto
     */
    terreEtCailloux: number;
}
/**
 * 
 * @export
 * @interface MateriauxDechetsInertesARemblayerDto
 */
export interface MateriauxDechetsInertesARemblayerDto {
    /**
     * 
     * @type {number}
     * @memberof MateriauxDechetsInertesARemblayerDto
     */
    autresMateriaux: number;
    /**
     * 
     * @type {number}
     * @memberof MateriauxDechetsInertesARemblayerDto
     */
    terreEtCailloux: number;
}
/**
 * 
 * @export
 * @interface MesureBasiqueBruitEnvironnementDto
 */
export interface MesureBasiqueBruitEnvironnementDto {
    /**
     * 
     * @type {boolean}
     * @memberof MesureBasiqueBruitEnvironnementDto
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueBruitEnvironnementDto
     */
    nbCampagnesMesure: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueBruitEnvironnementDto
     */
    nbPointsMesure: number | null;
}
/**
 * 
 * @export
 * @interface MesureBasiqueBruitEnvironnementSpecifiqueDto
 */
export interface MesureBasiqueBruitEnvironnementSpecifiqueDto {
    /**
     * 
     * @type {boolean}
     * @memberof MesureBasiqueBruitEnvironnementSpecifiqueDto
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueBruitEnvironnementSpecifiqueDto
     */
    nbCampagnesMesure: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueBruitEnvironnementSpecifiqueDto
     */
    nbPointsMesure: number | null;
}
/**
 * 
 * @export
 * @interface MesureBasiqueVibrationExplosifDto
 */
export interface MesureBasiqueVibrationExplosifDto {
    /**
     * 
     * @type {boolean}
     * @memberof MesureBasiqueVibrationExplosifDto
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueVibrationExplosifDto
     */
    nbCampagnesMesure: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueVibrationExplosifDto
     */
    nbPointsMesure: number | null;
}
/**
 * 
 * @export
 * @interface MesureBasiqueVibrationMaterielDto
 */
export interface MesureBasiqueVibrationMaterielDto {
    /**
     * 
     * @type {boolean}
     * @memberof MesureBasiqueVibrationMaterielDto
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueVibrationMaterielDto
     */
    nbCampagnesMesure: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesureBasiqueVibrationMaterielDto
     */
    nbPointsMesure: number | null;
}
/**
 * 
 * @export
 * @interface MesurePoussiereAtmospheriqueDto1819
 */
export interface MesurePoussiereAtmospheriqueDto1819 {
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    jauge: boolean;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    libelle: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    premierTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    quatriemeTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    secondTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    troisiemeTrimestre: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto1819
     */
    typeDEmplacement: MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum {
    'EXTERIEUR' = 'EXTERIEUR',
    'INTERIEUR' = 'INTERIEUR',
    'LIMITE' = 'LIMITE',
    'TEMOIN' = 'TEMOIN'
}

/**
 * 
 * @export
 * @interface MesurePoussiereAtmospheriqueDto20Now
 */
export interface MesurePoussiereAtmospheriqueDto20Now {
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    jauge: boolean;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    libelle: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    premierTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    quatriemeTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    secondTrimestre: number | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    troisiemeTrimestre: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereAtmospheriqueDto20Now
     */
    typeDEmplacement: MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum {
    'STATION_TYPE_A' = 'STATION_TYPE_A',
    'STATION_TYPE_B' = 'STATION_TYPE_B',
    'STATION_TYPE_C' = 'STATION_TYPE_C'
}

/**
 * 
 * @export
 * @interface MesurePoussiereCanaliseeDto1819
 */
export interface MesurePoussiereCanaliseeDto1819 {
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    concentrationPoussiere: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    date: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    debitMoyenCampagne: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    libelle: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto1819
     */
    percentagePM10: number | null;
}
/**
 * 
 * @export
 * @interface MesurePoussiereCanaliseeDto20Now
 */
export interface MesurePoussiereCanaliseeDto20Now {
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    concentrationPoussiere: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    date: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    debitMoyenCampagne: number | null;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    libelle: string | null;
    /**
     * 
     * @type {number}
     * @memberof MesurePoussiereCanaliseeDto20Now
     */
    percentagePM10: number | null;
}
/**
 * 
 * @export
 * @interface MethodeAlternativeDto
 */
export interface MethodeAlternativeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof MethodeAlternativeDto
     */
    descriptions: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof MethodeAlternativeDto
     */
    emissionsBiomasseDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeAlternativeDto
     */
    emissionsBiomasseNonDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeAlternativeDto
     */
    emissionsFossiles: number;
}
/**
 * 
 * @export
 * @interface MethodeCalculCo2Dto2121
 */
export interface MethodeCalculCo2Dto2121 {
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto2121
     */
    emissionsBiomasseDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto2121
     */
    emissionsBiomasseNonDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto2121
     */
    emissionsFossiles: number;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculCo2Dto2121
     */
    fluxName: string;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculCo2Dto2121
     */
    methode: string;
}
/**
 * 
 * @export
 * @interface MethodeCalculCo2Dto22Now
 */
export interface MethodeCalculCo2Dto22Now {
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    donneeActivite: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto22Now
     */
    emissionsBiomasseDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto22Now
     */
    emissionsBiomasseNonDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculCo2Dto22Now
     */
    emissionsFossiles: number;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    facteurConversion: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    facteurEmission: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    facteurOxydation: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculCo2Dto22Now
     */
    fluxName: string;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    fractionBiomasse: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    fractionBiomasseNonDurable: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculCo2Dto22Now
     */
    methode: string;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    pouvoirCalorifique: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculCo2Dto22Now
     */
    referenceFlux: string;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculCo2Dto22Now
     */
    teneurCarbone: AerDonneeSurveillanceDto;
}
/**
 * 
 * @export
 * @interface MethodeCalculExceptPfcDto
 */
export interface MethodeCalculExceptPfcDto {
    /**
     * 
     * @type {ClassementFluxDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    classementFlux: ClassementFluxDto;
    /**
     * 
     * @type {DonneeActiviteDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    donneeActivite: DonneeActiviteDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    facteurConversion: DonneeSurveillanceDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    facteurEmission: DonneeSurveillanceDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    facteurOxydation: DonneeSurveillanceDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    fractionBiomasse: DonneeSurveillanceDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    pouvoirCalorifique: DonneeSurveillanceDto;
    /**
     * 
     * @type {DonneeSurveillanceDto}
     * @memberof MethodeCalculExceptPfcDto
     */
    teneurCarbone: DonneeSurveillanceDto;
}
/**
 * 
 * @export
 * @interface MethodeCalculPfcDto2121
 */
export interface MethodeCalculPfcDto2121 {
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculPfcDto2121
     */
    emissionsPfc: number;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculPfcDto2121
     */
    fluxName: string;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculPfcDto2121
     */
    methode: string;
}
/**
 * 
 * @export
 * @interface MethodeCalculPfcDto22Now
 */
export interface MethodeCalculPfcDto22Now {
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculPfcDto22Now
     */
    donneeActivite: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculPfcDto22Now
     */
    duree: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {number}
     * @memberof MethodeCalculPfcDto22Now
     */
    emissionsPfc: number;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculPfcDto22Now
     */
    fC2F6: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculPfcDto22Now
     */
    fluxName: string;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculPfcDto22Now
     */
    frequence: AerDonneeSurveillanceDto;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculPfcDto22Now
     */
    methode: string;
    /**
     * 
     * @type {string}
     * @memberof MethodeCalculPfcDto22Now
     */
    referenceFlux: string;
    /**
     * 
     * @type {AerDonneeSurveillanceDto}
     * @memberof MethodeCalculPfcDto22Now
     */
    sefCf4: AerDonneeSurveillanceDto;
}
/**
 * 
 * @export
 * @interface MethodeMesureEmissionDto2121
 */
export interface MethodeMesureEmissionDto2121 {
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto2121
     */
    emissionsBiomasseDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto2121
     */
    emissionsBiomasseNonDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto2121
     */
    emissionsFossiles: number;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto2121
     */
    gazMesure: string;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto2121
     */
    pointMesure: string;
}
/**
 * 
 * @export
 * @interface MethodeMesureEmissionDto22Now
 */
export interface MethodeMesureEmissionDto22Now {
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    concentrationGes: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    debitHoraire: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    debitTotal: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    emissionsBiomasseDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    emissionsBiomasseNonDurable: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    emissionsFossiles: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    fractionBiomasse: number;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    fractionBiomasseNonDurable: number;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto22Now
     */
    gazMesure: string;
    /**
     * 
     * @type {number}
     * @memberof MethodeMesureEmissionDto22Now
     */
    heuresExploitation: number;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto22Now
     */
    niveauApplique: MethodeMesureEmissionDto22NowNiveauAppliqueEnum;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto22Now
     */
    pointMesure: string;
    /**
     * 
     * @type {string}
     * @memberof MethodeMesureEmissionDto22Now
     */
    referenceFlux: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MethodeMesureEmissionDto22NowNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface MethodeSurveillanceDto
 */
export interface MethodeSurveillanceDto {
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    methodeAlternative: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    methodeCalculCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    methodeMesureCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    surveillanceCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    surveillanceN2o: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MethodeSurveillanceDto
     */
    surveillancePfc: boolean;
}
/**
 * 
 * @export
 * @interface ModaliteTransportDto
 */
export interface ModaliteTransportDto {
    /**
     * 
     * @type {number}
     * @memberof ModaliteTransportDto
     */
    autre: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModaliteTransportDto
     */
    famille: string;
    /**
     * 
     * @type {string}
     * @memberof ModaliteTransportDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof ModaliteTransportDto
     */
    navigable: number | null;
    /**
     * 
     * @type {string}
     * @memberof ModaliteTransportDto
     */
    preciserAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof ModaliteTransportDto
     */
    rail: number | null;
    /**
     * 
     * @type {number}
     * @memberof ModaliteTransportDto
     */
    route: number | null;
}
/**
 * 
 * @export
 * @interface ModelPackage
 */
export interface ModelPackage {
    /**
     * 
     * @type {Array<Annotation>}
     * @memberof ModelPackage
     */
    annotations: Array<Annotation>;
    /**
     * 
     * @type {Array<Annotation>}
     * @memberof ModelPackage
     */
    declaredAnnotations: Array<Annotation>;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    implementationTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    implementationVendor: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    implementationVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelPackage
     */
    sealed: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    specificationTitle: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    specificationVendor: string;
    /**
     * 
     * @type {string}
     * @memberof ModelPackage
     */
    specificationVersion: string;
}
/**
 * 
 * @export
 * @interface Module
 */
export interface Module {
    /**
     * 
     * @type {Array<Annotation>}
     * @memberof Module
     */
    annotations: Array<Annotation>;
    /**
     * 
     * @type {ClassLoader}
     * @memberof Module
     */
    classLoader: ClassLoader;
    /**
     * 
     * @type {Array<Annotation>}
     * @memberof Module
     */
    declaredAnnotations: Array<Annotation>;
    /**
     * 
     * @type {ModuleDescriptor}
     * @memberof Module
     */
    descriptor: ModuleDescriptor;
    /**
     * 
     * @type {ModuleLayer}
     * @memberof Module
     */
    layer: ModuleLayer;
    /**
     * 
     * @type {string}
     * @memberof Module
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Module
     */
    named: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Module
     */
    packages: Array<string>;
}
/**
 * 
 * @export
 * @interface ModuleDescriptor
 */
export interface ModuleDescriptor {
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    automatic: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModuleDescriptor
     */
    open: boolean;
}
/**
 * 
 * @export
 * @interface ModuleLayer
 */
export interface ModuleLayer {
}
/**
 * 
 * @export
 * @interface MonAiotNatureServiceDto
 */
export interface MonAiotNatureServiceDto {
    /**
     * 
     * @type {string}
     * @memberof MonAiotNatureServiceDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MonAiotNatureServiceDto
     */
    nom: string;
}
/**
 * 
 * @export
 * @interface NatureServiceWithEnumDto
 */
export interface NatureServiceWithEnumDto {
    /**
     * 
     * @type {MonAiotNatureServiceDto}
     * @memberof NatureServiceWithEnumDto
     */
    natureService: MonAiotNatureServiceDto;
    /**
     * 
     * @type {string}
     * @memberof NatureServiceWithEnumDto
     */
    value: NatureServiceWithEnumDtoValueEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum NatureServiceWithEnumDtoValueEnum {
    'ASNR' = 'ASNR',
    'BQA' = 'BQA',
    'CGA' = 'CGA',
    'DDCSPP' = 'DDCSPP',
    'DDTM' = 'DDTM',
    'DREAL' = 'DREAL',
    'DSND' = 'DSND'
}

/**
 * 
 * @export
 * @interface OneFileRelatedToNimDto
 */
export interface OneFileRelatedToNimDto {
    /**
     * 
     * @type {FileDto}
     * @memberof OneFileRelatedToNimDto
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof OneFileRelatedToNimDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface Pds2121
 */
export interface Pds2121 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof Pds2121
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof Pds2121
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface Pds22Now
 */
export interface Pds22Now {
    /**
     * 
     * @type {FileDto}
     * @memberof Pds22Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof Pds22Now
     */
    nimDynId: string;
    /**
     * 
     * @type {PdsDataDto22Now}
     * @memberof Pds22Now
     */
    pdsData: PdsDataDto22Now;
}
/**
 * 
 * @export
 * @interface PdsDataDto22Now
 */
export interface PdsDataDto22Now {
    /**
     * 
     * @type {BlocDonneeSurveillanceDto}
     * @memberof PdsDataDto22Now
     */
    blocDonneeSurveillanceDto: BlocDonneeSurveillanceDto;
    /**
     * 
     * @type {InfosGeneralesPdsDto}
     * @memberof PdsDataDto22Now
     */
    infosGeneralesPdsDto: InfosGeneralesPdsDto;
    /**
     * 
     * @type {MethodeSurveillanceDto}
     * @memberof PdsDataDto22Now
     */
    methodeSurveillanceDto: MethodeSurveillanceDto;
}
/**
 * 
 * @export
 * @interface PdsJustificatifFilesDto
 */
export interface PdsJustificatifFilesDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof PdsJustificatifFilesDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof PdsJustificatifFilesDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface PdsMethodeCalculPfcDto
 */
export interface PdsMethodeCalculPfcDto {
    /**
     * 
     * @type {ClassementFluxDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    classementFlux: ClassementFluxDto;
    /**
     * 
     * @type {SurveillanceEmissionDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    donneeActivite: SurveillanceEmissionDto;
    /**
     * 
     * @type {SurveillanceEmissionDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    duree: SurveillanceEmissionDto;
    /**
     * 
     * @type {SurveillanceEmissionDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    fC2F6: SurveillanceEmissionDto;
    /**
     * 
     * @type {SurveillanceEmissionDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    frequence: SurveillanceEmissionDto;
    /**
     * 
     * @type {SurveillanceEmissionDto}
     * @memberof PdsMethodeCalculPfcDto
     */
    sefCf4: SurveillanceEmissionDto;
}
/**
 * 
 * @export
 * @interface PmsDto2020
 */
export interface PmsDto2020 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof PmsDto2020
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof PmsDto2020
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface PmsDto21Now
 */
export interface PmsDto21Now {
    /**
     * 
     * @type {FileDto}
     * @memberof PmsDto21Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof PmsDto21Now
     */
    nimDynId: string;
    /**
     * 
     * @type {PmsExtractedDataDto}
     * @memberof PmsDto21Now
     */
    pmsExtractedDataDto: PmsExtractedDataDto;
}
/**
 * 
 * @export
 * @interface PmsExtractedDataDto
 */
export interface PmsExtractedDataDto {
    /**
     * 
     * @type {string}
     * @memberof PmsExtractedDataDto
     */
    numeroNim: string | null;
}
/**
 * 
 * @export
 * @interface PmsJustificatifFilesDto
 */
export interface PmsJustificatifFilesDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof PmsJustificatifFilesDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof PmsJustificatifFilesDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface PointMesureDto
 */
export interface PointMesureDto {
    /**
     * 
     * @type {ClassementFluxDto}
     * @memberof PointMesureDto
     */
    classementFlux: ClassementFluxDto;
    /**
     * 
     * @type {string}
     * @memberof PointMesureDto
     */
    gazMesure: string;
    /**
     * 
     * @type {string}
     * @memberof PointMesureDto
     */
    niveauApplique: PointMesureDtoNiveauAppliqueEnum;
    /**
     * 
     * @type {string}
     * @memberof PointMesureDto
     */
    niveauRequis: PointMesureDtoNiveauRequisEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PointMesureDtoNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}
/**
    * @export
    * @enum {string}
    */
export enum PointMesureDtoNiveauRequisEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface PolluantEauDto
 */
export interface PolluantEauDto {
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof PolluantEauDto
     */
    dontMasseAccidentelle: number | null;
    /**
     * 
     * @type {number}
     * @memberof PolluantEauDto
     */
    dontMasseImportee: number | null;
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof PolluantEauDto
     */
    masseEmiseTotale: number | null;
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    methode: PolluantEauDtoMethodeEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PolluantEauDto
     */
    normesID: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    polluantID: string | null;
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    referenceMethode: PolluantEauDtoReferenceMethodeEnum | null;
    /**
     * 
     * @type {number}
     * @memberof PolluantEauDto
     */
    rendementStationExterne: number | null;
    /**
     * 
     * @type {string}
     * @memberof PolluantEauDto
     */
    typeRejet: PolluantEauDtoTypeRejetEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum PolluantEauDtoMethodeEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE'
}
/**
    * @export
    * @enum {string}
    */
export enum PolluantEauDtoReferenceMethodeEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}
/**
    * @export
    * @enum {string}
    */
export enum PolluantEauDtoTypeRejetEnum {
    'ISOLE' = 'ISOLE',
    'RACCORDE' = 'RACCORDE'
}

/**
 * 
 * @export
 * @interface PoussiereAtmospheriqueDto1819
 */
export interface PoussiereAtmospheriqueDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereAtmospheriqueDto1819
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {Array<MesurePoussiereAtmospheriqueDto1819>}
     * @memberof PoussiereAtmospheriqueDto1819
     */
    mesuresDesRetombee: Array<MesurePoussiereAtmospheriqueDto1819>;
}
/**
 * 
 * @export
 * @interface PoussiereAtmospheriqueDto20Now
 */
export interface PoussiereAtmospheriqueDto20Now {
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereAtmospheriqueDto20Now
     */
    doitEffectuerMesures: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereAtmospheriqueDto20Now
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {Array<MesurePoussiereAtmospheriqueDto20Now>}
     * @memberof PoussiereAtmospheriqueDto20Now
     */
    mesuresDesRetombee: Array<MesurePoussiereAtmospheriqueDto20Now>;
}
/**
 * 
 * @export
 * @interface PoussiereCanaliseeDto1819
 */
export interface PoussiereCanaliseeDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereCanaliseeDto1819
     */
    doitEffectuerMesures: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereCanaliseeDto1819
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {Array<MesurePoussiereCanaliseeDto1819>}
     * @memberof PoussiereCanaliseeDto1819
     */
    mesuresDesRejets: Array<MesurePoussiereCanaliseeDto1819>;
}
/**
 * 
 * @export
 * @interface PoussiereCanaliseeDto20Now
 */
export interface PoussiereCanaliseeDto20Now {
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereCanaliseeDto20Now
     */
    doitEffectuerMesures: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PoussiereCanaliseeDto20Now
     */
    mesureEffectuee: boolean | null;
    /**
     * 
     * @type {Array<MesurePoussiereCanaliseeDto20Now>}
     * @memberof PoussiereCanaliseeDto20Now
     */
    mesuresDesRejets: Array<MesurePoussiereCanaliseeDto20Now>;
}
/**
 * 
 * @export
 * @interface PrelevementEnEau
 */
export interface PrelevementEnEau {
    /**
     * 
     * @type {boolean}
     * @memberof PrelevementEnEau
     */
    depassementDeSeuilAdduction: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrelevementEnEau
     */
    depassementDeSeuilEauxSouterraines: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrelevementEnEau
     */
    depassementDeSeuilEauxSuperficielles: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PrelevementEnEau
     */
    depassementDeSeuilMerOuOcean: boolean;
    /**
     * 
     * @type {number}
     * @memberof PrelevementEnEau
     */
    seuilEnKgAnAdduction: number;
    /**
     * 
     * @type {number}
     * @memberof PrelevementEnEau
     */
    seuilEnKgAnMilieuNaturel: number;
}
/**
 * 
 * @export
 * @interface PrestataireDroitAddModel
 */
export interface PrestataireDroitAddModel {
    /**
     * 
     * @type {string}
     * @memberof PrestataireDroitAddModel
     */
    prestataireDroitDto: PrestataireDroitAddModelPrestataireDroitDtoEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum PrestataireDroitAddModelPrestataireDroitDtoEnum {
    'PRESTATAIRE_DROIT_E' = 'PRESTATAIRE_DROIT_E',
    'PRESTATAIRE_DROIT_EN' = 'PRESTATAIRE_DROIT_EN',
    'PRESTATAIRE_DROIT_N' = 'PRESTATAIRE_DROIT_N'
}

/**
 * 
 * @export
 * @interface PreventionDto
 */
export interface PreventionDto {
    /**
     * 
     * @type {boolean}
     * @memberof PreventionDto
     */
    activiteSaisonniere: boolean;
    /**
     * 
     * @type {number}
     * @memberof PreventionDto
     */
    nbVisites: number | null;
    /**
     * 
     * @type {string}
     * @memberof PreventionDto
     */
    organismeDePreventionID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PreventionDto
     */
    structureFonctionnelle: boolean | null;
}
/**
 * 
 * @export
 * @interface ProductionDechetsDto1819
 */
export interface ProductionDechetsDto1819 {
    /**
     * 
     * @type {Array<DechetProduitDto1819>}
     * @memberof ProductionDechetsDto1819
     */
    productionDechet: Array<DechetProduitDto1819>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto1819
     */
    productionDechetDangereuxSup2000: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto1819
     */
    productionDechetNonDangereuxSup2000: boolean | null;
}
/**
 * 
 * @export
 * @interface ProductionDechetsDto2022
 */
export interface ProductionDechetsDto2022 {
    /**
     * 
     * @type {Array<DechetProduitDto2022>}
     * @memberof ProductionDechetsDto2022
     */
    productionDechet: Array<DechetProduitDto2022>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto2022
     */
    productionDechetDangereuxSup2000: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto2022
     */
    productionDechetNonDangereuxSup2000: boolean | null;
}
/**
 * 
 * @export
 * @interface ProductionDechetsDto23Now
 */
export interface ProductionDechetsDto23Now {
    /**
     * 
     * @type {Array<DechetProduitDto23Now>}
     * @memberof ProductionDechetsDto23Now
     */
    productionDechet: Array<DechetProduitDto23Now>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto23Now
     */
    productionDechetDangereuxSup2000: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductionDechetsDto23Now
     */
    productionDechetNonDangereuxSup2000: boolean | null;
}
/**
 * 
 * @export
 * @interface ProductionDto1822
 */
export interface ProductionDto1822 {
    /**
     * 
     * @type {ExtractionDto1822}
     * @memberof ProductionDto1822
     */
    extraction: ExtractionDto1822;
    /**
     * 
     * @type {RecyclageDto1822}
     * @memberof ProductionDto1822
     */
    recyclage: RecyclageDto1822;
    /**
     * 
     * @type {TransformationDto}
     * @memberof ProductionDto1822
     */
    transformation: TransformationDto;
    /**
     * 
     * @type {TransportDto1822}
     * @memberof ProductionDto1822
     */
    transport: TransportDto1822;
}
/**
 * 
 * @export
 * @interface ProductionDto23Now
 */
export interface ProductionDto23Now {
    /**
     * 
     * @type {ExtractionDto23Now}
     * @memberof ProductionDto23Now
     */
    extraction: ExtractionDto23Now;
    /**
     * 
     * @type {RecyclageDto23Now}
     * @memberof ProductionDto23Now
     */
    recyclage: RecyclageDto23Now;
    /**
     * 
     * @type {TransformationDto}
     * @memberof ProductionDto23Now
     */
    transformation: TransformationDto;
    /**
     * 
     * @type {TransportDto23Now}
     * @memberof ProductionDto23Now
     */
    transport: TransportDto23Now;
}
/**
 * 
 * @export
 * @interface ProduitRecyclageDto1822
 */
export interface ProduitRecyclageDto1822 {
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    familleId: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    nature: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    precisionAutre: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    precisionFamille: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    precisionSousFamilleNiveau1: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProduitRecyclageDto1822
     */
    quantiteAnnuelleProduite: number;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto1822
     */
    sousFamilleId: string | null;
    /**
     * 
     * @type {Array<ElementBasiqueDto>}
     * @memberof ProduitRecyclageDto1822
     */
    sousFamillesNiveau2: Array<ElementBasiqueDto>;
}
/**
 * 
 * @export
 * @interface ProduitRecyclageDto23Now
 */
export interface ProduitRecyclageDto23Now {
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitRecyclageDto23Now
     */
    famille: ElementBasiqueDto | null;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto23Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto23Now
     */
    nature: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitRecyclageDto23Now
     */
    precisionAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProduitRecyclageDto23Now
     */
    quantite: number;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitRecyclageDto23Now
     */
    sousFamille: ElementBasiqueDto | null;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitRecyclageDto23Now
     */
    sousFamilleNiveau2: ElementBasiqueDto | null;
}
/**
 * 
 * @export
 * @interface ProduitTransportDto1822
 */
export interface ProduitTransportDto1822 {
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    departementID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProduitTransportDto1822
     */
    enFrance: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    famille: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProduitTransportDto1822
     */
    franceEntiere: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    paysID: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    precisionAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProduitTransportDto1822
     */
    tonnage: number;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto1822
     */
    typeProduit: string;
}
/**
 * 
 * @export
 * @interface ProduitTransportDto23Now
 */
export interface ProduitTransportDto23Now {
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto23Now
     */
    departementId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProduitTransportDto23Now
     */
    enFrance: boolean;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitTransportDto23Now
     */
    famille: ElementBasiqueDto | null;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto23Now
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProduitTransportDto23Now
     */
    paysId: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProduitTransportDto23Now
     */
    quantite: number;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitTransportDto23Now
     */
    sousFamille: ElementBasiqueDto | null;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof ProduitTransportDto23Now
     */
    sousFamilleNiveau2: ElementBasiqueDto | null;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsBlocInspecteurDto2020
 */
export interface QuotasAllocationsBlocInspecteurDto2020 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasAllocationsBlocInspecteurDto2020
     */
    documents: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsBlocInspecteurDto21Now
 */
export interface QuotasAllocationsBlocInspecteurDto21Now {
    /**
     * 
     * @type {Array<RapportOfficeDto>}
     * @memberof QuotasAllocationsBlocInspecteurDto21Now
     */
    documents: Array<RapportOfficeDto>;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsBlocPlanMethodSurveillanceDto2020
 */
export interface QuotasAllocationsBlocPlanMethodSurveillanceDto2020 {
    /**
     * 
     * @type {Array<PmsDto2020>}
     * @memberof QuotasAllocationsBlocPlanMethodSurveillanceDto2020
     */
    listPms: Array<PmsDto2020>;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsBlocVerificationDto20Now
 */
export interface QuotasAllocationsBlocVerificationDto20Now {
    /**
     * 
     * @type {Array<QuotasAllocationsVerificationDto20Now>}
     * @memberof QuotasAllocationsBlocVerificationDto20Now
     */
    verifications: Array<QuotasAllocationsVerificationDto20Now>;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsDto2020
 */
export interface QuotasAllocationsDto2020 {
    /**
     * 
     * @type {BlocAlcDto2020}
     * @memberof QuotasAllocationsDto2020
     */
    blocAlc: BlocAlcDto2020;
    /**
     * 
     * @type {QuotasAllocationsEmailsVerificateursDto20Now}
     * @memberof QuotasAllocationsDto2020
     */
    blocEmails: QuotasAllocationsEmailsVerificateursDto20Now;
    /**
     * 
     * @type {QuotasAllocationsBlocInspecteurDto2020}
     * @memberof QuotasAllocationsDto2020
     */
    blocInspecteur: QuotasAllocationsBlocInspecteurDto2020;
    /**
     * 
     * @type {QuotasAllocationsBlocPlanMethodSurveillanceDto2020}
     * @memberof QuotasAllocationsDto2020
     */
    blocPlanMethodSurveillance: QuotasAllocationsBlocPlanMethodSurveillanceDto2020;
    /**
     * 
     * @type {QuotasAllocationsBlocVerificationDto20Now}
     * @memberof QuotasAllocationsDto2020
     */
    blocVerification: QuotasAllocationsBlocVerificationDto20Now;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsDto21Now
 */
export interface QuotasAllocationsDto21Now {
    /**
     * 
     * @type {BlocAlcDto21Now}
     * @memberof QuotasAllocationsDto21Now
     */
    blocAlc: BlocAlcDto21Now;
    /**
     * 
     * @type {QuotasAllocationsEmailsVerificateursDto20Now}
     * @memberof QuotasAllocationsDto21Now
     */
    blocEmails: QuotasAllocationsEmailsVerificateursDto20Now;
    /**
     * 
     * @type {QuotasAllocationsBlocInspecteurDto21Now}
     * @memberof QuotasAllocationsDto21Now
     */
    blocInspecteur: QuotasAllocationsBlocInspecteurDto21Now;
    /**
     * 
     * @type {QuotasAllocationsBlocVerificationDto20Now}
     * @memberof QuotasAllocationsDto21Now
     */
    blocVerification: QuotasAllocationsBlocVerificationDto20Now;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsEmailsVerificateursDto20Now
 */
export interface QuotasAllocationsEmailsVerificateursDto20Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasAllocationsEmailsVerificateursDto20Now
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface QuotasAllocationsVerificationDto20Now
 */
export interface QuotasAllocationsVerificationDto20Now {
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    conclusion: QuotasAllocationsVerificationDto20NowConclusionEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    conditionDispense: QuotasAllocationsVerificationDto20NowConditionDispenseEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    dateDerniereVisite: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    dispenseVisiteSite: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    motifsNonSatisfaisant: Array<QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    motifsStatisfaisantAvecRemarque: Array<QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum>;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    organisme: QuotasAllocationsVerificationDto20NowOrganismeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    precisionDispense: string | null;
    /**
     * 
     * @type {FileDto}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    rapport: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    referenceDernierPlanSurveillance: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasAllocationsVerificationDto20Now
     */
    visiteCombinee: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasAllocationsVerificationDto20NowConclusionEnum {
    'NON_SATISFAISANT' = 'NON_SATISFAISANT',
    'SATISFAISANT' = 'SATISFAISANT',
    'SATISFAISANT_AVEC_REMARQUE' = 'SATISFAISANT_AVEC_REMARQUE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasAllocationsVerificationDto20NowConditionDispenseEnum {
    'AUTRE' = 'AUTRE',
    'CONDITION_III_A' = 'CONDITION_III_A',
    'CONDITION_III_B' = 'CONDITION_III_B',
    'CONDITION_III_C' = 'CONDITION_III_C',
    'CONDITION_IV' = 'CONDITION_IV',
    'CONDITION_V' = 'CONDITION_V'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum {
    'DONNES_MANQUANTE_VERIFICATION' = 'DONNES_MANQUANTE_VERIFICATION',
    'INEXACTITUDE' = 'INEXACTITUDE',
    'IRREGULARITE' = 'IRREGULARITE',
    'NON_RESPECT_FAR_ALC' = 'NON_RESPECT_FAR_ALC',
    'PLAN_SURVEILLANCE_INCOMPLET' = 'PLAN_SURVEILLANCE_INCOMPLET',
    'PLAN_SURVEILLANCE_NON_APPROUVE' = 'PLAN_SURVEILLANCE_NON_APPROUVE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum {
    'INEXACTITUDE' = 'INEXACTITUDE',
    'IRREGULARITE' = 'IRREGULARITE',
    'NON_RESPECT_FAR_ALC' = 'NON_RESPECT_FAR_ALC',
    'RECOMMANDATION_AMELIORATION' = 'RECOMMANDATION_AMELIORATION'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasAllocationsVerificationDto20NowOrganismeEnum {
    'APAVE' = 'APAVE',
    'BUREAU_VERITAS_CERTIFICATION_FRANCE' = 'BUREAU_VERITAS_CERTIFICATION_FRANCE',
    'DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL' = 'DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL',
    'EY_ET_ASSOCIES' = 'EY_ET_ASSOCIES',
    'LRQA_FRANCE_SAS' = 'LRQA_FRANCE_SAS',
    'SGS_FRANCE' = 'SGS_FRANCE',
    'SOCOTEC_ENVIRONNEMENT' = 'SOCOTEC_ENVIRONNEMENT'
}

/**
 * 
 * @export
 * @interface QuotasBlocEmissionsBilanTotalDto1819
 */
export interface QuotasBlocEmissionsBilanTotalDto1819 {
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto1819
     */
    emissionsCO2: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto1819
     */
    emissionsN2O: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto1819
     */
    emissionsPFC: number | null;
}
/**
 * 
 * @export
 * @interface QuotasBlocEmissionsBilanTotalDto20Now
 */
export interface QuotasBlocEmissionsBilanTotalDto20Now {
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto20Now
     */
    emissionsCO2: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto20Now
     */
    emissionsN2O: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasBlocEmissionsBilanTotalDto20Now
     */
    emissionsPFC: number | null;
}
/**
 * 
 * @export
 * @interface QuotasBlocEmissionsDto1819
 */
export interface QuotasBlocEmissionsDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocEmissionsDto1819
     */
    derogationFrequenceEchantillonnage: boolean | null;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleCalculeDto1819>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    emissionsCalculees: Array<QuotasEmissionSimpleCalculeDto1819>;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleMesureDto1819>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    emissionsMesurees: Array<QuotasEmissionSimpleMesureDto1819>;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleMethodeAlternativeDto1819>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    emissionsMethodeAlternative: Array<QuotasEmissionSimpleMethodeAlternativeDto1819>;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {Array<QuotasEmissionFluxDto1819>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    flux: Array<QuotasEmissionFluxDto1819>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    fluxSoumisADerogation: Array<string>;
    /**
     * 
     * @type {Array<QuotasEmissionPointMesureDto1819>}
     * @memberof QuotasBlocEmissionsDto1819
     */
    pointsMesure: Array<QuotasEmissionPointMesureDto1819>;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocEmissionsDto1819
     */
    transfertCO2: boolean | null;
}
/**
 * 
 * @export
 * @interface QuotasBlocEmissionsDto2020
 */
export interface QuotasBlocEmissionsDto2020 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocEmissionsDto2020
     */
    derogationFrequenceEchantillonnage: boolean | null;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleCalculeDto2020>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    emissionsCalculees: Array<QuotasEmissionSimpleCalculeDto2020>;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleMesureDto2020>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    emissionsMesurees: Array<QuotasEmissionSimpleMesureDto2020>;
    /**
     * 
     * @type {Array<QuotasEmissionSimpleMethodeAlternativeDto2020>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    emissionsMethodeAlternative: Array<QuotasEmissionSimpleMethodeAlternativeDto2020>;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {Array<QuotasEmissionFluxDto2020>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    flux: Array<QuotasEmissionFluxDto2020>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    fluxSoumisADerogation: Array<string>;
    /**
     * 
     * @type {Array<QuotasEmissionPointMesureDto2020>}
     * @memberof QuotasBlocEmissionsDto2020
     */
    pointsMesure: Array<QuotasEmissionPointMesureDto2020>;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocEmissionsDto2020
     */
    transfertCO2: boolean | null;
}
/**
 * 
 * @export
 * @interface QuotasBlocEmissionsEmailsVerificateursDto20Now
 */
export interface QuotasBlocEmissionsEmailsVerificateursDto20Now {
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasBlocEmissionsEmailsVerificateursDto20Now
     */
    emails: Array<string>;
}
/**
 * 
 * @export
 * @interface QuotasBlocInfoGeneralesDto1819
 */
export interface QuotasBlocInfoGeneralesDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocInfoGeneralesDto1819
     */
    excluArticle27: boolean | null;
}
/**
 * 
 * @export
 * @interface QuotasBlocInfosComplementairesInstallationsDto2020
 */
export interface QuotasBlocInfosComplementairesInstallationsDto2020 {
    /**
     * 
     * @type {Array<QuotasInfosComplementairesInstallationsDto2021>}
     * @memberof QuotasBlocInfosComplementairesInstallationsDto2020
     */
    installations: Array<QuotasInfosComplementairesInstallationsDto2021>;
}
/**
 * 
 * @export
 * @interface QuotasBlocInspecteurDto1819
 */
export interface QuotasBlocInspecteurDto1819 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasBlocInspecteurDto1819
     */
    documents: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface QuotasBlocInstallationsDto1819
 */
export interface QuotasBlocInstallationsDto1819 {
    /**
     * 
     * @type {Array<QuotasInstallationDto1819>}
     * @memberof QuotasBlocInstallationsDto1819
     */
    installations: Array<QuotasInstallationDto1819>;
}
/**
 * 
 * @export
 * @interface QuotasBlocPlanSurveillanceDto1819
 */
export interface QuotasBlocPlanSurveillanceDto1819 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    calculBilanMassique: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    calculFacteurEmissionConversion: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    calculFacteurEmissionOxydation: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    estMesure: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    estMethodeAlternative: boolean | null;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasBlocPlanSurveillanceDto1819
     */
    fichiers: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface QuotasBlocVerificationDto1819
 */
export interface QuotasBlocVerificationDto1819 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasBlocVerificationDto1819
     */
    rapports: Array<FileDto>;
    /**
     * 
     * @type {Array<QuotasVerificationDto1819>}
     * @memberof QuotasBlocVerificationDto1819
     */
    verifications: Array<QuotasVerificationDto1819>;
}
/**
 * 
 * @export
 * @interface QuotasBlocVerificationEmissionsDto20Now
 */
export interface QuotasBlocVerificationEmissionsDto20Now {
    /**
     * 
     * @type {Array<QuotasEmissionsVerificationDto20Now>}
     * @memberof QuotasBlocVerificationEmissionsDto20Now
     */
    verifications: Array<QuotasEmissionsVerificationDto20Now>;
}
/**
 * 
 * @export
 * @interface QuotasDto1819
 */
export interface QuotasDto1819 {
    /**
     * 
     * @type {QuotasBlocEmissionsBilanTotalDto1819}
     * @memberof QuotasDto1819
     */
    blocBilanTotal: QuotasBlocEmissionsBilanTotalDto1819;
    /**
     * 
     * @type {QuotasBlocEmissionsDto1819}
     * @memberof QuotasDto1819
     */
    blocEmissions: QuotasBlocEmissionsDto1819;
    /**
     * 
     * @type {QuotasBlocInfoGeneralesDto1819}
     * @memberof QuotasDto1819
     */
    blocInfoGenerales: QuotasBlocInfoGeneralesDto1819;
    /**
     * 
     * @type {QuotasBlocInspecteurDto1819}
     * @memberof QuotasDto1819
     */
    blocInspecteur: QuotasBlocInspecteurDto1819;
    /**
     * 
     * @type {QuotasBlocInstallationsDto1819}
     * @memberof QuotasDto1819
     */
    blocInstallations: QuotasBlocInstallationsDto1819;
    /**
     * 
     * @type {QuotasBlocPlanSurveillanceDto1819}
     * @memberof QuotasDto1819
     */
    blocPlanSurveillance: QuotasBlocPlanSurveillanceDto1819;
    /**
     * 
     * @type {QuotasBlocVerificationDto1819}
     * @memberof QuotasDto1819
     */
    blocVerification: QuotasBlocVerificationDto1819;
}
/**
 * 
 * @export
 * @interface QuotasDto2020
 */
export interface QuotasDto2020 {
    /**
     * 
     * @type {QuotasAllocationsDto2020}
     * @memberof QuotasDto2020
     */
    allocations: QuotasAllocationsDto2020;
    /**
     * 
     * @type {QuotasEmissionsDto2020}
     * @memberof QuotasDto2020
     */
    emissions: QuotasEmissionsDto2020;
}
/**
 * 
 * @export
 * @interface QuotasDto2121
 */
export interface QuotasDto2121 {
    /**
     * 
     * @type {QuotasAllocationsDto21Now}
     * @memberof QuotasDto2121
     */
    allocations: QuotasAllocationsDto21Now;
    /**
     * 
     * @type {QuotasEmissionsDto2121}
     * @memberof QuotasDto2121
     */
    emissions: QuotasEmissionsDto2121;
}
/**
 * 
 * @export
 * @interface QuotasDto22Now
 */
export interface QuotasDto22Now {
    /**
     * 
     * @type {QuotasAllocationsDto21Now}
     * @memberof QuotasDto22Now
     */
    allocations: QuotasAllocationsDto21Now;
    /**
     * 
     * @type {QuotasEmissionsDto22Now}
     * @memberof QuotasDto22Now
     */
    emissions: QuotasEmissionsDto22Now;
}
/**
 * 
 * @export
 * @interface QuotasEmissionFluxDto1819
 */
export interface QuotasEmissionFluxDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto1819
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto1819
     */
    nom: string | null;
}
/**
 * 
 * @export
 * @interface QuotasEmissionFluxDto2020
 */
export interface QuotasEmissionFluxDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto2020
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionFluxDto2020
     */
    nom: string | null;
}
/**
 * 
 * @export
 * @interface QuotasEmissionPointMesureDto1819
 */
export interface QuotasEmissionPointMesureDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto1819
     */
    intitule: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto1819
     */
    nim: string | null;
}
/**
 * 
 * @export
 * @interface QuotasEmissionPointMesureDto2020
 */
export interface QuotasEmissionPointMesureDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto2020
     */
    intitule: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionPointMesureDto2020
     */
    nimDynId: string | null;
}
/**
 * 
 * @export
 * @interface QuotasEmissionSimpleCalculeDto1819
 */
export interface QuotasEmissionSimpleCalculeDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    gaz: QuotasEmissionSimpleCalculeDto1819GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    methode: QuotasEmissionSimpleCalculeDto1819MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto1819
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleCalculeDto1819GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleCalculeDto1819MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleCalculeDto2020
 */
export interface QuotasEmissionSimpleCalculeDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    gaz: QuotasEmissionSimpleCalculeDto2020GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    methode: QuotasEmissionSimpleCalculeDto2020MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleCalculeDto2020
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleCalculeDto2020GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleCalculeDto2020MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleDto2020
 */
export interface QuotasEmissionSimpleDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleDto2020
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleDto2020
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleDto2020
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    gaz: QuotasEmissionSimpleDto2020GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    methode: QuotasEmissionSimpleDto2020MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleDto2020
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleDto2020GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleDto2020MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleMesureDto1819
 */
export interface QuotasEmissionSimpleMesureDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    gaz: QuotasEmissionSimpleMesureDto1819GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    methode: QuotasEmissionSimpleMesureDto1819MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto1819
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMesureDto1819GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMesureDto1819MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleMesureDto2020
 */
export interface QuotasEmissionSimpleMesureDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    gaz: QuotasEmissionSimpleMesureDto2020GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    methode: QuotasEmissionSimpleMesureDto2020MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMesureDto2020
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMesureDto2020GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMesureDto2020MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleMethodeAlternativeDto1819
 */
export interface QuotasEmissionSimpleMethodeAlternativeDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    gaz: QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    methode: QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto1819
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionSimpleMethodeAlternativeDto2020
 */
export interface QuotasEmissionSimpleMethodeAlternativeDto2020 {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    descriptionMethode: string | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    emission: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    emissionOrigineBiomasse: number | null;
    /**
     * 
     * @type {number}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    emissionOrigineFossile: number | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    flux: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    gaz: QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    methode: QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionSimpleMethodeAlternativeDto2020
     */
    pointMesure: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum {
    'ALTERNATIVE' = 'ALTERNATIVE',
    'BILAN_MASSIQUE' = 'BILAN_MASSIQUE',
    'FACTEUR_CONVERSION' = 'FACTEUR_CONVERSION',
    'FACTEUR_OXYDATION' = 'FACTEUR_OXYDATION',
    'MESURE' = 'MESURE'
}

/**
 * 
 * @export
 * @interface QuotasEmissionsBlocInspecteurDto2020
 */
export interface QuotasEmissionsBlocInspecteurDto2020 {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasEmissionsBlocInspecteurDto2020
     */
    documents: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsBlocInspecteurDto21Now
 */
export interface QuotasEmissionsBlocInspecteurDto21Now {
    /**
     * 
     * @type {Array<RapportOfficeDto>}
     * @memberof QuotasEmissionsBlocInspecteurDto21Now
     */
    documents: Array<RapportOfficeDto>;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsBlocPlanSurveillanceDto2020
 */
export interface QuotasEmissionsBlocPlanSurveillanceDto2020 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    calculBilanMassique: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    calculFacteurEmissionConversion: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    calculFacteurEmissionOxydation: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    estMesure: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    estMethodeAlternative: boolean | null;
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2020
     */
    fichiers: Array<FileDto>;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsBlocPlanSurveillanceDto2121
 */
export interface QuotasEmissionsBlocPlanSurveillanceDto2121 {
    /**
     * 
     * @type {Array<QuotasFluxDerogationDto2121>}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2121
     */
    fluxDerogationInstallations: Array<QuotasFluxDerogationDto2121>;
    /**
     * 
     * @type {Array<QuotasInfosComplementairesInstallationsDto2021>}
     * @memberof QuotasEmissionsBlocPlanSurveillanceDto2121
     */
    infosComplementairesInstallations: Array<QuotasInfosComplementairesInstallationsDto2021>;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsDto2020
 */
export interface QuotasEmissionsDto2020 {
    /**
     * 
     * @type {QuotasBlocEmissionsBilanTotalDto20Now}
     * @memberof QuotasEmissionsDto2020
     */
    blocBilanTotal: QuotasBlocEmissionsBilanTotalDto20Now;
    /**
     * 
     * @type {QuotasBlocEmissionsEmailsVerificateursDto20Now}
     * @memberof QuotasEmissionsDto2020
     */
    blocEmails: QuotasBlocEmissionsEmailsVerificateursDto20Now;
    /**
     * 
     * @type {QuotasBlocEmissionsDto2020}
     * @memberof QuotasEmissionsDto2020
     */
    blocEmissions: QuotasBlocEmissionsDto2020;
    /**
     * 
     * @type {QuotasBlocInfosComplementairesInstallationsDto2020}
     * @memberof QuotasEmissionsDto2020
     */
    blocInfosComplementairesInstallations: QuotasBlocInfosComplementairesInstallationsDto2020;
    /**
     * 
     * @type {QuotasEmissionsBlocInspecteurDto2020}
     * @memberof QuotasEmissionsDto2020
     */
    blocInspecteur: QuotasEmissionsBlocInspecteurDto2020;
    /**
     * 
     * @type {QuotasEmissionsBlocPlanSurveillanceDto2020}
     * @memberof QuotasEmissionsDto2020
     */
    blocPlanSurveillance: QuotasEmissionsBlocPlanSurveillanceDto2020;
    /**
     * 
     * @type {QuotasBlocVerificationEmissionsDto20Now}
     * @memberof QuotasEmissionsDto2020
     */
    blocVerification: QuotasBlocVerificationEmissionsDto20Now;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsDto2121
 */
export interface QuotasEmissionsDto2121 {
    /**
     * 
     * @type {QuotasBlocEmissionsBilanTotalDto20Now}
     * @memberof QuotasEmissionsDto2121
     */
    blocBilanTotal: QuotasBlocEmissionsBilanTotalDto20Now;
    /**
     * 
     * @type {QuotasBlocEmissionsEmailsVerificateursDto20Now}
     * @memberof QuotasEmissionsDto2121
     */
    blocEmails: QuotasBlocEmissionsEmailsVerificateursDto20Now;
    /**
     * 
     * @type {QuotasEmissionsBlocInspecteurDto21Now}
     * @memberof QuotasEmissionsDto2121
     */
    blocInspecteur: QuotasEmissionsBlocInspecteurDto21Now;
    /**
     * 
     * @type {QuotasEmissionsBlocPlanSurveillanceDto2121}
     * @memberof QuotasEmissionsDto2121
     */
    blocPlanSurveillance: QuotasEmissionsBlocPlanSurveillanceDto2121;
    /**
     * 
     * @type {QuotasBlocVerificationEmissionsDto20Now}
     * @memberof QuotasEmissionsDto2121
     */
    blocVerification: QuotasBlocVerificationEmissionsDto20Now;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsDto22Now
 */
export interface QuotasEmissionsDto22Now {
    /**
     * 
     * @type {QuotasBlocEmissionsBilanTotalDto20Now}
     * @memberof QuotasEmissionsDto22Now
     */
    blocBilanTotal: QuotasBlocEmissionsBilanTotalDto20Now;
    /**
     * 
     * @type {QuotasBlocEmissionsEmailsVerificateursDto20Now}
     * @memberof QuotasEmissionsDto22Now
     */
    blocEmails: QuotasBlocEmissionsEmailsVerificateursDto20Now;
    /**
     * 
     * @type {QuotasEmissionsBlocInspecteurDto21Now}
     * @memberof QuotasEmissionsDto22Now
     */
    blocInspecteur: QuotasEmissionsBlocInspecteurDto21Now;
    /**
     * 
     * @type {QuotasBlocVerificationEmissionsDto20Now}
     * @memberof QuotasEmissionsDto22Now
     */
    blocVerification: QuotasBlocVerificationEmissionsDto20Now;
}
/**
 * 
 * @export
 * @interface QuotasEmissionsVerificationDto20Now
 */
export interface QuotasEmissionsVerificationDto20Now {
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    conclusion: QuotasEmissionsVerificationDto20NowConclusionEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    conditionDispense: QuotasEmissionsVerificationDto20NowConditionDispenseEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    dateDerniereVisite: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    dispenseVisiteSite: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    motifsNonSatisfaisant: Array<QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    motifsStatisfaisantAvecRemarque: Array<QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum>;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    nimDynId: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    organisme: QuotasEmissionsVerificationDto20NowOrganismeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    precisionDispense: string | null;
    /**
     * 
     * @type {FileDto}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    rapport: FileDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    rapportAmeliorationRequis: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasEmissionsVerificationDto20Now
     */
    referenceDernierPlanSurveillance: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionsVerificationDto20NowConclusionEnum {
    'NON_SATISFAISANT' = 'NON_SATISFAISANT',
    'SATISFAISANT' = 'SATISFAISANT',
    'SATISFAISANT_AVEC_REMARQUE' = 'SATISFAISANT_AVEC_REMARQUE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionsVerificationDto20NowConditionDispenseEnum {
    'AUTRE' = 'AUTRE',
    'CONDITION_I' = 'CONDITION_I',
    'CONDITION_II' = 'CONDITION_II',
    'CONDITION_III' = 'CONDITION_III',
    'CONDITION_IV' = 'CONDITION_IV',
    'CONDITION_V' = 'CONDITION_V'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum {
    'DONNES_MANQUANTE_VERIFICATION' = 'DONNES_MANQUANTE_VERIFICATION',
    'INEXACTITUDE' = 'INEXACTITUDE',
    'IRREGULARITE' = 'IRREGULARITE',
    'NON_RESPECT_MRR' = 'NON_RESPECT_MRR',
    'PLAN_SURVEILLANCE_INCOMPLET' = 'PLAN_SURVEILLANCE_INCOMPLET',
    'PLAN_SURVEILLANCE_NON_APPROUVE' = 'PLAN_SURVEILLANCE_NON_APPROUVE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum {
    'INEXACTITUDE' = 'INEXACTITUDE',
    'IRREGULARITE' = 'IRREGULARITE',
    'NON_RESPECT_MRR' = 'NON_RESPECT_MRR',
    'RECOMMANDATION_AMELIORATION' = 'RECOMMANDATION_AMELIORATION'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasEmissionsVerificationDto20NowOrganismeEnum {
    'APAVE' = 'APAVE',
    'BUREAU_VERITAS_CERTIFICATION_FRANCE' = 'BUREAU_VERITAS_CERTIFICATION_FRANCE',
    'DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL' = 'DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL',
    'EY_ET_ASSOCIES' = 'EY_ET_ASSOCIES',
    'LRQA_FRANCE_SAS' = 'LRQA_FRANCE_SAS',
    'SGS_FRANCE' = 'SGS_FRANCE',
    'SOCOTEC_ENVIRONNEMENT' = 'SOCOTEC_ENVIRONNEMENT'
}

/**
 * 
 * @export
 * @interface QuotasFluxDerogationDto2121
 */
export interface QuotasFluxDerogationDto2121 {
    /**
     * 
     * @type {boolean}
     * @memberof QuotasFluxDerogationDto2121
     */
    derogationFrequenceEchantillonnage: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasFluxDerogationDto2121
     */
    fluxSoumisADerogation: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof QuotasFluxDerogationDto2121
     */
    nimDynId: string | null;
}
/**
 * 
 * @export
 * @interface QuotasInfosComplementairesInstallationsDto2021
 */
export interface QuotasInfosComplementairesInstallationsDto2021 {
    /**
     * 
     * @type {string}
     * @memberof QuotasInfosComplementairesInstallationsDto2021
     */
    categorie: QuotasInfosComplementairesInstallationsDto2021CategorieEnum | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasInfosComplementairesInstallationsDto2021
     */
    faiblementEmettrice: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInfosComplementairesInstallationsDto2021
     */
    nimDynId: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasInfosComplementairesInstallationsDto2021CategorieEnum {
    'A' = 'A',
    'B' = 'B',
    'C' = 'C'
}

/**
 * 
 * @export
 * @interface QuotasInstallationDto1819
 */
export interface QuotasInstallationDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto1819
     */
    categorie: QuotasInstallationDto1819CategorieEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasInstallationDto1819
     */
    codesActiviteID: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasInstallationDto1819
     */
    faiblementEmettrice: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto1819
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto1819
     */
    nim: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasInstallationDto1819CategorieEnum {
    'A' = 'A',
    'B' = 'B',
    'C' = 'C'
}

/**
 * 
 * @export
 * @interface QuotasInstallationDto20Now
 */
export interface QuotasInstallationDto20Now {
    /**
     * 
     * @type {Array<RestorationCode>}
     * @memberof QuotasInstallationDto20Now
     */
    codesActivite: Array<RestorationCode> | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto20Now
     */
    entreeSEQE: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto20Now
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasInstallationDto20Now
     */
    isNotAllocationGratuit: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasInstallationDto20Now
     */
    isNouvelEntrant: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto20Now
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto20Now
     */
    nomInstallation: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasInstallationDto20Now
     */
    sortieSEQE: string | null;
}
/**
 * 
 * @export
 * @interface QuotasVerificationDto1819
 */
export interface QuotasVerificationDto1819 {
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    conclusion: QuotasVerificationDto1819ConclusionEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    conditionDispense: QuotasVerificationDto1819ConditionDispenseEnum | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    dateDerniereVisite: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasVerificationDto1819
     */
    dispenseVisiteSite: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasVerificationDto1819
     */
    motifsNonVerifiee: Array<QuotasVerificationDto1819MotifsNonVerifieeEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuotasVerificationDto1819
     */
    motifsStatisfaisantAvecRemarque: Array<QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum>;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    nim: string | null;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    nomOrganisme: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof QuotasVerificationDto1819
     */
    rapportAmeliorationRequis: boolean;
    /**
     * 
     * @type {string}
     * @memberof QuotasVerificationDto1819
     */
    referenceDernierPlanSurveillance: string | null;
}

/**
    * @export
    * @enum {string}
    */
export enum QuotasVerificationDto1819ConclusionEnum {
    'NON_VERIFIEE' = 'NON_VERIFIEE',
    'SATISFAISANT' = 'SATISFAISANT',
    'SATISFAISANT_AVEC_REMARQUE' = 'SATISFAISANT_AVEC_REMARQUE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasVerificationDto1819ConditionDispenseEnum {
    'CONDITION_I' = 'CONDITION_I',
    'CONDITION_III' = 'CONDITION_III',
    'CONDITION_II_A' = 'CONDITION_II_A',
    'CONDITION_II_B' = 'CONDITION_II_B',
    'CONDITION_IV' = 'CONDITION_IV'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasVerificationDto1819MotifsNonVerifieeEnum {
    'INEXACTITUDE' = 'INEXACTITUDE',
    'LIMITATION_DONNEES' = 'LIMITATION_DONNEES',
    'NON_CONFORME' = 'NON_CONFORME',
    'PLAN_SURVEILLANCE_NON_APPROUVE' = 'PLAN_SURVEILLANCE_NON_APPROUVE',
    'PORTEE_LIMITEE' = 'PORTEE_LIMITEE'
}
/**
    * @export
    * @enum {string}
    */
export enum QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum {
    'INEXACTITUDE' = 'INEXACTITUDE',
    'IRREGULARITE' = 'IRREGULARITE',
    'NON_CONFORME_PLAN_SURVEILLANCE' = 'NON_CONFORME_PLAN_SURVEILLANCE',
    'NON_RESPECT_MRR' = 'NON_RESPECT_MRR',
    'RECOMMANDATION_AMELIORATION' = 'RECOMMANDATION_AMELIORATION'
}

/**
 * 
 * @export
 * @interface Rangebigdecimal
 */
export interface Rangebigdecimal {
    /**
     * 
     * @type {Comparatorbigdecimal}
     * @memberof Rangebigdecimal
     */
    comparator: Comparatorbigdecimal;
    /**
     * 
     * @type {number}
     * @memberof Rangebigdecimal
     */
    maximum: number;
    /**
     * 
     * @type {number}
     * @memberof Rangebigdecimal
     */
    minimum: number;
    /**
     * 
     * @type {boolean}
     * @memberof Rangebigdecimal
     */
    naturalOrdering: boolean;
}
/**
 * 
 * @export
 * @interface RapportAlcDataDto2123
 */
export interface RapportAlcDataDto2123 {
    /**
     * 
     * @type {InfosGeneralesAlcDto}
     * @memberof RapportAlcDataDto2123
     */
    infosGeneralesAlcDto: InfosGeneralesAlcDto;
    /**
     * 
     * @type {InfosInstallationDto2123}
     * @memberof RapportAlcDataDto2123
     */
    infosInstallationDto: InfosInstallationDto2123;
}
/**
 * 
 * @export
 * @interface RapportAlcDataDto24Now
 */
export interface RapportAlcDataDto24Now {
    /**
     * 
     * @type {InfosGeneralesAlcDto}
     * @memberof RapportAlcDataDto24Now
     */
    infosGeneralesAlcDto: InfosGeneralesAlcDto;
    /**
     * 
     * @type {InfosInstallationDto24Now}
     * @memberof RapportAlcDataDto24Now
     */
    infosInstallationDto: InfosInstallationDto24Now;
}
/**
 * 
 * @export
 * @interface RapportEmissionDataDto2121
 */
export interface RapportEmissionDataDto2121 {
    /**
     * 
     * @type {BilanTotalEmissionDto}
     * @memberof RapportEmissionDataDto2121
     */
    bilanTotalEmissionDto: BilanTotalEmissionDto;
    /**
     * 
     * @type {Array<Co2N2oTransferDto>}
     * @memberof RapportEmissionDataDto2121
     */
    co2N2oTransferDestinationList: Array<Co2N2oTransferDto>;
    /**
     * 
     * @type {Array<Co2N2oTransferDto>}
     * @memberof RapportEmissionDataDto2121
     */
    co2N2oTransferSourceList: Array<Co2N2oTransferDto>;
    /**
     * 
     * @type {InfosGeneralesEmissionDto}
     * @memberof RapportEmissionDataDto2121
     */
    infosGeneralesEmissionDto: InfosGeneralesEmissionDto;
    /**
     * 
     * @type {MethodeAlternativeDto}
     * @memberof RapportEmissionDataDto2121
     */
    methodeAlternativeDto: MethodeAlternativeDto;
    /**
     * 
     * @type {Array<MethodeCalculCo2Dto2121>}
     * @memberof RapportEmissionDataDto2121
     */
    methodeCalculCo2List: Array<MethodeCalculCo2Dto2121>;
    /**
     * 
     * @type {Array<MethodeCalculPfcDto2121>}
     * @memberof RapportEmissionDataDto2121
     */
    methodeCalculPfcList: Array<MethodeCalculPfcDto2121>;
    /**
     * 
     * @type {Array<MethodeMesureEmissionDto2121>}
     * @memberof RapportEmissionDataDto2121
     */
    methodeMesureEmissionList: Array<MethodeMesureEmissionDto2121>;
    /**
     * 
     * @type {MethodeSurveillanceDto}
     * @memberof RapportEmissionDataDto2121
     */
    methodeSurveillanceDto: MethodeSurveillanceDto;
    /**
     * 
     * @type {boolean}
     * @memberof RapportEmissionDataDto2121
     */
    mustFillCo2N2oTransferred: boolean;
}
/**
 * 
 * @export
 * @interface RapportEmissionDataDto22Now
 */
export interface RapportEmissionDataDto22Now {
    /**
     * 
     * @type {BilanTotalEmissionDto}
     * @memberof RapportEmissionDataDto22Now
     */
    bilanTotalEmissionDto: BilanTotalEmissionDto;
    /**
     * 
     * @type {Array<Co2N2oTransferDto>}
     * @memberof RapportEmissionDataDto22Now
     */
    co2N2oTransferDestinationList: Array<Co2N2oTransferDto>;
    /**
     * 
     * @type {Array<Co2N2oTransferDto>}
     * @memberof RapportEmissionDataDto22Now
     */
    co2N2oTransferSourceList: Array<Co2N2oTransferDto>;
    /**
     * 
     * @type {InfosGeneralesEmissionDto}
     * @memberof RapportEmissionDataDto22Now
     */
    infosGeneralesEmissionDto: InfosGeneralesEmissionDto;
    /**
     * 
     * @type {MethodeAlternativeDto}
     * @memberof RapportEmissionDataDto22Now
     */
    methodeAlternativeDto: MethodeAlternativeDto;
    /**
     * 
     * @type {Array<MethodeCalculCo2Dto22Now>}
     * @memberof RapportEmissionDataDto22Now
     */
    methodeCalculCo2List: Array<MethodeCalculCo2Dto22Now>;
    /**
     * 
     * @type {Array<MethodeCalculPfcDto22Now>}
     * @memberof RapportEmissionDataDto22Now
     */
    methodeCalculPfcList: Array<MethodeCalculPfcDto22Now>;
    /**
     * 
     * @type {Array<MethodeMesureEmissionDto22Now>}
     * @memberof RapportEmissionDataDto22Now
     */
    methodeMesureEmissionList: Array<MethodeMesureEmissionDto22Now>;
    /**
     * 
     * @type {MethodeSurveillanceDto}
     * @memberof RapportEmissionDataDto22Now
     */
    methodeSurveillanceDto: MethodeSurveillanceDto;
    /**
     * 
     * @type {boolean}
     * @memberof RapportEmissionDataDto22Now
     */
    mustFillCo2N2oTransferred: boolean;
}
/**
 * 
 * @export
 * @interface RapportEmissionDto2121
 */
export interface RapportEmissionDto2121 {
    /**
     * 
     * @type {FileDto}
     * @memberof RapportEmissionDto2121
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof RapportEmissionDto2121
     */
    nimDynId: string;
    /**
     * 
     * @type {RapportEmissionDataDto2121}
     * @memberof RapportEmissionDto2121
     */
    rapportEmissionData: RapportEmissionDataDto2121;
}
/**
 * 
 * @export
 * @interface RapportEmissionDto22Now
 */
export interface RapportEmissionDto22Now {
    /**
     * 
     * @type {FileDto}
     * @memberof RapportEmissionDto22Now
     */
    fichier: FileDto | null;
    /**
     * 
     * @type {string}
     * @memberof RapportEmissionDto22Now
     */
    nimDynId: string;
    /**
     * 
     * @type {RapportEmissionDataDto22Now}
     * @memberof RapportEmissionDto22Now
     */
    rapportEmissionData: RapportEmissionDataDto22Now;
}
/**
 * 
 * @export
 * @interface RapportEmissionJustificatifFilesDto
 */
export interface RapportEmissionJustificatifFilesDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof RapportEmissionJustificatifFilesDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof RapportEmissionJustificatifFilesDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface RapportOfficeDto
 */
export interface RapportOfficeDto {
    /**
     * 
     * @type {Array<FileDto>}
     * @memberof RapportOfficeDto
     */
    fichiers: Array<FileDto>;
    /**
     * 
     * @type {string}
     * @memberof RapportOfficeDto
     */
    nimDynId: string;
}
/**
 * 
 * @export
 * @interface ReceptionDechetsDto1822
 */
export interface ReceptionDechetsDto1822 {
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionDechetsDto1822
     */
    aCasiersAmiante: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionDechetsDto1822
     */
    aCasiersPlatre: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionDechetsDto1822
     */
    capaciteRestante: number | null;
    /**
     * 
     * @type {Array<DechetRecuDto1822>}
     * @memberof ReceptionDechetsDto1822
     */
    receptionDechets: Array<DechetRecuDto1822>;
}
/**
 * 
 * @export
 * @interface ReceptionDechetsDto23Now
 */
export interface ReceptionDechetsDto23Now {
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionDechetsDto23Now
     */
    aCasiersAmiante: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReceptionDechetsDto23Now
     */
    aCasiersPlatre: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ReceptionDechetsDto23Now
     */
    capaciteRestante: number | null;
    /**
     * 
     * @type {Array<DechetRecuDto23Now>}
     * @memberof ReceptionDechetsDto23Now
     */
    receptionDechets: Array<DechetRecuDto23Now>;
}
/**
 * 
 * @export
 * @interface RecyclageDto1822
 */
export interface RecyclageDto1822 {
    /**
     * 
     * @type {boolean}
     * @memberof RecyclageDto1822
     */
    doitRemplirTableau: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecyclageDto1822
     */
    quantiteEntrante: number;
    /**
     * 
     * @type {Array<ProduitRecyclageDto1822>}
     * @memberof RecyclageDto1822
     */
    tableauRecyclage: Array<ProduitRecyclageDto1822>;
}
/**
 * 
 * @export
 * @interface RecyclageDto23Now
 */
export interface RecyclageDto23Now {
    /**
     * 
     * @type {boolean}
     * @memberof RecyclageDto23Now
     */
    doitRemplirTableau: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecyclageDto23Now
     */
    quantiteEntrante: number;
    /**
     * 
     * @type {Array<ProduitRecyclageDto23Now>}
     * @memberof RecyclageDto23Now
     */
    tableauRecyclage: Array<ProduitRecyclageDto23Now>;
}
/**
 * 
 * @export
 * @interface RedirectView
 */
export interface RedirectView {
    /**
     * 
     * @type {ApplicationContext}
     * @memberof RedirectView
     */
    applicationContext: ApplicationContext | null;
    /**
     * 
     * @type {any}
     * @memberof RedirectView
     */
    attributesMap: any;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    beanName: string | null;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    contentType: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    exposePathVariables: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RedirectView
     */
    hosts: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    propagateQueryProperties: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RedirectView
     */
    redirectView: boolean;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    requestContextAttribute: string | null;
    /**
     * 
     * @type {any}
     * @memberof RedirectView
     */
    staticAttributes: any;
    /**
     * 
     * @type {string}
     * @memberof RedirectView
     */
    url: string | null;
}
/**
 * 
 * @export
 * @interface ReferenceActiviteQuotaDto
 */
export interface ReferenceActiviteQuotaDto {
    /**
     * 
     * @type {Array<ReferenceItemActiviteQuotaDto>}
     * @memberof ReferenceActiviteQuotaDto
     */
    referenceItemActiviteQuotaDtoList: Array<ReferenceItemActiviteQuotaDto>;
}
/**
 * 
 * @export
 * @interface ReferenceCodeApeDto
 */
export interface ReferenceCodeApeDto {
    /**
     * 
     * @type {Array<ReferenceItemCodeApeDto>}
     * @memberof ReferenceCodeApeDto
     */
    codesApe: Array<ReferenceItemCodeApeDto>;
}
/**
 * 
 * @export
 * @interface ReferenceCombustibleDto
 */
export interface ReferenceCombustibleDto {
    /**
     * 
     * @type {Array<ReferenceItemCombustibleDto>}
     * @memberof ReferenceCombustibleDto
     */
    referenceItemCombustibleDtoList: Array<ReferenceItemCombustibleDto>;
}
/**
 * 
 * @export
 * @interface ReferenceCoordonneesDepartementDto
 */
export interface ReferenceCoordonneesDepartementDto {
    /**
     * 
     * @type {Array<ReferenceItemCoordonneesDepartementDto>}
     * @memberof ReferenceCoordonneesDepartementDto
     */
    referenceItemCoordonneesDepartementDtoList: Array<ReferenceItemCoordonneesDepartementDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDechetDto
 */
export interface ReferenceDechetDto {
    /**
     * 
     * @type {Array<ReferenceItemDechetDto>}
     * @memberof ReferenceDechetDto
     */
    dechets: Array<ReferenceItemDechetDto>;
}
/**
 * 
 * @export
 * @interface ReferenceDepartementDto
 */
export interface ReferenceDepartementDto {
    /**
     * 
     * @type {Array<ReferenceItemDepartementDto>}
     * @memberof ReferenceDepartementDto
     */
    departements: Array<ReferenceItemDepartementDto>;
}
/**
 * 
 * @export
 * @interface ReferenceEprtrDto
 */
export interface ReferenceEprtrDto {
    /**
     * 
     * @type {Array<ReferenceItemEprtrDto>}
     * @memberof ReferenceEprtrDto
     */
    referenceItemEprtrDtoList: Array<ReferenceItemEprtrDto>;
}
/**
 * 
 * @export
 * @interface ReferenceEquipeGunDto
 */
export interface ReferenceEquipeGunDto {
    /**
     * 
     * @type {Array<EquipeGunItemDto>}
     * @memberof ReferenceEquipeGunDto
     */
    equipeGunList: Array<EquipeGunItemDto>;
}
/**
 * 
 * @export
 * @interface ReferenceFamilleUsageCodeProduitDto
 */
export interface ReferenceFamilleUsageCodeProduitDto {
    /**
     * 
     * @type {Array<ReferenceItemFamilleUsageCodeProduitDto>}
     * @memberof ReferenceFamilleUsageCodeProduitDto
     */
    referenceItemFamilleUsageCodeProduitDtoList: Array<ReferenceItemFamilleUsageCodeProduitDto>;
}
/**
 * 
 * @export
 * @interface ReferenceFamilleUsageProductionDto
 */
export interface ReferenceFamilleUsageProductionDto {
    /**
     * 
     * @type {Array<ReferenceItemFamilleUsageProductionDto>}
     * @memberof ReferenceFamilleUsageProductionDto
     */
    referenceItemFamilleUsageProductionDtoList: Array<ReferenceItemFamilleUsageProductionDto>;
}
/**
 * 
 * @export
 * @interface ReferenceFamilleUsageRecyclageDto
 */
export interface ReferenceFamilleUsageRecyclageDto {
    /**
     * 
     * @type {Array<ReferenceItemFamilleUsageRecyclageDto>}
     * @memberof ReferenceFamilleUsageRecyclageDto
     */
    referenceItemFamilleUsageRecyclageDtoList: Array<ReferenceItemFamilleUsageRecyclageDto>;
}
/**
 * 
 * @export
 * @interface ReferenceGazDto
 */
export interface ReferenceGazDto {
    /**
     * 
     * @type {Array<ReferenceItemGazDto>}
     * @memberof ReferenceGazDto
     */
    gaz: Array<ReferenceItemGazDto>;
}
/**
 * 
 * @export
 * @interface ReferenceGeoCodeInseeDto
 */
export interface ReferenceGeoCodeInseeDto {
    /**
     * 
     * @type {Array<ReferenceItemGeoCodeInseeDto>}
     * @memberof ReferenceGeoCodeInseeDto
     */
    referenceItemGeoCodeInseeDtoList: Array<ReferenceItemGeoCodeInseeDto>;
}
/**
 * 
 * @export
 * @interface ReferenceItemActiviteQuotaDto
 */
export interface ReferenceItemActiviteQuotaDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemActiviteQuotaDto
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferenceItemActiviteQuotaDto
     */
    gesConcerne: Array<ReferenceItemActiviteQuotaDtoGesConcerneEnum>;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemActiviteQuotaDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemActiviteQuotaDto
     */
    uuid: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceItemActiviteQuotaDtoGesConcerneEnum {
    'CO2' = 'CO2',
    'N2O' = 'N2O',
    'PFC' = 'PFC'
}

/**
 * 
 * @export
 * @interface ReferenceItemCarriereAccidentDto
 */
export interface ReferenceItemCarriereAccidentDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferenceItemCarriereAccidentDto
     */
    causes: Array<string>;
}
/**
 * 
 * @export
 * @interface ReferenceItemCodeApeDto
 */
export interface ReferenceItemCodeApeDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCodeApeDto
     */
    clefEchange: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCodeApeDto
     */
    codeApe: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemCodeApeDto
     */
    disponible: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCodeApeDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCodeApeDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCodeApeDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemCombustibleDto
 */
export interface ReferenceItemCombustibleDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    biomasse: ReferenceItemCombustibleDtoBiomasseEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    codeCombustible: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    commentaireFeCo2: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    commentaireFeSox: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    commentaireMasseVolumique: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    commentairePci: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    designation: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    feReferenceCo2: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    feReferenceSox: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    infCO2: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    infNOX: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    infSOX: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    masseVolumique: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    masseVolumiqueInf: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    masseVolumiqueSup: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    pciReference: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    pciReferenceInf: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    pciReferenceSup: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    restorationCode: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    supCO2: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    supNOX: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemCombustibleDto
     */
    supSOX: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCombustibleDto
     */
    uuid: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceItemCombustibleDtoBiomasseEnum {
    'NON' = 'NON',
    'OUI' = 'OUI',
    'PARTIEL' = 'PARTIEL'
}

/**
 * 
 * @export
 * @interface ReferenceItemCoordonneesDepartementDto
 */
export interface ReferenceItemCoordonneesDepartementDto {
    /**
     * 
     * @type {AreaRange}
     * @memberof ReferenceItemCoordonneesDepartementDto
     */
    areaRange: AreaRange;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCoordonneesDepartementDto
     */
    numeroDepartement: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemCoordonneesDepartementDto
     */
    systemeCoordonnees: ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum {
    'LAMBERT93' = 'LAMBERT93',
    'UTM_20N' = 'UTM_20N',
    'UTM_21N' = 'UTM_21N',
    'UTM_22N' = 'UTM_22N',
    'UTM_38S' = 'UTM_38S',
    'UTM_40S' = 'UTM_40S',
    'WGS84' = 'WGS84'
}

/**
 * 
 * @export
 * @interface ReferenceItemDechetDto
 */
export interface ReferenceItemDechetDto {
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemDechetDto
     */
    avecAmiante: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDechetDto
     */
    codeDechet: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemDechetDto
     */
    dangereux: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemDechetDto
     */
    dechetInerte: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemDechetDto
     */
    disponible: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDechetDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemDechetDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDechetDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemDepartementDto
 */
export interface ReferenceItemDepartementDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    chefLieu: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    codeRegion: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    ncc: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    nccenr: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    nom: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    numero: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemDepartementDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemDepartementDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemElementIndexDto
 */
export interface ReferenceItemElementIndexDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemElementIndexDto
     */
    nom: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemElementIndexDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemEprtrDto
 */
export interface ReferenceItemEprtrDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemEprtrDto
     */
    codeActivite: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemEprtrDto
     */
    estSelectionnable: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemEprtrDto
     */
    idActivite: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemEprtrDto
     */
    matiereProduite: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemEprtrDto
     */
    niveau: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemEprtrDto
     */
    nomActivite: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemEprtrDto
     */
    ordre: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemEprtrDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemEprtrDto
     */
    unite: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemEprtrDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemFamilleUsageCodeProduitDto
 */
export interface ReferenceItemFamilleUsageCodeProduitDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageCodeProduitDto
     */
    codeProduit: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageCodeProduitDto
     */
    libelle: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemFamilleUsageProductionDto
 */
export interface ReferenceItemFamilleUsageProductionDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    codeProduit: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    libelle: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    libelleCourt: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    precision: boolean;
    /**
     * 
     * @type {Array<ReferenceItemFamilleUsageProductionDto>}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    referenceItemFamilleUsageDtos: Array<ReferenceItemFamilleUsageProductionDto>;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    restorationCode: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    seuil: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageProductionDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemFamilleUsageRecyclageDto
 */
export interface ReferenceItemFamilleUsageRecyclageDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    libelle: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    precision: boolean;
    /**
     * 
     * @type {Array<ReferenceItemFamilleUsageRecyclageDto>}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    referenceItemFamilleUsageDtos: Array<ReferenceItemFamilleUsageRecyclageDto>;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemFamilleUsageRecyclageDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemGazDto
 */
export interface ReferenceItemGazDto {
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc1233zd: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc1234yf: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc1234ze: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc125: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc134: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc134a: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc143: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc143a: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc152a: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc227ea: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc23: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc236fa: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc245ca: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc245fa: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc32: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc365mfc: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc41: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    hfc4310mee: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGazDto
     */
    nom: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    r218: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    r600a: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGazDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGazDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemGeoCodeInseeDto
 */
export interface ReferenceItemGeoCodeInseeDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    ancienneRegion: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    codeInsee: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    codePostal: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    nomCommune: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    nomDepartement: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    nouvelleRegion: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    numeroDepartement: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemGeoCodeInseeDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemNormeAirDto
 */
export interface ReferenceItemNormeAirDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeAirDto
     */
    descriptionNorme: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeAirDto
     */
    idNorme: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeAirDto
     */
    libelleMethode: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeAirDto
     */
    libelleNorme: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeAirDto
     */
    libelleReference: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeAirDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeAirDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemNormeEauDto
 */
export interface ReferenceItemNormeEauDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeEauDto
     */
    descriptionNorme: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeEauDto
     */
    idNorme: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeEauDto
     */
    libelle: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeEauDto
     */
    matchInt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeEauDto
     */
    matchNro: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeEauDto
     */
    matchPer: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeEauDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeEauDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemNormeSolDto
 */
export interface ReferenceItemNormeSolDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeSolDto
     */
    descriptionNorme: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeSolDto
     */
    idNorme: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeSolDto
     */
    libelle: string;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeSolDto
     */
    matchInt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeSolDto
     */
    matchNro: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemNormeSolDto
     */
    matchPer: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemNormeSolDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemNormeSolDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemOrganismeAccrediteDto
 */
export interface ReferenceItemOrganismeAccrediteDto {
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemOrganismeAccrediteDto
     */
    actif: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeAccrediteDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeAccrediteDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemOrganismeAccrediteDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeAccrediteDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemOrganismeExterieurPreventionDto
 */
export interface ReferenceItemOrganismeExterieurPreventionDto {
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemOrganismeExterieurPreventionDto
     */
    actif: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeExterieurPreventionDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeExterieurPreventionDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemOrganismeExterieurPreventionDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemOrganismeExterieurPreventionDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemPaysDto
 */
export interface ReferenceItemPaysDto {
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPaysDto
     */
    codeInsee: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPaysDto
     */
    designation: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPaysDto
     */
    restorationCode: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemPaysDto
     */
    ue: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPaysDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemPolluantDto
 */
export interface ReferenceItemPolluantDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    cas: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemPolluantDto
     */
    combustion20MW: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemPolluantDto
     */
    covNm: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantDto
     */
    emissionNationale: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemPolluantDto
     */
    incineration: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    nom: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    sandre: string | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    section: string | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantDto
     */
    seuilGJ: number | null;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantDto
     */
    seuilKgAn: number | null;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    uniteUsuelle: ReferenceItemPolluantDtoUniteUsuelleEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemPolluantDto
     */
    uuid: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceItemPolluantDtoUniteUsuelleEnum {
    'G_AN' = 'G_AN',
    'KG_AN' = 'KG_AN',
    'KG_J' = 'KG_J',
    'KT_AN' = 'KT_AN',
    'MG_AN' = 'MG_AN',
    'T_AN' = 'T_AN'
}

/**
 * 
 * @export
 * @interface ReferenceItemPolluantElementDto
 */
export interface ReferenceItemPolluantElementDto {
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantElementDto
     */
    partElement: number;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemPolluantElementDto
     */
    polluantRestorationCode: number;
    /**
     * 
     * @type {ReferenceItemElementIndexDto}
     * @memberof ReferenceItemPolluantElementDto
     */
    referenceItemElementIndexDto: ReferenceItemElementIndexDto;
}
/**
 * 
 * @export
 * @interface ReferenceItemRegionDto
 */
export interface ReferenceItemRegionDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemRegionDto
     */
    capNom: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemRegionDto
     */
    codeRegion: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemRegionDto
     */
    nom: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemRegionDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemRegionDto
     */
    trigramme: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemRegionDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemSandreDto
 */
export interface ReferenceItemSandreDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    bassindce: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    codeEu: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemSandreDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    type: ReferenceItemSandreDtoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSandreDto
     */
    uuid: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferenceItemSandreDtoTypeEnum {
    'EAU_SOUTERRAINE' = 'EAU_SOUTERRAINE',
    'MASSE_EAU_COTIERE' = 'MASSE_EAU_COTIERE',
    'MASSE_EAU_TRANSITION' = 'MASSE_EAU_TRANSITION',
    'PLAN_D_EAU' = 'PLAN_D_EAU',
    'RIVIERE' = 'RIVIERE'
}

/**
 * 
 * @export
 * @interface ReferenceItemStationEpurationDto
 */
export interface ReferenceItemStationEpurationDto {
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemStationEpurationDto
     */
    codeDepartement: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemStationEpurationDto
     */
    codeOuvrageDepollution: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemStationEpurationDto
     */
    gid: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemStationEpurationDto
     */
    nomOuvrageDepollution: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemStationEpurationDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemStationEpurationDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceItemSubstanceCarriereDto
 */
export interface ReferenceItemSubstanceCarriereDto {
    /**
     * 
     * @type {boolean}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    actif: boolean;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    code: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    glossaire: string;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    libelle: string;
    /**
     * 
     * @type {number}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    restorationCode: number;
    /**
     * 
     * @type {string}
     * @memberof ReferenceItemSubstanceCarriereDto
     */
    uuid: string;
}
/**
 * 
 * @export
 * @interface ReferenceNatureServiceDto
 */
export interface ReferenceNatureServiceDto {
    /**
     * 
     * @type {Array<NatureServiceWithEnumDto>}
     * @memberof ReferenceNatureServiceDto
     */
    organismes: Array<NatureServiceWithEnumDto>;
}
/**
 * 
 * @export
 * @interface ReferenceNormeAirDto
 */
export interface ReferenceNormeAirDto {
    /**
     * 
     * @type {Array<ReferenceItemNormeAirDto>}
     * @memberof ReferenceNormeAirDto
     */
    referenceItemNormeAirDtoList: Array<ReferenceItemNormeAirDto>;
}
/**
 * 
 * @export
 * @interface ReferenceNormeEauDto
 */
export interface ReferenceNormeEauDto {
    /**
     * 
     * @type {Array<ReferenceItemNormeEauDto>}
     * @memberof ReferenceNormeEauDto
     */
    referenceItemNormeEauDtoList: Array<ReferenceItemNormeEauDto>;
}
/**
 * 
 * @export
 * @interface ReferenceNormeSolDto
 */
export interface ReferenceNormeSolDto {
    /**
     * 
     * @type {Array<ReferenceItemNormeSolDto>}
     * @memberof ReferenceNormeSolDto
     */
    referenceItemNormeSolDtoList: Array<ReferenceItemNormeSolDto>;
}
/**
 * 
 * @export
 * @interface ReferenceOrganismeAccrediteDto
 */
export interface ReferenceOrganismeAccrediteDto {
    /**
     * 
     * @type {Array<ReferenceItemOrganismeAccrediteDto>}
     * @memberof ReferenceOrganismeAccrediteDto
     */
    referenceItemOrganismeAccrediteDtoList: Array<ReferenceItemOrganismeAccrediteDto>;
}
/**
 * 
 * @export
 * @interface ReferenceOrganismeExterieurPreventionDto
 */
export interface ReferenceOrganismeExterieurPreventionDto {
    /**
     * 
     * @type {Array<ReferenceItemOrganismeExterieurPreventionDto>}
     * @memberof ReferenceOrganismeExterieurPreventionDto
     */
    referenceItemOrganismeExterieurPreventionDtoList: Array<ReferenceItemOrganismeExterieurPreventionDto>;
}
/**
 * 
 * @export
 * @interface ReferencePaysDto
 */
export interface ReferencePaysDto {
    /**
     * 
     * @type {Array<ReferenceItemPaysDto>}
     * @memberof ReferencePaysDto
     */
    referenceItemPaysDtoList: Array<ReferenceItemPaysDto>;
}
/**
 * 
 * @export
 * @interface ReferencePolluantDefaultUniteDto
 */
export interface ReferencePolluantDefaultUniteDto {
    /**
     * 
     * @type {Array<ReferencePolluantDefaultUniteItemDto>}
     * @memberof ReferencePolluantDefaultUniteDto
     */
    polluantConcentrationUniteDtoList: Array<ReferencePolluantDefaultUniteItemDto>;
}
/**
 * 
 * @export
 * @interface ReferencePolluantDefaultUniteItemDto
 */
export interface ReferencePolluantDefaultUniteItemDto {
    /**
     * 
     * @type {string}
     * @memberof ReferencePolluantDefaultUniteItemDto
     */
    concentrationUsualUnit: ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ReferencePolluantDefaultUniteItemDto
     */
    restorationId: number;
}

/**
    * @export
    * @enum {string}
    */
export enum ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum {
    'G_NM3' = 'G_NM3',
    'KG_NM3' = 'KG_NM3',
    'MG_NM3' = 'MG_NM3',
    'NG_NM3' = 'NG_NM3',
    'PG_NM3' = 'PG_NM3',
    'UG_NM3' = 'UG_NM3'
}

/**
 * 
 * @export
 * @interface ReferencePolluantDto
 */
export interface ReferencePolluantDto {
    /**
     * 
     * @type {Array<ReferenceItemPolluantDto>}
     * @memberof ReferencePolluantDto
     */
    polluants: Array<ReferenceItemPolluantDto>;
}
/**
 * 
 * @export
 * @interface ReferencePolluantElementDto
 */
export interface ReferencePolluantElementDto {
    /**
     * 
     * @type {Array<ReferenceItemPolluantElementDto>}
     * @memberof ReferencePolluantElementDto
     */
    referenceItemPolluantElementDtoList: Array<ReferenceItemPolluantElementDto>;
}
/**
 * 
 * @export
 * @interface ReferenceRegionDto
 */
export interface ReferenceRegionDto {
    /**
     * 
     * @type {Array<ReferenceItemRegionDto>}
     * @memberof ReferenceRegionDto
     */
    referenceItemRegionDtoList: Array<ReferenceItemRegionDto>;
}
/**
 * 
 * @export
 * @interface ReferenceSandreDto
 */
export interface ReferenceSandreDto {
    /**
     * 
     * @type {Array<ReferenceItemSandreDto>}
     * @memberof ReferenceSandreDto
     */
    referenceItemSandreDtoList: Array<ReferenceItemSandreDto>;
}
/**
 * 
 * @export
 * @interface ReferenceServiceGunDto
 */
export interface ReferenceServiceGunDto {
    /**
     * 
     * @type {Array<ServiceGunItemDto>}
     * @memberof ReferenceServiceGunDto
     */
    serviceGunDtoList: Array<ServiceGunItemDto>;
}
/**
 * 
 * @export
 * @interface ReferenceStationEpurationDto
 */
export interface ReferenceStationEpurationDto {
    /**
     * 
     * @type {Array<ReferenceItemStationEpurationDto>}
     * @memberof ReferenceStationEpurationDto
     */
    referenceItemStationEpurationDtoList: Array<ReferenceItemStationEpurationDto>;
}
/**
 * 
 * @export
 * @interface ReferenceSubstanceCarriereDto
 */
export interface ReferenceSubstanceCarriereDto {
    /**
     * 
     * @type {Array<ReferenceItemSubstanceCarriereDto>}
     * @memberof ReferenceSubstanceCarriereDto
     */
    referenceItemSubstanceCarriereDtoList: Array<ReferenceItemSubstanceCarriereDto>;
}
/**
 * 
 * @export
 * @interface RejetIsoleEauDto
 */
export interface RejetIsoleEauDto {
    /**
     * 
     * @type {number}
     * @memberof RejetIsoleEauDto
     */
    chaleurRejetee: number | null;
    /**
     * 
     * @type {string}
     * @memberof RejetIsoleEauDto
     */
    milieuRecepteurFinalID: string | null;
    /**
     * 
     * @type {number}
     * @memberof RejetIsoleEauDto
     */
    volumeAnnuelRejete: number | null;
}
/**
 * 
 * @export
 * @interface RejetRaccordeEauDto1822
 */
export interface RejetRaccordeEauDto1822 {
    /**
     * 
     * @type {number}
     * @memberof RejetRaccordeEauDto1822
     */
    chaleurRejetee: number | null;
    /**
     * 
     * @type {string}
     * @memberof RejetRaccordeEauDto1822
     */
    milieuRecepteurFinalID: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejetRaccordeEauDto1822
     */
    nomStationEpurationID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RejetRaccordeEauDto1822
     */
    stationEpurationAutre: boolean;
    /**
     * 
     * @type {number}
     * @memberof RejetRaccordeEauDto1822
     */
    volumeAnnuelRejete: number | null;
}
/**
 * 
 * @export
 * @interface RejetRaccordeEauDto23Now
 */
export interface RejetRaccordeEauDto23Now {
    /**
     * 
     * @type {boolean}
     * @memberof RejetRaccordeEauDto23Now
     */
    alimentationEauIndustrielle: boolean;
    /**
     * 
     * @type {number}
     * @memberof RejetRaccordeEauDto23Now
     */
    chaleurRejetee: number | null;
    /**
     * 
     * @type {string}
     * @memberof RejetRaccordeEauDto23Now
     */
    milieuRecepteurFinalID: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejetRaccordeEauDto23Now
     */
    nomStationEpurationID: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RejetRaccordeEauDto23Now
     */
    stationEpurationAutre: boolean;
    /**
     * 
     * @type {number}
     * @memberof RejetRaccordeEauDto23Now
     */
    volumeAnnuelRejete: number | null;
}
/**
 * 
 * @export
 * @interface RejetSolDto
 */
export interface RejetSolDto {
    /**
     * 
     * @type {string}
     * @memberof RejetSolDto
     */
    description: string | null;
    /**
     * 
     * @type {number}
     * @memberof RejetSolDto
     */
    emission: number | null;
    /**
     * 
     * @type {string}
     * @memberof RejetSolDto
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof RejetSolDto
     */
    masseAccidentelle: number | null;
    /**
     * 
     * @type {string}
     * @memberof RejetSolDto
     */
    methodeUtilisee: RejetSolDtoMethodeUtiliseeEnum | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof RejetSolDto
     */
    normesID: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RejetSolDto
     */
    polluantID: string | null;
    /**
     * 
     * @type {string}
     * @memberof RejetSolDto
     */
    referenceMethode: RejetSolDtoReferenceMethodeEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum RejetSolDtoMethodeUtiliseeEnum {
    'CALCUL' = 'CALCUL',
    'ESTIMATION' = 'ESTIMATION',
    'MESURE' = 'MESURE'
}
/**
    * @export
    * @enum {string}
    */
export enum RejetSolDtoReferenceMethodeEnum {
    'ALT' = 'ALT',
    'AUT' = 'AUT',
    'BMA' = 'BMA',
    'CSS' = 'CSS',
    'INT' = 'INT',
    'MRC' = 'MRC',
    'NRO' = 'NRO',
    'PER' = 'PER'
}

/**
 * 
 * @export
 * @interface ResteVentilationDto
 */
export interface ResteVentilationDto {
    /**
     * 
     * @type {number}
     * @memberof ResteVentilationDto
     */
    reste: number | null;
}
/**
 * 
 * @export
 * @interface RestorationCode
 */
export interface RestorationCode {
    /**
     * 
     * @type {number}
     * @memberof RestorationCode
     */
    code: number;
}
/**
 * 
 * @export
 * @interface RetrieveRightsRequestBodyDto
 */
export interface RetrieveRightsRequestBodyDto {
    /**
     * 
     * @type {string}
     * @memberof RetrieveRightsRequestBodyDto
     */
    monAIOTAccessToken: string;
}
/**
 * 
 * @export
 * @interface SanteDto
 */
export interface SanteDto {
    /**
     * 
     * @type {AccidentDto}
     * @memberof SanteDto
     */
    accident: AccidentDto;
    /**
     * 
     * @type {EmpoussierageDto}
     * @memberof SanteDto
     */
    empoussierage: EmpoussierageDto;
    /**
     * 
     * @type {PreventionDto}
     * @memberof SanteDto
     */
    prevention: PreventionDto;
}
/**
 * 
 * @export
 * @interface ServiceGunItemDto
 */
export interface ServiceGunItemDto {
    /**
     * 
     * @type {string}
     * @memberof ServiceGunItemDto
     */
    codeService: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGunItemDto
     */
    dateDerniereModification: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGunItemDto
     */
    identifiantService: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGunItemDto
     */
    libelleCourt: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceGunItemDto
     */
    libelleLong: string;
}
/**
 * 
 * @export
 * @interface SimpleDeclarationDto
 */
export interface SimpleDeclarationDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleDeclarationDto
     */
    annee: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    commune: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    etablissement: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    etablissementName: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedDeclaringAllocation: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedDeclaringEmission: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedDeclaringGlobal: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedInspectorAllocation: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedInspectorEmission: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleDeclarationDto
     */
    lastTimeEditedInspectorGlobal: string;
    /**
     * 
     * @type {SimpleDenormalizedDto}
     * @memberof SimpleDeclarationDto
     */
    simpleDenormalizedDto: SimpleDenormalizedDto;
}
/**
 * 
 * @export
 * @interface SimpleDeclarationListDto
 */
export interface SimpleDeclarationListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SimpleDeclarationListDto
     */
    departementNumbersList: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimpleDeclarationListDto
     */
    nbTotalDeclarations: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDeclarationListDto
     */
    nbTotalDeclarationsToCheck: number;
    /**
     * 
     * @type {Array<SimpleDeclarationDto>}
     * @memberof SimpleDeclarationListDto
     */
    pageDeclarations: Array<SimpleDeclarationDto>;
}
/**
 * 
 * @export
 * @interface SimpleDenormalizedDto
 */
export interface SimpleDenormalizedDto {
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    carriere: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    consoOfSolvant: boolean;
    /**
     * 
     * @type {number}
     * @memberof SimpleDenormalizedDto
     */
    declarationProgression: number;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    depassementDeSeuil: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    elevage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    eprtr: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    errorPresent: boolean;
    /**
     * 
     * @type {string}
     * @memberof SimpleDenormalizedDto
     */
    generalState: SimpleDenormalizedDtoGeneralStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleDenormalizedDto
     */
    quotaAllocationsState: SimpleDenormalizedDtoQuotaAllocationsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleDenormalizedDto
     */
    quotaEmissionsState: SimpleDenormalizedDtoQuotaEmissionsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    quotas: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    quotasAllocations: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    quotasExclusionsIncinerateur: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    rienADeclarer: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleDenormalizedDto
     */
    warningPresent: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SimpleDenormalizedDtoGeneralStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum SimpleDenormalizedDtoQuotaAllocationsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum SimpleDenormalizedDtoQuotaEmissionsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}

/**
 * 
 * @export
 * @interface SimpleInstallationDenormalizedDto
 */
export interface SimpleInstallationDenormalizedDto {
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDenormalizedDto
     */
    departement: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDenormalizedDto
     */
    nomInstallation: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDenormalizedDto
     */
    numeroNim: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDenormalizedDto
     */
    quotaAllocationsState: SimpleInstallationDenormalizedDtoQuotaAllocationsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDenormalizedDto
     */
    quotaEmissionsState: SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SimpleInstallationDenormalizedDto
     */
    rienADeclarer: boolean;
}

/**
    * @export
    * @enum {string}
    */
export enum SimpleInstallationDenormalizedDtoQuotaAllocationsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}
/**
    * @export
    * @enum {string}
    */
export enum SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum {
    'CREATED' = 'CREATED',
    'FORCED' = 'FORCED',
    'IN_REVIEW' = 'IN_REVIEW',
    'NONE' = 'NONE',
    'RECALLED' = 'RECALLED',
    'STARTED' = 'STARTED',
    'SUBMITTED' = 'SUBMITTED',
    'VALIDATED' = 'VALIDATED',
    'VERIFIED' = 'VERIFIED',
    'VERIFYING' = 'VERIFYING'
}

/**
 * 
 * @export
 * @interface SimpleInstallationDto
 */
export interface SimpleInstallationDto {
    /**
     * 
     * @type {number}
     * @memberof SimpleInstallationDto
     */
    annee: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    etablissement: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    etablissementName: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    lastTimeEditedAllocationDeclarant: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    lastTimeEditedAllocationInspector: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    lastTimeEditedEmissionDeclarant: string;
    /**
     * 
     * @type {string}
     * @memberof SimpleInstallationDto
     */
    lastTimeEditedEmissionInspector: string;
    /**
     * 
     * @type {SimpleInstallationDenormalizedDto}
     * @memberof SimpleInstallationDto
     */
    simpleInstallationDenormalizedDto: SimpleInstallationDenormalizedDto;
}
/**
 * 
 * @export
 * @interface SimpleInstallationListDto
 */
export interface SimpleInstallationListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SimpleInstallationListDto
     */
    departementNumbersList: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SimpleInstallationListDto
     */
    nbTotalDeclarations: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleInstallationListDto
     */
    nbTotalDeclarationsToCheck: number;
    /**
     * 
     * @type {Array<SimpleInstallationDto>}
     * @memberof SimpleInstallationListDto
     */
    pageDeclarations: Array<SimpleInstallationDto>;
}
/**
 * 
 * @export
 * @interface SolDto
 */
export interface SolDto {
    /**
     * 
     * @type {boolean}
     * @memberof SolDto
     */
    doitRemplirSol: boolean;
    /**
     * 
     * @type {Array<RejetSolDto>}
     * @memberof SolDto
     */
    rejets: Array<RejetSolDto>;
    /**
     * 
     * @type {number}
     * @memberof SolDto
     */
    volumeDechets: number | null;
}
/**
 * 
 * @export
 * @interface SousInstallationRefAlternatifDataDto2123
 */
export interface SousInstallationRefAlternatifDataDto2123 {
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    adaptationEffectiveNiveauActivite: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    allocationFinale: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    criteresAdaptationValidees: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    decisionNecessaireAutoriteCompetente: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    efficaciteEnergetique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    efficaciteMoyenne: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    emissionsAttribuees: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    niveauActivite: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    niveauActiviteMoyen: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    nonRejetParAutoriteCompetente: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    variationEfficaciteEnergetique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto2123
     */
    variationNiveauActivite: number | null;
}
/**
 * 
 * @export
 * @interface SousInstallationRefAlternatifDataDto24Now
 */
export interface SousInstallationRefAlternatifDataDto24Now {
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    adaptationEffectiveNiveauActivite: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    allocationFinale: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    allocationProvisoire: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    criteresAdaptationValidees: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    decisionNecessaireAutoriteCompetente: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    efficaciteEnergetique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    efficaciteMoyenne: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    emissionsAttribuees: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    niveauActivite: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    niveauActiviteMoyen: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    nonRejetParAutoriteCompetente: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    variationEfficaciteEnergetique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefAlternatifDataDto24Now
     */
    variationNiveauActivite: number | null;
}
/**
 * 
 * @export
 * @interface SousInstallationRefAlternatifDto2123
 */
export interface SousInstallationRefAlternatifDto2123 {
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDto2123
     */
    refChauffageUrbainOrProcede: boolean;
    /**
     * 
     * @type {string}
     * @memberof SousInstallationRefAlternatifDto2123
     */
    title: string | null;
    /**
     * 
     * @type {UniteRefAlternatifDto}
     * @memberof SousInstallationRefAlternatifDto2123
     */
    uniteRefAlternatifDto: UniteRefAlternatifDto;
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefAlternatifDataDto2123; }}
     * @memberof SousInstallationRefAlternatifDto2123
     */
    yearToSousInstallationDataMap: { [key: string]: SousInstallationRefAlternatifDataDto2123; };
}
/**
 * 
 * @export
 * @interface SousInstallationRefAlternatifDto24Now
 */
export interface SousInstallationRefAlternatifDto24Now {
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefAlternatifDto24Now
     */
    refChauffageUrbainOrProcede: boolean;
    /**
     * 
     * @type {string}
     * @memberof SousInstallationRefAlternatifDto24Now
     */
    title: string | null;
    /**
     * 
     * @type {UniteRefAlternatifDto}
     * @memberof SousInstallationRefAlternatifDto24Now
     */
    uniteRefAlternatifDto: UniteRefAlternatifDto;
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefAlternatifDataDto24Now; }}
     * @memberof SousInstallationRefAlternatifDto24Now
     */
    yearToSousInstallationDataMap: { [key: string]: SousInstallationRefAlternatifDataDto24Now; };
}
/**
 * 
 * @export
 * @interface SousInstallationRefProduitDataDto
 */
export interface SousInstallationRefProduitDataDto {
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    allocationFinale: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    allocationProvisoireAvecParametresIntegres: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    allocationProvisoireSurBaseNiveauxActivites: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SousInstallationRefProduitDataDto
     */
    criteresAdaptationValidees: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    emissionsAttribuees: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    niveauActivite: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    niveauActiviteMoyen: number | null;
    /**
     * 
     * @type {number}
     * @memberof SousInstallationRefProduitDataDto
     */
    variationNiveauActivite: number | null;
}
/**
 * 
 * @export
 * @interface SousInstallationRefProduitDto
 */
export interface SousInstallationRefProduitDto {
    /**
     * 
     * @type {string}
     * @memberof SousInstallationRefProduitDto
     */
    title: string | null;
    /**
     * 
     * @type {string}
     * @memberof SousInstallationRefProduitDto
     */
    unite: string | null;
    /**
     * 
     * @type {{ [key: string]: SousInstallationRefProduitDataDto; }}
     * @memberof SousInstallationRefProduitDto
     */
    yearToSousInstallationDataMap: { [key: string]: SousInstallationRefProduitDataDto; };
}
/**
 * 
 * @export
 * @interface SpfoDto
 */
export interface SpfoDto {
    /**
     * 
     * @type {boolean}
     * @memberof SpfoDto
     */
    activiteTraitementSurface: boolean;
    /**
     * 
     * @type {string}
     * @memberof SpfoDto
     */
    natureProcedeTraitementSurface: string | null;
    /**
     * 
     * @type {number}
     * @memberof SpfoDto
     */
    quantiteEliminee: number;
    /**
     * 
     * @type {number}
     * @memberof SpfoDto
     */
    quantiteStockeeSurSite: number;
    /**
     * 
     * @type {number}
     * @memberof SpfoDto
     */
    quantiteUtilisee: number;
}
/**
 * 
 * @export
 * @interface SubstanceExtractionDto1822
 */
export interface SubstanceExtractionDto1822 {
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    familleId: string;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    precisionFamille: string | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    precisionSousFamilleNiveau1: string | null;
    /**
     * 
     * @type {number}
     * @memberof SubstanceExtractionDto1822
     */
    quantiteExtraite: number;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    sousFamilleId: string | null;
    /**
     * 
     * @type {Array<ElementBasiqueDto>}
     * @memberof SubstanceExtractionDto1822
     */
    sousFamillesNiveau2: Array<ElementBasiqueDto>;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto1822
     */
    substanceID: string;
}
/**
 * 
 * @export
 * @interface SubstanceExtractionDto23Now
 */
export interface SubstanceExtractionDto23Now {
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof SubstanceExtractionDto23Now
     */
    famille: ElementBasiqueDto | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto23Now
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof SubstanceExtractionDto23Now
     */
    quantite: number;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof SubstanceExtractionDto23Now
     */
    sousFamille: ElementBasiqueDto | null;
    /**
     * 
     * @type {ElementBasiqueDto}
     * @memberof SubstanceExtractionDto23Now
     */
    sousFamilleNiveau2: ElementBasiqueDto | null;
    /**
     * 
     * @type {string}
     * @memberof SubstanceExtractionDto23Now
     */
    substanceId: string;
}
/**
 * 
 * @export
 * @interface SuperficieDto
 */
export interface SuperficieDto {
    /**
     * 
     * @type {boolean}
     * @memberof SuperficieDto
     */
    aRemisEnEtat: boolean;
    /**
     * 
     * @type {DetailsSuperficieEcouleeDto}
     * @memberof SuperficieDto
     */
    remisEnEtatAnneeEcoulee: DetailsSuperficieEcouleeDto;
    /**
     * 
     * @type {DetailsSuperficiePrecedenteDto}
     * @memberof SuperficieDto
     */
    remisEnEtatAnneePrecedentes: DetailsSuperficiePrecedenteDto;
}
/**
 * 
 * @export
 * @interface SuperficieEauEcouleeDto
 */
export interface SuperficieEauEcouleeDto {
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauEcouleeDto
     */
    autre: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuperficieEauEcouleeDto
     */
    typeRemiseEtatAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauEcouleeDto
     */
    zoneEcologique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauEcouleeDto
     */
    zoneEconomique: number | null;
}
/**
 * 
 * @export
 * @interface SuperficieEauPrecedenteDto
 */
export interface SuperficieEauPrecedenteDto {
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauPrecedenteDto
     */
    autre: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuperficieEauPrecedenteDto
     */
    typeRemiseEtatAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauPrecedenteDto
     */
    zoneEcologique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieEauPrecedenteDto
     */
    zoneEconomique: number | null;
}
/**
 * 
 * @export
 * @interface SuperficieTerreEcouleeDto
 */
export interface SuperficieTerreEcouleeDto {
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerreEcouleeDto
     */
    autre: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuperficieTerreEcouleeDto
     */
    typeRemiseEtatAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerreEcouleeDto
     */
    zoneAgricole: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerreEcouleeDto
     */
    zoneEcologique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerreEcouleeDto
     */
    zoneEconomique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerreEcouleeDto
     */
    zoneForestiere: number | null;
}
/**
 * 
 * @export
 * @interface SuperficieTerrePrecedenteDto
 */
export interface SuperficieTerrePrecedenteDto {
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerrePrecedenteDto
     */
    autre: number | null;
    /**
     * 
     * @type {string}
     * @memberof SuperficieTerrePrecedenteDto
     */
    typeRemiseEtatAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerrePrecedenteDto
     */
    zoneAgricole: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerrePrecedenteDto
     */
    zoneEcologique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerrePrecedenteDto
     */
    zoneEconomique: number | null;
    /**
     * 
     * @type {number}
     * @memberof SuperficieTerrePrecedenteDto
     */
    zoneForestiere: number | null;
}
/**
 * 
 * @export
 * @interface SurveillanceEmissionDto
 */
export interface SurveillanceEmissionDto {
    /**
     * 
     * @type {string}
     * @memberof SurveillanceEmissionDto
     */
    incertitude: string | null;
    /**
     * 
     * @type {string}
     * @memberof SurveillanceEmissionDto
     */
    niveauApplique: SurveillanceEmissionDtoNiveauAppliqueEnum | null;
    /**
     * 
     * @type {string}
     * @memberof SurveillanceEmissionDto
     */
    niveauRequis: SurveillanceEmissionDtoNiveauRequisEnum | null;
}

/**
    * @export
    * @enum {string}
    */
export enum SurveillanceEmissionDtoNiveauAppliqueEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}
/**
    * @export
    * @enum {string}
    */
export enum SurveillanceEmissionDtoNiveauRequisEnum {
    'FOUR' = 'FOUR',
    'NO_LEVEL' = 'NO_LEVEL',
    'ONE' = 'ONE',
    'S_O' = 'S_O',
    'THREE' = 'THREE',
    'TWO' = 'TWO',
    'TWO_A' = 'TWO_A',
    'TWO_A_TWO_B' = 'TWO_A_TWO_B',
    'TWO_B' = 'TWO_B'
}

/**
 * 
 * @export
 * @interface SurveillanceMethodeAlternativeDto
 */
export interface SurveillanceMethodeAlternativeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveillanceMethodeAlternativeDto
     */
    descriptionList: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SurveillanceMethodeAlternativeDto
     */
    justificationList: Array<string>;
}
/**
 * 
 * @export
 * @interface SurveillanceTransfereDto
 */
export interface SurveillanceTransfereDto {
    /**
     * 
     * @type {ClassementFluxDto}
     * @memberof SurveillanceTransfereDto
     */
    classementFlux: ClassementFluxDto;
    /**
     * 
     * @type {string}
     * @memberof SurveillanceTransfereDto
     */
    idInstallation: string;
    /**
     * 
     * @type {string}
     * @memberof SurveillanceTransfereDto
     */
    nomExploitant: string;
    /**
     * 
     * @type {string}
     * @memberof SurveillanceTransfereDto
     */
    nomInstallation: string;
}
/**
 * 
 * @export
 * @interface TableauDechetsInertesDto
 */
export interface TableauDechetsInertesDto {
    /**
     * 
     * @type {MateriauxDechetsInertesARecyclerDto}
     * @memberof TableauDechetsInertesDto
     */
    aRecycler: MateriauxDechetsInertesARecyclerDto;
    /**
     * 
     * @type {MateriauxDechetsInertesARemblayerDto}
     * @memberof TableauDechetsInertesDto
     */
    aRemblayer: MateriauxDechetsInertesARemblayerDto;
    /**
     * 
     * @type {number}
     * @memberof TableauDechetsInertesDto
     */
    totalMateriauxEntrant: number;
}
/**
 * 
 * @export
 * @interface TableauQuartzEmpoussierageDto
 */
export interface TableauQuartzEmpoussierageDto {
    /**
     * 
     * @type {boolean}
     * @memberof TableauQuartzEmpoussierageDto
     */
    autreOrganisme: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TableauQuartzEmpoussierageDto
     */
    evaluationReveleRisqueNonFaible: boolean;
    /**
     * 
     * @type {number}
     * @memberof TableauQuartzEmpoussierageDto
     */
    nbGEHMoinsDe10PourcentVLEP: number | null;
    /**
     * 
     * @type {number}
     * @memberof TableauQuartzEmpoussierageDto
     */
    nbTotalPrelevement: number | null;
    /**
     * 
     * @type {string}
     * @memberof TableauQuartzEmpoussierageDto
     */
    organismeAccrediteID: string | null;
    /**
     * 
     * @type {string}
     * @memberof TableauQuartzEmpoussierageDto
     */
    precisionSiAutre: string | null;
}
/**
 * 
 * @export
 * @interface TableauTotalEmpoussierageDto
 */
export interface TableauTotalEmpoussierageDto {
    /**
     * 
     * @type {boolean}
     * @memberof TableauTotalEmpoussierageDto
     */
    autreOrganisme: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TableauTotalEmpoussierageDto
     */
    evaluationReveleRisqueNonFaible: boolean;
    /**
     * 
     * @type {number}
     * @memberof TableauTotalEmpoussierageDto
     */
    nbTotalPrelevement: number | null;
    /**
     * 
     * @type {number}
     * @memberof TableauTotalEmpoussierageDto
     */
    nbreGEHsupVLEP: number | null;
    /**
     * 
     * @type {string}
     * @memberof TableauTotalEmpoussierageDto
     */
    organismeAccrediteID: string | null;
    /**
     * 
     * @type {string}
     * @memberof TableauTotalEmpoussierageDto
     */
    precisionSiAutre: string | null;
    /**
     * 
     * @type {number}
     * @memberof TableauTotalEmpoussierageDto
     */
    valeurMaximale: number | null;
    /**
     * 
     * @type {number}
     * @memberof TableauTotalEmpoussierageDto
     */
    valeurMinimale: number | null;
}
/**
 * 
 * @export
 * @interface TransformationDto
 */
export interface TransformationDto {
    /**
     * 
     * @type {Array<ActiviteTransformationDto>}
     * @memberof TransformationDto
     */
    activiteTransformations: Array<ActiviteTransformationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof TransformationDto
     */
    doitRemplirTableau: boolean;
}
/**
 * 
 * @export
 * @interface TransportDto1822
 */
export interface TransportDto1822 {
    /**
     * 
     * @type {Array<ModaliteTransportDto>}
     * @memberof TransportDto1822
     */
    modaliteTransports: Array<ModaliteTransportDto>;
    /**
     * 
     * @type {Array<ProduitTransportDto1822>}
     * @memberof TransportDto1822
     */
    tableauDestinations: Array<ProduitTransportDto1822>;
}
/**
 * 
 * @export
 * @interface TransportDto23Now
 */
export interface TransportDto23Now {
    /**
     * 
     * @type {Array<ModaliteTransportDto>}
     * @memberof TransportDto23Now
     */
    modaliteTransports: Array<ModaliteTransportDto>;
    /**
     * 
     * @type {Array<ProduitTransportDto23Now>}
     * @memberof TransportDto23Now
     */
    tableauDestinations: Array<ProduitTransportDto23Now>;
}
/**
 * 
 * @export
 * @interface TypeActiviteDto1819
 */
export interface TypeActiviteDto1819 {
    /**
     * 
     * @type {string}
     * @memberof TypeActiviteDto1819
     */
    activitePrincipaleEPRTRID: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TypeActiviteDto1819
     */
    activitesEPRTRIDs: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    carriere: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    combustion20MW: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    elevage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    estConsommateurSolvant: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    estEPRTR: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    estSousQuotaCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    exporteBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    incinerationDechet: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    isdi: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    isdnd: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    recepDechet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    torchereBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto1819
     */
    valorisationBiogaz: boolean | null;
}
/**
 * 
 * @export
 * @interface TypeActiviteDto2020
 */
export interface TypeActiviteDto2020 {
    /**
     * 
     * @type {string}
     * @memberof TypeActiviteDto2020
     */
    activitePrincipaleEPRTRID: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TypeActiviteDto2020
     */
    activitesEPRTRIDs: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    carriere: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    combustion20MW: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    elevage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    estConsommateurSolvant: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    estEPRTR: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    estSousQuotaCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    excluArticle27: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    exporteBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    incinerationDechet: boolean | null;
    /**
     * 
     * @type {Array<QuotasInstallationDto20Now>}
     * @memberof TypeActiviteDto2020
     */
    installations: Array<QuotasInstallationDto20Now>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    isdd: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    isdi: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    isdnd: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    recepDechet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    torchereBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteDto2020
     */
    valorisationBiogaz: boolean | null;
}
/**
 * 
 * @export
 * @interface TypeActiviteDto2122
 */
export interface TypeActiviteDto2122 {
    /**
     * 
     * @type {TypeActiviteGlobalDto21Now}
     * @memberof TypeActiviteDto2122
     */
    global: TypeActiviteGlobalDto21Now;
    /**
     * 
     * @type {TypeActiviteQuotaDto2122}
     * @memberof TypeActiviteDto2122
     */
    quotas: TypeActiviteQuotaDto2122;
}
/**
 * 
 * @export
 * @interface TypeActiviteDto23Now
 */
export interface TypeActiviteDto23Now {
    /**
     * 
     * @type {TypeActiviteGlobalDto21Now}
     * @memberof TypeActiviteDto23Now
     */
    global: TypeActiviteGlobalDto21Now;
    /**
     * 
     * @type {TypeActiviteQuotaDto23Now}
     * @memberof TypeActiviteDto23Now
     */
    quotas: TypeActiviteQuotaDto23Now;
}
/**
 * 
 * @export
 * @interface TypeActiviteGlobalDto21Now
 */
export interface TypeActiviteGlobalDto21Now {
    /**
     * 
     * @type {string}
     * @memberof TypeActiviteGlobalDto21Now
     */
    activitePrincipaleEPRTRID: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TypeActiviteGlobalDto21Now
     */
    activitesEPRTRIDs: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    carriere: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    combustion20MW: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    elevage: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    estConsommateurSolvant: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    estEPRTR: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    exporteBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    incinerationDechet: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    isdd: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    isdi: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    isdnd: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    recepDechet: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    torchereBiogaz: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteGlobalDto21Now
     */
    valorisationBiogaz: boolean | null;
}
/**
 * 
 * @export
 * @interface TypeActiviteQuotaDto2122
 */
export interface TypeActiviteQuotaDto2122 {
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto2122
     */
    estSousQuotaCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto2122
     */
    excluArticle27: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto2122
     */
    excluArticle27Bis: boolean | null;
    /**
     * 
     * @type {Array<QuotasInstallationDto20Now>}
     * @memberof TypeActiviteQuotaDto2122
     */
    installations: Array<QuotasInstallationDto20Now>;
}
/**
 * 
 * @export
 * @interface TypeActiviteQuotaDto23Now
 */
export interface TypeActiviteQuotaDto23Now {
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto23Now
     */
    estSousQuotaCo2: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto23Now
     */
    excluArticle27: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto23Now
     */
    excluArticle27Bis: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TypeActiviteQuotaDto23Now
     */
    incinerateur: boolean | null;
    /**
     * 
     * @type {Array<QuotasInstallationDto20Now>}
     * @memberof TypeActiviteQuotaDto23Now
     */
    installations: Array<QuotasInstallationDto20Now>;
}
/**
 * 
 * @export
 * @interface UniteRefAlternatifDto
 */
export interface UniteRefAlternatifDto {
    /**
     * 
     * @type {string}
     * @memberof UniteRefAlternatifDto
     */
    uniteEfficaciteEnergetiqueDeclared: string | null;
    /**
     * 
     * @type {string}
     * @memberof UniteRefAlternatifDto
     */
    uniteNiveauActivite: string | null;
}
/**
 * 
 * @export
 * @interface UploadReferentialViolationsDto
 */
export interface UploadReferentialViolationsDto {
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UploadReferentialViolationsDto
     */
    violations: { [key: string]: Array<string>; };
}
/**
 * 
 * @export
 * @interface VibrationDto
 */
export interface VibrationDto {
    /**
     * 
     * @type {boolean}
     * @memberof VibrationDto
     */
    campagneExplosion: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof VibrationDto
     */
    campagneVibration: boolean | null;
    /**
     * 
     * @type {MesureBasiqueVibrationExplosifDto}
     * @memberof VibrationDto
     */
    mesuresExplosion: MesureBasiqueVibrationExplosifDto;
    /**
     * 
     * @type {MesureBasiqueVibrationMaterielDto}
     * @memberof VibrationDto
     */
    mesuresVibration: MesureBasiqueVibrationMaterielDto;
    /**
     * 
     * @type {boolean}
     * @memberof VibrationDto
     */
    soumisMesureVibrations: boolean;
}

/**
 * AuthControllerApi - fetch parameter creator
 * @export
 */
export const AuthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authUserInfoDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserInfoDtoUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/auth/userinfo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary auth
         * @param {string} [state] state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsingGET(state?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/auth`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authenticateUser
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(code: string, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling authenticateUserUsingPOST.');
            }
            const localVarPath = `/api/auth/authenticateUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authenticateUserWithSpecificRight
         * @param {string} code code
         * @param {AuthenticateWithRightRequestBodyDto} requireDroitDto requireDroitDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithSpecificRightUsingPOST(code: string, requireDroitDto: AuthenticateWithRightRequestBodyDto, options: any = {}): FetchArgs {
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling authenticateUserWithSpecificRightUsingPOST.');
            }
            // verify required parameter 'requireDroitDto' is not null or undefined
            if (requireDroitDto === null || requireDroitDto === undefined) {
                throw new RequiredError('requireDroitDto','Required parameter requireDroitDto was null or undefined when calling authenticateUserWithSpecificRightUsingPOST.');
            }
            const localVarPath = `/api/auth/authenticateUserWithSpecificRight`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthenticateWithRightRequestBodyDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(requireDroitDto || {}) : (requireDroitDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeUserActiveRight
         * @param {ChangeRightRequestBodyDto} changeRightDto changeRightDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserActiveRightUsingPOST(changeRightDto: ChangeRightRequestBodyDto, options: any = {}): FetchArgs {
            // verify required parameter 'changeRightDto' is not null or undefined
            if (changeRightDto === null || changeRightDto === undefined) {
                throw new RequiredError('changeRightDto','Required parameter changeRightDto was null or undefined when calling changeUserActiveRightUsingPOST.');
            }
            const localVarPath = `/api/auth/changeUserRight`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangeRightRequestBodyDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(changeRightDto || {}) : (changeRightDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refreshMonAIOTToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMonAIOTTokenUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/api/auth/refreshMonAIOTToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveListOfDroits
         * @param {RetrieveRightsRequestBodyDto} retrieveRightsDto retrieveRightsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveListOfDroitsUsingPOST(retrieveRightsDto: RetrieveRightsRequestBodyDto, options: any = {}): FetchArgs {
            // verify required parameter 'retrieveRightsDto' is not null or undefined
            if (retrieveRightsDto === null || retrieveRightsDto === undefined) {
                throw new RequiredError('retrieveRightsDto','Required parameter retrieveRightsDto was null or undefined when calling retrieveListOfDroitsUsingPOST.');
            }
            const localVarPath = `/api/auth/retrieveListOfDroits`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RetrieveRightsRequestBodyDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(retrieveRightsDto || {}) : (retrieveRightsDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authUserInfoDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserInfoDtoUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthUserInfoDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authUserInfoDtoUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary auth
         * @param {string} [state] state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsingGET(state?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RedirectView> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authUsingGET(state, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary authenticateUser
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(code: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthJwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateUserUsingPOST(code, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary authenticateUserWithSpecificRight
         * @param {string} code code
         * @param {AuthenticateWithRightRequestBodyDto} requireDroitDto requireDroitDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithSpecificRightUsingPOST(code: string, requireDroitDto: AuthenticateWithRightRequestBodyDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthJwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateUserWithSpecificRightUsingPOST(code, requireDroitDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changeUserActiveRight
         * @param {ChangeRightRequestBodyDto} changeRightDto changeRightDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserActiveRightUsingPOST(changeRightDto: ChangeRightRequestBodyDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthJwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).changeUserActiveRightUsingPOST(changeRightDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary refreshMonAIOTToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMonAIOTTokenUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthJwtDto> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).refreshMonAIOTTokenUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveListOfDroits
         * @param {RetrieveRightsRequestBodyDto} retrieveRightsDto retrieveRightsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveListOfDroitsUsingPOST(retrieveRightsDto: RetrieveRightsRequestBodyDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AuthDroitDto>> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).retrieveListOfDroitsUsingPOST(retrieveRightsDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary authUserInfoDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUserInfoDtoUsingGET(options?: any) {
            return AuthControllerApiFp(configuration).authUserInfoDtoUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary auth
         * @param {string} [state] state
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authUsingGET(state?: string, options?: any) {
            return AuthControllerApiFp(configuration).authUsingGET(state, options)(fetch, basePath);
        },
        /**
         * 
         * @summary authenticateUser
         * @param {string} code code
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(code: string, options?: any) {
            return AuthControllerApiFp(configuration).authenticateUserUsingPOST(code, options)(fetch, basePath);
        },
        /**
         * 
         * @summary authenticateUserWithSpecificRight
         * @param {string} code code
         * @param {AuthenticateWithRightRequestBodyDto} requireDroitDto requireDroitDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserWithSpecificRightUsingPOST(code: string, requireDroitDto: AuthenticateWithRightRequestBodyDto, options?: any) {
            return AuthControllerApiFp(configuration).authenticateUserWithSpecificRightUsingPOST(code, requireDroitDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeUserActiveRight
         * @param {ChangeRightRequestBodyDto} changeRightDto changeRightDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeUserActiveRightUsingPOST(changeRightDto: ChangeRightRequestBodyDto, options?: any) {
            return AuthControllerApiFp(configuration).changeUserActiveRightUsingPOST(changeRightDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary refreshMonAIOTToken
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshMonAIOTTokenUsingPOST(options?: any) {
            return AuthControllerApiFp(configuration).refreshMonAIOTTokenUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveListOfDroits
         * @param {RetrieveRightsRequestBodyDto} retrieveRightsDto retrieveRightsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveListOfDroitsUsingPOST(retrieveRightsDto: RetrieveRightsRequestBodyDto, options?: any) {
            return AuthControllerApiFp(configuration).retrieveListOfDroitsUsingPOST(retrieveRightsDto, options)(fetch, basePath);
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary authUserInfoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authUserInfoDtoUsingGET(options?: any) {
        return AuthControllerApiFp(this.configuration).authUserInfoDtoUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary auth
     * @param {string} [state] state
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authUsingGET(state?: string, options?: any) {
        return AuthControllerApiFp(this.configuration).authUsingGET(state, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary authenticateUser
     * @param {string} code code
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserUsingPOST(code: string, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserUsingPOST(code, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary authenticateUserWithSpecificRight
     * @param {string} code code
     * @param {AuthenticateWithRightRequestBodyDto} requireDroitDto requireDroitDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserWithSpecificRightUsingPOST(code: string, requireDroitDto: AuthenticateWithRightRequestBodyDto, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserWithSpecificRightUsingPOST(code, requireDroitDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeUserActiveRight
     * @param {ChangeRightRequestBodyDto} changeRightDto changeRightDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public changeUserActiveRightUsingPOST(changeRightDto: ChangeRightRequestBodyDto, options?: any) {
        return AuthControllerApiFp(this.configuration).changeUserActiveRightUsingPOST(changeRightDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary refreshMonAIOTToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public refreshMonAIOTTokenUsingPOST(options?: any) {
        return AuthControllerApiFp(this.configuration).refreshMonAIOTTokenUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveListOfDroits
     * @param {RetrieveRightsRequestBodyDto} retrieveRightsDto retrieveRightsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public retrieveListOfDroitsUsingPOST(retrieveRightsDto: RetrieveRightsRequestBodyDto, options?: any) {
        return AuthControllerApiFp(this.configuration).retrieveListOfDroitsUsingPOST(retrieveRightsDto, options)(this.fetch, this.basePath);
    }

}

/**
 * CampaignControllerApi - fetch parameter creator
 * @export
 */
export const CampaignControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCampaign
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignUsingPOST(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endAllocations' is not null or undefined
            if (endAllocations === null || endAllocations === undefined) {
                throw new RequiredError('endAllocations','Required parameter endAllocations was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endAllocationsDeclarants' is not null or undefined
            if (endAllocationsDeclarants === null || endAllocationsDeclarants === undefined) {
                throw new RequiredError('endAllocationsDeclarants','Required parameter endAllocationsDeclarants was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endEmissions' is not null or undefined
            if (endEmissions === null || endEmissions === undefined) {
                throw new RequiredError('endEmissions','Required parameter endEmissions was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endEmissionsDeclarants' is not null or undefined
            if (endEmissionsDeclarants === null || endEmissionsDeclarants === undefined) {
                throw new RequiredError('endEmissionsDeclarants','Required parameter endEmissionsDeclarants was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endGlobal' is not null or undefined
            if (endGlobal === null || endGlobal === undefined) {
                throw new RequiredError('endGlobal','Required parameter endGlobal was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'endGlobalDeclarants' is not null or undefined
            if (endGlobalDeclarants === null || endGlobalDeclarants === undefined) {
                throw new RequiredError('endGlobalDeclarants','Required parameter endGlobalDeclarants was null or undefined when calling createCampaignUsingPOST.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling createCampaignUsingPOST.');
            }
            const localVarPath = `/campagne/annee/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (endAllocations !== undefined) {
                localVarQueryParameter['endAllocations'] = endAllocations;
            }

            if (endAllocationsDeclarants !== undefined) {
                localVarQueryParameter['endAllocationsDeclarants'] = endAllocationsDeclarants;
            }

            if (endEmissions !== undefined) {
                localVarQueryParameter['endEmissions'] = endEmissions;
            }

            if (endEmissionsDeclarants !== undefined) {
                localVarQueryParameter['endEmissionsDeclarants'] = endEmissionsDeclarants;
            }

            if (endGlobal !== undefined) {
                localVarQueryParameter['endGlobal'] = endGlobal;
            }

            if (endGlobalDeclarants !== undefined) {
                localVarQueryParameter['endGlobalDeclarants'] = endGlobalDeclarants;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findCampaignById
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCampaignByIdUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling findCampaignByIdUsingGET.');
            }
            const localVarPath = `/campagne/annee/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getActiveOrLastCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveOrLastCampaignUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/campagne/current`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAdministrableCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdministrableCampaignUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/campagne/administrable`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNextCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCampaignUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/campagne/next`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setCampaignDates
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignDatesUsingPUT(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endAllocations' is not null or undefined
            if (endAllocations === null || endAllocations === undefined) {
                throw new RequiredError('endAllocations','Required parameter endAllocations was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endAllocationsDeclarants' is not null or undefined
            if (endAllocationsDeclarants === null || endAllocationsDeclarants === undefined) {
                throw new RequiredError('endAllocationsDeclarants','Required parameter endAllocationsDeclarants was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endEmissions' is not null or undefined
            if (endEmissions === null || endEmissions === undefined) {
                throw new RequiredError('endEmissions','Required parameter endEmissions was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endEmissionsDeclarants' is not null or undefined
            if (endEmissionsDeclarants === null || endEmissionsDeclarants === undefined) {
                throw new RequiredError('endEmissionsDeclarants','Required parameter endEmissionsDeclarants was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endGlobal' is not null or undefined
            if (endGlobal === null || endGlobal === undefined) {
                throw new RequiredError('endGlobal','Required parameter endGlobal was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'endGlobalDeclarants' is not null or undefined
            if (endGlobalDeclarants === null || endGlobalDeclarants === undefined) {
                throw new RequiredError('endGlobalDeclarants','Required parameter endGlobalDeclarants was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            // verify required parameter 'start' is not null or undefined
            if (start === null || start === undefined) {
                throw new RequiredError('start','Required parameter start was null or undefined when calling setCampaignDatesUsingPUT.');
            }
            const localVarPath = `/campagne/annee/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (endAllocations !== undefined) {
                localVarQueryParameter['endAllocations'] = endAllocations;
            }

            if (endAllocationsDeclarants !== undefined) {
                localVarQueryParameter['endAllocationsDeclarants'] = endAllocationsDeclarants;
            }

            if (endEmissions !== undefined) {
                localVarQueryParameter['endEmissions'] = endEmissions;
            }

            if (endEmissionsDeclarants !== undefined) {
                localVarQueryParameter['endEmissionsDeclarants'] = endEmissionsDeclarants;
            }

            if (endGlobal !== undefined) {
                localVarQueryParameter['endGlobal'] = endGlobal;
            }

            if (endGlobalDeclarants !== undefined) {
                localVarQueryParameter['endGlobalDeclarants'] = endGlobalDeclarants;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setCampaignToReady
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignToReadyUsingPOST(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setCampaignToReadyUsingPOST.');
            }
            const localVarPath = `/campagne/ready/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignControllerApi - functional programming interface
 * @export
 */
export const CampaignControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCampaign
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignUsingPOST(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).createCampaignUsingPOST(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findCampaignById
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCampaignByIdUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).findCampaignByIdUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getActiveOrLastCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveOrLastCampaignUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).getActiveOrLastCampaignUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAdministrableCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdministrableCampaignUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).getAdministrableCampaignUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNextCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCampaignUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).getNextCampaignUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setCampaignDates
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignDatesUsingPUT(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).setCampaignDatesUsingPUT(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setCampaignToReady
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignToReadyUsingPOST(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CampaignDto> {
            const localVarFetchArgs = CampaignControllerApiFetchParamCreator(configuration).setCampaignToReadyUsingPOST(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CampaignControllerApi - factory interface
 * @export
 */
export const CampaignControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createCampaign
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaignUsingPOST(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any) {
            return CampaignControllerApiFp(configuration).createCampaignUsingPOST(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findCampaignById
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findCampaignByIdUsingGET(annee: number, options?: any) {
            return CampaignControllerApiFp(configuration).findCampaignByIdUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getActiveOrLastCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveOrLastCampaignUsingGET(options?: any) {
            return CampaignControllerApiFp(configuration).getActiveOrLastCampaignUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAdministrableCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdministrableCampaignUsingGET(options?: any) {
            return CampaignControllerApiFp(configuration).getAdministrableCampaignUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNextCampaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextCampaignUsingGET(options?: any) {
            return CampaignControllerApiFp(configuration).getNextCampaignUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary setCampaignDates
         * @param {number} annee annee
         * @param {string} endAllocations endAllocations
         * @param {string} endAllocationsDeclarants endAllocationsDeclarants
         * @param {string} endEmissions endEmissions
         * @param {string} endEmissionsDeclarants endEmissionsDeclarants
         * @param {string} endGlobal endGlobal
         * @param {string} endGlobalDeclarants endGlobalDeclarants
         * @param {string} start start
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignDatesUsingPUT(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any) {
            return CampaignControllerApiFp(configuration).setCampaignDatesUsingPUT(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setCampaignToReady
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setCampaignToReadyUsingPOST(annee: number, options?: any) {
            return CampaignControllerApiFp(configuration).setCampaignToReadyUsingPOST(annee, options)(fetch, basePath);
        },
    };
};

/**
 * CampaignControllerApi - object-oriented interface
 * @export
 * @class CampaignControllerApi
 * @extends {BaseAPI}
 */
export class CampaignControllerApi extends BaseAPI {
    /**
     * 
     * @summary createCampaign
     * @param {number} annee annee
     * @param {string} endAllocations endAllocations
     * @param {string} endAllocationsDeclarants endAllocationsDeclarants
     * @param {string} endEmissions endEmissions
     * @param {string} endEmissionsDeclarants endEmissionsDeclarants
     * @param {string} endGlobal endGlobal
     * @param {string} endGlobalDeclarants endGlobalDeclarants
     * @param {string} start start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public createCampaignUsingPOST(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any) {
        return CampaignControllerApiFp(this.configuration).createCampaignUsingPOST(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findCampaignById
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public findCampaignByIdUsingGET(annee: number, options?: any) {
        return CampaignControllerApiFp(this.configuration).findCampaignByIdUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getActiveOrLastCampaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public getActiveOrLastCampaignUsingGET(options?: any) {
        return CampaignControllerApiFp(this.configuration).getActiveOrLastCampaignUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAdministrableCampaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public getAdministrableCampaignUsingGET(options?: any) {
        return CampaignControllerApiFp(this.configuration).getAdministrableCampaignUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNextCampaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public getNextCampaignUsingGET(options?: any) {
        return CampaignControllerApiFp(this.configuration).getNextCampaignUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setCampaignDates
     * @param {number} annee annee
     * @param {string} endAllocations endAllocations
     * @param {string} endAllocationsDeclarants endAllocationsDeclarants
     * @param {string} endEmissions endEmissions
     * @param {string} endEmissionsDeclarants endEmissionsDeclarants
     * @param {string} endGlobal endGlobal
     * @param {string} endGlobalDeclarants endGlobalDeclarants
     * @param {string} start start
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public setCampaignDatesUsingPUT(annee: number, endAllocations: string, endAllocationsDeclarants: string, endEmissions: string, endEmissionsDeclarants: string, endGlobal: string, endGlobalDeclarants: string, start: string, options?: any) {
        return CampaignControllerApiFp(this.configuration).setCampaignDatesUsingPUT(annee, endAllocations, endAllocationsDeclarants, endEmissions, endEmissionsDeclarants, endGlobal, endGlobalDeclarants, start, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setCampaignToReady
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignControllerApi
     */
    public setCampaignToReadyUsingPOST(annee: number, options?: any) {
        return CampaignControllerApiFp(this.configuration).setCampaignToReadyUsingPOST(annee, options)(this.fetch, this.basePath);
    }

}

/**
 * DeclarationControllerApi - fetch parameter creator
 * @export
 */
export const DeclarationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addDeclarationToGunReimport
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeclarationToGunReimportUsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling addDeclarationToGunReimportUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling addDeclarationToGunReimportUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/informationsGenerales/gun`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary approveDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration1819UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling approveDeclaration1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling approveDeclaration1819UsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling approveDeclaration1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/approvals/1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary approveDeclaration20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling approveDeclaration20NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling approveDeclaration20NowUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling approveDeclaration20NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/approvals/20Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary comments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventCommentsDto} eventCommentsDto eventCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsUsingPOST(annee: number, etablissement: string, eventCommentsDto: EventCommentsDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling commentsUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling commentsUsingPOST.');
            }
            // verify required parameter 'eventCommentsDto' is not null or undefined
            if (eventCommentsDto === null || eventCommentsDto === undefined) {
                throw new RequiredError('eventCommentsDto','Required parameter eventCommentsDto was null or undefined when calling commentsUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/comments`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventCommentsDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(eventCommentsDto || {}) : (eventCommentsDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contactGlobalInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGlobalInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling contactGlobalInspectorUsingPOST.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling contactGlobalInspectorUsingPOST.');
            }
            // verify required parameter 'emails' is not null or undefined
            if (emails === null || emails === undefined) {
                throw new RequiredError('emails','Required parameter emails was null or undefined when calling contactGlobalInspectorUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling contactGlobalInspectorUsingPOST.');
            }
            // verify required parameter 'subject' is not null or undefined
            if (subject === null || subject === undefined) {
                throw new RequiredError('subject','Required parameter subject was null or undefined when calling contactGlobalInspectorUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/contact/global`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (emails) {
                localVarQueryParameter['emails'] = emails;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contactQuotasInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactQuotasInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling contactQuotasInspectorUsingPOST.');
            }
            // verify required parameter 'content' is not null or undefined
            if (content === null || content === undefined) {
                throw new RequiredError('content','Required parameter content was null or undefined when calling contactQuotasInspectorUsingPOST.');
            }
            // verify required parameter 'emails' is not null or undefined
            if (emails === null || emails === undefined) {
                throw new RequiredError('emails','Required parameter emails was null or undefined when calling contactQuotasInspectorUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling contactQuotasInspectorUsingPOST.');
            }
            // verify required parameter 'subject' is not null or undefined
            if (subject === null || subject === undefined) {
                throw new RequiredError('subject','Required parameter subject was null or undefined when calling contactQuotasInspectorUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/contact/quotas`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }

            if (emails) {
                localVarQueryParameter['emails'] = emails;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteComments
         * @param {number} annee annee
         * @param {DeleteCommentDto} deleteCommentDto deleteCommentDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentsUsingDELETE(annee: number, deleteCommentDto: DeleteCommentDto, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteCommentsUsingDELETE.');
            }
            // verify required parameter 'deleteCommentDto' is not null or undefined
            if (deleteCommentDto === null || deleteCommentDto === undefined) {
                throw new RequiredError('deleteCommentDto','Required parameter deleteCommentDto was null or undefined when calling deleteCommentsUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteCommentsUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/delete-comments`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeleteCommentDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deleteCommentDto || {}) : (deleteCommentDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionEmissionsAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionEmissionsAsPdfUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling exportAccuseTransmissionEmissionsAsPdfUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling exportAccuseTransmissionEmissionsAsPdfUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/export/accuseTransmissionEmissions/pdf`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionNiveauxActiviteAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/export/accuseTransmissionNiveauxActivite/pdf`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionRegistreGerepAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling exportAccuseTransmissionRegistreGerepAsPdfUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling exportAccuseTransmissionRegistreGerepAsPdfUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/export/accuseTransmissionRegistreGerep/pdf`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDeclarationExcel
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationExcelUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling exportDeclarationExcelUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling exportDeclarationExcelUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/export/declaration/excel`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDeclarationPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationPdfUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling exportDeclarationPdfUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling exportDeclarationPdfUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/export/declaration/pdf`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findDeclarationById
         * @param {number} annee annee
         * @param {BodyFindDeclarationByIdDto} bodyFindDeclarationByIdDto bodyFindDeclarationByIdDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeclarationByIdUsingPOST(annee: number, bodyFindDeclarationByIdDto: BodyFindDeclarationByIdDto, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling findDeclarationByIdUsingPOST.');
            }
            // verify required parameter 'bodyFindDeclarationByIdDto' is not null or undefined
            if (bodyFindDeclarationByIdDto === null || bodyFindDeclarationByIdDto === undefined) {
                throw new RequiredError('bodyFindDeclarationByIdDto','Required parameter bodyFindDeclarationByIdDto was null or undefined when calling findDeclarationByIdUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling findDeclarationByIdUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"BodyFindDeclarationByIdDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(bodyFindDeclarationByIdDto || {}) : (bodyFindDeclarationByIdDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary patchDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {JsonPatchDto} patchWrapper patchWrapper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeclarationUsingPOST(annee: number, etablissement: string, patchWrapper: JsonPatchDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling patchDeclarationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling patchDeclarationUsingPOST.');
            }
            // verify required parameter 'patchWrapper' is not null or undefined
            if (patchWrapper === null || patchWrapper === undefined) {
                throw new RequiredError('patchWrapper','Required parameter patchWrapper was null or undefined when calling patchDeclarationUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/patchs`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"JsonPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(patchWrapper || {}) : (patchWrapper || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary recallDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recallDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling recallDeclarationUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling recallDeclarationUsingDELETE.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling recallDeclarationUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/submissions`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary relieveDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relieveDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling relieveDeclarationUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling relieveDeclarationUsingDELETE.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling relieveDeclarationUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/takeovers`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration1819UsingDELETE(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling resetDeclaration1819UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling resetDeclaration1819UsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/1819/reset`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration2020UsingDELETE(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling resetDeclaration2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling resetDeclaration2020UsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/2020/reset`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetGlobalDeclaration21NowUsingDELETE(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling resetGlobalDeclaration21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling resetGlobalDeclaration21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/21Now/resetGlobal`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetQuotaDeclaration21NowUsingDELETE(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling resetQuotaDeclaration21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling resetQuotaDeclaration21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/21Now/resetQuota`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reviewDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventMessageDto} msg msg
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewDeclarationUsingPOST(annee: number, etablissement: string, msg: EventMessageDto, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling reviewDeclarationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling reviewDeclarationUsingPOST.');
            }
            // verify required parameter 'msg' is not null or undefined
            if (msg === null || msg === undefined) {
                throw new RequiredError('msg','Required parameter msg was null or undefined when calling reviewDeclarationUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling reviewDeclarationUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/reviews`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventMessageDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(msg || {}) : (msg || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTypeActivite1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto1819} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite1819UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto1819, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setTypeActivite1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setTypeActivite1819UsingPOST.');
            }
            // verify required parameter 'typeActiviteDto' is not null or undefined
            if (typeActiviteDto === null || typeActiviteDto === undefined) {
                throw new RequiredError('typeActiviteDto','Required parameter typeActiviteDto was null or undefined when calling setTypeActivite1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/setActivite/1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeActiviteDto1819" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeActiviteDto || {}) : (typeActiviteDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTypeActivite2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto2020} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite2020UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto2020, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setTypeActivite2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setTypeActivite2020UsingPOST.');
            }
            // verify required parameter 'typeActiviteDto' is not null or undefined
            if (typeActiviteDto === null || typeActiviteDto === undefined) {
                throw new RequiredError('typeActiviteDto','Required parameter typeActiviteDto was null or undefined when calling setTypeActivite2020UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/setActivite/2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeActiviteDto2020" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeActiviteDto || {}) : (typeActiviteDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTypeActiviteGlobal21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteGlobalDto21Now} typeActiviteGlobalDto typeActiviteGlobalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteGlobal21NowUsingPOST(annee: number, etablissement: string, typeActiviteGlobalDto: TypeActiviteGlobalDto21Now, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setTypeActiviteGlobal21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setTypeActiviteGlobal21NowUsingPOST.');
            }
            // verify required parameter 'typeActiviteGlobalDto' is not null or undefined
            if (typeActiviteGlobalDto === null || typeActiviteGlobalDto === undefined) {
                throw new RequiredError('typeActiviteGlobalDto','Required parameter typeActiviteGlobalDto was null or undefined when calling setTypeActiviteGlobal21NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/setActiviteGlobal/21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeActiviteGlobalDto21Now" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeActiviteGlobalDto || {}) : (typeActiviteGlobalDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTypeActiviteQuota2122
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto2122} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota2122UsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto2122, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setTypeActiviteQuota2122UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setTypeActiviteQuota2122UsingPOST.');
            }
            // verify required parameter 'typeActiviteQuotaDto' is not null or undefined
            if (typeActiviteQuotaDto === null || typeActiviteQuotaDto === undefined) {
                throw new RequiredError('typeActiviteQuotaDto','Required parameter typeActiviteQuotaDto was null or undefined when calling setTypeActiviteQuota2122UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/setActiviteQuota/2122`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeActiviteQuotaDto2122" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeActiviteQuotaDto || {}) : (typeActiviteQuotaDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTypeActiviteQuota23Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto23Now} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota23NowUsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto23Now, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setTypeActiviteQuota23NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setTypeActiviteQuota23NowUsingPOST.');
            }
            // verify required parameter 'typeActiviteQuotaDto' is not null or undefined
            if (typeActiviteQuotaDto === null || typeActiviteQuotaDto === undefined) {
                throw new RequiredError('typeActiviteQuotaDto','Required parameter typeActiviteQuotaDto was null or undefined when calling setTypeActiviteQuota23NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/setActiviteQuota/23Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TypeActiviteQuotaDto23Now" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(typeActiviteQuotaDto || {}) : (typeActiviteQuotaDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationDto1819} quotasBlocVerificationDto1819 quotasBlocVerificationDto1819
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerification1819UsingPOST(annee: number, etablissement: string, quotasBlocVerificationDto1819: QuotasBlocVerificationDto1819, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setVerification1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setVerification1819UsingPOST.');
            }
            // verify required parameter 'quotasBlocVerificationDto1819' is not null or undefined
            if (quotasBlocVerificationDto1819 === null || quotasBlocVerificationDto1819 === undefined) {
                throw new RequiredError('quotasBlocVerificationDto1819','Required parameter quotasBlocVerificationDto1819 was null or undefined when calling setVerification1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/verifications/1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"QuotasBlocVerificationDto1819" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(quotasBlocVerificationDto1819 || {}) : (quotasBlocVerificationDto1819 || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setVerificationAllocations20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasAllocationsBlocVerificationDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationAllocations20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasAllocationsBlocVerificationDto20Now, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setVerificationAllocations20NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setVerificationAllocations20NowUsingPOST.');
            }
            // verify required parameter 'quotasBlocVerification' is not null or undefined
            if (quotasBlocVerification === null || quotasBlocVerification === undefined) {
                throw new RequiredError('quotasBlocVerification','Required parameter quotasBlocVerification was null or undefined when calling setVerificationAllocations20NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/verifications/20Now/allocations`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"QuotasAllocationsBlocVerificationDto20Now" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(quotasBlocVerification || {}) : (quotasBlocVerification || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setVerificationEmissions20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationEmissionsDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationEmissions20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasBlocVerificationEmissionsDto20Now, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling setVerificationEmissions20NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling setVerificationEmissions20NowUsingPOST.');
            }
            // verify required parameter 'quotasBlocVerification' is not null or undefined
            if (quotasBlocVerification === null || quotasBlocVerification === undefined) {
                throw new RequiredError('quotasBlocVerification','Required parameter quotasBlocVerification was null or undefined when calling setVerificationEmissions20NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/verifications/20Now/emissions`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"QuotasBlocVerificationEmissionsDto20Now" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(quotasBlocVerification || {}) : (quotasBlocVerification || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary startDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration1819UsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling startDeclaration1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling startDeclaration1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/1819/start`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary startDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration2020UsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling startDeclaration2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling startDeclaration2020UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/2020/start`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary startGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startGlobalDeclaration21NowUsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling startGlobalDeclaration21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling startGlobalDeclaration21NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/21Now/startGlobal`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary startQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startQuotaDeclaration21NowUsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling startQuotaDeclaration21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling startQuotaDeclaration21NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/21Now/startQuota`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submitDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling submitDeclarationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling submitDeclarationUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling submitDeclarationUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/submissions`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas1819UsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling submitSimplifiedQuotas1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling submitSimplifiedQuotas1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/simplifiedSubmissions/1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas2020UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling submitSimplifiedQuotas2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling submitSimplifiedQuotas2020UsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling submitSimplifiedQuotas2020UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/simplifiedSubmissions/2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas21NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling submitSimplifiedQuotas21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling submitSimplifiedQuotas21NowUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling submitSimplifiedQuotas21NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/simplifiedSubmissions/21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary submitVerif
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerifUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling submitVerifUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling submitVerifUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling submitVerifUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/verifications/submissions`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary takeoverDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeoverDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling takeoverDeclarationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling takeoverDeclarationUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling takeoverDeclarationUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/takeovers`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInseeInfo
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} siret siret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInseeInfoUsingGET(annee: number, etablissement: string, siret: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling updateInseeInfoUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling updateInseeInfoUsingGET.');
            }
            // verify required parameter 'siret' is not null or undefined
            if (siret === null || siret === undefined) {
                throw new RequiredError('siret','Required parameter siret was null or undefined when calling updateInseeInfoUsingGET.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/informationsGenerales/insee`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (siret !== undefined) {
                localVarQueryParameter['siret'] = siret;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary userInvalidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationInvalidationPathDto} declarationInvalidationPathDto declarationInvalidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvalidateDeclarationUsingDELETE(annee: number, declarationInvalidationPathDto: DeclarationInvalidationPathDto, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling userInvalidateDeclarationUsingDELETE.');
            }
            // verify required parameter 'declarationInvalidationPathDto' is not null or undefined
            if (declarationInvalidationPathDto === null || declarationInvalidationPathDto === undefined) {
                throw new RequiredError('declarationInvalidationPathDto','Required parameter declarationInvalidationPathDto was null or undefined when calling userInvalidateDeclarationUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling userInvalidateDeclarationUsingDELETE.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/validations`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeclarationInvalidationPathDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(declarationInvalidationPathDto || {}) : (declarationInvalidationPathDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary userValidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationValidationPathDto} declarationValidationPathDto declarationValidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidateDeclarationUsingPOST(annee: number, declarationValidationPathDto: DeclarationValidationPathDto, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling userValidateDeclarationUsingPOST.');
            }
            // verify required parameter 'declarationValidationPathDto' is not null or undefined
            if (declarationValidationPathDto === null || declarationValidationPathDto === undefined) {
                throw new RequiredError('declarationValidationPathDto','Required parameter declarationValidationPathDto was null or undefined when calling userValidateDeclarationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling userValidateDeclarationUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/validations`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeclarationValidationPathDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(declarationValidationPathDto || {}) : (declarationValidationPathDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary yieldQuota1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota1819UsingPOST(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling yieldQuota1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling yieldQuota1819UsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/submissions/1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary yieldQuota20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling yieldQuota20NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling yieldQuota20NowUsingPOST.');
            }
            // verify required parameter 'targets' is not null or undefined
            if (targets === null || targets === undefined) {
                throw new RequiredError('targets','Required parameter targets was null or undefined when calling yieldQuota20NowUsingPOST.');
            }
            const localVarPath = `/declarations/{annee}/{etablissement}/quota/submissions/20Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (targets) {
                localVarQueryParameter['targets'] = targets;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationControllerApi - functional programming interface
 * @export
 */
export const DeclarationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addDeclarationToGunReimport
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeclarationToGunReimportUsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).addDeclarationToGunReimportUsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary approveDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration1819UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).approveDeclaration1819UsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary approveDeclaration20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).approveDeclaration20NowUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary comments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventCommentsDto} eventCommentsDto eventCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsUsingPOST(annee: number, etablissement: string, eventCommentsDto: EventCommentsDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).commentsUsingPOST(annee, etablissement, eventCommentsDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary contactGlobalInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGlobalInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).contactGlobalInspectorUsingPOST(annee, content, emails, etablissement, subject, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary contactQuotasInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactQuotasInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).contactQuotasInspectorUsingPOST(annee, content, emails, etablissement, subject, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteComments
         * @param {number} annee annee
         * @param {DeleteCommentDto} deleteCommentDto deleteCommentDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentsUsingDELETE(annee: number, deleteCommentDto: DeleteCommentDto, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).deleteCommentsUsingDELETE(annee, deleteCommentDto, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionEmissionsAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionEmissionsAsPdfUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).exportAccuseTransmissionEmissionsAsPdfUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionNiveauxActiviteAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportAccuseTransmissionRegistreGerepAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDeclarationExcel
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationExcelUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).exportDeclarationExcelUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDeclarationPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationPdfUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).exportDeclarationPdfUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findDeclarationById
         * @param {number} annee annee
         * @param {BodyFindDeclarationByIdDto} bodyFindDeclarationByIdDto bodyFindDeclarationByIdDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeclarationByIdUsingPOST(annee: number, bodyFindDeclarationByIdDto: BodyFindDeclarationByIdDto, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).findDeclarationByIdUsingPOST(annee, bodyFindDeclarationByIdDto, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary patchDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {JsonPatchDto} patchWrapper patchWrapper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeclarationUsingPOST(annee: number, etablissement: string, patchWrapper: JsonPatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).patchDeclarationUsingPOST(annee, etablissement, patchWrapper, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary recallDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recallDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).recallDeclarationUsingDELETE(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary relieveDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relieveDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).relieveDeclarationUsingDELETE(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration1819UsingDELETE(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).resetDeclaration1819UsingDELETE(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration2020UsingDELETE(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).resetDeclaration2020UsingDELETE(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetGlobalDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).resetGlobalDeclaration21NowUsingDELETE(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetQuotaDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).resetQuotaDeclaration21NowUsingDELETE(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary reviewDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventMessageDto} msg msg
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewDeclarationUsingPOST(annee: number, etablissement: string, msg: EventMessageDto, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).reviewDeclarationUsingPOST(annee, etablissement, msg, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTypeActivite1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto1819} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite1819UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto1819, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setTypeActivite1819UsingPOST(annee, etablissement, typeActiviteDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTypeActivite2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto2020} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite2020UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto2020, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setTypeActivite2020UsingPOST(annee, etablissement, typeActiviteDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTypeActiviteGlobal21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteGlobalDto21Now} typeActiviteGlobalDto typeActiviteGlobalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteGlobal21NowUsingPOST(annee: number, etablissement: string, typeActiviteGlobalDto: TypeActiviteGlobalDto21Now, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setTypeActiviteGlobal21NowUsingPOST(annee, etablissement, typeActiviteGlobalDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTypeActiviteQuota2122
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto2122} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota2122UsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto2122, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setTypeActiviteQuota2122UsingPOST(annee, etablissement, typeActiviteQuotaDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTypeActiviteQuota23Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto23Now} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota23NowUsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto23Now, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto23Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setTypeActiviteQuota23NowUsingPOST(annee, etablissement, typeActiviteQuotaDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationDto1819} quotasBlocVerificationDto1819 quotasBlocVerificationDto1819
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerification1819UsingPOST(annee: number, etablissement: string, quotasBlocVerificationDto1819: QuotasBlocVerificationDto1819, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setVerification1819UsingPOST(annee, etablissement, quotasBlocVerificationDto1819, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setVerificationAllocations20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasAllocationsBlocVerificationDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationAllocations20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasAllocationsBlocVerificationDto20Now, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setVerificationAllocations20NowUsingPOST(annee, etablissement, quotasBlocVerification, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setVerificationEmissions20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationEmissionsDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationEmissions20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasBlocVerificationEmissionsDto20Now, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).setVerificationEmissions20NowUsingPOST(annee, etablissement, quotasBlocVerification, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary startDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration1819UsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).startDeclaration1819UsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary startDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration2020UsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).startDeclaration2020UsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary startGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startGlobalDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).startGlobalDeclaration21NowUsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary startQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startQuotaDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).startQuotaDeclaration21NowUsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary submitDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).submitDeclarationUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas1819UsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).submitSimplifiedQuotas1819UsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas2020UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).submitSimplifiedQuotas2020UsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary submitSimplifiedQuotas21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas21NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).submitSimplifiedQuotas21NowUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary submitVerif
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerifUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).submitVerifUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary takeoverDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeoverDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).takeoverDeclarationUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateInseeInfo
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} siret siret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInseeInfoUsingGET(annee: number, etablissement: string, siret: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto23Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).updateInseeInfoUsingGET(annee, etablissement, siret, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary userInvalidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationInvalidationPathDto} declarationInvalidationPathDto declarationInvalidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvalidateDeclarationUsingDELETE(annee: number, declarationInvalidationPathDto: DeclarationInvalidationPathDto, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).userInvalidateDeclarationUsingDELETE(annee, declarationInvalidationPathDto, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary userValidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationValidationPathDto} declarationValidationPathDto declarationValidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidateDeclarationUsingPOST(annee: number, declarationValidationPathDto: DeclarationValidationPathDto, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).userValidateDeclarationUsingPOST(annee, declarationValidationPathDto, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary yieldQuota1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota1819UsingPOST(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).yieldQuota1819UsingPOST(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary yieldQuota20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationControllerApiFetchParamCreator(configuration).yieldQuota20NowUsingPOST(annee, etablissement, targets, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DeclarationControllerApi - factory interface
 * @export
 */
export const DeclarationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addDeclarationToGunReimport
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addDeclarationToGunReimportUsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).addDeclarationToGunReimportUsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary approveDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration1819UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).approveDeclaration1819UsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary approveDeclaration20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeclaration20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).approveDeclaration20NowUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary comments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventCommentsDto} eventCommentsDto eventCommentsDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commentsUsingPOST(annee: number, etablissement: string, eventCommentsDto: EventCommentsDto, options?: any) {
            return DeclarationControllerApiFp(configuration).commentsUsingPOST(annee, etablissement, eventCommentsDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary contactGlobalInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactGlobalInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any) {
            return DeclarationControllerApiFp(configuration).contactGlobalInspectorUsingPOST(annee, content, emails, etablissement, subject, options)(fetch, basePath);
        },
        /**
         * 
         * @summary contactQuotasInspector
         * @param {number} annee annee
         * @param {string} content content
         * @param {Array<string>} emails emails
         * @param {string} etablissement etablissement
         * @param {string} subject subject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactQuotasInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any) {
            return DeclarationControllerApiFp(configuration).contactQuotasInspectorUsingPOST(annee, content, emails, etablissement, subject, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteComments
         * @param {number} annee annee
         * @param {DeleteCommentDto} deleteCommentDto deleteCommentDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentsUsingDELETE(annee: number, deleteCommentDto: DeleteCommentDto, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).deleteCommentsUsingDELETE(annee, deleteCommentDto, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportAccuseTransmissionEmissionsAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionEmissionsAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).exportAccuseTransmissionEmissionsAsPdfUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportAccuseTransmissionNiveauxActiviteAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportAccuseTransmissionRegistreGerepAsPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDeclarationExcel
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationExcelUsingGET(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).exportDeclarationExcelUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDeclarationPdf
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationPdfUsingGET(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).exportDeclarationPdfUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findDeclarationById
         * @param {number} annee annee
         * @param {BodyFindDeclarationByIdDto} bodyFindDeclarationByIdDto bodyFindDeclarationByIdDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findDeclarationByIdUsingPOST(annee: number, bodyFindDeclarationByIdDto: BodyFindDeclarationByIdDto, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).findDeclarationByIdUsingPOST(annee, bodyFindDeclarationByIdDto, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary patchDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {JsonPatchDto} patchWrapper patchWrapper
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDeclarationUsingPOST(annee: number, etablissement: string, patchWrapper: JsonPatchDto, options?: any) {
            return DeclarationControllerApiFp(configuration).patchDeclarationUsingPOST(annee, etablissement, patchWrapper, options)(fetch, basePath);
        },
        /**
         * 
         * @summary recallDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recallDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).recallDeclarationUsingDELETE(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary relieveDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        relieveDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).relieveDeclarationUsingDELETE(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration1819UsingDELETE(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).resetDeclaration1819UsingDELETE(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetDeclaration2020UsingDELETE(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).resetDeclaration2020UsingDELETE(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetGlobalDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).resetGlobalDeclaration21NowUsingDELETE(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetQuotaDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).resetQuotaDeclaration21NowUsingDELETE(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary reviewDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {EventMessageDto} msg msg
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewDeclarationUsingPOST(annee: number, etablissement: string, msg: EventMessageDto, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).reviewDeclarationUsingPOST(annee, etablissement, msg, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTypeActivite1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto1819} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite1819UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto1819, options?: any) {
            return DeclarationControllerApiFp(configuration).setTypeActivite1819UsingPOST(annee, etablissement, typeActiviteDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTypeActivite2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteDto2020} typeActiviteDto typeActiviteDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActivite2020UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto2020, options?: any) {
            return DeclarationControllerApiFp(configuration).setTypeActivite2020UsingPOST(annee, etablissement, typeActiviteDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTypeActiviteGlobal21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteGlobalDto21Now} typeActiviteGlobalDto typeActiviteGlobalDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteGlobal21NowUsingPOST(annee: number, etablissement: string, typeActiviteGlobalDto: TypeActiviteGlobalDto21Now, options?: any) {
            return DeclarationControllerApiFp(configuration).setTypeActiviteGlobal21NowUsingPOST(annee, etablissement, typeActiviteGlobalDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTypeActiviteQuota2122
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto2122} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota2122UsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto2122, options?: any) {
            return DeclarationControllerApiFp(configuration).setTypeActiviteQuota2122UsingPOST(annee, etablissement, typeActiviteQuotaDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTypeActiviteQuota23Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {TypeActiviteQuotaDto23Now} typeActiviteQuotaDto typeActiviteQuotaDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTypeActiviteQuota23NowUsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto23Now, options?: any) {
            return DeclarationControllerApiFp(configuration).setTypeActiviteQuota23NowUsingPOST(annee, etablissement, typeActiviteQuotaDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationDto1819} quotasBlocVerificationDto1819 quotasBlocVerificationDto1819
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerification1819UsingPOST(annee: number, etablissement: string, quotasBlocVerificationDto1819: QuotasBlocVerificationDto1819, options?: any) {
            return DeclarationControllerApiFp(configuration).setVerification1819UsingPOST(annee, etablissement, quotasBlocVerificationDto1819, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setVerificationAllocations20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasAllocationsBlocVerificationDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationAllocations20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasAllocationsBlocVerificationDto20Now, options?: any) {
            return DeclarationControllerApiFp(configuration).setVerificationAllocations20NowUsingPOST(annee, etablissement, quotasBlocVerification, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setVerificationEmissions20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {QuotasBlocVerificationEmissionsDto20Now} quotasBlocVerification quotasBlocVerification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setVerificationEmissions20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasBlocVerificationEmissionsDto20Now, options?: any) {
            return DeclarationControllerApiFp(configuration).setVerificationEmissions20NowUsingPOST(annee, etablissement, quotasBlocVerification, options)(fetch, basePath);
        },
        /**
         * 
         * @summary startDeclaration1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration1819UsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).startDeclaration1819UsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary startDeclaration2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startDeclaration2020UsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).startDeclaration2020UsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary startGlobalDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startGlobalDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).startGlobalDeclaration21NowUsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary startQuotaDeclaration21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startQuotaDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).startQuotaDeclaration21NowUsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary submitDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).submitDeclarationUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary submitSimplifiedQuotas1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas1819UsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).submitSimplifiedQuotas1819UsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary submitSimplifiedQuotas2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas2020UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).submitSimplifiedQuotas2020UsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary submitSimplifiedQuotas21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitSimplifiedQuotas21NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).submitSimplifiedQuotas21NowUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary submitVerif
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submitVerifUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).submitVerifUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary takeoverDeclaration
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeoverDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).takeoverDeclarationUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateInseeInfo
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} siret siret
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInseeInfoUsingGET(annee: number, etablissement: string, siret: string, options?: any) {
            return DeclarationControllerApiFp(configuration).updateInseeInfoUsingGET(annee, etablissement, siret, options)(fetch, basePath);
        },
        /**
         * 
         * @summary userInvalidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationInvalidationPathDto} declarationInvalidationPathDto declarationInvalidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInvalidateDeclarationUsingDELETE(annee: number, declarationInvalidationPathDto: DeclarationInvalidationPathDto, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).userInvalidateDeclarationUsingDELETE(annee, declarationInvalidationPathDto, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary userValidateDeclaration
         * @param {number} annee annee
         * @param {DeclarationValidationPathDto} declarationValidationPathDto declarationValidationPathDto
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userValidateDeclarationUsingPOST(annee: number, declarationValidationPathDto: DeclarationValidationPathDto, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).userValidateDeclarationUsingPOST(annee, declarationValidationPathDto, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary yieldQuota1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota1819UsingPOST(annee: number, etablissement: string, options?: any) {
            return DeclarationControllerApiFp(configuration).yieldQuota1819UsingPOST(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary yieldQuota20Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        yieldQuota20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
            return DeclarationControllerApiFp(configuration).yieldQuota20NowUsingPOST(annee, etablissement, targets, options)(fetch, basePath);
        },
    };
};

/**
 * DeclarationControllerApi - object-oriented interface
 * @export
 * @class DeclarationControllerApi
 * @extends {BaseAPI}
 */
export class DeclarationControllerApi extends BaseAPI {
    /**
     * 
     * @summary addDeclarationToGunReimport
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public addDeclarationToGunReimportUsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).addDeclarationToGunReimportUsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary approveDeclaration1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public approveDeclaration1819UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).approveDeclaration1819UsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary approveDeclaration20Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public approveDeclaration20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).approveDeclaration20NowUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary comments
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {EventCommentsDto} eventCommentsDto eventCommentsDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public commentsUsingPOST(annee: number, etablissement: string, eventCommentsDto: EventCommentsDto, options?: any) {
        return DeclarationControllerApiFp(this.configuration).commentsUsingPOST(annee, etablissement, eventCommentsDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary contactGlobalInspector
     * @param {number} annee annee
     * @param {string} content content
     * @param {Array<string>} emails emails
     * @param {string} etablissement etablissement
     * @param {string} subject subject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public contactGlobalInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).contactGlobalInspectorUsingPOST(annee, content, emails, etablissement, subject, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary contactQuotasInspector
     * @param {number} annee annee
     * @param {string} content content
     * @param {Array<string>} emails emails
     * @param {string} etablissement etablissement
     * @param {string} subject subject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public contactQuotasInspectorUsingPOST(annee: number, content: string, emails: Array<string>, etablissement: string, subject: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).contactQuotasInspectorUsingPOST(annee, content, emails, etablissement, subject, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteComments
     * @param {number} annee annee
     * @param {DeleteCommentDto} deleteCommentDto deleteCommentDto
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public deleteCommentsUsingDELETE(annee: number, deleteCommentDto: DeleteCommentDto, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).deleteCommentsUsingDELETE(annee, deleteCommentDto, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportAccuseTransmissionEmissionsAsPdf
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public exportAccuseTransmissionEmissionsAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).exportAccuseTransmissionEmissionsAsPdfUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportAccuseTransmissionNiveauxActiviteAsPdf
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportAccuseTransmissionRegistreGerepAsPdf
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).exportAccuseTransmissionRegistreGerepAsPdfUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDeclarationExcel
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public exportDeclarationExcelUsingGET(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).exportDeclarationExcelUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDeclarationPdf
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public exportDeclarationPdfUsingGET(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).exportDeclarationPdfUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findDeclarationById
     * @param {number} annee annee
     * @param {BodyFindDeclarationByIdDto} bodyFindDeclarationByIdDto bodyFindDeclarationByIdDto
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public findDeclarationByIdUsingPOST(annee: number, bodyFindDeclarationByIdDto: BodyFindDeclarationByIdDto, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).findDeclarationByIdUsingPOST(annee, bodyFindDeclarationByIdDto, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary patchDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {JsonPatchDto} patchWrapper patchWrapper
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public patchDeclarationUsingPOST(annee: number, etablissement: string, patchWrapper: JsonPatchDto, options?: any) {
        return DeclarationControllerApiFp(this.configuration).patchDeclarationUsingPOST(annee, etablissement, patchWrapper, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary recallDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public recallDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).recallDeclarationUsingDELETE(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary relieveDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public relieveDeclarationUsingDELETE(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).relieveDeclarationUsingDELETE(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetDeclaration1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public resetDeclaration1819UsingDELETE(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).resetDeclaration1819UsingDELETE(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetDeclaration2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public resetDeclaration2020UsingDELETE(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).resetDeclaration2020UsingDELETE(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetGlobalDeclaration21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public resetGlobalDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).resetGlobalDeclaration21NowUsingDELETE(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetQuotaDeclaration21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public resetQuotaDeclaration21NowUsingDELETE(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).resetQuotaDeclaration21NowUsingDELETE(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary reviewDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {EventMessageDto} msg msg
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public reviewDeclarationUsingPOST(annee: number, etablissement: string, msg: EventMessageDto, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).reviewDeclarationUsingPOST(annee, etablissement, msg, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTypeActivite1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {TypeActiviteDto1819} typeActiviteDto typeActiviteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setTypeActivite1819UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto1819, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setTypeActivite1819UsingPOST(annee, etablissement, typeActiviteDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTypeActivite2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {TypeActiviteDto2020} typeActiviteDto typeActiviteDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setTypeActivite2020UsingPOST(annee: number, etablissement: string, typeActiviteDto: TypeActiviteDto2020, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setTypeActivite2020UsingPOST(annee, etablissement, typeActiviteDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTypeActiviteGlobal21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {TypeActiviteGlobalDto21Now} typeActiviteGlobalDto typeActiviteGlobalDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setTypeActiviteGlobal21NowUsingPOST(annee: number, etablissement: string, typeActiviteGlobalDto: TypeActiviteGlobalDto21Now, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setTypeActiviteGlobal21NowUsingPOST(annee, etablissement, typeActiviteGlobalDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTypeActiviteQuota2122
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {TypeActiviteQuotaDto2122} typeActiviteQuotaDto typeActiviteQuotaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setTypeActiviteQuota2122UsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto2122, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setTypeActiviteQuota2122UsingPOST(annee, etablissement, typeActiviteQuotaDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTypeActiviteQuota23Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {TypeActiviteQuotaDto23Now} typeActiviteQuotaDto typeActiviteQuotaDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setTypeActiviteQuota23NowUsingPOST(annee: number, etablissement: string, typeActiviteQuotaDto: TypeActiviteQuotaDto23Now, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setTypeActiviteQuota23NowUsingPOST(annee, etablissement, typeActiviteQuotaDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setVerification1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {QuotasBlocVerificationDto1819} quotasBlocVerificationDto1819 quotasBlocVerificationDto1819
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setVerification1819UsingPOST(annee: number, etablissement: string, quotasBlocVerificationDto1819: QuotasBlocVerificationDto1819, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setVerification1819UsingPOST(annee, etablissement, quotasBlocVerificationDto1819, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setVerificationAllocations20Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {QuotasAllocationsBlocVerificationDto20Now} quotasBlocVerification quotasBlocVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setVerificationAllocations20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasAllocationsBlocVerificationDto20Now, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setVerificationAllocations20NowUsingPOST(annee, etablissement, quotasBlocVerification, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setVerificationEmissions20Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {QuotasBlocVerificationEmissionsDto20Now} quotasBlocVerification quotasBlocVerification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public setVerificationEmissions20NowUsingPOST(annee: number, etablissement: string, quotasBlocVerification: QuotasBlocVerificationEmissionsDto20Now, options?: any) {
        return DeclarationControllerApiFp(this.configuration).setVerificationEmissions20NowUsingPOST(annee, etablissement, quotasBlocVerification, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary startDeclaration1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public startDeclaration1819UsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).startDeclaration1819UsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary startDeclaration2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public startDeclaration2020UsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).startDeclaration2020UsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary startGlobalDeclaration21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public startGlobalDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).startGlobalDeclaration21NowUsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary startQuotaDeclaration21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public startQuotaDeclaration21NowUsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).startQuotaDeclaration21NowUsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary submitDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public submitDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).submitDeclarationUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary submitSimplifiedQuotas1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public submitSimplifiedQuotas1819UsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).submitSimplifiedQuotas1819UsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary submitSimplifiedQuotas2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public submitSimplifiedQuotas2020UsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).submitSimplifiedQuotas2020UsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary submitSimplifiedQuotas21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public submitSimplifiedQuotas21NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).submitSimplifiedQuotas21NowUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary submitVerif
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public submitVerifUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).submitVerifUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary takeoverDeclaration
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public takeoverDeclarationUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).takeoverDeclarationUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateInseeInfo
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} siret siret
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public updateInseeInfoUsingGET(annee: number, etablissement: string, siret: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).updateInseeInfoUsingGET(annee, etablissement, siret, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary userInvalidateDeclaration
     * @param {number} annee annee
     * @param {DeclarationInvalidationPathDto} declarationInvalidationPathDto declarationInvalidationPathDto
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public userInvalidateDeclarationUsingDELETE(annee: number, declarationInvalidationPathDto: DeclarationInvalidationPathDto, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).userInvalidateDeclarationUsingDELETE(annee, declarationInvalidationPathDto, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary userValidateDeclaration
     * @param {number} annee annee
     * @param {DeclarationValidationPathDto} declarationValidationPathDto declarationValidationPathDto
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public userValidateDeclarationUsingPOST(annee: number, declarationValidationPathDto: DeclarationValidationPathDto, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).userValidateDeclarationUsingPOST(annee, declarationValidationPathDto, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary yieldQuota1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public yieldQuota1819UsingPOST(annee: number, etablissement: string, options?: any) {
        return DeclarationControllerApiFp(this.configuration).yieldQuota1819UsingPOST(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary yieldQuota20Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>} targets targets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationControllerApi
     */
    public yieldQuota20NowUsingPOST(annee: number, etablissement: string, targets: Array<'GLOBAL' | 'QUOTA_ALLOCATIONS' | 'QUOTA_EMISSIONS'>, options?: any) {
        return DeclarationControllerApiFp(this.configuration).yieldQuota20NowUsingPOST(annee, etablissement, targets, options)(this.fetch, this.basePath);
    }

}

/**
 * DeclarationCreationControllerApi - fetch parameter creator
 * @export
 */
export const DeclarationCreationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDeclarationWithHeader
         * @param {EtablissementDataDto} etablissementDataDto etablissementDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeclarationWithHeaderUsingPOST(etablissementDataDto: EtablissementDataDto, options: any = {}): FetchArgs {
            // verify required parameter 'etablissementDataDto' is not null or undefined
            if (etablissementDataDto === null || etablissementDataDto === undefined) {
                throw new RequiredError('etablissementDataDto','Required parameter etablissementDataDto was null or undefined when calling createDeclarationWithHeaderUsingPOST.');
            }
            const localVarPath = `/declarations/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EtablissementDataDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(etablissementDataDto || {}) : (etablissementDataDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationCreationControllerApi - functional programming interface
 * @export
 */
export const DeclarationCreationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDeclarationWithHeader
         * @param {EtablissementDataDto} etablissementDataDto etablissementDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeclarationWithHeaderUsingPOST(etablissementDataDto: EtablissementDataDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto22Nowobjectobject> {
            const localVarFetchArgs = DeclarationCreationControllerApiFetchParamCreator(configuration).createDeclarationWithHeaderUsingPOST(etablissementDataDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DeclarationCreationControllerApi - factory interface
 * @export
 */
export const DeclarationCreationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createDeclarationWithHeader
         * @param {EtablissementDataDto} etablissementDataDto etablissementDataDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeclarationWithHeaderUsingPOST(etablissementDataDto: EtablissementDataDto, options?: any) {
            return DeclarationCreationControllerApiFp(configuration).createDeclarationWithHeaderUsingPOST(etablissementDataDto, options)(fetch, basePath);
        },
    };
};

/**
 * DeclarationCreationControllerApi - object-oriented interface
 * @export
 * @class DeclarationCreationControllerApi
 * @extends {BaseAPI}
 */
export class DeclarationCreationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createDeclarationWithHeader
     * @param {EtablissementDataDto} etablissementDataDto etablissementDataDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationCreationControllerApi
     */
    public createDeclarationWithHeaderUsingPOST(etablissementDataDto: EtablissementDataDto, options?: any) {
        return DeclarationCreationControllerApiFp(this.configuration).createDeclarationWithHeaderUsingPOST(etablissementDataDto, options)(this.fetch, this.basePath);
    }

}

/**
 * DeclarationFileControllerApi - fetch parameter creator
 * @export
 */
export const DeclarationFileControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteAlcPreliminaireUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcPreliminaire`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteAlcVerifie2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcVerifie2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteAlcVerifie21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcVerifie21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAllocationsRapportOffice2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAllocationsRapportOffice2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAllocationsRapportOffice2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportOffice2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAllocationsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAllocationsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAllocationsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteAllocationsRapportOffice21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportOffice21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteAllocationsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteAllocationsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteAllocationsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteAllocationsRapportVerificationUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportVerification`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiversDocumentsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteDiversDocumentsUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteDiversDocumentsUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteDiversDocumentsUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/documentsInfosGenerales`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElevageFeuilleUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteElevageFeuilleUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteElevageFeuilleUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteElevageFeuilleUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/elevage`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteEmissionsRapportOffice2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteEmissionsRapportOffice2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteEmissionsRapportOffice2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportOffice2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteEmissionsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteEmissionsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteEmissionsRapportOffice21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteEmissionsRapportOffice21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportOffice21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteEmissionsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteEmissionsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteEmissionsRapportVerificationUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteEmissionsRapportVerificationUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportVerification`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteJustificatifAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteJustificatifAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteJustificatifAlcPreliminaireUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteJustificatifAlcPreliminaireUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcPreliminaire`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteJustificatifAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteJustificatifAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteJustificatifAlcVerifie2020UsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteJustificatifAlcVerifie2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcVerifie2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteJustificatifAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteJustificatifAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteJustificatifAlcVerifie21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteJustificatifAlcVerifie21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcVerifie21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifsPds21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteJustificatifsPds21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteJustificatifsPds21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteJustificatifsPds21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteJustificatifsPds21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pdsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePds1819UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePds1819UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePds1819UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePds2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePds2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePds2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePds2121UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePds2121UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePds2121UsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deletePds2121UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds2121`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePds22NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePds22NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePds22NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deletePds22NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds22Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePgsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePgsUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePgsUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePgsUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pgs`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePms2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePms2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePms2020UsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deletePms2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pms2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePms21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePms21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePms21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deletePms21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pms21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePmsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deletePmsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deletePmsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deletePmsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deletePmsJustificatifs21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pmsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportOffice1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteQuotasRapportOffice1819UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteQuotasRapportOffice1819UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteQuotasRapportOffice1819UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/quotasRapportOffice1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportVerification1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteQuotasRapportVerification1819UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteQuotasRapportVerification1819UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteQuotasRapportVerification1819UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/quotasRapportVerification1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteRapportEmissions1819UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteRapportEmissions1819UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteRapportEmissions1819UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteRapportEmissions2020UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteRapportEmissions2020UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteRapportEmissions2020UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteRapportEmissions2121UsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteRapportEmissions2121UsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteRapportEmissions2121UsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteRapportEmissions2121UsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions2121`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteRapportEmissions22NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteRapportEmissions22NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteRapportEmissions22NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteRapportEmissions22NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions22Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteRapportEmissionsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteRapportEmissionsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteRapportEmissionsJustificatifs21NowUsingDELETE.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling deleteRapportEmissionsJustificatifs21NowUsingDELETE.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissionsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAlcPreliminaireUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAlcPreliminaireUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadAlcPreliminaireUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcPreliminaire`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAlcVerifie2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAlcVerifie2020UsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadAlcVerifie2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcVerifie2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAlcVerifie21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAlcVerifie21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadAlcVerifie21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/alcVerifie21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAllocationsRapportOffice2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAllocationsRapportOffice2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportOffice2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAllocationsRapportOffice21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAllocationsRapportOffice21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadAllocationsRapportOffice21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportOffice21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadAllocationsRapportVerificationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadAllocationsRapportVerificationUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadAllocationsRapportVerificationUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/allocationsRapportVerification`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDiversDocumentsUsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadDiversDocumentsUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadDiversDocumentsUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/documentsInfosGenerales`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadElevageFeuilleUsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadElevageFeuilleUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadElevageFeuilleUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/elevage`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadEmissionsRapportOffice2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadEmissionsRapportOffice2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportOffice2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadEmissionsRapportOffice21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadEmissionsRapportOffice21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadEmissionsRapportOffice21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportOffice21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadEmissionsRapportVerificationUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadEmissionsRapportVerificationUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadEmissionsRapportVerificationUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/emissionsRapportVerification`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadJustificatifAlcPreliminaireUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadJustificatifAlcPreliminaireUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadJustificatifAlcPreliminaireUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcPreliminaire`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadJustificatifAlcVerifie2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadJustificatifAlcVerifie2020UsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadJustificatifAlcVerifie2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcVerifie2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadJustificatifAlcVerifie21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadJustificatifAlcVerifie21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadJustificatifAlcVerifie21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/justificatifAlcVerifie21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifsPds21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadJustificatifsPds21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadJustificatifsPds21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadJustificatifsPds21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pdsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds1819UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPds1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPds1819UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2020UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPds2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPds2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPds2121UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPds2121UsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadPds2121UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds2121`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPds22NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPds22NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadPds22NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pds22Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPgsUsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPgsUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPgsUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pgs`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPms2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPms2020UsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadPms2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pms2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPms21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPms21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadPms21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pms21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPmsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadPmsJustificatifs21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadPmsJustificatifs21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadPmsJustificatifs21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/pmsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportOffice1819UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadQuotasRapportOffice1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadQuotasRapportOffice1819UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/quotasRapportOffice1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportVerification1819UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadQuotasRapportVerification1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadQuotasRapportVerification1819UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/quotasRapportVerification1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions1819UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadRapportEmissions1819UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadRapportEmissions1819UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions1819`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2020UsingPOST(annee: number, etablissement: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadRapportEmissions2020UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadRapportEmissions2020UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions2020`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadRapportEmissions2121UsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadRapportEmissions2121UsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadRapportEmissions2121UsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions2121`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadRapportEmissions22NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadRapportEmissions22NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadRapportEmissions22NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissions22Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissionsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadRapportEmissionsJustificatifs21NowUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadRapportEmissionsJustificatifs21NowUsingPOST.');
            }
            // verify required parameter 'nimDynId' is not null or undefined
            if (nimDynId === null || nimDynId === undefined) {
                throw new RequiredError('nimDynId','Required parameter nimDynId was null or undefined when calling uploadRapportEmissionsJustificatifs21NowUsingPOST.');
            }
            const localVarPath = `/declarations/files/{annee}/{etablissement}/rapportEmissionsJustificatifs21Now`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (nimDynId !== undefined) {
                localVarQueryParameter['nimDynId'] = nimDynId;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeclarationFileControllerApi - functional programming interface
 * @export
 */
export const DeclarationFileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAllocationsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAllocationsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteAllocationsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiversDocumentsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteDiversDocumentsUsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElevageFeuilleUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteElevageFeuilleUsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteEmissionsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteEmissionsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteEmissionsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteJustificatifAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteJustificatifAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteJustificatifAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifsPds21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteJustificatifsPds21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePds1819UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePds2020UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2121> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePds2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto22Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePds22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePgsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePgsUsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePms2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePms21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePmsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deletePmsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportOffice1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteQuotasRapportOffice1819UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportVerification1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteQuotasRapportVerification1819UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteRapportEmissions1819UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteRapportEmissions2020UsingDELETE(annee, etablissement, fileDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteRapportEmissions2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto22Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteRapportEmissions22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAllocationsRapportOffice2020UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAllocationsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadAllocationsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDiversDocumentsUsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadDiversDocumentsUsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadElevageFeuilleUsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadElevageFeuilleUsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadEmissionsRapportOffice2020UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadEmissionsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadEmissionsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto20Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadJustificatifAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadJustificatifAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadJustificatifAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifsPds21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadJustificatifsPds21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPds1819UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPds2020UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2121> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPds2121UsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto22Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPds22NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPgsUsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto18Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPgsUsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPms2020UsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPms21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPmsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadPmsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportOffice1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadQuotasRapportOffice1819UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportVerification1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadQuotasRapportVerification1819UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto1819> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadRapportEmissions1819UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeclarationDto2020> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadRapportEmissions2020UsingPOST(annee, etablissement, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadRapportEmissions2121UsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto22Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadRapportEmissions22NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissionsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto21Nowobjectobject> {
            const localVarFetchArgs = DeclarationFileControllerApiFetchParamCreator(configuration).uploadRapportEmissionsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DeclarationFileControllerApi - factory interface
 * @export
 */
export const DeclarationFileControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAllocationsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAllocationsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllocationsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteAllocationsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDiversDocumentsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteDiversDocumentsUsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteElevageFeuilleUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteElevageFeuilleUsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteEmissionsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteEmissionsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEmissionsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteEmissionsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteJustificatifAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteJustificatifAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteJustificatifAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJustificatifsPds21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteJustificatifsPds21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePds1819UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePds2020UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePds2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePds22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePds22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePgsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePgsUsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePms2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePms21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePms21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePmsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deletePmsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportOffice1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteQuotasRapportOffice1819UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteQuotasRapportVerification1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteQuotasRapportVerification1819UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteRapportEmissions1819UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteRapportEmissions2020UsingDELETE(annee, etablissement, fileDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteRapportEmissions2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissions22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteRapportEmissions22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {string} nimDynId nimDynId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
            return DeclarationFileControllerApiFp(configuration).deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAllocationsRapportOffice2020UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAllocationsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAllocationsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadAllocationsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAllocationsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadAllocationsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadDiversDocuments
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDiversDocumentsUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadDiversDocumentsUsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadElevageFeuille
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadElevageFeuilleUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadElevageFeuilleUsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadEmissionsRapportOffice2020UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadEmissionsRapportOffice21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadEmissionsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadEmissionsRapportVerification
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmissionsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadEmissionsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadJustificatifAlcPreliminaire
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadJustificatifAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadJustificatifAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadJustificatifAlcVerifie21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadJustificatifAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadJustificatifsPds21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadJustificatifsPds21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadJustificatifsPds21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPds1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPds1819UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPds2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPds2020UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPds2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPds2121UsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPds22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPds22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPds22NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPgs
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPgsUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPgsUsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPms2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPms2020UsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPms21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPms21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPms21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPmsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPmsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadPmsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadQuotasRapportOffice1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportOffice1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadQuotasRapportOffice1819UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadQuotasRapportVerification1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadQuotasRapportVerification1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadQuotasRapportVerification1819UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRapportEmissions1819
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadRapportEmissions1819UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRapportEmissions2020
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadRapportEmissions2020UsingPOST(annee, etablissement, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRapportEmissions2121
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadRapportEmissions2121UsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRapportEmissions22Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissions22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadRapportEmissions22NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRapportEmissionsJustificatifs21Now
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} nimDynId nimDynId
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRapportEmissionsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
            return DeclarationFileControllerApiFp(configuration).uploadRapportEmissionsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options)(fetch, basePath);
        },
    };
};

/**
 * DeclarationFileControllerApi - object-oriented interface
 * @export
 * @class DeclarationFileControllerApi
 * @extends {BaseAPI}
 */
export class DeclarationFileControllerApi extends BaseAPI {
    /**
     * 
     * @summary deleteAlcPreliminaire
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAlcVerifie2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAlcVerifie21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllocationsRapportOffice2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAllocationsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAllocationsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllocationsRapportOffice21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAllocationsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAllocationsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllocationsRapportVerification
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteAllocationsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteAllocationsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteDiversDocuments
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteDiversDocumentsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteDiversDocumentsUsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteElevageFeuille
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteElevageFeuilleUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteElevageFeuilleUsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteEmissionsRapportOffice2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteEmissionsRapportOffice2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteEmissionsRapportOffice2020UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteEmissionsRapportOffice21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteEmissionsRapportOffice21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteEmissionsRapportOffice21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteEmissionsRapportVerification
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteEmissionsRapportVerificationUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteEmissionsRapportVerificationUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteJustificatifAlcPreliminaire
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteJustificatifAlcPreliminaireUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteJustificatifAlcPreliminaireUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteJustificatifAlcVerifie2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteJustificatifAlcVerifie2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteJustificatifAlcVerifie2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteJustificatifAlcVerifie21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteJustificatifAlcVerifie21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteJustificatifAlcVerifie21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteJustificatifsPds21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteJustificatifsPds21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteJustificatifsPds21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePds1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePds1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePds1819UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePds2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePds2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePds2020UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePds2121
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePds2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePds2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePds22Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePds22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePds22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePgs
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePgsUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePgsUsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePms2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePms2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePms2020UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePms21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePms21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePms21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePmsJustificatifs21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deletePmsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deletePmsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteQuotasRapportOffice1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteQuotasRapportOffice1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteQuotasRapportOffice1819UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteQuotasRapportVerification1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteQuotasRapportVerification1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteQuotasRapportVerification1819UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRapportEmissions1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteRapportEmissions1819UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteRapportEmissions1819UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRapportEmissions2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteRapportEmissions2020UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteRapportEmissions2020UsingDELETE(annee, etablissement, fileDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRapportEmissions2121
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteRapportEmissions2121UsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteRapportEmissions2121UsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRapportEmissions22Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteRapportEmissions22NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteRapportEmissions22NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRapportEmissionsJustificatifs21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {string} nimDynId nimDynId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, nimDynId: string, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).deleteRapportEmissionsJustificatifs21NowUsingDELETE(annee, etablissement, fileDto, nimDynId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAlcPreliminaire
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAlcVerifie2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAlcVerifie21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAllocationsRapportOffice2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAllocationsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAllocationsRapportOffice2020UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAllocationsRapportOffice21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAllocationsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAllocationsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadAllocationsRapportVerification
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadAllocationsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadAllocationsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadDiversDocuments
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadDiversDocumentsUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadDiversDocumentsUsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadElevageFeuille
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadElevageFeuilleUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadElevageFeuilleUsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadEmissionsRapportOffice2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadEmissionsRapportOffice2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadEmissionsRapportOffice2020UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadEmissionsRapportOffice21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadEmissionsRapportOffice21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadEmissionsRapportOffice21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadEmissionsRapportVerification
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadEmissionsRapportVerificationUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadEmissionsRapportVerificationUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadJustificatifAlcPreliminaire
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadJustificatifAlcPreliminaireUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadJustificatifAlcPreliminaireUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadJustificatifAlcVerifie2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadJustificatifAlcVerifie2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadJustificatifAlcVerifie2020UsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadJustificatifAlcVerifie21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadJustificatifAlcVerifie21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadJustificatifAlcVerifie21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadJustificatifsPds21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadJustificatifsPds21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadJustificatifsPds21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPds1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPds1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPds1819UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPds2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPds2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPds2020UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPds2121
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPds2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPds2121UsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPds22Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPds22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPds22NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPgs
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPgsUsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPgsUsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPms2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPms2020UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPms2020UsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPms21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPms21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPms21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPmsJustificatifs21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadPmsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadPmsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadQuotasRapportOffice1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadQuotasRapportOffice1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadQuotasRapportOffice1819UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadQuotasRapportVerification1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadQuotasRapportVerification1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadQuotasRapportVerification1819UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRapportEmissions1819
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadRapportEmissions1819UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadRapportEmissions1819UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRapportEmissions2020
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadRapportEmissions2020UsingPOST(annee: number, etablissement: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadRapportEmissions2020UsingPOST(annee, etablissement, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRapportEmissions2121
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadRapportEmissions2121UsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadRapportEmissions2121UsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRapportEmissions22Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadRapportEmissions22NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadRapportEmissions22NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRapportEmissionsJustificatifs21Now
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} nimDynId nimDynId
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeclarationFileControllerApi
     */
    public uploadRapportEmissionsJustificatifs21NowUsingPOST(annee: number, etablissement: string, nimDynId: string, file?: any, options?: any) {
        return DeclarationFileControllerApiFp(this.configuration).uploadRapportEmissionsJustificatifs21NowUsingPOST(annee, etablissement, nimDynId, file, options)(this.fetch, this.basePath);
    }

}

/**
 * EmailUnsubscriptionControllerApi - fetch parameter creator
 * @export
 */
export const EmailUnsubscriptionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettingsUnsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUnsubscriptionUsingGET(emailDeclarant: string, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'emailDeclarant' is not null or undefined
            if (emailDeclarant === null || emailDeclarant === undefined) {
                throw new RequiredError('emailDeclarant','Required parameter emailDeclarant was null or undefined when calling getSettingsUnsubscriptionUsingGET.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling getSettingsUnsubscriptionUsingGET.');
            }
            const localVarPath = `/email/unsubscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (emailDeclarant !== undefined) {
                localVarQueryParameter['emailDeclarant'] = emailDeclarant;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {boolean} initiateReminderDisabled initiateReminderDisabled
         * @param {string} token token
         * @param {boolean} validationReminderDisabled validationReminderDisabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscriptionUsingPOST(emailDeclarant: string, initiateReminderDisabled: boolean, token: string, validationReminderDisabled: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'emailDeclarant' is not null or undefined
            if (emailDeclarant === null || emailDeclarant === undefined) {
                throw new RequiredError('emailDeclarant','Required parameter emailDeclarant was null or undefined when calling unsubscriptionUsingPOST.');
            }
            // verify required parameter 'initiateReminderDisabled' is not null or undefined
            if (initiateReminderDisabled === null || initiateReminderDisabled === undefined) {
                throw new RequiredError('initiateReminderDisabled','Required parameter initiateReminderDisabled was null or undefined when calling unsubscriptionUsingPOST.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling unsubscriptionUsingPOST.');
            }
            // verify required parameter 'validationReminderDisabled' is not null or undefined
            if (validationReminderDisabled === null || validationReminderDisabled === undefined) {
                throw new RequiredError('validationReminderDisabled','Required parameter validationReminderDisabled was null or undefined when calling unsubscriptionUsingPOST.');
            }
            const localVarPath = `/email/unsubscription`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (emailDeclarant !== undefined) {
                localVarQueryParameter['emailDeclarant'] = emailDeclarant;
            }

            if (initiateReminderDisabled !== undefined) {
                localVarQueryParameter['initiateReminderDisabled'] = initiateReminderDisabled;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (validationReminderDisabled !== undefined) {
                localVarQueryParameter['validationReminderDisabled'] = validationReminderDisabled;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EmailUnsubscriptionControllerApi - functional programming interface
 * @export
 */
export const EmailUnsubscriptionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getSettingsUnsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUnsubscriptionUsingGET(emailDeclarant: string, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EmailUnsubscriptionDto> {
            const localVarFetchArgs = EmailUnsubscriptionControllerApiFetchParamCreator(configuration).getSettingsUnsubscriptionUsingGET(emailDeclarant, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary unsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {boolean} initiateReminderDisabled initiateReminderDisabled
         * @param {string} token token
         * @param {boolean} validationReminderDisabled validationReminderDisabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscriptionUsingPOST(emailDeclarant: string, initiateReminderDisabled: boolean, token: string, validationReminderDisabled: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EmailUnsubscriptionControllerApiFetchParamCreator(configuration).unsubscriptionUsingPOST(emailDeclarant, initiateReminderDisabled, token, validationReminderDisabled, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EmailUnsubscriptionControllerApi - factory interface
 * @export
 */
export const EmailUnsubscriptionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getSettingsUnsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettingsUnsubscriptionUsingGET(emailDeclarant: string, token: string, options?: any) {
            return EmailUnsubscriptionControllerApiFp(configuration).getSettingsUnsubscriptionUsingGET(emailDeclarant, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary unsubscription
         * @param {string} emailDeclarant emailDeclarant
         * @param {boolean} initiateReminderDisabled initiateReminderDisabled
         * @param {string} token token
         * @param {boolean} validationReminderDisabled validationReminderDisabled
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscriptionUsingPOST(emailDeclarant: string, initiateReminderDisabled: boolean, token: string, validationReminderDisabled: boolean, options?: any) {
            return EmailUnsubscriptionControllerApiFp(configuration).unsubscriptionUsingPOST(emailDeclarant, initiateReminderDisabled, token, validationReminderDisabled, options)(fetch, basePath);
        },
    };
};

/**
 * EmailUnsubscriptionControllerApi - object-oriented interface
 * @export
 * @class EmailUnsubscriptionControllerApi
 * @extends {BaseAPI}
 */
export class EmailUnsubscriptionControllerApi extends BaseAPI {
    /**
     * 
     * @summary getSettingsUnsubscription
     * @param {string} emailDeclarant emailDeclarant
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailUnsubscriptionControllerApi
     */
    public getSettingsUnsubscriptionUsingGET(emailDeclarant: string, token: string, options?: any) {
        return EmailUnsubscriptionControllerApiFp(this.configuration).getSettingsUnsubscriptionUsingGET(emailDeclarant, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary unsubscription
     * @param {string} emailDeclarant emailDeclarant
     * @param {boolean} initiateReminderDisabled initiateReminderDisabled
     * @param {string} token token
     * @param {boolean} validationReminderDisabled validationReminderDisabled
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EmailUnsubscriptionControllerApi
     */
    public unsubscriptionUsingPOST(emailDeclarant: string, initiateReminderDisabled: boolean, token: string, validationReminderDisabled: boolean, options?: any) {
        return EmailUnsubscriptionControllerApiFp(this.configuration).unsubscriptionUsingPOST(emailDeclarant, initiateReminderDisabled, token, validationReminderDisabled, options)(this.fetch, this.basePath);
    }

}

/**
 * EtablissementControllerApi - fetch parameter creator
 * @export
 */
export const EtablissementControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtablissementUsingDELETE(codeEtablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'codeEtablissement' is not null or undefined
            if (codeEtablissement === null || codeEtablissement === undefined) {
                throw new RequiredError('codeEtablissement','Required parameter codeEtablissement was null or undefined when calling deleteEtablissementUsingDELETE.');
            }
            const localVarPath = `/etablissement/{codeEtablissement}`
                .replace(`{${"codeEtablissement"}}`, encodeURIComponent(String(codeEtablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllYearsDeclaredForEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'codeEtablissement' is not null or undefined
            if (codeEtablissement === null || codeEtablissement === undefined) {
                throw new RequiredError('codeEtablissement','Required parameter codeEtablissement was null or undefined when calling getAllYearsDeclaredForEtablissementUsingGET.');
            }
            const localVarPath = `/etablissement/{codeEtablissement}/annees`
                .replace(`{${"codeEtablissement"}}`, encodeURIComponent(String(codeEtablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EtablissementControllerApi - functional programming interface
 * @export
 */
export const EtablissementControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtablissementUsingDELETE(codeEtablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EtablissementControllerApiFetchParamCreator(configuration).deleteEtablissementUsingDELETE(codeEtablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllYearsDeclaredForEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<number>> {
            const localVarFetchArgs = EtablissementControllerApiFetchParamCreator(configuration).getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EtablissementControllerApi - factory interface
 * @export
 */
export const EtablissementControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEtablissementUsingDELETE(codeEtablissement: string, options?: any) {
            return EtablissementControllerApiFp(configuration).deleteEtablissementUsingDELETE(codeEtablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllYearsDeclaredForEtablissement
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement: string, options?: any) {
            return EtablissementControllerApiFp(configuration).getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement, options)(fetch, basePath);
        },
    };
};

/**
 * EtablissementControllerApi - object-oriented interface
 * @export
 * @class EtablissementControllerApi
 * @extends {BaseAPI}
 */
export class EtablissementControllerApi extends BaseAPI {
    /**
     * 
     * @summary deleteEtablissement
     * @param {string} codeEtablissement codeEtablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EtablissementControllerApi
     */
    public deleteEtablissementUsingDELETE(codeEtablissement: string, options?: any) {
        return EtablissementControllerApiFp(this.configuration).deleteEtablissementUsingDELETE(codeEtablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllYearsDeclaredForEtablissement
     * @param {string} codeEtablissement codeEtablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EtablissementControllerApi
     */
    public getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement: string, options?: any) {
        return EtablissementControllerApiFp(this.configuration).getAllYearsDeclaredForEtablissementUsingGET(codeEtablissement, options)(this.fetch, this.basePath);
    }

}

/**
 * FileControllerApi - fetch parameter creator
 * @export
 */
export const FileControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary allowedExtensions
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowedExtensionsUsingGET(section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options: any = {}): FetchArgs {
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling allowedExtensionsUsingGET.');
            }
            const localVarPath = `/api/file/extensions/allowed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary delete
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling deleteUsingDELETE.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling deleteUsingDELETE.');
            }
            // verify required parameter 'fileDto' is not null or undefined
            if (fileDto === null || fileDto === undefined) {
                throw new RequiredError('fileDto','Required parameter fileDto was null or undefined when calling deleteUsingDELETE.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling deleteUsingDELETE.');
            }
            const localVarPath = `/api/file/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            if (etablissement !== undefined) {
                localVarQueryParameter['etablissement'] = etablissement;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FileDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(fileDto || {}) : (fileDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadAllDeclarationFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationFilesUsingGET(annee: number, codeEtablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling downloadAllDeclarationFilesUsingGET.');
            }
            // verify required parameter 'codeEtablissement' is not null or undefined
            if (codeEtablissement === null || codeEtablissement === undefined) {
                throw new RequiredError('codeEtablissement','Required parameter codeEtablissement was null or undefined when calling downloadAllDeclarationFilesUsingGET.');
            }
            const localVarPath = `/api/file/all/declaration/files/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            if (codeEtablissement !== undefined) {
                localVarQueryParameter['codeEtablissement'] = codeEtablissement;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadExport
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportUsingGET(fileName: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling downloadExportUsingGET.');
            }
            const localVarPath = `/api/file/export/{fileName}`
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadFolderFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFolderFilesUsingGET(annee: number, codeEtablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling downloadFolderFilesUsingGET.');
            }
            // verify required parameter 'codeEtablissement' is not null or undefined
            if (codeEtablissement === null || codeEtablissement === undefined) {
                throw new RequiredError('codeEtablissement','Required parameter codeEtablissement was null or undefined when calling downloadFolderFilesUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling downloadFolderFilesUsingGET.');
            }
            const localVarPath = `/api/file/folder/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            if (codeEtablissement !== undefined) {
                localVarQueryParameter['codeEtablissement'] = codeEtablissement;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} fileExtension fileExtension
         * @param {string} fileId fileId
         * @param {string} fileName fileName
         * @param {string} fileType fileType
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(annee: number, etablissement: string, fileExtension: string, fileId: string, fileName: string, fileType: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'fileExtension' is not null or undefined
            if (fileExtension === null || fileExtension === undefined) {
                throw new RequiredError('fileExtension','Required parameter fileExtension was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'fileId' is not null or undefined
            if (fileId === null || fileId === undefined) {
                throw new RequiredError('fileId','Required parameter fileId was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'fileType' is not null or undefined
            if (fileType === null || fileType === undefined) {
                throw new RequiredError('fileType','Required parameter fileType was null or undefined when calling downloadUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling downloadUsingGET.');
            }
            const localVarPath = `/api/file/download`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            if (etablissement !== undefined) {
                localVarQueryParameter['etablissement'] = etablissement;
            }

            if (fileExtension !== undefined) {
                localVarQueryParameter['fileExtension'] = fileExtension;
            }

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            if (fileType !== undefined) {
                localVarQueryParameter['fileType'] = fileType;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(annee: number, etablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', file?: any, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling uploadUsingPOST.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling uploadUsingPOST.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling uploadUsingPOST.');
            }
            const localVarPath = `/api/file/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            if (etablissement !== undefined) {
                localVarQueryParameter['etablissement'] = etablissement;
            }

            if (section !== undefined) {
                localVarQueryParameter['section'] = section;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary allowedExtensions
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowedExtensionsUsingGET(section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).allowedExtensionsUsingGET(section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary delete
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).deleteUsingDELETE(annee, etablissement, fileDto, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadAllDeclarationFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationFilesUsingGET(annee: number, codeEtablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).downloadAllDeclarationFilesUsingGET(annee, codeEtablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadExport
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportUsingGET(fileName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).downloadExportUsingGET(fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadFolderFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFolderFilesUsingGET(annee: number, codeEtablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).downloadFolderFilesUsingGET(annee, codeEtablissement, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary download
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} fileExtension fileExtension
         * @param {string} fileId fileId
         * @param {string} fileName fileName
         * @param {string} fileType fileType
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(annee: number, etablissement: string, fileExtension: string, fileId: string, fileName: string, fileType: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).downloadUsingGET(annee, etablissement, fileExtension, fileId, fileName, fileType, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary upload
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(annee: number, etablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FileDto> {
            const localVarFetchArgs = FileControllerApiFetchParamCreator(configuration).uploadUsingPOST(annee, etablissement, section, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary allowedExtensions
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        allowedExtensionsUsingGET(section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
            return FileControllerApiFp(configuration).allowedExtensionsUsingGET(section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary delete
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {FileDto} fileDto fileDto
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
            return FileControllerApiFp(configuration).deleteUsingDELETE(annee, etablissement, fileDto, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadAllDeclarationFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationFilesUsingGET(annee: number, codeEtablissement: string, options?: any) {
            return FileControllerApiFp(configuration).downloadAllDeclarationFilesUsingGET(annee, codeEtablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadExport
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadExportUsingGET(fileName: string, options?: any) {
            return FileControllerApiFp(configuration).downloadExportUsingGET(fileName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadFolderFiles
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFolderFilesUsingGET(annee: number, codeEtablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
            return FileControllerApiFp(configuration).downloadFolderFilesUsingGET(annee, codeEtablissement, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary download
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {string} fileExtension fileExtension
         * @param {string} fileId fileId
         * @param {string} fileName fileName
         * @param {string} fileType fileType
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadUsingGET(annee: number, etablissement: string, fileExtension: string, fileId: string, fileName: string, fileType: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
            return FileControllerApiFp(configuration).downloadUsingGET(annee, etablissement, fileExtension, fileId, fileName, fileType, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary upload
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(annee: number, etablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', file?: any, options?: any) {
            return FileControllerApiFp(configuration).uploadUsingPOST(annee, etablissement, section, file, options)(fetch, basePath);
        },
    };
};

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
    /**
     * 
     * @summary allowedExtensions
     * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public allowedExtensionsUsingGET(section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
        return FileControllerApiFp(this.configuration).allowedExtensionsUsingGET(section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary delete
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {FileDto} fileDto fileDto
     * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public deleteUsingDELETE(annee: number, etablissement: string, fileDto: FileDto, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
        return FileControllerApiFp(this.configuration).deleteUsingDELETE(annee, etablissement, fileDto, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadAllDeclarationFiles
     * @param {number} annee annee
     * @param {string} codeEtablissement codeEtablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public downloadAllDeclarationFilesUsingGET(annee: number, codeEtablissement: string, options?: any) {
        return FileControllerApiFp(this.configuration).downloadAllDeclarationFilesUsingGET(annee, codeEtablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadExport
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public downloadExportUsingGET(fileName: string, options?: any) {
        return FileControllerApiFp(this.configuration).downloadExportUsingGET(fileName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadFolderFiles
     * @param {number} annee annee
     * @param {string} codeEtablissement codeEtablissement
     * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public downloadFolderFilesUsingGET(annee: number, codeEtablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
        return FileControllerApiFp(this.configuration).downloadFolderFilesUsingGET(annee, codeEtablissement, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary download
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {string} fileExtension fileExtension
     * @param {string} fileId fileId
     * @param {string} fileName fileName
     * @param {string} fileType fileType
     * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public downloadUsingGET(annee: number, etablissement: string, fileExtension: string, fileId: string, fileName: string, fileType: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', options?: any) {
        return FileControllerApiFp(this.configuration).downloadUsingGET(annee, etablissement, fileExtension, fileId, fileName, fileType, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary upload
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION'} section section
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileControllerApi
     */
    public uploadUsingPOST(annee: number, etablissement: string, section: 'DOCUMENTS_DIVERS' | 'ELEVAGE_FEUILLE_CALCUL' | 'QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS' | 'QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE' | 'QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE' | 'QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS' | 'QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE' | 'QUOTAS_JUSTIFICATIFS_ALC_VERIFIE' | 'QUOTAS_PLAN_SURVEILLANCE' | 'QUOTAS_RAPPORT_ALC_PRELIMINAIRE' | 'QUOTAS_RAPPORT_ALC_VERIFIE' | 'QUOTAS_RAPPORT_DECLARATION_D_OFFICE' | 'QUOTAS_RAPPORT_EMISSIONS' | 'QUOTAS_RAPPORT_VERIFICATION' | 'QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS' | 'QUOTAS_RAPPORT_VERIFICATION_EMISSIONS' | 'SOLVANT_PLAN_GESTION', file?: any, options?: any) {
        return FileControllerApiFp(this.configuration).uploadUsingPOST(annee, etablissement, section, file, options)(this.fetch, this.basePath);
    }

}

/**
 * GestionnaireControllerApi - fetch parameter creator
 * @export
 */
export const GestionnaireControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadAllDeclarationsFiles
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options: any = {}): FetchArgs {
            // verify required parameter 'filtresDeclarationDto' is not null or undefined
            if (filtresDeclarationDto === null || filtresDeclarationDto === undefined) {
                throw new RequiredError('filtresDeclarationDto','Required parameter filtresDeclarationDto was null or undefined when calling downloadAllDeclarationsFilesUsingPOST.');
            }
            const localVarPath = `/gestionnaire/download/declarationsFiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresDeclarationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresDeclarationDto || {}) : (filtresDeclarationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options: any = {}): FetchArgs {
            // verify required parameter 'filtresDeclarationDto' is not null or undefined
            if (filtresDeclarationDto === null || filtresDeclarationDto === undefined) {
                throw new RequiredError('filtresDeclarationDto','Required parameter filtresDeclarationDto was null or undefined when calling exportDeclarationsListUsingPOST.');
            }
            const localVarPath = `/gestionnaire/export/declarationsList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresDeclarationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresDeclarationDto || {}) : (filtresDeclarationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, options: any = {}): FetchArgs {
            // verify required parameter 'filtresEtablissementDto' is not null or undefined
            if (filtresEtablissementDto === null || filtresEtablissementDto === undefined) {
                throw new RequiredError('filtresEtablissementDto','Required parameter filtresEtablissementDto was null or undefined when calling exportEtablissementsListUsingPOST.');
            }
            const localVarPath = `/gestionnaire/export/etablissementsList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresEtablissementDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresEtablissementDto || {}) : (filtresEtablissementDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPostCampaignChangesUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/gestionnaire/export/events/post-campaign`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/gestionnaire/availableYears`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary reinitPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinitPostCampaignChangesUsingPUT(options: any = {}): FetchArgs {
            const localVarPath = `/gestionnaire/export/events/post-campaign/reinit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options: any = {}): FetchArgs {
            // verify required parameter 'filtresDeclarationDto' is not null or undefined
            if (filtresDeclarationDto === null || filtresDeclarationDto === undefined) {
                throw new RequiredError('filtresDeclarationDto','Required parameter filtresDeclarationDto was null or undefined when calling retrieveDeclarationsListUsingPOST.');
            }
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling retrieveDeclarationsListUsingPOST.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling retrieveDeclarationsListUsingPOST.');
            }
            const localVarPath = `/gestionnaire/retrieveDeclarationsList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresDeclarationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresDeclarationDto || {}) : (filtresDeclarationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveDeclarationsVerificateurList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options: any = {}): FetchArgs {
            // verify required parameter 'filtresDeclarationDto' is not null or undefined
            if (filtresDeclarationDto === null || filtresDeclarationDto === undefined) {
                throw new RequiredError('filtresDeclarationDto','Required parameter filtresDeclarationDto was null or undefined when calling retrieveDeclarationsVerificateurListUsingPOST.');
            }
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling retrieveDeclarationsVerificateurListUsingPOST.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling retrieveDeclarationsVerificateurListUsingPOST.');
            }
            const localVarPath = `/gestionnaire/retrieveDeclarationsVerificateurList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresDeclarationDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresDeclarationDto || {}) : (filtresDeclarationDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieveEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, pageNumber: number, pageSize: number, options: any = {}): FetchArgs {
            // verify required parameter 'filtresEtablissementDto' is not null or undefined
            if (filtresEtablissementDto === null || filtresEtablissementDto === undefined) {
                throw new RequiredError('filtresEtablissementDto','Required parameter filtresEtablissementDto was null or undefined when calling retrieveEtablissementsListUsingPOST.');
            }
            // verify required parameter 'pageNumber' is not null or undefined
            if (pageNumber === null || pageNumber === undefined) {
                throw new RequiredError('pageNumber','Required parameter pageNumber was null or undefined when calling retrieveEtablissementsListUsingPOST.');
            }
            // verify required parameter 'pageSize' is not null or undefined
            if (pageSize === null || pageSize === undefined) {
                throw new RequiredError('pageSize','Required parameter pageSize was null or undefined when calling retrieveEtablissementsListUsingPOST.');
            }
            const localVarPath = `/gestionnaire/retrieveEtablissementsList`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FiltresEtablissementDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filtresEtablissementDto || {}) : (filtresEtablissementDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GestionnaireControllerApi - functional programming interface
 * @export
 */
export const GestionnaireControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadAllDeclarationsFiles
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).exportDeclarationsListUsingPOST(filtresDeclarationDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).exportEtablissementsListUsingPOST(filtresEtablissementDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPostCampaignChangesUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).exportPostCampaignChangesUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<number>> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).getDeclarationsYearsAvailableUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary reinitPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinitPostCampaignChangesUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).reinitPostCampaignChangesUsingPUT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimpleDeclarationListDto> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).retrieveDeclarationsListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveDeclarationsVerificateurList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SimpleInstallationListDto> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary retrieveEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, pageNumber: number, pageSize: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EtablissementListDto> {
            const localVarFetchArgs = GestionnaireControllerApiFetchParamCreator(configuration).retrieveEtablissementsListUsingPOST(filtresEtablissementDto, pageNumber, pageSize, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GestionnaireControllerApi - factory interface
 * @export
 */
export const GestionnaireControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadAllDeclarationsFiles
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any) {
            return GestionnaireControllerApiFp(configuration).downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any) {
            return GestionnaireControllerApiFp(configuration).exportDeclarationsListUsingPOST(filtresDeclarationDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, options?: any) {
            return GestionnaireControllerApiFp(configuration).exportEtablissementsListUsingPOST(filtresEtablissementDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPostCampaignChangesUsingGET(options?: any) {
            return GestionnaireControllerApiFp(configuration).exportPostCampaignChangesUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailable
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableUsingGET(options?: any) {
            return GestionnaireControllerApiFp(configuration).getDeclarationsYearsAvailableUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary reinitPostCampaignChanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinitPostCampaignChangesUsingPUT(options?: any) {
            return GestionnaireControllerApiFp(configuration).reinitPostCampaignChangesUsingPUT(options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveDeclarationsList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any) {
            return GestionnaireControllerApiFp(configuration).retrieveDeclarationsListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveDeclarationsVerificateurList
         * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any) {
            return GestionnaireControllerApiFp(configuration).retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options)(fetch, basePath);
        },
        /**
         * 
         * @summary retrieveEtablissementsList
         * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
         * @param {number} pageNumber pageNumber
         * @param {number} pageSize pageSize
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        retrieveEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, pageNumber: number, pageSize: number, options?: any) {
            return GestionnaireControllerApiFp(configuration).retrieveEtablissementsListUsingPOST(filtresEtablissementDto, pageNumber, pageSize, options)(fetch, basePath);
        },
    };
};

/**
 * GestionnaireControllerApi - object-oriented interface
 * @export
 * @class GestionnaireControllerApi
 * @extends {BaseAPI}
 */
export class GestionnaireControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadAllDeclarationsFiles
     * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).downloadAllDeclarationsFilesUsingPOST(filtresDeclarationDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDeclarationsList
     * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public exportDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).exportDeclarationsListUsingPOST(filtresDeclarationDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportEtablissementsList
     * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public exportEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).exportEtablissementsListUsingPOST(filtresEtablissementDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportPostCampaignChanges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public exportPostCampaignChangesUsingGET(options?: any) {
        return GestionnaireControllerApiFp(this.configuration).exportPostCampaignChangesUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeclarationsYearsAvailable
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public getDeclarationsYearsAvailableUsingGET(options?: any) {
        return GestionnaireControllerApiFp(this.configuration).getDeclarationsYearsAvailableUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary reinitPostCampaignChanges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public reinitPostCampaignChangesUsingPUT(options?: any) {
        return GestionnaireControllerApiFp(this.configuration).reinitPostCampaignChangesUsingPUT(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveDeclarationsList
     * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
     * @param {number} pageNumber pageNumber
     * @param {number} pageSize pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public retrieveDeclarationsListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).retrieveDeclarationsListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveDeclarationsVerificateurList
     * @param {FiltresDeclarationDto} filtresDeclarationDto filtresDeclarationDto
     * @param {number} pageNumber pageNumber
     * @param {number} pageSize pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto: FiltresDeclarationDto, pageNumber: number, pageSize: number, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).retrieveDeclarationsVerificateurListUsingPOST(filtresDeclarationDto, pageNumber, pageSize, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary retrieveEtablissementsList
     * @param {FiltresEtablissementDto} filtresEtablissementDto filtresEtablissementDto
     * @param {number} pageNumber pageNumber
     * @param {number} pageSize pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GestionnaireControllerApi
     */
    public retrieveEtablissementsListUsingPOST(filtresEtablissementDto: FiltresEtablissementDto, pageNumber: number, pageSize: number, options?: any) {
        return GestionnaireControllerApiFp(this.configuration).retrieveEtablissementsListUsingPOST(filtresEtablissementDto, pageNumber, pageSize, options)(this.fetch, this.basePath);
    }

}

/**
 * InfoControllerApi - fetch parameter creator
 * @export
 */
export const InfoControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/api/info/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InfoControllerApi - functional programming interface
 * @export
 */
export const InfoControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InfoDto> {
            const localVarFetchArgs = InfoControllerApiFetchParamCreator(configuration).getInfoUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * InfoControllerApi - factory interface
 * @export
 */
export const InfoControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getInfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoUsingGET(options?: any) {
            return InfoControllerApiFp(configuration).getInfoUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * InfoControllerApi - object-oriented interface
 * @export
 * @class InfoControllerApi
 * @extends {BaseAPI}
 */
export class InfoControllerApi extends BaseAPI {
    /**
     * 
     * @summary getInfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InfoControllerApi
     */
    public getInfoUsingGET(options?: any) {
        return InfoControllerApiFp(this.configuration).getInfoUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * PurgeControllerApi - fetch parameter creator
 * @export
 */
export const PurgeControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countNbDeclarationsToPurge
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNbDeclarationsToPurgeUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling countNbDeclarationsToPurgeUsingGET.');
            }
            const localVarPath = `/declarations/purge/count`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailableToPurge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableToPurgeUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/declarations/purge/availableYears`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary markDeclarationAsDeleted
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDeclarationAsDeletedUsingPUT(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling markDeclarationAsDeletedUsingPUT.');
            }
            const localVarPath = `/declarations/purge`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (annee !== undefined) {
                localVarQueryParameter['annee'] = annee;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurgeControllerApi - functional programming interface
 * @export
 */
export const PurgeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countNbDeclarationsToPurge
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNbDeclarationsToPurgeUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = PurgeControllerApiFetchParamCreator(configuration).countNbDeclarationsToPurgeUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailableToPurge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableToPurgeUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<number>> {
            const localVarFetchArgs = PurgeControllerApiFetchParamCreator(configuration).getDeclarationsYearsAvailableToPurgeUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary markDeclarationAsDeleted
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDeclarationAsDeletedUsingPUT(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PurgeControllerApiFetchParamCreator(configuration).markDeclarationAsDeletedUsingPUT(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PurgeControllerApi - factory interface
 * @export
 */
export const PurgeControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary countNbDeclarationsToPurge
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countNbDeclarationsToPurgeUsingGET(annee: number, options?: any) {
            return PurgeControllerApiFp(configuration).countNbDeclarationsToPurgeUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeclarationsYearsAvailableToPurge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationsYearsAvailableToPurgeUsingGET(options?: any) {
            return PurgeControllerApiFp(configuration).getDeclarationsYearsAvailableToPurgeUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary markDeclarationAsDeleted
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markDeclarationAsDeletedUsingPUT(annee: number, options?: any) {
            return PurgeControllerApiFp(configuration).markDeclarationAsDeletedUsingPUT(annee, options)(fetch, basePath);
        },
    };
};

/**
 * PurgeControllerApi - object-oriented interface
 * @export
 * @class PurgeControllerApi
 * @extends {BaseAPI}
 */
export class PurgeControllerApi extends BaseAPI {
    /**
     * 
     * @summary countNbDeclarationsToPurge
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurgeControllerApi
     */
    public countNbDeclarationsToPurgeUsingGET(annee: number, options?: any) {
        return PurgeControllerApiFp(this.configuration).countNbDeclarationsToPurgeUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeclarationsYearsAvailableToPurge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurgeControllerApi
     */
    public getDeclarationsYearsAvailableToPurgeUsingGET(options?: any) {
        return PurgeControllerApiFp(this.configuration).getDeclarationsYearsAvailableToPurgeUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary markDeclarationAsDeleted
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurgeControllerApi
     */
    public markDeclarationAsDeletedUsingPUT(annee: number, options?: any) {
        return PurgeControllerApiFp(this.configuration).markDeclarationAsDeletedUsingPUT(annee, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceAdminControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceAdminControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAdminEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEmailUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/admin/references/email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceAdminControllerApi - functional programming interface
 * @export
 */
export const ReferenceAdminControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getAdminEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEmailUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AdminEmailDto> {
            const localVarFetchArgs = ReferenceAdminControllerApiFetchParamCreator(configuration).getAdminEmailUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceAdminControllerApi - factory interface
 * @export
 */
export const ReferenceAdminControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getAdminEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdminEmailUsingGET(options?: any) {
            return ReferenceAdminControllerApiFp(configuration).getAdminEmailUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceAdminControllerApi - object-oriented interface
 * @export
 * @class ReferenceAdminControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceAdminControllerApi extends BaseAPI {
    /**
     * 
     * @summary getAdminEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAdminControllerApi
     */
    public getAdminEmailUsingGET(options?: any) {
        return ReferenceAdminControllerApiFp(this.configuration).getAdminEmailUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceAirControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceAirControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadCombustiblesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCombustiblesAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/combustibles/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadGazAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGazAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/gaz/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadNormesAirAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesAirAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllCombustiblesForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCombustiblesForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllCombustiblesForCampaignUsingGET.');
            }
            const localVarPath = `/air/references/combustibles/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllGazForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGazForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllGazForCampaignUsingGET.');
            }
            const localVarPath = `/air/references/gaz/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllNormesAirForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesAirForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllNormesAirForCampaignUsingGET.');
            }
            const localVarPath = `/air/references/normes/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllPolluantDefaultUnite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantDefaultUniteUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/polluant/defaultUnite/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllPolluantElement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantElementUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/polluantElement`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getElementAutre
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementAutreUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/air/references/elementAutre`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadCombustibles
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCombustiblesUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/air/references/combustibles/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadGaz
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGazUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/air/references/gaz/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadNormesAir
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesAirUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/air/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceAirControllerApi - functional programming interface
 * @export
 */
export const ReferenceAirControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadCombustiblesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCombustiblesAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).downloadCombustiblesAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadGazAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGazAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).downloadGazAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadNormesAirAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesAirAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).downloadNormesAirAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllCombustiblesForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCombustiblesForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceCombustibleDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getAllCombustiblesForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllGazForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGazForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceGazDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getAllGazForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllNormesAirForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesAirForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceNormeAirDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getAllNormesAirForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllPolluantDefaultUnite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantDefaultUniteUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferencePolluantDefaultUniteDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getAllPolluantDefaultUniteUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllPolluantElement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantElementUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferencePolluantElementDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getAllPolluantElementUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getElementAutre
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementAutreUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemElementIndexDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).getElementAutreUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadCombustibles
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCombustiblesUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).uploadCombustiblesUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadGaz
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGazUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).uploadGazUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadNormesAir
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesAirUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceAirControllerApiFetchParamCreator(configuration).uploadNormesAirUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceAirControllerApi - factory interface
 * @export
 */
export const ReferenceAirControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadCombustiblesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCombustiblesAsCsvUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).downloadCombustiblesAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadGazAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGazAsCsvUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).downloadGazAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadNormesAirAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesAirAsCsvUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).downloadNormesAirAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllCombustiblesForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCombustiblesForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceAirControllerApiFp(configuration).getAllCombustiblesForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllGazForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGazForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceAirControllerApiFp(configuration).getAllGazForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllNormesAirForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesAirForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceAirControllerApiFp(configuration).getAllNormesAirForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllPolluantDefaultUnite
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantDefaultUniteUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).getAllPolluantDefaultUniteUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllPolluantElement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantElementUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).getAllPolluantElementUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getElementAutre
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getElementAutreUsingGET(options?: any) {
            return ReferenceAirControllerApiFp(configuration).getElementAutreUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadCombustibles
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCombustiblesUsingPOST(file?: any, options?: any) {
            return ReferenceAirControllerApiFp(configuration).uploadCombustiblesUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadGaz
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGazUsingPOST(file?: any, options?: any) {
            return ReferenceAirControllerApiFp(configuration).uploadGazUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadNormesAir
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesAirUsingPOST(file?: any, options?: any) {
            return ReferenceAirControllerApiFp(configuration).uploadNormesAirUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceAirControllerApi - object-oriented interface
 * @export
 * @class ReferenceAirControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceAirControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadCombustiblesAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public downloadCombustiblesAsCsvUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).downloadCombustiblesAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadGazAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public downloadGazAsCsvUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).downloadGazAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadNormesAirAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public downloadNormesAirAsCsvUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).downloadNormesAirAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllCombustiblesForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getAllCombustiblesForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getAllCombustiblesForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllGazForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getAllGazForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getAllGazForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllNormesAirForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getAllNormesAirForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getAllNormesAirForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllPolluantDefaultUnite
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getAllPolluantDefaultUniteUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getAllPolluantDefaultUniteUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllPolluantElement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getAllPolluantElementUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getAllPolluantElementUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getElementAutre
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public getElementAutreUsingGET(options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).getElementAutreUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadCombustibles
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public uploadCombustiblesUsingPOST(file?: any, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).uploadCombustiblesUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadGaz
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public uploadGazUsingPOST(file?: any, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).uploadGazUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadNormesAir
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceAirControllerApi
     */
    public uploadNormesAirUsingPOST(file?: any, options?: any) {
        return ReferenceAirControllerApiFp(this.configuration).uploadNormesAirUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceCarriereControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceCarriereControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadOrganismeAccrediteAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeAccrediteAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/organisme_accredite_intervenant/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadOrganismeExterieurPreventionAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeExterieurPreventionAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/organisme_exterieur_prevention/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadSubstanceCarriereAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSubstanceCarriereAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/substance_carriere/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageCodeProduit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageCodeProduitUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/famille_usage_code_produit`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageProductionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageProductionForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllFamilleUsageProductionForCampaignUsingGET.');
            }
            const localVarPath = `/carrieres/references/famille_usage_production/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageRecyclageForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageRecyclageForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllFamilleUsageRecyclageForCampaignUsingGET.');
            }
            const localVarPath = `/carrieres/references/famille_usage_recyclage/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllOrganismeAccrediteForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeAccrediteForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllOrganismeAccrediteForCampaignUsingGET.');
            }
            const localVarPath = `/carrieres/references/organisme_accredite_intervenant/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllOrganismeExterieurPreventionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeExterieurPreventionForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllOrganismeExterieurPreventionForCampaignUsingGET.');
            }
            const localVarPath = `/carrieres/references/organisme_exterieur_prevention/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllSubstanceCarriereForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubstanceCarriereForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllSubstanceCarriereForCampaignUsingGET.');
            }
            const localVarPath = `/carrieres/references/substance_carriere/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCausesAccident
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCausesAccidentUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/causes_accident`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadOrganismeAccredite
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeAccrediteUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/organisme_accredite_intervenant/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadOrganismeExterieurPrevention
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeExterieurPreventionUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/organisme_exterieur_prevention/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadSubstanceCarriere
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubstanceCarriereUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/carrieres/references/substance_carriere/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceCarriereControllerApi - functional programming interface
 * @export
 */
export const ReferenceCarriereControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadOrganismeAccrediteAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeAccrediteAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).downloadOrganismeAccrediteAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadOrganismeExterieurPreventionAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeExterieurPreventionAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).downloadOrganismeExterieurPreventionAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadSubstanceCarriereAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSubstanceCarriereAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).downloadSubstanceCarriereAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageCodeProduit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageCodeProduitUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceFamilleUsageCodeProduitDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllFamilleUsageCodeProduitUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageProductionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageProductionForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceFamilleUsageProductionDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllFamilleUsageProductionForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllFamilleUsageRecyclageForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageRecyclageForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceFamilleUsageRecyclageDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllFamilleUsageRecyclageForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllOrganismeAccrediteForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeAccrediteForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceOrganismeAccrediteDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllOrganismeAccrediteForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllOrganismeExterieurPreventionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeExterieurPreventionForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceOrganismeExterieurPreventionDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllOrganismeExterieurPreventionForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllSubstanceCarriereForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubstanceCarriereForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceSubstanceCarriereDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getAllSubstanceCarriereForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCausesAccident
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCausesAccidentUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemCarriereAccidentDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).getCausesAccidentUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadOrganismeAccredite
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeAccrediteUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).uploadOrganismeAccrediteUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadOrganismeExterieurPrevention
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeExterieurPreventionUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).uploadOrganismeExterieurPreventionUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadSubstanceCarriere
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubstanceCarriereUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceCarriereControllerApiFetchParamCreator(configuration).uploadSubstanceCarriereUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceCarriereControllerApi - factory interface
 * @export
 */
export const ReferenceCarriereControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadOrganismeAccrediteAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeAccrediteAsCsvUsingGET(options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).downloadOrganismeAccrediteAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadOrganismeExterieurPreventionAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOrganismeExterieurPreventionAsCsvUsingGET(options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).downloadOrganismeExterieurPreventionAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadSubstanceCarriereAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSubstanceCarriereAsCsvUsingGET(options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).downloadSubstanceCarriereAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllFamilleUsageCodeProduit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageCodeProduitUsingGET(options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllFamilleUsageCodeProduitUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllFamilleUsageProductionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageProductionForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllFamilleUsageProductionForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllFamilleUsageRecyclageForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFamilleUsageRecyclageForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllFamilleUsageRecyclageForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllOrganismeAccrediteForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeAccrediteForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllOrganismeAccrediteForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllOrganismeExterieurPreventionForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganismeExterieurPreventionForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllOrganismeExterieurPreventionForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllSubstanceCarriereForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubstanceCarriereForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getAllSubstanceCarriereForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCausesAccident
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCausesAccidentUsingGET(options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).getCausesAccidentUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadOrganismeAccredite
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeAccrediteUsingPOST(file?: any, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).uploadOrganismeAccrediteUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadOrganismeExterieurPrevention
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadOrganismeExterieurPreventionUsingPOST(file?: any, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).uploadOrganismeExterieurPreventionUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadSubstanceCarriere
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSubstanceCarriereUsingPOST(file?: any, options?: any) {
            return ReferenceCarriereControllerApiFp(configuration).uploadSubstanceCarriereUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceCarriereControllerApi - object-oriented interface
 * @export
 * @class ReferenceCarriereControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceCarriereControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadOrganismeAccrediteAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public downloadOrganismeAccrediteAsCsvUsingGET(options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).downloadOrganismeAccrediteAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadOrganismeExterieurPreventionAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public downloadOrganismeExterieurPreventionAsCsvUsingGET(options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).downloadOrganismeExterieurPreventionAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadSubstanceCarriereAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public downloadSubstanceCarriereAsCsvUsingGET(options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).downloadSubstanceCarriereAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllFamilleUsageCodeProduit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllFamilleUsageCodeProduitUsingGET(options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllFamilleUsageCodeProduitUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllFamilleUsageProductionForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllFamilleUsageProductionForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllFamilleUsageProductionForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllFamilleUsageRecyclageForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllFamilleUsageRecyclageForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllFamilleUsageRecyclageForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllOrganismeAccrediteForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllOrganismeAccrediteForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllOrganismeAccrediteForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllOrganismeExterieurPreventionForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllOrganismeExterieurPreventionForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllOrganismeExterieurPreventionForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllSubstanceCarriereForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getAllSubstanceCarriereForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getAllSubstanceCarriereForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCausesAccident
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public getCausesAccidentUsingGET(options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).getCausesAccidentUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadOrganismeAccredite
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public uploadOrganismeAccrediteUsingPOST(file?: any, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).uploadOrganismeAccrediteUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadOrganismeExterieurPrevention
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public uploadOrganismeExterieurPreventionUsingPOST(file?: any, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).uploadOrganismeExterieurPreventionUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadSubstanceCarriere
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceCarriereControllerApi
     */
    public uploadSubstanceCarriereUsingPOST(file?: any, options?: any) {
        return ReferenceCarriereControllerApiFp(this.configuration).uploadSubstanceCarriereUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceDechetControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceDechetControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadDechetsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDechetsAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/dechets/references/dechets/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDechetsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDechetsForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllDechetsForCampaignUsingGET.');
            }
            const localVarPath = `/dechets/references/dechets/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadDechets
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDechetsUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/dechets/references/dechets/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceDechetControllerApi - functional programming interface
 * @export
 */
export const ReferenceDechetControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadDechetsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDechetsAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceDechetControllerApiFetchParamCreator(configuration).downloadDechetsAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllDechetsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDechetsForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDechetDto> {
            const localVarFetchArgs = ReferenceDechetControllerApiFetchParamCreator(configuration).getAllDechetsForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadDechets
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDechetsUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceDechetControllerApiFetchParamCreator(configuration).uploadDechetsUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceDechetControllerApi - factory interface
 * @export
 */
export const ReferenceDechetControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadDechetsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDechetsAsCsvUsingGET(options?: any) {
            return ReferenceDechetControllerApiFp(configuration).downloadDechetsAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllDechetsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDechetsForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceDechetControllerApiFp(configuration).getAllDechetsForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadDechets
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDechetsUsingPOST(file?: any, options?: any) {
            return ReferenceDechetControllerApiFp(configuration).uploadDechetsUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceDechetControllerApi - object-oriented interface
 * @export
 * @class ReferenceDechetControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceDechetControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadDechetsAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDechetControllerApi
     */
    public downloadDechetsAsCsvUsingGET(options?: any) {
        return ReferenceDechetControllerApiFp(this.configuration).downloadDechetsAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllDechetsForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDechetControllerApi
     */
    public getAllDechetsForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceDechetControllerApiFp(this.configuration).getAllDechetsForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadDechets
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceDechetControllerApi
     */
    public uploadDechetsUsingPOST(file?: any, options?: any) {
        return ReferenceDechetControllerApiFp(this.configuration).uploadDechetsUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceEauControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceEauControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadNormesEauAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesEauAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadSandreAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSandreAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/sandre/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadStationEpurationAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStationEpurationAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/stations_epuration/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllNormesEauForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesEauForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllNormesEauForCampaignUsingGET.');
            }
            const localVarPath = `/eau/references/normes/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllSandreForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSandreForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllSandreForCampaignUsingGET.');
            }
            const localVarPath = `/eau/references/sandre/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllStationEpurationForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStationEpurationForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllStationEpurationForCampaignUsingGET.');
            }
            const localVarPath = `/eau/references/stations_epuration/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadNormesEau
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesEauUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadSandre
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSandreUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/sandre/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadStationEpuration
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStationEpurationUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/eau/references/stations_epuration/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceEauControllerApi - functional programming interface
 * @export
 */
export const ReferenceEauControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadNormesEauAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesEauAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).downloadNormesEauAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadSandreAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSandreAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).downloadSandreAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadStationEpurationAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStationEpurationAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).downloadStationEpurationAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllNormesEauForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesEauForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceNormeEauDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).getAllNormesEauForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllSandreForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSandreForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceSandreDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).getAllSandreForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllStationEpurationForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStationEpurationForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceStationEpurationDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).getAllStationEpurationForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadNormesEau
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesEauUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).uploadNormesEauUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadSandre
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSandreUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).uploadSandreUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadStationEpuration
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStationEpurationUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceEauControllerApiFetchParamCreator(configuration).uploadStationEpurationUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceEauControllerApi - factory interface
 * @export
 */
export const ReferenceEauControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadNormesEauAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesEauAsCsvUsingGET(options?: any) {
            return ReferenceEauControllerApiFp(configuration).downloadNormesEauAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadSandreAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSandreAsCsvUsingGET(options?: any) {
            return ReferenceEauControllerApiFp(configuration).downloadSandreAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadStationEpurationAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadStationEpurationAsCsvUsingGET(options?: any) {
            return ReferenceEauControllerApiFp(configuration).downloadStationEpurationAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllNormesEauForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesEauForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceEauControllerApiFp(configuration).getAllNormesEauForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllSandreForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSandreForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceEauControllerApiFp(configuration).getAllSandreForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllStationEpurationForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStationEpurationForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceEauControllerApiFp(configuration).getAllStationEpurationForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadNormesEau
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesEauUsingPOST(file?: any, options?: any) {
            return ReferenceEauControllerApiFp(configuration).uploadNormesEauUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadSandre
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadSandreUsingPOST(file?: any, options?: any) {
            return ReferenceEauControllerApiFp(configuration).uploadSandreUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadStationEpuration
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStationEpurationUsingPOST(file?: any, options?: any) {
            return ReferenceEauControllerApiFp(configuration).uploadStationEpurationUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceEauControllerApi - object-oriented interface
 * @export
 * @class ReferenceEauControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceEauControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadNormesEauAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public downloadNormesEauAsCsvUsingGET(options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).downloadNormesEauAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadSandreAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public downloadSandreAsCsvUsingGET(options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).downloadSandreAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadStationEpurationAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public downloadStationEpurationAsCsvUsingGET(options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).downloadStationEpurationAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllNormesEauForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public getAllNormesEauForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).getAllNormesEauForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllSandreForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public getAllSandreForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).getAllSandreForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllStationEpurationForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public getAllStationEpurationForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).getAllStationEpurationForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadNormesEau
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public uploadNormesEauUsingPOST(file?: any, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).uploadNormesEauUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadSandre
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public uploadSandreUsingPOST(file?: any, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).uploadSandreUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadStationEpuration
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEauControllerApi
     */
    public uploadStationEpurationUsingPOST(file?: any, options?: any) {
        return ReferenceEauControllerApiFp(this.configuration).uploadStationEpurationUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceEmailsDestinatairesControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceEmailsDestinatairesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadEmailsDestinatairesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEmailsDestinatairesAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/emails_destinataires/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadEmailsDestinataires
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmailsDestinatairesUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/emails_destinataires/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceEmailsDestinatairesControllerApi - functional programming interface
 * @export
 */
export const ReferenceEmailsDestinatairesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadEmailsDestinatairesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEmailsDestinatairesAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceEmailsDestinatairesControllerApiFetchParamCreator(configuration).downloadEmailsDestinatairesAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadEmailsDestinataires
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmailsDestinatairesUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceEmailsDestinatairesControllerApiFetchParamCreator(configuration).uploadEmailsDestinatairesUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceEmailsDestinatairesControllerApi - factory interface
 * @export
 */
export const ReferenceEmailsDestinatairesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadEmailsDestinatairesAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEmailsDestinatairesAsCsvUsingGET(options?: any) {
            return ReferenceEmailsDestinatairesControllerApiFp(configuration).downloadEmailsDestinatairesAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadEmailsDestinataires
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEmailsDestinatairesUsingPOST(file?: any, options?: any) {
            return ReferenceEmailsDestinatairesControllerApiFp(configuration).uploadEmailsDestinatairesUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceEmailsDestinatairesControllerApi - object-oriented interface
 * @export
 * @class ReferenceEmailsDestinatairesControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceEmailsDestinatairesControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadEmailsDestinatairesAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEmailsDestinatairesControllerApi
     */
    public downloadEmailsDestinatairesAsCsvUsingGET(options?: any) {
        return ReferenceEmailsDestinatairesControllerApiFp(this.configuration).downloadEmailsDestinatairesAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadEmailsDestinataires
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceEmailsDestinatairesControllerApi
     */
    public uploadEmailsDestinatairesUsingPOST(file?: any, options?: any) {
        return ReferenceEmailsDestinatairesControllerApiFp(this.configuration).uploadEmailsDestinatairesUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceInfosGeneralesControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceInfosGeneralesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadCodeApeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCodeApeAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/codes_ape/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadDepartementsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDepartementsAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/departements/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadEprtrAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEprtrAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/eprtr/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadGeoCodeInseeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeoCodeInseeAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/codes_insee/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary downloadRegionsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegionsAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/region/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllCodeApeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCodeApeForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllCodeApeForCampaignUsingGET.');
            }
            const localVarPath = `/infos_generales/references/codes_ape/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDepartements
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartementsUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllDepartementsUsingGET.');
            }
            const localVarPath = `/infos_generales/references/departements/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllEprtrElevage
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrElevageUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllEprtrElevageUsingGET.');
            }
            const localVarPath = `/infos_generales/references/eleveur_eprtr/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllEprtrForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllEprtrForCampaignUsingGET.');
            }
            const localVarPath = `/infos_generales/references/eprtr/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllEquipesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipesInspectionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/equipes_inspection/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllGeoCodeInseeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeoCodeInseeForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllGeoCodeInseeForCampaignUsingGET.');
            }
            const localVarPath = `/infos_generales/references/codes_insee/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllRegionsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegionsForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllRegionsForCampaignUsingGET.');
            }
            const localVarPath = `/infos_generales/references/region/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllServicesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServicesInspectionUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/services_inspection/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCoordonneesDepartement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoordonneesDepartementUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/coordonnees_departements/all`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeclarationAiotStatus
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationAiotStatusUsingGET(annee: number, codeEtablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getDeclarationAiotStatusUsingGET.');
            }
            // verify required parameter 'codeEtablissement' is not null or undefined
            if (codeEtablissement === null || codeEtablissement === undefined) {
                throw new RequiredError('codeEtablissement','Required parameter codeEtablissement was null or undefined when calling getDeclarationAiotStatusUsingGET.');
            }
            const localVarPath = `/infos_generales/references/gun_aiot_services/{codeEtablissement}/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"codeEtablissement"}}`, encodeURIComponent(String(codeEtablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getHasUpdateFailToday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHasUpdateFailTodayUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/gun_aiots_services`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrganismesInspectionAllowedAtDeclarationCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/organismes_inspection/allowed_at_declaration_creation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadCodeApe
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCodeApeUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/codes_ape/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadDepartements
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDepartementsUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/departements/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadEprtr
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEprtrUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/eprtr/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadGeoCodeInsee
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGeoCodeInseeUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/codes_insee/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadRegions
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRegionsUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/region/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceInfosGeneralesControllerApi - functional programming interface
 * @export
 */
export const ReferenceInfosGeneralesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadCodeApeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCodeApeAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).downloadCodeApeAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadDepartementsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDepartementsAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).downloadDepartementsAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadEprtrAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEprtrAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).downloadEprtrAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadGeoCodeInseeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeoCodeInseeAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).downloadGeoCodeInseeAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary downloadRegionsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegionsAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).downloadRegionsAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllCodeApeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCodeApeForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceCodeApeDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllCodeApeForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllDepartements
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartementsUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceDepartementDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllDepartementsUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllEprtrElevage
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrElevageUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceEprtrDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllEprtrElevageUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllEprtrForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceEprtrDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllEprtrForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllEquipesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipesInspectionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceEquipeGunDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllEquipesInspectionUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllGeoCodeInseeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeoCodeInseeForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceGeoCodeInseeDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllGeoCodeInseeForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllRegionsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegionsForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceRegionDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllRegionsForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllServicesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServicesInspectionUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceServiceGunDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getAllServicesInspectionUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCoordonneesDepartement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoordonneesDepartementUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceCoordonneesDepartementDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getCoordonneesDepartementUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeclarationAiotStatus
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationAiotStatusUsingGET(annee: number, codeEtablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getDeclarationAiotStatusUsingGET(annee, codeEtablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getHasUpdateFailToday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHasUpdateFailTodayUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getHasUpdateFailTodayUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrganismesInspectionAllowedAtDeclarationCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceNatureServiceDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadCodeApe
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCodeApeUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).uploadCodeApeUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadDepartements
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDepartementsUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).uploadDepartementsUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadEprtr
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEprtrUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).uploadEprtrUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadGeoCodeInsee
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGeoCodeInseeUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).uploadGeoCodeInseeUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadRegions
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRegionsUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInfosGeneralesControllerApiFetchParamCreator(configuration).uploadRegionsUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceInfosGeneralesControllerApi - factory interface
 * @export
 */
export const ReferenceInfosGeneralesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadCodeApeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadCodeApeAsCsvUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).downloadCodeApeAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadDepartementsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDepartementsAsCsvUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).downloadDepartementsAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadEprtrAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadEprtrAsCsvUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).downloadEprtrAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadGeoCodeInseeAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadGeoCodeInseeAsCsvUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).downloadGeoCodeInseeAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary downloadRegionsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRegionsAsCsvUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).downloadRegionsAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllCodeApeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCodeApeForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllCodeApeForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllDepartements
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartementsUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllDepartementsUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllEprtrElevage
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrElevageUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllEprtrElevageUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllEprtrForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEprtrForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllEprtrForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllEquipesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipesInspectionUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllEquipesInspectionUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllGeoCodeInseeForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllGeoCodeInseeForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllGeoCodeInseeForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllRegionsForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRegionsForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllRegionsForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllServicesInspection
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllServicesInspectionUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getAllServicesInspectionUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCoordonneesDepartement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoordonneesDepartementUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getCoordonneesDepartementUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeclarationAiotStatus
         * @param {number} annee annee
         * @param {string} codeEtablissement codeEtablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeclarationAiotStatusUsingGET(annee: number, codeEtablissement: string, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getDeclarationAiotStatusUsingGET(annee, codeEtablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getHasUpdateFailToday
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHasUpdateFailTodayUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getHasUpdateFailTodayUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrganismesInspectionAllowedAtDeclarationCreation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadCodeApe
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadCodeApeUsingPOST(file?: any, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).uploadCodeApeUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadDepartements
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDepartementsUsingPOST(file?: any, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).uploadDepartementsUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadEprtr
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadEprtrUsingPOST(file?: any, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).uploadEprtrUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadGeoCodeInsee
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadGeoCodeInseeUsingPOST(file?: any, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).uploadGeoCodeInseeUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadRegions
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadRegionsUsingPOST(file?: any, options?: any) {
            return ReferenceInfosGeneralesControllerApiFp(configuration).uploadRegionsUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceInfosGeneralesControllerApi - object-oriented interface
 * @export
 * @class ReferenceInfosGeneralesControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceInfosGeneralesControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadCodeApeAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public downloadCodeApeAsCsvUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).downloadCodeApeAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadDepartementsAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public downloadDepartementsAsCsvUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).downloadDepartementsAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadEprtrAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public downloadEprtrAsCsvUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).downloadEprtrAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadGeoCodeInseeAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public downloadGeoCodeInseeAsCsvUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).downloadGeoCodeInseeAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary downloadRegionsAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public downloadRegionsAsCsvUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).downloadRegionsAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllCodeApeForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllCodeApeForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllCodeApeForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllDepartements
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllDepartementsUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllDepartementsUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllEprtrElevage
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllEprtrElevageUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllEprtrElevageUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllEprtrForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllEprtrForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllEprtrForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllEquipesInspection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllEquipesInspectionUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllEquipesInspectionUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllGeoCodeInseeForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllGeoCodeInseeForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllGeoCodeInseeForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllRegionsForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllRegionsForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllRegionsForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllServicesInspection
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getAllServicesInspectionUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getAllServicesInspectionUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCoordonneesDepartement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getCoordonneesDepartementUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getCoordonneesDepartementUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeclarationAiotStatus
     * @param {number} annee annee
     * @param {string} codeEtablissement codeEtablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getDeclarationAiotStatusUsingGET(annee: number, codeEtablissement: string, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getDeclarationAiotStatusUsingGET(annee, codeEtablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getHasUpdateFailToday
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getHasUpdateFailTodayUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getHasUpdateFailTodayUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrganismesInspectionAllowedAtDeclarationCreation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadCodeApe
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public uploadCodeApeUsingPOST(file?: any, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).uploadCodeApeUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadDepartements
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public uploadDepartementsUsingPOST(file?: any, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).uploadDepartementsUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadEprtr
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public uploadEprtrUsingPOST(file?: any, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).uploadEprtrUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadGeoCodeInsee
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public uploadGeoCodeInseeUsingPOST(file?: any, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).uploadGeoCodeInseeUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadRegions
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInfosGeneralesControllerApi
     */
    public uploadRegionsUsingPOST(file?: any, options?: any) {
        return ReferenceInfosGeneralesControllerApiFp(this.configuration).uploadRegionsUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceInspecteursEmailQuotasControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceInspecteursEmailQuotasControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadInspecteursEmailQuotasAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInspecteursEmailQuotasAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/inspecteurs/email/quotas/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadInspecteursEmailQuotas
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInspecteursEmailQuotasUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/inspecteurs/email/quotas/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceInspecteursEmailQuotasControllerApi - functional programming interface
 * @export
 */
export const ReferenceInspecteursEmailQuotasControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadInspecteursEmailQuotasAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInspecteursEmailQuotasAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceInspecteursEmailQuotasControllerApiFetchParamCreator(configuration).downloadInspecteursEmailQuotasAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadInspecteursEmailQuotas
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInspecteursEmailQuotasUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceInspecteursEmailQuotasControllerApiFetchParamCreator(configuration).uploadInspecteursEmailQuotasUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceInspecteursEmailQuotasControllerApi - factory interface
 * @export
 */
export const ReferenceInspecteursEmailQuotasControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadInspecteursEmailQuotasAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInspecteursEmailQuotasAsCsvUsingGET(options?: any) {
            return ReferenceInspecteursEmailQuotasControllerApiFp(configuration).downloadInspecteursEmailQuotasAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadInspecteursEmailQuotas
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInspecteursEmailQuotasUsingPOST(file?: any, options?: any) {
            return ReferenceInspecteursEmailQuotasControllerApiFp(configuration).uploadInspecteursEmailQuotasUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceInspecteursEmailQuotasControllerApi - object-oriented interface
 * @export
 * @class ReferenceInspecteursEmailQuotasControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceInspecteursEmailQuotasControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadInspecteursEmailQuotasAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInspecteursEmailQuotasControllerApi
     */
    public downloadInspecteursEmailQuotasAsCsvUsingGET(options?: any) {
        return ReferenceInspecteursEmailQuotasControllerApiFp(this.configuration).downloadInspecteursEmailQuotasAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadInspecteursEmailQuotas
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceInspecteursEmailQuotasControllerApi
     */
    public uploadInspecteursEmailQuotasUsingPOST(file?: any, options?: any) {
        return ReferenceInspecteursEmailQuotasControllerApiFp(this.configuration).uploadInspecteursEmailQuotasUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferencePaysControllerApi - fetch parameter creator
 * @export
 */
export const ReferencePaysControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPaysAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPaysAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/pays/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllPaysDtoForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaysDtoForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllPaysDtoForCampaignUsingGET.');
            }
            const localVarPath = `/infos_generales/references/pays/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPays
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPaysUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/infos_generales/references/pays/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferencePaysControllerApi - functional programming interface
 * @export
 */
export const ReferencePaysControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPaysAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPaysAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferencePaysControllerApiFetchParamCreator(configuration).downloadPaysAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllPaysDtoForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaysDtoForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferencePaysDto> {
            const localVarFetchArgs = ReferencePaysControllerApiFetchParamCreator(configuration).getAllPaysDtoForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPays
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPaysUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferencePaysControllerApiFetchParamCreator(configuration).uploadPaysUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferencePaysControllerApi - factory interface
 * @export
 */
export const ReferencePaysControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadPaysAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPaysAsCsvUsingGET(options?: any) {
            return ReferencePaysControllerApiFp(configuration).downloadPaysAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllPaysDtoForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPaysDtoForCampaignUsingGET(annee: number, options?: any) {
            return ReferencePaysControllerApiFp(configuration).getAllPaysDtoForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPays
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPaysUsingPOST(file?: any, options?: any) {
            return ReferencePaysControllerApiFp(configuration).uploadPaysUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferencePaysControllerApi - object-oriented interface
 * @export
 * @class ReferencePaysControllerApi
 * @extends {BaseAPI}
 */
export class ReferencePaysControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadPaysAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencePaysControllerApi
     */
    public downloadPaysAsCsvUsingGET(options?: any) {
        return ReferencePaysControllerApiFp(this.configuration).downloadPaysAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllPaysDtoForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencePaysControllerApi
     */
    public getAllPaysDtoForCampaignUsingGET(annee: number, options?: any) {
        return ReferencePaysControllerApiFp(this.configuration).getAllPaysDtoForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPays
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencePaysControllerApi
     */
    public uploadPaysUsingPOST(file?: any, options?: any) {
        return ReferencePaysControllerApiFp(this.configuration).uploadPaysUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferencePostCampaignEmailControllerApi - fetch parameter creator
 * @export
 */
export const ReferencePostCampaignEmailControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPostCampaignEmailAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPostCampaignEmailAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/post_campaign_email/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPostCampaignEmails
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPostCampaignEmailsUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/post_campaign_email/references`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferencePostCampaignEmailControllerApi - functional programming interface
 * @export
 */
export const ReferencePostCampaignEmailControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPostCampaignEmailAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPostCampaignEmailAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferencePostCampaignEmailControllerApiFetchParamCreator(configuration).downloadPostCampaignEmailAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPostCampaignEmails
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPostCampaignEmailsUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferencePostCampaignEmailControllerApiFetchParamCreator(configuration).uploadPostCampaignEmailsUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferencePostCampaignEmailControllerApi - factory interface
 * @export
 */
export const ReferencePostCampaignEmailControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadPostCampaignEmailAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPostCampaignEmailAsCsvUsingGET(options?: any) {
            return ReferencePostCampaignEmailControllerApiFp(configuration).downloadPostCampaignEmailAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPostCampaignEmails
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPostCampaignEmailsUsingPOST(file?: any, options?: any) {
            return ReferencePostCampaignEmailControllerApiFp(configuration).uploadPostCampaignEmailsUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferencePostCampaignEmailControllerApi - object-oriented interface
 * @export
 * @class ReferencePostCampaignEmailControllerApi
 * @extends {BaseAPI}
 */
export class ReferencePostCampaignEmailControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadPostCampaignEmailAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencePostCampaignEmailControllerApi
     */
    public downloadPostCampaignEmailAsCsvUsingGET(options?: any) {
        return ReferencePostCampaignEmailControllerApiFp(this.configuration).downloadPostCampaignEmailAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPostCampaignEmails
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferencePostCampaignEmailControllerApi
     */
    public uploadPostCampaignEmailsUsingPOST(file?: any, options?: any) {
        return ReferencePostCampaignEmailControllerApiFp(this.configuration).uploadPostCampaignEmailsUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceQuotasControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceQuotasControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadActiviteQuotaAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActiviteQuotaAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/quotas/references/activites/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllActiviteQuotaForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiviteQuotaForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllActiviteQuotaForCampaignUsingGET.');
            }
            const localVarPath = `/quotas/references/activites/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadActiviteQuota
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActiviteQuotaUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/quotas/references/activites/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceQuotasControllerApi - functional programming interface
 * @export
 */
export const ReferenceQuotasControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadActiviteQuotaAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActiviteQuotaAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceQuotasControllerApiFetchParamCreator(configuration).downloadActiviteQuotaAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllActiviteQuotaForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiviteQuotaForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceActiviteQuotaDto> {
            const localVarFetchArgs = ReferenceQuotasControllerApiFetchParamCreator(configuration).getAllActiviteQuotaForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadActiviteQuota
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActiviteQuotaUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceQuotasControllerApiFetchParamCreator(configuration).uploadActiviteQuotaUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceQuotasControllerApi - factory interface
 * @export
 */
export const ReferenceQuotasControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadActiviteQuotaAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadActiviteQuotaAsCsvUsingGET(options?: any) {
            return ReferenceQuotasControllerApiFp(configuration).downloadActiviteQuotaAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllActiviteQuotaForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActiviteQuotaForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceQuotasControllerApiFp(configuration).getAllActiviteQuotaForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadActiviteQuota
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadActiviteQuotaUsingPOST(file?: any, options?: any) {
            return ReferenceQuotasControllerApiFp(configuration).uploadActiviteQuotaUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceQuotasControllerApi - object-oriented interface
 * @export
 * @class ReferenceQuotasControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceQuotasControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadActiviteQuotaAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceQuotasControllerApi
     */
    public downloadActiviteQuotaAsCsvUsingGET(options?: any) {
        return ReferenceQuotasControllerApiFp(this.configuration).downloadActiviteQuotaAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllActiviteQuotaForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceQuotasControllerApi
     */
    public getAllActiviteQuotaForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceQuotasControllerApiFp(this.configuration).getAllActiviteQuotaForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadActiviteQuota
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceQuotasControllerApi
     */
    public uploadActiviteQuotaUsingPOST(file?: any, options?: any) {
        return ReferenceQuotasControllerApiFp(this.configuration).uploadActiviteQuotaUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceSectionControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceSectionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPolluantsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPolluantsAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/{section}/references/polluants/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllPolluantsForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantsForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllPolluantsForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getAllPolluantsForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/polluants/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCo2ForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCo2ForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getCo2ForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getCo2ForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/co2/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCovnmForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCovnmForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getCovnmForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getCovnmForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/covnm/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getHfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getHfcForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getHfcForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/hfc/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getNoxForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getNoxForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/nox/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getPfcForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getPfcForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/pfc/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getSoxForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getSoxForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/sox/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSpfoForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpfoForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getSpfoForCampaignUsingGET.');
            }
            // verify required parameter 'section' is not null or undefined
            if (section === null || section === undefined) {
                throw new RequiredError('section','Required parameter section was null or undefined when calling getSpfoForCampaignUsingGET.');
            }
            const localVarPath = `/{section}/references/spfo/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"section"}}`, encodeURIComponent(String(section)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadPolluants
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPolluantsUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/{section}/references/polluants/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceSectionControllerApi - functional programming interface
 * @export
 */
export const ReferenceSectionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadPolluantsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPolluantsAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).downloadPolluantsAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllPolluantsForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantsForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferencePolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getAllPolluantsForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCo2ForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCo2ForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getCo2ForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCovnmForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCovnmForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getCovnmForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getHfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getHfcForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getNoxForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getPfcForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getSoxForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSpfoForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpfoForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceItemPolluantDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).getSpfoForCampaignUsingGET(annee, section, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadPolluants
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPolluantsUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceSectionControllerApiFetchParamCreator(configuration).uploadPolluantsUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceSectionControllerApi - factory interface
 * @export
 */
export const ReferenceSectionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadPolluantsAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadPolluantsAsCsvUsingGET(options?: any) {
            return ReferenceSectionControllerApiFp(configuration).downloadPolluantsAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllPolluantsForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPolluantsForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getAllPolluantsForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCo2ForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCo2ForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getCo2ForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCovnmForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCovnmForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getCovnmForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getHfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getHfcForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getNoxForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPfcForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getPfcForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSoxForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getSoxForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSpfoForCampaign
         * @param {number} annee annee
         * @param {'AIR' | 'EAU' | 'SOL'} section section
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpfoForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
            return ReferenceSectionControllerApiFp(configuration).getSpfoForCampaignUsingGET(annee, section, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadPolluants
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPolluantsUsingPOST(file?: any, options?: any) {
            return ReferenceSectionControllerApiFp(configuration).uploadPolluantsUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceSectionControllerApi - object-oriented interface
 * @export
 * @class ReferenceSectionControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceSectionControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadPolluantsAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public downloadPolluantsAsCsvUsingGET(options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).downloadPolluantsAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllPolluantsForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getAllPolluantsForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getAllPolluantsForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCo2ForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getCo2ForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getCo2ForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCovnmForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getCovnmForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getCovnmForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getHfcForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getHfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getHfcForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNoxForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getNoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getNoxForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPfcForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getPfcForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getPfcForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSoxForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getSoxForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getSoxForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSpfoForCampaign
     * @param {number} annee annee
     * @param {'AIR' | 'EAU' | 'SOL'} section section
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public getSpfoForCampaignUsingGET(annee: number, section: 'AIR' | 'EAU' | 'SOL', options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).getSpfoForCampaignUsingGET(annee, section, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadPolluants
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSectionControllerApi
     */
    public uploadPolluantsUsingPOST(file?: any, options?: any) {
        return ReferenceSectionControllerApiFp(this.configuration).uploadPolluantsUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * ReferenceSolControllerApi - fetch parameter creator
 * @export
 */
export const ReferenceSolControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadNormesSolAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesSolAsCsvUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/sol/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllNormesSolForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesSolForCampaignUsingGET(annee: number, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling getAllNormesSolForCampaignUsingGET.');
            }
            const localVarPath = `/sol/references/normes/{annee}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary uploadNormesSol
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesSolUsingPOST(file?: any, options: any = {}): FetchArgs {
            const localVarPath = `/sol/references/normes/csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new FormData();

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (file !== undefined) {
                localVarFormParams.append('file', file as any);
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams;

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReferenceSolControllerApi - functional programming interface
 * @export
 */
export const ReferenceSolControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary downloadNormesSolAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesSolAsCsvUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ReferenceSolControllerApiFetchParamCreator(configuration).downloadNormesSolAsCsvUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllNormesSolForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesSolForCampaignUsingGET(annee: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ReferenceNormeSolDto> {
            const localVarFetchArgs = ReferenceSolControllerApiFetchParamCreator(configuration).getAllNormesSolForCampaignUsingGET(annee, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary uploadNormesSol
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesSolUsingPOST(file?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadReferentialViolationsDto> {
            const localVarFetchArgs = ReferenceSolControllerApiFetchParamCreator(configuration).uploadNormesSolUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ReferenceSolControllerApi - factory interface
 * @export
 */
export const ReferenceSolControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary downloadNormesSolAsCsv
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadNormesSolAsCsvUsingGET(options?: any) {
            return ReferenceSolControllerApiFp(configuration).downloadNormesSolAsCsvUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllNormesSolForCampaign
         * @param {number} annee annee
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllNormesSolForCampaignUsingGET(annee: number, options?: any) {
            return ReferenceSolControllerApiFp(configuration).getAllNormesSolForCampaignUsingGET(annee, options)(fetch, basePath);
        },
        /**
         * 
         * @summary uploadNormesSol
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadNormesSolUsingPOST(file?: any, options?: any) {
            return ReferenceSolControllerApiFp(configuration).uploadNormesSolUsingPOST(file, options)(fetch, basePath);
        },
    };
};

/**
 * ReferenceSolControllerApi - object-oriented interface
 * @export
 * @class ReferenceSolControllerApi
 * @extends {BaseAPI}
 */
export class ReferenceSolControllerApi extends BaseAPI {
    /**
     * 
     * @summary downloadNormesSolAsCsv
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSolControllerApi
     */
    public downloadNormesSolAsCsvUsingGET(options?: any) {
        return ReferenceSolControllerApiFp(this.configuration).downloadNormesSolAsCsvUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllNormesSolForCampaign
     * @param {number} annee annee
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSolControllerApi
     */
    public getAllNormesSolForCampaignUsingGET(annee: number, options?: any) {
        return ReferenceSolControllerApiFp(this.configuration).getAllNormesSolForCampaignUsingGET(annee, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary uploadNormesSol
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReferenceSolControllerApi
     */
    public uploadNormesSolUsingPOST(file?: any, options?: any) {
        return ReferenceSolControllerApiFp(this.configuration).uploadNormesSolUsingPOST(file, options)(this.fetch, this.basePath);
    }

}

/**
 * TrackDechetControllerApi - fetch parameter creator
 * @export
 */
export const TrackDechetControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary updateDeclarationProductedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationProductedWasteUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling updateDeclarationProductedWasteUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling updateDeclarationProductedWasteUsingGET.');
            }
            const localVarPath = `/api/trackdechet/updateDeclaration/productedWaste/{annee}/{etablissement}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeclarationReceivedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationReceivedWasteUsingGET(annee: number, etablissement: string, options: any = {}): FetchArgs {
            // verify required parameter 'annee' is not null or undefined
            if (annee === null || annee === undefined) {
                throw new RequiredError('annee','Required parameter annee was null or undefined when calling updateDeclarationReceivedWasteUsingGET.');
            }
            // verify required parameter 'etablissement' is not null or undefined
            if (etablissement === null || etablissement === undefined) {
                throw new RequiredError('etablissement','Required parameter etablissement was null or undefined when calling updateDeclarationReceivedWasteUsingGET.');
            }
            const localVarPath = `/api/trackdechet/updateDeclaration/receivedWaste/{annee}/{etablissement}`
                .replace(`{${"annee"}}`, encodeURIComponent(String(annee)))
                .replace(`{${"etablissement"}}`, encodeURIComponent(String(etablissement)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrackDechetControllerApi - functional programming interface
 * @export
 */
export const TrackDechetControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary updateDeclarationProductedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationProductedWasteUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto23Nowobjectobject> {
            const localVarFetchArgs = TrackDechetControllerApiFetchParamCreator(configuration).updateDeclarationProductedWasteUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeclarationReceivedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationReceivedWasteUsingGET(annee: number, etablissement: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<IDeclarationDto23Nowobjectobject> {
            const localVarFetchArgs = TrackDechetControllerApiFetchParamCreator(configuration).updateDeclarationReceivedWasteUsingGET(annee, etablissement, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrackDechetControllerApi - factory interface
 * @export
 */
export const TrackDechetControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary updateDeclarationProductedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationProductedWasteUsingGET(annee: number, etablissement: string, options?: any) {
            return TrackDechetControllerApiFp(configuration).updateDeclarationProductedWasteUsingGET(annee, etablissement, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeclarationReceivedWaste
         * @param {number} annee annee
         * @param {string} etablissement etablissement
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeclarationReceivedWasteUsingGET(annee: number, etablissement: string, options?: any) {
            return TrackDechetControllerApiFp(configuration).updateDeclarationReceivedWasteUsingGET(annee, etablissement, options)(fetch, basePath);
        },
    };
};

/**
 * TrackDechetControllerApi - object-oriented interface
 * @export
 * @class TrackDechetControllerApi
 * @extends {BaseAPI}
 */
export class TrackDechetControllerApi extends BaseAPI {
    /**
     * 
     * @summary updateDeclarationProductedWaste
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackDechetControllerApi
     */
    public updateDeclarationProductedWasteUsingGET(annee: number, etablissement: string, options?: any) {
        return TrackDechetControllerApiFp(this.configuration).updateDeclarationProductedWasteUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeclarationReceivedWaste
     * @param {number} annee annee
     * @param {string} etablissement etablissement
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrackDechetControllerApi
     */
    public updateDeclarationReceivedWasteUsingGET(annee: number, etablissement: string, options?: any) {
        return TrackDechetControllerApiFp(this.configuration).updateDeclarationReceivedWasteUsingGET(annee, etablissement, options)(this.fetch, this.basePath);
    }

}

/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
export const UserControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelUnsubscriptionFromAllEmailingIfExists
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel: string, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'courriel' is not null or undefined
            if (courriel === null || courriel === undefined) {
                throw new RequiredError('courriel','Required parameter courriel was null or undefined when calling cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST.');
            }
            const localVarPath = `/mailing/{courriel}/desinscription/{token}/annuler`
                .replace(`{${"courriel"}}`, encodeURIComponent(String(courriel)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary checkUnsubscriptionStatus
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUnsubscriptionStatusUsingGET(courriel: string, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'courriel' is not null or undefined
            if (courriel === null || courriel === undefined) {
                throw new RequiredError('courriel','Required parameter courriel was null or undefined when calling checkUnsubscriptionStatusUsingGET.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling checkUnsubscriptionStatusUsingGET.');
            }
            const localVarPath = `/mailing/{courriel}/desinscription/{token}`
                .replace(`{${"courriel"}}`, encodeURIComponent(String(courriel)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary generateCustomEmailUnsubscriptionToken
         * @param {string} courriel courriel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomEmailUnsubscriptionTokenUsingGET(courriel: string, options: any = {}): FetchArgs {
            // verify required parameter 'courriel' is not null or undefined
            if (courriel === null || courriel === undefined) {
                throw new RequiredError('courriel','Required parameter courriel was null or undefined when calling generateCustomEmailUnsubscriptionTokenUsingGET.');
            }
            const localVarPath = `/mailing/{courriel}/token`
                .replace(`{${"courriel"}}`, encodeURIComponent(String(courriel)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unsubscribeFromAllEmailing
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromAllEmailingUsingPOST(courriel: string, token: string, options: any = {}): FetchArgs {
            // verify required parameter 'courriel' is not null or undefined
            if (courriel === null || courriel === undefined) {
                throw new RequiredError('courriel','Required parameter courriel was null or undefined when calling unsubscribeFromAllEmailingUsingPOST.');
            }
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling unsubscribeFromAllEmailingUsingPOST.');
            }
            const localVarPath = `/mailing/{courriel}/desinscription/{token}`
                .replace(`{${"courriel"}}`, encodeURIComponent(String(courriel)))
                .replace(`{${"token"}}`, encodeURIComponent(String(token)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Authorization required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelUnsubscriptionFromAllEmailingIfExists
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel: string, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary checkUnsubscriptionStatus
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUnsubscriptionStatusUsingGET(courriel: string, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourrielStatusDto> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).checkUnsubscriptionStatusUsingGET(courriel, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary generateCustomEmailUnsubscriptionToken
         * @param {string} courriel courriel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomEmailUnsubscriptionTokenUsingGET(courriel: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).generateCustomEmailUnsubscriptionTokenUsingGET(courriel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary unsubscribeFromAllEmailing
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromAllEmailingUsingPOST(courriel: string, token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourrielStatusDto> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).unsubscribeFromAllEmailingUsingPOST(courriel, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary cancelUnsubscriptionFromAllEmailingIfExists
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel: string, token: string, options?: any) {
            return UserControllerApiFp(configuration).cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary checkUnsubscriptionStatus
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkUnsubscriptionStatusUsingGET(courriel: string, token: string, options?: any) {
            return UserControllerApiFp(configuration).checkUnsubscriptionStatusUsingGET(courriel, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary generateCustomEmailUnsubscriptionToken
         * @param {string} courriel courriel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCustomEmailUnsubscriptionTokenUsingGET(courriel: string, options?: any) {
            return UserControllerApiFp(configuration).generateCustomEmailUnsubscriptionTokenUsingGET(courriel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary unsubscribeFromAllEmailing
         * @param {string} courriel courriel
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeFromAllEmailingUsingPOST(courriel: string, token: string, options?: any) {
            return UserControllerApiFp(configuration).unsubscribeFromAllEmailingUsingPOST(courriel, token, options)(fetch, basePath);
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary cancelUnsubscriptionFromAllEmailingIfExists
     * @param {string} courriel courriel
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel: string, token: string, options?: any) {
        return UserControllerApiFp(this.configuration).cancelUnsubscriptionFromAllEmailingIfExistsUsingPOST(courriel, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary checkUnsubscriptionStatus
     * @param {string} courriel courriel
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public checkUnsubscriptionStatusUsingGET(courriel: string, token: string, options?: any) {
        return UserControllerApiFp(this.configuration).checkUnsubscriptionStatusUsingGET(courriel, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary generateCustomEmailUnsubscriptionToken
     * @param {string} courriel courriel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public generateCustomEmailUnsubscriptionTokenUsingGET(courriel: string, options?: any) {
        return UserControllerApiFp(this.configuration).generateCustomEmailUnsubscriptionTokenUsingGET(courriel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary unsubscribeFromAllEmailing
     * @param {string} courriel courriel
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public unsubscribeFromAllEmailingUsingPOST(courriel: string, token: string, options?: any) {
        return UserControllerApiFp(this.configuration).unsubscribeFromAllEmailingUsingPOST(courriel, token, options)(this.fetch, this.basePath);
    }

}
