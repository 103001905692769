import { FluidInArray } from "./types";
import { AirGazHfcDto } from "api/gen";
import { ReferenceItemGazDto } from "api/gen/api";
import { AUTRE_GAZ_DTO } from "./selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { isGazAutre } from "../validation/validation";

const getGazNameById = (gazs: ReferenceItemGazDto[], gazId: string | null) => {
  if (gazId === null) {
    return null;
  }
  const gaz = findElementMatchingTemplate(
    {
      uuid: gazId,
    },
    gazs
  );
  return gaz || null;
};

const convertToFluidInArray = (
  fluidDto: AirGazHfcDto,
  referentiel: ReferenceItemGazDto[]
): FluidInArray => {
  const refGazDto = fluidDto.autre
    ? AUTRE_GAZ_DTO
    : getGazNameById(referentiel, fluidDto.gazID);
  // In AirGazHfcDto if the fluid declared is not in the referentiel the name of the fluid is stored in the key "gazID"
  const preciser = fluidDto.autre ? fluidDto.gazID : null;

  const newFrontFluidWithTooMuchKey = {
    ...fluidDto,
    fluid: refGazDto,
    preciser: preciser,
  };

  const { gazID, ...frontFluid } = newFrontFluidWithTooMuchKey;

  return {
    data: frontFluid,
    errors: {},
  };
};

export const computeFluidArrayInitialValues = (
  hfcFluid: AirGazHfcDto[],
  referentiel: ReferenceItemGazDto[]
): FluidInArray[] => {
  return hfcFluid.map(hfc => convertToFluidInArray(hfc, referentiel));
};

export const convertHfcInArrayToDto = (
  fluidInArray: FluidInArray[]
): AirGazHfcDto[] => {
  return fluidInArray.map(hfcFluid => {
    const singleFluid = hfcFluid.data;

    const isOtherSelected = isGazAutre(singleFluid.fluid);

    const newDtoWithTooMuchKey = {
      ...singleFluid,
      gazID: isOtherSelected
        ? singleFluid.preciser
        : singleFluid.fluid && singleFluid.fluid.uuid,
      autre: isOtherSelected,
    };

    const { fluid, preciser, ...newDto } = newDtoWithTooMuchKey;

    return newDto;
  });
};
