import {
  FILE_STATE,
  FileHandlers,
  FileSectionEnum,
  HandledFile,
} from "./types";
import _ from "lodash";
import { FileDto } from "api/gen";
import React, { useEffect, useState } from "react";
import FileLoaderButton from "./FileLoaderButton";
import { RowsWrapper } from "./fileRow/RowsWrapper";
import { getHandleFileList, isUploadingOrDeleting } from "./utils";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";

const useStyles = makeStyles({
  container: {
    display: "block",
    alignItems: "start",
  },
});

interface FileLoaderWithEventProps {
  text: string;
  filesPath: string;
  section: FileSectionEnum;
  declaration: AnyDeclarationDto;

  fileHandlers: FileHandlers;

  max?: number;
  isDisabled?: boolean;

  additionalClassnameContainer?: string;
  additionalClassnameButton?: string;
  additionalClassnameList?: string;

  transformFunction?: (f: Record<string, unknown>[]) => FileDto[];
  onReject?: () => void;
}

const FilesLoader = ({
  text,
  filesPath,
  section,
  declaration,
  fileHandlers,

  max,
  isDisabled,

  additionalClassnameContainer,
  additionalClassnameButton,
  additionalClassnameList,

  onReject,
  transformFunction,
}: FileLoaderWithEventProps): React.ReactElement => {
  const classes = useStyles();
  const { uploadHandlerWithEvent, deleteHandlerWithEvent } = fileHandlers;

  const [files, setFiles] = useState<Readonly<HandledFile[]>>(() => {
    return getHandleFileList(declaration, filesPath, transformFunction);
  });

  useEffect(
    () =>
      setFiles(getHandleFileList(declaration, filesPath, transformFunction)),
    [declaration, filesPath, setFiles, transformFunction]
  );

  const addFileToUpload = async (file: File) => {
    setFiles(files =>
      files.concat({
        localFile: file,
        uploadedFile: null,
        state: FILE_STATE.FILE_UPLOADING,
      })
    );

    const isUploadSuccessful = await uploadHandlerWithEvent(file);

    if (!isUploadSuccessful) {
      setFiles(files =>
        files.filter(handledFile => !_.isEqual(handledFile.localFile, file))
      );
    }
  };

  const addFileToDelete = async (fileDto: FileDto) => {
    setFiles(files => {
      return files.map(singleFile => {
        if (_.isEqual(singleFile.uploadedFile, fileDto)) {
          singleFile.state = FILE_STATE.FILE_DELETING;
        }

        return singleFile;
      });
    });

    const isDeleteSuccessful = await deleteHandlerWithEvent(fileDto);

    if (!isDeleteSuccessful) {
      setFiles(files => {
        files.forEach(f => {
          if (_.isEqual(f.uploadedFile, fileDto)) {
            f.state = FILE_STATE.FILE_UPLOADED;
          }
        });

        return files;
      });
    }
  };

  const isMaxFileCountReached = max !== undefined && files.length >= max;

  const isFileLoaderDisabled: boolean =
    isMaxFileCountReached || isDisabled || isUploadingOrDeleting(files);

  return (
    <div
      className={classNames(classes.container, additionalClassnameContainer)}
    >
      <FileLoaderButton
        text={text}
        section={section}
        isFileLoaderDisabled={isFileLoaderDisabled}
        addFileToUpload={addFileToUpload}
        additionalClassnameButton={additionalClassnameButton}
        onReject={onReject}
      />

      <RowsWrapper
        additionalClassname={additionalClassnameList}
        files={files}
        isDisabled={!!isDisabled}
        section={section}
        addFileToDelete={addFileToDelete}
      />
    </div>
  );
};

export default FilesLoader;
