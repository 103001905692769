import React from "react";
import {
  CampaignDtoStateEmissionsEnum,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
  QuotasEmissionsBlocPlanSurveillanceDto2121,
} from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import { PATH_QUOTAS_EMISSIONS } from "common/path/path20Now";
import Row from "common/presentational/Row";
// Bloc Bilan Total
import BlocBilanTotal from "../../../../from21/toNow/Quotas/Emissions/BlocBilanTotal";
// Bloc Verification
import BlocVerification from "../../../../from21/toNow/Quotas/Emissions/BlocVerification";
import QuotasGlobalFormActions from "../../../../from21/toNow/Quotas/QuotasGlobalFormActions";
import BlocEmailsVerificateurs from "../../../../from21/toNow/Quotas/Emissions/BlocEmailsVerificateurs";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import QuotasDeclarationOfficeDownload from "../../../../from21/toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import {
  useBasicDeclarationHandlers22Now,
  useDeclaration22Now,
  useDeclarationHelpers22Now,
} from "../../../toNow/versionedElements/declarationHooks22Now";
import BlocInspecteur from "../../../../from21/to23/Quotas/common/BlocInspecteur";
import { areAllEmissionsBlocsValidated } from "../../../../from21/to23/Quotas/utils/validation";
import PlanSurveillanceForm from "./BlocPlanSurveillance/PlanSurveillanceForm";
import BlocRappelTypeActivite from "../../../../from21/toNow/Quotas/common/BlocRappelTypeActivite";
import { ReferentialActiviteQuotasProps } from "../../../../from21/toNow/Quotas/common/BlocRappelTypeActivite/types";
import {
  updateHandlerBilanTotal22Now,
  updateHandlerEmailsAddress22Now,
} from "../../../toNow/versionedElements/updateHandlers22Now";
import RapportEmissionsForm from "./BlocRapportEmissions/RapportEmissionsForm";
import { useDeclarationStateHandlers21Now } from "../../../../from21/toNow/versionedElements/declarationHooks21Now";
import { isFullDeclarationActive } from "../../../../from20/toNow/Quotas/utils";

const EmissionsForm = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const declaration = useDeclaration22Now();
  const currentCampaign = useCurrentDeclarationCampaign();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers22Now();
  const { update } = useBasicDeclarationHandlers22Now();
  const { yieldQuotaEmissionsVerif } = useDeclarationStateHandlers21Now();

  const typeActiviteQuota = declaration.body.typeActivite.quotas;

  const blocPlanDeSurveillanceFormProps = useFormikBloc<
    QuotasEmissionsBlocPlanSurveillanceDto2121
  >();

  const blocBilanTotalProps = useFormikBloc<
    QuotasBlocEmissionsBilanTotalDto20Now
  >();
  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasBlocEmissionsEmailsVerificateursDto20Now
  >();

  const isFullForm = isFullDeclarationActive(declaration);

  const pageHasChanges =
    blocEmailsVerificateursProps.hasChanges ||
    blocPlanDeSurveillanceFormProps.hasChanges;

  // Are blocs validated?
  const allDeclarantBlocsValidated = areAllEmissionsBlocsValidated(
    isPathValidatedInDeclaration,
    isFullForm
  );

  // TODO [GEREP-2565] use actions buttons instead
  const GlobalFormActions = () => (
    <QuotasGlobalFormActions
      pathToValidate={PATH_QUOTAS_EMISSIONS}
      target={WorkflowTarget.QUOTA_EMISSIONS}
      quotaState={declaration.body.workflowStatus.quotaEmissions.state}
      yieldQuotaVerif={yieldQuotaEmissionsVerif}
      isSimplifiedQuota={
        typeActiviteQuota.excluArticle27 || typeActiviteQuota.excluArticle27Bis // TODO 1362 HERE
      }
      saveAction={() =>
        update(declaration => {
          if (isFullForm) {
            declaration = updateHandlerBilanTotal22Now(declaration, null);
            declaration = updateHandlerEmailsAddress22Now(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
          } else {
            declaration = updateHandlerBilanTotal22Now(
              declaration,
              blocBilanTotalProps.getValues()
            );
            declaration = updateHandlerEmailsAddress22Now(declaration, null);
          }
          return declaration;
        })
      }
      cancelAction={() => {
        blocBilanTotalProps.resetForm();
        blocEmailsVerificateursProps.resetForm();
        blocPlanDeSurveillanceFormProps.resetForm();
      }}
      hasChanges={pageHasChanges}
      declarantBlocsValidated={allDeclarantBlocsValidated}
    />
  );

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
      />
      <Row />

      <BlocRappelTypeActivite referentialActivites={referentialActivites} />
      <Row />

      {isFullForm ? (
        <>
          <PlanSurveillanceForm />
          <Row />

          <RapportEmissionsForm />
          <Row />

          <BlocEmailsVerificateurs
            formikRef={blocEmailsVerificateursProps.formikRef}
            hasChanges={blocEmailsVerificateursProps.hasChanges}
            setHasChanges={blocEmailsVerificateursProps.setHasChanges}
          />

          <GlobalFormActions />
          <Row />

          <BlocVerification />
        </>
      ) : (
        <>
          <Row />

          <BlocBilanTotal
            formikRef={blocBilanTotalProps.formikRef}
            hasChanges={blocBilanTotalProps.hasChanges}
            setHasChanges={blocBilanTotalProps.setHasChanges}
          />
          <Row />

          <GlobalFormActions />
        </>
      )}
      <Row />

      <BlocInspecteur
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
        currentState={declaration.body.workflowStatus.quotaEmissions.state}
        path={PATH_QUOTAS_EMISSIONS}
        isCampaignEnded={
          currentCampaign.stateEmissions === CampaignDtoStateEmissionsEnum.ENDED
        }
      />
      <Row height={"25px"} />
    </>
  );
};

export default EmissionsForm;
