import { FileDto } from "api/gen";
import {
  ExtensionAction,
  ExtensionObj,
  FILE_STATE,
  HandledFile,
} from "./types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import _ from "lodash";

export const DEFAULT_ALERT_MESSAGE =
  "Le format du fichier uploadé est incorrect.";

export const isUploadingOrDeleting = (
  files: Readonly<HandledFile[]>
): boolean => {
  return files.some(file => file.state !== FILE_STATE.FILE_UPLOADED);
};

export const handledFileTitle = (file: HandledFile): string => {
  switch (file.state) {
    case FILE_STATE.FILE_UPLOADING:
      if (file.localFile === null) {
        throw Error("uploading a null file");
      }
      return file.localFile.name;
    case FILE_STATE.FILE_UPLOADED:
      if (file.uploadedFile === null) {
        throw Error("uploaded file is null");
      }
      return `${file.uploadedFile.name}.${file.uploadedFile.extension}`;
    case FILE_STATE.FILE_DELETING:
      if (file.uploadedFile === null) {
        throw Error("uploaded file is null");
      }
      return file.uploadedFile.name;
    default: {
      throw new ShouldNotHappen(file.state);
    }
  }
};

export const extensionsStateReducer = (
  state: ExtensionObj,
  action: ExtensionAction
): ExtensionObj => {
  if (action.type === "launchLoad") {
    // we launch a fetch
    return { state: "loading" };
  }

  if (action.type === "loadResult") {
    //we just got results!
    if (action.backResult === null) {
      // the fetch was failed
      return { state: "failed" };
    }
    return {
      state: "success",
      result: action.backResult.map(singleExtension => `.${singleExtension}`),
    };
  }

  throw new ShouldNotHappen(action);
};

export const getHandleFileList = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  declaration: VersionedDeclarationDto,
  filesPath: string,
  transformFunction: ((f: Record<string, unknown>[]) => FileDto[]) | undefined
): HandledFile[] => {
  const files = transformFunction
    ? transformFunction(_.get(declaration, filesPath))
    : (_.get(declaration, filesPath) as FileDto[] | undefined);
  return files
    ? files.map(file => {
        return {
          localFile: null,
          uploadedFile: file,
          state: FILE_STATE.FILE_UPLOADED,
        };
      })
    : [];
};

export const isFileSpinnerVisible = (file: HandledFile): boolean => {
  switch (file.state) {
    case FILE_STATE.FILE_UPLOADING:
      if (file.localFile === null) {
        throw Error("uploading a null file");
      }

      return true;
    case FILE_STATE.FILE_UPLOADED:
      if (file.uploadedFile === null) {
        throw Error("uploaded file is null");
      }

      return false;
    case FILE_STATE.FILE_DELETING:
      if (file.uploadedFile === null) {
        throw Error("uploaded file is null");
      }

      return true;
    default: {
      throw new ShouldNotHappen(file.state);
    }
  }
};
