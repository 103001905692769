import * as Yup from "yup";
import {
  codePostalMessage,
  futureDateMessage,
  hoursLengthMessage,
  siretLengthMessage,
  siretLuhnMessage,
  siretNumberMessage,
} from "common/declarant/formik/formikMessages";
import {
  commonMixedFields,
  commonMixedFieldsNullable,
  commonNullableStringFields,
  commonNumberFields,
  commonNumberFieldsNullable,
  commonPositiveIntegerNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { checkLuhnAlgorithm } from "pages/CompanySpace/from20/to20/GeneralInformations/utils/luhnUtils";
import { ActiviteDto23NowEtatAdministratifEnum } from "api/gen";
import { ActivityType } from "../utils/types";

const checkSIRETNumber = commonStringFields
  .length(14, siretLengthMessage)
  .matches(/^[0-9]*$/, siretNumberMessage)
  .test(
    "SIRET must be undefined or pass the Luhn check",
    siretLuhnMessage,
    value => {
      if (value !== undefined && value !== null) {
        return checkLuhnAlgorithm(value);
      }
      return value === undefined;
    }
  );

const checkDateFermeturePassed = commonStringFields.test(
  "Date de fermeture must be prior to today",
  futureDateMessage,
  (value: string | null | undefined): boolean => {
    if (value !== undefined && value !== null) {
      return new Date(value) < new Date();
    }
    return false;
  }
);

const companyActivityCommonValidationSchema = Yup.object().shape({
  codeInspection: commonStringFields,
  identifiantService: commonNullableStringFields,
  nomEtablissement: commonStringFields,
  siret: checkSIRETNumber,
  codeNaf: commonMixedFields(),
  systemeCoordonnees: commonMixedFields(),
  abscisse: commonStringFields,
  ordonnee: commonStringFields,
  unite: commonMixedFieldsNullable(),
  matiereProduite: commonNullableStringFields,
  autreMatiereProduitePrecision: commonNullableStringFields,
  nombreInstallations: commonPositiveIntegerNumberFieldsNullable,
  website: commonNullableStringFields,
  nombreHeureDeFonctionnement: commonPositiveIntegerNumberFieldsNullable.max(
    8784,
    hoursLengthMessage
  ),
  trancheEmploye: commonMixedFieldsNullable(),
  informationsComplementaires: commonNullableStringFields,
  dateFermeture: Yup.string().when("etatAdministratif", {
    is: (etatAdministratif: ActiviteDto23NowEtatAdministratifEnum) =>
      etatAdministratif === ActiviteDto23NowEtatAdministratifEnum.CLOSED,
    then: checkDateFermeturePassed,
    otherwise: commonNullableStringFields,
  }),
  etatAdministratif: commonMixedFields(),
  numeroVoie: commonNullableStringFields,
  typeVoie: commonNullableStringFields,
  libelleVoie: commonStringFields,
  adresseComplement: commonNullableStringFields,
  codePostal: commonStringFields.length(5, codePostalMessage),
  informationCommune: commonMixedFields(),
});

export const companyActivityEPRTRValidationSchema: Yup.SchemaOf<Partial<
  ActivityType
>> = companyActivityCommonValidationSchema.concat(
  Yup.object().shape({ volumeProduction: commonNumberFields })
);

export const companyActivityNoEPRTRValidationSchema: Yup.SchemaOf<Partial<
  ActivityType
>> = companyActivityCommonValidationSchema.concat(
  Yup.object().shape({
    volumeProduction: commonNumberFieldsNullable,
  })
);
export const displayMandatory = (isFieldMandatory: boolean): string => {
  return isFieldMandatory ? " *" : "";
};

export const isDeclarationLinkedToGun = (
  codeEtablissement: string
): boolean => {
  const notLinkedDeclarationCodeFormat = /^00090[0-9]{5}$/;
  return !notLinkedDeclarationCodeFormat.test(codeEtablissement);
};
