import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FILE_BUTTONS_STYLE, SECTION_TITLE_GREY } from "theme";
import Row from "common/presentational/Row";
import QuotasAlcForm from "./QuotasAlcForm";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  ...FILE_BUTTONS_STYLE,
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  container: {
    border: "#e6e6e6 solid 1px",
    padding: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
});

const BlocAlc = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <p>
          <span className={classes.bold}>
            Rapport de déclaration des niveaux d’activité (ALC) :
          </span>
        </p>
        <Row height={"20px"} />
        <p>
          ATTENTION : Seul le rapport ALC préliminaire déposé avant le 31/01 de
          chaque année sera pris en compte pour déterminer si la délivrance des
          quotas gratuits pour l’année en cours peut être effectuée le 28/02. Un
          défaut de dépôt avant le 31/01 suspendra automatiquement la délivrance
          des quotas gratuits. La délivrance interviendra alors après
          l'instruction de la déclaration vérifiée des niveaux d’activité et le
          recalcul éventuel de l’allocation en cas de baisse ou hausse d’un
          niveau d’activité.
        </p>
        <Row height={"20px"} />
        <p>
          Le rapport ALC préliminaire ne nécessite pas la validation par un
          vérificateur.{" "}
          <span className={classes.bold}>
            Le dépôt d'un rapport ALC dans la section RAPPORT DE DÉCLARATION DES
            NIVEAUX D'ACTIVITÉ (VÉRIFIÉ) est obligatoire, ainsi que la
            validation par un vérificateur qui doit intervenir avant le 15/03
            (exceptionnellement 15 avril en 2021).
          </span>
        </p>
        <Row height={"20px"} />
        <p>
          <span className={classes.bold}>
            ATTENTION : Le modèle de rapport ALC a changé le 02/02/2021
          </span>
          , une nouvelle version est désormais disponible
        </p>
      </div>
      <Row height={"20px"} />
      <div className={classes.row}>
        <GreyFilesDownloader
          text="TÉLÉCHARGER UN MODÈLE DE RAPPORT ALC"
          isDisabled={false}
          fileName="ALC_template_COM_fr_2023-01-30.xls"
          additionalClassname={classes.fileButtons}
        />
      </div>
      <QuotasAlcForm isAlcVerifie={false} />
      <Row height={"20px"} />
      <QuotasAlcForm isAlcVerifie={true} />
    </div>
  );
};

export default BlocAlc;
