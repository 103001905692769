import React, { ReactElement } from "react";
import {
  FORCED,
  RECALLED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import ActionButtonsContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/ActionButtonsContainer";
import { useDeclarationGlobalState21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import SubmitButton from "../Global/SubmitButton";
import CancelSubmitButton from "pages/CompanySpace/from21/toNow/Actions/Global/CancelSubmitButton";

const DeclarantGlobalActionButtons = (): ReactElement => {
  const { isDeclarationStateContaining } = useDeclarationGlobalState21Now();

  return (
    <ActionButtonsContainer>
      {isDeclarationStateContaining([SUBMITTED, VALIDATED]) && (
        <CancelSubmitButton />
      )}
      {!isDeclarationStateContaining([
        SUBMITTED,
        RECALLED,
        VALIDATED,
        FORCED,
      ]) && <SubmitButton />}
    </ActionButtonsContainer>
  );
};

export default DeclarantGlobalActionButtons;
