import React from "react";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { VerificateurManagementController } from "./VerificateurManagementController";

const VerificateurDashboard = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    {
      label: "Déclarations",
      to: match.url,
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord vérificateur",
    image: null,
  };
  usePageHeaderSetterOnce(headerData);

  return <VerificateurManagementController />;
};

export default VerificateurDashboard;
