import React from "react";
import { RouteIdProps } from "common/declarant/type";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import TypeActiviteController from "./TypeActiviteController";

import idCardImg from "icons/pageLogos/id-card.svg";

const TypeActivite = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Types d'activités",
      to: match.url,
    },
  ];
  const imagedata = {
    src: idCardImg,
    alt: "une carte d'identité simplifiée",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Types d'activités",
    image: imagedata,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_EPRTR", "REFERENCE_ACTIVITES_QUOTAS"]}
      render={referentials => {
        return (
          <TypeActiviteController
            referentielEPRTR={referentials.REFERENCE_EPRTR}
            referentielActivites={referentials.REFERENCE_ACTIVITES_QUOTAS}
          />
        );
      }}
    />
  );
};

export default TypeActivite;
