import { ErrorDataProps } from "../../utils";
import { ErrorLevel, ErrorProps } from "common/utils/types";
import { makeStyles } from "@material-ui/styles";
import { ERROR, WARNING } from "theme";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const useErrorStyle = makeStyles({
  warning: {
    color: WARNING,
  },
  error: {
    color: ERROR,
  },
});

export const computeErrorData = (
  classes: Record<"warning" | "error", string>,
  error?: ErrorProps | null
): ErrorDataProps => {
  if (error === undefined || error === null) {
    return {
      errorIcon: undefined,
      errorMessageClass: undefined,
      errorMessage: undefined,
    };
  }

  switch (error.errorLevel) {
    case ErrorLevel.Blocking:
      return {
        errorIcon: "ban",
        errorMessage: error.text,
        errorMessageClass: classes.error,
      };
    case ErrorLevel.Warning: {
      //eslint-disable-next-line
      console.error(
        "the error level wasn't properly handled. Handling it as a blocking level error"
      );
      return {
        errorIcon: "ban",
        errorMessage: error.text,
        errorMessageClass: classes.error,
      };
    }
    default: {
      throw new ShouldNotHappen(error.errorLevel);
    }
  }
};
