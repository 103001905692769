import { DeclarationConstraintViolationDto } from "api/gen";
import React, { useMemo } from "react";
import { ViolationDisplayer } from "./ViolationDisplayer";

interface ViolationProcessorProps {
  errors: DeclarationConstraintViolationDto[];
  warnings: DeclarationConstraintViolationDto[];
}

export function ViolationProcessor({
  errors,
  warnings,
}: ViolationProcessorProps): React.ReactElement {
  const computedWarnings = useMemo(() => {
    const setPathWarningsToIgnore = new Set(
      errors.filter(error => error.commentRequired).map(error => error.path)
    );
    return warnings.filter(warning => {
      return !(
        setPathWarningsToIgnore.has(warning.path) && warning.commentRequired
      );
    });
  }, [errors, warnings]);

  return (
    <>
      <ViolationDisplayer errors={errors} warnings={computedWarnings} />
    </>
  );
}
