import React from "react";
import { TEXT_INPUT_STYLE } from "theme";
import { makeStyles } from "@material-ui/styles";
import { computeClassname } from "./utils";
import { DummyTextInputFieldProps } from "../types/dummyTypes";

const useStyles = makeStyles({
  ...TEXT_INPUT_STYLE,
  size: {
    width: "100%",
  },
});

const DummyTextInput = ({
  additionalClassName,
  disabled,
  value,
  placeholder,
  name,
  error,
  onChange,
  validator,
  maxLength = -1,
  ...props
}: DummyTextInputFieldProps): React.ReactElement => {
  const classes = useStyles();

  const inputClassName = computeClassname(
    // todo decide of the right way to pass errors to compute the classname, and eventually overriding style with a second className.
    classes,
    error,
    disabled,
    additionalClassName
  );
  return (
    <div className={classes.size}>
      <input
        type="text"
        className={inputClassName}
        disabled={disabled}
        placeholder={placeholder}
        onChange={event => {
          event.stopPropagation();

          if (validator && event && event.target && event.target.value) {
            const validated = validator(event.target.value);
            event.target.value = validated;
          }
          // we don't wanna save an empty string when user is actually writing nothing
          onChange && onChange(event.target.value || null);
        }}
        maxLength={maxLength}
        id={props.id}
        onBlur={event => {
          props.onBlur && props.onBlur(event);
          // we don't wanna save an empty string when user is actually writing nothing
          onChange && onChange(event.target.value.trim() || null);
        }}
        readOnly={props.readOnly}
        style={props.style}
        value={value || ""}
        name={name}
      />
    </div>
  );
};

export default DummyTextInput;
