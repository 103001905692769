import React from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import {
  DEFAULT_BUTTON_PADDING,
  DISABLED_BUTTON_HOVER_BACKGROUND,
  SECTION_TITLE_GREY,
  TEXT_LIGHT_GREY,
} from "theme";
import { DummyGlobalFormActionsProps } from "./types";
import { DummyCommentButton } from "common/comment/DummyCommentButton";
import InfoBulle from "../infoBulle/InfoBulle";

const useStyles = makeStyles({
  saveButton: {
    color: SECTION_TITLE_GREY,
    marginRight: `calc(5% - ${DEFAULT_BUTTON_PADDING})`,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
  },
  marginLeft: {
    marginLeft: "2px",
  },
  validationMessage: {
    color: TEXT_LIGHT_GREY,
    marginTop: "5px",
    marginRight: "10px",
  },
  buttonContainer: {
    justifyContent: "flex-end",
  },
  infoBulle: {
    alignSelf: "center",
  },
});

/**
 * simple control component to display form actions : validate, edit, save and cancel
 * no logic is done here
 *
 * use case : wrapped in GlobalFormActions, should not be used elsewhere unless you want special logic or title for your buttons
 */
const DummyGlobalFormActions = ({
  validateButton,
  editButton,
  cancelButton,
  saveButton,
  validationMessage,
  additionalLeftContent,
  commentButton,
  validationTooltipContent,
}: DummyGlobalFormActionsProps): React.ReactElement => {
  const classes = useStyles();

  const hasOneSpinnerVisible =
    (validateButton && validateButton.isSpinnerVisible) ||
    (editButton && editButton.isSpinnerVisible) ||
    (saveButton && saveButton.isSpinnerVisible);

  return (
    <>
      <Row height={"40px"} />
      <Row additionalClassname={classes.buttonContainer}>
        {additionalLeftContent}
        {!hasOneSpinnerVisible && cancelButton && cancelButton.isVisible && (
          <Button
            text={cancelButton.title}
            type="button"
            additionalClassname={classes.saveButton}
            onClick={cancelButton.onClick}
            isReversed
            isDisabled={!cancelButton.isEnabled}
          />
        )}

        {saveButton && saveButton.isVisible && (
          <Button
            text={
              <>
                <FontAwesomeIcon icon="save" />
                <span className={classes.marginLeft}>{saveButton.title}</span>
              </>
            }
            type="button"
            additionalClassname={classes.saveButton}
            onClick={saveButton.onClick}
            isReversed
            isDisabled={!saveButton.isEnabled || hasOneSpinnerVisible}
            isSpinnerVisible={saveButton.isSpinnerVisible}
          />
        )}

        {editButton && editButton.isVisible && (
          <Button
            text={
              <>
                <FontAwesomeIcon icon="edit" />
                <span className={classes.marginLeft}>{editButton.title}</span>
              </>
            }
            type="button"
            isDisabled={!editButton.isEnabled || hasOneSpinnerVisible}
            onClick={editButton.onClick}
            isReversed
            isSpinnerVisible={editButton.isSpinnerVisible}
          />
        )}

        {commentButton && commentButton.isEnabled && (
          <DummyCommentButton
            onClick={commentButton.onClick}
            state={commentButton.state}
          />
        )}

        {validateButton && validateButton.isVisible && (
          <>
            {validationMessage &&
              (validationMessage.isAlwaysVisible ||
                !validateButton.isEnabled) && (
                <span className={classes.validationMessage}>
                  {validationMessage.message}
                </span>
              )}
            {validationTooltipContent && (
              <InfoBulle
                content={validationTooltipContent}
                additionalClassName={classes.infoBulle}
              />
            )}
            <Button
              text={validateButton.title}
              type="submit"
              onClick={validateButton.onClick}
              isDisabled={!validateButton.isEnabled || hasOneSpinnerVisible}
              isSpinnerVisible={validateButton.isSpinnerVisible}
            />
          </>
        )}
      </Row>
    </>
  );
};

export default DummyGlobalFormActions;
