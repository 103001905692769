import React, { CSSProperties, ReactChild, ReactNode } from "react";
import { CommonProps, ErrorDataProps } from "../../utils";
import DummyChoiceSelectWithoutOptionProps from "./DummyChoiceSelectWithoutOptionProps";
import { ValueType } from "libAdapter/ReactSelect/TypesPatternAdapter";
import { UnWrappedChoiceSelectWTOPProps } from "../types/dummyTypes";
import SimpleInputWithTextLabelDisplayer from "../displayers/SimpleInputWithTextLabelDisplayer";
import { computeErrorData, useErrorStyle } from "../helpers/utils";
import { ErrorProps } from "common/utils/types";

interface BasicFieldType {
  label: string | ((classname: string) => ReactChild);
  name: string;
  additionalClassName?: string;
  style?: CSSProperties;
  disabled?: boolean;
  tooltipContent?: ReactNode;
  commentPath?: string;
}
interface BasicDummyInputType<T, IsMulti extends boolean>
  extends BasicFieldType {
  value: ValueType<T, IsMulti>;
  error?: ErrorProps;
}

interface ChoiceSelectFieldWTOPType<T, IsMulti extends boolean>
  extends UnWrappedChoiceSelectWTOPProps<T, IsMulti> {
  commonProps: CommonProps;
  additionalOnChange?: (value: ValueType<T, IsMulti>) => void;
}

export function DummyChoiceSelectWithLabel<T, IsMulti extends boolean>(
  props: BasicDummyInputType<T, IsMulti> & ChoiceSelectFieldWTOPType<T, IsMulti>
): React.ReactElement {
  const classes = useErrorStyle();
  const disabledStatus =
    props.disabled !== undefined ? props.disabled : props.commonProps.disabled;
  const errorData: ErrorDataProps = computeErrorData(classes, props.error);
  return (
    <SimpleInputWithTextLabelDisplayer
      name={props.name}
      label={props.label}
      disabled={disabledStatus}
      commonProps={props.commonProps}
      tooltipContent={props.tooltipContent}
      errorData={errorData}
      renderInput={id => {
        return (
          <DummyChoiceSelectWithoutOptionProps
            id={id}
            disabled={props.disabled}
            additionalClassName={props.additionalClassName}
            style={props.style}
            placeholder={props.placeholder}
            hideValue={props.hideValue}
            enableReset={props.enableReset}
            error={props.error || null}
            onChange={props.additionalOnChange}
            {...props}
          />
        );
      }}
    />
  );
}
