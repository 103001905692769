import { divide, H32info, h32infos, multiply } from "./h32infos";
import { CombustibleInModale } from "../../BlocCombustibles/utils/types";

const getMasseVolumiqueValue = (
  operationMasseVolumique: string | null,
  combustibleMasse: number | null
): number | null => {
  if (operationMasseVolumique === multiply) {
    return combustibleMasse;
  }
  if (operationMasseVolumique === divide) {
    if (combustibleMasse !== 0 && combustibleMasse !== null) {
      return 1 / combustibleMasse;
    }
    return 1;
  }
  return 1;
};

const computeConsommationValue = (combustible: CombustibleInModale): number => {
  if (combustible.consommationPCI !== null) {
    // if the consommationPCI isn't null, then it means the unit was PCS as per
    // the validate of combustible modale and converter of combustibleDto, so we
    // want to use the consommation multiplied by PCSToPCIFactor, which is
    // exactly what's stored in consommationPCI
    return combustible.consommationPCI;
  }
  return combustible.consommation === null ? 1 : combustible.consommation;
};

export const computeH32 = (
  facteur: number | null,
  facteurOxydation: number | null,
  combustible: CombustibleInModale
): number | null => {
  const info: H32info | undefined = h32infos.find(infos => {
    if (combustible.unite) {
      return (
        infos.uniteCombustible === combustible.unite &&
        (combustible.pciUnite === infos.unitePci ||
          (combustible.pciUnite !== null &&
            combustible.pciUnite === infos.unitePci))
      );
    }
    return false;
  });
  if (info && facteur !== null) {
    const operationMasseVolumique = getMasseVolumiqueValue(
      info.operationMasseVolumique,
      combustible.masse
    );
    const facteurOxydationEmission =
      facteurOxydation !== null && facteurOxydation !== undefined
        ? facteurOxydation / 100
        : 1;

    const pciValeur = info.multiplyByPciAndEmissionFactor
      ? combustible.pciValeur
      : 1;
    const consommation = computeConsommationValue(combustible);

    return (
      consommation *
      (pciValeur === null ? 1 : pciValeur) *
      facteur *
      info.convertionFactorCombustible *
      info.convertionFactorPci *
      (operationMasseVolumique === null ? 1 : operationMasseVolumique) *
      facteurOxydationEmission
    );
  }
  return null;
};
