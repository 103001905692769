import {
  AuthDroitDto,
  NatureServiceWithEnumDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";

export const baseInspectorCodeForRight = (
  right: AuthDroitDto,
  referentielInspectorCode: NatureServiceWithEnumDto[]
): NatureServiceWithEnumDto | null => {
  if (right.natureService) {
    const natureService = right.natureService;
    return (
      referentielInspectorCode.find(
        code => code.natureService.id === natureService.id
      ) || null
    );
  }
  return null;
};

export const baseDepartementForRight = (
  right: AuthDroitDto,
  referentielDep: ReferenceItemDepartementDto[]
): ReferenceItemDepartementDto | null => {
  return referentielDep.find(dep => dep.numero === right.departement) || null;
};
export const baseRegionForRight = (
  right: AuthDroitDto,
  referentielRegion: ReferenceItemRegionDto[],
  referentielDep: ReferenceItemDepartementDto[]
): ReferenceItemRegionDto | null => {
  if (right.codeRegion) {
    return (
      referentielRegion.find(
        region => region.codeRegion === right.codeRegion
      ) || null
    );
  } else if (right.departement) {
    const department = baseDepartementForRight(right, referentielDep);
    if (department) {
      return (
        referentielRegion.find(
          region => region.codeRegion === department.codeRegion
        ) || null
      );
    }
  }
  return null;
};

/**
 * Format a number to match the requiered minDifitCount adding 0 prefeix if needed
 * formatNumber(10, 4) => "0010"
 * formatNumber(12312, 4) => "12312"
 *
 *  @param numberToFormat  number we want to format
 * @param minDigitCount   requiered digit count
 */

/**
 * find  all department in selected region
 *
 * @param regionId        region's id we have selected
 * @param referentielDep  all departments in France
 */
export const possibleDepartmentForRegion = (
  codeRegion: string,
  referentielDep: ReferenceItemDepartementDto[]
): ReferenceItemDepartementDto[] => {
  return referentielDep.filter(dep => dep.codeRegion === codeRegion);
};

/**
 * check if right has valid informations to allow it to create establishment
 * prevent to fallback to super_admin right (means he can select what ever he wants during establishment creation)
 * if this is not a super_admin right
 *
 * @param right current selected right
 * @param referentielInspectorCode referential for inspector code
 * @param referentielRegion referential for regions
 * @param referentielDep referential for department
 */
export const isEstablishmentCreationAvailableForRight = (
  right: AuthDroitDto,
  referentielInspectorCode: NatureServiceWithEnumDto[],
  referentielRegion: ReferenceItemRegionDto[],
  referentielDep: ReferenceItemDepartementDto[]
): boolean => {
  if (
    right.natureService &&
    !baseInspectorCodeForRight(right, referentielInspectorCode)
  ) {
    return false;
  }
  if (
    right.codeRegion &&
    !baseRegionForRight(right, referentielRegion, referentielDep)
  ) {
    return false;
  }
  if (right.departement && !baseDepartementForRight(right, referentielDep)) {
    return false;
  }

  return true;
};
