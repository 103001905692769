import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { Declarants } from "./DeclarantIdentityList/types";
import {
  ActivityValues,
  AdministrationValues,
  CoordonneesDepartementMap,
  franceOption,
} from "./utils/types";
import Row from "common/presentational/Row";
import {
  computeActiviteInitialValues,
  computeAdminInitialValues,
  computeDeclarantInitialValues,
  createActivityDto,
  createAdministratifDto,
  createDeclarantsDto,
} from "./utils/mainSubmitHandler";
import { DeclarantIdentityList } from "./DeclarantIdentityList";
import { CompanyIdentity } from "./CompanyIdentity";
import { CompanyActivity } from "./CompanyActivity";
import { CompanyTechnique } from "../../../../CommonSpace/CompanyTechnique/2020_2020";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
  ReferenceActiviteQuotaDto,
  ReferenceCodeApeDto,
  ReferenceEprtrDto,
  ReferenceGeoCodeInseeDto,
  ReferenceItemCodeApeDto,
  ReferenceItemEprtrDto,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ReferencePaysDto,
} from "api/gen";
import { TechniqueValues } from "../../../../CommonSpace/CompanyTechnique/2020_2020/utils";
import { CREATED } from "common/messages/dashboardMessage";
import { useUserData } from "Authenticator/UserData";
import {
  PATH_INFO_GENERALE,
  PATH_INFO_GENERALE_ACTIVITE,
  PATH_INFO_GENERALE_ADMINISTRATIF,
  PATH_INFO_GENERALE_DECLARANT,
} from "common/path/path18Now";
import {
  useDeclaration2020,
  useDeclarationHelpers2020,
} from "../versionedElements/declarationHooks2020";
import GlobalFormActionFullContext2020 from "../versionedElements/GlobalFormActionFullContext2020";

const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  ref.current &&
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
}; // General scroll to element function

interface GeneralInformationsControllerProps {
  inspectionCode: string;
  referentielAPE: ReferenceCodeApeDto;
  referentielINSEE: ReferenceGeoCodeInseeDto;
  referentielEPRTR: ReferenceEprtrDto;
  referentielPays: ReferencePaysDto;
  referentielActivites: ReferenceActiviteQuotaDto;
  coordonneesDepartementMap: CoordonneesDepartementMap;
}

export const GeneralInformationsController = ({
  inspectionCode,
  referentielAPE,
  referentielINSEE,
  referentielEPRTR,
  referentielPays,
  referentielActivites,
  coordonneesDepartementMap,
}: GeneralInformationsControllerProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2020();

  const refForScroll = useRef<HTMLDivElement | null>(null);

  const declarationState: DeclarationStateDtoStateEnum =
    declaration.body.workflowStatus.general.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const shouldScrollToTechnique: boolean =
    declarationState === CREATED &&
    (userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
      userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
      userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
      userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN);
  //we set the refs and validation state here so it's all available for whole page validation and save
  const declarantIdentityProps = useFormikBloc<Declarants>();
  const companyIdentityProps = useFormikBloc<AdministrationValues>();
  const companyActivityProps = useFormikBloc<ActivityValues>();
  const companyTechniqueProps = useFormikBloc<TechniqueValues>();

  useLayoutEffect(() => {
    shouldScrollToTechnique && refForScroll && scrollToRef(refForScroll);
  }, [shouldScrollToTechnique, refForScroll]);

  const memoReferentielAPE: ReferenceItemCodeApeDto[] = useMemo(() => {
    return referentielAPE.codesApe.filter(code => code.disponible);
  }, [referentielAPE]);
  const memoReferentielINSEE: ReferenceItemGeoCodeInseeDto[] =
    referentielINSEE.referenceItemGeoCodeInseeDtoList;
  const memoReferentielPays: ReferenceItemPaysDto[] = useMemo(() => {
    const countriesFromReferentiel = referentielPays.referenceItemPaysDtoList;
    return [franceOption, ...countriesFromReferentiel];
  }, [referentielPays]);
  const memoReferentielEPRTR: ReferenceItemEprtrDto[] = useMemo(() => {
    return referentielEPRTR.referenceItemEprtrDtoList.filter(
      value => value.estSelectionnable
    );
  }, [referentielEPRTR]);

  const initialValues = useMemo(() => {
    return {
      declarants: computeDeclarantInitialValues(declaration),
      companyIdentity: computeAdminInitialValues(
        declaration,
        memoReferentielPays,
        memoReferentielINSEE
      ),
      companyActivity: computeActiviteInitialValues(
        declaration,
        inspectionCode,
        memoReferentielINSEE,
        memoReferentielAPE,
        coordonneesDepartementMap
      ),
    };
  }, [
    declaration,
    inspectionCode,
    memoReferentielAPE,
    memoReferentielINSEE,
    memoReferentielPays,
    coordonneesDepartementMap,
  ]);

  const hasChanges =
    declarantIdentityProps.hasChanges ||
    companyIdentityProps.hasChanges ||
    companyActivityProps.hasChanges;
  const allBlocksValidated =
    declarationState !== CREATED &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_DECLARANT) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ADMINISTRATIF) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ACTIVITE);

  return (
    <>
      <DeclarantIdentityList
        formikRef={declarantIdentityProps.formikRef}
        hasChanges={declarantIdentityProps.hasChanges}
        setHasChanges={declarantIdentityProps.setHasChanges}
        initialValues={initialValues.declarants}
        path={PATH_INFO_GENERALE_DECLARANT}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.declarants = createDeclarantsDto(
            values.declarants
          );
          return declaration;
        }}
      />

      <Row />

      <CompanyIdentity
        formikRef={companyIdentityProps.formikRef}
        hasChanges={companyIdentityProps.hasChanges}
        setHasChanges={companyIdentityProps.setHasChanges}
        initialValues={initialValues.companyIdentity}
        path={PATH_INFO_GENERALE_ADMINISTRATIF}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
            values
          );
          return declaration;
        }}
        referentielINSEE={memoReferentielINSEE}
        referentielPays={memoReferentielPays}
      />

      <Row />

      <CompanyActivity
        formikRef={companyActivityProps.formikRef}
        hasChanges={companyActivityProps.hasChanges}
        setHasChanges={companyActivityProps.setHasChanges}
        initialValues={initialValues.companyActivity}
        path={PATH_INFO_GENERALE_ACTIVITE}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.activite = createActivityDto(
            values
          );
          return declaration;
        }}
        referentielINSEE={memoReferentielINSEE}
        referentielAPE={memoReferentielAPE}
        coordonneesDepartementMap={coordonneesDepartementMap}
      />

      <Row />

      <div ref={refForScroll}>
        <CompanyTechnique
          formikRef={companyTechniqueProps.formikRef}
          hasChanges={companyTechniqueProps.hasChanges}
          setHasChanges={companyTechniqueProps.setHasChanges}
          referentielEPRTR={memoReferentielEPRTR}
          referentialActivites={referentielActivites}
        />
      </div>

      <GlobalFormActionFullContext2020
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasChanges}
        isValidateEnabled={allBlocksValidated}
        validationPath={PATH_INFO_GENERALE}
        updateHandler={declaration => {
          if (
            declarantIdentityProps.formikRef &&
            declarantIdentityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.declarants = createDeclarantsDto(
              declarantIdentityProps.formikRef.current.state.values.declarants
            );
          }

          if (
            companyIdentityProps.formikRef &&
            companyIdentityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
              companyIdentityProps.formikRef.current.state.values
            );
          }

          if (
            companyActivityProps.formikRef &&
            companyActivityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.activite = createActivityDto(
              companyActivityProps.formikRef.current.state.values
            );
          }

          return declaration;
        }}
        cancelAction={() => {
          declarantIdentityProps.formikRef &&
            declarantIdentityProps.formikRef.current &&
            declarantIdentityProps.formikRef.current.resetForm(
              initialValues.declarants
            );

          companyIdentityProps.formikRef &&
            companyIdentityProps.formikRef.current &&
            companyIdentityProps.formikRef.current.resetForm(
              initialValues.companyIdentity
            );

          companyActivityProps.formikRef &&
            companyActivityProps.formikRef.current &&
            companyActivityProps.formikRef.current.resetForm(
              initialValues.companyActivity
            );
        }}
      />
    </>
  );
};
