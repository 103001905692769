import React, { ReactElement, useCallback } from "react";
import ContactModal from "../modale/ContactModal";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { ContactModalProps } from "../modale/ContactModal/types";

const ContactInspectorQuotasModal = ({
  isOpen,
  onRequestClose,
  emailsDeclarants,
}: ContactModalProps): ReactElement => {
  const { declarationController } = useAuthenticatedApi();
  const contactInspector = useCallback(
    (
      emails: string[],
      annee: number,
      content: string,
      etablissement: string,
      subject: string
    ) =>
      declarationController.contactQuotasInspectorUsingPOST(
        annee,
        content,
        emails,
        etablissement,
        subject
      ),
    [declarationController]
  );

  return (
    <ContactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contactInspector={contactInspector}
      emailsDeclarants={emailsDeclarants}
    />
  );
};

export default ContactInspectorQuotasModal;
