import {
  commonNullableStringFields,
  commonPositiveNumberFields,
  commonStringFields,
  commonStringFieldsNoPipeAllowed,
  subStringFieldValidationMultipleScheme,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import * as Yup from "yup";
import { parseToUndefinedIfNull } from "common/form/utils";
import {
  appareilsUniqueMessage,
  futureDateMessage,
  hauteurRejetMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { AppareilInModale } from "../utils/types";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  AirCombustionAppareilDtoNatureEnum,
  AirCombustionAppareilDtoTypeFoyerEnum,
} from "api/gen";

export const autreSubPartNatureActivated = (
  nature: AirCombustionAppareilDtoNatureEnum | null
): boolean => nature === AirCombustionAppareilDtoNatureEnum.AUTRE;

export const autreSubPartTypeFoyerActivated = (
  type: AirCombustionAppareilDtoTypeFoyerEnum | null
): boolean => type === AirCombustionAppareilDtoTypeFoyerEnum.AUTRE;

export const isChaudiereSelected = (
  type: AirCombustionAppareilDtoNatureEnum | null
): boolean => type === AirCombustionAppareilDtoNatureEnum.CHAUDIERE;

export const singleAppareilOverwriteDependantFields = (
  appareil: AppareilInModale
): void => {
  if (!autreSubPartNatureActivated(appareil.nature)) {
    appareil.natureAutre = null;
  }
  if (
    isChaudiereSelected(appareil.nature) &&
    !autreSubPartTypeFoyerActivated(appareil.typeFoyer)
  ) {
    appareil.foyerAutre = null;
  }
  if (!isChaudiereSelected(appareil.nature)) {
    appareil.foyerAutre = null;
  }
};

export const singleAppareilValidationSchema = Yup.object().shape({
  nom: commonStringFieldsNoPipeAllowed,
  nameInstallation: commonNullableStringFields,
  date: commonStringFields,
  modification: commonNullableStringFields,
  lowestThan1500: commonNullableStringFields,
  localisation: commonNullableStringFields,
  activite: Yup.string()
    .trim()
    .transform(parseToUndefinedIfNull),
  nature: Yup.mixed<AirCombustionAppareilDtoNatureEnum>()
    .transform(parseToUndefinedIfNull)
    .required(requiredMessage),
  natureAutre: subStringFieldValidationScheme(
    "nature",
    autreSubPartNatureActivated
  ),
  typeFoyer: Yup.mixed<AirCombustionAppareilDtoTypeFoyerEnum>()
    .transform(parseToUndefinedIfNull)
    .nullable(),
  foyerAutre: subStringFieldValidationMultipleScheme(
    ["typeFoyer", "nature"],
    autreSubPartTypeFoyerActivated,
    isChaudiereSelected
  ),
  capacite: commonPositiveNumberFields,
  unite: commonStringFields,
  hauteurRejets: commonPositiveNumberFields.max(300, hauteurRejetMessage),
});

export const validate = (
  values: AppareilInModale,
  forbiddenAppareilName: string[]
): FormikErrors<AppareilInModale> => {
  const errors: FormikErrors<AppareilInModale> = {};
  if (forbiddenAppareilName.some(appareil => appareil === values.nom)) {
    errors.nom = appareilsUniqueMessage;
  }
  if (values.date && values.date > new Date()) {
    errors.date = futureDateMessage;
  }
  return errors;
};
