import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useDummyNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FORM_FIELD_STYLE } from "../theme";
import { BilanBiomasseDto } from "./types";

const useStyles = makeStyles({
  ...FORM_FIELD_STYLE,
  formContainer: {
    width: "75%",
    margin: "50px 0",
  },
});

interface BilanBiomasseFormProps {
  bilanBiomasse: BilanBiomasseDto;
}

const BilanBiomasseForm = ({
  bilanBiomasse,
}: BilanBiomasseFormProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "80%",
    formPrefix: "aer-bilan-biomasse",
  };

  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  return (
    <div className={classes.formContainer}>
      <DummyNumberField
        name={"emissionsCO2Durable"}
        label={
          "Pour information, total des émissions de CO2 biomasse durable en t CO2e/an"
        }
        value={bilanBiomasse.emissionsCo2BiomasseDurable}
        additionalClassNameInput={classes.centeredInput}
        unit={""}
      />
      <DummyNumberField
        name={"emissionsCO2NonDurable"}
        label={
          "Pour information, total des émissions de CO2 biomasse non durable en t CO2e/an"
        }
        value={bilanBiomasse.emissionsCo2BiomasseNonDurable}
        additionalClassNameInput={classes.centeredInput}
        unit={""}
      />
    </div>
  );
};

export default BilanBiomasseForm;
