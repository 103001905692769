import { ReferenceItemDepartementDto, ReferenceItemRegionDto } from "api/gen";

export const computeDepartementLabel = (
  departement: ReferenceItemDepartementDto
): string => {
  return `${departement.nom} (${departement.numero})`;
};

export const computeRegionLabel = (region: ReferenceItemRegionDto): string => {
  return `${region.nom}`;
};

export const filterRegionList = (
  regionList: ReferenceItemRegionDto[],
  departementAllowedList: ReferenceItemDepartementDto[]
): ReferenceItemRegionDto[] => {
  const listRegionCodeAllowed = departementAllowedList.reduce(
    (acc: Set<string>, singleDepartementDto: ReferenceItemDepartementDto) => {
      acc.add(singleDepartementDto.codeRegion);
      return acc;
    },
    new Set()
  );

  return regionList.filter(singleRegionDto =>
    listRegionCodeAllowed.has(singleRegionDto.codeRegion)
  );
};

export const filterDepartementsList = (
  departementsList: ReferenceItemDepartementDto[],
  departementNumbersList: string[]
): ReferenceItemDepartementDto[] => {
  return departementsList.filter(dep => {
    return departementNumbersList.includes(dep.numero);
  });
};

export const filterDepartementsInRegion = (
  departmentsList: ReferenceItemDepartementDto[],
  selectedRegion: ReferenceItemRegionDto | null
): ReferenceItemDepartementDto[] => {
  return departmentsList.filter(dep => {
    if (selectedRegion == null) {
      return true;
    }

    return dep.codeRegion === selectedRegion.codeRegion;
  });
};
