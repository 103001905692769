import React from "react";

export const estQuotasCo2Label =
  "L'établissement est soumis à la directive 2003/87/CE (directive quotas)";

export const getArticle27Label = (): React.ReactElement => (
  <>
    L’établissement est exclu au titre de l’article 27 (exclusion hôpitaux)
    <br />
    de la phase 4
  </>
);

export const getArcticle27BisLabel = (): React.ReactElement => (
  <>
    L’établissement est exclu au titre de l’article 27-bis (moins de 2 500
    tCO2e)
  </>
);
export const getIncinerateurLabel = (): React.ReactElement => (
  <>
    L’établissement exploite une installation d’incinération de déchets
    municipaux SEQE
  </>
);
