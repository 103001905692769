import React, { Fragment, ReactElement } from "react";

interface GroupedStickyColProps {
  sizeCells: number[];
  listCells: ReactElement[];
}
const GroupedCell = ({
  listCells,
  sizeCells,
}: GroupedStickyColProps): React.ReactElement => {
  return (
    <Fragment>
      {listCells.map((singleCell, idx) => (
        <div style={{ width: sizeCells[idx] }}>{singleCell}</div>
      ))}
    </Fragment>
  );
};

export default GroupedCell;
