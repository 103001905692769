import React, { ReactElement } from "react";
import { QuotasInstallationDto20Now, RapportEmissionDto22Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import Collapsible from "common/presentational/Collapsible";
import AerInfosGenerales from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/AerInfosGenerales";
import MethodesEmissionsData from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/MethodesEmissionsData";
import AerBilanTotal from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/AerBilanTotal";
import AerDonneesSurveillance from "./AerDonneesSurveillance";

const useStyles = makeStyles({
  collapsideTitle: {
    textAlign: "center",
  },
});

interface RapportEmissionDataProps {
  installation: QuotasInstallationDto20Now;
  rapportAerList: RapportEmissionDto22Now[];
}

const RapportEmissionData = ({
  installation,
  rapportAerList,
}: RapportEmissionDataProps): ReactElement => {
  const classes = useStyles();

  const rapportAer = rapportAerList.find(
    rapportData => rapportData.nimDynId === installation.id
  );

  if (rapportAer === undefined) {
    return <></>;
  }
  const { rapportEmissionData } = rapportAer;
  const {
    infosGeneralesEmissionDto,
    methodeSurveillanceDto,
    methodeCalculCo2List,
    methodeCalculPfcList,
    methodeMesureEmissionList,
    co2N2oTransferSourceList,
    co2N2oTransferDestinationList,
    methodeAlternativeDto,
    bilanTotalEmissionDto,
  } = rapportEmissionData;

  return (
    <Collapsible
      title={"SYNTHÈSE DU RAPPORT AER"}
      additionalTitleClassname={classes.collapsideTitle}
    >
      <>
        <AerInfosGenerales infosGeneralesAerDto={infosGeneralesEmissionDto} />
        <MethodesEmissionsData
          methodeSurveillanceDto={methodeSurveillanceDto}
          methodeCalculCo2List={methodeCalculCo2List}
          methodeCalculPfcList={methodeCalculPfcList}
          methodeMesureEmissionList={methodeMesureEmissionList}
          co2N2oTransferSourceList={co2N2oTransferSourceList}
          co2N2oTransferDestinationList={co2N2oTransferDestinationList}
          methodeAlternativeDto={methodeAlternativeDto}
        />
        <AerBilanTotal
          installation={installation}
          bilanTotalEmission={bilanTotalEmissionDto}
        />
        <AerDonneesSurveillance
          methodeCalculFluxExceptPfcList={methodeCalculCo2List}
          methodeMesureEmissionList={methodeMesureEmissionList}
          methodeCalculFluxPfcList={methodeCalculPfcList}
          methodeSurveillanceDto={methodeSurveillanceDto}
        />
      </>
    </Collapsible>
  );
};

export default RapportEmissionData;
