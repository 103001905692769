import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useDateFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { GeneralInformationsForm } from "./utils/types";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { convertDeclarationDtoUsingGeneralInformationsForm } from "../utils/converters";
import { convertDtoToGeneralInformationsForm } from "./utils/converters";
import {
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL,
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL_DATEFIN,
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL_EAU,
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMAX,
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMOY,
} from "common/path/path18Now";
import {
  additionalFieldValidation,
  validationSchema,
} from "./utils/validation";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";

interface BlocInfosGeneralesProps {
  generalInfosFormProps: FormikBlocRefAndState<GeneralInformationsForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const BlocInfosGenerales = ({
  generalInfosFormProps,
}: BlocInfosGeneralesProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration20Now();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "environnement-bloc-infos-generales",
  };
  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const initialValues = convertDtoToGeneralInformationsForm(declaration);

  return (
    <FormikBlocFullContext20Now
      hasChanges={generalInfosFormProps.hasChanges}
      setHasChanges={generalInfosFormProps.setHasChanges}
      formikRef={generalInfosFormProps.formikRef}
      title="INFORMATIONS GÉNÉRALES"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_GENERAL}
      areGlobalCommentsAllowed={true}
      updateHandler={convertDeclarationDtoUsingGeneralInformationsForm}
      additionalFieldValidation={additionalFieldValidation}
      renderContent={(_, shouldDisabledFields: boolean) => (
        <>
          <NumberField
            label="Production maximale autorisée par an *"
            name="maxProductionPerYear"
            unit="kt/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMAX}
          />

          <NumberField
            label="Production moyenne autorisée par an *"
            name="averageProductionPerYear"
            unit="kt/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_GENERAL_PRODMOY}
          />

          <DateField
            label="Date de fin d'autorisation *"
            name="endAuthorizationDate"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_GENERAL_DATEFIN}
          />

          <ChoiceSelectField
            label="Type de carrière *"
            name="eau"
            isMulti={false}
            options={selectPossibleValues.careerTypeEau}
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_GENERAL_EAU}
            enableReset={false}
          />

          <ChoiceSelectField
            label=""
            name="souterrain"
            isMulti={false}
            options={selectPossibleValues.careerTypeSouterrain}
            disabled={shouldDisabledFields}
            enableReset={false}
          />
        </>
      )}
    />
  );
};

export default BlocInfosGenerales;
