import React from "react";
import { WorkflowTarget } from "common/utils/types";
import { makeStyles } from "@material-ui/styles";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import {
  useAllocationsRapportOfficeFileHandler2020,
  useEmissionsRapportOfficeFileHandler2020,
} from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";

const useStyles = makeStyles({
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  uploadButton: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  uploadList: {
    flexDirection: "column",
    "&>div": {
      justifyContent: "center",
    },
  },
});

interface QuotasDeclarationOfficeUploadProps {
  workflowTarget: WorkflowTarget;
  isDisabled: boolean;
}

const QuotasDeclarationOfficeUpload = ({
  workflowTarget,
  isDisabled,
}: QuotasDeclarationOfficeUploadProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const classes = useStyles();
  const openAlertModale = useAlertModale();
  const emissionsRapportOfficeHandler = useEmissionsRapportOfficeFileHandler2020();
  const allocationsRapportOfficeHandler = useAllocationsRapportOfficeFileHandler2020();

  const filesPath =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? "body.sections.quotas.emissions.blocInspecteur.documents"
      : "body.sections.quotas.allocations.blocInspecteur.documents";

  const filesSection =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? FileSectionEnum.QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE
      : FileSectionEnum.QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE;

  const sectionFileHandler =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? emissionsRapportOfficeHandler
      : allocationsRapportOfficeHandler;

  return (
    <FilesLoader
      declaration={declaration}
      text={"Déposer rapport de déclaration d'office"}
      filesPath={filesPath}
      section={filesSection}
      fileHandlers={sectionFileHandler}
      //
      isDisabled={isDisabled}
      //
      additionalClassnameContainer={classes.uploadContainer}
      additionalClassnameButton={classes.uploadButton}
      additionalClassnameList={classes.uploadList}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
    />
  );
};

export default QuotasDeclarationOfficeUpload;
