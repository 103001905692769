import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { EmissionsInArray } from "./utils/types";
import _ from "lodash";
import { AirSolvantsInstallationDto } from "api/gen";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  emissionsInPage: EmissionsInArray[];
  setEmissionsInPage: Dispatch<SetStateAction<EmissionsInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationEmissionPath: string;
}

export const getInstallation = (
  installations: AirSolvantsInstallationDto[]
): React.ReactElement => {
  const installationText = installations
    .map(installation => installation.nom)
    .join(", ");

  return <div title={installationText}>{installationText}</div>;
};

const EmissionArray = ({
  emissionsInPage,
  setEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInPage: EmissionsInArray) {
    setEmissionsInPage(elderEmissions => {
      return elderEmissions.filter(elderEmissionInPage => {
        return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: EmissionsInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      if (!isValidated) {
        setEmissionInModale(emission);
        setEmissionModaleOpen(true);
      }
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      emission.data.substance !== null
        ? emission.data.substance.nom
        : undefined,
      isValidated,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.installations !== null
          ? getInstallation(emission.data.installations)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.pgsSimple ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.i1 !== null
          ? computeNumberWithSeparator(emission.data.i1)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.i2 !== null
          ? computeNumberWithSeparator(emission.data.i2)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o1 !== null
          ? computeNumberWithSeparator(emission.data.o1)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o2 !== null
          ? computeNumberWithSeparator(emission.data.o2)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o3 !== null
          ? computeNumberWithSeparator(emission.data.o3)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o4 !== null
          ? computeNumberWithSeparator(emission.data.o4)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o5 !== null
          ? computeNumberWithSeparator(emission.data.o5)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o6 !== null
          ? computeNumberWithSeparator(emission.data.o6)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o7 !== null
          ? computeNumberWithSeparator(emission.data.o7)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o8 !== null
          ? computeNumberWithSeparator(emission.data.o8)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.o9 !== null
          ? computeNumberWithSeparator(emission.data.o9)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.emission !== null
          ? computeNumberWithSeparator(emission.data.emission)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.emissionTotale !== null
          ? computeNumberWithSeparator(emission.data.emissionTotale)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.consommationSolvant !== null
          ? computeNumberWithSeparator(emission.data.consommationSolvant)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.utilisationSolvant !== null
          ? computeNumberWithSeparator(emission.data.utilisationSolvant)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.natureEquipement || undefined}>
          {emission.data.natureEquipement}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.rendement !== null
          ? computeNumberWithSeparator(emission.data.rendement)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        isValidated={isValidated}
        commentPath={`${validationEmissionPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = emissionsInPage.map(singleEmissionInPage => {
    return computeSingleEmissionLine(singleEmissionInPage, classes);
  });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Installation(s)</p>,
          <p>PGS simplifié</p>,
          <p>I1 (kg/an)</p>,
          <p>I2 (kg/an)</p>,
          <p>O1 (kg/an)</p>,
          <p>O2 (kg/an)</p>,
          <p>O3 (kg/an)</p>,
          <p>O4 (kg/an)</p>,
          <p>O5 (kg/an)</p>,
          <p>O6 (kg/an)</p>,
          <p>O7 (kg/an)</p>,
          <p>O8 (kg/an)</p>,
          <p>O9 (kg/an)</p>,
          <p>Émissions diffuses (kg/an)</p>,
          <p>Émissions totales (kg/an)</p>,
          <p>Consommation de solvants (kg/an)</p>,
          <p>Utilisation de solvants (kg/an)</p>,
          <p>Les émissions font l'objet d'épuration</p>,
          <p>Nature des équipements</p>,
          <p>Rendement d'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          250,
          200,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          180,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionArray;
