import {
  QuotasEmissionsVerificationDto20NowConclusionEnum,
  QuotasEmissionsVerificationDto20NowConditionDispenseEnum,
  QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum,
  QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum,
  QuotasEmissionsVerificationDto20NowOrganismeEnum,
} from "api/gen";

export const organismesLabels: {
  [key in QuotasEmissionsVerificationDto20NowOrganismeEnum]: string;
} = {
  APAVE: "APAVE",
  BUREAU_VERITAS_CERTIFICATION_FRANCE: "BUREAU VERITAS CERTIFICATION FRANCE",
  DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL:
    "DNV GL BUSINESS ASSURANCE France SARL",
  EY_ET_ASSOCIES: "EY & Associés",
  LRQA_FRANCE_SAS: "LRQA France SAS",
  SGS_FRANCE: "SGS FRANCE",
  SOCOTEC_ENVIRONNEMENT: "SOCOTEC Environnement",
};

export const organismesValues = Object.keys(
  organismesLabels
) as QuotasEmissionsVerificationDto20NowOrganismeEnum[];

export const exemptionConditionLabels: {
  [key in QuotasEmissionsVerificationDto20NowConditionDispenseEnum]: string;
} = {
  CONDITION_I: "Condition 1 (point 1 - art. 32 AVR)",
  CONDITION_II: "Condition 2 (point 2 - art. 32 AVR)",
  CONDITION_III: "Condition 3 (point 3 - art. 32 AVR)",
  CONDITION_IV: "Condition 4 (point 4 - art. 32 AVR)",
  CONDITION_V: "Condition 5 (point 5 - art. 32 AVR)",
  AUTRE: "Autre",
};

export const exemptionConditionOptions = Object.keys(
  exemptionConditionLabels
) as QuotasEmissionsVerificationDto20NowConditionDispenseEnum[];

export const verificationReportConclusionLabels: {
  [key in QuotasEmissionsVerificationDto20NowConclusionEnum]: string;
} = {
  SATISFAISANT: "Déclaration reconnue satisfaisante",
  SATISFAISANT_AVEC_REMARQUE:
    "Déclaration reconnue satisfaisante avec remarques",
  NON_SATISFAISANT:
    "Déclaration reconnue non satisfaisante (données ne pouvant pas être vérifiées)",
};

export const verificationReportConclusionOptions = Object.keys(
  verificationReportConclusionLabels
) as QuotasEmissionsVerificationDto20NowConclusionEnum[];

export const isReportUnsatisfying = (
  conclusion: QuotasEmissionsVerificationDto20NowConclusionEnum | null
): boolean => {
  return (
    conclusion ===
    QuotasEmissionsVerificationDto20NowConclusionEnum.NON_SATISFAISANT
  );
};

export const isSatisfyingWithRequests = (
  conclusion: QuotasEmissionsVerificationDto20NowConclusionEnum | null
): boolean => {
  return (
    conclusion ===
    QuotasEmissionsVerificationDto20NowConclusionEnum.SATISFAISANT_AVEC_REMARQUE
  );
};

export const satisfyingDeclarationReasonLabels: {
  [key in QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum]: string;
} = {
  INEXACTITUDE: "Inexactitude(s) non significative(s)",
  IRREGULARITE:
    "Irrégularité(s) n'entraînant pas d'inexactitude(s) significative(s)",
  NON_RESPECT_MRR:
    "Cas de non-respect du règlement MRR n'entraînant pas d'inexactitude(s) significative(s)",
  RECOMMANDATION_AMELIORATION: "Recommandation(s) d'amélioration",
};

export const satisfyingDeclarationReasonOptions = Object.keys(
  satisfyingDeclarationReasonLabels
) as QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum[];

export const unsatisfyingDeclarationReasonLabels: {
  [key in QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum]: string;
} = {
  INEXACTITUDE: "Inexactitude(s) significative(s)",
  IRREGULARITE:
    "Irrégularité(s) entraînant une ou plusieurs inexactitude(s) significative(s)",
  NON_RESPECT_MRR:
    "Cas de non-respect du règlement MRR entraînant une ou plusieurs inexactitude(s) significative(s)",
  DONNES_MANQUANTE_VERIFICATION:
    "Portée limitée due à des données manquantes ou des informations fournies pour la vérification insuffisantes",
  PLAN_SURVEILLANCE_INCOMPLET:
    "Portée limitée ou clarté insuffisante du plan de surveillance",
  PLAN_SURVEILLANCE_NON_APPROUVE:
    "Portée limitée due à un plan de surveillance non approuvé par l'autorité compétente",
};

export const unsatisfyingDeclarationReasonOptions = Object.keys(
  unsatisfyingDeclarationReasonLabels
) as QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum[];
