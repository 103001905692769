import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { InertWasteForm } from "./types";

export const convertDtoToInertWasteForm = (
  declaration: Declaration1919
): InertWasteForm => {
  return {
    inertWasteBring:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .aDesApportsDechetsInertes,
    remainingCapacityEndRefYear:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .capaciteRestanteTermeAnneeRef,
    groundAndStoneToBackfill:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRemblayer.terreEtCailloux,
    groundAndStoneToRecycle:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRecycler.terreEtCailloux,
    otherStuffToBackfill:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRemblayer.autresMateriaux,
    otherStuffToRecycle:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRecycler.autresMateriaux,
  };
};
