import React from "react";
import DummyCheckBox from "../dumbInput/DummyCheckBox";
import { computeErrorObject } from "./utils";
import { CheckBoxProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const CheckBox = ({
  field,
  form,
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: CheckBoxProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyCheckBox
      {...props}
      value={externalValue !== undefined ? externalValue : field.value || false}
      disabled={externalValue !== undefined ? true : disabled}
      onChange={newValue => {
        form.setFieldValue(field.name, newValue);
        form.setFieldTouched(field.name);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newValue);
        }
      }}
      name={field.name}
      error={error}
    />
  );
};

export default CheckBox;
