import {
  PATH_CARRIERE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_EAU,
  PATH_INFO_GENERALE,
  PATH_QUOTAS,
} from "common/path/path18Now";
import BigCard from "./cards/BigCard";
import React from "react";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import idCardImg from "icons/dashboardLogos/id-card.svg";
import waterImg from "icons/dashboardLogos/water.svg";
import helmetImg from "icons/dashboardLogos/helmet.svg";
import growthImg from "icons/dashboardLogos/growth.svg";
import deliveryTruckImg from "icons/dashboardLogos/delivery-truck.svg";
import insuranceImg from "icons/dashboardLogos/insurance.svg";
import { makeStyles } from "@material-ui/styles";
import { FOUR_COLUMNS_CARD_GRID } from "theme";
import co2GasImg from "icons/dashboardLogos/co2-gas.png";
import { useQuotaState1919 } from "../../versionedElements/declarationHooks1919";
import { useUserData } from "../../../../../../Authenticator/UserData";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../../../api/gen";
import { isCarriereDisabled, isEveryCarriereValidated } from "./utils";

interface DashboardCardsProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
}

const useStyles = makeStyles({
  mediaQueries: FOUR_COLUMNS_CARD_GRID,
});

const LecteurDashboardCards = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: DashboardCardsProps): React.ReactElement => {
  const classes = useStyles();
  const { isLecteurOnSection } = useUserData();
  const quotasState = useQuotaState1919();
  return (
    <>
      {(isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU) ||
        isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE)) && (
        <BigCard
          picture={{ src: idCardImg, alt: "" }}
          text="Informations générales"
          percentageProgress={declarationApi.getPathProgress(
            PATH_INFO_GENERALE
          )}
          isValidated={declarationApi.isPathValidatedInDeclaration(
            PATH_INFO_GENERALE
          )}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_INFO_GENERALE}`}
          isDisabled={!declarationApi.getIsActiveSection(PATH_INFO_GENERALE)}
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU) && (
        <BigCard
          picture={{ src: waterImg, alt: "" }}
          text="Eau"
          percentageProgress={declarationApi.getPathProgress(PATH_EAU)}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_EAU}`}
          isDisabled={!declarationApi.getIsActiveSection(PATH_EAU)}
          isValidated={declarationApi.isPathValidatedInDeclaration(PATH_EAU)}
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS) && (
        <BigCard
          picture={{ src: co2GasImg, alt: "" }}
          text="Quotas"
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS}`}
          percentageProgress={declarationApi.getPathProgress(PATH_QUOTAS)}
          isDisabled={!declarationApi.getIsActiveSection(PATH_QUOTAS)}
          isValidated={declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS)}
          note={
            declarationApi.getIsActiveSection("/quotas")
              ? quotasState.stateMessage
              : undefined
          }
          disabledMessage={
            "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales"
          }
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) && (
        <BigCard
          isClickable={false}
          picture={{ src: helmetImg, alt: "" }}
          text="Carrière"
          percentageProgress={declarationApi.getPathProgress(PATH_CARRIERE)}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`}
          isDisabled={isCarriereDisabled(declarationApi)}
          isValidated={isEveryCarriereValidated(declarationApi)}
          disabledMessage={
            "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales"
          }
          subCards={[
            {
              text: "Environnement",
              picture: { src: growthImg, alt: "" },
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`,
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
            },
            {
              text: "Production",
              picture: { src: deliveryTruckImg, alt: "" },
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_PRODUCTION
              ),
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_PRODUCTION}`,
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_PRODUCTION
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_PRODUCTION
              ),
            },
            {
              text: "Santé / Sécurité",
              picture: { src: insuranceImg, alt: "" },
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_SANTE}`,
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_SANTE
              ),
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_SANTE
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_SANTE
              ),
            },
          ]}
        />
      )}
    </>
  );
};
export default LecteurDashboardCards;
