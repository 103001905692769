import React, { ReactElement } from "react";
import { PointMesureDto } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import { formatNiveauFlux } from "./utils";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface MethodeSourceEmissionListProps {
  mesureSourceEmissionList: PointMesureDto[];
}

const MethodeSourceEmissionList = ({
  mesureSourceEmissionList,
}: MethodeSourceEmissionListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de mesure par source
        d'émission
      </SubSectionTitle>

      {mesureSourceEmissionList.map(
        ({
          classementFlux: { referenceFlux, fluxFullName },
          gazMesure,
          niveauApplique,
          niveauRequis,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${fluxFullName || ""} - ${referenceFlux ||
              ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p></p>,
                <p>Gaz mesuré</p>,
                <p>Niveau appliqué</p>,
                <p>Niveau requis</p>,
              ]}
              lines={[
                [
                  computeWrappedCell("Points de mesure", classes),
                  computeWrappedCell(gazMesure, classes),
                  computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
                  computeWrappedCell(formatNiveauFlux(niveauRequis), classes),
                ],
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </div>
  );
};

export default MethodeSourceEmissionList;
