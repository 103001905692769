import React, { ReactElement } from "react";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import FirstLineButtonsContainer from "../Dashboard/Components/Container/FirstLineButtonsContainer";
import ExcelDownloadButton from "./ExcelDownloadButton";
import PdfDownloadButton from "./PdfDownloadButton";
import DeleteEtablissementButton from "./DeleteEtablissementButton";
import { useUserData } from "../../../../../Authenticator/UserData";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  pushedLeft: {},

  container: {
    "& $pushedLeft": {
      marginLeft: "auto",
    },
  },
});

const DeclarationFirstLineButtons = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const { isSuperAdmin, isGerepAdmin } = useUserData();
  const classes = useStyles();

  return (
    <FirstLineButtonsContainer additionalClassName={classes.container}>
      <ExcelDownloadButton
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
      <PdfDownloadButton
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />

      {(isSuperAdmin || isGerepAdmin) && (
        <DeleteEtablissementButton
          etablissementCode={declarationEtablissement}
          additionalClassName={classes.pushedLeft}
        />
      )}
    </FirstLineButtonsContainer>
  );
};

export default DeclarationFirstLineButtons;
