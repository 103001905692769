import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { useComputeFirstLineContentCell } from "common/utils/computeWrappedCell";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../../utils/utils";
import { EmissionsInArray, FacteurEmissionInArray } from "../utils/types";
import { ERROR } from "theme";
import { ReferentialSinglePolluants } from "../../../utils/types";
import { isCo2 } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/biomasse/helpers";
import {
  computeCo2EmissionLine,
  computeSingleEmissionLine,
} from "./arrayUtils";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  facteurEmissionsInArray: FacteurEmissionInArray[];
  setFacteurEmissionsInArray: Dispatch<
    SetStateAction<FacteurEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationFacteursPath: string;
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const EmissionFacteurArray = ({
  facteurEmissionsInArray,
  setFacteurEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationFacteursPath,
  referentialSinglePolluants,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const arraySubstancesLines = filterByInstallation(
    facteurEmissionsInArray,
    installationName || ""
  ).reduce(
    (arrayLines: React.ReactElement[][], facteur: FacteurEmissionInArray) => {
      const isEmissionCo2 = isCo2(
        facteur.data.substance,
        referentialSinglePolluants.CO2
      );

      if (isEmissionCo2) {
        arrayLines.push(
          ...computeCo2EmissionLine(
            facteur,
            classes,
            setEmissionInModale,
            setEmissionModaleOpen,
            setFacteurEmissionsInArray,
            isValidated,
            validationFacteursPath,
            computeFirstLineContentCell
          )
        );
        return arrayLines;
      }
      arrayLines.push(
        computeSingleEmissionLine(
          facteur,
          classes,
          setEmissionInModale,
          setEmissionModaleOpen,
          setFacteurEmissionsInArray,
          isValidated,
          validationFacteursPath,
          computeFirstLineContentCell
        )
      );

      return arrayLines;
    },
    []
  );

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Combustible</p>,
        <p>Référence du combustible</p>,
        <p>Consommation annuelle</p>,
        <p>Unité</p>,
        <p>Facteur d'émission (kg/GJ)</p>,
        <p>Préciser écart facteur d'émission</p>,
        <p>Provenance du facteur d'émission</p>,
        <p>Facteur d'oxydation du carbone (%)</p>,
        <p>Provenance du facteur d'oxydation du carbone</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        130,
        130,
        200,
        130,
        200,
        200,
        130,
        200,
        130,
        200,
        130,
        130,
        90,
      ]}
    />
  );
};

export default EmissionFacteurArray;
