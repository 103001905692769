import {
  BuildingNh3Emissions,
  nh3DetailsFieldMatcher,
} from "../../utils/types";
import { AirElevageDetailNh320NowEspecesDeclareesEnum } from "api/gen";

export const especeLabels: {
  [key in AirElevageDetailNh320NowEspecesDeclareesEnum]: string;
} = {
  CAILLES: "Cailles",
  CANARDS: "Canards",
  DINDES_ET_DINDONS: "Dindes et dindons",
  PINTADES: "Pintades",
  POULES_PONDEUSES: "Poules pondeuses",
  POULETS_DE_CHAIR: "Poulets de chair",
  POULETTES: "Poulettes",
  AUTRES_VOLAILLES: "Autres volailles",
  PORCELETS_EN_POST_SEVRAGE: "Porcelets en post-sevrage",
  PORCS_DE_PRODUCTION: "Porcs de production",
  COCHETTES: "Cochettes",
  TRUIES_EN_MATERNITE: "Truies en maternité",
  TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES:
    "Truies en attente de saillie et truies gestantes",
  VERRATS: "Verrats",
  APPROCHE_GLOBALE_PORCS: "Approche globale porcs",
};

export const especesList: AirElevageDetailNh320NowEspecesDeclareesEnum[] = Object.keys(
  especeLabels
) as AirElevageDetailNh320NowEspecesDeclareesEnum[];

// This map is used to make the link between the species selected and the correspondong field for nh3 emissions
const completeSpeciesNh3Mapper = new Map<
  AirElevageDetailNh320NowEspecesDeclareesEnum,
  keyof BuildingNh3Emissions
>([
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.CAILLES,
    nh3DetailsFieldMatcher.caillesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.CANARDS,
    nh3DetailsFieldMatcher.canardsEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.DINDES_ET_DINDONS,
    nh3DetailsFieldMatcher.dindesDindonsEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.PINTADES,
    nh3DetailsFieldMatcher.pintadesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULES_PONDEUSES,
    nh3DetailsFieldMatcher.poulesPondeusesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULETS_DE_CHAIR,
    nh3DetailsFieldMatcher.pouletsDeChairEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULETTES,
    nh3DetailsFieldMatcher.poulettesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.AUTRES_VOLAILLES,
    nh3DetailsFieldMatcher.autresVolaillesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.PORCELETS_EN_POST_SEVRAGE,
    nh3DetailsFieldMatcher.porceletsEnPostSevrageEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.PORCS_DE_PRODUCTION,
    nh3DetailsFieldMatcher.porcsDeProductionEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.COCHETTES,
    nh3DetailsFieldMatcher.cochettesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.TRUIES_EN_MATERNITE,
    nh3DetailsFieldMatcher.truiesEnMaterniteEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES,
    nh3DetailsFieldMatcher.truiesEnAttenteSaillieEtGestantesEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.VERRATS,
    nh3DetailsFieldMatcher.verratsEmission,
  ],
  [
    AirElevageDetailNh320NowEspecesDeclareesEnum.APPROCHE_GLOBALE_PORCS,
    nh3DetailsFieldMatcher.approcheGlobalePorcsEmission,
  ],
]);

export const getSpecieEmissionField = (
  specie: AirElevageDetailNh320NowEspecesDeclareesEnum
): keyof BuildingNh3Emissions => {
  const specieEmissionField = completeSpeciesNh3Mapper.get(specie);

  if (specieEmissionField === undefined) {
    throw Error("All species must be in the Map");
  }
  return specieEmissionField;
};
