import React from "react";
import { makeStyles } from "@material-ui/styles";
import { CustomButtonProps } from "./type";
import classNames from "classnames";
import { PINK } from "theme";

const useStyles = makeStyles({
  container: {
    width: "31px",
    height: "31px",
    background: "white",
    borderRadius: "50%",
    color: "white",
    border: `2px solid ${PINK}`,
    borderStyle: "solid",
    cursor: "pointer",
  },
});

const CircularButton = ({
  additionalClassname,
  children,
  onClick,
}: CustomButtonProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <button
      onClick={onClick}
      className={classNames(classes.container, additionalClassname)}
    >
      {children}
    </button>
  );
};

export default CircularButton;
