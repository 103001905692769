import {
  ProduitTransportDto1822,
  ModaliteTransportDto,
  ReferencePaysDto,
  ReferenceDepartementDto,
  ReferenceItemDepartementDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageCodeProduitDto,
} from "api/gen";
import {
  PopulatedTransportProductInArray,
  TransportProductsForm,
} from "./types";
import {
  convertStringFamilyProductionToOptionProps,
  convertFamilyProductionNameToOptionProps,
} from "../../Families/converters";
import { franceOrOtherCountry } from "./selectPossibleValues";
import { convertStringCountryToModalOptionProps } from "../../utils/converters";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { familiesProduction } from "../../Families/selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { generateLabelFamily } from "./computers";

export const convertStringDepartmentToModalOptionProps = (
  departmentID: string,
  departmentRef: ReferenceItemDepartementDto[]
): OptionPropsWithObject<ReferenceItemDepartementDto | null, string> => {
  const department = findElementMatchingTemplate(
    { uuid: departmentID },
    departmentRef
  );
  const text = department ? `${department.numero} - ${department.nom}` : "";
  return {
    value: text,
    label: text,
    object: department,
  };
};

export const convertInFranceStringToBoolean = (
  inFranceLabel: string
): boolean => {
  return inFranceLabel === franceOrOtherCountry[0];
};

export const convertInFranceBooleanToString = (
  inFranceBoolean: boolean
): string => {
  return inFranceBoolean ? franceOrOtherCountry[0] : franceOrOtherCountry[1];
};

export const convertProduitTransportDtoToDisplayed = (
  produitsTransportDto: ProduitTransportDto1822[],
  referentialDepartments: ReferenceDepartementDto,
  referentialCountries: ReferencePaysDto,
  referentialFamilies: ReferenceFamilleUsageProductionDto,
  referentialFamiliesCodeProduits: ReferenceFamilleUsageCodeProduitDto
): PopulatedTransportProductInArray[] => {
  return produitsTransportDto.map(produitTransportDto => {
    const paysName =
      produitTransportDto.paysID === null
        ? null
        : findElementMatchingTemplate(
            { uuid: produitTransportDto.paysID },
            referentialCountries.referenceItemPaysDtoList
          );

    return {
      id: produitTransportDto.id,
      displayedProduct: {
        usageFamily: convertStringFamilyProductionToOptionProps(
          produitTransportDto.famille,
          referentialFamilies
        ),
        usageFamilyPrecision: produitTransportDto.precisionAutre,
        // TODO GEREP-763 label should purely be computed from object...
        productType: {
          label: generateLabelFamily(
            produitTransportDto.typeProduit,
            referentialFamiliesCodeProduits
          ),
          value: produitTransportDto.typeProduit,
          object: {
            codeValue: produitTransportDto.typeProduit,
          },
        },
        inFrance: convertInFranceBooleanToString(produitTransportDto.enFrance),
        department: produitTransportDto.franceEntiere
          ? {
              value: "0 - France entière",
              label: "0 - France entière",
              object: null,
            }
          : convertStringDepartmentToModalOptionProps(
              produitTransportDto.departementID || "",
              referentialDepartments.departements
            ),
        country: convertStringCountryToModalOptionProps(
          paysName ? paysName.designation : "",
          referentialCountries
        ),
        tonnage: produitTransportDto.tonnage,
        isFranceEntiere: produitTransportDto.franceEntiere,
      },
    };
  });
};

export const convertDisplayedToProduitTransportDto = (
  transportProductsInArray: PopulatedTransportProductInArray[]
): ProduitTransportDto1822[] => {
  return transportProductsInArray.map(transportProductInArray => {
    return {
      id: transportProductInArray.id,
      famille: transportProductInArray.displayedProduct.usageFamily
        ? transportProductInArray.displayedProduct.usageFamily.object.uuid
        : "",
      precisionAutre:
        transportProductInArray.displayedProduct.usageFamilyPrecision,
      typeProduit:
        transportProductInArray.displayedProduct.productType.object.codeValue,
      enFrance: convertInFranceStringToBoolean(
        transportProductInArray.displayedProduct.inFrance
      ),
      departementID:
        transportProductInArray.displayedProduct.department &&
        transportProductInArray.displayedProduct.department.object
          ? transportProductInArray.displayedProduct.department.object.uuid
          : null,
      paysID:
        transportProductInArray.displayedProduct.country &&
        transportProductInArray.displayedProduct.country.object
          ? transportProductInArray.displayedProduct.country.object.uuid
          : null,
      tonnage: transportProductInArray.displayedProduct.tonnage,
      franceEntiere: transportProductInArray.displayedProduct.isFranceEntiere,
    };
  });
};

export const convertModaliteTransportDtoToDisplayed = (
  modaliteTransportsDto: ModaliteTransportDto[],
  referentialFamilies: ReferenceFamilleUsageProductionDto
): TransportProductsForm[] => {
  return modaliteTransportsDto.map(modaliteTransport => {
    // to know the family used in the modaliteTransport, we have to search with the name of the famille in the referential
    const family =
      convertFamilyProductionNameToOptionProps(
        modaliteTransport.famille,
        referentialFamilies
      ) || familiesProduction(referentialFamilies)[0];
    return {
      id: family.value.toString(),
      usageFamily: family,
      byRoad: modaliteTransport.route,
      byRail: modaliteTransport.rail,
      byWaterWay: modaliteTransport.navigable,
      other: modaliteTransport.autre,
      otherPrecision: modaliteTransport.preciserAutre,
    };
  });
};

export const convertDisplayedToModaliteTransportDto = (
  transportProductsForm: Record<number, TransportProductsForm>
): ModaliteTransportDto[] => {
  return Object.values(transportProductsForm).map(product => {
    return {
      id: product.usageFamily.value.toString(),
      famille: product.usageFamily.label,
      route: product.byRoad,
      rail: product.byRail,
      navigable: product.byWaterWay,
      autre: product.other,
      preciserAutre: product.otherPrecision,
    };
  });
};
