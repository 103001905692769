import * as Yup from "yup";
import {
  commonNumberFields,
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
} from "../utils/utils";
import { GasEnum } from "../types";
import { requiredMessage } from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  methodDescription: commonStringFields,
  nimInstallation: commonObjectFields,
  gas: Yup.mixed<GasEnum>().required(requiredMessage),
  fossilEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => gas && isBiomasseSubPartActivated(gas),
    then: commonNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  biomassEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => gas && isBiomasseSubPartActivated(gas),
    then: commonNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  otherEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => gas && isOtherEmissionSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
});
