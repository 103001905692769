import React, { ReactElement } from "react";
import { AerDonneeSurveillanceDto, MethodeCalculCo2Dto22Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import { formatNiveauFlux } from "../../BlocPlanSurveillance/BlocDonneeSurveillance/utils";
import { computeEmissionWrappedCell } from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/MethodesEmissionsData/EmissionsArrays/arrayUtils";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

type AerMethodeCalculFluxExceptPfc = Pick<
  MethodeCalculCo2Dto22Now,
  | "referenceFlux"
  | "fluxName"
  | "donneeActivite"
  | "pouvoirCalorifique"
  | "facteurEmission"
  | "facteurOxydation"
  | "facteurConversion"
  | "teneurCarbone"
  | "fractionBiomasse"
  | "fractionBiomasseNonDurable"
>;

export interface MethodeCalculFluxExceptPfcListProps {
  methodeCalculFluxExceptPfcList: AerMethodeCalculFluxExceptPfc[];
}

const MethodeCalculFluxExceptPfcList = ({
  methodeCalculFluxExceptPfcList,
}: MethodeCalculFluxExceptPfcListProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de calcul par flux - hors
        PFC
      </SubSectionTitle>
      {methodeCalculFluxExceptPfcList.map(
        ({
          referenceFlux,
          fluxName,
          donneeActivite,
          facteurConversion,
          facteurEmission,
          facteurOxydation,
          fractionBiomasse,
          fractionBiomasseNonDurable,
          pouvoirCalorifique,
          teneurCarbone,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${fluxName || ""} - ${referenceFlux ||
              ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p></p>,
                <p>Valeur</p>,
                <p>Unité</p>,
                <p>Niveau appliqué</p>,
              ]}
              lines={[
                computeDonneeSurveillanceLine(
                  "Données d'activité",
                  donneeActivite,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Pouvoir calorifique inférieur",
                  pouvoirCalorifique,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Facteur d'émission préliminaire",
                  facteurEmission,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Facteur d'oxydation",
                  facteurOxydation,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Facteur de conversion",
                  facteurConversion,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Teneur en carbone",
                  teneurCarbone,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Fraction biomasse durable",
                  fractionBiomasse,
                  classes
                ),
                computeDonneeSurveillanceLine(
                  "Fraction biomasse non durable",
                  fractionBiomasseNonDurable,
                  classes
                ),
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </>
  );
};

function computeDonneeSurveillanceLine(
  lineDescription: string,
  { valeur, unite, niveauApplique }: AerDonneeSurveillanceDto,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell(lineDescription, classes),
    computeEmissionWrappedCell(valeur, classes),
    computeWrappedCell(unite, classes),
    computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
  ];
}

export default MethodeCalculFluxExceptPfcList;
