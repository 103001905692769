import {
  DeclarationDto1819,
  DeclarationSectionsDto1819,
  QuotasBlocVerificationDto1819,
  TypeActiviteDto1819,
} from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  backMessageApproveDeclaration,
  backMessageOnResetDeclaration,
  backMessageOnStartDeclaration,
  backMessageOnUpdateTypeActivite,
  backMessageOnYieldQuotas,
  backMessageSetVerification,
  backSubmitSimplifiedQuotas,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { DeclarationApiContext } from "pages/CompanySpace/DeclarationApiContext/DeclarationApiContext";
import { useCallback, useContext } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationState,
  useDeclarationStateHandlers,
  useQuotasState,
  useUpdateDeclarationTypeActivite,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  AnyDeclarationDto,
  BasicDeclarationHandlers,
  DeclarationState,
  DeclarationStateHandlers18Now,
} from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../toNow/versionedElements/declarationHooks19Now";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2018;
export const MAX_YEAR = 2019;

// Define all the DTO types for this version.
export type Declaration1919 = DeclarationDto1819;
export type TypeActivite1919 = TypeActiviteDto1819;
type DeclarationStateHandlers1919 = DeclarationStateHandlers18Now & {
  submitSimplifiedQuota: (target: QuotaWorkflowTarget) => Promise<boolean>;
  approve: (targets: WorkflowTarget[]) => Promise<boolean>;
  yieldQuotaVerif(): Promise<boolean>;
  updateQuotaVerif: (
    newQuotaVerif: QuotasBlocVerificationDto1819
  ) => Promise<boolean>;
  start: (newTypeActivite: TypeActivite1919) => Promise<boolean>;
  reset: () => Promise<boolean>;
};

export const useDeclaration1919 = (
  shouldThrowIfWrongYear?: boolean
): Declaration1919 => {
  return useDeclaration<DeclarationDto1819>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationState1919 = (): DeclarationState => {
  const getDeclarationState = useCallback(
    (declarationDto: Declaration1919) =>
      declarationDto.body.workflowStatus.general.state,
    []
  );

  return useDeclarationState<Declaration1919>(
    getDeclarationState,
    MIN_YEAR,
    MAX_YEAR
  );
};

export const useQuotaState1919 = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration1919) =>
      declarationDto.body.workflowStatus.quotaEmissions.state,
    []
  );

  return useQuotasState<Declaration1919>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useDeclarationHelpers1919 = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers1919 = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration1919> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration1919): DeclarationSectionsDto1819 =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration1919>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationStateHandlers1919 = (
  shouldThrowIfWrongYear?: boolean
): DeclarationStateHandlers1919 => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const approve = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.approveDeclaration1819UsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageApproveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActivite1919
    ): Promise<Declaration1919> => {
      // the as is justified because the back returns AbstractDeclarationsDto, and so far there is only one route.
      return declarationController.setTypeActivite1819UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateQuotaVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasBlocVerificationDto1819
    ): Promise<boolean> => {
      try {
        const returnedResult: DeclarationDto1819 = await declarationController.setVerification1819UsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        // the VersionedDeclarationDto type parameter should ALWAYS be subset of AnyDeclarationDto that can be assigned to it, but I didn't find a way to write it in ts
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const submitSimplifiedQuotaHandler = useCallback(async (): Promise<
    boolean
  > => {
    try {
      const returnedResult = await declarationController.submitSimplifiedQuotas1819UsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backSubmitSimplifiedQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const yieldQuotaVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota1819UsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const start = useCallback(
    async (newActiviteType: TypeActivite1919): Promise<boolean> => {
      let returnedResult: Declaration1919;
      try {
        returnedResult = await updateTypeActivite(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        //as justified because we threw at the beginning in case the year passed didn't match the declaration given, and
        // caller is responsible for passing year and type parameter in a sensible way
        returnedResult = await declarationController.startDeclaration1819UsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale, updateTypeActivite]
  );

  const reset = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetDeclaration1819UsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const stateHandlers18Now = useDeclarationStateHandlers(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );

  return {
    ...stateHandlers18Now,
    submitSimplifiedQuota: submitSimplifiedQuotaHandler,
    approve,
    yieldQuotaVerif,
    updateQuotaVerif,
    start,
    reset,
  };
};

export const useUpdateDeclarationTypeActivite1919 = (): ((
  newActiviteType: TypeActivite1919
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActivite1919
    ): Promise<Declaration1919> => {
      return declarationController.setTypeActivite1819UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  return useUpdateDeclarationTypeActivite<TypeActivite1919, Declaration1919>(
    updateTypeActivite,
    MIN_YEAR,
    MAX_YEAR
  );
};
