import React, { ReactNode } from "react";

interface FallBackComponentProps {
  error: string;
}

interface Props {
  children: ReactNode;
  FallbackComponent: React.ComponentType<FallBackComponentProps>;
}
interface State {
  hasError: boolean;
  errorMessage: string;
}
class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorMessage: error.message };
  }
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }
  componentDidCatch(error: Error): void {
    // Send error to your monitoring system
  }
  render(): React.ComponentType<FallBackComponentProps> | ReactNode {
    const { hasError, errorMessage } = this.state;
    const { FallbackComponent, children } = this.props;
    return hasError ? <FallbackComponent error={errorMessage} /> : children;
  }
}
export default ErrorBoundary;
