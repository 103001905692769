import React from "react";
import {
  ComputedEmissionInArray,
  ComputedEmissionInModale,
  FlowDeclarationProps,
  GasEnum,
  MethodEnum,
} from "../types";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import _ from "lodash";
import {
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import uuid from "uuid";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { methodLabels } from "../selectPossibleValues";
import { validationSchema } from "./validation";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface ComputedEmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  setComputedEmissionInModal: (
    emission: ComputedEmissionInArray | null
  ) => void;
  computedEmissionInModal: ComputedEmissionInArray | null;
  flowDeclarations: FlowDeclarationProps[];
  allowedMethods: MethodEnum[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const ComputedEmissionsModal = ({
  isOpen,
  setIsOpen,
  setComputedEmissionsInArray,
  setComputedEmissionInModal,
  computedEmissionInModal,
  flowDeclarations,
  allowedMethods,
}: ComputedEmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emissions-modale-calculees",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);

  //the value is an id because the id field is unique to each flow
  const flowOptions = flowDeclarations.filter(flow => flow.name);

  const onClose = () => {
    setComputedEmissionInModal(null);
    setIsOpen(false);
  };

  const defaultGasDummyValue = GasEnum.CO2;

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UNE ÉMISSION"
        closeFunction={onClose}
        isEdit={computedEmissionInModal !== null}
        initialEntity={_.get(computedEmissionInModal, "data", {
          method: null,
          flow: null,
          gas: null,
          fossilEmission: null,
          biomassEmission: null,
          otherEmission: null,
        })}
        validationSchema={validationSchema}
        onSubmit={(values: ComputedEmissionInModale) => {
          const emission: ComputedEmissionInArray = {
            //TODO compute errors
            data: {
              id: computedEmissionInModal
                ? computedEmissionInModal.data.id
                : uuid(),
              method: values.method,
              flow: values.flow,
              gas: defaultGasDummyValue,
              fossilEmission: values.fossilEmission,
              biomassEmission: values.biomassEmission,
              otherEmission: null,
            },
            errors: {},
          };
          setComputedEmissionsInArray(currentComputedEmissionsInArray => {
            const newComputedEmissionsInArray = _.cloneDeep(
              currentComputedEmissionsInArray
            );
            let indexToReplace = newComputedEmissionsInArray.length;
            if (computedEmissionInModal) {
              indexToReplace = _.findIndex(
                newComputedEmissionsInArray,
                computedEmissionInModal
              );
              setComputedEmissionInModal(null);
            }
            newComputedEmissionsInArray[indexToReplace] = emission;
            return newComputedEmissionsInArray;
          });
          setIsOpen(false);
        }}
        renderField={({ values }) => {
          const selectedFlow = flowDeclarations.find(
            flow => !!(values.flow && flow.id === values.flow.id)
          );

          return (
            <>
              <WrappedChoiceSelect
                label="Méthode utilisée *"
                name="method"
                isMulti={false}
                options={allowedMethods}
                computeLabel={method => methodLabels[method]}
                commonProps={commonProps}
              />

              <WrappedChoiceSelect
                label="Nom du flux *"
                name="flow"
                isMulti={false}
                options={flowOptions}
                computeLabel={flow => flow.name || ""}
                commonProps={commonProps}
              />

              <DummyTextField
                label="Numéro NIM"
                name="nim"
                disabled
                value={
                  selectedFlow && selectedFlow.nimInstallation
                    ? selectedFlow.nimInstallation.nim
                    : ""
                }
              />

              <DummyChoiceSelectWithLabel<GasEnum, false>
                label="Gaz émis"
                name="gas"
                isMulti={false}
                options={[defaultGasDummyValue]}
                value={defaultGasDummyValue}
                disabled
                computeLabel={gaz => gaz.toString()}
                commonProps={commonProps}
              />

              <NumberField
                label="Émission totale d'origine non biomasse *"
                name="fossilEmission"
                unit={computeUnitTCO2PerYear}
              />

              <NumberField
                label="Émission totale d'origine biomasse *"
                name="biomassEmission"
                unit={computeUnitTCO2PerYear}
              />
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default ComputedEmissionsModal;
