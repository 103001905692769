import uuid from "uuid";
import React from "react";
import { useStyles } from "./style";
import { getUserInfo, options } from "./util";
import { CommentValues } from "pages/CompanySpace/Comments/commentTypes";
import CommonEntityButton from "../../presentational/CommonEntityButton";
import deleteLogo from "icons/delete.svg";

const SingleComment = (props: {
  comment: CommentValues;
  deleteComment: (comment: CommentValues) => void;
}): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div key={uuid()} className={classes.global}>
        <span>
          <label>{getUserInfo(props.comment)}</label>
          <span className={classes.date}>
            {Intl.DateTimeFormat("fr-FR", options).format(props.comment.date)}
          </span>
        </span>
        <p className={classes.inputDisabled}>{props.comment.comment}</p>
      </div>
      {props.comment.isDeletable && (
        <CommonEntityButton
          supressLogo={deleteLogo}
          suppressMessage={
            "Êtes vous sûr de vouloir supprimer ce commentaire ?"
          }
          handlerSuppress={() => {
            props.deleteComment(props.comment);
          }}
        />
      )}
    </div>
  );
};

export default SingleComment;
