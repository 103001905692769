import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  batimentFieldMatcher,
  BuildingInArray,
  BuildingInModale,
} from "../utils/types";
import {
  especeLabels,
  getSpecieEmissionField,
} from "./utils/selectPossibleValues";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { AirElevageDetailNh320NowEspecesDeclareesEnum } from "api/gen";
import { addOrModifyArray } from "common/declarant/array/utils";
import { validateNh3Emissions } from "./utils/validation";
import { CallbackSetter } from "common/utils/types";

interface EmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  buildingsInArray: BuildingInArray[];
  setBuildingsInArray: CallbackSetter<BuildingInArray[]>;
  setBuildingInModal: (building: BuildingInArray | null) => void;
  buildingInModal: BuildingInArray | null;
  species: AirElevageDetailNh320NowEspecesDeclareesEnum[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const EmissionsModal = ({
  isOpen,
  setIsOpen,
  species,
  buildingsInArray,
  setBuildingsInArray,
  setBuildingInModal,
  buildingInModal,
}: EmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-emissions-nh3",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);

  const defaultInitialEntity: BuildingInModale = {
    name: null,
    caillesEmission: null,
    canardsEmission: null,
    dindesDindonsEmission: null,
    pintadesEmission: null,
    poulesPondeusesEmission: null,
    pouletsDeChairEmission: null,
    poulettesEmission: null,
    autresVolaillesEmission: null,
    porceletsEnPostSevrageEmission: null,
    porcsDeProductionEmission: null,
    cochettesEmission: null,
    truiesEnMaterniteEmission: null,
    truiesEnAttenteSaillieEtGestantesEmission: null,
    verratsEmission: null,
    approcheGlobalePorcsEmission: null,
  };

  const initialEntity = buildingInModal
    ? buildingInModal.data
    : defaultInitialEntity;

  const fields = species.map((specie, i) => {
    const name = getSpecieEmissionField(specie);
    return (
      <NumberField
        label={especeLabels[specie]}
        name={name}
        key={i}
        unit="kg/an/emplacement"
      />
    );
  });

  const onClose = () => {
    setBuildingInModal(null);
    setIsOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity<BuildingInModale>
        title="AJOUTER UN BÂTIMENT ET DES ÉMISSIONS DE NH3"
        closeFunction={onClose}
        isEdit={buildingInModal !== null}
        validate={values => {
          return validateNh3Emissions(
            values,
            buildingInModal,
            buildingsInArray,
            species
          );
        }}
        onSubmit={values => {
          addOrModifyArray(
            setBuildingsInArray,
            buildingInModal && buildingInModal.data.id,
            values
          );
          onClose();
        }}
        initialEntity={initialEntity}
        renderField={() => {
          return (
            <>
              <TextField
                name={batimentFieldMatcher.buildingName}
                label="Nom du bâtiment *"
              />

              {fields}
            </>
          );
        }}
      />

      <span>* Champs obligatoires</span>
    </CustomModal>
  );
};

export default EmissionsModal;
