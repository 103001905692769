import {
  FiltresDeclarationDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen/api";
import { DeclarationState } from "./FilterField/helpers/declarationStates";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";

export type MoreOrLessThanValidation =
  | "inférieur au taux demandé"
  | "supérieur au taux demandé";

export const lessThanValidation: MoreOrLessThanValidation =
  "inférieur au taux demandé";
export const moreThanValidation: MoreOrLessThanValidation =
  "supérieur au taux demandé";

export interface DeclarationFilters
  extends Omit<
    FiltresDeclarationDto,
    | "region"
    | "departement"
    | "moreThanValidationPercent"
    | "declarationGlobalState"
    | "quotasEmissionsState"
    | "quotasAllocationsState"
    | "lastModificationTimeDeclaring"
    | "lastModificationTimeInspector"
  > {
  region: ReferenceItemRegionDto | null;
  departement: ReferenceItemDepartementDto | null;
  declarationGlobalState: DeclarationState | null;
  quotasEmissionsState: DeclarationState | null;
  quotasAllocationsState: DeclarationState | null;
  moreOrLessThanValidationPercent: MoreOrLessThanValidation | null;
  lastModificationTimeDeclaring: Date | null;
  lastModificationTimeInspector: Date | null;
}

export type FilterFormValues = Omit<
  DeclarationFilters,
  | "searchByIdOrNameOrSiret"
  | "searchByNimNumber"
  | "sortBy"
  | "ascending"
  | "selectedSections"
  | "selectedFolderSections"
>;

export const filterFieldMatcher = fieldMatcherBuilder<
  DeclarationFilters
>().build({
  region: "region",
  departement: "departement",
  declarationGlobalState: "declarationGlobalState",
  quotasEmissionsState: "quotasEmissionsState",
  quotasAllocationsState: "quotasAllocationsState",
  quotasWithAnyExclusions: "quotasWithAnyExclusions",
  declarationYear: "annees",
  declarationValidationPercent: "declarationValidationPercent",
  moreOrLessThanValidationPercent: "moreOrLessThanValidationPercent",
  lastModificationTimeDeclaring: "lastModificationTimeDeclaring",
  lastModificationTimeInspector: "lastModificationTimeInspector",
  beforeLastModificationDeclaring: "beforeLastModificationDeclaring",
  beforeLastModificationInspector: "beforeLastModificationInspector",
  carriere: "carriere",
  elevage: "elevage",
  eprtr: "eprtr",
  quotasEmissions: "quotasEmissions",
  quotasAllocations: "quotasAllocations",
  isdi: "isdi",
  isdnd: "isdnd",
  consoOfSolvant: "consoOfSolvant",
  rienADeclarer: "rienADeclarer",
  searchByIdOrNameOrSiret: "searchByIdOrNameOrSiret",
  searchByNimNumber: "searchByNimNumber",
  sortBy: "sortBy",
  ascending: "ascending",
  selectedSections: "selectedSections",
  selectedFolderSections: "selectedFolderSections",
});
