import { CommonGlobalFormActionsInFullContextProps } from "common/actions/types";
import { DeclarationStateDtoStateEnum } from "api/gen";
import React from "react";
import GenericGlobalFormActionInFullContext from "common/actions/GenericGlobalFormActionInFullContext";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import {
  Declaration2123,
  useBasicDeclarationHandlers2123,
  useDeclaration2123,
  useDeclarationHelpers2123,
} from "./declarationHooks2123";
import { ViolationProcessor } from "common/violations/ViolationProcessor";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { DeclarationConstraintViolation19Now } from "../../from19/toNow/versionedElements/declarationHooks19Now";
import { useDeclarationErrorAndWarnings21Now } from "../toNow/versionedElements/errorAndWarningDisplayer21Now/utils";

type GlobalFormActionFullContext21NowProps = CommonGlobalFormActionsInFullContextProps<
  Declaration2123
>;

export const GlobalFormActionFullContext2123 = (
  props: GlobalFormActionFullContext21NowProps
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration2123 = useDeclaration2123;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers2123;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration2123
  > = useBasicDeclarationHandlers2123;
  const getDeclarationState: (
    declarationDto: Declaration2123
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings21Now;
  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );
  return (
    <GenericGlobalFormActionInFullContext<
      Declaration2123,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      {...props}
    />
  );
};

export default GlobalFormActionFullContext2123;
