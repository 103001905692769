import * as Yup from "yup";
import {
  commonArrayOfStringFields,
  commonBooleanFields,
  commonMixedFields,
  commonNullableStringFields,
  commonNumberPercentFields,
  commonObjectFields,
  commonObjectFieldsNullable,
  commonPercentageFields,
  commonPercentageFieldsNullable,
  commonPositiveNumberFields,
  MAX_NB_HOURS_YEAR,
  subBooleanFieldValidationScheme,
  subFieldValidationMultipleANDScheme,
  subFieldValidationScheme,
  subNumberFieldValidationScheme,
  subStringFieldValidationMultipleScheme,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";

import _, { isEqual } from "lodash";
import {
  combustionInstallationTooMuchTimeMessage,
  procedesMasseVolumiqueMessage,
  procedesUniqueSubstanceMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import {
  concentrationFieldMatcher,
  CorrelationEmissionInArray,
  debitFieldMatcher,
  emissionFieldMatcher,
  EmissionsInArray,
  EmissionsInModale,
  MesureEmissionInArray,
} from "../utils/types";
import {
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum,
  AirFugitivesProcedesDtoUniteEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { intrantSortantValidationSchema } from "../../../combustionProcedeUtils/intrantsSortants/validationUtils";
import { isCo2 } from "../../../combustionProcedeUtils/biomasse/helpers";
import { Errors } from "common/form/utils";
import {
  shouldElementNameBeFilled,
  shouldPartBeFilled,
} from "../../../combustionProcedeUtils/bilanMatiere/elementsHelpers";
import { MethodEnum } from "../utils/selectPossibleValues";

export const anySubPartActivated = (type: MethodEnum | null): boolean => {
  return (
    mesureSubPartActivated(type) ||
    correlationSubPartActivated(type) ||
    matiereSubPartActivated(type)
  );
};

export const mesureSubPartActivated = (type: MethodEnum | null): boolean => {
  return type === MethodEnum.MESURE;
};

export const correlationSubPartActivated = (
  type: MethodEnum | null
): boolean => {
  return type === MethodEnum.FACTEUR_CORRELATION;
};

export const matiereSubPartActivated = (
  emissionType: MethodEnum | null
): boolean => {
  return emissionType === MethodEnum.BILAN_MATIERE;
};

const mesureContinueDebitSubPartActivated = (
  debitType: boolean | null
): boolean => {
  return !debitType;
};

const mesureContinueConcentrationSubPartActivated = (
  concentrationType: boolean | null
): boolean => {
  return !concentrationType;
};

const epurationSubPartActivated = (epurationType: boolean | null): boolean => {
  return !!epurationType;
};

export const singleEmissionOverwriteDependantFields = (
  emission: EmissionsInModale,
  referentialCO2: ReferenceItemPolluantDto
): void => {
  if (!mesureSubPartActivated(emission.methods || null)) {
    emission.debitHoraire = null;
    emission.debit = null;
    emission.heures = null;
    emission.concentrationMoy = null;
    emission.concentration = null;
  }
  if (!correlationSubPartActivated(emission.methods || null)) {
    emission.nature = null;
    emission.facteur = null;
    emission.unite = null;
    emission.provenanceFacteurCorrelation = null;
  }
  if (!matiereSubPartActivated(emission.methods || null)) {
    emission.elementName = null;
    emission.elementProps = null;
    emission.part = null;
  }
  if (!isCo2(emission.substance, referentialCO2)) {
    emission.biomasse = null;
  }
};

//TODO [GEREP-2579] il faudra remettre le shape EmissionsInModale
// (et le mettre dans le ObjectSchema aussi) ici.
export const singleEmissionValidationSchema = (
  referentialCO2: ReferenceItemPolluantDto
): Yup.ObjectSchema<any> =>
  Yup.object().shape({
    methods: commonMixedFields<MethodEnum>(),
    substance: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      anySubPartActivated,
      commonObjectFields,
      Yup.object()
    ),
    procedes: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      anySubPartActivated,
      commonArrayOfStringFields,
      Yup.array()
    ),
    epuration: subBooleanFieldValidationScheme(
      emissionFieldMatcher.methods,
      anySubPartActivated
    ),
    natureEquipement: subStringFieldValidationMultipleScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.epuration],
      anySubPartActivated,
      epurationSubPartActivated
    ),
    rendement: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.epuration],
      anySubPartActivated,
      epurationSubPartActivated,
      commonNumberPercentFields,
      Yup.number()
    ),
    biomasse: subFieldValidationMultipleANDScheme(
      [emissionFieldMatcher.methods, emissionFieldMatcher.substance],
      anySubPartActivated,
      (type: ReferenceItemPolluantDto | null) => isCo2(type, referentialCO2),
      commonPercentageFields,
      Yup.number()
    ),

    // Mesure
    debitHoraire: subNumberFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated
    ),
    debit: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      debitValidationSchema,
      Yup.object()
    ),
    heures: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      commonPositiveNumberFields.max(
        MAX_NB_HOURS_YEAR,
        combustionInstallationTooMuchTimeMessage
      ),
      Yup.number()
    ),
    concentrationMoy: subNumberFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated
    ),
    concentrationMoyUnite: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      commonMixedFields<
        AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum
      >(),
      Yup.mixed<AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum>()
    ),
    concentration: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      mesureSubPartActivated,
      concentrationValidationSchema,
      Yup.object()
    ),

    // Facteur de correlation
    nature: commonNullableStringFields,
    facteur: subNumberFieldValidationScheme(
      emissionFieldMatcher.methods,
      correlationSubPartActivated
    ),
    unite: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      correlationSubPartActivated,
      commonMixedFields<AirFugitivesEmissionCorrelationDtoUniteEnum>(),
      Yup.mixed<AirFugitivesEmissionCorrelationDtoUniteEnum>()
    ),
    provenanceFacteurCorrelation: subStringFieldValidationScheme(
      emissionFieldMatcher.methods,
      correlationSubPartActivated
    ),

    // Bilan matière
    elementName: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      commonNullableStringFields,
      Yup.string()
    ),
    elementProps: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      commonObjectFieldsNullable,
      Yup.object()
    ),
    part: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      commonPercentageFieldsNullable,
      Yup.number()
    ),
    intrants: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.array().of(intrantSortantValidationSchema),
      Yup.array()
    ),
    sortants: subFieldValidationScheme(
      emissionFieldMatcher.methods,
      matiereSubPartActivated,
      Yup.array().of(intrantSortantValidationSchema),
      Yup.array()
    ),
  });

export const additionalValidations = (
  values: EmissionsInModale,
  correlationProcede: ProcedeInArray | null,
  allowedCouples: EmissionsInArray | null,
  mesureEmissionsInPage: MesureEmissionInArray[],
  correlationEmissionsInPage: CorrelationEmissionInArray[],
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>,
  autreElementUuid: string
): Errors<EmissionsInModale> => {
  let errors = {};
  let found;
  if (values.substance && values.procedes) {
    const valuesSubstance = values.substance;
    const valuesProcedes = values.procedes;
    if (
      !allowedCouples ||
      !isEqual(allowedCouples.data.procedes, valuesProcedes) ||
      !isEqual(allowedCouples.data.substance, valuesSubstance) ||
      !isEqual(allowedCouples.data.methods, values.methods)
    ) {
      if (mesureSubPartActivated(values.methods)) {
        found = mesureEmissionsInPage.filter(emission => {
          if (emission.data.procedes && emission.data.substance) {
            return (
              isEqual(emission.data.procedes.sort(), valuesProcedes.sort()) &&
              emission.data.substance.nom === valuesSubstance.nom
            );
          }
          return false;
        })[0];
      } else if (correlationSubPartActivated(values.methods)) {
        found = correlationEmissionsInPage.filter(emission => {
          if (emission.data.procedes && emission.data.substance) {
            return (
              emission.data.procedes[0] === valuesProcedes[0] &&
              emission.data.substance.nom === valuesSubstance.nom
            );
          }
          return false;
        })[0];
      }
    }
    if (found) {
      errors = {
        substance: procedesUniqueSubstanceMessage,
      };
    }
  }
  if (correlationSubPartActivated(values.methods) && correlationProcede) {
    if (
      (correlationProcede.data.unite === AirFugitivesProcedesDtoUniteEnum.KG ||
        correlationProcede.data.unite === AirFugitivesProcedesDtoUniteEnum.T) &&
      _.isNil(correlationProcede.data.masse) &&
      values.unite !== AirFugitivesEmissionCorrelationDtoUniteEnum.KG &&
      values.unite !== AirFugitivesEmissionCorrelationDtoUniteEnum.T &&
      values.unite !== AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
    ) {
      errors = {
        ...errors,
        unite: procedesMasseVolumiqueMessage,
      };
    }
    if (
      correlationProcede.data.unite !== AirFugitivesProcedesDtoUniteEnum.KG &&
      correlationProcede.data.unite !== AirFugitivesProcedesDtoUniteEnum.T &&
      _.isNil(correlationProcede.data.masse) &&
      (values.unite === AirFugitivesEmissionCorrelationDtoUniteEnum.KG ||
        values.unite === AirFugitivesEmissionCorrelationDtoUniteEnum.T)
    ) {
      errors = {
        ...errors,
        unite: procedesMasseVolumiqueMessage,
      };
    }
  }
  if (matiereSubPartActivated(values.methods)) {
    errors = {
      ...errors,
      ...validateMatierePolluantElement(
        values,
        polluantElementMap,
        autreElementUuid
      ),
    };
  }

  return errors;
};

const validateMatierePolluantElement = (
  values: EmissionsInModale,
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>,
  autreElementUuid: string
): Errors<EmissionsInModale> => {
  let errors = {};

  if (
    !shouldElementNameBeFilled(values, polluantElementMap) &&
    !values.elementProps
  ) {
    errors = { ...errors, elementProps: requiredMessage };
  }
  if (
    shouldElementNameBeFilled(values, polluantElementMap) &&
    (!values.elementName || !values.elementName.trim())
  ) {
    errors = { ...errors, elementName: requiredMessage };
  }
  if (
    shouldPartBeFilled(values, polluantElementMap, autreElementUuid) &&
    values.part === null
  ) {
    errors = { ...errors, part: requiredMessage };
  }

  return errors;
};

//TODO [GEREP-2579] il faudra remettre le shape AirFugitivesEmissionMesureConcentration ici.
const concentrationValidationSchema = Yup.object().shape({
  continu: commonBooleanFields,
  frequence: subNumberFieldValidationScheme(
    concentrationFieldMatcher.mesureContinueConcentration,
    mesureContinueConcentrationSubPartActivated
  ),
});

//TODO [GEREP-2579] il faudra remettre le shape AirFugitivesEmissionMesureDebit ici.
const debitValidationSchema = Yup.object().shape({
  continu: commonBooleanFields,
  frequence: subNumberFieldValidationScheme(
    debitFieldMatcher.mesureContinueDebit,
    mesureContinueDebitSubPartActivated
  ),
});
