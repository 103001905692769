import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { SECTION_FONT_SIZE, SECTION_TITLE_GREY } from "theme";
import { usePageHeaderInfo } from "pages/CommonSpace/AppPage/PageHeaderContext";
import AppPath from "./AppPath";
import DropdownMenu from "./DropdownMenu";
import HeaderButtons from "../button/HeaderButtons";
import { useUserData } from "Authenticator/UserData";
import DropdownYearsMenu from "./DropdownYearsMenu";
import { CreateDeclarationButton } from "./CreateDeclarationButton";
import { useActiveOrLastCampaign } from "pages/CommonSpace/CampaignContext";
import moment from "moment";
import { useDeclaredYears } from "pages/CompanySpace/DeclarationApiContext/utils/genericHooks";
import { useDeclarationPageHeaderInfo } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";
import ContactInspectorGlobalModal from "../modale/ContactInspectorGlobalModal";

const useStyles = makeStyles({
  root: {
    minHeight: 100,
    position: "sticky",
    top: "0px",
    backgroundColor: "white",
    zIndex: 15,
  },
  path: {
    color: SECTION_TITLE_GREY,
    fontSize: SECTION_FONT_SIZE,
    justifyContent: "space-between",
    display: "flex",
    margin: "3px",
    zIndex: 16,
  },
  declarationAction: {
    marginLeft: "auto",
    justifyContent: "flex-end",
    display: "flex",
    margin: "3px",
    alignItems: "center",
  },
  declaredYear: {
    fontWeight: "bold",
  },
  dropdownMenu: {
    marginLeft: "auto",
  },
});

const Header: React.FC = () => {
  const classes = useStyles();
  const headerContext = usePageHeaderInfo();
  const userData = useUserData();
  const listOfDeclaredYears = useDeclaredYears();
  const campaign = useActiveOrLastCampaign();
  const isCampaign = moment().isBetween(
    moment(campaign.start),
    moment(campaign.endGlobal)
  );
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const headerInfo = useDeclarationPageHeaderInfo();

  return (
    <header className={classes.root}>
      {/* app path */}
      <div className={classes.path}>
        {headerContext && headerContext.arianeString && (
          <AppPath pathlinks={headerContext.arianeString} />
        )}
        {/* must remain outside of headerContext to be seen in inspector's Home page */}
        <DropdownMenu additionalClassname={classes.dropdownMenu} />
      </div>

      {headerContext && (
        <>
          <HeaderButtons
            onMessage={
              userData.isDeclarant
                ? () => setIsContactModalOpen(true)
                : undefined
            }
            returnLink={
              (headerContext.arianeString &&
                headerContext.arianeString.length > 1 &&
                headerContext.arianeString[
                  headerContext.arianeString.length - 2
                ].to) ||
              undefined
            }
          />

          {headerInfo && (
            <div className={classes.declarationAction}>
              <DropdownYearsMenu
                yearsList={listOfDeclaredYears.filter(year => year > 2018)}
                headerInfo={headerInfo}
              />
            </div>
          )}

          {headerInfo &&
            !userData.isAnyPrestataire &&
            !userData.isLecteur &&
            isCampaign &&
            !listOfDeclaredYears.includes(campaign.annee) && (
              <div className={classes.declarationAction}>
                <CreateDeclarationButton />
              </div>
            )}

          {userData.isDeclarant && (
            <ContactInspectorGlobalModal
              isOpen={isContactModalOpen}
              onRequestClose={() => setIsContactModalOpen(false)}
              emailsDeclarants={headerInfo?.emailDeclarants || []}
            />
          )}
        </>
      )}
    </header>
  );
};

export default Header;
