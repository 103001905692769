import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  useBooleanCheckBoxGenerator,
  useDateFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  autreSubPartNatureActivated,
  autreSubPartTypeFoyerActivated,
  isChaudiereSelected,
  singleAppareilOverwriteDependantFields,
  singleAppareilValidationSchema,
  validate,
} from "./validation/validation";
import {
  arrayNature,
  arrayNatureLabel,
  arrayTypeFoyer,
  arrayTypeFoyerLabel,
} from "./utils/selectPossibleValues";
import { appareilFieldMatcher, AppareilInModale } from "./utils/types";
import { InstallationInArray } from "../../BlocInstallation/utils/types";
import { isAppareilSubpartLcpFormAvailable } from "./utils/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface FormAppareilProps {
  closeFunction: () => void;
  onSubmit: (
    values: AppareilInModale,
    formikActions: FormikActions<AppareilInModale>
  ) => void;
  initialAppareil: AppareilInModale | null;
  installation: InstallationInArray;
  forbiddenAppareilName: string[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormAppareil = ({
  closeFunction,
  onSubmit,
  initialAppareil,
  installation,
  forbiddenAppareilName,
}: FormAppareilProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-appareil-appareil-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const isAppareilLcpSubpartFormAvailable = isAppareilSubpartLcpFormAvailable(
    installation.data.type
  );

  const defaultAppareil: Omit<AppareilInModale, "installation"> = {
    nameInstallation: null,
    nom: null,
    date: null,
    modification: isAppareilLcpSubpartFormAvailable ? false : null,
    lowestThan1500: isAppareilLcpSubpartFormAvailable ? false : null,
    localisation: null,
    activite: null,
    nature: null,
    natureAutre: null,
    typeFoyer: null,
    foyerAutre: null,
    capacite: null,
    unite: null,
    hauteurRejets: null,
  };

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN APPAREIL"
      closeFunction={closeFunction}
      onSubmit={(values: AppareilInModale, formikBag) => {
        singleAppareilOverwriteDependantFields(
          values,
          isAppareilLcpSubpartFormAvailable
        );
        onSubmit(values, formikBag);
      }}
      isEdit={initialAppareil !== null}
      initialEntity={initialAppareil || defaultAppareil}
      validationSchema={singleAppareilValidationSchema}
      validate={values => validate(values, forbiddenAppareilName)}
      renderField={({ values }) => {
        return (
          <>
            <TextField
              name={appareilFieldMatcher.nom}
              label="Nom de l'appareil *"
            />
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation"
              value={installation.data.nom}
              disabled
            />
            <DateField
              name={appareilFieldMatcher.date}
              label="Date de mise en service de l'appareil *"
            />
            {isAppareilLcpSubpartFormAvailable && (
              <>
                <BooleanField
                  name={appareilFieldMatcher.modification}
                  label="Modification substantielle de l'appareil dans l'année"
                  tooltipContent="L’équipement a fait l’objet au cours de l’année rapportée d’une modification substantielle (application de l’article R. 512-33 du code de l’environnement)."
                />
                <BooleanField
                  name={appareilFieldMatcher.lowestThan1500}
                  label="Appareil de combustion fonctionnant moins de 1500 heures par an en moyenne mobile calculée sur une période de 5 ans"
                />
              </>
            )}
            <TextField
              name={appareilFieldMatcher.localisation}
              label="Localisation sur le site"
            />
            <TextField
              name={appareilFieldMatcher.activite}
              label="Activité(s) développée(s)"
            />
            <WrappedChoiceSelect
              name={appareilFieldMatcher.nature}
              label="Nature de l'appareil *"
              isMulti={false}
              options={arrayNature}
              commonProps={commonProps}
              computeLabel={nature => arrayNatureLabel[nature]}
            />
            {autreSubPartNatureActivated(values.nature) && (
              <TextField
                name={appareilFieldMatcher.natureAutre}
                label="Préciser *"
              />
            )}
            {isChaudiereSelected(values.nature) && (
              <>
                <WrappedChoiceSelect
                  name={appareilFieldMatcher.typeFoyer}
                  label="Type de foyer pour les chaudières utilisant un combustible solide"
                  isMulti={false}
                  commonProps={commonProps}
                  options={arrayTypeFoyer}
                  computeLabel={typeFoyer => arrayTypeFoyerLabel[typeFoyer]}
                />
                {autreSubPartTypeFoyerActivated(values.typeFoyer) && (
                  <TextField
                    name={appareilFieldMatcher.foyerAutre}
                    label="Précision du foyer *"
                  />
                )}
              </>
            )}

            <NumberField
              name={appareilFieldMatcher.capacite}
              label="Capacité autorisée *"
              unit=""
            />
            <TextField name={appareilFieldMatcher.unite} label="Unité *" />
            <NumberField
              name={appareilFieldMatcher.hauteurRejets}
              label="Hauteur des rejets *"
              unit="m"
            />
          </>
        );
      }}
    />
  );
};

export default FormAppareil;
