import {
  PopulatedTransportProductInArray,
  TransportProductsArrayForm,
  TransportProductsForm,
} from "./types";
import { convertModaliteTransportDtoToDisplayed } from "./converters";
import { familiesProduction } from "../../Families/selectPossibleValues";
import {
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import { OptionPropsWithObject } from "../../../../../../../../common/form/fields/types/basicTypes";

export const computeTotalProductsTonnage = (
  transportProductsInArray: PopulatedTransportProductInArray[]
): number => {
  let total = 0;
  transportProductsInArray.forEach(productInArray => {
    total += productInArray.displayedProduct.tonnage;
  });
  return total;
};

export const computeTransportProductsArrayFormInitialValues = (
  declaration: Declaration20Now,
  referentialFamilies: ReferenceFamilleUsageProductionDto
): TransportProductsArrayForm => {
  const productsTransport: Record<number, TransportProductsForm> = {};
  const modalitesTransport =
    declaration.body.sections.carriere.production.transport.modaliteTransports;

  familiesProduction(referentialFamilies).forEach(usageFamily => {
    // we check if a modaliteTransport is present for this family by checking the libelle of the usageFamily from referential and our string in famille
    const existingValue = modalitesTransport.find(modalite => {
      return usageFamily.object.libelleCourt === modalite.famille;
    });
    if (existingValue) {
      productsTransport[
        usageFamily.object.restorationCode
      ] = convertModaliteTransportDtoToDisplayed(
        [existingValue],
        referentialFamilies
      )[0];
    } else {
      productsTransport[usageFamily.object.restorationCode] = {
        usageFamily: usageFamily,
        byRoad: null,
        byRail: null,
        byWaterWay: null,
        other: null,
        otherPrecision: null,
      };
    }
  });

  return {
    productsTransport: productsTransport,
  };
};

export const generateLabelFamily = (
  codeProduit: string,
  referentialFamiliesCodeProduits: ReferenceFamilleUsageCodeProduitDto
): string => {
  const libelleToDisplay = findElementMatchingTemplate(
    { codeProduit: codeProduit },
    referentialFamiliesCodeProduits.referenceItemFamilleUsageCodeProduitDtoList
  );

  return `${codeProduit} - ${libelleToDisplay && libelleToDisplay.libelle}`;
};

export const computeListDeclaredFamilies = (
  listDeclaredProducts: PopulatedTransportProductInArray[]
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>[] => {
  return listDeclaredProducts
    .map(singleProduct => singleProduct.displayedProduct.usageFamily)
    .reduce(
      (
        acc: OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto>[],
        familleUsageNullableOptionProps
      ) => {
        if (familleUsageNullableOptionProps === null) {
          return acc;
        }

        if (
          acc.some(
            familleUsage =>
              familleUsage.object.restorationCode ===
              familleUsageNullableOptionProps.object.restorationCode
          )
        ) {
          return acc;
        }

        return acc.concat(familleUsageNullableOptionProps);
      },
      []
    );
};
