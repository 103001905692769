import { AirSolvantsCovnmPgsDto, AirSolvantsCovPgsDto } from "api/gen/api";
import { getValueOrZero } from "common/utils/numberUtils";
import { OutputEmissionsInModale } from "./types";

export const computeEmissionDiffuse = (
  values:
    | OutputEmissionsInModale
    | AirSolvantsCovPgsDto
    | AirSolvantsCovnmPgsDto
): number => {
  return (
    getValueOrZero(values.i1) -
    (getValueOrZero(values.o1) +
      getValueOrZero(values.o5) +
      getValueOrZero(values.o6) +
      getValueOrZero(values.o7) +
      getValueOrZero(values.o8))
  );
};

export const computeEmissionTotale = (
  values:
    | OutputEmissionsInModale
    | AirSolvantsCovPgsDto
    | AirSolvantsCovnmPgsDto
): number => {
  return (
    getValueOrZero(values.i1) -
    (getValueOrZero(values.o5) +
      getValueOrZero(values.o6) +
      getValueOrZero(values.o7) +
      getValueOrZero(values.o8))
  );
};

export const computeI1 = (values: OutputEmissionsInModale): number => {
  return (
    getValueOrZero(values.o1) +
    getValueOrZero(values.o2) +
    getValueOrZero(values.o3) +
    getValueOrZero(values.o4) +
    getValueOrZero(values.o5) +
    getValueOrZero(values.o6) +
    getValueOrZero(values.o7) +
    getValueOrZero(values.o8) +
    getValueOrZero(values.o9)
  );
};

export const computeConsommationSolvant = (
  values:
    | OutputEmissionsInModale
    | AirSolvantsCovPgsDto
    | AirSolvantsCovnmPgsDto
): number => {
  return getValueOrZero(values.i1) - getValueOrZero(values.o8);
};

export const computeUtilisationSolvant = (
  values:
    | OutputEmissionsInModale
    | AirSolvantsCovPgsDto
    | AirSolvantsCovnmPgsDto
): number => {
  return getValueOrZero(values.i1) + getValueOrZero(values.i2);
};
