import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { ProcedeInArray } from "./utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface ProcedeArrayProps {
  procedesInPage: ProcedeInArray[];
  setProcedesInPage: Dispatch<SetStateAction<ProcedeInArray[]>>;
  setProcedeInModale: Dispatch<SetStateAction<ProcedeInArray | null>>;
  setProcedeModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationProcedePath: string;
}

const ProcedeArray = ({
  procedesInPage,
  setProcedesInPage,
  setProcedeInModale,
  setProcedeModaleOpen,
  isValidated,
  validationProcedePath,
}: ProcedeArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressProcede(singleProcedeInPage: ProcedeInArray) {
    setProcedesInPage(elderProcedes => {
      return elderProcedes.filter(elderProcedeInPage => {
        return singleProcedeInPage.data.id !== elderProcedeInPage.data.id;
      });
    });
  }

  const computeSingleProcedeLine = (
    procede: ProcedeInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setProcedeInModale(procede);
      setProcedeModaleOpen(true);
    };

    const procedeUnit =
      procede.data.unit !== null && procede.data.unit.label !== "Autre"
        ? procede.data.unit.label
        : procede.data.unitPrecision;

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      procede.data.name || undefined,
      isValidated,
      !_.isEmpty(procede.errors)
    );

    return [
      computeWrappedCellWithError(
        <div title={procede.data.name || ""}>{firstCellContent}</div>,
        classes
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparator(procede.data.quantity || 0),
        classes
      ),
      computeWrappedCellWithError(
        <p title={procedeUnit || ""}>{procedeUnit}</p>,
        classes
      ),
      computeWrappedCellWithError(
        procede.data.density !== null
          ? computeNumberWithSeparator(procede.data.density)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressProcede(procede);
        }}
        suppressMessage={
          "Confirmez-vous la suppression de ce procédé ? Les émissions et informations associées à ce procédé seront supprimées."
        }
        isValidated={isValidated}
        commentPath={`${validationProcedePath}/${procede.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = procedesInPage.map(singleProcedeInPage => {
    return computeSingleProcedeLine(singleProcedeInPage, classes);
  });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Nom du procédé</p>,
          <p>Quantité produite ou volume d'activité</p>,
          <p>Unité</p>,
          <p>Masse volumique (kg/l = t/m3)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
      />
    </>
  );
};

export default ProcedeArray;
