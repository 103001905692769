import { makeStyles } from "@material-ui/styles";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import { QuotaWorkflowTarget } from "common/utils/types";
import React from "react";
import QuotasDeclarationOfficeUpload from "../../../../toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeUpload";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import CancelTakeoverButton from "../../../../toNow/Actions/Global/CancelTakeoverButton";
import QuotaTakeoverButton from "../../../../toNow/Actions/Quotas/QuotaTakeoverButton";
import QuotaReviewButton from "../../../../toNow/Actions/Quotas/QuotaReviewButton";
import QuotaApproveButton from "../../../Actions/Quotas/QuotaApproveButton";

const useStyles = makeStyles({
  buttonContainer: {
    "& button": {
      margin: "auto",
      padding: "5px 15px",
    },
  },
  downloadButton: {
    justifyContent: "center",
  },
});

interface BlocInspecteurProps {
  workflowTarget: QuotaWorkflowTarget;
  path: string;
  currentState: DeclarationStateDtoStateEnum;
  isCampaignEnded: boolean;
}

const BlocInspecteur = ({
  workflowTarget,
  path,
  currentState,
  isCampaignEnded,
}: BlocInspecteurProps): React.ReactElement | null => {
  const classes = useStyles();
  const userData = useUserData();

  const isForcedState = currentState === DeclarationStateDtoStateEnum.FORCED;
  const isRecalledState =
    currentState === DeclarationStateDtoStateEnum.RECALLED;

  // Button "Mettre en révision quotas" to pass in IN_REVIEW state in workflow
  const isButtonReviewVisible =
    !isCampaignEnded &&
    (currentState === DeclarationStateDtoStateEnum.SUBMITTED ||
      currentState === DeclarationStateDtoStateEnum.RECALLED ||
      currentState === DeclarationStateDtoStateEnum.VALIDATED);
  // Button "Valider la déclaration" to pass in VALIDATED state in workflow
  const isButtonValidateVisible =
    currentState === DeclarationStateDtoStateEnum.FORCED ||
    currentState === DeclarationStateDtoStateEnum.SUBMITTED;

  if (!userData.isInspecteur) {
    return null;
  }

  return (
    <DummyUnActionedBloc
      title={"espace inspecteur"}
      additionalClassname={classes.buttonContainer}
      renderContent={() => (
        <div>
          {isRecalledState && (
            <>
              <Row />
              <p>
                Le déclarant a demandé à pouvoir effectuer une modification de
                sa déclaration.
              </p>
            </>
          )}
          {isForcedState && !isCampaignEnded && (
            <>
              <Row />
              <CancelTakeoverButton workflowTarget={workflowTarget} />
            </>
          )}
          {!isForcedState &&
            (userData.userInfo.droits[0].authProfile ===
              AuthDroitDtoAuthProfileEnum.SUPER_ADMIN ||
              userData.userInfo.droits[0].authProfile ===
                AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
              !isCampaignEnded) && (
              <>
                <Row />
                <QuotaTakeoverButton
                  workflowTarget={workflowTarget}
                  path={path}
                />
              </>
            )}
          {isButtonReviewVisible && (
            <>
              <Row />
              <QuotaReviewButton
                text={
                  isRecalledState
                    ? "Accepter demande de modification"
                    : "Mettre en révision quotas"
                }
                workflowTarget={workflowTarget}
                path={path}
              />
            </>
          )}
          {isButtonValidateVisible && (
            <>
              <Row />
              <QuotaApproveButton workflowTarget={workflowTarget} path={path} />
            </>
          )}
          <Row height="40px" />
          {workflowTarget !== QuotaWorkflowTarget.QUOTA_ALLOCATIONS && (
            <>
              <Row additionalClassname={classes.downloadButton}>
                <GreyFilesDownloader
                  text="TÉLÉCHARGER UN MODÈLE DE RAPPORT DE DÉCLARATION D'OFFICE"
                  fileName="Declaration_doffice_emissions.doc"
                />
              </Row>
              <Row height="40px" />
            </>
          )}
          <QuotasDeclarationOfficeUpload
            workflowTarget={workflowTarget}
            isDisabled={!isForcedState}
          />
        </div>
      )}
    />
  );
};

export default BlocInspecteur;
