import React, { ReactElement } from "react";
import { BlocDonneeSurveillanceDto, MethodeSurveillanceDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import ClassementFlux from "./ClassementFlux";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
});

interface ClassementFluxSourceEmissionProps {
  blocDonneeSurveillance: BlocDonneeSurveillanceDto;
  methodeSurveillance: MethodeSurveillanceDto;
}

const ClassementFluxSourceEmission = ({
  blocDonneeSurveillance,
  methodeSurveillance,
}: ClassementFluxSourceEmissionProps): ReactElement => {
  const classes = useStyles();

  const {
    methodeCalculExceptPfcList,
    mesureSourceEmissionList,
    surveillanceEmissionPfcList,
    surveillanceTransfereList,
  } = blocDonneeSurveillance;
  const {
    methodeCalculCo2,
    methodeMesureCo2,
    surveillanceCo2,
    surveillancePfc,
    surveillanceN2o,
  } = methodeSurveillance;
  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Classement des flux et sources d'émission
      </SubSectionTitle>
      {methodeCalculCo2 && (
        <ClassementFlux
          title={"Flux hors émission PFC"}
          classements={methodeCalculExceptPfcList.map(
            ({ classementFlux }) => classementFlux
          )}
        />
      )}
      {(methodeMesureCo2 || surveillanceCo2 || surveillanceN2o) && (
        <ClassementFlux
          title={"Sources d'émission"}
          classements={mesureSourceEmissionList.map(
            ({ classementFlux }) => classementFlux
          )}
        />
      )}
      {surveillancePfc && (
        <ClassementFlux
          title={"Emission de PFC"}
          classements={surveillanceEmissionPfcList.map(
            ({ classementFlux }) => classementFlux
          )}
        />
      )}
      {surveillanceCo2 && (
        <ClassementFlux
          title={"CO2 et N2O transféré"}
          classements={surveillanceTransfereList.map(
            ({ classementFlux }) => classementFlux
          )}
        />
      )}
    </div>
  );
};

export default ClassementFluxSourceEmission;
