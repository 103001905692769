import { InArray, InModale } from "common/form/utils";
import { QuotasInstallationDto20Now } from "api/gen";

export interface FlowDeclarationProps {
  id: string;
  nimInstallation: QuotasInstallationDto20Now | null;
  name: string | null;
}

export interface MeasureDeclarationProps {
  id: string;
  nimInstallation: QuotasInstallationDto20Now | null;
  name: string | null;
}

export enum GasEnum {
  "PFC" = "PFC",
  "N2O" = "N2O",
  "CO2" = "CO2",
}

export enum MethodEnum {
  "ALTERNATIVE" = "ALTERNATIVE",
  "MESURE" = "MESURE",
  "BILAN_MASSIQUE" = "BILAN_MASSIQUE",
  "FACTEUR_OXYDATION" = "FACTEUR_OXYDATION",
  "FACTEUR_CONVERSION" = "FACTEUR_CONVERSION",
}

export interface BlocEmissionsFormValues {
  flowDeclarations: FlowDeclarationProps[];
  measureDeclarations: MeasureDeclarationProps[];
  exemption: boolean;
  CO2Transfer: boolean;
  exemptedFlows: string[];
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 *
 */
interface LegacyEmissionProps {
  method: MethodEnum;
  gas: GasEnum;
  fossilEmission: number | null;
  biomassEmission: number | null;
  otherEmission: number | null;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyComputedEmission extends LegacyEmissionProps {
  flow: FlowDeclarationProps | null;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMeasureEmission extends LegacyEmissionProps {
  measure: MeasureDeclarationProps | null;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyAlternativeEmission extends LegacyEmissionProps {
  methodDescription: string;
  nimInstallation: QuotasInstallationDto20Now | null;
}

export type ComputedEmissionInModale = InModale<LegacyComputedEmission>;
export type MeasureEmissionInModale = InModale<LegacyMeasureEmission>;
export type AlternativeEmissionInModale = InModale<LegacyAlternativeEmission>;

export type ComputedEmissionInArray = InArray<LegacyComputedEmission>;

export type MeasureEmissionInArray = InArray<LegacyMeasureEmission>;

export type AlternativeEmissionInArray = InArray<LegacyAlternativeEmission>;

export interface ComputedAllowedDeclarationMethods {
  bilanMassique: boolean;
  facteurConversion: boolean;
  facteurOxydation: boolean;
}

export interface AllowedDeclarationMethods {
  mesure: boolean;
  alternative: boolean;
  computed: false | ComputedAllowedDeclarationMethods;
}
