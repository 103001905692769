import React, { ReactElement } from "react";
import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import { useDeclarationStateHandlers21Now } from "../../versionedElements/declarationHooks21Now";
import {
  generateTransitionWarningMessage,
  IN_REVIEW,
} from "common/messages/dashboardMessage";
import { WorkflowTarget } from "common/utils/types";

const CancelSubmitButton = (): ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const { cancelSubmit } = useDeclarationStateHandlers21Now();

  return (
    <Button
      text={"Demander modification"}
      onClick={() => {
        openConfirmationModale(
          generateTransitionWarningMessage(IN_REVIEW),
          () => {
            cancelSubmit([WorkflowTarget.GLOBAL]);
          }
        );
      }}
    />
  );
};

export default CancelSubmitButton;
