import React, { Dispatch, SetStateAction } from "react";
import FormikAdapter from "libAdapter/Formik/FormikAdapter";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { EmailUnsubscriptionValues } from "./types";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import isEqual from "lodash.isequal";
import { SUBTITLE_STYLE } from "theme";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
});

interface BlocConfigurationNotificationProps {
  emailSubscription: EmailUnsubscriptionValues;
  setEmailSubscription: Dispatch<SetStateAction<EmailUnsubscriptionValues>>;
  onSubmit: (values: EmailUnsubscriptionValues) => Promise<boolean>;
}

export const BlocConfigurationNotification = ({
  emailSubscription,
  setEmailSubscription,
  onSubmit,
}: BlocConfigurationNotificationProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "company-technique-global",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);
  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();
  return (
    <FormikAdapter
      initialValues={emailSubscription}
      onSubmit={async values => {
        await triggerValidateSpinner(
          onSubmit(values).then((success: boolean) => {
            if (success) {
              setEmailSubscription(values);
              values.newElement = false;
            }
          })
        );
      }}
      render={props => {
        const hasChanges =
          !isEqual(
            props.values.initiateReminderDisabled,
            emailSubscription.initiateReminderDisabled
          ) ||
          !isEqual(
            props.values.validationReminderDisabled,
            emailSubscription.validationReminderDisabled
          ) ||
          emailSubscription.newElement;
        return (
          <>
            <div className={classes.main}>
              <div className={classes.subTitle}>
                Vos préférences de communication
              </div>
              <BooleanField
                label="Recevoir les notifications relatives aux déclarations annuelles non initialisées"
                name={"initiateReminderDisabled"}
                disabled={false}
              />
              <BooleanField
                label="Recevoir les notifications relatives aux déclarations annuelles finalisées mais non transmises à l’inspection"
                name={"validationReminderDisabled"}
                disabled={false}
              />
              <DummyGlobalFormActions
                validateButton={{
                  title: "VALIDER",
                  isVisible: true,
                  isEnabled: hasChanges,
                  onClick: props.submitForm,
                  isSpinnerVisible: isValidateSpinnerVisible,
                }}
              />
            </div>
          </>
        );
      }}
    />
  );
};

export default BlocConfigurationNotification;
