import {
  ActiviteTransformationDto,
  ReferenceGeoCodeInseeDto,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ReferencePaysDto,
} from "api/gen";
import { FactoryInArray, france } from "./types";
import { convertStringCountryToModalOptionProps } from "../../utils/converters";
import { findElementMatchingTemplate } from "common/utils/methods";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

const getPaysNameById = (paysRef: ReferenceItemPaysDto[], paysID: string) => {
  const pays = findElementMatchingTemplate({ uuid: paysID }, paysRef);
  return pays ? pays.designation : null;
};

const getCityById = (
  cityRef: ReferenceItemGeoCodeInseeDto[],
  cityID: string
): OptionPropsWithObject<ReferenceItemGeoCodeInseeDto> | null => {
  const city = findElementMatchingTemplate({ uuid: cityID }, cityRef);
  return city
    ? {
        value: parseInt(city.numeroDepartement),
        label: city.nomCommune,
        object: city,
      }
    : null;
};

export const convertActiviteTransformationDtoToDisplayed = (
  activitesTransformationDto: ActiviteTransformationDto[],
  referentialCountries: ReferencePaysDto,
  referentialCities: ReferenceGeoCodeInseeDto
): FactoryInArray[] => {
  return activitesTransformationDto.map(activite => {
    const paysName =
      activite.paysID !== france && activite.paysID !== null
        ? getPaysNameById(
            referentialCountries.referenceItemPaysDtoList,
            activite.paysID
          )
        : activite.paysID;

    const city =
      activite.paysID === france && activite.ville !== null
        ? getCityById(
            referentialCities.referenceItemGeoCodeInseeDtoList,
            activite.ville
          )
        : activite.ville;

    return {
      data: {
        id: activite.id,
        socialReason: activite.raisonSocialeUsine,
        zipCode: activite.codePostal,
        country: convertStringCountryToModalOptionProps(
          paysName || "",
          referentialCountries
        ) || {
          label: paysName,
          value: -1,
        },
        city: city !== null ? city : "",
        careerRange: activite.distanceUsineCarriere,
        poweredByOtherCareers: activite.alimenteeParCarrieres,
      },
      errors: {},
    };
  });
};

export const convertDisplayedToActiviteTransformationDto = (
  factoriesInArray: FactoryInArray[]
): ActiviteTransformationDto[] => {
  return factoriesInArray.map(populatedFactory => {
    let countryName: string | null;
    if (populatedFactory.data.country === null) {
      countryName = null;
    } else {
      countryName =
        populatedFactory.data.country.label !== france &&
        populatedFactory.data.country.object
          ? populatedFactory.data.country.object.uuid
          : populatedFactory.data.country.label;
    }

    const cityName =
      typeof populatedFactory.data.city !== "string" &&
      populatedFactory.data.city
        ? populatedFactory.data.city.object.uuid
        : populatedFactory.data.city;
    return {
      id: populatedFactory.data.id,
      raisonSocialeUsine: populatedFactory.data.socialReason,
      codePostal: populatedFactory.data.zipCode,
      ville: cityName,
      paysID: countryName,
      distanceUsineCarriere: populatedFactory.data.careerRange,
      alimenteeParCarrieres: !!populatedFactory.data.poweredByOtherCareers,
    };
  });
};
