import React, { ReactElement, ReactNode } from "react";
import { EmissionsTotalesCO2eDto, ResteVentilationDto } from "api/gen";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import InfoBulle from "../../../../../../../../../../common/infoBulle/InfoBulle";

const computeLine = <T,>(
  listElements: T[],
  computeCell: (sousInstallation: T) => ReactElement,
  lineDescription: ReactNode,
  classes: ClassesType<"full" | "tooltip">,
  infobulleMessage?: string
) => {
  const line = listElements.map(computeCell);

  line.unshift(
    computeWrappedCell(
      <>
        {infobulleMessage && (
          <InfoBulle
            content={infobulleMessage}
            placement={"left"}
            additionalClassName={classes.tooltip}
          />
        )}
        <p>{lineDescription}</p>
      </>,
      classes
    )
  );

  return line;
};

export const computeAnneeLine = (
  yearList: string[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = (annee: string) => <p>{annee}</p>;
  const lineDescription = "Année";

  return computeLine(yearList, computeCell, lineDescription, classes);
};

export const computeEmissionsTotalesCo2eLine = (
  listEmissionsTotalesCo2e: EmissionsTotalesCO2eDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ emission }: EmissionsTotalesCO2eDto) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(emission)}</p>
  );
  const lineDescription = "Emissions totales de CO2e en t/année";

  return computeLine(
    listEmissionsTotalesCo2e,
    computeCell,
    lineDescription,
    classes
  );
};

export const computeResteVentilationLine = (
  listResteVentilation: ResteVentilationDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ reste }: ResteVentilationDto) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(reste)}</p>
  );
  const lineDescription = "Reste de la ventilation de combustible en TJ/année";
  const infobulleMessage = "Le reste doit être égal à 0";

  return computeLine(
    listResteVentilation,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

export const computePreferredColWidths = (list: unknown[]): number[] => {
  const preferredColWidths = list.map(() => 20);
  preferredColWidths.unshift(60);

  return preferredColWidths;
};
