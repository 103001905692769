import * as Yup from "yup";
import { AccidentCauseType, AccidentInModale, deadlyLabel } from "../utils";
import { tooLongMessage } from "common/declarant/formik/formikMessages";
import {
  commonDateFieldsRequired,
  commonObjectFields,
  commonStringFields,
  subNumberFieldValidationScheme,
} from "common/declarant/formik/formikHelper";

//harcoded here because it shouldn't change.
export const otherAccidentLabel = "Autres causes";

export const singleAccidentHasPrecisionSubPartActivated = (
  mainAccidentCause: AccidentCauseType | null | undefined
) => {
  return (
    mainAccidentCause && mainAccidentCause.object.cause === otherAccidentLabel
  );
};

export const singleAccidentOverwriteDependantFields = (
  accident: AccidentInModale
) => {
  if (!singleAccidentHasPrecisionSubPartActivated(accident.accidentCause)) {
    accident.accidentCausePrecision = null;
  }

  if (accident.isConsolidated === deadlyLabel) {
    accident.nbDaysStopped = null;
  }
};

export const singleAccidentValidationSchema = Yup.object().shape({
  date: commonDateFieldsRequired,
  accidentCause: commonObjectFields,
  accidentCausePrecision: Yup.string().when("accidentCause", {
    is: (accidentCause: AccidentCauseType | null) =>
      singleAccidentHasPrecisionSubPartActivated(accidentCause || null),
    then: commonStringFields.max(250, tooLongMessage),
    otherwise: Yup.string().nullable(),
  }),
  isConsolidated: commonStringFields, //don't think checking against the array of values would be any useful here, since it should be the only selectable values anyway...
  nbDaysStopped: subNumberFieldValidationScheme(
    "isConsolidated",
    isConsolidated => isConsolidated !== deadlyLabel
  ),
});
