import { makeStyles } from "@material-ui/styles";
import React from "react";
import { DASHBOARD_BORDER_GREY, PAGE_TITLE_FONT_SIZE, PINK } from "theme";
import Row from "common/presentational/Row";
import BigCard from "./cards/BigCard";
import fileImg from "./icons/file.svg";
import groupImg from "./icons/group.svg";
import scheduleImg from "./icons/schedule.svg";
import rulerImg from "./icons/ruler.svg";
import { usePageHeaderSetterOnce } from "pages/CommonSpace/AppPage/PageHeaderContext";
import { useUserData } from "Authenticator/UserData";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
  },
  title: {
    color: PINK,
    fontSize: PAGE_TITLE_FONT_SIZE,
    fontWeight: "normal",
  },
});

const Home = (): React.ReactElement => {
  const classes = useStyles();
  const userData = useUserData();

  usePageHeaderSetterOnce(null);

  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <Row
          additionalStyle={{
            height: "30px",
            alignItems: "center",
            width: "90%",
          }}
        >
          <h3 className={classes.title}>ACCUEIL</h3>
        </Row>
        <Row height="60px" />
        <Row
          additionalStyle={{
            marginLeft: "20px",
            flexWrap: "wrap",
            height: "auto",
            justifyContent: "space-around",
          }}
        >
          <BigCard
            link="/inspecteur/etablissements"
            picture={{ src: groupImg, alt: "trois personnes" }}
            titre="ÉTABLISSEMENTS"
            text="Ensemble des comptes établissement soumis à mon autorité"
          />
          <BigCard
            link="/inspecteur/declarations"
            picture={{ src: fileImg, alt: "une feuille de papier" }}
            titre="DÉCLARATIONS"
            text="Ensemble des déclarations à inspecter"
          />
        </Row>
        {userData.isAdmin && (
          <>
            <Row height="60px" />
            <Row
              additionalStyle={{
                marginLeft: "20px",
                flexWrap: "wrap",
                height: "auto",
                justifyContent: "space-around",
              }}
            >
              {/* pages accessibles seulement à l'admin ici */}
              {userData.isSuperAdmin && (
                <BigCard
                  link="/superadmin/campagnes"
                  picture={{
                    src: scheduleImg,
                    alt: "Calendrier avec chronomètre",
                  }}
                  titre="CAMPAGNES"
                  text="Page de modification des dates de campagnes"
                />
              )}
              {
                <BigCard
                  link="/admin/referentiels"
                  picture={{
                    src: rulerImg,
                    alt: "une équerre, une règle et un rapporteur",
                  }}
                  titre="RÉFÉRENTIELS"
                  text="Page de modification des référentiels"
                />
              }
            </Row>
          </>
        )}
        <Row height="200px" />
      </div>
    </div>
  );
};

export default Home;
