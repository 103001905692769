import React, { useState } from "react";
import { FiltresDeclarationDtoSortByEnum, SimpleDeclarationDto } from "api/gen";
import { useDeclarationTableLines } from "./declarationTableLines";
import { VersionedContactInfoModal } from "../../ContactInfosModal/VersionedContactInfoModal";
import { useUserData } from "../../../../Authenticator/UserData";
import { useDeclarationTableauHeader } from "./declarationTableHeader";
import CommonEntityTableFirstNSticky from "../../../../common/presentational/CommonEntityTable/CommonEntityTableFirstNSticky";

interface CompanyTableProps {
  declarations: SimpleDeclarationDto[];
  sorter: FiltresDeclarationDtoSortByEnum | null;
  isSortReversed: boolean | null;
  updateSortFilter: (
    sorter: FiltresDeclarationDtoSortByEnum | null,
    isSortReversed: boolean | null
  ) => void;
}

const STICKY_ETABLISSEMENT_ANNEE_COMMUNE = 3;

export const DeclarationsTable = ({
  declarations,
  sorter,
  isSortReversed,
  updateSortFilter,
}: CompanyTableProps): React.ReactElement => {
  const userData = useUserData();
  const [contact, setContact] = useState<SimpleDeclarationDto | null>(null);

  const header = useDeclarationTableauHeader(
    sorter,
    isSortReversed,
    updateSortFilter,
    !declarations.some(declaration => declaration.annee < 2023)
  );
  const lines = useDeclarationTableLines(
    declarations,
    declaration => setContact(declaration),
    userData.isInspecteur
  );
  return (
    <>
      <CommonEntityTableFirstNSticky
        header={header}
        lines={lines}
        preferredColWidths={[
          160, // nom
          130, // commune
          60, // annee
          100, // % gen
          150, // last gene
          150, // last
          150,
          150,
          150,
          150,
          150,
          150,
          150,
          150,
          100,
        ]}
        nbColSticky={STICKY_ETABLISSEMENT_ANNEE_COMMUNE}
      />

      <VersionedContactInfoModal
        isOpen={contact !== null}
        year={contact && contact.annee}
        company={contact && contact.etablissement}
        onRequestClose={() => setContact(null)}
      />
    </>
  );
};
