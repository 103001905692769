import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { AreasOtherInfosForm } from "./utils/types";
import { convertDeclarationDtoUsingAreasOtherInfosForm } from "../utils/converters";
import { convertDtoToAreasOtherInfosForm } from "./utils/converters";
import { validationSchema } from "./utils/validation";
import { Nullable } from "common/utils/types";
import {
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE,
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_AUTORISEE,
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_EXPLOITEE,
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTANT_EXPLOITER,
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTITUEE,
} from "common/path/path18Now";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

interface BlocSuperficiesAutresRenseignementsProps {
  areasOtherInfosProps: FormikBlocRefAndState<Nullable<AreasOtherInfosForm>>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const BlocSuperficiesAutresRenseignements = ({
  areasOtherInfosProps,
}: BlocSuperficiesAutresRenseignementsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "environnement-bloc-autres-renseignements-superficies",
  };

  const NumberField = useNumberFieldGenerator(commonProps);

  const initialValues = convertDtoToAreasOtherInfosForm(declaration);

  return (
    <FormikBlocFullContext1919<Nullable<AreasOtherInfosForm>>
      hasChanges={areasOtherInfosProps.hasChanges}
      setHasChanges={areasOtherInfosProps.setHasChanges}
      formikRef={areasOtherInfosProps.formikRef}
      title="AUTRES RENSEIGNEMENTS SUR LES SUPERFICIES"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE}
      updateHandler={convertDeclarationDtoUsingAreasOtherInfosForm}
      renderContent={(_, shouldDisabledFields: boolean) => (
        <>
          <NumberField
            label="Superficie cadastrale autorisée *"
            name="authorizedArea"
            unit="ha"
            disabled={shouldDisabledFields}
            commentPath={
              PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_AUTORISEE
            }
          />

          <NumberField
            label="Superficie restant à exploiter *"
            name="remainingArea"
            unit="ha"
            disabled={shouldDisabledFields}
            tooltipContent="Superficie au 31/12/N-1"
            commentPath={
              PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTANT_EXPLOITER
            }
          />

          <NumberField
            label="Superficie exploitée dans l'année *"
            name="exploitedThisYearArea"
            unit="ha"
            disabled={shouldDisabledFields}
            tooltipContent="Superficie au 31/12/N-1"
            commentPath={
              PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_EXPLOITEE
            }
          />

          <NumberField
            label="Superficie restituée avec PV de récolement dans l'année"
            name="restoredArea"
            unit="ha"
            disabled={shouldDisabledFields}
            commentPath={
              PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE_RESTITUEE
            }
          />
        </>
      )}
    />
  );
};

export default BlocSuperficiesAutresRenseignements;
