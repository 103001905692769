import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

export enum MethodCombustion {
  FACTEUR_EMISSION,
  MESURE,
  BILAN_MATIERE,
}

export interface MethodCombustionInfo {
  value: MethodCombustion;
  display: string;
}

export const arrayMethods: OptionPropsWithObject<MethodCombustionInfo>[] = [
  {
    value: 1,
    label: "Facteur d'émission",
    object: {
      value: MethodCombustion.FACTEUR_EMISSION,
      display: "Facteur d'émission",
    },
  },
  {
    value: 2,
    label: "Mesure",
    object: {
      value: MethodCombustion.MESURE,
      display: "Mesure",
    },
  },
  {
    value: 3,
    label: "Bilan matière",
    object: {
      value: MethodCombustion.BILAN_MATIERE,
      display: "Bilan matière",
    },
  },
];
