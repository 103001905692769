import { AirGazHfcDto, AirGazPfcDto, ReferenceItemGazDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { InArray, InModale } from "common/form/utils";
import { Omit } from "react-router";

export const pfcFieldMatcher = fieldMatcherBuilder<AirGazPfcDto>().build({
  CF4: "cf4",
  C2F6: "c2f6",
  C3F8: "c3f8",
  C4F10: "c4f10",
  C5F12: "c5f12",
  C6F14: "c6f14",
  C4F8: "c4f8",
});

interface HfcFluid extends Omit<AirGazHfcDto, "id" | "gazID"> {
  fluid: ReferenceItemGazDto;
  preciser: string;
}

export type FluidInModale = InModale<HfcFluid>;

export type FluidInArray = InArray<HfcFluid>;

export const hfcFluidFieldMatcher = fieldMatcherBuilder<FluidInModale>().build({
  fluid: "fluid",
  preciser: "preciser",
  emission: "quantite",
  capacity: "capacite",
  justify: "justification",
  HFC32: "hfc32",
  HFC125: "hfc125",
  HFC134a: "hfc134a",
  HFC143a: "hfc143a",
  HFC152a: "hfc152a",
  HFC227ea: "hfc227ea",
  HFC1234yf: "hfc1234yf",
  HFC1234ze: "hfc1234ze",
  HFC23: "hfc23",
  HFC41: "hfc41",
  HFC4310mee: "hfc4310mee",
  HFC134: "hfc134",
  HFC143: "hfc143",
  HFC236fa: "hfc236fa",
  HFC245ca: "hfc245ca",
  HFC245fa: "hfc245fa",
  HFC365mfc: "hfc365mfc",
  HFC1233zd: "hfc1233zd",
});
