import { CombustibleInModale } from "./types";

export const computeQuantiteMethaneOxyde = (
  values: CombustibleInModale
): number | null => {
  return values.biogazDebit !== null &&
    values.biogazDuree !== null &&
    values.ch4Teneur !== null
    ? (values.biogazDebit * values.biogazDuree * values.ch4Teneur) / 1000
    : null;
};
