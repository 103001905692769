import {
  ProduitTransportDto23Now,
  ModaliteTransportDto,
  ReferencePaysDto,
  ReferenceDepartementDto,
  ReferenceItemDepartementDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import { ProduitTransportType, TransportProductsForm } from "./types";
import {
  convertStringFamilyProductionToReferenceItem,
  convertFamilyProductionNameToReferenceItem,
  convertStringSubFamilyProductionToReferenceItem,
  convertStringSubFamilyLvl2ProductionToReferenceItem,
} from "../../Families/converters";
import { franceOrOtherCountry } from "./selectPossibleValues";
import { convertStringCountryToReferenceItem } from "../../utils/converters";
import { findElementMatchingTemplate } from "common/utils/methods";

export const convertStringDepartementToReferenceItem = (
  departmentID: string,
  departmentRef: ReferenceItemDepartementDto[]
): ReferenceItemDepartementDto | null => {
  const departement = findElementMatchingTemplate(
    { uuid: departmentID },
    departmentRef
  );
  return departement;
};

export const convertInFranceStringToBoolean = (
  inFranceLabel: string
): boolean => {
  return inFranceLabel === franceOrOtherCountry[0];
};

export const convertInFranceBooleanToString = (
  inFranceBoolean: boolean
): string => {
  return inFranceBoolean ? franceOrOtherCountry[0] : franceOrOtherCountry[1];
};

export const convertProduitTransportDtoToDisplayed = (
  produitsTransportDto: ProduitTransportDto23Now[],
  referentialDepartments: ReferenceDepartementDto,
  referentialCountries: ReferencePaysDto,
  referentialFamilies: ReferenceFamilleUsageProductionDto,
  referentialFamiliesCodeProduits: ReferenceFamilleUsageCodeProduitDto
): ProduitTransportType[] => {
  return produitsTransportDto.map(produitTransportDto => {
    const paysName =
      produitTransportDto.paysId === null
        ? null
        : findElementMatchingTemplate(
            { uuid: produitTransportDto.paysId },
            referentialCountries.referenceItemPaysDtoList
          );
    const family = convertStringFamilyProductionToReferenceItem(
      produitTransportDto.famille?.identifier || "",
      referentialFamilies
    );
    const subFamily = convertStringSubFamilyProductionToReferenceItem(
      produitTransportDto.sousFamille?.identifier || "",
      family
    );
    const subFamilyLvl2 = convertStringSubFamilyLvl2ProductionToReferenceItem(
      produitTransportDto.sousFamilleNiveau2?.identifier || "",
      subFamily
    );
    return {
      id: produitTransportDto.id,
      famille: family,
      precisionFamille: produitTransportDto.famille?.precisionSiAutre ?? "",
      sousFamille: subFamily,
      precisionSousFamille:
        produitTransportDto.sousFamille?.precisionSiAutre ?? "",
      sousFamilleNiveau2: subFamilyLvl2,
      precisionSousFamilleNiveau2:
        produitTransportDto.sousFamilleNiveau2?.precisionSiAutre ?? "",
      enFrance: convertInFranceBooleanToString(produitTransportDto.enFrance),
      departement: convertStringDepartementToReferenceItem(
        produitTransportDto.departementId || "",
        referentialDepartments.departements
      ),
      pays: convertStringCountryToReferenceItem(
        paysName ? paysName.designation : "",
        referentialCountries
      ),
      quantite: produitTransportDto.quantite,
    };
  });
};

export const convertDisplayedToProduitTransportDto = (
  transportProductsInArray: ProduitTransportType[]
): ProduitTransportDto23Now[] => {
  return transportProductsInArray.map(transportProductInArray => {
    return {
      id: transportProductInArray.id,
      quantite: transportProductInArray.quantite,
      famille: {
        identifier: transportProductInArray.famille?.uuid ?? "",
        precisionSiAutre: transportProductInArray.precisionFamille ?? "",
      },
      sousFamille: {
        identifier: transportProductInArray.sousFamille?.uuid ?? "",
        precisionSiAutre: transportProductInArray.precisionSousFamille ?? "",
      },
      sousFamilleNiveau2: {
        identifier: transportProductInArray.sousFamilleNiveau2?.uuid ?? "",
        precisionSiAutre:
          transportProductInArray.precisionSousFamilleNiveau2 ?? "",
      },
      enFrance: convertInFranceStringToBoolean(
        transportProductInArray.enFrance
      ),
      departementId: transportProductInArray.departement
        ? transportProductInArray.departement.uuid
        : null,
      paysId: transportProductInArray.pays
        ? transportProductInArray.pays.uuid
        : null,
    };
  });
};

export const convertModaliteTransportDtoToDisplayed = (
  modaliteTransportsDto: ModaliteTransportDto[],
  referentialFamilies: ReferenceFamilleUsageProductionDto
): TransportProductsForm[] => {
  return modaliteTransportsDto.map(modaliteTransport => {
    // to know the family used in the modaliteTransport, we have to search with the name of the famille in the referential
    const family =
      convertFamilyProductionNameToReferenceItem(
        modaliteTransport.famille,
        referentialFamilies
      ) || referentialFamilies.referenceItemFamilleUsageProductionDtoList[0];
    return {
      id: referentialFamilies.referenceItemFamilleUsageProductionDtoList
        .findIndex(
          (item: ReferenceItemFamilleUsageProductionDto) => item === family
        )
        .toString(),
      usageFamily: family,
      byRoad: modaliteTransport.route,
      byRail: modaliteTransport.rail,
      byWaterWay: modaliteTransport.navigable,
      other: modaliteTransport.autre,
      otherPrecision: modaliteTransport.preciserAutre,
    };
  });
};

export const convertDisplayedToModaliteTransportDto = (
  transportProductsForm: Record<number, TransportProductsForm>
): ModaliteTransportDto[] => {
  return Object.values(transportProductsForm).map(product => {
    return {
      id: product.usageFamily.restorationCode.toString(),
      famille: product.usageFamily.libelle,
      route: product.byRoad,
      rail: product.byRail,
      navigable: product.byWaterWay,
      autre: product.other,
      preciserAutre: product.otherPrecision,
    };
  });
};
