import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import {
  EmissionsForm,
  EmissionsSpeciesForm,
  PopulatedBuildingInArray,
} from "./types";
import {
  convertPopulatedBuildingToBuildingDto,
  convertSpecieOptionPropsToEnum,
} from "../blocEmissions/utils/converters";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";

export const convertDeclarationDtoUsingEmissionsForm = (
  declaration: Declaration1919,
  values: EmissionsForm
): Declaration1919 => {
  declaration.body.sections.air.elevage.emissions.nh3 = values.NH3Emission;
  declaration.body.sections.air.elevage.emissions.n2o = values.N2OEmission;
  declaration.body.sections.air.elevage.emissions.ch4 = values.CH4Emission;
  declaration.body.sections.air.elevage.emissions.tsp = values.TSPEmission;
  declaration.body.sections.air.elevage.emissions.pm10 = values.PM10Emission;
  return declaration;
};

export const convertDeclarationDtoUsingEmissionsSpeciesForm = (
  declaration: Declaration1919,
  values: EmissionsSpeciesForm
): Declaration1919 => {
  declaration.body.sections.air.elevage.detailNh3.especesDeclarees = convertSpecieOptionPropsToEnum(
    values.species
  );
  return declaration;
};

export const convertDeclarationDtoUsingBuildings = (
  declaration: Declaration1919,
  values: PopulatedBuildingInArray[],
  selectedSpecies: CleanOptionProps[]
): Declaration1919 => {
  declaration.body.sections.air.elevage.detailNh3.batiments = convertPopulatedBuildingToBuildingDto(
    values,
    selectedSpecies
  );
  return declaration;
};

export const convertDeclarationDtoUsingCheckboxesForm = (
  declaration: Declaration1919,
  isMtdChecked: boolean
): Declaration1919 => {
  declaration.body.sections.air.elevage.mtd = isMtdChecked;
  return declaration;
};
