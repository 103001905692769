import * as Yup from "yup";
import {
  PolluantInArray,
  PolluantInModale,
  SolCommonSelectOptions,
} from "../utils/types";
import { Errors } from "common/form/utils";
import { computeRefMethodForMethod } from "../utils/selectPossibleValues";
import {
  RejetSolDtoMethodeUtiliseeEnum,
  RejetSolDtoReferenceMethodeEnum,
} from "api/gen/api";
import {
  commonArrayOfObjectFields,
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import {
  requiredMessage,
  solInvalidAccidentalMessage,
  solMethodIncompatibleMessage,
  solSubstanceAlreadyInArrayMessage,
  solSubstanceInconnueMessage,
} from "common/declarant/formik/formikMessages";
import _ from "lodash";

export const singlePolluantHasSubPartActivated = (
  usedMethod: RejetSolDtoMethodeUtiliseeEnum | null
): boolean => {
  return !!(
    usedMethod &&
    (usedMethod === RejetSolDtoMethodeUtiliseeEnum.MESURE ||
      usedMethod === RejetSolDtoMethodeUtiliseeEnum.CALCUL)
  );
};

export const singlePolluantDescriptionAvailable = (
  methodRef: RejetSolDtoReferenceMethodeEnum | null
): boolean => {
  return !!(
    methodRef &&
    (methodRef === RejetSolDtoReferenceMethodeEnum.INT ||
      methodRef === RejetSolDtoReferenceMethodeEnum.PER ||
      methodRef === RejetSolDtoReferenceMethodeEnum.NRO ||
      methodRef === RejetSolDtoReferenceMethodeEnum.AUT)
  );
};

const singlePolluantRefMethodSupported = (
  ref: RejetSolDtoReferenceMethodeEnum,
  method: RejetSolDtoMethodeUtiliseeEnum
): RejetSolDtoReferenceMethodeEnum | undefined => {
  if (method === RejetSolDtoMethodeUtiliseeEnum.ESTIMATION) {
    return undefined;
  }

  const possibleRef = computeRefMethodForMethod(method);
  return possibleRef.find(tested => {
    return tested === ref;
  });
};

export const singlePolluantDescritionRequired = (
  methodRef: RejetSolDtoReferenceMethodeEnum
): boolean => {
  return (
    methodRef === RejetSolDtoReferenceMethodeEnum.INT ||
    methodRef === RejetSolDtoReferenceMethodeEnum.PER
  );
};

export const singlePolluantRefMethodRequired = (
  methodeRef: RejetSolDtoReferenceMethodeEnum | null
): boolean => {
  return !!(
    methodeRef &&
    (methodeRef === RejetSolDtoReferenceMethodeEnum.INT ||
      methodeRef === RejetSolDtoReferenceMethodeEnum.PER ||
      methodeRef === RejetSolDtoReferenceMethodeEnum.NRO)
  );
};

export const singlePolluantOverwriteDependantFields = (
  polluant: PolluantInModale
): void => {
  if (!singlePolluantHasSubPartActivated(polluant.usedMethod || null)) {
    polluant.methodReference = null;
    polluant.methodDescription = null;
  }
};

export const validationSchema = Yup.object().shape({
  doesTreatmentOrValorisation: Yup.boolean().required(requiredMessage),
  totalAmount: Yup.number().when("doesTreatmentOrValorisation", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
});

export const singlePolluantValidationSchema = Yup.object().shape({
  pollutingSubstance: commonObjectFields,
  emission: commonPositiveNumberFields,
  includingAccidental: commonPositiveNumberFields.max(
    Yup.ref("emission"),
    solInvalidAccidentalMessage
  ),
  usedMethod: Yup.mixed<RejetSolDtoMethodeUtiliseeEnum>().required(
    requiredMessage
  ),
  methodReference: Yup.mixed<RejetSolDtoReferenceMethodeEnum>().when(
    "usedMethod",
    {
      is: (usedMethod: RejetSolDtoMethodeUtiliseeEnum | null) =>
        singlePolluantHasSubPartActivated(usedMethod || null),
      then: Yup.mixed<RejetSolDtoReferenceMethodeEnum>().required(
        requiredMessage
      ),
      otherwise: Yup.mixed<RejetSolDtoReferenceMethodeEnum>().nullable(),
    }
  ),
  methodDescription: Yup.string().nullable(), // Field toucher friendly
  polluantNorme: Yup.array().when("methodReference", {
    is: (methodReference: RejetSolDtoReferenceMethodeEnum | null) =>
      singlePolluantRefMethodRequired(methodReference),
    then: commonArrayOfObjectFields,
    otherwise: Yup.array().nullable(),
  }),
});

export const additionalCalcErrors = (
  polluant: PolluantInModale,
  referentiels: SolCommonSelectOptions,
  polluantsInArray: PolluantInArray[],
  changedElemId: string | null
): Errors<PolluantInModale> => {
  const errorReturned: Errors<PolluantInModale> = {};

  if (polluant.pollutingSubstance) {
    const substanceName = polluant.pollutingSubstance.nom;
    if (
      !referentiels.polluantName.find(tested => {
        return tested.nom === substanceName;
      })
    ) {
      errorReturned.pollutingSubstance = solSubstanceInconnueMessage;
    } else {
      const isAlreadyInArray = polluantsInArray.some(tested => {
        return (
          _.isEqual(
            tested.data.pollutingSubstance,
            polluant.pollutingSubstance
          ) && tested.data.id !== changedElemId
        );
      });
      if (isAlreadyInArray) {
        errorReturned.pollutingSubstance = solSubstanceAlreadyInArrayMessage;
      }
    }
  }
  if (
    polluant.usedMethod &&
    singlePolluantHasSubPartActivated(polluant.usedMethod)
  ) {
    if (
      polluant.methodReference &&
      !singlePolluantRefMethodSupported(
        polluant.methodReference,
        polluant.usedMethod
      )
    ) {
      errorReturned.methodReference = solMethodIncompatibleMessage;
    }
  }
  if (
    polluant.methodReference &&
    singlePolluantDescritionRequired(polluant.methodReference) &&
    !polluant.methodDescription
  ) {
    errorReturned.methodDescription = requiredMessage;
  }

  return errorReturned;
};

export const calcErrors = (
  polluant: PolluantInModale,
  referentiels: SolCommonSelectOptions
): Errors<PolluantInModale> => {
  const errorReturned: Errors<PolluantInModale> = {};

  try {
    //todo find a way to remove this validate sync which takes too much time!
    // (create promise in the map, for the unmodified elems return them as they are in a new promise.then, and
    // wrap the map of promise in a promise.all)
    singlePolluantValidationSchema.validateSync(polluant, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach((errorData: { path: string; message: string }) => {
      const errorKey = errorData.path as keyof PolluantInModale; // we know the path is a valid key of polluantSol (as long as we keep polluant and schema with same key, that is)
      errorReturned[errorKey] = errorData.message;
    });
  }

  return {
    ...errorReturned,
    ...additionalCalcErrors(polluant, referentiels, [], null),
  };
};
