import {
  PolluantEauDtoMethodeEnum,
  PolluantEauDtoReferenceMethodeEnum,
  PolluantEauDtoTypeRejetEnum,
  ReferenceItemNormeEauDto,
} from "api/gen/api";
import { PolluantInModale } from "./types";
import BigNumber from "bignumber.js";

// Functions to define which field are active (ie displayed to user)
// Unactive field should not be considered in validation
// Unactive field are not saved when polluant is added

export const isFieldMethodReferenceActive = (
  usedMethod?: PolluantEauDtoMethodeEnum | null
): boolean => {
  return (
    usedMethod === PolluantEauDtoMethodeEnum.MESURE ||
    usedMethod === PolluantEauDtoMethodeEnum.CALCUL
  );
};

export const isFieldNormeActive = (
  methodReference?: PolluantEauDtoReferenceMethodeEnum | null
): boolean => {
  return (
    methodReference === PolluantEauDtoReferenceMethodeEnum.INT ||
    methodReference === PolluantEauDtoReferenceMethodeEnum.PER ||
    methodReference === PolluantEauDtoReferenceMethodeEnum.NRO
  );
};

export const isFieldIncludingImportedActive = (
  type: PolluantEauDtoTypeRejetEnum | null
): boolean => {
  return type === PolluantEauDtoTypeRejetEnum.ISOLE;
};

export const isPurificationYieldActive = (
  type: PolluantEauDtoTypeRejetEnum | null
): boolean => {
  return type === PolluantEauDtoTypeRejetEnum.RACCORDE;
};

// Functions for calculated field

export const calculateEmission = (values: PolluantInModale): number => {
  const emission = new BigNumber(values.emission ?? 0);
  const includingImported = new BigNumber(
    values.type &&
    isFieldIncludingImportedActive(values.type) &&
    values.includingImported !== null
      ? values.includingImported
      : 0
  );
  return emission.minus(includingImported).toNumber();
};

export const calculateFinalEmission = (values: PolluantInModale): number => {
  const purificationYield = new BigNumber(
    values.purificationYield ?? 0
  ).dividedBy(new BigNumber(100));
  const calcEmission = new BigNumber(calculateEmission(values));

  return new BigNumber(1)
    .minus(purificationYield)
    .multipliedBy(calcEmission)
    .toNumber();
};

// Utils functions

export const computeNormesList = (
  normes: ReferenceItemNormeEauDto[],
  methodReference?: PolluantEauDtoReferenceMethodeEnum | null
): ReferenceItemNormeEauDto[] => {
  return normes.filter(
    norme =>
      (methodReference === PolluantEauDtoReferenceMethodeEnum.INT &&
        norme.matchInt) ||
      (methodReference === PolluantEauDtoReferenceMethodeEnum.PER &&
        norme.matchPer) ||
      (methodReference === PolluantEauDtoReferenceMethodeEnum.NRO &&
        norme.matchNro)
  );
};

export const computeNormLabel = (
  norme: ReferenceItemNormeEauDto | null
): string => {
  if (norme !== null) {
    return `${norme.libelle}${
      norme.descriptionNorme ? ` - ${norme.descriptionNorme}` : ""
    }`;
  }
  return "";
};
