import React from "react";
import { CommonProps, computeId, ErrorDataProps } from "../../utils";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStylesCustom = (labelWidth: string) =>
  makeStyles({
    defaultInputField: {
      marginBottom: "5px",
      display: "flex",
    },
    autoHeight: {
      height: "auto",
    },
    fieldRow: {
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    icon: {
      marginLeft: "5px",
    },
    limitWidth: {
      width: "100%",
      // force max-width based on label width
      // espacially usefull for Select with isMulti and containig large string
      maxWidth: `calc(100% - ${labelWidth})`,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })();

//props awaited in a SimpleInputDisplayer component
interface FieldWrapperProps {
  renderLabel?: (id: string) => React.ReactElement;
  name: string;
  commonProps: CommonProps;
  renderInput: (id: string) => React.ReactElement;
  errorData: ErrorDataProps;
  additionalClassName?: string;
}

const SimpleInputDisplayer = ({
  renderLabel,
  name,
  commonProps,
  additionalClassName,
  renderInput,
  errorData: { errorMessage, errorIcon, errorMessageClass },
}: FieldWrapperProps) => {
  const id = computeId(name, commonProps);

  const classes = useStylesCustom(
    renderLabel !== undefined ? commonProps.labelWidth : "0px"
  );
  return (
    <div
      className={classNames(
        classes.defaultInputField,
        commonProps.className,
        additionalClassName
      )}
    >
      {renderLabel !== undefined ? renderLabel(id) : ""}
      <div className={classes.limitWidth}>
        <Row
          additionalClassname={classNames(classes.fieldRow, classes.autoHeight)}
        >
          {renderInput(id)}
          {errorIcon && (
            <FontAwesomeIcon
              icon={errorIcon}
              className={classNames(errorMessageClass, classes.icon)}
              size="2x"
            />
          )}
          {commonProps.commentPath && (
            <CommentButton path={commonProps.commentPath} />
          )}
        </Row>
        {errorMessage && (
          <Row additionalClassname={classes.autoHeight}>
            <p className={errorMessageClass}>{errorMessage}</p>
          </Row>
        )}
      </div>
    </div>
  );
};

export default SimpleInputDisplayer;
