import { number } from "yup";

const DEFAULT_DECIMAL_PRECISION = 2;

export const getValueOrZero = (number: number | null): number => {
  if (number === null) {
    return 0;
  }
  return number;
};

// todo enhancement need to differentiate between TRANSFORMER (changing / rounding the number) AND FORMATTER (displaying the number and replacing number with string)
// numberTransformer.withReplacement(null, 0).withNbDecimal(2)

// export const getValueOrZero: (
//   number: number | null
// ) => number = numberTransformer.withReplacement(null, 0);

// https://medium.com/swlh/how-to-round-to-a-certain-number-of-decimal-places-in-javascript-ed74c471c1b8
// !! n can be already in scientific format. number_prototype.ToFixed() is used to force a decimal format
// We add three more decimal precision in ToFixed method to keep the final precision wished
export const roundNumber = (n: number, decimalPlace: number): number => {
  //transformer
  return Number(
    Math.round(Number(n.toFixed(decimalPlace + 3) + "e" + decimalPlace)) +
      "e-" +
      decimalPlace
  );
};

const getRoundedFixedNumber = (
  // transformer
  n: number | null,
  decimalPlace: number
): string => {
  if (n === null) {
    return "";
  }
  return roundNumber(n, decimalPlace).toFixed(decimalPlace);
};

export const getRoundedFixedNumberWithSeparatorOrNull = (
  n: number | null,
  decimalPlace: number = DEFAULT_DECIMAL_PRECISION
): string => {
  if (n === null) {
    return "";
  }
  return computeStringWithSeparator(getRoundedFixedNumber(n, decimalPlace));
};

export const getRoundedValueOrNullIfZero = (
  // currently a problem as it displays i.e 3,4000000000000057 instead of 3,4
  number: number | null
): number | null => {
  if (number === 0 || number === null) {
    return null;
  }
  return roundNumber(number, 7);
};

const computeStringWithSeparator = (
  str: string,
  showTrailingZero: boolean = true
): string => {
  // todo enhancement: use decimalformat or something alike rather than THIS hand made algorithm
  const [numStr, numDecStr] = str.split(".");
  if (numStr.includes("e")) {
    // Big number expressed with exponent
    return numStr;
  }
  let resNumStr = "";
  // Add integer digits, with space every 3 digits
  for (let i = 0; i < numStr.length; i++) {
    resNumStr = numStr[numStr.length - 1 - i] + resNumStr;
    if (i % 3 === 2 && i < numStr.length - 1) {
      resNumStr = " " + resNumStr;
    }
  }
  // Add decimal digits
  if (numDecStr) {
    resNumStr += ",";
    for (let j = 0; j < numDecStr.length; j++) {
      resNumStr += numDecStr[j];
    }
    if (!showTrailingZero) {
      for (let j = 0; j < numDecStr.length; j++) {
        if (resNumStr.slice(-1) === "0") {
          resNumStr = resNumStr.substring(0, resNumStr.length - 1);
        }
      }

      if (resNumStr.slice(-1) === ",") {
        resNumStr = resNumStr.substring(0, resNumStr.length - 1);
      }
    }
  }
  return resNumStr;
};

export const computeNumberWithSeparatorAndVariableDecimal = (
  number: number | null,
  decimalPlaceInfOne = 10,
  decimalPlaceSupOne = 4
): string => {
  if (number === null) {
    return "";
  }
  if (number < 1) {
    return computeNumberWithSeparator(number, decimalPlaceInfOne);
  }
  return computeNumberWithSeparator(number, decimalPlaceSupOne);
};

export const computeNumberWithSeparator = (
  number: number | null,
  decimalPlace: number = 3
): string => {
  if (number === null) {
    return "";
  }
  if (number != 0 && number < Math.pow(10, -1 * decimalPlace)) {
    return number.toExponential(decimalPlace);
  }
  return computeStringWithSeparator(
    getRoundedFixedNumber(number, decimalPlace),
    false
  );
};

export const computeNumberWithPercentage = (
  percentage: number,
  total: number
): string => {
  return `${computeNumberWithSeparator(
    (percentage * total) / 100
  )} (${computeNumberWithSeparator(percentage, 0)}%)`;
};

export const computeSum = (numberList: number[]): number =>
  numberList.reduce((sum, current) => sum + current, 0);
