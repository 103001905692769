import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { THREE_COLUMNS_CARD_GRID } from "theme";
import Row from "common/presentational/Row";

interface DashboardCardsContainerProps {
  children: ReactNode;
}

const useStyles = makeStyles({
  dashboardCardsContainer: {
    ...THREE_COLUMNS_CARD_GRID,
    display: "grid",
    gridGap: "20px",
    height: "auto",
    marginBottom: "20px",
  },
});

const DashboardCardsContainer = ({
  children,
}: DashboardCardsContainerProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Row additionalClassname={classes.dashboardCardsContainer}>{children}</Row>
  );
};

export default DashboardCardsContainer;
