import {
  ReferenceFamilleUsageRecyclageDto,
  ProduitRecyclageDto23Now,
} from "api/gen";
import { ProduitRecyclingType } from "./types";
import { natures } from "./selectPossibleValues";
import {
  convertStringFamilyRecyclingToReferenceItem,
  convertStringSubFamilyRecyclingToReferenceItem,
  convertStringSubFamilyLvl2RecyclingToReferenceItem,
} from "../../Families/converters";

export const convertProduitRecyclageDtoToDisplayed = (
  recyclingProductsDto: ProduitRecyclageDto23Now[],
  referentialFamilies: ReferenceFamilleUsageRecyclageDto
): ProduitRecyclingType[] => {
  return recyclingProductsDto.map(recyclingProduct => {
    const family = convertStringFamilyRecyclingToReferenceItem(
      recyclingProduct.famille?.identifier ?? null,
      referentialFamilies
    );
    const subFamily = convertStringSubFamilyRecyclingToReferenceItem(
      recyclingProduct.sousFamille?.identifier ?? null,
      family
    );
    const subFamilyLvl2 = convertStringSubFamilyLvl2RecyclingToReferenceItem(
      recyclingProduct.sousFamilleNiveau2?.identifier ?? null,
      subFamily
    );

    return {
      id: recyclingProduct.id,
      nature: recyclingProduct.nature || natures[0],
      precisionAutre: recyclingProduct.famille?.precisionSiAutre ?? "",
      famille: family,
      precisionFamille: recyclingProduct.famille?.precisionSiAutre ?? "",
      sousFamille: subFamily,
      precisionSousFamille:
        recyclingProduct.sousFamille?.precisionSiAutre ?? "",
      sousFamilleNiveau2: subFamilyLvl2,
      precisionSousFamilleNiveau2:
        recyclingProduct.sousFamilleNiveau2?.precisionSiAutre ?? "",
      quantite: recyclingProduct.quantite,
    };
  });
};

export const convertDisplayedToProduitRecyclageDto = (
  recyclingProducts: ProduitRecyclingType[]
): ProduitRecyclageDto23Now[] => {
  return recyclingProducts.map(recyclingProduct => {
    return {
      id: recyclingProduct.id,
      nature: recyclingProduct.nature,
      precisionAutre: recyclingProduct.precisionAutre ?? "",
      quantite: recyclingProduct.quantite,
      famille: {
        identifier: recyclingProduct.famille?.uuid ?? "",
        precisionSiAutre: recyclingProduct.precisionFamille ?? "",
      },
      sousFamille: {
        identifier: recyclingProduct.sousFamille?.uuid ?? "",
        precisionSiAutre: recyclingProduct.precisionSousFamille ?? "",
      },
      sousFamilleNiveau2: {
        identifier: recyclingProduct.sousFamilleNiveau2?.uuid ?? "",
        precisionSiAutre: recyclingProduct.precisionSousFamilleNiveau2 ?? "",
      },
    };
  });
};
