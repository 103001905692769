import React, { useCallback, useMemo, useState } from "react";
import { Formik } from "formik";
import { FormikConfig, FormikProps, FormikValues } from "formik/dist/types";
import FormikContext from "./FormikContext";
import {
  transformValidationSchemaToIgnoreFields,
  validateIgnoringFields,
} from "./validateHelpers";
import { readonlySet } from "common/utils/types";
import { RenderType } from "./types";
import { mergeValues } from "./renderHelpers";

export default function FormikAdapter<T = FormikValues>(
  props: Omit<FormikConfig<T>, "render"> & {
    formikRef?: React.RefObject<Formik<T>> | null;
    render: RenderType<T>;
  }
): React.ReactElement {
  const [mapExternalFieldNameToFieldValue, setExternalFieldsMap] = useState<
    Map<string, unknown>
  >(new Map());

  const externalFieldsNames: ReadonlySet<string> = useMemo(
    () => readonlySet(new Set(mapExternalFieldNameToFieldValue.keys())),
    [mapExternalFieldNameToFieldValue]
  );

  const {
    render,
    validate,
    validationSchema,
    formikRef,
    ...remainderProps
  } = props;

  // we need to be sure the validation schema changes WHENEVER the set of external field name changes!
  const validationSchemaIgnoringFields = useMemo(() => {
    return (
      validationSchema !== undefined &&
      transformValidationSchemaToIgnoreFields(
        validationSchema,
        externalFieldsNames
      )
    );
  }, [validationSchema, externalFieldsNames]);

  const renderWithExternalValuesPriority: (
    props: FormikProps<T>
  ) => React.ReactNode = useCallback(
    renderProps => {
      const { values, ...remainderRenderProps } = renderProps;
      const mergedValues = mergeValues(
        values,
        mapExternalFieldNameToFieldValue
      );

      return render({
        values: mergedValues,
        ...remainderRenderProps,
        internalOnlyValues: values,
      });
    },
    [render, mapExternalFieldNameToFieldValue]
  );

  return (
    <FormikContext
      externalFieldsNames={externalFieldsNames}
      setExternalFieldsMap={setExternalFieldsMap}
    >
      <Formik
        validate={values => {
          return validateIgnoringFields(values, validate, externalFieldsNames);
        }}
        validationSchema={validationSchemaIgnoringFields}
        ref={formikRef}
        render={renderWithExternalValuesPriority}
        {...remainderProps}
      />
    </FormikContext>
  );
}
