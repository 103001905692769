import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";
import { TypeActiviteQuota2122 } from "../../versionedElements/declarationHooks2122";

export type TechniqueQuotasValues = Omit<
  TypeActiviteQuota2122,
  "installations"
>;

export const techniqueQuotasFieldMatcher = fieldMatcherBuilder<
  TechniqueQuotasValues
>().build({
  quotas: "estSousQuotaCo2",
  excluArticle27: "excluArticle27",
  excluArticle27Bis: "excluArticle27Bis",
});
