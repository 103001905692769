import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocCarriereProps {
  disabled: boolean;
}

export const BlocCarriere = ({
  disabled,
}: BlocCarriereProps): React.ReactElement => {
  const { referenceCarriereController } = useAuthenticatedApi();
  const classes = useStyles();

  // Substances Carrière
  const uploadSubstanceCarriereHandler = useCallback(
    (file: File) => {
      return referenceCarriereController.uploadSubstanceCarriereUsingPOST(file);
    },
    [referenceCarriereController]
  );

  const downloadSubstanceCarriereHandler = useCallback(() => {
    return referenceCarriereController.downloadSubstanceCarriereAsCsvUsingGET();
  }, [referenceCarriereController]);

  // Organismes Accrédités
  const uploadOrganismeAccrediteHandler = useCallback(
    (file: File) => {
      return referenceCarriereController.uploadOrganismeAccrediteUsingPOST(
        file
      );
    },
    [referenceCarriereController]
  );

  const downloadOrganismeAccrediteHandler = useCallback(() => {
    return referenceCarriereController.downloadOrganismeAccrediteAsCsvUsingGET();
  }, [referenceCarriereController]);

  // Organismes Extérieurs de Prévention
  const uploadOrganismeExterieurPreventionHandler = useCallback(
    (file: File) => {
      return referenceCarriereController.uploadOrganismeExterieurPreventionUsingPOST(
        file
      );
    },
    [referenceCarriereController]
  );

  const downloadOrganismeExterieurPreventionHandler = useCallback(() => {
    return referenceCarriereController.downloadOrganismeExterieurPreventionAsCsvUsingGET();
  }, [referenceCarriereController]);

  return (
    <DummyUnActionedBloc
      title="Carrière"
      renderContent={() => {
        return (
          <>
            {disabled && (
              <>
                <div className={classes.disabledText}>
                  Aucune campagne future à l'état prête n'a été trouvée. Vous ne
                  pouvez pas modifier les référentiels d'une campagne en cours.
                </div>
                <Row />
              </>
            )}
            <BlocReferentiel
              title="Substances Carrière"
              downloadHandler={downloadSubstanceCarriereHandler}
              filename="substance_carriere.csv"
              uploadHandler={uploadSubstanceCarriereHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Organismes Accrédités"
              downloadHandler={downloadOrganismeAccrediteHandler}
              filename="organisme_accredite.csv"
              uploadHandler={uploadOrganismeAccrediteHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Organismes Extérieurs de Prévention"
              downloadHandler={downloadOrganismeExterieurPreventionHandler}
              filename="organisme_exterieur_prevention.csv"
              uploadHandler={uploadOrganismeExterieurPreventionHandler}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default BlocCarriere;
