import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { PdsMethodeCalculPfcDto } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeSurveillanceEmissionLine } from "./tableLineComputer";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface SurveillanceEmissionPfcListProps {
  surveillanceEmissionPfcList: PdsMethodeCalculPfcDto[];
}

const SurveillanceEmissionPfcList = ({
  surveillanceEmissionPfcList,
}: SurveillanceEmissionPfcListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de calcul par émissions de
        PFC
      </SubSectionTitle>

      {surveillanceEmissionPfcList.map(
        ({
          classementFlux: { referenceFlux, fluxFullName },
          donneeActivite,
          frequence,
          duree,
          sefCf4,
          fC2F6,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${fluxFullName || ""} - ${referenceFlux ||
              ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p></p>,
                <p>Niveau appliqué</p>,
                <p>Niveau requis</p>,
                <p>Incertitude</p>,
              ]}
              lines={[
                computeSurveillanceEmissionLine(
                  donneeActivite,
                  "Données d'activité",
                  classes
                ),
                computeSurveillanceEmissionLine(
                  frequence,
                  "Fréquence",
                  classes
                ),
                computeSurveillanceEmissionLine(duree, "Durée", classes),
                computeSurveillanceEmissionLine(sefCf4, "SEF(CF4)", classes),
                computeSurveillanceEmissionLine(fC2F6, "F(C2F6)", classes),
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </div>
  );
};

export default SurveillanceEmissionPfcList;
