import { makeStyles } from "@material-ui/styles";
import React from "react";
import Row from "common/presentational/Row";
import { FIELD_FONT_SIZE, FIELD_FONT_WEIGHT, SECTION_TITLE_GREY } from "theme";
import { computeTotalEmission, updateHandler } from "./utils";
import { validationSchema } from "./validation";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { QuotasBlocEmissionsBilanTotalDto1819 } from "api/gen/api";
import { PATH_QUOTAS_BILAN_BLOC } from "common/path/path1819";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../versionedElements/FormikBlocFullContext1919";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "5px",
  },
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "center",
    alignSelf: "center",
    width: "25%",
  },
  fieldContainer: {
    width: "25%",
    justifyContent: "center",
    padding: "0 10px",
  },
  field: {
    width: "100%",
    margin: "auto",
  },
  noLabel: {
    "&>div:nth-child(1)": {
      marginRight: "0", // Remove margin used for label
    },
  },
});

const BlocBilanTotal = ({
  hasChanges,
  setHasChanges,
  formikRef,
}: FormikBlocRefAndState<QuotasBlocEmissionsBilanTotalDto1819>) => {
  const declaration = useDeclaration1919();
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.noLabel,
    labelWidth: "0",
    formPrefix: "bloc-quotas-bilan-total",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  return (
    <FormikBlocFullContext1919
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={declaration.body.sections.quotas.blocBilanTotal}
      validationSchema={validationSchema}
      title="bilan total"
      pathToValidate={PATH_QUOTAS_BILAN_BLOC}
      updateHandler={updateHandler}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      renderContent={(props, shouldDisabledFields) => (
        <>
          <Row />
          <div className={classes.row}>
            <label htmlFor="emissionsCO2" className={classes.label}>
              Émissions de CO
              <sub>2</sub>
            </label>
            <label htmlFor="emissionsN2O" className={classes.label}>
              Émissions de N<sub>2</sub>O
            </label>
            <label htmlFor="emissionsPFC" className={classes.label}>
              Émissions de PFC
            </label>
            <p className={classes.label}>
              Émissions totales en CO
              <sub>2</sub> équivalent
            </p>
          </div>
          <div className={classes.row}>
            <div className={classes.fieldContainer}>
              <NumberField
                name="emissionsCO2"
                label=""
                unit={computeUnitTCO2PerYear}
                additionalClassName={classes.field}
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classes.fieldContainer}>
              <NumberField
                name="emissionsN2O"
                label=""
                unit={computeUnitTCO2PerYear}
                additionalClassName={classes.field}
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classes.fieldContainer}>
              <NumberField
                name="emissionsPFC"
                label=""
                unit={computeUnitTCO2PerYear}
                additionalClassName={classes.field}
                disabled={shouldDisabledFields}
              />
            </div>
            <div className={classes.fieldContainer}>
              <DummyNumberField
                name="totalEmissions"
                label=""
                unit={computeUnitTCO2PerYear}
                additionalClassName={classes.field}
                disabled
                value={computeTotalEmission(props.values)}
              />
            </div>
          </div>
          <Row />
        </>
      )}
    />
  );
};

export default BlocBilanTotal;
