import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { GREY } from "theme";
import SingleTab from "./SingleTab";

const useStyles = makeStyles({
  boxTabs: {
    position: "relative",
    padding: "0 20%",
    height: "40px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    border: `1px solid ${GREY}`,
    borderBottom: "none",
  },
});

export interface SingleTabElemProps {
  title: string;
  renderComponent: () => React.ReactElement;
  img?: string;
  displayImg?: boolean;
  style?: string;
}

interface TabsProps {
  content: SingleTabElemProps[];
}

const Tabs = ({ content }: TabsProps) => {
  const classes = useStyles();

  const [activeTab, setActiveTab] = useState(content[0].title);

  const component = content.find(child => {
    return child.title === activeTab;
  });

  return (
    <div>
      <div className={classes.boxTabs}>
        {content.map((child, i) => {
          return (
            <SingleTab
              isSelected={activeTab === child.title}
              title={child.title}
              onClick={setActiveTab}
              key={i}
              img={child.img}
              displayImg={child.displayImg}
              style={child.style}
            />
          );
        })}
      </div>
      <div>{component && component.renderComponent()}</div>
    </div>
  );
};

export default Tabs;
