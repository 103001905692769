import React, { ReactElement } from "react";
import { useUserData } from "Authenticator/UserData";
import { ROUTER_PATH_TYPE_ACTIVITE } from "common/path/path21Now";
import { useTypeActivitePercentageProgress } from "../../../../toNow/Dashboard/Components/utils/progressionUtils";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import BigCard from "./BigCard";

import idCardImg from "icons/dashboardLogos/id-card.svg";

const TypeActiviteCard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const { isAnyPrestataire } = useUserData();
  const percentageProgress = useTypeActivitePercentageProgress();

  return (
    <BigCard
      picture={{ src: idCardImg, alt: "" }}
      text="Types d'activités"
      isValidated={percentageProgress === 100}
      percentageProgress={!isAnyPrestataire ? percentageProgress : undefined}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${ROUTER_PATH_TYPE_ACTIVITE}`}
    />
  );
};

export default TypeActiviteCard;
