import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSynthese from "./FormSynthese";
import { ReferenceItemPolluantDto, ReferenceNormeAirDto } from "api/gen";
import { SyntheseInArray } from "./utils/types";

interface SyntheseModaleProps {
  syntheseInModale: SyntheseInArray;
  setSyntheseInModale: Dispatch<SetStateAction<SyntheseInArray | null>>;
  synthesesInPage: SyntheseInArray[];
  setSynthesesInPage: React.Dispatch<
    React.SetStateAction<SyntheseInArray[] | null>
  >;
  referentialNORME: ReferenceNormeAirDto;
  referentialCO2: ReferenceItemPolluantDto;
}

const SyntheseModale = ({
  syntheseInModale,
  setSyntheseInModale,
  synthesesInPage,
  setSynthesesInPage,
  referentialNORME,
  referentialCO2,
}: SyntheseModaleProps): React.ReactElement => {
  const onClose = () => {
    setSyntheseInModale(null);
  };

  return (
    <CustomModal
      isOpen={true}
      contentLabel="Modale avec le formulaire d'ajout de syntheses."
      onRequestClose={onClose}
    >
      <FormSynthese
        referentialNORME={referentialNORME}
        referentielCO2={referentialCO2}
        closeFunction={onClose}
        onSubmit={values => {
          //TODO : compute errors
          const newSynthese: SyntheseInArray = {
            data: { ...values, id: syntheseInModale.data.id },
            errors: {},
          };
          if (synthesesInPage.length === 0) {
            return;
          }
          setSynthesesInPage(
            synthesesInPage.map(singleElderSynthese => {
              if (
                singleElderSynthese.data.id === syntheseInModale.data.id &&
                syntheseInModale.data.biomasse ===
                  singleElderSynthese.data.biomasse
              ) {
                return newSynthese;
              }
              return singleElderSynthese;
            })
          );

          onClose();
        }}
        initialSynthese={syntheseInModale.data}
      />
    </CustomModal>
  );
};

export default SyntheseModale;
