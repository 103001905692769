import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  CampaignDtoStateAllocationsEnum,
  CampaignDtoStateEmissionsEnum,
  CampaignDtoStateGlobalEnum,
  DeclarationStateDtoStateEnum,
} from "api/gen/api";
import _ from "lodash";
import { OptionProps } from "common/form/fields/types/basicTypes";
import { UserData } from "Authenticator/UserData";
import { IdentifiedData } from "common/form/utils";
import { CampaignState } from "../../pages/CompanySpace/WorkflowTargetProviderContext/types";

// From StackOverflow https://stackoverflow.com/questions/18236208
export const removeAccents = (str: string): string => {
  return str
    .replace(/[áàãâä]/gi, "a")
    .replace(/[éè¨êë]/gi, "e")
    .replace(/[íìïî]/gi, "i")
    .replace(/[óòöôõ]/gi, "o")
    .replace(/[úùüû]/gi, "u")
    .replace(/[ç]/gi, "c")
    .replace(/[ñ]/gi, "n")
    .replace(/[^a-zA-Z0-9]/g, " ");
};

export const compareString = (a: string | null, b: string | null): number => {
  if (a && b && a !== b) {
    return a < b ? -1 : 1;
  }
  return 0;
};

/**
 * Define for GlobalFormActionInUserContext / BlocInUserContext / FormikBlocInUserContext
 * if actions are allowed for current user

 * Actions are allowed for "declarant" when declaration is STARTED, IN_REVIEW or VERIFIED state
 * Actions are allowed for "verificateur" when declaration is VERIFYING, STARTED or IN_REVIEW state
 * Actions are allowed for "inspecteur" when declaration is FORCED (except for "verificateur" blocs)
 */

export const areActionsAllowedInUserContext = (
  userData: UserData,
  declarationState: DeclarationStateDtoStateEnum,
  currentCampaignState: CampaignState,
  declarationEtablissement: string,
  isAvailableToDeclarant: boolean,
  isAvailableToAllPrestataire: boolean,
  isAvailableToPrestataireEmissions: boolean,
  isAvailableToPrestataireNiveauxActivite: boolean
): boolean => {
  const isAvailableToAnyPrestataire =
    isAvailableToAllPrestataire ||
    isAvailableToPrestataireEmissions ||
    isAvailableToPrestataireNiveauxActivite;

  const isCampaignStatusAllowPrestataireEdit =
    currentCampaignState === campaignState.STARTED ||
    currentCampaignState === campaignState.ENDED_FOR_DECLARANTS;

  return (
    (isAvailableToDeclarant &&
      isAllowedToDeclarant(userData, currentCampaignState, declarationState)) ||
    (isAvailableToAllPrestataire &&
      isAllowedToAnyPrestataire(
        userData,
        declarationState,
        isCampaignStatusAllowPrestataireEdit
      )) ||
    (isAvailableToPrestataireEmissions &&
      isAllowedToPrestataireEmission(
        userData,
        declarationState,
        isCampaignStatusAllowPrestataireEdit,
        declarationEtablissement
      )) ||
    (isAvailableToPrestataireNiveauxActivite &&
      isAllowedToPrestaireNiveauxActivite(
        userData,
        declarationState,
        isCampaignStatusAllowPrestataireEdit,
        declarationEtablissement
      )) ||
    (!isAvailableToAnyPrestataire &&
      isAllowedToInspecteur(
        userData,
        currentCampaignState,
        declarationState
      )) ||
    (userData.isAdmin &&
      declarationState === DeclarationStateDtoStateEnum.FORCED)
  );
};

const campaignState = {
  ...CampaignDtoStateGlobalEnum,
  ...CampaignDtoStateAllocationsEnum,
  ...CampaignDtoStateEmissionsEnum,
};
const isAllowedToDeclarant = (
  userData: UserData,
  workflowCampaign: CampaignState,
  declarationState: DeclarationStateDtoStateEnum
): boolean => {
  return (
    userData.isDeclarant &&
    (workflowCampaign === campaignState.STARTED ||
      (workflowCampaign === campaignState.ENDED_FOR_DECLARANTS &&
        declarationState === DeclarationStateDtoStateEnum.IN_REVIEW)) &&
    (declarationState === DeclarationStateDtoStateEnum.STARTED ||
      declarationState === DeclarationStateDtoStateEnum.IN_REVIEW ||
      declarationState === DeclarationStateDtoStateEnum.VERIFYING ||
      declarationState === DeclarationStateDtoStateEnum.VERIFIED)
  );
};

const isAllowedToAnyPrestataire = (
  userData: UserData,
  declarationState: DeclarationStateDtoStateEnum,
  isCampaignStatusAllowPrestataireEdit: boolean
): boolean => {
  return (
    userData.isAnyPrestataire &&
    isCampaignStatusAllowPrestataireEdit &&
    (declarationState === DeclarationStateDtoStateEnum.VERIFYING ||
      declarationState === DeclarationStateDtoStateEnum.STARTED ||
      declarationState === DeclarationStateDtoStateEnum.IN_REVIEW)
  );
};

const isAllowedToPrestataireEmission = (
  userData: UserData,
  declarationState: DeclarationStateDtoStateEnum,
  isCampaignStatusAllowPrestataireEdit: boolean,
  declarationEtablissement: string
): boolean => {
  return (
    userData.isPrestataireEmissionsForEtablissement(declarationEtablissement) &&
    isCampaignStatusAllowPrestataireEdit &&
    (declarationState === DeclarationStateDtoStateEnum.VERIFYING ||
      declarationState === DeclarationStateDtoStateEnum.STARTED ||
      declarationState === DeclarationStateDtoStateEnum.IN_REVIEW)
  );
};

const isAllowedToPrestaireNiveauxActivite = (
  userData: UserData,
  declarationState: DeclarationStateDtoStateEnum,
  isCampaignStatusAllowPrestataireEdit: boolean,
  declarationEtablissement: string
): boolean => {
  return (
    userData.isPrestataireNiveauxActiviteForEtablissement(
      declarationEtablissement
    ) &&
    isCampaignStatusAllowPrestataireEdit &&
    (declarationState === DeclarationStateDtoStateEnum.VERIFYING ||
      declarationState === DeclarationStateDtoStateEnum.STARTED ||
      declarationState === DeclarationStateDtoStateEnum.IN_REVIEW)
  );
};

const isAllowedToInspecteur = (
  userData: UserData,
  workflowCampaign: CampaignState,
  declarationState: DeclarationStateDtoStateEnum
): boolean => {
  return (
    userData.isInspecteur &&
    declarationState === DeclarationStateDtoStateEnum.FORCED &&
    (workflowCampaign === campaignState.STARTED ||
      workflowCampaign === campaignState.ENDED_FOR_DECLARANTS)
  );
};

/**
 *  check if error from Formik is a string or a FormikError
 *  throw exception if it is a FormikError as our fields only handle string as error
 */
export function getErrorStringValue<T>(
  error: FormikErrors<T> | string
): string {
  if (!error || typeof error === "string") {
    return error;
  } else {
    throw Error("can not parse string from object");
  }
}

export const isSearchStringInCollection = (
  listStr: (string | undefined | null)[],
  searchStr: string
): boolean => {
  const lowerSearchStr = searchStr.toLowerCase();
  for (const testString of listStr) {
    if (testString && testString.toLowerCase().includes(lowerSearchStr)) {
      return true;
    }
  }

  return false;
};

/**
 * Trick to trigger a browser download from data fetched using swagger API
 * @param data data to download
 * @param fileName file name to download
 */
export const triggerDownload = (data: any, fileName: string): void => {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);

  a.href = window.URL.createObjectURL(data);
  a.setAttribute("download", fileName);
  a.click();

  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

export function searchKeyInOptionProps<T extends OptionProps>(
  key: string,
  props: T[]
): T | null {
  const prop = props.find(ele => {
    return ele.label === key;
  });

  return prop || null;
}

//as per https://stackoverflow.com/a/49936686
type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>;
};

export function findElementMatchingTemplate<T>(
  template: DeepPartial<T>,
  list: T[],
  format?: (toFormat: T) => T
): T | null {
  const formated = format ? list.map(l => format(l)) : list;
  return _.find(formated, _.matches(template)) || null;
}

export function findElementByIdOrNull<T extends IdentifiedData>(
  id: string | null | undefined,
  arrayElements: T[]
): T | null {
  return id ? arrayElements.find(e => e.id === id) || null : null;
}

export const normalizeLabel = (label1: string): string => {
  return label1
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase();
};

/**
 * a function to enhance the passed function so it catches first error launched and logs it in console.
 * useful when said function is to be run in a third party dependency that gobbles errors,
 * such as formik does. The enhanced function is returned, passed function isn't modified.
 *
 * (since this method will probably be used to enhance formik validate methods, the type parameter as well as the signature
 * are specifically those of a typical formik validate function. Should it become useful for others funtions,
 * it would be advised to modify this method to make it more generic, but right now (11/08/2020),
 * the single type signature is enough.
 *
 * @param functionToEnhance the function to enhance
 */
export const enhanceWithCatchAndLogErrors = <Values>(
  functionToEnhance: (values: Values) => FormikErrors<Values>
): ((values: Values) => FormikErrors<Values>) => {
  return (values: Values) => {
    let errors: FormikErrors<Values> = {};
    try {
      errors = functionToEnhance(values);
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.error("an error produced in validate function : ", e);
      }
      throw Error("the validate method passed produced an error");
    }

    return errors;
  };
};

export const sortObjectKeysAndValues = <T>(sourceObject: {
  [key: string]: T;
}): { sortedKeys: string[]; sortedValues: T[] } => {
  const sortedKeys = Object.keys(sourceObject).sort();
  const sortedValues = sortedKeys.map(key => sourceObject[key]);

  return { sortedKeys, sortedValues };
};
