import React, { ReactElement } from "react";
import { ClassementFluxDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { formatClassementFlux } from "../BlocDonneeSurveillance/utils";
import { computeEmissionWrappedCell } from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/MethodesEmissionsData/EmissionsArrays/arrayUtils";

const useStyles = makeStyles({
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface ClassementFluxProps {
  title: string;
  classements: ClassementFluxDto[];
}

const ClassementFlux = ({
  title,
  classements,
}: ClassementFluxProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.arrayContainer}>
      <ArrayTitle>{title}</ArrayTitle>
      <CommonEntityTable
        header={[
          <p>Référence du flux</p>,
          <p>Nom du flux</p>,
          <p>Classement selectionné</p>,
          <p>Estimation des émissions</p>,
        ]}
        lines={classements.map(
          ({ referenceFlux, fluxFullName, classement, emissionEstimation }) => [
            computeWrappedCell(referenceFlux, classes),
            computeWrappedCell(fluxFullName, classes),
            computeWrappedCell(formatClassementFlux(classement), classes),
            computeEmissionWrappedCell(
              emissionEstimation?.toString() ?? null,
              classes
            ),
          ]
        )}
        isFirstColSticky={false}
        isLastColSticky={false}
      />
    </div>
  );
};

export default ClassementFlux;
