import React, { useState } from "react";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import FormikBloc from "common/formikBloc/FormikBloc";
import {
  CREATED,
  generateTransitionWarningMessage,
  STARTED,
} from "common/messages/dashboardMessage";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useTechniqueQuotaEditHandler,
  useTechniqueQuotaSaveHandler,
  useTechniqueQuotaValidateHandler,
} from "./hooks";
import { computeTechQuotaInitialValues } from "./handlers";
import { LEFT_WITHDRAW_STYLE, SECTION_TITLE_GREY } from "theme";
import { useConfirmationModale } from "common/modale/hooks";
import Installations from "./Installations";
import { InstallationInArray } from "./Installations/types";
import { dtoToInstallations } from "./Installations/converter";
import { techniqueQuotasFieldMatcher, TechniqueQuotasValues } from "./utils";
import _ from "lodash";
import { PATH_TYPE_ACTIVITE_QUOTA } from "common/path/path21Now";
import {
  estQuotasCo2Label,
  getArcticle27BisLabel,
  getArticle27Label,
} from "./label";
import { useDeclaration2122 } from "../../versionedElements/declarationHooks2122";
import { useDeclarationErrorAndWarnings2122 } from "../errorAndWarningDisplayer2122/utils";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "20px",
    display: "flex",
  },
  separator: {
    height: "20px",
  },
  containerSpace: {
    padding: "10px 10px 25px 10px",
    fontSize: "17px",
  },
  row: {
    display: "flex",
    justifyContent: "spaceBetween",
    marginBottom: "15px",
  },
  subTitle: {
    color: SECTION_TITLE_GREY,
  },
  messageContainer: {
    height: "auto",
    justifyContent: "center",
  },
  ...LEFT_WITHDRAW_STYLE,
});

/**
 * @param referentielActivites  list of Activités Quotas referential
 */
interface TypeActiviteQuotaProps
  extends FormikBlocRefAndState<TechniqueQuotasValues> {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const TypeActiviteQuota = (
  props: TypeActiviteQuotaProps
): React.ReactElement => {
  const classes = useStyles();
  const declarationDto = useDeclaration2122();
  const openConfirmationModale = useConfirmationModale();
  const saveHandler = useTechniqueQuotaSaveHandler();
  const validateHandler = useTechniqueQuotaValidateHandler();
  const editHandler = useTechniqueQuotaEditHandler();
  const declaration = useDeclaration2122();

  const initialInstallations = dtoToInstallations(
    declaration.body.typeActivite.quotas.installations,
    props.referentialActivites
  );

  const initialFormikValues = computeTechQuotaInitialValues(
    declarationDto.body.typeActivite.quotas
  );

  const [installationsInArray, setInstallationsInArray] = useState<
    InstallationInArray[]
  >(initialInstallations);

  const possibleActivitesValues =
    props.referentialActivites.referenceItemActiviteQuotaDtoList;

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "company-technique-quota",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const declarationState =
    declarationDto.body.workflowStatus.quotaEmissions.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const areActionsAllowed =
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
    userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
    userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN;

  const isTechniqueValidated = declarationState !== CREATED;
  const shouldDisableFields = !areActionsAllowed || isTechniqueValidated;

  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<void>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<void>();

  const {
    warnings,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings2122(
    PATH_TYPE_ACTIVITE_QUOTA,
    false,
    declarationDto.body.workflowStatus.quotaEmissions.state !==
      DeclarationStateDtoStateEnum.CREATED
  );

  return (
    <FormikBloc
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      actionsAllowed={areActionsAllowed}
      isBlocValidated={isTechniqueValidated}
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      setHasChanges={props.setHasChanges}
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      hasFormChanges={(values: TechniqueQuotasValues) =>
        !_.isEqual(initialFormikValues, values) ||
        !_.isEqual(initialInstallations, installationsInArray)
      }
      saveAction={async (values: TechniqueQuotasValues) => {
        await triggerSaveSpinner(saveHandler(values, installationsInArray));
      }}
      editAction={async () => {
        openConfirmationModale(
          "Attention, cette action invalidera toutes les sections de la déclaration, êtes vous sûrs de vouloir l'effectuer ?",
          async () => {
            await triggerEditSpinner(editHandler());
          }
        );
      }}
      validateAction={async (values: TechniqueQuotasValues) => {
        openConfirmationModale(
          generateTransitionWarningMessage(STARTED),
          async () => {
            await triggerErrorAndWarningDisplay(
              triggerValidateSpinner(
                validateHandler(values, installationsInArray)
              )
            );
          }
        );
      }}
      cancelAction={formikProps => {
        setInstallationsInArray(initialInstallations);
        formikProps.resetForm();
      }}
      initialValues={initialFormikValues}
      title={"TYPE D'ACTIVITÉ QUOTAS"}
      additionalValidationAllowed={() => !hasError || props.hasChanges}
      renderContent={formikProps => {
        return (
          <>
            <div className={classes.containerSpace}>
              Si vous constatez des erreurs manifestes concernant le type
              d'activité Quotas de l'établissement, vous devez prendre contact
              avec le service d'inspection dont dépend l'établissement afin que
              les corrections nécessaires soient apportées.
            </div>
            <BooleanField
              label={estQuotasCo2Label}
              name={techniqueQuotasFieldMatcher.quotas}
              disabled={shouldDisableFields}
              tooltipContent={
                "Établissement comportant au moins une installation visée par la directive européenne n°2003/87/CE modifiée (quotas d'émission de gaz à effet de serre)"
              }
            />

            {formikProps.values.estSousQuotaCo2 && (
              <>
                <div className={classes.withdrawLeft}>
                  <BooleanField
                    label={getArticle27Label}
                    name={techniqueQuotasFieldMatcher.excluArticle27}
                    disabled={shouldDisableFields}
                  />
                  <BooleanField
                    label={getArcticle27BisLabel}
                    name={techniqueQuotasFieldMatcher.excluArticle27Bis}
                    disabled={shouldDisableFields}
                  />
                </div>

                <Row additionalClassname={classes.separator} />

                <Installations
                  values={installationsInArray}
                  setValues={setInstallationsInArray}
                  possibleActivitesValues={possibleActivitesValues}
                  shouldDisableFields={shouldDisableFields}
                />
              </>
            )}

            <ViolationProcessor errors={errors} warnings={warnings} />
          </>
        );
      }}
    />
  );
};

export default TypeActiviteQuota;
