import React, { useState } from "react";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { SECTION_TITLE_GREY, TEXT_LIGHT_GREY } from "theme";
import DownloadButton from "./DownloadButton";
import { useUserData } from "Authenticator/UserData";
import UploadButton from "./UploadButton";
import { UploadReferentialViolationsDto } from "api/gen";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-center",
    alignItems: "center",
    margin: "5px 0",
    height: "auto",
  },
  buttonArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "10px",
    flex: "1",
  },
  button: {
    margin: "0 5px",
    padding: "0.5em",
    height: "min-content",
  },
  importButton: {
    margin: "0 5px",
    "&>button": {
      padding: "0.5em",
      height: "min-content",
    },
  },
  label: {
    color: TEXT_LIGHT_GREY,
    flex: "1",
    textAlign: "right",
  },
  filename: {
    color: SECTION_TITLE_GREY,
    margin: "0 5px",
  },
});

export interface BlocReferentielProps {
  title: string;
  additionalContent?: React.ReactElement;
  downloadHandler: () => Promise<Response>;
  filename: string;
  uploadHandler: (file: File) => Promise<UploadReferentialViolationsDto>;
  onUploadSuccessful?: () => Promise<void>;
  disabled?: boolean;
}

export function BlocReferentiel({
  title,
  additionalContent,
  downloadHandler,
  filename,
  uploadHandler,
  onUploadSuccessful,
  disabled,
}: BlocReferentielProps): React.ReactElement {
  const classes = useStyles();
  const userData = useUserData();

  const [errors, setErrors] = useState<UploadReferentialViolationsDto | null>(
    null
  );

  const buildErrorMessage = (
    line: string,
    causes: string[]
  ): React.ReactElement => {
    const message = `Erreur(s) à la ligne ${line} : ${causes.join(" ; ")}`;
    return <ErrorDisplayer message={message} key={line} />;
  };

  return (
    <DummyUnActionedBloc
      title={title}
      renderContent={() => {
        return (
          <>
            {additionalContent}
            <Row additionalClassname={classes.container}>
              <label className={classes.label}>
                Cliquez ici pour télécharger les valeurs actuelles du
                référentiel "{title}" sous format CSV.
              </label>
              <div className={classes.buttonArea}>
                <DownloadButton
                  downloadHandler={downloadHandler}
                  filename={filename}
                  additionalClassname={classes.button}
                  // download of referential is possible no matter the state of campaign, or connected user status
                  disabled={false}
                />
              </div>
            </Row>
            {userData.isSuperAdmin && (
              <Row additionalClassname={classes.container}>
                <label className={classes.label}>
                  Cliquez ici pour téléverser un fichier CSV destiné à remplacer
                  les valeurs actuelles du référentiel "{title}". Ce fichier
                  devra être édité à partir du fichier téléchargé ci-dessus.
                </label>
                <div className={classes.buttonArea}>
                  <UploadButton
                    uploadHandler={uploadHandler}
                    setErrors={setErrors}
                    onUploadSuccessful={onUploadSuccessful}
                    additionalClassname={classes.importButton}
                    disabled={disabled}
                  />
                </div>
              </Row>
            )}
            {errors &&
              Object.entries(errors.violations).map(([line, causes]) =>
                buildErrorMessage(line, causes)
              )}
          </>
        );
      }}
    />
  );
}
