import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import Row from "../../../../common/presentational/Row";
import CustomModal from "../../../../common/presentational/CustomModal";
import { FIELD_FONT_SIZE, SECTION_TITLE_GREY } from "../../../../theme";
import DummyChoiceSelectWithoutOptionProps from "../../../../common/form/fields/dumbInput/DummyChoiceSelectWithoutOptionProps";
import SimpleInputWithTextLabelDisplayer from "../../../../common/form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { useAuthenticatedApi } from "../../../../Authenticator/AuthenticatedApi";
import { backAlertMessage } from "../../../../common/backErrors/utils";
import { backMessageFetchDeclaration } from "../../../../common/backErrors/errorMessages";
import { useAlertModale } from "../../../../common/modale/hooks";

const useStyles = makeStyles({
  global: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  inputField: {
    display: "flex",
  },
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: FIELD_FONT_SIZE,
    color: SECTION_TITLE_GREY,
    fontWeight: 400,
    marginBottom: "5%",
  },
  width: {
    width: "35%",
  },
});

export interface PurgeModalProps {
  close: () => void;
  isOpen: boolean;
  yearsAvailableToPurge: number[];
}

const PurgeModal = ({
  close,
  isOpen,
  yearsAvailableToPurge,
}: PurgeModalProps): React.ReactElement => {
  const [yearToDelete, setYearToDelete] = useState<number | null>(null);
  const [numberDeclaration, setNumberDeclaration] = useState<number | null>(
    null
  );
  const classes = useStyles();
  const { purgeController } = useAuthenticatedApi();
  const openAlertModale = useAlertModale();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "160px",
    formPrefix: "purge",
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setYearToDelete(null);
  }, [isOpen]);

  useEffect(() => {
    const updateNumberDeclaration = async () => {
      if (yearToDelete === null) {
        setNumberDeclaration(null);
        return;
      }
      try {
        const result = await purgeController.countNbDeclarationsToPurgeUsingGET(
          yearToDelete
        );
        setNumberDeclaration(result);
      } catch (excp) {
        await openAlertModale(
          backAlertMessage(excp, backMessageFetchDeclaration)
        );
        return;
      }
    };
    updateNumberDeclaration();
  }, [openAlertModale, purgeController, yearToDelete]);

  const onValidate = async () => {
    if (yearToDelete === null) {
      return;
    }
    try {
      await purgeController.markDeclarationAsDeletedUsingPUT(yearToDelete);
      close();
    } catch (excp) {
      await openAlertModale(
        backAlertMessage(excp, backMessageFetchDeclaration)
      );
      return;
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onRequestClose={close}
      className={classes.width}
    >
      <div className={classes.global}>
        <h3 className={classes.title}>Purge des Déclarations</h3>
        <Row />

        <SimpleInputWithTextLabelDisplayer
          label={"Année à supprimer :"}
          name={"year-to-delete"}
          renderInput={id => (
            <DummyChoiceSelectWithoutOptionProps<number, false>
              disabled={false}
              name={"year-to-delete"}
              id={id}
              isMulti={false}
              options={yearsAvailableToPurge}
              onChange={year => {
                if (year !== null) {
                  setYearToDelete(year);
                }
              }}
              computeLabel={year => year.toString()}
              enableReset={false}
              error={null}
              value={yearToDelete}
            />
          )}
          commonProps={commonProps}
          errorData={{}}
        />
        {numberDeclaration !== null && (
          <>
            <Row />
            <Row>
              <span>
                Voulez-vous supprimer les {numberDeclaration} déclarations pour
                les années {yearToDelete} et précédentes?
              </span>
            </Row>
          </>
        )}
        <DummyGlobalFormActions
          validateButton={{
            title: "Valider",
            isVisible: true,
            isEnabled: yearToDelete !== null && numberDeclaration !== null,
            onClick: () => {
              onValidate();
            },
            isSpinnerVisible: false,
          }}
          cancelButton={{
            title: "Annuler",
            isVisible: true,
            isEnabled: true,
            onClick: close,
          }}
        />
      </div>
    </CustomModal>
  );
};

export default PurgeModal;
