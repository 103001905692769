import { BlocRejetEauFormValues, BlocRejetEauOptions } from "./types";
import {
  EauBlocRejetEauDto1822,
  ReferenceItemStationEpurationDto,
  ReferenceSandreDto,
  ReferenceStationEpurationDto,
} from "api/gen/api";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  isOtherStationSelected,
  otherStationOption,
  selectPossibleValues,
} from "./selectPossibleValues";

const getStationEpurationNameById = (
  stationEpurations: ReferenceItemStationEpurationDto[],
  stationEpurationId: string
) => {
  const stationEpuration = findElementMatchingTemplate(
    { uuid: stationEpurationId },
    stationEpurations
  );
  return stationEpuration ? stationEpuration.nomOuvrageDepollution : "";
};

const getStationEpurationIdByName = (
  stationEpurations: ReferenceItemStationEpurationDto[],
  stationEpurationName: string
) => {
  const stationEpuration = findElementMatchingTemplate(
    { nomOuvrageDepollution: stationEpurationName },
    stationEpurations
  );
  return stationEpuration ? stationEpuration.uuid : "";
};

export const dtoToBlocValues = (
  dto: EauBlocRejetEauDto1822,
  options: BlocRejetEauOptions,
  referentialSandres: ReferenceSandreDto,
  referentialStationsEpuration: ReferenceStationEpurationDto
): BlocRejetEauFormValues => {
  const nomStationEpurationName = getStationEpurationNameById(
    referentialStationsEpuration.referenceItemStationEpurationDtoList,
    dto.rejetRaccorde.nomStationEpurationID || ""
  );
  return {
    milieuRaccorde:
      selectPossibleValues(
        referentialSandres,
        referentialStationsEpuration
      ).locations.find(
        item => item.uuid === dto.rejetRaccorde.milieuRecepteurFinalID
      ) || null,
    stationRaccorde: dto.rejetRaccorde.stationEpurationAutre
      ? otherStationOption
      : options.stations.find(
          item => item.nomOuvrageDepollution === nomStationEpurationName
        ) || null,
    volumeRaccorde: dto.rejetRaccorde.volumeAnnuelRejete,
    chaleurRaccorde: dto.rejetRaccorde.chaleurRejetee,
    milieuIsole:
      selectPossibleValues(
        referentialSandres,
        referentialStationsEpuration
      ).locations.find(
        item => item.uuid === dto.rejetIsole.milieuRecepteurFinalID
      ) || null,
    volumeIsole: dto.rejetIsole.volumeAnnuelRejete,
    chaleurIsole: dto.rejetIsole.chaleurRejetee,
  };
};

export const blocValuesToDto = (
  values: BlocRejetEauFormValues,
  referentialStationsEpuration: ReferenceStationEpurationDto
): EauBlocRejetEauDto1822 => {
  return {
    rejetRaccorde: {
      nomStationEpurationID: values.stationRaccorde
        ? getStationEpurationIdByName(
            referentialStationsEpuration.referenceItemStationEpurationDtoList,
            values.stationRaccorde.nomOuvrageDepollution
          )
        : null,
      stationEpurationAutre: values.stationRaccorde
        ? isOtherStationSelected(values.stationRaccorde)
        : false,
      milieuRecepteurFinalID: values.milieuRaccorde
        ? values.milieuRaccorde.uuid
        : null,
      volumeAnnuelRejete: values.volumeRaccorde,
      chaleurRejetee: values.chaleurRaccorde,
    },
    rejetIsole: {
      milieuRecepteurFinalID: values.milieuIsole
        ? values.milieuIsole.uuid
        : null,
      volumeAnnuelRejete: values.volumeIsole,
      chaleurRejetee: values.chaleurIsole,
    },
  };
};
