import {
  codePostalMessage,
  requiredMessage,
  sirenLengthMessage,
  wrongCommuneMessage,
} from "common/declarant/formik/formikMessages";
import {
  computeRequiredStringErrorMessage,
  filterOutNullishValues,
} from "common/declarant/formik/formikValidationHelper";
import { Errors } from "common/form/utils";
import { AdministratifType } from "../utils/types";

export const isCountryFrance = (country: string): boolean => {
  return country.toLowerCase() === "france";
};

export const companyIdentityValidation = (
  values: AdministratifType
): Errors<AdministratifType> => {
  const errors: Errors<AdministratifType> = {};

  errors.raisonSociale = computeRequiredStringErrorMessage(
    values.raisonSociale
  );
  errors.formeJuridique = computeRequiredStringErrorMessage(
    values.formeJuridique
  );
  errors.siren = validateSiren(values.siren);
  errors.adresse = computeRequiredStringErrorMessage(values.adresse);
  errors.codePostal = computeRequiredStringErrorMessage(values.codePostal);

  if (values.pays === null) {
    errors.pays = requiredMessage;
  }

  if (values.commune === null) {
    errors.commune = requiredMessage;
  }
  if (values.pays && isCountryFrance(values.pays.designation)) {
    if (values.codePostal && !/^[0-9]{5}$/.test(values.codePostal)) {
      errors.codePostal = codePostalMessage;
    }
    // the string type means the city isn't in france, cities in france are supposed to have an uuid.
    if (typeof values.commune === "string") {
      errors.commune = wrongCommuneMessage;
    }
  }

  return filterOutNullishValues(errors) as Errors<AdministratifType>;
};

const validateSiren = (siren: string | null): string | undefined => {
  if (siren !== null && !/^[0-9]{9}$/.test(siren.trim())) {
    return sirenLengthMessage;
  }
};
