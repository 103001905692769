import * as Yup from "yup";
import {
  commonNumberPercentFields,
  commonObjectFields,
  commonPercentageFields,
  commonPositiveNumberFields,
  MAX_NB_HOURS_YEAR,
  subArrayOfObjectFieldValidationScheme,
  subBooleanFieldValidationScheme,
  subFieldValidationMultipleANDScheme,
  subFieldValidationScheme,
  subNumberFieldValidationMultipleScheme,
  subNumberFieldValidationScheme,
  subObjectFieldValidationScheme,
  subStringFieldValidationMultipleScheme,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";

import _, { isEqual } from "lodash";
import { sortByKey } from "../utils/utils";
import { OptionProps } from "common/form/fields/types/basicTypes";
import {
  combustionInstallationTooMuchTimeMessage,
  procedesMasseVolumiqueMessage,
  procedesUniqueSubstanceMessage,
} from "common/declarant/formik/formikMessages";
import { getUnitLabel } from "../utils/gettersFunctions";
import { ProcedeInArray } from "../../listprocede/utils/types";
import {
  CorrelationEmissionInArray,
  EmissionsInArray,
  EmissionsInModale,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../utils/types";
import { ReferenceItemPolluantDto } from "api/gen";

export const anySubPartActivated = (type: OptionProps | null) => {
  return type && (type.value === 1 || type.value === 2 || type.value === 3);
};

export const mesureOrMatiereSubPartActivated = (type: OptionProps | null) => {
  return type && (type.value === 1 || type.value === 3);
};

export const mesureSubPartActivated = (type: OptionProps | null) => {
  return type && type.value === 1;
};

export const correlationSubPartActivated = (type: OptionProps | null) => {
  return type && type.value === 2;
};

export const matiereSubPartActivated = (emissionType: OptionProps | null) => {
  return emissionType && emissionType.value === 3;
};

export const mesureContinueDebitSubPartActivated = (
  debitType: boolean | null
) => {
  return !debitType;
};

export const mesureContinueConcentrationSubPartActivated = (
  concentrationType: boolean | null
) => {
  return !concentrationType;
};

export const epurationSubPartActivated = (
  concentrationType: boolean | null
) => {
  return concentrationType;
};

export const isCo2 = (
  type: OptionProps | null | undefined,
  referentialCO2: ReferenceItemPolluantDto
) => {
  return type && type.label === referentialCO2.nom;
};

export const singleEmissionOverwriteDependantFields = (
  emission: EmissionsInModale,
  referentialCO2: ReferenceItemPolluantDto
) => {
  if (!mesureSubPartActivated(emission.methods || null)) {
    emission.debitHoraire = null;
    emission.mesureContinueDebit = null;
    emission.frequenceMesureDebit = null;
    emission.heureFonctionnement = null;
    emission.concentrationMoyenne = null;
    emission.mesureContinueConcentration = null;
    emission.frequenceMesureConcentration = null;
  }
  if (!correlationSubPartActivated(emission.methods || null)) {
    emission.quantiteMatiereProcede = null;
    emission.uniteProcede = null;
    emission.natureCorrelation = null;
    emission.facteurCorrelation = null;
    emission.uniteCorrelation = null;
    emission.provenanceFacteurCorrelation = null;
    emission.masseVolumiqueProcede = null;
  }
  if (!matiereSubPartActivated(emission.methods || null)) {
    emission.descIntrant = null;
    emission.quantityIn = null;
    emission.quantityOut = null;
    emission.elementIndexe = null;
    emission.teneurMoyenne = null;
    emission.partElement = null;
  }
  if (!isCo2(emission.substance, referentialCO2)) {
    emission.biomasse = null;
  }
};

export const singleEmissionValidationSchema = (
  referentialCO2: ReferenceItemPolluantDto
) =>
  Yup.object().shape({
    methods: commonObjectFields,
    substance: subObjectFieldValidationScheme("methods", anySubPartActivated),
    procede: subArrayOfObjectFieldValidationScheme(
      "methods",
      anySubPartActivated
    ),
    debitHoraire: subNumberFieldValidationScheme(
      "methods",
      mesureSubPartActivated
    ),
    mesureContinueDebit: subBooleanFieldValidationScheme(
      "methods",
      mesureSubPartActivated
    ),
    frequenceMesureDebit: subNumberFieldValidationMultipleScheme(
      ["methods", "mesureContinueDebit"],
      mesureSubPartActivated,
      mesureContinueDebitSubPartActivated
    ),
    heureFonctionnement: subFieldValidationScheme(
      "methods",
      mesureSubPartActivated,
      commonPositiveNumberFields.max(
        MAX_NB_HOURS_YEAR,
        combustionInstallationTooMuchTimeMessage
      ),
      Yup.number()
    ),
    concentrationMoyenne: subNumberFieldValidationScheme(
      "methods",
      mesureSubPartActivated
    ),
    mesureContinueConcentration: subBooleanFieldValidationScheme(
      "methods",
      mesureSubPartActivated
    ),
    frequenceMesureConcentration: subNumberFieldValidationMultipleScheme(
      ["methods", "mesureContinueConcentration"],
      mesureSubPartActivated,
      mesureContinueConcentrationSubPartActivated
    ),
    epuration: subBooleanFieldValidationScheme("methods", anySubPartActivated),
    natureEquipement: subStringFieldValidationMultipleScheme(
      ["methods", "epuration"],
      anySubPartActivated,
      epurationSubPartActivated
    ),
    rendementEpuration: subFieldValidationMultipleANDScheme(
      ["methods", "epuration"],
      anySubPartActivated,
      epurationSubPartActivated,
      commonNumberPercentFields,
      Yup.number()
    ),
    quantiteMatiereProcede: Yup.number().nullable(),
    unit: Yup.string().nullable(),
    natureCorrelation: Yup.string().nullable(),
    facteurCorrelation: subNumberFieldValidationScheme(
      "methods",
      correlationSubPartActivated
    ),
    uniteCorrelation: subObjectFieldValidationScheme(
      "methods",
      correlationSubPartActivated
    ),
    provenanceFacteurCorrelation: subStringFieldValidationScheme(
      "methods",
      correlationSubPartActivated
    ),
    emissionAnnuelle: Yup.number().nullable(),
    descIntrant: subStringFieldValidationScheme(
      "methods",
      matiereSubPartActivated
    ),
    quantityIn: subNumberFieldValidationScheme(
      "methods",
      matiereSubPartActivated
    ),
    quantityOut: subNumberFieldValidationScheme(
      "methods",
      matiereSubPartActivated
    ),
    elementIndexe: subStringFieldValidationScheme(
      "methods",
      matiereSubPartActivated
    ),
    teneurMoyenne: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      commonNumberPercentFields,
      Yup.number()
    ),
    partElement: subFieldValidationScheme(
      "methods",
      matiereSubPartActivated,
      commonNumberPercentFields,
      Yup.number()
    ),
    masseVolumiqueProcede: Yup.number().nullable(),
    biomasse: subFieldValidationMultipleANDScheme(
      ["methods", "substance"],
      anySubPartActivated,
      (type: OptionProps | null) => isCo2(type, referentialCO2),
      commonPercentageFields,
      Yup.number()
    ),
  });

export const additionalValidations = (
  values: EmissionsInModale,
  allowedCouples: EmissionsInArray | null,
  mesureEmissionsInPage: MesureEmissionInArray[],
  correlationEmissionsInPage: CorrelationEmissionInArray[],
  matiereEmissionsInPage: MatiereEmissionInArray[],
  initialProcedes: ProcedeInArray[]
) => {
  let error = {};
  let found;
  if (values.methods) {
    if (
      !allowedCouples ||
      (allowedCouples &&
        (!isEqual(allowedCouples.data.procede, values.procede) ||
          !isEqual(allowedCouples.data.substance, values.substance) ||
          !isEqual(allowedCouples.data.methods, values.methods)))
    ) {
      if (values.methods.value === 1) {
        found = mesureEmissionsInPage.filter(emission => {
          if (
            emission.data.procede &&
            emission.data.substance &&
            values.substance
          ) {
            return (
              isEqual(
                sortByKey(emission.data.procede, "value"),
                sortByKey(values.procede, "value")
              ) && emission.data.substance.label === values.substance.label
            );
          }
          return false;
        })[0];
      } else if (values.methods.value === 2) {
        found = correlationEmissionsInPage.filter(emission => {
          if (
            emission.data.procede &&
            values.procede &&
            emission.data.substance &&
            values.substance
          ) {
            return (
              emission.data.procede[0].label === values.procede[0].label &&
              emission.data.substance.label === values.substance.label
            );
          }
          return false;
        })[0];
      }
    }
    if (found) {
      error = {
        substance: procedesUniqueSubstanceMessage,
      };
    }
    if (values.methods.value === 2) {
      const uniteProcede = getUnitLabel(values.procede || [], initialProcedes);
      if (
        values.procede &&
        (uniteProcede === "kg (kilogramme)" || uniteProcede === "t (tonne)") &&
        values.uniteCorrelation &&
        _.isNil(values.procede[0].object.data.density) &&
        values.uniteCorrelation.value >= 3 &&
        values.uniteCorrelation.value <= 6
      ) {
        error = {
          ...error,
          uniteCorrelation: procedesMasseVolumiqueMessage,
        };
      }
      if (
        values.procede &&
        values.uniteCorrelation &&
        uniteProcede !== "kg (kilogramme)" &&
        uniteProcede !== "t (tonne)" &&
        _.isNil(values.procede[0].object.data.density) &&
        values.uniteCorrelation.value < 3
      ) {
        error = {
          ...error,
          uniteCorrelation: procedesMasseVolumiqueMessage,
        };
      }
    }
  }
  return error;
};
