import React, { ReactElement } from "react";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";
import { WorkflowTarget } from "common/utils/types";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import GlobalDashboardCards from "../Cards/GlobalDashboardCards";
import { wrapWithWorkflowTargetProvider } from "../../../../../WorkflowTargetProviderContext";
import { PATH_ROOT } from "common/path/path18Now";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  useDeclaration21Now,
  useDeclarationGlobalState21Now,
  useDeclarationHelpers21Now,
} from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import SubDashboardContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/SubDashboardContainer";
import DashboardProgressBar from "pages/CompanySpace/from21/toNow/Dashboard/Components/DashboardProgressBar";
import DeclarationStatus from "pages/CompanySpace/from21/toNow/Dashboard/Components/DeclarationStatus";
import GlobalActionButtons from "../../../Actions/Global/GlobalActionButtons";
import AccuseTransmissionDownloadButton from "pages/CompanySpace/from21/toNow/Actions/AccuseTransmissionDownloadButton";
import GlobalNextActionInfo from "pages/CompanySpace/from21/toNow/Dashboard/Components/NextAction/GlobalNextActionInfo";
import DeclarationWarningsAndError from "pages/CompanySpace/from21/toNow/Dashboard/Components/DeclarationWarningsAndError";
import { DeclarationNothingToDeclare } from "pages/CompanySpace/from21/toNow/Dashboard/Components/DeclarationNothingToDeclare";
import DashboardCardsContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/DashboardCardsContainer";
import InformationsGeneralesCard from "../Cards/InformationsGeneralesCard";

const DeclarationGlobalSubDashboard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const declaration = useDeclaration21Now();
  const declarationApi = useDeclarationHelpers21Now();
  const { declarationController } = useAuthenticatedApi();
  const anonymousExportAccuseTransmission = (
    annee: number,
    etablissement: string
  ) =>
    declarationController.exportAccuseTransmissionRegistreGerepAsPdfUsingGET(
      annee,
      etablissement
    );

  const {
    isDeclarationStateContaining,
    stateMessage,
  } = useDeclarationGlobalState21Now();
  const currentCampaign = useCurrentDeclarationCampaign();

  const endedCampaignMessage = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateGlobal
  );

  const declarationStatusComment =
    declaration.body.workflowStatus.general.message;

  return (
    <SubDashboardContainer title="Déclaration annuelle des émissions polluantes et des déchets">
      {!!endedCampaignMessage && <h4>{endedCampaignMessage}</h4>}

      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.GLOBAL}
        isInReviewState={isDeclarationStateContaining(["IN_REVIEW"])}
        comment={declarationStatusComment}
      />

      <DashboardProgressBar declarationApi={declarationApi} />

      <DeclarationStatus
        statusDescription={"Statut de la déclaration GEREP :"}
        declarationStatus={stateMessage}
      />

      <GlobalActionButtons />

      <AccuseTransmissionDownloadButton
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        isDeclarationDone={declarationApi.getPathProgress(PATH_ROOT) === 100}
        suffixFileName="registre_gerep_"
        exportRequest={anonymousExportAccuseTransmission}
      />

      <GlobalNextActionInfo declarationApi={declarationApi} />

      <DeclarationWarningsAndError />

      <DeclarationNothingToDeclare />

      <DashboardCardsContainer>
        <InformationsGeneralesCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
        />
      </DashboardCardsContainer>

      <DashboardCardsContainer>
        <GlobalDashboardCards
          declarationApi={declarationApi}
          declarationEtablissement={declarationEtablissement}
          declarationAnnee={declarationAnnee}
        />
      </DashboardCardsContainer>
    </SubDashboardContainer>
  );
};

export default wrapWithWorkflowTargetProvider(
  DeclarationGlobalSubDashboard,
  "GLOBAL"
);
