import React from "react";
import { RouteIdProps } from "common/declarant/type";
import CampaignDisabled from "../../../../CampaignDisabled";
import Router1919 from "../../../from19/to19/Router1919";
import Router2020 from "../../../from20/to20/Router2020";
import Router2121 from "../../../from21/to21/Router2121";
import Router2222 from "../../../from22/to22/Router2222";
import Router2323 from "../../../from23/to23/Router2323";
import Router24Now from "../../../from24/toNow/Router24Now";

interface CorrectYearRouterProps {
  year: number;
  route: RouteIdProps;
}

export const CorrectYearRouter = ({
  year,
  route,
}: CorrectYearRouterProps): React.ReactElement => {
  let routerToDisplay: React.ReactElement;

  switch (year) {
    case 2018:
      routerToDisplay = <CampaignDisabled campaign={null} />;
      break;
    case 2019:
      routerToDisplay = <Router1919 route={route} />;
      break;
    case 2020:
      routerToDisplay = <Router2020 route={route} />;
      break;
    case 2021:
      routerToDisplay = <Router2121 route={route} />;
      break;
    case 2022:
      routerToDisplay = <Router2222 route={route} />;
      break;
    case 2023:
      routerToDisplay = <Router2323 route={route} />;
      break;
    case 2024:
    default:
      routerToDisplay = <Router24Now route={route} />;
      break;

    // by default, we want to display the most recent year, this way no modification of code is needed if new campaign needs no change compared to previous one
  }

  return routerToDisplay;
};
