import CommonEntityButton from "common/presentational/CommonEntityButton";
import { computeNumberWithSeparatorAndVariableDecimal } from "common/utils/numberUtils";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import { getSingleProcedeInMapById } from "../../utils/utils";
import { computeCorrelationEmissionAnnuelle } from "../utils/calculus";
import {
  computeUnitCorrelationLabel,
  computeUniteProcedeLabel,
} from "../utils/gettersFunctions";
import { CorrelationEmissionInArray, EmissionsInArray } from "../utils/types";
import { computeBiomasseTotal } from "../../../combustionProcedeUtils/biomasse/helpers";

const NUMBER_DECIMAL_INF_ONE = 12;
const NUMBER_DECIMAL_SUP_ONE = 4;

const suppressEmission = (
  singleEmissionInPage: CorrelationEmissionInArray,
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >
) => {
  setCorrelationEmissionsInPage(elderEmissions => {
    return elderEmissions.filter(elderEmissionInPage => {
      return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
    });
  });
};

// a method to compute lines that takes as param any action or data that needs to be deduced from another data
const dummyComputeSingleEmissionLine = (
  emission: CorrelationEmissionInArray,
  emissionAnnuelle: number | null,
  procede: ProcedeInArray | null,
  classes: ClassesType<"full" | "error">,
  editAction: () => void,
  suppressAction: () => void,
  substanceName: string | null,
  isValidated: boolean,
  validationCorrelationsPath: string,
  pathSuffix: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const firstCellContent = computeFirstLineContentCell(
    editAction,
    substanceName || undefined,
    isValidated,
    !_.isEmpty(emission.errors)
  );

  return [
    computeWrappedCellWithError(firstCellContent, classes),
    computeWrappedCellWithError(
      procede !== null ? procede.data.nom : "",
      classes
    ),
    computeWrappedCellWithError(
      procede !== null
        ? computeNumberWithSeparatorAndVariableDecimal(
            procede.data.quantite,
            NUMBER_DECIMAL_INF_ONE,
            NUMBER_DECIMAL_SUP_ONE
          )
        : "",
      classes
    ),
    computeWrappedCellWithError(computeUniteProcedeLabel(procede), classes),
    computeWrappedCellWithError(
      <p title={emission.data.nature || undefined}>{emission.data.nature} </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.facteur,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      computeUnitCorrelationLabel(emission.data.unite, procede),
      classes
    ),
    computeWrappedCellWithError(
      procede !== null
        ? computeNumberWithSeparatorAndVariableDecimal(
            procede.data.masse,
            NUMBER_DECIMAL_INF_ONE,
            NUMBER_DECIMAL_SUP_ONE
          )
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.provenanceFacteurCorrelation || undefined}>
        {emission.data.provenanceFacteurCorrelation}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emissionAnnuelle,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      emission.data.epuration ? "Oui" : "Non",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.natureEquipement || undefined}>
        {emission.data.natureEquipement}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.rendement,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    <CommonEntityButton
      handlerEdit={editAction}
      handlerSuppress={suppressAction}
      suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
      isValidated={isValidated}
      commentPath={`${validationCorrelationsPath}/${pathSuffix}`}
    />,
  ];
};

// method to compute the lines for a "standard" emission. Handles the actions and data that is "deduced" from the base emission given
export const computeSingleEmissionLine = (
  emission: CorrelationEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >,
  isValidated: boolean,
  validationCorrelationsPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setCorrelationEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const procede = getSingleProcedeInMapById(
    emission.data.procedes && emission.data.procedes[0],
    procedesInPageMap
  );

  const emissionAnnuelle = computeCorrelationEmissionAnnuelle(
    emission.data,
    procede
  );

  return dummyComputeSingleEmissionLine(
    emission,
    emissionAnnuelle,
    procede,
    classes,
    editAction,
    suppressAction,
    substanceName,
    isValidated,
    validationCorrelationsPath,
    emission.data.id,
    computeFirstLineContentCell
  );
};

// method to compute the lines for a co2 emission. Handles the duplication of line in 2,
// and the different name in first cell, as well as the different total. Also creates the
// actions and data that is "deduced" from the base emission given
export const computeCo2EmissionLine = (
  emission: CorrelationEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >,
  isValidated: boolean,
  validationCorrelationsPath: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[][] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setCorrelationEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;
  const procede = getSingleProcedeInMapById(
    emission.data.procedes && emission.data.procedes[0],
    procedesInPageMap
  );

  const emissionAnnuelle = computeCorrelationEmissionAnnuelle(
    emission.data,
    procede
  );
  const {
    emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse,
  } = computeBiomasseTotal(emissionAnnuelle, emission.data.biomasse);

  return [
    dummyComputeSingleEmissionLine(
      emission,
      emissionAnnuellesBiomasse,
      procede,
      classes,
      editAction,
      suppressAction,
      `${substanceName} biomasse`,
      isValidated,
      validationCorrelationsPath,
      emission.data.id + "/biomasse",
      computeFirstLineContentCell
    ),
    dummyComputeSingleEmissionLine(
      emission,
      emissionAnnuellesNonBiomasse,
      procede,
      classes,
      editAction,
      suppressAction,
      `${substanceName} non biomasse`,
      isValidated,
      validationCorrelationsPath,
      emission.data.id + "/nonBiomasse",
      computeFirstLineContentCell
    ),
  ];
};
