import * as Yup from "yup";
import { commonPositiveNumberFields } from "common/declarant/formik/formikHelper";
import {
  ReceivedWasteFormData,
  ReceivedWasteImport,
  ReceivedWasteImportArrayWithMessage,
} from "./types";
import { DechetRecuDto1822, ReferenceItemDechetDto } from "api/gen";
import { WasteData, WasteReferentiels } from "../types";
import { Errors } from "common/form/utils";
import { Nullable } from "common/utils/types";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { findElementMatchingTemplate } from "common/utils/methods";
import { parseOuiNonToBoolean } from "../utils/utils";
import { TypeActiviteGlobal20Now } from "../../../toNow/versionedElements/declarationHooks20Now";
import { CSV_RECEPTION_COLUMN_NUMBER } from "../utils/Constants";

export const filterCSVArray = (
  arrayResults: string[][],
  wasteData: WasteData
): ReceivedWasteImportArrayWithMessage | null => {
  if (
    arrayResults.length === 0 ||
    arrayResults[0].length < CSV_RECEPTION_COLUMN_NUMBER
  ) {
    return null;
  }

  const results: Nullable<ReceivedWasteImport>[] = [];
  let isNotAllowedError = false;
  arrayResults.forEach(ele => {
    let hasData = false;
    for (let i = 0; i < CSV_RECEPTION_COLUMN_NUMBER; i++) {
      if (ele[i] !== undefined && ele[i] !== "") {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      return;
    }

    const wasteId =
      ele[0] !== undefined ? ele[0].replace("*", "").trim() : null;

    const receivedQuantity =
      ele[5] !== undefined && ele[5] !== ""
        ? parseFloat(ele[5].replace(",", "."))
        : null;
    const treatedQuantity =
      ele[6] !== undefined && ele[6] !== ""
        ? parseFloat(ele[6].replace(",", "."))
        : null;

    let department: string | null = ele[2] || null;
    if (ele[2] && ele[2].length === 1) {
      department = `0${ele[2]}`;
    }

    const value: Nullable<ReceivedWasteImport> = {
      wasteId: wasteId,
      isOutOfWasteStatut: parseOuiNonToBoolean(ele[1]),
      departement: department,
      pays: ele[3] || null,
      notificationNumber: ele[4] || null,
      receivedQuantity: receivedQuantity,
      treatedQuantity: treatedQuantity,
      operation: ele[7] || null,
    };

    const itemDechet = wasteData.referentiels.polluants.find(
      element => element.codeDechet === wasteId
    );
    if (
      (itemDechet &&
        isDechetAllowed(
          wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases,
          wasteData.tempData.reception.formInfo.isISDI,
          wasteData.tempData.reception.formInfo.isISDD,
          wasteData.tempData.reception.formInfo.isISDND,
          itemDechet
        )) ||
      itemDechet === undefined
    ) {
      results.push(value);
    }
    if (
      itemDechet &&
      !isDechetAllowed(
        wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases,
        wasteData.tempData.reception.formInfo.isISDI,
        wasteData.tempData.reception.formInfo.isISDD,
        wasteData.tempData.reception.formInfo.isISDND,
        itemDechet
      )
    ) {
      isNotAllowedError = true;
    }
  });
  return {
    filteredArray: results,
    errorMessage: isNotAllowedError
      ? computeIsNotAllowedErrorMessage(
          wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases,
          wasteData.tempData.reception.formInfo.isISDI,
          wasteData.tempData.reception.formInfo.isISDND
        )
      : null,
  };
};

export const validationSchema = (
  isISDI: boolean,
  isISDD: boolean,
  isISDND: boolean
): Yup.ObjectSchema<any> => {
  if (!!isISDI || !!isISDD || !!isISDND) {
    return Yup.object().shape({
      remainingCapacity: commonPositiveNumberFields,
    });
  }
  return Yup.object().shape({
    remainingCapacity: Yup.number().nullable(),
  });
};

const findWasteInReferentiel = (
  waste: DechetRecuDto1822,
  referentiel: ReferenceItemDechetDto[]
) => {
  if (!waste.codeID) {
    return undefined;
  }

  return referentiel.find(element => element.uuid === waste.codeID);
};

export const generateReceivedWasteErrors = (
  waste: DechetRecuDto1822,
  referentiel: WasteReferentiels,
  typeActivite: TypeActiviteGlobal20Now,
  receptionDechetsData: ReceivedWasteFormData | null
): Errors<DechetRecuDto1822> => {
  const errors: Errors<DechetRecuDto1822> = {};
  const itemWaste = findWasteInReferentiel(waste, referentiel.polluants);
  if (itemWaste === undefined) {
    errors.codeID = requiredMessage;
  } else if (receptionDechetsData) {
    const isIsdi: boolean =
      typeActivite.isdi !== null ? typeActivite.isdi : false;
    const aCasierAmiante: boolean =
      receptionDechetsData.doesFacilityHaveAsbestosCases !== null
        ? receptionDechetsData.doesFacilityHaveAsbestosCases
        : false;

    if (
      !isDechetAllowed(
        aCasierAmiante,
        isIsdi,
        !!typeActivite.isdd,
        !!typeActivite.isdnd,
        itemWaste
      )
    ) {
      errors.codeID =
        "Ce déchet n'est pas autorisé dans la liste des déchets reçus avec ces valeurs pour ISDND, ISDD et ISDI.";
    }
  }
  if (waste.sortiDuStatutDeDechet === null) {
    errors.sortiDuStatutDeDechet = requiredMessage;
  }
  if (
    waste.operationTraitement === null ||
    !findElementMatchingTemplate(
      { code: waste.operationTraitement },
      referentiel.operationOrValorisationForReception
    )
  ) {
    errors.operationTraitement = requiredMessage;
  }
  if (waste.quantiteAdmise === null) {
    errors.quantiteAdmise = requiredMessage;
  }
  if (waste.quantiteTraitee === null) {
    errors.quantiteTraitee = requiredMessage;
  }

  if (waste.estTraiteDansUnAutrePays === null) {
    errors.estTraiteDansUnAutrePays = requiredMessage;
  } else if (!waste.estTraiteDansUnAutrePays) {
    if (!waste.departementID) {
      errors.departementID = requiredMessage;
    } else if (
      !findElementMatchingTemplate(
        { uuid: waste.departementID },
        referentiel.departements
      )
    ) {
      errors.departementID = requiredMessage;
    }
  } else {
    if (!waste.paysID) {
      errors.paysID = requiredMessage;
    } else {
      const optionCountry = findElementMatchingTemplate(
        {
          uuid: waste.paysID,
        },
        referentiel.pays
      );
      if (!optionCountry) {
        errors.paysID = requiredMessage;
      }
    }
  }

  return errors;
};

export const isDechetAllowed = (
  aCasierAmiante: boolean,
  isISDIChecked: boolean,
  isISDDChecked: boolean,
  isISDNDChecked: boolean,
  itemDechet: ReferenceItemDechetDto
): boolean => {
  const isNothing: boolean =
    !isISDNDChecked && !isISDDChecked && !isISDIChecked;
  const isAllowedBecauseAmiante: boolean =
    itemDechet.avecAmiante && aCasierAmiante;
  const isAllowedBecauseInerte: boolean =
    isISDIChecked && itemDechet.dechetInerte;
  const isAllowedBecauseNotDangereux = isISDNDChecked && !itemDechet.dangereux;

  return (
    isNothing ||
    isAllowedBecauseAmiante ||
    isAllowedBecauseInerte ||
    isAllowedBecauseNotDangereux ||
    isISDDChecked
  );
};

export const computeIsNotAllowedErrorMessage = (
  aCasierAmiante: boolean,
  isISDIChecked: boolean,
  isISDNDChecked: boolean
): string => {
  if (isISDIChecked && !isISDNDChecked && !aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets inertes peuvent être déclarés.";
  }
  if (!isISDIChecked && isISDNDChecked && !aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets non dangereux peuvent être déclarés.";
  }
  if (isISDIChecked && isISDNDChecked && !aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets non dangereux et/ou inertes peuvent être déclarés.";
  }
  if (isISDIChecked && !isISDNDChecked && aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets inertes et/ou avec amiante peuvent être déclarés.";
  }
  if (!isISDIChecked && isISDNDChecked && aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets non dangereux et/ou avec amiante peuvent être déclarés.";
  }
  if (isISDIChecked && isISDNDChecked && aCasierAmiante) {
    return "Certaines lignes n'ont pas été importées car seuls des déchets non dangereux et/ou inertes et/ou avec amiante peuvent être déclarés.";
  }
  return "";
};
