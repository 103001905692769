import React from "react";
import GlobalFormActionsInUserContext from "./GlobalFormActionsInUserContext";
import { GenericGlobalFormActionsInFullContextProps } from "./types";
import { useButtonStateWithRedirection } from "common/button/loadingAndRedirectionHelpers";
import { useSpinnerState } from "../button/loadingAndRedirectionHelpers";
import { Redirect } from "react-router";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { isPathCommented } from "../comment/commentUtils";

/**
 * form action controlling validationPath lifecycle in declaration
 * and perform save, validation and edit actions
 *
 * @param validationTitle:    see GlobalFormActions
 * @param hasChanges:         see GlobalFormActions
 * @param isValidateEnabled:  see GlobalFormActions
 * @param validationPath:     path controlled by these form actions (validated or not, update and validation attempt)
 * @param updateHandler:      declaration update on save or validate button click
 * @param cancelAction:       see GlobalFormActions
 * @param declarationState:   delcaration state (may be either global state or quotas state - if not given, global state will be used)
 * @param isAvailableToDeclarant:    are declarant allowed to use this bloc (default: true)
 * @param isAvailableToPrestataire:  are prestataire allowed to use this bloc (default: false)
 * @param validationMessage: message to display next to validation button. It is an optional object
 * with the message to display and a boolean indicating if the message should be always displayed
 * or only when the button is disabled. If undefined, no message will be displayed.
 * @param getDeclarationState: function to get the default declaration sate (called if no declarationState is given)
 * @param useDeclaration: version of the useDeclaration hook used to get the declaration for the years the bloc is used in
 * @param useDeclarationHelpers: version of the useDeclarationHelpers hook used to get the declaration helpers for the years the bloc is used in
 * @param useBasicDeclarationHandlers: version of the useBasicDeclarationHandlers hook used to get the declaration basic handlers for the years the bloc is used in
 * @param useDeclarationErrorAndWarnings: hook used to get the errors and warnings for the year the bloc is used in
 * @param displayErrorAndWarning: function returning a JSX element to display the errors and warnings for the year the bloc is used in
 */
const GenericGlobalFormActionInFullContext = <
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationConstraintViolationDto extends object
>({
  validationTitle,
  hasChanges,
  isValidateEnabled,
  validationPath,
  updateHandler,
  cancelAction,
  declarationState,
  isAvailableToDeclarant,
  isAvailableToAnyPrestataire,
  validationMessage,
  getDeclarationState,
  useDeclaration,
  useBasicDeclarationHandlers,
  useDeclarationHelpers,
  useDeclarationErrorAndWarnings,
  displayErrorAndWarning,
  areGlobalCommentsAllowed,
  useComments,
}: GenericGlobalFormActionsInFullContextProps<
  VersionedDeclarationDto,
  VersionedDeclarationConstraintViolationDto
>): React.ReactElement => {
  const declaration = useDeclaration();
  const {
    pathToDashboard,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers();
  const { cancelValidate, update, validate } = useBasicDeclarationHandlers();

  const [
    isValidateSpinnerVisible,
    triggerValidateSpinner,
    shouldRedirectToDashboard,
    isCurrentlyValidated,
  ] = useButtonStateWithRedirection(
    validationPath,
    isPathValidatedInDeclaration
  );
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState();

  const {
    warnings,
    hasUncommentedWarning,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings(
    validationPath,
    true,
    isPathValidatedInDeclaration(validationPath)
  );

  const commentsContext = useComments();

  const hasComments = isPathCommented(
    commentsContext.comments,
    validationPath,
    false
  );

  if (shouldRedirectToDashboard) {
    return <Redirect to={pathToDashboard} />;
  }

  return (
    <>
      {(errors.length > 0 || warnings.length > 0) &&
        displayErrorAndWarning(errors, warnings)}

      <GlobalFormActionsInUserContext
        validationTitle={validationTitle}
        isValidated={isCurrentlyValidated}
        hasChanges={hasChanges}
        isValidateEnabled={
          isValidateEnabled && !hasError && !hasUncommentedWarning
        }
        editAction={() => triggerEditSpinner(cancelValidate(validationPath))}
        cancelAction={cancelAction}
        saveAction={() => triggerSaveSpinner(update(updateHandler))}
        validateAction={() =>
          triggerErrorAndWarningDisplay(
            triggerValidateSpinner(validate(updateHandler, validationPath))
          )
        }
        declarationState={declarationState ?? getDeclarationState(declaration)}
        declarationEtablissement={declaration.etablissementCode}
        isAvailableToDeclarant={isAvailableToDeclarant}
        isAvailableToAnyPrestataire={isAvailableToAnyPrestataire}
        isValidateSpinnerVisible={isValidateSpinnerVisible}
        isEditSpinnerVisible={isEditSpinnerVisible}
        isSaveSpinnerVisible={isSaveSpinnerVisible}
        validationMessage={validationMessage}
        commentAction={e => {
          e.preventDefault();
          commentsContext.openModal(validationPath, false);
        }}
        areGlobalCommentsAllowed={areGlobalCommentsAllowed || false}
        hasComments={hasComments}
      />
    </>
  );
};

export default GenericGlobalFormActionInFullContext;
