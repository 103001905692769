import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { BlocInstallationProps, InstallationInArray } from "./types";
import InstallationsArray from "./installationsArray";
import Row from "common/presentational/Row";
import Button from "common/button";
import { SECTION_TITLE_GREY } from "theme";
import InstallationModale from "./installationModale";
import { updateInstallationsHandler } from "./converter";
import { PATH_QUOTAS_INSTALLATION_BLOC } from "common/path/path1819";
import _ from "lodash";
import { reconcileDeclarationEmission } from "../blocEmissions/utils/reconcile";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";
import BlocFullContext1919 from "../../versionedElements/BlocFullContext1919";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "spaceBetween",
    marginBottom: "15px",
  },
  subTitle: {
    color: SECTION_TITLE_GREY,
  },
});

const BlocInstallations = ({
  values,
  setValues,
  hasChanges,
  cancelAction,
  possibleValues,
  referentialActivites,
  setBlocEmissions,
}: BlocInstallationProps) => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    installationInModal,
    setInstallationInModal,
  ] = useState<InstallationInArray | null>(null);

  const openModal = (installation: InstallationInArray | null) => {
    setInstallationInModal(installation);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setInstallationInModal(null);
  };

  return (
    <>
      <BlocFullContext1919
        title="déclaration et description des installations"
        hasModification={hasChanges}
        isValidateButtonAvailable={true}
        path={PATH_QUOTAS_INSTALLATION_BLOC}
        updateHandler={declaration => {
          let updatedDeclaration = updateInstallationsHandler(
            _.cloneDeep(declaration),
            values,
            referentialActivites
          );

          if (!_.isEqual(declaration, updatedDeclaration)) {
            updatedDeclaration = reconcileDeclarationEmission(
              _.cloneDeep(declaration),
              updatedDeclaration
            );

            setBlocEmissions(
              updatedDeclaration.body.sections.quotas.blocEmissions
            );
          }
          return updatedDeclaration;
        }}
        cancelAction={cancelAction}
        declarationState={declaration.body.workflowStatus.quotaEmissions.state}
        renderContent={shouldDisabledFields => (
          <>
            {" "}
            <Row additionalClassname={classes.row}>
              <p className={classes.subTitle}>
                Tableau des NIM de l'établissement
              </p>
              <Button
                text="AJOUTER UN NIM"
                onClick={() => {
                  setIsModalOpen(true);
                }}
                isDisabled={shouldDisabledFields}
              />
            </Row>
            <InstallationsArray
              installationsInArray={values}
              setInstallationsInArray={setValues}
              openModal={openModal}
              isDisabled={shouldDisabledFields}
            />
          </>
        )}
      />
      <InstallationModale
        isOpen={isModalOpen}
        installationInModal={installationInModal}
        installationsInArray={values}
        setInstallationsInArray={setValues}
        closeModal={closeModal}
        possibleValues={possibleValues}
      />
    </>
  );
};

export default BlocInstallations;
