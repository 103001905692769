import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BORDER_GREY,
  FIELD_FONT_SIZE,
  FIELD_FONT_WEIGHT,
  SECTION_TITLE_GREY,
  SUBTITLE_STYLE,
} from "theme";
import classNames from "classnames";
import {
  AlternativeEmissionInArray,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "../BlocEmissions/types";
import { useDummyNumberFieldGenerator } from "common/form/fields/helpers/generators";
import {
  computeCO2Emissions,
  computeCO2EmissionsForAllInstallations,
  computeN2OEmissions,
  computePFCEmissions,
  computeTotalEmissions,
} from "./computers";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import Row from "common/presentational/Row";
import { QuotasInstallationDto20Now } from "api/gen";

const useStyles = makeStyles({
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
  },
  subTitle: SUBTITLE_STYLE,
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "5px",
  },
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "center",
    alignSelf: "center",
    width: "25%",
  },
  fieldContainer: {
    width: "25%",
    justifyContent: "center",
    textAlign: "center",
    padding: "0 10px",
  },
  field: {
    width: "100%",
    margin: "auto",
  },
  textField: {
    position: "relative",
    top: "20%",
  },
  noLabel: {
    "&>div:nth-child(1)": {
      marginRight: "0", // Remove margin used for label
    },
  },
});

interface BlocBilanTotalCalculeProps {
  installations: QuotasInstallationDto20Now[];
  computedEmissionsInArray: ComputedEmissionInArray[];
  measuredEmissionsInArray: MeasureEmissionInArray[];
  alternativeEmissionsInArray: AlternativeEmissionInArray[];
  measureDeclarations: MeasureDeclarationProps[];
  flowDeclarations: FlowDeclarationProps[];
  additionalClassname?: string;
}

const BlocBilanTotalCalcule = ({
  installations,
  computedEmissionsInArray,
  measuredEmissionsInArray,
  alternativeEmissionsInArray,
  measureDeclarations,
  flowDeclarations,
  additionalClassname,
}: BlocBilanTotalCalculeProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.noLabel,
    labelWidth: "0",
    formPrefix: "bloc-quotas-bilan-total-calcule",
  };

  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const DummyNumberFieldWithLabel = useDummyNumberFieldGenerator({
    ...commonProps,
    labelWidth: "50%",
    className: classes.field,
  });

  return (
    <>
      <div className={classNames(classes.container, additionalClassname)}>
        <h3 className={classes.subTitle}>bilan total (non biomasse)</h3>

        {installations.length !== 0 ? (
          <>
            <div className={classes.row}>
              <p className={classes.label}>NIM</p>
              <label htmlFor="emissionsCO2" className={classes.label}>
                Émissions de CO
                <sub>2</sub>
              </label>
              <label htmlFor="emissionsN2O" className={classes.label}>
                Émissions de N<sub>2</sub>O
              </label>
              <label htmlFor="emissionsPFC" className={classes.label}>
                Émissions de PFC
              </label>
              <p className={classes.label}>Émissions totales</p>
            </div>

            {installations.map(installation => (
              <div className={classes.row} key={installation.id}>
                <div className={classes.fieldContainer}>
                  <span className={classes.textField}>{installation.nim}</span>
                </div>
                <div className={classes.fieldContainer}>
                  <DummyNumberField
                    name="emissionsCO2"
                    label=""
                    unit={computeUnitTCO2PerYear}
                    additionalClassName={classes.field}
                    value={computeCO2Emissions(
                      installation,
                      computedEmissionsInArray,
                      measuredEmissionsInArray,
                      alternativeEmissionsInArray,
                      measureDeclarations,
                      flowDeclarations,
                      false
                    )}
                    disabled
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <DummyNumberField
                    name="emissionsN2O"
                    label=""
                    unit={computeUnitTCO2PerYear}
                    additionalClassName={classes.field}
                    value={computeN2OEmissions(
                      installation,
                      measuredEmissionsInArray,
                      alternativeEmissionsInArray,
                      measureDeclarations,
                      false
                    )}
                    disabled
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <DummyNumberField
                    name="emissionsPFC"
                    label=""
                    unit={computeUnitTCO2PerYear}
                    additionalClassName={classes.field}
                    value={computePFCEmissions(
                      installation,
                      alternativeEmissionsInArray,
                      false
                    )}
                    disabled
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <DummyNumberField
                    name="totalEmissions"
                    label=""
                    unit={computeUnitTCO2PerYear}
                    additionalClassName={classes.field}
                    value={computeTotalEmissions(
                      installation,
                      computedEmissionsInArray,
                      measuredEmissionsInArray,
                      alternativeEmissionsInArray,
                      measureDeclarations,
                      flowDeclarations,
                      false
                    )}
                    disabled
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>

      <Row />

      <DummyNumberFieldWithLabel
        name="allCO2BiomassEmissions"
        label={() => (
          <>
            Pour information, émissions de CO<sub>2</sub> biomasse
          </>
        )}
        unit={computeUnitTCO2PerYear}
        value={computeCO2EmissionsForAllInstallations(
          installations,
          computedEmissionsInArray,
          measuredEmissionsInArray,
          alternativeEmissionsInArray,
          measureDeclarations,
          flowDeclarations,
          true
        )}
        disabled
      />
    </>
  );
};

export default BlocBilanTotalCalcule;
