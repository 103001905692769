import { AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum } from "api/gen";

export const arrayCombustionConcentrationUnitsLabel: {
  [key in AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum]: string;
} = {
  KG_NM3: "kg/Nm\u00B3",
  G_NM3: "g/Nm\u00B3",
  MG_NM3: "mg/Nm\u00B3",
  UG_NM3: "\u00B5g/Nm\u00B3",
  NG_NM3: "ng/Nm\u00B3",
  PG_NM3: "pg/Nm\u00B3",
};

export const arrayCombustionConcentrationUnits: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum[] = Object.keys(
  arrayCombustionConcentrationUnitsLabel
) as AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum[];
