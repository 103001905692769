import React, { ReactElement } from "react";

import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import {
  generateTransitionWarningMessage,
  VALIDATED,
} from "common/messages/dashboardMessage";
import { WorkflowTarget } from "common/utils/types";
import {
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../../toNow/versionedElements/declarationHooks21Now";
import { isEveryRequiredSectionValidated } from "../../Dashboard/Components/utils/validationUtils";
import { useSpinnerState } from "../../../../../../common/button/loadingAndRedirectionHelpers";

const ApproveButton = (): ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const { approve } = useDeclarationStateHandlers21Now();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const declarationApi = useDeclarationHelpers21Now();

  return (
    <Button
      text={"VALIDER"}
      onClick={() => {
        openConfirmationModale(
          generateTransitionWarningMessage(VALIDATED),
          () => {
            triggerSpinner(approve([WorkflowTarget.GLOBAL]));
          }
        );
      }}
      isDisabled={!isEveryRequiredSectionValidated(declarationApi)}
      isSpinnerVisible={isSpinnerVisible}
    />
  );
};

export default ApproveButton;
