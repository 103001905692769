import { Nullable } from "common/utils/types";
import { StructureFonctionnelleData } from "../StructureFonctionnelle/utils";
import {
  ReferenceItemOrganismeAccrediteDto,
  ReferenceItemOrganismeExterieurPreventionDto,
} from "api/gen";
import {
  convertStructureFonctionnelleDataToDeclarationPreventionDto,
  overrideUncoherentValuesStructureFonctionnelle,
} from "../StructureFonctionnelle/converters";
import {
  AccidentInArray,
  AccidentsTravailData,
} from "../AccidentsTravail/utils";
import { MesureEmpoussierageData } from "../MesuresEmpoussierage/utils";
import { computeAccidentDtoWithArrayAccidentAndAccidentValues } from "../AccidentsTravail/converters";
import {
  convertMesureEmpoussierageDataToDeclarationEmpoussierageDto,
  overrideUncoherentValuesMesureEmpoussierage,
} from "../MesuresEmpoussierage/converters";
import { Declaration20Now } from "../../../versionedElements/declarationHooks20Now";

export const updateStructureFonctionnelle = (
  declaration: Declaration20Now,
  values: Nullable<StructureFonctionnelleData>,
  referenceItemOrganismeExterieurPreventionDtoList: ReferenceItemOrganismeExterieurPreventionDto[]
): Declaration20Now => {
  const coherentValues = overrideUncoherentValuesStructureFonctionnelle(values);
  declaration.body.sections.carriere.sante.prevention = convertStructureFonctionnelleDataToDeclarationPreventionDto(
    coherentValues,
    referenceItemOrganismeExterieurPreventionDtoList
  );
  return declaration;
};

export const updateAccidentsTravail = (
  declaration: Declaration20Now,
  values: Nullable<AccidentsTravailData>,
  accidentsList: AccidentInArray[]
): Declaration20Now => {
  declaration.body.sections.carriere.sante.accident = computeAccidentDtoWithArrayAccidentAndAccidentValues(
    accidentsList,
    values
  );
  return declaration;
};

export const updateMesuresEmpoussierage = (
  declaration: Declaration20Now,
  values: Nullable<MesureEmpoussierageData>,
  referenceItemOrganismeAccrediteDtoList: ReferenceItemOrganismeAccrediteDto[]
): Declaration20Now => {
  const coherentValues = overrideUncoherentValuesMesureEmpoussierage(values);
  declaration.body.sections.carriere.sante.empoussierage = convertMesureEmpoussierageDataToDeclarationEmpoussierageDto(
    coherentValues,
    referenceItemOrganismeAccrediteDtoList
  );
  return declaration;
};
