import {
  FileDto,
  QuotasEmissionsBlocPlanSurveillanceDto2020,
  QuotasEmissionSimpleCalculeDto2020,
  QuotasEmissionSimpleCalculeDto2020MethodeEnum,
} from "api/gen";
import { BlocPlanDeSurveillanceFormValues } from "./types";
import { computeAllowedDeclarationsMethods } from "../BlocEmissions/utils/utils";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

export const dtoToBlocValues = (
  dto: QuotasEmissionsBlocPlanSurveillanceDto2020
): BlocPlanDeSurveillanceFormValues => {
  return {
    computedByEmissionWithOxidationFactor: !!dto.calculFacteurEmissionOxydation,
    computedByEmissionWithConversionFactor: !!dto.calculFacteurEmissionConversion,
    computedByMassBalance: !!dto.calculBilanMassique,
    measure: !!dto.estMesure,
    alternativeMethod: !!dto.estMethodeAlternative,
  };
};

const blocValuesToDto = (
  values: BlocPlanDeSurveillanceFormValues | null,
  files: FileDto[]
): QuotasEmissionsBlocPlanSurveillanceDto2020 => {
  return {
    calculFacteurEmissionOxydation: values
      ? values.computedByEmissionWithOxidationFactor
      : false,
    calculFacteurEmissionConversion: values
      ? values.computedByEmissionWithConversionFactor
      : false,
    calculBilanMassique: values ? values.computedByMassBalance : false,
    estMesure: values ? values.measure : false,
    estMethodeAlternative: values ? values.alternativeMethod : false,
    fichiers: files,
  };
};

export const updateHandler = (
  declaration: Declaration2020,
  values: BlocPlanDeSurveillanceFormValues | null
): Declaration2020 => {
  declaration.body.sections.quotas.emissions.blocPlanSurveillance = blocValuesToDto(
    values,
    declaration.body.sections.quotas.emissions.blocPlanSurveillance.fichiers
  );
  const allowedDeclarationMethod = computeAllowedDeclarationsMethods(
    declaration.body.sections.quotas.emissions.blocPlanSurveillance
  );

  if (allowedDeclarationMethod.computed === false) {
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsCalculees = [];
    declaration.body.sections.quotas.emissions.blocEmissions.flux = [];
    declaration.body.sections.quotas.emissions.blocEmissions.fluxSoumisADerogation = [];
  } else {
    let emissionsCalculees: QuotasEmissionSimpleCalculeDto2020[] =
      declaration.body.sections.quotas.emissions.blocEmissions
        .emissionsCalculees;
    if (!allowedDeclarationMethod.computed.bilanMassique) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto2020MethodeEnum.BILAN_MASSIQUE
      );
    }
    if (!allowedDeclarationMethod.computed.facteurConversion) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_CONVERSION
      );
    }
    if (!allowedDeclarationMethod.computed.facteurOxydation) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_OXYDATION
      );
    }
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsCalculees = emissionsCalculees;
  }

  if (!allowedDeclarationMethod.mesure) {
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsMesurees = [];
    declaration.body.sections.quotas.emissions.blocEmissions.pointsMesure = [];
  }

  if (!allowedDeclarationMethod.alternative) {
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsMethodeAlternative = [];
  }

  return declaration;
};
