import React from "react";
import { BlocEmissionsFormValues, BlocEmissionsProps } from "./types";
import { additionalValidation, validationSchema } from "./validation";
import {
  alternativeEmissionToEmissionSimpleDto,
  computedEmissionToEmissionSimpleDto,
  dtoToBlocValues,
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  flowDeclarationToFluxDto,
  flowDeclarationToOptionProps,
  flowIdToFlowOptionProps,
  measureDeclarationToPointMesureDto,
  measuredEmissionToEmissionSimpleDto,
  updateHandler,
} from "./converter";
import { makeStyles } from "@material-ui/styles";
import {
  BLACK_BUTTON_STYLE,
  DISABLED_SECTION_STYLE,
  FILE_BUTTONS_STYLE,
  LEFT_WITHDRAW_STYLE,
  SECTION_TITLE_GREY,
} from "theme";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import classNames from "classnames";
import ComputedMethod from "./computedMethod";
import MeasureMethod from "./measureMethod";
import AlternativeMethod from "./alternativeMethod";
import { FileSectionEnum } from "common/FilesLoader/types";
import {
  useBooleanCheckBoxGenerator,
  useDummyChoiceSelectFieldGenerator,
} from "common/form/fields/helpers/generators";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "common/presentational/Row";
import _ from "lodash";
import { OptionProps } from "common/form/fields/types/basicTypes";
import {
  PATH_QUOTAS_EMISSIONS_BLOC,
  PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION,
  PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION,
  PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2,
} from "common/path/path1819";
import { useAlertModale } from "common/modale/hooks";
import {
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
} from "../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../versionedElements/FormikBlocFullContext1919";
import { useRapportEmissionsFileHandler1919 } from "../../versionedElements/filesLoaderDisplayer1919/sectionFilesHooks1919";
import FilesLoader from "common/FilesLoader/FilesLoader";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
    marginBottom: "20px",
  },
  ...FILE_BUTTONS_STYLE,
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...BLACK_BUTTON_STYLE,
  intermediateSaveButton: {
    padding: "5px",
    position: "relative",
    bottom: "29px",
    alignSelf: "flex-end",
    marginRight: "200px",
  },
  ...LEFT_WITHDRAW_STYLE,
  ...DISABLED_SECTION_STYLE,
});

const BlocEmissions = ({
  blocEmissionsFormProps,
  installationsInArray,
  isDisabled,
  computedEmissionsInArray,
  setComputedEmissionsInArray,
  measuredEmissionsInArray,
  setMeasuredEmissionsInArray,
  alternativeEmissionsInArray,
  setAlternativeEmissionsInArray,
  exemptedFlows,
  setExemptedFlows,
  allowedDeclarationMethods,
}: BlocEmissionsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();
  const { update } = useBasicDeclarationHandlers1919();
  const openAlertModale = useAlertModale();

  // TODO: GEREP-812 - remove during refacto
  const files = declaration.body.sections.quotas.blocEmissions.fichiers;
  const rapportEmissionsFileHandler = useRapportEmissionsFileHandler1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "bloc-quotas-plan-de-surveillance",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DummyChoiceSelect = useDummyChoiceSelectFieldGenerator(commonProps);

  const initialValues = dtoToBlocValues(
    declaration.body.sections.quotas.blocEmissions,
    installationsInArray
  );

  const intermediateSaveButtonAction = async (
    values: BlocEmissionsFormValues
  ) => {
    await update(declaration =>
      updateHandler(
        declaration,
        values,
        files,
        computedEmissionsInArray,
        measuredEmissionsInArray,
        alternativeEmissionsInArray,
        values.flowDeclarations,
        values.measureDeclarations,
        exemptedFlows
      )
    );
    blocEmissionsFormProps.setHasChanges(false);
  };

  return (
    <>
      {isDisabled && (
        <div className={classes.disabledMessage}>
          <span>
            Veuillez valider votre déclaration d'installations et votre plan de
            surveillance avant d'accéder à cette section
          </span>
        </div>
      )}
      <div className={isDisabled ? classes.disabledSection : ""}>
        <FormikBlocFullContext1919
          formikRef={blocEmissionsFormProps.formikRef}
          hasChanges={blocEmissionsFormProps.hasChanges}
          setHasChanges={blocEmissionsFormProps.setHasChanges}
          initialValues={initialValues}
          hasFormChanges={values => {
            return (
              values.CO2Transfer !==
                declaration.body.sections.quotas.blocEmissions.transfertCO2 ||
              values.exemption !==
                declaration.body.sections.quotas.blocEmissions
                  .derogationFrequenceEchantillonnage ||
              !_.isEqual(
                exemptedFlows.map(
                  f => flowIdToFlowOptionProps(f, values.flowDeclarations).label
                ),
                declaration.body.sections.quotas.blocEmissions
                  .fluxSoumisADerogation
              ) ||
              !_.isEqual(
                values.flowDeclarations.map(flowDeclarationToFluxDto),
                declaration.body.sections.quotas.blocEmissions.flux
              ) ||
              !_.isEqual(
                values.measureDeclarations.map(
                  measureDeclarationToPointMesureDto
                ),
                declaration.body.sections.quotas.blocEmissions.pointsMesure
              ) ||
              !_.isEqual(
                computedEmissionsInArray.map(e =>
                  computedEmissionToEmissionSimpleDto(
                    e,
                    values.flowDeclarations
                  )
                ),
                declaration.body.sections.quotas.blocEmissions
                  .emissionsCalculees
              ) ||
              !_.isEqual(
                measuredEmissionsInArray.map(e =>
                  measuredEmissionToEmissionSimpleDto(
                    e,
                    values.measureDeclarations
                  )
                ),
                declaration.body.sections.quotas.blocEmissions.emissionsMesurees
              ) ||
              !_.isEqual(
                alternativeEmissionsInArray.map(
                  alternativeEmissionToEmissionSimpleDto
                ),
                declaration.body.sections.quotas.blocEmissions
                  .emissionsMethodeAlternative
              )
            );
          }}
          cancelAction={() => {
            setComputedEmissionsInArray(() =>
              declaration.body.sections.quotas.blocEmissions.emissionsCalculees.map(
                e =>
                  emissionSimpleDtoToComputedEmission(
                    e,
                    initialValues.flowDeclarations
                  )
              )
            );
            setMeasuredEmissionsInArray(() =>
              declaration.body.sections.quotas.blocEmissions.emissionsMesurees.map(
                e =>
                  emissionSimpleDtoToMeasuredEmission(
                    e,
                    initialValues.measureDeclarations
                  )
              )
            );
            setAlternativeEmissionsInArray(() =>
              declaration.body.sections.quotas.blocEmissions.emissionsMethodeAlternative.map(
                emissionSimpleDtoToAlternativeEmission
              )
            );
            return initialValues;
          }}
          validationSchema={validationSchema}
          title={"déclaration des émissions"}
          pathToValidate={PATH_QUOTAS_EMISSIONS_BLOC}
          updateHandler={(declaration, values) =>
            updateHandler(
              declaration,
              values,
              files,
              computedEmissionsInArray,
              measuredEmissionsInArray,
              alternativeEmissionsInArray,
              values.flowDeclarations,
              values.measureDeclarations,
              exemptedFlows
            )
          }
          additionalValidationAllowed={() => !isDisabled}
          additionalFieldValidation={values =>
            additionalValidation(
              values.measureDeclarations,
              values.flowDeclarations
            )
          }
          declarationState={
            declaration.body.workflowStatus.quotaEmissions.state
          }
          renderContent={({ values }, shouldDisabledFields) => (
            <>
              <div className={classes.text}>
                <p>À faire dans ce bloc :</p>
                <ul>
                  <li>
                    Télécharger si besoin le modèle du rapport d'émission annuel
                    quotas,
                  </li>
                  <li>Le compléter</li>
                  <li>
                    Déposer le rapport d'émission annuel quotas complété (au
                    moins 1 par NIM),
                  </li>
                  <li>Reporter les informations demandées ci-dessous.</li>
                </ul>
              </div>

              <GreyFilesDownloader
                text="TÉLÉCHARGER MODÈLE RAPPORT D'ÉMISSION"
                isDisabled={shouldDisabledFields}
                fileName="RapportEmissions.xls"
                additionalClassname={classes.fileButtons}
              />

              <FilesLoader
                declaration={declaration}
                text={
                  "DÉPOSER RAPPORT D'ÉMISSION COMPLÉTÉ (AU MOINS 1 PAR NIM)"
                }
                filesPath={"body.sections.quotas.blocEmissions.fichiers"}
                section={FileSectionEnum.QUOTAS_RAPPORT_EMISSIONS}
                fileHandlers={rapportEmissionsFileHandler}
                //
                isDisabled={shouldDisabledFields}
                //
                additionalClassnameButton={classes.fileButtons}
                onReject={() =>
                  openAlertModale("Le format du fichier uploadé est incorrect.")
                }
              />

              {allowedDeclarationMethods.computed !== false && (
                <>
                  <ComputedMethod
                    values={values}
                    shouldDisabledFields={shouldDisabledFields}
                    installationsInArray={installationsInArray}
                    computedEmissionsInArray={computedEmissionsInArray}
                    setComputedEmissionsInArray={setComputedEmissionsInArray}
                    setExemptedFlows={setExemptedFlows}
                    computedAllowedDeclarationMethods={
                      allowedDeclarationMethods.computed
                    }
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              {allowedDeclarationMethods.mesure && (
                <>
                  <MeasureMethod
                    values={values}
                    shouldDisabledFields={shouldDisabledFields}
                    installationsInArray={installationsInArray}
                    measuredEmissionsInArray={measuredEmissionsInArray}
                    setMeasuredEmissionsInArray={setMeasuredEmissionsInArray}
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              {allowedDeclarationMethods.alternative && (
                <>
                  <AlternativeMethod
                    shouldDisabledFields={shouldDisabledFields}
                    alternativeEmissionsInArray={alternativeEmissionsInArray}
                    setAlternativeEmissionsInArray={
                      setAlternativeEmissionsInArray
                    }
                    installationsInArray={installationsInArray}
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              <Row />

              <CheckBox
                name="exemption"
                label="Une ou plusieurs fréquences d’échantillonnage font l’objet d’une dérogation pour coûts excessifs (article 35.2.b du règlement MRR)"
                disabled={shouldDisabledFields}
                commentPath={PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION}
              />

              {values.exemption && (
                <div className={classes.withdrawLeft}>
                  <DummyChoiceSelect
                    name="exemptedFlows"
                    label="Si oui, indiquer les flux concernés par la dérogation"
                    isMulti
                    options={values.flowDeclarations.map(
                      flowDeclarationToOptionProps
                    )}
                    value={exemptedFlows.map(f =>
                      flowIdToFlowOptionProps(f, values.flowDeclarations)
                    )}
                    additionalOnChange={value =>
                      setExemptedFlows(() =>
                        // Because the select is multi
                        (value as OptionProps[]).map(v => v.value.toString())
                      )
                    }
                    disabled={shouldDisabledFields}
                    commentPath={PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION}
                  />
                </div>
              )}

              <CheckBox
                name="CO2Transfer"
                label={() => (
                  <>
                    Y a-t-il eu transfert de CO
                    <sub>2</sub> intrinsèque au sens de l’article 48 ou
                    transfert de CO
                    <sub>2</sub> au sens de l’article 49 du règlement MRR ?
                  </>
                )}
                disabled={shouldDisabledFields}
                commentPath={PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

export default BlocEmissions;
