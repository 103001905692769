import { computeRefMethodForMethod, WasteMethod } from "./Referentiels";
import {
  DechetProduitDto1819,
  DechetProduitDto1819LieuOperationEnum,
  DechetProduitDto1819MethodReferenceEnum,
  DechetRecuDto1822,
  TypeActiviteDto1819,
} from "api/gen";
import {
  ProductedWasteImport,
  ProductedWasteStorage,
} from "../PolluantProduction/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteImport,
  ReceivedWasteStorage,
} from "../PolluantReception/types";
import uuid from "uuid";
import { WasteReferentiels } from "../types";
import { generateProductedWasteErrors } from "../PolluantProduction/utils";
import { generateReceivedWasteErrors } from "../PolluantReception/utils";
import { Nullable } from "common/utils/types";
import {
  findElementMatchingTemplate,
  normalizeLabel,
} from "common/utils/methods";

//  generate waste using CSV import
const computeProductionMethodForWaste = (
  waste: Nullable<ProductedWasteImport>,
  referentiel: WasteMethod[]
) => {
  if (!waste.methode) {
    return null;
  }
  const matchMethod = findElementMatchingTemplate(
    { csvCode: waste.methode },
    referentiel
  );
  return matchMethod ? matchMethod.backOfficeCode : null;
};

const computeProductionMethodRefForWaste = (
  waste: Nullable<ProductedWasteImport>,
  referentiel: WasteMethod[]
): DechetProduitDto1819MethodReferenceEnum | null => {
  if (!waste.methodeRef || !waste.methode) {
    return null;
  }

  const matchMethod = findElementMatchingTemplate(
    { csvCode: waste.methode },
    referentiel
  );
  const availableMethodRef =
    matchMethod && computeRefMethodForMethod(matchMethod);
  if (availableMethodRef === null) {
    return null;
  }
  const matchEnumValue = availableMethodRef
    .map(refMethod => refMethod.toString())
    .includes(waste.methodeRef)
    ? (waste.methodeRef as DechetProduitDto1819MethodReferenceEnum)
    : null;
  return matchEnumValue;
};

const computeLieuOperationForWaste = (
  waste: Nullable<ProductedWasteImport>
) => {
  if (!waste.departement && !waste.pays) {
    return DechetProduitDto1819LieuOperationEnum.SITE;
  } else if (waste.departement) {
    return DechetProduitDto1819LieuOperationEnum.DEPARTEMENT;
  } else if (waste.pays) {
    return DechetProduitDto1819LieuOperationEnum.PAYS;
  } else {
    return null;
  }
};

export const generatePolluantWasteFromCSV = (
  waste: Nullable<ProductedWasteImport>,
  referentiels: WasteReferentiels
): ProductedWasteStorage => {
  const lieuOperation = computeLieuOperationForWaste(waste);
  const code =
    waste.wasteId === null
      ? null
      : findElementMatchingTemplate(
          { codeDechet: waste.wasteId },
          referentiels.polluants
        );

  const temp = {
    id: uuid(),
    codeID: code !== null ? code.uuid : waste.wasteId,
    quantite: waste.wasteAmount,
    method: computeProductionMethodForWaste(waste, referentiels.methods),
    methodReference: computeProductionMethodRefForWaste(
      waste,
      referentiels.methods
    ),
    methodPrecision: waste.precisionRefMethod,
    operationElimination: waste.operationElimOuValo,
    lieuOperation: lieuOperation,
  };

  const pays =
    waste.pays !== null
      ? findElementMatchingTemplate(
          {
            designation: normalizeLabel(waste.pays),
          },
          referentiels.pays
        )
      : null;

  const departement =
    waste.departement !== null
      ? findElementMatchingTemplate(
          { numero: waste.departement },
          referentiels.departements
        )
      : null;

  let storage: DechetProduitDto1819;
  if (!lieuOperation) {
    storage = {
      ...temp,
      departementID:
        departement !== null ? departement.uuid : waste.departement,
      paysID: pays !== null ? pays.uuid : waste.pays,
      nomEtablissementReception: waste.etablissementReception,
      adresseEtablissementReception: waste.etablissementReceptionAdress,
      nomEtablissementElimination: waste.etablissementElimination,
      adresseEtablissementElimination: waste.etablissementEliminationAdress,
      numeroNotification: waste.numeroNotification,
    };
  } else if (lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE) {
    storage = {
      ...temp,
      departementID:
        departement !== null ? departement.uuid : waste.departement,
      paysID: null,
      nomEtablissementReception: null,
      adresseEtablissementReception: null,
      nomEtablissementElimination: null,
      adresseEtablissementElimination: null,
      numeroNotification: null,
    };
  } else if (
    lieuOperation === DechetProduitDto1819LieuOperationEnum.DEPARTEMENT
  ) {
    storage = {
      ...temp,
      departementID:
        departement !== null ? departement.uuid : waste.departement,
      paysID: null,
      nomEtablissementReception: waste.etablissementReception,
      adresseEtablissementReception: waste.etablissementReceptionAdress,
      nomEtablissementElimination: null,
      adresseEtablissementElimination: null,
      numeroNotification: null,
    };
  } else if (lieuOperation === DechetProduitDto1819LieuOperationEnum.PAYS) {
    storage = {
      ...temp,
      departementID: null,
      paysID: pays !== null ? pays.uuid : waste.pays,
      nomEtablissementReception: waste.etablissementReception,
      adresseEtablissementReception: waste.etablissementReceptionAdress,
      nomEtablissementElimination: waste.etablissementElimination,
      adresseEtablissementElimination: waste.etablissementEliminationAdress,
      numeroNotification: waste.numeroNotification,
    };
  } else {
    throw Error("storage is assigned for god's sake");
  }

  return {
    data: storage,
    errors: generateProductedWasteErrors(storage, referentiels),
  };
};

export const generateReceivedWasteFromCSV = (
  waste: Nullable<ReceivedWasteImport>,
  referentiels: WasteReferentiels,
  typeActivite: TypeActiviteDto1819,
  receptionDechetsData: ReceivedWasteFormData | null
): ReceivedWasteStorage => {
  let isNotInFrance: boolean | null;
  if (!waste.departement && !waste.pays) {
    isNotInFrance = null;
  } else if (waste.departement) {
    isNotInFrance = false;
  } else {
    isNotInFrance = true;
  }
  const code =
    waste.wasteId === null
      ? null
      : findElementMatchingTemplate(
          { codeDechet: waste.wasteId },
          referentiels.polluants
        );
  const temp = {
    id: uuid(),
    codeID: code ? code.uuid : waste.wasteId,
    sortiDuStatutDeDechet: waste.isOutOfWasteStatut,
    estTraiteDansUnAutrePays: isNotInFrance,
    quantiteAdmise: waste.receivedQuantity,
    quantiteTraitee: waste.treatedQuantity,
    operationTraitement: waste.operation,
  };

  const pays =
    waste.pays !== null
      ? findElementMatchingTemplate(
          {
            designation: normalizeLabel(waste.pays),
          },
          referentiels.pays
        )
      : null;

  const departement =
    waste.departement !== null
      ? findElementMatchingTemplate(
          { numero: waste.departement },
          referentiels.departements
        )
      : null;

  let storage: DechetRecuDto1822;
  if (isNotInFrance === null) {
    storage = {
      ...temp,
      departementID:
        departement !== null ? departement.uuid : waste.departement,
      paysID: pays !== null ? pays.uuid : waste.pays,
      numeroNotification: waste.notificationNumber,
    };
  } else if (!isNotInFrance) {
    storage = {
      ...temp,
      departementID:
        departement !== null ? departement.uuid : waste.departement,
      paysID: null,
      numeroNotification: null,
    };
  } else {
    storage = {
      ...temp,
      departementID: null,
      paysID: pays !== null ? pays.uuid : waste.pays,
      numeroNotification: waste.notificationNumber,
    };
  }

  return {
    data: storage,
    errors: generateReceivedWasteErrors(
      storage,
      referentiels,
      typeActivite,
      receptionDechetsData
    ),
  };
};

export const generateReceivedWasteFromProduced = (
  wasteData: ProductedWasteStorage
): ReceivedWasteStorage => {
  if (
    wasteData.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE
  ) {
    return {
      data: {
        id: wasteData.data.id,
        codeID: wasteData.data.codeID,
        sortiDuStatutDeDechet: null,
        estTraiteDansUnAutrePays: null,
        departementID: null,
        paysID: null,
        quantiteAdmise: 0,
        quantiteTraitee: wasteData.data.quantite,
        operationTraitement: wasteData.data.operationElimination,
        numeroNotification: null,
      },
      errors: {}, // no errors to display for received waste generated from produced one
    };
  } else {
    throw new Error("should generate only with produced on site waste");
  }
};

export const generateReceivedPolluant = (
  from: ProductedWasteStorage[]
): ReceivedWasteStorage[] => {
  const received: ReceivedWasteStorage[] = [];
  from
    .filter(test => {
      return (
        test.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE
      );
    })
    .forEach(waste => {
      received.push(generateReceivedWasteFromProduced(waste));
    });
  return received;
};

export const convertProductionFetchToLocal = (
  fetch: DechetProduitDto1819[],
  referentiels: WasteReferentiels
): ProductedWasteStorage[] => {
  return fetch.map(ele => {
    const populated: ProductedWasteStorage = {
      data: ele,
      errors: generateProductedWasteErrors(ele, referentiels),
    };
    return populated;
  });
};

export const convertReceptionFetchToLocal = (
  fetch: DechetRecuDto1822[],
  referentiels: WasteReferentiels,
  typeActivite: TypeActiviteDto1819,
  receptionDechetsData: ReceivedWasteFormData | null
): ReceivedWasteStorage[] => {
  return fetch.map(ele => {
    return {
      data: ele,
      errors: generateReceivedWasteErrors(
        ele,
        referentiels,
        typeActivite,
        receptionDechetsData
      ),
    };
  });
};
