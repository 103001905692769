import React, { Dispatch, SetStateAction } from "react";
import FormAppareil from "./FormAppareil";
import CustomModal from "common/presentational/CustomModal";
import cloneDeep from "lodash.clonedeep";
import { AppareilInArray, AppareilInModale } from "./utils/types";
import { CombustibleInArray } from "../BlocCombustibles/utils/types";
import { addOrModifyArray } from "common/declarant/array/utils";
import { InstallationInArray } from "../../BlocInstallation/utils/types";
import {
  FacteurEmissionInArray,
  LegacyEmissions,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../BlocEmissions/utils/types";
import { InArray } from "common/form/utils";

interface AppareilProps {
  appareilModaleOpen: boolean;
  appareilInModale: AppareilInArray | null;
  setAppareilsInArray: Dispatch<SetStateAction<AppareilInArray[]>>;
  appareilsInPage: AppareilInArray[];
  setAppareilInModale: Dispatch<SetStateAction<AppareilInArray | null>>;
  setAppareilModaleOpen: Dispatch<SetStateAction<boolean>>;
  installation: InstallationInArray;
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
}

const AppareilModale = ({
  appareilModaleOpen,
  appareilInModale,
  setAppareilsInArray,
  setAppareilInModale,
  setAppareilModaleOpen,
  installation,
  combustiblesInPage,
  setCombustiblesInPage,
  appareilsInPage,
  facteursInPage,
  setFacteursInPage,
  matieresInPage,
  setMatieresInPage,
  mesuresInPage,
  setMesuresInPage,
}: AppareilProps): React.ReactElement => {
  const onClose = () => {
    setAppareilInModale(null);
    setAppareilModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={appareilModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de appareil de mention de danger."
      onRequestClose={onClose}
    >
      <FormAppareil
        installation={installation}
        closeFunction={onClose}
        forbiddenAppareilName={appareilsInPage
          .filter(appareil => {
            if (appareil.data.nameInstallation !== installation.data.nom) {
              return false;
            }
            if (appareilInModale !== null) {
              return appareil.data.nom !== appareilInModale.data.nom;
            }
            return true;
          })
          .map(appareil => appareil.data.nom || "")}
        onSubmit={values => {
          const newAppareil: AppareilInModale = {
            ...values,
            nameInstallation: installation.data.nom,
          };
          // Refresh combustible linked to the appareil
          if (appareilInModale) {
            const copyCombustiblesInPage = cloneDeep(combustiblesInPage);
            const newCombustibles: CombustibleInArray[] = [];
            copyCombustiblesInPage.forEach(combustible => {
              if (combustible.data.appareils) {
                combustible.data.appareils.forEach((appareil, i) => {
                  if (
                    appareil.data.id === appareilInModale.data.id &&
                    combustible.data.appareils
                  ) {
                    combustible.data.appareils[i] = appareilInModale;
                  }
                });
                newCombustibles.push(combustible);
              }
              return false;
            });
            setCombustiblesInPage(newCombustibles);
          }
          // Refresh emissions linked to the appareil
          if (appareilInModale) {
            const updateAppareilEmission = <T extends LegacyEmissions>(
              emissions: InArray<T>[],
              setEmissions: React.Dispatch<React.SetStateAction<InArray<T>[]>>
            ) => {
              const copyEmissions = cloneDeep(emissions);
              const newEmissions: InArray<T>[] = [];
              copyEmissions.forEach(emission => {
                if (emission.data.appareils) {
                  emission.data.appareils.forEach((appareil, i) => {
                    if (
                      appareil.data.id === appareilInModale.data.id &&
                      emission.data.appareils
                    ) {
                      emission.data.appareils[i] = appareilInModale;
                    }
                  });
                  newEmissions.push(emission);
                }
                return false;
              });
              setEmissions(newEmissions);
            };
            updateAppareilEmission(facteursInPage, setFacteursInPage);
            updateAppareilEmission(mesuresInPage, setMesuresInPage);
            updateAppareilEmission(matieresInPage, setMatieresInPage);
          }
          addOrModifyArray(
            setAppareilsInArray,
            appareilInModale && appareilInModale.data.id,
            newAppareil
          );
          onClose();
        }}
        initialAppareil={appareilInModale && appareilInModale.data}
      />
    </CustomModal>
  );
};

export default AppareilModale;
