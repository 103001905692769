import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  FormikActions,
  FormikErrors,
  FormikProps,
  FormikValues,
} from "libAdapter/Formik/TypesPatternAdaptater";
import { Nullable } from "common/utils/types";
import { computeInitialFormValuesFromValidationSchema } from "./utils";
import { FIELD_FONT_SIZE, SECTION_TITLE_GREY } from "theme";
import FormikFieldToucher from "./formik/FormikFieldToucher";
import _ from "lodash";
import FormikAdapter from "../../libAdapter/Formik/FormikAdapter";

interface DeprecatedCommonFormSingleEntityProps<T extends FormikValues> {
  title: string;
  closeFunction: () => void;

  onSubmit: (values: T, formikActions: FormikActions<FormikValues>) => void;
  preventSubmitWithErrors?: (values: Nullable<T>) => FormikErrors<T>;
  initialEntity: Partial<T>;
  validationSchema: any;
  renderField: (props: FormikProps<Nullable<T>>) => React.ReactNode;
  validate?: (
    values: Nullable<T>
  ) => void | object | Promise<FormikErrors<Nullable<T>>>;
  overrideIsEdit?: (values: Nullable<T>) => boolean;
}

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: FIELD_FONT_SIZE,
    color: SECTION_TITLE_GREY,
    fontWeight: 400,
    marginBottom: "5%",
  },
});

//this component is used to have similar behavior across the different modal having a form for adding an entity :
// it creates an initial values object based on the initialEntity given AND the validation schema (taking fields asked by validation schema, and filling them with the initialEntity value if there is one, and null or boolean if it's not present, based on field type specified by validation schema
// it finally ensurethe differnt modale use the FormikFieldToucher enabling a common touching behavior onLoad (touching the fields if there is an initial entity), and disabling the submit button if there is a displayed error.
/**
 * @deprecated
 * @param closeFunction
 * @param onSubmit
 * @param validationSchema
 * @param renderField
 * @param initialEntity
 * @param title
 * @param validate
 * @param preventSubmitWithErrors
 * @param overrideIsEdit
 * @constructor
 */
function DeprecatedCommonFormSingleEntity<T extends FormikValues>({
  closeFunction,
  onSubmit,
  validationSchema,
  renderField,
  initialEntity,
  title,
  validate,
  preventSubmitWithErrors,
  overrideIsEdit,
}: DeprecatedCommonFormSingleEntityProps<T>): React.ReactElement {
  const classes = useStyles();

  const initialValues = computeInitialFormValuesFromValidationSchema<T>(
    initialEntity,
    validationSchema.fields
  );
  return (
    <>
      <h3 className={classes.title}>{title}</h3>
      <FormikAdapter
        onSubmit={(values: Nullable<T>, formikActions) => {
          if (preventSubmitWithErrors) {
            const additionalErrors = preventSubmitWithErrors(values);
            if (!_.isEqual(additionalErrors, {})) {
              formikActions.setErrors(additionalErrors);
              return;
            }
          }
          // the as coercion is because it should have NO nullable field unless the validation schema isn't done in the right way.
          onSubmit(values as T, formikActions);
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validate={validate}
        render={props => {
          return (
            <FormikFieldToucher
              formikProps={props}
              closeFunction={closeFunction}
              isEdit={
                overrideIsEdit
                  ? overrideIsEdit(initialValues)
                  : Object.keys(initialEntity).length !== 0
              }
              renderField={renderField}
            />
          );
        }}
      />
    </>
  );
}

export default DeprecatedCommonFormSingleEntity;
