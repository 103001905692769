import {
  DechetProduitDto23NowFillingSourceEnum,
  DechetRecuDto23NowFillingSourceEnum,
} from "api/gen/api";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
  generateReceivedPolluant,
} from "./Generators";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../production/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../reception/types";
import { DechetFillingSource, WasteReferentiels, WasteTabData } from "../types";
import _ from "lodash";
import { DataWithErrors } from "common/form/utils";
import { useAlertModale } from "common/modale/hooks";
import { normalizeLabel } from "common/utils/methods";
import { TypeActiviteGlobal21Now } from "../../../../from21/toNow/versionedElements/declarationHooks21Now";
import {
  DeclarationBody2323,
  DeclarationSections2323,
} from "../../versionedElements/declarationHooks2323";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";

export const shouldDisplayProductionArray = (
  productionInfo: ProductedWasteFormData
): boolean => {
  return (
    productionInfo.dangerousWasteOver2000kg ||
    (productionInfo.isEPRTR && productionInfo.atLeastOneFacilityWasteOver2Mkg)
  );
};
export const shouldDisplayReceptionArray = (
  receptionInfo: ReceivedWasteFormData
): boolean => {
  return receptionInfo.isFacilityManagingWaste;
};

//  delete waste from container and return new container and removed index
export function deleteWasteFromContainer<T>(data: T, container: T[]): T[] {
  return container.filter(item => !_.isEqual(data, item));
}

/////////
//  form helpers
/////////
//  parse fetched data
export const parseFetch = (
  declarationBody: DeclarationBody2323,
  referentiels: WasteReferentiels,
  typeActiviteGlobal: TypeActiviteGlobal21Now,
  receptionDechetsData: ReceivedWasteFormData | null
): [
  ProductedWasteFormData,
  ProductedWasteStorage[],
  ReceivedWasteFormData,
  ReceivedWasteStorage[]
] => {
  const declarationSections: DeclarationSections2323 = declarationBody.sections;

  const productionInfo: ProductedWasteFormData = {
    dangerousWasteOver2000kg:
      declarationSections.dechets.production.productionDechetDangereuxSup2000,
    atLeastOneFacilityWasteOver2Mkg: !!declarationSections.dechets.production
      .productionDechetNonDangereuxSup2000,
    isEPRTR: typeActiviteGlobal.estEPRTR,
  };
  const productionData: ProductedWasteStorage[] = convertProductionFetchToLocal(
    declarationSections.dechets.production.productionDechet,
    referentiels
  );
  const receptionInfo: ReceivedWasteFormData = {
    isFacilityManagingWaste: typeActiviteGlobal.recepDechet,
    isISDND: typeActiviteGlobal.isdnd || false,
    isISDD: typeActiviteGlobal.isdd || false,
    isISDI: typeActiviteGlobal.isdi || false,
    remainingCapacity: declarationSections.dechets.reception.capaciteRestante,
    doesFacilityHaveCasiersPlatre: !!declarationSections.dechets.reception
      .aCasiersPlatre,
    doesFacilityHaveAsbestosCases: !!declarationSections.dechets.reception
      .aCasiersAmiante,
  };
  const receptionData: ReceivedWasteStorage[] = convertReceptionFetchToLocal(
    declarationSections.dechets.reception.receptionDechets,
    referentiels,
    typeActiviteGlobal,
    receptionDechetsData || receptionInfo
  );
  return [productionInfo, productionData, receptionInfo, receptionData];
};

function mergeExternalAndImportedDechet<
  W extends { fillingSource: DechetFillingSource }
>(
  importedDechet: DataWithErrors<W>[],
  tempDechet: DataWithErrors<W>[]
): DataWithErrors<W>[] {
  tempDechet
    .filter(
      waste =>
        waste.data.fillingSource &&
        (waste.data.fillingSource ===
          DechetProduitDto23NowFillingSourceEnum.EXTERNAL_INPUT ||
          waste.data.fillingSource ===
            DechetRecuDto23NowFillingSourceEnum.EXTERNAL_INPUT)
    )
    .map(externalWaste => importedDechet.push(externalWaste));
  return importedDechet;
}
//  import waste data in collection
export function useImportWaste<
  T extends { fillingSource: DechetFillingSource },
  A
>(
  referentiels: WasteReferentiels,
  receptionDechetsData: ReceivedWasteFormData | null,
  generator: (
    t: A,
    props: WasteReferentiels,
    typeActivite: TypeActiviteGlobal21Now,
    receptionDechetsData: ReceivedWasteFormData | null
  ) => DataWithErrors<T>,
  tempWastes: DataWithErrors<T>[],
  setWastes: Dispatch<SetStateAction<DataWithErrors<T>[]>>,
  onImport: (() => void) | null
): (w: A[], message: string | null) => void {
  const openModale = useAlertModale();
  const typeActiviteGlobal = useCorrectYearTypeActiviteGlobal20Now();

  return useCallback(
    (w: A[], message: string | null) => {
      const imported = w.map(waste => {
        return generator(
          waste,
          referentiels,
          typeActiviteGlobal,
          receptionDechetsData
        );
      });
      onImport && onImport();
      setWastes(mergeExternalAndImportedDechet(imported, tempWastes));

      let errorMessage: string | null = null;

      if (
        imported.some(imported => {
          return !_.isEmpty(imported.errors);
        })
      ) {
        errorMessage =
          "Des erreurs ont été détectées dans votre fichier importé. Veuillez corriger les éléments encadrés en rouge dans le tableau et importer à nouveau.";
      }
      if (!message && errorMessage) {
        openModale(errorMessage);
      }
      if (message && !errorMessage) {
        openModale(message);
      }
      if (message && errorMessage) {
        openModale(message + "\n" + errorMessage);
      }
    },
    [
      tempWastes,
      generator,
      onImport,
      setWastes,
      openModale,
      referentiels,
      typeActiviteGlobal,
      receptionDechetsData,
    ]
  );
}
//  compute child visible data according to form values
export const productionDataComputed = (
  formInfo: ProductedWasteFormData,
  container: ProductedWasteStorage[]
): WasteTabData<ProductedWasteFormData, ProductedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayProductionArray(formInfo) ? container : [],
  };
};
export const receptionDataComputed = (
  formInfo: ReceivedWasteFormData,
  container: ReceivedWasteStorage[],
  productionInfo: ProductedWasteFormData,
  productionContainer: ProductedWasteStorage[]
): WasteTabData<ReceivedWasteFormData, ReceivedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayReceptionArray(formInfo)
      ? shouldDisplayProductionArray(productionInfo)
        ? [...container, ...generateReceivedPolluant(productionContainer)]
        : container
      : [],
  };
};

// Compute boolean SSD from gabarit production and reception
export const parseOuiNonToBoolean = (userInput: string): boolean | null => {
  if (normalizeLabel(userInput) === "oui") {
    return true;
  }
  if (normalizeLabel(userInput) === "non") {
    return false;
  }

  return null;
};

export const removeFrenchPostalCodeWhitespace = (
  postalCode: string | null,
  isFrenchPostalCode: boolean | null
): string | null => {
  if (!postalCode || !isFrenchPostalCode) {
    return postalCode;
  }
  return postalCode.replace(/\s+/g, "");
};
