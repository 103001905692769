import {
  FetchedReferentials,
  ReferentialKeyType,
  ReferentialValueType,
} from "./types";
import {
  buildReferentialFetchers,
  ReferentialControllers,
} from "./referentialsFetchers";

export const fetchReferentials = <Subset extends ReferentialKeyType>(
  yearReferentialsToFetch: number,
  listReferentialsToFetch: Subset[],
  controllers: ReferentialControllers,
  onException: (excp: ExceptionInformation) => Promise<void>
): Promise<Partial<FetchedReferentials<Subset>>> => {
  const referentialFetchers = buildReferentialFetchers(
    controllers,
    yearReferentialsToFetch
  );

  const fetched: Partial<FetchedReferentials<Subset>> = {};

  const fetchAll = async () => {
    let excp: ExceptionInformation | undefined = undefined;
    await Promise.all(
      listReferentialsToFetch.map(async (key: Subset) => {
        try {
          // the as is correct because we know the key passed will be one of the subset, and the value returned by the
          // referentialFetcher will therefore ALSO be a dto going with the subset  key
          fetched[key] = (await referentialFetchers[
            key
          ]()) as ReferentialValueType<Subset>;
        } catch (e) {
          excp = e;
          // Note : here, fetched does not contain any value associated with key.
          // Thus, later when calling setReferentials, the cache value associated
          // associated with key will not be changed, so in this case stay at null,
          // since we don't start fetching if it already has a correct value.
          // It is this null value that will be returned by the hook ;
          // the hook's caller is responsible to check against it.
        }
      })
    );

    if (excp) {
      await onException(excp);
    }

    return fetched;
  };

  return fetchAll();
};
