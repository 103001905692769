import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleCov from "./FormSingleCov";
import { CovInArray, CovInModale } from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { addOrModifyArray } from "common/declarant/array/utils";

interface CovProps {
  isOpen: boolean;
  covInModal: CovInArray | null;
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  setCovInModale: Dispatch<SetStateAction<CovInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  initialInstallations: InstallationInArray[];
}

const CovModale = ({
  isOpen,
  covInModal,
  setCovsInPage,
  setCovInModale,
  setModaleOpen,
  initialInstallations,
}: CovProps): React.ReactElement => {
  const onClose = () => {
    setCovInModale(null);
    setModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout de cov de mention de danger."
      onRequestClose={onClose}
    >
      <FormSingleCov
        installationsInPage={initialInstallations}
        closeFunction={onClose}
        onSubmit={(values, formikBag) => {
          const newCov: CovInModale = {
            danger: values.danger,
            installations: values.installations,
            flux: values.flux,
          };
          addOrModifyArray(
            setCovsInPage,
            covInModal && covInModal.data.id,
            newCov
          );
          onClose();
        }}
        initialCov={
          covInModal !== null
            ? covInModal.data
            : { danger: null, installations: null, flux: null }
        }
        isEdit={covInModal !== null}
      />
    </CustomModal>
  );
};

export default CovModale;
