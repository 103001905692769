import { makeStyles } from "@material-ui/styles";
import {
  DeclarationStateDtoStateEnum,
  QuotasEmissionsVerificationDto20NowConditionDispenseEnum,
  QuotasInstallationDto20Now,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import {
  useBooleanCheckBoxGenerator,
  useDateFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import FormikBlocInUserContext from "common/formikBloc/FormikBlocInUserContext";
import { useFormikBloc } from "common/formikBloc/utils";
import { useConfirmationModale } from "common/modale/hooks";
import { PATH_QUOTAS_EMISSIONS_VERIFICATION_BLOC } from "common/path/path20Now";
import Row from "common/presentational/Row";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { WorkflowTarget } from "common/utils/types";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import React, { Fragment } from "react";
import { FILE_BUTTONS_STYLE, LIGHT_PINK } from "theme";
import DownloadFilesButton from "../../common/DownloadFilesButton";
import {
  VERIFICATION_BLOC_NOT_VERIFIED_YET,
  VERIFICATION_BLOC_PENDING_VERIFICATION,
  VERIFICATION_BLOC_VERIFIED,
  VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION,
  VERIFIED_ALERT_MESSAGE,
} from "../../helpers";
import { getInstallationOptions } from "../BlocEmissions/converter";
import { blocValuesToDto, dtoToBlocValues } from "./converter";
import { useVerificationErrors } from "./hook";
import {
  exemptionConditionLabels,
  exemptionConditionOptions,
  isReportUnsatisfying,
  isSatisfyingWithRequests,
  organismesLabels,
  organismesValues,
  satisfyingDeclarationReasonLabels,
  satisfyingDeclarationReasonOptions,
  unsatisfyingDeclarationReasonLabels,
  unsatisfyingDeclarationReasonOptions,
  verificationReportConclusionLabels,
  verificationReportConclusionOptions,
} from "./selectPossibleValues";
import {
  BlocVerificationFormValues,
  fieldBlocMatcher,
  fieldMatcher,
} from "./types";
import { additionalFieldValidation, validationSchema } from "./validation";
import {
  useDeclaration2020,
  useDeclarationStateHandlers2020,
} from "../../../versionedElements/declarationHooks2020";
import RapportVerificationLoader from "../../common/BlocVerification/RapportVerificationLoader";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

export interface BlocVerificationProps {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
  disabledMessage: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: "bold",
    fontSize: "22px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  tooltipLink: {
    color: LIGHT_PINK,
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "12px",
  },
});

enum BlocVerifState {
  IN_PROGRESS,
  COMPLETED,
  IGNORED,
  UNKNOWN,
}

const getInaccessibleVerificationMessage = (
  blocVerifStatus: BlocVerifState
): string => {
  switch (blocVerifStatus) {
    case BlocVerifState.IGNORED:
      return VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION;
    case BlocVerifState.COMPLETED:
      return VERIFICATION_BLOC_VERIFIED;
    case BlocVerifState.IN_PROGRESS:
      return VERIFICATION_BLOC_PENDING_VERIFICATION;
    default:
      return VERIFICATION_BLOC_NOT_VERIFIED_YET;
  }
};

const BlocVerification = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const user = useUserData();
  const formProps = useFormikBloc<BlocVerificationFormValues>();
  const {
    updateQuotaEmissionsVerif,
    submitVerif,
  } = useDeclarationStateHandlers2020();
  const {
    hasValidateOnce,
    triggerValidation,
    errors,
    hasError,
  } = useVerificationErrors();

  const [isSaveSpinnerAvailable, triggerSaveSpinnerVisible] = useSpinnerState<
    boolean
  >();
  const [
    isValidateSpinnerAvailable,
    triggerValidateSpinnerVisible,
  ] = useSpinnerState<void>();

  const openModale = useConfirmationModale();

  // We are using data from DTO in this bloc because it is available to another profile
  // so we don't need to synchronize data from other blocs
  const typeActiviteQuotas = declaration.body.typeActivite;

  const installations: QuotasInstallationDto20Now[] =
    typeActiviteQuotas.installations;
  const nimOptions = getInstallationOptions(installations);
  const initialValues: BlocVerificationFormValues = dtoToBlocValues(
    declaration.body.sections.quotas.emissions.blocVerification,
    installations
  );

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-quotas-verification",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const quotasState = declaration.body.workflowStatus.quotaEmissions.state;

  const isVerificateur = user.isPrestataireEmissionsForEtablissement(
    declaration.etablissementCode
  );

  let blocVerifStatus: BlocVerifState;
  switch (quotasState) {
    case DeclarationStateDtoStateEnum.VERIFYING:
      blocVerifStatus = BlocVerifState.IN_PROGRESS;
      break;
    case DeclarationStateDtoStateEnum.NONE:
    case DeclarationStateDtoStateEnum.CREATED:
    case DeclarationStateDtoStateEnum.STARTED:
    case DeclarationStateDtoStateEnum.IN_REVIEW:
      blocVerifStatus = BlocVerifState.UNKNOWN;
      break;
    case DeclarationStateDtoStateEnum.FORCED:
      blocVerifStatus = BlocVerifState.IGNORED;
      break;
    case DeclarationStateDtoStateEnum.RECALLED:
    case DeclarationStateDtoStateEnum.SUBMITTED:
    case DeclarationStateDtoStateEnum.VALIDATED:
    case DeclarationStateDtoStateEnum.VERIFIED:
      blocVerifStatus = BlocVerifState.COMPLETED;
      break;
    default:
      throw new ShouldNotHappen(quotasState);
  }

  return (
    <FormikBlocInUserContext
      enableReinitialize={true}
      formikRef={formProps.formikRef}
      hasChanges={formProps.hasChanges}
      setHasChanges={formProps.setHasChanges}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title="ESPACE VERIFICATION - EMISSIONS"
      additionalFieldValidation={additionalFieldValidation}
      additionalValidationAllowed={() =>
        blocVerifStatus === BlocVerifState.IN_PROGRESS
      }
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      renderContent={({ values }, shouldDisabledFields) => (
        <>
          {!isVerificateur && (
            <div className={classes.disabledMessage}>
              <span>{getInaccessibleVerificationMessage(blocVerifStatus)}</span>
            </div>
          )}

          <div>
            <DownloadFilesButton />
            <GreyFilesDownloader
              text="TÉLÉCHARGER UN MODÈLE RAPPORT DE VÉRIFICATION - EMISSIONS"
              fileName="RapportVerificationEmissions.xls"
              additionalClassname={classes.fileButtons}
              isDisabled={shouldDisabledFields}
            />

            <FieldArray
              name={fieldBlocMatcher.verifications}
              render={() =>
                values.verifications.map((propsValues, index) => {
                  return (
                    <Fragment key={index}>
                      <span className={classes.sectionTitle}>
                        NIM à remplir :{" "}
                        {index < nimOptions.length ? nimOptions[index].nim : ""}
                        <CommentButton
                          // Here the word "verifications" of the path comes from the back Dto and not from type "BlocVerificationFormValues" so we don't use fieldBlocMatcher
                          path={`${PATH_QUOTAS_EMISSIONS_VERIFICATION_BLOC}/verifications/${propsValues.nimDynId}`}
                        />
                      </span>

                      <RapportVerificationLoader
                        installationId={nimOptions[index].id}
                        shouldDisableFields={shouldDisabledFields}
                        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
                      />
                      <WrappedChoiceSelect
                        label="Nom de l'organisme vérificateur *"
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.organisme}`}
                        isMulti={false}
                        options={organismesValues}
                        computeLabel={organisme => organismesLabels[organisme]}
                        commonProps={commonProps}
                        disabled={shouldDisabledFields}
                      />

                      <TextField
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.monitoringPlanReference}`}
                        label="Référence du dernier plan de surveillance approuvé ayant servi à la vérification *"
                        disabled={shouldDisabledFields}
                      />

                      <CheckBox
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.visitExemption}`}
                        label="Dispense de visite de site"
                        disabled={shouldDisabledFields}
                      />

                      {propsValues.dispenseVisiteSite && (
                        <>
                          <WrappedChoiceSelect
                            name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.conditionExemption}`}
                            label="Condition de la dispense *"
                            isMulti={false}
                            options={exemptionConditionOptions}
                            computeLabel={exemption =>
                              exemptionConditionLabels[exemption]
                            }
                            disabled={shouldDisabledFields}
                            tooltipContent={
                              <a
                                className={classes.tooltipLink}
                                href="https://ec.europa.eu/clima/sites/clima/files/ets/monitoring/docs/kgn_5_site_visits_en.pdf"
                              >
                                Guidance de la Commission sur les dispenses de
                                visite de site II.5
                              </a>
                            }
                            commonProps={commonProps}
                          />
                          {propsValues.conditionDispense ===
                            QuotasEmissionsVerificationDto20NowConditionDispenseEnum.AUTRE && (
                            <TextField
                              name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.detailExemption}`}
                              label="Préciser autre *"
                              disabled={shouldDisabledFields}
                            />
                          )}
                        </>
                      )}

                      <DateField
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.lastVisitDate}`}
                        label="Date de la dernière visite du site *"
                        disabled={shouldDisabledFields}
                      />

                      <CheckBox
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.improvementReport}`}
                        label="L’exploitant doit fournir un rapport d'amélioration au 30 juin à l'autorité compétente suite aux remarques du vérificateur"
                        disabled={shouldDisabledFields}
                      />

                      <WrappedChoiceSelect
                        name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.verificationReportConclusion}`}
                        label="Conclusion du rapport de vérification *"
                        isMulti={false}
                        options={verificationReportConclusionOptions}
                        computeLabel={verification =>
                          verificationReportConclusionLabels[verification]
                        }
                        disabled={shouldDisabledFields}
                        commonProps={commonProps}
                      />

                      {isSatisfyingWithRequests(propsValues.conclusion) && (
                        <WrappedChoiceSelect
                          name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.satisfyingDeclarationReason}`}
                          label="Motifs de déclaration reconnue satisfaisante avec remarques *"
                          options={satisfyingDeclarationReasonOptions}
                          computeLabel={reason =>
                            satisfyingDeclarationReasonLabels[reason]
                          }
                          disabled={shouldDisabledFields}
                          isMulti
                          commonProps={commonProps}
                        />
                      )}

                      {isReportUnsatisfying(propsValues.conclusion) && (
                        <WrappedChoiceSelect
                          name={`${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.unsatisfyingDeclarationReason}`}
                          label="Motifs de déclaration reconnue non satisfaisante *"
                          options={unsatisfyingDeclarationReasonOptions}
                          computeLabel={reason =>
                            unsatisfyingDeclarationReasonLabels[reason]
                          }
                          disabled={shouldDisabledFields}
                          isMulti
                          commonProps={commonProps}
                        />
                      )}

                      {index < nimOptions.length - 1 && <Row height={"45px"} />}
                    </Fragment>
                  );
                })
              }
            />

            {hasError && hasValidateOnce && (
              <ViolationProcessor errors={errors} warnings={[]} />
            )}
          </div>
        </>
      )}
      // Bloc reserved for PRESTATAIRE profiles
      isAvailableToDeclarant={false}
      isAvailableToPrestataireEmissions={true}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      declarationEtablissement={declaration.etablissementCode}
      // Special worflow action for this bloc, must define specific callbacks
      saveAction={values => {
        return triggerSaveSpinnerVisible(
          updateQuotaEmissionsVerif(blocValuesToDto(values, declaration))
        );
      }}
      isSaveSpinnerVisible={isSaveSpinnerAvailable}
      validateAction={values => {
        openModale(VERIFIED_ALERT_MESSAGE, () => {
          const validate = async () => {
            await updateQuotaEmissionsVerif(
              blocValuesToDto(values, declaration)
            );
            await submitVerif([WorkflowTarget.QUOTA_EMISSIONS]);
          };
          triggerValidateSpinnerVisible(triggerValidation(validate()));
        });
      }}
      isValidateSpinnerVisible={isValidateSpinnerAvailable}
      // Edit button should never be displayed. Instead, other button will be disabled when actions are no longer possible
      isBlocValidated={
        blocVerifStatus === BlocVerifState.COMPLETED ||
        blocVerifStatus === BlocVerifState.IGNORED
      }
      isEditSpinnerVisible={false}
    />
  );
};

export default BlocVerification;
