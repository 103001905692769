import { TechniqueQuotasValues } from "./utils";
import { InstallationInArray } from "./Installations/types";
import { singleInstallationToInstallationDto } from "./Installations/converter";
import { TypeActiviteQuota23Now } from "../../versionedElements/declarationHooks23Now";

export const computeTechQuotaInitialValues = (
  typeActiviteQuotaDto: TypeActiviteQuota23Now
): TechniqueQuotasValues => {
  const { installations, ...commonActiviteField } = typeActiviteQuotaDto;

  return commonActiviteField;
};

export const createTechniqueQuotaDto = (
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
): TypeActiviteQuota23Now => {
  return {
    ...values,
    installations: installationsInArray.map(
      singleInstallationToInstallationDto
    ),
  };
};
