import {
  AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum,
  AirFugitivesEmissionMesureDto24NowMesureTypeEnum,
} from "api/gen";

export const unitConcentrationMoyLabels: {
  [key in AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum]: string;
} = {
  G_NM3: "g/Nm\u00B3",
  MG_NM3: "mg/Nm\u00B3",
  UG_NM3: "\u00B5g/Nm\u00B3",
  NG_NM3: "ng/Nm\u00B3",
  PG_NM3: "pg/Nm\u00B3",
};

export const arrayFugitivesConcentrationUnits = Object.keys(
  unitConcentrationMoyLabels
) as AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum[];

export const arrayFugitivesTypeMesureLabel: {
  [key in AirFugitivesEmissionMesureDto24NowMesureTypeEnum]: string;
} = {
  CANALISEES: "Mesure des émissions canalisées",
  DIFFUSES: "Mesure des émissions diffuses",
  FUGITIVES: "Mesure des émissions fugitives",
};
export const arrayFugitivesTypeMesure: AirFugitivesEmissionMesureDto24NowMesureTypeEnum[] = Object.keys(
  arrayFugitivesTypeMesureLabel
) as AirFugitivesEmissionMesureDto24NowMesureTypeEnum[];
