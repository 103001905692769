import { InArray, InModale } from "common/form/utils";
import { MentionDanger } from "./selectPossibleValues";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";
import { AirFugitivesFluxDto } from "api/gen";

interface LegacyCov extends Omit<AirFugitivesFluxDto, "id"> {
  substance: MentionDanger;
}

export type CovInModale = InModale<LegacyCov>;

export type CovInArray = InArray<LegacyCov>;

export const fieldMatcher = fieldMatcherBuilder<CovInModale>().build({
  substance: "substance",
  procedes: "procedes",
  flux: "flux",
});
