import React from "react";
import { VersionedBlocInContextProps } from "./types";
import BlocInUserContext from "./BlocInUserContext";
import {
  useButtonStateWithRedirection,
  useSpinnerState,
} from "common/button/loadingAndRedirectionHelpers";
import { Redirect } from "react-router";
import { useConfirmationModale } from "common/modale/hooks";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { isPathCommented } from "../comment/commentUtils";

/**
 * Bloc using declaration to check its current validation state and to perform validation,
 * save and edit actions
 *
 * use case : should be use in every page
 *
 * @param path: path to check validation state and validate or edit this bloc (should be json path of the Dto handle in the bloc in the declaration)
 * @param updateHandle: changes to perform in the declaration on save or validate actions
 * @param cancelAction: action triggered when user
 * @param renderContent: content to render for current user rights (from BlocInUserContext) and current validation state
 * @param declarationState: delcaration state (may be either global state or quotas state - if not given, global state will be used)
 * @param editValidationMessage: if provided, display a confirmation modale before edit action
 * @param isAvailableToDeclarant:    are declarant allowed to use this bloc (default: true)
 * @param isAvailableToPrestataire:  are prestataire allowed to use this bloc (default: false)
 * @param getDeclarationState: function to get the default declaration sate (called if no declarationState is given)
 * @param useDeclaration: version of the useDeclaration hook used to get the declaration for the years the bloc is used in
 * @param useDeclarationHelpers: version of the useDeclarationHelpers hook used to get the declaration helpers for the years the bloc is used in
 * @param useBasicDeclarationHandlers: version of the useBasicDeclarationHandlers hook used to get the declaration basic handlers for the years the bloc is used in
 * @param useDeclarationErrorAndWarnings: hook used to get the errors and warnings for the year the bloc is used in
 * @param displayErrorAndWarning: function returning a JSX element to display the errors and warnings for the year the bloc is used in
 */
const GenericBlocFullContext = <
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationConstraintViolationDto extends object
>({
  //  DummyBlocGlobalStyling
  title,
  validationTitle,
  areButtonsInside,
  additionalStyle,
  additionalClassname,
  //  DummyBlocValidationProps
  hasModification,
  isValidateButtonAvailable,
  //  additional
  path,
  updateHandler,
  cancelAction,
  renderContent,
  declarationState,
  editValidationMessage,
  isAvailableToDeclarant,
  isAvailableToPrestataire,
  validationMessage,
  getDeclarationState,
  useDeclaration,
  useDeclarationHelpers,
  useBasicDeclarationHandlers,
  useDeclarationErrorAndWarnings,
  useComments,
  displayErrorAndWarning,
  areGlobalCommentsAllowed,
  icon,
  isStrictPathAlert,
}: VersionedBlocInContextProps<
  VersionedDeclarationDto,
  VersionedDeclarationConstraintViolationDto
>): React.ReactElement => {
  const declaration = useDeclaration();
  const {
    pathToDashboard,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers();
  const { validate, update, cancelValidate } = useBasicDeclarationHandlers();
  const openConfirmationModale = useConfirmationModale();

  const [
    isValidateSpinnerVisible,
    triggerValidateSpinner,
    shouldRedirectToDashboard,
    isCurrentlyValidated,
  ] = useButtonStateWithRedirection(path, isPathValidatedInDeclaration);
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState();

  const {
    warnings,
    hasUncommentedWarning,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings(
    path,
    isStrictPathAlert === true || false,
    isPathValidatedInDeclaration(path)
  );

  const commentsContext = useComments();

  const hasComments = isPathCommented(commentsContext.comments, path, false);

  if (
    areButtonsInside !== undefined &&
    areButtonsInside === false &&
    shouldRedirectToDashboard
  ) {
    return <Redirect to={pathToDashboard} />;
  }

  return (
    <BlocInUserContext
      //  DummyBlocGlobalStyling
      title={title}
      validationTitle={validationTitle}
      areButtonsInside={areButtonsInside}
      additionalStyle={additionalStyle}
      additionalClassname={additionalClassname}
      //  DummyBlocActionProps
      validateAction={() =>
        triggerErrorAndWarningDisplay(
          triggerValidateSpinner(validate(updateHandler, path))
        )
      }
      validationMessage={validationMessage}
      saveAction={() => triggerSaveSpinner(update(updateHandler))}
      editAction={async () => {
        if (editValidationMessage) {
          openConfirmationModale(editValidationMessage, () =>
            triggerEditSpinner(cancelValidate(path))
          );
        } else {
          triggerEditSpinner(cancelValidate(path));
        }
      }}
      cancelAction={cancelAction}
      //  DummyBlocValidationProps
      isValidated={isCurrentlyValidated}
      hasModification={hasModification}
      isValidateButtonAvailable={
        isValidateButtonAvailable &&
        ((!hasUncommentedWarning && !hasError) || hasModification)
      }
      renderContent={shouldDisabledFields => {
        return (
          <>
            {renderContent(shouldDisabledFields || isCurrentlyValidated)}
            {(errors.length > 0 || warnings.length > 0) &&
              displayErrorAndWarning(errors, warnings)}
          </>
        );
      }}
      declarationState={
        declarationState !== undefined
          ? declarationState
          : getDeclarationState(declaration)
      }
      declarationEtablissement={declaration.etablissementCode}
      isAvailableToDeclarant={isAvailableToDeclarant}
      isAvailableToPrestataire={isAvailableToPrestataire}
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed || false}
      commentAction={e => {
        e.preventDefault();
        commentsContext.openModal(path, false);
      }}
      hasComments={hasComments}
      icon={icon}
    />
  );
};

export default GenericBlocFullContext;
