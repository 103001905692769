import { getOptionsInstallations } from "../blocInstallation/utils/selectPossibleValues";
import { InArray } from "common/form/utils";
import {
  CleanOptionProps,
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { Information } from "../information/utils/types";
import { LegacySubInstallationElement } from "./types";
import { isEqual } from "lodash";
import { InstallationInArray } from "../blocInstallation/utils/types";
import {
  ch4AndBiogazPossibleValues,
  IsMesure,
} from "../listInstallation/blocCombustibles/utils/selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { AppareilInArray } from "../listInstallation/blocAppareils/utils/types";

export const filterByInstallation = <T extends LegacySubInstallationElement>(
  contentToFilter: InArray<T>[],
  installationName: string
): InArray<T>[] => {
  return (
    contentToFilter.filter(content => {
      return content.data.nameInstallation === installationName;
    }) || []
  );
};

export const getAppareilsLabels = (appareils: OptionProps[] | null) => {
  if (!appareils) {
    return [];
  }
  return appareils.map(appareil => {
    return appareil.label;
  });
};

export const getAppareilsToOptionProps = (
  appareils: string[],
  appareilsReferential: AppareilInArray[]
) => {
  const appareilsObjects: OptionPropsWithObject<AppareilInArray, string>[] = [];
  //TODO https://github.com/Polyconseil/mtes-gerep/pull/811#discussion_r356136417
  appareils.forEach(appareil => {
    const appareilsObject = findElementMatchingTemplate(
      { data: { nom: appareil } },
      appareilsReferential
    );
    if (appareilsObject !== null) {
      appareilsObjects.push({
        value: appareil,
        label: appareil,
        object: appareilsObject,
      });
    }
  });
  return appareilsObjects;
};

export const mesure = "Mesure";
export const calcul = "Calcul";

export const getMesureOrCalcul = (
  isMesure: boolean | null
): OptionPropsWithObject<IsMesure> | null => {
  if (isMesure === null) {
    return null;
  }

  const toReturn = ch4AndBiogazPossibleValues.find(
    test => test.object.isMesure === isMesure
  );
  return toReturn || null;
};

const checkErrors = (
  array: CleanOptionProps[],
  installation: InstallationInArray
) => {
  const hasNoError = array.find(element => {
    if (installation.data.type) {
      return element.label === installation.data.type.label;
    }
    return false;
  });
  if (hasNoError) {
    return {};
  } else {
    return {
      type: "error",
    };
  }
};

export const computeErrors = (
  installation: InstallationInArray,
  informationsInPage: Information
) => {
  if (informationsInPage.lcp && informationsInPage.wiCoWi) {
    return {};
  } else if (informationsInPage.lcp && !informationsInPage.wiCoWi) {
    return checkErrors(getOptionsInstallations("lcp"), installation);
  } else if (!informationsInPage.lcp && informationsInPage.wiCoWi) {
    return checkErrors(getOptionsInstallations("wi"), installation);
  } else {
    return checkErrors(getOptionsInstallations("noLcpNoWi"), installation);
  }
};

//Compare only data because i dont want to compare errors
export const hasEqualDisplayed = (
  initialInstallations: InstallationInArray[],
  installationsInPage: InstallationInArray[]
) => {
  if (initialInstallations.length !== installationsInPage.length) {
    return false;
  }
  for (let i = 0; i < initialInstallations.length; i++) {
    if (!isEqual(initialInstallations[i].data, installationsInPage[i].data)) {
      return false;
    }
  }
  return true;
};
