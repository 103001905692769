import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Collapsible from "common/presentational/Collapsible";
import Tabs from "common/tabs";
import Row from "common/presentational/Row";
import {
  ReferenceCombustibleDto,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
  ReferencePolluantDefaultUniteItemDto,
} from "api/gen";
import isEqual from "lodash.isequal";
import { createAirCombustionEmissionDto } from "./BlocEmissions/utils/utils";
import { createAirCombustionsCombustibleDto } from "./BlocCombustibles/utils/utils";
import { createAirCombustionsAppareilDto } from "./BlocAppareils/utils/utils";
import BlocEmissions from "./BlocEmissions";
import BlocCombustibles from "./BlocCombustibles";
import BlocAppareils from "./BlocAppareils";
import { InstallationInArray } from "../BlocInstallation/utils/types";
import { AppareilInArray } from "./BlocAppareils/utils/types";
import { CombustibleInArray } from "./BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./BlocEmissions/utils/types";
import { ReferentialSinglePolluants } from "../utils/types";
import BlocFullContext24Now from "../../../versionedElements/BlocFullContext24Now";
import { Declaration24Now } from "../../../versionedElements/declarationHooks24Now";
import dangerImg from "icons/danger.svg";
import { useDeclarationErrorAndWarnings23Now } from "pages/CompanySpace/from23/toNow/versionedElements/errorAndWarningDisplayer23Now/utils";
import {
  PATH_AIR_COMBUSTION_CONTENT_APPAREILS,
  PATH_AIR_COMBUSTION_CONTENT_COMBUSTIBLES,
  PATH_AIR_COMBUSTION_CONTENT_EMISSIONS,
} from "common/path/path18Now";
import { CombustionAlerts } from "./utils/types";
import {
  filterAlertByPathAndIds,
  shouldDisplayWarningTab,
} from "./utils/utils";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
  warningIcon: {
    width: "10%",
    marginRight: "20px",
  },
});

interface ListInstallationProps {
  installationsList: InstallationInArray[];
  validationContentPath: string;
  initialAppareils: AppareilInArray[];
  appareilsInPage: AppareilInArray[];
  setAppareilsInPage: React.Dispatch<React.SetStateAction<AppareilInArray[]>>;
  initialCombustibles: CombustibleInArray[];
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  initialFacteurs: FacteurEmissionInArray[];
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  initialMesures: MesureEmissionInArray[];
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  initialMatieres: MatiereEmissionInArray[];
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  combustibleReferential: ReferenceCombustibleDto;
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
  referentialSinglePolluants: ReferentialSinglePolluants;
  substancesAir: ReferenceItemPolluantDto[];
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElemenAutreUuid: string;
  polluantDefaultUniteMap: Map<number, ReferencePolluantDefaultUniteItemDto>;
}

const ListInstallation = ({
  installationsList,
  validationContentPath,
  initialAppareils,
  appareilsInPage,
  setAppareilsInPage,
  initialCombustibles,
  combustiblesInPage,
  setCombustiblesInPage,
  initialFacteurs,
  facteursInPage,
  setFacteursInPage,
  initialMesures,
  mesuresInPage,
  setMesuresInPage,
  initialMatieres,
  matieresInPage,
  setMatieresInPage,
  combustibleReferential,
  isTorchereBiogaz,
  isValorisationBiogaz,
  substancesAir,
  referentialSinglePolluants,
  polluantElementMap,
  referentialElemenAutreUuid,
  polluantDefaultUniteMap,
}: ListInstallationProps): React.ReactElement => {
  const classes = useStyles();
  const [isValidated, setIsValidated] = useState(false);

  const updateHandler = (declaration: Declaration24Now) => {
    setIsValidated(true);
    declaration.body.sections.air.combustion.content.emissions = createAirCombustionEmissionDto(
      [facteursInPage, mesuresInPage, matieresInPage]
    );
    declaration.body.sections.air.combustion.content.combustibles = createAirCombustionsCombustibleDto(
      combustiblesInPage
    );
    declaration.body.sections.air.combustion.content.appareils = createAirCombustionsAppareilDto(
      appareilsInPage
    );
    return declaration;
  };

  const { warnings, errors } = useDeclarationErrorAndWarnings23Now(
    validationContentPath,
    false,
    true
  );

  const mapAlertsByInstallation: Map<string, CombustionAlerts> = new Map([]);
  installationsList.map(singleInstallation => {
    const installationName = singleInstallation.data.nom;

    const appareilsIds = appareilsInPage
      .filter(appareil => appareil.data.nameInstallation === installationName)
      .map(appareil => appareil.data.id);

    const combustiblesIds = combustiblesInPage
      .filter(
        combustible => combustible.data.nameInstallation === installationName
      )
      .map(combustible => combustible.data.id);

    const facteursIds = facteursInPage
      .filter(facteur => facteur.data.nameInstallation === installationName)
      .map(facteur => facteur.data.id);
    const mesuresIds = mesuresInPage
      .filter(mesure => mesure.data.nameInstallation === installationName)
      .map(mesure => mesure.data.id);
    const matieresIds = matieresInPage
      .filter(matiere => matiere.data.nameInstallation === installationName)
      .map(matiere => matiere.data.id);

    const installationAlerts: CombustionAlerts = {
      appareilErrors: filterAlertByPathAndIds(
        errors,
        PATH_AIR_COMBUSTION_CONTENT_APPAREILS,
        appareilsIds
      ),
      appareilWarnings: filterAlertByPathAndIds(
        warnings,
        PATH_AIR_COMBUSTION_CONTENT_APPAREILS,
        appareilsIds
      ),
      combustibleErrors: filterAlertByPathAndIds(
        errors,
        PATH_AIR_COMBUSTION_CONTENT_COMBUSTIBLES,
        combustiblesIds
      ),
      combustibleWarnings: filterAlertByPathAndIds(
        warnings,
        PATH_AIR_COMBUSTION_CONTENT_COMBUSTIBLES,
        combustiblesIds
      ),
      emissionErrors: filterAlertByPathAndIds(
        errors,
        PATH_AIR_COMBUSTION_CONTENT_EMISSIONS,
        [...facteursIds, ...mesuresIds, ...matieresIds]
      ),
      emissionWarnings: filterAlertByPathAndIds(
        warnings,
        PATH_AIR_COMBUSTION_CONTENT_EMISSIONS,
        [...facteursIds, ...mesuresIds, ...matieresIds]
      ),
    };
    return mapAlertsByInstallation.set(
      singleInstallation.data.id,
      installationAlerts
    );
  });

  return (
    <div className={classes.container}>
      <BlocFullContext24Now
        title={""}
        hasModification={
          !isEqual(initialAppareils, appareilsInPage) ||
          !isEqual(initialCombustibles, combustiblesInPage) ||
          !isEqual(initialFacteurs, facteursInPage) ||
          !isEqual(initialMesures, mesuresInPage) ||
          !isEqual(initialMatieres, matieresInPage)
        }
        isValidateButtonAvailable={true}
        path={validationContentPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => {
          setAppareilsInPage(initialAppareils);
          setCombustiblesInPage(initialCombustibles);
          setFacteursInPage(initialFacteurs);
          setMesuresInPage(initialMesures);
          setMatieresInPage(initialMatieres);
        }}
        isStrictPathAlert={true}
        renderContent={shouldDisabledFields => {
          return installationsList.map(singleInstallation => {
            const installationId = singleInstallation.data.id;
            const appareilErrors = mapAlertsByInstallation.get(installationId)
              ?.appareilErrors;
            const appareilWarnings = mapAlertsByInstallation.get(installationId)
              ?.appareilWarnings;

            const combustibleErrors = mapAlertsByInstallation.get(
              installationId
            )?.combustibleErrors;
            const combustibleWarnings = mapAlertsByInstallation.get(
              installationId
            )?.combustibleWarnings;

            const emissionErrors = mapAlertsByInstallation.get(installationId)
              ?.emissionErrors;
            const emissionWarnings = mapAlertsByInstallation.get(installationId)
              ?.emissionWarnings;
            return (
              <div key={installationId}>
                <Collapsible title={singleInstallation.data.nom || ""}>
                  <Tabs
                    content={[
                      {
                        title: "APPAREILS",
                        renderComponent: () => (
                          <BlocAppareils
                            setAppareilsInPage={setAppareilsInPage}
                            appareilsInPage={appareilsInPage}
                            installation={singleInstallation}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            setCombustiblesInPage={setCombustiblesInPage}
                            combustiblesInPage={combustiblesInPage}
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            matieresInPage={matieresInPage}
                            setMatieresInPage={setMatieresInPage}
                            mesuresInPage={mesuresInPage}
                            setMesuresInPage={setMesuresInPage}
                            isValidated={isValidated}
                            errors={appareilErrors || []}
                            warnings={appareilWarnings || []}
                          />
                        ),
                        img: dangerImg,
                        displayImg: shouldDisplayWarningTab(
                          isValidated,
                          appareilErrors,
                          appareilWarnings
                        ),
                        style: classes.warningIcon,
                      },
                      {
                        title: "COMBUSTIBLES",
                        renderComponent: () => (
                          <BlocCombustibles
                            setCombustiblesInPage={setCombustiblesInPage}
                            combustiblesInPage={combustiblesInPage}
                            installation={singleInstallation}
                            combustibleReferential={combustibleReferential}
                            appareilsInPage={appareilsInPage}
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            isTorchereBiogaz={isTorchereBiogaz}
                            isValorisationBiogaz={isValorisationBiogaz}
                            referentialSinglePolluants={
                              referentialSinglePolluants
                            }
                            referentialElemenAutreUuid={
                              referentialElemenAutreUuid
                            }
                            isValidated={isValidated}
                            errors={combustibleErrors || []}
                            warnings={combustibleWarnings || []}
                          />
                        ),
                        img: dangerImg,
                        displayImg: shouldDisplayWarningTab(
                          isValidated,
                          combustibleErrors,
                          combustibleWarnings
                        ),
                        style: classes.warningIcon,
                      },
                      {
                        title: "ÉMISSIONS",
                        renderComponent: () => (
                          <BlocEmissions
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            mesuresInPage={mesuresInPage}
                            setMesuresInPage={setMesuresInPage}
                            matieresInPage={matieresInPage}
                            setMatieresInPage={setMatieresInPage}
                            installation={singleInstallation}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            substancesAir={substancesAir}
                            combustiblesInPage={combustiblesInPage}
                            appareilsInPage={appareilsInPage}
                            referentialSinglePolluants={
                              referentialSinglePolluants
                            }
                            polluantElementMap={polluantElementMap}
                            referentialElementAutreUuid={
                              referentialElemenAutreUuid
                            }
                            polluantDefaultUniteMap={polluantDefaultUniteMap}
                            isValidated={isValidated}
                            errors={emissionErrors || []}
                            warnings={emissionWarnings || []}
                          />
                        ),
                        img: dangerImg,
                        displayImg: shouldDisplayWarningTab(
                          isValidated,
                          emissionErrors,
                          emissionWarnings
                        ),
                        style: classes.warningIcon,
                      },
                    ]}
                  />
                </Collapsible>
                <Row height={"10px"} />
              </div>
            );
          });
        }}
      />
    </div>
  );
};

export default ListInstallation;
