import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { PATH_ROOT } from "common/path/path18Now";
import { useDeclarationErrorAndWarnings21Now } from "../../versionedElements/errorAndWarningDisplayer21Now/utils";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  errorContainer: {
    margin: "auto",
    width: "500px",
  },
});

const DeclarationWarningsAndError = (): ReactElement => {
  const classes = useStyles();
  const { errors, warnings } = useDeclarationErrorAndWarnings21Now(
    PATH_ROOT,
    true,
    true
  );

  return (
    <div className={classes.errorContainer}>
      <ViolationProcessor errors={errors} warnings={warnings} />
    </div>
  );
};

export default DeclarationWarningsAndError;
