import React from "react";
import { makeStyles } from "@material-ui/styles";
import { ARRAY_DEFAULT_BUTTON_WIDTH, BACKGROUND_PINK } from "theme";
import classNames from "classnames";
import editLogo from "icons/edit.svg";
import cancelLogo from "icons/cancel.svg";
import { useConfirmationModale } from "common/modale/hooks";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  row: {
    display: "flex",
    justifyContent: "center",
    width: ARRAY_DEFAULT_BUTTON_WIDTH,
    height: "28px",
  },
  icon: {
    height: "16px",
    width: "16px",
  },
  button: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "6px 6px",
    "&:hover": {
      background: BACKGROUND_PINK,
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
  buttonDisabled: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "6px 6px",
  },
  disabled: {
    height: "16px",
    width: "16px",
    filter: "grayscale(100%)",
  },
  commentButton: {
    padding: "4px 4px",
    width: "30px",
    height: "30px",
    "&:hover": {
      background: BACKGROUND_PINK,
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
});

interface CommonEntityButtonProps {
  suppressMessage?: string;
  handlerEdit?: () => void;
  handlerSuppress?: () => void;
  additionalClassName?: string;
  isValidated?: boolean;
  commentPath?: string;
  supressLogo?: string;
}

// TODO Concerning https://dl.polyconseil.fr/jira/browse/GEREP-657, pretty sure we ought to pass only the entityToSuppress and its setter, but that will be another task
const CommonEntityButton = ({
  handlerEdit,
  handlerSuppress,
  suppressMessage,
  additionalClassName,
  isValidated,
  commentPath,
  supressLogo,
}: CommonEntityButtonProps): React.ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();

  return (
    <div className={classNames(classes.row, additionalClassName)}>
      {handlerEdit && (
        <button
          type="button"
          onClick={handlerEdit}
          className={isValidated ? classes.buttonDisabled : classes.button}
          disabled={isValidated}
        >
          <img
            src={editLogo}
            alt="edit logo"
            className={isValidated ? classes.disabled : classes.icon}
          />
        </button>
      )}

      {commentPath && (
        <CommentButton
          path={commentPath}
          additionalClassName={classes.commentButton}
        />
      )}

      {handlerSuppress && (
        <button
          type="button"
          onClick={() => {
            suppressMessage
              ? openConfirmationModale(suppressMessage, handlerSuppress)
              : handlerSuppress();
          }}
          className={isValidated ? classes.buttonDisabled : classes.button}
          disabled={isValidated}
        >
          <img
            src={supressLogo ?? cancelLogo}
            alt={supressLogo ? "supression logo" : "cancel logo"}
            className={isValidated ? classes.disabled : classes.icon}
          />
        </button>
      )}
    </div>
  );
};

export default CommonEntityButton;
