import React from "react";
import {
  AirCombustionCombustibleDto1819,
  ReferenceItemCombustibleDto,
} from "api/gen";
import {
  arrayBrutOuSec,
  arrayPciUnite,
  arraySurCendres,
  arrayUnite,
} from "./selectPossibleValues";
import { getAppareilsLabels, getMesureOrCalcul } from "../../../utils/utils";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { findElementMatchingTemplate } from "common/utils/methods";
import { AppareilInArray } from "../../blocAppareils/utils/types";
import { CombustibleInArray } from "./types";

export const PCI_CONVERSION_FACTOR = 0.901;

export const getAppareils = (appareils: OptionProps[]) => {
  const appareilText = appareils.map(appareil => appareil.label).join(", ");
  return <div title={appareilText}>{appareilText}</div>;
};

const checkIsMesure = (value: string) => {
  return value === "Mesure";
};

const getCombustibleIdByName = (
  combustibles: ReferenceItemCombustibleDto[],
  combustibleName: string
) => {
  const combustible = combustibles.find(
    comb => comb.designation === combustibleName
  );
  return combustible ? combustible.uuid : null;
};

//=============================================================
// COMBUSTIBLES
//=============================================================
const getAppareil = (
  combustible: AirCombustionCombustibleDto1819,
  appareils: AppareilInArray[],
  installationName: string
) => {
  const filterAppareils = appareils.filter(appareil => {
    return (
      combustible.appareils.some(
        appareilInCombustible => appareilInCombustible === appareil.data.nom
      ) && appareil.data.nameInstallation === installationName
    );
  });
  if (filterAppareils) {
    return filterAppareils.map(appareil => {
      return {
        value: appareil.data.nom || "",
        label: appareil.data.nom || "",
        object: appareil,
      };
    });
  }
  return [];
};

export const getReferentialItem = (
  referentials: ReferenceItemCombustibleDto[],
  designation: string
): ReferenceItemCombustibleDto | null => {
  return (
    referentials.find(referential => {
      return referential.designation === designation;
    }) || null
  );
};

export const convertCombustiblesToDisplayed = (
  combustible: AirCombustionCombustibleDto1819,
  referentials: ReferenceItemCombustibleDto[],
  appareils: AppareilInArray[]
): CombustibleInArray => {
  let type: OptionPropsWithObject<
    ReferenceItemCombustibleDto,
    string
  > | null = null;
  if (combustible.designationID !== null) {
    const typeReferential = findElementMatchingTemplate(
      { uuid: combustible.designationID },
      referentials
    );
    if (typeReferential !== null) {
      type = {
        value: typeReferential.uuid,
        label: typeReferential.designation,
        object: typeReferential,
      };
    }
  }

  const unite =
    combustible.unite === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: combustible.unite } },
          arrayUnite
        );

  const surCendre =
    combustible.surCendres === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: combustible.surCendres } },
          arraySurCendres
        );

  const pciUnite =
    combustible.pci.unite === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: combustible.pci.unite } },
          arrayPciUnite
        );

  const pciBrut =
    combustible.pci.brut === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: combustible.pci.brut } },
          arrayBrutOuSec
        );

  const consommationPCI =
    unite && combustible.consommation !== null && unite.object.isPCS
      ? combustible.consommation * PCI_CONVERSION_FACTOR
      : null;

  return {
    data: {
      type: type,
      code: combustible.code,
      nameInstallation: combustible.installation,
      consommation: combustible.consommation,
      unite: unite,
      appareils:
        combustible.installation !== null
          ? getAppareil(combustible, appareils, combustible.installation)
          : null,
      masse: combustible.masseVolumique,
      eau: combustible.eau,
      carbone: combustible.carbone,
      soufre: combustible.soufre,
      surCendres: surCendre,
      cendres: combustible.cendres,
      chlore: combustible.chlore,
      pciValeur: combustible.pci.valeur,
      pciUnite: pciUnite,
      pciEcart: combustible.pci.ecart,
      pciBrut: pciBrut,
      pciProvenance: combustible.pci.provenance,
      biogazFraction: combustible.biogaz.fraction,
      biogazEcart: combustible.biogaz.ecart,
      biogazDebit: combustible.biogaz.debit,
      biogazMesure: getMesureOrCalcul(combustible.biogaz.mesure),
      biogazFrequence: combustible.biogaz.frequence,
      biogazDuree: combustible.biogaz.duree,
      ch4Teneur: combustible.biogaz.ch4.teneur,
      ch4Mesure: getMesureOrCalcul(combustible.biogaz.ch4.mesure),
      ch4Frequence: combustible.biogaz.ch4.frequence,
      quantiteMethaneOxyde:
        combustible.biogaz.duree !== null &&
        combustible.biogaz.debit !== null &&
        combustible.biogaz.ch4.teneur !== null
          ? ((combustible.biogaz.duree || 0) *
              (combustible.biogaz.debit || 0) *
              (combustible.biogaz.ch4.teneur || 0)) /
            1000
          : null,
      id: combustible.id,
      consommationPCI: consommationPCI,
    },
    errors: {},
  };
};

export const createAirCombustionsCombustibleDto = (
  combustiblesInPage: CombustibleInArray[],
  referentials: ReferenceItemCombustibleDto[]
): AirCombustionCombustibleDto1819[] => {
  const combustibles = combustiblesInPage.map(singlePopulatedCombustible => {
    const singleCombustible = singlePopulatedCombustible.data;
    const singleCombustibleDto: AirCombustionCombustibleDto1819 = {
      id: singlePopulatedCombustible.data.id,
      code: singleCombustible.code,
      designationID: singleCombustible.type
        ? getCombustibleIdByName(referentials, singleCombustible.type.label)
        : null,
      installation: singleCombustible.nameInstallation,
      consommation: singleCombustible.consommation,
      unite: singleCombustible.unite && singleCombustible.unite.object.code,
      appareils: getAppareilsLabels(singleCombustible.appareils),
      masseVolumique: singleCombustible.masse,
      eau: singleCombustible.eau,
      carbone: singleCombustible.carbone,
      soufre: singleCombustible.soufre,
      surCendres:
        singleCombustible.surCendres &&
        singleCombustible.surCendres.object.code,
      cendres: singleCombustible.cendres,
      chlore: singleCombustible.chlore,
      pci: {
        valeur: singleCombustible.pciValeur,
        unite:
          singleCombustible.pciUnite && singleCombustible.pciUnite.object.code,
        ecart: singleCombustible.pciEcart,
        brut:
          singleCombustible.pciBrut && singleCombustible.pciBrut.object.code,
        provenance: singleCombustible.pciProvenance,
      },
      biogaz: {
        fraction: singleCombustible.biogazFraction,
        ecart: singleCombustible.biogazEcart,
        debit: singleCombustible.biogazDebit,
        mesure:
          singleCombustible.biogazMesure &&
          checkIsMesure(singleCombustible.biogazMesure.label),
        frequence: singleCombustible.biogazFrequence,
        duree: singleCombustible.biogazDuree,
        ch4: {
          teneur: singleCombustible.ch4Teneur,
          mesure:
            singleCombustible.ch4Mesure &&
            checkIsMesure(singleCombustible.ch4Mesure.label),
          frequence: singleCombustible.ch4Frequence,
        },
      },
    };

    return singleCombustibleDto;
  });
  return combustibles;
};
