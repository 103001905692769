export interface H32info {
  uniteCombustible: string;
  convertionFactorCombustible: number;
  unitePci: string | null;
  convertionFactorPci: number;
  operationMasseVolumique: "MULTIPLY" | "DIVIDE" | null;
  multiplyByPciAndEmissionFactor: boolean;
}

export const multiply = "MULTIPLY";
export const divide = "DIVIDE";

export const h32infos: H32info[] = [
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "t (tonne)",
    convertionFactorCombustible: 1,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "kg (kilogramme)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: divide,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "hl (hectolitre)",
    convertionFactorCombustible: 0.1,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "l (litre)",
    convertionFactorCombustible: 0.001,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "1000 m3 (mille mètres cubes)",
    convertionFactorCombustible: 1000,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 0.0036,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 0.001,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 0.0041868,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 0.042,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "th/kg PCI",
    convertionFactorPci: 4.1868,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "tep/t PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: multiply,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 10,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 3.6,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 36,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 42,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "m3 (mètre cube)",
    convertionFactorCombustible: 1,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 420,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: true,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "tep (tonne équivalent pétrole)",
    convertionFactorCombustible: 42,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GJ (gigajoule)",
    convertionFactorCombustible: 1,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3.6,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    convertionFactorCombustible: 3600,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 0.0036,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3.6,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "kWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MJ/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "th/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/1000m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "th/kg PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/t PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "GJ/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "MWh/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/m3 PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: "tep/hl PCI",
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
  {
    uniteCombustible: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    convertionFactorCombustible: 3600,
    unitePci: null,
    convertionFactorPci: 1,
    operationMasseVolumique: null,
    multiplyByPciAndEmissionFactor: false,
  },
];
