import {
  AirSyntheseDto,
  AirSyntheseEmissionDto,
  ComputedAirSyntheseEmissionDto1823,
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { SyntheseInArray, SyntheseInModale } from "./types";
import {
  CINTSubPartActivated,
  CSubPartActivated,
  MINTSubPartActivated,
  MNROSubPartActivated,
  MPERSubPartActivated,
  MSubPartActivated,
} from "../validation/validation";
import { computeTotalEmissionsUsualUnit } from "./utils";

const getSubstanceById = (
  substances: ReferenceItemPolluantDto[],
  substanceId: string
): ReferenceItemPolluantDto | null => {
  return findElementMatchingTemplate({ uuid: substanceId }, substances);
};

const getReferenceItemNormsByIds = (
  normesID: string[],
  normes: ReferenceItemNormeAirDto[]
): ReferenceItemNormeAirDto[] => {
  const normItems: ReferenceItemNormeAirDto[] = [];
  normesID.forEach(el => {
    const norm = normes.find(norm => {
      return norm.uuid === el;
    });
    if (norm) {
      normItems.push(norm);
    }
  });
  return normItems;
};

const getAdequateNorms = (
  singleSynthese: SyntheseInModale
): ReferenceItemNormeAirDto[] => {
  if (singleSynthese.normMINT) {
    return singleSynthese.normMINT;
  } else if (singleSynthese.normMPER) {
    return singleSynthese.normMPER;
  } else if (singleSynthese.normMNRO) {
    return singleSynthese.normMNRO;
  } else if (singleSynthese.normCINT) {
    return singleSynthese.normCINT;
  } else {
    return [];
  }
};

export const convertDtoToSyntheseInArray = (
  emission: AirSyntheseEmissionDto,
  airSyntheseEmissionComputed: ComputedAirSyntheseEmissionDto1823,
  substances: ReferenceItemPolluantDto[],
  normes: ReferenceItemNormeAirDto[]
): SyntheseInArray => {
  const substance = getSubstanceById(
    substances,
    airSyntheseEmissionComputed.substanceID
  );

  //TODO : compute errors
  const newSyntheseInArray: SyntheseInArray = {
    data: {
      id: emission.id,
      additionnel: emission.additionnel,
      description: emission.description,
      emissionsAlreadyDeclared: airSyntheseEmissionComputed.declare,
      methode: emission.methode,
      referenceMesure: emission.referenceMesure,
      referenceCalcule: emission.referenceCalcule,
      origine: emission.origine,
      substance: substance,
      totalEmissions: airSyntheseEmissionComputed.total,
      accidentel: emission.accidentel,
      normCINT:
        CSubPartActivated(emission.methode) &&
        CINTSubPartActivated(emission.referenceCalcule)
          ? getReferenceItemNormsByIds(emission.normesID, normes)
          : null,
      normMINT:
        MSubPartActivated(emission.methode) &&
        MINTSubPartActivated(emission.referenceMesure)
          ? getReferenceItemNormsByIds(emission.normesID, normes)
          : null,
      normMNRO:
        MSubPartActivated(emission.methode) &&
        MNROSubPartActivated(emission.referenceMesure)
          ? getReferenceItemNormsByIds(emission.normesID, normes)
          : null,
      normMPER:
        MSubPartActivated(emission.methode) &&
        MPERSubPartActivated(emission.referenceMesure)
          ? getReferenceItemNormsByIds(emission.normesID, normes)
          : null,
      totalEmissionsUsualUnit: null,
      biomasse: airSyntheseEmissionComputed.biomasse,
    },
    errors: {},
  };

  return {
    data: {
      ...newSyntheseInArray.data,
      totalEmissionsUsualUnit: computeTotalEmissionsUsualUnit(
        newSyntheseInArray.data
      ),
    },
    errors: { ...newSyntheseInArray.errors },
  };
};

export const convertSyntheseInArrayToDto = (
  SyntheseInArray: SyntheseInArray[]
): AirSyntheseDto => {
  const emissions = SyntheseInArray.map(singlePopulatedSynthese => {
    const singleSynthese = singlePopulatedSynthese.data;
    const singleEmission: AirSyntheseEmissionDto = {
      id: singleSynthese.id,
      accidentel: singleSynthese.accidentel,
      additionnel: singleSynthese.additionnel,
      methode: singleSynthese.methode,
      origine: singleSynthese.origine,
      referenceCalcule: singleSynthese.referenceCalcule,
      referenceMesure: singleSynthese.referenceMesure,
      substanceID:
        singleSynthese.substance === null ? "" : singleSynthese.substance.uuid,
      normesID: getAdequateNorms(singleSynthese).map(norm => norm.uuid),
      description: singleSynthese.description,
      biomasse: singleSynthese.biomasse || false,
    };
    return singleEmission;
  });

  const returnedDto: AirSyntheseDto = {
    emissions: emissions,
  };

  return returnedDto;
};
