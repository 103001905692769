import React from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import {
  BLACK_BUTTON_STYLE,
  FILE_BUTTONS_STYLE,
  SECTION_TITLE_GREY,
} from "theme";
import { BlocPlanDeSurveillanceProps } from "./types";
import { dtoToBlocValues, updateHandler } from "./converter";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { FileSectionEnum } from "common/FilesLoader/types";
import { PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC } from "common/path/path20Now";
import { commonBooleanFields } from "common/declarant/formik/formikHelper";
import { useAlertModale } from "common/modale/hooks";
import InfoBulle from "common/infoBulle/InfoBulle";
import _ from "lodash";
import { PLAN_DE_SURVEILLANCE_EDIT_MESSAGE } from "../../helpers";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { useEmissionValidationMessage } from "../../utils/utils";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import FormikBlocFullContext2020 from "../../../versionedElements/FormikBlocFullContext2020";
import { usePdsFileHandler2020 } from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  ...FILE_BUTTONS_STYLE,
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...BLACK_BUTTON_STYLE,
  headerCheckboxes: {
    color: SECTION_TITLE_GREY,
    marginLeft: "20px",
    marginTop: "20px",
  },
  headerCheckboxesBold: {
    fontWeight: "bold",
  },
  checkboxes: {
    marginTop: "20px",
  },
});

const validationSchema = Yup.object().shape({
  computedByEmissionWithOxidationFactor: commonBooleanFields,
  computedByEmissionWithConversionFactor: commonBooleanFields,
  computedByMassBalance: commonBooleanFields,
  measure: commonBooleanFields,
  alternativeMethod: commonBooleanFields,
});

const BlocPlanDeSurveillance = ({
  blocPlanDeSurveillanceFormProps,
  setBlocEmissions,
}: BlocPlanDeSurveillanceProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const validationMessage = useEmissionValidationMessage();
  const openAlertModale = useAlertModale();

  const pdsFileHandler = usePdsFileHandler2020();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "bloc-quotas-plan-de-surveillance",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  return (
    <FormikBlocFullContext2020
      formikRef={blocPlanDeSurveillanceFormProps.formikRef}
      hasChanges={blocPlanDeSurveillanceFormProps.hasChanges}
      setHasChanges={blocPlanDeSurveillanceFormProps.setHasChanges}
      initialValues={dtoToBlocValues(
        declaration.body.sections.quotas.emissions.blocPlanSurveillance
      )}
      validationSchema={validationSchema}
      title={"plan de surveillance des émissions"}
      editValidationMessage={
        validationMessage || PLAN_DE_SURVEILLANCE_EDIT_MESSAGE
      }
      pathToValidate={PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) => {
        const updatedDeclaration = updateHandler(
          _.cloneDeep(declaration),
          values
        );
        if (!_.isEqual(declaration, updatedDeclaration)) {
          setBlocEmissions(
            updatedDeclaration.body.sections.quotas.emissions.blocEmissions
          );
        }
        return updatedDeclaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      renderContent={(_, shouldDisabledFields) => (
        <>
          <div className={classes.text}>
            <p>À faire dans ce bloc :</p>
            <ul>
              <li>
                Déposer le(s) plan(s) de surveillance en vigueur pour l'année de
                déclaration (au minimum 1 par NIM),
              </li>
              <li>
                Cocher les méthodes de surveillance applicables pour l'année de
                déclaration.
              </li>
            </ul>
          </div>

          <GreyFilesDownloader
            text="TÉLÉCHARGER LE MODÈLE PLAN DE SURVEILLANCE"
            isDisabled={shouldDisabledFields}
            fileName="ModèlePlanSurveillanceQuotas.xls"
            additionalClassname={classes.fileButtons}
          />

          <FilesLoader
            declaration={declaration}
            text={
              "DÉPOSER LE PLAN DE SURVEILLANCE DES ÉMISSIONS (AU MOINS 1 PAR NIM)"
            }
            filesPath={
              "body.sections.quotas.emissions.blocPlanSurveillance.fichiers"
            }
            section={FileSectionEnum.QUOTAS_PLAN_SURVEILLANCE}
            fileHandlers={pdsFileHandler}
            //
            isDisabled={shouldDisabledFields}
            onReject={() =>
              openAlertModale("Le format du fichier uploadé est incorrect.")
            }
          />

          <h5 className={classes.headerCheckboxes}>
            <b className={classes.headerCheckboxesBold}>
              Méthodes de surveillance
            </b>{" "}
            (plusieurs choix possibles) *
            <InfoBulle
              content={
                "Dans le cas de la déclaration d’émissions nulles, merci de sélectionner par défaut la méthode de calcul par facteur d'émission associé à un facteur d'oxydation, de déclarer un flux fictif avec un intitulé explicite, par exemple « émissions directes nulles », et de déclarer une émission nulle."
              }
            />
          </h5>
          <div className={classes.checkboxes}>
            <CheckBox
              name="computedByEmissionWithOxidationFactor"
              label="Calcul par facteur d'émission associé à un facteur d'oxydation"
              disabled={shouldDisabledFields}
            />

            <CheckBox
              name="computedByEmissionWithConversionFactor"
              label="Calcul par facteur d'émission associé à un facteur de conversion"
              disabled={shouldDisabledFields}
            />

            <CheckBox
              name="computedByMassBalance"
              label="Calcul par bilan massique"
              disabled={shouldDisabledFields}
            />

            <CheckBox
              name="measure"
              label="Mesure (système de mesure en continu des émissions - SMCE)"
              disabled={shouldDisabledFields}
            />

            <CheckBox
              name="alternativeMethod"
              label="Méthode alternative (approuvée) ou Méthode de calcul pour les PFC"
              disabled={shouldDisabledFields}
            />
          </div>
        </>
      )}
    />
  );
};

export default BlocPlanDeSurveillance;
