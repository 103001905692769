import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";

const useStyles = makeStyles({
  link: {
    color: PINK,
  },
});

export const UnitHelper = (): ReactElement => {
  const classes = useStyles();

  return (
    <>
      Pour les établissements relevant du{" "}
      <a
        href={
          "https://eur-lex.europa.eu/legal-content/FR/TXT/HTML/?uri=CELEX:02006R0166-20200101"
        }
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        règlement E-PRTR
      </a>{" "}
      les unités sont grisées et proposées selon la nomenclature de l’activité
      principale du site. Les données relatives aux volumes de production
      doivent être déclarées conformément à la{" "}
      <a
        href="https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32022D0142&from=EN"
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        décision d'éxécution (UE) 2022/142
      </a>{" "}
      (dont les unités et les indicateurs ont été définis par la commission
      européenne).
    </>
  );
};

export const UnitHelperElevage = (): ReactElement => {
  const classes = useStyles();

  const linkToReglementation1741 = (): ReactElement => {
    return (
      <a
        href="https://eur-lex.europa.eu/legal-content/FR/TXT/PDF/?uri=CELEX:32022D0142&from=EN"
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        décision d'exécution 2019/1741
      </a>
    );
  };

  const linkToReglementation2014 = (): ReactElement => {
    return (
      <a
        href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32014R0808"
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        règlement d’exécution (UE) n° 808/2014
      </a>
    );
  };
  return (
    <>
      <p>
        Les données relatives aux volumes de production doivent être déclarées
        conformément à la {linkToReglementation1741()} qui prévoit pour les
        éleveurs une déclaration en nombre d'unités de gros bétail (UGB). Les
        données relatives au volume de production couvrent la production viable
        totale d’un établissement et incluent l’ensemble de la production
        annuelle vendue, stockée sur le site ou utilisée sur le site au cours de
        traitements ultérieurs. L’ensemble de la production gâchée, rejetée ou
        non conforme aux spécifications est exclu.
      </p>

      <p>
        Les coefficients permettant de convertir le nombre d’animaux en UGB sont
        ceux de l’annexe II du {linkToReglementation2014()} et sont détaillés
        ci-dessous :
      </p>

      <ul>
        <li>Truies reproductrices &gt; 50 kg : 0,5 UGB</li>
        <li>Autres porcins : 0,3 UGB</li>
        <li>Poules pondeuses : 0,014 UGB</li>
        <li>Autres volailles : 0,03 UGB</li>
      </ul>

      <p>
        Au regard de la définition du nombre d'UGB issue de la{" "}
        {linkToReglementation1741()}, les unités de gros bétail pour les animaux
        qui ne relèvent pas explicitement du {linkToReglementation2014()} se
        fondent sur des preuves scientifiques. Les coefficients de conversion de
        l'annexe I du{" "}
        <a
          href="https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32018R1091"
          target="_blank"
          className={classes.link}
          rel="noopener noreferrer"
        >
          règlement (UE) 2018/1091
        </a>{" "}
        peuvent donc être utilisés pour ces animaux (les exemples cités dans la{" "}
        {linkToReglementation1741()} correspondant à ces coefficients pour les
        poulets de chair et les autruches).
      </p>
    </>
  );
};
