import { makeStyles } from "@material-ui/styles";
import React, { ReactElement } from "react";
import classNames from "classnames";
import { DISABLED_NUMBER_INPUT_TEXT } from "theme";
import Row from "common/presentational/Row";
import InfoBulle from "common/infoBulle/InfoBulle";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { CommonProps } from "common/form/utils";
import { WrappedChoiceSelectWithoutLabel } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectWithoutLabel";
import { WrappedNumberInputWithoutLabel } from "common/form/fields/wrappedConnectedInput/WrappedNumberInputWithoutLabel";
import { WrappedTextAreaFieldWithoutLabel } from "common/form/fields/wrappedConnectedInput/WrappedTextAreaFieldWithoutLabel";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";
import { validateBlocCalculatedEmission } from "./validation";
import { isCh4MethodAutre, isCo2MethodAutre } from "./utils";
import FormikBlocFullContext19Now from "../../../versionedElements/FormikBlocFullContext19Now";
import {
  ch4MethodLabels,
  ch4MethodList,
  co2MethodLabels,
  co2MethodList,
} from "../utils/selectPossibleValues";
import {
  blocCalculatedEmissionFieldMatcher,
  BlocCalculatedEmissionFormType,
} from "../utils/types";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  capitalise: {
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  tableHeader: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    fontWeight: "bold",
    width: "200px",
    height: "60px",
    verticalAlign: "middle",
    paddingBottom: "5px",
  },
  tableTopHeader: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    fontWeight: "bold",
    width: "200px",
    height: "30px",
    verticalAlign: "middle",
    paddingRight: "40px",
  },
  centerContent: {
    verticalAlign: "middle",
  },
  emptyLine: {
    height: "20px",
  },
  noBorderTable: {},
  flexCenter: {
    justifyContent: "space-around",
  },
  methodRow: {
    height: "200px",
  },
  methodCell: {
    height: "100%",
    "&>div": {
      height: "100%",
      "&>div": {
        justifyContent: "space-evenly",
        "&>div:nth-child(1)": {
          height: "60%", //TODO REMOVE !IMPORTANT!!!
        },
      },
    },
  },
  selectField: {
    width: "100%",
  },
  td: {
    verticalAlign: "middle",
    justifyContent: "center",
  },
  tableComment: {
    width: "60px",
  },
  inputField: {
    width: "100%",
    resize: "none",
    height: "80px",
  },
  flex: { display: "flex" },
  widthSelect: {
    "& > div >div": {
      maxWidth: "100%",
    },
  },
});

interface BlocCalculatedEmissionsProps
  extends FormikBlocRefAndState<BlocCalculatedEmissionFormType> {
  initialValues: BlocCalculatedEmissionFormType;
  path: string;
  updateHandler: (
    declaration: Declaration19Now,
    values: BlocCalculatedEmissionFormType
  ) => Declaration19Now;
}

export const BlocCalculatedEmissions = ({
  hasChanges,
  setHasChanges,
  formikRef,
  initialValues,
  path,
  updateHandler,
}: BlocCalculatedEmissionsProps): ReactElement => {
  const classes = useStyles();

  return (
    <FormikBlocFullContext19Now
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      title={"Tableau des émissions calculées par cinétique de dégradation"}
      pathToValidate={path}
      areGlobalCommentsAllowed={true}
      preventSubmitWithErrors={values => {
        return validateBlocCalculatedEmission(values);
      }}
      updateHandler={updateHandler}
      renderContent={(props, shouldDisabledFields) => {
        const commonProps: CommonProps = {
          disabled: shouldDisabledFields,
          className: "",
          labelWidth: "50%",
          formPrefix: "bloc-air-isdnd-emissions-calculées",
        };

        const shouldShowCO2TextArea = isCo2MethodAutre(props.values.co2Method);
        const shouldShowCH4TextArea = isCh4MethodAutre(props.values.ch4Method);
        return (
          <Row height="100%" additionalClassname={classes.flexCenter}>
            <table className={classes.noBorderTable}>
              <tbody>
                <tr>
                  <th />
                  <th className={classes.tableTopHeader}>Quantité générée</th>
                  <th className={classes.tableTopHeader}>
                    <div
                      className={classNames(classes.capitalise, classes.flex)}
                    >
                      <InfoBulle content="Indiquer la somme des quantités captées pour valorisation, torchage et export." />
                      Quantité captée
                    </div>
                  </th>
                  <th className={classes.tableTopHeader}>
                    <span className={classes.capitalise}>émissions</span>{" "}
                    totales
                  </th>
                  <th className={classes.tableTopHeader}>Méthode</th>
                  <th className={classes.tableComment} />
                </tr>
                <tr>
                  <th
                    className={classes.tableHeader}
                    rowSpan={shouldShowCO2TextArea ? 2 : 1}
                  >
                    CO<sub>2</sub>
                  </th>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={
                        blocCalculatedEmissionFieldMatcher.amountCo2Generated
                      }
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.amountCo2Caught}
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.amountCo2Total}
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classNames(classes.td, classes.widthSelect)}>
                    <WrappedChoiceSelectWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.co2Method}
                      commonProps={commonProps}
                      additionalClassName={classes.selectField}
                      options={co2MethodList}
                      computeLabel={method => co2MethodLabels[method]}
                      isMulti={false}
                    />
                  </td>
                  <td
                    className={classes.td}
                    rowSpan={shouldShowCO2TextArea ? 2 : 1}
                  >
                    <CommentButton path={`${path}/calculCo2`} />
                  </td>
                </tr>
                {shouldShowCO2TextArea && (
                  <tr className={classes.methodRow}>
                    <td className={classes.centerContent}>
                      <span className={classes.tableTopHeader}>
                        Description / Désignation de la méthode :{" "}
                      </span>
                    </td>
                    <td className={classes.methodCell} colSpan={3}>
                      <WrappedTextAreaFieldWithoutLabel
                        name={
                          blocCalculatedEmissionFieldMatcher.co2MethodDescription
                        }
                        commonProps={commonProps}
                        additionalClassName={classes.inputField}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={6} className={classes.emptyLine} />
                </tr>
                <tr>
                  <th
                    className={classes.tableHeader}
                    rowSpan={shouldShowCH4TextArea ? 2 : 1}
                  >
                    CH<sub>4</sub>
                  </th>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={
                        blocCalculatedEmissionFieldMatcher.amountCh4Generated
                      }
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.amountCh4Caught}
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classes.td}>
                    <WrappedNumberInputWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.amountCh4Total}
                      unit="kg/an"
                      commonProps={commonProps}
                    />
                  </td>
                  <td className={classNames(classes.td, classes.widthSelect)}>
                    <WrappedChoiceSelectWithoutLabel
                      name={blocCalculatedEmissionFieldMatcher.ch4Method}
                      commonProps={commonProps}
                      additionalClassName={classes.selectField}
                      options={ch4MethodList}
                      computeLabel={method => ch4MethodLabels[method]}
                      isMulti={false}
                    />
                  </td>
                  <td
                    className={classes.td}
                    rowSpan={shouldShowCH4TextArea ? 2 : 1}
                  >
                    <CommentButton path={`${path}/calculCh4`} />
                  </td>
                </tr>
                {shouldShowCH4TextArea && (
                  <tr className={classes.methodRow}>
                    <td className={classes.centerContent}>
                      <span className={classes.tableTopHeader}>
                        Description / Désignation de la méthode :{" "}
                      </span>
                    </td>
                    <td className={classes.methodCell} colSpan={3}>
                      <WrappedTextAreaFieldWithoutLabel
                        name={
                          blocCalculatedEmissionFieldMatcher.ch4MethodDescription
                        }
                        commonProps={commonProps}
                        additionalClassName={classes.inputField}
                      />
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={6}>
                    <ErrorDisplayer
                      message={props.errors.errorCo2OrCh4Required}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Row>
        );
      }}
    />
  );
};
