import React, { useState } from "react";
import { MainFormValues, PolluantInArray } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import FieldWrapper from "common/form/fields/wrappers/FieldWrapper";
import Row from "common/presentational/Row";
import Button from "common/button";
import SolArray from "../../toNow/Sol/SolArray";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import {
  FIELD_FONT_SIZE,
  FIELD_FONT_WEIGHT,
  GREY,
  SECTION_TITLE_GREY,
  SEPARATOR_WIDTH,
  SUBTITLE_STYLE,
  WHOLE_LINE_LABEL_LEFT_MARGIN,
  WHOLE_LINE_LABEL_UL_LEFT_MARGIN,
  WHOLE_LINE_LABEL_WIDTH,
} from "theme";
import {
  computeDisplayedDataFromDto,
  computeFormInitialValues,
  computeRejetDtoFromDisplayedData,
} from "./utils/converter";
import _ from "lodash";
import { useFormikBloc } from "common/formikBloc/utils";
import SolModale from "../../toNow/Sol/SolModale";
import { selectPossibleMethods } from "./utils/selectPossibleValues";
import { validationSchema } from "../../toNow/Sol/validation/validation";
import { ReferenceNormeSolDto, ReferencePolluantDto } from "api/gen/api";
import CheckBox from "common/form/fields/connectedInput/CheckBox";
import { UnwrappedFieldProps } from "common/form/fields/types/basicTypes";
import {
  PATH_SOL,
  PATH_SOL_DOIT_REMPLIR,
  PATH_SOL_VOLUMEDECHETS,
} from "common/path/path18Now";
import { useDeclaration24Now } from "../versionedElements/declarationHooks24Now";
import FormikBlocFullContext24Now from "pages/CompanySpace/from24/toNow/versionedElements/FormikBlocFullContext24Now";
import { useConfirmationModale } from "common/modale/hooks";
import jaugeLogo from "icons/jauge.svg";

const useStyles = makeStyles({
  subTitle: SUBTITLE_STYLE,
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    width: WHOLE_LINE_LABEL_WIDTH,
    marginLeft: WHOLE_LINE_LABEL_LEFT_MARGIN,
  },
  ulInLabel: {
    marginLeft: WHOLE_LINE_LABEL_UL_LEFT_MARGIN,
  },
  separator: {
    border: `${SEPARATOR_WIDTH} ${GREY} solid`,
    width: "100%",
  },
  inputField: {
    marginTop: "10px",
    marginBottom: "5px",
    display: "flex",
    "&>div>div": {
      justifyContent: "flex-end",
    },
  },
  text: {
    margin: "12px 0",
  },
  jaugeIcon: {
    width: "30px",
    marginRight: "47%",
  },
});

interface MainSolFormProps {
  referentialPolluants: ReferencePolluantDto;
  referentialNormes: ReferenceNormeSolDto;
}

const MainSolForm = ({
  referentialPolluants,
  referentialNormes,
}: MainSolFormProps): React.ReactElement => {
  const classes = useStyles();

  const declaration = useDeclaration24Now();
  const referentiels = selectPossibleMethods(referentialNormes);

  referentiels.polluantName = referentialPolluants.polluants;

  const initialValues = computeFormInitialValues(declaration);
  const validPolluants = computeDisplayedDataFromDto(
    declaration.body.sections.sol.rejets,
    referentiels,
    referentialNormes,
    declaration.computed.sections.sol.computedSolSeuilsDtoList
  );

  const formProps = useFormikBloc<MainFormValues>();
  const [polluantModaleOpen, setPolluantModaleOpen] = useState(false);
  //Notice we set the polluantInModale to an InArray type, because id is mandatory and won't be required with the InModale type.
  // to be renamed in https://dl.polyconseil.fr/jira/browse/GEREP-1370
  const [
    polluantInModale,
    setPolluantInModale,
  ] = useState<PolluantInArray | null>(null);
  const [polluantsInPage, setPolluantsInPage] = useState<PolluantInArray[]>(
    validPolluants
  );
  const openConfirmationModale = useConfirmationModale();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-sol",
  };
  const NumberField = useNumberFieldGenerator(commonProps);

  const compareFunction = (a: PolluantInArray, b: PolluantInArray): number => {
    if (
      a.data.pollutingSubstance &&
      b.data.pollutingSubstance &&
      a.data.pollutingSubstance.nom !== b.data.pollutingSubstance.nom
    ) {
      return a.data.pollutingSubstance.nom < b.data.pollutingSubstance.nom
        ? -1
        : 1;
    }
    return 0;
  };

  return (
    <>
      <FormikBlocFullContext24Now
        formikRef={formProps.formikRef}
        hasChanges={formProps.hasChanges}
        setHasChanges={formProps.setHasChanges}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title={""}
        isSingle={true}
        pathToValidate={PATH_SOL}
        updateHandler={(declaration, values) => {
          declaration.body.sections.sol.doitRemplirSol =
            values.doesTreatmentOrValorisation;

          if (values.doesTreatmentOrValorisation) {
            declaration.body.sections.sol.volumeDechets = values.totalAmount;
            declaration.body.sections.sol.rejets = computeRejetDtoFromDisplayedData(
              polluantsInPage,
              referentiels
            );
          } else {
            declaration.body.sections.sol.volumeDechets = null;
            declaration.body.sections.sol.rejets = [];

            if (formProps.formikRef.current) {
              formProps.formikRef.current.resetForm({
                doesTreatmentOrValorisation: false,
                totalAmount: null,
              });
            }
          }

          setPolluantsInPage(
            computeDisplayedDataFromDto(
              declaration.body.sections.sol.rejets,
              referentiels,
              referentialNormes,
              declaration.computed.sections.sol.computedSolSeuilsDtoList
            )
          );

          return declaration;
        }}
        hasFormChanges={values => {
          return (
            !_.isEqual(polluantsInPage, validPolluants) ||
            !_.isEqual(initialValues, values)
          );
        }}
        cancelAction={() => {
          setPolluantsInPage(validPolluants);
          return initialValues;
        }}
        renderContent={(props, shouldDisabledFields) => {
          return (
            <>
              <FieldWrapper
                renderLabel={(id: string) => (
                  <label className={classes.label} htmlFor={id}>
                    L'établissement exerce une des opérations de traitement ou
                    de valorisation suivantes :
                    <Row height="10px" />
                    <ul className={classes.ulInLabel}>
                      <li>
                        l'application de déchets ou d'effluents sur ou dans les
                        sols agricoles
                      </li>
                      <li>le traitement en milieu terrestre</li>
                      <li>l'injection en profondeur</li>
                    </ul>
                  </label>
                )}
                name="doesTreatmentOrValorisation"
                renderField={(formikProps: UnwrappedFieldProps, id: string) => (
                  <CheckBox
                    id={id}
                    field={formikProps.field}
                    form={formikProps.form}
                    disabled={shouldDisabledFields}
                  />
                )}
                commonProps={{
                  ...commonProps,
                  labelWidth: "90%",
                  commentPath: PATH_SOL_DOIT_REMPLIR,
                }}
              />

              {props.values.doesTreatmentOrValorisation && (
                <>
                  <Row />
                  <hr className={classes.separator} />
                  <Row />

                  <NumberField
                    name="totalAmount"
                    label="Quantité de déchets/boues/effluents épandus ou injectés *"
                    unit="t/an"
                    disabled={shouldDisabledFields}
                    commentPath={PATH_SOL_VOLUMEDECHETS}
                    tooltipContent={
                      "La quantité doit être déclarée en tonnage de déchets/effluents/boues humides."
                    }
                  />
                  <Row />
                  <Row>
                    <span className={classes.subTitle}>
                      TABLEAU DES REJETS DE SUBSTANCES DANS LE SOL
                    </span>
                    {polluantsInPage.some(
                      item =>
                        item.data.emission != null &&
                        item.data.pollutingSubstance != null &&
                        item.data.pollutingSubstance.seuilKgAn !== null &&
                        item.data.emission >
                          item.data.pollutingSubstance.seuilKgAn
                    ) && (
                      <img
                        src={jaugeLogo}
                        alt="Depassement de Seuil"
                        className={classes.jaugeIcon}
                      />
                    )}
                  </Row>
                  <div className={classes.text}>
                    Cette partie concerne uniquement les émissions dans le sol
                    de polluants provenant de déchets soumis à l'une des
                    opérations de traitement suivantes : traitement en milieu
                    terrestre (épandage) ou injection en profondeur. En cas
                    d'épandage de boues industrielles, seules les substances
                    n'ayant pas d'intérêt agronomique avéré pour les sols ou
                    pour la nutrition des cultures (tels que métaux, substances
                    dangereuses, ...) devront être déclarées, dès lors qu'elles
                    sont rejetées en quantité supérieure aux seuils.
                  </div>
                  <div className={classes.text}>
                    Les valeurs en gras dépassent les seuils de l'arrêté
                    ministériel du 31/01/2008 relatif au registre et à la
                    déclaration annuelle des émissions et de transferts de
                    polluants et des déchets.
                  </div>
                  <Row
                    additionalStyle={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      text="AJOUTER UNE SUBSTANCE"
                      type="button"
                      onClick={() => setPolluantModaleOpen(true)}
                      isDisabled={shouldDisabledFields}
                    />
                  </Row>

                  <Row height="20px" />

                  <SolArray
                    polluantsInArray={_.cloneDeep(polluantsInPage).sort(
                      compareFunction
                    )}
                    isValidated={shouldDisabledFields}
                    removePolluant={populatedPolluant => {
                      setPolluantsInPage(
                        polluantsInPage.filter(tested => {
                          return populatedPolluant.data.id !== tested.data.id;
                        })
                      );
                    }}
                    editPolluant={populatedPolluant => {
                      setPolluantModaleOpen(true);
                      setPolluantInModale(populatedPolluant);
                    }}
                  />

                  <Row height={"20px"} />

                  <Row
                    additionalStyle={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      isReversed={true}
                      text="TOUT SUPPRIMER"
                      type="button"
                      onClick={() => {
                        openConfirmationModale(
                          "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                          () => setPolluantsInPage([])
                        );
                      }}
                      isDisabled={shouldDisabledFields}
                    />
                  </Row>
                </>
              )}
            </>
          );
        }}
      />

      <SolModale
        polluantsInArray={polluantsInPage}
        isOpen={polluantModaleOpen}
        polluantInModal={polluantInModale}
        referentialNormes={referentialNormes}
        closeModal={() => {
          setPolluantModaleOpen(false);
          setPolluantInModale(null);
        }}
        referentiels={referentiels}
        setPolluantsInArray={setPolluantsInPage}
      />
    </>
  );
};

export default MainSolForm;
