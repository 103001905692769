import { AirFugitivesProcedesDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { InArray, InModale } from "common/form/utils";
import { Omit } from "react-router";

export type ProcedeInModale = InModale<Omit<AirFugitivesProcedesDto, "id">>;

export type ProcedeInArray = InArray<Omit<AirFugitivesProcedesDto, "id">>;

export const procedeFieldMatcher = fieldMatcherBuilder<ProcedeInModale>().build(
  {
    name: "nom",
    quantity: "quantite",
    unit: "unite",
    unitPrecision: "precision",
    // the key "masse" of AirFugitivesProcedesDto is in fact a masse volumique
    density: "masse",
  }
);
