import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CustomModal from "common/presentational/CustomModal";
import * as Yup from "yup";
import {
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { WasteData } from "../types";
import { ReceivedWasteStorage } from "../reception/types";
import {
  DechetRecuDto1822,
  ReferenceItemDechetDto,
  ReferenceItemDepartementDto,
  ReferenceItemPaysDto,
} from "api/gen";
import {
  generateErrorsInModal,
  isCountryAccessibleForReception,
  isDepartementAccessibleForReception,
  isNotificationNumberAccessibleForReception,
} from "../reception/submitHandler";
import {
  displayCodeDechet,
  displayDepartement,
  displayOperation,
  displayOperationLocation,
  displayPays,
  formatDepartementToModalLine,
} from "../utils/formater";
import {
  convertModalToStoredData,
  convertReceptionStoredToModal,
} from "./converter";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";
import { isDechetAllowed } from "../reception/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

const useStyles = makeStyles({
  headertitle5: {
    color: "#6b6b6b",
    textTransform: "uppercase",
  },
  contentJustified: {
    display: "flex",
    justifyContent: "space-between",
  },
});

interface DeclareReceptionWasteModalProps {
  wasteData: WasteData;
  isOpen: boolean;
  isISDNDChecked: boolean;
  isISDDChecked: boolean;
  isISDIChecked: boolean;
  aCasierAmiantes: boolean;
  updatingWaste: ReceivedWasteStorage | null;
  close: () => void;
  validate: (waste: DechetRecuDto1822) => void;
}

const validationSchema = Yup.object().shape({
  wasteId: commonObjectFields,
  receptionAmount: commonPositiveNumberFields,
  treatedAmount: commonPositiveNumberFields,
  operation: Yup.object().nullable(),
  location: Yup.object().nullable(),
  departement: Yup.object().nullable(),
  pays: Yup.object().nullable(),
  notification: Yup.string().nullable(),
});

export const DeclareReceptionWasteModal = ({
  wasteData,
  isOpen,
  isISDNDChecked,
  isISDDChecked,
  isISDIChecked,
  aCasierAmiantes,
  updatingWaste,
  close,
  validate,
}: DeclareReceptionWasteModalProps): React.ReactElement => {
  const classes = useStyles();

  const wasteInChoiceSelectModal = useMemo(
    () =>
      wasteData.referentiels.polluants.filter(dechet =>
        isDechetAllowed(
          aCasierAmiantes,
          isISDIChecked,
          isISDDChecked,
          isISDNDChecked,
          dechet
        )
      ),
    [
      wasteData.referentiels.polluants,
      isISDIChecked,
      isISDDChecked,
      aCasierAmiantes,
      isISDNDChecked,
    ]
  );

  const initialValues = convertReceptionStoredToModal(
    updatingWaste ? updatingWaste.data : null,
    wasteData.referentiels
  );

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "modale-production-déchets",
  };
  const textInputProps = {
    disabled: true,
    className: "",
    labelWidth: "50%",
    formPrefix: "modale-production-déchets",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const ConnectedTextInput = useTextFieldGenerator(commonProps);
  const TextInput = useDummyTextFieldGenerator(textInputProps);

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale formulaire ajout déchet"
      onRequestClose={close}
    >
      <DeprecatedCommonFormSingleEntity
        title={"AJOUTER UN DÉCHET"}
        closeFunction={close}
        onSubmit={values => {
          const toAddOrUpdate = convertModalToStoredData(values, updatingWaste);
          validate(toAddOrUpdate);
        }}
        initialEntity={initialValues}
        validationSchema={validationSchema}
        validate={values => generateErrorsInModal(values)}
        renderField={({ values }) => {
          return (
            <>
              <WrappedChoiceSelectModale
                label={"Déchet *"}
                name={"wasteId"}
                header={["Code déchet", "Libellé", "Dangereux"]}
                linesData={wasteInChoiceSelectModal}
                formatLine={dechet => [
                  dechet.codeDechet,
                  dechet.libelle,
                  dechet.dangereux ? "Dangereux" : "Non dangereux",
                ]}
                formatSelectedTitle={(
                  lineData: ReferenceItemDechetDto | string | null
                ) => displayCodeDechet(lineData)}
                title={"DÉCHETS"}
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [lineData.codeDechet, lineData.libelle],
                    searchedStr
                  )
                }
                isFirstSticky={false}
                commonProps={commonProps}
              />

              {typeof values.wasteId === "object" &&
                values.wasteId !== null && (
                  <TextInput
                    label="Dénomination"
                    value={values.wasteId.libelle}
                    name="wasteReadableName"
                  />
                )}

              <CheckBox
                name="isNoLongerWaste"
                label="Sortie du statut de déchet (SSD)"
                tooltipContent="Le déchet a bénéficié de la procédure SSD (statut sortie de déchet). Se référer aux arrêtés ministériels fixant les critères de sortie du statut de déchet."
              />

              <NumberField
                label="Quantité admise *"
                name="receptionAmount"
                unit="t/an"
                additionalClassName={classes.contentJustified}
              />

              <NumberField
                label="Quantité traitée *"
                name="treatedAmount"
                unit="t/an"
                additionalClassName={classes.contentJustified}
              />

              <WrappedChoiceSelect
                name="operation"
                label="Opération d'élimination ou de valorisation *"
                isMulti={false}
                options={
                  wasteData.referentiels.operationOrValorisationForReception
                }
                computeLabel={displayOperation}
                commonProps={commonProps}
              />

              <WrappedChoiceSelect
                name="location"
                label="Lieu de provenance *"
                isMulti={false}
                options={wasteData.referentiels.receptionLocation}
                computeLabel={displayOperationLocation}
                commonProps={commonProps}
              />

              {isDepartementAccessibleForReception(values) && (
                <WrappedChoiceSelectModale
                  name={"departement"}
                  label={"Département *"}
                  title={""}
                  header={["Département"]}
                  linesData={wasteData.referentiels.departements}
                  formatLine={option => [formatDepartementToModalLine(option)]}
                  isFirstSticky={false}
                  formatSelectedTitle={(
                    lineData: ReferenceItemDepartementDto | string | null
                  ) => displayDepartement(lineData)}
                  isLineInSearch={(lineData, searchedStr) =>
                    isSearchStringInCollection(
                      [formatDepartementToModalLine(lineData)],
                      searchedStr
                    )
                  }
                  commonProps={commonProps}
                />
              )}

              {isCountryAccessibleForReception(values) && (
                <WrappedChoiceSelectModale
                  name={"pays"}
                  label={"Pays *"}
                  title={""}
                  header={["Pays"]}
                  linesData={wasteData.referentiels.pays}
                  formatLine={pays => [pays.designation]}
                  isFirstSticky={false}
                  formatSelectedTitle={(
                    lineData: ReferenceItemPaysDto | string | null
                  ) => displayPays(lineData)}
                  isLineInSearch={(lineData, searchedStr) =>
                    isSearchStringInCollection(
                      [lineData.designation],
                      searchedStr
                    )
                  }
                  commonProps={commonProps}
                />
              )}

              {isNotificationNumberAccessibleForReception(values) && (
                <ConnectedTextInput
                  label="Numéro de notification"
                  name="notification"
                />
              )}
            </>
          );
        }}
      />
    </CustomModal>
  );
};
