import React from "react";
import * as Yup from "yup";
import {
  GasOptions,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
  MeasureEmissionInModale,
} from "../types";
import { makeStyles } from "@material-ui/styles";
import {
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import {
  useChoiceSelectFieldGenerator,
  useDummyChoiceSelectFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { selectPossibleValues } from "../selectPossibleValues";
import CustomModal from "common/presentational/CustomModal";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import _ from "lodash";
import uuid from "uuid";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
  overWriteEmissionDependantFields,
} from "../utils/utils";
import { OptionPropsWithObject } from "../../../../../../../common/form/fields/types/basicTypes";

interface MeasuredEmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setMeasuredEmissionsInArray: (
    callback: (emissions: MeasureEmissionInArray[]) => MeasureEmissionInArray[]
  ) => void;
  setMeasuredEmissionInModal: (emission: MeasureEmissionInArray | null) => void;
  measuredEmissionInModal: MeasureEmissionInArray | null;
  measureDeclarations: MeasureDeclarationProps[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  measure: commonObjectFields,
  gas: commonObjectFields,
  fossilEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      isBiomasseSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  biomassEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      isBiomasseSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  otherEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      isOtherEmissionSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
});

const MeasuredEmissionsModal = ({
  isOpen,
  setIsOpen,
  setMeasuredEmissionsInArray,
  measuredEmissionInModal,
  setMeasuredEmissionInModal,
  measureDeclarations,
}: MeasuredEmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emissions-modale-mesurees",
  };

  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const DummyChoiceSelect = useDummyChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);

  const gasOptions = selectPossibleValues.gas.filter(
    gas => gas.label !== "PFC"
  );

  const measureOptions = measureDeclarations
    .filter(measure => measure.name)
    .map(measure => ({
      value: (measure.nim && measure.nim.label) || "",
      label: measure.name || "",
      object: measure.id,
    }));

  const onClose = () => {
    setMeasuredEmissionInModal(null);
    setIsOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UNE ÉMISSION"
        closeFunction={onClose}
        initialEntity={_.get(measuredEmissionInModal, "data", {
          method: null,
          measure: null,
          gas: null,
          fossilEmission: null,
          biomassEmission: null,
          otherEmission: null,
        })}
        isEdit={measuredEmissionInModal !== null}
        validationSchema={validationSchema}
        onSubmit={(values: MeasureEmissionInModale) => {
          const emission: MeasureEmissionInArray = {
            //TODO: compute errors
            data: {
              id: measuredEmissionInModal
                ? measuredEmissionInModal.data.id
                : uuid(),
              // Method is a dummy field - we automatically set its default value (here: "Méthode alternative/PFC")
              method: selectPossibleValues.methods[1],
              measure: values.measure,
              gas: values.gas,
              fossilEmission: values.fossilEmission,
              biomassEmission: values.biomassEmission,
              otherEmission: values.otherEmission,
            },
            errors: {},
          };
          overWriteEmissionDependantFields(emission);
          setMeasuredEmissionsInArray(currentMeasuredEmissionsInArray => {
            const newMeasuredEmissionsInArray = _.cloneDeep(
              currentMeasuredEmissionsInArray
            );
            let indexToReplace = newMeasuredEmissionsInArray.length;
            if (measuredEmissionInModal) {
              indexToReplace = _.findIndex(
                newMeasuredEmissionsInArray,
                measuredEmissionInModal
              );
              setMeasuredEmissionInModal(null);
            }
            newMeasuredEmissionsInArray[indexToReplace] = emission;
            return newMeasuredEmissionsInArray;
          });
          setIsOpen(false);
        }}
        renderField={({ values }) => (
          <>
            <DummyChoiceSelect
              label="Méthode utilisée *"
              name="method"
              isMulti={false}
              options={[selectPossibleValues.methods[1]]}
              disabled
              value={selectPossibleValues.methods[1]}
            />

            <ChoiceSelect
              label="Point de mesure *"
              name="measure"
              isMulti={false}
              options={measureOptions}
            />

            <DummyTextField
              label="Numéro NIM"
              name="nim"
              disabled
              value={values.measure ? values.measure.value : ""}
            />

            <ChoiceSelect
              label="Gaz émis *"
              name="gas"
              isMulti={false}
              options={gasOptions}
            />

            {values.gas && isOtherEmissionSubPartActivated(values.gas) && (
              <NumberField
                label={`Émission de ${
                  // TODO : Jonathan repasse exposants
                  values.gas.object === GasOptions.PFC ? "PFC" : "N2O"
                } *`}
                name="otherEmission"
                unit={computeUnitTCO2PerYear}
              />
            )}

            {isBiomasseSubPartActivated(values.gas) && (
              <>
                <NumberField
                  label="Émission totale d'origine non biomasse *"
                  name="fossilEmission"
                  unit={computeUnitTCO2PerYear}
                />

                <NumberField
                  label="Émission totale d'origine biomasse *"
                  name="biomassEmission"
                  unit={computeUnitTCO2PerYear}
                />
              </>
            )}
          </>
        )}
      />
    </CustomModal>
  );
};

export default MeasuredEmissionsModal;
