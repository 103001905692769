import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  AirCombustionAppareilDtoNatureEnum,
  AirCombustionAppareilDtoTypeFoyerEnum,
} from "api/gen";

export interface ObjectNature {
  code: AirCombustionAppareilDtoNatureEnum;
  isOther: boolean;
  isChaudiere: boolean;
}

export interface ObjectTypeFoyer {
  code: AirCombustionAppareilDtoTypeFoyerEnum;
  isOther: boolean;
}

export const arrayNature: OptionPropsWithObject<ObjectNature>[] = [
  {
    value: 1,
    label: "Chaudière",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.CHAUDIERE,
      isOther: false,
      isChaudiere: true,
    },
  },
  {
    value: 2,
    label: "Turbine gaz",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.TURBINE_GAZ,
      isOther: false,
      isChaudiere: false,
    },
  },
  {
    value: 3,
    label: "Four",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.FOUR,
      isOther: false,
      isChaudiere: false,
    },
  },
  {
    value: 4,
    label: "Moteur gaz",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.MOTEUR_GAZ,
      isOther: false,
      isChaudiere: false,
    },
  },
  {
    value: 5,
    label: "Moteur diesel",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.MOTEUR_DIESEL,
      isOther: false,
      isChaudiere: false,
    },
  },
  {
    value: 6,
    label: "Autre",
    object: {
      code: AirCombustionAppareilDtoNatureEnum.AUTRE,
      isOther: true,
      isChaudiere: false,
    },
  },
];

export const arrayTypeFoyer: OptionPropsWithObject<ObjectTypeFoyer>[] = [
  {
    value: 1,
    label:
      "Charbon pulvérisé (chauffe frontale ou tangentielle, cendres fondues)",
    object: {
      code: AirCombustionAppareilDtoTypeFoyerEnum.CHARBON,
      isOther: false,
    },
  },
  {
    value: 2,
    label: "Foyer(s) à grille (grille classique, chaînes de projection)",
    object: {
      code: AirCombustionAppareilDtoTypeFoyerEnum.GRILLE,
      isOther: false,
    },
  },
  {
    value: 3,
    label: "Lit(s) fluidisé(s) chaud(s)",
    object: {
      code: AirCombustionAppareilDtoTypeFoyerEnum.LIT_CHAUD,
      isOther: false,
    },
  },
  {
    value: 4,
    label: "Lit(s) fluidisé(s) dense(s) ou circulant(s)",
    object: {
      code: AirCombustionAppareilDtoTypeFoyerEnum.LIST_DENSE,
      isOther: false,
    },
  },
  {
    value: 5,
    label: "Autre(s)",
    object: {
      code: AirCombustionAppareilDtoTypeFoyerEnum.AUTRE,
      isOther: true,
    },
  },
];
