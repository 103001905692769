import React from "react";
import logoCombustion from "icons/pageLogos/fire.svg";
import MainAirForm from "./MainAirForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Combustion = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      declarationEtablissement
    ),
    {
      label: "Combustion",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoCombustion,
    alt: "une flamme stylisée, en gris",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Combustion / Incinération",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_COMBUSTIBLE",
        "REFERENCE_POLLUANTS_AIR",
        "REFERENCE_CO2",
        "REFERENCE_SOX",
        "REFERENCE_NOX",
      ]}
      render={referentials => {
        return (
          <MainAirForm
            referentialCombustible={referentials.REFERENCE_COMBUSTIBLE}
            referentialAir={referentials.REFERENCE_POLLUANTS_AIR}
            referentialSinglePolluants={{
              CO2: referentials.REFERENCE_CO2,
              SOX: referentials.REFERENCE_SOX,
              NOX: referentials.REFERENCE_NOX,
            }}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Combustion, "GLOBAL");
