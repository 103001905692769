import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { ResteVentilationDto } from "api/gen";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { EMPHASIZED_LAST_HEADER_CELL } from "common/presentational/CommonEntityTable/cssRules";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import {
  computeAnneeLine,
  computePreferredColWidths,
  computeResteVentilationLine,
} from "./tableLineComputer";
import { computeTableWidth } from "theme";
import { sortObjectKeysAndValues } from "common/utils/methods";

const useStyles = makeStyles({
  container: {
    margin: "30px 0",
  },
  table: (object: Record<string, unknown>) => ({
    ...EMPHASIZED_LAST_HEADER_CELL,
    width: computeTableWidth(object),
  }),
  full: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    marginLeft: "10px",
  },
});

interface AlcVerifieVentilationCombustibleProps {
  yearToResteVentilationMap: {
    [key: string]: ResteVentilationDto;
  };
}

const AlcVerifieVentilationCombustible = ({
  yearToResteVentilationMap,
}: AlcVerifieVentilationCombustibleProps): ReactElement => {
  const classes = useStyles(yearToResteVentilationMap);

  const {
    sortedKeys,
    sortedValues: sortedResteVentilationList,
  } = sortObjectKeysAndValues(yearToResteVentilationMap);

  return (
    <div className={classes.container}>
      <ArrayTitle>Reste de la ventilation de combustible</ArrayTitle>

      <CommonEntityTable
        header={computeAnneeLine(sortedKeys, classes)}
        lines={[
          computeResteVentilationLine(sortedResteVentilationList, classes),
        ]}
        preferredColWidths={computePreferredColWidths(
          sortedResteVentilationList
        )}
        isFirstColSticky={false}
        isLastColSticky={false}
        additionalClassName={classes.table}
      />
    </div>
  );
};

export default AlcVerifieVentilationCombustible;
