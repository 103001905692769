import React from "react";
import { ClassesType } from "./types";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import { LINK_ERROR_STYLE, LINK_STYLE } from "theme";
import _ from "lodash";

const useStyles = makeStyles({
  link: LINK_STYLE,
  linkError: LINK_ERROR_STYLE,
});

export const computeWrappedCell = (
  contentCell: React.ReactNode,
  classes: ClassesType<"full">
): React.ReactElement => {
  return computeWrappedCellWithError(contentCell, { error: "", ...classes });
};

export const computeWrappedCellWithError = (
  contentCell: React.ReactNode,
  classes: ClassesType<"full" | "error">,
  errorMessage?: string
): React.ReactElement => {
  return (
    <div
      className={classNames(classes.full, { [classes.error]: errorMessage })}
    >
      {contentCell}
    </div>
  );
};

export const useComputeFirstLineContentCell = (): ((
  handlerEdit: () => void,
  cellContent: string | undefined,
  isCellDisabled: boolean,
  hasLineError: boolean
) => React.ReactElement) => {
  const classes = useStyles();

  return (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ): React.ReactElement => {
    if (isCellDisabled) {
      return <p title={cellContent}>{cellContent}</p>;
    }
    return (
      <p
        // if an error is anywhere on the line, the first cell with the edit link MUST appear differently
        className={classNames(classes.link, {
          [classes.linkError]: hasLineError,
        })}
        onClick={handlerEdit}
        title={cellContent}
      >
        {cellContent}
      </p>
    );
  };
};

export const parseBoolean = (bool: boolean | null | undefined): string => {
  if (_.isNil(bool)) {
    return "";
  }

  return bool ? "Oui" : "Non";
};
