export const natures: string[] = [
  "BR-Briques",
  "BE-Béton",
  "CI-Ciment",
  "FI-Fines",
  "GR-Granulats",
  "PA-Pavés",
  "PL-Plâtre",
  "TE-Terres",
  "TU-Tuiles",
  "AU-Autre",
];
