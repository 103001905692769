import InfoBulle from "common/infoBulle/InfoBulle";
import React from "react";
import SpaceChild from "common/presentational/SpaceChild";

const InfobulleGlobalActions = () => {
  return (
    <InfoBulle
      content={
        <SpaceChild>
          <p>
            En cliquant sur ce bouton, vous passerez la main au prestataire
            vérificateur quotas qui complètera l'espace dédié à la vérification
            (il ne pourra pour autant pas modifier les informations que vous
            aurez renseignées dans votre espace).
          </p>
          <p>
            Tant que le prestataire vérificateur quotas n'aura pas validé le
            pavé de vérification, il vous sera toujours possible de modifier vos
            données en cliquant sur "Modifier" puis en transmettant à nouveau la
            page au prestataire vérificateur quotas.
          </p>
          <p>
            Une fois que le prestataire vérificateur quotas aura validé le pavé
            de vérification, vous pourrez transmettre la déclaration quotas à
            l'inspection pour validation.
          </p>
          <p>
            Il sera toujours possible de modifier la déclaration quotas avant la
            transmission à l'inspection, mais toute modification faite après
            validation du pavé de vérification par le prestataire vérificateur
            quotas nécessitera une nouvelle vérification par celui-ci avant de
            pouvoir être transmise à l'inspection.
          </p>
          <p>
            Après transmission à l'inspection, la déclaration quotas ne sera
            plus modifiable. L'inspection pourra alors soit valider la
            déclaration quotas, soit la mettre en révision. Dans ce cas, un
            nouveau cycle de déclaration par le déclarant / vérification par le
            prestataire vérificateur quotas / validation par l'inspection sera
            initié.
          </p>
        </SpaceChild>
      }
    />
  );
};

export default InfobulleGlobalActions;
