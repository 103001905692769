import React, { Dispatch, SetStateAction, useState } from "react";
import {
  buildDatesCampagnesFromDto,
  DATES_NAMES,
  NO_MODIF_OF_PAST_DATE_MESSAGE,
  stateToString,
} from "./converters";
import { DatesCampagne, ShouldDisableDates } from "./types";
import { generateValidateDatesCampagne } from "./validation";
import { useLocalDateFieldGenerator } from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { GREY_OVERLAY, LONG_TEXT_INPUT_WIDTH } from "theme";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import _ from "lodash";
import {
  CampaignDto,
  CampaignDtoStateAllocationsEnum,
  CampaignDtoStateEmissionsEnum,
  CampaignDtoStateGlobalEnum,
} from "api/gen";
import { enhanceWithCatchAndLogErrors } from "common/utils/methods";
import { commonShouldDisableSubmitButton } from "../../../../common/declarant/formik/commonShouldDisableSubmitButton";
import Row from "common/presentational/Row";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { backAlertMessage } from "common/backErrors/utils";
import {
  backMessageFetchDeclaration,
  backMessageReferential,
} from "common/backErrors/errorMessages";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useAlertModale } from "common/modale/hooks";
import addYear from "common/utils/addYear";
import FormikAdapter from "../../../../libAdapter/Formik/FormikAdapter";
import PurgeModal from "./PurgeModal";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  stateInfoWrapper: {
    marginBottom: "5px",
  },
  stateInfo: {
    color: GREY_OVERLAY,
  },
});

const buildShouldDisableDates = (
  stateAllocations: CampaignDtoStateAllocationsEnum,
  stateEmissions: CampaignDtoStateEmissionsEnum,
  stateGlobal: CampaignDtoStateGlobalEnum
): ShouldDisableDates => {
  const shouldDisableEndAllocations =
    stateAllocations === CampaignDtoStateAllocationsEnum.ENDED;
  const shouldDisableEndAllocationsDeclarants =
    shouldDisableEndAllocations ||
    stateAllocations === CampaignDtoStateAllocationsEnum.ENDED_FOR_DECLARANTS;
  const shouldDisableEndEmissions =
    stateEmissions === CampaignDtoStateEmissionsEnum.ENDED;
  const shouldDisableEndEmissionsDeclarants =
    shouldDisableEndAllocations ||
    stateEmissions === CampaignDtoStateEmissionsEnum.ENDED_FOR_DECLARANTS;
  const shouldDisableEndGlobal =
    stateGlobal === CampaignDtoStateGlobalEnum.ENDED;
  const shouldDisableEndGlobalDeclarants =
    shouldDisableEndGlobal ||
    stateGlobal === CampaignDtoStateGlobalEnum.ENDED_FOR_DECLARANTS;
  const shouldDisableStart =
    stateGlobal !== CampaignDtoStateGlobalEnum.PLANNED &&
    stateGlobal !== CampaignDtoStateGlobalEnum.READY;
  return {
    start: shouldDisableStart,
    endAllocationsDeclarants: shouldDisableEndAllocationsDeclarants,
    endEmissionsDeclarants: shouldDisableEndEmissionsDeclarants,
    endGlobalDeclarants: shouldDisableEndGlobalDeclarants,
    endAllocations: shouldDisableEndAllocations,
    endEmissions: shouldDisableEndEmissions,
    endGlobal: shouldDisableEndGlobal,
  };
};

interface BlocDatesCampagneProps {
  campaign: CampaignDto;
  setCampaign: Dispatch<SetStateAction<CampaignDto | null>>;
  minimumDate: string;
  minimumDateName: string;
  onSubmit: (values: DatesCampagne) => Promise<boolean>;
}

export const BlocDatesCampagne = ({
  campaign,
  minimumDate,
  minimumDateName,
  onSubmit,
  setCampaign,
}: BlocDatesCampagneProps): React.ReactElement => {
  const [initialDates, setInitialDates] = useState<DatesCampagne>(
    buildDatesCampagnesFromDto(campaign)
  );
  const [isPurgeModalOpen, setIsPurgeModalOpen] = useState<boolean>(false);
  const [yearsAvailableToPurge, setYearsAvailableToPurge] = useState<number[]>(
    []
  );
  const { campaignController, purgeController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const shouldDisable = buildShouldDisableDates(
    campaign.stateAllocations,
    campaign.stateEmissions,
    campaign.stateGlobal
  );

  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "campaign-dates",
  };
  const LocalDateField = useLocalDateFieldGenerator(commonProps);
  const buildDateFieldFromDateCampagne = (dateKey: keyof DatesCampagne) => {
    return (
      <LocalDateField
        label={DATES_NAMES[dateKey]}
        name={dateKey}
        disabled={shouldDisable[dateKey]}
        tooltipContent={
          shouldDisable[dateKey] ? NO_MODIF_OF_PAST_DATE_MESSAGE[dateKey] : null
        }
      />
    );
  };
  const validate = enhanceWithCatchAndLogErrors(
    generateValidateDatesCampagne(
      initialDates,
      shouldDisable,
      minimumDate,
      minimumDateName
    )
  );

  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();

  const addCampaign = () => {
    try {
      campaignController
        .createCampaignUsingPOST(
          campaign.annee + 1,
          addYear(initialDates.endAllocations),
          addYear(initialDates.endAllocationsDeclarants),
          addYear(initialDates.endEmissions),
          addYear(initialDates.endEmissionsDeclarants),
          addYear(initialDates.endGlobal),
          addYear(initialDates.endGlobalDeclarants),
          addYear(initialDates.start)
        )
        .then((campaignDto: CampaignDto) => {
          if (campaignDto) {
            setCampaign(campaignDto);
            setInitialDates(buildDatesCampagnesFromDto(campaignDto));
          }
        });
    } catch (e) {
      openModale(backAlertMessage(e, backMessageReferential));
    }
  };

  const title = "Campagne " + campaign.annee;
  const stateAllocationsInfo =
    "État des allocations quotas : " + stateToString(campaign.stateAllocations);
  const stateEmissionsInfo =
    "État des émissions quotas : " + stateToString(campaign.stateAllocations);
  const stateGlobalInfo =
    "État global : " + stateToString(campaign.stateGlobal);

  const openPurgeModal = async () => {
    try {
      const result = await purgeController.getDeclarationsYearsAvailableToPurgeUsingGET();
      setYearsAvailableToPurge(result);
      setIsPurgeModalOpen(true);
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageFetchDeclaration));
      return;
    }
  };

  return (
    <FormikAdapter
      enableReinitialize={true}
      onSubmit={async values => {
        await triggerValidateSpinner(
          onSubmit(values).then((success: boolean) => {
            if (success) {
              setInitialDates(values);
            }
          })
        );
      }}
      initialValues={initialDates}
      validate={validate}
      render={props => {
        const hasChanges = !_.isEqual(props.values, initialDates);

        return (
          <>
            <DummyUnActionedBloc
              title={title}
              renderContent={() => {
                return (
                  <>
                    <div className={classes.stateInfoWrapper}>
                      <div className={classes.stateInfo}>
                        {stateAllocationsInfo}
                      </div>
                      <div className={classes.stateInfo}>
                        {stateEmissionsInfo}
                      </div>
                      <div className={classes.stateInfo}>{stateGlobalInfo}</div>
                    </div>
                    {buildDateFieldFromDateCampagne("start")}
                    {buildDateFieldFromDateCampagne("endAllocationsDeclarants")}
                    {buildDateFieldFromDateCampagne("endAllocations")}
                    {buildDateFieldFromDateCampagne("endEmissionsDeclarants")}
                    {buildDateFieldFromDateCampagne("endEmissions")}
                    {buildDateFieldFromDateCampagne("endGlobalDeclarants")}
                    {buildDateFieldFromDateCampagne("endGlobal")}
                    <Row height={"40px"}>
                      <Button
                        text={"Purge des Déclarations"}
                        type="button"
                        onClick={() => openPurgeModal()}
                      />
                      {campaign.stateGlobal !==
                      CampaignDtoStateGlobalEnum.ENDED ? (
                        <DummyGlobalFormActions
                          validateButton={{
                            title: "VALIDER",
                            isVisible: true,
                            isEnabled:
                              hasChanges &&
                              !commonShouldDisableSubmitButton(props),
                            onClick: props.submitForm,
                            isSpinnerVisible: isValidateSpinnerVisible,
                          }}
                          cancelButton={{
                            title: "ANNULER",
                            isVisible: hasChanges,
                            isEnabled: hasChanges,
                            onClick: () => {
                              props.setValues(initialDates);
                            },
                          }}
                        />
                      ) : (
                        <Row height="40px">
                          <Button
                            text={
                              <>
                                Nouvelle campagne{" "}
                                <FontAwesomeIcon icon="plus" />
                              </>
                            }
                            isReversed
                            onClick={addCampaign}
                          />
                        </Row>
                      )}
                    </Row>
                  </>
                );
              }}
            />
            <PurgeModal
              close={() => setIsPurgeModalOpen(false)}
              isOpen={isPurgeModalOpen}
              yearsAvailableToPurge={yearsAvailableToPurge}
            />
          </>
        );
      }}
    />
  );
};

export default BlocDatesCampagne;
