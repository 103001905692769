import { makeStyles } from "@material-ui/styles";
import React from "react";
import { QuotasBlocInfoGeneralesDto1819 } from "api/gen/api";
import { ClassicBlocProps } from "common/bloc/utils";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { updateHandler } from "./utils";
import { PATH_QUOTAS_INFOGENERALE_BLOC } from "common/path/path1819";
import BlocFullContext1919 from "../../versionedElements/BlocFullContext1919";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const BlocInfoGenerales = ({
  values,
  setValues,
  hasChanges,
  cancelAction,
}: ClassicBlocProps<QuotasBlocInfoGeneralesDto1819>) => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-quotas",
  };
  const CheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  return (
    <BlocFullContext1919
      title={"informations complémentaires concernant l'établissement"}
      hasModification={hasChanges}
      isValidateButtonAvailable={true}
      path={PATH_QUOTAS_INFOGENERALE_BLOC}
      updateHandler={declaration => updateHandler(declaration, values)}
      cancelAction={cancelAction}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      renderContent={shouldDisabledFields => (
        <>
          <CheckBox
            name="excluArticle27"
            label="Exclu au titre de l’article 27 (Exclusion hôpitaux)"
            disabled={shouldDisabledFields}
            value={values.excluArticle27}
            onChange={_ =>
              setValues({ ...values, excluArticle27: !values.excluArticle27 })
            }
            commentPath={`${PATH_QUOTAS_INFOGENERALE_BLOC}/excluArticle27`}
          />
        </>
      )}
    />
  );
};

export default BlocInfoGenerales;
