import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { updateDeclarationDtoUsingEmissionsForm } from "../utils/converters";
import {
  PATH_AIR_ELEVAGE_EMISSION,
  PATH_AIR_ELEVAGE_EMISSION_CH4,
  PATH_AIR_ELEVAGE_EMISSION_N2O,
  PATH_AIR_ELEVAGE_EMISSION_NH3,
  PATH_AIR_ELEVAGE_EMISSION_PM10,
  PATH_AIR_ELEVAGE_EMISSION_TSP,
} from "common/path/path18Now";
import {
  computeLabelCH4,
  computeLabelN2O,
  computeLabelNH3,
  computeLabelPM10,
} from "common/form/subs/utils";
import { emissionFieldMatcher } from "../utils/types";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";
import { validationSchema } from "./validation";
import { AirElevageEmissionsDto } from "api/gen";

interface BlocAutresEmissionsProps {
  formEmissionsProps: FormikBlocRefAndState<AirElevageEmissionsDto>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
  },
});

const BlocAutresEmissions = ({
  formEmissionsProps,
}: BlocAutresEmissionsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration20Now();

  const commonProps = {
    disabled: false,
    formPrefix: "bloc-autres-emissions",
    labelWidth: "50%",
    className: classes.inputField,
  };

  const NumberField = useNumberFieldGenerator(commonProps);

  const initialValues: AirElevageEmissionsDto =
    declaration.body.sections.air.elevage.emissions;

  return (
    <FormikBlocFullContext20Now
      hasChanges={formEmissionsProps.hasChanges}
      setHasChanges={formEmissionsProps.setHasChanges}
      formikRef={formEmissionsProps.formikRef}
      title={"ÉMISSIONS TOTALES"}
      isSingle={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      updateHandler={updateDeclarationDtoUsingEmissionsForm}
      pathToValidate={PATH_AIR_ELEVAGE_EMISSION}
      areGlobalCommentsAllowed={true}
      renderContent={(_, shouldDisabledFields: boolean) => (
        <>
          <NumberField
            label={computeLabelNH3}
            name={emissionFieldMatcher.NH3Emission}
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_NH3}
          />

          <NumberField
            label={computeLabelN2O}
            name={emissionFieldMatcher.N2OEmission}
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_N2O}
          />

          <NumberField
            label={computeLabelCH4}
            name={emissionFieldMatcher.CH4Emission}
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_CH4}
          />

          <NumberField
            label="TSP"
            name={emissionFieldMatcher.TSPEmission}
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_TSP}
          />

          <NumberField
            label={computeLabelPM10}
            name={emissionFieldMatcher.PM10Emission}
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_PM10}
          />
        </>
      )}
    />
  );
};

export default BlocAutresEmissions;
