import {
  PATH_QUOTAS_BILAN_BLOC,
  PATH_QUOTAS_EMISSIONS_BLOC,
  PATH_QUOTAS_INFOGENERALE_BLOC,
  PATH_QUOTAS_INSTALLATION_BLOC,
  PATH_QUOTAS_PLAN_BLOC,
} from "common/path/path1819";
import {
  DeclarationStateDtoStateEnum,
  QuotasBlocInfoGeneralesDto1819,
} from "api/gen";
import { useLayoutEffect, useState } from "react";
import {
  ButtonStateHandler,
  useSpinnerState,
} from "common/button/loadingAndRedirectionHelpers";
import {
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../../versionedElements/declarationHooks1919";

// Define if using the whole form, or only bloc "Bilan Total"
export const isFullDeclarationActive = (
  infoGenerales: QuotasBlocInfoGeneralesDto1819
) => {
  return !infoGenerales.excluArticle27;
};

// Check if all declarant's blocs are validated
export const useAllDeclarantBlocsValidated = (isFullForm: boolean) => {
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const isBlocInfoGeneralesValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_INFOGENERALE_BLOC
  );
  const isBlocInstallationsValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_INSTALLATION_BLOC
  );
  const isBlocPlanDeSurveillanceValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_PLAN_BLOC
  );
  const isBlocEmissionsValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_EMISSIONS_BLOC
  );
  const isBlocBilanTotalValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_BILAN_BLOC
  );
  return (
    isBlocInfoGeneralesValidated &&
    (isFullForm
      ? isBlocInstallationsValidated &&
        isBlocPlanDeSurveillanceValidated &&
        isBlocEmissionsValidated
      : isBlocBilanTotalValidated)
  );
};

export function useQuotasButtonStateWithRedirection<T>(): [
  boolean,
  ButtonStateHandler<T>,
  boolean,
  DeclarationStateDtoStateEnum
] {
  const declaration = useDeclaration1919();

  const [currentWorkflowState, setCurrentWorkflowState] = useState(
    declaration.body.workflowStatus.quotaEmissions.state
  );
  const [shouldRedirectToDashboard, setShouldRedirectToDashboard] = useState<
    boolean
  >(false);

  useLayoutEffect(() => {
    const quotasState = declaration.body.workflowStatus.quotaEmissions.state;
    if (
      (currentWorkflowState === DeclarationStateDtoStateEnum.CREATED ||
        currentWorkflowState === DeclarationStateDtoStateEnum.STARTED) &&
      quotasState === DeclarationStateDtoStateEnum.VERIFYING
    ) {
      setShouldRedirectToDashboard(true);
    }
    setCurrentWorkflowState(quotasState);
  }, [
    declaration,
    currentWorkflowState,
    setCurrentWorkflowState,
    setShouldRedirectToDashboard,
  ]);

  const [isLoading, setLoading] = useSpinnerState();
  return [
    isLoading,
    setLoading,
    shouldRedirectToDashboard,
    currentWorkflowState,
  ];
}
