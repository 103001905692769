import {
  commonNullableStringFields,
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
  MAX_NB_HOURS_YEAR,
  subNumberFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import * as Yup from "yup";
import { combustionInstallationTooMuchTimeMessage } from "common/declarant/formik/formikMessages";
import { OptionProps } from "common/form/fields/types/basicTypes";
import { InstallationInModale } from "../utils/types";

export const singleInstallationHasSubPartActivated = (
  type: OptionProps | null
) => {
  return (
    type &&
    (type.value === 2 ||
      type.value === 3 ||
      type.value === 4 ||
      type.value === 5)
  );
};

export const singleInstallationOverwriteDependantFields = (
  installation: InstallationInModale
) => {
  if (!singleInstallationHasSubPartActivated(installation.type)) {
    installation.quantiteChaleur = null;
    installation.quantiteElectricite = null;
    installation.rendementChaleur = null;
    installation.rendementElectricite = null;
  }
};

export const singleInstallationtValidationSchema = Yup.object().shape({
  nom: commonStringFields,
  heure: commonPositiveNumberFields.max(
    MAX_NB_HOURS_YEAR,
    combustionInstallationTooMuchTimeMessage
  ),
  volumeActivite: commonPositiveNumberFields,
  unite: commonStringFields,
  typeProduit: commonNullableStringFields,
  type: commonObjectFields,
  quantiteChaleur: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  quantiteElectricite: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  rendementChaleur: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  rendementElectricite: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
});
