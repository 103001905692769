import {
  multipleEmailsValidation,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  emails: multipleEmailsValidation,
  subject: commonStringFields,
  content: commonStringFields,
});
