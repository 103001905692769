import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

window.onload = function() {
  // On the first run, initialize the numberOfSession
  if (
    window.localStorage.numberOfSession === undefined ||
    Number.isNaN(parseInt(window.localStorage.numberOfSession))
  ) {
    window.localStorage.numberOfSession = 0;
  }

  // IF there is no session active and the last session was closed more than one minute(REACT_APP_DECONNECTION_TIMEOUT) ago
  // REACT_APP_DECONNECTION_TIMEOUT need to be defined, if not every time we reload
  // leave the last session, we will be redirected to the homepage
  const sessionCount = parseInt(window.localStorage.numberOfSession);
  const now = new Date().getTime();
  const currentRight = window.localStorage.getItem("activeRight");
  if (
    sessionCount <= 0 &&
    now >
      parseInt(window.localStorage.lastSessionClosedDate) +
        parseInt(process.env.REACT_APP_DECONNECTION_TIMEOUT || "60")
  ) {
    // clear the JWT
    window.localStorage.clear();
    currentRight && window.localStorage.setItem("activeRight", currentRight);
  }

  // ELSE we add one to the count of active session
  window.localStorage.numberOfSession = sessionCount + 1;

  ReactDOM.render(<App />, document.getElementById("root"));
};
window.onunload = function() {
  // decrease the number of active session by one
  window.localStorage.numberOfSession =
    parseInt(window.localStorage.numberOfSession) - 1;

  // If we just close the last session, we are saving the date
  if (window.localStorage.numberOfSession <= 0) {
    window.localStorage.lastSessionClosedDate = new Date().getTime();
  }
};
