import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import Button from "common/button";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_PRODUCTION_TRANSFORMATION } from "common/path/path18Now";
import Row from "common/presentational/Row";
import { FactoryInArray } from "./utils/types";

interface FactoriesArrayProps {
  factoriesInArray: FactoryInArray[];
  setFactoriesInArray: (factory: FactoryInArray[]) => void;
  onEdit: (factory: FactoryInArray) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  table: {
    marginTop: "20px",
  },
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const FactoriesArray = ({
  factoriesInArray,
  setFactoriesInArray,
  onEdit,
  isDisabled,
}: FactoriesArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>Raison sociale usine/atelier</p>,
    <p>Code postal</p>,
    <p>Ville</p>,
    <p>Pays</p>,
    <p>Usine/atelier alimenté(e) par d'autres carrières</p>,
    <p>Distance carrière - usine/atelier (km)</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = factoriesInArray.map(factoryInArray => {
    let cityName: string;

    if (typeof factoryInArray.data.city === "string") {
      cityName = factoryInArray.data.city;
    } else if (factoryInArray.data.city === null) {
      cityName = "";
    } else {
      cityName = factoryInArray.data.city.label;
    }

    return [
      <p
        title={factoryInArray.data.socialReason || ""}
        className={isDisabled ? classes.disabledLink : classes.link}
        onClick={() => {
          if (!isDisabled) {
            onEdit(factoryInArray);
          }
        }}
      >
        {factoryInArray.data.socialReason}
      </p>,
      <p>{factoryInArray.data.zipCode}</p>,
      <p title={cityName}>{cityName}</p>,
      <p>{factoryInArray.data.country && factoryInArray.data.country.label}</p>,
      <p>{factoryInArray.data.poweredByOtherCareers ? "Oui" : "Non"}</p>,
      <p>
        {factoryInArray.data.careerRange !== null
          ? computeNumberWithSeparator(factoryInArray.data.careerRange || 0)
          : ""}
      </p>,
      <CommonEntityButton
        handlerEdit={() => onEdit(factoryInArray)}
        commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSFORMATION}/${factoryInArray.data.id}`}
        handlerSuppress={() =>
          setFactoriesInArray(
            factoriesInArray.filter(f => f.data.id !== factoryInArray.data.id)
          )
        }
        isValidated={isDisabled}
      />,
    ];
  });

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredHeaderHeight={75}
        preferredColWidths={[250, 140, 140, 140, 170, 170, 120]}
        additionalClassName={classes.table}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setFactoriesInArray([])}
        />
      </Row>
    </>
  );
};

export default FactoriesArray;
