import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useChoiceRadioFieldGenerator,
  useDateFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Button from "common/button";
import Row from "common/presentational/Row";
import {
  filterFieldMatcher,
  FilterFormValues,
  lessThanValidation,
  moreThanValidation,
} from "../types";
import { useActiveOrLastCampaign } from "pages/CommonSpace/CampaignContext";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import {
  LecteurDroitDtoAllowedSectionsEnum,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";
import { FetchedReferentials } from "../../../CommonSpace/ReferentialsContext/types";
import {
  computeDepartementLabel,
  computeRegionLabel,
  filterDepartementsInRegion,
  filterDepartementsList,
  filterRegionList,
} from "./helpers/locations";
import {
  computeStateLabel,
  globalStateOptions,
  quotasStateOptions,
} from "./helpers/declarationStates";
import { useUserData } from "Authenticator/UserData";
import FormikAdapter from "../../../../libAdapter/Formik/FormikAdapter";

const useStyles = makeStyles({
  buttonRow: {
    display: "flex",
    marginTop: "15px",
    justifyContent: "end",
  },
  button: {
    height: "100%",
  },
});

interface FilterFormProps {
  filters: FilterFormValues;
  setFilters: (filters: FilterFormValues) => void;
  close: () => void;
  departementNumbersList: string[];
  referentialLocations: FetchedReferentials<
    "REFERENCE_DEPARTEMENTS" | "REFERENCE_REGIONS"
  > | null;
  years: number[];
}

const FilterForm = ({
  filters,
  setFilters,
  close,
  departementNumbersList,
  referentialLocations,
  years,
}: FilterFormProps): React.ReactElement => {
  const classes = useStyles();
  const {
    isLecteur,
    isLecteurOnSection,
    isLecteurOnlyOnSection,
  } = useUserData();

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "",
  };

  const departementsList = filterDepartementsList(
    (referentialLocations &&
      referentialLocations.REFERENCE_DEPARTEMENTS &&
      referentialLocations.REFERENCE_DEPARTEMENTS.departements) ||
      [],
    departementNumbersList
  );

  const regionsList = filterRegionList(
    (referentialLocations &&
      referentialLocations.REFERENCE_REGIONS &&
      referentialLocations.REFERENCE_REGIONS.referenceItemRegionDtoList) ||
      [],
    departementsList
  );

  const NumberField = useNumberFieldGenerator(commonProps);
  const BooleanCheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const showToLecteurCarriere =
    isLecteur &&
    isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) &&
    isLecteurOnSection(
      LecteurDroitDtoAllowedSectionsEnum.QUOTAS ||
        LecteurDroitDtoAllowedSectionsEnum.EAU
    );
  const showToLecteurQuotas =
    isLecteur && isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);
  const isOnlyLecteurQuotas = isLecteurOnlyOnSection(
    LecteurDroitDtoAllowedSectionsEnum.QUOTAS
  );

  return (
    <FormikAdapter
      initialValues={filters}
      onSubmit={values => {
        setFilters(values);
        close();
      }}
      render={({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <WrappedChoiceSelect
            name={filterFieldMatcher.region}
            label="Région"
            options={regionsList.sort((region1, region2) =>
              computeRegionLabel(region1).localeCompare(
                computeRegionLabel(region2)
              )
            )}
            computeLabel={(lineData: ReferenceItemRegionDto | null) =>
              lineData ? computeRegionLabel(lineData) : ""
            }
            additionalOnChange={(newValue: ReferenceItemRegionDto | null) => {
              newValue !== null &&
                setFieldValue(filterFieldMatcher.departement, null);
            }}
            commonProps={commonProps}
            isMulti={false}
          />

          <WrappedChoiceSelect
            name={filterFieldMatcher.departement}
            label="Département"
            options={filterDepartementsInRegion(
              departementsList,
              values.region
            )}
            computeLabel={(lineData: ReferenceItemDepartementDto | null) =>
              lineData ? computeDepartementLabel(lineData) : ""
            }
            commonProps={commonProps}
            isMulti={false}
          />

          {!isOnlyLecteurQuotas && (
            <WrappedChoiceSelect
              label={() => (
                <>
                  État de la déclaration
                  <br />
                  hors quotas
                </>
              )}
              name={filterFieldMatcher.declarationGlobalState}
              options={globalStateOptions}
              computeLabel={computeStateLabel}
              commonProps={commonProps}
              isMulti={false}
            />
          )}

          <WrappedChoiceSelect
            label={() => (
              <>
                État de la déclaration
                <br />
                quotas émissions
              </>
            )}
            name={filterFieldMatcher.quotasEmissionsState}
            options={quotasStateOptions}
            computeLabel={computeStateLabel}
            commonProps={commonProps}
            isMulti={false}
            additionalOnChange={newDeclarationState => {
              setFieldValue(
                filterFieldMatcher.quotasEmissions,
                newDeclarationState !== null
              );
            }}
          />

          <WrappedChoiceSelect
            label={() => (
              <>
                État de la déclaration
                <br />
                quotas niveaux d'activité
              </>
            )}
            name={filterFieldMatcher.quotasAllocationsState}
            options={quotasStateOptions}
            computeLabel={computeStateLabel}
            commonProps={commonProps}
            isMulti={false}
            additionalOnChange={newDeclarationState => {
              setFieldValue(
                filterFieldMatcher.quotasAllocations,
                newDeclarationState !== null
              );
            }}
          />

          <WrappedChoiceSelect
            label="Année de la déclaration"
            name={filterFieldMatcher.declarationYear}
            options={years}
            computeLabel={annee => annee.toString()}
            commonProps={commonProps}
            isMulti={true}
          />

          <>
            <NumberField
              label="Taux de validation"
              name={filterFieldMatcher.declarationValidationPercent}
              unit="%"
            />
            <ChoiceRadioField
              label={""}
              name={filterFieldMatcher.moreOrLessThanValidationPercent}
              choices={[lessThanValidation, moreThanValidation]}
            />
            <DateField
              label="Date de la dernière action déclarant"
              name={filterFieldMatcher.lastModificationTimeDeclaring}
            />
            <BooleanCheckboxField
              label={"Avant la date demandée"}
              name={filterFieldMatcher.beforeLastModificationDeclaring}
            />
            <DateField
              label="Date de la dernière action inspecteur"
              name={filterFieldMatcher.lastModificationTimeInspector}
            />
            <BooleanCheckboxField
              label={"Avant la date demandée"}
              name={filterFieldMatcher.beforeLastModificationInspector}
            />
            {(!isLecteur || showToLecteurCarriere) && (
              <BooleanCheckboxField
                label="Carrière"
                name={filterFieldMatcher.carriere}
              />
            )}
            {!isLecteur && (
              <BooleanCheckboxField
                label="Élevage"
                name={filterFieldMatcher.elevage}
              />
            )}
            {!isLecteur && (
              <BooleanCheckboxField
                label="E-PRTR"
                name={filterFieldMatcher.eprtr}
              />
            )}
            {(!isLecteur || showToLecteurQuotas) && (
              <BooleanCheckboxField
                label="Quotas - Emissions"
                name={filterFieldMatcher.quotasEmissions}
                additionalOnChange={newIsQuotas => {
                  if (!newIsQuotas && values.quotasEmissionsState !== null) {
                    setFieldValue(
                      filterFieldMatcher.quotasEmissionsState,
                      null
                    );
                  }
                }}
              />
            )}
            {(!isLecteur || showToLecteurQuotas) && (
              <BooleanCheckboxField
                label="Quotas - Niveaux d’activité"
                name={filterFieldMatcher.quotasAllocations}
                additionalOnChange={newIsQuotasAllocations => {
                  if (
                    !newIsQuotasAllocations &&
                    values.quotasAllocationsState !== null
                  ) {
                    setFieldValue(
                      filterFieldMatcher.quotasAllocationsState,
                      null
                    );
                  }
                }}
              />
            )}
            {(!isLecteur || showToLecteurQuotas) && (
              <BooleanCheckboxField
                label="Quotas - Exclusions, incinérateur"
                name={filterFieldMatcher.quotasWithAnyExclusions}
              />
            )}
            {!isLecteur && (
              <BooleanCheckboxField
                label="ISDI"
                name={filterFieldMatcher.isdi}
              />
            )}
            {!isLecteur && (
              <BooleanCheckboxField
                label="ISDND"
                name={filterFieldMatcher.isdnd}
              />
            )}

            {!isLecteur && (
              <BooleanCheckboxField
                label="Consommation de solvants"
                name={filterFieldMatcher.consoOfSolvant}
              />
            )}
          </>

          <Row additionalClassname={classes.buttonRow}>
            <Button
              text="Rechercher"
              type="submit"
              additionalClassname={classes.button}
            />
          </Row>
        </form>
      )}
    />
  );
};

export default FilterForm;
