import {
  QuotasAllocationsVerificationDto20NowConclusionEnum,
  QuotasAllocationsVerificationDto20NowConditionDispenseEnum,
  QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum,
  QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum,
  QuotasAllocationsVerificationDto20NowOrganismeEnum,
} from "api/gen";

export const organismesLabels: {
  [key in QuotasAllocationsVerificationDto20NowOrganismeEnum]: string;
} = {
  APAVE: "APAVE",
  BUREAU_VERITAS_CERTIFICATION_FRANCE: "BUREAU VERITAS CERTIFICATION FRANCE",
  DNV_GL_BUSINESS_ASSURANCE_FRANCE_SARL:
    "DNV GL BUSINESS ASSURANCE France SARL",
  EY_ET_ASSOCIES: "EY & Associés",
  LRQA_FRANCE_SAS: "LRQA France SAS",
  SGS_FRANCE: "SGS FRANCE",
  SOCOTEC_ENVIRONNEMENT: "SOCOTEC Environnement",
};

export const organismesValues = Object.keys(
  organismesLabels
) as QuotasAllocationsVerificationDto20NowOrganismeEnum[];

export const exemptionConditionLabels: {
  [key in QuotasAllocationsVerificationDto20NowConditionDispenseEnum]: string;
} = {
  CONDITION_III_A: "Condition 3bis (point 3bis - art. 32 AVR)",
  CONDITION_III_B: "Condition 3ter (point 3ter - art. 32 AVR)",
  CONDITION_III_C: "Condition 3quarter (point 3quarter - art. 32 AVR)",
  CONDITION_IV: "Condition 4 (point 4 - art. 32 AVR)",
  CONDITION_V: "Condition 5 (point 5 - art. 32 AVR)",
  AUTRE: "Autre",
};

export const exemptionConditionOptions = Object.keys(
  exemptionConditionLabels
) as QuotasAllocationsVerificationDto20NowConditionDispenseEnum[];

export const satisfyingDeclarationReasonLabels: {
  [key in QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum]: string;
} = {
  INEXACTITUDE: "Inexactitude(s) non significative(s)",
  IRREGULARITE:
    "Irrégularité(s) n'entraînant pas d'inexactitude(s) significative(s)",
  NON_RESPECT_FAR_ALC:
    "Cas de non-respect du règlement FAR ou du règlement ALC n'entraînant pas d'inexactitude(s) significative(s)",
  RECOMMANDATION_AMELIORATION: "Recommandation(s) d'amélioration",
};

export const satisfyingDeclarationReasonOptions = Object.keys(
  satisfyingDeclarationReasonLabels
) as QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum[];

export const unsatisfyingDeclarationReasonLabels: {
  [key in QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum]: string;
} = {
  INEXACTITUDE: "Inexactitude(s) significative(s)",
  IRREGULARITE:
    "Irrégularité(s) entraînant une ou plusieurs inexactitude(s) significative(s)",
  NON_RESPECT_FAR_ALC:
    "Cas de non-respect du règlement FAR ou du règlement ALC entraînant une ou plusieurs inexactitude(s) significative(s)",
  DONNES_MANQUANTE_VERIFICATION:
    "Portée limitée due à des données manquantes ou des informations fournies pour la vérification insuffisantes",
  PLAN_SURVEILLANCE_INCOMPLET:
    "Portée limitée ou clarté insuffisante du plan méthodologique de surveillance",
  PLAN_SURVEILLANCE_NON_APPROUVE:
    "Portée limitée due à un plan méthodologique de surveillance non approuvé par l'autorité compétente",
};

export const unsatisfyingDeclarationReasonOptions = Object.keys(
  unsatisfyingDeclarationReasonLabels
) as QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum[];

export const verificationReportConclusionLabels: {
  [key in QuotasAllocationsVerificationDto20NowConclusionEnum]: string;
} = {
  SATISFAISANT: "Déclaration reconnue satisfaisante",
  SATISFAISANT_AVEC_REMARQUE:
    "Déclaration reconnue satisfaisante avec remarques",
  NON_SATISFAISANT:
    "Déclaration reconnue non satisfaisante (données ne pouvant pas être vérifiées)",
};

export const verificationReportConclusionOptions = Object.keys(
  verificationReportConclusionLabels
) as QuotasAllocationsVerificationDto20NowConclusionEnum[];

export const isReportUnsatisfying = (
  conclusion: QuotasAllocationsVerificationDto20NowConclusionEnum | null
): boolean => {
  return (
    conclusion ===
    QuotasAllocationsVerificationDto20NowConclusionEnum.NON_SATISFAISANT
  );
};

export const isSatisfyingWithRequests = (
  conclusion: QuotasAllocationsVerificationDto20NowConclusionEnum | null
): boolean => {
  return (
    conclusion ===
    QuotasAllocationsVerificationDto20NowConclusionEnum.SATISFAISANT_AVEC_REMARQUE
  );
};
