import { PATH_QUOTAS } from "./path18Now";

//QUOTAS
export const PATH_QUOTAS_INFOGENERALE_BLOC = `${PATH_QUOTAS}/blocInfoGenerales`;
export const PATH_QUOTAS_INSTALLATION_BLOC = `${PATH_QUOTAS}/blocInstallations`;
export const PATH_QUOTAS_INSTALLATION = `${PATH_QUOTAS_INSTALLATION_BLOC}/installations`;
export const PATH_QUOTAS_PLAN_BLOC = `${PATH_QUOTAS}/blocPlanSurveillance`;
export const PATH_QUOTAS_EMISSIONS_BLOC = `${PATH_QUOTAS}/blocEmissions`;
export const PATH_QUOTAS_EMISSIONS_BLOC_METHODES_ALTERNATIVES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsMethodeAlternative`;
export const PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_CALCULEES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsCalculees`;
export const PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_MESUREES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsMesurees`;
export const PATH_QUOTAS_EMISSIONS_BLOC_FLUX = `${PATH_QUOTAS_EMISSIONS_BLOC}/flux`;
export const PATH_QUOTAS_EMISSIONS_BLOC_POINT_MESURE = `${PATH_QUOTAS_EMISSIONS_BLOC}/pointsMesure`;
export const PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION = `${PATH_QUOTAS_EMISSIONS_BLOC}/derogationFrequenceEchantillonnage`;
export const PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION = `${PATH_QUOTAS_EMISSIONS_BLOC}/fluxSoumisADerogation`;
export const PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2 = `${PATH_QUOTAS_EMISSIONS_BLOC}/transfertCO2`;
export const PATH_QUOTAS_BILAN_BLOC = `${PATH_QUOTAS}/blocBilanTotal`;
export const PATH_QUOTAS_VERIFICATION_BLOC = `${PATH_QUOTAS}/blocVerification`;
export const PATH_QUOTAS_INSPECTEUR_BLOC = `${PATH_QUOTAS}/blocInspecteur`;
