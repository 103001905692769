import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DeclarationConstraintViolationDto } from "api/gen/api";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";

/**
 * Use this hook to find errors for blocVerification to display in a ViolationProcessor.
 * Similar to useDeclarationErrorAndWarnings, but since backend does not give us specific errors
 * for this bloc in declaration's error, we have to build those errors by ourselves in frontend.
 */
export const useVerificationErrors = () => {
  const declaration = useDeclaration1919();
  const isMounted = useRef<boolean>(false);
  const [hasValidateOnce, setHasValidateOnce] = useState(false);

  const errors = useMemo(() => {
    const currentErrors: DeclarationConstraintViolationDto[] = [];

    // Must have exactly one uploaded file per nim
    if (
      declaration.body.sections.quotas.blocVerification.rapports.length !==
      declaration.body.sections.quotas.blocInstallations.installations.length
    ) {
      currentErrors.push({
        message: "Un rapport de vérification doit être importé par NIM.",
        preventSubmit: false,
        path: "",
        commentRequired: false,
      });
    }

    return currentErrors;
  }, [
    declaration.body.sections.quotas.blocVerification.rapports,
    declaration.body.sections.quotas.blocInstallations.installations,
  ]);

  const hasError = useMemo(() => errors.length > 0, [errors]);

  const triggerValidation = useCallback(
    async (promess: Promise<void>) => {
      await promess;
      if (!isMounted.current) {
        return;
      }
      setHasValidateOnce(true);
    },
    [setHasValidateOnce, isMounted]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return {
    hasValidateOnce,
    triggerValidation,
    errors,
    hasError,
  };
};
