import * as Yup from "yup";
import {
  ProductedWasteImport,
  ReceivedProductedImportArrayWithMessage,
} from "./types";
import { Errors } from "common/form/utils";
import {
  DechetProduitDto1819,
  DechetProduitDto1819LieuOperationEnum,
  ReferenceItemDechetDto,
} from "api/gen";
import { WasteReferentiels } from "../types";
import { Nullable } from "common/utils/types";
import { computeRefMethodForMethod } from "../utils/Referentiels";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { findElementMatchingTemplate } from "common/utils/methods";

export function filterPolluantProductionCSVArray(
  arrayResults: string[][]
): ReceivedProductedImportArrayWithMessage {
  const results: Nullable<ProductedWasteImport>[] = [];
  arrayResults.forEach(ele => {
    let hasData = false;
    for (let i = 0; i < 13; i++) {
      if (ele[i] !== undefined && ele[i] !== "") {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      return;
    }

    let department: string | null = ele[6] || null;
    if (department && department.length === 1) {
      department = `0${department}`;
    }

    const wasteId = ele[0] !== undefined ? ele[0].replace("*", "") : null;
    const amount =
      ele[1] !== undefined && ele[1] !== ""
        ? parseFloat(ele[1].replace(",", "."))
        : null;
    const value: Nullable<ProductedWasteImport> = {
      wasteId: wasteId,
      wasteAmount: amount,
      methode: ele[2],
      methodeRef: ele[3] || null,
      precisionRefMethod: ele[4] || null,
      operationElimOuValo: ele[5] || null,
      departement: department,
      pays: ele[7] || null,
      etablissementReception: ele[8] || null,
      etablissementReceptionAdress: ele[9] || null,
      etablissementElimination: ele[10] || null,
      etablissementEliminationAdress: ele[11] || null,
      numeroNotification: ele[12] || null,
    };

    results.push(value);
  });
  return { filteredArray: results, errorMessage: null };
}

export const validationSchema = Yup.object().shape({});

const isWasteInReferentiel = (
  waste: DechetProduitDto1819,
  referentiel: ReferenceItemDechetDto[]
) => {
  if (!waste.codeID) {
    return false;
  }

  const wasteRef = referentiel.find(element => element.uuid === waste.codeID);
  return wasteRef !== undefined;
};

export const generateProductedWasteErrors = (
  waste: DechetProduitDto1819,
  referentiel: WasteReferentiels
): Errors<DechetProduitDto1819> => {
  const errors: Errors<DechetProduitDto1819> = {};
  if (!isWasteInReferentiel(waste, referentiel.polluants)) {
    errors.codeID = requiredMessage;
  }
  if (!waste.lieuOperation) {
    errors.lieuOperation = requiredMessage;
  }
  if (waste.quantite === null) {
    errors.quantite = requiredMessage;
  }
  const method =
    waste.method === null
      ? null
      : findElementMatchingTemplate(
          { backOfficeCode: waste.method },
          referentiel.methods
        );
  if (!method) {
    errors.method = requiredMessage;
  } else {
    const availableMethodRefs = computeRefMethodForMethod(method);
    if (
      availableMethodRefs.length > 0 &&
      (!waste.methodReference ||
        !availableMethodRefs.includes(waste.methodReference))
    ) {
      errors.methodReference = requiredMessage;
    }
  }

  if (
    waste.operationElimination === null ||
    !findElementMatchingTemplate(
      { code: waste.operationElimination },
      referentiel.operationOrValorisationForProduction
    )
  ) {
    errors.operationElimination = requiredMessage;
  }
  if (!waste.lieuOperation) {
    if (!waste.nomEtablissementReception) {
      errors.nomEtablissementReception = requiredMessage;
    }
    if (!waste.adresseEtablissementReception) {
      errors.adresseEtablissementReception = requiredMessage;
    }
    if (!waste.nomEtablissementElimination) {
      errors.nomEtablissementElimination = requiredMessage;
    }
    if (!waste.adresseEtablissementElimination) {
      errors.adresseEtablissementElimination = requiredMessage;
    }
  } else if (
    waste.lieuOperation === DechetProduitDto1819LieuOperationEnum.DEPARTEMENT
  ) {
    if (!waste.departementID) {
      errors.departementID = requiredMessage;
    } else if (
      findElementMatchingTemplate(
        { uuid: waste.departementID },
        referentiel.departements
      )
    ) {
      if (
        !waste.nomEtablissementReception ||
        waste.nomEtablissementReception.trim().length === 0
      ) {
        errors.nomEtablissementReception = requiredMessage;
      }
      if (
        !waste.adresseEtablissementReception ||
        waste.adresseEtablissementReception.trim().length === 0
      ) {
        errors.adresseEtablissementReception = requiredMessage;
      }
    } else {
      errors.departementID = requiredMessage;
    }
  } else if (
    waste.lieuOperation === DechetProduitDto1819LieuOperationEnum.PAYS
  ) {
    if (!waste.paysID) {
      errors.paysID = requiredMessage;
    } else {
      const optionCountry = findElementMatchingTemplate(
        {
          uuid: waste.paysID,
        },
        referentiel.pays
      );
      if (!optionCountry) {
        errors.paysID = requiredMessage;
      }
    }
    if (
      !waste.nomEtablissementReception ||
      waste.nomEtablissementReception.trim().length === 0
    ) {
      errors.nomEtablissementReception = requiredMessage;
    }

    if (
      !waste.adresseEtablissementReception ||
      waste.adresseEtablissementReception.trim().length === 0
    ) {
      errors.adresseEtablissementReception = requiredMessage;
    }

    if (
      !waste.nomEtablissementElimination ||
      waste.nomEtablissementElimination.trim().length === 0
    ) {
      errors.nomEtablissementElimination = requiredMessage;
    }

    if (
      !waste.adresseEtablissementElimination ||
      waste.adresseEtablissementElimination.trim().length === 0
    ) {
      errors.adresseEtablissementElimination = requiredMessage;
    }
  }
  if (
    waste.lieuOperation &&
    !findElementMatchingTemplate(
      {
        backCode: waste.lieuOperation,
      },
      referentiel.productionLocation
    )
  ) {
    errors.lieuOperation = "cette valeur n'est pas dans le référentiel";
  }

  return errors;
};
