import { divide, H32info, h32infos, multiply } from "./h32infos";
import { CombustibleInModale } from "../../blocCombustibles/utils/types";

const getMasseVolumiqueValue = (
  operationMasseVolumique: string | null,
  combustibleMasse: number | null
) => {
  if (operationMasseVolumique === multiply) {
    return combustibleMasse;
  }
  if (operationMasseVolumique === divide) {
    if (combustibleMasse !== 0 && combustibleMasse !== null) {
      return 1 / combustibleMasse;
    }
    return 1;
  }
  return 1;
};

const computeConsommationValue = (
  combustible: CombustibleInModale,
  shouldUsePCIValue: boolean
): number => {
  if (combustible.consommationPCI !== null && shouldUsePCIValue) {
    return combustible.consommationPCI;
  }
  return combustible.consommation === null ? 1 : combustible.consommation;
};

export const computeH32 = (
  facteur: number | null,
  facteurOxydation: number | null,
  combustible: CombustibleInModale
): number | null => {
  const info: H32info | undefined = h32infos.find(infos => {
    if (combustible.unite) {
      return (
        infos.uniteCombustible === combustible.unite.label &&
        (combustible.pciUnite === infos.unitePci ||
          (combustible.pciUnite !== null &&
            combustible.pciUnite.label === infos.unitePci))
      );
    }
    return false;
  });
  if (info && facteur !== null) {
    const pciValeur = info.multiplyByPciAndEmissionFactor
      ? combustible.pciValeur
      : 1;
    const operationMasseVolumique = getMasseVolumiqueValue(
      info.operationMasseVolumique,
      combustible.masse
    );
    const facteurOxydationEmission =
      facteurOxydation !== null && facteurOxydation !== undefined
        ? facteurOxydation / 100
        : 1;

    const consommation = computeConsommationValue(
      combustible,
      info.multiplyByPciAndEmissionFactor
    );

    return (
      consommation *
      (pciValeur === null ? 1 : pciValeur) *
      facteur *
      info.convertionFactorCombustible *
      info.convertionFactorPci *
      (operationMasseVolumique === null ? 1 : operationMasseVolumique) *
      facteurOxydationEmission
    );
  }
  return null;
};
