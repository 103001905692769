import React from "react";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "../../../DeclarationApiContext/utils/types";
import { DeclarationStateDtoStateEnum } from "api/gen";
import {
  Declaration2020,
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
  useDeclarationHelpers2020,
} from "./declarationHooks2020";
import { CommonBlocInContextProps } from "common/bloc/types";
import GenericBlocFullContext from "common/bloc/GenericBlocFullContext";
import { DeclarationConstraintViolation19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import { useDeclarationErrorAndWarnings2020 } from "./errorAndWarningDisplayer2020/utils";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

type BlocInContext2020Props = CommonBlocInContextProps<Declaration2020>;

export const BlocFullContext2020 = (
  props: BlocInContext2020Props
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration2020 = useDeclaration2020;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers2020;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration2020
  > = useBasicDeclarationHandlers2020;
  const getDeclarationState: (
    declarationDto: Declaration2020
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings2020;

  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );
  return (
    <GenericBlocFullContext<
      Declaration2020,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      areGlobalCommentsAllowed={props.areGlobalCommentsAllowed}
      {...props}
    />
  );
};

export default BlocFullContext2020;
