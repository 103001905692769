import { AirSolvantsInformationDtoDerogationEnum } from "api/gen/api";
import { AirSolvantsInformationDto } from "api/gen";
import { Information } from "./types";

export const createAirSolvantsInformationDto = (
  infoDisplayed: Information | null,
  originalDto: AirSolvantsInformationDto
): AirSolvantsInformationDto => {
  if (infoDisplayed) {
    return {
      ...originalDto,
      ...infoDisplayed,
      consommation:
        infoDisplayed.consommation !== null ? infoDisplayed.consommation : 0,
    };
  }
  return {
    ...originalDto,
    activites: [],
    conforme: false,
    consommation: 0,
    danger: true,
    derogation: AirSolvantsInformationDtoDerogationEnum.NONE,
    ied: true,
    sme: false,
  };
};

export const convertDtoToInformation = (
  informations: AirSolvantsInformationDto
): Information => {
  const { files, ...infosDisplayed } = informations;
  return infosDisplayed;
};
