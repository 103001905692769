import React, { ReactElement } from "react";
import { PATH_QUOTAS_ALC_PRELIMINAIRE } from "common/path/path20Now";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_GREY, SECTION_TITLE_STYLE } from "theme";
import AlcRapportFileLoader from "./AlcRapportFileLoader";
import JustificatifsFilesLoader from "./JustificatifsFilesLoader";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import BlocFullContext21Now from "pages/CompanySpace/from21/toNow/versionedElements/BlocFullContext21Now";
import { useDeclaration2122 } from "pages/CompanySpace/from21/to22/versionedElements/declarationHooks2122";
import { useNiveauxActiviteValidationMessage } from "pages/CompanySpace/from21/toNow/Quotas/utils/utils";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ...SECTION_TITLE_STYLE,
});

const QuotasAlcPreliminaireForm = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2122();
  const validationMessage = useNiveauxActiviteValidationMessage();
  const installations =
    declaration.computed.sections.quotas.allocations.installations;

  const blocPath = PATH_QUOTAS_ALC_PRELIMINAIRE;

  const filesPath = "body.sections.quotas.allocations.blocAlc.alcPreliminaire";

  return (
    <BlocFullContext21Now
      hasModification={false}
      isValidateButtonAvailable={true}
      editValidationMessage={validationMessage}
      title={"RAPPORT DE DECLARATION DES NIVEAUX D’ACTIVITE (PRELIMINAIRE)"}
      path={blocPath}
      updateHandler={declaration => {
        return declaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          <div className={classes.text}>
            <p>
              Le dépôt est facultatif, mais la section doit être validée (avec
              ou sans dépôt).
            </p>
          </div>
          <Row height={"20px"} />
          {installations.map(installation => (
            <div className={classes.row} key={installation.id}>
              <div className={classes.sectionTitle}>
                NIM à remplir : {installation.nim}
                <CommentButton path={`${blocPath}/${installation.id}`} />
              </div>
              <AlcRapportFileLoader
                installation={installation}
                filesPath={filesPath}
                shouldDisableFields={shouldDisabledFields}
              />
              <JustificatifsFilesLoader
                installation={installation}
                filesPath={filesPath}
                shouldDisableFields={shouldDisabledFields}
              />
            </div>
          ))}
        </>
      )}
    />
  );
};

export default QuotasAlcPreliminaireForm;
