import { AirFugitivesProcedesDto } from "api/gen";
import { arrayUnits } from "./selectPossibleValues";
import { ProcedeInArray } from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";

export const createAirFugitivesProcedeDto = (
  ProcedesInPage: ProcedeInArray[]
) => {
  const procedes = ProcedesInPage.map((singlePopulatedProcede, index) => {
    const singleFugitive = singlePopulatedProcede.data;
    if (singleFugitive.unit === null) {
      throw Error("Should not happen");
    }
    const singleProcede: AirFugitivesProcedesDto = {
      id: singlePopulatedProcede.data.id,
      precision: singleFugitive.unitPrecision,
      masse: singleFugitive.density,
      nom: singleFugitive.name,
      quantite: singleFugitive.quantity,
      unite: singleFugitive.unit && singleFugitive.unit.object.code,
    };
    return singleProcede;
  });

  return procedes;
};

export const convertProcedeToDisplayed = (
  procede: AirFugitivesProcedesDto
): ProcedeInArray => {
  const unit =
    procede.unite === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: procede.unite } },
          arrayUnits
        );
  return {
    data: {
      id: procede.id,
      name: procede.nom,
      quantity: procede.quantite,
      unit: unit,
      unitPrecision: procede.precision,
      density: procede.masse,
    },
    errors: {},
  };
};
