import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { VibrationMeasuresForm } from "./types";

export const convertDtoToVibrationMeasuresForm = (
  declaration: Declaration1919
): VibrationMeasuresForm => {
  return {
    vibrationMeasuresSubmitted:
      declaration.body.sections.carriere.environnement.vibration
        .soumisMesureVibrations,
    explosiveUtilisation:
      declaration.body.sections.carriere.environnement.vibration
        .campagneExplosion || false,
    explosiveMeasuresDone: !!declaration.body.sections.carriere.environnement
      .vibration.mesuresExplosion.mesureEffectuee,
    explosiveMeasurePointsNumber:
      declaration.body.sections.carriere.environnement.vibration
        .mesuresExplosion.nbPointsMesure,
    explosiveMeasureCampaignsNumber:
      declaration.body.sections.carriere.environnement.vibration
        .mesuresExplosion.nbCampagnesMesure,
    vibrationUtilisation:
      declaration.body.sections.carriere.environnement.vibration
        .campagneVibration || false,
    vibrationMeasuresDone: !!declaration.body.sections.carriere.environnement
      .vibration.mesuresVibration.mesureEffectuee,
    vibrationMeasurePointsNumber:
      declaration.body.sections.carriere.environnement.vibration
        .mesuresVibration.nbPointsMesure,
    vibrationMeasureCampaignsNumber:
      declaration.body.sections.carriere.environnement.vibration
        .mesuresVibration.nbCampagnesMesure,
    errorsAtLeastRequired: null,
  };
};
