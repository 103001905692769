import React, { useState } from "react";
import Row from "common/presentational/Row";
import ListProcede from "./ListProcede";
import Emission from "./Emission";
import CovDanger from "./CovDanger";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./Emission/utils/types";
import { CovInArray } from "./CovDanger/utils/types";
import Tabs from "common/tabs";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import {
  convertDtoToProcedeInArray,
  convertProcedeInArrayToDto,
} from "./ListProcede/utils/utils";
import {
  convertCovInArrayToDto,
  convertDtoToCovInArray,
} from "./CovDanger/utils/utils";
import {
  convertDtoToCorrelationEmissionInArray,
  convertDtoToMatiereEmissionInArray,
  convertDtoToMesureEmissionInArray,
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "./Emission/utils/utils";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { ProcedesInfo } from "./utils/types";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import isEqual from "lodash.isequal";
import {
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
  ReferencePolluantDto,
} from "api/gen";
import MessageInfoField from "common/form/MessageInfoField";
import { ProcedeInArray } from "./ListProcede/utils/types";
import { DISABLED_SECTION_STYLE } from "theme";
import {
  PATH_AIR_PROCEDES,
  PATH_AIR_PROCEDES_EMISSION,
  PATH_AIR_PROCEDES_FLUX,
  PATH_AIR_PROCEDES_MENTIONDANGER,
  PATH_AIR_PROCEDES_PROCEDES,
} from "common/path/path18Now";
import FormikBlocFullContext2023 from "../../versionedElements/FormikBlocFullContext2023";
import GlobalFormActionFullContext2023 from "../../versionedElements/GlobalFormActionFullContext2023";
import {
  useDeclaration2023,
  useDeclarationHelpers2023,
} from "../../versionedElements/declarationHooks2023";

const useStyles = makeStyles({
  ...DISABLED_SECTION_STYLE,
});

interface MainProcedeFormProps {
  referential: ReferencePolluantDto;
  referentialCO2: ReferenceItemPolluantDto;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialAutreElementUuid: string;
}

const MainProcedeForm = ({
  referential,
  referentialCO2,
  polluantElementMap,
  referentialAutreElementUuid,
}: MainProcedeFormProps): React.ReactElement => {
  const declaration = useDeclaration2023();
  const classes = useStyles();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2023();

  const substances: ReferenceItemPolluantDto[] = referential.polluants;

  const blocFugitivesDto = declaration.body.sections.air.fugitives;

  const initialProcedes = blocFugitivesDto.procedes.map(procede =>
    convertDtoToProcedeInArray(procede)
  );
  const initialCovs = blocFugitivesDto.flux.map(flux =>
    convertDtoToCovInArray(flux, initialProcedes)
  );
  const initialCorrelation = blocFugitivesDto.emission.correlation.map(
    correlation =>
      convertDtoToCorrelationEmissionInArray(
        correlation,
        initialProcedes,
        substances
      )
  );
  const initialMesure = blocFugitivesDto.emission.mesure.map(mesure =>
    convertDtoToMesureEmissionInArray(mesure, initialProcedes, substances)
  );
  const initialMatiere = blocFugitivesDto.emission.bilan.map(matiere =>
    convertDtoToMatiereEmissionInArray(
      matiere,
      initialProcedes,
      substances,
      polluantElementMap
    )
  );
  const [procedesInPage, setProcedesInPage] = useState<ProcedeInArray[]>(
    initialProcedes
  );

  const initialProcedesMap = new Map<string, ProcedeInArray>(
    initialProcedes.map(procede => [procede.data.id, procede])
  );

  const initialMentionDanger =
    declaration.body.sections.air.fugitives.mentionDanger;
  const [covsInPage, setCovsInPage] = useState<CovInArray[]>(initialCovs);
  const [correlationEmissionsInPage, setCorrelationEmissionsInPage] = useState<
    CorrelationEmissionInArray[]
  >(initialCorrelation);
  const [mesureEmissionsInPage, setMesureEmissionsInPage] = useState<
    MesureEmissionInArray[]
  >(initialMesure);
  const [matiereEmissionsInPage, setMatiereEmissionsInPage] = useState<
    MatiereEmissionInArray[]
  >(initialMatiere);

  const pageHasChanges =
    !isEqual(initialProcedes, procedesInPage) ||
    !isEqual(initialMesure, mesureEmissionsInPage) ||
    !isEqual(initialCorrelation, correlationEmissionsInPage) ||
    !isEqual(initialMatiere, matiereEmissionsInPage) ||
    !isEqual(initialCovs, covsInPage);

  const isListProcedeValidated = isPathValidatedInDeclaration(
    PATH_AIR_PROCEDES_PROCEDES
  );
  const isListCovValidated = isPathValidatedInDeclaration(
    PATH_AIR_PROCEDES_FLUX
  );
  const isListEmissionValidated = isPathValidatedInDeclaration(
    PATH_AIR_PROCEDES_EMISSION
  );
  const isProcedesInfoValidated = isPathValidatedInDeclaration(
    PATH_AIR_PROCEDES_MENTIONDANGER
  );

  const canSubmitForm = () => {
    //https://github.com/Polyconseil/mtes-gerep/pull/555 Validation front. Volonté de Jonathan.
    return (
      (procedesInPage.length === 0 &&
        isListProcedeValidated &&
        isProcedesInfoValidated &&
        !initialMentionDanger) ||
      (isListProcedeValidated &&
        (!initialMentionDanger || isListCovValidated) &&
        isListEmissionValidated &&
        isProcedesInfoValidated)
    );
  };

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "bloc-procedes-info",
  };
  const formReception = useFormikBloc<ProcedesInfo>();
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const hasMentionDanger =
    declaration.body.sections.air.fugitives.mentionDanger;

  const tabsContent = [
    {
      title: "ÉMISSIONS",
      renderComponent: () => (
        <Emission
          validationEmissionPath={PATH_AIR_PROCEDES_EMISSION}
          setMatiereEmissionsInPage={setMatiereEmissionsInPage}
          matiereEmissionsInPage={matiereEmissionsInPage}
          initialMatiere={initialMatiere}
          setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
          correlationEmissionsInPage={correlationEmissionsInPage}
          initialCorrelation={initialCorrelation}
          setMesureEmissionsInPage={setMesureEmissionsInPage}
          mesureEmissionsInPage={mesureEmissionsInPage}
          initialMesure={initialMesure}
          procedesInPageMap={initialProcedesMap}
          initialProcedes={initialProcedes}
          covsInPage={covsInPage}
          substances={substances}
          referentialCO2={referentialCO2}
          polluantElementMap={polluantElementMap}
          referentialElementAutreUuid={referentialAutreElementUuid}
          showValidationMessage={hasMentionDanger}
        />
      ),
    },
  ];

  if (hasMentionDanger) {
    tabsContent.push({
      title: "ÉMISSIONS DE COV PAR MENTION DE DANGER",
      renderComponent: () => (
        <CovDanger
          validationCovPath={PATH_AIR_PROCEDES_FLUX}
          procedesInPageMap={initialProcedesMap}
          initialCovs={initialCovs}
          covsInPage={covsInPage}
          setCovsInPage={setCovsInPage}
          correlationEmissionsInPage={correlationEmissionsInPage}
          mesureEmissionsInPage={mesureEmissionsInPage}
          matiereEmissionsInPage={matiereEmissionsInPage}
        />
      ),
    });
  }

  return (
    <>
      <FormikBlocFullContext2023
        formikRef={formReception.formikRef}
        hasChanges={formReception.hasChanges}
        setHasChanges={formReception.setHasChanges}
        initialValues={{ hasMentionDanger: initialMentionDanger }}
        validationSchema={{}}
        title={"Informations Complémentaires"}
        pathToValidate={PATH_AIR_PROCEDES_MENTIONDANGER}
        areGlobalCommentsAllowed={false}
        updateHandler={(declaration, values) => {
          declaration.body.sections.air.fugitives.mentionDanger =
            values.hasMentionDanger;
          return declaration;
        }}
        renderContent={(props, shouldDisableFields) => {
          return (
            <>
              <CheckBox
                name="hasMentionDanger"
                label="Utilisation de COV à mention de danger hors solvants"
                disabled={shouldDisableFields}
                tooltipContent={
                  "Au moins une activité utilisant des substances ou mélanges auxquels sont attribuées ou sur lesquels doivent être apposées les mentions de dangers H340, H350, H350i, H360D ou H360F par le règlement 1272/2008/CE du Conseil et / ou émettant des composés organiques volatils halogénés auxquels est attribuée, ou sur lesquels doit être apposée la mention de danger H341 ou H351 (anciennes phrases de risques R40, R45, R46, R49, R60, R61 ou R68)"
                }
                commentPath={`${PATH_AIR_PROCEDES_MENTIONDANGER}`}
              />
              {props.values.hasMentionDanger && (
                <MessageInfoField message="Vous devez déclarer au moins une émission de COV à mention de danger pour pouvoir valider la page" />
              )}
            </>
          );
        }}
      />
      <Row height={"20px"} />
      <ListProcede
        validationProcedePath={PATH_AIR_PROCEDES_PROCEDES}
        initialProcedes={initialProcedes}
        covsInPage={covsInPage}
        correlationEmissionsInPage={correlationEmissionsInPage}
        matiereEmissionsInPage={matiereEmissionsInPage}
        mesureEmissionsInPage={mesureEmissionsInPage}
        procedesInPage={procedesInPage}
        setProcedesInPage={setProcedesInPage}
        setMesureEmissionsInPage={setMesureEmissionsInPage}
        setMatiereEmissionsInPage={setMatiereEmissionsInPage}
        setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
        setCovsInPage={setCovsInPage}
      />
      <Row height={"20px"} />
      <div>
        {(!isListProcedeValidated || !isProcedesInfoValidated) && (
          <>
            <div className={classes.disabledMessage}>
              Veuillez valider les parties précédentes pour accéder aux
              émissions.
            </div>
            <Row height={"20px"} />
          </>
        )}
        <div
          className={classNames({
            [classes.disabledSection]:
              !isListProcedeValidated ||
              !isProcedesInfoValidated ||
              procedesInPage.length === 0,
          })}
        >
          <Tabs content={tabsContent} />
        </div>
      </div>
      <GlobalFormActionFullContext2023
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={pageHasChanges}
        isValidateEnabled={canSubmitForm()}
        validationPath={PATH_AIR_PROCEDES}
        updateHandler={declaration => {
          const procedesInPageMap = new Map<string, ProcedeInArray>(
            procedesInPage.map(procede => [procede.data.id, procede])
          );
          declaration.body.sections.air.fugitives.procedes = convertProcedeInArrayToDto(
            procedesInPage
          );
          declaration.body.sections.air.fugitives.flux = convertCovInArrayToDto(
            covsInPage,
            procedesInPageMap
          );
          declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
            correlationEmissionsInPage,
            procedesInPageMap
          );
          declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
            mesureEmissionsInPage,
            procedesInPageMap
          );
          declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
            matiereEmissionsInPage,
            procedesInPageMap
          );
          return declaration;
        }}
        cancelAction={() => {
          setProcedesInPage(initialProcedes);
          setMesureEmissionsInPage(initialMesure);
          setMatiereEmissionsInPage(initialMatiere);
          setCorrelationEmissionsInPage(initialCorrelation);
          setCovsInPage(initialCovs);
        }}
      />
    </>
  );
};

export default MainProcedeForm;
