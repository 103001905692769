import React, { ReactElement } from "react";
import { CategorieInstallationDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import { translateBoolToFrenchOrNull } from "common/utils/booleanUtils";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import { computeNumberWithSeparator } from "../../../../../../../../common/utils/numberUtils";

const useStyles = makeStyles({
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface PdsInstallationCategoryProps {
  categorieInstallation: CategorieInstallationDto;
}

const PdsInstallationCategory = ({
  categorieInstallation,
}: PdsInstallationCategoryProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <SubSectionTitle>Catégorie de l&apos;installation</SubSectionTitle>
      <CommonEntityTable
        header={[
          <p>Estimation des émissions annuelles (t CO2e)</p>,
          <p>Catégorie de l'installation</p>,
          <p>Installation faiblement émettrice</p>,
        ]}
        lines={[
          computeInstallationCategoryLine(categorieInstallation, classes),
        ]}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[0, 0, 0]}
      />
    </>
  );
};

export default PdsInstallationCategory;

function computeInstallationCategoryLine(
  {
    categorie,
    yearlyEmissionEstimate,
    faiblementEmettrice,
  }: CategorieInstallationDto,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell(
      computeNumberWithSeparator(yearlyEmissionEstimate),
      classes
    ),
    computeWrappedCell(categorie, classes),
    computeWrappedCell(
      translateBoolToFrenchOrNull(faiblementEmettrice),
      classes
    ),
  ];
}
