import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { PAGE_TITLE_FONT_SIZE, SECTION_TITLE_GREY } from "theme";

const useStyles = makeStyles({
  title: {
    color: SECTION_TITLE_GREY,
    fontSize: PAGE_TITLE_FONT_SIZE,
    margin: "10px 0",
  },
});

const SubSectionTitle = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const classes = useStyles();

  return <h4 className={classes.title}>{children}</h4>;
};

export default SubSectionTitle;
