import {
  ProduitTransportDto23Now,
  ReferenceItemDepartementDto,
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { WithFamily, withFamilyTypeMatcher } from "../../Families/types";

export interface TransportProductsForm {
  usageFamily: ReferenceItemFamilleUsageProductionDto;
  byRoad: number | null;
  byRail: number | null;
  byWaterWay: number | null;
  other: number | null;
  otherPrecision: string | null;
}

export interface TransportProductsArrayForm {
  productsTransport: Record<number, TransportProductsForm>;
}

export type ProduitTransportType = Omit<
  ProduitTransportDto23Now,
  | "famille"
  | "sousFamille"
  | "sousFamilleNiveau2"
  | "departementId"
  | "paysId"
  | "enFrance"
> &
  WithFamily<ReferenceItemFamilleUsageProductionDto> & {
    departement: ReferenceItemDepartementDto | null;
    pays: ReferenceItemPaysDto | null;
    enFrance: string;
  };

export const fieldProduitTransportTypeWithoutFamilyMatcher = fieldMatcherBuilder<
  ProduitTransportType
>().build({
  quantity: "quantite",
  department: "departement",
  country: "pays",
  inFrance: "enFrance",
});

export const fieldProduitTransportTypeMatcher = {
  ...fieldProduitTransportTypeWithoutFamilyMatcher,
  ...withFamilyTypeMatcher,
};
