import React, { ReactElement, useMemo } from "react";
import Button from "common/button";
import { QuotaWorkflowTarget } from "common/utils/types";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useBasicDeclarationHandlers21Now,
  useDeclaration21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../../toNow/versionedElements/declarationHooks21Now";
import { areAllQuotaTargetBlocsValidated } from "../../Quotas/utils/validation";

const QuotaApproveButton = ({
  workflowTarget,
  path,
}: {
  workflowTarget: QuotaWorkflowTarget;
  path: string;
}): ReactElement => {
  const { approve } = useDeclarationStateHandlers21Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const { validate } = useBasicDeclarationHandlers21Now();

  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  const declaration = useDeclaration21Now();
  const isButtonEnabled = useMemo(
    () =>
      areAllQuotaTargetBlocsValidated(
        workflowTarget,
        declaration,
        isPathValidatedInDeclaration
      ),
    [workflowTarget, declaration, isPathValidatedInDeclaration]
  );

  return (
    <Button
      text={"VALIDER"}
      onClick={async () => {
        const validateCall = async () => {
          // the button can be accessible while the path quotas is already validated in declaration
          // because the page was loaded before quota is validated (e.g. multiple tabs)
          if (!isPathValidatedInDeclaration(path)) {
            const hasPathValidationSucceeded = await validate(
              declarationDto => declarationDto,
              path
            );
            if (!hasPathValidationSucceeded) {
              return false;
            }
          }
          await approve([workflowTarget]);
          return true;
        };

        return triggerSpinner(validateCall());
      }}
      isDisabled={!isButtonEnabled}
      isSpinnerVisible={isSpinnerVisible}
    />
  );
};

export default QuotaApproveButton;
