import { getListTypeInstallation } from "../BlocInstallation/utils/selectPossibleValues";
import { Information } from "../InformationBloc/utils/types";
import { LegacySubInstallationElement } from "./types";
import { isEqual } from "lodash";
import { InstallationInArray } from "../BlocInstallation/utils/types";
import { AppareilInArray } from "../ListInstallation/BlocAppareils/utils/types";
import {
  AirCombustionInstallationsDto20NowTypeEnum,
  TypeActiviteGlobalDto21Now,
} from "../../../../../../../api/gen";
import { InArray } from "../../../../../../../common/form/utils";
import { findElementMatchingTemplate } from "../../../../../../../common/utils/methods";

export const filterByInstallation = <T extends LegacySubInstallationElement>(
  contentToFilter: InArray<T>[],
  installationName: string
): InArray<T>[] => {
  return (
    contentToFilter.filter(content => {
      return content.data.nameInstallation === installationName;
    }) || []
  );
};

export const transformNameAppareilsInAppareilsInArray = (
  appareils: string[],
  appareilsReferential: AppareilInArray[]
): AppareilInArray[] => {
  const appareilsInArray: AppareilInArray[] = [];
  //TODO https://github.com/Polyconseil/mtes-gerep/pull/811#discussion_r356136417
  appareils.forEach(appareil => {
    const singleAppareilsInArray = findElementMatchingTemplate(
      { data: { nom: appareil } },
      appareilsReferential
    );
    if (singleAppareilsInArray !== null) {
      appareilsInArray.push(singleAppareilsInArray);
    }
  });
  return appareilsInArray;
};

export const mesure = "Mesure";
export const calcul = "Calcul";

const checkErrors = (
  array: AirCombustionInstallationsDto20NowTypeEnum[],
  installation: InstallationInArray
) => {
  const hasNoError = array.find(element => {
    if (installation.data.type) {
      return element === installation.data.type;
    }
    return false;
  });
  if (hasNoError) {
    return {};
  } else {
    return {
      type: "error",
    };
  }
};

export const computeErrors = (
  installation: InstallationInArray,
  informationsInPage: Information,
  typeActiviteGlobal: TypeActiviteGlobalDto21Now
):
  | {
      type: "error";
    }
  | Record<string, unknown> => {
  if (informationsInPage.lcp && informationsInPage.wiCoWi) {
    return {};
  } else {
    return checkErrors(
      getListTypeInstallation(
        typeActiviteGlobal,
        informationsInPage.lcp,
        informationsInPage.wiCoWi
      ),
      installation
    );
  }
};

//Compare only data because i dont want to compare errors
export const hasEqualDisplayed = (
  initialInstallations: InstallationInArray[],
  installationsInPage: InstallationInArray[]
): boolean => {
  if (initialInstallations.length !== installationsInPage.length) {
    return false;
  }
  for (let i = 0; i < initialInstallations.length; i++) {
    if (!isEqual(initialInstallations[i].data, installationsInPage[i].data)) {
      return false;
    }
  }
  return true;
};
