import React from "react";
import {
  SubstanceExtractionType,
  fieldSubstanceExtractionTypeMatcher,
} from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import _ from "lodash";
import {
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import {
  familiesProductionValidationSchema,
  validateFamiliesForSubstanceExtractionInArray,
} from "../Families/validation";
import {
  onSelectFamilyHandler,
  onSelectSubFamilyHandler,
  onSelectSubFamilyLvl2Handler,
} from "../Families/utils";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { isSearchStringInCollection } from "common/utils/methods";
import { SUBSTANCES_TO_RECYCLE } from "./utils/languages";
import { YEARLY_AMOUNT } from "../utils/languages";
import {
  PRECISION_SUB_USAGE_FAMILY,
  PRECISION_USAGE_FAMILY,
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "../Families/languages";
import { ReferenceFamilleUsageProductionDto } from "api/gen";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import uuid from "uuid";

interface SubstanceExtractionModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  substanceExtractionInModal: SubstanceExtractionType | undefined;
  setSubstanceExtractionInModal: (
    substance: SubstanceExtractionType | undefined
  ) => void;
  substancesExtractionInArray: SubstanceExtractionType[];
  setSubstancesExtractionInArray: (
    substances: SubstanceExtractionType[]
  ) => void;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = familiesProductionValidationSchema({
  substance: commonObjectFields,
  quantite: commonPositiveNumberFields,
});

const SubstanceExtractionModal = ({
  isOpen,
  setIsModalOpen,
  substanceExtractionInModal,
  setSubstanceExtractionInModal,
  substancesExtractionInArray,
  setSubstancesExtractionInArray,
  referentialFamilies,
}: SubstanceExtractionModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-activite-extractive",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);

  const onClose = () => {
    setSubstanceExtractionInModal(undefined);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity<SubstanceExtractionType>
        title="AJOUTER UNE SUBSTANCE"
        validationSchema={validationSchema}
        initialEntity={
          substanceExtractionInModal || {
            id: "",
            substance: null,
            quantite: 0,
            famille: null,
            precisionFamille: "",
            sousFamille: null,
            precisionSousFamille: "",
            sousFamilleNiveau2: null,
            precisionSousFamilleNiveau2: "",
          }
        }
        closeFunction={onClose}
        isEdit={substanceExtractionInModal !== undefined}
        onSubmit={(values: SubstanceExtractionType) => {
          values.id = substanceExtractionInModal?.id || uuid();
          const newSubstancesExtractionInArray = substancesExtractionInArray;
          let indexToReplace = newSubstancesExtractionInArray.length;
          if (substanceExtractionInModal) {
            indexToReplace = _.findIndex(
              newSubstancesExtractionInArray,
              substanceExtractionInModal
            );
            setSubstanceExtractionInModal(undefined);
          }
          newSubstancesExtractionInArray[indexToReplace] = values;
          setSubstancesExtractionInArray(newSubstancesExtractionInArray);
          setIsModalOpen(false);
        }}
        validate={values =>
          validateFamiliesForSubstanceExtractionInArray(
            values,
            substancesExtractionInArray
          )
        }
        renderField={({ values, setFieldValue }) => (
          <>
            <WrappedChoiceSelectModale
              label={`${SUBSTANCES_TO_RECYCLE} *`}
              name={fieldSubstanceExtractionTypeMatcher.substance}
              title="LISTE DES SUBSTANCES"
              header={["Code", "Libellé"]}
              linesData={selectPossibleValues.substances}
              formatLine={substance => [
                substance.code.toString(),
                substance.libelle,
              ]}
              formatSelectedTitle={lineData => lineData.libelle}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.code.toString(), lineData.libelle],
                  searchedStr
                )
              }
              commonProps={commonProps}
            />
            <NumberField
              label={`${YEARLY_AMOUNT} *`}
              name={fieldSubstanceExtractionTypeMatcher.quantity}
              unit="ktonnes"
            />
            <WrappedChoiceSelect
              label={`${USAGE_FAMILY} *`}
              name={fieldSubstanceExtractionTypeMatcher.family}
              isMulti={false}
              options={
                referentialFamilies.referenceItemFamilleUsageProductionDtoList
              }
              additionalOnChange={() => onSelectFamilyHandler(setFieldValue)}
              computeLabel={option => option.code + " - " + option.libelle}
              commonProps={commonProps}
            />
            {values.famille && values.famille.precision && (
              <TextField
                label={`${PRECISION_USAGE_FAMILY} *`}
                name={fieldSubstanceExtractionTypeMatcher.precisionFamily}
              />
            )}
            {values.famille &&
              values.famille.referenceItemFamilleUsageDtos.length > 0 && (
                <WrappedChoiceSelect
                  label={`${SUB_USAGE_FAMILY} *`}
                  name={fieldSubstanceExtractionTypeMatcher.subFamily}
                  isMulti={false}
                  options={values.famille?.referenceItemFamilleUsageDtos}
                  additionalOnChange={() =>
                    onSelectSubFamilyHandler(setFieldValue)
                  }
                  computeLabel={option => option.code + " - " + option.libelle}
                  commonProps={commonProps}
                />
              )}
            {values.sousFamille && values.sousFamille.precision && (
              <TextField
                label={`${PRECISION_SUB_USAGE_FAMILY} *`}
                name={fieldSubstanceExtractionTypeMatcher.precisionSubFamily}
              />
            )}
            {values.sousFamille &&
              values.sousFamille.referenceItemFamilleUsageDtos.length > 0 && (
                <WrappedChoiceSelect
                  label={`${SUB_USAGE_FAMILY_LEVEL_2} *`}
                  name={fieldSubstanceExtractionTypeMatcher.subFamilyLevel2}
                  isMulti={false}
                  options={values.sousFamille?.referenceItemFamilleUsageDtos}
                  additionalOnChange={() =>
                    onSelectSubFamilyLvl2Handler(setFieldValue)
                  }
                  computeLabel={option => option.code + " - " + option.libelle}
                  commonProps={commonProps}
                />
              )}
            {values.sousFamilleNiveau2 &&
              values.sousFamilleNiveau2.precision && (
                <TextField
                  label={`${PRECISION_SUB_USAGE_FAMILY} (niveau 2)*`}
                  name={
                    fieldSubstanceExtractionTypeMatcher.precisionSubFamilyLevel2
                  }
                />
              )}
          </>
        )}
      />
    </CustomModal>
  );
};

export default SubstanceExtractionModal;
