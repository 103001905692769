import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../utils/utils";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { createAppareilDiv } from "./utils/utils";
import { EmissionsInArray, FacteurEmissionInArray } from "./utils/types";
import _ from "lodash";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  facteurEmissionsInArray: FacteurEmissionInArray[];
  setFacteurEmissionsInArray: Dispatch<
    SetStateAction<FacteurEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationFacteursPath: string;
}

const EmissionArray = ({
  facteurEmissionsInArray,
  setFacteurEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationFacteursPath,
}: EmissionArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInArray: FacteurEmissionInArray) {
    setFacteurEmissionsInArray(elderEmissions => {
      return elderEmissions.filter(elderEmissionInArray => {
        return singleEmissionInArray.data.id !== elderEmissionInArray.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: FacteurEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };
    const substanceName =
      emission.data.substance && emission.data.substance.label;
    const combustibleName =
      emission.data.combustible && emission.data.combustible.label;

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      substanceName || undefined,
      isValidated,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.appareils && createAppareilDiv(emission.data.appareils),
        classes
      ),
      computeWrappedCellWithError(
        <p title={combustibleName || undefined}>{combustibleName}</p>,
        classes
      ),
      computeWrappedCellWithError(emission.data.referenceCombustible, classes),
      computeWrappedCellWithError(
        emission.data.consoAnnuelle !== null
          ? computeNumberWithSeparator(emission.data.consoAnnuelle)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.unite || undefined}>{emission.data.unite}</p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.facteur !== null
          ? computeNumberWithSeparator(emission.data.facteur)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.ecart || undefined}>{emission.data.ecart}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.provenance || undefined}>
          {emission.data.provenance}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.combustible !== null &&
          emission.data.combustible.object.data.biogazFraction !== null
          ? computeNumberWithSeparator(
              emission.data.combustible.object.data.biogazFraction
            )
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.facteurOxydation !== null
          ? computeNumberWithSeparator(emission.data.facteurOxydation)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.provenanceFacteurOxydation || undefined}>
          {emission.data.provenanceFacteurOxydation}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.nature || undefined}>{emission.data.nature}</p>,
        classes
      ),
      computeWrappedCellWithError(emission.data.rendement, classes),
      computeWrappedCellWithError(
        emission.data.emissionsAnnuelles !== null
          ? computeNumberWithSeparator(
              Number(emission.data.emissionsAnnuelles.toFixed(3))
            )
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette emission ?"}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        isValidated={isValidated}
        commentPath={`${validationFacteursPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = filterByInstallation(
    facteurEmissionsInArray,
    installationName || ""
  ).map(facteur => {
    return computeSingleEmissionLine(facteur, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Combustible</p>,
        <p>Référence du combustible</p>,
        <p>Consommation annuelle</p>,
        <p>Unité</p>,
        <p>Facteur d'émission (kg/GJ)</p>,
        <p>Préciser écart facteur d'émission</p>,
        <p>Provenance du facteur d'émission</p>,
        <p>Fraction de la biomasse (%)</p>,
        <p>Facteur d'oxydation du carbone (%)</p>,
        <p>Provenance du facteur d'oxydation du carbone</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        130,
        130,
        200,
        130,
        200,
        200,
        130,
        130,
        200,
        130,
        200,
        130,
        130,
        90,
      ]}
    />
  );
};

export default EmissionArray;
