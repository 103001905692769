import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import { InertWasteForm } from "./types";

export const convertDtoToInertWasteForm = (
  declaration: Declaration20Now
): InertWasteForm => {
  return {
    inertWasteBring:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .aDesApportsDechetsInertes,
    remainingCapacityEndRefYear:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .capaciteRestanteTermeAnneeRef,
    groundAndStoneToBackfill:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRemblayer.terreEtCailloux,
    groundAndStoneToRecycle:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRecycler.terreEtCailloux,
    otherStuffToBackfill:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRemblayer.autresMateriaux,
    otherStuffToRecycle:
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes &&
      declaration.body.sections.carriere.environnement.dechetsInertes
        .tableauDechetsInertes.aRecycler.autresMateriaux,
  };
};
