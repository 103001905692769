import {
  AirCombustionCombustibleDto20NowSurCendresEnum,
  AirCombustionCombustiblePciDtoBrutEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
} from "api/gen/api";
import { AirCombustionCombustibleDto20NowUniteEnum } from "api/gen";

export const computeLabelCh4AndBiogazMethodEstimation = (
  isMesure: boolean
): string => (isMesure ? "Mesure" : "Calcul");

export const arraySurCendresLabel: {
  [key in AirCombustionCombustibleDto20NowSurCendresEnum]: string;
} = {
  SUR_CENDRES: "Sur cendres",
  HORS_CENDRES: "Hors cendres",
};

export const arraySurCendres: AirCombustionCombustibleDto20NowSurCendresEnum[] = Object.keys(
  arraySurCendresLabel
) as AirCombustionCombustibleDto20NowSurCendresEnum[];

export const arrayBrutOuSecLabel: {
  [key in AirCombustionCombustiblePciDtoBrutEnum]: string;
} = {
  BRUT: "Brut",
  SEC: "Sec",
};

export const arrayBrutOuSec: AirCombustionCombustiblePciDtoBrutEnum[] = Object.keys(
  arrayBrutOuSecLabel
) as AirCombustionCombustiblePciDtoBrutEnum[];

export const arrayUniteLabel: {
  [key in AirCombustionCombustibleDto20NowUniteEnum]: string;
} = {
  T: "t (tonne)",
  KG: "kg (kilogramme)",
  HL: "hl (hectolitre)",
  L: "l (litre)",
  M3: "m3 (mètre cube)",
  K_M3: "1000 m3 (mille mètres cubes)",
  TEP: "tep (tonne équivalent pétrole)",
  GJ: "GJ (gigajoule)",
  KWH_PCI: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
  MWH_PCI: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
  GWH_PCI: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
  KWH_PCS: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
  MWH_PCS: "MWh PCS (mégawatt heure pouvoir calorifique supérieur",
  GWH_PCS: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
};

export const arrayUnite: AirCombustionCombustibleDto20NowUniteEnum[] = Object.keys(
  arrayUniteLabel
) as AirCombustionCombustibleDto20NowUniteEnum[];

export const isUniteMasse = (
  unite: AirCombustionCombustibleDto20NowUniteEnum
): boolean => {
  return (
    unite === AirCombustionCombustibleDto20NowUniteEnum.T ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.KG
  );
};

export const isUniteVolume = (
  unite: AirCombustionCombustibleDto20NowUniteEnum
): boolean => {
  return (
    unite === AirCombustionCombustibleDto20NowUniteEnum.HL ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.L ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.M3 ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.K_M3
  );
};

export const isUniteEnergy = (
  unite: AirCombustionCombustibleDto20NowUniteEnum
): boolean => {
  return (
    unite === AirCombustionCombustibleDto20NowUniteEnum.TEP ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.GJ ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.KWH_PCI ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.MWH_PCI ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.GWH_PCI ||
    isUnitePCS(unite)
  );
};

export const isUnitePCS = (
  unite: AirCombustionCombustibleDto20NowUniteEnum | null
): boolean => {
  return (
    unite === AirCombustionCombustibleDto20NowUniteEnum.KWH_PCS ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.MWH_PCS ||
    unite === AirCombustionCombustibleDto20NowUniteEnum.GWH_PCS
  );
};

export const arrayPciUniteLabel: {
  [key in AirCombustionCombustiblePciDtoUniteEnum]: string;
} = {
  GJ_T: "GJ/t PCI",
  GJ_M3: "GJ/m3 PCI",
  GJ_HL: "GJ/hl PCI",
  MJ_M3: "MJ/m3 PCI",
  KWH_M3: "kWh/m3 PCI",
  MWH_T: "MWh/t PCI",
  MWH_M3: "MWh/m3 PCI",
  MWH_HL: "MWh/hl PCI",
  TH_KG: "th/kg PCI",
  TH_M3: "th/m3 PCI",
  TEP_T: "tep/t PCI",
  TEP_1000M3: "tep/1000m3 PCI",
  TEP_M3: "tep/m3 PCI",
  TEP_HL: "tep/hl PCI",
};

export const arrayPciUnite: AirCombustionCombustiblePciDtoUniteEnum[] = Object.keys(
  arrayPciUniteLabel
) as AirCombustionCombustiblePciDtoUniteEnum[];

const getValueOr1 = (value?: number | null): number =>
  value === null || value === 0 || value === undefined ? 1 : value;

export const arrayPciUniteCalcul: {
  [key in AirCombustionCombustiblePciDtoUniteEnum]: (
    pciValue: number,
    masse?: number | null
  ) => number;
} = {
  GJ_T: (pciValue: number) => pciValue,
  GJ_M3: (pciValue: number, masse?: number | null) =>
    pciValue / getValueOr1(masse),
  GJ_HL: (pciValue: number, masse?: number | null) =>
    (10 * pciValue) / getValueOr1(masse),
  MJ_M3: (pciValue: number, masse?: number | null) =>
    pciValue / (1000 * getValueOr1(masse)),
  KWH_M3: (pciValue: number, masse?: number | null) =>
    (0.0036 * pciValue) / getValueOr1(masse),
  MWH_T: (pciValue: number) => 3.6 * pciValue,
  MWH_M3: (pciValue, masse) => (3.6 * pciValue) / getValueOr1(masse),
  MWH_HL: (pciValue, masse) => (36 * pciValue) / getValueOr1(masse),
  TH_KG: (pciValue: number) => 4.2 * pciValue,
  TH_M3: (pciValue, masse) => (0.0042 * pciValue) / getValueOr1(masse),
  TEP_T: (pciValue: number) => 42 * pciValue,
  TEP_1000M3: (pciValue, masse) =>
    (42 * pciValue) / (1000 * getValueOr1(masse)),
  TEP_M3: (pciValue, masse) => (42 * pciValue) / getValueOr1(masse),
  TEP_HL: (pciValue, masse) => (420 * pciValue) / getValueOr1(masse),
};

export const isUnitPCIEnergyByMasse = (
  unitPCI: AirCombustionCombustiblePciDtoUniteEnum | null
): boolean => {
  if (!unitPCI) {
    return false;
  }
  return (
    unitPCI === AirCombustionCombustiblePciDtoUniteEnum.GJ_T ||
    unitPCI === AirCombustionCombustiblePciDtoUniteEnum.MWH_T ||
    unitPCI === AirCombustionCombustiblePciDtoUniteEnum.TH_KG ||
    unitPCI === AirCombustionCombustiblePciDtoUniteEnum.TEP_T
  );
};
