import React from "react";
import { SubFamilyLvl2PrecisionProps } from "./types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "./languages";
import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const computeFamiliesArrayHeader = () => {
  return [
    <p>{USAGE_FAMILY}</p>,
    <p>{SUB_USAGE_FAMILY}</p>,
    <p>{SUB_USAGE_FAMILY_LEVEL_2}</p>,
  ];
};

export const computeFamiliesArrayCells = (
  usageFamily: OptionPropsWithObject<
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
  > | null,
  usageSubFamiliesLvl2: OptionPropsWithObject<
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
  >[],
  usageFamilyPrecision: string | null,
  usageSubFamily: OptionPropsWithObject<
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
  > | null,
  usageSubFamilyPrecision: string | null,
  usageSubFamiliesLvl2Precisions: SubFamilyLvl2PrecisionProps[]
): React.ReactElement[] => {
  const render = [];
  if (usageFamily && usageFamily.object.precision) {
    render.push(<p>{usageFamilyPrecision}</p>);
  } else {
    render.push(<p>{usageFamily ? usageFamily.label : ""}</p>);
  }
  if (usageSubFamily && usageSubFamily.object.precision) {
    render.push(<p>{usageSubFamilyPrecision || ""}</p>);
  } else {
    render.push(<p>{usageSubFamily ? usageSubFamily.label : ""}</p>);
  }

  const maxSubFamiliesLvl2Displayed = 3;
  let subFamiliesLvl2Label = "";
  for (let i = 0; i < maxSubFamiliesLvl2Displayed; i++) {
    const usageSubFamilyLvl2 = usageSubFamiliesLvl2[i];
    if (!usageSubFamilyLvl2) {
      continue;
    }
    if (
      usageSubFamilyLvl2.object.precision &&
      usageSubFamiliesLvl2Precisions[i]
    ) {
      subFamiliesLvl2Label += `${usageSubFamiliesLvl2Precisions[i].precision} `;
    } else {
      subFamiliesLvl2Label += `${usageSubFamilyLvl2.label} `;
    }
  }
  if (usageSubFamiliesLvl2.length > maxSubFamiliesLvl2Displayed) {
    subFamiliesLvl2Label += "...";
  }
  render.push(<p title={subFamiliesLvl2Label}>{subFamiliesLvl2Label}</p>);
  return render;
};
