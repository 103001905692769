import * as Yup from "yup";
import { AccidentInModale, deadlyLabel } from "../utils";
import { tooLongMessage } from "common/declarant/formik/formikMessages";
import {
  commonDateFieldsRequired,
  commonStringFields,
  subNumberFieldValidationScheme,
} from "common/declarant/formik/formikHelper";

//harcoded here because it shouldn't change.
export const otherAccidentLabel = "Autres causes";

export const isAccidentOther = (mainAccidentCause: string | null): boolean => {
  return mainAccidentCause === otherAccidentLabel;
};

export const singleAccidentOverwriteDependantFields = (
  accident: AccidentInModale
): void => {
  if (!isAccidentOther(accident.causePrincipale)) {
    accident.precisionSiAutre = null;
  }

  if (accident.isConsolidated === deadlyLabel) {
    accident.nbJoursArretTravail = null;
  }
};

export const singleAccidentValidationSchema = Yup.object().shape({
  accidentDate: commonDateFieldsRequired,
  causePrincipale: Yup.string().nullable(),
  precisionSiAutre: Yup.string().when("causePrincipale", {
    is: isAccidentOther,
    then: commonStringFields.max(250, tooLongMessage),
    otherwise: Yup.string().nullable(),
  }),
  isConsolidated: commonStringFields, //don't think checking against the array of values would be any useful here, since it should be the only selectable values anyway...
  nbJoursArretTravail: subNumberFieldValidationScheme(
    "isConsolidated",
    isConsolidated => isConsolidated !== deadlyLabel
  ),
});
