import { triggerDownload } from "common/utils/methods";

export const downloadExportResult = async (
  result: Response,
  defaultFileName: string
): Promise<boolean> => {
  const blob = await result.blob();
  if (!blob) {
    return false;
  }
  let fileName = result.headers.get("Content-Disposition");
  if (fileName) {
    // Parsing the header to get the file name
    // It is formated as 'name="{fileName}"'
    fileName = fileName.split('"')[1];
  } else {
    fileName = defaultFileName;
  }
  triggerDownload(blob, fileName);
  return true;
};
