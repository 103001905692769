import { QuotasBlocEmissionsBilanTotalDto20Now } from "api/gen";
import { getValueOrZero } from "common/utils/numberUtils";

export const computeTotalEmission = (
  values: QuotasBlocEmissionsBilanTotalDto20Now
): number => {
  return (
    getValueOrZero(values.emissionsCO2) +
    getValueOrZero(values.emissionsN2O) +
    getValueOrZero(values.emissionsPFC)
  );
};
