import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  arrayBrutOuSec,
  arrayBrutOuSecLabel,
  arrayPciUnite,
  arrayPciUniteLabel,
  arraySurCendres,
  arraySurCendresLabel,
  arrayUnite,
  arrayUniteLabel,
  computeLabelCh4AndBiogazMethodEstimation,
  isUniteEnergy,
  isUnitePCS,
} from "./utils/selectPossibleValues";
import {
  additionnalCombustibleValidation,
  biogazMesureSubPartActivated,
  ch4MesureSubPartActivated,
  isMasseRequired,
  singleCombustibleOverwriteDependantFields,
  singleCombustibleValidationSchema,
  torchereSubPartActivated,
} from "./validation/validation";
import { computeQuantiteMethaneOxyde } from "./utils/calculus";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { ReferenceCombustibleDto, ReferenceItemCombustibleDto } from "api/gen";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import MessageInfoField from "common/form/MessageInfoField";
import { nullFrequenceMessage } from "common/declarant/formik/formikMessages";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { AppareilInArray } from "../BlocAppareils/utils/types";
import { CombustibleInModale } from "./utils/types";
import { FormComponentProps } from "common/form/utils";
import { PCI_CONVERSION_FACTOR } from "./utils/utils";
import {
  getMessageBiogazEcart,
  getMessagePreciserMasseVolumique,
  shouldDisplayBiogazEcart,
  shouldDisplayPreciserMasseVolumique,
} from "./utils/FormCombustibleUtils";
import {
  getMasseVolumiqueCombustible,
  getMessagePreciserPci,
  shouldDisplayPreciserPci,
} from "./utils/CombustiblePciUtils";
import {
  getNonPCSUnits,
  isCombustibleGazNaturel,
} from "./utils/CombustiblePcsUtils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface FormCombustibleProps extends FormComponentProps<CombustibleInModale> {
  installationName: string | null;
  combustibleReferential: ReferenceCombustibleDto;
  appareilsInArray: AppareilInArray[];
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  warning: {
    color: "red",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    minHeight: "38px",
    "& > div": {
      justifyContent: "center",
    },
  },
  heightAuto: {
    height: "auto",
  },
});

const FormSingleCombustible = ({
  closeFunction,
  onSubmit,
  initialObject,
  installationName,
  combustibleReferential,
  appareilsInArray,
  isTorchereBiogaz,
  isValorisationBiogaz,
  isEdit,
}: FormCombustibleProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-combustible-combustible-individuelle",
  };
  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const getAppareilsForInstallation = (
    allAppareils: AppareilInArray[],
    installationName: string
  ): AppareilInArray[] => {
    if (!allAppareils) {
      return [];
    }
    return (
      allAppareils.filter(appareil => {
        return appareil.data.nameInstallation === installationName;
      }) || []
    );
  };

  const substancesInChoiceSelectModal: ReferenceItemCombustibleDto[] =
    combustibleReferential.referenceItemCombustibleDtoList;

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN COMBUSTIBLE"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: CombustibleInModale, formikBag) => {
        values.quantiteMethaneOxyde = computeQuantiteMethaneOxyde(values);
        singleCombustibleOverwriteDependantFields(
          values,
          isTorchereBiogaz,
          isValorisationBiogaz
        );
        if (values.type) {
          if (!shouldDisplayPreciserMasseVolumique(values, values.type)) {
            values.masseEcart = null;
          }

          const combustible = values.type;
          if (
            !shouldDisplayPreciserPci(
              values,
              combustible,
              getMasseVolumiqueCombustible(values, combustible)
            )
          ) {
            values.pciEcart = null;
          }
          if (!shouldDisplayBiogazEcart(values, combustible)) {
            values.biogazEcart = null;
          }
        }
        onSubmit(values, formikBag);
      }}
      initialEntity={initialObject}
      validationSchema={singleCombustibleValidationSchema(
        isTorchereBiogaz,
        isValorisationBiogaz
      )}
      validate={additionnalCombustibleValidation()}
      renderField={({ values }) => {
        const combustible = values.type;
        const isGazNaturel =
          values.type && isCombustibleGazNaturel(values.type);
        const unites = isGazNaturel ? arrayUnite : getNonPCSUnits();
        const isNotMandatory = values.unite && isUniteEnergy(values.unite);
        if (values.consommation && isGazNaturel && isUnitePCS(values.unite)) {
          values.consommationPCI = values.consommation * PCI_CONVERSION_FACTOR;
        } else {
          values.consommationPCI = null;
        }

        const masseVolumiqueCombustible = getMasseVolumiqueCombustible(
          values,
          combustible
        );
        return (
          <>
            {initialObject.type && (
              <MessageInfoField
                message={
                  "Après avoir modifié ce combustible, pensez à vérifier la cohérence de toutes les émissions liées a celui-ci"
                }
                additionalClassname={classes.warning}
              />
            )}
            <WrappedChoiceSelectModale
              name="type"
              label="Type de combustible *"
              title="TYPE DE COMBUSTIBLE"
              header={[
                "Code NAPFUE",
                "Désignation",
                "PCI réference (GJ/t)",
                "Biomasse",
              ]}
              linesData={substancesInChoiceSelectModal}
              formatLine={combustible => [
                combustible.codeCombustible,
                combustible.designation,
                combustible.pciReference !== null
                  ? computeNumberWithSeparator(combustible.pciReference)
                  : "",
                combustible.biomasse,
              ]}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.codeCombustible, lineData.designation],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData.designation}
              commonProps={commonProps}
              preferredColWidths={[80, 300, 80, 80]}
            />
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation *"
              value={installationName}
              disabled
            />
            <NumberField
              name="consommation"
              label="Consommation annuelle *"
              unit=""
              tooltipContent="Pour les installations concernées par la déclaration de flux sortants, il convient d'utiliser des valeurs négatives."
            />
            <WrappedChoiceSelect
              name="unite"
              label="Unité *"
              isMulti={false}
              options={unites}
              commonProps={commonProps}
              computeLabel={unite => arrayUniteLabel[unite]}
            />
            {values.consommationPCI && (
              <NumberField
                disabled={true}
                name="consommationPCI"
                label="Consommation annuelle en PCI"
                unit=""
              />
            )}
            <WrappedChoiceSelect
              name="appareils"
              label="Appareil(s) consommateur(s) *"
              isMulti
              options={getAppareilsForInstallation(
                appareilsInArray,
                installationName || ""
              ).filter(appareil => appareil.data.nom !== null)}
              commonProps={commonProps}
              computeLabel={appareil => appareil.data.nom || ""}
            />
            <NumberField
              name="masse"
              label={`Masse volumique du combustible${
                isMasseRequired(values) ? " *" : ""
              }`}
              unit="kg/l = t/m³"
              tooltipContent="L'application réalisant un calcul automatique de vos émissions, il est obligatoire de remplir ce champ si vous utilisez, dans le tableau des «Emissions de l'installation», des unités de volume ou de contenance pour la caractérisation des émissions."
            />
            {shouldDisplayPreciserMasseVolumique(values, combustible) && (
              <>
                <MessageInfoField
                  message={getMessagePreciserMasseVolumique(
                    values,
                    combustible
                  )}
                  additionalClassname={classes.heightAuto}
                />
                <TextField
                  name="masseEcart"
                  label="Préciser écart masse Volumique *"
                />
              </>
            )}
            <NumberField
              name="eau"
              label="Teneur en eau"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            {generateWarningMessageIfUnder1Percent(values.eau)}
            <NumberField
              name="carbone"
              label="Teneur en carbone"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            {generateWarningMessageIfUnder1Percent(values.carbone)}
            <NumberField
              name="soufre"
              label="Teneur en soufre"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            {generateWarningMessageIfUnder1Percent(values.soufre)}
            <WrappedChoiceSelect
              name="surCendres"
              label="Sur cendres ou hors cendres"
              isMulti={false}
              options={arraySurCendres}
              commonProps={commonProps}
              computeLabel={surCendre => arraySurCendresLabel[surCendre]}
            />
            <NumberField
              name="cendres"
              label="Teneur en cendre"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            {generateWarningMessageIfUnder1Percent(values.cendres)}
            <NumberField
              name="chlore"
              label="Teneur en chlore"
              unit="%"
              tooltipContent="Tenir les analyses à la disposition de l'inspection des installations classées."
            />
            {generateWarningMessageIfUnder1Percent(values.chlore)}
            <NumberField
              name="pciValeur"
              label={
                isNotMandatory
                  ? "PCI (Pouvoir Calorifique Inférieur)"
                  : "PCI (Pouvoir Calorifique Inférieur) *"
              }
              unit=""
              tooltipContent="Si le combustible renseigné est le gaz naturel, alors il peut être nécessaire de convertir le PCS (mentionné sur les factures) en PCI. La formule à utiliser est la suivante :  « Consommation (MWhPCI) » = « Consommation (MWhPCS) » x « ratio PCI/PCS ». Le ratio PCI/PCS pour le gaz naturel est 0,901."
            />
            <WrappedChoiceSelect
              name="pciUnite"
              label={isNotMandatory ? "Unité PCI" : "Unité PCI *"}
              isMulti={false}
              options={arrayPciUnite}
              commonProps={commonProps}
              computeLabel={pciUnite => arrayPciUniteLabel[pciUnite]}
            />
            {shouldDisplayPreciserPci(
              values,
              combustible,
              masseVolumiqueCombustible
            ) && (
              <>
                <MessageInfoField
                  message={getMessagePreciserPci(
                    values,
                    combustible,
                    masseVolumiqueCombustible
                  )}
                  additionalClassname={classes.heightAuto}
                />
                <TextField name="pciEcart" label="Préciser écart PCI *" />
              </>
            )}
            <WrappedChoiceSelect
              name="pciBrut"
              label="Brut ou sec"
              isMulti={false}
              options={arrayBrutOuSec}
              commonProps={commonProps}
              computeLabel={brutOuSec => arrayBrutOuSecLabel[brutOuSec]}
            />
            <TextField
              name="pciProvenance"
              label={
                isNotMandatory ? "Provenance du PCI" : "Provenance du PCI *"
              }
            />
            <NumberField
              name="biogazFraction"
              label="Fraction de la biomasse (%) *"
              unit="%"
            />
            {generateWarningMessageIfUnder1Percent(values.biogazFraction)}
            {shouldDisplayBiogazEcart(values, combustible) && (
              <>
                <MessageInfoField
                  message={getMessageBiogazEcart(values, combustible)}
                  additionalClassname={classes.heightAuto}
                />
                <TextField
                  name="biogazEcart"
                  label="Préciser écart fraction biomasse *"
                />
              </>
            )}
            {torchereSubPartActivated(
              values.type,
              isTorchereBiogaz,
              isValorisationBiogaz
            ) && (
              <>
                <NumberField
                  name="biogazDebit"
                  label="Débit du biogaz *"
                  unit="m³/h"
                />
                <WrappedChoiceSelect
                  name="biogazMesure"
                  label="Méthode d'estimation du débit *"
                  isMulti={false}
                  options={[false, true]}
                  commonProps={commonProps}
                  computeLabel={computeLabelCh4AndBiogazMethodEstimation}
                />
                {biogazMesureSubPartActivated(values.biogazMesure) && (
                  <>
                    <NumberField
                      name="biogazFrequence"
                      label="Fréquence de la mesure du débit"
                      tooltipContent="Préciser Fréquence de la mesure du débit est sur mesure en continu ou sur au moins une fois par semaine ou au moins une fois par mois ou au moins une fois par trimestre ou au moins une fois par an."
                      unit=""
                    />
                    {values.biogazFrequence == null && (
                      <MessageInfoField
                        message={nullFrequenceMessage}
                        additionalClassname={classes.heightAuto}
                      />
                    )}
                  </>
                )}
                <NumberField
                  name="biogazDuree"
                  label="Temps de fonctionnement *"
                  unit="h/an"
                />
                <NumberField
                  name="ch4Teneur"
                  label={() => (
                    <>
                      Teneur en CH<sub>4</sub>
                    </>
                  )}
                  unit="%"
                />
                {generateWarningMessageIfUnder1Percent(values.ch4Teneur)}
                <WrappedChoiceSelect
                  name="ch4Mesure"
                  label={() => (
                    <>
                      Méthode d'estimation de la teneur en CH<sub>4</sub> *
                    </>
                  )}
                  isMulti={false}
                  options={[false, true]}
                  commonProps={commonProps}
                  computeLabel={computeLabelCh4AndBiogazMethodEstimation}
                />
                {ch4MesureSubPartActivated(values.ch4Mesure) && (
                  <>
                    <NumberField
                      name="ch4Frequence"
                      label={() => (
                        <>
                          Fréquence de la mesure en CH<sub>4</sub>
                        </>
                      )}
                      tooltipContent={
                        <span>
                          Préciser Fréquence de la mesure de la teneur en CH
                          <sub>4</sub> est sur mesure en continu ou sur au moins
                          une fois par semaine ou au moins une fois par mois ou
                          au moins une fois par trimestre ou au moins une fois
                          par an.
                        </span>
                      }
                      unit=""
                    />
                    {values.ch4Frequence == null && (
                      <MessageInfoField
                        message={nullFrequenceMessage}
                        additionalClassname={classes.heightAuto}
                      />
                    )}
                  </>
                )}
                <DummyNumberField
                  name="quantiteMethaneOxyde"
                  label="Quantité de méthane oxydé par combustion *"
                  unit="1000 m³"
                  value={computeQuantiteMethaneOxyde(values)}
                  disabled
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleCombustible;
