import React, { ReactElement } from "react";
import { useUserData } from "Authenticator/UserData";
import InspecteurGlobalActionButtons from "../Inspecteur/InspecteurGlobalActionButtons";
import DeclarantGlobalActionButtons from "../Declarant/DeclarantGlobalActionButtons";

const GlobalActionButtons = (): ReactElement => {
  const { isInspecteur, isDeclarant } = useUserData();

  if (isInspecteur) {
    return <InspecteurGlobalActionButtons />;
  }

  if (isDeclarant) {
    return <DeclarantGlobalActionButtons />;
  }

  return <></>;
};

export default GlobalActionButtons;
