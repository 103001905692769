export const toLocalDateString = (date: Date): string => {
  // Returns string representation of the date in format "YYYY-MM-DD"
  // The date returned is local, so for example August 1st,
  // The returned date is parsable by Java LocalDate
  return (
    date
      .getFullYear()
      .toString()
      .padStart(4, "0") +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date
      .getDate()
      .toString()
      .padStart(2, "0")
  );
};

export default toLocalDateString;
