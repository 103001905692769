import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  QuotasFluxDerogationDto2121,
  QuotasInstallationDto20Now,
} from "api/gen";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { fluxDerogationFieldMatcher } from "./types";
import Row from "common/presentational/Row";
import FluxDerogationListForm from "./FluxDerogationListForm";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface FluxDerogationFormProps {
  installation: QuotasInstallationDto20Now;
  index: number;
  namePrefix: string;
  fluxDerogationList: QuotasFluxDerogationDto2121[];
  shouldDisableFields: boolean;
}

const FluxDerogationForm = ({
  installation,
  index,
  namePrefix,
  fluxDerogationList,
  shouldDisableFields,
}: FluxDerogationFormProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "flux-derogation",
  };

  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const fluxDerogationInstallation = fluxDerogationList.find(
    fluxDerogation => fluxDerogation.nimDynId === installation.id
  ) || {
    nimDynId: installation.id,
    derogationFrequenceEchantillonnage: null,
    fluxSoumisADerogation: [],
  };

  return (
    <div key={`${index}-flux-derogation-installations`}>
      <BooleanField
        label="Une ou plusieurs fréquences d’échantillonnage font l’objet d’une dérogation pour coûts excessifs (article 35.2.b du règlement MRR)"
        name={`${namePrefix}.${fluxDerogationFieldMatcher.derogationFrequenceEchantillonnage}`}
        disabled={shouldDisableFields}
      />
      <Row />
      {fluxDerogationInstallation.derogationFrequenceEchantillonnage && (
        <FluxDerogationListForm
          derogationInstallation={fluxDerogationInstallation}
          nameFieldArray={`${namePrefix}.${fluxDerogationFieldMatcher.fluxSoumisADerogation}`}
          shouldDisableFields={shouldDisableFields}
        />
      )}
    </div>
  );
};

export default FluxDerogationForm;
