import { SolCommonSelectOptions } from "./types";
import {
  ReferenceItemNormeSolDto,
  ReferenceItemPolluantDto,
  ReferenceNormeSolDto,
  RejetSolDtoMethodeUtiliseeEnum,
  RejetSolDtoReferenceMethodeEnum,
} from "api/gen";

const arrayExistingPolluants: ReferenceItemPolluantDto[] = [];

export const arrayNormeReferentiels = (
  referential: ReferenceNormeSolDto
): ReferenceItemNormeSolDto[] => {
  return referential.referenceItemNormeSolDtoList;
};

export const methodLabel: {
  [key in RejetSolDtoMethodeUtiliseeEnum]: string;
} = {
  MESURE: "Mesure",
  CALCUL: "Calcul",
  ESTIMATION: "Estimation",
};

const arrayMethod: RejetSolDtoMethodeUtiliseeEnum[] = Object.keys(
  methodLabel
) as RejetSolDtoMethodeUtiliseeEnum[];

export const arrayRefMethod: RejetSolDtoReferenceMethodeEnum[] = [
  RejetSolDtoReferenceMethodeEnum.INT,
  RejetSolDtoReferenceMethodeEnum.PER,
  RejetSolDtoReferenceMethodeEnum.NRO,
  RejetSolDtoReferenceMethodeEnum.ALT,
  RejetSolDtoReferenceMethodeEnum.MRC,
  RejetSolDtoReferenceMethodeEnum.BMA,
  RejetSolDtoReferenceMethodeEnum.CSS,
  RejetSolDtoReferenceMethodeEnum.AUT,
];

export const computeRefMethodForMethod = (
  methode: RejetSolDtoMethodeUtiliseeEnum | undefined | null
): RejetSolDtoReferenceMethodeEnum[] => {
  if (methode === RejetSolDtoMethodeUtiliseeEnum.MESURE) {
    return [
      RejetSolDtoReferenceMethodeEnum.PER,
      RejetSolDtoReferenceMethodeEnum.NRO,
      RejetSolDtoReferenceMethodeEnum.MRC,
      RejetSolDtoReferenceMethodeEnum.INT,
      RejetSolDtoReferenceMethodeEnum.AUT,
      RejetSolDtoReferenceMethodeEnum.ALT,
    ];
  } else if (methode === RejetSolDtoMethodeUtiliseeEnum.CALCUL) {
    return [
      RejetSolDtoReferenceMethodeEnum.PER,
      RejetSolDtoReferenceMethodeEnum.NRO,
      RejetSolDtoReferenceMethodeEnum.INT,
      RejetSolDtoReferenceMethodeEnum.CSS,
      RejetSolDtoReferenceMethodeEnum.BMA,
      RejetSolDtoReferenceMethodeEnum.AUT,
    ];
  } else {
    throw Error("should not get method ref with this method");
  }
};

export const computeNormesForMethod = (
  method: RejetSolDtoReferenceMethodeEnum,
  referentialNormes: ReferenceNormeSolDto
): ReferenceItemNormeSolDto[] => {
  return arrayNormeReferentiels(referentialNormes).filter(norme => {
    if (method === RejetSolDtoReferenceMethodeEnum.INT) {
      return norme.matchInt;
    } else if (method === RejetSolDtoReferenceMethodeEnum.PER) {
      return norme.matchPer;
    } else if (method === RejetSolDtoReferenceMethodeEnum.NRO) {
      return norme.matchNro;
    }

    return false;
  });
};

export const selectPossibleMethods = (
  referentialNormes: ReferenceNormeSolDto
): SolCommonSelectOptions => ({
  polluantName: arrayExistingPolluants,
  polluantMethod: arrayMethod,
  polluantRefMethod: arrayRefMethod,
  normes: arrayNormeReferentiels(referentialNormes),
});
