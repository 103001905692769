import { MutableRefObject, useCallback, useState } from "react";
import { useAuthenticatedApi } from "../../../Authenticator/AuthenticatedApi";
import {
  SimpleDeclarationListDto,
  SimpleInstallationListDto,
} from "../../../api/gen";
import { DeclarationFilters } from "../../InspectorSpace/DeclarationsDashboard/types";
import { declarationFiltersToDto } from "pages/InspectorSpace/DeclarationsDashboard/converter";

export const useFetchDeclarationInspecteur = (
  mounted: MutableRefObject<boolean>
): [
  SimpleDeclarationListDto | null,
  (
    filters: DeclarationFilters,
    linesPerPage: number,
    page: number
  ) => Promise<void>,
  () => void
] => {
  const { gestionnaireController } = useAuthenticatedApi();
  const [
    declarations,
    setDeclarations,
  ] = useState<SimpleDeclarationListDto | null>(null);
  const resetDeclarations = () => setDeclarations(null);
  const retrieveDeclarationsInspecteur = useCallback(
    async (filters: DeclarationFilters, linesPerPage: number, page: number) => {
      const result = await gestionnaireController.retrieveDeclarationsListUsingPOST(
        declarationFiltersToDto(filters),
        page,
        linesPerPage
      );
      if (mounted.current) {
        setDeclarations(result);
      }
    },
    [gestionnaireController, mounted]
  );

  return [declarations, retrieveDeclarationsInspecteur, resetDeclarations];
};

export const useFetchDeclarationVerificateur = (
  mounted: MutableRefObject<boolean>
): [
  SimpleInstallationListDto | null,
  (
    filters: DeclarationFilters,
    linesPerPage: number,
    page: number
  ) => Promise<void>,
  () => void
] => {
  const { gestionnaireController } = useAuthenticatedApi();
  const [
    declarations,
    setDeclarations,
  ] = useState<SimpleInstallationListDto | null>(null);
  const resetDeclarations = () => setDeclarations(null);
  const retrieveDeclarationsVerificateur = useCallback(
    async (filters: DeclarationFilters, linesPerPage: number, page: number) => {
      const result = await gestionnaireController.retrieveDeclarationsVerificateurListUsingPOST(
        declarationFiltersToDto(filters),
        page,
        linesPerPage
      );
      if (mounted.current) {
        setDeclarations(result);
      }
    },
    [gestionnaireController, mounted]
  );

  return [declarations, retrieveDeclarationsVerificateur, resetDeclarations];
};

export const useFetchDeclarationYears = (): [number[], () => Promise<void>] => {
  const { gestionnaireController } = useAuthenticatedApi();
  const [years, setYears] = useState<number[]>([]);
  const retrieveDeclarationsYearsInspecteur = useCallback(async () => {
    const result = await gestionnaireController.getDeclarationsYearsAvailableUsingGET();
    setYears(result.sort((a, b) => a - b));
  }, [gestionnaireController]);

  return [years, retrieveDeclarationsYearsInspecteur];
};
