import React from "react";
import {
  AllowedDeclarationMethods,
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  MeasureEmissionInArray,
} from "./types";
import { additionalValidation, validationSchema } from "./validation";
import {
  alternativeEmissionToEmissionSimpleDto,
  computedEmissionToEmissionSimpleDto,
  dtoExemptedFlowsToExemptedFlowDeclarations,
  dtoToBlocValues,
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  flowDeclarationToFluxDto,
  measureDeclarationToPointMesureDto,
  measuredEmissionToEmissionSimpleDto,
  updateHandler,
} from "./converter";
import { makeStyles } from "@material-ui/styles";
import {
  BLACK_BUTTON_STYLE,
  DISABLED_SECTION_STYLE,
  FILE_BUTTONS_STYLE,
  LEFT_WITHDRAW_STYLE,
  SECTION_TITLE_GREY,
} from "theme";
import classNames from "classnames";
import ComputedMethod from "./ComputedMethod";
import MeasureMethod from "./MeasureMethod";
import AlternativeMethod from "./AlternativeMethod";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Row from "common/presentational/Row";
import _ from "lodash";
import {
  PATH_QUOTAS_EMISSIONS_BLOC,
  PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION,
  PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION,
  PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2,
} from "common/path/path20Now";
import { useAlertModale } from "common/modale/hooks";
import { QuotasInstallationDto20Now } from "api/gen";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { useEmissionValidationMessage } from "../../utils/utils";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { getFlowNameById } from "./utils/utils";
import {
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
} from "../../../versionedElements/declarationHooks2020";
import FormikBlocFullContext2020 from "../../../versionedElements/FormikBlocFullContext2020";
import { useRapportEmissionsFileHandler2020 } from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
    marginBottom: "20px",
  },
  ...FILE_BUTTONS_STYLE,
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...BLACK_BUTTON_STYLE,
  intermediateSaveButton: {
    padding: "5px",
    position: "relative",
    bottom: "29px",
    alignSelf: "flex-end",
    marginRight: "200px",
  },
  ...LEFT_WITHDRAW_STYLE,
  ...DISABLED_SECTION_STYLE,
});

interface BlocEmissionsProps {
  blocEmissionsFormProps: FormikBlocRefAndState<BlocEmissionsFormValues>;
  allowedDeclarationMethods: AllowedDeclarationMethods;
  isDisabled: boolean;
  computedEmissionsInArray: ComputedEmissionInArray[];
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  measuredEmissionsInArray: MeasureEmissionInArray[];
  setMeasuredEmissionsInArray: (
    callback: (emissions: MeasureEmissionInArray[]) => MeasureEmissionInArray[]
  ) => void;
  alternativeEmissionsInArray: AlternativeEmissionInArray[];
  setAlternativeEmissionsInArray: (
    callback: (
      previousEmissions: AlternativeEmissionInArray[]
    ) => AlternativeEmissionInArray[]
  ) => void;
}

const BlocEmissions = ({
  blocEmissionsFormProps,
  isDisabled,
  computedEmissionsInArray,
  setComputedEmissionsInArray,
  measuredEmissionsInArray,
  setMeasuredEmissionsInArray,
  alternativeEmissionsInArray,
  setAlternativeEmissionsInArray,
  allowedDeclarationMethods,
}: BlocEmissionsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const validationMessage = useEmissionValidationMessage();
  const { update } = useBasicDeclarationHandlers2020();
  const openAlertModale = useAlertModale();

  const rapportEmissionFileHandler = useRapportEmissionsFileHandler2020();

  const typeActiviteQuotas = declaration.body.typeActivite;

  const installations: QuotasInstallationDto20Now[] =
    typeActiviteQuotas.installations;

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "bloc-quotas-plan-de-surveillance",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const initialValues = dtoToBlocValues(
    declaration.body.sections.quotas.emissions.blocEmissions,
    installations
  );

  const intermediateSaveButtonAction = async (
    values: BlocEmissionsFormValues
  ) => {
    await update(declaration =>
      updateHandler(
        declaration,
        values,
        computedEmissionsInArray,
        measuredEmissionsInArray,
        alternativeEmissionsInArray,
        values.flowDeclarations,
        values.measureDeclarations,
        values.exemptedFlows
      )
    );
    blocEmissionsFormProps.setHasChanges(false);
  };

  return (
    <>
      {isDisabled && (
        <div className={classes.disabledMessage}>
          <span>
            Veuillez valider votre plan de surveillance avant d'accéder à cette
            section
          </span>
        </div>
      )}
      <div className={isDisabled ? classes.disabledSection : ""}>
        <FormikBlocFullContext2020
          formikRef={blocEmissionsFormProps.formikRef}
          hasChanges={blocEmissionsFormProps.hasChanges}
          setHasChanges={blocEmissionsFormProps.setHasChanges}
          initialValues={initialValues}
          editValidationMessage={validationMessage}
          hasFormChanges={values => {
            return (
              values.CO2Transfer !==
                declaration.body.sections.quotas.emissions.blocEmissions
                  .transfertCO2 ||
              values.exemption !==
                declaration.body.sections.quotas.emissions.blocEmissions
                  .derogationFrequenceEchantillonnage ||
              !_.isEqual(
                values.exemptedFlows,
                dtoExemptedFlowsToExemptedFlowDeclarations(
                  declaration.body.sections.quotas.emissions.blocEmissions
                    .fluxSoumisADerogation,
                  values.flowDeclarations
                )
              ) ||
              !_.isEqual(
                values.flowDeclarations.map(flowDeclarationToFluxDto),
                declaration.body.sections.quotas.emissions.blocEmissions.flux
              ) ||
              !_.isEqual(
                values.measureDeclarations.map(
                  measureDeclarationToPointMesureDto
                ),
                declaration.body.sections.quotas.emissions.blocEmissions
                  .pointsMesure
              ) ||
              !_.isEqual(
                computedEmissionsInArray.map(e =>
                  computedEmissionToEmissionSimpleDto(
                    e,
                    values.flowDeclarations
                  )
                ),
                declaration.body.sections.quotas.emissions.blocEmissions
                  .emissionsCalculees
              ) ||
              !_.isEqual(
                measuredEmissionsInArray.map(e =>
                  measuredEmissionToEmissionSimpleDto(
                    e,
                    values.measureDeclarations
                  )
                ),
                declaration.body.sections.quotas.emissions.blocEmissions
                  .emissionsMesurees
              ) ||
              !_.isEqual(
                alternativeEmissionsInArray.map(
                  alternativeEmissionToEmissionSimpleDto
                ),
                declaration.body.sections.quotas.emissions.blocEmissions
                  .emissionsMethodeAlternative
              )
            );
          }}
          cancelAction={() => {
            setComputedEmissionsInArray(() =>
              declaration.body.sections.quotas.emissions.blocEmissions.emissionsCalculees.map(
                e =>
                  emissionSimpleDtoToComputedEmission(
                    e,
                    initialValues.flowDeclarations
                  )
              )
            );
            setMeasuredEmissionsInArray(() =>
              declaration.body.sections.quotas.emissions.blocEmissions.emissionsMesurees.map(
                e =>
                  emissionSimpleDtoToMeasuredEmission(
                    e,
                    initialValues.measureDeclarations
                  )
              )
            );
            setAlternativeEmissionsInArray(() =>
              declaration.body.sections.quotas.emissions.blocEmissions.emissionsMethodeAlternative.map(
                emission =>
                  emissionSimpleDtoToAlternativeEmission(
                    emission,
                    installations
                  )
              )
            );
            return initialValues;
          }}
          validationSchema={validationSchema}
          title={"déclaration des émissions"}
          pathToValidate={PATH_QUOTAS_EMISSIONS_BLOC}
          areGlobalCommentsAllowed={true}
          updateHandler={(declaration, values) =>
            updateHandler(
              declaration,
              values,
              computedEmissionsInArray,
              measuredEmissionsInArray,
              alternativeEmissionsInArray,
              values.flowDeclarations,
              values.measureDeclarations,
              values.exemptedFlows
            )
          }
          additionalValidationAllowed={() => !isDisabled}
          additionalFieldValidation={values => additionalValidation(values)}
          declarationState={
            declaration.body.workflowStatus.quotaEmissions.state
          }
          renderContent={({ values, setFieldValue }, shouldDisabledFields) => (
            <>
              <div className={classes.text}>
                <p>À faire dans ce bloc :</p>
                <ul>
                  <li>
                    Télécharger si besoin le modèle du rapport d'émission annuel
                    quotas,
                  </li>
                  <li>Le compléter</li>
                  <li>
                    Déposer le rapport d'émission annuel quotas complété (au
                    moins 1 par NIM),
                  </li>
                  <li>Reporter les informations demandées ci-dessous.</li>
                </ul>
              </div>

              <GreyFilesDownloader
                text="TÉLÉCHARGER MODÈLE RAPPORT D'ÉMISSION"
                isDisabled={shouldDisabledFields}
                fileName="RapportEmissions.xls"
                additionalClassname={classes.fileButtons}
              />

              <FilesLoader
                declaration={declaration}
                text={
                  "DÉPOSER RAPPORT D'ÉMISSION COMPLÉTÉ (AU MOINS 1 PAR NIM)"
                }
                filesPath={
                  "body.sections.quotas.emissions.blocEmissions.fichiers"
                }
                section={FileSectionEnum.QUOTAS_RAPPORT_EMISSIONS}
                fileHandlers={rapportEmissionFileHandler}
                //
                isDisabled={shouldDisabledFields}
                //
                additionalClassnameButton={classes.fileButtons}
                onReject={() =>
                  openAlertModale(
                    "Le format attendu pour le rapport d'émission est le format EXCEL."
                  )
                }
              />

              {allowedDeclarationMethods.computed !== false && (
                <>
                  <ComputedMethod
                    values={values}
                    shouldDisabledFields={shouldDisabledFields}
                    installations={installations}
                    computedEmissionsInArray={computedEmissionsInArray}
                    setComputedEmissionsInArray={setComputedEmissionsInArray}
                    setExemptedFlows={(flows: string[]) =>
                      setFieldValue("exemptedFlows", flows)
                    }
                    computedAllowedDeclarationMethods={
                      allowedDeclarationMethods.computed
                    }
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              {allowedDeclarationMethods.mesure && (
                <>
                  <MeasureMethod
                    values={values}
                    shouldDisabledFields={shouldDisabledFields}
                    installations={installations}
                    measuredEmissionsInArray={measuredEmissionsInArray}
                    setMeasuredEmissionsInArray={setMeasuredEmissionsInArray}
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              {allowedDeclarationMethods.alternative && (
                <>
                  <AlternativeMethod
                    shouldDisabledFields={shouldDisabledFields}
                    alternativeEmissionsInArray={alternativeEmissionsInArray}
                    setAlternativeEmissionsInArray={
                      setAlternativeEmissionsInArray
                    }
                    installations={installations}
                  />

                  {blocEmissionsFormProps.hasChanges && (
                    <Button
                      text={
                        <>
                          <FontAwesomeIcon icon="save" /> ENREGISTRER
                        </>
                      }
                      additionalClassname={classNames(
                        classes.intermediateSaveButton,
                        classes.blackButton
                      )}
                      isDisabled={shouldDisabledFields}
                      isReversed
                      onClick={() => intermediateSaveButtonAction(values)}
                    />
                  )}
                </>
              )}

              <Row />

              <CheckBox
                name="exemption"
                label="Une ou plusieurs fréquences d’échantillonnage font l’objet d’une dérogation pour coûts excessifs (article 35.2.b du règlement MRR)"
                disabled={shouldDisabledFields}
                commentPath={PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION}
              />

              {values.exemption && (
                <div className={classes.withdrawLeft}>
                  <WrappedChoiceSelect
                    name="exemptedFlows"
                    label="Si oui, indiquer les flux concernés par la dérogation *"
                    isMulti
                    options={values.flowDeclarations.map(flow => flow.id)}
                    computeLabel={flowId =>
                      getFlowNameById(flowId, values.flowDeclarations) || ""
                    }
                    disabled={shouldDisabledFields}
                    commentPath={PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION}
                    commonProps={commonProps}
                  />
                </div>
              )}

              <CheckBox
                name="CO2Transfer"
                label={() => (
                  <>
                    Y a-t-il eu transfert de CO
                    <sub>2</sub> intrinsèque au sens de l’article 48 ou
                    transfert de CO
                    <sub>2</sub> au sens de l’article 49 du règlement MRR ?
                  </>
                )}
                disabled={shouldDisabledFields}
                commentPath={PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2}
              />
            </>
          )}
        />
      </div>
    </>
  );
};

export default BlocEmissions;
