import * as Yup from "yup";
import {
  commonNumberFieldsNullable,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";

//TODO [GEREP-2579] il faudra remettre le shape AirElevageEmissionsDto ici.
export const validationSchema = Yup.object().shape({
  ch4: commonPositiveNumberFieldsNullable,
  n2o: commonPositiveNumberFieldsNullable,
  nh3: commonNumberFieldsNullable,
  tsp: commonNumberFieldsNullable,
  pm10: commonPositiveNumberFieldsNullable,
});
