import React, { ReactElement } from "react";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import SubDashboardContainer from "../../../../toNow/Dashboard/Components/Container/SubDashboardContainer";
import DashboardCardsContainer from "../../../../toNow/Dashboard/Components/Container/DashboardCardsContainer";
import TypeActiviteCard from "../Cards/TypeActiviteCard";
import TypeActiviteNextActionCard from "../../../../toNow/Dashboard/Components/NextAction/TypeActiviteNextActionCard";

const TypeActiviteSubDashboard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  return (
    <SubDashboardContainer
      title="Types d'activités"
      informationMessage={
        "Cette section doit être vérifiée par le déclarant. " +
        "En cas d’erreur, veuillez contacter l’inspection."
      }
    >
      <DashboardCardsContainer>
        <TypeActiviteCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
        />
        <TypeActiviteNextActionCard />
      </DashboardCardsContainer>
    </SubDashboardContainer>
  );
};

export default TypeActiviteSubDashboard;
