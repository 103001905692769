import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonBooleanFieldsNullable,
  commonMixedFields,
  commonNullableStringFields,
  commonNumberFields,
  commonNumberFieldsNullable,
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
  subFieldValidationMultipleANDScheme,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { autreString } from "../utils/utils";
import { SyntheseInModale } from "../utils/types";
import {
  MethodSyntheseInfo,
  ReferenceCalcule,
  ReferenceMesure,
} from "../utils/selectPossibleValues";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemNormeAirDto, ReferenceItemPolluantDto } from "api/gen";

export function descriptionScheme<T, U, V, W>(
  properties: [string, string, string, string, string, string, string],
  subPartActivated1: (
    type1: T | null,
    type2: T | null,
    type3: T | null,
    method1: V | null,
    method: U | null
  ) => boolean | null,
  subPartActivated2: (
    type4: T | null,
    method2: W | null,
    method: U | null
  ) => boolean | null
) {
  return Yup.string().when(properties, {
    is: (
      type1: T | null,
      type2: T | null,
      type3: T | null,
      type4: T | null,
      method1: V | null,
      method2: W | null,
      method: U | null
    ) =>
      subPartActivated1(
        type1 || null,
        type2 || null,
        type3 || null,
        method1 || null,
        method || null
      ) || subPartActivated2(type4 || null, method2 || null, method || null),
    then: commonStringFields,
    otherwise: commonNullableStringFields,
  });
}

export const MSubPartActivated = (
  type: OptionPropsWithObject<MethodSyntheseInfo> | null | null
): boolean => {
  return type?.value === 1;
};

export const CSubPartActivated = (
  type: OptionPropsWithObject<MethodSyntheseInfo> | null
): boolean => {
  return type?.value === 2;
};

export const MINTSubPartActivated = (
  type: OptionPropsWithObject<ReferenceMesure> | null
): boolean => {
  return type?.value === 1;
};

export const MPERSubPartActivated = (
  type: OptionPropsWithObject<ReferenceMesure> | null
): boolean => {
  return type?.value === 2;
};

export const MNROSubPartActivated = (
  type: OptionPropsWithObject<ReferenceMesure> | null
): boolean => {
  return type?.value === 3;
};

export const CINTSubPartActivated = (
  type: OptionPropsWithObject<ReferenceCalcule> | null
): boolean => {
  return type?.value === 1;
};

export const MALTSubPartActivated = (
  type: OptionPropsWithObject<ReferenceMesure> | null
): boolean => {
  return type?.value === 4;
};

export const MMRCSubPartActivated = (
  type: OptionPropsWithObject<ReferenceMesure> | null
): boolean => {
  return type?.value === 5;
};

export const CBMASubPartActivated = (
  type: OptionPropsWithObject<ReferenceCalcule> | null
): boolean => {
  return type?.value === 5;
};

export const CCSSSubPartActivated = (
  type: OptionPropsWithObject<ReferenceCalcule> | null
): boolean => {
  return type?.value === 6;
};

const descriptionSchemaM = (
  type1: OptionPropsWithObject<ReferenceItemNormeAirDto>[] | null,
  type2: OptionPropsWithObject<ReferenceItemNormeAirDto>[] | null,
  type3: OptionPropsWithObject<ReferenceItemNormeAirDto>[] | null,
  methodRef: OptionPropsWithObject<ReferenceMesure> | null,
  method: OptionPropsWithObject<MethodSyntheseInfo> | null
) => {
  if (MSubPartActivated(method)) {
    if (type1 && MINTSubPartActivated(methodRef)) {
      return type1.some(el => el.label.toLowerCase() === autreString);
    }
  }
  if (type2 && MPERSubPartActivated(methodRef)) {
    return type2.some(el => el.label.toLowerCase() === autreString);
  }
  if (type3 && MNROSubPartActivated(methodRef)) {
    return type3.some(el => el.label.toLowerCase() === autreString);
  }
  return false;
};

const descriptionSchemaC = (
  type: OptionPropsWithObject<ReferenceItemNormeAirDto>[] | null,
  methodRef: OptionPropsWithObject<ReferenceCalcule> | null,
  method: OptionPropsWithObject<MethodSyntheseInfo> | null
) => {
  if (CSubPartActivated(method)) {
    if (type && CINTSubPartActivated(methodRef)) {
      return type.some(el => el.label.toLowerCase() === autreString);
    }
  }
  return false;
};

export const singleSyntheseOverwriteDependantFields = (
  synthese: SyntheseInModale
): void => {
  if (!MSubPartActivated(synthese.method || null)) {
    synthese.methodReferenceM = null;
  }
  if (!MINTSubPartActivated(synthese.methodReferenceM)) {
    synthese.normMINT = null;
  }
  if (!MPERSubPartActivated(synthese.methodReferenceM)) {
    synthese.normMPER = null;
  }
  if (!MNROSubPartActivated(synthese.methodReferenceM)) {
    synthese.normMNRO = null;
  }
  if (!CSubPartActivated(synthese.method || null)) {
    synthese.methodReferenceC = null;
  }
  if (!CINTSubPartActivated(synthese.methodReferenceC)) {
    synthese.normCINT = null;
  }
  if (
    CBMASubPartActivated(synthese.methodReferenceC) ||
    CCSSSubPartActivated(synthese.methodReferenceC) ||
    MALTSubPartActivated(synthese.methodReferenceM) ||
    MMRCSubPartActivated(synthese.methodReferenceM) ||
    (!MSubPartActivated(synthese.method) && !CSubPartActivated(synthese.method))
  ) {
    synthese.description = null;
  }
};

export const singleSyntheseValidationSchema: Yup.SchemaOf<Partial<
  SyntheseInModale
>> = Yup.object().shape({
  substance: commonMixedFields<ReferenceItemPolluantDto>(),
  emissionsAlreadyDeclared: commonNumberFields.nullable(),
  withAccidentalEmissions: commonPositiveNumberFields,
  additionalAccidentalEmissions: commonPositiveNumberFields,
  origin: commonNullableStringFields,
  totalEmissions: commonNumberFields.nullable(),
  method: commonMixedFields<OptionPropsWithObject<MethodSyntheseInfo>>(),
  methodReferenceM: subFieldValidationScheme(
    "method",
    MSubPartActivated,
    commonObjectFields,
    Yup.object()
  ),
  methodReferenceC: subFieldValidationScheme(
    "method",
    CSubPartActivated,
    commonObjectFields,
    Yup.object()
  ),
  normMINT: subFieldValidationMultipleANDScheme(
    ["methodReferenceM", "method"],
    MINTSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normMPER: subFieldValidationMultipleANDScheme(
    ["methodReferenceM", "method"],
    MPERSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normMNRO: subFieldValidationMultipleANDScheme(
    ["methodReferenceM", "method"],
    MNROSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normCINT: subFieldValidationMultipleANDScheme(
    ["methodReferenceC", "method"],
    CINTSubPartActivated,
    CSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  description: descriptionScheme(
    [
      "normMINT",
      "normMPER",
      "normMNRO",
      "normCINT",
      "methodReferenceM",
      "methodReferenceC",
      "method",
    ],
    descriptionSchemaM,
    descriptionSchemaC
  ),
  totalEmissionsUsualUnit: commonNumberFieldsNullable,
  uniteUsuelle: commonNullableStringFields,
  factor: commonNumberFieldsNullable,
  isBiomasse: commonBooleanFieldsNullable,
});

export const canDisplayDescription = (values: SyntheseInModale): boolean => {
  return !!(
    (values.methodReferenceC || values.methodReferenceM) &&
    (MSubPartActivated(values.method) || CSubPartActivated(values.method)) &&
    !MALTSubPartActivated(values.methodReferenceM) &&
    !MMRCSubPartActivated(values.methodReferenceM) &&
    !CBMASubPartActivated(values.methodReferenceC) &&
    !CCSSSubPartActivated(values.methodReferenceC)
  );
};
