import { WasteTabData } from "../types";
import {
  DechetProduitDto1819,
  DechetRecuDto1822,
  ProductionDechetsDto1819,
  ReceptionDechetsDto1822,
} from "api/gen";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../PolluantProduction/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../PolluantReception/types";

/////////
//  patch API methods
/////////
//  helpers
const createWasteProductionDechetDto: (
  wastes: ProductedWasteStorage[]
) => DechetProduitDto1819[] = (wastes: ProductedWasteStorage[]) => {
  return wastes.map(ele => {
    return ele.data;
  });
};
const createWasteReceptionDechetDto: (
  wastes: ReceivedWasteStorage[]
) => DechetRecuDto1822[] = (wastes: ReceivedWasteStorage[]) => {
  return wastes.map(ele => {
    return ele.data;
  });
};
//  main methods
export const createWasteProductionDto = (
  values: WasteTabData<ProductedWasteFormData, ProductedWasteStorage>
): ProductionDechetsDto1819 => {
  const newDto: ProductionDechetsDto1819 = {
    productionDechetDangereuxSup2000: values.formInfo.dangerousWasteOver2000kg,
    productionDechetNonDangereuxSup2000:
      values.formInfo.atLeastOneFacilityWasteOver2Mkg,
    productionDechet: createWasteProductionDechetDto(values.container),
  };
  return newDto;
};
export const createWasteReceptionDto = (
  values: WasteTabData<ReceivedWasteFormData, ReceivedWasteStorage>
): ReceptionDechetsDto1822 => {
  const newDto: ReceptionDechetsDto1822 = {
    aCasiersAmiante: values.formInfo.doesFacilityHaveAsbestosCases,
    aCasiersPlatre: values.formInfo.doesFacilityHaveCasiersPlatre,
    capaciteRestante: values.formInfo.remainingCapacity,
    receptionDechets: createWasteReceptionDechetDto(values.container),
  };
  return newDto;
};
