import { GasEnum, MethodEnum } from "./types";

export const gasOptions: GasEnum[] = [GasEnum.CO2, GasEnum.PFC, GasEnum.N2O];

export const methodLabels: {
  [key in MethodEnum]: string;
} = {
  ALTERNATIVE: "Méthode alternative/PFC",
  MESURE: "Méthode par mesure (SMCE)",
  FACTEUR_OXYDATION: "Facteur d'oxydation",
  FACTEUR_CONVERSION: "Facteur de conversion",
  BILAN_MASSIQUE: "Bilan massique",
};

export const methodsOptions: MethodEnum[] = [
  MethodEnum.ALTERNATIVE,
  MethodEnum.MESURE,
];

export const computedMethodsOptions: MethodEnum[] = [
  MethodEnum.FACTEUR_CONVERSION,
  MethodEnum.FACTEUR_OXYDATION,
  MethodEnum.BILAN_MASSIQUE,
];
