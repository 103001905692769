import React from "react";
import CardProfile from "./cards/CardProfile";
import { ProfilButton } from "./cards/ProfilButton";
import { useAutoClosedElement } from "common/utils/hooks/openableElems";

interface DropdownMenuProps {
  additionalClassname?: string;
}

const DropdownMenu = ({
  additionalClassname,
}: DropdownMenuProps): React.ReactElement => {
  const [isDetailOpen, setIsDetailOpen, cardRef] = useAutoClosedElement<
    HTMLDivElement
  >();

  return (
    <div className={additionalClassname}>
      <ProfilButton
        onClick={() => {
          setIsDetailOpen(!isDetailOpen);
        }}
      />

      {isDetailOpen && <CardProfile containerRef={cardRef} />}
    </div>
  );
};

export default DropdownMenu;
