import { EauBlocInfoGeneralesDto } from "api/gen/api";
import { BlocInfoGeneralesFormValues } from "./types";

export const dtoToBlocValues = (
  dto: EauBlocInfoGeneralesDto
): BlocInfoGeneralesFormValues => ({
  depassementSeuil: dto.depassementSeuilPrelevement,
  megathermie: dto.megathermie,
});

export const blocValuesToDto = (
  values: BlocInfoGeneralesFormValues
): EauBlocInfoGeneralesDto => ({
  depassementSeuilPrelevement: values.depassementSeuil,
  megathermie: values.megathermie,
});
