import { ReferenceInfosGeneralesControllerApi } from "api/gen";

const computeGunNotification = (
  hasAiotImportFailed: boolean,
  hasGunUpdateFailed: boolean
): string | null => {
  if (hasAiotImportFailed) {
    return "Les champs interconnectés à l’application GUNenv n’ont pas pu être initialisés en raison d’un dysfonctionnement du service. Les champs concernés seront automatiquement initialisés lorsque l’interconnexion sera à nouveau opérationnelle.";
  } else if (hasGunUpdateFailed) {
    return "La dernière mise à jour des champs GUN n’a pas pu être réalisée en raison d’un dysfonctionnement du service. Les champs concernés seront automatiquement mis à jour lorsque l’interconnexion sera à nouveau opérationnelle.";
  } else {
    return null;
  }
};

export const fetchGunNotification = async (
  etablissementCode: string,
  annee: number,
  referenceInfoGeneralController: ReferenceInfosGeneralesControllerApi
): Promise<string | null> => {
  const hasAiotImportFailed = await referenceInfoGeneralController.getDeclarationAiotStatusUsingGET(
    annee,
    etablissementCode
  );
  const hasGunUpdateFailed = await referenceInfoGeneralController.getHasUpdateFailTodayUsingGET();

  return computeGunNotification(hasAiotImportFailed, hasGunUpdateFailed);
};
