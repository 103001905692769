import React, { useMemo } from "react";
import { useFormikBloc } from "common/formikBloc/utils";
import Row from "common/presentational/Row";
import {
  LecteurDroitDtoAllowedSectionsEnum,
  ReferenceActiviteQuotaDto,
  ReferenceEprtrDto,
  ReferenceItemEprtrDto,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { TechniqueQuotasValues } from "./TypeActiviteQuota/utils";
import RetourTableauDeBordButton from "./RetourTableauDeBordButton";
import { makeStyles } from "@material-ui/styles";
import GlobalInspectorContactButton from "../../../from21/toNow/Actions/Global/GlobalInspectorContactButton";
import TypeActiviteQuota from "./TypeActiviteQuota";
import QuotasInspectorContactButton from "../../../from21/toNow/Actions/Quotas/QuotasInspectorContactButton";
import TypeActiviteGlobal from "../../../from21/toNow/TypeActivite/TypeActiviteGlobal";
import { TechniqueGlobalValues } from "../../../from21/toNow/TypeActivite/TypeActiviteGlobal/utils";

const useStyles = makeStyles({
  container: {
    justifyContent: "flex-end",
    marginTop: "20px",
    marginBottom: "50px",
  },
});

interface TypeActiviteControllerProps {
  referentielEPRTR: ReferenceEprtrDto;
  referentielActivites: ReferenceActiviteQuotaDto;
}

const TypeActiviteController = ({
  referentielEPRTR,
  referentielActivites,
}: TypeActiviteControllerProps): React.ReactElement => {
  const {
    isAnyPrestataire,
    isLecteur,
    isLecteurOnSection,
    isLecteurOnlyOnSection,
  } = useUserData();
  const canSeeTypeActiviteGlobal =
    !isAnyPrestataire &&
    !isLecteurOnlyOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);
  const canSeeTypeActiviteQuotas =
    !isLecteur || isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);

  const classes = useStyles();

  const companyTechniqueGlobalProps = useFormikBloc<TechniqueGlobalValues>();
  const companyTechniqueQuotasProps = useFormikBloc<TechniqueQuotasValues>();

  const memoReferentielEPRTR: ReferenceItemEprtrDto[] = useMemo(() => {
    return referentielEPRTR.referenceItemEprtrDtoList.filter(
      value => value.estSelectionnable
    );
  }, [referentielEPRTR]);

  return (
    <>
      {canSeeTypeActiviteGlobal && (
        <>
          <TypeActiviteGlobal
            formikRef={companyTechniqueGlobalProps.formikRef}
            hasChanges={companyTechniqueGlobalProps.hasChanges}
            setHasChanges={companyTechniqueGlobalProps.setHasChanges}
            referentielEPRTR={memoReferentielEPRTR}
          />
          <GlobalInspectorContactButton />
          <Row />
        </>
      )}
      {canSeeTypeActiviteQuotas && (
        <>
          <TypeActiviteQuota
            formikRef={companyTechniqueQuotasProps.formikRef}
            hasChanges={companyTechniqueQuotasProps.hasChanges}
            setHasChanges={companyTechniqueQuotasProps.setHasChanges}
            referentialActivites={referentielActivites}
          />
          <QuotasInspectorContactButton />
          <Row />
        </>
      )}
      <Row additionalClassname={classes.container}>
        <RetourTableauDeBordButton />
      </Row>
    </>
  );
};

export default TypeActiviteController;
