import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../utils/utils";
import { createAppareilDiv } from "./utils/utils";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { EmissionsInArray, MesureEmissionInArray } from "./utils/types";
import _ from "lodash";
import { ClassesType } from "common/utils/types";
import { ERROR } from "theme";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  mesureEmissionsInArray: MesureEmissionInArray[];
  setMesureEmissionsInArray: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationMesuresPath: string;
}

const EmissionArray = ({
  mesureEmissionsInArray,
  setMesureEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationMesuresPath,
}: EmissionArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInArray: MesureEmissionInArray) {
    setMesureEmissionsInArray(elderEmissions => {
      return elderEmissions.filter(elderEmissionInArray => {
        return singleEmissionInArray.data.id !== elderEmissionInArray.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: MesureEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      emission.data.substance !== null ? emission.data.substance.label : "",
      isValidated,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.appareils && createAppareilDiv(emission.data.appareils),
        classes
      ),
      computeWrappedCellWithError(
        emission.data.heures !== null
          ? computeNumberWithSeparator(emission.data.heures)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.debit !== null
          ? computeNumberWithSeparator(emission.data.debit)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.continuDebit ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.frequenceDebit !== null
          ? computeNumberWithSeparator(emission.data.frequenceDebit)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.concentrationDebit !== null
          ? computeNumberWithSeparator(emission.data.concentrationDebit)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.continuConcentration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.frequenceConcentration !== null
          ? computeNumberWithSeparator(emission.data.frequenceConcentration)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.biomasseMesure !== null
          ? computeNumberWithSeparator(emission.data.biomasseMesure)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.nature || undefined}>{emission.data.nature}</p>,
        classes
      ),
      computeWrappedCellWithError(emission.data.rendement, classes),
      computeWrappedCellWithError(
        emission.data.emissionsAnnuelles !== null
          ? computeNumberWithSeparator(
              Number(emission.data.emissionsAnnuelles.toFixed(3))
            )
          : null,
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette emission ?"}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        isValidated={isValidated}
        commentPath={`${validationMesuresPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = filterByInstallation(
    mesureEmissionsInArray,
    installationName || ""
  ).map(mesure => {
    return computeSingleEmissionLine(mesure, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Nombre d'heures de fonctionnement (h/an)</p>,
        <p>Débit horaire moyen des effluents (Nm3/h)</p>,
        <p>Mesure en continu du débit</p>,
        <p>Fréquence de la mesure du débit (nb/an)</p>,
        <p>Concentration moyenne de polluant après traitement (kg/Nm3)</p>,
        <p>Mesure en continu de la concentration</p>,
        <p>Fréquence de la mesure de concentration (nb/an)</p>,
        <p>Fraction de la biomasse (%)</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        200,
        130,
        200,
        210,
        130,
        200,
        130,
        130,
        200,
        130,
        130,
        90,
      ]}
    />
  );
};

export default EmissionArray;
