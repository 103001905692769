import { BuildingInArray, BuildingNh3Emissions } from "../../utils/types";
import {
  especeLabels,
  especesList,
  getSpecieEmissionField,
} from "./selectPossibleValues";
import { AirElevageDetailNh320NowEspecesDeclareesEnum } from "api/gen";

export const computeKeysOfSelectedSpecies = (
  selectedSpecies: AirElevageDetailNh320NowEspecesDeclareesEnum[]
): (keyof BuildingNh3Emissions)[] => {
  return selectedSpecies.map(espece => getSpecieEmissionField(espece));
};

export const computeKeysOfUnselectedSpecies = (
  selectedSpecies: AirElevageDetailNh320NowEspecesDeclareesEnum[]
): (keyof BuildingNh3Emissions)[] => {
  return especesList
    .filter(espece => !selectedSpecies.includes(espece))
    .map(espece => getSpecieEmissionField(espece));
};

export const compareBuildingFunction = (
  a: BuildingInArray,
  b: BuildingInArray
): number => {
  if (a.data.name && b.data.name && a.data.name !== b.data.name) {
    return a.data.name < b.data.name ? -1 : 1;
  }
  return 0;
};

const speciesCategoriesForSorting = {
  volailles: new Set([
    AirElevageDetailNh320NowEspecesDeclareesEnum.CAILLES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.CANARDS,
    AirElevageDetailNh320NowEspecesDeclareesEnum.DINDES_ET_DINDONS,
    AirElevageDetailNh320NowEspecesDeclareesEnum.PINTADES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULES_PONDEUSES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULETS_DE_CHAIR,
    AirElevageDetailNh320NowEspecesDeclareesEnum.POULETTES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.AUTRES_VOLAILLES,
  ]),
  porcs: new Set([
    AirElevageDetailNh320NowEspecesDeclareesEnum.PORCELETS_EN_POST_SEVRAGE,
    AirElevageDetailNh320NowEspecesDeclareesEnum.PORCS_DE_PRODUCTION,
    AirElevageDetailNh320NowEspecesDeclareesEnum.COCHETTES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.TRUIES_EN_MATERNITE,
    AirElevageDetailNh320NowEspecesDeclareesEnum.TRUIES_EN_ATTENTE_DE_SAILLIE_ET_GESTANTES,
    AirElevageDetailNh320NowEspecesDeclareesEnum.VERRATS,
    AirElevageDetailNh320NowEspecesDeclareesEnum.APPROCHE_GLOBALE_PORCS,
  ]),
};

export const compareSpeciesFunction = (
  a: AirElevageDetailNh320NowEspecesDeclareesEnum,
  b: AirElevageDetailNh320NowEspecesDeclareesEnum
): number => {
  if (
    speciesCategoriesForSorting.porcs.has(a) &&
    speciesCategoriesForSorting.volailles.has(b)
  ) {
    return 1;
  }
  if (
    speciesCategoriesForSorting.volailles.has(a) &&
    speciesCategoriesForSorting.porcs.has(b)
  ) {
    return -1;
  }
  return especeLabels[a].localeCompare(especeLabels[b]);
};
