import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "common/button";
import Row from "common/presentational/Row";
import {
  filterFieldMatcher,
  FilterFormValues,
} from "pages/InspectorSpace/DeclarationsDashboard/types";
import { useActiveOrLastCampaign } from "pages/CommonSpace/CampaignContext";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { ReferenceItemDepartementDto, ReferenceItemRegionDto } from "api/gen";
import { FetchedReferentials } from "pages/CommonSpace/ReferentialsContext/types";
import {
  computeDepartementLabel,
  computeRegionLabel,
  filterDepartementsInRegion,
  filterDepartementsList,
  filterRegionList,
} from "pages/InspectorSpace/DeclarationsDashboard/FilterField/helpers/locations";
import {
  computeStateLabel,
  quotasStateOptions,
} from "pages/InspectorSpace/DeclarationsDashboard/FilterField/helpers/declarationStates";
import FormikAdapter from "libAdapter/Formik/FormikAdapter";

const useStyles = makeStyles({
  buttonRow: {
    display: "flex",
    marginTop: "15px",
    justifyContent: "end",
  },
  button: {
    height: "100%",
  },
});

interface FilterFormProps {
  filters: FilterFormValues;
  setFilters: (filters: FilterFormValues) => void;
  close: () => void;
  departementNumbersList: string[];
  referentialLocations: FetchedReferentials<
    "REFERENCE_DEPARTEMENTS" | "REFERENCE_REGIONS"
  > | null;
}

const FilterVerificatorForm = ({
  filters,
  setFilters,
  close,
  departementNumbersList,
  referentialLocations,
}: FilterFormProps): React.ReactElement => {
  const classes = useStyles();
  const campaign = useActiveOrLastCampaign();

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "",
  };

  const departementsList = filterDepartementsList(
    (referentialLocations &&
      referentialLocations.REFERENCE_DEPARTEMENTS &&
      referentialLocations.REFERENCE_DEPARTEMENTS.departements) ||
      [],
    departementNumbersList
  );

  const regionsList = filterRegionList(
    (referentialLocations &&
      referentialLocations.REFERENCE_REGIONS &&
      referentialLocations.REFERENCE_REGIONS.referenceItemRegionDtoList) ||
      [],
    departementsList
  );

  const REFERENTIAL_YEARS: number[] = [];
  for (let i = 2018; i <= campaign.annee; i++) {
    REFERENTIAL_YEARS.push(i);
  }

  return (
    <FormikAdapter
      initialValues={filters}
      onSubmit={values => {
        setFilters(values);
        close();
      }}
      render={({ handleSubmit, values, setFieldValue }) => (
        <form onSubmit={handleSubmit}>
          <WrappedChoiceSelect
            name={filterFieldMatcher.region}
            label="Région"
            options={regionsList.sort((region1, region2) =>
              computeRegionLabel(region1).localeCompare(
                computeRegionLabel(region2)
              )
            )}
            computeLabel={(lineData: ReferenceItemRegionDto | null) =>
              lineData ? computeRegionLabel(lineData) : ""
            }
            additionalOnChange={(newValue: ReferenceItemRegionDto | null) => {
              newValue !== null && setFieldValue("departement", null);
            }}
            commonProps={commonProps}
            isMulti={false}
          />

          <WrappedChoiceSelect
            name={filterFieldMatcher.departement}
            label="Département"
            options={filterDepartementsInRegion(
              departementsList,
              values.region
            )}
            computeLabel={(lineData: ReferenceItemDepartementDto | null) =>
              lineData ? computeDepartementLabel(lineData) : ""
            }
            commonProps={commonProps}
            isMulti={false}
          />

          <WrappedChoiceSelect
            label={() => (
              <>
                État de la déclaration
                <br />
                quotas émissions
              </>
            )}
            name={filterFieldMatcher.quotasEmissionsState}
            options={quotasStateOptions}
            computeLabel={computeStateLabel}
            commonProps={commonProps}
            isMulti={false}
          />

          <WrappedChoiceSelect
            label={() => (
              <>
                État de la déclaration
                <br />
                quotas niveaux d'activité
              </>
            )}
            name={filterFieldMatcher.quotasAllocationsState}
            options={quotasStateOptions}
            computeLabel={computeStateLabel}
            commonProps={commonProps}
            isMulti={false}
          />

          <WrappedChoiceSelect
            label="Année de la déclaration"
            name={filterFieldMatcher.declarationYear}
            options={REFERENTIAL_YEARS}
            computeLabel={annee => annee.toString()}
            commonProps={commonProps}
            isMulti={true}
          />

          <Row additionalClassname={classes.buttonRow}>
            <Button
              text="Rechercher"
              type="submit"
              additionalClassname={classes.button}
            />
          </Row>
        </form>
      )}
    />
  );
};

export default FilterVerificatorForm;
