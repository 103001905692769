import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  AirSyntheseEmissionDtoMethodeEnum,
  AirSyntheseEmissionDtoReferenceCalculeEnum,
  AirSyntheseEmissionDtoReferenceMesureEnum,
} from "api/gen";

export interface MethodSyntheseInfo {
  code: AirSyntheseEmissionDtoMethodeEnum;
  display: string;
}

export const arrayMethods: OptionPropsWithObject<MethodSyntheseInfo>[] = [
  {
    value: 1,
    label: "Mesure",
    object: {
      code: AirSyntheseEmissionDtoMethodeEnum.MESURE,
      display: "Mesure",
    },
  },
  {
    value: 2,
    label: "Calcul",
    object: {
      code: AirSyntheseEmissionDtoMethodeEnum.CALCUL,
      display: "Calcul",
    },
  },
  {
    value: 3,
    label: "Estimation",
    object: {
      code: AirSyntheseEmissionDtoMethodeEnum.ESTIMATION,
      display: "Estimation",
    },
  },
];

export interface ReferenceMesure {
  code: AirSyntheseEmissionDtoReferenceMesureEnum;
  display: string;
}

export const arrayMethodRefM: OptionPropsWithObject<ReferenceMesure>[] = [
  {
    value: 1,
    label: "INT",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.INT,
      display: "INT",
    },
  },
  {
    value: 2,
    label: "PER",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.PER,
      display: "PER",
    },
  },
  {
    value: 3,
    label: "NRO",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.NRO,
      display: "NRO",
    },
  },
  {
    value: 4,
    label: "ALT",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.ALT,
      display: "ALT",
    },
  },
  {
    value: 5,
    label: "MRC",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.MRC,
      display: "MRC",
    },
  },
  {
    value: 6,
    label: "AUT",
    object: {
      code: AirSyntheseEmissionDtoReferenceMesureEnum.AUT,
      display: "AUT",
    },
  },
];

export interface ReferenceCalcule {
  code: AirSyntheseEmissionDtoReferenceCalculeEnum;
  display: string;
}
export const arrayMethodRefC: OptionPropsWithObject<ReferenceCalcule>[] = [
  {
    value: 1,
    label: "INT",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.INT,
      display: "INT",
    },
  },
  {
    value: 2,
    label: "PER",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.PER,
      display: "PER",
    },
  },
  {
    value: 3,
    label: "NRO",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.NRO,
      display: "NRO",
    },
  },
  {
    value: 4,
    label: "BMA",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.BMA,
      display: "BMA",
    },
  },
  {
    value: 5,
    label: "CSS",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.CSS,
      display: "CSS",
    },
  },
  {
    value: 6,
    label: "AUT",
    object: {
      code: AirSyntheseEmissionDtoReferenceCalculeEnum.AUT,
      display: "AUT",
    },
  },
];
