import { findElementMatchingTemplate } from "common/utils/methods";
import {
  EquipeGunItemDto,
  NatureServiceWithEnumDto,
  ReferenceItemCodeApeDto,
  ReferenceItemGeoCodeInseeDto,
  ServiceGunItemDto,
} from "api/gen";
import { InspectionServiceDto } from "./types";

export const getRefApeDtoById = (
  apeRef: ReferenceItemCodeApeDto[],
  apeId: string
): ReferenceItemCodeApeDto | null => {
  return findElementMatchingTemplate({ uuid: apeId }, apeRef);
};

export const getRefCityInseeById = (
  cityRef: ReferenceItemGeoCodeInseeDto[],
  cityId: string
): ReferenceItemGeoCodeInseeDto | null => {
  return findElementMatchingTemplate({ uuid: cityId }, cityRef);
};

export const getRefCityInseeByNomCommune = (
  commune: string | null,
  referentiel: ReferenceItemGeoCodeInseeDto[]
): ReferenceItemGeoCodeInseeDto | null => {
  return referentiel.find(ref => ref.nomCommune === commune) || null;
};

export const computeServiceInspectionList = (
  servicesGunList: ServiceGunItemDto[],
  gerepServiceList: NatureServiceWithEnumDto[]
): InspectionServiceDto[] => {
  return servicesGunList
    .map(service => {
      return {
        id: service.identifiantService,
        libelle: service.libelleCourt,
      };
    })
    .concat(
      gerepServiceList.map(service => {
        return {
          id: service.natureService.id,
          libelle: service.natureService.nom,
        };
      })
    );
};

export const computeEquipesGunMap = (
  equipeGunList: EquipeGunItemDto[]
): Map<string, EquipeGunItemDto> => {
  return new Map(
    equipeGunList.map(equipe => [equipe.identifiantEquipe, equipe])
  );
};
