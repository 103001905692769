import { TechniqueQuotasValues } from "./utils";
import { useCallback } from "react";
import { createTechniqueQuotaDto } from "./handlers";
import { InstallationInArray } from "./Installations/types";
import {
  useDeclarationStateHandlers23Now,
  useUpdateDeclarationTypeActiviteQuota23Now,
} from "../../versionedElements/declarationHooks23Now";

export const useTechniqueQuotaSaveHandler = (): ((
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
) => Promise<void>) => {
  const setTypeActiviteQuota = useUpdateDeclarationTypeActiviteQuota23Now();

  const update = useCallback(
    async (
      values: TechniqueQuotasValues,
      installationsInArray: InstallationInArray[]
    ) => {
      const newTechniqueDto = createTechniqueQuotaDto(
        values,
        installationsInArray
      );
      await setTypeActiviteQuota(newTechniqueDto);
    },
    [setTypeActiviteQuota]
  );
  return update;
};

export const useTechniqueQuotaValidateHandler = (): ((
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
) => Promise<void>) => {
  const { startQuota } = useDeclarationStateHandlers23Now();

  const startCall = useCallback(
    async (
      values: TechniqueQuotasValues,
      installationsInArray: InstallationInArray[]
    ) => {
      const newTechniqueDto = createTechniqueQuotaDto(
        values,
        installationsInArray
      );
      await startQuota(newTechniqueDto);
    },
    [startQuota]
  );
  return startCall;
};

export const useTechniqueQuotaEditHandler = (): (() => Promise<void>) => {
  const { resetQuota } = useDeclarationStateHandlers23Now();

  const resetCall = useCallback(async () => {
    await resetQuota();
  }, [resetQuota]);
  return resetCall;
};
