import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  icon: {
    color: "red",
    marginLeft: "5px",
  },
  flexCentered: props => ({
    color: "red",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    visibility: props.isHidden ? "hidden" : "visible",
  }),
});

interface ErrorDisplayerProps {
  message?: string;
  isHidden?: boolean;
  key?: string;
}

const ErrorDisplayer = ({
  message,
  isHidden,
}: ErrorDisplayerProps): React.ReactElement => {
  const classes = useStyles({ isHidden: isHidden });

  return message ? (
    <div className={classes.flexCentered}>
      <FontAwesomeIcon icon={"times"} className={classes.icon} size="2x" />
      <p style={{ marginLeft: "10px" }}>{message}</p>
    </div>
  ) : (
    <></>
  );
};

export default ErrorDisplayer;
