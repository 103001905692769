import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export interface AggregationModaleValues {
  resumeDeclarations: boolean;
  infoGenerales: boolean;
  typeActiviteGlobal: boolean;
  typeActiviteQuotas: boolean;
  dechets: boolean;
  eau: boolean;
  sol: boolean;
  airCombustion: boolean;
  airProcedes: boolean;
  airSolvants: boolean;
  airIsdnd: boolean;
  airElevage: boolean;
  airSynthese: boolean;
  airGazFluores: boolean;
  quotasEmissions: boolean;
  quotasAllocations: boolean;
  quotasWithAnyExclusions: boolean;
  carriereEnvironnement: boolean;
  carriereProduction: boolean;
  carriereSanteSecurite: boolean;
  spfo: boolean;
}

export const sectionFieldMatcher = fieldMatcherBuilder<
  AggregationModaleValues
>().build({
  resumeDeclarations: "resumeDeclarations",
  infoGenerales: "infoGenerales",
  typeActiviteGlobal: "typeActiviteGlobal",
  typeActiviteQuotas: "typeActiviteQuotas",
  dechets: "dechets",
  eau: "eau",
  sol: "sol",
  airCombustion: "airCombustion",
  airProcedes: "airProcedes",
  airSolvants: "airSolvants",
  airIsdnd: "airIsdnd",
  airElevage: "airElevage",
  airSynthese: "airSynthese",
  airGazFluores: "airGazFluores",
  quotasEmissions: "quotasEmissions",
  quotasAllocations: "quotasAllocations",
  quotasWithAnyExclusions: "quotasWithAnyExclusions",
  carriereEnvironnement: "carriereEnvironnement",
  carriereProduction: "carriereProduction",
  carriereSanteSecurite: "carriereSanteSecurite",
  spfo: "spfo",
});
