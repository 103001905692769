import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import { CovInArray, CovInModale } from "./utils/types";
import FormCov from "./FormSingleCov";
import { ProcedeInArray } from "../ListProcede/utils/types";
import { addOrModifyArray } from "common/declarant/array/utils";

interface CovProps {
  covModaleOpen: boolean;
  covInModale: CovInArray | null;
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  setCovInModale: Dispatch<SetStateAction<CovInArray | null>>;
  setCovModaleOpen: Dispatch<SetStateAction<boolean>>;
  procedesInPageMap: Map<string, ProcedeInArray>;
}

const CovModale = ({
  covModaleOpen,
  covInModale,
  setCovsInPage,
  setCovInModale,
  setCovModaleOpen,
  procedesInPageMap,
}: CovProps): React.ReactElement => {
  const onClose = () => {
    setCovInModale(null);
    setCovModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={covModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de cov de mention de danger."
      onRequestClose={onClose}
    >
      <FormCov
        procedesInPageMap={procedesInPageMap}
        closeFunction={onClose}
        onSubmit={(values, formikBag) => {
          if (values.substance === null) {
            throw Error("Should not happen");
          }

          const newCov: CovInModale = {
            substance: values.substance,
            procedes: values.procedes,
            flux: values.flux,
          };
          addOrModifyArray(
            setCovsInPage,
            covInModale && covInModale.data.id,
            newCov
          );
          onClose();
        }}
        isEdit={covInModale !== null}
        initialCov={
          covInModale !== null
            ? covInModale.data
            : { flux: null, procedes: null, substance: null }
        }
      />
    </CustomModal>
  );
};

export default CovModale;
