import React from "react";
import { FactoryInModale, france } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  ReferenceGeoCodeInseeDto,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ReferencePaysDto,
} from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { FormComponentProps } from "common/form/utils";
import { validateFactory } from "./utils/validate";

interface FormFactoryProps extends FormComponentProps<FactoryInModale> {
  referentialCountries: ReferencePaysDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const countryIsFilled = (
  country: OptionPropsWithObject<ReferenceItemPaysDto | null> | null
) => {
  return country !== null;
};

const FormFactory = ({
  closeFunction,
  onSubmit,
  initialObject,
  referentialCountries,
  referentialInsee,
  isEdit,
}: FormFactoryProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-informations-transformation",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const getCityOptionProps = (
    city: string | null
  ): OptionPropsWithObject<ReferenceItemGeoCodeInseeDto> | null => {
    if (city) {
      const result = referentialInsee.referenceItemGeoCodeInseeDtoList.find(
        ref => ref.nomCommune === city
      );
      return result
        ? {
            value: parseInt(result.numeroDepartement),
            label: result.nomCommune,
            object: result,
          }
        : null;
    }
    return null;
  };

  return (
    <CommonFormSingleEntity
      isEdit={isEdit}
      title="AJOUTER UNE USINE/ATELIER"
      validate={validateFactory}
      initialEntity={initialObject}
      closeFunction={closeFunction}
      onSubmit={(values: FactoryInModale, formikActions) => {
        onSubmit(values, formikActions);
      }}
      renderField={formProps => (
        <>
          <TextField label="Raison sociale *" name="socialReason" />

          <TextField label="Code postal *" name="zipCode" />

          <WrappedChoiceSelectModale<
            OptionPropsWithObject<ReferenceItemPaysDto | null>
          >
            label="Pays *"
            name="country"
            title=""
            header={["Pays"]}
            linesData={[
              { label: france, value: 0, object: null },
              ...referentialCountries.referenceItemPaysDtoList.map(
                (item, index) => {
                  return {
                    label: item.designation,
                    value: index + 1,
                    object: item,
                  };
                }
              ),
            ]}
            formatLine={lineData => [lineData.label]}
            formatSelectedTitle={lineData => lineData.label}
            isLineInSearch={(lineData, searchedStr) =>
              isSearchStringInCollection([lineData.label], searchedStr)
            }
            commonProps={commonProps}
            onSelect={newValue => {
              if (newValue) {
                // because city is doubly typed, we must do the OptionPropsWithObject to string or string to OptionPropsWithObject conversions depending on whether or not we are in France.
                if (
                  newValue.label !== france &&
                  typeof formProps.values.city !== "string"
                ) {
                  formProps.setFieldValue(
                    "city",
                    formProps.values.city ? formProps.values.city.label : null
                  );
                }
                if (
                  newValue.label === france &&
                  typeof formProps.values.city === "string"
                ) {
                  formProps.setFieldValue(
                    "city",
                    getCityOptionProps(formProps.values.city)
                  );
                }
              }
            }}
          />

          {countryIsFilled(formProps.values.country) && (
            <>
              {formProps.values.country &&
              formProps.values.country.label === france ? (
                <WrappedChoiceSelectModale<
                  OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>
                >
                  label="Ville *"
                  name="city"
                  title=""
                  header={["Ville"]}
                  linesData={[
                    ...referentialInsee.referenceItemGeoCodeInseeDtoList.map(
                      (item, index) => {
                        return {
                          label: item.nomCommune,
                          value: index,
                          object: item,
                        };
                      }
                    ),
                  ]}
                  formatLine={lineData => [lineData.label]}
                  formatSelectedTitle={lineData => lineData.label}
                  isLineInSearch={(lineData, searchedStr) =>
                    isSearchStringInCollection([lineData.label], searchedStr)
                  }
                  commonProps={commonProps}
                />
              ) : (
                <TextField
                  label="Ville *"
                  name="city"
                  tooltipContent="Saisir le nom de la commune en mettant des tirets (-) entre les mots pour une commune à noms composés et sans saisir l’article par lequel commence éventuellement le nom de la commune. Exemple pour « Les Sables d’Olonne », écrire « Sables- d’Olonne (les)», ou pour « Le Havre » écrire « Havre (Le) »"
                />
              )}
            </>
          )}

          <NumberField
            label="Distance carrière-usine/atelier *"
            name="careerRange"
            unit="km"
            tooltipContent="Concerne notamment les carrières de minéraux industriels (dont calcaires cimentiers, chaux, gypse/anhydrite, argiles communes pour tuiles & briques) ou de roches ornementales ou de construction. Ne concerne pas le plus souvent les carrières de granulats - celles équipées seulement d'installation(s) de concassage et/ou criblage dans la carrière - ou de matériaux > 80 mm. Sauf si, outre les opérations de concassage et/ou criblage, le granulat est traité dans une usine pour être lavé, roulé, trié automatiquement et/ou séché. La distance carrière - usine/atelier est mesurée entre la sortie de la carrière et l'entrée de l’usine/atelier où les matériaux sont transformés. Si l’usine ou l’atelier est juxtaposé(e) à l’enceinte du périmètre de la carrière, il y a lieu de considérer que cette distance est égale à 0."
          />

          <CheckBox
            label="Usine/atelier alimenté par d'autres carrières"
            name="poweredByOtherCareers"
          />
        </>
      )}
    />
  );
};

export default FormFactory;
