import * as Yup from "yup";
import {
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";

export const validationProductionSchema = Yup.object().shape({
  wasteId: commonObjectFields,
  wasteAmount: commonPositiveNumberFields,
  method: commonObjectFields,
  methorRef: Yup.object().nullable(),
  description: Yup.string().nullable(),
  operation: Yup.object().nullable(),
  location: Yup.object().nullable(),
  departement: Yup.object().nullable(),
  pays: Yup.object().nullable(),
  receptionName: Yup.string().nullable(),
  receptionAdress: Yup.string().nullable(),
  treatmentName: Yup.string().nullable(),
  treatmentAdress: Yup.string().nullable(),
  notification: Yup.string().nullable(),
});
