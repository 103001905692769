import {
  QuotasEmissionsVerificationDto20NowConclusionEnum,
  QuotasEmissionsVerificationDto20NowConditionDispenseEnum,
  QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum,
  QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum,
  QuotasEmissionsVerificationDto20NowOrganismeEnum,
} from "api/gen";
import {
  commonBooleanFields,
  commonDateFieldsNullable,
  commonNullableStringFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  futureDateMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";
import * as Yup from "yup";
import {
  isReportUnsatisfying,
  isSatisfyingWithRequests,
} from "./selectPossibleValues";
import {
  BlocVerificationFormValues,
  fieldBlocMatcher,
  fieldMatcher,
} from "./types";

//TODO [GEREP-2579] il faudra remettre le shape BlocVerificationFormValues ici.
export const validationSchema = Yup.object().shape({
  verifications: Yup.array().of(
    //TODO [GEREP-2579] il faudra remettre le shape BlocVerificationFormValuesProps ici.
    Yup.object().shape({
      id: commonStringFields,
      nimDynId: commonStringFields,
      organisme: Yup.mixed<
        QuotasEmissionsVerificationDto20NowOrganismeEnum
      >().nullable(),
      referenceDernierPlanSurveillance: commonNullableStringFields,
      dispenseVisiteSite: commonBooleanFields,
      conditionDispense: Yup.mixed<
        QuotasEmissionsVerificationDto20NowConditionDispenseEnum
      >().nullable(),
      precisionDispense: commonNullableStringFields,
      dateDerniereVisite: commonDateFieldsNullable,
      rapportAmeliorationRequis: commonBooleanFields,
      conclusion: Yup.mixed<
        QuotasEmissionsVerificationDto20NowConclusionEnum
      >().nullable(),
      motifsStatisfaisantAvecRemarque: Yup.array().of(
        Yup.mixed<
          QuotasEmissionsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum
        >()
      ),
      motifsNonSatisfaisant: Yup.array().of(
        Yup.mixed<
          QuotasEmissionsVerificationDto20NowMotifsNonSatisfaisantEnum
        >()
      ),
    })
  ),
});

export const additionalFieldValidation = (
  values: BlocVerificationFormValues
): Errors<BlocVerificationFormValues> => {
  const errors: Errors<Record<string, unknown>> = {};

  // the validation is done manually because there is a bug with yup
  // as all the fields of FieldArray are not displayed at the same time
  values.verifications.forEach((verification, index) => {
    if (!verification.organisme) {
      errors[
        `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.organisme}`
      ] = requiredMessage;
    }
    if (!verification.referenceDernierPlanSurveillance) {
      errors[
        `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.monitoringPlanReference}`
      ] = requiredMessage;
    }
    if (verification.dispenseVisiteSite) {
      if (!verification.conditionDispense) {
        errors[
          `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.conditionExemption}`
        ] = requiredMessage;
      }
      if (
        verification.conditionDispense ===
          QuotasEmissionsVerificationDto20NowConditionDispenseEnum.AUTRE &&
        !verification.precisionDispense
      ) {
        errors[
          `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.detailExemption}`
        ] = requiredMessage;
      }
    }
    if (!verification.dateDerniereVisite) {
      errors[
        `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.lastVisitDate}`
      ] = requiredMessage;
    }
    if (
      verification.dateDerniereVisite &&
      verification.dateDerniereVisite > new Date()
    ) {
      errors[
        `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.lastVisitDate}`
      ] = futureDateMessage;
    }
    if (!verification.conclusion) {
      errors[
        `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.verificationReportConclusion}`
      ] = requiredMessage;
    }
    if (verification.conclusion) {
      if (
        isSatisfyingWithRequests(verification.conclusion) &&
        verification.motifsStatisfaisantAvecRemarque.length === 0
      ) {
        errors[
          `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.satisfyingDeclarationReason}`
        ] = requiredMessage;
      }
      if (
        isReportUnsatisfying(verification.conclusion) &&
        verification.motifsNonSatisfaisant.length === 0
      ) {
        errors[
          `${fieldBlocMatcher.verifications}.${index}.${fieldMatcher.unsatisfyingDeclarationReason}`
        ] = requiredMessage;
      }
    }
  });
  return errors;
};
