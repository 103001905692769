import React from "react";
import { Field } from "libAdapter/Formik/FieldComponentAdaptater";
import _get from "lodash.get";
import { CommonProps, ErrorDataProps } from "../../utils";
import { makeStyles } from "@material-ui/styles";
import SimpleInputDisplayer from "../displayers/SimpleInputDisplayer";
import { UnwrappedFieldProps } from "../types/basicTypes";

const useStyles = makeStyles({
  red: {
    color: "red",
  },
});

//props awaited in a FieldWrapper component
export interface FieldWrapperProps {
  renderLabel?: (id: string) => React.ReactElement;
  name: string;
  commonProps: CommonProps;
  renderField: (props: UnwrappedFieldProps, id: string) => React.ReactElement;
  additionalClassName?: string;
}

const FieldWrapper = ({
  name,
  renderField,
  ...props
}: FieldWrapperProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={(formikProps: UnwrappedFieldProps) => {
        const fieldTouched = _get(
          formikProps.form.touched,
          formikProps.field.name,
          false
        );
        const errorMessage = _get(
          formikProps.form.errors,
          formikProps.field.name,
          ""
        );

        // for fields that contain an array, the touched value is empty array on edit, and it must still display errors
        const hasError: boolean = fieldTouched !== false && errorMessage !== "";
        const errorData: ErrorDataProps = hasError
          ? {
              errorIcon: "times",
              errorMessage: errorMessage,
              errorMessageClass: classes.red,
            }
          : {
              errorIcon: undefined,
              errorMessageClass: undefined,
              errorMessage: undefined,
            };

        return (
          <SimpleInputDisplayer
            {...props}
            name={name}
            renderInput={id => renderField(formikProps, id)}
            errorData={errorData}
          />
        );
      }}
    />
  );
};

export default FieldWrapper;
