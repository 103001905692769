import { DeclarationConstraintViolation19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";

export const filterAlertByPathAndIds = (
  alerts: DeclarationConstraintViolation19Now[],
  path: string,
  ids: string[]
): DeclarationConstraintViolation19Now[] => {
  return alerts
    .filter(alert => alert.path.includes(path))
    .filter(alert => ids.some(id => alert.path.includes(id)));
};

export const shouldDisplayWarningTab = (
  isValidated: boolean,
  errors: DeclarationConstraintViolation19Now[] | undefined,
  warnings: DeclarationConstraintViolation19Now[] | undefined
): boolean => {
  return (
    isValidated && ((errors || []).length > 0 || (warnings || []).length > 0)
  );
};
