import { makeStyles } from "@material-ui/styles";
import React from "react";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import classNames from "classnames";
import { LINK_STYLE } from "theme";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { renderSingleDiv } from "common/presentational/CommonEntityTable/renderHelper";
import { calculateEmission, calculateFinalEmission } from "./utils";
import { PolluantInArray } from "./types";
import { PATH_EAU_REJETPOLLUANT_REJETPOLLUANT } from "common/path/path18Now";
import { methodLabel, typeRejetLabel } from "./selectPossibleValues";

const useStyles = makeStyles({
  arrayPolluant: {},
  mediumCol: {
    width: "120px",
  },
  largeCol: {
    width: "150px",
  },

  cell: {
    margin: "auto",
    maxHeight: "2.4em", // 2 lines (1 line = 1em + space between lines = 0.2em)
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  errorCell: {
    backgroundColor: "red",
  },

  link: LINK_STYLE,

  depassementDeSeuil: {
    fontWeight: "bold",
  },
  normal: {
    fontWeight: "normal",
  },
});

const isDepassementDeSeuilMasseEmise = (polluant: PolluantInArray): boolean => {
  return polluant.data.emission != null &&
    polluant.data.pollutingSubstance != null &&
    polluant.data.pollutingSubstance.seuilKgAn != null &&
    polluant.data.emission > polluant.data.pollutingSubstance.seuilKgAn
    ? true
    : false;
};

interface PolluantArrayProps {
  polluantsInArray: PolluantInArray[];
  setPolluantsInArray: (polluants: PolluantInArray[]) => void;
  openModal: (polluant: PolluantInArray) => void;
  shouldDisabledFields: boolean;
}

const PolluantArray = ({
  polluantsInArray,
  setPolluantsInArray,
  openModal,
  shouldDisabledFields,
}: PolluantArrayProps): JSX.Element => {
  const classes = useStyles();

  function suppressPolluant(singlePolluantInArray: PolluantInArray) {
    setPolluantsInArray(
      polluantsInArray.filter(
        singleElderPolluant =>
          singleElderPolluant.data.id !== singlePolluantInArray.data.id
      )
    );
  }

  const getCell = (
    contentCell: React.ReactNode,
    errorMessage?: string
  ): React.ReactElement => (
    // TODO: find a better way to display error in array
    <div
      className={classNames(classes.cell, {
        [classes.errorCell]: errorMessage,
      })}
    >
      {contentCell}
    </div>
  );

  const getSinglePolluantLine = (
    polluant: PolluantInArray
  ): React.ReactElement[] => {
    const includingImportedContent =
      polluant.data.includingImported !== null
        ? computeNumberWithSeparator(polluant.data.includingImported)
        : "";

    const includingAccidentalContent =
      polluant.data.includingAccidental !== null
        ? computeNumberWithSeparator(polluant.data.includingAccidental)
        : "";

    return [
      getCell(
        <p
          className={classNames(
            { [classes.link]: !shouldDisabledFields },
            classes.cell
          )}
          onClick={() => !shouldDisabledFields && openModal(polluant)}
          title={
            polluant.data.pollutingSubstance
              ? polluant.data.pollutingSubstance.nom
              : ""
          }
        >
          {polluant.data.pollutingSubstance
            ? polluant.data.pollutingSubstance.nom
            : ""}
        </p>
      ),

      getCell(
        <p>{polluant.data.type ? typeRejetLabel[polluant.data.type] : ""}</p>
      ),

      getCell(
        <p
          className={
            isDepassementDeSeuilMasseEmise(polluant)
              ? classes.depassementDeSeuil
              : classes.normal
          }
        >
          {polluant.data.emission !== null
            ? computeNumberWithSeparator(polluant.data.emission)
            : ""}
        </p>
      ),

      getCell(<p>{includingImportedContent}</p>),

      getCell(<p>{includingAccidentalContent}</p>),

      getCell(
        <p>
          {polluant.data.usedMethod
            ? methodLabel[polluant.data.usedMethod]
            : ""}
        </p>
      ),

      getCell(
        <p>
          {polluant.data.methodReference
            ? polluant.data.methodReference.toString()
            : ""}
        </p>
      ),

      getCell(
        <p title={polluant.data.methodDescription || ""}>
          {polluant.data.methodDescription}
        </p>
      ),

      renderSingleDiv(
        polluant.data.normes
          ? polluant.data.normes.map(norme => norme.libelle)
          : []
      ),

      getCell(
        <p
          className={
            isDepassementDeSeuilMasseEmise(polluant)
              ? classes.depassementDeSeuil
              : classes.normal
          }
        >
          {computeNumberWithSeparator(calculateEmission(polluant.data))}
        </p>
      ),

      getCell(
        <p>
          {polluant.data.purificationYield !== null
            ? polluant.data.purificationYield + "%"
            : ""}
        </p>
      ),

      getCell(
        <p className={classes.cell}>
          {computeNumberWithSeparator(calculateFinalEmission(polluant.data))}
        </p>
      ),

      <CommonEntityButton
        handlerEdit={() => openModal(polluant)}
        handlerSuppress={() => suppressPolluant(polluant)}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette substance ?"}
        isValidated={shouldDisabledFields}
        commentPath={`${PATH_EAU_REJETPOLLUANT_REJETPOLLUANT}/${polluant.data.id}`}
      />,
    ];
  };

  const arrayPolluantsLines = polluantsInArray.map(getSinglePolluantLine);

  return (
    <CommonEntityTable
      header={[
        <p className={classes.largeCol}>Substance</p>,
        <p className={classes.mediumCol}>Type de rejet</p>,
        <p className={classes.mediumCol}>
          Masse émise
          <br />
          (kg/an)
        </p>,
        <p className={classes.mediumCol}>
          Dont masse importée
          <br />
          (kg/an)
        </p>,
        <p className={classes.mediumCol}>
          Dont masse accidentelle
          <br />
          (kg/an)
        </p>,
        <p className={classes.mediumCol}>Méthode</p>,
        <p className={classes.mediumCol}>Référence de la méthode</p>,
        <p className={classes.largeCol}>Description / Désignation</p>,
        <p>Normes appliquées</p>,
        <p className={classes.mediumCol}>Masse émise retenue (kg/an)</p>,
        <p className={classes.largeCol}>
          Rendement de la station externe sur le polluant (%)
        </p>,
        <p className={classes.mediumCol}>Rejet final (kg/an)</p>,
        <p className={classes.mediumCol}>Actions</p>,
      ]}
      preferredColWidths={[
        250,
        120,
        120,
        120,
        120,
        120,
        120,
        150,
        150,
        120,
        150,
        120,
        120,
      ]}
      preferredHeaderHeight={75}
      lines={arrayPolluantsLines}
      additionalClassName={classes.arrayPolluant}
    />
  );
};

export default PolluantArray;
