import { SpfoDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export const spfoFieldMatcher = fieldMatcherBuilder<SpfoDto>().build({
  quantityStored: "quantiteStockeeSurSite",
  quantityUsed: "quantiteUtilisee",
  quantityWasted: "quantiteEliminee",
  isSurfaceActivity: "activiteTraitementSurface",
  surfaceActivityDescription: "natureProcedeTraitementSurface",
});
