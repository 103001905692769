import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/styles";
import commentLogo from "./icons/commentLogo.svg";
import commentLogoDisable from "./icons/commentLogoDisabled.svg";
import commentLogoFull from "./icons/commentLogoFull.svg";
import classNames from "classnames";
import { CommentButtonState } from "../actions/types";

const useStyles = makeStyles({
  button: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "8px 8px",
    height: "38px",
    width: "38px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonFull: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "8px 8px",
    height: "38px",
    width: "38px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonEmpty: {
    outline: "none",
    border: "none",
    background: "none",
    padding: "8px 8px",
    height: "38px",
    width: "38px",
  },
  icon: {
    height: "20px",
    width: "20px",
  },
});

interface DummyCommentButtonProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  state: CommentButtonState;
  additionalClassName?: string;
  additionalStyle?: CSSProperties;
}

export const DummyCommentButton = ({
  onClick,
  state,
  additionalClassName,
  additionalStyle,
}: DummyCommentButtonProps): React.ReactElement => {
  const classes = useStyles();

  const getStyleFromState = (state: CommentButtonState): string => {
    switch (state) {
      case CommentButtonState.FULL:
        return classes.buttonFull;
      case CommentButtonState.EMPTY:
        return classes.button;
      case CommentButtonState.DISABLED:
        return classes.buttonEmpty;
    }
  };

  const getIconFromState = (state: CommentButtonState): string => {
    switch (state) {
      case CommentButtonState.FULL:
        return commentLogoFull;
      case CommentButtonState.EMPTY:
        return commentLogo;
      case CommentButtonState.DISABLED:
        return commentLogoDisable;
    }
  };

  return (
    <>
      <button
        onClick={onClick}
        className={classNames(getStyleFromState(state), additionalClassName)}
        style={{ ...additionalStyle }}
        disabled={state === CommentButtonState.DISABLED}
      >
        <img
          src={getIconFromState(state)}
          alt={"comment logo"}
          className={classes.icon}
        />
      </button>
    </>
  );
};
