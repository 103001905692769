import { InstallationInArray, InstallationInModale } from "../types";
import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonBooleanFieldsNullable,
  commonDateFieldsNullable,
  commonDateFieldsRequired,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  nimNumberMessage,
  quotasNIMUniqueMessage,
  quotasNombreActivitesMaxMessage,
  quotasNombreActivitesMinMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";

// Make sure validation schema has same keys as Installation
const singleInstallationValidationSchemaShape: {
  [index in keyof InstallationInModale]: Yup.SchemaOf<any>;
} = {
  nim: commonStringFields,
  nomInstallation: commonStringFields,
  entreeSEQE: commonDateFieldsRequired,
  sortieSEQE: commonDateFieldsNullable,
  codesActivite: commonArrayOfObjectFields
    .min(1, quotasNombreActivitesMinMessage)
    .max(5, quotasNombreActivitesMaxMessage),
  isNotAllocationGratuit: commonBooleanFieldsNullable,
  isNouvelEntrant: commonBooleanFieldsNullable,
};

export const singleInstallationValidationSchema = Yup.object().shape(
  singleInstallationValidationSchemaShape
);

export const validationSchema = Yup.object().shape({
  installations: Yup.array().of(
    Yup.object().shape({ data: singleInstallationValidationSchema })
  ),
});

export const preventSubmitWithErrors = (
  values: InstallationInModale,
  installationInArray: InstallationInArray[],
  initialInstallation: InstallationInModale
): Errors<InstallationInModale> => {
  const errors: Errors<InstallationInModale> = {};
  if (values.nim) {
    if (!values.nim.match(/^FR[0-9]{15}$|^FR-new-[0-9]{8}$/g)) {
      errors.nim = nimNumberMessage;
    }
    if (installationInArray.some(i => i.data.nim === values.nim)) {
      if (initialInstallation.nim !== values.nim) {
        errors.nim = quotasNIMUniqueMessage;
      }
    }
  }
  return errors;
};
