import { AirCombustionAppareilDto } from "api/gen";
import { arrayNature, arrayTypeFoyer } from "./selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { AppareilInArray } from "./types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ObjectInstallations } from "../../../blocInstallation/utils/selectPossibleValues";

//=============================================================
// APPAREILS
//=============================================================
export const convertAppareilsToDisplayed = (
  appareil: AirCombustionAppareilDto
): AppareilInArray => {
  const appareilNature =
    appareil.nature === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: appareil.nature } },
          arrayNature
        );

  const appareilTypeFoyer =
    appareil.typeFoyer === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: appareil.typeFoyer } },
          arrayTypeFoyer
        );

  return {
    data: {
      nom: appareil.nom,
      nameInstallation: appareil.installation,
      date: appareil.date ? new Date(appareil.date) : null,
      modification: appareil.modification,
      lowestThan1500: appareil.lowestThan1500,
      localisation: appareil.localisation,
      activite: appareil.activite,
      nature: appareilNature,

      natureAutre: appareil.natureAutre,
      typeFoyer: appareilTypeFoyer,
      foyerAutre: appareil.foyerAutre,
      capacite: appareil.capacite,
      unite: appareil.unite,
      hauteurRejets: appareil.hauteurRejets,
      id: appareil.id,
    },
    errors: {},
  };
};

export const createAirCombustionsAppareilDto = (
  appareilsInPage: AppareilInArray[]
): AirCombustionAppareilDto[] => {
  const appareils = appareilsInPage.map(singlePopulatedAppareil => {
    const singleAppareil = singlePopulatedAppareil.data;
    const singleAppareilDto: AirCombustionAppareilDto = {
      id: singlePopulatedAppareil.data.id,
      nom: singleAppareil.nom,
      installation: singleAppareil.nameInstallation,
      date: singleAppareil.date
        ? singleAppareil.date.toISOString().split(".")[0] + "Z"
        : null,
      modification: singleAppareil.modification,
      lowestThan1500: singleAppareil.lowestThan1500,
      localisation: singleAppareil.localisation,
      activite: singleAppareil.activite,
      nature: singleAppareil.nature && singleAppareil.nature.object.code,
      natureAutre: singleAppareil.natureAutre,
      typeFoyer:
        singleAppareil.typeFoyer && singleAppareil.typeFoyer.object.code,
      foyerAutre: singleAppareil.foyerAutre,
      capacite: singleAppareil.capacite,
      unite: singleAppareil.unite,
      hauteurRejets: singleAppareil.hauteurRejets,
    };
    return singleAppareilDto;
  });
  return appareils;
};

export const isAppareilSubpartFormAvailable = (
  installationType: OptionPropsWithObject<ObjectInstallations> | null
) => {
  if (!installationType) {
    return false;
  }
  return installationType.object.isLCPAvailable;
};
