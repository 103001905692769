import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Row from "common/presentational/Row";
import PageSelection from "common/presentational/pager/PageSelection";
import SearchField from "common/presentational/search/SearchField";
import FilterField from "./FilterField";
import NumberOfLinesSelector, {
  NUMBER_OF_LINES_OPTIONS,
} from "common/presentational/pager/NumberOfLinesSelector";
import { FiltresDeclarationDtoSortByEnum, SimpleDeclarationDto } from "api/gen";
import Spinner from "common/presentational/Spinner";
import { DeclarationsTable } from "./DeclarationTable";
import AggregationModale from "./AggregationModale";
import RedirectHome from "../../CommonSpace/AppPage/Redirect/RedirectHome";
import InfoBulle from "common/infoBulle/InfoBulle";
import { useUserData } from "../../../Authenticator/UserData";
import { AdminExportButtons } from "./components/AdminExportButtons";
import { AdminDownloadFilesButtons } from "./components/AdminDownloadFilesButtons";
import AggregationFilesModale from "./AggregationFilesModale";
import { VersionedContactInfoModal } from "../ContactInfosModal/VersionedContactInfoModal";
import { useDeclarationsDashboardContext } from "pages/CommonSpace/AppPage/DeclarationsDashboardContext";
import { DeclarationFilters } from "./types";

const useStyles = makeStyles({
  nbLines: {
    fontSize: "14px",
    flexDirection: "row",
    alignItems: "center",
  },
  flexStart: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  topRow: {
    height: "inherit",
    minHeight: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
  },
  searchFields: {
    display: "flex",
    flexDirection: "column",
    flex: "2 0 auto",
  },
  searchField: {
    marginBottom: "4px",
    minWidth: "300px",
  },
});

export const DeclarationsManagementController = (): React.ReactElement => {
  const classes = useStyles();
  const userData = useUserData();
  const {
    declarationsInspecteur,
    linesPerPage,
    currentPage,
    filters,
    setFilters,
    updateAndFetch,
    error,
    declarationsYearsInspecteur,
  } = useDeclarationsDashboardContext({
    linesPerPage: NUMBER_OF_LINES_OPTIONS[0],
  });

  const [isAggregationModaleOpen, setIsAggregationModaleOpen] = useState<
    boolean
  >(false);
  const [
    isAggregationFilesModaleOpen,
    setIsAggregationFilesModaleOpen,
  ] = useState<boolean>(false);
  const [
    declarationSelectedForContact,
    setDeclarationSelectedForContact,
  ] = useState<SimpleDeclarationDto | null>(null);
  const nbTotalDeclarations = declarationsInspecteur?.nbTotalDeclarations ?? 0;
  const nbTotalDeclarationsToCheck =
    declarationsInspecteur?.nbTotalDeclarationsToCheck ?? 0;
  const pageDeclarations = declarationsInspecteur?.pageDeclarations ?? [];

  const handleFilterFieldChange = (filters: DeclarationFilters) => {
    updateAndFetch({ filters, page: 0 });
  };

  const handleSearchByIdOrNameOrSiretChange = (
    searchByIdOrNameOrSiret: string
  ) => {
    setFilters(filter => {
      return {
        ...filter,
        searchByIdOrNameOrSiret,
      };
    });
  };

  const handleSearchByNimChange = (searchByNimNumber: string) => {
    setFilters(filter => {
      return {
        ...filter,
        searchByNimNumber,
      };
    });
  };

  const handleSearchEnter = () => {
    updateAndFetch({ page: 0 });
  };

  const handleSortChange = (
    sortBy: FiltresDeclarationDtoSortByEnum | null,
    ascending: boolean | null
  ) => {
    updateAndFetch({
      filters: {
        ascending,
        sortBy,
      },
    });
  };

  const handleLinesPerPageChange = (linesPerPage: number) => {
    updateAndFetch({ linesPerPage, page: 0 });
  };

  const handlePageChange = (page: number) => {
    updateAndFetch({ page });
  };

  if (error) {
    return <RedirectHome initialRedirection={true} />;
  }

  let nbPagesTotal = 0;
  if (declarationsInspecteur !== null) {
    nbPagesTotal = Math.ceil(nbTotalDeclarations / linesPerPage);
  }

  return (
    <main>
      <Row height="30px" />

      <div className={classes.topRow}>
        <FilterField
          filters={filters}
          setFilters={handleFilterFieldChange}
          departementNumbersList={
            declarationsInspecteur?.departementNumbersList || []
          }
          years={declarationsYearsInspecteur}
        />
        <div className={classes.searchFields}>
          <SearchField
            search={filters.searchByIdOrNameOrSiret || ""}
            placeholder={"Etablissement, AIOT, SIRET ..."}
            setSearch={handleSearchByIdOrNameOrSiretChange}
            onEnterAction={handleSearchEnter}
            additionalClassName={classes.searchField}
          />
          <SearchField
            search={filters.searchByNimNumber || ""}
            placeholder={"NIM (quotas) ..."}
            setSearch={handleSearchByNimChange}
            onEnterAction={handleSearchEnter}
          />
        </div>
      </div>

      {declarationsInspecteur !== null ? (
        <>
          <Row height="24px" additionalClassname={classes.nbLines}>
            <p className={classes.flexStart}>
              <InfoBulle
                content={
                  <>
                    Correspond aux déclarations ayant l'un des statuts suivants
                    :
                    <ul>
                      <li>Section "Type d'activité" à valider</li>
                      <li>À valider</li>
                      <li>En attente de mise en révision</li>
                      <li>Déclaration d'office</li>
                    </ul>
                  </>
                }
                placement="topRight"
              />
              {`${nbTotalDeclarationsToCheck} déclaration${
                nbTotalDeclarationsToCheck > 1 ? "s" : ""
              } pour action`}
            </p>
            <p>{`${nbTotalDeclarations} Ligne${
              nbTotalDeclarations > 1 ? "s" : ""
            }`}</p>
          </Row>

          <DeclarationsTable
            declarations={pageDeclarations}
            sorter={filters.sortBy || FiltresDeclarationDtoSortByEnum.ID}
            isSortReversed={!!filters.ascending}
            updateSortFilter={handleSortChange}
          />

          <Row height="24px" additionalClassname={classes.nbLines}>
            <NumberOfLinesSelector
              title={"établissements par page"}
              selected={linesPerPage}
              onChange={handleLinesPerPageChange}
            />
          </Row>

          {nbTotalDeclarations > linesPerPage && (
            <PageSelection
              currentPage={currentPage}
              setPage={handlePageChange}
              nbPagesTotal={nbPagesTotal}
              nbPagesToDisplay={9}
            />
          )}

          {userData.isInspecteur && (
            <>
              <AdminExportButtons
                annees={filters.annees}
                isDisabled={pageDeclarations.length === 0}
                setIsAggregationModaleOpen={setIsAggregationModaleOpen}
              />
              <AggregationModale
                isOpen={isAggregationModaleOpen}
                onRequestClose={() => setIsAggregationModaleOpen(false)}
                filter={filters}
                setFilter={setFilters}
              />
              <AdminDownloadFilesButtons
                isDisabled={pageDeclarations.length === 0}
                setIsAggregationModaleOpen={setIsAggregationFilesModaleOpen}
              />
              <AggregationFilesModale
                isOpen={isAggregationFilesModaleOpen}
                onRequestClose={() => setIsAggregationFilesModaleOpen(false)}
                filter={filters}
                setFilter={setFilters}
              />
            </>
          )}
        </>
      ) : (
        <>
          <Row />
          <Spinner />
        </>
      )}

      <VersionedContactInfoModal
        isOpen={declarationSelectedForContact !== null}
        year={
          declarationSelectedForContact && declarationSelectedForContact.annee
        }
        company={
          declarationSelectedForContact &&
          declarationSelectedForContact.etablissement
        }
        onRequestClose={() => setDeclarationSelectedForContact(null)}
      />
    </main>
  );
};
