import {
  commonNullableStringFields,
  commonObjectFields,
  commonObjectFieldsNullable,
  commonPositiveNumberFields,
  commonStringFields,
  commonStringFieldsNoPipeAllowed,
  subStringFieldValidationMultipleScheme,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { ObjectNature, ObjectTypeFoyer } from "../utils/selectPossibleValues";
import * as Yup from "yup";
import { parseToUndefinedIfNull } from "common/form/utils";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  appareilsUniqueMessage,
  futureDateMessage,
  hauteurRejetMessage,
} from "common/declarant/formik/formikMessages";
import { AppareilInModale } from "../utils/types";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";

export const autreSubPartActivated = (
  type: OptionPropsWithObject<ObjectNature | ObjectTypeFoyer> | null | undefined
): boolean => !!type?.object.isOther;

export const chaudiereSelected = (
  type: OptionPropsWithObject<ObjectNature> | null | undefined
): boolean => !!type?.object.isChaudiere;

export const singleAppareilOverwriteDependantFields = (
  appareil: AppareilInModale
): void => {
  if (!autreSubPartActivated(appareil.nature)) {
    appareil.natureAutre = null;
  }
  if (
    chaudiereSelected(appareil.nature) &&
    !autreSubPartActivated(appareil.typeFoyer)
  ) {
    appareil.foyerAutre = null;
  }
  if (!chaudiereSelected(appareil.nature)) {
    appareil.foyerAutre = null;
  }
};

export const singleAppareilValidationSchema = Yup.object().shape({
  nom: commonStringFieldsNoPipeAllowed,
  nameInstallation: commonNullableStringFields,
  date: commonStringFields,
  modification: commonNullableStringFields,
  lowestThan1500: commonNullableStringFields,
  localisation: commonNullableStringFields,
  activite: Yup.string()
    .trim()
    .transform(parseToUndefinedIfNull),
  nature: commonObjectFields,
  natureAutre: subStringFieldValidationScheme("nature", autreSubPartActivated),
  typeFoyer: commonObjectFieldsNullable,
  foyerAutre: subStringFieldValidationMultipleScheme(
    ["typeFoyer", "nature"],
    autreSubPartActivated,
    chaudiereSelected
  ),
  capacite: commonPositiveNumberFields,
  unite: commonStringFields,
  hauteurRejets: commonPositiveNumberFields.max(300, hauteurRejetMessage),
});

export const validate = (
  values: AppareilInModale,
  forbiddenAppareilName: string[]
): FormikErrors<AppareilInModale> => {
  const errors: FormikErrors<AppareilInModale> = {};
  if (forbiddenAppareilName.some(appareil => appareil === values.nom)) {
    errors.nom = appareilsUniqueMessage;
  }
  if (values.date && values.date > new Date()) {
    errors.date = futureDateMessage;
  }
  return errors;
};
