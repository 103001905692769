import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { InstallationInArray, InstallationInModale } from "../types";
import FormSingleInstallation from "./FormSingleInstallation";
import uuid from "uuid";
import { ReferenceItemActiviteQuotaDto } from "api/gen";

interface InstallationModaleProps {
  isOpen: boolean;
  installationInModal: InstallationInArray | null;
  installationsInArray: InstallationInArray[];
  setInstallationsInArray: (newInstallations: InstallationInArray[]) => void;
  closeModal: () => void;
  possibleActivitesValues: ReferenceItemActiviteQuotaDto[];
}

const addInstallationInArray = (
  elderInstallations: InstallationInArray[],
  newInstallation: InstallationInModale
): InstallationInArray[] => {
  return elderInstallations.concat({
    data: { ...newInstallation, id: uuid() },
    errors: {},
  });
};

const updateInstallationInArray = (
  elderInstallations: InstallationInArray[],
  newInstallation: InstallationInModale,
  id: string
): InstallationInArray[] => {
  return elderInstallations.map(singleElderInstallation => {
    if (singleElderInstallation.data.id === id) {
      singleElderInstallation.data = { ...newInstallation, id: id };
    }
    return singleElderInstallation;
  });
};

const InstallationModale = ({
  isOpen,
  installationInModal,
  installationsInArray,
  setInstallationsInArray,
  closeModal,
  possibleActivitesValues,
}: InstallationModaleProps): React.ReactElement => {
  const onSubmit = (newInstallation: InstallationInModale) => {
    setInstallationsInArray(
      installationInModal === null
        ? addInstallationInArray(installationsInArray, newInstallation)
        : updateInstallationInArray(
            installationsInArray,
            newInstallation,
            installationInModal.data.id
          )
    );
    closeModal();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'installations."
      onRequestClose={closeModal}
    >
      <FormSingleInstallation
        onClose={closeModal}
        onSubmit={onSubmit}
        initialInstallation={
          installationInModal !== null
            ? installationInModal.data
            : {
                nim: null,
                nomInstallation: null,
                entreeSEQE: null,
                sortieSEQE: null,
                codesActivite: [],
                isNotAllocationGratuit: false,
                isNouvelEntrant: false,
              }
        }
        isEdit={installationInModal !== null}
        installationsInArray={installationsInArray}
        possibleActivitesValues={possibleActivitesValues}
      />
    </CustomModal>
  );
};

export default InstallationModale;
