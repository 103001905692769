import React, { ReactElement, useMemo } from "react";
import Button from "common/button";
import {
  useBasicDeclarationHandlers21Now,
  useDeclaration21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../../toNow/versionedElements/declarationHooks21Now";
import SeparateSubmissionWarning from "common/messages/SeparateSubmissionWarning";
import { useConfirmationModale } from "common/modale/hooks";
import { areAllQuotaTargetBlocsValidated } from "../../Quotas/utils/validation";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";

const QuotaValidateButton = ({
  workflowTarget,
  path,
}: {
  workflowTarget: QuotaWorkflowTarget;
  path: string;
}): ReactElement => {
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const { validate, cancelValidate } = useBasicDeclarationHandlers21Now();
  const {
    submitSimplifiedQuota,
    yieldQuotaAllocationsVerif,
    yieldQuotaEmissionsVerif,
  } = useDeclarationStateHandlers21Now();
  const openConfirmationModale = useConfirmationModale();

  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  const declaration = useDeclaration21Now();
  const isButtonEnabled = useMemo(
    () =>
      areAllQuotaTargetBlocsValidated(
        workflowTarget,
        declaration,
        isPathValidatedInDeclaration
      ),
    [workflowTarget, declaration, isPathValidatedInDeclaration]
  );

  const {
    excluArticle27,
    excluArticle27Bis,
  } = declaration.body.typeActivite.quotas;
  const isSimplifiedQuota = excluArticle27 || excluArticle27Bis;

  const yieldQuotaVerif =
    workflowTarget === WorkflowTarget.QUOTA_ALLOCATIONS
      ? yieldQuotaAllocationsVerif
      : yieldQuotaEmissionsVerif;

  return (
    <Button
      text={
        isSimplifiedQuota
          ? "TRANSMETTRE À L'INSPECTION"
          : "TRANSMETTRE AU VÉRIFICATEUR"
      }
      onClick={() => {
        openConfirmationModale(<SeparateSubmissionWarning />, async () => {
          // if the state is in review, it means it could be already validated
          if (isPathValidatedInDeclaration(path)) {
            await cancelValidate(path);
          }

          // validate whole page QUOTAS (without changing dto) before submitting verification
          const validateCall = async () => {
            if (!(await validate(declarationDto => declarationDto, path))) {
              return false;
            }
            const result = isSimplifiedQuota
              ? await submitSimplifiedQuota(workflowTarget)
              : await yieldQuotaVerif();
            return result;
          };
          return triggerSpinner(validateCall());
        });
      }}
      isSpinnerVisible={isSpinnerVisible}
      isDisabled={!isButtonEnabled}
    />
  );
};

export default QuotaValidateButton;
