import {
  unitCorrelationLabels,
  unitCorrelationList,
} from "./selectPossibleValues";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import {
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesProcedesDtoUniteEnum,
} from "api/gen";
import { unitLabels } from "../../ListProcede/utils/selectPossibleValues";

export const getUnitCorrelationList = (
  procede: ProcedeInArray | null
): AirFugitivesEmissionCorrelationDtoUniteEnum[] => {
  //Retournes les bonnes options en fonction de l'unite du procédé choisi
  const unitProcede = procede && procede.data.unite;
  if (unitProcede) {
    if (unitProcede === AirFugitivesProcedesDtoUniteEnum.AUTRE) {
      return [AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE];
    } else {
      return unitCorrelationList;
    }
  }

  return [];
};

export const computeUniteProcedeLabel = (
  procede: ProcedeInArray | null
): string | null => {
  if (procede && procede.data.unite) {
    return procede.data.unite !== AirFugitivesProcedesDtoUniteEnum.AUTRE
      ? unitLabels[procede.data.unite]
      : procede.data.precision;
  }
  return "";
};

export const computeUnitCorrelationLabel = (
  unit: AirFugitivesEmissionCorrelationDtoUniteEnum | null,
  correlationProcede: ProcedeInArray | null
): string | null => {
  if (unit === AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE) {
    return correlationProcede ? `kg/${correlationProcede.data.precision}` : "";
  }
  return unit ? unitCorrelationLabels[unit] : null;
};
