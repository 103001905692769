import React from "react";
import { Co2N2oTransferDto } from "api/gen";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
  arrayContainer: {
    width: "65%",
  },
});

interface ExportedCo2N2oArrayProps {
  exportedCo2N2oList: Co2N2oTransferDto[];
}

const ExportedCo2N2oArray = ({
  exportedCo2N2oList,
}: ExportedCo2N2oArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleNimLine = (
    exportedCo2N2o: Co2N2oTransferDto,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(exportedCo2N2o.numeroNim, classes),
      computeWrappedCell(exportedCo2N2o.installationName, classes),
      computeWrappedCell(exportedCo2N2o.exploitantName, classes),
    ];
  };

  const nimLines = exportedCo2N2oList.map(exportedCo2N2o =>
    computeSingleNimLine(exportedCo2N2o, classes)
  );
  return (
    <div className={classes.container}>
      <ArrayTitle>
        Le CO2 ou N2O inhérent ou transféré est exporté vers
      </ArrayTitle>

      <CommonEntityTable
        header={[
          <p>ID de l'installation (numéro NIM)</p>,
          <p>Dénomination de l'installation</p>,
          <p>Nom de l'exploitant</p>,
        ]}
        lines={nimLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[1, 1, 1]}
        additionalClassName={classes.arrayContainer}
      />
    </div>
  );
};

export default ExportedCo2N2oArray;
