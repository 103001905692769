//formik common utility
import { FormikTouched } from "libAdapter/Formik/TypesPatternAdaptater";
import _ from "lodash";

// TODO opensource : make PR formik to type this properly as this SHOULD be
// FormikTouched<Touched> and FormikErrors<Errors> (but it doesn't match with the test scenario in the test file.)
// and that test scenario is actually nearer to what formik allows
interface CommonShouldDisableSubmitButtonParamObject {
  touched: any;
  errors: any;
}

// TODO opensource : make PR formik to type this properly as this SHOULD be
// FormikTouched<Touched> (but it doesn't match with the test scenario in the test file.)
// and that test scenario is actually nearer to what formik allows
function wasTouched(touchedObject: any): boolean {
  if (Array.isArray(touchedObject)) {
    return touchedObject.some(singleElem => wasTouched(singleElem));
  }

  if (typeof touchedObject === "object") {
    // the as boolean is correct, as FormikTouched is an object where each key has either another FormikTouched OR a boolean
    // flatmapping the object means there are only booleans as a consequence
    return _.flatMap(touchedObject).some(elem => wasTouched(elem as boolean));
  }

  return touchedObject;
}

export function commonShouldDisableSubmitButton({
  touched,
  errors,
}: CommonShouldDisableSubmitButtonParamObject): boolean {
  for (const [key, singleTouched] of Object.entries(touched)) {
    //we set errors as any because we want to check if key exist in second object
    const currentErrors = errors[key];

    // the as is correct, as FormikTouched is an object where each key has either another FormikTouched OR a boolean,
    // and singleTouched is an entry of a formikTouched object
    if (
      typeof currentErrors === "string" &&
      wasTouched(singleTouched as FormikTouched<unknown> | boolean)
    ) {
      return true;
    }

    if (
      typeof singleTouched === "object" &&
      typeof currentErrors === "object"
    ) {
      if (
        commonShouldDisableSubmitButton({
          // the as is correct, as FormikTouched is an object where each key has either another FormikTouched OR a boolean,
          // and singleTouched is an entry of a formikTouched object Here, we just ensure singleTouched was an object, leaving ONLY FormikTouched
          touched: singleTouched as FormikTouched<unknown>,
          errors: currentErrors,
        })
      ) {
        return true;
      }
    }
  }

  return false;
}
