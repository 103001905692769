import { makeStyles } from "@material-ui/styles";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import React, { ReactChild } from "react";
import dangerIcon from "icons/danger.svg";

const useStyles = makeStyles({
  titleContainer: {
    display: "flex",
    margin: "0px 0px 25px 0px",
  },
  title: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "unset",
  },
  content: {
    fontSize: "16px",
  },
  picture: {
    minWidth: "60px",
    width: "60px",
    marginRight: "5%",
  },
});

interface ConfirmationModaleProps {
  onConfirmation: () => void;
  onCancel: () => void;
  message: ReactChild | string;
  messageDetails?: ReactChild | string;
}

export const ConfirmationModaleContent = ({
  onConfirmation,
  onCancel,
  message,
  messageDetails,
}: ConfirmationModaleProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.titleContainer}>
        <img src={dangerIcon} alt={"Attention"} className={classes.picture} />
        <div className={classes.title}>{message}</div>
      </div>
      <div className={classes.content}>{messageDetails}</div>

      <DummyGlobalFormActions
        validateButton={{
          title: "Confirmer",
          isVisible: true,
          isEnabled: true,
          onClick: onConfirmation,
          isSpinnerVisible: false,
        }}
        cancelButton={{
          title: "Annuler",
          isVisible: true,
          isEnabled: true,
          onClick: onCancel,
        }}
      />
    </>
  );
};
