import React, { ReactElement } from "react";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { useUserData } from "Authenticator/UserData";
import { PATH_INFO_GENERALE, PATH_ROOT } from "common/path/path18Now";
import { roundPathProgression } from "pages/CompanySpace/from19/to19/Dashboard/DeclarationSummary/utils";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  globalTip: {
    marginBottom: "25px",
    textAlign: "center",
  },
});

const GlobalNextActionInfo = ({
  declarationApi,
}: {
  declarationApi: DeclarationHelpers;
}): ReactElement => {
  const classes = useStyles();
  const { isDeclarant } = useUserData();
  const isInfoGeneralesValidated = declarationApi.isPathValidatedInDeclaration(
    PATH_INFO_GENERALE
  );
  if (!isDeclarant || !isInfoGeneralesValidated) {
    return <></>;
  }

  const globalPercentageProgress = roundPathProgression(
    declarationApi.getPathProgress(PATH_ROOT)
  );
  if (globalPercentageProgress !== 100) {
    return (
      <p className={classes.globalTip}>
        Veuillez valider toutes les pages actives.
      </p>
    );
  }

  const isGlobalValidated = declarationApi.isPathValidatedInDeclaration(
    PATH_ROOT
  );
  if (!isGlobalValidated) {
    return (
      <p className={classes.globalTip}>
        Veuillez transmettre la déclaration à l'inspection.
      </p>
    );
  }

  return <></>;
};

export default GlobalNextActionInfo;
