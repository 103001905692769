import {
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
} from "common/path/path18Now";
import { getDisabledMessageForTypeActiviteConditionnalSection } from "../utils/disabledMessageUtils";
import {
  GlobalDashboardCardsProps,
  SubCard,
} from "../../../../to23/Dashboard/Components/Cards/types";
import growthImg from "icons/dashboardLogos/growth.svg";
import deliveryTruckImg from "icons/dashboardLogos/delivery-truck.svg";
import insuranceImg from "icons/dashboardLogos/insurance.svg";

function buildEnvironnementSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Environnement",
    picture: { src: growthImg, alt: "" },
    percentageProgress: getPathProgress(PATH_CARRIERE_ENVIRONNEMENT),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`,
    isDisabled: !getIsActiveSection(PATH_CARRIERE_ENVIRONNEMENT),
    isValidated: isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildProductionSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Production",
    picture: { src: deliveryTruckImg, alt: "" },
    percentageProgress: getPathProgress(PATH_CARRIERE_PRODUCTION),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_PRODUCTION}`,
    isDisabled: !getIsActiveSection(PATH_CARRIERE_PRODUCTION),
    isValidated: isPathValidatedInDeclaration(PATH_CARRIERE_PRODUCTION),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildSanteSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Santé / Sécurité",
    picture: { src: insuranceImg, alt: "" },
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_SANTE}`,
    percentageProgress: getPathProgress(PATH_CARRIERE_SANTE),
    isDisabled: !getIsActiveSection(PATH_CARRIERE_SANTE),
    isValidated: isPathValidatedInDeclaration(PATH_CARRIERE_SANTE),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

export function buildCarriereSubCards(
  subCardsProps: GlobalDashboardCardsProps
): SubCard[] {
  return [
    buildEnvironnementSubCard(subCardsProps),
    buildProductionSubCard(subCardsProps),
    buildSanteSubCard(subCardsProps),
  ];
}
