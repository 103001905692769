import React, { CSSProperties, ReactChild, ReactNode } from "react";
import { CommonProps, ErrorDataProps } from "../../utils";
import FieldWrapperWithTextLabel from "../wrappers/FieldWrapperWithTextLabel";
import TextInput from "../connectedInput/TextInput";
import NumberInput from "../connectedInput/NumberInput";
import ChoiceSelect from "../connectedInput/ChoiceSelect";
import classNames from "classnames";
import TextArea from "../connectedInput/TextArea";
import CheckBox from "../connectedInput/CheckBox";
import DummyTextInput from "../dumbInput/DummyTextInput";
import SimpleInputWithTextLabelDisplayer from "../displayers/SimpleInputWithTextLabelDisplayer";
import { ErrorProps } from "common/utils/types";
import { computeErrorData, useErrorStyle } from "./utils";
import { FieldProps } from "libAdapter/Formik/TypesPatternAdaptater";
import DummyTextArea from "../dumbInput/DummyTextArea";
import DummyNumberInput from "../dumbInput/DummyNumberInput";
import ChoiceInput from "../connectedInput/ChoiceInput";
import DummyChoiceInput from "../dumbInput/DummyChoiceInput";
import DateInput from "../connectedInput/DateInput";
import DummyDateInput from "../dumbInput/DummyDateInput";
import { Omit } from "react-router";
import { OptionProps } from "../types/basicTypes";
import {
  UnWrappedChoiceInputProps,
  UnWrappedChoiceSelectProps,
} from "../types/dummyTypes";
import LocalDateInput from "../connectedInput/LocalDateInput";
import DummyCheckBox from "../dumbInput/DummyCheckBox";
import DummyChoiceSelect from "../dumbInput/DummyChoiceSelect";
import { ValueType } from "libAdapter/ReactSelect/TypesPatternAdapter";

interface BasicFieldType {
  label: string | ((classname: string) => ReactChild);
  name: string;
  additionalClassName?: string;
  style?: CSSProperties;
  disabled?: boolean;
  hideLabelComponentIfEmptyText?: boolean;
  tooltipContent?: ReactNode;
  commentPath?: string;
}

function connectedFieldHookGenerator<T extends { externalValue?: unknown }>(
  renderMethod: (
    id: string,
    disabled: boolean,
    fieldProps: FieldProps,
    additionalRenderProps: Pick<T, Exclude<keyof T, keyof BasicFieldType>>, // as inspired by : https://stackoverflow.com/a/49708585/7059810 , since we'll get whatever else we need outside of additionalRenderPros.
    additionalClassName?: string,
    style?: CSSProperties,
    tooltipContent?: ReactNode,
    additionalClassNameInput?: string
  ) => React.ReactElement
) {
  return function useConnectedField(
    commonProps: CommonProps,
    basicAdditionalClassName?: string
  ) {
    return React.useMemo(() => {
      return ({
        label,
        name,
        additionalClassName,
        style,
        disabled,
        hideLabelComponentIfEmptyText,
        tooltipContent,
        commentPath,
        ...additionalRenderProps
      }: BasicFieldType & T) => {
        const disabledStatus =
          additionalRenderProps.externalValue !== undefined ||
          (disabled ?? commonProps.disabled);

        const classname = classNames(
          basicAdditionalClassName,
          additionalClassName
        );
        return (
          <FieldWrapperWithTextLabel
            name={name}
            label={label}
            disabled={disabledStatus}
            render={(fieldProps, id) =>
              renderMethod(
                id,
                disabledStatus,
                fieldProps,
                additionalRenderProps,
                classname,
                style
              )
            }
            commonProps={{
              disabled: commonProps.disabled,
              className: commonProps.className,
              formPrefix: commonProps.formPrefix,
              labelWidth: commonProps.labelWidth,
              commentPath: commentPath,
            }}
            hideLabelComponentIfEmptyText={hideLabelComponentIfEmptyText}
            tooltipContent={tooltipContent}
          />
        );
      };
    }, [
      commonProps.disabled,
      commonProps.className,
      commonProps.formPrefix,
      commonProps.labelWidth,
      basicAdditionalClassName,
    ]);
  };
}

interface BasicOnChange<Value> {
  additionalOnChange?: (newValue: Value) => void;
}

export interface TextFieldType extends BasicOnChange<string | null> {
  placeholder?: string;
  externalValue?: string | null;
}

export const useTextFieldGenerator = connectedFieldHookGenerator<TextFieldType>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <TextInput
      field={field}
      form={form}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      {...additionalRenderProps}
    />
  )
);

export const useTextAreaGenerator = connectedFieldHookGenerator<TextFieldType>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <TextArea
      field={field}
      form={form}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={Object.assign({ resize: "none" }, style)}
      rows={3}
      {...additionalRenderProps}
    />
  )
);

interface NumberFieldType extends BasicOnChange<number | null> {
  unit: string | ((classname: string) => ReactChild);
  placeholder?: number;
  hideValue?: boolean;
  additionalClassNameInput?: string;
  externalValue?: number | null;
}

export const useNumberFieldGenerator = connectedFieldHookGenerator<
  NumberFieldType
>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName,
    style,
    _,
    additionalClassNameInput
  ) => (
    <NumberInput
      field={field}
      form={form}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      additionalClassNameInput={additionalClassNameInput}
      style={style}
      {...additionalRenderProps}
    />
  )
);

interface ChoiceSelectFieldType<T extends OptionProps, IsMulti extends boolean>
  extends UnWrappedChoiceSelectProps<T, IsMulti> {
  additionalOnChange?: (value: ValueType<T, IsMulti>) => void;
  externalValue?: ValueType<T, IsMulti>;
}

export function useChoiceSelectFieldGenerator<
  T extends OptionProps,
  IsMulti extends boolean
>(
  commonProps: CommonProps,
  basicAdditionalClassName?: string | undefined,
  enableReset?: boolean
): (
  props: BasicFieldType & ChoiceSelectFieldType<T, IsMulti>
) => React.ReactElement {
  return connectedFieldHookGenerator<ChoiceSelectFieldType<T, IsMulti>>(
    (
      id,
      disabled,
      { field, form },
      additionalRenderProps,
      additionalClassName,
      style
    ) => (
      <ChoiceSelect
        field={field}
        form={form}
        id={id}
        disabled={disabled}
        additionalClassName={additionalClassName}
        style={style}
        enableReset={enableReset}
        {...additionalRenderProps}
      />
    )
  )(commonProps, basicAdditionalClassName);
}

interface ChoiceRadioFieldType extends UnWrappedChoiceInputProps {
  additionalOnChange?: (newValue: string | null) => void;
  externalValue?: string | null;
}

// TODO [GEREP-2885] deprecate the generator in favor of WrappedChoiceRadioField
export const useChoiceRadioFieldGenerator = connectedFieldHookGenerator<
  ChoiceRadioFieldType
>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <ChoiceInput
      field={field}
      form={form}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      {...additionalRenderProps}
    />
  )
);

//IMPORTANT NOTE : DateField will do NOTHING with style props
interface DateFieldType extends Omit<BasicFieldType, "style"> {
  additionalOnChange?: (date: Date | null) => void;
  externalValue?: string | null;
}
export const useDateFieldGenerator = connectedFieldHookGenerator<DateFieldType>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName
  ) => {
    return (
      <DateInput
        id={id}
        field={field}
        form={form}
        disabled={disabled}
        additionalClassName={additionalClassName}
        additionalOnChange={additionalRenderProps.additionalOnChange}
        externalValue={additionalRenderProps.externalValue}
      />
    );
  }
);

//IMPORTANT NOTE : LocalDateField will do NOTHING with style props
interface LocalDateFieldType extends Omit<BasicFieldType, "style"> {
  additionalOnChange?: (localDate: string | null) => void;
  externalValue?: string | null;
}
export const useLocalDateFieldGenerator = connectedFieldHookGenerator<
  LocalDateFieldType
>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName
  ) => {
    return (
      <LocalDateInput
        id={id}
        field={field}
        form={form}
        disabled={disabled}
        additionalClassName={additionalClassName}
        additionalOnChange={additionalRenderProps.additionalOnChange}
        externalValue={additionalRenderProps.externalValue}
      />
    );
  }
);

interface BooleanCheckboxType extends BasicOnChange<boolean> {
  externalValue?: boolean;
}

export const useBooleanCheckBoxGenerator = connectedFieldHookGenerator<
  BooleanCheckboxType
>(
  (
    id,
    disabled,
    { field, form },
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <CheckBox
      field={field}
      form={form}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      {...additionalRenderProps}
    />
  )
);

// And here go the SAME generators but with SimpleInput instead of FieldWrapper.

interface BasicDummyInputType extends BasicFieldType {
  value: any;
  error?: ErrorProps;
}

function dummyFieldHookGenerator<T>(
  renderMethod: (
    id: string,
    name: string,
    disabled: boolean,
    error: ErrorProps | null,
    value: any,
    additionalRenderProps: Pick<T, Exclude<keyof T, keyof BasicDummyInputType>>, // as inspired by : https://stackoverflow.com/a/49708585/7059810 , since we'll get whatever else we need outside of additionalRenderPros.
    additionalClassName?: string,
    style?: CSSProperties,
    tooltipContent?: ReactNode
  ) => React.ReactElement
) {
  return (commonProps: CommonProps, basicAdditionalClassName?: string) => {
    return React.useMemo(() => {
      return ({
        label,
        name,
        value,
        additionalClassName,
        style,
        disabled,
        error,
        hideLabelComponentIfEmptyText,
        tooltipContent,
        commentPath,
        ...additionalRenderProps
      }: BasicDummyInputType & T) => {
        const disabledStatus =
          disabled !== undefined ? disabled : commonProps.disabled;
        const classes = useErrorStyle();
        const errorData: ErrorDataProps = computeErrorData(classes, error);
        const classname = classNames(
          basicAdditionalClassName,
          additionalClassName
        );
        return (
          <SimpleInputWithTextLabelDisplayer
            name={name}
            label={label}
            disabled={disabledStatus}
            errorData={errorData}
            renderInput={id =>
              renderMethod(
                id,
                name,
                disabledStatus,
                error || null,
                value,
                additionalRenderProps,
                classname,
                style
              )
            }
            commonProps={{
              disabled: commonProps.disabled,
              className: commonProps.className,
              formPrefix: commonProps.formPrefix,
              labelWidth: commonProps.labelWidth,
              commentPath: commentPath,
            }}
            hideLabelComponentIfEmptyText={hideLabelComponentIfEmptyText}
            tooltipContent={tooltipContent}
          />
        );
      };
    }, [
      commonProps.disabled,
      commonProps.formPrefix,
      commonProps.labelWidth,
      commonProps.className,
      basicAdditionalClassName,
    ]);
  };
}

export const useDummyTextFieldGenerator = dummyFieldHookGenerator<
  TextFieldType
>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <DummyTextInput
      readOnly
      value={value}
      name={name}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      error={error}
      {...additionalRenderProps}
    />
  )
);

export const useDummyTextAreaGenerator = dummyFieldHookGenerator<TextFieldType>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <DummyTextArea
      readOnly
      value={value}
      name={name}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={Object.assign({ resize: "none" }, style)}
      rows={3}
      error={error}
      {...additionalRenderProps}
    />
  )
);

export const useDummyNumberFieldGenerator = dummyFieldHookGenerator<
  NumberFieldType
>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <DummyNumberInput
      readOnly
      value={value}
      name={name}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      error={error}
      {...additionalRenderProps}
    />
  )
);

export function useDummyChoiceSelectFieldGenerator<
  T extends OptionProps,
  IsMulti extends boolean
>(
  commonProps: CommonProps,
  basicAdditionalClassName?: string | undefined
): ({
  label,
  name,
  value,
  additionalClassName,
  style,
  disabled,
  error,
  tooltipContent,
  commentPath,
  ...additionalRenderProps
}: BasicDummyInputType &
  ChoiceSelectFieldType<T, IsMulti>) => React.ReactElement {
  return dummyFieldHookGenerator<ChoiceSelectFieldType<T, IsMulti>>(
    (
      id,
      name,
      disabled,
      error,
      value,
      additionalRenderProps,
      additionalClassName,
      style
    ) => (
      <DummyChoiceSelect
        readOnly
        value={value}
        name={name}
        id={id}
        disabled={disabled}
        additionalClassName={additionalClassName}
        style={style}
        error={error}
        onChange={additionalRenderProps.additionalOnChange}
        {...additionalRenderProps}
      />
    )
  )(commonProps, basicAdditionalClassName);
}

export const useDummyRadioChoiceFieldGenerator = dummyFieldHookGenerator<
  ChoiceRadioFieldType
>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <DummyChoiceInput
      readOnly
      value={value}
      name={name}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      error={error}
      {...additionalRenderProps}
    />
  )
);

interface DummyDateFieldType extends Omit<BasicDummyInputType, "style"> {
  onChange?: (date: Date | Date[] | null) => void;
  value: Date;
}

//IMPORTANT NOTE : DateField will do NOTHING with style props
export const useDummyDateFieldGenerator = dummyFieldHookGenerator<
  DummyDateFieldType
>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName
  ) => (
    <DummyDateInput
      id={id}
      value={value}
      name={name}
      disabled={disabled}
      additionalClassName={additionalClassName}
      error={error}
      {...additionalRenderProps}
    />
  )
);

interface DummyCheckBoxOnChange {
  onChange?: (newValue: boolean) => void;
}

export const useDummyBooleanCheckboxFieldGenerator = dummyFieldHookGenerator<
  DummyCheckBoxOnChange
>(
  (
    id,
    name,
    disabled,
    error,
    value,
    additionalRenderProps,
    additionalClassName,
    style
  ) => (
    <DummyCheckBox
      readOnly
      value={value}
      name={name}
      id={id}
      disabled={disabled}
      additionalClassName={additionalClassName}
      style={style}
      error={error}
      onChange={additionalRenderProps.onChange}
      {...additionalRenderProps}
    />
  )
);
