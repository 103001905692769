import React, { useState } from "react";
import BlocInfosGenerales from "./BlocInfosGenerales";
import BlocSuperficies from "./BlocSuperficies";
import BlocSuperficiesAutresRenseignements from "./BlocSuperficiesAutresRenseignements";
import BlocDechetsInertes from "./BlocDechetsInertes";
import BlocRetombeesPoussieres from "./BlocRetombeesPoussiere";
import BlocRejetPoussieres from "./BlocRejetPoussieres";
import BlocMesuresBruit from "./BlocMesuresBruit";
import BlocMesuresVibrations from "./BlocMesuresVibrations";
import logo from "icons/pageLogos/growth.svg";
import { RouteIdProps } from "common/declarant/type";
import { Nullable } from "common/utils/types";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { GeneralInformationsForm } from "./BlocInfosGenerales/utils/types";
import { AreasForm } from "./BlocSuperficies/utils/types";
import { AreasOtherInfosForm } from "./BlocSuperficiesAutresRenseignements/utils/types";
import {
  DustFalloutForm,
  DustFalloutPointInArray,
} from "./BlocRetombeesPoussiere/utils/types";
import { InertWasteForm } from "./BlocDechetsInertes/utils/types";
import { convertDustFalloutPointToDisplayed } from "./BlocRetombeesPoussiere/utils/converters";
import {
  DustRejectionForm,
  DustRejectionPointInArray,
} from "./BlocRejetPoussieres/utils/types";
import { convertDustRejectionPointToDisplayed } from "./BlocRejetPoussieres/utils/converters";
import { NoiseMeasuresForm } from "./BlocMesuresBruit/utils/types";
import { VibrationMeasuresForm } from "./BlocMesuresVibrations/utils/types";
import {
  convertDeclarationDtoUsingAreasForm,
  convertDeclarationDtoUsingAreasOtherInfosForm,
  convertDeclarationDtoUsingDustFalloutForm,
  convertDeclarationDtoUsingDustRejectionForm,
  convertDeclarationDtoUsingGeneralInformationsForm,
  convertDeclarationDtoUsingInertWasteForm,
  convertDeclarationDtoUsingNoiseMeasuresForm,
  convertDeclarationDtoUsingVibrationMeasuresForm,
} from "./utils/converters";
import Row from "common/presentational/Row";
import {
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT,
  PATH_CARRIERE_ENVIRONNEMENT_CANALISEE,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES,
  PATH_CARRIERE_ENVIRONNEMENT_GENERAL,
  PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE,
  PATH_CARRIERE_ENVIRONNEMENT_VIBRATION,
} from "common/path/path18Now";
import {
  Declaration20Now,
  useDeclaration20Now,
  useDeclarationHelpers20Now,
} from "../../versionedElements/declarationHooks20Now";
import GlobalFormActionFullContext20Now from "../../versionedElements/GlobalFormActionFullContext20Now";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Environnement = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Environnement",
      to: match.url,
    },
  ];
  const imageData = {
    src: logo,
    alt: "logo de environnement, avec une main et une plante",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Environnement",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  const declaration = useDeclaration20Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers20Now();

  const initialDustFalloutPoints = convertDustFalloutPointToDisplayed(
    declaration.body.sections.carriere.environnement.poussiereAtmospherique
      .mesuresDesRetombee
  );
  const [dustFalloutPointsInArray, setDustFalloutPointsInArray] = useState<
    DustFalloutPointInArray[]
  >(initialDustFalloutPoints);

  const initialDustRejectionPoints = convertDustRejectionPointToDisplayed(
    declaration.body.sections.carriere.environnement.poussiereCanalisee
      .mesuresDesRejets || []
  );
  const [dustRejectionPointsInArray, setDustRejectionPointsInArray] = useState<
    DustRejectionPointInArray[]
  >(initialDustRejectionPoints);

  const generalInfosFormProps = useFormikBloc<GeneralInformationsForm>();
  const areasFormProps = useFormikBloc<AreasForm>();
  const areasOtherInfosProps = useFormikBloc<Nullable<AreasOtherInfosForm>>();
  const inertWasteFormProps = useFormikBloc<InertWasteForm>();
  const dustFalloutFormProps = useFormikBloc<DustFalloutForm>();
  const dustRejectionFormProps = useFormikBloc<DustRejectionForm>();
  const noiseMeasuresFormProps = useFormikBloc<NoiseMeasuresForm>();
  const vibrationMeasuresFormProps = useFormikBloc<VibrationMeasuresForm>();

  const isGlobalValidateAvailable =
    !isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_GENERAL) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE) &&
    isPathValidatedInDeclaration(
      PATH_CARRIERE_ENVIRONNEMENT_RENSEIGNEMENTSUPERFICIE
    ) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_CANALISEE) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_BRUIT) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_ENVIRONNEMENT_VIBRATION);

  return (
    <>
      <BlocInfosGenerales generalInfosFormProps={generalInfosFormProps} />

      <Row />

      <BlocSuperficies areasFormProps={areasFormProps} />

      <Row />

      <BlocSuperficiesAutresRenseignements
        areasOtherInfosProps={areasOtherInfosProps}
      />

      <Row />

      <BlocDechetsInertes inertWasteFormProps={inertWasteFormProps} />

      <Row />

      <BlocRetombeesPoussieres
        initialEmissionPoints={initialDustFalloutPoints}
        dustFalloutFormProps={dustFalloutFormProps}
        generalInformationsFormProps={generalInfosFormProps}
        emissionPointsInArray={dustFalloutPointsInArray}
        setEmissionPointsInArray={setDustFalloutPointsInArray}
      />

      <Row />

      <BlocRejetPoussieres
        dustRejectionFormProps={dustRejectionFormProps}
        initialEmissionPoints={initialDustRejectionPoints}
        emissionPointsInArray={dustRejectionPointsInArray}
        setEmissionPointsInArray={setDustRejectionPointsInArray}
      />

      <Row />

      <BlocMesuresBruit noiseMeasuresFormProps={noiseMeasuresFormProps} />

      <Row />

      <BlocMesuresVibrations
        vibrationMeasuresFormProps={vibrationMeasuresFormProps}
      />

      <GlobalFormActionFullContext20Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        validationPath={PATH_CARRIERE_ENVIRONNEMENT}
        hasChanges={
          generalInfosFormProps.hasChanges ||
          areasFormProps.hasChanges ||
          areasOtherInfosProps.hasChanges ||
          inertWasteFormProps.hasChanges ||
          dustFalloutFormProps.hasChanges ||
          dustRejectionFormProps.hasChanges ||
          noiseMeasuresFormProps.hasChanges ||
          vibrationMeasuresFormProps.hasChanges
        }
        isValidateEnabled={isGlobalValidateAvailable}
        cancelAction={() => {
          setDustFalloutPointsInArray(
            convertDustFalloutPointToDisplayed(
              declaration.body.sections.carriere.environnement
                .poussiereAtmospherique.mesuresDesRetombee
            )
          );
          setDustRejectionPointsInArray(
            convertDustRejectionPointToDisplayed(
              declaration.body.sections.carriere.environnement
                .poussiereCanalisee.mesuresDesRejets || []
            )
          );
          if (
            generalInfosFormProps.formikRef &&
            generalInfosFormProps.formikRef.current
          ) {
            generalInfosFormProps.formikRef.current.resetForm();
          }
          if (areasFormProps.formikRef && areasFormProps.formikRef.current) {
            areasFormProps.formikRef.current.resetForm();
          }
          if (
            areasOtherInfosProps.formikRef &&
            areasOtherInfosProps.formikRef.current
          ) {
            areasOtherInfosProps.formikRef.current.resetForm();
          }
          if (
            inertWasteFormProps.formikRef &&
            inertWasteFormProps.formikRef.current
          ) {
            inertWasteFormProps.formikRef.current.resetForm();
          }
          if (
            dustFalloutFormProps.formikRef &&
            dustFalloutFormProps.formikRef.current
          ) {
            dustFalloutFormProps.formikRef.current.resetForm();
          }
          if (
            dustRejectionFormProps.formikRef &&
            dustRejectionFormProps.formikRef.current
          ) {
            dustRejectionFormProps.formikRef.current.resetForm();
          }
          if (
            noiseMeasuresFormProps.formikRef &&
            noiseMeasuresFormProps.formikRef.current
          ) {
            noiseMeasuresFormProps.formikRef.current.resetForm();
          }
          if (
            vibrationMeasuresFormProps.formikRef &&
            vibrationMeasuresFormProps.formikRef.current
          ) {
            vibrationMeasuresFormProps.formikRef.current.resetForm();
          }
        }}
        updateHandler={(declaration: Declaration20Now) => {
          if (
            generalInfosFormProps.formikRef &&
            generalInfosFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingGeneralInformationsForm(
              declaration,
              generalInfosFormProps.formikRef.current.state.values
            );
          }
          if (areasFormProps.formikRef && areasFormProps.formikRef.current) {
            declaration = convertDeclarationDtoUsingAreasForm(
              declaration,
              areasFormProps.formikRef.current.state.values
            );
          }
          if (
            areasOtherInfosProps.formikRef &&
            areasOtherInfosProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingAreasOtherInfosForm(
              declaration,
              areasOtherInfosProps.formikRef.current.state.values
            );
          }
          if (
            inertWasteFormProps.formikRef &&
            inertWasteFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingInertWasteForm(
              declaration,
              inertWasteFormProps.formikRef.current.state.values
            );
          }
          if (
            dustFalloutFormProps.formikRef &&
            dustFalloutFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingDustFalloutForm(
              declaration,
              dustFalloutFormProps.formikRef.current.state.values,
              dustFalloutPointsInArray
            );
          }
          if (
            dustRejectionFormProps.formikRef &&
            dustRejectionFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingDustRejectionForm(
              declaration,
              dustRejectionFormProps.formikRef.current.state.values,
              dustRejectionPointsInArray
            );
          }
          if (
            noiseMeasuresFormProps.formikRef &&
            noiseMeasuresFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingNoiseMeasuresForm(
              declaration,
              noiseMeasuresFormProps.formikRef.current.state.values
            );
          }
          if (
            vibrationMeasuresFormProps.formikRef &&
            vibrationMeasuresFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingVibrationMeasuresForm(
              declaration,
              vibrationMeasuresFormProps.formikRef.current.state.values
            );
          }
          return declaration;
        }}
      />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(Environnement, "GLOBAL");
