import React from "react";
import logoProcede from "icons/pageLogos/chemical.svg";
import MainProcedeForm from "./MainProcedeForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { useElementAutreUuid } from "pages/CommonSpace/ReferentialsContext";
import { ReferenceItemPolluantElementDto } from "api/gen";
import { wrapWithWorkflowTargetProvider } from "pages/CompanySpace/WorkflowTargetProviderContext";

const Procedes = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Procédés",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoProcede,
    alt: "un tube a essais",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Procédés / Émissions diffuses",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);
  const elementAutreUuid: string = useElementAutreUuid();

  const computePolluantElementMapFromArray = (
    polluantElementList: ReferenceItemPolluantElementDto[]
  ): Map<number, Map<string, ReferenceItemPolluantElementDto>> => {
    const polluantElementMap = new Map();

    for (const polluantElement of polluantElementList) {
      const polluantRestorationId: number =
        polluantElement.polluantRestorationCode;

      if (polluantElementMap.has(polluantRestorationId)) {
        polluantElementMap
          .get(polluantRestorationId)
          .set(
            polluantElement.referenceItemElementIndexDto.uuid,
            polluantElement
          );
      } else {
        const elementToItem: Map<
          string,
          ReferenceItemPolluantElementDto
        > = new Map([
          [polluantElement.referenceItemElementIndexDto.uuid, polluantElement],
        ]);

        polluantElementMap.set(polluantRestorationId, elementToItem);
      }
    }

    return polluantElementMap;
  };

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_POLLUANTS_AIR",
        "REFERENCE_CO2",
        "REFERENCE_POLLUANT_ELEMENT",
      ]}
      render={referentials => {
        return (
          <MainProcedeForm
            referential={referentials.REFERENCE_POLLUANTS_AIR}
            referentialCO2={referentials.REFERENCE_CO2}
            polluantElementMap={computePolluantElementMapFromArray(
              referentials.REFERENCE_POLLUANT_ELEMENT
                .referenceItemPolluantElementDtoList
            )}
            referentialAutreElementUuid={elementAutreUuid}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Procedes, "GLOBAL");
