import {
  QuotasBlocInfosComplementairesInstallationsDto2020,
  QuotasInfosComplementairesInstallationsDto2021,
} from "api/gen";
import { BlocInfosComplementaires, InstallationInBloc } from "./types";

export const installationDtoToInBloc = (
  dto: QuotasInfosComplementairesInstallationsDto2021
): InstallationInBloc => {
  return {
    nimDynId: dto.nimDynId,
    categorie: dto.categorie,
    faiblementEmettrice: dto.faiblementEmettrice,
  };
};

export const blocInfosComplementairesToDto = (
  values: BlocInfosComplementaires
): QuotasBlocInfosComplementairesInstallationsDto2020 => {
  return {
    installations: values.installations.map(installationInBlocToDto),
  };
};

const installationInBlocToDto = (
  values: InstallationInBloc
): QuotasInfosComplementairesInstallationsDto2021 => {
  return {
    nimDynId: values.nimDynId,
    categorie: values.categorie,
    faiblementEmettrice: values.faiblementEmettrice,
  };
};
