import classNames from "classnames";
import { roundPathProgression } from "./utils/progressionUtils";
import { PATH_ROOT } from "common/path/path18Now";
import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import ProgressBar from "common/progressBar";
import { PINK, SECTION_FONT_SIZE } from "theme";
import Row from "common/presentational/Row";

interface DashboardProgressBarProps {
  declarationApi: DeclarationHelpers;
}

const useStyles = makeStyles({
  container: {
    height: "30px",
    alignItems: "center",
    width: "90%",
    padding: "25px 45px 45px",
  },
  progressBar: {
    width: "70%",
    height: "100%",
    margin: "auto",
  },
  title: {
    color: PINK,
    fontSize: SECTION_FONT_SIZE,
    textTransform: "uppercase",
    fontWeight: "normal",
  },
});

const DashboardProgressBar = ({
  declarationApi,
}: DashboardProgressBarProps): ReactElement => {
  const classes = useStyles();
  return (
    <Row additionalClassname={classes.container}>
      <h3 className={classes.title}>État d'avancement</h3>
      <div className={classNames(classes.progressBar)}>
        <ProgressBar
          percentageProgress={roundPathProgression(
            declarationApi.getPathProgress(PATH_ROOT)
          )}
          isValidated={declarationApi.isPathValidatedInDeclaration(PATH_ROOT)}
        />
      </div>
    </Row>
  );
};

export default DashboardProgressBar;
