import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemOrganismeExterieurPreventionDto } from "api/gen";

export const organismeExterieurLabel: "Organisme extérieur" =
  "Organisme extérieur";
export const structureFonctionnelleLabel: "Structure fonctionnelle" =
  "Structure fonctionnelle";

export type OutsideOrganismOrFunctionalStructureType =
  | "Organisme extérieur"
  | "Structure fonctionnelle";

export type OutsidePreventionOrganismType = OptionPropsWithObject<
  ReferenceItemOrganismeExterieurPreventionDto
>;

export interface StructureFonctionnelleData extends FormikValues {
  isSeasonalActivity: boolean;
  outsideOrganismOrFunctionalStructure: OutsideOrganismOrFunctionalStructureType;
  outsidePreventionOrganism: OutsidePreventionOrganismType;
  numberVisitsMadeInYear: number | null;
}

export const selectedOrganismOption = (
  outsideOrganismOrFunctionalStructure: OutsideOrganismOrFunctionalStructureType | null
) => outsideOrganismOrFunctionalStructure === organismeExterieurLabel;

export const computeOutsideOrganismOrFunctionalStructureLabel = (
  isFunctionalStructure: boolean | null
) => {
  if (isFunctionalStructure === null) {
    return null;
  }
  return isFunctionalStructure
    ? structureFonctionnelleLabel
    : organismeExterieurLabel;
};
