import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { AreasForm } from "./types";

export const convertDtoToAreasForm = (
  declaration: Declaration1919
): AreasForm => {
  return {
    isRestoredThisYear:
      declaration.body.sections.carriere.environnement.superficie.aRemisEnEtat,
    agriculturalZone:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneAgricole,
    agriculturalZonePreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneAgricole,
    forestryZone:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneForestiere,
    forestryZonePreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneForestiere,
    economicZoneGround:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEconomique,
    economicZoneGroundPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEconomique,
    economicZoneWater:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEconomique,
    economicZoneWaterPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEconomique,
    ecologicalZoneGround:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.zoneEcologique,
    ecologicalZoneGroundPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.zoneEcologique,
    ecologicalZoneWater:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatEau.zoneEcologique,
    ecologicalZoneWaterPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatEau.zoneEcologique,
    otherGround:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.autre,
    otherGroundPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.autre,
    otherWater:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatEau.autre,
    otherWaterPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatEau.autre,
    repairTypeGround:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatTerre.typeRemiseEtatAutre,
    repairTypeGroundPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatTerre.typeRemiseEtatAutre,
    repairTypeWater:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneeEcoulee.remiseEtatEau.typeRemiseEtatAutre,
    repairTypeWaterPreviousYear:
      declaration.body.sections.carriere.environnement.superficie
        .remisEnEtatAnneePrecedentes.remiseEtatEau.typeRemiseEtatAutre,
  };
};
