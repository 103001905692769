import { ReferenceActiviteQuotaDto } from "api/gen";
import {
  QuotasBlocInstallationsDto1819,
  QuotasInstallationDto1819,
  QuotasInstallationDto1819CategorieEnum,
  ReferenceItemActiviteQuotaDto,
} from "api/gen/api";
import {
  InstallationInArray,
  InstallationInModale,
  InstallationSelectOptions,
} from "./types";
import {
  cleanComputeRightOptionProps,
  computeRightOptionProps,
} from "common/form/fields/connectedInput/utils";
import { cleanComputeEnumValue } from "common/utils/enumOptionsPropsConverter";
import { OptionProps } from "common/form/fields/types/basicTypes";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

const getActiviteNameById = (
  activites: ReferenceItemActiviteQuotaDto[],
  activiteId: string
) => {
  const activite = findElementMatchingTemplate({ uuid: activiteId }, activites);
  return activite ? activite.description : null;
};

const getActiviteIdByName = (
  activites: ReferenceItemActiviteQuotaDto[],
  activiteName: string
) => {
  const activite = findElementMatchingTemplate(
    { description: activiteName },
    activites
  );
  return activite ? activite.uuid : null;
};

const installationDtoToInstallation = (
  installationDto: QuotasInstallationDto1819,
  possibleValues: InstallationSelectOptions,
  referentialActivites: ReferenceActiviteQuotaDto
): InstallationInModale => {
  return {
    nim: installationDto.nim,
    categorie: cleanComputeRightOptionProps(
      installationDto.categorie,
      possibleValues.categories
    ),
    activites:
      (installationDto.codesActiviteID &&
        (installationDto.codesActiviteID
          .map(activite =>
            computeRightOptionProps(
              getActiviteNameById(
                referentialActivites.referenceItemActiviteQuotaDtoList,
                activite
              ),
              possibleValues.activites
            )
          )
          .filter(option => !!option) as OptionProps[])) ||
      [],
    faiblementEmettrice: !!installationDto.faiblementEmettrice,
  };
};

export const dtoToInstallations = (
  dto: QuotasBlocInstallationsDto1819,
  possibleValues: InstallationSelectOptions,
  referentialActivites: ReferenceActiviteQuotaDto
): InstallationInArray[] => {
  //TODO compute errors
  return dto.installations.map(installationDto => ({
    data: {
      ...installationDtoToInstallation(
        installationDto,
        possibleValues,
        referentialActivites
      ),
      id: installationDto.id,
    },
    errors: {},
  }));
};

const singleInstallationToInstallationDto = (
  populatedInstallation: InstallationInArray,
  referentialActivites: ReferenceActiviteQuotaDto
): QuotasInstallationDto1819 => {
  return {
    id: populatedInstallation.data.id,
    nim: populatedInstallation.data.nim,
    categorie: cleanComputeEnumValue(
      populatedInstallation.data.categorie,
      QuotasInstallationDto1819CategorieEnum
    ),
    codesActiviteID:
      (populatedInstallation.data.activites &&
        populatedInstallation.data.activites.map(
          option =>
            getActiviteIdByName(
              referentialActivites.referenceItemActiviteQuotaDtoList,
              option.label
            ) || ""
        )) ||
      [],
    faiblementEmettrice: populatedInstallation.data.faiblementEmettrice,
  };
};

export const updateInstallationsHandler = (
  declaration: Declaration1919,
  installations: InstallationInArray[],
  referentialActivites: ReferenceActiviteQuotaDto
): Declaration1919 => {
  declaration.body.sections.quotas.blocInstallations.installations = installations.map(
    installation =>
      singleInstallationToInstallationDto(installation, referentialActivites)
  );

  return declaration;
};
