import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  singleInstallationHasSubPartActivated,
  singleInstallationOverwriteDependantFields,
  singleInstallationtValidationSchema,
} from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { getOptionsInstallations } from "./utils/selectPossibleValues";
import {
  combustionTypeIsWrong,
  combustionUniqueInstallationMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";
import { computeErrors } from "../utils/utils";
import { InstallationInArray, InstallationInModale } from "./utils/types";

interface FormSinglePolluantProps {
  closeFunction: () => void;

  onSubmit: (
    values: InstallationInModale,
    formikActions: FormikActions<InstallationInModale>
  ) => void;

  initialInstallation: InstallationInModale | null;
  isLcp: boolean;
  isWicowi: boolean;
  forbiddenInstallationName: string[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormSingleInstallation = ({
  closeFunction,
  onSubmit,
  initialInstallation,
  isLcp,
  isWicowi,
  forbiddenInstallationName,
}: FormSinglePolluantProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-combustion-installation-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);

  const getRightOptions = () => {
    if (isLcp && isWicowi) {
      return getOptionsInstallations("lcpAndWi");
    } else if (isLcp) {
      return getOptionsInstallations("lcp");
    } else if (isWicowi) {
      return getOptionsInstallations("wi");
    } else {
      return getOptionsInstallations("noLcpNoWi");
    }
  };

  const optionsList = getRightOptions();
  const defaultOption = optionsList.length === 1 ? optionsList[0] : null;

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE INSTALLATION"
      closeFunction={closeFunction}
      onSubmit={(values: InstallationInModale, formikBag) => {
        singleInstallationOverwriteDependantFields(values);
        onSubmit(values, formikBag);
      }}
      isEdit={initialInstallation !== null}
      initialEntity={
        initialInstallation === null
          ? {
              nom: null,
              type: defaultOption,
              heure: null,
              volumeActivite: null,
              unite: null,
              typeProduit: null,
              quantiteChaleur: null,
              quantiteElectricite: null,
              rendementChaleur: null,
              rendementElectricite: null,
            }
          : initialInstallation
      }
      validationSchema={singleInstallationtValidationSchema}
      validate={values => {
        const errors: Errors<InstallationInModale> = {};
        const newInstallation: InstallationInArray = {
          data: {
            id: "1",
            nom: values.nom,
            type: values.type,
            heure: values.heure,
            volumeActivite: values.volumeActivite,
            unite: values.unite,
            typeProduit: values.typeProduit,
            quantiteChaleur: values.quantiteChaleur,
            quantiteElectricite: values.quantiteElectricite,
            rendementChaleur: values.rendementChaleur,
            rendementElectricite: values.rendementElectricite,
          },
          errors: {},
        };
        const information = { lcp: isLcp, wiCoWi: isWicowi };
        const computedErrors = computeErrors(newInstallation, information);
        if (Object.keys(computedErrors).length > 0) {
          errors.type = combustionTypeIsWrong;
        }
        if (
          forbiddenInstallationName.some(
            singleInstallationName => singleInstallationName === values.nom
          )
        ) {
          errors.nom = combustionUniqueInstallationMessage;
        }
        return errors;
      }}
      renderField={({ values }) => {
        return (
          <>
            <TextField name="nom" label="Nom de l'installation *" />
            <ChoiceSelectField
              name="type"
              label="Type d'installation *"
              options={optionsList}
              isMulti={false}
              disabled={optionsList.length <= 1}
              additionalClassName={classes.longField}
            />
            <NumberField
              name="heure"
              label="Nombre d'heures de fonctionnement *"
              unit="h/an"
              tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
            />
            <NumberField
              name="volumeActivite"
              label="Volume d'activité *"
              unit=""
              tooltipContent={
                <>
                  Correspond :
                  <ul>
                    <li>
                      Pour les installations de combustion, à la puissance
                      thermique maximum autorisée (en MWth)
                    </li>
                    <li>
                      Pour les installations d'incinération ou de coïncinération
                      de déchets, à la capacité nominale (en t/h)
                    </li>
                    <li>
                      Pour les autres types d'installations, à la capacité en t,
                      m², m³ ou tout autre paramètre représentatif de l'activité
                      par unité de temps.
                    </li>
                  </ul>
                </>
              }
            />
            <TextField name="unite" label="Unité *" />
            <TextField
              name="typeProduit"
              label="Type(s) de produit(s)"
              tooltipContent="Le type de produit correspond ici au produit qui sort de l'installation concernée."
            />

            {singleInstallationHasSubPartActivated(values.type) && (
              <>
                <NumberField
                  name="quantiteChaleur"
                  label="Quantité de chaleur produite *"
                  unit="GJ"
                />
                <NumberField
                  name="quantiteElectricite"
                  label="Quantité d'électricité produite *"
                  unit="GJ"
                />
                <NumberField
                  name="rendementChaleur"
                  label="Rendement de chaleur *"
                  unit="%"
                />
                <NumberField
                  name="rendementElectricite"
                  label="Rendement d'électricité *"
                  unit="%"
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleInstallation;
