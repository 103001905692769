import {
  commonNullableStringFields,
  commonPositiveNumberFields,
  commonStringFields,
  MAX_NB_HOURS_YEAR,
  subNumberFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import * as Yup from "yup";
import {
  combustionInstallationTooMuchTimeMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { InstallationInModale } from "../utils/types";
import { AirCombustionInstallationsDto20NowTypeEnum } from "api/gen";
import { parseToUndefinedIfNull } from "common/form/utils";

export const singleInstallationHasSubPartActivated = (
  type: AirCombustionInstallationsDto20NowTypeEnum | null
): boolean => {
  return !!(
    type &&
    (type === AirCombustionInstallationsDto20NowTypeEnum.WI ||
      type === AirCombustionInstallationsDto20NowTypeEnum.CoWI ||
      type === AirCombustionInstallationsDto20NowTypeEnum.LCP_WI ||
      type === AirCombustionInstallationsDto20NowTypeEnum.LCP_COWI)
  );
};

export const singleInstallationOverwriteDependantFields = (
  installation: InstallationInModale
): void => {
  if (!singleInstallationHasSubPartActivated(installation.type)) {
    installation.quantiteChaleur = null;
    installation.quantiteElectricite = null;
    installation.rendementChaleur = null;
    installation.rendementElectricite = null;
  }
};

export const singleInstallationValidationSchema = Yup.object().shape({
  nom: commonStringFields,
  heure: commonPositiveNumberFields.max(
    MAX_NB_HOURS_YEAR,
    combustionInstallationTooMuchTimeMessage
  ),
  volumeActivite: commonPositiveNumberFields,
  unite: commonStringFields,
  typeProduit: commonNullableStringFields,
  type: Yup.mixed<AirCombustionInstallationsDto20NowTypeEnum>()
    .transform(parseToUndefinedIfNull)
    .required(requiredMessage),
  quantiteChaleur: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  quantiteElectricite: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  rendementChaleur: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
  rendementElectricite: subNumberFieldValidationScheme(
    "type",
    singleInstallationHasSubPartActivated
  ),
});
