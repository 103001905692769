import { Declaration21Now } from "./declarationHooks21Now";
import {
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
} from "api/gen";

export const updateHandlerBilanTotal21Now = (
  declaration: Declaration21Now,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration21Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddress21Now = (
  declaration: Declaration21Now,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration21Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
