import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BlocInfoGeneralesFormValues } from "./types";
import { updateHandlerInfoGenerales } from "../utils";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import {
  PATH_EAU_INFOGENERALE,
  PATH_EAU_INFOGENERALE_MEGATHERMIE,
  PATH_EAU_INFOGENERALE_SEUIL,
} from "common/path/path18Now";
import BlocFullContext24Now from "../../versionedElements/BlocFullContext24Now";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface BlocInfoGeneralesProps {
  values: BlocInfoGeneralesFormValues;
  setValues: (values: BlocInfoGeneralesFormValues) => void;
  hasChanges: boolean;
  cancelAction: () => void;
}

const BlocInfoGenerales = ({
  values,
  setValues,
  hasChanges,
  cancelAction,
}: BlocInfoGeneralesProps): JSX.Element => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "75%",
    formPrefix: "bloc-eau-info-generales",
  };

  const BooleanSelectField = useDummyBooleanCheckboxFieldGenerator(commonProps);

  return (
    <BlocFullContext24Now
      title="informations complémentaires"
      hasModification={hasChanges}
      isValidateButtonAvailable={true} // No validation needed (only two boolean, all combinaisons authorized)
      path={PATH_EAU_INFOGENERALE}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration =>
        updateHandlerInfoGenerales(declaration, values)
      }
      cancelAction={cancelAction}
      renderContent={shouldDisabledFields => (
        <>
          <BooleanSelectField
            name="depassementSeuil"
            label="L'établissement prélève plus de 50 000 m3/an sur le réseau AEP ou 7 000 m3/an dans le milieu naturel"
            disabled={shouldDisabledFields}
            value={values.depassementSeuil}
            onChange={checked =>
              setValues({ ...values, depassementSeuil: checked })
            }
            commentPath={PATH_EAU_INFOGENERALE_SEUIL}
          />
          <BooleanSelectField
            name="megathermie"
            label="L'établissement rejette plus 100 Mth/an pour les rejets en mer ou plus de 10 Mth/an pour les rejets en rivière pour la période allant du 1er avril au 31 décembre"
            disabled={shouldDisabledFields}
            value={values.megathermie}
            onChange={checked => setValues({ ...values, megathermie: checked })}
            commentPath={PATH_EAU_INFOGENERALE_MEGATHERMIE}
          />
        </>
      )}
    />
  );
};

export default BlocInfoGenerales;
