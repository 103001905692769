import { AirSolvantsInstallationDto } from "api/gen";
import { CovInArray } from "../../CovBloc/utils/types";
import { EmissionsInArray } from "../../EmissionBloc/utils/types";
import { InstallationInArray } from "./types";

export const convertInstallationInArraysToInstallationDtos = (
  InstallationInArray: InstallationInArray[]
): AirSolvantsInstallationDto[] => {
  return InstallationInArray.map(singlePopulatedInstallation =>
    convertInstallationInArrayToDto(singlePopulatedInstallation)
  );
};

export const convertInstallationInArrayToDto = (
  installation: InstallationInArray
): AirSolvantsInstallationDto => {
  return installation.data;
};

export const convertInstallationsToDisplayed = (
  installation: AirSolvantsInstallationDto
): InstallationInArray => {
  return {
    //TODO: calculate errors
    data: installation,
    errors: {},
  };
};

/**
 * Filter the installations list declared in page to get only those linked to a emission
 * @param emission installations linked to a emission
 * @param installationsInPage installation in front page
 * @returns the list of the installations in page linked to the emission
 */
const getEmissionLinkedInstallationInPages = <
  T extends CovInArray | EmissionsInArray
>(
  emission: T,
  installationsInPage: InstallationInArray[]
): InstallationInArray[] => {
  const emissionInstallationList = emission.data.installations;

  if (emissionInstallationList === null) {
    return [];
  }

  return installationsInPage.filter(
    installation =>
      emissionInstallationList.find(
        oldInstallation => oldInstallation.id === installation.data.id
      ) !== undefined
  );
};

/**
 * Update the emissions with the new installations saved.
 * First for each emission, remove deleted installations and update each installation modified.
 * Then delete each emission with no installation linked.
 * @param content emissions to update
 * @returns updated emissions
 */
export const modifyEmissionBasedOnInstallation = <
  T extends CovInArray | EmissionsInArray
>(
  content: T[],
  installationsInPage: InstallationInArray[]
): T[] => {
  return content
    .map((singleElderValue: T) => {
      const newInstallationList: InstallationInArray[] = getEmissionLinkedInstallationInPages(
        singleElderValue,
        installationsInPage
      );
      singleElderValue.data.installations = convertInstallationInArraysToInstallationDtos(
        newInstallationList
      );
      return singleElderValue;
    })
    .filter((singleElderValue: T) => {
      return (
        singleElderValue.data.installations !== null &&
        singleElderValue.data.installations.length > 0
      );
    });
};

export const compareInstallationFunction = (
  a: InstallationInArray,
  b: InstallationInArray
): number => {
  if (a.data.nom && b.data.nom && a.data.nom !== b.data.nom) {
    return a.data.nom < b.data.nom ? -1 : 1;
  }
  return 0;
};
