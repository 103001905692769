import React from "react";
import {
  AuthDroitDto,
  AuthDroitDtoAuthProfileEnum,
  LecteurDroitDtoAllowedSectionsEnum,
  ReferenceRegionDto,
} from "api/gen/api";
import _ from "lodash";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { getTrigrammeFromCodeRegionOrEmptyString } from "common/utils/regions";

const computeSuperAdminTitleForRight = () => {
  return "Super administrateur";
};
const computeAdminTitleForRight = (
  right: AuthDroitDto,
  referentialRegions: ReferenceRegionDto
) => {
  return `Administrateur ${(right.natureService && right.natureService.nom) ||
    ""} ${getTrigrammeFromCodeRegionOrEmptyString(
    right.codeRegion,
    referentialRegions
  )} ${right.departement || ""}`;
};
const computeLecteurTitleForRight = (
  right: AuthDroitDto,
  referentialRegions: ReferenceRegionDto
) => {
  return `Lecteur ${
    right.droitsEtendus
  } ${getTrigrammeFromCodeRegionOrEmptyString(
    right.codeRegion,
    referentialRegions
  )} ${right.departement || ""}
`;
};

const computeExploitantTitleForRight = (right: AuthDroitDto) => {
  return `Exploitant ${right.codeEtablissement ||
    ""} - ${right.nameEtablissement || ""}`;
};
const computeGestionnaireTitleForRight = (
  right: AuthDroitDto,
  referentialRegions: ReferenceRegionDto
) => {
  return `Gestionnaire ${(right.natureService && right.natureService.nom) ||
    ""} ${getTrigrammeFromCodeRegionOrEmptyString(
    right.codeRegion,
    referentialRegions
  )} ${right.departement || ""}`;
};
const computePrestataireTitleForRight = (right: AuthDroitDto) => {
  const numberEtablissements =
    right.codeEtablissementPrestataireDroitMap !== null
      ? Object.values(right.codeEtablissementPrestataireDroitMap).reduce(
          (sum, arr) => sum + arr.length,
          0
        )
      : 0;
  if (numberEtablissements === 0) {
    return "";
  } else {
    return `${numberEtablissements} Établissement${
      numberEtablissements > 1 ? "s" : ""
    }`;
  }
};

export const computeRightRows = (
  rights: AuthDroitDto[],
  referentialRegions: ReferenceRegionDto,
  activeRight: AuthDroitDto | undefined,
  callback: (newRight: AuthDroitDto) => void,
  containerClass: string,
  containerActiveRightClass: string
): React.ReactElement[] => {
  const divForRightStr = (
    strRight: string,
    key: string,
    isActiveRight: boolean,
    onClick: () => void
  ) => {
    return (
      <div
        className={isActiveRight ? containerActiveRightClass : containerClass}
        key={key}
        onClick={isActiveRight ? undefined : onClick}
      >
        &emsp;&emsp;- {strRight}
      </div>
    );
  };

  return rights.map((right, index) => {
    const isActiveRight = _.isEqual(right, activeRight);
    let title;
    switch (right.authProfile) {
      case AuthDroitDtoAuthProfileEnum.SUPER_ADMIN:
        title = computeSuperAdminTitleForRight();
        break;
      case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR:
      case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA:
        title = computeAdminTitleForRight(right, referentialRegions);
        break;
      case AuthDroitDtoAuthProfileEnum.LECTEUR:
        title = computeLecteurTitleForRight(right, referentialRegions);
        break;
      case AuthDroitDtoAuthProfileEnum.EXPLOITANT:
        title = computeExploitantTitleForRight(right);
        break;
      case AuthDroitDtoAuthProfileEnum.GESTIONNAIRE:
        title = computeGestionnaireTitleForRight(right, referentialRegions);
        break;
      case AuthDroitDtoAuthProfileEnum.PRESTATAIRE:
        title = computePrestataireTitleForRight(right);
        break;
      default:
        throw new ShouldNotHappen(right.authProfile);
    }

    return divForRightStr(title, index.toString(), isActiveRight, () =>
      callback(right)
    );
  });
};
