import React from "react";
import { FactoryInArray } from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import uuid from "uuid";
import { ReferenceGeoCodeInseeDto, ReferencePaysDto } from "api/gen";
import FormFactory from "./FormFactory";

interface FactoriesModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  factoryInModal: FactoryInArray | null;
  setFactoryInModal: (factory: FactoryInArray | null) => void;
  factoriesInArray: FactoryInArray[];
  setFactoriesInArray: (substances: FactoryInArray[]) => void;
  referentialCountries: ReferencePaysDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
}

const FactoriesModal = ({
  isOpen,
  setIsModalOpen,
  factoryInModal,
  setFactoryInModal,
  factoriesInArray,
  setFactoriesInArray,
  referentialCountries,
  referentialInsee,
}: FactoriesModalProps) => {
  const onClose = () => {
    setFactoryInModal(null);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <FormFactory
        referentialCountries={referentialCountries}
        referentialInsee={referentialInsee}
        closeFunction={onClose}
        onSubmit={values => {
          const factory: FactoryInArray = {
            data: {
              id: factoryInModal ? factoryInModal.data.id : uuid(),
              socialReason: values.socialReason,
              careerRange: values.careerRange,
              city: values.city,
              country: values.country,
              poweredByOtherCareers: values.poweredByOtherCareers,
              zipCode: values.zipCode,
            },
            errors: {},
          };
          const newFactoriesInArray = factoriesInArray;
          let indexToReplace = newFactoriesInArray.length;
          if (factoryInModal) {
            indexToReplace = _.findIndex(newFactoriesInArray, factoryInModal);
            setFactoryInModal(null);
          }
          newFactoriesInArray[indexToReplace] = factory;
          setFactoriesInArray(newFactoriesInArray);
          onClose();
        }}
        initialObject={
          factoryInModal !== null
            ? factoryInModal.data
            : {
                socialReason: null,
                careerRange: null,
                city: null,
                country: null,
                poweredByOtherCareers: null,
                zipCode: null,
              }
        }
        isEdit={factoryInModal !== null}
      />
    </CustomModal>
  );
};

export default FactoriesModal;
