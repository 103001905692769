import React, { useMemo } from "react";
import { RouteIdProps } from "common/declarant/type";
import DeclarationApiProvider from "./DeclarationApiContext/DeclarationApiContext";
import { CurrentDeclarationCampaignProvider } from "pages/CommonSpace/CampaignContext";
import { DeclarationApiProviderToFetchInitializer } from "./DeclarationApiContext/utils/types";
import { useUserData } from "Authenticator/UserData";
import RedirectHome from "pages/CommonSpace/AppPage/Redirect/RedirectHome";
import { CorrectYearRouter } from "./DeclarationApiContext/utils/correctYearVersionedElements/CorrectYearRouter";
import CommentStore from "./Comments/CommentStore";

const CompanySpace = (route: RouteIdProps): React.ReactElement => {
  const declarationAnnee = route.match.params.annee;
  const declarationEtablissement = route.match.params.etablissement;

  const userData = useUserData();

  const declarationInitializer: DeclarationApiProviderToFetchInitializer = useMemo(() => {
    return {
      type: "declarationProps",
      props: {
        declarationAnnee: declarationAnnee,
        declarationEtablissement: declarationEtablissement,
      },
    };
  }, [declarationAnnee, declarationEtablissement]);

  // exploitants are redirected to their own declaration
  if (userData.userInfo.droits.length > 0) {
    const { codeEtablissement } = userData.userInfo.droits[0];
    if (
      !userData.isInspecteur &&
      !userData.isLecteur &&
      !userData.isAnyPrestataire &&
      codeEtablissement !== declarationEtablissement
    ) {
      return <RedirectHome initialRedirection={true} />;
    }
  }

  return (
    <CurrentDeclarationCampaignProvider
      declarationAnnee={parseInt(declarationAnnee)}
    >
      <DeclarationApiProvider initializer={declarationInitializer}>
        <CommentStore>
          <CorrectYearRouter route={route} year={parseInt(declarationAnnee)} />
        </CommentStore>
      </DeclarationApiProvider>
    </CurrentDeclarationCampaignProvider>
  );
};

export default CompanySpace;
