import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_ELEVAGE,
  PATH_AIR_GAZ,
  PATH_AIR_ISDND,
  PATH_AIR_PROCEDES,
  PATH_AIR_SOLVANTS,
  PATH_AIR_SYNTHESE,
} from "common/path/path18Now";
import {
  getDisabledMessageForConditionnalSection,
  getDisabledMessageForTypeActiviteConditionnalSection,
} from "../../../../toNow/Dashboard/Components/utils/disabledMessageUtils";
import { GlobalDashboardCardsProps, SubCard } from "./types";

import fireImg from "icons/dashboardLogos/fire.svg";
import chemicalImg from "icons/dashboardLogos/chemical.svg";
import flaskImg from "icons/dashboardLogos/flask.svg";
import biogasPlantImg from "icons/dashboardLogos/biogas-plant.svg";
import pigImg from "icons/dashboardLogos/pig.svg";
import listImg from "icons/dashboardLogos/list.svg";
import gasFluoreImg from "icons/dashboardLogos/gas-fluore.svg";

function buildCombustionSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Combustion / Incinération",
    picture: { src: fireImg, alt: "" },
    percentageProgress: getPathProgress(PATH_AIR_COMBUSTION),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_COMBUSTION}`,
    isDisabled: !getIsActiveSection(PATH_AIR_COMBUSTION),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_COMBUSTION),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildProcedesSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Procédés / Émissions diffuses",
    picture: { src: chemicalImg, alt: "" },
    percentageProgress: getPathProgress(PATH_AIR_PROCEDES),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_PROCEDES}`,
    isDisabled: !getIsActiveSection(PATH_AIR_PROCEDES),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_PROCEDES),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildSolvantsSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Solvants / PGS",
    picture: { src: flaskImg, alt: "" },
    percentageProgress: getPathProgress(PATH_AIR_SOLVANTS),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_SOLVANTS}`,
    isDisabled: !getIsActiveSection(PATH_AIR_SOLVANTS),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_SOLVANTS),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildISDNDSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "ISDND",
    picture: { src: biogasPlantImg, alt: "" },
    percentageProgress: getPathProgress(PATH_AIR_ISDND),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_ISDND}`,
    isDisabled: !getIsActiveSection(PATH_AIR_ISDND),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_ISDND),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildElevageSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Élevage",
    picture: { src: pigImg, alt: "" },
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_ELEVAGE}`,
    percentageProgress: getPathProgress(PATH_AIR_ELEVAGE),
    isDisabled: !getIsActiveSection(PATH_AIR_ELEVAGE),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_ELEVAGE),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildSyntheseSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Synthèse",
    picture: { src: listImg, alt: "" },
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_SYNTHESE}`,
    percentageProgress: getPathProgress(PATH_AIR_SYNTHESE),
    isDisabled: !getIsActiveSection(PATH_AIR_SYNTHESE),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_SYNTHESE),
    disabledMessage: getDisabledMessageForConditionnalSection(
      isPathValidatedInDeclaration,
      "Cette page est active si vous avez déclaré au moins une substance dans l'air et validé tous les blocs du pavé Air"
    ),
  };
}

function buildGazSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Gaz fluorés",
    picture: { src: gasFluoreImg, alt: "" },
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_GAZ}`,
    percentageProgress: getPathProgress(PATH_AIR_GAZ),
    isDisabled: !getIsActiveSection(PATH_AIR_GAZ),
    isValidated: isPathValidatedInDeclaration(PATH_AIR_GAZ),
    disabledMessage: getDisabledMessageForConditionnalSection(
      isPathValidatedInDeclaration,
      "Cette page est active si vous avez déclaré au moins une émission de HFC ou de PFC dans l'air et validé le bloc Synthèse"
    ),
  };
}

export function buildAirSubCards(props: GlobalDashboardCardsProps): SubCard[] {
  return [
    buildCombustionSubCard(props),
    buildProcedesSubCard(props),
    buildSolvantsSubCard(props),
    buildISDNDSubCard(props),
    buildElevageSubCard(props),
    buildSyntheseSubCard(props),
    buildGazSubCard(props),
  ];
}
