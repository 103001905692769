import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import { LARGE_BUTTON_WIDTH } from "theme";
import Row from "common/presentational/Row";
import Button from "common/button";
import CombustibleArray from "./CombustibleArray";
import CombustibleModale from "./CombustibleModale";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { ReferenceCombustibleDto } from "api/gen";
import { useConfirmationModale } from "common/modale/hooks";
import { CombustibleInArray } from "./utils/types";
import { AppareilInArray } from "../BlocAppareils/utils/types";
import { FacteurEmissionInArray } from "../BlocEmissions/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import _ from "lodash";
import { compareCombustibles } from "./utils/utils";
import { DeclarationConstraintViolation19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";
import { ViolationProcessor } from "common/violations/ViolationProcessor";
import { InstallationInArray } from "../../BlocInstallation/utils/types";

const useStyles = makeStyles({
  largeButton: {
    width: LARGE_BUTTON_WIDTH,
  },
});

interface BlocCombustibleProps {
  setCombustiblesInPage: Dispatch<SetStateAction<CombustibleInArray[]>>;
  combustiblesInPage: CombustibleInArray[];
  installation: InstallationInArray;
  combustibleReferential: ReferenceCombustibleDto;
  appareilsInPage: AppareilInArray[];
  shouldDisabledFields: boolean;
  validationContentPath: string;
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  referentialSinglePolluants: ReferentialSinglePolluants;
  referentialElemenAutreUuid: string;
  isValidated: boolean;
  errors: DeclarationConstraintViolation19Now[];
  warnings: DeclarationConstraintViolation19Now[];
}

const BlocCombustibles = ({
  setCombustiblesInPage,
  combustiblesInPage,
  installation,
  combustibleReferential,
  appareilsInPage,
  shouldDisabledFields,
  validationContentPath,
  isTorchereBiogaz,
  isValorisationBiogaz,
  facteursInPage,
  setFacteursInPage,
  referentialSinglePolluants,
  referentialElemenAutreUuid,
  isValidated,
  errors,
  warnings,
}: BlocCombustibleProps): React.ReactElement => {
  const classes = useStyles();

  const openConfirmationModale = useConfirmationModale();
  const [combustibleModaleOpen, setCombustibleModaleOpen] = useState(false);

  const [
    combustibleInModale,
    setCombustibleInModale,
  ] = useState<CombustibleInArray | null>(null);

  const installationName = installation.data.nom;

  const displayErrorAndWarning = (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => <ViolationProcessor errors={errors} warnings={warnings} />;

  return (
    <>
      <DummyUnActionedBloc
        title={`Déclaration des combustibles de l'installation: ${installationName}`}
        renderContent={() => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UN COMBUSTIBLE"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    setCombustibleModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <CombustibleArray
                combustiblesInArray={_.cloneDeep(combustiblesInPage).sort(
                  compareCombustibles
                )}
                setCombustiblesInArray={setCombustiblesInPage}
                setCombustibleInModale={setCombustibleInModale}
                setCombustibleModaleOpen={setCombustibleModaleOpen}
                isValidated={shouldDisabledFields}
                installationName={installationName}
                validationCombustiblesPath={`${validationContentPath}/combustibles`}
                facteursInPage={facteursInPage}
                setFacteursInPage={setFacteursInPage}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ? Cela supprimera également le contenu du tableau émissions.",
                      () => {
                        setCombustiblesInPage([]);
                        setFacteursInPage([]);
                      }
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              {isValidated &&
                (errors.length > 0 || warnings.length > 0) &&
                displayErrorAndWarning(errors, warnings)}
            </>
          );
        }}
      />
      <CombustibleModale
        combustibleModaleOpen={combustibleModaleOpen}
        combustibleInModale={combustibleInModale}
        setCombustiblesInArray={setCombustiblesInPage}
        setCombustibleInModale={setCombustibleInModale}
        setCombustibleModaleOpen={setCombustibleModaleOpen}
        installationName={installationName}
        combustibleReferential={combustibleReferential}
        appareilsInArray={appareilsInPage}
        isTorchereBiogaz={isTorchereBiogaz}
        isValorisationBiogaz={isValorisationBiogaz}
        facteursInPage={facteursInPage}
        setFacteursInPage={setFacteursInPage}
        referentialSinglePolluants={referentialSinglePolluants}
        autreElementUuid={referentialElemenAutreUuid}
      />
    </>
  );
};

export default BlocCombustibles;
