import React, { useCallback, useEffect, useState } from "react";
import {
  AdminEmailDto,
  EtablissementListDto,
  FiltresEtablissementDto,
  FiltresEtablissementDtoSortByEnum,
  NatureServiceWithEnumDto,
  ReferenceDepartementDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
  ReferenceNatureServiceDto,
  ReferenceRegionDto,
} from "api/gen";
import Row from "common/presentational/Row";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchField from "common/presentational/search/SearchField";
import NumberOfLinesSelector, {
  NUMBER_OF_LINES_OPTIONS,
} from "common/presentational/pager/NumberOfLinesSelector";
import PageSelection from "common/presentational/pager/PageSelection";
import { CompanyManagementModale } from "./creationModale/CompanyManagementModale";
import { makeStyles } from "@material-ui/styles";
import { CompanyTable } from "./CompanyTable";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import Spinner from "common/presentational/Spinner";
import { useUserData } from "Authenticator/UserData";
import { isEstablishmentCreationAvailableForRight } from "./creationModale/utils";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import {
  backMessageExportEtablissementList,
  backMessageRetrieveEstablishmentList,
} from "common/backErrors/errorMessages";
import RedirectHome from "pages/CommonSpace/AppPage/Redirect/RedirectHome";
import { downloadExportResult } from "pages/CommonSpace/CompanyTechnique/2019_now/utils";
import { useActiveOrLastCampaign } from "pages/CommonSpace/CampaignContext";
import { isCampaignActive } from "pages/CommonSpace/CampaignContext/utils";

const useStyles = makeStyles({
  nbLines: {
    fontSize: "14px",
    flexDirection: "row-reverse",
    alignItems: "center",
  },
  rightElement: {
    justifyContent: "flex-end",
  },
  searchField: {
    height: "80%",
  },
  downloadButton: {
    padding: "5px",
  },
});

interface CompanyManagementControllerProps {
  referentielDepartements: ReferenceDepartementDto;
  referentielRegions: ReferenceRegionDto;
  referentielOrganismesAtCreation: ReferenceNatureServiceDto;
  adminEmailDto: AdminEmailDto;
}

export const CompanyManagementController = (
  props: CompanyManagementControllerProps
): React.ReactElement => {
  const classes = useStyles();
  const { gestionnaireController } = useAuthenticatedApi();
  const onpenAlertModale = useAlertModale();
  const currentRight = useUserData().userInfo.droits[0];
  const campaign = useActiveOrLastCampaign();

  const [
    establishmentList,
    setEstablishmentList,
  ] = useState<EtablissementListDto | null>(null);
  const [filter, setFilter] = useState<FiltresEtablissementDto>({
    sortBy: FiltresEtablissementDtoSortByEnum.ETABLISSEMENT_NAME,
    ascending: true,
    searchByIdOrNameOrSiret: null,
    searchByNimNumber: null,
  });
  const [requestFailed, setRequestFailed] = useState<boolean>(false);
  const [numberOfLines, setNumberOfLines] = useState(
    NUMBER_OF_LINES_OPTIONS[0]
  );
  const [pageIndex, setPageIndex] = useState(0);
  const [isModaleOpen, setIsModaleOpen] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [searchString, setSearchString] = useState<string>(
    filter.searchByIdOrNameOrSiret || ""
  );

  const departements: ReferenceItemDepartementDto[] =
    props.referentielDepartements.departements;

  const regions: ReferenceItemRegionDto[] =
    props.referentielRegions.referenceItemRegionDtoList;

  const organismesInspection: NatureServiceWithEnumDto[] =
    props.referentielOrganismesAtCreation.organismes;

  useEffect(() => {
    if (establishmentList !== null) {
      return;
    }

    let outdated = false; // To prevent race condition

    const fetchEstablishements = async () => {
      try {
        const result = await gestionnaireController.retrieveEtablissementsListUsingPOST(
          filter,
          pageIndex,
          numberOfLines
        );
        if (!outdated) {
          setEstablishmentList(result);
        }
      } catch (excp) {
        await onpenAlertModale(
          backAlertMessage(excp, backMessageRetrieveEstablishmentList)
        );
        setRequestFailed(true);
      }
    };

    fetchEstablishements();
    return () => {
      outdated = true;
    };
  }, [
    gestionnaireController,
    filter,
    numberOfLines,
    pageIndex,
    onpenAlertModale,
    establishmentList,
  ]);

  const exportExcelEtablissements = useCallback(
    async (filter: FiltresEtablissementDto) => {
      try {
        const result = await gestionnaireController.exportEtablissementsListUsingPOST(
          filter
        );
        if (!(await downloadExportResult(result, "etablissements.xls"))) {
          throw Error("Failed to download etablissements content.");
        }
      } catch (excp) {
        onpenAlertModale(
          backAlertMessage(excp, backMessageExportEtablissementList)
        );
        setRequestFailed(true);
      }
    },
    [gestionnaireController, onpenAlertModale]
  );

  if (requestFailed) {
    return <RedirectHome initialRedirection={true} />;
  }

  let nbPagesTotal = 0;
  if (establishmentList !== null) {
    nbPagesTotal = Math.ceil(establishmentList.count / numberOfLines);
  }

  return (
    <>
      {isCampaignActive(campaign) &&
        isEstablishmentCreationAvailableForRight(
          currentRight,
          organismesInspection,
          regions,
          departements
        ) && (
          <Row height="30px">
            <Button
              text={
                <>
                  Nouvel établissement <FontAwesomeIcon icon="plus" />
                </>
              }
              isReversed
              onClick={() => {
                setIsModaleOpen(true);
              }}
            />
          </Row>
        )}

      <Row height="30px" additionalClassname={classes.rightElement}>
        <SearchField
          search={searchString}
          placeholder={"Rechercher un établissement..."}
          setSearch={setSearchString}
          onEnterAction={() => {
            setEstablishmentList(null);
            setFilter(filter => {
              return {
                ...filter,
                searchByIdOrNameOrSiret: searchString,
              };
            });
            setPageIndex(0);
          }}
          additionalClassName={classes.searchField}
        />
      </Row>

      {establishmentList !== null ? (
        <>
          <Row height="24px" additionalClassname={classes.nbLines}>
            <p>{`${establishmentList.count} Ligne${
              establishmentList.count !== 1 ? "s" : ""
            }`}</p>
          </Row>

          <CompanyTable
            establishments={establishmentList.etablissements}
            sorter={filter.sortBy}
            isSortReversed={filter.ascending}
            updateSortFilter={(field, reversed) => {
              setEstablishmentList(null);
              setFilter(filter => {
                return {
                  ...filter,
                  ascending: reversed,
                  sortBy: field,
                };
              });
            }}
            referentielDepartments={departements}
            referentielRegion={regions}
          />

          <Row height="10px" />

          <Row height="24px" additionalClassname={classes.nbLines}>
            <NumberOfLinesSelector
              title={"établissements par page"}
              selected={numberOfLines}
              onChange={newNumberOfLine => {
                setPageIndex(0);
                setEstablishmentList(null);
                setNumberOfLines(newNumberOfLine);
              }}
            />
          </Row>

          {establishmentList.count > numberOfLines && (
            <PageSelection
              currentPage={pageIndex}
              setPage={newPageIndex => {
                setEstablishmentList(null);
                setPageIndex(newPageIndex);
              }}
              nbPagesTotal={nbPagesTotal}
              nbPagesToDisplay={9}
            />
          )}

          <Button
            text={
              <>
                <FontAwesomeIcon icon="download" /> Télécharger les
                établissements
              </>
            }
            onClick={async values => {
              setIsFetching(true);
              await exportExcelEtablissements(filter);
              setIsFetching(false);
            }}
            isReversed
            additionalClassname={classes.downloadButton}
            isDisabled={establishmentList.count === 0}
            isSpinnerVisible={isFetching}
          />
        </>
      ) : (
        <Spinner />
      )}

      {isModaleOpen && (
        <CompanyManagementModale
          isOpen={isModaleOpen}
          close={hasCreatedEstablishment => {
            if (hasCreatedEstablishment) {
              setEstablishmentList(null);
            }
            setIsModaleOpen(false);
          }}
          referentielDepartment={departements}
          referentielRegions={regions}
          referentielOrganismes={organismesInspection}
          adminEmail={props.adminEmailDto.email}
        />
      )}
    </>
  );
};
