export const FORM_FIELD_STYLE = {
  inputField: {
    marginBottom: "5px" as const,
  },
  centeredInput: {
    textAlign: "center" as const,
  },
  formContainer: {
    width: "70%" as const,
    margin: "50px 0" as const,
  },
};
