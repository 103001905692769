import {
  AirCombustionEmissionBilanIntrantsDto20Now,
  AirCombustionEmissionBilanSortantsDto20Now,
  AirFugitivesEmissionBilanIntrantsDto20Now,
  AirFugitivesEmissionBilanSortantsDto20Now,
} from "api/gen";
import { getValueOrZero } from "common/utils/numberUtils";
import uuid from "uuid";

export const computePercentageMoyenneTeneurIntrantsSortants = (
  intrantsSortants:
    | Array<AirFugitivesEmissionBilanIntrantsDto20Now>
    | Array<AirFugitivesEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanIntrantsDto20Now>
    | null
): number | null => {
  return intrantsSortants &&
    computeSumQuantiteIntrantsSortants(intrantsSortants) !== 0
    ? (computeSumEmissionsIntrantsSortants(intrantsSortants) * 100) /
        computeSumQuantiteIntrantsSortants(intrantsSortants)
    : null;
};

const computeSumQuantiteIntrantsSortants = (
  intrantsSortants:
    | Array<AirFugitivesEmissionBilanIntrantsDto20Now>
    | Array<AirFugitivesEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanIntrantsDto20Now>
): number =>
  intrantsSortants.reduce(
    (total, intrantSortant) => total + getValueOrZero(intrantSortant.quantite),
    0
  );

export const computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants = (
  intrants:
    | Array<AirFugitivesEmissionBilanIntrantsDto20Now>
    | Array<AirCombustionEmissionBilanIntrantsDto20Now>
    | null,
  sortants:
    | Array<AirFugitivesEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanSortantsDto20Now>
    | null,
  part: number | null
): number => {
  if (part === 0 || part == null) {
    return 0;
  }
  return (
    (computeSumEmissionsIntrantsSortants(intrants) -
      computeSumEmissionsIntrantsSortants(sortants)) /
    (part / 100)
  );
};

export const computeSumEmissionsIntrantsSortants = (
  intrantsSortants:
    | Array<AirFugitivesEmissionBilanIntrantsDto20Now>
    | Array<AirFugitivesEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanSortantsDto20Now>
    | Array<AirCombustionEmissionBilanIntrantsDto20Now>
    | null
): number =>
  intrantsSortants
    ? intrantsSortants.reduce(
        (total, intrantSortant) =>
          total + computeEmissionAnnuelleForIntrantSortant(intrantSortant),
        0
      )
    : 0;

export const computeEmissionAnnuelleForIntrantSortant = (
  intrantSortant:
    | AirFugitivesEmissionBilanIntrantsDto20Now
    | AirFugitivesEmissionBilanSortantsDto20Now
    | AirCombustionEmissionBilanSortantsDto20Now
    | AirCombustionEmissionBilanIntrantsDto20Now
): number =>
  (getValueOrZero(intrantSortant.quantite) *
    getValueOrZero(intrantSortant.teneurMoyenne)) /
  100;

export const createNewIntrantSortant = ():
  | AirFugitivesEmissionBilanIntrantsDto20Now
  | AirFugitivesEmissionBilanSortantsDto20Now
  | AirCombustionEmissionBilanSortantsDto20Now
  | AirCombustionEmissionBilanIntrantsDto20Now => ({
  id: uuid(),
  descriptif: null,
  teneurMoyenne: null,
  quantite: null,
});
