import React from "react";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import logoGazFluores from "icons/pageLogos/gas-fluore.svg";
import { GazFluoresController } from "./GazFluoresController";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const GazFluores = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Gaz fluorés",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoGazFluores,
    alt: "Fluor du tableau périodique",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Gaz fluorés",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_GAZ"]}
      render={referentials => {
        return (
          <GazFluoresController referentiel={referentials.REFERENCE_GAZ} />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(GazFluores, "GLOBAL");
