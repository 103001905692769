import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { FileHandlers } from "common/FilesLoader/types";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";
import { useCallback, useMemo } from "react";
import { Declaration19Now, MAX_YEAR, MIN_YEAR } from "../declarationHooks19Now";

export const usePgsFilesHandler19Now = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadPgsUsingPOST(
        annee,
        etablissement,
        file
      )) as Declaration19Now;
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deletePgsUsingDELETE(
        annee,
        etablissement,
        file
      )) as Declaration19Now;
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration19Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration19Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
