import React, { ReactElement } from "react";
import { InfosGeneralesAlcDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import AlcVerifieEmissionsCo2e from "./AlcVerifieEmissionsCo2e";
import AlcVerifieVentilationCombustible from "./AlcVerifieVentilationCombustible";
import AlcVerifieGenericInfos from "./AlcVerifieGenericInfos";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
});

interface AlcVerifieInfosGeneralesProps {
  infosGeneralesAlcDto: InfosGeneralesAlcDto;
}

const AlcVerifieInfosGenerales = ({
  infosGeneralesAlcDto,
}: AlcVerifieInfosGeneralesProps): ReactElement => {
  const classes = useStyles();
  const {
    yearToEmissionsTotalesCO2eMap,
    yearToResteVentilationMap,
  } = infosGeneralesAlcDto;

  return (
    <div className={classes.container}>
      <SubSectionTitle>Informations générales de l'ALC</SubSectionTitle>

      <AlcVerifieGenericInfos infosGeneralesAlcDto={infosGeneralesAlcDto} />

      <AlcVerifieEmissionsCo2e
        yearToEmissionsTotalesCO2eMap={yearToEmissionsTotalesCO2eMap}
      />

      <AlcVerifieVentilationCombustible
        yearToResteVentilationMap={yearToResteVentilationMap}
      />
    </div>
  );
};

export default AlcVerifieInfosGenerales;
