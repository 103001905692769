import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";

const useStyles = makeStyles({
  link: {
    color: PINK,
  },
});

export const LocationHelper = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      <a
        href={`${process.env.PUBLIC_URL}/download/NoticeGeoportail.pdf`}
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        Aide
      </a>{" "}
      pour obtenir les coordonnées de l'établissement à partir du site{" "}
      <a
        href="https://www.geoportail.gouv.fr/"
        target="_blank"
        className={classes.link}
        rel="noopener noreferrer"
      >
        Géoportail
      </a>
    </>
  );
};
