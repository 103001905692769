import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemOrganismeAccrediteDto } from "api/gen";

export type AccreditedOrganismInterveningType = OptionPropsWithObject<ReferenceItemOrganismeAccrediteDto | null>;

interface CommonPoussiereData {
  hasShownNotWeakRiskOnly: boolean;
  accreditedOrganismIntervening: AccreditedOrganismInterveningType | null;
  precision: string | null;
  nbTotalPrelevement: number | null;
}

export interface PoussiereAlveolaireQuartzTridimiteData
  extends CommonPoussiereData {
  nbGEHLessThan10PercentVLEP: number | null;
}

export interface PoussiereAlveolaireTotaleData extends CommonPoussiereData {
  minimalValue: number | null;
  maximalValue: number | null;
  nbGEHBiggerThanVLEP: number | null;
}

export interface MesureEmpoussierageData {
  wasRiskEvaluationRealised: boolean;
  nbGEH: number | null;
  poussiereAlveolaireQuartzTridimite: PoussiereAlveolaireQuartzTridimiteData | null;
  poussiereAlveolaireTotale: PoussiereAlveolaireTotaleData | null;
}

export const otherOrganismAccreditedOption: AccreditedOrganismInterveningType = {
  label: "Autre organisme",
  value: -1,
  object: null,
};

export const wasOtherOrganismSelected = (
  interveningOrganism: AccreditedOrganismInterveningType | null
) =>
  interveningOrganism !== null &&
  interveningOrganism.object === otherOrganismAccreditedOption.object;
