import { LegacySubInstallationElement } from "../../../utils/types";
import { InArray, InModale } from "common/form/utils";
import { CombustibleInArray } from "../../BlocCombustibles/utils/types";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import {
  AirCombustionEmissionBilanIntrantsDto20Now,
  AirCombustionEmissionBilanSortantsDto20Now,
  AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { MethodCombustion } from "./selectPossibleValues";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 *
 * when suppressing, be careful, the export is because this type is used in a method. most likely it will have to be modified to fit the type from back, in the case it isn't exported.
 *
 * TODO to suppress properly : https://dl.polyconseil.fr/jira/browse/GEREP-997
 *
 * also note the type it replaces was exported as a Nullable
 */
export interface LegacyEmissions extends LegacySubInstallationElement {
  substance: ReferenceItemPolluantDto;
  methods: MethodCombustion;
  epuration: boolean;
  nature: string;
  rendement: number;
  emissionsAnnuelles: number;
  appareils: AppareilInArray[];
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyFacteurEmission extends LegacyEmissions {
  combustible: CombustibleInArray;
  referenceCombustible: string;
  consoAnnuelle: number;
  unite: string;
  facteur: number;
  ecart: string;
  provenance: string;
  facteurOxydation: number;
  provenanceFacteurOxydation: string;
}

export type FacteurEmissionInArray = InArray<LegacyFacteurEmission>;
export type FacteurEmissionInModale = InModale<LegacyFacteurEmission>;

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMesureEmission extends LegacyEmissions {
  heures: number;
  debit: number;
  continuDebit: boolean;
  frequenceDebit: number;
  concentrationDebit: number;
  concentrationDebitUnit: AirCombustionEmissionMesureDebitDto2023ConcentrationUniteEnum;
  continuConcentration: boolean;
  frequenceConcentration: number;
  biomasseMesure: number;
}

export type MesureEmissionInArray = InArray<LegacyMesureEmission>;
export type MesureEmissionInModale = InModale<LegacyMesureEmission>;

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMatiereEmission extends LegacyEmissions {
  intrants: Array<AirCombustionEmissionBilanIntrantsDto20Now>;
  sortants: Array<AirCombustionEmissionBilanSortantsDto20Now>;
  elementProps: ReferenceItemPolluantElementDto;
  elementName: string;
  partElement: number;
  biomasseMatiere: number;
}

export type MatiereEmissionInArray = InArray<LegacyMatiereEmission>;
export type MatiereEmissionInModale = InModale<LegacyMatiereEmission>;

export type EmissionsInModale = InModale<LegacyMesureEmission> &
  InModale<LegacyFacteurEmission> &
  InModale<LegacyMatiereEmission>;

export type OneOfEmissionsInModale =
  | InModale<LegacyMesureEmission>
  | InModale<LegacyFacteurEmission>
  | InModale<LegacyMatiereEmission>;

export type EmissionsInArray =
  | MesureEmissionInArray
  | FacteurEmissionInArray
  | MatiereEmissionInArray;

export const emissionFieldMatcher = fieldMatcherBuilder<
  EmissionsInModale
>().build({
  substance: "substance",
  methods: "methods",
  epuration: "epuration",
  nature: "nature",
  rendement: "rendement",
  emissionsAnnuelles: "emissionsAnnuelles",
  appareils: "appareils",
  combustible: "combustible",
  referenceCombustible: "referenceCombustible",
  consoAnnuelle: "consoAnnuelle",
  unite: "unite",
  facteur: "facteur",
  ecart: "ecart",
  provenance: "provenance",
  facteurOxydation: "facteurOxydation",
  provenanceFacteurOxydation: "provenanceFacteurOxydation",
  heures: "heures",
  debit: "debit",
  continuDebit: "continuDebit",
  frequenceDebit: "frequenceDebit",
  concentrationDebit: "concentrationDebit",
  concentrationDebitUnit: "concentrationDebitUnit",
  continuConcentration: "continuConcentration",
  frequenceConcentration: "frequenceConcentration",
  biomasseMesure: "biomasseMesure",
  intrants: "intrants",
  sortants: "sortants",
  elementProps: "elementProps",
  elementName: "elementName",
  partElement: "partElement",
  biomasseMatiere: "biomasseMatiere",
});

export const intrantSortantFieldMatcher = fieldMatcherBuilder<
  | AirCombustionEmissionBilanIntrantsDto20Now
  | AirCombustionEmissionBilanSortantsDto20Now
>().build({
  descriptif: "descriptif",
  quantite: "quantite",
  teneurMoyenne: "teneurMoyenne",
});
