import { useDeclaration } from "../../genericHooks";
import {
  TypeActiviteGlobal19Now,
  TypeActiviteQuota19Now,
} from "../../../../from19/toNow/versionedElements/declarationHooks19Now";
import {
  TypeActiviteGlobal20Now,
  TypeActiviteQuota20Now,
} from "../../../../from20/toNow/versionedElements/declarationHooks20Now";
import { Declaration1919 } from "../../../../from19/to19/versionedElements/declarationHooks1919";
import { Declaration2020 } from "../../../../from20/to20/versionedElements/declarationHooks2020";
import { Declaration21Now } from "../../../../from21/toNow/versionedElements/declarationHooks21Now";

export const useCorrectYearTypeActiviteGlobal19Now = (): TypeActiviteGlobal19Now => {
  const declaration = useDeclaration();
  const year = declaration.annee;

  switch (year) {
    case 2018:
      throw Error("shouldn't call this in 2018");
    case 2019:
      return (declaration as Declaration1919).body.typeActivite;
    case 2020:
      return (declaration as Declaration2020).body.typeActivite;
    default:
      // by default, we want to use the most recent year, this way no modification of code is needed if new campaign needs no change compared to previous one
      return (declaration as Declaration21Now).body.typeActivite.global;
  }
};

export const useCorrectYearTypeActiviteGlobal20Now = (): TypeActiviteGlobal20Now => {
  const declaration = useDeclaration();
  const year = declaration.annee;

  switch (year) {
    case 2018:
      throw Error("shouldn't call this in 2018");
    case 2019:
      throw Error("shouldn't call this in 2019");
    case 2020:
      return (declaration as Declaration2020).body.typeActivite;
    default:
      // by default, we want to use the most recent year, this way no modification of code is needed if new campaign needs no change compared to previous one
      return (declaration as Declaration21Now).body.typeActivite.global;
  }
};

export const useCorrectYearTypeActiviteQuotas19Now = (): TypeActiviteQuota19Now => {
  const declaration = useDeclaration();
  const year = declaration.annee;

  switch (year) {
    case 2018:
      throw Error("shouldn't call this in 2018");
    case 2019:
      return (declaration as Declaration1919).body.typeActivite;
    case 2020:
      return (declaration as Declaration2020).body.typeActivite;
    default:
      // by default, we want to use the most recent year, this way no modification of code is needed if new campaign needs no change compared to previous one
      return (declaration as Declaration21Now).body.typeActivite.quotas;
  }
};

export const useCorrectYearTypeActiviteQuotas20Now = (): TypeActiviteQuota20Now => {
  const declaration = useDeclaration();
  const year = declaration.annee;

  switch (year) {
    case 2018:
      throw Error("shouldn't call this in 2018");
    case 2019:
      throw Error("shouldn't call this in 2019");
    case 2020:
      return (declaration as Declaration2020).body.typeActivite;
    default:
      // by default, we want to use the most recent year, this way no modification of code is needed if new campaign needs no change compared to previous one
      return (declaration as Declaration21Now).body.typeActivite.quotas;
  }
};
