import React, { ReactElement } from "react";

import Button from "common/button";
import { useConfirmationModaleWithReason } from "common/modale/hooks";
import {
  generateDeclarationTransitionWarningMessage,
  IN_REVIEW,
} from "common/messages/dashboardMessage";
import {
  useBasicDeclarationHandlers21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../versionedElements/declarationHooks21Now";
import { QuotaWorkflowTarget } from "common/utils/types";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";

const QuotaReviewButton = ({
  workflowTarget,
  path,
  text,
}: {
  workflowTarget: QuotaWorkflowTarget;
  text: string;
  path: string;
}): ReactElement => {
  const openConfirmationModaleWithReason = useConfirmationModaleWithReason();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const { review } = useDeclarationStateHandlers21Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const { cancelValidate } = useBasicDeclarationHandlers21Now();

  return (
    <Button
      text={text}
      onClick={() => {
        openConfirmationModaleWithReason(
          generateDeclarationTransitionWarningMessage(
            workflowTarget,
            IN_REVIEW
          ),
          "Votre message",
          async message => {
            const reviewCall = async () => {
              await review([workflowTarget], message ? message : "");
              if (isPathValidatedInDeclaration(path)) {
                await cancelValidate(path);
              }
              return true;
            };
            return triggerSpinner(reviewCall());
          }
        );
      }}
      isSpinnerVisible={isSpinnerVisible}
    />
  );
};

export default QuotaReviewButton;
