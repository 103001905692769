// Declare the range for all generic hooks we'll call for this version.
import { Declaration2323 } from "../../from23/toNow/versionedElements/declarationHooks23Now";
import {
  DeclarationBodyDto2323,
  DeclarationSectionsDto2323,
  TypeActiviteDto23Now,
  TypeActiviteQuotaDto2122,
  DeclarationDto2121,
  DeclarationDto2222,
  DeclarationBodyDto2121,
  DeclarationBodyDto2222,
  DeclarationSectionsDto2121,
  DeclarationSectionsDto2222,
} from "../../../../api/gen";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
} from "../../DeclarationApiContext/utils/genericHooks";
import { useDeclarationHelpers19Now } from "../../from19/toNow/versionedElements/declarationHooks19Now";
import { BasicDeclarationHandlers } from "../../DeclarationApiContext/utils/types";
import { useCallback } from "react";

export const MIN_YEAR = 2021;
export const MAX_YEAR = 2023;

// Define all the DTO types for this version.
export type Declaration2123 =
  | DeclarationDto2121
  | DeclarationDto2222
  | Declaration2323;
export type DeclarationBody2123 =
  | DeclarationBodyDto2121
  | DeclarationBodyDto2222
  | DeclarationBodyDto2323;
export type DeclarationSections2123 =
  | DeclarationSectionsDto2121
  | DeclarationSectionsDto2222
  | DeclarationSectionsDto2323;
export type TypeActiviteQuota2123 =
  | TypeActiviteQuotaDto2122
  | TypeActiviteDto23Now;
export const useDeclaration2123 = (
  shouldThrowIfWrongYear?: boolean
): Declaration2123 => {
  return useDeclaration<Declaration2123>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationHelpers2123 = useDeclarationHelpers19Now;
export const useBasicDeclarationHandlers2123 = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration2123> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration2123): DeclarationSections2123 =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration2123>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};
