import * as Yup from "yup";
import {
  commonArrayOfStringFields,
  commonBooleanFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { Information } from "../utils/types";
import {
  requiredMessage,
  solvantsConsommationAnnuelle2Message,
} from "common/declarant/formik/formikMessages";
import { AirSolvantsInformationDtoDerogationEnum } from "api/gen";

export const isPgsObligatoire = (value: number | null): boolean => {
  return value !== null && value >= 30;
};

export const chapterVIEDSubPartActivated = (type: boolean | null): boolean => {
  return !!type;
};

export const installConformeSubPartActivated = (
  type: boolean | null
): boolean => {
  return !type;
};

export const singleSolvantOverwriteDependantFields = (
  values: Information
): Information => {
  if (!chapterVIEDSubPartActivated(values.ied)) {
    values.activites = [];
    values.conforme = false;
    values.derogation = null;
  }
  if (!installConformeSubPartActivated(values.conforme)) {
    values.derogation = null;
  }
  return values;
};

export const informationValidationSchema = Yup.object().shape({
  consommation: commonPositiveNumberFields.moreThan(
    0,
    solvantsConsommationAnnuelle2Message
  ),
  ied: commonBooleanFields,
  activites: Yup.array().when("ied", {
    is: (type: boolean | null) => chapterVIEDSubPartActivated(type || null),
    then: commonArrayOfStringFields,
    otherwise: Yup.array().of(Yup.string().nullable()),
  }),
  conforme: Yup.boolean().when("ied", {
    is: (type: boolean | null) => chapterVIEDSubPartActivated(type || null),
    then: commonBooleanFields,
    otherwise: Yup.boolean().nullable(),
  }),
  derogation: Yup.mixed<AirSolvantsInformationDtoDerogationEnum>().when(
    ["conforme", "ied"],
    {
      is: (isConforme: boolean | null, isIed: boolean | null) =>
        installConformeSubPartActivated(isConforme || null) &&
        chapterVIEDSubPartActivated(isIed || null),
      then: Yup.mixed<AirSolvantsInformationDtoDerogationEnum>().required(
        requiredMessage
      ),
      otherwise: Yup.mixed<
        AirSolvantsInformationDtoDerogationEnum
      >().nullable(),
    }
  ),
  danger: commonBooleanFields,
  sme: commonBooleanFields,
});
