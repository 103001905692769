import { ActiviteTransformationType } from "./types";
import { Errors } from "common/form/utils";
import {
  computeRequiredPositiveNumberErrorMessage,
  computeRequiredStringErrorMessage,
  filterOutNullishValues,
} from "common/declarant/formik/formikValidationHelper";
import {
  codePostalMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Nullable } from "common/utils/types";

export const validateFactory = (
  factoryToValidate: Nullable<ActiviteTransformationType>
): Errors<ActiviteTransformationType> => {
  const errors: Errors<ActiviteTransformationType> = {};

  const {
    raisonSocialeUsine,
    codePostal,
    ville,
    paysID,
    distanceUsineCarriere,
  } = factoryToValidate;

  errors.raisonSocialeUsine = computeRequiredStringErrorMessage(
    raisonSocialeUsine
  );

  errors.codePostal = computeRequiredStringErrorMessage(codePostal);

  if (!paysID) {
    if (codePostal && !/^[0-9]{5}$/.test(codePostal)) {
      errors.codePostal = codePostalMessage;
    }
  }

  if (typeof ville === "string") {
    errors.ville = computeRequiredStringErrorMessage(ville);
  } else if (!ville) {
    errors.ville = requiredMessage;
  }

  if (!paysID) {
    errors.paysID = requiredMessage;
  }

  errors.distanceUsineCarriere = computeRequiredPositiveNumberErrorMessage(
    distanceUsineCarriere
  );

  return filterOutNullishValues(errors);
};
