import React, { useCallback, useEffect, useMemo } from "react";
import { cloneDeep } from "lodash";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { createCSVImportButton } from "common/csvImport";
import HRule from "common/presentational/HRule";
import { makeStyles } from "@material-ui/styles";
import { PINK, SECTION_TITLE_GREY, WASTE_BROWSE_MESSAGE_WIDTH } from "theme";
import Row from "common/presentational/Row";
import Button from "common/button";
import WasteProductionArray from "./WasteProductionArray";
import {
  ProductedWasteImport,
  ProductedWasteProps,
  ProductedWasteStorage,
} from "./types";
import { filterPolluantProductionCSVArray } from "./utils";
import { shouldDisplayProductionArray } from "../../utils/utils";
import { Nullable } from "common/utils/types";
import InfoBulle from "common/infoBulle/InfoBulle";
import {
  PATH_DECHET_PRODUCTION_DANGEREUX,
  PATH_DECHET_PRODUCTION_NON_DANGEREUX,
} from "common/path/path18Now";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { dechetsAtLeastOneMessage } from "common/declarant/formik/formikMessages";
import { useConfirmationModale } from "common/modale/hooks";
import MessageInfoField from "common/form/MessageInfoField";
import { findElementMatchingTemplate } from "common/utils/methods";
import { DechetProduitDto23NowLieuOperationEnum } from "api/gen";
import FormikBlocFullContext24Now from "../../../versionedElements/FormikBlocFullContext24Now";
import TDImportButton from "../../utils/TDImportButton";
import { Declaration24Now } from "../../../versionedElements/declarationHooks24Now";

const useStyles = makeStyles({
  headertitle5: {
    color: "#6b6b6b",
    textTransform: "uppercase",
  },
  par: {
    color: "#4c4c4c",
    "&>li": {
      marginLeft: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  tooltip: {
    marginTop: "5px",
  },
  tooltipList: {
    "&>li": {
      marginLeft: "10px",
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
  link: {
    color: PINK,
  },
  text: {
    color: SECTION_TITLE_GREY,
    padding: "10px 20px 25px 20px",
  },
  message: {
    "&>div": {
      width: WASTE_BROWSE_MESSAGE_WIDTH,
    },
  },
  tdButton: {
    marginRight: "auto",
  },
});

export const PolluantProduction = ({
  formRef,
  wasteData,
  validationPath,
  importWastes,
  deleteWaste,
  deleteAllWastes,
  openModal,
  reset,
  computedProductionDto,
  computedReceptionDto,
  productionSaveCompletion,
  hasChanges,
  onChange,
  onUnMount,
}: ProductedWasteProps): React.ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "85%",
    formPrefix: "bloc-déchets",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const CSVImportButton = createCSVImportButton<
    Nullable<ProductedWasteImport>
  >();

  const compareLocalisationFunction = useCallback(
    (a: ProductedWasteStorage, b: ProductedWasteStorage): number => {
      if (
        a.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE &&
        b.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE
      ) {
        return 0;
      }

      if (
        a.data.lieuOperation ===
          DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT &&
        b.data.lieuOperation ===
          DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT &&
        a.data.departementID &&
        b.data.departementID &&
        a.data.departementID !== b.data.departementID
      ) {
        const aDepartement = findElementMatchingTemplate(
          { uuid: a.data.departementID },
          wasteData.referentiels.departements
        );
        const bDepartement = findElementMatchingTemplate(
          { uuid: b.data.departementID },
          wasteData.referentiels.departements
        );
        if (aDepartement && bDepartement) {
          return aDepartement.numero < bDepartement.numero ? -1 : 1;
        }
      }

      if (
        a.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS &&
        b.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS &&
        a.data.paysID &&
        b.data.paysID &&
        a.data.paysID !== b.data.paysID
      ) {
        const aPays = findElementMatchingTemplate(
          { uuid: a.data.paysID },
          wasteData.referentiels.pays
        );
        const bPays = findElementMatchingTemplate(
          { uuid: b.data.paysID },
          wasteData.referentiels.pays
        );
        if (aPays && bPays) {
          return aPays.designation < bPays.designation ? -1 : 1;
        }
      }
      if (
        b.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE ||
        (b.data.lieuOperation ===
          DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT &&
          a.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS)
      ) {
        return 1;
      }

      if (
        a.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE ||
        (a.data.lieuOperation ===
          DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT &&
          b.data.lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS)
      ) {
        return -1;
      }
      return 0;
    },
    [wasteData.referentiels.departements, wasteData.referentiels.pays]
  );

  const compareOperationEliminationFunction = useCallback(
    (a: ProductedWasteStorage, b: ProductedWasteStorage): number => {
      if (a.data.operationElimination && b.data.operationElimination) {
        const aChar = a.data.operationElimination[0];
        const bChar = b.data.operationElimination[0];
        const aInt = parseInt(a.data.operationElimination.substr(1));
        const bInt = parseInt(b.data.operationElimination.substr(1));
        if (aChar !== bChar) {
          return aChar < bChar ? -1 : 1;
        }
        if (aInt !== bInt) {
          return aInt < bInt ? -1 : 1;
        }
      }
      return 0;
    },
    []
  );

  const compareFunction = useCallback(
    (a: ProductedWasteStorage, b: ProductedWasteStorage): number => {
      if (a.data.codeID && b.data.codeID && a.data.codeID !== b.data.codeID) {
        const aDechet = findElementMatchingTemplate(
          { uuid: a.data.codeID },
          wasteData.referentiels.polluants
        );
        const bDechet = findElementMatchingTemplate(
          { uuid: b.data.codeID },
          wasteData.referentiels.polluants
        );
        if (aDechet && bDechet) {
          return aDechet.codeDechet < bDechet.codeDechet ? -1 : 1;
        }
      }
      const operationSorting = compareOperationEliminationFunction(a, b);
      if (operationSorting !== 0) {
        return operationSorting;
      }
      return compareLocalisationFunction(a, b);
    },
    [
      compareLocalisationFunction,
      compareOperationEliminationFunction,
      wasteData.referentiels.polluants,
    ]
  );

  const sortedArrayElem = useMemo(() => {
    return cloneDeep(wasteData.tempData.production.container).sort(
      compareFunction
    );
  }, [compareFunction, wasteData.tempData.production.container]);

  useEffect(() => {
    return () => {
      onUnMount();
    };
  }, [onUnMount]);

  const tooltipContent =
    "Les lignes pour lesquelles tous les champs sont identiques sont fusionnées en une seule ligne. La quantité est égale à la somme de toutes les lignes fusionnées.";
  const updateHandler = (declaration: Declaration24Now) => {
    declaration.body.sections.dechets.production = computedProductionDto();
    return declaration;
  };
  const updateHandlerReception = (declaration: Declaration24Now) => {
    declaration.body.sections.dechets.reception = computedReceptionDto();
    return declaration;
  };

  return (
    <FormikBlocFullContext24Now
      validationTitle={"FUSIONNER ET VALIDER"}
      validationToolTipContent={tooltipContent}
      formikRef={formRef.formikRef}
      hasChanges={formRef.hasChanges}
      setHasChanges={formRef.setHasChanges}
      initialValues={wasteData.tempData.production.formInfo}
      preventSubmitWithErrors={values => {
        if (
          shouldDisplayProductionArray(values) &&
          wasteData.tempData.production.container.length === 0
        ) {
          return {
            atLeastOneError: dechetsAtLeastOneMessage,
          };
        }
        return {};
      }}
      title={"Pavé Déchets Sortants"}
      pathToValidate={validationPath}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration => updateHandler(declaration)}
      updateCompletion={productionSaveCompletion}
      onChange={onChange}
      shouldFetchInCaseOfError={true}
      renderContent={({ values, errors }, shouldDisabledFields) => {
        return (
          <>
            <div className={classes.text}>
              <p>Le tableau ci-dessous doit être renseigné :</p>
              <li>
                dès lors que la quantité totale de déchets dangereux générés ou
                expédiés par l'établissement est supérieure à 2 t/an ;
              </li>
              <li>
                dès lors que la quantité totale de déchets non dangereux générés
                par l'établissement est supérieure à 2 000 t/an (ne concerne que
                les établissements E-PRTR).
              </li>
            </div>
            <CheckBox
              name="dangerousWasteOver2000kg"
              label="La production totale de déchets dangereux de l'établissement dépasse 2 t/an"
              disabled={shouldDisabledFields}
              commentPath={PATH_DECHET_PRODUCTION_DANGEREUX}
            />

            {wasteData.tempData.production.formInfo.isEPRTR && (
              <CheckBox
                name="atLeastOneFacilityWasteOver2Mkg"
                label="L'établissement est visé par le règlement E-PRTR et la production totale de déchets non dangereux dépasse 2 000 t/an"
                disabled={shouldDisabledFields}
                commentPath={PATH_DECHET_PRODUCTION_NON_DANGEREUX}
              />
            )}

            {shouldDisplayProductionArray(values) && (
              <>
                <HRule />
                <div>
                  <h5 className={classes.headertitle5}>
                    Tableau récapitulatif des déchets sortants
                  </h5>
                  <Row />

                  <ul className={classes.par}>
                    Ce tableau peut être rempli de trois manières :
                    <li>
                      Pré-remplissage et mise à jour à partir de l’API
                      Trackdéchets en cliquant sur le bouton « Récupérer les
                      données à partir de Trackdéchets »
                    </li>
                    Pour des raisons de confidentialité des données, le service
                    de téléchargement n’est possible que si le SIREN du compte
                    utilisateur exploitant (enregistré lors de la création du
                    compte sur le{" "}
                    <a
                      href={`https://authentification.din.developpement-durable.gouv.fr/authSAML/aide.do`}
                      className={classes.link}
                    >
                      portail Cerbère
                    </a>
                    ) dispose des 9 premiers chiffres du code SIRET de la
                    déclaration annuelle GEREP. Si les déchets ne se chargent
                    pas, vous devez ajouter les déchets par l'une des deux
                    autres méthodes ci-dessous.
                    <li>
                      Ligne par ligne en cliquant sur 'AJOUTER UN DÉCHET'.
                    </li>
                    <li>
                      {" "}
                      Par un formulaire de saisie en masse depuis un tableur
                      permettant l'insertion de plusieurs lignes préalablement
                      saisies dans ce tableur avec le bouton 'PARCOURIR'. Le
                      tableur à importer doit être élaboré à partir de{" "}
                      <a
                        href={`${process.env.PUBLIC_URL}/download/GabaritDechetsProduction.csv`}
                        className={classes.link}
                      >
                        ce gabarit
                      </a>
                      &nbsp;(ouvrir le document sous libreoffice permettra
                      d'éviter les erreurs d'encodage des caractères spéciaux).
                      Veuillez lire&nbsp;
                      <a
                        href={`${process.env.PUBLIC_URL}/download/NoticeGabaritProductionExpéditionDéchets.pdf`}
                        className={classes.link}
                        target={"_blank"}
                        rel="noopener noreferrer" // Using target="_blank" without rel="noopener noreferrer" is a security risk: see https://mathiasbynens.github.io/rel-noopener
                      >
                        la documentation
                      </a>
                      &nbsp;pour plus de précisions sur le mode opératoire.
                    </li>
                    Le code pour le déchet produit et expédié est renseigné
                    selon la nomenclature figurant à l'annexe de la décision
                    2000/532/CE. Le code pour l'élimination ou la valorisation
                    du déchet est renseigné selon l'annexe IV de l'arrêté du 31
                    janvier 2008 modifié et correspond à l'opération réalisée
                    par la société vers laquelle est expédié le déchet.
                  </ul>
                </div>

                <MessageInfoField
                  additionalClassname={classes.message}
                  message="Seul le format csv est accepté, merci de ne pas changer le format du gabarit."
                />

                <Row
                  additionalStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <TDImportButton
                    methodUpdate={(
                      tdController,
                      annee,
                      etablissement,
                      options
                    ) =>
                      tdController.updateDeclarationProductedWasteUsingGET(
                        annee,
                        etablissement,
                        options
                      )
                    }
                    additionalClassname={classes.tdButton}
                    isDisabled={shouldDisabledFields}
                    updateHandlerProduit={updateHandler}
                    updateHandlerRecu={updateHandlerReception}
                  />
                  <InfoBulle
                    additionalClassName={classes.tooltip}
                    content={
                      <ul className={classes.tooltipList}>
                        <li>
                          1. Télécharger et enregistrer le 'Gabarit XLS
                          production déchets' dans votre ordinateur.
                        </li>
                        <li>
                          <ul className={classes.tooltipList}>
                            2. Remplir le gabarit avec les données à déclarer :
                            <li>
                              Code déchet : Code du déchet produit, figurant à
                              l'annexe II de l'article R. 541-8 du code de
                              l'environnement. Code à 6 chiffres à saisir avec
                              espace (ex : 02 03 01).
                            </li>
                            <li>
                              Quantité produite (t/an) : Masse annuelle du
                              déchet produits, exprimée en tonne.
                            </li>
                            <li>
                              Méthode : La méthode de calcul de la quantité (M,
                              C, E ou Pesage).
                            </li>
                            <li>
                              Référence méthode : Référence de la méthode de
                              calcul de la quantité (INT, PER, NRO, ALT, MRC ou
                              AUT pour la méthode M et INT, PER, NRO, BMA, CSS
                              ou AUT pour la méthode C).
                            </li>
                            <li>
                              Précision référence méthode : Précision sur cette
                              méthode.
                            </li>
                            <li>
                              Opération de traitement : Code de l'opération
                              d'élimination ou de valorisation figurant dans les
                              annexes II A et II B de la directive 2006/12/CE du
                              5 avril 2006 relative aux déchets (ex : D1). A
                              noter: les codes D7 et D11 ne peuvent pas être
                              utilisés car ils correspondent à des opérations
                              interdites par la législation européenne et les
                              conventions internationales dont la France est
                              signataire.
                            </li>
                            <li>
                              Nom du site récepteur : Le nom du site récepteur.
                            </li>
                            <li>
                              Adresse du site récepteur : L'adresse du site
                              récepteur.
                            </li>
                            <li>
                              Département (si France) : Numéro du département de
                              destination du déchets s'il n'est pas traité sur
                              site (ex : 60).
                            </li>
                            <li>
                              Pays (si hors France) : Libellé du pays de
                              destination du déchets s'il est traité à
                              l'étranger (ex : Belgique, Italie, Allemagne,...).
                            </li>
                            <li>
                              Etablissement élimination (si hors France) : Le
                              nom de l'établissement d'élimination lorsque le
                              lieu d'opération d'élimination n'est pas en
                              France.
                            </li>
                            <li>
                              Adresse établissement élimination (si hors France)
                              : L'adresse de l'établissement d'élimination
                              lorsque le lieu d'opération d'élimination n'est
                              pas en France.
                            </li>
                            <li>
                              Adresse établissement réceptionnant (si hors
                              France) : L'adresse de l'établissement
                              réceptionnant les déchets lorsque le lieu
                              d'opération d'élimination n'est pas en France.
                            </li>
                            <li>
                              Numéro de de notification : Le numéro de
                              notification lorsque le lieu d'opération
                              d'élimination n'est pas en France.
                            </li>
                          </ul>
                        </li>
                        <li>3. Enregistrer le gabarit une fois rempli.</li>
                        <li>
                          4. Joindre le gabarit à la déclaration en cliquant sur
                          le bouton « Parcourir ... » du tableau production de
                          déchets. Rechercher le gabarit enregistré sur
                          l'ordinateur puis cliquer sur le bouton « Envoyer » Le
                          site de télé-déclaration vérifie alors automatiquement
                          que le gabarit a bien été rempli conformément aux
                          indications détaillées au point 2 (vérification du
                          format des données) et affiche, le cas échéant, les
                          erreurs repérées. Attention: les lignes erronées ne
                          seront pas intégrées dans la déclaration. La procédure
                          conseillée dans ce cas est la suivante: ouvrir le
                          gabarit enregistré dans votre ordinateur et corriger
                          les erreurs recommencer les étapes 3 et 4 décrites
                          ci-dessus (le site indiquera alors la présence de
                          doublons pour les lignes ayant déjà été intégrées lors
                          de la précédente intégration).
                        </li>
                        <li>
                          5. Pour poursuivre la déclaration cliquer sur le
                          bouton « VALIDER» pour passer au tableau suivant.
                        </li>
                      </ul>
                    }
                  />
                  <CSVImportButton
                    filterAction={filterPolluantProductionCSVArray}
                    handleImportedData={(
                      wastes: Nullable<ProductedWasteImport>[],
                      message: string | null
                    ) => {
                      importWastes(wastes, message);
                    }}
                    isDisabled={shouldDisabledFields}
                    headerLinesCount={1}
                  />

                  <div>&nbsp;</div>
                  <Button
                    text="Ajouter un déchet"
                    onClick={() => openModal(null)}
                    additionalStyle={{
                      textTransform: "uppercase",
                      marginLeft: "10px",
                    }}
                    type="button"
                    isDisabled={shouldDisabledFields}
                  />
                </Row>

                <Row />

                <WasteProductionArray
                  isBlocValidated={shouldDisabledFields}
                  wasteContainer={sortedArrayElem}
                  deleteWaste={deleteWaste}
                  openModal={openModal}
                  referentiels={wasteData.referentiels}
                />

                <Row />

                <Row
                  additionalStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    text="TOUT SUPPRIMER"
                    isDisabled={shouldDisabledFields}
                    isReversed
                    onClick={() => {
                      openConfirmationModale(
                        "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                        () => deleteAllWastes()
                      );
                    }}
                  />
                </Row>
                <Row />

                <ErrorDisplayer message={errors.atLeastOneError} />
              </>
            )}
          </>
        );
      }}
      hasFormChanges={hasChanges}
      cancelAction={reset}
    />
  );
};
