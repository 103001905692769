import React, { useState } from "react";
import _ from "lodash";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import Row from "common/presentational/Row";
import {
  isBlocRejetEauActive,
  isOver50000Treshold,
  updateHandlerPageEau,
} from "./utils";
import BlocInfoGenerales from "./BlocInfoGenerales";
import { BlocInfoGeneralesFormValues } from "./BlocInfoGenerales/types";
import { dtoToBlocValues as dtoToBlocInfoGeneralesValues } from "./BlocInfoGenerales/converter";
import BlocPrelevementEau from "./BlocPrelevementEau";
import { BlocPrelevementEauFormValues24Now } from "./BlocPrelevementEau/types";
import BlocRejetPolluant from "./BlocRejetPolluant";
import { dtoToPolluantsInArray } from "./BlocRejetPolluant/converter";
import BlocRejetEau from "./BlocRejetEau";
import { BlocRejetEauFormValues } from "./BlocRejetEau/types";
import {
  ReferenceNormeEauDto,
  ReferencePolluantDto,
  ReferenceSandreDto,
  ReferenceStationEpurationDto,
} from "api/gen";
import { selectPossibleValues } from "./BlocRejetPolluant/selectPossibleValues";
import { dtoToBlocValues } from "./BlocPrelevementEau/converter";
import { PolluantInArray } from "./BlocRejetPolluant/types";
import {
  PATH_EAU,
  PATH_EAU_INFOGENERALE,
  PATH_EAU_PRELEVEMENT,
  PATH_EAU_REJETEAU,
  PATH_EAU_REJETPOLLUANT,
} from "common/path/path18Now";
import {
  useDeclaration24Now,
  useDeclarationHelpers24Now,
} from "../../toNow/versionedElements/declarationHooks24Now";
import GlobalFormActionFullContext24Now from "../../toNow/versionedElements/GlobalFormActionFullContext24Now";

interface GeneralFormProps {
  referentialPolluants: ReferencePolluantDto;
  referentialNormes: ReferenceNormeEauDto;
  referentialSandres: ReferenceSandreDto;
  referentialStationsEpuration: ReferenceStationEpurationDto;
}

const GeneralForm = ({
  referentialPolluants,
  referentialNormes,
  referentialSandres,
  referentialStationsEpuration,
}: GeneralFormProps): JSX.Element => {
  const declaration = useDeclaration24Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();

  // BlocInfoGenerales
  const blocInfoGeneralesValuesFromDto = dtoToBlocInfoGeneralesValues(
    declaration.body.sections.eau.infoGenerales
  );
  const [blocInfoGeneralesValues, setBlocInfoGeneralesValues] = useState<
    BlocInfoGeneralesFormValues
  >(blocInfoGeneralesValuesFromDto);
  const blocInfoGeneralesHasChanges = !_.isEqual(
    blocInfoGeneralesValuesFromDto,
    blocInfoGeneralesValues
  );

  // BlocPrelevementEau
  const blocPrelevementEauProps = useFormikBloc<
    BlocPrelevementEauFormValues24Now
  >();

  // BlocRejetPolluants
  const selectPossibleValuesRejetPolluant = selectPossibleValues(
    referentialPolluants,
    referentialNormes
  );
  const polluantsInArrayInitialValueFromDto = dtoToPolluantsInArray(
    declaration.body.sections.eau.rejetPolluants,
    referentialPolluants,
    referentialNormes,
    declaration.computed.sections.eau.rejetPolluants
  );
  const [polluantsInArray, setPolluantsInArray] = useState<PolluantInArray[]>(
    polluantsInArrayInitialValueFromDto
  );
  const blocRejetPolluantsHasChanges = !_.isEqual(
    polluantsInArrayInitialValueFromDto,
    polluantsInArray
  );

  // BlocRejetEau
  const blocRejetEauProps = useFormikBloc<BlocRejetEauFormValues>();

  const blocPrelevementEauValues: BlocPrelevementEauFormValues24Now = dtoToBlocValues(
    declaration.body.sections.eau.prelevement,
    referentialSandres
  );

  const isRejetEauActive =
    isBlocRejetEauActive(
      blocPrelevementEauValues,
      blocInfoGeneralesValues,
      polluantsInArray
    ) &&
    isPathValidatedInDeclaration(PATH_EAU_INFOGENERALE) &&
    isPathValidatedInDeclaration(PATH_EAU_PRELEVEMENT) &&
    isPathValidatedInDeclaration(PATH_EAU_REJETPOLLUANT);

  // Page
  const pageHasChanges = () => {
    return (
      blocInfoGeneralesHasChanges ||
      blocPrelevementEauProps.hasChanges ||
      blocRejetPolluantsHasChanges ||
      (isRejetEauActive && blocRejetEauProps.hasChanges)
    );
  };

  const canSubmitPage = () => {
    const isBlocInfoGeneralesValidated = isPathValidatedInDeclaration(
      PATH_EAU_INFOGENERALE
    );
    const isBlocPrelevementEauValidated = isPathValidatedInDeclaration(
      PATH_EAU_PRELEVEMENT
    );
    const isBlocRejetPolluantsValidated = isPathValidatedInDeclaration(
      PATH_EAU_REJETPOLLUANT
    );
    const isBlocRejetEauValidated = isPathValidatedInDeclaration(
      PATH_EAU_REJETEAU
    );
    return (
      isBlocInfoGeneralesValidated &&
      isBlocPrelevementEauValidated &&
      isBlocRejetPolluantsValidated &&
      (!isRejetEauActive || isBlocRejetEauValidated)
    );
  };

  return (
    <>
      <Row>
        <span>
          Les prélèvements et/ou rejets déclarés concernent uniquement un usage
          industriel (exclusion des eaux sanitaires et pluviales).
        </span>
      </Row>

      <Row />

      <BlocInfoGenerales
        values={blocInfoGeneralesValues}
        setValues={setBlocInfoGeneralesValues}
        hasChanges={blocInfoGeneralesHasChanges}
        cancelAction={() =>
          setBlocInfoGeneralesValues(blocInfoGeneralesValuesFromDto)
        }
      />
      <Row />
      <BlocPrelevementEau
        {...blocPrelevementEauProps}
        referentialSandres={referentialSandres}
        isDepassementSeuil={blocInfoGeneralesValues.depassementSeuil}
        isDisabled={!isPathValidatedInDeclaration(PATH_EAU_INFOGENERALE)}
      />
      <Row />
      <BlocRejetPolluant
        polluantsInArray={polluantsInArray}
        setPolluantsInArray={setPolluantsInArray}
        hasChanges={blocRejetPolluantsHasChanges}
        cancelAction={() => {
          setPolluantsInArray(polluantsInArrayInitialValueFromDto);
        }}
        selectPossibleValues={selectPossibleValuesRejetPolluant}
        referentialPolluants={referentialPolluants}
        referentialNormes={referentialNormes}
      />
      <Row />
      {isRejetEauActive && (
        <>
          <BlocRejetEau
            {...blocRejetEauProps}
            isOver5000Treshold={isOver50000Treshold(
              blocInfoGeneralesValues,
              blocPrelevementEauValues
            )}
            isMegathermie={blocInfoGeneralesValues.megathermie}
            referentialSandres={referentialSandres}
            referentialStationsEpuration={referentialStationsEpuration}
            polluantsInArray={polluantsInArray}
          />
          <Row />
        </>
      )}
      <GlobalFormActionFullContext24Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={pageHasChanges()}
        isValidateEnabled={canSubmitPage()}
        validationPath={PATH_EAU}
        updateHandler={declaration =>
          updateHandlerPageEau(
            declaration,
            blocInfoGeneralesValues,
            blocPrelevementEauProps.formikRef,
            polluantsInArray,
            blocRejetEauProps.formikRef,
            referentialStationsEpuration
          )
        }
        cancelAction={() => {
          setBlocInfoGeneralesValues(blocInfoGeneralesValuesFromDto);
          blocPrelevementEauProps.formikRef?.current &&
            blocPrelevementEauProps.formikRef.current.resetForm();
          setPolluantsInArray(polluantsInArrayInitialValueFromDto);
          blocRejetEauProps.formikRef?.current &&
            blocRejetEauProps.formikRef.current.resetForm();
        }}
      />
    </>
  );
};

export default GeneralForm;
