import { makeStyles } from "@material-ui/styles";
import {
  SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum,
  SimpleInstallationDto,
} from "api/gen";
import classNames from "classnames";
import { PATH_DASHBOARD } from "common/path/path18Now";
import { findElementMatchingTemplate } from "common/utils/methods";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { LINK_STYLE } from "theme";
import {
  CommonDeclarationStateEnum,
  DeclarationState,
  stateOptions,
} from "pages/InspectorSpace/DeclarationsDashboard/FilterField/helpers/declarationStates";
import VerificateurActionButtons from "./VerificateurActionButtons";
import { getTimeRightFormat } from "../../../../common/utils/DateUtils";

const useStyles = makeStyles({
  link: {
    ...LINK_STYLE,
  },
  state: {
    borderRadius: "4px",
    padding: "4px",
    width: "100%",
  },
});

export const useDeclarationVerificateurTableLines = (
  declarations: SimpleInstallationDto[],
  onContactInfosClick: (declaration: SimpleInstallationDto) => void
): React.ReactElement[][] => {
  const classes = useStyles();

  const computeStateOption = (
    state: CommonDeclarationStateEnum,
    rienADeclarer: boolean
  ): DeclarationState | null => {
    return findElementMatchingTemplate(
      {
        state,
        rienADeclarer:
          state ===
          SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum.SUBMITTED
            ? rienADeclarer
            : null,
      },
      stateOptions
    );
  };

  const buildState = useCallback(
    (option: DeclarationState | null): React.ReactElement => {
      return (
        <p
          title={option ? option.title : "L'état actuel n'est pas spécifié..."}
          className={classNames(classes.state)}
          style={{
            backgroundColor: option ? option.backgroundColor : "white",
            color: option ? option.textColor : "black",
          }}
        >
          {option ? option.label : "État non spécifié"}
        </p>
      );
    },
    [classes.state]
  );

  const buildSingleDeclarationLine = useCallback(
    (installation: SimpleInstallationDto): React.ReactElement[] => {
      const quotaAllocationsOption = computeStateOption(
        installation.simpleInstallationDenormalizedDto.quotaAllocationsState,
        installation.simpleInstallationDenormalizedDto.rienADeclarer
      );
      const quotaEmissionsOption = computeStateOption(
        installation.simpleInstallationDenormalizedDto.quotaEmissionsState,
        installation.simpleInstallationDenormalizedDto.rienADeclarer
      );

      return [
        <Link
          to={`/declaration/${installation.annee}/${installation.etablissement}${PATH_DASHBOARD}`}
          className={classes.link}
        >
          {installation.simpleInstallationDenormalizedDto.numeroNim}
        </Link>,
        <p>{installation.etablissement}</p>,
        <p>{installation.annee}</p>,
        <p>{installation.etablissementName}</p>,
        <p>{installation.simpleInstallationDenormalizedDto.nomInstallation}</p>,
        buildState(quotaAllocationsOption),
        <p>
          {installation.lastTimeEditedAllocationDeclarant &&
            getTimeRightFormat(installation.lastTimeEditedAllocationDeclarant)}
        </p>,
        <p>
          {installation.lastTimeEditedAllocationInspector &&
            getTimeRightFormat(installation.lastTimeEditedAllocationInspector)}
        </p>,
        buildState(quotaEmissionsOption),
        <p>
          {installation.lastTimeEditedEmissionDeclarant &&
            getTimeRightFormat(installation.lastTimeEditedEmissionDeclarant)}
        </p>,
        <p>
          {installation.lastTimeEditedEmissionInspector &&
            getTimeRightFormat(installation.lastTimeEditedEmissionInspector)}
        </p>,
        <VerificateurActionButtons
          declaration={installation}
          onContactInfosClick={onContactInfosClick}
        />,
      ];
    },
    [classes.link, buildState, onContactInfosClick]
  );

  return useMemo(
    () =>
      declarations.map(declaration => buildSingleDeclarationLine(declaration)),
    [declarations, buildSingleDeclarationLine]
  );
};
