import React from "react";
import * as Yup from "yup";
import {
  DustRejectionPointInArray,
  DustRejectionPointInModale,
} from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import uuid from "uuid";
import {
  useDateFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  commonDateFieldsRequired,
  commonPositiveIntegerNumberFields,
  commonStrictlyPositiveIntegerNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { futureDateMessage } from "common/declarant/formik/formikMessages";

interface DustRejectionModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  emissionPointsInArray: DustRejectionPointInArray[];
  setEmissionPointsInArray: (points: DustRejectionPointInArray[]) => void;
  emissionPointInModal: DustRejectionPointInArray | null;
  setEmissionPointInModal: (point: DustRejectionPointInArray | null) => void;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  name: commonStringFields,
  date: commonDateFieldsRequired,
  dustConcentration: commonStrictlyPositiveIntegerNumberFields,
  averageFlow: commonStrictlyPositiveIntegerNumberFields,
  measuredPm10: commonPositiveIntegerNumberFields,
});

const DustRejectionModal = ({
  isOpen,
  setIsModalOpen,
  emissionPointsInArray,
  setEmissionPointsInArray,
  emissionPointInModal,
  setEmissionPointInModal,
}: DustRejectionModalProps) => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-rejet-poussieres",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const onClose = () => {
    setEmissionPointInModal(null);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UN POINT D'ÉMISSION"
        validationSchema={validationSchema}
        initialEntity={_.get(emissionPointInModal, "data", {
          name: null,
          date: null,
          averageFlow: null,
          dustConcentration: null,
          measuredPm10: null,
        })}
        isEdit={emissionPointInModal !== null}
        closeFunction={onClose}
        onSubmit={(values: DustRejectionPointInModale) => {
          const emissionPoint: DustRejectionPointInArray = {
            data: {
              id: emissionPointInModal ? emissionPointInModal.data.id : uuid(),
              name: values.name,
              date: values.date,
              averageFlow: values.averageFlow,
              dustConcentration: values.dustConcentration,
              measuredPm10: values.measuredPm10,
            },
            errors: {},
          };
          const newEmissionPointsInArray = emissionPointsInArray;
          let indexToReplace = newEmissionPointsInArray.length;
          if (emissionPointInModal) {
            indexToReplace = _.findIndex(
              newEmissionPointsInArray,
              emissionPointInModal
            );
            setEmissionPointInModal(null);
          }
          newEmissionPointsInArray[indexToReplace] = emissionPoint;
          setEmissionPointsInArray(newEmissionPointsInArray);
          setIsModalOpen(false);
        }}
        validate={values => {
          if (values.date && values.date > new Date()) {
            return { date: futureDateMessage };
          }
          return {};
        }}
        renderField={() => (
          <>
            <TextField name="name" label="Nom *" />

            <DateField name="date" label="Date *" />

            <NumberField
              name="dustConcentration"
              label="Concentration en poussières *"
              unit="mg/Nm³"
            />

            <NumberField
              name="averageFlow"
              label="Débit moyen durant la campagne *"
              unit="Nm³/h"
            />

            <NumberField
              name="measuredPm10"
              label="PM 10 mesuré *"
              unit="%"
              tooltipContent="Si la valeur n'est pas connue, la valeur 0 doit être renseignée"
            />
          </>
        )}
      />
    </CustomModal>
  );
};

export default DustRejectionModal;
