import React from "react";
import HttpError from "./HttpError";

const Page403CreationImpossible = (): React.ReactElement => {
  return (
    <HttpError code={403} message={"La déclaration n'a pas encore été créée"} />
  );
};

export default Page403CreationImpossible;
