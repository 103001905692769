import {
  PATH_CARRIERE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_EAU,
  PATH_INFO_GENERALE,
} from "common/path/path18Now";
import { isCarriereDisabled, isEveryCarriereValidated } from "./utils";
import {
  GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION,
  getDisabledMessageForTypeActiviteConditionnalSection,
} from "./disabledMessageUtils";
import BigCard from "./cards/BigCard";
import React from "react";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import idCardImg from "icons/dashboardLogos/id-card.svg";
import waterImg from "icons/dashboardLogos/water.svg";
import helmetImg from "icons/dashboardLogos/helmet.svg";
import growthImg from "icons/dashboardLogos/growth.svg";
import deliveryTruckImg from "icons/dashboardLogos/delivery-truck.svg";
import insuranceImg from "icons/dashboardLogos/insurance.svg";
import { makeStyles } from "@material-ui/styles";
import QuotasDashboardCards from "./QuotasDashboardCards";
import { FOUR_COLUMNS_CARD_GRID } from "theme";
import { useUserData } from "../../../../../../Authenticator/UserData";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../../../api/gen";

interface DashboardCardsProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
}

const useStyles = makeStyles({
  mediaQueries: FOUR_COLUMNS_CARD_GRID,
});

const LecteurDashboardCards = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: DashboardCardsProps): React.ReactElement => {
  const classes = useStyles();
  const { isLecteurOnSection } = useUserData();
  return (
    <>
      {(isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) ||
        isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU)) && (
        <BigCard
          picture={{ src: idCardImg, alt: "" }}
          text="Informations générales"
          percentageProgress={declarationApi.getPathProgress(
            PATH_INFO_GENERALE
          )}
          isValidated={declarationApi.isPathValidatedInDeclaration(
            PATH_INFO_GENERALE
          )}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_INFO_GENERALE}`}
          isDisabled={!declarationApi.getIsActiveSection(PATH_INFO_GENERALE)}
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU) && (
        <BigCard
          picture={{ src: waterImg, alt: "" }}
          text="Eau"
          percentageProgress={declarationApi.getPathProgress(PATH_EAU)}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_EAU}`}
          isDisabled={!declarationApi.getIsActiveSection(PATH_EAU)}
          isValidated={declarationApi.isPathValidatedInDeclaration(PATH_EAU)}
          disabledMessage={GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION}
        />
      )}

      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) && (
        <BigCard
          isClickable={false}
          picture={{ src: helmetImg, alt: "" }}
          text="Carrière"
          percentageProgress={declarationApi.getPathProgress(PATH_CARRIERE)}
          link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`}
          isDisabled={isCarriereDisabled(declarationApi)}
          isValidated={isEveryCarriereValidated(declarationApi)}
          subCards={[
            {
              text: "Environnement",
              picture: { src: growthImg, alt: "" },
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`,
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_ENVIRONNEMENT
              ),
              disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
                declarationApi
              ),
            },
            {
              text: "Production",
              picture: { src: deliveryTruckImg, alt: "" },
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_PRODUCTION
              ),
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_PRODUCTION}`,
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_PRODUCTION
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_PRODUCTION
              ),
              disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
                declarationApi
              ),
            },
            {
              text: "Santé / Sécurité",
              picture: { src: insuranceImg, alt: "" },
              link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_SANTE}`,
              percentageProgress: declarationApi.getPathProgress(
                PATH_CARRIERE_SANTE
              ),
              isDisabled: !declarationApi.getIsActiveSection(
                PATH_CARRIERE_SANTE
              ),
              isValidated: declarationApi.isPathValidatedInDeclaration(
                PATH_CARRIERE_SANTE
              ),
              disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
                declarationApi
              ),
            },
          ]}
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS) && (
        <QuotasDashboardCards
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
        />
      )}
    </>
  );
};

export default LecteurDashboardCards;
