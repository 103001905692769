import React, { CSSProperties, useState } from "react";
import { LIGHT_PINK, PINK } from "theme";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { DummyCheckBoxProps } from "../types/dummyTypes";

const Tick = ({
  hovered,
  className,
  style,
  disabled,
}: {
  hovered: boolean;
  className: string;
  style: CSSProperties;
  disabled: boolean | undefined;
}) => {
  const getFillColor = () => {
    if (disabled) {
      return "gray";
    }
    if (hovered) {
      return LIGHT_PINK;
    }
    return PINK;
  };
  return (
    <svg
      x="0px"
      y="0px"
      viewBox="0 0 512 512"
      width="20px"
      height="20px"
      className={className}
      style={style}
    >
      <g>
        <g>
          <g>
            <path
              d="M504.502,75.496c-9.997-9.998-26.205-9.998-36.204,0L161.594,382.203L43.702,264.311c-9.997-9.998-26.205-9.997-36.204,0    c-9.998,9.997-9.998,26.205,0,36.203l135.994,135.992c9.994,9.997,26.214,9.99,36.204,0L504.502,111.7    C514.5,101.703,514.499,85.494,504.502,75.496z"
              data-original="#000000"
              data-old_color="#000000"
              fill={getFillColor()}
            />
          </g>
        </g>
      </g>{" "}
    </svg>
  );
};

const useStyles = makeStyles({
  input: {
    width: "20px",
    height: "20px",
    margin: "2px",
    border: "2px solid white",
    appearance: "none",
    outline: `2px solid ${PINK}`,
    boxShadow: "none",
    fontSize: "2em",
  },
  tick: {
    width: "15px",
    height: "15px",
    position: "relative",
    right: "20px",
    bottom: "5px",
    marginRight: "-15px",
  },
});

const DummyCheckBox = ({
  value,
  name,
  additionalClassName,
  disabled,
  onChange,
  ...props
}: DummyCheckBoxProps): React.ReactElement => {
  const classes = useStyles();

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const handleChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();

    if (!disabled) {
      onChange && onChange(!value);
    }
  };

  const style = {
    cursor: disabled ? "not-allowed" : "pointer",
    outlineColor: disabled ? "gray" : PINK,
  };
  if (props.style) {
    props.style = { ...props.style, ...style };
  } else {
    props.style = style;
  }

  return (
    <label
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <input
        type="checkbox"
        name={name}
        value={name + "_is_true"}
        className={classNames(classes.input, additionalClassName)}
        checked={value}
        onChange={handleChanges}
        {...props}
      />
      {(value || isHovered) && (
        <Tick
          hovered={isHovered}
          className={classes.tick}
          style={style}
          disabled={disabled}
        />
      )}
    </label>
  );
};

export default DummyCheckBox;
