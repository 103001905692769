import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { convertDeclarationDtoUsingCheckboxesForm } from "../utils/converters";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { CommonProps } from "common/form/utils";
import { PATH_AIR_ELEVAGE_MTD } from "common/path/path18Now";
import BlocInformations from "../BlocInformations";
import {
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../../../versionedElements/declarationHooks1919";
import BlocFullContext1919 from "../../../versionedElements/BlocFullContext1919";

interface BlocCheckboxesProps {
  setIsMtdChecked: (checked: boolean) => void;
  isMtdChecked: boolean;
}

const useStyles = makeStyles({
  header: {
    color: "#6b6b6b",
    textTransform: "uppercase",
    marginBottom: "20px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const BlocCheckboxes = ({
  setIsMtdChecked,
  isMtdChecked,
}: BlocCheckboxesProps) => {
  const classes = useStyles();
  const declaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const commonProps: CommonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "55%",
    formPrefix: "bloc-checkboxes",
  };

  const DummyCheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  return (
    <>
      <BlocFullContext1919
        hasModification={
          isMtdChecked !== (declaration.body.sections.air.elevage.mtd || false)
        }
        isValidateButtonAvailable={
          !isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_MTD)
        }
        title=""
        updateHandler={(declaration: Declaration1919) =>
          convertDeclarationDtoUsingCheckboxesForm(declaration, isMtdChecked)
        }
        path={PATH_AIR_ELEVAGE_MTD}
        cancelAction={() =>
          setIsMtdChecked(declaration.body.sections.air.elevage.mtd || false)
        }
        renderContent={(shouldDisabledFields: boolean) => (
          <>
            <h5 className={classes.header}>
              Données héritées d'informations générales
            </h5>

            <DummyCheckBox
              name="inheritedBreeding"
              label="L'établissement est un établissement d'élevage intensif de volailles ou de porcs (rubrique 3660)"
              tooltipContent={
                <>
                  Élevage intensif comprenant :
                  <ul>
                    <li>Plus de 40 000 emplacements pour les volailles</li>
                    <li>
                      Ou plus de 2 000 emplacements pour les porcs de plus de
                      30kg
                    </li>
                    <li>Ou plus de 750 emplacements pour les truies</li>
                  </ul>
                </>
              }
              value={declaration.body.typeActivite.elevage}
              disabled
            />

            <h5 className={classes.header}>Informations complémentaires</h5>

            <DummyCheckBox
              name="mtdAuthorized"
              label="Installations autorisées avant la parution des conclusions MTD"
              value={isMtdChecked}
              onChange={selected => setIsMtdChecked(selected)}
              disabled={shouldDisabledFields}
              commentPath={PATH_AIR_ELEVAGE_MTD}
              tooltipContent="Selon la décision d’exécution (UE) n° 2017/302 de la Commission du 15 février 2017 établissant les conclusions sur les meilleures techniques disponibles (MTD), au titre de la directive 2010/75/UE du Parlement européen et du Conseil, pour l’élevage intensif de volailles ou de porcs"
            />

            <BlocInformations shouldDisabledFields={shouldDisabledFields} />
          </>
        )}
      />
    </>
  );
};

export default BlocCheckboxes;
