import { Nullable } from "common/utils/types";
import React, { useState } from "react";
import Row from "common/presentational/Row";
import AccidentsTravail from "./AccidentsTravail";
import MesuresEmpoussierage from "./MesuresEmpoussierage";
import { StructureFonctionnelleData } from "./StructureFonctionnelle/utils";
import StructureFonctionnelle from "./StructureFonctionnelle";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  AccidentInArray,
  AccidentsTravailData,
} from "./AccidentsTravail/utils";
import { MesureEmpoussierageData } from "./MesuresEmpoussierage/utils";
import {
  ReferenceItemCarriereAccidentDto,
  ReferenceOrganismeAccrediteDto,
  ReferenceOrganismeExterieurPreventionDto,
} from "api/gen";
import {
  updateAccidentsTravail,
  updateMesuresEmpoussierage,
  updateStructureFonctionnelle,
} from "./utils/updaters";
import {
  PATH_CARRIERE_SANTE,
  PATH_CARRIERE_SANTE_ACCIDENT,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE,
  PATH_CARRIERE_SANTE_PREVENTION,
} from "common/path/path18Now";
import {
  Declaration20Now,
  useDeclaration20Now,
  useDeclarationHelpers20Now,
} from "../../versionedElements/declarationHooks20Now";
import GlobalFormActionFullContext20Now from "../../versionedElements/GlobalFormActionFullContext20Now";
import { convertDescriptionAccidentToAccidentInArray } from "./AccidentsTravail/AccidentArray/converters";

interface MainCarriereFormProps {
  referentialCauseAccident: ReferenceItemCarriereAccidentDto;
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto;
  referentialOrganismeAccredite: ReferenceOrganismeAccrediteDto;
}

const MainCarriereForm = ({
  referentialCauseAccident: { causes: mainAccidentCauseList },
  referentialOrganismeExterieur,
  referentialOrganismeAccredite,
}: MainCarriereFormProps) => {
  //TODO take all one step up, to compute some or all of the initialValues here (probably only state values will be computed here, but still.
  const dataDeclaration = useDeclaration20Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers20Now();

  //we set the refs and validation state here so it's all available for whole page validation and save
  const structureFonctionnelleProps = useFormikBloc<
    Nullable<StructureFonctionnelleData>
  >();
  const accidentsTravailProps = useFormikBloc<Nullable<AccidentsTravailData>>();
  const mesuresEmpoussierageProps = useFormikBloc<
    Nullable<MesureEmpoussierageData>
  >();

  //this state is needed for AccidentsTravail bloc, but defined here because cancel affects it.
  const initialAccidentsList = dataDeclaration.body.sections.carriere.sante.accident.accidents.map(
    convertDescriptionAccidentToAccidentInArray
  );

  const [accidentsList, setAccidentsList] = useState<AccidentInArray[]>(
    initialAccidentsList
  );

  const activeOrganismeExterieurPreventionList = referentialOrganismeExterieur.referenceItemOrganismeExterieurPreventionDtoList.filter(
    ({ actif }) => actif
  );
  const activeOrganismeAccrediteList = referentialOrganismeAccredite.referenceItemOrganismeAccrediteDtoList.filter(
    ({ actif }) => actif
  );

  return (
    <>
      {/*TODO : lift useful state up because we'll need all changes and initial values here for GlobalFormActions. Use New interface from https://github.com/Polyconseil/mtes-gerep/pull/213 */}
      <StructureFonctionnelle
        {...structureFonctionnelleProps}
        activeOrganismeExterieurPreventionList={
          activeOrganismeExterieurPreventionList
        }
      />
      <Row />
      <AccidentsTravail
        {...accidentsTravailProps}
        mainAccidentCauseList={mainAccidentCauseList}
        setAccidentsList={setAccidentsList}
        initialAccidentsList={initialAccidentsList}
        accidentsList={accidentsList}
      />
      <Row />
      <MesuresEmpoussierage
        {...mesuresEmpoussierageProps}
        activeOrganismeAccrediteList={activeOrganismeAccrediteList}
      />
      <Row />
      <GlobalFormActionFullContext20Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        validationPath={PATH_CARRIERE_SANTE}
        hasChanges={
          structureFonctionnelleProps.hasChanges ||
          accidentsTravailProps.hasChanges ||
          mesuresEmpoussierageProps.hasChanges
        }
        isValidateEnabled={
          !isPathValidatedInDeclaration(PATH_CARRIERE_SANTE) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_PREVENTION) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_ACCIDENT) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_EMPOUSSIERAGE)
        }
        cancelAction={() => {
          setAccidentsList(initialAccidentsList);
          structureFonctionnelleProps.resetForm();
          accidentsTravailProps.resetForm();
          mesuresEmpoussierageProps.resetForm();
        }}
        updateHandler={(declaration: Declaration20Now) => {
          const structureFonctionnellesValues = structureFonctionnelleProps.getValues();
          const accidentsTravailValues = accidentsTravailProps.getValues();
          const mesuresEmpoussierageValues = mesuresEmpoussierageProps.getValues();
          if (
            structureFonctionnellesValues &&
            accidentsTravailValues &&
            mesuresEmpoussierageValues
          ) {
            return updateMesuresEmpoussierage(
              updateAccidentsTravail(
                updateStructureFonctionnelle(
                  declaration,
                  structureFonctionnellesValues,
                  activeOrganismeExterieurPreventionList
                ),
                accidentsTravailValues,
                accidentsList
              ),
              mesuresEmpoussierageValues,
              activeOrganismeAccrediteList
            );
          }
          return declaration;
        }}
      />
    </>
  );
};

export default MainCarriereForm;
