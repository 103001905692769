// Declare the range for all generic hooks we'll call for this version.
import {
  DeclarationBodyDto2121,
  DeclarationBodyDto2222,
  DeclarationDto2121,
  DeclarationDto2222,
  DeclarationSectionsDto2121,
  DeclarationSectionsDto2222,
  FileDto,
  TypeActiviteQuotaDto2122,
} from "../../../../../api/gen";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useQuotasState,
  useUpdateDeclarationTypeActivite,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  BasicDeclarationHandlers,
  DeclarationState,
} from "../../../DeclarationApiContext/utils/types";
import { useCallback, useMemo } from "react";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import {
  Declaration21Now,
  TypeActiviteQuota21Now,
} from "../../toNow/versionedElements/declarationHooks21Now";
import { useAuthenticatedApi } from "../../../../../Authenticator/AuthenticatedApi";
import { FileHandlers } from "common/FilesLoader/types";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";

export const MIN_YEAR = 2021;
export const MAX_YEAR = 2022;

// Define all the DTO types for this version.
export type Declaration2122 = DeclarationDto2121 | DeclarationDto2222;
export type DeclarationBody2122 =
  | DeclarationBodyDto2121
  | DeclarationBodyDto2222;
export type DeclarationSections2122 =
  | DeclarationSectionsDto2121
  | DeclarationSectionsDto2222;
export type TypeActiviteQuota2122 = TypeActiviteQuotaDto2122;
export const useDeclaration2122 = (
  shouldThrowIfWrongYear?: boolean
): Declaration2122 => {
  return useDeclaration<Declaration2122>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationHelpers2122 = useDeclarationHelpers19Now;
export const useBasicDeclarationHandlers2122 = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration2122> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration2122): DeclarationSections2122 =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration2122>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useQuotaAllocationsState2122 = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration2122) =>
      declarationDto.body.workflowStatus.quotaAllocations.state,
    []
  );

  return useQuotasState<Declaration2122>(getQuotaState, MIN_YEAR, MAX_YEAR);
};
export const useUpdateDeclarationTypeActiviteQuota2122 = (): ((
  newActiviteType: TypeActiviteQuota21Now
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteQuota21Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteQuota2122UsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  return useUpdateDeclarationTypeActivite<
    TypeActiviteQuota21Now,
    Declaration21Now
  >(updateTypeActivite, MIN_YEAR, MAX_YEAR);
};

export const useAlcPreliminaireFileHandler2122 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadAlcPreliminaireUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      )) as Declaration2122;
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteAlcPreliminaireUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      )) as Declaration2122;
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2122>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2122>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
