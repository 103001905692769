import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { singleCovValidationSchema } from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { mentionDangerList } from "./utils/selectPossibleValues";
import { CovInModale, fieldMatcher } from "./utils/types";
import { ProcedeInArray } from "../ListProcede/utils/types";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { getProcedeName } from "../utils/utils";

interface FormCovProps {
  closeFunction: () => void;
  onSubmit: (
    values: CovInModale,
    formikActions: FormikActions<CovInModale>
  ) => void;
  initialCov: CovInModale;
  isEdit: boolean;
  procedesInPageMap: Map<string, ProcedeInArray>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const FormSingleCov = ({
  closeFunction,
  onSubmit,
  initialCov,
  isEdit,
  procedesInPageMap,
}: FormCovProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-cov-procedes",
  };
  const NumberField = useNumberFieldGenerator(commonProps);

  const procedeIdList: string[] = Array.from(procedesInPageMap.keys());

  return (
    <CommonFormSingleEntity
      title="AJOUTER DES ÉMISSIONS DE COV A MENTION DE DANGER"
      closeFunction={closeFunction}
      onSubmit={(values: CovInModale, formikBag) => {
        onSubmit(values, formikBag);
      }}
      initialEntity={initialCov}
      validationSchema={singleCovValidationSchema}
      isEdit={isEdit}
      renderField={() => {
        return (
          <>
            <WrappedChoiceSelect
              name={fieldMatcher.substance}
              label="Utilisation de COV à mention de danger *"
              isMulti={false}
              options={mentionDangerList}
              computeLabel={mention => mention}
              commonProps={commonProps}
            />
            <WrappedChoiceSelect
              isMulti
              name={fieldMatcher.procedes}
              label="Procédé(s) *"
              options={procedeIdList}
              computeLabel={procedeId =>
                getProcedeName(procedeId, procedesInPageMap) || ""
              }
              commonProps={commonProps}
            />
            <NumberField
              name={fieldMatcher.flux}
              label="Émissions *"
              unit="kg/an"
            />
          </>
        );
      }}
    />
  );
};

export default FormSingleCov;
