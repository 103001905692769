import React from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { FileDto } from "api/gen";
import { FileRow } from "./FileRow";
import { FileSectionEnum, HandledFile } from "../types";

const useStyles = makeStyles({
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
});

interface RowsWrapperProps {
  additionalClassname?: string;
  files: Readonly<HandledFile[]>;
  section: FileSectionEnum;
  isDisabled: boolean;
  addFileToDelete: (fileDto: FileDto) => Promise<void>;
}

export const RowsWrapper = ({
  additionalClassname,
  files,
  section,
  isDisabled,
  addFileToDelete,
}: RowsWrapperProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.list, additionalClassname)}>
      {files.map((file, index) => (
        <FileRow
          key={index}
          file={file}
          section={section}
          isDisabled={isDisabled}
          addFileToDelete={addFileToDelete}
        />
      ))}
    </div>
  );
};
