import React from "react";
import Footer from "common/footer";
import Banner from "common/Header/Banner";
import EmailUnsubscription from "./EmailUnsubscription";

const UnauthenticatedAppPages = (): JSX.Element => {
  return (
    <>
      <Banner />
      <EmailUnsubscription />
      <Footer />
    </>
  );
};

export default UnauthenticatedAppPages;
