import { FactoryInModale } from "./types";
import { Errors } from "common/form/utils";
import {
  computeRequiredPositiveNumberErrorMessage,
  computeRequiredStringErrorMessage,
  filterOutNullishValues,
} from "common/declarant/formik/formikValidationHelper";
import {
  codePostalMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Nullable } from "common/utils/types";

export const validateFactory = (
  factoryToValidate: Nullable<FactoryInModale>
): Errors<FactoryInModale> => {
  const errors: Errors<FactoryInModale> = {};

  const {
    socialReason,
    zipCode,
    city,
    country,
    careerRange,
  } = factoryToValidate;

  errors.socialReason = computeRequiredStringErrorMessage(socialReason);

  errors.zipCode = computeRequiredStringErrorMessage(zipCode);

  if (country && country.object === null) {
    if (zipCode && !/^[0-9]{5}$/.test(zipCode)) {
      errors.zipCode = codePostalMessage;
    }
  }

  if (typeof city === "string") {
    errors.city = computeRequiredStringErrorMessage(city);
  } else if (city === null) {
    errors.city = requiredMessage;
  }

  if (country === null) {
    errors.country = requiredMessage;
  }

  errors.careerRange = computeRequiredPositiveNumberErrorMessage(careerRange);

  return filterOutNullishValues(errors);
};
