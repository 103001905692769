import { CommentValues } from "pages/CompanySpace/Comments/commentTypes";

export const options: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
};

export const getUserInfo = (comment: CommentValues): string => {
  // Pour des raisons métier, si on n'a pas de nom/prénom, on n'affiche que le droit
  if (comment.userFamilyName && comment.userName) {
    return `${comment.userFamilyName} ${comment.userName} - ${comment.userDroit}`;
  }
  return comment.userDroit;
};
