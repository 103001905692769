import {
  QuotasEmissionsBlocPlanSurveillanceDto2121,
  QuotasFluxDerogationDto2121,
  QuotasInfosComplementairesInstallationsDto2021,
  QuotasInstallationDto20Now,
} from "api/gen";
import { BlocPlanDeSurveillanceFormValues } from "./types";
import { Declaration2121 } from "../../../versionedElements/declarationHooks2121";

export const computeInitialValues = (
  blocPlanSurveillance: QuotasEmissionsBlocPlanSurveillanceDto2121,
  installations: QuotasInstallationDto20Now[]
): BlocPlanDeSurveillanceFormValues => {
  const infosComplementairesInstallations: QuotasInfosComplementairesInstallationsDto2021[] = installations.map(
    installation => {
      const infosComplementairesInstallation = blocPlanSurveillance.infosComplementairesInstallations.find(
        infos => infos.nimDynId === installation.id
      );
      return infosComplementairesInstallation
        ? infosComplementairesInstallation
        : {
            nimDynId: installation.id,
            categorie: null,
            faiblementEmettrice: false,
          };
    }
  );

  const fluxDerogationInstallations: QuotasFluxDerogationDto2121[] = installations.map(
    installation => {
      const fluxDerogationInstallation = blocPlanSurveillance.fluxDerogationInstallations.find(
        fluxDerogation => fluxDerogation.nimDynId === installation.id
      );
      return fluxDerogationInstallation
        ? fluxDerogationInstallation
        : {
            nimDynId: installation.id,
            derogationFrequenceEchantillonnage: false,
            fluxSoumisADerogation: [""],
          };
    }
  );

  return {
    infosComplementairesInstallations: infosComplementairesInstallations,
    fluxDerogationInstallations: fluxDerogationInstallations,
  };
};

const blocValuesToDto = (
  values: BlocPlanDeSurveillanceFormValues | null
): QuotasEmissionsBlocPlanSurveillanceDto2121 => {
  return (
    values ?? {
      infosComplementairesInstallations: [],
      fluxDerogationInstallations: [],
    }
  );
};

export const updateHandler = (
  declaration: Declaration2121,
  values: BlocPlanDeSurveillanceFormValues | null
): Declaration2121 => {
  declaration.body.sections.quotas.emissions.blocPlanSurveillance = blocValuesToDto(
    values
  );

  return declaration;
};
