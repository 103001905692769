import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import {
  AlcVerifieJustificatifFilesDto,
  QuotasInstallationDto20Now,
} from "api/gen";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useDeclaration2122 } from "pages/CompanySpace/from21/to22/versionedElements/declarationHooks2122";
import { useJustificatifAlcVerifieFileHandler21Now } from "pages/CompanySpace/from21/toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import { filesRelatedToNimDtoToFileDto } from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/utils";

interface JustificatifsFilesLoaderProps {
  installation: QuotasInstallationDto20Now;
  shouldDisableFields: boolean;
}

const JustificatifsFilesLoader = ({
  installation,
  shouldDisableFields,
}: JustificatifsFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2122();
  const openAlertModale = useAlertModale();
  const justificatifAlcVerifieFileHandler = useJustificatifAlcVerifieFileHandler21Now(
    installation.id
  );

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER DOCUMENT(S) JUSTIFICATIF(S)"}
      filesPath={
        "body.externalSections.quotas.allocations.blocAlc.alcVerifie.documentsJustificatifs"
      }
      section={FileSectionEnum.QUOTAS_JUSTIFICATIFS_ALC_VERIFIE}
      fileHandlers={justificatifAlcVerifieFileHandler}
      //
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(alcs: Record<string, unknown>[]) =>
        filesRelatedToNimDtoToFileDto(
          installation.id,
          (alcs as unknown) as AlcVerifieJustificatifFilesDto[]
        )
      }
    />
  );
};

export default JustificatifsFilesLoader;
