import React from "react";
import Button from "common/button";
import {
  Declaration24Now,
  useTdApiUpdateWaste,
} from "../../versionedElements/declarationHooks24Now";
import { TrackDechetControllerApi } from "api/gen";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  button: {
    textTransform: "uppercase",
  },
});

interface TDImportButtonProps<
  VersionedDeclarationDto extends Declaration24Now
> {
  methodUpdate: (
    tdController: TrackDechetControllerApi,
    annee: number,
    etablissement: string,
    options?: any
  ) => Promise<VersionedDeclarationDto>;
  additionalClassname?: string;
  isDisabled: boolean;
  updateHandlerRecu: (
    declaration: VersionedDeclarationDto
  ) => VersionedDeclarationDto;
  updateHandlerProduit: (
    declaration: VersionedDeclarationDto
  ) => VersionedDeclarationDto;
}

const TDImportButton = ({
  methodUpdate,
  additionalClassname,
  isDisabled,
  updateHandlerRecu,
  updateHandlerProduit,
}: TDImportButtonProps<Declaration24Now>): React.ReactElement => {
  const classes = useStyles();

  const updateWasteMethod = useTdApiUpdateWaste(
    methodUpdate,
    updateHandlerRecu,
    updateHandlerProduit
  );

  const [isImportSpinnerVisible, triggerImportTd] = useSpinnerState();

  return (
    <Button
      text={"récupérer les données à partir de trackdéchets"}
      onClick={() => triggerImportTd(updateWasteMethod())}
      isSpinnerVisible={isImportSpinnerVisible}
      additionalClassname={classNames(classes.button, additionalClassname)}
      isDisabled={isDisabled}
    />
  );
};

export default TDImportButton;
