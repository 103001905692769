import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { ProduitTransportType } from "../BlocTransportProduits/utils/types";

export interface WithFamily<ReferenceItemFamilleUsageDto> {
  famille: ReferenceItemFamilleUsageDto | null;
  sousFamille: ReferenceItemFamilleUsageDto | null;
  sousFamilleNiveau2: ReferenceItemFamilleUsageDto | null;
  precisionFamille: string | null;
  precisionSousFamille: string | null;
  precisionSousFamilleNiveau2: string | null;
}

export const withFamilyTypeMatcher = fieldMatcherBuilder<
  ProduitTransportType
>().build({
  family: "famille",
  subFamily: "sousFamille",
  subFamilyLevel2: "sousFamilleNiveau2",
  precisionFamily: "precisionFamille",
  precisionSubFamily: "precisionSousFamille",
  precisionSubFamilyLevel2: "precisionSousFamilleNiveau2",
});
