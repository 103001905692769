import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import { PINK } from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    padding: "9px",
    "& ul": {
      marginLeft: "1em",
    },
  },
  popupWrapper: {
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    marginRight: "10px",
  },
  width: {
    maxWidth: "400px",
    padding: 0,
    "& > div > div": {
      padding: 0,
    },
    "& > div > div:first-child": {
      // This is the arrow, which bothers me
      display: "none",
    },
  },
});

interface InfoBulleProps {
  content?: ReactNode;
  placement?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  additionalClassName?: string;
}

const InfoBulle = ({
  content,
  additionalClassName,
  placement,
}: InfoBulleProps): ReactElement => {
  const classes = useStyles();

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); //This is to prevent modals to close if we click on the content of the tooltip
  };

  return (
    <div className={classNames(classes.popupWrapper, additionalClassName)}>
      <Tooltip
        placement={placement || "top"}
        destroyTooltipOnHide={true}
        trigger={["click"]}
        overlay={
          <div onClick={handleOnClick} className={classes.container}>
            <div>{content}</div>
          </div>
        }
        overlayClassName={classes.width}
      >
        <FontAwesomeIcon icon="question-circle" color={PINK} />
      </Tooltip>
    </div>
  );
};

export default InfoBulle;
