import {
  QuotasBlocInfosComplementairesInstallationsDto2020,
  QuotasInstallationDto20Now,
} from "api/gen";
import {
  blocInfosComplementairesToDto,
  installationDtoToInBloc,
} from "./converter";
import { BlocInfosComplementaires } from "./types";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

export const updateHandler = (
  declaration: Declaration2020,
  values: BlocInfosComplementaires | null
): Declaration2020 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocInfosComplementairesInstallations = blocInfosComplementairesToDto(
      values
    );
  }
  return declaration;
};

export const computeInitialValues = (
  dto: QuotasBlocInfosComplementairesInstallationsDto2020,
  installationsInArray: QuotasInstallationDto20Now[]
): BlocInfosComplementaires => {
  return {
    installations: installationsInArray.map(singleInstallationInfoGenerale => {
      const infosComplementairesDto = dto.installations.find(
        singleInstallationInfosComplementaires =>
          singleInstallationInfosComplementaires.nimDynId ===
          singleInstallationInfoGenerale.id
      );
      if (infosComplementairesDto) {
        return installationDtoToInBloc(infosComplementairesDto);
      }

      return {
        nimDynId: singleInstallationInfoGenerale.id,
        categorie: null,
        faiblementEmettrice: false,
      };
    }),
  };
};

export const getNimFromNimDynId = (
  nimDynId: string | null,
  installations: QuotasInstallationDto20Now[]
): string | null | undefined => {
  const installation = installations.find(
    installation => installation.id === nimDynId
  );
  return installation ? installation.nim : null;
};
