import { withFamilyTypeMatcher } from "./types";

export const onSelectFamilyHandler = (
  setFieldValue: (field: string, value: any) => void
) => {
  setFieldValue(withFamilyTypeMatcher.precisionFamily, "");
  setFieldValue(withFamilyTypeMatcher.subFamily, null);
  setFieldValue(withFamilyTypeMatcher.precisionSubFamily, "");
  setFieldValue(withFamilyTypeMatcher.subFamilyLevel2, null);
  setFieldValue(withFamilyTypeMatcher.precisionSubFamilyLevel2, "");
};

export const onSelectSubFamilyHandler = (
  setFieldValue: (field: string, value: any) => void
) => {
  setFieldValue(withFamilyTypeMatcher.precisionSubFamily, "");
  setFieldValue(withFamilyTypeMatcher.subFamilyLevel2, null);
  setFieldValue(withFamilyTypeMatcher.precisionSubFamilyLevel2, "");
};

export const onSelectSubFamilyLvl2Handler = (
  setFieldValue: (field: string, value: any) => void
) => {
  setFieldValue(withFamilyTypeMatcher.precisionSubFamilyLevel2, "");
};
