import { makeStyles } from "@material-ui/styles";
import React from "react";
import { PINK, SECTION_FONT_SIZE, SECTION_TITLE_GREY } from "theme";
import { TrailItem } from "../utils/types";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  path: {
    color: SECTION_TITLE_GREY,
    fontSize: SECTION_FONT_SIZE,
    justifyContent: "flex-start",
    display: "flex",
    margin: "3px",
    marginRight: "6px",
    "& a": {
      color: SECTION_TITLE_GREY,
      fontSize: SECTION_FONT_SIZE,
    },
  },
  pathbold: {
    fontSize: SECTION_FONT_SIZE,
    display: "flex",
    margin: "3px",
    color: PINK,
    "& a": {
      fontWeight: "bold",
      color: PINK,
    },
  },
});

const AppPath = ({ pathlinks }: TrailItem) => {
  const classes = useStyles();
  return (
    <ul className={classes.path}>
      {pathlinks.map((pathLink, idx) => {
        const isLast = idx === pathlinks.length - 1;
        return (
          <li key={idx} className={classes.path}>
            <div className={isLast ? classes.pathbold : classes.path}>
              <Link to={pathLink.to}>{pathLink.label}</Link>
            </div>
            {!isLast && <div className={classes.path}>&gt;</div>}
          </li>
        );
      })}
    </ul>
  );
};

export default AppPath;
