import React, { ReactElement } from "react";
import { PATH_INFO_GENERALE } from "common/path/path18Now";
import { CREATED } from "common/messages/dashboardMessage";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";

import idCardImg from "icons/dashboardLogos/id-card.svg";
import {
  useDeclarationGlobalState21Now,
  useDeclarationHelpers21Now,
} from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import { DISABLED_MESSAGE_INFORMATIONS_GENERALES } from "pages/CompanySpace/from21/toNow/Dashboard/Components/utils/disabledMessageUtils";
import BigCard from "./BigCard";

const InformationsGeneralesCard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const {
    getPathProgress,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers21Now();

  const { isDeclarationStateContaining } = useDeclarationGlobalState21Now();

  return (
    <BigCard
      picture={{ src: idCardImg, alt: "" }}
      text="Informations générales"
      percentageProgress={getPathProgress(PATH_INFO_GENERALE)}
      isValidated={isPathValidatedInDeclaration(PATH_INFO_GENERALE)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_INFO_GENERALE}`}
      isDisabled={isDeclarationStateContaining([CREATED])}
      disabledMessage={DISABLED_MESSAGE_INFORMATIONS_GENERALES}
    />
  );
};

export default InformationsGeneralesCard;
