import { AirFugitivesProcedesDto } from "api/gen";
import { ProcedeInArray } from "./types";

export const convertProcedeInArrayToDto = (
  ProcedesInPage: ProcedeInArray[]
): AirFugitivesProcedesDto[] => {
  return ProcedesInPage.map(
    singlePopulatedProcede => singlePopulatedProcede.data
  );
};

export const convertDtoToProcedeInArray = (
  procede: AirFugitivesProcedesDto
): ProcedeInArray => {
  return {
    data: procede,
    errors: {},
  };
};
