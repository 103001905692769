import { Nullable } from "common/utils/types";
import { StructureFonctionnelleData } from "../StructureFonctionnelle/utils";
import {
  ReferenceOrganismeAccrediteDto,
  ReferenceOrganismeExterieurPreventionDto,
} from "api/gen";
import {
  convertStructureFonctionnelleDataToDeclarationPreventionDto,
  overrideUncoherentValuesStructureFonctionnelle,
} from "../StructureFonctionnelle/converters";
import {
  AccidentInArray,
  AccidentsTravailData,
} from "../AccidentsTravail/utils";
import { MesureEmpoussierageData } from "../MesuresEmpoussierage/utils";
import { computeAccidentDtoWithArrayAccidentAndAccidentValues } from "../AccidentsTravail/converters";
import {
  convertMesureEmpoussierageDataToDeclarationEmpoussierageDto,
  overrideUncoherentValuesMesureEmpoussierage,
} from "../MesuresEmpoussierage/converters";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const updateStructureFonctionnelle = (
  declaration: Declaration1919,
  values: Nullable<StructureFonctionnelleData>,
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto
): Declaration1919 => {
  const coherentValues = overrideUncoherentValuesStructureFonctionnelle(values);
  declaration.body.sections.carriere.sante.prevention = convertStructureFonctionnelleDataToDeclarationPreventionDto(
    coherentValues,
    referentialOrganismeExterieur
  );
  return declaration;
};

export const updateAccidentsTravail = (
  declaration: Declaration1919,
  values: Nullable<AccidentsTravailData>,
  accidentsList: AccidentInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.sante.accident = computeAccidentDtoWithArrayAccidentAndAccidentValues(
    accidentsList,
    values
  );
  return declaration;
};

export const updateMesuresEmpoussierage = (
  declaration: Declaration1919,
  values: Nullable<MesureEmpoussierageData>,
  referentialOrganismeAccredite: ReferenceOrganismeAccrediteDto
): Declaration1919 => {
  const coherentValues = overrideUncoherentValuesMesureEmpoussierage(values);
  declaration.body.sections.carriere.sante.empoussierage = convertMesureEmpoussierageDataToDeclarationEmpoussierageDto(
    coherentValues,
    referentialOrganismeAccredite
  );
  return declaration;
};
