import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { AlcDto20Now, QuotasInstallationDto20Now } from "api/gen";
import { alcDtoToJustificatifsFilesDto } from "./utils";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useJustificatifAlcPreliminaireFileHandler21Now } from "pages/CompanySpace/from21/toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import { useDeclaration2122 } from "pages/CompanySpace/from21/to22/versionedElements/declarationHooks2122";

interface JustificatifsFilesLoaderProps {
  installation: QuotasInstallationDto20Now;
  filesPath: string;
  shouldDisableFields: boolean;
}

const JustificatifsFilesLoader = ({
  installation,
  filesPath,
  shouldDisableFields,
}: JustificatifsFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2122();
  const openAlertModale = useAlertModale();
  const justificatifAlcPreliminaireFileHandler = useJustificatifAlcPreliminaireFileHandler21Now(
    installation.id
  );

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER DOCUMENT(S) JUSTIFICATIF(S)"}
      filesPath={filesPath}
      section={FileSectionEnum.QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE}
      fileHandlers={justificatifAlcPreliminaireFileHandler}
      //
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(alcs: Record<string, unknown>[]) =>
        alcDtoToJustificatifsFilesDto(
          installation.id,
          (alcs as unknown) as AlcDto20Now[]
        )
      }
    />
  );
};

export default JustificatifsFilesLoader;
