import React from "react";
import { makeStyles } from "@material-ui/styles";
import arrowDown from "icons/arrow-down.svg";
import FilterForm from "./FilterForm";
import { GREY, ICON_GREY, SIMPLE_TEXT_INPUT_STYLE } from "theme";
import Button from "common/button";
import Card from "common/Card";
import FilterList from "./FilterList";
import { DeclarationFilters, FilterFormValues } from "../types";
import { useAutoClosedElement } from "common/utils/hooks/openableElems";
import { useReferentials } from "pages/CommonSpace/ReferentialsContext/hooks";

const useStyles = makeStyles({
  main: {
    width: "100%",
    display: "flex",
    position: "relative",
  },
  button: {
    width: "100px",
    color: ICON_GREY,
    "&:hover": {
      backgroundColor: GREY,
    },
  },
  img: {
    marginLeft: "15px",
    width: "15px",
  },
  filterList: {
    ...SIMPLE_TEXT_INPUT_STYLE.container,
    marginLeft: "5px",
    height: "inherit",
    width: "100%",
    maxWidth: "400px",
    flexWrap: "wrap",
  },
  formContainer: {
    position: "absolute",
    left: 0,
    top: "100%",
    marginTop: "5px",
    backgroundColor: "white",
    width: "500px",
    height: "inherit",
    zIndex: 11, // 'cause CommonEntityTable has zIndex=10
  },
});

export interface FilterFieldProps {
  filters: DeclarationFilters;
  setFilters: (filters: DeclarationFilters) => void;
  departementNumbersList: string[];
  years: number[];
}

const FilterField = ({
  filters,
  setFilters,
  departementNumbersList,
  years,
}: FilterFieldProps): React.ReactElement => {
  const classes = useStyles();
  const [formOpen, setFormOpen, cardRef] = useAutoClosedElement<
    HTMLDivElement
  >();
  const referenceLocationList = useReferentials([
    "REFERENCE_DEPARTEMENTS",
    "REFERENCE_REGIONS",
  ]);

  const handleFilterChange = (values: FilterFormValues) => {
    setFilters({ ...filters, ...values });
  };

  return (
    <main className={classes.main}>
      <Button
        text="Filtres"
        rightAlignedElem={
          <img src={arrowDown} alt="" className={classes.img} />
        }
        isReversed
        additionalClassname={classes.button}
        onClick={() => setFormOpen(!formOpen)}
      />
      <FilterList
        filters={filters}
        setFilters={handleFilterChange}
        additionalClassname={classes.filterList}
      />
      {formOpen && (
        <Card
          containerRef={cardRef}
          additionalClassname={classes.formContainer}
        >
          <FilterForm
            filters={filters}
            setFilters={handleFilterChange}
            close={() => setFormOpen(false)}
            departementNumbersList={departementNumbersList}
            referentialLocations={referenceLocationList}
            years={years}
          />
        </Card>
      )}
    </main>
  );
};

export default FilterField;
