import { ReceivedWasteStorage } from "../reception/types";
import {
  DeclarationWasteReceptionFormData,
  WasteReferentiels,
} from "../../types";
import {
  DechetRecuDto23Now,
  DechetRecuDto23NowFillingSourceEnum,
} from "api/gen";
import { isNotificationNumberAccessibleForReception } from "../reception/submitHandler";
import uuid from "uuid";
import { findElementMatchingTemplate } from "common/utils/methods";

export const convertReceptionStoredToModal = (
  updatingWaste: DechetRecuDto23Now | null,
  wasteData: WasteReferentiels
): Partial<DeclarationWasteReceptionFormData> => {
  const initialValues: Partial<DeclarationWasteReceptionFormData> = {};
  if (updatingWaste) {
    if (updatingWaste.codeID) {
      const codeID = findElementMatchingTemplate(
        { uuid: updatingWaste.codeID },
        wasteData.polluants
      );
      initialValues.wasteId = codeID ? codeID : updatingWaste.codeID;
    }

    initialValues.isNoLongerWaste =
      updatingWaste.sortiDuStatutDeDechet || undefined;

    initialValues.receptionAmount =
      updatingWaste.quantiteAdmise !== null
        ? updatingWaste.quantiteAdmise
        : undefined;

    initialValues.treatedAmount =
      updatingWaste.quantiteTraitee !== null
        ? updatingWaste.quantiteTraitee
        : undefined;

    if (updatingWaste.operationTraitement) {
      initialValues.operation = findElementMatchingTemplate(
        { code: updatingWaste.operationTraitement },
        wasteData.operationOrValorisationForProduction
      );
    }

    if (updatingWaste.estTraiteDansUnAutrePays !== null) {
      if (!updatingWaste.estTraiteDansUnAutrePays) {
        initialValues.location = wasteData.receptionLocation[0];
      } else {
        initialValues.location = wasteData.receptionLocation[1];
      }
    }

    if (updatingWaste.departementID) {
      initialValues.departement =
        findElementMatchingTemplate(
          { uuid: updatingWaste.departementID },
          wasteData.departements
        ) || updatingWaste.departementID;
    }

    if (updatingWaste.paysID) {
      initialValues.pays =
        findElementMatchingTemplate(
          { uuid: updatingWaste.paysID },
          wasteData.pays
        ) || updatingWaste.paysID;
    }

    initialValues.notification = updatingWaste.numeroNotification || undefined;
  }
  return initialValues;
};

export const convertModalToStoredData = (
  values: DeclarationWasteReceptionFormData,
  updatingWaste: ReceivedWasteStorage | null
): DechetRecuDto23Now => {
  if (!values.operation) {
    throw Error("Unknonw operation selected");
  } else if (!values.operation.isAvailableForProduction) {
    throw Error("Selected operation is not available for production");
  }

  const data = {
    id: updatingWaste ? updatingWaste.data.id : uuid(),
    codeID:
      typeof values.wasteId === "object" && values.wasteId !== null
        ? values.wasteId.uuid
        : null,
    sortiDuStatutDeDechet: values.isNoLongerWaste || false,
    quantiteAdmise: values.receptionAmount,
    quantiteTraitee: values.treatedAmount,
    operationTraitement: values.operation.code,
    estTraiteDansUnAutrePays: values.location.isCountryAccessible,
  };

  if (!data.estTraiteDansUnAutrePays) {
    if (typeof values.departement !== "object" || values.departement === null) {
      throw Error("This department does not exist in referential.");
    }

    const polluant: DechetRecuDto23Now = {
      ...data,
      departementID: values.departement.uuid,

      paysID: null,
      numeroNotification: null,
      fillingSource: DechetRecuDto23NowFillingSourceEnum.USER_INPUT,
    };
    return polluant;
  } else {
    if (typeof values.pays !== "object" || values.pays === null) {
      throw Error("This country does not exist in referential.");
    }

    const polluant: DechetRecuDto23Now = {
      ...data,
      departementID: null,

      paysID: values.pays.uuid,
      numeroNotification: isNotificationNumberAccessibleForReception(values)
        ? values.notification
        : null,
      fillingSource: DechetRecuDto23NowFillingSourceEnum.USER_INPUT,
    };
    return polluant;
  }
};
