import {
  ProduitTransportType,
  TransportProductsArrayForm,
  TransportProductsForm,
} from "./types";
import { convertModaliteTransportDtoToDisplayed } from "./converters";
import {
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";

export const computeTotalProductsTonnage = (
  transportProductsInArray: ProduitTransportType[]
): number => {
  let total = 0;
  transportProductsInArray.forEach(productInArray => {
    total += productInArray.quantite;
  });
  return total;
};

export const computeTransportProductsArrayFormInitialValues = (
  declaration: Declaration20Now,
  referentialFamilies: ReferenceFamilleUsageProductionDto
): TransportProductsArrayForm => {
  const productsTransport: Record<number, TransportProductsForm> = {};
  const modalitesTransport =
    declaration.body.sections.carriere.production.transport.modaliteTransports;

  referentialFamilies.referenceItemFamilleUsageProductionDtoList.forEach(
    referenceFamilleItem => {
      // we check if a modaliteTransport is present for this family by checking the libelle of the usageFamily from referential and our string in famille
      const existingValue = modalitesTransport.find(modalite => {
        return referenceFamilleItem.libelle === modalite.famille;
      });
      if (existingValue) {
        productsTransport[
          referenceFamilleItem.restorationCode
        ] = convertModaliteTransportDtoToDisplayed(
          [existingValue],
          referentialFamilies
        )[0];
      } else {
        productsTransport[referenceFamilleItem.restorationCode] = {
          usageFamily: referenceFamilleItem,
          byRoad: null,
          byRail: null,
          byWaterWay: null,
          other: null,
          otherPrecision: null,
        };
      }
    }
  );

  return {
    productsTransport: productsTransport,
  };
};

export const generateLabelFamily = (
  codeProduit: string,
  referentialFamiliesCodeProduits: ReferenceFamilleUsageCodeProduitDto
): string => {
  const libelleToDisplay = findElementMatchingTemplate(
    { codeProduit: codeProduit },
    referentialFamiliesCodeProduits.referenceItemFamilleUsageCodeProduitDtoList
  );

  return `${codeProduit} - ${libelleToDisplay && libelleToDisplay.libelle}`;
};

export const computeListDeclaredFamilies = (
  listDeclaredProducts: ProduitTransportType[]
): ReferenceItemFamilleUsageProductionDto[] => {
  return listDeclaredProducts
    .map(singleProduct => singleProduct.famille)
    .reduce(
      (acc: ReferenceItemFamilleUsageProductionDto[], referenceFamilleItem) => {
        if (referenceFamilleItem === null) {
          return acc;
        }

        if (
          acc.some(
            familleUsage =>
              familleUsage.restorationCode ===
              referenceFamilleItem.restorationCode
          )
        ) {
          return acc;
        }

        return acc.concat(referenceFamilleItem);
      },
      []
    );
};
