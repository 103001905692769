import React from "react";
import {
  BACKGROUND_GREY,
  DEFAULT_BORDER_RADIUS,
  INPUT_BORDER_WIDTH,
  PINK,
} from "theme";
import { makeStyles } from "@material-ui/styles";
import { computeClassname } from "./utils";
import { DummyChoiceInputProps } from "../types/dummyTypes";
import { ClassesType } from "common/utils/types";

const activeLabelStyle = {
  backgroundColor: PINK,
  color: "white",
};

const useStyles = makeStyles({
  buttonOption: {
    display: "inline-block",
    float: "left",
    margin: 0,
    padding: 0,
    fontSize: "1em",
    flex: 1,
  },
  radioInput: {
    display: "none",

    "&:checked + label": activeLabelStyle,
  },
  label: {
    border: `${INPUT_BORDER_WIDTH} solid ${PINK}`,
    padding: "8px 30px",
    cursor: "pointer",
    borderRadius: DEFAULT_BORDER_RADIUS,
    display: "flex",
    justifyContent: "center",
  },
  disabled: {
    opacity: 0.5,
    "& label": {
      borderColor: BACKGROUND_GREY,
      cursor: "not-allowed",
    },
    "& input:checked + label": {
      backgroundColor: BACKGROUND_GREY,
    },
  },
  active: {
    "& label:hover": activeLabelStyle,
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  error: {},
  warning: {},
});

function buildChoicesNode(
  choices: string[],
  classes: ClassesType<"container" | "buttonOption" | "radioInput" | "label">,
  name: string,
  uniqId: string,
  value: string | null,
  isDisabled?: boolean,
  readonly?: boolean,
  onChange?: (newValue: string) => void
) {
  return (
    <div className={classes.container}>
      {choices.map(singleChoice => {
        const currentId = `${uniqId}-value-${singleChoice}`;
        // first we define a useful const for the current iteration.

        return (
          <span className={classes.buttonOption} key={currentId}>
            <input
              className={classes.radioInput}
              type="radio"
              name={name}
              value={singleChoice} //la clef actuelle ds choice
              id={currentId} //name + la clef actuelle ds choice
              disabled={isDisabled} // more accessible for screen reader if they know whether this is disabled or not.
              defaultChecked={value === singleChoice} // a déduire de la value qu'on trouve ds fieldProps
              readOnly={readonly}
              onChange={event => {
                event.stopPropagation();

                onChange && onChange(singleChoice);
              }}
            />
            <label
              className={classes.label}
              htmlFor={currentId} //name + la clef actuelle ds choice
            >
              {singleChoice}
            </label>
          </span>
        );
      })}
    </div>
  );
}

const DummyChoiceInput = ({
  name,
  additionalClassName,
  disabled,
  choices,
  value,
  readOnly,
  onChange,
  error,
  ...props
}: DummyChoiceInputProps): React.ReactElement => {
  const classes = useStyles();

  const containerClassName = computeClassname(
    // todo decide of the right way to pass errors to compute the classname
    classes,
    error,
    disabled,
    additionalClassName
  );

  return (
    <div className={containerClassName} {...props}>
      {buildChoicesNode(
        choices,
        classes,
        name,
        props.id,
        value,
        disabled,
        readOnly,
        onChange
      ) //untested
      }
    </div>
  );
};

export default DummyChoiceInput;
