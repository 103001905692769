import React from "react";
import { DummyBlocProps } from "./types";
import GlobalFormActions from "common/actions/GlobalFormActions";
import { DummyUnActionedBloc } from "./DummyUnActionedBloc";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import InfoBulle from "../infoBulle/InfoBulle";

/**
 * basic page bloc display (grey border with title, content and form buttons below)
 * and button will be placed properly if it's the only bloc in the page or if you have several of it
 *
 * use case: wrapped in BlocInContext and DummyFormikBloc, should not be used elsewhere
 *
 * @param title: bloc title with it's own style
 * @param areButtonsInside: default is true, will show button inside the bloc with title "VALIDER" or under it with title "VALIDER PAGE"
 * @param additionalStyle: optional, additional bloc style
 * @param additionalClassName: optional, additional bloc class name
 * @param hasModification: see GlobalFormActions
 * @param isValidateButtonAvailable: see GlobalFormActions
 * @param validateAction: see GlobalFormActions
 * @param saveAction: see GlobalFormActions
 * @param editAction: see GlobalFormActions
 * @param cancelAction: see GlobalFormActions
 * @param isValidated: see GlobalFormActions
 * @param actionsAllowed: see GlobalFormActions
 * @param renderContent: content to render inside the bloc
 */
const DummyBloc = ({
  //  DummyBlocGlobalStylingProps
  title,
  validationTitle = "VALIDER",
  areButtonsInside = true,
  additionalStyle,
  additionalClassname,
  //  DummyBlocValidationProps
  hasModification,
  isValidateButtonAvailable,
  //  DummyBlocActionProps
  validateAction,
  saveAction,
  editAction,
  cancelAction,
  //  spinner
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  //  additional
  isValidated,
  actionsAllowed,
  renderContent,
  validationMessage,
  areGlobalCommentsAllowed,
  hasComments,
  commentAction,
  validationTooltipContent,
  icon,
}: DummyBlocProps): React.ReactElement => {
  if (!areButtonsInside && !validationMessage) {
    validationMessage = {
      message: DEFAULT_VALIDATION_MESSAGE,
      isAlwaysVisible: false,
    };
  }

  const buttons: React.ReactElement = (
    <GlobalFormActions
      validationTitle={areButtonsInside ? validationTitle : "VALIDER PAGE >"}
      isValidated={isValidated}
      hasChanges={hasModification}
      isValidateEnabled={isValidateButtonAvailable}
      actionsAllowed={actionsAllowed}
      editAction={editAction}
      cancelAction={cancelAction}
      saveAction={saveAction}
      validateAction={validateAction}
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      validationMessage={validationMessage}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed}
      commentAction={commentAction}
      hasComments={hasComments}
      validationTooltipContent={validationTooltipContent}
    />
  );
  return (
    <>
      <DummyUnActionedBloc
        title={title}
        additionalStyle={additionalStyle}
        additionalClassname={additionalClassname}
        renderContent={() => {
          return (
            <>
              {renderContent()}
              {areButtonsInside && buttons}
            </>
          );
        }}
        icon={icon}
      />
      {!areButtonsInside && buttons}
    </>
  );
};

export default DummyBloc;
