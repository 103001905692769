import React from "react";
import Row from "common/presentational/Row";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { pfcFieldMatcher } from "../utils/types";
import { makeStyles } from "@material-ui/styles";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import FormikBlocFullContext19Now from "../../../versionedElements/FormikBlocFullContext19Now";
import { pcfValidationSchema } from "../validation/validation";
import { AirGazPfcDto } from "api/gen";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface BlocPFCProps extends FormikBlocRefAndState<AirGazPfcDto> {
  validationPath: string;
  initialValues: AirGazPfcDto;
}

const BlocPFC = ({
  formikRef,
  hasChanges,
  setHasChanges,
  validationPath,
  initialValues,
}: BlocPFCProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "declarant-identity",
  };
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <FormikBlocFullContext19Now
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      validationSchema={pcfValidationSchema}
      title={"TABLEAU DES ÉMISSIONS DE PERFLUOROCARBURES (PFC)"}
      pathToValidate={validationPath}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) => {
        declaration.body.sections.air.gaz.pfc = values;
        return declaration;
      }}
      renderContent={(_, shouldDisabledFields) => {
        return (
          <>
            <Row />
            <NumberField
              name={pfcFieldMatcher.CF4}
              label={() => (
                <>
                  CF<sub>4</sub> (PFC-14)
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/cf4`}
            />
            <NumberField
              name={pfcFieldMatcher.C2F6}
              label={() => (
                <>
                  C<sub>2</sub>F<sub>6</sub> (PFC-116)
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c2f6`}
            />
            <NumberField
              name={pfcFieldMatcher.C3F8}
              label={() => (
                <>
                  C<sub>3</sub>F<sub>8</sub>
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c3f8`}
            />
            <NumberField
              name={pfcFieldMatcher.C4F10}
              label={() => (
                <>
                  C<sub>4</sub>F<sub>10</sub>
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c4f10`}
            />
            <NumberField
              name={pfcFieldMatcher.C5F12}
              label={() => (
                <>
                  C<sub>5</sub>F<sub>12</sub>
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c5f12`}
            />
            <NumberField
              name={pfcFieldMatcher.C6F14}
              label={() => (
                <>
                  C<sub>6</sub>F<sub>14</sub>
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c6f14`}
            />
            <NumberField
              name={pfcFieldMatcher.C4F8}
              label={() => (
                <>
                  C<sub>4</sub>F<sub>8</sub>
                </>
              )}
              unit="kg/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationPath}/c4f8`}
            />
          </>
        );
      }}
    />
  );
};

export default BlocPFC;
