import { AirCombustionInstallationsDto1819 } from "api/gen";
import { arrayInstallations } from "./selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { InstallationInArray } from "./types";

//=============================================================
// INSTALLATIONS
//=============================================================
export const convertInstallationsToDisplayed = (
  installations: AirCombustionInstallationsDto1819
): InstallationInArray => {
  const InstallationType =
    installations.type === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: installations.type } },
          arrayInstallations
        );
  return {
    data: {
      nom: installations.nom,
      type: InstallationType,
      heure: installations.heure,
      volumeActivite: installations.volumeActivite,
      unite: installations.unite,
      typeProduit: installations.typeProduit,
      quantiteChaleur: installations.quantiteChaleur,
      quantiteElectricite: installations.quantiteElectricite,
      rendementChaleur: installations.rendementChaleur,
      rendementElectricite: installations.rendementElectricite,
      id: installations.id,
    },
    //TODO repasser un coup pour ttes les erreurs
    errors: {},
  };
};

export const createAirCombustionsInstallationDto = (
  installationsInPage: InstallationInArray[]
): AirCombustionInstallationsDto1819[] => {
  const installations = installationsInPage.map(singlePopulatedInstallation => {
    const singleInstallation = singlePopulatedInstallation.data;
    const singleInstallationDto: AirCombustionInstallationsDto1819 = {
      id: singlePopulatedInstallation.data.id,
      nom: singleInstallation.nom,
      heure: singleInstallation.heure,
      volumeActivite: singleInstallation.volumeActivite,
      typeProduit: singleInstallation.typeProduit,
      unite: singleInstallation.unite,
      type: singleInstallation.type && singleInstallation.type.object.code,
      quantiteChaleur: singleInstallation.quantiteChaleur,
      quantiteElectricite: singleInstallation.quantiteElectricite,
      rendementChaleur: singleInstallation.rendementChaleur,
      rendementElectricite: singleInstallation.rendementElectricite,
    };
    return singleInstallationDto;
  });
  return installations;
};
