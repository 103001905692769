// common messages
//Unexpected template string expression. This string should be used by Yup.
export const tooLongMessage =
  "Ce champ doit contenir au maximum ${max} caractères."; // eslint-disable-line
export const requiredMessage = "Ce champ est requis.";
export const integerMessage = "Ce champ doit contenir un nombre entier.";
export const positiveMessage = "Ce champ doit contenir une valeur positive.";
export const strictlyPositiveMessage =
  "Ce champ doit contenir une valeur strictement positive.";
export const tooBigMessage =
  "La valeur entrée est trop élevée : veuillez entrer une valeur inférieure à 10³¹.";
export const atLeastOneMessage = "Au moins un champ doit être rempli.";
export const percentageMessage =
  "La valeur doit être comprise entre 0% et 100%.";
export const sumPercentagesMessage =
  "La somme des pourcentages doit être égale à 100%.";
export const valeursConcentrationMessage =
  "Renseigner des valeurs de concentration n'intégrant pas de correction d’incertitude.";
export const nimNumberMessage = "Le format du numéro n'est pas bon.";
export const futureDateMessage =
  "La date ne doit pas faire référence à un moment futur.";
export const noPipeMessage = 'Ce champ ne doit pas contenir le caractère "|".';
export const lessThanTwoDecimalsMessage =
  "Ce champ ne doit pas contenir un nombre avec plus de deux décimales après la virgule.";
export const generateLessThanNDecimalsMessage = (nbDecimal: number): string =>
  `Ce champ ne doit pas contenir un nombre avec plus de ${nbDecimal} décimale${
    nbDecimal === 1 ? "" : "s"
  } après la virgule.`;

// infos générales
export const emailMessage = "Ce champ doit contenir un email.";
export const phoneNumberMessage =
  "Ce champ doit contenir un numéro de téléphone.";

export const duplicateDeclarantMessage = "Ce déclarant existe déjà.";
export const wrongCommuneMessage = "Cette commune n'existe pas.";
export const codePostalMessage = "Code postal invalide.";
export const siretLengthMessage =
  "Un numéro de SIRET doit contenir exactement 14 caractères numériques.";
export const siretLuhnMessage =
  "Le numéro SIRET saisi est invalide, veuillez le corriger.";
export const siretNumberMessage =
  "Un numéro de SIRET doit contenir exclusivement des chiffres.";
export const hoursLengthMessage =
  "La valeur de ce champ doit être inférieure à 8785 heures.";
export const sirenLengthMessage =
  "Un numéro de SIREN doit contenir exactement 9 caractères numériques.";

// déchets
export const dechetsAtLeastOneMessage =
  "Au moins une valeur de déchet doit être renseignée.";
export const dechetsNotSurSite =
  "L'option sur site n'est pas disponible si l'onglet réception n'est pas accessible.";
export const codePostalFrancaisMessage =
  "Le code postal doit être au bon format et comporter 5 chiffres " +
  "(sans espaces ni au début, ni au milieu, ni à la fin).";
export const codePostalEtrangerMessage =
  "Le numéro ne correspond à aucune forme de code postal.";

// air - gaz fluorés
export const gazFluoresCapacityMessage =
  "Attention, la capacité installée est inférieure à la quantité émise.";
export const gazFluoresUniqueMessage = "Ce gaz existe déjà";

// air - élevage
export const elevageUniqueBuildingMessage =
  "Un bâtiment ayant ce nom existe déjà.";
export const elevageAtLeastOneEmissionMessage =
  "Au moins une émission doit être déclarée.";
export const elevageArrayBuildingsRequired =
  "Vous devez déclarer au moins un bâtiment.";

// air - combustion
export const appareilsUniqueMessage = "Cet appareil existe déjà.";
export const combustionInstallationTooMuchTimeMessage =
  "La valeur doit être inférieure à 8785 heures.";
export const combustionUniqueInstallationMessage =
  "Une installation ayant ce nom existe déjà.";
export const combustionTypeIsWrong =
  "Votre récapitulatif ne permet pas de déclarer ce type d'installation.";
export const hauteurRejetMessage =
  "La hauteur doit être comprise entre 0 et 300m";
export const partMessage = "La valeur doit être comprise entre 1% et 100%.";
export const nullFrequenceMessage =
  "Si la fréquence n'est pas renseignée, la mesure sera considérée par défaut comme continue.";
export const incorrectUnitForCombustibleMessage =
  "Cette unité est incompatible avec le combustible sélectionné.";

// air - procédés
export const procedesUniqueSubstanceMessage =
  "Ce couple substance/procédé existe déjà.";
export const procedesMasseVolumiqueMessage =
  "vous devez renseigner la masse volumique de la matière dans le sous-bloc déclaration de procédés";
export const procedesUniqueMessage = "Ce procédé existe déjà.";

// air - solvants
export const solvantsI1Message =
  "I1 doit être égal à O1 + O2 + O3 + O4 + O5 + O6 + O7 + O8 + O9";
export const solvantsEmissionsDiffusesMessage =
  "Les émissions diffuses ne peuvent pas être négatives.";
export const solvantsEmissionsTotalesMessage =
  "Les émissions totales ne peuvent pas être négatives.";
export const solvantsConsommationAnnuelleMessage =
  "La consommation annuelle de solvants étant supérieure à 30 t/an, il est nécessaire de déposer au moins un fichier PGS pour valider.";
export const solvantsConsommationAnnuelle2Message =
  "Si vous n'avez pas de consommation de solvants, veuillez le renseigner dans le bloc Informations Générales.";
export const solvantsUniqueInstallationMessage =
  "Cette installation existe déjà.";
export const solvantsCOVNMTotauxMessage =
  "La somme des émissions de COVNM totaux ne peut pas être inferieure à la somme des émissions des COV spécifiques.";
export const solvantsCOVSpecifiquesMessage =
  "La somme des émissions de COV spécifiques étant supérieure à 30 000 kg/an, vous devez déclarer des émissions de COVNM.";
export const solvantsCovMentionDangerMessage =
  "La somme des émissions de COV par mention de danger étant supérieure à 30 000 kg/an, vous devez déclarer des émissions COVNM totaux.";

// air - synthèse
export const syntheseTooManyAccidentalEmissionsMessage =
  "Vos émissions accidentelles ne peuvent pas être supérieures au total de vos émissions déclarées.";

// carrière - environnement
export const environnementAtLeastOneDechetInerteMessage =
  "Au moins une valeur de déchet inerte doit être renseignée.";
export const environnementProductionMoyenneMessage =
  "La production moyenne autorisée en kt/an ne peut pas être supérieure à la production maximale autorisée en kt/an.";
export const environnementAtLeastOneFieldMessage =
  "Au moins un des deux champs doit être renseigné.";
export const environnementAtLeastOneSurfaceMessage =
  "Au moins une valeur de surface en terre ou en eau remises doit être renseignée.";
export const environnementAtLeastOneSurfacePreviousYearMessage =
  "Au moins une valeur doit être renseignée dans les colonnes relatives aux surfaces en terre et en eau remises en état l’année N-1.";

// carrière - production
export const productionFamiliesCombinationSubstanceMessage =
  "Cette combinaison de substance, famille, sous-famille est déjà présente dans le tableau.";
export const productionFamiliesCominationProductMessage =
  "Cette combinaison de produit, famille, sous-famille est déjà présente dans le tableau.";
export const productionProductAlreadyDefinedForDepMessage =
  "Ce produit est déjà défini pour ce département. Si le tonnage doit être modifié, veuillez modifier la ligne déjà existante.";
export const productionProductAlreadyDefinedForStateMessage =
  "Ce produit est déjà défini pour cet état. Si le tonnage doit être modifié, veuillez modifier la ligne déjà existante.";
export const productionArrayRecyclingRequired =
  "Vous devez déclarer au moins un produit à recycler.";

// carrière - santé sécurité
export const santeSecuriteInvalidMaxValueMessage =
  "La valeur maximum doit être supérieure à la valeur minimum.";

// sol
export const solInvalidAccidentalMessage =
  "La valeur de ce champ doit être inférieure à celle du champ des émissions.";
export const solSubstanceInconnueMessage = "Cette substance est inconnue.";
export const solMethodInconnueMessage = "La méthode sélectionnée est inconnue.";
export const solMethodIncompatibleMessage =
  "La méthode de référence est incompatible avec la méthode sélectionnée.";
export const solSubstanceAlreadyInArrayMessage =
  "Cette substance a déjà été renseignée.";
export const solPolluantAlreadyInArrayMessage =
  "Cette substance a déjà été renseignée.";

// eau
export const eauInvalidNumberDaysMessage =
  "La valeur doit être inférieure à 366.";
export const eauTotalTakingThresholdMessage =
  "Dépassement du seuil de prélèvement.";
export const eauTakingAtLeastOneMessage =
  "Au moins un champ non nul est requis.";
export const eauMasseEmiseMessage =
  "La valeur doit être inférieure ou égale à la masse émise.";
export const eauPurificationYieldUnder100Message =
  "La valeur doit être inférieure à 100%.";
export const eauSubstanceAlreadyInArrayMessage =
  "Ce couple substance/type existe déjà.";

// quotas
export const quotasAtLeastCO2Message =
  "Vous devez déclarer au moins une émission de CO2.";
export const quotasAtLeastN2OMessage =
  "Vous devez déclarer au moins une émission de N2O.";
export const quotasAtLeastPFCMessage =
  "Vous devez déclarer au moins une émission de PFC.";
export const quotasFluxNamesMessage =
  "Les flux ne peuvent pas avoir le même nom.";
export const quotasPointMesureNamesMessage =
  "Les points de mesure ne peuvent pas avoir le même nom.";
export const quotasNIMSelectedMessage =
  "Veuillez remplir les champs pour tous les NIM sélectionnés.";
export const quotasNIMUniqueMessage =
  "Une installation avec ce numéro NIM existe déjà.";
export const quotasNombreActivitesMaxMessage =
  "Vous pouvez déclarer 5 activités maximum.";
export const quotasNombreActivitesMinMessage =
  "Vous devez déclarer au moins 1 activité.";
