import React, { ReactElement, useState } from "react";
import Button from "common/button";
import ContactInspectorGlobalModal from "common/modale/ContactInspectorGlobalModal";
import { makeStyles } from "@material-ui/styles";
import { useUserData } from "Authenticator/UserData";
import { useDeclaration21Now } from "../../versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  contactButton: {
    margin: "25px auto",
    padding: "10px 40px",
  },
});

const GlobalInspectorContactButton = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration21Now();
  const [isContactModalOpen, setIsContactModalOpen] = useState<boolean>(false);
  const { isDeclarant } = useUserData();

  return (
    <>
      {isDeclarant && (
        <Button
          text={"Envoyer un mail à l'inspecteur GEREP"}
          onClick={() => setIsContactModalOpen(true)}
          additionalClassname={classes.contactButton}
        />
      )}
      <ContactInspectorGlobalModal
        isOpen={isContactModalOpen}
        onRequestClose={() => setIsContactModalOpen(false)}
        emailsDeclarants={declaration.body.sections.infoGenerale.declarants
          .map(declarant => declarant.email)
          .filter((email): email is string => email !== null)}
      />
    </>
  );
};

export default GlobalInspectorContactButton;
