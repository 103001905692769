import React, { createContext, useEffect, useState } from "react";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  AnyDeclarationDto,
  DeclarationApi,
  DeclarationApiProviderProps,
} from "./utils/types";
import Spinner from "common/presentational/Spinner";
import { useAuthenticatedUser } from "Authenticator/AuthenticatedUser";
import Page404 from "pages/errors/errorPages/Page404";
import { getDeclaration } from "./utils/utils";
import _ from "lodash";
import * as HttpStatus from "http-status-codes";
import Page403CreationImpossible from "../../errors/errorPages/Page403CreationImpossible";

export const DeclarationApiContext = createContext<DeclarationApi | null>(null);

function DeclarationApiProvider({
  children,
  initializer,
}: DeclarationApiProviderProps): React.ReactElement {
  const authenticatedUser = useAuthenticatedUser();
  const { declarationController } = useAuthenticatedApi();

  const [responseStatus, setResponseStatus] = useState<number | null>(null);
  const [
    declarationDto,
    setDeclarationDto,
  ] = useState<AnyDeclarationDto | null>(
    initializer.type === "declarationData" ? initializer.dto : null
  );
  const [declarationApi, setDeclarationApi] = useState<DeclarationApi | null>(
    declarationDto !== null
      ? { declaration: declarationDto, setDeclaration: setDeclarationDto }
      : null
  );

  useEffect(() => {
    if (
      declarationDto !== null &&
      (initializer.type === "declarationData" ||
        (initializer.type === "declarationProps" &&
          declarationDto.annee ===
            parseInt(initializer.props.declarationAnnee) &&
          declarationDto.etablissementCode ===
            initializer.props.declarationEtablissement))
    ) {
      return;
    }

    let canceled = false;
    // all functions used are declared inside the useEffect to avoid warning of missing dependency in deps array
    const fetchDeclaration = async (annee: number, establishment: string) => {
      const declarationResponse = await getDeclaration(
        declarationController,
        annee,
        establishment,
        authenticatedUser.jwt
      );

      if (canceled) {
        return;
      }

      setResponseStatus(declarationResponse.status);

      if (
        declarationResponse.declaration !== null &&
        declarationResponse.jwt !== null
      ) {
        setDeclarationDto(declarationResponse.declaration);
        authenticatedUser.setJwt(declarationResponse.jwt);
      } else {
        setDeclarationApi(null);
        setDeclarationDto(null);
      }
    };

    if (
      initializer.type === "declarationData" &&
      !_.isEqual(declarationDto, initializer.dto)
    ) {
      setDeclarationDto(initializer.dto);
    } else if (
      initializer.type === "declarationProps" &&
      initializer.props.declarationAnnee != null &&
      initializer.props.declarationEtablissement !== ""
    ) {
      setDeclarationApi(null);
      fetchDeclaration(
        parseInt(initializer.props.declarationAnnee),
        initializer.props.declarationEtablissement
      );
    }

    return () => {
      canceled = false;
    };
  }, [declarationController, initializer, authenticatedUser, declarationDto]);

  useEffect(() => {
    if (!declarationDto) {
      return;
    }

    setDeclarationApi({
      declaration: declarationDto,
      setDeclaration: setDeclarationDto,
    });
  }, [declarationController, declarationDto]);

  if (
    declarationApi &&
    ((initializer.type === "declarationProps" &&
      declarationApi.declaration.annee ===
        parseInt(initializer.props.declarationAnnee)) ||
      (initializer.type === "declarationData" &&
        _.isEqual(declarationApi.declaration, initializer.dto)))
  ) {
    return (
      <DeclarationApiContext.Provider value={declarationApi}>
        {children}
      </DeclarationApiContext.Provider>
    );
  } else if (responseStatus === HttpStatus.FORBIDDEN) {
    return <Page403CreationImpossible />;
  } else if (responseStatus === null) {
    return <Spinner />;
  } else {
    return <Page404 />;
  }
}

export default DeclarationApiProvider;
