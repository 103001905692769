import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import Spinner from "common/presentational/Spinner";
import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { computeDeclarationUrl } from "./computePath";

interface RouteParams {
  etablissement: string;
}

const DeclarationRedirect = (): React.ReactElement => {
  const { etablissement } = useParams<RouteParams>();
  const { etablissementController } = useAuthenticatedApi();
  const [loading, setLoading] = useState(true);
  const [annee, setAnnee] = useState<number | null>(null);

  // Fetch year declared for etablissement to find the last one
  useEffect(() => {
    let ignore = false;

    async function findYearToRedirectTo() {
      const listOfDeclaredYears = await etablissementController.getAllYearsDeclaredForEtablissementUsingGET(
        etablissement
      );

      const lastYearDeclared =
        listOfDeclaredYears.length > 0
          ? Math.max(...listOfDeclaredYears)
          : null;

      if (ignore) {
        return;
      }

      // 2018 declaration are not displayed in GEREP
      if (lastYearDeclared !== 2018) {
        setAnnee(lastYearDeclared);
      }

      setLoading(false);
    }

    findYearToRedirectTo();

    return () => {
      ignore = true;
    };
  }, [etablissement, etablissementController]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Redirect to={computeDeclarationUrl(etablissement, "dashboard", annee)} />
  );
};

export default DeclarationRedirect;
