import React, { ReactElement } from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { Pds22Now } from "api/gen";
import { useDeclaration22Now } from "../../../../toNow/versionedElements/declarationHooks22Now";
import { usePdsFileHandler22Now } from "../../../../toNow/versionedElements/filesLoaderDisplayer22Now/sectionFilesHooks22Now";
import FilesLoader from "common/FilesLoader/FilesLoader";
import {
  NimFilesLoaderProps,
  oneFileRelatedToNimToFileDto,
} from "../../../../../from21/toNow/Quotas/common/FilesRelatedToNim/utils";

const PdsLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): ReactElement => {
  const declaration = useDeclaration22Now();
  const openAlertModale = useAlertModale();
  const pdsFileHandler = usePdsFileHandler22Now(installationId);

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE PLAN DE SURVEILLANCE DES ÉMISSIONS"}
      filesPath={
        "body.externalSections.quotas.emissions.blocPlanSurveillance.planSurveillanceList"
      }
      section={FileSectionEnum.QUOTAS_PLAN_SURVEILLANCE}
      fileHandlers={pdsFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(pdsList: Record<string, unknown>[]) =>
        oneFileRelatedToNimToFileDto(
          installationId,
          (pdsList as unknown) as Pds22Now[]
        )
      }
    />
  );
};

export default PdsLoader;
