import React, { useEffect } from "react";

interface ShouldNotHappenComponentProps {
  reason: string;
}

const ShouldNotHappenComponent = ({
  reason,
}: ShouldNotHappenComponentProps): React.ReactElement => {
  useEffect(() => {
    throw Error(reason);
  });

  return <div></div>;
};

export default ShouldNotHappenComponent;
