import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ImageProps } from "common/utils/types";
import { DASHBOARD_BORDER_GREY } from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    position: "relative",
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
    "& h3": {
      color: "#FFB026",
      fontSize: "18px",
      fontWeight: "normal",
      marginRight: "30px",
    },
    "& p": {
      marginTop: "20px",
    },
  },
  icon: {
    width: "30px",
    position: "absolute",
    top: "15px",
    right: "15px",
  },
});

interface MessageBoxProps {
  title: string;
  subject: string;
  comment: string;
  icon?: ImageProps;
  additionalClassname?: string;
}

const MessageBox = ({
  title,
  subject,
  comment,
  icon,
  additionalClassname,
}: MessageBoxProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.container, additionalClassname)}>
      <h3>{title}</h3>
      <p>
        <b>{subject}</b> : {comment}
      </p>
      <img alt="" {...icon} className={classes.icon} />
    </div>
  );
};

export default MessageBox;
