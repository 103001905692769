import {
  FiltresDeclarationDtoDeclarationGlobalStateEnum,
  FiltresDeclarationDtoQuotasAllocationsStateEnum,
  FiltresDeclarationDtoQuotasEmissionsStateEnum,
  SimpleDenormalizedDtoGeneralStateEnum,
  SimpleDenormalizedDtoQuotaAllocationsStateEnum,
  SimpleDenormalizedDtoQuotaEmissionsStateEnum,
  SimpleInstallationDenormalizedDtoQuotaAllocationsStateEnum,
  SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  DASHBOARD_DECLARATION_BLUE,
  DASHBOARD_DECLARATION_GREEN,
  DASHBOARD_DECLARATION_GREY,
  DASHBOARD_DECLARATION_PURPLE,
  DASHBOARD_DECLARATION_RED,
  DASHBOARD_DECLARATION_YELLOW,
} from "theme";

export type CommonDeclarationStateEnum =
  | FiltresDeclarationDtoDeclarationGlobalStateEnum
  | FiltresDeclarationDtoQuotasAllocationsStateEnum
  | FiltresDeclarationDtoQuotasEmissionsStateEnum
  | SimpleDenormalizedDtoGeneralStateEnum
  | SimpleDenormalizedDtoQuotaEmissionsStateEnum
  | SimpleDenormalizedDtoQuotaAllocationsStateEnum
  | SimpleInstallationDenormalizedDtoQuotaAllocationsStateEnum
  | SimpleInstallationDenormalizedDtoQuotaEmissionsStateEnum;

export interface DeclarationState {
  label: string;
  state: CommonDeclarationStateEnum;
  title: string;
  backgroundColor: string;
  textColor: string;
  rienADeclarer: boolean | null;
  isQuotasSpecific: boolean;
}

export const stateOptions: DeclarationState[] = [
  {
    label: "Non débutée",
    state: SimpleDenormalizedDtoGeneralStateEnum.NONE,
    title: "La déclaration n'a pas encore été débutée par l'exploitant.",
    backgroundColor: "white",
    textColor: "black",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "Activités à valider",
    state: SimpleDenormalizedDtoGeneralStateEnum.CREATED,
    title: "La déclaration n'a pas encore été débutée par l'exploitant.",
    backgroundColor: DASHBOARD_DECLARATION_RED,
    textColor: "white",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "En cours",
    state: SimpleDenormalizedDtoGeneralStateEnum.STARTED,
    title: "La déclaration est en cours de remplissage par l'exploitant.",
    backgroundColor: DASHBOARD_DECLARATION_GREY,
    textColor: "black",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  // SUBMITTED state is divided into 2 virtual states : "A valider" & "Rien a declarer"
  {
    label: "À valider",
    state: SimpleDenormalizedDtoGeneralStateEnum.SUBMITTED,
    title: "La déclaration a été transmise à l'inspection par l'exploitant.",
    backgroundColor: DASHBOARD_DECLARATION_PURPLE,
    textColor: "white",
    rienADeclarer: false, // SUBMITTED + not rienADeclarer => À valider
    isQuotasSpecific: false,
  },
  {
    label: "Rien à déclarer",
    state: SimpleDenormalizedDtoGeneralStateEnum.SUBMITTED,
    title: "La déclaration a été transmise à l'inspection par l'exploitant.",
    backgroundColor: DASHBOARD_DECLARATION_PURPLE,
    textColor: "white",
    rienADeclarer: true, // SUBMITTED + rienADeclarer => Rien à déclarer
    isQuotasSpecific: false,
  },
  {
    label: "Demande de modifications",
    state: SimpleDenormalizedDtoGeneralStateEnum.RECALLED,
    title:
      "L'exploitant vous a envoyé une demande de mise en révision afin de pouvoir apporter des modifications à la déclaration qu'il a transmise.",
    backgroundColor: DASHBOARD_DECLARATION_BLUE,
    textColor: "white",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "En révision",
    state: SimpleDenormalizedDtoGeneralStateEnum.IN_REVIEW,
    title: "La déclaration est en cours de correction par l'exploitant.",
    backgroundColor: DASHBOARD_DECLARATION_YELLOW,
    textColor: "white",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "Déclaration d'office",
    state: SimpleDenormalizedDtoGeneralStateEnum.FORCED,
    title: "La déclaration est en cours de remplissage par l'inspection.",
    backgroundColor: DASHBOARD_DECLARATION_GREEN,
    textColor: "white",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "Validée",
    state: SimpleDenormalizedDtoGeneralStateEnum.VALIDATED,
    title: "L'inspection a validé la déclaration.",
    backgroundColor: DASHBOARD_DECLARATION_GREEN,
    textColor: "white",
    rienADeclarer: null,
    isQuotasSpecific: false,
  },
  {
    label: "En attente de vérification",
    state: SimpleDenormalizedDtoGeneralStateEnum.VERIFYING,
    title:
      "La déclaration Quotas est en attente de vérification par le prestataire du déclarant.",
    backgroundColor: "white",
    textColor: "black",
    rienADeclarer: null,
    isQuotasSpecific: true,
  },
  {
    label: "Vérifiée",
    state: SimpleDenormalizedDtoGeneralStateEnum.VERIFIED,
    title:
      "La déclaration Quotas a été vérifiée par le prestataire du déclarant.",
    backgroundColor: "white",
    textColor: "black",
    rienADeclarer: null,
    isQuotasSpecific: true,
  },
];

export const computeStateOptionByFiltreState = (
  state: CommonDeclarationStateEnum | null,
  rienADeclarer: boolean | null
): DeclarationState | null => {
  if (!state) {
    return null;
  }

  return findElementMatchingTemplate({ state, rienADeclarer }, stateOptions);
};

export const globalStateOptions = stateOptions.filter(
  s =>
    !s.isQuotasSpecific &&
    s.state !== SimpleDenormalizedDtoGeneralStateEnum.NONE
);

export const quotasStateOptions = stateOptions.filter(
  s =>
    s.state !== SimpleDenormalizedDtoGeneralStateEnum.NONE &&
    s.rienADeclarer !== true
);

export const computeStateLabel = (s: DeclarationState): string => s.label;
