import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { PATH_INFO_GENERALE } from "common/path/path18Now";

export const GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION =
  'Veuillez valider le pavé "Informations générales" pour accéder au reste de la déclaration';

export const DISABLED_MESSAGE_FOR_CONDITIONNAL_SECTION =
  "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales";

export const PRESTATAIRE_DISABLED_MESSAGE_FOR_INACTIVE_SECTION =
  "Cette partie est indisponible car le déclarant n'a pas validé la première partie de sa déclaration";

export const getDisabledMessageForTypeActiviteConditionnalSection = (
  declarationApi: DeclarationHelpers
): string =>
  declarationApi.isPathValidatedInDeclaration(PATH_INFO_GENERALE)
    ? DISABLED_MESSAGE_FOR_CONDITIONNAL_SECTION
    : GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION;

export const getDisabledMessageForConditionnalSection = (
  declarationApi: DeclarationHelpers,
  customDisabledMessage: string
): string =>
  declarationApi.isPathValidatedInDeclaration(PATH_INFO_GENERALE)
    ? customDisabledMessage
    : GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION;

export const computeDisabledMessageForProfileForConditionalSection = (
  isPathValidatedInDeclaration: (path: string) => boolean,
  isAnyPrestataire: boolean
): string => {
  if (isPathValidatedInDeclaration(PATH_INFO_GENERALE)) {
    return DISABLED_MESSAGE_FOR_CONDITIONNAL_SECTION;
  } else {
    return isAnyPrestataire
      ? PRESTATAIRE_DISABLED_MESSAGE_FOR_INACTIVE_SECTION
      : GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION;
  }
};
