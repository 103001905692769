import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  BlocRejetPolluantOptions,
  PolluantInArray,
  PolluantInModale,
} from "../types";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import {
  additionalValidation,
  isFieldMethodDescriptionRequired,
  singlePolluantValidationSchema,
} from "./validation";
import {
  calculateEmission,
  calculateFinalEmission,
  computeNormesList,
  computeNormLabel,
  isFieldIncludingImportedActive,
  isFieldMethodReferenceActive,
  isFieldNormeActive,
  isPurificationYieldActive,
} from "../utils";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { methodsMessage } from "common/infoBulle/messages";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { methodLabel, typeRejetLabel } from "../selectPossibleValues";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface FormSinglePolluantProps {
  closeFunction: () => void;
  onSubmit: (
    values: PolluantInModale,
    formikActions: FormikActions<PolluantInModale>
  ) => void;
  polluantsInArray: PolluantInArray[];
  polluantInModal: PolluantInArray | null;
  selectPossibleValues: BlocRejetPolluantOptions;
}

const FormSinglePolluant = ({
  closeFunction,
  onSubmit,
  polluantsInArray,
  polluantInModal,
  selectPossibleValues,
}: FormSinglePolluantProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-eau-polluant-individuel",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE SUBSTANCE"
      closeFunction={closeFunction}
      onSubmit={onSubmit}
      isEdit={polluantInModal !== null}
      initialEntity={
        (polluantInModal && polluantInModal.data) || {
          pollutingSubstance: null,
          type: null,
          emission: null,
          includingImported: null,
          includingAccidental: null,
          usedMethod: null,
          methodReference: null,
          normes: null,
          methodDescription: null,
          purificationYield: null,
        }
      }
      validationSchema={singlePolluantValidationSchema}
      validate={values =>
        additionalValidation(values, polluantInModal, polluantsInArray)
      }
      renderField={({ values }) => {
        return (
          <>
            <WrappedChoiceSelectModale
              name="pollutingSubstance"
              label="Substance *"
              title="SUBSTANCES DANS L'EAU"
              header={[
                "CAS",
                "Libellé",
                "Seuil annuel (kg/an)",
                "Seuil journalier (g/jour)",
              ]}
              linesData={selectPossibleValues.polluantSubstances}
              formatLine={polluant => [
                polluant.cas || "",
                polluant.nom,
                polluant.seuilKgAn !== null
                  ? computeNumberWithSeparator(polluant.seuilKgAn)
                  : "",
                polluant.seuilGJ !== null
                  ? computeNumberWithSeparator(polluant.seuilGJ)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.nom}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.cas, lineData.nom],
                  searchedStr
                )
              }
              isFirstSticky={false}
              preferredColWidths={[250, 250, 150, 150]}
              commonProps={commonProps}
            />
            <WrappedChoiceSelect
              name="type"
              label="Type de rejet *"
              isMulti={false}
              options={selectPossibleValues.polluantTypes}
              computeLabel={dataline => typeRejetLabel[dataline]}
              commonProps={commonProps}
            />
            <NumberField
              name="emission"
              label="Masse émise *"
              unit="kg/an"
              tooltipContent={
                "Masse annuelle totale des rejets chroniques et accidentels, canalisés et diffus, d'un polluant incluant la masse importée. Pour les rejets raccordés (type de rejet : R), la masse émise totale correspond au rejet avant raccordement (encore appelé rejet brut)."
              }
            />
            {values.type && isFieldIncludingImportedActive(values.type) && (
              <NumberField
                name="includingImported"
                label="Dont masse importée *"
                unit="kg/an"
                tooltipContent={
                  "Pour les stations d'épuration urbaines, la masse importée est égale à 0"
                }
              />
            )}
            <NumberField
              name="includingAccidental"
              label="Dont masse accidentelle *"
              unit="kg/an"
            />
            <WrappedChoiceSelect
              name="usedMethod"
              label="Méthode *"
              isMulti={false}
              options={selectPossibleValues.polluantMethods}
              computeLabel={method => methodLabel[method]}
              commonProps={commonProps}
            />
            {isFieldMethodReferenceActive(values.usedMethod) && (
              <>
                <WrappedChoiceSelect
                  name="methodReference"
                  label="Référence de la méthode *"
                  isMulti={false}
                  options={selectPossibleValues.polluantRefMethods}
                  tooltipContent={methodsMessage}
                  computeLabel={dataline => dataline.toString()}
                  commonProps={commonProps}
                />
                <TextField
                  name="methodDescription"
                  label={`Description/Désignation de la méthode ${
                    isFieldMethodDescriptionRequired(
                      values.usedMethod,
                      values.methodReference
                    )
                      ? "*"
                      : ""
                  }`}
                />
              </>
            )}
            {isFieldNormeActive(values.methodReference) && (
              <WrappedChoiceSelect
                name="normes"
                label="Normes appliquées"
                options={computeNormesList(
                  selectPossibleValues.polluantNormes,
                  values.methodReference
                )}
                isMulti
                computeLabel={norme => computeNormLabel(norme)}
                commonProps={commonProps}
              />
            )}
            <DummyNumberField
              name="calculatedEmission"
              label="Masse émise retenue"
              unit="kg/an"
              disabled
              value={calculateEmission(values)}
            />
            {values.type && isPurificationYieldActive(values.type) && (
              <>
                <NumberField
                  name="purificationYield"
                  label="Rendement de la station externe sur le polluant"
                  unit="%"
                />
                {generateWarningMessageIfUnder1Percent(
                  values.purificationYield
                )}
              </>
            )}
            <DummyNumberField
              name="finalCalculatedEmission"
              label="Rejet final"
              unit="kg/an"
              disabled
              value={calculateFinalEmission(values)}
            />
          </>
        );
      }}
    />
  );
};

export default FormSinglePolluant;
