import * as Yup from "yup";
import {
  commonBooleanFields,
  commonPositiveNumberFields,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { spfoFieldMatcher } from "./types";

//TODO [GEREP-2579] il faudra remettre le shape SpfoDto ici.
export const validationSchema = Yup.object().shape({
  quantiteStockeeSurSite: commonPositiveNumberFields,
  quantiteUtilisee: commonPositiveNumberFields,
  quantiteEliminee: commonPositiveNumberFields,
  activiteTraitementSurface: commonBooleanFields,
  natureProcedeTraitementSurface: subStringFieldValidationScheme(
    spfoFieldMatcher.isSurfaceActivity,
    (type: boolean | null) => !!type
  ),
});
