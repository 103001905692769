import React, { MouseEvent } from "react";
import classNames from "classnames";

/**
 * Convert many string elements into a single one,
 * with commas as separators, all wrapped in a div with a title
 * @param elements collection of elements to convert
 */
export const renderSingleDiv = (elements: string[]) => {
  const text = elements.join(", ");
  return <div title={text}>{text}</div>;
};

export const renderHeaderCell = (
  data: React.ReactNode,
  style: React.CSSProperties,
  key: string,
  classes: Record<string, string>
) => {
  return (
    <div key={key} style={style} className={classes.flexToCenter}>
      {data}
    </div>
  );
};

export const renderGridCell = (
  data: React.ReactNode,
  style: React.CSSProperties,
  key: string,
  isBackgrounded: boolean,
  isOver: boolean,
  onMouseOver: () => void,
  onClick: (event: MouseEvent) => void,
  classes: Record<
    | "greyBackground"
    | "flexToCenter"
    | "noBackground"
    | "pinkBackground"
    | "textCenter",
    string
  >
) => {
  return (
    <div
      key={key}
      style={style}
      className={classNames(
        classes.flexToCenter,
        { [classes.greyBackground]: isBackgrounded && !isOver },
        { [classes.noBackground]: !isBackgrounded && !isOver },
        { [classes.pinkBackground]: isOver }
      )}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      <div className={classes.textCenter}>{data}</div>
    </div>
  );
};

export const getScrollbarWidth = (): number => {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode && outer.parentNode.removeChild(outer);

  return scrollbarWidth;
};
