import { PATH_QUOTAS } from "./path18Now";

//QUOTAS ALLOCATIONS
export const PATH_QUOTAS_ALLOCATIONS = `${PATH_QUOTAS}/allocations`;
export const PATH_QUOTAS_ALLOCATIONS_PMS = `${PATH_QUOTAS_ALLOCATIONS}/blocPlanMethodSurveillance`;
export const PATH_QUOTAS_ALLOCATIONS_PMS_LIST_PMS_FIELD = `${PATH_QUOTAS_ALLOCATIONS_PMS}/listPms`;
export const PATH_QUOTAS_ALLOCATIONS_EMAILS = `${PATH_QUOTAS_ALLOCATIONS}/blocEmails`;
export const PATH_QUOTAS_ALC_BLOC = `${PATH_QUOTAS_ALLOCATIONS}/blocAlc`;
export const PATH_QUOTAS_ALC_PRELIMINAIRE = `${PATH_QUOTAS_ALC_BLOC}/alcPreliminaire`;
export const PATH_QUOTAS_ALC_VERIFIE = `${PATH_QUOTAS_ALC_BLOC}/alcVerifie`;
export const PATH_QUOTAS_ALLOCATIONS_VERIFICATION_BLOC = `${PATH_QUOTAS_ALLOCATIONS}/blocVerification`;
//QUOTAS EMISSIONS
export const PATH_QUOTAS_EMISSIONS = `${PATH_QUOTAS}/emissions`;
export const PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC = `${PATH_QUOTAS_EMISSIONS}/blocPlanSurveillance`;
export const PATH_QUOTAS_EMISSIONS_BLOC = `${PATH_QUOTAS_EMISSIONS}/blocEmissions`;
export const PATH_QUOTAS_EMISSIONS_BLOC_METHODES_ALTERNATIVES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsMethodeAlternative`;
export const PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_CALCULEES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsCalculees`;
export const PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_MESUREES = `${PATH_QUOTAS_EMISSIONS_BLOC}/emissionsMesurees`;
export const PATH_QUOTAS_EMISSIONS_BLOC_FLUX = `${PATH_QUOTAS_EMISSIONS_BLOC}/flux`;
export const PATH_QUOTAS_EMISSIONS_BLOC_POINT_MESURE = `${PATH_QUOTAS_EMISSIONS_BLOC}/pointsMesure`;
export const PATH_QUOTAS_EMISSIONS_BLOC_DEROGATION = `${PATH_QUOTAS_EMISSIONS_BLOC}/derogationFrequenceEchantillonnage`;
export const PATH_QUOTAS_EMISSIONS_BLOC_FLUX_DEROGATION = `${PATH_QUOTAS_EMISSIONS_BLOC}/fluxSoumisADerogation`;
export const PATH_QUOTAS_EMISSIONS_BLOC_TRANSFERT_CO2 = `${PATH_QUOTAS_EMISSIONS_BLOC}/transfertCO2`;
export const PATH_QUOTAS_BILAN_BLOC = `${PATH_QUOTAS_EMISSIONS}/blocBilanTotal`;
export const PATH_QUOTAS_EMISSIONS_EMAILS = `${PATH_QUOTAS_EMISSIONS}/blocEmails`;
export const PATH_QUOTAS_EMISSIONS_VERIFICATION_BLOC = `${PATH_QUOTAS_EMISSIONS}/blocVerification`;
export const PATH_QUOTAS_INSPECTEUR_BLOC = `${PATH_QUOTAS_EMISSIONS}/blocInspecteur`;
