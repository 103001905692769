import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { getInstallation } from "../EmissionBloc/EmissionArray";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { CovInArray } from "./utils/types";
import _ from "lodash";
import { arrayMentionLabels } from "./utils/selectPossibleValues";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface CovArrayProps {
  covsInPage: CovInArray[];
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  setCovInModale: Dispatch<SetStateAction<CovInArray | null>>;
  setCovModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationCovsPath: string;
}

const CovArray = ({
  covsInPage,
  setCovsInPage,
  setCovInModale,
  setCovModaleOpen,
  isValidated,
  validationCovsPath,
}: CovArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressCov(singleCovInPage: CovInArray) {
    setCovsInPage(elderCovs => {
      return elderCovs.filter(elderCovInPage => {
        return singleCovInPage.data.id !== elderCovInPage.data.id;
      });
    });
  }

  // TODO  https://dl.polyconseil.fr/jira/browse/GEREP-657

  const computeSingleCovLine = (
    cov: CovInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      if (!isValidated) {
        setCovInModale(cov);
        setCovModaleOpen(true);
      }
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      cov.data.danger !== null
        ? arrayMentionLabels[cov.data.danger]
        : undefined,
      isValidated,
      !_.isEmpty(cov.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        cov.data.installations !== null
          ? getInstallation(cov.data.installations)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        cov.data.flux !== null ? computeNumberWithSeparator(cov.data.flux) : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        handlerSuppress={() => {
          suppressCov(cov);
        }}
        isValidated={isValidated}
        commentPath={`${validationCovsPath}/${cov.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = covsInPage.map(singleCovInPage => {
    return computeSingleCovLine(singleCovInPage, classes);
  });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Mention de danger</p>,
          <p>Installation(s)</p>,
          <p>Émissions (kg/an)</p>,
          <p>Actions</p>,
        ]}
        preferredColWidths={[150, 120, 120, ACTION_COLUMN_DEFAULT_WIDTH]}
        lines={arraySubstancesLines}
      />
    </>
  );
};

export default CovArray;
