import { Dispatch, SetStateAction, useRef, useState } from "react";
import { Formik } from "libAdapter/Formik/TypesPatternAdaptater";

// TODO: repass on those interface's types to be sure they are able to handle Partial<T> or Nullable<T> when required

export interface FormikBlocRefAndState<T> {
  formikRef: React.MutableRefObject<Formik<T> | null>;
  //  has bloc any changes and setter
  hasChanges: boolean;
  setHasChanges: Dispatch<SetStateAction<boolean>>;
}

export interface FormikBlocExtendedRefAndState<T>
  extends FormikBlocRefAndState<T> {
  // values and resetForm are only shortcuts to use some formikRef attributes
  getValues: () => T | null;
  resetForm: () => void;
}

export function useFormikBloc<T>(
  initialHasChanges = false
): FormikBlocExtendedRefAndState<T> {
  const [hasChanges, setHasChanges] = useState(initialHasChanges);
  const ref = useRef<Formik<T> | null>(null);
  return {
    formikRef: ref,
    hasChanges: hasChanges,
    setHasChanges: setHasChanges,
    getValues: () => ref.current && ref.current.state.values,
    resetForm: () => {
      ref.current && ref.current.resetForm();
    },
  };
}
