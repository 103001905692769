import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/button";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useConfirmationModale } from "common/modale/hooks";

interface ResetButtonProps {
  resetHandler: () => Promise<void>;
  confirmationMessage: string;
  confirmationMessageDetails?: string;
}

export const ResetButton = ({
  resetHandler,
  confirmationMessage,
  confirmationMessageDetails,
}: ResetButtonProps) => {
  const [isResetSpinnerVisible, triggerResetSpinner] = useSpinnerState<void>();
  const openConfirmationModale = useConfirmationModale();

  return (
    <>
      <Button
        text={
          <>
            RÉINITIALISER <FontAwesomeIcon icon="trash-alt" />
          </>
        }
        isSpinnerVisible={isResetSpinnerVisible}
        onClick={async () => {
          openConfirmationModale(
            confirmationMessage,
            async () => {
              await triggerResetSpinner(resetHandler());
            },
            confirmationMessageDetails
          );
        }}
      />
    </>
  );
};

export default ResetButton;
