import React, { ReactElement } from "react";
import {
  Co2N2oTransferDto,
  MethodeAlternativeDto,
  MethodeSurveillanceDto,
} from "api/gen";
import SubSectionTitle from "../../../../common/TitleComponents/SubSectionTitle";
import Row from "common/presentational/Row";
import MethodeCalculCo2Array from "./EmissionsArrays/MethodeCalculCo2Array";
import MethodeCalculPfcArray from "./EmissionsArrays/MethodeCalculPfcArray";
import MethodeMesureEmissionArray from "./EmissionsArrays/MethodeMesureEmissionArray";
import ImportedCo2N2oArray from "./EmissionsArrays/ImportedCo2N2oArray";
import ExportedCo2N2oArray from "./EmissionsArrays/ExportedCo2N2oArray";
import { makeStyles } from "@material-ui/styles";
import MethodeAlternativeSection from "./EmissionsArrays/MethodeAlternativeSection";
import {
  MethodeCalculCo2,
  MethodeCalculPfcDto,
  MethodeMesureEmission,
} from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import MethodesSurveillance from "../../../../common/MethodesSurveillance/MethodesSurveillance";

const useStyles = makeStyles({
  arraysContainer: {
    width: "85%",
    margin: "50px 0",
  },
});

interface MethodesEmissionsDataProps {
  methodeSurveillanceDto: MethodeSurveillanceDto;
  methodeCalculCo2List: MethodeCalculCo2[];
  methodeCalculPfcList: MethodeCalculPfcDto[];
  methodeMesureEmissionList: MethodeMesureEmission[];
  co2N2oTransferSourceList: Co2N2oTransferDto[];
  co2N2oTransferDestinationList: Co2N2oTransferDto[];
  methodeAlternativeDto: MethodeAlternativeDto;
}

const MethodesEmissionsData = ({
  methodeSurveillanceDto,
  methodeCalculCo2List,
  methodeCalculPfcList,
  methodeMesureEmissionList,
  co2N2oTransferSourceList,
  co2N2oTransferDestinationList,
  methodeAlternativeDto,
}: MethodesEmissionsDataProps): ReactElement => {
  const classes = useStyles();

  const shouldDiplayTransferSourceArray = co2N2oTransferSourceList.length > 0;
  const shouldDiplayTransferDestinationArray =
    co2N2oTransferDestinationList.length > 0;

  const {
    methodeCalculCo2,
    surveillancePfc,
    methodeMesureCo2,
    surveillanceN2o,
    surveillanceCo2,
    methodeAlternative,
  } = methodeSurveillanceDto;
  return (
    <>
      <SubSectionTitle>
        MÉTHODES DE SURVEILLANCE ET DE DÉTERMINATION DES ÉMISSIONS
      </SubSectionTitle>
      <Row height={"20px"} />
      <MethodesSurveillance methodeSurveillanceDto={methodeSurveillanceDto} />
      <div className={classes.arraysContainer}>
        {methodeCalculCo2 && (
          <>
            <MethodeCalculCo2Array
              methodeCalculCo2DtoList={methodeCalculCo2List}
            />
            <Row height={"20px"} />
          </>
        )}

        {surveillancePfc && (
          <>
            <MethodeCalculPfcArray
              methodeCalculPfcDtoList={methodeCalculPfcList}
            />
            <Row height={"20px"} />
          </>
        )}

        {(methodeMesureCo2 || surveillanceN2o || surveillanceCo2) && (
          <>
            <MethodeMesureEmissionArray
              methodeMesureEmissionList={methodeMesureEmissionList}
            />
            <Row height={"20px"} />
          </>
        )}

        {shouldDiplayTransferSourceArray && (
          <>
            <ImportedCo2N2oArray
              importedCo2N2oList={co2N2oTransferSourceList}
            />
            <Row height={"20px"} />
          </>
        )}
        {shouldDiplayTransferDestinationArray && (
          <>
            <ExportedCo2N2oArray
              exportedCo2N2oList={co2N2oTransferDestinationList}
            />
            <Row height={"20px"} />
          </>
        )}

        {methodeAlternative && (
          <MethodeAlternativeSection
            methodeAlternativeDto={methodeAlternativeDto}
          />
        )}
      </div>
    </>
  );
};

export default MethodesEmissionsData;
