import React from "react";
import logo from "icons/pageLogos/id-card.svg";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../CommonSpace/AppPage/PageHeaderContext";
import { GeneralInformationsController } from "./GeneralInformationsController";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import {
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
} from "api/gen";
import { CoordonneesDepartementMap } from "./utils/types";
import { wrapWithWorkflowTargetProvider } from "pages/CompanySpace/WorkflowTargetProviderContext";

const GeneralInformations = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Informations générales",
      to: match.url,
    },
  ];
  const imagedata = {
    src: logo,
    alt: "une carte d'identité simplifiée",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Informations générales",
    image: imagedata,
  };
  usePageHeaderSetterOnce(headerData);

  const computeCoordonneesDepartementMapFromArray = (
    coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[]
  ): CoordonneesDepartementMap => {
    const coordonneesDepartementMap = new Map();

    for (const coordonneesDepartement of coordonneesDepartementList) {
      const numeroDepartement: string =
        coordonneesDepartement.numeroDepartement;

      if (coordonneesDepartementMap.has(numeroDepartement)) {
        coordonneesDepartementMap
          .get(numeroDepartement)
          .set(
            coordonneesDepartement.systemeCoordonnees,
            coordonneesDepartement
          );
      } else {
        const systemToItem: Map<
          ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
          ReferenceItemCoordonneesDepartementDto
        > = new Map([
          [coordonneesDepartement.systemeCoordonnees, coordonneesDepartement],
        ]);

        coordonneesDepartementMap.set(numeroDepartement, systemToItem);
      }
    }

    return coordonneesDepartementMap;
  };

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_CODE_APE",
        "REFERENCE_CODE_INSEE_WITH_LOCATION",
        "REFERENCE_PAYS",
        "REFERENCE_EPRTR",
        "REFERENCE_COORDONNEES_DEPARTEMENT",
        "REFERENCE_ACTIVITES_QUOTAS",
      ]}
      render={referentials => {
        return (
          <GeneralInformationsController
            inspectionCode={match.params.etablissement}
            referentielAPE={referentials.REFERENCE_CODE_APE}
            referentielINSEE={referentials.REFERENCE_CODE_INSEE_WITH_LOCATION}
            referentielEPRTR={referentials.REFERENCE_EPRTR}
            referentielPays={referentials.REFERENCE_PAYS}
            coordonneesDepartementMap={computeCoordonneesDepartementMapFromArray(
              referentials.REFERENCE_COORDONNEES_DEPARTEMENT
                .referenceItemCoordonneesDepartementDtoList
            )}
            referentielActivites={referentials.REFERENCE_ACTIVITES_QUOTAS}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(GeneralInformations, "GLOBAL");
