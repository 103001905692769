import {
  AirSyntheseEmissionDtoMethodeEnum,
  AirSyntheseEmissionDtoReferenceCalculeEnum,
  AirSyntheseEmissionDtoReferenceMesureEnum,
  ReferenceItemPolluantDtoUniteUsuelleEnum,
} from "api/gen";

export const methodLabels: {
  [key in AirSyntheseEmissionDtoMethodeEnum]: string;
} = {
  MESURE: "Mesure",
  CALCUL: "Calcul",
  ESTIMATION: "Estimation",
};

export const arrayMethods: AirSyntheseEmissionDtoMethodeEnum[] = Object.keys(
  methodLabels
) as AirSyntheseEmissionDtoMethodeEnum[];

export const arrayMethodRefM: AirSyntheseEmissionDtoReferenceMesureEnum[] = [
  AirSyntheseEmissionDtoReferenceMesureEnum.INT,
  AirSyntheseEmissionDtoReferenceMesureEnum.PER,
  AirSyntheseEmissionDtoReferenceMesureEnum.NRO,
  AirSyntheseEmissionDtoReferenceMesureEnum.ALT,
  AirSyntheseEmissionDtoReferenceMesureEnum.MRC,
  AirSyntheseEmissionDtoReferenceMesureEnum.AUT,
];

export const arrayMethodRefC: AirSyntheseEmissionDtoReferenceCalculeEnum[] = [
  AirSyntheseEmissionDtoReferenceCalculeEnum.INT,
  AirSyntheseEmissionDtoReferenceCalculeEnum.PER,
  AirSyntheseEmissionDtoReferenceCalculeEnum.NRO,
  AirSyntheseEmissionDtoReferenceCalculeEnum.BMA,
  AirSyntheseEmissionDtoReferenceCalculeEnum.CSS,
  AirSyntheseEmissionDtoReferenceCalculeEnum.AUT,
];

export const unitLabels: {
  [key in ReferenceItemPolluantDtoUniteUsuelleEnum]: string;
} = {
  G_AN: "g/an",
  KG_AN: "kg/an",
  T_AN: "t/an",
  KT_AN: "kt/an",
  MG_AN: "mg/an",
  KG_J: "kg/j",
};
