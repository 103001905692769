import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useAlertModale } from "common/modale/hooks";
import { useCallback } from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageDownloadFile } from "common/backErrors/errorMessages";
import { useDeclaration } from "pages/CompanySpace/DeclarationApiContext/utils/genericHooks";

export const useDownloadRapportAlcVerifieN1Handler = (): ((
  fileDto: FileDto
) => Promise<Response | undefined>) => {
  const { fileController } = useAuthenticatedApi();
  const declaration = useDeclaration();
  const openAlertModale = useAlertModale();

  return useCallback(
    async (fileDto: FileDto): Promise<Response | undefined> => {
      try {
        return await fileController.downloadUsingGET(
          declaration.annee - 1,
          declaration.etablissementCode,
          fileDto.extension,
          fileDto.id,
          fileDto.name,
          fileDto.type,
          FileSectionEnum.QUOTAS_RAPPORT_ALC_VERIFIE
        );
      } catch (excp) {
        await openAlertModale(backAlertMessage(excp, backMessageDownloadFile));
        return undefined;
      }
    },
    [
      fileController,
      declaration.annee,
      declaration.etablissementCode,
      openAlertModale,
    ]
  );
};
