import { TechniqueGlobalValues } from "./utils";
import { ReferenceItemEprtrDto } from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { TypeActiviteGlobal21Now } from "../../versionedElements/declarationHooks21Now";

const getEprtrDtoById = (
  eprtrRef: ReferenceItemEprtrDto[],
  eprtrId: string
) => {
  const eprtr = findElementMatchingTemplate({ uuid: eprtrId }, eprtrRef);
  return eprtr;
};

const getEprtrDtoListByIds = (
  eprtrRef: ReferenceItemEprtrDto[],
  eprtrIds: string[]
) => {
  const eprtrs: ReferenceItemEprtrDto[] = [];
  eprtrIds.forEach(eprtrId => {
    const eprtr = getEprtrDtoById(eprtrRef, eprtrId);
    if (eprtr !== null) {
      eprtrs.push(eprtr);
    }
  });

  return eprtrs;
};

export const computeTechGlobalInitialValues = (
  typeActiviteGlobalDto: TypeActiviteGlobal21Now,
  referentielEPRTR: ReferenceItemEprtrDto[]
): TechniqueGlobalValues => {
  const {
    activitePrincipaleEPRTRID,
    activitesEPRTRIDs,
    ...commonActiviteField
  } = typeActiviteGlobalDto;

  return {
    ...commonActiviteField,
    principalEPRTR: getEprtrDtoById(
      referentielEPRTR,
      activitePrincipaleEPRTRID || ""
    ),
    activitesEPRTR: getEprtrDtoListByIds(referentielEPRTR, activitesEPRTRIDs),
  };
};

export const createTechniqueGlobalDto = (
  values: TechniqueGlobalValues
): TypeActiviteGlobal21Now => {
  const { principalEPRTR, activitesEPRTR, ...commonActiviteField } = values;

  return {
    ...commonActiviteField,
    activitePrincipaleEPRTRID: principalEPRTR && principalEPRTR.uuid,
    activitesEPRTRIDs: activitesEPRTR.map(eprtr => eprtr.uuid),
  };
};

export const overrideUncoherentValuesTechniqueDto = (
  techGlobal: TypeActiviteGlobal21Now
): TypeActiviteGlobal21Now => {
  if (!techGlobal.recepDechet) {
    techGlobal.incinerationDechet = false;
    techGlobal.isdnd = false;
    techGlobal.isdd = false;
    techGlobal.isdi = false;
  }
  if (!techGlobal.isdnd) {
    techGlobal.exporteBiogaz = false;
    techGlobal.torchereBiogaz = false;
    techGlobal.valorisationBiogaz = false;
  }
  if (!techGlobal.estEPRTR) {
    techGlobal.activitesEPRTRIDs = [];
    techGlobal.activitePrincipaleEPRTRID = null;
  }
  return techGlobal;
};
