import { makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";
import Modal from "react-modal";
import classNames from "classnames";
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_MODAL_SHADOW,
  GREY_OVERLAY,
} from "theme";
import Row from "./Row";
import { refType } from "../utils/types";

Modal.setAppElement("body");

const useStyles = makeStyles({
  overlayContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "auto",
    zIndex: 19, //should be big enough to override all the other z index.
  },
  overlay: {
    backgroundColor: GREY_OVERLAY,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    flexDirection: "column",
    minHeight: "100%",
  },
  content: {
    border: "1px solid #ccc",
    background: "#fff",
    WebkitOverflowScrolling: "touch",
    borderRadius: DEFAULT_BORDER_RADIUS,
    outline: "none",
    padding: "50px",
    height: "auto",
    minHeight: "100%",
    width: "70%",
    boxShadow: DEFAULT_MODAL_SHADOW,
  },
  container: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
});

const CustomModal = ({
  className,
  overlayClassName,
  children,
  onRequestClose,
  ...props
}: Readonly<Modal.Props> &
  Readonly<{ children?: ReactNode }>): React.ReactElement => {
  const classes = useStyles();
  const customOverlayClassName = classNames(classes.overlay, overlayClassName);
  const customContentClassName = classNames(classes.content, className);
  let wrapperRef: refType;

  /**
   * Set the wrapper ref. It allows to check when a click is made in the overlay to check if it was made on the modal or not.
   */
  const setModalRef = (node: refType) => {
    wrapperRef = node;
  };

  return (
    <Modal
      {...props}
      overlayClassName={classes.overlayContainer}
      className={classes.container}
      onRequestClose={onRequestClose}
    >
      {/*todo give all accessibility props not to the modal but to the div with custom content classname. */}
      <div
        className={customOverlayClassName}
        onClick={e => {
          e.stopPropagation();
          if (
            onRequestClose &&
            wrapperRef &&
            !wrapperRef.contains(e.target as Node)
          ) {
            onRequestClose(e);
          }
        }}
      >
        <Row height="50px" />
        <div className={customContentClassName} ref={setModalRef}>
          {children}
        </div>
        <Row height="50px" />
      </div>
    </Modal>
  );
};

export default CustomModal;
