import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { FluidInArray } from "./utils/types";
import {
  computeFluidArrayInitialValues,
  convertHfcInArrayToDto,
} from "./utils/converter";
import Row from "common/presentational/Row";
import BlocHFC from "./BlocHFC";
import BlocPFC from "./BlocPFC";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import isEqual from "lodash.isequal";
import { AirGazPfcDto, ReferenceGazDto } from "api/gen";
import { getPossibleGazList } from "./utils/selectPossibleValues";
import {
  PATH_AIR_GAZ,
  PATH_AIR_GAZ_HFC,
  PATH_AIR_GAZ_PFC,
} from "common/path/path18Now";
import {
  useDeclaration19Now,
  useDeclarationHelpers19Now,
} from "../../versionedElements/declarationHooks19Now";
import GlobalFormActionFullContext19Now from "../../versionedElements/GlobalFormActionFullContext19Now";

const useStyles = makeStyles({
  separator: {
    marginTop: "0px",
    marginBottom: "0px",
  },
});

interface GazFluoresProps {
  referentiel: ReferenceGazDto;
}

export const GazFluoresController = ({
  referentiel,
}: GazFluoresProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration19Now();
  const {
    isPathValidatedInDeclaration,
    getIsActiveSection,
  } = useDeclarationHelpers19Now();

  const memoReferentiel = useMemo(() => getPossibleGazList(referentiel), [
    referentiel,
  ]);

  const initialPFCData = declaration.body.sections.air.gaz.pfc;
  const initialFluidArray = computeFluidArrayInitialValues(
    declaration.body.sections.air.gaz.hfc,
    referentiel.gaz
  );

  const pfcFormProps = useFormikBloc<AirGazPfcDto>();
  const [fluidInArray, setFluidInArray] = useState<FluidInArray[]>(
    initialFluidArray
  );

  const isPFCValidated = isPathValidatedInDeclaration(PATH_AIR_GAZ_PFC);
  const isHFCValidated = isPathValidatedInDeclaration(PATH_AIR_GAZ_HFC);

  const canSubmitForm =
    (!getIsActiveSection(PATH_AIR_GAZ_PFC) && isHFCValidated) ||
    (!getIsActiveSection(PATH_AIR_GAZ_HFC) && isPFCValidated) ||
    (isPFCValidated && isHFCValidated);
  const hasChanges =
    pfcFormProps.hasChanges || !isEqual(initialFluidArray, fluidInArray);

  return (
    <>
      <Row />
      <>
        {getIsActiveSection(PATH_AIR_GAZ_PFC) && (
          <BlocPFC
            formikRef={pfcFormProps.formikRef}
            hasChanges={pfcFormProps.hasChanges}
            setHasChanges={pfcFormProps.setHasChanges}
            validationPath={PATH_AIR_GAZ_PFC}
            initialValues={initialPFCData}
          />
        )}

        <Row additionalClassname={classes.separator} />

        {getIsActiveSection(PATH_AIR_GAZ_HFC) && (
          <BlocHFC
            fluidInArray={fluidInArray}
            setFluidInArray={setFluidInArray}
            initialFluidInArray={initialFluidArray}
            validationPath={PATH_AIR_GAZ_HFC}
            possibleValues={memoReferentiel}
          />
        )}

        <Row />

        <GlobalFormActionFullContext19Now
          validationTitle={"VALIDER PAGE >"}
          validationMessage={{
            message: DEFAULT_VALIDATION_MESSAGE,
            isAlwaysVisible: false,
          }}
          hasChanges={hasChanges}
          isValidateEnabled={canSubmitForm}
          validationPath={PATH_AIR_GAZ}
          updateHandler={declaration => {
            if (pfcFormProps.formikRef.current) {
              declaration.body.sections.air.gaz.pfc =
                pfcFormProps.formikRef.current.state.values;
            }
            declaration.body.sections.air.gaz.hfc = convertHfcInArrayToDto(
              fluidInArray
            );
            return declaration;
          }}
          cancelAction={() => {
            if (pfcFormProps.formikRef.current) {
              pfcFormProps.formikRef.current.resetForm(initialPFCData);
            }
            setFluidInArray(initialFluidArray);
          }}
        />
      </>
    </>
  );
};
