import React from "react";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import FormikBloc from "common/formikBloc/FormikBloc";
import {
  CREATED,
  generateTransitionWarningMessage,
  STARTED,
} from "common/messages/dashboardMessage";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
  ReferenceItemEprtrDto,
} from "api/gen";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useTechniqueGlobalEditHandler,
  useTechniqueGlobalSaveHandler,
  useTechniqueGlobalValidateHandler,
} from "./hooks";
import { computeTechGlobalInitialValues } from "./handlers";
import { LEFT_WITHDRAW_STYLE, SECTION_TITLE_GREY } from "theme";
import { useConfirmationModale } from "common/modale/hooks";
import { techniqueGlobalFieldMatcher, TechniqueGlobalValues } from "./utils";
import _ from "lodash";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { useDeclaration21Now } from "../../versionedElements/declarationHooks21Now";
import { useDeclarationErrorAndWarnings21Now } from "../../versionedElements/errorAndWarningDisplayer21Now/utils";
import { PATH_TYPE_ACTIVITE_GLOBAL } from "../../../../../../common/path/path21Now";
import { preventSubmitWithErrors } from "./validation";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "20px",
    display: "flex",
  },
  separator: {
    height: "20px",
  },
  containerSpace: {
    padding: "10px 10px 25px 10px",
    fontSize: "17px",
  },
  row: {
    display: "flex",
    justifyContent: "spaceBetween",
    marginBottom: "15px",
  },
  subTitle: {
    color: SECTION_TITLE_GREY,
  },
  messageContainer: {
    height: "auto",
    justifyContent: "center",
  },
  ...LEFT_WITHDRAW_STYLE,
});

/**
 * @param referentielEPRTE      list of EPRTR referential
 */
interface TypeActiviteGlobalProps
  extends FormikBlocRefAndState<TechniqueGlobalValues> {
  referentielEPRTR: ReferenceItemEprtrDto[];
}

const TypeActiviteGlobal = ({
  referentielEPRTR,
  formikRef,
  hasChanges,
  setHasChanges,
}: TypeActiviteGlobalProps): React.ReactElement => {
  const classes = useStyles();
  const declarationDto = useDeclaration21Now();
  const openConfirmationModale = useConfirmationModale();
  const saveHandler = useTechniqueGlobalSaveHandler();
  const validateHandler = useTechniqueGlobalValidateHandler();
  const editHandler = useTechniqueGlobalEditHandler();

  const initialFormikValues = computeTechGlobalInitialValues(
    declarationDto.body.typeActivite.global,
    referentielEPRTR
  );

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "company-technique-global",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const declarationState = declarationDto.body.workflowStatus.general.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const areActionsAllowed =
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
    userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
    userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN;

  const isTechniqueValidated = declarationState !== CREATED;
  const shouldDisableFields = !areActionsAllowed || isTechniqueValidated;

  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<void>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<void>();

  const {
    warnings,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings21Now(
    PATH_TYPE_ACTIVITE_GLOBAL,
    false,
    declarationDto.body.workflowStatus.general.state !==
      DeclarationStateDtoStateEnum.CREATED
  );

  return (
    <FormikBloc
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      actionsAllowed={areActionsAllowed}
      isBlocValidated={isTechniqueValidated}
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      hasFormChanges={(values: TechniqueGlobalValues) =>
        !_.isEqual(initialFormikValues, values)
      }
      saveAction={async (values: TechniqueGlobalValues) => {
        await triggerSaveSpinner(saveHandler(values));
      }}
      editAction={async () => {
        openConfirmationModale(
          "Attention, cette action invalidera toutes les sections de la déclaration, êtes vous sûr de vouloir l'effectuer ?",
          async () => {
            await triggerEditSpinner(editHandler());
          }
        );
      }}
      validateAction={async (values: TechniqueGlobalValues) => {
        openConfirmationModale(
          generateTransitionWarningMessage(STARTED),
          async () => {
            await triggerErrorAndWarningDisplay(
              triggerValidateSpinner(validateHandler(values))
            );
          }
        );
      }}
      cancelAction={formikProps => {
        formikProps.resetForm();
      }}
      initialValues={initialFormikValues}
      title={"TYPE D'ACTIVITÉ GLOBAL"}
      additionalFieldValidation={preventSubmitWithErrors}
      additionalValidationAllowed={() => !hasError || hasChanges}
      renderContent={formikProps => {
        let filteredActivity;
        if (formikProps.values.estEPRTR && formikProps.values.principalEPRTR) {
          const currentPrincipalEPRTR = formikProps.values.principalEPRTR;
          filteredActivity = referentielEPRTR.filter(
            act => act.uuid !== currentPrincipalEPRTR.uuid
          );
        } else {
          filteredActivity = referentielEPRTR;
        }

        return (
          <>
            <div className={classes.containerSpace}>
              Si vous constatez des erreurs manifestes concernant le type
              d'activité global de l'établissement, vous devez prendre contact
              avec le service d'inspection dont dépend l'établissement afin que
              les corrections nécessaires soient apportées.
            </div>
            <BooleanField
              label="L'établissement est visé par le règlement 166/2006 (règlement E-PRTR)"
              name={techniqueGlobalFieldMatcher.EPRTR}
              disabled={shouldDisableFields}
              tooltipContent={
                <>
                  Établissement comportant au moins une activité visée à
                  l'Annexe I du règlement (CE) N°166/2006 sur la création d'un
                  registre européen des rejets et des transferts de polluants
                  (dit règlement E-PRTR). La majorité des établissements visés
                  par la directive IED sont également visés par le règlement
                  E-PRTR.&nbsp;
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ComparatifIEDE-PRTRv2.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Comparatif IED E-PRTR
                  </a>
                </>
              }
            />
            {formikProps.values.estEPRTR && (
              <div className={classes.withdrawLeft}>
                <WrappedChoiceSelect
                  label="Activité principale E-PRTR *"
                  name={techniqueGlobalFieldMatcher.principalEPRTR}
                  isMulti={false}
                  options={referentielEPRTR}
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  tooltipLabel
                  commonProps={commonProps}
                />
                <WrappedChoiceSelect
                  label="Activités secondaires E-PRTR"
                  name={techniqueGlobalFieldMatcher.activitesEPRTR}
                  options={filteredActivity}
                  isMulti
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  tooltipLabel
                  commonProps={commonProps}
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est un établissement d'élevage intensif de volailles ou de porcs (rubrique 3660)"
              name={techniqueGlobalFieldMatcher.elevage}
              disabled={shouldDisableFields}
              additionalOnChange={isElevage => {
                formikProps.setFieldValue(
                  techniqueGlobalFieldMatcher.EPRTR,
                  isElevage
                );
              }}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement possède une ou plusieurs installations de combustion d'une puissance supérieure à 20 MW"
              name={techniqueGlobalFieldMatcher.combustion20MW}
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement réceptionne / traite / stocke des déchets (y compris tri-transit-regroupement, incinération, compostage et méthanisation)"
              name={techniqueGlobalFieldMatcher.recepDechet}
              disabled={shouldDisableFields}
              tooltipContent={
                "Ne concerne pas le stockage temporaire, avant collecte, sur le site de production des déchets"
              }
            />
            {formikProps.values.recepDechet && (
              <div className={classes.withdrawLeft}>
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations d'incinération ou de co-incinération de déchets"
                  name={techniqueGlobalFieldMatcher.incinerationDechet}
                  disabled={shouldDisableFields}
                />
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets dangereux (ISDD)"
                  name={techniqueGlobalFieldMatcher.ISDD}
                  disabled={shouldDisableFields}
                />
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets non dangereux (ISDND)"
                  name={techniqueGlobalFieldMatcher.ISDND}
                  disabled={shouldDisableFields}
                />
                {formikProps.values.isdnd && (
                  <div className={classes.withdrawLeft}>
                    <BooleanField
                      label="L'ISDND possède une ou plusieurs torchères de biogaz"
                      name={techniqueGlobalFieldMatcher.torchereBiogaz}
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND dispose d'un système de valorisation du biogaz"
                      name={techniqueGlobalFieldMatcher.valorisationBiogaz}
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND exporte du biogaz"
                      name={techniqueGlobalFieldMatcher.exportBiogaz}
                      disabled={shouldDisableFields}
                    />
                  </div>
                )}
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets inertes (ISDI)"
                  name={techniqueGlobalFieldMatcher.ISDI}
                  disabled={shouldDisableFields}
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement consomme des solvants"
              name={techniqueGlobalFieldMatcher.solvants}
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est soumis à enquête annuelle carrière (rubrique 2510-1)"
              name={techniqueGlobalFieldMatcher.carriere}
              disabled={shouldDisableFields}
            />

            <ViolationProcessor errors={errors} warnings={warnings} />
          </>
        );
      }}
    />
  );
};

export default TypeActiviteGlobal;
