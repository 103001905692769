import { AccidentDto } from "api/gen";
import { Nullable } from "common/utils/types";
import { AccidentInArray, AccidentsTravailData } from "./utils";
import { convertAccidentInArrayToDescriptionAccident } from "./AccidentArray/converters";
import { Declaration20Now } from "../../../versionedElements/declarationHooks20Now";

export const computeInitialFormValues = (
  declarationData: Declaration20Now
): Nullable<AccidentsTravailData> => {
  return {
    bigAccident:
      declarationData.body.sections.carriere.sante.accident
        .accidentAvecArretTravail,
  };
};

export const computeAccidentDtoWithArrayAccidentAndAccidentValues = (
  arrayAccidents: AccidentInArray[],
  formValues: Nullable<AccidentsTravailData>
): AccidentDto => {
  const isArrayDisplayed = !!formValues.bigAccident;
  if (!isArrayDisplayed) {
    return { accidents: [], accidentAvecArretTravail: false };
  }
  return {
    accidents: arrayAccidents.map(convertAccidentInArrayToDescriptionAccident),
    accidentAvecArretTravail: true,
  };
};
