import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

export interface Mentions {
  name: string;
}

export const arrayMentions: OptionPropsWithObject<Mentions>[] = [
  { value: 1, label: "H340", object: { name: "H340" } },
  { value: 2, label: "H350", object: { name: "H350" } },
  { value: 3, label: "H350i", object: { name: "H350i" } },
  { value: 4, label: "H360D", object: { name: "H360D" } },
  { value: 5, label: "H360F", object: { name: "H360F" } },
  { value: 6, label: "H341", object: { name: "H341" } },
  { value: 7, label: "H351", object: { name: "H351" } },
];
