import React from "react";
import {
  AirCombustionCombustibleDto20Now,
  ReferenceItemCombustibleDto,
} from "api/gen";
import { isUnitePCS } from "./selectPossibleValues";
import { findElementMatchingTemplate } from "common/utils/methods";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import { CombustibleInArray } from "./types";
import { getValueOrZero } from "common/utils/numberUtils";

export const PCI_CONVERSION_FACTOR = 0.901;

export const getAppareils = (
  appareils: AppareilInArray[]
): React.ReactElement => {
  const appareilText = appareils.map(appareil => appareil.data.nom).join(", ");
  return <div title={appareilText}>{appareilText}</div>;
};

//=============================================================
// COMBUSTIBLES
//=============================================================
const getAppareil = (
  combustible: AirCombustionCombustibleDto20Now,
  appareils: AppareilInArray[],
  installationName: string
) => {
  return appareils.filter(appareil => {
    return (
      combustible.appareils.some(
        appareilInCombustible => appareilInCombustible === appareil.data.nom
      ) && appareil.data.nameInstallation === installationName
    );
  });
};

export const convertCombustiblesToDisplayed = (
  combustible: AirCombustionCombustibleDto20Now,
  referentials: ReferenceItemCombustibleDto[],
  appareils: AppareilInArray[]
): CombustibleInArray => {
  const type: ReferenceItemCombustibleDto | null =
    combustible.designationID === null
      ? null
      : findElementMatchingTemplate(
          { uuid: combustible.designationID },
          referentials
        );

  const unite = combustible.unite;

  const surCendre = combustible.surCendres;

  const pciUnite = combustible.pci.unite;

  const pciBrut = combustible.pci.brut;

  const consommationPCI =
    unite && combustible.consommation !== null && isUnitePCS(unite)
      ? combustible.consommation * PCI_CONVERSION_FACTOR
      : null;

  return {
    data: {
      type: type,
      code: combustible.code,
      nameInstallation: combustible.installation,
      consommation: combustible.consommation,
      unite: unite,
      appareils:
        combustible.installation !== null
          ? getAppareil(combustible, appareils, combustible.installation)
          : null,
      masse: combustible.masseVolumique,
      masseEcart: combustible.masseVolumiqueEcart,
      eau: combustible.eau,
      carbone: combustible.carbone,
      soufre: combustible.soufre,
      surCendres: surCendre,
      cendres: combustible.cendres,
      chlore: combustible.chlore,
      pciValeur: combustible.pci.valeur,
      pciUnite: pciUnite,
      pciEcart: combustible.pci.ecart,
      pciBrut: pciBrut,
      pciProvenance: combustible.pci.provenance,
      biogazFraction: combustible.biogaz.fraction,
      biogazEcart: combustible.biogaz.ecart,
      biogazDebit: combustible.biogaz.debit,
      biogazMesure: combustible.biogaz.mesure,
      biogazFrequence: combustible.biogaz.frequence,
      biogazDuree: combustible.biogaz.duree,
      ch4Teneur: combustible.biogaz.ch4.teneur,
      ch4Mesure: combustible.biogaz.ch4.mesure,
      ch4Frequence: combustible.biogaz.ch4.frequence,
      quantiteMethaneOxyde:
        combustible.biogaz.duree !== null &&
        combustible.biogaz.debit !== null &&
        combustible.biogaz.ch4.teneur !== null
          ? (getValueOrZero(combustible.biogaz.duree) *
              getValueOrZero(combustible.biogaz.debit) *
              getValueOrZero(combustible.biogaz.ch4.teneur)) /
            1000
          : null,
      id: combustible.id,
      consommationPCI: consommationPCI,
    },
    errors: {},
  };
};

export const createAirCombustionsCombustibleDto = (
  combustiblesInPage: CombustibleInArray[]
): AirCombustionCombustibleDto20Now[] => {
  return combustiblesInPage.map(singlePopulatedCombustible => {
    const singleCombustible = singlePopulatedCombustible.data;
    const singleCombustibleDto: AirCombustionCombustibleDto20Now = {
      id: singlePopulatedCombustible.data.id,
      code: singleCombustible.code,
      designationID: singleCombustible.type
        ? singleCombustible.type.uuid
        : null,
      installation: singleCombustible.nameInstallation,
      consommation: singleCombustible.consommation,
      unite: singleCombustible.unite,
      appareils:
        singleCombustible.appareils !== null
          ? singleCombustible.appareils.map(appareil => {
              return appareil.data.nom || "";
            })
          : [],
      masseVolumique: singleCombustible.masse,
      masseVolumiqueEcart: singleCombustible.masseEcart,
      eau: singleCombustible.eau,
      carbone: singleCombustible.carbone,
      soufre: singleCombustible.soufre,
      surCendres: singleCombustible.surCendres,
      cendres: singleCombustible.cendres,
      chlore: singleCombustible.chlore,
      pci: {
        valeur: singleCombustible.pciValeur,
        unite: singleCombustible.pciUnite,
        ecart: singleCombustible.pciEcart,
        brut: singleCombustible.pciBrut && singleCombustible.pciBrut,
        provenance: singleCombustible.pciProvenance,
      },
      biogaz: {
        fraction: singleCombustible.biogazFraction,
        ecart: singleCombustible.biogazEcart,
        debit: singleCombustible.biogazDebit,
        mesure: singleCombustible.biogazMesure,
        frequence: singleCombustible.biogazFrequence,
        duree: singleCombustible.biogazDuree,
        ch4: {
          teneur: singleCombustible.ch4Teneur,
          mesure: singleCombustible.ch4Mesure,
          frequence: singleCombustible.ch4Frequence,
        },
      },
    };

    return singleCombustibleDto;
  });
};

export const compareCombustibles = (
  a: CombustibleInArray,
  b: CombustibleInArray
): number => {
  if (
    a.data.type &&
    b.data.type &&
    a.data.type.designation !== b.data.type.designation
  ) {
    return a.data.type.designation < b.data.type.designation ? -1 : 1;
  }

  if (
    a.data.appareils &&
    b.data.appareils &&
    a.data.appareils !== b.data.appareils
  ) {
    return a.data.appareils < b.data.appareils ? -1 : 1;
  }
  return 0;
};
