import React from "react";
import Button from "common/button";
import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";
import profileImg from "common/Header/icons/user.svg";
import arrowImg from "common/Header/icons/arrow.svg";
import {
  DASHBOARD_BACKGROUND_GREY,
  DEFAULT_BORDER_RADIUS,
  SHADOW,
} from "theme";

const useStyles = makeStyles({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0",
    display: "flex",
    color: "#000000",
    top: 0,
    left: 0,
    width: "150px",
    height: "13px",
    position: "relative",
  },
  divImage: {
    marginLeft: "4px",
    padding: "0 0",
    float: "left",
    height: "30px",
    width: "30px",
  },
  divText: {
    margin: "3px 0",
    float: "left",
  },
  divArrow: {
    marginRight: "5px",
    padding: "0 0",
    float: "left",
    height: "13px",
    width: "13px",
  },
  shadow: {
    backgroundColor: DASHBOARD_BACKGROUND_GREY,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: "15px",
    boxShadow: SHADOW,
    "&:hover": {
      "& span img": {
        filter: "brightness(0) invert(1)",
      },
      "& span p": {
        color: "white",
      },
    },
  },
});

interface ProfilButtonProps {
  onClick: () => void;
}

export const ProfilButton = ({
  onClick,
}: ProfilButtonProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Button
      text={
        <span className={classes.container}>
          <span className={classNames(classes.divImage)}>
            <img src={profileImg} alt="Profil" height="100%" />
          </span>

          <span className={classNames(classes.divText)}>
            <p>Mes droits</p>
          </span>

          <span className={classNames(classes.divArrow)}>
            <img src={arrowImg} alt="Flèche" height="100%" width="100%" />
          </span>
        </span>
      }
      type="button"
      onClick={onClick}
      additionalClassname={classes.shadow}
    />
  );
};
