import {
  AirCombustionCombustiblePciDtoUniteEnum,
  ReferenceItemCombustibleDto,
} from "api/gen";
import { CombustibleInModale } from "./types";
import {
  arrayPciUniteCalcul,
  arrayPciUniteLabel,
  isUniteMasse,
  isUniteVolume,
} from "./selectPossibleValues";

export const getMessagePreciserPci = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null,
  masseVolumiqueCombustible: number | null
): string => {
  if (values.pciValeur !== null && values.pciUnite && values.type) {
    if (combustible) {
      return `La valeur du PCI déclarée pour ce combustible (${values.pciValeur.toFixed(
        2
      )} ${
        arrayPciUniteLabel[values.pciUnite]
      }${getConversionIndicationForPciMessage(
        values.pciUnite,
        values.pciValeur,
        masseVolumiqueCombustible
      )}) ne correspond pas aux valeurs usuellement rencontrées qui sont comprises entre ${
        combustible.pciReferenceInf
      } GJ/t et ${combustible.pciReferenceSup} GJ/t.`;
    }
    return "";
  }
  return "";
};

const getConversionIndicationForPciMessage = (
  pciUnite: AirCombustionCombustiblePciDtoUniteEnum,
  pciValeur: number,
  masse: number | null
): string =>
  pciUnite === AirCombustionCombustiblePciDtoUniteEnum.GJ_T
    ? ""
    : ` soit ${arrayPciUniteCalcul[pciUnite](pciValeur, masse).toFixed(
        2
      )} GJ/t`;

export const getMasseVolumiqueCombustible = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): number | null => {
  return values.masse === null && combustible !== null
    ? combustible.masseVolumique
    : values.masse;
};

export const shouldDisplayPreciserPci = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null,
  masseVolumique: number | null
): boolean => {
  return (
    (values.consommation !== null &&
      values.unite &&
      values.pciValeur !== null &&
      values.pciUnite &&
      combustible &&
      values.consommation > 0 &&
      (isUniteMasse(values.unite) ||
        (isUniteVolume(values.unite) && masseVolumique !== null)) &&
      combustible.pciReferenceInf &&
      combustible.pciReferenceSup &&
      !isPciInBounds(
        combustible.pciReferenceSup,
        combustible.pciReferenceInf,
        values.pciValeur,
        masseVolumique,
        values.pciUnite
      )) ||
    false
  );
};

const isPciInBounds = (
  sup: number,
  inf: number,
  value: number,
  masse: number | null,
  pciUnite: AirCombustionCombustiblePciDtoUniteEnum
): boolean => {
  const valueInGJt = arrayPciUniteCalcul[pciUnite](value, masse);
  return inf <= valueInGJt && valueInGJt <= sup;
};
