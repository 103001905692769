import { ReferenceItemPolluantDto } from "api/gen";

interface BiomasseTotalReturnObject {
  emissionAnnuellesBiomasse: number | null;
  emissionAnnuellesNonBiomasse: number | null;
}

/**
 * Compute the total CO2 biomasse and non biomasse from total CO2 and the percentage of CO2 biomasse
 * @param emissionTotal total emission of CO2 computed
 * @param percentageBiomasse percentage of CO2 biomasse (%)
 * @returns total emission of CO2 biomasse and CO2 non biomasse
 */
export const computeBiomasseTotal = (
  emissionTotal: number | null,
  percentageBiomasse: number | null
): BiomasseTotalReturnObject => {
  const emissionAnnuellesBiomasse: number | null =
    emissionTotal !== null && percentageBiomasse !== null
      ? /*Putting the parenthesis around the percentage conversion ensure a 100 percentage is actually considered as 1.
         *Otherwhise, the loss of precision due to multiplying a big number may make the operation loose precision BEFORE dividing by 100,
         *therefore making a 100 percentage be considered as different than 1 */
        emissionTotal * (percentageBiomasse / 100)
      : null;

  const emissionAnnuellesNonBiomasse: number | null =
    emissionTotal !== null && emissionAnnuellesBiomasse !== null
      ? emissionTotal - emissionAnnuellesBiomasse
      : null;

  return {
    emissionAnnuellesBiomasse: emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse: emissionAnnuellesNonBiomasse,
  };
};

export const isCo2 = (
  type: ReferenceItemPolluantDto | null,
  referentialCO2: ReferenceItemPolluantDto
): boolean => {
  return !!type && type.restorationCode === referentialCO2.restorationCode;
};
