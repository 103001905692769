import React, { useMemo } from "react";
import { SHORT_TEXT_INPUT_WIDTH } from "theme";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import {
  activiteFieldMatcher,
  ActivityType,
  AUTRE_MATIERE_PRODUITE,
  CoordonneesDepartementMap,
  InspectionServiceDto,
} from "../utils/types";
import { LocationHelper } from "./LocationHelper";
import {
  EquipeGunItemDto,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemEprtrDto,
  ReferenceItemGeoCodeInseeDto,
} from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import {
  getAssociatedSystemCoordinates,
  getRefCoordonneedDepartementDtoFromEnum,
} from "../utils/systemCoordinatesUtils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import {
  companyActivityValidationSchema,
  displayMandatory,
  isDeclarationLinkedToGun,
} from "./validation";
import { computeInspectionServiceLabel } from "./serviceUtils";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";
import { getRefApeDtoById, getRefCityInseeById } from "../utils/converter";
import {
  Declaration2122,
  useDeclaration2122,
} from "../../versionedElements/declarationHooks2122";
import FormikBlocFullContext2122 from "../../versionedElements/FormikBlocFullContext2122";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  additionalInfo: {
    height: "100px",
  },
  textJustify: {
    textAlign: "justify",
  },
});

interface CompanyActivityProps
  extends CommonConnectedFormikBlocProps<ActivityType, Declaration2122> {
  referentielEquipesGun: Map<string, EquipeGunItemDto>;
  inspectionServiceList: InspectionServiceDto[];
  referentielINSEE: ReferenceItemGeoCodeInseeDto[];
  referentielAPE: ReferenceItemCodeApeDto[];
  referentielEPRTR: ReferenceItemEprtrDto[];
  coordonneesDepartementMap: CoordonneesDepartementMap;
  coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[];
}

export const CompanyActivity = ({
  formikRef,
  hasChanges,
  setHasChanges,
  initialValues,
  path,
  updateHandler,
  referentielAPE,
  referentielINSEE,
  inspectionServiceList,
  referentielEquipesGun,
  referentielEPRTR,
  coordonneesDepartementMap,
  coordonneesDepartementList,
}: CompanyActivityProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2122();

  const estEprtr = declaration.body.typeActivite.global.estEPRTR;
  const mandatoryEprtrField = displayMandatory(estEprtr);

  const externalUnite =
    declaration.body.externalSections.infoGenerale.activite.unite;
  const externalMatiereProduite =
    declaration.body.externalSections.infoGenerale.activite.matiereProduite;

  const memoInspectionServiceIds = useMemo(
    () => inspectionServiceList.map(service => service.id),
    [inspectionServiceList]
  );

  const memoUnites = useMemo(
    () => Array.from(new Set(referentielEPRTR.map(({ unite }) => unite))),
    [referentielEPRTR]
  );
  const memoMatieresProduites = useMemo(() => {
    const matiereProduiteList = Array.from(
      new Set(referentielEPRTR.map(({ matiereProduite }) => matiereProduite))
    );
    matiereProduiteList.push(AUTRE_MATIERE_PRODUITE);
    return matiereProduiteList;
  }, [referentielEPRTR]);

  const gunActivite = isDeclarationLinkedToGun(declaration.etablissementCode)
    ? declaration.body.externalSections.infoGenerale.activite
    : undefined;

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-activity",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);

  const locationHelper = useMemo(() => {
    return <LocationHelper />;
  }, []);

  return (
    <FormikBlocFullContext2122
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      validationSchema={companyActivityValidationSchema}
      title={"INFORMATIONS RELATIVES A L'ÉTABLISSEMENT"}
      pathToValidate={path}
      areGlobalCommentsAllowed={true}
      updateHandler={updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <span className={classes.textJustify}>
              Les données, en lecture seule, dans cette section sont
              pré-remplies à partir de l’application des installations classées
              GUNenv. Si vous constatez des erreurs manifestes concernant les
              informations administratives de l'établissement, vous devez
              prendre contact avec le service d'inspection dont dépend
              l'établissement afin que les corrections nécessaires soient
              apportées. La partie relative à la production du site est
              accessible en écriture.
            </span>
            <Row />
            <DummyTextField
              name={activiteFieldMatcher.inspectionCode}
              label="Code inspection *"
              value={formProps.values.codeInspection}
              disabled={true}
            />

            <DummyChoiceSelectWithLabel<string, false>
              name={activiteFieldMatcher.inspectionService}
              label={"Service d’inspection *"}
              isMulti={false}
              options={memoInspectionServiceIds}
              value={
                gunActivite?.identifiantService ||
                formProps.values.identifiantService
              }
              computeLabel={serviceId =>
                computeInspectionServiceLabel(serviceId, inspectionServiceList)
              }
              disabled
              commonProps={commonProps}
            />
            {gunActivite?.identifiantEquipe && (
              <DummyTextField
                name={"equipeInspection"}
                label={"Identifiant équipe"}
                value={
                  referentielEquipesGun.get(gunActivite?.identifiantEquipe)
                    ?.nomEquipe || gunActivite.identifiantEquipe
                }
                disabled={true}
              />
            )}
            <TextField
              name={activiteFieldMatcher.establishmentName}
              label="Nom de l'établissement *"
              disabled={shouldDisabledFields}
              externalValue={gunActivite?.nomEtablissement}
            />
            <TextField
              name={activiteFieldMatcher.installationAdress}
              label="Adresse du site *"
              disabled={shouldDisabledFields}
              externalValue={gunActivite?.installationAdresse}
            />
            <WrappedChoiceSelectModale
              name={activiteFieldMatcher.installationCity}
              label={"Commune *"}
              disabled={shouldDisabledFields}
              title="Commune"
              header={["Code INSEE", "Code postal", "Commune", "Département"]}
              linesData={referentielINSEE}
              formatLine={insee => [
                insee?.codeInsee || "",
                insee?.codePostal || "",
                insee?.nomCommune || "",
                insee?.numeroDepartement.toString() || "",
              ]}
              formatSelectedTitle={lineData => lineData?.nomCommune || ""}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData?.codeInsee,
                    lineData?.codePostal,
                    lineData?.nomCommune,
                    lineData?.numeroDepartement,
                  ],
                  searchedStr
                )
              }
              preferredColWidths={[100, 100, 250, 80]}
              commonProps={commonProps}
              onSelect={data => {
                formProps.setFieldValue(
                  activiteFieldMatcher.installationPostalCode,
                  data ? data.codePostal : data
                );
              }}
              externalValue={
                gunActivite === undefined
                  ? undefined
                  : getRefCityInseeById(
                      referentielINSEE,
                      gunActivite.installationCity || ""
                    )
              }
            />
            <TextField
              name={activiteFieldMatcher.installationPostalCode}
              label="Code postal *"
              disabled={shouldDisabledFields}
              externalValue={gunActivite?.installationCodePostal}
            />
            <TextField
              name={activiteFieldMatcher.SIRETNumber}
              label="Numéro SIRET"
              disabled={shouldDisabledFields}
              externalValue={gunActivite?.siret}
            />
            <WrappedChoiceSelectModale
              name={activiteFieldMatcher.NAFCode}
              label={"Code NAF"}
              disabled={shouldDisabledFields}
              title="Code NAF"
              header={["Code NAF", "Libellé"]}
              linesData={referentielAPE}
              formatLine={ape => [ape?.codeApe || "", ape?.libelle || ""]}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData?.codeApe, lineData?.libelle],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData?.codeApe || ""}
              preferredColWidths={[100, 300]}
              commonProps={commonProps}
              externalValue={
                gunActivite === undefined
                  ? undefined
                  : getRefApeDtoById(
                      referentielAPE,
                      gunActivite.codeNafID || ""
                    )
              }
            />
            <DummyTextField
              name="principalActivity"
              label="Activité principale"
              value={
                gunActivite === undefined
                  ? formProps.values.codeNaf?.libelle
                  : getRefApeDtoById(
                      referentielAPE,
                      gunActivite.codeNafID || ""
                    )?.libelle
              }
              disabled={true}
            />

            <Row />

            <WrappedChoiceSelect
              name={activiteFieldMatcher.systemCoordinates}
              label="Système de coordonnées géographiques *"
              isMulti={false}
              options={getAssociatedSystemCoordinates(
                formProps.values,
                coordonneesDepartementMap
              )}
              disabled={shouldDisabledFields}
              computeLabel={coordonnees =>
                coordonnees.systemeCoordonnees.toString()
              }
              commonProps={commonProps}
              externalValue={
                gunActivite === undefined
                  ? undefined
                  : getRefCoordonneedDepartementDtoFromEnum(
                      gunActivite.systemeCoordonnees,
                      coordonneesDepartementList
                    )
              }
            />
            <TextField
              name={activiteFieldMatcher.longitude}
              label="Abscisse/Longitude/X *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              additionalOnChange={newValue => {
                const formattedValue =
                  newValue && newValue.replaceAll(".", ",");
                formProps.setFieldValue(
                  activiteFieldMatcher.longitude,
                  formattedValue
                );
              }}
              tooltipContent={locationHelper}
              externalValue={gunActivite?.abscisse}
            />
            <TextField
              name={activiteFieldMatcher.latitude}
              label="Ordonnée/Latitude/Y *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              additionalOnChange={newValue => {
                const formattedValue =
                  newValue && newValue.replaceAll(".", ",");
                formProps.setFieldValue(
                  activiteFieldMatcher.latitude,
                  formattedValue
                );
              }}
              tooltipContent={locationHelper}
              externalValue={gunActivite?.ordonnee}
            />

            <Row />

            <NumberField
              name={activiteFieldMatcher.productionVolume}
              label={`Volume de production`}
              unit=""
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelect
              name={activiteFieldMatcher.unit}
              label={`Unité${mandatoryEprtrField}`}
              isMulti={false}
              options={memoUnites}
              disabled={shouldDisabledFields}
              computeLabel={unite => unite}
              commonProps={commonProps}
              externalValue={estEprtr ? externalUnite : undefined}
            />
            <WrappedChoiceSelect
              name={activiteFieldMatcher.producedMatter}
              label={`Matière produite/Type de produits${mandatoryEprtrField}`}
              isMulti={false}
              options={memoMatieresProduites}
              disabled={shouldDisabledFields}
              computeLabel={matiereProduite => matiereProduite}
              commonProps={commonProps}
              externalValue={estEprtr ? externalMatiereProduite : undefined}
            />
            {!estEprtr &&
              formProps.values.matiereProduite === AUTRE_MATIERE_PRODUITE && (
                <TextField
                  name={activiteFieldMatcher.otherProducedMatter}
                  label="Préciser autre"
                  disabled={shouldDisabledFields}
                />
              )}
            <NumberField
              name={activiteFieldMatcher.workDuration}
              label="Nombre d'heures d'exploitation au cours de l'année"
              unit="heures"
              disabled={shouldDisabledFields}
            />
            <NumberField
              name={activiteFieldMatcher.employee}
              label="Nombre d'employés"
              unit=""
              disabled={shouldDisabledFields}
            />
            <TextField
              name={activiteFieldMatcher.website}
              label="Adresse du site internet"
              disabled={shouldDisabledFields}
              tooltipContent={""}
            />
            <TextArea
              name={activiteFieldMatcher.additionalInfo}
              label="Informations complémentaires / remarques"
              disabled={shouldDisabledFields}
              additionalClassName={classes.additionalInfo}
            />
          </>
        );
      }}
    />
  );
};
