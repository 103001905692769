import {
  AllowedDeclarationMethods,
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasOptions,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "../types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  QuotasBlocEmissionsDto1819,
  QuotasBlocPlanSurveillanceDto1819,
} from "api/gen";
import { InstallationInArray } from "../../blocInstallations/types";
import {
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  flowLabelToId,
  fluxDtoToFlowDeclaration,
  pointMesureDtoToMeasureDeclaration,
} from "../converter";
import { Formik } from "libAdapter/Formik/TypesPatternAdaptater";

//data function. Shouldn't be called directly
const isGasCO2 = (gas: OptionPropsWithObject<GasOptions>) => {
  return gas.object === GasOptions.CO2;
};

const isGasN2OOrPFC = (gas: OptionPropsWithObject<GasOptions>) => {
  return gas.object === GasOptions.N2O || gas.object === GasOptions.PFC;
};

// should be a method taking an object, but because of yup, currently taking only the gas key as a parameter
export const isOtherEmissionSubPartActivated = (
  gas: OptionPropsWithObject<GasOptions> | null
): boolean => {
  return !!gas && isGasN2OOrPFC(gas);
};

// should be a method taking an object, but because of yup, currently taking only the gas key as a parameter
export const isBiomasseSubPartActivated = (
  gas: OptionPropsWithObject<GasOptions> | null
): boolean => {
  return !!gas && isGasCO2(gas);
};

export const overWriteEmissionDependantFields = (
  emission: MeasureEmissionInArray | AlternativeEmissionInArray
): void => {
  if (!isBiomasseSubPartActivated(emission.data.gas)) {
    emission.data.biomassEmission = null;
    emission.data.fossilEmission = null;
  }
  if (!isOtherEmissionSubPartActivated(emission.data.gas)) {
    emission.data.otherEmission = null;
  }
};

export const findMeasureDeclaration = (
  id: string,
  measureDeclarations: MeasureDeclarationProps[]
): MeasureDeclarationProps | null => {
  return measureDeclarations.find(m => m.id === id) || null;
};

export const findFlowDeclaration = (
  id: string,
  flowDeclarations: FlowDeclarationProps[]
): FlowDeclarationProps | null => {
  return flowDeclarations.find(f => f.id === id) || null;
};

export const hasSameNames = (
  flowOrMeasures: FlowDeclarationProps[] | MeasureDeclarationProps[]
): boolean => {
  for (const obj of flowOrMeasures) {
    for (const otherObj of flowOrMeasures) {
      if (obj.name && obj.name === otherObj.name && obj.id !== otherObj.id) {
        return true;
      }
    }
  }
  return false;
};

const isComputedMethodsAllowed = (
  planEmissionsDto: QuotasBlocPlanSurveillanceDto1819
) => {
  return !!(
    planEmissionsDto.calculBilanMassique ||
    planEmissionsDto.calculFacteurEmissionConversion ||
    planEmissionsDto.calculFacteurEmissionOxydation
  );
};

export const computeAllowedDeclarationsMethods = (
  planEmissionsDto: QuotasBlocPlanSurveillanceDto1819
): AllowedDeclarationMethods => {
  return {
    alternative: !!planEmissionsDto.estMethodeAlternative,
    computed: isComputedMethodsAllowed(planEmissionsDto)
      ? {
          bilanMassique: !!planEmissionsDto.calculBilanMassique,
          facteurConversion: !!planEmissionsDto.calculFacteurEmissionConversion,
          facteurOxydation: !!planEmissionsDto.calculFacteurEmissionOxydation,
        }
      : false,
    mesure: !!planEmissionsDto.estMesure,
  };
};

export const generateSetterBlocEmissions = (
  installationsList: InstallationInArray[],
  blocEmissionsRef: React.MutableRefObject<Formik<
    BlocEmissionsFormValues
  > | null>,
  setAlternativeEmissionsInArray: (
    alternativesEmissionsInArray: AlternativeEmissionInArray[]
  ) => void,
  setMeasuredEmissionInArray: (
    measuredEmissionsInArray: MeasureEmissionInArray[]
  ) => void,
  setComputedEmissionsInArray: (
    computedEmissionsInArray: ComputedEmissionInArray[]
  ) => void,
  setExemptedFlowsSelected: (exemptedFlows: string[]) => void
): ((blocEmissionsDto: QuotasBlocEmissionsDto1819) => void) => {
  return blocEmissionsDto => {
    const resetFlowDeclarations = blocEmissionsDto.flux.map(f =>
      fluxDtoToFlowDeclaration(f, installationsList)
    );
    const resetMeasureDeclarations = blocEmissionsDto.pointsMesure.map(p =>
      pointMesureDtoToMeasureDeclaration(p, installationsList)
    );

    const newAlternativeEmissionsInArray = blocEmissionsDto.emissionsMethodeAlternative.map(
      emissionSimpleDtoToAlternativeEmission
    );
    const newMeasuredEmissionInArray = blocEmissionsDto.emissionsMesurees.map(
      emission =>
        emissionSimpleDtoToMeasuredEmission(emission, resetMeasureDeclarations)
    );
    const newComputedEmissionsInArray = blocEmissionsDto.emissionsCalculees.map(
      emission =>
        emissionSimpleDtoToComputedEmission(emission, resetFlowDeclarations)
    );

    setExemptedFlowsSelected(
      blocEmissionsDto.fluxSoumisADerogation.map(f => {
        return flowLabelToId(f, resetFlowDeclarations);
      })
    );

    setAlternativeEmissionsInArray(newAlternativeEmissionsInArray);

    setMeasuredEmissionInArray(newMeasuredEmissionInArray);

    setComputedEmissionsInArray(newComputedEmissionsInArray);

    if (blocEmissionsRef.current) {
      blocEmissionsRef.current.state.values.measureDeclarations = resetMeasureDeclarations;
      blocEmissionsRef.current.state.values.flowDeclarations = resetFlowDeclarations;
      blocEmissionsRef.current.state.values.CO2Transfer = !!blocEmissionsDto.transfertCO2;
      blocEmissionsRef.current.state.values.exemption = !!blocEmissionsDto.derogationFrequenceEchantillonnage;
    }
  };
};
