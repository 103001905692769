import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageRecyclageDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const convertFamilyProductionNameToReferenceItem = (
  familyName: string | null,
  referential: ReferenceFamilleUsageProductionDto
): ReferenceItemFamilleUsageProductionDto | null => {
  return (
    referential.referenceItemFamilleUsageProductionDtoList.find(
      family => family.libelle === familyName
    ) || null
  );
};

export const convertStringFamilyProductionToReferenceItem = (
  familyUuid: string | null,
  referential: ReferenceFamilleUsageProductionDto
): ReferenceItemFamilleUsageProductionDto | null => {
  return (
    referential.referenceItemFamilleUsageProductionDtoList.find(
      family => family.uuid === familyUuid
    ) || null
  );
};

export const convertStringFamilyRecyclingToReferenceItem = (
  familyUuid: string | null,
  referential: ReferenceFamilleUsageRecyclageDto
): ReferenceItemFamilleUsageRecyclageDto | null => {
  return (
    referential.referenceItemFamilleUsageRecyclageDtoList.find(
      family => family.uuid === familyUuid
    ) || null
  );
};

export const convertStringSubFamilyProductionToReferenceItem = (
  subFamilyUuid: string | null,
  family: ReferenceItemFamilleUsageProductionDto | null
): ReferenceItemFamilleUsageProductionDto | null => {
  if (!family) {
    return null;
  }
  return (
    family.referenceItemFamilleUsageDtos.find(
      subFamily => subFamily.uuid === subFamilyUuid
    ) || null
  );
};

export const convertStringSubFamilyRecyclingToReferenceItem = (
  subFamilyUuid: string | null,
  family: ReferenceItemFamilleUsageRecyclageDto | null
): ReferenceItemFamilleUsageRecyclageDto | null => {
  if (!family) {
    return null;
  }
  return (
    family.referenceItemFamilleUsageDtos.find(
      subFamily => subFamily.uuid === subFamilyUuid
    ) || null
  );
};

export const convertStringSubFamilyLvl2ProductionToReferenceItem = (
  subFamilyLvl2Uuid: string | null,
  subFamily: ReferenceItemFamilleUsageProductionDto | null
): ReferenceItemFamilleUsageProductionDto | null => {
  if (!subFamily) {
    return null;
  }
  return (
    subFamily.referenceItemFamilleUsageDtos.find(
      subFamilyLvl2 => subFamilyLvl2.uuid === subFamilyLvl2Uuid
    ) || null
  );
};

export const convertStringSubFamilyLvl2RecyclingToReferenceItem = (
  subFamilyLvl2Uuid: string | null,
  subFamily: ReferenceItemFamilleUsageRecyclageDto | null
): ReferenceItemFamilleUsageRecyclageDto | null => {
  if (!subFamily) {
    return null;
  }
  return (
    subFamily.referenceItemFamilleUsageDtos.find(
      subFamilyLvl2 => subFamilyLvl2.uuid === subFamilyLvl2Uuid
    ) || null
  );
};
