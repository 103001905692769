import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  FormikActions,
  FormikErrors,
} from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  canDisplayDescription,
  CINTSubPartActivated,
  CSubPartActivated,
  isDisplayedDescriptionMandatory,
  MINTSubPartActivated,
  MNROSubPartActivated,
  MPERSubPartActivated,
  MSubPartActivated,
  singleSyntheseOverwriteDependantFields,
  singleSyntheseValidationSchema,
} from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  arrayMethodRefC,
  arrayMethodRefM,
  arrayMethods,
  methodLabels,
  unitLabels,
} from "./utils/selectPossibleValues";
import { ReferenceItemPolluantDto, ReferenceNormeAirDto } from "api/gen";
import {
  strictlyPositiveMessage,
  syntheseTooManyAccidentalEmissionsMessage,
} from "common/declarant/formik/formikMessages";
import { ErrorLevel } from "common/utils/types";
import {
  computeTotalEmissions,
  computeTotalEmissionsUsualUnit,
  concatLibelleNorme,
} from "./utils/utils";
import { fieldMatcher, SyntheseInModale } from "./utils/types";
import { methodsMessage } from "common/infoBulle/messages";
import { computePolluantSyntheseLibelle } from "./MainSyntheseForm";
import { getValueOrZero } from "common/utils/numberUtils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface FormSyntheseProps {
  closeFunction: () => void;

  onSubmit: (
    values: SyntheseInModale,
    formikActions: FormikActions<SyntheseInModale>
  ) => void;

  initialSynthese: SyntheseInModale;
  referentialNORME: ReferenceNormeAirDto;
  referentielCO2: ReferenceItemPolluantDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormSynthese = ({
  closeFunction,
  onSubmit,
  initialSynthese,
  referentialNORME,
  referentielCO2,
}: FormSyntheseProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-combustion-synthese-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextAreaField = useTextAreaGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="VALIDER UNE ÉMISSION"
      closeFunction={closeFunction}
      isEdit={false}
      onSubmit={(values: SyntheseInModale, formikBag) => {
        values.totalEmissions = computeTotalEmissions(values);
        values.totalEmissionsUsualUnit = computeTotalEmissionsUsualUnit(values);
        singleSyntheseOverwriteDependantFields(values);
        onSubmit(values, formikBag);
      }}
      initialEntity={initialSynthese}
      validationSchema={singleSyntheseValidationSchema}
      validate={values => {
        values.totalEmissions =
          getValueOrZero(values.additionnel) +
          getValueOrZero(values.emissionsAlreadyDeclared);
        let errors: FormikErrors<SyntheseInModale> = {};
        if (getValueOrZero(values.totalEmissions) < 0) {
          errors = { totalEmissions: strictlyPositiveMessage };
        }
        if (
          getValueOrZero(values.accidentel) >
          getValueOrZero(values.emissionsAlreadyDeclared)
        ) {
          errors.accidentel = syntheseTooManyAccidentalEmissionsMessage;
        }
        return errors;
      }}
      renderField={({ values, errors }) => {
        return (
          <>
            <DummyTextField
              name={fieldMatcher.substance}
              label="Substance"
              disabled
              value={computePolluantSyntheseLibelle(
                values.substance,
                values.biomasse,
                referentielCO2
              )}
            />
            <DummyNumberField
              name={fieldMatcher.emissionsAlreadyDeclared}
              label="Total des émissions déclarées"
              unit="kg/an"
              disabled
              value={values.emissionsAlreadyDeclared}
            />
            <NumberField
              name={fieldMatcher.withAccidentalEmissions}
              label="Dont émissions accidentelles *"
              unit="kg/an"
            />
            <NumberField
              name={fieldMatcher.additionalAccidentalEmissions}
              label="Émissions accidentelles additionnelles *"
              unit="kg/an"
            />
            <TextField
              name={fieldMatcher.origin}
              label="Préciser l'origine des émissions accidentelles (le cas échéant)"
            />
            <DummyNumberField
              name={fieldMatcher.totalEmissions}
              label="Total des émissions déclarées (en kg/an)"
              unit="kg/an"
              disabled
              value={computeTotalEmissions(values)}
              error={
                errors.totalEmissions
                  ? {
                      text: errors.totalEmissions,
                      errorLevel: ErrorLevel.Blocking,
                    }
                  : undefined
              }
            />
            <DummyNumberField
              name={fieldMatcher.totalEmissionsUsualUnit}
              label="Total des émissions déclarées (en unités usuelles)"
              unit={
                values.substance
                  ? unitLabels[values.substance.uniteUsuelle]
                  : ""
              }
              disabled
              value={computeTotalEmissionsUsualUnit(values)}
            />
            <WrappedChoiceSelect
              name={fieldMatcher.method}
              label="Méthode *"
              isMulti={false}
              options={arrayMethods}
              computeLabel={method => methodLabels[method]}
              commonProps={commonProps}
            />
            {MSubPartActivated(values.methode) && (
              <>
                <WrappedChoiceSelect
                  name={fieldMatcher.methodReferenceM}
                  label="Référence de la méthode *"
                  isMulti={false}
                  options={arrayMethodRefM}
                  tooltipContent={methodsMessage}
                  computeLabel={ref => ref.toString()}
                  commonProps={commonProps}
                />
                {MINTSubPartActivated(values.referenceMesure) && (
                  <WrappedChoiceSelect
                    isMulti
                    name={fieldMatcher.normMINT}
                    label="Normes appliquées *"
                    options={referentialNORME.referenceItemNormeAirDtoList.filter(
                      ref => {
                        return (
                          ref.libelleMethode === "m" &&
                          ref.libelleReference === "int"
                        );
                      }
                    )}
                    computeLabel={concatLibelleNorme}
                    commonProps={commonProps}
                  />
                )}
                {MPERSubPartActivated(values.referenceMesure) && (
                  <WrappedChoiceSelect
                    isMulti
                    name={fieldMatcher.normMPER}
                    label="Normes appliquées *"
                    options={referentialNORME.referenceItemNormeAirDtoList.filter(
                      ref => {
                        return (
                          ref.libelleMethode === "m" &&
                          ref.libelleReference === "per"
                        );
                      }
                    )}
                    computeLabel={concatLibelleNorme}
                    commonProps={commonProps}
                  />
                )}
                {MNROSubPartActivated(values.referenceMesure) && (
                  <WrappedChoiceSelect
                    isMulti
                    name={fieldMatcher.normMNRO}
                    label="Normes appliquées *"
                    options={referentialNORME.referenceItemNormeAirDtoList.filter(
                      ref => {
                        return (
                          ref.libelleMethode === "m" &&
                          ref.libelleReference === "nro"
                        );
                      }
                    )}
                    computeLabel={concatLibelleNorme}
                    commonProps={commonProps}
                  />
                )}
              </>
            )}
            {CSubPartActivated(values.methode) && (
              <>
                <WrappedChoiceSelect
                  name={fieldMatcher.methodReferenceC}
                  label="Référence de la méthode *"
                  isMulti={false}
                  options={arrayMethodRefC}
                  tooltipContent={methodsMessage}
                  computeLabel={ref => ref.toString()}
                  commonProps={commonProps}
                />
                {CINTSubPartActivated(values.referenceCalcule) && (
                  <WrappedChoiceSelect
                    isMulti
                    name={fieldMatcher.normCINT}
                    label="Normes appliquées *"
                    options={referentialNORME.referenceItemNormeAirDtoList.filter(
                      ref => {
                        return (
                          ref.libelleMethode === "c" &&
                          ref.libelleReference === "int"
                        );
                      }
                    )}
                    computeLabel={concatLibelleNorme}
                    commonProps={commonProps}
                  />
                )}
              </>
            )}
            {canDisplayDescription(values) && (
              <TextAreaField
                name={fieldMatcher.description}
                label={`Description / désignation${
                  isDisplayedDescriptionMandatory(values) ? " *" : ""
                }`}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default FormSynthese;
