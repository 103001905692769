import { DeclarationDto2121, DeclarationSectionsDto2121 } from "api/gen";
import { useCallback } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
} from "../../../DeclarationApiContext/utils/genericHooks";
import { BasicDeclarationHandlers } from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2021;
export const MAX_YEAR = 2021;

// Define all the DTO types for this version.
export type Declaration2121 = DeclarationDto2121;

export const useDeclaration2121 = (
  shouldThrowIfWrongYear?: boolean
): Declaration2121 => {
  return useDeclaration<Declaration2121>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationHelpers2121 = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers2121 = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration2121> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration2121): DeclarationSectionsDto2121 =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration2121>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};
