import {
  procedeFieldMatcher,
  ProcedeInArray,
} from "../../ListProcede/utils/types";
import { MethodEnum } from "./selectPossibleValues";
import {
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum,
  AirFugitivesProcedesDtoUniteEnum,
} from "api/gen";
import {
  CorrelationEmissionInModale,
  EmissionsInModale,
  MesureEmissionInModale,
} from "./types";
import { computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants } from "../../../combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import { getValueOrZero } from "common/utils/numberUtils";
import { getSingleProcedeInMapById } from "../../utils/utils";

enum OperationEnum {
  "DIVIDE" = "DIVIDE",
  "MULTIPLY" = "MULTIPLY",
}

type AirFugitiveCorrespondance<ArrayElems> = {
  [KeyLevel1 in Exclude<
    AirFugitivesProcedesDtoUniteEnum,
    AirFugitivesProcedesDtoUniteEnum.AUTRE
  >]: {
    [KeyLevel2 in Exclude<
      AirFugitivesEmissionCorrelationDtoUniteEnum,
      AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
    >]: ArrayElems;
  };
};

//Correspond au tableau qui permet de trouver le facteur de conversion multiplicatif. Present dans la page Procede sur confluence.
//EX: facteurMultiplicatifProcedeAndCorrelation[procedeUnite][correlationUnite]
const facteurMultiplicatifProcedeAndCorrelation: AirFugitiveCorrespondance<number> = {
  KG: { KG: 1, T: 0.001, HL: 0.01, L: 1, KM3: 0.000001, M3: 0.001 },
  T: { KG: 1000, T: 1, HL: 10, L: 1000, KM3: 0.001, M3: 1 },
  HL: { KG: 100, T: 0.1, HL: 1, L: 100, KM3: 0.0001, M3: 0.1 },
  L: { KG: 1, T: 0.001, HL: 0.001, L: 1, KM3: 0.000001, M3: 0.001 },
  KM3: { KG: 1000000, T: 1000, HL: 10000, L: 1000000, KM3: 1, M3: 1000 },
  M3: { KG: 1000, T: 1, HL: 10, L: 1000, KM3: 0.001, M3: 1 },
};

//Correspond au tableau qui permet de trouver l'operation a faire avec la masse volumique. Present dans la page Procede sur confluence.
//EX: operationMasseVolumique[procedeUnite][correlationUnite]
const operationMasseVolumique: AirFugitiveCorrespondance<OperationEnum | null> = {
  KG: {
    KG: null,
    T: null,
    HL: OperationEnum.DIVIDE,
    L: OperationEnum.DIVIDE,
    KM3: OperationEnum.DIVIDE,
    M3: OperationEnum.DIVIDE,
  },
  T: {
    KG: null,
    T: null,
    HL: OperationEnum.DIVIDE,
    L: OperationEnum.DIVIDE,
    KM3: OperationEnum.DIVIDE,
    M3: OperationEnum.DIVIDE,
  },
  HL: {
    KG: OperationEnum.MULTIPLY,
    T: OperationEnum.MULTIPLY,
    HL: null,
    L: null,
    KM3: null,
    M3: null,
  },
  L: {
    KG: OperationEnum.MULTIPLY,
    T: OperationEnum.MULTIPLY,
    HL: null,
    L: null,
    KM3: null,
    M3: null,
  },
  KM3: {
    KG: OperationEnum.MULTIPLY,
    T: OperationEnum.MULTIPLY,
    HL: null,
    L: null,
    KM3: null,
    M3: null,
  },
  M3: {
    KG: OperationEnum.MULTIPLY,
    T: OperationEnum.MULTIPLY,
    HL: null,
    L: null,
    KM3: null,
    M3: null,
  },
};

export const computeEmissionAnnuelle = (
  values: EmissionsInModale,
  procedesInPageMap: Map<string, ProcedeInArray>,
  autreElementUuid: string
): number => {
  const methodValue = values.methods;

  if (methodValue === MethodEnum.MESURE) {
    return computeMesureEmissionsAnnuelles(values);
  }
  if (methodValue === MethodEnum.FACTEUR_CORRELATION) {
    const procede = getSingleProcedeInMapById(
      values.procedes && values.procedes[0],
      procedesInPageMap
    );
    return computeCorrelationEmissionAnnuelle(values, procede);
  }
  if (methodValue === MethodEnum.BILAN_MATIERE) {
    const part: number | null =
      values.elementProps === null ||
      values.elementProps.referenceItemElementIndexDto.uuid === autreElementUuid
        ? values.part
        : 100 * values.elementProps.partElement;

    return computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants(
      values.intrants,
      values.sortants,
      part
    );
  }

  return 0;
};

export const computeCorrelationEmissionAnnuelle = <
  T extends CorrelationEmissionInModale
>(
  emission: T,
  procede: ProcedeInArray | null
): number => {
  if (procede === null) {
    //we still don't have procédé, so we should return 0
    return 0;
  }

  const unitProcede = procede.data.unite;
  const quantityValue = procede.data.quantite;
  const unitCorrelation = emission.unite;
  const facteur = emission.facteur;
  const masseVolumique = getValueOrZero(
    procede.data[procedeFieldMatcher.density]
  );

  if (
    unitProcede &&
    quantityValue !== null &&
    unitCorrelation &&
    facteur !== null
  ) {
    if (unitProcede !== AirFugitivesProcedesDtoUniteEnum.AUTRE) {
      if (
        unitCorrelation === AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
      ) {
        //the uniteCorrelation shouldn't be autre when the unitProcede isn't Autre as well, so we return 0
        return 0;
      }

      let emissionsAnuelle =
        quantityValue *
        facteur *
        facteurMultiplicatifProcedeAndCorrelation[unitProcede][unitCorrelation];

      const operation = operationMasseVolumique[unitProcede][unitCorrelation];

      if (operation === OperationEnum.MULTIPLY && masseVolumique !== 0) {
        emissionsAnuelle *= masseVolumique;
      } else if (operation === OperationEnum.DIVIDE && masseVolumique !== 0) {
        emissionsAnuelle /= masseVolumique;
      }
      return emissionsAnuelle;
    } else {
      return quantityValue * facteur;
    }
  }
  return 0;
};

export const computeMesureEmissionsAnnuelles = <
  T extends MesureEmissionInModale
>(
  emission: T
): number => {
  if (
    emission.debitHoraire !== null &&
    emission.heures !== null &&
    emission.concentrationMoy !== null &&
    emission.concentrationMoyUnite
  ) {
    return (
      emission.debitHoraire *
      emission.heures *
      convertConcentrationToKgNM3(
        emission.concentrationMoy,
        emission.concentrationMoyUnite
      )
    );
  }
  return 0;
};

const convertConcentrationToKgNM3 = (
  concentration: number,
  concentrationUnit: AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum
): number => {
  switch (concentrationUnit) {
    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.KG_NM3:
      return concentration;

    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.G_NM3:
      return concentration * 1e-3;

    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.MG_NM3:
      return concentration * 1e-6;

    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.UG_NM3:
      return concentration * 1e-9;

    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.NG_NM3:
      return concentration * 1e-12;

    case AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.PG_NM3:
      return concentration * 1e-15;
  }
};
