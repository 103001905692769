import { BuildingNumeralValues } from "../../utils/types";
import { selectPossibleValues } from "./selectPossibleValues";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";

export const computeKeysOfUnselectedSpecies = (
  selectedSpecies: CleanOptionProps[]
): (keyof BuildingNumeralValues)[] => {
  return Object.values(selectPossibleValues.speciesMapper).filter(
    val =>
      !selectedSpecies.some(
        specie => selectPossibleValues.speciesMapper[specie.value] === val
      )
  );
};
