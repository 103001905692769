import { BlocPrelevementEauFormValues } from "./types";

export const computeTotalWaterTaking = (
  values: BlocPrelevementEauFormValues
): number => {
  return (
    (values.volumeEauSurface !== null ? values.volumeEauSurface : 0) +
    (values.volumeEauSouterraine !== null ? values.volumeEauSouterraine : 0) +
    (values.volumeEauDistribution !== null ? values.volumeEauDistribution : 0) +
    (values.volumeEauMer !== null ? values.volumeEauMer : 0)
  );
};
