import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { PINK } from "theme";
import { ClassesType } from "common/utils/types";
import classNames from "classnames";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import ArrayButtons from "./buttons/ArrayButtons";
import { computeMethodReference, computeNorms } from "./utils/utils";
import { ReferenceItemPolluantDto, ReferencePolluantDto } from "api/gen";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { SyntheseInArray } from "./utils/types";
import { computePolluantSyntheseLibelle } from "./MainSyntheseForm";
import { PATH_AIR_SYNTHESE_EMISSION } from "common/path/path18Now";
import { methodLabels, unitLabels } from "./utils/selectPossibleValues";

const useStyles = makeStyles({
  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    backgroundColor: "red",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  depassementDeSeuil: {
    fontWeight: "bold",
  },
  normal: {
    fontWeight: "normal",
  },
});

const computeWrappedCell = (
  contentCell: React.ReactNode,
  classes: ClassesType<"full" | "error">,
  errorMessage?: string
) => {
  return (
    <div
      className={classNames(classes.full, { [classes.error]: errorMessage })}
    >
      {contentCell}
    </div>
  );
};

interface SyntheseArrayProps {
  synthesesInArray: SyntheseInArray[];
  setSyntheseInModale: Dispatch<SetStateAction<SyntheseInArray | null>>;
  isValidated: boolean;
  validationPath: string;
  referentialAIR: ReferencePolluantDto;
  referentialCO2: ReferenceItemPolluantDto;
}

const SyntheseArray = ({
  synthesesInArray,
  setSyntheseInModale,
  isValidated,
  validationPath,
  referentialAIR,
  referentialCO2,
}: SyntheseArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleSyntheseLine = (
    synthese: SyntheseInArray,
    classes: ClassesType<
      "link" | "full" | "error" | "depassementDeSeuil" | "normal"
    >
  ): React.ReactElement[] => {
    const editAction = () => {
      setSyntheseInModale(synthese);
    };
    const polluantSyntheseLibelle = computePolluantSyntheseLibelle(
      synthese.data.substance,
      synthese.data.biomasse,
      referentialCO2
    );

    return [
      computeWrappedCell(
        <p
          className={isValidated ? "" : classes.link}
          onClick={
            isValidated
              ? undefined
              : () => {
                  editAction();
                }
          }
          title={polluantSyntheseLibelle || undefined}
        >
          {polluantSyntheseLibelle}
        </p>,
        classes
      ),
      computeWrappedCell(
        <p
          className={
            synthese.data.depassementDeSeuilDeclare
              ? classes.depassementDeSeuil
              : classes.normal
          }
        >
          {synthese.data.emissionsAlreadyDeclared !== null
            ? computeNumberWithSeparator(synthese.data.emissionsAlreadyDeclared)
            : ""}
        </p>,
        classes
      ),
      computeWrappedCell(
        synthese.data.accidentel !== null
          ? computeNumberWithSeparator(synthese.data.accidentel)
          : "",
        classes
      ),
      computeWrappedCell(
        synthese.data.additionnel !== null
          ? computeNumberWithSeparator(synthese.data.additionnel)
          : "",
        classes
      ),
      computeWrappedCell(
        <p title={synthese.data.origine || undefined}>
          {synthese.data.origine}
        </p>,
        classes
      ),
      computeWrappedCell(
        <p
          className={
            synthese.data.depassementDeSeuilTotal
              ? classes.depassementDeSeuil
              : classes.normal
          }
        >
          {synthese.data.totalEmissions !== null
            ? computeNumberWithSeparator(synthese.data.totalEmissions)
            : ""}
        </p>,
        classes
      ),
      computeWrappedCell(
        <p
          className={
            synthese.data.depassementDeSeuilTotal
              ? classes.depassementDeSeuil
              : classes.normal
          }
        >
          {synthese.data.totalEmissionsUsualUnit !== null
            ? `${computeNumberWithSeparator(
                Number(synthese.data.totalEmissionsUsualUnit)
              )} ${synthese.data.substance &&
                unitLabels[synthese.data.substance.uniteUsuelle]}`
            : ""}
        </p>,
        classes
      ),
      computeWrappedCell(
        synthese.data.methode !== null
          ? methodLabels[synthese.data.methode]
          : "",
        classes
      ),
      computeWrappedCell(computeMethodReference(synthese), classes),
      computeWrappedCell(computeNorms(synthese), classes),
      computeWrappedCell(
        <p title={synthese.data.description || undefined}>
          {synthese.data.description}
        </p>,
        classes
      ),
      <ArrayButtons
        isSingleValidated={
          synthese.data.methode !== null &&
          synthese.data.accidentel !== null &&
          synthese.data.additionnel !== null
        }
        handlerEdit={editAction}
        commentPath={`${PATH_AIR_SYNTHESE_EMISSION}/${synthese.data.id}`}
        isValidated={isValidated}
      />,
    ];
  };
  const arrayPolluantsLines = synthesesInArray.map(singleSyntheseInArray => {
    return computeSingleSyntheseLine(singleSyntheseInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Émissions déclarées dans les blocs (kg/an)</p>,
        <p>Dont émissions accidentelles (kg/an)</p>,
        <p>Émissions accidentelles additionnelles (kg/an)</p>,
        <p>Préciser l'origine des émissions accidentelles (le cas échéant)</p>,
        <p>Total des émissions déclarées (kg/an)</p>,
        <p>Total des émissions déclarées (en unités usuelles)</p>,
        <p>Méthode</p>,
        <p>Référence de la méthode</p>,
        <p>Normes appliquées</p>,
        <p>Description / désignation</p>,
        <p>Actions</p>,
      ]}
      lines={arrayPolluantsLines}
      preferredColWidths={[
        200,
        200,
        200,
        200,
        200,
        200,
        200,
        200,
        200,
        200,
        200,
        220,
      ]}
    />
  );
};

export default SyntheseArray;
