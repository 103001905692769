import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { SousInstallationRefAlternatifDto24Now } from "api/gen";
import { computeTableWidth } from "theme";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { EMPHASIZED_LAST_HEADER_CELL } from "common/presentational/CommonEntityTable/cssRules";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import {
  computeAnneeWithDonneesHistoriquesLine,
  computeSousInstallationReferentielAlternatifLines,
} from "../tableLineComputer";
import { computePreferredColWidths } from "../../InfosGenerales/tableLineComputer";
import { sortObjectKeysAndValues } from "common/utils/methods";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  table: (object: Record<string, unknown>) => ({
    ...EMPHASIZED_LAST_HEADER_CELL,
    width: computeTableWidth(object),
  }),
  full: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    marginLeft: "10px",
  },
});

interface SousInstallationReferentielAlternatifTableProps {
  sousInstallationRefAlternatif: SousInstallationRefAlternatifDto24Now;
}

const SousInstallationReferentielAlternatifTable = ({
  sousInstallationRefAlternatif,
}: SousInstallationReferentielAlternatifTableProps): ReactElement => {
  const {
    title,
    uniteRefAlternatifDto,
    yearToSousInstallationDataMap,
    refChauffageUrbainOrProcede: isPartialTable,
  } = sousInstallationRefAlternatif;
  const classes = useStyles(yearToSousInstallationDataMap);

  const {
    sortedKeys,
    sortedValues: sortedSousInstallationDataList,
  } = sortObjectKeysAndValues(yearToSousInstallationDataMap);

  return (
    <div className={classes.container}>
      <ArrayTitle>{title}</ArrayTitle>

      <CommonEntityTable
        header={computeAnneeWithDonneesHistoriquesLine(sortedKeys, classes)}
        lines={computeSousInstallationReferentielAlternatifLines(
          uniteRefAlternatifDto,
          sortedSousInstallationDataList,
          classes,
          isPartialTable
        )}
        isFirstColSticky={false}
        isLastColSticky={false}
        additionalClassName={classes.table}
        preferredColWidths={computePreferredColWidths(
          sortedSousInstallationDataList
        )}
        preferredContentHeight={isPartialTable ? 405 : 775}
        isFixedHeight={true}
      />
    </div>
  );
};

export default SousInstallationReferentielAlternatifTable;
