import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";
import { Declaration22Now, MAX_YEAR, MIN_YEAR } from "../declarationHooks22Now";
import { useCallback, useMemo } from "react";
import { FileHandlers } from "common/FilesLoader/types";

export const usePdsFileHandler22Now = (nimDynId: string): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPds22NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePds22NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration22Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration22Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useRapportEmissionsFileHandler22Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadRapportEmissions22NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteRapportEmissions22NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration22Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration22Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
