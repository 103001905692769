export const PINK = "#E02A66";
export const PINK_HOVER = "#bd2255";
export const TITLE_COLOR = "#E02A66";
export const BACKGROUND_PINK = "#ffd4e2";
export const GREY = "#DEDEDE";
export const ICON_GREY = "#4D4D4D";
export const ORANGE_BANDEAU = "#de9729";
export const WARNING = "orange";
export const ERROR = "red";

export const BACKGROUND_LIGHT_GREY = "#D8D8D8";
export const BACKGROUND_GREY = "#D8D8D8";
export const BUTTON_DARK_GREY = "#A2A2A2";
export const SECTION_TITLE_GREY = "#4d4d4d";
export const DISABLED_NUMBER_INPUT_BACKGROUND = "#FFFFFF";
export const DISABLED_NUMBER_INPUT_TEXT = "#2c2c2c";
export const DISABLED_BUTTON_HOVER_BACKGROUND = "#cdcdcd";
export const DISABLED_INPUT_TEXT = "#707070";

export const TEXT_LIGHT_GREY = "#999999";
export const ARRAY_GREY = "#f2f2f2";
export const GREY_OVERLAY = "rgba(99, 99, 99, 0.75)";

export const LIGHT_PINK = "#E08FA5";
export const GREEN = "#6AC259";
export const LIGHT_GREEN = "#7CE368";

export const DASHBOARD_BACKGROUND_GREY = "#f6f6f6";
export const DASHBOARD_BORDER_GREY = "#e6e6e6";
export const DASHBOARD_DISABLED_BACKGROUND_GREY = "#d8d8d8";
export const DASHBOARD_DISABLED_FONT_GREY = "#a2a2a2";
export const DASHBOARD_DISABLED_HOVER_GREY = "#6d6d6d";

export const DASHBOARD_DECLARATION_RED = "#CC0000";
export const DASHBOARD_DECLARATION_YELLOW = "#E6B800";
export const DASHBOARD_DECLARATION_GREEN = "#86B300";
export const DASHBOARD_DECLARATION_BLUE = "#0099FF";
export const DASHBOARD_DECLARATION_PURPLE = "#B366FF";
export const DASHBOARD_DECLARATION_GREY = "#CCCCCC";
export const DASHBOARD_DECLARATION_BROWN = "#91670d";

export const LIGHT_BEIGE_BACKGROUND = "rgba(225, 198, 153, 0.1)";
export const DISABLED_HIDE_GREY = "rgba(0, 0, 0, 0.75)";

export const DISABLED_BLOC_FILTER = "opacity(0.3)";

//  back button
export const PREVIOUS_PAGE_CIRCLE_RADIUS = 50;

export const SHADOW = "0px 2px 4px rgba(0, 0, 0, 0.25)";
// for checker:
export const CHECKER_HEIGHT = 10;
export const CHECKER_WIDTH = 40;
export const CHECKER_HANDLE_HEIGHT = 16;
export const CHECKER_DEFAULT_COLOR = "#888";

export const INPUT_BORDER_WIDTH = "1px";
export const SECTION_FONT_SIZE = "1em";
export const PAGE_TITLE_FONT_SIZE = "1.5em";
export const SUB_SECTION_FONT_SIZE = "1.125em";
export const HELP_TEXT_FONT_SIZE = "0.875em";
export const FIELD_FONT_SIZE = "1em";
export const FIELD_FONT_WEIGHT = "bold";
export const DEFAULT_LINE_HEIGHT = "30px";

export const LONG_TEXT_INPUT_WIDTH = "100%";
export const SHORT_TEXT_INPUT_WIDTH = "35%";

export const LONG_BUTTON_WIDTH = "35%";
export const DEFAULT_BUTTON_WIDTH = "230px";
export const DEFAULT_BORDER_RADIUS = "5px";

export const LARGE_BUTTON_WIDTH = "280px";

export const DEFAULT_BUTTON_FONT_SIZE = "1em";
export const DEFAULT_BUTTON_PADDING = "0.5em";
export const DEFAULT_BUTTON_SHADOW = SHADOW;
export const DEFAULT_MODAL_SHADOW = "2px 2px 5px #2c2c2C";

export const DEFAULT_INPUT_HEIGHT = "36px";
export const LEFT_FIELD_LABEL_WIDTH = "20em";
export const LABEL_RIGHT_MARGIN = "1em";
export const CHOICE_INPUT_MARGIN = "20px";
export const CHOICE_INPUT_WIDTH = "75px";

export const WHOLE_LINE_LABEL_WIDTH = "110%";
export const WHOLE_LINE_LABEL_UL_LEFT_MARGIN = "8%";
export const WHOLE_LINE_LABEL_LEFT_MARGIN = "5%";

export const SELECT_DEFAULT_WIDTH = "100%";

export const SEPARATOR_WIDTH = "1px";

export const ARRAY_DEFAULT_HEADER_HEIGHT = "50px";
export const ARRAY_DEFAULT_LINE_HEIGHT = "50px";
export const ARRAY_DEFAULT_HEIGHT = "400px";
export const ARRAY_NB_LINES_SCROLL = "10";
export const ARRAY_SHADOW = "0px 4px 2px rgba(0, 0, 0, 0.15)";

export const BLOC_MIN_WIDTH = "640px";

export const TAB_UNDERLINE_SIZE = "3px";

//because it's more practical to have the values without px
export const ARRAY_DEFAULT_BUTTON_WIDTH_AS_NUMBER = 80;
export const ARRAY_DEFAULT_BUTTON_WIDTH = `${ARRAY_DEFAULT_BUTTON_WIDTH_AS_NUMBER}px`;
export const ARRAY_LARGE_BUTTON_WIDTH = "100px";

const UPPERCASE = "uppercase" as const;

export const HELP_TEXT_STYLE = {
  fontSize: HELP_TEXT_FONT_SIZE,
  color: SECTION_TITLE_GREY,
  marginBottom: "1em",
};

export const SUBTITLE_STYLE = {
  color: SECTION_TITLE_GREY,
  fontSize: SUB_SECTION_FONT_SIZE,
  textTransform: UPPERCASE,
  marginBottom: "20px",
};

export const BIG_CARD_NOTE = {
  padding: "4px",
  color: "white",
  borderRadius: "5px",
  marginTop: "6px",
  marginLeft: "auto",
  backgroundColor: PINK,
  width: "60%",
  textAlign: "center" as const,
  fontSize: "12px",
};

export const SIMPLE_LINK_STYLE = {
  "&:hover": {
    cursor: "pointer",
  },
};
export const LINK_STYLE = {
  ...SIMPLE_LINK_STYLE,
  color: PINK,
  textDecoration: "underline",
};

export const LINK_ERROR_STYLE = {
  color: "white",
  background: ERROR,
  width: "100%",
  minWidth: "4em",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const TEXT_INPUT_STYLE = {
  container: {
    display: "flex",
    border: `${INPUT_BORDER_WIDTH} solid ${PINK}`,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: "0px 10px",
    height: DEFAULT_INPUT_HEIGHT,
    flexDirection: "row" as const,
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box" as const,
    // for some reason, the disabled rule is always overridden by the container rule without regard to the rule definition order
    // so we need to write a more specific selector here.
    // syntax is explained here : https://cssinjs.org/jss-plugin-nested/?v=v10.0.0#pseudo-and-nested-selectors
    "&$disabled": {
      borderColor: DISABLED_NUMBER_INPUT_TEXT,
    },
  },

  warning: {},
  active: {
    border: `${INPUT_BORDER_WIDTH} solid ${PINK}`,
  },
  disabled: {
    backgroundColor: DISABLED_NUMBER_INPUT_BACKGROUND,
  },
  error: {
    borderColor: ERROR,
  },
};

export const SIMPLE_TEXT_INPUT_STYLE = {
  container: {
    borderRadius: DEFAULT_BORDER_RADIUS,
    margin: 0,
    padding: "0 5px",
    display: "flex",
    alignItems: "center",
    border: `${INPUT_BORDER_WIDTH} solid ${GREY}`,
    backgroundColor: "white",
  },
  content: {
    height: "100%",
    width: "100%",
    background: "transparent",
    border: "none",
  },
};

export const LEFT_WITHDRAW_STYLE = {
  withdrawLeft: {
    marginRight: "10%",
  },
};

export const SCROLL_BAR_STYLE = {
  "&::-webkit-scrollbar": {
    width: "8px",
    height: "8px",
    backgroundColor: "#F5F5F5",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "#B7B7B7",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "10px",
    backgroundColor: "#F5F5F5",
  },
};
export const HRULE_DEFAULT_PADDING_BEFORE = "30px";
export const HRULE_DEFAULT_PADDING_AFTER = "30px";

export const MAIN_CONTAINER_STYLE = {
  maxWidth: 1024,
  margin: "15px auto",
};

export const WASTE_BROWSE_MESSAGE_WIDTH = "420px";

// for elements that can be added or suppressed as user wishes
export const DYNAMIC_ELEMENTS_STYLE = {
  itemWrapper: {
    position: "relative" as const,
  },
  iconButton: {
    borderRadius: "50%",
    border: "none",
    color: "white",
    outline: "none",
    padding: "0px",
    marginLeft: "20px",
    backgroundColor: PINK,
    width: "40px",
    fontSize: "35px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: PINK_HOVER,
    },
  },
  removeButton: {
    padding: "3px 4px",
  },
  removeButtonRow: {
    width: "50%",
  },
  removeButtonWrapper: {
    width: SELECT_DEFAULT_WIDTH,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    height: "100%",
  },
};

export const DISABLED_SECTION_STYLE = {
  disabledSection: {
    filter: DISABLED_BLOC_FILTER,
    pointerEvents: "none" as const,
  },
  disabledMessage: {
    textAlign: "center" as const,
    color: "black" as const,
    fontSize: "18px" as const,
    width: "100%" as const,
  },
};

export const RAPPEL_INFOS_GENERALES_STYLE = {
  filter: "opacity(0.5)",
};

export const BLACK_BUTTON_STYLE = {
  blackButton: {
    "&>a>button": {
      color: SECTION_TITLE_GREY,
      "&:hover": {
        backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
      },
    },
  },
};

export const FILE_BUTTONS_STYLE = {
  fileButtons: {
    marginBottom: "20px",
    display: "flex" as const,
    flexDirection: "row" as const,
  },
};

export const SECTION_TITLE_STYLE = {
  sectionTitle: {
    fontWeight: "bold" as const,
    fontSize: "22px",
    marginBottom: "12px",
  },
};

export const CARD_PADDING_DEFAULT_PX_VALUE = 15;

export const THREE_COLUMNS_CARD_GRID = {
  "@media(min-width: 450px)": {
    gridTemplateColumns: "repeat(2,calc(50% - 20px))",
  },
  "@media(min-width: 650px)": {
    gridTemplateColumns: "repeat(3,calc(33.33% - 20px))",
  },
};

export const FOUR_COLUMNS_CARD_GRID = {
  ...THREE_COLUMNS_CARD_GRID,
  "@media(min-width: 850px)": {
    gridTemplateColumns: "repeat(4,calc(25% - 20px))",
  },
};

export const computeTableWidth = (object: Record<string, unknown>): string =>
  Math.min(100, 35 + 10 * Object.keys(object).length) + "%";
export const ICON_STYLE = {
  height: "1.5rem",
  width: "1.5rem",
  verticalAlign: "bottom",
};
