import {
  FileDto,
  QuotasBlocEmissionsDto2020,
  QuotasEmissionFluxDto2020,
  QuotasEmissionPointMesureDto2020,
  QuotasEmissionSimpleCalculeDto2020,
  QuotasEmissionSimpleCalculeDto2020GazEnum,
  QuotasEmissionSimpleCalculeDto2020MethodeEnum,
  QuotasEmissionSimpleMesureDto2020,
  QuotasEmissionSimpleMesureDto2020GazEnum,
  QuotasEmissionSimpleMesureDto2020MethodeEnum,
  QuotasEmissionSimpleMethodeAlternativeDto2020,
  QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum,
  QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum,
  QuotasInstallationDto20Now,
} from "api/gen";
import {
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasEnum,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
  MethodEnum,
} from "./types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { findElementByIdOrNull } from "common/utils/methods";
import { getFlowNameById } from "./utils/utils";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

export const getInstallationOptions = (
  installations: QuotasInstallationDto20Now[]
): QuotasInstallationDto20Now[] => {
  return installations.filter(installation => installation.nim !== null);
};

export const fluxDtoToFlowDeclaration = (
  flux: QuotasEmissionFluxDto2020,
  installations: QuotasInstallationDto20Now[]
): FlowDeclarationProps => {
  return {
    id: flux.id,
    nimInstallation: findElementByIdOrNull(flux.nimDynId, installations),
    name: flux.nom,
  };
};

export const pointMesureDtoToMeasureDeclaration = (
  point: QuotasEmissionPointMesureDto2020,
  installations: QuotasInstallationDto20Now[]
): MeasureDeclarationProps => {
  return {
    id: point.id,
    nimInstallation: findElementByIdOrNull(point.nimDynId, installations),
    name: point.intitule,
  };
};

export const flowDeclarationToFluxDto = (
  flowDeclaration: FlowDeclarationProps
): QuotasEmissionFluxDto2020 => {
  return {
    id: flowDeclaration.id,
    nimDynId:
      flowDeclaration.nimInstallation && flowDeclaration.nimInstallation.id,
    nom: flowDeclaration.name,
  };
};

export const measureDeclarationToPointMesureDto = (
  mesureDeclaration: MeasureDeclarationProps
): QuotasEmissionPointMesureDto2020 => {
  return {
    id: mesureDeclaration.id,
    nimDynId:
      mesureDeclaration.nimInstallation && mesureDeclaration.nimInstallation.id,
    intitule: mesureDeclaration.name,
  };
};

const apiGasEnumToGenericGasEnum = (
  gasEnum:
    | QuotasEmissionSimpleCalculeDto2020GazEnum
    | QuotasEmissionSimpleMesureDto2020GazEnum
    | QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum
    | null
): GasEnum | null => {
  if (!gasEnum) {
    return null;
  }
  switch (gasEnum) {
    case QuotasEmissionSimpleCalculeDto2020GazEnum.CO2:
    case QuotasEmissionSimpleMesureDto2020GazEnum.CO2:
    case QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.CO2:
      return GasEnum.CO2;

    case QuotasEmissionSimpleCalculeDto2020GazEnum.PFC:
    case QuotasEmissionSimpleMesureDto2020GazEnum.PFC:
    case QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.PFC:
      return GasEnum.PFC;

    case QuotasEmissionSimpleCalculeDto2020GazEnum.N2O:
    case QuotasEmissionSimpleMesureDto2020GazEnum.N2O:
    case QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.N2O:
      return GasEnum.N2O;

    default:
      throw new ShouldNotHappen(gasEnum);
  }
};

const genericGasEnumToApiGasEnum = (
  genericGasEnum: GasEnum
): {
  calcule: QuotasEmissionSimpleCalculeDto2020GazEnum;
  mesure: QuotasEmissionSimpleMesureDto2020GazEnum;
  alternative: QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum;
} => {
  switch (genericGasEnum) {
    case GasEnum.CO2:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020GazEnum.CO2,
        mesure: QuotasEmissionSimpleMesureDto2020GazEnum.CO2,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.CO2,
      };

    case GasEnum.PFC:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020GazEnum.PFC,
        mesure: QuotasEmissionSimpleMesureDto2020GazEnum.PFC,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.PFC,
      };

    case GasEnum.N2O:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020GazEnum.N2O,
        mesure: QuotasEmissionSimpleMesureDto2020GazEnum.N2O,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto2020GazEnum.N2O,
      };

    default:
      throw Error(`Error converting ${genericGasEnum} to Enum`);
  }
};

const apiMethodEnumToGenericMethodEnum = (
  methodEnum:
    | QuotasEmissionSimpleCalculeDto2020MethodeEnum
    | QuotasEmissionSimpleMesureDto2020MethodeEnum
    | QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum
    | null
): MethodEnum | null => {
  if (!methodEnum) {
    return null;
  }
  switch (methodEnum) {
    case QuotasEmissionSimpleCalculeDto2020MethodeEnum.ALTERNATIVE:
    case QuotasEmissionSimpleMesureDto2020MethodeEnum.ALTERNATIVE:
    case QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.ALTERNATIVE:
      return MethodEnum.ALTERNATIVE;

    case QuotasEmissionSimpleCalculeDto2020MethodeEnum.MESURE:
    case QuotasEmissionSimpleMesureDto2020MethodeEnum.MESURE:
    case QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.MESURE:
      return MethodEnum.MESURE;

    case QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_OXYDATION:
    case QuotasEmissionSimpleMesureDto2020MethodeEnum.FACTEUR_OXYDATION:
    case QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.FACTEUR_OXYDATION:
      return MethodEnum.FACTEUR_OXYDATION;

    case QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_CONVERSION:
    case QuotasEmissionSimpleMesureDto2020MethodeEnum.FACTEUR_CONVERSION:
    case QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.FACTEUR_CONVERSION:
      return MethodEnum.FACTEUR_CONVERSION;

    case QuotasEmissionSimpleCalculeDto2020MethodeEnum.BILAN_MASSIQUE:
    case QuotasEmissionSimpleMesureDto2020MethodeEnum.BILAN_MASSIQUE:
    case QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.BILAN_MASSIQUE:
      return MethodEnum.BILAN_MASSIQUE;

    default:
      throw new ShouldNotHappen(methodEnum);
  }
};

const genericMethodEnumToApiMethodEnum = (
  genericMethodEnum: MethodEnum
): {
  calcule: QuotasEmissionSimpleCalculeDto2020MethodeEnum;
  mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum;
  alternative: QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum;
} => {
  switch (genericMethodEnum) {
    case MethodEnum.ALTERNATIVE:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020MethodeEnum.ALTERNATIVE,
        mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum.ALTERNATIVE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.ALTERNATIVE,
      };

    case MethodEnum.MESURE:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020MethodeEnum.MESURE,
        mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum.MESURE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.MESURE,
      };

    case MethodEnum.FACTEUR_OXYDATION:
      return {
        calcule:
          QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_OXYDATION,
        mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum.FACTEUR_OXYDATION,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.FACTEUR_OXYDATION,
      };

    case MethodEnum.FACTEUR_CONVERSION:
      return {
        calcule:
          QuotasEmissionSimpleCalculeDto2020MethodeEnum.FACTEUR_CONVERSION,
        mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum.FACTEUR_CONVERSION,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.FACTEUR_CONVERSION,
      };

    case MethodEnum.BILAN_MASSIQUE:
      return {
        calcule: QuotasEmissionSimpleCalculeDto2020MethodeEnum.BILAN_MASSIQUE,
        mesure: QuotasEmissionSimpleMesureDto2020MethodeEnum.BILAN_MASSIQUE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto2020MethodeEnum.BILAN_MASSIQUE,
      };

    default:
      throw Error(`Error converting ${genericMethodEnum} to Enum`);
  }
};

/**
 * @deprecated To be ditched when the link will be with uuid instead of name
 */
const flowOrMeasureNameToFlowOrMeasureDeclarationProps = <
  T extends { name: string | null }
>(
  label: string | null,
  values: T[]
): T | null => {
  return values.find(obj => obj.name === label) || null;
};

export const dtoExemptedFlowsToExemptedFlowDeclarations = (
  flowNames: string[],
  flowInPage: FlowDeclarationProps[]
): string[] => {
  return flowNames
    .map(name => {
      const correspondingFlow = flowOrMeasureNameToFlowOrMeasureDeclarationProps(
        name,
        flowInPage
      );
      return correspondingFlow ? correspondingFlow.id : null;
    })
    .filter(flow => flow !== null) as string[];
};

/**
 * @deprecated To be ditched when the link will be with uuid instead of name
 */
const exemptedFlowdeclarationToDtoExemptedFlows = (
  exemptedFlow: string[],
  flowsInPage: FlowDeclarationProps[]
): string[] => {
  return exemptedFlow
    .map(flowId => getFlowNameById(flowId, flowsInPage))
    .filter(name => name !== null) as string[];
};

export const emissionSimpleDtoToAlternativeEmission = (
  emissionDto: QuotasEmissionSimpleMethodeAlternativeDto2020,
  installations: QuotasInstallationDto20Now[]
): AlternativeEmissionInArray => {
  const installation: QuotasInstallationDto20Now | null =
    installations.find(
      installation => installation.id === emissionDto.nimDynId
    ) || null;

  return {
    //TODO : calculate the errors
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      nimInstallation: installation,
      gas: apiGasEnumToGenericGasEnum(emissionDto.gaz),
      method: apiMethodEnumToGenericMethodEnum(emissionDto.methode),
      methodDescription: emissionDto.descriptionMethode,
      otherEmission: emissionDto.emission,
    },
    errors: {},
  };
};

export const alternativeEmissionToEmissionSimpleDto = (
  alternativeEmission: AlternativeEmissionInArray
): QuotasEmissionSimpleMethodeAlternativeDto2020 => {
  if (
    alternativeEmission.data.gas &&
    (alternativeEmission.data.gas === GasEnum.N2O ||
      alternativeEmission.data.gas === GasEnum.PFC)
  ) {
    alternativeEmission.data.biomassEmission = null;
    alternativeEmission.data.fossilEmission = null;
  } else {
    alternativeEmission.data.otherEmission = null;
  }
  return {
    id: alternativeEmission.data.id,
    emissionOrigineBiomasse: alternativeEmission.data.biomassEmission,
    emissionOrigineFossile: alternativeEmission.data.fossilEmission,
    nimDynId:
      alternativeEmission.data.nimInstallation &&
      alternativeEmission.data.nimInstallation.id,
    gaz:
      alternativeEmission.data.gas &&
      genericGasEnumToApiGasEnum(alternativeEmission.data.gas).alternative,
    methode:
      alternativeEmission.data.method &&
      genericMethodEnumToApiMethodEnum(alternativeEmission.data.method)
        .alternative,
    descriptionMethode: alternativeEmission.data.methodDescription,
    pointMesure: null,
    flux: null,
    emission: alternativeEmission.data.otherEmission,
  };
};

export const emissionSimpleDtoToMeasuredEmission = (
  emissionDto: QuotasEmissionSimpleMesureDto2020,
  measureDeclarations: MeasureDeclarationProps[]
): MeasureEmissionInArray => {
  return {
    //TODO : compute errors
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      measure: flowOrMeasureNameToFlowOrMeasureDeclarationProps(
        emissionDto.pointMesure,
        measureDeclarations
      ),
      gas: apiGasEnumToGenericGasEnum(emissionDto.gaz),
      method: apiMethodEnumToGenericMethodEnum(emissionDto.methode),
      otherEmission: emissionDto.emission,
    },
    errors: {},
  };
};

export const measuredEmissionToEmissionSimpleDto = (
  measuredEmission: MeasureEmissionInArray,
  measureDeclarations: MeasureDeclarationProps[]
): QuotasEmissionSimpleMesureDto2020 => {
  const measurePoint = findElementByIdOrNull(
    measuredEmission.data.measure && measuredEmission.data.measure.id,
    measureDeclarations
  );
  if (
    measuredEmission.data.gas &&
    (measuredEmission.data.gas === GasEnum.N2O ||
      measuredEmission.data.gas === GasEnum.PFC)
  ) {
    measuredEmission.data.biomassEmission = null;
    measuredEmission.data.fossilEmission = null;
  } else {
    measuredEmission.data.otherEmission = null;
  }
  return {
    id: measuredEmission.data.id,
    emissionOrigineBiomasse: measuredEmission.data.biomassEmission,
    emissionOrigineFossile: measuredEmission.data.fossilEmission,
    nimDynId:
      measurePoint &&
      measurePoint.nimInstallation &&
      measurePoint.nimInstallation.id,
    pointMesure: measurePoint && measurePoint.name,
    gaz:
      measuredEmission.data.gas &&
      genericGasEnumToApiGasEnum(measuredEmission.data.gas).mesure,
    methode:
      measuredEmission.data.method &&
      genericMethodEnumToApiMethodEnum(measuredEmission.data.method).mesure,
    descriptionMethode: null,
    flux: null,
    emission: measuredEmission.data.otherEmission,
  };
};

export const emissionSimpleDtoToComputedEmission = (
  emissionDto: QuotasEmissionSimpleCalculeDto2020,
  flowDeclarations: FlowDeclarationProps[]
): ComputedEmissionInArray => {
  return {
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      otherEmission: emissionDto.emission,
      flow: flowOrMeasureNameToFlowOrMeasureDeclarationProps(
        emissionDto.flux,
        flowDeclarations
      ),
      gas: apiGasEnumToGenericGasEnum(emissionDto.gaz),
      method: apiMethodEnumToGenericMethodEnum(emissionDto.methode),
    },
    errors: {},
  };
};

export const computedEmissionToEmissionSimpleDto = (
  computedEmission: ComputedEmissionInArray,
  flowDeclarations: FlowDeclarationProps[]
): QuotasEmissionSimpleCalculeDto2020 => {
  const flow = findElementByIdOrNull(
    computedEmission.data.flow && computedEmission.data.flow.id,
    flowDeclarations
  );
  return {
    id: computedEmission.data.id,
    emissionOrigineBiomasse: computedEmission.data.biomassEmission,
    emissionOrigineFossile: computedEmission.data.fossilEmission,
    nimDynId: flow && flow.nimInstallation && flow.nimInstallation.id,
    flux: flow && flow.name,
    gaz:
      computedEmission.data.gas &&
      genericGasEnumToApiGasEnum(computedEmission.data.gas).calcule,
    methode:
      computedEmission.data.method &&
      genericMethodEnumToApiMethodEnum(computedEmission.data.method).calcule,
    descriptionMethode: null,
    pointMesure: null,
    emission: null,
  };
};

export const dtoToBlocValues = (
  dto: QuotasBlocEmissionsDto2020,
  installations: QuotasInstallationDto20Now[]
): BlocEmissionsFormValues => {
  const flowDeclarations = dto.flux.map(flux =>
    fluxDtoToFlowDeclaration(flux, installations)
  );
  return {
    flowDeclarations: flowDeclarations,
    measureDeclarations: dto.pointsMesure.map(point =>
      pointMesureDtoToMeasureDeclaration(point, installations)
    ),
    exemption: !!dto.derogationFrequenceEchantillonnage,
    CO2Transfer: !!dto.transfertCO2,
    exemptedFlows: dtoExemptedFlowsToExemptedFlowDeclarations(
      dto.fluxSoumisADerogation,
      flowDeclarations
    ),
  };
};

const blocValuesToDto = (
  values: BlocEmissionsFormValues | null,
  files: FileDto[],
  computedEmissionsInArray: ComputedEmissionInArray[],
  measuredEmissionsInArray: MeasureEmissionInArray[],
  alternativeEmissionsInArray: AlternativeEmissionInArray[],
  flowDeclarations: FlowDeclarationProps[],
  measureDeclarations: MeasureDeclarationProps[],
  exemptedFlow: string[]
): QuotasBlocEmissionsDto2020 => {
  return {
    fichiers: files,
    flux: values ? values.flowDeclarations.map(flowDeclarationToFluxDto) : [],
    pointsMesure: values
      ? values.measureDeclarations.map(measureDeclarationToPointMesureDto)
      : [],
    emissionsCalculees: computedEmissionsInArray.map(e =>
      computedEmissionToEmissionSimpleDto(e, flowDeclarations)
    ),
    emissionsMesurees: measuredEmissionsInArray.map(e =>
      measuredEmissionToEmissionSimpleDto(e, measureDeclarations)
    ),
    emissionsMethodeAlternative: alternativeEmissionsInArray.map(
      alternativeEmissionToEmissionSimpleDto
    ),
    derogationFrequenceEchantillonnage: values ? values.exemption : false,
    fluxSoumisADerogation: exemptedFlowdeclarationToDtoExemptedFlows(
      exemptedFlow,
      values ? values.flowDeclarations : []
    ),
    transfertCO2: values ? values.CO2Transfer : false,
  };
};

export const updateHandler = (
  declaration: Declaration2020,
  values: BlocEmissionsFormValues | null,
  computedEmissionsInArray: ComputedEmissionInArray[],
  measuredEmissionsInArray: MeasureEmissionInArray[],
  alternativeEmissionsInArray: AlternativeEmissionInArray[],
  flowDeclarations: FlowDeclarationProps[],
  measureDeclarations: MeasureDeclarationProps[],
  exemptedFlows: string[]
): Declaration2020 => {
  declaration.body.sections.quotas.emissions.blocEmissions = blocValuesToDto(
    values,
    declaration.body.sections.quotas.emissions.blocEmissions.fichiers,
    computedEmissionsInArray,
    measuredEmissionsInArray,
    alternativeEmissionsInArray,
    flowDeclarations,
    measureDeclarations,
    exemptedFlows
  );
  return declaration;
};
