import { SimpleDeclarationListDto, SimpleInstallationListDto } from "api/gen";
import { backMessageRetrieveDeclarationList } from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { computeInitialFilterValues } from "pages/InspectorSpace/DeclarationsDashboard/converter";
import { DeclarationFilters } from "pages/InspectorSpace/DeclarationsDashboard/types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useActiveOrLastCampaign } from "../CampaignContext";
import {
  useFetchDeclarationInspecteur,
  useFetchDeclarationVerificateur,
  useFetchDeclarationYears,
} from "./hooks";
import { useUserData } from "../../../Authenticator/UserData";

interface DeclarationsDashboardContextProps {
  children: React.ReactNode;
}

interface UpdateOptions {
  filters?: Partial<DeclarationFilters>;
  page?: number;
  linesPerPage?: number;
}

interface DeclarationsDashboardContextState {
  declarationsInspecteur: SimpleDeclarationListDto | null;
  declarationsVerificateur: SimpleInstallationListDto | null;
  filters: DeclarationFilters;
  setFilters: React.Dispatch<React.SetStateAction<DeclarationFilters>>;
  currentPage: number;
  linesPerPage: number;
  updateAndFetch: (options: UpdateOptions) => void;
  error: boolean;
  hasFetched: boolean;
  declarationsYearsInspecteur: number[];
}

const Context = React.createContext<DeclarationsDashboardContextState | null>(
  null
);

export const useDeclarationsDashboardContext = (
  initialFetchOptions: UpdateOptions
): DeclarationsDashboardContextState => {
  const context = useContext(Context);

  if (context === null) {
    throw Error("DeclarationsDashboardContext should be mounted");
  }

  if (!context.hasFetched) {
    context.updateAndFetch(initialFetchOptions);
  }

  return context;
};

const DeclarationsDashboardContext = (
  props: DeclarationsDashboardContextProps
): React.ReactElement => {
  const campaign = useActiveOrLastCampaign();
  const openAlertModale = useAlertModale();
  const [filters, setFilters] = useState<DeclarationFilters>(
    computeInitialFilterValues(campaign)
  );
  const [linesPerPage, setLinesPerPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const mounted = useRef(true);
  const userData = useUserData();
  const isVerificateur = userData.isAnyPrestataire;
  const [
    declarationsInspecteur,
    retrieveDeclarationsInspecteur,
    resetDeclarationsInspecteur,
  ] = useFetchDeclarationInspecteur(mounted);
  const [
    declarationsVerificateur,
    retrieveDeclarationsVerificateur,
    resetDeclarationsVerificateur,
  ] = useFetchDeclarationVerificateur(mounted);
  const [
    years,
    retrieveDeclarationsYearsInspecteur,
  ] = useFetchDeclarationYears();

  useEffect(() => {
    if (
      userData.isInspecteur ||
      userData.isGerepAdmin ||
      userData.isAdminBQA ||
      userData.isSuperAdmin ||
      userData.isLecteur
    ) {
      retrieveDeclarationsYearsInspecteur();
    }
    return () => {
      mounted.current = false;
    };
  }, []);

  const fetchDeclarations = async (
    filters: DeclarationFilters,
    linesPerPage: number,
    page: number
  ) => {
    if (!hasFetched) {
      setHasFetched(true);
    }

    try {
      if (!isVerificateur) {
        await retrieveDeclarationsInspecteur(filters, linesPerPage, page);
      } else {
        await retrieveDeclarationsVerificateur(filters, linesPerPage, page);
      }
    } catch (excp) {
      await openAlertModale(
        backAlertMessage(excp, backMessageRetrieveDeclarationList)
      );
      setError(true);
    }
  };

  const updateAndFetch = (options: UpdateOptions): void => {
    resetDeclarationsInspecteur();
    resetDeclarationsVerificateur();
    if (options.page !== undefined) {
      setCurrentPage(options.page);
    }
    if (options.linesPerPage !== undefined) {
      setLinesPerPage(options.linesPerPage);
    }
    if (options.filters) {
      setFilters({ ...filters, ...options.filters });
    }

    fetchDeclarations(
      { ...filters, ...options.filters },
      options.linesPerPage !== undefined ? options.linesPerPage : linesPerPage,
      options.page !== undefined ? options.page : currentPage
    );
  };

  const value: DeclarationsDashboardContextState = {
    declarationsInspecteur,
    declarationsVerificateur,
    filters,
    setFilters,
    currentPage,
    linesPerPage,
    updateAndFetch,
    error,
    hasFetched,
    declarationsYearsInspecteur: years,
  };

  return <Context.Provider value={value}>{props.children}</Context.Provider>;
};

export default DeclarationsDashboardContext;
