import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useAlertModale, useSuccessModale } from "common/modale/hooks";
import { useCallback, useState } from "react";
import { FiltresDeclarationDto } from "api/gen";
import { downloadExportResult } from "../../../CommonSpace/CompanyTechnique/2019_now/utils";
import { backAlertMessage } from "common/backErrors/utils";
import {
  backMessageExportAccuseTransmission,
  backMessageExportDeclaration,
} from "common/backErrors/errorMessages";
import { triggerDownload } from "common/utils/methods";

const useExportCallBack = (
  fileExtension: string,
  exportGETFunction: (annee: number, etablissement: string) => Promise<Response>
): ((
  declarationAnnee: number,
  declarationEtablissement: string
) => Promise<boolean>) => {
  const openModale = useAlertModale();

  return useCallback(
    async (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<boolean> => {
      try {
        const returnedResult = await exportGETFunction(
          declarationAnnee,
          declarationEtablissement
        );
        if (
          !(await downloadExportResult(
            returnedResult,
            `${declarationEtablissement}_${declarationAnnee}.${fileExtension}`
          ))
        ) {
          throw Error("Failed to download declaration content.");
        }
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageExportDeclaration));
        return false;
      }

      return true;
    },
    [exportGETFunction, openModale, fileExtension]
  );
};

export const useDeclarationAggregation = (): ((
  filters: FiltresDeclarationDto
) => Promise<boolean>) => {
  const { gestionnaireController } = useAuthenticatedApi();
  const openAlertModale = useAlertModale();
  const openSuccessModale = useSuccessModale();

  return useCallback(
    async (filters: FiltresDeclarationDto): Promise<boolean> => {
      try {
        await gestionnaireController.exportDeclarationsListUsingPOST(filters);
        openSuccessModale("La demande d'export a bien été reçue.");
      } catch (excp) {
        openAlertModale(backAlertMessage(excp, backMessageExportDeclaration));
        return false;
      }

      return true;
    },
    [gestionnaireController, openAlertModale]
  );
};

export function useDownloadExport(): [
  boolean,
  (exportId: string) => Promise<void>
] {
  const { fileController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const [isExportDownloaded, setExportDownloaded] = useState(false);

  const downloadExportCallback = useCallback(
    async (exportId: string) => {
      try {
        const returnedResult = await fileController.downloadExportUsingGET(
          exportId
        );
        const hasDownloadSucceeded = await downloadExportResult(
          returnedResult,
          "_defaultFileName" // backend is expected to have set the filename
        );
        if (!hasDownloadSucceeded) {
          throw Error("Failed to download declaration content.");
        }
        setExportDownloaded(true);
      } catch (e) {
        openModale(backAlertMessage(e, backMessageExportDeclaration));
      }
    },
    [fileController, openModale]
  );

  return [isExportDownloaded, downloadExportCallback];
}

export const useDeclarationExcelExport = (): ((
  declarationAnnee: number,
  declarationEtablissement: string
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  // Be careful : Swagger does not bind functions with their associated controlers, so we need to
  // use an anonymous function that calls the Swagger one.
  // See https://github.com/Polyconseil/mtes-gerep/pull/1188#discussion_r423547032
  const exportGETFunction = useCallback(
    (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<Response> =>
      declarationController.exportDeclarationExcelUsingGET(
        declarationAnnee,
        declarationEtablissement
      ),
    [declarationController]
  );

  return useExportCallBack("xlsx", exportGETFunction);
};

export const useDeclarationPdfExport = (): ((
  declarationAnnee: number,
  declarationEtablissement: string
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  // Be careful : Swagger does not bind functions with their associated controlers, so we need to
  // use an anonymous function that calls the Swagger one.
  // See https://github.com/Polyconseil/mtes-gerep/pull/1188#discussion_r423547032
  const exportGETFunction = useCallback(
    (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<Response> =>
      declarationController.exportDeclarationPdfUsingGET(
        declarationAnnee,
        declarationEtablissement
      ),
    [declarationController]
  );

  return useExportCallBack("pdf", exportGETFunction);
};

export const useAccuseTransmissionPdfExport = (
  suffixFileName: string,
  exportRequest: (
    annee: number,
    etablissement: string,
    options?: any
  ) => Promise<Response>
): ((
  declarationAnnee: number,
  declarationEtablissement: string
) => Promise<boolean>) => {
  const openModale = useAlertModale();

  return useCallback(
    async (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<boolean> => {
      try {
        const fetchResult = await exportRequest(
          declarationAnnee,
          declarationEtablissement
        );
        const blob = await fetchResult.blob();
        if (!blob) {
          throw Error("Failed to load declaration transmission PDF");
        }

        let fileName = fetchResult.headers.get("Content-Disposition");
        if (fileName) {
          // Parsing the header to get the file name
          // It is formated as 'name="{fileName}"'
          fileName = fileName.split('"')[1];
        } else {
          fileName =
            "Accuse_transmission_declaration_" +
            suffixFileName +
            declarationEtablissement +
            "_" +
            declarationAnnee +
            ".pdf";
        }
        triggerDownload(blob, fileName);
        return true;
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageExportAccuseTransmission));
        return false;
      }
    },
    [openModale, exportRequest, suffixFileName]
  );
};
