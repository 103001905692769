import * as Yup from "yup";
import { StringSchema } from "yup";
import {
  commonBooleanFieldsNullable,
  commonMixedFields,
  commonNullableStringFields,
  commonPercentageFieldsNullable,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveNumberFields,
  commonStringFields,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { FluidInModale, hfcFluidFieldMatcher } from "../utils/types";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  gazFluoresCapacityMessage,
  gazFluoresUniqueMessage,
  sumPercentagesMessage,
} from "common/declarant/formik/formikMessages";
import { ReferenceItemGazDto } from "api/gen";
import { AUTRE_UUID } from "../utils/selectPossibleValues";
import { sumHFC } from "../utils/utils";

export const shouldDisplayJustify = (
  capacity: number | null,
  emission: number | null
): boolean => {
  return capacity !== null && emission !== null && capacity < emission;
};

export const validateHFC = (
  values: FluidInModale,
  forbiddenAutreFluidNames: string[]
): FormikErrors<FluidInModale> => {
  const errors: FormikErrors<FluidInModale> = {};
  if (sumHFC(values) > 100) {
    errors.hfc1233zd =
      (values.hfc1233zd !== null && sumPercentagesMessage) || undefined;
    errors.hfc1234yf =
      (values.hfc1234yf !== null && sumPercentagesMessage) || undefined;
    errors.hfc1234ze =
      (values.hfc1234ze !== null && sumPercentagesMessage) || undefined;
    errors.hfc125 =
      (values.hfc125 !== null && sumPercentagesMessage) || undefined;
    errors.hfc134 =
      (values.hfc134 !== null && sumPercentagesMessage) || undefined;
    errors.hfc134a =
      (values.hfc134a !== null && sumPercentagesMessage) || undefined;
    errors.hfc143a =
      (values.hfc143a !== null && sumPercentagesMessage) || undefined;
    errors.hfc152a =
      (values.hfc152a !== null && sumPercentagesMessage) || undefined;
    errors.hfc227ea =
      (values.hfc227ea !== null && sumPercentagesMessage) || undefined;
    errors.hfc23 =
      (values.hfc23 !== null && sumPercentagesMessage) || undefined;
    errors.hfc143 =
      (values.hfc143 !== null && sumPercentagesMessage) || undefined;
    errors.hfc236fa =
      (values.hfc236fa !== null && sumPercentagesMessage) || undefined;
    errors.hfc245ca =
      (values.hfc245ca !== null && sumPercentagesMessage) || undefined;
    errors.hfc245fa =
      (values.hfc245fa !== null && sumPercentagesMessage) || undefined;
    errors.hfc32 =
      (values.hfc32 !== null && sumPercentagesMessage) || undefined;
    errors.hfc365mfc =
      (values.hfc365mfc !== null && sumPercentagesMessage) || undefined;
    errors.hfc41 =
      (values.hfc41 !== null && sumPercentagesMessage) || undefined;
    errors.hfc4310mee =
      (values.hfc4310mee !== null && sumPercentagesMessage) || undefined;
  }

  if (
    shouldDisplayJustify(values.capacite, values.quantite) &&
    !values.justification
  ) {
    errors.capacite = gazFluoresCapacityMessage;
  }

  if (forbiddenAutreFluidNames.some(fluid => fluid === values.preciser)) {
    errors.preciser = gazFluoresUniqueMessage;
  }

  return errors;
};

export const isGazAutre = (type: ReferenceItemGazDto | null): boolean => {
  return !!type && type.uuid === AUTRE_UUID;
};

export const overwriteDependantFields = (hfc: FluidInModale): void => {
  if (!isGazAutre(hfc.fluid)) {
    hfc.preciser = null;
  }
};

//TODO [GEREP-2579] il faudra remettre le shape FluidInModale ici.
export const singleFluidValidationSchema = Yup.object().shape({
  fluid: commonMixedFields<ReferenceItemGazDto>(),
  preciser: subFieldValidationScheme<
    StringSchema<string | null | undefined>,
    ReferenceItemGazDto | null
  >(hfcFluidFieldMatcher.fluid, isGazAutre, commonStringFields, Yup.string()),
  justification: commonNullableStringFields,
  quantite: commonStrictlyPositiveNumberFields,
  capacite: commonPositiveNumberFieldsNullable,
  hfc32: commonPercentageFieldsNullable,
  hfc125: commonPercentageFieldsNullable,
  hfc134a: commonPercentageFieldsNullable,
  hfc143a: commonPercentageFieldsNullable,
  hfc152a: commonPercentageFieldsNullable,
  hfc227ea: commonPercentageFieldsNullable,
  hfc1234yf: commonPercentageFieldsNullable,
  hfc1234ze: commonPercentageFieldsNullable,
  hfc23: commonPercentageFieldsNullable,
  hfc41: commonPercentageFieldsNullable,
  hfc4310mee: commonPercentageFieldsNullable,
  hfc134: commonPercentageFieldsNullable,
  hfc143: commonPercentageFieldsNullable,
  hfc236fa: commonPercentageFieldsNullable,
  hfc245ca: commonPercentageFieldsNullable,
  hfc245fa: commonPercentageFieldsNullable,
  hfc365mfc: commonPercentageFieldsNullable,
  hfc1233zd: commonPercentageFieldsNullable,
  autre: commonBooleanFieldsNullable,
});

//TODO [GEREP-2579] il faudra remettre le shape AirGazPfcDto ici.
export const pcfValidationSchema = Yup.object().shape({
  cf4: commonPositiveNumberFieldsNullable,
  c2f6: commonPositiveNumberFieldsNullable,
  c3f8: commonPositiveNumberFieldsNullable,
  c4f10: commonPositiveNumberFieldsNullable,
  c5f12: commonPositiveNumberFieldsNullable,
  c6f14: commonPositiveNumberFieldsNullable,
  c4f8: commonPositiveNumberFieldsNullable,
});
