import {
  commonArrayOfObjectFields,
  commonBooleanFields,
  commonNullableStringFields,
  commonNumberPercentFields,
  commonObjectFields,
  commonPositiveNumberFieldsNullable,
  subFieldValidationScheme,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import * as Yup from "yup";
import { Errors, parseToUndefinedIfNull } from "common/form/utils";
import {
  computeEmissionDiffuse,
  computeEmissionTotale,
  computeI1,
} from "../utils/calculus";
import {
  solvantsEmissionsDiffusesMessage,
  solvantsEmissionsTotalesMessage,
  solvantsI1Message,
} from "common/declarant/formik/formikMessages";
import { InputEmissionsInModale } from "../utils/types";

export const epurationSubPartActivated = (type: boolean | null): boolean => {
  return !!type;
};

export const pgsSimpleSubPartActivated = (type: boolean | null): boolean => {
  return !type;
};

export const singleEmissionOverwriteDependantFields = (
  values: InputEmissionsInModale
): void => {
  if (!epurationSubPartActivated(values.epuration)) {
    values.natureEquipement = null;
    values.rendement = null;
  }
  if (!pgsSimpleSubPartActivated(values.pgsSimple)) {
    values.o1 = null;
    values.o2 = null;
    values.o3 = null;
    values.o4 = null;
    values.o9 = null;
    values.emission = null;
  }
};

export const singleEmissionValidationSchemaFunction = (isSpecific: boolean) => {
  return Yup.object().shape({
    installations: commonArrayOfObjectFields,
    substance: isSpecific
      ? commonObjectFields
      : Yup.object().transform(parseToUndefinedIfNull),
    pgsSimple: commonBooleanFields,
    i1: commonPositiveNumberFieldsNullable,
    i2: commonPositiveNumberFieldsNullable,
    o1: commonPositiveNumberFieldsNullable,
    o2: commonPositiveNumberFieldsNullable,
    o3: commonPositiveNumberFieldsNullable,
    o4: commonPositiveNumberFieldsNullable,
    o5: commonPositiveNumberFieldsNullable,
    o6: commonPositiveNumberFieldsNullable,
    o7: commonPositiveNumberFieldsNullable,
    o8: commonPositiveNumberFieldsNullable,
    o9: commonPositiveNumberFieldsNullable,
    epuration: commonBooleanFields,
    natureEquipement: subStringFieldValidationScheme(
      "epuration",
      epurationSubPartActivated
    ),
    rendement: subFieldValidationScheme(
      "epuration",
      epurationSubPartActivated,
      commonNumberPercentFields,
      Yup.number()
    ),
    //2 fields here are here only for dummyInput, so the errors linked to them appear only when they're touched
    // aka when the user hit validate
    emissionDiffuses: commonNullableStringFields,
    emissionTotales: commonNullableStringFields,
    consommationSolvant: commonNullableStringFields,
    utilisationSolvant: commonNullableStringFields,
  });
};

export const additionalValidations = (
  values: InputEmissionsInModale
): Errors<InputEmissionsInModale> => {
  let error: Errors<InputEmissionsInModale> = {};
  const sumI1 = computeI1(values);
  if (!values.pgsSimple && sumI1 !== values.i1) {
    error = {
      ...error,
      i1: solvantsI1Message + ", soit " + sumI1 + "kg/an.",
    };
  }
  if (!values.pgsSimple && computeEmissionDiffuse(values) < 0) {
    error = {
      ...error,
      emissionDiffuses: solvantsEmissionsDiffusesMessage,
    };
  }
  if (computeEmissionTotale(values) < 0) {
    error = {
      ...error,
      emissionTotales: solvantsEmissionsTotalesMessage,
    };
  }
  return error;
};
