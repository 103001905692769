import React, { ReactElement } from "react";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import MethodeCalculFluxExceptPfcList, {
  MethodeCalculFluxExceptPfcListProps,
} from "./MethodeCalculFluxExceptPfcList";
import MethodeMesureEmissionList, {
  MethodeMesureEmissionListProps,
} from "./MethodeMesureEmissionList";
import Row from "common/presentational/Row";
import { MethodeSurveillanceDto } from "api/gen";
import MethodeCalculFluxPfcList, {
  MethodeCalculFluxPfcListProps,
} from "./MethodeCalculFluxPfcList";

type AerDonneesSurveillanceProps = MethodeCalculFluxExceptPfcListProps &
  MethodeMesureEmissionListProps &
  MethodeCalculFluxPfcListProps & {
    methodeSurveillanceDto: MethodeSurveillanceDto;
  };

const AerDonneesSurveillance = ({
  methodeCalculFluxExceptPfcList,
  methodeMesureEmissionList,
  methodeCalculFluxPfcList,
  methodeSurveillanceDto,
}: AerDonneesSurveillanceProps): ReactElement => {
  const {
    methodeCalculCo2,
    methodeMesureCo2,
    surveillanceCo2,
    surveillancePfc,
  } = methodeSurveillanceDto;
  return (
    <>
      <SubSectionTitle>
        DONNÉES RELATIVES AU CALCUL DES ÉMISSIONS
      </SubSectionTitle>
      <Row height={"20px"} />

      {methodeCalculCo2 && (
        <MethodeCalculFluxExceptPfcList
          methodeCalculFluxExceptPfcList={methodeCalculFluxExceptPfcList}
        />
      )}

      {(methodeMesureCo2 || surveillanceCo2) && (
        <MethodeMesureEmissionList
          methodeMesureEmissionList={methodeMesureEmissionList}
        />
      )}

      {surveillancePfc && (
        <MethodeCalculFluxPfcList
          methodeCalculFluxPfcList={methodeCalculFluxPfcList}
        />
      )}
    </>
  );
};

export default AerDonneesSurveillance;
