import {
  commonNumberFields,
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { MethodEnum } from "../types";
import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  method: Yup.mixed<MethodEnum>().required(requiredMessage),
  flow: commonObjectFields,
  fossilEmission: Yup.number().when("method", {
    is: (method: MethodEnum | null) =>
      !method || method !== MethodEnum.BILAN_MASSIQUE,
    then: commonPositiveNumberFields,
    otherwise: commonNumberFields,
  }),
  biomassEmission: Yup.number().when("method", {
    is: (method: MethodEnum | null) =>
      !method || method !== MethodEnum.BILAN_MASSIQUE,
    then: commonPositiveNumberFields,
    otherwise: commonNumberFields,
  }),
});
