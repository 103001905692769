import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BACKGROUND_GREY, PINK, TAB_UNDERLINE_SIZE } from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  tab: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
    borderBottom: `${TAB_UNDERLINE_SIZE} solid transparent`,
    borderTop: `${TAB_UNDERLINE_SIZE} solid transparent`,
    alignItems: "center",
    padding: "0 10px",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover": {
      fontWeight: "bold",
    },
  },
  selected: {
    color: PINK,
    backgroundColor: BACKGROUND_GREY,
    borderBottom: `${TAB_UNDERLINE_SIZE} solid ${PINK}`,
    fontWeight: "bold",
  },
});

interface SingleTabProps {
  isSelected: boolean;
  title: string;
  onClick(e: string): void;
  img?: string;
  displayImg?: boolean;
  style?: string;
}

const SingleTab = ({
  isSelected,
  title,
  onClick,
  img,
  displayImg,
  style,
}: SingleTabProps) => {
  const classes = useStyles();

  const handleOnClick = () => {
    onClick(title);
  };

  return (
    <div
      className={classNames(classes.tab, {
        [classes.selected]: isSelected,
      })}
      onClick={handleOnClick}
    >
      {displayImg ? <img src={img} className={style} alt="danger" /> : ""}
      {title}
    </div>
  );
};

export default SingleTab;
