import { makeStyles } from "@material-ui/styles";
import React from "react";
import { PAGE_TITLE_FONT_SIZE, TITLE_COLOR } from "theme";
import classNames from "classnames";
import { usePageHeaderInfo } from "pages/CommonSpace/AppPage/PageHeaderContext";

const useStyles = makeStyles({
  title: {
    color: TITLE_COLOR,
    fontSize: PAGE_TITLE_FONT_SIZE,
    margin: "auto",
    fontWeight: 400,
    textAlign: "center",
  },
  titleWrapper: {
    display: "flex",
    textTransform: "uppercase",
    padding: "30px",
    textAlign: "center",
    position: "relative",
  },
  image: {
    maxHeight: "100px",
  },
});

const SectionTitle = (): React.ReactElement | null => {
  const classes = useStyles();
  const headerContext = usePageHeaderInfo();

  if (headerContext === null) {
    return null;
  }
  const image = headerContext.image;
  return (
    <div className={classNames(classes.titleWrapper)}>
      {image && <div style={{ width: "8%" }} />}
      {/* Check that all h3 have font-weight: 400 for uniformity */}
      <h3 className={classes.title}>{headerContext.title}</h3>
      {image && (
        <img {...image} alt={image.alt} width="8%" className={classes.image} />
      )}
    </div>
  );
};

export default SectionTitle;
