import React, { useState } from "react";
import { cloneDeep, isEqual } from "lodash";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import {
  BuildingInArray,
  EmissionsSpeciesForm,
  especesFieldMatcher,
} from "../utils/types";
import {
  convertBuildingInArrayListToDtos,
  convertDtosToBuildingInArrayList,
} from "./utils/converters";
import Button from "common/button";
import EmissionsArray from "./EmissionsArray";
import EmissionsModal from "./EmissionsModal";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { makeStyles } from "@material-ui/styles";
import { especeLabels, especesList } from "./utils/selectPossibleValues";
import {
  updateDeclarationDtoUsingBuildings,
  updateDeclarationDtoUsingEmissionsSpeciesForm,
} from "../utils/converters";
import {
  PATH_AIR_ELEVAGE_DETAIL,
  PATH_AIR_ELEVAGE_DETAIL_ESPECES,
} from "common/path/path18Now";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { elevageArrayBuildingsRequired } from "common/declarant/formik/formikMessages";
import Row from "common/presentational/Row";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { compareBuildingFunction, compareSpeciesFunction } from "./utils/utils";
import { specieValidationSchema } from "./utils/validation";
import { CallbackSetter } from "common/utils/types";

interface EmissionsWhenMtdUncheckedProps {
  buildingsInArray: BuildingInArray[];
  setBuildingsInArray: CallbackSetter<BuildingInArray[]>;
  formSpeciesEmissionsProps: FormikBlocRefAndState<EmissionsSpeciesForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
  },
  addNH3EmissionButton: {
    marginRight: "10%",
    marginTop: "30px",
    padding: "5px",
    alignSelf: "flex-end",
  },
  dropdown: {
    zIndex: 11,
  },
});

const BlocEmissions = ({
  buildingsInArray,
  setBuildingsInArray,
  formSpeciesEmissionsProps,
}: EmissionsWhenMtdUncheckedProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration20Now();

  const commonProps = {
    disabled: false,
    formPrefix: "bloc-emissions-mtd-checked",
    labelWidth: "50%",
    className: classes.inputField,
  };

  const initialValues: EmissionsSpeciesForm = {
    species: declaration.body.sections.air.elevage.detailNh3.especesDeclarees,
  };

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [
    buildingInModal,
    setBuildingInModal,
  ] = useState<BuildingInArray | null>(null);

  return (
    <FormikBlocFullContext20Now
      hasChanges={formSpeciesEmissionsProps.hasChanges}
      setHasChanges={formSpeciesEmissionsProps.setHasChanges}
      formikRef={formSpeciesEmissionsProps.formikRef}
      title="ÉMISSIONS DE NH3 PAR CATÉGORIE, PAR BÂTIMENT ET PAR EMPLACEMENT"
      validationSchema={specieValidationSchema}
      initialValues={initialValues}
      updateHandler={(
        declaration: Declaration20Now,
        values: EmissionsSpeciesForm
      ) => {
        declaration = updateDeclarationDtoUsingBuildings(
          declaration,
          buildingsInArray,
          values.species
        );
        return updateDeclarationDtoUsingEmissionsSpeciesForm(
          declaration,
          values
        );
      }}
      hasFormChanges={values => {
        const isSpeciesArrayUpdated = !isEqual(
          convertBuildingInArrayListToDtos(buildingsInArray, values.species),
          declaration.body.sections.air.elevage.detailNh3.batiments
        );
        const isSpeciesDeclaredUpdated = !isEqual(
          values.species,
          declaration.body.sections.air.elevage.detailNh3.especesDeclarees
        );
        return isSpeciesArrayUpdated || isSpeciesDeclaredUpdated;
      }}
      cancelAction={() => {
        setBuildingsInArray(() =>
          convertDtosToBuildingInArrayList(
            declaration.body.sections.air.elevage.detailNh3.batiments
          )
        );

        return initialValues;
      }}
      preventSubmitWithErrors={() => {
        if (buildingsInArray.length === 0) {
          return {
            errorArrayRequired: elevageArrayBuildingsRequired,
          };
        }
        return {};
      }}
      pathToValidate={PATH_AIR_ELEVAGE_DETAIL}
      areGlobalCommentsAllowed={true}
      renderContent={({ values, errors }, shouldDisabledFields: boolean) => (
        <>
          <WrappedChoiceSelect
            name={especesFieldMatcher.species}
            label="Veuillez sélectionner toutes les espèces de votre établissement"
            disabled={shouldDisabledFields}
            additionalClassName={classes.dropdown}
            isMulti
            options={especesList}
            computeLabel={espece => especeLabels[espece]}
            commonProps={commonProps}
            commentPath={PATH_AIR_ELEVAGE_DETAIL_ESPECES}
          />

          <Button
            text="AJOUTER UN BÂTIMENT"
            additionalClassname={classes.addNH3EmissionButton}
            onClick={() => setModalIsOpen(true)}
            isDisabled={shouldDisabledFields || values.species.length === 0}
          />

          <EmissionsArray
            species={cloneDeep(values.species).sort(compareSpeciesFunction)}
            buildingsInArray={cloneDeep(buildingsInArray).sort(
              compareBuildingFunction
            )}
            setBuildingsInArray={setBuildingsInArray}
            setBuildingInModale={setBuildingInModal}
            setBuildingModaleOpen={setModalIsOpen}
            isDisabled={shouldDisabledFields}
          />

          <EmissionsModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            species={values.species}
            buildingsInArray={buildingsInArray}
            setBuildingsInArray={setBuildingsInArray}
            setBuildingInModal={setBuildingInModal}
            buildingInModal={buildingInModal}
          />

          <Row />

          <ErrorDisplayer message={errors.errorArrayRequired} />
        </>
      )}
    />
  );
};

export default BlocEmissions;
