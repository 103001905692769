export enum MethodCombustion {
  "FACTEUR_EMISSION" = "FACTEUR_EMISSION",
  "MESURE" = "MESURE",
  "BILAN_MATIERE" = "BILAN_MATIERE",
}

export const arrayMethodsLabel: {
  [key in MethodCombustion]: string;
} = {
  FACTEUR_EMISSION: "Facteur d'émission",
  MESURE: "Mesure",
  BILAN_MATIERE: "Bilan matière",
};

export const arrayMethods: MethodCombustion[] = Object.keys(
  arrayMethodsLabel
) as MethodCombustion[];
