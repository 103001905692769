import {
  MesurePoussiereAtmospheriqueDto1819,
  MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum,
} from "api/gen";
import { DustFalloutForm, DustFalloutPointInArray } from "./types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { JAUGE, PLAQUETTE, selectPossibleValues } from "./selectPossibleValues";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const convertDisplayedToDustFalloutPoint = (
  populatedEmissionPoints: DustFalloutPointInArray[]
): MesurePoussiereAtmospheriqueDto1819[] => {
  return populatedEmissionPoints.map(singlePopulatedEmissionPoint => {
    const getLocationType = (): MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum | null => {
      if (!singlePopulatedEmissionPoint.data.locationType) {
        return null;
      }
      return singlePopulatedEmissionPoint.data.locationType.object;
    };
    return {
      id: singlePopulatedEmissionPoint.data.id,
      jauge: singlePopulatedEmissionPoint.data.gaugeOrBooklet === JAUGE,
      premierTrimestre: singlePopulatedEmissionPoint.data.firstTrimester,
      secondTrimestre: singlePopulatedEmissionPoint.data.secondTrimester,
      troisiemeTrimestre: singlePopulatedEmissionPoint.data.thirdTrimester,
      quatriemeTrimestre: singlePopulatedEmissionPoint.data.fourthTrimester,
      libelle: singlePopulatedEmissionPoint.data.name,
      typeDEmplacement: getLocationType(),
    };
  });
};

export const convertDustFalloutPointToDisplayed = (
  emissionPoints: MesurePoussiereAtmospheriqueDto1819[]
): DustFalloutPointInArray[] => {
  return emissionPoints.map(singleEmissionPoint => {
    const getLocationType = (): OptionPropsWithObject<
      MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum
    > | null => {
      if (!singleEmissionPoint.typeDEmplacement) {
        return null;
      }
      return (
        selectPossibleValues.locationType.find(
          locationType =>
            locationType.object === singleEmissionPoint.typeDEmplacement
        ) || null
      );
    };
    return {
      data: {
        id: singleEmissionPoint.id,
        name: singleEmissionPoint.libelle,
        locationType: getLocationType(),
        gaugeOrBooklet: singleEmissionPoint.jauge ? JAUGE : PLAQUETTE,
        firstTrimester: singleEmissionPoint.premierTrimestre,
        secondTrimester: singleEmissionPoint.secondTrimestre,
        thirdTrimester: singleEmissionPoint.troisiemeTrimestre,
        fourthTrimester: singleEmissionPoint.quatriemeTrimestre,
      },
      errors: {},
    };
  });
};

export const convertDtoToDustFalloutForm = (
  declaration: Declaration1919
): DustFalloutForm => {
  return {
    doneMeasures: !!declaration.body.sections.carriere.environnement
      .poussiereAtmospherique.mesureEffectuee,
  };
};
