import React, { ReactElement } from "react";
import { InfosGeneralesAlcDto } from "api/gen";
import { useDummyTextFieldGenerator } from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { translateBoolToFrenchOrNull } from "common/utils/booleanUtils";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
  },
  centeredInput: {
    textAlign: "center",
  },
  formContainer: {
    width: "75%",
    margin: "50px 0",
  },
});

const AlcVerifieGenericInfos = ({
  infosGeneralesAlcDto,
}: {
  infosGeneralesAlcDto: InfosGeneralesAlcDto;
}): ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "alc-verifie-infos-generales",
  };
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.centeredInput
  );

  const {
    annee,
    numeroNim,
    isInstallationEnPlace,
    atLeastOneSubInstallationStoppedActivites,
  } = infosGeneralesAlcDto;

  return (
    <div className={classes.formContainer}>
      <DummyTextField
        name={"annee"}
        label={"Année de la déclaration"}
        value={annee}
      />
      <DummyTextField
        name={"numeroNim"}
        label={"Numéro NIM"}
        value={numeroNim}
      />
      <DummyTextField
        name={"estInstallationEnPlace"}
        label={"Cette installation est une installation en place"}
        value={translateBoolToFrenchOrNull(isInstallationEnPlace)}
      />
      <DummyTextField
        name={"atLeastOneSubInstallationStoppedActivites"}
        label={"L'installation ou une sous-installation a cessé ses activités"}
        value={translateBoolToFrenchOrNull(
          atLeastOneSubInstallationStoppedActivites
        )}
      />
    </div>
  );
};

export default AlcVerifieGenericInfos;
