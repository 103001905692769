import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import { QuotasInstallationDto20Now } from "api/gen";
import classNames from "classnames";
import Button from "common/button";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { useConfirmationModale } from "common/modale/hooks";
import { PATH_QUOTAS_EMISSIONS_BLOC_FLUX } from "common/path/path20Now";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import { FIELD_FONT_SIZE, FIELD_FONT_WEIGHT, SECTION_TITLE_GREY } from "theme";
import uuid from "uuid";
import { computedMethodsOptions } from "../selectPossibleValues";
import {
  BlocEmissionsFormValues,
  ComputedAllowedDeclarationMethods,
  ComputedEmissionInArray,
  MethodEnum,
} from "../types";
import { hasSameNames } from "../utils/utils";
import ComputedEmissionsArray from "./ComputedEmissionsArray";
import ComputedEmissionsModal from "./ComputedEmissionsModal";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface ComputedMethodProps {
  values: BlocEmissionsFormValues;
  shouldDisabledFields: boolean;
  installations: QuotasInstallationDto20Now[];
  computedEmissionsInArray: ComputedEmissionInArray[];
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  setExemptedFlows: (flows: string[]) => void;
  computedAllowedDeclarationMethods: ComputedAllowedDeclarationMethods;
}

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    marginBottom: "5px",
  },
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "center",
    alignSelf: "center",
    width: "25%",
  },
  fieldContainer: {
    width: "25%",
    justifyContent: "center",
  },
  field: {
    width: "100%",
    margin: "auto",
  },
  noLabel: {
    "&>div:nth-child(1)": {
      marginRight: "0", // Remove margin used for label
    },
  },
  insertFlowButton: {
    marginLeft: "10px",
    marginBottom: "20px",
  },
  insertArrayButton: {
    marginLeft: "80%",
  },
  button: {
    padding: "5px",
    alignSelf: "flex-start",
  },
  header: {
    color: SECTION_TITLE_GREY,
    fontWeight: "bold",
    marginBottom: "30px",
  },
  subHeader: {
    color: SECTION_TITLE_GREY,
    marginLeft: "10px",
    marginBottom: "20px",
    marginTop: "20px",
  },
});

const ComputedMethod = ({
  values,
  shouldDisabledFields,
  installations,
  computedEmissionsInArray,
  setComputedEmissionsInArray,
  setExemptedFlows,
  computedAllowedDeclarationMethods,
}: ComputedMethodProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.noLabel,
    labelWidth: "0",
    formPrefix: "bloc-quotas-declaration-emissions-calculees",
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    computedEmissionInModal,
    setComputedEmissionInModal,
  ] = useState<ComputedEmissionInArray | null>(null);

  const TextField = useTextFieldGenerator(commonProps);

  const openConfirmationModale = useConfirmationModale();

  return (
    <>
      <h3 className={classes.header}>Méthode par calcul</h3>

      <h4 className={classes.subHeader}>Déclaration des flux *</h4>

      <FieldArray
        name="flowDeclarations"
        render={arrayHelpers => (
          <>
            <Button
              text={
                <span>
                  <FontAwesomeIcon icon="plus" /> AJOUTER
                </span>
              }
              onClick={() =>
                arrayHelpers.push({
                  id: uuid(),
                  name: "",
                  nim: "",
                })
              }
              additionalClassname={classNames(
                classes.button,
                classes.insertFlowButton
              )}
              isDisabled={shouldDisabledFields}
            />
            {values.flowDeclarations.length > 0 ? (
              <>
                <div className={classes.row}>
                  <p className={classes.label}>Nom du flux *</p>
                  <p className={classes.label}>NIM *</p>
                  <p className={classes.label}></p>
                </div>
                {values.flowDeclarations.map((flow, i) => (
                  <div key={flow.id} className={classes.row}>
                    <div className={classes.fieldContainer}>
                      <TextField
                        additionalClassName={classes.field}
                        label=""
                        name={`flowDeclarations.${i}.name`}
                        disabled={shouldDisabledFields}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <WrappedChoiceSelect
                        additionalClassName={classes.field}
                        label=""
                        name={`flowDeclarations.${i}.nimInstallation`}
                        computeLabel={installation => installation.nim || ""}
                        isMulti={false}
                        options={installations}
                        disabled={shouldDisabledFields}
                        commonProps={commonProps}
                      />
                    </div>
                    <div className={classes.fieldContainer}>
                      <CommonEntityButton
                        isValidated={shouldDisabledFields}
                        handlerSuppress={() =>
                          openConfirmationModale(
                            "Êtes-vous sûr de vouloir supprimer ces informations ? Les émissions calculées liées seront également supprimées.",
                            () => {
                              arrayHelpers.remove(i);
                              setComputedEmissionsInArray(() =>
                                computedEmissionsInArray.filter(
                                  e => e.data.flow && e.data.flow.id !== flow.id
                                )
                              );
                              setExemptedFlows(
                                values.exemptedFlows.filter(
                                  exemptedFlowId => exemptedFlowId !== flow.id
                                )
                              );
                            }
                          )
                        }
                        commentPath={`${PATH_QUOTAS_EMISSIONS_BLOC_FLUX}/${flow.id}`}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : (
              ""
            )}
          </>
        )}
      />

      <h4 className={classes.subHeader}>Émissions calculées</h4>

      <Button
        text="AJOUTER UNE ÉMISSION"
        additionalClassname={classNames(
          classes.button,
          classes.insertArrayButton
        )}
        onClick={() => setIsModalOpen(true)}
        isDisabled={
          shouldDisabledFields ||
          values.flowDeclarations.filter(f => f.name).length === 0 ||
          hasSameNames(values.flowDeclarations)
        }
      />

      <ComputedEmissionsArray
        computedEmissionsInArray={computedEmissionsInArray}
        setComputedEmissionsInArray={setComputedEmissionsInArray}
        onEdit={emission => {
          setComputedEmissionInModal(emission);
          setIsModalOpen(true);
        }}
        isDisabled={shouldDisabledFields}
        flowDeclarations={values.flowDeclarations}
      />

      <ComputedEmissionsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        computedEmissionInModal={computedEmissionInModal}
        setComputedEmissionInModal={setComputedEmissionInModal}
        setComputedEmissionsInArray={setComputedEmissionsInArray}
        flowDeclarations={values.flowDeclarations}
        allowedMethods={computedMethodsOptions.filter(singleMethod => {
          switch (singleMethod) {
            case MethodEnum.BILAN_MASSIQUE:
              return computedAllowedDeclarationMethods.bilanMassique;
            case MethodEnum.FACTEUR_CONVERSION:
              return computedAllowedDeclarationMethods.facteurConversion;
            case MethodEnum.FACTEUR_OXYDATION:
              return computedAllowedDeclarationMethods.facteurOxydation;
            case MethodEnum.ALTERNATIVE:
            case MethodEnum.MESURE:
              throw new Error(`Method ${singleMethod.toString()} forbidden`);
            default:
              throw new ShouldNotHappen(singleMethod);
          }
        })}
      />
    </>
  );
};

export default ComputedMethod;
