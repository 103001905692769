import React, { useEffect, useMemo, useState } from "react";
import { WasteData } from "../types";
import { PolluantProduction } from "./production";
import Row from "common/presentational/Row";
import { DeclareWasteModale } from "./productionModal";
import _ from "lodash";
import {
  deleteWasteFromContainer,
  parseFetch,
  productionDataComputed,
  receptionDataComputed,
  shouldDisplayProductionArray,
  shouldDisplayReceptionArray,
  useImportWaste,
} from "../utils/utils";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../entrant/reception/types";
import {
  methods,
  operationOrValoriationForReception,
  operationOrValorisationForProduction,
  productionLocation,
  receptionLocation,
} from "../utils/Referentiels";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
  generatePolluantWasteFromCSV,
} from "../utils/Generators";
import {
  createWasteProductionDto,
  createWasteReceptionDto,
} from "../utils/SubmitHandler";
import {
  ProductedWasteFormData,
  ProductedWasteImport,
  ProductedWasteStorage,
} from "./production/types";
import { Nullable } from "common/utils/types";
import {
  DechetProduitDto23Now,
  DechetProduitDto23NowLieuOperationEnum,
  DechetRecuDto23Now,
  ReferenceDechetDto,
  ReferenceDepartementDto,
  ReferencePaysDto,
} from "api/gen";
import { useEntityModale } from "common/modale/modaleStates";
import { DataWithErrors } from "common/form/utils";
import { DeclareReceptionWasteModal } from "../entrant/receptionModal";
import {
  PATH_DECHET_PRODUCTION,
  PATH_DECHET_PRODUCTION_LIST,
} from "common/path/path18Now";
import { generateReceivedWasteErrors } from "../entrant/reception/utils";
import { generateProductedWasteErrors } from "./production/utils";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";
import {
  useDeclaration24Now,
  useDeclarationHelpers24Now,
} from "../../versionedElements/declarationHooks24Now";
import { TypeActiviteGlobal21Now } from "../../../../from21/toNow/versionedElements/declarationHooks21Now";
import GlobalFormActionFullContext24Now from "../../versionedElements/GlobalFormActionFullContext24Now";

interface WastFormProps {
  referentialDechet: ReferenceDechetDto;
  referentialDepartements: ReferenceDepartementDto;
  referentielPays: ReferencePaysDto;
}

const WasteControllerSortant = ({
  referentialDechet,
  referentialDepartements,
  referentielPays,
}: WastFormProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();

  //  formik refs
  const formProduction = useFormikBloc<ProductedWasteFormData>();
  const formReception = useFormikBloc<ReceivedWasteFormData>();

  const typeActivite: TypeActiviteGlobal21Now = useCorrectYearTypeActiviteGlobal20Now();
  const isReceptionAvailable = typeActivite.recepDechet;

  const receptionDechets: ReceivedWasteFormData | null =
    formReception.formikRef.current &&
    formReception.formikRef.current.state.values;

  const referentiels = useMemo(() => {
    return {
      polluants: referentialDechet.dechets,
      methods: methods,
      operationOrValorisationForProduction: operationOrValorisationForProduction,
      operationOrValorisationForReception: operationOrValoriationForReception,
      productionLocation: productionLocation.filter(singleLocation => {
        return (
          isReceptionAvailable ||
          singleLocation.backCode !==
            DechetProduitDto23NowLieuOperationEnum.SITE
        );
      }),
      receptionLocation: receptionLocation,
      departements: referentialDepartements.departements,
      pays: referentielPays.referenceItemPaysDtoList,
    };
  }, [
    referentialDechet.dechets,
    referentialDepartements.departements,
    referentielPays.referenceItemPaysDtoList,
    isReceptionAvailable,
  ]);

  //  handle fetch data
  const [
    productionInfo,
    productionContainer,
    receptionInfo,
    receptionContainer,
  ] = parseFetch(
    declaration.body,
    referentiels,
    typeActivite,
    receptionDechets
  );

  //    init page data with fetch data
  const [tempProductionContainer, setTempProductionContainer] = useState<
    ProductedWasteStorage[]
  >(productionContainer);
  const [tempReceptionContainer, setTempReceptionContainer] = useState<
    ReceivedWasteStorage[]
  >(receptionContainer);
  const [tempWasteProductInfo, setTempWasteProductInfo] = useState<
    ProductedWasteFormData
  >(productionInfo);
  const [tempWasteReceptionInfo, setTempWasteReceptionInfo] = useState<
    ReceivedWasteFormData
  >(receptionInfo);

  //    modal handlers
  const productionModalProps = useEntityModale<
    DataWithErrors<DechetProduitDto23Now>
  >();
  const receptionModalProps = useEntityModale<
    DataWithErrors<DechetRecuDto23Now>
  >();

  //  to display data
  const wasteData: WasteData = {
    //  containers
    validData: {
      production: productionDataComputed(productionInfo, productionContainer),
      reception: receptionDataComputed(
        receptionInfo,
        receptionContainer,
        productionInfo,
        productionContainer
      ),
    },
    tempData: {
      production: productionDataComputed(
        tempWasteProductInfo,
        tempProductionContainer
      ),
      reception: receptionDataComputed(
        tempWasteReceptionInfo,
        tempReceptionContainer,
        tempWasteProductInfo,
        tempProductionContainer
      ),
    },

    //  form referentiels
    referentiels: referentiels,
  };

  //  function data handler
  const importProduction = useImportWaste<
    DechetProduitDto23Now,
    Nullable<ProductedWasteImport>
  >(
    wasteData.referentiels,
    receptionDechets,
    generatePolluantWasteFromCSV,
    tempProductionContainer,
    setTempProductionContainer,
    () => {
      if (formProduction.formikRef.current) {
        const formValues = formProduction.formikRef.current.state.values;
        if (!_.isEqual(formValues, tempWasteReceptionInfo)) {
          setTempWasteProductInfo(formValues);
        }
      }
    }
  );

  const addDeclaredWaste = (waste: DechetProduitDto23Now) => {
    const data = {
      data: waste,
      errors: generateProductedWasteErrors(waste, referentiels),
    };

    setTempProductionContainer(value => [...value, data]);
    productionModalProps.closeModale();
  };

  const updateProductedWaste = (
    previousData: ProductedWasteStorage,
    newData: DechetProduitDto23Now
  ) => {
    const data = {
      data: newData,
      errors: generateProductedWasteErrors(newData, referentiels),
    };
    setTempProductionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return data;
        }
      });
    });
    productionModalProps.closeModale();
  };

  const addReceivedWaste = (waste: DechetRecuDto23Now) => {
    const data = { data: waste, errors: {} };

    if (formReception.formikRef.current) {
      const formValues = formReception.formikRef.current.state.values;
      setTempWasteReceptionInfo(formValues);
    }
    setTempReceptionContainer(value => [...value, data]);
    receptionModalProps.closeModale();
  };
  const updateReceivedWaste = (
    previousData: ReceivedWasteStorage,
    newData: DechetRecuDto23Now
  ) => {
    setTempReceptionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return { data: newData, errors: {} };
        }
      });
    });
    receptionModalProps.closeModale();
  };

  const computeProductionDto = () => {
    //  I have to check if changes have not been reported here in production form
    let info;
    if (formProduction.formikRef.current) {
      info = formProduction.formikRef.current.state.values;
    } else {
      info = tempWasteProductInfo;
    }

    if (!shouldDisplayProductionArray(info)) {
      setTempProductionContainer([]);
    }

    return createWasteProductionDto({
      formInfo: info,
      container: shouldDisplayProductionArray(info)
        ? tempProductionContainer
        : [],
    });
  };
  const computeReceptionDto = () => {
    //  I have to check if changes have not been reported here in reception form
    let info;
    if (formReception.formikRef.current) {
      info = formReception.formikRef.current.state.values;
    } else {
      info = tempWasteReceptionInfo;
    }

    return createWasteReceptionDto({
      formInfo: info,
      container: shouldDisplayReceptionArray(info)
        ? tempReceptionContainer
        : [],
    });
  };

  const shouldShowBottomSave =
    !_.isEqual(
      wasteData.validData.production.container,
      wasteData.tempData.production.container
    ) ||
    !_.isEqual(
      wasteData.validData.reception.container,
      wasteData.tempData.reception.container
    ) ||
    formProduction.hasChanges ||
    formReception.hasChanges;
  const isProductionValidated = isPathValidatedInDeclaration(
    PATH_DECHET_PRODUCTION_LIST
  );

  // Warning, the dependencies used here make React run the effect ONLY for the first change
  useEffect(() => {
    if (formProduction.formikRef.current) {
      setTempWasteProductInfo(formProduction.formikRef.current.state.values);
    }
  }, [formProduction.hasChanges, formProduction.formikRef]);
  useEffect(() => {
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      setTempWasteReceptionInfo(currentFormReceptionRef.state.values);
    }
  }, [formReception.hasChanges, formReception.formikRef]);

  useEffect(() => {
    //because errors in reception array depend of the formReception, we want to recompute them (btw, putting the errors in the state was a mistake, as they're actually a COMPUTED value)
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      const newReceptionContainer = tempReceptionContainer.map(
        singleWasteReception => ({
          ...singleWasteReception,
          errors: generateReceivedWasteErrors(
            singleWasteReception.data,
            referentiels,
            typeActivite,
            currentFormReceptionRef.state.values
          ),
        })
      );

      if (!_.isEqual(newReceptionContainer, tempReceptionContainer)) {
        setTempReceptionContainer(newReceptionContainer);
      }
    }
  }, [formReception, referentiels, tempReceptionContainer, typeActivite]);

  useEffect(() => {
    // Use effect to update the temp data when the declaration changes
    setTempProductionContainer(
      convertProductionFetchToLocal(
        declaration.body.sections.dechets.production.productionDechet,
        referentiels
      )
    );
    setTempReceptionContainer(
      convertReceptionFetchToLocal(
        declaration.body.sections.dechets.reception.receptionDechets,
        referentiels,
        typeActivite,
        receptionDechets
      )
    );
  }, [declaration.body.sections.dechets]);

  return (
    <>
      <PolluantProduction
        formRef={formProduction}
        wasteData={wasteData}
        validationPath={PATH_DECHET_PRODUCTION_LIST}
        showValidationMessage={isReceptionAvailable}
        importWastes={importProduction}
        deleteWaste={waste => {
          const productionCopy = Array.from(tempProductionContainer);
          const newProduction = deleteWasteFromContainer(waste, productionCopy);
          setTempProductionContainer(newProduction);
        }}
        deleteAllWastes={() => {
          setTempProductionContainer([]);
        }}
        openModal={currentData => {
          productionModalProps.openModale(currentData);
        }}
        reset={() => {
          setTempProductionContainer(productionContainer);
          return wasteData.validData.production.formInfo;
        }}
        computedProductionDto={computeProductionDto}
        computedReceptionDto={computeReceptionDto}
        productionSaveCompletion={declaration => {
          const value = convertProductionFetchToLocal(
            declaration.body.sections.dechets.production.productionDechet,
            referentiels
          );
          setTempProductionContainer(value);
        }}
        hasChanges={values => {
          const isFormChange = !_.isEqual(values, productionInfo);
          const isContainerChange = !_.isEqual(
            tempProductionContainer,
            productionContainer
          );
          return isFormChange || isContainerChange;
        }}
        onChange={formValues => {
          const previousState = shouldDisplayProductionArray(
            wasteData.tempData.production.formInfo
          );
          const currentState = shouldDisplayProductionArray(formValues);
          if (previousState === currentState) {
            return;
          }

          setTempWasteProductInfo(formValues);
        }}
        onUnMount={() => {
          if (formProduction.formikRef.current) {
            setTempWasteProductInfo(
              formProduction.formikRef.current.state.values
            );
          }
        }}
      />

      <Row />

      <DeclareWasteModale
        wasteData={wasteData}
        isOpen={productionModalProps.modaleState.isOpen}
        updatingWaste={productionModalProps.modaleState.elementToEdit}
        close={() => {
          productionModalProps.closeModale();
        }}
        validate={waste => {
          if (productionModalProps.modaleState.elementToEdit) {
            updateProductedWaste(
              productionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addDeclaredWaste(waste);
          }
        }}
        isOnSiteOptionAvailable={isReceptionAvailable}
      />

      <DeclareReceptionWasteModal
        wasteData={wasteData}
        isOpen={receptionModalProps.modaleState.isOpen}
        updatingWaste={receptionModalProps.modaleState.elementToEdit}
        aCasierAmiantes={
          wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases
        }
        isISDIChecked={wasteData.tempData.reception.formInfo.isISDI}
        isISDDChecked={wasteData.tempData.reception.formInfo.isISDD}
        isISDNDChecked={wasteData.tempData.reception.formInfo.isISDND}
        isIncinerationDechetChecked={
          wasteData.tempData.reception.formInfo.isIncinerationDechet
        }
        close={() => {
          receptionModalProps.closeModale();
        }}
        validate={waste => {
          if (receptionModalProps.modaleState.elementToEdit) {
            updateReceivedWaste(
              receptionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addReceivedWaste(waste);
          }
        }}
      />

      <GlobalFormActionFullContext24Now
        validationTitle={"VALIDER PAGE >"}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={shouldShowBottomSave}
        isValidateEnabled={isProductionValidated}
        validationPath={PATH_DECHET_PRODUCTION}
        updateHandler={declaration => {
          declaration.body.sections.dechets.production = computeProductionDto();
          return declaration;
        }}
        cancelAction={() => {
          setTempWasteProductInfo(productionInfo);
          setTempProductionContainer(productionContainer);

          setTempWasteReceptionInfo(receptionInfo);
          setTempReceptionContainer(receptionContainer);

          if (formProduction.formikRef.current) {
            formProduction.formikRef.current.resetForm(productionInfo);
          }
          if (formReception.formikRef.current) {
            formReception.formikRef.current.resetForm(receptionInfo);
          }
        }}
      />
    </>
  );
};

export default WasteControllerSortant;
