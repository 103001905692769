import { RouteIdProps } from "common/declarant/type";
import React from "react";
import { Redirect } from "react-router";

const RedirectDashboard = ({ match }: RouteIdProps): React.ReactElement => {
  const redirectUrl = `/declaration/${parseInt(match.params.annee)}/${
    match.params.etablissement
  }/dashboard`;
  return <Redirect to={redirectUrl} />;
};

export default RedirectDashboard;
