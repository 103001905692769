import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import {
  PATH_QUOTAS_ALC_PRELIMINAIRE,
  PATH_QUOTAS_ALC_VERIFIE,
  PATH_QUOTAS_ALLOCATIONS_EMAILS,
  PATH_QUOTAS_ALLOCATIONS_PMS,
} from "common/path/path20Now";

export const areAllAllocationsBlocsValidated = (
  declarationApi: DeclarationHelpers
): boolean =>
  declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_PMS) &&
  declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_EMAILS) &&
  declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS_ALC_PRELIMINAIRE) &&
  declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS_ALC_VERIFIE);
