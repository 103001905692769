import { ReferenceGazDto, ReferenceItemGazDto } from "api/gen/api";

export const AUTRE_UUID = "AUTRE_UUID";

export const AUTRE_GAZ_DTO: ReferenceItemGazDto = {
  uuid: AUTRE_UUID,
  nom: "Autre",
  hfc32: 0,
  hfc125: 0,
  hfc134a: 0,
  hfc143a: 0,
  hfc152a: 0,
  hfc227ea: 0,
  hfc1234yf: 0,
  hfc1233zd: 0,
  hfc23: 0,
  hfc41: 0,
  hfc4310mee: 0,
  hfc134: 0,
  hfc143: 0,
  hfc236fa: 0,
  hfc245ca: 0,
  hfc245fa: 0,
  hfc365mfc: 0,
  hfc1234ze: 0,
  r218: 0,
  r600a: 0,
  restorationCode: -1,
};

export const getPossibleGazList = (
  from: ReferenceGazDto
): ReferenceItemGazDto[] => {
  return [...from.gaz, AUTRE_GAZ_DTO];
};
