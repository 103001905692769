import { useRef } from "react";
import { FileDto } from "api/gen";
import _ from "lodash";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";

export const useDeclarationFileChange = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  declaration: VersionedDeclarationDto,
  path: string,
  onChange: () => void
) => {
  const files = _.get(declaration, path);
  const filesRef = useRef<FileDto[]>(files);

  if (!_.isEqual(files, filesRef.current)) {
    filesRef.current = files;
    onChange();
  }
};
