import React from "react";
import { makeStyles } from "@material-ui/styles";
import dangerIcon from "icons/danger-pink-gerep.svg";

const useStyles = makeStyles({
  picture: {
    height: "200px",
    paddingBottom: "40px",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontSize: "20px",
    height: "100%",
    "& p": {
      paddingBottom: "10px",
      "&:nth-child(2)": {
        fontWeight: "bold",
        paddingBottom: "40px",
      },
      "&:last-child": {
        paddingBottom: "40px",
      },
    },
  },
});

const NoDecleration = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classes.text}>
      <img src={dangerIcon} alt={"Attention"} className={classes.picture} />
      <p>
        La requête pour accéder à la déclaration de l'établissement n’a pas
        abouti. Il semble qu’aucune déclaration ne soit existante dans GEREP
        pour cet etablissement.
      </p>
      <p>
        Afin de générer une nouvelle déclaration sur l’application GEREP, pour
        les établissements suivis par les Dreal et les DDPP, la procédure est la
        suivante :
      </p>
      <p>
        1° Cocher la case “Déclaration annuelle GEREP” dans GUNenv Informations
        / Métier / Autosurveillance et déclaration
      </p>
      <p>
        2° Attribuer les droits sur l'établissement à un (ou des exploitant(s))
        au sein de MonAIOT.
      </p>
      <p>
        3° L’exploitant se connecte à Gerep via MonAIOT pour initialiser une
        première déclaration sur Gerep (sans connexion de sa part, aucune
        déclaration n’est initialisée)
      </p>
      <p>
        4° Une fois la la déclaration initialisée, l’inspecteur doit remplir et
        valider le pavé “Types d’activités” pour l'établissement.
      </p>
    </div>
  );
};

export default NoDecleration;
