import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { createProcedeDiv } from "../utils/utils";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { CorrelationEmissionInArray, EmissionsInArray } from "./utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface EmissionArrayProps {
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationEmissionPath: string;
}

const EmissionCorrelationArray = ({
  correlationEmissionsInPage,
  setCorrelationEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
}: EmissionArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInPage: CorrelationEmissionInArray) {
    setCorrelationEmissionsInPage(elderEmissions => {
      return elderEmissions.filter(elderEmissionInPage => {
        return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: CorrelationEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const emissionTitleString =
      emission.data.substance !== null ? emission.data.substance.label : "";

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      emissionTitleString,
      isValidated,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.procede !== null
          ? createProcedeDiv(emission.data.procede)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.quantiteMatiereProcede !== null
          ? computeNumberWithSeparator(emission.data.quantiteMatiereProcede)
          : "",
        classes
      ),
      computeWrappedCellWithError(emission.data.uniteProcede, classes),
      computeWrappedCellWithError(
        <p title={emission.data.natureCorrelation || undefined}>
          {emission.data.natureCorrelation}{" "}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.facteurCorrelation !== null
          ? computeNumberWithSeparator(emission.data.facteurCorrelation)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.uniteCorrelation !== null
          ? emission.data.uniteCorrelation.label
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.masseVolumiqueProcede !== null
          ? computeNumberWithSeparator(emission.data.masseVolumiqueProcede)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.provenanceFacteurCorrelation || undefined}>
          {emission.data.provenanceFacteurCorrelation}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.emissionAnnuelle !== null
          ? computeNumberWithSeparator(emission.data.emissionAnnuelle)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.biomasse !== null
          ? computeNumberWithSeparator(emission.data.biomasse)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.natureEquipement || undefined}>
          {emission.data.natureEquipement}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.rendementEpuration !== null
          ? computeNumberWithSeparator(emission.data.rendementEpuration)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        isValidated={isValidated}
        commentPath={`${validationEmissionPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = correlationEmissionsInPage
    .filter(correlation => {
      const methods = correlation.data.methods;
      return methods && methods.value === 2;
    })
    .map(correlation => {
      return computeSingleEmissionLine(correlation, classes);
    });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Procédé</p>,
          <p>Quantité de matière première ou volume d'activité</p>,
          <p>Unité</p>,
          <p>Nature de la corrélation</p>,
          <p>Facteur de la corrélation</p>,
          <p>Unité du facteur de corrélation</p>,
          <p>
            Masse volumique de la quantité de matière première ou
            production(kg/l)
          </p>,
          <p>Provenance du facteur de corrélation</p>,
          <p>Émissions annuelles (kg/an)</p>,
          <p>Fraction de la biomasse (%)</p>,
          <p>Les émissions font-elles l'objet d'épuration ?</p>,
          <p>Nature des équipements</p>,
          <p>Rendement de l'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          250,
          150,
          200,
          130,
          130,
          130,
          130,
          250,
          200,
          130,
          130,
          200,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionCorrelationArray;
