import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  tipCard: {
    gridColumn: "2/-1",
    margin: "auto",
  },
});

const SectionNextActionCard = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const classes = useStyles();

  return <p className={classes.tipCard}>{children}</p>;
};

export default SectionNextActionCard;
