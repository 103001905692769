import * as Yup from "yup";
import {
  codePostalMessage,
  hoursLengthMessage,
  siretLengthMessage,
  siretLuhnMessage,
  siretNumberMessage,
} from "common/declarant/formik/formikMessages";
import { ActivityType } from "../utils/types";
import {
  commonMixedFields,
  commonMixedFieldsNullable,
  commonNullableStringFields,
  commonNumberFieldsNullable,
  commonPositiveIntegerNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { checkLuhnAlgorithm } from "pages/CompanySpace/from20/to20/GeneralInformations/utils/luhnUtils";

const checkSIRETNumber = commonNullableStringFields
  .length(14, siretLengthMessage)
  .matches(/^[0-9]*$/, siretNumberMessage)
  .test(
    "SIRET must be undefined or pass the Luhn check",
    siretLuhnMessage,
    value => {
      if (value !== undefined && value !== null) {
        return checkLuhnAlgorithm(value);
      }
      return value === undefined;
    }
  );

export const companyActivityValidationSchema: Yup.SchemaOf<Partial<
  ActivityType
>> = Yup.object().shape({
  codeInspection: commonStringFields,
  identifiantService: commonNullableStringFields,
  nomEtablissement: commonStringFields,
  installationAdresse: commonStringFields,
  installationCodePostal: commonStringFields.length(5, codePostalMessage),
  installationCity: commonMixedFields(),
  siret: checkSIRETNumber,
  codeNaf: commonMixedFieldsNullable(),
  systemeCoordonnees: commonMixedFields(),
  abscisse: commonStringFields,
  ordonnee: commonStringFields,
  volumeProduction: commonNumberFieldsNullable,
  unite: commonMixedFieldsNullable(),
  matiereProduite: commonNullableStringFields,
  autreMatiereProduitePrecision: commonNullableStringFields,
  nombreInstallations: commonPositiveIntegerNumberFieldsNullable,
  website: commonNullableStringFields,
  nombreHeureDeFonctionnement: commonPositiveIntegerNumberFieldsNullable.max(
    8784,
    hoursLengthMessage
  ),
  employe: commonPositiveIntegerNumberFieldsNullable,
  informationsComplementaires: commonNullableStringFields,
});

export const displayMandatory = (isFieldMandatory: boolean): string => {
  return isFieldMandatory ? " *" : "";
};

export const isDeclarationLinkedToGun = (
  codeEtablissement: string
): boolean => {
  const notLinkedDeclarationCodeFormat = /^00090[0-9]{5}$/;
  return !notLinkedDeclarationCodeFormat.test(codeEtablissement);
};
