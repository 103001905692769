import { AccidentDto, DescriptionAccidentDto } from "api/gen";
import { Nullable } from "common/utils/types";
import {
  AccidentCauseType,
  AccidentInArray,
  AccidentsTravailData,
} from "./utils";
import {
  convertDeclarationDescriptionAccidentDtoToPopulatedAccident,
  convertPopulatedAccidentToDeclarationDescriptionAccidentDto,
} from "./AccidentArray/converters";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const computeInitialFormValues = (
  declarationData: Declaration1919
): Nullable<AccidentsTravailData> => {
  return {
    bigAccident:
      declarationData.body.sections.carriere.sante.accident
        .accidentAvecArretTravail,
  };
};

export const computeInitialArrayValues = (
  declarationData: Declaration1919,
  causesList: AccidentCauseType[]
): AccidentInArray[] => {
  return declarationData.body.sections.carriere.sante.accident.accidents.map(
    (singleAccidentDto: DescriptionAccidentDto) =>
      convertDeclarationDescriptionAccidentDtoToPopulatedAccident(
        singleAccidentDto,
        causesList
      )
  );
};

export const computeAccidentDtoWithArrayAccidentAndAccidentValues = (
  arrayAccidents: AccidentInArray[],
  formValues: Nullable<AccidentsTravailData>
): AccidentDto => {
  const isArrayDisplayed = !!formValues.bigAccident;
  if (!isArrayDisplayed) {
    return { accidents: [], accidentAvecArretTravail: false };
  }
  return {
    accidents: arrayAccidents.map(singlePopulatedArrayAccident =>
      convertPopulatedAccidentToDeclarationDescriptionAccidentDto(
        singlePopulatedArrayAccident
      )
    ),
    accidentAvecArretTravail: true,
  };
};
