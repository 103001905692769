import React from "react";
import { BilanTotalEmissionDto, QuotasInstallationDto20Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import SubSectionTitle from "../../../../common/TitleComponents/SubSectionTitle";
import BilanNonBiomasseArray from "./BilanNonBiomasseArray";
import BilanBiomasseForm from "./BilanBiomasseForm";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
});

interface AerBilanTotalProps {
  installation: QuotasInstallationDto20Now;
  bilanTotalEmission: BilanTotalEmissionDto;
}

const AerBilanTotal = ({
  installation,
  bilanTotalEmission,
}: AerBilanTotalProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubSectionTitle>BILAN TOTAL (NON BIOMASSE)</SubSectionTitle>

      <BilanNonBiomasseArray
        installation={installation}
        bilanNonBiomasseDto={bilanTotalEmission}
      />
      <BilanBiomasseForm bilanBiomasse={bilanTotalEmission} />
    </div>
  );
};

export default AerBilanTotal;
