import React from "react";
import Row from "common/presentational/Row";
import BlocAlcDescription from "./BlocAlcDescription";
import QuotasAlcVerifieForm from "./AlcVerifie/QuotasAlcVerifieForm";

const BlocAlc = (): React.ReactElement => {
  return (
    <>
      <BlocAlcDescription />

      <Row height={"20px"} />

      <QuotasAlcVerifieForm />
    </>
  );
};

export default BlocAlc;
