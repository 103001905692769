import { Nullable } from "common/utils/types";
import { EmpoussierageDto, ReferenceItemOrganismeAccrediteDto } from "api/gen";
import _ from "lodash";

import { findElementMatchingTemplate } from "common/utils/methods";
import {
  MesureEmpoussierageData,
  otherOrganismAccreditedOption,
  wasOtherOrganismSelected,
} from "./utils";

const getOrganismeAccrediteNameById = (
  organismeAccreditesReferentiel: ReferenceItemOrganismeAccrediteDto[],
  organismeAccrediteId: string
) => {
  const organismeAccredite = findElementMatchingTemplate(
    { uuid: organismeAccrediteId },
    organismeAccreditesReferentiel
  );
  return organismeAccredite ? organismeAccredite.libelle : undefined;
};

const getOrganismeAccrediteIdByName = (
  organismeAccreditesReferentiel: ReferenceItemOrganismeAccrediteDto[],
  organismeAccrediteName: string
) => {
  const organismeAccredite = findElementMatchingTemplate(
    { libelle: organismeAccrediteName },
    organismeAccreditesReferentiel
  );
  return organismeAccredite ? organismeAccredite.uuid : null;
};

export const overrideUncoherentValuesMesureEmpoussierage = (
  currentValues: Nullable<MesureEmpoussierageData>
): Nullable<MesureEmpoussierageData> => {
  const valuesToReturn = _.cloneDeep(currentValues);
  if (!valuesToReturn.wasRiskEvaluationRealised) {
    valuesToReturn.poussiereAlveolaireQuartzTridimite = null;
    valuesToReturn.poussiereAlveolaireTotale = null;
    valuesToReturn.nbGEH = null;
  } else if (
    valuesToReturn.poussiereAlveolaireQuartzTridimite &&
    !wasOtherOrganismSelected(
      valuesToReturn.poussiereAlveolaireQuartzTridimite
        .accreditedOrganismIntervening || null
    )
  ) {
    valuesToReturn.poussiereAlveolaireQuartzTridimite.precision = null;
  } else if (
    valuesToReturn.poussiereAlveolaireTotale &&
    !wasOtherOrganismSelected(
      valuesToReturn.poussiereAlveolaireTotale.accreditedOrganismIntervening ||
        null
    )
  ) {
    valuesToReturn.poussiereAlveolaireTotale.precision = null;
  }

  return valuesToReturn;
};

export const convertMesureEmpoussierageDataToDeclarationEmpoussierageDto = (
  values: Nullable<MesureEmpoussierageData>,
  referenceItemOrganismeAccrediteDtoList: ReferenceItemOrganismeAccrediteDto[]
): EmpoussierageDto => {
  return {
    mesureEffectuee: !!values.wasRiskEvaluationRealised,
    nbreGEH: values.nbGEH,
    tableauEmpoussierageAlveolaireQuartz: values.poussiereAlveolaireQuartzTridimite
      ? {
          evaluationReveleRisqueNonFaible:
            values.poussiereAlveolaireQuartzTridimite.hasShownNotWeakRiskOnly,
          nbGEHMoinsDe10PourcentVLEP:
            values.poussiereAlveolaireQuartzTridimite
              .nbGEHLessThan10PercentVLEP,
          nbTotalPrelevement:
            values.poussiereAlveolaireQuartzTridimite.nbTotalPrelevement,

          organismeAccrediteID: values.poussiereAlveolaireQuartzTridimite
            .accreditedOrganismIntervening
            ? getOrganismeAccrediteIdByName(
                referenceItemOrganismeAccrediteDtoList,
                values.poussiereAlveolaireQuartzTridimite
                  .accreditedOrganismIntervening.libelle
              )
            : null,
          precisionSiAutre: values.poussiereAlveolaireQuartzTridimite.precision,
          autreOrganisme: values.poussiereAlveolaireQuartzTridimite
            .accreditedOrganismIntervening
            ? wasOtherOrganismSelected(
                values.poussiereAlveolaireQuartzTridimite
                  .accreditedOrganismIntervening
              )
            : false,
        }
      : {
          evaluationReveleRisqueNonFaible: false,
          nbGEHMoinsDe10PourcentVLEP: null,
          nbTotalPrelevement: null,
          organismeAccrediteID: null,
          precisionSiAutre: null,
          autreOrganisme: false,
        },
    tableauEmpoussierageAlveolaireTotal: values.poussiereAlveolaireTotale
      ? {
          evaluationReveleRisqueNonFaible:
            values.poussiereAlveolaireTotale.hasShownNotWeakRiskOnly,
          valeurMaximale: values.poussiereAlveolaireTotale.maximalValue,
          valeurMinimale: values.poussiereAlveolaireTotale.minimalValue,
          nbTotalPrelevement:
            values.poussiereAlveolaireTotale.nbTotalPrelevement,
          organismeAccrediteID: values.poussiereAlveolaireTotale
            .accreditedOrganismIntervening
            ? getOrganismeAccrediteIdByName(
                referenceItemOrganismeAccrediteDtoList,
                values.poussiereAlveolaireTotale.accreditedOrganismIntervening
                  .libelle
              )
            : null,
          precisionSiAutre: values.poussiereAlveolaireTotale.precision,
          nbreGEHsupVLEP: values.poussiereAlveolaireTotale.nbGEHBiggerThanVLEP,
          autreOrganisme: values.poussiereAlveolaireTotale
            .accreditedOrganismIntervening
            ? wasOtherOrganismSelected(
                values.poussiereAlveolaireTotale.accreditedOrganismIntervening
              )
            : false,
        }
      : {
          evaluationReveleRisqueNonFaible: false,
          nbreGEHsupVLEP: null,
          nbTotalPrelevement: null,
          organismeAccrediteID: null,
          precisionSiAutre: null,
          valeurMaximale: null,
          valeurMinimale: null,
          autreOrganisme: false,
        },
  };
};

export const convertDeclarationEmpoussierageDtoToMesureEmpoussierageData = (
  declarationData: EmpoussierageDto,
  referenceItemOrganismeAccrediteDtoList: ReferenceItemOrganismeAccrediteDto[]
): Nullable<MesureEmpoussierageData> => {
  const organismeAccrediteQuartzName = getOrganismeAccrediteNameById(
    referenceItemOrganismeAccrediteDtoList,
    declarationData.tableauEmpoussierageAlveolaireQuartz.organismeAccrediteID ||
      ""
  );
  const organismeAccrediteTotalName = getOrganismeAccrediteNameById(
    referenceItemOrganismeAccrediteDtoList,
    declarationData.tableauEmpoussierageAlveolaireTotal.organismeAccrediteID ||
      ""
  );
  return {
    nbGEH: declarationData.nbreGEH,
    poussiereAlveolaireQuartzTridimite: declarationData.tableauEmpoussierageAlveolaireQuartz && {
      hasShownNotWeakRiskOnly:
        declarationData.tableauEmpoussierageAlveolaireQuartz
          .evaluationReveleRisqueNonFaible,
      accreditedOrganismIntervening: declarationData
        .tableauEmpoussierageAlveolaireQuartz.autreOrganisme
        ? otherOrganismAccreditedOption
        : findElementMatchingTemplate(
            { libelle: organismeAccrediteQuartzName },
            referenceItemOrganismeAccrediteDtoList
          ),
      precision:
        declarationData.tableauEmpoussierageAlveolaireQuartz.precisionSiAutre,
      nbTotalPrelevement:
        declarationData.tableauEmpoussierageAlveolaireQuartz.nbTotalPrelevement,
      nbGEHLessThan10PercentVLEP:
        declarationData.tableauEmpoussierageAlveolaireQuartz
          .nbGEHMoinsDe10PourcentVLEP,
    },
    poussiereAlveolaireTotale: declarationData.tableauEmpoussierageAlveolaireTotal && {
      hasShownNotWeakRiskOnly:
        declarationData.tableauEmpoussierageAlveolaireTotal
          .evaluationReveleRisqueNonFaible,
      accreditedOrganismIntervening: declarationData
        .tableauEmpoussierageAlveolaireTotal.autreOrganisme
        ? otherOrganismAccreditedOption
        : findElementMatchingTemplate(
            { libelle: organismeAccrediteTotalName },
            referenceItemOrganismeAccrediteDtoList
          ),
      precision:
        declarationData.tableauEmpoussierageAlveolaireTotal.precisionSiAutre,
      nbTotalPrelevement:
        declarationData.tableauEmpoussierageAlveolaireTotal.nbTotalPrelevement,
      minimalValue:
        declarationData.tableauEmpoussierageAlveolaireTotal.valeurMinimale,
      maximalValue:
        declarationData.tableauEmpoussierageAlveolaireTotal.valeurMaximale,
      nbGEHBiggerThanVLEP:
        declarationData.tableauEmpoussierageAlveolaireTotal.nbreGEHsupVLEP,
    },
    wasRiskEvaluationRealised: declarationData.mesureEffectuee,
  };
};
