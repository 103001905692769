import React from "react";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { AreasForm } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  computeTotal,
  computeTotalGround,
  computeTotalGroundPreviousYear,
  computeTotalPreviousYear,
  computeTotalWater,
  computeTotalWaterPreviousYear,
} from "./utils/computers";
import Row from "common/presentational/Row";
import { DISABLED_NUMBER_INPUT_TEXT } from "theme";
import { convertDeclarationDtoUsingAreasForm } from "../utils/converters";
import { convertDtoToAreasForm } from "./utils/converters";
import { preventSubmitWithErrors, validationSchema } from "./utils/validation";
import {
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_AUTRE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECOLOGIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECONOMIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_REMISE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AGRICOLE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AUTRE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECOLOGIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECONOMIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_FORET,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_REMISE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_AUTRE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECOLOGIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECONOMIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_REMISE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AGRICOLE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AUTRE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECOLOGIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECONOMIQUE,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_FORET,
  PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_REMISE,
} from "common/path/path18Now";
import InfoBulle from "common/infoBulle/InfoBulle";
import classNames from "classnames";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

interface BlocSuperficiesProps {
  areasFormProps: FormikBlocRefAndState<AreasForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "0",
    display: "flex",
  },
  header: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    fontWeight: "bold",
    fontSize: "120%",
    width: "42.5%",
    textAlign: "center",
  },
  twoCols: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    width: "42.5%",
    textAlign: "center",
  },
  column: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    fontWeight: "bold",
    fontSize: "90%",
    width: "21.25%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  firstCol: {
    width: "15%",
  },
  center: {
    alignItems: "center",
    height: "auto",
  },
  marginBottom: { marginBottom: "5px" },
  titles: {
    margin: "10px 0",
  },
});

const BlocSuperficies = ({
  areasFormProps,
}: BlocSuperficiesProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "0",
    formPrefix: "environnement-bloc-superficies",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const Checkbox = useBooleanCheckBoxGenerator({
    ...commonProps,
    labelWidth: "50%",
  });

  const initialValues = convertDtoToAreasForm(declaration);

  return (
    <FormikBlocFullContext1919
      hasChanges={areasFormProps.hasChanges}
      setHasChanges={areasFormProps.setHasChanges}
      formikRef={areasFormProps.formikRef}
      title="SUPERFICIES REMISES EN ÉTAT (TE1)"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE}
      updateHandler={convertDeclarationDtoUsingAreasForm}
      preventSubmitWithErrors={preventSubmitWithErrors}
      renderContent={({ values }, shouldDisabledFields: boolean) => {
        const shouldDisplayLineRemiseEnEtat =
          (values.otherGroundPreviousYear !== null &&
            values.otherGroundPreviousYear > 0) ||
          (values.otherWaterPreviousYear !== null &&
            values.otherWaterPreviousYear > 0) ||
          (values.otherGround !== null && values.otherGround > 0) ||
          (values.otherWater !== null && values.otherWater > 0);
        return (
          <>
            <Checkbox
              name="isRestoredThisYear"
              label="Superficies remises en état dans l'année écoulée"
              disabled={shouldDisabledFields}
              commentPath={PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT}
            />

            {values.isRestoredThisYear && (
              <>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol} />
                  <div className={classes.header}>Cumul années précédentes</div>
                  <div className={classes.header}>Année N-1</div>
                </Row>
                <Row
                  additionalClassname={classNames(
                    classes.center,
                    classes.titles
                  )}
                >
                  <div className={classes.firstCol} />
                  <div className={classes.column}>Superficie en terre</div>
                  <div className={classes.column}>Superficie en eau</div>
                  <div className={classes.column}>Superficie en terre</div>
                  <div className={classes.column}>Superficie en eau</div>
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Zone agricole</div>
                  <div className={classes.column}>
                    <NumberField
                      name="agriculturalZonePreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AGRICOLE
                      }
                    />
                  </div>
                  <div className={classes.column} />
                  <div className={classes.column}>
                    <NumberField
                      name="agriculturalZone"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AGRICOLE
                      }
                    />
                  </div>
                  <div className={classes.column} />
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Zone forestière</div>
                  <div className={classes.column}>
                    <NumberField
                      name="forestryZonePreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_FORET
                      }
                    />
                  </div>
                  <div className={classes.column} />
                  <div className={classes.column}>
                    <NumberField
                      name="forestryZone"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_FORET
                      }
                    />
                  </div>
                  <div className={classes.column} />
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Zone économique</div>
                  <div className={classes.column}>
                    <>
                      <NumberField
                        name="economicZoneGroundPreviousYear"
                        label=""
                        unit="ha"
                        disabled={shouldDisabledFields}
                        commentPath={
                          PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECONOMIQUE
                        }
                      />
                      <InfoBulle
                        content={
                          "Espace à vocation économique : base de loisir, parc d'activité"
                        }
                        additionalClassName={classes.marginBottom}
                      />
                    </>
                  </div>
                  <div className={classes.column}>
                    <>
                      <NumberField
                        name="economicZoneWaterPreviousYear"
                        label=""
                        unit="ha"
                        disabled={shouldDisabledFields}
                        commentPath={
                          PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECONOMIQUE
                        }
                      />
                      <InfoBulle
                        content={
                          "Espace à vocation économique : Base nautique, zone aquacole, zone halieutique"
                        }
                        additionalClassName={classes.marginBottom}
                      />
                    </>
                  </div>
                  <div className={classes.column}>
                    <>
                      <NumberField
                        name="economicZoneGround"
                        label=""
                        unit="ha"
                        disabled={shouldDisabledFields}
                        commentPath={
                          PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECONOMIQUE
                        }
                      />
                      <InfoBulle
                        content={
                          "Espace à vocation économique : base de loisir, parc d'activité"
                        }
                        additionalClassName={classes.marginBottom}
                      />
                    </>
                  </div>
                  <div className={classes.column}>
                    <>
                      <NumberField
                        name="economicZoneWater"
                        label=""
                        unit="ha"
                        disabled={shouldDisabledFields}
                        commentPath={
                          PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECONOMIQUE
                        }
                      />
                      <InfoBulle
                        content={
                          "Espace à vocation économique : Base nautique, zone aquacole, zone halieutique"
                        }
                        additionalClassName={classes.marginBottom}
                      />
                    </>
                  </div>
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>
                    Zone intérêt écologique
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="ecologicalZoneGroundPreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_ECOLOGIQUE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="ecologicalZoneWaterPreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_ECOLOGIQUE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="ecologicalZoneGround"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_ECOLOGIQUE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="ecologicalZoneWater"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_ECOLOGIQUE
                      }
                    />
                  </div>
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Autre</div>
                  <div className={classes.column}>
                    <NumberField
                      name="otherGroundPreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_AUTRE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="otherWaterPreviousYear"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_AUTRE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="otherGround"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_AUTRE
                      }
                    />
                  </div>
                  <div className={classes.column}>
                    <NumberField
                      name="otherWater"
                      label=""
                      unit="ha"
                      disabled={shouldDisabledFields}
                      commentPath={
                        PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_AUTRE
                      }
                    />
                  </div>
                </Row>
                {shouldDisplayLineRemiseEnEtat && (
                  <Row additionalClassname={classes.center}>
                    <div className={classes.firstCol}>
                      Type de remise en état *
                    </div>
                    <div className={classes.column}>
                      {values.otherGroundPreviousYear !== null &&
                      values.otherGroundPreviousYear > 0 ? (
                        <>
                          <TextField
                            name="repairTypeGroundPreviousYear"
                            label=""
                            disabled={shouldDisabledFields}
                            commentPath={
                              PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_TERRE_REMISE
                            }
                          />
                          <InfoBulle
                            content={
                              "Par exemple, espace destiné à être construit"
                            }
                            additionalClassName={classes.marginBottom}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.column}>
                      {values.otherWaterPreviousYear !== null &&
                      values.otherWaterPreviousYear > 0 ? (
                        <>
                          <TextField
                            name="repairTypeWaterPreviousYear"
                            label=""
                            disabled={shouldDisabledFields}
                            commentPath={
                              PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_PRECEDENTE_EAU_REMISE
                            }
                          />
                          <InfoBulle
                            content={
                              "Par exemple, espace destiné à être construit"
                            }
                            additionalClassName={classes.marginBottom}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.column}>
                      {values.otherGround !== null && values.otherGround > 0 ? (
                        <>
                          <TextField
                            name="repairTypeGround"
                            label=""
                            disabled={shouldDisabledFields}
                            commentPath={
                              PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_TERRE_REMISE
                            }
                          />
                          <InfoBulle
                            content={
                              "Par exemple, espace destiné à être construit"
                            }
                            additionalClassName={classes.marginBottom}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={classes.column}>
                      {values.otherWater !== null && values.otherWater > 0 ? (
                        <>
                          <TextField
                            name="repairTypeWater"
                            label=""
                            disabled={shouldDisabledFields}
                            commentPath={
                              PATH_CARRIERE_ENVIRONNEMENT_SUPERFICIE_REMIS_EN_ETAT_ANNEE_ECOULEE_EAU_REMISE
                            }
                          />
                          <InfoBulle
                            content={
                              "Par exemple, espace destiné à être construit"
                            }
                            additionalClassName={classes.marginBottom}
                          />
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                )}
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Total</div>
                  <div className={classes.column}>
                    <DummyNumberField
                      label=""
                      name="totalGroundPreviousYear"
                      unit="ha"
                      disabled
                      value={computeTotalGroundPreviousYear(values)}
                    />
                  </div>
                  <div className={classes.column}>
                    <DummyNumberField
                      label=""
                      name="totalWaterPreviousYear"
                      unit="ha"
                      disabled
                      value={computeTotalWaterPreviousYear(values)}
                    />
                  </div>
                  <div className={classes.column}>
                    <DummyNumberField
                      label=""
                      name="totalGround"
                      unit="ha"
                      disabled
                      value={computeTotalGround(values)}
                    />
                  </div>
                  <div className={classes.column}>
                    <DummyNumberField
                      label=""
                      name="totalWater"
                      unit="ha"
                      disabled
                      value={computeTotalWater(values)}
                    />
                  </div>
                </Row>
                <Row additionalClassname={classes.center}>
                  <div className={classes.firstCol}>Total général</div>
                  <div className={classes.twoCols}>
                    <DummyNumberField
                      label=""
                      name="totalPreviousYear"
                      unit="ha"
                      disabled
                      value={computeTotalPreviousYear(values)}
                    />
                  </div>
                  <div className={classes.twoCols}>
                    <DummyNumberField
                      label=""
                      name="total"
                      unit="ha"
                      disabled
                      value={computeTotal(values)}
                    />
                  </div>
                </Row>
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default BlocSuperficies;
