import {
  AuthUserInfoDto,
  AuthDroitDto,
  AuthDroitDtoAuthProfileEnum,
  LecteurDroitDtoAllowedSectionsEnum,
  PrestataireDroitAddModelPrestataireDroitDtoEnum,
} from "api/gen";
import { createContext, useContext } from "react";
import sectionTitle from "../common/presentational/SectionTitle";

export interface UserData {
  userInfo: AuthUserInfoDto;
  userRights: AuthDroitDto[];
  // shortcut to get user access right
  isDeclarant: boolean;
  isAnyPrestataire: boolean;
  isPrestataireEmissionsForEtablissement: (
    codeEtablissement: string
  ) => boolean;
  isPrestataireNiveauxActiviteForEtablissement: (
    codeEtablissement: string
  ) => boolean;
  isInspecteur: boolean;
  isAdmin: boolean;
  isGerepAdmin: boolean;
  isAdminBQA: boolean;
  isSuperAdmin: boolean;
  isLecteur: boolean;
  isLecteurOnSection: (section: LecteurDroitDtoAllowedSectionsEnum) => boolean;
  isLecteurOnlyOnSection: (
    section: LecteurDroitDtoAllowedSectionsEnum
  ) => boolean;
}

interface UserDataAndAction extends UserData {
  setActiveRight: (right: AuthDroitDto) => void;
  logout: () => void;
}

export const UserContext = createContext<UserDataAndAction | null>(null);

export const useUserData = (): UserDataAndAction => {
  const context = useContext(UserContext);
  if (context === null) {
    throw new Error("Expected this hook to be called inside an Api context");
  }
  return context;
};

export const buildUserData = (
  userInfo: AuthUserInfoDto,
  userRights: AuthDroitDto[]
): UserData => {
  if (userInfo.droits.length === 0) {
    throw new Error("User account with no right should not be possible");
  }
  const authDroit = userInfo.droits[0];
  const { authProfile } = authDroit;

  const isDeclarant = authProfile === AuthDroitDtoAuthProfileEnum.EXPLOITANT;
  const isAnyPrestataire =
    authProfile === AuthDroitDtoAuthProfileEnum.PRESTATAIRE;
  const isSuperAdmin = authProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN;
  const isAdminBQA =
    authProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA;
  const isGerepAdmin =
    authProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR;
  const isAdmin = isSuperAdmin || isGerepAdmin || isAdminBQA;
  const isInspecteur =
    isAdmin || authProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE;
  const isLecteur = authProfile === AuthDroitDtoAuthProfileEnum.LECTEUR;
  const lecteurRights = authDroit.lecteurRights
    ? authDroit.lecteurRights.allowedSections
    : [];
  if (
    !(
      isDeclarant ||
      isAnyPrestataire ||
      isInspecteur ||
      isAdmin ||
      isSuperAdmin ||
      isLecteur
    )
  ) {
    throw new Error("User's profile is not recognized");
  }

  const isPrestataireEmissionsForEtablissement = (
    codeEtablissement: string
  ) => {
    if (!isAnyPrestataire) {
      return false;
    }
    const prestataireDroit = findKeyByValue(
      authDroit.codeEtablissementPrestataireDroitMap,
      codeEtablissement
    );
    return !!(
      prestataireDroit ===
        PrestataireDroitAddModelPrestataireDroitDtoEnum.PRESTATAIRE_DROIT_E ||
      prestataireDroit ===
        PrestataireDroitAddModelPrestataireDroitDtoEnum.PRESTATAIRE_DROIT_EN
    );
  };
  const isPrestataireNiveauxActiviteForEtablissement = (
    codeEtablissement: string
  ) => {
    if (!isAnyPrestataire) {
      return false;
    }
    const prestataireDroit = findKeyByValue(
      authDroit.codeEtablissementPrestataireDroitMap,
      codeEtablissement
    );
    return !!(
      prestataireDroit ===
        PrestataireDroitAddModelPrestataireDroitDtoEnum.PRESTATAIRE_DROIT_N ||
      prestataireDroit ===
        PrestataireDroitAddModelPrestataireDroitDtoEnum.PRESTATAIRE_DROIT_EN
    );
  };

  const findKeyByValue = (
    map: { [key: string]: Array<string> } | null,
    value: string
  ): string | null => {
    if (!map) {
      return null;
    }

    return (
      Object.entries(map).find(([key, values]) =>
        values.includes(value)
      )?.[0] || null
    );
  };

  const isLecteurOnSection = (section: LecteurDroitDtoAllowedSectionsEnum) => {
    return isLecteur && lecteurRights.includes(section);
  };

  const isLecteurOnlyOnSection = (
    section: LecteurDroitDtoAllowedSectionsEnum
  ) => {
    return isLecteurOnSection(section) && lecteurRights.length === 1;
  };
  return {
    userInfo,
    userRights,
    isDeclarant,
    isAnyPrestataire,
    isPrestataireEmissionsForEtablissement,
    isPrestataireNiveauxActiviteForEtablissement,
    isInspecteur,
    isGerepAdmin: isGerepAdmin,
    isAdminBQA: isAdminBQA,
    isAdmin: isAdmin,
    isSuperAdmin,
    isLecteur,
    isLecteurOnSection,
    isLecteurOnlyOnSection,
  };
};
