import {
  PATH_AIR,
  PATH_AIR_COMBUSTION,
  PATH_AIR_ELEVAGE,
  PATH_AIR_GAZ,
  PATH_AIR_ISDND,
  PATH_AIR_PROCEDES,
  PATH_AIR_SOLVANTS,
  PATH_AIR_SYNTHESE,
  PATH_CARRIERE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_DECHET,
  PATH_EAU,
  PATH_INFO_GENERALE,
  PATH_QUOTAS,
  PATH_SOL,
  PATH_SPFO,
} from "common/path/path18Now";
import {
  isAirDisabled,
  isCarriereDisabled,
  isEveryAirValidated,
  isEveryCarriereValidated,
} from "./utils";
import BigCard from "./cards/BigCard";
import React from "react";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import idCardImg from "icons/dashboardLogos/id-card.svg";
import garbageImg from "icons/dashboardLogos/garbage.svg";
import waterImg from "icons/dashboardLogos/water.svg";
import shovelImg from "icons/dashboardLogos/shovel.svg";
import windImg from "icons/dashboardLogos/wind.svg";
import fireImg from "icons/dashboardLogos/fire.svg";
import chemicalImg from "icons/dashboardLogos/chemical.svg";
import flaskImg from "icons/dashboardLogos/flask.svg";
import biogasPlantImg from "icons/dashboardLogos/biogas-plant.svg";
import pigImg from "icons/dashboardLogos/pig.svg";
import listImg from "icons/dashboardLogos/list.svg";
import gasFluoreImg from "icons/dashboardLogos/gas-fluore.svg";
import helmetImg from "icons/dashboardLogos/helmet.svg";
import growthImg from "icons/dashboardLogos/growth.svg";
import deliveryTruckImg from "icons/dashboardLogos/delivery-truck.svg";
import insuranceImg from "icons/dashboardLogos/insurance.svg";
import moleculeImg from "icons/dashboardLogos/molecule.svg";
import { makeStyles } from "@material-ui/styles";
import { FOUR_COLUMNS_CARD_GRID } from "theme";
import co2GasImg from "icons/dashboardLogos/co2-gas.png";
import { useQuotaState1919 } from "../../versionedElements/declarationHooks1919";

interface DashboardCardsProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
}

const useStyles = makeStyles({
  mediaQueries: FOUR_COLUMNS_CARD_GRID,
});

const AllDashboardCards = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: DashboardCardsProps): React.ReactElement => {
  const classes = useStyles();

  const quotasState = useQuotaState1919();
  return (
    <>
      <BigCard
        picture={{ src: idCardImg, alt: "" }}
        text="Informations générales"
        percentageProgress={declarationApi.getPathProgress(PATH_INFO_GENERALE)}
        isValidated={declarationApi.isPathValidatedInDeclaration(
          PATH_INFO_GENERALE
        )}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_INFO_GENERALE}`}
        isDisabled={!declarationApi.getIsActiveSection(PATH_INFO_GENERALE)}
      />
      <BigCard
        picture={{
          src: garbageImg,
          alt: "",
        }}
        text="Déchets"
        percentageProgress={declarationApi.getPathProgress(PATH_DECHET)}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_DECHET}`}
        isDisabled={!declarationApi.getIsActiveSection(PATH_DECHET)}
        isValidated={declarationApi.isPathValidatedInDeclaration(PATH_DECHET)}
      />
      <BigCard
        picture={{ src: waterImg, alt: "" }}
        text="Eau"
        percentageProgress={declarationApi.getPathProgress(PATH_EAU)}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_EAU}`}
        isDisabled={!declarationApi.getIsActiveSection(PATH_EAU)}
        isValidated={declarationApi.isPathValidatedInDeclaration(PATH_EAU)}
      />
      <BigCard
        picture={{ src: shovelImg, alt: "" }}
        text="Sol"
        percentageProgress={declarationApi.getPathProgress(PATH_SOL)}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_SOL}`}
        isDisabled={!declarationApi.getIsActiveSection(PATH_SOL)}
        isValidated={declarationApi.isPathValidatedInDeclaration(PATH_SOL)}
        disabledMessage={
          "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales"
        }
      />
      <BigCard
        isClickable={false}
        additionalClassname={classes.mediaQueries}
        picture={{ src: windImg, alt: "" }}
        text="Air"
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_COMBUSTION}`}
        isDisabled={isAirDisabled(declarationApi)}
        percentageProgress={declarationApi.getPathProgress(PATH_AIR)}
        isValidated={isEveryAirValidated(declarationApi)}
        subCards={[
          {
            text: "Combustion / Incinération",
            picture: { src: fireImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(
              PATH_AIR_COMBUSTION
            ),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_COMBUSTION}`,
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_COMBUSTION),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_COMBUSTION
            ),
            disabledMessage:
              "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales",
          },
          {
            text: "Procédés / Émissions diffuses",
            picture: { src: chemicalImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(
              PATH_AIR_PROCEDES
            ),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_PROCEDES}`,
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_PROCEDES),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_PROCEDES
            ),
            disabledMessage:
              "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales",
          },
          {
            text: "Solvants / PGS",
            picture: { src: flaskImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(
              PATH_AIR_SOLVANTS
            ),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_SOLVANTS}`,
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_SOLVANTS),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_SOLVANTS
            ),
            disabledMessage:
              "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales",
          },
          {
            text: "ISDND",
            picture: { src: biogasPlantImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(PATH_AIR_ISDND),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_ISDND}`,
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_ISDND),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_ISDND
            ),
            disabledMessage:
              "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales",
          },
          {
            text: "Élevage",
            picture: { src: pigImg, alt: "" },
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_ELEVAGE}`,
            percentageProgress: declarationApi.getPathProgress(
              PATH_AIR_ELEVAGE
            ),
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_ELEVAGE),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_ELEVAGE
            ),
            disabledMessage:
              "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales",
          },
          {
            text: "Synthèse",
            picture: { src: listImg, alt: "" },
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_SYNTHESE}`,
            percentageProgress: declarationApi.getPathProgress(
              PATH_AIR_SYNTHESE
            ),
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_SYNTHESE),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_SYNTHESE
            ),
            disabledMessage:
              "Cette page est active si vous avez déclaré au moins une substance dans l'air et validé tous les blocs du pavé Air",
          },
          {
            text: "Gaz fluorés",
            picture: {
              src: gasFluoreImg,
              alt: "",
            },
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_GAZ}`,
            percentageProgress: declarationApi.getPathProgress(PATH_AIR_GAZ),
            isDisabled: !declarationApi.getIsActiveSection(PATH_AIR_GAZ),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_AIR_GAZ
            ),
            disabledMessage:
              "Cette page est active si vous avez déclaré au moins une émission de HFC ou de PFC dans l'air et validé le bloc Synthèse",
          },
        ]}
      />
      <BigCard
        picture={{ src: co2GasImg, alt: "" }}
        text="Quotas"
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS}`}
        percentageProgress={declarationApi.getPathProgress(PATH_QUOTAS)}
        isDisabled={!declarationApi.getIsActiveSection(PATH_QUOTAS)}
        isValidated={declarationApi.isPathValidatedInDeclaration(PATH_QUOTAS)}
        note={
          declarationApi.getIsActiveSection("/quotas")
            ? quotasState.stateMessage
            : undefined
        }
        disabledMessage={
          "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales"
        }
      />
      <BigCard
        isClickable={false}
        picture={{ src: helmetImg, alt: "" }}
        text="Carrière"
        percentageProgress={declarationApi.getPathProgress(PATH_CARRIERE)}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`}
        isDisabled={isCarriereDisabled(declarationApi)}
        isValidated={isEveryCarriereValidated(declarationApi)}
        disabledMessage={
          "L'activation de cette page dépend de la section « Type d'activités » de la page Informations Générales"
        }
        subCards={[
          {
            text: "Environnement",
            picture: { src: growthImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(
              PATH_CARRIERE_ENVIRONNEMENT
            ),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`,
            isDisabled: !declarationApi.getIsActiveSection(
              PATH_CARRIERE_ENVIRONNEMENT
            ),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_CARRIERE_ENVIRONNEMENT
            ),
          },
          {
            text: "Production",
            picture: { src: deliveryTruckImg, alt: "" },
            percentageProgress: declarationApi.getPathProgress(
              PATH_CARRIERE_PRODUCTION
            ),
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_PRODUCTION}`,
            isDisabled: !declarationApi.getIsActiveSection(
              PATH_CARRIERE_PRODUCTION
            ),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_CARRIERE_PRODUCTION
            ),
          },
          {
            text: "Santé / Sécurité",
            picture: { src: insuranceImg, alt: "" },
            link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_SANTE}`,
            percentageProgress: declarationApi.getPathProgress(
              PATH_CARRIERE_SANTE
            ),
            isDisabled: !declarationApi.getIsActiveSection(PATH_CARRIERE_SANTE),
            isValidated: declarationApi.isPathValidatedInDeclaration(
              PATH_CARRIERE_SANTE
            ),
          },
        ]}
      />
      <BigCard
        picture={{ src: moleculeImg, alt: "" }}
        text="SPFO"
        percentageProgress={declarationApi.getPathProgress(PATH_SPFO)}
        link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_SPFO}`}
        isDisabled={!declarationApi.getIsActiveSection(PATH_SPFO)}
        isValidated={declarationApi.isPathValidatedInDeclaration(PATH_SPFO)}
        disabledMessage={
          "Cette page est active si vous avez déclaré au moins une émission de sulfonate de perfluorooctane dans le sol, l'eau ou l'air"
        }
      />
    </>
  );
};

export default AllDashboardCards;
