import React, { createContext } from "react";
import { AuthJwtDto } from "api/gen";

interface AuthJwtContext {
  jwt: AuthJwtDto;
  setJwt: (newJwt: AuthJwtDto) => void;
}

export const AuthenticatedUserContext = createContext<AuthJwtContext | null>(
  null
);

export const useAuthenticatedUser = (): AuthJwtContext => {
  const context = React.useContext(AuthenticatedUserContext);
  if (context === null) {
    throw Error(
      "User is not Authenticated yet or you are out of authenticated scopes."
    );
  }
  return context;
};
