import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { AirFugitivesProcedesDtoUniteEnum } from "api/gen";

export interface ObjectUnite {
  code: AirFugitivesProcedesDtoUniteEnum;
}

//TODO GEREP-1397 BEWARE! the order and most importantly, VALUE here MUST actually be correlated with array in emissions/utils/calculus.ts
export const arrayUnits: OptionPropsWithObject<ObjectUnite>[] = [
  {
    value: 1,
    label: "kg (kilogramme)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.KG,
    },
  },
  {
    value: 2,
    label: "t (tonne)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.T,
    },
  },
  {
    value: 3,
    label: "hl (hectolitre)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.HL,
    },
  },
  {
    value: 4,
    label: "l (litre)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.L,
    },
  },
  {
    value: 5,
    label: "1000 m³ (mille mètres cubes)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.KM3,
    },
  },
  {
    value: 6,
    label: "m³ (mètre cube)",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.M3,
    },
  },
  {
    value: 7,
    label: "Autre",
    object: {
      code: AirFugitivesProcedesDtoUniteEnum.AUTRE,
    },
  },
];
