import { FileDto, FilesRelatedToNimDto, OneFileRelatedToNimDto } from "api/gen";

export interface NimFilesLoaderProps {
  installationId: string;
  shouldDisableFields: boolean;
}

export type NimFilesDto = FilesRelatedToNimDto;

export const filesRelatedToNimDtoToFileDto = (
  installationId: string,
  nimFilesDtos: NimFilesDto[]
): FileDto[] => {
  const currentNimFilesDto = nimFilesDtos.find(
    nimFilesDto => nimFilesDto.nimDynId === installationId
  );
  if (currentNimFilesDto !== undefined) {
    return currentNimFilesDto.fichiers;
  } else {
    return [];
  }
};

export const oneFileRelatedToNimToFileDto = (
  installationId: string,
  nimFilesDtos: OneFileRelatedToNimDto[]
): FileDto[] => {
  const currentNimFilesDto = nimFilesDtos.find(
    nimFilesDto => nimFilesDto.nimDynId === installationId
  );
  if (currentNimFilesDto !== undefined && currentNimFilesDto.fichier !== null) {
    return [currentNimFilesDto.fichier];
  } else {
    return [];
  }
};
