import React, { useEffect, useRef, useState } from "react";
import ReactModal from "react-modal";
import { makeStyles } from "@material-ui/styles";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import SingleComment from "common/comment/SingleComment";
import { CommentValues } from "./commentTypes";
import { useCorrectYearCommentsToDisplay } from "../DeclarationApiContext/utils/correctYearVersionedElements/hooks/comments";
import classNames from "classnames";
import { ERROR, TEXT_LIGHT_GREY } from "../../../theme";
import { useUserData } from "../../../Authenticator/UserData";

const useStyles = makeStyles({
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    border: "1px solid gray",
    backgroundColor: "#FFFFFF",
    outline: "none",
    zIndex: 18,
    maxHeight: "80vh",
    overflowY: "auto",
    minWidth: "300px",
  },
  global: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  input: {
    outline: "none",
    minHeight: "3.6em",
    padding: "10px",
    resize: "vertical",
    border: "1px solid gray",
    height: "100px",
    fontFamily: "arial,helvetica,sans-serif",
    "&-webkit-scrollbar": {
      display: "none",
    },
  },
  invalidInput: {
    border: "1px solid red",
  },
  valid: {
    color: TEXT_LIGHT_GREY,
  },
  invalid: {
    color: ERROR,
  },
  charactersCounter: {
    fontSize: "13px",
    alignSelf: "end",
  },
});

const MAX_NUMBERS_CHARACTERS_COMMENT = 500;

export interface CommentModalProps {
  comments: CommentValues[];
  isWarning: boolean;
  isReadOnly: boolean;
  close: () => void;
  postComment: (comment: string) => void;
  deleteComment: (comment: CommentValues) => void;
}

const CommentModal = (props: CommentModalProps): React.ReactElement => {
  const classes = useStyles();

  const inputRef = useRef<HTMLTextAreaElement | null>(null);
  const [hasPipeInComment, setHasPipeInComment] = useState(false);

  const { isDeclarant } = useUserData();
  const commentsToDisplay: CommentValues[] = useCorrectYearCommentsToDisplay(
    props.comments,
    props.isWarning
  );
  const [nbCharacters, setNbCharacters] = useState(0);
  const [isBlockedByNumberCharacters, setIsBlockByNumberCharacters] = useState(
    false
  );

  useEffect(() => {
    setIsBlockByNumberCharacters(
      isDeclarant && nbCharacters > MAX_NUMBERS_CHARACTERS_COMMENT
    );
  }, [nbCharacters, isDeclarant]);
  return (
    <ReactModal
      isOpen={true}
      className={classes.modal}
      style={{ overlay: { zIndex: 17 } }}
      shouldCloseOnOverlayClick
      onRequestClose={props.close}
    >
      <div className={classes.global}>
        {/* display comment list */}
        <div>
          {commentsToDisplay.map(singleComment => (
            <SingleComment
              comment={singleComment}
              deleteComment={props.deleteComment}
            />
          ))}
        </div>
        {!props.isReadOnly && (
          <>
            <textarea
              ref={inputRef}
              placeholder={"Votre commentaire..."}
              className={classNames(
                classes.input,
                isBlockedByNumberCharacters ? classes.invalidInput : ""
              )}
              onChange={event => {
                setHasPipeInComment(event.target.value.includes("|"));
                setNbCharacters(event.target.value.length);
              }}
            />{" "}
            {isDeclarant && (
              <div
                className={classNames(
                  classes.charactersCounter,
                  isBlockedByNumberCharacters ? classes.invalid : classes.valid
                )}
              >
                {nbCharacters}/{MAX_NUMBERS_CHARACTERS_COMMENT} caractères
              </div>
            )}
          </>
        )}

        <ErrorDisplayer
          message="Veuillez ne pas utiliser le caractère | dans votre commentaire"
          isHidden={!hasPipeInComment}
        />
        <DummyGlobalFormActions
          validateButton={{
            title: "Envoyer",
            isEnabled: !hasPipeInComment && !isBlockedByNumberCharacters,
            isVisible: !props.isReadOnly,
            onClick: async () => {
              if (inputRef.current) {
                props.postComment(inputRef.current.value.trim());
              }
            },
            isSpinnerVisible: false,
          }}
          cancelButton={{
            title: props.isReadOnly ? "Fermer" : "Annuler",
            isVisible: true,
            isEnabled: true,
            onClick: props.close,
          }}
        />
      </div>
    </ReactModal>
  );
};

export default CommentModal;
