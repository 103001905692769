import React from "react";
import { PINK } from "theme";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    marginBottom: "100px",
    marginTop: "100px",
  },
  title: {
    fontSize: "2rem",
    color: PINK,
  },
});

/**
 * The maintenance mode can be enabled by setting the boolean constant in src/pages/CommonSpace/AppPage/index.tsx
 */
const Maintenance = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      <h1 className={classes.title}>
        Des opérations de maintenance sont en cours. Nous vous prions de nous
        excuser pour la gêne occasionnée et vous invitons à réessayer
        ultérieurement.
      </h1>
    </div>
  );
};

export default Maintenance;
