import React from "react";
import {
  PATH_QUOTAS_ALC_PRELIMINAIRE,
  PATH_QUOTAS_ALC_VERIFIE,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_GREY, SECTION_TITLE_STYLE } from "theme";
import AlcRapportFileLoader from "./AlcRapportFileLoader";
import JustificatifsFilesLoader from "./JustificatifsFilesLoader";
import { useNiveauxActiviteValidationMessage } from "../../utils/utils";
import BlocFullContext2020 from "../../../versionedElements/BlocFullContext2020";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ...SECTION_TITLE_STYLE,
});

interface QuotasAlcUploaderProps {
  isAlcVerifie: boolean;
}

const QuotasAlcForm = ({
  isAlcVerifie,
}: QuotasAlcUploaderProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const validationMessage = useNiveauxActiviteValidationMessage();
  const installations =
    declaration.computed.sections.quotas.allocations.installations;

  const label = isAlcVerifie ? "VERIFIE" : "PRELIMINAIRE";

  const blocPath = isAlcVerifie
    ? PATH_QUOTAS_ALC_VERIFIE
    : PATH_QUOTAS_ALC_PRELIMINAIRE;

  const filesPath = isAlcVerifie
    ? "body.sections.quotas.allocations.blocAlc.alcVerifie"
    : "body.sections.quotas.allocations.blocAlc.alcPreliminaire";

  return (
    <BlocFullContext2020
      hasModification={false}
      isValidateButtonAvailable={true}
      editValidationMessage={validationMessage}
      title={`RAPPORT DE DECLARATION DES NIVEAUX D’ACTIVITE (${label})`}
      path={blocPath}
      updateHandler={declaration => {
        return declaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          {!isAlcVerifie && (
            <div className={classes.text}>
              <p>
                Le dépôt est facultatif, mais la section doit être validée (avec
                ou sans dépôt).
              </p>
            </div>
          )}
          <Row height={"20px"} />
          {installations.map(installation => (
            <div className={classes.row} key={installation.id}>
              <div className={classes.sectionTitle}>
                NIM à remplir : {installation.nim}
                <CommentButton path={`${blocPath}/${installation.id}`} />
              </div>
              <AlcRapportFileLoader
                label={label}
                installation={installation}
                filesPath={filesPath}
                isAlcVerifie={isAlcVerifie}
                shouldDisableFields={shouldDisabledFields}
              />
              <JustificatifsFilesLoader
                installation={installation}
                filesPath={filesPath}
                isAlcVerifie={isAlcVerifie}
                shouldDisableFields={shouldDisabledFields}
              />
            </div>
          ))}
        </>
      )}
    />
  );
};

export default QuotasAlcForm;
