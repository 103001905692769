import React, { useState } from "react";
import danger from "icons/danger.svg";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyBooleanCheckboxFieldGenerator,
} from "common/form/fields/helpers/generators";
import { SECTION_TITLE_GREY } from "theme";
import {
  aggregationModaleValuesToSelectedSections,
  areAllCheckboxesSelected,
  filtreGestionnaireDtoToAggregationModaleValues,
  selectAllCheckbox,
} from "./converters";
import Spinner from "common/presentational/Spinner";
import { useUserData } from "Authenticator/UserData";
import { useDeclarationAggregation } from "../../../CompanySpace/DeclarationApiContext/utils/exportHooks";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { AggregationModaleValues, sectionFieldMatcher } from "./types";
import { DeclarationFilters } from "../types";
import { declarationFiltersToDto } from "../converter";

interface AggregationModaleProps {
  isOpen: boolean;
  onRequestClose: () => void;
  filter: DeclarationFilters;
  setFilter: (filter: DeclarationFilters) => void;
}

const useStyles = makeStyles({
  loadingMessage: {
    textAlign: "center",
    marginBottom: "20px",
    color: SECTION_TITLE_GREY,
  },
  inputField: {
    marginBottom: "5px",
  },
  warningIcon: {
    width: "5%",
    margin: "10px",
  },
  warningContainer: {
    marginBottom: "50px",
    color: SECTION_TITLE_GREY,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
});

const AggregationModale = ({
  isOpen,
  onRequestClose,
  filter,
  setFilter,
}: AggregationModaleProps): React.ReactElement => {
  const classes = useStyles();
  const declarationAggregation = useDeclarationAggregation();
  const { userInfo } = useUserData();
  const { email } = userInfo;

  const commonProps = {
    disabled: false,
    formPrefix: "aggregation-modale",
    labelWidth: "50%",
    className: classes.inputField,
  };
  const initialValues = filtreGestionnaireDtoToAggregationModaleValues(filter);

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DummyCheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <CommonFormSingleEntity<AggregationModaleValues>
        title="TÉLÉCHARGER LA LISTE DES DÉCLARATIONS"
        isEdit={false}
        closeFunction={onRequestClose}
        initialEntity={initialValues}
        onSubmit={async values => {
          setIsFetching(true);
          const sections = aggregationModaleValuesToSelectedSections(values);
          const newFilter = {
            ...filter,
            selectedSections: sections,
          };
          setFilter(newFilter);
          await declarationAggregation(declarationFiltersToDto(newFilter));
          setIsFetching(false);
          onRequestClose();
        }}
        validate={values => {
          const sections = aggregationModaleValuesToSelectedSections(values);
          if (sections.length === null || sections.length === 0) {
            // throwing an empty error to disable the validate button
            return {
              infoGenerales: "",
            };
          }
        }}
        renderField={({ setFieldValue, values }) =>
          isFetching ? (
            <>
              <div className={classes.loadingMessage}>
                Demande d'export en cours...
              </div>
              <Spinner />
            </>
          ) : (
            <>
              <div className={classes.warningContainer}>
                <img
                  src={danger}
                  alt="un panneau attention"
                  className={classes.warningIcon}
                />
                <ul>
                  <li>
                    <p>
                      Les filtres appliqués sur le tableau des déclarations
                      seront pris en compte pour l'export.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        Le fichier téléchargé sera mis à votre disposition à
                        l’adresse mail {email} dès qu’il sera chargé. Ce procédé
                        peut prendre quelques minutes.
                      </strong>
                    </p>
                  </li>
                </ul>
              </div>

              <DummyCheckBox
                label="TOUT SÉLECTIONNER"
                name="selectAll"
                value={areAllCheckboxesSelected(values)}
                onChange={newValue =>
                  selectAllCheckbox(values, newValue, setFieldValue)
                }
              />

              <CheckBox
                label="Résumé des déclarations"
                name={sectionFieldMatcher.resumeDeclarations}
              />

              <CheckBox
                label="Informations générales"
                name={sectionFieldMatcher.infoGenerales}
              />

              <CheckBox
                label="Types d'activité global"
                name={sectionFieldMatcher.typeActiviteGlobal}
              />

              <CheckBox
                label="Types d'activité quotas"
                name={sectionFieldMatcher.typeActiviteQuotas}
              />

              <CheckBox label="Déchets" name={sectionFieldMatcher.dechets} />

              <CheckBox label="Eau" name={sectionFieldMatcher.eau} />

              <CheckBox label="Sol" name={sectionFieldMatcher.sol} />

              <CheckBox
                label="Combustion / Incinération"
                name={sectionFieldMatcher.airCombustion}
              />

              <CheckBox
                label="Procédés / Émissions diffuses"
                name={sectionFieldMatcher.airProcedes}
              />

              <CheckBox
                label="Solvants"
                name={sectionFieldMatcher.airSolvants}
              />

              <CheckBox label="ISDND" name={sectionFieldMatcher.airIsdnd} />

              <CheckBox label="Élevage" name={sectionFieldMatcher.airElevage} />

              <CheckBox
                label="Synthèse"
                name={sectionFieldMatcher.airSynthese}
              />

              <CheckBox
                label="Gaz fluorés"
                name={sectionFieldMatcher.airGazFluores}
              />

              <CheckBox
                label="Quotas - Emissions"
                name={sectionFieldMatcher.quotasEmissions}
              />

              <CheckBox
                label="Quotas - Niveaux d’activité"
                name={sectionFieldMatcher.quotasAllocations}
              />

              {filter.annees?.includes(2023) && (
                <CheckBox
                  label="Quotas - Exclusions, incinérateur"
                  name={sectionFieldMatcher.quotasWithAnyExclusions}
                />
              )}

              <CheckBox
                label="Environnement"
                name={sectionFieldMatcher.carriereEnvironnement}
              />

              <CheckBox
                label="Production"
                name={sectionFieldMatcher.carriereProduction}
              />

              <CheckBox
                label="Santé / Sécurité"
                name={sectionFieldMatcher.carriereSanteSecurite}
              />

              <CheckBox label="SPFO" name={sectionFieldMatcher.spfo} />
            </>
          )
        }
      />
    </CustomModal>
  );
};

export default AggregationModale;
