import React, { ReactElement } from "react";
import { CampaignDtoStateGlobalEnum } from "api/gen";
import {
  FORCED,
  RECALLED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { useUserData } from "Authenticator/UserData";
import { useDeclarationGlobalState21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import ActionButtonsContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/ActionButtonsContainer";
import CancelTakeoverButton from "pages/CompanySpace/from21/toNow/Actions/Global/CancelTakeoverButton";
import ReviewButton from "pages/CompanySpace/from21/toNow/Actions/Global/ReviewButton";
import ApproveButton from "../Global/ApproveButton";
import TakeoverButton from "pages/CompanySpace/from21/toNow/Actions/Global/TakeoverButton";

const InspecteurGlobalActionButtons = (): ReactElement => {
  const { isAdmin } = useUserData();
  const currentCampaign = useCurrentDeclarationCampaign();
  const isGlobalCampaignActive =
    currentCampaign.stateGlobal !== CampaignDtoStateGlobalEnum.ENDED;
  const { isDeclarationStateContaining } = useDeclarationGlobalState21Now();

  return (
    <ActionButtonsContainer>
      {isDeclarationStateContaining([FORCED]) && isGlobalCampaignActive && (
        <CancelTakeoverButton workflowTarget={WorkflowTarget.GLOBAL} />
      )}

      {!isDeclarationStateContaining([FORCED]) &&
        (isAdmin || isGlobalCampaignActive) && <TakeoverButton />}

      {isDeclarationStateContaining([RECALLED]) && isGlobalCampaignActive && (
        <ReviewButton text={"Accepter la demande de modification"} />
      )}

      {isDeclarationStateContaining([SUBMITTED]) && isGlobalCampaignActive && (
        <ReviewButton text={"Mettre en révision"} />
      )}

      {isDeclarationStateContaining([SUBMITTED, FORCED]) && <ApproveButton />}

      {isDeclarationStateContaining([VALIDATED]) && isGlobalCampaignActive && (
        <ReviewButton text={"INVALIDER"} />
      )}
    </ActionButtonsContainer>
  );
};

export default InspecteurGlobalActionButtons;
