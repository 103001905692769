import {
  ReferenceItemDechetDto,
  ReferenceItemDepartementDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { ProductionLocationProps } from "../PolluantProduction/types";
import { ReceptionLocationProps } from "../PolluantReception/types";
import { OperationDescription, WasteMethod } from "./Referentiels";

export const formatDepartementToModalLine = (
  depOption: ReferenceItemDepartementDto
): string => {
  return `${depOption.numero} - ${depOption.nom}`;
};
export const formatWasteLabel = (
  waste: ReferenceItemDechetDto | null,
  defaultValue: string
): string => {
  let wasteWording: string;

  if (waste) {
    wasteWording = waste.dangereux ? `${waste.codeDechet}*` : waste.codeDechet;
  } else {
    wasteWording = defaultValue;
  }

  return wasteWording;
};

export const displayMethod = (method: WasteMethod | null): string => {
  if (method !== null) {
    return method.label;
  }
  return "";
};

export const displayOperation = (
  operation: OperationDescription | null
): string => {
  if (operation !== null) {
    return operation.label;
  }
  return "";
};

export const displayOperationLocation = (
  operationLocation: ProductionLocationProps | ReceptionLocationProps | null
): string => {
  if (operationLocation !== null) {
    return operationLocation.label;
  }
  return "";
};

export const displayCodeDechet = (
  dechet: ReferenceItemDechetDto | string | null
): string => {
  if (typeof dechet === "object" && dechet !== null) {
    return dechet.codeDechet;
  }
  return dechet ? dechet : "";
};

export const displayDepartement = (
  departement: ReferenceItemDepartementDto | string | null
): string => {
  if (typeof departement === "object" && departement !== null) {
    return formatDepartementToModalLine(departement);
  }
  return departement ? departement : "";
};

export const displayPays = (
  pays: ReferenceItemPaysDto | string | null
): string => {
  if (typeof pays === "object" && pays !== null) {
    return pays.designation;
  }
  return pays ? pays : "";
};

export const normalizeLabelPays = (label1: string): string => {
  return label1
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toUpperCase();
};
