import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  computeInitialValues,
  getNimFromNimDynId,
  updateHandler,
} from "./utils";
import { validationSchema } from "./validation";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { PATH_QUOTAS_INFOS_COMPLEMENTAIRES_INSTALLATIONS } from "common/path/path2020";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import Row from "common/presentational/Row";
import {
  QuotasInfosComplementairesInstallationsDto2021CategorieEnum,
  QuotasInstallationDto20Now,
} from "api/gen";
import { FIELD_FONT_SIZE, FIELD_FONT_WEIGHT, SECTION_TITLE_GREY } from "theme";
import {
  infosComplementairesInstallationsLabels,
  infosComplementairesInstallationsOptions,
} from "./selectPossibleValues";
import { BlocInfosComplementaires } from "./types";
import InfoBulle from "common/infoBulle/InfoBulle";
import { useEmissionValidationMessage } from "../../utils/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import FormikBlocFullContext2020 from "../../../versionedElements/FormikBlocFullContext2020";

const useStyles = makeStyles({
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "center",
    alignSelf: "center",
    width: "25%",
  },
  field: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    alignSelf: "center",
  },
  additionalRowStyle: {
    justifyContent: "space-around",
    marginBottom: "30px",
  },
});

const BlocInfosComplementairesInstallations = ({
  hasChanges,
  setHasChanges,
  formikRef,
}: FormikBlocRefAndState<BlocInfosComplementaires>): React.ReactElement => {
  const declaration = useDeclaration2020();
  const classes = useStyles();
  const validationMessage = useEmissionValidationMessage();

  const commonProps = {
    disabled: false,
    className: classes.field,
    labelWidth: "10%",
    formPrefix: "infos-complémentaires",
  };

  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const typeActiviteQuotas = declaration.body.typeActivite;

  const installations: QuotasInstallationDto20Now[] =
    typeActiviteQuotas.installations;
  const initialValues: BlocInfosComplementaires = computeInitialValues(
    declaration.body.sections.quotas.emissions
      .blocInfosComplementairesInstallations,
    installations
  );

  return (
    <FormikBlocFullContext2020
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      validationSchema={validationSchema}
      editValidationMessage={validationMessage}
      title={"INFORMATIONS COMPLEMENTAIRES SUR LES INSTALLATIONS"}
      pathToValidate={PATH_QUOTAS_INFOS_COMPLEMENTAIRES_INSTALLATIONS}
      areGlobalCommentsAllowed={true}
      updateHandler={updateHandler}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      renderContent={(props, shouldDisabledFields) => (
        <>
          {props.values.installations.length !== 0 ? (
            <>
              <Row additionalClassname={classes.additionalRowStyle}>
                <span className={classes.label}>NIM</span>
                <span className={classes.label}>
                  <InfoBulle
                    content={
                      "Conformément à l'article 19 du règlement MRR (2018/2066), les émissions annuelles moyennes vérifiées permettant la définition de la catégorie sont celles avant déduction du CO2 transféré et ne tiennent pas compte du CO2 issu de la biomasse."
                    }
                  />
                  Catégorie de l'installation
                </span>
                <span className={classes.label}>
                  <InfoBulle
                    content={
                      "Emissions inférieures ou égales à 25 000 tCO2/an."
                    }
                  />
                  Installation faiblement émettrice
                </span>
              </Row>

              <FieldArray
                name={"installations"}
                render={() => (
                  <div>
                    {props.values.installations.map((installation, index) => (
                      <Row
                        key={`${index}-infos-comp-installations`}
                        additionalClassname={classes.additionalRowStyle}
                      >
                        <div className={classes.field}>
                          <span className={classes.field}>
                            {getNimFromNimDynId(
                              installation.nimDynId,
                              installations
                            )}
                          </span>
                        </div>
                        <div className={classes.field}>
                          <WrappedChoiceSelect
                            label=""
                            name={`installations.${index}.categorie`}
                            isMulti={false}
                            options={infosComplementairesInstallationsOptions}
                            computeLabel={info =>
                              infosComplementairesInstallationsLabels[info]
                            }
                            additionalClassName={classes.field}
                            disabled={shouldDisabledFields}
                            commonProps={commonProps}
                          />
                        </div>
                        <div className={classes.field}>
                          {installation.categorie && (
                            <>
                              {installation.categorie ===
                                QuotasInfosComplementairesInstallationsDto2021CategorieEnum.A && (
                                <>
                                  <BooleanField
                                    label=""
                                    name={`installations.${index}.faiblementEmettrice`}
                                    disabled={shouldDisabledFields}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Row>
                    ))}
                  </div>
                )}
              />
            </>
          ) : (
            ""
          )}
        </>
      )}
    />
  );
};

export default BlocInfosComplementairesInstallations;
