import {
  QuotasAllocationsVerificationDto20NowConclusionEnum,
  QuotasAllocationsVerificationDto20NowConditionDispenseEnum,
  QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum,
  QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum,
  QuotasAllocationsVerificationDto20NowOrganismeEnum,
} from "api/gen";

export enum BlocVerifState {
  IN_PROGRESS,
  COMPLETED,
  IGNORED,
  UNKNOWN,
}

export interface BlocVerificationInstallationFormValues {
  installationId: string;
  organisme: QuotasAllocationsVerificationDto20NowOrganismeEnum | null;
  monitoringPlanReference: string | null;
  visitExemption: boolean;
  conditionExemption: QuotasAllocationsVerificationDto20NowConditionDispenseEnum | null;
  exemptionDetail: string | null;
  lastVisitDate: Date | null;
  activityAndEmissionVisit: boolean;
  verificationReportConclusion: QuotasAllocationsVerificationDto20NowConclusionEnum | null;
  satisfyingDeclarationReason: QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum[];
  unsatisfyingDeclarationReason: QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum[];
}

export interface BlocVerificationFormValues {
  installations: BlocVerificationInstallationFormValues[];
}
