import React, { useEffect, useState } from "react";
import CustomModal from "common/presentational/CustomModal";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  LINK_STYLE,
  PINK,
  SECTION_TITLE_GREY,
} from "theme";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import { InfoControllerApi } from "api/gen/api";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    color: PINK,
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
  bold: {
    fontWeight: "bold",
  },
  link: LINK_STYLE,
});

interface ModalMentionsLegalesProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalMentionsLegales = ({
  isOpen,
  onRequestClose,
}: ModalMentionsLegalesProps) => {
  const classes = useStyles();
  const [version, setVersion] = useState<string | null>(null);

  useEffect(() => {
    const getVersion = async () => {
      const controller = new InfoControllerApi(undefined, "");
      try {
        setVersion((await controller.getInfoUsingGET()).version);
      } catch (_) {
        setVersion("+++");
      }
    };

    getVersion();
  }, [setVersion]);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={classes.title}>
        <h1>Mentions légales</h1>
      </div>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          href="https://www.declarationpollution.developpement-durable.gouv.fr"
        >
          www.declarationpollution.developpement-durable.gouv.fr
        </a>{" "}
        est un site du Ministère de la transition écologique. Il a fait l'objet
        d'une déclaration auprès de la CNIL (Commission Nationale de
        l'Informatique et des Libertés) qui a donné lieu à un dossier nº
        1047187.
        <br /> <br />
        <h5 className={classes.bold}>Le rôle de la CNIL</h5>
        <br />
        La CNIL veille à ce que les traitements informatisés, publics ou privés,
        d'informations nominatives soient effectués conformément à la loi
        nº78-17 de 1978 modifiée par la loi nº 2004-801 du 6 août 2004. Les
        "informations nominatives" désignent celles qui permettent, directement
        ou indirectement, et quelle que soit leur forme, l'identification de
        personnes physiques, que le traitement soit effectué par une personne
        physique ou morale (une société par exemple).
        <br /> <br />
        <h5 className={classes.bold}>
          Mise en oeuvre des traitements informatisés
        </h5>
        <br />
        Les traitements automatisés d'informations nominatives, opérés pour le
        compte de l'Etat, d'un établissement public, d'une collectivité
        territoriale ou d'une personne morale de droit privé gérant un service
        public, sont visés par l'article 27 de la loi nº 2004-801 du 6 août
        2004. Ce dernier précise que ces traitements sont autorisés par décret
        en Conseil d'état, pris après avis motivé et publié de la CNIL.
        <br /> <br />
        <h5 className={classes.bold}>
          Déclaration des émissions polluantes en ligne
        </h5>
        <br />
        Le renseignement des informations nominatives fournies dans le
        formulaire de déclaration en ligne est obligatoire. Ces informations
        font l'objet d'un traitement automatisé au Ministère de la transition
        écologique et sont destinées exclusivement aux agents des services
        travaillant dans le domaine de l'inspection des installations classées.
        Aucune information personnelle n'est collectée à l'insu des
        utilisateurs. Aucune information personnelle n'est cédée à des tiers.
        L'utilisateur peut exercer son droit d'accès aux informations, et le cas
        échéant son droit de rectification directement en ligne ou auprès du
        service d'inspection des installations classées auquel il est rattaché.
        <br /> <br />
        <h5 className={classes.bold}>Utilisation de l'adresse électronique</h5>
        <br />
        L'adresse électronique recueillie dans le formulaire de déclaration des
        émissions polluantes ne sera utilisée que pour la communication entre le
        service d'inspection et l'utilisateur notamment pour l'envoi d'un
        courrier électronique destiné à informer l'utilisateur de la mise en
        révision de sa déclaration par le service d'inspection des installations
        classées.
        <br /> <br />
        <h5 className={classes.bold}>Cookies</h5>
        <br />
        Certaines pages de ce site implantent un cookie dans l'ordinateur de
        l'utilisateur. Un cookie ne permet pas au Ministère de la transition
        écologique d'identifier l'utilisateur. En revanche, il enregistre des
        informations relatives à la navigation de l'ordinateur sur le site, que
        le Ministère de la transition écologique peut lire lors de ses visites
        ultérieures.
        <br /> <br />
        <h5 className={classes.bold}>Icônes utilisées</h5>
        <br />
        Certaines pages de ce site utilisent des icônes de fontawesome. Ces
        icônes sont sous licence{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          href="https://fontawesome.com/license/free"
        >
          Creative Commons Attribution 4.0
        </a>
        . Certaines icônes sont des combinaisons d'icônes de fontawesome.
        <br /> <br />
        Version : {version}
      </p>
      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
        isReversed
      />
    </CustomModal>
  );
};

export default ModalMentionsLegales;
