import * as Yup from "yup";
import {
  commonBooleanFieldsNullable,
  commonDateFieldsNullable,
  commonNullableStringFields,
} from "common/declarant/formik/formikHelper";
import { BlocVerificationFormValues } from "./types";
import {
  futureDateMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";

export const validationSchema = Yup.object().shape({
  props: Yup.array().of(
    Yup.object().shape({
      organizationName: commonNullableStringFields,
      monitoringPlanReference: commonNullableStringFields,
      visitExemption: commonBooleanFieldsNullable,
      conditionExemption: Yup.object().nullable(),
      lastVisitDate: commonDateFieldsNullable,
      improvementReport: commonBooleanFieldsNullable,
      verificationReportConclusion: Yup.object().nullable(),
      satisfyingDeclarationReason: Yup.array().nullable(),
      uncheckedDeclarationReason: Yup.array().nullable(),
    })
  ),
});

export const additionalFieldValidation = (
  values: BlocVerificationFormValues
): Errors<BlocVerificationFormValues> => {
  const errors: Errors<BlocVerificationFormValues> = {};

  if (values.date > new Date()) {
    errors.date = futureDateMessage;
  }

  // the validation is done manually because there is a bug with yup
  // as all the fields of FieldArray are not displayed at the same time
  values.props.forEach((props, index) => {
    if (!props.organizationName) {
      errors[`props.${index}.organizationName`] = requiredMessage;
    }
    if (!props.monitoringPlanReference) {
      errors[`props.${index}.monitoringPlanReference`] = requiredMessage;
    }
    if (props.visitExemption && !props.conditionExemption) {
      errors[`props.${index}.conditionExemption`] = requiredMessage;
    }
    if (!props.lastVisitDate) {
      errors[`props.${index}.lastVisitDate`] = requiredMessage;
    }
    if (!props.verificationReportConclusion) {
      errors[`props.${index}.verificationReportConclusion`] = requiredMessage;
    }
    if (props.verificationReportConclusion) {
      if (
        props.verificationReportConclusion.object.isSatisfyingWithRequests &&
        props.satisfyingDeclarationReason.length === 0
      ) {
        errors[`props.${index}.satisfyingDeclarationReason`] = requiredMessage;
      }
      if (
        props.verificationReportConclusion.object.isReportUnchecked &&
        props.uncheckedDeclarationReason.length === 0
      ) {
        errors[`props.${index}.uncheckedDeclarationReason`] = requiredMessage;
      }
    }
  });
  return errors;
};
