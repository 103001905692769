import { makeStyles } from "@material-ui/styles";
import React from "react";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import { Information } from "./utils/types";
import {
  informationValidationSchema,
  isPgsObligatoire,
  singleSolvantOverwriteDependantFields,
} from "./validation/validation";
import MessageInfoField from "common/form/MessageInfoField";
import {
  derogationLabels,
  derogationOptions,
  getActiviteOptions,
} from "./utils/selectPossibleValues";
import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { createAirSolvantsInformationDto } from "./utils/utils";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import Row from "common/presentational/Row";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useDeclarationFileChange } from "common/utils/hooks/declarationEventHooks";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration19Now } from "../../../versionedElements/declarationHooks19Now";
import FormikBlocFullContext19Now from "../../../versionedElements/FormikBlocFullContext19Now";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import classNames from "classnames";
import { usePgsFilesHandler19Now } from "../../../versionedElements/filesLoaderDisplayer19Now/sectionFilesHooks19Now";
import FilesLoader from "common/FilesLoader/FilesLoader";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  select: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  importContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "& div": {
      justifyContent: "flex-end",
    },
  },
  size: {
    height: "auto",
  },
  ...LEFT_WITHDRAW_STYLE,
});

interface InformationProps {
  validationInformationPath: string;
  initialInformations: Information;
  formikInformationProps: FormikBlocRefAndState<Information>;
}

const InformationBloc = ({
  validationInformationPath,
  initialInformations,
  formikInformationProps,
}: InformationProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration19Now();
  const openAlertModale = useAlertModale();

  const pgsFilesHandler = usePgsFilesHandler19Now();

  const updateHandler = (
    declaration: Declaration19Now,
    values: Information
  ) => {
    declaration.body.sections.air.solvants.informations = createAirSolvantsInformationDto(
      values,
      declaration.body.sections.air.solvants.informations
    );
    return declaration;
  };

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-information-solvants",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const filePath = "body.sections.air.solvants.informations.files";
  useDeclarationFileChange(declaration, filePath, () => {
    if (
      formikInformationProps.formikRef &&
      formikInformationProps.formikRef.current
    ) {
      formikInformationProps.formikRef.current.setFieldError(
        "consommationAnnuelleSolvant",
        undefined
      );
    }
  });

  return (
    <FormikBlocFullContext19Now
      formikRef={formikInformationProps.formikRef}
      hasChanges={formikInformationProps.hasChanges}
      setHasChanges={formikInformationProps.setHasChanges}
      initialValues={initialInformations}
      validationSchema={informationValidationSchema}
      title="Informations complémentaires"
      pathToValidate={validationInformationPath}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) => {
        return updateHandler(
          declaration,
          singleSolvantOverwriteDependantFields(values)
        );
      }}
      renderContent={({ values }, shouldDisabledFields) => {
        return (
          <>
            <NumberField
              name="consommation"
              label="Consommation annuelle de solvants (en tonnes) *"
              unit="t/an"
              disabled={shouldDisabledFields}
              commentPath={`${validationInformationPath}/consommation`}
              tooltipContent="«consommation» : quantité totale de solvants organiques utilisée dans une installation par année de calendrier ou toute autre période de douze mois, moins les composés organiques volatils récupérés en vue de leur réutilisation. Celle-ci est calculée à l’aide du plan de gestion de solvants via l’équation suivante : C = I1 – O8
              (directive n° 2010/75/UE du 24/11/10 relative aux émissions industrielles)"
            />
            {isPgsObligatoire(values.consommation) && (
              <MessageInfoField message="Vous devez importer vos PGS avec le bouton « Déposer PGS » pour les déclarer dans GEREP." />
            )}
            <CheckBox
              name="ied"
              label="Au moins une des activités utilisant des solvants organiques, visées au chapitre V de la directive IED"
              disabled={shouldDisabledFields}
              commentPath={`${validationInformationPath}/ied`}
            />
            {values.ied && (
              <div className={classes.withdrawLeft}>
                <WrappedChoiceSelect
                  isMulti
                  name="activites"
                  label="Activité(s) listée(s) à l'annexe VII de le directive IED *"
                  options={getActiviteOptions(values.activites)}
                  computeLabel={activite => activite}
                  disabled={shouldDisabledFields}
                  commentPath={`${validationInformationPath}/activites`}
                  tooltipContent={
                    "Activités listées à l'annexe VII de la directive 2010/75/UE du 24 novembre 2010 relative aux émissions industrielles pour les installations visées au chapitre V de la même directive."
                  }
                  commonProps={{
                    ...commonProps,
                    className: classNames(classes.inputField, classes.select),
                  }}
                />
                <CheckBox
                  name="conforme"
                  label="Installation conforme aux dispositions de l'article 59, paragraphe 1 de la directive IED"
                  disabled={shouldDisabledFields}
                  commentPath={`${validationInformationPath}/conforme`}
                  tooltipContent="Extrait de l'article 59 de la directive du 24 novembre 2010 (Réduction des émissions) :
                  1.a) les émissions de composés organiques volatils des installations ne dépassent pas les valeurs limites d’émission dans les gaz résiduaires et les valeurs limites d’émission diffuse, ou les valeurs limites d’émission totale, et les autres exigences énoncées dans l’annexe VII, parties 2 et 3 sont respectées ;
                  1.b) les installations respectent les exigences du schéma de réduction figurant dans l’annexe VII, partie 5 à condition qu’il en résulte une réduction des émissions équivalente à celle qu’aurait permis d’obtenir l’application des valeurs limites d’émission visées au point a)."
                />
                {values.conforme === false && (
                  <div className={classes.withdrawLeft}>
                    <WrappedChoiceSelect
                      name="derogation"
                      label="Installation faisant l'objet d'une dérogation prévue à l'article 59 de la directive IED *"
                      isMulti={false}
                      options={derogationOptions}
                      computeLabel={derogation => derogationLabels[derogation]}
                      disabled={shouldDisabledFields}
                      commentPath={`${validationInformationPath}/derogation`}
                      tooltipContent="Extrait de l'article 59 de la directive du 24 novembre 2010 (Réduction des émissions) :
                    2. Par dérogation au paragraphe 1, point a), si l’exploitant démontre à l’autorité compétente qu’une installation déterminée ne peut, d’un point de vue technique et économique, respecter la valeur limite d’émission fugitive, l’autorité compétente peut autoriser le dépassement de cette valeur limite d’émission, pour autant qu’il n’y ait pas lieu de craindre des risques importants pour la santé humaine ou pour l’environnement et que l’exploitant prouve, à l’autorité compétente qu’il est fait appel aux meilleures techniques disponibles.
                    3. Par dérogation au paragraphe 1, pour les activités de revêtement relevant de la rubrique 8 du tableau figurant dans l’annexe VII, partie 2, qui ne peuvent être réalisées dans des conditions maîtrisées, l’autorité compétente peut accepter que les émissions des installations ne respectent pas les exigences du présent paragraphe si l’exploitant démontre à l’autorité compétente que cela n’est pas techniquement ni économiquement réalisable et qu’il est fait appel aux meilleures techniques disponibles.
                    "
                      commonProps={{
                        ...commonProps,
                        className: classNames(
                          classes.inputField,
                          classes.select
                        ),
                      }}
                    />
                  </div>
                )}
              </div>
            )}
            <CheckBox
              name="sme"
              label="Mise en oeuvre d'un schéma de maîtrise des émissions (SME)"
              disabled={shouldDisabledFields}
              commentPath={`${validationInformationPath}/sme`}
            />
            <CheckBox
              name="danger"
              label="Utilisation de COV à mention de danger"
              disabled={shouldDisabledFields}
              tooltipContent="Au moins une activité utilisant des substances ou mélanges auxquels sont attribuées ou sur lesquels doivent être apposées les mentions de dangers H340, H350, H350i, H360D ou H360F par le règlement 1272/2008/CE du Conseil et / ou émettant des composés organiques volatils halogénés auxquels est attribuée, ou sur lesquels doit être apposée la mention de danger H341 ou H351 (anciennes phrases de risques R40, R45, R46, R49, R60, R61 ou R68)"
              commentPath={`${validationInformationPath}/danger`}
            />
            {values.danger && (
              <MessageInfoField
                additionalClassname={classes.size}
                message="Vous devrez déclarer au moins une émission de COV à mention de danger pour pouvoir valider la page"
              />
            )}
            <Row />
            <FilesLoader
              declaration={declaration}
              text={"Déposer PGS"}
              filesPath={filePath}
              section={FileSectionEnum.SOLVANT_PLAN_GESTION}
              fileHandlers={pgsFilesHandler}
              //
              max={5}
              isDisabled={shouldDisabledFields}
              //
              additionalClassnameContainer={classes.importContainer}
              onReject={() =>
                openAlertModale("Le format du fichier uploadé est incorrect.")
              }
            />
            <Row />
          </>
        );
      }}
    />
  );
};

export default InformationBloc;
