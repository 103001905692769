import React, { ReactChild, useCallback, useContext } from "react";
import { ConfirmationModaleContent } from "./commonModaleContent/ConfirmationModaleContent";
import { GlobalModaleProviderContext } from "./GlobalModaleProvider";
import {
  MessageModaleContent,
  MessageModaleType,
} from "./commonModaleContent/MessageModaleContent";
import { ReasonModaleContent } from "./commonModaleContent/ReasonModaleContent";

export const useModale = () => {
  const setComponent = useContext(GlobalModaleProviderContext);
  if (setComponent === null) {
    throw Error("should be called in ConfirmationModaleContext");
  }

  return setComponent;
};

export type OpenConfirmationModaleTrigger = (
  message: ReactChild | string,
  confirmationCallback: () => void,
  messageDetails?: string | ReactChild
) => void;

export const useConfirmationModale = (): OpenConfirmationModaleTrigger => {
  const setAlertModale = useModale();

  return useCallback(
    (
      newMessage: string | ReactChild,
      callback: () => void,
      messageDetails?: string | ReactChild
    ) => {
      const node = (
        <ConfirmationModaleContent
          onConfirmation={() => {
            callback();
            setAlertModale(null);
          }}
          onCancel={() => {
            setAlertModale(null);
          }}
          message={newMessage}
          messageDetails={messageDetails}
        />
      );

      setAlertModale({
        node: node,
        isClosingExplicitOnly: false,
      });
    },
    [setAlertModale]
  );
};

export type OpenMessageModaleTrigger = (
  alertMessage: ReactChild
) => Promise<void>;

const useMessageModale = (
  type: MessageModaleType
): OpenMessageModaleTrigger => {
  const setMessageModale = useModale();
  return useCallback(
    (message: ReactChild) => {
      return new Promise((resolve: () => void): void => {
        const node = (
          <MessageModaleContent
            type={type}
            onClose={() => {
              setMessageModale(null);
              resolve();
            }}
          >
            {message}
          </MessageModaleContent>
        );

        setMessageModale({
          node: node,
          isClosingExplicitOnly: true,
        });
      });
    },
    [setMessageModale, type]
  );
};

export const useAlertModale = (): OpenMessageModaleTrigger => {
  return useMessageModale(MessageModaleType.ALERT);
};

export const useSuccessModale = (): OpenMessageModaleTrigger => {
  return useMessageModale(MessageModaleType.SUCCESS);
};

export type OpenReasonModaleTrigger = (
  message: string,
  placeHolder: string,
  onValidation: (reason: string) => void
) => void;
export const useConfirmationModaleWithReason = (): OpenReasonModaleTrigger => {
  const setAlertModale = useModale();

  return useCallback(
    (
      message: string,
      placeHolder: string,
      onValidation: (reason: string) => void
    ) => {
      const node = (
        <ReasonModaleContent
          message={message}
          reasonPlaceHolder={placeHolder}
          onValidation={(reason: string) => {
            onValidation(reason);
            setAlertModale(null);
          }}
          onCancel={() => {
            setAlertModale(null);
          }}
        />
      );

      setAlertModale({
        node: node,
        isClosingExplicitOnly: false,
      });
    },
    [setAlertModale]
  );
};
