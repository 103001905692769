import {
  ActiviteDto2020,
  ActiviteDto2020SystemeCoordonneesEnum,
  AdministratifDto20Now,
  DeclarantDto,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
} from "api/gen";
import {
  ActivityValues,
  AdministrationValues,
  CoordonneesDepartementMap,
  getUnitsEnumLabel,
  units,
} from "./types";
import { Declarants, DeclarantType } from "../DeclarantIdentityList/types";
import uuid from "uuid";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration2020 } from "../../versionedElements/declarationHooks2020";
import {
  transformActiviteToReferenceEnum,
  transformReferenceToActiviteEnum,
} from "./systemCoordinatesUtils";

const getCityNameById = (
  cityRef: ReferenceItemGeoCodeInseeDto[],
  cityId: string
) => {
  const city = findElementMatchingTemplate({ uuid: cityId }, cityRef);
  return city ? city.nomCommune : null;
};

const getApeNameById = (apeRef: ReferenceItemCodeApeDto[], apeId: string) => {
  const ape = findElementMatchingTemplate({ uuid: apeId }, apeRef);
  return ape ? ape.codeApe : null;
};

//  helper functions
export const computeFrenchCityWithReferential = (
  commune: string | null,
  referentiel: ReferenceItemGeoCodeInseeDto[]
): ReferenceItemGeoCodeInseeDto | null => {
  return referentiel.find(ref => ref.nomCommune === commune) || null;
};

const computeActiviteInitialAPECodeValue = (
  codeAPE: string | null,
  referentiel: ReferenceItemCodeApeDto[]
): ReferenceItemCodeApeDto | null => {
  return referentiel.find(ref => ref.codeApe === codeAPE) || null;
};

export const createDeclarantsDto = (
  values: DeclarantType[]
): DeclarantDto[] => {
  const returnedDto: DeclarantDto[] = values.map(declarant => {
    if (declarant.id === null) {
      throw Error("Should not happen");
    }

    const result: DeclarantDto = {
      id: declarant.id,
      prenom: declarant.firstName,
      nom: declarant.lastName,
      telephone: declarant.phone,
      email: declarant.email,
      role: declarant.declarantRoleInEnterprise,
    };
    return result;
  });
  return returnedDto;
};

export const createActivityDto = (values: ActivityValues): ActiviteDto2020 => {
  if (values.inspectionCode) {
    const returnedDto: ActiviteDto2020 = {
      codeInspection: values.inspectionCode,
      nomEtablissement: values.establishmentName,
      installationAdresse: values.installationAdress,
      installationCity: values.installationCity && values.installationCity.uuid,
      installationCodePostal: values.installationPostalCode,
      siret: values.SIRETNumber,
      codeNafID: values.NAFCode && values.NAFCode.uuid,
      systemeCoordonnees:
        values.systemCoordinates &&
        transformReferenceToActiviteEnum(
          values.systemCoordinates.systemeCoordonnees
        ),
      abscisse: values.longitude,
      ordonnee: values.latitude,
      volumeProduction:
        values.productionVolume !== undefined ? values.productionVolume : null,
      unite: (values.unit && getUnitsEnumLabel(values.unit)) || null,
      matiereProduite: values.producedMatter,
      nombreHeureDeFonctionnement: values.workDuration,
      employe: values.employee !== undefined ? values.employee : null,
      website: values.website || null,
      informationsComplementaires: values.additionalInfo || null,
    };

    return returnedDto;
  }
  throw Error("Code inspection is missing.");
};

export const isCountryFrance = (country: string): boolean => {
  return country.toLowerCase() === "france";
};

export const createAdministratifDto = (
  values: AdministrationValues
): AdministratifDto20Now => {
  // Country
  const isFrance =
    values.country !== null && isCountryFrance(values.country.designation);
  const countryId = isFrance ? "FRANCE" : values.country && values.country.uuid;

  // City
  let cityId: string | null = null;
  let cityString: string | null = null;
  if (typeof values.city === "string") {
    cityString = values.city;
  } else {
    cityId = values.city && values.city.uuid;
  }
  // France's communes should have an id. Other communes should be pure string.
  const commune = isFrance ? cityId : cityString;

  const returnedDto: AdministratifDto20Now = {
    raisonSociale: values.socialReason,
    formeJuridique: values.legalStatus,
    societeMere: values.parentCompany,
    adresse: values.adress,
    codePostal: values.postalCode,
    commune: commune,
    pays: countryId,
    siren: values.SIRENNumber,
  };

  return returnedDto;
};

export const computeDeclarantInitialValues: (
  declarationDto: Declaration2020
) => Declarants = (declarationDto: Declaration2020) => {
  const returnedResult: Declarants = {
    declarants: [
      {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        declarantRoleInEnterprise: "",
        id: uuid(),
      },
    ],
  };

  if (declarationDto.body.sections.infoGenerale.declarants.length !== 0) {
    returnedResult.declarants = declarationDto.body.sections.infoGenerale.declarants.map(
      declarant => {
        const result: DeclarantType = {
          id: declarant.id,
          firstName: declarant.prenom,
          lastName: declarant.nom,
          email: declarant.email,
          phone: declarant.telephone,
          declarantRoleInEnterprise: declarant.role,
        };
        return result;
      }
    );
  }

  return returnedResult;
};

export const computeActiviteInitialValues = (
  declarationDto: Declaration2020,
  inspectionCode: string,
  cityReferentiels: ReferenceItemGeoCodeInseeDto[],
  apeCodeReferentiels: ReferenceItemCodeApeDto[],
  coordonneesDepartementMap: CoordonneesDepartementMap
): ActivityValues => {
  const cityName = getCityNameById(
    cityReferentiels,
    declarationDto.body.sections.infoGenerale.activite.installationCity || ""
  );

  const city = computeFrenchCityWithReferential(cityName, cityReferentiels);

  const NAFCodeName = getApeNameById(
    apeCodeReferentiels,
    declarationDto.body.sections.infoGenerale.activite.codeNafID || ""
  );

  const NAFCode = computeActiviteInitialAPECodeValue(
    NAFCodeName,
    apeCodeReferentiels
  );

  const postalCode: string | null =
    declarationDto.body.sections.infoGenerale.activite.installationCodePostal;

  const systemeCoordonnees: ActiviteDto2020SystemeCoordonneesEnum | null =
    declarationDto.body.sections.infoGenerale.activite.systemeCoordonnees;

  const numeroDepartement: string | null = city && city.numeroDepartement;

  const availableReferenceItems: Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  > =
    (numeroDepartement && coordonneesDepartementMap.get(numeroDepartement)) ||
    new Map();

  const referenceItem: ReferenceItemCoordonneesDepartementDto | null =
    (systemeCoordonnees &&
      availableReferenceItems.get(
        transformActiviteToReferenceEnum(systemeCoordonnees)
      )) ||
    null;

  const returnedResult: ActivityValues = {
    inspectionCode: inspectionCode,
    establishmentName:
      declarationDto.body.sections.infoGenerale.activite.nomEtablissement,
    installationAdress:
      declarationDto.body.sections.infoGenerale.activite.installationAdresse,
    installationPostalCode: postalCode,
    installationCity: city,
    SIRETNumber: declarationDto.body.sections.infoGenerale.activite.siret,
    NAFCode: NAFCode,
    systemCoordinates: referenceItem,
    longitude: declarationDto.body.sections.infoGenerale.activite.abscisse,
    latitude: declarationDto.body.sections.infoGenerale.activite.ordonnee,
    productionVolume:
      declarationDto.body.sections.infoGenerale.activite.volumeProduction,
    unit:
      units.find(
        unit =>
          getUnitsEnumLabel(unit) ===
          declarationDto.body.sections.infoGenerale.activite.unite
      ) || null,
    producedMatter:
      declarationDto.body.sections.infoGenerale.activite.matiereProduite,
    workDuration:
      declarationDto.body.sections.infoGenerale.activite
        .nombreHeureDeFonctionnement,
    employee: declarationDto.body.sections.infoGenerale.activite.employe,
    website: declarationDto.body.sections.infoGenerale.activite.website,
    additionalInfo:
      declarationDto.body.sections.infoGenerale.activite
        .informationsComplementaires,
  };
  return returnedResult;
};

export const computeAdminInitialValues = (
  declarationDto: Declaration2020,
  countryReferentiels: ReferenceItemPaysDto[],
  cityReferentiels: ReferenceItemGeoCodeInseeDto[]
): AdministrationValues => {
  const administratif = declarationDto.body.sections.infoGenerale.administratif;

  // Country
  const isFrance =
    administratif.pays !== null && isCountryFrance(administratif.pays);
  let country = null;

  if (administratif.pays !== null) {
    country = isFrance
      ? // France has no id, should be found by its label
        findElementMatchingTemplate(
          { designation: "FRANCE" },
          countryReferentiels
        )
      : // Other countries should have an id
        findElementMatchingTemplate(
          { uuid: administratif.pays },
          countryReferentiels
        );
  }

  // City
  const commune = administratif.commune;
  let city = null;

  if (commune !== null) {
    city = isFrance
      ? // France's cities should be given as an id
        findElementMatchingTemplate({ uuid: commune }, cityReferentiels)
      : // Cities from another country should be pure string
        commune;
  }

  const returnedResult: AdministrationValues = {
    socialReason: administratif.raisonSociale,
    adress: administratif.adresse,
    postalCode: administratif.codePostal,
    parentCompany: administratif.societeMere,
    legalStatus: administratif.formeJuridique,
    city: city,
    country: country,
    SIRENNumber: administratif.siren,
  };
  return returnedResult;
};
