import { FamilyProductionProps, FamilyRecyclingProps } from "./types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import _ from "lodash";
import { Nullable } from "common/utils/types";
import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const handleFamiliesReclyclingCommonFormSingleEntitySubmit = (
  values: FamilyRecyclingProps
) => {
  values.usageSubFamiliesLvl2.forEach((_, i) => {
    if (!values.usageSubFamiliesLvl2Precisions[i]) {
      values.usageSubFamiliesLvl2Precisions[i] = { precision: "" };
    }
  });

  if (
    values.usageSubFamiliesLvl2Precisions.length === 1 &&
    !values.usageSubFamiliesLvl2Precisions[0].precision
  ) {
    values.usageSubFamiliesLvl2Precisions = [];
  }
};

export const handleFamiliesProductionCommonFormSingleEntitySubmit = (
  values: FamilyProductionProps
) => {
  values.usageSubFamiliesLvl2.forEach((_, i) => {
    if (!values.usageSubFamiliesLvl2Precisions[i]) {
      values.usageSubFamiliesLvl2Precisions[i] = { precision: "" };
    }
  });

  if (
    values.usageSubFamiliesLvl2Precisions.length === 1 &&
    !values.usageSubFamiliesLvl2Precisions[0].precision
  ) {
    values.usageSubFamiliesLvl2Precisions = [];
  }
};

export const onSelectFamilyHandler = (
  setFieldValue: (field: string, value: any) => void
) => {
  setFieldValue("usageFamilyPrecision", null);
  setFieldValue("usageSubFamily", null);
  setFieldValue("usageSubFamilyPrecision", null);
  setFieldValue("usageSubFamiliesLvl2", []);
  setFieldValue("usageSubFamiliesLvl2Precisions", []);
};

export const onSelectSubFamilyHandler = (
  setFieldValue: (field: string, value: any) => void
) => {
  setFieldValue("usageSubFamilyPrecision", null);
  setFieldValue("usageSubFamiliesLvl2", []);
  setFieldValue("usageSubFamiliesLvl2Precisions", []);
};

// TODO : https://dl.polyconseil.fr/jira/browse/GEREP-1268
export const onSelectSubFamilyLvl2Handler = (
  setFieldValue: (field: string, value: any) => void,
  selected: OptionPropsWithObject<
    | ReferenceItemFamilleUsageRecyclageDto
    | ReferenceItemFamilleUsageProductionDto
  >[],
  values: Nullable<FamilyProductionProps | FamilyRecyclingProps>
) => {
  setFieldValue(
    "usageSubFamiliesLvl2Precisions",
    selected
      .filter(subFamilyLvl2 => subFamilyLvl2.object.precision)
      .map((currentValue, index) => {
        return {
          precision: _.get(
            values,
            `usageSubFamiliesLvl2Precisions[${index}].precision`,
            ""
          ),
        };
      })
  );
};
