import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";
import { Declaration2020, MAX_YEAR, MIN_YEAR } from "../declarationHooks2020";
import { useCallback, useMemo } from "react";
import { FileHandlers } from "common/FilesLoader/types";

export const usePdsFileHandler2020 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPds2020UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePds2020UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useRapportEmissionsFileHandler2020 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadRapportEmissions2020UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteRapportEmissions2020UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useEmissionsRapportVerificationFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadEmissionsRapportVerificationUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteEmissionsRapportVerificationUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useEmissionsRapportOfficeFileHandler2020 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadEmissionsRapportOffice2020UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteEmissionsRapportOffice2020UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const usePmsFileHandler2020 = (nimDynId: string): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPms2020UsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePms2020UsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAlcPreliminaireFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadAlcPreliminaireUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      )) as Declaration2020;
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteAlcPreliminaireUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      )) as Declaration2020;
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifAlcPreliminaireFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadJustificatifAlcPreliminaireUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      )) as Declaration2020;
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteJustificatifAlcPreliminaireUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      )) as Declaration2020;
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAlcVerifieFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAlcVerifie2020UsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAlcVerifie2020UsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifAlcVerifieFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadJustificatifAlcVerifie2020UsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteJustificatifAlcVerifie2020UsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAllocationsRapportVerificationFileHandler2020 = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAllocationsRapportVerificationUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAllocationsRapportVerificationUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAllocationsRapportOfficeFileHandler2020 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAllocationsRapportOffice2020UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAllocationsRapportOffice2020UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration2020>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration2020>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
