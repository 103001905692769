import {
  ProduitRecyclageDto1822,
  ReferenceFamilleUsageRecyclageDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";
import { PopulatedRecyclingProductInArray } from "./types";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { natures } from "./selectPossibleValues";
import {
  convertStringFamilyRecyclingToOptionProps,
  convertStringSubFamilyRecyclingToOptionProps,
  convertStringSubFamilyLvl2RecyclingToOptionProps,
} from "../../Families/converters";

export const convertNatureStringToOptionProps = (
  natureLabel: string
): OptionProps | undefined => {
  return natures.find(n => n.label === natureLabel);
};

export const convertProduitRecyclageDtoToDisplayed = (
  recyclingProductsDto: ProduitRecyclageDto1822[],
  referentialFamilies: ReferenceFamilleUsageRecyclageDto
): PopulatedRecyclingProductInArray[] => {
  return recyclingProductsDto.map(recyclingProduct => {
    const family = convertStringFamilyRecyclingToOptionProps(
      recyclingProduct.familleId,
      referentialFamilies
    );
    const subFamily = convertStringSubFamilyRecyclingToOptionProps(
      recyclingProduct.sousFamilleId,
      family
    );

    const subFamilyLvl2s: {
      usageSubFamiliesLvl2: OptionPropsWithObject<
        ReferenceItemFamilleUsageRecyclageDto,
        number
      >;
      usageSubFamiliesLvl2Precisions: string;
    }[] = recyclingProduct.sousFamillesNiveau2.flatMap(subFamilyLvl2 => {
      const subFamilyLvl2OptionProps: OptionPropsWithObject<
        ReferenceItemFamilleUsageRecyclageDto
      > | null = convertStringSubFamilyLvl2RecyclingToOptionProps(
        subFamilyLvl2.identifier,
        subFamily
      );

      if (subFamilyLvl2OptionProps === null) {
        return [];
      }

      return [
        {
          usageSubFamiliesLvl2: subFamilyLvl2OptionProps,
          usageSubFamiliesLvl2Precisions: subFamilyLvl2.precisionSiAutre,
        },
      ];
    });

    return {
      id: recyclingProduct.id,
      displayedProduct: {
        nature:
          convertNatureStringToOptionProps(recyclingProduct.nature) ||
          natures[0],
        naturePrecision: recyclingProduct.precisionAutre,
        productionQuantity: recyclingProduct.quantiteAnnuelleProduite,
        usageFamily: family,
        usageFamilyPrecision: recyclingProduct.precisionFamille,
        usageSubFamily: subFamily,
        usageSubFamilyPrecision: recyclingProduct.precisionSousFamilleNiveau1,
        usageSubFamiliesLvl2: subFamilyLvl2s.map(
          subFamilyLvl2 => subFamilyLvl2.usageSubFamiliesLvl2
        ),
        usageSubFamiliesLvl2Precisions: subFamilyLvl2s.map(subFamilyLvl2 => ({
          precision: subFamilyLvl2.usageSubFamiliesLvl2Precisions,
        })),
      },
    };
  });
};

export const convertDisplayedToProduitRecyclageDto = (
  recyclingProducts: PopulatedRecyclingProductInArray[]
): ProduitRecyclageDto1822[] => {
  return recyclingProducts.map(recyclingProduct => {
    return {
      id: recyclingProduct.id,
      nature: recyclingProduct.displayedProduct.nature.label,
      precisionAutre: recyclingProduct.displayedProduct.naturePrecision,
      quantiteAnnuelleProduite:
        recyclingProduct.displayedProduct.productionQuantity,
      familleId: recyclingProduct.displayedProduct.usageFamily
        ? recyclingProduct.displayedProduct.usageFamily.object.uuid
        : "",
      precisionFamille: recyclingProduct.displayedProduct.usageFamilyPrecision,
      sousFamilleId: recyclingProduct.displayedProduct.usageSubFamily
        ? recyclingProduct.displayedProduct.usageSubFamily.object.uuid
        : null,
      precisionSousFamilleNiveau1:
        recyclingProduct.displayedProduct.usageSubFamilyPrecision,
      sousFamillesNiveau2: recyclingProduct.displayedProduct.usageSubFamiliesLvl2.map(
        (subFamilyLvl2, i) => {
          return {
            identifier: subFamilyLvl2.object.uuid,
            precisionSiAutre: recyclingProduct.displayedProduct
              .usageSubFamiliesLvl2Precisions[i]
              ? recyclingProduct.displayedProduct
                  .usageSubFamiliesLvl2Precisions[i].precision
              : "",
          };
        }
      ),
    };
  });
};
