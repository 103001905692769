import React from "react";
import { MethodeAlternativeDto } from "api/gen";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import { computeEmissionCell } from "../arrayUtils";

const useStyles = makeStyles({
  full: {
    display: "flex",
    alignItems: "center",
  },
  arrayContainer: {
    width: "65%",
  },
});

interface MethodeAlternativeArraysProps {
  methodeAlternativeDto: MethodeAlternativeDto;
}

const MethodeAlternativeArray = ({
  methodeAlternativeDto,
}: MethodeAlternativeArraysProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleEmissionLine = (
    methodeAlternativeDto: MethodeAlternativeDto,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(
        computeEmissionCell(methodeAlternativeDto.emissionsFossiles),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeAlternativeDto.emissionsBiomasseDurable),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeAlternativeDto.emissionsBiomasseNonDurable),
        classes
      ),
    ];
  };

  const emissionLines = [
    computeSingleEmissionLine(methodeAlternativeDto, classes),
  ];

  return (
    <>
      <ArrayTitle>Méthode alternative (tonnes)</ArrayTitle>
      <CommonEntityTable
        header={[
          <p>Émissions de CO2 fossiles</p>,
          <p>Émissions de CO2 biomasse durable</p>,
          <p>Émissions de CO2 biomasse non durable</p>,
        ]}
        lines={emissionLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[1, 1, 1]}
        additionalClassName={classes.arrayContainer}
      />
    </>
  );
};

export default MethodeAlternativeArray;
