import {
  MesurePoussiereAtmospheriqueDto20Now,
  MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum,
} from "api/gen";
import { DustFalloutForm, DustFalloutPointInArray } from "./types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { selectPossibleValues } from "./selectPossibleValues";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";

export const convertDisplayedToDustFalloutPoint = (
  populatedEmissionPoints: DustFalloutPointInArray[]
): MesurePoussiereAtmospheriqueDto20Now[] => {
  return populatedEmissionPoints.map(singlePopulatedEmissionPoint => {
    const getLocationType = (): MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum | null => {
      if (!singlePopulatedEmissionPoint.data.locationType) {
        return null;
      }
      return singlePopulatedEmissionPoint.data.locationType.object;
    };
    return {
      id: singlePopulatedEmissionPoint.data.id,
      jauge:
        singlePopulatedEmissionPoint.data.gaugeOrBooklet ===
        selectPossibleValues.gaugeOrBooklet[0],
      premierTrimestre: singlePopulatedEmissionPoint.data.firstTrimester,
      secondTrimestre: singlePopulatedEmissionPoint.data.secondTrimester,
      troisiemeTrimestre: singlePopulatedEmissionPoint.data.thirdTrimester,
      quatriemeTrimestre: singlePopulatedEmissionPoint.data.fourthTrimester,
      libelle: singlePopulatedEmissionPoint.data.name,
      typeDEmplacement: getLocationType(),
    };
  });
};

export const convertDustFalloutPointToDisplayed = (
  emissionPoints: MesurePoussiereAtmospheriqueDto20Now[]
): DustFalloutPointInArray[] => {
  return emissionPoints.map(singleEmissionPoint => {
    const getLocationType = (): OptionPropsWithObject<
      MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum
    > | null => {
      if (singleEmissionPoint.typeDEmplacement !== null) {
        return (
          selectPossibleValues.locationType.find(
            locationType =>
              locationType.object === singleEmissionPoint.typeDEmplacement
          ) || null
        );
      }
      return null;
    };
    return {
      data: {
        id: singleEmissionPoint.id,
        name: singleEmissionPoint.libelle,
        locationType: getLocationType(),
        gaugeOrBooklet: singleEmissionPoint.jauge
          ? selectPossibleValues.gaugeOrBooklet[0]
          : selectPossibleValues.gaugeOrBooklet[1],
        firstTrimester: singleEmissionPoint.premierTrimestre,
        secondTrimester: singleEmissionPoint.secondTrimestre,
        thirdTrimester: singleEmissionPoint.troisiemeTrimestre,
        fourthTrimester: singleEmissionPoint.quatriemeTrimestre,
      },
      errors: {},
    };
  });
};

export const convertDtoToDustFalloutForm = (
  declaration: Declaration20Now
): DustFalloutForm => {
  return {
    doneMeasures: !!declaration.body.sections.carriere.environnement
      .poussiereAtmospherique.mesureEffectuee,
    inheritedCareer: !!declaration.body.sections.carriere.environnement
      .poussiereAtmospherique.doitEffectuerMesures,
  };
};
