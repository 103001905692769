import React, { ReactElement } from "react";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { makeStyles } from "@material-ui/styles";
import { PopulatedBuildingInArray } from "../utils/types";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { PINK } from "theme";
import Button from "common/button";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { CleanOptionProps } from "common/form/fields/types/basicTypes";
import { PATH_AIR_ELEVAGE_DETAIL_BATIMENT } from "common/path/path18Now";
import Row from "common/presentational/Row";

interface EmissionsArrayProps {
  species: CleanOptionProps[];
  buildingsInArray: PopulatedBuildingInArray[];
  setBuildingsInArray: (buildings: PopulatedBuildingInArray[]) => void;
  onEdit: (batiment: PopulatedBuildingInArray) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  array: {
    marginTop: "20px",
  },
  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const EmissionsArray = ({
  species,
  buildingsInArray,
  setBuildingsInArray,
  onEdit,
  isDisabled,
}: EmissionsArrayProps) => {
  const classes = useStyles();

  const header = [<p>Nom du bâtiment</p>];
  header.push(...species.map(s => <p>Émissions de NH3 des {s.label}</p>));
  header.push(<p>Actions</p>);

  const lines = buildingsInArray.map((building: PopulatedBuildingInArray) => {
    const render: ReactElement[] = [
      <span
        onClick={() => {
          if (!isDisabled) {
            onEdit(building);
          }
        }}
        className={isDisabled ? "" : classes.link}
        title={building.displayedBuilding.buildingName}
      >
        {building.displayedBuilding.buildingName}
      </span>,
    ];
    species.forEach(s => {
      const index = selectPossibleValues.speciesMapper[s.value];
      const value = computeNumberWithSeparator(
        building.displayedBuilding[index]
      );
      render.push(<p>{`${value} kg/an/emplacement`}</p>);
    });
    render.push(
      <CommonEntityButton
        handlerEdit={() => onEdit(building)}
        suppressMessage={"êtes vous sûr de vouloir supprimer ce batiment ?"}
        handlerSuppress={() =>
          setBuildingsInArray(
            buildingsInArray.filter(b => b.id !== building.id)
          )
        }
        isValidated={isDisabled}
        commentPath={`${PATH_AIR_ELEVAGE_DETAIL_BATIMENT}/${building.id}`}
      />
    );
    return render;
  });

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredHeaderHeight={75}
        additionalClassName={classes.array}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setBuildingsInArray([])}
        />
      </Row>
    </>
  );
};

export default EmissionsArray;
