import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageRecyclageDto,
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";
import {
  familiesProduction,
  familiesRecycling,
  subFamiliesProduction,
  subFamiliesProductionLvl2,
  subFamiliesRecycling,
  subFamiliesRecyclingLvl2,
} from "./selectPossibleValues";

export const convertFamilyProductionNameToOptionProps = (
  familyName: string | null,
  referential: ReferenceFamilleUsageProductionDto
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto> | null => {
  return (
    familiesProduction(referential).find(
      family => family.object.libelleCourt === familyName
    ) || null
  );
};

export const convertStringFamilyProductionToOptionProps = (
  familyUuid: string | null,
  referential: ReferenceFamilleUsageProductionDto
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto> | null => {
  return (
    familiesProduction(referential).find(
      family => family.object.uuid === familyUuid
    ) || null
  );
};

export const convertStringFamilyRecyclingToOptionProps = (
  familyUuid: string | null,
  referential: ReferenceFamilleUsageRecyclageDto
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto> | null => {
  return (
    familiesRecycling(referential).find(
      family => family.object.uuid === familyUuid
    ) || null
  );
};

export const convertStringSubFamilyProductionToOptionProps = (
  subFamilyUuid: string | null,
  family: OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto> | null
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto> | null => {
  if (!family) {
    return null;
  }
  return (
    subFamiliesProduction(family).find(
      subFamily => subFamily.object.uuid === subFamilyUuid
    ) || null
  );
};

export const convertStringSubFamilyRecyclingToOptionProps = (
  subFamilyUuid: string | null,
  family: OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto> | null
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto> | null => {
  if (!family) {
    return null;
  }
  return (
    subFamiliesRecycling(family).find(
      subFamily => subFamily.object.uuid === subFamilyUuid
    ) || null
  );
};

export const convertStringSubFamilyLvl2ProductionToOptionProps = (
  subFamilyLvl2Uuid: string | null,
  subFamily: OptionPropsWithObject<
    ReferenceItemFamilleUsageProductionDto
  > | null
): OptionPropsWithObject<ReferenceItemFamilleUsageProductionDto> | null => {
  if (!subFamily) {
    return null;
  }
  return (
    subFamiliesProductionLvl2(subFamily).find(
      subFamilyLvl2 => subFamilyLvl2.object.uuid === subFamilyLvl2Uuid
    ) || null
  );
};

export const convertStringSubFamilyLvl2RecyclingToOptionProps = (
  subFamilyLvl2Uuid: string | null,
  subFamily: OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto> | null
): OptionPropsWithObject<ReferenceItemFamilleUsageRecyclageDto> | null => {
  if (!subFamily) {
    return null;
  }
  return (
    subFamiliesRecyclingLvl2(subFamily).find(
      subFamilyLvl2 => subFamilyLvl2.object.uuid === subFamilyLvl2Uuid
    ) || null
  );
};
