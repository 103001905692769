import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { LONG_TEXT_INPUT_WIDTH } from "theme";

import { FluidInModale, hfcFluidFieldMatcher } from "../utils/types";
import {
  isGazAutre,
  overwriteDependantFields,
  shouldDisplayJustify,
  singleFluidValidationSchema,
  validateHFC,
} from "../validation/validation";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import MessageInfoField from "common/form/MessageInfoField";
import { ReferenceItemGazDto } from "api/gen";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { AUTRE_UUID } from "../utils/selectPossibleValues";
import { getRoundedValueOrNullIfZero } from "common/utils/numberUtils";
import { computeOtherPercentage } from "../utils/utils";

interface FormSingleFluidProps {
  closeFunction: () => void;
  onSubmit: (values: FluidInModale) => void;
  selectPossibleValues: ReferenceItemGazDto[];
  initialFluid: FluidInModale;
  isEdit: boolean;
  forbiddenAutreFluidNames: string[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  select: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  title: {
    fontSize: "16px",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    minHeight: "38px",
    "& > div": {
      justifyContent: "center",
    },
  },
});

const FormSingleFluid = ({
  closeFunction,
  onSubmit,
  selectPossibleValues,
  initialFluid,
  isEdit,
  forbiddenAutreFluidNames,
}: FormSingleFluidProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-GazFluores-Fluid-individuel",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const DumbNumberField = useDummyNumberFieldGenerator({
    ...commonProps,
    disabled: true,
  });

  return (
    <CommonFormSingleEntity<FluidInModale>
      title={"AJOUTER UN FLUIDE"}
      isEdit={isEdit}
      closeFunction={closeFunction}
      onSubmit={values => {
        overwriteDependantFields(values);
        if (!shouldDisplayJustify(values.capacite, values.quantite)) {
          values.justification = null;
        }
        onSubmit(values);
      }}
      initialEntity={initialFluid}
      validationSchema={singleFluidValidationSchema}
      validate={values => validateHFC(values, forbiddenAutreFluidNames)}
      renderField={({ values, errors, setFieldValue }) => {
        const isOtherSelected = isGazAutre(values.fluid);
        return (
          <>
            <WrappedChoiceSelect
              name={hfcFluidFieldMatcher.fluid}
              label="Nom du fluide *"
              isMulti={false}
              options={selectPossibleValues}
              computeLabel={fluid => fluid.nom}
              commonProps={commonProps}
              additionalClassName={classes.select}
              additionalOnChange={choice => {
                if (!choice || choice.uuid === AUTRE_UUID) {
                  setFieldValue(hfcFluidFieldMatcher.HFC32, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC125, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC134a, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC143a, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC152a, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC227ea, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC1234yf, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC1234ze, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC23, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC41, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC4310mee, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC134, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC143, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC236fa, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC245ca, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC245fa, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC365mfc, null);
                  setFieldValue(hfcFluidFieldMatcher.HFC1233zd, null);
                } else {
                  const factor = 100;
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC32,
                    getRoundedValueOrNullIfZero(factor * choice.hfc32)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC125,
                    getRoundedValueOrNullIfZero(factor * choice.hfc125)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC134a,
                    getRoundedValueOrNullIfZero(factor * choice.hfc134a)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC143a,
                    getRoundedValueOrNullIfZero(factor * choice.hfc143a)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC152a,
                    getRoundedValueOrNullIfZero(factor * choice.hfc152a)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC227ea,
                    getRoundedValueOrNullIfZero(factor * choice.hfc227ea)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC1234yf,
                    getRoundedValueOrNullIfZero(factor * choice.hfc1234yf)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC1234ze,
                    getRoundedValueOrNullIfZero(factor * choice.hfc1234ze)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC23,
                    getRoundedValueOrNullIfZero(factor * choice.hfc23)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC41,
                    getRoundedValueOrNullIfZero(factor * choice.hfc41)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC4310mee,
                    getRoundedValueOrNullIfZero(factor * choice.hfc4310mee)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC134,
                    getRoundedValueOrNullIfZero(factor * choice.hfc134)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC143,
                    getRoundedValueOrNullIfZero(factor * choice.hfc143)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC236fa,
                    getRoundedValueOrNullIfZero(factor * choice.hfc236fa)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC245ca,
                    getRoundedValueOrNullIfZero(factor * choice.hfc245ca)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC245fa,
                    getRoundedValueOrNullIfZero(factor * choice.hfc245fa)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC365mfc,
                    getRoundedValueOrNullIfZero(factor * choice.hfc365mfc)
                  );
                  setFieldValue(
                    hfcFluidFieldMatcher.HFC1233zd,
                    getRoundedValueOrNullIfZero(factor * choice.hfc1233zd)
                  );
                }
              }}
            />
            {isGazAutre(values.fluid) && (
              <TextField
                label={"Précisez le nom du fluide *"}
                name={hfcFluidFieldMatcher.preciser}
              />
            )}
            <NumberField
              name={hfcFluidFieldMatcher.emission}
              label="Quantité de fluide émise *"
              unit="kg/an"
            />
            <NumberField
              name={hfcFluidFieldMatcher.capacity}
              label="Capacité installée"
              unit="kg/an"
            />
            {shouldDisplayJustify(values.capacite, values.quantite) && (
              <TextField
                name={hfcFluidFieldMatcher.justify}
                label="Justification *"
              />
            )}
            <MessageInfoField
              message="Composition"
              additionalClassname={classes.title}
            />
            <NumberField
              name={hfcFluidFieldMatcher.HFC1233zd}
              label="HFC-1233zd"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc1233zd)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC1234yf}
              label="HFC-1234yf"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc1234yf)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC1234ze}
              label="HFC-1234ze"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc1234ze)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC125}
              label="HFC-125"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc125)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC134}
              label="HFC-134"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc134)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC134a}
              label="HFC-134a"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc134a)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC143}
              label="HFC-143"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc143)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC143a}
              label="HFC-143a"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc143a)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC152a}
              label="HFC-152a"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc152a)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC227ea}
              label="HFC-227ea"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc227ea)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC23}
              label="HFC-23"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc23)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC236fa}
              label="HFC-236fa"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc236fa)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC245ca}
              label="HFC-245ca"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc245ca)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC245fa}
              label="HFC-245fa"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc245fa)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC32}
              label="HFC-32"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc32)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC365mfc}
              label="HFC-365mfc"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc365mfc)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC41}
              label="HFC-41"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc41)}
            <NumberField
              name={hfcFluidFieldMatcher.HFC4310mee}
              label="HFC-4310mee"
              unit="%"
              disabled={!isOtherSelected}
            />
            {generateWarningMessageIfUnder1Percent(values.hfc4310mee)}
            <DumbNumberField
              name={""}
              label="Autres composés"
              unit="%"
              disabled={true}
              value={computeOtherPercentage(values)}
            />
          </>
        );
      }}
    />
  );
};

export default FormSingleFluid;
