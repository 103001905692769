import {
  DechetProduitDto1819LieuOperationEnum,
  DeclarationBodyDto1819,
  DeclarationSectionsDto1819,
  TypeActiviteDto1819,
} from "api/gen/api";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
  generateReceivedPolluant,
} from "./Generators";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../PolluantProduction/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../PolluantReception/types";
import { WasteReferentiels, WasteTabData } from "../types";
import _ from "lodash";
import { isNotificationNumberAccessibleForProduction } from "../PolluantProduction/submitHandler";
import { convertReceptionStoredToModal } from "../ReceptionModale/converter";
import { isNotificationNumberAccessibleForReception } from "../PolluantReception/submitHandler";
import { convertProductionStoredToModalData } from "../ProductionModale/converter";
import { DataWithErrors } from "common/form/utils";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";

export const shouldDisplayProductionArray = (
  productionInfo: ProductedWasteFormData
): boolean => {
  return (
    productionInfo.dangerousWasteOver2000kg ||
    (productionInfo.isEPRTR && productionInfo.atLeastOneFacilityWasteOver2Mkg)
  );
};
export const shouldDisplayReceptionArray = (
  receptionInfo: ReceivedWasteFormData
): boolean => {
  return receptionInfo.isFacilityManagingWaste;
};

//  delete waste from container and return new container and removed index
export function deleteWasteFromContainer<T>(data: T, container: T[]): T[] {
  return container.filter(item => !_.isEqual(data, item));
}

//  find duplicate in array
export const isProductedWasteInContainer = (
  waste: ProductedWasteStorage,
  container: ProductedWasteStorage[],
  referentiels: WasteReferentiels
): boolean => {
  //  we have to convert stored data (aka back office model data) to modal data in order to use the same methods in the modal and in this function
  const converted = convertProductionStoredToModalData(
    waste.data,
    referentiels
  );

  //  allow duplicates with errors
  if (
    !waste.data.codeID ||
    !waste.data.operationElimination ||
    !waste.data.lieuOperation
  ) {
    return false;
  } else if (
    waste.data.lieuOperation ===
      DechetProduitDto1819LieuOperationEnum.DEPARTEMENT &&
    !(
      waste.data.departementID &&
      waste.data.nomEtablissementReception &&
      waste.data.adresseEtablissementReception
    )
  ) {
    return false;
  } else if (
    waste.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.PAYS &&
    !(
      waste.data.paysID &&
      waste.data.nomEtablissementReception &&
      waste.data.adresseEtablissementReception &&
      waste.data.nomEtablissementElimination &&
      waste.data.adresseEtablissementElimination &&
      (isNotificationNumberAccessibleForProduction(converted)
        ? waste.data.numeroNotification !== null &&
          waste.data.numeroNotification !== ""
        : true)
    )
  ) {
    return false;
  }

  // If the data has no error, prevent duplication
  return container.some(test => {
    if (
      waste.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.SITE
      );
    } else if (
      waste.data.lieuOperation ===
      DechetProduitDto1819LieuOperationEnum.DEPARTEMENT
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation ===
          DechetProduitDto1819LieuOperationEnum.DEPARTEMENT &&
        test.data.departementID === waste.data.departementID &&
        test.data.nomEtablissementReception ===
          waste.data.nomEtablissementReception &&
        test.data.adresseEtablissementReception ===
          waste.data.adresseEtablissementReception
      );
    } else if (
      waste.data.lieuOperation === DechetProduitDto1819LieuOperationEnum.PAYS
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation ===
          DechetProduitDto1819LieuOperationEnum.PAYS &&
        test.data.paysID === waste.data.paysID &&
        test.data.nomEtablissementReception ===
          waste.data.nomEtablissementReception &&
        test.data.adresseEtablissementReception ===
          waste.data.adresseEtablissementReception &&
        test.data.nomEtablissementElimination ===
          waste.data.nomEtablissementElimination &&
        test.data.adresseEtablissementElimination ===
          waste.data.adresseEtablissementElimination &&
        test.data.numeroNotification === waste.data.numeroNotification
      );
    } else {
      return false;
    }
  });
};
export const isReceivedWasteInContainer = (
  waste: ReceivedWasteStorage,
  container: ReceivedWasteStorage[],
  referential: WasteReferentiels
): boolean => {
  //  allow duplication for invalid data only
  if (
    !waste.data.codeID ||
    !waste.data.operationTraitement ||
    (!waste.data.estTraiteDansUnAutrePays && !waste.data.departementID) ||
    (waste.data.estTraiteDansUnAutrePays && !waste.data.paysID)
  ) {
    return false;
  }

  // To check if numeroNotification is required or not we have to convert the data (with back office format) to modal data in order to use the same methods
  const converted = convertReceptionStoredToModal(waste.data, referential);
  if (
    isNotificationNumberAccessibleForReception(converted) &&
    !waste.data.numeroNotification
  ) {
    return false;
  }

  // If all required data is present check for unicity
  return container.some(test => {
    return (
      waste.data.codeID === test.data.codeID &&
      waste.data.sortiDuStatutDeDechet === test.data.sortiDuStatutDeDechet &&
      waste.data.operationTraitement === test.data.operationTraitement &&
      waste.data.departementID === test.data.departementID &&
      waste.data.paysID === test.data.paysID &&
      waste.data.numeroNotification === test.data.numeroNotification
    );
  });
};

/////////
//  form helpers
/////////
//  parse fetched data
export const parseFetch = (
  declarationBody: DeclarationBodyDto1819,
  referentiels: WasteReferentiels,
  typeActivite: TypeActiviteDto1819,
  receptionDechetsData: ReceivedWasteFormData | null
): [
  ProductedWasteFormData,
  ProductedWasteStorage[],
  ReceivedWasteFormData,
  ReceivedWasteStorage[]
] => {
  const declarationSections: DeclarationSectionsDto1819 =
    declarationBody.sections;

  const productionInfo: ProductedWasteFormData = {
    dangerousWasteOver2000kg:
      declarationSections.dechets.production.productionDechetDangereuxSup2000,
    atLeastOneFacilityWasteOver2Mkg: !!declarationSections.dechets.production
      .productionDechetNonDangereuxSup2000,
    isEPRTR: declarationBody.typeActivite.estEPRTR,
  };
  const productionData: ProductedWasteStorage[] = convertProductionFetchToLocal(
    declarationSections.dechets.production.productionDechet,
    referentiels
  );
  const receptionInfo: ReceivedWasteFormData = {
    isFacilityManagingWaste: declarationBody.typeActivite.recepDechet,
    isISDND: declarationBody.typeActivite.isdnd || false,
    isISDI: declarationBody.typeActivite.isdi || false,
    remainingCapacity:
      declarationSections.dechets.reception.capaciteRestante !== 0
        ? declarationSections.dechets.reception.capaciteRestante
        : null,
    doesFacilityHaveCasiersPlatre: !!declarationSections.dechets.reception
      .aCasiersPlatre,
    doesFacilityHaveAsbestosCases: !!declarationSections.dechets.reception
      .aCasiersAmiante,
  };
  const receptionData: ReceivedWasteStorage[] = convertReceptionFetchToLocal(
    declarationSections.dechets.reception.receptionDechets,
    referentiels,
    typeActivite,
    receptionDechetsData || receptionInfo
  );
  return [productionInfo, productionData, receptionInfo, receptionData];
};
//  import waste data in collection
export function useImportWaste<T, A>(
  referentiels: WasteReferentiels,
  receptionDechetsData: ReceivedWasteFormData | null,
  generator: (
    t: A,
    props: WasteReferentiels,
    typeActivite: TypeActiviteDto1819,
    receptionDechetsData: ReceivedWasteFormData | null
  ) => DataWithErrors<T>,
  setWastes: Dispatch<SetStateAction<DataWithErrors<T>[]>>,
  onImport: (() => void) | null
): (w: A[]) => void {
  const openModale = useAlertModale();
  const declaration = useDeclaration1919();
  const typeActivite = declaration.body.typeActivite;

  return useCallback(
    (w: A[]) => {
      const imported = w.map(waste => {
        return generator(
          waste,
          referentiels,
          typeActivite,
          receptionDechetsData
        );
      });
      onImport && onImport();
      setWastes(imported);

      if (
        imported.some(imported => {
          return !_.isEmpty(imported.errors);
        })
      ) {
        openModale(
          "Des erreurs ont été détectées dans votre fichier importé. Veuillez corriger les éléments encadrés en rouge dans le tableau et importer à nouveau."
        );
      }
    },
    [
      generator,
      onImport,
      setWastes,
      openModale,
      referentiels,
      typeActivite,
      receptionDechetsData,
    ]
  );
}
//  compute child visible data according to form values
export const productionDataComputed = (
  formInfo: ProductedWasteFormData,
  container: ProductedWasteStorage[]
): WasteTabData<ProductedWasteFormData, ProductedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayProductionArray(formInfo) ? container : [],
  };
};
export const receptionDataComputed = (
  formInfo: ReceivedWasteFormData,
  container: ReceivedWasteStorage[],
  productionInfo: ProductedWasteFormData,
  productionContainer: ProductedWasteStorage[]
): WasteTabData<ReceivedWasteFormData, ReceivedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayReceptionArray(formInfo)
      ? shouldDisplayProductionArray(productionInfo)
        ? [...container, ...generateReceivedPolluant(productionContainer)]
        : container
      : [],
  };
};
