import { ProduitRecyclingType } from "./types";

export const computeTotalProductionQuantities = (
  recyclingProductsInArray: ProduitRecyclingType[]
): number => {
  let total = 0;
  recyclingProductsInArray.forEach(product => {
    total += product.quantite;
  });
  return total;
};
