import { CallbackSetter } from "../../common/utils/types";
import React, { createContext, useContext } from "react";

interface AdapterFormikContextProps {
  externalFieldsNames: ReadonlySet<string>;
  setExternalFieldsMap: CallbackSetter<Map<string, unknown>>;
}

interface FormikContextComponentProps extends AdapterFormikContextProps {
  children: React.ReactElement;
}

const AdapterFormikContext = createContext<AdapterFormikContextProps | null>(
  null
);

export const useAdapterFormikContextSetter = (): CallbackSetter<Map<
  string,
  unknown
>> => {
  const context = useContext(AdapterFormikContext);
  if (context !== null) {
    return context.setExternalFieldsMap;
  } else {
    throw new Error(
      "CampaignContext Error. You probably forgot to put a <AdapterFormikContext.Adapter>"
    );
  }
};

export const FormikContext = ({
  externalFieldsNames,
  setExternalFieldsMap,
  children,
}: FormikContextComponentProps): React.ReactElement => {
  return (
    <AdapterFormikContext.Provider
      value={{ externalFieldsNames, setExternalFieldsMap }}
    >
      {children}
    </AdapterFormikContext.Provider>
  );
};

export default FormikContext;
