import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import { TEXT_LIGHT_GREY } from "theme";
import Row from "common/presentational/Row";
import Button from "common/button";
import EmissionMesureArray from "./mesure/EmissionMesureArray";
import EmissionCorrelationArray from "./correlation/EmissionCorrelationArray";
import EmissionMatiereArray from "./matiere/EmissionMatiereArray";
import EmissionModale from "./EmissionModale";
import SyntheseIntrantsArray from "./SyntheseIntrantsArray";
import {
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import {
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "./utils/utils";
import isEqual from "lodash.isequal";
import { convertCovInArrayToDto } from "../CovDanger/utils/utils";
import { CovInArray } from "../CovDanger/utils/types";
import { useConfirmationModale } from "common/modale/hooks";
import { ProcedeInArray } from "../ListProcede/utils/types";
import {
  CorrelationEmissionInArray,
  EmissionsInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import Collapsible from "common/presentational/Collapsible";
import SyntheseSortantsArray from "./SyntheseSortantsArray";
import _ from "lodash";
import { compareEmissionFunction } from "../utils/compareMethod";
import { BlocFullContext24Now } from "../../../versionedElements/BlocFullContext24Now";
import { Declaration24Now } from "../../../versionedElements/declarationHooks24Now";

const useStyles = makeStyles({
  title: {
    fontSize: "19px",
    color: TEXT_LIGHT_GREY,
  },
});

interface EmissionProps {
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>;
  matiereEmissionsInPage: MatiereEmissionInArray[];
  initialMatiere: MatiereEmissionInArray[];
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  initialCorrelation: CorrelationEmissionInArray[];
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  mesureEmissionsInPage: MesureEmissionInArray[];
  initialMesure: MesureEmissionInArray[];
  procedesInPageMap: Map<string, ProcedeInArray>;
  initialProcedes: ProcedeInArray[];
  covsInPage: CovInArray[];
  substances: ReferenceItemPolluantDto[];
  validationEmissionPath: string;
  referentialCO2: ReferenceItemPolluantDto;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElementAutreUuid: string;
  showValidationMessage: boolean;
}

const Emission = ({
  validationEmissionPath,
  setMatiereEmissionsInPage,
  initialMatiere,
  setCorrelationEmissionsInPage,
  initialCorrelation,
  setMesureEmissionsInPage,
  initialMesure,
  procedesInPageMap,
  initialProcedes,
  correlationEmissionsInPage,
  mesureEmissionsInPage,
  matiereEmissionsInPage,
  covsInPage,
  substances,
  referentialCO2,
  polluantElementMap,
  referentialElementAutreUuid,
  showValidationMessage,
}: EmissionProps): React.ReactElement => {
  const classes = useStyles();
  const [emissionModaleOpen, setEmissionModaleOpen] = useState(false);
  const [
    emissionInModale,
    setEmissionInModale,
  ] = useState<EmissionsInArray | null>(null);
  const openConfirmationModale = useConfirmationModale();

  const updateHandler = (declaration: Declaration24Now) => {
    declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
      correlationEmissionsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
      mesureEmissionsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
      matiereEmissionsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.flux = convertCovInArrayToDto(
      covsInPage,
      procedesInPageMap
    );
    return declaration;
  };

  const validationMessage = {
    message: TABS_VALIDATION_MESSAGE,
    isAlwaysVisible: true,
  };

  return (
    <>
      <BlocFullContext24Now
        title="Déclaration des émissions"
        hasModification={
          !isEqual(initialMesure, mesureEmissionsInPage) ||
          !isEqual(initialCorrelation, correlationEmissionsInPage) ||
          !isEqual(initialMatiere, matiereEmissionsInPage)
        }
        isValidateButtonAvailable={true}
        validationMessage={
          showValidationMessage ? validationMessage : undefined
        }
        path={validationEmissionPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => {
          setMesureEmissionsInPage(initialMesure);
          setMatiereEmissionsInPage(initialMatiere);
          setCorrelationEmissionsInPage(initialCorrelation);
        }}
        renderContent={shouldDisableFields => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER DES ÉMISSIONS"
                  onClick={() => {
                    setEmissionModaleOpen(true);
                  }}
                  isDisabled={shouldDisableFields}
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par mesure
              </span>
              <EmissionMesureArray
                mesureEmissionsInPage={_.cloneDeep(
                  mesureEmissionsInPage
                ).sort((a, b) =>
                  compareEmissionFunction(a, b, procedesInPageMap)
                )}
                procedesInPageMap={procedesInPageMap}
                setMesureEmissionsInPage={setMesureEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisableFields}
                validationEmissionPath={validationEmissionPath}
                referentialCO2={referentialCO2}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setMesureEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={
                    shouldDisableFields || mesureEmissionsInPage.length === 0
                  }
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par facteur de corrélation
              </span>
              <EmissionCorrelationArray
                correlationEmissionsInPage={_.cloneDeep(
                  correlationEmissionsInPage
                ).sort((a, b) =>
                  compareEmissionFunction(a, b, procedesInPageMap)
                )}
                setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisableFields}
                validationEmissionPath={validationEmissionPath}
                referentialCO2={referentialCO2}
                procedesInPageMap={procedesInPageMap}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setCorrelationEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={
                    shouldDisableFields ||
                    correlationEmissionsInPage.length === 0
                  }
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par bilan matière
              </span>
              <EmissionMatiereArray
                matiereEmissionsInPage={_.cloneDeep(
                  matiereEmissionsInPage
                ).sort((a, b) =>
                  compareEmissionFunction(a, b, procedesInPageMap)
                )}
                procedesInPageMap={procedesInPageMap}
                setMatiereEmissionsInPage={setMatiereEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisableFields}
                validationEmissionPath={validationEmissionPath}
                polluantElementMap={polluantElementMap}
                autreElementUuid={referentialElementAutreUuid}
                referentialCO2={referentialCO2}
              />
              <Row height={"30px"} />
              <Collapsible
                title="Synthèse des intrants renseignés pour émissions par bilan matière"
                initiallyOpen={false}
              >
                <SyntheseIntrantsArray
                  setEmissionInModale={setEmissionInModale}
                  matiereEmissionsInPage={matiereEmissionsInPage}
                  isValidated={shouldDisableFields}
                  setEmissionModaleOpen={setEmissionModaleOpen}
                  autreElementUuid={referentialElementAutreUuid}
                  polluantElementMap={polluantElementMap}
                />
              </Collapsible>
              <Collapsible
                title="Synthèse des sortants renseignés pour émissions par bilan matière"
                initiallyOpen={false}
              >
                <SyntheseSortantsArray
                  setEmissionInModale={setEmissionInModale}
                  matiereEmissionsInPage={matiereEmissionsInPage}
                  isValidated={shouldDisableFields}
                  setEmissionModaleOpen={setEmissionModaleOpen}
                  autreElementUuid={referentialElementAutreUuid}
                  polluantElementMap={polluantElementMap}
                />
              </Collapsible>
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setMatiereEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={
                    shouldDisableFields || matiereEmissionsInPage.length === 0
                  }
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <EmissionModale
        referentialCO2={referentialCO2}
        polluantElementMap={polluantElementMap}
        referentialElementAutreUuid={referentialElementAutreUuid}
        emissionModaleOpen={emissionModaleOpen}
        emissionInModale={emissionInModale}
        setMatiereEmissionsInPage={setMatiereEmissionsInPage}
        setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
        setMesureEmissionsInPage={setMesureEmissionsInPage}
        setEmissionInModale={setEmissionInModale}
        setEmissionModaleOpen={setEmissionModaleOpen}
        procedesInPageMap={procedesInPageMap}
        correlationEmissionsInPage={correlationEmissionsInPage}
        matiereEmissionsInPage={matiereEmissionsInPage}
        mesureEmissionsInPage={mesureEmissionsInPage}
        substances={substances}
      />
    </>
  );
};

export default Emission;
