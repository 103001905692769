import React from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import {
  ProduitTransportType,
  fieldProduitTransportTypeMatcher,
} from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import {
  useChoiceRadioFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { franceOrOtherCountry } from "./utils/selectPossibleValues";
import { convertInFranceStringToBoolean } from "./utils/converters";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferencePaysDto,
} from "api/gen";
import {
  PRECISION_SUB_USAGE_FAMILY,
  PRECISION_USAGE_FAMILY,
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "../Families/languages";
import {
  onSelectFamilyHandler,
  onSelectSubFamilyHandler,
  onSelectSubFamilyLvl2Handler,
} from "../Families/utils";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  productionProductAlreadyDefinedForDepMessage,
  productionProductAlreadyDefinedForStateMessage,
} from "common/declarant/formik/formikMessages";
import { isSearchStringInCollection } from "common/utils/methods";
import { familiesProductionValidationSchema } from "../Families/validation";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import uuid from "uuid";
import { YEARLY_AMOUNT } from "../utils/languages";
import { Errors } from "../../../../../../../common/form/utils";

interface TransportProductModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  transportProductInModal: ProduitTransportType | null;
  setTransportProductInModal: (product: ProduitTransportType | null) => void;
  transportProductsInArray: ProduitTransportType[];
  setTransportProductsInArray: (substances: ProduitTransportType[]) => void;
  referentialCountries: ReferencePaysDto;
  referentialDepartements: ReferenceDepartementDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = familiesProductionValidationSchema({
  enFrance: commonStringFields,
  departement: Yup.object().when("enFrance", {
    is: (enFrance: string | null) =>
      enFrance && convertInFranceStringToBoolean(enFrance),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  pays: Yup.object().when("enFrance", {
    is: (enFrance: string | null) =>
      enFrance && !convertInFranceStringToBoolean(enFrance),
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  quantite: commonPositiveNumberFields,
});

const TransportProductModal = ({
  isOpen,
  setIsModalOpen,
  transportProductInModal,
  setTransportProductInModal,
  transportProductsInArray,
  setTransportProductsInArray,
  referentialCountries,
  referentialDepartements,
  referentialFamilies,
  referentialFamiliesCodeProduction,
}: TransportProductModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-transport-produits",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);

  const onClose = () => {
    setTransportProductInModal(null);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UN PRODUIT EXPÉDIÉ"
        validationSchema={validationSchema}
        initialEntity={
          transportProductInModal || {
            id: "",
            quantite: 0,
            famille: null,
            precisionFamille: "",
            sousFamille: null,
            precisionSousFamille: "",
            sousFamilleNiveau2: null,
            precisionSousFamilleNiveau2: "",
            enFrance: "true",
            departement: null,
            pays: null,
          }
        }
        closeFunction={onClose}
        isEdit={transportProductInModal !== null}
        onSubmit={(values: ProduitTransportType) => {
          values.id = transportProductInModal?.id || uuid();
          const newTransportProductsInArray = _.cloneDeep(
            transportProductsInArray
          );
          let indexToReplace = newTransportProductsInArray.length;
          if (transportProductInModal) {
            indexToReplace = _.findIndex(
              newTransportProductsInArray,
              transportProductInModal
            );
            setTransportProductInModal(null);
          }
          newTransportProductsInArray[indexToReplace] = values;
          setTransportProductsInArray(newTransportProductsInArray);
          setIsModalOpen(false);
        }}
        validate={values => {
          const errors: Errors<ProduitTransportType> = {};
          if (values.enFrance) {
            if (
              convertInFranceStringToBoolean(values.enFrance) &&
              values.departement &&
              transportProductsInArray
                .filter(p => p.id !== values.id)
                .some(
                  product =>
                    _.isEqual(product.departement, values.departement) &&
                    _.isEqual(product.famille, values.famille) &&
                    _.isEqual(product.sousFamille, values.sousFamille) &&
                    _.isEqual(
                      product.sousFamilleNiveau2,
                      values.sousFamilleNiveau2
                    )
                )
            ) {
              errors.departement = productionProductAlreadyDefinedForDepMessage;
            }

            if (
              !convertInFranceStringToBoolean(values.enFrance) &&
              transportProductsInArray
                .filter(p => p.id !== values.id)
                .some(
                  product =>
                    _.isEqual(product.pays, values.pays) &&
                    _.isEqual(product.famille, values.famille) &&
                    _.isEqual(product.sousFamille, values.sousFamille) &&
                    _.isEqual(
                      product.sousFamilleNiveau2,
                      values.sousFamilleNiveau2
                    )
                )
            ) {
              errors.pays = productionProductAlreadyDefinedForStateMessage;
            }
          }
          return errors;
        }}
        renderField={({ values, setFieldValue }) => {
          return (
            <>
              <WrappedChoiceSelect
                label={`${USAGE_FAMILY} *`}
                name={fieldProduitTransportTypeMatcher.family}
                isMulti={false}
                options={
                  referentialFamilies.referenceItemFamilleUsageProductionDtoList
                }
                additionalOnChange={() => onSelectFamilyHandler(setFieldValue)}
                computeLabel={option => option.code + " - " + option.libelle}
                commonProps={commonProps}
              />
              {values.famille && values.famille.precision && (
                <TextField
                  label={`${PRECISION_USAGE_FAMILY} *`}
                  name={fieldProduitTransportTypeMatcher.precisionFamily}
                />
              )}
              {values.famille &&
                values.famille.referenceItemFamilleUsageDtos.length > 0 && (
                  <WrappedChoiceSelect
                    label={`${SUB_USAGE_FAMILY} *`}
                    name={fieldProduitTransportTypeMatcher.subFamily}
                    isMulti={false}
                    options={values.famille?.referenceItemFamilleUsageDtos}
                    additionalOnChange={() =>
                      onSelectSubFamilyHandler(setFieldValue)
                    }
                    computeLabel={option =>
                      option.code + " - " + option.libelle
                    }
                    commonProps={commonProps}
                  />
                )}
              {values.sousFamille && values.sousFamille.precision && (
                <TextField
                  label={`${PRECISION_SUB_USAGE_FAMILY} *`}
                  name={fieldProduitTransportTypeMatcher.precisionSubFamily}
                />
              )}
              {values.sousFamille &&
                values.sousFamille.referenceItemFamilleUsageDtos.length > 0 && (
                  <WrappedChoiceSelect
                    label={`${SUB_USAGE_FAMILY_LEVEL_2} *`}
                    name={fieldProduitTransportTypeMatcher.subFamilyLevel2}
                    isMulti={false}
                    options={values.sousFamille?.referenceItemFamilleUsageDtos}
                    additionalOnChange={() =>
                      onSelectSubFamilyLvl2Handler(setFieldValue)
                    }
                    computeLabel={option =>
                      option.code + " - " + option.libelle
                    }
                    commonProps={commonProps}
                  />
                )}
              {values.sousFamilleNiveau2 &&
                values.sousFamilleNiveau2.precision && (
                  <TextField
                    label={`${PRECISION_SUB_USAGE_FAMILY} (niveau 2)*`}
                    name={
                      fieldProduitTransportTypeMatcher.precisionSubFamilyLevel2
                    }
                  />
                )}

              <NumberField
                name={fieldProduitTransportTypeMatcher.quantity}
                label={`${YEARLY_AMOUNT} *`}
                unit="ktonnes"
              />

              <ChoiceRadioField
                name={fieldProduitTransportTypeMatcher.inFrance}
                label="Destination *"
                choices={franceOrOtherCountry}
                additionalOnChange={() => {
                  setFieldValue("department", null);
                  setFieldValue("country", null);
                }}
              />

              {values.enFrance &&
                convertInFranceStringToBoolean(values.enFrance) && (
                  <WrappedChoiceSelectModale
                    name={fieldProduitTransportTypeMatcher.department}
                    label=""
                    title=""
                    header={["Département"]}
                    linesData={referentialDepartements.departements}
                    formatLine={lineData => [
                      lineData.numero + " - " + lineData.nom,
                    ]}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection([lineData.nom], searchedStr)
                    }
                    formatSelectedTitle={lineData => lineData.nom}
                    commonProps={commonProps}
                  />
                )}

              {values.enFrance &&
                !convertInFranceStringToBoolean(values.enFrance) && (
                  <WrappedChoiceSelectModale
                    label=""
                    name={fieldProduitTransportTypeMatcher.country}
                    title=""
                    header={["Pays"]}
                    linesData={referentialCountries.referenceItemPaysDtoList}
                    formatLine={lineData => [lineData.designation]}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection(
                        [lineData.designation],
                        searchedStr
                      )
                    }
                    formatSelectedTitle={lineData => lineData.designation}
                    commonProps={commonProps}
                  />
                )}
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default TransportProductModal;
