import { makeStyles } from "@material-ui/styles";
import {
  QuotasInstallationDto20Now,
  ReferenceActiviteQuotaDto,
  RestorationCode,
} from "api/gen/api";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  parseBoolean,
} from "common/utils/computeWrappedCell";
import React from "react";
import { ERROR, RAPPEL_INFOS_GENERALES_STYLE, SUBTITLE_STYLE } from "theme";
import { useDeclaration2020 } from "../../versionedElements/declarationHooks2020";

interface BlocRappelInstallationsQuotasProps {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  subTitle: SUBTITLE_STYLE,
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
  lineBreak: {
    whiteSpace: "pre-line",
  },
  rappelInfosGenerales: RAPPEL_INFOS_GENERALES_STYLE,
});

const BlocRappelInstallationsQuotas = ({
  referentialActivites,
}: BlocRappelInstallationsQuotasProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const installations = declaration.body.typeActivite.installations;

  const computeLine = (
    installation: QuotasInstallationDto20Now
  ): React.ReactElement[] => {
    const mapCodeToActiviteDescription = (activite: RestorationCode) => {
      const description = referentialActivites.referenceItemActiviteQuotaDtoList.find(
        referentiel => referentiel.restorationCode === activite.code
      );
      return description === undefined ? null : description.description;
    };

    const activiteString =
      installation.codesActivite &&
      installation.codesActivite.map(mapCodeToActiviteDescription).join(",\n");

    return [
      computeWrappedCellWithError(<p>{installation.nim}</p>, classes),
      computeWrappedCellWithError(
        <p>{installation.nomInstallation}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {installation.entreeSEQE
            ? new Date(installation.entreeSEQE).toLocaleDateString()
            : null}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {installation.sortieSEQE
            ? new Date(installation.sortieSEQE).toLocaleDateString()
            : null}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p
          title={activiteString ? activiteString : undefined}
          className={classes.lineBreak}
        >
          {activiteString ? activiteString : null}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>{parseBoolean(installation.isNotAllocationGratuit)}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>{parseBoolean(installation.isNouvelEntrant)}</p>,
        classes
      ),
    ];
  };

  return (
    <>
      <h3 className={classes.subTitle}>
        Déclaration et description des installations quotas
      </h3>
      <CommonEntityTable
        header={[
          <p>Numéro NIM</p>,
          <p>Nom de l’installation</p>,
          <p>Date d’entrée dans le SEQE</p>,
          <p>Date de sortie du SEQE</p>,
          <p>Activités de l’installation</p>,
          <p>Non admissible allocation gratuite</p>,
          <p>Nouvel entrant - Niveaux d’activité non déclarés</p>,
        ]}
        preferredColWidths={[180, 140, 100, 100, 310, 150, 150]}
        lines={installations.map(computeLine)}
        isFirstColSticky={false}
        isLastColSticky={false}
        additionalClassName={classes.rappelInfosGenerales}
      />
    </>
  );
};

export default BlocRappelInstallationsQuotas;
