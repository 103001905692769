import React, { ReactElement } from "react";
import { MethodeMesureEmissionDto22Now } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import { makeStyles } from "@material-ui/styles";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { formatNiveauFlux } from "../../BlocPlanSurveillance/BlocDonneeSurveillance/utils";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

type AerMethodeMesureEmission = Pick<
  MethodeMesureEmissionDto22Now,
  | "pointMesure"
  | "referenceFlux"
  | "gazMesure"
  | "niveauApplique"
  | "concentrationGes"
  | "heuresExploitation"
  | "debitHoraire"
  | "debitTotal"
  | "fractionBiomasse"
  | "fractionBiomasseNonDurable"
>;

export interface MethodeMesureEmissionListProps {
  methodeMesureEmissionList: AerMethodeMesureEmission[];
}

const MethodeMesureEmissionList = ({
  methodeMesureEmissionList,
}: MethodeMesureEmissionListProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de mesure par source
        d’émission
      </SubSectionTitle>
      {methodeMesureEmissionList.map(
        ({
          pointMesure,
          referenceFlux,
          gazMesure,
          niveauApplique,
          concentrationGes,
          heuresExploitation,
          debitHoraire,
          debitTotal,
          fractionBiomasse,
          fractionBiomasseNonDurable,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${pointMesure} - ${referenceFlux}`}</ArrayTitle>
            <CommonEntityTable
              header={[<p></p>, <p>Valeur</p>, <p>Unité</p>]}
              lines={[
                [
                  computeWrappedCell("Gaz mesuré", classes),
                  computeWrappedCell(gazMesure, classes),
                  computeWrappedCell("", classes),
                ],
                [
                  computeWrappedCell("Niveau appliqué", classes),
                  computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
                  computeWrappedCell("", classes),
                ],
                [
                  computeWrappedCell(
                    "Concentration de GES (moyenne horaire annuelle)",
                    classes
                  ),
                  computeWrappedCell(concentrationGes, classes),
                  computeWrappedCell("g/Nm3", classes),
                ],
                [
                  computeWrappedCell("Heures d’exploitation", classes),
                  computeWrappedCell(heuresExploitation, classes),
                  computeWrappedCell("h/an", classes),
                ],
                [
                  computeWrappedCell(
                    "Débit des effluents gazeux (moyenne horaire annuelle)",
                    classes
                  ),
                  computeWrappedCell(debitHoraire, classes),
                  computeWrappedCell("1000 Nm3/h", classes),
                ],
                [
                  computeWrappedCell(
                    "Débit des effluents gazeux (total annuel)",
                    classes
                  ),
                  computeWrappedCell(debitTotal, classes),
                  computeWrappedCell("1000 Nm3/h", classes),
                ],
                [
                  computeWrappedCell(
                    "Fraction issue de la biomasse durable",
                    classes
                  ),
                  computeWrappedCell(fractionBiomasse, classes),
                  computeWrappedCell("%", classes),
                ],
                [
                  computeWrappedCell(
                    "Fraction issue de la biomasse non durable",
                    classes
                  ),
                  computeWrappedCell(fractionBiomasseNonDurable, classes),
                  computeWrappedCell("%", classes),
                ],
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </>
  );
};

export default MethodeMesureEmissionList;
