import * as Yup from "yup";
import {
  commonBooleanFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import { VibrationMeasuresForm } from "./types";
import { environnementAtLeastOneFieldMessage } from "common/declarant/formik/formikMessages";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";

export const validationSchema = Yup.object().shape({
  vibrationMeasuresSubmitted: commonBooleanFields,
  explosiveUtilisation: commonBooleanFields,
  explosiveMeasuresDone: commonBooleanFields,
  vibrationUtilisation: commonBooleanFields,
  vibrationMeasuresDone: commonBooleanFields,
  explosiveMeasurePointsNumber: Yup.number().when(
    [
      "vibrationMeasuresSubmitted",
      "explosiveUtilisation",
      "explosiveMeasuresDone",
    ],
    {
      is: (
        vibrationMeasuresSubmitted: boolean,
        explosiveUtilisation: boolean,
        explosiveMeasuresDone: boolean
      ) =>
        vibrationMeasuresSubmitted &&
        explosiveUtilisation &&
        explosiveMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  explosiveMeasureCampaignsNumber: Yup.number().when(
    [
      "vibrationMeasuresSubmitted",
      "explosiveUtilisation",
      "explosiveMeasuresDone",
    ],
    {
      is: (
        vibrationMeasuresSubmitted: boolean,
        explosiveUtilisation: boolean,
        explosiveMeasuresDone: boolean
      ) =>
        vibrationMeasuresSubmitted &&
        explosiveUtilisation &&
        explosiveMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  vibrationMeasurePointsNumber: Yup.number().when(
    [
      "vibrationMeasuresSubmitted",
      "vibrationUtilisation",
      "vibrationMeasuresDone",
    ],
    {
      is: (
        vibrationMeasuresSubmitted: boolean,
        vibrationUtilisation: boolean,
        explosivibrationMeasuresDoneveMeasuresDone: boolean
      ) =>
        vibrationMeasuresSubmitted &&
        vibrationUtilisation &&
        explosivibrationMeasuresDoneveMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  vibrationMeasureCampaignsNumber: Yup.number().when(
    [
      "vibrationMeasuresSubmitted",
      "vibrationUtilisation",
      "vibrationMeasuresDone",
    ],
    {
      is: (
        vibrationMeasuresSubmitted: boolean,
        vibrationUtilisation: boolean,
        explosivibrationMeasuresDoneveMeasuresDone: boolean
      ) =>
        vibrationMeasuresSubmitted &&
        vibrationUtilisation &&
        explosivibrationMeasuresDoneveMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
});

export const preventSubmitWithErrors = (values: VibrationMeasuresForm) => {
  const errors: FormikErrors<VibrationMeasuresForm> = {};

  if (
    values.vibrationMeasuresSubmitted &&
    !values.explosiveUtilisation &&
    !values.vibrationUtilisation
  ) {
    errors.errorsAtLeastRequired = environnementAtLeastOneFieldMessage;
  }

  return errors;
};
