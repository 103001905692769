import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { singleCovValidationSchema } from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  arrayMentionLabels,
  arrayMentionOptions,
} from "./utils/selectPossibleValues";
import { CovInModale } from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { convertInstallationInArraysToInstallationDtos } from "../InstallationBloc/utils/utils";

interface FormCovProps {
  closeFunction: () => void;
  onSubmit: (
    values: CovInModale,
    formikActions: FormikActions<CovInModale>
  ) => void;
  initialCov: CovInModale;
  isEdit: boolean;
  installationsInPage: InstallationInArray[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const FormSingleCov = ({
  closeFunction,
  onSubmit,
  initialCov,
  installationsInPage,
  isEdit,
}: FormCovProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-cov-solvants",
  };

  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      isEdit={isEdit}
      title="AJOUTER DES ÉMISSIONS DE COV A MENTION DE DANGER"
      closeFunction={closeFunction}
      onSubmit={(values: CovInModale, formikBag) => {
        onSubmit(values, formikBag);
      }}
      initialEntity={initialCov}
      validationSchema={singleCovValidationSchema}
      renderField={() => {
        return (
          <>
            <WrappedChoiceSelect
              name="danger"
              label="Utilisation de COV à mention de danger"
              isMulti={false}
              options={arrayMentionOptions}
              computeLabel={mentionDanger => arrayMentionLabels[mentionDanger]}
              tooltipContent="Mentions de danger attribuées ou pouvant être apposées sur les substances ou préparations classées comme cancérogènes, mutagènes ou reprotoxiques"
              commonProps={commonProps}
            />
            <WrappedChoiceSelect
              isMulti
              name="installations"
              label="Installation(s)"
              options={convertInstallationInArraysToInstallationDtos(
                installationsInPage
              )}
              computeLabel={installation => installation.nom || ""}
              commonProps={commonProps}
            />
            <NumberField name="flux" label="Émissions" unit="kg/an" />
          </>
        );
      }}
    />
  );
};

export default FormSingleCov;
