import React, { ReactElement, useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { useDownloadExport } from "pages/CompanySpace/DeclarationApiContext/utils/exportHooks";
import Spinner from "common/presentational/Spinner";

export default function ExportDownload({
  match: { params },
}: RouteComponentProps<{ exportId: string }>): ReactElement {
  const { exportId } = params;
  const [isExportDownloaded, downloadExport] = useDownloadExport();

  useEffect(() => {
    downloadExport(exportId);
  }, [downloadExport, exportId]);

  return isExportDownloaded ? <Redirect to={"/"} /> : <Spinner />;
}
