import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import {
  CorrelationEmissionInArray,
  CorrelationEmissionInModale,
  EmissionsInArray,
  EmissionsInModale,
  MatiereEmissionInArray,
  MatiereEmissionInModale,
  MesureEmissionInArray,
  MesureEmissionInModale,
} from "./utils/types";
import FormEmission from "./FormEmission";
import {
  addOrModifyArray,
  moveAndDeleteEmission,
} from "common/declarant/array/utils";
import {
  AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { ProcedeInArray } from "../ListProcede/utils/types";
import { MethodEnum } from "./utils/selectPossibleValues";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import uuid from "uuid";

interface EmissionModaleProps {
  emissionModaleOpen: boolean;
  emissionInModale: EmissionsInArray | null;
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>;
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  procedesInPageMap: Map<string, ProcedeInArray>;
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  substances: ReferenceItemPolluantDto[];
  referentialCO2: ReferenceItemPolluantDto;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElementAutreUuid: string;
}

const EmissionModale = ({
  emissionModaleOpen,
  emissionInModale,
  setMatiereEmissionsInPage,
  setCorrelationEmissionsInPage,
  setMesureEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  procedesInPageMap,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  mesureEmissionsInPage,
  substances,
  referentialCO2,
  polluantElementMap,
  referentialElementAutreUuid,
}: EmissionModaleProps): React.ReactElement => {
  const onClose = () => {
    setEmissionInModale(null);
    setEmissionModaleOpen(false);
  };

  const defaultInitialValue: EmissionsInModale = {
    procedes: null,
    epuration: false,
    natureEquipement: null,
    rendement: null,
    elementName: null,
    elementProps: null,
    part: null,
    concentrationMoy: null,
    concentrationMoyUnite: null,
    debitHoraire: null,
    facteur: null,
    concentration: { continu: false, frequence: null },
    debit: { continu: false, frequence: null },
    heures: null,
    methods: null,
    nature: null,
    provenanceFacteurCorrelation: null,
    substance: null,
    unite: null,
    biomasse: null,
    intrants: [],
    sortants: [],
    typeMesure: null,
  };

  return (
    <CustomModal
      isOpen={emissionModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout d'émissions."
      onRequestClose={onClose}
    >
      <FormEmission
        referentialCO2={referentialCO2}
        polluantElementMap={polluantElementMap}
        autreElementUuid={referentialElementAutreUuid}
        substances={substances}
        procedesInPageMap={procedesInPageMap}
        correlationEmissionsInPage={correlationEmissionsInPage}
        matiereEmissionsInPage={matiereEmissionsInPage}
        mesureEmissionsInPage={mesureEmissionsInPage}
        closeFunction={onClose}
        allowedCouples={emissionInModale}
        onSubmit={values => {
          if (values.methods === null) {
            throw Error("Should not happen, check validation");
          }

          if (values.methods === MethodEnum.MESURE) {
            const newEmission: MesureEmissionInModale = {
              methods: values.methods,
              substance: values.substance,
              procedes: values.procedes,
              debitHoraire: values.debitHoraire,
              debit: values.debit,
              heures: values.heures,
              concentrationMoy: values.concentrationMoy,
              concentrationMoyUnite: values.concentrationMoyUnite,
              concentration: values.concentration,
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendement: values.rendement,
              biomasse: values.biomasse,
              typeMesure: values.typeMesure,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ça veut dire que j'ajoute ou modifie
              values.methods === emissionInModale.data.methods // S'il y avait déjà des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setMesureEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                // If Typescript understands that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodEnum.FACTEUR_CORRELATION:
                  moveAndDeleteEmission(
                    setMesureEmissionsInPage,
                    setCorrelationEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodEnum.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setMesureEmissionsInPage,
                    setMatiereEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else if (values.methods === MethodEnum.FACTEUR_CORRELATION) {
            const newEmission: CorrelationEmissionInModale = {
              methods: values.methods,
              substance: values.substance,
              procedes: values.procedes && [values.procedes[0]],
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendement: values.rendement,
              nature: values.nature,
              facteur: values.facteur,
              unite: values.unite,
              provenanceFacteurCorrelation: values.provenanceFacteurCorrelation,
              biomasse: values.biomasse,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ca veut dire que j'ajoute ou modifie
              values.methods === emissionInModale.data.methods // S'il y avait déjà des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setCorrelationEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                // If Typescript understands that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodEnum.MESURE:
                  moveAndDeleteEmission(
                    setCorrelationEmissionsInPage,
                    setMesureEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodEnum.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setCorrelationEmissionsInPage,
                    setMatiereEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else if (values.methods === MethodEnum.BILAN_MATIERE) {
            const newEmission: MatiereEmissionInModale = {
              methods: values.methods,
              substance: values.substance,
              intrants: values.intrants,
              sortants: values.sortants,
              procedes: values.procedes,
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendement: values.rendement,
              elementName: values.elementName,
              elementProps: values.elementProps,
              part: values.part,
              biomasse: values.biomasse,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ca veut dire que j'ajoute ou modifie
              values.methods === emissionInModale.data.methods // S'il y avait déjà des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setMatiereEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                // If Typescript understands that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodEnum.MESURE:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInPage,
                    setMesureEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodEnum.FACTEUR_CORRELATION:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInPage,
                    setCorrelationEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;

                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else {
            throw new ShouldNotHappen(values.methods);
          }

          onClose();
        }}
        isEdit={emissionInModale !== null}
        initialEmission={
          emissionInModale !== null
            ? {
                ...defaultInitialValue,
                ...emissionInModale.data,
              }
            : {
                ...defaultInitialValue,
                intrants: [
                  {
                    descriptif: null,
                    id: uuid(),
                    quantite: null,
                    teneurMoyenne: null,
                  },
                ],
              }
        }
      />
    </CustomModal>
  );
};

export default EmissionModale;
