import {
  PATH_DECHET_PRODUCTION,
  PATH_DECHET_RECEPTION,
} from "common/path/path18Now";
import { PATH_DECHET_SYNTHESE } from "common/path/path24Now";
import garbageImg from "icons/dashboardLogos/garbage.svg";
import listImg from "icons/dashboardLogos/list.svg";
import { GlobalDashboardCardsProps } from "pages/CompanySpace/from21/to23/Dashboard/Components/Cards/types";
import { SubCard } from "./types";
import {
  getDisabledMessageForConditionnalSection,
  getDisabledMessageForTypeActiviteConditionnalSection,
} from "pages/CompanySpace/from21/toNow/Dashboard/Components/utils/disabledMessageUtils";
import { Declaration24Now } from "../../../versionedElements/declarationHooks24Now";

function buildDechetSortantSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Déchets Sortants",
    picture: { src: garbageImg, alt: "" },
    percentageProgress: getPathProgress(PATH_DECHET_PRODUCTION),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_DECHET_PRODUCTION}`,
    isDisabled: !getIsActiveSection(PATH_DECHET_PRODUCTION),
    isValidated: isPathValidatedInDeclaration(PATH_DECHET_PRODUCTION),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildDechetEntrantSubCard({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Déchets Entrants",
    picture: { src: garbageImg, alt: "" },
    percentageProgress: getPathProgress(PATH_DECHET_RECEPTION),
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_DECHET_RECEPTION}`,
    isDisabled: !getIsActiveSection(PATH_DECHET_RECEPTION),
    isValidated: isPathValidatedInDeclaration(PATH_DECHET_RECEPTION),
    disabledMessage: getDisabledMessageForTypeActiviteConditionnalSection(
      isPathValidatedInDeclaration
    ),
  };
}

function buildSyntheseSubCard(
  {
    declarationAnnee,
    declarationEtablissement,
    declarationApi,
  }: GlobalDashboardCardsProps,
  declaration: Declaration24Now
): SubCard {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return {
    text: "Synthèse",
    picture: { src: listImg, alt: "" },
    link: `/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_DECHET_SYNTHESE}`,
    percentageProgress: getPathProgress(PATH_DECHET_SYNTHESE),
    isDisabled: !getIsActiveSection(PATH_DECHET_SYNTHESE),
    isValidated: isPathValidatedInDeclaration(PATH_DECHET_SYNTHESE),
    disabledMessage: getDisabledMessageForConditionnalSection(
      isPathValidatedInDeclaration,
      "Cette page est active si vous avez validé tous les blocs du pavé Déchets"
    ),
    isDepassementDeSeuil:
      declaration.computed.sections.dechets.depassementDeSeuilGlobal,
  };
}

export function buildDechetSubCards(
  props: GlobalDashboardCardsProps,
  declaration: Declaration24Now
): SubCard[] {
  return [
    buildDechetSortantSubCard(props),
    buildDechetEntrantSubCard(props),
    buildSyntheseSubCard(props, declaration),
  ];
}
