import React from "react";
import { computeErrorObject } from "./utils";
import DummyChoiceSelectModal from "../dumbInput/DummyChoiceSelectModal";
import { ChoiceSelectModalProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

function ChoiceSelectModal<
  DisplayedValue,
  SelectTableValue extends DisplayedValue
>({
  //  FieldProps
  field,
  form,
  //  UnwrappedFieldProps
  id,
  disabled,
  additionalClassName,
  style,
  externalValue,
  //  basic props
  onSelect,
  //    Select customisation
  title,
  hideValue,
  //    line formater
  linesData,
  formatLine,
  formatSelectedTitle,
  //  search
  placeholder,
  isLineInSearch,
  //    CommonEntityTable customisation
  header,
  isFirstSticky = false,
  isLastSticky = false,
  preferredColWidths,
  cellStyles,
}: ChoiceSelectModalProps<
  DisplayedValue,
  SelectTableValue
>): React.ReactElement {
  const error = computeErrorObject({ form, field });
  const value = field.value || undefined;
  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyChoiceSelectModal
      //  UnwrappedFieldProps
      id={id}
      additionalClassName={additionalClassName}
      style={style}
      //  basic props
      onSelect={newValue => {
        form.setFieldValue(field.name, newValue);
        form.setFieldTouched(field.name);
        if (onSelect) {
          onSelect(newValue);
        }
      }}
      title={title}
      value={externalValue !== undefined ? externalValue : value}
      disabled={externalValue !== undefined ? true : disabled}
      name={field.name}
      error={error}
      onChange={field.onChange}
      onBlur={field.onBlur}
      //
      linesData={linesData}
      formatLine={formatLine}
      formatSelectedTitle={formatSelectedTitle}
      isLineInSearch={isLineInSearch}
      //
      header={header}
      placeholder={placeholder}
      hideValue={hideValue}
      isFirstSticky={isFirstSticky}
      isLastSticky={isLastSticky}
      preferredColWidths={preferredColWidths}
      cellStyles={cellStyles}
    />
  );
}

export default ChoiceSelectModal;
