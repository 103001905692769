import React, { ReactElement } from "react";
import { Omit } from "react-router";
import FieldWrapper, { FieldWrapperProps } from "../wrappers/FieldWrapper";
import TextArea from "../connectedInput/TextArea";
import { TextAreaFieldProps } from "../types/connectedTypes";

type WrappedTextAreaFieldWithoutLabelProps = Omit<
  FieldWrapperProps,
  "renderField"
> &
  Omit<TextAreaFieldProps, "field" | "form" | "id">;

export const WrappedTextAreaFieldWithoutLabel = (
  props: WrappedTextAreaFieldWithoutLabelProps
): ReactElement => {
  const isDisabled =
    props.externalValue !== undefined ||
    (props.disabled ?? props.commonProps.disabled);

  return (
    <FieldWrapper
      name={props.name}
      commonProps={props.commonProps}
      renderField={({ field, form }, id) => (
        <TextArea
          id={id}
          field={field}
          form={form}
          additionalClassName={props.additionalClassName}
          style={props.style}
          disabled={isDisabled}
          additionalOnChange={props.additionalOnChange}
          rows={props.rows}
          placeholder={props.placeholder}
          externalValue={props.externalValue}
        />
      )}
    />
  );
};
