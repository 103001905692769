import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";
import { TypeActiviteQuota23Now } from "../../versionedElements/declarationHooks23Now";

export type TechniqueQuotasValues = Omit<
  TypeActiviteQuota23Now,
  "installations"
>;

export const techniqueQuotasFieldMatcher = fieldMatcherBuilder<
  TechniqueQuotasValues
>().build({
  quotas: "estSousQuotaCo2",
  excluArticle27: "excluArticle27",
  excluArticle27Bis: "excluArticle27Bis",
  incinerateur: "incinerateur",
});
