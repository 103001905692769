import {
  CommentDto,
  DeclarationCommentDto,
  DeleteCommentDto,
  EventCommentsDto,
} from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useCallback } from "react";
import {
  useDeclaration,
  useDeclarationComments,
} from "../DeclarationApiContext/utils/genericHooks";
import {
  AnyDeclarationDto,
  DeclarationCommentsHandlers,
} from "../DeclarationApiContext/utils/types";

// declare the range for all generic hooks we'll call in this file
const MIN_YEAR = 2018;
const MAX_YEAR = undefined;

export const useDeclarationForComments = (): AnyDeclarationDto => {
  return useDeclaration<AnyDeclarationDto>(MIN_YEAR, MAX_YEAR);
};

export const useConcreteDeclarationComments = (): DeclarationCommentsHandlers<DeclarationCommentDto> => {
  const { declarationController } = useAuthenticatedApi();

  const commentDeclaration = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      path: string,
      comment: string,
      isDynamic: boolean,
      isAlertOrError: boolean
    ): Promise<AnyDeclarationDto> => {
      const commentDto: CommentDto = {
        path: path,
        value: comment,
        dynamic: isDynamic,
        alert: isAlertOrError,
      };
      const eventCommentDto: EventCommentsDto = {
        comments: [commentDto],
      };

      // the as is justified because the back returns AbstractDeclarationsDto, and so far there is only one route.
      return declarationController.commentsUsingPOST(
        annee,
        codeEtablissement,
        eventCommentDto
      ) as Promise<AnyDeclarationDto>;
    },
    [declarationController]
  );

  const deleteCommentDeclaration = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      path: string,
      user: string,
      creationDate: string
    ): Promise<AnyDeclarationDto> => {
      const deleteCommentDto: DeleteCommentDto = {
        path: path,
        user: user,
        creationDate: creationDate,
      };

      // the as is justified because the back returns AbstractDeclarationsDto, and so far there is only one route.
      return declarationController.deleteCommentsUsingDELETE(
        annee,
        deleteCommentDto,
        codeEtablissement
      ) as Promise<AnyDeclarationDto>;
    },
    [declarationController]
  );

  const getComments = useCallback(
    (declarationDto: AnyDeclarationDto): DeclarationCommentDto[] =>
      declarationDto.body.comments,
    []
  );

  return useDeclarationComments<AnyDeclarationDto, DeclarationCommentDto>(
    commentDeclaration,
    deleteCommentDeclaration,
    getComments,
    MIN_YEAR,
    MAX_YEAR
  );
};
