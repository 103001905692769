import React, { ReactElement } from "react";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { PATH_QUOTAS_ALLOCATIONS } from "common/path/path20Now";
import { useUserData } from "Authenticator/UserData";

import calculatorImg from "icons/dashboardLogos/calculator.svg";
import { useDeclarationHelpers21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import BigCard from "./BigCard";
import { computeDisabledMessageForProfileForConditionalSection } from "pages/CompanySpace/from21/toNow/Dashboard/Components/utils/disabledMessageUtils";

const QuotaAllocationsCard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers21Now();

  const {
    isAnyPrestataire,
    isPrestataireNiveauxActiviteForEtablissement,
  } = useUserData();
  const isUserPrestataireEmissionOnly =
    isAnyPrestataire &&
    !isPrestataireNiveauxActiviteForEtablissement(declarationEtablissement);

  return (
    <BigCard
      text={"Niveaux d'activité"}
      picture={{ src: calculatorImg, alt: "" }}
      percentageProgress={
        !isAnyPrestataire ? getPathProgress(PATH_QUOTAS_ALLOCATIONS) : undefined
      }
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS_ALLOCATIONS}`}
      isDisabled={!getIsActiveSection(PATH_QUOTAS_ALLOCATIONS)}
      isValidated={isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS)}
      disabledMessage={
        isUserPrestataireEmissionOnly
          ? "La section niveaux d'activité n'est pas accessible pour les vérificateurs émissions quotas."
          : computeDisabledMessageForProfileForConditionalSection(
              isPathValidatedInDeclaration,
              isAnyPrestataire
            )
      }
    />
  );
};

export default QuotaAllocationsCard;
