import { UserData } from "../../../Authenticator/UserData";
import { AnyDeclarationDto } from "../DeclarationApiContext/utils/types";
import {
  DeclarationCommentDto,
  DeclarationStateDtoStateEnum,
} from "../../../api/gen";
import {
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_EMISSIONS,
} from "common/path/path20Now";

export const isCommentDeletable = (
  userData: UserData,
  declaration: AnyDeclarationDto,
  comment: DeclarationCommentDto
): boolean => {
  const isOwnComment =
    userData.userInfo.id === comment.user &&
    userData.userInfo.droits[0].authProfile === comment.userDroit;
  if (userData.isAnyPrestataire) {
    return canPrestaDelete(comment.path, declaration) && isOwnComment;
  }
  if (userData.isDeclarant) {
    return canDeclarantDelete(comment, declaration) && isOwnComment;
  }
  if (userData.isSuperAdmin && !isOwnComment) {
    return canSuperAdminDelete(comment, declaration);
  }
  if (userData.isInspecteur) {
    return isOwnComment;
  }
  return false;
};

const canPrestaDelete = (
  commentPath: string,
  declaration: AnyDeclarationDto
): boolean => {
  if (commentPath.includes(PATH_QUOTAS_ALLOCATIONS)) {
    return (
      declaration.body.workflowStatus.quotaAllocations.state ===
      DeclarationStateDtoStateEnum.VERIFYING
    );
  }
  if (commentPath.includes(PATH_QUOTAS_EMISSIONS)) {
    return (
      declaration.body.workflowStatus.quotaEmissions.state ===
      DeclarationStateDtoStateEnum.VERIFYING
    );
  }
  return false;
};

const canDeclarantDelete = (
  comment: DeclarationCommentDto,
  declaration: AnyDeclarationDto
): boolean => {
  if (
    comment.alert &&
    declaration.body.userValidation.some(userValidation =>
      comment.path.includes(userValidation.userValidationPath)
    )
  ) {
    return false;
  }
  if (comment.path.includes(PATH_QUOTAS_ALLOCATIONS)) {
    return (
      declaration.body.workflowStatus.quotaAllocations.state ===
        DeclarationStateDtoStateEnum.IN_REVIEW ||
      declaration.body.workflowStatus.quotaAllocations.state ===
        DeclarationStateDtoStateEnum.STARTED
    );
  }
  if (comment.path.includes(PATH_QUOTAS_EMISSIONS)) {
    return (
      declaration.body.workflowStatus.quotaAllocations.state ===
        DeclarationStateDtoStateEnum.IN_REVIEW ||
      declaration.body.workflowStatus.quotaAllocations.state ===
        DeclarationStateDtoStateEnum.STARTED
    );
  }
  return (
    declaration.body.workflowStatus.general.state ===
      DeclarationStateDtoStateEnum.IN_REVIEW ||
    declaration.body.workflowStatus.general.state ===
      DeclarationStateDtoStateEnum.STARTED
  );
};

const canSuperAdminDelete = (
  comment: DeclarationCommentDto,
  declaration: AnyDeclarationDto
): boolean => {
  if (
    comment.alert &&
    declaration.body.userValidation.some(userValidation =>
      comment.path.includes(userValidation.userValidationPath)
    )
  ) {
    return false;
  }
  if (comment.path.includes(PATH_QUOTAS_ALLOCATIONS)) {
    return (
      declaration.body.workflowStatus.quotaAllocations.state ===
      DeclarationStateDtoStateEnum.FORCED
    );
  }
  if (comment.path.includes(PATH_QUOTAS_EMISSIONS)) {
    return (
      declaration.body.workflowStatus.quotaAllocations.state ===
      DeclarationStateDtoStateEnum.FORCED
    );
  }
  return (
    declaration.body.workflowStatus.general.state ===
    DeclarationStateDtoStateEnum.FORCED
  );
};
