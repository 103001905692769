import React, { useState } from "react";
import CustomModal from "common/presentational/CustomModal";
import {
  NatureServiceWithEnumDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";
import { EstablishmentCreation } from "./EstablishmentCreationForm";
import {
  AnyDeclarationDto,
  DeclarationApiProviderFetchedInitializer,
} from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { CancelButtonType } from "common/actions/types";
import { Redirect } from "react-router-dom";
import { ROUTER_PATH_TYPE_ACTIVITE } from "../../../../common/path/path21Now";

interface CompanyManagementModaleProps {
  isOpen: boolean;
  close: (hasCreatedEstablishment: boolean) => void;
  referentielDepartment: ReferenceItemDepartementDto[];
  referentielRegions: ReferenceItemRegionDto[];
  referentielOrganismes: NatureServiceWithEnumDto[];
  adminEmail: string;
}

interface DeclarationCreationProps {
  declaration: AnyDeclarationDto;
}

export const CompanyManagementModale = ({
  isOpen,
  close,
  referentielDepartment,
  referentielRegions,
  referentielOrganismes,
  adminEmail,
}: CompanyManagementModaleProps): React.ReactElement => {
  const [
    declarationData,
    setDeclarationData,
  ] = useState<DeclarationCreationProps | null>(null);

  let declarationInitializer: DeclarationApiProviderFetchedInitializer | null = null;
  if (declarationData) {
    declarationInitializer = {
      type: "declarationData",
      dto: declarationData.declaration,
    };
  }

  const closeCallback = () => {
    close(declarationData !== null);
  };

  const defaultCancelButton: CancelButtonType = {
    title: "Fermer",
    isEnabled: true,
    isVisible: true,
    onClick: closeCallback,
  };
  const buildRedirectionLink = (
    declarationAnnee: number,
    etablissementCode: string
  ) => {
    return `/declaration/${declarationAnnee.toString()}/${etablissementCode}${ROUTER_PATH_TYPE_ACTIVITE}`;
  };

  return (
    <>
      {!declarationData && (
        <CustomModal
          isOpen={isOpen}
          contentLabel={"Modale formulaire ajout d'établissement"}
          onRequestClose={closeCallback}
        >
          <EstablishmentCreation
            setDeclaration={declaration => {
              setDeclarationData({
                declaration: declaration,
              });
            }}
            adminEmail={adminEmail}
            cancelButton={defaultCancelButton}
            departements={referentielDepartment}
            regions={referentielRegions}
            referentielOrganismes={referentielOrganismes}
          />
        </CustomModal>
      )}

      {declarationInitializer && declarationData && (
        <Redirect
          to={buildRedirectionLink(
            declarationData.declaration.annee,
            declarationData.declaration.etablissementCode
          )}
        />
      )}
    </>
  );
};
