import { BlocCalculatedEmissionFormType } from "../utils/types";
import {
  AirIsdndCh4DtoMethodeEnum,
  AirIsdndCo2DtoMethodeEnum,
  AirIsdndEmissionsCalculeesDto,
} from "api/gen";

export const computeInitialBlocValues = (
  emissionsCalculees: AirIsdndEmissionsCalculeesDto
): BlocCalculatedEmissionFormType => {
  return {
    amountCh4Caught: emissionsCalculees.calculCh4.capte,
    amountCh4Generated: emissionsCalculees.calculCh4.genere,
    amountCo2Caught: emissionsCalculees.calculCo2.capte,
    amountCo2Generated: emissionsCalculees.calculCo2.genere,
    ch4Method: emissionsCalculees.calculCh4.methode,
    co2Method: emissionsCalculees.calculCo2.methode,
    co2MethodDescription: emissionsCalculees.calculCo2.methodeDescription,
    ch4MethodDescription: emissionsCalculees.calculCh4.methodeDescription,
    amountCh4Total: emissionsCalculees.calculCh4.total,
    amountCo2Total: emissionsCalculees.calculCo2.total,
  };
};

export const isCo2MethodAutre = (
  co2Method: AirIsdndCo2DtoMethodeEnum | null
): boolean => {
  return co2Method === AirIsdndCo2DtoMethodeEnum.AUTRE_METHODE;
};

export const isCh4MethodAutre = (
  ch4Method: AirIsdndCh4DtoMethodeEnum | null
): boolean => {
  return ch4Method === AirIsdndCh4DtoMethodeEnum.AUTRE_METHODE;
};
