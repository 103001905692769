import { makeStyles } from "@material-ui/styles";
import {
  CampaignDtoStateAllocationsEnum,
  QuotasAllocationsEmailsVerificateursDto20Now,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_QUOTAS_ALC_PRELIMINAIRE,
  PATH_QUOTAS_ALC_VERIFIE,
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_ALLOCATIONS_EMAILS,
  PATH_QUOTAS_ALLOCATIONS_PMS,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import React from "react";
import QuotasGlobalFormActions from "../QuotasGlobalFormActions";
import BlocAlc from "./BlocAlc";
import BlocEmailsVerificateurs from "./BlocEmailsVerificateurs";
import { updateHandler } from "./BlocEmailsVerificateurs/utils";
import BlocPlanMethodSurveillance from "./BlocPlanMethodSurveillance";
import Verification from "./Verification";
import BlocRappelInstallationsQuotas from "../common/BlocRappelInstallationsQuotas";
import QuotasDeclarationOfficeDownload from "../common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import {
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
  useDeclarationHelpers2020,
  useDeclarationStateHandlers2020,
} from "../../versionedElements/declarationHooks2020";
import BlocInspecteur from "../common/BlocInspecteur";
import { areAllAllocationsBlocsValidated } from "./validationUtils";

interface NiveauActiviteFormProps {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const NiveauxActiviteForm = ({
  referentialActivites,
}: NiveauActiviteFormProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const classes = useStyles();
  const { yieldQuotaAllocationsVerif } = useDeclarationStateHandlers2020();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2020();
  const { update } = useBasicDeclarationHandlers2020();
  const declarationApi = useDeclarationHelpers2020();
  const currentCampaign = useCurrentDeclarationCampaign();

  const DummyCheckbox = useDummyBooleanCheckboxFieldGenerator({
    disabled: true,
    className: classes.inputField,
    labelWidth: "60%",
    formPrefix: "",
  });

  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasAllocationsEmailsVerificateursDto20Now
  >();

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
      />

      <Row />

      <DummyCheckbox
        name="soumisQuotas"
        label="L'établissement est soumis à la directive 2003/87/CE (directive quotas)"
        value={declaration.body.typeActivite.estSousQuotaCo2}
      />

      <Row height={"25px"} />

      <BlocRappelInstallationsQuotas
        referentialActivites={referentialActivites}
      />

      <Row height={"25px"} />

      <BlocPlanMethodSurveillance />

      <Row height={"25px"} />

      <BlocAlc />

      <Row height={"25px"} />

      <BlocEmailsVerificateurs
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        formikRef={blocEmailsVerificateursProps.formikRef}
        setHasChanges={blocEmailsVerificateursProps.setHasChanges}
      />
      <QuotasGlobalFormActions
        pathToValidate={PATH_QUOTAS_ALLOCATIONS}
        target={WorkflowTarget.QUOTA_ALLOCATIONS}
        quotaState={declaration.body.workflowStatus.quotaAllocations.state}
        yieldQuotaVerif={yieldQuotaAllocationsVerif}
        isSimplifiedQuota={false}
        saveAction={() =>
          update(declaration => {
            declaration = updateHandler(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
            return declaration;
          })
        }
        cancelAction={() => {
          blocEmailsVerificateursProps.resetForm();
        }}
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        declarantBlocsValidated={
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_EMAILS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_PMS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALC_PRELIMINAIRE) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALC_VERIFIE)
        }
      />
      <Row height={"25px"} />

      <Verification />

      <Row height={"25px"} />

      <BlocInspecteur
        canValidatePage={areAllAllocationsBlocsValidated(declarationApi)}
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        path={PATH_QUOTAS_ALLOCATIONS}
        currentState={declaration.body.workflowStatus.quotaAllocations.state}
        isCampaignEnded={
          currentCampaign.stateAllocations ===
          CampaignDtoStateAllocationsEnum.ENDED
        }
      />

      <Row height={"25px"} />
    </>
  );
};

export default NiveauxActiviteForm;
