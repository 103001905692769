import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import { TEXT_LIGHT_GREY } from "theme";
import Row from "common/presentational/Row";
import Button from "common/button";
import EmissionMesureArray from "./EmissionMesureArray";
import EmissionCorrelationArray from "./EmissionCorrelationArray";
import EmissionMatiereArray from "./EmissionMatiereArray";
import EmissionModale from "./EmissionModale";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "./utils/utils";
import isEqual from "lodash.isequal";
import { createAirFugitivesCovDto } from "../covDanger/utils/utils";
import { CovInArray } from "../covDanger/utils/types";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { useConfirmationModale } from "common/modale/hooks";
import { ProcedeInArray } from "../listprocede/utils/types";
import {
  CorrelationEmissionInArray,
  EmissionsInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import BlocFullContext1919 from "../../../versionedElements/BlocFullContext1919";
import { Declaration1919 } from "../../../versionedElements/declarationHooks1919";
import _ from "lodash";

const useStyles = makeStyles({
  title: {
    fontSize: "19px",
    color: TEXT_LIGHT_GREY,
  },
});

interface EmissionProps {
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>;
  matiereEmissionsInPage: MatiereEmissionInArray[];
  initialMatiere: MatiereEmissionInArray[];
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  initialCorrelation: CorrelationEmissionInArray[];
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  mesureEmissionsInPage: MesureEmissionInArray[];
  initialMesure: MesureEmissionInArray[];
  initialProcedes: ProcedeInArray[];
  covsInPage: CovInArray[];
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[];
  validationEmissionPath: string;
  referentialCO2: ReferenceItemPolluantDto;
}

const Emission = ({
  validationEmissionPath,
  setMatiereEmissionsInPage,
  initialMatiere,
  setCorrelationEmissionsInPage,
  initialCorrelation,
  setMesureEmissionsInPage,
  initialMesure,
  initialProcedes,
  correlationEmissionsInPage,
  mesureEmissionsInPage,
  matiereEmissionsInPage,
  covsInPage,
  substances,
  referentialCO2,
}: EmissionProps) => {
  const classes = useStyles();
  const [emissionModaleOpen, setEmissionModaleOpen] = useState(false);
  const [
    emissionInModale,
    setEmissionInModale,
  ] = useState<EmissionsInArray | null>(null);
  const openConfirmationModale = useConfirmationModale();

  const updateHandler = (declaration: Declaration1919) => {
    declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
      correlationEmissionsInPage,
      substances
    );
    declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
      mesureEmissionsInPage,
      substances
    );
    declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
      matiereEmissionsInPage,
      substances
    );
    declaration.body.sections.air.fugitives.flux = createAirFugitivesCovDto(
      covsInPage
    );
    return declaration;
  };

  const compareFunction = (
    a: EmissionsInArray,
    b: EmissionsInArray
  ): number => {
    if (
      a.data.substance &&
      b.data.substance &&
      a.data.substance.object.nom !== b.data.substance.object.nom
    ) {
      return a.data.substance.object.nom < b.data.substance.object.nom ? -1 : 1;
    }

    if (a.data.procede && b.data.procede && a.data.procede !== b.data.procede) {
      return a.data.procede < b.data.procede ? -1 : 1;
    }
    return 0;
  };

  return (
    <>
      <BlocFullContext1919
        title="Déclaration des émissions"
        hasModification={
          !isEqual(initialMesure, mesureEmissionsInPage) ||
          !isEqual(initialCorrelation, correlationEmissionsInPage) ||
          !isEqual(initialMatiere, matiereEmissionsInPage)
        }
        isValidateButtonAvailable={true}
        validationMessage={{
          message: TABS_VALIDATION_MESSAGE,
          isAlwaysVisible: true,
        }}
        path={validationEmissionPath}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => {
          setMesureEmissionsInPage(initialMesure);
          setMatiereEmissionsInPage(initialMatiere);
          setCorrelationEmissionsInPage(initialCorrelation);
        }}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER DES ÉMISSIONS"
                  onClick={() => {
                    setEmissionModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par mesure
              </span>
              <EmissionMesureArray
                mesureEmissionsInPage={_.cloneDeep(mesureEmissionsInPage).sort(
                  compareFunction
                )}
                setMesureEmissionsInPage={setMesureEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                validationEmissionPath={validationEmissionPath}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setMesureEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields || !mesureEmissionsInPage[0]}
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par facteur de corrélation
              </span>
              <EmissionCorrelationArray
                correlationEmissionsInPage={_.cloneDeep(
                  correlationEmissionsInPage
                ).sort(compareFunction)}
                setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                validationEmissionPath={validationEmissionPath}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setCorrelationEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={
                    shouldDisabledFields || !correlationEmissionsInPage[0]
                  }
                />
              </Row>
              <Row />
              <span className={classes.title}>
                Émissions calculées par bilan matière
              </span>
              <EmissionMatiereArray
                matiereEmissionsInPage={_.cloneDeep(
                  matiereEmissionsInPage
                ).sort(compareFunction)}
                setMatiereEmissionsInPage={setMatiereEmissionsInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                validationEmissionPath={validationEmissionPath}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setMatiereEmissionsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={
                    shouldDisabledFields || !matiereEmissionsInPage[0]
                  }
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <EmissionModale
        referentialCO2={referentialCO2}
        emissionModaleOpen={emissionModaleOpen}
        emissionInModale={emissionInModale}
        setMatiereEmissionsInPage={setMatiereEmissionsInPage}
        setCorrelationEmissionsInPage={setCorrelationEmissionsInPage}
        setMesureEmissionsInPage={setMesureEmissionsInPage}
        setEmissionInModale={setEmissionInModale}
        setEmissionModaleOpen={setEmissionModaleOpen}
        initialProcedes={initialProcedes}
        correlationEmissionsInPage={correlationEmissionsInPage}
        matiereEmissionsInPage={matiereEmissionsInPage}
        mesureEmissionsInPage={mesureEmissionsInPage}
        substances={substances}
      />
    </>
  );
};

export default Emission;
