import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import Button from "../../../../../common/button";
import { makeStyles } from "@material-ui/styles";
import { useDeclarationHelpers23Now } from "../versionedElements/declarationHooks23Now";

const useStyles = makeStyles({
  button: {
    padding: "5px",
  },
});

const RetourTableauDeBordButton = (): React.ReactElement => {
  const classes = useStyles();
  const { pathToDashboard } = useDeclarationHelpers23Now();
  const [shouldRedirectToDashboard, setShouldRedirectToDashboard] = useState<
    boolean
  >(false);

  if (shouldRedirectToDashboard) {
    return <Redirect to={pathToDashboard} />;
  }
  return (
    <Button
      text={"RETOUR AU TABLEAU DE BORD"}
      additionalClassname={classes.button}
      onClick={() => setShouldRedirectToDashboard(true)}
    />
  );
};

export default RetourTableauDeBordButton;
