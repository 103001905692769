import React, { Fragment, useState } from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  PopulatedTransportProductInArray,
  TransportProductsArrayForm,
} from "./utils/types";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  computeTotalProductsTonnage,
  computeTransportProductsArrayFormInitialValues,
} from "./utils/computers";
import {
  commonNullableStringFields,
  commonPercentageFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import TransportProductArray from "./TransportProductArray";
import TransportProductModal from "./TransportProductModal";
import Button from "common/button";
import { convertDeclarationDtoUsingTransportProductsForm } from "../utils/converters";
import { SECTION_TITLE_GREY } from "theme";
import _ from "lodash";
import { convertProduitTransportDtoToDisplayed } from "./utils/converters";
import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferencePaysDto,
} from "api/gen";
import { sumPercentagesMessage } from "common/declarant/formik/formikMessages";
import Row from "common/presentational/Row";
import {
  PATH_CARRIERE_PRODUCTION_TRANSPORT,
  PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE,
} from "common/path/path18Now";
import { familiesProduction } from "../Families/selectPossibleValues";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";

interface BlocTransportProduitsProps {
  initialTransportProducts: PopulatedTransportProductInArray[];
  transportProductsFormProps: FormikBlocRefAndState<TransportProductsArrayForm>;
  transportProductsInArray: PopulatedTransportProductInArray[];
  setTransportProductsInArray: (
    products: PopulatedTransportProductInArray[]
  ) => void;
  referentialCountries: ReferencePaysDto;
  referentialDepartements: ReferenceDepartementDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  containerSpace: {
    padding: "15px 10px 25px 10px",
    color: SECTION_TITLE_GREY,
  },
  buttonModal: {
    padding: "5px",
    marginLeft: "80%",
    marginTop: "20px",
  },
  fieldArrayTitles: {
    color: SECTION_TITLE_GREY,
    marginLeft: "20px",
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: "110%",
  },
});

const validationSchema = Yup.object().shape({
  productsTransport: Yup.array().of(
    Yup.object().shape({
      byRoad: commonPercentageFieldsNullable,
      byRail: commonPercentageFieldsNullable,
      byWaterWay: commonPercentageFieldsNullable,
      other: commonPercentageFieldsNullable,
      otherPrecision: Yup.string().when("other", {
        is: (other: number | null) => other !== null && other > 0,
        then: commonStringFields,
        otherwise: commonNullableStringFields,
      }),
    })
  ),
});

const BlocTransportProduits = ({
  initialTransportProducts,
  transportProductsFormProps,
  transportProductsInArray,
  setTransportProductsInArray,
  referentialCountries,
  referentialDepartements,
  referentialFamilies,
  referentialFamiliesCodeProduction,
}: BlocTransportProduitsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "carriere-production-transport",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [transportProductInModal, setTransportProductInModal] = useState<
    PopulatedTransportProductInArray | undefined
  >(undefined);

  const initialValues = computeTransportProductsArrayFormInitialValues(
    declaration,
    referentialFamilies
  );

  return (
    <>
      <FormikBlocFullContext1919
        hasChanges={transportProductsFormProps.hasChanges}
        setHasChanges={transportProductsFormProps.setHasChanges}
        formikRef={transportProductsFormProps.formikRef}
        title="TRANSPORT DES PRODUITS FINIS EXPÉDIÉS (TP4)"
        validationSchema={validationSchema}
        initialValues={initialValues}
        pathToValidate={PATH_CARRIERE_PRODUCTION_TRANSPORT}
        enableReinitialize
        updateHandler={(declaration, values) =>
          convertDeclarationDtoUsingTransportProductsForm(
            declaration,
            values,
            transportProductsInArray
          )
        }
        hasFormChanges={values => {
          return (
            !_.isEqual(
              values.productsTransport,
              initialValues.productsTransport
            ) || !_.isEqual(transportProductsInArray, initialTransportProducts)
          );
        }}
        cancelAction={() => {
          setTransportProductsInArray(
            convertProduitTransportDtoToDisplayed(
              declaration.body.sections.carriere.production.transport
                .tableauDestinations,
              referentialDepartements,
              referentialCountries,
              referentialFamilies,
              referentialFamiliesCodeProduction
            )
          );
          return initialValues;
        }}
        additionalFieldValidation={values => {
          const errors: { [k: string]: string } = {};
          values.productsTransport.forEach((productTransport, i) => {
            if (
              productTransport.byRail === null &&
              productTransport.byRoad === null &&
              productTransport.byWaterWay === null &&
              productTransport.other === null
            ) {
              return;
            }
            if (
              (productTransport.byRail || 0) +
                (productTransport.byRoad || 0) +
                (productTransport.byWaterWay || 0) +
                (productTransport.other || 0) !==
              100
            ) {
              errors[`productsTransport.${i}.byRoad`] = sumPercentagesMessage;
              errors[`productsTransport.${i}.byRail`] = sumPercentagesMessage;
              errors[
                `productsTransport.${i}.byWaterWay`
              ] = sumPercentagesMessage;
              errors[`productsTransport.${i}.other`] = sumPercentagesMessage;
            }
          });
          return errors;
        }}
        renderContent={({ values }, shouldDisabledFields: boolean) => (
          <>
            <div className={classes.containerSpace}>
              Les produits sont soit les produits vendus en sortie de carrières,
              soit issus de la première transformation en sortie d'usine ou
              d'atelier
            </div>
            <FieldArray
              name="productsTransport"
              render={() => (
                <div>
                  {familiesProduction(referentialFamilies).map(
                    (usageFamily, i) => (
                      <Fragment key={usageFamily.value}>
                        <h3 className={classes.fieldArrayTitles}>
                          {usageFamily.label}
                        </h3>

                        <NumberField
                          name={`productsTransport.${i}.byRoad`}
                          label="Par la route"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${usageFamily.value}/route`}
                        />

                        <NumberField
                          name={`productsTransport.${i}.byRail`}
                          label="Par le rail"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${usageFamily.value}/rail`}
                        />

                        <NumberField
                          name={`productsTransport.${i}.byWaterWay`}
                          label="Par voie navigable"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${usageFamily.value}/navigable`}
                        />

                        <NumberField
                          name={`productsTransport.${i}.other`}
                          label="Autre"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${usageFamily.value}/autre`}
                        />

                        {values.productsTransport[i] &&
                        values.productsTransport[i].other &&
                        // the as coercion is because there is a TS error even with the non-null check
                        (values.productsTransport[i].other as number) > 0 ? (
                          <TextField
                            name={`productsTransport.${i}.otherPrecision`}
                            label="Préciser autre *"
                            disabled={shouldDisabledFields}
                            commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${usageFamily.value}/preciserAutre`}
                          />
                        ) : (
                          ""
                        )}
                      </Fragment>
                    )
                  )}
                </div>
              )}
            />
            <div className={classes.containerSpace}>
              Complétez le tableau suivant en cliquant sur le bouton "Ajouter un
              produit" et en renseignant les caractéristiques demandées
              (destination, tonnage, etc.)
            </div>

            <Button
              text="AJOUTER UN PRODUIT"
              additionalClassname={classes.buttonModal}
              isDisabled={shouldDisabledFields}
              onClick={() => setIsModalOpen(true)}
            />

            <TransportProductArray
              transportProductsInArray={transportProductsInArray}
              setTransportProductsInArray={setTransportProductsInArray}
              onEdit={product => {
                setTransportProductInModal(product);
                setIsModalOpen(true);
              }}
              isDisabled={shouldDisabledFields}
            />

            <TransportProductModal
              isOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              transportProductInModal={transportProductInModal}
              setTransportProductInModal={setTransportProductInModal}
              transportProductsInArray={transportProductsInArray}
              setTransportProductsInArray={setTransportProductsInArray}
              referentialCountries={referentialCountries}
              referentialDepartements={referentialDepartements}
              referentialFamilies={referentialFamilies}
              referentialFamiliesCodeProduction={
                referentialFamiliesCodeProduction
              }
            />

            <Row />

            <DummyNumberField
              name="totalTonnage"
              label="Total produits expédiés"
              unit="ktonnes"
              disabled
              value={computeTotalProductsTonnage(transportProductsInArray)}
            />
          </>
        )}
      />
    </>
  );
};

export default BlocTransportProduits;
