import { makeStyles } from "@material-ui/styles";
import React from "react";
import Row from "./Row";
import {
  HRULE_DEFAULT_PADDING_AFTER,
  HRULE_DEFAULT_PADDING_BEFORE,
} from "theme";

const useStyles = makeStyles({
  hrule: {
    display: "block",
    height: "1px",
    border: 0,
    borderTop: "1px solid #ccc",
    margin: "1em",
    width: "100%",
  },
});

interface HRuleProps {
  paddingBefore?: string;
  paddingAfter?: string;
}

const HRule = ({
  paddingBefore = HRULE_DEFAULT_PADDING_BEFORE,
  paddingAfter = HRULE_DEFAULT_PADDING_AFTER,
}: HRuleProps) => {
  const classes = useStyles();
  return (
    <>
      {!!paddingBefore && <Row height={paddingBefore} />}
      <Row height="1em">
        <hr className={classes.hrule} />
      </Row>
      {!!paddingAfter && <Row height={paddingAfter} />}
    </>
  );
};

export default HRule;
