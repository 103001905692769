import React from "react";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  WASTE_CODE_DISPLAY_COL_WIDTH,
  WASTE_LIBELLE_DISPLAY_COL_WIDTH,
} from "../utils/Constants";
import { ComputedSyntheseElementDto } from "api/gen";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { WasteReferentiels } from "../types";
import { formatWasteLabel } from "../utils/formater";
import { findElementMatchingTemplate } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";

interface WasteSyntheseArrayProps {
  lines: ComputedSyntheseElementDto[];
  referentials: WasteReferentiels;
  isReceptionAvailable: boolean;
}

export const WasteSyntheseArray = ({
  lines,
  referentials,
  isReceptionAvailable,
}: WasteSyntheseArrayProps): React.ReactElement => {
  return (
    <DummyUnActionedBloc
      title={`Bilan des déchets produits${
        isReceptionAvailable ? " et/ou des déchets traités" : ""
      } par l'établissement`}
      renderContent={() => {
        return (
          <CommonEntityTable
            header={[
              <p>Code déchet</p>,
              <p>Dénomination</p>,
              <p>Production / Traitement</p>,
              <p>Quantité (t/an)</p>,
              <p>Commentaires</p>,
            ]}
            lines={lines.map(line => {
              const dechet = findElementMatchingTemplate(
                { uuid: line.codeID },
                referentials.polluants
              );

              const codeDechet = formatWasteLabel(dechet, line.codeID);

              return [
                <span>{codeDechet}</span>,
                <span>{dechet ? dechet.libelle : line.codeID}</span>,
                //we capitalize the first letter of string. copied from https://flaviocopes.com/how-to-uppercase-first-letter-javascript/
                <span>{`${line.operation
                  .charAt(0)
                  .toUpperCase()}${line.operation
                  .slice(1)
                  .toLowerCase()}`}</span>,
                <span>{computeNumberWithSeparator(line.quantite)}</span>,
                <CommonEntityButton
                  commentPath={`/${line.id}`}
                  isValidated={true}
                />,
              ];
            })}
            preferredColWidths={[
              WASTE_CODE_DISPLAY_COL_WIDTH,
              WASTE_LIBELLE_DISPLAY_COL_WIDTH,
              100,
              100,
              ACTION_COLUMN_DEFAULT_WIDTH,
            ]}
          />
        );
      }}
    />
  );
};
