import React, { useState } from "react";
import BlocActiviteExtractive from "./BlocActiviteExtractive";
import BlocInformationsTransformation from "./BlocInformationsTransformation";
import BlocActiviteRecyclage from "./BlocActiviteRecyclage";
import BlocTransportProduits from "./BlocTransportProduits";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  ExtractiveActivityForm,
  PopulatedSubstanceExtractionInArray,
} from "./BlocActiviteExtractive/utils/types";
import { convertSubstanceExtractionDtoToDisplayed } from "./BlocActiviteExtractive/utils/converters";
import {
  FactoryInArray,
  TransformationInformationsForm,
} from "./BlocInformationsTransformation/utils/types";
import { convertActiviteTransformationDtoToDisplayed } from "./BlocInformationsTransformation/utils/converters";
import {
  RecyclingActivityForm,
  PopulatedRecyclingProductInArray,
} from "./BlocActiviteRecyclage/utils/types";
import { convertProduitRecyclageDtoToDisplayed } from "./BlocActiviteRecyclage/utils/converters";
import {
  TransportProductsArrayForm,
  PopulatedTransportProductInArray,
} from "./BlocTransportProduits/utils/types";
import { convertProduitTransportDtoToDisplayed } from "./BlocTransportProduits/utils/converters";
import {
  convertDeclarationDtoUsingExtractiveActivityForm,
  convertDeclarationDtoUsingTransformationInformationsForm,
  convertDeclarationDtoUsingRecyclingActivityForm,
  convertDeclarationDtoUsingTransportProductsForm,
} from "./utils/converters";
import Row from "common/presentational/Row";
import {
  ReferenceSubstanceCarriereDto,
  ReferencePaysDto,
  ReferenceDepartementDto,
  ReferenceGeoCodeInseeDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceFamilleUsageRecyclageDto,
  ReferenceFamilleUsageCodeProduitDto,
} from "api/gen";
import {
  PATH_CARRIERE_PRODUCTION_EXTRACTION,
  PATH_CARRIERE_PRODUCTION_TRANSFORMATION,
  PATH_CARRIERE_PRODUCTION_RECYCLAGE,
  PATH_CARRIERE_PRODUCTION_TRANSPORT,
  PATH_CARRIERE_PRODUCTION,
} from "common/path/path18Now";
import {
  useDeclaration2022,
  useDeclarationHelpers2022,
} from "../../versionedElements/declarationHooks2022";
import GlobalFormActionFullContext2022 from "../../versionedElements/GlobalFormActionFullContext2022";

interface MainProductionFormProps {
  referentialSubstances: ReferenceSubstanceCarriereDto;
  referentialCountries: ReferencePaysDto;
  referentialDepartments: ReferenceDepartementDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
  referentialFamiliesProduction: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
  referentialFamiliesRecycling: ReferenceFamilleUsageRecyclageDto;
}

const MainProductionForm = ({
  referentialCountries,
  referentialDepartments,
  referentialSubstances,
  referentialInsee,
  referentialFamiliesProduction,
  referentialFamiliesCodeProduction,
  referentialFamiliesRecycling,
}: MainProductionFormProps) => {
  const declaration = useDeclaration2022();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2022();

  const initialSubstances = convertSubstanceExtractionDtoToDisplayed(
    declaration.body.sections.carriere.production.extraction.substances,
    referentialSubstances,
    referentialFamiliesProduction
  );
  const [
    substancesExtractionInArray,
    setSubstancesExtractionInArray,
  ] = useState<PopulatedSubstanceExtractionInArray[]>(initialSubstances);

  const initialFactories = convertActiviteTransformationDtoToDisplayed(
    declaration.body.sections.carriere.production.transformation
      .activiteTransformations || [],
    referentialCountries,
    referentialInsee
  );
  const [factoriesInArray, setFactoriesInArray] = useState<FactoryInArray[]>(
    initialFactories
  );

  const initialRecycling = convertProduitRecyclageDtoToDisplayed(
    declaration.body.sections.carriere.production.recyclage.tableauRecyclage ||
      [],
    referentialFamiliesRecycling
  );
  const [recyclingProductsInArray, setRecyclingProductsInArray] = useState<
    PopulatedRecyclingProductInArray[]
  >(initialRecycling);

  const initialTransport = convertProduitTransportDtoToDisplayed(
    declaration.body.sections.carriere.production.transport.tableauDestinations,
    referentialDepartments,
    referentialCountries,
    referentialFamiliesProduction,
    referentialFamiliesCodeProduction
  );
  const [transportProductsInArray, setTransportProductsInArray] = useState<
    PopulatedTransportProductInArray[]
  >(initialTransport);

  const extractiveActivityFormProps = useFormikBloc<ExtractiveActivityForm>();
  const transformationInformationFormProps = useFormikBloc<
    TransformationInformationsForm
  >();
  const recyclingActivityFormProps = useFormikBloc<RecyclingActivityForm>();
  const transportProductsFormProps = useFormikBloc<
    TransportProductsArrayForm
  >();

  const isGlobalValidateAvailable =
    isPathValidatedInDeclaration(PATH_CARRIERE_PRODUCTION_EXTRACTION) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_PRODUCTION_TRANSFORMATION) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_PRODUCTION_RECYCLAGE) &&
    isPathValidatedInDeclaration(PATH_CARRIERE_PRODUCTION_TRANSPORT);

  return (
    <>
      <BlocActiviteExtractive
        substancesExtractionInArray={substancesExtractionInArray}
        setSubstancesExtractionInArray={setSubstancesExtractionInArray}
        extractiveActivityFormProps={extractiveActivityFormProps}
        referentialSubstances={referentialSubstances}
        referentialFamilies={referentialFamiliesProduction}
      />

      <Row />

      <BlocInformationsTransformation
        factoriesInArray={factoriesInArray}
        setFactoriesInArray={setFactoriesInArray}
        transformationInformationsFormProps={transformationInformationFormProps}
        referentialCountries={referentialCountries}
        referentialInsee={referentialInsee}
      />

      <Row />

      <BlocActiviteRecyclage
        recyclingActivityFormProps={recyclingActivityFormProps}
        recyclingProductsInArray={recyclingProductsInArray}
        setRecyclingProductsInArray={setRecyclingProductsInArray}
        referentialFamilies={referentialFamiliesRecycling}
      />

      <Row />

      <BlocTransportProduits
        initialTransportProducts={initialTransport}
        transportProductsFormProps={transportProductsFormProps}
        transportProductsInArray={transportProductsInArray}
        setTransportProductsInArray={setTransportProductsInArray}
        referentialCountries={referentialCountries}
        referentialDepartements={referentialDepartments}
        referentialFamilies={referentialFamiliesProduction}
        referentialFamiliesCodeProduction={referentialFamiliesCodeProduction}
      />

      <GlobalFormActionFullContext2022
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        validationPath={PATH_CARRIERE_PRODUCTION}
        isValidateEnabled={isGlobalValidateAvailable}
        hasChanges={
          extractiveActivityFormProps.hasChanges ||
          transformationInformationFormProps.hasChanges ||
          recyclingActivityFormProps.hasChanges ||
          transportProductsFormProps.hasChanges
        }
        updateHandler={declaration => {
          if (
            extractiveActivityFormProps.formikRef &&
            extractiveActivityFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingExtractiveActivityForm(
              declaration,
              extractiveActivityFormProps.formikRef.current.state.values,
              referentialSubstances,
              substancesExtractionInArray
            );
          }
          if (
            transformationInformationFormProps.formikRef &&
            transformationInformationFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingTransformationInformationsForm(
              declaration,
              transformationInformationFormProps.formikRef.current.state.values,
              factoriesInArray
            );
          }
          if (
            recyclingActivityFormProps.formikRef &&
            recyclingActivityFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingRecyclingActivityForm(
              declaration,
              recyclingActivityFormProps.formikRef.current.state.values,
              recyclingProductsInArray
            );
          }
          if (
            transportProductsFormProps.formikRef &&
            transportProductsFormProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingTransportProductsForm(
              declaration,
              transportProductsFormProps.formikRef.current.state.values,
              transportProductsInArray
            );
          }
          return declaration;
        }}
        cancelAction={() => {
          if (
            extractiveActivityFormProps.formikRef &&
            extractiveActivityFormProps.formikRef.current
          ) {
            extractiveActivityFormProps.formikRef.current.resetForm();
          }
          if (
            transformationInformationFormProps.formikRef &&
            transformationInformationFormProps.formikRef.current
          ) {
            transformationInformationFormProps.formikRef.current.resetForm();
          }
          if (
            recyclingActivityFormProps.formikRef &&
            recyclingActivityFormProps.formikRef.current
          ) {
            recyclingActivityFormProps.formikRef.current.resetForm();
          }
          if (
            transportProductsFormProps.formikRef &&
            transportProductsFormProps.formikRef.current
          ) {
            transportProductsFormProps.formikRef.current.resetForm();
          }
        }}
      />
    </>
  );
};

export default MainProductionForm;
