import React from "react";

export const EtatAdministratifHelper = () => {
  return (
    <>
      Cette information est sourcée depuis l'INSEE mais elle ne vaut pas
      déclaration de cessation d'activité au titre de l'article R512-75-1 du
      code de l'environnement.
    </>
  );
};
