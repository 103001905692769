import {
  FileDto,
  QuotasBlocVerificationDto1819,
  QuotasVerificationDto1819,
  QuotasVerificationDto1819ConclusionEnum,
  QuotasVerificationDto1819ConditionDispenseEnum,
  QuotasVerificationDto1819MotifsNonVerifieeEnum,
  QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum,
} from "api/gen/api";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import _ from "lodash";
import { InstallationInArray } from "../blocInstallations/types";
import { selectPossibleValues } from "./selectPossibleValues";
import {
  BlocVerificationFormValues,
  BlocVerificationFormValuesProps,
  QuotasVerificationProps,
} from "./types";
import { findNimFromInstallationId } from "./utils";

const exemptionConditionEnumToOptionProps = (
  conditionDispense: QuotasVerificationDto1819ConditionDispenseEnum | null
): OptionProps | null => {
  if (!conditionDispense) {
    return null;
  }
  switch (conditionDispense) {
    case QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_I:
      return selectPossibleValues.exemptionConditions[0];
    case QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_II_A:
      return selectPossibleValues.exemptionConditions[1];
    case QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_II_B:
      return selectPossibleValues.exemptionConditions[2];
    case QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_III:
      return selectPossibleValues.exemptionConditions[3];
    case QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_IV:
      return selectPossibleValues.exemptionConditions[4];
    default:
      throw new ShouldNotHappen(conditionDispense);
  }
};

const exemptionConditionOptionPropsToEnum = (
  exemptionCondition: OptionProps | null
): QuotasVerificationDto1819ConditionDispenseEnum | null => {
  if (!exemptionCondition) {
    return null;
  }
  if (
    _.isEqual(exemptionCondition, selectPossibleValues.exemptionConditions[0])
  ) {
    return QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_I;
  }
  if (
    _.isEqual(exemptionCondition, selectPossibleValues.exemptionConditions[1])
  ) {
    return QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_II_A;
  }
  if (
    _.isEqual(exemptionCondition, selectPossibleValues.exemptionConditions[2])
  ) {
    return QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_II_B;
  }
  if (
    _.isEqual(exemptionCondition, selectPossibleValues.exemptionConditions[3])
  ) {
    return QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_III;
  }
  if (
    _.isEqual(exemptionCondition, selectPossibleValues.exemptionConditions[4])
  ) {
    return QuotasVerificationDto1819ConditionDispenseEnum.CONDITION_IV;
  }

  throw Error(`Error converting ${exemptionCondition} to Enum`);
};

const verificationReportConclusionEnumToOptionProps = (
  conclusion: QuotasVerificationDto1819ConclusionEnum | null
): OptionPropsWithObject<QuotasVerificationProps> | null => {
  if (!conclusion) {
    return null;
  }
  switch (conclusion) {
    case QuotasVerificationDto1819ConclusionEnum.SATISFAISANT:
      return selectPossibleValues.verificationReportConclusion[0];
    case QuotasVerificationDto1819ConclusionEnum.SATISFAISANT_AVEC_REMARQUE:
      return selectPossibleValues.verificationReportConclusion[1];
    case QuotasVerificationDto1819ConclusionEnum.NON_VERIFIEE:
      return selectPossibleValues.verificationReportConclusion[2];
    default:
      throw new ShouldNotHappen(conclusion);
  }
};

const verificationReportConclusionOptionPropsToEnum = (
  verificationReportConclusion: OptionProps | null
): QuotasVerificationDto1819ConclusionEnum | null => {
  if (!verificationReportConclusion) {
    return null;
  }

  if (
    _.isEqual(
      verificationReportConclusion,
      selectPossibleValues.verificationReportConclusion[0]
    )
  ) {
    return QuotasVerificationDto1819ConclusionEnum.SATISFAISANT;
  }

  if (
    _.isEqual(
      verificationReportConclusion,
      selectPossibleValues.verificationReportConclusion[1]
    )
  ) {
    return QuotasVerificationDto1819ConclusionEnum.SATISFAISANT_AVEC_REMARQUE;
  }

  if (
    _.isEqual(
      verificationReportConclusion,
      selectPossibleValues.verificationReportConclusion[2]
    )
  ) {
    return QuotasVerificationDto1819ConclusionEnum.NON_VERIFIEE;
  }

  throw Error(`Error converting ${verificationReportConclusion} to Enum`);
};

const satisfyingDeclarationReasonEnumToOptionProps = (
  motifsSatisfaisaitAvecRemarque: QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum
): OptionProps => {
  switch (motifsSatisfaisaitAvecRemarque) {
    case QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.INEXACTITUDE:
      return selectPossibleValues.satisfyingDeclarationReasons[0];
    case QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.IRREGULARITE:
      return selectPossibleValues.satisfyingDeclarationReasons[1];
    case QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.NON_CONFORME_PLAN_SURVEILLANCE:
      return selectPossibleValues.satisfyingDeclarationReasons[2];
    case QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.NON_RESPECT_MRR:
      return selectPossibleValues.satisfyingDeclarationReasons[3];
    case QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.RECOMMANDATION_AMELIORATION:
      return selectPossibleValues.satisfyingDeclarationReasons[4];
    default:
      throw new ShouldNotHappen(motifsSatisfaisaitAvecRemarque);
  }
};

const satisfyingDeclarationReasonOptionPropsToEnum = (
  satisfyingDeclarationReason: OptionProps
): QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum => {
  if (
    _.isEqual(
      satisfyingDeclarationReason,
      selectPossibleValues.satisfyingDeclarationReasons[0]
    )
  ) {
    return QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.INEXACTITUDE;
  }

  if (
    _.isEqual(
      satisfyingDeclarationReason,
      selectPossibleValues.satisfyingDeclarationReasons[1]
    )
  ) {
    return QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.IRREGULARITE;
  }

  if (
    _.isEqual(
      satisfyingDeclarationReason,
      selectPossibleValues.satisfyingDeclarationReasons[2]
    )
  ) {
    return QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.NON_CONFORME_PLAN_SURVEILLANCE;
  }

  if (
    _.isEqual(
      satisfyingDeclarationReason,
      selectPossibleValues.satisfyingDeclarationReasons[3]
    )
  ) {
    return QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.NON_RESPECT_MRR;
  }

  if (
    _.isEqual(
      satisfyingDeclarationReason,
      selectPossibleValues.satisfyingDeclarationReasons[4]
    )
  ) {
    return QuotasVerificationDto1819MotifsStatisfaisantAvecRemarqueEnum.RECOMMANDATION_AMELIORATION;
  }

  throw Error(`Error converting ${satisfyingDeclarationReason} to Enum`);
};

const uncheckedDeclarationReasonEnumToOptionProps = (
  motifsNonVerifiee: QuotasVerificationDto1819MotifsNonVerifieeEnum
): OptionProps => {
  switch (motifsNonVerifiee) {
    case QuotasVerificationDto1819MotifsNonVerifieeEnum.INEXACTITUDE:
      return selectPossibleValues.uncheckedDeclarationReasons[0];
    case QuotasVerificationDto1819MotifsNonVerifieeEnum.NON_CONFORME:
      return selectPossibleValues.uncheckedDeclarationReasons[1];
    case QuotasVerificationDto1819MotifsNonVerifieeEnum.LIMITATION_DONNEES:
      return selectPossibleValues.uncheckedDeclarationReasons[2];
    case QuotasVerificationDto1819MotifsNonVerifieeEnum.PORTEE_LIMITEE:
      return selectPossibleValues.uncheckedDeclarationReasons[3];
    case QuotasVerificationDto1819MotifsNonVerifieeEnum.PLAN_SURVEILLANCE_NON_APPROUVE:
      return selectPossibleValues.uncheckedDeclarationReasons[4];
    default:
      throw new ShouldNotHappen(motifsNonVerifiee);
  }
};

const uncheckedDeclarationReasonOptionPropsToEnum = (
  uncheckedDeclarationReason: OptionProps
): QuotasVerificationDto1819MotifsNonVerifieeEnum => {
  if (
    _.isEqual(
      uncheckedDeclarationReason,
      selectPossibleValues.uncheckedDeclarationReasons[0]
    )
  ) {
    return QuotasVerificationDto1819MotifsNonVerifieeEnum.INEXACTITUDE;
  }

  if (
    _.isEqual(
      uncheckedDeclarationReason,
      selectPossibleValues.uncheckedDeclarationReasons[1]
    )
  ) {
    return QuotasVerificationDto1819MotifsNonVerifieeEnum.NON_CONFORME;
  }

  if (
    _.isEqual(
      uncheckedDeclarationReason,
      selectPossibleValues.uncheckedDeclarationReasons[2]
    )
  ) {
    return QuotasVerificationDto1819MotifsNonVerifieeEnum.LIMITATION_DONNEES;
  }

  if (
    _.isEqual(
      uncheckedDeclarationReason,
      selectPossibleValues.uncheckedDeclarationReasons[3]
    )
  ) {
    return QuotasVerificationDto1819MotifsNonVerifieeEnum.PORTEE_LIMITEE;
  }

  if (
    _.isEqual(
      uncheckedDeclarationReason,
      selectPossibleValues.uncheckedDeclarationReasons[4]
    )
  ) {
    return QuotasVerificationDto1819MotifsNonVerifieeEnum.PLAN_SURVEILLANCE_NON_APPROUVE;
  }

  throw Error(`Error converting ${uncheckedDeclarationReason} to Enum`);
};

const QuotasVerificationDto1819ToBlocVerificationFormValuesProps = (
  QuotasVerificationDto1819: QuotasVerificationDto1819
): BlocVerificationFormValuesProps => {
  return {
    installationId: QuotasVerificationDto1819.id,
    organizationName: QuotasVerificationDto1819.nomOrganisme,
    monitoringPlanReference:
      QuotasVerificationDto1819.referenceDernierPlanSurveillance,
    visitExemption: QuotasVerificationDto1819.dispenseVisiteSite,
    conditionExemption: exemptionConditionEnumToOptionProps(
      QuotasVerificationDto1819.conditionDispense
    ),
    lastVisitDate: QuotasVerificationDto1819.dateDerniereVisite
      ? new Date(QuotasVerificationDto1819.dateDerniereVisite)
      : null,
    improvementReport: QuotasVerificationDto1819.rapportAmeliorationRequis,
    verificationReportConclusion: verificationReportConclusionEnumToOptionProps(
      QuotasVerificationDto1819.conclusion
    ),
    satisfyingDeclarationReason: QuotasVerificationDto1819.motifsStatisfaisantAvecRemarque.map(
      satisfyingDeclarationReasonEnumToOptionProps
    ),
    uncheckedDeclarationReason: QuotasVerificationDto1819.motifsNonVerifiee.map(
      uncheckedDeclarationReasonEnumToOptionProps
    ),
  };
};

const blocVerificationFormValuesPropsToQuotasVerificationDto1819 = (
  blocVerificationFormValuesPropsParam: BlocVerificationFormValuesProps,
  installationsInArray: InstallationInArray[]
): QuotasVerificationDto1819 => {
  const blocVerificationFormValuesProps = _.cloneDeep(
    blocVerificationFormValuesPropsParam
  );
  return {
    id: blocVerificationFormValuesProps.installationId,
    nim: findNimFromInstallationId(
      blocVerificationFormValuesProps.installationId,
      installationsInArray
    ),
    nomOrganisme: blocVerificationFormValuesProps.organizationName,
    referenceDernierPlanSurveillance:
      blocVerificationFormValuesProps.monitoringPlanReference,
    dispenseVisiteSite: blocVerificationFormValuesProps.visitExemption,
    conditionDispense: exemptionConditionOptionPropsToEnum(
      blocVerificationFormValuesProps.conditionExemption
    ),
    dateDerniereVisite: blocVerificationFormValuesProps.lastVisitDate
      ? blocVerificationFormValuesProps.lastVisitDate
          .toISOString()
          .split(".")[0] + "Z"
      : null,
    rapportAmeliorationRequis:
      blocVerificationFormValuesProps.improvementReport,
    conclusion: verificationReportConclusionOptionPropsToEnum(
      blocVerificationFormValuesProps.verificationReportConclusion
    ),
    motifsStatisfaisantAvecRemarque: blocVerificationFormValuesProps.satisfyingDeclarationReason.map(
      satisfyingDeclarationReasonOptionPropsToEnum
    ),
    motifsNonVerifiee: blocVerificationFormValuesProps.uncheckedDeclarationReason.map(
      uncheckedDeclarationReasonOptionPropsToEnum
    ),
  };
};

export const dtoToBlocValues = (
  dto: QuotasBlocVerificationDto1819,
  installationsInArray: InstallationInArray[]
): BlocVerificationFormValues => {
  return {
    props: installationsInArray.map(installation => {
      const verification = dto.verifications.find(
        v => v.nim === installation.data.nim
      );
      if (verification) {
        return QuotasVerificationDto1819ToBlocVerificationFormValuesProps(
          verification
        );
      } else {
        return {
          installationId: installation.data.id,
          organizationName: null,
          monitoringPlanReference: null,
          visitExemption: false,
          conditionExemption: null,
          lastVisitDate: null,
          improvementReport: false,
          verificationReportConclusion: null,
          satisfyingDeclarationReason: [],
          uncheckedDeclarationReason: [],
        };
      }
    }),
  };
};

export const blocValuesToDto = (
  values: BlocVerificationFormValues,
  files: FileDto[],
  installationsInArray: InstallationInArray[]
): QuotasBlocVerificationDto1819 => {
  return {
    rapports: files,
    verifications: values.props.map(val =>
      blocVerificationFormValuesPropsToQuotasVerificationDto1819(
        val,
        installationsInArray
      )
    ),
  };
};
