import React from "react";
import FieldWrapperWithTextLabel from "../wrappers/FieldWrapperWithTextLabel";
import { ChoiceSelectModalProps } from "../types/connectedTypes";
import { WrappedFieldProps } from "../types/basicTypes";
import { CommonProps } from "../../utils";
import ChoiceSelectModal from "../connectedInput/ChoiceSelectModal";
import { Omit } from "react-router";

interface WrappedChoiceSelectModaleProps<T>
  extends Omit<ChoiceSelectModalProps<T>, "field" | "form" | "id">,
    WrappedFieldProps {
  commonProps: CommonProps;
}

export function WrappedChoiceSelectModale<T>(
  props: WrappedChoiceSelectModaleProps<T>
): React.ReactElement {
  const isDisabled =
    props.externalValue !== undefined ||
    (props.disabled ?? props.commonProps.disabled);

  return (
    <FieldWrapperWithTextLabel
      name={props.name}
      label={props.label}
      commonProps={props.commonProps}
      tooltipContent={props.tooltipContent}
      disabled={isDisabled}
      render={(fieldProps, id) => {
        return (
          <ChoiceSelectModal
            field={fieldProps.field}
            form={fieldProps.form}
            id={id}
            disabled={isDisabled}
            additionalClassName={props.additionalClassName}
            style={props.style}
            title={props.title}
            header={props.header}
            linesData={props.linesData}
            formatLine={props.formatLine}
            formatSelectedTitle={props.formatSelectedTitle}
            placeholder={props.placeholder}
            isLineInSearch={props.isLineInSearch}
            hideValue={props.hideValue}
            onSelect={props.onSelect}
            isFirstSticky={props.isFirstSticky}
            isLastSticky={props.isLastSticky}
            preferredColWidths={props.preferredColWidths}
            cellStyles={props.cellStyles}
            externalValue={props.externalValue}
          />
        );
      }}
    />
  );
}
