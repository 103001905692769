import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useChoiceSelectFieldGenerator,
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  LEFT_WITHDRAW_STYLE,
  LONG_TEXT_INPUT_WIDTH,
  SECTION_TITLE_GREY,
} from "theme";
import {
  additionalValidations,
  anySubPartActivated,
  correlationSubPartActivated,
  isCo2,
  matiereSubPartActivated,
  mesureSubPartActivated,
  singleEmissionOverwriteDependantFields,
  singleEmissionValidationSchema,
} from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import MessageInfoField from "common/form/MessageInfoField";
import { arrayMethods } from "./utils/selectPossibleValues";
import { valeursConcentrationMessage } from "common/declarant/formik/formikMessages";
import { computeEmissionAnnuelle } from "./utils/calculus";
import {
  getAllProcedes,
  getMasseVolumiqueValue,
  getQuantityValue,
  getUnitCorrelationOptions,
  getUnitLabel,
} from "./utils/gettersFunctions";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import { isSearchStringInCollection } from "common/utils/methods";
import { ProcedeInArray } from "../listprocede/utils/types";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import {
  CorrelationEmissionInArray,
  EmissionsInArray,
  EmissionsInModale,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { ValueType } from "libAdapter/ReactSelect/TypesPatternAdapter";

interface FormEmissionProps {
  closeFunction: () => void;
  onSubmit: (
    values: EmissionsInModale,
    formikActions: FormikActions<EmissionsInModale>
  ) => void;
  initialEmission: EmissionsInModale;
  isEdit: boolean;
  initialProcedes: ProcedeInArray[];
  allowedCouples: EmissionsInArray | null;
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[];
  referentialCO2: ReferenceItemPolluantDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  calculatedField: {
    backgroundColor: "white",
    color: SECTION_TITLE_GREY,
    border: `1px solid ${SECTION_TITLE_GREY}`,
    "& input": {
      backgroundColor: "white",
    },
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
  mBottom: {
    marginBottom: "5px",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const FormEmission = ({
  closeFunction,
  onSubmit,
  initialEmission,
  initialProcedes,
  isEdit,
  allowedCouples,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  mesureEmissionsInPage,
  substances,
  referentialCO2,
}: FormEmissionProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emission-procedes",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const SingleChoiceSelectField = useChoiceSelectFieldGenerator<
    OptionProps,
    false
  >(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE ÉMISSION"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: EmissionsInModale, formikBag) => {
        if (values.procede === null) {
          throw Error(
            "la validation devrait obliger la sélection d'un procédé"
          );
        }
        values.emissionAnnuelle = computeEmissionAnnuelle(
          values,
          initialProcedes
        );
        values.quantiteMatiereProcede = getQuantityValue(
          values.procede,
          initialProcedes
        );
        singleEmissionOverwriteDependantFields(values, referentialCO2);
        values.uniteProcede = getUnitLabel(values.procede, initialProcedes);
        values.masseVolumiqueProcede = getMasseVolumiqueValue(
          values.procede,
          initialProcedes
        );
        onSubmit(values, formikBag);
      }}
      initialEntity={initialEmission}
      validationSchema={() => singleEmissionValidationSchema(referentialCO2)}
      validate={values =>
        additionalValidations(
          values,
          allowedCouples,
          mesureEmissionsInPage,
          correlationEmissionsInPage,
          matiereEmissionsInPage,
          initialProcedes
        )
      }
      renderField={({ values, setFieldValue, setFieldTouched }) => {
        const handleOnChangeUniteCorrelationWithoutChangingProcede = () => {
          setFieldValue("uniteCorrelation", null);
          setFieldTouched("uniteCorrelation", false);
        };

        const handleOnChangeUniteCorrelationChangingProcede = (
          procedeValue: ValueType<OptionProps, false>
        ) => {
          handleOnChangeUniteCorrelationWithoutChangingProcede();
          setFieldValue("procede", [procedeValue]);
        };

        return (
          <>
            <WrappedChoiceSelectModale
              name="substance"
              label="Substance *"
              title="SUBSTANCES DANS L'AIR"
              header={["CAS", "Libellé", "Seuil (kg/an)"]}
              linesData={substances}
              formatLine={substance => [
                substance.object.cas || "",
                substance.label,
                substance.object.seuilKgAn !== null
                  ? computeNumberWithSeparator(substance.object.seuilKgAn)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.label}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.object.cas, lineData.label],
                  searchedStr
                )
              }
              preferredColWidths={[80, 350, 80]}
              isFirstSticky={false}
              commonProps={commonProps}
            />
            <ChoiceSelectField
              name="methods"
              label="Choix de la méthode *"
              isMulti={false}
              options={arrayMethods}
            />
            {(mesureSubPartActivated(values.methods) ||
              matiereSubPartActivated(values.methods)) && (
              <ChoiceSelectField
                isMulti
                name="procede"
                // When value === 2, it is Facteur de correlation
                label={"Procédé(s) *"}
                options={getAllProcedes(initialProcedes)}
                additionalOnChange={e =>
                  handleOnChangeUniteCorrelationWithoutChangingProcede()
                }
              />
            )}
            {correlationSubPartActivated(values.methods) && (
              <SingleChoiceSelectField
                name="procede"
                label={"Procédé *"}
                isMulti={false}
                options={getAllProcedes(initialProcedes)}
                additionalOnChange={e =>
                  handleOnChangeUniteCorrelationChangingProcede(e)
                }
              />
            )}
            {mesureSubPartActivated(values.methods) && (
              <>
                <NumberField
                  name="debitHoraire"
                  label="Débit horaire *"
                  unit="Nm³/h"
                />
                <NumberField
                  name="heureFonctionnement"
                  label="Nombre d'heures de fonctionnement *"
                  unit="h/an"
                  tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
                />
                <BooleanField
                  name="mesureContinueDebit"
                  label="Mesure en continu du débit"
                />
                {values.mesureContinueDebit === false && (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceMesureDebit"
                      label="Nombre de mesures *"
                      unit="mesures/an"
                    />
                  </div>
                )}
                <NumberField
                  name="concentrationMoyenne"
                  label="Concentration moyenne *"
                  unit="kg/Nm³"
                />
                <BooleanField
                  name="mesureContinueConcentration"
                  label="Mesure en continu de la concentration"
                />
                {values.mesureContinueConcentration === true ? (
                  <MessageInfoField message={valeursConcentrationMessage} />
                ) : (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceMesureConcentration"
                      label="Nombre de mesures *"
                      unit="mesures/an"
                    />
                  </div>
                )}
              </>
            )}
            {correlationSubPartActivated(values.methods) && (
              <>
                <DummyNumberField
                  name="quantiteMatiereProcede"
                  label="Quantité de matière première ou volume d'activité"
                  disabled
                  additionalClassName={classes.calculatedField}
                  value={
                    values.procede !== null && values.procede !== undefined
                      ? getQuantityValue(values.procede, initialProcedes)
                      : 0
                  }
                  unit=""
                />
                <DummyTextField
                  name="uniteProcede"
                  label="Unité"
                  disabled
                  additionalClassName={classes.calculatedField}
                  value={
                    values.procede !== null
                      ? getUnitLabel(values.procede, initialProcedes)
                      : ""
                  }
                />
                <TextField
                  name="natureCorrelation"
                  label="Nature de la corrélation"
                  tooltipContent="Préciser la corrélation qu'elle soit simple ou multiple.
                  Exemple de corrélation simple : x kg de NOx émis pour 1 tonne d'acier produit."
                />
                <NumberField
                  name="facteurCorrelation"
                  label="Facteur de corrélation *"
                  unit=""
                />
                <ChoiceSelectField
                  name="uniteCorrelation"
                  label="Unité du facteur de corrélation *"
                  isMulti={false}
                  options={
                    values !== null
                      ? getUnitCorrelationOptions(values, initialProcedes)
                      : []
                  }
                />
                <DummyNumberField
                  name="masseVolumiqueProcede"
                  label="Masse volumique de la quantité de matière première ou production"
                  disabled
                  unit="kg/l = t/m³"
                  value={
                    values.procede !== null
                      ? getMasseVolumiqueValue(values.procede, initialProcedes)
                      : null
                  }
                />
                <TextField
                  name="provenanceFacteurCorrelation"
                  label="Provenance du facteur de corrélation *"
                />
              </>
            )}
            {matiereSubPartActivated(values.methods) && (
              <>
                <TextField
                  name="descIntrant"
                  label="Description des intrants *"
                  tooltipContent="Si plusieurs intrants existent, il convient de les séparer d'une virgule."
                />
                <NumberField
                  name="quantityIn"
                  label="Quantité entrante *"
                  unit="kg/an"
                />
                <NumberField
                  name="quantityOut"
                  label="Quantité sortante *"
                  unit="kg/an"
                  tooltipContent="Il convient ici de recenser toute quantité exportée ou sortant des limites de l'installation autrement que sous forme atmosphérique (produits, matériaux, mises à l'égout ou en décharge, épuration, pertes) et les variations des stocks dans les limites de l'installation."
                />
                <TextField
                  name="elementIndexe"
                  label="Élement sur lequel est indexé le bilan matière *"
                  tooltipContent={
                    <span>
                      Exemple: Dans le cas de la détermination des émissions de
                      SO<sub>2</sub> issues de la combustion de fuel lourd ou de
                      charbon, l'élément sur lequel est indexé le calcul est la
                      teneur en soufre du combustible.{" "}
                    </span>
                  }
                />
                <NumberField
                  name="teneurMoyenne"
                  label="Teneur moyenne de l'élément dans les intrants *"
                  unit="%"
                  tooltipContent={
                    <span>
                      Exemple: Dans le cas de la détermination des émissions de
                      SO<sub>2</sub> issues de la combustion de fuel lourd ou de
                      charbon, préciser la teneur massique en soufre du
                      combustible (valeur entre 0 et 100).
                    </span>
                  }
                />
                <NumberField
                  name="partElement"
                  label="Part de l'élément dans la substance émise *"
                  unit="%"
                  tooltipContent={
                    <span>
                      <p className={classes.mBottom}>
                        Exemple : Dans le cas de la détermination des émissions
                        de SO<sub>2</sub> issues de la combustion de fuel lourd
                        ou de charbon, préciser la part molaire du soufre sur
                        lequel est indexé le calcul dans la masse molaire totale
                        du SO<sub>2</sub> soit 50,05 % (détail : 100 * Masse
                        molaire de S / Masse molaire de SO<sub>2</sub> = 100 *
                        32,066 / 64,065 = 50,05 %).
                      </p>
                      <p className={classes.mBottom}>
                        Aide : Principales masses molaires des éléments et
                        composés :
                        <ul className={classes.list}>
                          <li>
                            SO<sub>2</sub> dont la part de S est de 50,05 %
                            (32,066 / 64,065)
                          </li>
                          <li>
                            CO<sub>2</sub> dont la part de C est de 27,29 %
                            (12,011 / 44,010)
                          </li>
                          <li>
                            HCl dont la part de Cl est de 97,26 % (35,463 /
                            36,461)
                          </li>
                          <li>
                            HF dont la part de F est de 94,96 % (18,998 /
                            20,006)
                          </li>
                          <li>
                            voire les métaux lourds pour lesquels le ratio est
                            de 1.
                          </li>
                        </ul>
                      </p>
                      <p>
                        Remarque : il est essentiel pour les gros tonnages,
                        particulièrement ceux de CO<sub>2</sub>, que les ratios
                        utilisés soient le plus précis possibles tant les écarts
                        dûs aux arrondis peuvent rapidement être convertis en
                        des tonnages non négligeables.
                      </p>
                    </span>
                  }
                />
              </>
            )}
            {anySubPartActivated(values.methods) && (
              <>
                {isCo2(values.substance, referentialCO2) && (
                  <NumberField
                    name="biomasse"
                    label="Fraction de la biomasse (%) *"
                    unit="%"
                  />
                )}
                <BooleanField
                  name="epuration"
                  label="Les émissions font-elles l'objet d'épuration ?"
                />
                {values.epuration === true && (
                  <div className={classes.withdrawLeft}>
                    <TextField
                      name="natureEquipement"
                      label="Nature des équipements *"
                      tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                    />
                    <NumberField
                      name="rendementEpuration"
                      label="Rendement de l'épuration *"
                      unit="%"
                      tooltipContent="Le rendement global d'épuration pour tous les équipements est attendu."
                    />
                  </div>
                )}
              </>
            )}
            {(matiereSubPartActivated(values.methods) ||
              mesureSubPartActivated(values.methods) ||
              correlationSubPartActivated(values.methods)) && (
              <DummyNumberField
                name="emissionAnnuelle"
                label="Émissions annuelles"
                unit="kg/an"
                disabled
                additionalClassName={classes.calculatedField}
                value={computeEmissionAnnuelle(values, initialProcedes)}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default FormEmission;
