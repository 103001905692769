import {
  ActiviteDto23Now,
  ActiviteDto23NowTrancheEmployeEnum,
  AdministratifDto20Now,
  Adresse23NowDto,
  ExternalActiviteInseeDto23NowEtatAdministratifEnum,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { IdentifiedData } from "common/form/utils";
import {
  arrayExternalAdministratifStateLabel,
  arrayTrancheEmployeLabel,
} from "./converter";

export type AdministratifType = Omit<
  AdministratifDto20Now,
  "commune" | "pays"
> & {
  commune: string | ReferenceItemGeoCodeInseeDto | null;
  pays: ReferenceItemPaysDto | null;
};

export const arrayExternalAdministratifState: ExternalActiviteInseeDto23NowEtatAdministratifEnum[] = Object.keys(
  arrayExternalAdministratifStateLabel
) as ExternalActiviteInseeDto23NowEtatAdministratifEnum[];

export const arrayTrancheEmployeState: ActiviteDto23NowTrancheEmployeEnum[] = Object.keys(
  arrayTrancheEmployeLabel
) as ActiviteDto23NowTrancheEmployeEnum[];

export const administratifFieldMatcher = fieldMatcherBuilder<
  AdministratifType
>().build({
  socialReason: "raisonSociale",
  parentCompany: "societeMere",
  legalStatus: "formeJuridique",
  adress: "adresse",
  postalCode: "codePostal",
  country: "pays",
  city: "commune",
  SIRENNumber: "siren",
});

type AdresseType = Omit<Adresse23NowDto, "uuidCommune" | "libelleCommune"> & {
  informationCommune: ReferenceItemGeoCodeInseeDto | null;
};

export type ActivityType = Omit<
  ActiviteDto23Now,
  "codeNafID" | "systemeCoordonnees" | "adresse"
> & {
  codeNaf: ReferenceItemCodeApeDto | null;
  systemeCoordonnees: ReferenceItemCoordonneesDepartementDto | null;
} & AdresseType;

const adresseFieldMatcher = fieldMatcherBuilder<AdresseType>().build({
  numeroVoie: "numeroVoie",
  typeVoie: "typeVoie",
  libelleVoie: "libelleVoie",
  adresseComplement: "adresseComplement",
  codePostal: "codePostal",
  informationCommune: "informationCommune",
});
export const activiteFieldMatcher = fieldMatcherBuilder<ActivityType>().build({
  inspectionCode: "codeInspection",
  inspectionService: "identifiantService",
  establishmentName: "nomEtablissement",
  SIRETNumber: "siret",
  NAFCode: "codeNaf",
  administratifState: "etatAdministratif",
  closingDate: "dateFermeture",
  systemCoordinates: "systemeCoordonnees",
  longitude: "abscisse",
  latitude: "ordonnee",
  productionVolume: "volumeProduction",
  workDuration: "nombreHeureDeFonctionnement",
  unit: "unite",
  producedMatter: "matiereProduite",
  otherProducedMatter: "autreMatiereProduitePrecision",
  nombreInstallations: "nombreInstallations",
  website: "website",
  trancheEmploye: "trancheEmploye",
  additionalInfo: "informationsComplementaires",
  ...adresseFieldMatcher,
});

export type CoordonneesDepartementMap = Map<
  string,
  Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  >
>;

export const franceOption: ReferenceItemPaysDto = {
  restorationCode: -1,
  codeInsee: -1,
  ue: true,
  designation: "FRANCE",
  uuid: "",
};

export interface InspectionServiceDto extends IdentifiedData {
  libelle: string;
}

export const AUTRE_MATIERE_PRODUITE = "Autre";
