import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  backMessageAddComment,
  backMessageFetchDeclaration,
  backMessageOnEdit,
  backMessageOnSave,
  backMessageOnUpdateTypeActivite,
  backMessageOnValidate,
  backMessageRecallDeclaration,
  backMessageRelieveDeclaration,
  backMessageReviewDeclaration,
  backMessageSubmitDeclaration,
  backMessageSubmitVerifDeclaration,
  backMessageTakeOverDeclaraion,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { StateMessage, stateMessages } from "common/messages/dashboardMessage";
import { useAlertModale } from "common/modale/hooks";
import { cloneDeep } from "lodash";
import { useDeclarationPageHeaderInfo } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { createPatch } from "rfc6902";
import { DeclarationApiContext } from "../DeclarationApiContext";
import {
  AnyDeclarationDto,
  AnyDeclarationSectionDto,
  BasicDeclarationHandlers,
  DeclarationCommentsHandlers,
  DeclarationHelpers,
  DeclarationState,
  DeclarationStateHandlers18Now,
  Progression,
} from "./types";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";
import { throwIfWrongYear } from "./utils";
import { useAuthenticatedUser } from "../../../../Authenticator/AuthenticatedUser";

// DO NOT use the hooks in this file directly! They're supposed to be exposed through year / year range more specific hooks

export const useDeclaration = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  minYear?: number,
  maxYear?: number,
  shouldThrowIfWrongYear = true
): VersionedDeclarationDto => {
  const declarationApi = useContext(DeclarationApiContext);
  if (declarationApi !== null) {
    if (shouldThrowIfWrongYear) {
      throwIfWrongYear(declarationApi.declaration, minYear, maxYear);
    }
    // when calling, we should put the declarationDto and year appropriately.
    return declarationApi.declaration as VersionedDeclarationDto;
  } else {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }
};

export const useDeclaredYears = (): number[] => {
  const { etablissementController } = useAuthenticatedApi();
  const headerInfo = useDeclarationPageHeaderInfo();
  const openAlertModale = useAlertModale();
  const [declaredYears, setDeclaredYears] = useState<number[]>([]);
  useEffect(() => {
    if (headerInfo === null || headerInfo.declarationEtablissement === null) {
      return;
    }
    let outdated = false; // To prevent race condition
    const fetchEstablishementDeclaredYear = async () => {
      try {
        const result = await etablissementController.getAllYearsDeclaredForEtablissementUsingGET(
          headerInfo.declarationEtablissement
        );
        if (!outdated) {
          setDeclaredYears(result);
        }
      } catch (excp) {
        await openAlertModale(
          backAlertMessage(excp, backMessageFetchDeclaration)
        );
        return;
      }
    };
    fetchEstablishementDeclaredYear();
    return () => {
      outdated = true;
    };
  }, [etablissementController, headerInfo, openAlertModale]);
  return useMemo(() => {
    return declaredYears;
  }, [declaredYears]);
};

export const useDeclarationState = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  getDeclarationState: (
    declarationDto: VersionedDeclarationDto
  ) => keyof StateMessage,
  minYear?: number,
  maxYear?: number
): DeclarationState => {
  const declaration = useDeclaration<VersionedDeclarationDto>(minYear, maxYear);

  const currentState = getDeclarationState(declaration);

  const stateMessage: string = useMemo(() => stateMessages[currentState], [
    currentState,
  ]);

  const isDeclarationStateContaining: (
    state: (keyof StateMessage)[]
  ) => boolean = useCallback(
    (state: (keyof StateMessage)[]) => {
      return state.some(singleState => {
        return singleState === currentState;
      });
    },
    [currentState]
  );

  return useMemo(() => {
    return { isDeclarationStateContaining, stateMessage };
  }, [isDeclarationStateContaining, stateMessage]);
};

export const useQuotasState = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  getQuotaState: (
    declarationDto: VersionedDeclarationDto
  ) => keyof StateMessage,
  minYear?: number,
  maxYear?: number
): {
  isDeclarationStateContaining: (state: (keyof StateMessage)[]) => boolean;
  stateMessage: string;
} => {
  const declaration = useDeclaration<VersionedDeclarationDto>(minYear, maxYear);

  const currentState = getQuotaState(declaration);

  const stateMessage: string = useMemo(() => stateMessages[currentState], [
    currentState,
  ]);

  const isDeclarationStateContaining: (
    state: (keyof StateMessage)[]
  ) => boolean = useCallback(
    (state: (keyof StateMessage)[]) => {
      return state.some(singleState => {
        return singleState === currentState;
      });
    },
    [currentState]
  );

  return useMemo(() => {
    return { isDeclarationStateContaining, stateMessage };
  }, [isDeclarationStateContaining, stateMessage]);
};

type DeclarationUpdate<VersionedDeclarationDto extends AnyDeclarationDto> = (
  declaration: VersionedDeclarationDto
) => VersionedDeclarationDto;
const usePatch = <VersionedDeclarationDto extends AnyDeclarationDto>(
  getPatchParams: (
    declarationDto: VersionedDeclarationDto
  ) => AnyDeclarationSectionDto,
  minYear?: number,
  maxYear?: number,
  shouldThrowIfWrongYear?: boolean
) => {
  const { declarationController } = useAuthenticatedApi();
  const declaration = useDeclaration<VersionedDeclarationDto>(
    minYear,
    maxYear,
    shouldThrowIfWrongYear
  );
  const openModale = useAlertModale();

  return useCallback(
    async (
      withUpdates: DeclarationUpdate<VersionedDeclarationDto>
    ): Promise<VersionedDeclarationDto | null> => {
      const declarationCopy = cloneDeep(declaration);
      const patchedDeclaration = withUpdates(declarationCopy);
      const arrayPatches = createPatch(
        getPatchParams(declaration),
        getPatchParams(patchedDeclaration)
      ).map(operation => ({ value: undefined, ...operation }));

      if (arrayPatches.length > 0) {
        try {
          const newDeclaration = await declarationController.patchDeclarationUsingPOST(
            declarationCopy.annee,
            declarationCopy.etablissementCode,
            {
              patch: arrayPatches,
            }
          );
          // when calling, we should put the declarationDto and year appropriately
          // and the type shouldn't change between what we took and what we save
          return newDeclaration as VersionedDeclarationDto;
        } catch (excp) {
          await openModale(backAlertMessage(excp, backMessageOnSave));
          return null;
        }
      }

      return declaration;
    },
    [declaration, declarationController, openModale, getPatchParams]
  );
};

export const useBasicDeclarationHandlers = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  getPatchParams: (
    declarationDto: VersionedDeclarationDto
  ) => AnyDeclarationSectionDto,
  minYear?: number,
  maxYear?: number,
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<VersionedDeclarationDto> => {
  const { declarationController } = useAuthenticatedApi();
  const authenticatedUser = useAuthenticatedUser();
  const declarationApi = useContext(DeclarationApiContext);
  const patchAction = usePatch<VersionedDeclarationDto>(
    getPatchParams,
    minYear,
    maxYear,
    shouldThrowIfWrongYear
  );
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const updateHandler = useCallback(
    async (
      withUpdates: (
        declaration: VersionedDeclarationDto
      ) => VersionedDeclarationDto
    ) => {
      const result = await patchAction(withUpdates);
      if (result !== null) {
        declarationApi.setDeclaration(result);
        return true;
      } else {
        return false;
      }
    },
    [declarationApi, patchAction]
  );

  const validateHandler = useCallback(
    async (
      withUpdates: (dto: VersionedDeclarationDto) => VersionedDeclarationDto,
      pathToValidate: string,
      shouldFetchInCaseOfError: boolean = false
    ) => {
      const result = await patchAction(withUpdates);
      if (result === null) {
        return false;
      }

      try {
        const returnedResult = await declarationController.userValidateDeclarationUsingPOST(
          declarationApi.declaration.annee,
          { path: pathToValidate },
          declarationApi.declaration.etablissementCode
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        if (shouldFetchInCaseOfError) {
          const failedDeclaration = await declarationController.findDeclarationByIdUsingPOST(
            declarationApi.declaration.annee,
            { monAiotToken: authenticatedUser.jwt.monAIOTJWT },
            declarationApi.declaration.etablissementCode
          );
          declarationApi.setDeclaration(failedDeclaration);
          return false;
        } else {
          await openModale(backAlertMessage(excp, backMessageOnValidate));
          declarationApi.setDeclaration(result);
          return false;
        }
      }
    },
    [
      declarationApi,
      declarationController,
      openModale,
      patchAction,
      authenticatedUser.jwt.monAIOTJWT,
    ]
  );

  const cancelValidateHandler = useCallback(
    async (pathToInvalidate: string) => {
      try {
        const returnedResult = await declarationController.userInvalidateDeclarationUsingDELETE(
          declarationApi.declaration.annee,
          { path: pathToInvalidate },
          declarationApi.declaration.etablissementCode
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageOnEdit));
        return false;
      }
    },
    [declarationApi, declarationController, openModale]
  );

  return useMemo(() => {
    return {
      update: updateHandler,
      validate: validateHandler,
      cancelValidate: cancelValidateHandler,
    };
  }, [updateHandler, validateHandler, cancelValidateHandler]);
};

export const useDeclarationStateHandlers = (
  minYear?: number,
  maxYear?: number,
  shouldThrowIfWrongYear?: boolean
): DeclarationStateHandlers18Now => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  if (shouldThrowIfWrongYear) {
    throwIfWrongYear(declarationApi.declaration, minYear, maxYear);
  }

  const submitHandler = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.submitDeclarationUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSubmitDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const submitVerifHandler = useCallback(
    async (targets: QuotaWorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.submitVerifUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageSubmitVerifDeclaration)
        );
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const cancelSubmitHandler = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.recallDeclarationUsingDELETE(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageRecallDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const reviewHandler = useCallback(
    async (targets: WorkflowTarget[], message: string): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.reviewDeclarationUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          { message: message },
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageReviewDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const takeoverHandler = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.takeoverDeclarationUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageTakeOverDeclaraion));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const cancelTakeoverHandler = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.relieveDeclarationUsingDELETE(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
        declarationApi.setDeclaration(returnedResult as AnyDeclarationDto);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageRelieveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  return useMemo(() => {
    return {
      submit: submitHandler,
      review: reviewHandler,
      takeover: takeoverHandler,
      cancelSubmit: cancelSubmitHandler,
      cancelTakeover: cancelTakeoverHandler,
      submitVerif: submitVerifHandler,
    };
  }, [
    submitHandler,
    reviewHandler,
    takeoverHandler,
    cancelSubmitHandler,
    cancelTakeoverHandler,
    submitVerifHandler,
  ]);
};

export const useUpdateDeclarationTypeActivite = <
  VersionedTypeActiviteDto,
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  updateTypeActivite: (
    annee: number,
    codeEtablissement: string,
    newTypeActivite: VersionedTypeActiviteDto
  ) => Promise<VersionedDeclarationDto>,
  minYear?: number,
  maxYear?: number
): ((newActiviteType: VersionedTypeActiviteDto) => Promise<boolean>) => {
  const openModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  throwIfWrongYear(declarationApi.declaration, minYear, maxYear);

  return useCallback(
    async (newActiviteType: VersionedTypeActiviteDto): Promise<boolean> => {
      try {
        const returnedResult: VersionedDeclarationDto = await updateTypeActivite(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }
    },
    [declarationApi, openModale, updateTypeActivite]
  );
};

export const useDeclarationHelpers = <
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationUserValidationDto
>(
  computeProgress: (declarationDto: VersionedDeclarationDto) => Progression,
  getActiveSections: (declarationDto: VersionedDeclarationDto) => string[],
  getValidation: (
    declarationDto: VersionedDeclarationDto
  ) => VersionedDeclarationUserValidationDto[],
  getValidationPath: (
    validation: VersionedDeclarationUserValidationDto
  ) => string,
  minYear?: number,
  maxYear?: number
): DeclarationHelpers => {
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  throwIfWrongYear(declarationApi.declaration, minYear, maxYear);

  // when calling, we should put the declarationDto and year appropriately
  const progress: Progression = useMemo(
    () =>
      computeProgress(declarationApi.declaration as VersionedDeclarationDto),
    [declarationApi, computeProgress]
  );

  // when calling, we should put the declarationDto and year appropriately
  const activeSections: string[] = useMemo(
    () =>
      getActiveSections(declarationApi.declaration as VersionedDeclarationDto),
    [declarationApi, getActiveSections]
  );

  // when calling, we should put the declarationDto and year appropriately.
  const validation: VersionedDeclarationUserValidationDto[] = useMemo(
    () => getValidation(declarationApi.declaration as VersionedDeclarationDto),
    [declarationApi, getValidation]
  );

  const getPathProgressHandler = useCallback(
    (path: string) => {
      return isNaN(progress[path]) ? undefined : progress[path];
    },
    [progress]
  );

  const getValidationUserHandler = useCallback(
    (path: string): boolean => {
      const checkPathRecursively = (path: string): boolean => {
        const isInValidation = validation.some(validation => {
          return getValidationPath(validation) === path;
        });
        if (isInValidation) {
          return true;
        }
        const pathCompo = path.split("/");
        if (pathCompo.length < 2) {
          return false;
        }
        pathCompo.pop();
        return checkPathRecursively(pathCompo.join("/"));
      };
      return checkPathRecursively(path);
    },
    [validation, getValidationPath]
  );

  const getIsActiveSectionHandler = useCallback(
    (path: string) => {
      return activeSections.includes(path);
    },
    [activeSections]
  );

  const pathToDashboardHandler = useMemo(() => {
    return `/declaration/${declarationApi.declaration.annee}/${declarationApi.declaration.etablissementCode}/dashboard`;
  }, [declarationApi]);

  return useMemo(() => {
    return {
      getPathProgress: getPathProgressHandler,
      getIsActiveSection: getIsActiveSectionHandler,
      pathToDashboard: pathToDashboardHandler,
      isPathValidatedInDeclaration: getValidationUserHandler,
    };
  }, [
    getPathProgressHandler,
    getIsActiveSectionHandler,
    pathToDashboardHandler,
    getValidationUserHandler,
  ]);
};

export const useDeclarationComments = <
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationCommentDto
>(
  commentDeclaration: (
    annee: number,
    codeEtablissement: string,
    path: string,
    comment: string,
    isDynamic: boolean,
    isAlertOrError: boolean
  ) => Promise<VersionedDeclarationDto>,
  deleteCommentDecalaration: (
    annee: number,
    codeEtablissement: string,
    path: string,
    user: string,
    creationDate: string
  ) => Promise<VersionedDeclarationDto>,
  getComments: (
    declarationDto: VersionedDeclarationDto
  ) => VersionedDeclarationCommentDto[],
  minYear?: number,
  maxYear?: number
): DeclarationCommentsHandlers<VersionedDeclarationCommentDto> => {
  const openModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  throwIfWrongYear(declarationApi.declaration, minYear, maxYear);

  const postComment = useCallback(
    async (
      path: string,
      comment: string,
      isDynamic: boolean,
      isAlertOrError: boolean
    ): Promise<boolean> => {
      try {
        const returnedResult: VersionedDeclarationDto = await commentDeclaration(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          path,
          comment,
          isDynamic,
          isAlertOrError
        );
        // the VersionedDeclarationDto type parameter should ALWAYS be subset of AnyDeclarationDto that can be assigned to it, but I didn't find a way to write it in ts
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageAddComment));
        return false;
      }
    },
    [declarationApi, openModale, commentDeclaration]
  );

  const deleteComment = useCallback(
    async (
      path: string,
      user: string,
      creationDate: string
    ): Promise<boolean> => {
      try {
        const returnedResult: VersionedDeclarationDto = await deleteCommentDecalaration(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          path,
          user,
          creationDate
        );
        // the VersionedDeclarationDto type parameter should ALWAYS be subset of AnyDeclarationDto that can be assigned to it, but I didn't find a way to write it in ts
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageAddComment));
        return false;
      }
    },
    [declarationApi, openModale, commentDeclaration]
  );

  return useMemo(() => {
    // when calling, we should put the declarationDto and year appropriately.
    return {
      comments: getComments(
        declarationApi.declaration as VersionedDeclarationDto
      ),
      postComment: postComment,
      deleteComment: deleteComment,
    };
  }, [getComments, postComment, declarationApi]);
};
