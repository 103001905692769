import {
  ReferencePaysDto,
  ReferenceItemPaysDto,
  ReferenceSubstanceCarriereDto,
} from "api/gen";
import {
  ExtractiveActivityForm,
  SubstanceExtractionType,
} from "../BlocActiviteExtractive/utils/types";
import { convertDisplayedToSubtanceExtractionDto } from "../BlocActiviteExtractive/utils/converters";
import {
  RecyclingActivityForm,
  ProduitRecyclingType,
} from "../BlocActiviteRecyclage/utils/types";
import { convertDisplayedToProduitRecyclageDto } from "../BlocActiviteRecyclage/utils/converters";
import {
  ActiviteTransformationType,
  france,
  TransformationInformationsForm,
} from "../BlocInformationsTransformation/utils/types";
import {
  convertDisplayedToActiviteTransformationDto,
  generateFranceReferenceItemPays,
} from "../BlocInformationsTransformation/utils/converters";
import {
  TransportProductsArrayForm,
  ProduitTransportType,
} from "../BlocTransportProduits/utils/types";
import {
  convertDisplayedToProduitTransportDto,
  convertDisplayedToModaliteTransportDto,
} from "../BlocTransportProduits/utils/converters";
import _ from "lodash";
import { Declaration23Now } from "../../../versionedElements/declarationHooks23Now";

export const convertStringCountryToReferenceItem = (
  countryLabel: string,
  referential: ReferencePaysDto
): ReferenceItemPaysDto | null => {
  if (countryLabel === france) {
    return generateFranceReferenceItemPays();
  }
  const matchCountry = referential
    ? referential.referenceItemPaysDtoList.find(
        country => country.designation === countryLabel
      )
    : null;
  return matchCountry ? matchCountry : null;
};

export const convertDeclarationDtoUsingExtractiveActivityForm = (
  declaration: Declaration23Now,
  values: ExtractiveActivityForm,
  referentialSubstances: ReferenceSubstanceCarriereDto,
  substancesExtractionInArray: SubstanceExtractionType[]
): Declaration23Now => {
  declaration.body.sections.carriere.production.extraction.quantiteRestante =
    values.depositQuantity;
  declaration.body.sections.carriere.production.extraction.quantiteSterilesGeneres =
    values.annualGeneratedQuantity;
  declaration.body.sections.carriere.production.extraction.substances = convertDisplayedToSubtanceExtractionDto(
    substancesExtractionInArray,
    referentialSubstances
  );
  return declaration;
};

export const convertDeclarationDtoUsingRecyclingActivityForm = (
  declaration: Declaration23Now,
  values: RecyclingActivityForm,
  recyclingProductsInArray: ProduitRecyclingType[]
): Declaration23Now => {
  declaration.body.sections.carriere.production.recyclage.doitRemplirTableau =
    values.recyclingActivity;
  if (values.recyclingActivity) {
    declaration.body.sections.carriere.production.recyclage.quantiteEntrante =
      values.recyclingMaterialQuantity;
    declaration.body.sections.carriere.production.recyclage.tableauRecyclage = convertDisplayedToProduitRecyclageDto(
      recyclingProductsInArray
    );
  } else {
    declaration.body.sections.carriere.production.recyclage.quantiteEntrante = 0;
    declaration.body.sections.carriere.production.recyclage.tableauRecyclage = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingTransformationInformationsForm = (
  declaration: Declaration23Now,
  values: TransformationInformationsForm,
  factoriesInArray: ActiviteTransformationType[]
): Declaration23Now => {
  declaration.body.sections.carriere.production.transformation.doitRemplirTableau =
    values.transformationActivityDone;
  if (values.transformationActivityDone) {
    declaration.body.sections.carriere.production.transformation.activiteTransformations = convertDisplayedToActiviteTransformationDto(
      factoriesInArray
    );
  } else {
    declaration.body.sections.carriere.production.transformation.activiteTransformations = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingTransportProductsForm = (
  declaration: Declaration23Now,
  values: TransportProductsArrayForm,
  transportProductsInArray: ProduitTransportType[]
): Declaration23Now => {
  declaration.body.sections.carriere.production.transport.modaliteTransports = convertDisplayedToModaliteTransportDto(
    values.productsTransport
  );
  declaration.body.sections.carriere.production.transport.tableauDestinations = convertDisplayedToProduitTransportDto(
    transportProductsInArray
  );
  return declaration;
};
