import * as Yup from "yup";
import {
  commonArrayOfStringFields,
  commonMixedFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { MentionDanger } from "../utils/selectPossibleValues";

//TODO [GEREP-2579] il faudra remettre le shape CovInModale ici.
export const singleCovValidationSchema = Yup.object().shape({
  substance: commonMixedFields<MentionDanger>(),
  procedes: commonArrayOfStringFields,
  flux: commonPositiveNumberFields,
});
