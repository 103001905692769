import React, { useState } from "react";
import * as Yup from "yup";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import {
  commonBooleanFieldsNullable,
  commonObjectFields,
} from "common/declarant/formik/formikHelper";
import FormikBloc from "common/formikBloc/FormikBloc";
import {
  CREATED,
  generateTransitionWarningMessage,
  STARTED,
} from "common/messages/dashboardMessage";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
  ReferenceActiviteQuotaDto,
  ReferenceItemEprtrDto,
} from "api/gen";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useTechniqueEditHandler,
  useTechniqueSaveHandler,
  useTechniqueValidateHandler,
} from "./hooks";
import { computeTechInitialValues } from "./handlers";
import { LEFT_WITHDRAW_STYLE, SECTION_TITLE_GREY } from "theme";
import { PATH_TYPE_ACTIVITE } from "common/path/path1820";
import { useConfirmationModale } from "common/modale/hooks";
import Installations from "./Installations";
import { InstallationInArray } from "./Installations/types";
import { dtoToInstallations } from "./Installations/converter";
import { shouldDisableQuotas, TechniqueValues } from "./utils";
import _ from "lodash";
import MessageInfoField from "common/form/MessageInfoField";
import { COMBUSTION_COMBUSTIBLES_ACTIVITE_RESTORATION_CODE } from "common/installations/activityCode";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { useDeclaration2020 } from "../../../CompanySpace/from20/to20/versionedElements/declarationHooks2020";
import { useDeclarationErrors2020 } from "../../../CompanySpace/from20/to20/versionedElements/errorAndWarningDisplayer2020/utils";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "20px",
    display: "flex",
  },
  separator: {
    height: "20px",
  },
  containerSpace: {
    padding: "10px 10px 25px 10px",
    fontSize: "17px",
  },
  row: {
    display: "flex",
    justifyContent: "spaceBetween",
    marginBottom: "15px",
  },
  subTitle: {
    color: SECTION_TITLE_GREY,
  },
  messageContainer: {
    height: "auto",
    justifyContent: "center",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const validationSchema = Yup.object().shape({
  EPRTR: Yup.boolean().nullable(),
  principalEPRTR: Yup.object().when("EPRTR", {
    is: true,
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),
  elevage: Yup.boolean().nullable(),
  combustion: Yup.boolean().nullable(),
  incineration: Yup.boolean().nullable(),
  solvant: Yup.boolean().nullable(),
  recepDechet: Yup.boolean().nullable(),
  ISDND: Yup.boolean().nullable(),
  ISDD: Yup.boolean().nullable(),
  ISDI: Yup.boolean().nullable(),
  torchere: Yup.boolean().nullable(),
  valorisation: Yup.boolean().nullable(),
  export: Yup.boolean().nullable(),
  carriere: Yup.boolean().nullable(),
  quotas: Yup.boolean().nullable(),
  excluArticle27: commonBooleanFieldsNullable,
});

/**
 * @param referentielEPRTE      list of EPRTR referential
 * @param referentielActivites  list of Activités Quotas referential
 * @param didValidate           if set, alert modal is not triggered and didValidate is called when validation succeed
 *                              if not set, alert modal is triggered and works as default Formikbloc
 */
interface CompanyTechniqueProps extends FormikBlocRefAndState<TechniqueValues> {
  referentielEPRTR: ReferenceItemEprtrDto[];
  referentialActivites: ReferenceActiviteQuotaDto;
  didValidate?: () => void;
}

export const CompanyTechnique = (
  props: CompanyTechniqueProps
): React.ReactElement => {
  const classes = useStyles();
  const declarationDto = useDeclaration2020();
  const openConfirmationModale = useConfirmationModale();
  const saveHandler = useTechniqueSaveHandler();
  const validateHandler = useTechniqueValidateHandler();
  const editHandler = useTechniqueEditHandler();
  const declaration = useDeclaration2020();

  const initialInstallations = dtoToInstallations(
    declaration.body.typeActivite.installations,
    props.referentialActivites
  );

  const initialFormikValues = computeTechInitialValues(
    declarationDto,
    props.referentielEPRTR
  );

  const [installationsInArray, setInstallationsInArray] = useState<
    InstallationInArray[]
  >(initialInstallations);

  const possibleActivitesValues =
    props.referentialActivites.referenceItemActiviteQuotaDtoList;

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "company-technique",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const declarationState = declarationDto.body.workflowStatus.general.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const areActionsAllowed =
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
    userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
    userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN;

  const isTechniqueValidated = declarationState !== CREATED;
  const shouldDisableFields = !areActionsAllowed || isTechniqueValidated;

  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<void>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<void>();

  const isQuotasDisabled =
    shouldDisableFields || shouldDisableQuotas(declaration.body.workflowStatus);

  const {
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrors2020(
    PATH_TYPE_ACTIVITE,
    false,
    declarationDto.body.workflowStatus.general.state !==
      DeclarationStateDtoStateEnum.CREATED
  );

  return (
    <FormikBloc
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      actionsAllowed={areActionsAllowed}
      isBlocValidated={isTechniqueValidated}
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      setHasChanges={props.setHasChanges}
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      hasFormChanges={(values: TechniqueValues) =>
        !_.isEqual(initialFormikValues, values) ||
        !_.isEqual(initialInstallations, installationsInArray)
      }
      saveAction={async (values: TechniqueValues) => {
        await triggerSaveSpinner(saveHandler(values, installationsInArray));
      }}
      editAction={async () => {
        openConfirmationModale(
          "Attention, cette action invalidera toutes les sections de la déclaration, êtes vous sûr de vouloir l'effectuer ?",
          async () => {
            await triggerEditSpinner(editHandler());
          }
        );
      }}
      validateAction={async (values: TechniqueValues) => {
        if (props.didValidate) {
          await triggerErrorAndWarningDisplay(
            triggerValidateSpinner(
              validateHandler(values, installationsInArray)
            )
          );
          props.didValidate();
        } else {
          openConfirmationModale(
            generateTransitionWarningMessage(STARTED),
            async () => {
              await triggerErrorAndWarningDisplay(
                triggerValidateSpinner(
                  validateHandler(values, installationsInArray)
                )
              );
            }
          );
        }
      }}
      cancelAction={() => {
        setInstallationsInArray(initialInstallations);
        return initialFormikValues;
      }}
      initialValues={initialFormikValues}
      validationSchema={validationSchema}
      title={"TYPE D'ACTIVITÉ"}
      additionalFieldValidation={values => {
        const errors: FormikErrors<TechniqueValues> = {};

        if (values.EPRTR && values.principalEPRTR && values.activitesEPRTR) {
          const currentPrincipalEPRTR = values.principalEPRTR;
          if (
            values.activitesEPRTR.find(
              act => act.uuid === currentPrincipalEPRTR.uuid
            )
          ) {
            errors.principalEPRTR = `Vous ne pouvez pas sélectionner ${currentPrincipalEPRTR.nomActivite} comme activité principale et secondaire.`;
          }
        }

        return errors;
      }}
      additionalValidationAllowed={() => !hasError || props.hasChanges}
      renderContent={formikProps => {
        let filteredActivity;
        if (formikProps.values.EPRTR && formikProps.values.principalEPRTR) {
          const currentPrincipalEPRTR = formikProps.values.principalEPRTR;
          filteredActivity = props.referentielEPRTR.filter(
            act => act.uuid !== currentPrincipalEPRTR.uuid
          );
        } else {
          filteredActivity = props.referentielEPRTR;
        }

        return (
          <>
            <div className={classes.containerSpace}>
              Si vous constatez des erreurs manifestes concernant les types
              d'activités de l'établissement, vous pouvez prendre contact avec
              le service d'inspection dont dépend l'établissement afin que les
              corrections nécessaires soient apportées.
            </div>
            <BooleanField
              label="L'établissement est visé par le règlement 166/2006 (règlement E-PRTR)"
              name="EPRTR"
              disabled={shouldDisableFields}
              tooltipContent={
                <>
                  Établissement comportant au moins une activité visée à
                  l'Annexe I du règlement (CE) N°166/2006 sur la création d'un
                  registre européen des rejets et des transferts de polluants
                  (dit règlement E-PRTR). La majorité des établissements visés
                  par la directive IED sont également visés par le règlement
                  E-PRTR.&nbsp;
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ComparatifIEDE-PRTRv2.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Comparatif IED E-PRTR
                  </a>
                </>
              }
            />
            {formikProps.values.EPRTR && (
              <div className={classes.withdrawLeft}>
                <WrappedChoiceSelect
                  label="Activité principale E-PRTR *"
                  name="principalEPRTR"
                  isMulti={false}
                  options={props.referentielEPRTR}
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  commonProps={commonProps}
                  tooltipLabel
                />
                <WrappedChoiceSelect
                  label="Activités secondaires E-PRTR"
                  name="activitesEPRTR"
                  options={filteredActivity}
                  isMulti
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  commonProps={commonProps}
                  tooltipLabel
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est un établissement d'élevage intensif de volailles ou de porcs (rubrique 3660)"
              name="elevage"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement possède une ou plusieurs installations de combustion d'une puissance supérieure à 20 MW"
              name="combustion20MW"
              disabled={shouldDisableFields}
            />
            {!formikProps.values.combustion20MW &&
              formikProps.values.quotas &&
              installationsInArray.some(
                installation =>
                  !!installation.data.codesActivite &&
                  installation.data.codesActivite.some(
                    code =>
                      code.restorationCode ===
                      COMBUSTION_COMBUSTIBLES_ACTIVITE_RESTORATION_CODE
                  )
              ) && (
                <>
                  <Row additionalClassname={classes.separator} />
                  <MessageInfoField
                    message={
                      "La case ci-dessus n'est pas cochée alors que l'activité « Combustion de combustibles dans " +
                      "des installations dont la puissance calorifique totale de combustion est supérieure à 20 MW » " +
                      "a été déclarée pour au moins une installation quotas plus bas dans cette section. Veuillez " +
                      "vérifier la cohérence de la saisie et rectifier la situation si nécessaire. Si cet état de " +
                      "fait ne correspond pas à une erreur, veuillez ne pas tenir compte de ce message."
                    }
                    additionalClassname={classes.messageContainer}
                  />
                </>
              )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement réceptionne / traite / stocke des déchets (y compris tri-transit-regroupement, incinération, compostage et méthanisation)"
              name="recepDechet"
              disabled={shouldDisableFields}
              tooltipContent={
                "Ne concerne pas le stockage temporaire, avant collecte, sur le site de production des déchets"
              }
            />
            {formikProps.values.recepDechet && (
              <div className={classes.withdrawLeft}>
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations d'incinération ou de co-incinération de déchets"
                  name="incinerationDechet"
                  disabled={shouldDisableFields}
                />
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets dangereux (ISDD)"
                  name="ISDD"
                  disabled={shouldDisableFields}
                />
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets non dangereux (ISDND)"
                  name="ISDND"
                  disabled={shouldDisableFields}
                />
                {formikProps.values.ISDND && (
                  <div className={classes.withdrawLeft}>
                    <BooleanField
                      label="L'ISDND possède une ou plusieurs torchères de biogaz"
                      name="torchereBiogaz"
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND dispose d'un système de valorisation du biogaz"
                      name="valorisationBiogaz"
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND exporte du biogaz"
                      name="exportBiogaz"
                      disabled={shouldDisableFields}
                    />
                  </div>
                )}
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets inertes (ISDI)"
                  name="ISDI"
                  disabled={shouldDisableFields}
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement consomme des solvants"
              name="solvants"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est soumis à enquête annuelle carrière (rubrique 2510-1)"
              name="carriere"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est soumis à la directive 2003/87/CE (directive quotas)"
              name="quotas"
              disabled={isQuotasDisabled}
              tooltipContent={
                "Établissement comportant au moins une installation visée par la directive européenne n°2003/87/CE modifiée (quotas d'émission de gaz à effet de serre)"
              }
            />

            {formikProps.values.quotas && (
              <>
                <div className={classes.withdrawLeft}>
                  <BooleanField
                    label={() => (
                      <>
                        L’établissement est exclu au titre de l’article 27
                        (exclusion hôpitaux)
                        <br />
                        de la phase 3
                      </>
                    )}
                    name="excluArticle27"
                    disabled={isQuotasDisabled}
                  />
                </div>

                <Row additionalClassname={classes.separator} />

                <Installations
                  values={installationsInArray}
                  setValues={setInstallationsInArray}
                  possibleActivitesValues={possibleActivitesValues}
                  shouldDisableFields={isQuotasDisabled}
                />
              </>
            )}

            <ViolationProcessor errors={errors} warnings={[]} />
          </>
        );
      }}
    />
  );
};
