import {
  AirCombustionEmissionBilanIntrantsDto20Now,
  AirCombustionEmissionBilanSortantsDto20Now,
  AirFugitivesEmissionBilanIntrantsDto20Now,
  AirFugitivesEmissionBilanSortantsDto20Now,
} from "api/gen";

const MULTIPLE_INTRANTS_LABEL = "Multiples intrants";
const MULTIPLE_SORTANTS_LABEL = "Multiples sortants";

export const getIntrantName = (
  intrants: Array<
    | AirFugitivesEmissionBilanIntrantsDto20Now
    | AirCombustionEmissionBilanIntrantsDto20Now
  > | null
): string | null => {
  if (!intrants || intrants.length === 0) {
    return "";
  }
  return intrants.length > 1 ? MULTIPLE_INTRANTS_LABEL : intrants[0].descriptif;
};

export const getSortantName = (
  sortants: Array<
    | AirFugitivesEmissionBilanSortantsDto20Now
    | AirCombustionEmissionBilanSortantsDto20Now
  > | null
): string | null => {
  if (!sortants || sortants.length === 0) {
    return "";
  }
  return sortants.length > 1 ? MULTIPLE_SORTANTS_LABEL : sortants[0].descriptif;
};
