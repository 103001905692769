import React, { ReactElement } from "react";
import { BlocDonneeSurveillanceDto, MethodeSurveillanceDto } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import MethodeCalculExceptPfcList from "./MethodeCalculExceptPfcList";
import MethodeSourceEmissionList from "./MethodeSourceEmissionList";
import SurveillanceTransfereList from "./SurveillanceTransfereList";
import SurveillanceEmissionPfcList from "./SurveillanceEmissionPfcList";
import SurveillanceMethodeAlternative from "./SurveillanceMethodeAlternative";

interface PdsDonneesSurveillanceProps {
  blocDonneeSurveillance: BlocDonneeSurveillanceDto;
  methodeSurveillance: MethodeSurveillanceDto;
}

const PdsBlocDonneeSurveillance = ({
  blocDonneeSurveillance,
  methodeSurveillance,
}: PdsDonneesSurveillanceProps): ReactElement => {
  const {
    methodeCalculExceptPfcList,
    mesureSourceEmissionList,
    surveillanceTransfereList,
    surveillanceEmissionPfcList,
    surveillanceMethodeAlternativeDto,
  } = blocDonneeSurveillance;
  const {
    methodeCalculCo2,
    methodeMesureCo2,
    surveillanceCo2,
    surveillancePfc,
    surveillanceN2o,
    methodeAlternative,
  } = methodeSurveillance;
  return (
    <>
      <SubSectionTitle>DONNÉES DE SURVEILLANCE</SubSectionTitle>
      {methodeCalculCo2 && (
        <MethodeCalculExceptPfcList
          methodeCalculExceptPfcList={methodeCalculExceptPfcList}
        />
      )}
      {(methodeMesureCo2 || surveillanceCo2 || surveillanceN2o) && (
        <MethodeSourceEmissionList
          mesureSourceEmissionList={mesureSourceEmissionList}
        />
      )}
      {surveillanceCo2 && (
        <SurveillanceTransfereList
          surveillanceTransfereList={surveillanceTransfereList}
        />
      )}
      {surveillancePfc && (
        <SurveillanceEmissionPfcList
          surveillanceEmissionPfcList={surveillanceEmissionPfcList}
        />
      )}
      {methodeAlternative && (
        <SurveillanceMethodeAlternative
          surveillanceMethodeAlternative={surveillanceMethodeAlternativeDto}
        />
      )}
    </>
  );
};

export default PdsBlocDonneeSurveillance;
