import React, { useCallback, useRef } from "react";
import Button from "common/button";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageDownloadFile } from "common/backErrors/errorMessages";
import { useAlertModale } from "common/modale/hooks";

export interface DownloadButtonProps {
  downloadHandler: () => Promise<Response>;
  filename: string;
  additionalClassname?: string;
  disabled?: boolean;
}

export const DownloadButton = ({
  downloadHandler,
  filename,
  additionalClassname,
  disabled,
}: DownloadButtonProps) => {
  const [isDownloadSpinnerVisible, triggerDownloadSpinner] = useSpinnerState<
    void
  >();
  const openAlertModale = useAlertModale();
  const linkRef = useRef<HTMLAnchorElement | null>(null);

  const downloadFile = useCallback(async (): Promise<void> => {
    if (linkRef.current === null || linkRef.current.href === null) {
      return;
    }

    try {
      const response = await downloadHandler();
      const data = await response.blob();

      linkRef.current.href = window.URL.createObjectURL(data);
      linkRef.current.download = filename;
      linkRef.current.click();
    } catch (e) {
      await openAlertModale(backAlertMessage(e, backMessageDownloadFile));
    }
  }, [downloadHandler, filename, openAlertModale]);

  return (
    <>
      <Button
        text={
          <>
            TÉLÉCHARGER <FontAwesomeIcon icon="download" />
          </>
        }
        isSpinnerVisible={isDownloadSpinnerVisible}
        onClick={() => triggerDownloadSpinner(downloadFile())}
        additionalClassname={additionalClassname}
        isDisabled={disabled}
      />
      {/* eslint-disable-next-line */}
      <a ref={linkRef} download />
    </>
  );
};

export default DownloadButton;
