import React from "react";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  button: {
    padding: "5px",
  },
  link: {
    textDecoration: "none",
  },
});

export interface FilesDownloaderProps {
  text: string;
  fileName: string;
  isDisabled?: boolean;

  additionalClassname?: string;
}

const FilesDownloader = ({
  text,
  fileName,
  isDisabled,
  additionalClassname,
}: FilesDownloaderProps): React.ReactElement => {
  if (isDisabled === undefined || isDisabled === null) {
    isDisabled = false;
  }
  const classes = useStyles();

  const button = (
    <Button
      text={
        <>
          {text} <FontAwesomeIcon icon="download" />
        </>
      }
      additionalClassname={classes.button}
      isReversed
      isDisabled={isDisabled}
    />
  );

  return (
    <div className={additionalClassname}>
      {isDisabled ? (
        button
      ) : (
        <a
          href={`${process.env.PUBLIC_URL}/download/${fileName}`}
          className={classes.link}
          download
        >
          {button}
        </a>
      )}
    </div>
  );
};

export default FilesDownloader;
