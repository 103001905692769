import React from "react";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { CompanyManagementController } from "./CompanyManagementController";
import { ReferentialFetcher } from "common/ReferentialFetcher";

export const CompanyManagement = (): React.ReactElement => {
  const pagePath = [
    {
      label: "Accueil",
      to: "/accueil",
    },
    {
      label: "Etablissements",
      to: "/inspecteur/etablissements",
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "gestion des comptes établissement",
    image: null,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_DEPARTEMENTS",
        "REFERENCE_REGIONS",
        "REFERENCE_ORGANISMES_INSPECTION_ALLOWED_AT_DECLARATION_CREATION",
        "REFERENCE_ADMIN_EMAIL",
      ]}
      render={referentials => {
        return (
          <CompanyManagementController
            referentielDepartements={referentials.REFERENCE_DEPARTEMENTS}
            referentielRegions={referentials.REFERENCE_REGIONS}
            referentielOrganismesAtCreation={
              referentials.REFERENCE_ORGANISMES_INSPECTION_ALLOWED_AT_DECLARATION_CREATION
            }
            adminEmailDto={referentials.REFERENCE_ADMIN_EMAIL}
          />
        );
      }}
    />
  );
};
