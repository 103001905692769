import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BLACK_BUTTON_STYLE } from "../../theme";
import FilesDownloader, { FilesDownloaderProps } from "./index";
import classNames from "classnames";

const useStyles = makeStyles({
  ...BLACK_BUTTON_STYLE,
});

const GreyFilesDownloader = (
  props: FilesDownloaderProps
): React.ReactElement => {
  const classes = useStyles();

  return (
    <FilesDownloader
      {...props}
      additionalClassname={classNames(
        classes.blackButton,
        props.additionalClassname
      )}
    />
  );
};

export default FilesDownloader;
