import { DescriptionAccidentDto } from "api/gen";
import {
  AccidentInArray,
  ConsolidatedType,
  deadlyLabel,
  noLabel,
  yesLabel,
} from "../utils";

export const convertAccidentInArrayToDescriptionAccident = ({
  data,
}: AccidentInArray): DescriptionAccidentDto => {
  const {
    accidentDate,
    causePrincipale,
    isConsolidated,
    id,
    nbJoursArretTravail,
    precisionSiAutre,
  } = data;
  return {
    accidentDate: accidentDate?.toISOString().split(".")[0] + "Z",
    causePrincipale,
    estConsolide: isConsolidated === yesLabel,
    estMortel: isConsolidated === deadlyLabel,
    id,
    nbJoursArretTravail,
    precisionSiAutre,
  };
};

export const convertDescriptionAccidentToAccidentInArray = ({
  estConsolide,
  estMortel,
  id,
  accidentDate,
  causePrincipale,
  precisionSiAutre,
  nbJoursArretTravail,
}: DescriptionAccidentDto): AccidentInArray => {
  let isConsolidated: ConsolidatedType = noLabel;
  if (estConsolide) {
    isConsolidated = yesLabel;
  } else if (estMortel) {
    isConsolidated = deadlyLabel;
  }

  return {
    data: {
      id,
      accidentDate: accidentDate !== null ? new Date(accidentDate) : null,
      causePrincipale,
      nbJoursArretTravail,
      precisionSiAutre,
      isConsolidated,
    },
    errors: {},
  };
};
