import React from "react";
import * as Yup from "yup";
import {
  DustFalloutPointInArray,
  DustFalloutPointInModale,
} from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import {
  commonObjectFields,
  commonPositiveNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import _ from "lodash";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceRadioFieldGenerator,
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import uuid from "uuid";
import { OptionProps } from "common/form/fields/types/basicTypes";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";

interface DustFalloutModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  emissionPointsInArray: DustFalloutPointInArray[];
  setEmissionPointsInArray: (points: DustFalloutPointInArray[]) => void;
  emissionPointInModal: DustFalloutPointInArray | null;
  setEmissionPointInModal: (point: DustFalloutPointInArray | null) => void;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  name: commonStringFields,
  locationType: commonObjectFields,
  gaugeOrBooklet: commonStringFields,
  firstTrimester: commonPositiveNumberFieldsNullable,
  secondTrimester: commonPositiveNumberFieldsNullable,
  thirdTrimester: commonPositiveNumberFieldsNullable,
  fourthTrimester: commonPositiveNumberFieldsNullable,
});

const DustFalloutModal = ({
  isOpen,
  setIsModalOpen,
  emissionPointsInArray,
  setEmissionPointsInArray,
  emissionPointInModal,
  setEmissionPointInModal,
}: DustFalloutModalProps) => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-retombees-poussieres",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);
  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);

  // the first table insertion needs to be in "Temoin" location type
  const locationTypeOptions: OptionProps[] = [];
  if (
    emissionPointsInArray.length === 0 ||
    !emissionPointsInArray.some(
      point => point.data.locationType === selectPossibleValues.locationType[0]
    )
  ) {
    locationTypeOptions.push(selectPossibleValues.locationType[0]);
  } else {
    locationTypeOptions.push(
      selectPossibleValues.locationType[1],
      selectPossibleValues.locationType[2],
      selectPossibleValues.locationType[3]
    );
  }

  const onClose = () => {
    setEmissionPointInModal(null);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UN POINT D'ÉMISSION"
        validationSchema={validationSchema}
        isEdit={emissionPointInModal !== null}
        initialEntity={_.get(emissionPointInModal, "data", {
          name: null,
          locationType: null,
          gaugeOrBooklet: null,
          firstTrimester: null,
          secondTrimester: null,
          thirdTrimester: null,
          fourthTrimester: null,
        })}
        closeFunction={onClose}
        onSubmit={(values: DustFalloutPointInModale) => {
          const emissionPoint: DustFalloutPointInArray = {
            data: {
              id: emissionPointInModal ? emissionPointInModal.data.id : uuid(),
              name: values.name,
              locationType: values.locationType,
              gaugeOrBooklet: values.gaugeOrBooklet,
              firstTrimester: values.firstTrimester,
              secondTrimester: values.secondTrimester,
              thirdTrimester: values.thirdTrimester,
              fourthTrimester: values.fourthTrimester,
            },
            errors: {},
          };
          const newEmissionPointsInArray = emissionPointsInArray;
          let indexToReplace = newEmissionPointsInArray.length;
          if (emissionPointInModal) {
            indexToReplace = _.findIndex(
              newEmissionPointsInArray,
              emissionPointInModal
            );
            setEmissionPointInModal(null);
          }
          newEmissionPointsInArray[indexToReplace] = emissionPoint;
          setEmissionPointsInArray(newEmissionPointsInArray);
          setIsModalOpen(false);
        }}
        renderField={() => (
          <>
            <TextField label="Nom *" name="name" />

            <ChoiceSelect
              label="Type d'emplacement *"
              name="locationType"
              isMulti={false}
              options={locationTypeOptions}
            />

            <ChoiceRadioField
              label="Jauge / Plaquette *"
              name="gaugeOrBooklet"
              choices={selectPossibleValues.gaugeOrBooklet}
            />

            <NumberField
              label="Trimestre 1"
              name="firstTrimester"
              unit="mg/m²/jour"
            />

            <NumberField
              label="Trimestre 2"
              name="secondTrimester"
              unit="mg/m²/jour"
            />

            <NumberField
              label="Trimestre 3"
              name="thirdTrimester"
              unit="mg/m²/jour"
            />

            <NumberField
              label="Trimestre 4"
              name="fourthTrimester"
              unit="mg/m²/jour"
            />
          </>
        )}
      />
    </CustomModal>
  );
};

export default DustFalloutModal;
