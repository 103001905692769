import {
  getMasseVolumiqueValue,
  getQuantityValue,
  getUnitProcede,
} from "./gettersFunctions";
import _ from "lodash";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ProcedeInArray } from "../../listprocede/utils/types";
import { MethodProcede, ObjectUnite } from "./selectPossibleValues";
import {
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesProcedesDtoUniteEnum,
} from "api/gen";
import { EmissionsInModale } from "./types";

//TODO GEREP-1397 refacto all those shitty arrays

type AirFugitiveCorrespondance<ArrayElems> = {
  [KeyLevel1 in Exclude<
    AirFugitivesProcedesDtoUniteEnum,
    AirFugitivesProcedesDtoUniteEnum.AUTRE
  >]: {
    [KeyLevel2 in Exclude<
      AirFugitivesEmissionCorrelationDtoUniteEnum,
      AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
    >]: ArrayElems;
  };
};

//Correspond au tableau qui permet de trouver le facteur de conversion multiplicatif. Present dans la page Procede sur confluence.
//EX: facteurMultiplicatifProcedeAndCorrelation[procedeUnite][correlationUnite]
export const facteurMultiplicatifProcedeAndCorrelation: AirFugitiveCorrespondance<number> = {
  KG: { KG: 1, T: 0.001, HL: 0.01, L: 1, KM3: 0.000001, M3: 0.001 },
  T: { KG: 1000, T: 1, HL: 10, L: 1000, KM3: 0.001, M3: 1 },
  HL: { KG: 100, T: 0.1, HL: 1, L: 100, KM3: 0.0001, M3: 0.1 },
  L: { KG: 1, T: 0.001, HL: 0.001, L: 1, KM3: 0.000001, M3: 0.001 },
  KM3: { KG: 1000000, T: 1000, HL: 10000, L: 1000000, KM3: 1, M3: 1000 },
  M3: { KG: 1000, T: 1, HL: 10, L: 1000, KM3: 0.001, M3: 1 },
};

//Correspond au tableau qui permet de trouver l'operation a faire avec la masse volumique. Present dans la page Procede sur confluence.
//EX: operationMasseVolumique[procedeUnite][correlationUnite]
export const operationMasseVolumique: AirFugitiveCorrespondance<
  "divide" | "multiply" | null
> = {
  KG: {
    KG: null,
    T: null,
    HL: "divide",
    L: "divide",
    KM3: "divide",
    M3: "divide",
  },
  T: {
    KG: null,
    T: null,
    HL: "divide",
    L: "divide",
    KM3: "divide",
    M3: "divide",
  },
  HL: { KG: "multiply", T: "multiply", HL: null, L: null, KM3: null, M3: null },
  L: { KG: "multiply", T: "multiply", HL: null, L: null, KM3: null, M3: null },
  KM3: {
    KG: "multiply",
    T: "multiply",
    HL: null,
    L: null,
    KM3: null,
    M3: null,
  },
  M3: { KG: "multiply", T: "multiply", HL: null, L: null, KM3: null, M3: null },
};

export const computeEmissionAnnuelle = (
  values: EmissionsInModale,
  initialProcedes: ProcedeInArray[]
): number => {
  const methodValue = values.methods && values.methods.object.value;

  if (
    methodValue === MethodProcede.MESURE &&
    !_.isNil(values.debitHoraire) &&
    !_.isNil(values.heureFonctionnement) &&
    !_.isNil(values.concentrationMoyenne)
  ) {
    return (
      values.debitHoraire *
      values.heureFonctionnement *
      values.concentrationMoyenne
    );
  }
  if (methodValue === MethodProcede.FACTEUR_CORRELATION) {
    if (values.procede === null) {
      //we still don't have procédé, so we should return 0
      return 0;
    }
    const unitProcede = getUnitProcede(values.procede, initialProcedes);
    const quantityValue = getQuantityValue(values.procede, initialProcedes);
    if (
      unitProcede &&
      !_.isNil(quantityValue) &&
      !_.isNil(values.facteurCorrelation) &&
      !_.isNil(values.uniteCorrelation)
    ) {
      if (unitProcede.object.code !== AirFugitivesProcedesDtoUniteEnum.AUTRE) {
        if (
          values.uniteCorrelation.object.code ===
          AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
        ) {
          //the uniteCorrelation shouldn't be autre when the unitProcede isn't Autre as well, so we return 0
          return 0;
        }
        let emissionsAnuelle =
          quantityValue *
          values.facteurCorrelation *
          facteurMultiplicatifProcedeAndCorrelation[unitProcede.object.code][
            values.uniteCorrelation.object.code
          ];
        const masseVolumique = getMasseVolumiqueValue(
          values.procede,
          initialProcedes
        );
        const operation =
          operationMasseVolumique[unitProcede.object.code][
            values.uniteCorrelation.object.code
          ];
        if (operation === "multiply" && !_.isNil(masseVolumique)) {
          emissionsAnuelle *= masseVolumique;
        } else if (operation === "divide" && masseVolumique) {
          emissionsAnuelle /= masseVolumique;
        }
        return emissionsAnuelle;
      } else {
        return quantityValue * values.facteurCorrelation;
      }
    }
  }
  if (
    methodValue === MethodProcede.BILAN_MATIERE &&
    !_.isNil(values.quantityIn) &&
    !_.isNil(values.quantityOut) &&
    !_.isNil(values.teneurMoyenne) &&
    !_.isNil(values.partElement)
  ) {
    return (
      (values.quantityIn - values.quantityOut) *
      (values.teneurMoyenne / values.partElement)
    );
  }
  return 0; //an error occured, most likely
};

export const computeEmissionAnnuelleCorrelationOnly = (
  procede: OptionPropsWithObject<ProcedeInArray, string>[] | null,
  quantityValue: number | null,
  masseVolumique: number | null,
  unitCorrelation: OptionPropsWithObject<ObjectUnite> | null,
  facteur: number | null
): number => {
  if (procede) {
    const unitProcede = procede[0].object.data.unit;
    if (
      unitProcede &&
      !_.isNil(quantityValue) &&
      unitCorrelation &&
      !_.isNil(facteur)
    ) {
      if (unitProcede.object.code !== AirFugitivesProcedesDtoUniteEnum.AUTRE) {
        if (
          unitCorrelation.object.code ===
          AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
        ) {
          //the uniteCorrelation shouldn't be autre when the unitProcede isn't Autre as well, so we return 0
          return 0;
        }
        let emissionsAnuelle =
          quantityValue *
          facteur *
          facteurMultiplicatifProcedeAndCorrelation[unitProcede.object.code][
            unitCorrelation.object.code
          ];
        const operation =
          operationMasseVolumique[unitProcede.object.code][
            unitCorrelation.object.code
          ];
        if (operation === "multiply" && masseVolumique) {
          emissionsAnuelle *= masseVolumique;
        } else if (operation === "divide" && masseVolumique) {
          emissionsAnuelle /= masseVolumique;
        }
        return emissionsAnuelle;
      } else {
        return quantityValue * facteur;
      }
    }
  }
  return 0;
};
