import {
  AirCombustionCombustibleDto1819SurCendresEnum,
  AirCombustionCombustiblePciDtoBrutEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
} from "api/gen/api";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { AirCombustionCombustibleDto1819UniteEnum } from "api/gen";

export interface IsMesure {
  isMesure: boolean;
}

export const ch4AndBiogazPossibleValues: OptionPropsWithObject<IsMesure>[] = [
  { value: 1, label: "Calcul", object: { isMesure: false } },
  { value: 2, label: "Mesure", object: { isMesure: true } },
];

export interface ObjectSurCendres {
  code: AirCombustionCombustibleDto1819SurCendresEnum;
}

export const arraySurCendres: OptionPropsWithObject<ObjectSurCendres>[] = [
  {
    value: 1,
    label: "Sur cendres",
    object: { code: AirCombustionCombustibleDto1819SurCendresEnum.SUR_CENDRES },
  },
  {
    value: 2,
    label: "Hors cendres",
    object: {
      code: AirCombustionCombustibleDto1819SurCendresEnum.HORS_CENDRES,
    },
  },
];

export interface ObjectBrutOuSec {
  code: AirCombustionCombustiblePciDtoBrutEnum;
}

export const arrayBrutOuSec: OptionPropsWithObject<ObjectBrutOuSec>[] = [
  {
    value: 1,
    label: "Brut",
    object: { code: AirCombustionCombustiblePciDtoBrutEnum.BRUT },
  },
  {
    value: 2,
    label: "Sec",
    object: { code: AirCombustionCombustiblePciDtoBrutEnum.SEC },
  },
];

export interface ObjectUnite {
  code: AirCombustionCombustibleDto1819UniteEnum;
  isUnitMasse: boolean;
  isUnitVolume: boolean;
  isEnergy: boolean;
  isPCS: boolean;
}

export const arrayUnite: OptionPropsWithObject<ObjectUnite>[] = [
  {
    value: 1,
    label: "t (tonne)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.T,
      isUnitMasse: true,
      isUnitVolume: false,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 2,
    label: "kg (kilogramme)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.KG,
      isUnitMasse: true,
      isUnitVolume: false,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 3,
    label: "hl (hectolitre)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.HL,
      isUnitMasse: false,
      isUnitVolume: true,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 4,
    label: "l (litre)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.L,
      isUnitMasse: false,
      isUnitVolume: true,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 5,
    label: "m3 (mètre cube)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.M3,
      isUnitMasse: false,
      isUnitVolume: true,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 6,
    label: "1000 m3 (mille mètres cubes)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.K_M3,
      isUnitMasse: false,
      isUnitVolume: true,
      isEnergy: false,
      isPCS: false,
    },
  },
  {
    value: 7,
    label: "tep (tonne équivalent pétrole)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.TEP,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: false,
    },
  },
  {
    value: 8,
    label: "GJ (gigajoule)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.GJ,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: false,
    },
  },
  {
    value: 9,
    label: "kWh PCI (kilowatt heure pouvoir calorifique inférieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.KWH_PCI,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: false,
    },
  },
  {
    value: 10,
    label: "MWh PCI (mégawatt heure pouvoir calorifique inférieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.MWH_PCI,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: false,
    },
  },
  {
    value: 11,
    label: "GWh PCI (gigawatt heure pouvoir calorifique inférieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.GWH_PCI,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: false,
    },
  },
  {
    value: 12,
    label: "kWh PCS (kilowatt heure pouvoir calorifique supérieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.KWH_PCS,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: true,
    },
  },
  {
    value: 13,
    label: "MWh PCS (mégawatt heure pouvoir calorifique supérieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.MWH_PCS,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: true,
    },
  },
  {
    value: 14,
    label: "GWh PCS (gigawatt heure pouvoir calorifique supérieur)",
    object: {
      code: AirCombustionCombustibleDto1819UniteEnum.GWH_PCS,
      isUnitMasse: false,
      isUnitVolume: false,
      isEnergy: true,
      isPCS: true,
    },
  },
];

export interface ObjectPciUnite {
  code: AirCombustionCombustiblePciDtoUniteEnum;
  calcul: (pciValue: number, masse?: number | null) => number;
  isEnergyByMasse: boolean;
}

export const arrayPciUnite: OptionPropsWithObject<ObjectPciUnite>[] = [
  {
    value: 1,
    label: "GJ/t PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.GJ_T,
      calcul: pciValue => pciValue,
      isEnergyByMasse: true,
    },
  },
  {
    value: 2,
    label: "GJ/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.GJ_M3,
      calcul: (pciValue, masse) => pciValue / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 3,
    label: "GJ/hl PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.GJ_HL,
      calcul: (pciValue, masse) => (10 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 4,
    label: "MJ/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.MJ_M3,
      calcul: (pciValue, masse) => pciValue / (1000 * (masse || 1)),
      isEnergyByMasse: false,
    },
  },
  {
    value: 5,
    label: "kWh/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.KWH_M3,
      calcul: (pciValue, masse) => (0.0036 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 6,
    label: "MWh/t PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.MWH_T,
      calcul: pciValue => 3.6 * pciValue,
      isEnergyByMasse: true,
    },
  },
  {
    value: 7,
    label: "MWh/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.MWH_M3,
      calcul: (pciValue, masse) => (3.6 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 8,
    label: "MWh/hl PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.MWH_HL,
      calcul: (pciValue, masse) => (36 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 9,
    label: "th/kg PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TH_KG,
      calcul: pciValue => 4.2 * pciValue,
      isEnergyByMasse: true,
    },
  },
  {
    value: 10,
    label: "th/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TH_M3,
      calcul: (pciValue, masse) => (0.0042 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 11,
    label: "tep/t PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TEP_T,
      calcul: pciValue => 42 * pciValue,
      isEnergyByMasse: true,
    },
  },
  {
    value: 12,
    label: "tep/1000m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TEP_1000M3,
      calcul: (pciValue, masse) => (42 * pciValue) / (1000 * (masse || 1)),
      isEnergyByMasse: false,
    },
  },
  {
    value: 13,
    label: "tep/m3 PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TEP_M3,
      calcul: (pciValue, masse) => (42 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
  {
    value: 14,
    label: "tep/hl PCI",
    object: {
      code: AirCombustionCombustiblePciDtoUniteEnum.TEP_HL,
      calcul: (pciValue, masse) => (420 * pciValue) / (masse || 1),
      isEnergyByMasse: false,
    },
  },
];
