import React, { ReactElement } from "react";
import { SurveillanceMethodeAlternativeDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface SurveillanceMethodeAlternativeProps {
  surveillanceMethodeAlternative: SurveillanceMethodeAlternativeDto;
}

const SurveillanceMethodeAlternative = ({
  surveillanceMethodeAlternative,
}: SurveillanceMethodeAlternativeProps): ReactElement => {
  const classes = useStyles();

  const { descriptionList, justificationList } = surveillanceMethodeAlternative;
  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Données issues de la surveillance issues de la méthode alternative
      </SubSectionTitle>

      <div className={classes.arrayContainer}>
        <CommonEntityTable
          header={[
            <p>Description détaillée de la méthode alternative utilisée</p>,
          ]}
          lines={descriptionList.map(description => [
            computeWrappedCell(description, classes),
          ])}
          isFirstColSticky={false}
          isLastColSticky={false}
        />
      </div>

      <div className={classes.arrayContainer}>
        <CommonEntityTable
          header={[
            <p>Justification de l’application de la méthode alternative</p>,
          ]}
          lines={justificationList.map(justification => [
            computeWrappedCell(justification, classes),
          ])}
          isFirstColSticky={false}
          isLastColSticky={false}
        />
      </div>
    </div>
  );
};

export default SurveillanceMethodeAlternative;
