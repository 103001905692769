import { BlocPrelevementEauFormValues24Now } from "./types";

export const computeTotalWaterTaking = (
  values: BlocPrelevementEauFormValues24Now
): number => {
  return (
    (values.volumeEauSuperficielle !== null
      ? values.volumeEauSuperficielle
      : 0) +
    (values.volumeEauSouterraine !== null ? values.volumeEauSouterraine : 0) +
    (values.volumeEauPotable !== null ? values.volumeEauPotable : 0) +
    (values.volumeEauMer !== null ? values.volumeEauMer : 0) +
    (values.volumeEauAdduction !== null ? values.volumeEauAdduction : 0)
  );
};

export const isDepassementDeSeuilEauPotableAndAdduction = (
  volumeEauPotable: number | null,
  volumeEauAdduction: number | null,
  seuilEnKgAnAdduction: number | null
): boolean => {
  const volumeEauPotableNotNull =
    volumeEauPotable !== null ? volumeEauPotable : 0;
  const volumeEauAdductionNotNull =
    volumeEauAdduction !== null ? volumeEauAdduction : 0;
  return seuilEnKgAnAdduction != null &&
    volumeEauPotableNotNull + volumeEauAdductionNotNull > seuilEnKgAnAdduction
    ? true
    : false;
};
