import { SubstanceExtractionType } from "./types";
import {
  ReferenceSubstanceCarriereDto,
  ReferenceItemSubstanceCarriereDto,
  ReferenceFamilleUsageProductionDto,
  SubstanceExtractionDto23Now,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  convertStringFamilyProductionToReferenceItem,
  convertStringSubFamilyProductionToReferenceItem,
  convertStringSubFamilyLvl2ProductionToReferenceItem,
} from "../../Families/converters";

const getSubstanceById = (
  substances: ReferenceItemSubstanceCarriereDto[],
  substanceId: string
) => {
  const substance = findElementMatchingTemplate(
    { uuid: substanceId },
    substances
  );
  return substance;
};

const getSubstanceIdByName = (
  substances: ReferenceItemSubstanceCarriereDto[],
  substanceName: string
) => {
  const substance = findElementMatchingTemplate(
    { libelle: substanceName },
    substances
  );
  return substance ? substance.uuid : null;
};

export const convertSubstanceExtractionDtoToDisplayed = (
  substancesDto: SubstanceExtractionDto23Now[],
  referentialSubstances: ReferenceSubstanceCarriereDto,
  referentialFamilies: ReferenceFamilleUsageProductionDto
): SubstanceExtractionType[] => {
  return substancesDto.map(singleSubstanceDto => {
    const substance = getSubstanceById(
      referentialSubstances.referenceItemSubstanceCarriereDtoList,
      singleSubstanceDto.substanceId
    );

    const family = convertStringFamilyProductionToReferenceItem(
      singleSubstanceDto.famille?.identifier ?? null,
      referentialFamilies
    );
    const subFamily = convertStringSubFamilyProductionToReferenceItem(
      singleSubstanceDto.sousFamille?.identifier ?? null,
      family
    );

    const subFamilyLvl2 = convertStringSubFamilyLvl2ProductionToReferenceItem(
      singleSubstanceDto.sousFamilleNiveau2?.identifier ?? null,
      subFamily
    );

    return {
      id: singleSubstanceDto.id,
      substance: substance,
      famille: family,
      precisionFamille: singleSubstanceDto.famille?.precisionSiAutre ?? "",
      sousFamille: subFamily,
      precisionSousFamille:
        singleSubstanceDto.sousFamille?.precisionSiAutre ?? "",
      sousFamilleNiveau2: subFamilyLvl2,
      precisionSousFamilleNiveau2:
        singleSubstanceDto.sousFamilleNiveau2?.precisionSiAutre ?? "",
      quantite: singleSubstanceDto.quantite,
    };
  });
};

export const convertDisplayedToSubtanceExtractionDto = (
  displayedSubstances: SubstanceExtractionType[],
  referentialSubstances: ReferenceSubstanceCarriereDto
): SubstanceExtractionDto23Now[] => {
  return displayedSubstances.map(singleDisplayedSubstance => {
    return {
      id: singleDisplayedSubstance.id,
      substanceId:
        getSubstanceIdByName(
          referentialSubstances.referenceItemSubstanceCarriereDtoList,
          singleDisplayedSubstance.substance?.libelle ?? ""
        ) || "",
      quantite: singleDisplayedSubstance.quantite,
      famille: {
        identifier: singleDisplayedSubstance.famille?.uuid ?? "",
        precisionSiAutre: singleDisplayedSubstance.precisionFamille ?? "",
      },
      sousFamille: {
        identifier: singleDisplayedSubstance.sousFamille?.uuid ?? "",
        precisionSiAutre: singleDisplayedSubstance.precisionSousFamille ?? "",
      },
      sousFamilleNiveau2: {
        identifier: singleDisplayedSubstance.sousFamilleNiveau2?.uuid ?? "",
        precisionSiAutre:
          singleDisplayedSubstance.precisionSousFamilleNiveau2 ?? "",
      },
    };
  });
};
