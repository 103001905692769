export const VERIFIED_ALERT_MESSAGE =
  "Confirmez que vous voulez valider ce bloc, la validation est irréversible sauf changements des données par l'exploitant.";
export const PLAN_DE_SURVEILLANCE_EDIT_MESSAGE =
  "Attention, modifier ce bloc supprimera les émissions liées à des méthodes de surveillance non sélectionnées";

export const VERIFICATION_BLOC_NOT_VERIFIED_YET =
  "Ce bloc est réservé à la vérification, transmettez à la vérification.";
export const VERIFICATION_BLOC_PENDING_VERIFICATION =
  "Ce bloc est en attente de vérification.";
export const VERIFICATION_BLOC_VERIFIED =
  "Ce bloc est réservé à la vérification et a déjà été validé.";
export const VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION =
  "Ce bloc est réservé à la vérification";
