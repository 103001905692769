import React, { createContext, PropsWithChildren, useContext } from "react";
import { WorkflowTarget } from "../../../common/utils/types";
import { useCurrentDeclarationCampaign } from "../../CommonSpace/CampaignContext";
import { CampaignDto } from "api/gen";
import { CampaignState, WorkflowCampaign } from "./types";

type WorkflowTargetProps = PropsWithChildren<{
  workflowTarget: WorkflowTarget;
}>;

const WorkflowTargetContext = createContext<WorkflowCampaign | null>(null);

export const wrapWithWorkflowTargetProvider = <PropsType extends {}>(
  Component: React.FunctionComponent<PropsType>,
  workflowTarget: WorkflowTarget
): ((props: PropsType) => React.ReactElement) => {
  return (props: PropsType): React.ReactElement => (
    <WorkflowTargetProvider workflowTarget={workflowTarget}>
      <Component {...props} />
    </WorkflowTargetProvider>
  );
};

export const WorkflowTargetProvider = ({
  children,
  workflowTarget,
}: WorkflowTargetProps): React.ReactElement => {
  const declarationCampaign = useCurrentDeclarationCampaign();
  return (
    <WorkflowTargetContext.Provider
      value={getCampaignWorkflow(workflowTarget, declarationCampaign)}
    >
      {children}
    </WorkflowTargetContext.Provider>
  );
};

export const useStatusCampaignCurrentWorkflow = (): CampaignState => {
  const workflowTarget = useContext(WorkflowTargetContext);
  if (workflowTarget !== null) {
    return workflowTarget.status;
  } else {
    throw new Error(
      "WorkflowTarget Error. You probably forgot to put a <WorkflowTarget.Provider>"
    );
  }
};

const getCampaignWorkflow = (
  workflowTarget: WorkflowTarget,
  declarationCampaign: CampaignDto
): WorkflowCampaign => {
  switch (workflowTarget) {
    case "GLOBAL": {
      return {
        workflowTarget,
        status: declarationCampaign.stateGlobal,
      };
    }
    case "QUOTA_ALLOCATIONS": {
      return {
        workflowTarget,
        status: declarationCampaign.stateAllocations,
      };
    }
    case "QUOTA_EMISSIONS": {
      return {
        workflowTarget,
        status: declarationCampaign.stateEmissions,
      };
    }
  }
};
