import * as Yup from "yup";
import {
  codePostalMessage,
  hoursLengthMessage,
  siretLengthMessage,
  siretLuhnMessage,
  siretNumberMessage,
} from "common/declarant/formik/formikMessages";
import { UnitsEnum } from "../utils/types";
import {
  commonNullableStringFields,
  commonNumberFieldsNullable,
  commonObjectFields,
  commonPositiveIntegerNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { checkLuhnAlgorithm } from "pages/CompanySpace/from20/to20/GeneralInformations/utils/luhnUtils";

const checkSIRETNumber = commonStringFields
  .length(14, siretLengthMessage)
  .matches(/^[0-9]*$/, siretNumberMessage)
  .test("SIRET must pass the Luhn check", siretLuhnMessage, value => {
    if (value !== undefined && value !== null) {
      return checkLuhnAlgorithm(value);
    } else {
      return false;
    }
  });

export const companyActivityValidationSchema = Yup.object().shape({
  inspectionCode: commonStringFields,
  establishmentName: commonStringFields,
  installationAdress: commonStringFields,
  installationPostalCode: commonStringFields.length(5, codePostalMessage),
  installationCity: commonObjectFields,
  SIRETNumber: checkSIRETNumber,
  NAFCode: commonObjectFields,
  systemCoordinates: commonObjectFields,
  longitude: commonStringFields,
  latitude: commonStringFields,
  productionVolume: commonNumberFieldsNullable,
  unit: Yup.mixed<UnitsEnum>().nullable(),
  producedMatter: commonNullableStringFields,
  website: commonNullableStringFields,
  workDuration: commonPositiveIntegerNumberFieldsNullable.max(
    8784,
    hoursLengthMessage
  ),
  employee: commonPositiveIntegerNumberFieldsNullable,
  additionalInfo: commonNullableStringFields,
});
