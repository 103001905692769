import { AuthControllerApi, AuthDroitDto, AuthJwtDto } from "api/gen";
import { AUTHENTICATION_STATUS } from "Authenticator";
import * as HttpStatus from "http-status-codes";
import { generateAuthenticatedHeader } from "./AuthenticatedApi";
import { buildUserData, UserData } from "./UserData";

export const ACTIVE_RIGHT = "activeRight";

const authController = (): AuthControllerApi => {
  return new AuthControllerApi(undefined, "", fetch);
};

const authenticatedAuthController = (token: string): AuthControllerApi => {
  return new AuthControllerApi(
    undefined,
    "",
    generateAuthenticatedHeader(token)
  );
};

// Get new GEREP token and MonAiot token from valid GEREP token
export const refreshMonAIOTToken = async (
  authDto: AuthJwtDto
): Promise<AuthJwtDto | null> => {
  const controller = authenticatedAuthController(authDto.jwt);

  try {
    const authJWT = await controller.refreshMonAIOTTokenUsingPOST();
    return authJWT;
  } catch (excp) {
    return null;
  }
};

interface FetchRightsResponse {
  jwt: AuthJwtDto;
  rights: AuthDroitDto[];
}

interface FetchUserDataResponse {
  authJwt: AuthJwtDto;
  userData: UserData;
}

// Get new tokens for wanted rights
const changeRight = async (
  authDto: AuthJwtDto,
  requestedRight: AuthDroitDto
) => {
  const controller = authenticatedAuthController(authDto.jwt);
  const requestParams = {
    targetRight: requestedRight,
    monAIOTAccessToken: authDto.monAIOTJWT,
  };

  try {
    const newJwtDto = await controller.changeUserActiveRightUsingPOST(
      requestParams
    );
    return newJwtDto;
  } catch (excp) {
    return null;
  }
};

const _authenticateWithoutActiveRightInStorage = async (
  code: string,
  authApi: AuthControllerApi
): Promise<AuthJwtDto | Response | null> => {
  try {
    return await authApi.authenticateUserUsingPOST(code);
  } catch (err) {
    if (err instanceof Response) {
      return err;
    }

    return null;
  }
};

// Authenticate by getting jwt, either with rights stored in localstorage
// If it fails or no right in localstorage, we authenticate without it
// in which case the backend will give us our first found right
const _fetchJwt = async (
  code: string
): Promise<AuthJwtDto | Response | null> => {
  const controller = authController();

  const activeRightString = window.localStorage.getItem(ACTIVE_RIGHT);
  if (activeRightString !== null) {
    try {
      const activeRight = JSON.parse(activeRightString) as AuthDroitDto;
      return await controller.authenticateUserWithSpecificRightUsingPOST(code, {
        requireDroitDto: activeRight,
      });
    } catch (err) {
      // We remove active rights from local storage and try to authenticate without it
      window.localStorage.removeItem(ACTIVE_RIGHT);
      return _authenticateWithoutActiveRightInStorage(code, controller);
    }
  } else {
    return _authenticateWithoutActiveRightInStorage(code, controller);
  }
};

const _fetchUserInfo = async (authDto: AuthJwtDto) => {
  const controller = authenticatedAuthController(authDto.jwt);

  try {
    const userInfo = await controller.authUserInfoDtoUsingGET();
    return userInfo;
  } catch (err) {
    return null;
  }
};

const _fetchUserRights = async (
  authDto: AuthJwtDto,
  isRecoveryEnabled: boolean
): Promise<FetchRightsResponse | Response | null> => {
  const controller = authenticatedAuthController(authDto.jwt);

  try {
    const userRights = await controller.retrieveListOfDroitsUsingPOST({
      monAIOTAccessToken: authDto.monAIOTJWT,
    });
    return { jwt: authDto, rights: userRights };
  } catch (excp) {
    if (excp instanceof Response) {
      if (isRecoveryEnabled && excp.status === HttpStatus.EXPECTATION_FAILED) {
        const newJWT = await refreshMonAIOTToken(authDto);
        if (newJWT === null) {
          return null;
        } else {
          return _fetchUserRights(newJWT, false);
        }
      } else {
        return excp;
      }
    }

    return null;
  }
};

const _fetchUserData = async (
  authDto: AuthJwtDto
): Promise<FetchUserDataResponse | Response | null> => {
  const userInfo = await _fetchUserInfo(authDto);
  if (userInfo === null) {
    return null;
  }

  const rightsResponse = await _fetchUserRights(authDto, true);
  if (rightsResponse === null) {
    return null;
  } else if (rightsResponse instanceof Response) {
    return rightsResponse;
  }

  return {
    authJwt: rightsResponse.jwt,
    userData: buildUserData(userInfo, rightsResponse.rights),
  };
};

const _fetchAndTreatUserData = async (
  authDto: AuthJwtDto,
  setAuthDto: (jwt: AuthJwtDto | null) => void,
  setUserData: (userData: UserData | null) => void,
  setAuthenticationStatus: (status: AUTHENTICATION_STATUS) => void
): Promise<boolean> => {
  const userData = await _fetchUserData(authDto);
  if (userData === null) {
    setAuthDto(null);
    setUserData(null);
    return false;
  } else if (userData instanceof Response) {
    if (userData.status === HttpStatus.UNAUTHORIZED) {
      setAuthenticationStatus(
        AUTHENTICATION_STATUS.RECONNECTION_TO_MON_ICPE_REQUIRED
      );
    } else {
      setAuthenticationStatus(AUTHENTICATION_STATUS.UNKNOWN_ERROR_OCCURRED);
    }
    return false;
  } else {
    setAuthDto(userData.authJwt);
    setUserData(userData.userData);

    // backend is supposed to send us userInfo with exactly 1 right in userInfo.droits
    if (userData.userData.userInfo.droits.length > 0) {
      window.localStorage.setItem(
        ACTIVE_RIGHT,
        JSON.stringify(userData.userData.userInfo.droits[0])
      );
    } else {
      window.localStorage.removeItem(ACTIVE_RIGHT);
      throw new Error("No right found in userInfo");
    }
    return true;
  }
};

const _authenticateUser = async (
  code: string,
  setAuthDto: (jwt: AuthJwtDto | null) => void,
  setUserData: (userData: UserData | null) => void,
  setAuthenticationStatus: (status: AUTHENTICATION_STATUS) => void
): Promise<boolean> => {
  const jwt = await _fetchJwt(code);
  if (jwt === null) {
    setAuthenticationStatus(AUTHENTICATION_STATUS.UNKNOWN_ERROR_OCCURRED);
    return false;
  } else if (jwt instanceof Response) {
    setAuthenticationStatus(AUTHENTICATION_STATUS.NO_RIGHT_OVER_GEREP);
    return false;
  }

  return _fetchAndTreatUserData(
    jwt,
    setAuthDto,
    setUserData,
    setAuthenticationStatus
  );
};

export const connectUser = async (
  authDto: AuthJwtDto | null,
  setAuthDto: (jwt: AuthJwtDto | null) => void,
  setUserData: (userData: UserData | null) => void,
  setAuthenticationStatus: (status: AUTHENTICATION_STATUS) => void,
  isRecoverAvailable: boolean = false
): Promise<string | null> => {
  // If we have a token already (authDto), we try to just fetch our user info with it
  // if it fails, we retry once to connect as if without token
  if (authDto !== null) {
    const succeed = await _fetchAndTreatUserData(
      authDto,
      setAuthDto,
      setUserData,
      setAuthenticationStatus
    );
    if (!succeed && isRecoverAvailable) {
      return connectUser(
        null,
        setAuthDto,
        setUserData,
        setAuthenticationStatus
      );
    }
    return null;
  }

  // Check if authentication from ICPE is done and code is given in url param - use it to retrieve jwt and userData
  // Get code if authentication from ICPE is done, or redirect to ICPE
  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");

  if (code) {
    _authenticateUser(code, setAuthDto, setUserData, setAuthenticationStatus);
    // State returned from ICPE contains path to redirect to once user is connected
    const state = params.get("state");
    // decode path from base64
    return atob(state || "");
  }

  // Redirect to ICPE for authentication and pass current path in base64 to be redirected once
  // authentication is complete
  const redirectUrlBase64 = btoa(window.location.pathname);
  window.location.href =
    process.env.REACT_APP_BACK_URL + "api/auth?state=" + redirectUrlBase64;
  return null;
};

// Currently called when token in localstorage changed
export const reloadUserData = async (
  authDto: AuthJwtDto,
  setAuthDto: (jwt: AuthJwtDto | null) => void,
  setUserData: (userData: UserData | null) => void,
  setAuthenticationStatus: (status: AUTHENTICATION_STATUS) => void
): Promise<void> => {
  _fetchAndTreatUserData(
    authDto,
    setAuthDto,
    setUserData,
    setAuthenticationStatus
  );
};

export const updateJwtAndActiveRight = async (
  authDto: AuthJwtDto,
  requestedRight: AuthDroitDto,
  setAuthDto: (jwt: AuthJwtDto | null) => void,
  setUserData: (userData: UserData | null) => void,
  setAuthenticationStatus: (status: AUTHENTICATION_STATUS) => void,
  isRecoverAvailable: boolean = true
): Promise<void> => {
  const newJwt = await changeRight(authDto, requestedRight);
  if (newJwt === null) {
    if (isRecoverAvailable) {
      const newAuthJwt = await refreshMonAIOTToken(authDto);
      if (newAuthJwt) {
        return updateJwtAndActiveRight(
          newAuthJwt,
          requestedRight,
          setAuthDto,
          setUserData,
          setAuthenticationStatus
        );
      } else {
        setAuthenticationStatus(
          AUTHENTICATION_STATUS.RECONNECTION_TO_MON_ICPE_REQUIRED
        );
        return;
      }
    } else {
      setAuthenticationStatus(AUTHENTICATION_STATUS.UNKNOWN_ERROR_OCCURRED);
      return;
    }
  }

  await _fetchAndTreatUserData(
    newJwt,
    setAuthDto,
    setUserData,
    setAuthenticationStatus
  );
};
