import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap.css";
import {
  DISABLED_NUMBER_INPUT_BACKGROUND,
  DISABLED_NUMBER_INPUT_TEXT,
  INPUT_BORDER_WIDTH,
} from "theme";

const useStyles = makeStyles({
  container: {
    padding: "9px",
    "& ul": {
      marginLeft: "1em",
    },
    backgroundColor: DISABLED_NUMBER_INPUT_BACKGROUND,
    border: `${INPUT_BORDER_WIDTH} solid ${DISABLED_NUMBER_INPUT_TEXT}`,
    color: "black",
  },
  triggerBox: {
    width: "90%",
    height: "100%",
    pointerEvents: "all",
  },
  width: {
    maxWidth: "400px",
    padding: 0,
    "& > div > div": {
      padding: 0,
    },
    "& > div > div:first-child": {
      // This is the arrow, which bothers me
      display: "none",
    },
  },
});

interface InfoBulleProps {
  children: ReactNode;
  content?: ReactNode;
  placement?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "topLeft"
    | "topRight"
    | "bottomLeft"
    | "bottomRight";
  additionalClassName?: string;
}

const TooltipLabel = ({
  content,
  children,
  placement,
}: InfoBulleProps): ReactElement => {
  const classes = useStyles();

  const handleOnClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation(); //This is to prevent modals to close if we click on the content of the tooltip
  };

  return (
    <Tooltip
      placement={placement || "right"}
      destroyTooltipOnHide={true}
      trigger={["hover"]}
      overlay={
        <div onClick={handleOnClick} className={classes.container}>
          <div>{content}</div>
        </div>
      }
      overlayClassName={classes.width}
    >
      <div className={classes.triggerBox}>{children}</div>
    </Tooltip>
  );
};

export default TooltipLabel;
