import { QuotasAllocationsEmailsVerificateursDto20Now } from "api/gen";
import { Declaration21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";

export const updateHandlerEmailsAddressAllocations21Now = (
  declaration: Declaration21Now,
  values: QuotasAllocationsEmailsVerificateursDto20Now | null
): Declaration21Now => {
  if (values) {
    declaration.body.sections.quotas.allocations.blocEmails = values;
  }
  return declaration;
};
