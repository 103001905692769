import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import { LARGE_BUTTON_WIDTH } from "theme";
import { cloneDeep } from "lodash";
import Row from "common/presentational/Row";
import Button from "common/button";
import InstallationArray from "./InstallationArray";
import InstallationModale from "./InstallationModale";
import { InArray } from "common/form/utils";
import { createAirCombustionsInstallationDto } from "./utils/utils";
import { InstallationInArray } from "./utils/types";
import { AppareilInArray } from "../ListInstallation/BlocAppareils/utils/types";
import { CombustibleInArray } from "../ListInstallation/BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../ListInstallation/BlocEmissions/utils/types";
import { LegacySubInstallationElement } from "../utils/types";
import { useConfirmationModale } from "common/modale/hooks";
import { createAirCombustionsAppareilDto } from "../ListInstallation/BlocAppareils/utils/utils";
import { createAirCombustionsCombustibleDto } from "../ListInstallation/BlocCombustibles/utils/utils";
import { createAirCombustionEmissionDto } from "../ListInstallation/BlocEmissions/utils/utils";
import { hasEqualDisplayed } from "../utils/utils";
import { TypeActiviteGlobalDto21Now } from "api/gen";
import BlocFullContext2123 from "../../../BlocFullContext2123";
import { Declaration2123 } from "../../../declarationHooks2123";

const useStyles = makeStyles({
  largeButton: {
    width: LARGE_BUTTON_WIDTH,
  },
  marginBottom: {
    marginBottom: "20px",
  },
});

interface BlocInstallationProps {
  initialInstallations: InstallationInArray[];
  installationsInPage: InstallationInArray[];
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  validationInstallationPath: string;
  isLcp: boolean;
  isWicowi: boolean;
  appareilsInPage: AppareilInArray[];
  setAppareilsInPage: React.Dispatch<React.SetStateAction<AppareilInArray[]>>;
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  typeActiviteGlobal: TypeActiviteGlobalDto21Now;
}

const BlocInstallation = ({
  installationsInPage,
  setInstallationsInPage,
  validationInstallationPath,
  initialInstallations,
  isLcp,
  isWicowi,
  appareilsInPage,
  setAppareilsInPage,
  combustiblesInPage,
  setCombustiblesInPage,
  facteursInPage,
  setFacteursInPage,
  mesuresInPage,
  setMesuresInPage,
  matieresInPage,
  setMatieresInPage,
  typeActiviteGlobal,
}: BlocInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const [installationModaleOpen, setInstallationModaleOpen] = useState(false);
  const openConfirmationModale = useConfirmationModale();
  const [
    installationInModale,
    setInstallationInModale,
  ] = useState<InstallationInArray | null>(null);

  function deleteIfNotExists<T extends LegacySubInstallationElement>(
    contents: InArray<T>[]
  ) {
    const installationsName = installationsInPage.map(
      element => element.data.nom
    );
    return contents.filter(content => {
      return installationsName.some(installationName => {
        return installationName === content.data.nameInstallation;
      });
    });
  }

  const updateHandler = (declaration: Declaration2123) => {
    declaration.body.sections.air.combustion.installations = createAirCombustionsInstallationDto(
      installationsInPage
    );
    const appareil = deleteIfNotExists(appareilsInPage);
    declaration.body.sections.air.combustion.content.appareils = createAirCombustionsAppareilDto(
      appareil
    );
    setAppareilsInPage(appareil);
    const combustibles = deleteIfNotExists(combustiblesInPage);
    declaration.body.sections.air.combustion.content.combustibles = createAirCombustionsCombustibleDto(
      combustibles
    );
    setCombustiblesInPage(combustibles);
    const facteurs = deleteIfNotExists(facteursInPage);
    const mesures = deleteIfNotExists(mesuresInPage);
    const matieres = deleteIfNotExists(matieresInPage);
    declaration.body.sections.air.combustion.content.emissions = createAirCombustionEmissionDto(
      [facteurs, mesures, matieres]
    );
    setFacteursInPage(facteurs);
    setMesuresInPage(mesures);
    setMatieresInPage(matieres);
    return declaration;
  };

  const compareFunction = (
    a: InstallationInArray,
    b: InstallationInArray
  ): number => {
    if (a.data.nom && b.data.nom && a.data.nom !== b.data.nom) {
      return a.data.nom < b.data.nom ? -1 : 1;
    } else {
      return 0;
    }
  };

  return (
    <BlocFullContext2123
      title="Déclaration des installations"
      hasModification={
        !hasEqualDisplayed(initialInstallations, installationsInPage)
      }
      isValidateButtonAvailable={true}
      path={validationInstallationPath}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration => updateHandler(declaration)}
      cancelAction={() => {
        setInstallationsInPage(initialInstallations);
      }}
      renderContent={shouldDisabledFields => {
        return (
          <>
            <span className={classes.marginBottom}>
              Les étapes de déclaration pour une installation de combustion sont
              les suivantes :
              <ul style={{ listStyle: "none" }}>
                <li>
                  1 - description de l’installation (LCP - grande installation
                  de combustion, WI - incinération, coWI - coincinération, Autre
                  - toute installation non listée) Chaque installation de
                  combustion ou incinération est déclarée en une seule et unique
                  installation incluant les appareils de combustion ou
                  incinération exploités par un même opérateur et situés sur un
                  même site (enceinte de l'établissement) sauf à ce que
                  l'exploitant démontre que les appareils ne pourraient pas être
                  techniquement et économiquement raccordés à une cheminée
                  commune.
                </li>
                <li>2 - description des appareils de l'installation.</li>
                <li>
                  3 - déclaration des combustibles consommés dans
                  l'installation.
                </li>
                <li>4 - déclaration des émissions de l'installation.</li>
              </ul>
            </span>
            <Row additionalStyle={{ justifyContent: "flex-end" }}>
              <Button
                text="AJOUTER UNE INSTALLATION"
                additionalClassname={classes.largeButton}
                onClick={() => setInstallationModaleOpen(true)}
                isDisabled={shouldDisabledFields}
              />
            </Row>
            <Row />
            <InstallationArray
              installationsInPage={cloneDeep(installationsInPage).sort(
                compareFunction
              )}
              setInstallationsInPage={setInstallationsInPage}
              setInstallationInModale={setInstallationInModale}
              setInstallationModaleOpen={setInstallationModaleOpen}
              isValidated={shouldDisabledFields}
              validationInstallationPath={validationInstallationPath}
            />
            <Row height={"15px"} />
            <Row additionalStyle={{ justifyContent: "flex-end" }}>
              <Button
                text="TOUT SUPPRIMER"
                additionalClassname={classes.largeButton}
                onClick={() => {
                  openConfirmationModale(
                    "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                    () => setInstallationsInPage([])
                  );
                }}
                isReversed
                isDisabled={shouldDisabledFields}
              />
            </Row>
            <Row />
            <InstallationModale
              isOpen={installationModaleOpen}
              installationsInPage={installationsInPage}
              installationInModal={installationInModale}
              setInstallationsInPage={setInstallationsInPage}
              setInstallationInModale={setInstallationInModale}
              setModaleOpen={setInstallationModaleOpen}
              isLcp={isLcp}
              isWicowi={isWicowi}
              setAppareilsInPage={setAppareilsInPage}
              setCombustiblesInPage={setCombustiblesInPage}
              setFacteursInPage={setFacteursInPage}
              setMesuresInPage={setMesuresInPage}
              setMatieresInPage={setMatieresInPage}
              typeActiviteGlobal={typeActiviteGlobal}
            />
          </>
        );
      }}
    />
  );
};

export default BlocInstallation;
