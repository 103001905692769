import DeclarationSummary from "pages/CompanySpace/from20/to20/Dashboard/DeclarationSummary";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import Row from "common/presentational/Row";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import React from "react";
import { useUserData } from "Authenticator/UserData";
import DeclarationStatut from "./components/DeclarationStatut";
import DeclarantGlobalActionButtons from "./components/DeclarantGlobalActionButtons";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import {
  useDeclaration2020,
  useDeclarationState2020,
} from "../../versionedElements/declarationHooks2020";
import { WorkflowTarget } from "common/utils/types";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";

const DashboardDeclarant = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const declaration = useDeclaration2020();
  const declarationState = useDeclarationState2020();
  const declarationStateComment =
    declaration.body.workflowStatus.general.message;

  const currentCampaign = useCurrentDeclarationCampaign();

  const { isAnyPrestataire, isLecteur } = useUserData();

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };
  usePageHeaderSetterOnce(headerData);

  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateGlobal
  );

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />
      {!isAnyPrestataire && <DeclarationStatut />}
      <Row height={"20px"} />
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.GLOBAL}
        isInReviewState={declarationState.isDeclarationStateContaining([
          "IN_REVIEW",
        ])}
        comment={declarationStateComment}
      />
      <Row height={"20px"} />
      <DeclarationSummary
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        isVerificateur={isAnyPrestataire}
        isLecteur={isLecteur}
      />
      <Row height={"20px"} />

      {!isAnyPrestataire && <DeclarantGlobalActionButtons />}
      <Row height={"20px"} />
    </>
  );
};

export default DashboardDeclarant;
