import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { AlcDto20Now, QuotasInstallationDto20Now } from "api/gen";
import { alcDtoToAlcFileDto } from "./utils";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import {
  useAlcPreliminaireFileHandler2020,
  useAlcVerifieFileHandler2020,
} from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";

interface AlcUploaderProps {
  label: string;
  installation: QuotasInstallationDto20Now;
  filesPath: string;
  isAlcVerifie: boolean;
  shouldDisableFields: boolean;
}

const AlcRapportFileLoader = ({
  label,
  installation,
  filesPath,
  isAlcVerifie,
  shouldDisableFields,
}: AlcUploaderProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const openAlertModale = useAlertModale();
  const alcVerifieFileHandler = useAlcVerifieFileHandler2020(installation.id);
  const alcPreliminaireFileHandler = useAlcPreliminaireFileHandler2020(
    installation.id
  );

  const sectionFileHandler = isAlcVerifie
    ? alcVerifieFileHandler
    : alcPreliminaireFileHandler;

  const filesSection = isAlcVerifie
    ? FileSectionEnum.QUOTAS_RAPPORT_ALC_VERIFIE
    : FileSectionEnum.QUOTAS_RAPPORT_ALC_PRELIMINAIRE;

  return (
    <FilesLoader
      declaration={declaration}
      text={`DÉPOSER LE RAPPORT ALC ${label}`}
      filesPath={filesPath}
      section={filesSection}
      fileHandlers={sectionFileHandler}
      //
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      max={1}
      transformFunction={(alcs: Record<string, unknown>[]) =>
        alcDtoToAlcFileDto(installation.id, (alcs as unknown) as AlcDto20Now[])
      }
    />
  );
};

export default AlcRapportFileLoader;
