import { ErrorLevel, ErrorProps } from "common/utils/types";
import { FieldProps } from "libAdapter/Formik/TypesPatternAdaptater";
import { OptionProps } from "../types/basicTypes";

export function computeErrorObject({
  form,
  field,
}: FieldProps): ErrorProps | null {
  const error: ErrorProps | null =
    form.touched[field.name] !== null && form.errors[field.name] !== undefined
      ? {
          text: form.errors[field.name] as string,
          errorLevel: ErrorLevel.Blocking,
        }
      : null;
  return error;
}

//this method takes a string representing the label you want in
//the array of option props, and an array of option props, and it will return
// the optionProps having a label corresponding to the string given.
export function computeRightOptionProps<T extends OptionProps = OptionProps>(
  strVal: string | null | undefined,
  possibleProps: T[]
): T | undefined {
  if (strVal === null || strVal === undefined) {
    return undefined;
  }
  return possibleProps.find(
    singlePossibleProps =>
      singlePossibleProps.label.toLowerCase() === strVal.toLowerCase()
  );
}

// same as function on top this but with null instead of undefined. Is usually used as a transition between a new "correct" method, and an elder, "legacy" method.
export function cleanComputeRightOptionProps<
  T extends OptionProps = OptionProps
>(strVal: string | null | undefined, possibleProps: T[]): T | null {
  return computeRightOptionProps(strVal, possibleProps) || null;
}

//this method takes an array of string representing the labels you want in
//the array of option props, and an array of option props, and it will return
//an array of all the optionProps having a label in the array of string given.
export const computeRightArrayOptionProps = (
  strVal: string[] | null,
  possibleProps: OptionProps[]
): OptionProps[] | undefined => {
  if (strVal === null || strVal === undefined) {
    return undefined;
  }
  const array: OptionProps[] = [];
  strVal.forEach(singleStrVal => {
    const optionProps = computeRightOptionProps(singleStrVal, possibleProps);
    if (optionProps) {
      array.push(optionProps);
    }
  });
  return array;
};
