import React from "react";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import {
  commonNullableStringFields,
  commonObjectFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  codePostalMessage,
  requiredMessage,
  sirenLengthMessage,
  wrongCommuneMessage,
} from "common/declarant/formik/formikMessages";
import { parseToUndefinedIfNull } from "common/form/utils";
import { AdministrationValues } from "../utils/types";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import { Nullable } from "common/utils/types";
import { ReferenceItemGeoCodeInseeDto, ReferenceItemPaysDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { isSearchStringInCollection } from "common/utils/methods";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import { isCountryFrance } from "../utils/mainSubmitHandler";
import FormikBlocFullContext1919 from "../../versionedElements/FormikBlocFullContext1919";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

//  to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = Yup.object().shape({
  socialReason: commonStringFields,
  usualName: commonStringFields,
  parentCompany: commonNullableStringFields,
  legalStatus: commonStringFields,
  SIRENNumber: Yup.string()
    .trim()
    .transform(parseToUndefinedIfNull)
    .matches(/^[0-9]{9}$/, sirenLengthMessage),
  country: commonObjectFields,
  adress: commonStringFields,
  postalCode: commonStringFields,
});

interface CompanyIdentityProps
  extends CommonConnectedFormikBlocProps<
    Nullable<AdministrationValues>,
    Declaration1919
  > {
  referentielINSEE: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[];
  referentielPays: OptionPropsWithObject<ReferenceItemPaysDto | null>[];
}

export const CompanyIdentity = ({
  formikRef,
  hasChanges,
  setHasChanges,
  initialValues,
  path,
  updateHandler,
  referentielINSEE,
  referentielPays,
}: CompanyIdentityProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-identity",
  };

  const TextField = useTextFieldGenerator(commonProps);

  const getCityOptionProps = (city: string | null) => {
    if (city) {
      const result = referentielINSEE.find(ref => ref.label === city);
      return result || { value: -1, label: city, object: null };
    }
    return null;
  };

  return (
    <FormikBlocFullContext1919
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      validationSchema={validationSchema}
      additionalFieldValidation={values => {
        const errors: FormikErrors<Nullable<AdministrationValues>> = {};
        if (values.country && values.city === null) {
          errors.city = requiredMessage;
        }
        if (
          values.country &&
          isCountryFrance(values.country.label) &&
          values.postalCode &&
          !/^[0-9]{5}$/.test(values.postalCode)
        ) {
          errors.postalCode = codePostalMessage;
        }
        if (
          values.city !== null &&
          (values.city as OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>)
            .value === -1
        ) {
          errors.city = wrongCommuneMessage;
        }
        return errors;
      }}
      title={"identité de l'entreprise"}
      pathToValidate={path}
      updateHandler={updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <TextField
              label="Raison sociale *"
              name="socialReason"
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Nom d'usage *"
              name="usualName"
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Société mère"
              name="parentCompany"
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Forme juridique *"
              name="legalStatus"
              disabled={shouldDisabledFields}
              tooltipContent={"EARL, GAEC, SA, SARL, SAS, etc."}
            />
            <TextField
              label="Numéro de SIREN"
              name="SIRENNumber"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"country"}
              label={"Pays *"}
              disabled={shouldDisabledFields}
              title={""}
              header={["Pays"]}
              linesData={referentielPays}
              formatLine={option => [
                option.object ? option.object.designation : option.label,
              ]}
              commonProps={commonProps}
              isFirstSticky={false}
              formatSelectedTitle={lineData =>
                lineData.object ? lineData.object.designation : lineData.label
              }
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData.object && lineData.object.designation,
                    lineData.label,
                  ],
                  searchedStr
                )
              }
              onSelect={newValue => {
                if (newValue) {
                  if (
                    formProps.values.country &&
                    formProps.values.country.object === null &&
                    newValue.object !== null &&
                    typeof formProps.values.city !== "string"
                  ) {
                    formProps.setFieldValue(
                      "city",
                      formProps.values.city ? formProps.values.city.label : null
                    );
                  }
                  if (
                    formProps.values.country &&
                    formProps.values.country.object !== null &&
                    newValue.object === null &&
                    (typeof formProps.values.city === "string" ||
                      formProps.values.city === null)
                  ) {
                    formProps.setFieldValue(
                      "city",
                      getCityOptionProps(formProps.values.city)
                    );
                  }
                } else {
                  formProps.setFieldValue("city", null);
                }
              }}
            />
            {formProps.values.country ? (
              isCountryFrance(formProps.values.country.label) ? (
                <>
                  <TextField
                    label="Adresse *"
                    name="adress"
                    disabled={shouldDisabledFields}
                  />
                  <WrappedChoiceSelectModale
                    name={"city"}
                    label={"Commune *"}
                    disabled={shouldDisabledFields}
                    title="Commune"
                    header={[
                      "Code INSEE",
                      "Code postal",
                      "Commune",
                      "Département",
                    ]}
                    linesData={referentielINSEE}
                    formatLine={insee => [
                      insee.object.codeInsee,
                      insee.object.codePostal,
                      insee.object.nomCommune,
                      insee.object.numeroDepartement,
                    ]}
                    formatSelectedTitle={(
                      lineData: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto | null>
                    ) => {
                      return lineData.object
                        ? lineData.object.nomCommune
                        : lineData.label;
                    }}
                    isFirstSticky={false}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection(
                        [
                          lineData.object.codeInsee,
                          lineData.object.codePostal,
                          lineData.object.nomCommune,
                          lineData.object.numeroDepartement,
                        ],
                        searchedStr
                      )
                    }
                    preferredColWidths={[100, 100, 250, 80]}
                    onSelect={data => {
                      formProps.setFieldValue(
                        "postalCode",
                        data ? data.object.codePostal : data
                      );
                    }}
                    commonProps={commonProps}
                  />
                  <TextField
                    label="Code postal *"
                    name="postalCode"
                    disabled={shouldDisabledFields}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label="Adresse *"
                    name="adress"
                    disabled={shouldDisabledFields}
                  />
                  <TextField
                    name="city"
                    label="Commune *"
                    disabled={shouldDisabledFields}
                    tooltipContent={
                      "Saisir le nom de la commune en mettant des tirets (-) entre les mots pour une commune à noms composés et sans saisir l’article par lequel commence éventuellement le nom de la commune. Exemple pour « Les Sables d’Olonne », écrire « Sables- d’Olonne (les)», ou pour « Le Havre » écrire « Havre (Le) »"
                    }
                  />
                  <TextField
                    label="Code postal *"
                    name="postalCode"
                    disabled={shouldDisabledFields}
                  />
                </>
              )
            ) : (
              <></>
            )}
          </>
        );
      }}
    />
  );
};
