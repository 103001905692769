import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { DASHBOARD_BORDER_GREY, SECTION_TITLE_GREY } from "theme";
import { AlcModeleDownloader } from "./AlcModeleDownloader";
import { useDeclaration2122 } from "../../../versionedElements/declarationHooks2122";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
    color: SECTION_TITLE_GREY,
    "&>p": {
      marginBottom: "20px",
    },
    marginBottom: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
});

const BlocAlcDescription = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2122();
  return (
    <div className={classes.container}>
      <p>
        <span className={classes.bold}>
          Rapport de déclaration des niveaux d’activité (ALC) :
        </span>
      </p>

      <p>
        ATTENTION : Seul le rapport ALC préliminaire déposé avant le 31/01 de
        chaque année sera pris en compte pour déterminer si la délivrance des
        quotas gratuits pour l'année en cours peut être effectuée le 28/02. Un
        défaut de dépôt avant le 31/01 suspendra automatiquement la délivrance
        des quotas gratuits. La délivrance interviendra alors après
        l'instruction de la déclaration vérifiée des niveaux d'activité et le
        recalcul éventuel de l'allocation en cas de baisse ou hausse d'un niveau
        d'activité.
      </p>

      <p>
        Le rapport ALC préliminaire ne nécessite pas la validation par un
        vérificateur.{" "}
        <span className={classes.bold}>
          Le dépôt d'un rapport ALC dans la section RAPPORT DE DÉCLARATION DES
          NIVEAUX D'ACTIVITÉ (VÉRIFIÉ) est obligatoire, ainsi que la validation
          par un vérificateur
        </span>{" "}
        qui doit intervenir avant le 31/03.
      </p>

      <p>
        ATTENTION : Il est impératif de{" "}
        <span className={classes.bold}>
          repartir de la dernière version validée du rapport ALC vérifié de
          l'année précédente{" "}
        </span>
        disponible sur le lien ci-dessous, sauf si une consigne de repartir du
        dernier fichier vérifié non validé a été transmise par le service
        d'inspection.
      </p>

      <p>
        Le bouton "télécharger votre rapport ALC vérifié de l'année précédente"
        vous permet d'accéder à votre fichier ALC {declaration.annee}. Il a
        normalement été mis{" "}
        <span className={classes.bold}>
          à jour avec les valeurs actualisées des BM.
        </span>
      </p>

      <p>
        Attention cependant, la mise à jour avec les valeurs actualisées des BM
        n'a pas été effectuée lorsque le fichier ALC de l'année précédente
        n'était pas validé ou comportait des erreurs trop importantes et le
        fichier téléchargeable dans ce cas est le dernier fichier déposé sur la
        plateforme (avant le 31 juillet {declaration.annee}). Les installations
        concernées ont reçu une consigne du service d'inspection en ce sens et
        doivent obligatoirement repartir du dernier fichier ALC vérifié transmis
        au service d'inspection. Il est aussi possible dans ce cas de repartir
        d'un fichier vierge.
      </p>

      <AlcModeleDownloader />
    </div>
  );
};

export default BlocAlcDescription;
