import React from "react";
import { GlobalFormActionsInContextProps } from "./types";
import GlobalFormActions from "./GlobalFormActions";
import { useUserData } from "Authenticator/UserData";
import { areActionsAllowedInUserContext } from "../utils/methods";
import { useStatusCampaignCurrentWorkflow } from "../../pages/CompanySpace/WorkflowTargetProviderContext";

/**
 * form actions controlling button's accessibility
 * si l'utilisateur actuel est un inspecteur et qu'il n'est pas en mode déclaration d'office il ne doit pas pouvoir interragir avec les buttons
 *
 * use case : wrapped in GlobalFormActionInFullContext, should not be used elsewhere
 *
 * for props detail see GlobalFormAction comments
 * GlobalFormActionsInUserContext computes actionsAllowed prop of GlobalFormActions
 *
 * @param isAvailableToDeclarant: are declarant allowed to use this bloc (default: true)
 * @param isAvailableToAnyPrestataire: are prestataire allowed to use this bloc (default: false)
 * @param declarationState: current declaration state (may be either global state or quotas state)
 * @param validationMessage: message to display next to validation button. It is an optional object
 * with the message to display and a boolean indicating if the message should be always displayed
 * or only when the button is disabled. If undefined, no message will be displayed.
 */
const GlobalFormActionsInUserContext = ({
  validationTitle,
  isValidated,
  hasChanges,
  isValidateEnabled,
  editAction,
  cancelAction,
  saveAction,
  validateAction,
  declarationState,
  declarationEtablissement,
  isAvailableToDeclarant = true,
  isAvailableToAnyPrestataire = false,
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  commentAction,
  hasComments,
  areGlobalCommentsAllowed,
  validationMessage,
}: GlobalFormActionsInContextProps): React.ReactElement => {
  const userData = useUserData();
  const currentCampaignStatus = useStatusCampaignCurrentWorkflow();
  const areActionsAllowed = areActionsAllowedInUserContext(
    userData,
    declarationState,
    currentCampaignStatus,
    declarationEtablissement,
    isAvailableToDeclarant,
    isAvailableToAnyPrestataire,
    false,
    false
  );

  return (
    <GlobalFormActions
      validationTitle={validationTitle}
      isValidated={isValidated}
      hasChanges={hasChanges}
      isValidateEnabled={isValidateEnabled}
      actionsAllowed={areActionsAllowed}
      editAction={editAction}
      cancelAction={cancelAction}
      saveAction={saveAction}
      validateAction={validateAction}
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      validationMessage={validationMessage}
      commentAction={commentAction}
      hasComments={hasComments}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed}
    />
  );
};

export default GlobalFormActionsInUserContext;
