import {
  ProduitRecyclageDto23Now,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { WithFamily, withFamilyTypeMatcher } from "../../Families/types";

export interface RecyclingActivityForm {
  recyclingActivity: boolean;
  recyclingMaterialQuantity: number;
  errorArrayRequired?: string;
}

export type ProduitRecyclingType = Omit<
  ProduitRecyclageDto23Now,
  "famille" | "sousFamille" | "sousFamilleNiveau2"
> &
  WithFamily<ReferenceItemFamilleUsageRecyclageDto>;

const fieldProduitRecyclingTypeWithoutFamilyMatcher = fieldMatcherBuilder<
  ProduitRecyclingType
>().build({
  nature: "nature",
  precisionNature: "precisionAutre",
  quantity: "quantite",
});

export const fieldProduitRecyclingTypeMatcher = {
  ...fieldProduitRecyclingTypeWithoutFamilyMatcher,
  ...withFamilyTypeMatcher,
};
