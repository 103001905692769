import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ARRAY_DEFAULT_BUTTON_WIDTH, PINK } from "theme";
import { ClassesType } from "common/utils/types";
import classNames from "classnames";
import { FluidInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import {
  computeNumberWithPercentage,
  computeNumberWithSeparator,
  getValueOrZero,
} from "common/utils/numberUtils";
import { isGazAutre } from "./validation/validation";
import { computeOtherPercentage } from "./utils/utils";

const useStyles = makeStyles({
  arrayPolluant: {},

  littleCol: {
    width: "130px",
  },

  longCol: {
    width: "200px",
  },

  buttonsHeader: {
    width: ARRAY_DEFAULT_BUTTON_WIDTH,
  },

  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },

  error: {
    backgroundColor: "red",
  },

  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const computeWrappedCell = (
  contentCell: React.ReactNode,
  classes: ClassesType<"full" | "error">,
  errorMessage?: string
) => {
  return (
    <div
      className={classNames(classes.full, { [classes.error]: errorMessage })}
    >
      {contentCell}
    </div>
  );
};

interface HFCArrayProps {
  fluidInArray: FluidInArray[];
  setFluidInArray: Dispatch<SetStateAction<FluidInArray[]>>;
  setFluidInModale: Dispatch<SetStateAction<FluidInArray | null>>;
  setFluidModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  commentPath: string;
}

const HFCArray = ({
  fluidInArray,
  setFluidInArray,
  setFluidInModale,
  setFluidModaleOpen,
  isValidated,
  commentPath,
}: HFCArrayProps): React.ReactElement => {
  const classes = useStyles();

  function suppressFluid(singleFluid: FluidInArray) {
    setFluidInArray(elderFluid => {
      return elderFluid.filter(fluidInArray => {
        return singleFluid.data.id !== fluidInArray.data.id;
      });
    });
  }

  const computeSingleFluidLine = (
    fluid: FluidInArray,
    classes: ClassesType<"link" | "full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setFluidInModale(fluid);
      setFluidModaleOpen(true);
    };
    const otherPercentage = computeOtherPercentage(fluid.data);
    return [
      computeWrappedCell(
        <p
          className={isValidated ? "" : classes.link}
          onClick={isValidated ? undefined : editAction}
        >
          {fluid.data.fluid !== null && !isGazAutre(fluid.data.fluid)
            ? fluid.data.fluid.nom
            : fluid.data.preciser || ""}
        </p>,
        classes,
        fluid.errors.fluid
      ),
      computeWrappedCell(
        computeNumberWithSeparator(getValueOrZero(fluid.data.quantite)),
        classes,
        fluid.errors.quantite
      ),
      computeWrappedCell(
        computeNumberWithSeparator(getValueOrZero(fluid.data.capacite)),
        classes,
        fluid.errors.capacite
      ),
      computeWrappedCell(
        <p title={fluid.data.justification || undefined}>
          {fluid.data.justification}
        </p>,
        classes,
        fluid.errors.justification
      ),

      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc1233zd),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc1233zd
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc1234yf),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc1234yf
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc1234ze),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc1234ze
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc125),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc125
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc134),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc134
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc134a),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc134a
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc143),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc143
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc143a),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc143a
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc152a),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc152a
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc227ea),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc227ea
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc23),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc23
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc236fa),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc236fa
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc245ca),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc245ca
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc245fa),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc245fa
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc32),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc32
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc365mfc),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc365mfc
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc41),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc41
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(fluid.data.hfc4310mee),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.hfc4310mee
      ),
      computeWrappedCell(
        computeNumberWithPercentage(
          getValueOrZero(otherPercentage),
          getValueOrZero(fluid.data.quantite)
        ),
        classes,
        fluid.errors.autre
      ),
      // todo: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressFluid(fluid);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer ce fluide ?"}
        isValidated={isValidated}
        commentPath={`${commentPath}/${fluid.data.id}`}
      />,
    ];
  };

  const arrayFluidLines = fluidInArray.map(singleFluidInArray => {
    return computeSingleFluidLine(singleFluidInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p className={classes.longCol}>Nom du fluide</p>,
        <p className={classes.littleCol}>Quantité émise (kg)</p>,
        <p>Capacité (kg)</p>,
        <p>Justification</p>,
        <p>HFC-1233zd (kg)</p>,
        <p>HFC-1234yf (kg)</p>,
        <p>HFC-1234ze (kg)</p>,
        <p>HFC-125 (kg)</p>,
        <p>HFC-134 (kg)</p>,
        <p>HFC-134a (kg)</p>,
        <p>HFC-143 (kg)</p>,
        <p>HFC-143a (kg)</p>,
        <p>HFC-152a (kg)</p>,
        <p>HFC-227ea (kg)</p>,
        <p>HFC-23 (kg)</p>,
        <p>HFC-236fa (kg)</p>,
        <p>HFC-245ca (kg)</p>,
        <p>HFC-245fa (kg)</p>,
        <p>HFC-32 (kg)</p>,
        <p>HFC-365mfc (kg)</p>,
        <p>HFC-41 (kg)</p>,
        <p>HFC-4310mee (kg)</p>,
        <p>Autres composés (kg)</p>,
        <p className={classes.buttonsHeader}>Actions</p>,
      ]}
      preferredColWidths={[
        200,
        170,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        170,
        ACTION_COLUMN_DEFAULT_WIDTH,
      ]}
      lines={arrayFluidLines}
      additionalClassName={classes.arrayPolluant}
    />
  );
};

export default HFCArray;
