import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useJustificatifRapportEmissionsFileHandler21Now } from "../../../versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import { NimFilesLoaderProps } from "../../common/FilesRelatedToNim/utils";
import JustificatifsFilesLoader from "../../common/FilesRelatedToNim/JustificatifsFilesLoader";

const JustificatifRapportLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): React.ReactElement => {
  const justificatifFileHandler = useJustificatifRapportEmissionsFileHandler21Now(
    installationId
  );

  return (
    <JustificatifsFilesLoader
      installationId={installationId}
      shouldDisableFields={shouldDisableFields}
      filesPath={
        "body.externalSections.quotas.emissions.blocEmissions.documentsJustificatifs"
      }
      fileSection={
        FileSectionEnum.QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS
      }
      justificatifFileHandler={justificatifFileHandler}
    />
  );
};

export default JustificatifRapportLoader;
