import { OptionProps } from "common/form/fields/types/basicTypes";

const careerTypeSouterrain: OptionProps[] = [
  {
    value: 0,
    label: "Souterrain",
  },
  {
    value: 1,
    label: "Ciel ouvert",
  },
];

const careerTypeEau: OptionProps[] = [
  {
    value: 0,
    label: "Eau",
  },
  {
    value: 1,
    label: "Hors eau",
  },
];

export const selectPossibleValues = {
  careerTypeSouterrain: careerTypeSouterrain,
  careerTypeEau: careerTypeEau,
};
