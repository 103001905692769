import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { singleCovValidationSchema } from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { arrayMentions } from "./utils/selectPossibleValues";
import { CovInModale } from "./utils/types";
import { ProcedeInArray } from "../listprocede/utils/types";

interface FormCovProps {
  closeFunction: () => void;
  onSubmit: (
    values: CovInModale,
    formikActions: FormikActions<CovInModale>
  ) => void;
  initialCov: CovInModale;
  isEdit: boolean;
  initialProcedes: ProcedeInArray[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const FormSingleCov = ({
  closeFunction,
  onSubmit,
  initialCov,
  isEdit,
  initialProcedes,
}: FormCovProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-cov-procedes",
  };
  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);

  const getProcedes = () => {
    return initialProcedes.map((procede, i) => {
      if (!procede.data.name) {
        procede.data.name = "none";
      }
      return {
        value: procede.data.name,
        label: procede.data.name,
        object: { ...procede },
      };
    });
  };

  return (
    <CommonFormSingleEntity
      title="AJOUTER DES ÉMISSIONS DE COV A MENTION DE DANGER"
      closeFunction={closeFunction}
      onSubmit={(values: CovInModale, formikBag) => {
        onSubmit(values, formikBag);
      }}
      initialEntity={initialCov}
      validationSchema={singleCovValidationSchema}
      isEdit={isEdit}
      renderField={() => {
        return (
          <>
            <ChoiceSelectField
              name="substance"
              label="Utilisation de COV à mention de danger *"
              isMulti={false}
              options={arrayMentions}
            />
            <ChoiceSelectField
              isMulti
              name="procede"
              label="Procédé(s) *"
              options={getProcedes()}
            />
            <NumberField name="flux" label="Émissions *" unit="kg/an" />
          </>
        );
      }}
    />
  );
};

export default FormSingleCov;
