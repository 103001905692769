import React from "react";

export const methodsMessage = (
  <ul style={{ listStyle: "none" }}>
    <li>
      INT : Norme de mesurage ou méthode de calcul approuvée internationalement
    </li>
    <li>
      PER ; Méthode de mesure ou de calcul déjà prescrite par l'autorité
      compétente dans le cadre d'une licence ou d'un permis d'exploitation pour
      l'établissement concerné
    </li>
    <li>
      NRO : Méthode de mesure ou de calcul nationale ou régionale obligatoire
      prescrite par la loi pour le polluant et l'établissement concerné
    </li>
    <li>
      ALT : Méthode de mesure alternative conforme aux normes de mesurage
      CEN/ISO existantes
    </li>
    <li>
      MRC : Méthode de mesure dont la performance est démontrée au moyen de
      matériels de référence certifiés et agréée par l'autorité compétente
    </li>
    <li>BMA : Méthode par bilan massique agréée par l'autorité compétente</li>
    <li>CSS : Méthode de calcul spécifique par secteur européenne</li>
    <li>AUT : Autre méthode de mesure ou de calcul</li>
  </ul>
);
