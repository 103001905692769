import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  useBooleanCheckBoxGenerator,
  useDateFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  autreSubPartNatureActivated,
  autreSubPartTypeFoyerActivated,
  isChaudiereSelected,
  singleAppareilOverwriteDependantFields,
  singleAppareilValidationSchema,
  validate,
} from "./validation/validation";
import {
  arrayNature,
  arrayNatureLabel,
  arrayTypeFoyer,
  arrayTypeFoyerLabel,
} from "./utils/selectPossibleValues";
import { AppareilInModale } from "./utils/types";
import { InstallationInArray } from "../../BlocInstallation/utils/types";
import { isAppareilSubpartFormAvailable } from "./utils/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

interface FormAppareilProps {
  closeFunction: () => void;
  onSubmit: (
    values: AppareilInModale,
    formikActions: FormikActions<AppareilInModale>
  ) => void;
  initialAppareil: AppareilInModale | null;
  installation: InstallationInArray;
  forbiddenAppareilName: string[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormAppareil = ({
  closeFunction,
  onSubmit,
  initialAppareil,
  installation,
  forbiddenAppareilName,
}: FormAppareilProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-appareil-appareil-individuelle",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN APPAREIL"
      closeFunction={closeFunction}
      onSubmit={(values: AppareilInModale, formikBag) => {
        singleAppareilOverwriteDependantFields(values);
        onSubmit(values, formikBag);
      }}
      isEdit={initialAppareil !== null}
      initialEntity={
        initialAppareil === null
          ? {
              nameInstallation: null,
              nom: null,
              date: null,
              modification: false,
              lowestThan1500: false,
              localisation: null,
              activite: null,
              nature: null,
              natureAutre: null,
              typeFoyer: null,
              foyerAutre: null,
              capacite: null,
              unite: null,
              hauteurRejets: null,
            }
          : initialAppareil
      }
      validationSchema={singleAppareilValidationSchema}
      validate={values => validate(values, forbiddenAppareilName)}
      renderField={({ values }) => {
        return (
          <>
            <TextField name="nom" label="Nom de l'appareil *" />
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation"
              value={installation.data.nom}
              disabled
            />
            <DateField
              name="date"
              label="Date de mise en service de l'appareil *"
            />
            {isAppareilSubpartFormAvailable(installation.data.type) && (
              <>
                <BooleanField
                  name="modification"
                  label="Modification substantielle de l'appareil dans l'année"
                  tooltipContent="L’équipement a fait l’objet au cours de l’année rapportée d’une modification substantielle (application de l’article R. 512-33 du code de l’environnement)."
                />
                <BooleanField
                  name="lowestThan1500"
                  label="Appareil de combustion fonctionnant moins de 1500 heures par an en moyenne mobile calculée sur une période de 5 ans"
                />
              </>
            )}
            <TextField name="localisation" label="Localisation sur le site" />
            <TextField name="activite" label="Activité(s) développée(s)" />
            <WrappedChoiceSelect
              name="nature"
              label="Nature de l'appareil *"
              isMulti={false}
              options={arrayNature}
              commonProps={commonProps}
              computeLabel={nature => arrayNatureLabel[nature]}
            />
            {autreSubPartNatureActivated(values.nature) && (
              <TextField name="natureAutre" label="Préciser *" />
            )}
            {isChaudiereSelected(values.nature) && (
              <>
                <WrappedChoiceSelect
                  name="typeFoyer"
                  label="Type de foyer pour les chaudières utilisant un combustible solide"
                  isMulti={false}
                  commonProps={commonProps}
                  options={arrayTypeFoyer}
                  computeLabel={typeFoyer => arrayTypeFoyerLabel[typeFoyer]}
                />
                {autreSubPartTypeFoyerActivated(values.typeFoyer) && (
                  <TextField name="foyerAutre" label="Précision du foyer *" />
                )}
              </>
            )}

            <NumberField name="capacite" label="Capacité autorisée *" unit="" />
            <TextField name="unite" label="Unité *" />
            <NumberField
              name="hauteurRejets"
              label="Hauteur des rejets *"
              unit="m"
            />
          </>
        );
      }}
    />
  );
};

export default FormAppareil;
