import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  BACKGROUND_GREY,
  BACKGROUND_PINK,
  BUTTON_DARK_GREY,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BUTTON_FONT_SIZE,
  DEFAULT_BUTTON_PADDING,
  DEFAULT_BUTTON_SHADOW,
  PINK,
  PINK_HOVER,
} from "theme";
import classNames from "classnames";
import { ButtonProps } from "./type";
import Spinner from "../presentational/Spinner";

const useStyles = makeStyles({
  button: {
    color: "white",
    backgroundColor: PINK,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: DEFAULT_BUTTON_PADDING,
    paddingRight: DEFAULT_BUTTON_PADDING,
    fontSize: DEFAULT_BUTTON_FONT_SIZE,
    borderRadius: DEFAULT_BORDER_RADIUS,
    border: 0,
    cursor: "pointer",
    outline: "none",
  },
  rightAligned: {},
  disabled: {
    borderColor: BACKGROUND_GREY,
    cursor: "not-allowed",
    backgroundColor: BACKGROUND_GREY,
    color: BUTTON_DARK_GREY,
  },
  active: {
    boxShadow: DEFAULT_BUTTON_SHADOW,
    "&:hover": {
      backgroundColor: PINK_HOVER,
    },
  },
  reversedButton: {
    color: PINK,
    backgroundColor: "white",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: BACKGROUND_PINK,
    },
  },
  reversedDisabled: {
    cursor: "not-allowed",
    color: BUTTON_DARK_GREY,
    "&:hover": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const Button = ({
  text,
  isDisabled,
  onClick,
  additionalStyle,
  additionalClassname,
  rightAlignedElem,
  type = "button",
  isReversed,
  isSpinnerVisible = false,
}: ButtonProps): React.ReactElement => {
  const classes = useStyles();

  const buttonClassName = classNames(
    classes.button,
    {
      [classes.reversedButton]: isReversed,
      [classes.reversedDisabled]: isReversed && isDisabled,
      [classes.disabled]: isDisabled,
      [classes.active]: !isDisabled,
    },
    additionalClassname
  );

  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [width, setWidth] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (width) {
      return;
    }

    if (buttonRef.current) {
      setWidth(buttonRef.current.clientWidth + 1);
    } else {
      setWidth(null);
    }
  }, [width, setWidth]);

  const style: CSSProperties = {
    ...additionalStyle,
  };

  return (
    <button
      ref={buttonRef}
      style={width ? { ...style, width } : style}
      className={buttonClassName}
      onClick={e => {
        if (!isDisabled && onClick) {
          onClick(e);
        }
      }}
      disabled={isDisabled || isSpinnerVisible}
      type={type}
    >
      {isSpinnerVisible && width ? (
        <Spinner width={width} height={20} />
      ) : (
        <>
          <span>{text}</span>
          <span className={classes.rightAligned}>{rightAlignedElem}</span>
        </>
      )}
    </button>
  );
};

export default Button;
