import React from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import backImg from "./images/reply-solid.svg";
import mailImg from "./images/mail.svg";
import CircularButton from "./CircularButton";
import ImgButton from "./ImgButton";
import * as H from "history";
import { Link } from "react-router-dom";
import { useDeclarationPageHeaderInfo } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";

const useStyles = makeStyles({
  buttonContainer: {
    display: "flex",
    top: 0,
    left: 0,
    height: "100%",
  },
  roundButtonContainer: {
    borderRadius: "50%",
    margin: "0 15px",
  },
  menuContainer: {
    display: "flex",
    outline: 0,
    alignItems: "flex-end",
  },
  customImg: {
    height: "50%",
    width: "50%",
    margin: "25% 25%",
  },
  establishmentName: {
    marginLeft: "auto",
    fontWeight: "bold",
  },
});

interface HeaderButtonsProps {
  additionalClassname?: string;
  onMessage?: () => void;
  returnLink?: H.LocationDescriptor;
}

const HeaderButtons = ({
  additionalClassname,
  returnLink,
  onMessage,
}: HeaderButtonsProps): React.ReactElement => {
  const classes = useStyles();
  const headerInfo = useDeclarationPageHeaderInfo();

  return (
    <div className={classNames(classes.menuContainer, additionalClassname)}>
      {returnLink && (
        <Link
          className={classNames(
            classes.buttonContainer,
            classes.roundButtonContainer
          )}
          to={returnLink}
        >
          <CircularButton additionalClassname={classes.menuContainer}>
            <div className={classes.customImg}>
              <img
                src={backImg}
                alt="Flèche vers l'arrière"
                height="100%"
                width="100%"
              />
            </div>
          </CircularButton>
        </Link>
      )}
      {onMessage && (
        <div className={classes.buttonContainer}>
          <ImgButton
            onClick={onMessage}
            additionalClassname={classes.menuContainer}
          >
            <img
              src={mailImg}
              alt="Enveloppe fermée"
              height="100%"
              width="100%"
            />
          </ImgButton>
        </div>
      )}

      {headerInfo && (
        <div className={classes.establishmentName}>
          {headerInfo.etablissementTitle} -{" "}
          {headerInfo.declarationEtablissement}
        </div>
      )}
    </div>
  );
};
export default HeaderButtons;
