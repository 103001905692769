import React from "react";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import {
  useBooleanCheckBoxGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { WasteData } from "../types";
import { computeRefMethodForMethod } from "../utils/Referentiels";
import { ProductedWasteStorage } from "../production/types";
import {
  DechetProduitDto2022,
  DechetProduitDto2022LieuOperationEnum,
  DechetProduitDto2022MethodReferenceEnum,
  ReferenceItemDechetDto,
  ReferenceItemDepartementDto,
  ReferenceItemPaysDto,
} from "api/gen";
import {
  generateErrorsInModal,
  isCountryAccessibleForProduction,
  isDepartementAccessibleForProduction,
  isNotificationNumberAccessibleForProduction,
  isReceptionFacilityAccessibleForProduction,
  isSortiDuStatusDeDechetAccessibleForProduction,
  isTreatmentFacilityAccessibleForProduction,
} from "../production/submitHandler";
import {
  displayCodeDechet,
  displayDepartement,
  displayMethod,
  displayOperation,
  displayOperationLocation,
  displayPays,
  formatDepartementToModalLine,
} from "../utils/formater";
import {
  convertModalToStoredData,
  convertProductionStoredToModalData,
} from "./converter";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";
import { methodsMessage } from "common/infoBulle/messages";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { removeFrenchPostalCodeWhitespace } from "../utils/utils";
import { validationProductionSchema } from "./validation";

const useStyles = makeStyles({
  headertitle5: {
    color: "#6b6b6b",
    textTransform: "uppercase",
  },
  contentJustified: {
    display: "flex",
    justifyContent: "space-between",
  },
});

interface WasteModaleProps {
  wasteData: WasteData;
  isOpen: boolean;
  updatingWaste: ProductedWasteStorage | null;
  close: () => void;
  validate: (waste: DechetProduitDto2022) => void;
  isOnSiteOptionAvailable: boolean;
}

export const DeclareWasteModale = ({
  wasteData,
  isOpen,
  updatingWaste,
  close,
  validate,
  isOnSiteOptionAvailable,
}: WasteModaleProps): React.ReactElement => {
  const classes = useStyles();

  const initialValues = convertProductionStoredToModalData(
    updatingWaste ? updatingWaste.data : null,
    wasteData.referentiels
  );

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "modale-production-déchets",
  };
  const textInputProps = {
    ...commonProps,
    disabled: true,
  };

  const BooleanCheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const ConnectedTextInput = useTextFieldGenerator(commonProps);
  const TextInput = useDummyTextFieldGenerator(textInputProps);

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale formulaire ajout polluant"
      onRequestClose={close}
    >
      <DeprecatedCommonFormSingleEntity
        title={"AJOUTER UN DÉCHET"}
        closeFunction={close}
        onSubmit={values => {
          const toAddOrUpdate = convertModalToStoredData(values, updatingWaste);
          validate(toAddOrUpdate);
        }}
        initialEntity={initialValues}
        validationSchema={validationProductionSchema}
        validate={values =>
          generateErrorsInModal(values, isOnSiteOptionAvailable)
        }
        renderField={({ values, setFieldValue }) => {
          const methodRef = computeRefMethodForMethod(values.method);

          const handleOnChangeFrenchReceptionPostalCode = (
            postalCode: string | null
          ) => {
            const isReceptionPostalCodeFrench =
              !!values.isFirstReceptionFrance ||
              (!!values.location &&
                values.location.backCode ===
                  DechetProduitDto2022LieuOperationEnum.DEPARTEMENT);
            setFieldValue(
              "receptionPostalCode",
              removeFrenchPostalCodeWhitespace(
                postalCode,
                isReceptionPostalCodeFrench
              )
            );
          };

          return (
            <>
              <WrappedChoiceSelectModale
                label={"Déchet *"}
                name={"wasteId"}
                header={["Code déchet", "Libellé", "Dangereux"]}
                linesData={wasteData.referentiels.polluants}
                title={"DÉCHETS"}
                formatLine={polluant => [
                  polluant.codeDechet,
                  polluant.libelle,
                  polluant.dangereux ? "Dangereux" : "Non dangereux",
                ]}
                formatSelectedTitle={(
                  lineData: ReferenceItemDechetDto | string | null
                ) => displayCodeDechet(lineData)}
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [lineData.codeDechet, lineData.libelle],
                    searchedStr
                  )
                }
                isFirstSticky={false}
                commonProps={commonProps}
              />

              {typeof values.wasteId === "object" &&
                values.wasteId !== null && (
                  <TextInput
                    label="Dénomination"
                    value={values.wasteId.libelle}
                    name="wasteReadableName"
                  />
                )}

              <NumberField
                label="Quantité de déchets produite *"
                name="wasteAmount"
                unit="t/an"
                additionalClassName={classes.contentJustified}
                disabled={false}
              />

              <WrappedChoiceSelect
                name="method"
                label="Méthode utilisée *"
                isMulti={false}
                options={wasteData.referentiels.methods}
                computeLabel={displayMethod}
                commonProps={commonProps}
              />

              {methodRef.length > 0 && (
                <WrappedChoiceSelect
                  name={"methorRef"}
                  label={"Référence de la méthode *"}
                  isMulti={false}
                  options={methodRef}
                  computeLabel={(
                    lineData: DechetProduitDto2022MethodReferenceEnum | null
                  ) => (lineData ? lineData.toString() : "")}
                  tooltipContent={methodsMessage}
                  commonProps={commonProps}
                />
              )}

              <ConnectedTextInput
                name="description"
                label="Description/désignation"
                placeholder="Mesure par nos services de..."
              />

              <WrappedChoiceSelect
                name="operation"
                label="Première opération d'élimination ou de valorisation *"
                isMulti={false}
                options={
                  wasteData.referentiels.operationOrValorisationForProduction
                }
                computeLabel={displayOperation}
                commonProps={commonProps}
              />

              <WrappedChoiceSelect
                name="location"
                label="Lieu de l'opération de traitement final *"
                isMulti={false}
                options={wasteData.referentiels.productionLocation}
                computeLabel={displayOperationLocation}
                commonProps={commonProps}
              />

              {isSortiDuStatusDeDechetAccessibleForProduction(values) && (
                <BooleanCheckboxField
                  name="isNoLongerWaste"
                  label="Sortie du statut de déchet (SSD)"
                  tooltipContent="Le déchet a bénéficié de la procédure SSD (statut sortie de déchet). Se référer aux arrêtés ministériels fixant les critères de sortie du statut de déchet."
                />
              )}

              {isDepartementAccessibleForProduction(values) && (
                <WrappedChoiceSelectModale
                  name={"departement"}
                  label={"Département *"}
                  title={""}
                  header={["Département"]}
                  linesData={wasteData.referentiels.departements}
                  formatLine={dep => [formatDepartementToModalLine(dep)]}
                  isFirstSticky={false}
                  formatSelectedTitle={(
                    lineData: ReferenceItemDepartementDto | string | null
                  ) => displayDepartement(lineData)}
                  isLineInSearch={(lineData, searchedStr) =>
                    isSearchStringInCollection(
                      [formatDepartementToModalLine(lineData)],
                      searchedStr
                    )
                  }
                  commonProps={commonProps}
                />
              )}

              {isCountryAccessibleForProduction(values) && (
                <>
                  <WrappedChoiceSelectModale
                    name={"pays"}
                    label={"Pays *"}
                    title={""}
                    header={["Pays"]}
                    linesData={wasteData.referentiels.pays}
                    formatLine={pays => [pays.designation]}
                    isFirstSticky={false}
                    formatSelectedTitle={(
                      lineData: ReferenceItemPaysDto | string | null
                    ) => displayPays(lineData)}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection(
                        [lineData.designation],
                        searchedStr
                      )
                    }
                    commonProps={commonProps}
                  />
                  <BooleanCheckboxField
                    label="Le premier établissement réceptionnant le déchet est situé en France"
                    name="isFirstReceptionFrance"
                  />
                </>
              )}

              {isReceptionFacilityAccessibleForProduction(values) && (
                <>
                  <ConnectedTextInput
                    label="Nom du premier établissement réceptionnant le déchet *"
                    name="receptionName"
                  />
                  <ConnectedTextInput
                    label="Adresse du premier établissement réceptionnant le déchet *"
                    name="receptionAdress"
                  />
                  <ConnectedTextInput
                    label="Code postal du premier établissement réceptionnant le déchet *"
                    name="receptionPostalCode"
                    additionalOnChange={postalCode =>
                      handleOnChangeFrenchReceptionPostalCode(postalCode)
                    }
                  />
                  <ConnectedTextInput
                    label="Commune du premier établissement réceptionnant le déchet *"
                    name="receptionCommune"
                  />
                </>
              )}

              {isTreatmentFacilityAccessibleForProduction(values) && (
                <>
                  <ConnectedTextInput
                    label="Nom de l'établissement assurant l'opération de traitement final *"
                    name="treatmentName"
                  />
                  <ConnectedTextInput
                    label="Adresse de l'établissement assurant l'opération de traitement final *"
                    name="treatmentAdress"
                  />
                  <ConnectedTextInput
                    label="Code postal de l'établissement assurant l'opération de traitement final *"
                    name="treatmentPostalCode"
                  />
                  <ConnectedTextInput
                    label="Commune de l'établissement assurant l'opération de traitement final *"
                    name="treatmentCommune"
                  />
                </>
              )}

              {isNotificationNumberAccessibleForProduction(values) && (
                <ConnectedTextInput
                  label="Numéro de notification"
                  name="notification"
                />
              )}
            </>
          );
        }}
      />
    </CustomModal>
  );
};
