import {
  PATH_QUOTAS_ALC_VERIFIE,
  PATH_QUOTAS_ALLOCATIONS_EMAILS,
  PATH_QUOTAS_ALLOCATIONS_PMS,
  PATH_QUOTAS_BILAN_BLOC,
  PATH_QUOTAS_EMISSIONS_BLOC,
  PATH_QUOTAS_EMISSIONS_EMAILS,
  PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC,
} from "common/path/path20Now";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";
import { isFullDeclarationActive } from "pages/CompanySpace/from19/to19/Quotas/utils/utils";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { Declaration21Now } from "../../../toNow/versionedElements/declarationHooks21Now";

export const areAllAllocationsBlocsValidated = (
  isPathValidatedInDeclaration: (path: string) => boolean
): boolean =>
  isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_PMS) &&
  isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_EMAILS) &&
  isPathValidatedInDeclaration(PATH_QUOTAS_ALC_VERIFIE);

export const areAllEmissionsBlocsValidated = (
  isPathValidatedInDeclaration: (path: string) => boolean,
  isFullForm: boolean
): boolean => {
  const isBlocPlanDeSurveillanceValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC
  );
  const isBlocEmissionsValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_EMISSIONS_BLOC
  );
  const isBlocBilanTotalValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_BILAN_BLOC
  );
  const isBlocEmailsValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_EMISSIONS_EMAILS
  );
  return isFullForm
    ? isBlocPlanDeSurveillanceValidated &&
        isBlocEmissionsValidated &&
        isBlocEmailsValidated
    : isBlocBilanTotalValidated;
};

export function areAllQuotaTargetBlocsValidated(
  workflowTarget: QuotaWorkflowTarget,
  declaration: Declaration21Now,
  isPathValidatedInDeclaration: (path: string) => boolean
): boolean {
  const typeActiviteQuota = declaration.body.typeActivite.quotas;
  const isFullForm = isFullDeclarationActive(typeActiviteQuota);

  switch (workflowTarget) {
    case WorkflowTarget.QUOTA_ALLOCATIONS:
      return areAllAllocationsBlocsValidated(isPathValidatedInDeclaration);

    case WorkflowTarget.QUOTA_EMISSIONS:
      return areAllEmissionsBlocsValidated(
        isPathValidatedInDeclaration,
        isFullForm
      );

    default:
      throw new ShouldNotHappen(workflowTarget);
  }
}
