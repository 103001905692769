import React, { ReactChild, ReactNode } from "react";
import {
  DASHBOARD_DISABLED_FONT_GREY,
  DEFAULT_LINE_HEIGHT,
  FIELD_FONT_SIZE,
  FIELD_FONT_WEIGHT,
  LABEL_RIGHT_MARGIN,
  LEFT_FIELD_LABEL_WIDTH,
  SECTION_TITLE_GREY,
} from "theme";
import { makeStyles } from "@material-ui/styles";
import InfoBulle from "common/infoBulle/InfoBulle";

// style to give the label the right color, size, etc., and align it on the left as wanted
const useStyles = makeStyles({
  label: props => ({
    color: props.disabled ? DASHBOARD_DISABLED_FONT_GREY : SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "right",
  }),

  rightAligned: {
    width: LEFT_FIELD_LABEL_WIDTH,
    marginRight: LABEL_RIGHT_MARGIN,
    marginTop: "0.2em",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: DEFAULT_LINE_HEIGHT,
    textAlign: "right",
  },

  tooltip: {
    display: "inline-block",
  },

  container: {
    display: "inline-block",
  },
});

//props awaited in a BasicLabel component
interface BasicLabelProps {
  content: string | ((classname: string) => ReactChild);
  htmlFor: string;
  width?: string;
  disabled?: boolean;
  hideIfEmpty?: boolean;
  tooltipContent?: ReactNode;
}

/**
 * a basic component to display a label
 * @param content the string for the label OR a method taking the className for the label and returning the label to display
 * @param width the width for the label
 * @param htmlFor the htmlFor key on the label element
 * @param disabled whether or not the label should have a disabled style
 * @param hideIfEmpty when this param is passed and is equal to true, then we don't display the label should it be empty
 * @param tooltipContent the content of the tooltip
 * @constructor
 */
const BasicLabel = ({
  content,
  width,
  htmlFor,
  disabled,
  hideIfEmpty,
  tooltipContent,
}: BasicLabelProps): React.ReactElement => {
  const classes = useStyles({ disabled: disabled });

  const contentToDisplay: ReactChild =
    typeof content === "function" ? content(classes.label) : content;

  if (contentToDisplay === "" && hideIfEmpty) {
    return <></>;
  }

  return (
    //todo find a way we keep the label to its size even if error message is extremely long.
    <div
      className={classes.rightAligned}
      // We need to add min-width because, when field try to take too much space (Select with isMulti and large string),
      // label effective width may become smaller than requested width
      style={{ width: width, minWidth: width }}
    >
      <div className={classes.container}>
        {tooltipContent && (
          <InfoBulle
            content={tooltipContent}
            additionalClassName={classes.tooltip}
          />
        )}
        <label htmlFor={htmlFor} className={classes.label}>
          {contentToDisplay}
        </label>
      </div>
    </div>
  );
};

export default BasicLabel;
