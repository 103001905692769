import { InArray, InModale } from "common/form/utils";
import { MethodEnum } from "./selectPossibleValues";
import {
  AirFugitivesEmissionBilanIntrantsDto20Now,
  AirFugitivesEmissionBilanSortantsDto20Now,
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesEmissionMesureConcentration,
  AirFugitivesEmissionMesureDebit,
  AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum,
  AirFugitivesEmissionMesureDto24NowMesureTypeEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 *
 * when suppressing, be careful, the export is because this type is used in a method. most likely it will have to be modified to fit the type from back, in the case it isn't exported.
 *
 * TODO to suppress properly : https://dl.polyconseil.fr/jira/browse/GEREP-1010
 */
interface LegacyEmissions {
  methods: MethodEnum;
  epuration: boolean;
  natureEquipement: string;
  rendement: number;
  biomasse: number;
  substance: ReferenceItemPolluantDto;
  procedes: string[];
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMesureEmission extends LegacyEmissions {
  debitHoraire: number;
  debit: AirFugitivesEmissionMesureDebit;
  heures: number;
  concentrationMoy: number;
  concentrationMoyUnite: AirFugitivesEmissionMesureDto24NowConcentrationMoyUniteEnum;
  concentration: AirFugitivesEmissionMesureConcentration;
  typeMesure: AirFugitivesEmissionMesureDto24NowMesureTypeEnum;
}

export type MesureEmissionInArray = InArray<LegacyMesureEmission>;
export type MesureEmissionInModale = InModale<LegacyMesureEmission>;

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyCorrelationEmission extends LegacyEmissions {
  nature: string;
  facteur: number;
  unite: AirFugitivesEmissionCorrelationDtoUniteEnum;
  provenanceFacteurCorrelation: string;
}

export type CorrelationEmissionInArray = InArray<LegacyCorrelationEmission>;
export type CorrelationEmissionInModale = InModale<LegacyCorrelationEmission>;

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMatiereEmission extends LegacyEmissions {
  elementProps: ReferenceItemPolluantElementDto;
  elementName: string;
  part: number;
  intrants: Array<AirFugitivesEmissionBilanIntrantsDto20Now>;
  sortants: Array<AirFugitivesEmissionBilanSortantsDto20Now>;
}

export type MatiereEmissionInArray = InArray<LegacyMatiereEmission>;
export type MatiereEmissionInModale = InModale<LegacyMatiereEmission>;

export type EmissionsInModale = MesureEmissionInModale &
  CorrelationEmissionInModale &
  MatiereEmissionInModale;

export type EmissionsInArray =
  | MesureEmissionInArray
  | CorrelationEmissionInArray
  | MatiereEmissionInArray;

export const emissionFieldMatcher = fieldMatcherBuilder<
  EmissionsInModale
>().build({
  substance: "substance",
  methods: "methods",
  procedes: "procedes",
  debitHoraire: "debitHoraire",
  heureFonctionnement: "heures",
  debit: "debit",
  concentrationMoyenne: "concentrationMoy",
  concentrationMoyenneUnit: "concentrationMoyUnite",
  concentration: "concentration",
  natureCorrelation: "nature",
  facteurCorrelation: "facteur",
  uniteCorrelation: "unite",
  provenanceFacteurCorrelation: "provenanceFacteurCorrelation",
  elementName: "elementName",
  elementProps: "elementProps",
  partElement: "part",
  intrants: "intrants",
  sortants: "sortants",
  biomasse: "biomasse",
  epuration: "epuration",
  natureEquipement: "natureEquipement",
  rendementEpuration: "rendement",
  typeMesure: "typeMesure",
});

export const intrantFieldMatcher = fieldMatcherBuilder<
  AirFugitivesEmissionBilanIntrantsDto20Now
>().build({
  descriptif: "descriptif",
  quantite: "quantite",
  teneurMoyenne: "teneurMoyenne",
});

export const sortantFieldMatcher = fieldMatcherBuilder<
  AirFugitivesEmissionBilanSortantsDto20Now
>().build({
  descriptif: "descriptif",
  quantite: "quantite",
  teneurMoyenne: "teneurMoyenne",
});

export const concentrationFieldMatcher = fieldMatcherBuilder<
  AirFugitivesEmissionMesureConcentration
>().build({
  mesureContinueConcentration: "continu",
  frequenceMesureConcentration: "frequence",
});

export const debitFieldMatcher = fieldMatcherBuilder<
  AirFugitivesEmissionMesureDebit
>().build({
  mesureContinueDebit: "continu",
  frequenceMesureDebit: "frequence",
});
