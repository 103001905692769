import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DeclarationConstraintViolationDto } from "api/gen";
import { useDeclaration1919 } from "../declarationHooks1919";
import { ErrorsAndWarningsHandlers } from "../../../../DeclarationApiContext/utils/types";
import { findErrorsOrWarningsForPath } from "../../../toNow/versionedElements/errorAndWarningDisplayer19Now/utils";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";

/**
 *
 * use this to find errors and warning from declaration Dto and store in state
 * additionaly errors and warnings are only displayed when we tried to validate
 *
 * @param path                  path to find errors and warning
 * @param isStrict              whether errors and warning path must match stricly path
 * @param isInitiallyTriggered  are warnings/errors displayed during the first render
 *
 * @returns an array:
 *          - first element, all warnings in declaration for the path
 *          - second element, a boolean indicating whether some warnings requiered
 *          comment and are not commented yet
 *          - third element, all errors in declaration for the path
 *          - fourth element, a boolean indicating whether we currently have errors (not very usefull ok)
 *          - fifth element, a callback to trigger validation, as soon as call once, errors and warnings will
 *          be displayed
 */
export const useDeclarationErrorAndWarnings1919 = (
  path: string,
  isStrict: boolean = false,
  isInitiallyTriggered: boolean = false
): ErrorsAndWarningsHandlers<DeclarationConstraintViolationDto> => {
  const isMounted = useRef<boolean>(false);
  const declaration = useDeclaration1919();
  const commentStore = useComments();

  const [hasValidateOnce, setHasValidateOnce] = useState(isInitiallyTriggered);

  const errors = useMemo(
    () =>
      findErrorsOrWarningsForPath(declaration.computed.errors, path, isStrict),
    [declaration.computed.errors, isStrict, path]
  );
  const warnings = useMemo(
    () =>
      findErrorsOrWarningsForPath(
        declaration.computed.warnings,
        path,
        isStrict
      ),
    [declaration.computed.warnings, isStrict, path]
  );

  const hasError = useMemo(() => errors.length > 0, [errors]);
  const hasMissingCommentsOnRequiredWarnings = useMemo(
    () =>
      warnings.some(
        warning =>
          warning.commentRequired &&
          !commentStore.comments.some(comment => comment.path === warning.path)
      ),
    [warnings, commentStore.comments]
  );
  const triggerValidation = useCallback(
    async (promess: Promise<void>) => {
      await promess;

      if (!isMounted.current) {
        return;
      }
      setHasValidateOnce(true);
    },
    [setHasValidateOnce, isMounted]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return useMemo(() => {
    if (!hasValidateOnce) {
      return {
        warnings: [],
        hasUncommentedWarning: false,
        errors: [],
        hasError: false,
        triggerErrorAndWarningDisplay: triggerValidation,
      };
    } else {
      return {
        warnings,
        hasUncommentedWarning: hasMissingCommentsOnRequiredWarnings,
        errors,
        hasError,
        triggerErrorAndWarningDisplay: triggerValidation,
      };
    }
  }, [
    hasValidateOnce,
    warnings,
    hasMissingCommentsOnRequiredWarnings,
    errors,
    hasError,
    triggerValidation,
  ]);
};
