import * as Yup from "yup";
import {
  commonBooleanFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import { InertWasteForm } from "./types";
import { environnementAtLeastOneDechetInerteMessage } from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  inertWasteBring: commonBooleanFields,
  remainingCapacityEndRefYear: Yup.number().when("inertWasteBring", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  groundAndStoneToBackfill: Yup.number().when("inertWasteBring", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
  groundAndStoneToRecycle: Yup.number().when("inertWasteBring", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
  otherStuffToBackfill: Yup.number().when("inertWasteBring", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
  otherStuffToRecycle: Yup.number().when("inertWasteBring", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
});

export const preventSubmitWithErrors = (values: InertWasteForm) => {
  if (
    !values.groundAndStoneToBackfill &&
    !values.groundAndStoneToRecycle &&
    !values.otherStuffToBackfill &&
    !values.otherStuffToRecycle &&
    values.inertWasteBring
  ) {
    return {
      groundAndStoneToBackfill: "",
      groundAndStoneToRecycle: "",
      otherStuffToBackfill: "",
      otherStuffToRecycle: environnementAtLeastOneDechetInerteMessage,
    };
  }
  return {};
};
