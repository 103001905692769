import Button from "common/button";
import TransportProductArray from "./TransportProductArray";
import TransportProductModal from "./TransportProductModal";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { PopulatedTransportProductInArray } from "./utils/types";
import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferencePaysDto,
} from "api/gen";

const useStyles = makeStyles({
  buttonModal: {
    padding: "5px",
    marginLeft: "80%",
    marginTop: "20px",
  },
});

interface SectionListTransportsProduitsProps {
  transportProductsInArray: PopulatedTransportProductInArray[];
  setTransportProductsInArray: (
    products: PopulatedTransportProductInArray[]
  ) => void;
  referentialCountries: ReferencePaysDto;
  referentialDepartements: ReferenceDepartementDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
  shouldDisableFields: boolean;
}

const SectionListTransportsProduits = ({
  transportProductsInArray,
  setTransportProductsInArray,
  referentialCountries,
  referentialDepartements,
  referentialFamilies,
  referentialFamiliesCodeProduction,
  shouldDisableFields,
}: SectionListTransportsProduitsProps): React.ReactElement => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [
    transportProductInModal,
    setTransportProductInModal,
  ] = useState<PopulatedTransportProductInArray | null>(null);

  return (
    <>
      <Button
        text="AJOUTER UN PRODUIT"
        additionalClassname={classes.buttonModal}
        isDisabled={shouldDisableFields}
        onClick={() => setIsModalOpen(true)}
      />

      <TransportProductArray
        transportProductsInArray={transportProductsInArray}
        setTransportProductsInArray={setTransportProductsInArray}
        onEdit={product => {
          setTransportProductInModal(product);
          setIsModalOpen(true);
        }}
        isDisabled={shouldDisableFields}
      />

      <TransportProductModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        transportProductInModal={transportProductInModal}
        setTransportProductInModal={setTransportProductInModal}
        transportProductsInArray={transportProductsInArray}
        setTransportProductsInArray={setTransportProductsInArray}
        referentialCountries={referentialCountries}
        referentialDepartements={referentialDepartements}
        referentialFamilies={referentialFamilies}
        referentialFamiliesCodeProduction={referentialFamiliesCodeProduction}
      />
    </>
  );
};

export default SectionListTransportsProduits;
