import React from "react";
import {
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDtoUniteUsuelleEnum,
} from "api/gen";
import { SyntheseInArray, SyntheseInModale } from "./types";
import { getValueOrZero } from "common/utils/numberUtils";
import {
  CINTSubPartActivated,
  CSubPartActivated,
  MINTSubPartActivated,
  MNROSubPartActivated,
  MPERSubPartActivated,
  MSubPartActivated,
} from "../validation/validation";

export const autreString = "autre";
export const biomasseString = " biomasse";
export const nonBiomasseString = ` non${biomasseString}`;

export const concatLibelleNorme = (norm: ReferenceItemNormeAirDto): string => {
  return `${norm.libelleNorme}${
    norm.descriptionNorme ? ` - ${norm.descriptionNorme}` : ""
  }`;
};

const createNormDiv = (norms: ReferenceItemNormeAirDto[]) => {
  const normeString = norms.map(norm => concatLibelleNorme(norm)).join(", ");
  return <div title={normeString}>{normeString}</div>;
};

export const computeNorms = (
  synthese: SyntheseInArray
): React.ReactElement | "" => {
  if (
    MINTSubPartActivated(synthese.data.referenceMesure) &&
    synthese.data.normMINT
  ) {
    return createNormDiv(synthese.data.normMINT);
  } else if (
    MPERSubPartActivated(synthese.data.referenceMesure) &&
    synthese.data.normMPER
  ) {
    return createNormDiv(synthese.data.normMPER);
  } else if (
    MNROSubPartActivated(synthese.data.referenceMesure) &&
    synthese.data.normMNRO
  ) {
    return createNormDiv(synthese.data.normMNRO);
  } else if (
    CINTSubPartActivated(synthese.data.referenceCalcule) &&
    synthese.data.normCINT
  ) {
    return createNormDiv(synthese.data.normCINT);
  } else {
    return "";
  }
};

export const computeMethodReference = (synthese: SyntheseInArray): string => {
  if (
    MSubPartActivated(synthese.data.methode) &&
    synthese.data.referenceMesure
  ) {
    return synthese.data.referenceMesure.toString();
  } else if (
    CSubPartActivated(synthese.data.methode) &&
    synthese.data.referenceCalcule
  ) {
    return synthese.data.referenceCalcule.toString();
  } else {
    return "";
  }
};

const getConversionFactor = (
  unit: ReferenceItemPolluantDtoUniteUsuelleEnum
): number => {
  switch (unit) {
    case ReferenceItemPolluantDtoUniteUsuelleEnum.G_AN:
      return 1000;
    case ReferenceItemPolluantDtoUniteUsuelleEnum.KG_AN:
      return 1;
    case ReferenceItemPolluantDtoUniteUsuelleEnum.T_AN:
      return 0.001;
    case ReferenceItemPolluantDtoUniteUsuelleEnum.KT_AN:
      return 0.000001;
    case ReferenceItemPolluantDtoUniteUsuelleEnum.MG_AN:
      return 1000000;
    default:
      return 1;
  }
};

export const computeTotalEmissions = (synthese: SyntheseInModale): number => {
  return (
    getValueOrZero(synthese.additionnel) +
    getValueOrZero(synthese.emissionsAlreadyDeclared)
  );
};

export const computeTotalEmissionsUsualUnit = (
  synthese: SyntheseInModale
): number | null => {
  //we recompute the totales emission because in a form, the totalesEmission may have changed.
  const totalesEmissions = computeTotalEmissions(synthese);
  const factor =
    synthese.substance !== null
      ? getConversionFactor(synthese.substance.uniteUsuelle)
      : null;

  if (factor === null) {
    return null;
  }
  return factor * totalesEmissions;
};

export const compareFunction = (
  a: SyntheseInArray,
  b: SyntheseInArray
): number => {
  if (
    a.data.substance &&
    b.data.substance &&
    a.data.substance.nom !== b.data.substance.nom
  ) {
    return a.data.substance.nom < b.data.substance.nom ? -1 : 1;
  }
  return 0;
};
