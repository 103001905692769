import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DeclarationStateDtoStateEnum,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { FileSectionEnum } from "common/FilesLoader/types";
import {
  useBooleanCheckBoxGenerator,
  useChoiceSelectFieldGenerator,
  useDateFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import FormikBlocInUserContext from "common/formikBloc/FormikBlocInUserContext";
import { useFormikBloc } from "common/formikBloc/utils";
import { useAlertModale, useConfirmationModale } from "common/modale/hooks";
import { PATH_QUOTAS_VERIFICATION_BLOC } from "common/path/path1819";
import Row from "common/presentational/Row";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { WorkflowTarget } from "common/utils/types";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import { FILE_BUTTONS_STYLE, LIGHT_PINK } from "theme";
import {
  useDeclaration1919,
  useDeclarationStateHandlers1919,
} from "../../versionedElements/declarationHooks1919";
import { installationsInArrayToNimOptionProps } from "../blocEmissions/converter";
import { dtoToInstallations } from "../blocInstallations/converter";
import { InstallationSelectOptions } from "../blocInstallations/types";
import {
  VERIFICATION_BLOC_NOT_VERIFIED_YET,
  VERIFICATION_BLOC_PENDING_VERIFICATION,
  VERIFICATION_BLOC_VERIFIED,
  VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION,
  VERIFIED_ALERT_MESSAGE,
} from "../helpers";
import { blocValuesToDto, dtoToBlocValues } from "./converter";
import { useVerificationErrors } from "./hook";
import { selectPossibleValues } from "./selectPossibleValues";
import { BlocVerificationFormValues } from "./types";
import { additionalFieldValidation, validationSchema } from "./validation";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useQuotasRapportVerificationFileHandler1919 } from "../../versionedElements/filesLoaderDisplayer1919/sectionFilesHooks1919";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

export interface BlocVerificationProps {
  possibleValues: InstallationSelectOptions;
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
  disabledMessage: {
    textAlign: "center",
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: "bold",
    fontSize: "22px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  tooltipLink: {
    color: LIGHT_PINK,
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "22px",
    marginBottom: "12px",
  },
});

enum BlocVerifState {
  IN_PROGRESS,
  COMPLETED,
  IGNORED,
  UNKNOWN,
}

const getInaccessibleVerificationMessage = (
  blocVerifStatus: BlocVerifState
): string => {
  switch (blocVerifStatus) {
    case BlocVerifState.IGNORED:
      return VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION;
    case BlocVerifState.COMPLETED:
      return VERIFICATION_BLOC_VERIFIED;
    case BlocVerifState.IN_PROGRESS:
      return VERIFICATION_BLOC_PENDING_VERIFICATION;
    default:
      return VERIFICATION_BLOC_NOT_VERIFIED_YET;
  }
};

const BlocVerification = ({
  possibleValues,
  referentialActivites,
}: BlocVerificationProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();
  const { isAnyPrestataire } = useUserData();
  const formProps = useFormikBloc<BlocVerificationFormValues>();
  const { updateQuotaVerif, submitVerif } = useDeclarationStateHandlers1919();
  const openAlertModale = useAlertModale();
  const {
    hasValidateOnce,
    triggerValidation,
    errors,
    hasError,
  } = useVerificationErrors();

  const [isSaveSpinnerAvailable, triggerSaveSpinnerVisible] = useSpinnerState<
    boolean
  >();
  const [
    isValidateSpinnerAvailable,
    triggerValidateSpinnerVisible,
  ] = useSpinnerState<void>();

  const openModale = useConfirmationModale();

  const rapportFilesHandler = useQuotasRapportVerificationFileHandler1919();
  const files = declaration.body.sections.quotas.blocVerification.rapports;

  // We are using data from DTO in this bloc because it is available to another profile
  // so we don't need to synchronize data from other blocs
  const installationsInArray = dtoToInstallations(
    declaration.body.sections.quotas.blocInstallations,
    possibleValues,
    referentialActivites
  );
  const nimOptions = installationsInArrayToNimOptionProps(installationsInArray);
  const initialValues = dtoToBlocValues(
    declaration.body.sections.quotas.blocVerification,
    installationsInArray
  );

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-quotas-verification",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const quotasState = declaration.body.workflowStatus.quotaEmissions.state;

  let blocVerifStatus: BlocVerifState;
  switch (quotasState) {
    case DeclarationStateDtoStateEnum.VERIFYING:
      blocVerifStatus = BlocVerifState.IN_PROGRESS;
      break;
    case DeclarationStateDtoStateEnum.NONE:
    case DeclarationStateDtoStateEnum.CREATED:
    case DeclarationStateDtoStateEnum.STARTED:
    case DeclarationStateDtoStateEnum.IN_REVIEW:
      blocVerifStatus = BlocVerifState.UNKNOWN;
      break;
    case DeclarationStateDtoStateEnum.FORCED:
      blocVerifStatus = BlocVerifState.IGNORED;
      break;
    case DeclarationStateDtoStateEnum.RECALLED:
    case DeclarationStateDtoStateEnum.SUBMITTED:
    case DeclarationStateDtoStateEnum.VALIDATED:
    case DeclarationStateDtoStateEnum.VERIFIED:
      blocVerifStatus = BlocVerifState.COMPLETED;
      break;
    default:
      throw new ShouldNotHappen(quotasState);
  }

  return (
    <FormikBlocInUserContext
      enableReinitialize={true}
      formikRef={formProps.formikRef}
      hasChanges={formProps.hasChanges}
      setHasChanges={formProps.setHasChanges}
      initialValues={initialValues}
      validationSchema={validationSchema}
      title="espace vérification"
      additionalFieldValidation={additionalFieldValidation}
      additionalValidationAllowed={() =>
        blocVerifStatus === BlocVerifState.IN_PROGRESS
      }
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      renderContent={({ values }, shouldDisabledFields) => (
        <>
          {!isAnyPrestataire && (
            <div className={classes.disabledMessage}>
              <span>{getInaccessibleVerificationMessage(blocVerifStatus)}</span>
            </div>
          )}

          <div>
            <GreyFilesDownloader
              text="TÉLÉCHARGER MODÈLE RAPPORT DE VÉRIFICATION"
              fileName="RapportVerificationEmissions.xls"
              additionalClassname={classes.fileButtons}
              isDisabled={shouldDisabledFields}
            />

            <FilesLoader
              declaration={declaration}
              text={
                "DÉPOSER RAPPORT DE VÉRIFICATION (RÉSERVÉ AU VÉRIFICATEUR, 1 PAR NIM)"
              }
              filesPath={"body.sections.quotas.blocVerification.rapports"}
              section={FileSectionEnum.QUOTAS_RAPPORT_VERIFICATION}
              fileHandlers={rapportFilesHandler}
              //
              max={installationsInArray.length}
              isDisabled={shouldDisabledFields}
              //
              additionalClassnameButton={classes.fileButtons}
              onReject={() =>
                openAlertModale("Le format du fichier uploadé est incorrect.")
              }
            />

            <FieldArray
              name="props"
              render={() =>
                values.props.map((propsValues, index) => {
                  return (
                    <Fragment key={index}>
                      <span className={classes.sectionTitle}>
                        NIM à remplir :{" "}
                        {index < nimOptions.length
                          ? nimOptions[index].label
                          : ""}
                        <CommentButton
                          path={`${PATH_QUOTAS_VERIFICATION_BLOC}/verifications/${propsValues.installationId}`}
                        />
                      </span>

                      <TextField
                        name={`props.${index}.organizationName`}
                        label="Nom de l'organisme vérificateur *"
                        disabled={shouldDisabledFields}
                      />

                      <TextField
                        name={`props.${index}.monitoringPlanReference`}
                        label="Référence du dernier plan de surveillance approuvé ayant servi à la vérification *"
                        disabled={shouldDisabledFields}
                      />

                      <CheckBox
                        name={`props.${index}.visitExemption`}
                        label="Dispense de visite de site"
                        disabled={shouldDisabledFields}
                      />

                      {propsValues && propsValues.visitExemption && (
                        <ChoiceSelect
                          name={`props.${index}.conditionExemption`}
                          label="Condition de la dispense *"
                          isMulti={false}
                          options={selectPossibleValues.exemptionConditions}
                          disabled={shouldDisabledFields}
                          tooltipContent={
                            <a
                              className={classes.tooltipLink}
                              href="https://ec.europa.eu/clima/sites/clima/files/ets/monitoring/docs/kgn_5_site_visits_en.pdf"
                            >
                              Guidance de la Commission sur les dispenses de
                              visite de site II.5
                            </a>
                          }
                        />
                      )}

                      <DateField
                        name={`props.${index}.lastVisitDate`}
                        label="Date de la dernière visite du site *"
                        disabled={shouldDisabledFields}
                      />

                      <CheckBox
                        name={`props.${index}.improvementReport`}
                        label="L’exploitant doit fournir un rapport d'amélioration au 30 juin à l'autorité compétente suite aux remarques du vérificateur"
                        disabled={shouldDisabledFields}
                      />

                      <ChoiceSelect
                        name={`props.${index}.verificationReportConclusion`}
                        label="Conclusion du rapport de vérification *"
                        isMulti={false}
                        options={
                          selectPossibleValues.verificationReportConclusion
                        }
                        disabled={shouldDisabledFields}
                      />

                      {propsValues.verificationReportConclusion &&
                        propsValues.verificationReportConclusion.object
                          .isSatisfyingWithRequests && (
                          <ChoiceSelect
                            name={`props.${index}.satisfyingDeclarationReason`}
                            label="Motifs de déclaration reconnue satisfaisante avec remarques *"
                            options={
                              selectPossibleValues.satisfyingDeclarationReasons
                            }
                            disabled={shouldDisabledFields}
                            isMulti
                          />
                        )}

                      {propsValues.verificationReportConclusion &&
                        propsValues.verificationReportConclusion.object
                          .isReportUnchecked && (
                          <ChoiceSelect
                            name={`props.${index}.uncheckedDeclarationReason`}
                            label="Motifs de déclaration non vérifiée *"
                            options={
                              selectPossibleValues.uncheckedDeclarationReasons
                            }
                            disabled={shouldDisabledFields}
                            isMulti
                          />
                        )}

                      {index < nimOptions.length - 1 && <Row height={"45px"} />}
                    </Fragment>
                  );
                })
              }
            />

            {hasError && hasValidateOnce && (
              <ViolationProcessor errors={errors} warnings={[]} />
            )}
          </div>
        </>
      )}
      // Bloc reserved for PRESTATAIRE profiles
      isAvailableToDeclarant={false}
      isAvailableToAnyPrestataire={true}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      declarationEtablissement={declaration.etablissementCode}
      // Special worflow action for this bloc, must define specific callbacks
      saveAction={values => {
        return triggerSaveSpinnerVisible(
          updateQuotaVerif(blocValuesToDto(values, files, installationsInArray))
        );
      }}
      isSaveSpinnerVisible={isSaveSpinnerAvailable}
      validateAction={values => {
        openModale(VERIFIED_ALERT_MESSAGE, () => {
          const validate = async () => {
            await updateQuotaVerif(
              blocValuesToDto(values, files, installationsInArray)
            );
            await submitVerif([WorkflowTarget.QUOTA_EMISSIONS]);
          };
          triggerValidateSpinnerVisible(triggerValidation(validate()));
        });
      }}
      isValidateSpinnerVisible={isValidateSpinnerAvailable}
      // Edit button should never be displayed. Instead, other button will be disabled when actions are no longer possible
      isBlocValidated={
        blocVerifStatus === BlocVerifState.COMPLETED ||
        blocVerifStatus === BlocVerifState.IGNORED
      }
      isEditSpinnerVisible={false}
    />
  );
};

export default BlocVerification;
