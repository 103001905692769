import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleInstallation from "./FormSingleInstallation";
import { addOrModifyArray } from "common/declarant/array/utils";
import cloneDeep from "lodash.clonedeep";
import { LegacySubInstallationElement } from "../utils/types";
import { InArray } from "common/form/utils";
import { InstallationInArray, InstallationInModale } from "./utils/types";
import { AppareilInArray } from "../listInstallation/blocAppareils/utils/types";
import { CombustibleInArray } from "../listInstallation/blocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../listInstallation/blocEmissions/utils/types";
import { isAppareilSubpartFormAvailable } from "../listInstallation/blocAppareils/utils/utils";

interface InstallationModaleProps {
  isOpen: boolean;
  installationInModal: InstallationInArray | null;
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  setInstallationInModale: Dispatch<SetStateAction<InstallationInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  isLcp: boolean;
  isWicowi: boolean;
  installationsInPage: InstallationInArray[];
  appareilsInPage: AppareilInArray[];
  setAppareilsInPage: React.Dispatch<React.SetStateAction<AppareilInArray[]>>;
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
}

const InstallationModale = ({
  isOpen,
  installationInModal,
  setInstallationsInPage,
  setInstallationInModale,
  setModaleOpen,
  isLcp,
  isWicowi,
  installationsInPage,
  appareilsInPage,
  setAppareilsInPage,
  combustiblesInPage,
  setCombustiblesInPage,
  facteursInPage,
  setFacteursInPage,
  mesuresInPage,
  setMesuresInPage,
  matieresInPage,
  setMatieresInPage,
}: InstallationModaleProps) => {
  const onClose = () => {
    setInstallationInModale(null);
    setModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'installations."
      onRequestClose={onClose}
    >
      <FormSingleInstallation
        isLcp={isLcp}
        isWicowi={isWicowi}
        forbiddenInstallationName={installationsInPage
          .map(installation => installation.data.nom || "")
          .filter(installationName => {
            if (installationName === "") {
              return false;
            }
            if (installationInModal !== null) {
              return installationName !== installationInModal.data.nom; //If the name is different than the name in the modal, we keep it.
            }
            return true; //If there is no procede in the modal, we keep all procedes names.
          })}
        closeFunction={onClose}
        onSubmit={values => {
          const newInstallation: InstallationInModale = {
            nom: values.nom,
            type: values.type,
            heure: values.heure,
            volumeActivite: values.volumeActivite,
            unite: values.unite,
            typeProduit: values.typeProduit,
            quantiteChaleur: values.quantiteChaleur,
            quantiteElectricite: values.quantiteElectricite,
            rendementChaleur: values.rendementChaleur,
            rendementElectricite: values.rendementElectricite,
          };
          if (installationInModal) {
            const modifyAppareilBasedOnInstallation = <
              T extends LegacySubInstallationElement
            >(
              content: InArray<T>[]
            ) => {
              // Update appareil name for all dependent objects
              const newAppareils = cloneDeep(content);
              for (let i = 0; content[i]; i++) {
                if (
                  content[i].data.nameInstallation ===
                    installationInModal.data.nom &&
                  values.nom !== installationInModal.data.nom
                ) {
                  newAppareils[i].data.nameInstallation = values.nom;
                }
              }
              return newAppareils;
            };
            setAppareilsInPage(appareils => {
              appareils = modifyAppareilBasedOnInstallation(appareilsInPage);
              // Overwriting dependent fields
              if (!isAppareilSubpartFormAvailable(newInstallation.type)) {
                appareils
                  .filter(
                    appareil =>
                      appareil.data.nameInstallation === newInstallation.nom
                  )
                  .forEach(appareil => {
                    appareil.data.modification = null;
                    appareil.data.lowestThan1500 = null;
                  });
              }
              return appareils;
            });
            setCombustiblesInPage(
              modifyAppareilBasedOnInstallation(combustiblesInPage)
            );
            setFacteursInPage(
              modifyAppareilBasedOnInstallation(facteursInPage)
            );
            setMesuresInPage(modifyAppareilBasedOnInstallation(mesuresInPage));
            setMatieresInPage(
              modifyAppareilBasedOnInstallation(matieresInPage)
            );
          }
          addOrModifyArray(
            setInstallationsInPage,
            installationInModal && installationInModal.data.id,
            newInstallation
          );
          onClose();
        }}
        initialInstallation={installationInModal && installationInModal.data}
      />
    </CustomModal>
  );
};

export default InstallationModale;
