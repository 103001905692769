import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleProcede from "./FormSingleProcede";
import { addOrModifyArray } from "common/declarant/array/utils";
import { ProcedeInArray } from "./utils/types";

interface ProcedeModaleProps {
  procedeModaleOpen: boolean;
  procedeInModale: ProcedeInArray | null;
  setProcedesInPage: Dispatch<SetStateAction<ProcedeInArray[]>>;
  setProcedeInModale: Dispatch<SetStateAction<ProcedeInArray | null>>;
  setProcedeModaleOpen: Dispatch<SetStateAction<boolean>>;
  procedesInPage: ProcedeInArray[];
}

const ProcedeModale = ({
  procedeModaleOpen,
  procedeInModale,
  setProcedesInPage,
  setProcedeInModale,
  setProcedeModaleOpen,
  procedesInPage,
}: ProcedeModaleProps): React.ReactElement => {
  const onClose = () => {
    setProcedeInModale(null);
    setProcedeModaleOpen(false);
  };
  return (
    <CustomModal
      isOpen={procedeModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de procédés."
      onRequestClose={onClose}
    >
      <FormSingleProcede
        closeFunction={onClose}
        forbiddenProcedeName={procedesInPage
          .map(procede => procede.data.nom || "")
          .filter(procedeName => {
            if (procedeName === "") {
              return false; //We filted the empty strings out.
            }
            if (procedeInModale !== null) {
              return procedeName !== procedeInModale.data.nom; //If the name is different than the name in the modal, we keep it.
            }
            return true; //If there is no procede in the modal, we keep all procedes names.
          })}
        onSubmit={(values, formikBag) => {
          addOrModifyArray(
            setProcedesInPage,
            procedeInModale && procedeInModale.data.id,
            values
          );
          onClose();
        }}
        isEdit={procedeInModale !== null}
        initialProcede={
          procedeInModale !== null
            ? procedeInModale.data
            : {
                nom: null,
                quantite: null,
                unite: null,
                precision: null,
                masse: null,
              }
        }
      />
    </CustomModal>
  );
};

export default ProcedeModale;
