import { AirSolvantsEmissionDtoDangerEnum } from "api/gen";

export const arrayMentionLabels: {
  [key in AirSolvantsEmissionDtoDangerEnum]: string;
} = {
  H340: "H340",
  H350: "H350",
  H350I: "H350I",
  H360D: "H360D",
  H360F: "H360F",
  H341: "H341",
  H351: "H351",
};

export const arrayMentionOptions = Object.keys(
  arrayMentionLabels
) as AirSolvantsEmissionDtoDangerEnum[];
