import React, { ReactNode } from "react";
import Spinner from "common/presentational/Spinner";
import RedirectHome from "pages/CommonSpace/AppPage/Redirect/RedirectHome";
import { useReferentials } from "pages/CommonSpace/ReferentialsContext/hooks";
import {
  FetchedReferentials,
  ReferentialKeyType,
} from "pages/CommonSpace/ReferentialsContext/types";

interface ReferentialFetcherProps<Subset extends ReferentialKeyType> {
  wantedKeys: Subset[];
  render: (fetched: FetchedReferentials<Subset>) => ReactNode;
}

export function ReferentialFetcher<Subset extends ReferentialKeyType>({
  wantedKeys,
  render,
}: ReferentialFetcherProps<Subset>): React.ReactElement {
  const wantedRefs = useReferentials(wantedKeys);
  if (wantedRefs === null) {
    return <Spinner />;
  } else if (Object.values(wantedRefs).some(ref => ref === null)) {
    return <RedirectHome initialRedirection={true} />;
  } else {
    return <>{render(wantedRefs)}</>;
  }
}
