import React from "react";
import { ProcedeInArray } from "../ListProcede/utils/types";

export const findSingleProcedeInArrayByName = (
  procedeName: string | null,
  procedes: ProcedeInArray[]
): ProcedeInArray | null => {
  return (
    procedes.find(procede => {
      return procede.data.nom === procedeName;
    }) || null
  );
};

export const getSingleProcedeInMapById = (
  procedeId: string | null,
  procedes: Map<string, ProcedeInArray>
): ProcedeInArray | null => {
  return procedes.get(procedeId || "") || null;
};

export const getProcedesIds = (
  procedeNames: string[],
  procedes: ProcedeInArray[]
): string[] => {
  return procedeNames
    .map(name => {
      const procede = findSingleProcedeInArrayByName(name, procedes);
      return procede ? procede.data.id : null;
    })
    .filter(id => id !== null) as string[];
};

export const getProcedeName = (
  id: string,
  procedesInPageMap: Map<string, ProcedeInArray>
): string | null => {
  const procede = procedesInPageMap.get(id);
  return procede ? procede.data.nom : null;
};

export const getProcedesNames = (
  procedeIds: string[] | null,
  procedesInPageMap: Map<string, ProcedeInArray>
): string[] => {
  if (procedeIds === null) {
    return [];
  }
  return procedeIds
    .map(id => getProcedeName(id, procedesInPageMap))
    .filter(nom => nom !== null) as string[];
};

export const createProcedeDiv = (
  procedesIds: string[],
  procedesInPageMap: Map<string, ProcedeInArray>
): React.ReactElement => {
  const procedesText = getProcedesNames(procedesIds, procedesInPageMap).join(
    ", "
  );

  return <div title={procedesText}>{procedesText}</div>;
};
