export const SIRET_EXCEPTIONS = /356000000...../;

export function doubleAndSumDigitsIfNecessary(n: number): number {
  /* return 2n if (2n < 10) else return 2n-9 because for a number between 10 and 19, the sum of its digits is equal to 2n-9. (ex: 16-9 = 10-9 + 6 = 7)*/
  return n > 4 ? 2 * n - 9 : 2 * n;
}

function sumDigits(siret: string): number {
  let sum = 0;
  for (let i = 0; i < siret.length; i++) {
    sum += parseInt(siret.charAt(i));
  }
  return sum;
}

function computeLuhnAlgorithm(siret: string): number {
  let sum = 0;
  let isOdd = false;
  for (let i = siret.length - 1; i > -1; i--) {
    const n = parseInt(siret.charAt(i));
    sum += isOdd ? doubleAndSumDigitsIfNecessary(n) : n;
    isOdd = !isOdd;
  }
  return sum;
}

export function checkLuhnAlgorithm(siret: string): boolean {
  if (siret.match(SIRET_EXCEPTIONS)) {
    return sumDigits(siret) % 5 === 0;
  } else {
    return computeLuhnAlgorithm(siret) % 10 === 0;
  }
}
