import { DechetProduitDto2022LieuOperationEnum } from "api/gen/api";
import { Dispatch, SetStateAction, useCallback } from "react";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
  generateReceivedPolluant,
} from "./Generators";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../production/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../reception/types";
import { WasteReferentiels, WasteTabData } from "../types";
import _ from "lodash";
import { isNotificationNumberAccessibleForProduction } from "../production/submitHandler";
import { convertReceptionStoredToModal } from "../receptionModal/converter";
import { isNotificationNumberAccessibleForReception } from "../reception/submitHandler";
import { convertProductionStoredToModalData } from "../productionModal/converter";
import { DataWithErrors } from "common/form/utils";
import {
  DeclarationBody20Now,
  DeclarationSections20Now,
  TypeActiviteGlobal20Now,
} from "../../../toNow/versionedElements/declarationHooks20Now";
import { useAlertModale } from "common/modale/hooks";
import { normalizeLabel } from "common/utils/methods";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";
import { DeclarationBody2022 } from "../../versionedElements/declarationHooks2022";

export const shouldDisplayProductionArray = (
  productionInfo: ProductedWasteFormData
): boolean => {
  return (
    productionInfo.dangerousWasteOver2000kg ||
    (productionInfo.isEPRTR && productionInfo.atLeastOneFacilityWasteOver2Mkg)
  );
};
export const shouldDisplayReceptionArray = (
  receptionInfo: ReceivedWasteFormData
): boolean => {
  return receptionInfo.isFacilityManagingWaste;
};

//  delete waste from container and return new container and removed index
export function deleteWasteFromContainer<T>(data: T, container: T[]): T[] {
  return container.filter(item => !_.isEqual(data, item));
}

//  find duplicate in array
export const isProductedWasteInContainer = (
  waste: ProductedWasteStorage,
  container: ProductedWasteStorage[],
  referentiels: WasteReferentiels
): boolean => {
  //  we have to convert stored data (aka back office model data) to modal data in order to use the same methods in the modal and in this function
  const converted = convertProductionStoredToModalData(
    waste.data,
    referentiels
  );

  //  allow duplicates with errors
  if (
    !waste.data.codeID ||
    !waste.data.operationElimination ||
    !waste.data.lieuOperation
  ) {
    return false;
  } else if (
    waste.data.lieuOperation ===
      DechetProduitDto2022LieuOperationEnum.DEPARTEMENT &&
    !(
      waste.data.departementID &&
      waste.data.nomEtablissementReception &&
      waste.data.streetAdresseEtablissementReception &&
      waste.data.postalCodeEtablissementReception &&
      waste.data.communeEtablissementReception
    )
  ) {
    return false;
  } else if (
    waste.data.lieuOperation === DechetProduitDto2022LieuOperationEnum.PAYS &&
    !(
      waste.data.paysID &&
      waste.data.nomEtablissementReception &&
      waste.data.streetAdresseEtablissementReception &&
      waste.data.postalCodeEtablissementReception &&
      waste.data.communeEtablissementReception &&
      waste.data.nomEtablissementElimination &&
      waste.data.streetAdresseEtablissementElimination &&
      (isNotificationNumberAccessibleForProduction(converted)
        ? waste.data.numeroNotification !== null &&
          waste.data.numeroNotification !== ""
        : true)
    )
  ) {
    return false;
  }

  // If the data has no error, prevent duplication
  return container.some(test => {
    if (
      waste.data.lieuOperation === DechetProduitDto2022LieuOperationEnum.SITE
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation === DechetProduitDto2022LieuOperationEnum.SITE
      );
    } else if (
      waste.data.lieuOperation ===
      DechetProduitDto2022LieuOperationEnum.DEPARTEMENT
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation ===
          DechetProduitDto2022LieuOperationEnum.DEPARTEMENT &&
        test.data.departementID === waste.data.departementID &&
        test.data.nomEtablissementReception ===
          waste.data.nomEtablissementReception &&
        test.data.streetAdresseEtablissementReception ===
          waste.data.streetAdresseEtablissementReception &&
        test.data.postalCodeEtablissementReception ===
          waste.data.postalCodeEtablissementReception &&
        test.data.communeEtablissementReception ===
          waste.data.communeEtablissementReception
      );
    } else if (
      waste.data.lieuOperation === DechetProduitDto2022LieuOperationEnum.PAYS
    ) {
      return (
        test.data.codeID === waste.data.codeID &&
        test.data.operationElimination === waste.data.operationElimination &&
        test.data.lieuOperation ===
          DechetProduitDto2022LieuOperationEnum.PAYS &&
        test.data.paysID === waste.data.paysID &&
        test.data.nomEtablissementReception ===
          waste.data.nomEtablissementReception &&
        test.data.streetAdresseEtablissementReception ===
          waste.data.streetAdresseEtablissementReception &&
        test.data.postalCodeEtablissementReception ===
          waste.data.postalCodeEtablissementReception &&
        test.data.communeEtablissementReception ===
          waste.data.communeEtablissementReception &&
        test.data.nomEtablissementElimination ===
          waste.data.nomEtablissementElimination &&
        test.data.streetAdresseEtablissementElimination ===
          waste.data.streetAdresseEtablissementElimination &&
        test.data.numeroNotification === waste.data.numeroNotification
      );
    } else {
      return false;
    }
  });
};
export const isReceivedWasteInContainer = (
  waste: ReceivedWasteStorage,
  container: ReceivedWasteStorage[],
  referential: WasteReferentiels
): boolean => {
  //  allow duplication for invalid data only
  if (
    !waste.data.codeID ||
    !waste.data.operationTraitement ||
    (!waste.data.estTraiteDansUnAutrePays && !waste.data.departementID) ||
    (waste.data.estTraiteDansUnAutrePays && !waste.data.paysID)
  ) {
    return false;
  }

  // To check if numeroNotification is required or not we have to convert the data (with back office format) to modal data in order to use the same methods
  const converted = convertReceptionStoredToModal(waste.data, referential);
  if (
    isNotificationNumberAccessibleForReception(converted) &&
    !waste.data.numeroNotification
  ) {
    return false;
  }

  // If all required data is present check for unicity
  return container.some(test => {
    return (
      waste.data.codeID === test.data.codeID &&
      waste.data.sortiDuStatutDeDechet === test.data.sortiDuStatutDeDechet &&
      waste.data.operationTraitement === test.data.operationTraitement &&
      waste.data.departementID === test.data.departementID &&
      waste.data.paysID === test.data.paysID &&
      waste.data.numeroNotification === test.data.numeroNotification
    );
  });
};

/////////
//  form helpers
/////////
//  parse fetched data
export const parseFetch = (
  declarationBody: DeclarationBody2022,
  referentiels: WasteReferentiels,
  typeActiviteGlobal: TypeActiviteGlobal20Now,
  receptionDechetsData: ReceivedWasteFormData | null
): [
  ProductedWasteFormData,
  ProductedWasteStorage[],
  ReceivedWasteFormData,
  ReceivedWasteStorage[]
] => {
  const declarationSections: DeclarationSections20Now =
    declarationBody.sections;

  const productionInfo: ProductedWasteFormData = {
    dangerousWasteOver2000kg:
      declarationSections.dechets.production.productionDechetDangereuxSup2000,
    atLeastOneFacilityWasteOver2Mkg: !!declarationSections.dechets.production
      .productionDechetNonDangereuxSup2000,
    isEPRTR: typeActiviteGlobal.estEPRTR,
  };
  const productionData: ProductedWasteStorage[] = convertProductionFetchToLocal(
    declarationSections.dechets.production.productionDechet,
    referentiels
  );
  const receptionInfo: ReceivedWasteFormData = {
    isFacilityManagingWaste: typeActiviteGlobal.recepDechet,
    isISDND: typeActiviteGlobal.isdnd || false,
    isISDD: typeActiviteGlobal.isdd || false,
    isISDI: typeActiviteGlobal.isdi || false,
    remainingCapacity:
      declarationSections.dechets.reception.capaciteRestante !== 0
        ? declarationSections.dechets.reception.capaciteRestante
        : null,
    doesFacilityHaveCasiersPlatre: !!declarationSections.dechets.reception
      .aCasiersPlatre,
    doesFacilityHaveAsbestosCases: !!declarationSections.dechets.reception
      .aCasiersAmiante,
  };
  const receptionData: ReceivedWasteStorage[] = convertReceptionFetchToLocal(
    declarationSections.dechets.reception.receptionDechets,
    referentiels,
    typeActiviteGlobal,
    receptionDechetsData || receptionInfo
  );
  return [productionInfo, productionData, receptionInfo, receptionData];
};
//  import waste data in collection
export function useImportWaste<T, A>(
  referentiels: WasteReferentiels,
  receptionDechetsData: ReceivedWasteFormData | null,
  generator: (
    t: A,
    props: WasteReferentiels,
    typeActivite: TypeActiviteGlobal20Now,
    receptionDechetsData: ReceivedWasteFormData | null
  ) => DataWithErrors<T>,
  setWastes: Dispatch<SetStateAction<DataWithErrors<T>[]>>,
  onImport: (() => void) | null
): (w: A[], message: string | null) => void {
  const openModale = useAlertModale();
  const typeActiviteGlobal = useCorrectYearTypeActiviteGlobal20Now();

  return useCallback(
    (w: A[], message: string | null) => {
      const imported = w.map(waste => {
        return generator(
          waste,
          referentiels,
          typeActiviteGlobal,
          receptionDechetsData
        );
      });
      onImport && onImport();
      setWastes(imported);

      let errorMessage: string | null = null;

      if (
        imported.some(imported => {
          return !_.isEmpty(imported.errors);
        })
      ) {
        errorMessage =
          "Des erreurs ont été détectées dans votre fichier importé. Veuillez corriger les éléments encadrés en rouge dans le tableau et importer à nouveau.";
      }
      if (!message && errorMessage) {
        openModale(errorMessage);
      }
      if (message && !errorMessage) {
        openModale(message);
      }
      if (message && errorMessage) {
        openModale(message + "\n" + errorMessage);
      }
    },
    [
      generator,
      onImport,
      setWastes,
      openModale,
      referentiels,
      typeActiviteGlobal,
      receptionDechetsData,
    ]
  );
}
//  compute child visible data according to form values
export const productionDataComputed = (
  formInfo: ProductedWasteFormData,
  container: ProductedWasteStorage[]
): WasteTabData<ProductedWasteFormData, ProductedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayProductionArray(formInfo) ? container : [],
  };
};
export const receptionDataComputed = (
  formInfo: ReceivedWasteFormData,
  container: ReceivedWasteStorage[],
  productionInfo: ProductedWasteFormData,
  productionContainer: ProductedWasteStorage[]
): WasteTabData<ReceivedWasteFormData, ReceivedWasteStorage> => {
  return {
    formInfo: formInfo,
    container: shouldDisplayReceptionArray(formInfo)
      ? shouldDisplayProductionArray(productionInfo)
        ? [...container, ...generateReceivedPolluant(productionContainer)]
        : container
      : [],
  };
};

// Compute boolean SSD from gabarit production and reception
export const parseOuiNonToBoolean = (userInput: string): boolean | null => {
  if (normalizeLabel(userInput) === "oui") {
    return true;
  }
  if (normalizeLabel(userInput) === "non") {
    return false;
  }

  return null;
};

export const removeFrenchPostalCodeWhitespace = (
  postalCode: string | null,
  isFrenchPostalCode: boolean | null
): string | null => {
  if (!postalCode || !isFrenchPostalCode) {
    return postalCode;
  }
  return postalCode.replace(/\s+/g, "");
};
