import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import {
  DISABLED_BLOC_FILTER,
  LEFT_WITHDRAW_STYLE,
  SUBTITLE_STYLE,
} from "theme";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import BlocInstallation from "./blocInstallation";
import ListInstallation from "./listInstallation";
import InformationBloc from "./information";
import classNames from "classnames";
import isEqual from "lodash.isequal";
import { ReferenceCombustibleDto, ReferencePolluantDto } from "api/gen";
import {
  convertInformationsToDisplayed,
  createAirCombustionsInformationDto,
} from "./information/utils/utils";
import {
  convertInstallationsToDisplayed,
  createAirCombustionsInstallationDto,
} from "./blocInstallation/utils/utils";
import {
  convertAppareilsToDisplayed,
  createAirCombustionsAppareilDto,
} from "./listInstallation/blocAppareils/utils/utils";
import {
  convertCombustiblesToDisplayed,
  createAirCombustionsCombustibleDto,
} from "./listInstallation/blocCombustibles/utils/utils";
import {
  convertEmissionsToDisplayed,
  createAirCombustionEmissionDto,
} from "./listInstallation/blocEmissions/utils/utils";
import { computeErrors, hasEqualDisplayed } from "./utils/utils";
import { InstallationInArray } from "./blocInstallation/utils/types";
import { AppareilInArray } from "./listInstallation/blocAppareils/utils/types";
import { CombustibleInArray } from "./listInstallation/blocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./listInstallation/blocEmissions/utils/types";
import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_COMBUSTION_CONTENT,
  PATH_AIR_COMBUSTION_INSTALLATION,
  PATH_AIR_COMBUSTION_RECAPITULATIF,
} from "common/path/path18Now";
import { ReferentialSinglePolluants } from "./utils/types";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../../versionedElements/declarationHooks1919";
import GlobalFormActionFullContext1919 from "../../versionedElements/GlobalFormActionFullContext1919";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "10px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  disable: {
    filter: DISABLED_BLOC_FILTER,
    pointerEvents: "none",
  },
  message: {
    textAlign: "center",
    color: "black",
    fontSize: "18px",
    width: "100%",
  },
  ...LEFT_WITHDRAW_STYLE,
});

interface MainAirFormProps {
  referentialCombustible: ReferenceCombustibleDto;
  referentialAir: ReferencePolluantDto;
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const MainAirForm = ({
  referentialCombustible,
  referentialAir,
  referentialSinglePolluants,
}: MainAirFormProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();
  const { cancelValidate } = useBasicDeclarationHandlers1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-air-combustion",
  };

  const substancesAir = useMemo(
    () =>
      referentialAir.polluants.map((polluant, index) => {
        return {
          value: polluant.nom,
          label: polluant.nom,
          object: polluant,
        };
      }),
    [referentialAir]
  );

  const blocCombustionDto = declaration.body.sections.air.combustion;

  //====================================
  //TYPE ACTIVITE
  //====================================
  const typeActivite = declaration.body.typeActivite;

  //====================================
  //REACAPITULATIF
  //====================================
  const initialInformations = convertInformationsToDisplayed(
    blocCombustionDto.recapitulatif
  );
  const [informationsInPage, setInformationsInPage] = useState(
    initialInformations
  );

  //====================================
  //INSTALLATIONS
  //====================================
  const initialInstallations = blocCombustionDto.installations.map(
    installation => convertInstallationsToDisplayed(installation)
  );
  const [installationsInPage, setInstallationsInPage] = useState<
    InstallationInArray[]
  >(initialInstallations);

  //====================================
  //APPAREILS
  //====================================
  const initialAppareils = blocCombustionDto.content.appareils.map(appareil =>
    convertAppareilsToDisplayed(appareil)
  );
  const [appareilsInPage, setAppareilsInPage] = useState<AppareilInArray[]>(
    initialAppareils
  );

  //====================================
  //COMBUSTIBLES
  //====================================
  const initialCombustibles = blocCombustionDto.content.combustibles.map(
    combustible =>
      convertCombustiblesToDisplayed(
        combustible,
        referentialCombustible.referenceItemCombustibleDtoList,
        initialAppareils
      )
  );
  const [combustiblesInPage, setCombustiblesInPage] = useState<
    CombustibleInArray[]
  >(initialCombustibles);

  //====================================
  //EMISSIONS
  //====================================
  const [
    initialFacteurs,
    initialMesures,
    initialMatieres,
  ] = convertEmissionsToDisplayed(
    blocCombustionDto.content.emissions,
    initialCombustibles,
    substancesAir,
    initialAppareils
  );

  //============FACTEUR
  const [facteursInPage, setFacteursInPage] = useState<
    FacteurEmissionInArray[]
  >(initialFacteurs);

  //============MESURE
  const [mesuresInPage, setMesuresInPage] = useState<MesureEmissionInArray[]>(
    initialMesures
  );

  //============MATIERE
  const [matieresInPage, setMatieresInPage] = useState<
    MatiereEmissionInArray[]
  >(initialMatieres);

  const DummyBooleanField = useDummyBooleanCheckboxFieldGenerator({
    ...commonProps,
    disabled: true,
  });

  const isInstallationValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_INSTALLATION
  );
  const isInformationsValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_RECAPITULATIF
  );
  const isContentValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_CONTENT
  );

  const computedInstallations: InstallationInArray[] = installationsInPage.map(
    installation => {
      return {
        data: installation.data,
        errors: computeErrors(installation, informationsInPage),
      };
    }
  );

  const hasError = computedInstallations.find(
    installation => !isEqual(installation.errors, {})
  );
  if (hasError && isInstallationValidated) {
    cancelValidate(PATH_AIR_COMBUSTION_INSTALLATION);
  }

  const canSubmitForm = () => {
    return (
      (!installationsInPage[0] &&
        isInformationsValidated &&
        isInstallationValidated) ||
      (isInstallationValidated && isInformationsValidated && isContentValidated)
    );
  };

  return (
    <>
      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>
          DONNÉES HÉRITÉES DU BLOC INFORMATIONS GÉNÉRALES
        </h3>
      </Row>
      <DummyBooleanField
        name="hasCombustionInstallation"
        label="L'établissement possède une ou plusieurs installations de combustion d'une puissance supérieure à 20 MW"
        value={typeActivite.combustion20MW}
      />
      <Row height={"12px"} />
      <DummyBooleanField
        name="receivesWaste"
        label="L'établissement réceptionne / traite / stocke des déchets"
        value={typeActivite.recepDechet}
      />
      <div className={classes.withdrawLeft}>
        <Row height={"12px"} />
        <DummyBooleanField
          name="hasWasteBurningInstallation"
          label="L'établissement possède une ou plusieurs installations d'incinération ou de co-incinération de déchets"
          value={typeActivite.incinerationDechet}
        />
        <Row height={"12px"} />
        <DummyBooleanField
          name="hasOneOrManyISDND"
          label="L'établissement possède une ou plusieurs ISDND"
          value={typeActivite.isdnd}
        />
        <div className={classes.withdrawLeft}>
          <Row height={"12px"} />
          <DummyBooleanField
            name="hasBiogazTorcheres"
            label="L'ISDND possède une ou plusieurs torchères de biogaz"
            value={typeActivite.torchereBiogaz}
          />
          <Row height={"12px"} />
          <DummyBooleanField
            name="hasBiogazValorisationInstallation"
            label="L'ISDND dispose d'un système de valorisation du biogaz"
            value={typeActivite.valorisationBiogaz}
          />
        </div>
      </div>
      <Row />
      <InformationBloc
        validationInformationPath={PATH_AIR_COMBUSTION_RECAPITULATIF}
        initialInformations={initialInformations}
        informationsInPage={informationsInPage}
        setInformationsInPage={setInformationsInPage}
      />
      <Row />
      <div>
        {!isInformationsValidated && (
          <>
            <div className={classes.message}>
              Veuillez valider les informations complémentaires afin que ce bloc
              soit accessible.
            </div>
            <Row height={"20px"} />
          </>
        )}
        <div
          className={classNames({
            [classes.disable]: !isInformationsValidated,
          })}
        >
          <BlocInstallation
            initialInstallations={initialInstallations}
            installationsInPage={computedInstallations}
            setInstallationsInPage={setInstallationsInPage}
            validationInstallationPath={PATH_AIR_COMBUSTION_INSTALLATION}
            isLcp={informationsInPage.lcp}
            isWicowi={informationsInPage.wiCoWi}
            appareilsInPage={appareilsInPage}
            setAppareilsInPage={setAppareilsInPage}
            combustiblesInPage={combustiblesInPage}
            setCombustiblesInPage={setCombustiblesInPage}
            facteursInPage={facteursInPage}
            setFacteursInPage={setFacteursInPage}
            mesuresInPage={mesuresInPage}
            setMesuresInPage={setMesuresInPage}
            matieresInPage={matieresInPage}
            setMatieresInPage={setMatieresInPage}
            combustibleReferential={referentialCombustible}
            substances={substancesAir}
          />
        </div>
      </div>
      <Row />
      <div>
        {!isInstallationValidated || !isInformationsValidated ? (
          <>
            <div className={classes.message}>
              Veuillez valider les informations complémentaires et la
              déclaration des installations afin que ce bloc soit accessible.
            </div>
            <Row height={"20px"} />
          </>
        ) : null}
        <div
          className={classNames({
            [classes.disable]:
              !isInformationsValidated ||
              !isInstallationValidated ||
              !installationsInPage[0],
          })}
        >
          <ListInstallation
            installationsList={installationsInPage}
            validationContentPath={PATH_AIR_COMBUSTION_CONTENT}
            initialAppareils={initialAppareils}
            appareilsInPage={appareilsInPage}
            setAppareilsInPage={setAppareilsInPage}
            initialCombustibles={initialCombustibles}
            combustiblesInPage={combustiblesInPage}
            setCombustiblesInPage={setCombustiblesInPage}
            initialFacteurs={initialFacteurs}
            facteursInPage={facteursInPage}
            setFacteursInPage={setFacteursInPage}
            initialMesures={initialMesures}
            mesuresInPage={mesuresInPage}
            setMesuresInPage={setMesuresInPage}
            initialMatieres={initialMatieres}
            matieresInPage={matieresInPage}
            setMatieresInPage={setMatieresInPage}
            combustibleReferential={referentialCombustible}
            isTorchereBiogaz={typeActivite.torchereBiogaz || false}
            isValorisationBiogaz={typeActivite.valorisationBiogaz || false}
            substancesAir={substancesAir}
            referentialSinglePolluants={referentialSinglePolluants}
          />
        </div>
      </div>
      <GlobalFormActionFullContext1919
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={
          !isEqual(initialAppareils, appareilsInPage) ||
          !isEqual(initialCombustibles, combustiblesInPage) ||
          !isEqual(initialFacteurs, facteursInPage) ||
          !isEqual(initialMesures, mesuresInPage) ||
          !isEqual(initialMatieres, matieresInPage) ||
          !isEqual(initialInformations, informationsInPage) ||
          !hasEqualDisplayed(initialInstallations, installationsInPage)
        }
        isValidateEnabled={canSubmitForm()}
        validationPath={PATH_AIR_COMBUSTION}
        updateHandler={declaration => {
          declaration.body.sections.air.combustion.recapitulatif = createAirCombustionsInformationDto(
            informationsInPage
          );
          declaration.body.sections.air.combustion.installations = createAirCombustionsInstallationDto(
            installationsInPage
          );
          declaration.body.sections.air.combustion.content.emissions = createAirCombustionEmissionDto(
            [facteursInPage, mesuresInPage, matieresInPage],
            substancesAir
          );
          declaration.body.sections.air.combustion.content.combustibles = createAirCombustionsCombustibleDto(
            combustiblesInPage,
            referentialCombustible.referenceItemCombustibleDtoList
          );
          declaration.body.sections.air.combustion.content.appareils = createAirCombustionsAppareilDto(
            appareilsInPage
          );
          return declaration;
        }}
        cancelAction={() => {
          setInformationsInPage(initialInformations);
          setInstallationsInPage(initialInstallations);
          setAppareilsInPage(initialAppareils);
          setCombustiblesInPage(initialCombustibles);
          setFacteursInPage(initialFacteurs);
          setMesuresInPage(initialMesures);
          setMatieresInPage(initialMatieres);
        }}
      />
    </>
  );
};

export default MainAirForm;
