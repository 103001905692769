import React, { useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import ProcedeArray from "./ProcedeArray";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../Emission/utils/types";
import { CovInArray } from "../CovDanger/utils/types";
import ProcedesModale from "./ProcedeModale";
import { convertProcedeInArrayToDto } from "./utils/utils";
import { convertCovInArrayToDto } from "../CovDanger/utils/utils";
import {
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "../Emission/utils/utils";
import isEqual from "lodash.isequal";
import { ProcedeInArray } from "./utils/types";
import { modifyEmissionBasedOnProcede } from "./utils/updateEmission";
import _ from "lodash";
import { compareProcedeFunction } from "../utils/compareMethod";
import { BlocFullContext2023 } from "../../../versionedElements/BlocFullContext2023";
import { Declaration2023 } from "../../../versionedElements/declarationHooks2023";

interface ListProcedeProps {
  initialProcedes: ProcedeInArray[];
  covsInPage: CovInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  validationProcedePath: string;
  procedesInPage: ProcedeInArray[];
  setProcedesInPage: React.Dispatch<React.SetStateAction<ProcedeInArray[]>>;
  setMesureEmissionsInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  setMatiereEmissionsInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  setCorrelationEmissionsInPage: React.Dispatch<
    React.SetStateAction<CorrelationEmissionInArray[]>
  >;
  setCovsInPage: React.Dispatch<React.SetStateAction<CovInArray[]>>;
}
const ListProcede = ({
  initialProcedes,
  covsInPage,
  mesureEmissionsInPage,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  validationProcedePath,
  procedesInPage,
  setProcedesInPage,
  setMesureEmissionsInPage,
  setMatiereEmissionsInPage,
  setCorrelationEmissionsInPage,
  setCovsInPage,
}: ListProcedeProps): React.ReactElement => {
  const [procedeModaleOpen, setProcedeModaleOpen] = useState(false);

  const [procedeInModale, setProcedeInModale] = useState<ProcedeInArray | null>(
    null
  );

  const updateHandler = (declaration: Declaration2023) => {
    const procedesInPageMap = new Map<string, ProcedeInArray>(
      procedesInPage.map(procede => [procede.data.id, procede])
    );

    declaration.body.sections.air.fugitives.procedes = convertProcedeInArrayToDto(
      procedesInPage
    );
    const flux = modifyEmissionBasedOnProcede(covsInPage, procedesInPage);
    declaration.body.sections.air.fugitives.flux = convertCovInArrayToDto(
      flux,
      procedesInPageMap
    );
    setCovsInPage(flux);
    const correlation = modifyEmissionBasedOnProcede(
      correlationEmissionsInPage,
      procedesInPage
    );
    declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
      correlation,
      procedesInPageMap
    );
    setCorrelationEmissionsInPage(correlation);
    const mesure = modifyEmissionBasedOnProcede(
      mesureEmissionsInPage,
      procedesInPage
    );
    declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
      mesure,
      procedesInPageMap
    );
    setMesureEmissionsInPage(mesure);
    const matiere = modifyEmissionBasedOnProcede(
      matiereEmissionsInPage,
      procedesInPage
    );
    declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
      matiere,
      procedesInPageMap
    );
    setMatiereEmissionsInPage(matiere);
    return declaration;
  };

  return (
    <>
      <BlocFullContext2023
        title="déclaration des procédés"
        hasModification={!isEqual(initialProcedes, procedesInPage)}
        isValidateButtonAvailable={true}
        path={validationProcedePath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setProcedesInPage(initialProcedes)}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UN PROCÉDÉ"
                  onClick={() => {
                    setProcedeModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <ProcedeArray
                validationProcedePath={validationProcedePath}
                procedesInPage={_.cloneDeep(procedesInPage).sort(
                  compareProcedeFunction
                )}
                setProcedesInPage={setProcedesInPage}
                setProcedeInModale={setProcedeInModale}
                setProcedeModaleOpen={setProcedeModaleOpen}
                isValidated={shouldDisabledFields}
              />
              <Row />
            </>
          );
        }}
      />
      <ProcedesModale
        procedeModaleOpen={procedeModaleOpen}
        procedeInModale={procedeInModale}
        setProcedesInPage={setProcedesInPage}
        setProcedeInModale={setProcedeInModale}
        setProcedeModaleOpen={setProcedeModaleOpen}
        procedesInPage={procedesInPage}
      />
    </>
  );
};

export default ListProcede;
