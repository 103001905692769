import { createContext, useContext } from "react";
import {
  DeclarationControllerApi,
  DeclarationCreationControllerApi,
  DeclarationFileControllerApi,
  FileControllerApi,
  GestionnaireControllerApi,
  PurgeControllerApi,
  ReferenceAdminControllerApi,
  ReferenceAirControllerApi,
  ReferenceCarriereControllerApi,
  ReferenceDechetControllerApi,
  ReferenceEauControllerApi,
  ReferenceEmailsDestinatairesControllerApi,
  ReferenceInfosGeneralesControllerApi,
  ReferenceInspecteursEmailQuotasControllerApi,
  ReferencePaysControllerApi,
  ReferenceQuotasControllerApi,
  ReferenceSectionControllerApi,
  ReferenceSolControllerApi,
  TrackDechetControllerApi,
} from "api/gen";
import { temporaryStorage } from "./storage";
import {
  AuthJwtDto,
  CampaignControllerApi,
  EtablissementControllerApi,
  ReferencePostCampaignEmailControllerApi,
} from "api/gen/api";

export interface AuthenticatedApiContextInterface {
  declarationController: DeclarationControllerApi;
  declarationCreationController: DeclarationCreationControllerApi;
  gestionnaireController: GestionnaireControllerApi;
  etablissementController: EtablissementControllerApi;
  fileController: FileControllerApi;
  declarationFileController: DeclarationFileControllerApi;
  campaignController: CampaignControllerApi;
  referenceSectionController: ReferenceSectionControllerApi;
  referenceAirController: ReferenceAirControllerApi;
  referenceEauController: ReferenceEauControllerApi;
  referenceSolController: ReferenceSolControllerApi;
  referenceDechetController: ReferenceDechetControllerApi;
  referenceCarriereController: ReferenceCarriereControllerApi;
  referenceInfoGeneralController: ReferenceInfosGeneralesControllerApi;
  referencePaysController: ReferencePaysControllerApi;
  referenceQuotasController: ReferenceQuotasControllerApi;
  referenceAdminController: ReferenceAdminControllerApi;
  referenceEmailsDestinatairesController: ReferenceEmailsDestinatairesControllerApi;
  referenceInspecteursEmailQuotasController: ReferenceInspecteursEmailQuotasControllerApi;
  referencePostCampaignEmailController: ReferencePostCampaignEmailControllerApi;
  purgeController: PurgeControllerApi;
  trackdechetController: TrackDechetControllerApi;
}

export const AuthenticatedApiContext = createContext<AuthenticatedApiContextInterface | null>(
  null
);

export const useAuthenticatedApi = (): AuthenticatedApiContextInterface => {
  const context = useContext(AuthenticatedApiContext);

  if (context === null) {
    throw new Error("Expected this hook to be called inside an Api context");
  }

  return context;
};

// Remove jwt from temporaryStorage when receiving an expired session error
const manageExpiredSession = async (res: Response) => {
  if (res.status === 401) {
    res
      .clone()
      .json()
      .then(resJson => {
        if (resJson && resJson.error === "Session Token expired") {
          temporaryStorage.put("jwt", null);
        }
      });
  }
  return res;
};

export const generateAuthenticatedHeader = (withBearer: string) => {
  return (url: RequestInfo, options?: RequestInit): Promise<Response> => {
    const previousHeader = (options && options.headers) || {};
    const optionsWithToken: RequestInit = {
      ...options,
      headers: {
        ...previousHeader,
        Authorization: `Bearer ${withBearer}`,
      },
    };

    return fetch(url, optionsWithToken).then(manageExpiredSession);
  };
};

// Similar to classic fetch, but add access token in the header
const authenticatedFetch = (userData: AuthJwtDto) =>
  generateAuthenticatedHeader(userData.jwt);

export const buildAuthenticatedApi = (
  userData: AuthJwtDto
): AuthenticatedApiContextInterface => {
  return {
    declarationController: new DeclarationControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    declarationCreationController: new DeclarationCreationControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    gestionnaireController: new GestionnaireControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    etablissementController: new EtablissementControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceSectionController: new ReferenceSectionControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceAirController: new ReferenceAirControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceEauController: new ReferenceEauControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceSolController: new ReferenceSolControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceDechetController: new ReferenceDechetControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceCarriereController: new ReferenceCarriereControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    fileController: new FileControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),

    declarationFileController: new DeclarationFileControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    campaignController: new CampaignControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceInfoGeneralController: new ReferenceInfosGeneralesControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referencePaysController: new ReferencePaysControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceQuotasController: new ReferenceQuotasControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceAdminController: new ReferenceAdminControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceEmailsDestinatairesController: new ReferenceEmailsDestinatairesControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referenceInspecteursEmailQuotasController: new ReferenceInspecteursEmailQuotasControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    referencePostCampaignEmailController: new ReferencePostCampaignEmailControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    purgeController: new PurgeControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
    trackdechetController: new TrackDechetControllerApi(
      undefined,
      "",
      authenticatedFetch(userData)
    ),
  };
};
