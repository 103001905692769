import { TransportProductsArrayForm, TransportProductsForm } from "./types";
import { sumPercentagesMessage } from "common/declarant/formik/formikMessages";
import * as Yup from "yup";
import {
  commonNullableStringFields,
  commonPercentageFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { enhanceWithCatchAndLogErrors } from "common/utils/methods";
import { FormikErrors } from "formik/dist/types";

//TODO [GEREP-2579] il faudra remettre le type ObjectSchema<Shape<
//   Omit<TransportProductsForm, "usageFamily">,
//   Omit<TransportProductsForm, "usageFamily">
// >> pour le validationSchema ici.
const validationSchemaYup = Yup.object().shape({
  byRoad: commonPercentageFields,
  byRail: commonPercentageFields,
  byWaterWay: commonPercentageFields,
  other: commonPercentageFields,
  otherPrecision: Yup.string().when("other", {
    is: (other: number | null) => other !== null && other > 0,
    then: commonStringFields,
    otherwise: commonNullableStringFields,
  }),
});

export const validateTransportsForm = (
  values: TransportProductsArrayForm,
  listRestorationCodeFamilleUsageProductionDeclared: number[]
): { [k: string]: string | FormikErrors<TransportProductsForm> } => {
  const errors: {
    [k: string]: string | FormikErrors<TransportProductsForm>;
  } = {};
  Object.entries(values.productsTransport).forEach(
    ([codeFamilleUsage, productTransport]) => {
      if (
        listRestorationCodeFamilleUsageProductionDeclared.includes(
          parseInt(codeFamilleUsage)
        )
      ) {
        const sumPercentage: number =
          (productTransport.byRail === null ? 0 : productTransport.byRail) +
          (productTransport.byRoad === null ? 0 : productTransport.byRoad) +
          (productTransport.byWaterWay === null
            ? 0
            : productTransport.byWaterWay) +
          (productTransport.other === null ? 0 : productTransport.other);
        if (sumPercentage !== 100) {
          errors[
            `productsTransport.${codeFamilleUsage}.byRoad`
          ] = sumPercentagesMessage;
          errors[
            `productsTransport.${codeFamilleUsage}.byRail`
          ] = sumPercentagesMessage;
          errors[
            `productsTransport.${codeFamilleUsage}.byWaterWay`
          ] = sumPercentagesMessage;
          errors[
            `productsTransport.${codeFamilleUsage}.other`
          ] = sumPercentagesMessage;
        }

        const yupCalculedErrors = enhanceWithCatchAndLogErrors(
          (valuesPassed: TransportProductsForm) => {
            const yupErrors: { [k: string]: string } = {};
            try {
              validationSchemaYup.validateSync(valuesPassed, {
                abortEarly: false,
              });
            } catch (err) {
              err.inner.forEach(
                (errorData: { path: string; message: string | undefined }) => {
                  if (errorData.message !== undefined) {
                    const errorKey = errorData.path as keyof TransportProductsForm; // we know the path is a valid key of TransportProductsForm (as long as we keep form and schema with same key, that is)
                    yupErrors[errorKey] = errorData.message;
                  }
                }
              );
            }

            return yupErrors;
          }
        )(productTransport);

        if (Object.keys(yupCalculedErrors).length !== 0) {
          Object.entries(yupCalculedErrors).forEach(
            ([keyErrorYup, errorMessage]) => {
              if (typeof errorMessage === "string") {
                errors[
                  `productsTransport.${codeFamilleUsage}.${keyErrorYup}`
                ] = errorMessage;
              }
            }
          );
        }
      }
    }
  );
  return errors;
};
