import React from "react";
import { makeStyles } from "@material-ui/styles";
import { GREY } from "theme";
import HeaderBandeau from "./HeaderBandeau";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
  root: {
    minHeight: 100,
    fontFamily: "arial, helvetica, sans-serif",
  },
  hr: {
    border: `1px ${GREY} solid`,
  },
});

const Banner = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <HeaderBandeau />
        <hr className={classes.hr} />
      </div>
    </div>
  );
};

export default Banner;
