import React from "react";
import { computeErrorObject } from "./utils";
import DummyChoiceSelect from "../dumbInput/DummyChoiceSelect";
import { ChoiceSelectProps } from "../types/connectedTypes";
import { OptionProps } from "../types/basicTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

/**
 * @deprecated use WrappedChoiceSelect(WithoutLabel) instead.
 */
function ChoiceSelect<T extends OptionProps, IsMulti extends boolean>({
  field,
  form,
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: ChoiceSelectProps<T, IsMulti>): React.ReactElement {
  const error = computeErrorObject({ form, field });

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyChoiceSelect<T, IsMulti>
      {...field}
      {...props}
      error={error}
      value={externalValue !== undefined ? externalValue : field.value}
      disabled={externalValue !== undefined ? true : disabled}
      onChange={selectedOption => {
        form.setFieldValue(field.name, selectedOption);
        form.setFieldTouched(field.name);
        if (additionalOnChange !== undefined) {
          additionalOnChange(selectedOption);
        }
      }}
    />
  );
}

export default ChoiceSelect;
