import React from "react";
import FieldWrapperWithTextLabel from "../wrappers/FieldWrapperWithTextLabel";
import { ChoiceSelectWTOPProps } from "../types/connectedTypes";
import { WrappedFieldProps } from "../types/basicTypes";
import { CommonProps } from "../../utils";
import { Omit } from "react-router";
import ChoiceSelectWithoutOptionProps from "../connectedInput/ChoiceSelectWithoutOptionProps";

interface WrappedChoiceSelectProps<T, IsMulti extends boolean>
  extends Omit<ChoiceSelectWTOPProps<T, IsMulti>, "field" | "form" | "id">,
    WrappedFieldProps {
  commonProps: CommonProps;
}

export function WrappedChoiceSelect<T, IsMulti extends boolean>({
  name,
  label,
  commonProps,
  commentPath,
  tooltipContent,
  additionalClassName,
  style,
  placeholder,
  hideValue,
  enableReset,
  options,
  isMulti,
  computeLabel,
  additionalOnChange,
  externalValue,
  tooltipLabel,
  disabled,
}: WrappedChoiceSelectProps<T, IsMulti>): React.ReactElement {
  const isDisabled =
    externalValue !== undefined || (disabled ?? commonProps.disabled);

  return (
    <FieldWrapperWithTextLabel
      name={name}
      label={label}
      commonProps={{ ...commonProps, commentPath: commentPath }}
      tooltipContent={tooltipContent}
      disabled={isDisabled}
      render={(fieldProps, id) => {
        return (
          <ChoiceSelectWithoutOptionProps
            field={fieldProps.field}
            form={fieldProps.form}
            options={options}
            isMulti={isMulti}
            computeLabel={computeLabel}
            id={id}
            disabled={isDisabled}
            additionalClassName={additionalClassName}
            style={style}
            placeholder={placeholder}
            hideValue={hideValue}
            enableReset={enableReset}
            additionalOnChange={additionalOnChange}
            externalValue={externalValue}
            tooltipLabel={tooltipLabel}
          />
        );
      }}
    />
  );
}
