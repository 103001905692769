import {
  AlternativeEmissionInArray,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasOptions,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "../blocEmissions/types";
import {
  findFlowDeclaration,
  findMeasureDeclaration,
} from "../blocEmissions/utils/utils";
import { InstallationInArray } from "../blocInstallations/types";

const isCO2 = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas && emission.data.gas.object === GasOptions.CO2;

const isN2O = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas && emission.data.gas.object === GasOptions.N2O;

const isPFC = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray
) => emission.data.gas && emission.data.gas.object === GasOptions.PFC;

const filterAlternativeEmissionsByNim = (
  emission: AlternativeEmissionInArray,
  installation: InstallationInArray
) =>
  emission.data.nimOptionProps &&
  emission.data.nimOptionProps.label === installation.data.nim;

const filterMeasuredEmissionsByNim = (
  emission: MeasureEmissionInArray,
  installation: InstallationInArray,
  measureDeclarations: MeasureDeclarationProps[]
) => {
  const measure = findMeasureDeclaration(
    emission.data.measure && emission.data.measure.object,
    measureDeclarations
  );
  return measure && measure.nim && installation.data.nim === measure.nim.label;
};

const filterComputedEmissionsByNim = (
  emission: ComputedEmissionInArray,
  installation: InstallationInArray,
  flowDeclarations: FlowDeclarationProps[]
) => {
  const flow = findFlowDeclaration(
    emission.data.flow && emission.data.flow.object,
    flowDeclarations
  );
  return flow && flow.nim && installation.data.nim === flow.nim.label;
};

const getGasEmissionToIncrement = (
  emission:
    | ComputedEmissionInArray
    | MeasureEmissionInArray
    | AlternativeEmissionInArray,
  onlyBiomass: boolean
) => {
  if (isCO2(emission)) {
    return onlyBiomass
      ? emission.data.biomassEmission || 0
      : emission.data.fossilEmission || 0;
  } else {
    return emission.data.otherEmission || 0;
  }
};

export const computeCO2Emissions = (
  installation: InstallationInArray,
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  computedEmissions
    .filter(em =>
      filterComputedEmissionsByNim(em, installation, flowDeclarations)
    )
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  measuredEmissions
    .filter(em =>
      filterMeasuredEmissionsByNim(em, installation, measureDeclarations)
    )
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isCO2)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computeN2OEmissions = (
  installation: InstallationInArray,
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  measuredEmissions
    .filter(em =>
      filterMeasuredEmissionsByNim(em, installation, measureDeclarations)
    )
    .filter(isN2O)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isN2O)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computePFCEmissions = (
  installation: InstallationInArray,
  alternativeEmissions: AlternativeEmissionInArray[],
  onlyBiomass: boolean
): number => {
  let total = 0;
  alternativeEmissions
    .filter(em => filterAlternativeEmissionsByNim(em, installation))
    .filter(isPFC)
    .forEach(em => (total += getGasEmissionToIncrement(em, onlyBiomass)));
  return total;
};

export const computeTotalEmissions = (
  installation: InstallationInArray,
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
): number => {
  return (
    computeCO2Emissions(
      installation,
      computedEmissions,
      measuredEmissions,
      alternativeEmissions,
      measureDeclarations,
      flowDeclarations,
      onlyBiomass
    ) +
    computeN2OEmissions(
      installation,
      measuredEmissions,
      alternativeEmissions,
      measureDeclarations,
      onlyBiomass
    ) +
    computePFCEmissions(installation, alternativeEmissions, onlyBiomass)
  );
};

export const computeCO2EmissionsForAllInstallations = (
  installations: InstallationInArray[],
  computedEmissions: ComputedEmissionInArray[],
  measuredEmissions: MeasureEmissionInArray[],
  alternativeEmissions: AlternativeEmissionInArray[],
  measureDeclarations: MeasureDeclarationProps[],
  flowDeclarations: FlowDeclarationProps[],
  onlyBiomass: boolean
) => {
  let total = 0;
  installations.forEach(
    installation =>
      (total += computeCO2Emissions(
        installation,
        computedEmissions,
        measuredEmissions,
        alternativeEmissions,
        measureDeclarations,
        flowDeclarations,
        onlyBiomass
      ))
  );
  return total;
};
