import React from "react";
import { makeStyles } from "@material-ui/styles";
import magnify from "icons/magnify.svg";
import { SIMPLE_TEXT_INPUT_STYLE } from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  main: {
    ...SIMPLE_TEXT_INPUT_STYLE.container,
    height: "100%",
    width: "100%",
    maxWidth: "300px",
  },
  input: {
    ...SIMPLE_TEXT_INPUT_STYLE.content,
    outline: "0",
  },
  button: {
    marginLeft: "5px",
    height: "80%",
  },
});

export interface SearchFieldProps {
  search: string;
  setSearch: (search: string) => void;
  onEnterAction: () => void;
  placeholder?: string;
  additionalClassName?: string;
}

const SearchField = ({
  search,
  setSearch,
  onEnterAction,
  placeholder = "Rechercher...",
  additionalClassName,
}: SearchFieldProps): React.ReactElement => {
  const classes = useStyles();

  const onEnter = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onEnterAction();
    }
  };

  return (
    <div className={classNames(classes.main, additionalClassName)}>
      <input
        type="text"
        placeholder={placeholder}
        value={search}
        onChange={event => {
          setSearch(event.target.value);
        }}
        onKeyPress={onEnter}
        className={classes.input}
      />
      <input
        type="image"
        src={magnify}
        alt="Rechercher"
        onClick={() => {
          onEnterAction();
        }}
        className={classes.button}
      />
    </div>
  );
};

export default SearchField;
