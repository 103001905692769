import { ReferenceStationEpurationDto } from "api/gen/api";
import { BlocInfoGeneralesFormValues } from "./BlocInfoGenerales/types";
import { BlocPrelevementEauFormValues } from "./BlocPrelevementEau/types";
import { BlocRejetEauFormValues } from "./BlocRejetEau/types";
import { blocValuesToDto as blocInfoGeneralesValuesToDto } from "./BlocInfoGenerales/converter";
import { blocValuesToDto as blocPrelevementEauValuesToDto } from "./BlocPrelevementEau/converter";
import { blocValuesToDto as blocRejetEauValuesToDto } from "./BlocRejetEau/converter";
import { blocValuesToDto as blocRejetPolluantValuesToDto } from "./BlocRejetPolluant/converter";
import { Formik } from "libAdapter/Formik/TypesPatternAdaptater";
import { computeTotalWaterTaking } from "./BlocPrelevementEau/utils";
import { PolluantInArray } from "./BlocRejetPolluant/types";
import { Declaration20Now } from "../../toNow/versionedElements/declarationHooks20Now";

export function isOver50000Treshold(
  infoGeneralesValues: BlocInfoGeneralesFormValues,
  values: BlocPrelevementEauFormValues
): boolean {
  /** If depassement de seuil we take the computed value, if not depassement seuil we take the user input value */
  const depassementSeuilAndOver50000 =
    infoGeneralesValues.depassementSeuil &&
    computeTotalWaterTaking(values) > 50000;
  const notDepassementSeuilAndOver50000 =
    !infoGeneralesValues.depassementSeuil &&
    values.prelevementTotal &&
    values.prelevementTotal > 50000;
  return !!(depassementSeuilAndOver50000 || notDepassementSeuilAndOver50000);
}

export const isBlocRejetEauActive = (
  values: BlocPrelevementEauFormValues,
  infoGeneralesValues: BlocInfoGeneralesFormValues,
  polluantsInArray: PolluantInArray[]
): boolean => {
  return !!(
    polluantsInArray.length > 0 ||
    infoGeneralesValues.megathermie ||
    isOver50000Treshold(infoGeneralesValues, values)
  );
};

export const updateHandlerInfoGenerales = (
  declaration: Declaration20Now,
  values: BlocInfoGeneralesFormValues
) => {
  declaration.body.sections.eau.infoGenerales = blocInfoGeneralesValuesToDto(
    values
  );
  return declaration;
};

export const updateHandlerPrelevementEau = (
  declaration: Declaration20Now,
  values: BlocPrelevementEauFormValues,
  isDepassementSeuil: boolean
) => {
  declaration.body.sections.eau.prelevement = blocPrelevementEauValuesToDto(
    values,
    isDepassementSeuil
  );
  return declaration;
};

export const updateHandlerRejetPolluant = (
  declaration: Declaration20Now,
  polluantsInArray: PolluantInArray[]
) => {
  declaration.body.sections.eau.rejetPolluants = blocRejetPolluantValuesToDto(
    polluantsInArray
  );
  return declaration;
};

export const updateHandlerRejetEau = (
  declaration: Declaration20Now,
  values: BlocRejetEauFormValues,
  referentialStationsEpuration: ReferenceStationEpurationDto
) => {
  declaration.body.sections.eau.rejetEau = blocRejetEauValuesToDto(
    values,
    referentialStationsEpuration
  );
  return declaration;
};

export const updateHandlerPageEau = (
  declaration: Declaration20Now,
  infoGeneralesValues: BlocInfoGeneralesFormValues,
  prelevementEauFormikRef: React.RefObject<
    Formik<BlocPrelevementEauFormValues>
  > | null,
  polluantsInArray: PolluantInArray[],
  rejetEauFormikRef: React.RefObject<Formik<BlocRejetEauFormValues>> | null,
  referentialStationsEpuration: ReferenceStationEpurationDto
) => {
  declaration = updateHandlerInfoGenerales(declaration, infoGeneralesValues);
  if (prelevementEauFormikRef && prelevementEauFormikRef.current) {
    declaration = updateHandlerPrelevementEau(
      declaration,
      prelevementEauFormikRef.current.state.values,
      infoGeneralesValues.depassementSeuil
    );
  }
  declaration = updateHandlerRejetPolluant(declaration, polluantsInArray);
  if (rejetEauFormikRef && rejetEauFormikRef.current) {
    declaration = updateHandlerRejetEau(
      declaration,
      rejetEauFormikRef.current.state.values,
      referentialStationsEpuration
    );
  }
  return declaration;
};
