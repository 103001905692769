import {
  useDeclarationGlobalState21Now,
  useQuotaEmissionsState21Now,
} from "../../../versionedElements/declarationHooks21Now";
import { CREATED } from "common/messages/dashboardMessage";

export const roundPathProgression = (progress?: number): number => {
  if (progress === undefined) {
    return 0;
  }
  progress = Math.round(progress);
  const valueRound = 5;
  const modulo = progress % valueRound;
  const isToAdd = modulo > valueRound / 2;
  const valueToComplete = isToAdd ? valueRound - modulo : modulo;
  return isToAdd ? progress + valueToComplete : progress - valueToComplete;
};

export const useTypeActivitePercentageProgress = (): number => {
  const {
    isDeclarationStateContaining: isDeclarationGlobalStateContaining,
  } = useDeclarationGlobalState21Now();
  const {
    isDeclarationStateContaining: isDeclarationQuotaStateContaining,
  } = useQuotaEmissionsState21Now();

  const isGlobalTypeActiviteValidated = !isDeclarationGlobalStateContaining([
    CREATED,
  ]);
  const isQuotaTypeActiviteValidated = !isDeclarationQuotaStateContaining([
    CREATED,
  ]);

  let percentageProgress = 0;
  if (isGlobalTypeActiviteValidated) {
    percentageProgress += 50;
  }
  if (isQuotaTypeActiviteValidated) {
    percentageProgress += 50;
  }
  return percentageProgress;
};
