import { Dispatch, SetStateAction, useState } from "react";
import isEqual from "lodash.isequal";

export interface ClassicBlocProps<T> {
  values: T;
  setValues: Dispatch<SetStateAction<T>>;
  hasChanges: boolean;
  cancelAction: () => void;
}

// little hook used to easily define values manipulated by blocs not controlled by formik
export function useClassicBloc<T>(initialValues: T): ClassicBlocProps<T> {
  const [values, setValues] = useState<T>(initialValues);
  const hasChanges = !isEqual(initialValues, values);
  const cancelAction = () => {
    setValues(initialValues);
  };
  return {
    values,
    setValues,
    hasChanges,
    cancelAction,
  };
}
