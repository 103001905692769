import { AccidentInArray } from "../utils";

export const computeAccidentDateProperlyFormatted = (
  accidentEntity: AccidentInArray
): string => {
  const accidentDate = accidentEntity.data.date;

  if (accidentDate === null) {
    return "";
  }

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return accidentDate.toLocaleDateString("fr-FR", options);
};
