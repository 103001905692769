import React, { useState } from "react";
import { MainFormValues, PolluantInArray } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import FieldWrapper from "common/form/fields/wrappers/FieldWrapper";
import Row from "common/presentational/Row";
import Button from "common/button";
import SolArray from "./SolArray";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import {
  FIELD_FONT_SIZE,
  FIELD_FONT_WEIGHT,
  GREY,
  SECTION_TITLE_GREY,
  SEPARATOR_WIDTH,
  SUBTITLE_STYLE,
  WHOLE_LINE_LABEL_LEFT_MARGIN,
  WHOLE_LINE_LABEL_UL_LEFT_MARGIN,
  WHOLE_LINE_LABEL_WIDTH,
} from "theme";
import {
  computeDisplayedDataFromDto,
  computeFormInitialValues,
  computeRejetDtoFromDisplayedData,
} from "./utils/converter";
import _ from "lodash";
import { useFormikBloc } from "common/formikBloc/utils";
import SolModale from "./SolModale";
import { selectPossibleMethods } from "./utils/selectPossibleValues";
import { validationSchema } from "./validation/validation";
import { ReferenceNormeSolDto, ReferencePolluantDto } from "api/gen/api";
import CheckBox from "common/form/fields/connectedInput/CheckBox";
import { UnwrappedFieldProps } from "common/form/fields/types/basicTypes";
import {
  PATH_SOL,
  PATH_SOL_DOIT_REMPLIR,
  PATH_SOL_VOLUMEDECHETS,
} from "common/path/path18Now";
import { useDeclaration1919 } from "../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../to19/versionedElements/FormikBlocFullContext1919";
import { useEntityModale } from "common/modale/modaleStates";

const useStyles = makeStyles({
  subTitle: SUBTITLE_STYLE,
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    width: WHOLE_LINE_LABEL_WIDTH,
    marginLeft: WHOLE_LINE_LABEL_LEFT_MARGIN,
  },
  ulInLabel: {
    marginLeft: WHOLE_LINE_LABEL_UL_LEFT_MARGIN,
  },
  separator: {
    border: `${SEPARATOR_WIDTH} ${GREY} solid`,
    width: "100%",
  },
  inputField: {
    marginTop: "10px",
    marginBottom: "5px",
    display: "flex",
    "&>div>div": {
      justifyContent: "flex-end",
    },
  },
  text: {
    margin: "12px 0",
  },
});

interface MainSolFormProps {
  referentialPolluants: ReferencePolluantDto;
  referentialNormes: ReferenceNormeSolDto;
}

const MainSolForm = ({
  referentialPolluants,
  referentialNormes,
}: MainSolFormProps): React.ReactElement => {
  const classes = useStyles();

  const declaration = useDeclaration1919();
  const referentiels = selectPossibleMethods(referentialNormes);

  referentiels.polluantName = referentialPolluants.polluants;

  const initialValues = computeFormInitialValues(declaration);
  const validPolluants = computeDisplayedDataFromDto(
    declaration.body.sections.sol.rejets,
    referentiels,
    referentialNormes
  );

  const formProps = useFormikBloc<MainFormValues>();
  const { modaleState, openModale, closeModale } = useEntityModale<
    PolluantInArray
  >();
  const [polluantsInPage, setPolluantsInPage] = useState<PolluantInArray[]>(
    validPolluants
  );

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-sol",
  };
  const NumberField = useNumberFieldGenerator(commonProps);

  const compareFunction = (a: PolluantInArray, b: PolluantInArray): number => {
    if (
      a.data.pollutingSubstance &&
      b.data.pollutingSubstance &&
      a.data.pollutingSubstance.nom !== b.data.pollutingSubstance.nom
    ) {
      return a.data.pollutingSubstance.nom < b.data.pollutingSubstance.nom
        ? -1
        : 1;
    }
    return 0;
  };

  return (
    <>
      <FormikBlocFullContext1919
        formikRef={formProps.formikRef}
        hasChanges={formProps.hasChanges}
        setHasChanges={formProps.setHasChanges}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title={""}
        isSingle={true}
        pathToValidate={PATH_SOL}
        additionalValidationAllowed={() => {
          return !polluantsInPage.some(polluant => {
            return !_.isEqual(polluant.errors, {});
          });
        }}
        updateHandler={(declaration, values) => {
          declaration.body.sections.sol.doitRemplirSol =
            values.doesTreatmentOrValorisation;

          if (values.doesTreatmentOrValorisation) {
            declaration.body.sections.sol.volumeDechets = values.totalAmount;
            declaration.body.sections.sol.rejets = computeRejetDtoFromDisplayedData(
              polluantsInPage,
              referentiels
            );
          } else {
            declaration.body.sections.sol.volumeDechets = null;
            declaration.body.sections.sol.rejets = [];

            if (formProps.formikRef.current) {
              formProps.formikRef.current.resetForm({
                doesTreatmentOrValorisation: false,
                totalAmount: null,
              });
            }
          }

          setPolluantsInPage(
            computeDisplayedDataFromDto(
              declaration.body.sections.sol.rejets,
              referentiels,
              referentialNormes
            )
          );

          return declaration;
        }}
        hasFormChanges={values => {
          return (
            !_.isEqual(polluantsInPage, validPolluants) ||
            !_.isEqual(initialValues, values)
          );
        }}
        cancelAction={() => {
          setPolluantsInPage(validPolluants);
          return initialValues;
        }}
        renderContent={(props, shouldDisabledFields) => {
          return (
            <>
              <FieldWrapper
                renderLabel={(id: string) => (
                  <label className={classes.label} htmlFor={id}>
                    L'établissement exerce une des opérations de traitement ou
                    de valorisation suivantes :
                    <Row height="10px" />
                    <ul className={classes.ulInLabel}>
                      <li>
                        l'application de déchets ou d'effluents sur ou dans les
                        sols agricoles
                      </li>
                      <li>le traitement en milieu terrestre</li>
                      <li>l'injection en profondeur</li>
                    </ul>
                  </label>
                )}
                name="doesTreatmentOrValorisation"
                renderField={(formikProps: UnwrappedFieldProps, id: string) => (
                  <CheckBox
                    id={id}
                    field={formikProps.field}
                    form={formikProps.form}
                    disabled={shouldDisabledFields}
                  />
                )}
                commonProps={{
                  ...commonProps,
                  labelWidth: "90%",
                  commentPath: PATH_SOL_DOIT_REMPLIR,
                }}
              />

              {props.values.doesTreatmentOrValorisation && (
                <>
                  <Row />
                  <hr className={classes.separator} />
                  <Row />

                  <NumberField
                    name="totalAmount"
                    label="Quantité de déchets/boues/effluents épandus ou injectés *"
                    unit="t/an"
                    disabled={shouldDisabledFields}
                    commentPath={PATH_SOL_VOLUMEDECHETS}
                    tooltipContent={
                      "La quantité doit être déclarée en tonnage de déchets/effluents/boues humides."
                    }
                  />
                  <Row />
                  <Row>
                    <span className={classes.subTitle}>
                      TABLEAU DES REJETS DE SUBSTANCES DANS LE SOL
                    </span>
                  </Row>
                  <div className={classes.text}>
                    Cette partie concerne uniquement les émissions dans le sol
                    de polluants provenant de déchets soumis à l'une des
                    opérations de traitement suivantes : traitement en milieu
                    terrestre (épandage) ou injection en profondeur. En cas
                    d'épandage de boues industrielles, seules les substances
                    n'ayant pas d'intérêt agronomique avéré pour les sols ou
                    pour la nutrition des cultures (tels que métaux, substances
                    dangereuses, ...) devront être déclarées, dès lors qu'elles
                    sont rejetées en quantité supérieure aux seuils.
                  </div>
                  <Row
                    additionalStyle={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      text="AJOUTER UNE SUBSTANCE"
                      type="button"
                      onClick={() => {
                        openModale(null);
                      }}
                      isDisabled={shouldDisabledFields}
                    />
                  </Row>

                  <Row height="20px" />

                  <SolArray
                    polluantsInArray={_.cloneDeep(polluantsInPage).sort(
                      compareFunction
                    )}
                    isValidated={shouldDisabledFields}
                    setPolluantsInArray={setPolluantsInPage}
                    openModal={openModale}
                  />

                  <Row height={"20px"} />

                  <Row
                    additionalStyle={{
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      isReversed={true}
                      text="TOUT SUPPRIMER"
                      type="button"
                      onClick={() => {
                        setPolluantsInPage([]);
                      }}
                      isDisabled={shouldDisabledFields}
                    />
                  </Row>
                </>
              )}
            </>
          );
        }}
      />

      <SolModale
        polluantsInArray={polluantsInPage}
        isOpen={modaleState.isOpen}
        polluantInModal={modaleState.elementToEdit}
        referentialNormes={referentialNormes}
        closeModal={closeModale}
        referentiels={referentiels}
        setPolluantsInArray={setPolluantsInPage}
      />
    </>
  );
};

export default MainSolForm;
