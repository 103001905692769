import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import CustomModal from "common/presentational/CustomModal";
import {
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { ContactModalProps } from "./types";
import { validationSchema } from "./validation";
import { FIELD_FONT_SIZE, SECTION_TITLE_GREY, WARNING } from "theme";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import { useSpinnerState } from "../../button/loadingAndRedirectionHelpers";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageContactInspector } from "common/backErrors/errorMessages";
import { useDeclarationPageHeaderInfo } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";
import FormikAdapter from "../../../libAdapter/Formik/FormikAdapter";

const useStyles = makeStyles({
  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: FIELD_FONT_SIZE,
    color: SECTION_TITLE_GREY,
    fontWeight: 400,
    marginBottom: "5%",
  },
  warningContainer: {
    display: "flex",
    marginBottom: "2%",
  },
  warningIcon: {
    width: "20px",
    height: "20px",
    marginRight: "2%",
  },
  warningText: {
    display: "flex",
    color: WARNING,
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  textArea: {
    minHeight: "100px",
  },
});

interface ContactMessage {
  emails: string;
  subject: string | null;
  content: string | null;
}

interface ExtendedContactModalProps extends ContactModalProps {
  contactInspector: (
    emails: string[],
    annee: number,
    content: string,
    etablissement: string,
    subject: string,
    options?: any
  ) => Promise<Response>;
}

const ContactModal = ({
  isOpen,
  onRequestClose,
  contactInspector,
  emailsDeclarants,
}: ExtendedContactModalProps): ReactElement => {
  const classes = useStyles();
  const headerInfo = useDeclarationPageHeaderInfo();
  const [isLoading, triggerLoading] = useSpinnerState<void>();
  const openAlertModale = useAlertModale();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "25%",
    formPrefix: "modal-contact-inspecteur",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);

  const sendContactMessage = async (
    emails: string,
    subject: string,
    content: string
  ) => {
    if (
      headerInfo === null ||
      headerInfo.declarationAnnee === null ||
      headerInfo.declarationEtablissement === null
    ) {
      throw Error(
        "declaration header should not be null to open contact modale"
      );
    }

    try {
      await contactInspector(
        emails.split(/[\s,;]+/).map(email => email.trim()),
        parseInt(headerInfo.declarationAnnee),
        content,
        headerInfo.declarationEtablissement,
        subject
      );
    } catch (excp) {
      await openAlertModale(
        backAlertMessage(excp, backMessageContactInspector)
      );
      return;
    }

    onRequestClose();
  };

  const initialContent: ContactMessage = {
    emails: emailsDeclarants.join("; "),
    subject: null,
    content: null,
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <h3 className={classes.title}>CONTACT INSPECTION</h3>
      <FormikAdapter
        onSubmit={values => {
          if (values.emails === null) {
            throw Error("Message emails should not be null");
          }
          if (values.content === null) {
            throw Error("Message content should not be null");
          }
          if (values.subject === null) {
            throw Error("Message subject should not be null");
          }

          triggerLoading(
            sendContactMessage(values.emails, values.subject, values.content)
          );
        }}
        initialValues={initialContent}
        validationSchema={validationSchema}
        render={props => {
          return (
            <>
              <TextField
                placeholder="email 1; email 2; email 3 …"
                name="emails"
                label="Emails déclarants"
              />
              <TextField name="subject" label="Sujet" />

              <TextArea
                name="content"
                label="Votre message"
                additionalClassName={classes.textArea}
              />

              <DummyGlobalFormActions
                validateButton={{
                  isSpinnerVisible: isLoading,
                  title: "ENVOYER",
                  isVisible: true,
                  isEnabled: true,
                  onClick: () => {
                    props.submitForm();
                  },
                }}
                cancelButton={{
                  title: "ANNULER",
                  isVisible: true,
                  isEnabled: true,
                  onClick: () => {
                    onRequestClose();
                  },
                }}
              />
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default ContactModal;
