import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  BACKGROUND_GREY,
  BUTTON_DARK_GREY,
  DEFAULT_BORDER_RADIUS,
  GREY,
  HELP_TEXT_FONT_SIZE,
} from "theme";
import Button from "common/button";
import Spinner from "common/presentational/Spinner";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    fontSize: HELP_TEXT_FONT_SIZE,
    margin: "2px",
    padding: "2px 4px",
    borderRadius: DEFAULT_BORDER_RADIUS,
    color: "#000",
    backgroundColor: BACKGROUND_GREY,
    alignItems: "center",
  },
  // TODO: improve button appearance
  remove: {
    marginLeft: "4px",
    width: "1.2em",
    height: "1.2em",
    borderRadius: "1.2em",
    color: "#000",
    backgroundColor: BUTTON_DARK_GREY,
    "&:hover": {
      backgroundColor: GREY,
    },
    boxShadow: "none",
  },
});

interface ChipProps {
  onRemove?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
}

const Chip = ({ onRemove, isLoading, isDisabled, children }: ChipProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {isLoading && <Spinner size="sm" />}
      {children}
      {onRemove && (
        <Button
          text="x"
          onClick={onRemove}
          additionalClassname={classes.remove}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default Chip;
