import React, { Dispatch, SetStateAction, useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import CovArray from "./CovArray";
import CovModale from "./CovModale";
import { compareCovFunction, createAirSolvantsCovDto } from "./utils/utils";
import { createAirSolvantEmissionDto } from "../EmissionBloc/utils/utils";
import { cloneDeep, isEqual } from "lodash";
import { CovInArray } from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { EmissionsInArray } from "../EmissionBloc/utils/types";
import { makeStyles } from "@material-ui/styles";
import { useConfirmationModale } from "common/modale/hooks";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import BlocFullContext19Now from "../../../versionedElements/BlocFullContext19Now";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: "20px",
  },
});

interface CovProps {
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  initialInstallations: InstallationInArray[];
  covsInPage: CovInArray[];
  initialCovs: CovInArray[];
  emissionSpecifiquesInPage: EmissionsInArray[];
  emissionTotalesInPage: EmissionsInArray[];
  validationCovsPath: string;
}

const CovBloc = ({
  setCovsInPage,
  initialInstallations,
  covsInPage,
  initialCovs,
  emissionSpecifiquesInPage,
  emissionTotalesInPage,
  validationCovsPath,
}: CovProps): React.ReactElement => {
  const classes = useStyles();

  const openConfirmationModale = useConfirmationModale();
  const [covModaleOpen, setCovModaleOpen] = useState(false);
  //Notice we set the covInModale to an InArray type, because id is mandatory and won't be required with the InModale type.
  // to be renamed in https://dl.polyconseil.fr/jira/browse/GEREP-1370
  const [covInModale, setCovInModale] = useState<CovInArray | null>(null);

  const updateHandler = (declaration: Declaration19Now) => {
    declaration.body.sections.air.solvants.emissions = createAirSolvantsCovDto(
      covsInPage
    );
    declaration.body.sections.air.solvants.covs = createAirSolvantEmissionDto(
      emissionSpecifiquesInPage
    );
    declaration.body.sections.air.solvants.covnms = createAirSolvantEmissionDto(
      emissionTotalesInPage
    );
    return declaration;
  };

  const covnmInArray = cloneDeep(covsInPage).sort(compareCovFunction);

  return (
    <>
      <BlocFullContext19Now
        title="Émissions de COV à mention de danger"
        hasModification={!isEqual(initialCovs, covsInPage)}
        isValidateButtonAvailable={true}
        validationMessage={{
          message: TABS_VALIDATION_MESSAGE,
          isAlwaysVisible: true,
        }}
        path={validationCovsPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setCovsInPage(initialCovs)}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <p className={classes.marginBottom}>
                Substances ou mélanges auxquels sont attribuées ou sur lesquels
                doivent être apposées les mentions de dangers H340, H350, H350i,
                H360D ou H360F par le règlement 1272/2008/CE du Conseil et/ou
                émettant des composés organiques volatils halogénés auxquels est
                attribuée, ou sur lesquels doit être apposée la mention de
                danger H341 ou H351 (anciennes phrases de risques R40, R45, R46,
                R49, R60, R61 ou R68)
              </p>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER DES ÉMISSIONS"
                  onClick={() => {
                    setCovModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <CovArray
                covsInPage={covnmInArray}
                setCovsInPage={setCovsInPage}
                setCovInModale={setCovInModale}
                setCovModaleOpen={setCovModaleOpen}
                isValidated={shouldDisabledFields}
                validationCovsPath={validationCovsPath}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setCovsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      {/* //TODO https://dl.polyconseil.fr/jira/browse/GEREP-656 */}
      <CovModale
        isOpen={covModaleOpen}
        covInModal={covInModale}
        setCovsInPage={setCovsInPage}
        setCovInModale={setCovInModale}
        setModaleOpen={setCovModaleOpen}
        initialInstallations={initialInstallations}
      />
    </>
  );
};

export default CovBloc;
