import { MainFormValues } from "./types";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";

export const computeFormInitialValues = (
  declarationDto: Declaration19Now
): MainFormValues => {
  return {
    ch4Content: declarationDto.body.sections.air.isdnd.basic.ch4,
    volumeBiogazExported: declarationDto.body.sections.air.isdnd.basic.biogaz,
  };
};

export const calcEmissionAnnuelle = (
  debit: number,
  heures: number,
  concentration: number
): number => {
  return debit * heures * concentration;
};
