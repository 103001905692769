import { makeStyles } from "@material-ui/styles";
import React, { useLayoutEffect, useRef, useState } from "react";
import Spinner from "common/presentational/Spinner";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FileDto } from "api/gen";
import { useDownloadRapportAlcVerifieN1Handler } from "./hooks/alcModeleHooks";

const useStyle = makeStyles({
  spinner: {
    width: "auto",
    marginLeft: "3px",
  },
  button: {
    padding: "5px",
  },
  link: {
    textDecoration: "none",
  },
});

enum FILE_DOWNLOAD_STATE {
  NONE,
  PREPARING,
  DOWNLOADING,
}

interface AlcModeleAnneePrecedenteButtonProps {
  fileDto: FileDto | undefined;
  installationNim: string | null;
}

const AlcModeleAnneePrecedenteButton = ({
  fileDto,
  installationNim,
}: AlcModeleAnneePrecedenteButtonProps): React.ReactElement => {
  const classes = useStyle();
  const downloadHandler = useDownloadRapportAlcVerifieN1Handler();
  const [downloadState, setDownloadState] = useState<FILE_DOWNLOAD_STATE>(
    FILE_DOWNLOAD_STATE.NONE
  );

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  useLayoutEffect(() => {
    const trigger = async () => {
      if (fileDto === undefined || linkRef.current === null) {
        return;
      }

      if (!linkRef.current.href) {
        const response = await downloadHandler(fileDto);
        if (response === undefined) {
          return;
        }

        const blob = await response.blob();

        linkRef.current.href = window.URL.createObjectURL(blob);
        linkRef.current.download = `${fileDto.name}.${fileDto.extension}`;
      }

      // Anchor has now an href, click again on it to perform download in browser
      linkRef.current.click();
      setDownloadState(FILE_DOWNLOAD_STATE.NONE);
    };

    if (downloadState === FILE_DOWNLOAD_STATE.PREPARING) {
      setDownloadState(FILE_DOWNLOAD_STATE.DOWNLOADING);
      trigger();
    }
  }, [downloadState, setDownloadState, fileDto, downloadHandler]);

  const isSpinnerVisible = downloadState !== FILE_DOWNLOAD_STATE.NONE;

  return (
    <>
      {isSpinnerVisible ? (
        <Spinner additionalClassname={classes.spinner} />
      ) : (
        <Button
          text={
            <>
              {installationNim === null ? (
                <p>TÉLÉCHARGER VOTRE RAPPORT ALC DE L'ANNÉE PRÉCÉDENTE</p>
              ) : (
                <p>
                  TÉLÉCHARGER VOTRE RAPPORT ALC DE L'ANNÉE PRÉCÉDENTE - NIM :{" "}
                  {installationNim}
                </p>
              )}
              <br />
              <FontAwesomeIcon icon="download" />
            </>
          }
          isDisabled={fileDto === undefined}
          isReversed
          additionalClassname={classes.button}
          onClick={() => setDownloadState(FILE_DOWNLOAD_STATE.PREPARING)}
        />
      )}
      {/* Because before the first download action the anchor is not valid the linter throw an error */}
      {/* eslint-disable-next-line */}
      <a ref={linkRef} className={classes.link} download />
    </>
  );
};

export default AlcModeleAnneePrecedenteButton;
