import React, { useMemo } from "react";
import {
  AuthDroitDtoAuthProfileEnum,
  EtablissementDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";
import Row from "common/presentational/Row";
import email from "icons/email.svg";
import cancelLogo from "icons/cancel.svg";
import { findElementMatchingTemplate } from "common/utils/methods";
import { useUserData } from "Authenticator/UserData";

export const useCompanyTableLines = (
  establishments: EtablissementDto[],
  referentielDepartments: ReferenceItemDepartementDto[],
  referentielRegion: ReferenceItemRegionDto[],
  onContactInfosClick: (establishment: EtablissementDto) => void,
  onDeleteClick: (establishment: EtablissementDto) => void,
  classes: Record<"contactButton" | "deleteButton", string>
): React.ReactElement[][] => {
  const userRights = useUserData().userInfo.droits[0];
  const { isSuperAdmin, isGerepAdmin } = useUserData();
  const toReturn = useMemo(() => {
    const buildActionButtons = (
      establishment: EtablissementDto
    ): React.ReactElement => {
      return (
        <Row height="25px">
          <input
            className={classes.contactButton}
            type="image"
            src={email}
            title="Fiche contact"
            alt="Fiche contact"
            width="30px"
            onClick={() => onContactInfosClick(establishment)}
          />
          {(isSuperAdmin || isGerepAdmin) && (
            <input
              className={classes.deleteButton}
              type="image"
              src={cancelLogo}
              title="Effacer l'établissement"
              alt="Effacer l'établissement"
              width="25px"
              onClick={() => onDeleteClick(establishment)}
            />
          )}
        </Row>
      );
    };

    return establishments.map(establishment => {
      const department = findElementMatchingTemplate(
        { numero: establishment.departement },
        referentielDepartments
      );
      const region = findElementMatchingTemplate(
        { codeRegion: establishment.codeRegion },
        referentielRegion
      );
      return [
        <p>{establishment.nom}</p>,
        <p>{establishment.code}</p>,
        <p>
          {establishment.natureService != null
            ? establishment.natureService.nom
            : ""}
        </p>,
        <p>{department ? department.nom : establishment.departement}</p>,
        <p>{region ? region.nom : establishment.codeRegion}</p>,
        buildActionButtons(establishment),
      ];
    });
  }, [
    establishments,
    onContactInfosClick,
    referentielDepartments,
    referentielRegion,
    onDeleteClick,
    userRights.authProfile,
    classes.contactButton,
    classes.deleteButton,
  ]);
  return toReturn;
};
