import { ReferenceRegionDto } from "api/gen";

export const getTrigrammeFromCodeRegionOrEmptyString = (
  codeRegion: string | null,
  referentialRegions: ReferenceRegionDto
) => {
  if (!codeRegion || !referentialRegions) {
    return "";
  }
  const region = referentialRegions.referenceItemRegionDtoList.find(
    region => region.codeRegion === codeRegion
  );
  return region ? region.trigramme : "";
};
