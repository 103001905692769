import React, { ReactElement } from "react";
import { QuotasActionButtonsProps } from "../../../toNow/Actions/types";
import { useUserData } from "Authenticator/UserData";
import InspecteurQuotasActionButtons from "../Inspecteur/InspecteurQuotasActionButtons";
import DeclarantQuotasActionButtons from "../Declarant/DeclarantQuotasActionButtons";

const QuotasActionButtons = ({
  quotaState,
  workflowTarget,
  path,
  isQuotaCampaignActive,
}: QuotasActionButtonsProps): ReactElement => {
  const { isInspecteur, isDeclarant } = useUserData();

  if (isInspecteur) {
    return (
      <InspecteurQuotasActionButtons
        quotaState={quotaState}
        workflowTarget={workflowTarget}
        path={path}
        isQuotaCampaignActive={isQuotaCampaignActive}
      />
    );
  }

  if (isDeclarant) {
    return (
      <DeclarantQuotasActionButtons
        quotaState={quotaState}
        workflowTarget={workflowTarget}
        path={path}
        isQuotaCampaignActive={isQuotaCampaignActive}
      />
    );
  }

  return <></>;
};

export default QuotasActionButtons;
