import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonNullableStringFields,
  commonNumberFields,
  commonPercentageFields,
  commonPercentageFieldsNullable,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveNumberFieldsNullable,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { CombustibleInModale, LegacyCombustible } from "../utils/types";
import {
  AirCombustionCombustibleDto20NowSurCendresEnum,
  AirCombustionCombustibleDto20NowUniteEnum,
  AirCombustionCombustiblePciDtoBrutEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
  ReferenceItemCombustibleDto,
} from "api/gen";
import {
  isUniteEnergy,
  isUniteMasse,
  isUnitePCS,
  isUniteVolume,
  isUnitPCIEnergyByMasse,
} from "../utils/selectPossibleValues";
import { Nullable } from "common/utils/types";

import {
  incorrectUnitForCombustibleMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import {
  shouldDisplayBiogazEcart,
  shouldDisplayPreciserMasseVolumique,
} from "../utils/FormCombustibleUtils";
import {
  getMasseVolumiqueCombustible,
  shouldDisplayPreciserPci,
} from "../utils/CombustiblePciUtils";
import { isCombustibleGazNaturel } from "../utils/CombustiblePcsUtils";
import { parseToUndefinedIfNull } from "common/form/utils";

export const torchereSubPartActivated = (
  type: ReferenceItemCombustibleDto | null,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
): boolean => {
  return !!(
    type &&
    (type.codeCombustible === "309" || type.codeCombustible === "310") &&
    (isTorchereBiogaz || isValorisationBiogaz)
  );
};

export const ch4MesureSubPartActivated = (
  ch4Mesure: boolean | null
): boolean => {
  return !!ch4Mesure;
};

export const biogazMesureSubPartActivated = (
  biogazMesure: boolean | null
): boolean => {
  return !!biogazMesure;
};

export const singleCombustibleOverwriteDependantFields = (
  combustible: CombustibleInModale,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
): void => {
  if (
    !torchereSubPartActivated(
      combustible.type || null,
      isTorchereBiogaz,
      isValorisationBiogaz
    )
  ) {
    combustible.biogazDebit = null;
    combustible.biogazMesure = null;
    combustible.biogazFrequence = null;
    combustible.biogazDuree = null;
    combustible.ch4Teneur = null;
    combustible.ch4Mesure = null;
    combustible.ch4Frequence = null;
    combustible.quantiteMethaneOxyde = null;
  }
  if (!ch4MesureSubPartActivated(combustible.ch4Mesure)) {
    combustible.ch4Frequence = null;
  }
  if (!biogazMesureSubPartActivated(combustible.biogazMesure)) {
    combustible.biogazFrequence = null;
  }
};

export const additionnalCombustibleValidation = () => (
  values: Nullable<Nullable<LegacyCombustible>>
): Partial<Record<keyof CombustibleInModale, string>> => {
  let errors: Partial<Record<keyof CombustibleInModale, string>> = {};
  const combustible: ReferenceItemCombustibleDto | null = values.type;

  const masseVolumiqueCombustible = getMasseVolumiqueCombustible(
    values,
    combustible
  );
  if (
    values.type &&
    shouldDisplayPreciserMasseVolumique(values, combustible) &&
    !values.masseEcart
  ) {
    errors = { ...errors, masseEcart: requiredMessage };
  }
  if (
    values.type &&
    shouldDisplayPreciserPci(values, combustible, masseVolumiqueCombustible) &&
    !values.pciEcart
  ) {
    errors = { ...errors, pciEcart: requiredMessage };
  }
  if (
    values.type &&
    shouldDisplayBiogazEcart(values, combustible) &&
    !values.biogazEcart
  ) {
    errors = { ...errors, biogazEcart: requiredMessage };
  }
  if (isMasseRequired(values) && values.masse === null) {
    errors = { ...errors, masse: requiredMessage };
  }
  if (values.unite && !isUniteEnergy(values.unite)) {
    if (values.pciValeur === null) {
      errors = { ...errors, pciValeur: requiredMessage };
    }
    if (values.pciUnite === null) {
      errors = { ...errors, pciUnite: requiredMessage };
    }
    if (values.pciProvenance === null) {
      errors = { ...errors, pciProvenance: requiredMessage };
    }
  }

  if (!isCombustibleGazNaturel(combustible) && isUnitePCS(values.unite)) {
    errors = { ...errors, unite: incorrectUnitForCombustibleMessage };
  }
  return errors;
};

export const singleCombustibleValidationSchema = (
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
) =>
  Yup.object().shape({
    type: Yup.object()
      .transform(parseToUndefinedIfNull)
      .required(requiredMessage),
    nameInstallation: commonNullableStringFields,
    consommation: commonNumberFields,
    unite: Yup.mixed<AirCombustionCombustibleDto20NowUniteEnum>()
      .transform(parseToUndefinedIfNull)
      .required(requiredMessage),
    appareils: commonArrayOfObjectFields,
    masse: commonStrictlyPositiveNumberFieldsNullable,
    masseEcart: commonNullableStringFields,
    eau: commonPercentageFieldsNullable,
    carbone: commonPercentageFieldsNullable,
    soufre: commonPercentageFieldsNullable,
    surCendres: Yup.mixed<
      AirCombustionCombustibleDto20NowSurCendresEnum
    >().nullable(),
    cendres: commonPercentageFieldsNullable,
    chlore: commonPercentageFieldsNullable,
    pciValeur: commonPositiveNumberFieldsNullable,
    pciUnite: Yup.mixed<AirCombustionCombustiblePciDtoUniteEnum>().nullable(),
    pciEcart: commonNullableStringFields,
    pciBrut: Yup.mixed<AirCombustionCombustiblePciDtoBrutEnum>().nullable(),
    pciProvenance: commonNullableStringFields,
    biogazFraction: commonPercentageFields,
    biogazEcart: commonNullableStringFields,
    biogazDebit: subFieldValidationScheme(
      "type",
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    biogazMesure: subFieldValidationScheme(
      "type",
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      Yup.boolean().required(requiredMessage),
      Yup.boolean()
    ),
    biogazFrequence: commonPositiveNumberFieldsNullable,
    biogazDuree: subFieldValidationScheme(
      "type",
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Teneur: subFieldValidationScheme(
      "type",
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Mesure: subFieldValidationScheme(
      "type",
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      Yup.boolean().required(requiredMessage),
      Yup.boolean()
    ),
    ch4Frequence: commonPositiveNumberFieldsNullable,
    quantiteMethaneOxyde: commonPositiveNumberFieldsNullable,
  });

export const isMasseRequired = (values: CombustibleInModale): boolean => {
  if (values.consommation !== null && values.unite && values.pciUnite) {
    return (
      values.consommation > 0 &&
      ((isUniteMasse(values.unite) &&
        !isUnitPCIEnergyByMasse(values.pciUnite)) ||
        (isUniteVolume(values.unite) &&
          isUnitPCIEnergyByMasse(values.pciUnite)))
    );
  }
  return false;
};
