import React, { CSSProperties } from "react";
import { DASHBOARD_BORDER_GREY, SUBTITLE_STYLE } from "theme";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
    display: "flex",
    flexDirection: "column",
  },
  subTitle: SUBTITLE_STYLE,
  alignIcon: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
});

export interface DummyUnActionedBlocProps {
  title: string;
  additionalStyle?: CSSProperties;
  additionalClassname?: string;
  renderContent: () => void;
  icon?: React.ReactNode;
}

export const DummyUnActionedBloc = ({
  title,
  additionalStyle,
  additionalClassname,
  renderContent,
  icon,
}: DummyUnActionedBlocProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.container, additionalClassname)}
      style={additionalStyle}
    >
      <div className={classes.alignIcon}>
        <h3 className={classes.subTitle}>{title}</h3>
        {icon}
      </div>
      {renderContent()}
    </div>
  );
};
