import React, { ReactElement } from "react";
import { ComputedEmissionInArray, FlowDeclarationProps } from "../types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import Button from "common/button";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_CALCULEES } from "common/path/path20Now";
import { useConfirmationModale } from "common/modale/hooks";
import Row from "common/presentational/Row";
import _ from "lodash";
import { methodLabels } from "../selectPossibleValues";
import { findElementByIdOrNull } from "common/utils/methods";

interface ComputedEmissionsArrayProps {
  computedEmissionsInArray: ComputedEmissionInArray[];
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  onEdit: (emission: ComputedEmissionInArray) => void;
  isDisabled: boolean;
  flowDeclarations: FlowDeclarationProps[];
}

const useStyles = makeStyles({
  array: {
    marginTop: "20px",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const ComputedEmissionsArray = ({
  computedEmissionsInArray,
  setComputedEmissionsInArray,
  onEdit,
  isDisabled,
  flowDeclarations,
}: ComputedEmissionsArrayProps): React.ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const header: ReactElement[] = [
    <p>Nom du flux</p>,
    <p>Méthode</p>,
    <p>Gaz émis</p>,
    <p>NIM</p>,
    <p>Émission de CO2 d'origine non biomasse (tCO2e/an)</p>,
    <p>Émission de CO2 d'origine biomasse (tCO2e/an)</p>,
    <p>Actions</p>,
  ];

  const computeLine = (
    emission: ComputedEmissionInArray
  ): React.ReactElement[] => {
    const flow = findElementByIdOrNull(
      emission.data.flow && emission.data.flow.id,
      flowDeclarations
    );

    const firstCellContent = computeFirstLineContentCell(
      () => onEdit(emission),
      flow && flow.name !== null ? flow.name : undefined,
      isDisabled,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p>{emission.data.method && methodLabels[emission.data.method]}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>{emission.data.gas && emission.data.gas.toString()}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>{flow && flow.nimInstallation && flow.nimInstallation.nim}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.fossilEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.fossilEmission
              )
            : ""}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.biomassEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.biomassEmission
              )
            : ""}
        </p>,
        classes
      ),
      <CommonEntityButton
        handlerEdit={() => onEdit(emission)}
        commentPath={`${PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_CALCULEES}/${emission.data.id}`}
        handlerSuppress={() =>
          setComputedEmissionsInArray(() =>
            computedEmissionsInArray.filter(e => e.data.id !== emission.data.id)
          )
        }
        isValidated={isDisabled}
        suppressMessage="Êtes-vous sûr de vouloir supprimer ces informations ?"
      />,
    ];
  };

  return (
    <>
      <CommonEntityTable
        header={header}
        preferredHeaderHeight={100}
        lines={computedEmissionsInArray.map(computeLine)}
        additionalClassName={classes.array}
        preferredColWidths={[100, 150, 80, 150, 80, 80, 100]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() =>
            openConfirmationModale(
              "Êtes-vous sûr de vouloir supprimer toutes les informations de ce tableau ?",
              () => setComputedEmissionsInArray(() => [])
            )
          }
        />
      </Row>
    </>
  );
};

export default ComputedEmissionsArray;
