import React, { ReactElement, ReactNode } from "react";
import {
  SousInstallationRefAlternatifDataDto2123,
  SousInstallationRefProduitDataDto,
  UniteRefAlternatifDto,
} from "api/gen";
import InfoBulle from "common/infoBulle/InfoBulle";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { EFFICACITE_ENERGETIQUE_DECIMAL_PRECISION } from "../constants";
import { translateBoolToFrenchOrNull } from "common/utils/booleanUtils";

const computeLine = <T,>(
  listSousInstallationData: T[],
  computeCell: (sousInstallation: T) => ReactElement,
  lineDescription: ReactNode,
  classes: ClassesType<"full" | "tooltip">,
  infobulleMessage?: string
) => {
  const line = listSousInstallationData.map(computeCell);

  line.unshift(
    computeWrappedCell(
      <>
        {infobulleMessage && (
          <InfoBulle
            content={infobulleMessage}
            placement={"left"}
            additionalClassName={classes.tooltip}
          />
        )}
        <p>{lineDescription}</p>
      </>,
      classes
    )
  );

  return line;
};

type SousInstallationData =
  | SousInstallationRefProduitDataDto
  | SousInstallationRefAlternatifDataDto2123;

export const computeAnneeWithDonneesHistoriquesLine = (
  yearList: string[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = (annee: string) => (
    <p>{annee === "-1" ? "Données historiques (HAL)" : annee}</p>
  );
  return computeLine(yearList, computeCell, "", classes);
};

const computeNiveauActiviteLine = (
  listSousInstallationData: SousInstallationData[],
  uniteNiveauActivite: string | null,
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ niveauActivite }: SousInstallationData) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(niveauActivite)}</p>
  );
  const unite = uniteNiveauActivite ? `(${uniteNiveauActivite})` : "";

  const lineDescription = `Niveau d'activité ${unite}`;

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes
  );
};

const computeNiveauActiviteMoyenLine = (
  listSousInstallationData: SousInstallationData[],
  unite: string | null,
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ niveauActiviteMoyen }: SousInstallationData) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(niveauActiviteMoyen)}</p>
  );
  const lineDescription = `Niveau d'activité moyen (${unite})`;
  const infobulleMessage =
    "Moyenne des niveaux d'activité des deux années précédentes.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeVariationNiveauActiviteLine = (
  listSousInstallationData: SousInstallationData[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ variationNiveauActivite }: SousInstallationData) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(variationNiveauActivite)}</p>
  );
  const lineDescription = "Variation du niveau d'activité (%)";
  const infobulleMessage =
    "(AAL-HAL) / HAL (en %)." +
    " Les valeurs affichée dans le rapport ALC diffèrent des valeurs affichées ci-contre dès lors qu'elles sont comprises entre -15% et +15%." +
    " En effet, le fichier ALC affiche 0% lorsque la variation est comprise dans cette intervalle.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    <b>{lineDescription}</b>,
    classes,
    infobulleMessage
  );
};

const computeCriteresAdaptationRefProduitLine = (
  listSousInstallationData: SousInstallationRefProduitDataDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    criteresAdaptationValidees,
  }: SousInstallationRefProduitDataDto) => (
    <p>{translateBoolToFrenchOrNull(criteresAdaptationValidees)}</p>
  );
  const lineDescription = "Critères d'adaptation validés ?";
  const infobulleMessage = "Sur la base du paramètre niveau d'activité.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeEmptyLine = (
  list: unknown[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  return computeLine(list, () => <></>, "", classes);
};

const computeAllocationProvisoireNiveauActiviteLine = (
  listSousInstallationData: SousInstallationRefProduitDataDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    allocationProvisoireSurBaseNiveauxActivites,
  }: SousInstallationRefProduitDataDto) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(
        allocationProvisoireSurBaseNiveauxActivites
      )}
    </p>
  );
  const lineDescription =
    "Allocation provisoire sur la base des niveaux d'activité uniquement (quotas)";
  const infobulleMessage = "Sans prise en compte des facteurs de correction.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeAllocationProvisoireParametreIntegreLine = (
  listSousInstallationData: SousInstallationRefProduitDataDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    allocationProvisoireAvecParametresIntegres,
  }: SousInstallationRefProduitDataDto) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(
        allocationProvisoireAvecParametresIntegres
      )}
    </p>
  );
  const lineDescription =
    "Allocation provisoire avec paramètres intégrés (quotas)";
  const infobulleMessage =
    "Sans prise en compte des facteurs de correction. Paramètres intégrés au calcul : chaleur non-ETS, gaz résiduaires, HVC, VCM, interchangeabilité combustible-électricité.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeEmissionsAttribueesLine = (
  listSousInstallationData: SousInstallationData[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ emissionsAttribuees }: SousInstallationData) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(emissionsAttribuees)}</p>
  );
  const lineDescription = "Emissions attribuées (tCO2e)";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes
  );
};

const computeAllocationFinaleLine = (
  listSousInstallationData: SousInstallationData[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({ allocationFinale }: SousInstallationData) => (
    <p>{getRoundedFixedNumberWithSeparatorOrNull(allocationFinale)}</p>
  );
  const lineDescription = "Allocation finale (quotas)";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes
  );
};

export const computeSousInstallationReferentielProduitLines = (
  uniteInstallation: string | null,
  listSousInstallationData: SousInstallationRefProduitDataDto[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[][] => {
  return [computeNiveauActiviteLine, computeNiveauActiviteMoyenLine]
    .map(computeLineMethod =>
      computeLineMethod(listSousInstallationData, uniteInstallation, classes)
    )
    .concat(
      [
        computeVariationNiveauActiviteLine,
        computeCriteresAdaptationRefProduitLine,
        computeEmptyLine,
        computeAllocationProvisoireNiveauActiviteLine,
        computeAllocationProvisoireParametreIntegreLine,
        computeEmissionsAttribueesLine,
        computeAllocationFinaleLine,
      ].map(computeLineMethod =>
        computeLineMethod(listSousInstallationData, classes)
      )
    );
};

const computeCriteresAdaptationRefAlternatifLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">,
  isPartialTable: boolean
): ReactElement[] => {
  const computeCell = ({
    criteresAdaptationValidees,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>{translateBoolToFrenchOrNull(criteresAdaptationValidees)}</p>
  );
  const lineDescription = isPartialTable
    ? "Critères d'adaptation validés ?"
    : "Critères d'adaptation validés (sans l'efficacité énergétique) ?";
  const infobulleMessage = isPartialTable
    ? undefined
    : "Sans “Efficacité énergétique”";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeEfficaciteEnergetiqueLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  uniteEfficacite: string | null,
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const unite = uniteEfficacite ? `(${uniteEfficacite})` : "";

  const computeCell = ({
    efficaciteEnergetique,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(
        efficaciteEnergetique,
        EFFICACITE_ENERGETIQUE_DECIMAL_PRECISION
      )}
    </p>
  );
  const lineDescription = `Efficacité énergétique déclarée ${unite}`;

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes
  );
};

const computeEfficaciteMoyenneLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  uniteEfficacite: string | null,
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const unite = uniteEfficacite ? `(${uniteEfficacite})` : "";

  const computeCell = ({
    efficaciteMoyenne,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(
        efficaciteMoyenne,
        EFFICACITE_ENERGETIQUE_DECIMAL_PRECISION
      )}
    </p>
  );
  const lineDescription = `Efficacité moyenne ${unite}`;
  const infobulleMessage = "Moyenne des deux années précédentes";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeVariationEfficaciteEnergetiqueLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    variationEfficaciteEnergetique,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(variationEfficaciteEnergetique)}
    </p>
  );
  const lineDescription = "Variation d'efficacité énergétique (%)";

  return computeLine(
    listSousInstallationData,
    computeCell,
    <b>{lineDescription}</b>,
    classes
  );
};

const computeDecisionNecessaireLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    decisionNecessaireAutoriteCompetente,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>{translateBoolToFrenchOrNull(decisionNecessaireAutoriteCompetente)}</p>
  );
  const lineDescription = "Décision nécessaire de l'autorité compétente (AC) ?";

  return computeLine(
    listSousInstallationData,
    computeCell,
    <b>{lineDescription}</b>,
    classes
  );
};

const computeNonRejetAcLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    nonRejetParAutoriteCompetente,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>{translateBoolToFrenchOrNull(nonRejetParAutoriteCompetente)}</p>
  );
  const lineDescription = "Rejet par l'AC";
  const infobulleMessage =
    "Tel que renseigné dans le fichier ALC actuellement." +
    " L'autorité compétente (AC) peut demander la modification de ce paramètre à l'exploitant suite à l'instruction du dossier." +
    " Si valeur = « VRAI », alors l'AC rejette l'adaptation de l'allocation." +
    " Si valeur = « FAUX », alors l'AC valide l'adaptation.";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes,
    infobulleMessage
  );
};

const computeAdaptationEffectiveLine = (
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">
): ReactElement[] => {
  const computeCell = ({
    adaptationEffectiveNiveauActivite,
  }: SousInstallationRefAlternatifDataDto2123) => (
    <p>
      {getRoundedFixedNumberWithSeparatorOrNull(
        adaptationEffectiveNiveauActivite
      )}
    </p>
  );
  const lineDescription = "Adaptation effective du niveau d'activité (%)";

  return computeLine(
    listSousInstallationData,
    computeCell,
    lineDescription,
    classes
  );
};

export const computeSousInstallationReferentielAlternatifLines = (
  uniteInstallation: UniteRefAlternatifDto,
  listSousInstallationData: SousInstallationRefAlternatifDataDto2123[],
  classes: ClassesType<"full" | "tooltip">,
  isPartialTable: boolean
): ReactElement[][] => {
  const listLinesToReturn: ReactElement[][] = [
    computeNiveauActiviteLine,
    computeNiveauActiviteMoyenLine,
  ]
    .map(computeLineMethod =>
      computeLineMethod(
        listSousInstallationData,
        uniteInstallation.uniteNiveauActivite,
        classes
      )
    )
    .concat(
      [
        computeVariationNiveauActiviteLine,
        computeCriteresAdaptationRefAlternatifLine,
        computeEmptyLine,
      ].map(computeLineMethod =>
        computeLineMethod(listSousInstallationData, classes, isPartialTable)
      )
    );

  if (!isPartialTable) {
    listLinesToReturn.push(
      computeEfficaciteEnergetiqueLine(
        listSousInstallationData,
        uniteInstallation.uniteEfficaciteEnergetiqueDeclared,
        classes
      ),
      computeEfficaciteMoyenneLine(
        listSousInstallationData,
        uniteInstallation.uniteEfficaciteEnergetiqueDeclared,
        classes
      ),
      ...[
        computeVariationEfficaciteEnergetiqueLine,
        computeDecisionNecessaireLine,
        computeNonRejetAcLine,
        computeEmptyLine,
        computeAdaptationEffectiveLine,
      ].map(computeLineMethod =>
        computeLineMethod(listSousInstallationData, classes)
      )
    );
  }

  listLinesToReturn.push(
    ...[
      computeEmissionsAttribueesLine,
      computeAllocationFinaleLine,
    ].map(computeLineMethod =>
      computeLineMethod(listSousInstallationData, classes)
    )
  );

  return listLinesToReturn;
};
