import * as Yup from "yup";
import {
  commonPercentageFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";

export const ISDNDControllerValidationSchema = Yup.object().shape({
  volumeBiogazExported: commonPositiveNumberFields,
  ch4Content: commonPercentageFields,
});
