//TODO : populate a bit this folder, but a lot of import path would change... https://dl.polyconseil.fr/jira/browse/GEREP-658
import { useCallback, useState } from "react";

interface CommonModaleEntityRelatedState<EntityInModale> {
  isOpen: boolean;
  elementToEdit: EntityInModale | null;
}

export interface ModaleEntityHandler<EntityInModale> {
  modaleState: CommonModaleEntityRelatedState<EntityInModale>;
  openModale: (elem: EntityInModale | null) => void;
  closeModale: () => void;
}

//This hook will return a standalone modaleState, as well as methods to open and close the modale. The open method expect a param representing the elem to put in modale
export function useEntityModale<EntityInModale>(): ModaleEntityHandler<
  EntityInModale
> {
  const [modaleState, setModaleState] = useState<
    CommonModaleEntityRelatedState<EntityInModale>
  >({
    isOpen: false,
    elementToEdit: null,
  });

  const openModale = useCallback(
    (elemInModale: EntityInModale | null) =>
      setModaleState({ isOpen: true, elementToEdit: elemInModale }),
    [setModaleState]
  );
  const closeModale = useCallback(
    () => setModaleState({ isOpen: false, elementToEdit: null }),
    [setModaleState]
  );

  return { modaleState, openModale, closeModale };
}
