import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import { ReferenceItemOrganismeExterieurPreventionDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export const organismeExterieurLabel: "Organisme extérieur" = "Organisme extérieur" as const;
export const structureFonctionnelleLabel: "Structure fonctionnelle" = "Structure fonctionnelle" as const;

export type OutsideOrganismOrFunctionalStructureType =
  | "Organisme extérieur"
  | "Structure fonctionnelle";

export interface StructureFonctionnelleData extends FormikValues {
  isSeasonalActivity: boolean;
  outsideOrganismOrFunctionalStructure: OutsideOrganismOrFunctionalStructureType;
  outsidePreventionOrganism: ReferenceItemOrganismeExterieurPreventionDto;
  numberVisitsMadeInYear: number | null;
}

export const structureFonctionnelleFieldMatcher = fieldMatcherBuilder<
  StructureFonctionnelleData
>().build({
  isSeasonalActivity: "isSeasonalActivity",
  outsideOrganismOrFunctionalStructure: "outsideOrganismOrFunctionalStructure",
  outsidePreventionOrganism: "outsidePreventionOrganism",
  numberVisitsMadeInYear: "numberVisitsMadeInYear",
});

export const isOrganismExterieur = (
  outsideOrganismOrFunctionalStructure: OutsideOrganismOrFunctionalStructureType | null
): boolean => outsideOrganismOrFunctionalStructure === organismeExterieurLabel;

export const computeOutsideOrganismOrFunctionalStructureLabel = (
  isFunctionalStructure: boolean | null
): OutsideOrganismOrFunctionalStructureType | null => {
  if (isFunctionalStructure === null) {
    return null;
  }
  return isFunctionalStructure
    ? structureFonctionnelleLabel
    : organismeExterieurLabel;
};
