import { useCallback, useEffect, useRef, useState } from "react";

type IsOpen = boolean;
type SetIsOpen = (isOpen: boolean) => void;
type OpenableElementRef<T> = React.MutableRefObject<T | null>;
export function useAutoClosedElement<T extends HTMLElement>(): [
  IsOpen,
  SetIsOpen,
  OpenableElementRef<T>
] {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openableElementRef = useRef<T | null>(null);
  const clickOutSideCallback = useCallback(
    e => {
      if (!openableElementRef.current) {
        return;
      }

      if (
        isOpen &&
        e.target &&
        !openableElementRef.current.contains(e.target) &&
        // CAUTION: this is a quick fix for Select field in element. Because a click event on
        // Select's menu will trigger menu close before click event listener trigger its callback,
        // target element in menu is no longer present in DOM and is therefore fail cndition
        // openableElementRef.current.contains(e.target). We need therefore to prevent closing
        // for click on element no longer in the whole document.
        document.contains(e.target)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen, isOpen, openableElementRef]
  );

  useEffect(() => {
    document.addEventListener("click", clickOutSideCallback);
    return () => {
      document.removeEventListener("click", clickOutSideCallback);
    };
  }, [clickOutSideCallback]);

  return [isOpen, setIsOpen, openableElementRef];
}
