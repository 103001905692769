import * as Yup from "yup";
import {
  commonBooleanFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";

export const validationSchema = Yup.object().shape({
  noiseMeasures: commonBooleanFields,
  noiseMeasuresDone: commonBooleanFields,
  specificNoisesMeasures: commonBooleanFields,
  specificNoisesMeasuresDone: commonBooleanFields,
  noiseMeasuresPointsNumber: Yup.number().when(
    ["noiseMeasures", "noiseMeasuresDone"],
    {
      is: (noiseMeasures: boolean | null, noiseMeasuresDone: boolean | null) =>
        noiseMeasures && noiseMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  noiseMeasuresCampaignNumber: Yup.number().when(
    ["noiseMeasures", "noiseMeasuresDone"],
    {
      is: (noiseMeasures: boolean | null, noiseMeasuresDone: boolean | null) =>
        noiseMeasures && noiseMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  specificNoiseMeasuresPointsNumber: Yup.number().when(
    ["specificNoisesMeasures", "specificNoisesMeasuresDone"],
    {
      is: (
        specificNoiseMeasures: boolean | null,
        specificNoiseMeasuresDone: boolean | null
      ) => specificNoiseMeasures && specificNoiseMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
  specificNoiseMeasuresCampaignNumber: Yup.number().when(
    ["specificNoisesMeasures", "specificNoisesMeasuresDone"],
    {
      is: (
        specificNoiseMeasures: boolean | null,
        specificNoiseMeasuresDone: boolean | null
      ) => specificNoiseMeasures && specificNoiseMeasuresDone,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }
  ),
});
