import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  FormikActions,
  FormikErrors,
} from "libAdapter/Formik/TypesPatternAdaptater";
import { Nullable } from "common/utils/types";

export interface CommonProps {
  disabled: boolean;
  className: string;
  labelWidth: string;
  formPrefix: string;
  commentPath?: string; // TODO : à déplacer dans WrappedFieldProps
}

export const computeId = (name: string, fieldProps: CommonProps): string => {
  return `${fieldProps.formPrefix}-${name}`;
};

/**
 * Make all properties in T optional and a string
 */
export type Errors<T> = { [P in keyof T]?: string };

//inspired by https://github.com/jquense/yup/issues/66#issuecomment-318486137 to avoid strange parsing error of wrong type given to yup.
export const parseToUndefinedIfNull = (cv: any, ov: any): any => {
  return ov === null ? undefined : cv;
};

export const parseToUndefinedIfEmptyString = (cv: any, ov: any): any => {
  return ov === "" ? undefined : cv;
};

export const removeWhiteSpace = (cv: string, ov: string): string => {
  return cv.replace(" ", "");
};

export interface ErrorDataProps {
  errorMessage?: string | FormikErrors<any>;
  errorIcon?: IconProp;
  errorMessageClass?: string;
}

export interface DataWithErrors<T> {
  data: T;
  errors: Errors<T>;
}

export interface IdentifiedData {
  id: string;
}

/**
 * Data for an array entry.
 * A form array is represented by a native JS array of it.
 * An array entry needs :
 * - data : raw data used to generate entry displayed data and errors. This data is expected to have an id being the unique identifier of the array entry
 * - errors : holds errors computed from entry data
 */
type ArrayEntryData<T extends IdentifiedData> = DataWithErrors<T>;

// the following types are for the different page that base themselves on A legacyProps that will be replaced by a backoffice DTO.

/**
 * for the types in the modale, since we're in a modale, all keys can be null
 */
export type InModale<T> = Nullable<T>;

/**
 * to create an ArrayEntryData with a legacy type (therefore, without id and no key nullable.).
 *
 */
export type InArray<T> = ArrayEntryData<InModale<T> & IdentifiedData>;

export type FormComponentProps<ElementType> = {
  closeFunction: () => void;

  onSubmit: (
    values: ElementType,
    formikActions: FormikActions<ElementType>
  ) => void;

  initialObject: ElementType;
  isEdit: boolean;
};
