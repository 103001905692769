import { AuthDroitDtoAuthProfileEnum } from "api/gen";
import { useUserData } from "Authenticator/UserData";
import ModalBienvenue from "common/footer/ModalBienvenue";
import React, { useState } from "react";

const checkAndUpdateIsFirstConnexionMade = () => {
  if (window.localStorage.getItem("isFirstConnexionMade") === null) {
    window.localStorage.setItem("isFirstConnexionMade", "true");
    return true;
  }
  return false;
};

const ShouldOpenModaleBienvenue = (): React.ReactElement => {
  const userRights = useUserData().userInfo.droits[0];
  const [isOpenModalBienvenue, setIsOpenModalBienvenue] = useState(
    checkAndUpdateIsFirstConnexionMade() &&
      userRights.authProfile === AuthDroitDtoAuthProfileEnum.EXPLOITANT
  );

  return (
    <ModalBienvenue
      isOpen={isOpenModalBienvenue}
      onRequestClose={() => setIsOpenModalBienvenue(false)}
    />
  );
};

export default ShouldOpenModaleBienvenue;
