import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  spacedText: {
    "& > p": {
      margin: "25px 0",
    },
  },
});

const AlcVerifieSummary = (): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.spacedText}>
      <p>
        La section ci-dessous reprend certaines informations du fichier ALC
        soumis. Des vérifications automatiques sont effectuées et certaines
        anomalies potentiellement relevées. L'exploitant est amené à justifier
        le remplissage de certaines sections ou à soumettre un nouveau fichier
        suite à l'analyse des erreurs relevées. Il est important d'apporter des
        justifications aux anomalies soulignées par les vérifications
        automatiques, afin d'éviter les questions à leur sujet.
      </p>
      <p>
        Les données extraites, tests et commentaires sont disponibles pour le
        vérificateur et l'inspecteur en charge de la validation du dossier, pour
        permettre une vérification plus complète des informations.
      </p>
      <p>
        Vous devez cliquer sur le bouton "VALIDER" à la fin de cette section
        afin de confirmer la soumission de ce fichier ALC.
      </p>
    </div>
  );
};

export default AlcVerifieSummary;
