import React from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSinglePolluant from "./FormSinglePolluant";
import {
  BlocRejetPolluantOptions,
  PolluantInArray,
  PolluantInModale,
} from "../types";
import {
  isFieldIncludingImportedActive,
  isFieldMethodReferenceActive,
  isPurificationYieldActive,
} from "../utils";
import uuid from "uuid";

interface PolluantModaleProps {
  isOpen: boolean;
  polluantInModal: PolluantInArray | null;
  polluantsInArray: PolluantInArray[];
  setPolluantsInArray: (polluants: PolluantInArray[]) => void;
  closeModal: () => void;
  selectPossibleValues: BlocRejetPolluantOptions;
}

const PolluantModale = ({
  isOpen,
  polluantInModal,
  polluantsInArray,
  setPolluantsInArray,
  closeModal,
  selectPossibleValues,
}: PolluantModaleProps): React.ReactElement => {
  // PolluantsInArray after a new polluant has been added, or an existing polluant has been modified
  const editedPolluantsInArray = (
    elderPolluantsInArray: PolluantInArray[],
    newPolluant: PolluantInModale
  ) => {
    let edited = false;
    // If id already in polluant array, update values
    if (polluantInModal !== null) {
      elderPolluantsInArray = elderPolluantsInArray.map(
        singlePolluantInArray => {
          if (singlePolluantInArray.data.id === polluantInModal.data.id) {
            edited = true;
            return {
              ...singlePolluantInArray,
              data: { ...newPolluant, id: polluantInModal.data.id },
              errors: {}, // Already validated in modal form, no error
            };
          }
          return singlePolluantInArray;
        }
      );
    }
    // Else append new polluant in array
    return edited
      ? elderPolluantsInArray
      : elderPolluantsInArray.concat({
          data: { ...newPolluant, id: uuid() },
          errors: {},
        });
  };

  const submitNewPolluant = (values: PolluantInModale) => {
    const newPolluant: PolluantInModale = {
      ...values,
      includingImported: isFieldIncludingImportedActive(values.type)
        ? values.includingImported
        : null,
      ...(isFieldMethodReferenceActive(values.usedMethod)
        ? {
            methodReference: values.methodReference,
            methodDescription: values.methodDescription,
          }
        : { methodReference: null, methodDescription: null }),
      purificationYield: isPurificationYieldActive(values.type)
        ? values.purificationYield
        : null,
    };
    setPolluantsInArray(editedPolluantsInArray(polluantsInArray, newPolluant));
    closeModal();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout de polluants."
      onRequestClose={closeModal}
    >
      <FormSinglePolluant
        closeFunction={closeModal}
        onSubmit={submitNewPolluant}
        polluantsInArray={polluantsInArray}
        polluantInModal={polluantInModal}
        selectPossibleValues={selectPossibleValues}
      />
    </CustomModal>
  );
};

export default PolluantModale;
