import React from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { LEFT_WITHDRAW_STYLE, SUBTITLE_STYLE } from "theme";
import BlocRappelInstallationsQuotas from "./BlocRappelInstallationsQuotas";
import { ReferentialActiviteQuotasProps } from "./types";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";
import {
  estQuotasCo2Label,
  getArcticle27BisLabel,
  getArticle27Label,
  getIncinerateurLabel,
} from "pages/CompanySpace/from23/toNow/TypeActivite/TypeActiviteQuota/label";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  ...LEFT_WITHDRAW_STYLE,
});

const BlocRappelTypeActivite = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const typeActiviteQuota = declaration.body.typeActivite.quotas;

  const DummyCheckbox = useDummyBooleanCheckboxFieldGenerator({
    disabled: true,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "",
  });

  return (
    <>
      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>
          DONNÉES HÉRITÉES DU BLOC TYPE D'ACTIVITÉS
        </h3>
      </Row>

      <DummyCheckbox
        name="soumisQuotas"
        label={estQuotasCo2Label}
        value={typeActiviteQuota.estSousQuotaCo2}
      />

      <div className={classes.withdrawLeft}>
        <DummyCheckbox
          name="excluArticle27"
          label={getArticle27Label}
          value={typeActiviteQuota.excluArticle27}
        />
        <DummyCheckbox
          name="excluArticle27Bis"
          label={getArcticle27BisLabel}
          value={typeActiviteQuota.excluArticle27Bis}
        />
        <DummyCheckbox
          name="incinerateur"
          label={getIncinerateurLabel}
          value={typeActiviteQuota.incinerateur}
        />
      </div>

      <Row />

      <BlocRappelInstallationsQuotas
        referentialActivites={referentialActivites}
        installations={typeActiviteQuota.installations}
      />
    </>
  );
};

export default BlocRappelTypeActivite;
