import React, { ReactElement } from "react";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import TypeActiviteSubDashboard from "./Components/SubDashboard/TypeActiviteSubDashboard";
import DeclarationQuotasSubDashboard from "./Components/SubDashboard/DeclarationQuotasSubDashboard";

const DashboardQuotas = ({ match }: RouteIdProps): ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <>
      <TypeActiviteSubDashboard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />

      <DeclarationQuotasSubDashboard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
    </>
  );
};

export default DashboardQuotas;
