import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK, SECTION_TITLE_GREY } from "theme";
import ModalTextes from "./ModalTextes";
import ModalGuides from "./ModalGuides";
import ModalFaq from "./ModalFaq";
import ModalSupport from "./ModalSupport";
import ModalMentionsLegales from "./ModalMentionsLegales";
import ModalBienvenue from "./ModalBienvenue";

const useStyles = makeStyles({
  footerLink: {
    display: "flex",
    justifyContent: "center",
  },
  ul: {
    listStyle: "none",
    display: "inline",
    "& li::before": {
      content: `'•'`,
      color: PINK,
      display: "inline-block",
      width: "1em",
      marginLeft: "-1em",
    },
    "& li": {
      fontSize: "smaller",
      display: "inline",
      textTransform: "uppercase",
      marginRight: "2.5em",
      color: SECTION_TITLE_GREY,
      cursor: "pointer",
    },
  },
});

const FooterHelp = (): React.ReactElement => {
  const classes = useStyles();

  const [isModalTextesOpen, setIsModalTextesOpen] = useState<boolean>(false);
  const [isModalGuidesOpen, setIsModalGuidesOpen] = useState<boolean>(false);
  const [isModalFaqOpen, setIsModalFaqOpen] = useState<boolean>(false);
  const [isModalSupportOpen, setIsModalSupportOpen] = useState<boolean>(false);
  const [isModalMentionsLegalesOpen, setIsModalMentionsLegalesOpen] = useState<
    boolean
  >(false);
  const [isModalBienvenueOpen, setIsModalBienvenueOpen] = useState<boolean>(
    false
  );

  const references = [
    {
      label: "Textes de référence",
      onClick: () => setIsModalTextesOpen(true),
    },
    {
      label: "Informations",
      onClick: () => setIsModalBienvenueOpen(true),
    },
    {
      label: "Guides",
      onClick: () => setIsModalGuidesOpen(true),
    },
    {
      label: "FAQ",
      onClick: () => setIsModalFaqOpen(true),
    },
    {
      label: "Support",
      onClick: () => setIsModalSupportOpen(true),
    },
    {
      label: "Mentions légales",
      onClick: () => setIsModalMentionsLegalesOpen(true),
    },
  ];

  return (
    <>
      <div className={classes.footerLink}>
        <ul className={classes.ul}>
          {references.map((pathLink, index) => {
            return (
              <li key={index} onClick={pathLink.onClick}>
                {pathLink.label}
              </li>
            );
          })}
        </ul>
      </div>

      <ModalTextes
        isOpen={isModalTextesOpen}
        onRequestClose={() => setIsModalTextesOpen(false)}
      />

      <ModalBienvenue
        isOpen={isModalBienvenueOpen}
        onRequestClose={() => setIsModalBienvenueOpen(false)}
      />

      <ModalGuides
        isOpen={isModalGuidesOpen}
        onRequestClose={() => setIsModalGuidesOpen(false)}
      />

      <ModalFaq
        isOpen={isModalFaqOpen}
        onRequestClose={() => setIsModalFaqOpen(false)}
      />

      <ModalSupport
        isOpen={isModalSupportOpen}
        onRequestClose={() => setIsModalSupportOpen(false)}
      />

      <ModalMentionsLegales
        isOpen={isModalMentionsLegalesOpen}
        onRequestClose={() => setIsModalMentionsLegalesOpen(false)}
      />
    </>
  );
};

export default FooterHelp;
