import { WorkflowTarget } from "common/utils/types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const SUBMITTED = "SUBMITTED";
export const RECALLED = "RECALLED";
export const FORCED = "FORCED";
export const VALIDATED = "VALIDATED";
export const IN_REVIEW = "IN_REVIEW";
export const STARTED = "STARTED";
export const CREATED = "CREATED";
export const VERIFIED = "VERIFIED";
export const VERIFYING = "VERIFYING";

export interface StateMessage {
  SUBMITTED: string;
  RECALLED: string;
  FORCED: string;
  VALIDATED: string;
  IN_REVIEW: string;
  STARTED: string;
  CREATED: string;
  NONE: string;
  VERIFIED: string;
  VERIFYING: string;
}

export const stateMessages: StateMessage = {
  SUBMITTED: "En attente de validation par l'inspection",
  RECALLED: "Demande de retour en attente",
  FORCED: "Déclaration d'office (saisie par l'inspection)",
  VALIDATED: "Déclaration validée par l'inspection",
  IN_REVIEW: "Déclaration mise en révision par l'inspection",
  STARTED: "Saisie exploitant",
  CREATED:
    "En attente de la validation de la section « Type d'activité » par l'inspection",
  VERIFIED: "Déclaration vérifiée par le prestataire",
  VERIFYING: "Déclaration en cours de vérification par le prestataire",
  NONE: "",
};

export const generateDeletionWarningMessage = (): string =>
  "Êtes vous sûr de vouloir poursuivre cette action ?" +
  " L'établissement et toutes les déclarations qui lui sont liées seront définitivement supprimés.";

export const generateDeclarationTransitionWarningMessage = (
  workflowTarget: WorkflowTarget,
  arrivalState: keyof StateMessage
): string => {
  let workflowLabel: string;
  switch (workflowTarget) {
    case WorkflowTarget.GLOBAL:
      workflowLabel = "GEREP";
      break;
    case WorkflowTarget.QUOTA_ALLOCATIONS:
      workflowLabel = "quota niveaux d'activité";
      break;
    case WorkflowTarget.QUOTA_EMISSIONS:
      workflowLabel = "quota émissions";
      break;
    default:
      throw new ShouldNotHappen(workflowTarget);
  }

  return (
    "Êtes vous sûr de vouloir poursuivre cette action ?" +
    ` La déclaration ${workflowLabel} passera au statut ${stateMessages[arrivalState]}`
  );
};

export const generateTransitionWarningMessage = (
  arrivalState: keyof StateMessage
): string => {
  return generateDeclarationTransitionWarningMessage(
    WorkflowTarget.GLOBAL,
    arrivalState
  );
};
