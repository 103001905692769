import React, { ReactChild, ReactNode } from "react";
import FieldWrapper from "./FieldWrapper";
import BasicLabel from "../label/BasicLabel";
import { CommonProps } from "../../utils";
import { UnwrappedFieldProps } from "../types/basicTypes";

interface FieldWrapperWithTextLabelProps {
  label: string | ((classname: string) => ReactChild);
  name: string;
  render: (props: UnwrappedFieldProps, id: string) => React.ReactElement;
  commonProps: CommonProps;
  disabled?: boolean;
  hideLabelComponentIfEmptyText?: boolean;
  tooltipContent?: ReactNode;
}

const FieldWrapperWithTextLabel = ({
  label,
  name,
  render,
  commonProps,
  disabled,
  hideLabelComponentIfEmptyText,
  tooltipContent,
}: FieldWrapperWithTextLabelProps): React.ReactElement => {
  return (
    <FieldWrapper
      renderLabel={id => (
        <BasicLabel
          content={label}
          htmlFor={id}
          width={commonProps.labelWidth}
          disabled={disabled}
          hideIfEmpty={hideLabelComponentIfEmptyText}
          tooltipContent={tooltipContent}
        />
      )}
      name={name}
      renderField={render}
      commonProps={commonProps}
    />
  );
};

export default FieldWrapperWithTextLabel;
