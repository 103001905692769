import React, { ReactElement } from "react";
import { Redirect } from "react-router";
import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import { generateDeletionWarningMessage } from "common/messages/dashboardMessage";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { makeStyles } from "@material-ui/styles";
import { DASHBOARD_BACKGROUND_GREY } from "theme";
import { useDeleteEtablissement } from "./Inspecteur/hooks";
import classNames from "classnames";

const useStyles = makeStyles({
  deleteEtablissementButton: {
    background: DASHBOARD_BACKGROUND_GREY,
    color: "black",
  },
});

const DeleteEtablissementButton = ({
  etablissementCode,
  additionalClassName,
}: {
  etablissementCode: string;
  additionalClassName: string;
}): ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const { deleteEtablissement, shouldRedirect } = useDeleteEtablissement();

  if (shouldRedirect) {
    return <Redirect to={"/inspecteur/declarations"} />;
  }

  return (
    <Button
      onClick={() =>
        openConfirmationModale(generateDeletionWarningMessage(), () =>
          triggerSpinner(deleteEtablissement(etablissementCode))
        )
      }
      text="Effacer l'etablissement"
      isSpinnerVisible={isSpinnerVisible}
      additionalClassname={classNames(
        classes.deleteEtablissementButton,
        additionalClassName
      )}
    />
  );
};

export default DeleteEtablissementButton;
