import React, { useMemo } from "react";
import { SHORT_TEXT_INPUT_WIDTH } from "theme";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import {
  ActivityValues,
  CoordonneesDepartementMap,
  getUnitsEnumLabel,
  units,
} from "../utils/types";
import { LocationHelper } from "./LocationHelper";
import { ReferenceItemCodeApeDto, ReferenceItemGeoCodeInseeDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import FormikBlocFullContext2020 from "../../versionedElements/FormikBlocFullContext2020";
import { Declaration2020 } from "../../versionedElements/declarationHooks2020";
import { getAssociatedSystemCoordinates } from "../utils/systemCoordinatesUtils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { companyActivityValidationSchema } from "./validation";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  additionalInfo: {
    height: "100px",
  },
});

interface CompanyActivityProps
  extends CommonConnectedFormikBlocProps<ActivityValues, Declaration2020> {
  referentielINSEE: ReferenceItemGeoCodeInseeDto[];
  referentielAPE: ReferenceItemCodeApeDto[];
  coordonneesDepartementMap: CoordonneesDepartementMap;
}

export const CompanyActivity = (
  props: CompanyActivityProps
): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-activity",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);

  const locationHelper = useMemo(() => {
    return <LocationHelper />;
  }, []);

  return (
    <FormikBlocFullContext2020
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      setHasChanges={props.setHasChanges}
      initialValues={props.initialValues}
      validationSchema={companyActivityValidationSchema}
      title={"INFORMATIONS RELATIVES A L'ÉTABLISSEMENT"}
      pathToValidate={props.path}
      areGlobalCommentsAllowed={true}
      updateHandler={props.updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <TextField
              name="inspectionCode"
              label="Code inspection *"
              disabled={true}
            />
            <TextField
              name="establishmentName"
              label="Nom de l'établissement *"
              disabled={shouldDisabledFields}
            />
            <TextField
              name="installationAdress"
              label="Adresse du site *"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"installationCity"}
              label={"Commune *"}
              disabled={shouldDisabledFields}
              title="Commune"
              header={["Code INSEE", "Code postal", "Commune", "Département"]}
              linesData={props.referentielINSEE}
              formatLine={insee => [
                insee.codeInsee,
                insee.codePostal,
                insee.nomCommune,
                insee.numeroDepartement.toString(),
              ]}
              formatSelectedTitle={lineData => lineData.nomCommune}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData.codeInsee,
                    lineData.codePostal,
                    lineData.nomCommune,
                    lineData.numeroDepartement,
                  ],
                  searchedStr
                )
              }
              preferredColWidths={[100, 100, 250, 80]}
              commonProps={commonProps}
              onSelect={data => {
                formProps.setFieldValue(
                  "installationPostalCode",
                  data ? data.codePostal : data
                );
              }}
            />
            <TextField
              name="installationPostalCode"
              label="Code postal *"
              disabled={shouldDisabledFields}
            />
            <TextField
              name="SIRETNumber"
              label="Numéro SIRET *"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"NAFCode"}
              label={"Code NAF *"}
              disabled={shouldDisabledFields}
              title="Code NAF"
              header={["Code NAF", "Libellé"]}
              linesData={props.referentielAPE}
              formatLine={ape => [ape.codeApe, ape.libelle]}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.codeApe, lineData.libelle],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData.codeApe}
              preferredColWidths={[100, 300]}
              commonProps={commonProps}
            />
            <DummyTextField
              name="principalActivity"
              label="Activité principale"
              value={
                formProps.values.NAFCode && formProps.values.NAFCode.libelle
              }
              disabled={true}
            />

            <Row />

            <WrappedChoiceSelect
              name="systemCoordinates"
              label="Système de coordonnées géographiques *"
              isMulti={false}
              options={getAssociatedSystemCoordinates(
                formProps.values,
                props.coordonneesDepartementMap
              )}
              disabled={shouldDisabledFields}
              computeLabel={coordonnees =>
                coordonnees.systemeCoordonnees.toString()
              }
              commonProps={commonProps}
            />
            <TextField
              name="longitude"
              label="Abscisse/Longitude/X *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              tooltipContent={locationHelper}
            />
            <TextField
              name="latitude"
              label="Ordonnée/Latitude/Y *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              tooltipContent={locationHelper}
            />

            <Row />

            <NumberField
              name="productionVolume"
              label="Volume de production"
              unit=""
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelect
              name="unit"
              label="Unité"
              isMulti={false}
              options={units}
              disabled={shouldDisabledFields}
              computeLabel={getUnitsEnumLabel}
              commonProps={commonProps}
            />
            <TextField
              name="producedMatter"
              label="Matière produite/Type de produits"
              disabled={shouldDisabledFields}
            />
            <NumberField
              name="workDuration"
              label="Nombre d'heures d'exploitation au cours de l'année"
              unit="heures"
              disabled={shouldDisabledFields}
            />
            <NumberField
              name="employee"
              label="Nombre d'employés"
              unit=""
              disabled={shouldDisabledFields}
            />
            <TextField
              name="website"
              label="Adresse du site internet"
              disabled={shouldDisabledFields}
              tooltipContent={""}
            />
            <TextArea
              name="additionalInfo"
              label="Informations complémentaires / remarques"
              disabled={shouldDisabledFields}
              additionalClassName={classes.additionalInfo}
            />
          </>
        );
      }}
    />
  );
};
