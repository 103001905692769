import { PopulatedSubstanceExtractionInArray } from "./types";
import _ from "lodash";

export const computeUniquesSubstances = (
  substances: PopulatedSubstanceExtractionInArray[]
): PopulatedSubstanceExtractionInArray[] => {
  const uniquesSubstances: PopulatedSubstanceExtractionInArray[] = [];
  substances.forEach(substanceInArray => {
    if (
      !uniquesSubstances.some(s =>
        _.isEqual(
          s.displayedSubstance.substance,
          substanceInArray.displayedSubstance.substance
        )
      )
    ) {
      uniquesSubstances.push(substanceInArray);
    }
  });
  return uniquesSubstances;
};

export const computeSubstanceTotalAnnualQuantity = (
  substances: PopulatedSubstanceExtractionInArray[],
  substanceToCompute: PopulatedSubstanceExtractionInArray
): number => {
  let total = 0;
  substances
    .filter(substanceInArray =>
      _.isEqual(
        substanceInArray.displayedSubstance.substance,
        substanceToCompute.displayedSubstance.substance
      )
    )
    .forEach(
      substanceInArray =>
        (total += substanceInArray.displayedSubstance.annualQuantity)
    );
  return total;
};

export const computeTotalAnnualQuantity = (
  substances: PopulatedSubstanceExtractionInArray[],
  annualGeneratedQuantity: number
): number => {
  let total = annualGeneratedQuantity;
  substances.forEach(substanceInArray => {
    total += substanceInArray.displayedSubstance.annualQuantity;
  });
  return total;
};
