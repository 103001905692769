import React, { Dispatch, SetStateAction } from "react";
import { AccidentInArray, AccidentInModale } from "../utils";
import CustomModal from "common/presentational/CustomModal";
import FormSingleAccident from "../FormSingleAccident";
import { ModaleEntityHandler } from "common/modale/modaleStates";
import { Omit } from "react-router";
import { addOrModifyArray } from "common/declarant/array/utils";

interface AccidentModaleProps
  extends Omit<ModaleEntityHandler<AccidentInArray>, "openModale"> {
  setAccidentsInArray: Dispatch<SetStateAction<AccidentInArray[]>>;
  mainAccidentCauseList: string[];
}

const AccidentModale = ({
  setAccidentsInArray,
  modaleState,
  closeModale,
  mainAccidentCauseList,
}: AccidentModaleProps) => {
  const onClose = () => {
    closeModale();
  };

  const accidentInModal = modaleState.elementToEdit;

  return (
    <CustomModal
      isOpen={modaleState.isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'accidents."
      onRequestClose={onClose}
    >
      <FormSingleAccident
        closeFunction={onClose}
        isEdit={accidentInModal !== null}
        mainAccidentCauseList={mainAccidentCauseList}
        onSubmit={(
          {
            accidentDate,
            causePrincipale,
            precisionSiAutre,
            isConsolidated,
            nbJoursArretTravail,
          },
          _formikBag
        ) => {
          const newAccident: AccidentInModale = {
            accidentDate,
            causePrincipale,
            precisionSiAutre,
            isConsolidated,
            nbJoursArretTravail,
          };
          addOrModifyArray(
            setAccidentsInArray,
            accidentInModal && accidentInModal.data.id,
            newAccident
          );
          closeModale();
        }}
        initialAccident={
          accidentInModal !== null
            ? accidentInModal.data
            : {
                id: "", //id field not used by formik
                accidentDate: null,
                causePrincipale: null,
                precisionSiAutre: null,
                isConsolidated: null,
                nbJoursArretTravail: null,
              }
        }
      />
    </CustomModal>
  );
};

export default AccidentModale;
