import {
  DeclarationConstraintViolationDto,
  DeclarationDto1819,
  DeclarationUserValidationDto,
  TypeActiviteDto1819,
  TypeActiviteDto2020,
  TypeActiviteGlobalDto21Now,
  TypeActiviteQuotaDto2122,
  TypeActiviteQuotaDto23Now,
} from "api/gen";
import { useCallback } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationHelpers,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  AnyDeclarationSectionDto,
  BasicDeclarationHandlers,
  DeclarationHelpers,
  Progression,
} from "../../../DeclarationApiContext/utils/types";
import { Declaration20Now } from "../../../from20/toNow/versionedElements/declarationHooks20Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2018;
export const MAX_YEAR = undefined;

// Define all the DTO types for this version.
export type Declaration19Now = DeclarationDto1819 | Declaration20Now;
export type DeclarationConstraintViolation19Now = DeclarationConstraintViolationDto;
export type TypeActiviteGlobal19Now =
  | TypeActiviteDto1819
  | TypeActiviteDto2020
  | TypeActiviteGlobalDto21Now;
export type TypeActiviteQuota19Now =
  | TypeActiviteDto1819
  | TypeActiviteDto2020
  | TypeActiviteQuotaDto2122
  | TypeActiviteQuotaDto23Now;

type DeclarationUserValidation19Now = DeclarationUserValidationDto;

export const useDeclaration19Now = (): Declaration19Now => {
  return useDeclaration<Declaration19Now>(MIN_YEAR, MAX_YEAR);
};

export const useBasicDeclarationHandlers19Now = (): BasicDeclarationHandlers<Declaration19Now> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration19Now): AnyDeclarationSectionDto =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration19Now>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR
  );
};

export const useDeclarationHelpers19Now = (): DeclarationHelpers => {
  const computeProgress = useCallback(
    (declarationDto: Declaration19Now): Progression => {
      return declarationDto.computed.progression.progressions.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.path]: item.percentage,
          }),
        {}
      );
    },
    []
  );

  const getActiveSections = useCallback(
    (declarationDto: Declaration19Now): string[] =>
      declarationDto.computed.activeSections,
    []
  );

  const getValidation = useCallback(
    (declarationDto: Declaration19Now): DeclarationUserValidation19Now[] =>
      declarationDto.body.userValidation,
    []
  );

  const getValidationPath = useCallback(
    (validation: DeclarationUserValidation19Now): string =>
      validation.userValidationPath,
    []
  );

  return useDeclarationHelpers<
    Declaration19Now,
    DeclarationUserValidation19Now
  >(
    computeProgress,
    getActiveSections,
    getValidation,
    getValidationPath,
    MIN_YEAR,
    MAX_YEAR
  );
};
