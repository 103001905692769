import React from "react";
import { PopulatedSubstanceExtractionInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import {
  computeFamiliesArrayCells,
  computeFamiliesArrayHeader,
} from "../Families/computers";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_PRODUCTION_EXTRACTION_SUBSTANCES } from "common/path/path18Now";
import { SUBSTANCES_TO_RECYCLE, YEARLY_AMOUNT } from "./utils/languages";
import Row from "common/presentational/Row";

interface SubstanceExtractionArrayProps {
  substancesExtractionInArray: PopulatedSubstanceExtractionInArray[];
  setSubstancesExtractionInArray: (
    subtances: PopulatedSubstanceExtractionInArray[]
  ) => void;
  onEdit: (substance: PopulatedSubstanceExtractionInArray | undefined) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const SubstanceExtractionArray = ({
  substancesExtractionInArray,
  setSubstancesExtractionInArray,
  onEdit,
  isDisabled,
}: SubstanceExtractionArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>{SUBSTANCES_TO_RECYCLE}</p>,
    ...computeFamiliesArrayHeader(),
    <p>{`${YEARLY_AMOUNT} (ktonnes)`}</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = substancesExtractionInArray.map(
    substance => {
      const render = [
        <p
          title={substance.displayedSubstance.substance.label}
          className={isDisabled ? classes.disabledLink : classes.link}
          onClick={() => {
            if (!isDisabled) {
              onEdit(substance);
            }
          }}
        >
          {substance.displayedSubstance.substance.label}
        </p>,
      ];
      render.push(
        ...computeFamiliesArrayCells(
          substance.displayedSubstance.usageFamily,
          substance.displayedSubstance.usageSubFamiliesLvl2,
          substance.displayedSubstance.usageFamilyPrecision,
          substance.displayedSubstance.usageSubFamily,
          substance.displayedSubstance.usageSubFamilyPrecision,
          substance.displayedSubstance.usageSubFamiliesLvl2Precisions
        )
      );
      render.push(
        <p>
          {computeNumberWithSeparator(
            substance.displayedSubstance.annualQuantity
          )}
        </p>
      );
      render.push(
        <CommonEntityButton
          handlerEdit={() => onEdit(substance)}
          handlerSuppress={() =>
            setSubstancesExtractionInArray(
              substancesExtractionInArray.filter(s => s.id !== substance.id)
            )
          }
          commentPath={`${PATH_CARRIERE_PRODUCTION_EXTRACTION_SUBSTANCES}/${substance.id}`}
          isValidated={isDisabled}
        />
      );
      return render;
    }
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredColWidths={[
          250,
          150,
          150,
          175,
          160,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          onClick={() => setSubstancesExtractionInArray([])}
          isReversed
          isDisabled={isDisabled}
        />
      </Row>
    </>
  );
};

export default SubstanceExtractionArray;
