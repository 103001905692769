import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { QuotasVerificationProps } from "./types";

const exemptionConditions = [
  {
    value: 1,
    label: "Condition I",
  },
  {
    value: 2,
    label: "Condition IIa",
  },
  {
    value: 3,
    label: "Condition IIb",
  },
  {
    value: 4,
    label: "Condition III",
  },
  {
    value: 5,
    label: "Condition IV",
  },
];

const verificationReportConclusion: OptionPropsWithObject<
  QuotasVerificationProps
>[] = [
  {
    value: 1,
    label: "Déclaration reconnue satisfaisante",
    object: {
      isReportUnchecked: false,
      isSatisfyingWithRequests: false,
    },
  },
  {
    value: 2,
    label: "Déclaration reconnue satisfaisante avec remarques",
    object: {
      isReportUnchecked: false,
      isSatisfyingWithRequests: true,
    },
  },
  {
    value: 3,
    label: "Déclaration non vérifiée",
    object: {
      isReportUnchecked: true,
      isSatisfyingWithRequests: false,
    },
  },
];

const satisfyingDeclarationReasons = [
  {
    value: 1,
    label: "Inexactitudes non significatives (Erreur < 5 %)",
  },
  {
    value: 2,
    label: "Irrégularités non significatives",
  },
  {
    value: 3,
    label: "Non-conformité au plan de surveillance",
  },
  {
    value: 4,
    label: "Non-respect du règlement MRR",
  },
  {
    value: 5,
    label: "Recommandations d'améliorations",
  },
];

const uncheckedDeclarationReasons = [
  {
    value: 1,
    label: "Inexactitudes significatives non corrigées",
  },
  {
    value: 2,
    label: "Non-conformités significatives",
  },
  {
    value: 3,
    label: "Limitation dans les données",
  },
  {
    value: 4,
    label: "Portée limitée",
  },
  {
    value: 5,
    label: "Plan de surveillance non approuvé par l'autorité compétente",
  },
];

export const selectPossibleValues = {
  exemptionConditions: exemptionConditions,
  verificationReportConclusion: verificationReportConclusion,
  satisfyingDeclarationReasons: satisfyingDeclarationReasons,
  uncheckedDeclarationReasons: uncheckedDeclarationReasons,
};
