import {
  AirFugitivesEmissionBilanDto1819,
  AirFugitivesEmissionCorrelationDto,
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesEmissionMesureDto1819,
  ReferenceItemPolluantDto,
} from "api/gen";
import {
  arrayMethods,
  arrayUnits,
  MethodProcede,
  ObjectUnite,
} from "./selectPossibleValues";
import { computeEmissionAnnuelleCorrelationOnly } from "./calculus";
import {
  getProcedes,
  getProcedesLabels,
  getSingleProcede,
} from "../../utils/utils";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ProcedeInArray } from "../../listprocede/utils/types";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";

//https://stackoverflow.com/a/19326174
export const sortByKey = (
  array: OptionPropsWithObject<ProcedeInArray, string>[] | null,
  key: string
) => {
  if (array) {
    return array.sort((a: any, b: any) => {
      const x = a[key];
      const y = b[key];
      if (!x || !y) {
        return 1;
      }
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }
  return array;
};

const getQantiteMatiereProcede = (
  procedeName: string,
  procedes: ProcedeInArray[]
): number | null => {
  const procede = procedes.find(procede => {
    return procede.data.name === procedeName;
  });
  if (procede) {
    return procede.data.quantity;
  }

  throw Error(
    "this method should be called with a procedeName that is in the Procede array given"
  );
};

const getMasseVolumiqueProcede = (
  procedeName: string,
  procedes: ProcedeInArray[]
): number | null => {
  const procede = procedes.find(procede => {
    return procede.data.name === procedeName;
  });
  if (procede) {
    return procede.data.density;
  }

  throw Error(
    "this method should be called with a procedeName that is in the Procede array given"
  );
};

const getUniteProcedeForCorrelation = (
  procedeName: string,
  procedes: ProcedeInArray[]
): string | null => {
  const procede = procedes.find(procede => {
    return procede.data.name === procedeName;
  });
  if (procede && procede.data.unit) {
    return procede.data.unit.label !== "Autre"
      ? procede.data.unit.label
      : procede.data.unitPrecision;
  }

  throw Error(
    "this method should be called with a procedeName that is in the Procede array given"
  );
};

const getUnitCorrelation = (
  correlation: AirFugitivesEmissionCorrelationDto
): OptionPropsWithObject<ObjectUnite> | null => {
  if (correlation.precision === null) {
    return null;
  }
  const result =
    correlation.unite === null
      ? null
      : findElementMatchingTemplate(
          { object: { code: correlation.unite } },
          arrayUnits
        );
  if (
    result &&
    result.object.code !== AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
  ) {
    return result;
  }
  return {
    value: 7,
    label: correlation.precision,
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE,
      name: correlation.precision,
    },
  };
};

const getSubstanceIdByName = (
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[],
  substanceName: string | null
) => {
  if (substanceName === null) {
    return null;
  }
  const substance = findElementMatchingTemplate(
    { object: { nom: substanceName } },
    substances
  );
  return substance ? substance.object.uuid : null;
};

export const createAirFugitivesEmissionCorrelationDto = (
  correlationEmissionsInPage: CorrelationEmissionInArray[],
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): AirFugitivesEmissionCorrelationDto[] => {
  const correlations = correlationEmissionsInPage.map(
    singlePopulatedEmissionCorrelation => {
      const singleCorrelation = singlePopulatedEmissionCorrelation.data;

      const singleCorrelationDto: AirFugitivesEmissionCorrelationDto = {
        id: singlePopulatedEmissionCorrelation.data.id,
        unite:
          singleCorrelation.uniteCorrelation &&
          singleCorrelation.uniteCorrelation.object.code,
        facteur: singleCorrelation.facteurCorrelation,
        nature: singleCorrelation.natureCorrelation,
        precision:
          singleCorrelation.uniteCorrelation &&
          singleCorrelation.uniteCorrelation.label,
        procede:
          singleCorrelation.procede && singleCorrelation.procede[0].label,
        provenance: singleCorrelation.provenanceFacteurCorrelation,
        substanceID: singleCorrelation.substance
          ? getSubstanceIdByName(substances, singleCorrelation.substance.label)
          : null,
        epuration: !!singleCorrelation.epuration,
        natureEquipement: singleCorrelation.natureEquipement,
        rendement: singleCorrelation.rendementEpuration,
        biomasse: singleCorrelation.biomasse,
      };

      return singleCorrelationDto;
    }
  );
  return correlations;
};

export const convertCorrelationToDisplayed = (
  correlation: AirFugitivesEmissionCorrelationDto,
  procedes: ProcedeInArray[],
  substanceReferential: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): CorrelationEmissionInArray => {
  const correlationSubstance =
    correlation.substanceID === null
      ? null
      : findElementMatchingTemplate(
          { object: { uuid: correlation.substanceID } },
          substanceReferential
        );
  const unitCorrelation = getUnitCorrelation(correlation);
  const singleProcede =
    correlation.procede === null
      ? null
      : getSingleProcede(correlation.procede, procedes);
  const quantiteMatiereProcede =
    correlation.procede === null
      ? null
      : getQantiteMatiereProcede(correlation.procede, procedes);
  const masseVolumiqueProcede =
    correlation.procede === null
      ? null
      : getMasseVolumiqueProcede(correlation.procede, procedes);
  const uniteProcede =
    correlation.procede === null
      ? null
      : getUniteProcedeForCorrelation(correlation.procede, procedes);
  const emissionAnnuelle = computeEmissionAnnuelleCorrelationOnly(
    singleProcede,
    quantiteMatiereProcede,
    masseVolumiqueProcede,
    unitCorrelation,
    correlation.facteur
  );

  const method = findElementMatchingTemplate(
    { object: { value: MethodProcede.FACTEUR_CORRELATION } },
    arrayMethods
  );

  return {
    data: {
      id: correlation.id,
      methods: method,
      substance: correlationSubstance
        ? {
            value: correlationSubstance.object.nom,
            label: correlationSubstance.object.nom,
            object: correlationSubstance.object,
          }
        : null,
      procede: singleProcede,
      emissionAnnuelle: emissionAnnuelle,
      quantiteMatiereProcede: quantiteMatiereProcede,
      uniteProcede: uniteProcede || "",
      natureCorrelation: correlation.nature,
      facteurCorrelation: correlation.facteur,
      uniteCorrelation: unitCorrelation,
      provenanceFacteurCorrelation: correlation.provenance,
      masseVolumiqueProcede: masseVolumiqueProcede,
      epuration: correlation.epuration,
      natureEquipement: correlation.natureEquipement,
      rendementEpuration: correlation.rendement,
      biomasse: correlation.biomasse,
    },
    errors: {},
  };
};

export const createAirFugitivesEmissionMesureDto = (
  mesureEmissionsInPage: MesureEmissionInArray[],
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): AirFugitivesEmissionMesureDto1819[] => {
  const mesure = mesureEmissionsInPage.map(singlePopulatedEmissionMesure => {
    const singleMesure = singlePopulatedEmissionMesure.data;
    const procedesList =
      singleMesure.procede !== null
        ? getProcedesLabels(singleMesure.procede)
        : [];

    const singleMesureDto: AirFugitivesEmissionMesureDto1819 = {
      id: singlePopulatedEmissionMesure.data.id,
      concentration: {
        continu: !!singleMesure.mesureContinueConcentration,
        frequence: singleMesure.frequenceMesureConcentration,
      },
      concentrationMoy: singleMesure.concentrationMoyenne,
      procedes: procedesList,
      epuration: !!singleMesure.epuration,
      heures: singleMesure.heureFonctionnement,
      nature: singleMesure.natureEquipement,
      debit: {
        continu: !!singleMesure.mesureContinueDebit,
        frequence: singleMesure.frequenceMesureDebit,
      },
      debitHoraire: singleMesure.debitHoraire,
      rendement: singleMesure.rendementEpuration,
      substanceID: singleMesure.substance
        ? getSubstanceIdByName(substances, singleMesure.substance.label)
        : null,
      biomasse: singleMesure.biomasse,
    };
    return singleMesureDto;
  });

  return mesure;
};

export const convertMesureToDisplayed = (
  mesure: AirFugitivesEmissionMesureDto1819,
  procedes: ProcedeInArray[],
  substanceReferential: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): MesureEmissionInArray => {
  const mesureSubstance =
    mesure.substanceID === null
      ? null
      : findElementMatchingTemplate(
          { object: { uuid: mesure.substanceID } },
          substanceReferential
        );
  const mesureEmissionAnnuelle =
    mesure.debitHoraire !== null &&
    mesure.heures !== null &&
    mesure.concentrationMoy !== null
      ? mesure.debitHoraire * mesure.heures * mesure.concentrationMoy
      : null;

  const method = findElementMatchingTemplate(
    { object: { value: MethodProcede.MESURE } },
    arrayMethods
  );

  return {
    data: {
      id: mesure.id,
      methods: method,
      substance: mesureSubstance
        ? {
            value: mesureSubstance.object.nom,
            label: mesureSubstance.object.nom,
            object: mesureSubstance.object,
          }
        : null,
      procede: getProcedes(mesure.procedes, procedes),
      emissionAnnuelle: mesureEmissionAnnuelle,
      debitHoraire: mesure.debitHoraire,
      mesureContinueDebit: mesure.debit && mesure.debit.continu,
      frequenceMesureDebit: mesure.debit && mesure.debit.frequence,
      heureFonctionnement: mesure.heures,
      concentrationMoyenne: mesure.concentrationMoy,
      mesureContinueConcentration:
        mesure.concentration && mesure.concentration.continu,
      frequenceMesureConcentration:
        mesure.concentration && mesure.concentration.frequence,
      epuration: mesure.epuration,
      natureEquipement: mesure.nature,
      rendementEpuration: mesure.rendement,
      biomasse: mesure.biomasse,
    },
    errors: {},
  };
};

export const createAirFugitivesEmissionMatiereDto = (
  matiereEmissionsInPage: MatiereEmissionInArray[],
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): AirFugitivesEmissionBilanDto1819[] => {
  const matiere = matiereEmissionsInPage.map(singlePopulatedEmissionMatiere => {
    const singleMatiere = singlePopulatedEmissionMatiere.data;
    const matiereProcedes =
      singleMatiere.procede === null
        ? []
        : getProcedesLabels(singleMatiere.procede);
    const singleMatiereDto: AirFugitivesEmissionBilanDto1819 = {
      id: singlePopulatedEmissionMatiere.data.id,
      element: singleMatiere.elementIndexe,
      entrant: singleMatiere.quantityIn,
      epuration: !!singleMatiere.epuration,
      intrant: singleMatiere.descIntrant,
      nature: singleMatiere.natureEquipement,
      part: singleMatiere.partElement,
      procedes: matiereProcedes,
      rendement: singleMatiere.rendementEpuration,
      sortant: singleMatiere.quantityOut,
      substanceID: singleMatiere.substance
        ? getSubstanceIdByName(substances, singleMatiere.substance.label)
        : null,
      teneur: singleMatiere.teneurMoyenne,
      biomasse: singleMatiere.biomasse,
    };
    return singleMatiereDto;
  });

  return matiere;
};

export const convertMatiereToDisplayed = (
  matiere: AirFugitivesEmissionBilanDto1819,
  procedes: ProcedeInArray[],
  substanceReferential: OptionPropsWithObject<ReferenceItemPolluantDto>[]
): MatiereEmissionInArray => {
  const matiereSubstance =
    matiere.substanceID === null
      ? null
      : findElementMatchingTemplate(
          { object: { uuid: matiere.substanceID } },
          substanceReferential
        );

  const matiereEmissionAnnuelle: number | null =
    matiere.entrant !== null &&
    matiere.sortant !== null &&
    matiere.teneur !== null &&
    matiere.part !== null
      ? (matiere.entrant - matiere.sortant) * (matiere.teneur / matiere.part)
      : null;

  const method = findElementMatchingTemplate(
    { object: { value: MethodProcede.BILAN_MATIERE } },
    arrayMethods
  );

  return {
    data: {
      methods: method,
      substance: matiereSubstance
        ? {
            value: matiereSubstance.object.nom,
            label: matiereSubstance.object.nom,
            object: matiereSubstance.object,
          }
        : null,
      procede: getProcedes(matiere.procedes, procedes),
      emissionAnnuelle: matiereEmissionAnnuelle,
      descIntrant: matiere.intrant,
      quantityIn: matiere.entrant,
      quantityOut: matiere.sortant,
      elementIndexe: matiere.element,
      teneurMoyenne: matiere.teneur,
      partElement: matiere.part,
      epuration: matiere.epuration,
      natureEquipement: matiere.nature,
      rendementEpuration: matiere.rendement,
      id: matiere.id,
      biomasse: matiere.biomasse,
    },
    errors: {},
  };
};
