import * as Yup from "yup";
import {
  commonPercentageFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";

//TODO [GEREP-2579] il faudra remettre le shape | AirFugitivesEmissionBilanIntrantsDto20Now
//   | AirFugitivesEmissionBilanSortantsDto20Now
//   | AirCombustionEmissionBilanSortantsDto20Now
//   | AirCombustionEmissionBilanIntrantsDto20Now ici.
export const intrantSortantValidationSchema = Yup.object().shape({
  quantite: commonPositiveNumberFields,
  descriptif: commonStringFields,
  id: commonStringFields,
  teneurMoyenne: commonPercentageFields,
});
