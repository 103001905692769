import React, { ReactElement } from "react";
import { useUserData } from "Authenticator/UserData";
import { PATH_QUOTAS_EMISSIONS } from "common/path/path20Now";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";

import smogImg from "icons/dashboardLogos/smog.svg";
import { useDeclarationHelpers21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import BigCard from "./BigCard";
import { computeDisabledMessageForProfileForConditionalSection } from "pages/CompanySpace/from21/toNow/Dashboard/Components/utils/disabledMessageUtils";

const QuotaEmissionsCard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = useDeclarationHelpers21Now();

  const { isAnyPrestataire } = useUserData();

  return (
    <BigCard
      text={"Emissions"}
      picture={{ src: smogImg, alt: "" }}
      percentageProgress={
        !isAnyPrestataire ? getPathProgress(PATH_QUOTAS_EMISSIONS) : undefined
      }
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_QUOTAS_EMISSIONS}`}
      isDisabled={!getIsActiveSection(PATH_QUOTAS_EMISSIONS)}
      isValidated={isPathValidatedInDeclaration(PATH_QUOTAS_EMISSIONS)}
      disabledMessage={computeDisabledMessageForProfileForConditionalSection(
        isPathValidatedInDeclaration,
        isAnyPrestataire
      )}
    />
  );
};

export default QuotaEmissionsCard;
