import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";
import { Declaration21Now, MAX_YEAR, MIN_YEAR } from "../declarationHooks21Now";
import { useCallback, useMemo } from "react";
import { FileHandlers } from "common/FilesLoader/types";

export const useDiversFilesHandlers21Now = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadDiversDocumentsUsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteDiversDocumentsUsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifPdsFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadJustificatifsPds21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteJustificatifsPds21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifRapportEmissionsFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadRapportEmissionsJustificatifs21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteRapportEmissionsJustificatifs21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useEmissionsRapportVerificationFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadEmissionsRapportVerificationUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteEmissionsRapportVerificationUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useEmissionsRapportOfficeFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadEmissionsRapportOffice21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteEmissionsRapportOffice21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const usePmsFileHandler21Now = (nimDynId: string): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPms21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePms21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifPmsFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPmsJustificatifs21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePmsJustificatifs21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAlcPreliminaireFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadAlcPreliminaireUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      )) as Declaration21Now;
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteAlcPreliminaireUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      )) as Declaration21Now;
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifAlcPreliminaireFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadJustificatifAlcPreliminaireUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      )) as Declaration21Now;
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteJustificatifAlcPreliminaireUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      )) as Declaration21Now;
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAlcVerifieFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAlcVerifie21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAlcVerifie21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useJustificatifAlcVerifieFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadJustificatifAlcVerifie21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteJustificatifAlcVerifie21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAllocationsRapportVerificationFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAllocationsRapportVerificationUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAllocationsRapportVerificationUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useAllocationsRapportOfficeFileHandler21Now = (
  nimDynId: string
): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadAllocationsRapportOffice21NowUsingPOST(
        annee,
        etablissement,
        nimDynId,
        file
      );
    },
    [declarationFileController, nimDynId]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteAllocationsRapportOffice21NowUsingDELETE(
        annee,
        etablissement,
        file,
        nimDynId
      );
    },
    [declarationFileController, nimDynId]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration21Now>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration21Now>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
