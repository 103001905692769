import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  PINK,
  SECTION_TITLE_GREY,
} from "theme";
import Button from "common/button";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    color: PINK,
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
});

interface ModalSupportProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalSupport = ({
  isOpen,
  onRequestClose,
}: ModalSupportProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={classes.title}>
        <h1>Support</h1>
      </div>
      Pour toute question relative à la déclaration des émissions polluantes,
      vous pouvez contacter le service d'inspection en charge de votre
      établissement.
      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
        isReversed
      />
    </CustomModal>
  );
};

export default ModalSupport;
