import React from "react";
import logoSol from "icons/pageLogos/shovel.svg";
import MainSolForm from "../../to23/Sol/MainSolForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../CommonSpace/AppPage/PageHeaderContext";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

const Sol = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Sol",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoSol,
    alt:
      "logo des émissions de polluants dans le sol, avec une pelle dans un tas de terre",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "sol",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_POLLUANTS_SOL", "REFERENCE_NORMES_SOL"]}
      render={referentials => {
        return (
          <MainSolForm
            referentialPolluants={referentials.REFERENCE_POLLUANTS_SOL}
            referentialNormes={referentials.REFERENCE_NORMES_SOL}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Sol, "GLOBAL");
