import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { backMessageDownloadFile } from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { FileSectionEnum } from "../types";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration } from "pages/CompanySpace/DeclarationApiContext/utils/genericHooks";
import { useCallback } from "react";

export const useAllowedExtensionsHandler = (): ((
  section: FileSectionEnum
) => Promise<string[] | null>) => {
  const { fileController } = useAuthenticatedApi();

  return useCallback(
    async (section: FileSectionEnum): Promise<string[] | null> => {
      try {
        return await fileController.allowedExtensionsUsingGET(section);
      } catch (excp) {
        return null;
      }
    },
    [fileController]
  );
};

export const useDownloadHandler = (
  section: FileSectionEnum
): ((fileDto: FileDto) => Promise<Response | null>) => {
  const { fileController } = useAuthenticatedApi();
  const declaration = useDeclaration();
  const openAlertModale = useAlertModale();

  return useCallback(
    async (fileDto: FileDto): Promise<Response | null> => {
      try {
        return await fileController.downloadUsingGET(
          declaration.annee,
          declaration.etablissementCode,
          fileDto.extension,
          fileDto.id,
          fileDto.name,
          fileDto.type,
          section
        );
      } catch (excp) {
        await openAlertModale(backAlertMessage(excp, backMessageDownloadFile));
        return null;
      }
    },
    [
      fileController,
      declaration.annee,
      declaration.etablissementCode,
      section,
      openAlertModale,
    ]
  );
};
