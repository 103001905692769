import React from "react";
import { makeStyles } from "@material-ui/styles";
import FilesDownloader from "common/FilesDownloader";
import {
  PATH_QUOTAS_ALLOCATIONS_PMS,
  PATH_QUOTAS_ALLOCATIONS_PMS_LIST_PMS_FIELD,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import { FIELD_FONT_WEIGHT, FILE_BUTTONS_STYLE } from "theme";
import PmsLoader from "./PmsLoader";
import JustificatifPmsLoader from "./JustificatifPmsLoader";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";
import { useNiveauxActiviteValidationMessage } from "pages/CompanySpace/from21/toNow/Quotas/utils/utils";
import BlocFullContext24Now from "../../../versionedElements/BlocFullContext24Now";
import NimFilesLoaderDisplayer from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/NimFilesLoaderDisplayer";

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
  bold: {
    fontWeight: FIELD_FONT_WEIGHT,
  },
  marginBottom: {
    marginBottom: "5px",
  },
});

const BlocPlanMethodSurveillance = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const validationMessage = useNiveauxActiviteValidationMessage();
  const installations =
    declaration.computed.sections.quotas.allocations.installations;

  return (
    <BlocFullContext24Now
      title={"plan méthodologique de surveillance"}
      hasModification={false}
      isValidateButtonAvailable={true}
      editValidationMessage={validationMessage}
      path={PATH_QUOTAS_ALLOCATIONS_PMS}
      updateHandler={declaration => {
        return declaration;
      }}
      cancelAction={() => {
        return;
      }}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      renderContent={shouldDisabledFields => (
        <>
          <p>
            ATTENTION : le plan méthodologique de surveillance (PMS) est relatif
            <span className={classes.bold}> aux niveaux d'activité</span>. Le
            plan de surveillance, relatif aux émissions, doit être déposé dans
            la section correspondante du pavé émissions.
          </p>
          <p className={classes.marginBottom}>
            Veuillez déposer dans cette section le PMS en vigueur pour l'année
            de déclaration et pour chacune des installations (NIM) de
            l'établissement (1 par NIM).
          </p>
          <Row>
            <FilesDownloader
              text="TÉLÉCHARGER UN MODÈLE DE PMS"
              fileName="ModèlePMS.xls"
              additionalClassname={classes.fileButtons}
            />
          </Row>
          <Row />

          {installations.map(installation => (
            <NimFilesLoaderDisplayer
              installation={installation}
              commentPath={PATH_QUOTAS_ALLOCATIONS_PMS_LIST_PMS_FIELD}
              justificatifLoader
            >
              <PmsLoader
                installationId={installation.id}
                shouldDisableFields={shouldDisabledFields}
              />
              <JustificatifPmsLoader
                installationId={installation.id}
                shouldDisableFields={shouldDisabledFields}
              />
            </NimFilesLoaderDisplayer>
          ))}
        </>
      )}
    />
  );
};

export default BlocPlanMethodSurveillance;
