import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  additionalValidations,
  epurationSubPartActivated,
  pgsSimpleSubPartActivated,
  singleEmissionOverwriteDependantFields,
  singleEmissionValidationSchemaFunction,
} from "./validation/validation";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  computeConsommationSolvant,
  computeEmissionDiffuse,
  computeEmissionTotale,
  computeUtilisationSolvant,
} from "./utils/calculus";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  getErrorStringValue,
  isSearchStringInCollection,
} from "common/utils/methods";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import MessageInfoField from "common/form/MessageInfoField";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import {
  EmissionsInArray,
  InputEmissionsInModale,
  OutputEmissionsInModale,
} from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { convertInstallationInArraysToInstallationDtos } from "../InstallationBloc/utils/utils";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface FormSingleEmissionProps {
  closeFunction: () => void;

  onSubmit: (
    values: OutputEmissionsInModale,
    formikActions: FormikActions<InputEmissionsInModale>
  ) => void;
  initialEmission: InputEmissionsInModale;
  emissionInModal: EmissionsInArray | null;
  emissionsInArray: EmissionsInArray[];
  installationsInPage: InstallationInArray[];
  isSpecific: boolean;
  isEdit: boolean;
  substances: ReferenceItemPolluantDto[];
  referentialCOVNM: ReferenceItemPolluantDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  title: {
    fontSize: "16px",
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    minHeight: "38px",
    "& > div": {
      justifyContent: "center",
    },
  },
  ...LEFT_WITHDRAW_STYLE,
});

const FormSingleEmission = ({
  closeFunction,
  onSubmit,
  initialEmission,
  installationsInPage,
  isEdit,
  isSpecific,
  substances,
  referentialCOVNM,
}: FormSingleEmissionProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emission-solvants",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE ÉMISSION"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: InputEmissionsInModale, formikBag) => {
        singleEmissionOverwriteDependantFields(values);
        if (!values.pgsSimple) {
          values.emission = computeEmissionDiffuse(values);
        }
        values.emissionTotale = computeEmissionTotale(values);
        values.consommationSolvant = computeConsommationSolvant(values);
        values.utilisationSolvant = computeUtilisationSolvant(values);
        if (!isSpecific) {
          values.substance = referentialCOVNM;
        }
        onSubmit(values, formikBag);
      }}
      preventSubmitWithErrors={values => additionalValidations(values)}
      initialEntity={initialEmission}
      validationSchema={singleEmissionValidationSchemaFunction(isSpecific)}
      renderField={({ values, touched, errors }) => {
        return (
          <>
            {isSpecific ? (
              <WrappedChoiceSelectModale
                name="substance"
                label="Substance *"
                title="COVNM spécifiques"
                header={[
                  "CAS",
                  "Libellé",
                  "Seuil de l'annexe II de l'arrêté du 31 janvier 2008 modifié (kg/an)",
                ]}
                linesData={substances}
                formatLine={substance => [
                  substance.cas || "",
                  substance.nom,
                  substance.seuilKgAn !== null
                    ? computeNumberWithSeparator(substance.seuilKgAn)
                    : "",
                ]}
                formatSelectedTitle={lineData => lineData.nom}
                isLineInSearch={(lineData, searchedStr) =>
                  isSearchStringInCollection(
                    [lineData.cas, lineData.nom],
                    searchedStr
                  )
                }
                commonProps={commonProps}
              />
            ) : (
              <DummyChoiceSelectWithLabel
                name="substance"
                label="Substance *"
                isMulti={false}
                options={[referentialCOVNM]}
                value={referentialCOVNM}
                disabled
                computeLabel={substance => substance.nom}
                commonProps={commonProps}
              />
            )}
            <WrappedChoiceSelect
              isMulti
              name="installations"
              label="Installation(s) *"
              options={convertInstallationInArraysToInstallationDtos(
                installationsInPage
              )}
              computeLabel={installation => installation.nom || ""}
              commonProps={commonProps}
            />
            <BooleanField name="pgsSimple" label="PGS simplifié" />
            <MessageInfoField
              message="Entrées et sorties du plan de gestion de solvants (PGS) en kg"
              additionalClassname={classes.title}
            />
            <NumberField
              name="i1"
              label="I1"
              unit="kg/an"
              tooltipContent="Quantité (kg) de solvants organiques, à l'état pur ou dans des mélanges achetés, qui est utilisée dans les installations pendant la période au cours de laquelle le bilan massique est calculé"
            />
            <NumberField
              name="i2"
              label="I2"
              unit="kg/an"
              tooltipContent="Quantité (kg) de solvants organiques à l'état pur ou dans des mélanges récupérés et réutilisés comme solvants à l'entrée de l'unité (le solvant recyclé est compté chaque fois qu'il est utilisé pour exercer l'activité"
            />
            {pgsSimpleSubPartActivated(values.pgsSimple) && (
              <>
                <NumberField
                  name="o1"
                  label="O1"
                  unit="kg/an"
                  tooltipContent="Émissions (kg) dans les gaz résiduaires"
                />
                <NumberField
                  name="o2"
                  label="O2"
                  unit="kg/an"
                  tooltipContent="Perte (kg) de solvants organiques dans l'eau, compte tenu, le cas échéant, du traitement des eaux résiduaires pour le calcul prévu dans O5"
                />
                <NumberField
                  name="o3"
                  label="O3"
                  unit="kg/an"
                  tooltipContent="Quantité (kg) de solvants organiques qui subsistent sous forme d'impuretés ou de résidus dans les produits issus de l'opération"
                />
                <NumberField
                  name="o4"
                  label="O4"
                  unit="kg/an"
                  tooltipContent="Émissions (kg) non captées de solvants organiques dans l'air. Cela comprend la ventilation générale de locaux qui s'accompagne d'un rejet d'air dans l'environnement extérieur par les fenêtres, les portes, les évents ou des ouvertures similaires"
                />
              </>
            )}
            <NumberField
              name="o5"
              label="O5"
              unit="kg/an"
              tooltipContent="Perte (kg) de solvants organiques et/ou de composés organiques due à des réactions chimiques ou physiques y compris de ceux qui sont détruits, par incinération ou d'autres traitements de gaz et des eaux résiduaires, ou captés, par exemple par absorption, à condition qu'ils ne soient pas comptés dans O5, O7 ou O8"
            />
            <NumberField
              name="o6"
              label="O6"
              unit="kg/an"
              tooltipContent="Solvants organiques (kg) contenus dans les déchets collectés"
            />
            <NumberField
              name="o7"
              label="O7"
              unit="kg/an"
              tooltipContent="Solvants organiques, ou solvants organiques contenus dans des mélanges, (kg) qui sont vendus ou sont destinés à la vente en tant que produits ayant une valeur commerciale"
            />
            <NumberField
              name="o8"
              label="O8"
              unit="kg/an"
              tooltipContent="Solvants organiques (kg) contenus dans des mélanges, récupérés en vue d'une réutilisation, mais non utilisés à l'entrée de l'unité, à condition qu'ils ne soient pas comptés dans O7"
            />

            {pgsSimpleSubPartActivated(values.pgsSimple) && (
              <>
                <NumberField
                  name="o9"
                  label="O9"
                  unit="kg/an"
                  tooltipContent="Solvants organiques (kg) libérés d'une autre manière"
                />
                <DummyNumberField
                  name="emissionDiffuses"
                  label="Émissions diffuses au sens de annexe VII de la directive 2010/75/UE (IED)"
                  value={computeEmissionDiffuse(values)}
                  disabled
                  unit="kg/an"
                  tooltipContent="Émissions diffuses = I1 - O1 - O5 - O6 - O7 - O8"
                  error={
                    (touched.emissionDiffuses &&
                      errors.emissionDiffuses && {
                        text: getErrorStringValue(errors.emissionDiffuses),
                        errorLevel: 1,
                      }) ||
                    undefined
                  }
                />
              </>
            )}
            <DummyNumberField
              name="emissionTotales"
              label="Émissions totales au sens de annexe VII de la directive 2010/75/UE (IED)"
              value={computeEmissionTotale(values)}
              disabled
              unit="kg/an"
              tooltipContent="Émissions totales = I1 - O5 - O6 - O7 - O8"
              error={
                (touched.emissionTotales &&
                  errors.emissionTotales && {
                    text: getErrorStringValue(errors.emissionTotales),
                    errorLevel: 1,
                  }) ||
                undefined
              }
            />
            <DummyNumberField
              name="consommationSolvant"
              label="Consommation de solvants"
              value={computeConsommationSolvant(values)}
              disabled
              unit="kg/an"
              tooltipContent="c = i1 - o8"
            />
            <DummyNumberField
              name="utilisationSolvant"
              label="Utilisation de solvants"
              value={computeUtilisationSolvant(values)}
              disabled
              unit="kg/an"
              tooltipContent="i = i1 + i2"
            />
            <BooleanField
              name="epuration"
              label="Les émissions font l'objet d'épuration"
            />
            {epurationSubPartActivated(values.epuration) && (
              <div className={classes.withdrawLeft}>
                <TextField
                  name="natureEquipement"
                  label="Nature des équipements"
                  tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                />
                <NumberField
                  name="rendement"
                  label="Rendement d'épuration"
                  unit="%"
                  tooltipContent="Le rendement global d'épuration pour tous les équipements est attendu."
                />
                {generateWarningMessageIfUnder1Percent(values.rendement)}
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleEmission;
