import {
  ReferenceAdminControllerApi,
  ReferenceAirControllerApi,
  ReferenceCarriereControllerApi,
  ReferenceDechetControllerApi,
  ReferenceEauControllerApi,
  ReferenceInfosGeneralesControllerApi,
  ReferencePaysControllerApi,
  ReferenceQuotasControllerApi,
  ReferenceSectionControllerApi,
  ReferenceSolControllerApi,
} from "../../../api/gen";
import { ReferentialKeyType, ReferentialsObject } from "./types";

type ReferentialFetchers = {
  [key in ReferentialKeyType]: () => Promise<ReferentialsObject[key]>;
};

export interface ReferentialControllers {
  referenceSectionController: ReferenceSectionControllerApi;
  referenceAirController: ReferenceAirControllerApi;
  referenceEauController: ReferenceEauControllerApi;
  referenceSolController: ReferenceSolControllerApi;
  referenceDechetController: ReferenceDechetControllerApi;
  referenceCarriereController: ReferenceCarriereControllerApi;
  referenceInfoGeneralController: ReferenceInfosGeneralesControllerApi;
  referencePaysController: ReferencePaysControllerApi;
  referenceQuotasController: ReferenceQuotasControllerApi;
  referenceAdminController: ReferenceAdminControllerApi;
}

export const buildReferentialFetchers = (
  {
    referenceSectionController,
    referenceAirController,
    referenceEauController,
    referenceSolController,
    referenceDechetController,
    referenceCarriereController,
    referenceInfoGeneralController,
    referencePaysController,
    referenceQuotasController,
    referenceAdminController,
  }: ReferentialControllers,
  declarationYear: number
): ReferentialFetchers => {
  return {
    // Warning : Swagger doesn't bind the controller's member functions,
    // so we have to wrap the call in a closure to keep the controller in scope
    // cf https://github.com/Polyconseil/mtes-gerep/pull/1188#discussion_r423547032
    REFERENCE_POLLUANTS_AIR: () => {
      return referenceSectionController.getAllPolluantsForCampaignUsingGET(
        declarationYear,
        "AIR"
      );
    },
    REFERENCE_POLLUANTS_SOL: () => {
      return referenceSectionController.getAllPolluantsForCampaignUsingGET(
        declarationYear,
        "SOL"
      );
    },
    REFERENCE_POLLUANTS_EAU: () => {
      return referenceSectionController.getAllPolluantsForCampaignUsingGET(
        declarationYear,
        "EAU"
      );
    },
    REFERENCE_CO2: () => {
      return referenceSectionController.getCo2ForCampaignUsingGET(
        declarationYear,
        "AIR"
      );
    },
    REFERENCE_SOX: () => {
      return referenceSectionController.getSoxForCampaignUsingGET(
        declarationYear,
        "AIR"
      );
    },
    REFERENCE_NOX: () => {
      return referenceSectionController.getNoxForCampaignUsingGET(
        declarationYear,
        "AIR"
      );
    },
    REFERENCE_COVNM: () => {
      return referenceSectionController.getCovnmForCampaignUsingGET(
        declarationYear,
        "AIR"
      );
    },
    REFERENCE_GAZ: () => {
      return referenceAirController.getAllGazForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_NORMES_AIR: () => {
      return referenceAirController.getAllNormesAirForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_NORMES_EAU: () => {
      return referenceEauController.getAllNormesEauForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_NORMES_SOL: () => {
      return referenceSolController.getAllNormesSolForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_DECHET: () => {
      return referenceDechetController.getAllDechetsForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_SUBSTANCE_CARRIERE: () => {
      return referenceCarriereController.getAllSubstanceCarriereForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_COMBUSTIBLE: () => {
      return referenceAirController.getAllCombustiblesForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_CARRIERE_SANTE_ORGANISME_EXTERIEUR_PREVENTION: () => {
      return referenceCarriereController.getAllOrganismeExterieurPreventionForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_CARRIERE_SANTE_CAUSE_ACCIDENT: () => {
      return referenceCarriereController.getCausesAccidentUsingGET(); //  currently not versionned
    },
    REFERENCE_CARRIERE_SANTE_ORGANISME_ACCREDITE_INTERVENANT: () => {
      return referenceCarriereController.getAllOrganismeAccrediteForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_CODE_APE: () => {
      return referenceInfoGeneralController.getAllCodeApeForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_CODE_INSEE_WITH_LOCATION: () => {
      return referenceInfoGeneralController.getAllGeoCodeInseeForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_EPRTR: () => {
      return referenceInfoGeneralController.getAllEprtrForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_PAYS: () => {
      return referencePaysController.getAllPaysDtoForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_SANDRE_EAU: () => {
      return referenceEauController.getAllSandreForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_STATIONS_EPURATION: () => {
      return referenceEauController.getAllStationEpurationForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_REGIONS: () => {
      return referenceInfoGeneralController.getAllRegionsForCampaignUsingGET(
        declarationYear
      ); //  currently not versionned
    },
    REFERENCE_DEPARTEMENTS: () => {
      return referenceInfoGeneralController.getAllDepartementsUsingGET(
        declarationYear
      );
    },
    REFERENCE_ACTIVITES_QUOTAS: () => {
      return referenceQuotasController.getAllActiviteQuotaForCampaignUsingGET(
        declarationYear
      ); //  currently not versionned
    },
    REFERENCE_ORGANISMES_INSPECTION_ALLOWED_AT_DECLARATION_CREATION: () => {
      return referenceInfoGeneralController.getOrganismesInspectionAllowedAtDeclarationCreationUsingGET(
        declarationYear
      ); // currently not versionned
    },
    REFERENCE_SERVICES_INSPECTION: () => {
      return referenceInfoGeneralController.getAllServicesInspectionUsingGET(); // currently not versionned
    },
    REFERENCE_EQUIPES_INSPECTION: () => {
      return referenceInfoGeneralController.getAllEquipesInspectionUsingGET(); // currently not versionned
    },
    REFERENCE_FAMILLE_PRODUCTION: () => {
      return referenceCarriereController.getAllFamilleUsageProductionForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_FAMILLE_PRODUCTION_CODE_PRODUIT: () => {
      return referenceCarriereController.getAllFamilleUsageCodeProduitUsingGET();
    },
    REFERENCE_FAMILLE_RECYCLAGE: () => {
      return referenceCarriereController.getAllFamilleUsageRecyclageForCampaignUsingGET(
        declarationYear
      );
    },
    REFERENCE_ADMIN_EMAIL: () => {
      return referenceAdminController.getAdminEmailUsingGET();
    },
    REFERENCE_POLLUANT_ELEMENT: () => {
      return referenceAirController.getAllPolluantElementUsingGET();
    },
    REFERENCE_POLLUANT_DEFAULT_UNITE: () => {
      return referenceAirController.getAllPolluantDefaultUniteUsingGET();
    },
    REFERENCE_COORDONNEES_DEPARTEMENT: () => {
      return referenceInfoGeneralController.getCoordonneesDepartementUsingGET();
    },
    REFERENCE_EPRTR_ELEVAGE: () => {
      return referenceInfoGeneralController.getAllEprtrElevageUsingGET(
        declarationYear
      );
    },
  };
};
