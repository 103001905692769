import { useAdapterFormikContextSetter } from "./FormikContext";
import { useEffect } from "react";

// we need to have an easy way to ensure each field with an externalValue has its name
// saved in the formik context set of strings. This allows to ensure external value don't block validation.
export const useExternalFieldNameEffect = (
  fieldName: string,
  externalValue: unknown
): void => {
  const setExternalFieldsMap = useAdapterFormikContextSetter();

  useEffect(() => {
    if (externalValue !== undefined) {
      setExternalFieldsMap(currentExternalFieldMap => {
        if (fieldName in currentExternalFieldMap) {
          return currentExternalFieldMap;
        }
        return new Map(currentExternalFieldMap).set(fieldName, externalValue);
      });
    }
  }, [externalValue, fieldName, setExternalFieldsMap]);
};
