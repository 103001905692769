import React, { ReactElement } from "react";
import { IN_REVIEW, STARTED, VERIFIED } from "common/messages/dashboardMessage";
import ActionButtonsContainer from "../../../toNow/Dashboard/Components/Container/ActionButtonsContainer";
import QuotaValidateButton from "../Quotas/QuotaValidateButton";
import QuotaSubmitButton from "../../../toNow/Actions/Quotas/QuotaSubmitButton";
import { QuotasActionButtonsProps } from "../../../toNow/Actions/types";

const DeclarantQuotasActionButtons = ({
  quotaState,
  workflowTarget,
  path,
}: QuotasActionButtonsProps): ReactElement => {
  return (
    <ActionButtonsContainer>
      {quotaState.isDeclarationStateContaining([STARTED, IN_REVIEW]) && (
        <QuotaValidateButton workflowTarget={workflowTarget} path={path} />
      )}
      {quotaState.isDeclarationStateContaining([VERIFIED]) && (
        <QuotaSubmitButton workflowTarget={workflowTarget} />
      )}
    </ActionButtonsContainer>
  );
};

export default DeclarantQuotasActionButtons;
