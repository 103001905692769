import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { EmissionsTotalesCO2eDto } from "api/gen";
import { EMPHASIZED_LAST_HEADER_CELL } from "common/presentational/CommonEntityTable/cssRules";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import {
  computeAnneeLine,
  computeEmissionsTotalesCo2eLine,
  computePreferredColWidths,
} from "./tableLineComputer";
import { computeTableWidth } from "theme";
import { sortObjectKeysAndValues } from "common/utils/methods";

const useStyles = makeStyles({
  container: {
    margin: "30px 0",
  },
  table: (object: Record<string, unknown>) => ({
    ...EMPHASIZED_LAST_HEADER_CELL,
    width: computeTableWidth(object),
  }),
  full: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    marginLeft: "10px",
  },
});

interface AlcVerifieEmissionsCo2eProps {
  yearToEmissionsTotalesCO2eMap: {
    [key: string]: EmissionsTotalesCO2eDto;
  };
}

const AlcVerifieEmissionsCo2e = ({
  yearToEmissionsTotalesCO2eMap,
}: AlcVerifieEmissionsCo2eProps): ReactElement => {
  const classes = useStyles(yearToEmissionsTotalesCO2eMap);

  const {
    sortedKeys,
    sortedValues: sortedEmissionsList,
  } = sortObjectKeysAndValues(yearToEmissionsTotalesCO2eMap);

  return (
    <div className={classes.container}>
      <ArrayTitle>Emissions totales de CO2e (extraction ALC)</ArrayTitle>

      <CommonEntityTable
        header={computeAnneeLine(sortedKeys, classes)}
        lines={[computeEmissionsTotalesCo2eLine(sortedEmissionsList, classes)]}
        preferredColWidths={computePreferredColWidths(sortedEmissionsList)}
        isFirstColSticky={false}
        isLastColSticky={false}
        additionalClassName={classes.table}
      />
    </div>
  );
};

export default AlcVerifieEmissionsCo2e;
