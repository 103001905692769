import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { AdministrationValues } from "../utils/types";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import { ReferenceItemGeoCodeInseeDto, ReferenceItemPaysDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import {
  computeFrenchCityWithReferential,
  isCountryFrance,
} from "../utils/mainSubmitHandler";
import FormikBlocFullContext2020 from "../../versionedElements/FormikBlocFullContext2020";
import { Declaration2020 } from "../../versionedElements/declarationHooks2020";
import { companyIdentityValidation } from "./validation";

//  to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface CompanyIdentityProps
  extends CommonConnectedFormikBlocProps<
    AdministrationValues,
    Declaration2020
  > {
  referentielINSEE: ReferenceItemGeoCodeInseeDto[];
  referentielPays: ReferenceItemPaysDto[];
}

export const CompanyIdentity = ({
  formikRef,
  hasChanges,
  setHasChanges,
  initialValues,
  path,
  updateHandler,
  referentielINSEE,
  referentielPays,
}: CompanyIdentityProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-identity",
  };

  const TextField = useTextFieldGenerator(commonProps);

  return (
    <FormikBlocFullContext2020
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      additionalFieldValidation={companyIdentityValidation}
      title={"identité de l'entreprise"}
      pathToValidate={path}
      areGlobalCommentsAllowed={true}
      updateHandler={updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <TextField
              label="Raison sociale *"
              name="socialReason"
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Société mère"
              name="parentCompany"
              disabled={shouldDisabledFields}
            />
            <TextField
              label="Forme juridique *"
              name="legalStatus"
              disabled={shouldDisabledFields}
              tooltipContent={"EARL, GAEC, SA, SARL, SAS, etc."}
            />
            <TextField
              label="Numéro SIREN"
              name="SIRENNumber"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"country"}
              label={"Pays *"}
              disabled={shouldDisabledFields}
              title={""}
              header={["Pays"]}
              linesData={referentielPays}
              formatLine={option => [option.designation]}
              commonProps={commonProps}
              isFirstSticky={false}
              formatSelectedTitle={lineData => lineData.designation}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection([lineData.designation], searchedStr)
              }
              onSelect={newValue => {
                if (newValue) {
                  if (
                    formProps.values.country &&
                    isCountryFrance(formProps.values.country.designation) &&
                    !isCountryFrance(newValue.designation) &&
                    typeof formProps.values.city !== "string"
                  ) {
                    formProps.setFieldValue(
                      "city",
                      formProps.values.city
                        ? formProps.values.city.nomCommune
                        : null
                    );
                  }
                  if (
                    formProps.values.country &&
                    !isCountryFrance(formProps.values.country.designation) &&
                    isCountryFrance(newValue.designation) &&
                    (typeof formProps.values.city === "string" ||
                      formProps.values.city === null)
                  ) {
                    formProps.setFieldValue(
                      "city",
                      computeFrenchCityWithReferential(
                        formProps.values.city,
                        referentielINSEE
                      )
                    );
                  }
                } else {
                  formProps.setFieldValue("city", null);
                }
              }}
            />
            {formProps.values.country ? (
              isCountryFrance(formProps.values.country.designation) ? (
                <>
                  <TextField
                    label="Adresse *"
                    name="adress"
                    disabled={shouldDisabledFields}
                  />
                  <WrappedChoiceSelectModale
                    name={"city"}
                    label={"Commune *"}
                    disabled={shouldDisabledFields}
                    title="Commune"
                    header={[
                      "Code INSEE",
                      "Code postal",
                      "Commune",
                      "Département",
                    ]}
                    linesData={referentielINSEE}
                    formatLine={insee => [
                      insee.codeInsee,
                      insee.codePostal,
                      insee.nomCommune,
                      insee.numeroDepartement,
                    ]}
                    formatSelectedTitle={(
                      lineData: ReferenceItemGeoCodeInseeDto
                    ) => {
                      return lineData.nomCommune;
                    }}
                    isFirstSticky={false}
                    isLineInSearch={(lineData, searchedStr) =>
                      isSearchStringInCollection(
                        [
                          lineData.codeInsee,
                          lineData.codePostal,
                          lineData.nomCommune,
                          lineData.numeroDepartement,
                        ],
                        searchedStr
                      )
                    }
                    preferredColWidths={[100, 100, 250, 80]}
                    onSelect={data => {
                      formProps.setFieldValue(
                        "postalCode",
                        data ? data.codePostal : data
                      );
                    }}
                    commonProps={commonProps}
                  />
                  <TextField
                    label="Code postal *"
                    name="postalCode"
                    disabled={shouldDisabledFields}
                  />
                </>
              ) : (
                <>
                  <TextField
                    label="Adresse *"
                    name="adress"
                    disabled={shouldDisabledFields}
                  />
                  <TextField
                    name="city"
                    label="Commune *"
                    disabled={shouldDisabledFields}
                    tooltipContent={
                      "Saisir le nom de la commune en mettant des tirets (-) entre les mots pour une commune à noms composés et sans saisir l’article par lequel commence éventuellement le nom de la commune. Exemple pour « Les Sables d’Olonne », écrire « Sables- d’Olonne (les)», ou pour « Le Havre » écrire « Havre (Le) »"
                    }
                  />
                  <TextField
                    label="Code postal *"
                    name="postalCode"
                    disabled={shouldDisabledFields}
                  />
                </>
              )
            ) : (
              <></>
            )}
          </>
        );
      }}
    />
  );
};
