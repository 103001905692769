import * as Yup from "yup";
import {
  commonObjectFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { BlocEmissionsFormValues } from "./types";
import {
  quotasFluxNamesMessage,
  quotasPointMesureNamesMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  flowDeclarations: Yup.array().of(
    Yup.object().shape({
      name: commonStringFields,
      nimInstallation: commonObjectFields,
    })
  ),
  measureDeclarations: Yup.array().of(
    Yup.object().shape({
      name: commonStringFields,
      nimInstallation: commonObjectFields,
    })
  ),
});

export const additionalValidation = ({
  measureDeclarations,
  flowDeclarations,
  exemption,
  exemptedFlows,
}: BlocEmissionsFormValues): { [k: string]: string } => {
  const errors: { [k: string]: string } = {};

  // checking for unique names for measure points and flows

  // this one is handled in additionalFieldValidation to
  // display the error without a submit click because it makes a button disabled

  measureDeclarations.forEach((point, i) =>
    measureDeclarations.forEach((otherPoint, j) => {
      if (otherPoint.name === point.name && otherPoint.id !== point.id) {
        errors[`measureDeclarations.${i}.name`] = quotasPointMesureNamesMessage;
        errors[`measureDeclarations.${j}.name`] = quotasPointMesureNamesMessage;
      }
    })
  );

  flowDeclarations.forEach((flow, i) =>
    flowDeclarations.forEach((otherFlow, j) => {
      if (otherFlow.name === flow.name && otherFlow.id !== flow.id) {
        errors[`flowDeclarations.${i}.name`] = quotasFluxNamesMessage;
        errors[`flowDeclarations.${j}.name`] = quotasFluxNamesMessage;
      }
    })
  );

  if (exemption && exemptedFlows.length === 0) {
    errors["exemptedFlows"] = requiredMessage;
  }

  return errors;
};
