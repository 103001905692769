export const computeDeclarationUrl = (
  codeEtablissement: string | null,
  page: string,
  campaignYear: number | null
): string => {
  if (campaignYear == null) {
    return "/aucune-declaration";
  }
  if (codeEtablissement === null) {
    return "/404";
  }
  const year = Math.max(2019, campaignYear);
  return `/declaration/${year}/${codeEtablissement}/${page}`;
};
