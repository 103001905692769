import { InArray, InModale } from "common/form/utils";
import {
  AirSyntheseEmissionDto,
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";

interface LegacySyntheseProps
  extends Omit<AirSyntheseEmissionDto, "id" | "substanceID" | "normesID"> {
  substance: ReferenceItemPolluantDto;
  normMINT: ReferenceItemNormeAirDto[];
  normMPER: ReferenceItemNormeAirDto[];
  normMNRO: ReferenceItemNormeAirDto[];
  normCINT: ReferenceItemNormeAirDto[];
  totalEmissionsUsualUnit: number;
  emissionsAlreadyDeclared: number;
  totalEmissions: number;
}

export const fieldMatcher = fieldMatcherBuilder<SyntheseInModale>().build({
  substance: "substance",
  emissionsAlreadyDeclared: "emissionsAlreadyDeclared",
  withAccidentalEmissions: "accidentel",
  additionalAccidentalEmissions: "additionnel",
  origin: "origine",
  totalEmissions: "totalEmissions",
  totalEmissionsUsualUnit: "totalEmissionsUsualUnit",
  method: "methode",
  methodReferenceM: "referenceMesure",
  methodReferenceC: "referenceCalcule",
  description: "description",
  normMINT: "normMINT",
  normMPER: "normMPER",
  normMNRO: "normMNRO",
  normCINT: "normCINT",
});

export type SyntheseInModale = InModale<LegacySyntheseProps>;

export type SyntheseInArray = InArray<LegacySyntheseProps>;
