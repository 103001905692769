import React from "react";
import MainSyntheseForm from "./MainSyntheseForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import logo from "icons/pageLogos/list.svg";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Synthese = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    { label: "Synthèse", to: match.url },
  ];
  const imageData = {
    src: logo,
    alt: "logo de liste",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Synthèse",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_POLLUANTS_AIR",
        "REFERENCE_NORMES_AIR",
        "REFERENCE_CO2",
      ]}
      render={referentials => {
        return (
          <MainSyntheseForm
            referentialAIR={referentials.REFERENCE_POLLUANTS_AIR}
            referentialNORME={referentials.REFERENCE_NORMES_AIR}
            referentialCO2={referentials.REFERENCE_CO2}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Synthese, "GLOBAL");
