import React from "react";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";

const useStyles = makeStyles({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "100px",
  },
  titre: {
    fontSize: "14rem",
    color: PINK,
  },
  sousTitre: {
    fontSize: "2rem",
  },
});

interface ErrorPageProps {
  code: number | null;
  text: string;
}

const ErrorPage = ({ code, text }: ErrorPageProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.content}>
      {code !== null && <h1 className={classes.titre}>{code}</h1>}
      <h2 className={classes.sousTitre}>{text}</h2>
    </div>
  );
};

export default ErrorPage;
