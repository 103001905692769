import { BlocPrelevementEauFormValues24Now } from "./types";
import { EauBlocPrelevementDto23Now, ReferenceSandreDto } from "api/gen/api";
import {
  arraySeaWaterTakingLocationLabel,
  selectPossibleMethods,
} from "./selectPossibleValues";

export const dtoToBlocValues = (
  dto: EauBlocPrelevementDto23Now,
  referentialSandres: ReferenceSandreDto
): BlocPrelevementEauFormValues24Now => {
  return {
    // Full declaration fields
    nbJoursTravailles: dto.nombreJoursTravailles,
    volumeEauSuperficielle: dto.volumeEauxSuperficielles,
    volumeEauSouterraine: dto.volumeEauxSouterraines,
    volumeEauPotable: dto.volumeReseauPotableAEP,
    volumeEauAdduction: dto.volumeReseauAdductionAutreAEP,
    volumeEauMer: dto.volumeMerOcean,
    lieuEauSurface:
      selectPossibleMethods(referentialSandres).surface.find(
        item => item.uuid === dto.codeSandreSurfaceID
      ) || null,
    lieuEauSouterraine:
      selectPossibleMethods(referentialSandres).underground.find(
        item => item.uuid === dto.codeSandreSouterrainID
      ) || null,
    lieuEauMer:
      selectPossibleMethods(referentialSandres).sea.find(
        sea =>
          arraySeaWaterTakingLocationLabel[sea].toLocaleLowerCase() ===
          dto.codeSandreMerOcean?.toLocaleLowerCase()
      ) || null,
    prelevementTotal: dto.prelevementTotal,
  };
};

export const blocValuesToDto = (
  values: BlocPrelevementEauFormValues24Now,
  isDepassementSeuil: boolean
): EauBlocPrelevementDto23Now => {
  return {
    nombreJoursTravailles: values.nbJoursTravailles,
    volumeEauxSuperficielles: values.volumeEauSuperficielle,
    volumeEauxSouterraines: values.volumeEauSouterraine,
    volumeReseauAdductionAutreAEP: values.volumeEauAdduction,
    volumeReseauPotableAEP: values.volumeEauPotable,
    volumeMerOcean: values.volumeEauMer,
    codeSandreSurfaceID: values.lieuEauSurface
      ? values.lieuEauSurface.uuid
      : null,
    codeSandreSouterrainID: values.lieuEauSouterraine
      ? values.lieuEauSouterraine.uuid
      : null,
    codeSandreMerOcean:
      values.lieuEauMer && arraySeaWaterTakingLocationLabel[values.lieuEauMer],
    prelevementTotal: !isDepassementSeuil ? values.prelevementTotal : null,
  };
};
