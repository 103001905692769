import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { Declarants } from "./DeclarantIdentityList/types";
import { ActivityValues, AdministrationValues } from "./utils/types";
import Row from "common/presentational/Row";
import {
  computeActiviteInitialValues,
  computeAdminInitialValues,
  computeDeclarantInitialValues,
  createActivityDto,
  createAdministratifDto,
  createDeclarantsDto,
} from "./utils/mainSubmitHandler";
import { DeclarantIdentityList } from "./DeclarantIdentityList";
import { CompanyIdentity } from "./CompanyIdentity";
import { CompanyActivity } from "./CompanyActivity";
import { CompanyTechnique } from "../../../../CommonSpace/CompanyTechnique/2019_2019";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
  ReferenceCodeApeDto,
  ReferenceEprtrDto,
  ReferenceGeoCodeInseeDto,
  ReferenceItemCodeApeDto,
  ReferenceItemEprtrDto,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ReferencePaysDto,
} from "api/gen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { Nullable } from "common/utils/types";
import { TechniqueValues } from "../../../../CommonSpace/CompanyTechnique/2019_2019/utils";
import { CREATED } from "common/messages/dashboardMessage";
import { useUserData } from "Authenticator/UserData";
import {
  PATH_INFO_GENERALE,
  PATH_INFO_GENERALE_ACTIVITE,
  PATH_INFO_GENERALE_ADMINISTRATIF,
  PATH_INFO_GENERALE_DECLARANT,
} from "common/path/path18Now";
import {
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../versionedElements/declarationHooks1919";
import GlobalFormActionFullContext1919 from "../versionedElements/GlobalFormActionFullContext1919";

const scrollToRef = (ref: React.RefObject<HTMLElement>) => {
  ref.current &&
    window.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
}; // General scroll to element function

interface GeneralInformationsControllerProps {
  inspectionCode: string;
  referentielAPE: ReferenceCodeApeDto;
  referentielINSEE: ReferenceGeoCodeInseeDto;
  referentielEPRTR: ReferenceEprtrDto;
  referentielPays: ReferencePaysDto;
}

export const GeneralInformationsController = ({
  inspectionCode,
  referentielAPE,
  referentielINSEE,
  referentielEPRTR,
  referentielPays,
}: GeneralInformationsControllerProps): React.ReactElement => {
  const declaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const refForScroll = useRef<HTMLDivElement | null>(null);

  const declarationState: DeclarationStateDtoStateEnum =
    declaration.body.workflowStatus.general.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const shouldScrollToTechnique: boolean =
    declarationState === CREATED &&
    (userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
      userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
      userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
      userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN);
  //we set the refs and validation state here so it's all available for whole page validation and save
  const declarantIdentityProps = useFormikBloc<Declarants>();
  const companyIdentityProps = useFormikBloc<Nullable<AdministrationValues>>();
  const companyActivityProps = useFormikBloc<Nullable<ActivityValues>>();
  const companyTechniqueProps = useFormikBloc<TechniqueValues>();

  useLayoutEffect(() => {
    shouldScrollToTechnique && refForScroll && scrollToRef(refForScroll);
  }, [shouldScrollToTechnique, refForScroll]);

  const memoReferentielAPE: OptionPropsWithObject<
    ReferenceItemCodeApeDto
  >[] = useMemo(() => {
    return referentielAPE.codesApe
      .filter(code => code.disponible)
      .map((code, index) => {
        return {
          value: index,
          label: code.codeApe,
          object: code,
        };
      });
  }, [referentielAPE]);
  const memoReferentielINSEE: OptionPropsWithObject<
    ReferenceItemGeoCodeInseeDto
  >[] = useMemo(() => {
    return referentielINSEE.referenceItemGeoCodeInseeDtoList.map(
      (code, index) => {
        return {
          value: index,
          label: code.nomCommune,
          object: code,
        };
      }
    );
  }, [referentielINSEE]);
  const memoReferentielPays: OptionPropsWithObject<ReferenceItemPaysDto | null>[] = useMemo(() => {
    const countriesFromReferentiel = referentielPays.referenceItemPaysDtoList.map(
      (value, index) => {
        return {
          value: index,
          label: value.designation,
          object: value,
        };
      }
    );
    const frenchOption = {
      value: -1,
      label: "FRANCE",
      object: null,
    };
    return [frenchOption, ...countriesFromReferentiel];
  }, [referentielPays]);
  const memoReferentielEPRTR: ReferenceItemEprtrDto[] = useMemo(() => {
    return referentielEPRTR.referenceItemEprtrDtoList.filter(
      value => value.estSelectionnable
    );
  }, [referentielEPRTR]);

  const initialValues = useMemo(() => {
    return {
      declarants: computeDeclarantInitialValues(declaration),
      companyIdentity: computeAdminInitialValues(
        declaration,
        memoReferentielPays,
        memoReferentielINSEE
      ),
      companyActivity: computeActiviteInitialValues(
        declaration,
        inspectionCode,
        memoReferentielINSEE,
        memoReferentielAPE
      ),
    };
  }, [
    declaration,
    inspectionCode,
    memoReferentielAPE,
    memoReferentielINSEE,
    memoReferentielPays,
  ]);

  const hasChanges =
    declarantIdentityProps.hasChanges ||
    companyIdentityProps.hasChanges ||
    companyActivityProps.hasChanges;
  const allBlocksValidated =
    declarationState !== CREATED &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_DECLARANT) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ADMINISTRATIF) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ACTIVITE);

  return (
    <>
      <DeclarantIdentityList
        formikRef={declarantIdentityProps.formikRef}
        hasChanges={declarantIdentityProps.hasChanges}
        setHasChanges={declarantIdentityProps.setHasChanges}
        initialValues={initialValues.declarants}
        path={PATH_INFO_GENERALE_DECLARANT}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.declarants = createDeclarantsDto(
            values.declarants
          );
          return declaration;
        }}
      />

      <Row />

      <CompanyIdentity
        formikRef={companyIdentityProps.formikRef}
        hasChanges={companyIdentityProps.hasChanges}
        setHasChanges={companyIdentityProps.setHasChanges}
        initialValues={initialValues.companyIdentity}
        path={PATH_INFO_GENERALE_ADMINISTRATIF}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
            values
          );
          return declaration;
        }}
        referentielINSEE={memoReferentielINSEE}
        referentielPays={memoReferentielPays}
      />

      <Row />

      <CompanyActivity
        formikRef={companyActivityProps.formikRef}
        hasChanges={companyActivityProps.hasChanges}
        setHasChanges={companyActivityProps.setHasChanges}
        initialValues={initialValues.companyActivity}
        path={PATH_INFO_GENERALE_ACTIVITE}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.activite = createActivityDto(
            values
          );
          return declaration;
        }}
        referentielINSEE={memoReferentielINSEE}
        referentielAPE={memoReferentielAPE}
      />

      <Row />

      <div ref={refForScroll}>
        <CompanyTechnique
          formikRef={companyTechniqueProps.formikRef}
          hasChanges={companyTechniqueProps.hasChanges}
          setHasChanges={companyTechniqueProps.setHasChanges}
          referentielEPRTE={memoReferentielEPRTR}
        />
      </div>

      <GlobalFormActionFullContext1919
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasChanges}
        isValidateEnabled={allBlocksValidated}
        validationPath={PATH_INFO_GENERALE}
        updateHandler={declaration => {
          if (
            declarantIdentityProps.formikRef &&
            declarantIdentityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.declarants = createDeclarantsDto(
              declarantIdentityProps.formikRef.current.state.values.declarants
            );
          }

          if (
            companyIdentityProps.formikRef &&
            companyIdentityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
              companyIdentityProps.formikRef.current.state.values
            );
          }

          if (
            companyActivityProps.formikRef &&
            companyActivityProps.formikRef.current
          ) {
            declaration.body.sections.infoGenerale.activite = createActivityDto(
              companyActivityProps.formikRef.current.state.values
            );
          }

          return declaration;
        }}
        cancelAction={() => {
          declarantIdentityProps.formikRef &&
            declarantIdentityProps.formikRef.current &&
            declarantIdentityProps.formikRef.current.resetForm(
              initialValues.declarants
            );

          companyIdentityProps.formikRef &&
            companyIdentityProps.formikRef.current &&
            companyIdentityProps.formikRef.current.resetForm(
              initialValues.companyIdentity
            );

          companyActivityProps.formikRef &&
            companyActivityProps.formikRef.current &&
            companyActivityProps.formikRef.current.resetForm(
              initialValues.companyActivity
            );
        }}
      />
    </>
  );
};
