import { isNil, omitBy } from "lodash";
import {
  positiveMessage,
  requiredMessage,
  tooBigMessage,
} from "./formikMessages";

export const MAX_NUMBER = 10 ** 31;

export const isBlankString = (s: string | null): boolean => {
  if (!s) {
    return true;
  }
  return s.trim() === "";
};

export const filterOutNullishValues = (
  value: Record<string, unknown>
): Record<string, unknown> => {
  return omitBy(value, isNil);
};

export const computeRequiredPositiveNumberErrorMessage = (
  n: number | null
): string | undefined => {
  if (isNil(n)) {
    return requiredMessage;
  }
  if (n < 0) {
    return positiveMessage;
  }
  if (n >= MAX_NUMBER) {
    return tooBigMessage;
  }
  return undefined;
};

export const computeRequiredStringErrorMessage = (
  s: string | null
): string | undefined => {
  if (isBlankString(s)) {
    return requiredMessage;
  }
  return undefined;
};
