import {
  ActiviteTransformationDto,
  ReferenceItemGeoCodeInseeDto,
} from "api/gen";
import { ReferenceItemPaysDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export const france = "FRANCE";

export interface TransformationInformationsForm {
  transformationActivityDone: boolean;
  errorArrayRequired?: string;
}

export type ActiviteTransformationType = Omit<
  ActiviteTransformationDto,
  "paysID" | "ville"
> & {
  paysID: ReferenceItemPaysDto | null;
  ville: string | ReferenceItemGeoCodeInseeDto | null;
};

export const fieldActiviteTransformationMatcher = fieldMatcherBuilder<
  ActiviteTransformationType
>().build({
  poweredByOtherCareers: "alimenteeParCarrieres",
  zipCode: "codePostal",
  careerRange: "distanceUsineCarriere",
  country: "paysID",
  socialReason: "raisonSocialeUsine",
  city: "ville",
});
