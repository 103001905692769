import React, { createContext, useContext, useEffect, useState } from "react";
import { CampaignDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import Spinner from "common/presentational/Spinner";
import Page404 from "pages/errors/errorPages/Page404";
import { useUserData } from "Authenticator/UserData";
import CampaignDisabled from "pages/CampaignDisabled";
import { CampaignDtoStateGlobalEnum } from "api/gen/api";

export interface CampaignContextProps {
  campaign: CampaignDto;
  setter: (newCampaign: CampaignDto) => void;
}

const ActiveOrLastCampaignContext = createContext<CampaignContextProps | null>(
  null
);
const CurrentDeclarationCampaignContext = createContext<CampaignDto | null>(
  null
);

export const useActiveOrLastCampaign = (): CampaignDto => {
  const context = useContext(ActiveOrLastCampaignContext);
  if (context !== null) {
    return context.campaign;
  } else {
    throw new Error(
      "CampaignContext Error. You probably forgot to put a <CampaignContext.Provider>"
    );
  }
};

export const useActiveOrLastCampaignContext = (): CampaignContextProps => {
  const context = useContext(ActiveOrLastCampaignContext);
  if (context !== null) {
    return context;
  } else {
    throw new Error(
      "CampaignContext Error. You probably forgot to put a <CampaignContext.Provider>"
    );
  }
};

export const useCurrentDeclarationCampaign = (): CampaignDto => {
  const context = useContext(CurrentDeclarationCampaignContext);
  if (context !== null) {
    return context;
  } else {
    throw new Error(
      "CampaignContext Error. You probably forgot to put a <CampaignContext.Provider>"
    );
  }
};

export const ActiveOrLastCampaignContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const { campaignController } = useAuthenticatedApi();

  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<CampaignDto | null>(null);

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const activeOrLastCampaign = await campaignController.getActiveOrLastCampaignUsingGET();
        setCampaign(activeOrLastCampaign);
      } catch {
        setHasFailed(true);
      }
    };

    fetchCampaign();
  }, [setCampaign, campaignController, setHasFailed]);

  if (hasFailed) {
    return <Page404 />;
  } else if (campaign) {
    return (
      <ActiveOrLastCampaignContext.Provider
        value={{
          campaign: campaign,
          setter: setCampaign,
        }}
      >
        {children}
      </ActiveOrLastCampaignContext.Provider>
    );
  } else {
    return <Spinner />;
  }
};

export const CurrentDeclarationCampaignProvider = ({
  children,
  declarationAnnee,
}: {
  children: React.ReactElement;
  declarationAnnee: number;
}): React.ReactElement => {
  const { campaignController } = useAuthenticatedApi();

  const [hasFailed, setHasFailed] = useState<boolean>(false);
  const [campaign, setCampaign] = useState<CampaignDto | null>(null);

  const userData = useUserData();

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        const currentCampaign = await campaignController.findCampaignByIdUsingGET(
          declarationAnnee
        );
        setCampaign(currentCampaign);
      } catch {
        setHasFailed(true);
      }
    };

    fetchCampaign();
  }, [setCampaign, campaignController, declarationAnnee, setHasFailed]);

  // No campaign found
  if (hasFailed) {
    return <CampaignDisabled campaign={null} />;
  }
  // Campaign found but not allowed to current user
  // (nb: while in PLANNED/READY states, global state and quotas state are supposed to be the same,
  // so we only need to check global state)
  if (
    campaign &&
    !userData.isInspecteur &&
    (campaign.stateGlobal === CampaignDtoStateGlobalEnum.PLANNED ||
      campaign.stateGlobal === CampaignDtoStateGlobalEnum.READY)
  ) {
    return <CampaignDisabled campaign={campaign} />;
  }
  // Campaign found and user can access it normally
  if (campaign) {
    return (
      <CurrentDeclarationCampaignContext.Provider value={campaign}>
        {children}
      </CurrentDeclarationCampaignContext.Provider>
    );
  }
  // Waiting...
  return <Spinner />;
};
