import { SubstanceExtractionType } from "./types";
import _ from "lodash";

export const computeUniquesSubstances = (
  substances: SubstanceExtractionType[]
): SubstanceExtractionType[] => {
  const uniquesSubstances: SubstanceExtractionType[] = [];
  substances.forEach(substanceInArray => {
    if (
      !uniquesSubstances.some(s =>
        _.isEqual(s.substance, substanceInArray.substance)
      )
    ) {
      uniquesSubstances.push(substanceInArray);
    }
  });
  return uniquesSubstances;
};

export const computeSubstanceTotalAnnualQuantity = (
  substances: SubstanceExtractionType[],
  substanceToCompute: SubstanceExtractionType
): number => {
  let total = 0;
  substances
    .filter(substanceInArray =>
      _.isEqual(substanceInArray.substance, substanceToCompute.substance)
    )
    .forEach(substanceInArray => (total += substanceInArray.quantite));
  return total;
};

export const computeTotalAnnualQuantity = (
  substances: SubstanceExtractionType[],
  annualGeneratedQuantity: number
): number => {
  let total = annualGeneratedQuantity;
  substances.forEach(substanceInArray => {
    total += substanceInArray.quantite;
  });
  return total;
};
