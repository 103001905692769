import { QuotasInfosComplementairesInstallationsDto2021CategorieEnum } from "api/gen";

export const infosComplementairesInstallationsLabels: {
  [key in QuotasInfosComplementairesInstallationsDto2021CategorieEnum]: string;
} = {
  A: "A ( ≤ 50 000 tCO2e)",
  B: "B ( > 50 000 et ≤ 500 000 tCO2e)",
  C: "C ( > 500 000 tCO2e)",
};

export const infosComplementairesInstallationsOptions = Object.keys(
  infosComplementairesInstallationsLabels
) as QuotasInfosComplementairesInstallationsDto2021CategorieEnum[];
