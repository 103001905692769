import {
  QuotasAllocationsVerificationDto20NowConclusionEnum,
  QuotasAllocationsVerificationDto20NowConditionDispenseEnum,
  QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum,
  QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum,
  QuotasAllocationsVerificationDto20NowOrganismeEnum,
} from "api/gen";
import {
  commonBooleanFields,
  commonDateFieldsNullable,
  commonNullableStringFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  futureDateMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";
import * as Yup from "yup";
import {
  isReportUnsatisfying,
  isSatisfyingWithRequests,
} from "./selectPossibleValues";
import { BlocVerificationFormValues } from "./types";

//TODO [GEREP-2579] il faudra remettre le shape BlocVerificationFormValues ici.
export const validationSchema = Yup.object().shape({
  installations: Yup.array().of(
    //TODO [GEREP-2579] il faudra remettre le shape BlocVerificationInstallationFormValues ici.
    Yup.object().shape({
      installationId: commonStringFields,
      organisme: Yup.mixed<
        QuotasAllocationsVerificationDto20NowOrganismeEnum
      >().nullable(),
      monitoringPlanReference: commonNullableStringFields,
      visitExemption: commonBooleanFields,
      conditionExemption: Yup.mixed<
        QuotasAllocationsVerificationDto20NowConditionDispenseEnum
      >().nullable(),
      exemptionDetail: commonNullableStringFields,
      lastVisitDate: commonDateFieldsNullable,
      activityAndEmissionVisit: commonBooleanFields,
      verificationReportConclusion: Yup.mixed<
        QuotasAllocationsVerificationDto20NowConclusionEnum
      >().nullable(),
      satisfyingDeclarationReason: Yup.array().of(
        Yup.mixed<
          QuotasAllocationsVerificationDto20NowMotifsStatisfaisantAvecRemarqueEnum
        >()
      ),
      unsatisfyingDeclarationReason: Yup.array().of(
        Yup.mixed<
          QuotasAllocationsVerificationDto20NowMotifsNonSatisfaisantEnum
        >()
      ),
    })
  ),
});

export const additionalFieldValidation = (
  values: BlocVerificationFormValues
): Errors<BlocVerificationFormValues> => {
  const errors: Errors<Record<string, unknown>> = {};

  // the validation is done manually because there is a bug with yup
  // as all the fields of FieldArray are not displayed at the same time
  values.installations.forEach((installation, index) => {
    if (!installation.organisme) {
      errors[`installations.${index}.organisme`] = requiredMessage;
    }
    if (!installation.monitoringPlanReference) {
      errors[
        `installations.${index}.monitoringPlanReference`
      ] = requiredMessage;
    }
    if (installation.visitExemption) {
      if (!installation.conditionExemption) {
        errors[`installations.${index}.conditionExemption`] = requiredMessage;
      }
      if (
        installation.conditionExemption ===
          QuotasAllocationsVerificationDto20NowConditionDispenseEnum.AUTRE &&
        !installation.exemptionDetail
      ) {
        errors[`installations.${index}.exemptionDetail`] = requiredMessage;
      }
    }
    if (!installation.lastVisitDate) {
      errors[`installations.${index}.lastVisitDate`] = requiredMessage;
    }
    if (installation.lastVisitDate && installation.lastVisitDate > new Date()) {
      errors[`props.${index}.lastVisitDate`] = futureDateMessage;
    }
    if (!installation.verificationReportConclusion) {
      errors[
        `installations.${index}.verificationReportConclusion`
      ] = requiredMessage;
    }
    if (installation.verificationReportConclusion) {
      if (
        isSatisfyingWithRequests(installation.verificationReportConclusion) &&
        installation.satisfyingDeclarationReason.length === 0
      ) {
        errors[
          `installations.${index}.satisfyingDeclarationReason`
        ] = requiredMessage;
      }
      if (
        isReportUnsatisfying(installation.verificationReportConclusion) &&
        installation.unsatisfyingDeclarationReason.length === 0
      ) {
        errors[
          `installations.${index}.unsatisfyingDeclarationReason`
        ] = requiredMessage;
      }
    }
  });
  return errors;
};
