import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import { LINK_STYLE } from "theme";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useElevageFileHandler20Now } from "../../../versionedElements/filesLoaderDisplayer20Now/sectionFilesHooks20Now";
import FilesLoader from "common/FilesLoader/FilesLoader";
import ModalGuides from "common/footer/ModalGuides";

interface BlocInformationsProps {
  shouldDisabledFields: boolean;
}

const useStyles = makeStyles({
  bloc: {
    marginTop: "50px",
  },
  informationsToDo: {
    marginTop: "10px",
  },
  informationsToDoElement: {
    marginLeft: "50px",
    "& a": LINK_STYLE,
  },
  filesPart: {
    marginTop: "50px",
  },
  uploadModuleButton: {
    marginLeft: "10%",
  },
  twoColsChildren: {
    height: "auto",
    "&>div": {
      width: "50%",
    },
  },
  informationAdvice: {
    marginTop: "20px",
    "& a": LINK_STYLE,
  },
});

const BlocInformations = ({
  shouldDisabledFields,
}: BlocInformationsProps): React.ReactElement => {
  const classes = useStyles();
  const openAlertModale = useAlertModale();
  const declaration = useDeclaration20Now();
  const specificFileHandler = useElevageFileHandler20Now();
  const [isModalGuidesOpen, setIsModalGuidesOpen] = useState<boolean>(false);
  const guides = {
    label: "ce lien",
    onClick: () => setIsModalGuidesOpen(true),
  };

  return (
    <div className={classes.bloc}>
      <span>
        Pour remplir le bloc élevage, les étapes suivantes sont nécessaires :
      </span>

      <ul className={classes.informationsToDo}>
        <li className={classes.informationsToDoElement}>
          Télécharger le(s) module(s) de calcul Porcins et/ou Volailles
        </li>
        <li className={classes.informationsToDoElement}>Les compléter</li>
        <li className={classes.informationsToDoElement}>
          Déposer le(s) module(s) de calcul rempli(s) dans GEREP
        </li>
        <li className={classes.informationsToDoElement}>
          Reporter les valeurs d'émissions dans les tableaux ci-dessous
        </li>
      </ul>

      <div className={classes.informationAdvice}>
        <span>
          Des guides sont présents sur{" "}
          <a onClick={guides.onClick}>{guides.label}</a> pour vous aider dans le
          processus de déclaration.
        </span>
      </div>

      <div className={classes.filesPart}>
        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE PORCINS (FORMAT EXCEL)"
            fileName="ModulePorcinsV3.10.xlsx"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE PORCINS (FORMAT OPEN OFFICE)"
            fileName="ModulePorcinsV3.10.ods"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE VOLAILLES (FORMAT EXCEL)"
            fileName="ModuleCalculVolaillesV3.8.xlsx"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row additionalClassname={classes.twoColsChildren}>
          <GreyFilesDownloader
            text="TÉLÉCHARGER MODULE VOLAILLES (FORMAT OPEN OFFICE)"
            fileName="ModuleCalculVolaillesV3.8.ods"
            isDisabled={shouldDisabledFields}
          />
          <FilesLoader
            declaration={declaration}
            text={"DÉPOSER MODULE"}
            filesPath={"body.sections.air.elevage.fichiers"}
            section={FileSectionEnum.ELEVAGE_FEUILLE_CALCUL}
            fileHandlers={specificFileHandler}
            //
            isDisabled={shouldDisabledFields}
            //
            additionalClassnameContainer={classes.uploadModuleButton}
            onReject={() =>
              openAlertModale("Le format du fichier uploadé est incorrect.")
            }
          />
        </Row>

        <Row />

        <Row>
          <GreyFilesDownloader
            text="Télécharger le Bilan Réel Simplifié (BRS) Porcins"
            fileName="BRSPorc.xlsm"
            isDisabled={shouldDisabledFields}
          />
        </Row>

        <Row>
          <GreyFilesDownloader
            text="Télécharger le Bilan Réel Simplifié (BRS) Volailles"
            fileName="BRSVolaille.xlsx"
            isDisabled={shouldDisabledFields}
          />
        </Row>
      </div>
      <ModalGuides
        isOpen={isModalGuidesOpen}
        onRequestClose={() => setIsModalGuidesOpen(false)}
      />
    </div>
  );
};

export default BlocInformations;
