import React from "react";
import {
  CampaignDtoStateEmissionsEnum,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
  QuotasEmissionsBlocPlanSurveillanceDto2121,
} from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import { PATH_QUOTAS_EMISSIONS } from "common/path/path20Now";
import Row from "common/presentational/Row";
// Bloc Bilan Total
import BlocBilanTotal from "../../../toNow/Quotas/Emissions/BlocBilanTotal";
// Bloc Verification
import BlocVerification from "../../../toNow/Quotas/Emissions/BlocVerification";
import QuotasGlobalFormActions from "../../../toNow/Quotas/QuotasGlobalFormActions";
import BlocEmailsVerificateurs from "../../../toNow/Quotas/Emissions/BlocEmailsVerificateurs";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import QuotasDeclarationOfficeDownload from "../../../toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import {
  useBasicDeclarationHandlers2121,
  useDeclaration2121,
  useDeclarationHelpers2121,
} from "../../versionedElements/declarationHooks2121";
import BlocInspecteur from "../../../to23/Quotas/common/BlocInspecteur";
import { areAllEmissionsBlocsValidated } from "../../../to23/Quotas/utils/validation";
import RapportEmissionsForm from "./BlocRapportEmissions/RapportEmissionsForm";
import BlocPlanSurveillance from "./BlocPlanSurveillance";
import BlocRappelTypeActivite from "../../../toNow/Quotas/common/BlocRappelTypeActivite";
import { ReferentialActiviteQuotasProps } from "../../../toNow/Quotas/common/BlocRappelTypeActivite/types";
import {
  updateHandlerBilanTotal2121,
  updateHandlerEmailsAddressEmissions2121,
} from "../../versionedElements/updateHandlers2121";
import { useDeclarationStateHandlers21Now } from "../../../toNow/versionedElements/declarationHooks21Now";
import { isFullDeclarationActive } from "../../../../from20/toNow/Quotas/utils";

const EmissionsForm = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const declaration = useDeclaration2121();
  const currentCampaign = useCurrentDeclarationCampaign();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2121();
  const { update } = useBasicDeclarationHandlers2121();
  const { yieldQuotaEmissionsVerif } = useDeclarationStateHandlers21Now();

  const typeActiviteQuota = declaration.body.typeActivite.quotas;

  const blocPlanDeSurveillanceFormProps = useFormikBloc<
    QuotasEmissionsBlocPlanSurveillanceDto2121
  >();

  const blocBilanTotalProps = useFormikBloc<
    QuotasBlocEmissionsBilanTotalDto20Now
  >();
  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasBlocEmissionsEmailsVerificateursDto20Now
  >();

  const isFullForm = isFullDeclarationActive(declaration);

  const pageHasChanges =
    blocEmailsVerificateursProps.hasChanges ||
    blocPlanDeSurveillanceFormProps.hasChanges;

  // Are blocs validated?
  const allDeclarantBlocsValidated = areAllEmissionsBlocsValidated(
    isPathValidatedInDeclaration,
    isFullForm
  );

  // TODO [GEREP-2565] use actions buttons instead
  const GlobalFormActions = () => (
    <QuotasGlobalFormActions
      pathToValidate={PATH_QUOTAS_EMISSIONS}
      target={WorkflowTarget.QUOTA_EMISSIONS}
      quotaState={declaration.body.workflowStatus.quotaEmissions.state}
      yieldQuotaVerif={yieldQuotaEmissionsVerif}
      isSimplifiedQuota={
        typeActiviteQuota.excluArticle27 || typeActiviteQuota.excluArticle27Bis
      }
      saveAction={() =>
        update(declaration => {
          if (isFullForm) {
            declaration = updateHandlerBilanTotal2121(declaration, null);
            declaration = updateHandlerEmailsAddressEmissions2121(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
          } else {
            declaration = updateHandlerBilanTotal2121(
              declaration,
              blocBilanTotalProps.getValues()
            );
            declaration = updateHandlerEmailsAddressEmissions2121(
              declaration,
              null
            );
          }
          return declaration;
        })
      }
      cancelAction={() => {
        blocBilanTotalProps.resetForm();
        blocEmailsVerificateursProps.resetForm();
        blocPlanDeSurveillanceFormProps.resetForm();
      }}
      hasChanges={pageHasChanges}
      declarantBlocsValidated={allDeclarantBlocsValidated}
    />
  );

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
      />

      <Row />

      <BlocRappelTypeActivite referentialActivites={referentialActivites} />

      <Row />

      {isFullForm ? (
        <>
          <BlocPlanSurveillance
            blocPlanDeSurveillanceFormProps={blocPlanDeSurveillanceFormProps}
          />
          <Row />

          <RapportEmissionsForm />

          <Row />

          <BlocEmailsVerificateurs
            formikRef={blocEmailsVerificateursProps.formikRef}
            hasChanges={blocEmailsVerificateursProps.hasChanges}
            setHasChanges={blocEmailsVerificateursProps.setHasChanges}
          />

          <GlobalFormActions />

          <Row />

          <BlocVerification />
        </>
      ) : (
        <>
          <Row />

          <BlocBilanTotal
            formikRef={blocBilanTotalProps.formikRef}
            hasChanges={blocBilanTotalProps.hasChanges}
            setHasChanges={blocBilanTotalProps.setHasChanges}
          />

          <Row />

          <GlobalFormActions />
        </>
      )}

      <Row />

      <BlocInspecteur
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
        currentState={declaration.body.workflowStatus.quotaEmissions.state}
        path={PATH_QUOTAS_EMISSIONS}
        isCampaignEnded={
          currentCampaign.stateEmissions === CampaignDtoStateEmissionsEnum.ENDED
        }
      />

      <Row height={"25px"} />
    </>
  );
};

export default EmissionsForm;
