import { ReferenceItemGeoCodeInseeDto, ReferenceItemPaysDto } from "api/gen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { InArray, InModale } from "common/form/utils";

export const france = "FRANCE";

export interface TransformationInformationsForm {
  transformationActivityDone: boolean;
  errorArrayRequired?: string;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyFactory {
  socialReason: string;
  zipCode: string;
  city: string | OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>;
  country: OptionPropsWithObject<ReferenceItemPaysDto | null>;
  careerRange: number;
  poweredByOtherCareers: boolean;
}

export type FactoryInModale = InModale<LegacyFactory>;
export type FactoryInArray = InArray<LegacyFactory>;
