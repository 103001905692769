import * as Yup from "yup";
import {
  commonPositiveNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { AreasForm } from "./types";
import { computeTotal, computeTotalPreviousYear } from "./computers";
import {
  environnementAtLeastOneSurfaceMessage,
  environnementAtLeastOneSurfacePreviousYearMessage,
} from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  agriculturalZone: commonPositiveNumberFieldsNullable,
  agriculturalZonePreviousYear: commonPositiveNumberFieldsNullable,
  forestryZone: commonPositiveNumberFieldsNullable,
  forestryZonePreviousYear: commonPositiveNumberFieldsNullable,
  economicZoneGround: commonPositiveNumberFieldsNullable,
  economicZoneGroundPreviousYear: commonPositiveNumberFieldsNullable,
  economicZoneWater: commonPositiveNumberFieldsNullable,
  economicZoneWaterPreviousYear: commonPositiveNumberFieldsNullable,
  ecologicalZoneGround: commonPositiveNumberFieldsNullable,
  ecologicalZoneGroundPreviousYear: commonPositiveNumberFieldsNullable,
  ecologicalZoneWater: commonPositiveNumberFieldsNullable,
  ecologicalZoneWaterPreviousYear: commonPositiveNumberFieldsNullable,
  otherGround: commonPositiveNumberFieldsNullable,
  otherGroundPreviousYear: commonPositiveNumberFieldsNullable,
  otherWater: commonPositiveNumberFieldsNullable,
  otherWaterPreviousYear: commonPositiveNumberFieldsNullable,
  repairTypeGround: Yup.string().when("otherGround", {
    is: (otherGround: number | null) => otherGround !== null && otherGround > 0,
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
  repairTypeWater: Yup.string().when("otherWater", {
    is: (otherWater: number | null) => otherWater !== null && otherWater > 0,
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
  repairTypeGroundPreviousYear: Yup.string().when("otherGroundPreviousYear", {
    is: (otherGroundPreviousYear: number | null) =>
      otherGroundPreviousYear !== null && otherGroundPreviousYear > 0,
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
  repairTypeWaterPreviousYear: Yup.string().when("otherWaterPreviousYear", {
    is: (otherWaterPreviousYear: number | null) =>
      otherWaterPreviousYear !== null && otherWaterPreviousYear > 0,
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
});

export const preventSubmitWithErrors = (values: AreasForm) => {
  if (values.isRestoredThisYear) {
    if (!computeTotal(values) && !computeTotalPreviousYear(values)) {
      return {
        agriculturalZone: "",
        agriculturalZonePreviousYear: "",
        forestryZone: "",
        forestryZonePreviousYear: "",
        economicZoneGround: "",
        economicZoneGroundPreviousYear: "",
        economicZoneWater: "",
        economicZoneWaterPreviousYear: "",
        ecologicalZoneGround: "",
        ecologicalZoneGroundPreviousYear: "",
        ecologicalZoneWater: "",
        ecologicalZoneWaterPreviousYear: "",
        otherGround: "",
        otherGroundPreviousYear: "",
        otherWater: "",
        otherWaterPreviousYear: environnementAtLeastOneSurfaceMessage,
        repairTypeGround: "",
        repairTypeGroundPreviousYear: "",
        repairTypeWater: "",
        repairTypeWaterPreviousYear: "",
      };
    } else if (!computeTotal(values)) {
      return {
        agriculturalZone: "",
        forestryZone: "",
        economicZoneGround: "",
        economicZoneWater: "",
        ecologicalZoneGround: "",
        ecologicalZoneWater: "",
        otherGround: "",
        otherWater: environnementAtLeastOneSurfacePreviousYearMessage,
        repairTypeGround: "",
        repairTypeWater: "",
      };
    }
  }
  return {};
};
