import React from "react";

import { makeStyles } from "@material-ui/styles";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import { SubBlocEmpoussierageProps } from "./props";
import CommonPoussiere from "./CommonPoussiere";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";

const useStyles = makeStyles({
  longInput: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  ...LEFT_WITHDRAW_STYLE,
});

const fieldNamePrefix = "poussiereAlveolaireTotale";

const PoussiereAlveolaireTotaleSubPart = ({
  commonProps,
  formikProps,
  shouldDisableFields,
  organismeMeasuringEmpoussierageList,
  commentBasePath,
}: SubBlocEmpoussierageProps) => {
  const classes = useStyles();

  const NumberField = useNumberFieldGenerator(commonProps, classes.longInput);

  return (
    <>
      <CommonPoussiere
        shouldDisableFields={shouldDisableFields}
        subTitle={"Poussières alvéolaires totales"}
        fieldNamePrefix={fieldNamePrefix}
        parenthesisText={"article 2 du décret n° 2013-797"}
        longInputClassName={classes.longInput}
        commonProps={commonProps}
        formikProps={formikProps}
        organismeMeasuringEmpoussierageList={
          organismeMeasuringEmpoussierageList
        }
        commentBasePath={commentBasePath}
      />

      {formikProps.values.poussiereAlveolaireTotale &&
        formikProps.values.poussiereAlveolaireTotale
          .hasShownNotWeakRiskOnly && (
          <div className={classes.withdrawLeft}>
            <NumberField
              disabled={shouldDisableFields}
              name={`${fieldNamePrefix}.minimalValue`}
              label={"Valeur minimum"}
              unit={"mg/m³ d'air"}
              commentPath={`${commentBasePath}/valeurMinimale`}
            />

            <NumberField
              disabled={shouldDisableFields}
              name={`${fieldNamePrefix}.maximalValue`}
              label={"Valeur maximum"}
              unit={"mg/m³ d'air"}
              commentPath={`${commentBasePath}/valeurMaximale`}
            />
            <NumberField
              disabled={shouldDisableFields}
              name={`${fieldNamePrefix}.nbGEHBiggerThanVLEP`}
              label={"Nombre de GEH > VLEP"}
              unit={""}
              tooltipContent={
                "Nombre de GEH dont au moins un prélèvement est > à au moins une des 3 VLEP possibles. Si c’est le cas pour plusieurs VLEP, inscrire le nombre total de GEH supérieur aux VLEP."
              }
              commentPath={`${commentBasePath}/nbreGEHsupVLEP`}
            />
          </div>
        )}
    </>
  );
};

export default PoussiereAlveolaireTotaleSubPart;
