import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonBooleanFieldsNullable,
  commonMixedFields,
  commonMixedFieldsNullable,
  commonNullableStringFields,
  commonNumberFields,
  commonNumberFieldsNullable,
  commonPositiveNumberFields,
  commonStringFields,
  subFieldValidationMultipleANDScheme,
} from "common/declarant/formik/formikHelper";
import { autreString, concatLibelleNorme } from "../utils/utils";
import { fieldMatcher, SyntheseInModale } from "../utils/types";
import {
  AirSyntheseEmissionDtoMethodeEnum,
  AirSyntheseEmissionDtoReferenceCalculeEnum,
  AirSyntheseEmissionDtoReferenceMesureEnum,
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDto,
} from "api/gen";

export const MSubPartActivated = (
  type: AirSyntheseEmissionDtoMethodeEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoMethodeEnum.MESURE;
};

export const CSubPartActivated = (
  type: AirSyntheseEmissionDtoMethodeEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoMethodeEnum.CALCUL;
};

export const MINTSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceMesureEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceMesureEnum.INT;
};

export const MPERSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceMesureEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceMesureEnum.PER;
};

export const MNROSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceMesureEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceMesureEnum.NRO;
};

export const MALTSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceMesureEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceMesureEnum.ALT;
};

export const MMRCSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceMesureEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceMesureEnum.MRC;
};

export const CINTSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceCalculeEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceCalculeEnum.INT;
};

const CCSSSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceCalculeEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceCalculeEnum.CSS;
};

const CAUTSubPartActivated = (
  type: AirSyntheseEmissionDtoReferenceCalculeEnum | null
): boolean => {
  return type === AirSyntheseEmissionDtoReferenceCalculeEnum.AUT;
};

const isLabelEqualsAutre = (
  norms: ReferenceItemNormeAirDto[] | null
): boolean => {
  if (!norms) {
    return false;
  }
  return norms.some(
    norm => concatLibelleNorme(norm).toLowerCase() === autreString
  );
};

const descriptionSchemaM = (
  type1: ReferenceItemNormeAirDto[] | null,
  type2: ReferenceItemNormeAirDto[] | null,
  type3: ReferenceItemNormeAirDto[] | null,
  methodRef: AirSyntheseEmissionDtoReferenceMesureEnum | null,
  method: AirSyntheseEmissionDtoMethodeEnum | null
) => {
  if (MSubPartActivated(method)) {
    if (type1 && MINTSubPartActivated(methodRef)) {
      return isLabelEqualsAutre(type1);
    }
  }
  if (type2 && MPERSubPartActivated(methodRef)) {
    return isLabelEqualsAutre(type2);
  }
  if (type3 && MNROSubPartActivated(methodRef)) {
    return isLabelEqualsAutre(type3);
  }
  return false;
};

const descriptionSchemaC = (
  type: ReferenceItemNormeAirDto[] | null,
  methodRef: AirSyntheseEmissionDtoReferenceCalculeEnum | null,
  method: AirSyntheseEmissionDtoMethodeEnum | null
) => {
  if (CSubPartActivated(method)) {
    if (type && CINTSubPartActivated(methodRef)) {
      return isLabelEqualsAutre(type);
    }
  }
  return false;
};

export const singleSyntheseOverwriteDependantFields = (
  synthese: SyntheseInModale
): void => {
  if (!MSubPartActivated(synthese.methode || null)) {
    synthese.referenceMesure = null;
  }
  if (!MINTSubPartActivated(synthese.referenceMesure)) {
    synthese.normMINT = null;
  }
  if (!MPERSubPartActivated(synthese.referenceMesure)) {
    synthese.normMPER = null;
  }
  if (!MNROSubPartActivated(synthese.referenceMesure)) {
    synthese.normMNRO = null;
  }
  if (!CSubPartActivated(synthese.methode || null)) {
    synthese.referenceCalcule = null;
  }
  if (!CINTSubPartActivated(synthese.referenceCalcule)) {
    synthese.normCINT = null;
  }
  if (
    CAUTSubPartActivated(synthese.referenceCalcule) ||
    CCSSSubPartActivated(synthese.referenceCalcule) ||
    MALTSubPartActivated(synthese.referenceMesure) ||
    MMRCSubPartActivated(synthese.referenceMesure) ||
    (!MSubPartActivated(synthese.methode) &&
      !CSubPartActivated(synthese.methode))
  ) {
    synthese.description = null;
  }
};

const isDescriptionMandatory = (
  normMINT: ReferenceItemNormeAirDto[] | null,
  normMPER: ReferenceItemNormeAirDto[] | null,
  normMNRO: ReferenceItemNormeAirDto[] | null,
  normCINT: ReferenceItemNormeAirDto[] | null,
  referenceMesure: AirSyntheseEmissionDtoReferenceMesureEnum | null,
  referenceCalcule: AirSyntheseEmissionDtoReferenceCalculeEnum | null,
  method: AirSyntheseEmissionDtoMethodeEnum | null
): boolean => {
  return (
    descriptionSchemaM(normMINT, normMPER, normMNRO, referenceMesure, method) ||
    descriptionSchemaC(normCINT, referenceCalcule, method)
  );
};

function descriptionSchema(
  properties: [string, string, string, string, string, string, string]
): Yup.StringSchema<string | undefined> {
  return Yup.string().when(properties, {
    is: (
      type1: ReferenceItemNormeAirDto[] | null,
      type2: ReferenceItemNormeAirDto[] | null,
      type3: ReferenceItemNormeAirDto[] | null,
      type4: ReferenceItemNormeAirDto[] | null,
      method1: AirSyntheseEmissionDtoReferenceMesureEnum | null,
      method2: AirSyntheseEmissionDtoReferenceCalculeEnum | null,
      method: AirSyntheseEmissionDtoMethodeEnum | null
    ) =>
      isDescriptionMandatory(
        type1,
        type2,
        type3,
        type4,
        method1,
        method2,
        method
      ),
    then: commonStringFields,
    otherwise: commonNullableStringFields,
  });
}

export const singleSyntheseValidationSchema: Yup.SchemaOf<Partial<
  SyntheseInModale
>> = Yup.object().shape({
  substance: commonMixedFields<ReferenceItemPolluantDto>(),
  emissionsAlreadyDeclared: commonNumberFields.nullable(),
  accidentel: commonPositiveNumberFields,
  additionnel: commonPositiveNumberFields,
  origine: commonNullableStringFields,
  totalEmissions: commonNumberFields.nullable(),
  methode: commonMixedFields<AirSyntheseEmissionDtoMethodeEnum>(),
  referenceMesure: Yup.mixed<AirSyntheseEmissionDtoReferenceMesureEnum>().when(
    fieldMatcher.method,
    {
      is: (type: AirSyntheseEmissionDtoMethodeEnum | null) =>
        MSubPartActivated(type || null),
      then: commonMixedFields<AirSyntheseEmissionDtoReferenceMesureEnum>(),
      otherwise: commonMixedFieldsNullable<
        AirSyntheseEmissionDtoReferenceMesureEnum
      >(),
    }
  ),
  referenceCalcule: Yup.mixed<
    AirSyntheseEmissionDtoReferenceCalculeEnum
  >().when(fieldMatcher.method, {
    is: (type: AirSyntheseEmissionDtoMethodeEnum | null) =>
      CSubPartActivated(type || null),
    then: commonMixedFields<AirSyntheseEmissionDtoReferenceCalculeEnum>(),
    otherwise: commonMixedFieldsNullable<
      AirSyntheseEmissionDtoReferenceCalculeEnum
    >(),
  }),
  normMINT: subFieldValidationMultipleANDScheme(
    [fieldMatcher.methodReferenceM, fieldMatcher.method],
    MINTSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normMPER: subFieldValidationMultipleANDScheme(
    [fieldMatcher.methodReferenceM, fieldMatcher.method],
    MPERSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normMNRO: subFieldValidationMultipleANDScheme(
    [fieldMatcher.methodReferenceM, fieldMatcher.method],
    MNROSubPartActivated,
    MSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  normCINT: subFieldValidationMultipleANDScheme(
    [fieldMatcher.methodReferenceC, fieldMatcher.method],
    CINTSubPartActivated,
    CSubPartActivated,
    commonArrayOfObjectFields,
    Yup.array()
  ),
  description: descriptionSchema([
    fieldMatcher.normMINT,
    fieldMatcher.normMPER,
    fieldMatcher.normMNRO,
    fieldMatcher.normCINT,
    fieldMatcher.methodReferenceM,
    fieldMatcher.methodReferenceC,
    fieldMatcher.method,
  ]),
  totalEmissionsUsualUnit: commonNumberFieldsNullable,
  biomasse: commonBooleanFieldsNullable,
  depassementDeSeuilDeclare: commonBooleanFieldsNullable,
  depassementDeSeuilTotal: commonBooleanFieldsNullable,
});

export const canDisplayDescription = (values: SyntheseInModale): boolean => {
  return !!(
    (values.referenceCalcule || values.referenceMesure) &&
    (MSubPartActivated(values.methode) || CSubPartActivated(values.methode)) &&
    !MALTSubPartActivated(values.referenceMesure) &&
    !MMRCSubPartActivated(values.referenceMesure) &&
    !CAUTSubPartActivated(values.referenceCalcule) &&
    !CCSSSubPartActivated(values.referenceCalcule)
  );
};

export const isDisplayedDescriptionMandatory = (
  values: SyntheseInModale
): boolean => {
  return isDescriptionMandatory(
    values.normMINT,
    values.normMPER,
    values.normMNRO,
    values.normCINT,
    values.referenceMesure,
    values.referenceCalcule,
    values.methode
  );
};
