import React, { useEffect, useRef, useState } from "react";
import { Redirect } from "react-router";
import {
  AuthDroitDto,
  AuthDroitDtoAuthProfileEnum,
  EtablissementControllerApi,
} from "api/gen";
import { isEqual } from "lodash";
import { useActiveOrLastCampaign } from "../../CampaignContext";
import { useUserData } from "Authenticator/UserData";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { useAuthenticatedApi } from "../../../../Authenticator/AuthenticatedApi";
import { computeDeclarationUrl } from "./computePath";

/**
 * Build the path to home page according to profile rights
 * @param right user active right
 * @param campaignYear year of the current campaign
 * @param etablissementController the etablissementController api
 */
const buildPathToHome = async (
  right: AuthDroitDto,
  campaignYear: number,
  etablissementController: EtablissementControllerApi
): Promise<string> => {
  const listOfDeclaredYears: number[] = right.codeEtablissement
    ? await etablissementController.getAllYearsDeclaredForEtablissementUsingGET(
        right.codeEtablissement
      )
    : [];

  const yearToRedirectTo =
    listOfDeclaredYears.length > 1 ||
    (listOfDeclaredYears.length > 0 && listOfDeclaredYears[0] !== 2018)
      ? Math.max(...listOfDeclaredYears)
      : campaignYear;

  switch (right.authProfile) {
    case AuthDroitDtoAuthProfileEnum.SUPER_ADMIN:
    case AuthDroitDtoAuthProfileEnum.GESTIONNAIRE:
    case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA:
    case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR: {
      return "/accueil";
    }
    case AuthDroitDtoAuthProfileEnum.EXPLOITANT: {
      return computeDeclarationUrl(
        right.codeEtablissement,
        "dashboard",
        yearToRedirectTo
      );
    }
    case AuthDroitDtoAuthProfileEnum.PRESTATAIRE: {
      return "/verificateur/declarations";
    }
    case AuthDroitDtoAuthProfileEnum.LECTEUR: {
      return "/lecteur/declarations";
    }
    default: {
      throw new ShouldNotHappen(right.authProfile);
    }
  }
};

interface RedirectHomeProps {
  initialRedirection?: boolean; // Define if we should redirect during the first render
}

const RedirectHome = ({
  initialRedirection,
}: RedirectHomeProps): React.ReactElement | null => {
  const userInfo = useUserData().userInfo;
  const userRight = userInfo.droits[0];
  const previousUserRight = useRef<AuthDroitDto | undefined>(undefined);
  const campaign = useActiveOrLastCampaign();
  const { etablissementController } = useAuthenticatedApi();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  !shouldRedirect &&
    setShouldRedirect(
      !!(
        (previousUserRight.current !== undefined || initialRedirection) &&
        !isEqual(previousUserRight.current, userRight)
      )
    );

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  userRight &&
    buildPathToHome(userRight, campaign.annee, etablissementController).then(
      newRedirectUrl => {
        isMounted.current && setRedirectUrl(newRedirectUrl);
      }
    );

  previousUserRight.current = userRight;

  return shouldRedirect && redirectUrl ? <Redirect to={redirectUrl} /> : null;
};

export default RedirectHome;
