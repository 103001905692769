import React from "react";
import {
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "./languages";
import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const computeFamiliesArrayHeader = () => {
  return [
    <p>{USAGE_FAMILY}</p>,
    <p>{SUB_USAGE_FAMILY}</p>,
    <p>{SUB_USAGE_FAMILY_LEVEL_2}</p>,
  ];
};

export const computeFamiliesArrayCells = (
  usageFamily:
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
    | null,
  usageFamilyPrecision: string | null,
  usageSubFamily:
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
    | null,
  usageSubFamilyPrecision: string | null,
  usageSubFamilyLvl2:
    | ReferenceItemFamilleUsageProductionDto
    | ReferenceItemFamilleUsageRecyclageDto
    | null,
  usageSubFamilyLvl2Precision: string | null
): React.ReactElement[] => {
  const render = [];
  if (usageFamily && usageFamily.precision) {
    render.push(<p>{usageFamilyPrecision}</p>);
  } else {
    render.push(
      <p>{usageFamily ? usageFamily.code + " - " + usageFamily.libelle : ""}</p>
    );
  }
  if (usageSubFamily && usageSubFamily.precision) {
    render.push(<p>{usageSubFamilyPrecision || ""}</p>);
  } else {
    render.push(
      <p>
        {usageSubFamily
          ? usageSubFamily.code + " - " + usageSubFamily.libelle
          : ""}
      </p>
    );
  }
  if (usageSubFamilyLvl2 && usageSubFamilyLvl2.precision) {
    render.push(<p>{usageSubFamilyLvl2Precision || ""}</p>);
  } else {
    render.push(
      <p>
        {usageSubFamilyLvl2
          ? usageSubFamilyLvl2.code + " - " + usageSubFamilyLvl2.libelle
          : ""}
      </p>
    );
  }
  return render;
};
