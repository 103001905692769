import _ from "lodash";
import { CommentValues } from "pages/CompanySpace/Comments/commentTypes";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { Declaration21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import { CommentButtonState } from "../actions/types";

// ex: "/air/elevage/emissions" => "air.elevage.emissions"
const pathToJsonPath = (path: string) => {
  const pathWithFirstSlashRemoved = path.replace("/", "");
  const pathWithSlashReplacedByDots = pathWithFirstSlashRemoved.replace(
    /\//g,
    "."
  );
  return pathWithSlashReplacedByDots;
};

export const listSuffixesPath: string[] = [
  "/co2Biomasse",
  "/co2NonBiomasse",
  "/n2o",
  "/pfc",
  "/poussieres",
  "/lessThanOneTon",
  "/significantDifference",
  "/produits/dangereux/anormalementHaut",
  "/produits/dangereux/anormalementBas",
  "/produits/dangereux/anormalementHautMoyenne",
  "/produits/dangereux/anormalementBasMoyenne",
  "/produits/dangereux/limite",
  "/produits/nonDangereux/anormalementHaut",
  "/produits/nonDangereux/anormalementBas",
  "/produits/nonDangereux/anormalementHautMoyenne",
  "/produits/nonDangereux/anormalementBasMoyenne",
  "/produits/nonDangereux/limite",
  "/produits/transfrontalier/anormalementHautMoyenne",
  "/produits/transfrontalier/anormalementBasMoyenne",
  "/recus/dangereux/anormalementHaut",
  "/recus/dangereux/anormalementBas",
  "/recus/dangereux/anormalementHautMoyenne",
  "/recus/dangereux/anormalementBasMoyenne",
  "/recus/dangereux/limite",
  "/recus/nonDangereux/anormalementHaut",
  "/recus/nonDangereux/anormalementBas",
  "/recus/nonDangereux/anormalementHautMoyenne",
  "/recus/nonDangereux/anormalementBasMoyenne",
  "/recus/nonDangereux/limite",
  "/recus/transfrontalier/anormalementHautMoyenne",
  "/recus/transfrontalier/anormalementBasMoyenne",
  "/generatedEqualsCaptedPlusTotal",
  "/missingGunFields",
  "/volumeProductionNul",
  "/aqueuxAnneePrecedenteZero",
  "/aqueuxAumgentation",
  "/aqueuxDiminution",
  "/chaleurAnneePrecedenteZero",
  "/chaleurAugmentation",
  "/chaleurDiminution",
];

export const isPathInDeclaration = (
  declaration: AnyDeclarationDto,
  path: string
): boolean => {
  // TODO [GEREP-2674] there should be no need to duplicate code to account for external sections
  const isInSection = _.has(
    declaration.body.sections,
    pathToJsonPath(removeLastPartOfStringIfInList(path, listSuffixesPath))
  );

  if (declaration.annee < 2021) {
    return isInSection;
  }

  const isInExternalSection = _.has(
    (declaration as Declaration21Now).body.externalSections,
    pathToJsonPath(removeLastPartOfStringIfInList(path, listSuffixesPath))
  );
  return isInSection || isInExternalSection;
};

export const removeLastPartOfStringIfInList = (
  path: string,
  listStrToRemove: string[]
): string => {
  const regexRemoval = new RegExp(
    listStrToRemove
      .map(singleStrToRemove => `${_.escapeRegExp(singleStrToRemove)}$`)
      .join("|")
  );

  return path.replace(regexRemoval, "");
};

export const isPathCommented = (
  commentsList: CommentValues[],
  path: string,
  isAlertOrError: boolean
): boolean => {
  return commentsList.some(
    com => com.path === path && com.isAlertOrError === isAlertOrError
  );
};

export const computeCommentsListForModale = (
  convertedComments: CommentValues[],
  commentPath: string | null
): CommentValues[] => {
  return _.cloneDeep(convertedComments)
    .sort((a, b) => {
      return a.date.getTime() - b.date.getTime();
    })
    .filter(comment => {
      return comment.path === commentPath;
    });
};

export function computeCommentButtonState(
  isLecteur: boolean,
  hasComments: boolean
): CommentButtonState {
  if (isLecteur) {
    return hasComments ? CommentButtonState.FULL : CommentButtonState.DISABLED;
  }
  return hasComments ? CommentButtonState.FULL : CommentButtonState.EMPTY;
}
