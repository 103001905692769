import React, { useState } from "react";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { useCompanyTableLines } from "./CompanyTableLines";
import {
  EtablissementDto,
  FiltresEtablissementDtoSortByEnum,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";
import { useCompanyTableHeader } from "./CompanyTableHeader";
import { useAlertModale, useConfirmationModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageDeleteEtablissement } from "common/backErrors/errorMessages";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { generateDeletionWarningMessage } from "common/messages/dashboardMessage";
import { makeStyles } from "@material-ui/styles";
import { VersionedContactInfoModal } from "../../ContactInfosModal/VersionedContactInfoModal";

const useStyles = makeStyles({
  contactButton: {
    padding: "2px",
  },
  deleteButton: {
    padding: "3px",
  },
});

interface CompanyTableProps {
  establishments: EtablissementDto[];
  sorter: FiltresEtablissementDtoSortByEnum | null;
  isSortReversed: boolean | null;
  updateSortFilter: (
    sorter: FiltresEtablissementDtoSortByEnum | null,
    isSortReversed: boolean | null
  ) => void;
  referentielDepartments: ReferenceItemDepartementDto[];
  referentielRegion: ReferenceItemRegionDto[];
}

export const CompanyTable = ({
  establishments,
  sorter,
  isSortReversed,
  updateSortFilter,
  referentielDepartments,
  referentielRegion,
}: CompanyTableProps): React.ReactElement => {
  const [contact, setContact] = useState<EtablissementDto | null>(null);

  const header = useCompanyTableHeader(
    sorter,
    isSortReversed,
    updateSortFilter
  );

  const { etablissementController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const openConfirmationModale = useConfirmationModale();

  const deleteEtablissement = async (establishmentCode: string) => {
    try {
      await etablissementController.deleteEtablissementUsingDELETE(
        establishmentCode
      );
      // utilisation de updateSortFilter pour mettre à jour l'état sans utiliser de loader
      updateSortFilter(sorter, isSortReversed);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageDeleteEtablissement));
      return false;
    }
  };

  const classes = useStyles();

  const lines = useCompanyTableLines(
    establishments,
    referentielDepartments,
    referentielRegion,
    establishment => setContact(establishment),
    establishment =>
      openConfirmationModale(generateDeletionWarningMessage(), () =>
        deleteEtablissement(establishment.code)
      ),
    classes
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredColWidths={[160, 130, 100, 100, 100, 90]}
      />

      <VersionedContactInfoModal
        isOpen={contact !== null}
        year={contact && contact.annee}
        company={contact && contact.code}
        onRequestClose={() => setContact(null)}
      />
    </>
  );
};
