import { FiltresDeclarationDtoSelectedFolderSectionsEnum } from "api/gen";
import { AggregationFilesModaleValues } from "./types";
import { Nullable } from "common/utils/types";
import { DeclarationFilters } from "../types";

export const filtreGestionnaireDtoToAggregationModaleValues = (
  filter: DeclarationFilters
): AggregationFilesModaleValues => ({
  documentsDivers:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.DOCUMENTS_DIVERS
    ) || false,
  solvantPlanGestion:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.SOLVANT_PLAN_GESTION
    ) || false,
  elevageFeuilleCalcul:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.ELEVAGE_FEUILLE_CALCUL
    ) || false,
  quotasPlanSurveillance:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_PLAN_SURVEILLANCE
    ) || false,
  quotasJustificatifsPds:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE
    ) || false,
  quotasRapportEmissions:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_EMISSIONS
    ) || false,
  quotasJustificatifsRapportEmissions:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS
    ) || false,
  quotasRapportVerificationEmissions:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_VERIFICATION_EMISSIONS
    ) || false,
  quotasRapportDeclarationOfficeEmissions:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE
    ) || false,
  quotasRapportAlcPreliminaire:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_ALC_PRELIMINAIRE
    ) || false,
  quotasJustificatifsAlcPreliminaire:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE
    ) || false,
  quotasRapportAlcVerifie:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_ALC_VERIFIE
    ) || false,
  quotasJustificatifsAlcVerifie:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_JUSTIFICATIFS_ALC_VERIFIE
    ) || false,
  quotasAllocationPlanSurveillance:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE
    ) || false,
  quotasJustificatifsPms:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS
    ) || false,
  quotasRapportVerificationAllocation:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS
    ) || false,
  quotasRapportDeclarationOfficeAllocation:
    filter.selectedFolderSections?.includes(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE
    ) || false,
});

export const aggregationFilesModaleValuesToSelectedFolderSections = (
  values: AggregationFilesModaleValues | Nullable<AggregationFilesModaleValues>
): FiltresDeclarationDtoSelectedFolderSectionsEnum[] => {
  const folderSections = [];
  if (values.documentsDivers) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.DOCUMENTS_DIVERS
    );
  }
  if (values.solvantPlanGestion) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.SOLVANT_PLAN_GESTION
    );
  }
  if (values.elevageFeuilleCalcul) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.ELEVAGE_FEUILLE_CALCUL
    );
  }
  if (values.quotasPlanSurveillance) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_PLAN_SURVEILLANCE
    );
  }
  if (values.quotasJustificatifsPds) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_JUSTIFICATIFS_PLAN_SURVEILLANCE
    );
  }
  if (values.quotasRapportEmissions) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_EMISSIONS
    );
  }
  if (values.quotasJustificatifsRapportEmissions) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_JUSTIFICATIFS_RAPPORT_EMISSIONS
    );
  }
  if (values.quotasRapportVerificationEmissions) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_VERIFICATION_EMISSIONS
    );
  }
  if (values.quotasRapportDeclarationOfficeEmissions) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE
    );
  }
  if (values.quotasRapportAlcPreliminaire) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_ALC_PRELIMINAIRE
    );
  }
  if (values.quotasJustificatifsAlcPreliminaire) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE
    );
  }
  if (values.quotasRapportAlcVerifie) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_ALC_VERIFIE
    );
  }
  if (values.quotasJustificatifsAlcVerifie) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_JUSTIFICATIFS_ALC_VERIFIE
    );
  }
  if (values.quotasAllocationPlanSurveillance) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE
    );
  }
  if (values.quotasJustificatifsPms) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS
    );
  }
  if (values.quotasRapportVerificationAllocation) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS
    );
  }
  if (values.quotasRapportDeclarationOfficeAllocation) {
    folderSections.push(
      FiltresDeclarationDtoSelectedFolderSectionsEnum.QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE
    );
  }

  return folderSections;
};

export const areAllCheckboxesSelected = (
  values: Nullable<AggregationFilesModaleValues>
): boolean => {
  for (const key in values) {
    if (!values[key as keyof Nullable<AggregationFilesModaleValues>]) {
      return false;
    }
  }
  return true;
};

export const selectAllCheckbox = (
  values: Nullable<AggregationFilesModaleValues>,
  value: boolean,
  setFieldValue: (field: string, value: boolean) => void
): void => {
  for (const key in values) {
    setFieldValue(key, value);
  }
};
