import React, { ReactElement } from "react";
import {
  PATH_AIR,
  PATH_AIR_COMBUSTION,
  PATH_CARRIERE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_DECHET,
  PATH_EAU,
  PATH_SOL,
  PATH_SPFO,
} from "common/path/path18Now";
import { makeStyles } from "@material-ui/styles";
import { THREE_COLUMNS_CARD_GRID } from "theme";
import garbageImg from "icons/dashboardLogos/garbage.svg";
import waterImg from "icons/dashboardLogos/water.svg";
import shovelImg from "icons/dashboardLogos/shovel.svg";
import windImg from "icons/dashboardLogos/wind.svg";
import helmetImg from "icons/dashboardLogos/helmet.svg";
import moleculeImg from "icons/dashboardLogos/molecule.svg";
import { useUserData } from "../../../../../../../Authenticator/UserData";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../../../../api/gen";
import {
  GlobalDashboardCardsLecteurProps,
  GlobalDashboardCardsProps,
} from "pages/CompanySpace/from21/to23/Dashboard/Components/Cards/types";
import {
  GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION,
  getDisabledMessageForConditionnalSection,
  getDisabledMessageForTypeActiviteConditionnalSection,
} from "pages/CompanySpace/from21/toNow/Dashboard/Components/utils/disabledMessageUtils";
import { buildAirSubCards } from "./AirSubCards";
import { buildCarriereSubCards } from "pages/CompanySpace/from21/toNow/Dashboard/Components/Cards/CarriereSubCards";
import {
  isAirDisabled,
  isCarriereDisabled,
  isDechetDisabled,
  isEveryAirValidated,
  isEveryCarriereValidated,
  isEveryDechetValidated,
} from "../utils/validationUtils";
import { buildDechetSubCards } from "./DechetSubCards";
import BigCard from "./BigCard";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";

const useStyles = makeStyles({
  subCardContainer: THREE_COLUMNS_CARD_GRID,
});

const DechetCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const classes = useStyles();
  const { getPathProgress } = declarationApi;
  const declaration = useDeclaration24Now();

  return (
    <BigCard
      isClickable={false}
      additionalClassname={classes.subCardContainer}
      picture={{
        src: garbageImg,
        alt: "",
      }}
      text="Déchets"
      percentageProgress={getPathProgress(PATH_DECHET)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_DECHET}`}
      isDisabled={isDechetDisabled(declarationApi)}
      isValidated={isEveryDechetValidated(declarationApi)}
      disabledMessage={GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION}
      subCards={buildDechetSubCards(
        {
          declarationAnnee,
          declarationEtablissement,
          declarationApi,
        },
        declaration
      )}
      isDepassementDeSeuil={
        declaration.computed.sections.dechets.depassementDeSeuilGlobal
      }
    />
  );
};

const EauCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;
  const declaration = useDeclaration24Now();

  return (
    <BigCard
      picture={{ src: waterImg, alt: "" }}
      text="Eau"
      percentageProgress={getPathProgress(PATH_EAU)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_EAU}`}
      isDisabled={!getIsActiveSection(PATH_EAU)}
      isValidated={isPathValidatedInDeclaration(PATH_EAU)}
      disabledMessage={GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION}
      isDepassementDeSeuil={
        declaration.computed.sections.eau.depassementDeSeuilGlobal
      }
    />
  );
};

const SolCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;
  const declaration = useDeclaration24Now();

  return (
    <BigCard
      picture={{ src: shovelImg, alt: "" }}
      text="Sol"
      percentageProgress={getPathProgress(PATH_SOL)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_SOL}`}
      isDisabled={!getIsActiveSection(PATH_SOL)}
      isValidated={isPathValidatedInDeclaration(PATH_SOL)}
      disabledMessage={getDisabledMessageForTypeActiviteConditionnalSection(
        isPathValidatedInDeclaration
      )}
      isDepassementDeSeuil={
        declaration.computed.sections.sol.depassementDeSeuilGlobal
      }
    />
  );
};

const AirCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const classes = useStyles();
  const { getPathProgress } = declarationApi;
  const declaration = useDeclaration24Now();

  return (
    <BigCard
      isClickable={false}
      additionalClassname={classes.subCardContainer}
      picture={{ src: windImg, alt: "" }}
      text="Air"
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_AIR_COMBUSTION}`}
      isDisabled={isAirDisabled(declarationApi)}
      percentageProgress={getPathProgress(PATH_AIR)}
      isValidated={isEveryAirValidated(declarationApi)}
      subCards={buildAirSubCards(
        {
          declarationAnnee,
          declarationEtablissement,
          declarationApi,
        },
        declaration
      )}
      isDepassementDeSeuil={
        declaration.computed.sections.air.depassementDeSeuilGlobal
      }
    />
  );
};

const CarriereCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const classes = useStyles();
  const { getPathProgress } = declarationApi;

  return (
    <BigCard
      isClickable={false}
      additionalClassname={classes.subCardContainer}
      picture={{ src: helmetImg, alt: "" }}
      text="Carrière"
      percentageProgress={getPathProgress(PATH_CARRIERE)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_CARRIERE_ENVIRONNEMENT}`}
      isDisabled={isCarriereDisabled(declarationApi)}
      isValidated={isEveryCarriereValidated(declarationApi)}
      subCards={buildCarriereSubCards({
        declarationAnnee,
        declarationEtablissement,
        declarationApi,
      })}
    />
  );
};

const SPFOCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const {
    getPathProgress,
    getIsActiveSection,
    isPathValidatedInDeclaration,
  } = declarationApi;

  return (
    <BigCard
      picture={{ src: moleculeImg, alt: "" }}
      text="SPFO"
      percentageProgress={getPathProgress(PATH_SPFO)}
      link={`/declaration/${declarationAnnee}/${declarationEtablissement}${PATH_SPFO}`}
      isDisabled={!getIsActiveSection(PATH_SPFO)}
      isValidated={isPathValidatedInDeclaration(PATH_SPFO)}
      disabledMessage={getDisabledMessageForConditionnalSection(
        isPathValidatedInDeclaration,
        "Cette page est active si vous avez déclaré au moins une émission de sulfonate de perfluorooctane dans le sol, l'eau ou l'air"
      )}
    />
  );
};

const GlobalDashboardCards = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  const { isLecteur, isLecteurOnSection } = useUserData();
  return (
    <>
      {isLecteur ? (
        <LecteurRestrictedCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
          isLecteurOnSection={isLecteurOnSection}
        />
      ) : (
        <AllGlobalCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
        />
      )}
    </>
  );
};
const AllGlobalCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
}: GlobalDashboardCardsProps): ReactElement => {
  return (
    <>
      <DechetCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
      <EauCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
      <SolCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
      <AirCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
      <CarriereCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
      <SPFOCard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        declarationApi={declarationApi}
      />
    </>
  );
};
const LecteurRestrictedCard = ({
  declarationAnnee,
  declarationEtablissement,
  declarationApi,
  isLecteurOnSection,
}: GlobalDashboardCardsLecteurProps): ReactElement => {
  return (
    <>
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU) && (
        <EauCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
        />
      )}
      {isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) && (
        <CarriereCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
          declarationApi={declarationApi}
        />
      )}
    </>
  );
};
export default GlobalDashboardCards;
