import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import {
  DYNAMIC_ELEMENTS_STYLE,
  LEFT_WITHDRAW_STYLE,
  LONG_TEXT_INPUT_WIDTH,
  SECTION_TITLE_GREY,
} from "theme";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { arrayMethods, arrayMethodsLabel } from "./utils/selectPossibleValues";
import {
  anySubPartActivated,
  facteurSubPartActivated,
  matiereSubPartActivated,
  mesureSubPartActivated,
  singleEmissionValidationSchema,
  validateMatierePolluantElement,
} from "./validation/validation";
import MessageInfoField from "common/form/MessageInfoField";
import {
  requiredMessage,
  valeursConcentrationMessage,
} from "common/declarant/formik/formikMessages";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { isSearchStringInCollection } from "common/utils/methods";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import {
  EmissionsInModale,
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { AppareilInArray } from "../BlocAppareils/utils/types";
import { CombustibleInArray } from "../BlocCombustibles/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import {
  computeEmissionsAnnuelles,
  elementTooltip,
  shouldDisplayEcartEmission,
  updateEmission,
} from "./utils/utils";
import Row from "common/presentational/Row";
import {
  computeEmissionAnnuelleForIntrantSortant,
  createNewIntrantSortant,
} from "../../../../../toNow/Air/combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import Button from "common/button";
import SimpleInputWithTextLabelDisplayer from "common/form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { isCo2 } from "../../../../../toNow/Air/combustionProcedeUtils/biomasse/helpers";
import {
  getAssociatedPolluantElementsForPolluantWithAutreLastPosition,
  isElementSelectedAutre,
  shouldElementNameBeFilled,
  shouldPartBeFilled,
} from "../../../../../toNow/Air/combustionProcedeUtils/bilanMatiere/elementsHelpers";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { arrayUniteLabel } from "../BlocCombustibles/utils/selectPossibleValues";
import {
  computeCombustibleLabel,
  filterAppareilsForCombustible,
  getAppareilList,
  getCombustiblesForInstallation,
  getMessageEcartEmission,
} from "./utils/formUtils";
import {
  arrayCombustionConcentrationUnits,
  arrayCombustionConcentrationUnitsLabel,
} from "../../utils/selectPossibleUnits";

interface FormEmissionProps {
  closeFunction: () => void;
  onSubmit: (
    values: EmissionsInModale,
    formikActions: FormikActions<EmissionsInModale>
  ) => void;
  initialEmission: EmissionsInModale;
  installationName: string | null;
  facteurEmissionsInArray: FacteurEmissionInArray[];
  matiereEmissionsInArray: MatiereEmissionInArray[];
  mesureEmissionsInArray: MesureEmissionInArray[];
  substancesAir: ReferenceItemPolluantDto[];
  appareilsInPage: AppareilInArray[];
  combustiblesInPage: CombustibleInArray[];
  referentialSinglePolluants: ReferentialSinglePolluants;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  autreElementUuid: string;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
  mBottom: {
    marginBottom: "5px",
  },
  heightAuto: {
    height: "auto",
  },
  calculatedField: {
    backgroundColor: "white",
    color: SECTION_TITLE_GREY,
    border: `1px solid ${SECTION_TITLE_GREY}`,
    "& input": {
      backgroundColor: "white",
    },
  },
  ...LEFT_WITHDRAW_STYLE,
  ...DYNAMIC_ELEMENTS_STYLE,
});

const FormSingleEmission = ({
  closeFunction,
  onSubmit,
  initialEmission,
  installationName,
  substancesAir,
  combustiblesInPage,
  appareilsInPage,
  referentialSinglePolluants,
  polluantElementMap,
  autreElementUuid,
}: FormEmissionProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emission-emission-individuelle",
  };
  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const stringInstallationName = installationName || "";

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE ÉMISSION"
      isEdit={initialEmission.facteurOxydation !== 100}
      closeFunction={closeFunction}
      onSubmit={(values: EmissionsInModale, formikBag) => {
        onSubmit(
          updateEmission(
            values,
            installationName,
            referentialSinglePolluants,
            autreElementUuid
          ),
          formikBag
        );
      }}
      initialEntity={initialEmission}
      validationSchema={() =>
        singleEmissionValidationSchema(referentialSinglePolluants.CO2)
      }
      validate={values => {
        let errors = {};

        if (
          values.combustible &&
          values.combustible.data !== null &&
          values.combustible.data.type &&
          values.substance &&
          shouldDisplayEcartEmission(values, referentialSinglePolluants) &&
          !values.ecart
        ) {
          errors = { ...errors, ecart: requiredMessage };
        }

        if (matiereSubPartActivated(values.methods)) {
          errors = {
            ...errors,
            ...validateMatierePolluantElement(
              values,
              polluantElementMap,
              autreElementUuid
            ),
          };
        }

        return errors;
      }}
      renderField={({ setValues, values }) => {
        const onSelectSubstance = (
          newValue: ReferenceItemPolluantDto | null
        ) => {
          // Reset facteur oxydation
          if (
            newValue &&
            newValue.uuid !== referentialSinglePolluants.CO2.uuid
          ) {
            values.facteurOxydation = 100;
          }

          // Reset elementProps
          values.elementProps = null;
        };
        const emissionsAnnuelles = computeEmissionsAnnuelles(
          values,
          autreElementUuid
        );
        const appareilsField = (combustible: CombustibleInArray | null) => (
          <WrappedChoiceSelect
            name="appareils"
            label="Appareil(s) émetteur(s) *"
            options={getAppareilList(
              stringInstallationName,
              combustible,
              appareilsInPage
            )}
            isMulti
            commonProps={commonProps}
            computeLabel={appareil => appareil.data.nom || ""}
          />
        );

        return (
          <>
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation"
              value={installationName}
              disabled
            />
            <WrappedChoiceSelectModale
              name="substance"
              label="Substance *"
              title="SUBSTANCES DANS L'AIR"
              header={["CAS", "Libellé", "Seuil (kg/an)"]}
              linesData={substancesAir}
              formatLine={substance => [
                substance.cas || "",
                substance.nom,
                substance.seuilKgAn !== null
                  ? computeNumberWithSeparator(substance.seuilKgAn)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.nom}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.cas, lineData.nom],
                  searchedStr
                )
              }
              onSelect={onSelectSubstance}
              commonProps={commonProps}
            />
            <WrappedChoiceSelect
              name="methods"
              label="Choix de la méthode *"
              isMulti={false}
              options={arrayMethods}
              additionalOnChange={value => {
                if (facteurSubPartActivated(value) && values.combustible) {
                  setValues({
                    ...values,
                    methods: value,
                    appareils: filterAppareilsForCombustible(
                      values.combustible,
                      values.appareils,
                      stringInstallationName
                    ),
                  });
                }
              }}
              commonProps={commonProps}
              computeLabel={method => arrayMethodsLabel[method]}
            />
            {anySubPartActivated(values.methods) &&
              !facteurSubPartActivated(values.methods) &&
              appareilsField(null)}
            {facteurSubPartActivated(values.methods) && (
              <>
                <WrappedChoiceSelect
                  name="combustible"
                  label="Choix du combustible *"
                  isMulti={false}
                  options={getCombustiblesForInstallation(
                    combustiblesInPage,
                    stringInstallationName
                  )}
                  additionalOnChange={combustible => {
                    setValues({
                      ...values,
                      combustible: combustible,
                      appareils: filterAppareilsForCombustible(
                        combustible,
                        values.appareils,
                        stringInstallationName
                      ),
                    });
                  }}
                  commonProps={commonProps}
                  computeLabel={computeCombustibleLabel}
                />

                {values.combustible && appareilsField(values.combustible)}

                <DummyTextField
                  name="referenceCombustible"
                  label="Référence du combustible"
                  value={values.combustible ? values.combustible.data.code : ""}
                  disabled
                />
                <DummyNumberField
                  name="consoAnnuelle"
                  label="Consommation annuelle"
                  value={
                    values.combustible
                      ? values.combustible.data.consommation
                      : null
                  }
                  unit={""}
                  disabled
                />
                <DummyTextField
                  name="unite"
                  label="Unité"
                  value={
                    values.combustible && values.combustible.data.unite
                      ? arrayUniteLabel[values.combustible.data.unite]
                      : ""
                  }
                  disabled
                />
                <NumberField
                  name="facteur"
                  label="Facteur d'émission *"
                  unit="kg/GJ"
                />
                {shouldDisplayEcartEmission(
                  values,
                  referentialSinglePolluants
                ) && (
                  <>
                    <MessageInfoField
                      message={getMessageEcartEmission(
                        values,
                        referentialSinglePolluants
                      )}
                      additionalClassname={classes.heightAuto}
                    />
                    <TextField
                      name="ecart"
                      label="Préciser écart facteur d'émission *"
                    />
                  </>
                )}

                <TextField
                  name="provenance"
                  label="Provenance du facteur d'émission *"
                />
                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <>
                    <DummyNumberField
                      name="biomasseFacteur"
                      label="Fraction de la biomasse (%) *"
                      unit="%"
                      value={
                        values.combustible &&
                        values.combustible.data.biogazFraction
                      }
                      disabled
                    />
                    <NumberField
                      name="facteurOxydation"
                      label="Facteur d'oxydation du carbone *"
                      unit="%"
                    />
                    {generateWarningMessageIfUnder1Percent(
                      values.facteurOxydation
                    )}
                    <TextField
                      name="provenanceFacteurOxydation"
                      label="Provenance du facteur d'oxydation du carbone"
                    />
                  </>
                )}
              </>
            )}
            {mesureSubPartActivated(values.methods) && (
              <>
                <NumberField
                  name="heures"
                  label="Nombre d'heures de fonctionnement *"
                  unit="h/an"
                  tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
                />
                <NumberField
                  name="debit"
                  label="Débit horaire moyen des effluents *"
                  unit="Nm³/h"
                />
                <BooleanField
                  name="continuDebit"
                  label="Mesure en continu du débit"
                />
                {values.continuDebit === false && (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceDebit"
                      label="Fréquence de la mesure du débit *"
                      unit="nb/an"
                    />
                  </div>
                )}
                <NumberField
                  name="concentrationDebit"
                  label="Concentration moyenne de polluant après traitement *"
                  unit=""
                />
                <WrappedChoiceSelect
                  name="concentrationDebitUnit"
                  label="Unité de la concentration moyenne de polluant après traitement *"
                  isMulti={false}
                  options={arrayCombustionConcentrationUnits}
                  commonProps={commonProps}
                  computeLabel={concentrationUnit =>
                    arrayCombustionConcentrationUnitsLabel[concentrationUnit]
                  }
                />
                <BooleanField
                  name="continuConcentration"
                  label="Mesure en continu de la concentration"
                />
                {values.continuConcentration === true ? (
                  <MessageInfoField message={valeursConcentrationMessage} />
                ) : (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceConcentration"
                      label="Fréquence de la mesure de concentration *"
                      unit="nb/an"
                    />
                  </div>
                )}
                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <>
                    <NumberField
                      name="biomasseMesure"
                      label="Fraction de la biomasse (%) *"
                      unit="%"
                    />
                    {generateWarningMessageIfUnder1Percent(
                      values.biomasseMesure
                    )}
                  </>
                )}
              </>
            )}
            {matiereSubPartActivated(values.methods) && (
              <>
                {shouldElementNameBeFilled(values, polluantElementMap) && (
                  <TextField
                    name="elementName"
                    label="Élément sur lequel est indexé le bilan matière *"
                    tooltipContent={elementTooltip}
                  />
                )}
                {!shouldElementNameBeFilled(values, polluantElementMap) && (
                  <>
                    <WrappedChoiceSelect
                      name="elementProps"
                      label="Élément sur lequel est indexé le bilan matière *"
                      isMulti={false}
                      options={getAssociatedPolluantElementsForPolluantWithAutreLastPosition(
                        values.substance,
                        polluantElementMap,
                        autreElementUuid
                      )}
                      tooltipContent={elementTooltip}
                      commonProps={commonProps}
                      computeLabel={elementProps =>
                        elementProps.referenceItemElementIndexDto.nom
                      }
                    />
                    {isElementSelectedAutre(
                      values.elementProps,
                      autreElementUuid
                    ) && (
                      <TextField name="elementName" label="Préciser autre *" />
                    )}
                  </>
                )}
                {shouldPartBeFilled(
                  values,
                  polluantElementMap,
                  autreElementUuid
                ) && (
                  <>
                    <NumberField
                      name="partElement"
                      label="Part de l'élément dans la substance émise *"
                      unit="%"
                      tooltipContent={
                        <span>
                          <p className={classes.mBottom}>
                            Exemple : Dans le cas de la détermination des
                            émissions de SO
                            <sub>2</sub> issues de la combustion de fuel lourd
                            ou de charbon, préciser la part molaire du soufre
                            sur lequel est indexé le calcul dans la masse
                            molaire totale du SO<sub>2</sub>
                            soit 50,05 % (détail : 100 * Masse molaire de S /
                            Masse molaire de SO
                            <sub>2</sub> = 100 * 32,066 / 64,065 = 50,05 %).
                          </p>
                          <p className={classes.mBottom}>
                            Aide : Principales masses molaires des éléments et
                            composés :
                            <ul className={classes.list}>
                              <li>
                                SO<sub>2</sub> dont la part de S est de 50,05 %
                                (32,066 / 64,065)
                              </li>
                              <li>
                                CO<sub>2</sub> dont la part de C est de 27,29 %
                                (12,011 / 44,010)
                              </li>
                              <li>
                                HCl dont la part de Cl est de 97,26 % (35,463 /
                                36,461)
                              </li>
                              <li>
                                HF dont la part de F est de 94,96 % (18,998 /
                                20,006)
                              </li>
                              <li>
                                voire les métaux lourds pour lesquels le ratio
                                est de 1.
                              </li>
                            </ul>
                          </p>
                          <p>
                            Remarque : il est essentiel pour les gros tonnages,
                            particulièrement ceux de CO2, que les ratios
                            utilisés soient le plus précis possibles tant les
                            écarts dûs aux arrondis peuvent rapidement être
                            convertis en des tonnages non négligeables.
                          </p>
                        </span>
                      }
                    />
                    {generateWarningMessageIfUnder1Percent(values.partElement)}
                  </>
                )}
                {!shouldPartBeFilled(
                  values,
                  polluantElementMap,
                  autreElementUuid
                ) && (
                  <DummyNumberField
                    name="partElement"
                    label="Part de l'élément dans la substance émise"
                    value={
                      values.elementProps &&
                      100 * values.elementProps.partElement
                    }
                    unit="%"
                    disabled
                  />
                )}

                <>
                  <Row />
                  <FieldArray
                    name="intrants"
                    render={arrayHelpers => (
                      <div>
                        {values.intrants &&
                          values.intrants.map((intrant, index) => (
                            <div key={index} className={classes.itemWrapper}>
                              <TextField
                                label={`Descriptif de l'intrant ${index + 1} *`}
                                name={`intrants.${index}.descriptif`}
                              />
                              <NumberField
                                label={`Quantité de l'intrant ${index + 1} *`}
                                name={`intrants.${index}.quantite`}
                                unit="kg"
                              />
                              <NumberField
                                label={`Teneur moyenne de l'élément dans l'intrant ${index +
                                  1} *`}
                                name={`intrants.${index}.teneurMoyenne`}
                                unit="%"
                              />
                              {generateWarningMessageIfUnder1Percent(
                                intrant.teneurMoyenne
                              )}
                              <DummyNumberField
                                name="emissionAnnuelle"
                                label="Émissions annuelles"
                                unit="kg/an"
                                disabled
                                additionalClassName={classes.calculatedField}
                                value={computeEmissionAnnuelleForIntrantSortant(
                                  intrant
                                )}
                              />
                              {values.intrants && values.intrants.length > 1 && (
                                <Row>
                                  <div className={classes.removeButtonRow}>
                                    <div
                                      className={classes.removeButtonWrapper}
                                    >
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        additionalClassname={
                                          classes.removeButton
                                        }
                                        text="Supprimer l'intrant"
                                      />
                                    </div>
                                  </div>
                                </Row>
                              )}
                              <Row />
                            </div>
                          ))}
                        <SimpleInputWithTextLabelDisplayer
                          label="Ajouter un nouvel intrant"
                          name="new-intrant"
                          errorData={{}}
                          commonProps={commonProps}
                          renderInput={() => (
                            <>
                              <button
                                type="button"
                                onClick={() => {
                                  arrayHelpers.push(createNewIntrantSortant());
                                }}
                                className={classes.iconButton}
                              >
                                +
                              </button>
                            </>
                          )}
                        />
                      </div>
                    )}
                  />
                  <Row />
                </>

                <>
                  <Row />
                  <FieldArray
                    name="sortants"
                    render={arrayHelpers => (
                      <div>
                        {values.sortants &&
                          values.sortants.map((sortant, index) => (
                            <div key={index} className={classes.itemWrapper}>
                              <TextField
                                label={`Descriptif du sortant ${index + 1} *`}
                                name={`sortants.${index}.descriptif`}
                              />
                              <NumberField
                                label={`Quantité du sortant ${index + 1} *`}
                                name={`sortants.${index}.quantite`}
                                unit="kg"
                                tooltipContent={
                                  <span>
                                    Il convient ici de recenser toute quantité
                                    exportée ou sortant des limites de
                                    l'installation autrement que sous forme
                                    atmosphérique (produits, matériaux, mises à
                                    l'égout ou en décharge, épuration, pertes)
                                    et les variations des stocks dans les
                                    limites de l'installation.
                                  </span>
                                }
                              />
                              <NumberField
                                label={`Teneur moyenne de l'élément dans le sortant ${index +
                                  1} *`}
                                name={`sortants.${index}.teneurMoyenne`}
                                unit="%"
                              />
                              <DummyNumberField
                                name="emissionAnnuelle"
                                label="Émissions annuelles"
                                unit="kg/an"
                                disabled
                                additionalClassName={classes.calculatedField}
                                value={computeEmissionAnnuelleForIntrantSortant(
                                  sortant
                                )}
                              />
                              {values.sortants && values.sortants.length >= 1 && (
                                <Row>
                                  <div className={classes.removeButtonRow}>
                                    <div
                                      className={classes.removeButtonWrapper}
                                    >
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        additionalClassname={
                                          classes.removeButton
                                        }
                                        text="Supprimer le sortant"
                                      />
                                    </div>
                                  </div>
                                </Row>
                              )}

                              <Row />
                            </div>
                          ))}
                        <SimpleInputWithTextLabelDisplayer
                          label="Ajouter un nouveau sortant"
                          name="new-sortant"
                          commonProps={commonProps}
                          errorData={{}}
                          renderInput={() => (
                            <button
                              type="button"
                              onClick={() => {
                                arrayHelpers.push(createNewIntrantSortant());
                              }}
                              className={classes.iconButton}
                            >
                              +
                            </button>
                          )}
                        />
                      </div>
                    )}
                  />
                  <Row />
                </>

                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <>
                    <NumberField
                      name="biomasseMatiere"
                      label="Fraction de la biomasse (%) *"
                      unit="%"
                    />
                    {generateWarningMessageIfUnder1Percent(
                      values.biomasseMatiere
                    )}
                  </>
                )}
              </>
            )}
            {anySubPartActivated(values.methods) && (
              <>
                <BooleanField
                  name="epuration"
                  label="Les émissions font-elles l'objet d'épuration ?"
                />
                {values.epuration === true && (
                  <div className={classes.withdrawLeft}>
                    <TextField
                      name="nature"
                      label="Nature des équipements *"
                      tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                    />
                    <NumberField
                      name="rendement"
                      label="Rendement de l'épuration *"
                      unit="%"
                      tooltipContent="Le rendement global d'épuration pour tous les équipements est attendu."
                    />
                    {generateWarningMessageIfUnder1Percent(values.rendement)}
                  </div>
                )}
                <DummyNumberField
                  name="emissionsAnnuelles"
                  label="Émissions annuelles"
                  unit="kg/an"
                  disabled
                  value={emissionsAnnuelles !== null ? emissionsAnnuelles : ""}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleEmission;
