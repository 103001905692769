import {
  AirCombustionAppareilDto,
  AirCombustionInstallationsDto20NowTypeEnum,
} from "api/gen";
import { AppareilInArray } from "./types";
import {
  arrayInstallationsRequiredCategories,
  InstallationCategory,
} from "../../../BlocInstallation/utils/selectPossibleValues";

//=============================================================
// APPAREILS
//=============================================================
export const convertAppareilsToDisplayed = (
  appareil: AirCombustionAppareilDto
): AppareilInArray => {
  return {
    data: {
      nom: appareil.nom,
      nameInstallation: appareil.installation,
      date: appareil.date ? new Date(appareil.date) : null,
      modification: appareil.modification,
      lowestThan1500: appareil.lowestThan1500,
      localisation: appareil.localisation,
      activite: appareil.activite,
      nature: appareil.nature,

      natureAutre: appareil.natureAutre,
      typeFoyer: appareil.typeFoyer,
      foyerAutre: appareil.foyerAutre,
      capacite: appareil.capacite,
      unite: appareil.unite,
      hauteurRejets: appareil.hauteurRejets,
      id: appareil.id,
    },
    errors: {},
  };
};

export const createAirCombustionsAppareilDto = (
  appareilsInPage: AppareilInArray[]
): AirCombustionAppareilDto[] => {
  return appareilsInPage.map(singlePopulatedAppareil => {
    const singleAppareil = singlePopulatedAppareil.data;
    const singleAppareilDto: AirCombustionAppareilDto = {
      id: singlePopulatedAppareil.data.id,
      nom: singleAppareil.nom,
      installation: singleAppareil.nameInstallation,
      date: singleAppareil.date
        ? singleAppareil.date.toISOString().split(".")[0] + "Z"
        : null,
      modification: singleAppareil.modification,
      lowestThan1500: singleAppareil.lowestThan1500,
      localisation: singleAppareil.localisation,
      activite: singleAppareil.activite,
      nature: singleAppareil.nature,
      natureAutre: singleAppareil.natureAutre,
      typeFoyer: singleAppareil.typeFoyer,
      foyerAutre: singleAppareil.foyerAutre,
      capacite: singleAppareil.capacite,
      unite: singleAppareil.unite,
      hauteurRejets: singleAppareil.hauteurRejets,
    };
    return singleAppareilDto;
  });
};

export const isAppareilSubpartFormAvailable = (
  installationType: AirCombustionInstallationsDto20NowTypeEnum | null
): boolean => {
  if (!installationType) {
    return false;
  }
  return arrayInstallationsRequiredCategories[installationType].includes(
    InstallationCategory.LCP
  );
};
