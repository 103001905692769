import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { NoiseMeasuresForm } from "./types";

export const convertDtoToNoiseMeasuresForm = (
  declaration: Declaration1919
): NoiseMeasuresForm => {
  return {
    noiseMeasures: true,
    noiseMeasuresDone: !!declaration.body.sections.carriere.environnement.bruit
      .mesures.mesureEffectuee,
    noiseMeasuresPointsNumber:
      declaration.body.sections.carriere.environnement.bruit.mesures
        .nbPointsMesure,
    noiseMeasuresCampaignNumber:
      declaration.body.sections.carriere.environnement.bruit.mesures
        .nbCampagnesMesure,
    specificNoisesMeasures:
      declaration.body.sections.carriere.environnement.bruit
        .mesuresSpecifiquesDansEnvironnement,
    specificNoisesMeasuresDone: !!declaration.body.sections.carriere
      .environnement.bruit.mesuresSpecifiques.mesureEffectuee,
    specificNoiseMeasuresPointsNumber:
      declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques
        .nbPointsMesure,
    specificNoiseMeasuresCampaignNumber:
      declaration.body.sections.carriere.environnement.bruit.mesuresSpecifiques
        .nbCampagnesMesure,
  };
};
