import React from "react";
import { makeStyles } from "@material-ui/styles";
import * as Yup from "yup";
import { commonPositiveNumberFieldsNullable } from "common/declarant/formik/formikHelper";
import { useNumberFieldGenerator } from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { convertDeclarationDtoUsingEmissionsForm } from "../utils/converters";
import {
  PATH_AIR_ELEVAGE_EMISSION,
  PATH_AIR_ELEVAGE_EMISSION_CH4,
  PATH_AIR_ELEVAGE_EMISSION_N2O,
  PATH_AIR_ELEVAGE_EMISSION_NH3,
  PATH_AIR_ELEVAGE_EMISSION_PM10,
  PATH_AIR_ELEVAGE_EMISSION_TSP,
} from "common/path/path18Now";
import {
  computeLabelCH4,
  computeLabelN2O,
  computeLabelNH3,
  computeLabelPM10,
} from "common/form/subs/utils";
import { EmissionsForm } from "../utils/types";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

interface BlocAutresEmissionsProps {
  formEmissionsProps: FormikBlocRefAndState<EmissionsForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
  },
});

const validationSchema = Yup.object().shape({
  CH4Emission: commonPositiveNumberFieldsNullable,
  N2OEmission: commonPositiveNumberFieldsNullable,
  PM10Emission: commonPositiveNumberFieldsNullable,
});

const BlocAutresEmissions = ({
  formEmissionsProps,
}: BlocAutresEmissionsProps) => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    formPrefix: "bloc-autres-emissions",
    labelWidth: "50%",
    className: classes.inputField,
  };

  const NumberField = useNumberFieldGenerator(commonProps);

  const initialValues: EmissionsForm = {
    NH3Emission: declaration.body.sections.air.elevage.emissions.nh3,
    CH4Emission: declaration.body.sections.air.elevage.emissions.ch4,
    N2OEmission: declaration.body.sections.air.elevage.emissions.n2o,
    PM10Emission: declaration.body.sections.air.elevage.emissions.pm10,
    TSPEmission: declaration.body.sections.air.elevage.emissions.tsp,
  };

  return (
    <FormikBlocFullContext1919
      hasChanges={formEmissionsProps.hasChanges}
      setHasChanges={formEmissionsProps.setHasChanges}
      formikRef={formEmissionsProps.formikRef}
      title={"ÉMISSIONS TOTALES"}
      isSingle={false}
      validationSchema={validationSchema}
      initialValues={initialValues}
      updateHandler={convertDeclarationDtoUsingEmissionsForm}
      pathToValidate={PATH_AIR_ELEVAGE_EMISSION}
      renderContent={(_, shouldDisabledFields: boolean) => (
        <>
          <NumberField
            label={computeLabelNH3}
            name="NH3Emission"
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_NH3}
          />

          <NumberField
            label={computeLabelN2O}
            name="N2OEmission"
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_N2O}
          />

          <NumberField
            label={computeLabelCH4}
            name="CH4Emission"
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_CH4}
          />

          <NumberField
            label="TSP"
            name="TSPEmission"
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_TSP}
          />

          <NumberField
            label={computeLabelPM10}
            name="PM10Emission"
            unit="kg/an"
            disabled={shouldDisabledFields}
            commentPath={PATH_AIR_ELEVAGE_EMISSION_PM10}
          />
        </>
      )}
    />
  );
};

export default BlocAutresEmissions;
