import { CombustibleInModale } from "./types";
import {
  AirCombustionCombustibleDto20NowUniteEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
  ReferenceItemCombustibleDto,
  ReferenceItemCombustibleDtoBiomasseEnum,
} from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import {
  isUniteEnergy,
  isUniteMasse,
  isUniteVolume,
  isUnitPCIEnergyByMasse,
} from "./selectPossibleValues";

export const shouldDisplayBiogazEcart = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): boolean => {
  if (values.type && values.biogazFraction !== null) {
    if (combustible && combustible.biomasse) {
      switch (combustible.biomasse) {
        case ReferenceItemCombustibleDtoBiomasseEnum.NON:
          return values.biogazFraction > 0;
        case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
          return values.biogazFraction < 100;
        case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
          return false;
        default:
          throw new ShouldNotHappen(combustible.biomasse);
      }
    }
  }

  return false;
};

export const shouldDisplayMasseVolumique = (
  values: CombustibleInModale
): boolean => {
  return shouldDisplayMasseVolumiqueProperties(
    values.consommation,
    values.unite,
    values.pciUnite
  );
};

export const shouldDisplayMasseVolumiqueProperties = (
  consommation: number | null,
  unite: AirCombustionCombustibleDto20NowUniteEnum | null,
  pciUnite: AirCombustionCombustiblePciDtoUniteEnum | null
): boolean => {
  if (consommation !== null && unite && pciUnite) {
    return (
      consommation >= 0 &&
      ((isUniteMasse(unite) && !isUnitPCIEnergyByMasse(pciUnite)) ||
        (isUniteVolume(unite) && isUnitPCIEnergyByMasse(pciUnite)))
    );
  }
  return false;
};

export const shouldDisplayPreciserMasseVolumique = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): boolean => {
  return !!(
    shouldDisplayMasseVolumique(values) &&
    values.masse !== null &&
    combustible &&
    combustible.masseVolumiqueInf !== null &&
    combustible.masseVolumiqueSup !== null &&
    (values.masse < combustible.masseVolumiqueInf ||
      values.masse > combustible.masseVolumiqueSup)
  );
};

export const getMessagePreciserMasseVolumique = (
  combustible: ReferenceItemCombustibleDto | null
): string => {
  return combustible
    ? `La masse volumique renseignée est éloignée de la valeur communément admise pour ce combustible, à savoir ${combustible.masseVolumique} kg/L ou t/m3.`
    : "";
};

export const getMessageBiogazEcart = (
  values: CombustibleInModale,
  combustible: ReferenceItemCombustibleDto | null
): string => {
  if (values.type && values.biogazFraction !== null) {
    if (combustible && combustible.biomasse) {
      switch (combustible.biomasse) {
        case ReferenceItemCombustibleDtoBiomasseEnum.NON:
          return `${combustible.designation} est un combustible non biomasse. La fraction biomasse déclarée devrait être 0%.`;
        case ReferenceItemCombustibleDtoBiomasseEnum.OUI:
          return `${combustible.designation} est un combustible totalement biomasse. La fraction biomasse déclarée devrait être 100%.`;
        case ReferenceItemCombustibleDtoBiomasseEnum.PARTIEL:
          return `${combustible.designation} est un combustible partiellement biomasse. La fraction biomasse déclarée devrait être comprise entre 0 et 100%.`;
        default:
          throw new ShouldNotHappen(combustible.biomasse);
      }
    }
    return "";
  }
  return "";
};

export const shouldDisplayPciFields = (
  unite: AirCombustionCombustibleDto20NowUniteEnum | undefined
): boolean => {
  return !unite || !isUniteEnergy(unite);
};
