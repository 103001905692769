import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  selectable: {
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  selected: {
    textDecoration: "underline",
    fontWeight: "bold",
  },
});

export const NUMBER_OF_LINES_OPTIONS = [10, 50, 100];

interface NumberOfLinesSelectorProps {
  title: string;
  values?: number[];
  selected: number;
  onChange: (numberOfLines: number) => void;
}

const NumberOfLinesSelector = ({
  title,
  values = NUMBER_OF_LINES_OPTIONS,
  selected,
  onChange,
}: NumberOfLinesSelectorProps): React.ReactElement => {
  const classes = useStyles();

  const createSelection = (value: number, isSelected: boolean = false) => {
    return (
      <span
        onClick={() => {
          if (!isSelected) {
            onChange(value);
          }
        }}
        className={classNames({
          [classes.selectable]: !isSelected,
          [classes.selected]: isSelected,
        })}
      >
        {value}
      </span>
    );
  };

  return (
    <p>
      {values.map((value, index) => {
        const isLast = index === values.length - 1;
        return (
          <Fragment key={index}>
            {createSelection(value, value === selected)}
            {isLast ? <>&nbsp;</> : <>&nbsp;/&nbsp;</>}
          </Fragment>
        );
      })}
      {title}
    </p>
  );
};

export default NumberOfLinesSelector;
