import React from "react";
import { makeStyles } from "@material-ui/styles";
import { QuotasInstallationDto20Now } from "api/gen";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import { computeEmissionCell } from "../MethodesEmissionsData/EmissionsArrays/arrayUtils";
import { computeSum } from "common/utils/numberUtils";
import { BilanNonBiomasseDto } from "./types";

const useStyles = makeStyles({
  arrayContainer: {
    width: "95%",
    marginTop: "20px",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface BilanNonBiomasseArrayProps {
  installation: QuotasInstallationDto20Now;
  bilanNonBiomasseDto: BilanNonBiomasseDto;
}

const BilanNonBiomasseArray = ({
  installation,
  bilanNonBiomasseDto,
}: BilanNonBiomasseArrayProps): React.ReactElement => {
  const classes = useStyles();

  const unitLabel = " t CO2e/an";

  const computeBilanTotalLine = (
    bilanNonBiomasseDto: BilanNonBiomasseDto,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(installation.nim, classes),
      computeWrappedCell(
        computeEmissionCell(
          computeSum(bilanNonBiomasseDto.emissionsCo2NonBiomasseList)
        ) + unitLabel,
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(
          computeSum(bilanNonBiomasseDto.emissionsN2oNonBiomasseList)
        ) + unitLabel,
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(bilanNonBiomasseDto.emissionsPfcNonBiomasse ?? 0) +
          unitLabel,
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(
          bilanNonBiomasseDto.emissionsTotalesNonBiomasse ?? 0
        ) + unitLabel,
        classes
      ),
    ];
  };

  const emissionLines = [computeBilanTotalLine(bilanNonBiomasseDto, classes)];

  return (
    <CommonEntityTable
      header={[
        <p>NIM</p>,
        <p>Émissions de CO2</p>,
        <p>Émissions de N2O</p>,
        <p>Émissions de PFC</p>,
        <p>Émissions totales</p>,
      ]}
      lines={emissionLines}
      isFirstColSticky={false}
      isLastColSticky={false}
      preferredColWidths={[1, 1, 1, 1, 1]}
      additionalClassName={classes.arrayContainer}
    />
  );
};

export default BilanNonBiomasseArray;
