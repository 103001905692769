import React from "react";
import {
  PopulatedSubstanceExtractionInArray,
  SubstanceExtractionProps,
} from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import _ from "lodash";
import {
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import uuid from "uuid";
import {
  familiesProductionValidationSchema,
  validateFamiliesForSubstanceExtractionInArray,
} from "../Families/validation";
import {
  handleFamiliesProductionCommonFormSingleEntitySubmit,
  onSelectFamilyHandler,
  onSelectSubFamilyHandler,
  onSelectSubFamilyLvl2Handler,
} from "../Families/utils";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";
import { SUBSTANCES_TO_RECYCLE, YEARLY_AMOUNT } from "./utils/languages";
import {
  PRECISION_SUB_USAGE_FAMILY,
  PRECISION_USAGE_FAMILY,
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "../Families/languages";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import {
  ReferenceFamilleUsageProductionDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import {
  familiesProduction,
  subFamiliesProduction,
  subFamiliesProductionLvl2,
} from "../Families/selectPossibleValues";

interface SubstanceExtractionModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  substanceExtractionInModal: PopulatedSubstanceExtractionInArray | undefined;
  setSubstanceExtractionInModal: (
    substance: PopulatedSubstanceExtractionInArray | undefined
  ) => void;
  substancesExtractionInArray: PopulatedSubstanceExtractionInArray[];
  setSubstancesExtractionInArray: (
    substances: PopulatedSubstanceExtractionInArray[]
  ) => void;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = familiesProductionValidationSchema({
  substance: commonObjectFields,
  annualQuantity: commonPositiveNumberFields,
});

const SubstanceExtractionModal = ({
  isOpen,
  setIsModalOpen,
  substanceExtractionInModal,
  setSubstanceExtractionInModal,
  substancesExtractionInArray,
  setSubstancesExtractionInArray,
  referentialFamilies,
}: SubstanceExtractionModalProps) => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-activite-extractive",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);

  const onClose = () => {
    setSubstanceExtractionInModal(undefined);
    setIsModalOpen(false);
  };

  const initialValue: Partial<SubstanceExtractionProps> = _.get(
    substanceExtractionInModal,
    "displayedSubstance",
    {}
  );

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <DeprecatedCommonFormSingleEntity
        title="AJOUTER UNE SUBSTANCE"
        validationSchema={validationSchema}
        initialEntity={initialValue}
        closeFunction={onClose}
        onSubmit={values => {
          handleFamiliesProductionCommonFormSingleEntitySubmit(values);
          const substance: PopulatedSubstanceExtractionInArray = {
            id: substanceExtractionInModal
              ? substanceExtractionInModal.id
              : uuid(),
            displayedSubstance: {
              substance: values.substance,
              annualQuantity: values.annualQuantity,
              usageFamily: values.usageFamily,
              usageFamilyPrecision: values.usageFamilyPrecision,
              usageSubFamily: values.usageSubFamily,
              usageSubFamilyPrecision: values.usageSubFamilyPrecision,
              usageSubFamiliesLvl2: values.usageSubFamiliesLvl2,
              usageSubFamiliesLvl2Precisions:
                values.usageSubFamiliesLvl2Precisions,
            },
          };
          const newSubstancesExtractionInArray = substancesExtractionInArray;
          let indexToReplace = newSubstancesExtractionInArray.length;
          if (substanceExtractionInModal) {
            indexToReplace = _.findIndex(
              newSubstancesExtractionInArray,
              substanceExtractionInModal
            );
            setSubstanceExtractionInModal(undefined);
          }
          newSubstancesExtractionInArray[indexToReplace] = substance;
          setSubstancesExtractionInArray(newSubstancesExtractionInArray);
          setIsModalOpen(false);
        }}
        validate={values =>
          validateFamiliesForSubstanceExtractionInArray(
            values,
            substancesExtractionInArray
          )
        }
        renderField={({ values, setFieldValue }) => (
          <>
            <WrappedChoiceSelectModale
              label={`${SUBSTANCES_TO_RECYCLE} *`}
              name="substance"
              title="LISTE DES SUBSTANCES"
              header={["Code", "Libellé"]}
              linesData={selectPossibleValues.substances}
              formatLine={substance => [
                substance.object.code && substance.object.code.toString(),
                substance.label,
              ]}
              formatSelectedTitle={lineData => lineData.label}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.object.code.toString(), lineData.label],
                  searchedStr
                )
              }
              commonProps={commonProps}
            />
            <NumberField
              label={`${YEARLY_AMOUNT} *`}
              name="annualQuantity"
              unit="ktonnes"
            />
            <ChoiceSelect
              label={`${USAGE_FAMILY} *`}
              name="usageFamily"
              isMulti={false}
              options={familiesProduction(referentialFamilies)}
              additionalOnChange={() => onSelectFamilyHandler(setFieldValue)}
            />
            {values.usageFamily && values.usageFamily.object.precision && (
              <TextField
                label={`${PRECISION_USAGE_FAMILY} *`}
                name="usageFamilyPrecision"
              />
            )}
            {values.usageFamily &&
              values.usageFamily.object.referenceItemFamilleUsageDtos.length >
                0 && (
                <ChoiceSelect
                  label={`${SUB_USAGE_FAMILY} *`}
                  name="usageSubFamily"
                  isMulti={false}
                  options={subFamiliesProduction(values.usageFamily)}
                  additionalOnChange={() =>
                    onSelectSubFamilyHandler(setFieldValue)
                  }
                />
              )}
            {values.usageSubFamily &&
              values.usageSubFamily.object.precision && (
                <TextField
                  label={`${PRECISION_SUB_USAGE_FAMILY} *`}
                  name="usageSubFamilyPrecision"
                />
              )}
            {values.usageSubFamily &&
              values.usageSubFamily.object.referenceItemFamilleUsageDtos
                .length > 0 && (
                <ChoiceSelect
                  label={`${SUB_USAGE_FAMILY_LEVEL_2} *`}
                  name="usageSubFamiliesLvl2"
                  isMulti
                  options={subFamiliesProductionLvl2(values.usageSubFamily)}
                  additionalOnChange={selected =>
                    onSelectSubFamilyLvl2Handler(
                      setFieldValue,
                      // because we KNOW this choiceSelect has a isMulti attribute
                      selected as OptionPropsWithObject<
                        ReferenceItemFamilleUsageProductionDto
                      >[],
                      values
                    )
                  }
                />
              )}
            <FieldArray
              name="usageSubFamiliesLvl2Precisions"
              render={() => (
                <>
                  {values.usageSubFamiliesLvl2 &&
                    values.usageSubFamiliesLvl2
                      .filter(subFamilyLvl2 => subFamilyLvl2.object.precision)
                      .map((subFamilyLvl2, i) => {
                        return (
                          <TextField
                            label={`${PRECISION_SUB_USAGE_FAMILY} ${subFamilyLvl2.label} *`}
                            name={`usageSubFamiliesLvl2Precisions.${i}.precision`}
                            key={subFamilyLvl2.value}
                          />
                        );
                      })}
                </>
              )}
            />
          </>
        )}
      />
    </CustomModal>
  );
};

export default SubstanceExtractionModal;
