import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { getAppareils } from "./utils/utils";
import { filterByInstallation } from "../../utils/utils";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { CombustibleInArray } from "./utils/types";
import { FacteurEmissionInArray } from "../BlocEmissions/utils/types";
import _ from "lodash";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";
import {
  arrayBrutOuSecLabel,
  arrayPciUniteLabel,
  arraySurCendresLabel,
  arrayUniteLabel,
  computeLabelCh4AndBiogazMethodEstimation,
} from "./utils/selectPossibleValues";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface CombustibleArrayProps {
  combustiblesInArray: CombustibleInArray[];
  setCombustiblesInArray: Dispatch<SetStateAction<CombustibleInArray[]>>;
  setCombustibleInModale: Dispatch<SetStateAction<CombustibleInArray | null>>;
  setCombustibleModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationCombustiblesPath: string;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
}

const CombustibleArray = ({
  combustiblesInArray,
  setCombustiblesInArray,
  setCombustibleInModale,
  setCombustibleModaleOpen,
  isValidated,
  installationName,
  validationCombustiblesPath,
  facteursInPage,
  setFacteursInPage,
}: CombustibleArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressCombustible(singleCombustibleInArray: CombustibleInArray) {
    // Delete emissions link to the combustible
    const newCombustibles = combustiblesInArray.filter(combustible => {
      return singleCombustibleInArray.data.id !== combustible.data.id;
    });
    const combustiblesId = singleCombustibleInArray.data.id;
    const newFacteurs = facteursInPage.filter(facteur => {
      if (facteur.data.combustible) {
        return combustiblesId !== facteur.data.combustible.data.id;
      }
      return false;
    });
    setCombustiblesInArray(newCombustibles);
    setFacteursInPage(newFacteurs);
  }

  const computeSingleCombustibleLine = (
    combustible: CombustibleInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setCombustibleInModale(combustible);
      setCombustibleModaleOpen(true);
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      combustible.data.type !== null ? combustible.data.type.designation : "",
      isValidated,
      !_.isEmpty(combustible.errors)
    );

    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p title={combustible.data.nameInstallation || undefined}>
          {combustible.data.nameInstallation}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.consommation !== null
          ? computeNumberWithSeparator(combustible.data.consommation)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.unite !== null
          ? arrayUniteLabel[combustible.data.unite]
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.consommationPCI
          ? computeNumberWithSeparator(combustible.data.consommationPCI)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.appareils !== null
          ? getAppareils(combustible.data.appareils)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.masse !== null
          ? computeNumberWithSeparator(combustible.data.masse)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={combustible.data.masseEcart || undefined}>
          {combustible.data.masseEcart}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.eau !== null
          ? computeNumberWithSeparator(combustible.data.eau)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.carbone !== null
          ? computeNumberWithSeparator(combustible.data.carbone)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.soufre !== null
          ? computeNumberWithSeparator(combustible.data.soufre)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.surCendres !== null
          ? arraySurCendresLabel[combustible.data.surCendres]
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.cendres !== null
          ? computeNumberWithSeparator(combustible.data.cendres)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.chlore !== null
          ? computeNumberWithSeparator(combustible.data.chlore)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.pciValeur !== null
          ? computeNumberWithSeparator(combustible.data.pciValeur)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.pciUnite !== null
          ? arrayPciUniteLabel[combustible.data.pciUnite]
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={combustible.data.pciEcart || undefined}>
          {combustible.data.pciEcart}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.pciBrut !== null
          ? arrayBrutOuSecLabel[combustible.data.pciBrut]
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={combustible.data.pciProvenance || undefined}>
          {combustible.data.pciProvenance}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.biogazFraction !== null
          ? computeNumberWithSeparator(combustible.data.biogazFraction)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={combustible.data.biogazEcart || undefined}>
          {combustible.data.biogazEcart}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.biogazDebit !== null
          ? computeNumberWithSeparator(combustible.data.biogazDebit)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.biogazMesure !== null
          ? computeLabelCh4AndBiogazMethodEstimation(
              combustible.data.biogazMesure
            )
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.biogazFrequence !== null
          ? computeNumberWithSeparator(combustible.data.biogazFrequence)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.biogazDuree !== null
          ? computeNumberWithSeparator(combustible.data.biogazDuree)
          : null,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.ch4Teneur !== null
          ? computeNumberWithSeparator(combustible.data.ch4Teneur)
          : null,
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.ch4Mesure !== null
          ? computeLabelCh4AndBiogazMethodEstimation(combustible.data.ch4Mesure)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.ch4Frequence !== null
          ? computeNumberWithSeparator(combustible.data.ch4Frequence)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        combustible.data.quantiteMethaneOxyde !== null
          ? computeNumberWithSeparator(combustible.data.quantiteMethaneOxyde)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer ce combustible ?"}
        handlerSuppress={() => {
          suppressCombustible(combustible);
        }}
        isValidated={isValidated}
        commentPath={`${validationCombustiblesPath}/${combustible.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = filterByInstallation(
    combustiblesInArray,
    installationName || ""
  ).map(singleCombustibleInArray => {
    return computeSingleCombustibleLine(singleCombustibleInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Type de combustible</p>,
        <p>Nom de l'installation</p>,
        <p>Consommation annuelle</p>,
        <p>Unité</p>,
        <p>Consommation annuelle en PCI</p>,
        <p>Appareil(s) consommateur(s)</p>,
        <p>Masse volumique du combustible (kg/l&nbsp;=&nbsp;t/m3)</p>,
        <p>Préciser écart masse volumique</p>,
        <p>Teneur en eau (en&nbsp;%)</p>,
        <p>Teneur en carbone (en&nbsp;%)</p>,
        <p>Teneur en soufre (en&nbsp;%)</p>,
        <p>Sur cendres ou hors cendres</p>,
        <p>Teneur en cendre (en&nbsp;%)</p>,
        <p>Teneur en chlore (en&nbsp;%)</p>,
        <p>PCI (Pouvoir&nbsp;Calorifique&nbsp;Inférieur)</p>,
        <p>Unité PCI</p>,
        <p>Préciser écart PCI</p>,
        <p>Brut ou sec</p>,
        <p>Provenance du PCI</p>,
        <p>Fraction de la biomasse (%)</p>,
        <p>Préciser écart fraction biomasse</p>,
        <p>Débit du biogaz (m3/h)</p>,
        <p>Méthode d'estimation du débit</p>,
        <p>Fréquence de la mesure du débit</p>,
        <p>Temps de fonctionnement (h/an)</p>,
        <p>Teneur en CH4 (en&nbsp;%)</p>,
        <p>Méthode d'estimation de la teneur en CH4</p>,
        <p>Fréquence de la mesure en CH4</p>,
        <p>Quantité de méthane oxydé par combustion (1000&nbsp;m3)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        200,
        170,
        130,
        170,
        130,
        130,
        200,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        200,
        130,
        130,
        130,
        130,
        130,
        130,
        130,
        200,
        130,
        200,
        130,
        200,
        130,
        200,
        90,
      ]}
    />
  );
};

export default CombustibleArray;
