import React, { useContext, useLayoutEffect } from "react";
import { AnyDeclarationDto } from "../../CompanySpace/DeclarationApiContext/utils/types";

export interface DeclarationPageHeaderProps {
  etablissementTitle: string;
  declarationAnnee: string;
  declarationEtablissement: string;
  isDeclarantEmailAvailable: boolean;
  emailDeclarants: string[];
}
interface DeclarationPageHeaderContextProps {
  headerInfo: DeclarationPageHeaderProps | null;
  setter: (newData: DeclarationPageHeaderProps | null) => void;
}

export const DeclarationPageHeaderContext = React.createContext<DeclarationPageHeaderContextProps | null>(
  null
);
export const useDeclarationPageHeaderInfo = (): DeclarationPageHeaderProps | null => {
  const context = useContext(DeclarationPageHeaderContext);
  if (context === null) {
    throw Error("header can only be used inside App");
  } else {
    return context.headerInfo;
  }
};

export interface CommonDeclarationPageHeaderAutoUpdaterProps {
  children: React.ReactElement;
}

export interface GenericDeclarationPageHeaderAutoUpdaterProps<
  VersionedDeclarationDto extends AnyDeclarationDto
> extends CommonDeclarationPageHeaderAutoUpdaterProps {
  useDeclaration: () => VersionedDeclarationDto;
  getEtablissementName: (declarationDto: VersionedDeclarationDto) => string;
  computeIsDeclarantEmailAvailable: (
    declarationDto: VersionedDeclarationDto
  ) => boolean;
}

export const GenericDeclarationPageHeaderAutoUpdater = <
  VersionedDeclarationDto extends AnyDeclarationDto
>({
  children,
  useDeclaration,
  getEtablissementName,
  computeIsDeclarantEmailAvailable,
}: GenericDeclarationPageHeaderAutoUpdaterProps<
  VersionedDeclarationDto
>): React.ReactElement => {
  const declaration = useDeclaration();

  const context = useContext(DeclarationPageHeaderContext);

  const setter = context ? context.setter : null;

  const isDeclarantEmailAvailable: boolean = computeIsDeclarantEmailAvailable(
    declaration
  );

  const etablissementName: string = getEtablissementName(declaration);

  //  useLayoutEffect plutôt que useEffect pour synchroniser la mise à jour du header avec la mise à jour de l'enfant
  //  qui vient de set le contenu du header
  useLayoutEffect(() => {
    const newInfo = {
      declarationAnnee: declaration.annee.toString(),
      declarationEtablissement: declaration.etablissementCode,
      etablissementTitle: etablissementName,
      isDeclarantEmailAvailable: isDeclarantEmailAvailable,
      emailDeclarants: declaration.body.sections.infoGenerale.declarants
        .map(declarant => declarant.email)
        .filter((email): email is string => email !== null),
    };

    setter && setter(newInfo);

    // Important to keep this return since it allows to ensure header is rerendered with an empty data when the DeclarationPageHeaderContext is unmounted (i.e, when leaving a declaration page)
    return () => {
      setter && setter(null);
    };

    // be careful when changing dependency array : this hook mustn't be rerun on every declaration update,
    // that's why only primitive types are given as dependency.
  }, [
    setter,
    declaration.etablissementCode,
    etablissementName,
    declaration.annee,
    isDeclarantEmailAvailable,
    declaration.body.sections.infoGenerale.declarants,
  ]);

  return <>{children}</>;
};
