import React from "react";
import {
  addOrModifyArray,
  moveAndDeleteEmission,
} from "common/declarant/array/utils";
import CustomModal from "common/presentational/CustomModal";
import {
  AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
  ReferencePolluantDefaultUniteItemDto,
} from "api/gen";
import {
  EmissionsInArray,
  EmissionsInModale,
  FacteurEmissionInArray,
  FacteurEmissionInModale,
  MatiereEmissionInArray,
  MatiereEmissionInModale,
  MesureEmissionInArray,
  MesureEmissionInModale,
} from "./utils/types";
import { AppareilInArray } from "../BlocAppareils/utils/types";
import { CombustibleInArray } from "../BlocCombustibles/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import { MethodCombustion } from "./utils/selectPossibleValues";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { defaultEmissionInModaleInitialValue } from "./utils/utils";
import FormSingleEmission from "./FormSingleEmission";
import uuid from "uuid";

interface EmissionProps {
  emissionModaleOpen: boolean;
  emissionInModale: EmissionsInArray | null;
  setFacteurEmissionsInArray: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  setMesureEmissionsInArray: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  setMatiereEmissionsInArray: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  setEmissionInModale: React.Dispatch<
    React.SetStateAction<EmissionsInArray | null>
  >;
  setEmissionModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  facteurEmissionsInArray: FacteurEmissionInArray[];
  matiereEmissionsInArray: MatiereEmissionInArray[];
  mesureEmissionsInArray: MesureEmissionInArray[];
  installationName: string | null;
  substancesAir: ReferenceItemPolluantDto[];
  appareilsInPage: AppareilInArray[];
  combustiblesInPage: CombustibleInArray[];
  referentialSinglePolluants: ReferentialSinglePolluants;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElementAutreUuid: string;
  polluantDefaultUniteMap: Map<number, ReferencePolluantDefaultUniteItemDto>;
}

const EmissionModale = ({
  emissionModaleOpen,
  emissionInModale,
  setFacteurEmissionsInArray,
  setMesureEmissionsInArray,
  setMatiereEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  facteurEmissionsInArray,
  matiereEmissionsInArray,
  mesureEmissionsInArray,
  installationName,
  substancesAir,
  combustiblesInPage,
  appareilsInPage,
  referentialSinglePolluants,
  polluantElementMap,
  referentialElementAutreUuid,
  polluantDefaultUniteMap,
}: EmissionProps): React.ReactElement => {
  const onClose = () => {
    setEmissionInModale(null);
    setEmissionModaleOpen(false);
  };

  const defaultInitialValue: EmissionsInModale = defaultEmissionInModaleInitialValue;

  return (
    <CustomModal
      isOpen={emissionModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de emission de mention de danger."
      onRequestClose={onClose}
    >
      <FormSingleEmission
        referentialSinglePolluants={referentialSinglePolluants}
        polluantElementMap={polluantElementMap}
        polluantDefaultUniteMap={polluantDefaultUniteMap}
        autreElementUuid={referentialElementAutreUuid}
        combustiblesInPage={combustiblesInPage}
        appareilsInPage={appareilsInPage}
        substancesAir={substancesAir}
        facteurEmissionsInArray={facteurEmissionsInArray}
        matiereEmissionsInArray={matiereEmissionsInArray}
        mesureEmissionsInArray={mesureEmissionsInArray}
        installationName={installationName}
        closeFunction={onClose}
        onSubmit={values => {
          if (values.methods === null) {
            throw Error("Should not happen, check validation");
          }

          if (values.methods === MethodCombustion.FACTEUR_EMISSION) {
            const newEmission: FacteurEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              combustible: values.combustible,
              referenceCombustible: values.referenceCombustible,
              consoAnnuelle: values.consoAnnuelle,
              unite: values.unite,
              facteur: values.facteur,
              ecart: values.ecart,
              provenance: values.provenance,
              facteurOxydation: values.facteurOxydation,
              provenanceFacteurOxydation: values.provenanceFacteurOxydation,
            };
            if (
              !emissionInModale ||
              (emissionInModale.data.methods &&
                values.methods === emissionInModale.data.methods)
            ) {
              addOrModifyArray(
                setFacteurEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodCombustion.MESURE:
                  moveAndDeleteEmission(
                    setFacteurEmissionsInArray,
                    setMesureEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setFacteurEmissionsInArray,
                    setMatiereEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else if (values.methods === MethodCombustion.MESURE) {
            const newEmission: MesureEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              heures: values.heures,
              debit: values.debit,
              continuDebit: values.continuDebit,
              frequenceDebit: values.frequenceDebit,
              concentrationDebit: values.concentrationDebit,
              concentrationDebitUnit: values.concentrationDebitUnit,
              continuConcentration: values.continuConcentration,
              frequenceConcentration: values.frequenceConcentration,
              biomasseMesure: values.biomasseMesure,
              typeMesure: values.typeMesure,
            };
            if (
              !emissionInModale ||
              (emissionInModale.data.methods &&
                values.methods === emissionInModale.data.methods)
            ) {
              addOrModifyArray(
                setMesureEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodCombustion.FACTEUR_EMISSION:
                  moveAndDeleteEmission(
                    setMesureEmissionsInArray,
                    setFacteurEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setMesureEmissionsInArray,
                    setMatiereEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else if (values.methods === MethodCombustion.BILAN_MATIERE) {
            const newEmission: MatiereEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              intrants: values.intrants,
              sortants: values.sortants,
              elementProps: values.elementProps,
              elementName: values.elementName,
              partElement: values.partElement,
              biomasseMatiere: values.biomasseMatiere,
            };
            if (
              !emissionInModale ||
              (emissionInModale.data.methods &&
                values.methods === emissionInModale.data.methods)
            ) {
              addOrModifyArray(
                setMatiereEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods) {
                case MethodCombustion.FACTEUR_EMISSION:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInArray,
                    setFacteurEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.MESURE:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInArray,
                    setMesureEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(emissionInModale.data.methods);
              }
            }
          } else {
            throw new ShouldNotHappen(values.methods);
          }

          onClose();
        }}
        initialEmission={
          emissionInModale !== null
            ? { ...defaultInitialValue, ...emissionInModale.data }
            : {
                ...defaultInitialValue,
                facteurOxydation: 100,
                concentrationDebitUnit: null,
                intrants: [
                  {
                    descriptif: null,
                    id: uuid(),
                    quantite: null,
                    teneurMoyenne: null,
                  },
                ],
              }
        }
      />
    </CustomModal>
  );
};

export default EmissionModale;
