import { DatesCampagne, ShouldDisableDates } from "./types";
import { DATES_NAMES, NO_MODIF_OF_PAST_DATE_MESSAGE } from "./converters";
import { FormikErrors } from "formik/dist/types";
import { Nullable } from "common/utils/types";

/**
 * Utils method to add error on a field when it has been modified
 */
function setErrorWhenModif(
  values: Nullable<DatesCampagne>,
  initialValues: DatesCampagne,
  key: keyof DatesCampagne,
  errors: FormikErrors<DatesCampagne>
): FormikErrors<DatesCampagne> {
  const newValue = values[key];
  if (newValue !== null && newValue.localeCompare(initialValues[key]) !== 0) {
    errors[key] = NO_MODIF_OF_PAST_DATE_MESSAGE[key];
  }
  return errors;
}

/**
 * Utils method to add error on a field when it is before value to compare
 */
function setErrorWhenBeforeValue(
  values: Nullable<DatesCampagne>,
  key: keyof DatesCampagne,
  valueToCampare: string | null,
  nameValueToCompare: string,
  errors: FormikErrors<DatesCampagne>
): FormikErrors<DatesCampagne> {
  const newValue = values[key];
  if (
    newValue !== null &&
    valueToCampare !== null &&
    newValue.localeCompare(valueToCampare) < 0
  ) {
    errors[
      key
    ] = `La ${DATES_NAMES[key]} ne peut pas être antérieure à la ${nameValueToCompare}.`;
  }
  return errors;
}

/**
 * Same as setErrorWhenBeforeValue but using key of another value in values
 */
function setErrorWhenBefore(
  values: Nullable<DatesCampagne>,
  key: keyof DatesCampagne,
  keyToCompare: keyof DatesCampagne,
  errors: FormikErrors<DatesCampagne>
): FormikErrors<DatesCampagne> {
  return setErrorWhenBeforeValue(
    values,
    key,
    values[keyToCompare],
    DATES_NAMES[keyToCompare],
    errors
  );
}

/**
 * Utils method to add error on a field when it is after value to compare
 */
function setErrorWhenAfterValue(
  values: Nullable<DatesCampagne>,
  key: keyof DatesCampagne,
  valueToCampare: string | null,
  nameValueToCompare: string,
  errors: FormikErrors<DatesCampagne>
): FormikErrors<DatesCampagne> {
  const newValue = values[key];
  if (
    newValue !== null &&
    valueToCampare !== null &&
    newValue.localeCompare(valueToCampare) > 0
  ) {
    errors[
      key
    ] = `La ${DATES_NAMES[key]} doit être avant la ${nameValueToCompare}.`;
  }
  return errors;
}

/**
 * Same as setErrorWhenAfterValue but using key of another value in values
 */
function setErrorWhenAfter(
  values: Nullable<DatesCampagne>,
  key: keyof DatesCampagne,
  keyToCompare: keyof DatesCampagne,
  errors: FormikErrors<DatesCampagne>
): FormikErrors<DatesCampagne> {
  return setErrorWhenAfterValue(
    values,
    key,
    values[keyToCompare],
    DATES_NAMES[keyToCompare],
    errors
  );
}

export const generateValidateDatesCampagne = (
  initialValues: DatesCampagne,
  shouldDisable: ShouldDisableDates,
  minimumDate: string,
  minimumDateName: string
) => (values: Nullable<DatesCampagne>): FormikErrors<DatesCampagne> => {
  let errors: FormikErrors<DatesCampagne> = {};

  Object.entries(values).forEach(([keyCampaignDate, valueCampaignDate]) => {
    // given the documentation of object.entries and the type of values ( a Nullable of DatesCampagne),
    // keyCampaignDate CAN'T be different from a keyof DatesCampaign (since Nullable<DatesCampagne> have
    // the same key than DatesCampagne
    const typescriptValidKeyCampaignDate = keyCampaignDate as keyof DatesCampagne;

    if (valueCampaignDate === null) {
      errors[
        typescriptValidKeyCampaignDate
      ] = `Le champ ${DATES_NAMES[typescriptValidKeyCampaignDate]} ne doit pas être vide.`;
    }
  });

  /* Note : Here is the workflow of the campaign, as extracted from the constraints in the database :
   *
   *     start ─┬─> endAllocationsDeclarants ──> endAllocations
   *            ├─> endEmissionsDeclarants ────> endEmissions
   *            └─> endGlobalDeclarants ───────> endGlobal
   *
   * Each sub-campaigns are independent, but they start at the same date
   */

  // Note n°2 : we use the fact that in pseudo-ISO form, we have the year first, then the month,
  // then the day of the month, so a lexical compare does exactly what we need

  ////////////////////////////////
  //            START           //
  ////////////////////////////////
  if (shouldDisable.start) {
    errors = setErrorWhenModif(values, initialValues, "start", errors);
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "start",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenAfter(
      values,
      "start",
      "endAllocationsDeclarants",
      errors
    );
    errors = setErrorWhenAfter(
      values,
      "start",
      "endEmissionsDeclarants",
      errors
    );
    errors = setErrorWhenAfter(values, "start", "endGlobalDeclarants", errors);
  }

  ////////////////////////////////
  // END ALLOCATIONS DECLARANTS //
  ////////////////////////////////
  if (shouldDisable.endAllocationsDeclarants) {
    errors = setErrorWhenModif(
      values,
      initialValues,
      "endAllocationsDeclarants",
      errors
    );
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endAllocationsDeclarants",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(
      values,
      "endAllocationsDeclarants",
      "start",
      errors
    );
    errors = setErrorWhenAfter(
      values,
      "endAllocationsDeclarants",
      "endAllocations",
      errors
    );
  }

  ////////////////////////////////
  //       END ALLOCATIONS      //
  ////////////////////////////////
  if (shouldDisable.endAllocations) {
    errors = setErrorWhenModif(values, initialValues, "endAllocations", errors);
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endAllocations",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(
      values,
      "endAllocations",
      "endAllocationsDeclarants",
      errors
    );
  }

  ////////////////////////////////
  //  END EMISSIONS DECLARANTS  //
  ////////////////////////////////
  if (shouldDisable.endEmissionsDeclarants) {
    errors = setErrorWhenModif(
      values,
      initialValues,
      "endEmissionsDeclarants",
      errors
    );
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endEmissionsDeclarants",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(
      values,
      "endEmissionsDeclarants",
      "start",
      errors
    );
    errors = setErrorWhenAfter(
      values,
      "endEmissionsDeclarants",
      "endEmissions",
      errors
    );
  }

  ////////////////////////////////
  //        END EMISSIONS       //
  ////////////////////////////////
  if (shouldDisable.endEmissions) {
    errors = setErrorWhenModif(values, initialValues, "endEmissions", errors);
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endEmissions",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(
      values,
      "endEmissions",
      "endEmissionsDeclarants",
      errors
    );
  }

  ////////////////////////////////
  //   END GLOBAL DECLARANTS    //
  ////////////////////////////////
  if (shouldDisable.endGlobalDeclarants) {
    errors = setErrorWhenModif(
      values,
      initialValues,
      "endGlobalDeclarants",
      errors
    );
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endGlobalDeclarants",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(values, "endGlobalDeclarants", "start", errors);
    errors = setErrorWhenAfter(
      values,
      "endGlobalDeclarants",
      "endGlobal",
      errors
    );
  }

  ////////////////////////////////
  //         END GLOBAL         //
  ////////////////////////////////
  if (shouldDisable.endGlobal) {
    errors = setErrorWhenModif(values, initialValues, "endGlobal", errors);
  } else {
    errors = setErrorWhenBeforeValue(
      values,
      "endGlobal",
      minimumDate,
      minimumDateName,
      errors
    );
    errors = setErrorWhenBefore(
      values,
      "endGlobal",
      "endGlobalDeclarants",
      errors
    );
  }

  return errors;
};
