import React from "react";
import { WorkflowTarget } from "common/utils/types";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useDeclaration21Now } from "../../../versionedElements/declarationHooks21Now";
import {
  filesRelatedToNimDtoToFileDto,
  NimFilesLoaderProps,
} from "../FilesRelatedToNim/utils";
import {
  useAllocationsRapportOfficeFileHandler21Now,
  useEmissionsRapportOfficeFileHandler21Now,
} from "../../../versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { RapportOfficeDto } from "api/gen";

interface RapportOfficeLoaderProps extends NimFilesLoaderProps {
  workflowTarget: WorkflowTarget;
}

const RapportOfficeLoader = ({
  workflowTarget,
  installationId,
  shouldDisableFields,
}: RapportOfficeLoaderProps): React.ReactElement => {
  const declaration = useDeclaration21Now();
  const emissionsRapportOfficeHandler = useEmissionsRapportOfficeFileHandler21Now(
    installationId
  );
  const allocationsRapportOfficeHandler = useAllocationsRapportOfficeFileHandler21Now(
    installationId
  );

  const filesPath =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? "body.sections.quotas.emissions.blocInspecteur.documents"
      : "body.sections.quotas.allocations.blocInspecteur.documents";

  const filesSection =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? FileSectionEnum.QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE
      : FileSectionEnum.QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE;

  const sectionFileHandler =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? emissionsRapportOfficeHandler
      : allocationsRapportOfficeHandler;

  return (
    <FilesLoader
      declaration={declaration}
      text={"Déposer rapport de déclaration d'office"}
      filesPath={filesPath}
      section={filesSection}
      fileHandlers={sectionFileHandler}
      //
      isDisabled={shouldDisableFields}
      transformFunction={(rapportOfficeList: Record<string, unknown>[]) =>
        filesRelatedToNimDtoToFileDto(
          installationId,
          (rapportOfficeList as unknown) as RapportOfficeDto[]
        )
      }
    />
  );
};

export default RapportOfficeLoader;
