import {
  PopulatedTransportProductInArray,
  TransportProductsArrayForm,
  TransportProductsForm,
} from "./types";
import { convertModaliteTransportDtoToDisplayed } from "./converters";
import { familiesProduction } from "../../Families/selectPossibleValues";
import {
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const computeTotalProductsTonnage = (
  transportProductsInArray: PopulatedTransportProductInArray[]
): number => {
  let total = 0;
  transportProductsInArray.forEach(productInArray => {
    total += productInArray.displayedProduct.tonnage;
  });
  return total;
};

export const computeTransportProductsArrayFormInitialValues = (
  declaration: Declaration1919,
  referentialFamilies: ReferenceFamilleUsageProductionDto
): TransportProductsArrayForm => {
  const productsTransport: TransportProductsForm[] = [];
  const modalitesTransport =
    declaration.body.sections.carriere.production.transport.modaliteTransports;

  familiesProduction(referentialFamilies).forEach(usageFamily => {
    // we check if a modaliteTransport is present for this family by checking the libelle of the usageFamily from referential and our string in famille
    const existingValue = modalitesTransport.find(modalite => {
      return usageFamily.object.libelleCourt === modalite.famille;
    });
    if (existingValue) {
      productsTransport.push(
        convertModaliteTransportDtoToDisplayed(
          [existingValue],
          referentialFamilies
        )[0]
      );
    } else {
      productsTransport.push({
        usageFamily: usageFamily,
        byRoad: null,
        byRail: null,
        byWaterWay: null,
        other: null,
        otherPrecision: null,
      });
    }
  });

  return {
    productsTransport: productsTransport,
  };
};

export const generateLabelFamily = (
  codeProduit: string,
  referentialFamiliesCodeProduits: ReferenceFamilleUsageCodeProduitDto
): string => {
  const libelleToDisplay = findElementMatchingTemplate(
    { codeProduit: codeProduit },
    referentialFamiliesCodeProduits.referenceItemFamilleUsageCodeProduitDtoList
  );

  return `${codeProduit} - ${libelleToDisplay && libelleToDisplay.libelle}`;
};
