import React from "react";
import { PATH_INFO_GENERALE_DOCUMENTS } from "common/path/path21Now";
import Row from "common/presentational/Row";
import { FileSectionEnum } from "common/FilesLoader/types";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useDiversFilesHandlers21Now } from "../../../toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import { useAlertModale } from "common/modale/hooks";
import { DEFAULT_ALERT_MESSAGE } from "common/FilesLoader/utils";
import { makeStyles } from "@material-ui/styles";
import { BlocFullContext2122 } from "../../versionedElements/BlocFullContext2122";
import { useDeclaration2122 } from "../../versionedElements/declarationHooks2122";

const useStyles = makeStyles({
  uploadButton: {
    height: "auto",
  },
});

const BlocDocuments = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2122();
  const openAlertModale = useAlertModale();
  const diversFilesHandler = useDiversFilesHandlers21Now();

  return (
    <BlocFullContext2122
      title={"Dépôt de documents divers (facultatif)"}
      hasModification={false}
      isValidateButtonAvailable={true}
      path={PATH_INFO_GENERALE_DOCUMENTS}
      updateHandler={declaration => {
        return declaration;
      }}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          <p>
            Des documents divers, visant à compléter la déclaration, peuvent
            être déposés dans cette section. Cette section doit être validée
            même si aucun fichier n'a été déposé.
          </p>
          <Row />
          <Row additionalClassname={classes.uploadButton}>
            <FilesLoader
              declaration={declaration}
              text={"DÉPOSER DOCUMENT(S) DIVERS"}
              filesPath={
                "body.externalSections.infoGenerale.documents.fichiers"
              }
              section={FileSectionEnum.DOCUMENTS_DIVERS}
              fileHandlers={diversFilesHandler}
              isDisabled={shouldDisabledFields}
              onReject={() => openAlertModale(DEFAULT_ALERT_MESSAGE)}
            />
          </Row>
          <Row />
        </>
      )}
    />
  );
};

export default BlocDocuments;
