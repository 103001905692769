import React from "react";
import { CommentButtonState, GlobalFormActionsProps } from "./types";
import DummyGlobalFormActions from "./DummyGlobalFormActions";
import { useUserData } from "../../Authenticator/UserData";
import { computeCommentButtonState } from "../comment/commentUtils";
import InfoBulle from "../infoBulle/InfoBulle";

/**
 * form action with basic buttons logic :
 * - if isValidated then edit button is visible and all other are hiden
 * - if hasChanges then save and cancel button are visible
 *
 * use case : wrapped in DummyBloc , should not be used elsewhere, for bottom page form action see GlobalFormActionInFullContext
 *
 * warn :
 * - isValidated and hasChanges both control cancel and save buttons visibility, cancel and save buttons will be visible if hasChanges === true && isValidated === false
 *    hasChanges = true && isValidated = true should never happend but will not raise error
 * - actionsAllowed and isValidateEnabled both control validation button, actionsAllowed managed state for all 4 buttons when isValidatedEnabled only control validation button
 *    actionsAllowed and isValidateEnabled both need to be true for validation to be enabled
 * @param validationTitle: title for validation button
 * @param isValidated: control if we should show (edit button) or (validation, save, cancel buttons)
 * @param hasChanges: control if save and cancel buttons should show
 * @param isValidateEnabled: control if validate button should be unabled or not
 * @param actionsAllow: control whether we can interract with buttons or not
 * @param editAction: action to trigger on edit button click
 * @param cancelAction: action to trigger on cancel button click
 * @param saveAction: action to trigger on save button click
 * @param validateAction: action to trigger on validate button click
 * @param isValidateSpinnerVisible indicates if the spinner for the validate button should be visible
 * @param isEditSpinnerVisible indicates if the spinner for the edit button should be visible
 * @param isSaveSpinnerVisible indicates if the spinner for the save button should be visible
 * @param validationMessage: message to display next to validation button. It is an optional object
 * with the message to display and a boolean indicating if the message should be always displayed
 * or only when the button is disabled. If undefined, no message will be displayed.
 */
const GlobalFormActions = ({
  validationTitle,
  isValidated,
  hasChanges,
  isValidateEnabled,
  actionsAllowed,
  editAction,
  cancelAction,
  saveAction,
  validateAction,
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  validationMessage,
  commentAction,
  hasComments,
  areGlobalCommentsAllowed,
  validationTooltipContent,
}: GlobalFormActionsProps): React.ReactElement => {
  const { isLecteur } = useUserData();
  return (
    <DummyGlobalFormActions
      validateButton={{
        title: validationTitle,
        isVisible: !isValidated,
        isEnabled: isValidateEnabled && actionsAllowed,
        onClick: validateAction,
        isSpinnerVisible: isValidateSpinnerVisible,
      }}
      editButton={{
        title: "MODIFIER",
        isVisible: isValidated,
        isEnabled: actionsAllowed,
        onClick: editAction,
        isSpinnerVisible: isEditSpinnerVisible,
      }}
      cancelButton={{
        title: "ANNULER",
        isVisible: hasChanges && !isValidated,
        isEnabled: actionsAllowed,
        onClick: cancelAction,
      }}
      saveButton={{
        title: "ENREGISTRER",
        isVisible: hasChanges && !isValidated,
        isEnabled: actionsAllowed,
        onClick: saveAction,
        isSpinnerVisible: isSaveSpinnerVisible,
      }}
      commentButton={{
        isEnabled: areGlobalCommentsAllowed,
        state: computeCommentButtonState(isLecteur, hasComments),
        hasComments: hasComments,
        onClick: commentAction,
      }}
      validationMessage={validationMessage}
      validationTooltipContent={validationTooltipContent}
    />
  );
};

export default GlobalFormActions;
