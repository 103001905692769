import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useMemo, useState } from "react";
import Button from "common/button";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  FIELD_FONT_SIZE,
  SECTION_TITLE_GREY,
  SELECT_DEFAULT_WIDTH,
} from "theme";
import CustomModal from "common/presentational/CustomModal";
import Row from "common/presentational/Row";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import DummyTextInput from "./DummyTextInput";
import { DummyChoiceSelectModalProps } from "../types/dummyTypes";
import SelectAdapter from "libAdapter/ReactSelect/SelectAdapter";

const useStyles = makeStyles({
  highContainer: {
    width: SELECT_DEFAULT_WIDTH,
  },

  title: {
    display: "flex",
    justifyContent: "center",
    fontSize: FIELD_FONT_SIZE,
    color: SECTION_TITLE_GREY,
    fontWeight: 400,
    marginBottom: "5%",
  },

  cancelButton: {
    marginLeft: "90%",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
  },

  cancelRow: {
    marginTop: "50px",
  },

  searchRow: {
    width: "auto",
    marginBottom: "50px",
    marginLeft: "70%",
  },

  table: {
    maxHeight: "400px",
  },
});

function DummyChoiceSelectModal<
  DisplayedValue,
  SelectTableValue extends DisplayedValue
>({
  //  DummyFieldProps
  disabled,
  additionalClassName,
  id,
  error,
  //  basic props
  onSelect,
  //    Select customisation
  title,
  value,
  hideValue,
  //    line formater
  linesData,
  formatLine,
  formatSelectedTitle,
  //  search
  placeholder,
  isLineInSearch,
  //    CommonEntityTable customisation
  header,
  isFirstSticky = false,
  isLastSticky = false,
  preferredColWidths,
  cellStyles,
}: DummyChoiceSelectModalProps<
  DisplayedValue,
  SelectTableValue
>): React.ReactElement {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");

  const visibleLinesData = useMemo(
    () => linesData.filter(lineData => isLineInSearch(lineData, searchValue)),
    [linesData, searchValue, isLineInSearch]
  );
  const lines = useMemo(
    () => visibleLinesData.map(lineData => formatLine(lineData)),
    [visibleLinesData, formatLine]
  );
  return (
    <>
      <SelectAdapter
        id={id}
        placeholder={placeholder}
        onMenuOpen={() => {
          setIsModalOpen(true);
        }}
        isMulti={false}
        value={
          hideValue
            ? null
            : value
            ? {
                value: formatSelectedTitle(value),
                label: formatSelectedTitle(value),
              }
            : undefined
        }
        error={error}
        isDisabled={disabled}
        isClearable={true}
        onChange={() => {
          onSelect && onSelect(null);
        }}
        additionalClassName={additionalClassName}
      />

      <CustomModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <h3 className={classes.title}>{title}</h3>

        <Row additionalClassname={classes.searchRow}>
          <DummyTextInput
            id="search-choiceSelectModal"
            name="search-choiceSelectModal"
            value={searchValue}
            error={error}
            placeholder="Rechercher..."
            onChange={str => setSearchValue(str || "")}
          />
        </Row>

        <CommonEntityTable
          header={header.map(element => (
            <p>{element}</p>
          ))}
          lines={lines.map(element => element.map(sub => <p>{sub}</p>))}
          didSelectRow={row => {
            if (onSelect) {
              const selectedOption = visibleLinesData[row];
              onSelect(selectedOption);
              setIsModalOpen(false);
            }
          }}
          preferredColWidths={preferredColWidths}
          cellStyles={cellStyles}
          isFirstColSticky={isFirstSticky}
          isLastColSticky={isLastSticky}
        />

        <Row additionalClassname={classes.cancelRow}>
          <Button
            text="ANNULER"
            isReversed
            onClick={() => setIsModalOpen(false)}
            additionalClassname={classes.cancelButton}
          />
        </Row>
      </CustomModal>
    </>
  );
}

export default DummyChoiceSelectModal;
