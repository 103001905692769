import { AirIsdndCh4DtoMethodeEnum, AirIsdndCo2DtoMethodeEnum } from "api/gen";

export const co2MethodLabels: {
  [key in AirIsdndCo2DtoMethodeEnum]: string;
} = {
  ADEME: "ADEME",
  AUTRE_METHODE: "Autre méthode",
};

export const co2MethodList = Object.keys(
  co2MethodLabels
) as AirIsdndCo2DtoMethodeEnum[];

export const ch4MethodLabels: {
  [key in AirIsdndCh4DtoMethodeEnum]: string;
} = {
  ADEME: "ADEME",
  AUTRE_METHODE: "Autre méthode",
};

export const ch4MethodList = Object.keys(
  ch4MethodLabels
) as AirIsdndCh4DtoMethodeEnum[];
