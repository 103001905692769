import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { AreasOtherInfosForm } from "./types";
import { Nullable } from "common/utils/types";

export const convertDtoToAreasOtherInfosForm = (
  declaration: Declaration1919
): Nullable<AreasOtherInfosForm> => {
  return {
    authorizedArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieAutorisee,
    remainingArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieRestantAExploiter,
    exploitedThisYearArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieExploitee,
    restoredArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieRestituee,
  };
};
