import React from "react";
import { makeStyles } from "@material-ui/styles";
import Button from "common/button";
import {
  AuthDroitDtoAuthProfileEnum,
  CampaignDtoStateEmissionsEnum,
  DeclarationStateDtoStateEnum,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import Row from "common/presentational/Row";
import { FileSectionEnum } from "common/FilesLoader/types";
import {
  generateDeclarationTransitionWarningMessage,
  IN_REVIEW,
} from "common/messages/dashboardMessage";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import {
  useAlertModale,
  useConfirmationModaleWithReason,
} from "common/modale/hooks";
import { PATH_QUOTAS } from "common/path/path18Now";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import {
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
  useDeclarationStateHandlers1919,
} from "../../versionedElements/declarationHooks1919";
import { WorkflowTarget } from "common/utils/types";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useQuotasRapportOfficeFileHandler1919 } from "../../versionedElements/filesLoaderDisplayer1919/sectionFilesHooks1919";

const useStyles = makeStyles({
  button: {
    margin: "auto",
    padding: "0.5em 1em",
  },
  uploadContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  uploadButton: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  uploadList: {
    flexDirection: "column",
    "&>div": {
      justifyContent: "center",
    },
  },
});

interface BlocInspecteurProps {
  canValidatePage: boolean;
}

const BlocInspecteur = ({
  canValidatePage,
}: BlocInspecteurProps): React.ReactElement => {
  const currentCampaign = useCurrentDeclarationCampaign();
  const declaration = useDeclaration1919();
  const classes = useStyles();
  const openConfirmationModalWithReason = useConfirmationModaleWithReason();
  const userData = useUserData();
  const {
    cancelTakeover,
    takeover,
    review,
    approve,
  } = useDeclarationStateHandlers1919();
  const { validate, cancelValidate } = useBasicDeclarationHandlers1919();
  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    boolean
  >();
  const [isReviewSpinnerVisible, triggerReviewSpinner] = useSpinnerState<
    boolean
  >();
  const [
    isCancelTakeoverSpinnerVisible,
    triggerCancelTakeoverSpinner,
  ] = useSpinnerState<boolean>();
  const [isTakeoverSpinnerVisible, triggerTakeoverSpinner] = useSpinnerState<
    boolean
  >();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const openAlertModale = useAlertModale();

  const rapportOfficeFilesHandler = useQuotasRapportOfficeFileHandler1919();

  const currentState = declaration.body.workflowStatus.quotaEmissions.state;

  const isForcedState = currentState === DeclarationStateDtoStateEnum.FORCED;
  const isRecalledState =
    currentState === DeclarationStateDtoStateEnum.RECALLED;

  // Button "Mettre en révision quotas" to pass in IN_REVIEW state in workflow
  const isButtonReviewVisible =
    // Campaign 2019 only use quotas campaign "émissions"
    currentCampaign.stateEmissions !== CampaignDtoStateEmissionsEnum.ENDED &&
    (currentState === DeclarationStateDtoStateEnum.SUBMITTED ||
      currentState === DeclarationStateDtoStateEnum.RECALLED ||
      currentState === DeclarationStateDtoStateEnum.VALIDATED);
  // Button "Valider la déclaration" to pass in VALIDATED state in workflow
  const isButtonValidateVisible =
    currentState === DeclarationStateDtoStateEnum.FORCED ||
    currentState === DeclarationStateDtoStateEnum.SUBMITTED;

  return (
    <DummyUnActionedBloc
      title="espace inspecteur"
      renderContent={() =>
        userData.isInspecteur ? (
          <div>
            {isRecalledState && (
              <>
                <Row />
                <p>
                  Le déclarant a demandé à pouvoir effectuer une modification de
                  sa déclaration.
                </p>
              </> // TODO: change text and style if required
            )}
            {isForcedState &&
              currentCampaign.stateEmissions !==
                CampaignDtoStateEmissionsEnum.ENDED && (
                <>
                  <Row />
                  <Button
                    text="Passer en saisie quotas Exploitant"
                    onClick={() =>
                      triggerCancelTakeoverSpinner(
                        cancelTakeover([WorkflowTarget.QUOTA_EMISSIONS])
                      )
                    }
                    additionalClassname={classes.button}
                    isSpinnerVisible={isCancelTakeoverSpinnerVisible}
                  />
                </>
              )}
            {!isForcedState &&
              (userData.userInfo.droits[0].authProfile ===
                AuthDroitDtoAuthProfileEnum.SUPER_ADMIN ||
                userData.userInfo.droits[0].authProfile ===
                  AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
                currentCampaign.stateEmissions !==
                  CampaignDtoStateEmissionsEnum.ENDED) && (
                <>
                  <Row />
                  <Button
                    text="Déclaration d'office"
                    onClick={() =>
                      triggerTakeoverSpinner(
                        takeover([WorkflowTarget.QUOTA_EMISSIONS])
                      )
                    }
                    additionalClassname={classes.button}
                    isSpinnerVisible={isTakeoverSpinnerVisible}
                  />
                </>
              )}
            {isButtonReviewVisible && (
              <>
                <Row />
                <Button
                  text={
                    isRecalledState
                      ? "Accepter demande de modification"
                      : "Mettre en révision quotas"
                  }
                  onClick={() =>
                    openConfirmationModalWithReason(
                      generateDeclarationTransitionWarningMessage(
                        WorkflowTarget.QUOTA_EMISSIONS,
                        IN_REVIEW
                      ),
                      "Votre message",
                      async text => {
                        const reviewCall = async () => {
                          await review(
                            [WorkflowTarget.QUOTA_EMISSIONS],
                            text ? text : ""
                          );
                          if (isPathValidatedInDeclaration(PATH_QUOTAS)) {
                            await cancelValidate(PATH_QUOTAS);
                          }
                          return true;
                        };
                        return triggerReviewSpinner(reviewCall());
                      }
                    )
                  }
                  additionalClassname={classes.button}
                  isSpinnerVisible={isReviewSpinnerVisible}
                />
              </>
            )}
            {isButtonValidateVisible && (
              <>
                <Row />
                <Button
                  text="Valider la déclaration Quotas"
                  //Duplicate of QuotasGlobalFormActions
                  onClick={async () => {
                    const validateCall = async () => {
                      // Before PR#1119, when an admin invalidated the quota declaration,
                      // the path /quotas was not invalidated so we have to check for this specific case.
                      // in addition, it's possible the button is accessible while the path quotas is already validated in declaration
                      // because e.g the page was loaded BEFORE quota was validated
                      if (
                        !isPathValidatedInDeclaration(PATH_QUOTAS) &&
                        !(await validate(
                          declarationDto => declarationDto,
                          PATH_QUOTAS
                        ))
                      ) {
                        return false;
                      }
                      await approve([WorkflowTarget.QUOTA_EMISSIONS]);
                      return true;
                    };
                    return triggerValidateSpinner(validateCall());
                  }}
                  additionalClassname={classes.button}
                  isDisabled={canValidatePage}
                  isSpinnerVisible={isValidateSpinnerVisible}
                />
              </>
            )}
            <Row height="40px" />
            <FilesLoader
              declaration={declaration}
              text={"Déposer rapport de déclaration d'office"}
              filesPath={"body.sections.quotas.blocInspecteur.documents"}
              section={FileSectionEnum.QUOTAS_RAPPORT_DECLARATION_D_OFFICE}
              fileHandlers={rapportOfficeFilesHandler}
              //
              isDisabled={!isForcedState}
              //
              additionalClassnameContainer={classes.uploadContainer}
              additionalClassnameButton={classes.uploadButton}
              additionalClassnameList={classes.uploadList}
              onReject={() =>
                openAlertModale("Le format du fichier uploadé est incorrect.")
              }
            />
          </div>
        ) : (
          <p>Espace réservé à l'inspecteur</p>
        )
      }
    />
  );
};

export default BlocInspecteur;
