import {
  Declaration19Now,
  DeclarationConstraintViolation19Now,
  useBasicDeclarationHandlers19Now,
  useDeclaration19Now,
  useDeclarationHelpers19Now,
} from "./declarationHooks19Now";
import { CommonGlobalFormActionsInFullContextProps } from "common/actions/types";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "../../../DeclarationApiContext/utils/types";
import { DeclarationStateDtoStateEnum } from "api/gen";
import React from "react";
import GenericGlobalFormActionInFullContext from "common/actions/GenericGlobalFormActionInFullContext";
import { useDeclarationErrorAndWarnings19Now } from "./errorAndWarningDisplayer19Now/utils";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

type GlobalFormActionFullContext19NowProps = CommonGlobalFormActionsInFullContextProps<
  Declaration19Now
>;

export const GlobalFormActionFullContext19Now = (
  props: GlobalFormActionFullContext19NowProps
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration19Now = useDeclaration19Now;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers19Now;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration19Now
  > = useBasicDeclarationHandlers19Now;
  const getDeclarationState: (
    declarationDto: Declaration19Now
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings19Now;
  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );

  return (
    <GenericGlobalFormActionInFullContext<
      Declaration19Now,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      {...props}
    />
  );
};

export default GlobalFormActionFullContext19Now;
