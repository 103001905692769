import { DeclarationStateDtoStateEnum } from "api/gen";
import { useLayoutEffect, useState } from "react";
import {
  ButtonStateHandler,
  useSpinnerState,
} from "common/button/loadingAndRedirectionHelpers";
import { UserData, useUserData } from "Authenticator/UserData";
import { useDeclaration24Now } from "../../versionedElements/declarationHooks24Now";

/**
 *
 * @param quotaState deux types de quota states sont possible (quota allocations ou quota émissions)
 */
export function useQuotasButtonStateWithRedirection<T>(
  quotaState: DeclarationStateDtoStateEnum
): [boolean, ButtonStateHandler<T>, boolean, DeclarationStateDtoStateEnum] {
  const declaration = useDeclaration24Now();

  const [currentWorkflowState, setCurrentWorkflowState] = useState(quotaState);
  const [shouldRedirectToDashboard, setShouldRedirectToDashboard] = useState<
    boolean
  >(false);

  useLayoutEffect(() => {
    if (
      (currentWorkflowState === DeclarationStateDtoStateEnum.CREATED ||
        currentWorkflowState === DeclarationStateDtoStateEnum.STARTED) &&
      quotaState === DeclarationStateDtoStateEnum.VERIFYING
    ) {
      setShouldRedirectToDashboard(true);
    }
    setCurrentWorkflowState(quotaState);
  }, [
    declaration,
    currentWorkflowState,
    setCurrentWorkflowState,
    setShouldRedirectToDashboard,
    quotaState,
  ]);

  const [isLoading, setLoading] = useSpinnerState();
  return [
    isLoading,
    setLoading,
    shouldRedirectToDashboard,
    currentWorkflowState,
  ];
}

export function useNiveauxActiviteValidationMessage(): string | undefined {
  const declaration = useDeclaration24Now();
  const userData = useUserData();
  const quotaState = declaration.body.workflowStatus.quotaAllocations.state;
  const paveLibelle = "niveaux d'activité";

  return generateValidationMessage(userData, quotaState, paveLibelle);
}

export function useEmissionValidationMessage(): string | undefined {
  const declaration = useDeclaration24Now();
  const userData = useUserData();
  const quotaState = declaration.body.workflowStatus.quotaEmissions.state;
  const paveLibelle = "émissions";

  return generateValidationMessage(userData, quotaState, paveLibelle);
}

export const generateImportEmailsConfirmationMessage = (
  emailsToImport: string[]
): string => {
  const emailsArray = emailsToImport.map(email => " " + email);
  return `Attention, cette opération va remplacer les adresses mail de cette section par les adresses suivantes :${emailsArray}.`;
};

const generateValidationMessage = (
  userData: UserData,
  quotaState: DeclarationStateDtoStateEnum,
  paveLibelle: string
): string | undefined => {
  const shouldEditMessage =
    userData.isDeclarant &&
    quotaState === DeclarationStateDtoStateEnum.VERIFYING;
  if (shouldEditMessage) {
    return `Attention, modifier cette section invalidera la déclaration quotas ${paveLibelle}. Une fois cette section revalidée, il vous faudra transmettre à nouveau la déclaration à la vérification.`;
  }
};
