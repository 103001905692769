import React, { useEffect, useMemo, useState } from "react";
import CustomModal from "common/presentational/CustomModal";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DeclarantDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  LINK_STYLE,
  SECTION_TITLE_GREY,
} from "theme";
import Button from "common/button";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageFetchDeclaration } from "common/backErrors/errorMessages";
import Spinner from "common/presentational/Spinner";
import { useAuthenticatedUser } from "Authenticator/AuthenticatedUser";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface ContactInfosModalProps {
  isOpen: boolean;
  company: string | null;
  year: number | null;
  onRequestClose: () => void;
}

const useStyles = makeStyles({
  inputField: {
    display: "flex",
    marginBottom: "5px",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "50px",
  },
  infosContainer: {
    display: "inline-flex",
    flexDirection: "column",
  },
  infosRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "20px",
    marginTop: "20px",
  },
  infosLabel: {
    color: SECTION_TITLE_GREY,
    fontWeight: "bold",
  },
  infosLink: LINK_STYLE,
});

const computeDeclarantLabel = (declarant: DeclarantDto): string => {
  return `${declarant.prenom === null ? "" : `${declarant.prenom} `}${
    declarant.nom === null ? "" : declarant.nom
  }`;
};

export const ContactInfosModal2018Now = ({
  isOpen,
  company,
  year,
  onRequestClose,
}: ContactInfosModalProps): React.ReactElement => {
  const classes = useStyles();

  const authenticatedUser = useAuthenticatedUser();
  const { declarationController } = useAuthenticatedApi();
  const openAlertModale = useAlertModale();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [declaration, setDeclaration] = useState<AnyDeclarationDto | null>(
    null
  );
  const [
    declarantSelected,
    setDeclarantSelected,
  ] = useState<DeclarantDto | null>(null);

  const declarants: DeclarantDto[] = useMemo(() => {
    return declaration ? declaration.body.sections.infoGenerale.declarants : [];
  }, [declaration]);

  useEffect(() => {
    let mounted = true;
    if (year !== null && company) {
      const fetchDeclaration = async () => {
        try {
          const declarationDto = (await declarationController.findDeclarationByIdUsingPOST(
            year,
            { monAiotToken: authenticatedUser.jwt.monAIOTJWT },
            company
          )) as AnyDeclarationDto;
          const declarants =
            declarationDto.body.sections.infoGenerale.declarants;

          if (!mounted) {
            return;
          }
          setDeclaration(declarationDto);
          setDeclarantSelected(declarants.length > 0 ? declarants[0] : null);
        } catch (excp) {
          if (!mounted) {
            return;
          }

          await openAlertModale(
            backAlertMessage(excp, backMessageFetchDeclaration)
          );
          onRequestClose();
          return;
        }

        setIsLoading(false);
      };

      fetchDeclaration();
    } else {
      setIsLoading(true);
      setDeclaration(null);
      setDeclarantSelected(null);
    }

    return () => {
      mounted = false;
    };
  }, [
    declarationController,
    company,
    year,
    openAlertModale,
    onRequestClose,
    authenticatedUser.jwt.monAIOTJWT,
  ]);

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-informations-contact",
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <DummyChoiceSelectWithLabel
            name="declarantSelected"
            label="Sélectionnez la fiche de contact"
            isMulti={false}
            options={declarants}
            value={declarantSelected}
            additionalOnChange={option => {
              if (option !== null) {
                setDeclarantSelected(option);
              }
            }}
            computeLabel={computeDeclarantLabel}
            commonProps={commonProps}
          />

          {declarantSelected && (
            <div className={classes.container}>
              <div className={classes.infosContainer}>
                <div className={classes.infosRow}>
                  <span className={classes.infosLabel}>
                    Rôle au sein de l'entreprise
                  </span>
                </div>
                <div className={classes.infosRow}>
                  <span className={classes.infosLabel}>Adresse e-mail</span>
                </div>
                <div className={classes.infosRow}>
                  <span className={classes.infosLabel}>
                    Numéro de téléphone
                  </span>
                </div>
              </div>

              <div className={classes.infosContainer}>
                <div className={classes.infosRow}>
                  <span>{declarantSelected.role}</span>
                </div>
                <div className={classes.infosRow}>
                  <a
                    href={`mailto:${declarantSelected.email}`}
                    className={classes.infosLink}
                  >
                    {declarantSelected.email}
                  </a>
                </div>
                <div className={classes.infosRow}>
                  <span>{declarantSelected.telephone}</span>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
        isReversed
      />
    </CustomModal>
  );
};
