import { BlocPrelevementEauFormValues2323 } from "./types";

export const computeTotalWaterTaking = (
  values: BlocPrelevementEauFormValues2323
): number => {
  return (
    (values.volumeEauSuperficielle !== null
      ? values.volumeEauSuperficielle
      : 0) +
    (values.volumeEauSouterraine !== null ? values.volumeEauSouterraine : 0) +
    (values.volumeEauPotable !== null ? values.volumeEauPotable : 0) +
    (values.volumeEauMer !== null ? values.volumeEauMer : 0) +
    (values.volumeEauAdduction !== null ? values.volumeEauAdduction : 0)
  );
};
