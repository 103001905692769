import React from "react";
import {
  GasEnum,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
  MeasureEmissionInModale,
  MethodEnum,
} from "../types";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { gasOptions, methodLabels } from "../selectPossibleValues";
import CustomModal from "common/presentational/CustomModal";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import _ from "lodash";
import uuid from "uuid";
import { computeUnitTCO2PerYear } from "common/form/subs/utils";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
  overWriteEmissionDependantFields,
} from "../utils/utils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { validationSchema } from "./validation";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface MeasuredEmissionsModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setMeasuredEmissionsInArray: (
    callback: (emissions: MeasureEmissionInArray[]) => MeasureEmissionInArray[]
  ) => void;
  setMeasuredEmissionInModal: (emission: MeasureEmissionInArray | null) => void;
  measuredEmissionInModal: MeasureEmissionInArray | null;
  measureDeclarations: MeasureDeclarationProps[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const MeasuredEmissionsModal = ({
  isOpen,
  setIsOpen,
  setMeasuredEmissionsInArray,
  measuredEmissionInModal,
  setMeasuredEmissionInModal,
  measureDeclarations,
}: MeasuredEmissionsModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emissions-modale-mesurees",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);

  const filteredGasOptions = gasOptions.filter(gas => gas !== GasEnum.PFC);

  //the value is an id because the id field is unique to each measure
  const measureOptions = measureDeclarations.filter(measure => measure.name);

  const onClose = () => {
    setMeasuredEmissionInModal(null);
    setIsOpen(false);
  };

  const defaultMethodDummyValue = MethodEnum.MESURE;

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UNE ÉMISSION"
        closeFunction={onClose}
        initialEntity={_.get(measuredEmissionInModal, "data", {
          method: null,
          measure: null,
          gas: null,
          fossilEmission: null,
          biomassEmission: null,
          otherEmission: null,
        })}
        isEdit={measuredEmissionInModal !== null}
        validationSchema={validationSchema}
        onSubmit={(values: MeasureEmissionInModale) => {
          const emission: MeasureEmissionInArray = {
            //TODO: compute errors
            data: {
              id: measuredEmissionInModal
                ? measuredEmissionInModal.data.id
                : uuid(),
              method: defaultMethodDummyValue,
              measure: values.measure,
              gas: values.gas,
              fossilEmission: values.fossilEmission,
              biomassEmission: values.biomassEmission,
              otherEmission: values.otherEmission,
            },
            errors: {},
          };
          overWriteEmissionDependantFields(emission);
          setMeasuredEmissionsInArray(currentMeasuredEmissionsInArray => {
            const newMeasuredEmissionsInArray = _.cloneDeep(
              currentMeasuredEmissionsInArray
            );
            let indexToReplace = newMeasuredEmissionsInArray.length;
            if (measuredEmissionInModal) {
              indexToReplace = _.findIndex(
                newMeasuredEmissionsInArray,
                measuredEmissionInModal
              );
              setMeasuredEmissionInModal(null);
            }
            newMeasuredEmissionsInArray[indexToReplace] = emission;
            return newMeasuredEmissionsInArray;
          });
          setIsOpen(false);
        }}
        renderField={({ values }) => {
          const selectedMeasure = measureDeclarations.find(
            measure => !!(values.measure && measure.id === values.measure.id)
          );

          return (
            <>
              <DummyChoiceSelectWithLabel<MethodEnum, false>
                label="Méthode utilisée *"
                name="method"
                isMulti={false}
                options={[defaultMethodDummyValue]}
                disabled
                value={defaultMethodDummyValue}
                computeLabel={method => methodLabels[method]}
                commonProps={commonProps}
              />

              <WrappedChoiceSelect
                label="Point de mesure *"
                name="measure"
                isMulti={false}
                options={measureOptions}
                computeLabel={mesure => mesure.name || ""}
                commonProps={commonProps}
              />

              <DummyTextField
                label="Numéro NIM"
                name="nim"
                disabled
                value={
                  selectedMeasure && selectedMeasure.nimInstallation
                    ? selectedMeasure.nimInstallation.nim
                    : ""
                }
              />

              <WrappedChoiceSelect
                label="Gaz émis *"
                name="gas"
                isMulti={false}
                options={filteredGasOptions}
                computeLabel={gas => gas.toString()}
                commonProps={commonProps}
              />

              {values.gas && isOtherEmissionSubPartActivated(values.gas) && (
                <NumberField
                  label={`Émission de ${
                    values.gas === GasEnum.PFC ? "PFC" : "N2O"
                  } *`}
                  name="otherEmission"
                  unit={computeUnitTCO2PerYear}
                />
              )}

              {isBiomasseSubPartActivated(values.gas) && (
                <>
                  <NumberField
                    label="Émission totale d'origine non biomasse *"
                    name="fossilEmission"
                    unit={computeUnitTCO2PerYear}
                  />

                  <NumberField
                    label="Émission totale d'origine biomasse *"
                    name="biomassEmission"
                    unit={computeUnitTCO2PerYear}
                  />
                </>
              )}
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default MeasuredEmissionsModal;
