import { TechniqueValues } from "./utils";
import { ReferenceItemEprtrDto, TypeActiviteDto2020 } from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import { InstallationInArray } from "./Installations/types";
import { singleInstallationToInstallationDto } from "./Installations/converter";
import { Declaration2020 } from "../../../CompanySpace/from20/to20/versionedElements/declarationHooks2020";

const getEprtrDtoById = (
  eprtrRef: ReferenceItemEprtrDto[],
  eprtrId: string
) => {
  const eprtr = findElementMatchingTemplate({ uuid: eprtrId }, eprtrRef);
  return eprtr;
};

const getEprtrDtoListByIds = (
  eprtrRef: ReferenceItemEprtrDto[],
  eprtrIds: string[]
) => {
  const eprtrs: ReferenceItemEprtrDto[] = [];
  eprtrIds.forEach(eprtrId => {
    const eprtr = getEprtrDtoById(eprtrRef, eprtrId);
    if (eprtr !== null) {
      eprtrs.push(eprtr);
    }
  });

  return eprtrs;
};

export const computeTechInitialValues = (
  declarationDto: Declaration2020,
  referentielEPRTR: ReferenceItemEprtrDto[]
): TechniqueValues => {
  const typeActiviteDto: TypeActiviteDto2020 = declarationDto.body.typeActivite;
  const activitesEPRTR: ReferenceItemEprtrDto[] = getEprtrDtoListByIds(
    referentielEPRTR,
    typeActiviteDto.activitesEPRTRIDs
  );

  const returnedResult: TechniqueValues = {
    EPRTR: typeActiviteDto.estEPRTR || false,
    principalEPRTR: getEprtrDtoById(
      referentielEPRTR,
      typeActiviteDto.activitePrincipaleEPRTRID || ""
    ),
    activitesEPRTR: activitesEPRTR,
    elevage: typeActiviteDto.elevage || false,
    combustion20MW: typeActiviteDto.combustion20MW || false,
    incinerationDechet: typeActiviteDto.incinerationDechet || false,
    solvants: typeActiviteDto.estConsommateurSolvant || false,
    recepDechet: typeActiviteDto.recepDechet || false,
    ISDND: typeActiviteDto.isdnd || false,
    ISDD: typeActiviteDto.isdd || false,
    ISDI: typeActiviteDto.isdi || false,
    torchereBiogaz: typeActiviteDto.torchereBiogaz || false,
    valorisationBiogaz: typeActiviteDto.valorisationBiogaz || false,
    exportBiogaz: typeActiviteDto.exporteBiogaz || false,
    carriere: typeActiviteDto.carriere || false,
    quotas: typeActiviteDto.estSousQuotaCo2 || false,
    excluArticle27: typeActiviteDto.excluArticle27 || false,
  };
  return returnedResult;
};

export const createTechniqueDto = (
  values: TechniqueValues,
  installationsInArray: InstallationInArray[]
): TypeActiviteDto2020 => {
  const activitePrincipaleEPRTRId = values.principalEPRTR
    ? values.principalEPRTR.uuid
    : null;
  const activiteEPRTRIds = values.activitesEPRTR
    ? values.activitesEPRTR.map(eprtr => {
        return eprtr.uuid;
      })
    : [];
  const returnedDto: TypeActiviteDto2020 = {
    estEPRTR: values.EPRTR,
    activitePrincipaleEPRTRID: activitePrincipaleEPRTRId,
    activitesEPRTRIDs: activiteEPRTRIds,
    combustion20MW: !!values.combustion20MW,
    incinerationDechet: !!values.incinerationDechet,
    elevage: !!values.elevage,
    estConsommateurSolvant: !!values.solvants,
    recepDechet: !!values.recepDechet,
    isdi: !!values.ISDI,
    isdd: !!values.ISDD,
    isdnd: !!values.ISDND,
    torchereBiogaz: !!values.torchereBiogaz,
    exporteBiogaz: !!values.exportBiogaz,
    valorisationBiogaz: !!values.valorisationBiogaz,
    carriere: !!values.carriere,
    estSousQuotaCo2: values.quotas,
    excluArticle27: !!values.excluArticle27,
    installations: installationsInArray.map(
      singleInstallationToInstallationDto
    ),
  };

  return returnedDto;
};

export const overrideUncoherentValuesTechniqueDto = (
  tech: TypeActiviteDto2020
): TypeActiviteDto2020 => {
  if (!tech.recepDechet) {
    tech.incinerationDechet = false;
    tech.isdnd = false;
    tech.isdd = false;
    tech.isdi = false;
  }
  if (!tech.isdnd) {
    tech.exporteBiogaz = false;
    tech.torchereBiogaz = false;
    tech.valorisationBiogaz = false;
  }
  if (!tech.estEPRTR) {
    tech.activitesEPRTRIDs = [];
    tech.activitePrincipaleEPRTRID = null;
  }
  return tech;
};
