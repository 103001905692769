import * as Yup from "yup";
import {
  commonObjectFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { SubstanceExtractionType } from "../BlocActiviteExtractive/utils/types";
import { ProduitRecyclingType } from "../BlocActiviteRecyclage/utils/types";
import { Nullable } from "common/utils/types";
import {
  productionFamiliesCombinationSubstanceMessage,
  productionFamiliesCominationProductMessage,
  tooLongMessage,
} from "common/declarant/formik/formikMessages";
import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";

export const familiesProductionValidationSchema = (additionalProps: object) => {
  return Yup.object().shape({
    ...additionalProps,

    famille: commonObjectFields,

    precisionFamille: Yup.string().when("famille", {
      is: (family: ReferenceItemFamilleUsageProductionDto) =>
        family && family.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    sousFamille: Yup.object().when("famille", {
      is: (family: ReferenceItemFamilleUsageProductionDto) =>
        family && family.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    precisionSousFamille: Yup.string().when("sousFamille", {
      is: (subFamily: ReferenceItemFamilleUsageProductionDto) =>
        subFamily && subFamily.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    sousFamilleNiveau2: Yup.object().when("sousFamille", {
      is: (subFamily: ReferenceItemFamilleUsageProductionDto) =>
        subFamily && subFamily.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    precisionSousFamilleNiveau2: Yup.string().when("sousFamilleNiveau2", {
      is: (subFamilyLevel2: ReferenceItemFamilleUsageProductionDto) =>
        subFamilyLevel2 && subFamilyLevel2.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),
  });
};

export const familiesRecyclingValidationSchema = (additionProps: object) => {
  return Yup.object().shape({
    ...additionProps,

    famille: commonObjectFields,

    precisionFamille: Yup.string().when("famille", {
      is: (family: ReferenceItemFamilleUsageRecyclageDto) =>
        family && family.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    sousFamille: Yup.object().when("famille", {
      is: (family: ReferenceItemFamilleUsageRecyclageDto) =>
        family && family.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    precisionSousFamille: Yup.string().when("sousFamille", {
      is: (subFamily: ReferenceItemFamilleUsageRecyclageDto) =>
        subFamily && subFamily.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),

    sousFamilleNiveau2: Yup.object().when("sousFamille", {
      is: (subFamily: ReferenceItemFamilleUsageRecyclageDto) =>
        subFamily && subFamily.referenceItemFamilleUsageDtos.length > 0,
      then: commonObjectFields,
      otherwise: Yup.object().nullable(),
    }),

    precisionSousFamilleNiveau2: Yup.string().when("sousFamilleNiveau2", {
      is: (subFamilyLevel2: ReferenceItemFamilleUsageRecyclageDto) =>
        subFamilyLevel2 && subFamilyLevel2.precision,
      then: commonStringFields.max(250, tooLongMessage),
      otherwise: Yup.string().nullable(),
    }),
  });
};

export const validateFamiliesForSubstanceExtractionInArray = (
  values: Nullable<SubstanceExtractionType>,
  substancesExtractionInArray: SubstanceExtractionType[]
): object | void => {
  for (const substance of substancesExtractionInArray) {
    if (substance.substance !== values.substance) {
      continue;
    }

    if (
      values.famille !== substance.famille ||
      values.famille !== substance.sousFamille ||
      values.sousFamilleNiveau2 !== substance.sousFamilleNiveau2
    ) {
      continue;
    }
    return {
      famille: productionFamiliesCombinationSubstanceMessage,
      sousFamille: productionFamiliesCombinationSubstanceMessage,
      sousFamilleNiveau2: productionFamiliesCombinationSubstanceMessage,
    };
  }
};

export const validateFamiliesForRecyclingProductsInArray = (
  values: Nullable<ProduitRecyclingType>,
  recyclingProductsInArray: ProduitRecyclingType[]
): object | void => {
  for (const product of recyclingProductsInArray) {
    if (product.nature !== values.nature) {
      continue;
    }

    if (
      values.famille !== product.famille ||
      values.famille !== product.sousFamille ||
      values.sousFamilleNiveau2 !== product.sousFamilleNiveau2
    ) {
      continue;
    }
    return {
      famille: productionFamiliesCominationProductMessage,
      sousFamille: productionFamiliesCominationProductMessage,
      sousFamilleNiveau2: productionFamiliesCominationProductMessage,
    };
  }
};
