import { DeclarationStateDtoStateEnum } from "api/gen";
import { useUserData } from "Authenticator/UserData";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import SeparateSubmissionWarning from "common/messages/SeparateSubmissionWarning";
import { useConfirmationModale } from "common/modale/hooks";
import { PATH_QUOTAS } from "common/path/path18Now";
import RedirectHome from "pages/CommonSpace/AppPage/Redirect/RedirectHome";
import React, { ReactNode } from "react";
import InfobulleGlobalActions from "./utils/InfobulleGlobalActions";
import { useQuotasButtonStateWithRedirection } from "./utils/utils";
import { QuotaWorkflowTarget } from "common/utils/types";
import {
  useBasicDeclarationHandlers21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../versionedElements/declarationHooks21Now";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import {
  computeCommentButtonState,
  isPathCommented,
} from "common/comment/commentUtils";

interface QuotasGlobalFormActionsProps {
  saveAction: () => Promise<boolean>;
  cancelAction: () => void;
  hasChanges: boolean;
  declarantBlocsValidated: boolean;
  pathToValidate: string;
  yieldQuotaVerif: () => Promise<boolean>;
  quotaState: DeclarationStateDtoStateEnum;
  target: QuotaWorkflowTarget;
  isSimplifiedQuota: boolean | null;
}

// Contrary to other pages (where GlobalFormActions are used to mark page as validated for user),
// page Quotas will use those button to submit quotas declaration or recall it.
const QuotasGlobalFormActions = ({
  saveAction,
  cancelAction,
  hasChanges,
  declarantBlocsValidated,
  pathToValidate,
  yieldQuotaVerif,
  quotaState,
  target,
  isSimplifiedQuota,
}: QuotasGlobalFormActionsProps): React.ReactElement => {
  const { validate, cancelValidate } = useBasicDeclarationHandlers21Now();
  const {
    cancelSubmit,
    submit,
    submitSimplifiedQuota,
  } = useDeclarationStateHandlers21Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const openConfirmationModale = useConfirmationModale();

  const commentsContext = useComments();

  const hasComments = isPathCommented(
    commentsContext.comments,
    pathToValidate,
    false
  );

  const userData = useUserData();

  const [
    isValidateSpinnerVisible,
    triggerValidateSpinner,
    shouldRedirectToDashboard,
  ] = useQuotasButtonStateWithRedirection<boolean>(quotaState);
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<boolean>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<boolean>();

  const additionalLeftContent: ReactNode | undefined = userData.isDeclarant && (
    <InfobulleGlobalActions target={target} />
  );
  if (shouldRedirectToDashboard) {
    return <RedirectHome initialRedirection={true} />;
  } else if (
    quotaState === DeclarationStateDtoStateEnum.STARTED ||
    quotaState === DeclarationStateDtoStateEnum.VERIFYING ||
    quotaState === DeclarationStateDtoStateEnum.IN_REVIEW
  ) {
    const isValidateAvailable =
      userData.isDeclarant &&
      (quotaState === DeclarationStateDtoStateEnum.STARTED ||
        quotaState === DeclarationStateDtoStateEnum.IN_REVIEW);
    const isEditVisible =
      userData.isDeclarant &&
      quotaState === DeclarationStateDtoStateEnum.VERIFYING;
    const areSaveAndCancelVisible =
      userData.isDeclarant &&
      quotaState === DeclarationStateDtoStateEnum.STARTED;

    return (
      <DummyGlobalFormActions
        additionalLeftContent={additionalLeftContent}
        validateButton={{
          title: isSimplifiedQuota
            ? "TRANSMETTRE À L'INSPECTION"
            : "TRANSMETTRE AU VÉRIFICATEUR",
          isVisible: isValidateAvailable,
          isEnabled: isValidateAvailable && declarantBlocsValidated,
          onClick: () => {
            openConfirmationModale(<SeparateSubmissionWarning />, async () => {
              // if the state is in review, it means it could be already validated
              if (isPathValidatedInDeclaration(pathToValidate)) {
                await cancelValidate(pathToValidate);
              }

              // validate whole page QUOTAS (without changing dto) before submitting verification
              const validateCall = async () => {
                if (
                  !(await validate(
                    declarationDto => declarationDto,
                    pathToValidate
                  ))
                ) {
                  return false;
                }
                const result = isSimplifiedQuota
                  ? await submitSimplifiedQuota(target)
                  : await yieldQuotaVerif();
                return result;
              };
              return triggerValidateSpinner(validateCall());
            });
          },
          isSpinnerVisible: isValidateSpinnerVisible,
        }}
        editButton={{
          title: "TRANSMETTRE AU VÉRIFICATEUR",
          isVisible: isEditVisible,
          isEnabled: false,
          onClick: () => {
            return triggerEditSpinner(cancelValidate(PATH_QUOTAS));
          },
          isSpinnerVisible: isEditSpinnerVisible,
        }}
        cancelButton={{
          title: "ANNULER",
          isVisible:
            areSaveAndCancelVisible &&
            hasChanges &&
            !isValidateSpinnerVisible &&
            !isSaveSpinnerVisible,
          isEnabled: true,
          onClick: cancelAction,
        }}
        saveButton={{
          title: "ENREGISTRER",
          isVisible: areSaveAndCancelVisible && hasChanges,
          isEnabled: !isSaveSpinnerVisible,
          onClick: () => {
            return triggerSaveSpinner(saveAction());
          },
          isSpinnerVisible: isSaveSpinnerVisible,
        }}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        commentButton={{
          isEnabled: true,
          state: computeCommentButtonState(userData.isLecteur, hasComments),
          onClick: e => {
            e.preventDefault();
            commentsContext.openModal(pathToValidate, false);
          },
        }}
      />
    );
  } else {
    const canSubmitForVerification =
      quotaState === DeclarationStateDtoStateEnum.VERIFIED;
    return (
      <>
        {quotaState === DeclarationStateDtoStateEnum.VERIFIED && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            editButton={{
              title: "MODIFIER",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () => {
                return triggerEditSpinner(cancelValidate(PATH_QUOTAS));
              },
              isSpinnerVisible: isEditSpinnerVisible,
            }}
          />
        )}
        {(quotaState === DeclarationStateDtoStateEnum.SUBMITTED ||
          quotaState === DeclarationStateDtoStateEnum.VALIDATED) && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            editButton={{
              title: "DEMANDER LA MODIFICATION",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () => {
                return triggerEditSpinner(cancelSubmit([target]));
              },
              isSpinnerVisible: false,
            }}
          />
        )}
        {canSubmitForVerification && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            validateButton={{
              title: "TRANSMETTRE À L'INSPECTION",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () =>
                openConfirmationModale(<SeparateSubmissionWarning />, () =>
                  triggerValidateSpinner(submit([target]))
                ),
              isSpinnerVisible: isValidateSpinnerVisible,
            }}
            validationMessage={{
              message: DEFAULT_VALIDATION_MESSAGE,
              isAlwaysVisible: false,
            }}
          />
        )}
      </>
    );
  }
};

export default QuotasGlobalFormActions;
