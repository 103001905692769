import React from "react";
import * as Yup from "yup";
import {
  familiesRecyclingValidationSchema,
  validateFamiliesForRecyclingProductsInArray,
} from "../Families/validation";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { isNatureDefinedAsOther } from "./utils/utils";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import { natures } from "./utils/selectPossibleValues";
import {
  onSelectFamilyHandler,
  onSelectSubFamilyHandler,
  onSelectSubFamilyLvl2Handler,
} from "../Families/utils";
import { PRECISE_OTHER, PRODUCTS_NATURE } from "./utils/languages";
import {
  PRECISION_SUB_USAGE_FAMILY,
  PRECISION_USAGE_FAMILY,
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "../Families/languages";
import { ReferenceFamilleUsageRecyclageDto } from "api/gen";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import {
  ProduitRecyclingType,
  fieldProduitRecyclingTypeMatcher,
} from "./utils/types";
import uuid from "uuid";
import { YEARLY_AMOUNT } from "../utils/languages";

interface RecyclingProductModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  recyclingProductInModal: ProduitRecyclingType | undefined;
  setRecyclingProductInModal: (
    product: ProduitRecyclingType | undefined
  ) => void;
  recyclingProductsInArray: ProduitRecyclingType[];
  setRecyclingProductsInArray: (products: ProduitRecyclingType[]) => void;
  referentialFamilies: ReferenceFamilleUsageRecyclageDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = familiesRecyclingValidationSchema({
  nature: commonStringFields,
  precisionAutre: Yup.string().when("nature", {
    is: (nature: string) => isNatureDefinedAsOther(nature),
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
  quantite: commonPositiveNumberFields,
});

const RecyclingProductModal = ({
  isOpen,
  setIsModalOpen,
  recyclingProductInModal,
  setRecyclingProductInModal,
  recyclingProductsInArray,
  setRecyclingProductsInArray,
  referentialFamilies,
}: RecyclingProductModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-activite-recyclage",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);

  const onClose = () => {
    setRecyclingProductInModal(undefined);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <CommonFormSingleEntity
        title="AJOUTER UN PRODUIT À RECYCLER"
        validationSchema={validationSchema}
        initialEntity={
          recyclingProductInModal || {
            id: "",
            nature: "",
            precisionAutre: "",
            quantite: 0,
            famille: null,
            precisionFamille: "",
            sousFamille: null,
            precisionSousFamille: "",
            sousFamilleNiveau2: null,
            precisionSousFamilleNiveau2: "",
          }
        }
        closeFunction={onClose}
        isEdit={recyclingProductInModal !== undefined}
        onSubmit={(values: ProduitRecyclingType) => {
          values.id = recyclingProductInModal?.id || uuid();
          const newRecyclingProductsInArray = recyclingProductsInArray;
          let indexToReplace = newRecyclingProductsInArray.length;
          if (recyclingProductInModal) {
            indexToReplace = _.findIndex(
              newRecyclingProductsInArray,
              recyclingProductInModal
            );
            setRecyclingProductInModal(undefined);
          }
          newRecyclingProductsInArray[indexToReplace] = values;
          setRecyclingProductsInArray(newRecyclingProductsInArray);
          setIsModalOpen(false);
        }}
        validate={values =>
          validateFamiliesForRecyclingProductsInArray(
            values,
            recyclingProductsInArray
          )
        }
        renderField={({ values, setFieldValue }) => (
          <>
            <WrappedChoiceSelect
              label={`${PRODUCTS_NATURE} *`}
              name={fieldProduitRecyclingTypeMatcher.nature}
              isMulti={false}
              options={natures}
              computeLabel={option => option}
              commonProps={commonProps}
            />
            {values.nature && isNatureDefinedAsOther(values.nature) && (
              <TextField label={`${PRECISE_OTHER} *`} name="naturePrecision" />
            )}
            <NumberField
              label={`${YEARLY_AMOUNT} *`}
              name={fieldProduitRecyclingTypeMatcher.quantity}
              unit="ktonnes"
            />
            <WrappedChoiceSelect
              label={`${USAGE_FAMILY} *`}
              name={fieldProduitRecyclingTypeMatcher.family}
              isMulti={false}
              options={
                referentialFamilies.referenceItemFamilleUsageRecyclageDtoList
              }
              additionalOnChange={() => onSelectFamilyHandler(setFieldValue)}
              computeLabel={option => option.code + " - " + option.libelle}
              commonProps={commonProps}
            />
            {values.famille && values.famille.precision && (
              <TextField
                label={`${PRECISION_USAGE_FAMILY} *`}
                name={fieldProduitRecyclingTypeMatcher.precisionFamily}
              />
            )}
            {values.famille &&
              values.famille.referenceItemFamilleUsageDtos.length > 0 && (
                <WrappedChoiceSelect
                  label={`${SUB_USAGE_FAMILY} *`}
                  name={fieldProduitRecyclingTypeMatcher.subFamily}
                  isMulti={false}
                  options={values.famille?.referenceItemFamilleUsageDtos}
                  additionalOnChange={() =>
                    onSelectSubFamilyHandler(setFieldValue)
                  }
                  computeLabel={option => option.code + " - " + option.libelle}
                  commonProps={commonProps}
                />
              )}
            {values.sousFamille && values.sousFamille.precision && (
              <TextField
                label={`${PRECISION_SUB_USAGE_FAMILY} *`}
                name={fieldProduitRecyclingTypeMatcher.precisionSubFamily}
              />
            )}
            {values.sousFamille &&
              values.sousFamille.referenceItemFamilleUsageDtos.length > 0 && (
                <WrappedChoiceSelect
                  label={`${SUB_USAGE_FAMILY_LEVEL_2} *`}
                  name={fieldProduitRecyclingTypeMatcher.subFamilyLevel2}
                  isMulti={false}
                  options={values.sousFamille?.referenceItemFamilleUsageDtos}
                  additionalOnChange={() =>
                    onSelectSubFamilyLvl2Handler(setFieldValue)
                  }
                  computeLabel={option => option.code + " - " + option.libelle}
                  commonProps={commonProps}
                />
              )}
            {values.sousFamilleNiveau2 &&
              values.sousFamilleNiveau2.precision && (
                <TextField
                  label={`${PRECISION_SUB_USAGE_FAMILY} (niveau 2)*`}
                  name={
                    fieldProduitRecyclingTypeMatcher.precisionSubFamilyLevel2
                  }
                />
              )}
          </>
        )}
      />
    </CustomModal>
  );
};

export default RecyclingProductModal;
