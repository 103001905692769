import * as Yup from "yup";
import { parseToUndefinedIfNull } from "common/form/utils";
import {
  commonObjectFields,
  commonObjectFieldsNullable,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import {
  eauInvalidNumberDaysMessage,
  eauTakingAtLeastOneMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { LieuEauDeMerEnum } from "./types";

export const WATER_TAKING_THRESHOLD = 50000;

// Build all pairs of interdependant fields when at least one of them is required
// https://github.com/jquense/yup/issues/193#issuecomment-437878160
const allPairs = (values: string[]) => {
  const res: [string, string][] = [];
  values.forEach(str1 => {
    values.forEach(str2 => {
      if (str1 !== str2) {
        res.push([str1, str2]);
      }
    });
  });
  return res;
};

const validationSchemaPrelevement = Yup.object().shape({
  nbJoursTravailles: Yup.number().nullable(),
  volumeEauSurface: Yup.number().nullable(),
  lieuEauSurface: Yup.object().nullable(),
  volumeEauSouterraine: Yup.number().nullable(),
  lieuEauSouterraine: Yup.object().nullable(),
  volumeEauDistribution: Yup.number().nullable(),
  volumeEauMer: Yup.number().nullable(),
  lieuEauMer: Yup.mixed<LieuEauDeMerEnum>().nullable(),
  prelevementTotal: Yup.number().nullable(),
});

const validationSchemaFullForm = Yup.object().shape(
  {
    nbJoursTravailles: commonPositiveNumberFields.max(
      366,
      eauInvalidNumberDaysMessage
    ),
    volumeEauSurface: Yup.number().when(
      ["volumeEauSouterraine", "volumeEauDistribution", "volumeEauMer"],
      {
        is: (a: any, b: any, c: any) => !a && !b && !c,
        then: commonPositiveNumberFieldsNullable
          .moreThan(0, eauTakingAtLeastOneMessage)
          .required(eauTakingAtLeastOneMessage),
        otherwise: commonPositiveNumberFieldsNullable,
      }
    ),
    volumeEauSouterraine: Yup.number().when(
      ["volumeEauSurface", "volumeEauDistribution", "volumeEauMer"],
      {
        is: (a: any, b: any, c: any) => !a && !b && !c,
        then: commonPositiveNumberFieldsNullable
          .moreThan(0, eauTakingAtLeastOneMessage)
          .required(eauTakingAtLeastOneMessage),
        otherwise: commonPositiveNumberFieldsNullable,
      }
    ),
    volumeEauDistribution: Yup.number().when(
      ["volumeEauSurface", "volumeEauSouterraine", "volumeEauMer"],
      {
        is: (a: any, b: any, c: any) => !a && !b && !c,
        then: commonPositiveNumberFieldsNullable
          .moreThan(0, eauTakingAtLeastOneMessage)
          .required(eauTakingAtLeastOneMessage),
        otherwise: commonPositiveNumberFieldsNullable,
      }
    ),
    volumeEauMer: Yup.number().when(
      ["volumeEauSurface", "volumeEauSouterraine", "volumeEauDistribution"],
      {
        is: (a: any, b: any, c: any) => !a && !b && !c,
        then: commonPositiveNumberFieldsNullable
          .moreThan(0, eauTakingAtLeastOneMessage)
          .required(eauTakingAtLeastOneMessage),
        otherwise: commonPositiveNumberFieldsNullable,
      }
    ),
    lieuEauSurface: Yup.object()
      .transform(parseToUndefinedIfNull)
      .when("volumeEauSurface", {
        is: (value: number | null) => !!value,
        then: commonObjectFields,
        otherwise: commonObjectFieldsNullable,
      }),
    lieuEauSouterraine: Yup.object()
      .transform(parseToUndefinedIfNull)
      .when("volumeEauSouterraine", {
        is: (value: number | null) => !!value,
        then: commonObjectFields,
        otherwise: commonObjectFieldsNullable,
      }),
    lieuEauMer: Yup.mixed<LieuEauDeMerEnum>()
      .transform(parseToUndefinedIfNull)
      .when("volumeEauMer", {
        is: (value: number | null) => !!value,
        then: Yup.mixed<LieuEauDeMerEnum>().required(requiredMessage),
        otherwise: Yup.mixed<LieuEauDeMerEnum>().nullable(),
      }),
    prelevementTotal: Yup.number().nullable(),
  },
  // Avoid error because of cyclic dependencies
  allPairs([
    "volumeEauSurface",
    "volumeEauSouterraine",
    "volumeEauDistribution",
    "volumeEauMer",
  ])
);

export const validationSchema = (isDepassementSeuil: boolean) => {
  return isDepassementSeuil
    ? validationSchemaFullForm
    : validationSchemaPrelevement;
};
