import React from "react";
import { FileHandlers, FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { useDeclaration21Now } from "../../../versionedElements/declarationHooks21Now";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { filesRelatedToNimDtoToFileDto, NimFilesDto } from "./utils";

interface JustificatifsFilesLoaderProps {
  installationId: string;
  shouldDisableFields: boolean;
  filesPath: string;
  fileSection: FileSectionEnum;
  justificatifFileHandler: FileHandlers;
}

const JustificatifsFilesLoader = ({
  installationId,
  shouldDisableFields,
  filesPath,
  fileSection,
  justificatifFileHandler,
}: JustificatifsFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration21Now();
  const openAlertModale = useAlertModale();

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER DOCUMENT(S) JUSTIFICATIF(S)"}
      filesPath={filesPath}
      section={fileSection}
      fileHandlers={justificatifFileHandler}
      //
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(justificatifs: Record<string, unknown>[]) =>
        filesRelatedToNimDtoToFileDto(
          installationId,
          (justificatifs as unknown) as NimFilesDto[]
        )
      }
    />
  );
};

export default JustificatifsFilesLoader;
