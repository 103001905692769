import { PopulatedSubstanceExtractionInArray } from "./types";
import {
  SubstanceExtractionDto1822,
  ReferenceSubstanceCarriereDto,
  ReferenceItemSubstanceCarriereDto,
  ReferenceFamilleUsageProductionDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import { findElementMatchingTemplate } from "common/utils/methods";
import {
  convertStringFamilyProductionToOptionProps,
  convertStringSubFamilyProductionToOptionProps,
  convertStringSubFamilyLvl2ProductionToOptionProps,
} from "../../Families/converters";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

const getSubstanceNameById = (
  substances: ReferenceItemSubstanceCarriereDto[],
  substanceId: string
) => {
  const substance = findElementMatchingTemplate(
    { uuid: substanceId },
    substances
  );
  return substance ? substance.libelle : null;
};

const getSubstanceIdByName = (
  substances: ReferenceItemSubstanceCarriereDto[],
  substanceName: string
) => {
  const substance = findElementMatchingTemplate(
    { libelle: substanceName },
    substances
  );
  return substance ? substance.uuid : null;
};

export const convertSubstanceExtractionDtoToDisplayed = (
  substancesDto: SubstanceExtractionDto1822[],
  referentialSubstances: ReferenceSubstanceCarriereDto,
  referentialFamilies: ReferenceFamilleUsageProductionDto
): PopulatedSubstanceExtractionInArray[] => {
  return substancesDto.map(singleSubstanceDto => {
    const substanceName = getSubstanceNameById(
      referentialSubstances.referenceItemSubstanceCarriereDtoList,
      singleSubstanceDto.substanceID
    );

    const family = convertStringFamilyProductionToOptionProps(
      singleSubstanceDto.familleId,
      referentialFamilies
    );
    const subFamily = convertStringSubFamilyProductionToOptionProps(
      singleSubstanceDto.sousFamilleId,
      family
    );

    const subFamilyLvl2s: {
      usageSubFamiliesLvl2: OptionPropsWithObject<
        ReferenceItemFamilleUsageProductionDto,
        number
      >;
      usageSubFamiliesLvl2Precisions: string;
    }[] = singleSubstanceDto.sousFamillesNiveau2.flatMap(subFamilyLvl2 => {
      const subFamilyLvl2OptionProps: OptionPropsWithObject<
        ReferenceItemFamilleUsageProductionDto
      > | null = convertStringSubFamilyLvl2ProductionToOptionProps(
        subFamilyLvl2.identifier,
        subFamily
      );

      if (subFamilyLvl2OptionProps === null) {
        return [];
      }

      return [
        {
          usageSubFamiliesLvl2: subFamilyLvl2OptionProps,
          usageSubFamiliesLvl2Precisions: subFamilyLvl2.precisionSiAutre,
        },
      ];
    });

    return {
      id: singleSubstanceDto.id,
      displayedSubstance: {
        substance: {
          label: substanceName || "",
          value: substanceName || "",
        },
        usageFamily: family,
        usageFamilyPrecision: singleSubstanceDto.precisionFamille,
        usageSubFamily: subFamily,
        usageSubFamilyPrecision: singleSubstanceDto.precisionSousFamilleNiveau1,
        usageSubFamiliesLvl2: subFamilyLvl2s.map(
          subFamilyLvl2 => subFamilyLvl2.usageSubFamiliesLvl2
        ),
        usageSubFamiliesLvl2Precisions: subFamilyLvl2s.map(subFamilyLvl2 => ({
          precision: subFamilyLvl2.usageSubFamiliesLvl2Precisions,
        })),
        annualQuantity: singleSubstanceDto.quantiteExtraite,
      },
    };
  });
};

export const convertDisplayedToSubtanceExtractionDto = (
  displayedSubstances: PopulatedSubstanceExtractionInArray[],
  referentialSubstances: ReferenceSubstanceCarriereDto
): SubstanceExtractionDto1822[] => {
  return displayedSubstances.map(singleDisplayedSubstance => {
    return {
      id: singleDisplayedSubstance.id,
      substanceID:
        getSubstanceIdByName(
          referentialSubstances.referenceItemSubstanceCarriereDtoList,
          singleDisplayedSubstance.displayedSubstance.substance.label
        ) || "",
      quantiteExtraite:
        singleDisplayedSubstance.displayedSubstance.annualQuantity,
      familleId: singleDisplayedSubstance.displayedSubstance.usageFamily
        ? singleDisplayedSubstance.displayedSubstance.usageFamily.object.uuid
        : "",
      precisionFamille:
        singleDisplayedSubstance.displayedSubstance.usageFamilyPrecision,
      sousFamilleId: singleDisplayedSubstance.displayedSubstance.usageSubFamily
        ? singleDisplayedSubstance.displayedSubstance.usageSubFamily.object.uuid
        : null,
      precisionSousFamilleNiveau1:
        singleDisplayedSubstance.displayedSubstance.usageSubFamilyPrecision,
      sousFamillesNiveau2: singleDisplayedSubstance.displayedSubstance.usageSubFamiliesLvl2.map(
        (eltSubFamilyLvl2, i) => {
          return {
            identifier: eltSubFamilyLvl2.object.uuid,
            precisionSiAutre: singleDisplayedSubstance.displayedSubstance
              .usageSubFamiliesLvl2Precisions[i]
              ? singleDisplayedSubstance.displayedSubstance
                  .usageSubFamiliesLvl2Precisions[i].precision
              : "",
          };
        }
      ),
    };
  });
};
