import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import JustificatifsFilesLoader from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/JustificatifsFilesLoader";
import { NimFilesLoaderProps } from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/utils";
import { useJustificatifPmsFileHandler21Now } from "pages/CompanySpace/from21/toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";

const JustificatifPmsLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): React.ReactElement => {
  const justificatifFileHandler = useJustificatifPmsFileHandler21Now(
    installationId
  );

  return (
    <JustificatifsFilesLoader
      installationId={installationId}
      shouldDisableFields={shouldDisableFields}
      filesPath={
        "body.externalSections.quotas.allocations.blocPlanMethodSurveillance.documentsJustificatifs"
      }
      fileSection={FileSectionEnum.QUOTAS_ALLOCATION_JUSTIFICATIFS_PMS}
      justificatifFileHandler={justificatifFileHandler}
    />
  );
};

export default JustificatifPmsLoader;
