import React, { ReactElement } from "react";
import { SousInstallationRefProduitDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import { isEmpty } from "lodash";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import SousInstallationReferentielProduitTable from "./SousInstallationReferentielProduitTable";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
});

interface SousInstallationReferentielProduitProps {
  sousInstallationRefProduitList: SousInstallationRefProduitDto[];
}

const SousInstallationReferentielProduit = ({
  sousInstallationRefProduitList,
}: SousInstallationReferentielProduitProps): ReactElement => {
  const classes = useStyles();

  if (isEmpty(sousInstallationRefProduitList)) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Sous-installations avec référentiel de produit
      </SubSectionTitle>

      {sousInstallationRefProduitList.map(
        (sousInstallationRefProduit, index) => (
          <SousInstallationReferentielProduitTable
            sousInstallationRefProduit={sousInstallationRefProduit}
            key={"ref-produit-" + index}
          />
        )
      )}
    </div>
  );
};

export default SousInstallationReferentielProduit;
