import React, { useState } from "react";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { FactoryInArray, TransformationInformationsForm } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import Button from "common/button";
import FactoriesArray from "./FactoriesArray";
import FactoriesModal from "./FactoriesModal";
import { convertDeclarationDtoUsingTransformationInformationsForm } from "../utils/converters";
import _ from "lodash";
import {
  convertActiviteTransformationDtoToDisplayed,
  convertDisplayedToActiviteTransformationDto,
} from "./utils/converters";
import { ReferencePaysDto, ReferenceGeoCodeInseeDto } from "api/gen";
import {
  PATH_CARRIERE_PRODUCTION_TRANSFORMATION,
  PATH_CARRIERE_PRODUCTION_TRANSFORMATION_REMPLIR_TABLEAU,
} from "common/path/path18Now";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { useDeclaration2022 } from "../../../versionedElements/declarationHooks2022";
import FormikBlocFullContext2022 from "../../../versionedElements/FormikBlocFullContext2022";

interface BlocInformationsTransformationProps {
  transformationInformationsFormProps: FormikBlocRefAndState<
    TransformationInformationsForm
  >;
  factoriesInArray: FactoryInArray[];
  setFactoriesInArray: (factories: FactoryInArray[]) => void;
  referentialCountries: ReferencePaysDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  buttonModal: {
    padding: "5px",
    marginLeft: "80%",
    marginTop: "20px",
  },
});

const BlocInformationsTransformation = ({
  transformationInformationsFormProps,
  factoriesInArray,
  setFactoriesInArray,
  referentialCountries,
  referentialInsee,
}: BlocInformationsTransformationProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2022();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "carriere-production-transformation",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [factoryInModal, setFactoryInModal] = useState<FactoryInArray | null>(
    null
  );

  const initialValues: TransformationInformationsForm = {
    transformationActivityDone:
      declaration.body.sections.carriere.production.transformation
        .doitRemplirTableau,
  };

  return (
    <FormikBlocFullContext2022
      hasChanges={transformationInformationsFormProps.hasChanges}
      setHasChanges={transformationInformationsFormProps.setHasChanges}
      formikRef={transformationInformationsFormProps.formikRef}
      title="INFORMATIONS RELATIVES À LA PREMIÈRE TRANSFORMATION (TP2)"
      validationSchema={{}}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_PRODUCTION_TRANSFORMATION}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) =>
        convertDeclarationDtoUsingTransformationInformationsForm(
          declaration,
          values,
          factoriesInArray
        )
      }
      hasFormChanges={values =>
        values.transformationActivityDone !==
          declaration.body.sections.carriere.production.transformation
            .doitRemplirTableau ||
        (values.transformationActivityDone &&
          !_.isEqual(
            convertDisplayedToActiviteTransformationDto(factoriesInArray),
            declaration.body.sections.carriere.production.transformation
              .activiteTransformations
          ))
      }
      cancelAction={() => {
        setFactoriesInArray(
          convertActiviteTransformationDtoToDisplayed(
            declaration.body.sections.carriere.production.transformation
              .activiteTransformations,
            referentialCountries,
            referentialInsee
          )
        );
        return initialValues;
      }}
      renderContent={({ values, errors }, shouldDisabledFields: boolean) => (
        <>
          <CheckBox
            name="transformationActivityDone"
            label="Une activité de transformation est réalisée hors site dans une usine ou un
                   atelier appartenant à la société, au groupe ou au groupement d'entreprise possédant
                   la carrière objet de l'enquête"
            disabled={shouldDisabledFields}
            commentPath={
              PATH_CARRIERE_PRODUCTION_TRANSFORMATION_REMPLIR_TABLEAU
            }
          />

          {values.transformationActivityDone && (
            <>
              <Button
                text="AJOUTER UNE USINE/ATELIER"
                additionalClassname={classes.buttonModal}
                isDisabled={shouldDisabledFields}
                onClick={() => setIsModalOpen(true)}
              />

              <FactoriesArray
                factoriesInArray={factoriesInArray}
                setFactoriesInArray={setFactoriesInArray}
                onEdit={factory => {
                  setFactoryInModal(factory);
                  setIsModalOpen(true);
                }}
                isDisabled={shouldDisabledFields}
              />

              <FactoriesModal
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                factoryInModal={factoryInModal}
                setFactoryInModal={setFactoryInModal}
                factoriesInArray={factoriesInArray}
                setFactoriesInArray={setFactoriesInArray}
                referentialCountries={referentialCountries}
                referentialInsee={referentialInsee}
              />

              <ErrorDisplayer message={errors.errorArrayRequired} />
            </>
          )}
        </>
      )}
    />
  );
};

export default BlocInformationsTransformation;
