import { makeStyles } from "@material-ui/styles";
import {
  AuthDroitDtoAuthProfileEnum,
  DeclarationStateDtoStateEnum,
} from "api/gen";
import { useUserData } from "Authenticator/UserData";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Button from "common/button";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  generateDeclarationTransitionWarningMessage,
  IN_REVIEW,
} from "common/messages/dashboardMessage";
import {
  useConfirmationModale,
  useConfirmationModaleWithReason,
} from "common/modale/hooks";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import React from "react";
import QuotasDeclarationOfficeUpload from "./QuotasDeclarationOfficeUpload";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import {
  useBasicDeclarationHandlers2020,
  useDeclarationHelpers2020,
  useDeclarationStateHandlers2020,
} from "../../../versionedElements/declarationHooks2020";
import { TakeOverModalContent, TakeOverModalTitle } from "./takeOverModal";

const useStyles = makeStyles({
  button: {
    margin: "auto",
    padding: "0.5em 1em",
  },
  twoColsChildren: {
    height: "auto",
    "&>div": {
      width: "50%",
    },
  },
});

interface BlocInspecteurProps {
  canValidatePage: boolean;
  workflowTarget: WorkflowTarget;
  path: string;
  currentState: DeclarationStateDtoStateEnum;
  isCampaignEnded: boolean;
}

const BlocInspecteur = ({
  canValidatePage,
  workflowTarget,
  path,
  currentState,
  isCampaignEnded,
}: BlocInspecteurProps): React.ReactElement | null => {
  const classes = useStyles();
  const openConfirmationModalWithReason = useConfirmationModaleWithReason();
  const openConfirmationModal = useConfirmationModale();
  const userData = useUserData();
  const {
    cancelTakeover,
    takeover,
    review,
    approve,
  } = useDeclarationStateHandlers2020();
  const { validate, cancelValidate } = useBasicDeclarationHandlers2020();
  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    boolean
  >();
  const [isReviewSpinnerVisible, triggerReviewSpinner] = useSpinnerState<
    boolean
  >();
  const [
    isCancelTakeoverSpinnerVisible,
    triggerCancelTakeoverSpinner,
  ] = useSpinnerState<boolean>();
  const [isTakeoverSpinnerVisible, triggerTakeoverSpinner] = useSpinnerState<
    boolean
  >();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2020();

  const isForcedState = currentState === DeclarationStateDtoStateEnum.FORCED;
  const isRecalledState =
    currentState === DeclarationStateDtoStateEnum.RECALLED;

  // Button "Mettre en révision quotas" to pass in IN_REVIEW state in workflow
  const isButtonReviewVisible =
    !isCampaignEnded &&
    (currentState === DeclarationStateDtoStateEnum.SUBMITTED ||
      currentState === DeclarationStateDtoStateEnum.RECALLED ||
      currentState === DeclarationStateDtoStateEnum.VALIDATED);
  // Button "Valider la déclaration" to pass in VALIDATED state in workflow
  const isButtonValidateVisible =
    currentState === DeclarationStateDtoStateEnum.FORCED ||
    currentState === DeclarationStateDtoStateEnum.SUBMITTED;

  if (!userData.isInspecteur) {
    return null;
  }

  return (
    <DummyUnActionedBloc
      title="espace inspecteur"
      renderContent={() => (
        <div>
          {isRecalledState && (
            <>
              <Row />
              <p>
                Le déclarant a demandé à pouvoir effectuer une modification de
                sa déclaration.
              </p>
            </>
          )}
          {isForcedState && !isCampaignEnded && (
            <>
              <Row />
              <Button
                text="Passer en saisie quotas Exploitant"
                onClick={() =>
                  triggerCancelTakeoverSpinner(cancelTakeover([workflowTarget]))
                }
                additionalClassname={classes.button}
                isSpinnerVisible={isCancelTakeoverSpinnerVisible}
              />
            </>
          )}
          {!isForcedState &&
            (userData.userInfo.droits[0].authProfile ===
              AuthDroitDtoAuthProfileEnum.SUPER_ADMIN ||
              userData.userInfo.droits[0].authProfile ===
                AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
              !isCampaignEnded) && (
              <>
                <Row />
                <Button
                  text="Déclaration d'office"
                  onClick={() =>
                    openConfirmationModal(
                      TakeOverModalTitle,
                      async () => {
                        await triggerTakeoverSpinner(
                          takeover([workflowTarget])
                        );
                        if (isPathValidatedInDeclaration(path)) {
                          cancelValidate(path);
                        }
                      },
                      TakeOverModalContent
                    )
                  }
                  additionalClassname={classes.button}
                  isSpinnerVisible={isTakeoverSpinnerVisible}
                />
              </>
            )}
          {isButtonReviewVisible && (
            <>
              <Row />
              <Button
                text={
                  isRecalledState
                    ? "Accepter demande de modification"
                    : "Mettre en révision quotas"
                }
                onClick={() =>
                  openConfirmationModalWithReason(
                    generateDeclarationTransitionWarningMessage(
                      workflowTarget,
                      IN_REVIEW
                    ),
                    "Votre message",
                    async text => {
                      const reviewCall = async () => {
                        await review([workflowTarget], text ? text : "");
                        if (isPathValidatedInDeclaration(path)) {
                          await cancelValidate(path);
                        }
                        return true;
                      };
                      return triggerReviewSpinner(reviewCall());
                    }
                  )
                }
                additionalClassname={classes.button}
                isSpinnerVisible={isReviewSpinnerVisible}
              />
            </>
          )}
          {isButtonValidateVisible && (
            <>
              <Row />
              <Button
                text="Valider la déclaration Quotas"
                //Duplicate of QuotasGlobalFormActions
                onClick={async () => {
                  const validateCall = async () => {
                    // Before PR#1119, when an admin invalidated the quota declaration,
                    // the path /quotas was not invalidated so we have to check for this specific case.
                    // in addition, it's possible the button is accessible while the path quotas is already validated in declaration
                    // because e.g the page was loaded BEFORE quota was validated
                    if (
                      !isPathValidatedInDeclaration(path) &&
                      !(await validate(declarationDto => declarationDto, path))
                    ) {
                      return false;
                    }
                    await approve([workflowTarget]);
                    return true;
                  };
                  return triggerValidateSpinner(validateCall());
                }}
                additionalClassname={classes.button}
                isDisabled={!canValidatePage}
                isSpinnerVisible={isValidateSpinnerVisible}
              />
            </>
          )}
          <Row height="40px" />
          <Row additionalClassname={classes.twoColsChildren}>
            <GreyFilesDownloader
              text="TÉLÉCHARGER UN MODÈLE DE RAPPORT DE DÉCLARATION D'OFFICE"
              fileName="Declaration_doffice_emissions.doc"
            />
            <QuotasDeclarationOfficeUpload
              workflowTarget={workflowTarget}
              isDisabled={!isForcedState}
            />
          </Row>
        </div>
      )}
    />
  );
};

export default BlocInspecteur;
