import { FluidInArray, FluidInModale } from "./types";
import { getValueOrZero } from "common/utils/numberUtils";

export const sumHFC = (values: FluidInModale): number => {
  return (
    getValueOrZero(values.hfc32) +
    getValueOrZero(values.hfc125) +
    getValueOrZero(values.hfc134a) +
    getValueOrZero(values.hfc143a) +
    getValueOrZero(values.hfc152a) +
    getValueOrZero(values.hfc227ea) +
    getValueOrZero(values.hfc1234yf) +
    getValueOrZero(values.hfc1234ze) +
    getValueOrZero(values.hfc23) +
    getValueOrZero(values.hfc41) +
    getValueOrZero(values.hfc4310mee) +
    getValueOrZero(values.hfc134) +
    getValueOrZero(values.hfc143) +
    getValueOrZero(values.hfc236fa) +
    getValueOrZero(values.hfc245ca) +
    getValueOrZero(values.hfc245fa) +
    getValueOrZero(values.hfc365mfc) +
    getValueOrZero(values.hfc1233zd)
  );
};

export const computeOtherPercentage = <T extends FluidInModale>(
  values: T
): number | null => {
  const sum = sumHFC(values);
  if (sum <= 100) {
    return 100 - sum;
  } else {
    return null;
  }
};

export const compareFluidFunction = (
  a: FluidInArray,
  b: FluidInArray
): number => {
  if (a.data.fluid && b.data.fluid && a.data.fluid.nom !== b.data.fluid.nom) {
    return a.data.fluid.nom < b.data.fluid.nom ? -1 : 1;
  }
  return 0;
};
