import { AirCombustionRecapitulatifDto } from "api/gen";
import { Information } from "./types";

//=============================================================
// INFORMATIONS
//=============================================================
export const convertInformationsToDisplayed = (
  information: AirCombustionRecapitulatifDto
) => {
  return {
    lcp: information.lcp || false,
    wiCoWi: information.wicowi || false,
  };
};

export const createAirCombustionsInformationDto = (
  informationsInPage: Information
) => {
  const information: AirCombustionRecapitulatifDto = {
    lcp: informationsInPage.lcp,
    wicowi: informationsInPage.wiCoWi,
  };
  return information;
};
