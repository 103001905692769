import {
  ActiviteDto23Now,
  ActiviteDto23NowSystemeCoordonneesEnum,
  AdministratifDto20Now,
  Adresse23NowDto,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
  ServiceGunItemDto,
} from "api/gen";
import {
  ActivityType,
  AdministratifType,
  CoordonneesDepartementMap,
} from "./types";
import { Declarants } from "../DeclarantIdentityList/types";
import uuid from "uuid";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration23Now } from "../../../toNow/versionedElements/declarationHooks23Now";
import {
  transformActiviteToReferenceEnum,
  transformReferenceToActiviteEnum,
} from "./systemCoordinatesUtils";
import { getRefApeDtoById, getRefCityInseeById } from "./converter";
import { isCountryFrance } from "../CompanyIdentity/validation";

export const createActivityDto = (values: ActivityType): ActiviteDto23Now => {
  const {
    numeroVoie,
    libelleVoie,
    typeVoie,
    adresseComplement,
    codePostal,
    informationCommune,
    codeNaf,
    systemeCoordonnees,
    ...filteredOutValues
  } = values;
  if (values.codeInspection) {
    return {
      ...filteredOutValues,
      adresse: createAdresseDto(values),
      codeNafID: values.codeNaf && values.codeNaf.uuid,
      systemeCoordonnees:
        values.systemeCoordonnees &&
        transformReferenceToActiviteEnum(
          values.systemeCoordonnees.systemeCoordonnees
        ),
    };
  }
  throw Error("Code inspection is missing.");
};

const createAdresseDto = (values: ActivityType): Adresse23NowDto => {
  return {
    numeroVoie: values.numeroVoie,
    typeVoie: values.typeVoie,
    libelleVoie: values.libelleVoie,
    adresseComplement: values.adresseComplement,
    libelleCommune: values.informationCommune?.nomCommune ?? null,
    codePostal: values.codePostal,
    uuidCommune: values.informationCommune?.uuid ?? null,
  };
};

export const createAdministratifDto = (
  values: AdministratifType
): AdministratifDto20Now => {
  // Country
  const isFrance =
    values.pays !== null && isCountryFrance(values.pays.designation);
  const countryId = isFrance ? "FRANCE" : values.pays?.uuid;

  // City
  let cityId: string | undefined = undefined;
  let cityString: string | undefined = undefined;
  if (typeof values.commune === "string") {
    cityString = values.commune;
  } else {
    cityId = values.commune?.uuid;
  }

  const { commune, pays, ...filteredOutValues } = values;
  // France's communes should have an id. Other communes should be pure string.
  const communeValue = isFrance ? cityId : cityString;
  return {
    ...filteredOutValues,
    commune: communeValue || null,
    pays: countryId || null,
  };
};

export const computeDeclarantInitialValues = (
  declarationDto: Declaration23Now
): Declarants => {
  const returnedResult: Declarants = {
    declarants: [
      {
        prenom: "",
        nom: "",
        email: "",
        telephone: "",
        role: "",
        id: uuid(),
      },
    ],
  };

  if (declarationDto.body.sections.infoGenerale.declarants.length !== 0) {
    returnedResult.declarants =
      declarationDto.body.sections.infoGenerale.declarants;
  }

  return returnedResult;
};

export const computeActiviteInitialValues = (
  declarationDto: Declaration23Now,
  serviceGunReferentiel: ServiceGunItemDto[],
  cityReferentiels: ReferenceItemGeoCodeInseeDto[],
  apeCodeReferentiels: ReferenceItemCodeApeDto[],
  coordonneesDepartementMap: CoordonneesDepartementMap
): ActivityType => {
  const activiteDto = declarationDto.body.sections.infoGenerale.activite;

  const city = getRefCityInseeById(
    cityReferentiels,
    activiteDto.adresse.uuidCommune || ""
  );

  const systemeCoordonnees: ActiviteDto23NowSystemeCoordonneesEnum | null =
    activiteDto.systemeCoordonnees;

  const numeroDepartement: string | null = city && city.numeroDepartement;

  const availableReferenceItems: Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  > =
    (numeroDepartement && coordonneesDepartementMap.get(numeroDepartement)) ||
    new Map();

  const referenceItem: ReferenceItemCoordonneesDepartementDto | null =
    (systemeCoordonnees &&
      availableReferenceItems.get(
        transformActiviteToReferenceEnum(systemeCoordonnees)
      )) ||
    null;

  const codeNaf = getRefApeDtoById(
    apeCodeReferentiels,
    activiteDto.codeNafID || ""
  );

  return {
    ...activiteDto,
    informationCommune: city,
    codeNaf: codeNaf,
    systemeCoordonnees: referenceItem,
    numeroVoie: activiteDto.adresse.numeroVoie,
    typeVoie: activiteDto.adresse.typeVoie,
    libelleVoie: activiteDto.adresse.libelleVoie,
    adresseComplement: activiteDto.adresse.adresseComplement,
    codePostal: activiteDto.adresse.codePostal,
    trancheEmploye: activiteDto.trancheEmploye,
  };
};

export const computeAdminInitialValues = (
  declarationDto: Declaration23Now,
  countryReferentiels: ReferenceItemPaysDto[],
  cityReferentiels: ReferenceItemGeoCodeInseeDto[]
): AdministratifType => {
  const administratif = declarationDto.body.sections.infoGenerale.administratif;

  // Country
  const isFrance =
    administratif.pays !== null && isCountryFrance(administratif.pays);
  let country = null;

  if (administratif.pays !== null) {
    country = isFrance
      ? // France has no id, should be found by its label
        findElementMatchingTemplate(
          { designation: "FRANCE" },
          countryReferentiels
        )
      : // Other countries should have an id
        findElementMatchingTemplate(
          { uuid: administratif.pays },
          countryReferentiels
        );
  }

  // City
  const commune = administratif.commune;
  let city = null;

  if (commune !== null) {
    city = isFrance
      ? // France's cities should be given as an id
        findElementMatchingTemplate({ uuid: commune }, cityReferentiels)
      : // Cities from another country should be pure string
        commune;
  }

  return {
    ...administratif,
    commune: city,
    pays: country,
  };
};
