import React from "react";
import { InfosGeneralesEmissionDto } from "api/gen";
import SubSectionTitle from "../../../common/TitleComponents/SubSectionTitle";
import { makeStyles } from "@material-ui/styles";
import { useDummyTextFieldGenerator } from "common/form/fields/helpers/generators";
import { FORM_FIELD_STYLE } from "./theme";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
  ...FORM_FIELD_STYLE,
});

interface AerInfosGeneralesProps {
  infosGeneralesAerDto: InfosGeneralesEmissionDto;
}

const AerInfosGenerales = ({
  infosGeneralesAerDto,
}: AerInfosGeneralesProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "60%",
    formPrefix: "aer-infos-generales",
  };
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.centeredInput
  );

  return (
    <div className={classes.container}>
      <SubSectionTitle>Informations générales de l'AER</SubSectionTitle>
      <div className={classes.formContainer}>
        <DummyTextField
          name={"annee"}
          label={"Année de la déclaration"}
          value={infosGeneralesAerDto.annee}
        />
        <DummyTextField
          name={"numeroNim"}
          label={"Numéro NIM"}
          value={infosGeneralesAerDto.numeroNim}
        />
      </div>
    </div>
  );
};

export default AerInfosGenerales;
