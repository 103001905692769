import {
  QuotasInstallationDto20Now,
  ReferenceActiviteQuotaDto,
  ReferenceItemActiviteQuotaDto,
  RestorationCode,
} from "api/gen";
import toLocalDateString from "common/utils/ToLocalDateString";
import { InstallationInArray, InstallationInModale } from "./types";

const installationDtoToInstallation = (
  installation: QuotasInstallationDto20Now,
  referentialActivites: ReferenceActiviteQuotaDto
): InstallationInModale => {
  const codesActivite: ReferenceItemActiviteQuotaDto[] = getReferenceItemActiviteListByCodes(
    installation.codesActivite ?? [],
    referentialActivites
  );

  let entreeSEQE: Date | null;
  if (installation.entreeSEQE === null) {
    entreeSEQE = null;
  } else {
    entreeSEQE = new Date(installation.entreeSEQE);
    // The backend does not save the time and the frontend converts it to locale one
    entreeSEQE.setHours(0, 0, 0, 0);
  }

  let sortieSEQE: Date | null;
  if (installation.sortieSEQE === null) {
    sortieSEQE = null;
  } else {
    sortieSEQE = new Date(installation.sortieSEQE);
    // The backend does not save the time and the frontend converts it to locale one
    sortieSEQE.setHours(0, 0, 0, 0);
  }

  return {
    nim: installation.nim,
    nomInstallation: installation.nomInstallation,
    entreeSEQE: entreeSEQE,
    sortieSEQE: sortieSEQE,
    codesActivite: codesActivite,
    isNotAllocationGratuit: installation.isNotAllocationGratuit,
    isNouvelEntrant: installation.isNouvelEntrant,
  };
};

export const dtoToInstallations = (
  installations: QuotasInstallationDto20Now[],
  referentialActivites: ReferenceActiviteQuotaDto
): InstallationInArray[] => {
  //TODO compute errors
  return installations.map(installation => ({
    data: {
      ...installationDtoToInstallation(installation, referentialActivites),
      id: installation.id,
    },
    errors: {},
  }));
};

export const singleInstallationToInstallationDto = (
  populatedInstallation: InstallationInArray
): QuotasInstallationDto20Now => {
  return {
    id: populatedInstallation.data.id,
    nim: populatedInstallation.data.nim,
    nomInstallation: populatedInstallation.data.nomInstallation,
    entreeSEQE: populatedInstallation.data.entreeSEQE
      ? toLocalDateString(populatedInstallation.data.entreeSEQE)
      : null,
    sortieSEQE: populatedInstallation.data.sortieSEQE
      ? toLocalDateString(populatedInstallation.data.sortieSEQE)
      : null,
    codesActivite: populatedInstallation.data.codesActivite
      ? populatedInstallation.data.codesActivite.map(activite => ({
          code: activite.restorationCode,
        }))
      : [],
    isNotAllocationGratuit:
      populatedInstallation.data.isNotAllocationGratuit !== null
        ? populatedInstallation.data.isNotAllocationGratuit
        : false,
    isNouvelEntrant:
      populatedInstallation.data.isNouvelEntrant !== null
        ? populatedInstallation.data.isNouvelEntrant
        : false,
  };
};

const getReferenceItemFromRestorationCode = (
  restorationCode: RestorationCode,
  referentialActivites: ReferenceActiviteQuotaDto
): ReferenceItemActiviteQuotaDto | null => {
  return (
    referentialActivites.referenceItemActiviteQuotaDtoList.find(
      referenceItem => referenceItem.restorationCode === restorationCode.code
    ) || null
  );
};

const getReferenceItemActiviteListByCodes = (
  restorationCodes: RestorationCode[],
  referentialActivites: ReferenceActiviteQuotaDto
) => {
  const activites: ReferenceItemActiviteQuotaDto[] = [];
  restorationCodes.forEach(restorationCode => {
    const activite = getReferenceItemFromRestorationCode(
      restorationCode,
      referentialActivites
    );
    if (activite !== null) {
      activites.push(activite);
    }
  });

  return activites;
};
