import { makeStyles } from "@material-ui/styles";
import React from "react";
import Collapsible from "common/presentational/Collapsible";
import Tabs from "common/tabs";
import Row from "common/presentational/Row";
import {
  ReferenceCombustibleDto,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import isEqual from "lodash.isequal";
import { createAirCombustionEmissionDto } from "./BlocEmissions/utils/utils";
import { createAirCombustionsCombustibleDto } from "./BlocCombustibles/utils/utils";
import { createAirCombustionsAppareilDto } from "./BlocAppareils/utils/utils";
import BlocEmissions from "./BlocEmissions";
import BlocCombustibles from "./BlocCombustibles";
import BlocAppareils from "./BlocAppareils";
import { InstallationInArray } from "../BlocInstallation/utils/types";
import { AppareilInArray } from "./BlocAppareils/utils/types";
import { CombustibleInArray } from "./BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./BlocEmissions/utils/types";
import { ReferentialSinglePolluants } from "../utils/types";
import BlocFullContext2020 from "../../../versionedElements/BlocFullContext2020";
import { Declaration2020 } from "../../../versionedElements/declarationHooks2020";

const useStyles = makeStyles({
  container: {
    position: "relative",
  },
});

interface ListInstallationProps {
  installationsList: InstallationInArray[];
  validationContentPath: string;
  initialAppareils: AppareilInArray[];
  appareilsInPage: AppareilInArray[];
  setAppareilsInPage: React.Dispatch<React.SetStateAction<AppareilInArray[]>>;
  initialCombustibles: CombustibleInArray[];
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  initialFacteurs: FacteurEmissionInArray[];
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  initialMesures: MesureEmissionInArray[];
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  initialMatieres: MatiereEmissionInArray[];
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  combustibleReferential: ReferenceCombustibleDto;
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
  referentialSinglePolluants: ReferentialSinglePolluants;
  substancesAir: ReferenceItemPolluantDto[];
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElemenAutreUuid: string;
}

const ListInstallation = ({
  installationsList,
  validationContentPath,
  initialAppareils,
  appareilsInPage,
  setAppareilsInPage,
  initialCombustibles,
  combustiblesInPage,
  setCombustiblesInPage,
  initialFacteurs,
  facteursInPage,
  setFacteursInPage,
  initialMesures,
  mesuresInPage,
  setMesuresInPage,
  initialMatieres,
  matieresInPage,
  setMatieresInPage,
  combustibleReferential,
  isTorchereBiogaz,
  isValorisationBiogaz,
  substancesAir,
  referentialSinglePolluants,
  polluantElementMap,
  referentialElemenAutreUuid,
}: ListInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const updateHandler = (declaration: Declaration2020) => {
    declaration.body.sections.air.combustion.content.emissions = createAirCombustionEmissionDto(
      [facteursInPage, mesuresInPage, matieresInPage]
    );
    declaration.body.sections.air.combustion.content.combustibles = createAirCombustionsCombustibleDto(
      combustiblesInPage
    );
    declaration.body.sections.air.combustion.content.appareils = createAirCombustionsAppareilDto(
      appareilsInPage
    );
    return declaration;
  };

  return (
    <div className={classes.container}>
      <BlocFullContext2020
        title={""}
        hasModification={
          !isEqual(initialAppareils, appareilsInPage) ||
          !isEqual(initialCombustibles, combustiblesInPage) ||
          !isEqual(initialFacteurs, facteursInPage) ||
          !isEqual(initialMesures, mesuresInPage) ||
          !isEqual(initialMatieres, matieresInPage)
        }
        isValidateButtonAvailable={true}
        path={validationContentPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => {
          setAppareilsInPage(initialAppareils);
          setCombustiblesInPage(initialCombustibles);
          setFacteursInPage(initialFacteurs);
          setMesuresInPage(initialMesures);
          setMatieresInPage(initialMatieres);
        }}
        renderContent={shouldDisabledFields => {
          return installationsList.map(singleInstallation => {
            return (
              <div key={singleInstallation.data.id}>
                <Collapsible title={singleInstallation.data.nom || ""}>
                  <Tabs
                    content={[
                      {
                        title: "APPAREILS",
                        renderComponent: () => (
                          <BlocAppareils
                            setAppareilsInPage={setAppareilsInPage}
                            appareilsInPage={appareilsInPage}
                            installation={singleInstallation}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            setCombustiblesInPage={setCombustiblesInPage}
                            combustiblesInPage={combustiblesInPage}
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            matieresInPage={matieresInPage}
                            setMatieresInPage={setMatieresInPage}
                            mesuresInPage={mesuresInPage}
                            setMesuresInPage={setMesuresInPage}
                          />
                        ),
                      },
                      {
                        title: "COMBUSTIBLES",
                        renderComponent: () => (
                          <BlocCombustibles
                            setCombustiblesInPage={setCombustiblesInPage}
                            combustiblesInPage={combustiblesInPage}
                            installationName={singleInstallation.data.nom}
                            combustibleReferential={combustibleReferential}
                            appareilsInPage={appareilsInPage}
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            isTorchereBiogaz={isTorchereBiogaz}
                            isValorisationBiogaz={isValorisationBiogaz}
                            referentialSinglePolluants={
                              referentialSinglePolluants
                            }
                            referentialElemenAutreUuid={
                              referentialElemenAutreUuid
                            }
                          />
                        ),
                      },
                      {
                        title: "ÉMISSIONS",
                        renderComponent: () => (
                          <BlocEmissions
                            facteursInPage={facteursInPage}
                            setFacteursInPage={setFacteursInPage}
                            mesuresInPage={mesuresInPage}
                            setMesuresInPage={setMesuresInPage}
                            matieresInPage={matieresInPage}
                            setMatieresInPage={setMatieresInPage}
                            installationName={singleInstallation.data.nom}
                            shouldDisabledFields={shouldDisabledFields}
                            validationContentPath={validationContentPath}
                            substancesAir={substancesAir}
                            combustiblesInPage={combustiblesInPage}
                            appareilsInPage={appareilsInPage}
                            referentialSinglePolluants={
                              referentialSinglePolluants
                            }
                            polluantElementMap={polluantElementMap}
                            referentialElementAutreUuid={
                              referentialElemenAutreUuid
                            }
                          />
                        ),
                      },
                    ]}
                  />
                </Collapsible>
                <Row height={"10px"} />
              </div>
            );
          });
        }}
      />
    </div>
  );
};

export default ListInstallation;
