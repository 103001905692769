import {
  solvantsCovMentionDangerMessage,
  solvantsCOVNMTotauxMessage,
  solvantsCOVSpecifiquesMessage,
} from "common/declarant/formik/formikMessages";
import { EmissionsInArray } from "../EmissionBloc/utils/types";
import { CovInArray } from "../CovBloc/utils/types";
import { ErrorLevel, ErrorProps } from "common/utils/types";

export const getSommeCOVTotal = (
  emissionTotale: EmissionsInArray[]
): number => {
  let total = 0;
  emissionTotale.forEach(emission => {
    if (emission.data.emissionTotale !== null) {
      total += emission.data.emissionTotale;
    }
  });
  return total;
};

export const getSommeCOVDangerTotal = (
  covsInArray: CovInArray[],
  isDanger: boolean
): number => {
  if (!isDanger) {
    return 0;
  }
  let total = 0;
  covsInArray.forEach(emission => {
    if (emission.data.flux !== null) {
      total += emission.data.flux;
    }
  });
  return total;
};

export const getCOVNMError = (
  emissionTotalesInArray: EmissionsInArray[],
  emissionSpecifiquesInArray: EmissionsInArray[],
  covsInArray: CovInArray[],
  isDanger: boolean
): ErrorProps | undefined => {
  if (
    getSommeCOVTotal(emissionTotalesInArray) > 0 &&
    getSommeCOVTotal(emissionTotalesInArray) <
      getSommeCOVTotal(emissionSpecifiquesInArray)
  ) {
    return {
      text: solvantsCOVNMTotauxMessage,
      errorLevel: ErrorLevel.Blocking,
    };
  }
  if (
    getSommeCOVTotal(emissionSpecifiquesInArray) >= 30000 &&
    getSommeCOVTotal(emissionTotalesInArray) <
      getSommeCOVTotal(emissionSpecifiquesInArray)
  ) {
    return {
      text: solvantsCOVSpecifiquesMessage,
      errorLevel: ErrorLevel.Blocking,
    };
  }
  if (
    emissionTotalesInArray.length === 0 &&
    getSommeCOVDangerTotal(covsInArray, isDanger) >= 30000
  ) {
    return {
      text: solvantsCovMentionDangerMessage,
      errorLevel: ErrorLevel.Blocking,
    };
  }
  return undefined;
};
