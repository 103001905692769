import React, { Dispatch, SetStateAction } from "react";
import { EmissionsInArray, MatiereEmissionInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import {
  AirFugitivesEmissionBilanSortantsDto20Now,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { createAppareilDiv } from "./utils/utils";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import { computeEmissionAnnuelleForIntrantSortant } from "../../../../../toNow/Air/combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import { shouldPartBeFilled } from "../../../../../toNow/Air/combustionProcedeUtils/bilanMatiere/elementsHelpers";

interface SyntheseSortantsArrayProps {
  matiereEmissionsInPage: MatiereEmissionInArray[];
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >;
  autreElementUuid: string;
}

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const SyntheseSortantsArray = ({
  matiereEmissionsInPage,
  setEmissionModaleOpen,
  isValidated,
  setEmissionInModale,
  polluantElementMap,
  autreElementUuid,
}: SyntheseSortantsArrayProps): React.ReactElement => {
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const classes = useStyles();

  const computeSingleSortantLine = (
    sortant: AirFugitivesEmissionBilanSortantsDto20Now,
    emission: MatiereEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const emissionTitleString =
      emission.data.substance !== null ? emission.data.substance.nom : "";

    const substanceName = computeFirstLineContentCell(
      editAction,
      emissionTitleString,
      isValidated,
      !_.isEmpty(emission.errors)
    );

    const substanceRestorationCode =
      emission.data.substance && emission.data.substance.restorationCode;

    const elementUuid =
      emission.data.elementProps &&
      emission.data.elementProps.referenceItemElementIndexDto.uuid;

    const polluantElementsInReferential: Map<
      string | null,
      ReferenceItemPolluantElementDto
    > | null = polluantElementMap.get(substanceRestorationCode) || null;

    const polluantElement: ReferenceItemPolluantElementDto | null =
      (polluantElementsInReferential &&
        polluantElementsInReferential.get(elementUuid)) ||
      null;

    const element =
      shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
      polluantElement === null
        ? emission.data.elementName
        : polluantElement.referenceItemElementIndexDto.nom;

    const part =
      shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
      polluantElement === null
        ? emission.data.partElement
        : 100 * polluantElement.partElement;

    const quantiteElementDansSortant = computeEmissionAnnuelleForIntrantSortant(
      sortant
    );

    return [
      computeWrappedCellWithError(substanceName, classes),
      computeWrappedCellWithError(
        emission.data.appareils && createAppareilDiv(emission.data.appareils),
        classes
      ),
      computeWrappedCellWithError(
        <p title={element || undefined}>{element}</p>,
        classes
      ),
      computeWrappedCellWithError(
        part === null ? "" : computeNumberWithSeparator(part),
        classes
      ),
      computeWrappedCellWithError(
        sortant.descriptif ? sortant.descriptif : "",
        classes
      ),
      computeWrappedCellWithError(
        sortant.quantite !== null
          ? computeNumberWithSeparator(sortant.quantite)
          : "",
        classes
      ),

      computeWrappedCellWithError(
        sortant.teneurMoyenne !== null
          ? computeNumberWithSeparator(sortant.teneurMoyenne)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparator(quantiteElementDansSortant),
        classes
      ),
      <CommonEntityButton handlerEdit={editAction} isValidated={isValidated} />,
    ];
  };

  const computeSortantArray = (
    mesureEmission: MatiereEmissionInArray,
    sortants: Array<AirFugitivesEmissionBilanSortantsDto20Now> | null
  ) =>
    sortants
      ? sortants.map(sortant =>
          computeSingleSortantLine(sortant, mesureEmission, classes)
        )
      : [];

  const arraySortantsLines = matiereEmissionsInPage
    .map(mesureEmission =>
      computeSortantArray(mesureEmission, mesureEmission.data.sortants)
    )
    .reduce(
      (allSortants, sortantArray) => allSortants.concat(sortantArray),
      []
    );

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Appareil(s) émetteur(s)</p>,
          <p>Élément sur lequel est indexé le bilan matière</p>,
          <p>Part de l'élément dans la substance émise (%)</p>,
          <p>Description du sortant</p>,
          <p>Quantité sortante (kg)</p>,
          <p>Teneur moyenne de l’élément dans le sortant (%)</p>,
          <p>Quantité de l’élément (kg)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySortantsLines}
        preferredColWidths={[
          230,
          200,
          130,
          130,
          130,
          130,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default SyntheseSortantsArray;
