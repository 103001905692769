import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import { FluidInArray, FluidInModale } from "./utils/types";
import FormSingleFluid from "./BlocHFC/FormSingleFluid";
import { ReferenceItemGazDto } from "api/gen";
import { isGazAutre } from "./validation/validation";
import { addOrModifyArray } from "common/declarant/array/utils";

interface FluidModaleProps {
  isOpen: boolean;
  fluidInArray: FluidInArray[];
  fluidInModale: FluidInArray | null;
  setFluidInArray: Dispatch<SetStateAction<FluidInArray[]>>;
  setFluidInModale: Dispatch<SetStateAction<FluidInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  selectPossibleValue: ReferenceItemGazDto[];
  declaredFluids: FluidInArray[];
}

const HFCModale = ({
  isOpen,
  fluidInArray,
  fluidInModale,
  setFluidInArray,
  setFluidInModale,
  setModaleOpen,
  selectPossibleValue,
  declaredFluids,
}: FluidModaleProps): React.ReactElement => {
  // Array of the id of the fluids already declared
  const valueIdFluidEmitted = fluidInArray
    .filter(singleFluidInArray => singleFluidInArray.data.fluid !== null)
    .map(singleFluidInArray => {
      const refGaz = singleFluidInArray.data.fluid;
      if (refGaz === null) {
        throw Error(
          "The null elems should already have been filtered out of the array"
        );
      }
      return refGaz.uuid;
    });

  const filteredSelectAllowedValues = selectPossibleValue.filter(
    singleExistingFluid => {
      if (
        fluidInModale !== null && //if we are in edit
        fluidInModale.data.fluid !== null && // and the polluant being edited has a name
        fluidInModale.data.fluid.uuid === singleExistingFluid.uuid //and this name is the same as the polluant we're discussing whether to reject or not
      ) {
        //then we're obviously allowing this polluant to be selected.
        return true;
      }
      const isInArray = valueIdFluidEmitted.includes(singleExistingFluid.uuid);
      // The user should be able to declare as many Autre emission as he wants to.
      return isGazAutre(singleExistingFluid) || !isInArray;
    }
  ); //thanks to https://stackoverflow.com/a/33034768/7059810

  const onClose = () => {
    setFluidInModale(null);
    setModaleOpen(false);
  };

  const nullInitialValue: FluidInModale = {
    fluid: null,
    preciser: null,
    quantite: null,
    capacite: null,
    justification: null,
    hfc32: null,
    hfc125: null,
    hfc134a: null,
    hfc143a: null,
    hfc152a: null,
    hfc227ea: null,
    hfc1234yf: null,
    hfc1234ze: null,
    hfc23: null,
    hfc41: null,
    hfc4310mee: null,
    hfc134: null,
    hfc143: null,
    hfc236fa: null,
    hfc245ca: null,
    hfc245fa: null,
    hfc365mfc: null,
    hfc1233zd: null,
    autre: null,
  };

  const fluidInitialValues: FluidInModale =
    fluidInModale !== null ? fluidInModale.data : nullInitialValue;

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'installations."
      onRequestClose={onClose}
    >
      <FormSingleFluid
        closeFunction={onClose}
        isEdit={fluidInModale !== null}
        onSubmit={values => {
          const newFluid: FluidInModale = {
            ...values,
            autre: isGazAutre(values.fluid),
          };
          addOrModifyArray(
            setFluidInArray,
            fluidInModale && fluidInModale.data.id,
            newFluid
          );
          setModaleOpen(false);
          setFluidInModale(null);
        }}
        selectPossibleValues={filteredSelectAllowedValues}
        initialFluid={fluidInitialValues}
        forbiddenAutreFluidNames={declaredFluids
          .filter(
            fluid =>
              isGazAutre(fluid.data.fluid) &&
              (fluidInModale === null ||
                fluid.data.preciser !== fluidInModale.data.preciser)
          )
          .map(fluid => fluid.data.preciser || "")}
      />
    </CustomModal>
  );
};

export default HFCModale;
