import React from "react";
import logoEau from "icons/pageLogos/water.svg";
import GeneralForm from "./GeneralForm";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { RouteIdProps } from "common/declarant/type";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

const Eau = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Eau",
      to: match.url,
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Eau",
    image: { src: logoEau, alt: "logo eau" },
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_POLLUANTS_EAU",
        "REFERENCE_NORMES_EAU",
        "REFERENCE_SANDRE_EAU",
        "REFERENCE_STATIONS_EPURATION",
      ]}
      render={referentials => {
        return (
          <GeneralForm
            referentialPolluants={referentials.REFERENCE_POLLUANTS_EAU}
            referentialNormes={referentials.REFERENCE_NORMES_EAU}
            referentialSandres={referentials.REFERENCE_SANDRE_EAU}
            referentialStationsEpuration={
              referentials.REFERENCE_STATIONS_EPURATION
            }
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Eau, "GLOBAL");
