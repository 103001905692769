import React, { ReactElement } from "react";
import { SurveillanceTransfereDto } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface SurveillanceTransfereListProps {
  surveillanceTransfereList: SurveillanceTransfereDto[];
}

const SurveillanceTransfereList = ({
  surveillanceTransfereList,
}: SurveillanceTransfereListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Données issues de la surveillance de CO2, du N2O et du CSC transférés,
        inhérents
      </SubSectionTitle>

      {surveillanceTransfereList.map(
        ({
          classementFlux: { referenceFlux, fluxFullName },
          idInstallation,
          nomExploitant,
          nomInstallation,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`Réf : ${referenceFlux || ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p>Dénomination de l'installation</p>,
                <p>Nom de l'exploitant</p>,
                <p>Indicateur unique de l'installation</p>,
                <p>Type de transfert</p>,
              ]}
              lines={[
                [
                  computeWrappedCell(idInstallation, classes),
                  computeWrappedCell(nomExploitant, classes),
                  computeWrappedCell(nomInstallation, classes),
                  computeWrappedCell(fluxFullName, classes),
                ],
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </div>
  );
};

export default SurveillanceTransfereList;
