import React, { ReactElement } from "react";
import Row from "common/presentational/Row";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import QuotasAllocationsSubDashboard from "./QuotasAllocationsSubDashboard";
import QuotasEmissionsSubDashboard from "./QuotasEmissionsSubDashboard";
import { useUserData } from "Authenticator/UserData";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { WorkflowTarget } from "../../../../../../../common/utils/types";
import { makeStyles } from "@material-ui/styles";
import { LIGHT_BEIGE_BACKGROUND } from "theme";
import { useDeclaration21Now } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import SubDashboardContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/SubDashboardContainer";

const useStyles = makeStyles({
  specificBackround: {
    backgroundColor: LIGHT_BEIGE_BACKGROUND,
  },
});
const DeclarationQuotasSubDashboard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const classes = useStyles();

  const {
    isAnyPrestataire,
    isPrestataireNiveauxActiviteForEtablissement,
  } = useUserData();
  const isUserPrestataireEmissionOnly =
    isAnyPrestataire &&
    !isPrestataireNiveauxActiviteForEtablissement(declarationEtablissement);

  const declaration = useDeclaration21Now();
  const isAllocation = declaration.header.allocation;

  const currentCampaign = useCurrentDeclarationCampaign();
  const endedCampaignMessageAllocations = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateAllocations,
    WorkflowTarget.QUOTA_ALLOCATIONS
  );
  const endedCampaignMessageEmissions = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateEmissions,
    WorkflowTarget.QUOTA_EMISSIONS
  );

  return (
    <SubDashboardContainer
      title="Déclaration quotas"
      additionalClassname={classes.specificBackround}
    >
      {!!endedCampaignMessageAllocations && (
        <h4>{endedCampaignMessageAllocations}</h4>
      )}
      {!isUserPrestataireEmissionOnly && isAllocation && (
        <>
          <QuotasAllocationsSubDashboard
            declarationAnnee={declarationAnnee}
            declarationEtablissement={declarationEtablissement}
          />
          <Row />
        </>
      )}
      {!!endedCampaignMessageEmissions && (
        <h4>{endedCampaignMessageEmissions}</h4>
      )}
      <QuotasEmissionsSubDashboard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
    </SubDashboardContainer>
  );
};

export default DeclarationQuotasSubDashboard;
