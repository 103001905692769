import React from "react";
import { makeStyles } from "@material-ui/styles";
import FilesDownloader from "common/FilesDownloader";
import {
  PATH_QUOTAS_ALLOCATIONS_PMS,
  PATH_QUOTAS_ALLOCATIONS_PMS_LIST_PMS_FIELD,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import PmsFilesLoader from "./PmsFilesLoader";
import {
  FIELD_FONT_WEIGHT,
  FILE_BUTTONS_STYLE,
  SECTION_TITLE_STYLE,
} from "theme";
import { useNiveauxActiviteValidationMessage } from "../../utils/utils";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import BlocFullContext2020 from "../../../versionedElements/BlocFullContext2020";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
  bold: {
    fontWeight: FIELD_FONT_WEIGHT,
  },
  nimLine: {
    height: "auto",
    "&>*": {
      width: "50%",
    },
  },
  marginBottom: {
    marginBottom: "5px",
  },
  ...SECTION_TITLE_STYLE,
});

const BlocPlanMethodSurveillance = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const validationMessage = useNiveauxActiviteValidationMessage();
  const installations =
    declaration.computed.sections.quotas.allocations.installations;

  return (
    <BlocFullContext2020
      title={"plan méthodologique de surveillance"}
      hasModification={false}
      isValidateButtonAvailable={true}
      editValidationMessage={validationMessage}
      path={PATH_QUOTAS_ALLOCATIONS_PMS}
      updateHandler={declaration => {
        return declaration;
      }}
      cancelAction={() => {
        return;
      }}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      renderContent={shouldDisabledFields => (
        <>
          <p>
            ATTENTION : le plan méthodologique de surveillance (PMS) est relatif
            <span className={classes.bold}> aux niveaux d'activité</span>. Le
            plan de surveillance, relatif aux émissions, doit être déposé dans
            la section correspondante du pavé émissions.
          </p>
          <p className={classes.marginBottom}>
            Veuillez déposer dans cette section le(s) PMS en vigueur pour
            l'année de déclaration et pour chacune des installations (NIM) de
            l'établissement (au moins 1 par NIM).
          </p>
          <Row>
            <FilesDownloader
              text="TÉLÉCHARGER UN MODÈLE DE PMS"
              fileName="ModèlePMS.xls"
              additionalClassname={classes.fileButtons}
            />
          </Row>
          <Row />
          {installations.map(installation => (
            <Row additionalClassname={classes.nimLine} key={installation.id}>
              <div className={classes.sectionTitle}>
                NIM à remplir : {installation.nim}
                <CommentButton
                  path={`${PATH_QUOTAS_ALLOCATIONS_PMS_LIST_PMS_FIELD}/${installation.id}`}
                />
              </div>
              <PmsFilesLoader
                installation={installation}
                shouldDisableFields={shouldDisabledFields}
              />
            </Row>
          ))}
        </>
      )}
    />
  );
};

export default BlocPlanMethodSurveillance;
