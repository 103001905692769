import React, { useState } from "react";
import * as Yup from "yup";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  PopulatedRecyclingProductInArray,
  RecyclingActivityForm,
} from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  commonBooleanFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import { computeTotalProductionQuantities } from "./utils/computers";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Button from "common/button";
import RecyclingProductArray from "./RecyclingProductArray";
import RecyclingProductModal from "./RecyclingProductModal";
import { convertDeclarationDtoUsingRecyclingActivityForm } from "../utils/converters";
import _ from "lodash";
import {
  convertDisplayedToProduitRecyclageDto,
  convertProduitRecyclageDtoToDisplayed,
} from "./utils/converters";
import {
  PATH_CARRIERE_PRODUCTION_RECYCLAGE,
  PATH_CARRIERE_PRODUCTION_RECYCLAGE_QUANTITE_ENTRANTE,
  PATH_CARRIERE_PRODUCTION_RECYCLAGE_REMPLIR_TABLEAU,
} from "common/path/path18Now";
import Row from "common/presentational/Row";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { productionArrayRecyclingRequired } from "common/declarant/formik/formikMessages";
import { LEFT_WITHDRAW_STYLE, SECTION_TITLE_GREY } from "theme";
import { ReferenceFamilleUsageRecyclageDto } from "api/gen";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";

interface BlocActiviteRecyclageProps {
  recyclingActivityFormProps: FormikBlocRefAndState<RecyclingActivityForm>;
  recyclingProductsInArray: PopulatedRecyclingProductInArray[];
  setRecyclingProductsInArray: (
    factories: PopulatedRecyclingProductInArray[]
  ) => void;
  referentialFamilies: ReferenceFamilleUsageRecyclageDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  buttonModal: {
    padding: "5px",
    marginLeft: "80%",
    marginTop: "20px",
  },
  containerSpace: {
    padding: "15px 10px 25px 10px",
    color: SECTION_TITLE_GREY,
  },
  ...LEFT_WITHDRAW_STYLE,
});

const validationSchema = Yup.object().shape({
  recyclingActivity: commonBooleanFields,
  recyclingMaterialQuantity: Yup.number().when("recyclingActivity", {
    is: true,
    then: commonPositiveNumberFields,
    otherwise: commonPositiveNumberFieldsNullable,
  }),
});

const BlocActiviteRecyclage = ({
  recyclingActivityFormProps,
  recyclingProductsInArray,
  setRecyclingProductsInArray,
  referentialFamilies,
}: BlocActiviteRecyclageProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "carriere-production-extraction",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const initialValues: RecyclingActivityForm = {
    recyclingActivity:
      declaration.body.sections.carriere.production.recyclage
        .doitRemplirTableau,
    recyclingMaterialQuantity:
      declaration.body.sections.carriere.production.recyclage.quantiteEntrante,
  };

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [recyclingProductInModal, setRecyclingProductInModal] = useState<
    PopulatedRecyclingProductInArray | undefined
  >(undefined);

  return (
    <FormikBlocFullContext1919
      hasChanges={recyclingActivityFormProps.hasChanges}
      setHasChanges={recyclingActivityFormProps.setHasChanges}
      formikRef={recyclingActivityFormProps.formikRef}
      title="ACTIVITÉ DE RECYCLAGE (TP3)"
      initialValues={initialValues}
      validationSchema={validationSchema}
      pathToValidate={PATH_CARRIERE_PRODUCTION_RECYCLAGE}
      hasFormChanges={values => {
        return (
          values.recyclingActivity !==
            declaration.body.sections.carriere.production.recyclage
              .doitRemplirTableau ||
          values.recyclingMaterialQuantity !==
            declaration.body.sections.carriere.production.recyclage
              .quantiteEntrante ||
          (values.recyclingActivity &&
            !_.isEqual(
              convertDisplayedToProduitRecyclageDto(recyclingProductsInArray),
              declaration.body.sections.carriere.production.recyclage
                .tableauRecyclage
            ))
        );
      }}
      cancelAction={() => {
        setRecyclingProductsInArray(
          convertProduitRecyclageDtoToDisplayed(
            declaration.body.sections.carriere.production.recyclage
              .tableauRecyclage,
            referentialFamilies
          )
        );
        return initialValues;
      }}
      updateHandler={(declaration, values) =>
        convertDeclarationDtoUsingRecyclingActivityForm(
          declaration,
          values,
          recyclingProductsInArray
        )
      }
      preventSubmitWithErrors={values => {
        if (values.recyclingActivity && recyclingProductsInArray.length === 0) {
          return {
            errorArrayRequired: productionArrayRecyclingRequired,
          };
        }
        return {};
      }}
      renderContent={({ values, errors }, shouldDisabledFields: boolean) => (
        <>
          <div className={classes.containerSpace}>
            Après le tri d'un mélange de matériaux, la quantité de matériaux qui
            n'est pas recyclée et qui reste définitivement sur le site doit être
            saisie dans le tableau TE2.
          </div>

          <CheckBox
            label="Activité de recyclage des déchets du BTP ou de déchets inertes"
            name="recyclingActivity"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_PRODUCTION_RECYCLAGE_REMPLIR_TABLEAU}
          />

          {values.recyclingActivity && (
            <>
              <div className={classes.withdrawLeft}>
                <NumberField
                  label="Quantité entrante sur le site d'un matériau ou de mélange de matériaux à recycler *"
                  name="recyclingMaterialQuantity"
                  disabled={shouldDisabledFields}
                  unit="ktonnes"
                  commentPath={
                    PATH_CARRIERE_PRODUCTION_RECYCLAGE_QUANTITE_ENTRANTE
                  }
                />
              </div>

              <Button
                text="AJOUTER UN PRODUIT"
                isDisabled={shouldDisabledFields}
                additionalClassname={classes.buttonModal}
                onClick={() => setIsModalOpen(true)}
              />

              <RecyclingProductArray
                recyclingProductsInArray={recyclingProductsInArray}
                setRecyclingProductsInArray={setRecyclingProductsInArray}
                isDisabled={shouldDisabledFields}
                onEdit={product => {
                  setRecyclingProductInModal(product);
                  setIsModalOpen(true);
                }}
              />

              <RecyclingProductModal
                isOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                recyclingProductInModal={recyclingProductInModal}
                setRecyclingProductInModal={setRecyclingProductInModal}
                recyclingProductsInArray={recyclingProductsInArray}
                setRecyclingProductsInArray={setRecyclingProductsInArray}
                referentialFamilies={referentialFamilies}
              />

              <Row />

              <DummyNumberField
                label="Total des quantités de production"
                name="totalProductionQuantities"
                value={computeTotalProductionQuantities(
                  recyclingProductsInArray
                )}
                unit="ktonnes"
                disabled
              />

              <ErrorDisplayer message={errors.errorArrayRequired} />
            </>
          )}
        </>
      )}
    />
  );
};

export default BlocActiviteRecyclage;
