import {
  AirElevageDetailNh320NowEspecesDeclareesEnum,
  AirElevageEmissionsDto,
  AirElevageNh3BatimentDto20Now,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { InArray, InModale } from "common/form/utils";
import { Omit } from "react-router";

export const emissionFieldMatcher = fieldMatcherBuilder<
  AirElevageEmissionsDto
>().build({
  NH3Emission: "nh3",
  N2OEmission: "n2o",
  CH4Emission: "ch4",
  TSPEmission: "tsp",
  PM10Emission: "pm10",
});

export interface EmissionsSpeciesForm {
  species: AirElevageDetailNh320NowEspecesDeclareesEnum[];
  errorArrayRequired?: string;
}

export const especesFieldMatcher = fieldMatcherBuilder<
  EmissionsSpeciesForm
>().build({
  species: "species",
});

type BuildingProps = Omit<AirElevageNh3BatimentDto20Now, "id">;

export type BuildingInModale = InModale<BuildingProps>;

export type BuildingInArray = InArray<BuildingProps>;

export type BuildingNh3Emissions = Omit<
  AirElevageNh3BatimentDto20Now,
  "id" | "name"
>;

export const nh3DetailsFieldMatcher = fieldMatcherBuilder<
  BuildingNh3Emissions
>().build({
  caillesEmission: "caillesEmission",
  canardsEmission: "canardsEmission",
  dindesDindonsEmission: "dindesDindonsEmission",
  pintadesEmission: "pintadesEmission",
  poulesPondeusesEmission: "poulesPondeusesEmission",
  pouletsDeChairEmission: "pouletsDeChairEmission",
  poulettesEmission: "poulettesEmission",
  autresVolaillesEmission: "autresVolaillesEmission",
  porceletsEnPostSevrageEmission: "porceletsEnPostSevrageEmission",
  porcsDeProductionEmission: "porcsDeProductionEmission",
  cochettesEmission: "cochettesEmission",
  truiesEnMaterniteEmission: "truiesEnMaterniteEmission",
  truiesEnAttenteSaillieEtGestantesEmission:
    "truiesEnAttenteSaillieEtGestantesEmission",
  verratsEmission: "verratsEmission",
  approcheGlobalePorcsEmission: "approcheGlobalePorcsEmission",
});

export const batimentFieldMatcher = fieldMatcherBuilder<
  BuildingInModale
>().build({
  buildingName: "name",
});
