import { ReferenceItemEprtrDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";
import { TypeActiviteGlobal21Now } from "../../versionedElements/declarationHooks21Now";

export interface TechniqueGlobalValues
  extends Omit<
    TypeActiviteGlobal21Now,
    "activitePrincipaleEPRTRID" | "activitesEPRTRIDs"
  > {
  principalEPRTR: ReferenceItemEprtrDto | null;
  activitesEPRTR: ReferenceItemEprtrDto[];
}

export const techniqueGlobalFieldMatcher = fieldMatcherBuilder<
  TechniqueGlobalValues
>().build({
  EPRTR: "estEPRTR",
  principalEPRTR: "principalEPRTR",
  activitesEPRTR: "activitesEPRTR",
  elevage: "elevage",
  combustion20MW: "combustion20MW",
  incinerationDechet: "incinerationDechet",
  solvants: "estConsommateurSolvant",
  recepDechet: "recepDechet",
  ISDND: "isdnd",
  ISDD: "isdd",
  ISDI: "isdi",
  torchereBiogaz: "torchereBiogaz",
  valorisationBiogaz: "valorisationBiogaz",
  exportBiogaz: "exporteBiogaz",
  carriere: "carriere",
});
