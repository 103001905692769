import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ARRAY_DEFAULT_BUTTON_WIDTH, PINK } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import { PolluantInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { renderSingleDiv } from "common/presentational/CommonEntityTable/renderHelper";
import { PATH_SOL_REJETS } from "common/path/path18Now";
import { methodLabel } from "./utils/selectPossibleValues";

const useStyles = makeStyles({
  arrayPolluantError: {
    border: "red 1px solid",
  },

  littleCol: {
    width: "130px",
  },

  longCol: {
    width: "200px",
  },

  buttonsHeader: {
    width: ARRAY_DEFAULT_BUTTON_WIDTH,
  },

  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },

  full: {},
  error: {},

  comment: {
    overflow: "hidden",
    position: "relative",
    lineHeight: "1em",
    maxHeight: "3em",
    textAlign: "justify",
  },
});

interface SolArrayProps {
  polluantsInArray: PolluantInArray[];
  isValidated: boolean;
  setPolluantsInArray: (polluants: PolluantInArray[]) => void;
  openModal: (polluant: PolluantInArray) => void;
}

const SolArray = ({
  polluantsInArray,
  isValidated,
  setPolluantsInArray,
  openModal,
}: SolArrayProps): React.ReactElement => {
  const classes = useStyles();

  function suppressPolluant(singlePolluantInArray: PolluantInArray) {
    setPolluantsInArray(
      polluantsInArray.filter(
        singleElderPolluant =>
          singleElderPolluant.data.id !== singlePolluantInArray.data.id
      )
    );
  }

  const computeSinglePolluantLine = (
    polluant: PolluantInArray,
    classes: ClassesType<"link" | "comment" | "full" | "error">
  ): React.ReactElement[] => {
    /*todo: fitzPRComment track down ALL parsing from undefined or null and replace it with lodash!!!*/

    const polluantTitle =
      polluant.data.pollutingSubstance !== null
        ? polluant.data.pollutingSubstance.nom
        : "";

    return [
      computeWrappedCellWithError(
        <p
          className={isValidated ? "" : classes.link}
          title={polluantTitle}
          onClick={
            isValidated
              ? undefined
              : () => {
                  openModal(polluant);
                }
          }
        >
          {polluantTitle}
        </p>,
        classes,
        polluant.errors.pollutingSubstance
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparator(polluant.data.emission),
        classes,
        polluant.errors.emission
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparator(polluant.data.includingAccidental),
        classes,
        polluant.errors.includingAccidental
      ),
      computeWrappedCellWithError(
        polluant.data.usedMethod !== null
          ? methodLabel[polluant.data.usedMethod]
          : "",
        classes,
        polluant.errors.usedMethod
      ),
      computeWrappedCellWithError(
        polluant.data.methodReference !== null
          ? polluant.data.methodReference.toString()
          : "",
        classes,
        polluant.errors.methodReference
      ),
      computeWrappedCellWithError(
        <p
          className={classes.comment}
          title={polluant.data.methodDescription || undefined}
        >
          {polluant.data.methodDescription}
        </p>,
        classes,
        polluant.errors.methodDescription
      ),
      computeWrappedCellWithError(
        renderSingleDiv(
          polluant.data.polluantNorme
            ? polluant.data.polluantNorme.map(norme => norme.libelle)
            : []
        ),
        classes,
        polluant.errors.polluantNorme
      ),
      // todo: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={() => {
          openModal(polluant);
        }}
        handlerSuppress={() => {
          suppressPolluant(polluant);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette substance ?"}
        isValidated={isValidated}
        commentPath={`${PATH_SOL_REJETS}/${polluant.data.id}`}
      />,
    ];
  };

  const arrayPolluantsLines = polluantsInArray.map(singlePolluantInArray => {
    return computeSinglePolluantLine(singlePolluantInArray, classes);
  });

  // Permet de déterminer s'il y a une erreur dans le tableau pour pouvoir le signaler. La méthode n'est peut être pas très limpide
  // à première vue mais facilement généralisable aux autres tableaux
  const isErrorInArray = (polluantsInArray: PolluantInArray[]) => {
    return polluantsInArray.some(singlePolluantInArray => {
      if (singlePolluantInArray.errors) {
        return Object.values(singlePolluantInArray.errors).some(
          value => !!value
        );
      } else {
        return false;
      }
    });
  };

  //todo add a loader and a loadingState if errors is still to undefined
  return (
    <CommonEntityTable
      header={[
        <p className={classes.longCol}>Substance</p>,
        <p>Émission (kg/an)</p>,
        <p className={classes.littleCol}>
          Dont masse accidentelle <br /> (kg/an)
        </p>,
        <p>Méthode</p>,
        <p className={classes.littleCol}>Référence de la méthode</p>,
        <p className={classes.longCol}>Description</p>,
        <p className={classes.longCol}>Normes appliquées</p>,
        <p className={classes.buttonsHeader}>Actions</p>,
      ]}
      preferredColWidths={[
        250,
        120,
        120,
        120,
        120,
        250,
        250,
        ACTION_COLUMN_DEFAULT_WIDTH,
      ]}
      preferredHeaderHeight={75}
      lines={arrayPolluantsLines}
      additionalClassName={
        isErrorInArray(polluantsInArray)
          ? classes.arrayPolluantError
          : undefined
      }
    />
  );
};

export default SolArray;
