import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceItemDepartementDto,
  ReferenceItemFamilleUsageProductionDto,
} from "api/gen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { generateLabelFamily } from "./computers";

export const departments = (
  referentialDepartements: ReferenceDepartementDto
): OptionPropsWithObject<ReferenceItemDepartementDto | null, string>[] => {
  const depOptionProps: OptionPropsWithObject<
    ReferenceItemDepartementDto,
    string
  >[] = referentialDepartements.departements.map((dep, i) => ({
    value: `${dep.numero} - ${dep.nom}`,
    label: `${dep.numero} - ${dep.nom}`,
    object: dep,
  }));

  return [
    {
      value: "0 - France entière",
      label: "0 - France entière",
      object: null,
    },
    ...depOptionProps,
  ];
};

export const franceOrOtherCountry: string[] = ["France", "Hors France"];

export interface ProductType {
  codeValue: string;
}

export const possibleProductTypesForFamilies = (
  families: ReferenceItemFamilleUsageProductionDto[],
  referential: ReferenceFamilleUsageCodeProduitDto
): OptionPropsWithObject<ProductType, string>[] => {
  const productTypes: OptionPropsWithObject<ProductType, string>[] = [];
  families.forEach(family => {
    if (
      family.codeProduit !== null &&
      !productTypes.some(test => test.object.codeValue === family.codeProduit)
    ) {
      productTypes.push({
        label: generateLabelFamily(family.codeProduit, referential),
        value: family.codeProduit,
        object: {
          codeValue: family.codeProduit,
        },
      });
    }

    const children = possibleProductTypesForFamilies(
      family.referenceItemFamilleUsageDtos,
      referential
    );
    children.forEach(child => {
      if (
        productTypes.some(
          test => test.object.codeValue === child.object.codeValue
        )
      ) {
        return;
      }

      productTypes.push(child);
    });
  });

  // we ensure the product types are ordered correctly
  return productTypes.sort((productType1, productType2) =>
    productType1.object.codeValue.localeCompare(productType2.object.codeValue)
  );
};
