import { QuotasEmissionsVerificationDto20Now } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";

export interface BlocVerificationFormValues {
  verifications: BlocVerificationFormValuesProps[];
}

export const fieldBlocMatcher = fieldMatcherBuilder<
  BlocVerificationFormValues
>().build({
  verifications: "verifications",
});

export interface BlocVerificationFormValuesProps
  extends Omit<
    QuotasEmissionsVerificationDto20Now,
    "rapport" | "dateDerniereVisite"
  > {
  dateDerniereVisite: Date | null;
}

export const fieldMatcher = fieldMatcherBuilder<
  BlocVerificationFormValuesProps
>().build({
  organisme: "organisme",
  monitoringPlanReference: "referenceDernierPlanSurveillance",
  visitExemption: "dispenseVisiteSite",
  conditionExemption: "conditionDispense",
  detailExemption: "precisionDispense",
  lastVisitDate: "dateDerniereVisite",
  improvementReport: "rapportAmeliorationRequis",
  verificationReportConclusion: "conclusion",
  satisfyingDeclarationReason: "motifsStatisfaisantAvecRemarque",
  unsatisfyingDeclarationReason: "motifsNonSatisfaisant",
});
