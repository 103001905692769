import React, { createContext, useContext, useEffect, useState } from "react";
import { FetchedReferentialPerYear } from "./types";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageFetchDeclaration } from "common/backErrors/errorMessages";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { ReferenceItemElementIndexDto } from "api/gen";

export interface ReferentialsAndSetter {
  referentials: FetchedReferentialPerYear;
  setter: (
    functionParam: (
      oldReferentials: FetchedReferentialPerYear
    ) => FetchedReferentialPerYear
  ) => void;
}

const ReferentialsContext = createContext<ReferentialsAndSetter | null>(null);

export const useReferentialsAndSetter = (): ReferentialsAndSetter => {
  const context = useContext(ReferentialsContext);
  if (context === null) {
    throw new Error(
      "ReferentialsContext Error. You probably forgot to put a <ReferentialsContextProvider>"
    );
  } else {
    return context;
  }
};

export const ReferentialsContextProvider = ({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement => {
  const [referentials, setReferentials] = useState<FetchedReferentialPerYear>(
    {}
  );

  return (
    <ReferentialsContext.Provider
      value={{
        referentials: referentials,
        setter: setReferentials,
      }}
    >
      {children}
    </ReferentialsContext.Provider>
  );
};

export default ReferentialsContextProvider;

export const useElementAutreUuid = (): string => {
  const onpenAlertModale = useAlertModale();
  const authenticatedApi = useAuthenticatedApi();
  const [elementAutreUuid, setElementAutreUuid] = useState<string>("");

  useEffect(() => {
    const fetchElementAutreUuid = async () => {
      try {
        const elementAutreUuidTmp: ReferenceItemElementIndexDto = await authenticatedApi.referenceAirController.getElementAutreUsingGET();
        setElementAutreUuid(elementAutreUuidTmp.uuid);
      } catch (excp) {
        setElementAutreUuid("");
        await onpenAlertModale(
          backAlertMessage(excp, backMessageFetchDeclaration)
        );
      }
    };

    fetchElementAutreUuid();
  }, [onpenAlertModale, authenticatedApi]);

  return elementAutreUuid;
};
