import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { BlocRejetEauFormValues } from "./types";
import { isAlimentationIndustrielleSelected } from "./selectPossibleValues";

export const validationMilieuAndStation = (values: BlocRejetEauFormValues) => {
  const errors: FormikErrors<BlocRejetEauFormValues> = {};
  if (!!values.milieuRaccorde !== !!values.volumeRaccorde) {
    if (values.volumeRaccorde === null) {
      errors.volumeRaccorde = requiredMessage;
    }
    if (
      !values.milieuRaccorde &&
      !isAlimentationIndustrielleSelected(values.stationRaccorde)
    ) {
      errors.milieuRaccorde = requiredMessage;
    }
  }
  if (!!values.milieuIsole !== !!values.volumeIsole) {
    if (values.volumeIsole === null) {
      errors.volumeIsole = requiredMessage;
    }
    if (!values.milieuIsole) {
      errors.milieuIsole = requiredMessage;
    }
  }
  if (!values.milieuIsole && !values.milieuRaccorde && values.chaleurIsole) {
    errors.milieuIsole = requiredMessage;
  }
  if (!values.milieuRaccorde && !values.milieuIsole && values.chaleurRaccorde) {
    errors.milieuRaccorde = requiredMessage;
  }
  if (
    !values.stationRaccorde &&
    !!values.milieuRaccorde &&
    (values.chaleurRaccorde || values.volumeRaccorde)
  ) {
    errors.stationRaccorde = requiredMessage;
  }
  return errors;
};
