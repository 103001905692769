import React, { ReactElement } from "react";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import Row from "common/presentational/Row";
import MethodesSurveillance from "pages/CompanySpace/from21/toNow/Quotas/common/MethodesSurveillance/MethodesSurveillance";
import { BlocDonneeSurveillanceDto, MethodeSurveillanceDto } from "api/gen";
import ClassementFluxSourceEmission from "./ClassementFluxSourceEmission";

interface PdsSurveillanceEmissionsProps {
  methodeSurveillance: MethodeSurveillanceDto;
  blocDonneeSurveillance: BlocDonneeSurveillanceDto;
}

const PdsBlocMethodeSurveillance = ({
  methodeSurveillance,
  blocDonneeSurveillance,
}: PdsSurveillanceEmissionsProps): ReactElement => {
  return (
    <>
      <SubSectionTitle>SURVEILLANCE DES ÉMISSIONS</SubSectionTitle>
      <Row height={"20px"} />

      <MethodesSurveillance methodeSurveillanceDto={methodeSurveillance} />

      <ClassementFluxSourceEmission
        blocDonneeSurveillance={blocDonneeSurveillance}
        methodeSurveillance={methodeSurveillance}
      />
    </>
  );
};

export default PdsBlocMethodeSurveillance;
