import React from "react";
import {
  DEFAULT_BORDER_RADIUS,
  DEFAULT_BUTTON_SHADOW,
  DEFAULT_INPUT_HEIGHT,
  DISABLED_INPUT_TEXT,
  DISABLED_NUMBER_INPUT_BACKGROUND,
  DISABLED_NUMBER_INPUT_TEXT,
  ERROR,
  INPUT_BORDER_WIDTH,
  PINK,
  SELECT_DEFAULT_WIDTH,
  WARNING,
} from "theme";
import { makeStyles } from "@material-ui/styles";
import Select, {
  IndicatorContainerProps,
  SingleValueProps,
} from "react-select";
import {
  GroupTypeBase,
  OptionTypeBase,
  SelectProps,
  Styles,
} from "./TypesPatternAdapter";
import { MultiValueLabel, SingleValue } from "./tooltipLabelUtils";
import { computeClassname } from "common/form/fields/dumbInput/utils";
import { ErrorProps } from "common/utils/types";

const useStyles = makeStyles({
  container: {
    display: "flex",
    border: `${INPUT_BORDER_WIDTH} solid ${PINK}`,
    borderRadius: DEFAULT_BORDER_RADIUS,
    flexDirection: "row",
    justifyContent: "space-between",
    width: SELECT_DEFAULT_WIDTH,
    "&>div": {
      width: "100%",
      border: 0,
    },
  },
  disabled: {
    borderColor: DISABLED_NUMBER_INPUT_TEXT,
  },
  warning: {
    borderColor: WARNING,
  },
  error: {
    borderColor: ERROR,
  },
  active: {},
});

const DEFAULT_PLACEHOLDER = "Sélectionner...";
const DEFAULT_MESSAGE = "Pas de correspondances...";

interface SelectAdapterProps<T extends OptionTypeBase, IsMulti extends boolean>
  extends SelectProps<T, IsMulti, GroupTypeBase<T>> {
  id: string;
  stylesOverride?: Partial<Styles<T, IsMulti, GroupTypeBase<T>>>;
  tooltipLabel?: boolean;
  error: ErrorProps | null;
  additionalClassName?: string;
}

const SelectAdapter = <T extends OptionTypeBase, IsMulti extends boolean>({
  id,
  placeholder,
  stylesOverride,
  tooltipLabel,
  additionalClassName,
  error,
  isDisabled,
  ...props
}: SelectAdapterProps<T, IsMulti>): React.ReactElement => {
  const classes = useStyles();

  const selectClassName = computeClassname(
    classes,
    error,
    isDisabled,
    additionalClassName
  );

  const defaultStyles = {
    control: (base: any, state: any) => ({
      ...base,
      boxShadow: state.isFocused ? DEFAULT_BUTTON_SHADOW : null,
      backgroundColor: DISABLED_NUMBER_INPUT_BACKGROUND,
      minHeight: DEFAULT_INPUT_HEIGHT,
      borderColor: PINK,
      "&:hover": {
        borderColor: PINK,
      },
    }),
    indicatorsContainer: (
      base: any,
      { isDisabled }: IndicatorContainerProps<T, IsMulti, GroupTypeBase<T>>
    ) => ({
      ...base,
      "&>div": {
        color: isDisabled ? DISABLED_INPUT_TEXT : PINK,
        "&:hover": {
          color: PINK,
        },
      },
      "&>span": {
        display: "none",
      },
    }),
    singleValue: (base: any, { isDisabled }: SingleValueProps<T>) => ({
      ...base,
      color: isDisabled ? DISABLED_INPUT_TEXT : undefined,
    }),
  };

  return (
    <Select
      noOptionsMessage={() => DEFAULT_MESSAGE}
      aria-labelledby={id}
      inputId={id}
      components={
        tooltipLabel
          ? { MultiValueLabel: MultiValueLabel, SingleValue: SingleValue }
          : undefined
      }
      placeholder={
        placeholder === undefined ? DEFAULT_PLACEHOLDER : placeholder
      }
      styles={stylesOverride === undefined ? defaultStyles : stylesOverride}
      className={selectClassName}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

export default SelectAdapter;
