import * as Yup from "yup";
import {
  commonDateFieldsRequired,
  commonObjectFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import { GeneralInformationsForm } from "./types";
import {
  environnementAtLeastOneFieldMessage,
  environnementProductionMoyenneMessage,
} from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  maxProductionPerYear: commonPositiveNumberFieldsNullable,
  averageProductionPerYear: Yup.number().when(
    ["maxProductionPerYear"],
    (maxProductionPerYear: number) => {
      const message = environnementProductionMoyenneMessage;
      return commonPositiveNumberFieldsNullable.max(
        maxProductionPerYear,
        message
      );
    }
  ),
  endAuthorizationDate: commonDateFieldsRequired,
  eau: commonObjectFields,
  souterrain: commonObjectFields,
});

export const additionalFieldValidation = (values: GeneralInformationsForm) => {
  if (!values.averageProductionPerYear && !values.maxProductionPerYear) {
    return {
      averageProductionPerYear: environnementAtLeastOneFieldMessage,
      maxProductionPerYear: environnementAtLeastOneFieldMessage,
    };
  }
  return {};
};
