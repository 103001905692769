import { LegacySubInstallationElement } from "../../../utils/types";
import { InArray, InModale } from "common/form/utils";
import {
  AirCombustionAppareilDtoNatureEnum,
  AirCombustionAppareilDtoTypeFoyerEnum,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
export interface LegacyAppareil extends LegacySubInstallationElement {
  nom: string;
  date: Date;
  modification: boolean;
  lowestThan1500: boolean;
  localisation: string;
  activite: string;
  nature: AirCombustionAppareilDtoNatureEnum;
  natureAutre: string;
  typeFoyer: AirCombustionAppareilDtoTypeFoyerEnum;
  foyerAutre: string;
  capacite: number;
  unite: string;
  hauteurRejets: number;
}

export type AppareilInModale = InModale<LegacyAppareil>;
export type AppareilInArray = InArray<LegacyAppareil>;

export const appareilFieldMatcher = fieldMatcherBuilder<
  AppareilInModale
>().build({
  nom: "nom",
  date: "date",
  modification: "modification",
  lowestThan1500: "lowestThan1500",
  localisation: "localisation",
  activite: "activite",
  nature: "nature",
  natureAutre: "natureAutre",
  typeFoyer: "typeFoyer",
  foyerAutre: "foyerAutre",
  capacite: "capacite",
  unite: "unite",
  hauteurRejets: "hauteurRejets",
});
