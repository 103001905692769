import React from "react";
import { BlocInUserContextProps } from "./types";
import DummyBloc from "./DummyBloc";
import { useUserData } from "Authenticator/UserData";
import { areActionsAllowedInUserContext } from "../utils/methods";
import { useStatusCampaignCurrentWorkflow } from "../../pages/CompanySpace/WorkflowTargetProviderContext";

/**
 * basic bloc that handle whether current user has right to edit the bloc or not
 * it's only purpose it to determine actionsAllowed prop of DummyBloc
 * see DummyBloc for props
 *
 * use case: wrapped in BlocFullContext, should not be used elsewhere
 *
 * @param renderContent: content to render for current user rights
 * @param isAvailableToDeclarant: are declarant allowed to use this bloc (default: true)
 * @param isAvailableToPrestataire: are prestataire allowed to use this bloc (default: false)
 * @param declarationState: current declaration state (may be either global state or quotas state)
 */
const BlocInUserContext = ({
  title,
  validationTitle,
  areButtonsInside,
  additionalStyle,
  additionalClassname,
  hasModification,
  isValidateButtonAvailable,
  validateAction,
  saveAction,
  editAction,
  cancelAction,
  isValidated,
  renderContent,
  declarationState,
  declarationEtablissement,
  isAvailableToDeclarant = true,
  isAvailableToPrestataire = false,
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  validationMessage,
  areGlobalCommentsAllowed,
  commentAction,
  hasComments,
  icon,
}: BlocInUserContextProps): React.ReactElement => {
  const userData = useUserData();
  const currentCampaignStatus = useStatusCampaignCurrentWorkflow();
  const areActionsAllowed = areActionsAllowedInUserContext(
    userData,
    declarationState,
    currentCampaignStatus,
    declarationEtablissement,
    isAvailableToDeclarant,
    isAvailableToPrestataire,
    false,
    false
  );

  return (
    <DummyBloc
      title={title}
      validationTitle={validationTitle}
      areButtonsInside={areButtonsInside}
      additionalStyle={additionalStyle}
      additionalClassname={additionalClassname}
      hasModification={hasModification}
      isValidateButtonAvailable={isValidateButtonAvailable}
      validateAction={validateAction}
      saveAction={saveAction}
      editAction={editAction}
      cancelAction={cancelAction}
      isValidated={isValidated}
      actionsAllowed={areActionsAllowed}
      renderContent={() => {
        return renderContent(!areActionsAllowed);
      }}
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      validationMessage={validationMessage}
      areGlobalCommentsAllowed={areGlobalCommentsAllowed}
      commentAction={commentAction}
      hasComments={hasComments}
      icon={icon}
    />
  );
};

export default BlocInUserContext;
