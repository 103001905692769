import {
  DechetProduitDto1819LieuOperationEnum,
  DechetProduitDto1819MethodEnum,
  DechetProduitDto1819MethodReferenceEnum,
  ReferenceItemDepartementDto,
} from "api/gen";
import { ProductionLocationProps } from "../PolluantProduction/types";
import { ReceptionLocationProps } from "../PolluantReception/types";

export interface OperationDescription {
  label: string;
  code: string;
  description: string;
  isAvailableForProduction: boolean;
  isAvailableForReception: boolean;
}

export interface WasteMethod {
  label: string;
  backOfficeCode: DechetProduitDto1819MethodEnum;
  frontCode: string;
  csvCode: string;
}
export const methods: WasteMethod[] = [
  {
    label: "Mesure",
    backOfficeCode: DechetProduitDto1819MethodEnum.MESURE,
    frontCode: "M",
    csvCode: "M",
  },
  {
    label: "Calcul",
    backOfficeCode: DechetProduitDto1819MethodEnum.CALCUL,
    frontCode: "C",
    csvCode: "C",
  },
  {
    label: "Estimation",
    backOfficeCode: DechetProduitDto1819MethodEnum.ESTIMATION,
    frontCode: "E",
    csvCode: "E",
  },
  {
    label: "Pesage",
    backOfficeCode: DechetProduitDto1819MethodEnum.PESAGE,
    frontCode: "P",
    csvCode: "Pesage",
  },
];
const operationOrValorisationReferential: OperationDescription[] = [
  {
    label:
      "R1 - Utilisation principale comme combustible ou autre moyen de produire de l'énergie",
    code: "R1",
    description:
      "Utilisation principale comme combustible ou autre moyen de produire de l'énergie",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R2 - Récupération ou régénération des solvants",
    code: "R2",
    description: "Récupération ou régénération des solvants",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R3 - Recyclage ou récupération des substances organiques qui ne sont pas utilisées comme solvants (y compris les opérations de compostage et autres transformations biologiques)",
    code: "R3",
    description:
      "Recyclage ou récupération des substances organiques qui ne sont pas utilisées comme solvants (y compris les opérations de compostage et autres transformations biologiques)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R4 - Recyclage ou récupération des métaux et des composés métalliques",
    code: "R4",
    description:
      "Recyclage ou récupération des métaux et des composés métalliques",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R5 - Recyclage ou récupération d'autres matières inorganiques",
    code: "R5",
    description: "Recyclage ou récupération d'autres matières inorganiques",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R6 - Régénération des acides ou des bases",
    code: "R6",
    description: "Régénération des acides ou des bases",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R7 - Récupération des produits servant à capter les polluants",
    code: "R7",
    description: "Récupération des produits servant à capter les polluants",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R8 - Récupération des produits provenant des catalyseurs",
    code: "R8",
    description: "Récupération des produits provenant des catalyseurs",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "R9 - Régénération ou autres réemplois des huiles",
    code: "R9",
    description: "Régénération ou autres réemplois des huiles",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R10 - Epandage sur le sol au profit de l'agriculture ou de l'écologie",
    code: "R10",
    description:
      "Epandage sur le sol au profit de l'agriculture ou de l'écologie",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R11 - Utilisation de déchets résiduels obtenus à partir de l'une des opérations numérotées R 1 à R 10",
    code: "R11",
    description:
      "Utilisation de déchets résiduels obtenus à partir de l'une des opérations numérotées R 1 à R 10",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R12 - Echange de déchets en vue de les soumettre à l'une des opérations numérotées R 1 à R 11",
    code: "R12",
    description:
      "Echange de déchets en vue de les soumettre à l'une des opérations numérotées R 1 à R 11",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "R13 - Stockage de déchets préalablement à l'une des opérations numérotées R 1 à R 12 (à l'exclusion du stockage temporaire, avant collecte, sur le site de production)",
    code: "R13",
    description:
      "Stockage de déchets préalablement à l'une des opérations numérotées R 1 à R 12 (à l'exclusion du stockage temporaire, avant collecte, sur le site de production)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D1 - Dépôt sur ou dans le sol (par exemple, mise en décharge, etc.)",
    code: "D1",
    description:
      "Dépôt sur ou dans le sol (par exemple, mise en décharge, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D2 - Traitement en milieu terrestre (par exemple, biodégradation de déchets liquides ou de boues dans les sols, etc.)",
    code: "D2",
    description:
      "Traitement en milieu terrestre (par exemple, biodégradation de déchets liquides ou de boues dans les sols, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: false,
  },
  {
    label:
      "D3 - Injection en profondeur (par exemple, injection des déchets pompables dans des puits, des dômes de sel ou des failles géologiques naturelles, etc.)",
    code: "D3",
    description:
      "Injection en profondeur (par exemple, injection des déchets pompables dans des puits, des dômes de sel ou des failles géologiques naturelles, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: false,
  },
  {
    label:
      "D4 - Lagunage (par exemple, déversement de déchets liquides ou de boues dans des puits, des étangs ou des bassins, etc.)",
    code: "D4",
    description:
      "Lagunage (par exemple, déversement de déchets liquides ou de boues dans des puits, des étangs ou des bassins, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D5 - Mise en décharge spécialement aménagée (par exemple, placement dans des alvéoles étanches séparées, recouvertes et isolées les unes et les autres et de l'environnement etc.)",
    code: "D5",
    description:
      "Mise en décharge spécialement aménagée (par exemple, placement dans des alvéoles étanches séparées, recouvertes et isolées les unes et les autres et de l'environnement etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "D6 - Rejet dans le milieu aquatique sauf l'immersion",
    code: "D6",
    description: "Rejet dans le milieu aquatique sauf l'immersion",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D8 - Traitement biologique non spécifié ailleurs dans la présente annexe, aboutissant à des composés ou à des mélanges qui sont éliminés selon l'un des procédés numérotés D 1 à D 12",
    code: "D8",
    description:
      "Traitement biologique non spécifié ailleurs dans la présente annexe, aboutissant à des composés ou à des mélanges qui sont éliminés selon l'un des procédés numérotés D 1 à D 12",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D9 - Traitement physico-chimique non spécifié ailleurs dans la présente annexe, aboutissant à des composés ou à des mélanges qui sont éliminés selon l'un des procédés numérotés D 1 à D 12 (par exemple, évaporation, séchage, calcination, etc.)",
    code: "D9",
    description:
      "Traitement physico-chimique non spécifié ailleurs dans la présente annexe, aboutissant à des composés ou à des mélanges qui sont éliminés selon l'un des procédés numérotés D 1 à D 12 (par exemple, évaporation, séchage, calcination, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label: "D10 - Incinération à terre",
    code: "D10",
    description: "Incinération à terre",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D12 - Stockage permanent (par exemple, placement de conteneurs dans une mine, etc.)",
    code: "D12",
    description:
      "Stockage permanent (par exemple, placement de conteneurs dans une mine, etc.)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D13 - Regroupement préalablement à l'une des opérations numérotées D 1 à D 12",
    code: "D13",
    description:
      "Regroupement préalablement à l'une des opérations numérotées D 1 à D 12",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D14 - Reconditionnement préalablement à l'une des opérations numérotées D 1 à D 13",
    code: "D14",
    description:
      "Reconditionnement préalablement à l'une des opérations numérotées D 1 à D 13",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
  {
    label:
      "D15 - Stockage préalablement à l'une des opérations numérotées D 1 à D 14 (à l'exclusion du stockage temporaire, avant collecte, sur le site de production)",
    code: "D15",
    description:
      "Stockage préalablement à l'une des opérations numérotées D 1 à D 14 (à l'exclusion du stockage temporaire, avant collecte, sur le site de production)",
    isAvailableForProduction: true,
    isAvailableForReception: true,
  },
];
export const operationOrValorisationForProduction: OperationDescription[] = operationOrValorisationReferential.filter(
  obj => obj.isAvailableForProduction
);
export const operationOrValoriationForReception: OperationDescription[] = operationOrValorisationReferential.filter(
  obj => obj.isAvailableForReception
);

export const productionLocation: ProductionLocationProps[] = [
  {
    label: "Sur site",
    backCode: DechetProduitDto1819LieuOperationEnum.SITE,
    isDepartmentAvailable: false,
    isCountryAvailable: false,
  },
  {
    label: "France",
    backCode: DechetProduitDto1819LieuOperationEnum.DEPARTEMENT,
    isDepartmentAvailable: true,
    isCountryAvailable: false,
  },
  {
    label: "Étranger",
    backCode: DechetProduitDto1819LieuOperationEnum.PAYS,
    isDepartmentAvailable: false,
    isCountryAvailable: true,
  },
];

export const receptionLocation: ReceptionLocationProps[] = [
  {
    label: "France",
    isDepartmentAccessible: true,
    isCountryAccessible: false,
  },
  {
    label: "Étranger",
    isDepartmentAccessible: false,
    isCountryAccessible: true,
  },
];
const mesureMethodRefs: DechetProduitDto1819MethodReferenceEnum[] = [
  DechetProduitDto1819MethodReferenceEnum.INT,
  DechetProduitDto1819MethodReferenceEnum.PER,
  DechetProduitDto1819MethodReferenceEnum.NRO,
  DechetProduitDto1819MethodReferenceEnum.ALT,
  DechetProduitDto1819MethodReferenceEnum.MRC,
  DechetProduitDto1819MethodReferenceEnum.AUT,
];
const calculMethodRefs: DechetProduitDto1819MethodReferenceEnum[] = [
  DechetProduitDto1819MethodReferenceEnum.INT,
  DechetProduitDto1819MethodReferenceEnum.PER,
  DechetProduitDto1819MethodReferenceEnum.NRO,
  DechetProduitDto1819MethodReferenceEnum.BMA,
  DechetProduitDto1819MethodReferenceEnum.CSS,
  DechetProduitDto1819MethodReferenceEnum.AUT,
];

export const computeRefMethodForMethod = (
  methode: WasteMethod | undefined | null
): DechetProduitDto1819MethodReferenceEnum[] => {
  if (
    methode &&
    methode.backOfficeCode === DechetProduitDto1819MethodEnum.MESURE
  ) {
    return mesureMethodRefs;
  } else if (
    methode &&
    methode.backOfficeCode === DechetProduitDto1819MethodEnum.CALCUL
  ) {
    return calculMethodRefs;
  }
  return [];
};

export const locationOptionPropsIfAvailableInLocations = (
  short: string,
  locations: ReferenceItemDepartementDto[]
): ReferenceItemDepartementDto | undefined => {
  return locations.find(location => location.numero === short);
};
