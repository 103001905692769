import React, { useMemo } from "react";
import { DISABLED_SECTION_STYLE, SHORT_TEXT_INPUT_WIDTH } from "theme";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import {
  useDateFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import mailImg from "common/button/images/mail.svg";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import {
  activiteFieldMatcher,
  ActivityType,
  arrayExternalAdministratifState,
  arrayTrancheEmployeState,
  AUTRE_MATIERE_PRODUITE,
  CoordonneesDepartementMap,
  InspectionServiceDto,
} from "../utils/types";
import { LocationHelper } from "./LocationHelper";
import {
  ActiviteDto23NowEtatAdministratifEnum,
  EquipeGunItemDto,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemEprtrDto,
  ReferenceItemGeoCodeInseeDto,
} from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import {
  getAssociatedSystemCoordinates,
  getRefCoordonneedDepartementDtoFromEnum,
} from "../utils/systemCoordinatesUtils";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import {
  companyActivityEPRTRValidationSchema,
  companyActivityNoEPRTRValidationSchema,
  displayMandatory,
  isDeclarationLinkedToGun,
} from "./validation";
import { computeInspectionServiceLabel } from "./serviceUtils";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";
import {
  arrayExternalAdministratifStateLabel,
  arrayTrancheEmployeLabel,
  getRefApeDtoById,
  getRefCityInseeById,
} from "../utils/converter";
import {
  Declaration24Now,
  useAddDeclarationToGunReimport,
  useDeclaration24Now,
  useUpdateFromInsee,
} from "../../versionedElements/declarationHooks24Now";
import FormikBlocFullContext24Now from "../../versionedElements/FormikBlocFullContext24Now";
import { UnitHelper, UnitHelperElevage } from "./UnitHelper";
import Button from "../../../../../../common/button";
import { useSpinnerState } from "../../../../../../common/button/loadingAndRedirectionHelpers";
import { EtatAdministratifHelper } from "./EtatAdministratifHelper";
import { useUserData } from "../../../../../../Authenticator/UserData";
import { useDeclaredYears } from "pages/CompanySpace/DeclarationApiContext/utils/genericHooks";

const useStyles = makeStyles({
  updateButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  mailIcon: {
    height: "1.5rem",
    width: "1.5rem",
    verticalAlign: "bottom",
  },
  updateButton: {
    width: "30%",
    height: "25px",
    marginBottom: "5px",
    marginLeft: "5px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  additionalInfo: {
    height: "100px",
  },
  textJustify: {
    textAlign: "justify",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "5px",
    justifyContent: "end",
  },
  leftFieldContainer: {
    width: "50%",
  },
  rightFieldContainer: {
    width: "25%",
  },
  largeField: {
    width: "300px",
  },
  ulList: {
    paddingLeft: "1rem",
  },
  ...DISABLED_SECTION_STYLE,
});

interface CompanyActivityProps
  extends CommonConnectedFormikBlocProps<ActivityType, Declaration24Now> {
  referentielEquipesGun: Map<string, EquipeGunItemDto>;
  inspectionServiceList: InspectionServiceDto[];
  referentielINSEE: ReferenceItemGeoCodeInseeDto[];
  referentielAPE: ReferenceItemCodeApeDto[];
  referentielEPRTR: ReferenceItemEprtrDto[];
  coordonneesDepartementMap: CoordonneesDepartementMap;
  coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[];
  eprtrElevageList: ReferenceItemEprtrDto[];
}
export const CompanyActivity = ({
  formikRef,
  hasChanges,
  setHasChanges,
  initialValues,
  path,
  updateHandler,
  referentielAPE,
  referentielINSEE,
  inspectionServiceList,
  referentielEquipesGun,
  referentielEPRTR,
  eprtrElevageList,
  coordonneesDepartementMap,
  coordonneesDepartementList,
}: CompanyActivityProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const updateFromInseeFunction = useUpdateFromInsee();
  const updateFromGunFunction = useAddDeclarationToGunReimport();
  const isAdmin = useUserData().isAdmin;

  const user = useUserData();
  const [
    isUpdateInseeSpinnerVisible,
    triggerInseeValidateSpinner,
  ] = useSpinnerState<void>();
  const [
    isUpdateGunSpinnerVisible,
    triggerGunValidateSpinner,
  ] = useSpinnerState<void>();
  const estEprtr = declaration.body.typeActivite.global.estEPRTR;
  const mandatoryEprtrField = displayMandatory(estEprtr);

  const externalUnite =
    declaration.body.externalSections.infoGenerale.activite.unite;
  const externalMatiereProduite =
    declaration.body.externalSections.infoGenerale.activite.matiereProduite;

  const memoInspectionServiceIds = useMemo(
    () => inspectionServiceList.map(service => service.id),
    [inspectionServiceList]
  );

  const memoUnites = useMemo(
    () => Array.from(new Set(referentielEPRTR.map(({ unite }) => unite))),
    [referentielEPRTR]
  );
  const memoMatieresProduites = useMemo(() => {
    const matiereProduiteList = Array.from(
      new Set(referentielEPRTR.map(({ matiereProduite }) => matiereProduite))
    );
    matiereProduiteList.push(AUTRE_MATIERE_PRODUITE);
    return matiereProduiteList;
  }, [referentielEPRTR]);

  const isLinkedToGun = isDeclarationLinkedToGun(declaration.etablissementCode);
  const gunActivite = isLinkedToGun
    ? declaration.body.externalSections.infoGenerale.activite.gunDto
    : undefined;
  const inseeExternalActivite =
    declaration.body.externalSections.infoGenerale.activite.inseeDto;
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-activity",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);
  const DateField = useDateFieldGenerator(commonProps);

  const locationHelper = useMemo(() => {
    return <LocationHelper />;
  }, []);

  const etatAdministratifHelper = useMemo(() => {
    return <EtatAdministratifHelper />;
  }, []);

  const eprtrUUidList = eprtrElevageList.map(eprtr => eprtr.uuid);

  const shouldDisplayElevageUnitHelper =
    declaration.body.typeActivite.global.activitePrincipaleEPRTRID != null &&
    eprtrUUidList.includes(
      declaration.body.typeActivite.global.activitePrincipaleEPRTRID
    );

  const isFirstDeclaration = useDeclaredYears().length === 1;
  return (
    <FormikBlocFullContext24Now
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={initialValues}
      validationSchema={
        declaration.body.typeActivite.global.estEPRTR
          ? companyActivityEPRTRValidationSchema
          : companyActivityNoEPRTRValidationSchema
      }
      title={"INFORMATIONS RELATIVES A L'ÉTABLISSEMENT"}
      pathToValidate={path}
      areGlobalCommentsAllowed={true}
      enableReinitialize
      updateHandler={updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        const shouldDisabledFieldsFirstDeclaration = isAdmin
          ? shouldDisabledFields
          : shouldDisabledFields || (isFirstDeclaration && isLinkedToGun);
        return (
          <>
            <span className={classes.textJustify}>
              Les données, en lecture seule, dans cette section sont
              pré-remplies à partir de l'application des installations classées
              GUNenv et de l'API Entreprise de l’INSEE. <br />
              <strong>
                Si vous constatez des erreurs manifestes concernant les
                informations administratives de l'établissement :{" "}
              </strong>
              <ul className={classes.ulList}>
                <li>
                  <strong>Vérifiez le code SIRET pré-rempli. </strong> Il est
                  possible qu’il soit obsolète ou erroné. Indiquez le SIRET du
                  site d’exploitation, puis cliquez sur le bouton 'IMPORTER
                  DONNEES SIRENE'. Les informations administratives se mettent à
                  jour. Cliquez sur le bouton 'VALIDER' du pavé Informations
                  relatives à l'établissement{" "}
                </li>
                <li>
                  <strong>
                    Si ces mises à jour restent incorrectes, adressez un message
                    au support par le bouton{" "}
                    <img
                      src={mailImg}
                      className={classes.mailIcon}
                      alt="Enveloppe fermée"
                    />{" "}
                    ci-dessus.
                  </strong>
                </li>
              </ul>
            </span>
            <Row />{" "}
            <div className={classes.updateButtonContainer}>
              {(user.isSuperAdmin || user.isAdmin) && isLinkedToGun && (
                <Button
                  text="IMPORTER DONNÉES GUN"
                  onClick={() =>
                    triggerGunValidateSpinner(
                      updateFromGunFunction(declaration)
                    )
                  }
                  additionalClassname={classes.updateButton}
                  isDisabled={shouldDisabledFields}
                  isSpinnerVisible={isUpdateGunSpinnerVisible}
                />
              )}{" "}
              <Button
                text="IMPORTER DONNÉES SIRENE"
                onClick={() =>
                  triggerInseeValidateSpinner(
                    updateFromInseeFunction(
                      declaration,
                      formProps.values.siret
                    ).then((updated: Declaration24Now) => {
                      // this the only easy way to be sure that this field is correctly updated after insee update.
                      // if another case occur, we should find a proper implementation.
                      formikRef.current?.setFieldValue(
                        "trancheEmploye",
                        updated.body.sections.infoGenerale.activite
                          .trancheEmploye
                      );
                    })
                  )
                }
                additionalClassname={classes.updateButton}
                isDisabled={shouldDisabledFields}
                isSpinnerVisible={isUpdateInseeSpinnerVisible}
              />
            </div>
            <TextField
              name={activiteFieldMatcher.SIRETNumber}
              label="Numéro SIRET *"
              disabled={shouldDisabledFields}
            />
            <DummyTextField
              name={activiteFieldMatcher.inspectionCode}
              label="Code inspection *"
              value={formProps.values.codeInspection}
              disabled={true}
            />
            <DummyChoiceSelectWithLabel<string, false>
              name={activiteFieldMatcher.inspectionService}
              label={"Service d’inspection *"}
              isMulti={false}
              options={memoInspectionServiceIds}
              value={
                gunActivite?.identifiantService ||
                formProps.values.identifiantService
              }
              computeLabel={serviceId =>
                computeInspectionServiceLabel(serviceId, inspectionServiceList)
              }
              disabled
              commonProps={commonProps}
            />
            {gunActivite?.identifiantEquipe && (
              <DummyTextField
                name={"equipeInspection"}
                label={"Identifiant équipe"}
                value={
                  referentielEquipesGun.get(gunActivite?.identifiantEquipe)
                    ?.nomEquipe || gunActivite.identifiantEquipe
                }
                disabled={true}
              />
            )}
            <TextField
              name={activiteFieldMatcher.establishmentName}
              label="Nom de l'établissement *"
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite.nomEtablissement ?? undefined
              }
            />
            <TextField
              name={activiteFieldMatcher.numeroVoie}
              label="Numéro de voie"
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite.adresse.numeroVoie ?? undefined
              }
            />
            <TextField
              name={activiteFieldMatcher.typeVoie}
              label="Type de voie"
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite.adresse.typeVoie ?? undefined
              }
            />
            <TextField
              name={activiteFieldMatcher.libelleVoie}
              label="Libellé de voie *"
              disabled={
                isAdmin
                  ? shouldDisabledFields
                  : shouldDisabledFields ||
                    (isFirstDeclaration &&
                      isLinkedToGun &&
                      (inseeExternalActivite.nomEtablissement === null || // Modificaiton permise seulement si n'est pas présent dans l'import SIRENE
                        inseeExternalActivite.adresse.libelleVoie !== null))
              }
              externalValue={
                inseeExternalActivite.adresse.libelleVoie ?? undefined
              }
            />
            <TextField
              name={activiteFieldMatcher.adresseComplement}
              label="Complément d'adresse"
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite.adresse.adresseComplement ?? undefined
              }
            />
            <WrappedChoiceSelectModale
              name={activiteFieldMatcher.informationCommune}
              label={"Commune *"}
              disabled={shouldDisabledFieldsFirstDeclaration}
              title="Commune"
              header={["Code INSEE", "Code postal", "Commune", "Département"]}
              linesData={referentielINSEE}
              formatLine={insee => [
                insee?.codeInsee || "",
                insee?.codePostal || "",
                insee?.nomCommune || "",
                insee?.numeroDepartement.toString() || "",
              ]}
              formatSelectedTitle={lineData => lineData?.nomCommune || ""}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData?.codeInsee,
                    lineData?.codePostal,
                    lineData?.nomCommune,
                    lineData?.numeroDepartement,
                  ],
                  searchedStr
                )
              }
              preferredColWidths={[100, 100, 250, 80]}
              commonProps={commonProps}
              onSelect={data => {
                formProps.setFieldValue(
                  activiteFieldMatcher.codePostal,
                  data ? data.codePostal : data
                );
              }}
              externalValue={
                inseeExternalActivite.adresse.uuidCommune
                  ? getRefCityInseeById(
                      referentielINSEE,
                      inseeExternalActivite?.adresse.uuidCommune || ""
                    )
                  : undefined
              }
            />
            <TextField
              name={activiteFieldMatcher.codePostal}
              label="Code postal *"
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite.adresse.codePostal ?? undefined
              }
            />
            <WrappedChoiceSelectModale
              name={activiteFieldMatcher.NAFCode}
              label={"Code NAF *"}
              disabled={shouldDisabledFieldsFirstDeclaration}
              title="Code NAF"
              header={["Code NAF", "Libellé"]}
              linesData={referentielAPE}
              formatLine={ape => [ape?.codeApe || "", ape?.libelle || ""]}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData?.codeApe, lineData?.libelle],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData?.codeApe || ""}
              preferredColWidths={[100, 300]}
              commonProps={commonProps}
              externalValue={
                inseeExternalActivite.codeNafID
                  ? getRefApeDtoById(
                      referentielAPE,
                      inseeExternalActivite.codeNafID || ""
                    )
                  : undefined
              }
            />
            <DummyTextField
              name={"principalActivity"}
              label="Activité principale *"
              value={
                inseeExternalActivite.activitePrincipale
                  ? inseeExternalActivite.activitePrincipale
                  : getRefApeDtoById(
                      referentielAPE,
                      formProps.values.codeNaf?.uuid || ""
                    )?.libelle
              }
              disabled={true}
            />
            <WrappedChoiceSelect
              name={activiteFieldMatcher.administratifState}
              label={"État administratif *"}
              isMulti={false}
              options={arrayExternalAdministratifState}
              disabled={shouldDisabledFieldsFirstDeclaration}
              externalValue={
                inseeExternalActivite?.etatAdministratif ?? undefined
              }
              computeLabel={state =>
                arrayExternalAdministratifStateLabel[state]
              }
              commonProps={commonProps}
              tooltipContent={etatAdministratifHelper}
            />
            {formProps.values.etatAdministratif ===
              ActiviteDto23NowEtatAdministratifEnum.CLOSED && (
              <DateField
                name={activiteFieldMatcher.closingDate}
                label="Date de fermeture"
                disabled={shouldDisabledFields}
                externalValue={
                  inseeExternalActivite.dateFermeture
                    ? inseeExternalActivite.dateFermeture
                    : undefined
                }
              />
            )}
            <Row />
            <WrappedChoiceSelect
              name={activiteFieldMatcher.systemCoordinates}
              label="Système de coordonnées géographiques *"
              isMulti={false}
              options={getAssociatedSystemCoordinates(
                formProps.values,
                coordonneesDepartementMap
              )}
              disabled={shouldDisabledFields}
              computeLabel={coordonnees =>
                coordonnees.systemeCoordonnees.toString()
              }
              commonProps={commonProps}
              externalValue={
                gunActivite === undefined
                  ? undefined
                  : getRefCoordonneedDepartementDtoFromEnum(
                      gunActivite.systemeCoordonnees,
                      coordonneesDepartementList
                    )
              }
            />
            <TextField
              name={activiteFieldMatcher.longitude}
              label="Abscisse/Longitude/X *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              additionalOnChange={newValue => {
                const formattedValue =
                  newValue && newValue.replaceAll(".", ",");
                formProps.setFieldValue(
                  activiteFieldMatcher.longitude,
                  formattedValue
                );
              }}
              tooltipContent={locationHelper}
              externalValue={gunActivite?.abscisse}
            />
            <TextField
              name={activiteFieldMatcher.latitude}
              label="Ordonnée/Latitude/Y *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              additionalOnChange={newValue => {
                const formattedValue =
                  newValue && newValue.replaceAll(".", ",");
                formProps.setFieldValue(
                  activiteFieldMatcher.latitude,
                  formattedValue
                );
              }}
              tooltipContent={locationHelper}
              externalValue={gunActivite?.ordonnee}
            />
            <Row />
            <NumberField
              name={activiteFieldMatcher.productionVolume}
              label={`Volume de production${mandatoryEprtrField}`}
              unit=""
              disabled={shouldDisabledFields}
            />
            <div className={classes.row}>
              <div className={classes.leftFieldContainer}>
                <WrappedChoiceSelect
                  name={activiteFieldMatcher.unit}
                  label={`Unité${mandatoryEprtrField}`}
                  isMulti={false}
                  options={memoUnites}
                  disabled={shouldDisabledFields}
                  computeLabel={unite => unite}
                  commonProps={commonProps}
                  tooltipContent={
                    shouldDisplayElevageUnitHelper ? (
                      <UnitHelperElevage />
                    ) : (
                      <UnitHelper />
                    )
                  }
                  externalValue={estEprtr ? externalUnite : undefined}
                />
              </div>
              <div className={classes.rightFieldContainer}>
                <WrappedChoiceSelect
                  name={activiteFieldMatcher.producedMatter}
                  isMulti={false}
                  options={memoMatieresProduites}
                  disabled={shouldDisabledFields}
                  label={" de "}
                  computeLabel={matiereProduite => matiereProduite}
                  commonProps={{ ...commonProps, labelWidth: "30px" }}
                  externalValue={estEprtr ? externalMatiereProduite : undefined}
                  tooltipLabel
                />
              </div>
            </div>
            {!estEprtr &&
              formProps.values.matiereProduite === AUTRE_MATIERE_PRODUITE && (
                <TextField
                  name={activiteFieldMatcher.otherProducedMatter}
                  label="Préciser autre"
                  disabled={shouldDisabledFields}
                />
              )}
            <NumberField
              name={activiteFieldMatcher.workDuration}
              label="Nombre d'heures d'exploitation au cours de l'année"
              unit="heures"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelect
              name={activiteFieldMatcher.trancheEmploye}
              label={`Nombre d'employés`}
              isMulti={false}
              options={arrayTrancheEmployeState}
              disabled={shouldDisabledFields}
              computeLabel={state => arrayTrancheEmployeLabel[state]}
              commonProps={commonProps}
            />
            <TextField
              name={activiteFieldMatcher.website}
              label="Adresse du site internet"
              disabled={shouldDisabledFields}
              tooltipContent={""}
            />
            <TextArea
              name={activiteFieldMatcher.additionalInfo}
              label="Informations complémentaires / remarques"
              disabled={shouldDisabledFields}
              additionalClassName={classes.additionalInfo}
            />
          </>
        );
      }}
    />
  );
};
