import React from "react";
import { makeStyles } from "@material-ui/styles";
import { InertWasteForm } from "./utils/types";
import { DISABLED_NUMBER_INPUT_TEXT, LEFT_WITHDRAW_STYLE } from "theme";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import {
  computeTotal,
  computeTotalToBackfill,
  computeTotalToRecycle,
} from "./utils/computers";
import { convertDeclarationDtoUsingInertWasteForm } from "../utils/converters";
import { convertDtoToInertWasteForm } from "./utils/converters";
import { preventSubmitWithErrors, validationSchema } from "./utils/validation";
import {
  PATH_CARRIERE_ENVIRONNEMENT_CAPACITE_RESTANTE_TERME_ANNEE_REF,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_APPORTS,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_MATERIAUX,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_TERRECAILLOUX,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_MATERIAUX,
  PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_TERRECAILLOUX,
} from "common/path/path18Now";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";

interface BlocDechetsInertesProps {
  inertWasteFormProps: FormikBlocRefAndState<InertWasteForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  inputFieldWithLabel: {
    marginTop: "20px",
    marginBottom: "5px",
    display: "flex",
  },
  flexCenter: {
    justifyContent: "space-around",
  },
  table: {
    marginTop: "50px",
    borderSpacing: "20px",
  },
  tableHeader: {
    color: DISABLED_NUMBER_INPUT_TEXT,
    fontWeight: "bold",
    fontSize: "90%",
    margin: "10px",
    width: "500px",
  },
  tableBody: {
    textAlign: "center",
  },
  tableFirstColumn: {
    width: "25%",
  },
  containerSpace: {
    padding: "15px 0",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const BlocDechetsInertes = ({
  inertWasteFormProps,
}: BlocDechetsInertesProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "0",
    formPrefix: "environnement-bloc-dechets-inertes",
  };

  const pageLabelWidthInPercent = 50;
  const CheckBox = useBooleanCheckBoxGenerator({
    ...commonProps,
    labelWidth: `${pageLabelWidthInPercent}%`,
  });
  const NumberField = useNumberFieldGenerator(commonProps);
  const NumberFieldWithLabel = useNumberFieldGenerator({
    ...commonProps,
    className: classes.inputFieldWithLabel,
    labelWidth: `${pageLabelWidthInPercent}%`,
  });
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const initialValues = convertDtoToInertWasteForm(declaration);

  return (
    <FormikBlocFullContext1919
      hasChanges={inertWasteFormProps.hasChanges}
      setHasChanges={inertWasteFormProps.setHasChanges}
      formikRef={inertWasteFormProps.formikRef}
      title="DÉCHETS INERTES (TE2)"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES}
      updateHandler={convertDeclarationDtoUsingInertWasteForm}
      preventSubmitWithErrors={preventSubmitWithErrors}
      renderContent={({ values }, shouldDisabledFields: boolean) => (
        <>
          <div className={classes.containerSpace}>
            Les quantités de matériaux entrants destinés à être recyclés devront
            être détaillées dans le tableau TP3.
          </div>
          <CheckBox
            label="Apport de déchets inertes"
            name="inertWasteBring"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_APPORTS}
          />

          {values.inertWasteBring && (
            <>
              <div className={classes.withdrawLeft}>
                <NumberFieldWithLabel
                  label="Pour les carrières valorisant des déchets inertes par remblaiement, capacité restante au terme de l'année de référence *"
                  name="remainingCapacityEndRefYear"
                  unit="m³"
                  disabled={shouldDisabledFields}
                  commentPath={
                    PATH_CARRIERE_ENVIRONNEMENT_CAPACITE_RESTANTE_TERME_ANNEE_REF
                  }
                />
              </div>

              <Row height="100%" additionalClassname={classes.flexCenter}>
                <table className={classes.table}>
                  <thead>
                    <tr>
                      <th />
                      <th className={classes.tableHeader}>
                        Quantités de matériaux entrants destinés à être
                        remblayés sur le site
                      </th>
                      <th className={classes.tableHeader}>
                        Quantités de matériaux entrants destinés à être recyclés
                      </th>
                    </tr>
                  </thead>
                  <tbody className={classes.tableBody}>
                    <tr>
                      <td className={classes.tableFirstColumn}>
                        Terres et cailloux
                      </td>
                      <td>
                        <NumberField
                          label=""
                          name="groundAndStoneToBackfill"
                          unit="ktonnes"
                          disabled={shouldDisabledFields}
                          commentPath={
                            PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_TERRECAILLOUX
                          }
                        />
                      </td>
                      <td>
                        <NumberField
                          label=""
                          name="groundAndStoneToRecycle"
                          unit="ktonnes"
                          disabled={shouldDisabledFields}
                          commentPath={
                            PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_TERRECAILLOUX
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.tableFirstColumn}>
                        Autres matériaux issus du BTP (déconstruction, ...)
                      </td>
                      <td>
                        <NumberField
                          label=""
                          name="otherStuffToBackfill"
                          unit="ktonnes"
                          disabled={shouldDisabledFields}
                          commentPath={
                            PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_REMBLAYER_MATERIAUX
                          }
                        />
                      </td>
                      <td>
                        <NumberField
                          label=""
                          name="otherStuffToRecycle"
                          unit="ktonnes"
                          disabled={shouldDisabledFields}
                          commentPath={
                            PATH_CARRIERE_ENVIRONNEMENT_DECHETSINERTES_TABLEAU_RECYCLER_MATERIAUX
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td>
                        <DummyNumberField
                          label=""
                          name="totalStuffToBackfill"
                          unit="ktonnes"
                          disabled
                          value={computeTotalToBackfill(values)}
                        />
                      </td>
                      <td>
                        <DummyNumberField
                          label=""
                          name="totalStuffToRecycle"
                          unit="ktonnes"
                          disabled
                          value={computeTotalToRecycle(values)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Total</td>
                      <td colSpan={2}>
                        <DummyNumberField
                          label=""
                          name="totalStuff"
                          unit="ktonnes"
                          disabled
                          value={computeTotal(values)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Row>
            </>
          )}
        </>
      )}
    />
  );
};

export default BlocDechetsInertes;
