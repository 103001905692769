import React from "react";
import mailImg from "../../../../../../common/button/images/mail.svg";
import { ICON_STYLE } from "../../../../../../theme";

interface InseeIllegalErrorProps {
  siret: string;
}

const InseeIllegalError = ({
  siret,
}: InseeIllegalErrorProps): React.ReactElement => {
  return (
    <div>
      L’accès aux données de l'établissement immatriculé {siret} n’est pas
      autorisé. Les données ne sont pas diffusées publiquement par l’INSEE et
      GEREP ne dispose pas des droits nécessaires. <br />
      <br />
      Nous vous invitons à enregistrer la page avec le SIRET correct de
      l’établissement, qui vous permettra de disposer des données Trackdéchets
      pré-remplies. <br />
      <br />
      Pour corriger les autres informations administratives, veuillez contacter
      le support, en cliquant sur{" "}
      <img src={mailImg} style={ICON_STYLE} alt="Enveloppe fermée" />.
    </div>
  );
};

export default InseeIllegalError;
