import React from "react";
import {
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDtoUniteUsuelleEnum,
  ReferencePolluantDto,
} from "api/gen";
import { SyntheseInArray, SyntheseInModale } from "./types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

export const autreString = "autre";
export const biomasseString = " biomasse";
export const nonBiomasseString = ` non${biomasseString}`;

const createNormDiv = (
  norms: OptionPropsWithObject<ReferenceItemNormeAirDto>[]
) => {
  const normeString = norms.map(norm => norm.label).join(", ");
  return <div title={normeString}>{normeString}</div>;
};

export const createNorm = (
  synthese: SyntheseInArray
): React.ReactElement | "" => {
  if (synthese.data.methodReferenceM) {
    if (synthese.data.methodReferenceM.value === 1 && synthese.data.normMINT) {
      return createNormDiv(synthese.data.normMINT);
    } else if (
      synthese.data.methodReferenceM.value === 2 &&
      synthese.data.normMPER
    ) {
      return createNormDiv(synthese.data.normMPER);
    } else if (
      synthese.data.methodReferenceM.value === 3 &&
      synthese.data.normMNRO
    ) {
      return createNormDiv(synthese.data.normMNRO);
    }
  } else if (
    synthese.data.methodReferenceC &&
    synthese.data.methodReferenceC.value === 1 &&
    synthese.data.normCINT
  ) {
    return createNormDiv(synthese.data.normCINT);
  }
  return "";
};

export const findMethodReference = (synthese: SyntheseInArray) => {
  if (synthese.data.method) {
    if (synthese.data.method.value === 1 && synthese.data.methodReferenceM) {
      return synthese.data.methodReferenceM.label;
    } else if (
      synthese.data.method.value === 2 &&
      synthese.data.methodReferenceC
    ) {
      return synthese.data.methodReferenceC.label;
    } else {
      return "";
    }
  }
};

export const getConvertionFactor = (unit: string) => {
  switch (unit) {
    case "g/an":
      return 1000;
    case "kg/an":
      return 1;
    case "t/an":
      return 0.001;
    case "kt/an":
      return 0.000001;
    case "mg/an":
      return 1000000;
    default:
      return 1;
  }
};

export const getUsualUnit = (
  airReference: ReferencePolluantDto,
  substanceName: string
) => {
  const substance = airReference.polluants.find(substance => {
    return substance.nom === substanceName;
  });
  if (substance) {
    switch (substance.uniteUsuelle) {
      case ReferenceItemPolluantDtoUniteUsuelleEnum.G_AN:
        return "g/an";
      case ReferenceItemPolluantDtoUniteUsuelleEnum.KG_AN:
        return "kg/an";
      case ReferenceItemPolluantDtoUniteUsuelleEnum.T_AN:
        return "t/an";
      case ReferenceItemPolluantDtoUniteUsuelleEnum.KT_AN:
        return "kt/an";
      case ReferenceItemPolluantDtoUniteUsuelleEnum.MG_AN:
        return "mg/an";
      case ReferenceItemPolluantDtoUniteUsuelleEnum.KG_J:
        return "kg/j";
      default:
        throw new ShouldNotHappen(substance.uniteUsuelle);
    }
  }
  return "";
};

export const computeTotalEmissions = (synthese: SyntheseInModale): number => {
  return (
    (synthese.additionalAccidentalEmissions || 0) +
    (synthese.emissionsAlreadyDeclared || 0)
  );
};

export const computeTotalEmissionsUsualUnit = (synthese: SyntheseInModale) => {
  //we recompute the totales emission because in a form, the totalesEmission may have changed.
  const totalesEmissions = computeTotalEmissions(synthese);

  if (synthese.factor === null) {
    return null;
  }
  return synthese.factor * totalesEmissions;
};

export const createFullSynthese = (
  tmp: SyntheseInArray[],
  airReference: ReferencePolluantDto
): SyntheseInArray[] => {
  return tmp.map(content => {
    //the compute are made in this order because they're dependent of the precedent one.

    content.data.uniteUsuelle =
      content.data.substance !== null
        ? getUsualUnit(airReference, content.data.substance.nom)
        : null;

    content.data.factor =
      content.data.uniteUsuelle !== null
        ? getConvertionFactor(content.data.uniteUsuelle)
        : null;

    //TODO: compute errors
    return {
      data: {
        ...content.data,
        totalEmissionsUsualUnit: computeTotalEmissionsUsualUnit(content.data),
      },
      errors: {},
    };
  });
};
