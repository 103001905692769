import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../utils/utils";
import cloneDeep from "lodash.clonedeep";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { AppareilInArray } from "./utils/types";
import { CombustibleInArray } from "../BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  LegacyEmissions,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../BlocEmissions/utils/types";
import _ from "lodash";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";
import { InArray } from "common/form/utils";
import {
  AirCombustionAppareilDtoNatureEnum,
  AirCombustionAppareilDtoTypeFoyerEnum,
} from "../../../../../../../../api/gen";
import {
  arrayNatureLabel,
  arrayTypeFoyerLabel,
} from "./utils/selectPossibleValues";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface AppareilArrayProps {
  appareilsInArray: AppareilInArray[];
  setAppareilsInArray: Dispatch<SetStateAction<AppareilInArray[]>>;
  setAppareilInModale: Dispatch<SetStateAction<AppareilInArray | null>>;
  setAppareilModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName: string | null;
  validationAppareilsPath: string;
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: Dispatch<SetStateAction<CombustibleInArray[]>>;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
}

const AppareilArray = ({
  appareilsInArray,
  setAppareilsInArray,
  setAppareilInModale,
  setAppareilModaleOpen,
  isValidated,
  installationName,
  validationAppareilsPath,
  combustiblesInPage,
  setCombustiblesInPage,
  facteursInPage,
  setFacteursInPage,
  matieresInPage,
  setMatieresInPage,
  mesuresInPage,
  setMesuresInPage,
}: AppareilArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressAppareil(singleAppareilInArray: AppareilInArray) {
    const newAppareils = appareilsInArray.filter(appareil => {
      return singleAppareilInArray.data.id !== appareil.data.id;
    });
    setAppareilsInArray(newAppareils);

    const appareilId = singleAppareilInArray.data.id;
    //Delete combustible linked only to the deleted appareil and update appareils list
    const copyCombustible = cloneDeep(combustiblesInPage);
    const newCombustibles: CombustibleInArray[] = [];
    copyCombustible.forEach(combustible => {
      if (combustible.data.appareils) {
        const hasFoundAppareil = combustible.data.appareils.some(appareil => {
          return appareil.data.id === appareilId;
        });
        if (!hasFoundAppareil) {
          newCombustibles.push(combustible);
        } else {
          combustible.data.appareils = combustible.data.appareils.filter(
            appareil => {
              return appareilId !== appareil.data.id;
            }
          );
          if (combustible.data.appareils.length > 0) {
            newCombustibles.push(combustible);
          }
        }
      }
    });
    setCombustiblesInPage(newCombustibles);

    //Delete emissions linked only to the deleted appareil and update appareils list
    const deleteAndUpdateEmission = <T extends LegacyEmissions>(
      emissions: InArray<T>[],
      setEmissions: React.Dispatch<React.SetStateAction<InArray<T>[]>>
    ) => {
      const copyEmissions = cloneDeep(emissions);
      const newEmissions: InArray<T>[] = [];
      copyEmissions.forEach(emission => {
        if (emission.data.appareils) {
          const hasFoundAppareil = emission.data.appareils.some(appareil => {
            return appareil.data.id === appareilId;
          });
          if (!hasFoundAppareil) {
            newEmissions.push(emission);
          } else {
            emission.data.appareils = emission.data.appareils.filter(
              appareil => {
                return appareilId !== appareil.data.id;
              }
            );
            if (emission.data.appareils.length > 0) {
              newEmissions.push(emission);
            }
          }
        }
      });
      setEmissions(newEmissions);
    };
    deleteAndUpdateEmission(facteursInPage, setFacteursInPage);
    deleteAndUpdateEmission(mesuresInPage, setMesuresInPage);
    deleteAndUpdateEmission(matieresInPage, setMatieresInPage);
  }

  const computeSingleAppareilLine = (
    appareil: AppareilInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setAppareilInModale(appareil);
      setAppareilModaleOpen(true);
    };

    const appareilNature =
      appareil.data.nature &&
      appareil.data.nature !== AirCombustionAppareilDtoNatureEnum.AUTRE
        ? arrayNatureLabel[appareil.data.nature]
        : appareil.data.natureAutre;

    const appareilTypeFoyer =
      appareil.data.typeFoyer &&
      appareil.data.typeFoyer !== AirCombustionAppareilDtoTypeFoyerEnum.AUTRE
        ? arrayTypeFoyerLabel[appareil.data.typeFoyer]
        : appareil.data.foyerAutre;

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      appareil.data.nom || undefined,
      isValidated,
      !_.isEmpty(appareil.errors)
    );
    //TODO add errors here
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p title={appareil.data.nameInstallation || undefined}>
          {appareil.data.nameInstallation}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        appareil.data.date && appareil.data.date.toLocaleDateString(),
        classes
      ),
      computeWrappedCellWithError(
        appareil.data.modification === null
          ? ""
          : !appareil.data.modification
          ? "Non"
          : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        appareil.data.lowestThan1500 === null
          ? ""
          : !appareil.data.lowestThan1500
          ? "Non"
          : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={appareil.data.localisation || undefined}>
          {appareil.data.localisation}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={appareil.data.activite || undefined}>
          {appareil.data.activite}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={appareilNature || undefined}>{appareilNature}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={appareilTypeFoyer || undefined}>{appareilTypeFoyer}</p>,
        classes
      ),
      computeWrappedCellWithError(
        appareil.data.capacite !== null
          ? computeNumberWithSeparator(appareil.data.capacite)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={appareil.data.unite || undefined}>{appareil.data.unite}</p>,
        classes
      ),
      computeWrappedCellWithError(
        appareil.data.hauteurRejets !== null
          ? computeNumberWithSeparator(appareil.data.hauteurRejets)
          : "",
        classes
      ),
      <CommonEntityButton
        suppressMessage={"Êtes vous sûr de vouloir supprimer cet appareil ?"}
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressAppareil(appareil);
        }}
        isValidated={isValidated}
        commentPath={`${validationAppareilsPath}/${appareil.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = filterByInstallation(
    appareilsInArray,
    installationName || ""
  ).map(singleAppareilInArray => {
    return computeSingleAppareilLine(singleAppareilInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Nom</p>,
        <p>Nom de l'installation</p>,
        <p>Date de mise en service de l'appareil</p>,
        <p>Modification substantielle de l'appareil dans l'année</p>,
        <p>
          Appareil fonctionnant moins de 1500 h/an en moyenne mobile sur une
          période de 5 ans
        </p>,
        <p>Localisation sur le site</p>,
        <p>Activité(s) développée(s)</p>,
        <p>Nature de l'appareil</p>,
        <p>
          Type de foyer pour les chaudières utilisant un combustible solide
        </p>,
        <p>Capacité autorisée</p>,
        <p>Unité</p>,
        <p>Hauteur des rejets (en m)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        200,
        170,
        130,
        230,
        300,
        200,
        200,
        130,
        230,
        130,
        130,
        150,
        90,
      ]}
    />
  );
};

export default AppareilArray;
