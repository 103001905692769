import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { FILE_BUTTONS_STYLE, SECTION_TITLE_GREY } from "theme";
import { PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC } from "common/path/path20Now";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import Row from "common/presentational/Row";
import NimFilesLoaderDisplayer from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/NimFilesLoaderDisplayer";
import PdsLoader from "./PdsLoader";
import JustificatifPdsLoader from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocPlanSurveillance/JustificatifPdsLoader";
import PlanSurveillanceData from "./PlanSurveillanceData";
import BlocFullContext24Now from "../../../versionedElements/BlocFullContext24Now";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";

const useStyles = makeStyles({
  text: {
    color: SECTION_TITLE_GREY,
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  ...FILE_BUTTONS_STYLE,
});

const PlanSurveillanceForm = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const installations = declaration.body.typeActivite.quotas.installations;

  return (
    <BlocFullContext24Now
      hasModification={false}
      isValidateButtonAvailable={true}
      title={"PLAN DE SURVEILLANCE DES ÉMISSIONS"}
      path={PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC}
      updateHandler={declaration => declaration}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          <div className={classes.text}>
            <p>À faire dans ce bloc :</p>
            <ul>
              <li>
                Déposer le(s) plan(s) de surveillance en vigueur pour l'année de
                déclaration (1 par NIM),
              </li>
            </ul>
          </div>
          <Row height={"20px"} />

          <GreyFilesDownloader
            text="TÉLÉCHARGER LE MODÈLE PLAN DE SURVEILLANCE (version du 06/04/2021)"
            isDisabled={shouldDisabledFields}
            fileName="Plan_Surveillance_emissions_210406.xls"
            additionalClassname={classes.fileButtons}
          />

          {installations.map(installation => (
            <>
              <NimFilesLoaderDisplayer
                installation={installation}
                justificatifLoader
              >
                <PdsLoader
                  installationId={installation.id}
                  shouldDisableFields={shouldDisabledFields}
                />
                <JustificatifPdsLoader
                  installationId={installation.id}
                  shouldDisableFields={shouldDisabledFields}
                />
              </NimFilesLoaderDisplayer>
              <Row height={"20px"} />

              <PlanSurveillanceData
                installation={installation}
                planSurveillanceList={
                  declaration.body.externalSections.quotas.emissions
                    .blocPlanSurveillance.planSurveillanceList
                }
              />
            </>
          ))}
        </>
      )}
    />
  );
};

export default PlanSurveillanceForm;
