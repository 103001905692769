import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";

import {
  AccidentCauseType,
  AccidentInModale,
  deadlyLabel,
  noLabel,
  yesLabel,
} from "./utils";
import {
  singleAccidentHasPrecisionSubPartActivated,
  singleAccidentOverwriteDependantFields,
  singleAccidentValidationSchema,
} from "./AccidentModale/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  useChoiceRadioFieldGenerator,
  useChoiceSelectFieldGenerator,
  useDateFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { futureDateMessage } from "common/declarant/formik/formikMessages";

interface FormSingleAccidentProps {
  closeFunction: () => void;
  onSubmit: (
    values: AccidentInModale,
    formikActions: FormikActions<AccidentInModale>
  ) => void;

  initialAccident: AccidentInModale;
  isEdit: boolean;
  mainAccidentCauseList: AccidentCauseType[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const FormSingleAccident = ({
  closeFunction,
  onSubmit,
  initialAccident,
  isEdit,
  mainAccidentCauseList,
}: FormSingleAccidentProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "30%",
    formPrefix: "bloc-carrière-accident-individuel",
  };

  const DateField = useDateFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UN ACCIDENT"
      closeFunction={closeFunction}
      onSubmit={(values: AccidentInModale, formikBag) => {
        singleAccidentOverwriteDependantFields(values);
        onSubmit(values, formikBag);
      }}
      initialEntity={initialAccident}
      isEdit={isEdit}
      validationSchema={singleAccidentValidationSchema}
      validate={values => {
        if (values.date && values.date > new Date()) {
          return { date: futureDateMessage };
        }
      }}
      renderField={({ values }) => {
        return (
          <>
            <DateField name="date" label="Date *" />
            <ChoiceSelectField
              name="accidentCause"
              label="Cause principale de l'accident"
              isMulti={false}
              options={mainAccidentCauseList}
            />

            {singleAccidentHasPrecisionSubPartActivated(
              values.accidentCause
            ) && <TextField name="accidentCausePrecision" label="Préciser" />}

            <ChoiceRadioField
              name="isConsolidated"
              label="Arrêt de travail consolidé"
              choices={[yesLabel, noLabel, deadlyLabel]}
              tooltipContent={
                <>
                  Arrêt de travail consolidé : Arrêt de travail terminé validé
                  par la médecine du travail. Cette case est présente sur le
                  CERFA 11138*01
                  <br />
                  <br />
                  Accident mortel : Accident dont la victime est tuée sur le
                  coup ou dans les trois jours suivants
                </>
              }
            />

            {(values.isConsolidated === yesLabel ||
              values.isConsolidated === "Non") && (
              <NumberField
                name="nbDaysStopped"
                label="Nombre cumulé de jours d'arrêt ouvrables"
                unit="jours"
              />
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleAccident;
