import { BackErrorMessages } from "./utils";
import * as HttpStatus from "http-status-codes";

export const HTTP_UNAVAILABLE_FOR_LEGAL_REASONS = 451;
export const HTTP_NOT_FOUND = 404;
export const defaultErrorMessage =
  "Une erreur est survenue. Veuillez ré-essayer ultérieurement.";

export const backMessageFetchDeclaration: BackErrorMessages = {
  default: defaultErrorMessage,
};

export const backMessageOnSave: BackErrorMessages = {
  [HttpStatus.FORBIDDEN]: "L'enregistrement des modifications est impossible.",
  [HttpStatus.UNAUTHORIZED]:
    "Vous n'êtes pas autorisé à modifier cette partie de la déclaration.",
  [HttpStatus.BAD_REQUEST]: "Impossible d'enregistrer, données invalides.",
  [HttpStatus.NOT_FOUND]: "Impossible de trouver la déclaration.",
  default: defaultErrorMessage,
};

export const backMessageOnValidate: BackErrorMessages = {
  [HttpStatus.FORBIDDEN]:
    "La validation de cette partie de la déclaration a été refusée car des erreurs sont présentes.",
  [HttpStatus.UNAUTHORIZED]:
    "Vous n'êtes pas autorisé à valider cette partie de la déclaration.",
  [HttpStatus.BAD_REQUEST]: "Impossible de valider, données invalides.",
  [HttpStatus.NOT_FOUND]: "Chemin de validation invalide.",
  default: defaultErrorMessage,
};

export const backMessageOnEdit: BackErrorMessages = {
  [HttpStatus.FORBIDDEN]:
    "L'édition de cette partie de la déclaration est impossible.",
  [HttpStatus.UNAUTHORIZED]:
    "Vous n'êtes pas autorisé à modifier cette partie de la déclaration.",
  [HttpStatus.BAD_REQUEST]: "Impossible de modifier, données invalides.",
  [HttpStatus.NOT_FOUND]: "Chemin de validation invalide.",
  default: defaultErrorMessage,
};

export const backMessageGeneral: BackErrorMessages = {
  [HttpStatus.UNAUTHORIZED]:
    "Vous n'êtes pas autorisé à effectuer cette action.",
  default: defaultErrorMessage,
};

export const backMessageOnUpdateTypeActivite = backMessageGeneral;

export const backMessageOnStartDeclaration: BackErrorMessages = {
  [HttpStatus.FORBIDDEN]:
    "La validation du type d'activité a été refusée car des erreurs sont présentes.",
  [HttpStatus.UNAUTHORIZED]:
    "Vous n'êtes pas autorisé à valider le type d'activité.",
  [HttpStatus.BAD_REQUEST]: "Impossible de valider, données invalides.",
  default: defaultErrorMessage,
};

export const backMessageOnResetDeclaration = backMessageGeneral;

export const backMessageOnYieldQuotas = backMessageGeneral;

export const backSubmitSimplifiedQuotas = backMessageGeneral;

export const backMessageSetVerification = backMessageGeneral;

export const backMessageSubmitDeclaration = backMessageGeneral;

export const backMessageSubmitVerifDeclaration = backMessageOnValidate;

export const backMessageRecallDeclaration = backMessageGeneral;

export const backMessageReviewDeclaration = backMessageGeneral;

export const backMessageTakeOverDeclaraion = backMessageGeneral;

export const backMessageRelieveDeclaration = backMessageGeneral;

export const backMessageApproveDeclaration = backMessageGeneral;

export const backMessageAddComment = backMessageGeneral;

export const backMessageExportDeclaration = backMessageGeneral;

export const backMessageExportEtablissementList = backMessageGeneral;

export const backMessageExportAccuseTransmission = backMessageGeneral;

export const backMessageDeclarationFilesDownload = {
  ...backMessageGeneral,
  [HttpStatus.NOT_FOUND]: "Aucun fichier n'a été déposé dans la déclaration.",
};

export const backMessageContactInspector = {
  ...backMessageGeneral,
  [HttpStatus.NOT_FOUND]:
    "Aucun destinataire n'a été trouvé pour cette déclaration.",
};

export const backMessageEstablishmentCreation = {
  ...backMessageGeneral,
  [HttpStatus.CONFLICT]:
    "Cet établissement existe déjà, veuillez créer un établissement différent.",
};

export const backMessageRetrieveEstablishmentList = backMessageGeneral;

export const backMessageRetrieveDeclarationList = backMessageGeneral;

export const backMessageChangeUserRights = backMessageGeneral;

export const backMessageUploadFile = {
  ...backMessageGeneral,
  [HttpStatus.UNSUPPORTED_MEDIA_TYPE]:
    "Le contenu du fichier téléversé n'est pas dans un format supporté.",
  [HttpStatus.NOT_ACCEPTABLE]:
    "Le fichier déposé semble corrompu. Il ne sera donc pas enregistré sur la plateforme. Veuillez en déposer un nouveau.",
};

export const backMessageDownloadFile = backMessageGeneral;

export const backMessageDeleteFile = backMessageGeneral;

export const backMessageDeleteEtablissement = backMessageGeneral;

export const backMessageReferential = backMessageGeneral;
export const backMessageReferentialUpdate = {
  ...backMessageGeneral,
  [HttpStatus.BAD_REQUEST]:
    "le fichier excel envoyé est invalide, veuillez le remplir en vous conformant aux normes du fichier téléchargé.",
};

//for messages independant of response status

export const backMessageAllowedExtensionsFile =
  'Une erreur est survenue, pour pouvoir envoyer des fichiers cliquez sur "recharger".';

export const backMessageInseeError = {
  ...backMessageGeneral,
  [HttpStatus.INTERNAL_SERVER_ERROR]:
    "Une erreur est survenue, veuillez réessayer dans quelques minutes.",
  [HTTP_UNAVAILABLE_FOR_LEGAL_REASONS]:
    "Cet établissement n'est pas consultable auprès de l'INSEE.",
  [HttpStatus.TOO_MANY_REQUESTS]:
    "L'API Entreprise est actuellement indisponible, veuillez réessayer ultérieurement.",
  [HttpStatus.NOT_FOUND]: "Ce siret n'existe pas sur l'API Entreprise.",
};

export const backMessageTDError = {
  ...backMessageGeneral,
  [HttpStatus.INTERNAL_SERVER_ERROR]:
    "Une erreur est survenue, veuillez réessayer dans quelques minutes.",
  [HttpStatus.NOT_FOUND]:
    "Les données demandées ne sont pas disponibles, veuillez vérifier votre déclaration sur Trackdéchets.",
  [HttpStatus.TOO_MANY_REQUESTS]:
    "L'API Trackdéchets est actuellement indisponible, veuillez réessayer ultérieurement.",
  [HttpStatus.FORBIDDEN]:
    "Pour des raisons de confidentialité des données, le service de téléchargement des données Trackdéchets n’est pas possible avec votre compte utilisateur. " +
    "Le SIREN de votre compte utilisateur (enregistré lors de la création de votre compte sur le portail Cerbère) doit disposer des 9 premiers chiffres du code SIRET de la déclaration annuelle GEREP. " +
    "Vous devez remplir le pavé Déchets par saisie manuelle ou par l’import du tableur.",
};
