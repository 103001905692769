import React from "react";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../CommonSpace/AppPage/PageHeaderContext";
import ErrorPage from "./ErrorPage";

const HttpError = (props: {
  code: number;
  message: string;
}): React.ReactElement => {
  const paths = usePathToDeclarationDashboard(null, null);

  paths.push({ label: props.code.toString(), to: window.location.pathname });

  const headerData: PageHeaderProps = {
    arianeString: paths,
    title: "",
  };

  usePageHeaderSetterOnce(headerData);
  return <ErrorPage code={props.code} text={props.message} />;
};

export default HttpError;
