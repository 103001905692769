export const mergeValues = <T extends Record<string, any>>(
  values: T,
  mapExternalFieldNameToFieldValue: Map<string, unknown>
): T => {
  const shallowClone: T = { ...values };

  mapExternalFieldNameToFieldValue.forEach(
    // the below as are ok because mapExternalFieldNameToFieldValue is supposed to have values matching EXACTLY the original values
    (fieldValue, fieldName) =>
      (shallowClone[fieldName as keyof T] = fieldValue as any)
  );

  return shallowClone;
};
