import React from "react";
import { DustFalloutPointInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_RETOMBEE } from "common/path/path18Now";
import Row from "common/presentational/Row";

interface DustFalloutArrayProps {
  isDisabled: boolean;
  emissionPointsInArray: DustFalloutPointInArray[];
  setEmissionPointsInArray: (points: DustFalloutPointInArray[]) => void;
  onEdit: (point: DustFalloutPointInArray) => void;
}

const useStyles = makeStyles({
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const DustFalloutArray = ({
  isDisabled,
  emissionPointsInArray,
  setEmissionPointsInArray,
  onEdit,
}: DustFalloutArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>Point d'émission</p>,
    <p>Trimestre 1 (mg/m²/jour)</p>,
    <p>Trimestre 2 (mg/m²/jour)</p>,
    <p>Trimestre 3 (mg/m²/jour)</p>,
    <p>Trimestre 4 (mg/m²/jour)</p>,
    <p>Type d'emplacement</p>,
    <p>Jauge / Plaquette</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = emissionPointsInArray.map(
    emissionPoint => [
      <p
        title={emissionPoint.data.name || ""}
        className={isDisabled ? classes.disabledLink : classes.link}
        onClick={() => {
          if (!isDisabled) {
            onEdit(emissionPoint);
          }
        }}
      >
        {emissionPoint.data.name}
      </p>,
      emissionPoint.data.firstTrimester === null ? (
        <p></p>
      ) : (
        <p>{computeNumberWithSeparator(emissionPoint.data.firstTrimester)}</p>
      ),
      emissionPoint.data.secondTrimester === null ? (
        <p></p>
      ) : (
        <p>{computeNumberWithSeparator(emissionPoint.data.secondTrimester)}</p>
      ),
      emissionPoint.data.thirdTrimester === null ? (
        <p></p>
      ) : (
        <p>{computeNumberWithSeparator(emissionPoint.data.thirdTrimester)}</p>
      ),
      emissionPoint.data.fourthTrimester === null ? (
        <p></p>
      ) : (
        <p>{computeNumberWithSeparator(emissionPoint.data.fourthTrimester)}</p>
      ),
      emissionPoint.data.locationType === null ? (
        <p></p>
      ) : (
        <p>{emissionPoint.data.locationType.label}</p>
      ),
      <p>{emissionPoint.data.gaugeOrBooklet}</p>,
      <CommonEntityButton
        handlerEdit={() => onEdit(emissionPoint)}
        commentPath={`${PATH_CARRIERE_ENVIRONNEMENT_ATMOSPHERIQUE_MESURES_RETOMBEE}/${emissionPoint.data.id}`}
        handlerSuppress={() =>
          setEmissionPointsInArray(
            emissionPointsInArray.filter(
              e => e.data.id !== emissionPoint.data.id
            )
          )
        }
        suppressMessage={
          "Êtes vous sûr de vouloir supprimer ce point d'émission ?"
        }
        isValidated={isDisabled}
      />,
    ]
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredColWidths={[
          250,
          180,
          180,
          180,
          180,
          120,
          120,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setEmissionPointsInArray([])}
        />
      </Row>
    </>
  );
};

export default DustFalloutArray;
