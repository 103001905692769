import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { LEFT_WITHDRAW_STYLE, SECTION_TITLE_GREY, SUBTITLE_STYLE } from "theme";
import Row from "common/presentational/Row";
import {
  BlocCalculatedEmissionFormType,
  MainFormValues,
  mainFormValuesFieldMatcher,
} from "./utils/types";
import { computeFormInitialValues } from "./utils/converter";
import {
  useDummyBooleanCheckboxFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { useFormikBloc } from "common/formikBloc/utils";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  createAirIsdndBasicDto,
  createAirIsdndCalculCh4Dto,
  createAirIsdndCalculCo2Dto,
} from "./utils/mainSubmitHandler";
import { computeInitialBlocValues } from "./blocCalculatedEmissions/utils";
import { BlocCalculatedEmissions } from "./blocCalculatedEmissions";
import { ISDNDControllerValidationSchema } from "./validation/validation";
import {
  PATH_AIR_ISDND,
  PATH_AIR_ISDND_BASIC,
  PATH_AIR_ISDND_EMISSIONS,
} from "common/path/path18Now";
import {
  useDeclaration19Now,
  useDeclarationHelpers19Now,
} from "../../versionedElements/declarationHooks19Now";
import FormikBlocFullContext19Now from "../../versionedElements/FormikBlocFullContext19Now";
import GlobalFormActionFullContext19Now from "../../versionedElements/GlobalFormActionFullContext19Now";
import { useCorrectYearTypeActiviteGlobal19Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";

const useStyles = makeStyles({
  blocDescription: {
    margin: "auto",
    color: SECTION_TITLE_GREY,
    textAlign: "center",
  },
  inputField: {
    marginBottom: "5px",
  },
  subTitle: SUBTITLE_STYLE,
  ...LEFT_WITHDRAW_STYLE,
});

export const ISDNDController = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration19Now();
  const typeActiviteGlobal = useCorrectYearTypeActiviteGlobal19Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers19Now();

  const initialFormValues = computeFormInitialValues(declaration);
  const initialCalculatedEmissions = computeInitialBlocValues(
    declaration.body.sections.air.isdnd.emissionsCalculees
  );
  initialCalculatedEmissions.errorCo2OrCh4Required = null;

  const isdndFormProps = useFormikBloc<MainFormValues>();
  const emissionFormProps = useFormikBloc<BlocCalculatedEmissionFormType>();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-air-isdnd",
  };
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyBooleanCheckBox = useDummyBooleanCheckboxFieldGenerator({
    ...commonProps,
    disabled: true,
  });

  const isdndFormValidated = isPathValidatedInDeclaration(PATH_AIR_ISDND_BASIC);
  const isCalculatedEmissionValidated = isPathValidatedInDeclaration(
    PATH_AIR_ISDND_EMISSIONS
  );

  const isIsdnd = typeActiviteGlobal.isdnd || false;
  const exportingBioGaz = typeActiviteGlobal.exporteBiogaz || false;

  const hasChanges = isdndFormProps.hasChanges || emissionFormProps.hasChanges;
  const isPageValidateEnabled =
    (!exportingBioGaz || isdndFormValidated) && isCalculatedEmissionValidated;

  return (
    <>
      <p className={classes.blocDescription}>
        Seules les émissions de CO<sub>2</sub> et CH<sub>4</sub> liées aux
        casiers de stockage sont à déclarer dans ce module. Pour les autres
        substances ou les activités connexes, merci de vous rendre dans les
        modules combustion (torchage, valorisation en chaudières ou turbines,
        etc.) ou procédés/émissions diffuses (COVNM du massif, compostage, etc.)
      </p>

      <Row />

      <p className={classes.subTitle}>
        DONNÉES HÉRITÉES DU BLOC INFORMATIONS GÉNÉRALES
      </p>

      <DummyBooleanCheckBox
        name="ISDND"
        label="L'établissement possède une ou plusieurs installations de stockage de déchets non dangereux (ISDND)"
        value={isIsdnd}
      />

      <div className={classes.withdrawLeft}>
        <DummyBooleanCheckBox
          name="exportingBiogaz"
          label="L'ISDND exporte du biogaz"
          value={exportingBioGaz}
        />
      </div>

      <Row />

      {exportingBioGaz && (
        <>
          <FormikBlocFullContext19Now
            formikRef={isdndFormProps.formikRef}
            hasChanges={isdndFormProps.hasChanges}
            setHasChanges={isdndFormProps.setHasChanges}
            initialValues={initialFormValues}
            validationSchema={ISDNDControllerValidationSchema}
            title={"informations complémentaires"}
            pathToValidate={PATH_AIR_ISDND_BASIC}
            areGlobalCommentsAllowed={true}
            updateHandler={(declaration, values) => {
              declaration.body.sections.air.isdnd.basic = createAirIsdndBasicDto(
                values
              );

              return declaration;
            }}
            renderContent={(mainFormValues, shouldDisableFields) => {
              return (
                <>
                  <NumberField
                    name={mainFormValuesFieldMatcher.volumeBiogazExported}
                    label="Volume de biogaz exporté *"
                    unit="kg/an"
                    disabled={shouldDisableFields}
                    commentPath={`${PATH_AIR_ISDND_BASIC}/biogaz`}
                  />
                  <NumberField
                    name={mainFormValuesFieldMatcher.ch4Content}
                    label={() => (
                      <>
                        Teneur en CH<sub>4</sub> *
                      </>
                    )}
                    unit="%"
                    disabled={shouldDisableFields}
                    commentPath={`${PATH_AIR_ISDND_BASIC}/ch4`}
                  />
                  {generateWarningMessageIfUnder1Percent(
                    mainFormValues.values.ch4Content
                  )}
                </>
              );
            }}
          />

          <Row />
        </>
      )}

      <BlocCalculatedEmissions
        formikRef={emissionFormProps.formikRef}
        hasChanges={emissionFormProps.hasChanges}
        setHasChanges={emissionFormProps.setHasChanges}
        initialValues={initialCalculatedEmissions}
        path={PATH_AIR_ISDND_EMISSIONS}
        updateHandler={(declaration, values) => {
          declaration.body.sections.air.isdnd.emissionsCalculees.calculCh4 = createAirIsdndCalculCh4Dto(
            values
          );
          declaration.body.sections.air.isdnd.emissionsCalculees.calculCo2 = createAirIsdndCalculCo2Dto(
            values
          );

          if (
            emissionFormProps.formikRef &&
            emissionFormProps.formikRef.current
          ) {
            emissionFormProps.formikRef.current.resetForm(
              computeInitialBlocValues(
                declaration.body.sections.air.isdnd.emissionsCalculees
              )
            );
          }

          return declaration;
        }}
      />

      <Row />

      <GlobalFormActionFullContext19Now
        validationTitle={"VALIDER PAGE >"}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasChanges}
        isValidateEnabled={isPageValidateEnabled}
        validationPath={PATH_AIR_ISDND}
        updateHandler={declaration => {
          if (isdndFormProps.formikRef && isdndFormProps.formikRef.current) {
            declaration.body.sections.air.isdnd.basic = createAirIsdndBasicDto(
              isdndFormProps.formikRef.current.state.values
            );
          }

          if (
            emissionFormProps.formikRef &&
            emissionFormProps.formikRef.current
          ) {
            declaration.body.sections.air.isdnd.emissionsCalculees.calculCh4 = createAirIsdndCalculCh4Dto(
              emissionFormProps.formikRef.current.state.values
            );
            declaration.body.sections.air.isdnd.emissionsCalculees.calculCo2 = createAirIsdndCalculCo2Dto(
              emissionFormProps.formikRef.current.state.values
            );
          }

          return declaration;
        }}
        cancelAction={() => {
          if (isdndFormProps.formikRef && isdndFormProps.formikRef.current) {
            isdndFormProps.formikRef.current.resetForm(initialFormValues);
          }
          if (
            emissionFormProps.formikRef &&
            emissionFormProps.formikRef.current
          ) {
            emissionFormProps.formikRef.current.resetForm(
              initialCalculatedEmissions
            );
          }
        }}
      />
    </>
  );
};
