import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WasteData } from "../types";
import Row from "common/presentational/Row";
import { DeclareWasteModale } from "../sortant/productionModal";
import _ from "lodash";
import {
  parseFetch,
  productionDataComputed,
  receptionDataComputed,
  shouldDisplayProductionArray,
  shouldDisplayReceptionArray,
} from "../utils/utils";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../entrant/reception/types";
import {
  methods,
  operationOrValoriationForReception,
  operationOrValorisationForProduction,
  productionLocation,
  receptionLocation,
} from "../utils/Referentiels";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
} from "../utils/Generators";
import {
  createWasteProductionDto,
  createWasteReceptionDto,
} from "../utils/SubmitHandler";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../sortant/production/types";
import {
  ComputedSyntheseElementDto,
  DechetProduitDto23Now,
  DechetProduitDto23NowLieuOperationEnum,
  DechetRecuDto23Now,
  ReferenceDechetDto,
  ReferenceDepartementDto,
  ReferencePaysDto,
} from "api/gen";
import { useEntityModale } from "common/modale/modaleStates";
import { DataWithErrors } from "common/form/utils";
import { DeclareReceptionWasteModal } from "../entrant/receptionModal";
import {
  PATH_DECHET_PRODUCTION,
  PATH_DECHET_RECEPTION,
  PATH_DECHET_RESUME,
} from "common/path/path18Now";
import { generateReceivedWasteErrors } from "../entrant/reception/utils";
import { generateProductedWasteErrors } from "../sortant/production/utils";
import { findElementMatchingTemplate } from "common/utils/methods";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";
import {
  useDeclaration24Now,
  useDeclarationHelpers24Now,
} from "../../versionedElements/declarationHooks24Now";
import { TypeActiviteGlobal21Now } from "../../../../from21/toNow/versionedElements/declarationHooks21Now";
import GlobalFormActionFullContext24Now from "../../versionedElements/GlobalFormActionFullContext24Now";
import { WasteSyntheseArray } from "./synthese";
import WasteSumUpWrapper from "./sumUp/WasteSumUpWrapper";
import { PATH_DECHET_SYNTHESE } from "common/path/path24Now";

interface WastFormProps {
  referentialDechet: ReferenceDechetDto;
  referentialDepartements: ReferenceDepartementDto;
  referentielPays: ReferencePaysDto;
}

const WasteControllerSynthese = ({
  referentialDechet,
  referentialDepartements,
  referentielPays,
}: WastFormProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();

  //  formik refs
  const formProduction = useFormikBloc<ProductedWasteFormData>();
  const formReception = useFormikBloc<ReceivedWasteFormData>();

  const typeActivite: TypeActiviteGlobal21Now = useCorrectYearTypeActiviteGlobal20Now();
  const isReceptionAvailable = typeActivite.recepDechet;

  const receptionDechets: ReceivedWasteFormData | null =
    formReception.formikRef.current &&
    formReception.formikRef.current.state.values;

  const referentiels = useMemo(() => {
    return {
      polluants: referentialDechet.dechets,
      methods: methods,
      operationOrValorisationForProduction: operationOrValorisationForProduction,
      operationOrValorisationForReception: operationOrValoriationForReception,
      productionLocation: productionLocation.filter(singleLocation => {
        return (
          isReceptionAvailable ||
          singleLocation.backCode !==
            DechetProduitDto23NowLieuOperationEnum.SITE
        );
      }),
      receptionLocation: receptionLocation,
      departements: referentialDepartements.departements,
      pays: referentielPays.referenceItemPaysDtoList,
    };
  }, [
    referentialDechet.dechets,
    referentialDepartements.departements,
    referentielPays.referenceItemPaysDtoList,
    isReceptionAvailable,
  ]);

  //  handle fetch data
  const [
    productionInfo,
    productionContainer,
    receptionInfo,
    receptionContainer,
  ] = parseFetch(
    declaration.body,
    referentiels,
    typeActivite,
    receptionDechets
  );

  //    init page data with fetch data
  const [tempProductionContainer, setTempProductionContainer] = useState<
    ProductedWasteStorage[]
  >(productionContainer);
  const [tempReceptionContainer, setTempReceptionContainer] = useState<
    ReceivedWasteStorage[]
  >(receptionContainer);
  const [tempWasteProductInfo, setTempWasteProductInfo] = useState<
    ProductedWasteFormData
  >(productionInfo);
  const [tempWasteReceptionInfo, setTempWasteReceptionInfo] = useState<
    ReceivedWasteFormData
  >(receptionInfo);

  //    modal handlers
  const productionModalProps = useEntityModale<
    DataWithErrors<DechetProduitDto23Now>
  >();
  const receptionModalProps = useEntityModale<
    DataWithErrors<DechetRecuDto23Now>
  >();

  //  to display data
  const wasteData: WasteData = {
    //  containers
    validData: {
      production: productionDataComputed(productionInfo, productionContainer),
      reception: receptionDataComputed(
        receptionInfo,
        receptionContainer,
        productionInfo,
        productionContainer
      ),
    },
    tempData: {
      production: productionDataComputed(
        tempWasteProductInfo,
        tempProductionContainer
      ),
      reception: receptionDataComputed(
        tempWasteReceptionInfo,
        tempReceptionContainer,
        tempWasteProductInfo,
        tempProductionContainer
      ),
    },

    //  form referentiels
    referentiels: referentiels,
  };

  //  function data handler
  const addDeclaredWaste = (waste: DechetProduitDto23Now) => {
    const data = {
      data: waste,
      errors: generateProductedWasteErrors(waste, referentiels),
    };

    setTempProductionContainer(value => [...value, data]);
    productionModalProps.closeModale();
  };

  const updateProductedWaste = (
    previousData: ProductedWasteStorage,
    newData: DechetProduitDto23Now
  ) => {
    const data = {
      data: newData,
      errors: generateProductedWasteErrors(newData, referentiels),
    };
    setTempProductionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return data;
        }
      });
    });
    productionModalProps.closeModale();
  };

  const addReceivedWaste = (waste: DechetRecuDto23Now) => {
    const data = { data: waste, errors: {} };

    if (formReception.formikRef.current) {
      const formValues = formReception.formikRef.current.state.values;
      setTempWasteReceptionInfo(formValues);
    }
    setTempReceptionContainer(value => [...value, data]);
    receptionModalProps.closeModale();
  };
  const updateReceivedWaste = (
    previousData: ReceivedWasteStorage,
    newData: DechetRecuDto23Now
  ) => {
    setTempReceptionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return { data: newData, errors: {} };
        }
      });
    });
    receptionModalProps.closeModale();
  };

  const computeProductionDto = () => {
    //  I have to check if changes have not been reported here in production form
    let info;
    if (formProduction.formikRef.current) {
      info = formProduction.formikRef.current.state.values;
    } else {
      info = tempWasteProductInfo;
    }

    if (!shouldDisplayProductionArray(info)) {
      setTempProductionContainer([]);
    }

    return createWasteProductionDto({
      formInfo: info,
      container: shouldDisplayProductionArray(info)
        ? tempProductionContainer
        : [],
    });
  };
  const computeReceptionDto = () => {
    //  I have to check if changes have not been reported here in reception form
    let info;
    if (formReception.formikRef.current) {
      info = formReception.formikRef.current.state.values;
    } else {
      info = tempWasteReceptionInfo;
    }

    return createWasteReceptionDto({
      formInfo: info,
      container: shouldDisplayReceptionArray(info)
        ? tempReceptionContainer
        : [],
    });
  };

  const shouldShowBottomSave =
    !_.isEqual(
      wasteData.validData.production.container,
      wasteData.tempData.production.container
    ) ||
    !_.isEqual(
      wasteData.validData.reception.container,
      wasteData.tempData.reception.container
    ) ||
    formProduction.hasChanges ||
    formReception.hasChanges;
  const isProductionValidated = isPathValidatedInDeclaration(
    PATH_DECHET_PRODUCTION
  );
  const isReceptionValidated = isPathValidatedInDeclaration(
    PATH_DECHET_RECEPTION
  );
  const hasValidatedAllBlocs =
    isProductionValidated && (isReceptionValidated || !isReceptionAvailable);

  // Warning, the dependencies used here make React run the effect ONLY for the first change
  useEffect(() => {
    if (formProduction.formikRef.current) {
      setTempWasteProductInfo(formProduction.formikRef.current.state.values);
    }
  }, [formProduction.hasChanges, formProduction.formikRef]);
  useEffect(() => {
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      setTempWasteReceptionInfo(currentFormReceptionRef.state.values);
    }
  }, [formReception.hasChanges, formReception.formikRef]);

  useEffect(() => {
    //because errors in reception array depend of the formReception, we want to recompute them (btw, putting the errors in the state was a mistake, as they're actually a COMPUTED value)
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      const newReceptionContainer = tempReceptionContainer.map(
        singleWasteReception => ({
          ...singleWasteReception,
          errors: generateReceivedWasteErrors(
            singleWasteReception.data,
            referentiels,
            typeActivite,
            currentFormReceptionRef.state.values
          ),
        })
      );

      if (!_.isEqual(newReceptionContainer, tempReceptionContainer)) {
        setTempReceptionContainer(newReceptionContainer);
      }
    }
  }, [formReception, referentiels, tempReceptionContainer, typeActivite]);

  useEffect(() => {
    // Use effect to update the temp data when the declaration changes
    setTempProductionContainer(
      convertProductionFetchToLocal(
        declaration.body.sections.dechets.production.productionDechet,
        referentiels
      )
    );
    setTempReceptionContainer(
      convertReceptionFetchToLocal(
        declaration.body.sections.dechets.reception.receptionDechets,
        referentiels,
        typeActivite,
        receptionDechets
      )
    );
  }, [declaration.body.sections.dechets]);

  const compareFunction = useCallback(
    (a: ComputedSyntheseElementDto, b: ComputedSyntheseElementDto): number => {
      const aDechet = findElementMatchingTemplate(
        { uuid: a.codeID },
        wasteData.referentiels.polluants
      );
      const bDechet = findElementMatchingTemplate(
        { uuid: b.codeID },
        wasteData.referentiels.polluants
      );
      if (aDechet && bDechet && aDechet.codeDechet !== bDechet.codeDechet) {
        return aDechet.codeDechet < bDechet.codeDechet ? -1 : 1;
      }
      return 0;
    },
    [wasteData.referentiels.polluants]
  );

  const sortedArrayElem = useMemo(() => {
    return _.cloneDeep(declaration.computed.sections.dechets.synthese).sort(
      compareFunction
    );
  }, [compareFunction, declaration.computed.sections.dechets.synthese]);

  return (
    <>
      {hasValidatedAllBlocs && (
        <WasteSyntheseArray
          lines={sortedArrayElem}
          referentials={wasteData.referentiels}
          isReceptionAvailable={isReceptionAvailable}
        />
      )}

      <Row />

      <WasteSumUpWrapper
        wasteData={wasteData}
        validationPath={PATH_DECHET_RESUME}
        isReceptionAvailable={isReceptionAvailable}
        computedWaste={declaration.computed.sections.dechets}
      />

      <DeclareWasteModale
        wasteData={wasteData}
        isOpen={productionModalProps.modaleState.isOpen}
        updatingWaste={productionModalProps.modaleState.elementToEdit}
        close={() => {
          productionModalProps.closeModale();
        }}
        validate={waste => {
          if (productionModalProps.modaleState.elementToEdit) {
            updateProductedWaste(
              productionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addDeclaredWaste(waste);
          }
        }}
        isOnSiteOptionAvailable={isReceptionAvailable}
      />

      <DeclareReceptionWasteModal
        wasteData={wasteData}
        isOpen={receptionModalProps.modaleState.isOpen}
        updatingWaste={receptionModalProps.modaleState.elementToEdit}
        aCasierAmiantes={
          wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases
        }
        isISDIChecked={wasteData.tempData.reception.formInfo.isISDI}
        isISDDChecked={wasteData.tempData.reception.formInfo.isISDD}
        isISDNDChecked={wasteData.tempData.reception.formInfo.isISDND}
        isIncinerationDechetChecked={
          wasteData.tempData.reception.formInfo.isIncinerationDechet
        }
        close={() => {
          receptionModalProps.closeModale();
        }}
        validate={waste => {
          if (receptionModalProps.modaleState.elementToEdit) {
            updateReceivedWaste(
              receptionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addReceivedWaste(waste);
          }
        }}
      />

      <GlobalFormActionFullContext24Now
        validationTitle={"VALIDER PAGE >"}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={shouldShowBottomSave}
        isValidateEnabled={hasValidatedAllBlocs}
        validationPath={PATH_DECHET_SYNTHESE}
        updateHandler={declaration => {
          declaration.body.sections.dechets.production = computeProductionDto();
          declaration.body.sections.dechets.reception = computeReceptionDto();
          return declaration;
        }}
        cancelAction={() => {
          setTempWasteProductInfo(productionInfo);
          setTempProductionContainer(productionContainer);

          setTempWasteReceptionInfo(receptionInfo);
          setTempReceptionContainer(receptionContainer);

          if (formProduction.formikRef.current) {
            formProduction.formikRef.current.resetForm(productionInfo);
          }
          if (formReception.formikRef.current) {
            formReception.formikRef.current.resetForm(receptionInfo);
          }
        }}
      />
    </>
  );
};

export default WasteControllerSynthese;
