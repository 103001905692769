import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  ProduitTransportType,
  TransportProductsArrayForm,
} from "./utils/types";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import {
  computeListDeclaredFamilies,
  computeTotalProductsTonnage,
  computeTransportProductsArrayFormInitialValues,
} from "./utils/computers";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import { convertDeclarationDtoUsingTransportProductsForm } from "../utils/converters";
import { SECTION_TITLE_GREY } from "theme";
import _ from "lodash";
import { convertProduitTransportDtoToDisplayed } from "./utils/converters";
import {
  ReferenceDepartementDto,
  ReferenceFamilleUsageCodeProduitDto,
  ReferenceFamilleUsageProductionDto,
  ReferencePaysDto,
} from "api/gen";
import Row from "common/presentational/Row";
import {
  PATH_CARRIERE_PRODUCTION_TRANSPORT,
  PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE,
} from "common/path/path18Now";
import SectionListTransportsProduits from "./SectionListTransportsProduits";
import { validateTransportsForm } from "./utils/validate";
import { useDeclaration23Now } from "../../../versionedElements/declarationHooks23Now";
import FormikBlocFullContext23Now from "../../../versionedElements/FormikBlocFullContext23Now";

interface BlocTransportProduitsProps {
  initialTransportProducts: ProduitTransportType[];
  transportProductsFormProps: FormikBlocRefAndState<TransportProductsArrayForm>;
  transportProductsInArray: ProduitTransportType[];
  setTransportProductsInArray: (products: ProduitTransportType[]) => void;
  referentialCountries: ReferencePaysDto;
  referentialDepartements: ReferenceDepartementDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
  referentialFamiliesCodeProduction: ReferenceFamilleUsageCodeProduitDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  containerSpace: {
    padding: "15px 10px 25px 10px",
    color: SECTION_TITLE_GREY,
  },
  fieldArrayTitles: {
    color: SECTION_TITLE_GREY,
    marginLeft: "20px",
    marginBottom: "20px",
    marginTop: "20px",
    fontSize: "110%",
  },
});

const BlocTransportProduits = ({
  initialTransportProducts,
  transportProductsFormProps,
  transportProductsInArray,
  setTransportProductsInArray,
  referentialCountries,
  referentialDepartements,
  referentialFamilies,
  referentialFamiliesCodeProduction,
}: BlocTransportProduitsProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration23Now();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "carriere-production-transport",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const initialValues = computeTransportProductsArrayFormInitialValues(
    declaration,
    referentialFamilies
  );

  return (
    <>
      <FormikBlocFullContext23Now
        hasChanges={transportProductsFormProps.hasChanges}
        setHasChanges={transportProductsFormProps.setHasChanges}
        formikRef={transportProductsFormProps.formikRef}
        title="TRANSPORT DES PRODUITS FINIS EXPÉDIÉS (TP4)"
        initialValues={initialValues}
        pathToValidate={PATH_CARRIERE_PRODUCTION_TRANSPORT}
        areGlobalCommentsAllowed={true}
        enableReinitialize
        updateHandler={(declaration, values) =>
          convertDeclarationDtoUsingTransportProductsForm(
            declaration,
            values,
            transportProductsInArray
          )
        }
        hasFormChanges={values => {
          return (
            !_.isEqual(
              values.productsTransport,
              initialValues.productsTransport
            ) || !_.isEqual(transportProductsInArray, initialTransportProducts)
          );
        }}
        cancelAction={() => {
          setTransportProductsInArray(
            convertProduitTransportDtoToDisplayed(
              declaration.body.sections.carriere.production.transport
                .tableauDestinations,
              referentialDepartements,
              referentialCountries,
              referentialFamilies,
              referentialFamiliesCodeProduction
            )
          );
          return initialValues;
        }}
        additionalFieldValidation={values =>
          validateTransportsForm(
            values,
            computeListDeclaredFamilies(transportProductsInArray).map(
              famille => famille.restorationCode
            )
          )
        }
        renderContent={({ values }, shouldDisabledFields: boolean) => (
          <>
            <div className={classes.containerSpace}>
              Les produits sont soit les produits vendus en sortie de carrières,
              soit les produits issus de la première transformation en sortie
              d'usine ou d'atelier
            </div>
            <div className={classes.containerSpace}>
              Complétez le tableau suivant en cliquant sur le bouton "Ajouter un
              produit" et en renseignant les caractéristiques demandées
              (destination, tonnage, etc.)
            </div>
            <SectionListTransportsProduits
              transportProductsInArray={transportProductsInArray}
              setTransportProductsInArray={setTransportProductsInArray}
              referentialCountries={referentialCountries}
              referentialDepartements={referentialDepartements}
              referentialFamilies={referentialFamilies}
              referentialFamiliesCodeProduction={
                referentialFamiliesCodeProduction
              }
              shouldDisableFields={shouldDisabledFields}
            />
            <Row />

            <DummyNumberField
              name="totalTonnage"
              label="Total produits expédiés"
              unit="ktonnes"
              disabled
              value={computeTotalProductsTonnage(transportProductsInArray)}
            />
            <FieldArray
              name="productsTransport"
              render={() => (
                <div>
                  {computeListDeclaredFamilies(transportProductsInArray)
                    .sort(
                      (usageFamily1, usageFamily2) =>
                        usageFamily1.restorationCode -
                        usageFamily2.restorationCode
                    )
                    .map((usageFamily, i) => (
                      <Fragment key={i}>
                        <h3 className={classes.fieldArrayTitles}>
                          {usageFamily.libelleCourt}
                        </h3>

                        <NumberField
                          name={`productsTransport.${usageFamily.restorationCode}.byRoad`}
                          label="Par la route *"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${i}/route`}
                        />
                        {generateWarningMessageIfUnder1Percent(
                          values.productsTransport[usageFamily.restorationCode]
                            .byRoad
                        )}

                        <NumberField
                          name={`productsTransport.${usageFamily.restorationCode}.byRail`}
                          label="Par le rail *"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${i}/rail`}
                        />
                        {generateWarningMessageIfUnder1Percent(
                          values.productsTransport[usageFamily.restorationCode]
                            .byRail
                        )}

                        <NumberField
                          name={`productsTransport.${usageFamily.restorationCode}.byWaterWay`}
                          label="Par voie navigable *"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${i}/navigable`}
                        />
                        {generateWarningMessageIfUnder1Percent(
                          values.productsTransport[usageFamily.restorationCode]
                            .byWaterWay
                        )}
                        <NumberField
                          name={`productsTransport.${usageFamily.restorationCode}.other`}
                          label="Autre *"
                          unit="%"
                          disabled={shouldDisabledFields}
                          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${i}/autre`}
                        />
                        {generateWarningMessageIfUnder1Percent(
                          values.productsTransport[usageFamily.restorationCode]
                            .other
                        )}

                        {values.productsTransport[
                          usageFamily.restorationCode
                        ] &&
                        values.productsTransport[usageFamily.restorationCode]
                          .other &&
                        // the as coercion is because there is a TS error even with the non-null check
                        (values.productsTransport[usageFamily.restorationCode]
                          .other as number) > 0 ? (
                          <TextField
                            name={`productsTransport.${usageFamily.restorationCode}.otherPrecision`}
                            label="Préciser autre *"
                            disabled={shouldDisabledFields}
                            commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_MODALITE}/${i}/preciserAutre`}
                          />
                        ) : (
                          ""
                        )}
                      </Fragment>
                    ))}
                </div>
              )}
            />
          </>
        )}
      />
    </>
  );
};

export default BlocTransportProduits;
