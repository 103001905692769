import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import { BuildingInArray, EmissionsSpeciesForm } from "./types";
import { convertBuildingInArrayListToDtos } from "../blocEmissions/utils/converters";
import {
  AirElevageDetailNh320NowEspecesDeclareesEnum,
  AirElevageEmissionsDto,
} from "api/gen";

export const updateDeclarationDtoUsingEmissionsForm = (
  declaration: Declaration20Now,
  values: AirElevageEmissionsDto
): Declaration20Now => {
  declaration.body.sections.air.elevage.emissions = values;
  return declaration;
};

export const updateDeclarationDtoUsingEmissionsSpeciesForm = (
  declaration: Declaration20Now,
  values: EmissionsSpeciesForm
): Declaration20Now => {
  declaration.body.sections.air.elevage.detailNh3.especesDeclarees =
    values.species;
  return declaration;
};

export const updateDeclarationDtoUsingBuildings = (
  declaration: Declaration20Now,
  values: BuildingInArray[],
  selectedSpecies: AirElevageDetailNh320NowEspecesDeclareesEnum[]
): Declaration20Now => {
  declaration.body.sections.air.elevage.detailNh3.batiments = convertBuildingInArrayListToDtos(
    values,
    selectedSpecies
  );
  return declaration;
};
