import {
  ProductedWasteImport,
  ReceivedProductedImportArrayWithMessage,
} from "./types";
import { Errors } from "common/form/utils";
import {
  DechetProduitDto23Now,
  DechetProduitDto23NowLieuOperationEnum,
  ReferenceItemDechetDto,
} from "api/gen";
import { WasteReferentiels } from "../types";
import { Nullable } from "common/utils/types";
import { computeRefMethodForMethod } from "../utils/Referentiels";
import {
  codePostalEtrangerMessage,
  codePostalFrancaisMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import { findElementMatchingTemplate } from "common/utils/methods";
import { parseOuiNonToBoolean } from "../utils/utils";
import { CSV_PRODUCTION_COLUMN_NUMBER } from "../utils/Constants";

export const filterPolluantProductionCSVArray = (
  arrayResults: string[][]
): ReceivedProductedImportArrayWithMessage | null => {
  const results: Nullable<ProductedWasteImport>[] = [];
  if (
    arrayResults.length === 0 ||
    arrayResults[0].length < CSV_PRODUCTION_COLUMN_NUMBER
  ) {
    return null;
  }

  arrayResults.forEach(ele => {
    let hasData = false;

    for (let i = 0; i < CSV_PRODUCTION_COLUMN_NUMBER; i++) {
      if (ele[i] !== undefined && ele[i] !== "") {
        hasData = true;
        break;
      }
    }

    if (!hasData) {
      return;
    }

    let department: string | null = ele[6] || null;
    if (department && department.length === 1) {
      department = `0${department}`;
    }

    const wasteId =
      ele[0] !== undefined ? ele[0].replace("*", "").trim() : null;
    const amount =
      ele[1] !== undefined && ele[1] !== ""
        ? parseFloat(ele[1].replace(",", "."))
        : null;
    const value: Nullable<ProductedWasteImport> = {
      wasteId: wasteId,
      wasteAmount: amount,
      methode: ele[2],
      methodeRef: ele[3] || null,
      precisionRefMethod: ele[4] || null,
      operationElimOuValo: ele[5] || null,
      departement: department,
      pays: ele[7] || null,
      isFirstReceptionFrance: parseOuiNonToBoolean(ele[8]),
      etablissementReception: ele[9] || null,
      etablissementReceptionStreetAdress: ele[10] || null,
      etablissementReceptionPostalCode: ele[11].trim() || null,
      etablissementReceptionCommune: ele[12] || null,
      etablissementElimination: ele[13] || null,
      etablissementEliminationStreetAdress: ele[14] || null,
      etablissementEliminationPostalCode: ele[15].trim() || null,
      etablissementEliminationCommune: ele[16] || null,
      numeroNotification: ele[17] || null,
      isOutOfWasteStatut: parseOuiNonToBoolean(ele[18]),
    };

    results.push(value);
  });
  return { filteredArray: results, errorMessage: null };
};

const isWasteInReferentiel = (
  waste: DechetProduitDto23Now,
  referentiel: ReferenceItemDechetDto[]
) => {
  if (!waste.codeID) {
    return false;
  }

  const wasteRef = referentiel.find(element => element.uuid === waste.codeID);
  return wasteRef !== undefined;
};

export const generateProductedWasteErrors = (
  waste: DechetProduitDto23Now,
  referentiel: WasteReferentiels
): Errors<DechetProduitDto23Now> => {
  const errors: Errors<DechetProduitDto23Now> = {};
  if (!isWasteInReferentiel(waste, referentiel.polluants)) {
    errors.codeID = requiredMessage;
  }
  if (!waste.lieuOperation) {
    errors.lieuOperation = requiredMessage;
  }
  if (waste.quantite === null) {
    errors.quantite = requiredMessage;
  }
  const method =
    waste.method === null
      ? null
      : findElementMatchingTemplate(
          { backOfficeCode: waste.method },
          referentiel.methods
        );
  if (!method) {
    errors.method = requiredMessage;
  } else {
    const availableMethodRefs = computeRefMethodForMethod(method);
    if (
      availableMethodRefs.length > 0 &&
      (!waste.methodReference ||
        !availableMethodRefs.includes(waste.methodReference))
    ) {
      errors.methodReference = requiredMessage;
    }
  }

  if (
    waste.operationElimination === null ||
    !findElementMatchingTemplate(
      { code: waste.operationElimination },
      referentiel.operationOrValorisationForProduction
    )
  ) {
    errors.operationElimination = requiredMessage;
  }
  if (!waste.lieuOperation) {
    if (!waste.nomEtablissementReception) {
      errors.nomEtablissementReception = requiredMessage;
    }
    if (!waste.streetAdresseEtablissementReception) {
      errors.streetAdresseEtablissementReception = requiredMessage;
    }
    if (!waste.postalCodeEtablissementReception) {
      errors.postalCodeEtablissementReception = requiredMessage;
    }
    if (!waste.communeEtablissementReception) {
      errors.communeEtablissementReception = requiredMessage;
    }
    if (!waste.nomEtablissementElimination) {
      errors.nomEtablissementElimination = requiredMessage;
    }
    if (!waste.streetAdresseEtablissementElimination) {
      errors.streetAdresseEtablissementElimination = requiredMessage;
    }
  } else if (
    waste.lieuOperation === DechetProduitDto23NowLieuOperationEnum.SITE
  ) {
    if (waste.sortiDuStatutDeDechet === null) {
      errors.sortiDuStatutDeDechet = requiredMessage;
    }
  } else if (
    waste.lieuOperation === DechetProduitDto23NowLieuOperationEnum.DEPARTEMENT
  ) {
    if (!waste.departementID) {
      errors.departementID = requiredMessage;
    } else if (
      findElementMatchingTemplate(
        { uuid: waste.departementID },
        referentiel.departements
      )
    ) {
      if (
        !waste.nomEtablissementReception ||
        waste.nomEtablissementReception.trim().length === 0
      ) {
        errors.nomEtablissementReception = requiredMessage;
      }
      if (
        !waste.streetAdresseEtablissementReception ||
        waste.streetAdresseEtablissementReception.trim().length === 0
      ) {
        errors.streetAdresseEtablissementReception = requiredMessage;
      }
      if (
        !waste.postalCodeEtablissementReception ||
        !isFrenchPostalCode(waste.postalCodeEtablissementReception)
      ) {
        errors.postalCodeEtablissementReception = codePostalFrancaisMessage;
      }
      if (
        !waste.communeEtablissementReception ||
        waste.communeEtablissementReception.trim().length === 0
      ) {
        errors.communeEtablissementReception = requiredMessage;
      }
    } else {
      errors.departementID = requiredMessage;
    }
  } else if (
    waste.lieuOperation === DechetProduitDto23NowLieuOperationEnum.PAYS
  ) {
    if (!waste.paysID) {
      errors.paysID = requiredMessage;
    } else {
      const optionCountry = findElementMatchingTemplate(
        {
          uuid: waste.paysID,
        },
        referentiel.pays
      );
      if (!optionCountry) {
        errors.paysID = requiredMessage;
      }
    }
    if (
      !waste.nomEtablissementReception ||
      waste.nomEtablissementReception.trim().length === 0
    ) {
      errors.nomEtablissementReception = requiredMessage;
    }

    if (
      !waste.streetAdresseEtablissementReception ||
      waste.streetAdresseEtablissementReception.trim().length === 0
    ) {
      errors.streetAdresseEtablissementReception = requiredMessage;
    }

    if (
      waste.firstReceptionFrance === true &&
      (!waste.postalCodeEtablissementReception ||
        !isFrenchPostalCode(waste.postalCodeEtablissementReception))
    ) {
      errors.postalCodeEtablissementReception = codePostalFrancaisMessage;
    }

    if (
      !waste.firstReceptionFrance &&
      (!waste.postalCodeEtablissementReception ||
        waste.postalCodeEtablissementReception.trim().length > 10)
    ) {
      errors.postalCodeEtablissementReception = codePostalEtrangerMessage;
    }

    if (
      !waste.communeEtablissementReception ||
      waste.communeEtablissementReception.trim().length === 0
    ) {
      errors.communeEtablissementReception = requiredMessage;
    }

    if (
      !waste.nomEtablissementElimination ||
      waste.nomEtablissementElimination.trim().length === 0
    ) {
      errors.nomEtablissementElimination = requiredMessage;
    }

    if (
      !waste.streetAdresseEtablissementElimination ||
      waste.streetAdresseEtablissementElimination.trim().length === 0
    ) {
      errors.streetAdresseEtablissementElimination = requiredMessage;
    }
    if (
      !waste.postalCodeEtablissementElimination ||
      waste.postalCodeEtablissementElimination.trim().length > 10
    ) {
      errors.postalCodeEtablissementElimination = codePostalEtrangerMessage;
    }
    if (
      !waste.communeEtablissementElimination ||
      waste.communeEtablissementElimination.trim().length === 0
    ) {
      errors.communeEtablissementElimination = requiredMessage;
    }
  }
  if (
    waste.lieuOperation &&
    !findElementMatchingTemplate(
      {
        backCode: waste.lieuOperation,
      },
      referentiel.productionLocation
    )
  ) {
    errors.lieuOperation = "cette valeur n'est pas dans le référentiel";
  }

  return errors;
};

export const isFrenchPostalCode = (postalCode: string): boolean => {
  const codePostalFrançais = /([0-9]){5}/;
  return codePostalFrançais.test(postalCode.trim());
};
