import React from "react";
import {
  AirCombustionEmissionBilanDto1819,
  AirCombustionEmissionDto1819,
  AirCombustionEmissionFacteurDto,
  AirCombustionEmissionMesureDto1819,
  ReferenceItemPolluantDto,
} from "api/gen";
import { computeH32 } from "./calculus";
import {
  getAppareilsLabels,
  getAppareilsToOptionProps,
} from "../../../utils/utils";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import {
  CombustibleInArray,
  CombustibleInModale,
} from "../../blocCombustibles/utils/types";
import {
  EmissionsInModale,
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { arrayMethods, MethodCombustion } from "./selectPossibleValues";
import { AppareilInArray } from "../../blocAppareils/utils/types";
import { ReferentialSinglePolluants } from "../../../utils/types";
import { singleEmissionOverwriteDependantFields } from "../validation/validation";
import cloneDeep from "lodash.clonedeep";

export const createAppareilDiv = (emission: OptionProps[]) => {
  const appareilsName = emission
    .map(emission => {
      return emission.label;
    })
    .join(", ");
  return <p title={appareilsName}>{appareilsName}</p>;
};

const getSubstanceIdByName = (
  substances: OptionPropsWithObject<ReferenceItemPolluantDto, string>[],
  substanceName: string
) => {
  const substance = findElementMatchingTemplate(
    { object: { nom: substanceName } },
    substances
  );
  return substance ? substance.object.uuid : null;
};

//=============================================================
// EMISSIONS
//==========FACTEUR
//=============================================================
const getCombustible = (
  facteur: AirCombustionEmissionFacteurDto,
  combustibles: CombustibleInArray[],
  installationName: string | null
): CombustibleInArray | null => {
  const combustible = combustibles.find(combustible => {
    return (
      combustible.data.id === facteur.combustibleDynId &&
      combustible.data.nameInstallation !== null &&
      installationName === combustible.data.nameInstallation
    );
  });
  if (combustible) {
    return combustible;
  }
  return null;
};

export const getEmissionsAnnuelles = (
  facteur: number | null,
  facteurOxydation: number | null,
  combustible: CombustibleInModale | null
): number | null => {
  if (combustible && facteur !== null) {
    return computeH32(facteur, facteurOxydation, combustible);
  }
  return null;
};

const convertFacteurToDisplayed = (
  facteurs: AirCombustionEmissionFacteurDto[],
  combustibles: CombustibleInArray[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[],
  appareilsReferential: AppareilInArray[]
): FacteurEmissionInArray[] => {
  return facteurs.map(facteur => {
    const combustible = getCombustible(
      facteur,
      combustibles,
      facteur.installation
    );

    const substance =
      facteur.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { object: { uuid: facteur.substanceID } },
            substanceReferential
          );

    const appareils = getAppareilsToOptionProps(
      facteur.appareils,
      appareilsReferential
    );

    const method = findElementMatchingTemplate(
      { object: { value: MethodCombustion.FACTEUR_EMISSION } },
      arrayMethods
    );

    return {
      //TODO : compute errors
      data: {
        id: facteur.id,
        nameInstallation: facteur.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: facteur.epuration,
        nature: facteur.nature,
        rendement: facteur.rendement,
        emissionsAnnuelles: getEmissionsAnnuelles(
          facteur.facteur,
          facteur.facteurOxydation,
          combustible && combustible.data
        ),
        // specific
        combustible: combustible
          ? {
              value: combustible.data.id,
              label: combustible.data.type ? combustible.data.type.label : "",
              object: combustible,
            }
          : null,
        consoAnnuelle: combustible ? combustible.data.consommation : null,
        referenceCombustible: combustible ? combustible.data.code : null,
        unite:
          combustible && combustible.data.unite
            ? combustible.data.unite.label
            : null,
        facteur: facteur.facteur,
        ecart: facteur.ecart,
        provenance: facteur.provenance,
        facteurOxydation: facteur.facteurOxydation,
        provenanceFacteurOxydation: facteur.provenanceFacteurOxydation,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionFacteurDto = (
  facteursInPage: FacteurEmissionInArray[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[]
): AirCombustionEmissionFacteurDto[] => {
  const facteurs = facteursInPage.map(singlePopulatedFacteur => {
    const singleFacteur = singlePopulatedFacteur.data;
    const singleFacteurDto: AirCombustionEmissionFacteurDto = {
      id: singlePopulatedFacteur.data.id,
      installation: singleFacteur.nameInstallation,
      appareils: getAppareilsLabels(singleFacteur.appareils),
      combustibleDynId:
        singleFacteur.combustible && singleFacteur.combustible.object
          ? singleFacteur.combustible.object.data.id
          : "",
      ecart: singleFacteur.ecart,
      epuration: !!singleFacteur.epuration,
      facteur: singleFacteur.facteur,
      facteurOxydation: singleFacteur.facteurOxydation,
      nature: singleFacteur.nature,
      provenance: singleFacteur.provenance,
      provenanceFacteurOxydation: singleFacteur.provenanceFacteurOxydation,
      rendement: singleFacteur.rendement,
      substanceID: singleFacteur.substance
        ? getSubstanceIdByName(
            substanceReferential,
            singleFacteur.substance.label
          )
        : null,
    };
    return singleFacteurDto;
  });
  return facteurs;
};

//=============================================================
// EMISSIONS
//==========MESURE
//=============================================================
export const convertMesureToDisplayed = (
  mesures: AirCombustionEmissionMesureDto1819[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[],
  appareilsReferential: AppareilInArray[]
): MesureEmissionInArray[] => {
  return mesures.map(mesure => {
    const substance =
      mesure.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { object: { uuid: mesure.substanceID } },
            substanceReferential
          );

    const appareils = getAppareilsToOptionProps(
      mesure.appareils,
      appareilsReferential
    );

    const method = findElementMatchingTemplate(
      { object: { value: MethodCombustion.MESURE } },
      arrayMethods
    );

    return {
      data: {
        //general
        nameInstallation: mesure.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: mesure.epuration,
        nature: mesure.nature,
        rendement: mesure.rendement,
        emissionsAnnuelles:
          (mesure.debit.debit || 0) *
          (mesure.heures || 0) *
          (mesure.debit.concentration || 0),
        //specific
        heures: mesure.heures,
        debit: mesure.debit.debit,
        continuDebit: mesure.debit.continu,
        frequenceDebit: mesure.debit.frequence,
        concentrationDebit: mesure.debit.concentration,
        continuConcentration: mesure.concentration.continu,
        frequenceConcentration: mesure.concentration.frequence,
        biomasseMesure: mesure.biomasse,
        id: mesure.id,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionMesureDto = (
  mesuresInPage: MesureEmissionInArray[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[]
) => {
  const mesure = mesuresInPage.map(singlePopulatedMesure => {
    const singleMesure = singlePopulatedMesure.data;
    const singleMesureDto: AirCombustionEmissionMesureDto1819 = {
      id: singlePopulatedMesure.data.id,
      installation: singleMesure.nameInstallation,
      appareils: getAppareilsLabels(singleMesure.appareils),
      biomasse: singleMesure.biomasseMesure,
      concentration: {
        continu: !!singleMesure.continuConcentration,
        frequence: singleMesure.frequenceConcentration,
      },
      debit: {
        concentration: singleMesure.concentrationDebit,
        continu: !!singleMesure.continuDebit,
        debit: singleMesure.debit,
        frequence: singleMesure.frequenceDebit,
      },
      epuration: !!singleMesure.epuration,
      heures: singleMesure.heures,
      nature: singleMesure.nature,
      rendement: singleMesure.rendement,
      substanceID: singleMesure.substance
        ? getSubstanceIdByName(
            substanceReferential,
            singleMesure.substance.label
          )
        : null,
    };
    return singleMesureDto;
  });
  return mesure;
};

//=============================================================
// EMISSIONS
//==========MATIERE
//=============================================================
export const convertMatiereToDisplayed = (
  matieres: AirCombustionEmissionBilanDto1819[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[],
  appareilsReferential: AppareilInArray[]
): MatiereEmissionInArray[] => {
  return matieres.map(matiere => {
    const substance =
      matiere.substanceID === null
        ? null
        : findElementMatchingTemplate(
            { object: { uuid: matiere.substanceID } },
            substanceReferential
          );

    const appareils = getAppareilsToOptionProps(
      matiere.appareils,
      appareilsReferential
    );

    const method = findElementMatchingTemplate(
      { object: { value: MethodCombustion.BILAN_MATIERE } },
      arrayMethods
    );

    return {
      data: {
        //general
        nameInstallation: matiere.installation,
        substance: substance,
        methods: method,
        appareils: appareils,
        epuration: matiere.epuration,
        nature: matiere.nature,
        rendement: matiere.rendement,
        emissionsAnnuelles:
          matiere.entrant !== null &&
          matiere.sortant !== null &&
          matiere.teneur !== null &&
          matiere.part
            ? ((matiere.entrant - matiere.sortant) * matiere.teneur) /
              matiere.part
            : 0,
        //specific
        intrants: matiere.intrants,
        entrant: matiere.entrant,
        sortant: matiere.sortant,
        element: matiere.element,
        teneur: matiere.teneur,
        part: matiere.part,
        biomasseMatiere: matiere.biomasse,
        id: matiere.id,
      },
      errors: {},
    };
  });
};

export const createAirCombustionEmissionMatiereDto = (
  matieresInPage: MatiereEmissionInArray[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[]
) => {
  const matiere = matieresInPage.map(singlePopulatedMatiere => {
    const singleMatiere = singlePopulatedMatiere.data;
    const singleMatiereDto: AirCombustionEmissionBilanDto1819 = {
      id: singlePopulatedMatiere.data.id,
      installation: singleMatiere.nameInstallation,
      appareils: getAppareilsLabels(singleMatiere.appareils),
      biomasse: singleMatiere.biomasseMatiere,
      element: singleMatiere.element,
      entrant: singleMatiere.entrant,
      epuration: !!singleMatiere.epuration,
      intrants: singleMatiere.intrants,
      nature: singleMatiere.nature,
      part: singleMatiere.part,
      rendement: singleMatiere.rendement,
      sortant: singleMatiere.sortant,
      substanceID: singleMatiere.substance
        ? getSubstanceIdByName(
            substanceReferential,
            singleMatiere.substance.label
          )
        : null,
      teneur: singleMatiere.teneur,
    };
    return singleMatiereDto;
  });
  return matiere;
};

//=============================================================
// EMISSIONS
//=============================================================
type ExportedEmissions = [
  FacteurEmissionInArray[],
  MesureEmissionInArray[],
  MatiereEmissionInArray[]
];

export const convertEmissionsToDisplayed = (
  emissions: AirCombustionEmissionDto1819,
  combustible: CombustibleInArray[],
  substanceReferential: OptionPropsWithObject<
    ReferenceItemPolluantDto,
    string
  >[],
  appareilsReferential: AppareilInArray[]
): ExportedEmissions => {
  const facteurs = convertFacteurToDisplayed(
    emissions.facteurs,
    combustible,
    substanceReferential,

    appareilsReferential
  );
  const mesures = convertMesureToDisplayed(
    emissions.mesures,
    substanceReferential,
    appareilsReferential
  );
  const matieres = convertMatiereToDisplayed(
    emissions.bilans,
    substanceReferential,
    appareilsReferential
  );
  return [facteurs, mesures, matieres];
};

export const createAirCombustionEmissionDto = (
  [facteursInPage, mesuresInPage, matieresInPage]: ExportedEmissions,
  substances: OptionPropsWithObject<ReferenceItemPolluantDto, string>[]
): AirCombustionEmissionDto1819 => {
  const emissionDto: AirCombustionEmissionDto1819 = {
    facteurs: createAirCombustionEmissionFacteurDto(facteursInPage, substances),
    mesures: createAirCombustionEmissionMesureDto(mesuresInPage, substances),
    bilans: createAirCombustionEmissionMatiereDto(matieresInPage, substances),
  };
  return emissionDto;
};

//=============================================================
// EMISSIONS
//==========UPDATES
//=============================================================

export const defaultEmissionInModaleInitialValue: EmissionsInModale = {
  appareils: null,
  biomasseMatiere: null,
  biomasseMesure: null,
  combustible: null,
  concentrationDebit: null,
  consoAnnuelle: null,
  continuConcentration: false,
  continuDebit: false,
  debit: null,
  ecart: null,
  element: null,
  emissionsAnnuelles: null,
  entrant: null,
  epuration: false,
  facteur: null,
  facteurOxydation: null,
  frequenceConcentration: null,
  frequenceDebit: null,
  heures: null,
  intrants: null,
  methods: null,
  nameInstallation: null,
  nature: null,
  part: null,
  provenance: null,
  provenanceFacteurOxydation: null,
  referenceCombustible: null,
  rendement: null,
  sortant: null,
  substance: null,
  teneur: null,
  unite: null,
};

export const computeEmissionsAnnuelles = (
  values: EmissionsInModale
): number | null => {
  if (values.methods && values.methods.label === "Facteur d'émission") {
    if (values.combustible) {
      return computeH32(
        values.facteur,
        values.facteurOxydation,
        values.combustible.object.data
      );
    }
    return 0;
  } else if (values.methods && values.methods.label === "Mesure") {
    return (
      (values.debit || 0) *
      (values.heures || 0) *
      (values.concentrationDebit || 0)
    );
  } else {
    return values.entrant !== null &&
      values.sortant !== null &&
      values.teneur !== null &&
      values.part
      ? ((values.entrant - values.sortant) * values.teneur) / values.part
      : 0;
  }
};

export const shouldDisplayEcartEmission = (
  values: EmissionsInModale,
  referentialSinglePolluants: ReferentialSinglePolluants
): boolean => {
  if (
    values.combustible &&
    values.combustible.object &&
    values.combustible.object.data &&
    values.combustible.object.data.type &&
    values.substance
  ) {
    const combustible = values.combustible.object.data.type.object;
    return (
      (values.substance.label === referentialSinglePolluants.CO2.nom &&
        combustible.infCO2 !== null &&
        combustible.supCO2 !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infCO2 ||
          values.facteur > combustible.supCO2)) ||
      (values.substance.label === referentialSinglePolluants.SOX.nom &&
        combustible.infSOX !== null &&
        combustible.supSOX !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infSOX ||
          values.facteur > combustible.supSOX)) ||
      (values.substance.label === referentialSinglePolluants.NOX.nom &&
        combustible.infNOX !== null &&
        combustible.supNOX !== null &&
        values.facteur !== null &&
        (values.facteur < combustible.infNOX ||
          values.facteur > combustible.supNOX))
    );
  } else {
    return false;
  }
};

export const updateEmission = (
  emission: EmissionsInModale,
  installationName: string | null,
  referentialSinglePolluants: ReferentialSinglePolluants
): EmissionsInModale => {
  const newEmission = cloneDeep(emission);
  newEmission.emissionsAnnuelles = computeEmissionsAnnuelles(emission);
  newEmission.nameInstallation = installationName || "";
  newEmission.referenceCombustible = emission.combustible
    ? emission.combustible.object.data.code
    : "";
  newEmission.consoAnnuelle =
    emission.combustible &&
    emission.combustible.object.data.consommation !== null
      ? emission.combustible.object.data.consommation
      : null;
  newEmission.unite =
    emission.combustible && emission.combustible.object.data.unite
      ? emission.combustible.object.data.unite.label
      : "";
  if (!shouldDisplayEcartEmission(emission, referentialSinglePolluants)) {
    newEmission.ecart = null;
  }
  singleEmissionOverwriteDependantFields(
    newEmission,
    referentialSinglePolluants.CO2
  );
  return newEmission;
};
