import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import Row from "./Row";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  spinner: props => ({
    justifyContent: "center",
    alignItems: "center",
    width: props.width && `${props.width}px`,
  }),
});

interface SpinnerProps {
  size?: SizeProp;
  width?: number;
  height?: number;
  additionalClassname?: string;
}

const Spinner = ({
  size,
  width,
  height,
  additionalClassname,
}: SpinnerProps) => {
  const classes = useStyles({ width: width });

  return (
    <Row
      height="auto"
      additionalClassname={classNames(classes.spinner, additionalClassname)}
    >
      <FontAwesomeIcon
        icon="spinner"
        spin
        size={size ? size : "2x"}
        style={{ height: height && `${height}px` }}
      />
    </Row>
  );
};

export default Spinner;
