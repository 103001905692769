import React from "react";
import { components, SingleValueProps } from "react-select";
import { OptionTypeBase } from "./TypesPatternAdapter";
import { MultiValueGenericProps } from "react-select/src/components/MultiValue";
import TooltipLabel from "./TooltipLabel";

export const MultiValueLabel = <T extends OptionTypeBase>({
  ...props
}: MultiValueGenericProps<T>): React.ReactElement => {
  return (
    <TooltipLabel content={<span>{props.data.label}</span>}>
      <components.MultiValueLabel {...props} />
    </TooltipLabel>
  );
};

export const SingleValue = <T extends OptionTypeBase>({
  ...props
}: SingleValueProps<T>): React.ReactElement => {
  return (
    <TooltipLabel content={<span>{props.data.label}</span>}>
      <components.SingleValue {...props} />
    </TooltipLabel>
  );
};
