import { makeStyles } from "@material-ui/styles";
import {
  SimpleDeclarationDto,
  SimpleDenormalizedDtoQuotaEmissionsStateEnum,
} from "api/gen";
import classNames from "classnames";
import { PATH_DASHBOARD } from "common/path/path18Now";
import { findElementMatchingTemplate } from "common/utils/methods";
import { roundPathProgression } from "pages/CompanySpace/from19/to19/Dashboard/DeclarationSummary/utils";
import React, { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { LINK_STYLE } from "theme";
import {
  CommonDeclarationStateEnum,
  DeclarationState,
  stateOptions,
} from "../FilterField/helpers/declarationStates";
import DeclarationActionButtons from "./DeclarationActionButtons";
import { getTimeRightFormat } from "../../../../common/utils/DateUtils";
import jaugeLogo from "icons/jauge.svg";
import { MIN_YEAR } from "pages/CompanySpace/from24/toNow/versionedElements/declarationHooks24Now";

const useStyles = makeStyles({
  link: {
    ...LINK_STYLE,
  },
  state: {
    borderRadius: "4px",
    padding: "4px",
    width: "100%",
  },
  img: {
    height: "30%",
    width: "30%",
  },
});

export const useDeclarationTableLines = (
  declarations: SimpleDeclarationDto[],
  onContactInfosClick: (declaration: SimpleDeclarationDto) => void,
  isInspecteur: boolean
): React.ReactElement[][] => {
  const classes = useStyles();
  const computeStateOption = (
    state: CommonDeclarationStateEnum,
    rienADeclarer: boolean
  ): DeclarationState | null => {
    return findElementMatchingTemplate(
      {
        state,
        rienADeclarer:
          state === SimpleDenormalizedDtoQuotaEmissionsStateEnum.SUBMITTED
            ? rienADeclarer
            : null,
      },
      stateOptions
    );
  };

  const buildState = useCallback(
    (option: DeclarationState | null): React.ReactElement => {
      return (
        <p
          title={option ? option.title : "L'état actuel n'est pas spécifié..."}
          className={classNames(classes.state)}
          style={{
            backgroundColor: option ? option.backgroundColor : "white",
            color: option ? option.textColor : "black",
          }}
        >
          {option ? option.label : "État non spécifié"}
        </p>
      );
    },
    [classes.state]
  );

  const buildSingleDeclarationLine = useCallback(
    (
      declaration: SimpleDeclarationDto,
      isInspecteur: boolean
    ): React.ReactElement[] => {
      const generalOption = computeStateOption(
        declaration.simpleDenormalizedDto.generalState,
        declaration.simpleDenormalizedDto.rienADeclarer
      );

      const quotaAllocationsOption = computeStateOption(
        declaration.simpleDenormalizedDto.quotaAllocationsState,
        declaration.simpleDenormalizedDto.rienADeclarer
      );
      const quotaEmissionsOption = computeStateOption(
        declaration.simpleDenormalizedDto.quotaEmissionsState,
        declaration.simpleDenormalizedDto.rienADeclarer
      );

      return [
        <Link
          to={`/declaration/${declaration.annee}/${declaration.etablissement}${PATH_DASHBOARD}`}
          className={classes.link}
        >
          {declaration.etablissementName}
        </Link>,
        <p>
          {declaration.commune
            ? declaration.commune.charAt(0).toUpperCase() +
              declaration.commune.slice(1).toLowerCase()
            : ""}
        </p>,
        <p>{declaration.annee}</p>,
        <p>
          {`${roundPathProgression(
            declaration.simpleDenormalizedDto.declarationProgression
          )}%`}
        </p>,
        buildState(generalOption),
        declaration.simpleDenormalizedDto.quotas ? (
          buildState(quotaEmissionsOption)
        ) : (
          <p></p>
        ),
        declaration.simpleDenormalizedDto.quotasAllocations ? (
          buildState(quotaAllocationsOption)
        ) : (
          <p></p>
        ),
        <p>
          {declaration.simpleDenormalizedDto.depassementDeSeuil ? (
            <img
              src={jaugeLogo}
              alt="Depassement de Seuil Logo"
              className={classes.img}
            />
          ) : declaration.annee < MIN_YEAR ? ( // Le depassement de seuil est pour 24Now, le tableau n'est pas versionné.
            "non disponible"
          ) : (
            ""
          )}
        </p>,
        <p>
          {declaration.lastTimeEditedDeclaringGlobal &&
            getTimeRightFormat(declaration.lastTimeEditedDeclaringGlobal)}
        </p>,
        <p>
          {declaration.lastTimeEditedInspectorGlobal &&
            getTimeRightFormat(declaration.lastTimeEditedInspectorGlobal)}
        </p>,
        <p>
          {declaration.lastTimeEditedDeclaringAllocation &&
            getTimeRightFormat(declaration.lastTimeEditedDeclaringAllocation)}
        </p>,
        <p>
          {declaration.lastTimeEditedInspectorAllocation &&
            getTimeRightFormat(declaration.lastTimeEditedInspectorAllocation)}
        </p>,
        <p>
          {declaration.lastTimeEditedDeclaringEmission &&
            getTimeRightFormat(declaration.lastTimeEditedDeclaringEmission)}
        </p>,
        <p>
          {declaration.lastTimeEditedInspectorEmission &&
            getTimeRightFormat(declaration.lastTimeEditedInspectorEmission)}
        </p>,

        <DeclarationActionButtons
          declaration={declaration}
          onContactInfosClick={onContactInfosClick}
          isInspecteur={isInspecteur}
        />,
      ];
    },
    [classes.link, buildState, onContactInfosClick]
  );

  return useMemo(
    () =>
      declarations.map(declaration =>
        buildSingleDeclarationLine(declaration, isInspecteur)
      ),
    [declarations, buildSingleDeclarationLine, isInspecteur]
  );
};
