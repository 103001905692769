import React from "react";
import logo from "icons/pageLogos/flask.svg";
import MainSolvForm from "./MainSolvForm";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { RouteIdProps } from "common/declarant/type";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Solvants = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Solvants / PGS",
      to: match.url,
    },
  ];
  const imageData = {
    src: logo,
    alt: " fioles de chimie",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Solvants / PGS",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_POLLUANTS_AIR", "REFERENCE_COVNM"]}
      render={referentials => {
        return (
          <MainSolvForm
            referential={referentials.REFERENCE_POLLUANTS_AIR}
            referentialCOVNM={referentials.REFERENCE_COVNM}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Solvants, "GLOBAL");
