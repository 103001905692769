import React from "react";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
} from "../../CommonSpace/AppPage/PageHeaderContext";
import { RouteIdProps } from "common/declarant/type";
import { DeclarationsManagementController } from "./DeclarationsManagementController";
import { useUserData } from "../../../Authenticator/UserData";

const DeclarationsDashboard = ({ match }: RouteIdProps): React.ReactElement => {
  const { isAnyPrestataire } = useUserData();
  const pagePath = isAnyPrestataire
    ? [
        {
          label: "Déclarations",
          to: match.url,
        },
      ]
    : [
        {
          label: "Accueil",

          to: "/accueil",
        },
        { label: "Déclarations", to: match.url },
      ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord des déclarations",
    image: null,
  };
  usePageHeaderSetterOnce(headerData);

  return <DeclarationsManagementController />;
};

export default DeclarationsDashboard;
