import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleEmission from "./FormSingleEmission";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  EmissionsInArray,
  InputEmissionsInModale,
  OutputEmissionsInModale,
} from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { addOrModifyArray } from "common/declarant/array/utils";

interface EmissionModaleProps {
  isOpen: boolean;
  emissionInModal: EmissionsInArray | null;
  setEmissionInPage: Dispatch<SetStateAction<EmissionsInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  emissionInPage: EmissionsInArray[];
  initialInstallations: InstallationInArray[];
  isSpecific: boolean;
  substances: ReferenceItemPolluantDto[];
  referentialCOVNM: ReferenceItemPolluantDto;
}

const EmissionModale = ({
  isOpen,
  emissionInModal,
  setEmissionInPage,
  setEmissionInModale,
  setModaleOpen,
  emissionInPage,
  initialInstallations,
  isSpecific,
  substances,
  referentialCOVNM,
}: EmissionModaleProps): React.ReactElement => {
  const onClose = () => {
    setEmissionInModale(null);
    setModaleOpen(false);
  };

  const defaultInitialValue: InputEmissionsInModale = {
    consommationSolvant: null,
    emission: null,
    emissionDiffuses: null,
    emissionTotale: null,
    emissionTotales: null,
    epuration: false,
    i1: null,
    i2: null,
    installations: null,
    natureEquipement: null,
    o1: null,
    o2: null,
    o3: null,
    o4: null,
    o5: null,
    o6: null,
    o7: null,
    o8: null,
    o9: null,
    pgsSimple: false,
    rendement: null,
    substance: null,
    utilisationSolvant: null,
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'emissions."
      onRequestClose={onClose}
    >
      <FormSingleEmission
        referentialCOVNM={referentialCOVNM}
        substances={substances}
        isSpecific={isSpecific}
        installationsInPage={initialInstallations}
        emissionsInArray={emissionInPage}
        emissionInModal={emissionInModal}
        closeFunction={onClose}
        onSubmit={values => {
          const newEmission: OutputEmissionsInModale = {
            installations: values.installations,
            substance: values.substance,
            pgsSimple: values.pgsSimple,
            i1: values.i1,
            i2: values.i2,
            o1: values.o1,
            o2: values.o2,
            o3: values.o3,
            o4: values.o4,
            o5: values.o5,
            o6: values.o6,
            o7: values.o7,
            o8: values.o8,
            o9: values.o9,
            emission: values.emission,
            emissionTotale: values.emissionTotale,
            consommationSolvant: values.consommationSolvant,
            utilisationSolvant: values.utilisationSolvant,
            epuration: values.epuration,
            natureEquipement: values.natureEquipement,
            rendement: values.rendement,
          };
          addOrModifyArray(
            setEmissionInPage,
            emissionInModal && emissionInModal.data.id,
            newEmission
          );
          onClose();
        }}
        initialEmission={
          emissionInModal !== null
            ? { ...defaultInitialValue, ...emissionInModal.data }
            : defaultInitialValue
        }
        isEdit={emissionInModal !== null}
      />
    </CustomModal>
  );
};

export default EmissionModale;
