import {
  DeclarationStateDtoStateEnum,
  FileDto,
  QuotasAllocationsBlocVerificationDto20Now,
  QuotasAllocationsVerificationDto20Now,
  QuotasInstallationDto20Now,
} from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import {
  BlocVerificationFormValues,
  BlocVerificationInstallationFormValues,
  BlocVerifState,
} from "./types";
import {
  VERIFICATION_BLOC_NOT_VERIFIED_YET,
  VERIFICATION_BLOC_PENDING_VERIFICATION,
  VERIFICATION_BLOC_VERIFIED,
  VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION,
} from "pages/CompanySpace/from21/toNow/Quotas/helpers";
import { Declaration2323 } from "../../../versionedElements/declarationHooks2323";

export const quotasStateToBlockVerifState = (
  quotasState: DeclarationStateDtoStateEnum
): BlocVerifState => {
  switch (quotasState) {
    case DeclarationStateDtoStateEnum.VERIFYING:
      return BlocVerifState.IN_PROGRESS;
    case DeclarationStateDtoStateEnum.NONE:
    case DeclarationStateDtoStateEnum.CREATED:
    case DeclarationStateDtoStateEnum.STARTED:
    case DeclarationStateDtoStateEnum.IN_REVIEW:
      return BlocVerifState.UNKNOWN;
    case DeclarationStateDtoStateEnum.FORCED:
      return BlocVerifState.IGNORED;
    case DeclarationStateDtoStateEnum.RECALLED:
    case DeclarationStateDtoStateEnum.SUBMITTED:
    case DeclarationStateDtoStateEnum.VALIDATED:
    case DeclarationStateDtoStateEnum.VERIFIED:
      return BlocVerifState.COMPLETED;
    default:
      throw new ShouldNotHappen(quotasState);
  }
};

export const getInaccessibleVerificationMessage = (
  blocVerifStatus: BlocVerifState
): string => {
  switch (blocVerifStatus) {
    case BlocVerifState.IGNORED:
      return VERIFICATION_BLOC_VERIFIED_COMPULSORY_DECLARATION;
    case BlocVerifState.COMPLETED:
      return VERIFICATION_BLOC_VERIFIED;
    case BlocVerifState.IN_PROGRESS:
      return VERIFICATION_BLOC_PENDING_VERIFICATION;
    default:
      return VERIFICATION_BLOC_NOT_VERIFIED_YET;
  }
};

export const installationVerificationToDTO = (
  installation: BlocVerificationInstallationFormValues,
  rapport: FileDto | null
): QuotasAllocationsVerificationDto20Now => {
  return {
    id: installation.installationId,
    organisme: installation.organisme,
    referenceDernierPlanSurveillance: installation.monitoringPlanReference,
    dispenseVisiteSite: installation.visitExemption,
    conditionDispense: installation.conditionExemption,
    precisionDispense: installation.exemptionDetail,
    dateDerniereVisite: installation.lastVisitDate
      ? installation.lastVisitDate.toISOString().split(".")[0] + "Z"
      : null,
    visiteCombinee: installation.activityAndEmissionVisit,
    conclusion: installation.verificationReportConclusion,
    motifsNonSatisfaisant: installation.unsatisfyingDeclarationReason,
    motifsStatisfaisantAvecRemarque: installation.satisfyingDeclarationReason,
    nimDynId: installation.installationId,
    rapport,
  };
};

export const verificationFormToDTO = (
  formValues: BlocVerificationFormValues,
  declaration: Declaration2323
): QuotasAllocationsBlocVerificationDto20Now => {
  return {
    verifications: formValues.installations.map(installation => {
      const declarationInstallationVerification = declaration.body.sections.quotas.allocations.blocVerification.verifications.find(
        verification => verification.nimDynId === installation.installationId
      );
      const rapport = declarationInstallationVerification
        ? declarationInstallationVerification.rapport
        : null;
      return installationVerificationToDTO(installation, rapport);
    }),
  };
};

const installationValidationDtoToFormValue = (
  verification: QuotasAllocationsVerificationDto20Now
): BlocVerificationInstallationFormValues => ({
  installationId: verification.id,
  organisme: verification.organisme,
  monitoringPlanReference: verification.referenceDernierPlanSurveillance,
  visitExemption: verification.dispenseVisiteSite,
  conditionExemption: verification.conditionDispense,
  exemptionDetail: verification.precisionDispense,
  lastVisitDate: verification.dateDerniereVisite
    ? new Date(verification.dateDerniereVisite)
    : null,
  activityAndEmissionVisit: verification.visiteCombinee,
  verificationReportConclusion: verification.conclusion,
  satisfyingDeclarationReason: verification.motifsStatisfaisantAvecRemarque,
  unsatisfyingDeclarationReason: verification.motifsNonSatisfaisant,
});

export const blockDtoToBlockFormValues = (
  dto: QuotasAllocationsBlocVerificationDto20Now,
  installationsInArray: QuotasInstallationDto20Now[]
): BlocVerificationFormValues => {
  return {
    installations: installationsInArray.map(installation => {
      const verification: QuotasAllocationsVerificationDto20Now | null =
        dto.verifications.find(v => v.nimDynId === installation.id) || null;

      if (verification) {
        return installationValidationDtoToFormValue(verification);
      } else {
        return {
          installationId: installation.id,
          organisme: null,
          monitoringPlanReference: null,
          visitExemption: false,
          conditionExemption: null,
          exemptionDetail: null,
          lastVisitDate: null,
          activityAndEmissionVisit: false,
          verificationReportConclusion: null,
          satisfyingDeclarationReason: [],
          unsatisfyingDeclarationReason: [],
        };
      }
    }),
  };
};
