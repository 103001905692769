import { makeStyles } from "@material-ui/styles";
import React from "react";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { InstallationInArray } from "./types";
import _ from "lodash";
import { PATH_QUOTAS_INSTALLATION } from "common/path/path1819";

const useStyles = makeStyles({
  lineBreak: {
    whiteSpace: "pre-line",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface InstallationsArrayProps {
  installationsInArray: InstallationInArray[];
  setInstallationsInArray: (installations: InstallationInArray[]) => void;
  openModal: (installation: InstallationInArray | null) => void;
  isDisabled: boolean;
}

const InstallationsArray = ({
  installationsInArray,
  setInstallationsInArray,
  openModal,
  isDisabled,
}: InstallationsArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const removeInstallation = (singleInstallation: InstallationInArray) => {
    setInstallationsInArray(
      installationsInArray.filter(
        singleElderInstallation =>
          singleElderInstallation.data.id !== singleInstallation.data.id
      )
    );
  };

  const computeLine = (
    installation: InstallationInArray
  ): React.ReactElement[] => {
    const editAction = () => {
      !isDisabled && openModal(installation);
    };

    const activiteString =
      installation.data.activites &&
      installation.data.activites.map(activite => activite.label).join(",\n");

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      installation.data.nim || undefined,
      isDisabled,
      !_.isEmpty(installation.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p>
          {installation.data.categorie && installation.data.categorie.label}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>{installation.data.faiblementEmettrice ? "Oui" : "Non"}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p title={activiteString || undefined} className={classes.lineBreak}>
          {activiteString}
        </p>,
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        commentPath={`${PATH_QUOTAS_INSTALLATION}/${installation.data.id}`}
        handlerSuppress={() => {
          removeInstallation(installation);
        }}
        isValidated={isDisabled}
      />,
    ];
  };

  return (
    <CommonEntityTable
      header={[
        <p>Numéro NIM</p>,
        <p>Catégorie de l'installation</p>,
        <p>Installation faiblement émettrice</p>,
        <p>Activités de l'installation</p>,
        <p>Actions</p>,
      ]}
      preferredColWidths={[120, 70, 70, 310, ACTION_COLUMN_DEFAULT_WIDTH]}
      lines={installationsInArray.map(computeLine)}
    />
  );
};

export default InstallationsArray;
