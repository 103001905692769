import React from "react";
import { makeStyles } from "@material-ui/styles";
import WasteSumUpArray from "./WasteSumUpArray";
import { WasteBasicStorage, WasteSumUpWrapperProps } from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import BigNumber from "bignumber.js";
import jaugeLogo from "icons/jauge.svg";
import { ComputedDechetTotalDto24Now } from "api/gen";

const useStyles = makeStyles({
  jaugeIcon: {
    width: "30px",
    marginBottom: "20px",
  },
});

const getTotalAdmisDepassementDeSeuilDangereux = (
  total: ComputedDechetTotalDto24Now | null
) => {
  return total !== null ? total.totalAdmis.depassementDeSeuilDangereux : false;
};

const getTotalAdmisDepassementDeSeuilNonDangereux = (
  total: ComputedDechetTotalDto24Now | null
) => {
  return total !== null
    ? total.totalAdmis.depassementDeSeuilNonDangereux
    : false;
};

const getTotalExpedieDepassementDeSeuilDangereux = (
  total: ComputedDechetTotalDto24Now | null
) => {
  return total !== null
    ? total.totalExpedie.depassementDeSeuilDangereux
    : false;
};

const getTotalExpedieDepassementDeSeuiNonDangereux = (
  total: ComputedDechetTotalDto24Now | null
) => {
  return total !== null
    ? total.totalExpedie.depassementDeSeuilNonDangereux
    : false;
};

const WasteSumUpWrapper = ({
  wasteData,
  isReceptionAvailable,
  computedWaste,
}: WasteSumUpWrapperProps): React.ReactElement => {
  const classes = useStyles();

  const dangerous: WasteBasicStorage = {
    made: new BigNumber(0),
    received: new BigNumber(0),
    depassementDeSeuilreceived: false,
    treated: new BigNumber(0),
    send: new BigNumber(0),
    depassementDeSeuilSend: false,
  };
  const normal: WasteBasicStorage = {
    made: new BigNumber(0),
    received: new BigNumber(0),
    depassementDeSeuilreceived: false,
    treated: new BigNumber(0),
    send: new BigNumber(0),
    depassementDeSeuilSend: false,
  };

  wasteData.tempData.production.container.forEach(waste => {
    const wasteOption = waste.data.codeID
      ? findElementMatchingTemplate(
          { uuid: waste.data.codeID },
          wasteData.referentiels.polluants
        )
      : null;
    let container: WasteBasicStorage;
    if (wasteOption && wasteOption.dangereux) {
      container = dangerous;
      container.depassementDeSeuilreceived = getTotalAdmisDepassementDeSeuilNonDangereux(
        computedWaste.totaux
      );
      container.depassementDeSeuilSend = getTotalExpedieDepassementDeSeuilDangereux(
        computedWaste.totaux
      );
    } else if (wasteOption) {
      container = normal;
      container.depassementDeSeuilreceived = getTotalAdmisDepassementDeSeuilNonDangereux(
        computedWaste.totaux
      );
      container.depassementDeSeuilSend = getTotalExpedieDepassementDeSeuiNonDangereux(
        computedWaste.totaux
      );
    } else {
      return;
    }

    if (waste.data.quantite !== null) {
      container.made = container.made.plus(new BigNumber(waste.data.quantite));
    }

    if (
      (waste.data.departementID || waste.data.paysID) &&
      waste.data.quantite !== null
    ) {
      container.send = container.send.plus(new BigNumber(waste.data.quantite));
    }
  });

  wasteData.tempData.reception.container.forEach(waste => {
    const wasteOption = waste.data.codeID
      ? findElementMatchingTemplate(
          { uuid: waste.data.codeID },
          wasteData.referentiels.polluants
        )
      : null;
    let container: WasteBasicStorage;
    if (wasteOption && wasteOption.dangereux) {
      container = dangerous;
      container.depassementDeSeuilreceived = getTotalAdmisDepassementDeSeuilNonDangereux(
        computedWaste.totaux
      );
      container.depassementDeSeuilSend = getTotalExpedieDepassementDeSeuilDangereux(
        computedWaste.totaux
      );
    } else if (wasteOption) {
      container = normal;
      container.depassementDeSeuilreceived = getTotalAdmisDepassementDeSeuilNonDangereux(
        computedWaste.totaux
      );
      container.depassementDeSeuilSend = getTotalExpedieDepassementDeSeuiNonDangereux(
        computedWaste.totaux
      );
    } else {
      return;
    }

    if (waste.data.quantiteAdmise !== null) {
      container.received = container.received.plus(
        new BigNumber(waste.data.quantiteAdmise)
      );
    }

    if (waste.data.quantiteTraitee !== null) {
      container.treated = container.treated.plus(
        new BigNumber(waste.data.quantiteTraitee)
      );
    }
  });

  return (
    <DummyUnActionedBloc
      title={"Bilan des mouvements de déchets"}
      renderContent={() => {
        return (
          <WasteSumUpArray
            dangerous={dangerous}
            normal={normal}
            isReceptionAvailable={isReceptionAvailable}
          />
        );
      }}
      icon={
        computedWaste.depassementDeSeuilGlobal && (
          <img
            src={jaugeLogo}
            alt="Depassement de Seuil"
            className={classes.jaugeIcon}
          />
        )
      }
    />
  );
};

export default WasteSumUpWrapper;
