import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import { AreasOtherInfosForm } from "./types";
import { Nullable } from "common/utils/types";

export const convertDtoToAreasOtherInfosForm = (
  declaration: Declaration20Now
): Nullable<AreasOtherInfosForm> => {
  return {
    authorizedArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieAutorisee,
    remainingArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieRestantAExploiter,
    exploitedThisYearArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieExploitee,
    restoredArea:
      declaration.body.sections.carriere.environnement
        .autresRenseignementsSuperficie.superficieRestituee,
  };
};
