import React from "react";
import { computeErrorObject } from "./utils";
import DummyTextArea from "../dumbInput/DummyTextArea";
import { TextAreaFieldProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const TextArea = ({
  field, // { name, value, onChange, onBlur }
  form,
  additionalClassName,
  externalValue,
  disabled,
  placeholder,
  rows,
  additionalOnChange,
  ...props
}: TextAreaFieldProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });
  const fieldValue = field.value === null ? "" : field.value;

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyTextArea
      {...field}
      {...props}
      additionalClassName={additionalClassName}
      value={externalValue !== undefined ? externalValue : fieldValue}
      disabled={externalValue !== undefined ? true : disabled}
      error={error}
      onChange={newValue => {
        form.setFieldValue(field.name, newValue);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newValue);
        }
      }}
    />
  );
};

export default TextArea;
