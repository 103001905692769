import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import Button from "common/button";
import Spinner from "common/presentational/Spinner";
import { FileSectionEnum, HandledFile } from "../types";
import React, { useLayoutEffect, useRef, useState } from "react";
import { useDownloadHandler } from "../hooks/filesHooks";

const useStyle = makeStyles({
  spacing: {
    marginLeft: "3px",
  },
  row: {
    width: "auto",
  },
});

enum FILE_DOWNLOAD_STATE {
  NONE,
  PREPARING,
  DOWNLOADING,
}

interface DownloadFileButtonProps {
  file: HandledFile;
  section: FileSectionEnum;
}

const DownloadFileButton = ({
  file,
  section,
}: DownloadFileButtonProps): React.ReactElement => {
  const classes = useStyle();
  const downloadHandler = useDownloadHandler(section);
  const [downloadState, setDownloadState] = useState<FILE_DOWNLOAD_STATE>(
    FILE_DOWNLOAD_STATE.NONE
  );

  const linkRef = useRef<HTMLAnchorElement | null>(null);

  useLayoutEffect(() => {
    const trigger = async () => {
      if (file.uploadedFile === null) {
        throw Error("file is not uploaded yet");
      }

      if (linkRef.current === null) {
        return;
      }

      if (!linkRef.current.href) {
        const response = await downloadHandler(file.uploadedFile);
        if (response === null) {
          return;
        }

        const blob = await response.blob();

        linkRef.current.href = window.URL.createObjectURL(blob);
        linkRef.current.download = `${file.uploadedFile.name}.${file.uploadedFile.extension}`;
      }

      // Anchor has now an href, click again on it to perform download in browser
      linkRef.current.click();
      setDownloadState(FILE_DOWNLOAD_STATE.NONE);
    };

    if (downloadState === FILE_DOWNLOAD_STATE.PREPARING) {
      setDownloadState(FILE_DOWNLOAD_STATE.DOWNLOADING);
      trigger();
    }
  }, [downloadState, setDownloadState, file, downloadHandler]);

  const isSpinnerVisible = downloadState !== FILE_DOWNLOAD_STATE.NONE;

  return (
    <>
      {isSpinnerVisible ? (
        <Spinner
          additionalClassname={classNames(classes.spacing, classes.row)}
        />
      ) : (
        <Button
          additionalClassname={classNames(classes.spacing)}
          isReversed
          text={<FontAwesomeIcon icon={faDownload} />}
          onClick={() => setDownloadState(FILE_DOWNLOAD_STATE.PREPARING)}
        />
      )}
      {/* Because before the first download action the anchor is not valid the linter throw an error */}
      {/* eslint-disable-next-line */}
      <a ref={linkRef} download />
    </>
  );
};

export default DownloadFileButton;
