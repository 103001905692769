import React, { Dispatch, SetStateAction, useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import EmissionArray from "./EmissionArray";
import EmissionModale from "./EmissionModale";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  compareEmissionFunction,
  createAirSolvantEmissionDto,
} from "./utils/utils";
import { createAirSolvantsCovDto } from "../CovBloc/utils/utils";
import { cloneDeep, isEqual } from "lodash";
import { makeStyles } from "@material-ui/styles";
import { useConfirmationModale } from "common/modale/hooks";
import { EmissionsInArray } from "./utils/types";
import { InstallationInArray } from "../InstallationBloc/utils/types";
import { CovInArray } from "../CovBloc/utils/types";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import BlocFullContext19Now from "../../../versionedElements/BlocFullContext19Now";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";

const useStyles = makeStyles({
  marginBottom: {
    marginBottom: "20px",
  },
});

interface EmissionProps {
  setEmissionInPage: Dispatch<SetStateAction<EmissionsInArray[]>>;
  initialInstallations: InstallationInArray[];
  emissionInPage: EmissionsInArray[];
  initialEmission: EmissionsInArray[];
  isSpecific: boolean;
  otherEmissionInPage: EmissionsInArray[];
  covsInPage: CovInArray[];
  validationEmissionPath: string;
  substances: ReferenceItemPolluantDto[];
  referentialCOVNM: ReferenceItemPolluantDto;
}

const EmissionBloc = ({
  setEmissionInPage,
  initialInstallations,
  emissionInPage,
  initialEmission,
  isSpecific,
  otherEmissionInPage,
  covsInPage,
  validationEmissionPath,
  substances,
  referentialCOVNM,
}: EmissionProps): React.ReactElement => {
  const classes = useStyles();

  const openConfirmationModale = useConfirmationModale();
  const [emissionModaleOpen, setEmissionModaleOpen] = useState(false);
  const [
    emissionInModale,
    setEmissionInModale,
  ] = useState<EmissionsInArray | null>(null);

  const updateHandler = (declaration: Declaration19Now) => {
    declaration.body.sections.air.solvants.covs = createAirSolvantEmissionDto(
      isSpecific ? emissionInPage : otherEmissionInPage
    );
    declaration.body.sections.air.solvants.covnms = createAirSolvantEmissionDto(
      isSpecific ? otherEmissionInPage : emissionInPage
    );
    declaration.body.sections.air.solvants.emissions = createAirSolvantsCovDto(
      covsInPage
    );
    return declaration;
  };

  const emissionsInArray = cloneDeep(emissionInPage).sort(
    compareEmissionFunction
  );

  return (
    <>
      <BlocFullContext19Now
        title={
          !isSpecific
            ? "ÉMISSIONS DE COVNM TOTAUX"
            : "ÉMISSIONS DE COVNM SPECIFIQUES"
        }
        hasModification={!isEqual(initialEmission, emissionInPage)}
        isValidateButtonAvailable={true}
        validationMessage={{
          message: TABS_VALIDATION_MESSAGE,
          isAlwaysVisible: true,
        }}
        path={validationEmissionPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setEmissionInPage(initialEmission)}
        renderContent={shouldDisabledFields => {
          return (
            <>
              {!isSpecific ? (
                <p className={classes.marginBottom}>
                  Émissions de COVNM (composés organiques volatils non
                  méthaniques) totaux déclarées par plan de gestion de solvants
                  (PGS)
                </p>
              ) : (
                <p className={classes.marginBottom}>
                  Émissions de COV spécifiques déclarées par plan de gestion de
                  solvants (PGS)
                </p>
              )}
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER DES ÉMISSIONS"
                  onClick={() => {
                    setEmissionModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <EmissionArray
                emissionsInPage={emissionsInArray}
                setEmissionsInPage={setEmissionInPage}
                setEmissionInModale={setEmissionInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                validationEmissionPath={validationEmissionPath}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setEmissionInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <EmissionModale
        referentialCOVNM={referentialCOVNM}
        isSpecific={isSpecific}
        isOpen={emissionModaleOpen}
        emissionInModal={emissionInModale}
        setEmissionInPage={setEmissionInPage}
        setEmissionInModale={setEmissionInModale}
        setModaleOpen={setEmissionModaleOpen}
        emissionInPage={emissionInPage}
        initialInstallations={initialInstallations}
        substances={substances}
      />
    </>
  );
};

export default EmissionBloc;
