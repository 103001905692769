import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocAirProps {
  disabled: boolean;
}

export const BlocAir = ({ disabled }: BlocAirProps) => {
  const { referenceAirController } = useAuthenticatedApi();
  const classes = useStyles();

  // Combustibles
  const uploadCombustiblesHandler = useCallback(
    (file: File) => {
      return referenceAirController.uploadCombustiblesUsingPOST(file);
    },
    [referenceAirController]
  );

  const downloadCombustiblesHandler = useCallback(() => {
    return referenceAirController.downloadCombustiblesAsCsvUsingGET();
  }, [referenceAirController]);

  // Gaz
  const uploadGazHandler = useCallback(
    (file: File) => {
      return referenceAirController.uploadGazUsingPOST(file);
    },
    [referenceAirController]
  );

  const downloadGazHandler = useCallback(() => {
    return referenceAirController.downloadGazAsCsvUsingGET();
  }, [referenceAirController]);

  // Normes
  const uploadNormesHandler = useCallback(
    (file: File) => {
      return referenceAirController.uploadNormesAirUsingPOST(file);
    },
    [referenceAirController]
  );

  const downloadNormesHandler = useCallback(() => {
    return referenceAirController.downloadNormesAirAsCsvUsingGET();
  }, [referenceAirController]);

  return (
    <DummyUnActionedBloc
      title="Air"
      renderContent={() => {
        return (
          <>
            {disabled && (
              <>
                <div className={classes.disabledText}>
                  Aucune campagne future à l'état prête n'a été trouvée. Vous ne
                  pouvez pas modifier les référentiels d'une campagne en cours.
                </div>
                <Row />
              </>
            )}
            <BlocReferentiel
              title="Combustibles"
              downloadHandler={downloadCombustiblesHandler}
              filename="combustibles.csv"
              uploadHandler={uploadCombustiblesHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Gaz fluorés"
              downloadHandler={downloadGazHandler}
              filename="gaz.csv"
              uploadHandler={uploadGazHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Normes"
              downloadHandler={downloadNormesHandler}
              filename="normes_air.csv"
              uploadHandler={uploadNormesHandler}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default BlocAir;
