import {
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";

interface MatiereEmission {
  elementProps: ReferenceItemPolluantElementDto | null;
  substance: ReferenceItemPolluantDto | null;
}

const getAssociatedPolluantElementsForPolluant = (
  substance: ReferenceItemPolluantDto | null,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >
): ReferenceItemPolluantElementDto[] => {
  const substanceRestorationCode = substance && substance.restorationCode;

  return Array.from(
    (polluantElementMap.get(substanceRestorationCode) || new Map()).values()
  );
};

export const getAssociatedPolluantElementsForPolluantWithAutreLastPosition = (
  substance: ReferenceItemPolluantDto | null,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >,
  autreElementUuid: string
): ReferenceItemPolluantElementDto[] => {
  return getAssociatedPolluantElementsForPolluant(
    substance,
    polluantElementMap
  ).sort(
    (
      e1: ReferenceItemPolluantElementDto,
      e2: ReferenceItemPolluantElementDto
    ) => {
      if (e1.referenceItemElementIndexDto.uuid === autreElementUuid) {
        return 1;
      }
      if (e2.referenceItemElementIndexDto.uuid === autreElementUuid) {
        return -1;
      }

      return e1.referenceItemElementIndexDto.nom <
        e2.referenceItemElementIndexDto.nom
        ? -1
        : 1;
    }
  );
};

export const isElementSelectedAutre = (
  element: ReferenceItemPolluantElementDto | null,
  autreElementUuid: string
): boolean => {
  return (
    !!element && element.referenceItemElementIndexDto.uuid === autreElementUuid
  );
};

export const shouldElementNameBeFilled = (
  matiere: MatiereEmission,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >
): boolean => {
  return (
    getAssociatedPolluantElementsForPolluant(
      matiere.substance,
      polluantElementMap
    ).length === 0
  );
};

export const shouldPartBeFilled = (
  matiere: MatiereEmission,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >,
  autreElementUuid: string
): boolean => {
  return (
    shouldElementNameBeFilled(matiere, polluantElementMap) ||
    isElementSelectedAutre(matiere.elementProps, autreElementUuid)
  );
};
