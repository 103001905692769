import { RouteIdProps } from "common/declarant/type";
import React from "react";
import logo from "icons/pageLogos/insurance.svg";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../../CommonSpace/AppPage/PageHeaderContext";
import MainCarriereForm from "./MainCarriereForm";
import { computeOptionPropsListFromReferential } from "./utils/selectPossibleValues";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const CarriereSanteSecurite = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      declarationEtablissement
    ),
    {
      label: "Santé sécurité",
      to: match.url,
    },
  ];
  const imagedata = {
    src: logo,
    alt: "un casque",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Santé / Sécurité",
    image: imagedata,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_CARRIERE_SANTE_ORGANISME_EXTERIEUR_PREVENTION",
        "REFERENCE_CARRIERE_SANTE_CAUSE_ACCIDENT",
        "REFERENCE_CARRIERE_SANTE_ORGANISME_ACCREDITE_INTERVENANT",
      ]}
      render={referentials => {
        return (
          <MainCarriereForm
            listOptions={computeOptionPropsListFromReferential(
              referentials.REFERENCE_CARRIERE_SANTE_ORGANISME_EXTERIEUR_PREVENTION,
              referentials.REFERENCE_CARRIERE_SANTE_CAUSE_ACCIDENT,
              referentials.REFERENCE_CARRIERE_SANTE_ORGANISME_ACCREDITE_INTERVENANT
            )}
            referentialOrganismeExterieur={
              referentials.REFERENCE_CARRIERE_SANTE_ORGANISME_EXTERIEUR_PREVENTION
            }
            referentialOrganismeAccredite={
              referentials.REFERENCE_CARRIERE_SANTE_ORGANISME_ACCREDITE_INTERVENANT
            }
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(CarriereSanteSecurite, "GLOBAL");
