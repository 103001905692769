import * as Yup from "yup";
import {
  commonBooleanFields,
  commonStringFields,
  subNumberFieldValidationScheme,
  subObjectFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { isOrganismExterieur } from "./utils";

export const validationSchema = Yup.object().shape({
  isSeasonalActivity: commonBooleanFields,
  outsideOrganismOrFunctionalStructure: commonStringFields,
  outsidePreventionOrganism: subObjectFieldValidationScheme(
    "outsideOrganismOrFunctionalStructure",
    isOrganismExterieur
  ),
  numberVisitsMadeInYear: subNumberFieldValidationScheme(
    "outsideOrganismOrFunctionalStructure",
    isOrganismExterieur,
    true
  ),
});
