import { AirFugitivesEmissionCorrelationDtoUniteEnum } from "api/gen";

export enum MethodEnum {
  "MESURE" = "MESURE",
  "FACTEUR_CORRELATION" = "FACTEUR_CORRELATION",
  "BILAN_MATIERE" = "BILAN_MATIERE",
}

export const methodLabels: { [key in MethodEnum]: string } = {
  MESURE: "Mesure",
  FACTEUR_CORRELATION: "Facteur de corrélation",
  BILAN_MATIERE: "Bilan matière",
};

export const methodList = Object.keys(methodLabels) as MethodEnum[];

export interface ObjectUnite {
  code: AirFugitivesEmissionCorrelationDtoUniteEnum;
  name: string;
}

export const unitCorrelationLabels: {
  [key in Exclude<
    AirFugitivesEmissionCorrelationDtoUniteEnum,
    AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE
  >]: string;
} = {
  KG: "kg/kg",
  T: "kg/t",
  HL: "kg/hl",
  L: "kg/l",
  KM3: "kg/1000m³",
  M3: "kg/m³",
};

export const unitCorrelationList = Object.keys(
  unitCorrelationLabels
) as AirFugitivesEmissionCorrelationDtoUniteEnum[];
