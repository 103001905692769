import React from "react";
import { MethodeSurveillanceDto } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import { useDummyTextFieldGenerator } from "common/form/fields/helpers/generators";
import SubSectionTitle from "../TitleComponents/SubSectionTitle";
import { translateBoolToFrenchOrNull } from "common/utils/booleanUtils";
import { FORM_FIELD_STYLE } from "../../Emissions/BlocRapportEmissions/RapportData/theme";

const useStyles = makeStyles({
  ...FORM_FIELD_STYLE,
});

interface MethodesSurveillanceProps {
  methodeSurveillanceDto: MethodeSurveillanceDto;
}

const MethodesSurveillance = ({
  methodeSurveillanceDto,
}: MethodesSurveillanceProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "65%",
    formPrefix: "aer-methodes-surveillance",
  };
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.centeredInput
  );
  return (
    <>
      <SubSectionTitle>Méthodes de surveillance</SubSectionTitle>
      <div className={classes.formContainer}>
        <DummyTextField
          name={"isMethodeCalculCo2"}
          label={"Méthode de calcul pour le CO2"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.methodeCalculCo2
          )}
        />
        <DummyTextField
          name={"isMethodeMesureCo2"}
          label={"Méthode de mesure pour le CO2"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.methodeMesureCo2
          )}
        />
        <DummyTextField
          name={"isMethodeAlternative"}
          label={"Méthode alternative (article 22)"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.methodeAlternative
          )}
        />
        <DummyTextField
          name={"isSurveillanceN2o"}
          label={"Surveillance des émissions de N2O"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.surveillanceN2o
          )}
        />
        <DummyTextField
          name={"isSurveillancePfc"}
          label={"Surveillance des émissions de PFC"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.surveillancePfc
          )}
        />
        <DummyTextField
          name={"isSurveillanceCo2"}
          label={"Surveillance du CO2, du N2O et du CSC transférés, inhérents"}
          value={translateBoolToFrenchOrNull(
            methodeSurveillanceDto.surveillanceCo2
          )}
        />
      </div>
    </>
  );
};

export default MethodesSurveillance;
