import React from "react";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { NoiseMeasuresForm } from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { convertDeclarationDtoUsingNoiseMeasuresForm } from "../utils/converters";
import { convertDtoToNoiseMeasuresForm } from "./utils/converters";
import { validationSchema } from "./utils/validation";
import {
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_EFFECUTEE,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_CAMPAGNES,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_POINTS,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_EFFECUTEE,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_ENVIRONNEMENT,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_CAMPAGNES,
  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_POINTS,
} from "common/path/path18Now";
import { LEFT_WITHDRAW_STYLE } from "theme";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";

interface BlocMesuresBruitProps {
  noiseMeasuresFormProps: FormikBlocRefAndState<NoiseMeasuresForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const BlocMesuresBruit = ({
  noiseMeasuresFormProps,
}: BlocMesuresBruitProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "environnement-bloc-mesures-bruit",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);

  const initialValues = convertDtoToNoiseMeasuresForm(declaration);

  return (
    <FormikBlocFullContext1919
      hasChanges={noiseMeasuresFormProps.hasChanges}
      setHasChanges={noiseMeasuresFormProps.setHasChanges}
      formikRef={noiseMeasuresFormProps.formikRef}
      title="MESURES DE BRUITS DANS L'ENVIRONNEMENT (TE5)"
      initialValues={initialValues}
      validationSchema={validationSchema}
      pathToValidate={PATH_CARRIERE_ENVIRONNEMENT_BRUIT}
      updateHandler={convertDeclarationDtoUsingNoiseMeasuresForm}
      renderContent={({ values }, shouldDisabledFields: boolean) => (
        <>
          <CheckBox
            label="Mesures de bruit dans l'environnement"
            name="noiseMeasures"
            disabled
          />

          {values.noiseMeasures && (
            <div className={classes.withdrawLeft}>
              <CheckBox
                label="Mesures réalisées"
                name="noiseMeasuresDone"
                disabled={shouldDisabledFields}
                // TODO : required comment
                commentPath={
                  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_EFFECUTEE
                }
              />
              {values.noiseMeasuresDone && (
                <div className={classes.withdrawLeft}>
                  <NumberField
                    label="Nombre de points de mesure *"
                    name="noiseMeasuresPointsNumber"
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_POINTS
                    }
                  />

                  <NumberField
                    label="Nombre de campagnes de mesure *"
                    name="noiseMeasuresCampaignNumber"
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_NB_CAMPAGNES
                    }
                  />
                </div>
              )}
            </div>
          )}

          <CheckBox
            label="Mesures spécifiques de bruit dans l'environnement"
            name="specificNoisesMeasures"
            disabled={shouldDisabledFields}
            commentPath={
              PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_ENVIRONNEMENT
            }
          />

          {values.specificNoisesMeasures && (
            <div className={classes.withdrawLeft}>
              <CheckBox
                label="Mesures réalisées"
                name="specificNoisesMeasuresDone"
                disabled={shouldDisabledFields}
                // TODO : required comment
                commentPath={
                  PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_EFFECUTEE
                }
              />
              {values.specificNoisesMeasuresDone && (
                <div className={classes.withdrawLeft}>
                  <NumberField
                    label="Nombre de points de mesure *"
                    name="specificNoiseMeasuresPointsNumber"
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_POINTS
                    }
                  />

                  <NumberField
                    label="Nombre de campagnes de mesure *"
                    name="specificNoiseMeasuresCampaignNumber"
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_ENVIRONNEMENT_BRUIT_MESURES_SPECIFIQUES_NB_CAMPAGNES
                    }
                  />
                </div>
              )}
            </div>
          )}
        </>
      )}
    />
  );
};

export default BlocMesuresBruit;
