import { AirElevageEmissionsDto } from "api/gen";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { RouteIdProps } from "common/declarant/type";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_AIR_ELEVAGE,
  PATH_AIR_ELEVAGE_DETAIL,
  PATH_AIR_ELEVAGE_EMISSION,
  PATH_AIR_ELEVAGE_MTD,
} from "common/path/path18Now";
import Row from "common/presentational/Row";
import _ from "lodash";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { Declaration20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";
import React, { useState } from "react";
import {
  useDeclaration20Now,
  useDeclarationHelpers20Now,
} from "../../versionedElements/declarationHooks20Now";
import { GlobalFormActionFullContext20Now } from "../../versionedElements/GlobalFormActionFullContext20Now";
import BlocAutresEmissions from "./BlocAutresEmissions";
import BlocCheckboxes from "./BlocCheckboxes";
import BlocEmissions from "./blocEmissions/BlocEmissions";
import { convertDtosToBuildingInArrayList } from "./blocEmissions/utils/converters";
import logo from "icons/pageLogos/pig.svg";
import {
  updateDeclarationDtoUsingEmissionsForm,
  updateDeclarationDtoUsingEmissionsSpeciesForm,
} from "./utils/converters";
import { BuildingInArray, EmissionsSpeciesForm } from "./utils/types";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Breeding = ({ match }: RouteIdProps): React.ReactElement => {
  const declaration = useDeclaration20Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers20Now();

  const initialBuildingInPage = convertDtosToBuildingInArrayList(
    declaration.body.sections.air.elevage.detailNh3.batiments
  );

  const [buildingsInArray, setBuildingsInArray] = useState<BuildingInArray[]>(
    initialBuildingInPage
  );

  const formEmissionsProps = useFormikBloc<AirElevageEmissionsDto>(false);
  const formSpeciesEmissionsProps = useFormikBloc<EmissionsSpeciesForm>(false);

  const isGlobalValidateAvailable =
    !isPathValidatedInDeclaration(PATH_AIR_ELEVAGE) &&
    isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_MTD) &&
    isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_DETAIL) &&
    isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_EMISSION);

  const hasGlobalChanges = (): boolean => {
    let hasChanges = !_.isEqual(buildingsInArray, initialBuildingInPage);

    hasChanges =
      formEmissionsProps.hasChanges ||
      formSpeciesEmissionsProps.hasChanges ||
      hasChanges;

    return hasChanges;
  };

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Élevage",
      to: match.url,
    },
  ];
  const imageData = {
    src: logo,
    alt: "logo des élevages, avec un cochon",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Élevage",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <>
      <BlocCheckboxes />

      <Row />

      <BlocEmissions
        buildingsInArray={buildingsInArray}
        setBuildingsInArray={setBuildingsInArray}
        formSpeciesEmissionsProps={formSpeciesEmissionsProps}
      />

      <>
        <Row />

        <BlocAutresEmissions formEmissionsProps={formEmissionsProps} />
      </>

      <GlobalFormActionFullContext20Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasGlobalChanges()}
        isValidateEnabled={isGlobalValidateAvailable}
        cancelAction={() => {
          setBuildingsInArray(
            convertDtosToBuildingInArrayList(
              declaration.body.sections.air.elevage.detailNh3.batiments
            )
          );
          if (formEmissionsProps.formikRef.current) {
            formEmissionsProps.formikRef.current.resetForm();
          }
          if (formSpeciesEmissionsProps.formikRef.current) {
            formSpeciesEmissionsProps.formikRef.current.resetForm();
          }
        }}
        validationPath={PATH_AIR_ELEVAGE}
        updateHandler={(declaration: Declaration20Now) => {
          if (formEmissionsProps.formikRef.current) {
            declaration = updateDeclarationDtoUsingEmissionsForm(
              declaration,
              formEmissionsProps.formikRef.current.state.values
            );
          }
          if (formSpeciesEmissionsProps.formikRef.current) {
            declaration = updateDeclarationDtoUsingEmissionsSpeciesForm(
              declaration,
              formSpeciesEmissionsProps.formikRef.current.state.values
            );
          }
          return declaration;
        }}
      />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(Breeding, "GLOBAL");
