import { PATH_INFO_GENERALE } from "common/path/path18Now";

export const DISABLED_MESSAGE_INFORMATIONS_GENERALES =
  'Le pavé "Types d\'activité" doit être validé pour accéder au pavé "Informations générales"';

export const GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION =
  'Veuillez valider le pavé "Informations générales" pour accéder au reste de la déclaration';

const DISABLED_MESSAGE_FOR_CONDITIONNAL_SECTION =
  "L'activation de cette page dépend de la section \"type d'activité global\" de la page « Types d'activités »";

const DISABLED_MESSAGE_FOR_QUOTAS_SECTION =
  "L'activation de cette page dépend de la section \"type d'activité quotas\" de la page « Types d'activités »";

const PRESTATAIRE_DISABLED_MESSAGE_FOR_INACTIVE_SECTION =
  "Cette partie est indisponible car le déclarant n'a pas validé la première partie de sa déclaration";

export const getDisabledMessageForTypeActiviteConditionnalSection = (
  isPathValidatedInDeclaration: (path: string) => boolean
): string =>
  isPathValidatedInDeclaration(PATH_INFO_GENERALE)
    ? DISABLED_MESSAGE_FOR_CONDITIONNAL_SECTION
    : GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION;

export const getDisabledMessageForConditionnalSection = (
  isPathValidatedInDeclaration: (path: string) => boolean,
  customDisabledMessage: string
): string =>
  isPathValidatedInDeclaration(PATH_INFO_GENERALE)
    ? customDisabledMessage
    : GENERIC_DISABLED_MESSAGE_FOR_INACTIVE_SECTION;

export const computeDisabledMessageForProfileForConditionalSection = (
  isPathValidatedInDeclaration: (path: string) => boolean,
  isAnyPrestataire: boolean
): string => {
  return isAnyPrestataire
    ? PRESTATAIRE_DISABLED_MESSAGE_FOR_INACTIVE_SECTION
    : DISABLED_MESSAGE_FOR_QUOTAS_SECTION;
};
