import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import {
  CorrelationEmissionInArray,
  CorrelationEmissionInModale,
  EmissionsInArray,
  EmissionsInModale,
  MatiereEmissionInArray,
  MatiereEmissionInModale,
  MesureEmissionInArray,
  MesureEmissionInModale,
} from "./utils/types";
import FormEmission from "./FormEmission";
import {
  addOrModifyArray,
  moveAndDeleteEmission,
} from "common/declarant/array/utils";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import { ProcedeInArray } from "../listprocede/utils/types";
import { MethodProcede } from "./utils/selectPossibleValues";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

interface EmissionModaleProps {
  emissionModaleOpen: boolean;
  emissionInModale: EmissionsInArray | null;
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>;
  setCorrelationEmissionsInPage: Dispatch<
    SetStateAction<CorrelationEmissionInArray[]>
  >;
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  initialProcedes: ProcedeInArray[];
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[];
  referentialCO2: ReferenceItemPolluantDto;
}

const EmissionModale = ({
  emissionModaleOpen,
  emissionInModale,
  setMatiereEmissionsInPage,
  setCorrelationEmissionsInPage,
  setMesureEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  initialProcedes,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  mesureEmissionsInPage,
  substances,
  referentialCO2,
}: EmissionModaleProps): React.ReactElement => {
  const onClose = () => {
    setEmissionInModale(null);
    setEmissionModaleOpen(false);
  };

  const defaultInitialValue: EmissionsInModale = {
    procede: null,
    epuration: false,
    natureEquipement: null,
    rendementEpuration: null,
    emissionAnnuelle: null,
    descIntrant: null,
    quantityIn: null,
    quantityOut: null,
    elementIndexe: null,
    teneurMoyenne: null,
    partElement: null,
    concentrationMoyenne: null,
    debitHoraire: null,
    facteurCorrelation: null,
    frequenceMesureConcentration: null,
    frequenceMesureDebit: null,
    heureFonctionnement: null,
    masseVolumiqueProcede: null,
    mesureContinueConcentration: false,
    mesureContinueDebit: false,
    methods: null,
    natureCorrelation: null,
    provenanceFacteurCorrelation: null,
    quantiteMatiereProcede: null,
    substance: null,
    uniteCorrelation: null,
    uniteProcede: null,
    biomasse: null,
  };

  return (
    <CustomModal
      isOpen={emissionModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout d'émissions."
      onRequestClose={onClose}
    >
      <FormEmission
        referentialCO2={referentialCO2}
        substances={substances}
        correlationEmissionsInPage={correlationEmissionsInPage}
        matiereEmissionsInPage={matiereEmissionsInPage}
        mesureEmissionsInPage={mesureEmissionsInPage}
        initialProcedes={initialProcedes}
        closeFunction={onClose}
        allowedCouples={emissionInModale}
        onSubmit={values => {
          if (values.methods === null) {
            throw Error("Should not happen, check validation");
          }

          if (values.methods.object.value === MethodProcede.MESURE) {
            const newEmission: MesureEmissionInModale = {
              methods: values.methods,
              substance: values.substance && {
                value: values.substance.label,
                label: values.substance.label,
                object: values.substance.object,
              },
              procede: values.procede,
              debitHoraire: values.debitHoraire,
              mesureContinueDebit: values.mesureContinueDebit,
              frequenceMesureDebit: values.frequenceMesureDebit,
              heureFonctionnement: values.heureFonctionnement,
              concentrationMoyenne: values.concentrationMoyenne,
              mesureContinueConcentration: values.mesureContinueConcentration,
              frequenceMesureConcentration: values.frequenceMesureConcentration,
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendementEpuration: values.rendementEpuration,
              emissionAnnuelle: values.emissionAnnuelle,
              biomasse: values.biomasse,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ca veut dire que j'ajoute ou modifie
              (emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value) //Si il avait deja des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setMesureEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodProcede.MESURE:
                  throw Error("Should not happen, check conditions above.");
                case MethodProcede.FACTEUR_CORRELATION:
                  moveAndDeleteEmission(
                    setMesureEmissionsInPage,
                    setCorrelationEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodProcede.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setMesureEmissionsInPage,
                    setMatiereEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else if (
            values.methods.object.value === MethodProcede.FACTEUR_CORRELATION
          ) {
            const newEmission: CorrelationEmissionInModale = {
              methods: values.methods,
              substance: values.substance && {
                value: values.substance.label,
                label: values.substance.label,
                object: values.substance.object,
              },
              procede: values.procede && [values.procede[0]],
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendementEpuration: values.rendementEpuration,
              quantiteMatiereProcede: values.quantiteMatiereProcede,
              uniteProcede: values.uniteProcede,
              natureCorrelation: values.natureCorrelation,
              facteurCorrelation: values.facteurCorrelation,
              uniteCorrelation: values.uniteCorrelation,
              provenanceFacteurCorrelation: values.provenanceFacteurCorrelation,
              emissionAnnuelle: values.emissionAnnuelle,
              masseVolumiqueProcede: values.masseVolumiqueProcede,
              biomasse: values.biomasse,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ca veut dire que j'ajoute ou modifie
              (emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value) //Si il avait deja des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setCorrelationEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodProcede.MESURE:
                  moveAndDeleteEmission(
                    setCorrelationEmissionsInPage,
                    setMesureEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodProcede.FACTEUR_CORRELATION:
                  throw Error("Should not happen, check conditions above.");
                case MethodProcede.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setCorrelationEmissionsInPage,
                    setMatiereEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else if (
            values.methods.object.value === MethodProcede.BILAN_MATIERE
          ) {
            const newEmission: MatiereEmissionInModale = {
              methods: values.methods,
              substance: values.substance && {
                value: values.substance.label,
                label: values.substance.label,
                object: values.substance.object,
              },
              procede: values.procede,
              epuration: values.epuration,
              natureEquipement: values.natureEquipement,
              rendementEpuration: values.rendementEpuration,
              emissionAnnuelle: values.emissionAnnuelle,
              descIntrant: values.descIntrant,
              quantityIn: values.quantityIn,
              quantityOut: values.quantityOut,
              elementIndexe: values.elementIndexe,
              teneurMoyenne: values.teneurMoyenne,
              partElement: values.partElement,
              biomasse: values.biomasse,
            };
            if (
              !emissionInModale || // Si la modale est vide alors ca veut dire que j'ajoute ou modifie
              (emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value) //Si il avait deja des choses dans la modale + Si la methode n'a pas changé quand je modifie alors j'ajoute ou modifie
            ) {
              addOrModifyArray(
                setMatiereEmissionsInPage,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodProcede.MESURE:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInPage,
                    setMesureEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodProcede.FACTEUR_CORRELATION:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInPage,
                    setCorrelationEmissionsInPage,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodProcede.BILAN_MATIERE:
                  throw Error("Should not happen, check conditions above.");

                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else {
            throw new ShouldNotHappen(values.methods.object.value);
          }

          onClose();
        }}
        isEdit={emissionInModale !== null}
        initialEmission={
          emissionInModale !== null
            ? {
                ...defaultInitialValue,
                ...emissionInModale.data,
              }
            : defaultInitialValue
        }
      />
    </CustomModal>
  );
};

export default EmissionModale;
