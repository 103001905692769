import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import React from "react";
import {
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "../../../DeclarationApiContext/utils/types";
import { CommonFormikBlocInContextProps } from "common/formikBloc/types";
import GenericFormikBlocFullContext from "common/formikBloc/GenericFormikBlocFullContext";
import { DeclarationStateDtoStateEnum } from "api/gen";
import {
  Declaration1919,
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "./declarationHooks1919";
import { DeclarationConstraintViolation19Now } from "../../toNow/versionedElements/declarationHooks19Now";
import { useDeclarationErrorAndWarnings1919 } from "./errorAndWarningDisplayer1919/utils";
import { useComments } from "pages/CompanySpace/Comments/CommentStore";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

type FormikBlocInContext1919Props<
  T extends FormikValues
> = CommonFormikBlocInContextProps<T, Declaration1919>;

export const FormikBlocFullContext1919 = <T extends FormikValues>(
  props: FormikBlocInContext1919Props<T>
): React.ReactElement => {
  //use the hooks directly from brother file
  const useDeclaration: () => Declaration1919 = useDeclaration1919;
  const useDeclarationHelpers: () => DeclarationHelpers = useDeclarationHelpers1919;
  const useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    Declaration1919
  > = useBasicDeclarationHandlers1919;
  const getDeclarationState: (
    declarationDto: Declaration1919
  ) => DeclarationStateDtoStateEnum = declarationDto =>
    declarationDto.body.workflowStatus.general.state;
  const useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<
    DeclarationConstraintViolation19Now
  > = useDeclarationErrorAndWarnings1919;
  const displayErrorAndWarning: (
    errors: DeclarationConstraintViolation19Now[],
    warnings: DeclarationConstraintViolation19Now[]
  ) => React.ReactElement = (errors, warnings) => (
    <ViolationProcessor errors={errors} warnings={warnings} />
  );

  return (
    <GenericFormikBlocFullContext<
      T,
      Declaration1919,
      DeclarationConstraintViolation19Now
    >
      useDeclaration={useDeclaration}
      useBasicDeclarationHandlers={useBasicDeclarationHandlers}
      useDeclarationHelpers={useDeclarationHelpers}
      getDeclarationState={getDeclarationState}
      useDeclarationErrorAndWarnings={useDeclarationErrorAndWarnings}
      displayErrorAndWarning={displayErrorAndWarning}
      useComments={useComments}
      areGlobalCommentsAllowed={props.areGlobalCommentsAllowed}
      shouldFetchInCaseOfError={props.shouldFetchInCaseOfError ?? false}
      {...props}
    />
  );
};

export default FormikBlocFullContext1919;
