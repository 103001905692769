import { OptionProps } from "common/form/fields/types/basicTypes";
import { removeAccents } from "./methods";

export const computeOptionProps = (
  enumValues: any,
  lowerCase: boolean = true
): OptionProps[] => {
  // per https://stackoverflow.com/questions/30774874/enum-as-parameter-in-typescript enum doesn't exist as utils. We therefore use a variant of this solution : https://stackoverflow.com/a/30793604/7059810
  let id = 1;
  const ret: OptionProps[] = [];
  for (const enumMember in enumValues) {
    const label = lowerCase ? enumMember.toLowerCase() : enumMember;
    ret.push({ label: label, value: id }); //ignore this warning because we know it's an enum that is passed.
    id++;
  }
  return ret;
};

// Search element of an enum type corresponding to an OptionProps, based on enum's keys and OptionProps' label
/** @deprecated */
function computeEnumKey<T>(
  optionValue: OptionProps | undefined | null,
  enumType: T
): keyof T | undefined {
  if (optionValue === undefined || optionValue === null) {
    return undefined;
  }
  for (const enumKey of Object.keys(enumType)) {
    // need to use 'of' instead of 'in' to get enum strings instead of numeric index
    if (
      removeAccents(optionValue.label.toLowerCase()) ===
      removeAccents(enumKey.toLowerCase())
    ) {
      return enumKey as keyof T; // enumKey is a string but is indeed a key of enumType
    }
  }
  return undefined;
}

/**
 * Convert an OptionProps representing an enum type (generated with computeOptionProps for this enum) into corresponding element of this enum
 *
 * @deprecated use cleanComputeEnumValue instead
 * @param optionValue optionProps selected into the list generated by enum
 * @param enumObject enum that was used to generate the list of OptionProps where user selected optionValue
 */
export function computeEnumValue<T>(
  optionValue: OptionProps | undefined,
  enumObject: T
): T[keyof T] | undefined {
  const enumKey = computeEnumKey(optionValue, enumObject);
  return enumKey && enumObject[enumKey];
}

// same as function on top this but with null instead of undefined. Is usually used as a transition between a new "correct" method, and an elder, "legacy" method.
export function cleanComputeEnumValue<T>(
  optionValue: OptionProps | null,
  enumObject: T
): T[keyof T] | null {
  const enumKey = computeEnumKey(optionValue, enumObject);
  return (enumKey && enumObject[enumKey]) || null;
}

export function computeEnumValueOrThrow<T>(
  optionValue: OptionProps,
  enumObject: T
): T[keyof T] {
  const returnValue = cleanComputeEnumValue(optionValue, enumObject);
  if (returnValue) {
    return returnValue;
  }
  throw Error(
    "this method expects an optionValue that CAN be assigned to enumObject"
  );
}

export function findByKey<T, A>(
  key: T,
  collection: A[],
  isMatching: (key: T, item: A) => boolean
) {
  if (key !== undefined && key !== null) {
    return collection.find(item => isMatching(key, item)) || null;
  }
  return null;
}
