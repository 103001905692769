import { InArray, InModale } from "common/form/utils";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

export interface AccidentsTravailData {
  bigAccident: boolean;
}

export type ConsolidatedType = "Oui" | "Non" | "Accident mortel";
export type AccidentCauseType = OptionPropsWithObject<{ cause: string }>;

export const yesLabel: ConsolidatedType = "Oui";
export const noLabel: ConsolidatedType = "Non";
export const deadlyLabel: ConsolidatedType = "Accident mortel";

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyAccidentProps {
  date: Date;
  accidentCause: AccidentCauseType;
  accidentCausePrecision: string;
  isConsolidated: ConsolidatedType;
  nbDaysStopped: number;
}

export type AccidentInModale = InModale<LegacyAccidentProps>;

export type AccidentInArray = InArray<LegacyAccidentProps>;
