import React from "react";
import * as Papa from "papaparse";
import { makeStyles } from "@material-ui/styles";
import FileLoader from "common/button/FileLoader";
import { useAlertModale } from "common/modale/hooks";

const useStyles = makeStyles({
  marginRight: {
    marginRight: "5%",
  },
});

interface ExcelImportProps<T> {
  filterAction: (
    arrayPossible: string[][]
  ) => { filteredArray: T[]; errorMessage: string | null } | null;
  handleImportedData: (imported: T[], message: string | null) => void;
  isDisabled?: boolean;
  headerLinesCount?: number; //  number of line to ignore
}

export function createCSVImportButton<T>() {
  return ({
    filterAction,
    handleImportedData,
    isDisabled = false,
    headerLinesCount = 1,
  }: ExcelImportProps<T>): React.ReactElement => {
    const classes = useStyles();
    const openAlertModale = useAlertModale();
    const csvErrorMessage =
      "Une erreur s'est produite, veuillez utiliser le formulaire de saisie en masse (gabarit) disponible sur cette page.";

    return (
      <FileLoader
        text="PARCOURIR"
        type="button"
        additionalClassname={classes.marginRight}
        onFileHandler={(file: File) => {
          Papa.parse(file, {
            complete: function(results) {
              if (results.errors.length !== 0) {
                openAlertModale(csvErrorMessage);
              } else {
                const arrayResults = results.data;
                arrayResults.splice(0, headerLinesCount); // remove headers
                const filtered = filterAction(arrayResults);
                if (filtered === null) {
                  openAlertModale(csvErrorMessage);
                } else {
                  handleImportedData(
                    filtered.filteredArray,
                    filtered.errorMessage
                  );
                }
              }
            },
            encoding: "utf-8",
            delimiter: ";",
          });
        }}
        acceptedFileType={".csv"}
        onReject={() =>
          openAlertModale("Le format du fichier uploadé est incorrect.")
        }
        isDisabled={isDisabled}
      />
    );
  };
}
