import React, { ReactElement } from "react";
import { SousInstallationRefAlternatifDto2123 } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import { isEmpty } from "lodash";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import SousInstallationReferentielAlternatifTable from "./SousInstallationReferentielAlternatifTable";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
});

interface SousInstallationReferentielAlternatifProps {
  sousInstallationRefAlternatifList: SousInstallationRefAlternatifDto2123[];
}

const SousInstallationReferentielAlternatif = ({
  sousInstallationRefAlternatifList,
}: SousInstallationReferentielAlternatifProps): ReactElement => {
  const classes = useStyles();

  if (isEmpty(sousInstallationRefAlternatifList)) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Sous-installations avec référentiel alternatif
      </SubSectionTitle>

      {sousInstallationRefAlternatifList.map(
        (sousInstallationRefAlternatif, index) => (
          <SousInstallationReferentielAlternatifTable
            sousInstallationRefAlternatif={sousInstallationRefAlternatif}
            key={"ref-alternatif-" + index}
          />
        )
      )}
    </div>
  );
};

export default SousInstallationReferentielAlternatif;
