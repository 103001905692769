import {
  AirElevageDetailNh320NowEspecesDeclareesEnum,
  AirElevageNh3BatimentDto20Now,
} from "api/gen";
import { getValueOrZero } from "common/utils/numberUtils";
import { BuildingInArray } from "../../utils/types";
import { computeKeysOfUnselectedSpecies } from "./utils";

export const convertDtosToBuildingInArrayList = (
  arrayBuildingDtos: AirElevageNh3BatimentDto20Now[]
): BuildingInArray[] => {
  return arrayBuildingDtos.map(singleBuildingDto => {
    return {
      data: singleBuildingDto,
      errors: {},
    };
  });
};

export const convertBuildingInArrayListToDtos = (
  arrayPopulatedBuilding: BuildingInArray[],
  selectedSpecies: AirElevageDetailNh320NowEspecesDeclareesEnum[]
): AirElevageNh3BatimentDto20Now[] => {
  return arrayPopulatedBuilding.map(singlePopulatedBuilding => {
    const singleBuilding = singlePopulatedBuilding.data;
    const unselectedSpeciesKey = computeKeysOfUnselectedSpecies(
      selectedSpecies
    );
    unselectedSpeciesKey.forEach(specieKey => (singleBuilding[specieKey] = 0));

    const singleBuildingDto: AirElevageNh3BatimentDto20Now = {
      id: singleBuilding.id,
      name: singleBuilding.name || "",
      caillesEmission: getValueOrZero(singleBuilding.caillesEmission),
      canardsEmission: getValueOrZero(singleBuilding.canardsEmission),
      cochettesEmission: getValueOrZero(singleBuilding.cochettesEmission),
      pouletsDeChairEmission: getValueOrZero(
        singleBuilding.pouletsDeChairEmission
      ),
      pintadesEmission: getValueOrZero(singleBuilding.pintadesEmission),
      porceletsEnPostSevrageEmission: getValueOrZero(
        singleBuilding.porceletsEnPostSevrageEmission
      ),
      porcsDeProductionEmission: getValueOrZero(
        singleBuilding.porcsDeProductionEmission
      ),
      poulesPondeusesEmission: getValueOrZero(
        singleBuilding.poulesPondeusesEmission
      ),
      poulettesEmission: getValueOrZero(singleBuilding.poulettesEmission),
      dindesDindonsEmission: getValueOrZero(
        singleBuilding.dindesDindonsEmission
      ),
      truiesEnAttenteSaillieEtGestantesEmission: getValueOrZero(
        singleBuilding.truiesEnAttenteSaillieEtGestantesEmission
      ),
      truiesEnMaterniteEmission: getValueOrZero(
        singleBuilding.truiesEnMaterniteEmission
      ),
      autresVolaillesEmission: getValueOrZero(
        singleBuilding.autresVolaillesEmission
      ),
      verratsEmission: getValueOrZero(singleBuilding.verratsEmission),
      approcheGlobalePorcsEmission: getValueOrZero(
        singleBuilding.approcheGlobalePorcsEmission
      ),
    };
    return singleBuildingDto;
  });
};
