import React, { useEffect, useMemo, useState } from "react";
import BlocConfigurationNotification from "./BlocConfigurationNotification";
import { EmailUnsubscriptionValues } from "./BlocConfigurationNotification/types";
import Row from "common/presentational/Row";
import { DASHBOARD_BORDER_GREY, PAGE_TITLE_FONT_SIZE, PINK } from "theme";
import { makeStyles } from "@material-ui/styles";
import { EmailUnsubscriptionControllerApi } from "api/gen";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale, useSuccessModale } from "common/modale/hooks";
import { backMessageReferential } from "common/backErrors/errorMessages";
import Spinner from "common/presentational/Spinner";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
  },
  title: {
    color: PINK,
    fontSize: PAGE_TITLE_FONT_SIZE,
    margin: "auto",
    fontWeight: 400,
    textAlign: "center",
  },
  titleWrapper: {
    display: "flex",
    textTransform: "uppercase",
    padding: "30px",
    textAlign: "center",
    position: "relative",
  },
});

export const EmailUnsubscription = (): React.ReactElement => {
  const classes = useStyles();

  const [emailSubscription, setEmailSubscription] = useState<
    EmailUnsubscriptionValues
  >({
    initiateReminderDisabled: false,
    validationReminderDisabled: false,
    emailDeclarant: "",
    newElement: false,
  });

  const emailUnsubscriptionControllerApi = useMemo(() => {
    return new EmailUnsubscriptionControllerApi(undefined, "");
  }, []);

  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const params = new URLSearchParams(window.location.search);
  const email = encodeURIComponent(params.get("email") || "");
  const token = encodeURIComponent(params.get("token") || "");

  useEffect(() => {
    let isUnmounted = false;
    const fetchNext = async () => {
      try {
        const emailUnsubscriptionDto = await emailUnsubscriptionControllerApi.getSettingsUnsubscriptionUsingGET(
          email || "",
          token || ""
        );
        if (!isUnmounted) {
          emailSubscription.initiateReminderDisabled = !emailUnsubscriptionDto.newElement
            ? emailUnsubscriptionDto.initiateReminderDisabled
            : true;
          emailSubscription.validationReminderDisabled = !emailUnsubscriptionDto.newElement
            ? emailUnsubscriptionDto.validationReminderDisabled
            : true;
          emailSubscription.newElement = emailUnsubscriptionDto.newElement;
          setDataFetched(true);
        }
      } catch (excp) {
        setError(true);
      }
    };
    fetchNext();
    return () => {
      isUnmounted = true;
    };
  }, [email, emailSubscription, emailUnsubscriptionControllerApi, token]);

  const openAlertModale = useAlertModale();
  const openSuccessModale = useSuccessModale();

  if (error) {
    return (
      <>
        <div className={classes.main}>
          <div className={classes.titleWrapper}>
            <h3 className={classes.title}>Desabonnement des notifications</h3>
          </div>
          <div className={classes.container}>Le lien utilisé est erroné.</div>
        </div>
      </>
    );
  }
  if (!dataFetched) {
    return <Spinner />;
  }
  return (
    <>
      <div className={classes.main}>
        <div className={classes.titleWrapper}>
          <h3 className={classes.title}>Desabonnement des notifications</h3>
        </div>
        <div className={classes.container}>
          <BlocConfigurationNotification
            emailSubscription={emailSubscription}
            setEmailSubscription={setEmailSubscription}
            onSubmit={async value => {
              try {
                await emailUnsubscriptionControllerApi.unsubscriptionUsingPOST(
                  email,
                  value.initiateReminderDisabled,
                  token,
                  value.validationReminderDisabled
                );
                openSuccessModale("La configuration a été mise à jour.");
                return true;
              } catch (excp) {
                openAlertModale(backAlertMessage(excp, backMessageReferential));
                return false;
              }
            }}
          />
        </div>
      </div>
    </>
  );
};

export default EmailUnsubscription;
