import { AirIsdndCh4Dto, AirIsdndCo2Dto } from "api/gen";
import { BlocCalculatedEmissionFormType, MainFormValues } from "./types";
import {
  AirIsdndBasicDto,
  AirIsdndCh4DtoMethodeEnum,
  AirIsdndCo2DtoMethodeEnum,
} from "api/gen/api";

export const createAirIsdndBasicDto = (
  formData: MainFormValues
): AirIsdndBasicDto => {
  return {
    biogaz: formData.volumeBiogazExported,
    ch4: formData.ch4Content,
  };
};

export const createAirIsdndCalculCh4Dto = (
  calculCh4: BlocCalculatedEmissionFormType
): AirIsdndCh4Dto => {
  return {
    capte: calculCh4.amountCh4Caught,
    genere: calculCh4.amountCh4Generated,
    methode: calculCh4.ch4Method,
    methodeDescription:
      calculCh4.ch4Method === AirIsdndCh4DtoMethodeEnum.AUTRE_METHODE
        ? calculCh4.ch4MethodDescription
        : null,
    total: calculCh4.amountCh4Total,
  };
};

export const createAirIsdndCalculCo2Dto = (
  calculCo2: BlocCalculatedEmissionFormType
): AirIsdndCo2Dto => {
  return {
    capte: calculCo2.amountCo2Caught,
    genere: calculCo2.amountCo2Generated,
    methode: calculCo2.co2Method,
    methodeDescription:
      calculCo2.co2Method === AirIsdndCo2DtoMethodeEnum.AUTRE_METHODE
        ? calculCo2.co2MethodDescription
        : null,
    total: calculCo2.amountCo2Total,
  };
};
