import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE } from "theme";
import { ProduitTransportType } from "./utils/types";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import Button from "common/button";
import { convertInFranceStringToBoolean } from "./utils/converters";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { PATH_CARRIERE_PRODUCTION_TRANSPORT_TABLEAU } from "common/path/path18Now";
import Row from "common/presentational/Row";
import { computeFamiliesArrayCells } from "../Families/computers";
import { YEARLY_AMOUNT } from "../utils/languages";
import {
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
} from "../Families/languages";

interface TransportProductArrayProps {
  transportProductsInArray: ProduitTransportType[];
  setTransportProductsInArray: (products: ProduitTransportType[]) => void;
  onEdit: (factory: ProduitTransportType) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  table: {
    marginTop: "20px",
  },
  link: LINK_STYLE,
  disabledLink: {
    color: "black",
    textDecoration: "none",
    cursor: "text",
  },
});

const TransportProductArray = ({
  transportProductsInArray,
  setTransportProductsInArray,
  onEdit,
  isDisabled,
}: TransportProductArrayProps) => {
  const classes = useStyles();

  const header: React.ReactElement[] = [
    <p>Famille d'usage du produit expédié</p>,
    <p>{SUB_USAGE_FAMILY}</p>,
    <p>{SUB_USAGE_FAMILY_LEVEL_2}</p>,
    <p>Destination</p>,
    <p>{`${YEARLY_AMOUNT} (ktonnes)`}</p>,
    <p>Actions</p>,
  ];

  const lines: React.ReactElement[][] = transportProductsInArray.map(
    product => {
      const render = [
        ...computeFamiliesArrayCells(
          product.famille,
          product.precisionFamille,
          product.sousFamille,
          product.precisionSousFamille,
          product.sousFamilleNiveau2,
          product.precisionSousFamilleNiveau2
        ),
      ];
      render.push(
        <p>
          {convertInFranceStringToBoolean(product.enFrance)
            ? product.departement?.numero + " - " + product.departement?.nom
            : product.pays?.designation}
        </p>
      );
      render.push(<p>{computeNumberWithSeparator(product.quantite || 0)}</p>);
      render.push(
        <CommonEntityButton
          handlerEdit={() => onEdit(product)}
          commentPath={`${PATH_CARRIERE_PRODUCTION_TRANSPORT_TABLEAU}/${product.id}`}
          handlerSuppress={() =>
            setTransportProductsInArray(
              transportProductsInArray.filter(t => t.id !== product.id)
            )
          }
          isValidated={isDisabled}
        />
      );
      return render;
    }
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        additionalClassName={classes.table}
        preferredColWidths={[
          250,
          140,
          140,
          140,
          140,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setTransportProductsInArray([])}
        />
      </Row>
    </>
  );
};

export default TransportProductArray;
