import React, { useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import InstallationsArray from "./InstallationsArray";
import InstallationsModale from "./InstallationsModale";
import {
  compareInstallationFunction,
  convertInstallationInArraysToInstallationDtos,
  modifyEmissionBasedOnInstallation,
} from "./utils/utils";
import { isEqual } from "lodash";
import { createAirSolvantsCovDto } from "../CovBloc/utils/utils";
import { createAirSolvantEmissionDto } from "../EmissionBloc/utils/utils";
import { InstallationInArray } from "./utils/types";
import { EmissionsInArray } from "../EmissionBloc/utils/types";
import { CovInArray } from "../CovBloc/utils/types";
import BlocFullContext19Now from "../../../versionedElements/BlocFullContext19Now";
import { Declaration19Now } from "pages/CompanySpace/from19/toNow/versionedElements/declarationHooks19Now";
import cloneDeep from "lodash.clonedeep";

interface InstallationProps {
  initialInstallations: InstallationInArray[];
  installationsInPage: InstallationInArray[];
  setInstallationsInPage: React.Dispatch<
    React.SetStateAction<InstallationInArray[]>
  >;
  emissionTotalesInPage: EmissionsInArray[];
  setEmissionTotalesInPage: React.Dispatch<
    React.SetStateAction<EmissionsInArray[]>
  >;
  emissionSpecifiquesInPage: EmissionsInArray[];
  setEmissionSpecifiquesInPage: React.Dispatch<
    React.SetStateAction<EmissionsInArray[]>
  >;
  covsInPage: CovInArray[];
  setCovsInPage: React.Dispatch<React.SetStateAction<CovInArray[]>>;
  validationInstallationPath: string;
}

const InstallationBloc = ({
  initialInstallations,
  installationsInPage,
  setInstallationsInPage,
  emissionTotalesInPage,
  setEmissionTotalesInPage,
  emissionSpecifiquesInPage,
  setEmissionSpecifiquesInPage,
  covsInPage,
  setCovsInPage,
  validationInstallationPath,
}: InstallationProps): React.ReactElement => {
  const [installationModaleOpen, setInstallationModaleOpen] = useState(false);
  const [
    installationInModale,
    setInstallationInModale,
  ] = useState<InstallationInArray | null>(null);

  const updateHandler = (declaration: Declaration19Now) => {
    declaration.body.sections.air.solvants.installations = convertInstallationInArraysToInstallationDtos(
      installationsInPage
    );

    const emissionTotales = modifyEmissionBasedOnInstallation(
      emissionTotalesInPage,
      installationsInPage
    );
    declaration.body.sections.air.solvants.covnms = createAirSolvantEmissionDto(
      emissionTotales
    );
    setEmissionTotalesInPage(emissionTotales);

    const emissionSpecifiques = modifyEmissionBasedOnInstallation(
      emissionSpecifiquesInPage,
      installationsInPage
    );
    declaration.body.sections.air.solvants.covs = createAirSolvantEmissionDto(
      emissionSpecifiques
    );
    setEmissionSpecifiquesInPage(emissionSpecifiques);

    const covs = modifyEmissionBasedOnInstallation(
      covsInPage,
      installationsInPage
    );
    declaration.body.sections.air.solvants.emissions = createAirSolvantsCovDto(
      covs
    );
    setCovsInPage(covs);

    return declaration;
  };

  return (
    <>
      <BlocFullContext19Now
        title="Déclaration des Installations"
        hasModification={!isEqual(initialInstallations, installationsInPage)}
        isValidateButtonAvailable={true}
        path={validationInstallationPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setInstallationsInPage(initialInstallations)}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UNE INSTALLATION"
                  onClick={() => {
                    setInstallationModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <InstallationsArray
                installationsInPage={cloneDeep(installationsInPage).sort(
                  compareInstallationFunction
                )}
                setInstallationsInPage={setInstallationsInPage}
                setInstallationInModale={setInstallationInModale}
                setInstallationModaleOpen={setInstallationModaleOpen}
                isValidated={shouldDisabledFields}
                validationInstallationPath={validationInstallationPath}
              />
              <Row />
            </>
          );
        }}
      />

      <InstallationsModale
        isOpen={installationModaleOpen}
        installationInModal={installationInModale}
        setInstallationsInPage={setInstallationsInPage}
        setInstallationInModale={setInstallationInModale}
        setModaleOpen={setInstallationModaleOpen}
        installationsInPage={installationsInPage}
      />
    </>
  );
};

export default InstallationBloc;
