import { InArray, InModale } from "common/form/utils";
import { DescriptionAccidentDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export interface AccidentsTravailData {
  bigAccident: boolean;
}

export const accidentTravailFieldMatcher = fieldMatcherBuilder<
  AccidentsTravailData
>().build({
  bigAccident: "bigAccident",
});

export type ConsolidatedType = "Oui" | "Non" | "Accident mortel";

export const yesLabel: ConsolidatedType = "Oui";
export const noLabel: ConsolidatedType = "Non";
export const deadlyLabel: ConsolidatedType = "Accident mortel";

interface DescriptionAccident
  extends Omit<
    DescriptionAccidentDto,
    "id" | "accidentDate" | "estConsolide" | "estMortel"
  > {
  accidentDate: Date;
  isConsolidated: ConsolidatedType;
}

export type AccidentInModale = InModale<DescriptionAccident>;

export type AccidentInArray = InArray<DescriptionAccident>;

export const descriptionAccidentFieldMatcher = fieldMatcherBuilder<
  DescriptionAccident
>().build({
  accidentDate: "accidentDate",
  causePrincipale: "causePrincipale",
  precisionSiAutre: "precisionSiAutre",
  isConsolidated: "isConsolidated",
  nbJoursArretTravail: "nbJoursArretTravail",
});
