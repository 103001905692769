import { makeStyles } from "@material-ui/styles";
import React from "react";
import { validationSchema } from "./validation";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { PATH_QUOTAS_ALLOCATIONS_EMAILS } from "common/path/path20Now";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import Row from "common/presentational/Row";
import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import { QuotasAllocationsEmailsVerificateursDto20Now } from "api/gen";
import { useDeclaration2122 } from "../../../versionedElements/declarationHooks2122";
import {
  generateImportEmailsConfirmationMessage,
  useNiveauxActiviteValidationMessage,
} from "pages/CompanySpace/from21/toNow/Quotas/utils/utils";
import { updateHandlerEmailsAddressAllocations2122 } from "./utils";
import FormikBlocFullContext2122 from "../../../versionedElements/FormikBlocFullContext2122";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "20px",
    display: "flex",
    width: "60vw",
  },
  centerItems: {
    justifyContent: "center",
  },
});

const BlocEmailsVerificateurs = ({
  hasChanges,
  setHasChanges,
  formikRef,
}: FormikBlocRefAndState<
  QuotasAllocationsEmailsVerificateursDto20Now
>): React.ReactElement => {
  const declaration = useDeclaration2122();
  const classes = useStyles();
  const validationMessage = useNiveauxActiviteValidationMessage();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix:
      "bloc-quotas-allocations-personnes-notifier-passage-vérification",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const openConfirmationModale = useConfirmationModale();

  const shouldDisableImportButton = !declaration.body.sections.quotas.emissions.blocEmails.emails.some(
    email => email !== "" && email !== null
  );

  return (
    <FormikBlocFullContext2122
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={declaration.body.sections.quotas.allocations.blocEmails}
      validationSchema={validationSchema}
      title={"PERSONNES A NOTIFIER LORS DU PASSAGE A LA VERIFICATION"}
      pathToValidate={PATH_QUOTAS_ALLOCATIONS_EMAILS}
      areGlobalCommentsAllowed={true}
      editValidationMessage={validationMessage}
      updateHandler={updateHandlerEmailsAddressAllocations2122}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      renderContent={(props, shouldDisabledFields) => (
        <>
          <p>
            L'ajout des adresses mail ne vaut pas attribution des droits. Il est
            nécessaire, si cela n'a pas déjà été fait, d'attribuer le droit
            "Vérificateur niveaux d'activité" sur le module d’ajout de droit du
            Portail MonAIOT.
          </p>

          <Row />
          <FieldArray
            name={"emails"}
            render={arrayHelpers => (
              <div>
                {props.values.emails.map((email, index) => (
                  <>
                    <Row key={index} additionalClassname={classes.centerItems}>
                      {/*TODO GEREP-1989 proprifier affichage pour afficher boutons similaires aux boutons comms pour les suppressions*/}
                      <TextField
                        label={`adresse mail ${index + 1} *`}
                        name={`emails.${index}`}
                        disabled={shouldDisabledFields}
                      />
                    </Row>
                    {props.values.emails.length > 1 && (
                      <Row>
                        <Button
                          text="Supprimer une adresse mail"
                          isDisabled={shouldDisabledFields}
                          onClick={() => {
                            arrayHelpers.remove(index);
                          }}
                        />
                      </Row>
                    )}
                    <Row />
                  </>
                ))}
                <Row />
                <Row>
                  <Button
                    text="Ajouter une adresse mail"
                    isDisabled={shouldDisabledFields}
                    onClick={() => {
                      arrayHelpers.push("");
                    }}
                  />
                  <Button
                    text={"Importer les adresses du pavé émissions"}
                    isDisabled={
                      shouldDisabledFields || shouldDisableImportButton
                    }
                    onClick={() => {
                      const emailsToImport = declaration.body.sections.quotas.emissions.blocEmails.emails.filter(
                        email => email !== "" && email !== null
                      );
                      return openConfirmationModale(
                        generateImportEmailsConfirmationMessage(emailsToImport),
                        () => {
                          props.setFieldValue("emails", emailsToImport);
                        }
                      );
                    }}
                  />
                </Row>
              </div>
            )}
          />
          {/*TODO GEREP-1809 don't forget to add a warning message for the email not being one of a verificator */}
        </>
      )}
    />
  );
};

export default BlocEmailsVerificateurs;
