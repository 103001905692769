import React, { ReactElement } from "react";
import { GraphicSize } from "./types";
import ReactDOM from "react-dom";

export const checkProps = (
  header: React.ReactNode[],
  lines: React.ReactNode[][],
  colWidths: number[] | undefined,
  styles: React.CSSProperties[] | undefined
): void => {
  const expectedLen = header.length;
  if (lines.some(line => line.length !== expectedLen)) {
    //  if one of the line has the wrong length, we'll throw an error.
    throw Error(
      `Your header has a length of ${expectedLen}, and one of your line has a different length. 
        Please ensure header and line all have the same length.`
    );
  }
  if (colWidths && colWidths.length !== header.length) {
    //  if column widths count missmatch number of column we throw an error.
    throw Error("header size and column width size missmatch");
  }
  if (styles && styles.length !== header.length) {
    //  if column styles count missmatch number of column we throw an error.
    throw Error("header size and column style size missmatch");
  }
};

export const preferredWidthInCollection = (
  widths: number[] | undefined,
  styles: React.CSSProperties[] | undefined,
  index: number,
  defaultWidth: number
): number => {
  if (widths || styles) {
    //  preferred width for column
    const preferredWidth = widths ? widths[index] : 0;
    //  preferred width from style for column
    const styleWidth =
      styles && styles[index].width !== undefined
        ? parseInt(styles[index].width + "")
        : 0;
    //  if both set get max
    let computedWidth = Math.max(preferredWidth, styleWidth);
    //  if width too small, return default width
    if (computedWidth <= 0) {
      computedWidth = defaultWidth;
    }
    return computedWidth;
  } else {
    return defaultWidth;
  }
};

export const computeColumnWidths = (
  isFirstColSticky: boolean,
  isLastColSticky: boolean,
  bounds: GraphicSize | null,
  hasRightScrollBar: boolean,
  scrollBarWidth: number,
  defaultColumnWidth: number,
  header: React.ReactNode[],
  preferredColumnWidths: number[] | undefined,
  cellStyles: React.CSSProperties[] | undefined
): number[] => {
  let firstColWidth = 0;
  let lastColWidth = 0;
  let colWidth: number[] = [];
  if (bounds && bounds.width > 0) {
    //  default cold width tries to display all column with at least 120px width
    const defaultColWidth = Math.max(
      (bounds.width - (hasRightScrollBar ? scrollBarWidth : 0)) / header.length,
      defaultColumnWidth
    );
    //  preferred width for first column
    firstColWidth = preferredWidthInCollection(
      preferredColumnWidths,
      cellStyles,
      0,
      defaultColWidth
    );
    //  preferred width for last column
    lastColWidth = preferredWidthInCollection(
      preferredColumnWidths,
      cellStyles,
      header.length - 1,
      defaultColWidth
    );

    let sum = 0;
    if (isFirstColSticky) {
      sum += firstColWidth;
    }
    if (isLastColSticky) {
      sum += lastColWidth;
    }
    if (sum > (bounds.width * 2) / 3) {
      //  center space to display data is too small
      //  we recompute first and last widths
      if (isFirstColSticky) {
        firstColWidth *= 2 / 3 / (sum / bounds.width);
      }
      if (isLastColSticky) {
        lastColWidth *= 2 / 3 / (sum / bounds.width);
      }
    }

    for (let index = 0; index < header.length; index++) {
      if (index === 0) {
        colWidth.push(firstColWidth);
        continue;
      } else if (index === header.length - 1) {
        colWidth.push(lastColWidth);
        continue;
      } else {
        const width = preferredWidthInCollection(
          preferredColumnWidths,
          cellStyles,
          index,
          defaultColWidth
        );
        colWidth.push(width);
      }
    }

    const totalWidth = colWidth.reduce((sum, current) => sum + current);
    const properWidth = hasRightScrollBar
      ? bounds.width - scrollBarWidth
      : bounds.width;

    if (totalWidth < properWidth) {
      //  center columns don't fill center space
      //  recompute to match bounds.width
      const missingRatio = (properWidth - totalWidth) / totalWidth;
      colWidth = colWidth.map(value => {
        return value * (1 + missingRatio);
      });
    }
  }
  return colWidth;
};

// Insert a flex div with all the row elements hidden, to get the max height
export const insertHiddenDiv = (
  line: ReactElement[],
  widths: number[],
  completion: (height: number) => void
): void => {
  // Used to filter elements as <Link /> or <CommonEntityButton /> which cannot be rendered directly inside the body
  const isValidElement = (element: ReactElement) => {
    return (
      element.type &&
      (element.type.toString() === "div" || element.type.toString() === "p")
    );
  };

  // Inserting the main div
  const div = document.createElement("div");
  div.style.display = "flex";
  div.style.visibility = "hidden";
  document.body.appendChild(div);

  let counter = 0;
  // Then inserting the sub-divs inside it
  line.filter(isValidElement).forEach((element, i) => {
    const subDiv = document.createElement("div");
    subDiv.style.width = `${widths[i]}px`;
    div.appendChild(subDiv);
    ReactDOM.render(element, subDiv, () => {
      counter++;
      if (counter === line.filter(isValidElement).length) {
        // Triggering the end callback when we iterated all the elements by taking the main div height
        const height = div.offsetHeight;
        document.body.removeChild(div);
        completion(height);
      }
    });
  });
};

export const flattenNFirstElemsOfList = <T>(
  listElems: T[],
  nbElemsToReduce: number,
  flatteningFunction: (acc: T[]) => T
): T[] => {
  const groupedNFirstElems: T[] = [];

  return listElems.reduce((acc: T[], currentElem: T, idx: number): T[] => {
    if (idx < nbElemsToReduce) {
      groupedNFirstElems.push(currentElem);
    } else if (idx === nbElemsToReduce) {
      acc.push(flatteningFunction(groupedNFirstElems));
      acc.push(currentElem);
    } else {
      acc.push(currentElem);
    }

    return acc;
  }, []);
};
