import React, { useState } from "react";
import * as Yup from "yup";
import { commonArrayOfObjectFields } from "common/declarant/formik/formikHelper";
import { EmissionsSpeciesForm, PopulatedBuildingInArray } from "../utils/types";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import {
  convertBuildingDtoToPopulatedBuilding,
  convertSpecieEnumToOptionProps,
} from "./utils/converters";
import Button from "common/button";
import EmissionsArray from "./EmissionsArray";
import EmissionsModal from "./EmissionsModal";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { makeStyles } from "@material-ui/styles";
import { useChoiceSelectFieldGenerator } from "common/form/fields/helpers/generators";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import { isEqual } from "lodash";
import {
  convertDeclarationDtoUsingBuildings,
  convertDeclarationDtoUsingEmissionsSpeciesForm,
} from "../utils/converters";
import {
  PATH_AIR_ELEVAGE_DETAIL,
  PATH_AIR_ELEVAGE_DETAIL_ESPECES,
} from "common/path/path18Now";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { elevageArrayBuildingsRequired } from "common/declarant/formik/formikMessages";
import Row from "common/presentational/Row";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

interface EmissionsWhenMtdUncheckedProps {
  buildingsInArray: PopulatedBuildingInArray[];
  setBuildingsInArray: (buildings: PopulatedBuildingInArray[]) => void;
  formSpeciesEmissionsProps: FormikBlocRefAndState<EmissionsSpeciesForm>;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
  },
  addNH3EmissionButton: {
    marginRight: "10%",
    marginTop: "30px",
    padding: "5px",
    alignSelf: "flex-end",
  },
  dropdown: {
    zIndex: 11,
  },
});

const validationSchema = Yup.object().shape({
  species: commonArrayOfObjectFields,
});

const BlocEmissions = ({
  buildingsInArray,
  setBuildingsInArray,
  formSpeciesEmissionsProps,
}: EmissionsWhenMtdUncheckedProps) => {
  const classes = useStyles();
  const declaration = useDeclaration1919();

  const commonProps = {
    disabled: false,
    formPrefix: "bloc-emissions-mtd-checked",
    labelWidth: "50%",
    className: classes.inputField,
  };

  const initialValues: EmissionsSpeciesForm = {
    species: convertSpecieEnumToOptionProps(
      declaration.body.sections.air.elevage.detailNh3.especesDeclarees
    ),
  };

  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [buildingInModal, setBuildingInModal] = useState<
    PopulatedBuildingInArray | undefined
  >(undefined);

  const compareFunction = (
    a: PopulatedBuildingInArray,
    b: PopulatedBuildingInArray
  ): number => {
    if (a.displayedBuilding.buildingName !== b.displayedBuilding.buildingName) {
      return a.displayedBuilding.buildingName < b.displayedBuilding.buildingName
        ? -1
        : 1;
    }
    return 0;
  };

  return (
    <FormikBlocFullContext1919
      hasChanges={formSpeciesEmissionsProps.hasChanges}
      setHasChanges={formSpeciesEmissionsProps.setHasChanges}
      formikRef={formSpeciesEmissionsProps.formikRef}
      title="ÉMISSIONS DE NH3 PAR CATÉGORIE, PAR BÂTIMENT ET PAR EMPLACEMENT"
      validationSchema={validationSchema}
      initialValues={initialValues}
      updateHandler={(
        declaration: Declaration1919,
        values: EmissionsSpeciesForm
      ) => {
        declaration = convertDeclarationDtoUsingBuildings(
          declaration,
          buildingsInArray,
          values.species
        );
        return convertDeclarationDtoUsingEmissionsSpeciesForm(
          declaration,
          values
        );
      }}
      hasFormChanges={values => {
        const isSpeciesArrayUpdated = !isEqual(
          buildingsInArray,
          convertBuildingDtoToPopulatedBuilding(
            declaration.body.sections.air.elevage.detailNh3.batiments
          )
        );
        const isSpeciesDeclaredUpdated = !isEqual(
          values.species,
          convertSpecieEnumToOptionProps(
            declaration.body.sections.air.elevage.detailNh3.especesDeclarees
          )
        );
        return isSpeciesArrayUpdated || isSpeciesDeclaredUpdated;
      }}
      cancelAction={() => {
        setBuildingsInArray(
          convertBuildingDtoToPopulatedBuilding(
            declaration.body.sections.air.elevage.detailNh3.batiments
          )
        );

        return initialValues;
      }}
      preventSubmitWithErrors={() => {
        if (buildingsInArray.length === 0) {
          return {
            errorArrayRequired: elevageArrayBuildingsRequired,
          };
        }
        return {};
      }}
      pathToValidate={PATH_AIR_ELEVAGE_DETAIL}
      renderContent={({ values, errors }, shouldDisabledFields: boolean) => (
        <>
          <ChoiceSelect
            name="species"
            label="Veuillez sélectionner toutes les espèces de votre établissement"
            disabled={shouldDisabledFields}
            additionalClassName={classes.dropdown}
            isMulti
            options={selectPossibleValues.speciesDropdown}
            commentPath={PATH_AIR_ELEVAGE_DETAIL_ESPECES}
          />

          <Button
            text="AJOUTER UN BÂTIMENT"
            additionalClassname={classes.addNH3EmissionButton}
            onClick={() => setModalIsOpen(true)}
            isDisabled={shouldDisabledFields || values.species.length === 0}
          />

          <EmissionsArray
            species={values.species}
            buildingsInArray={buildingsInArray.sort(compareFunction)}
            setBuildingsInArray={setBuildingsInArray}
            onEdit={(data: PopulatedBuildingInArray) => {
              setBuildingInModal(data);
              setModalIsOpen(true);
            }}
            isDisabled={shouldDisabledFields}
          />

          <EmissionsModal
            isOpen={modalIsOpen}
            setIsOpen={setModalIsOpen}
            species={values.species}
            buildingsInArray={buildingsInArray}
            setBuildingsInArray={setBuildingsInArray}
            setBuildingInModal={setBuildingInModal}
            buildingInModal={buildingInModal}
          />

          <Row />

          <ErrorDisplayer message={errors.errorArrayRequired} />
        </>
      )}
    />
  );
};

export default BlocEmissions;
