import { DeclarationStateDtoStateEnum } from "api/gen";
import React, { ReactNode } from "react";
import {
  AnyDeclarationDto,
  BasicDeclarationHandlers,
  DeclarationHelpers,
  ErrorsAndWarningsHandlers,
} from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { CommentContextInterface } from "pages/CompanySpace/Comments/commentTypes";

interface ButtonProps<T> {
  title: string;
  isVisible: boolean;
  isEnabled: boolean;
  onClick: () => T;
}
interface LoadingButtonProps<T> extends ButtonProps<T> {
  isSpinnerVisible: boolean;
}

export enum CommentButtonState {
  DISABLED,
  EMPTY,
  FULL,
}

interface CommentButtonProps {
  state: CommentButtonState;
  isEnabled: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  hasComments?: boolean;
}

export type CancelButtonType = ButtonProps<void>;

export interface ValidationMessageProps {
  message: string;
  isAlwaysVisible: boolean;
}

export interface DummyGlobalFormActionsProps {
  validationMessage?: ValidationMessageProps;
  validateButton?: LoadingButtonProps<Promise<void> | void>;
  editButton?: LoadingButtonProps<Promise<void> | void>;
  cancelButton?: CancelButtonType;
  saveButton?: LoadingButtonProps<Promise<void> | void>;
  commentButton?: CommentButtonProps;
  additionalLeftContent?: ReactNode;
  validationTooltipContent?: string;
}

export interface GlobalFormActionsProps {
  validationTitle: string;
  isValidated: boolean;
  hasChanges: boolean;
  isValidateEnabled: boolean;
  actionsAllowed: boolean;
  editAction: () => Promise<void> | void;
  cancelAction: () => void;
  saveAction: () => Promise<void> | void;
  validateAction: () => Promise<void> | void;
  isValidateSpinnerVisible: boolean;
  isEditSpinnerVisible: boolean;
  isSaveSpinnerVisible: boolean;
  areGlobalCommentsAllowed: boolean;
  commentAction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  hasComments: boolean;
  validationMessage?: ValidationMessageProps;
  validationTooltipContent?: string;
}

export interface GlobalFormActionsInContextProps {
  validationTitle: string;
  isValidated: boolean;
  hasChanges: boolean;
  isValidateEnabled: boolean;
  editAction: () => Promise<void>;
  cancelAction: () => void;
  saveAction: () => Promise<void>;
  validateAction: () => Promise<void>;
  declarationState: DeclarationStateDtoStateEnum;
  declarationEtablissement: string;
  isAvailableToDeclarant?: boolean;
  isAvailableToAnyPrestataire?: boolean;
  isValidateSpinnerVisible: boolean;
  isEditSpinnerVisible: boolean;
  isSaveSpinnerVisible: boolean;
  areGlobalCommentsAllowed: boolean;
  commentAction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  hasComments: boolean;
  validationMessage?: ValidationMessageProps;
}

export interface CommonGlobalFormActionsInFullContextProps<
  VersionedDeclarationDto extends AnyDeclarationDto
> {
  validationTitle: string;
  hasChanges: boolean;
  isValidateEnabled: boolean;
  validationPath: string;
  updateHandler: (
    declaration: VersionedDeclarationDto
  ) => VersionedDeclarationDto;
  cancelAction: () => void;
  declarationState?: DeclarationStateDtoStateEnum;
  isAvailableToDeclarant?: boolean;
  isAvailableToAnyPrestataire?: boolean;
  validationMessage?: ValidationMessageProps;
  areGlobalCommentsAllowed?: boolean;
}

export interface GenericGlobalFormActionsInFullContextProps<
  VersionedDeclarationDto extends AnyDeclarationDto,
  VersionedDeclarationConstraintViolationDto extends object
> extends CommonGlobalFormActionsInFullContextProps<VersionedDeclarationDto> {
  getDeclarationState: (
    declarationDto: VersionedDeclarationDto
  ) => DeclarationStateDtoStateEnum;
  // versioned hooks needed in the FormAction
  useDeclaration: () => VersionedDeclarationDto;
  useDeclarationHelpers: () => DeclarationHelpers;
  useBasicDeclarationHandlers: () => BasicDeclarationHandlers<
    VersionedDeclarationDto
  >;
  useDeclarationErrorAndWarnings: (
    path: string,
    isStrict: boolean,
    isInitiallyTriggered: boolean
  ) => ErrorsAndWarningsHandlers<VersionedDeclarationConstraintViolationDto>;
  displayErrorAndWarning: (
    errors: VersionedDeclarationConstraintViolationDto[],
    warnings: VersionedDeclarationConstraintViolationDto[]
  ) => React.ReactElement;
  useComments: () => CommentContextInterface;
}
