import {
  AllowedDeclarationMethods,
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasEnum,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "../types";
import {
  QuotasBlocEmissionsDto2020,
  QuotasEmissionsBlocPlanSurveillanceDto2020,
  QuotasInstallationDto20Now,
} from "api/gen";
import {
  dtoExemptedFlowsToExemptedFlowDeclarations,
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  fluxDtoToFlowDeclaration,
  pointMesureDtoToMeasureDeclaration,
} from "../converter";
import { Formik } from "libAdapter/Formik/TypesPatternAdaptater";
import { findElementByIdOrNull } from "common/utils/methods";

//data function. Shouldn't be called directly
const isGasCO2 = (gas: GasEnum) => {
  return gas === GasEnum.CO2;
};

const isGasN2OOrPFC = (gas: GasEnum) => {
  return gas === GasEnum.N2O || gas === GasEnum.PFC;
};

// should be a method taking an object, but because of yup, currently taking only the gas key as a parameter
export const isOtherEmissionSubPartActivated = (
  gas: GasEnum | null
): boolean => {
  return !!gas && isGasN2OOrPFC(gas);
};

// should be a method taking an object, but because of yup, currently taking only the gas key as a parameter
export const isBiomasseSubPartActivated = (gas: GasEnum | null): boolean => {
  return !!gas && isGasCO2(gas);
};

export const overWriteEmissionDependantFields = (
  emission: MeasureEmissionInArray | AlternativeEmissionInArray
): void => {
  if (!isBiomasseSubPartActivated(emission.data.gas)) {
    emission.data.biomassEmission = null;
    emission.data.fossilEmission = null;
  }
  if (!isOtherEmissionSubPartActivated(emission.data.gas)) {
    emission.data.otherEmission = null;
  }
};

export const hasSameNames = (
  flowOrMeasures: FlowDeclarationProps[] | MeasureDeclarationProps[]
): boolean => {
  for (const obj of flowOrMeasures) {
    for (const otherObj of flowOrMeasures) {
      if (obj.name && obj.name === otherObj.name && obj.id !== otherObj.id) {
        return true;
      }
    }
  }
  return false;
};

export const getFlowNameById = (
  flowId: string,
  flowsInPage: FlowDeclarationProps[]
): string | null => {
  const correspondingFlowInPage = findElementByIdOrNull(flowId, flowsInPage);
  return correspondingFlowInPage ? correspondingFlowInPage.name : null;
};

const isComputedMethodsAllowed = (
  planEmissionsDto: QuotasEmissionsBlocPlanSurveillanceDto2020
) => {
  return !!(
    planEmissionsDto.calculBilanMassique ||
    planEmissionsDto.calculFacteurEmissionConversion ||
    planEmissionsDto.calculFacteurEmissionOxydation
  );
};

export const computeAllowedDeclarationsMethods = (
  planEmissionsDto: QuotasEmissionsBlocPlanSurveillanceDto2020
): AllowedDeclarationMethods => {
  return {
    alternative: !!planEmissionsDto.estMethodeAlternative,
    computed: isComputedMethodsAllowed(planEmissionsDto)
      ? {
          bilanMassique: !!planEmissionsDto.calculBilanMassique,
          facteurConversion: !!planEmissionsDto.calculFacteurEmissionConversion,
          facteurOxydation: !!planEmissionsDto.calculFacteurEmissionOxydation,
        }
      : false,
    mesure: !!planEmissionsDto.estMesure,
  };
};

export const generateSetterBlocEmissions = (
  installations: QuotasInstallationDto20Now[],
  blocEmissionsRef: React.MutableRefObject<Formik<
    BlocEmissionsFormValues
  > | null>,
  setAlternativeEmissionsInArray: (
    alternativesEmissionsInArray: AlternativeEmissionInArray[]
  ) => void,
  setMeasuredEmissionInArray: (
    measuredEmissionsInArray: MeasureEmissionInArray[]
  ) => void,
  setComputedEmissionsInArray: (
    computedEmissionsInArray: ComputedEmissionInArray[]
  ) => void
): ((blocEmissionsDto: QuotasBlocEmissionsDto2020) => void) => {
  return blocEmissionsDto => {
    const resetFlowDeclarations = blocEmissionsDto.flux.map(f =>
      fluxDtoToFlowDeclaration(f, installations)
    );
    const resetMeasureDeclarations = blocEmissionsDto.pointsMesure.map(p =>
      pointMesureDtoToMeasureDeclaration(p, installations)
    );

    const newAlternativeEmissionsInArray = blocEmissionsDto.emissionsMethodeAlternative.map(
      emission =>
        emissionSimpleDtoToAlternativeEmission(emission, installations)
    );
    const newMeasuredEmissionInArray = blocEmissionsDto.emissionsMesurees.map(
      emission =>
        emissionSimpleDtoToMeasuredEmission(emission, resetMeasureDeclarations)
    );
    const newComputedEmissionsInArray = blocEmissionsDto.emissionsCalculees.map(
      emission =>
        emissionSimpleDtoToComputedEmission(emission, resetFlowDeclarations)
    );

    const resetExemptedFlows = dtoExemptedFlowsToExemptedFlowDeclarations(
      blocEmissionsDto.fluxSoumisADerogation,
      resetFlowDeclarations
    );

    setAlternativeEmissionsInArray(newAlternativeEmissionsInArray);

    setMeasuredEmissionInArray(newMeasuredEmissionInArray);

    setComputedEmissionsInArray(newComputedEmissionsInArray);

    if (blocEmissionsRef.current) {
      blocEmissionsRef.current.state.values.measureDeclarations = resetMeasureDeclarations;
      blocEmissionsRef.current.state.values.flowDeclarations = resetFlowDeclarations;
      blocEmissionsRef.current.state.values.CO2Transfer = !!blocEmissionsDto.transfertCO2;
      blocEmissionsRef.current.state.values.exemption = !!blocEmissionsDto.derogationFrequenceEchantillonnage;
      blocEmissionsRef.current.state.values.exemptedFlows = resetExemptedFlows;
    }
  };
};
