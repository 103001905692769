import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { AlcDto20Now, QuotasInstallationDto20Now } from "api/gen";
import { alcDtoToJustificatifsFilesDto } from "./utils";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import {
  useJustificatifAlcPreliminaireFileHandler2020,
  useJustificatifAlcVerifieFileHandler2020,
} from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";

interface JustificatifsFilesLoaderProps {
  installation: QuotasInstallationDto20Now;
  filesPath: string;
  isAlcVerifie: boolean;
  shouldDisableFields: boolean;
}

const JustificatifsFilesLoader = ({
  installation,
  filesPath,
  isAlcVerifie,
  shouldDisableFields,
}: JustificatifsFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const openAlertModale = useAlertModale();
  const justificatifAlcVerifieFileHandler = useJustificatifAlcVerifieFileHandler2020(
    installation.id
  );
  const justificatifAlcPreliminaireFileHandler = useJustificatifAlcPreliminaireFileHandler2020(
    installation.id
  );

  const sectionFileHandler = isAlcVerifie
    ? justificatifAlcVerifieFileHandler
    : justificatifAlcPreliminaireFileHandler;

  const filesSection = isAlcVerifie
    ? FileSectionEnum.QUOTAS_JUSTIFICATIFS_ALC_VERIFIE
    : FileSectionEnum.QUOTAS_JUSTIFICATIFS_ALC_PRELIMINAIRE;

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER DOCUMENT(S) JUSTIFICATIF(S)"}
      filesPath={filesPath}
      section={filesSection}
      fileHandlers={sectionFileHandler}
      //
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(alcs: Record<string, unknown>[]) =>
        alcDtoToJustificatifsFilesDto(
          installation.id,
          (alcs as unknown) as AlcDto20Now[]
        )
      }
    />
  );
};

export default JustificatifsFilesLoader;
