import { MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum } from "api/gen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

const locationType: OptionPropsWithObject<
  MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum
>[] = [
  {
    value: 1,
    label: "Témoin",
    object: MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum.TEMOIN,
  },
  {
    value: 2,
    label: "Intérieur",
    object: MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum.INTERIEUR,
  },
  {
    value: 3,
    label: "Extérieur",
    object: MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum.EXTERIEUR,
  },
  {
    value: 4,
    label: "Limite",
    object: MesurePoussiereAtmospheriqueDto1819TypeDEmplacementEnum.LIMITE,
  },
];

export const JAUGE = "Jauge";
export const PLAQUETTE = "Plaquette";
const gaugeOrBooklet: ("Jauge" | "Plaquette")[] = [JAUGE, PLAQUETTE];

export const selectPossibleValues = {
  locationType: locationType,
  gaugeOrBooklet: gaugeOrBooklet,
};
