import React, { useState } from "react";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import {
  FiltresDeclarationDtoSortByEnum,
  SimpleInstallationDto,
} from "api/gen";
import { useDeclarationVerificateurTableLines } from "./verificateurTableLines";
import { VersionedContactInfoModal } from "pages/InspectorSpace/ContactInfosModal/VersionedContactInfoModal";
import { useVerificateurTableauHeader } from "./verificateurTableHeader";

interface CompanyTableProps {
  declarations: SimpleInstallationDto[];
  sorter: FiltresDeclarationDtoSortByEnum | null;
  isSortReversed: boolean | null;
  updateSortFilter: (
    sorter: FiltresDeclarationDtoSortByEnum | null,
    isSortReversed: boolean | null
  ) => void;
}

export const DeclarationsTable = ({
  declarations,
  sorter,
  isSortReversed,
  updateSortFilter,
}: CompanyTableProps): React.ReactElement => {
  const [contact, setContact] = useState<SimpleInstallationDto | null>(null);

  const header = useVerificateurTableauHeader(
    sorter,
    isSortReversed,
    updateSortFilter
  );
  const lines = useDeclarationVerificateurTableLines(
    declarations,
    declaration => setContact(declaration)
  );

  return (
    <>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredColWidths={[
          170,
          130,
          80,
          150,
          150,
          150,
          150,
          150,
          150,
          150,
          150,
          90,
        ]}
      />

      <VersionedContactInfoModal
        isOpen={contact !== null}
        year={contact && contact.annee}
        company={contact && contact.etablissement}
        onRequestClose={() => setContact(null)}
      />
    </>
  );
};
