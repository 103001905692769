import {
  QuotasAllocationsEmailsVerificateursDto20Now,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
} from "api/gen";
import { Declaration2323 } from "./declarationHooks2323";

export const updateHandlerBilanTotal2323 = (
  declaration: Declaration2323,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration2323 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddressEmissions2323 = (
  declaration: Declaration2323,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration2323 => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
export const updateHandlerEmailsAddressAllocations2323 = (
  declaration: Declaration2323,
  values: QuotasAllocationsEmailsVerificateursDto20Now | null
): Declaration2323 => {
  if (values) {
    declaration.body.sections.quotas.allocations.blocEmails = values;
  }
  return declaration;
};
