import {
  AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum,
  ReferenceItemPolluantDto,
  ReferencePolluantDefaultUniteItemDto,
} from "api/gen";
import { arrayReferencePolluantUnitsLabel } from "../../../utils/selectPossibleUnits";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import MessageInfoField from "common/form/MessageInfoField";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { convertConcentrationToTargetUnit } from "./utils";

const useStyles = makeStyles({
  concentrationAverage: {
    fontWeight: "bold",
    textDecoration: "underline",
  },
});

interface SubstanceInCommonUnitMessageProps {
  substance: ReferenceItemPolluantDto;
  concentrationDebit: number;
  concentrationDebitUnit: AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum;
  polluantDefaultUniteMap: Map<number, ReferencePolluantDefaultUniteItemDto>;
}
const SubstanceInCommonUnitMessage = ({
  substance,
  concentrationDebit,
  concentrationDebitUnit,
  polluantDefaultUniteMap,
}: SubstanceInCommonUnitMessageProps): React.ReactElement => {
  const classes = useStyles();
  const substanceRestorationCode = substance.restorationCode;

  const subtanceDefaultUnite = polluantDefaultUniteMap.get(
    substanceRestorationCode
  );
  if (!subtanceDefaultUnite) {
    return <></>;
  }
  const defaultUniteLabel =
    arrayReferencePolluantUnitsLabel[
      subtanceDefaultUnite.concentrationUsualUnit
    ];
  const quantityInDefaultUnite = convertConcentrationToTargetUnit(
    concentrationDebit,
    concentrationDebitUnit,
    subtanceDefaultUnite.concentrationUsualUnit
  );

  const message = (
    <>
      La concentration de “{substance.nom}” déclarée correspond à{" "}
      <span className={classes.concentrationAverage}>
        {computeNumberWithSeparator(quantityInDefaultUnite)} {defaultUniteLabel}
      </span>
    </>
  );

  return <MessageInfoField message={message} />;
};

export default SubstanceInCommonUnitMessage;
