import React, { ReactChild } from "react";

export const computeUnitTCO2PerYear: (
  unitClassName: string
) => ReactChild = () => (
  <>
    tCO<sub>2</sub>e/an
  </>
);

export const computeLabelNH3: (labelClassName: string) => ReactChild = () => (
  <>
    NH<sub>3</sub>
  </>
);

export const computeLabelN2O: (labelClassName: string) => ReactChild = () => (
  <>
    N<sub>2</sub>O
  </>
);

export const computeLabelCH4: (labelClassName: string) => ReactChild = () => (
  <>
    CH<sub>4</sub>
  </>
);

export const computeLabelPM10: (labelClassName: string) => ReactChild = () => (
  <>
    PM<sub>10</sub>
  </>
);
