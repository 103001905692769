import { Declaration22Now } from "./declarationHooks22Now";
import {
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
} from "api/gen";

export const updateHandlerBilanTotal22Now = (
  declaration: Declaration22Now,
  values: QuotasBlocEmissionsBilanTotalDto20Now | null
): Declaration22Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocBilanTotal = values;
  }
  return declaration;
};

export const updateHandlerEmailsAddress22Now = (
  declaration: Declaration22Now,
  values: QuotasBlocEmissionsEmailsVerificateursDto20Now | null
): Declaration22Now => {
  if (values) {
    declaration.body.sections.quotas.emissions.blocEmails = values;
  }
  return declaration;
};
