import { RefObject } from "react";
import { Formik, FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";

export enum ErrorLevel {
  Warning,
  Blocking,
}

export type ValueOf<T> = T[keyof T]; // inspired of https://stackoverflow.com/a/49286056/7059810

export type ClassesType<T extends string> = Record<T, string>;

export type refType = HTMLElement | null;
export type formikRefType<Values = FormikValues> = RefObject<
  Formik<Values>
> | null;

export interface ErrorProps {
  text: string;
  errorLevel: ErrorLevel;
}

export interface PathLink {
  label: string;
  to: string;
}

export interface TrailItem {
  pathlinks: PathLink[];
}

export interface ImageProps {
  alt: string;
  src: string;
}

//for easier use of callback setter, when we use a setState where we give a callback (and not directly the value)
export type CallbackSetter<T> = (callback: (elderValues: T) => T) => void;

/**
 * Make all properties in T nullable
 */
export type Nullable<T> = { [P in keyof T]: T[P] | null };

// enum supersets https://devimalplanet.com/typescript-how-to-extend-one-enum-from-another
export const QuotaWorkflowTarget = {
  QUOTA_ALLOCATIONS: "QUOTA_ALLOCATIONS",
  QUOTA_EMISSIONS: "QUOTA_EMISSIONS",
} as const;
export type QuotaWorkflowTarget = typeof QuotaWorkflowTarget[keyof typeof QuotaWorkflowTarget];

export const WorkflowTarget = {
  ...QuotaWorkflowTarget,
  GLOBAL: "GLOBAL",
} as const;
export type WorkflowTarget = typeof WorkflowTarget[keyof typeof WorkflowTarget];

export const readonlySet = <T>(set: Set<T>): ReadonlySet<T> => set;
// copypasted from https://stackoverflow.com/a/71725903/7059810
