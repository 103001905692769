import * as Yup from "yup";
import { Errors } from "common/form/utils";
import {
  PolluantEauDtoMethodeEnum,
  PolluantEauDtoReferenceMethodeEnum,
  PolluantEauDtoTypeRejetEnum,
} from "api/gen/api";
import {
  isFieldIncludingImportedActive,
  isFieldMethodReferenceActive,
  isPurificationYieldActive,
} from "../utils";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  subStringFieldValidationMultipleCombinedScheme,
} from "common/declarant/formik/formikHelper";
import {
  eauMasseEmiseMessage,
  eauPurificationYieldUnder100Message,
  eauSubstanceAlreadyInArrayMessage,
  requiredMessage,
} from "common/declarant/formik/formikMessages";
import _ from "lodash";
import { PolluantInArray, PolluantInModale } from "../types";

export const isFieldMethodDescriptionRequired = (
  usedMethod: PolluantEauDtoMethodeEnum | null,
  methodReference: PolluantEauDtoReferenceMethodeEnum | null
): boolean => {
  return (
    isFieldMethodReferenceActive(usedMethod) &&
    (usedMethod === PolluantEauDtoMethodeEnum.MESURE ||
      (usedMethod === PolluantEauDtoMethodeEnum.CALCUL &&
        methodReference !== null &&
        methodReference !== PolluantEauDtoReferenceMethodeEnum.AUT))
  );
};

export const singlePolluantValidationSchema = Yup.object().shape({
  pollutingSubstance: commonObjectFields,
  type: Yup.mixed<PolluantEauDtoTypeRejetEnum>().required(requiredMessage),
  emission: commonPositiveNumberFields,
  includingImported: Yup.mixed<PolluantEauDtoTypeRejetEnum>().when("type", {
    is: (type: PolluantEauDtoTypeRejetEnum) =>
      isFieldIncludingImportedActive(type),
    then: commonPositiveNumberFields.max(
      Yup.ref("emission"),
      eauMasseEmiseMessage
    ),
    otherwise: Yup.number().nullable(),
  }),
  includingAccidental: commonPositiveNumberFields.max(
    Yup.ref("emission"),
    eauMasseEmiseMessage
  ),
  usedMethod: Yup.mixed<PolluantEauDtoMethodeEnum>().required(requiredMessage),
  methodReference: Yup.mixed<PolluantEauDtoReferenceMethodeEnum>().when(
    "usedMethod",
    {
      is: (usedMethod: PolluantEauDtoMethodeEnum | null) =>
        isFieldMethodReferenceActive(usedMethod),
      then: Yup.mixed<PolluantEauDtoReferenceMethodeEnum>().required(
        requiredMessage
      ),
      otherwise: Yup.mixed<PolluantEauDtoReferenceMethodeEnum>().nullable(),
    }
  ),
  methodDescription: subStringFieldValidationMultipleCombinedScheme(
    ["usedMethod", "methodReference"],
    isFieldMethodDescriptionRequired
  ),
  normes: Yup.array().nullable(),
  purificationYield: Yup.mixed<PolluantEauDtoTypeRejetEnum>().when("type", {
    is: (type: PolluantEauDtoTypeRejetEnum | null) =>
      isPurificationYieldActive(type),
    then: commonPositiveNumberFieldsNullable.max(
      100,
      eauPurificationYieldUnder100Message
    ),
    otherwise: Yup.number().nullable(),
  }),
});

// Validation used both for element in array and for modal
export const additionalValidation = (
  values: PolluantInModale,
  polluantInModal: PolluantInArray | null,
  polluantsInArray: PolluantInArray[]
): Errors<PolluantInModale> => {
  const errors: Errors<PolluantInModale> = {};
  // Check pollutingSubstance not already in array, unless it is the polluant currently edited in modal
  for (const polluant of polluantsInArray) {
    if (
      polluantInModal &&
      polluant.data.id !== polluantInModal.data.id &&
      _.isEqual(values.pollutingSubstance, polluant.data.pollutingSubstance) &&
      _.isEqual(values.type, polluant.data.type)
    ) {
      errors.pollutingSubstance = eauSubstanceAlreadyInArrayMessage;
      errors.type = eauSubstanceAlreadyInArrayMessage;
      break;
    }
  }
  return errors;
};

export const calcErrors = (
  polluant: PolluantInModale,
  polluantsInArray: PolluantInArray[]
): Errors<PolluantInModale> => {
  const errors: Errors<PolluantInModale> = {};
  // errors from validationSchema
  try {
    singlePolluantValidationSchema.validateSync(polluant, {
      abortEarly: false,
    });
  } catch (err) {
    err.inner.forEach(
      (errorData: { path: string; message: string | undefined }) => {
        const errorKey = errorData.path as keyof PolluantInModale; // we know the path is a valid key of Polluant (as long as we keep polluant and schema with same key, that is)
        errors[errorKey] = errorData.message;
      }
    );
  }
  // add additionalError
  return {
    ...additionalValidation(polluant, null, polluantsInArray),
    ...errors,
  };
};
