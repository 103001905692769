import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "../../../common/bloc/DummyUnActionedBloc";

export const BlocMails = (): React.ReactElement => {
  const {
    referenceEmailsDestinatairesController,
    referenceInspecteursEmailQuotasController,
    referencePostCampaignEmailController,
  } = useAuthenticatedApi();

  const uploadContactInspectionHandler = useCallback(
    (file: File) =>
      referenceEmailsDestinatairesController.uploadEmailsDestinatairesUsingPOST(
        file
      ),
    [referenceEmailsDestinatairesController]
  );
  const downloadContactInspectionHandler = useCallback(() => {
    return referenceEmailsDestinatairesController.downloadEmailsDestinatairesAsCsvUsingGET();
  }, [referenceEmailsDestinatairesController]);

  const uploadNotificationInspectionQuotasHandler = useCallback(
    (file: File) =>
      referenceInspecteursEmailQuotasController.uploadInspecteursEmailQuotasUsingPOST(
        file
      ),
    [referenceInspecteursEmailQuotasController]
  );
  const downloadNotificationInspectionQuotasHandler = useCallback(
    () =>
      referenceInspecteursEmailQuotasController.downloadInspecteursEmailQuotasAsCsvUsingGET(),
    [referenceInspecteursEmailQuotasController]
  );

  const uploadPostCampaignEmailHandler = useCallback(
    (file: File) =>
      referencePostCampaignEmailController.uploadPostCampaignEmailsUsingPOST(
        file
      ),
    [referencePostCampaignEmailController]
  );
  const downloadPostCampaignEmailHandler = useCallback(
    () =>
      referencePostCampaignEmailController.downloadPostCampaignEmailAsCsvUsingGET(),
    [referencePostCampaignEmailController]
  );

  return (
    <DummyUnActionedBloc
      title={"Mails"}
      renderContent={() => {
        return (
          <>
            <BlocReferentiel
              title={"Mails contact inspection"}
              downloadHandler={downloadContactInspectionHandler}
              filename={"contact_inspection.csv"}
              uploadHandler={uploadContactInspectionHandler}
            />

            <BlocReferentiel
              title={"Mails notification inspection Quotas"}
              downloadHandler={downloadNotificationInspectionQuotasHandler}
              filename={"notification_inspection_quotas.csv"}
              uploadHandler={uploadNotificationInspectionQuotasHandler}
            />

            <BlocReferentiel
              title={"Mails destinataires des notifications post campagne"}
              downloadHandler={downloadPostCampaignEmailHandler}
              filename={"notification_post_campagne.csv"}
              uploadHandler={uploadPostCampaignEmailHandler}
            />
          </>
        );
      }}
    />
  );
};

export default BlocMails;
