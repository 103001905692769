import React, { ReactElement, useMemo } from "react";
import { useUserData } from "Authenticator/UserData";
import { DeclarationState } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { QuotaWorkflowTarget } from "common/utils/types";
import {
  useDeclaration24Now,
  useDeclarationHelpers24Now,
} from "../../../versionedElements/declarationHooks24Now";
import { IN_REVIEW, STARTED, VERIFIED } from "common/messages/dashboardMessage";
import { areAllQuotaTargetBlocsValidated } from "../../../Quotas/utils/validation";
import SectionNextActionCard from "pages/CompanySpace/from21/toNow/Dashboard/Components/Cards/SectionNextActionCard";

const QuotasNextActionCard = ({
  quotaState,
  workflowTarget,
}: {
  quotaState: DeclarationState;
  workflowTarget: QuotaWorkflowTarget;
}): ReactElement => {
  const declaration = useDeclaration24Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();
  const mustSendToVerificateur = useMemo(
    () =>
      quotaState.isDeclarationStateContaining([STARTED, IN_REVIEW]) &&
      areAllQuotaTargetBlocsValidated(
        workflowTarget,
        declaration,
        isPathValidatedInDeclaration
      ),
    [quotaState, workflowTarget, declaration, isPathValidatedInDeclaration]
  );

  const { isDeclarant } = useUserData();
  if (!isDeclarant) {
    return <></>;
  }

  if (mustSendToVerificateur) {
    return (
      <SectionNextActionCard>
        Veuillez transmettre la déclaration au vérificateur.
      </SectionNextActionCard>
    );
  }

  const mustSendToInspecteur = quotaState.isDeclarationStateContaining([
    VERIFIED,
  ]);
  if (mustSendToInspecteur) {
    return (
      <SectionNextActionCard>
        Veuillez transmettre la déclaration à l'inspection.
      </SectionNextActionCard>
    );
  }

  return <></>;
};

export default QuotasNextActionCard;
