import {
  DeclarationBodyDto2222,
  DeclarationDto2222,
  DeclarationSectionsDto2222,
} from "api/gen";
import { useCallback } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
} from "../../../DeclarationApiContext/utils/genericHooks";
import { BasicDeclarationHandlers } from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import {
  Declaration23Now,
  DeclarationBody23Now,
  DeclarationSections23Now,
} from "../../../from23/toNow/versionedElements/declarationHooks23Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2022;
export const MAX_YEAR = undefined;

// Define all the DTO types for this version.
export type Declaration22Now = DeclarationDto2222 | Declaration23Now;

export type DeclarationBody22Now =
  | DeclarationBodyDto2222
  | DeclarationBody23Now;
export type DeclarationSections22Now =
  | DeclarationSectionsDto2222
  | DeclarationSections23Now;

export const useDeclaration22Now = (
  shouldThrowIfWrongYear?: boolean
): Declaration22Now => {
  return useDeclaration<Declaration22Now>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationHelpers22Now = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers22Now = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration22Now> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration22Now): DeclarationSections22Now =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration22Now>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};
