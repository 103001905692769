import React, { useCallback, useEffect, useState } from "react";
import { useTemporaryStorage } from "./storage";
import { UserContext, UserData } from "./UserData";
import Spinner from "common/presentational/Spinner";
import {
  AuthenticatedApiContext,
  AuthenticatedApiContextInterface,
  buildAuthenticatedApi,
} from "./AuthenticatedApi";
import {
  ACTIVE_RIGHT,
  connectUser,
  reloadUserData,
  updateJwtAndActiveRight,
} from "./utils";
import { AuthDroitDto, AuthJwtDto } from "api/gen";
import { AuthenticatedUserContext } from "./AuthenticatedUser";
import FailedToLog from "pages/CommonSpace/AppPage/FailedToLog";
import { useHistory } from "react-router-dom";

export enum AUTHENTICATION_STATUS {
  NONE,
  NO_RIGHT_OVER_GEREP,
  UNKNOWN_ERROR_OCCURRED,
  RECONNECTION_TO_MON_ICPE_REQUIRED,
}

interface Props {
  children: React.ReactNode;
}

const parseAuthJwtDto = (stored: string): AuthJwtDto | null => {
  const parsed: unknown = JSON.parse(stored);
  if (typeof parsed === "object" && parsed !== null) {
    let foundKey = 0;
    for (const [key, value] of Object.entries(parsed)) {
      if (key === "jwt" && typeof value === "string") {
        foundKey++;
      }
      if (key === "monAIOTJWT" && typeof value === "string") {
        foundKey++;
      }
    }

    if (foundKey === 2) {
      return parsed as AuthJwtDto;
    }
  }

  return null;
};

const Authenticator = ({ children }: Props): React.ReactElement => {
  const history = useHistory();
  const [userData, setUserData] = useState<UserData | null>(null);
  const [authStatus, setAuthStatus] = useState<AUTHENTICATION_STATUS>(
    AUTHENTICATION_STATUS.NONE
  );
  const [authJwtDto, setAuthJwtDto] = useTemporaryStorage<AuthJwtDto>(
    "jwt",
    parseAuthJwtDto,
    function(newJwt) {
      if (newJwt === null) {
        setAuthStatus(AUTHENTICATION_STATUS.UNKNOWN_ERROR_OCCURRED);
      } else {
        setUserData(null);

        reloadUserData(newJwt, setAuthJwtDto, setUserData, setAuthStatus);
      }
    }
  );

  // memoised authenticatedApi to prevent re-builing when user info change
  const memoApi: AuthenticatedApiContextInterface | null = authJwtDto.current
    ? buildAuthenticatedApi(authJwtDto.current)
    : null;

  const setActiveRight = useCallback(
    (right: AuthDroitDto) => {
      if (authJwtDto.current === null) {
        throw Error("Can not change active right without authenticated user.");
      }

      setUserData(null);

      updateJwtAndActiveRight(
        authJwtDto.current,
        right,
        setAuthJwtDto,
        setUserData,
        setAuthStatus
      );
    },
    [authJwtDto, setAuthJwtDto, setUserData]
  );

  const logOut = useCallback(() => {
    window.localStorage.removeItem(ACTIVE_RIGHT);
    window.localStorage.removeItem("temporaryStorage.jwt");
    setUserData(null);
    window.location.href = process.env.REACT_APP_LOGOUT_URL || "";
  }, [setUserData]);

  useEffect(() => {
    let ignore = false;

    async function connect() {
      const redirectPath = await connectUser(
        authJwtDto.current,
        setAuthJwtDto,
        setUserData,
        setAuthStatus,
        true
      );
      if (redirectPath && !ignore) {
        history.push(redirectPath);
      }
    }
    connect();

    return () => {
      ignore = true;
    };
  }, [authJwtDto, setAuthJwtDto, history]);

  const authJwtApi =
    authJwtDto.current !== null
      ? { jwt: authJwtDto.current, setJwt: setAuthJwtDto }
      : null;

  if (userData && authJwtDto.current) {
    return (
      <AuthenticatedUserContext.Provider value={authJwtApi}>
        <UserContext.Provider
          value={{
            ...userData,
            setActiveRight: setActiveRight,
            logout: logOut,
          }}
        >
          <AuthenticatedApiContext.Provider value={memoApi}>
            {children}
          </AuthenticatedApiContext.Provider>
        </UserContext.Provider>
      </AuthenticatedUserContext.Provider>
    );
  } else if (authStatus === AUTHENTICATION_STATUS.NO_RIGHT_OVER_GEREP) {
    return (
      <FailedToLog message="La connexion a échoué car vous ne disposez des droits nécessaires pour accéder à la plateforme. Veuillez contacter votre inspecteur référent." />
    );
    // Si un troisième cas, il faudrait utiliser un dict {erreur -> message d'erreur}
  } else if (
    authStatus === AUTHENTICATION_STATUS.UNKNOWN_ERROR_OCCURRED ||
    authStatus === AUTHENTICATION_STATUS.RECONNECTION_TO_MON_ICPE_REQUIRED
  ) {
    return (
      <FailedToLog message="La connexion au portail d'authentification MonAIOT a échoué." />
    );
  } else {
    return <Spinner />;
  }
};

export default Authenticator;
