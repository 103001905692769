import { ErrorLevel, ErrorProps } from "common/utils/types";
import classNames from "classnames";

/**
 *
 * @param classes             CSS properties, must contains disabled, active, container, warning, error
 * @param error               displayed error props (if blocking error is computed, if warning warning is computed)
 * @param isDisabled          is field disabled (if true disabled is computed and active is not)
 * @param additionalClassName additional CSS class style (alway computed)
 */
export function computeClassname(
  classes: Record<
    "disabled" | "active" | "container" | "warning" | "error",
    string
  >,
  error: ErrorProps | null,
  isDisabled?: boolean,
  additionalClassName?: string
): string {
  const computeClassName = (error: ErrorProps | null, isDisabled?: boolean) => {
    //we shall give an error when we may deduce it from the fieldProps.
    return classNames(
      classes.container,
      {
        [classes.error]: error && error.errorLevel === ErrorLevel.Blocking,
        [classes.warning]: error && error.errorLevel === ErrorLevel.Warning,
        [classes.disabled]: isDisabled,
        [classes.active]: !isDisabled,
      },
      additionalClassName
    );
  };

  return computeClassName(error, isDisabled);
}
