import { findElementMatchingTemplate } from "common/utils/methods";
import {
  ActiviteDto23NowTrancheEmployeEnum,
  EquipeGunItemDto,
  ExternalActiviteInseeDto23NowEtatAdministratifEnum,
  NatureServiceWithEnumDto,
  ReferenceItemCodeApeDto,
  ReferenceItemGeoCodeInseeDto,
  ServiceGunItemDto,
} from "api/gen";
import { InspectionServiceDto } from "./types";

export const arrayExternalAdministratifStateLabel: {
  [key in ExternalActiviteInseeDto23NowEtatAdministratifEnum]: string;
} = {
  CLOSED: "Fermé",
  IN_ACTIVITY: "En Activité",
};

export const arrayTrancheEmployeLabel: {
  [key in ActiviteDto23NowTrancheEmployeEnum]: string;
} = {
  NN: "Unités non employeuses",
  ZERO: "0 salarié",
  ONE_OR_TWO: "1 ou 2 salariés",
  THREE_TO_FIVE: "3 à 5 salariés",
  SIX_TO_NINE: "6 à 9 salariés",
  TEN_TO_NINETEEN: "10 à 19 salariés",
  TWENTY_TO_FORTY_NINE: "20 à 49 salariés",
  FIFTY_TO_NINETY_NINE: "50 à 99 salariés",
  ONE_HUNDRED_TO_TWO_HUNDRED_EXCL: "100 à 199 salariés",
  TWO_HUNDRED_TO_TWO_HUNDRED_FIFTY_EXCL: "200 à 249 salariés",
  TWO_FIFTY_TO_FIVE_HUNDRED_EXCL: "250 à 499 salariés",
  FIVE_HUNDRED_TO_ONE_THOUSAND_EXCL: "500 à 999 salariés",
  ONE_THOUSAND_TO_TWO_THOUSAND_EXCL: "1 000 à 1 999 salariés",
  TWO_THOUSAND_TO_FIVE_THOUSAND_EXCL: "2 000 à 4 999 salariés",
  FIVE_THOUSAND_TO_TEN_THOUSAND_EXCL: "5 000 à 9 999 salariés",
  TEN_THOUSAND_AND_MORE: "10 000 salariés et plus",
};
export const getRefApeDtoById = (
  apeRef: ReferenceItemCodeApeDto[],
  apeId: string
): ReferenceItemCodeApeDto | null => {
  return findElementMatchingTemplate({ uuid: apeId }, apeRef);
};

export const getRefCityInseeById = (
  cityRef: ReferenceItemGeoCodeInseeDto[],
  cityId: string
): ReferenceItemGeoCodeInseeDto | null => {
  return findElementMatchingTemplate({ uuid: cityId }, cityRef);
};

export const getRefCityInseeByNomCommune = (
  commune: string | null,
  referentiel: ReferenceItemGeoCodeInseeDto[]
): ReferenceItemGeoCodeInseeDto | null => {
  return referentiel.find(ref => ref.nomCommune === commune) || null;
};

export const computeServiceInspectionList = (
  servicesGunList: ServiceGunItemDto[],
  gerepServiceList: NatureServiceWithEnumDto[]
): InspectionServiceDto[] => {
  return servicesGunList
    .map(service => {
      return {
        id: service.identifiantService,
        libelle: service.libelleCourt,
      };
    })
    .concat(
      gerepServiceList.map(service => {
        return {
          id: service.natureService.id,
          libelle: service.natureService.nom,
        };
      })
    );
};

export const computeEquipesGunMap = (
  equipeGunList: EquipeGunItemDto[]
): Map<string, EquipeGunItemDto> => {
  return new Map(
    equipeGunList.map(equipe => [equipe.identifiantEquipe, equipe])
  );
};
