import React, { ReactChild } from "react";
import dangerIcon from "icons/danger.svg";
import successIcon from "icons/success.svg";
import { makeStyles } from "@material-ui/styles";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";

const useStyles = makeStyles({
  container: {
    display: "flex",
    margin: "0px 0px 25px 0px",
  },
  title: {
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "unset",
    width: "100%",
  },
  picture: {
    width: "50px",
    marginRight: "5%",
  },
});

export enum MessageModaleType {
  ALERT,
  SUCCESS,
}

interface MessageModaleContentProps {
  type: MessageModaleType;
  children: ReactChild;
  onClose: () => void;
}

export const MessageModaleContent = ({
  type,
  children,
  onClose,
}: MessageModaleContentProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.container}>
        {type === MessageModaleType.ALERT && (
          <img src={dangerIcon} alt={"Attention"} className={classes.picture} />
        )}
        {type === MessageModaleType.SUCCESS && (
          <img src={successIcon} alt={"OK"} className={classes.picture} />
        )}

        <div className={classes.title}>{children}</div>
      </div>

      <DummyGlobalFormActions
        validateButton={{
          title: "Ok",
          isVisible: true,
          isEnabled: true,
          onClick: onClose,
          isSpinnerVisible: false,
        }}
      />
    </>
  );
};
