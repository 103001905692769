import * as Yup from "yup";
import { atLeastOneMessage } from "common/declarant/formik/formikMessages";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
} from "common/declarant/formik/formikHelper";
import {
  parseToUndefinedIfEmptyString,
  parseToUndefinedIfNull,
} from "common/form/utils";
import { ReferenceItemSandreDto } from "api/gen";

const RejetRaccordeSingleValueShape = (
  isOver50000Treshold: boolean,
  isMegathermie: boolean,
  substanceLength: number
) => ({
  milieuRaccorde: Yup.object()
    .transform(parseToUndefinedIfNull)
    .when("milieuIsole", {
      is: (value: ReferenceItemSandreDto | null) => !value,
      then: Yup.object()
        .transform(parseToUndefinedIfNull)
        .required(atLeastOneMessage),
    }),
  stationRaccorde: Yup.object()
    .transform(parseToUndefinedIfNull)
    .when("milieuRaccorde", {
      is: (value: ReferenceItemSandreDto | null) => !!value,
      then: commonObjectFields,
    }),
  volumeRaccorde: Yup.number()
    .transform(parseToUndefinedIfEmptyString)
    .transform(parseToUndefinedIfNull)
    .when("milieuRaccorde", {
      is: (value: ReferenceItemSandreDto | null) =>
        !!value && (substanceLength !== 0 || isOver50000Treshold),
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }),
  chaleurRaccorde: Yup.number()
    .transform(parseToUndefinedIfEmptyString)
    .transform(parseToUndefinedIfNull)
    .when("milieuRaccorde", {
      is: (value: ReferenceItemSandreDto | null) => !!value && isMegathermie,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }),
});

const RejetIsoleSingleValueShape = (
  isOver50000Treshold: boolean,
  isMegathermie: boolean,
  substanceLength: number
) => ({
  milieuIsole: Yup.object()
    .transform(parseToUndefinedIfNull)
    .when("milieuRaccorde", {
      is: (value: ReferenceItemSandreDto | null) => !value,
      then: Yup.object()
        .transform(parseToUndefinedIfNull)
        .required(atLeastOneMessage),
    }),
  volumeIsole: Yup.number()
    .transform(parseToUndefinedIfEmptyString)
    .transform(parseToUndefinedIfNull)
    .when("milieuIsole", {
      is: (value: ReferenceItemSandreDto | null) =>
        !!value && (substanceLength !== 0 || isOver50000Treshold),
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }),
  chaleurIsole: Yup.number()
    .transform(parseToUndefinedIfEmptyString)
    .transform(parseToUndefinedIfNull)
    .when("milieuIsole", {
      is: (value: ReferenceItemSandreDto | null) => !!value && isMegathermie,
      then: commonPositiveNumberFields,
      otherwise: commonPositiveNumberFieldsNullable,
    }),
});

export const validationSchema = (
  isOver50000Treshold: boolean,
  isMegathermie: boolean,
  substanceLength: number
) => {
  return Yup.object()
    .shape(
      RejetRaccordeSingleValueShape(
        isOver50000Treshold,
        isMegathermie,
        substanceLength
      ),
      // To avoid cyclic dependency
      [["milieuRaccorde", "milieuIsole"]]
    )
    .shape(
      RejetIsoleSingleValueShape(
        isOver50000Treshold,
        isMegathermie,
        substanceLength
      ),
      // To avoid cyclic dependency
      [["milieuRaccorde", "milieuIsole"]]
    );
};
