import {
  CampaignDto,
  FiltresDeclarationDto,
  FiltresDeclarationDtoDeclarationGlobalStateEnum,
  FiltresDeclarationDtoQuotasAllocationsStateEnum,
  FiltresDeclarationDtoQuotasEmissionsStateEnum,
  FiltresDeclarationDtoSortByEnum,
} from "api/gen/api";
import {
  DeclarationFilters,
  lessThanValidation,
  moreThanValidation,
} from "./types";

export const computeInitialFilterValues = (
  campaign: CampaignDto
): DeclarationFilters => ({
  region: null,
  departement: null,
  declarationGlobalState: null,
  quotasEmissionsState: null,
  quotasAllocationsState: null,
  declarationValidationPercent: null,
  moreOrLessThanValidationPercent: lessThanValidation,
  lastModificationTimeDeclaring: null,
  lastModificationTimeInspector: null,
  beforeLastModificationDeclaring: false,
  beforeLastModificationInspector: false,
  carriere: false,
  elevage: false,
  eprtr: false,
  quotasEmissions: false,
  quotasAllocations: false,
  quotasWithAnyExclusions: false,
  consoOfSolvant: false,
  sortBy: FiltresDeclarationDtoSortByEnum.ETABLISSEMENT_NAME,
  ascending: true,
  searchByIdOrNameOrSiret: null,
  searchByNimNumber: null,
  isdi: false,
  isdnd: false,
  annees: [campaign.annee],
  selectedSections: [],
  selectedFolderSections: [],
  rienADeclarer: null,
});

export const declarationFiltersToDto = (
  values: DeclarationFilters
): FiltresDeclarationDto => {
  const moreThanValidationPercent: boolean =
    values.moreOrLessThanValidationPercent === moreThanValidation;

  return {
    ...values,
    region: values.region?.codeRegion || null,
    departement: values.departement?.numero || null,
    declarationGlobalState: values.declarationGlobalState
      ? FiltresDeclarationDtoDeclarationGlobalStateEnum[
          values.declarationGlobalState.state
        ]
      : null,
    quotasEmissionsState: values.quotasEmissionsState
      ? FiltresDeclarationDtoQuotasEmissionsStateEnum[
          values.quotasEmissionsState.state
        ]
      : null,
    quotasAllocationsState: values.quotasAllocationsState
      ? FiltresDeclarationDtoQuotasAllocationsStateEnum[
          values.quotasAllocationsState.state
        ]
      : null,
    moreThanValidationPercent:
      values.declarationValidationPercent !== null
        ? moreThanValidationPercent
        : null, // filter not applied when declarationValidationPercent is not defined
    lastModificationTimeDeclaring:
      values.lastModificationTimeDeclaring &&
      values.lastModificationTimeDeclaring.toISOString().split(".")[0] + "Z",
    beforeLastModificationDeclaring:
      values.lastModificationTimeDeclaring !== null
        ? values.beforeLastModificationDeclaring
        : null, // filter not applied when lastModificationTime is not defined
    lastModificationTimeInspector:
      values.lastModificationTimeInspector &&
      values.lastModificationTimeInspector.toISOString().split(".")[0] + "Z",
    beforeLastModificationInspector:
      values.lastModificationTimeInspector !== null
        ? values.beforeLastModificationInspector
        : null, // filter not applied when lastModificationTime is not defined
    rienADeclarer: values.declarationGlobalState
      ? values.declarationGlobalState.rienADeclarer
      : null,
  };
};
