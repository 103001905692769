import React from "react";
import { useUserData } from "Authenticator/UserData";
import { DummyProfileCard } from "./DummyProfileCard";
import { AuthDroitDto, AuthDroitDtoAuthProfileEnum } from "api/gen/api";
import { ShouldNotHappen } from "../../../utils/ShouldNotHappen";
import { ReferentialFetcher } from "common/ReferentialFetcher";

interface CardProfileProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

const CardProfile = ({
  containerRef,
}: CardProfileProps): React.ReactElement => {
  const user = useUserData();

  const administratorRights: AuthDroitDto[] = [];
  const inspectorRights: AuthDroitDto[] = [];
  const declarantRights: AuthDroitDto[] = [];
  const verificatorRights: AuthDroitDto[] = [];
  const readerRights: AuthDroitDto[] = [];
  user.userRights.forEach(droit => {
    if (
      droit.authProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN ||
      droit.authProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
      droit.authProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA
    ) {
      //  administrateur
      administratorRights.push(droit);
    } else if (droit.authProfile === AuthDroitDtoAuthProfileEnum.EXPLOITANT) {
      //  exploitant
      declarantRights.push(droit);
    } else if (droit.authProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE) {
      inspectorRights.push(droit);
    } else if (droit.authProfile === AuthDroitDtoAuthProfileEnum.PRESTATAIRE) {
      verificatorRights.push(droit);
    } else if (droit.authProfile === AuthDroitDtoAuthProfileEnum.LECTEUR) {
      readerRights.push(droit);
    } else {
      throw new ShouldNotHappen(droit.authProfile);
    }
  });

  return (
    <ReferentialFetcher
      wantedKeys={["REFERENCE_REGIONS"]}
      render={referentials => {
        return (
          <DummyProfileCard
            containerRef={containerRef}
            firstName={user.userInfo.givenName}
            lastName={user.userInfo.familyName}
            administratorRights={administratorRights}
            inspectorRights={inspectorRights}
            declarantRights={declarantRights}
            verificatorRights={verificatorRights}
            readerRights={readerRights}
            referentialRegions={referentials.REFERENCE_REGIONS}
            activeRight={user.userInfo.droits[0]}
            onClickRight={user.setActiveRight}
          />
        );
      }}
    />
  );
};

export default CardProfile;
