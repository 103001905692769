import React from "react";
import Footer from "./common/footer";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faCommentAlt,
  faDownload,
  faEdit,
  faEnvelope,
  faExclamation,
  faExclamationTriangle,
  faLock,
  faPlus,
  faQuestionCircle,
  faReply,
  faSave,
  faSignOutAlt,
  faSpinner,
  faTimes,
  faTrashAlt,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import Authenticator from "Authenticator";
import AppPage from "pages/CommonSpace/AppPage";
import { GlobalModaleProvider } from "./common/modale/GlobalModaleProvider";
import { BrowserSupport } from "common/browserSupport";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UnauthenticatedAppPages from "pages/CommonSpace/UnauthenticatedAppPages";
import ErrorBoundary from "./pages/errors/ErrorBoundary";
import UnexpectedError from "./pages/errors/errorPages/UnexpectedError";
import ShouldOpenModaleBienvenue from "common/utils/ShouldOpenModaleBienvenue";

library.add(
  faExclamation,
  faExclamationTriangle,
  faEdit,
  faCommentAlt,
  faTimes,
  faLock,
  faSpinner,
  faBan,
  faSave,
  faBan,
  faReply,
  faQuestionCircle,
  faEnvelope,
  faDownload,
  faPlus,
  faUpload,
  faSignOutAlt,
  faTrashAlt
);

const App: React.FC = () => {
  return (
    <ErrorBoundary FallbackComponent={UnexpectedError}>
      <GlobalModaleProvider>
        <BrowserSupport>
          <Router>
            <Switch>
              <Route
                path="/unsubscribeEmail"
                component={UnauthenticatedAppPages}
              />
              <Route path="/">
                <Authenticator>
                  <AppPage />
                  <Footer />
                  <ShouldOpenModaleBienvenue />
                </Authenticator>
              </Route>
            </Switch>
          </Router>
        </BrowserSupport>
      </GlobalModaleProvider>
    </ErrorBoundary>
  );
};

export default App;
