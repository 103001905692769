import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocEauProps {
  disabled: boolean;
}

export const BlocEau = ({ disabled }: BlocEauProps) => {
  const { referenceEauController } = useAuthenticatedApi();
  const classes = useStyles();

  // Sandre
  const uploadSandreHandler = useCallback(
    (file: File) => {
      return referenceEauController.uploadSandreUsingPOST(file);
    },
    [referenceEauController]
  );

  const downloadSandreHandler = useCallback(() => {
    return referenceEauController.downloadSandreAsCsvUsingGET();
  }, [referenceEauController]);

  // Normes
  const uploadNormeEauHandler = useCallback(
    (file: File) => {
      return referenceEauController.uploadNormesEauUsingPOST(file);
    },
    [referenceEauController]
  );

  const downloadNormeEauHandler = useCallback(() => {
    return referenceEauController.downloadNormesEauAsCsvUsingGET();
  }, [referenceEauController]);

  // Stations d'épuration
  const uploadStationEpurationHandler = useCallback(
    (file: File) => {
      return referenceEauController.uploadStationEpurationUsingPOST(file);
    },
    [referenceEauController]
  );

  const downloadStationEpurationHandler = useCallback(() => {
    return referenceEauController.downloadStationEpurationAsCsvUsingGET();
  }, [referenceEauController]);

  return (
    <DummyUnActionedBloc
      title="Eau"
      renderContent={() => {
        return (
          <>
            {disabled && (
              <>
                <div className={classes.disabledText}>
                  Aucune campagne future à l'état prête n'a été trouvée. Vous ne
                  pouvez pas modifier les référentiels d'une campagne en cours.
                </div>
                <Row />
              </>
            )}
            <BlocReferentiel
              title="Sandre"
              downloadHandler={downloadSandreHandler}
              filename="sandre.csv"
              uploadHandler={uploadSandreHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Normes"
              downloadHandler={downloadNormeEauHandler}
              filename="normes_eau.csv"
              uploadHandler={uploadNormeEauHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Stations d'épuration"
              downloadHandler={downloadStationEpurationHandler}
              filename="stations_epurations.csv"
              uploadHandler={uploadStationEpurationHandler}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default BlocEau;
