import React from "react";
import {
  CampaignDtoStateAllocationsEnum,
  QuotasAllocationsEmailsVerificateursDto20Now,
} from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_QUOTAS_ALC_PRELIMINAIRE,
  PATH_QUOTAS_ALC_VERIFIE,
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_ALLOCATIONS_EMAILS,
  PATH_QUOTAS_ALLOCATIONS_PMS,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import QuotasDeclarationOfficeDownload from "../../../toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import BlocRappelTypeActivite from "../../../toNow/Quotas/common/BlocRappelTypeActivite";
import QuotasGlobalFormActions from "../../../toNow/Quotas/QuotasGlobalFormActions";
import { ReferentialActiviteQuotasProps } from "../../../toNow/Quotas/common/BlocRappelTypeActivite/types";
import {
  useBasicDeclarationHandlers21Now,
  useDeclaration21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../../toNow/versionedElements/declarationHooks21Now";
import BlocInspecteur from "../../../to23/Quotas/common/BlocInspecteur";
import BlocPlanMethodSurveillance from "./BlocPlanMethodSurveillance";
import BlocAlc from "./BlocAlc";
import BlocEmailsVerificateurs from "./BlocEmailsVerificateurs";
import Verification from "./Verification";
import { updateHandlerEmailsAddressAllocations21Now } from "./BlocEmailsVerificateurs/utils";

const NiveauxActiviteForm = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const declaration = useDeclaration21Now();
  const { yieldQuotaAllocationsVerif } = useDeclarationStateHandlers21Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const { update } = useBasicDeclarationHandlers21Now();
  const currentCampaign = useCurrentDeclarationCampaign();

  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasAllocationsEmailsVerificateursDto20Now
  >();

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
      />

      <Row />

      <BlocRappelTypeActivite referentialActivites={referentialActivites} />

      <Row height={"25px"} />

      <BlocPlanMethodSurveillance />

      <Row height={"25px"} />

      <BlocAlc />

      <Row height={"25px"} />

      <BlocEmailsVerificateurs
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        formikRef={blocEmailsVerificateursProps.formikRef}
        setHasChanges={blocEmailsVerificateursProps.setHasChanges}
      />
      <QuotasGlobalFormActions
        // TODO [GEREP-2565] use actions buttons instead
        pathToValidate={PATH_QUOTAS_ALLOCATIONS}
        target={WorkflowTarget.QUOTA_ALLOCATIONS}
        quotaState={declaration.body.workflowStatus.quotaAllocations.state}
        yieldQuotaVerif={yieldQuotaAllocationsVerif}
        isSimplifiedQuota={false}
        saveAction={() =>
          update(declaration => {
            declaration = updateHandlerEmailsAddressAllocations21Now(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
            return declaration;
          })
        }
        cancelAction={() => {
          blocEmailsVerificateursProps.resetForm();
        }}
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        declarantBlocsValidated={
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_EMAILS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_PMS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALC_PRELIMINAIRE) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALC_VERIFIE)
        }
      />
      <Row height={"25px"} />

      <Verification />

      <Row height={"25px"} />

      <BlocInspecteur
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        path={PATH_QUOTAS_ALLOCATIONS}
        currentState={declaration.body.workflowStatus.quotaAllocations.state}
        isCampaignEnded={
          currentCampaign.stateAllocations ===
          CampaignDtoStateAllocationsEnum.ENDED
        }
      />

      <Row height={"25px"} />
    </>
  );
};

export default NiveauxActiviteForm;
