import React from "react";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../CommonSpace/AppPage/PageHeaderContext";
import { GeneralInformationsController } from "./GeneralInformationsController";
import { ReferentialFetcher } from "common/ReferentialFetcher";

import idCardImg from "icons/pageLogos/id-card.svg";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

const GeneralInformations = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Informations générales",
      to: match.url,
    },
  ];
  const imagedata = {
    src: idCardImg,
    alt: "une carte d'identité simplifiée",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Informations générales",
    image: imagedata,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_ORGANISMES_INSPECTION_ALLOWED_AT_DECLARATION_CREATION",
        "REFERENCE_SERVICES_INSPECTION",
        "REFERENCE_EQUIPES_INSPECTION",
        "REFERENCE_CODE_APE",
        "REFERENCE_CODE_INSEE_WITH_LOCATION",
        "REFERENCE_PAYS",
        "REFERENCE_COORDONNEES_DEPARTEMENT",
        "REFERENCE_EPRTR",
        "REFERENCE_EPRTR_ELEVAGE",
      ]}
      render={referentials => {
        return (
          <GeneralInformationsController
            referentielGerepNatureServices={
              referentials.REFERENCE_ORGANISMES_INSPECTION_ALLOWED_AT_DECLARATION_CREATION
            }
            referentielServicesGun={referentials.REFERENCE_SERVICES_INSPECTION}
            referentielEquipesGun={referentials.REFERENCE_EQUIPES_INSPECTION}
            referentielAPE={referentials.REFERENCE_CODE_APE}
            referentielINSEE={referentials.REFERENCE_CODE_INSEE_WITH_LOCATION}
            referentielPays={referentials.REFERENCE_PAYS}
            referentielEPRTR={referentials.REFERENCE_EPRTR}
            eprtrElevageList={
              referentials.REFERENCE_EPRTR_ELEVAGE.referenceItemEprtrDtoList
            }
            coordonneesDepartementList={
              referentials.REFERENCE_COORDONNEES_DEPARTEMENT
                .referenceItemCoordonneesDepartementDtoList
            }
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(GeneralInformations, "GLOBAL");
