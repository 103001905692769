import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Nullable } from "common/utils/types";
import {
  organismeExterieurLabel,
  OutsidePreventionOrganismType,
  selectedOrganismOption,
  StructureFonctionnelleData,
  structureFonctionnelleLabel,
} from "./utils";
import {
  useBooleanCheckBoxGenerator,
  useChoiceRadioFieldGenerator,
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { convertDeclarationPreventionDtoToStructureFonctionnelleData } from "./converters";
import { validationSchema } from "./validation";
import { updateStructureFonctionnelle } from "../utils/updaters";
import {
  PATH_CARRIERE_SANTE_PREVENTION,
  PATH_CARRIERE_SANTE_PREVENTION_ACTIVITE_SAISONNIERE,
  PATH_CARRIERE_SANTE_PREVENTION_NB_VISITES,
  PATH_CARRIERE_SANTE_PREVENTION_ORGANISME_PREVENTION,
  PATH_CARRIERE_SANTE_PREVENTION_STRUCTURE_FONCTIONNELLE,
} from "common/path/path18Now";
import { ReferenceOrganismeExterieurPreventionDto } from "api/gen";
import { useDeclaration1919 } from "../../../versionedElements/declarationHooks1919";
import FormikBlocFullContext1919 from "../../../versionedElements/FormikBlocFullContext1919";

//to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface StructureFonctionnelleProps
  extends FormikBlocRefAndState<Nullable<StructureFonctionnelleData>> {
  externalPreventionOrganismList: OutsidePreventionOrganismType[];
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto;
}

const StructureFonctionnelle = ({
  formikRef,
  hasChanges,
  setHasChanges,
  externalPreventionOrganismList,
  referentialOrganismeExterieur,
}: StructureFonctionnelleProps): React.ReactElement => {
  const dataDeclaration = useDeclaration1919();
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "45%",
    formPrefix: "carriere-structure-fonctionnelle",
  };

  const CheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <>
      <FormikBlocFullContext1919
        formikRef={formikRef}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        initialValues={convertDeclarationPreventionDtoToStructureFonctionnelleData(
          dataDeclaration.body.sections.carriere.sante.prevention,
          externalPreventionOrganismList,
          referentialOrganismeExterieur
        )}
        validationSchema={validationSchema}
        title={"Organisation en matière de SST (tss1)"}
        pathToValidate={PATH_CARRIERE_SANTE_PREVENTION}
        updateHandler={(declaration, values) =>
          updateStructureFonctionnelle(
            declaration,
            values,
            referentialOrganismeExterieur
          )
        }
        enableReinitialize
        renderContent={(props, shouldDisabledFields) => {
          return (
            <>
              <CheckboxField
                label={"Activité saisonnière (si inferieure à 4 mois)"}
                name={"isSeasonalActivity"}
                disabled={shouldDisabledFields}
                commentPath={
                  PATH_CARRIERE_SANTE_PREVENTION_ACTIVITE_SAISONNIERE
                }
              />
              <ChoiceRadioField
                name="outsideOrganismOrFunctionalStructure"
                label="Organisme extérieur ou structure fonctionnelle"
                disabled={shouldDisabledFields}
                choices={[structureFonctionnelleLabel, organismeExterieurLabel]}
                commentPath={
                  PATH_CARRIERE_SANTE_PREVENTION_STRUCTURE_FONCTIONNELLE
                }
              />
              {selectedOrganismOption(
                props.values.outsideOrganismOrFunctionalStructure
              ) && (
                <>
                  <ChoiceSelectField
                    label={"Organisme extérieur de prévention *"}
                    name={"outsidePreventionOrganism"}
                    isMulti={false}
                    options={externalPreventionOrganismList}
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_SANTE_PREVENTION_ORGANISME_PREVENTION
                    }
                  />
                  <NumberField
                    label={"Nombre de visites effectuées dans l'année *"}
                    name={"numberVisitsMadeInYear"}
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={PATH_CARRIERE_SANTE_PREVENTION_NB_VISITES}
                  />
                </>
              )}
            </>
          );
        }}
      />
    </>
  );
};
export default StructureFonctionnelle;
