import React from "react";
import logo from "icons/pageLogos/delivery-truck.svg";
import { RouteIdProps } from "common/declarant/type";
import {
  usePathToDeclarationDashboard,
  usePageHeaderSetterOnce,
  PageHeaderProps,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import MainProductionForm from "./MainProductionForm";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const Production = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      declarationEtablissement
    ),
    {
      label: "Production",
      to: match.url,
    },
  ];
  const imagedata = {
    src: logo,
    alt: "un camion",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Production",
    image: imagedata,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_SUBSTANCE_CARRIERE",
        "REFERENCE_PAYS",
        "REFERENCE_DEPARTEMENTS",
        "REFERENCE_CODE_INSEE_WITH_LOCATION",
        "REFERENCE_FAMILLE_PRODUCTION",
        "REFERENCE_FAMILLE_RECYCLAGE",
        "REFERENCE_FAMILLE_PRODUCTION_CODE_PRODUIT",
      ]}
      render={referentials => {
        return (
          <MainProductionForm
            referentialSubstances={referentials.REFERENCE_SUBSTANCE_CARRIERE}
            referentialCountries={referentials.REFERENCE_PAYS}
            referentialDepartments={referentials.REFERENCE_DEPARTEMENTS}
            referentialInsee={referentials.REFERENCE_CODE_INSEE_WITH_LOCATION}
            referentialFamiliesProduction={
              referentials.REFERENCE_FAMILLE_PRODUCTION
            }
            referentialFamiliesCodeProduction={
              referentials.REFERENCE_FAMILLE_PRODUCTION_CODE_PRODUIT
            }
            referentialFamiliesRecycling={
              referentials.REFERENCE_FAMILLE_RECYCLAGE
            }
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Production, "GLOBAL");
