import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";

export const singleCovValidationSchema = Yup.object().shape({
  substance: commonObjectFields,
  procede: commonArrayOfObjectFields,
  flux: commonPositiveNumberFields,
});
