import React, { ReactElement } from "react";
import { Omit } from "react-router";
import FieldWrapper, { FieldWrapperProps } from "../wrappers/FieldWrapper";
import NumberInput from "../connectedInput/NumberInput";
import { UnwrappedNumberInputProps } from "../types/connectedTypes";

type WrappedNumberInputWithoutLabelProps = Omit<
  FieldWrapperProps,
  "renderField"
> &
  Omit<UnwrappedNumberInputProps, "field" | "form" | "id">;

export const WrappedNumberInputWithoutLabel = (
  props: WrappedNumberInputWithoutLabelProps
): ReactElement => {
  const isDisabled =
    props.externalValue !== undefined ||
    (props.disabled ?? props.commonProps.disabled);
  return (
    <FieldWrapper
      name={props.name}
      commonProps={props.commonProps}
      renderField={({ field, form }, id) => (
        <NumberInput
          id={id}
          unit={props.unit}
          field={field}
          form={form}
          additionalClassName={props.additionalClassNameInput}
          additionalOnChange={props.additionalOnChange}
          additionalClassNameInput={props.additionalClassNameInput}
          style={props.style}
          disabled={isDisabled}
          hideValue={props.hideValue}
          formatNumber={props.formatNumber}
          placeholder={props.placeholder}
          externalValue={props.externalValue}
        />
      )}
    />
  );
};
