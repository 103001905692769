import { findElementMatchingTemplate } from "common/utils/methods";
import { InspectionServiceDto } from "../utils/types";

export const computeInspectionServiceLabel = (
  serviceId: string,
  inspectionServiceList: InspectionServiceDto[]
): string => {
  const inspectionService = findElementMatchingTemplate(
    { id: serviceId },
    inspectionServiceList
  );

  return inspectionService?.libelle || "";
};
