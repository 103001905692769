import React, { Dispatch, SetStateAction } from "react";
import { EmissionsInArray, MatiereEmissionInArray } from "./utils/types";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import {
  AirFugitivesEmissionBilanIntrantsDto20Now,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparatorAndVariableDecimal } from "common/utils/numberUtils";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import { computeEmissionAnnuelleForIntrantSortant } from "../../combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import { shouldPartBeFilled } from "../../combustionProcedeUtils/bilanMatiere/elementsHelpers";

const NUMBER_DECIMAL_INF_ONE = 12;
const NUMBER_DECIMAL_SUP_ONE = 4;

interface SyntheseIntrantsArrayProps {
  matiereEmissionsInPage: MatiereEmissionInArray[];
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >;
  autreElementUuid: string;
}

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const SyntheseIntrantsArray = ({
  matiereEmissionsInPage,
  setEmissionModaleOpen,
  isValidated,
  setEmissionInModale,
  polluantElementMap,
  autreElementUuid,
}: SyntheseIntrantsArrayProps): React.ReactElement => {
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const classes = useStyles();

  const computeSingleIntrantLine = (
    intrant: AirFugitivesEmissionBilanIntrantsDto20Now,
    emission: MatiereEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const emissionTitleString =
      emission.data.substance !== null ? emission.data.substance.nom : "";

    const substanceName = computeFirstLineContentCell(
      editAction,
      emissionTitleString,
      isValidated,
      !_.isEmpty(emission.errors)
    );

    const substanceRestorationCode =
      emission.data.substance && emission.data.substance.restorationCode;

    const elementUuid =
      emission.data.elementProps &&
      emission.data.elementProps.referenceItemElementIndexDto.uuid;

    const polluantElementsInReferential: Map<
      string | null,
      ReferenceItemPolluantElementDto
    > | null = polluantElementMap.get(substanceRestorationCode) || null;

    const polluantElement: ReferenceItemPolluantElementDto | null =
      (polluantElementsInReferential &&
        polluantElementsInReferential.get(elementUuid)) ||
      null;

    const element =
      shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
      polluantElement === null
        ? emission.data.elementName
        : polluantElement.referenceItemElementIndexDto.nom;

    const part =
      shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
      polluantElement === null
        ? emission.data.part
        : 100 * polluantElement.partElement;

    const quantiteElementDansIntrant = computeEmissionAnnuelleForIntrantSortant(
      intrant
    );

    return [
      computeWrappedCellWithError(substanceName, classes),
      computeWrappedCellWithError(
        <p title={element || undefined}>{element}</p>,
        classes
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparatorAndVariableDecimal(
          part,
          NUMBER_DECIMAL_INF_ONE,
          NUMBER_DECIMAL_SUP_ONE
        ),
        classes
      ),
      computeWrappedCellWithError(
        intrant.descriptif ? intrant.descriptif : "",
        classes
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparatorAndVariableDecimal(
          intrant.quantite,
          NUMBER_DECIMAL_INF_ONE,
          NUMBER_DECIMAL_SUP_ONE
        ),
        classes
      ),

      computeWrappedCellWithError(
        computeNumberWithSeparatorAndVariableDecimal(
          intrant.teneurMoyenne,
          NUMBER_DECIMAL_INF_ONE,
          NUMBER_DECIMAL_SUP_ONE
        ),
        classes
      ),
      computeWrappedCellWithError(
        computeNumberWithSeparatorAndVariableDecimal(
          quantiteElementDansIntrant,
          NUMBER_DECIMAL_INF_ONE,
          NUMBER_DECIMAL_SUP_ONE
        ),
        classes
      ),
      <CommonEntityButton handlerEdit={editAction} isValidated={isValidated} />,
    ];
  };

  const computeIntrantArray = (
    mesureEmission: MatiereEmissionInArray,
    intrants: Array<AirFugitivesEmissionBilanIntrantsDto20Now> | null
  ) =>
    intrants
      ? intrants.map(intrant =>
          computeSingleIntrantLine(intrant, mesureEmission, classes)
        )
      : [];

  const arrayIntrantsLines = matiereEmissionsInPage
    .map(mesureEmission =>
      computeIntrantArray(mesureEmission, mesureEmission.data.intrants)
    )
    .reduce(
      (allIntrants, intrantArray) => allIntrants.concat(intrantArray),
      []
    );

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Élément sur lequel est indexé le bilan matière</p>,
          <p>Part de l'élément dans la substance émise (%)</p>,
          <p>Description de l'intrant</p>,
          <p>Quantité entrante (kg)</p>,
          <p>Teneur moyenne de l’élément dans l'intrant (%)</p>,
          <p>Quantité de l’élément (kg)</p>,
          <p>Actions</p>,
        ]}
        lines={arrayIntrantsLines}
        preferredColWidths={[
          300,
          130,
          130,
          130,
          130,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default SyntheseIntrantsArray;
