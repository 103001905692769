import { makeStyles } from "@material-ui/styles";
import React from "react";
import DeclarationSummary from "../DeclarationSummary";
import Button from "common/button";
import Row from "common/presentational/Row";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../../CommonSpace/AppPage/PageHeaderContext";
import { PINK } from "theme";
import {
  FORCED,
  generateTransitionWarningMessage,
  IN_REVIEW,
  RECALLED,
  SUBMITTED,
  VALIDATED,
} from "common/messages/dashboardMessage";
import { isEveryRequiredSectionValidated } from "pages/CompanySpace/from19/to19/Dashboard/DeclarationSummary/utils";
import { useConfirmationModale } from "common/modale/hooks";
import { useCurrentDeclarationCampaign } from "../../../../../CommonSpace/CampaignContext";
import {
  useDeclaration1919,
  useDeclarationHelpers1919,
  useDeclarationState1919,
  useDeclarationStateHandlers1919,
} from "../../versionedElements/declarationHooks1919";
import { WorkflowTarget } from "common/utils/types";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";

const useStyles = makeStyles({
  button: {
    margin: "0 22px 0 0",
  },
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
});

const DashboardLecteur = ({ match }: RouteIdProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration1919();
  const declarationApi = useDeclarationHelpers1919();
  const { cancelSubmit, submit } = useDeclarationStateHandlers1919();
  const openConfirmationModale = useConfirmationModale();

  const declarationAnnee = match.params.annee;
  const currentCampaign = useCurrentDeclarationCampaign();

  const declarationEtablissement = match.params.etablissement;
  const declarationStateComment =
    declaration.body.workflowStatus.general.message;
  const declarationState = useDeclarationState1919();

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };

  usePageHeaderSetterOnce(headerData);

  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateGlobal
  );

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />
      <span className={classes.color}>Statut de la déclaration : </span>
      <p className={classes.informationDeclaration}>
        {declarationState.stateMessage}
      </p>
      <Row height={"20px"} />
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.GLOBAL}
        isInReviewState={declarationState.isDeclarationStateContaining([
          "IN_REVIEW",
        ])}
        comment={declarationStateComment}
      />
      <Row height={"20px"} />
      <DeclarationSummary
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
      <Row height={"20px"} />

      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        {(declarationState.isDeclarationStateContaining([SUBMITTED]) ||
          declarationState.isDeclarationStateContaining([VALIDATED])) && (
          <Button
            text={"Demander modification"}
            isReversed
            additionalClassname={classes.button}
            additionalStyle={{ fontSize: "18px" }}
            onClick={() => {
              openConfirmationModale(
                generateTransitionWarningMessage(IN_REVIEW),
                () => {
                  cancelSubmit([WorkflowTarget.GLOBAL]);
                }
              );
            }}
          />
        )}
        {declarationState.isDeclarationStateContaining([RECALLED]) && (
          <p className={classes.color}>
            En attente de l'acceptation de modification par l'inspecteur
          </p>
        )}
        {declarationState.isDeclarationStateContaining([FORCED]) && (
          <p className={classes.color}>
            Un inspecteur a pris la main sur votre déclaration
          </p>
        )}
        {!declarationState.isDeclarationStateContaining([
          SUBMITTED,
          RECALLED,
          VALIDATED,
          FORCED,
        ]) && (
          <>
            {declaration.body.typeActivite.estSousQuotaCo2 && (
              <p>
                Attention, ce bouton ne permet pas de transmettre la déclaration
                quotas qui doit être transmise par l'intermédiaire du bouton
                "Transmettre à l'inspection" disponible dans le pavé Quotas.
              </p>
            )}
            <Button
              text={"TRANSMETTRE"}
              additionalClassname={classes.button}
              onClick={() => {
                openConfirmationModale(
                  generateTransitionWarningMessage(SUBMITTED),
                  () => {
                    submit([WorkflowTarget.GLOBAL]);
                  }
                );
              }}
              isDisabled={!isEveryRequiredSectionValidated(declarationApi)}
              // TODO, Get error from back and display it.
            />
          </>
        )}
      </Row>
      <Row height={"20px"} />
    </>
  );
};

export default DashboardLecteur;
