import { fieldMatcherBuilder } from "common/declarant/formik/utils";

export interface AggregationFilesModaleValues {
  // 18Now
  solvantPlanGestion: boolean;
  elevageFeuilleCalcul: boolean;
  quotasPlanSurveillance: boolean;
  quotasRapportEmissions: boolean;
  // 20Now
  quotasRapportVerificationEmissions: boolean;
  quotasRapportDeclarationOfficeEmissions: boolean;
  quotasRapportAlcPreliminaire: boolean;
  quotasJustificatifsAlcPreliminaire: boolean;
  quotasRapportAlcVerifie: boolean;
  quotasJustificatifsAlcVerifie: boolean;
  quotasAllocationPlanSurveillance: boolean;
  quotasRapportVerificationAllocation: boolean;
  quotasRapportDeclarationOfficeAllocation: boolean;
  // 21Now
  documentsDivers: boolean;
  quotasJustificatifsPds: boolean;
  quotasJustificatifsRapportEmissions: boolean;
  quotasJustificatifsPms: boolean;
}

export const fileSectionFieldMatcher = fieldMatcherBuilder<
  AggregationFilesModaleValues
>().build({
  solvantPlanGestion: "solvantPlanGestion",
  elevageFeuilleCalcul: "elevageFeuilleCalcul",
  quotasPlanSurveillance: "quotasPlanSurveillance",
  quotasRapportEmissions: "quotasRapportEmissions",
  quotasRapportVerificationEmissions: "quotasRapportVerificationEmissions",
  quotasRapportDeclarationOfficeEmissions:
    "quotasRapportDeclarationOfficeEmissions",
  quotasRapportAlcPreliminaire: "quotasRapportAlcPreliminaire",
  quotasJustificatifsAlcPreliminaire: "quotasJustificatifsAlcPreliminaire",
  quotasRapportAlcVerifie: "quotasRapportAlcVerifie",
  quotasJustificatifsAlcVerifie: "quotasJustificatifsAlcVerifie",
  quotasAllocationPlanSurveillance: "quotasAllocationPlanSurveillance",
  quotasRapportVerificationAllocation: "quotasRapportVerificationAllocation",
  quotasRapportDeclarationOfficeAllocation:
    "quotasRapportDeclarationOfficeAllocation",
  documentsDivers: "documentsDivers",
  quotasJustificatifsPds: "quotasJustificatifsPds",
  quotasJustificatifsRapportEmissions: "quotasJustificatifsRapportEmissions",
  quotasJustificatifsPms: "quotasJustificatifsPms",
});
