import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  LINK_STYLE,
  PINK,
  SECTION_TITLE_GREY,
} from "theme";
import Button from "common/button";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    color: PINK,
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
  bold: {
    fontWeight: "bold",
  },
  ul: {
    "& li": {
      marginLeft: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  link: LINK_STYLE,
});

interface ModalTextesProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalTextes = ({
  isOpen,
  onRequestClose,
}: ModalTextesProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={classes.title}>
        <h1>Textes de référence</h1>
      </div>

      <ul className={classes.ul}>
        <li>
          <span className={classes.bold}>Textes internationaux</span>
          <ul className={classes.ul}>
            <li>
              Déclaration annuelle
              <ul className={classes.ul}>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/PRTR_Protocole.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Protocole sur les registres des rejets et transferts de
                    polluants (Protocole PRTR)
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <span className={classes.bold}>Textes européens</span>
          <ul className={classes.ul}>
            <li>
              Déclaration annuelle
              <ul className={classes.ul}>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/EPRTR_Reglement.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Règlement n°166/2006 du 18 janvier 2006 concernant la
                    création d’un registre européen des rejets et des transferts
                    de polluants (Règlement E-PRTR)
                  </a>
                </li>
              </ul>
            </li>

            <li>
              Système d'Echange de Quotas d'Emissions de gaz à effet de serre de
              l'Union Européenne (SEQE)
              <ul className={classes.ul}>
                <li>
                  <a
                    href={
                      "https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32003L0087"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Directive 2003/87/CE modifiée établissant un système
                    d'échange de quotas d'émission de gaz à effet de serre
                    (Directive Quotas)
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32018R2066"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Règlement n°2018/2066 modifié relatif à la surveillance et à
                    la déclaration des émissions de gaz à effet de serre («
                    Règlement MRR »)
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32019R0331"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Règlement 2019/331 relatif à la surveillance et à la
                    déclaration des niveaux d’activité (« Règlement FAR »)
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32019R1842"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Règlement 2019/1842 relatif à l’allocation dynamique pour
                    les niveaux d’activité (« Règlement ALC »)
                  </a>
                </li>
                <li>
                  <a
                    href={
                      "https://eur-lex.europa.eu/legal-content/FR/TXT/?uri=CELEX:32018R2067"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Règlement 2018/2067 modifié relatif à la vérification et à
                    l’accréditation (« Règlement AVR »)
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li>
          <span className={classes.bold}>Textes français</span>
          <ul className={classes.ul}>
            <li>
              Déclaration annuelle
              <ul className={classes.ul}>
                <li>
                  <a
                    href="https://aida.ineris.fr/consultation_document/4577"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Arrêté du 31 janvier 2008 modifié relatif au registre et à
                    la déclaration annuelle des émissions et des transferts de
                    polluants et des déchets
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Système d'Echange de Quotas d'Emissions de gaz à effet de serre de
              l'Union Européenne (SEQE)
              <ul className={classes.ul}>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006074220/LEGISCTA000006176492/#LEGISCTA000006176492"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Code de l’environnement – Partie législative (Articles
                    L229-5 à L229-19)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/codes/section_lc/LEGITEXT000006074220/LEGISCTA000006176843/#LEGISCTA000006176843"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Code de l’environnement – Partie réglementaire (Articles
                    R229-5 à R229-37-11)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000042754400"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Arrêté du 21 décembre 2020 sur les modalités de mise en
                    œuvre des obligations particulières de surveillance, de
                    déclaration et de contrôle des émissions et des niveaux
                    d'activité (« Arrêté modalités phase 4 »)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/jorf/id/JORFTEXT000044537559"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Arrêté du 10 décembre 2021 fixant la liste des exploitants
                    d'installations soumises à autorisation pour les émissions
                    de gaz à effet de serre ainsi que le montant des quotas
                    d'émission affectés à titre gratuit pour la période
                    2021-2025 (« Arrêté phase 4 »)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/loda/id/JORFTEXT000044505632"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Arrêté du 10 décembre 2021 fixant la liste des
                    établissements hospitaliers exclus du système d'échange de
                    quotas d'émission de gaz à effet de serre et les plafonds
                    d'émission annuels qui leur sont applicables, et la liste
                    des établissements émettant moins de 2 500 tonnes
                    d'équivalent dioxyde de carbone exclus du système d'échange
                    de quotas d'émission de gaz à effet de serre, ainsi que les
                    mesures de surveillance et de déclaration qui leur sont
                    applicables, pour la période 2021-2025 (« Arrêté exclusions
                    phase 4 »)
                  </a>
                </li>
              </ul>
            </li>
            <li>
              SPFO
              <ul className={classes.ul}>
                <li>
                  <a
                    href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000019872071&dateTexte=20190123"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Arrêté du 26 novembre 2008 relatif à la déclaration annuelle
                    à l'administration des fabricants, importateurs ou
                    utilisateurs de sulfonates de perfluorooctane SPFO
                    mentionnée à l'article R. 521-42-6 du code de
                    l'environnement
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>

      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
        isReversed
      />
    </CustomModal>
  );
};

export default ModalTextes;
