import React from "react";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";
import mtesImg from "../icons/mte.png";
import bandeauImg from "../icons/bandeau.png";

const useStyles = makeStyles({
  logos: {
    color: TEXT_LIGHT_GREY,
    display: "flex",
    justifyContent: "space-between",
    height: "78px",
    alignItems: "stretch",
    "&>img": {
      height: "100%", // mandatory for a proper chrome display
    },
  },
});

const HeaderBandeau = (): React.ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.logos}>
      <img
        src={mtesImg}
        alt="MTE logo with text : déclaration annuelle des émissions et des transferts de polluants et des déchets"
      />
      <img src={bandeauImg} alt="Bandeau illustratif" />
    </div>
  );
};

export default HeaderBandeau;
