import {
  FileDto,
  QuotasBlocEmissionsDto1819,
  QuotasEmissionFluxDto1819,
  QuotasEmissionPointMesureDto1819,
  QuotasEmissionSimpleCalculeDto1819,
  QuotasEmissionSimpleCalculeDto1819GazEnum,
  QuotasEmissionSimpleCalculeDto1819MethodeEnum,
  QuotasEmissionSimpleMesureDto1819,
  QuotasEmissionSimpleMesureDto1819GazEnum,
  QuotasEmissionSimpleMesureDto1819MethodeEnum,
  QuotasEmissionSimpleMethodeAlternativeDto1819,
  QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum,
  QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum,
} from "api/gen/api";
import {
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  FlowDeclarationProps,
  GasOptions,
  MeasureDeclarationProps,
  MeasureEmissionInArray,
} from "./types";
import { selectPossibleValues } from "./selectPossibleValues";
import { findFlowDeclaration, findMeasureDeclaration } from "./utils/utils";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { InstallationInArray } from "../blocInstallations/types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

export const flowDeclarationToOptionProps = (
  flow: FlowDeclarationProps
): OptionProps => ({
  value: flow.id,
  label: flow.name || "",
});

export const flowIdToFlowOptionProps = (
  flowId: string,
  flows: FlowDeclarationProps[]
): OptionProps => {
  const flow = flows.find(f => f.id === flowId);
  if (flow) {
    return {
      value: flowId,
      label: flow.name || "",
    };
  }
  throw Error(`Flow with id ${flowId} not found`);
};

export const flowLabelToId = (
  flowLabel: string,
  flows: FlowDeclarationProps[]
): string => {
  const flow = flows.find(f => f.name === flowLabel);
  if (flow) {
    return flow.id;
  }
  throw Error(`Flow with label ${flowLabel} not found`);
};

export const installationsInArrayToNimOptionProps = (
  installationsInArray: InstallationInArray[]
): OptionProps[] => {
  return installationsInArray
    .filter(installation => installation.data.nim)
    .map(installation => {
      if (installation.data.nim === null) {
        throw Error(
          "installation.data.nim should have NO possibility of being null at this place"
        );
      }

      return {
        value: installation.data.nim,
        label: installation.data.nim,
      };
    });
};

export const fluxDtoToFlowDeclaration = (
  flux: QuotasEmissionFluxDto1819,
  installationsInArray: InstallationInArray[]
): FlowDeclarationProps => {
  return {
    id: flux.id,
    nim:
      installationsInArrayToNimOptionProps(installationsInArray).find(
        option => flux.nim === option.label
      ) || null,
    name: flux.nom,
  };
};

export const pointMesureDtoToMeasureDeclaration = (
  point: QuotasEmissionPointMesureDto1819,
  installationsInArray: InstallationInArray[]
): MeasureDeclarationProps => {
  return {
    id: point.id,
    nim:
      installationsInArrayToNimOptionProps(installationsInArray).find(
        option => point.nim === option.label
      ) || null,
    name: point.intitule,
  };
};

export const flowDeclarationToFluxDto = (
  flowDeclaration: FlowDeclarationProps
): QuotasEmissionFluxDto1819 => {
  return {
    id: flowDeclaration.id,
    nim: flowDeclaration.nim && flowDeclaration.nim.label,
    nom: flowDeclaration.name,
  };
};

export const measureDeclarationToPointMesureDto = (
  mesureDeclaration: MeasureDeclarationProps
): QuotasEmissionPointMesureDto1819 => {
  return {
    id: mesureDeclaration.id,
    nim: mesureDeclaration.nim && mesureDeclaration.nim.label,
    intitule: mesureDeclaration.name,
  };
};

const gasEnumToOptionProps = (
  gasEnum:
    | QuotasEmissionSimpleCalculeDto1819GazEnum
    | QuotasEmissionSimpleMesureDto1819GazEnum
    | QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum
    | null
): OptionPropsWithObject<GasOptions> | null => {
  if (!gasEnum) {
    return null;
  }
  switch (gasEnum) {
    case QuotasEmissionSimpleCalculeDto1819GazEnum.CO2:
    case QuotasEmissionSimpleMesureDto1819GazEnum.CO2:
    case QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.CO2:
      return selectPossibleValues.gas[0];

    case QuotasEmissionSimpleCalculeDto1819GazEnum.PFC:
    case QuotasEmissionSimpleMesureDto1819GazEnum.PFC:
    case QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.PFC:
      return selectPossibleValues.gas[1];

    case QuotasEmissionSimpleCalculeDto1819GazEnum.N2O:
    case QuotasEmissionSimpleMesureDto1819GazEnum.N2O:
    case QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.N2O:
      return selectPossibleValues.gas[2];

    default:
      throw new ShouldNotHappen(gasEnum);
  }
};

const gasOptionPropsToEnum = (
  gasOptionProps: OptionProps
): {
  calcule: QuotasEmissionSimpleCalculeDto1819GazEnum;
  mesure: QuotasEmissionSimpleMesureDto1819GazEnum;
  alternative: QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum;
} => {
  switch (gasOptionProps.label) {
    case selectPossibleValues.gas[0].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819GazEnum.CO2,
        mesure: QuotasEmissionSimpleMesureDto1819GazEnum.CO2,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.CO2,
      };

    case selectPossibleValues.gas[1].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819GazEnum.PFC,
        mesure: QuotasEmissionSimpleMesureDto1819GazEnum.PFC,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.PFC,
      };

    case selectPossibleValues.gas[2].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819GazEnum.N2O,
        mesure: QuotasEmissionSimpleMesureDto1819GazEnum.N2O,
        alternative: QuotasEmissionSimpleMethodeAlternativeDto1819GazEnum.N2O,
      };

    default:
      throw Error(`Error converting ${gasOptionProps} to Enum`);
  }
};

const methodEnumToOptionProps = (
  methodEnum:
    | QuotasEmissionSimpleCalculeDto1819MethodeEnum
    | QuotasEmissionSimpleMesureDto1819MethodeEnum
    | QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum
    | null
): OptionProps | null => {
  if (!methodEnum) {
    return null;
  }
  switch (methodEnum) {
    case QuotasEmissionSimpleCalculeDto1819MethodeEnum.ALTERNATIVE:
    case QuotasEmissionSimpleMesureDto1819MethodeEnum.ALTERNATIVE:
    case QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.ALTERNATIVE:
      return selectPossibleValues.methods[0];

    case QuotasEmissionSimpleCalculeDto1819MethodeEnum.MESURE:
    case QuotasEmissionSimpleMesureDto1819MethodeEnum.MESURE:
    case QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.MESURE:
      return selectPossibleValues.methods[1];

    case QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_OXYDATION:
    case QuotasEmissionSimpleMesureDto1819MethodeEnum.FACTEUR_OXYDATION:
    case QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.FACTEUR_OXYDATION:
      return selectPossibleValues.computedMethods[0];

    case QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_CONVERSION:
    case QuotasEmissionSimpleMesureDto1819MethodeEnum.FACTEUR_CONVERSION:
    case QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.FACTEUR_CONVERSION:
      return selectPossibleValues.computedMethods[1];

    case QuotasEmissionSimpleCalculeDto1819MethodeEnum.BILAN_MASSIQUE:
    case QuotasEmissionSimpleMesureDto1819MethodeEnum.BILAN_MASSIQUE:
    case QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.BILAN_MASSIQUE:
      return selectPossibleValues.computedMethods[2];

    default:
      throw new ShouldNotHappen(methodEnum);
  }
};

const methodOptionPropsToEnum = (
  methodOptionProps: OptionProps
): {
  calcule: QuotasEmissionSimpleCalculeDto1819MethodeEnum;
  mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum;
  alternative: QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum;
} => {
  switch (methodOptionProps.label) {
    case selectPossibleValues.methods[0].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819MethodeEnum.ALTERNATIVE,
        mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum.ALTERNATIVE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.ALTERNATIVE,
      };

    case selectPossibleValues.methods[1].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819MethodeEnum.MESURE,
        mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum.MESURE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.MESURE,
      };

    case selectPossibleValues.computedMethods[0].label:
      return {
        calcule:
          QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_OXYDATION,
        mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum.FACTEUR_OXYDATION,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.FACTEUR_OXYDATION,
      };

    case selectPossibleValues.computedMethods[1].label:
      return {
        calcule:
          QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_CONVERSION,
        mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum.FACTEUR_CONVERSION,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.FACTEUR_CONVERSION,
      };

    case selectPossibleValues.computedMethods[2].label:
      return {
        calcule: QuotasEmissionSimpleCalculeDto1819MethodeEnum.BILAN_MASSIQUE,
        mesure: QuotasEmissionSimpleMesureDto1819MethodeEnum.BILAN_MASSIQUE,
        alternative:
          QuotasEmissionSimpleMethodeAlternativeDto1819MethodeEnum.BILAN_MASSIQUE,
      };

    default:
      throw Error(`Error converting ${methodOptionProps} to Enum`);
  }
};

export const flowOrMeasureStringToOptionProps = (
  label: string | null,
  values: FlowDeclarationProps[] | MeasureDeclarationProps[]
): OptionProps => {
  const match = values.find(obj => obj.name === label);
  return {
    value: (match && match.nim && match.nim.label) || "",
    label: label || "",
    object: match && match.id,
  };
};

export const emissionSimpleDtoToAlternativeEmission = (
  emissionDto: QuotasEmissionSimpleMethodeAlternativeDto1819
): AlternativeEmissionInArray => {
  return {
    //TODO : calculate the errors
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      nimOptionProps:
        emissionDto.nim !== null
          ? {
              value: emissionDto.nim,
              label: emissionDto.nim,
            }
          : null,
      gas: gasEnumToOptionProps(emissionDto.gaz),
      method: methodEnumToOptionProps(emissionDto.methode),
      methodDescription: emissionDto.descriptionMethode,
      otherEmission: emissionDto.emission,
    },
    errors: {},
  };
};

export const alternativeEmissionToEmissionSimpleDto = (
  alternativeEmission: AlternativeEmissionInArray
): QuotasEmissionSimpleMethodeAlternativeDto1819 => {
  if (
    alternativeEmission.data.gas &&
    (alternativeEmission.data.gas.object === GasOptions.N2O ||
      alternativeEmission.data.gas.object === GasOptions.PFC)
  ) {
    alternativeEmission.data.biomassEmission = null;
    alternativeEmission.data.fossilEmission = null;
  } else {
    alternativeEmission.data.otherEmission = null;
  }
  return {
    id: alternativeEmission.data.id,
    emissionOrigineBiomasse: alternativeEmission.data.biomassEmission,
    emissionOrigineFossile: alternativeEmission.data.fossilEmission,
    nim:
      alternativeEmission.data.nimOptionProps &&
      alternativeEmission.data.nimOptionProps.label,
    gaz:
      alternativeEmission.data.gas &&
      gasOptionPropsToEnum(alternativeEmission.data.gas).alternative,
    methode:
      alternativeEmission.data.method &&
      methodOptionPropsToEnum(alternativeEmission.data.method).alternative,
    descriptionMethode: alternativeEmission.data.methodDescription,
    pointMesure: null,
    flux: null,
    emission: alternativeEmission.data.otherEmission,
  };
};

export const emissionSimpleDtoToMeasuredEmission = (
  emissionDto: QuotasEmissionSimpleMesureDto1819,
  measureDeclarations: MeasureDeclarationProps[]
): MeasureEmissionInArray => {
  return {
    //TODO : compute errors
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      measure: flowOrMeasureStringToOptionProps(
        emissionDto.pointMesure,
        measureDeclarations
      ),
      gas: gasEnumToOptionProps(emissionDto.gaz),
      method: methodEnumToOptionProps(emissionDto.methode),
      otherEmission: emissionDto.emission,
    },
    errors: {},
  };
};

export const measuredEmissionToEmissionSimpleDto = (
  measuredEmission: MeasureEmissionInArray,
  measureDeclarations: MeasureDeclarationProps[]
): QuotasEmissionSimpleMesureDto1819 => {
  const measurePoint = findMeasureDeclaration(
    measuredEmission.data.measure && measuredEmission.data.measure.object,
    measureDeclarations
  );
  if (
    measuredEmission.data.gas &&
    (measuredEmission.data.gas.object === GasOptions.N2O ||
      measuredEmission.data.gas.object === GasOptions.PFC)
  ) {
    measuredEmission.data.biomassEmission = null;
    measuredEmission.data.fossilEmission = null;
  } else {
    measuredEmission.data.otherEmission = null;
  }
  return {
    id: measuredEmission.data.id,
    emissionOrigineBiomasse: measuredEmission.data.biomassEmission,
    emissionOrigineFossile: measuredEmission.data.fossilEmission,
    nim: measurePoint && measurePoint.nim && measurePoint.nim.label,
    pointMesure: measurePoint && measurePoint.name,
    gaz:
      measuredEmission.data.gas &&
      gasOptionPropsToEnum(measuredEmission.data.gas).mesure,
    methode:
      measuredEmission.data.method &&
      methodOptionPropsToEnum(measuredEmission.data.method).mesure,
    descriptionMethode: null,
    flux: null,
    emission: measuredEmission.data.otherEmission,
  };
};

export const emissionSimpleDtoToComputedEmission = (
  emissionDto: QuotasEmissionSimpleCalculeDto1819,
  flowDeclarations: FlowDeclarationProps[]
): ComputedEmissionInArray => {
  return {
    data: {
      id: emissionDto.id,
      biomassEmission: emissionDto.emissionOrigineBiomasse,
      fossilEmission: emissionDto.emissionOrigineFossile,
      otherEmission: emissionDto.emission,
      // nim: emissionDto.nim,
      flow: flowOrMeasureStringToOptionProps(
        emissionDto.flux,
        flowDeclarations
      ),
      gas: gasEnumToOptionProps(emissionDto.gaz),
      method: methodEnumToOptionProps(emissionDto.methode),
    },
    errors: {},
  };
};

export const computedEmissionToEmissionSimpleDto = (
  computedEmission: ComputedEmissionInArray,
  flowDeclarations: FlowDeclarationProps[]
): QuotasEmissionSimpleCalculeDto1819 => {
  const flow = findFlowDeclaration(
    computedEmission.data.flow && computedEmission.data.flow.object,
    flowDeclarations
  );
  return {
    id: computedEmission.data.id,
    emissionOrigineBiomasse: computedEmission.data.biomassEmission,
    emissionOrigineFossile: computedEmission.data.fossilEmission,
    nim: flow && flow.nim && flow.nim.label,
    flux: flow && flow.name,
    gaz:
      computedEmission.data.gas &&
      gasOptionPropsToEnum(computedEmission.data.gas).calcule,
    methode:
      computedEmission.data.method &&
      methodOptionPropsToEnum(computedEmission.data.method).calcule,
    descriptionMethode: null,
    pointMesure: null,
    emission: null,
  };
};

export const dtoToBlocValues = (
  dto: QuotasBlocEmissionsDto1819,
  installationsInArray: InstallationInArray[]
): BlocEmissionsFormValues => {
  return {
    flowDeclarations: dto.flux.map(flux =>
      fluxDtoToFlowDeclaration(flux, installationsInArray)
    ),
    measureDeclarations: dto.pointsMesure.map(point =>
      pointMesureDtoToMeasureDeclaration(point, installationsInArray)
    ),
    exemption: !!dto.derogationFrequenceEchantillonnage,
    CO2Transfer: !!dto.transfertCO2,
  };
};

const blocValuesToDto = (
  values: BlocEmissionsFormValues | null,
  files: FileDto[],
  computedEmissionsInArray: ComputedEmissionInArray[],
  measuredEmissionsInArray: MeasureEmissionInArray[],
  alternativeEmissionsInArray: AlternativeEmissionInArray[],
  flowDeclarations: FlowDeclarationProps[],
  measureDeclarations: MeasureDeclarationProps[],
  exemptedFlows: string[]
): QuotasBlocEmissionsDto1819 => {
  return {
    fichiers: files,
    flux: values ? values.flowDeclarations.map(flowDeclarationToFluxDto) : [],
    pointsMesure: values
      ? values.measureDeclarations.map(measureDeclarationToPointMesureDto)
      : [],
    emissionsCalculees: computedEmissionsInArray.map(e =>
      computedEmissionToEmissionSimpleDto(e, flowDeclarations)
    ),
    emissionsMesurees: measuredEmissionsInArray.map(e =>
      measuredEmissionToEmissionSimpleDto(e, measureDeclarations)
    ),
    emissionsMethodeAlternative: alternativeEmissionsInArray.map(
      alternativeEmissionToEmissionSimpleDto
    ),
    derogationFrequenceEchantillonnage: values ? values.exemption : false,
    fluxSoumisADerogation: exemptedFlows.map(
      flow => flowIdToFlowOptionProps(flow, flowDeclarations).label
    ),
    transfertCO2: values ? values.CO2Transfer : false,
  };
};

export const updateHandler = (
  declaration: Declaration1919,
  values: BlocEmissionsFormValues | null,
  files: FileDto[],
  computedEmissionsInArray: ComputedEmissionInArray[],
  measuredEmissionsInArray: MeasureEmissionInArray[],
  alternativeEmissionsInArray: AlternativeEmissionInArray[],
  flowDeclarations: FlowDeclarationProps[],
  measureDeclarations: MeasureDeclarationProps[],
  exemptedFlows: string[]
) => {
  declaration.body.sections.quotas.blocEmissions = blocValuesToDto(
    values,
    files,
    computedEmissionsInArray,
    measuredEmissionsInArray,
    alternativeEmissionsInArray,
    flowDeclarations,
    measureDeclarations,
    exemptedFlows
  );
  return declaration;
};
