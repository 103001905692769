import React, { ReactElement } from "react";
import { MeasureDeclarationProps, MeasureEmissionInArray } from "../types";
import { makeStyles } from "@material-ui/styles";
import { ERROR } from "theme";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import Button from "common/button";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { getRoundedFixedNumberWithSeparatorOrNull } from "common/utils/numberUtils";
import { PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_MESUREES } from "common/path/path20Now";
import { useConfirmationModale } from "common/modale/hooks";
import Row from "common/presentational/Row";
import _ from "lodash";
import { findElementByIdOrNull } from "common/utils/methods";

interface MeasuredEmissionsArrayProps {
  measuredEmissionsInArray: MeasureEmissionInArray[];
  setMeasuredEmissionsInArray: (
    callback: (emissions: MeasureEmissionInArray[]) => MeasureEmissionInArray[]
  ) => void;
  onEdit: (emission: MeasureEmissionInArray) => void;
  isDisabled: boolean;
  measureDeclarations: MeasureDeclarationProps[];
}

const useStyles = makeStyles({
  array: {
    marginTop: "20px",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

const MeasuredEmissionsArray = ({
  isDisabled,
  onEdit,
  measuredEmissionsInArray,
  setMeasuredEmissionsInArray,
  measureDeclarations,
}: MeasuredEmissionsArrayProps): React.ReactElement => {
  const openConfirmationModale = useConfirmationModale();
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const header: ReactElement[] = [
    <p>Point de mesure</p>,
    <p>Gaz émis</p>,
    <p>NIM</p>,
    <p>Émission de CO2 d'origine non biomasse (tCO2e/an)</p>,
    <p>Émission de CO2 d'origine biomasse (tCO2e/an)</p>,
    // TODO : Jonathan repasse exposants
    <p>Émission de N2O ou PFC (tCO2e/an)</p>,
    <p>Actions</p>,
  ];

  const computeLine = (
    emission: MeasureEmissionInArray
  ): React.ReactElement[] => {
    const measure = findElementByIdOrNull(
      emission.data.measure && emission.data.measure.id,
      measureDeclarations
    );

    const firstCellContent = computeFirstLineContentCell(
      () => onEdit(emission),
      measure && measure.name !== null ? measure.name : undefined,
      isDisabled,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p>{emission.data.gas && emission.data.gas.toString()}</p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {measure && measure.nimInstallation && measure.nimInstallation.nim}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.fossilEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.fossilEmission
              )
            : ""}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.biomassEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.biomassEmission
              )
            : ""}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        <p>
          {emission.data.otherEmission !== null
            ? getRoundedFixedNumberWithSeparatorOrNull(
                emission.data.otherEmission
              )
            : ""}
        </p>,
        classes
      ),
      <CommonEntityButton
        handlerEdit={() => onEdit(emission)}
        commentPath={`${PATH_QUOTAS_EMISSIONS_BLOC_EMISSIONS_MESUREES}/${emission.data.id}`}
        handlerSuppress={() =>
          setMeasuredEmissionsInArray(() =>
            measuredEmissionsInArray.filter(e => e.data.id !== emission.data.id)
          )
        }
        isValidated={isDisabled}
        suppressMessage="Êtes-vous sûr de vouloir supprimer ces informations ?"
      />,
    ];
  };

  return (
    <>
      <CommonEntityTable
        header={header}
        preferredHeaderHeight={100}
        lines={measuredEmissionsInArray.map(computeLine)}
        additionalClassName={classes.array}
        preferredColWidths={[100, 80, 150, 80, 80, 80, 100]}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() =>
            openConfirmationModale(
              "Êtes-vous sûr de vouloir supprimer toutes les informations de ce tableau ?",
              () => setMeasuredEmissionsInArray(() => [])
            )
          }
        />
      </Row>
    </>
  );
};

export default MeasuredEmissionsArray;
