import React from "react";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeEmissionCell } from "./arrayUtils";
import { MethodeMesureEmission } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface MethodeMesureEmissionArrayProps {
  methodeMesureEmissionList: MethodeMesureEmission[];
}

const MethodeMesureEmissionArray = ({
  methodeMesureEmissionList,
}: MethodeMesureEmissionArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleMesureLine = (
    methodeMesureEmission: MethodeMesureEmission,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(methodeMesureEmission.pointMesure, classes),
      computeWrappedCell(methodeMesureEmission.gazMesure, classes),
      computeWrappedCell(
        computeEmissionCell(methodeMesureEmission.emissionsFossiles),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeMesureEmission.emissionsBiomasseDurable),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeMesureEmission.emissionsBiomasseNonDurable),
        classes
      ),
    ];
  };

  const mesureLines = methodeMesureEmissionList.map(methodeMesure =>
    computeSingleMesureLine(methodeMesure, classes)
  );
  return (
    <div className={classes.container}>
      <ArrayTitle>
        Méthode basée sur la mesure pour la détermination des émissions de N2O,
        CO2, CO2 transféré ou intrinsèque et N2O transféré ou intrinsèque
        (tonnes)
      </ArrayTitle>

      <CommonEntityTable
        header={[
          <p>Point de mesure</p>,
          <p>Gaz mesuré</p>,
          <p>Émissions de CO2e fossiles</p>,
          <p>Émissions de CO2 biomasse durable</p>,
          <p>Émissions de CO2 biomasse non durable</p>,
        ]}
        lines={mesureLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[1, 1, 1, 1, 1]}
      />
    </div>
  );
};

export default MethodeMesureEmissionArray;
