import { InArray, InModale } from "common/form/utils";
import { AirCombustionAppareilDto } from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { LegacySubInstallationElement } from "../../../utils/types";

type appareil = Omit<
  AirCombustionAppareilDto,
  "id" | "installation" | "date"
> & {
  date: Date;
} & LegacySubInstallationElement;

export type AppareilInModale = InModale<appareil>;
export type AppareilInArray = InArray<appareil>;

export const appareilFieldMatcher = fieldMatcherBuilder<
  AppareilInModale
>().build({
  nom: "nom",
  date: "date",
  modification: "modification",
  lowestThan1500: "lowestThan1500",
  localisation: "localisation",
  activite: "activite",
  nature: "nature",
  natureAutre: "natureAutre",
  typeFoyer: "typeFoyer",
  foyerAutre: "foyerAutre",
  capacite: "capacite",
  unite: "unite",
  hauteurRejets: "hauteurRejets",
});
