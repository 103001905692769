import { makeStyles } from "@material-ui/styles";
import React from "react";
import { CardProps } from "./type";
import classNames from "classnames";
import {
  DASHBOARD_BACKGROUND_GREY,
  DASHBOARD_DISABLED_BACKGROUND_GREY,
  DASHBOARD_DISABLED_FONT_GREY,
  DASHBOARD_DISABLED_HOVER_GREY,
  DEFAULT_BORDER_RADIUS,
  SHADOW,
} from "theme";

const useStyles = makeStyles({
  container: {
    height: "80px",
    width: "calc(20% - 30px)",
    backgroundColor: DASHBOARD_BACKGROUND_GREY,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: "15px",
    boxShadow: SHADOW,
    position: "relative",
  },

  overlay: {
    display: "none",
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    borderRadius: DEFAULT_BORDER_RADIUS,
    color: "white",
    backgroundColor: DASHBOARD_DISABLED_HOVER_GREY,
    alignItems: "center",
    "& p": {
      width: "95%",
      margin: "auto",
      textAlign: "justify",
    },
  },

  disabled: {
    position: "relative",
    boxShadow: "none",
    backgroundColor: DASHBOARD_DISABLED_BACKGROUND_GREY,
    color: DASHBOARD_DISABLED_FONT_GREY,
    cursor: "not-allowed",
    "&:hover $overlay": {
      display: "flex",
    },
  },
});

const Card = ({
  isDisabled,
  containerRef,
  additionalClassname,
  children,
  disabledMessage,
}: CardProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <div
      ref={containerRef}
      className={classNames(
        classes.container,
        { [classes.disabled]: isDisabled },
        additionalClassname
      )}
    >
      {children}
      <div className={classes.overlay}>
        <p>{disabledMessage}</p>
      </div>
    </div>
  );
};

export default Card;
