import { FiltresDeclarationDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  backMessageDeclarationFilesDownload,
  backMessageExportDeclaration,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale, useSuccessModale } from "common/modale/hooks";
import { downloadExportResult } from "pages/CommonSpace/CompanyTechnique/2019_now/utils";
import { useCallback } from "react";

export const useFilesAggregation = (): ((
  filters: FiltresDeclarationDto
) => Promise<boolean>) => {
  const { gestionnaireController } = useAuthenticatedApi();
  const openSuccessModale = useSuccessModale();
  const openAlertModale = useAlertModale();

  return useCallback(
    async (filters: FiltresDeclarationDto): Promise<boolean> => {
      try {
        await gestionnaireController.downloadAllDeclarationsFilesUsingPOST(
          filters
        );
        openSuccessModale("La demande d'export a bien été reçue.");
      } catch (excp) {
        openAlertModale(backAlertMessage(excp, backMessageExportDeclaration));
        return false;
      }

      return true;
    },
    [gestionnaireController, openAlertModale]
  );
};

export const useAllDeclarationFilesExport = (): ((
  declarationAnnee: number,
  declarationEtablissement: string
) => Promise<boolean>) => {
  const { fileController } = useAuthenticatedApi();

  // Be careful : Swagger does not bind functions with their associated controlers, so we need to
  // use an anonymous function that calls the Swagger one.
  // See https://github.com/Polyconseil/mtes-gerep/pull/1188#discussion_r423547032
  const downloadGETFunction = useCallback(
    (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<Response> =>
      fileController.downloadAllDeclarationFilesUsingGET(
        declarationAnnee,
        declarationEtablissement
      ),
    [fileController]
  );

  const openModale = useAlertModale();

  return useCallback(
    async (
      declarationAnnee: number,
      declarationEtablissement: string
    ): Promise<boolean> => {
      try {
        const downloadResponse = await downloadGETFunction(
          declarationAnnee,
          declarationEtablissement
        );
        if (
          !(await downloadExportResult(
            downloadResponse,
            `${declarationEtablissement}_${declarationAnnee}.zip`
          ))
        ) {
          throw Error("Failed to download declaration content.");
        }
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageDeclarationFilesDownload));
        return false;
      }
      return true;
    },
    [downloadGETFunction, openModale]
  );
};

export const useOneFolderFilesExport = (): ((
  declarationAnnee: number,
  declarationEtablissement: string,
  section: FileSectionEnum
) => Promise<boolean>) => {
  const { fileController } = useAuthenticatedApi();

  // Be careful : Swagger does not bind functions with their associated controlers, so we need to
  // use an anonymous function that calls the Swagger one.
  // See https://github.com/Polyconseil/mtes-gerep/pull/1188#discussion_r423547032
  const downloadGETFunction = useCallback(
    (
      declarationAnnee: number,
      declarationEtablissement: string,
      section: FileSectionEnum
    ): Promise<Response> =>
      fileController.downloadFolderFilesUsingGET(
        declarationAnnee,
        declarationEtablissement,
        section
      ),
    [fileController]
  );

  const openModale = useAlertModale();

  return useCallback(
    async (
      declarationAnnee: number,
      declarationEtablissement: string,
      section: FileSectionEnum
    ): Promise<boolean> => {
      try {
        const downloadResponse = await downloadGETFunction(
          declarationAnnee,
          declarationEtablissement,
          section
        );
        if (
          !(await downloadExportResult(
            downloadResponse,
            `${declarationEtablissement}_${declarationAnnee}.zip`
          ))
        ) {
          throw Error("Failed to download declaration content.");
        }
      } catch (excp) {
        openModale(backAlertMessage(excp, backMessageDeclarationFilesDownload));
        return false;
      }
      return true;
    },
    [downloadGETFunction, openModale]
  );
};
