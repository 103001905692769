import { TechniqueQuotasValues } from "./utils";
import { InstallationInArray } from "./Installations/types";
import { singleInstallationToInstallationDto } from "./Installations/converter";
import { TypeActiviteQuota21Now } from "../../../toNow/versionedElements/declarationHooks21Now";

export const computeTechQuotaInitialValues = (
  typeActiviteQuotaDto: TypeActiviteQuota21Now
): TechniqueQuotasValues => {
  const { installations, ...commonActiviteField } = typeActiviteQuotaDto;

  return commonActiviteField;
};

export const createTechniqueQuotaDto = (
  values: TechniqueQuotasValues,
  installationsInArray: InstallationInArray[]
): TypeActiviteQuota21Now => {
  return {
    ...values,
    installations: installationsInArray.map(
      singleInstallationToInstallationDto
    ),
  };
};
