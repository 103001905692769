import React from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import Button from "common/button";
import PolluantArray from "./PolluantArray";
import { BlocRejetPolluantOptions, PolluantInArray } from "./types";
import PolluantModale from "./PolluantModale";
import { updateHandlerRejetPolluant } from "../utils";
import { useEntityModale } from "common/modale/modaleStates";
import { PATH_EAU_REJETPOLLUANT } from "common/path/path18Now";
import { ReferenceNormeEauDto, ReferencePolluantDto } from "api/gen";
import _ from "lodash";
import BlocFullContext24Now from "../../versionedElements/BlocFullContext24Now";
import jaugeLogo from "icons/jauge.svg";

const useStyles = makeStyles({
  jaugeIcon: {
    width: "30px",
    marginBottom: "20px",
  },
  text: {
    margin: "12px 0",
  },
});

interface BlocRejetPolluantProps {
  polluantsInArray: PolluantInArray[];
  setPolluantsInArray: (polluants: PolluantInArray[]) => void;
  hasChanges: boolean;
  cancelAction: () => void;
  selectPossibleValues: BlocRejetPolluantOptions;
  referentialPolluants: ReferencePolluantDto;
  referentialNormes: ReferenceNormeEauDto;
}

const BlocRejetPolluant = ({
  polluantsInArray,
  setPolluantsInArray,
  hasChanges,
  cancelAction,
  selectPossibleValues,
  referentialPolluants,
  referentialNormes,
}: BlocRejetPolluantProps): JSX.Element => {
  const classes = useStyles();

  const { modaleState, openModale, closeModale } = useEntityModale<
    PolluantInArray
  >();

  const canSubmitArray = true;

  const compareFunction = (a: PolluantInArray, b: PolluantInArray): number => {
    if (
      a.data.pollutingSubstance &&
      b.data.pollutingSubstance &&
      a.data.pollutingSubstance.nom !== b.data.pollutingSubstance.nom
    ) {
      return a.data.pollutingSubstance.nom < b.data.pollutingSubstance.nom
        ? -1
        : 1;
    }
    return 0;
  };

  return (
    <BlocFullContext24Now
      title="tableau des rejets de substance dans l'eau"
      hasModification={hasChanges}
      isValidateButtonAvailable={canSubmitArray}
      path={PATH_EAU_REJETPOLLUANT}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration =>
        updateHandlerRejetPolluant(declaration, polluantsInArray)
      }
      cancelAction={cancelAction}
      renderContent={shouldDisabledFields => (
        <>
          <div className={classes.text}>
            Les valeurs en gras dépassent les seuils de l'arrêté ministériel du
            31/01/2008 relatif au registre et à la déclaration annuelle des
            émissions et de transferts de polluants et des déchets.
          </div>
          <Row
            additionalStyle={{
              justifyContent: "flex-end",
            }}
          >
            <Button
              text="AJOUTER UNE SUBSTANCE"
              type="button"
              onClick={() => openModale(null)}
              additionalStyle={{ marginLeft: "40px" }}
              isDisabled={shouldDisabledFields}
            />
          </Row>
          <Row height="15px" />
          <PolluantArray
            polluantsInArray={_.cloneDeep(polluantsInArray).sort(
              compareFunction
            )}
            setPolluantsInArray={setPolluantsInArray}
            openModal={openModale}
            shouldDisabledFields={shouldDisabledFields}
          />
          <Row
            additionalStyle={{
              marginTop: "15px",
              marginBottom: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              text="TOUT SUPPRIMER"
              type="button"
              isReversed
              onClick={() => setPolluantsInArray([])}
              isDisabled={shouldDisabledFields}
            />
          </Row>
          <PolluantModale
            isOpen={modaleState.isOpen}
            polluantInModal={modaleState.elementToEdit}
            polluantsInArray={polluantsInArray}
            setPolluantsInArray={setPolluantsInArray}
            closeModal={closeModale}
            selectPossibleValues={selectPossibleValues}
          />
        </>
      )}
      icon={
        polluantsInArray.some(
          item =>
            item.data.emission != null &&
            item.data.pollutingSubstance != null &&
            item.data.pollutingSubstance.seuilKgAn !== null &&
            item.data.emission > item.data.pollutingSubstance.seuilKgAn
        ) && (
          <img
            src={jaugeLogo}
            alt="Depassement de Seuil"
            className={classes.jaugeIcon}
          />
        )
      }
    />
  );
};

export default BlocRejetPolluant;
