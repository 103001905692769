import React, { Dispatch, SetStateAction } from "react";
import { makeStyles } from "@material-ui/styles";
import { Nullable } from "common/utils/types";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import {
  AccidentInArray,
  AccidentsTravailData,
  accidentTravailFieldMatcher,
} from "./utils";
import Button from "common/button";
import Row from "common/presentational/Row";
import AccidentModale from "./AccidentModale";
import AccidentArray from "./AccidentArray";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import _ from "lodash";
import { useEntityModale } from "common/modale/modaleStates";
import { validationSchema } from "./validation";
import { computeInitialFormValues } from "./converters";
import { updateAccidentsTravail } from "../utils/updaters";
import {
  PATH_CARRIERE_SANTE_ACCIDENT,
  PATH_CARRIERE_SANTE_ACCIDENT_ACCIDENT,
  PATH_CARRIERE_SANTE_ACCIDENT_ARRET_TRAVAIL,
} from "common/path/path18Now";
import { useConfirmationModale } from "common/modale/hooks";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";

//to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface AccidentsTravailProps
  extends FormikBlocRefAndState<Nullable<AccidentsTravailData>> {
  mainAccidentCauseList: string[];
  initialAccidentsList: AccidentInArray[];
  //dispatch is used here because it's needed in both form of setter, both directly setting a state or settng it with a callback.
  //(plus, it's typed as dispatch in children component)
  setAccidentsList: Dispatch<SetStateAction<AccidentInArray[]>>;
  accidentsList: AccidentInArray[];
}

const AccidentsTravail = ({
  hasChanges,
  setHasChanges,
  formikRef,
  mainAccidentCauseList,
  setAccidentsList,
  initialAccidentsList,
  accidentsList,
}: AccidentsTravailProps): React.ReactElement => {
  const classes = useStyles();
  const dataDeclaration = useDeclaration20Now();
  const initialValues = computeInitialFormValues(dataDeclaration);

  const { modaleState, openModale, closeModale } = useEntityModale<
    AccidentInArray
  >();
  const openConfirmationModale = useConfirmationModale();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "carriere-accidents-travail",
  };

  const CheckboxField = useBooleanCheckBoxGenerator(commonProps);

  return (
    <>
      <FormikBlocFullContext20Now
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        hasFormChanges={currentValues => {
          return (
            !_.isEqual(currentValues, initialValues) ||
            !_.isEqual(initialAccidentsList, accidentsList)
          );
        }}
        cancelAction={() => {
          setAccidentsList(initialAccidentsList);
          return initialValues;
        }}
        formikRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        title={"accidents du travail (tss2)"}
        pathToValidate={PATH_CARRIERE_SANTE_ACCIDENT}
        areGlobalCommentsAllowed={true}
        updateHandler={(declaration, values) => {
          return updateAccidentsTravail(declaration, values, accidentsList);
        }}
        renderContent={({ values }, shouldDisabledFields) => (
          <>
            <CheckboxField
              label="Accident ayant entraîné un arrêt de travail supérieur à 3 jours ouvrables"
              name={accidentTravailFieldMatcher.bigAccident}
              disabled={shouldDisabledFields}
              commentPath={PATH_CARRIERE_SANTE_ACCIDENT_ARRET_TRAVAIL}
            />
            <Row />
            {values.bigAccident && (
              <>
                <Row
                  additionalStyle={{
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    text="AJOUTER"
                    type="button"
                    onClick={() => openModale(null)}
                    isDisabled={shouldDisabledFields}
                  />
                </Row>
                <Row />
                <AccidentArray
                  accidentsInArray={accidentsList}
                  isDisabled={shouldDisabledFields}
                  openModale={openModale}
                  setAccidentsInArray={setAccidentsList}
                  commentBasePath={PATH_CARRIERE_SANTE_ACCIDENT_ACCIDENT}
                />
                <Row />
                <Row
                  additionalStyle={{
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    isReversed={true}
                    text="TOUT SUPPRIMER"
                    type="button"
                    onClick={() =>
                      openConfirmationModale(
                        "êtes vous sûr de vouloir supprimer tout les accidents du tableau ?",
                        () => setAccidentsList([])
                      )
                    }
                    isDisabled={
                      shouldDisabledFields || accidentsList.length === 0
                    }
                  />
                </Row>
                <Row />
              </>
            )}
          </>
        )}
      />
      <AccidentModale
        setAccidentsInArray={setAccidentsList}
        modaleState={modaleState}
        closeModale={closeModale}
        mainAccidentCauseList={mainAccidentCauseList}
      />
    </>
  );
};
export default AccidentsTravail;
