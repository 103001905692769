import { useCallback, useState } from "react";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageDeleteEtablissement } from "common/backErrors/errorMessages";

export const useDeleteEtablissement = (): {
  deleteEtablissement: (etablissement: string) => Promise<boolean>;
  shouldRedirect: boolean;
} => {
  const { etablissementController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const deleteEtablissement = useCallback(
    async (etablissement: string) => {
      try {
        await etablissementController.deleteEtablissementUsingDELETE(
          etablissement
        );
        setShouldRedirect(true);
        return true;
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageDeleteEtablissement)
        );
        return false;
      }
    },
    [etablissementController, openModale]
  );

  return { deleteEtablissement, shouldRedirect };
};
