import React from "react";
import { makeStyles } from "@material-ui/styles";
import { MesureEmpoussierageData } from "./utils";

import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { Nullable } from "common/utils/types";
import { validationSchema } from "./validation";
import Row from "common/presentational/Row";
import PoussiereAlveolaireQuartzTridimiteSubPart from "./poussiere/PoussiereAlveolaireQuartzTridimiteSubPart";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import { fillPoussieresSubPartIfEmpty } from "./changeHandler";
import PoussiereAlveolaireTotaleSubPart from "./poussiere/PoussiereAlveolaireTotaleSubPart";
import { convertDeclarationEmpoussierageDtoToMesureEmpoussierageData } from "./converters";
import { updateMesuresEmpoussierage } from "../utils/updaters";
import {
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE_MESURE_EFFECTUEE,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE_NBGEH,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_QUARTZ,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_TOTAL,
} from "common/path/path18Now";
import { ReferenceItemOrganismeAccrediteDto } from "api/gen";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";

//to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longInput: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  ...LEFT_WITHDRAW_STYLE,
});

interface MesuresEmpoussierageProps
  extends FormikBlocRefAndState<Nullable<MesureEmpoussierageData>> {
  activeOrganismeAccrediteList: ReferenceItemOrganismeAccrediteDto[];
}

const MesuresEmpoussierage = ({
  formikRef,
  hasChanges,
  setHasChanges,
  activeOrganismeAccrediteList,
}: MesuresEmpoussierageProps): React.ReactElement => {
  const dataDeclaration = useDeclaration20Now();
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "carriere-mesures-empoussierage",
  };

  const CheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps, classes.longInput);

  return (
    <FormikBlocFullContext20Now
      formikRef={formikRef}
      hasChanges={hasChanges}
      setHasChanges={setHasChanges}
      initialValues={convertDeclarationEmpoussierageDtoToMesureEmpoussierageData(
        dataDeclaration.body.sections.carriere.sante.empoussierage,
        activeOrganismeAccrediteList
      )}
      validationSchema={validationSchema}
      title={"Mesures d'empoussiérage (TSS3, décret n° 2013-797 du 30/08/2013)"}
      pathToValidate={PATH_CARRIERE_SANTE_EMPOUSSIERAGE}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) =>
        updateMesuresEmpoussierage(
          declaration,
          values,
          activeOrganismeAccrediteList
        )
      }
      renderContent={(formikProps, shouldDisabledFields) => {
        const { values } = formikProps;
        return (
          <>
            <CheckboxField
              label={"L'évaluation des risques a été réalisée"}
              name={"wasRiskEvaluationRealised"}
              disabled={shouldDisabledFields}
              additionalOnChange={selected => {
                if (selected) {
                  fillPoussieresSubPartIfEmpty(formikProps);
                }
              }}
              commentPath={PATH_CARRIERE_SANTE_EMPOUSSIERAGE_MESURE_EFFECTUEE}
            />
            {values.wasRiskEvaluationRealised && (
              <div className={classes.withdrawLeft}>
                <NumberField
                  name={"nbGEH"}
                  label={"Nombre de GEH (Groupe Exposition Homogène) *"}
                  disabled={shouldDisabledFields}
                  unit={""}
                  tooltipContent={
                    "Nombre de groupes sur lesquels on réalise les mesures de poussières alvéolaires"
                  }
                  commentPath={PATH_CARRIERE_SANTE_EMPOUSSIERAGE_NBGEH}
                />
                <Row />
                <PoussiereAlveolaireQuartzTridimiteSubPart
                  shouldDisableFields={shouldDisabledFields}
                  commonProps={commonProps}
                  formikProps={formikProps}
                  organismeMeasuringEmpoussierageList={
                    activeOrganismeAccrediteList
                  }
                  commentBasePath={
                    PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_QUARTZ
                  }
                />
                <Row />
                <PoussiereAlveolaireTotaleSubPart
                  shouldDisableFields={shouldDisabledFields}
                  commonProps={commonProps}
                  formikProps={formikProps}
                  organismeMeasuringEmpoussierageList={
                    activeOrganismeAccrediteList
                  }
                  commentBasePath={
                    PATH_CARRIERE_SANTE_EMPOUSSIERAGE_TABLEAU_TOTAL
                  }
                />
              </div>
            )}
          </>
        );
      }}
    />
  );
};
export default MesuresEmpoussierage;
