import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { backMessageAllowedExtensionsFile } from "common/backErrors/errorMessages";
import Button from "common/button";
import FileLoader from "common/button/FileLoader";
import { FileSectionEnum } from "./types";
import { extensionsStateReducer } from "./utils";
import Spinner from "common/presentational/Spinner";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import React, { useCallback, useEffect, useReducer } from "react";
import { DEFAULT_LINE_HEIGHT } from "theme";
import { useAllowedExtensionsHandler } from "./hooks/filesHooks";

const useStyles = makeStyles({
  icon: {
    paddingLeft: "8px",
  },
  rowSize: {
    minHeight: DEFAULT_LINE_HEIGHT,
    "&>button": {
      minHeight: DEFAULT_LINE_HEIGHT,
    },
  },
  leftAlign: {
    justifyContent: "flex-start",
  },
});

interface FileLoaderButtonProps {
  text: string;
  section: FileSectionEnum;
  isFileLoaderDisabled: boolean;

  additionalClassnameButton?: string;

  addFileToUpload: (file: File) => Promise<void>;
  onReject?: () => void;
}

const FileLoaderButton = ({
  text,
  section,
  isFileLoaderDisabled,
  additionalClassnameButton,
  addFileToUpload,
  onReject,
}: FileLoaderButtonProps): React.ReactElement => {
  const classes = useStyles();
  const allowedExtensionsHandler = useAllowedExtensionsHandler();

  //useReducer, with a launchLoad and concludeLoad action. State object should contains the data taken
  //as well as a key to indicate loadingStatus and successStatus
  const [
    allowedExtensions,
    dispatchAllowedExtensions,
  ] = useReducer(extensionsStateReducer, { state: "disabled" });

  const loadListExtensions = useCallback(() => {
    dispatchAllowedExtensions({ type: "launchLoad" });
    allowedExtensionsHandler(section).then(listExtension => {
      dispatchAllowedExtensions({
        type: "loadResult",
        backResult: listExtension,
      });
    });
  }, [allowedExtensionsHandler, section]);

  useEffect(() => {
    if (!isFileLoaderDisabled) {
      loadListExtensions();
    }
  }, [isFileLoaderDisabled, loadListExtensions]);

  switch (allowedExtensions.state) {
    case "loading":
      return <Spinner additionalClassname={classes.leftAlign} />;
    case "success":
      return (
        <FileLoader
          text={
            <>
              {text}
              <FontAwesomeIcon className={classes.icon} icon={faUpload} />
            </>
          }
          isDisabled={isFileLoaderDisabled}
          isReversed
          onFileHandler={addFileToUpload}
          acceptedFileType={allowedExtensions.result}
          additionalClassname={classNames(
            classes.rowSize,
            additionalClassnameButton
          )}
          onReject={onReject}
        />
      );
    case "disabled":
      return (
        <FileLoader
          text={
            <>
              {text}
              <FontAwesomeIcon className={classes.icon} icon={faUpload} />
            </>
          }
          isDisabled={true}
          isReversed
          onFileHandler={addFileToUpload}
          additionalClassname={classNames(
            classes.rowSize,
            additionalClassnameButton
          )}
          onReject={onReject}
        />
      );
    case "failed":
      return (
        <>
          <p>{backMessageAllowedExtensionsFile}</p>
          <Button
            text={"recharger"}
            onClick={loadListExtensions}
            additionalClassname={classes.rowSize}
          />
        </>
      );
    default:
      throw new ShouldNotHappen(allowedExtensions);
  }
};

export default FileLoaderButton;
