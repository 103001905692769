import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { AccidentInArray } from "../utils";
import { ARRAY_DEFAULT_BUTTON_WIDTH_AS_NUMBER, ERROR } from "theme";
import { ClassesType } from "common/utils/types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { computeAccidentDateProperlyFormatted } from "./utils";
import { otherAccidentLabel } from "../AccidentModale/validation";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
const numberFormatter = new Intl.NumberFormat("fr-FR", {});

// TODO: quite sure these props also could be factorised...
interface AccidentArrayProps {
  accidentsInArray: AccidentInArray[];
  setAccidentsInArray: Dispatch<SetStateAction<AccidentInArray[]>>;
  openModale: (elem: AccidentInArray | null) => void;
  isDisabled: boolean;
  commentBasePath: string;
}

const AccidentArray = ({
  accidentsInArray,
  setAccidentsInArray,
  openModale,
  isDisabled,
  commentBasePath,
}: AccidentArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  //TODO : could be extracted with the PopulatedElement that we developed. (https://dl.polyconseil.fr/jira/browse/GEREP-657)
  function suppressAccident(singleAccidentInArray: AccidentInArray) {
    setAccidentsInArray(elderAccidents => {
      return elderAccidents.filter(singleElderAccident => {
        return singleElderAccident.data.id !== singleAccidentInArray.data.id;
      });
    });
  }

  //TODO : this method could be the param used in the generic arrayBuilder ?
  const computeSingleAccidentLine = (
    accident: AccidentInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const handlerEdit = () => {
      openModale(accident);
    };

    // the additional check is because if the value is autre cause, we want to display the content of the precision field
    const accidentCause: string | undefined =
      accident.data.accidentCause !== null &&
      accident.data.accidentCause.label !== otherAccidentLabel
        ? accident.data.accidentCause.label
        : accident.data.accidentCausePrecision || undefined;

    const firstCellContent = computeFirstLineContentCell(
      handlerEdit,
      computeAccidentDateProperlyFormatted(accident),
      isDisabled,
      !_.isEmpty(accident.errors)
    );

    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        <p title={accidentCause}>{accidentCause}</p>,
        classes
      ),
      computeWrappedCellWithError(
        accident.data.nbDaysStopped !== null
          ? numberFormatter.format(accident.data.nbDaysStopped)
          : undefined,
        classes
      ),
      computeWrappedCellWithError(accident.data.isConsolidated, classes),
      // TODO: fitzPRComment, see https://github.com/Polyconseil/mtes-gerep/pull/19#discussion_r290202311
      // (short version, use the same function for all action, and give a dataset-index for polluant identification
      //  in the action, that will therefore become common to all buttons.
      <CommonEntityButton
        handlerEdit={handlerEdit}
        suppressMessage={"êtes vous sûr de vouloir supprimer cet accident ?"}
        handlerSuppress={() => suppressAccident(accident)}
        isValidated={isDisabled}
        commentPath={`${commentBasePath}/${accident.data.id}`}
      />,
    ];
  };

  const arrayAccidentsLines = accidentsInArray.map(singleAccidentInArray => {
    return computeSingleAccidentLine(singleAccidentInArray, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Date de l'accident</p>,
        <p>Cause principale de l'accident</p>,
        <p>Nombre cumulé de jours d'arrêt ouvrables</p>,
        <p>Arrêt de travail consolidé</p>,
        <p>Actions</p>,
      ]}
      lines={arrayAccidentsLines}
      preferredColWidths={[
        200,
        250,
        250,
        150,
        ARRAY_DEFAULT_BUTTON_WIDTH_AS_NUMBER,
      ]}
    />
  );
};

export default AccidentArray;
