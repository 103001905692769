import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { InArray, InModale } from "common/form/utils";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { InstallationInArray } from "../blocInstallations/types";

export interface FlowDeclarationProps {
  id: string;
  nim: OptionProps | null;
  name: string | null;
}

export interface MeasureDeclarationProps {
  id: string;
  nim: OptionProps | null;
  name: string | null;
}

export enum GasOptions {
  PFC,
  N2O,
  CO2,
}

export enum ComputedMethodOptions {
  BilanMassique,
  FacteurOxydation,
  FacteurConversion,
}

export interface BlocEmissionsFormValues extends FormikValues {
  flowDeclarations: FlowDeclarationProps[];
  measureDeclarations: MeasureDeclarationProps[];
  exemption: boolean;
  CO2Transfer: boolean;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 *
 * when suppressing, be careful, the export is because this type is used in a method. most likely it will have to be modified to fit the type from back, in the case it isn't exported.
 *
 * TODO to suppress properly : https://dl.polyconseil.fr/jira/browse/GEREP-996
 */
export interface LegacyEmissionProps {
  method: OptionProps;
  gas: OptionPropsWithObject<GasOptions>;
  fossilEmission: number | null;
  biomassEmission: number | null;
  otherEmission: number | null;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyComputedEmission extends LegacyEmissionProps {
  flow: OptionProps;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyMeasureEmission extends LegacyEmissionProps {
  measure: OptionProps;
}

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
interface LegacyAlternativeEmission extends LegacyEmissionProps {
  methodDescription: string;
  nimOptionProps: OptionProps;
}

export type ComputedEmissionInModale = InModale<LegacyComputedEmission>;
export type MeasureEmissionInModale = InModale<LegacyMeasureEmission>;
export type AlternativeEmissionInModale = InModale<LegacyAlternativeEmission>;

export type ComputedEmissionInArray = InArray<LegacyComputedEmission>;

export type MeasureEmissionInArray = InArray<LegacyMeasureEmission>;

export type AlternativeEmissionInArray = InArray<LegacyAlternativeEmission>;

export interface ComputedAllowedDeclarationMethods {
  bilanMassique: boolean;
  facteurConversion: boolean;
  facteurOxydation: boolean;
}

export interface AllowedDeclarationMethods {
  mesure: boolean;
  alternative: boolean;
  computed: false | ComputedAllowedDeclarationMethods;
}

export interface BlocEmissionsProps {
  blocEmissionsFormProps: FormikBlocRefAndState<BlocEmissionsFormValues>;
  allowedDeclarationMethods: AllowedDeclarationMethods;
  installationsInArray: InstallationInArray[];
  isDisabled: boolean;
  computedEmissionsInArray: ComputedEmissionInArray[];
  setComputedEmissionsInArray: (
    callback: (
      emissions: ComputedEmissionInArray[]
    ) => ComputedEmissionInArray[]
  ) => void;
  measuredEmissionsInArray: MeasureEmissionInArray[];
  setMeasuredEmissionsInArray: (
    callback: (emissions: MeasureEmissionInArray[]) => MeasureEmissionInArray[]
  ) => void;
  alternativeEmissionsInArray: AlternativeEmissionInArray[];
  setAlternativeEmissionsInArray: (
    callback: (
      previousEmissions: AlternativeEmissionInArray[]
    ) => AlternativeEmissionInArray[]
  ) => void;
  exemptedFlows: string[];
  setExemptedFlows: (setter: (flows: string[]) => string[]) => void;
}
