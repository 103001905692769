import { useLayoutEffect } from "react";
import { detect } from "detect-browser";
import { useAlertModale } from "common/modale/hooks";

const CHROME_VERSION = 57;
const EDGE_VERSION = 57;
const FIREFOX_VERSION = 74;
const IE_VERSION = 57;
const OPERA_VERSION = 57;
const SAFARI_VERSION = 57;
const ERROR_MESSAGE = `Cette application n'est pas compatible avec Internet Explorer. Merci d'utiliser une version récente de Chrome ou de Firefox. (Chrome ${CHROME_VERSION}+, Firefox ${FIREFOX_VERSION}+ ...)`;

const isBrowserSupported = () => {
  const browser = detect();
  if (browser && browser.version) {
    const majorVersion = browser.version.split(".")[0];
    switch (browser.name) {
      case "chrome":
        return parseInt(majorVersion) >= CHROME_VERSION;
      case "edge":
        return parseInt(majorVersion) >= EDGE_VERSION;
      case "firefox":
        return parseInt(majorVersion) >= FIREFOX_VERSION;
      case "ie":
        return parseInt(majorVersion) >= IE_VERSION;
      case "opera":
        return parseInt(majorVersion) >= OPERA_VERSION;
      case "safari":
        return parseInt(majorVersion) >= SAFARI_VERSION;
      default:
        return false;
    }
  }

  return false;
};

interface BrowserSupportProps {
  children: React.ReactElement;
}

export const BrowserSupport = ({ children }: BrowserSupportProps) => {
  const openAlertModale = useAlertModale();

  useLayoutEffect(() => {
    if (!isBrowserSupported()) {
      openAlertModale(ERROR_MESSAGE);
    }
  }, [openAlertModale]);

  return children;
};
