import {
  ReferenceItemFamilleUsageProductionDto,
  ReferenceItemSubstanceCarriereDto,
  SubstanceExtractionDto23Now,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { WithFamily, withFamilyTypeMatcher } from "../../Families/types";

export interface ExtractiveActivityForm {
  depositQuantity: number | null;
  annualGeneratedQuantity: number | null;
}

export type SubstanceExtractionType = Omit<
  SubstanceExtractionDto23Now,
  "famille" | "sousFamille" | "sousFamilleNiveau2" | "substanceId"
> &
  WithFamily<ReferenceItemFamilleUsageProductionDto> & {
    substance: ReferenceItemSubstanceCarriereDto | null;
  };

const substanceExtractionTypeWithoutFamilyMatcher = fieldMatcherBuilder<
  SubstanceExtractionType
>().build({
  substance: "substance",
  quantity: "quantite",
});

export const fieldSubstanceExtractionTypeMatcher = {
  ...substanceExtractionTypeWithoutFamilyMatcher,
  ...withFamilyTypeMatcher,
};
