import React, { ReactNode } from "react";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import { useUserData } from "Authenticator/UserData";
import { DeclarationStateDtoStateEnum } from "api/gen";
import RedirectHome from "pages/CommonSpace/AppPage/Redirect/RedirectHome";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { PATH_QUOTAS } from "common/path/path18Now";
import InfobulleGlobalActions from "./utils/InfobulleGlobalActions";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { useQuotasButtonStateWithRedirection } from "./utils/utils";
import {
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
  useDeclarationStateHandlers1919,
} from "../versionedElements/declarationHooks1919";
import { WorkflowTarget } from "common/utils/types";

interface QuotasGlobalFormActions {
  saveAction: () => Promise<boolean>;
  cancelAction: () => void;
  hasChanges: boolean;
  declarantBlocsValidated: boolean;
}

// Contrarly to other pages (where GlobalFormActions are used to mark page as validated for user),
// page Quotas will use those button to submit quotas declaration or recall it.
const QuotasGlobalFormActions = ({
  saveAction,
  cancelAction,
  hasChanges,
  declarantBlocsValidated,
}: QuotasGlobalFormActions) => {
  const { validate, cancelValidate } = useBasicDeclarationHandlers1919();
  const {
    yieldQuotaVerif,
    cancelSubmit,
    submit,
    submitSimplifiedQuota,
  } = useDeclarationStateHandlers1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();
  const userData = useUserData();

  const [
    isValidateSpinnerVisible,
    triggerValidateSpinner,
    shouldRedirectToDashboard,
    quotaState,
  ] = useQuotasButtonStateWithRedirection<boolean>();
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<boolean>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<boolean>();

  const declaration = useDeclaration1919();
  const isArticle27 =
    declaration.body.sections.quotas.blocInfoGenerales.excluArticle27;

  const additionalLeftContent: ReactNode | undefined = userData.isDeclarant && (
    <InfobulleGlobalActions />
  );
  if (shouldRedirectToDashboard) {
    return <RedirectHome initialRedirection={true} />;
  } else if (
    quotaState === DeclarationStateDtoStateEnum.STARTED ||
    quotaState === DeclarationStateDtoStateEnum.VERIFYING ||
    quotaState === DeclarationStateDtoStateEnum.IN_REVIEW
  ) {
    const isValidateAvailable =
      userData.isDeclarant &&
      (quotaState === DeclarationStateDtoStateEnum.STARTED ||
        quotaState === DeclarationStateDtoStateEnum.IN_REVIEW);
    const isEditAvailable =
      userData.isDeclarant &&
      quotaState === DeclarationStateDtoStateEnum.VERIFYING;
    const areSaveAndCancelVisible =
      userData.isDeclarant &&
      quotaState === DeclarationStateDtoStateEnum.STARTED;

    return (
      <DummyGlobalFormActions
        additionalLeftContent={additionalLeftContent}
        validateButton={{
          title: isArticle27
            ? "TRANSMETTRE À L'INSPECTION"
            : "TRANSMETTRE AU VÉRIFICATEUR",
          isVisible: isValidateAvailable,
          isEnabled: isValidateAvailable && declarantBlocsValidated,
          onClick: async () => {
            // if the state is in review, it means it could be already validated
            if (isPathValidatedInDeclaration(PATH_QUOTAS)) {
              await cancelValidate(PATH_QUOTAS);
            }

            // validate whole page QUOTAS (without changing dto) before submitting verification
            const validateCall = async () => {
              if (
                !(await validate(declarationDto => declarationDto, PATH_QUOTAS))
              ) {
                return false;
              }
              const result = isArticle27
                ? await submitSimplifiedQuota(WorkflowTarget.QUOTA_EMISSIONS)
                : await yieldQuotaVerif();
              return result;
            };
            return triggerValidateSpinner(validateCall());
          },
          isSpinnerVisible: isValidateSpinnerVisible,
        }}
        editButton={{
          title: "MODIFIER",
          isVisible: isEditAvailable,
          isEnabled: isEditAvailable,
          onClick: () => {
            return triggerEditSpinner(cancelValidate(PATH_QUOTAS));
          },
          isSpinnerVisible: isEditSpinnerVisible,
        }}
        cancelButton={{
          title: "ANNULER",
          isVisible:
            areSaveAndCancelVisible &&
            hasChanges &&
            !isValidateSpinnerVisible &&
            !isSaveSpinnerVisible,
          isEnabled: true,
          onClick: cancelAction,
        }}
        saveButton={{
          title: "ENREGISTRER",
          isVisible: areSaveAndCancelVisible && hasChanges,
          isEnabled: !isSaveSpinnerVisible,
          onClick: () => {
            return triggerSaveSpinner(saveAction());
          },
          isSpinnerVisible: isSaveSpinnerVisible,
        }}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
      />
    );
  } else {
    const canSubmitForVerification =
      quotaState === DeclarationStateDtoStateEnum.VERIFIED;
    return (
      <>
        {quotaState === DeclarationStateDtoStateEnum.VERIFIED && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            editButton={{
              title: "MODIFIER",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () => {
                return triggerEditSpinner(cancelValidate(PATH_QUOTAS));
              },
              isSpinnerVisible: isEditSpinnerVisible,
            }}
          />
        )}
        {(quotaState === DeclarationStateDtoStateEnum.SUBMITTED ||
          quotaState === DeclarationStateDtoStateEnum.VALIDATED) && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            editButton={{
              title: "DEMANDER LA MODIFICATION",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () => {
                return triggerEditSpinner(
                  cancelSubmit([WorkflowTarget.QUOTA_EMISSIONS])
                );
              },
              isSpinnerVisible: false,
            }}
          />
        )}
        {canSubmitForVerification && (
          <DummyGlobalFormActions
            additionalLeftContent={additionalLeftContent}
            validateButton={{
              title: "TRANSMETTRE À L'INSPECTION",
              isVisible: true,
              isEnabled: userData.isDeclarant,
              onClick: () =>
                triggerValidateSpinner(
                  submit([WorkflowTarget.QUOTA_EMISSIONS])
                ),
              isSpinnerVisible: isValidateSpinnerVisible,
            }}
            validationMessage={{
              message: DEFAULT_VALIDATION_MESSAGE,
              isAlwaysVisible: false,
            }}
          />
        )}
      </>
    );
  }
};

export default QuotasGlobalFormActions;
