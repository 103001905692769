import uuid from "uuid";
import { InArray, InModale } from "../../form/utils";
import { CallbackSetter } from "../../utils/types";

export function addOrModifyArray<T>(
  setter: CallbackSetter<InArray<T>[]>,
  currentId: string | null,
  newValues: InModale<T>,
  id?: string
): void {
  setter(elderValues => {
    if (currentId === null) {
      return elderValues.concat({
        data: { ...newValues, id: id === undefined ? uuid() : id },
        errors: {},
      });
    }
    return elderValues.map(singleElderValue => {
      if (singleElderValue.data.id === currentId) {
        singleElderValue.data = { ...newValues, id: currentId };
        singleElderValue.errors = {};
      }
      return singleElderValue;
    });
  });
}

export function moveAndDeleteEmission<ElderType, NewerType>(
  setterTo: CallbackSetter<InArray<NewerType>[]>,
  setterFrom: CallbackSetter<InArray<ElderType>[]>,
  elderId: string,
  newValues: InModale<NewerType>
): void {
  setterTo(elderEmissions => {
    return elderEmissions.concat({
      data: { ...newValues, id: uuid() },
      errors: {},
    });
  });
  setterFrom(elderEmissions => {
    return elderEmissions.filter(singleElderEmission => {
      return singleElderEmission.data.id !== elderId;
    });
  });
}
