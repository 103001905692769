import { WasteTabData } from "../types";
import {
  DechetProduitDto23Now,
  DechetRecuDto23Now,
  ProductionDechetsDto23Now,
  ReceptionDechetsDto23Now,
} from "api/gen";
import {
  ProductedWasteFormData,
  ProductedWasteStorage,
} from "../sortant/production/types";
import {
  ReceivedWasteFormData,
  ReceivedWasteStorage,
} from "../entrant/reception/types";

/////////
//  patch API methods
/////////
//  helpers
const createWasteProductionDechetDto: (
  wastes: ProductedWasteStorage[]
) => DechetProduitDto23Now[] = (wastes: ProductedWasteStorage[]) => {
  return wastes.map(ele => {
    return ele.data;
  });
};
const createWasteReceptionDechetDto: (
  wastes: ReceivedWasteStorage[]
) => DechetRecuDto23Now[] = (wastes: ReceivedWasteStorage[]) => {
  return wastes.map(ele => {
    return ele.data;
  });
};
//  main methods
export const createWasteProductionDto = (
  values: WasteTabData<ProductedWasteFormData, ProductedWasteStorage>
): ProductionDechetsDto23Now => {
  const newDto: ProductionDechetsDto23Now = {
    productionDechetDangereuxSup2000: values.formInfo.dangerousWasteOver2000kg,
    productionDechetNonDangereuxSup2000:
      values.formInfo.atLeastOneFacilityWasteOver2Mkg,
    productionDechet: createWasteProductionDechetDto(values.container),
  };
  return newDto;
};
export const createWasteReceptionDto = (
  values: WasteTabData<ReceivedWasteFormData, ReceivedWasteStorage>
): ReceptionDechetsDto23Now => {
  const newDto: ReceptionDechetsDto23Now = {
    aCasiersAmiante: values.formInfo.doesFacilityHaveAsbestosCases,
    aCasiersPlatre: values.formInfo.doesFacilityHaveCasiersPlatre,
    capaciteRestante: values.formInfo.remainingCapacity,
    receptionDechets: createWasteReceptionDechetDto(values.container),
  };
  return newDto;
};
