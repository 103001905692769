import React from "react";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import DeclarationGlobalSubDashboard from "./Components/SubDashboard/DeclarationGlobalSubDashboard";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../../api/gen";
import { useUserData } from "../../../../../Authenticator/UserData";
import { useDeclaration21Now } from "../../toNow/versionedElements/declarationHooks21Now";
import DeclarationFirstLineButtons from "../../toNow/Actions/DeclarationFirstLineButtons";
import TypeActiviteSubDashboard from "./Components/SubDashboard/TypeActiviteSubDashboard";
import DeclarationQuotasSubDashboard from "./Components/SubDashboard/DeclarationQuotasSubDashboard";

const isSectionQuotasAvailableForUser = (
  isLecteur: boolean,
  isLecteurOnSection: (section: LecteurDroitDtoAllowedSectionsEnum) => boolean
) => {
  if (!isLecteur) {
    return true;
  } else {
    return isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);
  }
};
const DashboardGlobal = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Tableau de bord de la déclaration",
  };
  usePageHeaderSetterOnce(headerData);
  const { isLecteur, isLecteurOnSection } = useUserData();
  const declaration = useDeclaration21Now();
  const isQuota = declaration.body.typeActivite.quotas.estSousQuotaCo2;

  return (
    <>
      <DeclarationFirstLineButtons
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
      <TypeActiviteSubDashboard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
      <DeclarationGlobalSubDashboard
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
      />
      {isQuota &&
        isSectionQuotasAvailableForUser(isLecteur, isLecteurOnSection) && (
          <DeclarationQuotasSubDashboard
            declarationAnnee={declarationAnnee}
            declarationEtablissement={declarationEtablissement}
          />
        )}
    </>
  );
};

export default DashboardGlobal;
