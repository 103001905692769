import React, { useMemo } from "react";
import { DeclarationConstraintViolationDto } from "api/gen";
import danger from "icons/danger.svg";
import clear from "icons/clear.svg";
import { makeStyles } from "@material-ui/styles";
import { PINK } from "theme";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "18px 6px 6px 6px",
  },
  imgContainer: {
    width: "10%",
    display: "flex",
    justifyContent: "center",
  },
  img: {
    width: "20px",
    height: "20px",
  },
  textContainer: {
    width: "80%",
    display: "flex",
    justifyContent: "center",
    whiteSpace: "pre-wrap",
  },
  color: {
    color: PINK,
  },
});

export interface ViolationDisplayerProps {
  errors: DeclarationConstraintViolationDto[];
  warnings: DeclarationConstraintViolationDto[];
}

export const ViolationDisplayer = ({
  errors,
  warnings,
}: ViolationDisplayerProps): React.ReactElement => {
  const classes = useStyles();

  const errorNodes = useMemo(
    () =>
      errors.map((error, index) => (
        <div key={`error_${index}`} className={classes.container}>
          <div className={classes.imgContainer}>
            <img
              src={clear}
              alt={"Une erreur à corriger."}
              className={classes.img}
            />
          </div>

          <div className={classes.textContainer}>
            <span className={classes.color}>{error.message}</span>
          </div>
          {error.commentRequired && (
            <CommentButton path={error.path} isAlertOrError={true} />
          )}
        </div>
      )),
    [
      classes.color,
      classes.container,
      classes.img,
      classes.imgContainer,
      classes.textContainer,
      errors,
    ]
  );
  const warningNodes = useMemo(
    () =>
      warnings.map((warning, index) => (
        <div key={`warning_${index}`} className={classes.container}>
          <div className={classes.imgContainer}>
            <img
              src={danger}
              alt={"Une anomalie à commenter ou corriger."}
              className={classes.img}
            />
          </div>
          <div className={classes.textContainer}>
            <span className={classes.color}>{warning.message}</span>
          </div>
          <CommentButton path={warning.path} isAlertOrError={true} />
        </div>
      )),
    [
      classes.color,
      classes.container,
      classes.img,
      classes.imgContainer,
      classes.textContainer,
      warnings,
    ]
  );

  return (
    <>
      {errorNodes}
      {warningNodes}
    </>
  );
};
