import React from "react";
import logoCombustion from "icons/pageLogos/fire.svg";
import MainAirForm from "./MainAirForm";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { useElementAutreUuid } from "pages/CommonSpace/ReferentialsContext";
import {
  ReferenceItemPolluantElementDto,
  ReferencePolluantDefaultUniteItemDto,
} from "api/gen";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const computePolluantDefaultUniteMapFromArray = (
  polluantDefaultUniteList: ReferencePolluantDefaultUniteItemDto[]
): Map<number, ReferencePolluantDefaultUniteItemDto> => {
  const polluantUnitMap = new Map();
  polluantDefaultUniteList.map(
    (polluantDefaultItem: ReferencePolluantDefaultUniteItemDto) =>
      polluantUnitMap.set(
        polluantDefaultItem.restorationId,
        polluantDefaultItem
      )
  );
  return polluantUnitMap;
};

const Combustion = ({ match }: RouteIdProps): React.ReactElement => {
  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      declarationEtablissement
    ),
    {
      label: "Combustion",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoCombustion,
    alt: "une flamme stylisée, en gris",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Combustion / Incinération",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);
  const elementAutreUuid = useElementAutreUuid();

  const computePolluantElementMapFromArray = (
    polluantElementList: ReferenceItemPolluantElementDto[]
  ): Map<number, Map<string, ReferenceItemPolluantElementDto>> => {
    const polluantElementMap = new Map();

    for (const polluantElement of polluantElementList) {
      const polluantRestorationId: number =
        polluantElement.polluantRestorationCode;

      if (polluantElementMap.has(polluantRestorationId)) {
        polluantElementMap
          .get(polluantRestorationId)
          .set(
            polluantElement.referenceItemElementIndexDto.uuid,
            polluantElement
          );
      } else {
        const elementToItem: Map<
          string,
          ReferenceItemPolluantElementDto
        > = new Map([
          [polluantElement.referenceItemElementIndexDto.uuid, polluantElement],
        ]);

        polluantElementMap.set(polluantRestorationId, elementToItem);
      }
    }

    return polluantElementMap;
  };

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_COMBUSTIBLE",
        "REFERENCE_POLLUANTS_AIR",
        "REFERENCE_CO2",
        "REFERENCE_SOX",
        "REFERENCE_NOX",
        "REFERENCE_POLLUANT_ELEMENT",
        "REFERENCE_POLLUANT_DEFAULT_UNITE",
      ]}
      render={referentials => {
        return (
          <MainAirForm
            referentialCombustible={referentials.REFERENCE_COMBUSTIBLE}
            referentialAir={referentials.REFERENCE_POLLUANTS_AIR}
            referentialSinglePolluants={{
              CO2: referentials.REFERENCE_CO2,
              SOX: referentials.REFERENCE_SOX,
              NOX: referentials.REFERENCE_NOX,
            }}
            polluantElementMap={computePolluantElementMapFromArray(
              referentials.REFERENCE_POLLUANT_ELEMENT
                .referenceItemPolluantElementDtoList
            )}
            referentialElementAutreUuid={elementAutreUuid}
            polluantDefaultUniteMap={computePolluantDefaultUniteMapFromArray(
              referentials.REFERENCE_POLLUANT_DEFAULT_UNITE
                .polluantConcentrationUniteDtoList
            )}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(Combustion, "GLOBAL");
