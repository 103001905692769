import React from "react";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_GREY } from "theme";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import CommonEntityTable from "common/presentational/CommonEntityTable";

const useStyles = makeStyles({
  full: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: SECTION_TITLE_GREY,
    margin: "20px 0",
  },
  darrayContainer: {
    width: "80%",
  },
});

interface PrecisionMethodeAlternativeProps {
  descriptions: string[];
}

const PrecisionMethodeAlternative = ({
  descriptions,
}: PrecisionMethodeAlternativeProps): React.ReactElement => {
  const classes = useStyles();

  const descriptionLines = descriptions.map(description => [
    computeWrappedCell(description, classes),
  ]);

  return (
    <>
      <h6 className={classes.title}>
        Précision relative à la méthode alternative
      </h6>

      <CommonEntityTable
        header={[<p>Description de la méthode alternative utilisée</p>]}
        lines={descriptionLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[1]}
        additionalClassName={classes.darrayContainer}
      />
    </>
  );
};

export default PrecisionMethodeAlternative;
