import {
  ReferenceItemPaysDto,
  ReferencePaysDto,
  ReferenceSubstanceCarriereDto,
} from "api/gen";
import {
  ExtractiveActivityForm,
  PopulatedSubstanceExtractionInArray,
} from "../BlocActiviteExtractive/utils/types";
import { convertDisplayedToSubtanceExtractionDto } from "../BlocActiviteExtractive/utils/converters";
import {
  PopulatedRecyclingProductInArray,
  RecyclingActivityForm,
} from "../BlocActiviteRecyclage/utils/types";
import { convertDisplayedToProduitRecyclageDto } from "../BlocActiviteRecyclage/utils/converters";
import {
  FactoryInArray,
  TransformationInformationsForm,
} from "../BlocInformationsTransformation/utils/types";
import { convertDisplayedToActiviteTransformationDto } from "../BlocInformationsTransformation/utils/converters";
import {
  PopulatedTransportProductInArray,
  TransportProductsArrayForm,
} from "../BlocTransportProduits/utils/types";
import {
  convertDisplayedToModaliteTransportDto,
  convertDisplayedToProduitTransportDto,
} from "../BlocTransportProduits/utils/converters";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import _ from "lodash";
import { Declaration1919 } from "../../../versionedElements/declarationHooks1919";

export const convertStringCountryToModalOptionProps = (
  countryLabel: string,
  referential: ReferencePaysDto
): OptionPropsWithObject<ReferenceItemPaysDto | null> => {
  const matchCountry = referential
    ? referential.referenceItemPaysDtoList.find(
        country => country.designation === countryLabel
      )
    : null;
  return {
    label: countryLabel,
    value:
      referential && matchCountry
        ? _.findIndex(referential.referenceItemPaysDtoList, matchCountry)
        : -1,
    object: matchCountry || null,
  };
};

export const convertDeclarationDtoUsingExtractiveActivityForm = (
  declaration: Declaration1919,
  values: ExtractiveActivityForm,
  referentialSubstances: ReferenceSubstanceCarriereDto,
  substancesExtractionInArray: PopulatedSubstanceExtractionInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.production.extraction.quantiteRestante =
    values.depositQuantity;
  declaration.body.sections.carriere.production.extraction.quantiteSterilesGeneres =
    values.annualGeneratedQuantity;
  declaration.body.sections.carriere.production.extraction.substances = convertDisplayedToSubtanceExtractionDto(
    substancesExtractionInArray,
    referentialSubstances
  );
  return declaration;
};

export const convertDeclarationDtoUsingRecyclingActivityForm = (
  declaration: Declaration1919,
  values: RecyclingActivityForm,
  recyclingProductsInArray: PopulatedRecyclingProductInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.production.recyclage.doitRemplirTableau =
    values.recyclingActivity;
  if (values.recyclingActivity) {
    declaration.body.sections.carriere.production.recyclage.quantiteEntrante =
      values.recyclingMaterialQuantity;
    declaration.body.sections.carriere.production.recyclage.tableauRecyclage = convertDisplayedToProduitRecyclageDto(
      recyclingProductsInArray
    );
  } else {
    declaration.body.sections.carriere.production.recyclage.quantiteEntrante = 0;
    declaration.body.sections.carriere.production.recyclage.tableauRecyclage = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingTransformationInformationsForm = (
  declaration: Declaration1919,
  values: TransformationInformationsForm,
  factoriesInArray: FactoryInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.production.transformation.doitRemplirTableau =
    values.transformationActivityDone;
  if (values.transformationActivityDone) {
    declaration.body.sections.carriere.production.transformation.activiteTransformations = convertDisplayedToActiviteTransformationDto(
      factoriesInArray
    );
  } else {
    declaration.body.sections.carriere.production.transformation.activiteTransformations = [];
  }
  return declaration;
};

export const convertDeclarationDtoUsingTransportProductsForm = (
  declaration: Declaration1919,
  values: TransportProductsArrayForm,
  transportProductsInArray: PopulatedTransportProductInArray[]
): Declaration1919 => {
  declaration.body.sections.carriere.production.transport.modaliteTransports = convertDisplayedToModaliteTransportDto(
    values.productsTransport
  );
  declaration.body.sections.carriere.production.transport.tableauDestinations = convertDisplayedToProduitTransportDto(
    transportProductsInArray
  );
  return declaration;
};
