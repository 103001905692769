import { makeStyles } from "@material-ui/styles";
import { FileSectionEnum } from "common/FilesLoader/types";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useAlertModale } from "common/modale/hooks";
import React from "react";
import { FILE_BUTTONS_STYLE } from "theme";
import { WorkflowTarget } from "common/utils/types";
import { VerificationFileDto, verificationsDtoToFileDto } from "./utils";
import {
  useAllocationsRapportVerificationFileHandler2020,
  useEmissionsRapportVerificationFileHandler2020,
} from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";

const useStyles = makeStyles({
  ...FILE_BUTTONS_STYLE,
});

interface RapportVerificationLoaderProps {
  installationId: string;
  shouldDisableFields: boolean;
  workflowTarget: WorkflowTarget;
}

const RapportVerificationLoader = ({
  installationId,
  shouldDisableFields,
  workflowTarget,
}: RapportVerificationLoaderProps): React.ReactElement => {
  const classes = useStyles();

  const declaration = useDeclaration2020();
  const openAlertModale = useAlertModale();

  const allocationsRapportFileHandler = useAllocationsRapportVerificationFileHandler2020(
    installationId
  );
  const emissionsRapportFileHandler = useEmissionsRapportVerificationFileHandler2020(
    installationId
  );

  const sectionFileHandler =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? emissionsRapportFileHandler
      : allocationsRapportFileHandler;

  const filesPath =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? "body.sections.quotas.emissions.blocVerification.verifications"
      : "body.sections.quotas.allocations.blocVerification.verifications";

  const filesSection =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? FileSectionEnum.QUOTAS_RAPPORT_VERIFICATION_EMISSIONS
      : FileSectionEnum.QUOTAS_RAPPORT_VERIFICATION_ALLOCATIONS;

  const text =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? "EMISSIONS"
      : "NIVEAUX D'ACTIVITE";

  return (
    <FilesLoader
      declaration={declaration}
      text={`DÉPOSER RAPPORT DE VÉRIFICATION - ${text}`}
      filesPath={filesPath}
      section={filesSection}
      fileHandlers={sectionFileHandler}
      isDisabled={shouldDisableFields}
      max={1}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(verifications: Record<string, unknown>[]) =>
        verificationsDtoToFileDto(
          installationId,
          (verifications as unknown) as VerificationFileDto[]
        )
      }
      additionalClassnameButton={classes.fileButtons}
    />
  );
};

export default RapportVerificationLoader;
