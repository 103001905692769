import React, { useState, useMemo } from "react";
import * as Yup from "yup";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import {
  ExtractiveActivityForm,
  PopulatedSubstanceExtractionInArray,
} from "./utils/types";
import { commonPositiveNumberFields } from "common/declarant/formik/formikHelper";
import { makeStyles } from "@material-ui/styles";
import {
  useNumberFieldGenerator,
  useDummyNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Button from "common/button";
import SubstanceExtractionArray from "./SubstanceExtractionArray";
import SubstanceExtractionModal from "./SubstanceExtractionModal";
import {
  convertSubstanceExtractionDtoToDisplayed,
  convertDisplayedToSubtanceExtractionDto,
} from "./utils/converters";
import {
  computeUniquesSubstances,
  computeSubstanceTotalAnnualQuantity,
  computeTotalAnnualQuantity,
} from "./utils/computers";
import { convertDeclarationDtoUsingExtractiveActivityForm } from "../utils/converters";
import _ from "lodash";
import {
  ReferenceSubstanceCarriereDto,
  ReferenceFamilleUsageProductionDto,
} from "api/gen";
import { selectPossibleValues } from "./utils/selectPossibleValues";
import {
  PATH_CARRIERE_PRODUCTION_EXTRACTION,
  PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_RESTANTE,
  PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_STERILES,
} from "common/path/path18Now";
import Row from "common/presentational/Row";
import FormikBlocFullContext2022 from "../../../versionedElements/FormikBlocFullContext2022";
import { useDeclaration2022 } from "../../../versionedElements/declarationHooks2022";

interface BlocActiviteExtractiveProps {
  substancesExtractionInArray: PopulatedSubstanceExtractionInArray[];
  setSubstancesExtractionInArray: (
    substances: PopulatedSubstanceExtractionInArray[]
  ) => void;
  extractiveActivityFormProps: FormikBlocRefAndState<ExtractiveActivityForm>;
  referentialSubstances: ReferenceSubstanceCarriereDto;
  referentialFamilies: ReferenceFamilleUsageProductionDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  buttonModal: {
    padding: "5px",
    marginLeft: "80%",
    marginTop: "20px",
    marginBottom: "20px",
  },
});

const validationSchema = Yup.object().shape({
  depositQuantity: commonPositiveNumberFields,
  annualGeneratedQuantity: commonPositiveNumberFields,
});

const BlocActiviteExtractive = ({
  substancesExtractionInArray,
  setSubstancesExtractionInArray,
  extractiveActivityFormProps,
  referentialSubstances,
  referentialFamilies,
}: BlocActiviteExtractiveProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2022();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "carriere-production-extraction",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [substanceExtractionInModal, setSubstanceExtractionInModal] = useState<
    PopulatedSubstanceExtractionInArray | undefined
  >(undefined);

  const initialValues: ExtractiveActivityForm = {
    depositQuantity:
      declaration.body.sections.carriere.production.extraction.quantiteRestante,
    annualGeneratedQuantity:
      declaration.body.sections.carriere.production.extraction
        .quantiteSterilesGeneres,
  };

  selectPossibleValues.substances = useMemo(
    () =>
      referentialSubstances.referenceItemSubstanceCarriereDtoList
        .filter(substanceDto => substanceDto.actif)
        .map(substanceDto => {
          return {
            value: substanceDto.libelle,
            label: substanceDto.libelle,
            object: substanceDto,
          };
        }),
    [referentialSubstances]
  );

  return (
    <FormikBlocFullContext2022
      hasChanges={extractiveActivityFormProps.hasChanges}
      setHasChanges={extractiveActivityFormProps.setHasChanges}
      formikRef={extractiveActivityFormProps.formikRef}
      title="ACTIVITÉ EXTRACTIVE (TP1)"
      validationSchema={validationSchema}
      initialValues={initialValues}
      pathToValidate={PATH_CARRIERE_PRODUCTION_EXTRACTION}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) =>
        convertDeclarationDtoUsingExtractiveActivityForm(
          declaration,
          values,
          referentialSubstances,
          substancesExtractionInArray
        )
      }
      hasFormChanges={values =>
        values.annualGeneratedQuantity !==
          declaration.body.sections.carriere.production.extraction
            .quantiteSterilesGeneres ||
        values.depositQuantity !==
          declaration.body.sections.carriere.production.extraction
            .quantiteRestante ||
        !_.isEqual(
          convertDisplayedToSubtanceExtractionDto(
            substancesExtractionInArray,
            referentialSubstances
          ),
          declaration.body.sections.carriere.production.extraction.substances
        )
      }
      cancelAction={() => {
        setSubstancesExtractionInArray(
          convertSubstanceExtractionDtoToDisplayed(
            declaration.body.sections.carriere.production.extraction.substances,
            referentialSubstances,
            referentialFamilies
          )
        );
        return initialValues;
      }}
      renderContent={({ values }, shouldDisabledFields: boolean) => (
        <>
          <NumberField
            name="depositQuantity"
            label="Quantité restante et accessible du gisement autorisée par l’AP, au 31 décembre (« réserve restante certaine ») *"
            unit="ktonnes"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_RESTANTE}
          />

          <NumberField
            name="annualGeneratedQuantity"
            label="Quantité annuelle de stériles générée *"
            unit="ktonnes"
            disabled={shouldDisabledFields}
            commentPath={PATH_CARRIERE_PRODUCTION_EXTRACTION_QUANTITE_STERILES}
          />

          <Button
            text="AJOUTER UNE SUBSTANCE"
            additionalClassname={classes.buttonModal}
            onClick={() => setIsModalOpen(true)}
            isDisabled={shouldDisabledFields}
          />

          <SubstanceExtractionArray
            substancesExtractionInArray={substancesExtractionInArray}
            setSubstancesExtractionInArray={setSubstancesExtractionInArray}
            onEdit={substance => {
              setSubstanceExtractionInModal(substance);
              setIsModalOpen(true);
            }}
            isDisabled={shouldDisabledFields}
          />

          <SubstanceExtractionModal
            isOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            substanceExtractionInModal={substanceExtractionInModal}
            setSubstanceExtractionInModal={setSubstanceExtractionInModal}
            substancesExtractionInArray={substancesExtractionInArray}
            setSubstancesExtractionInArray={setSubstancesExtractionInArray}
            referentialFamilies={referentialFamilies}
          />

          {computeUniquesSubstances(substancesExtractionInArray).map(
            (substanceInArray, i) => (
              <DummyNumberField
                key={`total-${i}`}
                name={substanceInArray.displayedSubstance.substance.label}
                label={`Total ${substanceInArray.displayedSubstance.substance.label}`}
                value={computeSubstanceTotalAnnualQuantity(
                  substancesExtractionInArray,
                  substanceInArray
                )}
                unit="ktonnes"
                disabled
              />
            )
          )}

          <Row />

          <DummyNumberField
            name="totalGeneratedAnnualQuantity"
            label="Total dont quantité de stériles générée"
            unit="ktonnes"
            value={computeTotalAnnualQuantity(
              substancesExtractionInArray,
              values.annualGeneratedQuantity === null
                ? 0
                : values.annualGeneratedQuantity
            )}
            disabled
          />
        </>
      )}
    />
  );
};

export default BlocActiviteExtractive;
