import React from "react";
import {
  CampaignDtoStateAllocationsEnum,
  QuotasAllocationsEmailsVerificateursDto20Now,
} from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_QUOTAS_ALC_VERIFIE,
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_ALLOCATIONS_EMAILS,
  PATH_QUOTAS_ALLOCATIONS_PMS,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { ReferentialActiviteQuotasProps } from "../common/BlocRappelTypeActivite/types";
import {
  useBasicDeclarationHandlers2323,
  useDeclaration2323,
  useDeclarationHelpers2323,
  useDeclarationStateHandlers2323,
} from "../../versionedElements/declarationHooks2323";
import QuotasDeclarationOfficeDownload from "../../../../from21/toNow/Quotas/common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import BlocRappelTypeActivite from "../common/BlocRappelTypeActivite";
import QuotasGlobalFormActions from "../../../../from21/toNow/Quotas/QuotasGlobalFormActions";
import BlocInspecteur from "../../../../from21/to23/Quotas/common/BlocInspecteur";
import { updateHandlerEmailsAddressAllocations2323 } from "../../versionedElements/updateHandlers2323";
import BlocAlc from "./BlocAlc";
import BlocEmailsVerificateurs from "./BlocEmailsVerificateurs";
import BlocPlanMethodSurveillance from "./BlocPlanMethodSurveillance";
import Verification from "./Verification";

const NiveauxActiviteForm = ({
  referentialActivites,
}: ReferentialActiviteQuotasProps): React.ReactElement => {
  const declaration = useDeclaration2323();
  const { yieldQuotaAllocationsVerif } = useDeclarationStateHandlers2323();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2323();
  const { update } = useBasicDeclarationHandlers2323();
  const currentCampaign = useCurrentDeclarationCampaign();

  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasAllocationsEmailsVerificateursDto20Now
  >();

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
      />

      <Row />

      <BlocRappelTypeActivite referentialActivites={referentialActivites} />

      <Row height={"25px"} />

      <BlocPlanMethodSurveillance />

      <Row height={"25px"} />

      <BlocAlc />

      <Row height={"25px"} />

      <BlocEmailsVerificateurs
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        formikRef={blocEmailsVerificateursProps.formikRef}
        setHasChanges={blocEmailsVerificateursProps.setHasChanges}
      />
      <QuotasGlobalFormActions
        // TODO [GEREP-2565] use actions buttons instead
        pathToValidate={PATH_QUOTAS_ALLOCATIONS}
        target={WorkflowTarget.QUOTA_ALLOCATIONS}
        quotaState={declaration.body.workflowStatus.quotaAllocations.state}
        yieldQuotaVerif={yieldQuotaAllocationsVerif}
        isSimplifiedQuota={false}
        saveAction={() =>
          update(declaration => {
            declaration = updateHandlerEmailsAddressAllocations2323(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
            return declaration;
          })
        }
        cancelAction={() => {
          blocEmailsVerificateursProps.resetForm();
        }}
        hasChanges={blocEmailsVerificateursProps.hasChanges}
        declarantBlocsValidated={
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_EMAILS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALLOCATIONS_PMS) &&
          isPathValidatedInDeclaration(PATH_QUOTAS_ALC_VERIFIE)
        }
      />
      <Row height={"25px"} />

      <Verification />

      <Row height={"25px"} />

      <BlocInspecteur
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        path={PATH_QUOTAS_ALLOCATIONS}
        currentState={declaration.body.workflowStatus.quotaAllocations.state}
        isCampaignEnded={
          currentCampaign.stateAllocations ===
          CampaignDtoStateAllocationsEnum.ENDED
        }
      />

      <Row height={"25px"} />
    </>
  );
};

export default NiveauxActiviteForm;
