import { FormikErrors } from "formik/dist/types";
import * as Yup from "yup";

type ValidateType<T> = (
  values: T
) => // the object is needed to get the same type than formik, so we need to disable this rule
// eslint-disable-next-line @typescript-eslint/ban-types
void | object | Promise<FormikErrors<T>>;

export const transformValidationSchemaToIgnoreFields = (
  originalValidationSchema: Yup.SchemaOf<Record<string, unknown>>,
  fieldNamesIgnored: ReadonlySet<string>
): Yup.SchemaOf<Record<string, unknown>> => {
  if (originalValidationSchema["_nodes"] !== undefined) {
    // note : no doc link for this method, so it's purely conjecture that INDEED
    // yup uses it to know what it needs to check. On 06/10/2021, it seems to be the case.
    originalValidationSchema["_nodes"] = originalValidationSchema[
      "_nodes"
    ].filter((nodeName: string) => {
      return !fieldNamesIgnored.has(nodeName);
    });
  }

  return originalValidationSchema;
};

const removeErrorForIgnoredFields = <T>(
  fieldNamesIgnored: ReadonlySet<string>,
  errorsComputed: FormikErrors<T>
) => {
  fieldNamesIgnored.forEach(
    // the as is justified as the T of values SHOULD be the only name used in our formik field
    fieldName => delete errorsComputed[fieldName as keyof T]
  );

  return errorsComputed;
};

export const validateIgnoringFields = <T>(
  values: T,
  validateMethod: ValidateType<T> | undefined,
  fieldNamesIgnored: ReadonlySet<string>
): Promise<Record<string, unknown>> | FormikErrors<T> => {
  let baseErrors: // the object is needed to get the same type than formik, so we need to disable this rule
  // eslint-disable-next-line @typescript-eslint/ban-types
  object | Promise<FormikErrors<T>> | undefined | void = {};
  if (validateMethod !== undefined) {
    baseErrors = validateMethod(values);
  }
  if (baseErrors === undefined) {
    baseErrors = {};
  }

  if (baseErrors instanceof Promise) {
    return Promise.resolve(baseErrors).then(
      (errorsComputed: FormikErrors<T>) => {
        return removeErrorForIgnoredFields(fieldNamesIgnored, errorsComputed);
      }
    );
  }

  return removeErrorForIgnoredFields(fieldNamesIgnored, baseErrors);
};
