import { EmissionsInArray } from "../../Emission/utils/types";
import { ProcedeInArray } from "./types";
import { CovInArray } from "../../CovDanger/utils/types";

export function modifyEmissionBasedOnProcede<
  T extends EmissionsInArray | CovInArray
>(content: T[], procedesInPage: ProcedeInArray[]): T[] {
  return content
    .map((singleElderValue: T) => {
      if (singleElderValue.data.procedes) {
        const newProcedeIdList = singleElderValue.data.procedes.filter(
          procedeId =>
            procedesInPage.find(procede => procede.data.id === procedeId) !==
            undefined
        );
        singleElderValue.data.procedes = newProcedeIdList;
      }
      return singleElderValue;
    })
    .filter(
      singleElderValue =>
        singleElderValue.data.procedes !== null &&
        singleElderValue.data.procedes.length !== 0
    );
}
