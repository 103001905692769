import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/styles";
import { useUserData } from "Authenticator/UserData";
import Button from "common/button";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { FileSectionEnum } from "common/FilesLoader/types";
import Row from "common/presentational/Row";
import { WorkflowTarget } from "common/utils/types";
import { useOneFolderFilesExport } from "pages/CompanySpace/DeclarationApiContext/utils/filesHooks";
import React from "react";
import { SUBTITLE_STYLE } from "theme";
import { useDeclaration21Now } from "../../../versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  button: {
    padding: "5px",
    justifyContent: "center",
  },
  subTitle: SUBTITLE_STYLE,
});

interface QuotasDeclarationOfficeDownloadProps {
  workflowTarget: WorkflowTarget;
}

const QuotasDeclarationOfficeDownload = ({
  workflowTarget,
}: QuotasDeclarationOfficeDownloadProps): React.ReactElement | null => {
  const classes = useStyles();

  const declaration = useDeclaration21Now();
  const userData = useUserData();
  const [isDownloadingVisible, triggerDownloading] = useSpinnerState<boolean>();
  const exportFolderFiles = useOneFolderFilesExport();

  const nbFilesToDownload =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? declaration.body.sections.quotas.emissions.blocInspecteur.documents
          .length
      : declaration.body.sections.quotas.allocations.blocInspecteur.documents
          .length;

  const folderSection =
    workflowTarget === WorkflowTarget.QUOTA_EMISSIONS
      ? FileSectionEnum.QUOTAS_EMISSIONS_RAPPORT_DECLARATION_D_OFFICE
      : FileSectionEnum.QUOTAS_ALLOCATION_RAPPORT_DECLARATION_D_OFFICE;

  if (!userData.isDeclarant || nbFilesToDownload === 0) {
    return null;
  }
  return (
    <>
      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>RAPPORT DE DÉCLARATION D'OFFICE</h3>
      </Row>
      {`L'inspecteur a déposé ${
        nbFilesToDownload === 1 ? "un" : "des"
      } rapport${nbFilesToDownload === 1 ? "" : "s"} de déclaration d'office.`}
      <Row additionalStyle={{ justifyContent: "center" }}>
        <Button
          text={
            <>
              {`TÉLÉCHARGER LE${nbFilesToDownload === 1 ? "" : "S"}
 RAPPORT${nbFilesToDownload === 1 ? "" : "S"} DE DÉCLARATION D'OFFICE`}
              &nbsp;
              <FontAwesomeIcon icon="download" />
            </>
          }
          isReversed
          onClick={() =>
            triggerDownloading(
              exportFolderFiles(
                declaration.annee,
                declaration.etablissementCode,
                folderSection
              )
            )
          }
          additionalClassname={classes.button}
          isSpinnerVisible={isDownloadingVisible}
        />
      </Row>
    </>
  );
};

export default QuotasDeclarationOfficeDownload;
