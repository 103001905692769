import { BlocPrelevementEauOptions, LieuEauDeMerEnum } from "./types";
import {
  ReferenceItemSandreDto,
  ReferenceItemSandreDtoTypeEnum,
  ReferenceSandreDto,
} from "api/gen";

const arraySurfaceWaterTakingLocation = (
  referential: ReferenceSandreDto
): ReferenceItemSandreDto[] => {
  return referential.referenceItemSandreDtoList.filter(
    itemSandre =>
      itemSandre.type !== ReferenceItemSandreDtoTypeEnum.EAU_SOUTERRAINE
  );
};

const arrayUndergroundWaterTakingLocation = (
  referential: ReferenceSandreDto
): ReferenceItemSandreDto[] => {
  return referential.referenceItemSandreDtoList.filter(
    itemSandre =>
      itemSandre.type === ReferenceItemSandreDtoTypeEnum.EAU_SOUTERRAINE
  );
};

export const arraySeaWaterTakingLocationLabel: {
  [key in LieuEauDeMerEnum]: string;
} = {
  MER_DU_NORD: "Mer du Nord",
  MANCHE: "Manche",
  OCEAN_ATLANTIQUE: "Océan Atlantique",
  MER_MEDITERRANEE: "Mer Méditerranée",
  MER_CARAIBES: "Mer des Caraïbes",
  OCEAN_INDIEN: "Océan Indien",
};

const arraySeaWaterTakingLocation: LieuEauDeMerEnum[] = Object.keys(
  arraySeaWaterTakingLocationLabel
) as LieuEauDeMerEnum[];

export const selectPossibleMethods = (
  referentialSandres: ReferenceSandreDto
): BlocPrelevementEauOptions => {
  return {
    surface: arraySurfaceWaterTakingLocation(referentialSandres),
    underground: arrayUndergroundWaterTakingLocation(referentialSandres),
    sea: arraySeaWaterTakingLocation,
  };
};
