import * as Yup from "yup";
import { declarantFieldMatcher, Declarants, DeclarantType } from "./types";
import {
  commonStringFields,
  commonStringFieldsNoPipeAllowed,
} from "common/declarant/formik/formikHelper";
import {
  duplicateDeclarantMessage,
  emailMessage,
} from "common/declarant/formik/formikMessages";
import { Errors } from "common/form/utils";

const declarantObjectSchema: Yup.SchemaOf<Omit<
  DeclarantType,
  "id"
>> = Yup.object().shape({
  nom: commonStringFields,
  prenom: commonStringFields,
  email: commonStringFieldsNoPipeAllowed.email(emailMessage),
  telephone: commonStringFields,
  role: commonStringFields,
});

export const validationSchema = Yup.object().shape({
  declarants: Yup.array().of(declarantObjectSchema),
});

export const validate = (values: Declarants): Errors<Declarants> => {
  const errors: Record<string, string> = {};
  const existingDeclarants: DeclarantType[] = [];
  values.declarants.forEach((element, index) => {
    if (
      existingDeclarants.some(
        existingElem =>
          existingElem.prenom === element.prenom &&
          existingElem.nom === element.nom &&
          existingElem.email === element.email
      )
    ) {
      errors[
        `declarants.${index}.${declarantFieldMatcher.lastName}`
      ] = duplicateDeclarantMessage;
    } else {
      existingDeclarants.push(element);
    }
  });
  return errors;
};
