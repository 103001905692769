import React, { ReactNode, useState } from "react";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  width: {
    width: "30%",
  },
});

interface ModaleDisplayerProps {
  node: ReactNode;
  isClosingExplicitOnly: boolean;
}

export const GlobalModaleProviderContext = React.createContext<
  ((newComponent: ModaleDisplayerProps | null) => void) | null
>(null);

interface GlobalModaleProviderProps {
  children: React.ReactElement;
}

export const GlobalModaleProvider = ({
  children,
}: GlobalModaleProviderProps) => {
  const classes = useStyles();
  const [component, setComponent] = useState<ModaleDisplayerProps | null>(null);

  return (
    <>
      {component && (
        <CustomModal
          className={classes.width}
          isOpen={true}
          onRequestClose={() => {
            if (!component.isClosingExplicitOnly) {
              setComponent(null);
            }
          }}
        >
          {component.node}
        </CustomModal>
      )}

      <GlobalModaleProviderContext.Provider value={setComponent}>
        {children}
      </GlobalModaleProviderContext.Provider>
    </>
  );
};
