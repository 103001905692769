import React, { useContext, useState } from "react";
import {
  CommentContextInterface,
  CommentModalControlProps,
  CommentStoreProps,
  CommentValues,
} from "./commentTypes";
import {
  computeCommentsListForModale,
  isPathInDeclaration,
} from "common/comment/commentUtils";
import CommentModal from "./CommentModal";
import {
  useConcreteDeclarationComments,
  useDeclarationForComments,
} from "./commentHooks";
import { useUserData } from "../../../Authenticator/UserData";
import { options } from "../../../common/comment/SingleComment/util";
import { isCommentDeletable } from "./utils";

const CommentStoreContext = React.createContext<CommentContextInterface | null>(
  null
);

export const useComments = (): CommentContextInterface => {
  const commentsContext = useContext(CommentStoreContext);
  if (commentsContext !== null) {
    return commentsContext;
  } else {
    throw new Error(
      "commentsContext Error. You probably forgot to put a Provider"
    );
  }
};

const CommentStore = ({ children }: CommentStoreProps): React.ReactElement => {
  const declaration = useDeclarationForComments();
  const {
    comments,
    deleteComment,
    postComment,
  } = useConcreteDeclarationComments();
  const userData = useUserData();

  const [modalControl, setModalControl] = useState<CommentModalControlProps>({
    isOpen: false,
    commentPath: null,
    isAlertOrError: false,
  });

  const convertedComments = comments.map(oneComment => {
    const returnedResult: CommentValues = {
      comment: oneComment.value,
      userId: oneComment.user,
      date: new Date(oneComment.creationDate), // TODO: deal with format / deal with timezone
      path: oneComment.path,
      userName: oneComment.userName,
      userFamilyName: oneComment.userFamilyName,
      userDroit: oneComment.userDroit,
      isAlertOrError: oneComment.alert,
      isDeletable: isCommentDeletable(userData, declaration, oneComment),
    };
    return returnedResult;
  });

  const contextInterface: CommentContextInterface = {
    comments: convertedComments,
    openModal: (path: string, alert: boolean) => {
      setModalControl({
        isOpen: true,
        commentPath: path,
        isAlertOrError: alert,
      });
    },
    closeModal: () => {
      setModalControl({
        isOpen: false,
        commentPath: null,
        isAlertOrError: false,
      });
    },
  };
  return (
    <CommentStoreContext.Provider value={contextInterface}>
      {children}

      {modalControl.isOpen && (
        <CommentModal
          comments={computeCommentsListForModale(
            convertedComments,
            modalControl.commentPath
          )}
          isWarning={modalControl.isAlertOrError}
          isReadOnly={userData.isLecteur}
          close={() => {
            setModalControl({
              isOpen: false,
              commentPath: null,
              isAlertOrError: false,
            });
          }}
          postComment={comment => {
            if (comment.trim() !== "" && modalControl.commentPath) {
              const isDynamicPath = !isPathInDeclaration(
                declaration,
                modalControl.commentPath
              );
              postComment(
                modalControl.commentPath,
                comment,
                isDynamicPath,
                modalControl.isAlertOrError
              );
              setModalControl({
                isOpen: false,
                commentPath: null,
                isAlertOrError: false,
              });
            }
          }}
          deleteComment={comment => {
            deleteComment(
              comment.path,
              comment.userId,
              Intl.DateTimeFormat("fr-FR", {
                ...options,
                year: "numeric",
                second: "2-digit",
              }).format(comment.date)
            );
            setModalControl({
              isOpen: false,
              commentPath: null,
              isAlertOrError: false,
            });
          }}
        />
      )}
    </CommentStoreContext.Provider>
  );
};

export default CommentStore;
