import React from "react";

export const TakeOverModalTitle = (
  <div>
    Afin de discriminer les déclarations d'ordre déclaratives (correction par
    l'inspecteur ou l'administrateur de la déclaration du déclarant) et d'ordre
    estimatives (déclaration d'office par l'inspecteur au sens de l'article 70
    du règlement MRR ou de l'article 3 du règlement ALC), veuillez respecter les
    consignes suivantes :
  </div>
);

export const TakeOverModalContent = (
  <ul>
    <li>
      <b>Dans le cas d'une correction :</b> veuillez ne pas déposer de rapport
      de déclaration d'office. Après avoir procédé aux modifications, vous
      pouvez valider la déclaration ou repasser la main à l'exploitant.
    </li>
    <br />
    <li>
      Dans le cas d'une déclaration d'office au sens de l'article 70 du
      règlement MRR ou de l'article 3 du règlement ALC : veuillez impérativement
      déposer un rapport de déclaration d'office (voir modèle sur icar, et
      fourni par le BQA) et ne pas valider la déclaration, qui peut rester au
      statut "déclaration d'office" jusqu'à la fin de la campagne. Il n'est pas
      obligatoire que les champs de la déclaration soient remplis.
    </li>
  </ul>
);
