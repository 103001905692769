import React, { ReactElement } from "react";
import Button from "common/button";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDeclarationExcelExport } from "pages/CompanySpace/DeclarationApiContext/utils/exportHooks";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useUserData } from "../../../../../Authenticator/UserData";

const ExcelDownloadButton = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const { isLecteur } = useUserData();
  const excelExportDeclaration = useDeclarationExcelExport();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();

  return (
    <Button
      text={
        <>
          <FontAwesomeIcon icon="download" /> Télécharger la déclaration Excel
        </>
      }
      onClick={() =>
        triggerSpinner(
          excelExportDeclaration(
            Number(declarationAnnee),
            declarationEtablissement
          )
        )
      }
      isReversed
      isSpinnerVisible={isSpinnerVisible}
      isDisabled={isLecteur}
    />
  );
};

export default ExcelDownloadButton;
