import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  QuotasInfosComplementairesInstallationsDto2021,
  QuotasInfosComplementairesInstallationsDto2021CategorieEnum,
  QuotasInstallationDto20Now,
} from "api/gen";
import Row from "common/presentational/Row";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import {
  infosComplementairesInstallationsLabels,
  infosComplementairesInstallationsOptions,
} from "./selectPossibleValues";
import { infosComplementaireFieldMatcher } from "./types";
import classNames from "classnames";

const useStyles = makeStyles({
  field: {
    width: "100%",
    justifyContent: "center",
    textAlign: "center",
    alignSelf: "center",
  },
  additionalRowStyle: {
    justifyContent: "space-around",
    marginBottom: "30px",
  },
  centerChildField: {
    "&>div>div>div": {
      justifyContent: "center",
    },
  },
});

interface InfosComplementairesFormProps {
  installation: QuotasInstallationDto20Now;
  index: number;
  namePrefix: string;
  infosInstallationList: QuotasInfosComplementairesInstallationsDto2021[];
  shouldDisableFields: boolean;
}

const InfosComplementairesForm = ({
  installation,
  index,
  namePrefix,
  infosInstallationList,
  shouldDisableFields,
}: InfosComplementairesFormProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.field,
    labelWidth: "10%",
    formPrefix: "infos-complementaires",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const infosComplementaireInstallation = infosInstallationList.find(
    infos => infos.nimDynId === installation.id
  ) || {
    nimDynId: installation.id,
    categorie: null,
    faiblementEmettrice: false,
  };

  return (
    <Row
      key={`${index}-infos-comp-installations`}
      additionalClassname={classes.additionalRowStyle}
    >
      <div className={classes.field}>
        <span className={classes.field}>{installation.nim}</span>
      </div>
      <div className={classes.field}>
        <WrappedChoiceSelect
          label=""
          name={`${namePrefix}.${infosComplementaireFieldMatcher.categorie}`}
          isMulti={false}
          options={infosComplementairesInstallationsOptions}
          computeLabel={info => infosComplementairesInstallationsLabels[info]}
          additionalClassName={classes.field}
          disabled={shouldDisableFields}
          commonProps={commonProps}
        />
      </div>
      <div className={classNames(classes.centerChildField, classes.field)}>
        {infosComplementaireInstallation.categorie ===
          QuotasInfosComplementairesInstallationsDto2021CategorieEnum.A && (
          <BooleanField
            label=""
            name={`${namePrefix}.${infosComplementaireFieldMatcher.faiblementEmettrice}`}
            disabled={shouldDisableFields}
            hideLabelComponentIfEmptyText={true}
          />
        )}
      </div>
    </Row>
  );
};

export default InfosComplementairesForm;
