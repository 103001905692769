import {
  DeclarationBodyDto24Now,
  DeclarationDto24Now,
  DeclarationSectionsDto24Now,
  QuotasAllocationsBlocVerificationDto20Now,
  QuotasBlocVerificationEmissionsDto20Now,
  TrackDechetControllerApi,
} from "api/gen";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationStateHandlers,
  useQuotasState,
  useUpdateDeclarationTypeActivite,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  BasicDeclarationHandlers,
  DeclarationState,
  DeclarationStateHandlers18Now,
} from "../../../DeclarationApiContext/utils/types";
import {
  QuotaWorkflowTarget,
  WorkflowTarget,
} from "../../../../../common/utils/types";
import {
  Declaration21Now,
  TypeActiviteGlobal21Now,
} from "../../../from21/toNow/versionedElements/declarationHooks21Now";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import { useCallback, useContext } from "react";
import { useAuthenticatedApi } from "../../../../../Authenticator/AuthenticatedApi";
import { DeclarationApiContext } from "../../../DeclarationApiContext/DeclarationApiContext";
import { useAlertModale } from "../../../../../common/modale/hooks";
import { backAlertMessage } from "../../../../../common/backErrors/utils";
import {
  backMessageApproveDeclaration,
  backMessageGeneral,
  backMessageInseeError,
  backMessageOnResetDeclaration,
  backMessageOnStartDeclaration,
  backMessageOnUpdateTypeActivite,
  backMessageOnYieldQuotas,
  backMessageSetVerification,
  backMessageTDError,
  backSubmitSimplifiedQuotas,
  HTTP_UNAVAILABLE_FOR_LEGAL_REASONS,
} from "../../../../../common/backErrors/errorMessages";
import { throwIfWrongYear } from "../../../DeclarationApiContext/utils/utils";
import InseeIllegalError from "../GeneralInformations/CompanyActivity/InseeIllegalError";
import {
  MIN_YEAR_TD,
  TypeActiviteQuota23Now,
} from "../../../from23/toNow/versionedElements/declarationHooks23Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2024;
export const MAX_YEAR = undefined;

// Define all the DTO types for this version.
export type Declaration24Now = DeclarationDto24Now;
export type DeclarationBody24Now = DeclarationBodyDto24Now;
export type DeclarationSections24Now = DeclarationSectionsDto24Now;
export const useDeclaration24Now = (
  shouldThrowIfWrongYear?: boolean
): Declaration24Now => {
  return useDeclaration<Declaration24Now>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

type DeclarationStateHandlers24Now = DeclarationStateHandlers18Now & {
  submitSimplifiedQuota: (target: QuotaWorkflowTarget) => Promise<boolean>;
  approve: (targets: WorkflowTarget[]) => Promise<boolean>;
  yieldQuotaAllocationsVerif(): Promise<boolean>;
  yieldQuotaEmissionsVerif(): Promise<boolean>;
  updateQuotaAllocationsVerif: (
    newQuotaVerif: QuotasAllocationsBlocVerificationDto20Now
  ) => Promise<boolean>;
  updateQuotaEmissionsVerif: (
    newQuotaVerif: QuotasBlocVerificationEmissionsDto20Now
  ) => Promise<boolean>;
  startGlobal: (newActiviteType: TypeActiviteGlobal21Now) => Promise<boolean>;
  startQuota: (newActiviteType: TypeActiviteQuota23Now) => Promise<boolean>;
  resetGlobal: () => Promise<boolean>;
  resetQuota: () => Promise<boolean>;
};
export const useDeclarationHelpers24Now = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers24Now = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration24Now> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration24Now): DeclarationSectionsDto24Now =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration24Now>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};
export const useDeclarationStateHandlers24Now = (): DeclarationStateHandlers24Now => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const approve = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.approveDeclaration20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageApproveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const updateTypeActiviteGlobal = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteGlobal21Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteGlobal21NowUsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateTypeActiviteQuota = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteQuota23Now
    ): Promise<Declaration21Now> => {
      return declarationController.setTypeActiviteQuota23NowUsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  const updateQuotaAllocationsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasAllocationsBlocVerificationDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration21Now = await declarationController.setVerificationAllocations20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const updateQuotaEmissionsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasBlocVerificationEmissionsDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration21Now = await declarationController.setVerificationEmissions20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const submitSimplifiedQuotaHandler = useCallback(
    async (target: QuotaWorkflowTarget): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.submitSimplifiedQuotas21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          [target]
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backSubmitSimplifiedQuotas));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const yieldQuotaAllocationsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_ALLOCATIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const yieldQuotaEmissionsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_EMISSIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const startGlobal = useCallback(
    async (newActiviteType: TypeActiviteGlobal21Now): Promise<boolean> => {
      let returnedResult: Declaration21Now;
      try {
        returnedResult = await updateTypeActiviteGlobal(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        returnedResult = await declarationController.startGlobalDeclaration21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [
      declarationController,
      declarationApi,
      openModale,
      updateTypeActiviteGlobal,
    ]
  );

  const startQuota = useCallback(
    async (newActiviteType: TypeActiviteQuota23Now): Promise<boolean> => {
      let returnedResult: Declaration21Now;
      try {
        returnedResult = await updateTypeActiviteQuota(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          newActiviteType
        );
      } catch (excp) {
        await openModale(
          backAlertMessage(excp, backMessageOnUpdateTypeActivite)
        );
        return false;
      }

      try {
        returnedResult = await declarationController.startQuotaDeclaration21NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        declarationApi.setDeclaration(returnedResult);
        await openModale(backAlertMessage(excp, backMessageOnStartDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale, updateTypeActiviteQuota]
  );

  const resetGlobal = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetGlobalDeclaration21NowUsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const resetQuota = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.resetQuotaDeclaration21NowUsingDELETE(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      // returnedResult being sent by the back, it IS AnyDeclarationDto (a union of all the declarationDto the back may send)
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnResetDeclaration));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const stateHandlers18Now = useDeclarationStateHandlers(MIN_YEAR, MAX_YEAR);

  return {
    ...stateHandlers18Now,
    submitSimplifiedQuota: submitSimplifiedQuotaHandler,
    approve,
    yieldQuotaAllocationsVerif,
    yieldQuotaEmissionsVerif,
    updateQuotaAllocationsVerif,
    updateQuotaEmissionsVerif,
    startGlobal,
    startQuota,
    resetGlobal,
    resetQuota,
  };
};
export const useUpdateDeclarationTypeActiviteQuota24Now = (): ((
  newActiviteType: TypeActiviteQuota23Now
) => Promise<boolean>) => {
  const { declarationController } = useAuthenticatedApi();

  const updateTypeActivite = useCallback(
    (
      annee: number,
      codeEtablissement: string,
      newTypeActivite: TypeActiviteQuota23Now
    ): Promise<Declaration24Now> => {
      return declarationController.setTypeActiviteQuota23NowUsingPOST(
        annee,
        codeEtablissement,
        newTypeActivite
      );
    },
    [declarationController]
  );

  return useUpdateDeclarationTypeActivite<
    TypeActiviteQuota23Now,
    Declaration24Now
  >(updateTypeActivite, MIN_YEAR, MAX_YEAR);
};
export const useQuotaAllocationsState24Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration24Now) =>
      declarationDto.body.workflowStatus.quotaAllocations.state,
    []
  );

  return useQuotasState<Declaration24Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useQuotaEmissionsState24Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration24Now) =>
      declarationDto.body.workflowStatus.quotaEmissions.state,
    []
  );

  return useQuotasState<Declaration24Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useTdApiUpdateWaste = (
  backofficeCallback: (
    tdController: TrackDechetControllerApi,
    annee: number,
    etablissement: string,
    options?: any
  ) => Promise<Declaration24Now>,
  updateHandlerRecu: (declaration: Declaration24Now) => Declaration24Now,
  updateHandlerProduit: (declaration: Declaration24Now) => Declaration24Now
): (() => Promise<boolean>) => {
  const { trackdechetController } = useAuthenticatedApi();

  const openModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }
  const { update } = useBasicDeclarationHandlers24Now(true);
  throwIfWrongYear(declarationApi.declaration, MIN_YEAR_TD, MAX_YEAR);

  return useCallback(async (): Promise<boolean> => {
    try {
      await update(declaration => updateHandlerRecu(declaration));
      await update(declaration => updateHandlerProduit(declaration));
      const returnedResult: Declaration24Now = await backofficeCallback(
        trackdechetController,
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageTDError));
      return false;
    }
  }, [backofficeCallback, trackdechetController, openModale, declarationApi]);
};

export const useUpdateFromInsee = (): ((
  declaration: Declaration24Now,
  siret: string | null
) => Promise<Declaration24Now>) => {
  const { declarationController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  return async (declaration: Declaration24Now, siret: string | null) => {
    if (!declarationApi) {
      throw new Error(
        "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
      );
    }
    if (siret === null) {
      await openModale("Le numéro siret est requis.");
      return declaration;
    }
    try {
      const updatedDeclaration = await declarationController.updateInseeInfoUsingGET(
        declaration.annee,
        declaration.etablissementCode,
        siret
      );
      declarationApi.setDeclaration(updatedDeclaration);
      return updatedDeclaration;
    } catch (except) {
      if (
        except instanceof Response &&
        except.status === HTTP_UNAVAILABLE_FOR_LEGAL_REASONS
      ) {
        await openModale(InseeIllegalError({ siret: siret }));
      } else {
        await openModale(backAlertMessage(except, backMessageInseeError));
      }
    }
    return declaration;
  };
};

export const useAddDeclarationToGunReimport = (): ((
  declaration: Declaration24Now
) => Promise<void>) => {
  const { declarationController } = useAuthenticatedApi();
  const openModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  return async (declaration: Declaration24Now) => {
    if (!declarationApi) {
      throw new Error(
        "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
      );
    }
    try {
      await declarationController.addDeclarationToGunReimportUsingPOST(
        declaration.annee,
        declaration.etablissementCode
      );
      await openModale(
        "La demande a été prise en compte. La déclaration devrait être mise à jour prochainement."
      );
    } catch (except) {
      await openModale(backAlertMessage(except, backMessageGeneral));
    }
  };
};
