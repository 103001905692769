import { useCallback, useEffect, useRef } from "react";
import _ from "lodash";

const temporaryStorageKey = (key: string) => "temporaryStorage." + key;

/**
 * object to manipulate temporaryStorage object in localstorage
 */
export const temporaryStorage = {
  put: (key: string, value: string | null): void => {
    value !== null
      ? window.localStorage.setItem(temporaryStorageKey(key), value)
      : window.localStorage.removeItem(temporaryStorageKey(key));
  },
  get: (key: string): string | null =>
    window.localStorage.getItem(temporaryStorageKey(key)),
  delete: (key: string): void => {
    window.localStorage.removeItem(temporaryStorageKey(key));
  },
};

/**
 * Hook to change or be notified of change of a value in temporaryStorage
 * @param key key to check in temporaryStorage
 * @param parser function to get a proper object from the string in localstorage
 * @param onChange callback when the value changed, even from another tab
 */
export function useTemporaryStorage<T>(
  key: string,
  parser: (stored: string) => T | null,
  onChange?: (newValue: T | null) => void
): [React.MutableRefObject<T | null>, (values: T | null) => void] {
  // Convert string from localstorage to wanted object
  // delete key if it failed
  const safeParser = useCallback(
    (str: string) => {
      try {
        return parser(str);
      } catch (e) {
        temporaryStorage.delete(key);
        return null;
      }
    },
    [parser, key]
  );

  const storedValue = temporaryStorage.get(key);
  const valueRef = useRef<T | null>(
    storedValue !== null ? safeParser(storedValue) : null
  );

  // Update key in localstorage and in ref
  const memoSetValue = useCallback(
    (newValue: T | null) => {
      if (newValue) {
        temporaryStorage.put(key, JSON.stringify(newValue));
      } else {
        temporaryStorage.delete(key);
      }
      valueRef.current = newValue;
    },
    [key, valueRef]
  );

  // to perform onChange callback when value change in localStorage, even when change come from another tab
  const updateValueOnStorageChange = useCallback(() => {
    const stored = temporaryStorage.get(key);
    const newValue = stored ? safeParser(stored) : null;
    if (!_.isEqual(valueRef.current, newValue)) {
      valueRef.current = newValue;
      onChange && onChange(newValue);
    }
    return newValue;
  }, [onChange, key, safeParser]);

  // listeners on localstorage to trigger onChange
  useEffect(() => {
    window.addEventListener("storage", updateValueOnStorageChange);
    return () => {
      window.removeEventListener("storage", updateValueOnStorageChange);
    };
  }, [updateValueOnStorageChange]);

  return [valueRef, memoSetValue];
}
