import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { DeclarantDto, InfoGeneraleDto23Now } from "api/gen";

export type DeclarantType = DeclarantDto;

export type Declarants = Pick<InfoGeneraleDto23Now, "declarants">;

export const declarantFieldMatcher = fieldMatcherBuilder<DeclarantType>().build(
  {
    id: "id",
    lastName: "nom",
    firstname: "prenom",
    email: "email",
    phone: "telephone",
    declarantRoleInEnterprise: "role",
  }
);
