import React from "react";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  DISABLED_BUTTON_HOVER_BACKGROUND,
  LINK_STYLE,
  PINK,
  SECTION_TITLE_GREY,
} from "theme";
import Button from "common/button";

const useStyles = makeStyles({
  title: {
    textAlign: "center",
    color: PINK,
    fontSize: "1.5rem",
    marginBottom: "20px",
  },
  button: {
    marginLeft: "90%",
    marginTop: "50px",
    color: SECTION_TITLE_GREY,
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
    padding: "5px",
  },
  ul: {
    "& li": {
      marginLeft: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
    "&>li>ul": {
      marginTop: "30px",
      "&>li": {
        marginBottom: "20px",
      },
    },
  },
  link: LINK_STYLE,
});

interface ModalGuidesProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ModalGuides = ({
  isOpen,
  onRequestClose,
}: ModalGuidesProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className={classes.title}>
        <h1>Guides</h1>
      </div>
      <ul className={classes.ul}>
        <li>
          Guides GEREP 3
          <ul>
            <li>
              Guide OMINEA :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/Ominea2023.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Document descriptif OMINEA (édition 2023)
                  </a>{" "}
                  Citepa Descriptif de l'organisation du système national
                  d'inventaires des émissions de polluants atmosphériques et des
                  méthodologies utilisées dans les inventaires UNECE, CCNUCC,
                  GIC, SECTEN
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/BDD_Ominea23.xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Base de données OMINEA (édition 2023)
                  </a>{" "}
                  Citepa Document au format xlsx
                </li>
              </ul>
            </li>
            <li>
              Notes d'aide à la déclaration :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideElevageCitepa.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Note d'aide à la déclaration du bloc "Élevage" (janvier
                    2021)
                  </a>{" "}
                  Citepa
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideSolvantsPGSCitepa.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Note d'aide au remplissage du bloc "Solvants / PGS"
                    (décembre 2020)
                  </a>{" "}
                  Citepa
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideGazsFluoresCitepa.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Note d'aide à la déclaration des émissions de gaz fluorés
                    (décembre 2020)
                  </a>{" "}
                  Citepa
                </li>
              </ul>
            </li>
            <li>
              Procédures de remplissage des gabarits déchets :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ProcedureGabaritProductionDechets.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Procédure de remplissage du gabarit production ou expédition
                    de déchets
                  </a>
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ProcedureGabaritReceptionDechets.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Procédure de remplissage du gabarit réception ou traitement
                    de déchets
                  </a>
                </li>
              </ul>
            </li>
            <li>
              Outils de calcul :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/OutilsCarriere.xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Outil "Carrières" (janvier 2020)
                  </a>{" "}
                  Citepa / UNICEM / ATILH
                </li>

                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/OutilConversionEquivalentCarboneCOV.xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Outil d'aide au remplissage des émissions de COV
                  </a>{" "}
                </li>
              </ul>
            </li>
            <li>
              Autres guides :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuidePGS.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide d'élaboration d'un plan de gestion des solvants
                  </a>{" "}
                  INERIS
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Anciens guides GEREP 2
          <ul>
            <li>
              Secteur des élevages de volailles :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideUtilisateurIEDVolailles.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide utilisateur de l'outil d'aide à l'évaluation des
                    émissions à l'air des élevages IED volailles (août 2018)
                  </a>{" "}
                  Citepa / MTE
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ModuleCalculVolaillesV3.8.xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Module de calcul Volailles au format Excel (décembre 2023)
                  </a>{" "}
                  Citepa
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ModuleCalculVolaillesV3.8.ods`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Module de calcul Volailles au format Open Office (décembre
                    2023)
                  </a>{" "}
                  Citepa
                </li>
              </ul>
            </li>
            <li>
              Secteur des élevages de porcs :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideUtilisateurIEDPorcins.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide utilisateur de l'outil d'aide à l'évaluation des
                    émissions à l'air des élevages IED porcins (Décembre 2023)
                  </a>{" "}
                  Citepa / MTE
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ModuleCalculPorcinsV3.10.xlsx`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Module de calcul Porcins au format Excel (janvier 2021)
                  </a>{" "}
                  Citepa
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ModuleCalculPorcinsV3.10.ods`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Module de calcul Porcins au format Open Office (janvier
                    2021)
                  </a>{" "}
                  Citepa
                </li>
              </ul>
            </li>
            <li>
              Secteur des déchets :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideIncinerationDechets.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide d'aide à la déclaration des rejets annuels de
                    polluants dans l'eau, l'air, les déchets et les sols à
                    destination des exploitants d'installation d'incinération de
                    déchets non dangereux et de déchets d'activités de soins à
                    risques infectieux
                  </a>{" "}
                  FNADE / ADEME
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideStockageDechets.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide méthodologique relatif à la déclaration des émissions
                    polluantes des installations de stockage de déchets
                  </a>{" "}
                  FNADE / ADEME
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/OutilCalculADEMEEmissions.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Outil de calcul des émissions dans l'air de CH<sub>4</sub>,
                    CO<sub>2</sub>, SO<sub>x</sub>, NO<sub>x</sub> issues des
                    centres de stockage de déchets ménagers et assimilés
                  </a>{" "}
                  ADEME
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideCompostage.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide d'aide à la déclaration des émissions polluantes
                    issues des installations de compostage
                  </a>{" "}
                  FNADE / ADEME
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/OutilCalculEmissions.xls`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Outil de calcul des émissions polluantes dans l'air des
                    installations de compostage
                  </a>{" "}
                  FNADE / ADEME
                </li>
              </ul>
            </li>
            <li>
              Secteur de l'industrie cimentière :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/IndustrieCiment.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Méthodologie pour la déclaration annuelle des émissions
                    polluantes de l'industrie cimentière en France (2015)
                  </a>{" "}
                  ATILH
                </li>
              </ul>
            </li>
            <li>
              Secteur de l'industrie de la fabrication de la chaux :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/EPER_Chaux.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide sur le mode d'obtention des données annuelles sur les
                    émissions polluantes et des moyens de contrôle de ces
                    émissions dans l'industrie de la fabrication de la chaux
                  </a>{" "}
                  Chambre Syndicale Nationale des Fabricants de Chaux Grasses et
                  Magnésiennes
                </li>
              </ul>
            </li>
            <li>
              Secteur des tuiles et briques :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideTuilesEtBriques.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide méthodologique relatif à la déclaration des émissions
                    polluantes des ICPE pour le secteur des tuiles et briques
                  </a>{" "}
                  Centre Technique Tuiles et Briques
                </li>
              </ul>
            </li>
            <li>
              Secteur de l'industrie de production et de transformation de
              l'acier :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideTransformationAcier.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide méthodologique pour l'évaluation des émissions dans
                    l'air des installations de production et de transformation
                    de l'acier
                  </a>{" "}
                  FFA
                </li>
              </ul>
            </li>
            <li>
              Secteur de l'industrie extractive :
              <ul>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideCarriere.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide méthodologique d'aide à la déclaration annuelle des
                    émissions polluantes et des déchets à l'attention des
                    exploitants de carrières et d'installations de premier
                    traitement de matériaux (décembre 2017)
                  </a>{" "}
                  MTE
                </li>
                <li>
                  <a
                    href={`${process.env.PUBLIC_URL}/download/GuideEAC.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    Guide d'aide à la déclaration de l'enquête annuelle carrière
                    (avril 2018)
                  </a>{" "}
                  MTE / UNICEM / ATILH
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <Button
        text="FERMER"
        onClick={onRequestClose}
        additionalClassname={classes.button}
        isReversed
      />
    </CustomModal>
  );
};

export default ModalGuides;
