import React from "react";
import * as Yup from "yup";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { TechniqueValues } from "./utils";
import { commonObjectFields } from "common/declarant/formik/formikHelper";
import FormikBloc from "common/formikBloc/FormikBloc";
import {
  CREATED,
  generateTransitionWarningMessage,
  STARTED,
} from "common/messages/dashboardMessage";
import { FormikErrors } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  AuthDroitDtoAuthProfileEnum,
  CampaignDtoStateEmissionsEnum,
  DeclarationStateDtoStateEnum,
  ReferenceItemEprtrDto,
} from "api/gen";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { useUserData } from "Authenticator/UserData";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useTechniqueEditHandler,
  useTechniqueSaveHandler,
  useTechniqueValidateHandler,
} from "./hooks";
import { useConfirmationModale } from "common/modale/hooks";
import { computeTechInitialValues } from "./handlers";
import { useCurrentDeclarationCampaign } from "../../CampaignContext";
import { LEFT_WITHDRAW_STYLE } from "theme";
import { useDeclaration1919 } from "../../../CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { useDeclarationErrorAndWarnings1919 } from "pages/CompanySpace/from19/to19/versionedElements/errorAndWarningDisplayer1919/utils";
import { PATH_TYPE_ACTIVITE } from "common/path/path1820";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { ViolationProcessor } from "common/violations/ViolationProcessor";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "20px",
    display: "flex",
  },
  separator: {
    height: "20px",
  },
  containerSpace: {
    padding: "10px 10px 25px 10px",
    fontSize: "17px",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const validationSchema = Yup.object().shape({
  EPRTR: Yup.boolean().nullable(),
  principalEPRTR: Yup.object().when("EPRTR", {
    is: true,
    then: commonObjectFields,
    otherwise: Yup.object().nullable(),
  }),

  quotas: Yup.boolean().nullable(),

  elevage: Yup.boolean().nullable(),
  combustion: Yup.boolean().nullable(),
  incineration: Yup.boolean().nullable(),
  solvant: Yup.boolean().nullable(),
  recepDechet: Yup.boolean().nullable(),
  ISDND: Yup.boolean().nullable(),
  ISDI: Yup.boolean().nullable(),
  torchere: Yup.boolean().nullable(),
  valorisation: Yup.boolean().nullable(),
  export: Yup.boolean().nullable(),
  carriere: Yup.boolean().nullable(),
});

/**
 * @param referentielEPRTE  list of EPRTE referential
 * @param didValidate       if set, alert modal is not triggered and didValidate is called when validation succeed
 *                          if not set, alert modal is triggered and works as default Formikbloc
 */
interface CompanyTechniqueProps extends FormikBlocRefAndState<TechniqueValues> {
  referentielEPRTE: ReferenceItemEprtrDto[];
  didValidate?: () => void;
}

export const CompanyTechnique = (
  props: CompanyTechniqueProps
): React.ReactElement => {
  const classes = useStyles();
  const declarationDto = useDeclaration1919();
  const openConfirmationModale = useConfirmationModale();
  const saveHandler = useTechniqueSaveHandler();
  const validateHandler = useTechniqueValidateHandler();
  const editHandler = useTechniqueEditHandler();
  const currentCampaign = useCurrentDeclarationCampaign();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "70%",
    formPrefix: "company-technique",
  };
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const declarationState = declarationDto.body.workflowStatus.general.state;

  const userProfile: AuthDroitDtoAuthProfileEnum = useUserData().userInfo
    .droits[0].authProfile;

  const areActionsAllowed =
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR ||
    userProfile === AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA ||
    userProfile === AuthDroitDtoAuthProfileEnum.GESTIONNAIRE ||
    userProfile === AuthDroitDtoAuthProfileEnum.SUPER_ADMIN;

  const isTechniqueValidated = declarationState !== CREATED;
  const shouldDisableFields = !areActionsAllowed || isTechniqueValidated;
  // Campaign 2019 only use quota campaign "émissions"
  const shouldDisableQuotas =
    currentCampaign.stateEmissions ===
      CampaignDtoStateEmissionsEnum.ENDED_FOR_DECLARANTS ||
    currentCampaign.stateEmissions === CampaignDtoStateEmissionsEnum.ENDED;
  const [isValidateSpinnerVisible, triggerValidateSpinner] = useSpinnerState<
    void
  >();
  const [isEditSpinnerVisible, triggerEditSpinner] = useSpinnerState<void>();
  const [isSaveSpinnerVisible, triggerSaveSpinner] = useSpinnerState<void>();

  const {
    warnings,
    hasUncommentedWarning,
    errors,
    hasError,
    triggerErrorAndWarningDisplay,
  } = useDeclarationErrorAndWarnings1919(
    PATH_TYPE_ACTIVITE,
    false,
    declarationDto.body.workflowStatus.general.state !==
      DeclarationStateDtoStateEnum.CREATED
  );

  return (
    <FormikBloc
      isValidateSpinnerVisible={isValidateSpinnerVisible}
      isEditSpinnerVisible={isEditSpinnerVisible}
      isSaveSpinnerVisible={isSaveSpinnerVisible}
      actionsAllowed={areActionsAllowed}
      isBlocValidated={isTechniqueValidated}
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      hasComments={false}
      commentAction={() => null}
      areGlobalCommentsAllowed={false}
      saveAction={async (values: TechniqueValues) => {
        await triggerSaveSpinner(saveHandler(values));
      }}
      editAction={async () => {
        openConfirmationModale(
          "Attention, cette action invalidera toutes les sections de la déclaration, êtes vous sûr de vouloir l'effectuer ?",
          async () => {
            await triggerEditSpinner(editHandler());
          }
        );
      }}
      validateAction={async (values: TechniqueValues) => {
        if (props.didValidate) {
          await triggerErrorAndWarningDisplay(
            triggerValidateSpinner(validateHandler(values))
          );
          props.didValidate();
        } else {
          openConfirmationModale(
            generateTransitionWarningMessage(STARTED),
            async () => {
              await triggerErrorAndWarningDisplay(
                triggerValidateSpinner(validateHandler(values))
              );
            }
          );
        }
      }}
      setHasChanges={props.setHasChanges}
      initialValues={computeTechInitialValues(
        declarationDto,
        props.referentielEPRTE
      )}
      validationSchema={validationSchema}
      title={"TYPE D'ACTIVITÉ"}
      additionalFieldValidation={values => {
        const errors: FormikErrors<TechniqueValues> = {};

        if (values.EPRTR && values.principalEPRTR && values.activitesEPRTR) {
          const currentPrincipalEPRTR = values.principalEPRTR;
          if (
            values.activitesEPRTR.find(
              act => act.uuid === currentPrincipalEPRTR.uuid
            )
          ) {
            errors.principalEPRTR = `Vous ne pouvez pas sélectionner ${currentPrincipalEPRTR.nomActivite} comme activité principale et secondaire.`;
          }
        }

        return errors;
      }}
      additionalValidationAllowed={() =>
        (!hasUncommentedWarning && !hasError) || props.hasChanges
      }
      renderContent={formikProps => {
        let filteredActivity;
        if (formikProps.values.EPRTR && formikProps.values.principalEPRTR) {
          const currentPrincipalEPRTR = formikProps.values.principalEPRTR;
          filteredActivity = props.referentielEPRTE.filter(
            act => act.uuid !== currentPrincipalEPRTR.uuid
          );
        } else {
          filteredActivity = props.referentielEPRTE;
        }

        return (
          <>
            <div className={classes.containerSpace}>
              Si vous constatez des erreurs manifestes concernant les types
              d'activités de l'établissement, vous pouvez prendre contact avec
              le service d'inspection dont dépend l'établissement afin que les
              corrections nécessaires soient apportées.
            </div>
            <BooleanField
              label="L'établissement est visé par le règlement 166/2006 (règlement E-PRTR)"
              name="EPRTR"
              disabled={shouldDisableFields}
              tooltipContent={
                <>
                  Établissement comportant au moins une activité visée à
                  l'Annexe I du règlement (CE) N°166/2006 sur la création d'un
                  registre européen des rejets et des transferts de polluants
                  (dit règlement E-PRTR). La majorité des établissements visés
                  par la directive IED sont également visés par le règlement
                  E-PRTR.&nbsp;
                  <a
                    href={`${process.env.PUBLIC_URL}/download/ComparatifIEDE-PRTRv2.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Comparatif IED E-PRTR
                  </a>
                </>
              }
            />
            {formikProps.values.EPRTR && (
              <div className={classes.withdrawLeft}>
                <WrappedChoiceSelect
                  label="Activité principale E-PRTR *"
                  name="principalEPRTR"
                  isMulti={false}
                  options={props.referentielEPRTE}
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  commonProps={commonProps}
                  tooltipLabel
                />
                <WrappedChoiceSelect
                  label="Activités secondaires E-PRTR"
                  name="activitesEPRTR"
                  options={filteredActivity}
                  isMulti
                  disabled={shouldDisableFields}
                  computeLabel={eprtr => eprtr.nomActivite}
                  commonProps={commonProps}
                  tooltipLabel
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est soumis à la directive 2003/87/CE (directive quotas)"
              name="quotas"
              disabled={shouldDisableFields || shouldDisableQuotas}
              tooltipContent={
                "Établissement comportant au moins une installation visée par la directive européenne n°2003/87/CE modifiée (quotas d'émission de gaz à effet de serre)"
              }
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est un établissement d'élevage intensif de volailles ou de porcs (rubrique 3660)"
              name="elevage"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement possède une ou plusieurs installations de combustion d'une puissance supérieure à 20 MW"
              name="combustion20MW"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement réceptionne / traite / stocke des déchets (y compris tri-transit-regroupement, incinération, compostage et méthanisation)"
              name="recepDechet"
              disabled={shouldDisableFields}
              tooltipContent={
                "Ne concerne pas le stockage temporaire, avant collecte, sur le site de production des déchets"
              }
            />
            {formikProps.values.recepDechet && (
              <div className={classes.withdrawLeft}>
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations d'incinération de déchets"
                  name="incinerationDechet"
                  disabled={shouldDisableFields}
                />
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets non dangereux (ISDND)"
                  name="ISDND"
                  disabled={shouldDisableFields}
                />
                {formikProps.values.ISDND && (
                  <div className={classes.withdrawLeft}>
                    <BooleanField
                      label="L'ISDND possède une ou plusieurs torchères de biogaz"
                      name="torchereBiogaz"
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND dispose d'un système de valorisation du biogaz"
                      name="valorisationBiogaz"
                      disabled={shouldDisableFields}
                    />
                    <BooleanField
                      label="L'ISDND exporte du biogaz"
                      name="exportBiogaz"
                      disabled={shouldDisableFields}
                    />
                  </div>
                )}
                <BooleanField
                  label="L'établissement possède une ou plusieurs installations de stockage de déchets inertes (ISDI)"
                  name="ISDI"
                  disabled={shouldDisableFields}
                />
              </div>
            )}

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement consomme des solvants"
              name="solvants"
              disabled={shouldDisableFields}
            />

            <Row additionalClassname={classes.separator} />

            <BooleanField
              label="L'établissement est soumis à enquête annuelle carrière (rubrique 2510-1)"
              name="carriere"
              disabled={shouldDisableFields}
            />

            <ViolationProcessor errors={errors} warnings={warnings} />
          </>
        );
      }}
    />
  );
};
