import React from "react";
import FormSPFO from "./FormSPFO";
import molecule from "icons/pageLogos/molecule.svg";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "../../../../CommonSpace/AppPage/PageHeaderContext";
import { PATH_SPFO } from "common/path/path18Now";
import {
  useDeclaration19Now,
  useDeclarationHelpers19Now,
} from "../versionedElements/declarationHooks19Now";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

const SPFO = ({ match }: RouteIdProps): React.ReactElement => {
  const declaration = useDeclaration19Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers19Now();

  const declarationAnnee = match.params.annee;
  const declarationEtablissement = match.params.etablissement;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      declarationEtablissement
    ),
    {
      label: "SPFO",
      to: match.url,
    },
  ];
  const imageData = {
    src: molecule,
    alt:
      "logo des émissions de polluants dans le sol, avec une pelle dans un tas de terre",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Sulfonates de perfluorooctane",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <FormSPFO
      formSPFODto={declaration.body.sections.spfo}
      isValidated={isPathValidatedInDeclaration(PATH_SPFO)}
    />
  );
};

export default wrapWithWorkflowTargetProvider(SPFO, "GLOBAL");
