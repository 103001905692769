import React from "react";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { makeStyles } from "@material-ui/styles";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { computeEmissionCell } from "./arrayUtils";
import { MethodeCalculCo2 } from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface MethodeCalculCo2ArrayProps {
  methodeCalculCo2DtoList: MethodeCalculCo2[];
}

const MethodeCalculCo2Array = ({
  methodeCalculCo2DtoList,
}: MethodeCalculCo2ArrayProps): React.ReactElement => {
  const classes = useStyles();

  const computeSingleFluxLine = (
    methodeCalculCo2Dto: MethodeCalculCo2,
    classes: ClassesType<"full">
  ): React.ReactElement[] => {
    return [
      computeWrappedCell(methodeCalculCo2Dto.fluxName, classes),
      computeWrappedCell(methodeCalculCo2Dto.methode, classes),
      computeWrappedCell(
        computeEmissionCell(methodeCalculCo2Dto.emissionsFossiles),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeCalculCo2Dto.emissionsBiomasseDurable),
        classes
      ),
      computeWrappedCell(
        computeEmissionCell(methodeCalculCo2Dto.emissionsBiomasseNonDurable),
        classes
      ),
    ];
  };

  const fluxLines = methodeCalculCo2DtoList.map(methodeCalculCo2Dto =>
    computeSingleFluxLine(methodeCalculCo2Dto, classes)
  );
  return (
    <div className={classes.container}>
      <ArrayTitle>
        Méthode de calcul pour la détermination des émissions de CO2 (tonnes)
      </ArrayTitle>

      <CommonEntityTable
        header={[
          <p>Nom du flux</p>,
          <p>Méthodes</p>,
          <p>Émissions fossiles</p>,
          <p>Émissions biomasse durable</p>,
          <p>Émissions biomasse non durable</p>,
        ]}
        lines={fluxLines}
        isFirstColSticky={false}
        isLastColSticky={false}
        preferredColWidths={[200, 200, 200, 200, 200]}
      />
    </div>
  );
};

export default MethodeCalculCo2Array;
