import { commonPositiveNumberFields } from "common/declarant/formik/formikHelper";
import { requiredMessage } from "common/declarant/formik/formikMessages";
import { GasEnum, MethodEnum } from "../types";
import * as Yup from "yup";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
} from "../utils/utils";

export const validationSchema = Yup.object().shape({
  measure: Yup.mixed<MethodEnum>().required(requiredMessage),
  gas: Yup.mixed<GasEnum>().required(requiredMessage),
  fossilEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => isBiomasseSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  biomassEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => isBiomasseSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  otherEmission: Yup.number().when("gas", {
    is: (gas: GasEnum | null) => isOtherEmissionSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
});
