import {
  computeOutsideOrganismOrFunctionalStructureLabel,
  OutsidePreventionOrganismType,
  selectedOrganismOption,
  StructureFonctionnelleData,
  structureFonctionnelleLabel,
} from "./utils";
import { Nullable } from "common/utils/types";
import {
  PreventionDto,
  ReferenceItemOrganismeExterieurPreventionDto,
  ReferenceOrganismeExterieurPreventionDto,
} from "api/gen";
import { cleanComputeRightOptionProps } from "common/form/fields/connectedInput/utils";
import { findElementMatchingTemplate } from "common/utils/methods";

export const overrideUncoherentValuesStructureFonctionnelle = (
  currentValues: Nullable<StructureFonctionnelleData>
): Nullable<StructureFonctionnelleData> => {
  if (
    !selectedOrganismOption(currentValues.outsideOrganismOrFunctionalStructure)
  ) {
    return {
      ...currentValues,
      numberVisitsMadeInYear: null,
      outsidePreventionOrganism: null,
    };
  }

  return currentValues;
};

const getOrganismeExterieurNameById = (
  organismeExterieursReferentiel: ReferenceItemOrganismeExterieurPreventionDto[],
  organismeExterieurId: string
) => {
  const organismeExterieur = findElementMatchingTemplate(
    { uuid: organismeExterieurId },
    organismeExterieursReferentiel
  );
  return organismeExterieur ? organismeExterieur.libelle : null;
};

const getOrganismeExterieurIdByName = (
  organismeExterieursReferentiel: ReferenceItemOrganismeExterieurPreventionDto[],
  organismeExterieurName: string
) => {
  const organismeExterieur = findElementMatchingTemplate(
    { libelle: organismeExterieurName },
    organismeExterieursReferentiel
  );
  return organismeExterieur ? organismeExterieur.uuid : null;
};

export const convertStructureFonctionnelleDataToDeclarationPreventionDto = (
  values: Nullable<StructureFonctionnelleData>,
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto
): PreventionDto => {
  return {
    nbVisites: values.numberVisitsMadeInYear,
    organismeDePreventionID: values.outsidePreventionOrganism
      ? getOrganismeExterieurIdByName(
          referentialOrganismeExterieur.referenceItemOrganismeExterieurPreventionDtoList,
          values.outsidePreventionOrganism.label
        )
      : null,
    structureFonctionnelle:
      values.outsideOrganismOrFunctionalStructure ===
      structureFonctionnelleLabel,
    activiteSaisonniere: !!values.isSeasonalActivity,
  };
};

export const convertDeclarationPreventionDtoToStructureFonctionnelleData = (
  declarationData: PreventionDto,
  externalPreventionOrganismList: OutsidePreventionOrganismType[],
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto
): Nullable<StructureFonctionnelleData> => {
  const outsidePreventionOrganismName = getOrganismeExterieurNameById(
    referentialOrganismeExterieur.referenceItemOrganismeExterieurPreventionDtoList,
    declarationData.organismeDePreventionID || ""
  );
  return {
    numberVisitsMadeInYear: declarationData.nbVisites,
    outsideOrganismOrFunctionalStructure: computeOutsideOrganismOrFunctionalStructureLabel(
      declarationData.structureFonctionnelle
    ),
    outsidePreventionOrganism: cleanComputeRightOptionProps(
      outsidePreventionOrganismName,
      externalPreventionOrganismList
    ),
    isSeasonalActivity: declarationData.activiteSaisonniere,
  };
};
