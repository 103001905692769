import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Nullable } from "common/utils/types";
import {
  isOrganismExterieur,
  organismeExterieurLabel,
  StructureFonctionnelleData,
  structureFonctionnelleFieldMatcher,
  structureFonctionnelleLabel,
} from "./utils";
import {
  useBooleanCheckBoxGenerator,
  useChoiceRadioFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { convertDeclarationPreventionDtoToStructureFonctionnelleData } from "./converters";
import { validationSchema } from "./validation";
import { updateStructureFonctionnelle } from "../utils/updaters";
import {
  PATH_CARRIERE_SANTE_PREVENTION,
  PATH_CARRIERE_SANTE_PREVENTION_ACTIVITE_SAISONNIERE,
  PATH_CARRIERE_SANTE_PREVENTION_NB_VISITES,
  PATH_CARRIERE_SANTE_PREVENTION_ORGANISME_PREVENTION,
  PATH_CARRIERE_SANTE_PREVENTION_STRUCTURE_FONCTIONNELLE,
} from "common/path/path18Now";
import { ReferenceItemOrganismeExterieurPreventionDto } from "api/gen";
import { useDeclaration20Now } from "../../../versionedElements/declarationHooks20Now";
import FormikBlocFullContext20Now from "../../../versionedElements/FormikBlocFullContext20Now";

//to give the form the right direction and spacing between fields and to the left of the fields.
const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

interface StructureFonctionnelleProps
  extends FormikBlocRefAndState<Nullable<StructureFonctionnelleData>> {
  activeOrganismeExterieurPreventionList: ReferenceItemOrganismeExterieurPreventionDto[];
}

const StructureFonctionnelle = ({
  formikRef,
  hasChanges,
  setHasChanges,
  activeOrganismeExterieurPreventionList,
}: StructureFonctionnelleProps): React.ReactElement => {
  const dataDeclaration = useDeclaration20Now();
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "45%",
    formPrefix: "carriere-structure-fonctionnelle",
  };

  const CheckboxField = useBooleanCheckBoxGenerator(commonProps);
  const ChoiceRadioField = useChoiceRadioFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <>
      <FormikBlocFullContext20Now
        formikRef={formikRef}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        initialValues={convertDeclarationPreventionDtoToStructureFonctionnelleData(
          dataDeclaration.body.sections.carriere.sante.prevention,
          activeOrganismeExterieurPreventionList
        )}
        validationSchema={validationSchema}
        title={"Organisation en matière de SST (tss1)"}
        pathToValidate={PATH_CARRIERE_SANTE_PREVENTION}
        areGlobalCommentsAllowed={true}
        updateHandler={(declaration, values) =>
          updateStructureFonctionnelle(
            declaration,
            values,
            activeOrganismeExterieurPreventionList
          )
        }
        enableReinitialize
        renderContent={(props, shouldDisabledFields) => {
          return (
            <>
              <CheckboxField
                label={"Activité saisonnière (si inferieure à 4 mois)"}
                name={structureFonctionnelleFieldMatcher.isSeasonalActivity}
                disabled={shouldDisabledFields}
                commentPath={
                  PATH_CARRIERE_SANTE_PREVENTION_ACTIVITE_SAISONNIERE
                }
              />
              <ChoiceRadioField
                name={
                  structureFonctionnelleFieldMatcher.outsideOrganismOrFunctionalStructure
                }
                label="Organisme extérieur ou structure fonctionnelle"
                disabled={shouldDisabledFields}
                choices={[structureFonctionnelleLabel, organismeExterieurLabel]}
                commentPath={
                  PATH_CARRIERE_SANTE_PREVENTION_STRUCTURE_FONCTIONNELLE
                }
              />
              {isOrganismExterieur(
                props.values.outsideOrganismOrFunctionalStructure
              ) && (
                <>
                  <WrappedChoiceSelect
                    label={"Organisme extérieur de prévention *"}
                    name={
                      structureFonctionnelleFieldMatcher.outsidePreventionOrganism
                    }
                    isMulti={false}
                    options={activeOrganismeExterieurPreventionList}
                    computeLabel={({ libelle }) => libelle}
                    disabled={shouldDisabledFields}
                    commentPath={
                      PATH_CARRIERE_SANTE_PREVENTION_ORGANISME_PREVENTION
                    }
                    commonProps={commonProps}
                  />
                  <NumberField
                    label={"Nombre de visites effectuées dans l'année *"}
                    name={
                      structureFonctionnelleFieldMatcher.numberVisitsMadeInYear
                    }
                    unit=""
                    disabled={shouldDisabledFields}
                    commentPath={PATH_CARRIERE_SANTE_PREVENTION_NB_VISITES}
                  />
                </>
              )}
            </>
          );
        }}
      />
    </>
  );
};
export default StructureFonctionnelle;
