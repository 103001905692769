import React, { useEffect, useState } from "react";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import BlocDatesCampagne from "./BlocDatesCampagne";
import { useActiveOrLastCampaignContext } from "pages/CommonSpace/CampaignContext";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useAlertModale } from "common/modale/hooks";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageReferential } from "common/backErrors/errorMessages";
import toLocalDateString from "common/utils/ToLocalDateString";
import { CampaignDto } from "../../../api/gen";
import Spinner from "../../../common/presentational/Spinner";

export const CampaignsManagement = (): React.ReactElement => {
  const pagePath = [
    {
      label: "Accueil",
      to: "/accueil",
    },
    {
      label: "Campagnes",
      to: "/superadmin/campagnes",
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "gestion des campagnes",
    image: null,
  };
  usePageHeaderSetterOnce(headerData);

  const { campaignController } = useAuthenticatedApi();
  const openModale = useAlertModale();

  const { setter: setActiveOrLastCampaign } = useActiveOrLastCampaignContext();
  const [
    campaignToDisplay,
    setCampaignToDisplay,
  ] = useState<CampaignDto | null>(null);

  useEffect(() => {
    let isUnmounted = false;
    const fetchNext = async () => {
      const next = await campaignController.getAdministrableCampaignUsingGET();
      if (!isUnmounted) {
        setCampaignToDisplay(next);
      }
    };
    fetchNext();
    return () => {
      isUnmounted = true;
    };
  }, [campaignController]);
  const today = toLocalDateString(new Date());

  if (!campaignToDisplay) {
    return <Spinner />;
  }

  return (
    <BlocDatesCampagne
      campaign={campaignToDisplay}
      setCampaign={setCampaignToDisplay}
      minimumDate={today}
      minimumDateName={"date actuelle"}
      onSubmit={async values => {
        try {
          setActiveOrLastCampaign(
            await campaignController.setCampaignDatesUsingPUT(
              campaignToDisplay.annee,
              values.endAllocations,
              values.endAllocationsDeclarants,
              values.endEmissions,
              values.endEmissionsDeclarants,
              values.endGlobal,
              values.endGlobalDeclarants,
              values.start
            )
          );
          return true;
        } catch (excp) {
          openModale(backAlertMessage(excp, backMessageReferential));
          return false;
        }
      }}
    />
  );
};

export default CampaignsManagement;
