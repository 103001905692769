import { useUserData } from "Authenticator/UserData";
import { RouteIdProps } from "common/declarant/type";
import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_ELEVAGE,
  PATH_AIR_GAZ,
  PATH_AIR_ISDND,
  PATH_AIR_PROCEDES,
  PATH_AIR_SOLVANTS,
  PATH_AIR_SYNTHESE,
  PATH_CARRIERE_ENVIRONNEMENT,
  PATH_CARRIERE_PRODUCTION,
  PATH_CARRIERE_SANTE,
  PATH_DASHBOARD,
  PATH_DECHET_PRODUCTION,
  PATH_DECHET_RECEPTION,
  PATH_EAU,
  PATH_INFO_GENERALE,
  PATH_QUOTAS,
  PATH_SOL,
  PATH_SPFO,
} from "common/path/path18Now";
import {
  PATH_QUOTAS_ALLOCATIONS,
  PATH_QUOTAS_EMISSIONS,
} from "common/path/path20Now";
import CampaignDisabled from "pages/CampaignDisabled";
import PublicPortal from "pages/PublicPortal";
import { Redirect, Route, Switch } from "react-router";
import React from "react";
import DashboardGlobal from "../../from24/toNow/Dashboard/DashboardGlobal";
import QuotasNiveauxActivite from "../../from24/toNow/Quotas/NiveauxActivite";
import QuotasEmissions from "../../from24/toNow/Quotas/Emissions";
import { computeDeclarationUrl } from "../../../CommonSpace/AppPage/Redirect/computePath";
import { DeclarationPageHeaderAutoUpdater19Now } from "../../from19/toNow/versionedElements/DeclarationPageHeaderAutoUpdater19Now";
import GazFluores from "../../from19/toNow/Air/GazFluores";
import RedirectDashboard from "../../../CommonSpace/AppPage/Redirect/RedirectDashboard";
import Solvants from "../../from19/toNow/Air/Solvants";
import Isdnd from "../../from19/toNow/Air/ISDND";
import Synthese from "../../from24/toNow/Air/Synthese";
import Breeding from "../../from20/toNow/Air/Breeding";
import WasteDeclarationEntrant from "../../from24/toNow/WasteDeclaration/entrant";
import WasteDeclarationSortant from "../../from24/toNow/WasteDeclaration/sortant";
import WasteDeclarationSynthese from "./WasteDeclaration/syntheseSum";
import { ROUTER_PATH_TYPE_ACTIVITE } from "../../../../common/path/path21Now";
import Sol from "../../from24/toNow/Sol";
import SPFO from "../../from19/toNow/SPFO";
import CarriereSanteSecurite from "../../from20/toNow/Carriere/CarriereSanteSecurite";
import Production from "../../from23/toNow/Carriere/Production";
import Environnement from "../../from20/toNow/Carriere/Environnement";
import { LecteurDroitDtoAllowedSectionsEnum } from "../../../../api/gen";
import GeneralInformations from "./GeneralInformations";
import TypeActivite from "../../from23/toNow/TypeActivite";
import Eau from "../../from24/toNow/Eau";
import { useDeclarationHelpers24Now } from "./versionedElements/declarationHooks24Now";
import Combustion from "./Air/Combustion";
import Procedes from "./Air/Procedes";
import { PATH_DECHET_SYNTHESE } from "common/path/path24Now";
import DashboardQuotas from "./Dashboard/DashboardQuotas";

interface RouterProps {
  route: RouteIdProps;
}

const Router24Now = ({ route }: RouterProps): React.ReactElement => {
  const {
    isDeclarant,
    isInspecteur,
    isAnyPrestataire,
    isLecteurOnSection,
  } = useUserData();
  const { getIsActiveSection } = useDeclarationHelpers24Now();

  // Global access pages -> everyone excepted "prestataire" can access these pages
  const hasGlobalAccess = isDeclarant || isInspecteur;
  // Quotas access pages -> everyone can access these pages
  const hasQuotasAccess =
    isDeclarant ||
    isAnyPrestataire ||
    isInspecteur ||
    isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS);
  const hasCarriereAccessLecteur = isLecteurOnSection(
    LecteurDroitDtoAllowedSectionsEnum.CARRIERE
  );
  const hasEauAccessLecteur = isLecteurOnSection(
    LecteurDroitDtoAllowedSectionsEnum.EAU
  );

  let Dashboard;
  if (
    isInspecteur ||
    isDeclarant ||
    isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.CARRIERE) ||
    isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.EAU)
  ) {
    Dashboard = DashboardGlobal;
  } else if (
    isAnyPrestataire ||
    isLecteurOnSection(LecteurDroitDtoAllowedSectionsEnum.QUOTAS)
  ) {
    Dashboard = DashboardQuotas;
  } else {
    Dashboard = PublicPortal;
  }

  let QuotasNiveauxActiviteComponent;
  if (!hasQuotasAccess) {
    QuotasNiveauxActiviteComponent = PublicPortal;
  } else if (getIsActiveSection(PATH_QUOTAS_ALLOCATIONS)) {
    QuotasNiveauxActiviteComponent = QuotasNiveauxActivite;
  } else {
    QuotasNiveauxActiviteComponent = () => (
      <p>Cette déclaration n'a pas de section niveaux d'activité.</p>
    );
  }

  let QuotasEmissionsComponent;
  if (!hasQuotasAccess) {
    QuotasEmissionsComponent = PublicPortal;
  } else if (getIsActiveSection(PATH_QUOTAS_EMISSIONS)) {
    QuotasEmissionsComponent = QuotasEmissions;
  } else {
    QuotasEmissionsComponent = () => (
      <p>Cette déclaration n'a pas de section émissions.</p>
    );
  }

  const RedirectToQuota = () => {
    return (
      <Redirect
        to={computeDeclarationUrl(
          route.match.params.etablissement,
          PATH_QUOTAS_EMISSIONS.replace("/", ""),
          parseInt(route.match.params.annee)
        )}
      />
    );
  };

  return (
    <DeclarationPageHeaderAutoUpdater19Now>
      <Switch>
        <Route
          path={`${route.match.path}/campagne_inactive`}
          component={CampaignDisabled}
        />
        <Route
          path={`${route.match.path}${PATH_AIR_GAZ}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_GAZ)
              ? GazFluores
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_SOLVANTS}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_SOLVANTS)
              ? Solvants
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_COMBUSTION}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_COMBUSTION)
              ? Combustion
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_PROCEDES}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_PROCEDES)
              ? Procedes
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_SYNTHESE}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_SYNTHESE)
              ? Synthese
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_AIR_ELEVAGE}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_ELEVAGE)
              ? Breeding
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_AIR_ISDND}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_AIR_ISDND)
              ? Isdnd
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_DECHET_PRODUCTION}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_DECHET_PRODUCTION)
              ? WasteDeclarationSortant
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_DECHET_RECEPTION}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_DECHET_RECEPTION)
              ? WasteDeclarationEntrant
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_DECHET_SYNTHESE}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_DECHET_SYNTHESE)
              ? WasteDeclarationSynthese
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_INFO_GENERALE}`}
          component={
            hasGlobalAccess || hasEauAccessLecteur || hasCarriereAccessLecteur
              ? GeneralInformations
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${ROUTER_PATH_TYPE_ACTIVITE}`}
          component={TypeActivite}
        />

        <Route
          path={`${route.match.path}${PATH_SOL}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_SOL)
              ? Sol
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_EAU}`}
          component={
            (hasGlobalAccess || hasEauAccessLecteur) &&
            getIsActiveSection(PATH_EAU)
              ? Eau
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_QUOTAS_ALLOCATIONS}`}
          component={QuotasNiveauxActiviteComponent}
        />

        <Route
          path={`${route.match.path}${PATH_QUOTAS_EMISSIONS}`}
          component={QuotasEmissionsComponent}
        />

        <Route
          path={`${route.match.path}${PATH_QUOTAS}`}
          component={RedirectToQuota}
        />

        <Route
          path={`${route.match.path}${PATH_SPFO}`}
          component={
            hasGlobalAccess && getIsActiveSection(PATH_SPFO)
              ? SPFO
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_CARRIERE_SANTE}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_SANTE)
              ? CarriereSanteSecurite
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_CARRIERE_PRODUCTION}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_PRODUCTION)
              ? Production
              : RedirectDashboard
          }
        />
        <Route
          path={`${route.match.path}${PATH_CARRIERE_ENVIRONNEMENT}`}
          component={
            (hasGlobalAccess || hasCarriereAccessLecteur) &&
            getIsActiveSection(PATH_CARRIERE_ENVIRONNEMENT)
              ? Environnement
              : RedirectDashboard
          }
        />

        <Route
          path={`${route.match.path}${PATH_DASHBOARD}`}
          component={Dashboard}
        />

        <Route path="/" component={PublicPortal} />
      </Switch>
    </DeclarationPageHeaderAutoUpdater19Now>
  );
};

export default Router24Now;
