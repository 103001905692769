import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { PmsDto2020, QuotasInstallationDto20Now } from "api/gen";
import { pmsDtoToPmsFileDto } from "./utils";
import { useDeclaration2020 } from "../../../versionedElements/declarationHooks2020";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { usePmsFileHandler2020 } from "../../../versionedElements/filesLoaderDisplayer2020/sectionFilesHooks2020";

interface PmsFilesLoaderProps {
  installation: QuotasInstallationDto20Now;
  shouldDisableFields: boolean;
}

const PmsFilesLoader = ({
  installation,
  shouldDisableFields,
}: PmsFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const openAlertModale = useAlertModale();
  const pmsFileHandler = usePmsFileHandler2020(installation.id);

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE PMS"}
      filesPath={
        "body.sections.quotas.allocations.blocPlanMethodSurveillance.listPms"
      }
      section={
        FileSectionEnum.QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE
      }
      fileHandlers={pmsFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      transformFunction={(listPms: Record<string, unknown>[]) =>
        pmsDtoToPmsFileDto(
          installation.id,
          (listPms as unknown) as PmsDto2020[]
        )
      }
    />
  );
};

export default PmsFilesLoader;
