// it's important this file has the quotas in name as it's a page that is in router
// eslint-disable-next-line
import { makeStyles } from "@material-ui/styles";
import { RouteIdProps } from "common/declarant/type";
import Row from "common/presentational/Row";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import React from "react";
import { PINK } from "theme";
import NiveauxActiviteForm from "./NiveauxActiviteForm";
import logoAllocations from "icons/pageLogos/calculator.svg";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { WorkflowTarget } from "common/utils/types";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";
import {
  useStatusCampaignCurrentWorkflow,
  wrapWithWorkflowTargetProvider,
} from "../../../../WorkflowTargetProviderContext";
import {
  useDeclaration2323,
  useQuotaAllocationsState2323,
} from "../../versionedElements/declarationHooks2323";

const useStyles = makeStyles({
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
});

const QuotasNiveauxActivite = ({ match }: RouteIdProps): React.ReactElement => {
  const classes = useStyles();

  const declarationAnnee = match.params.annee;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      match.params.etablissement
    ),
    {
      label: "Niveaux d'activité",
      to: match.url,
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Niveaux d'activité",
    image: { src: logoAllocations, alt: "une calculatrice en logo" },
  };
  usePageHeaderSetterOnce(headerData);

  const quotasState = useQuotaAllocationsState2323();
  const declaration = useDeclaration2323();
  const quotasStateComment =
    declaration.body.workflowStatus.quotaAllocations.message;

  const currentCampaignStatus = useStatusCampaignCurrentWorkflow();
  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaignStatus,
    WorkflowTarget.QUOTA_ALLOCATIONS
  );

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />
      <span>
        <span className={classes.color}>Statut de quotas : </span>
        <p className={classes.informationDeclaration}>
          {quotasState.stateMessage}
        </p>
      </span>
      <Row height={"20px"} />
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        isInReviewState={quotasState.isDeclarationStateContaining([
          "IN_REVIEW",
        ])}
        comment={quotasStateComment}
      />

      <ReferentialFetcher
        wantedKeys={["REFERENCE_ACTIVITES_QUOTAS"]}
        render={referentials => {
          return (
            <NiveauxActiviteForm
              referentialActivites={referentials.REFERENCE_ACTIVITES_QUOTAS}
            />
          );
        }}
      />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(
  QuotasNiveauxActivite,
  "QUOTA_ALLOCATIONS"
);
