import React, { ReactElement } from "react";
import { Omit } from "react-router";
import { ChoiceSelectWTOPProps } from "../types/connectedTypes";
import FieldWrapper, { FieldWrapperProps } from "../wrappers/FieldWrapper";
import ChoiceSelectWithoutOptionProps from "../connectedInput/ChoiceSelectWithoutOptionProps";

type WrappedChoiceSelectWithoutLabelProps<T, IsMulti extends boolean> = Omit<
  ChoiceSelectWTOPProps<T, IsMulti>,
  "field" | "form" | "id"
> &
  Omit<FieldWrapperProps, "renderField">;

export function WrappedChoiceSelectWithoutLabel<T, IsMulti extends boolean>(
  props: WrappedChoiceSelectWithoutLabelProps<T, IsMulti>
): ReactElement {
  const isDisabled =
    props.externalValue !== undefined ||
    (props.disabled ?? props.commonProps.disabled);

  return (
    <FieldWrapper
      name={props.name}
      commonProps={props.commonProps}
      renderField={({ field, form }, id) => (
        <ChoiceSelectWithoutOptionProps
          id={id}
          field={field}
          form={form}
          isMulti={props.isMulti}
          options={props.options}
          computeLabel={props.computeLabel}
          additionalClassName={props.additionalClassName}
          style={props.style}
          disabled={isDisabled}
          additionalOnChange={props.additionalOnChange}
          enableReset={props.enableReset}
          hideValue={props.hideValue}
          placeholder={props.placeholder}
          externalValue={props.externalValue}
        />
      )}
    />
  );
}
