import React from "react";
import { FileSectionEnum } from "common/FilesLoader/types";
import { useAlertModale } from "common/modale/hooks";
import { PmsDto21Now } from "api/gen";
import FilesLoader from "common/FilesLoader/FilesLoader";
import { useDeclaration2122 } from "../../../versionedElements/declarationHooks2122";
import { usePmsFileHandler21Now } from "pages/CompanySpace/from21/toNow/versionedElements/filesLoaderDisplayer21Now/sectionFilesHooks21Now";
import {
  NimFilesLoaderProps,
  oneFileRelatedToNimToFileDto,
} from "pages/CompanySpace/from21/toNow/Quotas/common/FilesRelatedToNim/utils";

const PmsLoader = ({
  installationId,
  shouldDisableFields,
}: NimFilesLoaderProps): React.ReactElement => {
  const declaration = useDeclaration2122();
  const openAlertModale = useAlertModale();
  const pmsFileHandler = usePmsFileHandler21Now(installationId);

  return (
    <FilesLoader
      declaration={declaration}
      text={"DÉPOSER LE PMS"}
      filesPath={
        "body.externalSections.quotas.allocations.blocPlanMethodSurveillance.listPms"
      }
      section={
        FileSectionEnum.QUOTAS_ALLOCATION_PLAN_METHODOLOGIQUE_SURVEILLANCE
      }
      fileHandlers={pmsFileHandler}
      isDisabled={shouldDisableFields}
      onReject={() =>
        openAlertModale("Le format du fichier uploadé est incorrect.")
      }
      max={1}
      transformFunction={(listPms: Record<string, unknown>[]) =>
        oneFileRelatedToNimToFileDto(
          installationId,
          (listPms as unknown) as PmsDto21Now[]
        )
      }
    />
  );
};

export default PmsLoader;
