import * as Yup from "yup";
import {
  commonArrayOfObjectFields,
  commonPositiveNumberFields,
} from "common/declarant/formik/formikHelper";
import { AirSolvantsEmissionDtoDangerEnum } from "api/gen";
import { requiredMessage } from "common/declarant/formik/formikMessages";

export const singleCovValidationSchema = Yup.object().shape({
  danger: Yup.mixed<AirSolvantsEmissionDtoDangerEnum>().required(
    requiredMessage
  ),
  installations: commonArrayOfObjectFields,
  flux: commonPositiveNumberFields,
});
