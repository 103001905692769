import { InertWasteForm } from "./types";

export const computeTotalToBackfill = (values: InertWasteForm): number => {
  return (
    (values.groundAndStoneToBackfill || 0) + (values.otherStuffToBackfill || 0)
  );
};

export const computeTotalToRecycle = (values: InertWasteForm): number => {
  return (
    (values.groundAndStoneToRecycle || 0) + (values.otherStuffToRecycle || 0)
  );
};

export const computeTotal = (values: InertWasteForm): number => {
  return computeTotalToBackfill(values) + computeTotalToRecycle(values);
};
