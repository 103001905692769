import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import Row from "common/presentational/Row";
import { SUBTITLE_STYLE } from "theme";
import { useClassicBloc } from "common/bloc/utils";
import { useFormikBloc } from "common/formikBloc/utils";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import {
  isFullDeclarationActive,
  useAllDeclarantBlocsValidated,
} from "./utils/utils";
import QuotasGlobalFormActions from "./QuotasGlobalFormActions";
// Bloc Info Generales
import BlocInfoGenerales from "./blocInfoGenerales";
import { updateHandler as updateHandlerInfoGenerales } from "./blocInfoGenerales/utils";
// Bloc Installations
import BlocInstallations from "./blocInstallations";
import {
  dtoToInstallations,
  updateInstallationsHandler,
} from "./blocInstallations/converter";
// Bloc Plan de Surveillance
import BlocPlanDeSurveillance from "./blocPlanDeSurveillance";
import { BlocPlanDeSurveillanceFormValues } from "./blocPlanDeSurveillance/types";
import { updateHandler as updateHandlerPlanDeSurveillance } from "./blocPlanDeSurveillance/converter";
// Bloc Emissions
import BlocEmissions from "./blocEmissions";
import {
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  MeasureEmissionInArray,
} from "./blocEmissions/types";
import {
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  flowLabelToId,
  fluxDtoToFlowDeclaration,
  pointMesureDtoToMeasureDeclaration,
  updateHandler as updateHandlerEmissions,
} from "./blocEmissions/converter";
// Bloc Bilan Total Calcule
import BlocBilanTotalCalcule from "./blocBilanTotalCalcule";
// Bloc Bilan Total
import BlocBilanTotal from "./blocBilanTotal";
import { updateHandler as updateHandlerBilanTotal } from "./blocBilanTotal/utils";
// Bloc Verification
import BlocVerification from "./BlocVerification";
// Bloc Inspecteur
import BlocInspecteur from "./BlocInspecteur";
import {
  QuotasBlocEmissionsBilanTotalDto1819,
  QuotasBlocInfoGeneralesDto1819,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { selectPossibleValues } from "./blocInstallations/selectPossibleValues";
import {
  computeAllowedDeclarationsMethods,
  generateSetterBlocEmissions,
} from "./blocEmissions/utils/utils";
import {
  PATH_QUOTAS_INSTALLATION_BLOC,
  PATH_QUOTAS_PLAN_BLOC,
} from "common/path/path1819";
import {
  useBasicDeclarationHandlers1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../versionedElements/declarationHooks1919";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

interface MainQuotasFormProps {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
});

const MainQuotasForm = ({
  referentialActivites,
}: MainQuotasFormProps): React.ReactElement => {
  const declaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();
  const { update } = useBasicDeclarationHandlers1919();
  const classes = useStyles();

  const possibleValuesInstallations = selectPossibleValues(
    referentialActivites
  );
  const blocInfoGeneralesProps = useClassicBloc<QuotasBlocInfoGeneralesDto1819>(
    {
      excluArticle27: !!declaration.body.sections.quotas.blocInfoGenerales
        .excluArticle27,
    }
  );
  const blocInstallationsProps = useClassicBloc(
    dtoToInstallations(
      declaration.body.sections.quotas.blocInstallations,
      possibleValuesInstallations,
      referentialActivites
    )
  );
  const blocPlanDeSurveillanceProps = useFormikBloc<
    BlocPlanDeSurveillanceFormValues
  >();
  const blocEmissionsProps = useFormikBloc<BlocEmissionsFormValues>();
  const blocBilanTotalProps = useFormikBloc<
    QuotasBlocEmissionsBilanTotalDto1819
  >();

  const DummyCheckbox = useDummyBooleanCheckboxFieldGenerator({
    disabled: true,
    className: classes.inputField,
    labelWidth: "60%",
    formPrefix: "",
  });

  const flowDeclarations = declaration.body.sections.quotas.blocEmissions.flux.map(
    f => fluxDtoToFlowDeclaration(f, blocInstallationsProps.values)
  );
  const measureDeclarations = declaration.body.sections.quotas.blocEmissions.pointsMesure.map(
    p => pointMesureDtoToMeasureDeclaration(p, blocInstallationsProps.values)
  );

  const [exemptedFlowsSelected, setExemptedFlowsSelected] = useState<string[]>(
    declaration.body.sections.quotas.blocEmissions.fluxSoumisADerogation.map(
      f => flowLabelToId(f, flowDeclarations)
    )
  );

  const [computedEmissionsInArray, setComputedEmissionsInArray] = useState<
    ComputedEmissionInArray[]
  >(
    declaration.body.sections.quotas.blocEmissions.emissionsCalculees.map(
      emission =>
        emissionSimpleDtoToComputedEmission(emission, flowDeclarations)
    )
  );
  const [measuredEmissionsInArray, setMeasuredEmissionInArray] = useState<
    MeasureEmissionInArray[]
  >(
    declaration.body.sections.quotas.blocEmissions.emissionsMesurees.map(
      emission =>
        emissionSimpleDtoToMeasuredEmission(emission, measureDeclarations)
    )
  );
  const [
    alternativeEmissionsInArray,
    setAlternativeEmissionsInArray,
  ] = useState<AlternativeEmissionInArray[]>(
    declaration.body.sections.quotas.blocEmissions.emissionsMethodeAlternative.map(
      emissionSimpleDtoToAlternativeEmission
    )
  );

  // Note : we didn't test whether this method worked as intended and was regenerated when the blocInstallation values changed. Since it's for the time being called only with empty values,
  // this doesn't cause problems, but it may be a source of bugs if called with data where the bloc installation props values is actually used.
  const setBlocEmissions = generateSetterBlocEmissions(
    blocInstallationsProps.values,
    blocEmissionsProps.formikRef,
    setAlternativeEmissionsInArray,
    setMeasuredEmissionInArray,
    setComputedEmissionsInArray,
    setExemptedFlowsSelected
  );

  const isFullForm = isFullDeclarationActive(blocInfoGeneralesProps.values);
  const pageHasChanges =
    blocInfoGeneralesProps.hasChanges &&
    (isFullForm
      ? blocInstallationsProps.hasChanges ||
        blocPlanDeSurveillanceProps.hasChanges ||
        blocEmissionsProps.hasChanges
      : blocBilanTotalProps.hasChanges);

  // Are blocs validated?
  const allDeclarantBlocsValidated = useAllDeclarantBlocsValidated(isFullForm);

  const globalFormActions = (
    <QuotasGlobalFormActions
      saveAction={() =>
        update(declaration => {
          declaration = updateHandlerInfoGenerales(
            declaration,
            blocInfoGeneralesProps.values
          );

          if (isFullForm) {
            declaration = updateInstallationsHandler(
              declaration,
              blocInstallationsProps.values,
              referentialActivites
            );
            declaration = updateHandlerPlanDeSurveillance(
              declaration,
              blocPlanDeSurveillanceProps.getValues(),
              declaration.body.sections.quotas.blocPlanSurveillance.fichiers // TODO: GEREP-812 - remove during refacto
            );
            declaration = updateHandlerEmissions(
              declaration,
              blocEmissionsProps.getValues(),
              declaration.body.sections.quotas.blocEmissions.fichiers, // TODO: GEREP-812 - remove during refacto
              computedEmissionsInArray,
              measuredEmissionsInArray,
              alternativeEmissionsInArray,
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .flowDeclarations
                : flowDeclarations,
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .measureDeclarations
                : measureDeclarations,
              exemptedFlowsSelected
            );
            declaration = updateHandlerBilanTotal(declaration, null);
          } else {
            declaration = updateInstallationsHandler(
              declaration,
              [],
              referentialActivites
            );
            declaration = updateHandlerPlanDeSurveillance(
              declaration,
              null,
              [] // TODO: GEREP-812 - remove during refacto
            );
            declaration = updateHandlerEmissions(
              declaration,
              null,
              [], // TODO: GEREP-812 - remove during refacto
              [],
              [],
              [],
              [],
              [],
              exemptedFlowsSelected
            );
            declaration = updateHandlerBilanTotal(
              declaration,
              blocBilanTotalProps.getValues()
            );
          }
          return declaration;
        })
      }
      cancelAction={() => {
        blocInfoGeneralesProps.cancelAction();
        blocInstallationsProps.cancelAction();
        blocPlanDeSurveillanceProps.resetForm();
        blocEmissionsProps.resetForm();
        blocBilanTotalProps.resetForm();
      }}
      hasChanges={pageHasChanges}
      declarantBlocsValidated={allDeclarantBlocsValidated}
    />
  );

  return (
    <>
      <Row />

      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>
          DONNÉES HÉRITÉES DU BLOC INFORMATIONS GÉNÉRALES
        </h3>
      </Row>

      <DummyCheckbox
        name="soumisQuotas"
        label="L'établissement est soumis à la directive 2003/87/CE (directive quotas)"
        value={declaration.body.typeActivite.estSousQuotaCo2}
      />

      <Row />

      <BlocInfoGenerales
        values={blocInfoGeneralesProps.values}
        setValues={blocInfoGeneralesProps.setValues}
        hasChanges={blocInfoGeneralesProps.hasChanges}
        cancelAction={blocInfoGeneralesProps.cancelAction}
      />

      {isFullForm ? (
        <>
          <Row />

          <BlocInstallations
            values={blocInstallationsProps.values}
            setValues={blocInstallationsProps.setValues}
            hasChanges={blocInstallationsProps.hasChanges}
            cancelAction={blocInstallationsProps.cancelAction}
            possibleValues={possibleValuesInstallations}
            referentialActivites={referentialActivites}
            setBlocEmissions={setBlocEmissions}
          />

          <Row />

          <BlocPlanDeSurveillance
            blocPlanDeSurveillanceFormProps={blocPlanDeSurveillanceProps}
            installationsInArray={blocInstallationsProps.values}
            setBlocEmissions={setBlocEmissions}
          />
          <Row />

          <BlocEmissions
            blocEmissionsFormProps={blocEmissionsProps}
            installationsInArray={blocInstallationsProps.values}
            isDisabled={
              !isPathValidatedInDeclaration(PATH_QUOTAS_INSTALLATION_BLOC) ||
              !isPathValidatedInDeclaration(PATH_QUOTAS_PLAN_BLOC)
            }
            computedEmissionsInArray={computedEmissionsInArray}
            setComputedEmissionsInArray={setComputedEmissionsInArray}
            measuredEmissionsInArray={measuredEmissionsInArray}
            setMeasuredEmissionsInArray={setMeasuredEmissionInArray}
            alternativeEmissionsInArray={alternativeEmissionsInArray}
            setAlternativeEmissionsInArray={setAlternativeEmissionsInArray}
            exemptedFlows={exemptedFlowsSelected}
            setExemptedFlows={setExemptedFlowsSelected}
            allowedDeclarationMethods={computeAllowedDeclarationsMethods(
              declaration.body.sections.quotas.blocPlanSurveillance
            )}
          />

          <Row />

          <BlocBilanTotalCalcule
            installationsInArray={blocInstallationsProps.values}
            computedEmissionsInArray={computedEmissionsInArray}
            measuredEmissionsInArray={measuredEmissionsInArray}
            alternativeEmissionsInArray={alternativeEmissionsInArray}
            measureDeclarations={
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .measureDeclarations
                : measureDeclarations
            }
            flowDeclarations={
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .flowDeclarations
                : flowDeclarations
            }
          />

          <Row />

          {globalFormActions}

          <Row />

          <BlocVerification
            possibleValues={possibleValuesInstallations}
            referentialActivites={referentialActivites}
          />
        </>
      ) : (
        <>
          <Row />

          <BlocBilanTotal
            formikRef={blocBilanTotalProps.formikRef}
            hasChanges={blocBilanTotalProps.hasChanges}
            setHasChanges={blocBilanTotalProps.setHasChanges}
          />

          <Row />

          {globalFormActions}
        </>
      )}

      <Row />

      <BlocInspecteur canValidatePage={!allDeclarantBlocsValidated} />

      <Row height={"25px"} />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(
  MainQuotasForm,
  "QUOTA_EMISSIONS"
);
