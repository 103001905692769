import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import { GeneralInformationsForm } from "./types";
import { selectPossibleValues } from "./selectPossibleValues";

export const convertDtoToGeneralInformationsForm = (
  declaration: Declaration1919
): GeneralInformationsForm => {
  return {
    maxProductionPerYear:
      declaration.body.sections.carriere.environnement.general.prodMax,
    averageProductionPerYear:
      declaration.body.sections.carriere.environnement.general.prodMoy,
    endAuthorizationDate: declaration.body.sections.carriere.environnement
      .general.dateFin
      ? new Date(
          declaration.body.sections.carriere.environnement.general.dateFin
        )
      : null,
    eau: declaration.body.sections.carriere.environnement.general.eau
      ? selectPossibleValues.careerTypeEau[0]
      : selectPossibleValues.careerTypeEau[1],
    souterrain: declaration.body.sections.carriere.environnement.general
      .souterrain
      ? selectPossibleValues.careerTypeSouterrain[0]
      : selectPossibleValues.careerTypeSouterrain[1],
  };
};
