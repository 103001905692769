import {
  ReferenceItemNormeSolDto,
  ReferenceItemPolluantDto,
  ReferenceNormeSolDto,
} from "api/gen/api";
import { RejetSolDto } from "api/gen";
import {
  MainFormValues,
  PolluantInArray,
  PolluantInModale,
  SolCommonSelectOptions,
} from "./types";
import { calcErrors } from "../validation/validation";
import _ from "lodash";
import { findElementMatchingTemplate } from "common/utils/methods";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";

export const convertNormeItemToLabel = (
  norme: ReferenceItemNormeSolDto
): string => {
  return `${norme.libelle}${
    norme.descriptionNorme ? ` - ${norme.descriptionNorme}` : ""
  }`;
};

const getSubstanceById = (
  substances: ReferenceItemPolluantDto[],
  substanceId: string
) => {
  const substance = findElementMatchingTemplate(
    { uuid: substanceId },
    substances
  );
  return substance;
};

const getSubstanceIdByName = (
  substances: ReferenceItemPolluantDto[],
  substanceName: string
) => {
  const substance = findElementMatchingTemplate(
    { nom: substanceName },
    substances
  );
  return substance ? substance.uuid : null;
};

const exportNormes = (
  normes: string[] | undefined | null,
  referentialNormes: ReferenceNormeSolDto
) => {
  if (_.isNil(normes)) {
    return null;
  }

  if (normes.length === 0) {
    return null;
  }

  const normesToExport: ReferenceItemNormeSolDto[] = [];
  normes.forEach(el => {
    const norm = referentialNormes.referenceItemNormeSolDtoList.find(
      norm => norm.uuid === el
    );
    if (norm) {
      normesToExport.push(norm);
    }
  });
  return normesToExport;
};

export const convertSolDtoToPolluantSol = (
  rejet: RejetSolDto,
  referentiels: SolCommonSelectOptions,
  referentialNormes: ReferenceNormeSolDto
): PolluantInModale => {
  const substance = getSubstanceById(
    referentiels.polluantName,
    rejet.polluantID || ""
  );
  const method = rejet.methodeUtilisee;
  const methodRef = rejet.referenceMethode;

  const polluantSol: PolluantInModale = {
    pollutingSubstance: substance,
    emission: rejet.emission,
    includingAccidental: rejet.masseAccidentelle,
    usedMethod: method,
    methodReference: methodRef,
    methodDescription: rejet.description,
    polluantNorme: exportNormes(rejet.normesID, referentialNormes),
  };
  return polluantSol;
};

export const computeDisplayedDataFromDto = (
  polluants: RejetSolDto[],
  referentiels: SolCommonSelectOptions,
  referentialNormes: ReferenceNormeSolDto
): PolluantInArray[] => {
  return polluants.map(polluant => {
    const polluantSol = convertSolDtoToPolluantSol(
      polluant,
      referentiels,
      referentialNormes
    );
    const displayedPolluant: PolluantInArray = {
      data: { ...polluantSol, id: polluant.id },
      errors: calcErrors(polluantSol, referentiels),
    };
    return displayedPolluant;
  });
};

export const computeFormInitialValues = (
  declarationDto: Declaration1919
): MainFormValues => {
  return {
    doesTreatmentOrValorisation:
      declarationDto.body.sections.sol.doitRemplirSol || false,
    totalAmount: declarationDto.body.sections.sol.volumeDechets,
  };
};

const findNormeId = (polluantNorme: ReferenceItemNormeSolDto[]) => {
  const normsID: string[] = [];
  polluantNorme.forEach(norm => {
    normsID.push(norm.uuid);
  });
  return normsID;
};

export const computeRejetDtoFromDisplayedData = (
  displayedPolluants: PolluantInArray[],
  referentiels: SolCommonSelectOptions
): RejetSolDto[] => {
  return displayedPolluants.map(polluant => {
    const rejet: RejetSolDto = {
      polluantID: polluant.data.pollutingSubstance
        ? getSubstanceIdByName(
            referentiels.polluantName,
            polluant.data.pollutingSubstance.nom
          )
        : null,
      methodeUtilisee: polluant.data.usedMethod,
      referenceMethode: polluant.data.methodReference,
      emission: polluant.data.emission,
      masseAccidentelle: polluant.data.includingAccidental,
      description: polluant.data.methodDescription,
      id: polluant.data.id,
      normesID: polluant.data.polluantNorme
        ? findNormeId(polluant.data.polluantNorme)
        : [],
    };
    return rejet;
  });
};
