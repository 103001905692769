import React, { ReactElement } from "react";
import { AlcVerifieFilesDto24Now, QuotasInstallationDto20Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import { FIELD_FONT_WEIGHT } from "theme";
import AlcVerifieSummary from "./AlcVerifieSummary";
import AlcVerifieInfosGenerales from "./InfosGenerales/AlcVerifieInfosGenerales";
import SousInstallationReferentielProduit from "./SousInstallation/ReferentielProduit/SousInstallationReferentielProduit";
import SousInstallationReferentielAlternatif from "./SousInstallation/ReferentielAlternatif/SousInstallationReferentielAlternatif";
import { sortObjectKeysAndValues } from "common/utils/methods";
import Collapsible from "common/presentational/Collapsible";

const useStyles = makeStyles({
  container: {
    margin: "20px 0",
    // b does not apply bold font weight without setting the css rule explicitly
    "& b": {
      fontWeight: FIELD_FONT_WEIGHT,
    },
  },
  collapsideTitle: {
    textAlign: "center",
  },
});

interface RapportAlcExtractedDataProps {
  installation: QuotasInstallationDto20Now;
  rapportAlcDataList: AlcVerifieFilesDto24Now[];
}

const RapportAlcExtractedData = ({
  installation,
  rapportAlcDataList,
}: RapportAlcExtractedDataProps): ReactElement => {
  const classes = useStyles();

  const rapportAlc = rapportAlcDataList.find(
    rapportAlcData => rapportAlcData.nimDynId === installation.id
  );
  if (rapportAlc === undefined) {
    return <></>;
  }

  const {
    infosGeneralesAlcDto,
    infosInstallationDto: {
      positionToSousInstallationRefProduitMap,
      positionToSousInstallationRefAlternatifMap,
    },
  } = rapportAlc.rapportAlcData;

  const {
    sortedValues: sortedSousInstallationRefProduitList,
  } = sortObjectKeysAndValues(positionToSousInstallationRefProduitMap);
  const {
    sortedValues: sortedSousInstallationRefAlternatifList,
  } = sortObjectKeysAndValues(positionToSousInstallationRefAlternatifMap);

  return (
    <Collapsible
      title={"SYNTHÈSE DU RAPPORT ALC"}
      additionalTitleClassname={classes.collapsideTitle}
    >
      <div className={classes.container}>
        <AlcVerifieSummary />

        <AlcVerifieInfosGenerales infosGeneralesAlcDto={infosGeneralesAlcDto} />

        <SousInstallationReferentielProduit
          sousInstallationRefProduitList={sortedSousInstallationRefProduitList}
        />

        <SousInstallationReferentielAlternatif
          sousInstallationRefAlternatifList={
            sortedSousInstallationRefAlternatifList
          }
        />
      </div>
    </Collapsible>
  );
};

export default RapportAlcExtractedData;
