import { PopulatedRecyclingProductInArray } from "./types";

export const computeTotalProductionQuantities = (
  recyclingProductsInArray: PopulatedRecyclingProductInArray[]
): number => {
  let total = 0;
  recyclingProductsInArray.forEach(product => {
    total += product.displayedProduct.productionQuantity;
  });
  return total;
};
