import {
  ComputedPolluantEauDto24Now,
  EauBlocRejetPolluantsDto,
  PolluantEauDto,
  ReferenceItemNormeEauDto,
  ReferenceNormeEauDto,
  ReferencePolluantDto,
} from "api/gen";
import { PolluantInArray, PolluantInModale } from "./types";
import _ from "lodash";

export const convertNormeItemToLabel = (
  norme: ReferenceItemNormeEauDto
): string => {
  return `${norme.libelle}${
    norme.descriptionNorme ? ` - ${norme.descriptionNorme}` : ""
  }`;
};

const getNormesByIds = (
  normesIDs: string[] | undefined | null,
  referentialNormes: ReferenceNormeEauDto
) => {
  if (_.isNil(normesIDs)) {
    return null;
  }

  if (normesIDs.length === 0) {
    return null;
  }

  const normesDtoList: ReferenceItemNormeEauDto[] = [];
  normesIDs.forEach(normesID => {
    const norm = referentialNormes.referenceItemNormeEauDtoList.find(
      norm => norm.uuid === normesID
    );
    if (norm) {
      normesDtoList.push(norm);
    }
  });
  return normesDtoList;
};

const polluantDtoToPolluant = (
  polluantDto: PolluantEauDto,
  referentialPolluants: ReferencePolluantDto,
  referentialNormes: ReferenceNormeEauDto,
  computedPolluant: ComputedPolluantEauDto24Now | null
): PolluantInModale => {
  return {
    pollutingSubstance:
      referentialPolluants.polluants.find(
        item => item.uuid === polluantDto.polluantID
      ) || null,
    type: polluantDto.typeRejet,
    emission: polluantDto.masseEmiseTotale,
    includingImported: polluantDto.dontMasseImportee,
    includingAccidental: polluantDto.dontMasseAccidentelle,
    usedMethod: polluantDto.methode,
    methodReference: polluantDto.referenceMethode,
    methodDescription: polluantDto.descriptionMethode,
    purificationYield: polluantDto.rendementStationExterne,
    normes: getNormesByIds(polluantDto.normesID, referentialNormes),
    depassementDeSeuil: computedPolluant?.depassementDeSeuil,
  };
};

export const dtoToPolluantsInArray = (
  dto: EauBlocRejetPolluantsDto,
  referentialPolluants: ReferencePolluantDto,
  referentialNormes: ReferenceNormeEauDto,
  computedPolluants: ComputedPolluantEauDto24Now[]
): PolluantInArray[] => {
  // Array to keep trace of polluting substances already in polluant array
  const polluantsInArray: PolluantInArray[] = [];
  return dto.rejetPolluants.map(polluantDto => {
    const polluant = polluantDtoToPolluant(
      polluantDto,
      referentialPolluants,
      referentialNormes,
      computedPolluants.find(
        computedPolluant => computedPolluant.polluant === polluantDto.polluantID
      ) ?? null
    );
    const polluantInArray: PolluantInArray = {
      data: { ...polluant, id: polluantDto.id },
      errors: {},
    };
    polluantsInArray.push(polluantInArray);
    return polluantInArray;
  });
};

const polluantToPolluantDto = (
  id: string,
  polluant: PolluantInModale
): PolluantEauDto => {
  return {
    id: id,
    polluantID: polluant.pollutingSubstance && polluant.pollutingSubstance.uuid,
    typeRejet: polluant.type,
    masseEmiseTotale: polluant.emission,
    dontMasseImportee: polluant.includingImported,
    dontMasseAccidentelle: polluant.includingAccidental,
    methode: polluant.usedMethod,
    referenceMethode: polluant.methodReference,
    descriptionMethode: polluant.methodDescription,
    rendementStationExterne: polluant.purificationYield,
    normesID: polluant.normes ? polluant.normes.map(norme => norme.uuid) : [],
  };
};

export const blocValuesToDto = (
  polluants: PolluantInArray[]
): EauBlocRejetPolluantsDto => ({
  rejetPolluants: polluants.map(polluantInArray =>
    polluantToPolluantDto(polluantInArray.data.id, polluantInArray.data)
  ),
});
