import React, { ReactElement, useCallback } from "react";
import { makeStyles } from "@material-ui/styles";

import { TEXT_LIGHT_GREY } from "theme";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import DownloadButton from "./BlocReferentiel/DownloadButton";
import ResetButton from "./ResetButton";

const useStyles = makeStyles({
  container: {
    display: "flex",
    justifyContent: "flex-center",
    alignItems: "center",
    margin: "5px 0",
    height: "auto",
  },
  label: {
    color: TEXT_LIGHT_GREY,
    flex: "1",
    textAlign: "right",
  },
  buttonArea: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: "10px",
    flex: "1",
    "& button": {
      margin: "0 5px",
      padding: "0.5em",
      height: "min-content",
    },
  },
});

export const BlocChangementsPostCampagne = (): ReactElement => {
  const classes = useStyles();
  const { gestionnaireController } = useAuthenticatedApi();

  const downloadHandler = useCallback(() => {
    return gestionnaireController.exportPostCampaignChangesUsingGET();
  }, [gestionnaireController]);

  const resetHandler = useCallback(async () => {
    await gestionnaireController.reinitPostCampaignChangesUsingPUT();
  }, [gestionnaireController]);

  return (
    <DummyUnActionedBloc
      title={"Changements post-campagne"}
      renderContent={() => {
        return (
          <>
            <Row additionalClassname={classes.container}>
              <label className={classes.label}>
                Cliquez ici pour télécharger la liste des changements réalisés
                sur les déclarations après la fin de la campagne qui leur est
                associée.
              </label>
              <div className={classes.buttonArea}>
                <DownloadButton
                  downloadHandler={downloadHandler}
                  filename={"changements_postcampagne.xlsx"}
                  disabled={false}
                />
              </div>
            </Row>
            <Row additionalClassname={classes.container}>
              <label className={classes.label}>
                Cliquez ici pour réinitialiser la liste des changements. Cette
                action aura pour conséquence la perte des données accumulées
                dans la liste jusqu&apos;ici et peut être utilisée lorsque tous
                les changements ont été pris en compte dans BDREP.
              </label>
              <div className={classes.buttonArea}>
                <ResetButton
                  resetHandler={resetHandler}
                  confirmationMessage={
                    "Les changements réinitialisés n'apparaîtront plus lors des prochains téléchargements de changements post-campagne."
                  }
                  confirmationMessageDetails={
                    "Etes-vous sûr de vouloir procéder ?"
                  }
                />
              </div>
            </Row>
          </>
        );
      }}
    ></DummyUnActionedBloc>
  );
};
