import { computeInitialFormValuesFromValidationSchema } from "common/declarant/utils";
import {
  MesureEmpoussierageData,
  PoussiereAlveolaireQuartzTridimiteData,
  PoussiereAlveolaireTotaleData,
} from "./utils";
import {
  poussiereAlveolaireQuartzTridimiteSchema,
  poussiereAlveolaireTotaleSchema,
} from "./validation";
import { FormikProps } from "libAdapter/Formik/TypesPatternAdaptater";
import { Nullable } from "common/utils/types";

//TODO : when https://github.com/Polyconseil/mtes-gerep/pull/214 is merged, complete the computeInitialFormValuesFromValidationSchema
// so that it recursively looks in the keys to give the object fields a default value with null inside. (when you do it though,
// keep in mind optionProps have to be properly handled. see https://github.com/Polyconseil/mtes-gerep/pull/158#discussion_r325193645
// if further details are needed.
export const fillPoussieresSubPartIfEmpty = (
  formikProps: FormikProps<Nullable<MesureEmpoussierageData>>
) => {
  const { setFieldValue, values } = formikProps;

  setFieldValue(
    "poussiereAlveolaireQuartzTridimite",
    computeInitialFormValuesFromValidationSchema<
      PoussiereAlveolaireQuartzTridimiteData
    >(
      values.poussiereAlveolaireQuartzTridimite || {},
      // because the validationSchema can't be typed properly
      (poussiereAlveolaireQuartzTridimiteSchema as any).fields
    )
  );

  setFieldValue(
    "poussiereAlveolaireTotale",
    computeInitialFormValuesFromValidationSchema<PoussiereAlveolaireTotaleData>(
      values.poussiereAlveolaireTotale || {},
      // because the validationSchema can't be typed properly
      (poussiereAlveolaireTotaleSchema as any).fields
    )
  );
};
