import React, { useState } from "react";
import danger from "icons/danger.svg";
import CustomModal from "common/presentational/CustomModal";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyBooleanCheckboxFieldGenerator,
} from "common/form/fields/helpers/generators";
import { SECTION_TITLE_GREY } from "theme";
import {
  aggregationFilesModaleValuesToSelectedFolderSections,
  areAllCheckboxesSelected,
  filtreGestionnaireDtoToAggregationModaleValues,
  selectAllCheckbox,
} from "./converters";
import Spinner from "common/presentational/Spinner";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { AggregationFilesModaleValues, fileSectionFieldMatcher } from "./types";
import { useFilesAggregation } from "pages/CompanySpace/DeclarationApiContext/utils/filesHooks";
import { DeclarationFilters } from "../types";
import { declarationFiltersToDto } from "../converter";
import { useUserData } from "Authenticator/UserData";

interface AggregationModaleProps {
  isOpen: boolean;
  onRequestClose: () => void;
  filter: DeclarationFilters;
  setFilter: (filter: DeclarationFilters) => void;
}

const useStyles = makeStyles({
  loadingMessage: {
    textAlign: "center",
    marginBottom: "20px",
    color: SECTION_TITLE_GREY,
  },
  inputField: {
    marginBottom: "5px",
  },
  sectionTitle: {
    fontWeight: "bold",
    fontSize: "1.3em",
    marginTop: "15px",
    marginBottom: "15px",
    marginRight: "48%",
    textAlign: "end",
    color: SECTION_TITLE_GREY,
  },
  warningIcon: {
    width: "5%",
    margin: "10px",
  },
  warningContainer: {
    marginBottom: "50px",
    color: SECTION_TITLE_GREY,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
});

const AggregationFilesModale = ({
  isOpen,
  onRequestClose,
  filter,
  setFilter,
}: AggregationModaleProps): React.ReactElement => {
  const classes = useStyles();
  const filesAggregation = useFilesAggregation();
  const { userInfo } = useUserData();
  const { email } = userInfo;

  const commonProps = {
    disabled: false,
    formPrefix: "aggregation-modale",
    labelWidth: "50%",
    className: classes.inputField,
  };
  const initialValues = filtreGestionnaireDtoToAggregationModaleValues(filter);

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const DummyCheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  const [isFetching, setIsFetching] = useState<boolean>(false);

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onRequestClose}>
      <CommonFormSingleEntity<AggregationFilesModaleValues>
        title="TÉLÉCHARGER LES FICHIERS DES DÉCLARATIONS"
        isEdit={false}
        closeFunction={onRequestClose}
        initialEntity={initialValues}
        onSubmit={async values => {
          setIsFetching(true);
          const folderSections = aggregationFilesModaleValuesToSelectedFolderSections(
            values
          );
          const newFilter = {
            ...filter,
            selectedFolderSections: folderSections,
          };
          setFilter(newFilter);
          await filesAggregation(declarationFiltersToDto(newFilter));
          setIsFetching(false);
          onRequestClose();
        }}
        validate={values => {
          const folderSections = aggregationFilesModaleValuesToSelectedFolderSections(
            values
          );
          if (folderSections.length === null || folderSections.length === 0) {
            // throwing an empty error to disable the validate button
            return {
              infoGenerales: "",
            };
          }
        }}
        renderField={({ setFieldValue, values }) =>
          isFetching ? (
            <>
              <div className={classes.loadingMessage}>
                Demande d'export en cours...
              </div>
              <Spinner />
            </>
          ) : (
            <>
              <div className={classes.warningContainer}>
                <img
                  src={danger}
                  alt="un panneau attention"
                  className={classes.warningIcon}
                />
                <ul>
                  <li>
                    <p>
                      Les filtres appliqués sur le tableau des déclarations
                      seront pris en compte pour le téléchargement des fichiers
                      déposés.
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        Le fichier téléchargé sera mis à votre disposition à
                        l’adresse mail {email} dès qu’il sera chargé. Ce procédé
                        peut prendre quelques minutes.
                      </strong>
                    </p>
                  </li>
                </ul>
              </div>
              <DummyCheckBox
                label="TOUT SÉLECTIONNER"
                name="selectAll"
                value={areAllCheckboxesSelected(values)}
                onChange={newValue =>
                  selectAllCheckbox(values, newValue, setFieldValue)
                }
              />
              <h2 className={classes.sectionTitle}>{"GEREP hors quotas"}</h2>
              <CheckBox
                label="Plan de gestion de solvant"
                name={fileSectionFieldMatcher.solvantPlanGestion}
              />
              <CheckBox
                label="Feuille de calculs élevage"
                name={fileSectionFieldMatcher.elevageFeuilleCalcul}
              />
              <h2 className={classes.sectionTitle}>{"Quotas émissions"}</h2>
              <CheckBox
                label="Plan de surveillance"
                name={fileSectionFieldMatcher.quotasPlanSurveillance}
              />
              <CheckBox
                label="Documents justificatifs plan de surveillance"
                name={fileSectionFieldMatcher.quotasJustificatifsPds}
              />
              <CheckBox
                label="Rapport AER vérifié"
                name={fileSectionFieldMatcher.quotasRapportEmissions}
              />
              <CheckBox
                label="Documents justificatifs rapport AER vérifié"
                name={
                  fileSectionFieldMatcher.quotasJustificatifsRapportEmissions
                }
              />{" "}
              <CheckBox
                label="Rapports de vérification quotas émissions"
                name={
                  fileSectionFieldMatcher.quotasRapportVerificationEmissions
                }
              />
              <CheckBox
                label="Rapports de déclaration d'office quotas émissions"
                name={
                  fileSectionFieldMatcher.quotasRapportDeclarationOfficeEmissions
                }
              />
              <h2 className={classes.sectionTitle}>
                Quotas niveaux d'activité
              </h2>
              <CheckBox
                label="Plan méthodologique de surveillance"
                name={fileSectionFieldMatcher.quotasAllocationPlanSurveillance}
              />
              <CheckBox
                label="Documents justificatifs plan méthodologie de surveillance"
                name={fileSectionFieldMatcher.quotasJustificatifsPms}
              />
              <CheckBox
                label="Rapports ALC préliminaire"
                name={fileSectionFieldMatcher.quotasRapportAlcPreliminaire}
              />
              <CheckBox
                label="Documents justificatifs ALC préliminaire"
                name={
                  fileSectionFieldMatcher.quotasJustificatifsAlcPreliminaire
                }
              />
              <CheckBox
                label="Rapport ALC vérifié"
                name={fileSectionFieldMatcher.quotasRapportAlcVerifie}
              />
              <CheckBox
                label="Documents justificatifs ALC vérifié"
                name={fileSectionFieldMatcher.quotasJustificatifsAlcVerifie}
              />
              <CheckBox
                label="Rapports de vérification quotas niveaux d'activité"
                name={
                  fileSectionFieldMatcher.quotasRapportVerificationAllocation
                }
              />
              <CheckBox
                label="Rapports de déclaration d'office quotas niveaux d'activité"
                name={
                  fileSectionFieldMatcher.quotasRapportDeclarationOfficeAllocation
                }
              />
              <CheckBox
                label="Documents divers"
                name={fileSectionFieldMatcher.documentsDivers}
              />
            </>
          )
        }
      />
    </CustomModal>
  );
};

export default AggregationFilesModale;
