import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { DEFAULT_BUTTON_SHADOW, PINK } from "theme";

const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& button": {
      marginRight: "20px",
      padding: "5px 15px",
      boxShadow: DEFAULT_BUTTON_SHADOW,
    },
  },
  statusDescription: {
    color: PINK,
    marginRight: "45px",
  },
});

const ActionButtonsContainer = ({
  children,
}: {
  children: ReactNode;
}): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p className={classes.statusDescription}>Actions :</p>
      {children}
    </div>
  );
};

export default ActionButtonsContainer;
