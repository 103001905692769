import { MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum } from "api/gen";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";

const locationType: OptionPropsWithObject<
  MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum
>[] = [
  {
    value: 1,
    label: "Station de type (a)",
    object:
      MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum.STATION_TYPE_A,
  },
  {
    value: 2,
    label: "Station de type (b)",
    object:
      MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum.STATION_TYPE_B,
  },
  {
    value: 3,
    label: "Station de type (c)",
    object:
      MesurePoussiereAtmospheriqueDto20NowTypeDEmplacementEnum.STATION_TYPE_C,
  },
];

const gaugeOrBooklet: string[] = ["Jauge", "Plaquette"];

export const selectPossibleValues = {
  locationType: locationType,
  gaugeOrBooklet: gaugeOrBooklet,
};
