export type MentionDanger =
  | "H340"
  | "H350"
  | "H350i"
  | "H360D"
  | "H360F"
  | "H341"
  | "H351";

export const mentionDangerList: MentionDanger[] = [
  "H340",
  "H350",
  "H350i",
  "H360D",
  "H360F",
  "H341",
  "H351",
];
