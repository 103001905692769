import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LEFT_WITHDRAW_STYLE, LONG_TEXT_INPUT_WIDTH } from "theme";
import {
  useBooleanCheckBoxGenerator,
  useChoiceSelectFieldGenerator,
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import {
  arrayMethods,
  MethodCombustionInfo,
} from "./utils/selectPossibleValues";
import {
  anySubPartActivated,
  facteurSubPartActivated,
  isCo2,
  matiereSubPartActivated,
  mesureSubPartActivated,
  singleEmissionValidationSchema,
} from "./validation/validation";
import MessageInfoField from "common/form/MessageInfoField";
import {
  requiredMessage,
  valeursConcentrationMessage,
} from "common/declarant/formik/formikMessages";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import { isSearchStringInCollection } from "common/utils/methods";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import {
  EmissionsInModale,
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { AppareilInArray } from "../blocAppareils/utils/types";
import { CombustibleInArray } from "../blocCombustibles/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import {
  computeEmissionsAnnuelles,
  shouldDisplayEcartEmission,
  updateEmission,
} from "./utils/utils";

interface FormEmissionProps {
  closeFunction: () => void;
  onSubmit: (
    values: EmissionsInModale,
    formikActions: FormikActions<EmissionsInModale>
  ) => void;
  initialEmission: EmissionsInModale;
  installationName: string | null;
  facteurEmissionsInArray: FacteurEmissionInArray[];
  matiereEmissionsInArray: MatiereEmissionInArray[];
  mesureEmissionsInArray: MesureEmissionInArray[];
  substancesAir: OptionPropsWithObject<ReferenceItemPolluantDto, string>[];
  appareilsInPage: AppareilInArray[];
  combustiblesInPage: CombustibleInArray[];
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
  mBottom: {
    marginBottom: "5px",
  },
  heightAuto: {
    height: "auto",
  },
  ...LEFT_WITHDRAW_STYLE,
});

const FormSingleEmission = ({
  closeFunction,
  onSubmit,
  initialEmission,
  installationName,
  substancesAir,
  combustiblesInPage,
  appareilsInPage,
  referentialSinglePolluants,
}: FormEmissionProps) => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emission-emission-individuelle",
  };
  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const ChoiceSelectField = useChoiceSelectFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const getAppareilsForInstallation = (
    allAppareils: AppareilInArray[],
    installationName: string
  ): AppareilInArray[] => {
    if (!allAppareils) {
      return [];
    }
    return (
      allAppareils.filter(appareil => {
        return appareil.data.nameInstallation === installationName;
      }) || []
    );
  };

  const getAppareilAsOptionProps = (
    installationName: string,
    combustible: OptionPropsWithObject<CombustibleInArray, string> | null
  ): OptionPropsWithObject<AppareilInArray, string>[] => {
    const currentAppareils = getAppareilsForInstallation(
      appareilsInPage,
      installationName || ""
    );
    const mapper = (
      appareil: AppareilInArray
    ): OptionPropsWithObject<AppareilInArray, string> => ({
      value: appareil.data.nom || "",
      label: appareil.data.nom || "",
      object: appareil,
    });
    if (combustible && combustible.object.data.appareils) {
      return currentAppareils
        .filter(appareil => {
          if (combustible.object.data.appareils === null) {
            throw Error("Should not happen");
          }
          return combustible.object.data.appareils.some(
            (a: OptionProps) => a.label === appareil.data.nom
          );
        })
        .map(mapper);
    }
    return currentAppareils.map(mapper);
  };

  const getCombustiblesForInstallation = (
    allCombustibles: CombustibleInArray[],
    installationName: string
  ): CombustibleInArray[] => {
    if (!allCombustibles) {
      return [];
    }
    return (
      allCombustibles.filter(combustible => {
        return combustible.data.nameInstallation === installationName;
      }) || []
    );
  };

  const getCombustibleAsOptionProps = (
    installationName: string
  ): OptionPropsWithObject<CombustibleInArray, string>[] => {
    const currentCombustibles = getCombustiblesForInstallation(
      combustiblesInPage,
      installationName
    );
    return currentCombustibles.map(combustible => {
      let label = "";
      if (combustible.data.type) {
        label = combustible.data.type.label;
        if (combustible.data.appareils) {
          label +=
            " (utilisé par " +
            combustible.data.appareils
              .map(ap => ap.object.data.nom)
              .join(", ") +
            ")";
        }
      }
      return {
        value: combustible.data.id,
        label: label,
        object: combustible,
      };
    });
  };

  const getMessageEcartEmission = (values: EmissionsInModale) => {
    if (
      values.combustible &&
      values.combustible.object &&
      values.combustible.object.data &&
      values.combustible.object.data.type &&
      values.substance
    ) {
      const combustible = values.combustible.object.data.type.object;
      let bornText: string;
      if (
        combustible.infCO2 !== null &&
        combustible.supCO2 !== null &&
        values.substance.label === referentialSinglePolluants.CO2.nom
      ) {
        bornText = `${combustible.infCO2} et ${combustible.supCO2}`;
      } else if (
        combustible.infSOX !== null &&
        combustible.supSOX !== null &&
        values.substance.label === referentialSinglePolluants.SOX.nom
      ) {
        bornText = `${combustible.infSOX} et ${combustible.supSOX}`;
      } else if (
        combustible.infNOX !== null &&
        combustible.supNOX !== null &&
        values.substance.label === referentialSinglePolluants.NOX.nom
      ) {
        bornText = `${combustible.infNOX} et ${combustible.supNOX}`;
      } else {
        return "";
      }
      return `La valeur du facteur d’émission du ${values.substance.label} déclarée pour le combustible ${values.combustible.object.data.type.label} ne correspond pas aux valeurs usuellement rencontrées qui sont comprises entre ${bornText}`;
    }
    return "";
  };

  const filterAppareilsForCombustible = (
    combustible: OptionPropsWithObject<CombustibleInArray, string>,
    appareils: OptionPropsWithObject<AppareilInArray, string>[] | null
  ) => {
    if (!appareils) {
      return [];
    }
    const combustibleAppareils = getAppareilAsOptionProps(
      installationName || "",
      combustible
    );
    return appareils.filter(appareil =>
      combustibleAppareils.some(
        otherAppareil => otherAppareil.value === appareil.value
      )
    );
  };

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE ÉMISSION"
      isEdit={initialEmission.facteurOxydation !== 100}
      closeFunction={closeFunction}
      onSubmit={(values: EmissionsInModale, formikBag) => {
        onSubmit(
          updateEmission(values, installationName, referentialSinglePolluants),
          formikBag
        );
      }}
      initialEntity={initialEmission}
      validationSchema={() =>
        singleEmissionValidationSchema(referentialSinglePolluants.CO2)
      }
      validate={values => {
        let errors = {};
        if (
          values.combustible &&
          values.combustible.object &&
          values.combustible.object.data &&
          values.combustible.object.data.type &&
          values.substance
        ) {
          if (
            shouldDisplayEcartEmission(values, referentialSinglePolluants) &&
            !values.ecart
          ) {
            errors = { ...errors, ecart: requiredMessage };
          }
        }
        return errors;
      }}
      renderField={({ setValues, values }) => {
        const resetFacteurOxydation = (
          newValue: OptionPropsWithObject<
            ReferenceItemPolluantDto,
            string
          > | null
        ) => {
          if (
            newValue &&
            newValue.label !== referentialSinglePolluants.CO2.nom
          ) {
            values.facteurOxydation = 100;
          }
        };
        const emissionsAnnuelles = computeEmissionsAnnuelles(values);
        const appareilsField = (
          combustible: OptionPropsWithObject<CombustibleInArray, string> | null
        ) => (
          <ChoiceSelectField
            name="appareils"
            label="Appareil(s) émetteur(s) *"
            options={getAppareilAsOptionProps(
              installationName || "",
              combustible
            )}
            isMulti
          />
        );

        return (
          <>
            <DummyTextField
              name="nameInstallation"
              label="Nom de l'installation"
              value={installationName}
              disabled
            />
            <WrappedChoiceSelectModale
              name="substance"
              label="Substance *"
              title="SUBSTANCES DANS L'AIR"
              header={["CAS", "Libellé", "Seuil (kg/an)"]}
              linesData={substancesAir}
              formatLine={substance => [
                substance.object.cas || "",
                substance.label,
                substance.object.seuilKgAn !== null
                  ? computeNumberWithSeparator(substance.object.seuilKgAn)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.label}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.object.cas, lineData.label],
                  searchedStr
                )
              }
              onSelect={resetFacteurOxydation}
              commonProps={commonProps}
            />
            <ChoiceSelectField
              name="methods"
              label="Choix de la méthode *"
              isMulti={false}
              options={arrayMethods}
              additionalOnChange={value => {
                // because ChoiceSelect are bad typed !!!!!! >_<
                const casted = value as OptionPropsWithObject<
                  MethodCombustionInfo,
                  number
                >;
                if (facteurSubPartActivated(casted) && values.combustible) {
                  setValues({
                    ...values,
                    methods: casted,
                    appareils: filterAppareilsForCombustible(
                      values.combustible,
                      values.appareils
                    ),
                  });
                }
              }}
            />
            {anySubPartActivated(values.methods) &&
              !facteurSubPartActivated(values.methods) &&
              appareilsField(null)}
            {facteurSubPartActivated(values.methods) && (
              <>
                <ChoiceSelectField
                  name="combustible"
                  label="Choix du combustible *"
                  isMulti={false}
                  options={getCombustibleAsOptionProps(installationName || "")}
                  additionalOnChange={combustible => {
                    // because ChoiceSelect are bad typed !!!!!! >_<
                    const casted = combustible as OptionPropsWithObject<
                      CombustibleInArray,
                      string
                    >;
                    setValues({
                      ...values,
                      combustible: casted,
                      appareils: filterAppareilsForCombustible(
                        casted,
                        values.appareils
                      ),
                    });
                  }}
                />

                {values.combustible && appareilsField(values.combustible)}

                <DummyTextField
                  name="referenceCombustible"
                  label="Référence du combustible"
                  value={
                    values.combustible && values.combustible.object
                      ? values.combustible.object.data.code
                      : ""
                  }
                  disabled
                />
                <DummyNumberField
                  name="consoAnnuelle"
                  label="Consommation annuelle"
                  value={
                    values.combustible && values.combustible.object
                      ? values.combustible.object.data.consommation
                      : null
                  }
                  unit={""}
                  disabled
                />
                <DummyTextField
                  name="unite"
                  label="Unité"
                  value={
                    values.combustible && values.combustible.object.data.unite
                      ? values.combustible.object.data.unite.label
                      : ""
                  }
                  disabled
                />
                <NumberField
                  name="facteur"
                  label="Facteur d'émission *"
                  unit="kg/GJ"
                />
                {shouldDisplayEcartEmission(
                  values,
                  referentialSinglePolluants
                ) && (
                  <>
                    <MessageInfoField
                      message={getMessageEcartEmission(values)}
                      additionalClassname={classes.heightAuto}
                    />
                    <TextField
                      name="ecart"
                      label="Préciser écart facteur d'émission *"
                    />
                  </>
                )}

                <TextField
                  name="provenance"
                  label="Provenance du facteur d'émission *"
                />
                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <>
                    <DummyNumberField
                      name="biomasseFacteur"
                      label="Fraction de la biomasse (%) *"
                      unit="%"
                      value={
                        values.combustible &&
                        values.combustible.object.data.biogazFraction
                      }
                      disabled
                    />
                    <NumberField
                      name="facteurOxydation"
                      label="Facteur d'oxydation du carbone *"
                      unit="%"
                    />
                    <TextField
                      name="provenanceFacteurOxydation"
                      label="Provenance du facteur d'oxydation du carbone"
                    />
                  </>
                )}
              </>
            )}
            {mesureSubPartActivated(values.methods) && (
              <>
                <NumberField
                  name="heures"
                  label="Nombre d'heures de fonctionnement *"
                  unit="h/an"
                  tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
                />
                <NumberField
                  name="debit"
                  label="Débit horaire moyen des effluents *"
                  unit="Nm³/h"
                />
                <BooleanField
                  name="continuDebit"
                  label="Mesure en continu du débit"
                />
                {values.continuDebit === false && (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceDebit"
                      label="Fréquence de la mesure du débit *"
                      unit="nb/an"
                    />
                  </div>
                )}
                <NumberField
                  name="concentrationDebit"
                  label="Concentration moyenne de polluant après traitement *"
                  unit="kg/Nm³"
                />
                <BooleanField
                  name="continuConcentration"
                  label="Mesure en continu de la concentration"
                />
                {values.continuConcentration === true ? (
                  <MessageInfoField message={valeursConcentrationMessage} />
                ) : (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name="frequenceConcentration"
                      label="Fréquence de la mesure de concentration *"
                      unit="nb/an"
                    />
                  </div>
                )}
                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <NumberField
                    name="biomasseMesure"
                    label="Fraction de la biomasse (%) *"
                    unit="%"
                  />
                )}
              </>
            )}
            {matiereSubPartActivated(values.methods) && (
              <>
                <TextField
                  name="intrants"
                  label="Description des intrants *"
                  tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                />
                <NumberField
                  name="entrant"
                  label="Quantités entrantes *"
                  unit="kg"
                />
                <NumberField
                  name="sortant"
                  label="Quantité sortantes hors émissions *"
                  unit="kg"
                  tooltipContent="Il convient ici de recenser toute quantité exportée ou sortant des limites de l'installation autrement que sous forme atmosphérique (produits, matériaux, mises à l'égout ou en décharge, épuration, pertes) et les variations des stocks dans les limites de l'installation."
                />
                <TextField
                  name="element"
                  label="Élément sur lequel est indexé le bilan matière *"
                  tooltipContent={
                    <span>
                      Exemple : Dans le cas de la détermination des émissions de
                      SO<sub>2</sub> issues de la combustion de fuel lourd ou de
                      charbon, l'élément sur lequel est indexé le calcul est la
                      teneur en soufre du combustible.{" "}
                    </span>
                  }
                />
                <NumberField
                  name="teneur"
                  label="Teneur moyenne de l'élément dans les intrants *"
                  unit="%"
                  tooltipContent={
                    <span>
                      Exemple : Dans le cas de la détermination des émissions de
                      SO<sub>2</sub> issues de la combustion de fuel lourd ou de
                      charbon, préciser la teneur massique en soufre du
                      combustible (valeur entre 0 et 100).
                    </span>
                  }
                />
                <NumberField
                  name="part"
                  label="Part de l'élément dans la substance émise *"
                  unit="%"
                  tooltipContent={
                    <span>
                      <p className={classes.mBottom}>
                        Exemple : Dans le cas de la détermination des émissions
                        de SO<sub>2</sub> issues de la combustion de fuel lourd
                        ou de charbon, préciser la part molaire du soufre sur
                        lequel est indexé le calcul dans la masse molaire totale
                        du SO<sub>2</sub>
                        soit 50,05 % (détail : 100 * Masse molaire de S / Masse
                        molaire de SO<sub>2</sub> = 100 * 32,066 / 64,065 =
                        50,05 %).
                      </p>
                      <p className={classes.mBottom}>
                        Aide : Principales masses molaires des éléments et
                        composés :
                        <ul className={classes.list}>
                          <li>
                            SO<sub>2</sub> dont la part de S est de 50,05 %
                            (32,066 / 64,065)
                          </li>
                          <li>
                            CO<sub>2</sub> dont la part de C est de 27,29 %
                            (12,011 / 44,010)
                          </li>
                          <li>
                            HCl dont la part de Cl est de 97,26 % (35,463 /
                            36,461)
                          </li>
                          <li>
                            HF dont la part de F est de 94,96 % (18,998 /
                            20,006)
                          </li>
                          <li>
                            voire les métaux lourds pour lesquels le ratio est
                            de 1.
                          </li>
                        </ul>
                      </p>
                      <p>
                        Remarque : il est essentiel pour les gros tonnages,
                        particulièrement ceux de CO2, que les ratios utilisés
                        soient le plus précis possibles tant les écarts dûs aux
                        arrondis peuvent rapidement être convertis en des
                        tonnages non négligeables.
                      </p>
                    </span>
                  }
                />
                {isCo2(values.substance, referentialSinglePolluants.CO2) && (
                  <NumberField
                    name="biomasseMatiere"
                    label="Fraction de la biomasse (%) *"
                    unit="%"
                  />
                )}
              </>
            )}
            {anySubPartActivated(values.methods) && (
              <>
                <BooleanField
                  name="epuration"
                  label="Les émissions font-elles l'objet d'épuration ?"
                />
                {values.epuration === true && (
                  <div className={classes.withdrawLeft}>
                    <TextField
                      name="nature"
                      label="Nature des équipements *"
                      tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                    />
                    <NumberField
                      name="rendement"
                      label="Rendement de l'épuration *"
                      unit="%"
                      tooltipContent="Le rendement global d'épuration pour tous les équipements est attendu."
                    />
                  </div>
                )}
                <DummyNumberField
                  name="emissionsAnnuelles"
                  label="Émissions annuelles"
                  unit="kg/an"
                  disabled
                  value={emissionsAnnuelles !== null ? emissionsAnnuelles : ""}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSingleEmission;
