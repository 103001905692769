import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import {
  DISABLED_BLOC_FILTER,
  LEFT_WITHDRAW_STYLE,
  SUBTITLE_STYLE,
} from "theme";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import BlocInstallation from "./BlocInstallation";
import ListInstallation from "./ListInstallation";
import InformationBloc from "./InformationBloc";
import classNames from "classnames";
import isEqual from "lodash.isequal";
import {
  ReferenceCombustibleDto,
  ReferenceItemPolluantElementDto,
  ReferencePolluantDto,
} from "api/gen";
import {
  convertInformationsToDisplayed,
  createAirCombustionsInformationDto,
} from "./InformationBloc/utils/utils";
import {
  convertInstallationsToDisplayed,
  createAirCombustionsInstallationDto,
} from "./BlocInstallation/utils/utils";
import {
  convertAppareilsToDisplayed,
  createAirCombustionsAppareilDto,
} from "./ListInstallation/BlocAppareils/utils/utils";
import {
  convertCombustiblesToDisplayed,
  createAirCombustionsCombustibleDto,
} from "./ListInstallation/BlocCombustibles/utils/utils";
import {
  convertEmissionsToDisplayed,
  createAirCombustionEmissionDto,
} from "./ListInstallation/BlocEmissions/utils/utils";
import { computeErrors, hasEqualDisplayed } from "./utils/utils";
import { InstallationInArray } from "./BlocInstallation/utils/types";
import { AppareilInArray } from "./ListInstallation/BlocAppareils/utils/types";
import { CombustibleInArray } from "./ListInstallation/BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./ListInstallation/BlocEmissions/utils/types";
import {
  PATH_AIR_COMBUSTION,
  PATH_AIR_COMBUSTION_CONTENT,
  PATH_AIR_COMBUSTION_INSTALLATION,
  PATH_AIR_COMBUSTION_RECAPITULATIF,
} from "common/path/path18Now";
import { ReferentialSinglePolluants } from "./utils/types";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
  useDeclarationHelpers2020,
} from "../../versionedElements/declarationHooks2020";
import GlobalFormActionFullContext2020 from "../../versionedElements/GlobalFormActionFullContext2020";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "10px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  disable: {
    filter: DISABLED_BLOC_FILTER,
    pointerEvents: "none",
  },
  message: {
    textAlign: "center",
    color: "black",
    fontSize: "18px",
    width: "100%",
  },
  ...LEFT_WITHDRAW_STYLE,
});

interface MainAirFormProps {
  referentialCombustible: ReferenceCombustibleDto;
  referentialAir: ReferencePolluantDto;
  referentialSinglePolluants: ReferentialSinglePolluants;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  referentialElementAutreUuid: string;
}

const MainAirForm = ({
  referentialCombustible,
  referentialAir,
  referentialSinglePolluants,
  polluantElementMap,
  referentialElementAutreUuid,
}: MainAirFormProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2020();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2020();
  const { cancelValidate } = useBasicDeclarationHandlers2020();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-air-combustion",
  };

  const blocCombustionDto = declaration.body.sections.air.combustion;

  //====================================
  // TYPE ACTIVITE
  //====================================
  const typeActiviteGlobal = useCorrectYearTypeActiviteGlobal20Now();

  //====================================
  // RECAPITULATIF
  //====================================
  const initialInformations = convertInformationsToDisplayed(
    blocCombustionDto.recapitulatif
  );
  const [informationsInPage, setInformationsInPage] = useState(
    initialInformations
  );

  //====================================
  //INSTALLATIONS
  //====================================
  const initialInstallations = blocCombustionDto.installations.map(
    installation => convertInstallationsToDisplayed(installation)
  );
  const [installationsInPage, setInstallationsInPage] = useState<
    InstallationInArray[]
  >(initialInstallations);

  //====================================
  //APPAREILS
  //====================================
  const initialAppareils = blocCombustionDto.content.appareils.map(appareil =>
    convertAppareilsToDisplayed(appareil)
  );
  const [appareilsInPage, setAppareilsInPage] = useState<AppareilInArray[]>(
    initialAppareils
  );

  //====================================
  //COMBUSTIBLES
  //====================================
  const initialCombustibles = blocCombustionDto.content.combustibles.map(
    combustible =>
      convertCombustiblesToDisplayed(
        combustible,
        referentialCombustible.referenceItemCombustibleDtoList,
        initialAppareils
      )
  );
  const [combustiblesInPage, setCombustiblesInPage] = useState<
    CombustibleInArray[]
  >(initialCombustibles);

  //====================================
  //EMISSIONS
  //====================================
  const [
    initialFacteurs,
    initialMesures,
    initialMatieres,
  ] = convertEmissionsToDisplayed(
    blocCombustionDto.content.emissions,
    initialCombustibles,
    referentialAir.polluants,
    initialAppareils,
    polluantElementMap
  );

  //============FACTEUR
  const [facteursInPage, setFacteursInPage] = useState<
    FacteurEmissionInArray[]
  >(initialFacteurs);

  //============MESURE
  const [mesuresInPage, setMesuresInPage] = useState<MesureEmissionInArray[]>(
    initialMesures
  );

  //============MATIERE
  const [matieresInPage, setMatieresInPage] = useState<
    MatiereEmissionInArray[]
  >(initialMatieres);

  const DummyBooleanField = useDummyBooleanCheckboxFieldGenerator({
    ...commonProps,
    disabled: true,
  });

  const isInstallationValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_INSTALLATION
  );
  const isInformationsValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_RECAPITULATIF
  );
  const isContentValidated = isPathValidatedInDeclaration(
    PATH_AIR_COMBUSTION_CONTENT
  );

  const computedInstallations: InstallationInArray[] = installationsInPage.map(
    installation => {
      return {
        data: installation.data,
        errors: computeErrors(
          installation,
          informationsInPage,
          typeActiviteGlobal
        ),
      };
    }
  );

  const hasError = computedInstallations.find(
    installation => !isEqual(installation.errors, {})
  );
  if (hasError && isInstallationValidated) {
    cancelValidate(PATH_AIR_COMBUSTION_INSTALLATION);
  }

  const canSubmitForm = () => {
    return (
      (installationsInPage.length === 0 &&
        isInformationsValidated &&
        isInstallationValidated) ||
      (isInstallationValidated && isInformationsValidated && isContentValidated)
    );
  };

  return (
    <>
      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>
          DONNÉES HÉRITÉES DU BLOC INFORMATIONS GÉNÉRALES
        </h3>
      </Row>
      <DummyBooleanField
        name="hasCombustionInstallation"
        label="L'établissement possède une ou plusieurs installations de combustion d'une puissance supérieure à 20 MW"
        value={typeActiviteGlobal.combustion20MW}
      />
      <Row height={"12px"} />
      <DummyBooleanField
        name="receivesWaste"
        label="L'établissement réceptionne / traite / stocke des déchets"
        value={typeActiviteGlobal.recepDechet}
      />
      <div className={classes.withdrawLeft}>
        <Row height={"12px"} />
        <DummyBooleanField
          name="hasWasteBurningInstallation"
          label="L'établissement possède une ou plusieurs installations d'incinération ou de co-incinération de déchets"
          value={typeActiviteGlobal.incinerationDechet}
        />
        <Row height={"12px"} />
        <DummyBooleanField
          name="hasOneOrManyISDND"
          label="L'établissement possède une ou plusieurs ISDND"
          value={typeActiviteGlobal.isdnd}
        />
        <div className={classes.withdrawLeft}>
          <Row height={"12px"} />
          <DummyBooleanField
            name="hasBiogazTorcheres"
            label="L'ISDND possède une ou plusieurs torchères de biogaz"
            value={typeActiviteGlobal.torchereBiogaz}
          />
          <Row height={"12px"} />
          <DummyBooleanField
            name="hasBiogazValorisationInstallation"
            label="L'ISDND dispose d'un système de valorisation du biogaz"
            value={typeActiviteGlobal.valorisationBiogaz}
          />
        </div>
      </div>
      <Row />
      <InformationBloc
        validationInformationPath={PATH_AIR_COMBUSTION_RECAPITULATIF}
        initialInformations={initialInformations}
        informationsInPage={informationsInPage}
        setInformationsInPage={setInformationsInPage}
        lcpDisabled={!typeActiviteGlobal.combustion20MW}
        wiCowiDisabled={!typeActiviteGlobal.incinerationDechet}
      />
      <Row />
      <div>
        {!isInformationsValidated && (
          <>
            <div className={classes.message}>
              Veuillez valider les informations complémentaires afin que ce bloc
              soit accessible.
            </div>
            <Row height={"20px"} />
          </>
        )}
        <div
          className={classNames({
            [classes.disable]: !isInformationsValidated,
          })}
        >
          <BlocInstallation
            initialInstallations={initialInstallations}
            installationsInPage={computedInstallations}
            setInstallationsInPage={setInstallationsInPage}
            validationInstallationPath={PATH_AIR_COMBUSTION_INSTALLATION}
            isLcp={informationsInPage.lcp}
            isWicowi={informationsInPage.wiCoWi}
            appareilsInPage={appareilsInPage}
            setAppareilsInPage={setAppareilsInPage}
            combustiblesInPage={combustiblesInPage}
            setCombustiblesInPage={setCombustiblesInPage}
            facteursInPage={facteursInPage}
            setFacteursInPage={setFacteursInPage}
            mesuresInPage={mesuresInPage}
            setMesuresInPage={setMesuresInPage}
            matieresInPage={matieresInPage}
            setMatieresInPage={setMatieresInPage}
            typeActiviteGlobal={typeActiviteGlobal}
          />
        </div>
      </div>
      <Row />
      <div>
        {!isInstallationValidated || !isInformationsValidated ? (
          <>
            <div className={classes.message}>
              Veuillez valider les informations complémentaires et la
              déclaration des installations afin que ce bloc soit accessible.
            </div>
            <Row height={"20px"} />
          </>
        ) : null}
        <div
          className={classNames({
            [classes.disable]:
              !isInformationsValidated ||
              !isInstallationValidated ||
              installationsInPage.length === 0,
          })}
        >
          <ListInstallation
            installationsList={installationsInPage}
            validationContentPath={PATH_AIR_COMBUSTION_CONTENT}
            initialAppareils={initialAppareils}
            appareilsInPage={appareilsInPage}
            setAppareilsInPage={setAppareilsInPage}
            initialCombustibles={initialCombustibles}
            combustiblesInPage={combustiblesInPage}
            setCombustiblesInPage={setCombustiblesInPage}
            initialFacteurs={initialFacteurs}
            facteursInPage={facteursInPage}
            setFacteursInPage={setFacteursInPage}
            initialMesures={initialMesures}
            mesuresInPage={mesuresInPage}
            setMesuresInPage={setMesuresInPage}
            initialMatieres={initialMatieres}
            matieresInPage={matieresInPage}
            setMatieresInPage={setMatieresInPage}
            combustibleReferential={referentialCombustible}
            isTorchereBiogaz={typeActiviteGlobal.torchereBiogaz || false}
            isValorisationBiogaz={
              typeActiviteGlobal.valorisationBiogaz || false
            }
            substancesAir={referentialAir.polluants}
            referentialSinglePolluants={referentialSinglePolluants}
            polluantElementMap={polluantElementMap}
            referentialElemenAutreUuid={referentialElementAutreUuid}
          />
        </div>
      </div>
      <GlobalFormActionFullContext2020
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={
          !isEqual(initialAppareils, appareilsInPage) ||
          !isEqual(initialCombustibles, combustiblesInPage) ||
          !isEqual(initialFacteurs, facteursInPage) ||
          !isEqual(initialMesures, mesuresInPage) ||
          !isEqual(initialMatieres, matieresInPage) ||
          !isEqual(initialInformations, informationsInPage) ||
          !hasEqualDisplayed(initialInstallations, installationsInPage)
        }
        isValidateEnabled={canSubmitForm()}
        validationPath={PATH_AIR_COMBUSTION}
        updateHandler={declaration => {
          declaration.body.sections.air.combustion.recapitulatif = createAirCombustionsInformationDto(
            informationsInPage
          );
          declaration.body.sections.air.combustion.installations = createAirCombustionsInstallationDto(
            installationsInPage
          );
          declaration.body.sections.air.combustion.content.emissions = createAirCombustionEmissionDto(
            [facteursInPage, mesuresInPage, matieresInPage]
          );
          declaration.body.sections.air.combustion.content.combustibles = createAirCombustionsCombustibleDto(
            combustiblesInPage
          );
          declaration.body.sections.air.combustion.content.appareils = createAirCombustionsAppareilDto(
            appareilsInPage
          );
          return declaration;
        }}
        cancelAction={() => {
          setInformationsInPage(initialInformations);
          setInstallationsInPage(initialInstallations);
          setAppareilsInPage(initialAppareils);
          setCombustiblesInPage(initialCombustibles);
          setFacteursInPage(initialFacteurs);
          setMesuresInPage(initialMesures);
          setMatieresInPage(initialMatieres);
        }}
      />
    </>
  );
};

export default MainAirForm;
