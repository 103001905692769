import React from "react";
import * as Yup from "yup";
import {
  familiesRecyclingValidationSchema,
  validateFamiliesForRecyclingProductsInArray,
} from "../Families/validation";
import {
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { isNatureDefinedAsOther } from "./utils/utils";
import {
  PopulatedRecyclingProductInArray,
  RecyclingProductProps,
} from "./utils/types";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import uuid from "uuid";
import { natures } from "./utils/selectPossibleValues";
import {
  handleFamiliesReclyclingCommonFormSingleEntitySubmit,
  onSelectFamilyHandler,
  onSelectSubFamilyHandler,
  onSelectSubFamilyLvl2Handler,
} from "../Families/utils";
import DeprecatedCommonFormSingleEntity from "common/declarant/DeprecatedCommonFormSingleEntity";
import {
  AMOUNT_PRODUCTION,
  PRECISE_OTHER,
  PRODUCTS_NATURE,
} from "./utils/languages";
import {
  PRECISION_SUB_USAGE_FAMILY,
  PRECISION_USAGE_FAMILY,
  SUB_USAGE_FAMILY,
  SUB_USAGE_FAMILY_LEVEL_2,
  USAGE_FAMILY,
} from "../Families/languages";
import {
  familiesRecycling,
  subFamiliesRecycling,
  subFamiliesRecyclingLvl2,
} from "../Families/selectPossibleValues";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import {
  ReferenceFamilleUsageRecyclageDto,
  ReferenceItemFamilleUsageRecyclageDto,
} from "api/gen";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";

interface RecyclingProductModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  recyclingProductInModal: PopulatedRecyclingProductInArray | undefined;
  setRecyclingProductInModal: (
    product: PopulatedRecyclingProductInArray | undefined
  ) => void;
  recyclingProductsInArray: PopulatedRecyclingProductInArray[];
  setRecyclingProductsInArray: (
    products: PopulatedRecyclingProductInArray[]
  ) => void;
  referentialFamilies: ReferenceFamilleUsageRecyclageDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const validationSchema = familiesRecyclingValidationSchema({
  nature: commonObjectFields,
  naturePrecision: Yup.string().when("nature", {
    is: (nature: OptionProps | null) => isNatureDefinedAsOther(nature?.label),
    then: commonStringFields,
    otherwise: Yup.string().nullable(),
  }),
  productionQuantity: commonPositiveNumberFields,
});

const RecyclingProductModal = ({
  isOpen,
  setIsModalOpen,
  recyclingProductInModal,
  setRecyclingProductInModal,
  recyclingProductsInArray,
  setRecyclingProductsInArray,
  referentialFamilies,
}: RecyclingProductModalProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "modal-activite-recyclage",
  };

  const ChoiceSelect = useChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextField = useTextFieldGenerator(commonProps);

  const onClose = () => {
    setRecyclingProductInModal(undefined);
    setIsModalOpen(false);
  };

  const initialValue: Partial<RecyclingProductProps> = _.get(
    recyclingProductInModal,
    "displayedProduct",
    {}
  );

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <DeprecatedCommonFormSingleEntity
        title="AJOUTER UN PRODUIT À RECYCLER"
        validationSchema={validationSchema}
        initialEntity={initialValue}
        closeFunction={onClose}
        onSubmit={values => {
          handleFamiliesReclyclingCommonFormSingleEntitySubmit(values);
          const product: PopulatedRecyclingProductInArray = {
            id: recyclingProductInModal ? recyclingProductInModal.id : uuid(),
            displayedProduct: {
              nature: values.nature,
              naturePrecision: values.naturePrecision,
              productionQuantity: values.productionQuantity,
              usageFamily: values.usageFamily,
              usageFamilyPrecision: values.usageFamilyPrecision,
              usageSubFamily: values.usageSubFamily,
              usageSubFamilyPrecision: values.usageSubFamilyPrecision,
              usageSubFamiliesLvl2: values.usageSubFamiliesLvl2,
              usageSubFamiliesLvl2Precisions:
                values.usageSubFamiliesLvl2Precisions,
            },
          };
          const newRecyclingProductsInArray = recyclingProductsInArray;
          let indexToReplace = newRecyclingProductsInArray.length;
          if (recyclingProductInModal) {
            indexToReplace = _.findIndex(
              newRecyclingProductsInArray,
              recyclingProductInModal
            );
            setRecyclingProductInModal(undefined);
          }
          newRecyclingProductsInArray[indexToReplace] = product;
          setRecyclingProductsInArray(newRecyclingProductsInArray);
          setIsModalOpen(false);
        }}
        validate={values =>
          validateFamiliesForRecyclingProductsInArray(
            values,
            recyclingProductsInArray
          )
        }
        renderField={({ values, setFieldValue }) => (
          <>
            <ChoiceSelect
              label={`${PRODUCTS_NATURE} *`}
              name="nature"
              isMulti={false}
              options={natures}
            />

            {values.nature && isNatureDefinedAsOther(values.nature.label) && (
              <TextField label={`${PRECISE_OTHER} *`} name="naturePrecision" />
            )}

            <NumberField
              label={`${AMOUNT_PRODUCTION} *`}
              name="productionQuantity"
              unit="ktonnes"
            />

            <ChoiceSelect
              label={`${USAGE_FAMILY} *`}
              name="usageFamily"
              isMulti={false}
              options={familiesRecycling(referentialFamilies)}
              additionalOnChange={() => onSelectFamilyHandler(setFieldValue)}
            />
            {values.usageFamily && values.usageFamily.object.precision && (
              <TextField
                label={`${PRECISION_USAGE_FAMILY} *`}
                name="usageFamilyPrecision"
              />
            )}
            {values.usageFamily &&
              values.usageFamily.object.referenceItemFamilleUsageDtos.length >
                0 && (
                <ChoiceSelect
                  label={`${SUB_USAGE_FAMILY} *`}
                  name="usageSubFamily"
                  isMulti={false}
                  options={subFamiliesRecycling(values.usageFamily)}
                  additionalOnChange={() =>
                    onSelectSubFamilyHandler(setFieldValue)
                  }
                />
              )}
            {values.usageSubFamily &&
              values.usageSubFamily.object.precision && (
                <TextField
                  label={`${PRECISION_SUB_USAGE_FAMILY} *`}
                  name="usageSubFamilyPrecision"
                />
              )}
            {values.usageSubFamily &&
              values.usageSubFamily.object.referenceItemFamilleUsageDtos
                .length > 0 && (
                <ChoiceSelect
                  label={`${SUB_USAGE_FAMILY_LEVEL_2} *`}
                  name="usageSubFamiliesLvl2"
                  isMulti
                  options={subFamiliesRecyclingLvl2(values.usageSubFamily)}
                  additionalOnChange={selected =>
                    onSelectSubFamilyLvl2Handler(
                      setFieldValue,
                      // because we KNOW this choiceSelect has a isMulti attribute
                      selected as OptionPropsWithObject<
                        ReferenceItemFamilleUsageRecyclageDto
                      >[],
                      values
                    )
                  }
                />
              )}
            <FieldArray
              name="usageSubFamiliesLvl2Precisions"
              render={() => (
                <>
                  {values.usageSubFamiliesLvl2 &&
                    values.usageSubFamiliesLvl2
                      .filter(subFamilyLvl2 => subFamilyLvl2.object.precision)
                      .map((subFamilyLvl2, i) => {
                        return (
                          <TextField
                            label={`${PRECISION_SUB_USAGE_FAMILY} ${subFamilyLvl2.label} *`}
                            name={`usageSubFamiliesLvl2Precisions.${i}.precision`}
                            key={subFamilyLvl2.value}
                          />
                        );
                      })}
                </>
              )}
            />
          </>
        )}
      />
    </CustomModal>
  );
};

export default RecyclingProductModal;
