import React, { useState } from "react";
import Row from "common/presentational/Row";
import { Link } from "react-router-dom";
import { SimpleInstallationDto } from "api/gen";
import { PATH_DASHBOARD } from "common/path/path18Now";
import eye from "icons/eye.svg";
import email from "icons/email.svg";
import download from "icons/download.svg";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import Spinner from "common/presentational/Spinner";
import VerificateurDownloadModal from "./VerificateurDownloadModal";

interface DeclarationActionButtonsProps {
  declaration: SimpleInstallationDto;
  onContactInfosClick: (declaration: SimpleInstallationDto) => void;
}

const VerificateurActionButtons = ({
  declaration,
  onContactInfosClick,
}: DeclarationActionButtonsProps): React.ReactElement => {
  const [isExportLoadingVisible, triggerExportLoading] = useSpinnerState<
    boolean
  >();
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  return (
    <Row height="25px">
      <Link
        to={`/declaration/${declaration.annee}/${declaration.etablissement}${PATH_DASHBOARD}`}
        title="Consulter"
      >
        <img src={eye} alt="Consulter" width="25px" />
      </Link>
      <input
        type="image"
        src={email}
        title="Fiche contact"
        alt="Fiche contact"
        width="25px"
        onClick={() => onContactInfosClick(declaration)}
      />
      {isExportLoadingVisible ? (
        <Spinner />
      ) : (
        <input
          type="image"
          src={download}
          title="Télécharger"
          alt="Télécharger"
          width="25px"
          onClick={() => {
            setIsDownloadModalOpen(true);
          }}
        />
      )}
      <VerificateurDownloadModal
        isOpen={isDownloadModalOpen}
        handleClose={() => setIsDownloadModalOpen(false)}
        declaration={declaration}
        triggerExportLoading={triggerExportLoading}
      />
    </Row>
  );
};

export default VerificateurActionButtons;
