import React, { Dispatch, SetStateAction, useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import CovArray from "./CovArray";
import { CovInArray } from "./utils/types";
import CovModale from "./CovModale";
import { convertCovInArrayToDto } from "./utils/utils";
import { cloneDeep, isEqual } from "lodash";
import {
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "../Emission/utils/utils";
import { useConfirmationModale } from "common/modale/hooks";
import { ProcedeInArray } from "../ListProcede/utils/types";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../Emission/utils/types";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import { compareCovFunction } from "../utils/compareMethod";
import { BlocFullContext24Now } from "../../../versionedElements/BlocFullContext24Now";
import { Declaration24Now } from "../../../versionedElements/declarationHooks24Now";

interface CovDangerProps {
  procedesInPageMap: Map<string, ProcedeInArray>;
  initialCovs: CovInArray[];
  covsInPage: CovInArray[];
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  mesureEmissionsInPage: MesureEmissionInArray[];
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  validationCovPath: string;
}

const CovDanger = ({
  validationCovPath,
  procedesInPageMap,
  initialCovs,
  covsInPage,
  setCovsInPage,
  mesureEmissionsInPage,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
}: CovDangerProps): React.ReactElement => {
  const openConfirmationModale = useConfirmationModale();

  const [covModaleOpen, setCovModaleOpen] = useState(false);
  const [covInModale, setCovInModale] = useState<CovInArray | null>(null);

  const updateHandler = (declaration: Declaration24Now) => {
    declaration.body.sections.air.fugitives.flux = convertCovInArrayToDto(
      covsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
      correlationEmissionsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
      mesureEmissionsInPage,
      procedesInPageMap
    );
    declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
      matiereEmissionsInPage,
      procedesInPageMap
    );
    return declaration;
  };

  const covnmInArray = cloneDeep(covsInPage).sort((a, b) =>
    compareCovFunction(a, b, procedesInPageMap)
  );

  return (
    <>
      <BlocFullContext24Now
        title="Émissions de COV à mention de danger"
        hasModification={!isEqual(initialCovs, covsInPage)}
        isValidateButtonAvailable={true}
        validationMessage={{
          message: TABS_VALIDATION_MESSAGE,
          isAlwaysVisible: true,
        }}
        path={validationCovPath}
        areGlobalCommentsAllowed={true}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setCovsInPage(initialCovs)}
        renderContent={shouldDisableFields => {
          return (
            <>
              <p>
                Substances ou mélanges auxquels sont attribuées ou sur lesquels
                doivent être apposées les mentions de dangers H340, H350, H350i,
                H360D ou H360F par le règlement 1272/2008/CE du Conseil et/ou
                émettant des composés organiques volatils halogénés auxquels est
                attribuée, ou sur lesquels doit être apposée la mention de
                danger H341 ou H351 (anciennes phrases de risques R40, R45, R46,
                R49, R60, R61 ou R68)
              </p>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER DES ÉMISSIONS"
                  onClick={() => {
                    setCovModaleOpen(true);
                  }}
                  isDisabled={shouldDisableFields}
                />
              </Row>
              <Row />
              <CovArray
                covsInPage={covnmInArray}
                setCovsInPage={setCovsInPage}
                setCovInModale={setCovInModale}
                setCovModaleOpen={setCovModaleOpen}
                isValidated={shouldDisableFields}
                validationCovPath={validationCovPath}
                procedesInPageMap={procedesInPageMap}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () => setCovsInPage([])
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisableFields || covsInPage.length === 0}
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <CovModale
        covModaleOpen={covModaleOpen}
        covInModale={covInModale}
        setCovsInPage={setCovsInPage}
        setCovInModale={setCovInModale}
        setCovModaleOpen={setCovModaleOpen}
        procedesInPageMap={procedesInPageMap}
      />
    </>
  );
};

export default CovDanger;
