import {
  QuotasInstallationDto1819CategorieEnum,
  ReferenceActiviteQuotaDto,
  ReferenceItemActiviteQuotaDto,
} from "api/gen/api";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { InstallationSelectOptions } from "./types";
import { computeOptionProps } from "common/utils/enumOptionsPropsConverter";

const categoriesInstallations = (): OptionProps[] =>
  computeOptionProps(QuotasInstallationDto1819CategorieEnum, false);

const activitesInstallations = (
  referential: ReferenceActiviteQuotaDto
): OptionPropsWithObject<ReferenceItemActiviteQuotaDto>[] => {
  const activites = referential.referenceItemActiviteQuotaDtoList.map(
    (activite, i) => ({
      value: i,
      label: activite.description,
      object: activite,
    })
  );
  return [...activites];
};

export const selectPossibleValues = (
  referentialActivites: ReferenceActiviteQuotaDto
): InstallationSelectOptions => ({
  categories: categoriesInstallations(),
  activites: activitesInstallations(referentialActivites),
});
