import * as Yup from "yup";
import {
  commonNumberFields,
  commonObjectFields,
  commonPositiveNumberFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import {
  isBiomasseSubPartActivated,
  isOtherEmissionSubPartActivated,
} from "../utils/utils";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { GasOptions } from "../types";

export const validationSchema = Yup.object().shape({
  methodDescription: commonStringFields,
  nimOptionProps: commonObjectFields,
  gas: commonObjectFields,
  fossilEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      gas && isBiomasseSubPartActivated(gas),
    then: commonNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  biomassEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      gas && isBiomasseSubPartActivated(gas),
    then: commonNumberFields,
    otherwise: Yup.number().nullable(),
  }),
  otherEmission: Yup.number().when("gas", {
    is: (gas: OptionPropsWithObject<GasOptions> | null) =>
      gas && isOtherEmissionSubPartActivated(gas),
    then: commonPositiveNumberFields,
    otherwise: Yup.number().nullable(),
  }),
});
