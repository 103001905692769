import React, { ReactElement, ReactNode } from "react";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    marginBottom: "20px",
    justifyContent: "flex-start",
    "& button": {
      marginLeft: "20px",
    },
  },
});

const FirstLineButtonsContainer = ({
  children,
  additionalClassName,
}: {
  children: ReactNode;
  additionalClassName: string;
}): ReactElement => {
  const classes = useStyles();

  return (
    <Row
      additionalClassname={classNames(classes.container, additionalClassName)}
    >
      {children}
    </Row>
  );
};

export default FirstLineButtonsContainer;
