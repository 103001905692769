import React from "react";
import { makeStyles } from "@material-ui/styles";
import { QuotasFluxDerogationDto2121 } from "api/gen";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import Row from "common/presentational/Row";
import Button from "common/button";
import { FIELD_FONT_SIZE, FIELD_FONT_WEIGHT, SECTION_TITLE_GREY } from "theme";

const useStyles = makeStyles({
  inputField: {
    display: "flex",
  },
  additionalRowFluxStyle: {
    justifyContent: "center",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  additionalRowButtonStyle: {
    marginLeft: "30%",
  },
  text: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "left",
    alignSelf: "left",
    width: "70%",
    marginBottom: "15px",
  },
});

interface FluxDerogationListFormProps {
  derogationInstallation: QuotasFluxDerogationDto2121;
  nameFieldArray: string;
  shouldDisableFields: boolean;
}

const FluxDerogationListForm = ({
  derogationInstallation,
  nameFieldArray,
  shouldDisableFields,
}: FluxDerogationListFormProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "40%",
    formPrefix: "flux-derogation-list",
  };

  const TextField = useTextFieldGenerator(commonProps);

  return (
    <FieldArray
      name={nameFieldArray}
      render={arrayHelpers => (
        <div className={classes.itemContainer}>
          <span className={classes.text}>
            Si oui, indiquer les flux concernés par la dérogation
          </span>
          {derogationInstallation.fluxSoumisADerogation.map(
            (flux, fluxIndex) => (
              <>
                <Row
                  key={fluxIndex}
                  additionalClassname={classes.additionalRowFluxStyle}
                >
                  <TextField
                    label={`Flux ${fluxIndex + 1}`}
                    name={`${nameFieldArray}.${fluxIndex}`}
                    disabled={shouldDisableFields}
                  />
                </Row>
                {derogationInstallation.fluxSoumisADerogation.length > 1 && (
                  <Row additionalClassname={classes.additionalRowButtonStyle}>
                    <Button
                      text="Supprimer le flux"
                      isDisabled={shouldDisableFields}
                      onClick={() => {
                        arrayHelpers.remove(fluxIndex);
                      }}
                    />
                  </Row>
                )}
              </>
            )
          )}
          <Row additionalClassname={classes.additionalRowButtonStyle}>
            <Button
              text="Ajouter un flux"
              isDisabled={shouldDisableFields}
              onClick={() => {
                arrayHelpers.push("");
              }}
            />
          </Row>
        </div>
      )}
    />
  );
};

export default FluxDerogationListForm;
