import React from "react";
import logoWaste from "icons/pageLogos/garbage.svg";
import WasteController from "./WasteController";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { RouteIdProps } from "common/declarant/type";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import { wrapWithWorkflowTargetProvider } from "../../../WorkflowTargetProviderContext";

const WasteDeclaration = ({ match }: RouteIdProps): React.ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Déchets",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoWaste,
    alt: "un déchet",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Déchets",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <ReferentialFetcher
      wantedKeys={[
        "REFERENCE_DECHET",
        "REFERENCE_DEPARTEMENTS",
        "REFERENCE_PAYS",
      ]}
      render={referentials => {
        return (
          <WasteController
            referentialDechet={referentials.REFERENCE_DECHET}
            referentialDepartements={referentials.REFERENCE_DEPARTEMENTS}
            referentielPays={referentials.REFERENCE_PAYS}
          />
        );
      }}
    />
  );
};

export default wrapWithWorkflowTargetProvider(WasteDeclaration, "GLOBAL");
