import {
  ActiviteDto2122,
  AdministratifDto20Now,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { IdentifiedData } from "common/form/utils";

export type AdministratifType = Omit<
  AdministratifDto20Now,
  "commune" | "pays"
> & {
  commune: string | ReferenceItemGeoCodeInseeDto | null;
  pays: ReferenceItemPaysDto | null;
};

export const administratifFieldMatcher = fieldMatcherBuilder<
  AdministratifType
>().build({
  socialReason: "raisonSociale",
  parentCompany: "societeMere",
  legalStatus: "formeJuridique",
  adress: "adresse",
  postalCode: "codePostal",
  country: "pays",
  city: "commune",
  SIRENNumber: "siren",
});

export type ActivityType = Omit<
  ActiviteDto2122,
  "installationCity" | "codeNafID" | "systemeCoordonnees"
> & {
  installationCity: ReferenceItemGeoCodeInseeDto | null;
  codeNaf: ReferenceItemCodeApeDto | null;
  systemeCoordonnees: ReferenceItemCoordonneesDepartementDto | null;
};

export const activiteFieldMatcher = fieldMatcherBuilder<ActivityType>().build({
  inspectionCode: "codeInspection",
  inspectionService: "identifiantService",
  establishmentName: "nomEtablissement",
  installationAdress: "installationAdresse",
  installationPostalCode: "installationCodePostal",
  installationCity: "installationCity",
  SIRETNumber: "siret",
  NAFCode: "codeNaf",
  systemCoordinates: "systemeCoordonnees",
  longitude: "abscisse",
  latitude: "ordonnee",
  productionVolume: "volumeProduction",
  workDuration: "nombreHeureDeFonctionnement",
  unit: "unite",
  producedMatter: "matiereProduite",
  otherProducedMatter: "autreMatiereProduitePrecision",
  nombreInstallations: "nombreInstallations",
  website: "website",
  employee: "employe",
  additionalInfo: "informationsComplementaires",
});

export type CoordonneesDepartementMap = Map<
  string,
  Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  >
>;

export const franceOption: ReferenceItemPaysDto = {
  restorationCode: -1,
  codeInsee: -1,
  ue: true,
  designation: "FRANCE",
  uuid: "",
};

export interface InspectionServiceDto extends IdentifiedData {
  libelle: string;
}

export const AUTRE_MATIERE_PRODUITE = "Autre";
