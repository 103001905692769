import * as Yup from "yup";
import { procedeFieldMatcher, ProcedeInModale } from "../utils/types";
import {
  commonMixedFields,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStringFieldsNoPipeAllowed,
  subStringFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import { AirFugitivesProcedesDtoUniteEnum } from "api/gen";

export const singleProcedeHasSubPartActivated = (
  procedeType: AirFugitivesProcedesDtoUniteEnum | null
): boolean => {
  return procedeType === AirFugitivesProcedesDtoUniteEnum.AUTRE;
};

export const singleProcedeOverwriteDependantFields = (
  procede: ProcedeInModale
): void => {
  if (!singleProcedeHasSubPartActivated(procede.unite)) {
    procede.precision = null;
  }
};

//TODO [GEREP-2579] il faudra remettre le shape ProcedeInModale ici.
export const singleProcedeValidationSchema = Yup.object().shape({
  nom: commonStringFieldsNoPipeAllowed,
  quantite: commonPositiveNumberFields,
  unite: commonMixedFields<AirFugitivesProcedesDtoUniteEnum>(),
  precision: subStringFieldValidationScheme(
    procedeFieldMatcher.unit,
    singleProcedeHasSubPartActivated
  ),
  masse: commonPositiveNumberFieldsNullable,
});
