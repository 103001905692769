import { SHORT_TEXT_INPUT_WIDTH } from "theme";
import * as Yup from "yup";
import Row from "common/presentational/Row";
import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useChoiceSelectFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { CommonConnectedFormikBlocProps } from "common/declarant/type";
import {
  commonObjectFields,
  commonPositiveIntegerNumberFieldsNullable,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { ActivityValues } from "../utils/types";
import { ReferenceItemCodeApeDto, ReferenceItemGeoCodeInseeDto } from "api/gen";
import { Nullable } from "common/utils/types";
import { LocationHelper } from "./LocationHelper";
import {
  OptionProps,
  OptionPropsWithObject,
} from "common/form/fields/types/basicTypes";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  codePostalMessage,
  hoursLengthMessage,
  positiveMessage,
  siretLengthMessage,
  siretNumberMessage,
} from "common/declarant/formik/formikMessages";
import { isSearchStringInCollection } from "common/utils/methods";
import FormikBlocFullContext1919 from "../../versionedElements/FormikBlocFullContext1919";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  additionalInfo: {
    height: "100px",
  },
});

export const units: OptionProps[] = [
  { value: 1, label: "pièces" },
  { value: 2, label: "kg" },
  { value: 3, label: "t" },
  { value: 4, label: "kWh" },
  { value: 5, label: "MWh" },
  { value: 6, label: "GWh" },
  { value: 7, label: "kW" },
  { value: 8, label: "MW" },
  { value: 9, label: "GW" },
  { value: 10, label: "GJ" },
  { value: 11, label: "L" },
  { value: 12, label: "hL" },
  { value: 13, label: "m3" },
  { value: 14, label: "m2" },
];

const validationSchema = Yup.object().shape({
  inspectionCode: commonStringFields,
  establishmentName: commonStringFields,
  installationAdress: commonStringFields,
  installationPostalCode: commonStringFields.length(5, codePostalMessage),
  installationCity: commonObjectFields,
  SIRETNumber: commonStringFields
    .length(14, siretLengthMessage)
    .matches(/^[0-9]*$/, siretNumberMessage),
  NAFCode: commonObjectFields,
  longitude: commonStringFields,
  latitude: commonStringFields,
  workDuration: commonPositiveIntegerNumberFieldsNullable.max(
    8784,
    hoursLengthMessage
  ),
});

interface CompanyActivityProps
  extends CommonConnectedFormikBlocProps<
    Nullable<ActivityValues>,
    Declaration1919
  > {
  referentielINSEE: OptionPropsWithObject<ReferenceItemGeoCodeInseeDto>[];
  referentielAPE: OptionPropsWithObject<ReferenceItemCodeApeDto>[];
}

export const CompanyActivity = (
  props: CompanyActivityProps
): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "company-activity",
  };

  const TextField = useTextFieldGenerator(commonProps);
  const DummyTextField = useDummyTextFieldGenerator(commonProps);
  const SelectField = useChoiceSelectFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);

  const locationHelper = useMemo(() => {
    return <LocationHelper />;
  }, []);

  return (
    <FormikBlocFullContext1919
      formikRef={props.formikRef}
      hasChanges={props.hasChanges}
      setHasChanges={props.setHasChanges}
      initialValues={props.initialValues}
      validationSchema={validationSchema}
      additionalFieldValidation={values => {
        if (
          values.employee !== null &&
          values.employee !== undefined &&
          values.employee < 0
        ) {
          return {
            employee: positiveMessage,
          };
        }
        return {};
      }}
      title={"INFORMATIONS RELATIVES A L'ÉTABLISSEMENT"}
      pathToValidate={props.path}
      updateHandler={props.updateHandler}
      renderContent={(formProps, shouldDisabledFields) => {
        return (
          <>
            <TextField
              name="inspectionCode"
              label="Code inspection *"
              disabled={true}
            />
            <TextField
              name="establishmentName"
              label="Nom de l'établissement *"
              disabled={shouldDisabledFields}
            />
            <TextField
              name="installationAdress"
              label="Adresse du site *"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"installationCity"}
              label={"Commune *"}
              disabled={shouldDisabledFields}
              title="Commune"
              header={["Code INSEE", "Code postal", "Commune", "Département"]}
              linesData={props.referentielINSEE}
              formatLine={insee => [
                insee.object.codeInsee,
                insee.object.codePostal,
                insee.object.nomCommune,
                insee.object.numeroDepartement.toString(),
              ]}
              formatSelectedTitle={lineData => lineData.object.nomCommune}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [
                    lineData.object.codeInsee,
                    lineData.object.codePostal,
                    lineData.object.nomCommune,
                    lineData.object.numeroDepartement,
                  ],
                  searchedStr
                )
              }
              preferredColWidths={[100, 100, 250, 80]}
              commonProps={commonProps}
              onSelect={data => {
                formProps.setFieldValue(
                  "installationPostalCode",
                  data ? data.object.codePostal : data
                );
              }}
            />
            <TextField
              name="installationPostalCode"
              label="Code postal *"
              disabled={shouldDisabledFields}
            />
            <TextField
              name="SIRETNumber"
              label="n° de SIRET *"
              disabled={shouldDisabledFields}
            />
            <WrappedChoiceSelectModale
              name={"NAFCode"}
              label={"Code NAF *"}
              disabled={shouldDisabledFields}
              title="Code NAF"
              header={["Code NAF", "Libellé"]}
              linesData={props.referentielAPE}
              formatLine={ape => [ape.object.codeApe, ape.object.libelle]}
              isFirstSticky={false}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.object.codeApe, lineData.object.libelle],
                  searchedStr
                )
              }
              formatSelectedTitle={lineData => lineData.object.codeApe}
              preferredColWidths={[100, 300]}
              commonProps={commonProps}
            />
            <DummyTextField
              name="principalActivity"
              label="Activité principale"
              value={
                formProps.values.NAFCode &&
                formProps.values.NAFCode.object.libelle
              }
              disabled={true}
            />

            <Row />

            <TextField
              name="longitude"
              label="Abscisse/Longitude/X (WGS84) *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              tooltipContent={locationHelper}
            />
            <TextField
              name="latitude"
              label="Ordonnée/Latitude/Y (WGS84) *"
              style={{
                width: SHORT_TEXT_INPUT_WIDTH,
              }}
              disabled={shouldDisabledFields}
              tooltipContent={locationHelper}
            />

            <Row />

            <NumberField
              name="productionVolume"
              label="Volume de production"
              unit=""
              disabled={shouldDisabledFields}
            />
            <SelectField
              name="unit"
              label="Unité"
              isMulti={false}
              options={units}
              disabled={shouldDisabledFields}
            />
            <TextField
              name="producedMatter"
              label="Matière produite/Type de produits"
              disabled={shouldDisabledFields}
            />
            <NumberField
              name="workDuration"
              label="Nombre d'heures d'exploitation au cours de l'année"
              unit="heures"
              disabled={shouldDisabledFields}
            />
            <NumberField
              name="employee"
              label="Nombre d'employés"
              unit=""
              disabled={shouldDisabledFields}
            />
            <TextField
              name="website"
              label="Adresse du site internet"
              disabled={shouldDisabledFields}
              tooltipContent={""}
            />
            <TextArea
              name="additionalInfo"
              label="Informations complémentaires / remarques"
              disabled={shouldDisabledFields}
              additionalClassName={classes.additionalInfo}
            />
          </>
        );
      }}
    />
  );
};
