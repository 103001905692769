import {
  DeclarationBodyDto2020,
  DeclarationDto2020,
  DeclarationSectionsDto2020,
  QuotasAllocationsBlocVerificationDto20Now,
  QuotasBlocVerificationEmissionsDto20Now,
  TypeActiviteDto2020,
  TypeActiviteGlobalDto21Now,
  TypeActiviteQuotaDto2122,
  TypeActiviteQuotaDto23Now,
} from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import {
  backMessageApproveDeclaration,
  backMessageOnYieldQuotas,
  backMessageSetVerification,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { WorkflowTarget } from "common/utils/types";
import { DeclarationApiContext } from "pages/CompanySpace/DeclarationApiContext/DeclarationApiContext";
import { useCallback, useContext } from "react";
import {
  useBasicDeclarationHandlers,
  useDeclaration,
  useDeclarationState,
  useDeclarationStateHandlers,
  useQuotasState,
} from "../../../DeclarationApiContext/utils/genericHooks";
import {
  BasicDeclarationHandlers,
  DeclarationState,
  DeclarationStateHandlers18Now,
} from "../../../DeclarationApiContext/utils/types";
import { useDeclarationHelpers19Now } from "../../../from19/toNow/versionedElements/declarationHooks19Now";
import {
  Declaration21Now,
  DeclarationBody21Now,
  DeclarationSections21Now,
} from "../../../from21/toNow/versionedElements/declarationHooks21Now";

// Declare the range for all generic hooks we'll call for this version.
export const MIN_YEAR = 2020;
export const MAX_YEAR = undefined;

// Define all the DTO types for this version.
export type Declaration20Now = DeclarationDto2020 | Declaration21Now;
export type DeclarationBody20Now =
  | DeclarationBodyDto2020
  | DeclarationBody21Now;
export type TypeActiviteGlobal20Now =
  | TypeActiviteDto2020
  | TypeActiviteGlobalDto21Now;
export type TypeActiviteQuota20Now =
  | TypeActiviteDto2020
  | TypeActiviteQuotaDto2122
  | TypeActiviteQuotaDto23Now;
export type DeclarationSections20Now =
  | DeclarationSectionsDto2020
  | DeclarationSections21Now;

type DeclarationStateHandlers20Now = DeclarationStateHandlers18Now & {
  approve: (targets: WorkflowTarget[]) => Promise<boolean>;
  yieldQuotaAllocationsVerif(): Promise<boolean>;
  yieldQuotaEmissionsVerif(): Promise<boolean>;
  updateQuotaAllocationsVerif: (
    newQuotaVerif: QuotasAllocationsBlocVerificationDto20Now
  ) => Promise<boolean>;
  updateQuotaEmissionsVerif: (
    newQuotaVerif: QuotasBlocVerificationEmissionsDto20Now
  ) => Promise<boolean>;
};

export const useDeclaration20Now = (
  shouldThrowIfWrongYear?: boolean
): Declaration20Now => {
  return useDeclaration<Declaration20Now>(
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationState20Now = (): DeclarationState => {
  const getDeclarationState = useCallback(
    (declarationDto: Declaration20Now) =>
      declarationDto.body.workflowStatus.general.state,
    []
  );

  return useDeclarationState<Declaration20Now>(
    getDeclarationState,
    MIN_YEAR,
    MAX_YEAR
  );
};

export const useQuotaAllocationsState20Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration20Now) =>
      declarationDto.body.workflowStatus.quotaAllocations.state,
    []
  );

  return useQuotasState<Declaration20Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useQuotaEmissionsState20Now = (): DeclarationState => {
  const getQuotaState = useCallback(
    (declarationDto: Declaration20Now) =>
      declarationDto.body.workflowStatus.quotaEmissions.state,
    []
  );

  return useQuotasState<Declaration20Now>(getQuotaState, MIN_YEAR, MAX_YEAR);
};

export const useDeclarationHelpers20Now = useDeclarationHelpers19Now;

export const useBasicDeclarationHandlers20Now = (
  shouldThrowIfWrongYear?: boolean
): BasicDeclarationHandlers<Declaration20Now> => {
  const getPatchParams = useCallback(
    (declarationDto: Declaration20Now): DeclarationSections20Now =>
      declarationDto.body.sections,
    []
  );

  return useBasicDeclarationHandlers<Declaration20Now>(
    getPatchParams,
    MIN_YEAR,
    MAX_YEAR,
    shouldThrowIfWrongYear
  );
};

export const useDeclarationStateHandlers20Now = (): DeclarationStateHandlers20Now => {
  const { declarationController } = useAuthenticatedApi();
  const declarationApi = useContext(DeclarationApiContext);
  const openModale = useAlertModale();
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  const approve = useCallback(
    async (targets: WorkflowTarget[]): Promise<boolean> => {
      try {
        const returnedResult = await declarationController.approveDeclaration20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          targets
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageApproveDeclaration));
        return false;
      }
    },
    [declarationController, declarationApi, openModale]
  );

  const updateQuotaAllocationsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasAllocationsBlocVerificationDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration20Now = await declarationController.setVerificationAllocations20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const updateQuotaEmissionsVerif = useCallback(
    async (
      quotasBlocVerificationDto: QuotasBlocVerificationEmissionsDto20Now
    ): Promise<boolean> => {
      try {
        const returnedResult: Declaration20Now = await declarationController.setVerificationEmissions20NowUsingPOST(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          quotasBlocVerificationDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openModale(backAlertMessage(excp, backMessageSetVerification));
        return false;
      }
    },
    [declarationApi, openModale, declarationController]
  );

  const yieldQuotaAllocationsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_ALLOCATIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const yieldQuotaEmissionsVerif = useCallback(async (): Promise<boolean> => {
    try {
      const returnedResult = await declarationController.yieldQuota20NowUsingPOST(
        declarationApi.declaration.annee,
        declarationApi.declaration.etablissementCode,
        [WorkflowTarget.QUOTA_EMISSIONS]
      );
      declarationApi.setDeclaration(returnedResult);
      return true;
    } catch (excp) {
      await openModale(backAlertMessage(excp, backMessageOnYieldQuotas));
      return false;
    }
  }, [declarationController, declarationApi, openModale]);

  const stateHandlers18Now = useDeclarationStateHandlers(MIN_YEAR, MAX_YEAR);

  return {
    ...stateHandlers18Now,
    approve,
    yieldQuotaAllocationsVerif,
    yieldQuotaEmissionsVerif,
    updateQuotaAllocationsVerif,
    updateQuotaEmissionsVerif,
  };
};
