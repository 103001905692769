import React, { ReactElement } from "react";
import logoISDND from "icons/pageLogos/biogas-plant.svg";
import { RouteIdProps } from "common/declarant/type";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { ISDNDController } from "./ISDNDController";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const ISDND = ({ match }: RouteIdProps): ReactElement => {
  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "ISDND",
      to: match.url,
    },
  ];
  const imageData = {
    src: logoISDND,
    alt:
      "logo des Installation de stockage de déchets non dangereux, avec un silo et un petit bâtiment",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "ISDND (Installation de stockage de déchets non dangereux)",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return <ISDNDController />;
};

export default wrapWithWorkflowTargetProvider(ISDND, "GLOBAL");
