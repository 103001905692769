import React, { useState } from "react";
import logo from "icons/pageLogos/pig.svg";
import BlocCheckboxes from "./blocCheckboxes";
import BlocAutresEmissions from "./blocAutresEmissions";
import { Declaration1919 } from "pages/CompanySpace/from19/to19/versionedElements/declarationHooks1919";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { RouteIdProps } from "common/declarant/type";
import {
  EmissionsForm,
  EmissionsSpeciesForm,
  PopulatedBuildingInArray,
} from "./utils/types";
import { convertBuildingDtoToPopulatedBuilding } from "./blocEmissions/utils/converters";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import _ from "lodash";
import {
  convertDeclarationDtoUsingBuildings,
  convertDeclarationDtoUsingCheckboxesForm,
  convertDeclarationDtoUsingEmissionsForm,
  convertDeclarationDtoUsingEmissionsSpeciesForm,
} from "./utils/converters";
import {
  PATH_AIR_ELEVAGE,
  PATH_AIR_ELEVAGE_DETAIL,
  PATH_AIR_ELEVAGE_EMISSION,
  PATH_AIR_ELEVAGE_MTD,
} from "common/path/path18Now";
import Row from "common/presentational/Row";
import BlocEmissions from "./blocEmissions/BlocEmissions";
import {
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../../versionedElements/declarationHooks1919";
import GlobalFormActionFullContext1919 from "../../versionedElements/GlobalFormActionFullContext1919";
import { wrapWithWorkflowTargetProvider } from "pages/CompanySpace/WorkflowTargetProviderContext";

const Breeding = ({ match }: RouteIdProps): React.ReactElement => {
  const declaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const [isMtdChecked, setIsMtdChecked] = useState<boolean>(
    declaration.body.sections.air.elevage.mtd || false
  );
  const [buildingsInArray, setBuildingsInArray] = useState<
    PopulatedBuildingInArray[]
  >(
    convertBuildingDtoToPopulatedBuilding(
      declaration.body.sections.air.elevage.detailNh3.batiments
    )
  );

  const formEmissionsProps = useFormikBloc<EmissionsForm>(false);
  const formSpeciesEmissionsProps = useFormikBloc<EmissionsSpeciesForm>(false);

  const isGlobalValidateAvailable =
    (!isMtdChecked &&
      !isPathValidatedInDeclaration(PATH_AIR_ELEVAGE) &&
      isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_MTD) &&
      isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_DETAIL) &&
      isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_EMISSION)) ||
    (isMtdChecked &&
      !isPathValidatedInDeclaration(PATH_AIR_ELEVAGE) &&
      isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_MTD) &&
      isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_EMISSION));

  const hasGlobalChanges = (): boolean => {
    let hasChanges: boolean =
      isMtdChecked !== (declaration.body.sections.air.elevage.mtd || false) ||
      !_.isEqual(
        buildingsInArray,
        convertBuildingDtoToPopulatedBuilding(
          declaration.body.sections.air.elevage.detailNh3.batiments
        )
      );

    hasChanges =
      formEmissionsProps.hasChanges ||
      formSpeciesEmissionsProps.hasChanges ||
      hasChanges;

    return hasChanges;
  };

  const pagePath = [
    ...usePathToDeclarationDashboard(
      match.params.annee,
      match.params.etablissement
    ),
    {
      label: "Élevage",
      to: match.url,
    },
  ];
  const imageData = {
    src: logo,
    alt: "logo des élevages, avec un cochon",
  };
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Élevage",
    image: imageData,
  };
  usePageHeaderSetterOnce(headerData);

  return (
    <>
      <BlocCheckboxes
        setIsMtdChecked={setIsMtdChecked}
        isMtdChecked={isMtdChecked}
      />

      <Row />

      {!isMtdChecked && (
        <BlocEmissions
          buildingsInArray={buildingsInArray}
          setBuildingsInArray={setBuildingsInArray}
          formSpeciesEmissionsProps={formSpeciesEmissionsProps}
        />
      )}

      <>
        <Row />

        <BlocAutresEmissions formEmissionsProps={formEmissionsProps} />
      </>

      <GlobalFormActionFullContext1919
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasGlobalChanges()}
        isValidateEnabled={isGlobalValidateAvailable}
        cancelAction={() => {
          setIsMtdChecked(declaration.body.sections.air.elevage.mtd || false);
          setBuildingsInArray(
            convertBuildingDtoToPopulatedBuilding(
              declaration.body.sections.air.elevage.detailNh3.batiments
            )
          );
          if (
            formEmissionsProps.formikRef &&
            formEmissionsProps.formikRef.current
          ) {
            formEmissionsProps.formikRef.current.resetForm();
          }
          if (
            formSpeciesEmissionsProps.formikRef &&
            formSpeciesEmissionsProps.formikRef.current
          ) {
            formSpeciesEmissionsProps.formikRef.current.resetForm();
          }
        }}
        validationPath={PATH_AIR_ELEVAGE}
        updateHandler={(declaration: Declaration1919) => {
          declaration = convertDeclarationDtoUsingCheckboxesForm(
            declaration,
            isMtdChecked
          );
          if (
            isMtdChecked &&
            formEmissionsProps.formikRef &&
            formEmissionsProps.formikRef.current
          ) {
            return convertDeclarationDtoUsingEmissionsForm(
              declaration,
              formEmissionsProps.formikRef.current.state.values
            );
          }
          if (
            formEmissionsProps.formikRef &&
            formEmissionsProps.formikRef.current &&
            formSpeciesEmissionsProps.formikRef &&
            formSpeciesEmissionsProps.formikRef.current
          ) {
            declaration = convertDeclarationDtoUsingEmissionsForm(
              declaration,
              formEmissionsProps.formikRef.current.state.values
              //formAutresEmissionsProps.formikRef.current.state.values
            );
            declaration = convertDeclarationDtoUsingEmissionsSpeciesForm(
              declaration,
              formSpeciesEmissionsProps.formikRef.current.state.values
            );
            return convertDeclarationDtoUsingBuildings(
              declaration,
              buildingsInArray,
              formSpeciesEmissionsProps.formikRef.current.state.values.species
            );
          }
          return declaration;
        }}
      />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(Breeding, "GLOBAL");
