import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import {
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
  ReferenceItemGeoCodeInseeDto,
  ReferenceItemPaysDto,
} from "api/gen";
import { Nullable } from "common/utils/types";

/**
 * @deprecated to be supressed to use a backoffice DTO
 */
interface LegacyAdministrationValues extends FormikValues {
  socialReason: string;
  parentCompany: string;
  legalStatus: string;
  adress: string;
  postalCode: string;
  country: ReferenceItemPaysDto;
  city: string | ReferenceItemGeoCodeInseeDto;
  SIRENNumber: string;
}

export type AdministrationValues = Nullable<LegacyAdministrationValues>;

/**
 * @deprecated to be supressed to use a backoffice DTO
 */
interface LegacyActivityValues {
  inspectionCode: string;
  establishmentName: string;
  installationAdress: string;
  installationPostalCode: string;
  installationCity: ReferenceItemGeoCodeInseeDto;
  SIRETNumber: string;
  NAFCode: ReferenceItemCodeApeDto;
  systemCoordinates: ReferenceItemCoordonneesDepartementDto;
  longitude: string;
  latitude: string;
  productionVolume: number;
  workDuration: number;
  unit: UnitsEnum;
  producedMatter: string;
  website: string;
  employee: number;
  additionalInfo: string;
}

export type ActivityValues = Nullable<LegacyActivityValues>;

export type CoordonneesDepartementMap = Map<
  string,
  Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  >
>;

export enum UnitsEnum {
  "pièces" = "pièces",
  "kg" = "kg",
  "t" = "t",
  "kWh" = "kWh",
  "MWh" = "MWh",
  "GWh" = "GWh",
  "kW" = "kW",
  "MW" = "MW",
  "GW" = "GW",
  "GJ" = "GJ",
  "L" = "L",
  "hL" = "hL",
  "m3" = "m3",
  "m2" = "m2",
}

const unitsEnumLabel: {
  [key in UnitsEnum]: string;
} = {
  pièces: "pièces",
  kg: "kg",
  t: "t",
  kWh: "kWh",
  MWh: "MWh",
  GWh: "GWh",
  kW: "kW",
  MW: "MW",
  GW: "GW",
  GJ: "GJ",
  L: "L",
  hL: "hL",
  m3: "m3",
  m2: "m2",
};

export const units: UnitsEnum[] = Object.keys(unitsEnumLabel) as UnitsEnum[];

export const getUnitsEnumLabel = (unit: UnitsEnum): string => {
  return unitsEnumLabel[unit];
};

export const franceOption: ReferenceItemPaysDto = {
  restorationCode: -1,
  codeInsee: -1,
  ue: true,
  designation: "FRANCE",
  uuid: "",
};
