import { QuotasBlocEmissionsBilanTotalDto1819 } from "api/gen";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

export const computeTotalEmission = (
  values: QuotasBlocEmissionsBilanTotalDto1819
) => {
  return (
    (values.emissionsCO2 || 0) +
    (values.emissionsN2O || 0) +
    (values.emissionsPFC || 0)
  );
};

export const updateHandler = (
  declaration: Declaration1919,
  values: QuotasBlocEmissionsBilanTotalDto1819 | null
) => {
  if (values) {
    declaration.body.sections.quotas.blocBilanTotal = values;
  }
  return declaration;
};
