import {
  FileDto,
  QuotasBlocPlanSurveillanceDto1819,
  QuotasEmissionSimpleCalculeDto1819,
  QuotasEmissionSimpleCalculeDto1819MethodeEnum,
} from "api/gen/api";
import { BlocPlanDeSurveillanceFormValues } from "./types";
import { computeAllowedDeclarationsMethods } from "../blocEmissions/utils/utils";
import { Declaration1919 } from "../../versionedElements/declarationHooks1919";

export const dtoToBlocValues = (
  dto: QuotasBlocPlanSurveillanceDto1819
): BlocPlanDeSurveillanceFormValues => {
  return {
    computedByEmissionWithOxidationFactor: !!dto.calculFacteurEmissionOxydation,
    computedByEmissionWithConversionFactor: !!dto.calculFacteurEmissionConversion,
    computedByMassBalance: !!dto.calculBilanMassique,
    measure: !!dto.estMesure,
    alternativeMethod: !!dto.estMethodeAlternative,
  };
};

const blocValuesToDto = (
  values: BlocPlanDeSurveillanceFormValues | null,
  files: FileDto[]
): QuotasBlocPlanSurveillanceDto1819 => {
  return {
    calculFacteurEmissionOxydation: values
      ? values.computedByEmissionWithOxidationFactor
      : false,
    calculFacteurEmissionConversion: values
      ? values.computedByEmissionWithConversionFactor
      : false,
    calculBilanMassique: values ? values.computedByMassBalance : false,
    estMesure: values ? values.measure : false,
    estMethodeAlternative: values ? values.alternativeMethod : false,
    fichiers: files,
  };
};

export const updateHandler = (
  declaration: Declaration1919,
  values: BlocPlanDeSurveillanceFormValues | null,
  files: FileDto[]
) => {
  declaration.body.sections.quotas.blocPlanSurveillance = blocValuesToDto(
    values,
    files
  );
  const allowedDeclarationMethod = computeAllowedDeclarationsMethods(
    declaration.body.sections.quotas.blocPlanSurveillance
  );

  if (!allowedDeclarationMethod.computed) {
    declaration.body.sections.quotas.blocEmissions.emissionsCalculees = [];
    declaration.body.sections.quotas.blocEmissions.flux = [];
    declaration.body.sections.quotas.blocEmissions.fluxSoumisADerogation = [];
  } else {
    let emissionsCalculees: QuotasEmissionSimpleCalculeDto1819[] =
      declaration.body.sections.quotas.blocEmissions.emissionsCalculees;
    if (!allowedDeclarationMethod.computed.bilanMassique) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto1819MethodeEnum.BILAN_MASSIQUE
      );
    }
    if (!allowedDeclarationMethod.computed.facteurConversion) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_CONVERSION
      );
    }
    if (!allowedDeclarationMethod.computed.facteurOxydation) {
      emissionsCalculees = emissionsCalculees.filter(
        singleEmission =>
          singleEmission.methode !==
          QuotasEmissionSimpleCalculeDto1819MethodeEnum.FACTEUR_OXYDATION
      );
    }
    declaration.body.sections.quotas.blocEmissions.emissionsCalculees = emissionsCalculees;
  }

  if (!allowedDeclarationMethod.mesure) {
    declaration.body.sections.quotas.blocEmissions.emissionsMesurees = [];
    declaration.body.sections.quotas.blocEmissions.pointsMesure = [];
  }

  if (!allowedDeclarationMethod.alternative) {
    declaration.body.sections.quotas.blocEmissions.emissionsMethodeAlternative = [];
  }

  return declaration;
};
