import { AirCombustionInstallationsDto20Now } from "api/gen";
import { InstallationInArray } from "./types";

//=============================================================
// INSTALLATIONS
//=============================================================
export const convertInstallationsToDisplayed = (
  installations: AirCombustionInstallationsDto20Now
): InstallationInArray => {
  return {
    data: {
      nom: installations.nom,
      type: installations.type,
      heure: installations.heure,
      volumeActivite: installations.volumeActivite,
      unite: installations.unite,
      typeProduit: installations.typeProduit,
      quantiteChaleur: installations.quantiteChaleur,
      quantiteElectricite: installations.quantiteElectricite,
      rendementChaleur: installations.rendementChaleur,
      rendementElectricite: installations.rendementElectricite,
      id: installations.id,
    },
    errors: {},
  };
};

export const createAirCombustionsInstallationDto = (
  installationsInPage: InstallationInArray[]
): AirCombustionInstallationsDto20Now[] => {
  return installationsInPage.map(singlePopulatedInstallation => {
    const singleInstallation = singlePopulatedInstallation.data;
    const singleInstallationDto: AirCombustionInstallationsDto20Now = {
      id: singlePopulatedInstallation.data.id,
      nom: singleInstallation.nom,
      heure: singleInstallation.heure,
      volumeActivite: singleInstallation.volumeActivite,
      typeProduit: singleInstallation.typeProduit,
      unite: singleInstallation.unite,
      type: singleInstallation.type,
      quantiteChaleur: singleInstallation.quantiteChaleur,
      quantiteElectricite: singleInstallation.quantiteElectricite,
      rendementChaleur: singleInstallation.rendementChaleur,
      rendementElectricite: singleInstallation.rendementElectricite,
    };
    return singleInstallationDto;
  });
};

export const shouldDisableTypeField = (
  hasSeveralOptions: boolean,
  hasFieldAnError: boolean
): boolean => {
  return !hasSeveralOptions && !hasFieldAnError;
};
