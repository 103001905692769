import { InArray, InModale } from "common/form/utils";
import {
  QuotasInstallationDto20Now,
  ReferenceItemActiviteQuotaDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";
import { Omit } from "react-router";

interface InstallationProps
  extends Omit<
    QuotasInstallationDto20Now,
    "id" | "codesActivite" | "entreeSEQE" | "sortieSEQE"
  > {
  entreeSEQE: Date;
  sortieSEQE: Date;
  codesActivite: ReferenceItemActiviteQuotaDto[];
}

export type InstallationInModale = InModale<InstallationProps>;

export type InstallationInArray = InArray<InstallationProps>;

export const installationFieldMatcher = fieldMatcherBuilder<
  InstallationInModale
>().build({
  nim: "nim",
  nomInstallation: "nomInstallation",
  entreeSEQE: "entreeSEQE",
  sortieSEQE: "sortieSEQE",
  codesActivite: "codesActivite",
  isNotAllocationGratuit: "isNotAllocationGratuit",
  isNouvelEntrant: "isNouvelEntrant",
});
