import React from "react";
import Row from "common/presentational/Row";
import { PATH_ROOT } from "common/path/path18Now";
import Button from "common/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DeclarationHelpers } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import {
  useAccuseTransmissionPdfExport,
  useDeclarationExcelExport,
  useDeclarationPdfExport,
} from "pages/CompanySpace/DeclarationApiContext/utils/exportHooks";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { useUserData } from "../../../../../../../Authenticator/UserData";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";

interface ExportButtonsProps {
  declarationAnnee: string;
  declarationEtablissement: string;
  declarationApi: DeclarationHelpers;
}

const ExportButtons = ({
  declarationApi,
  declarationAnnee,
  declarationEtablissement,
}: ExportButtonsProps): React.ReactElement => {
  const { isLecteur } = useUserData();
  const { declarationController } = useAuthenticatedApi();
  const excelExportDeclaration = useDeclarationExcelExport();
  const pdfExportDeclaration = useDeclarationPdfExport();
  const [
    isDownloadingAccuseTransmission,
    triggerDownloadAccuseTransmission,
  ] = useSpinnerState();
  const [isExcelExportLoading, triggerExcelExportLoading] = useSpinnerState();
  const [isPdfExportLoading, triggerPdfExportLoading] = useSpinnerState();
  const anonymousExportAccuseTransmission = (
    annee: number,
    etablissement: string
  ) =>
    declarationController.exportAccuseTransmissionRegistreGerepAsPdfUsingGET(
      annee,
      etablissement
    );
  const exportAccuseTransmissionPdf = useAccuseTransmissionPdfExport(
    "registre_gerep_",
    anonymousExportAccuseTransmission
  );

  const isDeclarationDone = declarationApi.getPathProgress(PATH_ROOT) === 100;

  return (
    <Row additionalStyle={{ justifyContent: "flex-end" }}>
      <Button
        text={
          <>
            <FontAwesomeIcon icon="download" /> Télécharger l'accusé de
            transmission
          </>
        }
        isReversed
        onClick={() =>
          triggerDownloadAccuseTransmission(
            exportAccuseTransmissionPdf(
              Number(declarationAnnee),
              declarationEtablissement
            )
          )
        }
        isDisabled={!isDeclarationDone || isLecteur}
        isSpinnerVisible={isDownloadingAccuseTransmission}
      />
      <Button
        text={
          <>
            <FontAwesomeIcon icon="download" /> Télécharger la déclaration Excel
          </>
        }
        onClick={() =>
          triggerExcelExportLoading(
            excelExportDeclaration(
              Number(declarationAnnee),
              declarationEtablissement
            )
          )
        }
        isReversed
        isSpinnerVisible={isExcelExportLoading}
        isDisabled={isLecteur}
      />
      <Button
        text={
          <>
            <FontAwesomeIcon icon="download" /> Télécharger la déclaration Pdf
          </>
        }
        onClick={() =>
          triggerPdfExportLoading(
            pdfExportDeclaration(
              Number(declarationAnnee),
              declarationEtablissement
            )
          )
        }
        isReversed
        isSpinnerVisible={isPdfExportLoading}
        isDisabled={isLecteur}
      />
    </Row>
  );
};

export default ExportButtons;
