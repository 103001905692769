import { ActivityType, CoordonneesDepartementMap } from "./types";
import {
  ActiviteDto23NowSystemeCoordonneesEnum,
  ExternalActiviteGunDto23NowSystemeCoordonneesEnum,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
} from "api/gen";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const getRefCoordonneedDepartementDtoFromEnum = (
  enumValue: ExternalActiviteGunDto23NowSystemeCoordonneesEnum | null,
  coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[]
): ReferenceItemCoordonneesDepartementDto | null => {
  return (
    (enumValue &&
      coordonneesDepartementList.find(
        dto =>
          dto.systemeCoordonnees ===
          // The as is justified because the parameter enum and ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum correspond to the same back enum SystemeCoordonnees
          ((enumValue as unknown) as ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum)
      )) ||
    null
  );
};

export const computeCoordonneesDepartementMapFromArray = (
  coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[]
): CoordonneesDepartementMap => {
  const coordonneesDepartementMap = new Map();

  for (const coordonneesDepartement of coordonneesDepartementList) {
    const numeroDepartement: string = coordonneesDepartement.numeroDepartement;

    if (coordonneesDepartementMap.has(numeroDepartement)) {
      coordonneesDepartementMap
        .get(numeroDepartement)
        .set(coordonneesDepartement.systemeCoordonnees, coordonneesDepartement);
    } else {
      const systemToItem: Map<
        ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
        ReferenceItemCoordonneesDepartementDto
      > = new Map([
        [coordonneesDepartement.systemeCoordonnees, coordonneesDepartement],
      ]);

      coordonneesDepartementMap.set(numeroDepartement, systemToItem);
    }
  }

  return coordonneesDepartementMap;
};

export const getAssociatedSystemCoordinates = (
  values: ActivityType,
  coordonneesDepartementMap: CoordonneesDepartementMap
): ReferenceItemCoordonneesDepartementDto[] => {
  const numeroDepartement: string | null =
    values.informationCommune && values.informationCommune.numeroDepartement;

  if (numeroDepartement === null) {
    return [];
  }

  const possibleSystems: Map<
    ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum,
    ReferenceItemCoordonneesDepartementDto
  > = coordonneesDepartementMap.get(numeroDepartement) || new Map();

  return Array.from(possibleSystems.values());
};

export const transformActiviteToReferenceEnum = (
  activiteDtoSystem: ActiviteDto23NowSystemeCoordonneesEnum
): ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum => {
  switch (activiteDtoSystem) {
    case ActiviteDto23NowSystemeCoordonneesEnum.WGS84:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.WGS84;
    case ActiviteDto23NowSystemeCoordonneesEnum.LAMBERT93:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.LAMBERT93;
    case ActiviteDto23NowSystemeCoordonneesEnum.UTM_20N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_20N;
    case ActiviteDto23NowSystemeCoordonneesEnum.UTM_21N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_21N;
    case ActiviteDto23NowSystemeCoordonneesEnum.UTM_22N:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_22N;
    case ActiviteDto23NowSystemeCoordonneesEnum.UTM_38S:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_38S;
    case ActiviteDto23NowSystemeCoordonneesEnum.UTM_40S:
      return ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_40S;
    default:
      throw new ShouldNotHappen(activiteDtoSystem);
  }
};

export const transformReferenceToActiviteEnum = (
  referenceItemSystem: ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum
): ActiviteDto23NowSystemeCoordonneesEnum => {
  switch (referenceItemSystem) {
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.WGS84:
      return ActiviteDto23NowSystemeCoordonneesEnum.WGS84;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.LAMBERT93:
      return ActiviteDto23NowSystemeCoordonneesEnum.LAMBERT93;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_20N:
      return ActiviteDto23NowSystemeCoordonneesEnum.UTM_20N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_21N:
      return ActiviteDto23NowSystemeCoordonneesEnum.UTM_21N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_22N:
      return ActiviteDto23NowSystemeCoordonneesEnum.UTM_22N;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_38S:
      return ActiviteDto23NowSystemeCoordonneesEnum.UTM_38S;
    case ReferenceItemCoordonneesDepartementDtoSystemeCoordonneesEnum.UTM_40S:
      return ActiviteDto23NowSystemeCoordonneesEnum.UTM_40S;
    default:
      throw new ShouldNotHappen(referenceItemSystem);
  }
};
