import {
  DonneeActiviteDto,
  DonneeSurveillanceDto,
  SurveillanceEmissionDto,
} from "api/gen";
import { ClassesType } from "common/utils/types";
import { ReactElement } from "react";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { formatNiveauFlux, formatFrequenceAnalyse } from "./utils";
import { computeEmissionWrappedCell } from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/MethodesEmissionsData/EmissionsArrays/arrayUtils";

export function computeDonneeActiviteLine(
  { niveauApplique, niveauRequis, niveauIncertitude }: DonneeActiviteDto,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell("Données d'activité", classes),
    computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
    computeWrappedCell(formatNiveauFlux(niveauRequis), classes),
    computeWrappedCell("-", classes),
    computeWrappedCell("-", classes),
    computeWrappedCell("-", classes),
    computeWrappedCell(niveauIncertitude, classes),
  ];
}

export function computeDonneeSurveillanceLine(
  {
    niveauApplique,
    niveauRequis,
    unite,
    valeurDefaut,
    frequenceAnalyse,
  }: DonneeSurveillanceDto,
  donneeName: string,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell(donneeName, classes),
    computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
    computeWrappedCell(formatNiveauFlux(niveauRequis), classes),
    computeEmissionWrappedCell(valeurDefaut, classes),
    computeWrappedCell(unite, classes),
    computeWrappedCell(formatFrequenceAnalyse(frequenceAnalyse), classes),
    computeWrappedCell("-", classes),
  ];
}

export function computeSurveillanceEmissionLine(
  { niveauApplique, niveauRequis, incertitude = "-" }: SurveillanceEmissionDto,
  donneeName: string,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell(donneeName, classes),
    computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
    computeWrappedCell(formatNiveauFlux(niveauRequis), classes),
    computeWrappedCell(incertitude, classes),
  ];
}
