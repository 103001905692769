import React, { Dispatch, SetStateAction } from "react";
import { AccidentCauseType, AccidentInArray, AccidentInModale } from "../utils";
import CustomModal from "common/presentational/CustomModal";
import FormSingleAccident from "../FormSingleAccident";
import { ModaleEntityHandler } from "common/modale/modaleStates";
import { Omit } from "react-router";
import { addOrModifyArray } from "common/declarant/array/utils";

interface AccidentModaleProps
  extends Omit<ModaleEntityHandler<AccidentInArray>, "openModale"> {
  setAccidentsInArray: Dispatch<SetStateAction<AccidentInArray[]>>;
  mainAccidentCauseList: AccidentCauseType[];
}

const AccidentModale = ({
  setAccidentsInArray,
  modaleState,
  closeModale,
  mainAccidentCauseList,
}: AccidentModaleProps): React.ReactElement => {
  const onClose = () => {
    closeModale();
  };

  const accidentInModal = modaleState.elementToEdit;

  return (
    <CustomModal
      isOpen={modaleState.isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'accidents."
      onRequestClose={onClose}
    >
      <FormSingleAccident
        closeFunction={onClose}
        isEdit={accidentInModal !== null}
        mainAccidentCauseList={mainAccidentCauseList}
        onSubmit={(values, formikBag) => {
          const newAccident: AccidentInModale = {
            date: values.date,
            accidentCause: values.accidentCause,
            accidentCausePrecision: values.accidentCausePrecision,
            isConsolidated: values.isConsolidated,
            nbDaysStopped: values.nbDaysStopped,
          };
          addOrModifyArray(
            setAccidentsInArray,
            accidentInModal && accidentInModal.data.id,
            newAccident
          );
          closeModale();
        }}
        initialAccident={
          accidentInModal !== null
            ? accidentInModal.data
            : {
                id: "", //id field not used by formik
                date: null,
                accidentCause: null,
                accidentCausePrecision: null,
                isConsolidated: null,
                nbDaysStopped: null,
              }
        }
      />
    </CustomModal>
  );
};

export default AccidentModale;
