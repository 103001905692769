import {
  BUTTON_DARK_GREY,
  DEFAULT_BORDER_RADIUS,
  DEFAULT_INPUT_HEIGHT,
  DISABLED_NUMBER_INPUT_BACKGROUND,
  DISABLED_NUMBER_INPUT_TEXT,
  ERROR,
  INPUT_BORDER_WIDTH,
  PINK,
  WARNING,
} from "theme";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { computeClassname } from "./utils";
import classNames from "classnames";
import { DummyNumberInputProps } from "../types/dummyTypes";

const useStyles = makeStyles({
  container: {
    display: "flex",
    border: `${INPUT_BORDER_WIDTH} solid ${PINK}`,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: "0px 10px",
    height: DEFAULT_INPUT_HEIGHT,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    boxSizing: "border-box",
  },
  input: {
    border: 0,
    display: "inline-block",
    margin: 0,
    MozAppearance: "textfield",
    "&::-webkit-inner-spin-button": {
      margin: 0,
      WebkitAppearance: "none",
    },
    outline: "none",
    boxShadow: "none",
    width: "100%",
  },
  unit: {
    fontWeight: "bold",
    fontSize: "0.9em",
    whiteSpace: "nowrap",
  },
  active: {},
  unitDisabled: {
    fontWeight: "bold",
    fontSize: "0.9em",
    color: BUTTON_DARK_GREY,
    whiteSpace: "nowrap",
  },
  disabled: {
    backgroundColor: DISABLED_NUMBER_INPUT_BACKGROUND,
    borderColor: DISABLED_NUMBER_INPUT_TEXT,
    color: DISABLED_NUMBER_INPUT_TEXT,
    "& input": {
      backgroundColor: DISABLED_NUMBER_INPUT_BACKGROUND,
    },
  },
  warning: {
    borderColor: WARNING,
  },
  error: {
    borderColor: ERROR,
  },
  flexOne: {
    flex: 1,
  },
});

const MAX_DECIMAL_NUMBER_DISPLAYED = 20;

const numberFormatter = new Intl.NumberFormat("fr-FR", {
  maximumFractionDigits: MAX_DECIMAL_NUMBER_DISPLAYED,
});

const defaultFormatNumber = (value: number): string => {
  if (isNaN(value)) {
    return "";
  }
  return numberFormatter.format(value);
};

//please note that this component is using a TEXT input field, therefore,
// the onChange should expect an event where the value is a STRING (and not a number)
const DummyNumberInput = ({
  unit,
  disabled,
  id,
  style,
  additionalClassName,
  additionalClassNameInput,
  value,
  error,
  name,
  placeholder,
  onChange,
  onBlur,
  readOnly,
  hideValue,
  formatNumber = defaultFormatNumber,
}: DummyNumberInputProps): React.ReactElement => {
  const classes = useStyles();
  const [stringValue, setStringValue] = useState<string>("");
  const valueRef = React.useRef<number | null>(null);
  const fullFormated = React.useCallback(
    (toFormat: string) => {
      if (toFormat === "-") {
        return toFormat;
      }

      const parts = toFormat.split(".");
      if (parts[1] === undefined) {
        return formatNumber(Number(parts[0]));
      } else {
        return `${formatNumber(Number(parts[0]))},${parts[1]}`;
      }
    },
    [formatNumber]
  );

  useEffect(() => {
    if (valueRef.current === value) {
      return;
    }
    valueRef.current = value;

    if (value === null) {
      setStringValue("");
    } else {
      const stringValue = fullFormated(value.toString());
      setStringValue(stringValue);
    }
  }, [value, setStringValue, fullFormated, valueRef]);

  const containerClassName = computeClassname(
    // todo decide of the right way to pass errors to compute the classname
    classes,
    error,
    disabled,
    additionalClassName
  );

  const unitContainerClassName = disabled ? classes.unitDisabled : classes.unit;

  let formatedPlaceholder = "";
  if (placeholder) {
    formatedPlaceholder = formatNumber(placeholder);
  }

  return (
    <div className={containerClassName} style={style}>
      <div className={classes.flexOne}>
        <input
          id={id}
          name={name}
          disabled={disabled}
          /*   add the error but when it is clear how it can be deduced from fieldProps and fieldProps ONLY!!!*/
          className={classNames(classes.input, additionalClassNameInput)}
          value={hideValue ? "" : stringValue}
          placeholder={formatedPlaceholder}
          onBlur={e => {
            value !== null
              ? setStringValue(fullFormated(value.toString()))
              : setStringValue("");
            valueRef.current = value;
            onBlur && onBlur(e);
          }}
          onChange={event => {
            event.stopPropagation();

            if (
              event === null ||
              event.target === null ||
              event.target.value === null
            ) {
              return;
            }

            let currentValue = event.target.value;
            currentValue = currentValue.replace(/[^0-9.,-]+/g, "");

            //  value must be number and number ONLY. dot must be handled by an inner state. Be careful to change this state when a dot is added or removed.
            if (currentValue === "") {
              setStringValue("");

              onChange && onChange(null);
            } else if (currentValue.match(/^-?\d*[.,]?\d*$/)) {
              //  we need to replace commas by dots because only dots are valid separator for numbers in JS.
              const onlyDotValue = currentValue.replace(",", ".");

              //  complicated logic to limit the input the user writes to what will be displayed.
              const truncateValue =
                onlyDotValue.length > MAX_DECIMAL_NUMBER_DISPLAYED
                  ? onlyDotValue.substring(0, MAX_DECIMAL_NUMBER_DISPLAYED)
                  : onlyDotValue;

              setStringValue(fullFormated(truncateValue));

              const numberValue = Number(truncateValue);
              if (!isNaN(numberValue) && value !== numberValue) {
                //  we call the onChange ONLY if the result given is a number
                valueRef.current = numberValue;
                onChange && onChange(numberValue);
              }
            }
          }}
          readOnly={readOnly}
        />
      </div>
      <span className={unitContainerClassName}>
        {typeof unit === "function" ? unit(unitContainerClassName) : unit}
      </span>
    </div>
  );
};

export default DummyNumberInput;
