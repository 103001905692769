import React from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import PolluantArray from "./PolluantArray";
import { BlocRejetPolluantOptions, PolluantInArray } from "./types";
import PolluantModale from "./PolluantModale";
import { updateHandlerRejetPolluant } from "../utils";
import { useEntityModale } from "common/modale/modaleStates";
import { PATH_EAU_REJETPOLLUANT } from "common/path/path18Now";
import { ReferenceNormeEauDto, ReferencePolluantDto } from "api/gen";
import BlocFullContext20Now from "../../../toNow/versionedElements/BlocFullContext20Now";
import _ from "lodash";

interface BlocRejetPolluantProps {
  polluantsInArray: PolluantInArray[];
  setPolluantsInArray: (polluants: PolluantInArray[]) => void;
  hasChanges: boolean;
  cancelAction: () => void;
  selectPossibleValues: BlocRejetPolluantOptions;
  referentialPolluants: ReferencePolluantDto;
  referentialNormes: ReferenceNormeEauDto;
}

const BlocRejetPolluant = ({
  polluantsInArray,
  setPolluantsInArray,
  hasChanges,
  cancelAction,
  selectPossibleValues,
  referentialPolluants,
  referentialNormes,
}: BlocRejetPolluantProps): JSX.Element => {
  const { modaleState, openModale, closeModale } = useEntityModale<
    PolluantInArray
  >();

  const canSubmitArray = true;

  const compareFunction = (a: PolluantInArray, b: PolluantInArray): number => {
    if (
      a.data.pollutingSubstance &&
      b.data.pollutingSubstance &&
      a.data.pollutingSubstance.nom !== b.data.pollutingSubstance.nom
    ) {
      return a.data.pollutingSubstance.nom < b.data.pollutingSubstance.nom
        ? -1
        : 1;
    }
    return 0;
  };

  return (
    <BlocFullContext20Now
      title="tableau des rejets de substance dans l'eau"
      hasModification={hasChanges}
      isValidateButtonAvailable={canSubmitArray}
      path={PATH_EAU_REJETPOLLUANT}
      areGlobalCommentsAllowed={true}
      updateHandler={declaration =>
        updateHandlerRejetPolluant(declaration, polluantsInArray)
      }
      cancelAction={cancelAction}
      renderContent={shouldDisabledFields => (
        <>
          <Row
            additionalStyle={{
              justifyContent: "flex-end",
            }}
          >
            <Button
              text="AJOUTER UNE SUBSTANCE"
              type="button"
              onClick={() => openModale(null)}
              additionalStyle={{ marginLeft: "40px" }}
              isDisabled={shouldDisabledFields}
            />
          </Row>
          <Row height="15px" />
          <PolluantArray
            polluantsInArray={_.cloneDeep(polluantsInArray).sort(
              compareFunction
            )}
            setPolluantsInArray={setPolluantsInArray}
            openModal={openModale}
            shouldDisabledFields={shouldDisabledFields}
          />
          <Row
            additionalStyle={{
              marginTop: "15px",
              marginBottom: "20px",
              justifyContent: "flex-end",
            }}
          >
            <Button
              text="TOUT SUPPRIMER"
              type="button"
              isReversed
              onClick={() => setPolluantsInArray([])}
              isDisabled={shouldDisabledFields}
            />
          </Row>
          <PolluantModale
            isOpen={modaleState.isOpen}
            polluantInModal={modaleState.elementToEdit}
            polluantsInArray={polluantsInArray}
            setPolluantsInArray={setPolluantsInArray}
            closeModal={closeModale}
            selectPossibleValues={selectPossibleValues}
          />
        </>
      )}
    />
  );
};

export default BlocRejetPolluant;
