import { Nullable } from "common/utils/types";
import React, { useState } from "react";
import Row from "common/presentational/Row";
import AccidentsTravail from "./AccidentsTravail";
import MesuresEmpoussierage from "./MesuresEmpoussierage";
import { StructureFonctionnelleData } from "./StructureFonctionnelle/utils";
import StructureFonctionnelle from "./StructureFonctionnelle";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  AccidentInArray,
  AccidentsTravailData,
} from "./AccidentsTravail/utils";
import { MesureEmpoussierageData } from "./MesuresEmpoussierage/utils";
import { OptionPropsListWrapper } from "./utils/selectPossibleValues";
import { computeInitialArrayValues } from "./AccidentsTravail/converters";
import {
  ReferenceOrganismeAccrediteDto,
  ReferenceOrganismeExterieurPreventionDto,
} from "api/gen";
import {
  updateAccidentsTravail,
  updateMesuresEmpoussierage,
  updateStructureFonctionnelle,
} from "./utils/updaters";
import {
  PATH_CARRIERE_SANTE,
  PATH_CARRIERE_SANTE_ACCIDENT,
  PATH_CARRIERE_SANTE_EMPOUSSIERAGE,
  PATH_CARRIERE_SANTE_PREVENTION,
} from "common/path/path18Now";
import {
  Declaration1919,
  useDeclaration1919,
  useDeclarationHelpers1919,
} from "../../versionedElements/declarationHooks1919";
import GlobalFormActionFullContext1919 from "../../versionedElements/GlobalFormActionFullContext1919";

interface MainCarriereFormProps {
  listOptions: OptionPropsListWrapper;
  referentialOrganismeExterieur: ReferenceOrganismeExterieurPreventionDto;
  referentialOrganismeAccredite: ReferenceOrganismeAccrediteDto;
}

const MainCarriereForm = ({
  listOptions,
  referentialOrganismeExterieur,
  referentialOrganismeAccredite,
}: MainCarriereFormProps) => {
  //TODO take all one step up, to compute some or all of the initialValues here (probably only state values will be computed here, but still.
  const dataDeclaration = useDeclaration1919();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers1919();

  const {
    externalPreventionOrganismList,
    mainAccidentCauseList,
    organismeMeasuringEmpoussierageList,
  } = listOptions;

  //we set the refs and validation state here so it's all available for whole page validation and save
  const structureFonctionnelleProps = useFormikBloc<
    Nullable<StructureFonctionnelleData>
  >();
  const accidentsTravailProps = useFormikBloc<Nullable<AccidentsTravailData>>();
  const mesuresEmpoussierageProps = useFormikBloc<
    Nullable<MesureEmpoussierageData>
  >();

  //this state is needed for AccidentsTravail bloc, but defined here because cancel affects it.
  const initialAccidentsList = computeInitialArrayValues(
    dataDeclaration,
    mainAccidentCauseList
  );

  const [accidentsList, setAccidentsList] = useState<AccidentInArray[]>(
    initialAccidentsList
  );

  return (
    <>
      {/*TODO : lift useful state up because we'll need all changes and initial values here for GlobalFormActions. Use New interface from https://github.com/Polyconseil/mtes-gerep/pull/213 */}
      <StructureFonctionnelle
        {...structureFonctionnelleProps}
        externalPreventionOrganismList={externalPreventionOrganismList}
        referentialOrganismeExterieur={referentialOrganismeExterieur}
      />
      <Row />
      <AccidentsTravail
        {...accidentsTravailProps}
        mainAccidentCauseList={mainAccidentCauseList}
        setAccidentsList={setAccidentsList}
        initialAccidentsList={initialAccidentsList}
        accidentsList={accidentsList}
      />
      <Row />
      <MesuresEmpoussierage
        {...mesuresEmpoussierageProps}
        organismeMeasuringEmpoussierageList={
          organismeMeasuringEmpoussierageList
        }
        referentialOrganismeAccredite={referentialOrganismeAccredite}
      />
      <Row />
      <GlobalFormActionFullContext1919
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        validationPath={PATH_CARRIERE_SANTE}
        hasChanges={
          structureFonctionnelleProps.hasChanges ||
          accidentsTravailProps.hasChanges ||
          mesuresEmpoussierageProps.hasChanges
        }
        isValidateEnabled={
          !isPathValidatedInDeclaration(PATH_CARRIERE_SANTE) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_PREVENTION) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_ACCIDENT) &&
          isPathValidatedInDeclaration(PATH_CARRIERE_SANTE_EMPOUSSIERAGE)
        }
        cancelAction={() => {
          setAccidentsList(initialAccidentsList);
          structureFonctionnelleProps.resetForm();
          accidentsTravailProps.resetForm();
          mesuresEmpoussierageProps.resetForm();
        }}
        updateHandler={(declaration: Declaration1919) => {
          const structureFonctionnellesValues = structureFonctionnelleProps.getValues();
          const accidentsTravailValues = accidentsTravailProps.getValues();
          const mesuresEmpoussierageValues = mesuresEmpoussierageProps.getValues();
          if (
            structureFonctionnellesValues &&
            accidentsTravailValues &&
            mesuresEmpoussierageValues
          ) {
            return updateMesuresEmpoussierage(
              updateAccidentsTravail(
                updateStructureFonctionnelle(
                  declaration,
                  structureFonctionnellesValues,
                  referentialOrganismeExterieur
                ),
                accidentsTravailValues,
                accidentsList
              ),
              mesuresEmpoussierageValues,
              referentialOrganismeAccredite
            );
          }
          return declaration;
        }}
      />
    </>
  );
};

export default MainCarriereForm;
