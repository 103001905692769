import React, { ReactElement } from "react";
import { InfosGeneralesPdsDto } from "api/gen";
import { useDummyTextFieldGenerator } from "common/form/fields/helpers/generators";
import { makeStyles } from "@material-ui/styles";
import { FORM_FIELD_STYLE } from "pages/CompanySpace/from21/toNow/Quotas/Emissions/BlocRapportEmissions/RapportData/theme";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import PdsInstallationCategory from "./PdsInstallationCategory";

const useStyles = makeStyles({
  container: {
    margin: "50px 0",
  },
  ...FORM_FIELD_STYLE,
});

interface PdsInfosGeneralesProps {
  infosGenerales: InfosGeneralesPdsDto;
}

const PdsInfosGenerales = ({
  infosGenerales,
}: PdsInfosGeneralesProps): ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "65%",
    formPrefix: "pds-infos-generales",
  };
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.centeredInput
  );
  return (
    <div className={classes.container}>
      <SubSectionTitle>Informations générales du PDS</SubSectionTitle>
      <div className={classes.formContainer}>
        <DummyTextField
          name={"numeroNim"}
          label={"Numéro NIM"}
          value={infosGenerales.numeroNim}
        />
      </div>

      <PdsInstallationCategory
        categorieInstallation={infosGenerales.categorieInstallationDto}
      />
    </div>
  );
};

export default PdsInfosGenerales;
