import React, { useCallback } from "react";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { backAlertMessage } from "common/backErrors/utils";
import { backMessageReferentialUpdate } from "common/backErrors/errorMessages";
import { useAlertModale, useSuccessModale } from "common/modale/hooks";
import FileLoader from "common/button/FileLoader";
import { UploadReferentialViolationsDto } from "api/gen";
import _ from "lodash";

export interface UploadButtonProps {
  uploadHandler: (file: File) => Promise<UploadReferentialViolationsDto>;
  setErrors: (violations: UploadReferentialViolationsDto | null) => void;
  onUploadSuccessful?: () => Promise<void>;
  additionalClassname?: string;
  disabled?: boolean;
}

export const UploadButton = ({
  uploadHandler,
  setErrors,
  onUploadSuccessful,
  additionalClassname,
  disabled,
}: UploadButtonProps): React.ReactElement => {
  const [isUploadSpinnerVisible, triggerUploadSpinner] = useSpinnerState<
    void
  >();
  const openAlertModale = useAlertModale();
  const openSuccessModale = useSuccessModale();

  const uploadFile = useCallback(
    async (file: File): Promise<void> => {
      const submitAndLogErrors = async () => {
        try {
          const errors: UploadReferentialViolationsDto = await uploadHandler(
            file
          );
          if (errors && errors.violations && !_.isEmpty(errors.violations)) {
            await openAlertModale(
              "Des erreurs sont présentes. Merci de les corriger avant de réessayer."
            );
            setErrors(errors);
          } else {
            if (onUploadSuccessful) {
              await onUploadSuccessful();
            }
            await openSuccessModale("Le référentiel a été mis à jour.");
            setErrors(null);
          }
        } catch (e) {
          await openAlertModale(
            backAlertMessage(e, backMessageReferentialUpdate)
          );
        }
      };

      await triggerUploadSpinner(submitAndLogErrors());
    },
    [
      triggerUploadSpinner,
      uploadHandler,
      openAlertModale,
      setErrors,
      onUploadSuccessful,
      openSuccessModale,
    ]
  );

  return (
    <FileLoader
      onFileHandler={uploadFile}
      text={
        <>
          ENVOYER <FontAwesomeIcon icon="upload" />
        </>
      }
      isSpinnerVisible={isUploadSpinnerVisible}
      additionalClassname={additionalClassname}
      isDisabled={disabled}
    />
  );
};

export default UploadButton;
