import React, { Dispatch, SetStateAction } from "react";
import FormSingleCombustible from "./FormSingleCombustible";
import CustomModal from "common/presentational/CustomModal";
import { ReferenceCombustibleDto } from "api/gen";
import { CombustibleInArray, CombustibleInModale } from "./utils/types";
import { AppareilInArray } from "../BlocAppareils/utils/types";
import {
  EmissionsInModale,
  FacteurEmissionInArray,
} from "../BlocEmissions/utils/types";
import uuid from "uuid";
import { addOrModifyArray } from "common/declarant/array/utils";
import {
  defaultEmissionInModaleInitialValue,
  updateEmission,
} from "../BlocEmissions/utils/utils";
import { ReferentialSinglePolluants } from "../../utils/types";

interface CombustibleProps {
  combustibleModaleOpen: boolean;
  combustibleInModale: CombustibleInArray | null;
  setCombustiblesInArray: Dispatch<SetStateAction<CombustibleInArray[]>>;
  setCombustibleInModale: Dispatch<SetStateAction<CombustibleInArray | null>>;
  setCombustibleModaleOpen: Dispatch<SetStateAction<boolean>>;
  installationName: string | null;
  combustibleReferential: ReferenceCombustibleDto;
  appareilsInArray: AppareilInArray[];
  isTorchereBiogaz: boolean;
  isValorisationBiogaz: boolean;
  referentialSinglePolluants: ReferentialSinglePolluants;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  autreElementUuid: string;
}

const CombustibleModale = ({
  combustibleModaleOpen,
  combustibleInModale,
  setCombustiblesInArray,
  setCombustibleInModale,
  setCombustibleModaleOpen,
  installationName,
  combustibleReferential,
  appareilsInArray,
  isTorchereBiogaz,
  isValorisationBiogaz,
  referentialSinglePolluants,
  facteursInPage,
  setFacteursInPage,
  autreElementUuid,
}: CombustibleProps): React.ReactElement => {
  const onClose = () => {
    setCombustibleInModale(null);
    setCombustibleModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={combustibleModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de combustible de mention de danger."
      onRequestClose={onClose}
    >
      <FormSingleCombustible
        isTorchereBiogaz={isTorchereBiogaz}
        isValorisationBiogaz={isValorisationBiogaz}
        combustibleReferential={combustibleReferential}
        appareilsInArray={appareilsInArray}
        installationName={installationName}
        closeFunction={onClose}
        onSubmit={values => {
          if (values.type === null) {
            throw new Error("Validation failed.");
          }

          const typeObject = values.type;
          if (typeObject === null) {
            throw Error("Should not happen");
          }
          const idCombustible = combustibleInModale
            ? combustibleInModale.data.id
            : uuid();

          const newCombustible: CombustibleInModale = {
            type: typeObject,
            code: typeObject.codeCombustible,
            nameInstallation: installationName,
            consommation: values.consommation,
            unite: values.unite,
            appareils: values.appareils,
            masse: values.masse,
            masseEcart: values.masseEcart,
            eau: values.eau,
            carbone: values.carbone,
            soufre: values.soufre,
            surCendres: values.surCendres,
            cendres: values.cendres,
            chlore: values.chlore,
            pciValeur: values.pciValeur,
            pciUnite: values.pciUnite,
            pciEcart: values.pciEcart,
            pciBrut: values.pciBrut,
            pciProvenance: values.pciProvenance,
            biogazFraction: values.biogazFraction,
            biogazEcart: values.biogazEcart,
            biogazDebit: values.biogazDebit,
            biogazMesure: values.biogazMesure,
            biogazFrequence: values.biogazFrequence,
            biogazDuree: values.biogazDuree,
            ch4Teneur: values.ch4Teneur,
            ch4Mesure: values.ch4Mesure,
            ch4Frequence: values.ch4Frequence,
            quantiteMethaneOxyde: values.quantiteMethaneOxyde,
            consommationPCI: values.consommationPCI,
          };
          // Update emissions linked to the combustible
          if (combustibleInModale) {
            const newFacteurs: FacteurEmissionInArray[] = facteursInPage.map(
              facteur => {
                if (
                  facteur.data.combustible &&
                  combustibleInModale.data.id ===
                    facteur.data.combustible.data.id
                ) {
                  const emissionInModale: EmissionsInModale = {
                    // On a besoin d'un objet EmissionsInModale complet pour respecter le typage
                    ...defaultEmissionInModaleInitialValue,
                    ...facteur.data,
                    combustible: {
                      data: {
                        ...newCombustible,
                        id: combustibleInModale.data.id,
                      },
                      errors: {},
                    },
                  };
                  const updateEmissionInModale: EmissionsInModale = updateEmission(
                    emissionInModale,
                    newCombustible.nameInstallation,
                    referentialSinglePolluants,
                    autreElementUuid
                  );
                  const newFacteur: FacteurEmissionInArray = {
                    data: {
                      ...updateEmissionInModale,
                      appareils:
                        combustibleInModale.data.appareils !==
                        newCombustible.appareils
                          ? newCombustible.appareils
                          : facteur.data.appareils,
                      id: facteur.data.id,
                    },
                    errors: facteur.errors,
                  };
                  return newFacteur;
                } else {
                  return facteur;
                }
              }
            );
            setFacteursInPage(newFacteurs);
          }

          addOrModifyArray(
            setCombustiblesInArray,
            combustibleInModale && combustibleInModale.data.id,
            newCombustible,
            idCombustible
          );

          onClose();
        }}
        isEdit={combustibleInModale !== null}
        initialObject={
          combustibleInModale !== null
            ? combustibleInModale.data
            : {
                nameInstallation: null,
                type: null,
                code: null,
                consommation: null,
                unite: null,
                appareils: null,
                masse: null,
                masseEcart: null,
                eau: null,
                carbone: null,
                soufre: null,
                surCendres: null,
                cendres: null,
                chlore: null,
                pciValeur: null,
                pciUnite: null,
                pciEcart: null,
                pciBrut: null,
                pciProvenance: null,
                biogazFraction: null,
                biogazEcart: null,
                biogazDebit: null,
                biogazMesure: null,
                biogazFrequence: null,
                biogazDuree: null,
                ch4Teneur: null,
                ch4Mesure: null,
                ch4Frequence: null,
                quantiteMethaneOxyde: null,
                consommationPCI: null,
              }
        }
      />
    </CustomModal>
  );
};

export default CombustibleModale;
