import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../utils/utils";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { createAppareilDiv } from "./utils/utils";
import { EmissionsInArray, MatiereEmissionInArray } from "./utils/types";
import _ from "lodash";
import { ERROR } from "theme";
import { ClassesType } from "common/utils/types";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  matiereEmissionsInArray: MatiereEmissionInArray[];
  setMatiereEmissionsInArray: Dispatch<
    SetStateAction<MatiereEmissionInArray[]>
  >;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationMatieresPath: string;
}

const EmissionArray = ({
  matiereEmissionsInArray,
  setMatiereEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationMatieresPath,
}: EmissionArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressEmission(singleEmissionInArray: MatiereEmissionInArray) {
    setMatiereEmissionsInArray(elderEmissions => {
      return elderEmissions.filter(elderEmissionInArray => {
        return singleEmissionInArray.data.id !== elderEmissionInArray.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: MatiereEmissionInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const substanceName =
      emission.data.substance && emission.data.substance.label;

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      substanceName || undefined,
      isValidated,
      !_.isEmpty(emission.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        emission.data.appareils && createAppareilDiv(emission.data.appareils),
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.intrants || undefined}>
          {emission.data.intrants}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.entrant !== null
          ? computeNumberWithSeparator(emission.data.entrant)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.sortant !== null
          ? computeNumberWithSeparator(emission.data.sortant)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.element || undefined}>
          {emission.data.element}
        </p>,
        classes
      ),
      computeWrappedCellWithError(
        emission.data.teneur !== null
          ? computeNumberWithSeparator(emission.data.teneur)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.part !== null
          ? computeNumberWithSeparator(emission.data.part)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        emission.data.biomasseMatiere !== null
          ? computeNumberWithSeparator(emission.data.biomasseMatiere)
          : "",
        classes
      ),
      computeWrappedCellWithError(
        !emission.data.epuration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCellWithError(
        <p title={emission.data.nature || undefined}>{emission.data.nature}</p>,
        classes
      ),
      computeWrappedCellWithError(emission.data.rendement, classes),
      computeWrappedCellWithError(
        emission.data.emissionsAnnuelles !== null
          ? computeNumberWithSeparator(
              Number(emission.data.emissionsAnnuelles.toFixed(3))
            )
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette emission ?"}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        isValidated={isValidated}
        commentPath={`${validationMatieresPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = filterByInstallation(
    matiereEmissionsInArray,
    installationName || ""
  ).map(matiere => {
    return computeSingleEmissionLine(matiere, classes);
  });

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Description des intrants</p>,
        <p>Quantités entrantes (kg)</p>,
        <p>Quantité sortantes hors émissions (kg)</p>,
        <p>Élément sur lequel est indexé le bilan matière</p>,
        <p>Teneur moyenne de l'élément dans les intrants (%)</p>,
        <p>Part de l'élément dans la substance émise (%)</p>,
        <p>Fraction de la biomasse (%)</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        130,
        130,
        200,
        130,
        130,
        130,
        130,
        200,
        130,
        130,
        90,
      ]}
    />
  );
};

export default EmissionArray;
