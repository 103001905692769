import { LegacySubInstallationElement } from "../../../utils/types";
import { InArray, InModale } from "common/form/utils";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import {
  AirCombustionCombustibleDto20NowSurCendresEnum,
  AirCombustionCombustibleDto20NowUniteEnum,
  AirCombustionCombustiblePciDtoBrutEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
  ReferenceItemCombustibleDto,
} from "api/gen";
import { fieldMatcherBuilder } from "common/declarant/formik/utils";

/**
 * @deprecated to be supressed to use a backoffice DTO (when optionProps will be ditched)
 */
export interface LegacyCombustible extends LegacySubInstallationElement {
  type: ReferenceItemCombustibleDto;
  code: string;
  consommation: number;
  unite: AirCombustionCombustibleDto20NowUniteEnum;
  appareils: AppareilInArray[];
  masse: number;
  masseEcart: string;
  eau: number;
  carbone: number;
  soufre: number;
  surCendres: AirCombustionCombustibleDto20NowSurCendresEnum;
  cendres: number;
  chlore: number;
  pciValeur: number;
  pciUnite: AirCombustionCombustiblePciDtoUniteEnum;
  pciEcart: string;
  pciBrut: AirCombustionCombustiblePciDtoBrutEnum;
  pciProvenance: string;
  biogazFraction: number;
  biogazEcart: string;
  biogazDebit: number;
  biogazMesure: boolean;
  biogazFrequence: number;
  biogazDuree: number;
  ch4Teneur: number;
  ch4Mesure: boolean;
  ch4Frequence: number;
  quantiteMethaneOxyde: number;
  consommationPCI: number;
}

export type CombustibleInModale = InModale<LegacyCombustible>;
export type CombustibleInArray = InArray<LegacyCombustible>;

export const combustibleFieldMatcher = fieldMatcherBuilder<
  CombustibleInModale
>().build({
  type: "type",
  code: "code",
  consommation: "consommation",
  unite: "unite",
  appareils: "appareils",
  masse: "masse",
  masseEcart: "masseEcart",
  eau: "eau",
  carbone: "carbone",
  soufre: "soufre",
  surCendres: "surCendres",
  cendres: "cendres",
  chlore: "chlore",
  pciValeur: "pciValeur",
  pciUnite: "pciUnite",
  pciEcart: "pciEcart",
  pciBrut: "pciBrut",
  pciProvenance: "pciProvenance",
  biogazFraction: "biogazFraction",
  biogazEcart: "biogazEcart",
  biogazDebit: "biogazDebit",
  biogazMesure: "biogazMesure",
  biogazFrequence: "biogazFrequence",
  biogazDuree: "biogazDuree",
  ch4Teneur: "ch4Teneur",
  ch4Mesure: "ch4Mesure",
  ch4Frequence: "ch4Frequence",
  quantiteMethaneOxyde: "quantiteMethaneOxyde",
  consommationPCI: "consommationPCI",
});
