import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { CommonProps } from "common/form/utils";
import { PATH_AIR_ELEVAGE_MTD } from "common/path/path18Now";
import BlocFullContext20Now from "../../../versionedElements/BlocFullContext20Now";
import { useDeclarationHelpers20Now } from "../../../versionedElements/declarationHooks20Now";
import BlocInformations from "../BlocInformations";

const useStyles = makeStyles({
  header: {
    color: "#6b6b6b",
    textTransform: "uppercase",
    marginBottom: "20px",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
});

const BlocCheckboxes = (): React.ReactElement => {
  const classes = useStyles();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers20Now();

  const commonProps: CommonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "55%",
    formPrefix: "bloc-checkboxes",
  };

  const DummyCheckBox = useDummyBooleanCheckboxFieldGenerator(commonProps);

  return (
    <>
      <BlocFullContext20Now
        hasModification={false}
        isValidateButtonAvailable={
          !isPathValidatedInDeclaration(PATH_AIR_ELEVAGE_MTD)
        }
        title=""
        path={PATH_AIR_ELEVAGE_MTD}
        // L'update et le cancel sont des props à garder obligatoire, mais elles ne sont pas utilisées ici
        updateHandler={declarationToUpdate => declarationToUpdate}
        cancelAction={() => {
          return;
        }}
        renderContent={(shouldDisabledFields: boolean) => (
          <>
            <h5 className={classes.header}>
              Données héritées d'informations générales
            </h5>

            <DummyCheckBox
              name="inheritedBreeding"
              label="L'établissement est un établissement d'élevage intensif de volailles ou de porcs (rubrique 3660)"
              tooltipContent={
                <>
                  Élevage intensif comprenant :
                  <ul>
                    <li>Plus de 40 000 emplacements pour les volailles</li>
                    <li>
                      Ou plus de 2 000 emplacements pour les porcs de plus de
                      30kg
                    </li>
                    <li>Ou plus de 750 emplacements pour les truies</li>
                  </ul>
                </>
              }
              value={true}
              disabled
            />

            <BlocInformations shouldDisabledFields={shouldDisabledFields} />
          </>
        )}
      />
    </>
  );
};

export default BlocCheckboxes;
