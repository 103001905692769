import React, { ReactElement } from "react";

import { DASHBOARD_BACKGROUND_GREY } from "theme";
import { makeStyles } from "@material-ui/styles";
import Button from "common/button";
import { useConfirmationModale } from "common/modale/hooks";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import {
  useBasicDeclarationHandlers21Now,
  useDeclarationHelpers21Now,
  useDeclarationStateHandlers21Now,
} from "../../versionedElements/declarationHooks21Now";
import { QuotaWorkflowTarget } from "common/utils/types";
import {
  TakeOverModalContent,
  TakeOverModalTitle,
} from "../../Quotas/common/BlocInspecteur/takeOverModal";

const useStyles = makeStyles({
  takeoverButton: {
    background: DASHBOARD_BACKGROUND_GREY,
    color: "black",
  },
});

const QuotaTakeoverButton = ({
  workflowTarget,
  path,
}: {
  workflowTarget: QuotaWorkflowTarget;
  path: string;
}): ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState();
  const { takeover } = useDeclarationStateHandlers21Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers21Now();
  const { cancelValidate } = useBasicDeclarationHandlers21Now();

  return (
    <Button
      onClick={() =>
        openConfirmationModale(
          TakeOverModalTitle,
          async () => {
            await triggerSpinner(takeover([workflowTarget]));
            if (isPathValidatedInDeclaration(path)) {
              cancelValidate(path);
            }
          },
          TakeOverModalContent
        )
      }
      text={"Déclaration d'office"}
      isSpinnerVisible={isSpinnerVisible}
      additionalClassname={classes.takeoverButton}
    />
  );
};

export default QuotaTakeoverButton;
