import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BACKGROUND_GREY,
  GREEN,
  LIGHT_GREEN,
  LIGHT_PINK,
  PINK,
} from "theme";
import classNames from "classnames";

const useStyles = makeStyles({
  progressWrap: {
    height: "100%",
    width: "100%",
    background: DASHBOARD_BACKGROUND_GREY,
    overflow: "hidden",
    position: "relative",
  },
  progressBar: {
    height: "100%",
    left: 0,
    position: "absolute",
    top: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  progressIndication: {
    color: "white",
  },
});

interface ProgressBarProps {
  percentageProgress: number;
  isSmall?: boolean;
  additionalClassname?: string;
  isValidated: boolean;
}

const ProgressBar = ({
  percentageProgress,
  isSmall,
  additionalClassname,
  isValidated,
}: ProgressBarProps) => {
  // a lot inspired by https://codepen.io/Tan ya_Rybachuk/pen/EZdbrG
  const classes = useStyles({ isSmall: !!isSmall });

  const additionalStyle = {
    width: `${percentageProgress}%`,
    background: `linear-gradient(to right, ${PINK} 0%, ${LIGHT_PINK} 100%)`,
  };

  if (isValidated) {
    additionalStyle.background = `linear-gradient(to right, ${GREEN} 0%, ${LIGHT_GREEN} 100%)`;
  }

  return (
    <div
      className={classNames(classes.progressWrap, additionalClassname)}
      data-progress={percentageProgress}
    >
      <div className={classes.progressBar} style={additionalStyle}>
        {!isSmall && (
          <p className={classes.progressIndication}>{percentageProgress}%</p>
        )}
      </div>
    </div>
  );
};
export default ProgressBar;
