import React from "react";
import { makeStyles } from "@material-ui/styles";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { LONG_TEXT_INPUT_WIDTH } from "theme";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import { singleInstallationValidationSchema } from "./validation/validation";
import {
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { solvantsUniqueInstallationMessage } from "common/declarant/formik/formikMessages";
import { InstallationInArray, InstallationInModale } from "./utils/types";

interface FormSingleInstallationProps {
  closeFunction: () => void;

  isEdit: boolean;
  onSubmit: (
    values: InstallationInModale,
    formikActions: FormikActions<InstallationInModale>
  ) => void;
  initialInstallation: InstallationInModale;
  installationInModal: InstallationInArray | null;
  installationsInPage: InstallationInArray[];
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
});

const FormSingleInstallation = ({
  closeFunction,
  onSubmit,
  initialInstallation,
  installationInModal,
  isEdit,
  installationsInPage,
}: FormSingleInstallationProps): React.ReactElement => {
  const classes = useStyles();

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-installation-solvants",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const NumberField = useNumberFieldGenerator(commonProps);

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE INSTALLATION"
      closeFunction={closeFunction}
      onSubmit={(values: InstallationInModale, formikBag) => {
        onSubmit(values, formikBag);
      }}
      initialEntity={initialInstallation}
      isEdit={isEdit}
      validationSchema={singleInstallationValidationSchema}
      validate={values => {
        let error = {};
        if (
          !installationInModal ||
          installationInModal.data.nom !== values.nom
        ) {
          if (
            installationsInPage.some(installation => {
              return installation.data.nom === values.nom;
            })
          ) {
            error = {
              nom: solvantsUniqueInstallationMessage,
            };
          }
        }
        return error;
      }}
      renderField={() => {
        return (
          <>
            <TextField name="nom" label="Nom de l'installation *" />
            <NumberField
              name="heures"
              label="Nombre d'heures de fonctionnement *"
              unit="h/an"
              tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
            />
            <NumberField
              name="volume"
              label="Volume d'activité *"
              unit=""
              tooltipContent={
                <span>
                  Unité en tonnes,m², m³, ou tout autre paramètre représentatif
                  de l'activité annuelle de l'installation
                </span>
              }
            />
            <TextField name="unite" label="Unité *" />
            <TextField
              name="produit"
              label="Type de produit(s)"
              tooltipContent="Le type de produit correspond ici au produit qui sort de l'installation concernée."
            />
          </>
        );
      }}
    />
  );
};

export default FormSingleInstallation;
