import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { TEXT_LIGHT_GREY } from "theme";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";

interface BlocActiviteProps {
  disabled: boolean;
}

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

export const BlocActivite = ({ disabled }: BlocActiviteProps) => {
  const {
    referenceInfoGeneralController,
    referenceQuotasController,
  } = useAuthenticatedApi();

  const classes = useStyles();

  // Codes APE
  const uploadCodeApeHandler = useCallback(
    (file: File) => {
      return referenceInfoGeneralController.uploadCodeApeUsingPOST(file);
    },
    [referenceInfoGeneralController]
  );

  const downloadCodeApeHandler = useCallback(() => {
    return referenceInfoGeneralController.downloadCodeApeAsCsvUsingGET();
  }, [referenceInfoGeneralController]);

  // EPRTR
  const uploadEprtrHandler = useCallback(
    (file: File) => {
      return referenceInfoGeneralController.uploadEprtrUsingPOST(file);
    },
    [referenceInfoGeneralController]
  );

  const downloadEprtrHandler = useCallback(() => {
    return referenceInfoGeneralController.downloadEprtrAsCsvUsingGET();
  }, [referenceInfoGeneralController]);

  // Activités quotas
  const uploadActiviteQuotaHandler = useCallback(
    (file: File) => {
      return referenceQuotasController.uploadActiviteQuotaUsingPOST(file);
    },
    [referenceQuotasController]
  );

  const downloadActiviteQuotaHandler = useCallback(() => {
    return referenceQuotasController.downloadActiviteQuotaAsCsvUsingGET();
  }, [referenceQuotasController]);

  return (
    <DummyUnActionedBloc
      title="Infos Générales"
      renderContent={() => {
        return (
          <>
            {disabled && (
              <>
                <div className={classes.disabledText}>
                  Aucune campagne future à l'état prête n'a été trouvée. Vous ne
                  pouvez pas modifier les référentiels d'une campagne en cours.
                </div>
                <Row />
              </>
            )}
            <BlocReferentiel
              title="Codes APE"
              downloadHandler={downloadCodeApeHandler}
              filename="codes_ape.csv"
              uploadHandler={uploadCodeApeHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Activités E-PRTR"
              downloadHandler={downloadEprtrHandler}
              filename="eprtr.csv"
              uploadHandler={uploadEprtrHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Activités Quotas"
              downloadHandler={downloadActiviteQuotaHandler}
              filename="activite_quota.csv"
              uploadHandler={uploadActiviteQuotaHandler}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default BlocActivite;
