import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleInstallation from "./FormSingleInstallation";
import { addOrModifyArray } from "common/declarant/array/utils";
import cloneDeep from "lodash.clonedeep";
import { LegacySubInstallationElement } from "../utils/types";
import { InArray } from "common/form/utils";
import { InstallationInArray, InstallationInModale } from "./utils/types";
import { AppareilInArray } from "../ListInstallation/BlocAppareils/utils/types";
import { CombustibleInArray } from "../ListInstallation/BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../ListInstallation/BlocEmissions/utils/types";
import { isAppareilSubpartLcpFormAvailable } from "../ListInstallation/BlocAppareils/utils/utils";
import { TypeActiviteGlobalDto21Now } from "api/gen";

interface InstallationModaleProps {
  isOpen: boolean;
  installationInModal: InstallationInArray | null;
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  setInstallationInModale: Dispatch<SetStateAction<InstallationInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  isLcp: boolean;
  isWicowi: boolean;
  installationsInPage: InstallationInArray[];
  setAppareilsInPage: React.Dispatch<React.SetStateAction<AppareilInArray[]>>;
  setCombustiblesInPage: React.Dispatch<
    React.SetStateAction<CombustibleInArray[]>
  >;
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  typeActiviteGlobal: TypeActiviteGlobalDto21Now;
}

const InstallationModale = ({
  isOpen,
  installationInModal,
  setInstallationsInPage,
  setInstallationInModale,
  setModaleOpen,
  isLcp,
  isWicowi,
  installationsInPage,
  setAppareilsInPage,
  setCombustiblesInPage,
  setFacteursInPage,
  setMesuresInPage,
  setMatieresInPage,
  typeActiviteGlobal,
}: InstallationModaleProps): React.ReactElement => {
  const onClose = () => {
    setInstallationInModale(null);
    setModaleOpen(false);
  };

  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout d'installations."
      onRequestClose={onClose}
    >
      <FormSingleInstallation
        isLcp={isLcp}
        isWicowi={isWicowi}
        typeActiviteGlobal={typeActiviteGlobal}
        forbiddenInstallationName={installationsInPage
          .map(installation => installation.data.nom || "")
          .filter(installationName => {
            if (installationName === "") {
              return false;
            }
            if (installationInModal !== null) {
              return installationName !== installationInModal.data.nom; //If the name is different than the name in the modal, we keep it.
            }
            return true; //If there is no procede in the modal, we keep all procedes names.
          })}
        closeFunction={onClose}
        onSubmit={values => {
          const newInstallation: InstallationInModale = {
            nom: values.nom,
            type: values.type,
            heure: values.heure,
            volumeActivite: values.volumeActivite,
            unite: values.unite,
            typeProduit: values.typeProduit,
            quantiteChaleur: values.quantiteChaleur,
            quantiteElectricite: values.quantiteElectricite,
            rendementChaleur: values.rendementChaleur,
            rendementElectricite: values.rendementElectricite,
          };
          if (installationInModal) {
            const modifySubElementBasedOnInstallation = <
              T extends LegacySubInstallationElement
            >(
              content: InArray<T>[]
            ) => {
              // Update appareil name for all dependent objects
              const newAppareils = cloneDeep(content);
              for (let i = 0; i < content.length; i++) {
                if (
                  content[i].data.nameInstallation ===
                    installationInModal.data.nom &&
                  values.nom !== installationInModal.data.nom
                ) {
                  newAppareils[i].data.nameInstallation = values.nom;
                }
              }
              return newAppareils;
            };
            setAppareilsInPage(currentAppareils => {
              const appareils = modifySubElementBasedOnInstallation(
                currentAppareils
              );
              // Overwriting dependent fields
              const isInstallationLcp = isAppareilSubpartLcpFormAvailable(
                newInstallation.type
              );
              appareils
                .filter(
                  appareil =>
                    appareil.data.nameInstallation === newInstallation.nom
                )
                .forEach(appareil => {
                  appareil.data.modification = isInstallationLcp
                    ? !!appareil.data.modification
                    : null;
                  appareil.data.lowestThan1500 = isInstallationLcp
                    ? !!appareil.data.lowestThan1500
                    : null;
                });

              return appareils;
            });
            setCombustiblesInPage(currentCombustibles =>
              modifySubElementBasedOnInstallation(currentCombustibles)
            );
            setFacteursInPage(currentFacteurs =>
              modifySubElementBasedOnInstallation(currentFacteurs)
            );
            setMesuresInPage(currentMesures =>
              modifySubElementBasedOnInstallation(currentMesures)
            );
            setMatieresInPage(currentMatieres =>
              modifySubElementBasedOnInstallation(currentMatieres)
            );
          }
          addOrModifyArray(
            setInstallationsInPage,
            installationInModal && installationInModal.data.id,
            newInstallation
          );
          onClose();
        }}
        initialInstallation={installationInModal && installationInModal.data}
      />
    </CustomModal>
  );
};

export default InstallationModale;
