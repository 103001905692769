import React from "react";
import { PATH_QUOTAS_ALC_VERIFIE } from "common/path/path20Now";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_STYLE } from "theme";
import AlcRapportFileLoader from "./AlcRapportFileLoader";
import JustificatifsFilesLoader from "./JustificatifsFilesLoader";
import { useNiveauxActiviteValidationMessage } from "../../../utils/utils";
import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import RapportAlcExtractedData from "./RapportExtractedData/RapportAlcExtractedData";
import { useDeclaration24Now } from "../../../../versionedElements/declarationHooks24Now";
import BlocFullContext24Now from "../../../../versionedElements/BlocFullContext24Now";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ...SECTION_TITLE_STYLE,
});

const QuotasAlcVerifieForm = (): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const validationMessage = useNiveauxActiviteValidationMessage();
  const installations =
    declaration.computed.sections.quotas.allocations.installations;
  const rapportAlcDataList =
    declaration.body.externalSections.quotas.allocations.blocAlc.alcVerifie
      .alcVerifieFiles;

  const blocPath = PATH_QUOTAS_ALC_VERIFIE;

  return (
    <BlocFullContext24Now
      hasModification={false}
      isValidateButtonAvailable={true}
      editValidationMessage={validationMessage}
      title={`RAPPORT DE DECLARATION DES NIVEAUX D’ACTIVITE (VERIFIE)`}
      path={blocPath}
      updateHandler={declaration => {
        return declaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaAllocations.state}
      cancelAction={() => {
        return;
      }}
      renderContent={shouldDisabledFields => (
        <>
          <Row height={"20px"} />
          {installations.map(installation => (
            <>
              <div className={classes.row} key={installation.id}>
                <div className={classes.sectionTitle}>
                  NIM à remplir : {installation.nim}
                  <CommentButton path={`${blocPath}/${installation.id}`} />
                </div>
                <AlcRapportFileLoader
                  installation={installation}
                  shouldDisableFields={shouldDisabledFields}
                />
                <JustificatifsFilesLoader
                  installation={installation}
                  shouldDisableFields={shouldDisabledFields}
                />
              </div>
              <Row height={"20px"} />
              <RapportAlcExtractedData
                installation={installation}
                rapportAlcDataList={rapportAlcDataList}
              />
            </>
          ))}
        </>
      )}
    />
  );
};

export default QuotasAlcVerifieForm;
