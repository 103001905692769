import { FiltresEtablissementDtoSortByEnum } from "api/gen";
import SortableTableHeaderView from "common/presentational/tableHeaderSorted/SortableTableHeaderView";

export const useCompanyTableHeader = (
  sortField: FiltresEtablissementDtoSortByEnum | null,
  isSortReversed: boolean | null,
  updateFilter: (
    field: FiltresEtablissementDtoSortByEnum | null,
    reversed: boolean | null
  ) => void
): React.ReactElement[] => {
  const content = [
    {
      field: FiltresEtablissementDtoSortByEnum.ETABLISSEMENT_NAME,
      name: "Établissement",
    },
    {
      field: FiltresEtablissementDtoSortByEnum.ID,
      name: "Code établissement",
    },
    {
      field: FiltresEtablissementDtoSortByEnum.NATURE_SERVICE,
      name: "Organisme d'inspection",
    },
    {
      field: FiltresEtablissementDtoSortByEnum.DEPARTEMENT,
      name: "Département",
    },
    {
      field: FiltresEtablissementDtoSortByEnum.REGION,
      name: "Région",
    },
  ];

  return SortableTableHeaderView({
    columns: content,
    sortField: sortField,
    isSortReversed: isSortReversed,
    setSortFieldAndIsReversed: updateFilter,
  });
};
