import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LINK_STYLE, TEXT_LIGHT_GREY } from "theme";
import classNames from "classnames";
import FooterHelp from "./FooterHelp";
import Row from "../presentational/Row";

const useStyles = makeStyles({
  nav: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  navItem: {
    color: TEXT_LIGHT_GREY,
    display: "inline",
    padding: "0 8px",
    borderRight: `${TEXT_LIGHT_GREY} 1px solid`,
  },
  noBorder: {
    border: 0,
  },
  link: LINK_STYLE,
});

const Footer: React.FC = () => {
  const classes = useStyles();

  const arrayLinks = [
    { label: "legifrance.gouv.fr", link: "https://www.legifrance.gouv.fr/" },
    { label: "service-public.fr", link: "https://www.service-public.fr/" },
    { label: "gouvernement.fr", link: "https://www.gouvernement.fr/" },
    { label: "france.fr", link: "https://www.france.fr/fr" },
    { label: "data.gouv.fr", link: "https://www.data.gouv.fr/fr/" },
  ];

  return (
    <footer>
      <FooterHelp />

      <Row />

      <ul className={classes.nav}>
        {arrayLinks.map((navItem, i) => {
          const liClassNames: string = classNames(classes.navItem, {
            [classes.noBorder]: i === arrayLinks.length - 1,
          });
          return (
            <li key={navItem.label} className={liClassNames}>
              <a
                href={navItem.link}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {navItem.label}
              </a>
            </li>
          );
        })}
      </ul>
      <Row height="10px" />
    </footer>
  );
};

export default Footer;
