import CommentButton from "pages/CompanySpace/Comments/CommentButton";
import { CommentValues } from "pages/CompanySpace/Comments/commentTypes";
import { useDeclaration } from "../../genericHooks";

export const useCorrectYearCommentButton = (): ((props: {
  path: string;
  isAlertOrError?: boolean;
  additionalClassName?: string;
}) => React.ReactElement) => {
  //eslint-disable-next-line
  const year = useDeclarationYear();
  // currently year doesn't have any effect, but when it will have, adding a switch here will be easy
  return CommentButton;
};

export const useCorrectYearCommentsToDisplay = (
  comments: CommentValues[],
  isWarning: boolean
): CommentValues[] => {
  const year = useDeclarationYear();

  switch (year) {
    case 2019:
      return comments;
    default:
      return comments.filter(comment => comment.isAlertOrError === isWarning);
  }
};

const useDeclarationYear = (): number => {
  return useDeclaration().annee;
};
