import { AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum } from "../../../../../../../api/gen";

export const unitConcentrationMoyLabels: {
  [key in AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum]: string;
} = {
  KG_NM3: "kg/Nm\u00B3",
  G_NM3: "g/Nm\u00B3",
  MG_NM3: "mg/Nm\u00B3",
  UG_NM3: "\u00B5g/Nm\u00B3",
  NG_NM3: "ng/Nm\u00B3",
  PG_NM3: "pg/Nm\u00B3",
};

export const arrayFugitivesConcentrationUnits = Object.keys(
  unitConcentrationMoyLabels
) as AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum[];
