import { makeStyles } from "@material-ui/styles";
import { DISABLED_BUTTON_HOVER_BACKGROUND, SECTION_TITLE_GREY } from "theme";
import React from "react";
import CustomModal from "common/presentational/CustomModal";
import Button from "common/button";
import Row from "common/presentational/Row";
import danger from "icons/danger.svg";

const useStyles = makeStyles({
  container: {
    width: "30%",
    height: "60%",
  },
  warningIcon: {
    width: "10%",
    marginRight: "20px",
  },
  warningContainer: {
    display: "flex",
    textAlign: "justify",
    color: SECTION_TITLE_GREY,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cancel: {
    color: SECTION_TITLE_GREY,
    width: "auto",
    boxShadow: "none",
    background: "none",
    marginRight: "30px",
    "&:hover": {
      backgroundColor: DISABLED_BUTTON_HOVER_BACKGROUND,
    },
  },
  download: {
    padding: "10%",
  },
  link: {
    width: "50px",
    marginRight: "60px",
    textDecoration: "none",
  },
  row: {
    justifyContent: "flex-end",
    marginTop: "15%",
  },
});

interface AlcModeleViergeModaleProps {
  onRequestClose: () => void;
}

export const AlcModeleViergeModale = ({
  onRequestClose,
}: AlcModeleViergeModaleProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <CustomModal
      isOpen={true}
      onRequestClose={onRequestClose}
      className={classes.container}
    >
      <div className={classes.warningContainer}>
        <img
          src={danger}
          alt="un panneau attention"
          className={classes.warningIcon}
        />
        <p>
          Attention, il est impératif, dès lors que cela est possible, de
          repartir du modèle de fichier de l'année précédente disponible au
          travers du bouton "Télécharger votre rapport de l'année précédente".
        </p>
      </div>
      <Row additionalClassname={classes.row}>
        <div className={classes.buttonContainer}>
          <Button
            text="ANNULER"
            additionalClassname={classes.cancel}
            onClick={onRequestClose}
          />
          <a
            href={`${process.env.PUBLIC_URL}/download/ALC_template_COM_fr_2023-01-30.xls`}
            className={classes.link}
            download
          >
            {
              <Button
                text="TÉLÉCHARGER"
                additionalClassname={classes.download}
                onClick={onRequestClose}
              />
            }
          </a>
        </div>
      </Row>
    </CustomModal>
  );
};
