import { SimpleInstallationDto } from "api/gen";
import { ButtonStateHandler } from "common/button/loadingAndRedirectionHelpers";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import CustomModal from "common/presentational/CustomModal";
import { useDeclarationExcelExport } from "pages/CompanySpace/DeclarationApiContext/utils/exportHooks";
import React from "react";
import { useBooleanCheckBoxGenerator } from "common/form/fields/helpers/generators";
import { useAllDeclarationFilesExport } from "pages/CompanySpace/DeclarationApiContext/utils/filesHooks";

interface DownloadChoice {
  exportExcel: boolean;
  fichiers: boolean;
}

interface DownloadModalProps {
  isOpen: boolean;
  handleClose: () => void;
  declaration: SimpleInstallationDto;
  triggerExportLoading: ButtonStateHandler<boolean>;
}

const VerificateurDownloadModal = ({
  isOpen,
  handleClose,
  declaration,
  triggerExportLoading,
}: DownloadModalProps): React.ReactElement => {
  const exportDeclaration = useDeclarationExcelExport();
  const exportDeclarationFiles = useAllDeclarationFilesExport();

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "50%",
    formPrefix: "modale-téléchargment-déclaration",
  };

  const BooleanCheckboxField = useBooleanCheckBoxGenerator(commonProps);

  const initialValues: DownloadChoice = {
    exportExcel: false,
    fichiers: false,
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={handleClose}>
      <CommonFormSingleEntity
        title={""}
        isEdit={true}
        closeFunction={handleClose}
        initialEntity={initialValues}
        onSubmit={(values: DownloadChoice) => {
          if (values.exportExcel) {
            triggerExportLoading(
              exportDeclaration(declaration.annee, declaration.etablissement)
            );
          }
          if (values.fichiers) {
            triggerExportLoading(
              exportDeclarationFiles(
                declaration.annee,
                declaration.etablissement
              )
            );
          }
          handleClose();
        }}
        renderField={() => {
          return (
            <>
              <BooleanCheckboxField
                name="exportExcel"
                label="Télécharger l'export Excel de la déclaration"
              />
              <BooleanCheckboxField
                name="fichiers"
                label="Télécharger tous les fichiers déposés dans la déclaration"
              />
            </>
          );
        }}
      />
    </CustomModal>
  );
};

export default VerificateurDownloadModal;
