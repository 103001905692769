import React, { useEffect, useState } from "react";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { makeStyles } from "@material-ui/styles";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { useUserData } from "Authenticator/UserData";
import { useAlertModale } from "common/modale/hooks";
import BlocActivite from "./BlocActivite";
import Row from "common/presentational/Row";
import BlocGeographie from "./BlocGeographie";
import BlocPolluants from "./BlocPolluants";
import BlocAir from "./BlocAir";
import BlocCarriere from "./BlocCarriere";
import BlocDechet from "./BlocDechet";
import BlocEau from "./BlocEau";
import BlocSol from "./BlocSol";
import BlocMails from "./BlocMails";
import { BlocChangementsPostCampagne } from "./BlocChangementsPostCampagne";

const useStyles = makeStyles({
  main: {
    maxWidth: 1024,
    margin: "15px auto",
  },
});

export const ReferentialsManagement = (): React.ReactElement => {
  const pagePath = [
    {
      label: "Accueil",
      to: "/accueil",
    },
    {
      label: "Référentiels",
      to: "/admin/referentiels",
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "gestion des référentiels",
    image: null,
  };
  usePageHeaderSetterOnce(headerData);
  const classes = useStyles();
  const openAlertModale = useAlertModale();
  const { isAdmin } = useUserData();

  const { campaignController } = useAuthenticatedApi();

  const [isNextCampaignPlanned, setIsNextCampaignPlanned] = useState<boolean>(
    false
  );
  useEffect(() => {
    campaignController
      .getNextCampaignUsingGET()
      .then(campaignDto => {
        setIsNextCampaignPlanned(
          campaignDto && campaignDto.stateGlobal === "PLANNED"
        );
      })
      .catch(e => {
        if (!(e instanceof Response) || e.status !== 404) {
          openAlertModale(
            "Une erreur est survenue lors de la récupération de l'état de la prochaine campagne"
          );
        }
      });
  }, [campaignController, openAlertModale]);

  return (
    <div className={classes.main}>
      Ouvrir les documents sous libreoffice permettra d'éviter les erreurs
      d'encodage des caractères spéciaux.
      {/* Mettre ici les blocs de chaque référentiel */}
      <Row />
      <BlocActivite disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocGeographie disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocPolluants disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocAir disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocCarriere disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocDechet disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocEau disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocSol disabled={!isNextCampaignPlanned} />
      <Row />
      <BlocMails />
      <Row />
      {isAdmin && (
        <>
          <BlocChangementsPostCampagne />
          <Row />
        </>
      )}
    </div>
  );
};

export default ReferentialsManagement;
