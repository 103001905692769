import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { PINK } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import classNames from "classnames";
import CommonEntityTable, {
  ACTION_COLUMN_DEFAULT_WIDTH,
} from "common/presentational/CommonEntityTable";
import { createProcedeDiv } from "../utils/utils";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import { EmissionsInArray, MesureEmissionInArray } from "./utils/types";

const useStyles = makeStyles({
  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
  error: {
    backgroundColor: "red",
  },
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

const computeWrappedCell = (
  contentCell: React.ReactNode,
  classes: ClassesType<"full" | "error">,
  errorMessage?: string
) => {
  return (
    <div
      className={classNames(classes.full, { [classes.error]: errorMessage })}
    >
      {contentCell}
    </div>
  );
};

interface EmissionArrayProps {
  mesureEmissionsInPage: MesureEmissionInArray[];
  setMesureEmissionsInPage: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationEmissionPath: string;
}

const EmissionMesureArray = ({
  mesureEmissionsInPage,
  setMesureEmissionsInPage,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  validationEmissionPath,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();

  function suppressEmission(singleEmissionInPage: MesureEmissionInArray) {
    setMesureEmissionsInPage(elderEmissions => {
      return elderEmissions.filter(elderEmissionInPage => {
        return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
      });
    });
  }

  const computeSingleEmissionLine = (
    emission: MesureEmissionInArray,
    classes: ClassesType<"link" | "full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setEmissionInModale(emission);
      setEmissionModaleOpen(true);
    };

    const emissionTitleString =
      emission.data.substance !== null ? emission.data.substance.label : "";

    return [
      computeWrappedCell(
        isValidated ? (
          <p title={emissionTitleString}>{emissionTitleString}</p>
        ) : (
          <p
            className={classes.link}
            onClick={editAction}
            title={emissionTitleString}
          >
            {emissionTitleString}
          </p>
        ),
        classes
      ),
      computeWrappedCell(
        emission.data.procede && createProcedeDiv(emission.data.procede),
        classes
      ),
      computeWrappedCell(
        emission.data.debitHoraire !== null
          ? computeNumberWithSeparator(emission.data.debitHoraire)
          : "",
        classes
      ),
      computeWrappedCell(
        emission.data.heureFonctionnement !== null
          ? computeNumberWithSeparator(emission.data.heureFonctionnement)
          : "",
        classes
      ),
      computeWrappedCell(
        !emission.data.mesureContinueDebit ? "Non" : "Oui",
        classes
      ),
      computeWrappedCell(
        emission.data.frequenceMesureDebit !== null
          ? computeNumberWithSeparator(emission.data.frequenceMesureDebit)
          : "",
        classes
      ),
      computeWrappedCell(
        emission.data.concentrationMoyenne !== null
          ? computeNumberWithSeparator(emission.data.concentrationMoyenne)
          : "",
        classes
      ),
      computeWrappedCell(
        !emission.data.mesureContinueConcentration ? "Non" : "Oui",
        classes
      ),
      computeWrappedCell(
        emission.data.frequenceMesureConcentration !== null
          ? computeNumberWithSeparator(
              emission.data.frequenceMesureConcentration
            )
          : "",
        classes
      ),
      computeWrappedCell(
        emission.data.emissionAnnuelle !== null
          ? computeNumberWithSeparator(emission.data.emissionAnnuelle)
          : "",
        classes
      ),
      computeWrappedCell(
        emission.data.biomasse !== null
          ? computeNumberWithSeparator(emission.data.biomasse)
          : "",
        classes
      ),
      computeWrappedCell(!emission.data.epuration ? "Non" : "Oui", classes),
      computeWrappedCell(
        <p title={emission.data.natureEquipement || ""}>
          {emission.data.natureEquipement}
        </p>,
        classes
      ),
      computeWrappedCell(
        emission.data.rendementEpuration !== null
          ? computeNumberWithSeparator(emission.data.rendementEpuration)
          : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressEmission(emission);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        isValidated={isValidated}
        commentPath={`${validationEmissionPath}/${emission.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = mesureEmissionsInPage
    .filter(mesure => {
      const methods = mesure.data.methods;
      return methods && methods.value === 1;
    })
    .map(mesure => {
      return computeSingleEmissionLine(mesure, classes);
    });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Substance</p>,
          <p>Procédé(s)</p>,
          <p>Débit horaire (Nm3/h)</p>,
          <p>Nombre d'heures de fonctionnement (h/an)</p>,
          <p>Mesure en continu du débit</p>,
          <p>Nombre de mesures (mesures/an)</p>,
          <p>Concentration Moyenne (kg/Nm3)</p>,
          <p>Mesure en continu de la concentration</p>,
          <p>Nombre de mesures (mesures/an)</p>,
          <p>Émissions annuelles (kg/an)</p>,
          <p>Fraction de la biomasse (%)</p>,
          <p>Les émissions font-elles l'object d'épuration ?</p>,
          <p>Nature des équipements</p>,
          <p>Rendement de l'épuration (%)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
        preferredColWidths={[
          250,
          130,
          130,
          170,
          130,
          200,
          130,
          200,
          170,
          130,
          130,
          200,
          130,
          130,
          ACTION_COLUMN_DEFAULT_WIDTH,
        ]}
      />
    </>
  );
};

export default EmissionMesureArray;
