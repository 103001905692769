import { FileDto } from "api/gen";
import {
  backMessageDeleteFile,
  backMessageUploadFile,
} from "common/backErrors/errorMessages";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { useCallback, useContext } from "react";
import { DeclarationApiContext } from "../DeclarationApiContext";
import { AnyDeclarationDto } from "./types";
import { throwIfWrongYear } from "./utils";

// DO NOT use the hooks in this file directly! They're supposed to be exposed through year / year range more specific hooks

export const useUploadFileHandler = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  uploadHandler: (
    annee: number,
    etablissement: string,
    file: File
  ) => Promise<VersionedDeclarationDto>,
  minYear?: number,
  maxYear?: number
): ((file: File) => Promise<boolean>) => {
  const openAlertModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  throwIfWrongYear(declarationApi.declaration, minYear, maxYear);

  return useCallback(
    async (file: File): Promise<boolean> => {
      try {
        const returnedResult: VersionedDeclarationDto = await uploadHandler(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          file
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openAlertModale(backAlertMessage(excp, backMessageUploadFile));
        return false;
      }
    },
    [declarationApi, openAlertModale, uploadHandler]
  );
};

export const useDeleteFileHandler = <
  VersionedDeclarationDto extends AnyDeclarationDto
>(
  deleteHandler: (
    annee: number,
    etablissement: string,
    file: FileDto
  ) => Promise<VersionedDeclarationDto>,
  minYear?: number,
  maxYear?: number
): ((fileDto: FileDto) => Promise<boolean>) => {
  const openAlertModale = useAlertModale();
  const declarationApi = useContext(DeclarationApiContext);
  if (!declarationApi) {
    throw new Error(
      "DeclarationApi Error. You probably forgot to put a <DeclarationApiContext.Provider>"
    );
  }

  throwIfWrongYear(declarationApi.declaration, minYear, maxYear);

  return useCallback(
    async (fileDto: FileDto): Promise<boolean> => {
      try {
        const returnedResult: VersionedDeclarationDto = await deleteHandler(
          declarationApi.declaration.annee,
          declarationApi.declaration.etablissementCode,
          fileDto
        );
        declarationApi.setDeclaration(returnedResult);
        return true;
      } catch (excp) {
        await openAlertModale(backAlertMessage(excp, backMessageDeleteFile));
        return false;
      }
    },
    [declarationApi, deleteHandler, openAlertModale]
  );
};
