import { AirFugitivesFluxDto } from "api/gen";
import { CovInArray } from "./types";
import { getProcedes, getProcedesLabels } from "../../utils/utils";
import { ProcedeInArray } from "../../listprocede/utils/types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { arrayMentions } from "./selectPossibleValues";

export const createAirFugitivesCovDto = (CovInPage: CovInArray[]) => {
  return CovInPage.map(singlePopulatedCov => {
    const singleCov = singlePopulatedCov.data;
    const singleCovDto: AirFugitivesFluxDto = {
      id: singlePopulatedCov.data.id,
      substance: singleCov.substance && singleCov.substance.label,
      procedes:
        singleCov.procede !== null ? getProcedesLabels(singleCov.procede) : [],
      flux: singleCov.flux,
    };
    return singleCovDto;
  });
};

//TODO : calculate and handle errors
export const convertCovToDisplayed = (
  cov: AirFugitivesFluxDto,
  procedes: ProcedeInArray[]
): CovInArray => {
  const covSubstance =
    cov.substance === null
      ? null
      : findElementMatchingTemplate(
          { object: { name: cov.substance } },
          arrayMentions
        );

  return {
    data: {
      id: cov.id,
      substance: covSubstance
        ? {
            value: covSubstance.object.name,
            label: covSubstance.object.name,
            object: covSubstance.object,
          }
        : null,
      procede: getProcedes(cov.procedes, procedes),
      flux: cov.flux,
    },
    errors: {},
  };
};
