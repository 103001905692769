import React from "react";
import { computeErrorObject } from "./utils";
import DummyChoiceInput from "../dumbInput/DummyChoiceInput";
import { ChoiceInputProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const ChoiceInput = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: ChoiceInputProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyChoiceInput
      {...field}
      {...props}
      error={error}
      value={externalValue !== undefined ? externalValue : field.value}
      disabled={externalValue !== undefined ? true : disabled}
      onChange={newValue => {
        form.setFieldValue(field.name, newValue);
        form.setFieldTouched(field.name);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newValue);
        }
      }}
    />
  );
};

export default ChoiceInput;
