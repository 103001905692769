import {
  AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum,
  AirCombustionEmissionMesureDto24NowTypeMesureEnum,
  ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum,
} from "api/gen";

export const arrayCombustionConcentrationUnitsLabel: {
  [key in AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum]: string;
} = {
  G_NM3: "g/Nm\u00B3",
  MG_NM3: "mg/Nm\u00B3",
  UG_NM3: "\u00B5g/Nm\u00B3",
  NG_NM3: "ng/Nm\u00B3",
  PG_NM3: "pg/Nm\u00B3",
};

export const arrayReferencePolluantUnitsLabel: {
  [key in ReferencePolluantDefaultUniteItemDtoConcentrationUsualUnitEnum]: string;
} = {
  KG_NM3: "kg/Nm\u00B3",
  G_NM3: "g/Nm\u00B3",
  MG_NM3: "mg/Nm\u00B3",
  UG_NM3: "\u00B5g/Nm\u00B3",
  NG_NM3: "ng/Nm\u00B3",
  PG_NM3: "pg/Nm\u00B3",
};

export const arrayCombustionTypeMesureLabel: {
  [key in AirCombustionEmissionMesureDto24NowTypeMesureEnum]: string;
} = {
  CANALISEES: "Mesure des émissions canalisées",
  DIFFUSES: "Mesure des émissions diffuses",
  FUGITIVES: "Mesure des émissions fugitives",
};
export const arrayCombustionConcentrationUnits: AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum[] = Object.keys(
  arrayCombustionConcentrationUnitsLabel
) as AirCombustionEmissionMesureDebitDto24NowConcentrationUniteEnum[];

export const arrayCombustionTypeMesure: AirCombustionEmissionMesureDto24NowTypeMesureEnum[] = Object.keys(
  arrayCombustionTypeMesureLabel
) as AirCombustionEmissionMesureDto24NowTypeMesureEnum[];
