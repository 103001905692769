import { FormikValues } from "libAdapter/Formik/TypesPatternAdaptater";
import React from "react";
import { DummyFormikBlocProps } from "./types";
import DummyBloc from "../bloc/DummyBloc";
import _ from "lodash";
import { commonShouldDisableSubmitButton } from "../declarant/formik/commonShouldDisableSubmitButton";
import { filterOutNullishValues } from "../declarant/formik/formikValidationHelper";
import FormikAdapter from "../../libAdapter/Formik/FormikAdapter";

/**
 * wrapper to use a Formik inside a Bloc and refresh bloc button states on Formik update
 *
 * use case : wrapped in FormikBloc, (should not be used)
 *
 * @param initialValues: values to initialise Formik
 * @param validationSchema: optional, Yup schema to validate Formik
 * @param title: Bloc title
 * @param isSingle: is Bloc is alone in the page and button should be outside the Bloc or multiple Bloc and buttons are inside
 * @param saveAction: action on save button click
 * @param editAction: edit button click
 * @param validateAction: action on validate button click with valid data from Formik
 * @param cancelAction: action on cancel button click
 * @param onChange: callback on every render to catch Formik values changes (should not be used to override Formik private state)
 * @param additionalValidationAllowed: additional method to prevent validation (Formik must be valid anyway), used when error is triggered on data outside Formik managed data
 * @param additionalFieldValidation: Formik validation with or without Yup schema (validate prop of Formik)
 * @param formikRef: ref of Formik
 * @param isBlocValidated: button management (see Bloc)
 * @param hasChanges: save and cancel button management (see Bloc)
 * @param actionsAllowed: enabled or disabled bloc buttons (see Bloc)
 * @param renderContent: content to render inside Formik (itself render inside the Bloc)
 */
function DummyFormikBloc<T extends FormikValues>({
  //  DummyFormikBlocDisplayProps
  initialValues,
  validationSchema,
  title,
  isSingle = false,
  //  spinner
  isValidateSpinnerVisible,
  isEditSpinnerVisible,
  isSaveSpinnerVisible,
  //  DummyFormikBlocControlProps
  saveAction,
  editAction,
  validateAction,
  cancelAction,
  onChange,
  //  DummyFormikBlocAdditionProps
  additionalValidationAllowed = () => true,
  additionalFieldValidation = () => {
    return {};
  },

  //  additional
  formikRef,
  isBlocValidated,
  hasChanges,
  actionsAllowed,
  renderContent,
  preventSubmitWithErrors,
  enableReinitialize,
  validationMessage,
  validationTitle,
  validationToolTipContent,
  areGlobalCommentsAllowed,
  hasComments,
  commentAction,
  icon,
}: DummyFormikBlocProps<T>): React.ReactElement {
  return (
    <FormikAdapter
      formikRef={formikRef}
      enableReinitialize={enableReinitialize}
      onSubmit={values => {
        if (preventSubmitWithErrors) {
          const additionalErrors = preventSubmitWithErrors(values);
          if (!_.isEqual(filterOutNullishValues(additionalErrors), {})) {
            if (formikRef && formikRef.current) {
              formikRef.current.setErrors(additionalErrors);
            }
            return;
          }
        }
        validateAction(values);
      }}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={additionalFieldValidation}
      render={props => {
        onChange && onChange(props.values);
        const isBlocSaved =
          isBlocValidated instanceof Function
            ? isBlocValidated()
            : isBlocValidated;
        const hasModification =
          hasChanges instanceof Function
            ? hasChanges(props.internalOnlyValues)
            : hasChanges;
        return (
          <DummyBloc
            //  DummyBlocGlobalStylingProps
            title={title}
            validationTitle={validationTitle}
            areButtonsInside={!isSingle}
            //  DummyBlocActionProps
            validateAction={async () => props.handleSubmit()}
            saveAction={() => saveAction(props.values)}
            editAction={editAction}
            cancelAction={() => {
              cancelAction(props);
            }}
            //  DummyBlocValidationProps
            hasModification={hasModification}
            isValidateButtonAvailable={
              !commonShouldDisableSubmitButton(props) && //  check if all fields touched have no error
              additionalValidationAllowed(props.values)
            }
            //  additional
            isValidated={isBlocSaved}
            actionsAllowed={actionsAllowed}
            renderContent={() => {
              return renderContent(props);
            }}
            validationMessage={validationMessage}
            //  spinner
            isValidateSpinnerVisible={isValidateSpinnerVisible}
            isEditSpinnerVisible={isEditSpinnerVisible}
            isSaveSpinnerVisible={isSaveSpinnerVisible}
            areGlobalCommentsAllowed={areGlobalCommentsAllowed}
            commentAction={commentAction}
            hasComments={hasComments}
            validationTooltipContent={validationToolTipContent}
            icon={icon}
          />
        );
      }}
    />
  );
}

export default DummyFormikBloc;
