import { FiltresDeclarationDtoSortByEnum } from "api/gen";
import SortableTableHeaderView from "common/presentational/tableHeaderSorted/SortableTableHeaderView";
import React from "react";

export const useDeclarationTableauHeader = (
  sortField: FiltresDeclarationDtoSortByEnum | null,
  isSortReversed: boolean | null,
  updateFilter: (
    field: FiltresDeclarationDtoSortByEnum | null,
    reversed: boolean | null
  ) => void,
  canOrderByCommune: boolean
): React.ReactElement[] => {
  const content = [
    {
      field: FiltresDeclarationDtoSortByEnum.ETABLISSEMENT_NAME,
      name: "Établissement",
    },
    {
      field: canOrderByCommune ? FiltresDeclarationDtoSortByEnum.COMMUNE : null,
      name: "Commune",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.ANNEE,
      name: "Année",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.VALIDATION_PERCENT,
      name: "Registre - Progression",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.GENERAL_STATE,
      name: "Statut - Registre",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.QUOTAS_EMISSIONS_STATE,
      name: "Statut - Quotas émissions",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.QUOTAS_ALLOCATIONS_STATE,
      name: "Statut - Quotas niveaux d'activité",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.DEPASSEMENT_SEUIL,
      name: "Depassement de Seuil",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_DECLARING,
      name: "Registre - Dernière action déclarant",
    },
    {
      field: FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_INSPECTOR,
      name: "Registre - Dernière action inspecteur",
    },

    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_ALLOCATION_DECLARING,
      name: "Niveaux d'activité - Dernière action déclarant",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_ALLOCATION_INSPECTOR,
      name: "Niveaux d'activité  - Dernière action inspecteur",
    },

    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_EMISSION_DECLARING,
      name: "Emissions - Dernière action déclarant",
    },
    {
      field:
        FiltresDeclarationDtoSortByEnum.LAST_MODIFICATION_TIME_EMISSION_INSPECTOR,
      name: "Emissions - Dernière action inspecteur",
    },
  ];

  return SortableTableHeaderView({
    columns: content,
    sortField: sortField,
    isSortReversed: isSortReversed,
    setSortFieldAndIsReversed: (sortField, isSortReversed) =>
      updateFilter(sortField, isSortReversed),
  });
};
