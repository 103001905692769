import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { AirFugitivesEmissionCorrelationDtoUniteEnum } from "api/gen";

export enum MethodProcede {
  MESURE,
  FACTEUR_CORRELATION,
  BILAN_MATIERE,
}

export interface MethodsProcedeInfo {
  value: MethodProcede;
  display: string;
}

export const arrayMethods: OptionPropsWithObject<MethodsProcedeInfo>[] = [
  {
    value: 1,
    label: "Mesure",
    object: { value: MethodProcede.MESURE, display: "Mesure" },
  },
  {
    value: 2,
    label: "Facteur de corrélation",
    object: {
      value: MethodProcede.FACTEUR_CORRELATION,
      display: "Facteur de corrélation",
    },
  },
  {
    value: 3,
    label: "Bilan matière",
    object: { value: MethodProcede.BILAN_MATIERE, display: "Bilan matière" },
  },
];

export interface ObjectUnite {
  code: AirFugitivesEmissionCorrelationDtoUniteEnum;
  name: string;
}

//TODO GEREP-1397 BEWARE! the order and most importantly, VALUE here MUST actually be correlated with array in utils/calculus.ts
export const arrayUnits: OptionPropsWithObject<ObjectUnite>[] = [
  {
    value: 1,
    label: "kg/kg",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.KG,
      name: "kg/kg",
    },
  },
  {
    value: 2,
    label: "kg/t",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.T,
      name: "kg/t",
    },
  },
  {
    value: 3,
    label: "kg/hl",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.HL,
      name: "kg/hl",
    },
  },
  {
    value: 4,
    label: "kg/l",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.L,
      name: "kg/l",
    },
  },
  {
    value: 5,
    label: "kg/1000m³",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.KM3,
      name: "kg/1000m³",
    },
  },
  {
    value: 6,
    label: "kg/m³",
    object: {
      code: AirFugitivesEmissionCorrelationDtoUniteEnum.M3,
      name: "kg/m³",
    },
  },
];
