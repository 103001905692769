import { FiltresDeclarationDtoSelectedSectionsEnum } from "api/gen";
import { AggregationModaleValues } from "./types";
import { Nullable } from "common/utils/types";
import { DeclarationFilters } from "../types";

export const filtreGestionnaireDtoToAggregationModaleValues = (
  filter: DeclarationFilters
): AggregationModaleValues => ({
  resumeDeclarations:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.RESUME_DECLARATIONS
    ) || false,
  infoGenerales:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.INFOS_GENERALES
    ) || false,
  typeActiviteGlobal:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.TYPE_ACTIVITE_GLOBAL
    ) || false,
  typeActiviteQuotas:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.TYPE_ACTIVITE_QUOTAS
    ) || false,
  dechets:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.DECHETS
    ) || false,
  eau:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.EAU
    ) || false,
  sol:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.SOL
    ) || false,
  airGazFluores:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_GAZ_FLUORES
    ) || false,
  airElevage:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_ELEVAGE
    ) || false,
  airCombustion:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_COMBUSTION
    ) || false,
  airIsdnd:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_ISDND
    ) || false,
  airSolvants:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_SOLVANTS
    ) || false,
  airProcedes:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_FUGITIVES
    ) || false,
  airSynthese:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.AIR_SYNTHESE
    ) || false,
  quotasEmissions:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_EMISSIONS
    ) || false,
  quotasAllocations:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_ALLOCATIONS
    ) || false,
  quotasWithAnyExclusions:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_WITH_ANY_EXCLUSIONS
    ) || false,
  carriereEnvironnement:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_ENVIRONNEMENT
    ) || false,
  carriereProduction:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_PRODUCTION
    ) || false,
  carriereSanteSecurite:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_SANTE_SECURITE
    ) || false,
  spfo:
    filter.selectedSections?.includes(
      FiltresDeclarationDtoSelectedSectionsEnum.SPFO
    ) || false,
});

export const aggregationModaleValuesToSelectedSections = (
  values: AggregationModaleValues | Nullable<AggregationModaleValues>
): FiltresDeclarationDtoSelectedSectionsEnum[] => {
  const sections = [];
  if (values.resumeDeclarations) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.RESUME_DECLARATIONS
    );
  }
  if (values.infoGenerales) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.INFOS_GENERALES);
  }
  if (values.typeActiviteGlobal) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.TYPE_ACTIVITE_GLOBAL
    );
  }
  if (values.typeActiviteQuotas) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.TYPE_ACTIVITE_QUOTAS
    );
  }
  if (values.dechets) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.DECHETS);
  }
  if (values.eau) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.EAU);
  }
  if (values.sol) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.SOL);
  }
  if (values.airGazFluores) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_GAZ_FLUORES);
  }
  if (values.airElevage) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_ELEVAGE);
  }
  if (values.airCombustion) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_COMBUSTION);
  }
  if (values.airIsdnd) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_ISDND);
  }
  if (values.airSolvants) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_SOLVANTS);
  }
  if (values.airProcedes) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_FUGITIVES);
  }
  if (values.airSynthese) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.AIR_SYNTHESE);
  }
  if (values.quotasEmissions) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_EMISSIONS);
  }
  if (values.quotasAllocations) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_ALLOCATIONS);
  }
  if (values.quotasWithAnyExclusions) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.QUOTAS_WITH_ANY_EXCLUSIONS
    );
  }
  if (values.carriereEnvironnement) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_ENVIRONNEMENT
    );
  }
  if (values.carriereProduction) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_PRODUCTION
    );
  }
  if (values.carriereSanteSecurite) {
    sections.push(
      FiltresDeclarationDtoSelectedSectionsEnum.CARRIERE_SANTE_SECURITE
    );
  }
  if (values.spfo) {
    sections.push(FiltresDeclarationDtoSelectedSectionsEnum.SPFO);
  }
  return sections;
};

export const areAllCheckboxesSelected = (
  values: Nullable<AggregationModaleValues>
): boolean => {
  for (const key in values) {
    if (!values[key as keyof Nullable<AggregationModaleValues>]) {
      return false;
    }
  }
  return true;
};

export const selectAllCheckbox = (
  values: Nullable<AggregationModaleValues>,
  value: boolean,
  setFieldValue: (field: string, value: boolean) => void
): void => {
  for (const key in values) {
    setFieldValue(key, value);
  }
};
