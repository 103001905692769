import React, { useCallback, useEffect, useMemo } from "react";
import {
  ReceivedWasteImport,
  ReceivedWasteProps,
  ReceivedWasteStorage,
} from "./types";
import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import HRule from "common/presentational/HRule";
import { makeStyles } from "@material-ui/styles";
import {
  LEFT_WITHDRAW_STYLE,
  PINK,
  SECTION_TITLE_GREY,
  WASTE_BROWSE_MESSAGE_WIDTH,
} from "theme";
import Button from "common/button";
import { filterCSVArray, validationSchema } from "./utils";
import { createCSVImportButton } from "common/csvImport";
import WasteReceptionArray from "./WasteReceptionArray";
import { shouldDisplayReceptionArray } from "../utils/utils";
import { Nullable } from "common/utils/types";
import InfoBulle from "common/infoBulle/InfoBulle";
import ErrorDisplayer from "common/errors/ErrorDisplayer";
import { dechetsAtLeastOneMessage } from "common/declarant/formik/formikMessages";
import { useConfirmationModale } from "common/modale/hooks";
import MessageInfoField from "common/form/MessageInfoField";
import { TABS_VALIDATION_MESSAGE } from "common/actions/utils";
import FormikBlocFullContext1919 from "../../versionedElements/FormikBlocFullContext1919";
import { findElementMatchingTemplate } from "common/utils/methods";

const useStyles = makeStyles({
  headertitle5: {
    color: "#6b6b6b",
    textTransform: "uppercase",
  },
  par: {
    color: "#4c4c4c",
    "&>li": {
      marginLeft: "20px",
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  tooltip: {
    marginTop: "5px",
  },
  tooltipList: {
    "&>li": {
      marginLeft: "10px",
      marginTop: "5px",
      marginBottom: "5px",
    },
  },
  link: {
    color: PINK,
  },
  text: {
    color: SECTION_TITLE_GREY,
    padding: "10px 20px 25px 20px",
  },
  message: {
    "&>div": {
      width: WASTE_BROWSE_MESSAGE_WIDTH,
    },
  },
  ...LEFT_WITHDRAW_STYLE,
});

const PolluantReception = ({
  formRef,
  wasteData,
  validationPath,
  productionValidationPath,
  importWastes,
  deleteWaste,
  deleteAllWastes,
  openModal,
  reset,
  computedProductionDto,
  receptionSaveCompletion,
  hasChanges,
  onChange,
  onUnMount,
}: ReceivedWasteProps): React.ReactElement => {
  const classes = useStyles();
  const openConfirmationModale = useConfirmationModale();

  const commonProps = {
    disabled: false,
    className: "",
    labelWidth: "85%",
    formPrefix: "bloc-déchets",
  };
  const commonPropsNI = {
    disabled: false,
    className: "",
    labelWidth: "70%",
    formPrefix: "bloc",
  };

  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonPropsNI);
  const CSVImportButton = createCSVImportButton<
    Nullable<ReceivedWasteImport>
  >();

  const compareLocalisationFunction = useCallback(
    (a: ReceivedWasteStorage, b: ReceivedWasteStorage): number => {
      if (
        !a.data.estTraiteDansUnAutrePays &&
        !b.data.estTraiteDansUnAutrePays &&
        a.data.departementID &&
        b.data.departementID &&
        a.data.departementID !== b.data.departementID
      ) {
        const aDepartement = findElementMatchingTemplate(
          { uuid: a.data.departementID },
          wasteData.referentiels.departements
        );
        const bDepartement = findElementMatchingTemplate(
          { uuid: b.data.departementID },
          wasteData.referentiels.departements
        );
        if (aDepartement && bDepartement) {
          return aDepartement.numero < bDepartement.numero ? -1 : 1;
        }
      }

      if (
        a.data.estTraiteDansUnAutrePays &&
        b.data.estTraiteDansUnAutrePays &&
        a.data.paysID &&
        b.data.paysID &&
        a.data.paysID !== b.data.paysID
      ) {
        const aPays = findElementMatchingTemplate(
          { uuid: a.data.paysID },
          wasteData.referentiels.pays
        );
        const bPays = findElementMatchingTemplate(
          { uuid: b.data.paysID },
          wasteData.referentiels.pays
        );
        if (aPays && bPays) {
          return aPays.designation < bPays.designation ? -1 : 1;
        }
      }

      if (!a.data.estTraiteDansUnAutrePays && b.data.estTraiteDansUnAutrePays) {
        return -1;
      }
      if (a.data.estTraiteDansUnAutrePays && !b.data.estTraiteDansUnAutrePays) {
        return 1;
      }
      return 0;
    },
    [wasteData.referentiels.departements, wasteData.referentiels.pays]
  );

  const compareOperationTraitementFunction = useCallback(
    (a: ReceivedWasteStorage, b: ReceivedWasteStorage): number => {
      if (a.data.operationTraitement && b.data.operationTraitement) {
        const aChar = a.data.operationTraitement[0];
        const bChar = b.data.operationTraitement[0];
        const aInt = parseInt(a.data.operationTraitement.substr(1));
        const bInt = parseInt(b.data.operationTraitement.substr(1));
        if (aChar !== bChar) {
          return aChar < bChar ? -1 : 1;
        }
        if (aInt !== bInt) {
          return aInt < bInt ? -1 : 1;
        }
      }
      return 0;
    },
    []
  );

  const compareFunction = useCallback(
    (a: ReceivedWasteStorage, b: ReceivedWasteStorage): number => {
      if (a.data.codeID && b.data.codeID && a.data.codeID !== b.data.codeID) {
        const aDechet = findElementMatchingTemplate(
          { uuid: a.data.codeID },
          wasteData.referentiels.polluants
        );
        const bDechet = findElementMatchingTemplate(
          { uuid: b.data.codeID },
          wasteData.referentiels.polluants
        );
        if (aDechet && bDechet) {
          return aDechet.codeDechet < bDechet.codeDechet ? -1 : 1;
        }
      }
      const localisationSorting = compareLocalisationFunction(a, b);
      if (localisationSorting !== 0) {
        return localisationSorting;
      }
      return compareOperationTraitementFunction(a, b);
    },
    [
      compareLocalisationFunction,
      compareOperationTraitementFunction,
      wasteData.referentiels.polluants,
    ]
  );

  const sortedArrayElem = useMemo(() => {
    return wasteData.tempData.reception.container.sort(compareFunction);
  }, [compareFunction, wasteData.tempData.reception.container]);

  useEffect(() => {
    return () => {
      onUnMount();
    };
  }, [onUnMount]);
  return (
    <FormikBlocFullContext1919
      formikRef={formRef.formikRef}
      hasChanges={formRef.hasChanges}
      setHasChanges={formRef.setHasChanges}
      initialValues={wasteData.tempData.reception.formInfo}
      preventSubmitWithErrors={values => {
        if (
          shouldDisplayReceptionArray(values) &&
          wasteData.tempData.reception.container.length === 0
        ) {
          return {
            atLeastOneError: dechetsAtLeastOneMessage,
          };
        }
        return {};
      }}
      validationSchema={validationSchema(
        wasteData.tempData.reception.formInfo.isISDI,
        wasteData.tempData.reception.formInfo.isISDND
      )}
      validationMessage={{
        message: TABS_VALIDATION_MESSAGE,
        isAlwaysVisible: true,
      }}
      title={"Données héritées du bloc informations générales"}
      renderContent={({ values, errors }, shouldDisabledFields) => {
        return (
          <>
            <div className={classes.text}>
              <p>
                Le tableau ci-dessous doit être renseigné, quelles que soient
                les quantités de déchets admises et traitées, dès lors que
                l'établissement assure :
              </p>
              <li>
                le stockage, transit, regroupement ou traitement y compris le
                tri de déchets dangereux ;
              </li>
              <li>
                le stockage, l'incinération, le compostage, la méthanisation de
                déchets non dangereux ou le traitement de déchets non dangereux
                permettant de bénéficier de la procédure de sortie du statut de
                déchet ;
              </li>
              <li>le stockage de déchets inertes.</li>
            </div>
            <CheckBox
              name="isFacilityManagingWaste"
              label="L'établissement réceptionne / traite / stocke des déchets (y compris tri-transit-regroupement, incinération, compostage et méthanisation)"
              disabled={true}
            />
            <div className={classes.withdrawLeft}>
              <CheckBox
                name="isISDND"
                label="L'établissement possède une ou plusieurs installations de stockage de déchets non dangereux (ISDND)"
                disabled={true}
              />
              <CheckBox
                name="isISDI"
                label="L'établissement possède une ou plusieurs installations de stockage de déchets inertes (ISDI)"
                disabled={true}
              />
            </div>

            {values.isISDND || values.isISDI ? (
              <>
                <Row />
                <div>
                  <h5 className={classes.headertitle5}>
                    Informations complémentaires
                  </h5>
                </div>

                <NumberField
                  name="remainingCapacity"
                  label="Capacité restante&nbsp;:&nbsp;"
                  unit="m³"
                  disabled={shouldDisabledFields}
                />

                {values.isISDND && (
                  <>
                    <Row />
                    <CheckBox
                      name="doesFacilityHavePargetCases"
                      label="L'installation dispose-t-elle de casiers à plâtre&nbsp;?&nbsp;"
                      disabled={shouldDisabledFields}
                    />
                    <CheckBox
                      name="doesFacilityHaveAsbestosCases"
                      label="L'installation dispose-t-elle de casiers à amiante&nbsp;?&nbsp;"
                      disabled={shouldDisabledFields}
                    />
                  </>
                )}
              </>
            ) : (
              <></>
            )}

            {shouldDisplayReceptionArray(values) && (
              <>
                <HRule />

                <div>
                  <h5 className={classes.headertitle5}>
                    Tableau récapitulatif de la réception des déchets
                  </h5>
                </div>
                <Row />
                <ul className={classes.par}>
                  Ce tableau peut être rempli de deux manières :
                  <li>Ligne par ligne en cliquant sur 'AJOUTER UN DÉCHET'.</li>
                  <li>
                    {" "}
                    Par un formulaire de saisie en masse depuis un tableur
                    permettant l'insertion de plusieurs lignes préalablement
                    saisies dans ce tableur avec le bouton 'PARCOURIR'. Le
                    tableur à importer doit être élaboré à partir de{" "}
                    <a
                      href={`${process.env.PUBLIC_URL}/download/GabaritDechetsReception.csv`}
                      className={classes.link}
                    >
                      ce gabarit
                    </a>
                    &nbsp;(ouvrir le document sous libreoffice permettra
                    d'éviter les erreurs d'encodage des caractères spéciaux).
                    Veuillez lire&nbsp;
                    <a
                      href={`${process.env.PUBLIC_URL}/download/NoticeGabaritRéceptionTraitementDéchets.pdf`}
                      className={classes.link}
                      target={"_blank"}
                      rel="noopener noreferrer" // Using target="_blank" without rel="noopener noreferrer" is a security risk: see https://mathiasbynens.github.io/rel-noopener
                    >
                      la documentation
                    </a>
                    &nbsp;pour plus de précisions sur le mode opératoire.
                  </li>
                  Le code pour le déchet réceptionné et traité est renseigné
                  selon la nomenclature figurant à l'annexe de la décision
                  2000/532/CE. Le code pour l'élimination ou la valorisation du
                  déchet est renseigné selon l'annexe IV de l'arrêté du 31
                  janvier 2008 modifié et correspond à l'opération réalisée par
                  l'établissement.
                </ul>

                <MessageInfoField
                  additionalClassname={classes.message}
                  message="Seul le format csv est accepté, merci de ne pas changer le format du gabarit."
                />

                <Row
                  additionalStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <InfoBulle
                    additionalClassName={classes.tooltip}
                    content={
                      <ul className={classes.tooltipList}>
                        <li>
                          1. Télécharger et enregistrer le 'Gabarit XLS
                          réception déchets' dans votre ordinateur.
                        </li>
                        <li>
                          <ul className={classes.tooltipList}>
                            2. Remplir le gabarit avec les données à déclarer :
                            <li>
                              Code déchet : Code du déchet produit, figurant à
                              l'annexe II de l'article R. 541-8 du code de
                              l'environnement. Code à 6 chiffres à saisir avec
                              espace (ex : 02 03 01).
                            </li>
                            <li>
                              Sortie du statut de déchet : Le déchet a-t-il
                              bénéficié de la procédure de sortie du statut de
                              déchet
                            </li>
                            <li>
                              Quantité admise (t/an) : Masse annuelle du déchet
                              admise, exprimée en tonne.
                            </li>
                            <li>
                              Quantité produite (t/an) : Masse annuelle du
                              déchet traitée, exprimée en tonne.
                            </li>
                            <li>
                              Opération de traitement : Code de l'opération
                              d'élimination ou de valorisation figurant dans les
                              annexes II A et II B de la directive 2006/12/CE du
                              5 avril 2006 relative aux déchets (ex : D1). A
                              noter: les codes D7 et D11 ne peuvent pas être
                              utilisés car ils correspondent à des opérations
                              interdites par la législation européenne et les
                              conventions internationales dont la France est
                              signataire.
                            </li>
                            <li>
                              Lieu de provenance : En France ou à l'étranger.
                            </li>
                            <li>
                              Département (si France) : Numéro du département de
                              destination du déchets s'il n'est pas traité sur
                              site (ex : 60).
                            </li>
                            <li>
                              Pays (si hors France) : Libellé du pays de
                              destination du déchets s'il est traité à
                              l'étranger (ex : Belgique, Italie, Allemagne,...).
                            </li>
                            <li>
                              Numéro de de notification : Le numéro de
                              notification lorsque le lieu d'opération
                              d'élimination n'est pas en France.
                            </li>
                          </ul>
                        </li>
                        <li>3. Enregistrer le gabarit une fois rempli.</li>
                        <li>
                          4. Joindre le gabarit à la déclaration en cliquant sur
                          le bouton « Parcourir ... » du tableau expédition de
                          déchets. Rechercher le gabarit enregistré sur
                          l'ordinateur puis cliquer sur le bouton « Envoyer » Le
                          site de télé-déclaration vérifie alors automatiquement
                          que le gabarit a bien été rempli conformément aux
                          indications détaillées au point 2 (vérification du
                          format des données) et affiche, le cas échéant, les
                          erreurs repérées. Attention: les lignes erronées ne
                          seront pas intégrées dans la déclaration. La procédure
                          conseillée dans ce cas est la suivante: ouvrir le
                          gabarit enregistré dans votre ordinateur et corriger
                          les erreurs recommencer les étapes 3 et 4 décrites
                          ci-dessus (le site indiquera alors la présence de
                          doublons pour les lignes ayant déjà été intégrées lors
                          de la précédente intégration).
                        </li>
                        <li>
                          5. Pour poursuivre la déclaration cliquer sur le
                          bouton « VALIDER» pour passer au tableau suivant.
                        </li>
                      </ul>
                    }
                  />
                  <CSVImportButton
                    filterAction={filterCSVArray}
                    handleImportedData={(
                      wastes: Nullable<ReceivedWasteImport>[]
                    ) => {
                      importWastes(wastes);
                    }}
                    isDisabled={shouldDisabledFields}
                    headerLinesCount={1}
                  />
                  <div>&nbsp;</div>
                  <Button
                    text="Ajouter un déchet"
                    onClick={() => openModal(null)}
                    additionalStyle={{
                      textTransform: "uppercase",
                      marginLeft: "10px",
                    }}
                    type="button"
                    isDisabled={shouldDisabledFields}
                  />
                </Row>
                <Row />

                <WasteReceptionArray
                  isBlocValidate={shouldDisabledFields}
                  wasteContainer={sortedArrayElem}
                  deleteWaste={deleteWaste}
                  openModal={openModal}
                  referentiels={wasteData.referentiels}
                />

                <Row />
                <Row
                  additionalStyle={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    text="TOUT SUPPRIMER"
                    isReversed
                    onClick={() =>
                      openConfirmationModale(
                        "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                        () => deleteAllWastes()
                      )
                    }
                    isDisabled={shouldDisabledFields}
                  />
                </Row>

                <ErrorDisplayer message={errors.atLeastOneError} />
              </>
            )}
          </>
        );
      }}
      pathToValidate={validationPath}
      updateHandler={declaration => {
        declaration.body.sections.dechets.reception = computedProductionDto();
        return declaration;
      }}
      updateCompletion={receptionSaveCompletion}
      hasFormChanges={hasChanges}
      cancelAction={reset}
      onChange={onChange}
    />
  );
};

export default PolluantReception;
