import React, { ReactElement, ReactNode } from "react";
import { makeStyles } from "@material-ui/styles";
import { SECTION_TITLE_GREY, SUB_SECTION_FONT_SIZE } from "theme";

const useStyles = makeStyles({
  title: {
    color: SECTION_TITLE_GREY,
    fontSize: SUB_SECTION_FONT_SIZE,
    margin: "20px 0",
  },
});

const ArrayTitle = ({ children }: { children: ReactNode }): ReactElement => {
  const classes = useStyles();

  return <h5 className={classes.title}>{children}</h5>;
};

export default ArrayTitle;
