import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import {
  ARRAY_GREY,
  ARRAY_SHADOW,
  BACKGROUND_PINK,
  DISABLED_NUMBER_INPUT_TEXT,
  GREY,
} from "theme";
import { Grid, GridCellProps, ScrollSync } from "react-virtualized";
import { checkProps, computeColumnWidths, insertHiddenDiv } from "./utils";
import {
  getScrollbarWidth,
  renderGridCell,
  renderHeaderCell,
} from "./renderHelper";
import { CommonEntityTableProps, GraphicSize } from "./types";
import _ from "lodash";

const ROW_HEIGHT_SHIFT = 24;
export const ACTION_COLUMN_DEFAULT_HEADER = <p>Actions</p>;
export const ACTION_COLUMN_DEFAULT_WIDTH = 100;

const borderProp = `1px solid ${GREY}`;

const useStyles = makeStyles({
  //  global
  header: {
    overflow: "hidden !important",
    background: ARRAY_GREY,
    fontSize: "0.9em",
    color: DISABLED_NUMBER_INPUT_TEXT,
    borderBottom: borderProp,
  },
  hidenScrollBar: {
    "-ms-overflow-style": "none", // IE 10+
    scrollbarWidth: "none", // Firefox
    "&::-webkit-scrollbar": {
      display: "none", // Safari and Chrome
    },
  },
  noOutline: {
    outline: "none",
  },
  stickyContainer: {
    zIndex: 10,
  },
  //  main container
  mainContainer: {
    border: borderProp,
    boxShadow: ARRAY_SHADOW,
  },
  scrollContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
  },
  //  first column
  firstHeaderContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    borderRight: borderProp,
  },
  firstColumnContainer: {
    position: "absolute",
    left: 0,
    borderRight: borderProp,
    zIndex: 10,
  },
  //  last column
  lastHeaderContainer: {
    position: "absolute",
    right: 0,
    top: 0,
    borderLeft: borderProp,
  },
  lastColumnContainer: {
    position: "absolute",
    borderLeft: borderProp,
  },
  greyBackground: {
    background: ARRAY_GREY,
  },
  noBackground: {
    background: "#fff",
  },
  pinkBackground: {
    backgroundColor: BACKGROUND_PINK,
    cursor: "pointer",
  },
  flexToCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    margin: "auto",
    textAlign: "center",
    overflow: "hidden",
  },
  textCenter: {
    // The width is not set to 100 % because it made the enveloppe icon in the etablissements list page be on
    // the very left of the column instead of being in the middle.
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

/**
 *
 * class to display table, width for column will be computed
 * to fit the minimum requierement pass in preferredColWidths or cellStyles
 *
 * @param header                  header content
 * @param lines                   content to display for each lines (each line and header must have the same size)
 * @param additionalClassName     class to apply to container div
 * @param didSelectRow            callback on row click
 *
 * @param preferredContentHeight  preferred height for table (header + visible lines)
 * @param isFixedHeight           table should be fixed in height or shrink when not enough lines
 * @param preferredHeaderHeight   preferred height for header content
 * @param defaultColumnWidth      default width for single column if not set
 *
 * @param preferredColWidths      preferred width for every column
 *                                width for colum will fit min requierement from both cellStyles and preferredColWidths
 *                                preferredColWidths and header must have the same size
 *                                pass 0 to a column to use the default width
 * @param cellStyles              style for cells, can contain width
 *                                width for colum will fit min requierement from both cellStyles and preferredColWidths
 *                                cellStyles and header must have the same size
 *
 * @param rowHeight               height for every line
 * @param isFirstColSticky        is first column in front of other and sticky on the left
 *                                if at least one column is sticky, it's width will be computed <= 2 / 3 of table width
 * @param isLastColSticky         is last column in front of other and sticky on the right
 *                                if at least one column is sticky, it's width will be computed <= 2 / 3 of table width
 *
 */
//important ! don't use % to style elem that will go in cells. Instead, use other measurement unit.
const CommonEntityTable = ({
  header,
  lines,
  additionalClassName,
  didSelectRow,

  preferredContentHeight = 500,
  isFixedHeight = false,
  preferredHeaderHeight = 50,
  defaultColumnWidth = 120,

  preferredColWidths,
  cellStyles,

  rowHeight = 50,
  isFirstColSticky = true,
  isLastColSticky = true,
}: CommonEntityTableProps): React.ReactElement => {
  //  checks if all array have the same length
  useEffect(() => checkProps(header, lines, preferredColWidths, cellStyles), [
    header,
    lines,
    preferredColWidths,
    cellStyles,
  ]);

  //  grid ref
  const firstColHeaderRef = useRef<Grid | null>(null);
  const firstColLinesRef = useRef<Grid | null>(null);
  const lastColHeaderRef = useRef<Grid | null>(null);
  const lastColLinesRef = useRef<Grid | null>(null);
  const centerColHeaderRef = useRef<Grid | null>(null);
  const centerCollinesRef = useRef<Grid | null>(null);

  //  views ref
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [bounds, setBounds] = useState<GraphicSize | null>(null);
  const [lineHoverIndex, setLineHoverIndex] = useState<number | null>(null);
  const [heights, setHeights] = useState<number[]>([]);
  const indexHeightsToCompute = useRef<number[]>([]);

  useEffect(() => {
    setHeights([]);
    // adding default heights to compute to be calculated at first render
    for (let i = 0; i < Math.min(lines.length, 10); i++) {
      indexHeightsToCompute.current[i] = i;
    }
  }, [lines]);

  const isValidFirstColSticky = header.length > 1 && isFirstColSticky;
  const isValidLastColSticky = header.length > 2 && isLastColSticky;
  const onHover = (cellIndex: number) => {
    if (didSelectRow) {
      setLineHoverIndex(cellIndex);
    }
  };

  const classes = useStyles();

  const renderFirstHeader = ({ style, key }: GridCellProps) => {
    const title = header[0];
    return renderHeaderCell(title, style, key, classes);
  };

  const renderHeader = ({ style, key, columnIndex }: GridCellProps) => {
    const title = header[columnIndex];
    if (isValidFirstColSticky && columnIndex === 0) {
      return renderHeaderCell(<></>, style, key, classes);
    } else if (isValidLastColSticky && columnIndex === header.length - 1) {
      return renderHeaderCell(<></>, style, key, classes);
    } else {
      return renderHeaderCell(title, style, key, classes);
    }
  };

  const renderLastHeader = ({ style, key }: GridCellProps) => {
    const title = header[header.length - 1];
    return renderHeaderCell(title, style, key, classes);
  };

  const renderFirstColumnCell = (
    { rowIndex, key, style }: GridCellProps,
    onMouseHover: (lineIndex: number) => void
  ) => {
    const data = lines[rowIndex][0];
    const cellStyle = cellStyles ? cellStyles[0] : {};
    return renderGridCell(
      data,
      { ...style, ...cellStyle },
      key,
      rowIndex % 2 === 1,
      rowIndex === lineHoverIndex,
      () => onMouseHover(rowIndex),
      () => {
        didSelectRow && didSelectRow(rowIndex);
      },
      classes
    );
  };

  const renderCell = (
    { columnIndex, key, rowIndex, style }: GridCellProps,
    onMouseHover: (lineIndex: number) => void
  ) => {
    const data = lines[rowIndex][columnIndex];
    const cellStyle = cellStyles ? cellStyles[columnIndex] : {};
    if (isValidFirstColSticky && columnIndex === 0) {
      return renderGridCell(
        <></>,
        style,
        key,
        rowIndex % 2 === 1,
        rowIndex === lineHoverIndex,
        () => onMouseHover(rowIndex),
        () => {
          didSelectRow && didSelectRow(rowIndex);
        },
        classes
      );
    } else if (isValidLastColSticky && columnIndex === header.length - 1) {
      return renderGridCell(
        <></>,
        style,
        key,
        rowIndex % 2 === 1,
        rowIndex === lineHoverIndex,
        () => onMouseHover(rowIndex),
        () => {
          didSelectRow && didSelectRow(rowIndex);
        },
        classes
      );
    } else {
      return renderGridCell(
        data,
        { ...style, ...cellStyle },
        key,
        rowIndex % 2 === 1,
        rowIndex === lineHoverIndex,
        () => onMouseHover(rowIndex),
        () => {
          didSelectRow && didSelectRow(rowIndex);
        },
        classes
      );
    }
  };

  const renderLastColumnCell = (
    { rowIndex, style, key }: GridCellProps,
    onMouseHover: (lineIndex: number) => void
  ) => {
    const data = lines[rowIndex][header.length - 1];
    const cellStyle = cellStyles ? cellStyles[header.length - 1] : {};
    return renderGridCell(
      data,
      { ...style, ...cellStyle },
      key,
      rowIndex % 2 === 1,
      rowIndex === lineHoverIndex,
      () => onMouseHover(rowIndex),
      () => {
        didSelectRow && didSelectRow(rowIndex);
      },
      classes
    );
  };

  const scrollBarWidth = useMemo(() => {
    return getScrollbarWidth();
  }, []);

  //  header should not exceed 1/3 of total height
  const headerHeight = !isFixedHeight
    ? Math.min(preferredContentHeight / 3, preferredHeaderHeight)
    : preferredHeaderHeight;

  const hasRightScrollBar = bounds
    ? heights.reduce(
        (accumulator, currentValue) =>
          currentValue ? accumulator + currentValue : accumulator + rowHeight,
        0
      ) >
      bounds.height - headerHeight
    : false;
  //  to move right sticky column (if visible) away from right scrollBar (if needed)
  const lastColDelta = hasRightScrollBar ? scrollBarWidth : 0;

  //  compute all column's width to fit in bounds and to match as close as possible
  //  preferred width
  const memoColWidth = useMemo(() => {
    return computeColumnWidths(
      isValidFirstColSticky,
      isValidLastColSticky,
      bounds,
      hasRightScrollBar,
      scrollBarWidth,
      defaultColumnWidth,
      header,
      preferredColWidths,
      cellStyles
    );
  }, [
    bounds,
    preferredColWidths,
    cellStyles,
    scrollBarWidth,
    header,
    hasRightScrollBar,
    isValidFirstColSticky,
    isValidLastColSticky,
    defaultColumnWidth,
  ]);

  //  width of all columns side by side
  const totalWidth =
    memoColWidth.length > 0
      ? memoColWidth.reduce((sum, current) => sum + current)
      : 0;

  // Define content height
  let contentHeight: number;
  if (isFixedHeight) {
    // Fix size content
    contentHeight = headerHeight + preferredContentHeight;
  } else {
    // Variable size content
    let computedContentHeight = 0;
    for (let i = 0; i < Math.min(10, lines.length); i++) {
      computedContentHeight += heights[i] ? heights[i] : rowHeight;
    }
    if (computedContentHeight < rowHeight) {
      computedContentHeight = rowHeight;
    }
    if (computedContentHeight > 10 * rowHeight) {
      computedContentHeight = 10 * rowHeight;
    }

    contentHeight = headerHeight + computedContentHeight;
  }

  //  is content scrollable horizontally. The round is necessary to avoid JS rounding bug where the
  //  scrollbar won't be displayed because the bounds width is superior to scrollBarWidth only with rounding error
  // (x.00001 vs x), but its space will be taken.
  const hasBottomScrollBar = bounds
    ? Math.round(totalWidth) >=
        (hasRightScrollBar
          ? Math.round(bounds.width - scrollBarWidth)
          : Math.round(bounds.width)) && lines.length > 0
    : false;

  //  scrollbarHeight is added to total width in case it is visible
  //  it prevent vertical scroll to appear when lines.length <= 10
  contentHeight += hasBottomScrollBar ? scrollBarWidth : 0;

  //  update bounds to fit container size
  //  (requiered in modal)
  const resizeCallback = useCallback(() => {
    if (containerRef.current) {
      setBounds({
        width: containerRef.current.clientWidth,
        height: contentHeight,
      });
    } else {
      setBounds(null);
    }
  }, [setBounds, contentHeight]);

  //  will be triggered on mount, used to setup bounds to display
  //  grids as fast as possible
  useLayoutEffect(() => {
    resizeCallback();
  }, [resizeCallback]);

  //  update bounds on window resize
  useEffect(() => {
    const callback = () => {
      setHeights([]);
      resizeCallback();
    };
    window.addEventListener("resize", callback);
    return () => {
      window.removeEventListener("resize", callback);
    };
  }, [resizeCallback, setHeights]);

  //  recompute all grid size on bounds changes
  useEffect(() => {
    if (firstColHeaderRef.current) {
      firstColHeaderRef.current.recomputeGridSize();
    }
    if (firstColLinesRef.current) {
      firstColLinesRef.current.recomputeGridSize();
    }
    if (lastColHeaderRef.current) {
      lastColHeaderRef.current.recomputeGridSize();
    }
    if (lastColLinesRef.current) {
      lastColLinesRef.current.recomputeGridSize();
    }
    if (centerColHeaderRef.current) {
      centerColHeaderRef.current.recomputeGridSize();
    }
    if (centerCollinesRef.current) {
      centerCollinesRef.current.recomputeGridSize();
    }
  }, [
    memoColWidth,
    firstColHeaderRef,
    firstColLinesRef,
    lastColHeaderRef,
    lastColLinesRef,
    centerColHeaderRef,
    centerCollinesRef,
    heights,
  ]);

  useEffect(() => {
    // computing the heights for needed rows
    indexHeightsToCompute.current
      .filter(index => index !== undefined && !heights[index] && lines[index])
      .forEach(index => {
        insertHiddenDiv(lines[index], memoColWidth, height => {
          setHeights(heights => {
            heights[index] = Math.max(height + ROW_HEIGHT_SHIFT, rowHeight);
            return _.cloneDeep(heights);
          });
        });
      });

    // then removing the heights to compute to avoid to recalculate the whole array when it is re-sizing
    indexHeightsToCompute.current = indexHeightsToCompute.current.filter(
      index => !heights[index]
    );
  }, [heights, setHeights, lines, memoColWidth, rowHeight]);

  return (
    <div
      ref={containerRef}
      className={classNames(classes.mainContainer, additionalClassName)}
    >
      {bounds && bounds.width > 0 && (
        <div style={{ height: bounds.height, width: bounds.width }}>
          <ScrollSync>
            {scrollProps => {
              return (
                <div className={classes.scrollContainer}>
                  {isValidFirstColSticky && (
                    <>
                      {/* display first column header */}
                      <div
                        className={classNames(
                          classes.stickyContainer,
                          classes.firstHeaderContainer
                        )}
                      >
                        <Grid
                          ref={firstColHeaderRef}
                          className={classNames(
                            classes.header,
                            classes.noOutline
                          )}
                          cellRenderer={renderFirstHeader}
                          width={memoColWidth[0]}
                          height={headerHeight}
                          columnWidth={memoColWidth[0]}
                          rowHeight={headerHeight}
                          rowCount={1}
                          columnCount={1}
                        />
                      </div>

                      {/* display first column lines */}
                      <div
                        className={classes.firstColumnContainer}
                        style={{
                          top: headerHeight,
                        }}
                      >
                        <Grid
                          ref={firstColLinesRef}
                          className={classNames(
                            classes.hidenScrollBar,
                            classes.stickyContainer,
                            classes.noOutline
                          )}
                          cellRenderer={gridProps =>
                            renderFirstColumnCell(gridProps, onHover)
                          }
                          width={memoColWidth[0]}
                          height={
                            bounds.height -
                            headerHeight -
                            (hasBottomScrollBar ? scrollBarWidth : 0)
                          }
                          columnWidth={memoColWidth[0]}
                          rowHeight={rowIndex => {
                            return heights[rowIndex.index] || rowHeight;
                          }}
                          rowCount={lines.length}
                          columnCount={1}
                          onScroll={event =>
                            scrollProps.onScroll({
                              ...event,
                              scrollLeft: scrollProps.scrollLeft,
                            })
                          }
                          scrollTop={scrollProps.scrollTop}
                        />
                      </div>
                    </>
                  )}

                  {/* display center lines */}
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: 0,
                    }}
                  >
                    <Grid
                      ref={lastColHeaderRef}
                      className={classNames(classes.header, classes.noOutline)}
                      cellRenderer={renderHeader}
                      width={bounds.width}
                      height={headerHeight}
                      rowHeight={headerHeight}
                      columnWidth={colIndex => {
                        if (hasRightScrollBar) {
                          if (colIndex.index === header.length - 1) {
                            return (
                              memoColWidth[colIndex.index] + scrollBarWidth
                            );
                          } else {
                            return memoColWidth[colIndex.index];
                          }
                        } else {
                          return memoColWidth[colIndex.index];
                        }
                      }}
                      rowCount={1}
                      columnCount={header.length}
                      scrollLeft={scrollProps.scrollLeft}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      left: 0,
                      top: headerHeight,
                    }}
                  >
                    <Grid
                      ref={lastColLinesRef}
                      className={classNames(classes.noOutline)}
                      cellRenderer={gridProps => {
                        if (
                          gridProps.rowIndex !== undefined &&
                          !heights[gridProps.rowIndex] &&
                          !indexHeightsToCompute.current.includes(
                            gridProps.rowIndex
                          )
                        ) {
                          // adding the row height to compute
                          indexHeightsToCompute.current.push(
                            gridProps.rowIndex
                          );
                          // forcing re-render
                          setHeights(_.cloneDeep(heights));
                        }
                        return renderCell(gridProps, onHover);
                      }}
                      width={bounds.width}
                      height={bounds.height - headerHeight}
                      rowHeight={rowIndex => {
                        return heights[rowIndex.index] || rowHeight;
                      }}
                      columnWidth={colIndex => memoColWidth[colIndex.index]}
                      rowCount={lines.length}
                      columnCount={header.length}
                      onScroll={scrollProps.onScroll}
                      scrollLeft={scrollProps.scrollLeft}
                      scrollTop={scrollProps.scrollTop}
                    />
                  </div>

                  {isValidLastColSticky && (
                    <>
                      {/* display last column header */}
                      <div
                        className={classNames(
                          classes.stickyContainer,
                          classes.lastHeaderContainer
                        )}
                      >
                        <Grid
                          ref={centerColHeaderRef}
                          className={classNames(
                            classes.header,
                            classes.noOutline
                          )}
                          cellRenderer={renderLastHeader}
                          width={memoColWidth[header.length - 1]}
                          height={headerHeight}
                          rowHeight={headerHeight}
                          columnWidth={memoColWidth[header.length - 1]}
                          rowCount={1}
                          columnCount={1}
                        />
                      </div>

                      {/* display last column lines */}
                      <div
                        style={{
                          right: lastColDelta,
                          top: headerHeight,
                        }}
                        className={classNames(
                          classes.stickyContainer,
                          classes.lastColumnContainer
                        )}
                      >
                        <Grid
                          ref={centerCollinesRef}
                          className={classNames(
                            classes.hidenScrollBar,
                            classes.stickyContainer,
                            classes.noOutline
                          )}
                          cellRenderer={gridProps =>
                            renderLastColumnCell(gridProps, onHover)
                          }
                          width={memoColWidth[header.length - 1] - lastColDelta}
                          height={
                            bounds.height -
                            headerHeight -
                            (hasBottomScrollBar ? scrollBarWidth : 0)
                          }
                          rowHeight={rowIndex => {
                            return heights[rowIndex.index] || rowHeight;
                          }}
                          columnWidth={
                            memoColWidth[header.length - 1] - lastColDelta
                          }
                          rowCount={lines.length}
                          columnCount={1}
                          onScroll={event =>
                            scrollProps.onScroll({
                              ...event,
                              scrollLeft: scrollProps.scrollLeft,
                            })
                          }
                          scrollTop={scrollProps.scrollTop}
                        />
                      </div>
                    </>
                  )}
                </div>
              );
            }}
          </ScrollSync>
        </div>
      )}
    </div>
  );
};

export default CommonEntityTable;
