import React from "react";
import {
  AirFugitivesEmissionBilanDto20Now,
  AirFugitivesEmissionCorrelationDto,
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
  AirFugitivesEmissionMesureDto2023,
  AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum,
} from "api/gen";
import { MethodEnum } from "./selectPossibleValues";
import {
  findSingleProcedeInArrayByName,
  getProcedesIds,
  getProcedesNames,
  getSingleProcedeInMapById,
} from "../../utils/utils";
import { ProcedeInArray } from "../../ListProcede/utils/types";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { computeUnitCorrelationLabel } from "./gettersFunctions";

export const createAirFugitivesEmissionCorrelationDto = (
  correlationEmissionsInPage: CorrelationEmissionInArray[],
  procedesInPageMap: Map<string, ProcedeInArray>
): AirFugitivesEmissionCorrelationDto[] => {
  const correlations = correlationEmissionsInPage.map(
    singlePopulatedEmissionCorrelation => {
      const singleCorrelation = singlePopulatedEmissionCorrelation.data;
      const procede = getSingleProcedeInMapById(
        singleCorrelation.procedes && singleCorrelation.procedes[0],
        procedesInPageMap
      );
      const singleCorrelationDto: AirFugitivesEmissionCorrelationDto = {
        id: singleCorrelation.id,
        unite: singleCorrelation.unite,
        facteur: singleCorrelation.facteur,
        nature: singleCorrelation.nature,
        precision: computeUnitCorrelationLabel(
          singleCorrelation.unite,
          procede
        ),
        procede: procede && procede.data.nom,
        provenance: singleCorrelation.provenanceFacteurCorrelation,
        substanceID: singleCorrelation.substance
          ? singleCorrelation.substance.uuid
          : null,
        epuration: !!singleCorrelation.epuration,
        natureEquipement: singleCorrelation.natureEquipement,
        rendement: singleCorrelation.rendement,
        biomasse: singleCorrelation.biomasse,
      };
      return singleCorrelationDto;
    }
  );
  return correlations;
};

export const convertDtoToCorrelationEmissionInArray = (
  correlation: AirFugitivesEmissionCorrelationDto,
  initialProcedes: ProcedeInArray[],
  substanceReferential: ReferenceItemPolluantDto[]
): CorrelationEmissionInArray => {
  const correlationSubstance = correlation.substanceID
    ? findElementMatchingTemplate(
        { uuid: correlation.substanceID },
        substanceReferential
      )
    : null;
  const correlationProcede = findSingleProcedeInArrayByName(
    correlation.procede,
    initialProcedes
  );

  return {
    data: {
      id: correlation.id,
      methods: MethodEnum.FACTEUR_CORRELATION,
      substance: correlationSubstance,
      procedes: correlationProcede ? [correlationProcede.data.id] : null,
      nature: correlation.nature,
      facteur: correlation.facteur,
      unite: correlation.unite,
      provenanceFacteurCorrelation: correlation.provenance,
      epuration: correlation.epuration,
      natureEquipement: correlation.natureEquipement,
      rendement: correlation.rendement,
      biomasse: correlation.biomasse,
    },
    errors: {},
  };
};

export const createAirFugitivesEmissionMesureDto = (
  mesureEmissionsInPage: MesureEmissionInArray[],
  procedesInPageMap: Map<string, ProcedeInArray>
): AirFugitivesEmissionMesureDto2023[] => {
  const mesure = mesureEmissionsInPage.map(singlePopulatedEmissionMesure => {
    const singleMesure = singlePopulatedEmissionMesure.data;
    const procedesList = getProcedesNames(
      singleMesure.procedes,
      procedesInPageMap
    );

    const singleMesureDto: AirFugitivesEmissionMesureDto2023 = {
      id: singleMesure.id,
      concentration: singleMesure.concentration
        ? singleMesure.concentration
        : {
            continu: false,
            frequence: null,
          },
      concentrationMoy: singleMesure.concentrationMoy,
      concentrationMoyUnite: singleMesure.concentrationMoyUnite,
      procedes: procedesList,
      epuration: !!singleMesure.epuration,
      heures: singleMesure.heures,
      nature: singleMesure.natureEquipement,
      debit: singleMesure.debit
        ? singleMesure.debit
        : {
            continu: false,
            frequence: null,
          },
      debitHoraire: singleMesure.debitHoraire,
      rendement: singleMesure.rendement,
      substanceID: singleMesure.substance ? singleMesure.substance.uuid : null,
      biomasse: singleMesure.biomasse,
    };
    return singleMesureDto;
  });

  return mesure;
};

export const convertDtoToMesureEmissionInArray = (
  mesure: AirFugitivesEmissionMesureDto2023,
  initialProcedes: ProcedeInArray[],
  substanceReferential: ReferenceItemPolluantDto[]
): MesureEmissionInArray => {
  const mesureSubstance =
    mesure.substanceID === null
      ? null
      : findElementMatchingTemplate(
          { uuid: mesure.substanceID },
          substanceReferential
        );

  return {
    data: {
      id: mesure.id,
      methods: MethodEnum.MESURE,
      substance: mesureSubstance,
      procedes: getProcedesIds(mesure.procedes, initialProcedes),
      debitHoraire: mesure.debitHoraire,
      debit: mesure.debit,
      heures: mesure.heures,
      concentrationMoy: mesure.concentrationMoy,
      concentrationMoyUnite:
        mesure.concentrationMoyUnite ||
        AirFugitivesEmissionMesureDto2023ConcentrationMoyUniteEnum.KG_NM3,
      concentration: mesure.concentration,
      epuration: mesure.epuration,
      natureEquipement: mesure.nature,
      rendement: mesure.rendement,
      biomasse: mesure.biomasse,
    },
    errors: {},
  };
};

export const createAirFugitivesEmissionMatiereDto = (
  matiereEmissionsInPage: MatiereEmissionInArray[],
  procedesInPageMap: Map<string, ProcedeInArray>
): AirFugitivesEmissionBilanDto20Now[] => {
  const matiere = matiereEmissionsInPage.map(singlePopulatedEmissionMatiere => {
    const singleMatiere = singlePopulatedEmissionMatiere.data;
    const matiereProcedes = getProcedesNames(
      singleMatiere.procedes,
      procedesInPageMap
    );
    const singleMatiereDto: AirFugitivesEmissionBilanDto20Now = {
      id: singleMatiere.id,
      elementID:
        singleMatiere.elementProps &&
        singleMatiere.elementProps.referenceItemElementIndexDto.uuid,
      elementName: singleMatiere.elementName,
      epuration: !!singleMatiere.epuration,
      nature: singleMatiere.natureEquipement,
      part: singleMatiere.part,
      procedes: matiereProcedes,
      rendement: singleMatiere.rendement,
      substanceID: singleMatiere.substance
        ? singleMatiere.substance.uuid
        : null,
      biomasse: singleMatiere.biomasse,
      intrants: singleMatiere.intrants || [],
      sortants: singleMatiere.sortants || [],
    };
    return singleMatiereDto;
  });

  return matiere;
};

export const convertDtoToMatiereEmissionInArray = (
  matiere: AirFugitivesEmissionBilanDto20Now,
  initialProcedes: ProcedeInArray[],
  substanceReferential: ReferenceItemPolluantDto[],
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >
): MatiereEmissionInArray => {
  const matiereSubstance =
    matiere.substanceID === null
      ? null
      : findElementMatchingTemplate(
          { uuid: matiere.substanceID },
          substanceReferential
        );

  const polluantElementsInReferential: Map<
    string | null,
    ReferenceItemPolluantElementDto
  > | null =
    polluantElementMap.get(
      matiereSubstance && matiereSubstance.restorationCode
    ) || null;

  const polluantElementInReferential: ReferenceItemPolluantElementDto | null =
    (polluantElementsInReferential &&
      polluantElementsInReferential.get(matiere.elementID)) ||
    null;

  return {
    data: {
      methods: MethodEnum.BILAN_MATIERE,
      substance: matiereSubstance,
      procedes: getProcedesIds(matiere.procedes, initialProcedes),
      elementName: matiere.elementName,
      intrants: matiere.intrants,
      sortants: matiere.sortants,
      elementProps: polluantElementInReferential,
      part: matiere.part,
      epuration: matiere.epuration,
      natureEquipement: matiere.nature,
      rendement: matiere.rendement,
      id: matiere.id,
      biomasse: matiere.biomasse,
    },
    errors: {},
  };
};

export const elementTooltip = (
  <span>
    Exemple: Dans le cas de la détermination des émissions de SO<sub>2</sub>{" "}
    issues de la combustion de fuel lourd ou de charbon, l'élément sur lequel
    est indexé le calcul est la teneur en soufre du combustible.{" "}
  </span>
);
