import * as Yup from "yup";
import {
  commonArrayOfMixedFields,
  commonBooleanFields,
  commonMixedFields,
  commonMixedFieldsNullable,
  commonNullableStringFields,
  commonNumberFields,
  commonNumberFieldsNullable,
  commonPercentageFields,
  commonPercentageFieldsNullable,
  commonPositiveNumberFields,
  commonPositiveNumberFieldsNullable,
  commonStrictlyPositiveNumberFieldsNullable,
  subFieldValidationScheme,
} from "common/declarant/formik/formikHelper";
import {
  combustibleFieldMatcher,
  CombustibleInModale,
  LegacyCombustible,
} from "../utils/types";
import {
  AirCombustionCombustibleDto20NowSurCendresEnum,
  AirCombustionCombustibleDto20NowUniteEnum,
  AirCombustionCombustiblePciDtoBrutEnum,
  AirCombustionCombustiblePciDtoUniteEnum,
  ReferenceItemCombustibleDto,
} from "api/gen";
import { isUnitePCS } from "../utils/selectPossibleValues";
import { Nullable } from "common/utils/types";
import {
  getMasseVolumiqueCombustible,
  shouldDisplayPreciserPci,
} from "../utils/CombustiblePciUtils";
import {
  shouldDisplayBiogazEcart,
  shouldDisplayMasseVolumique,
  shouldDisplayPciFields,
  shouldDisplayPreciserMasseVolumique,
} from "../utils/FormCombustibleUtils";
import {
  incorrectUnitForCombustibleMessage,
  positiveMessage,
  requiredMessage,
  tooBigMessage,
} from "../../../../../../../../../common/declarant/formik/formikMessages";
import { MAX_NUMBER } from "../../../../../../../../../common/declarant/formik/formikValidationHelper";
import { isCombustibleGazNaturel } from "../utils/CombustiblePcsUtils";
import { AppareilInArray } from "../../BlocAppareils/utils/types";
import { Errors } from "common/form/utils";

export const torchereSubPartActivated = (
  type: ReferenceItemCombustibleDto | null,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
): boolean => {
  return !!(
    type &&
    (type.codeCombustible === "309" || type.codeCombustible === "310") &&
    (isTorchereBiogaz || isValorisationBiogaz)
  );
};

export const ch4MesureSubPartActivated = (
  ch4Mesure: boolean | null
): boolean => {
  return !!ch4Mesure;
};

export const biogazMesureSubPartActivated = (
  biogazMesure: boolean | null
): boolean => {
  return !!biogazMesure;
};

export const singleCombustibleOverwriteDependantFields = (
  combustible: CombustibleInModale,
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
): void => {
  if (
    !torchereSubPartActivated(
      combustible.type || null,
      isTorchereBiogaz,
      isValorisationBiogaz
    )
  ) {
    combustible.biogazDebit = null;
    combustible.biogazMesure = null;
    combustible.biogazFrequence = null;
    combustible.biogazDuree = null;
    combustible.ch4Teneur = null;
    combustible.ch4Mesure = null;
    combustible.ch4Frequence = null;
    combustible.quantiteMethaneOxyde = null;
  }
  if (!ch4MesureSubPartActivated(combustible.ch4Mesure)) {
    combustible.ch4Frequence = null;
  }
  if (!biogazMesureSubPartActivated(combustible.biogazMesure)) {
    combustible.biogazFrequence = null;
  }
};

export const additionnalCombustibleValidation = (
  values: Nullable<Nullable<LegacyCombustible>>
): Errors<CombustibleInModale> => {
  let errors: Errors<CombustibleInModale> = {};
  const combustible: ReferenceItemCombustibleDto | null = values.type;

  const masseVolumiqueCombustible = getMasseVolumiqueCombustible(
    values,
    combustible
  );
  if (shouldDisplayMasseVolumique(values) && values.masse === null) {
    errors = { ...errors, masse: requiredMessage };
  }
  if (
    values.type &&
    shouldDisplayPreciserMasseVolumique(values, combustible) &&
    !values.masseEcart
  ) {
    errors = { ...errors, masseEcart: requiredMessage };
  }
  if (
    values.type &&
    shouldDisplayPreciserPci(values, combustible, masseVolumiqueCombustible) &&
    !values.pciEcart
  ) {
    errors = { ...errors, pciEcart: requiredMessage };
  }
  if (
    values.type &&
    shouldDisplayBiogazEcart(values, combustible) &&
    !values.biogazEcart
  ) {
    errors = { ...errors, biogazEcart: requiredMessage };
  }
  if (shouldDisplayPciFields(values?.unite || undefined)) {
    if (values.pciValeur === null) {
      errors = { ...errors, pciValeur: requiredMessage };
    }
    if (values.pciUnite === null) {
      errors = { ...errors, pciUnite: requiredMessage };
    }
    if (values.pciProvenance === null) {
      errors = { ...errors, pciProvenance: requiredMessage };
    }
    if (values.pciValeur !== null && values.pciValeur < 0) {
      errors = { ...errors, pciValeur: positiveMessage };
    } else if (values.pciValeur !== null && values.pciValeur > MAX_NUMBER) {
      errors = { ...errors, pciValeur: tooBigMessage };
    }
  }

  if (!isCombustibleGazNaturel(combustible) && isUnitePCS(values.unite)) {
    errors = { ...errors, unite: incorrectUnitForCombustibleMessage };
  }
  return errors;
};

export const singleCombustibleValidationSchema = (
  isTorchereBiogaz: boolean,
  isValorisationBiogaz: boolean
) =>
  Yup.object().shape({
    nameInstallation: commonNullableStringFields,
    type: commonMixedFields<ReferenceItemCombustibleDto>(),
    code: commonNullableStringFields,
    consommation: commonNumberFields,
    unite: commonMixedFields<AirCombustionCombustibleDto20NowUniteEnum>(),
    appareils: commonArrayOfMixedFields<AppareilInArray>(),
    masse: commonStrictlyPositiveNumberFieldsNullable,
    masseEcart: commonNullableStringFields,
    eau: commonPercentageFieldsNullable,
    carbone: commonPercentageFieldsNullable,
    soufre: commonPercentageFieldsNullable,
    surCendres: commonMixedFieldsNullable<
      AirCombustionCombustibleDto20NowSurCendresEnum
    >(),
    cendres: commonPercentageFieldsNullable,
    chlore: commonPercentageFieldsNullable,
    pciValeur: commonNumberFieldsNullable,
    pciUnite: commonMixedFieldsNullable<
      AirCombustionCombustiblePciDtoUniteEnum
    >(),
    pciEcart: commonNullableStringFields,
    pciBrut: commonMixedFieldsNullable<
      AirCombustionCombustiblePciDtoBrutEnum
    >(),
    pciProvenance: commonNullableStringFields,
    biogazFraction: commonPercentageFields,
    biogazEcart: commonNullableStringFields,
    biogazDebit: subFieldValidationScheme(
      combustibleFieldMatcher.type,
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    biogazMesure: subFieldValidationScheme(
      combustibleFieldMatcher.type,
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonBooleanFields,
      Yup.boolean()
    ),
    biogazFrequence: commonPositiveNumberFieldsNullable,
    biogazDuree: subFieldValidationScheme(
      combustibleFieldMatcher.type,
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Teneur: subFieldValidationScheme(
      combustibleFieldMatcher.type,
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonPositiveNumberFields,
      Yup.number()
    ),
    ch4Mesure: subFieldValidationScheme(
      combustibleFieldMatcher.type,
      (type: ReferenceItemCombustibleDto | null) =>
        torchereSubPartActivated(type, isTorchereBiogaz, isValorisationBiogaz),
      commonBooleanFields,
      Yup.boolean()
    ),
    ch4Frequence: commonPositiveNumberFieldsNullable,
    quantiteMethaneOxyde: commonPositiveNumberFieldsNullable,
    consommationPCI: commonPositiveNumberFieldsNullable,
  });
