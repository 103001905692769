import {
  AirSyntheseDto,
  AirSyntheseEmissionDto,
  ComputedAirSyntheseEmissionDto1823,
  ReferenceItemNormeAirDto,
  ReferenceItemPolluantDto,
} from "api/gen";
import {
  arrayMethodRefC,
  arrayMethodRefM,
  arrayMethods,
  MethodSyntheseInfo,
  ReferenceCalcule,
  ReferenceMesure,
} from "./selectPossibleValues";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { findElementMatchingTemplate } from "common/utils/methods";
import { SyntheseInArray, SyntheseInModale } from "./types";

const concatLibelleNorme = (norm: ReferenceItemNormeAirDto) => {
  return `${norm.libelleNorme}${
    norm.descriptionNorme ? ` - ${norm.descriptionNorme}` : ""
  }`;
};

const getSubstanceById = (
  substances: ReferenceItemPolluantDto[],
  substanceId: string
): ReferenceItemPolluantDto | null => {
  return findElementMatchingTemplate({ uuid: substanceId }, substances);
};

const getNormName = (
  normesID: string[],
  normes: ReferenceItemNormeAirDto[]
): OptionPropsWithObject<ReferenceItemNormeAirDto>[] => {
  const normsName: OptionPropsWithObject<ReferenceItemNormeAirDto>[] = [];
  normesID.forEach(el => {
    const norm = normes.find(norm => {
      return norm.uuid === el;
    });
    if (norm) {
      const normConcatDescription = concatLibelleNorme(norm);
      normsName.push({
        value: norm.idNorme,
        label: normConcatDescription,
        object: norm,
      });
    }
  });
  return normsName;
};

const findNormeID = (
  singleSynthese: SyntheseInModale,
  normes: ReferenceItemNormeAirDto[]
): string[] => {
  let normsName = [""];
  if (singleSynthese.normMINT) {
    normsName = singleSynthese.normMINT.map(norm => {
      return norm.label;
    });
  } else if (singleSynthese.normMPER) {
    normsName = singleSynthese.normMPER.map(norm => {
      return norm.label;
    });
  } else if (singleSynthese.normMNRO) {
    normsName = singleSynthese.normMNRO.map(norm => {
      return norm.label;
    });
  } else if (singleSynthese.normCINT) {
    normsName = singleSynthese.normCINT.map(norm => {
      return norm.label;
    });
  }
  const normsID: string[] = [];
  normsName.forEach(el => {
    const norm = normes.find(norm => {
      const normConcatDescription = concatLibelleNorme(norm);
      return normConcatDescription === el;
    });
    if (norm) {
      normsID.push(norm.uuid);
    }
  });
  return normsID;
};

export const convertEmissionToDisplayedEmission = (
  emission: AirSyntheseEmissionDto,
  airSyntheseEmissionComputed: ComputedAirSyntheseEmissionDto1823,
  substances: ReferenceItemPolluantDto[],
  normes: ReferenceItemNormeAirDto[]
): SyntheseInArray => {
  let methodComputed: OptionPropsWithObject<MethodSyntheseInfo> | null = null;
  let methodReferenceM: OptionPropsWithObject<ReferenceMesure> | null = null;
  let methodReferenceC: OptionPropsWithObject<ReferenceCalcule> | null = null;
  if (emission) {
    methodComputed =
      emission.methode === null
        ? null
        : findElementMatchingTemplate(
            { object: { code: emission.methode } },
            arrayMethods
          );
    methodReferenceM =
      emission.referenceMesure === null
        ? null
        : findElementMatchingTemplate(
            { object: { code: emission.referenceMesure } },
            arrayMethodRefM
          );
    methodReferenceC =
      emission.referenceCalcule === null
        ? null
        : findElementMatchingTemplate(
            { object: { code: emission.referenceCalcule } },
            arrayMethodRefC
          );
  }
  const substance = getSubstanceById(
    substances,
    airSyntheseEmissionComputed.substanceID
  );

  //TODO : compute errors
  return {
    data: {
      id: emission.id,
      additionalAccidentalEmissions: emission.additionnel,
      description: emission.description,
      emissionsAlreadyDeclared:
        airSyntheseEmissionComputed && airSyntheseEmissionComputed.declare,
      method: methodComputed,
      methodReferenceM: methodReferenceM,
      methodReferenceC: methodReferenceC,
      origin: emission.origine,
      substance: substance,
      totalEmissions:
        airSyntheseEmissionComputed && airSyntheseEmissionComputed.total,
      withAccidentalEmissions: emission.accidentel,
      normCINT:
        methodReferenceC &&
        methodReferenceC.label.toLowerCase() === "int" &&
        methodComputed &&
        methodComputed.label.toLowerCase() === "calcul"
          ? getNormName(emission.normesID, normes)
          : null,
      normMINT:
        methodReferenceM &&
        methodReferenceM.label.toLowerCase() === "int" &&
        methodComputed &&
        methodComputed.label.toLowerCase() === "mesure"
          ? getNormName(emission.normesID, normes)
          : null,
      normMNRO:
        methodReferenceM &&
        methodReferenceM.label.toLowerCase() === "nro" &&
        methodComputed &&
        methodComputed.label === "mesure"
          ? getNormName(emission.normesID, normes)
          : null,
      normMPER:
        methodReferenceM &&
        methodReferenceM.label.toLowerCase() === "per" &&
        methodComputed &&
        methodComputed.label.toLowerCase() === "mesure"
          ? getNormName(emission.normesID, normes)
          : null,
      totalEmissionsUsualUnit: null,
      uniteUsuelle: null,
      factor: null,
      isBiomasse: airSyntheseEmissionComputed.biomasse,
    },
    errors: {},
  };
};

export const createAirSyntheseDto = (
  SyntheseInArray: SyntheseInArray[],
  substances: ReferenceItemPolluantDto[],
  normes: ReferenceItemNormeAirDto[]
) => {
  const emissions = SyntheseInArray.map(singlePopulatedSynthese => {
    const singleSynthese = singlePopulatedSynthese.data;
    const singleEmission: AirSyntheseEmissionDto = {
      id: singlePopulatedSynthese.data.id,
      accidentel: singleSynthese.withAccidentalEmissions,
      additionnel: singleSynthese.additionalAccidentalEmissions,
      methode: singleSynthese.method && singleSynthese.method.object.code,
      origine: singleSynthese.origin,
      referenceCalcule:
        singleSynthese.methodReferenceC &&
        singleSynthese.methodReferenceC.object.code,
      referenceMesure:
        singleSynthese.methodReferenceM &&
        singleSynthese.methodReferenceM.object.code,
      substanceID:
        singleSynthese.substance === null ? "" : singleSynthese.substance.uuid,
      normesID: findNormeID(singleSynthese, normes),
      description: singleSynthese.description,
      biomasse: singleSynthese.isBiomasse || false,
    };
    return singleEmission;
  });

  const returnedDto: AirSyntheseDto = {
    emissions: emissions,
  };

  return returnedDto;
};
