import React, { ReactElement } from "react";
import { AerDonneeSurveillanceDto, MethodeCalculPfcDto22Now } from "api/gen";
import { makeStyles } from "@material-ui/styles";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { ClassesType } from "common/utils/types";
import { computeWrappedCell } from "common/utils/computeWrappedCell";
import { formatNiveauFlux } from "../../BlocPlanSurveillance/BlocDonneeSurveillance/utils";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

type AerMethodeCalculFluxPfc = Pick<
  MethodeCalculPfcDto22Now,
  | "referenceFlux"
  | "fluxName"
  | "donneeActivite"
  | "frequence"
  | "duree"
  | "sefCf4"
  | "fC2F6"
>;

export interface MethodeCalculFluxPfcListProps {
  methodeCalculFluxPfcList: AerMethodeCalculFluxPfc[];
}

const MethodeCalculFluxPfcList = ({
  methodeCalculFluxPfcList,
}: MethodeCalculFluxPfcListProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de calcul par émissions de
        PFC
      </SubSectionTitle>
      {methodeCalculFluxPfcList.map(
        ({
          referenceFlux,
          fluxName,
          donneeActivite,
          duree,
          frequence,
          fC2F6,
          sefCf4,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${fluxName || ""} - ${referenceFlux ||
              ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p></p>,
                <p>Valeur</p>,
                <p>Unité</p>,
                <p>Niveau appliqué</p>,
              ]}
              lines={[
                computeDonneeSurveillanceWithoutUnitLine(
                  "Données d'activité",
                  "t",
                  donneeActivite,
                  classes
                ),
                computeDonneeSurveillanceWithoutUnitLine(
                  "Fréquence",
                  "1/(cuve-jour)",
                  frequence,
                  classes
                ),
                computeDonneeSurveillanceWithoutUnitLine(
                  "Durée",
                  "min",
                  duree,
                  classes
                ),
                computeDonneeSurveillanceWithoutUnitLine(
                  "SEF(CF4)",
                  "(kgCF4/t Al)/(min/cuve-jour)",
                  sefCf4,
                  classes
                ),
                computeDonneeSurveillanceWithoutUnitLine(
                  "F(C2 F6)",
                  "tC2F6/tCF4",
                  fC2F6,
                  classes
                ),
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </>
  );
};

function computeDonneeSurveillanceWithoutUnitLine(
  lineDescription: string,
  unite: string,
  { valeur, niveauApplique }: AerDonneeSurveillanceDto,
  classes: ClassesType<"full">
): ReactElement[] {
  return [
    computeWrappedCell(lineDescription, classes),
    computeWrappedCell(valeur, classes),
    computeWrappedCell(unite, classes),
    computeWrappedCell(formatNiveauFlux(niveauApplique), classes),
  ];
}

export default MethodeCalculFluxPfcList;
