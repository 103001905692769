import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Row from "common/presentational/Row";
import {
  useDummyBooleanCheckboxFieldGenerator,
  useDummyNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import { DISABLED_BLOC_FILTER, LINK_STYLE, SUBTITLE_STYLE } from "theme";
import InstallationBloc from "./InstallationBloc";
import Tabs from "common/tabs";
import classNames from "classnames";
import EmissionBloc from "./EmissionBloc";
import { Information } from "./InformationBloc/utils/types";
import InformationBloc from "./InformationBloc";
import CovBloc from "./CovBloc";
import {
  getCOVNMError,
  getSommeCOVDangerTotal,
  getSommeCOVTotal,
} from "./utils/utils";
import isEqual from "lodash.isequal";
import {
  convertDtoToInformation,
  createAirSolvantsInformationDto,
} from "./InformationBloc/utils/utils";
import {
  convertInstallationInArraysToInstallationDtos,
  convertInstallationsToDisplayed,
} from "./InstallationBloc/utils/utils";
import {
  convertEmissionsToDisplayed,
  createAirSolvantEmissionDto,
} from "./EmissionBloc/utils/utils";
import {
  convertCovDangerToDisplayed,
  createAirSolvantsCovDto,
} from "./CovBloc/utils/utils";
import { ReferenceItemPolluantDto, ReferencePolluantDto } from "api/gen";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { InstallationInArray } from "./InstallationBloc/utils/types";
import { EmissionsInArray } from "./EmissionBloc/utils/types";
import { CovInArray } from "./CovBloc/utils/types";
import {
  PATH_AIR_SOLVANTS,
  PATH_AIR_SOLVANTS_COVNM,
  PATH_AIR_SOLVANTS_COVS,
  PATH_AIR_SOLVANTS_EMISSION,
  PATH_AIR_SOLVANTS_INFORMATION,
  PATH_AIR_SOLVANTS_INSTALLATION,
} from "common/path/path18Now";
import {
  useDeclaration19Now,
  useDeclarationHelpers19Now,
} from "../../versionedElements/declarationHooks19Now";
import GlobalFormActionFullContext19Now from "../../versionedElements/GlobalFormActionFullContext19Now";
import { useCorrectYearTypeActiviteGlobal19Now } from "../../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";

const useStyles = makeStyles({
  disable: {
    filter: DISABLED_BLOC_FILTER,
    pointerEvents: "none",
  },
  message: {
    textAlign: "center",
    color: "black",
    fontSize: "18px",
    width: "100%",
  },
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  specialDummyNumber: {
    color: "pink",
  },
  link: LINK_STYLE,
  calculated: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    "& > div": {
      flexDirection: "column",
      alignItems: "center",
      width: "33%",
      minWidth: "300px",
      "& > div": {
        display: "block",
        textAlign: "center",
        width: "auto !important",
        height: "auto !important",
        minHeight: "30px",
        marginRight: "0",
        "& > div": {
          position: "relative",
          "& > div": {
            margin: "auto",
            position: "relative",
          },
          "& > svg": {
            position: "absolute",
            right: "-40px",
          },
        },
      },
    },
  },
});

interface MainSolvFormProps {
  referential: ReferencePolluantDto;
  referentialCOVNM: ReferenceItemPolluantDto;
}

const MainSolvForm = ({
  referential,
  referentialCOVNM,
}: MainSolvFormProps): React.ReactElement => {
  const declaration = useDeclaration19Now();
  const typeActiviteGlobal = useCorrectYearTypeActiviteGlobal19Now();

  const { isPathValidatedInDeclaration } = useDeclarationHelpers19Now();
  const classes = useStyles();
  const blocSolvantsDto = declaration.body.sections.air.solvants;

  const substances: ReferenceItemPolluantDto[] = useMemo(
    () =>
      referential.polluants.filter(polluant => {
        return polluant.covNm === true;
      }),
    [referential]
  );

  const initialInformations = convertDtoToInformation(
    blocSolvantsDto.informations
  );
  const initialInstallations = blocSolvantsDto.installations.map(installation =>
    convertInstallationsToDisplayed(installation)
  );
  const initialEmissionTotales = blocSolvantsDto.covnms.map(covnm =>
    convertEmissionsToDisplayed(referentialCOVNM, covnm, substances)
  );
  const initialEmissionSpecifiques = blocSolvantsDto.covs.map(cov =>
    convertEmissionsToDisplayed(referentialCOVNM, cov, substances)
  );
  const initialCovs = blocSolvantsDto.emissions.map(emissions =>
    convertCovDangerToDisplayed(emissions)
  );

  const [installationsInPage, setInstallationsInPage] = useState<
    InstallationInArray[]
  >(initialInstallations);
  const [emissionTotalesInPage, setEmissionTotalesInPage] = useState<
    EmissionsInArray[]
  >(initialEmissionTotales);
  const [emissionSpecifiquesInPage, setEmissionSpecifiquesInPage] = useState<
    EmissionsInArray[]
  >(initialEmissionSpecifiques);
  const [covsInPage, setCovsInPage] = useState<CovInArray[]>(initialCovs);
  const formikInformationProps = useFormikBloc<Information>();

  const pageHasChanges =
    formikInformationProps.hasChanges ||
    !isEqual(initialInstallations, installationsInPage) ||
    !isEqual(initialEmissionTotales, emissionTotalesInPage) ||
    !isEqual(initialEmissionSpecifiques, emissionSpecifiquesInPage) ||
    !isEqual(initialCovs, covsInPage);

  const hasDangerCOV =
    declaration.body.sections.air.solvants.informations.danger;
  const isInformationValidated = isPathValidatedInDeclaration(
    PATH_AIR_SOLVANTS_INFORMATION
  );
  const isInstallationValidated = isPathValidatedInDeclaration(
    PATH_AIR_SOLVANTS_INSTALLATION
  );
  const isEmissionTotalesValidated = isPathValidatedInDeclaration(
    PATH_AIR_SOLVANTS_COVNM
  );
  const isEmissionSpecifiquesValidated = isPathValidatedInDeclaration(
    PATH_AIR_SOLVANTS_COVS
  );
  const isCovsValidated = isPathValidatedInDeclaration(
    PATH_AIR_SOLVANTS_EMISSION
  );

  const canSubmitForm = () => {
    return (
      !getCOVNMError(
        emissionTotalesInPage,
        emissionSpecifiquesInPage,
        covsInPage,
        hasDangerCOV
      ) &&
      isInformationValidated &&
      isInstallationValidated &&
      (installationsInPage.length === 0 ||
        (isEmissionTotalesValidated &&
          isEmissionSpecifiquesValidated &&
          (!hasDangerCOV || isCovsValidated)))
    );
  };

  const commonProps = {
    disabled: true,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-solvant-individuelle",
  };

  const DummyCheckbox = useDummyBooleanCheckboxFieldGenerator(commonProps);
  const DummyNumber = useDummyNumberFieldGenerator(commonProps);

  const tabsContent = [
    {
      title: "ÉMISSIONS DE COVNM TOTAUX",
      renderComponent: () => (
        <EmissionBloc
          setEmissionInPage={setEmissionTotalesInPage}
          initialInstallations={initialInstallations}
          emissionInPage={emissionTotalesInPage}
          initialEmission={initialEmissionTotales}
          isSpecific={false}
          otherEmissionInPage={emissionSpecifiquesInPage}
          covsInPage={covsInPage}
          validationEmissionPath={PATH_AIR_SOLVANTS_COVNM}
          substances={substances}
          referentialCOVNM={referentialCOVNM}
        />
      ),
    },
    {
      title: "ÉMISSIONS DE COVNM SPECIFIQUES",
      renderComponent: () => (
        <EmissionBloc
          setEmissionInPage={setEmissionSpecifiquesInPage}
          initialInstallations={initialInstallations}
          emissionInPage={emissionSpecifiquesInPage}
          initialEmission={initialEmissionSpecifiques}
          isSpecific={true}
          otherEmissionInPage={emissionTotalesInPage}
          covsInPage={covsInPage}
          validationEmissionPath={PATH_AIR_SOLVANTS_COVS}
          substances={substances}
          referentialCOVNM={referentialCOVNM}
        />
      ),
    },
  ];

  if (hasDangerCOV) {
    tabsContent.push({
      title: "ÉMISSIONS DE COVNM PAR MENTION DE DANGER",
      renderComponent: () => (
        <CovBloc
          setCovsInPage={setCovsInPage}
          initialInstallations={initialInstallations}
          covsInPage={covsInPage}
          initialCovs={initialCovs}
          emissionSpecifiquesInPage={emissionSpecifiquesInPage}
          emissionTotalesInPage={emissionTotalesInPage}
          validationCovsPath={PATH_AIR_SOLVANTS_EMISSION}
        />
      ),
    });
  }

  return (
    <>
      <Row />
      <span>
        Pour accéder à l'outil d'aide au remplissage des émissions de COV,
        veuillez cliquer{" "}
      </span>
      <a
        href={`${process.env.PUBLIC_URL}/download/OutilConversionEquivalentCarboneCOV.xlsx`}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        ici
      </a>
      <span>.</span>
      <Row />
      <Row>
        <span className={classes.subTitle}>
          Données héritées du bloc informations générales
        </span>
      </Row>
      <DummyCheckbox
        name="consommationSolvant"
        label="L'établissement consomme des solvants"
        disabled={true}
        value={typeActiviteGlobal.estConsommateurSolvant}
      />
      <Row height={"20px"} />
      <InformationBloc
        validationInformationPath={PATH_AIR_SOLVANTS_INFORMATION}
        initialInformations={initialInformations}
        formikInformationProps={formikInformationProps}
      />
      <Row />
      <InstallationBloc
        initialInstallations={initialInstallations}
        installationsInPage={installationsInPage}
        setInstallationsInPage={setInstallationsInPage}
        emissionTotalesInPage={emissionTotalesInPage}
        setEmissionTotalesInPage={setEmissionTotalesInPage}
        setEmissionSpecifiquesInPage={setEmissionSpecifiquesInPage}
        emissionSpecifiquesInPage={emissionSpecifiquesInPage}
        covsInPage={covsInPage}
        setCovsInPage={setCovsInPage}
        validationInstallationPath={PATH_AIR_SOLVANTS_INSTALLATION}
      />
      <Row height={"20px"} />
      <div>
        {!isInstallationValidated || !isInformationValidated ? (
          <>
            <div className={classes.message}>
              Veuillez valider votre déclaration d'installation ainsi que vos
              informations afin d'ajouter vos émissions
            </div>
            <Row height={"20px"} />
          </>
        ) : null}
        <div
          className={classNames({
            [classes.disable]:
              !isInstallationValidated ||
              !isInformationValidated ||
              installationsInPage.length === 0,
          })}
        >
          <Tabs content={tabsContent} />
          <Row />
          <div className={classes.calculated}>
            <DummyNumber
              label="Somme des émissions de COVNM totaux"
              name="COVNMTotaux"
              unit="kg/an"
              value={getSommeCOVTotal(emissionTotalesInPage)}
              disabled
              additionalClassName={classes.specialDummyNumber}
              error={getCOVNMError(
                emissionTotalesInPage,
                emissionSpecifiquesInPage,
                covsInPage,
                hasDangerCOV
              )}
            />
            <DummyNumber
              label="Somme des émissions de COVNM Spécifiques"
              name="COVSpecifique"
              unit="kg/an"
              value={getSommeCOVTotal(emissionSpecifiquesInPage)}
              disabled
              additionalClassName={classes.specialDummyNumber}
            />
            <DummyNumber
              label="Somme des émissions de COVNM par mention de danger"
              name="COVDanger"
              unit="kg/an"
              value={getSommeCOVDangerTotal(covsInPage, hasDangerCOV)}
              disabled
              additionalClassName={classes.specialDummyNumber}
            />
          </div>
          <Row />
        </div>
      </div>
      <GlobalFormActionFullContext19Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={pageHasChanges}
        isValidateEnabled={canSubmitForm()}
        validationPath={PATH_AIR_SOLVANTS}
        updateHandler={declaration => {
          if (
            formikInformationProps.formikRef &&
            formikInformationProps.formikRef.current
          ) {
            declaration.body.sections.air.solvants.informations = createAirSolvantsInformationDto(
              formikInformationProps.formikRef.current.state.values,
              declaration.body.sections.air.solvants.informations
            );
          }
          declaration.body.sections.air.solvants.installations = convertInstallationInArraysToInstallationDtos(
            installationsInPage
          );
          declaration.body.sections.air.solvants.covs = createAirSolvantEmissionDto(
            emissionSpecifiquesInPage
          );
          declaration.body.sections.air.solvants.covnms = createAirSolvantEmissionDto(
            emissionTotalesInPage
          );
          declaration.body.sections.air.solvants.emissions = createAirSolvantsCovDto(
            covsInPage
          );
          return declaration;
        }}
        cancelAction={() => {
          setInstallationsInPage(initialInstallations);
          if (
            formikInformationProps.formikRef &&
            formikInformationProps.formikRef.current
          ) {
            formikInformationProps.formikRef.current.resetForm(
              initialInformations
            );
          }
          setEmissionSpecifiquesInPage(initialEmissionSpecifiques);
          setEmissionTotalesInPage(initialEmissionTotales);
          setCovsInPage(initialCovs);
        }}
      />
    </>
  );
};

export default MainSolvForm;
