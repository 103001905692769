import React from "react";
import { PolluantInArray, SolCommonSelectOptions } from "./utils/types";
import FormSinglePolluant from "./FormSinglePolluant";
import CustomModal from "common/presentational/CustomModal";
import { ReferenceNormeSolDto } from "api/gen";
import { addOrModifyArray } from "common/declarant/array/utils";
import { CallbackSetter } from "common/utils/types";

interface SolModaleProps {
  isOpen: boolean;
  polluantInModal: PolluantInArray | null;
  referentiels: SolCommonSelectOptions;
  closeModal: () => void;
  setPolluantsInArray: CallbackSetter<PolluantInArray[]>;
  referentialNormes: ReferenceNormeSolDto;
  polluantsInArray: PolluantInArray[];
}

const SolModale = ({
  isOpen,
  polluantInModal,
  referentiels,
  closeModal,
  setPolluantsInArray,
  referentialNormes,
  polluantsInArray,
}: SolModaleProps): React.ReactElement => {
  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout de polluants."
      onRequestClose={closeModal}
    >
      <FormSinglePolluant
        changedElemId={polluantInModal && polluantInModal.data.id}
        polluantsInArray={polluantsInArray}
        closeFunction={closeModal}
        onSubmit={values => {
          addOrModifyArray(
            setPolluantsInArray,
            polluantInModal && polluantInModal.data.id,
            values
          );
          closeModal();
        }}
        referentiels={referentiels}
        initialPolluant={
          polluantInModal !== null
            ? polluantInModal.data
            : {
                pollutingSubstance: null,
                emission: null,
                includingAccidental: null,
                usedMethod: null,
                methodReference: null,
                methodDescription: null,
                polluantNorme: null,
              }
        }
        referentialNormes={referentialNormes}
      />
    </CustomModal>
  );
};

export default SolModale;
