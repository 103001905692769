import { AirCombustionInstallationsDto20NowTypeEnum } from "api/gen";
import { TypeActiviteGlobal20Now } from "pages/CompanySpace/from20/toNow/versionedElements/declarationHooks20Now";

export enum InstallationCategory {
  LCP,
  WICOWI,
  INCINERATION,
  OVER20MW,
  TORCHERE,
  VALORISATION,
}

export const arrayTypeInstallationsLabel: {
  [key in AirCombustionInstallationsDto20NowTypeEnum]: string;
} = {
  LCP: "LCP - grande installation de combustion",
  WI: "WI - incinération",
  CoWI: "coWI - co-incinération",
  LCP_WI: "LCP/WI",
  LCP_COWI: "LCP/CoWI",
  INFERIEUR_20MW: "Autres installations",
  SUPERIEUR_20MW: "Combustion >20 MW (rubrique 2910/3110)",
  INCINERATION: "Incinération de déchets",
  TORCHERE: "Torchère ISDND",
  VALORISATION: "Valorisation ISDND",
};

export const arrayTypeInstallations: AirCombustionInstallationsDto20NowTypeEnum[] = Object.keys(
  arrayTypeInstallationsLabel
) as AirCombustionInstallationsDto20NowTypeEnum[];

export const arrayInstallationsRequiredCategories: {
  [key in AirCombustionInstallationsDto20NowTypeEnum]: InstallationCategory[];
} = {
  LCP: [InstallationCategory.OVER20MW, InstallationCategory.LCP],
  WI: [InstallationCategory.INCINERATION, InstallationCategory.WICOWI],
  CoWI: [InstallationCategory.INCINERATION, InstallationCategory.WICOWI],
  LCP_WI: [
    InstallationCategory.OVER20MW,
    InstallationCategory.LCP,
    InstallationCategory.INCINERATION,
    InstallationCategory.WICOWI,
  ],
  LCP_COWI: [
    InstallationCategory.OVER20MW,
    InstallationCategory.LCP,
    InstallationCategory.INCINERATION,
    InstallationCategory.WICOWI,
  ],
  INFERIEUR_20MW: [],
  SUPERIEUR_20MW: [InstallationCategory.OVER20MW],
  INCINERATION: [InstallationCategory.INCINERATION],
  TORCHERE: [InstallationCategory.TORCHERE],
  VALORISATION: [InstallationCategory.VALORISATION],
};

export const getListTypeInstallation = (
  typeActiviteGlobal: TypeActiviteGlobal20Now,
  isLCPAvailable: boolean,
  isWicowiAvailable: boolean
): AirCombustionInstallationsDto20NowTypeEnum[] => {
  const categoriesSelected: Set<InstallationCategory> = new Set();
  if (typeActiviteGlobal.combustion20MW) {
    categoriesSelected.add(InstallationCategory.OVER20MW);
  }
  if (typeActiviteGlobal.incinerationDechet) {
    categoriesSelected.add(InstallationCategory.INCINERATION);
  }
  if (isLCPAvailable) {
    categoriesSelected.add(InstallationCategory.LCP);
  }
  if (isWicowiAvailable) {
    categoriesSelected.add(InstallationCategory.WICOWI);
  }
  if (typeActiviteGlobal.torchereBiogaz) {
    categoriesSelected.add(InstallationCategory.TORCHERE);
  }
  if (typeActiviteGlobal.valorisationBiogaz) {
    categoriesSelected.add(InstallationCategory.VALORISATION);
  }
  return arrayTypeInstallations.filter(installation =>
    arrayInstallationsRequiredCategories[installation].every(c =>
      categoriesSelected.has(c)
    )
  );
};
