import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  CampaignDtoStateEmissionsEnum,
  QuotasBlocEmissionsBilanTotalDto20Now,
  QuotasBlocEmissionsEmailsVerificateursDto20Now,
  QuotasInstallationDto20Now,
  ReferenceActiviteQuotaDto,
} from "api/gen";
import { useDummyBooleanCheckboxFieldGenerator } from "common/form/fields/helpers/generators";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_QUOTAS_EMISSIONS,
  PATH_QUOTAS_EMISSIONS_BLOC,
  PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC,
} from "common/path/path20Now";
import Row from "common/presentational/Row";
import {
  DISABLED_SECTION_STYLE,
  LEFT_WITHDRAW_STYLE,
  SUBTITLE_STYLE,
} from "theme";
// Bloc Bilan Total
import BlocBilanTotal from "./BlocBilanTotal";
import { updateHandler as updateHandlerBilanTotal } from "./BlocBilanTotal/utils";
// Bloc Bilan Total Calcule
import BlocBilanTotalCalcule from "./BlocBilanTotalCalcule";
// Bloc Emissions
import BlocEmissions from "./BlocEmissions";
import {
  dtoExemptedFlowsToExemptedFlowDeclarations,
  emissionSimpleDtoToAlternativeEmission,
  emissionSimpleDtoToComputedEmission,
  emissionSimpleDtoToMeasuredEmission,
  fluxDtoToFlowDeclaration,
  pointMesureDtoToMeasureDeclaration,
  updateHandler as updateHandlerEmissions,
} from "./BlocEmissions/converter";
import {
  AlternativeEmissionInArray,
  BlocEmissionsFormValues,
  ComputedEmissionInArray,
  MeasureEmissionInArray,
} from "./BlocEmissions/types";
import {
  computeAllowedDeclarationsMethods,
  generateSetterBlocEmissions,
} from "./BlocEmissions/utils/utils";
// Bloc Plan de Surveillance
import BlocPlanDeSurveillance from "./BlocPlanDeSurveillance";
import { updateHandler as updateHandlerPlanDeSurveillance } from "./BlocPlanDeSurveillance/converter";
import { BlocPlanDeSurveillanceFormValues } from "./BlocPlanDeSurveillance/types";
// Bloc Verification
import BlocVerification from "./BlocVerification";
import QuotasGlobalFormActions from "../QuotasGlobalFormActions";
import { useAllDeclarantBlocsEmissionsValidated } from "../utils/utils";
import BlocEmailsVerificateurs from "./BlocEmailsVerificateurs";
import { updateHandlerEmailsAddress } from "./BlocEmailsVerificateurs/utils";
import { WorkflowTarget } from "common/utils/types";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import BlocInfosComplementairesInstallations from "./BlocInfosComplementairesInstallations";
import { updateHandler as updateHandlerBlocInfosComplementaires } from "./BlocInfosComplementairesInstallations/utils";
import { BlocInfosComplementaires } from "./BlocInfosComplementairesInstallations/types";
import classNames from "classnames";
import BlocRappelInstallationsQuotas from "../common/BlocRappelInstallationsQuotas";
import QuotasDeclarationOfficeDownload from "../common/BlocInspecteur/QuotasDeclarationOfficeDownload";
import {
  useBasicDeclarationHandlers2020,
  useDeclaration2020,
  useDeclarationHelpers2020,
  useDeclarationStateHandlers2020,
} from "../../versionedElements/declarationHooks2020";
import BlocInspecteur from "../common/BlocInspecteur";
import { isFullDeclarationActive } from "../../../toNow/Quotas/utils";

interface EmissionsFormProps {
  referentialActivites: ReferenceActiviteQuotaDto;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  subTitle: SUBTITLE_STYLE,
  ...LEFT_WITHDRAW_STYLE,
  ...DISABLED_SECTION_STYLE,
});

const EmissionsForm = ({
  referentialActivites,
}: EmissionsFormProps): React.ReactElement => {
  const declaration = useDeclaration2020();
  const currentCampaign = useCurrentDeclarationCampaign();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2020();
  const { update } = useBasicDeclarationHandlers2020();
  const { yieldQuotaEmissionsVerif } = useDeclarationStateHandlers2020();
  const classes = useStyles();

  const typeActiviteQuotas = declaration.body.typeActivite;

  const installations: QuotasInstallationDto20Now[] =
    typeActiviteQuotas.installations;

  const blocPlanDeSurveillanceProps = useFormikBloc<
    BlocPlanDeSurveillanceFormValues
  >();
  const blocEmissionsProps = useFormikBloc<BlocEmissionsFormValues>();
  const blocBilanTotalProps = useFormikBloc<
    QuotasBlocEmissionsBilanTotalDto20Now
  >();
  const blocEmailsVerificateursProps = useFormikBloc<
    QuotasBlocEmissionsEmailsVerificateursDto20Now
  >();
  const blocInfosComplementairesInstallationsProps = useFormikBloc<
    BlocInfosComplementaires
  >();

  const DummyCheckbox = useDummyBooleanCheckboxFieldGenerator({
    disabled: true,
    className: classes.inputField,
    labelWidth: "60%",
    formPrefix: "",
  });

  const flowDeclarations = declaration.body.sections.quotas.emissions.blocEmissions.flux.map(
    f => fluxDtoToFlowDeclaration(f, installations)
  );
  const measureDeclarations = declaration.body.sections.quotas.emissions.blocEmissions.pointsMesure.map(
    p => pointMesureDtoToMeasureDeclaration(p, installations)
  );

  const exemptedFlowsSelected = dtoExemptedFlowsToExemptedFlowDeclarations(
    declaration.body.sections.quotas.emissions.blocEmissions
      .fluxSoumisADerogation,
    flowDeclarations
  );

  const [computedEmissionsInArray, setComputedEmissionsInArray] = useState<
    ComputedEmissionInArray[]
  >(
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsCalculees.map(
      emission =>
        emissionSimpleDtoToComputedEmission(emission, flowDeclarations)
    )
  );
  const [measuredEmissionsInArray, setMeasuredEmissionInArray] = useState<
    MeasureEmissionInArray[]
  >(
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsMesurees.map(
      emission =>
        emissionSimpleDtoToMeasuredEmission(emission, measureDeclarations)
    )
  );
  const [
    alternativeEmissionsInArray,
    setAlternativeEmissionsInArray,
  ] = useState<AlternativeEmissionInArray[]>(
    declaration.body.sections.quotas.emissions.blocEmissions.emissionsMethodeAlternative.map(
      emission =>
        emissionSimpleDtoToAlternativeEmission(emission, installations)
    )
  );

  // Note : we didn't test whether this method worked as intended and was regenerated when the blocInstallation values changed. Since it's for the time being called only with empty values,
  // this doesn't cause problems, but it may be a source of bugs if called with data where the bloc installation props values is actually used.
  const setBlocEmissions = generateSetterBlocEmissions(
    installations,
    blocEmissionsProps.formikRef,
    setAlternativeEmissionsInArray,
    setMeasuredEmissionInArray,
    setComputedEmissionsInArray
  );

  const isFullForm = isFullDeclarationActive(declaration);

  const pageHasChanges =
    blocPlanDeSurveillanceProps.hasChanges ||
    blocEmissionsProps.hasChanges ||
    blocEmailsVerificateursProps.hasChanges ||
    blocInfosComplementairesInstallationsProps.hasChanges;

  // Are blocs validated?
  const allDeclarantBlocsValidated = useAllDeclarantBlocsEmissionsValidated(
    isFullForm
  );

  const isPlanSurveillanceValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC
  );
  const isBlocEmissionsValidated = isPathValidatedInDeclaration(
    PATH_QUOTAS_EMISSIONS_BLOC
  );
  const isBlocInfosComplementaireAvailable =
    isPlanSurveillanceValidated && isBlocEmissionsValidated;

  const globalFormActions = (
    <QuotasGlobalFormActions
      pathToValidate={PATH_QUOTAS_EMISSIONS}
      target={WorkflowTarget.QUOTA_EMISSIONS}
      quotaState={declaration.body.workflowStatus.quotaEmissions.state}
      isSimplifiedQuota={declaration.body.typeActivite.excluArticle27}
      yieldQuotaVerif={yieldQuotaEmissionsVerif}
      saveAction={() =>
        update(declaration => {
          if (isFullForm) {
            declaration = updateHandlerPlanDeSurveillance(
              declaration,
              blocPlanDeSurveillanceProps.getValues()
            );
            declaration = updateHandlerEmissions(
              declaration,
              blocEmissionsProps.getValues(),
              computedEmissionsInArray,
              measuredEmissionsInArray,
              alternativeEmissionsInArray,
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .flowDeclarations
                : flowDeclarations,
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .measureDeclarations
                : measureDeclarations,
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .exemptedFlows
                : exemptedFlowsSelected
            );
            declaration = updateHandlerBilanTotal(declaration, null);
            declaration = updateHandlerEmailsAddress(
              declaration,
              blocEmailsVerificateursProps.getValues()
            );
            declaration = updateHandlerBlocInfosComplementaires(
              declaration,
              blocInfosComplementairesInstallationsProps.getValues()
            );
          } else {
            declaration = updateHandlerPlanDeSurveillance(declaration, null);
            declaration = updateHandlerEmissions(
              declaration,
              null,
              [],
              [],
              [],
              [],
              [],
              []
            );
            declaration = updateHandlerBilanTotal(
              declaration,
              blocBilanTotalProps.getValues()
            );
            declaration = updateHandlerEmailsAddress(declaration, null);
          }
          return declaration;
        })
      }
      cancelAction={() => {
        blocPlanDeSurveillanceProps.resetForm();
        blocEmissionsProps.resetForm();
        blocBilanTotalProps.resetForm();
        blocEmailsVerificateursProps.resetForm();
        blocInfosComplementairesInstallationsProps.resetForm();
      }}
      hasChanges={pageHasChanges}
      declarantBlocsValidated={allDeclarantBlocsValidated}
    />
  );

  return (
    <>
      <Row />

      <QuotasDeclarationOfficeDownload
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
      />

      <Row />

      <Row additionalStyle={{ justifyContent: "flex-start" }}>
        <h3 className={classes.subTitle}>
          DONNÉES HÉRITÉES DU BLOC INFORMATIONS GÉNÉRALES
        </h3>
      </Row>

      <DummyCheckbox
        name="soumisQuotas"
        label="L'établissement est soumis à la directive 2003/87/CE (directive quotas)"
        value={typeActiviteQuotas.estSousQuotaCo2}
      />

      <Row />

      <div className={classes.withdrawLeft}>
        <DummyCheckbox
          name="excluArticle27"
          label="L’établissement est exclu au titre de l’article 27 (exclusion hôpitaux) de la phase 3"
          value={typeActiviteQuotas.excluArticle27}
        />
      </div>

      <Row />

      <BlocRappelInstallationsQuotas
        referentialActivites={referentialActivites}
      />

      <Row />

      {isFullForm ? (
        <>
          <Row />

          <BlocPlanDeSurveillance
            blocPlanDeSurveillanceFormProps={blocPlanDeSurveillanceProps}
            setBlocEmissions={setBlocEmissions}
          />
          <Row />

          <BlocEmissions
            blocEmissionsFormProps={blocEmissionsProps}
            isDisabled={
              !isPathValidatedInDeclaration(PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC)
            }
            computedEmissionsInArray={computedEmissionsInArray}
            setComputedEmissionsInArray={setComputedEmissionsInArray}
            measuredEmissionsInArray={measuredEmissionsInArray}
            setMeasuredEmissionsInArray={setMeasuredEmissionInArray}
            alternativeEmissionsInArray={alternativeEmissionsInArray}
            setAlternativeEmissionsInArray={setAlternativeEmissionsInArray}
            allowedDeclarationMethods={computeAllowedDeclarationsMethods(
              declaration.body.sections.quotas.emissions.blocPlanSurveillance
            )}
          />

          <Row />

          <BlocBilanTotalCalcule
            installations={installations}
            computedEmissionsInArray={computedEmissionsInArray}
            measuredEmissionsInArray={measuredEmissionsInArray}
            alternativeEmissionsInArray={alternativeEmissionsInArray}
            measureDeclarations={
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .measureDeclarations
                : measureDeclarations
            }
            flowDeclarations={
              blocEmissionsProps.formikRef.current
                ? blocEmissionsProps.formikRef.current.state.values
                    .flowDeclarations
                : flowDeclarations
            }
          />

          <Row />

          {!isBlocInfosComplementaireAvailable && (
            <>
              <div className={classes.disabledMessage}>
                Veuillez valider les parties précédentes pour accéder aux
                informations complémentaires sur les installations.
              </div>
              <Row height={"20px"} />
            </>
          )}

          <div
            className={classNames({
              [classes.disabledSection]: !isBlocInfosComplementaireAvailable,
            })}
          >
            <BlocInfosComplementairesInstallations
              hasChanges={blocInfosComplementairesInstallationsProps.hasChanges}
              setHasChanges={
                blocInfosComplementairesInstallationsProps.setHasChanges
              }
              formikRef={blocInfosComplementairesInstallationsProps.formikRef}
            />
          </div>

          <Row />

          <BlocEmailsVerificateurs
            formikRef={blocEmailsVerificateursProps.formikRef}
            hasChanges={blocEmailsVerificateursProps.hasChanges}
            setHasChanges={blocEmailsVerificateursProps.setHasChanges}
          />

          {globalFormActions}

          <Row />

          <BlocVerification />
        </>
      ) : (
        <>
          <Row />

          <BlocBilanTotal
            formikRef={blocBilanTotalProps.formikRef}
            hasChanges={blocBilanTotalProps.hasChanges}
            setHasChanges={blocBilanTotalProps.setHasChanges}
          />

          <Row />

          {globalFormActions}
        </>
      )}

      <Row />

      <BlocInspecteur
        canValidatePage={allDeclarantBlocsValidated}
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
        currentState={declaration.body.workflowStatus.quotaEmissions.state}
        path={PATH_QUOTAS_EMISSIONS}
        isCampaignEnded={
          currentCampaign.stateEmissions === CampaignDtoStateEmissionsEnum.ENDED
        }
      />

      <Row height={"25px"} />
    </>
  );
};

export default EmissionsForm;
