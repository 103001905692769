import React from "react";
import { ContactInfosModal2018Now } from "./ContactInfoModal2018Now";
import ShouldNotHappenComponent from "../../errors/ShouldNotHappenComponent";

interface ContactInfosModalProps {
  isOpen: boolean;
  company: string | null;
  year: number | null;
  onRequestClose: () => void;
}

export const VersionedContactInfoModal = ({
  isOpen,
  company,
  year,
  onRequestClose,
}: ContactInfosModalProps): React.ReactElement => {
  if (year == null || year >= 2018) {
    return (
      <ContactInfosModal2018Now
        isOpen={isOpen}
        company={company}
        year={year}
        onRequestClose={onRequestClose}
      />
    );
  }

  return (
    <ShouldNotHappenComponent
      reason={"unexpected error, year passed with no Contact info modal"}
    />
  );
};
