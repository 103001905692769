import React from "react";
import { TEXT_LIGHT_GREY } from "theme";
import { makeStyles } from "@material-ui/styles";
import { ImageProps } from "common/utils/types";
import classNames from "classnames";

const useStyles = makeStyles({
  container: {
    display: "flex",
    marginBottom: "5px",
    justifyContent: "flex-end",
    height: "38px",
    fontSize: "15px",
    color: TEXT_LIGHT_GREY,
  },
  content: {
    display: "flex",
    width: "50%",
    "& img": {
      height: "100%",
      margin: "0 1em",
    },
  },
  message: {
    marginLeft: "1em",
  },
});

interface MessageInfoFieldProps {
  icon?: ImageProps;
  message: React.ReactNode;
  additionalClassname?: string;
}

const MessageInfoField = ({
  icon,
  message,
  additionalClassname,
}: MessageInfoFieldProps): React.ReactElement => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.container, additionalClassname)}>
      <div className={classes.content}>
        {icon ? <img src={icon.src} alt={icon.alt} /> : null}
        <span className={classes.message}>{message}</span>
      </div>
    </div>
  );
};

export default MessageInfoField;
