import React, { useMemo } from "react";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { Declarants } from "./DeclarantIdentityList/types";
import {
  ActivityType,
  AdministratifType,
  CoordonneesDepartementMap,
  franceOption,
  InspectionServiceDto,
} from "./utils/types";
import Row from "common/presentational/Row";
import {
  computeActiviteInitialValues,
  computeAdminInitialValues,
  computeDeclarantInitialValues,
  createActivityDto,
  createAdministratifDto,
} from "./utils/mainSubmitHandler";
import { DeclarantIdentityList } from "./DeclarantIdentityList";
import { CompanyIdentity } from "./CompanyIdentity";
import { CompanyActivity } from "./CompanyActivity";
import {
  DeclarationStateDtoStateEnum,
  EquipeGunItemDto,
  ReferenceCodeApeDto,
  ReferenceEprtrDto,
  ReferenceEquipeGunDto,
  ReferenceGeoCodeInseeDto,
  ReferenceItemCodeApeDto,
  ReferenceItemCoordonneesDepartementDto,
  ReferenceItemEprtrDto,
  ReferenceItemPaysDto,
  ReferenceNatureServiceDto,
  ReferencePaysDto,
  ReferenceServiceGunDto,
} from "api/gen";
import { CREATED } from "common/messages/dashboardMessage";
import {
  PATH_INFO_GENERALE,
  PATH_INFO_GENERALE_ACTIVITE,
  PATH_INFO_GENERALE_ADMINISTRATIF,
  PATH_INFO_GENERALE_DECLARANT,
} from "common/path/path18Now";
import {
  useDeclaration24Now,
  useDeclarationHelpers24Now,
} from "../../toNow/versionedElements/declarationHooks24Now";
import GlobalFormActionFullContext24Now from "../../toNow/versionedElements/GlobalFormActionFullContext24Now";
import { computeCoordonneesDepartementMapFromArray } from "./utils/systemCoordinatesUtils";
import {
  computeEquipesGunMap,
  computeServiceInspectionList,
} from "./utils/converter";
import { PATH_INFO_GENERALE_DOCUMENTS } from "common/path/path21Now";
import BlocDocuments from "./BlocDocuments";
import NotificationGun from "./NotificationGun";

interface GeneralInformationsControllerProps {
  referentielGerepNatureServices: ReferenceNatureServiceDto;
  referentielServicesGun: ReferenceServiceGunDto;
  referentielEquipesGun: ReferenceEquipeGunDto;
  referentielAPE: ReferenceCodeApeDto;
  referentielINSEE: ReferenceGeoCodeInseeDto;
  referentielPays: ReferencePaysDto;
  referentielEPRTR: ReferenceEprtrDto;
  eprtrElevageList: ReferenceItemEprtrDto[];
  coordonneesDepartementList: ReferenceItemCoordonneesDepartementDto[];
}

export const GeneralInformationsController = ({
  referentielGerepNatureServices: { organismes },
  referentielEquipesGun: { equipeGunList },
  referentielServicesGun: { serviceGunDtoList },
  referentielAPE: { codesApe },
  referentielINSEE: { referenceItemGeoCodeInseeDtoList },
  referentielPays: { referenceItemPaysDtoList },
  referentielEPRTR: { referenceItemEprtrDtoList },
  eprtrElevageList,
  coordonneesDepartementList,
}: GeneralInformationsControllerProps): React.ReactElement => {
  const declaration = useDeclaration24Now();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers24Now();
  const declarationState: DeclarationStateDtoStateEnum =
    declaration.body.workflowStatus.general.state;

  //we set the refs and validation state here so it's all available for whole page validation and save
  const declarantIdentityProps = useFormikBloc<Declarants>();
  const companyIdentityProps = useFormikBloc<AdministratifType>();
  const companyActivityProps = useFormikBloc<ActivityType>();

  const memoInspectionServiceList: InspectionServiceDto[] = useMemo(
    () => computeServiceInspectionList(serviceGunDtoList, organismes),
    [serviceGunDtoList, organismes]
  );

  const memoReferentielEquipeInspection: Map<
    string,
    EquipeGunItemDto
  > = useMemo(() => computeEquipesGunMap(equipeGunList), [equipeGunList]);

  const memoReferentielAPE: ReferenceItemCodeApeDto[] = useMemo(
    () => codesApe.filter(code => code.disponible),
    [codesApe]
  );
  const memoReferentielPays: ReferenceItemPaysDto[] = useMemo(
    () => [franceOption, ...referenceItemPaysDtoList],
    [referenceItemPaysDtoList]
  );
  const memoCoordonneesDepartementMap: CoordonneesDepartementMap = useMemo(
    () => computeCoordonneesDepartementMapFromArray(coordonneesDepartementList),
    [coordonneesDepartementList]
  );
  const memoReferentielEPRTR: ReferenceItemEprtrDto[] = useMemo(
    () => referenceItemEprtrDtoList.filter(value => value.estSelectionnable),
    [referenceItemEprtrDtoList]
  );

  const initialValues = useMemo(() => {
    return {
      declarants: computeDeclarantInitialValues(declaration),
      companyIdentity: computeAdminInitialValues(
        declaration,
        memoReferentielPays,
        referenceItemGeoCodeInseeDtoList
      ),
      companyActivity: computeActiviteInitialValues(
        declaration,
        serviceGunDtoList,
        referenceItemGeoCodeInseeDtoList,
        memoReferentielAPE,
        memoCoordonneesDepartementMap
      ),
    };
  }, [
    declaration,
    memoReferentielPays,
    referenceItemGeoCodeInseeDtoList,
    serviceGunDtoList,
    memoReferentielAPE,
    memoCoordonneesDepartementMap,
  ]);

  const hasChanges =
    declarantIdentityProps.hasChanges ||
    companyIdentityProps.hasChanges ||
    companyActivityProps.hasChanges;
  const allBlocksValidated =
    declarationState !== CREATED &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_DECLARANT) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ADMINISTRATIF) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_ACTIVITE) &&
    isPathValidatedInDeclaration(PATH_INFO_GENERALE_DOCUMENTS);

  return (
    <>
      <NotificationGun />
      <DeclarantIdentityList
        formikRef={declarantIdentityProps.formikRef}
        hasChanges={declarantIdentityProps.hasChanges}
        setHasChanges={declarantIdentityProps.setHasChanges}
        initialValues={initialValues.declarants}
        path={PATH_INFO_GENERALE_DECLARANT}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.declarants = values.declarants;

          return declaration;
        }}
      />
      <Row />

      <CompanyIdentity
        formikRef={companyIdentityProps.formikRef}
        hasChanges={companyIdentityProps.hasChanges}
        setHasChanges={companyIdentityProps.setHasChanges}
        initialValues={initialValues.companyIdentity}
        path={PATH_INFO_GENERALE_ADMINISTRATIF}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
            values
          );
          return declaration;
        }}
        referentielINSEE={referenceItemGeoCodeInseeDtoList}
        referentielPays={memoReferentielPays}
      />
      <Row />

      <CompanyActivity
        formikRef={companyActivityProps.formikRef}
        hasChanges={companyActivityProps.hasChanges}
        setHasChanges={companyActivityProps.setHasChanges}
        initialValues={initialValues.companyActivity}
        path={PATH_INFO_GENERALE_ACTIVITE}
        updateHandler={(declaration, values) => {
          declaration.body.sections.infoGenerale.activite = createActivityDto(
            values
          );
          return declaration;
        }}
        referentielEquipesGun={memoReferentielEquipeInspection}
        inspectionServiceList={memoInspectionServiceList}
        referentielINSEE={referenceItemGeoCodeInseeDtoList}
        referentielAPE={memoReferentielAPE}
        referentielEPRTR={memoReferentielEPRTR}
        eprtrElevageList={eprtrElevageList}
        coordonneesDepartementMap={memoCoordonneesDepartementMap}
        coordonneesDepartementList={coordonneesDepartementList}
      />
      <Row />

      <BlocDocuments />
      <Row />

      <GlobalFormActionFullContext24Now
        validationTitle="VALIDER PAGE >"
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={hasChanges}
        isValidateEnabled={allBlocksValidated}
        validationPath={PATH_INFO_GENERALE}
        updateHandler={declaration => {
          if (declarantIdentityProps.formikRef?.current) {
            declaration.body.sections.infoGenerale.declarants =
              declarantIdentityProps.formikRef.current.state.values.declarants;
          }

          if (companyIdentityProps.formikRef?.current) {
            declaration.body.sections.infoGenerale.administratif = createAdministratifDto(
              companyIdentityProps.formikRef.current.state.values
            );
          }

          if (companyActivityProps.formikRef?.current) {
            declaration.body.sections.infoGenerale.activite = createActivityDto(
              companyActivityProps.formikRef.current.state.values
            );
          }

          return declaration;
        }}
        cancelAction={() => {
          declarantIdentityProps.formikRef?.current?.resetForm(
            initialValues.declarants
          );

          companyIdentityProps.formikRef?.current?.resetForm(
            initialValues.companyIdentity
          );

          companyActivityProps.formikRef?.current?.resetForm(
            initialValues.companyActivity
          );
        }}
      />
    </>
  );
};
