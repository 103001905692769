// it's important this file has the quotas in name as it's a page that is in router
// eslint-disable-next-line
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { RouteIdProps } from "common/declarant/type";
import Row from "common/presentational/Row";
import { ReferentialFetcher } from "common/ReferentialFetcher";
import {
  PageHeaderProps,
  usePageHeaderSetterOnce,
  usePathToDeclarationDashboard,
} from "pages/CommonSpace/AppPage/PageHeaderContext";
import { PINK } from "theme";
import { useCurrentDeclarationCampaign } from "../../../../../CommonSpace/CampaignContext";
import logoEmissions from "icons/pageLogos/smog.svg";
import EmissionsForm from "./EmissionsForm";
import { generateEndedCampaignMessage } from "common/messages/campaignMessages";
import { WorkflowTarget } from "common/utils/types";
import InReviewCommentDisplayer from "common/presentational/InReviewCommentDisplayer";
import {
  useDeclaration2020,
  useQuotaEmissionsState2020,
} from "../../versionedElements/declarationHooks2020";
import { wrapWithWorkflowTargetProvider } from "../../../../WorkflowTargetProviderContext";

const useStyles = makeStyles({
  color: {
    color: PINK,
  },
  informationDeclaration: {
    padding: "5px",
    backgroundColor: PINK,
    display: "inline-block",
    borderRadius: "5px",
    color: "white",
  },
});

const QuotasEmissions = ({ match }: RouteIdProps): React.ReactElement => {
  const classes = useStyles();

  const declarationAnnee = match.params.annee;
  const pagePath = [
    ...usePathToDeclarationDashboard(
      declarationAnnee,
      match.params.etablissement
    ),
    {
      label: "Emissions",
      to: match.url,
    },
  ];
  const headerData: PageHeaderProps = {
    arianeString: pagePath,
    title: "Emissions",
    image: {
      src: logoEmissions,
      alt: "",
    },
  };
  usePageHeaderSetterOnce(headerData);

  const quotasState = useQuotaEmissionsState2020();
  const declaration = useDeclaration2020();
  const currentCampaign = useCurrentDeclarationCampaign();

  const comment = declaration.body.workflowStatus.quotaEmissions.message;

  const declarationString = generateEndedCampaignMessage(
    declarationAnnee,
    currentCampaign.stateEmissions,
    WorkflowTarget.QUOTA_EMISSIONS
  );

  return (
    <>
      {!!declarationString && <h4>{declarationString}</h4>}
      <Row />
      <span>
        <span className={classes.color}>Statut de quotas : </span>
        <p className={classes.informationDeclaration}>
          {quotasState.stateMessage}
        </p>
      </span>

      <Row height={"20px"} />
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.QUOTA_EMISSIONS}
        isInReviewState={quotasState.isDeclarationStateContaining([
          "IN_REVIEW",
        ])}
        comment={comment}
      />

      <ReferentialFetcher
        wantedKeys={["REFERENCE_ACTIVITES_QUOTAS"]}
        render={referentials => {
          return (
            <EmissionsForm
              referentialActivites={referentials.REFERENCE_ACTIVITES_QUOTAS}
            />
          );
        }}
      />
    </>
  );
};

export default wrapWithWorkflowTargetProvider(
  QuotasEmissions,
  "QUOTA_EMISSIONS"
);
