import React from "react";
import MessageInfoField from "common/form/MessageInfoField";

export const generateWarningMessageIfUnder1Percent = (
  value: number | null
): React.ReactElement | null => {
  if (value != null && value !== 0 && value < 1) {
    return (
      <MessageInfoField
        message={
          "Attention, ce champ est à déclarer directement en %, la valeur que vous avez indiquée est donc égale à " +
          value +
          "%."
        }
      />
    );
  } else {
    return null;
  }
};
