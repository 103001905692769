import {
  codePostalMessage,
  requiredMessage,
  sirenLengthMessage,
  wrongCommuneMessage,
} from "common/declarant/formik/formikMessages";
import {
  computeRequiredStringErrorMessage,
  filterOutNullishValues,
} from "common/declarant/formik/formikValidationHelper";
import { Errors } from "common/form/utils";
import { isCountryFrance } from "../utils/mainSubmitHandler";
import { AdministrationValues } from "../utils/types";

export const companyIdentityValidation = (
  values: AdministrationValues
): Errors<AdministrationValues> => {
  const errors: Errors<AdministrationValues> = {};

  errors.socialReason = computeRequiredStringErrorMessage(values.socialReason);
  errors.legalStatus = computeRequiredStringErrorMessage(values.legalStatus);
  errors.SIRENNumber = validateSiren(values.SIRENNumber);
  errors.adress = computeRequiredStringErrorMessage(values.adress);
  errors.postalCode = computeRequiredStringErrorMessage(values.postalCode);

  if (values.country === null) {
    errors.country = requiredMessage;
  }

  if (values.city === null) {
    errors.city = requiredMessage;
  }
  if (values.country && isCountryFrance(values.country.designation)) {
    if (values.postalCode && !/^[0-9]{5}$/.test(values.postalCode)) {
      errors.postalCode = codePostalMessage;
    }
    // the string type means the city isn't in france, cities in france are supposed to have an uuid.
    if (typeof values.city === "string") {
      errors.city = wrongCommuneMessage;
    }
  }

  return filterOutNullishValues(errors) as Errors<AdministrationValues>;
};

const validateSiren = (siren: string | null): string | undefined => {
  if (siren !== null && !/^[0-9]{9}$/.test(siren.trim())) {
    return sirenLengthMessage;
  }
};
