import React from "react";
import WasteSumUpArray from "./WasteSumUpArray";
import { WasteBasicStorage, WasteSumUpWrapperProps } from "./types";
import { findElementMatchingTemplate } from "common/utils/methods";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import BigNumber from "bignumber.js";

const WasteSumUpWrapper = ({
  wasteData,
  isReceptionAvailable,
}: WasteSumUpWrapperProps): React.ReactElement => {
  const dangerous: WasteBasicStorage = {
    made: new BigNumber(0),
    received: new BigNumber(0),
    treated: new BigNumber(0),
    send: new BigNumber(0),
  };
  const normal: WasteBasicStorage = {
    made: new BigNumber(0),
    received: new BigNumber(0),
    treated: new BigNumber(0),
    send: new BigNumber(0),
  };

  wasteData.tempData.production.container.forEach(waste => {
    const wasteOption = waste.data.codeID
      ? findElementMatchingTemplate(
          { uuid: waste.data.codeID },
          wasteData.referentiels.polluants
        )
      : null;
    let container: WasteBasicStorage;
    if (wasteOption && wasteOption.dangereux) {
      container = dangerous;
    } else if (wasteOption) {
      container = normal;
    } else {
      return;
    }

    if (waste.data.quantite !== null) {
      container.made = container.made.plus(new BigNumber(waste.data.quantite));
    }

    if (
      (waste.data.departementID || waste.data.paysID) &&
      waste.data.quantite !== null
    ) {
      container.send = container.send.plus(new BigNumber(waste.data.quantite));
    }
  });

  wasteData.tempData.reception.container.forEach(waste => {
    const wasteOption = waste.data.codeID
      ? findElementMatchingTemplate(
          { uuid: waste.data.codeID },
          wasteData.referentiels.polluants
        )
      : null;
    let container: WasteBasicStorage;
    if (wasteOption && wasteOption.dangereux) {
      container = dangerous;
    } else if (wasteOption) {
      container = normal;
    } else {
      return;
    }

    if (waste.data.quantiteAdmise !== null) {
      container.received = container.received.plus(
        new BigNumber(waste.data.quantiteAdmise)
      );
    }

    if (waste.data.quantiteTraitee !== null) {
      container.treated = container.treated.plus(
        new BigNumber(waste.data.quantiteTraitee)
      );
    }
  });

  return (
    <DummyUnActionedBloc
      title={"Bilan des mouvements de déchets"}
      renderContent={() => {
        return (
          <WasteSumUpArray
            dangerous={dangerous}
            normal={normal}
            isReceptionAvailable={isReceptionAvailable}
          />
        );
      }}
    />
  );
};

export default WasteSumUpWrapper;
