import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocSolProps {
  disabled: boolean;
}

export const BlocSol = ({ disabled }: BlocSolProps) => {
  const { referenceSolController } = useAuthenticatedApi();
  const classes = useStyles();

  const uploadHandler = useCallback(
    (file: File) => {
      return referenceSolController.uploadNormesSolUsingPOST(file);
    },
    [referenceSolController]
  );

  const downloadHandler = useCallback(() => {
    return referenceSolController.downloadNormesSolAsCsvUsingGET();
  }, [referenceSolController]);

  return (
    <BlocReferentiel
      title="Normes Sol"
      additionalContent={
        disabled ? (
          <>
            <div className={classes.disabledText}>
              Aucune campagne future à l'état prête n'a été trouvée. Vous ne
              pouvez pas modifier les référentiels d'une campagne en cours.
            </div>
            <Row />
          </>
        ) : (
          undefined
        )
      }
      downloadHandler={downloadHandler}
      filename="normes_sol.csv"
      uploadHandler={uploadHandler}
      disabled={disabled}
    />
  );
};

export default BlocSol;
