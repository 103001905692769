import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  FIELD_FONT_SIZE,
  FIELD_FONT_WEIGHT,
  FILE_BUTTONS_STYLE,
  SECTION_TITLE_GREY,
} from "theme";
import {
  BlocPlanDeSurveillanceFormValues,
  planSurveillanceFieldMatcher,
} from "./types";
import { computeInitialValues, updateHandler } from "./converter";
import { PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC } from "common/path/path20Now";
import _ from "lodash";
import GreyFilesDownloader from "common/FilesDownloader/GreyFilesDownloader";
import { useEmissionValidationMessage } from "../../../../toNow/Quotas/utils/utils";
import { useDeclaration2121 } from "../../../versionedElements/declarationHooks2121";
import FormikBlocFullContext2121 from "../../../versionedElements/FormikBlocFullContext2121";
import NimFilesLoaderDisplayer from "../../../../toNow/Quotas/common/FilesRelatedToNim/NimFilesLoaderDisplayer";
import PdsLoader from "./PdsLoader";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import Row from "common/presentational/Row";
import InfoBulle from "common/infoBulle/InfoBulle";
import InfosComplementairesForm from "./InfosComplementairesInstallations/InfosComplementairesForm";
import FluxDerogationForm from "./FluxDerogationInstallations/FluxDerogationForm";
import classNames from "classnames";
import JustificatifPdsLoader from "../../../../toNow/Quotas/Emissions/BlocPlanSurveillance/JustificatifPdsLoader";

const useStyles = makeStyles({
  greyColor: {
    color: SECTION_TITLE_GREY,
  },
  listPosition: {
    "&>ul": {
      marginTop: "1em",
      marginLeft: "4em",
      marginBottom: "1em",
    },
  },
  ...FILE_BUTTONS_STYLE,
  label: {
    color: SECTION_TITLE_GREY,
    fontSize: FIELD_FONT_SIZE,
    fontWeight: FIELD_FONT_WEIGHT,
    textAlign: "center",
    alignSelf: "center",
    width: "25%",
  },
  additionalRowStyle: {
    justifyContent: "space-around",
  },
});

interface BlocPlanDeSurveillanceProps {
  blocPlanDeSurveillanceFormProps: FormikBlocRefAndState<
    BlocPlanDeSurveillanceFormValues
  >;
}

const BlocPlanSurveillance = ({
  blocPlanDeSurveillanceFormProps,
}: BlocPlanDeSurveillanceProps): React.ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration2121();
  const validationMessage = useEmissionValidationMessage();

  const installations = declaration.body.typeActivite.quotas.installations;

  return (
    <FormikBlocFullContext2121
      formikRef={blocPlanDeSurveillanceFormProps.formikRef}
      hasChanges={blocPlanDeSurveillanceFormProps.hasChanges}
      setHasChanges={blocPlanDeSurveillanceFormProps.setHasChanges}
      initialValues={computeInitialValues(
        declaration.body.sections.quotas.emissions.blocPlanSurveillance,
        installations
      )}
      title={"plan de surveillance des émissions"}
      editValidationMessage={validationMessage}
      pathToValidate={PATH_QUOTAS_PLAN_SURVEILLANCE_BLOC}
      areGlobalCommentsAllowed={true}
      updateHandler={(declaration, values) => {
        const updatedDeclaration = updateHandler(
          _.cloneDeep(declaration),
          values
        );
        return updatedDeclaration;
      }}
      declarationState={declaration.body.workflowStatus.quotaEmissions.state}
      renderContent={(props, shouldDisabledFields) => (
        <>
          <div className={classNames(classes.listPosition, classes.greyColor)}>
            <p>À faire dans ce bloc :</p>
            <ul>
              <li>
                Déposer le(s) plan(s) de surveillance en vigueur pour l'année de
                déclaration (1 par NIM),
              </li>
              <li>
                Renseigner les informations complémentaires sur les
                installations.
              </li>
            </ul>
          </div>

          <GreyFilesDownloader
            text="TÉLÉCHARGER LE MODÈLE PLAN DE SURVEILLANCE (version du 06/04/2021)"
            isDisabled={shouldDisabledFields}
            fileName="Plan_Surveillance_emissions_210406.xls"
            additionalClassname={classes.fileButtons}
          />

          {installations.map(installation => (
            <NimFilesLoaderDisplayer
              installation={installation}
              justificatifLoader
            >
              <PdsLoader
                installationId={installation.id}
                shouldDisableFields={shouldDisabledFields}
              />
              <JustificatifPdsLoader
                installationId={installation.id}
                shouldDisableFields={shouldDisabledFields}
              />
            </NimFilesLoaderDisplayer>
          ))}

          <Row height="20px" />

          <>
            {installations.length > 0 && (
              <>
                <h6 className={classes.greyColor}>
                  Informations complémentaires sur les installations
                </h6>
                <Row />
                <Row additionalClassname={classes.additionalRowStyle}>
                  <span className={classes.label}>NIM</span>
                  <span className={classes.label}>
                    <InfoBulle
                      content={
                        "Sélectionner ici la catégorie renseignée dans le plan de surveillance des émissions. Pour rappel, la catégorie est définie conformément à l'article 19 du règlement MRR (2018/2066). Les émissions annuelles moyennes vérifiées permettant la définition de la catégorie sont celles avant déduction du CO2 transféré et ne tiennent pas compte du CO2 issu de la biomasse."
                      }
                    />
                    Catégorie de l'installation
                  </span>
                  <span className={classes.label}>
                    <InfoBulle
                      content={
                        "Emissions inférieures ou égales à 25 000 tCO2/an."
                      }
                    />
                    Installation faiblement émettrice
                  </span>
                </Row>
                <FieldArray
                  name={""}
                  render={() => (
                    <div>
                      {installations.map((installation, index) => {
                        return (
                          <>
                            <Row />
                            <InfosComplementairesForm
                              installation={installation}
                              index={index}
                              namePrefix={`${planSurveillanceFieldMatcher.infosComplementairesInstallations}.${index}`}
                              infosInstallationList={
                                props.values.infosComplementairesInstallations
                              }
                              shouldDisableFields={shouldDisabledFields}
                            />

                            <Row />
                            <FluxDerogationForm
                              installation={installation}
                              index={index}
                              namePrefix={`${planSurveillanceFieldMatcher.fluxDerogationInstallations}.${index}`}
                              fluxDerogationList={
                                props.values.fluxDerogationInstallations
                              }
                              shouldDisableFields={shouldDisabledFields}
                            />
                          </>
                        );
                      })}
                    </div>
                  )}
                />
              </>
            )}
          </>
        </>
      )}
    />
  );
};

export default BlocPlanSurveillance;
