import {
  FileDto,
  QuotasAllocationsVerificationDto20Now,
  QuotasEmissionsVerificationDto20Now,
} from "api/gen";

export type VerificationFileDto =
  | QuotasAllocationsVerificationDto20Now
  | QuotasEmissionsVerificationDto20Now;

export const verificationsDtoToFileDto = (
  nimDynId: string,
  verifications: VerificationFileDto[]
): FileDto[] => {
  const files: FileDto[] = [];
  verifications
    .filter(verif => verif.nimDynId === nimDynId)
    .forEach(verif => {
      if (verif.rapport) {
        files.push(verif.rapport);
      }
    });
  return files;
};
