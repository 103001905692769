import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { useComputeFirstLineContentCell } from "common/utils/computeWrappedCell";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { filterByInstallation } from "../../../utils/utils";
import { EmissionsInArray, MesureEmissionInArray } from "../utils/types";
import { ERROR } from "theme";
import { ReferentialSinglePolluants } from "../../../utils/types";
import {
  computeCo2EmissionLine,
  computeSingleEmissionLine,
} from "./arrayUtils";
import { isCo2 } from "../../../../../../../from20/toNow/Air/combustionProcedeUtils/biomasse/helpers";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});
interface EmissionArrayProps {
  mesureEmissionsInArray: MesureEmissionInArray[];
  setMesureEmissionsInArray: Dispatch<SetStateAction<MesureEmissionInArray[]>>;
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>;
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  installationName?: string | null;
  validationMesuresPath: string;
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const EmissionMesureArray = ({
  mesureEmissionsInArray,
  setMesureEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  isValidated,
  installationName,
  validationMesuresPath,
  referentialSinglePolluants,
}: EmissionArrayProps): React.ReactElement => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  const arraySubstancesLines = filterByInstallation(
    mesureEmissionsInArray,
    installationName || ""
  ).reduce(
    (arrayLines: React.ReactElement[][], mesure: MesureEmissionInArray) => {
      const isEmissionCo2 = isCo2(
        mesure.data.substance,
        referentialSinglePolluants.CO2
      );

      if (isEmissionCo2) {
        arrayLines.push(
          ...computeCo2EmissionLine(
            mesure,
            classes,
            setEmissionInModale,
            setEmissionModaleOpen,
            setMesureEmissionsInArray,
            isValidated,
            validationMesuresPath,
            computeFirstLineContentCell
          )
        );
        return arrayLines;
      }
      arrayLines.push(
        computeSingleEmissionLine(
          mesure,
          classes,
          setEmissionInModale,
          setEmissionModaleOpen,
          setMesureEmissionsInArray,
          isValidated,
          validationMesuresPath,
          computeFirstLineContentCell
        )
      );

      return arrayLines;
    },
    []
  );

  return (
    <CommonEntityTable
      header={[
        <p>Substance</p>,
        <p>Appareil(s) émetteur(s)</p>,
        <p>Type d'émission mesurée</p>,
        <p>Nombre d'heures de fonctionnement (h/an)</p>,
        <p>Débit horaire moyen des effluents (Nm3/h)</p>,
        <p>Mesure en continu du débit</p>,
        <p>Fréquence de la mesure du débit (nb/an)</p>,
        <p>Concentration moyenne de polluant après traitement</p>,
        <p>Unité de la concentration moyenne de polluant après traitement</p>,
        <p>Mesure en continu de la concentration</p>,
        <p>Fréquence de la mesure de concentration (nb/an)</p>,
        <p>Les émissions font-elles l'objet d'épuration?</p>,
        <p>Nature des équipements</p>,
        <p>Rendement d'épuration (%)</p>,
        <p>Émissions annuelles (kg/an)</p>,
        <p>Actions</p>,
      ]}
      lines={arraySubstancesLines}
      preferredColWidths={[
        230,
        200,
        200,
        200,
        200,
        130,
        200,
        210,
        160,
        130,
        200,
        130,
        200,
        130,
        130,
        90,
      ]}
    />
  );
};

export default EmissionMesureArray;
