import React from "react";
import { makeStyles } from "@material-ui/styles";
import { LEFT_WITHDRAW_STYLE, SUBTITLE_STYLE } from "theme";
import {
  useBooleanCheckBoxGenerator,
  useNumberFieldGenerator,
  useTextAreaGenerator,
} from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import { useFormikBloc } from "common/formikBloc/utils";
import {
  PATH_SPFO,
  PATH_SPFO_ACTIVITE_TRAITEMENT_SURFACE,
  PATH_SPFO_ELIMIEE,
  PATH_SPFO_NATURE_PROCEDE_TRAITEMENT_SURFACE,
  PATH_SPFO_STOCKEE,
  PATH_SPFO_UTILISEE,
} from "common/path/path18Now";
import FormikBlocFullContext19Now from "../versionedElements/FormikBlocFullContext19Now";
import { validationSchema } from "./utils/validation";
import { SpfoDto } from "api/gen";
import { spfoFieldMatcher } from "./utils/types";

const useStyles = makeStyles({
  subTitle: SUBTITLE_STYLE,
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  ...LEFT_WITHDRAW_STYLE,
});

interface FormSPFOProps {
  formSPFODto: SpfoDto;
  isValidated: boolean;
}

const FormSPFO = ({
  formSPFODto,
  isValidated,
}: FormSPFOProps): React.ReactElement => {
  const formSPFOProps = useFormikBloc<SpfoDto>();

  const classes = useStyles();

  const commonProps = {
    disabled: isValidated,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-spfo",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const CheckBox = useBooleanCheckBoxGenerator(commonProps);
  const TextArea = useTextAreaGenerator(commonProps);

  return (
    <FormikBlocFullContext19Now
      formikRef={formSPFOProps.formikRef}
      hasChanges={formSPFOProps.hasChanges}
      setHasChanges={formSPFOProps.setHasChanges}
      initialValues={formSPFODto}
      validationSchema={validationSchema}
      title={"Récapitulatif"}
      pathToValidate={PATH_SPFO}
      isSingle={true}
      updateHandler={(declaration, values) => {
        declaration.body.sections.spfo = values;
        return declaration;
      }}
      renderContent={(props, shouldDisabledFields) => {
        return (
          <>
            <NumberField
              label={"Quantité de SPFO stockée sur le site *"}
              name={spfoFieldMatcher.quantityStored}
              unit={"kg/an"}
              disabled={shouldDisabledFields}
              commentPath={PATH_SPFO_STOCKEE}
            />

            <NumberField
              name={spfoFieldMatcher.quantityUsed}
              label={"Quantité de SPFO utilisée sur le site *"}
              unit={"kg/an"}
              disabled={shouldDisabledFields}
              commentPath={PATH_SPFO_UTILISEE}
            />

            <NumberField
              name={spfoFieldMatcher.quantityWasted}
              label={"Quantité de SPFO éliminée *"}
              unit={"kg/an"}
              disabled={shouldDisabledFields}
              commentPath={PATH_SPFO_ELIMIEE}
            />

            <Row />
            <Row>
              <h3 className={classes.subTitle}>
                Activité de traitement de surface
              </h3>
            </Row>

            <CheckBox
              name={spfoFieldMatcher.isSurfaceActivity}
              label={"Activité de traitement de surface"}
              disabled={shouldDisabledFields}
              tooltipContent={
                <span>
                  Traitements anti-buée pour le chromage dur [VI] non décoratif
                  et les agents tensioactifs utilisés dans des systèmes
                  contrôlés de dépôt électrolytique
                </span>
              }
              commentPath={PATH_SPFO_ACTIVITE_TRAITEMENT_SURFACE}
            />

            {props.values.activiteTraitementSurface && (
              <div className={classes.withdrawLeft}>
                <TextArea
                  name={spfoFieldMatcher.surfaceActivityDescription}
                  label={
                    "Nature des procédés utilisés en référence aux meilleures techniques disponibles *"
                  }
                  style={{
                    height: "50px",
                  }}
                  disabled={shouldDisabledFields}
                  commentPath={PATH_SPFO_NATURE_PROCEDE_TRAITEMENT_SURFACE}
                />
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default FormSPFO;
