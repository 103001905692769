import { makeStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";

interface SpaceChildProps {
  spacing?: string;
  children: ReactNode | ReactNode[];
}

const useStyles = makeStyles({
  infobulleSpacing: props => ({
    "&>*": {
      marginBottom: props.spacing,
    },
    "&>*:last-child": {
      marginBottom: 0,
    },
  }),
});

/**
 * for spacing the child given in a horizontal way with the given spacing (or 5px if no spacing was given)
 * @param spacing the spacing between each element, set to 5px if props not given
 * @param children the children that are to be displayed with a bit of space between each
 * @constructor
 */
const SpaceChild = ({ spacing = "5px", children }: SpaceChildProps) => {
  const classes = useStyles({ spacing });

  return <div className={classes.infobulleSpacing}>{children}</div>;
};

export default SpaceChild;
