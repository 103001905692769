import React from "react";
import { LocalDateInputFieldProps } from "../types/connectedTypes";
import { computeErrorObject } from "./utils";
import DummyDateInput from "../dumbInput/DummyDateInput";
import toLocalDateString from "common/utils/ToLocalDateString";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const LocalDateInput = ({
  field, // { name, value, onChange, onBlur }
  form,
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: LocalDateInputFieldProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });
  const baseValue = externalValue !== undefined ? externalValue : field.value;
  const fieldValue = baseValue ? new Date(Date.parse(baseValue)) : null;

  useExternalFieldNameEffect(field.name, externalValue);

  return (
    <DummyDateInput
      {...field}
      {...props}
      value={fieldValue}
      disabled={externalValue !== undefined ? true : disabled}
      error={error}
      onChange={newValue => {
        const newFieldValue = newValue ? toLocalDateString(newValue) : null;
        form.setFieldValue(field.name, newFieldValue);
        form.setFieldTouched(field.name);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newFieldValue);
        }
      }}
    />
  );
};

export default LocalDateInput;
