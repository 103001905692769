import React, { useState } from "react";
import Row from "common/presentational/Row";
import Button from "common/button";
import ProcedeArray from "./ProcedesArray";
import {
  CorrelationEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../emissions/utils/types";
import { CovInArray } from "../covDanger/utils/types";
import ProcedesModale from "./ProcedesModale";
import { createAirFugitivesProcedeDto } from "./utils/utils";
import { ReferenceItemPolluantDto } from "api/gen";
import { createAirFugitivesCovDto } from "../covDanger/utils/utils";
import {
  createAirFugitivesEmissionCorrelationDto,
  createAirFugitivesEmissionMatiereDto,
  createAirFugitivesEmissionMesureDto,
} from "../emissions/utils/utils";
import isEqual from "lodash.isequal";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { LegacyProcedeAndSubstance } from "../utils/types";
import { ProcedeInArray } from "./utils/types";
import { InArray } from "common/form/utils";
import { Omit } from "react-router";
import BlocFullContext1919 from "../../../versionedElements/BlocFullContext1919";
import { Declaration1919 } from "../../../versionedElements/declarationHooks1919";

interface ListProcedeProps {
  initialProcedes: ProcedeInArray[];
  covsInPage: CovInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  validationProcedePath: string;
  procedesInPage: ProcedeInArray[];
  setProcedesInPage: React.Dispatch<React.SetStateAction<ProcedeInArray[]>>;
  setMesureEmissionsInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  setMatiereEmissionsInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  setCorrelationEmissionsInPage: React.Dispatch<
    React.SetStateAction<CorrelationEmissionInArray[]>
  >;
  setCovsInPage: React.Dispatch<React.SetStateAction<CovInArray[]>>;
  substances: OptionPropsWithObject<ReferenceItemPolluantDto>[];
}
const ListProcede = ({
  initialProcedes,
  covsInPage,
  mesureEmissionsInPage,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  validationProcedePath,
  procedesInPage,
  setProcedesInPage,
  setMesureEmissionsInPage,
  setMatiereEmissionsInPage,
  setCorrelationEmissionsInPage,
  setCovsInPage,
  substances,
}: ListProcedeProps) => {
  const [procedeModaleOpen, setProcedeModaleOpen] = useState(false);
  // note the procedeInModale is of type ProcedeInArray because we need its id, provided by inArray but NOT by inModale. It should be refactorised most likely.
  const [procedeInModale, setProcedeInModale] = useState<ProcedeInArray | null>(
    null
  );

  function modifyBasedOnProcede<
    T extends Omit<LegacyProcedeAndSubstance, "substance">
  >(content: InArray<T>[]) {
    // TODO Maybe https://github.com/Polyconseil/mtes-gerep/pull/166#discussion_r318641306
    return content.filter((singleElderValue: InArray<T>) => {
      // Example avec setCovsInPage
      // let exists: boolean[] = [];
      if (singleElderValue.data.procede) {
        for (let i = 0; singleElderValue.data.procede[i]; i++) {
          // Pour chaque procede de COV
          let exists = false;
          const singleProcede = singleElderValue.data.procede[i];
          if (singleElderValue.data.procede[i].object) {
            procedesInPage.forEach(procede => {
              // Pour chaque procede dans le tableau de procede
              if (
                procede.data.id === singleProcede.object.data.id // Si ils sont egaux
              ) {
                singleProcede.label = procede.data.name // Je modifie le procede de COV
                  ? procede.data.name
                  : "none";
                singleProcede.value = procede.data.name
                  ? procede.data.name
                  : "none";
                singleProcede.object = procede; // Je modifie le procede de COV
                exists = true; // Je dis que ce procede de COV existe bien dans le tableau de procede
              }
            });
          }
          if (!exists) {
            // Si il n'existe pas dans le tableau de procede
            singleElderValue.data.procede.splice(i, 1); // Je supprime le procede de COV
            i--;
          }
        }
        return singleElderValue && singleElderValue.data.procede[0]; // Je retourne le COV uniquement si il a toujours au moins un procede.
      }
      return singleElderValue;
    });
  }

  const updateHandler = (declaration: Declaration1919) => {
    declaration.body.sections.air.fugitives.procedes = createAirFugitivesProcedeDto(
      procedesInPage
    );
    const flux = modifyBasedOnProcede(covsInPage);
    declaration.body.sections.air.fugitives.flux = createAirFugitivesCovDto(
      flux
    );
    setCovsInPage(flux);
    const correlation = modifyBasedOnProcede(correlationEmissionsInPage);
    declaration.body.sections.air.fugitives.emission.correlation = createAirFugitivesEmissionCorrelationDto(
      correlation,
      substances
    );
    setCorrelationEmissionsInPage(correlation);
    const mesure = modifyBasedOnProcede(mesureEmissionsInPage);
    declaration.body.sections.air.fugitives.emission.mesure = createAirFugitivesEmissionMesureDto(
      mesure,
      substances
    );
    setMesureEmissionsInPage(mesure);
    const matiere = modifyBasedOnProcede(matiereEmissionsInPage);
    declaration.body.sections.air.fugitives.emission.bilan = createAirFugitivesEmissionMatiereDto(
      matiere,
      substances
    );
    setMatiereEmissionsInPage(matiere);
    return declaration;
  };

  const compareFunction = (a: ProcedeInArray, b: ProcedeInArray): number => {
    if (a.data.name && b.data.name && a.data.name !== b.data.name) {
      return a.data.name < b.data.name ? -1 : 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      <BlocFullContext1919
        title="déclaration des procédés"
        hasModification={!isEqual(initialProcedes, procedesInPage)}
        isValidateButtonAvailable={true}
        path={validationProcedePath}
        updateHandler={declaration => updateHandler(declaration)}
        cancelAction={() => setProcedesInPage(initialProcedes)}
        renderContent={shouldDisabledFields => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UN PROCÉDÉ"
                  onClick={() => {
                    setProcedeModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <ProcedeArray
                validationProcedePath={validationProcedePath}
                procedesInPage={procedesInPage.sort(compareFunction)}
                setProcedesInPage={setProcedesInPage}
                setProcedeInModale={setProcedeInModale}
                setProcedeModaleOpen={setProcedeModaleOpen}
                isValidated={shouldDisabledFields}
              />
              <Row />
            </>
          );
        }}
      />
      <ProcedesModale
        procedeModaleOpen={procedeModaleOpen}
        procedeInModale={procedeInModale}
        setProcedesInPage={setProcedesInPage}
        setProcedeInModale={setProcedeInModale}
        setProcedeModaleOpen={setProcedeModaleOpen}
        procedesInPage={procedesInPage}
      />
    </>
  );
};

export default ListProcede;
