import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction, useState } from "react";
import { LARGE_BUTTON_WIDTH } from "theme";
import { cloneDeep } from "lodash";
import Row from "common/presentational/Row";
import Button from "common/button";
import AppareilArray from "./AppareilArray";
import AppareilModale from "./AppareilModale";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { useConfirmationModale } from "common/modale/hooks";
import { AppareilInArray } from "./utils/types";
import { CombustibleInArray } from "../BlocCombustibles/utils/types";
import {
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "../BlocEmissions/utils/types";
import { InstallationInArray } from "../../BlocInstallation/utils/types";

const useStyles = makeStyles({
  largeButton: {
    width: LARGE_BUTTON_WIDTH,
  },
});

interface BlocAppareilsProps {
  setAppareilsInPage: Dispatch<SetStateAction<AppareilInArray[]>>;
  appareilsInPage: AppareilInArray[];
  installation: InstallationInArray;
  shouldDisabledFields: boolean;
  validationContentPath: string;
  combustiblesInPage: CombustibleInArray[];
  setCombustiblesInPage: Dispatch<SetStateAction<CombustibleInArray[]>>;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
}

const BlocAppareils = ({
  setAppareilsInPage,
  appareilsInPage,
  installation,
  shouldDisabledFields,
  validationContentPath,
  combustiblesInPage,
  setCombustiblesInPage,
  facteursInPage,
  setFacteursInPage,
  matieresInPage,
  setMatieresInPage,
  mesuresInPage,
  setMesuresInPage,
}: BlocAppareilsProps): React.ReactElement => {
  const classes = useStyles();

  const openConfirmationModale = useConfirmationModale();
  const [appareilModaleOpen, setAppareilModaleOpen] = useState(false);
  const [
    appareilInModale,
    setAppareilInModale,
  ] = useState<AppareilInArray | null>(null);

  const compareFunction = (a: AppareilInArray, b: AppareilInArray): number => {
    if (
      a.data.nameInstallation &&
      b.data.nameInstallation &&
      a.data.nameInstallation !== b.data.nameInstallation
    ) {
      return a.data.nameInstallation < b.data.nameInstallation ? -1 : 1;
    } else {
      return 0;
    }
  };

  return (
    <>
      <DummyUnActionedBloc
        title={`Déclaration des appareils de l'installation: ${installation.data.nom}`}
        renderContent={() => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UN APPAREIL"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    setAppareilModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <AppareilArray
                appareilsInArray={cloneDeep(appareilsInPage).sort(
                  compareFunction
                )}
                setAppareilsInArray={setAppareilsInPage}
                setAppareilInModale={setAppareilInModale}
                setAppareilModaleOpen={setAppareilModaleOpen}
                isValidated={shouldDisabledFields}
                installationName={installation.data.nom}
                validationAppareilsPath={`${validationContentPath}/appareils`}
                combustiblesInPage={combustiblesInPage}
                setCombustiblesInPage={setCombustiblesInPage}
                facteursInPage={facteursInPage}
                setFacteursInPage={setFacteursInPage}
                matieresInPage={matieresInPage}
                setMatieresInPage={setMatieresInPage}
                mesuresInPage={mesuresInPage}
                setMesuresInPage={setMesuresInPage}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ? Cela supprimera également le contenu des tableaux combustibles et émissions.",
                      () => {
                        setAppareilsInPage([]);
                        setCombustiblesInPage([]);
                        setFacteursInPage([]);
                      }
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <AppareilModale
        appareilModaleOpen={appareilModaleOpen}
        appareilInModale={appareilInModale}
        appareilsInPage={appareilsInPage}
        setAppareilsInArray={setAppareilsInPage}
        setAppareilInModale={setAppareilInModale}
        setAppareilModaleOpen={setAppareilModaleOpen}
        installation={installation}
        combustiblesInPage={combustiblesInPage}
        setCombustiblesInPage={setCombustiblesInPage}
        facteursInPage={facteursInPage}
        setFacteursInPage={setFacteursInPage}
        matieresInPage={matieresInPage}
        setMatieresInPage={setMatieresInPage}
        mesuresInPage={mesuresInPage}
        setMesuresInPage={setMesuresInPage}
      />
    </>
  );
};

export default BlocAppareils;
