import React, { useState } from "react";
import Button from "common/button";
import { makeStyles } from "@material-ui/styles";
import {
  DASHBOARD_BACKGROUND_GREY,
  DEFAULT_BORDER_RADIUS,
  PINK,
  SHADOW,
} from "theme";
import { useActiveOrLastCampaign } from "pages/CommonSpace/CampaignContext";
import { Redirect } from "react-router-dom";
import { useDeclarationPageHeaderInfo } from "pages/CommonSpace/AppPage/DeclarationPageHeaderContext";
import { PATH_INFO_GENERALE } from "common/path/path18Now";

const useStyles = makeStyles({
  container: {
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0 0",
    display: "flex",
    top: 0,
    left: 0,
    width: "150px",
    height: "13px",
    position: "relative",
    float: "left",
    fontWeight: "bold",
    color: PINK,
  },
  shadow: {
    backgroundColor: DASHBOARD_BACKGROUND_GREY,
    borderRadius: DEFAULT_BORDER_RADIUS,
    padding: "15px",
    boxShadow: SHADOW,
    "&:hover": {
      "& span img": {
        filter: "brightness(0) invert(1)",
      },
      "& span p": {
        color: "white",
      },
    },
  },
});

export const CreateDeclarationButton = () => {
  const classes = useStyles();

  const campaign = useActiveOrLastCampaign();
  const headerInfo = useDeclarationPageHeaderInfo();
  const [redirectionLink, setRedirectionLink] = useState("/");
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const onClick = (): void => {
    if (headerInfo === null) {
      return;
    } else {
      const redirectionLink = `/declaration/${campaign.annee.toString()}/${
        headerInfo.declarationEtablissement
      }${PATH_INFO_GENERALE}`;
      setRedirectionLink(redirectionLink);
      setShouldRedirect(true);
    }
  };

  if (shouldRedirect) {
    return <Redirect to={redirectionLink} />;
  }

  return (
    <Button
      text={
        <span className={classes.container}>
          <p>Déclarer l'année en cours</p>
        </span>
      }
      type="button"
      additionalClassname={classes.shadow}
      onClick={onClick}
    />
  );
};
