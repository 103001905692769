import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useDummyNumberFieldGenerator,
  useNumberFieldGenerator,
} from "common/form/fields/helpers/generators";
import Row from "common/presentational/Row";
import { BlocPrelevementEauFormValues24Now } from "./types";
import { dtoToBlocValues } from "./converter";
import { validationSchema } from "./validation";
import {
  computeTotalWaterTaking,
  isDepassementDeSeuilEauPotableAndAdduction,
} from "./utils";
import {
  arraySeaWaterTakingLocationLabel,
  selectPossibleMethods,
} from "./selectPossibleValues";
import { updateHandlerPrelevementEau } from "../utils";
import { DISABLED_SECTION_STYLE } from "theme";
import { FormikBlocRefAndState } from "common/formikBloc/utils";
import { ReferenceSandreDto } from "api/gen";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import {
  PATH_EAU_PRELEVEMENT,
  PATH_EAU_PRELEVEMENT_JOURS,
  PATH_EAU_PRELEVEMENT_MER_OCEAN,
  PATH_EAU_PRELEVEMENT_SOUTERRAIN,
} from "common/path/path18Now";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import FormikBlocFullContext24Now from "../../versionedElements/FormikBlocFullContext24Now";
import { useDeclaration24Now } from "../../versionedElements/declarationHooks24Now";
import {
  PATH_EAU_PRELEVEMENT_ADDUCTION,
  PATH_EAU_PRELEVEMENT_POTABLE,
  PATH_EAU_PRELEVEMENT_SUPERFICIELLE,
} from "../../../../../../common/path/path23Now";
import jaugeLogo from "icons/jauge.svg";

const useStyles = makeStyles({
  inputField: {
    display: "flex",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginBottom: "5px",
    "@media(max-width: 900px)": {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  leftFieldContainer: {
    width: "60%",
    minWidth: "450px",
    "@media(max-width: 900px)": {
      width: "100%",
    },
  },
  rightFieldContainer: {
    minWidth: "250px",
    "@media(max-width: 900px)": {
      position: "relative",
      left: "50%",
      width: "50%",
    },
  },
  largeField: {
    width: "300px",
  },
  ...DISABLED_SECTION_STYLE,
  jaugeIcon: {
    width: "30px",
    marginBottom: "20px",
  },
  depassementDeSeuil: {
    fontWeight: "bold",
  },
  normal: {
    fontWeight: "normal",
  },
  text: {
    margin: "12px 0",
  },
});

interface BlocPrelevementEauProps
  extends FormikBlocRefAndState<BlocPrelevementEauFormValues24Now> {
  isDepassementSeuil: boolean;
  isDisabled: boolean;
  referentialSandres: ReferenceSandreDto;
}

const BlocPrelevementEau = ({
  hasChanges,
  setHasChanges,
  formikRef,
  isDepassementSeuil,
  isDisabled,
  referentialSandres,
}: BlocPrelevementEauProps): JSX.Element => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  const prelevementEnEau = declaration.computed.sections.eau.prelevementEnEau;

  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-eau-prelevement",
  };

  const NumberField = useNumberFieldGenerator(commonProps);
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);

  const initialValues = dtoToBlocValues(
    declaration.body.sections.eau.prelevement,
    referentialSandres
  );

  return (
    <>
      {isDisabled && (
        <div className={classes.disabledMessage}>
          <span>
            Veuillez valider INFORMATIONS COMPLÉMENTAIRES avant d'accéder à
            cette section
          </span>
        </div>
      )}
      <div className={isDisabled ? classes.disabledSection : ""}>
        <FormikBlocFullContext24Now
          formikRef={formikRef}
          hasChanges={hasChanges}
          setHasChanges={setHasChanges}
          initialValues={initialValues}
          validationSchema={validationSchema(isDepassementSeuil)}
          title="tableau des prélèvements en eau"
          pathToValidate={PATH_EAU_PRELEVEMENT}
          areGlobalCommentsAllowed={true}
          updateHandler={(declaration, values) =>
            updateHandlerPrelevementEau(declaration, values, isDepassementSeuil)
          }
          renderContent={(props, shouldDisabledFields) => (
            <>
              <div className={classes.text}>
                Les valeurs en gras dépassent les seuils de l'arrêté ministériel
                du 31/01/2008 relatif au registre et à la déclaration annuelle
                des émissions et de transferts de polluants et des déchets.
              </div>
              {isDepassementSeuil && (
                <>
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="nbJoursTravailles"
                        label="Nombre de jours travaillés *"
                        unit=""
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_JOURS}
                      />
                    </div>
                  </div>
                  <Row height="15px" />
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="volumeEauSuperficielle"
                        label="Eaux superficielles (hors mer et océan)"
                        unit={"m³/an"}
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_SUPERFICIELLE}
                        additionalClassNameInput={
                          props.values.volumeEauSuperficielle != null &&
                          prelevementEnEau.seuilEnKgAnMilieuNaturel != null &&
                          props.values.volumeEauSuperficielle >
                            prelevementEnEau.seuilEnKgAnMilieuNaturel
                            ? classes.depassementDeSeuil
                            : classes.normal
                        }
                      />
                    </div>
                    <div className={classes.rightFieldContainer}>
                      <WrappedChoiceSelectModale
                        name="lieuEauSurface"
                        label=""
                        title="MILIEU RÉCEPTEUR"
                        header={["Code", "Libellé", "Indice du bassin"]}
                        linesData={
                          selectPossibleMethods(referentialSandres).surface
                        }
                        formatLine={item => [
                          item.code,
                          item.libelle,
                          item.bassindce,
                        ]}
                        formatSelectedTitle={lineData => lineData.libelle}
                        isLineInSearch={(lineData, searchedStr) =>
                          isSearchStringInCollection(
                            [lineData.code, lineData.libelle],
                            searchedStr
                          )
                        }
                        additionalClassName={classes.largeField}
                        placeholder="Milieu de prélèvement..."
                        disabled={shouldDisabledFields}
                        commonProps={{ ...commonProps, labelWidth: "0" }}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="volumeEauSouterraine"
                        label="Eaux souterraines"
                        unit="m³/an"
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_SOUTERRAIN}
                        additionalClassNameInput={
                          props.values.volumeEauSouterraine != null &&
                          prelevementEnEau.seuilEnKgAnMilieuNaturel != null &&
                          props.values.volumeEauSouterraine >
                            prelevementEnEau.seuilEnKgAnMilieuNaturel
                            ? classes.depassementDeSeuil
                            : classes.normal
                        }
                      />
                    </div>
                    <div className={classes.rightFieldContainer}>
                      <WrappedChoiceSelectModale
                        name="lieuEauSouterraine"
                        label=""
                        title="MILIEU RÉCEPTEUR"
                        header={["Code", "Libellé", "Indice du bassin"]}
                        linesData={
                          selectPossibleMethods(referentialSandres).underground
                        }
                        formatLine={lineData => [
                          lineData.code,
                          lineData.libelle,
                          lineData.bassindce,
                        ]}
                        formatSelectedTitle={lineData => lineData.libelle}
                        isLineInSearch={(lineData, searchedStr) =>
                          isSearchStringInCollection(
                            [lineData.code, lineData.libelle],
                            searchedStr
                          )
                        }
                        additionalClassName={classes.largeField}
                        placeholder="Milieu de prélèvement..."
                        disabled={shouldDisabledFields}
                        commonProps={{ ...commonProps, labelWidth: "0" }}
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="volumeEauPotable"
                        label="Eau potable (réseau AEP)"
                        unit="m³/an"
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_POTABLE}
                        additionalClassNameInput={
                          isDepassementDeSeuilEauPotableAndAdduction(
                            props.values.volumeEauPotable,
                            props.values.volumeEauAdduction,
                            prelevementEnEau.seuilEnKgAnAdduction
                          )
                            ? classes.depassementDeSeuil
                            : classes.normal
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="volumeEauAdduction"
                        label="Reseau d'adduction autre qu'AEP"
                        unit="m³/an"
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_ADDUCTION}
                        additionalClassNameInput={
                          isDepassementDeSeuilEauPotableAndAdduction(
                            props.values.volumeEauPotable,
                            props.values.volumeEauAdduction,
                            prelevementEnEau.seuilEnKgAnAdduction
                          )
                            ? classes.depassementDeSeuil
                            : classes.normal
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.leftFieldContainer}>
                      <NumberField
                        name="volumeEauMer"
                        label="Mer ou océan"
                        unit="m³/an"
                        disabled={shouldDisabledFields}
                        commentPath={PATH_EAU_PRELEVEMENT_MER_OCEAN}
                        additionalClassNameInput={
                          props.values.volumeEauMer != null &&
                          prelevementEnEau.seuilEnKgAnMilieuNaturel != null &&
                          props.values.volumeEauMer >
                            prelevementEnEau.seuilEnKgAnMilieuNaturel
                            ? classes.depassementDeSeuil
                            : classes.normal
                        }
                      />
                    </div>

                    <div className={classes.rightFieldContainer}>
                      <WrappedChoiceSelect
                        name="lieuEauMer"
                        label=""
                        isMulti={false}
                        options={selectPossibleMethods(referentialSandres).sea}
                        additionalClassName={classes.largeField}
                        placeholder="Milieu de prélèvement..."
                        disabled={shouldDisabledFields}
                        computeLabel={mer =>
                          arraySeaWaterTakingLocationLabel[mer]
                        }
                        commonProps={{ ...commonProps, labelWidth: "0" }}
                      />
                    </div>
                  </div>
                </>
              )}
              <Row height="15px" />
              <div className={!isDepassementSeuil ? "" : classes.row}>
                <div
                  className={
                    !isDepassementSeuil ? "" : classes.leftFieldContainer
                  }
                >
                  {!isDepassementSeuil ? (
                    <NumberField
                      name="prelevementTotal"
                      label="Prélèvement total"
                      unit="m³/an"
                      disabled={shouldDisabledFields}
                    />
                  ) : (
                    <DummyNumberField
                      name="prelevementTotalCalcule"
                      label="Prélèvement total"
                      unit="m³/an"
                      value={computeTotalWaterTaking(props.values)}
                      disabled
                    />
                  )}
                </div>
              </div>
            </>
          )}
          icon={
            (prelevementEnEau.depassementDeSeuilAdduction ||
              prelevementEnEau.depassementDeSeuilEauxSouterraines ||
              prelevementEnEau.depassementDeSeuilEauxSuperficielles ||
              prelevementEnEau.depassementDeSeuilMerOuOcean) && (
              <img
                src={jaugeLogo}
                alt="Depassement de Seuil"
                className={classes.jaugeIcon}
              />
            )
          }
        />
      </div>
    </>
  );
};

export default BlocPrelevementEau;
