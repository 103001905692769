import { FileDto } from "api/gen";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { FileHandlers } from "common/FilesLoader/types";
import {
  useDeleteFileHandler,
  useUploadFileHandler,
} from "pages/CompanySpace/DeclarationApiContext/utils/genericSectionFilesHooks";
import { useCallback, useMemo } from "react";
import { Declaration1919, MAX_YEAR, MIN_YEAR } from "../declarationHooks1919";

export const useElevageFileHandler1919 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return (await declarationFileController.uploadElevageFeuilleUsingPOST(
        annee,
        etablissement,
        file
      )) as Declaration1919;
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return (await declarationFileController.deleteElevageFeuilleUsingDELETE(
        annee,
        etablissement,
        file
      )) as Declaration1919;
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration1919>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration1919>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const usePdsFileHandler1919 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadPds1819UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deletePds1819UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration1919>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration1919>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useRapportEmissionsFileHandler1919 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadRapportEmissions1819UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteRapportEmissions1819UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration1919>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration1919>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useQuotasRapportVerificationFileHandler1919 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadQuotasRapportVerification1819UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteQuotasRapportVerification1819UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration1919>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration1919>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};

export const useQuotasRapportOfficeFileHandler1919 = (): FileHandlers => {
  const { declarationFileController } = useAuthenticatedApi();

  const uploadHandler = useCallback(
    async (annee: number, etablissement: string, file: File) => {
      return declarationFileController.uploadQuotasRapportOffice1819UsingPOST(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const deleteHandler = useCallback(
    async (annee: number, etablissement: string, file: FileDto) => {
      return declarationFileController.deleteQuotasRapportOffice1819UsingDELETE(
        annee,
        etablissement,
        file
      );
    },
    [declarationFileController]
  );

  const uploadHandlerWithEvent = useUploadFileHandler<Declaration1919>(
    uploadHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  const deleteHandlerWithEvent = useDeleteFileHandler<Declaration1919>(
    deleteHandler,
    MIN_YEAR,
    MAX_YEAR
  );

  return useMemo(() => {
    return {
      uploadHandlerWithEvent,
      deleteHandlerWithEvent,
    };
  }, [uploadHandlerWithEvent, deleteHandlerWithEvent]);
};
