import React from "react";
import {
  EtablissementDataDto,
  EtablissementDataDtoNatureServiceEnum,
  NatureServiceWithEnumDto,
  ReferenceItemDepartementDto,
  ReferenceItemRegionDto,
} from "api/gen";
import { CommonProps } from "common/form/utils";
import { useTextFieldGenerator } from "common/form/fields/helpers/generators";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { useSpinnerState } from "common/button/loadingAndRedirectionHelpers";
import { Nullable } from "common/utils/types";
import DummyGlobalFormActions from "common/actions/DummyGlobalFormActions";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/styles";
import {
  commonObjectFields,
  commonStringFields,
} from "common/declarant/formik/formikHelper";
import { useUserData } from "Authenticator/UserData";
import {
  baseDepartementForRight,
  baseInspectorCodeForRight,
  baseRegionForRight,
  possibleDepartmentForRegion,
} from "./utils";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import { isSearchStringInCollection } from "common/utils/methods";
import { backAlertMessage } from "common/backErrors/utils";
import { useAlertModale } from "common/modale/hooks";
import { backMessageEstablishmentCreation } from "common/backErrors/errorMessages";
import { CancelButtonType } from "common/actions/types";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import { AnyDeclarationDto } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import FormikAdapter from "libAdapter/Formik/FormikAdapter";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";

const useStyles = makeStyles({
  marginLeft: {
    marginLeft: "10px",
  },
  fieldWrapper: { width: "auto", display: "flex" },
  modaleList: {
    margin: "40px 0 40px 15%",
  },
});

const validationSchema = Yup.object().shape({
  companyName: commonStringFields,
  inspectionGroup: commonObjectFields,
  region: commonObjectFields,
  departement: commonObjectFields,
});

export interface EstablishmentData {
  companyName: string;
  inspectionGroup: NatureServiceWithEnumDto;
  region: ReferenceItemRegionDto;
  departement: ReferenceItemDepartementDto;
}

interface EstablishmentCreationFormProps {
  setDeclaration: (declaration: AnyDeclarationDto) => void;
  departements: ReferenceItemDepartementDto[];
  adminEmail: string;
  regions: ReferenceItemRegionDto[];
  referentielOrganismes: NatureServiceWithEnumDto[];
  cancelButton: CancelButtonType;
}

export const EstablishmentCreation = (
  props: EstablishmentCreationFormProps
): React.ReactElement => {
  const classes = useStyles();
  const { declarationCreationController } = useAuthenticatedApi();
  const [isSpinnerVisible, triggerSpinner] = useSpinnerState<void>();
  const openAlertModale = useAlertModale();

  const commonProps: CommonProps = {
    disabled: false,
    formPrefix: "companyManagement",
    labelWidth: "50%",
    className: "",
  };
  const TextField = useTextFieldGenerator(commonProps);

  const currentRight = useUserData().userInfo.droits[0];
  const initialValues: Nullable<EstablishmentData> = {
    companyName: null,
    inspectionGroup: baseInspectorCodeForRight(
      currentRight,
      props.referentielOrganismes
    ),
    region: baseRegionForRight(currentRight, props.regions, props.departements),
    departement: baseDepartementForRight(currentRight, props.departements),
  };

  return (
    <DummyUnActionedBloc
      title={"Données de l'établissement"}
      renderContent={() => {
        return (
          <>
            <FormikAdapter
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={values => {
                if (
                  !values.companyName ||
                  !values.inspectionGroup ||
                  !values.region ||
                  !values.departement
                ) {
                  throw Error("validation failed");
                }

                const etablissementDto: EtablissementDataDto = {
                  departement: values.departement.numero,
                  etablissementCode: null,
                  etablissementName: values.companyName,
                  natureService:
                    EtablissementDataDtoNatureServiceEnum[
                      values.inspectionGroup.value
                    ],
                  codeRegion: values.region.codeRegion,
                  equipeGunId: null,
                };
                const createEtablissement = async () => {
                  try {
                    const declaration = await declarationCreationController.createDeclarationWithHeaderUsingPOST(
                      etablissementDto
                    );
                    openAlertModale(
                      <div>
                        L'établissement "{values.companyName}" a bien été créé.
                        <ul className={classes.modaleList}>
                          <li>
                            Code établissement : {declaration.etablissementCode}
                            .
                          </li>
                          <li>
                            Service d'inspection :{" "}
                            {values.inspectionGroup
                              ? values.inspectionGroup.natureService.nom
                              : ""}
                            .
                          </li>
                        </ul>
                        Afin de permettre la création de cet établissement dans
                        le portail MonAIOT (
                        <a href="https://monaiot.developpement-durable.gouv.fr">
                          https://monaiot.developpement-durable.gouv.fr
                        </a>
                        ), <br />
                        veuillez contacter l'administrateur du portail MonAIOT (
                        <a href={`mailto:${props.adminEmail}`}>
                          {props.adminEmail}
                        </a>
                        ) en lui fournissant les informations suivantes :
                        <ul className={classes.modaleList}>
                          <li>Code établissement</li>
                          <li>Raison sociale</li>
                          <li>SIRET</li>
                          <li>Adresse</li>
                          <li>Code postal</li>
                          <li>Code INSEE commune</li>
                          <li>
                            Service d'inspection (ASNR, CGA, DDT(M) ou DSND)
                          </li>
                        </ul>
                        L'administrateur du portail MonAIOT vous informera le
                        cas échéant de la création de l'établissement dans le
                        portail MonAIOT. Vous pourrez alors attribuer les droits
                        GEREP à l'exploitant afin qu'il puisse procéder à la
                        déclaration. <br />
                        <br />
                        Afin de permettre à l'exploitant d'accéder à sa
                        déclaration, veuillez compléter la page "Types
                        d'activité".
                      </div>
                    ).then(() => props.setDeclaration(declaration));
                  } catch (excp) {
                    openAlertModale(
                      backAlertMessage(excp, backMessageEstablishmentCreation)
                    );
                    return;
                  }
                };
                triggerSpinner(createEtablissement());
              }}
              render={formProps => {
                return (
                  <>
                    <TextField
                      label={"Nom de l'établissement *"}
                      name={"companyName"}
                    />

                    <WrappedChoiceSelect
                      label={"Organisme d'inspection *"}
                      name={"inspectionGroup"}
                      isMulti={false}
                      options={props.referentielOrganismes}
                      disabled={initialValues.inspectionGroup !== null}
                      commonProps={commonProps}
                      computeLabel={organisme => organisme.natureService.nom}
                    />

                    <WrappedChoiceSelectModale
                      label={"Région *"}
                      name={"region"}
                      title={"Région"}
                      header={["Région"]}
                      linesData={props.regions.sort((region1, region2) =>
                        region1.nom.localeCompare(region2.nom)
                      )}
                      formatLine={lineData => [lineData.nom]}
                      onSelect={() => {
                        formProps.setFieldValue("departement", null);
                        formProps.setFieldTouched("departement", false);
                      }}
                      formatSelectedTitle={selectedOption => selectedOption.nom}
                      isLineInSearch={(lineData, searchedStr) =>
                        isSearchStringInCollection([lineData.nom], searchedStr)
                      }
                      disabled={initialValues.region !== null}
                      commonProps={commonProps}
                    />

                    {formProps.values.region && (
                      <WrappedChoiceSelectModale
                        label={"Département *"}
                        name={"departement"}
                        title={"Département"}
                        header={["Département"]}
                        linesData={possibleDepartmentForRegion(
                          formProps.values.region.codeRegion,
                          props.departements
                        )}
                        formatLine={lineData => [
                          `${lineData.numero} - ${lineData.nom}`,
                        ]}
                        formatSelectedTitle={selectedOption =>
                          `${selectedOption.numero} - ${selectedOption.nom}`
                        }
                        isLineInSearch={(lineData, searchedStr) =>
                          isSearchStringInCollection(
                            [lineData.numero],
                            searchedStr
                          )
                        }
                        disabled={initialValues.departement !== null}
                        commonProps={commonProps}
                      />
                    )}

                    <DummyGlobalFormActions
                      validateButton={{
                        title: "Valider et créer la déclaration",
                        isVisible: true,
                        isEnabled: true,
                        onClick: async () => {
                          formProps.submitForm();
                        },
                        isSpinnerVisible: isSpinnerVisible,
                      }}
                      cancelButton={props.cancelButton}
                      validationMessage={{
                        message: DEFAULT_VALIDATION_MESSAGE,
                        isAlwaysVisible: false,
                      }}
                    />
                  </>
                );
              }}
            />
          </>
        );
      }}
    />
  );
};
