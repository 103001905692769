import React from "react";
import { ActiviteTransformationType } from "./utils/types";
import CustomModal from "common/presentational/CustomModal";
import _ from "lodash";
import { ReferencePaysDto, ReferenceGeoCodeInseeDto } from "api/gen";
import FormFactory from "./FormFactory";
import uuid from "uuid";

interface FactoriesModalProps {
  isOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  factoryInModal: ActiviteTransformationType | null;
  setFactoryInModal: (factory: ActiviteTransformationType | null) => void;
  factoriesInArray: ActiviteTransformationType[];
  setFactoriesInArray: (substances: ActiviteTransformationType[]) => void;
  referentialCountries: ReferencePaysDto;
  referentialInsee: ReferenceGeoCodeInseeDto;
}

const FactoriesModal = ({
  isOpen,
  setIsModalOpen,
  factoryInModal,
  setFactoryInModal,
  factoriesInArray,
  setFactoriesInArray,
  referentialCountries,
  referentialInsee,
}: FactoriesModalProps): React.ReactElement => {
  const onClose = () => {
    setFactoryInModal(null);
    setIsModalOpen(false);
  };

  return (
    <CustomModal isOpen={isOpen} onRequestClose={onClose}>
      <FormFactory
        referentialCountries={referentialCountries}
        referentialInsee={referentialInsee}
        closeFunction={onClose}
        onSubmit={values => {
          values.id = factoryInModal?.id || uuid();
          const newFactoriesInArray = factoriesInArray;
          let indexToReplace = newFactoriesInArray.length;
          if (factoryInModal) {
            indexToReplace = _.findIndex(newFactoriesInArray, factoryInModal);
            setFactoryInModal(null);
          }
          newFactoriesInArray[indexToReplace] = values;
          setFactoriesInArray(newFactoriesInArray);
          onClose();
        }}
        initialObject={
          factoryInModal || {
            id: "",
            raisonSocialeUsine: null,
            distanceUsineCarriere: null,
            ville: null,
            paysID: null,
            alimenteeParCarrieres: false,
            codePostal: null,
          }
        }
        isEdit={factoryInModal !== null}
      />
    </CustomModal>
  );
};

export default FactoriesModal;
