import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/button";
import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  aggregationButton: {
    padding: "5px",
  },
});

export interface AdminExportButtonsProps {
  isDisabled: boolean;
  setIsAggregationModaleOpen: (value: React.SetStateAction<boolean>) => void;
}

export const AdminDownloadFilesButtons = ({
  isDisabled,
  setIsAggregationModaleOpen,
}: AdminExportButtonsProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <Button
      text={
        <>
          <FontAwesomeIcon icon="download" /> Télécharger les fichiers déposés
          dans les déclarations
        </>
      }
      isReversed
      onClick={() => {
        setIsAggregationModaleOpen(true);
      }}
      additionalClassname={classes.aggregationButton}
      isDisabled={isDisabled}
    />
  );
};
