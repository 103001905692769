import {
  CampaignDto,
  CampaignDtoStateAllocationsEnum,
  CampaignDtoStateEmissionsEnum,
  CampaignDtoStateGlobalEnum,
} from "api/gen";
import { DatesCampagne, MessagesDates } from "./types";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";

export const buildDatesCampagnesFromDto = (dto: CampaignDto): DatesCampagne => {
  return {
    start: dto.start,
    endAllocationsDeclarants: dto.endAllocationsDeclarants,
    endEmissionsDeclarants: dto.endEmissionsDeclarants,
    endGlobalDeclarants: dto.endGlobalDeclarants,
    endAllocations: dto.endAllocations,
    endEmissions: dto.endEmissions,
    endGlobal: dto.endGlobal,
  };
};

export const DATES_NAMES: MessagesDates = {
  start: "date de début de campagne",
  endAllocationsDeclarants:
    "date de fin des allocations quotas pour les déclarants",
  endEmissionsDeclarants:
    "date de fin des émissions quotas pour les déclarants",
  endGlobalDeclarants: "date de fin globale pour les déclarants",
  endAllocations: "date de fin générale des allocations quotas",
  endEmissions: "date de fin générale des émissions quotas",
  endGlobal: "date de fin globale",
};

export const NO_MODIF_OF_PAST_DATE_MESSAGE: MessagesDates = {
  start: `La ${DATES_NAMES.start} est passée. Vous ne pouvez plus la modifier.`,
  endAllocationsDeclarants: `La ${DATES_NAMES.endAllocationsDeclarants} est passée. Vous ne pouvez plus la modifier.`,
  endEmissionsDeclarants: `La ${DATES_NAMES.endEmissionsDeclarants} est passée. Vous ne pouvez plus la modifier.`,
  endGlobalDeclarants: `La ${DATES_NAMES.endGlobalDeclarants} est passée. Vous ne pouvez plus la modifier.`,
  endAllocations: `La ${DATES_NAMES.endAllocations} est passée. Vous ne pouvez plus la modifier.`,
  endEmissions: `La ${DATES_NAMES.endEmissions} est passée. Vous ne pouvez plus la modifier.`,
  endGlobal: `La ${DATES_NAMES.endGlobal} est passée. Vous ne pouvez plus la modifier.`,
};

export const stateToString = (
  state:
    | CampaignDtoStateAllocationsEnum
    | CampaignDtoStateEmissionsEnum
    | CampaignDtoStateGlobalEnum
): string => {
  switch (state) {
    case CampaignDtoStateAllocationsEnum.PLANNED:
    case CampaignDtoStateEmissionsEnum.PLANNED:
    case CampaignDtoStateGlobalEnum.PLANNED:
      return "planifié";

    case CampaignDtoStateAllocationsEnum.READY:
    case CampaignDtoStateEmissionsEnum.READY:
    case CampaignDtoStateGlobalEnum.READY:
      return "prêt";

    case CampaignDtoStateAllocationsEnum.STARTED:
    case CampaignDtoStateEmissionsEnum.STARTED:
    case CampaignDtoStateGlobalEnum.STARTED:
      return "en cours";

    case CampaignDtoStateAllocationsEnum.ENDED_FOR_DECLARANTS:
    case CampaignDtoStateEmissionsEnum.ENDED_FOR_DECLARANTS:
    case CampaignDtoStateGlobalEnum.ENDED_FOR_DECLARANTS:
      return "terminé pour les déclarants";

    case CampaignDtoStateAllocationsEnum.ENDED:
    case CampaignDtoStateEmissionsEnum.ENDED:
    case CampaignDtoStateGlobalEnum.ENDED:
      return "terminé";

    default:
      throw new ShouldNotHappen(state);
  }
};
