import { ReferenceItemPolluantElementDto } from "api/gen";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import {
  computeNumberWithSeparator,
  computeNumberWithSeparatorAndVariableDecimal,
} from "common/utils/numberUtils";
import { computeWrappedCellWithError } from "common/utils/computeWrappedCell";
import { ClassesType } from "common/utils/types";
import _ from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { computeBiomasseTotal } from "../../../combustionProcedeUtils/biomasse/helpers";
import {
  getIntrantName,
  getSortantName,
} from "../../../combustionProcedeUtils/intrantsSortants/EmissionMatiereArrayUtils";
import {
  computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants,
  computePercentageMoyenneTeneurIntrantsSortants,
  computeSumEmissionsIntrantsSortants,
} from "../../../combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import { createProcedeDiv } from "../../utils/utils";
import { EmissionsInArray, MatiereEmissionInArray } from "../utils/types";
import { shouldPartBeFilled } from "../../../combustionProcedeUtils/bilanMatiere/elementsHelpers";
import { ProcedeInArray } from "../../ListProcede/utils/types";

const NUMBER_DECIMAL_INF_ONE = 12;
const NUMBER_DECIMAL_SUP_ONE = 4;
const suppressEmission = (
  singleEmissionInPage: MatiereEmissionInArray,
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>
) => {
  setMatiereEmissionsInPage(elderEmissions => {
    return elderEmissions.filter(elderEmissionInPage => {
      return singleEmissionInPage.data.id !== elderEmissionInPage.data.id;
    });
  });
};

// a method to compute lines that takes as param any action or data that needs to be deduced from another data
const dummyComputeSingleEmissionLine = (
  emission: MatiereEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  editAction: () => void,
  suppressAction: () => void,
  substanceName: string | null,
  element: string | null,
  part: number | null,
  totalIntrants: number,
  totalSortants: number,
  moyenneTeneurIntrants: number | null,
  moyenneTeneurSortants: number | null,
  emissionsAnnuelles: number | null,
  isValidated: boolean,
  validationMatieresPath: string,
  pathSuffix: string,
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const firstCellContent = computeFirstLineContentCell(
    editAction,
    substanceName || undefined,
    isValidated,
    !_.isEmpty(emission.errors)
  );

  return [
    computeWrappedCellWithError(firstCellContent, classes),
    computeWrappedCellWithError(
      emission.data.procedes !== null
        ? createProcedeDiv(emission.data.procedes, procedesInPageMap)
        : "",
      classes
    ),
    computeWrappedCellWithError(
      <p title={element || undefined}>{element}</p>,
      classes
    ),
    computeWrappedCellWithError(computeNumberWithSeparator(part), classes),
    computeWrappedCellWithError(
      <p title={getIntrantName(emission.data.intrants) || undefined}>
        {getIntrantName(emission.data.intrants)}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        totalIntrants,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        moyenneTeneurIntrants,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      <p title={getSortantName(emission.data.sortants) || undefined}>
        {getSortantName(emission.data.sortants)}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        totalSortants,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        moyenneTeneurSortants,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      !emission.data.epuration ? "Non" : "Oui",
      classes
    ),
    computeWrappedCellWithError(
      <p title={emission.data.natureEquipement || undefined}>
        {emission.data.natureEquipement}
      </p>,
      classes
    ),
    computeWrappedCellWithError(
      computeNumberWithSeparatorAndVariableDecimal(
        emission.data.rendement,
        NUMBER_DECIMAL_INF_ONE,
        NUMBER_DECIMAL_SUP_ONE
      ),
      classes
    ),
    computeWrappedCellWithError(
      emissionsAnnuelles !== null
        ? computeNumberWithSeparatorAndVariableDecimal(
            emissionsAnnuelles,
            NUMBER_DECIMAL_INF_ONE,
            NUMBER_DECIMAL_SUP_ONE
          )
        : "",
      classes
    ),
    <CommonEntityButton
      handlerEdit={editAction}
      handlerSuppress={() => {
        suppressEmission(emission, setMatiereEmissionsInPage);
      }}
      suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
      isValidated={isValidated}
      commentPath={`${validationMatieresPath}/${pathSuffix}`}
    />,
  ];
};

// method to compute the lines for a "standard" emission. Handles the actions and data that is "deduced" from the base emission given
export const computeSingleEmissionLine = (
  emission: MatiereEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>,
  isValidated: boolean,
  validationMatieresPath: string,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >,
  autreElementUuid: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMatiereEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;

  const substanceRestorationCode =
    emission.data.substance && emission.data.substance.restorationCode;

  const elementUuid =
    emission.data.elementProps &&
    emission.data.elementProps.referenceItemElementIndexDto.uuid;

  const polluantElementsInReferential: Map<
    string | null,
    ReferenceItemPolluantElementDto
  > | null = polluantElementMap.get(substanceRestorationCode) || null;

  const polluantElement: ReferenceItemPolluantElementDto | null =
    (polluantElementsInReferential &&
      polluantElementsInReferential.get(elementUuid)) ||
    null;

  const element: string | null =
    shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
    polluantElement === null
      ? emission.data.elementName
      : polluantElement.referenceItemElementIndexDto.nom;

  const part: number | null =
    shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
    polluantElement === null
      ? emission.data.part
      : 100 * polluantElement.partElement;

  const totalIntrants = computeSumEmissionsIntrantsSortants(
    emission.data.intrants
  );

  const totalSortants = computeSumEmissionsIntrantsSortants(
    emission.data.sortants
  );

  const moyenneTeneurIntrants = computePercentageMoyenneTeneurIntrantsSortants(
    emission.data.intrants
  );
  const moyenneTeneurSortants = computePercentageMoyenneTeneurIntrantsSortants(
    emission.data.sortants
  );

  const emissionsAnnuelles: number = computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants(
    emission.data.intrants,
    emission.data.sortants,
    part
  );

  return dummyComputeSingleEmissionLine(
    emission,
    procedesInPageMap,
    classes,
    editAction,
    suppressAction,
    substanceName,
    element,
    part,
    totalIntrants,
    totalSortants,
    moyenneTeneurIntrants,
    moyenneTeneurSortants,
    emissionsAnnuelles,
    isValidated,
    validationMatieresPath,
    emission.data.id,
    setMatiereEmissionsInPage,
    computeFirstLineContentCell
  );
};

// method to compute the lines for a co2 emission. Handles the duplication of line in 2,
// and the different name in first cell, as well as the different total. Also creates the
// actions and data that is "deduced" from the base emission given
export const computeCo2EmissionLine = (
  emission: MatiereEmissionInArray,
  procedesInPageMap: Map<string, ProcedeInArray>,
  classes: ClassesType<"full" | "error">,
  setEmissionInModale: Dispatch<SetStateAction<EmissionsInArray | null>>,
  setEmissionModaleOpen: Dispatch<SetStateAction<boolean>>,
  setMatiereEmissionsInPage: Dispatch<SetStateAction<MatiereEmissionInArray[]>>,
  isValidated: boolean,
  validationMatieresPath: string,
  polluantElementMap: Map<
    number | null,
    Map<string | null, ReferenceItemPolluantElementDto>
  >,
  autreElementUuid: string,
  computeFirstLineContentCell: (
    handlerEdit: () => void,
    cellContent: string | undefined,
    isCellDisabled: boolean,
    hasLineError: boolean
  ) => React.ReactElement
): React.ReactElement[][] => {
  const editAction = () => {
    setEmissionInModale(emission);
    setEmissionModaleOpen(true);
  };

  const suppressAction = () => {
    suppressEmission(emission, setMatiereEmissionsInPage);
  };

  const substanceName = emission.data.substance && emission.data.substance.nom;

  const substanceRestorationCode =
    emission.data.substance && emission.data.substance.restorationCode;

  const elementUuid =
    emission.data.elementProps &&
    emission.data.elementProps.referenceItemElementIndexDto.uuid;

  const polluantElementsInReferential: Map<
    string | null,
    ReferenceItemPolluantElementDto
  > | null = polluantElementMap.get(substanceRestorationCode) || null;

  const polluantElement: ReferenceItemPolluantElementDto | null =
    (polluantElementsInReferential &&
      polluantElementsInReferential.get(elementUuid)) ||
    null;

  const element: string | null =
    shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
    polluantElement === null
      ? emission.data.elementName
      : polluantElement.referenceItemElementIndexDto.nom;

  const part =
    shouldPartBeFilled(emission.data, polluantElementMap, autreElementUuid) ||
    polluantElement === null
      ? emission.data.part
      : 100 * polluantElement.partElement;

  const totalIntrants = computeSumEmissionsIntrantsSortants(
    emission.data.intrants
  );

  const totalSortants = computeSumEmissionsIntrantsSortants(
    emission.data.sortants
  );

  const moyenneTeneurIntrants = computePercentageMoyenneTeneurIntrantsSortants(
    emission.data.intrants
  );
  const moyenneTeneurSortants = computePercentageMoyenneTeneurIntrantsSortants(
    emission.data.sortants
  );

  const emissionsAnnuelles: number = computeEmissionsAnnuellesForBilanMatiereWithIntrantsSortants(
    emission.data.intrants,
    emission.data.sortants,
    part
  );

  const {
    emissionAnnuellesBiomasse,
    emissionAnnuellesNonBiomasse,
  } = computeBiomasseTotal(emissionsAnnuelles, emission.data.biomasse);

  return [
    dummyComputeSingleEmissionLine(
      emission,
      procedesInPageMap,
      classes,
      editAction,
      suppressAction,
      `${substanceName} biomasse`,
      element,
      part,
      totalIntrants,
      totalSortants,
      moyenneTeneurIntrants,
      moyenneTeneurSortants,
      emissionAnnuellesBiomasse,
      isValidated,
      validationMatieresPath,
      emission.data.id + "/biomasse",
      setMatiereEmissionsInPage,
      computeFirstLineContentCell
    ),
    dummyComputeSingleEmissionLine(
      emission,
      procedesInPageMap,
      classes,
      editAction,
      suppressAction,
      `${substanceName} non biomasse`,
      element,
      part,
      totalIntrants,
      totalSortants,
      moyenneTeneurIntrants,
      moyenneTeneurSortants,
      emissionAnnuellesNonBiomasse,
      isValidated,
      validationMatieresPath,
      emission.data.id + "/nonBiomasse",
      setMatiereEmissionsInPage,
      computeFirstLineContentCell
    ),
  ];
};
