import React, { ReactElement } from "react";
import { MethodeCalculExceptPfcDto } from "api/gen";
import SubSectionTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/SubSectionTitle";
import ArrayTitle from "pages/CompanySpace/from21/toNow/Quotas/common/TitleComponents/ArrayTitle";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { makeStyles } from "@material-ui/styles";
import {
  computeDonneeActiviteLine,
  computeDonneeSurveillanceLine,
} from "./tableLineComputer";

const useStyles = makeStyles({
  container: {
    margin: "40px 0",
  },
  arrayContainer: {
    margin: "30px 0",
  },
  full: {
    display: "flex",
    alignItems: "center",
  },
});

interface MethodeCalculExceptPfcListProps {
  methodeCalculExceptPfcList: MethodeCalculExceptPfcDto[];
}

const MethodeCalculExceptPfcList = ({
  methodeCalculExceptPfcList,
}: MethodeCalculExceptPfcListProps): ReactElement => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SubSectionTitle>
        Données de surveillance issues de la méthode de calcul par flux - hors
        PFC
      </SubSectionTitle>

      {methodeCalculExceptPfcList.map(
        ({
          classementFlux: { referenceFlux, fluxFullName },
          donneeActivite,
          facteurConversion,
          facteurEmission,
          facteurOxydation,
          fractionBiomasse,
          pouvoirCalorifique,
          teneurCarbone,
        }) => (
          <div className={classes.arrayContainer}>
            <ArrayTitle>{`${fluxFullName || ""} - ${referenceFlux ||
              ""}`}</ArrayTitle>
            <CommonEntityTable
              header={[
                <p></p>,
                <p>Niveau appliqué</p>,
                <p>Niveau requis</p>,
                <p>Valeur par défaut (le cas échéant)</p>,
                <p>Unité</p>,
                <p>Fréquence d'analyse</p>,
                <p>Incertitude</p>,
              ]}
              lines={[
                computeDonneeActiviteLine(donneeActivite, classes),
                computeDonneeSurveillanceLine(
                  pouvoirCalorifique,
                  "Pouvoir calorifique inférieur",
                  classes
                ),
                computeDonneeSurveillanceLine(
                  facteurEmission,
                  "Facteur émission (préliminaire)",
                  classes
                ),
                computeDonneeSurveillanceLine(
                  facteurOxydation,
                  "Facteur d'oxydation",
                  classes
                ),
                computeDonneeSurveillanceLine(
                  facteurConversion,
                  "Facteur de conversion",
                  classes
                ),
                computeDonneeSurveillanceLine(
                  teneurCarbone,
                  "Teneur en carbone",
                  classes
                ),
                computeDonneeSurveillanceLine(
                  fractionBiomasse,
                  "Fraction biomasse",
                  classes
                ),
              ]}
              isFirstColSticky={true}
              isLastColSticky={false}
            />
          </div>
        )
      )}
    </div>
  );
};

export default MethodeCalculExceptPfcList;
