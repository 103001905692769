import {
  DeclarationStateDtoStateEnum,
  DeclarationWorkflowStatusDto,
  ReferenceItemEprtrDto,
} from "api/gen";

export interface TechniqueValues {
  EPRTR: boolean;
  principalEPRTR?: ReferenceItemEprtrDto | null;
  activitesEPRTR?: ReferenceItemEprtrDto[];
  elevage?: boolean;
  combustion20MW?: boolean;
  incinerationDechet?: boolean;
  solvants?: boolean;
  recepDechet?: boolean;
  ISDND?: boolean;
  ISDD?: boolean;
  ISDI?: boolean;
  torchereBiogaz?: boolean;
  valorisationBiogaz?: boolean;
  exportBiogaz?: boolean;
  carriere?: boolean;
  quotas: boolean;
  excluArticle27?: boolean;
}

const advancedStates: DeclarationStateDtoStateEnum[] = [
  DeclarationStateDtoStateEnum.VERIFIED,
  DeclarationStateDtoStateEnum.SUBMITTED,
  DeclarationStateDtoStateEnum.VALIDATED,
];

export const shouldDisableQuotas = (
  workflowStatus: DeclarationWorkflowStatusDto
): boolean =>
  advancedStates.includes(workflowStatus.quotaAllocations.state) ||
  advancedStates.includes(workflowStatus.quotaEmissions.state);
