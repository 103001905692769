import React from "react";
import ErrorPage from "./ErrorPage";

interface UnexpectedErrorProps {
  error: string;
}

const UnexpectedError = ({
  error,
}: UnexpectedErrorProps): React.ReactElement => {
  return (
    <ErrorPage
      code={null}
      text={`Une erreur inattendue s'est produite. Merci de contacter l'administrateur de ce site en lui donnant l'information suivante : "${error}"`}
    />
  );
};

export default UnexpectedError;
