import React, { ReactElement } from "react";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { makeStyles } from "@material-ui/styles";
import { BuildingInArray } from "../utils/types";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { PINK } from "theme";
import Button from "common/button";
import {
  especeLabels,
  getSpecieEmissionField,
} from "./utils/selectPossibleValues";
import {
  computeNumberWithSeparator,
  getValueOrZero,
} from "common/utils/numberUtils";
import { PATH_AIR_ELEVAGE_DETAIL_BATIMENT } from "common/path/path18Now";
import Row from "common/presentational/Row";
import { AirElevageDetailNh320NowEspecesDeclareesEnum } from "api/gen";
import { CallbackSetter } from "common/utils/types";

interface EmissionsArrayProps {
  species: AirElevageDetailNh320NowEspecesDeclareesEnum[];
  buildingsInArray: BuildingInArray[];
  setBuildingsInArray: CallbackSetter<BuildingInArray[]>;
  setBuildingInModale: (building: BuildingInArray | null) => void;
  setBuildingModaleOpen: (isOpen: boolean) => void;
  isDisabled: boolean;
}

const useStyles = makeStyles({
  array: {
    marginTop: "20px",
  },
  link: {
    color: PINK,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

const EmissionsArray = ({
  species,
  buildingsInArray,
  setBuildingsInArray,
  setBuildingInModale,
  setBuildingModaleOpen,
  isDisabled,
}: EmissionsArrayProps): React.ReactElement => {
  const classes = useStyles();

  const header = [<p>Nom du bâtiment</p>];
  header.push(...species.map(specieEnum => <p>{especeLabels[specieEnum]}</p>));
  header.push(<p>Actions</p>);

  const lines = buildingsInArray.map((building: BuildingInArray) => {
    const editAction = () => {
      setBuildingInModale(building);
      setBuildingModaleOpen(true);
    };
    const render: ReactElement[] = [
      <span
        onClick={() => {
          if (!isDisabled) {
            editAction();
          }
        }}
        className={isDisabled ? "" : classes.link}
        title={building.data.name || undefined}
      >
        {building.data.name}
      </span>,
    ];
    species.forEach(specieEnum => {
      const specieEmissionField = getSpecieEmissionField(specieEnum);
      const value = computeNumberWithSeparator(
        getValueOrZero(building.data[specieEmissionField])
      );
      render.push(<p>{value}</p>);
    });
    render.push(
      <CommonEntityButton
        handlerEdit={editAction}
        suppressMessage={"êtes vous sûr de vouloir supprimer ce batiment ?"}
        handlerSuppress={() =>
          setBuildingsInArray(() =>
            buildingsInArray.filter(b => b.data.id !== building.data.id)
          )
        }
        isValidated={isDisabled}
        commentPath={`${PATH_AIR_ELEVAGE_DETAIL_BATIMENT}/${building.data.id}`}
      />
    );
    return render;
  });

  return (
    <>
      <p>
        Toutes les émissions du tableau sont renseignées en kg/an/emplacement
      </p>
      <CommonEntityTable
        header={header}
        lines={lines}
        preferredHeaderHeight={75}
        additionalClassName={classes.array}
      />
      <Row height={"15px"} />
      <Row additionalStyle={{ justifyContent: "flex-end" }}>
        <Button
          text="TOUT SUPPRIMER"
          isReversed
          isDisabled={isDisabled}
          onClick={() => setBuildingsInArray(() => [])}
        />
      </Row>
    </>
  );
};

export default EmissionsArray;
