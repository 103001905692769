import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export type ButtonStateHandler<T> = (
  promise: Promise<T>,
  onCompletion?: (() => void) | undefined
) => Promise<void>;

/**
 *
 * use this to lock button while performing async actions
 *
 * @returns an array :
 *          - first element contains a boolean that is true if are currently loading the promise
 *          and false if we are not loading
 *          - second element, a function waiting for the promise and displaying loading state meanwhile
 *          this function takes one promise to wait for, and an optional function triggered on wait completion
 *          (only if the component is still mounted)
 */
export function useSpinnerState<T>(): [boolean, ButtonStateHandler<T>] {
  const isMounted = useRef<boolean>(false);
  const [currentPromiseNumber, setCurrentPromiseNumber] = useState<number>(0);

  const memoTriggerSpinner = useCallback(
    async (promise: Promise<T>, onCompletion?: () => void) => {
      setCurrentPromiseNumber(number => number + 1);
      try {
        await promise;
      } catch (excp) {
        if (isMounted.current) {
          setCurrentPromiseNumber(number => number - 1);
        }

        throw excp;
      }

      if (!isMounted.current) {
        return;
      }

      setCurrentPromiseNumber(number => number - 1);
      onCompletion && onCompletion();
    },
    [isMounted, setCurrentPromiseNumber]
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, [isMounted]);

  return [currentPromiseNumber !== 0, memoTriggerSpinner];
}

/**
 *
 * use this to lock button while performing async actions and when completed redirect
 *
 * @param path when this path moves from unvalidated to validated state perform redirection
 * @param isPathValidatedInDeclaration function to check whether a path is validated in the declaration or not
 *
 * @returns an array :
 *          - first element contains a boolean that is true if are currently loading the promise
 *          and false if we are not loading
 *          - second element, a boolean indicating whether we should redirect or not (redirection needs to be perform on component)
 *          - third element, a boolean indicating if path is currently validated
 *          - fourth element, a function waiting for the promise and displaying loading state meanwhile
 *          this function takes one promise to wait for, and an optional function triggered on wait completion
 *          (only if the component is still mounted)
 */
export function useButtonStateWithRedirection<T>(
  path: string,
  isPathValidatedInDeclaration: (pathToCheck: string) => boolean
): [boolean, ButtonStateHandler<T>, boolean, boolean] {
  const [isCurrentlyValidated, setIsCurrentlyValidated] = useState<boolean>(
    isPathValidatedInDeclaration(path)
  );
  const [shouldRedirectToDashboard, setShouldRedirectToDashboard] = useState<
    boolean
  >(false);

  useLayoutEffect(() => {
    const isValidated = isPathValidatedInDeclaration(path);
    if (isValidated && !isCurrentlyValidated) {
      setShouldRedirectToDashboard(true);
    }
    setIsCurrentlyValidated(isValidated);
  }, [
    path,
    setIsCurrentlyValidated,
    setShouldRedirectToDashboard,
    isPathValidatedInDeclaration,
    isCurrentlyValidated,
  ]);

  const [isLoading, setLoading] = useSpinnerState();
  return [
    isLoading,
    setLoading,
    shouldRedirectToDashboard,
    isCurrentlyValidated,
  ];
}
