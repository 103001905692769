import React, { useCallback, useEffect, useMemo, useState } from "react";
import { WasteData } from "./types";
import Tabs, { SingleTabElemProps } from "common/tabs";
import { PolluantProduction } from "./production";
import { PolluantReception } from "./reception";
import WasteSumUpWrapper from "./sumUp/WasteSumUpWrapper";
import Row from "common/presentational/Row";
import { DeclareWasteModale } from "./productionModal";
import _ from "lodash";
import {
  deleteWasteFromContainer,
  isProductedWasteInContainer,
  isReceivedWasteInContainer,
  parseFetch,
  productionDataComputed,
  receptionDataComputed,
  shouldDisplayProductionArray,
  shouldDisplayReceptionArray,
  useImportWaste,
} from "./utils/utils";
import { useFormikBloc } from "common/formikBloc/utils";
import { DEFAULT_VALIDATION_MESSAGE } from "common/actions/utils";
import {
  ReceivedWasteFormData,
  ReceivedWasteImport,
  ReceivedWasteStorage,
} from "./reception/types";
import {
  methods,
  operationOrValoriationForReception,
  operationOrValorisationForProduction,
  productionLocation,
  receptionLocation,
} from "./utils/Referentiels";
import {
  convertProductionFetchToLocal,
  convertReceptionFetchToLocal,
  generatePolluantWasteFromCSV,
  generateReceivedWasteFromCSV,
} from "./utils/Generators";
import {
  createWasteProductionDto,
  createWasteReceptionDto,
} from "./utils/SubmitHandler";
import {
  ProductedWasteFormData,
  ProductedWasteImport,
  ProductedWasteStorage,
} from "./production/types";
import { Nullable } from "common/utils/types";
import {
  ComputedSyntheseElementDto,
  DechetProduitDto2022,
  DechetProduitDto2022LieuOperationEnum,
  DechetRecuDto1822,
  ReferenceDechetDto,
  ReferenceDepartementDto,
  ReferencePaysDto,
} from "api/gen";
import { WasteSyntheseArray } from "./synthese";
import { useEntityModale } from "common/modale/modaleStates";
import { DataWithErrors } from "common/form/utils";
import { DeclareReceptionWasteModal } from "./receptionModal";
import { useAlertModale } from "common/modale/hooks";
import {
  PATH_DECHET,
  PATH_DECHET_PRODUCTION,
  PATH_DECHET_RECEPTION,
  PATH_DECHET_RESUME,
} from "common/path/path18Now";
import { generateReceivedWasteErrors } from "./reception/utils";
import { generateProductedWasteErrors } from "./production/utils";
import { TypeActiviteGlobal20Now } from "../../toNow/versionedElements/declarationHooks20Now";
import { findElementMatchingTemplate } from "common/utils/methods";
import { useCorrectYearTypeActiviteGlobal20Now } from "../../../DeclarationApiContext/utils/correctYearVersionedElements/hooks/typeActivite";
import GlobalFormActionFullContext2022 from "../versionedElements/GlobalFormActionFullContext2022";
import {
  useDeclaration2022,
  useDeclarationHelpers2022,
} from "../versionedElements/declarationHooks2022";

interface WastFormProps {
  referentialDechet: ReferenceDechetDto;
  referentialDepartements: ReferenceDepartementDto;
  referentielPays: ReferencePaysDto;
}

const WasteController = ({
  referentialDechet,
  referentialDepartements,
  referentielPays,
}: WastFormProps): React.ReactElement => {
  const declaration = useDeclaration2022();
  const openAlertModale = useAlertModale();
  const { isPathValidatedInDeclaration } = useDeclarationHelpers2022();

  //  formik refs
  const formProduction = useFormikBloc<ProductedWasteFormData>();
  const formReception = useFormikBloc<ReceivedWasteFormData>();

  const typeActivite: TypeActiviteGlobal20Now = useCorrectYearTypeActiviteGlobal20Now();
  const isReceptionAvailable = typeActivite.recepDechet;

  const receptionDechets: ReceivedWasteFormData | null =
    formReception.formikRef.current &&
    formReception.formikRef.current.state.values;

  const referentiels = useMemo(() => {
    return {
      polluants: referentialDechet.dechets,
      methods: methods,
      operationOrValorisationForProduction: operationOrValorisationForProduction,
      operationOrValorisationForReception: operationOrValoriationForReception,
      productionLocation: productionLocation.filter(singleLocation => {
        return (
          isReceptionAvailable ||
          singleLocation.backCode !== DechetProduitDto2022LieuOperationEnum.SITE
        );
      }),
      receptionLocation: receptionLocation,
      departements: referentialDepartements.departements,
      pays: referentielPays.referenceItemPaysDtoList,
    };
  }, [
    referentialDechet.dechets,
    referentialDepartements.departements,
    referentielPays.referenceItemPaysDtoList,
    isReceptionAvailable,
  ]);

  //  handle fetch data
  const [
    productionInfo,
    productionContainer,
    receptionInfo,
    receptionContainer,
  ] = parseFetch(
    declaration.body,
    referentiels,
    typeActivite,
    receptionDechets
  );

  //    init page data with fetch data
  const [tempProductionContainer, setTempProductionContainer] = useState<
    ProductedWasteStorage[]
  >(productionContainer);
  const [tempReceptionContainer, setTempReceptionContainer] = useState<
    ReceivedWasteStorage[]
  >(receptionContainer);
  const [tempWasteProductInfo, setTempWasteProductInfo] = useState<
    ProductedWasteFormData
  >(productionInfo);
  const [tempWasteReceptionInfo, setTempWasteReceptionInfo] = useState<
    ReceivedWasteFormData
  >(receptionInfo);

  //    modal handlers
  const productionModalProps = useEntityModale<
    DataWithErrors<DechetProduitDto2022>
  >();
  const receptionModalProps = useEntityModale<
    DataWithErrors<DechetRecuDto1822>
  >();

  //  to display data
  const wasteData: WasteData = {
    //  containers
    validData: {
      production: productionDataComputed(productionInfo, productionContainer),
      reception: receptionDataComputed(
        receptionInfo,
        receptionContainer,
        productionInfo,
        productionContainer
      ),
    },
    tempData: {
      production: productionDataComputed(
        tempWasteProductInfo,
        tempProductionContainer
      ),
      reception: receptionDataComputed(
        tempWasteReceptionInfo,
        tempReceptionContainer,
        tempWasteProductInfo,
        tempProductionContainer
      ),
    },

    //  form referentiels
    referentiels: referentiels,
  };

  //  function data handler
  const importProduction = useImportWaste<
    DechetProduitDto2022,
    Nullable<ProductedWasteImport>
  >(
    wasteData.referentiels,
    receptionDechets,
    generatePolluantWasteFromCSV,
    setTempProductionContainer,
    () => {
      if (formProduction.formikRef.current) {
        const formValues = formProduction.formikRef.current.state.values;
        if (!_.isEqual(formValues, tempWasteReceptionInfo)) {
          setTempWasteProductInfo(formValues);
        }
      }
    }
  );
  const importReception = useImportWaste<
    DechetRecuDto1822,
    Nullable<ReceivedWasteImport>
  >(
    wasteData.referentiels,
    receptionDechets,
    generateReceivedWasteFromCSV,
    setTempReceptionContainer,
    null
  );

  const openNotUniqueProductedWaste = () => {
    openAlertModale(
      "Impossible d'ajouter ce déchet, vous avez déjà déclaré ce déchet avec cette opération au même lieu."
    );
  };
  const openNotUniqueReceivedWaste = () => {
    openAlertModale(
      "Impossible d'ajouter ce déchet, vous avez déjà déclaré ce déchet avec cette sortie du statut, cette opération et ce lieu."
    );
  };

  const addDeclaredWaste = (waste: DechetProduitDto2022) => {
    const data = {
      data: waste,
      errors: generateProductedWasteErrors(waste, referentiels),
    };
    if (
      isProductedWasteInContainer(data, tempProductionContainer, referentiels)
    ) {
      openNotUniqueProductedWaste();
      return;
    }

    setTempProductionContainer(value => [...value, data]);
    productionModalProps.closeModale();
  };

  const updateProductedWaste = (
    previousData: ProductedWasteStorage,
    newData: DechetProduitDto2022
  ) => {
    const data = {
      data: newData,
      errors: generateProductedWasteErrors(newData, referentiels),
    };
    if (
      isProductedWasteInContainer(
        data,
        tempProductionContainer.filter(test => test.data.id !== data.data.id),
        referentiels
      )
    ) {
      openNotUniqueProductedWaste();
      return;
    }

    setTempProductionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return data;
        }
      });
    });
    productionModalProps.closeModale();
  };

  const addReceivedWaste = (waste: DechetRecuDto1822) => {
    const data = { data: waste, errors: {} };
    if (
      isReceivedWasteInContainer(data, tempReceptionContainer, referentiels)
    ) {
      openNotUniqueReceivedWaste();
      return;
    }

    if (formReception.formikRef.current) {
      const formValues = formReception.formikRef.current.state.values;
      setTempWasteReceptionInfo(formValues);
    }
    setTempReceptionContainer(value => [...value, data]);
    receptionModalProps.closeModale();
  };
  const updateReceivedWaste = (
    previousData: ReceivedWasteStorage,
    newData: DechetRecuDto1822
  ) => {
    const data = { data: newData, errors: {} };
    if (
      isReceivedWasteInContainer(
        data,
        tempReceptionContainer.filter(test => test.data.id !== data.data.id),
        referentiels
      )
    ) {
      openNotUniqueReceivedWaste();
      return;
    }

    setTempReceptionContainer(value => {
      return value.map(element => {
        if (element.data.id !== previousData.data.id) {
          return element;
        } else {
          return { data: newData, errors: {} };
        }
      });
    });
    receptionModalProps.closeModale();
  };

  const computeProductionDto = () => {
    //  I have to check if changes have not been reported here in production form
    let info;
    if (formProduction.formikRef.current) {
      info = formProduction.formikRef.current.state.values;
    } else {
      info = tempWasteProductInfo;
    }

    if (!shouldDisplayProductionArray(info)) {
      setTempProductionContainer([]);
    }

    return createWasteProductionDto({
      formInfo: info,
      container: shouldDisplayProductionArray(info)
        ? tempProductionContainer
        : [],
    });
  };
  const computeReceptionDto = () => {
    //  I have to check if changes have not been reported here in reception form
    let info;
    if (formReception.formikRef.current) {
      info = formReception.formikRef.current.state.values;
    } else {
      info = tempWasteReceptionInfo;
    }

    return createWasteReceptionDto({
      formInfo: info,
      container: shouldDisplayReceptionArray(info)
        ? tempReceptionContainer
        : [],
    });
  };

  const shouldShowBottomSave =
    !_.isEqual(
      wasteData.validData.production.container,
      wasteData.tempData.production.container
    ) ||
    !_.isEqual(
      wasteData.validData.reception.container,
      wasteData.tempData.reception.container
    ) ||
    formProduction.hasChanges ||
    formReception.hasChanges;
  const isProductionValidated = isPathValidatedInDeclaration(
    PATH_DECHET_PRODUCTION
  );
  const isReceptionValidated = isPathValidatedInDeclaration(
    PATH_DECHET_RECEPTION
  );
  const hasValidatedAllBlocs =
    isProductionValidated && (isReceptionValidated || !isReceptionAvailable);

  // Warning, the dependencies used here make React run the effect ONLY for the first change
  useEffect(() => {
    if (formProduction.formikRef.current) {
      setTempWasteProductInfo(formProduction.formikRef.current.state.values);
    }
  }, [formProduction.hasChanges, formProduction.formikRef]);
  useEffect(() => {
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      setTempWasteReceptionInfo(currentFormReceptionRef.state.values);
    }
  }, [formReception.hasChanges, formReception.formikRef]);

  useEffect(() => {
    //because errors in reception array depend of the formReception, we want to recompute them (btw, putting the errors in the state was a mistake, as they're actually a COMPUTED value)
    const currentFormReceptionRef = formReception.formikRef.current;
    if (currentFormReceptionRef) {
      const newReceptionContainer = tempReceptionContainer.map(
        singleWasteReception => ({
          ...singleWasteReception,
          errors: generateReceivedWasteErrors(
            singleWasteReception.data,
            referentiels,
            typeActivite,
            currentFormReceptionRef.state.values
          ),
        })
      );

      if (!_.isEqual(newReceptionContainer, tempReceptionContainer)) {
        setTempReceptionContainer(newReceptionContainer);
      }
    }
  }, [formReception, referentiels, tempReceptionContainer, typeActivite]);

  const receivedWasteTab: SingleTabElemProps = {
    title: "Réception et traitement",
    renderComponent: () => {
      return (
        <PolluantReception
          formRef={formReception}
          wasteData={wasteData}
          validationPath={PATH_DECHET_RECEPTION}
          productionValidationPath={PATH_DECHET_PRODUCTION}
          importWastes={importReception}
          deleteWaste={waste => {
            const receivedCopy = Array.from(tempReceptionContainer);
            const newReception = deleteWasteFromContainer(waste, receivedCopy);
            setTempReceptionContainer(newReception);
          }}
          deleteAllWastes={() => {
            setTempReceptionContainer([]);
          }}
          openModal={currentData => {
            receptionModalProps.openModale(currentData);
          }}
          reset={() => {
            setTempReceptionContainer(receptionContainer);
            return wasteData.validData.reception.formInfo;
          }}
          computedProductionDto={computeReceptionDto}
          receptionSaveCompletion={declaration => {
            const value = convertReceptionFetchToLocal(
              declaration.body.sections.dechets.reception.receptionDechets,
              referentiels,
              typeActivite,
              receptionDechets
            );
            setTempReceptionContainer(value);
          }}
          hasChanges={values => {
            const isFormChange = !_.isEqual(values, receptionInfo);
            const isContainerChange = !_.isEqual(
              tempReceptionContainer,
              receptionContainer
            );
            const toReturn = isFormChange || isContainerChange;
            if (
              values.doesFacilityHaveAsbestosCases !==
              wasteData.tempData.reception.formInfo
                .doesFacilityHaveAsbestosCases
            ) {
              setTempWasteReceptionInfo(values);
            }
            return toReturn;
          }}
          onChange={formValues => {
            const previousState = shouldDisplayReceptionArray(
              wasteData.tempData.reception.formInfo
            );
            const currentState = shouldDisplayReceptionArray(formValues);
            if (previousState === currentState) {
              return;
            }

            setTempWasteReceptionInfo(formValues);
          }}
          onUnMount={() => {
            if (formReception.formikRef.current) {
              setTempWasteReceptionInfo(
                formReception.formikRef.current.state.values
              );
            }
          }}
        />
      );
    },
  };

  const tabsContent: SingleTabElemProps[] = [
    {
      title: "Production et expédition",
      renderComponent: () => {
        return (
          <PolluantProduction
            formRef={formProduction}
            wasteData={wasteData}
            validationPath={PATH_DECHET_PRODUCTION}
            showValidationMessage={isReceptionAvailable}
            importWastes={importProduction}
            deleteWaste={waste => {
              const productionCopy = Array.from(tempProductionContainer);
              const newProduction = deleteWasteFromContainer(
                waste,
                productionCopy
              );
              setTempProductionContainer(newProduction);
            }}
            deleteAllWastes={() => {
              setTempProductionContainer([]);
            }}
            openModal={currentData => {
              productionModalProps.openModale(currentData);
            }}
            reset={() => {
              setTempProductionContainer(productionContainer);
              return wasteData.validData.production.formInfo;
            }}
            computedProductionDto={computeProductionDto}
            productionSaveCompletion={declaration => {
              const value = convertProductionFetchToLocal(
                declaration.body.sections.dechets.production.productionDechet,
                referentiels
              );
              setTempProductionContainer(value);
            }}
            hasChanges={values => {
              const isFormChange = !_.isEqual(values, productionInfo);
              const isContainerChange = !_.isEqual(
                tempProductionContainer,
                productionContainer
              );
              return isFormChange || isContainerChange;
            }}
            onChange={formValues => {
              const previousState = shouldDisplayProductionArray(
                wasteData.tempData.production.formInfo
              );
              const currentState = shouldDisplayProductionArray(formValues);
              if (previousState === currentState) {
                return;
              }

              setTempWasteProductInfo(formValues);
            }}
            onUnMount={() => {
              if (formProduction.formikRef.current) {
                setTempWasteProductInfo(
                  formProduction.formikRef.current.state.values
                );
              }
            }}
          />
        );
      },
    },
  ];

  if (isReceptionAvailable) {
    tabsContent.push(receivedWasteTab);
  }

  const compareFunction = useCallback(
    (a: ComputedSyntheseElementDto, b: ComputedSyntheseElementDto): number => {
      const aDechet = findElementMatchingTemplate(
        { uuid: a.codeID },
        wasteData.referentiels.polluants
      );
      const bDechet = findElementMatchingTemplate(
        { uuid: b.codeID },
        wasteData.referentiels.polluants
      );
      if (aDechet && bDechet && aDechet.codeDechet !== bDechet.codeDechet) {
        return aDechet.codeDechet < bDechet.codeDechet ? -1 : 1;
      }
      return 0;
    },
    [wasteData.referentiels.polluants]
  );

  const sortedArrayElem = useMemo(() => {
    return _.cloneDeep(declaration.computed.sections.dechets.synthese).sort(
      compareFunction
    );
  }, [compareFunction, declaration.computed.sections.dechets.synthese]);

  return (
    <>
      <Tabs content={tabsContent} />

      <Row />

      {hasValidatedAllBlocs && (
        <WasteSyntheseArray
          lines={sortedArrayElem}
          referentials={wasteData.referentiels}
          isReceptionAvailable={isReceptionAvailable}
        />
      )}

      <Row />

      <WasteSumUpWrapper
        wasteData={wasteData}
        validationPath={PATH_DECHET_RESUME}
        isReceptionAvailable={isReceptionAvailable}
      />

      <DeclareWasteModale
        wasteData={wasteData}
        isOpen={productionModalProps.modaleState.isOpen}
        updatingWaste={productionModalProps.modaleState.elementToEdit}
        close={() => {
          productionModalProps.closeModale();
        }}
        validate={waste => {
          if (productionModalProps.modaleState.elementToEdit) {
            updateProductedWaste(
              productionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addDeclaredWaste(waste);
          }
        }}
        isOnSiteOptionAvailable={isReceptionAvailable}
      />

      <DeclareReceptionWasteModal
        wasteData={wasteData}
        isOpen={receptionModalProps.modaleState.isOpen}
        updatingWaste={receptionModalProps.modaleState.elementToEdit}
        aCasierAmiantes={
          wasteData.tempData.reception.formInfo.doesFacilityHaveAsbestosCases
        }
        isISDIChecked={wasteData.tempData.reception.formInfo.isISDI}
        isISDDChecked={wasteData.tempData.reception.formInfo.isISDD}
        isISDNDChecked={wasteData.tempData.reception.formInfo.isISDND}
        close={() => {
          receptionModalProps.closeModale();
        }}
        validate={waste => {
          if (receptionModalProps.modaleState.elementToEdit) {
            updateReceivedWaste(
              receptionModalProps.modaleState.elementToEdit,
              waste
            );
          } else {
            addReceivedWaste(waste);
          }
        }}
      />

      <GlobalFormActionFullContext2022
        validationTitle={"VALIDER PAGE >"}
        validationMessage={{
          message: DEFAULT_VALIDATION_MESSAGE,
          isAlwaysVisible: false,
        }}
        hasChanges={shouldShowBottomSave}
        isValidateEnabled={hasValidatedAllBlocs}
        validationPath={PATH_DECHET}
        updateHandler={declaration => {
          declaration.body.sections.dechets.production = computeProductionDto();
          declaration.body.sections.dechets.reception = computeReceptionDto();
          return declaration;
        }}
        cancelAction={() => {
          setTempWasteProductInfo(productionInfo);
          setTempProductionContainer(productionContainer);

          setTempWasteReceptionInfo(receptionInfo);
          setTempReceptionContainer(receptionContainer);

          if (formProduction.formikRef.current) {
            formProduction.formikRef.current.resetForm(productionInfo);
          }
          if (formReception.formikRef.current) {
            formReception.formikRef.current.resetForm(receptionInfo);
          }
        }}
      />
    </>
  );
};

export default WasteController;
