import { arrayUnits } from "./selectPossibleValues";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ProcedeInArray } from "../../listprocede/utils/types";
import { EmissionsInModale } from "./types";
import {
  AirFugitivesEmissionCorrelationDtoUniteEnum,
  AirFugitivesProcedesDtoUniteEnum,
} from "api/gen";

export const getAllProcedes = (initialProcedes: ProcedeInArray[]) => {
  return initialProcedes.map((procede, i) => {
    if (!procede.data.name) {
      procede.data.name = "none";
    }
    return {
      value: procede.data.name,
      label: procede.data.name,
      object: { ...procede },
    };
  });
};

export const getSelectedProcede = (
  value: OptionPropsWithObject<ProcedeInArray, string>[],
  initialProcedes: ProcedeInArray[]
) => {
  if (initialProcedes && value && value[0]) {
    const procede = initialProcedes.find(procede => {
      return procede.data.name === value[0].label;
    });
    if (procede) {
      return procede;
    }
  }
  return "";
};

export const getQuantityValue = (
  value: OptionPropsWithObject<ProcedeInArray, string>[],
  initialProcedes: ProcedeInArray[]
) => {
  const selectedProcede = getSelectedProcede(value, initialProcedes);
  if (selectedProcede) {
    return selectedProcede.data.quantity || 0;
  }
  return 0;
};

export const getMasseVolumiqueValue = (
  value: OptionPropsWithObject<ProcedeInArray, string>[],
  initialProcedes: ProcedeInArray[]
) => {
  const selectedProcede = getSelectedProcede(value, initialProcedes);
  if (selectedProcede) {
    return selectedProcede.data.density;
  }
  return null;
};

export const getUnitLabel = (
  value: OptionPropsWithObject<ProcedeInArray, string>[],
  initialProcedes: ProcedeInArray[]
): string => {
  //Permet de get la bonne unité a afficher en fonciton de si elle a été precisé en choisissant "Autre" ou non.
  const selectedProcede = getSelectedProcede(value, initialProcedes);
  if (selectedProcede && selectedProcede.data.unit) {
    if (selectedProcede.data.unit.label === "Autre") {
      return selectedProcede.data.unitPrecision || "";
    }
    return selectedProcede.data.unit.label;
  }
  return "";
};

export const getUnitProcede = (
  value: OptionPropsWithObject<ProcedeInArray, string>[],
  initialProcedes: ProcedeInArray[]
) => {
  const selectedProcede = getSelectedProcede(value, initialProcedes);
  if (selectedProcede) {
    return selectedProcede.data.unit;
  }
};

export const getUnitCorrelationOptions = (
  values: EmissionsInModale,
  initialProcedes: ProcedeInArray[]
) => {
  //Retournes les bonnes options en fonction de l'unite du procédé choisi
  if (values.procede) {
    const unitProcede = getUnitProcede(values.procede, initialProcedes);
    if (unitProcede) {
      if (unitProcede.object.code === AirFugitivesProcedesDtoUniteEnum.AUTRE) {
        return [
          {
            value: 7,
            label: `kg/${values.procede[0].object.data.unitPrecision}`,
            object: {
              code: AirFugitivesEmissionCorrelationDtoUniteEnum.AUTRE,
              name: `kg/${values.procede[0].object.data.unitPrecision}`,
            },
          },
        ];
      } else {
        return arrayUnits;
      }
    }
  }

  return [];
};
