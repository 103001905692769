import * as Yup from "yup";
import { commonStringFields } from "common/declarant/formik/formikHelper";
import { QuotasInfosComplementairesInstallationsDto2021CategorieEnum } from "api/gen";
import { requiredMessage } from "common/declarant/formik/formikMessages";

export const validationSchema = Yup.object().shape({
  installations: Yup.array().of(
    Yup.object().shape({
      nimDynId: commonStringFields,
      categorie: Yup.mixed<
        QuotasInfosComplementairesInstallationsDto2021CategorieEnum
      >().required(requiredMessage),
    })
  ),
});
