import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  useBooleanCheckBoxGenerator,
  useDummyNumberFieldGenerator,
  useDummyTextFieldGenerator,
  useNumberFieldGenerator,
  useTextFieldGenerator,
} from "common/form/fields/helpers/generators";
import { FormikActions } from "libAdapter/Formik/TypesPatternAdaptater";
import { FieldArray } from "libAdapter/Formik/FieldComponentAdaptater";
import {
  DYNAMIC_ELEMENTS_STYLE,
  LEFT_WITHDRAW_STYLE,
  LONG_TEXT_INPUT_WIDTH,
  SECTION_TITLE_GREY,
} from "theme";
import {
  additionalValidations,
  anySubPartActivated,
  correlationSubPartActivated,
  matiereSubPartActivated,
  mesureSubPartActivated,
  singleEmissionOverwriteDependantFields,
  singleEmissionValidationSchema,
} from "./validation/validation";
import CommonFormSingleEntity from "common/declarant/CommonFormSingleEntity";
import MessageInfoField from "common/form/MessageInfoField";
import { methodLabels, methodList } from "./utils/selectPossibleValues";
import { valeursConcentrationMessage } from "common/declarant/formik/formikMessages";
import { computeEmissionAnnuelle } from "./utils/calculus";
import {
  computeUnitCorrelationLabel,
  computeUniteProcedeLabel,
  getUnitCorrelationList,
} from "./utils/gettersFunctions";
import { WrappedChoiceSelectModale } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelectModale";
import {
  ReferenceItemPolluantDto,
  ReferenceItemPolluantElementDto,
} from "api/gen";
import { isSearchStringInCollection } from "common/utils/methods";
import { generateWarningMessageIfUnder1Percent } from "common/utils/warningMessage";
import {
  procedeFieldMatcher,
  ProcedeInArray,
} from "../ListProcede/utils/types";
import {
  computeNumberWithSeparator,
  getValueOrZero,
} from "common/utils/numberUtils";
import {
  concentrationFieldMatcher,
  CorrelationEmissionInArray,
  debitFieldMatcher,
  emissionFieldMatcher,
  EmissionsInArray,
  EmissionsInModale,
  intrantFieldMatcher,
  MatiereEmissionInArray,
  MesureEmissionInArray,
  sortantFieldMatcher,
} from "./utils/types";
import { elementTooltip } from "./utils/utils";
import Row from "common/presentational/Row";
import Button from "common/button";
import {
  computeEmissionAnnuelleForIntrantSortant,
  createNewIntrantSortant,
} from "../../combustionProcedeUtils/intrantsSortants/intrantsSortantsUtils";
import SimpleInputWithTextLabelDisplayer from "common/form/fields/displayers/SimpleInputWithTextLabelDisplayer";
import { isCo2 } from "../../combustionProcedeUtils/biomasse/helpers";
import { WrappedChoiceSelect } from "common/form/fields/wrappedConnectedInput/WrappedChoiceSelect";
import { getProcedeName, getSingleProcedeInMapById } from "../utils/utils";
import {
  getAssociatedPolluantElementsForPolluantWithAutreLastPosition,
  isElementSelectedAutre,
  shouldElementNameBeFilled,
  shouldPartBeFilled,
} from "../../combustionProcedeUtils/bilanMatiere/elementsHelpers";
import {
  arrayFugitivesConcentrationUnits,
  unitConcentrationMoyLabels,
} from "../utils/selectPossibleUnits";
import { DummyChoiceSelectWithLabel } from "common/form/fields/dumbInput/DummyChoiceSelectWithLabel";

interface FormEmissionProps {
  closeFunction: () => void;
  onSubmit: (
    values: EmissionsInModale,
    formikActions: FormikActions<EmissionsInModale>
  ) => void;
  initialEmission: EmissionsInModale;
  isEdit: boolean;
  allowedCouples: EmissionsInArray | null;
  procedesInPageMap: Map<string, ProcedeInArray>;
  correlationEmissionsInPage: CorrelationEmissionInArray[];
  matiereEmissionsInPage: MatiereEmissionInArray[];
  mesureEmissionsInPage: MesureEmissionInArray[];
  substances: ReferenceItemPolluantDto[];
  referentialCO2: ReferenceItemPolluantDto;
  polluantElementMap: Map<number, Map<string, ReferenceItemPolluantElementDto>>;
  autreElementUuid: string;
}

const useStyles = makeStyles({
  inputField: {
    marginBottom: "5px",
    display: "flex",
  },
  longField: {
    width: LONG_TEXT_INPUT_WIDTH,
  },
  calculatedField: {
    backgroundColor: "white",
    color: SECTION_TITLE_GREY,
    border: `1px solid ${SECTION_TITLE_GREY}`,
    "& input": {
      backgroundColor: "white",
    },
  },
  list: {
    listStyle: "circle",
    "& li": {
      marginLeft: "30px",
    },
  },
  mBottom: {
    marginBottom: "5px",
  },
  ...LEFT_WITHDRAW_STYLE,
  ...DYNAMIC_ELEMENTS_STYLE,
});

const FormEmission = ({
  closeFunction,
  onSubmit,
  initialEmission,
  isEdit,
  allowedCouples,
  procedesInPageMap,
  correlationEmissionsInPage,
  matiereEmissionsInPage,
  mesureEmissionsInPage,
  substances,
  referentialCO2,
  polluantElementMap,
  autreElementUuid,
}: FormEmissionProps): React.ReactElement => {
  const classes = useStyles();
  const commonProps = {
    disabled: false,
    className: classes.inputField,
    labelWidth: "50%",
    formPrefix: "bloc-emission-procedes",
  };

  const TextField = useTextFieldGenerator(commonProps, classes.longField);
  const DummyTextField = useDummyTextFieldGenerator(
    commonProps,
    classes.longField
  );
  const DummyNumberField = useDummyNumberFieldGenerator(commonProps);
  const NumberField = useNumberFieldGenerator(commonProps);
  const BooleanField = useBooleanCheckBoxGenerator(commonProps);

  const initialCorrelationProcede = correlationSubPartActivated(
    initialEmission.methods
  )
    ? getSingleProcedeInMapById(
        initialEmission.procedes && initialEmission.procedes[0],
        procedesInPageMap
      )
    : null;

  const [
    correlationProcede,
    setCorrelationProcede,
  ] = useState<ProcedeInArray | null>(initialCorrelationProcede);

  const procedeIdList: string[] = Array.from(procedesInPageMap.keys());

  return (
    <CommonFormSingleEntity
      title="AJOUTER UNE ÉMISSION"
      closeFunction={closeFunction}
      isEdit={isEdit}
      onSubmit={(values: EmissionsInModale, formikBag) => {
        if (values.procedes === null) {
          throw Error(
            "la validation devrait obliger la sélection d'un procédé"
          );
        }
        singleEmissionOverwriteDependantFields(values, referentialCO2);
        onSubmit(values, formikBag);
      }}
      initialEntity={initialEmission}
      validationSchema={() => singleEmissionValidationSchema(referentialCO2)}
      validate={values =>
        additionalValidations(
          values,
          correlationProcede,
          allowedCouples,
          mesureEmissionsInPage,
          correlationEmissionsInPage,
          polluantElementMap,
          autreElementUuid
        )
      }
      renderField={({ values, setFieldValue, setFieldTouched }) => {
        const onSelectSubstance = () => {
          // Reset elementProps
          values.elementProps = null;
        };

        const handleOnChangeUniteCorrelationWithoutChangingProcede = () => {
          setFieldValue(emissionFieldMatcher.uniteCorrelation, null);
          setFieldTouched(emissionFieldMatcher.uniteCorrelation, false);
        };

        const handleOnChangeUniteCorrelationChangingProcede = (
          procedeValue: string | null
        ) => {
          const selectedProcede = getSingleProcedeInMapById(
            procedeValue,
            procedesInPageMap
          );
          setCorrelationProcede(selectedProcede);
          handleOnChangeUniteCorrelationWithoutChangingProcede();
          procedeValue
            ? setFieldValue(emissionFieldMatcher.procedes, [procedeValue])
            : setFieldValue(emissionFieldMatcher.procedes, []);
        };
        return (
          <>
            <WrappedChoiceSelectModale
              name={emissionFieldMatcher.substance}
              label="Substance *"
              title="SUBSTANCES DANS L'AIR"
              header={["CAS", "Libellé", "Seuil (kg/an)"]}
              linesData={substances}
              formatLine={substance => [
                substance.cas || "",
                substance.nom,
                substance.seuilKgAn !== null
                  ? computeNumberWithSeparator(substance.seuilKgAn)
                  : "",
              ]}
              formatSelectedTitle={lineData => lineData.nom}
              isLineInSearch={(lineData, searchedStr) =>
                isSearchStringInCollection(
                  [lineData.cas, lineData.nom],
                  searchedStr
                )
              }
              preferredColWidths={[80, 350, 80]}
              isFirstSticky={false}
              commonProps={commonProps}
              onSelect={onSelectSubstance}
            />
            <WrappedChoiceSelect
              name={emissionFieldMatcher.methods}
              label="Choix de la méthode *"
              isMulti={false}
              options={methodList}
              computeLabel={method => methodLabels[method]}
              commonProps={commonProps}
            />
            {(mesureSubPartActivated(values.methods) ||
              matiereSubPartActivated(values.methods)) && (
              <WrappedChoiceSelect
                isMulti={true}
                name={emissionFieldMatcher.procedes}
                // When value === 2, it is Facteur de correlation
                label={"Procédé(s) *"}
                options={procedeIdList}
                additionalOnChange={() =>
                  handleOnChangeUniteCorrelationWithoutChangingProcede()
                }
                computeLabel={procedeId =>
                  getProcedeName(procedeId, procedesInPageMap) || ""
                }
                commonProps={commonProps}
              />
            )}
            {correlationSubPartActivated(values.methods) && (
              <DummyChoiceSelectWithLabel<string, false>
                name={emissionFieldMatcher.procedes}
                label={"Procédé *"}
                isMulti={false}
                options={procedeIdList}
                value={
                  values.procedes && values.procedes.length !== 0
                    ? values.procedes[0]
                    : null
                }
                additionalOnChange={e =>
                  handleOnChangeUniteCorrelationChangingProcede(e)
                }
                computeLabel={procedeId =>
                  getProcedeName(procedeId, procedesInPageMap) || ""
                }
                commonProps={commonProps}
              />
            )}
            {mesureSubPartActivated(values.methods) && (
              <>
                <NumberField
                  name={emissionFieldMatcher.debitHoraire}
                  label="Débit horaire *"
                  unit="Nm³/h"
                />
                <NumberField
                  name={emissionFieldMatcher.heureFonctionnement}
                  label="Nombre d'heures de fonctionnement *"
                  unit="h/an"
                  tooltipContent="Le nombre d’heures de fonctionnement doit être calculé conformément à la décision 2012/249/UE du 7 mai 2012 concernant la détermination des périodes de démarrage et d’arrêt aux fins de la directive 2010/75/UE du Parlement européen et du Conseil relative aux émissions industrielles."
                />
                <BooleanField
                  name={`${emissionFieldMatcher.debit}.${debitFieldMatcher.mesureContinueDebit}`}
                  label="Mesure en continu du débit"
                />
                {!(values.debit && values.debit.continu === true) && (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name={`${emissionFieldMatcher.debit}.${debitFieldMatcher.frequenceMesureDebit}`}
                      label="Nombre de mesures *"
                      unit="mesures/an"
                    />
                  </div>
                )}
                <NumberField
                  name={emissionFieldMatcher.concentrationMoyenne}
                  label="Concentration moyenne *"
                  unit=""
                />
                <WrappedChoiceSelect
                  name={emissionFieldMatcher.concentrationMoyenneUnit}
                  label="Unité de la concentration moyenne *"
                  isMulti={false}
                  options={arrayFugitivesConcentrationUnits}
                  computeLabel={unit => unitConcentrationMoyLabels[unit]}
                  commonProps={commonProps}
                />
                <BooleanField
                  name={`${emissionFieldMatcher.concentration}.${concentrationFieldMatcher.mesureContinueConcentration}`}
                  label="Mesure en continu de la concentration"
                />
                {values.concentration &&
                values.concentration.continu === true ? (
                  <MessageInfoField message={valeursConcentrationMessage} />
                ) : (
                  <div className={classes.withdrawLeft}>
                    <NumberField
                      name={`${emissionFieldMatcher.concentration}.${concentrationFieldMatcher.frequenceMesureConcentration}`}
                      label="Nombre de mesures *"
                      unit="mesures/an"
                    />
                  </div>
                )}
              </>
            )}
            {correlationSubPartActivated(values.methods) && (
              <>
                <DummyNumberField
                  name={""}
                  label="Quantité de matière première ou volume d'activité"
                  disabled
                  additionalClassName={classes.calculatedField}
                  value={
                    correlationProcede &&
                    getValueOrZero(correlationProcede.data.quantite)
                  }
                  unit=""
                />
                <DummyTextField
                  name={""}
                  label="Unité"
                  disabled
                  additionalClassName={classes.calculatedField}
                  value={computeUniteProcedeLabel(correlationProcede)}
                />
                <TextField
                  name={emissionFieldMatcher.natureCorrelation}
                  label="Nature de la corrélation"
                  tooltipContent="Préciser la corrélation qu'elle soit simple ou multiple.
                  Exemple de corrélation simple : x kg de NOx émis pour 1 tonne d'acier produit."
                />
                <NumberField
                  name={emissionFieldMatcher.facteurCorrelation}
                  label="Facteur de corrélation *"
                  unit=""
                />
                <WrappedChoiceSelect
                  name={emissionFieldMatcher.uniteCorrelation}
                  label="Unité du facteur de corrélation *"
                  isMulti={false}
                  options={getUnitCorrelationList(correlationProcede)}
                  computeLabel={unit =>
                    computeUnitCorrelationLabel(unit, correlationProcede) || ""
                  }
                  commonProps={commonProps}
                />
                <DummyNumberField
                  name={""}
                  label="Masse volumique de la quantité de matière première ou production"
                  disabled
                  unit="kg/l = t/m³"
                  value={
                    correlationProcede &&
                    correlationProcede.data[procedeFieldMatcher.density]
                  }
                />
                <TextField
                  name={emissionFieldMatcher.provenanceFacteurCorrelation}
                  label="Provenance du facteur de corrélation *"
                />
              </>
            )}
            {matiereSubPartActivated(values.methods) && (
              <>
                {shouldElementNameBeFilled(values, polluantElementMap) && (
                  <TextField
                    name={emissionFieldMatcher.elementName}
                    label="Élément sur lequel est indexé le bilan matière *"
                    tooltipContent={elementTooltip}
                  />
                )}
                {!shouldElementNameBeFilled(values, polluantElementMap) && (
                  <>
                    <WrappedChoiceSelect
                      name={emissionFieldMatcher.elementProps}
                      label="Élément sur lequel est indexé le bilan matière *"
                      isMulti={false}
                      options={getAssociatedPolluantElementsForPolluantWithAutreLastPosition(
                        values.substance,
                        polluantElementMap,
                        autreElementUuid
                      )}
                      computeLabel={elem =>
                        elem.referenceItemElementIndexDto.nom
                      }
                      commonProps={commonProps}
                      tooltipContent={elementTooltip}
                    />
                    {isElementSelectedAutre(
                      values.elementProps,
                      autreElementUuid
                    ) && (
                      <TextField
                        name={emissionFieldMatcher.elementName}
                        label="Préciser autre *"
                      />
                    )}
                  </>
                )}
                {shouldPartBeFilled(
                  values,
                  polluantElementMap,
                  autreElementUuid
                ) && (
                  <>
                    <NumberField
                      name={emissionFieldMatcher.partElement}
                      label="Part de l'élément dans la substance émise *"
                      unit="%"
                      tooltipContent={
                        <span>
                          <p className={classes.mBottom}>
                            Exemple : Dans le cas de la détermination des
                            émissions de SO<sub>2</sub> issues de la combustion
                            de fuel lourd ou de charbon, préciser la part
                            molaire du soufre sur lequel est indexé le calcul
                            dans la masse molaire totale du SO<sub>2</sub> soit
                            50,05 % (détail : 100 * Masse molaire de S / Masse
                            molaire de SO
                            <sub>2</sub> = 100 * 32,066 / 64,065 = 50,05 %).
                          </p>
                          <p className={classes.mBottom}>
                            Aide : Principales masses molaires des éléments et
                            composés :
                            <ul className={classes.list}>
                              <li>
                                SO<sub>2</sub> dont la part de S est de 50,05 %
                                (32,066 / 64,065)
                              </li>
                              <li>
                                CO<sub>2</sub> dont la part de C est de 27,29 %
                                (12,011 / 44,010)
                              </li>
                              <li>
                                HCl dont la part de Cl est de 97,26 % (35,463 /
                                36,461)
                              </li>
                              <li>
                                HF dont la part de F est de 94,96 % (18,998 /
                                20,006)
                              </li>
                              <li>
                                voire les métaux lourds pour lesquels le ratio
                                est de 1.
                              </li>
                            </ul>
                          </p>
                          <p>
                            Remarque : il est essentiel pour les gros tonnages,
                            particulièrement ceux de CO<sub>2</sub>, que les
                            ratios utilisés soient le plus précis possibles tant
                            les écarts dûs aux arrondis peuvent rapidement être
                            convertis en des tonnages non négligeables.
                          </p>
                        </span>
                      }
                    />
                    {generateWarningMessageIfUnder1Percent(values.part)}
                  </>
                )}
                {!shouldPartBeFilled(
                  values,
                  polluantElementMap,
                  autreElementUuid
                ) && (
                  <DummyNumberField
                    name={emissionFieldMatcher.partElement}
                    label="Part de l'élément dans la substance émise"
                    value={
                      values.elementProps &&
                      100 * values.elementProps.partElement
                    }
                    unit="%"
                    disabled
                  />
                )}

                <>
                  <Row />
                  <FieldArray
                    name={emissionFieldMatcher.intrants}
                    render={arrayHelpers => (
                      <div>
                        {values.intrants &&
                          values.intrants.map((intrant, index) => (
                            <div key={index} className={classes.itemWrapper}>
                              <TextField
                                label={`Descriptif de l'intrant ${index + 1} *`}
                                name={`${emissionFieldMatcher.intrants}.${index}.${intrantFieldMatcher.descriptif}`}
                              />
                              <NumberField
                                label={`Quantité de l'intrant ${index + 1} *`}
                                name={`${emissionFieldMatcher.intrants}.${index}.${intrantFieldMatcher.quantite}`}
                                unit="kg"
                              />
                              <NumberField
                                label={`Teneur moyenne de l'élément dans l'intrant ${index +
                                  1} *`}
                                name={`${emissionFieldMatcher.intrants}.${index}.${intrantFieldMatcher.teneurMoyenne}`}
                                unit="%"
                              />
                              {generateWarningMessageIfUnder1Percent(
                                intrant.teneurMoyenne
                              )}
                              <DummyNumberField
                                name={""}
                                label="Émissions annuelles"
                                unit="kg/an"
                                disabled
                                additionalClassName={classes.calculatedField}
                                value={computeEmissionAnnuelleForIntrantSortant(
                                  intrant
                                )}
                              />
                              {values.intrants && values.intrants.length > 1 && (
                                <Row>
                                  <div className={classes.removeButtonRow}>
                                    <div
                                      className={classes.removeButtonWrapper}
                                    >
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        additionalClassname={
                                          classes.removeButton
                                        }
                                        text="Supprimer l'intrant"
                                      />
                                    </div>
                                  </div>
                                </Row>
                              )}

                              <Row />
                            </div>
                          ))}
                        <SimpleInputWithTextLabelDisplayer
                          label="Ajouter un nouvel intrant"
                          name="new-intrant"
                          commonProps={commonProps}
                          errorData={{}}
                          renderInput={() => (
                            <button
                              type="button"
                              onClick={() => {
                                arrayHelpers.push(createNewIntrantSortant());
                              }}
                              className={classes.iconButton}
                            >
                              +
                            </button>
                          )}
                        />
                      </div>
                    )}
                  />
                  <Row />
                </>

                <>
                  <Row />
                  <FieldArray
                    name={emissionFieldMatcher.sortants}
                    render={arrayHelpers => (
                      <div>
                        {values.sortants &&
                          values.sortants.map((sortant, index) => (
                            <div key={index} className={classes.itemWrapper}>
                              <TextField
                                label={`Descriptif du sortant ${index + 1} *`}
                                name={`${emissionFieldMatcher.sortants}.${index}.${sortantFieldMatcher.descriptif}`}
                              />
                              <NumberField
                                label={`Quantité du sortant ${index + 1} *`}
                                name={`${emissionFieldMatcher.sortants}.${index}.${sortantFieldMatcher.quantite}`}
                                unit="kg"
                                tooltipContent={
                                  <span>
                                    Il convient ici de recenser toute quantité
                                    exportée ou sortant des limites de
                                    l'installation autrement que sous forme
                                    atmosphérique (produits, matériaux, mises à
                                    l'égout ou en décharge, épuration, pertes)
                                    et les variations des stocks dans les
                                    limites de l'installation.
                                  </span>
                                }
                              />
                              <NumberField
                                label={`Teneur moyenne de l'élément dans le sortant ${index +
                                  1} *`}
                                name={`${emissionFieldMatcher.sortants}.${index}.${sortantFieldMatcher.teneurMoyenne}`}
                                unit="%"
                              />
                              <DummyNumberField
                                name={""}
                                label="Émissions annuelles"
                                unit="kg/an"
                                disabled
                                additionalClassName={classes.calculatedField}
                                value={computeEmissionAnnuelleForIntrantSortant(
                                  sortant
                                )}
                              />
                              {values.sortants && values.sortants.length >= 1 && (
                                <Row>
                                  <div className={classes.removeButtonRow}>
                                    <div
                                      className={classes.removeButtonWrapper}
                                    >
                                      <Button
                                        type="button"
                                        onClick={() => {
                                          arrayHelpers.remove(index);
                                        }}
                                        additionalClassname={
                                          classes.removeButton
                                        }
                                        text="Supprimer le sortant"
                                      />
                                    </div>
                                  </div>
                                </Row>
                              )}

                              <Row />
                            </div>
                          ))}
                        <SimpleInputWithTextLabelDisplayer
                          label="Ajouter un nouveau sortant"
                          name="new-sortant"
                          commonProps={commonProps}
                          errorData={{}}
                          renderInput={() => (
                            <button
                              type="button"
                              onClick={() => {
                                arrayHelpers.push(createNewIntrantSortant());
                              }}
                              className={classes.iconButton}
                            >
                              +
                            </button>
                          )}
                        />
                      </div>
                    )}
                  />
                  <Row />
                </>
              </>
            )}
            {anySubPartActivated(values.methods) && (
              <>
                {isCo2(values.substance, referentialCO2) && (
                  <>
                    <NumberField
                      name={emissionFieldMatcher.biomasse}
                      label="Fraction de la biomasse (%) *"
                      unit="%"
                    />
                    {generateWarningMessageIfUnder1Percent(values.biomasse)}
                  </>
                )}
                <BooleanField
                  name={emissionFieldMatcher.epuration}
                  label="Les émissions font-elles l'objet d'épuration ?"
                />
                {values.epuration === true && (
                  <div className={classes.withdrawLeft}>
                    <TextField
                      name={emissionFieldMatcher.natureEquipement}
                      label="Nature des équipements *"
                      tooltipContent="Si plusieurs équipements existent, il convient de les séparer d'une virgule."
                    />
                    <NumberField
                      name={emissionFieldMatcher.rendementEpuration}
                      label="Rendement de l'épuration *"
                      unit="%"
                      tooltipContent="Le rendement global d'épuration pour tous les équipements est attendu."
                    />
                    {generateWarningMessageIfUnder1Percent(values.rendement)}
                  </div>
                )}
                <DummyNumberField
                  name={""}
                  label="Émissions annuelles"
                  unit="kg/an"
                  disabled
                  additionalClassName={classes.calculatedField}
                  value={computeEmissionAnnuelle(
                    values,
                    procedesInPageMap,
                    autreElementUuid
                  )}
                />
              </>
            )}
          </>
        );
      }}
    />
  );
};

export default FormEmission;
