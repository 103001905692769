import { AlcDto20Now, FileDto } from "api/gen";

export const alcDtoToAlcFileDto = (
  installationId: string,
  alcs: AlcDto20Now[]
): FileDto[] => {
  const currentAlc = alcs.find(alc => alc.nimDynId === installationId);
  if (currentAlc !== undefined && currentAlc.fichier !== null) {
    return [currentAlc.fichier];
  } else {
    return [];
  }
};

export const alcDtoToJustificatifsFilesDto = (
  installationId: string,
  alcs: AlcDto20Now[]
): FileDto[] => {
  const currentAlc = alcs.find(alc => alc.nimDynId === installationId);
  if (currentAlc !== undefined) {
    return currentAlc.documentsJustificatifs;
  } else {
    return [];
  }
};
