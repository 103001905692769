import React, { useMemo, useState } from "react";
import Row from "common/presentational/Row";
import SyntheseModale from "./SyntheseModale";
import SyntheseArray from "./SyntheseArray";
import Spinner from "common/presentational/Spinner";
import {
  convertEmissionToDisplayedEmission,
  createAirSyntheseDto,
} from "./utils/converter";
import {
  biomasseString,
  createFullSynthese,
  nonBiomasseString,
} from "./utils/utils";
import { isEqual } from "lodash";
import {
  AirSyntheseEmissionDto,
  ComputedAirSyntheseEmissionDto1823,
  ReferenceItemPolluantDto,
  ReferenceNormeAirDto,
  ReferencePolluantDto,
} from "api/gen";
import uuid from "uuid";
import { SyntheseInArray } from "./utils/types";
import { PATH_AIR_SYNTHESE } from "common/path/path18Now";
import { useDeclaration1919 } from "../../versionedElements/declarationHooks1919";
import BlocFullContext1919 from "../../../to19/versionedElements/BlocFullContext1919";

const getEmissionInBody = (
  substance: ComputedAirSyntheseEmissionDto1823,
  emissions: AirSyntheseEmissionDto[]
): AirSyntheseEmissionDto | undefined => {
  const results = emissions.find(emissionName => {
    return (
      emissionName.substanceID === substance.substanceID &&
      emissionName.biomasse === substance.biomasse
    );
  });

  if (!results) {
    return {
      accidentel: null,
      additionnel: null,
      description: null,
      methode: null,
      normesID: [],
      origine: null,
      referenceCalcule: null,
      referenceMesure: null,
      substanceID: null,
      biomasse: false,
      id: uuid(),
    };
  }

  return results;
};

export const computePolluantSyntheseLibelle = (
  substance: ReferenceItemPolluantDto | null,
  isBiomasse: boolean | null,
  referentialCO2: ReferenceItemPolluantDto
): string => {
  if (substance === null) {
    return "";
  }
  if (substance.uuid !== referentialCO2.uuid) {
    return substance.nom;
  }
  if (isBiomasse) {
    return `${substance.nom}${biomasseString}`;
  }
  return `${substance.nom}${nonBiomasseString}`;
};

interface MainSyntheseFormProps {
  referentialAIR: ReferencePolluantDto;
  referentialNORME: ReferenceNormeAirDto;
  referentialCO2: ReferenceItemPolluantDto;
}

const MainSyntheseForm = ({
  referentialAIR,
  referentialNORME,
  referentialCO2,
}: MainSyntheseFormProps): React.ReactElement => {
  const declaration = useDeclaration1919();
  const blocSynthesesDto = declaration.body.sections.air.synthese;

  const airSyntheseEmissionComputed =
    declaration.computed.sections.air.synthese.emissions;
  const memoTempSynthese = useMemo(() => {
    const result: SyntheseInArray[] = [];
    airSyntheseEmissionComputed.forEach(emissionComputed => {
      const emissionInBody = getEmissionInBody(
        emissionComputed,
        blocSynthesesDto.emissions
      );
      if (emissionInBody) {
        result.push(
          convertEmissionToDisplayedEmission(
            emissionInBody,
            emissionComputed,
            referentialAIR.polluants,
            referentialNORME.referenceItemNormeAirDtoList
          )
        );
      }
    });
    return result;
  }, [
    airSyntheseEmissionComputed,
    blocSynthesesDto.emissions,
    referentialAIR.polluants,
    referentialNORME.referenceItemNormeAirDtoList,
  ]);

  const initialSynthese = createFullSynthese(memoTempSynthese, referentialAIR);

  const [synthesesInPage, setSynthesesInPage] = useState<
    SyntheseInArray[] | null
  >(initialSynthese);

  const [
    syntheseInModale,
    setSyntheseInModale,
  ] = useState<SyntheseInArray | null>(null);

  const canSubmitForm = () => {
    //checking only synthese.data.method because the data is sent by the backend and the only required field is synthese.data.method
    return (
      !!synthesesInPage &&
      synthesesInPage.every(synthese => {
        return !!synthese.data.method;
      })
    );
  };

  const compareFunction = (a: SyntheseInArray, b: SyntheseInArray): number => {
    if (
      a.data.substance &&
      b.data.substance &&
      a.data.substance.nom !== b.data.substance.nom
    ) {
      return a.data.substance.nom < b.data.substance.nom ? -1 : 1;
    }
    return 0;
  };

  return (
    <>
      {synthesesInPage ? (
        <>
          <BlocFullContext1919
            title=""
            hasModification={!isEqual(initialSynthese, synthesesInPage)}
            areButtonsInside={false}
            isValidateButtonAvailable={canSubmitForm()}
            path={PATH_AIR_SYNTHESE}
            updateHandler={declaration => {
              declaration.body.sections.air.synthese = createAirSyntheseDto(
                synthesesInPage,
                referentialAIR.polluants,
                referentialNORME.referenceItemNormeAirDtoList
              );
              return declaration;
            }}
            cancelAction={() => setSynthesesInPage(initialSynthese)}
            renderContent={shouldDisabledFields => {
              return (
                <>
                  <SyntheseArray
                    synthesesInArray={synthesesInPage.sort(compareFunction)}
                    setSyntheseInModale={setSyntheseInModale}
                    isValidated={shouldDisabledFields}
                    validationPath={PATH_AIR_SYNTHESE}
                    referentialAIR={referentialAIR}
                    referentialCO2={referentialCO2}
                  />
                  <Row height={"20px"} />
                </>
              );
            }}
          />
          {syntheseInModale && (
            <SyntheseModale
              syntheseInModale={syntheseInModale}
              synthesesInPage={synthesesInPage}
              setSynthesesInPage={setSynthesesInPage}
              setSyntheseInModale={setSyntheseInModale}
              referentialNORME={referentialNORME}
              referentialCO2={referentialCO2}
            />
          )}
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default MainSyntheseForm;
