import { isNil } from "lodash";
import {
  computeRequiredPositiveNumberErrorMessage,
  computeRequiredStringErrorMessage,
} from "common/declarant/formik/formikValidationHelper";
import { Errors } from "common/form/utils";
import { BlocCalculatedEmissionFormType } from "../utils/types";
import { isCh4MethodAutre, isCo2MethodAutre } from "./utils";

export const validateBlocCalculatedEmission = (
  values: BlocCalculatedEmissionFormType
): Errors<BlocCalculatedEmissionFormType> => {
  const errors: Errors<BlocCalculatedEmissionFormType> = {};
  const co2Values = [
    values.amountCo2Caught,
    values.amountCo2Generated,
    values.amountCo2Total,
    values.co2Method,
  ];
  const ch4Values = [
    values.amountCh4Caught,
    values.amountCh4Generated,
    values.amountCh4Total,
    values.ch4Method,
  ];
  const isCo2Filled = co2Values.some(
    (value: number | string | null) => !isNil(value)
  );
  const isCh4Filled = ch4Values.some(
    (value: number | string | null) => !isNil(value)
  );

  if (isCh4Filled) {
    errors.amountCh4Caught = computeRequiredPositiveNumberErrorMessage(
      values.amountCh4Caught
    );
    errors.amountCh4Generated = computeRequiredPositiveNumberErrorMessage(
      values.amountCh4Generated
    );
    errors.amountCh4Total = computeRequiredPositiveNumberErrorMessage(
      values.amountCh4Total
    );
    errors.ch4Method = computeRequiredStringErrorMessage(values.ch4Method);
  }
  if (isCo2Filled) {
    errors.amountCo2Caught = computeRequiredPositiveNumberErrorMessage(
      values.amountCo2Caught
    );
    errors.amountCo2Generated = computeRequiredPositiveNumberErrorMessage(
      values.amountCo2Generated
    );
    errors.amountCo2Total = computeRequiredPositiveNumberErrorMessage(
      values.amountCo2Total
    );
    errors.co2Method = computeRequiredStringErrorMessage(values.co2Method);
  }
  if (isCo2MethodAutre(values.co2Method)) {
    errors.co2MethodDescription = computeRequiredStringErrorMessage(
      values.co2MethodDescription
    );
  }
  if (isCh4MethodAutre(values.ch4Method)) {
    errors.ch4MethodDescription = computeRequiredStringErrorMessage(
      values.ch4MethodDescription
    );
  }
  return errors;
};
