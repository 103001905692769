import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { AuthDroitDto } from "api/gen/api";
import {
  DEFAULT_BORDER_RADIUS,
  GREY,
  PAGE_TITLE_FONT_SIZE,
  PINK,
  SHADOW,
} from "theme";
import { computeRightRows } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useUserData } from "Authenticator/UserData";
import { ReferenceRegionDto } from "api/gen";

const useStyles = makeStyles({
  container: {
    width: "320px",
    backgroundColor: "#FFFFFF",
    borderRadius: DEFAULT_BORDER_RADIUS,
    boxShadow: SHADOW,
    margin: "5px 0",
    zIndex: 11,
    position: "absolute",
    marginLeft: "-139px",
  },
  padded: {
    padding: "15px",
  },
  divTextHeader: {
    color: "#000000",
    textDecoration: "none",
    textAlign: "center",
    fontSize: PAGE_TITLE_FONT_SIZE,
    margin: "0 10px 20px 10px",
  },
  divTextFooter: {
    margin: "10px 0 0 0",
  },

  tableContent: {
    maxHeight: "20vh",
    overflowY: "auto",
  },

  lineContainer: {
    cursor: "pointer",
    "&:hover": {
      background: GREY,
    },
  },

  lineContainerActiveRight: {
    fontWeight: "bold",
  },

  logOutButton: {
    width: "100%",
    backgroundColor: PINK,
    borderRadius: "0 0 5px 5px",
    padding: "5px 0 10px 0",
    outline: "none !important",
  },
});

interface DummyProfileCardProps {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
  firstName: string;
  lastName: string;
  administratorRights: AuthDroitDto[];
  inspectorRights: AuthDroitDto[];
  declarantRights: AuthDroitDto[];
  verificatorRights: AuthDroitDto[];
  readerRights: AuthDroitDto[];
  referentialRegions: ReferenceRegionDto;
  activeRight?: AuthDroitDto;
  onClickRight?: (right: AuthDroitDto) => void;
}

export const DummyProfileCard = ({
  containerRef,
  firstName,
  lastName,
  administratorRights,
  inspectorRights,
  declarantRights,
  verificatorRights,
  readerRights,
  referentialRegions,
  activeRight,
  onClickRight,
}: DummyProfileCardProps): React.ReactElement => {
  const classes = useStyles();
  const userData = useUserData();

  const computeRightsSection = (rights: AuthDroitDto[], label: string) => {
    if (rights.length === 0) {
      return <></>;
    } else {
      return (
        <>
          <div>
            <span style={{ color: PINK, fontWeight: "bold" }}>
              {rights.length}
            </span>
            <span>
              {" "}
              {rights.length > 1 ? "droits" : "droit"} {label}
            </span>
          </div>
          {computeRightRows(
            rights,
            referentialRegions,
            activeRight,
            onClickRight ||
              (() => {
                return;
              }),
            classes.lineContainer,
            classes.lineContainerActiveRight
          )}
        </>
      );
    }
  };

  const [isButtonHovered, setIsButtonHovered] = useState(false);

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.padded}>
        {/* first name and name */}
        <p className={classes.divTextHeader}>
          {firstName} {lastName}
        </p>

        {/* rights display and selection */}
        <div className={classes.tableContent}>
          {computeRightsSection(administratorRights, "Administrateur")}
          {computeRightsSection(inspectorRights, "Gestionnaire")}
          {computeRightsSection(declarantRights, "Exploitant")}
          {computeRightsSection(verificatorRights, "Prestataire")}
          {computeRightsSection(readerRights, "Lecteur")}
        </div>
      </div>

      {/* log out button */}
      <div className={classes.divTextFooter}>
        <button
          onClick={() => {
            userData.logout();
          }}
          onMouseOver={() => {
            setIsButtonHovered(true);
          }}
          onMouseLeave={() => {
            setIsButtonHovered(false);
          }}
          className={classes.logOutButton}
          style={{
            backgroundColor: isButtonHovered ? "white" : PINK,
          }}
        >
          <>
            <FontAwesomeIcon
              icon={faSignOutAlt}
              color={isButtonHovered ? PINK : "white"}
            />
            <span style={{ color: isButtonHovered ? PINK : "white" }}>
              {" "}
              Quitter l'application
            </span>
          </>
        </button>
      </div>
    </div>
  );
};
