import React, { useContext, useLayoutEffect } from "react";
import { ImageProps, PathLink } from "common/utils/types";
import _ from "lodash";
import { AuthDroitDtoAuthProfileEnum } from "api/gen";
import { PATH_DASHBOARD } from "common/path/path18Now";
import { useUserData } from "Authenticator/UserData";

export interface PageHeaderProps {
  arianeString: PathLink[] | null;
  title: string;
  image?: ImageProps | null;
}
interface PageHeaderContextProps {
  headerInfo: PageHeaderProps | null;
  setter: (newData: PageHeaderProps | null) => void;
}

export const PageHeaderContext = React.createContext<PageHeaderContextProps | null>(
  null
);
export const usePageHeaderInfo = (): PageHeaderProps | null => {
  const context = useContext(PageHeaderContext);
  if (context === null) {
    throw Error("header can only be used inside App");
  } else {
    return context.headerInfo;
  }
};
export const usePageHeaderSetterOnce = (
  newInfo: PageHeaderProps | null
): void => {
  const context = useContext(PageHeaderContext);

  const setter = context ? context.setter : null;
  const currentInfo = context ? context.headerInfo : null;

  //  useLayoutEffect plutôt que useEffect pour synchroniser la mise à jour du header avec la mise à jour de l'enfant
  //  qui vient de set le contenu du header
  useLayoutEffect(() => {
    if (!currentInfo || !_.isEqual(currentInfo, newInfo)) {
      setter && setter(newInfo);
    }
  }, [setter, newInfo, currentInfo]);
};

export const usePathToDeclarationDashboard = (
  declarationAnnee: string | null,
  declarationEtablissement: string | null
): PathLink[] => {
  const userInfo = useUserData().userInfo;
  let paths: PathLink[] = [];

  if (declarationAnnee && declarationEtablissement) {
    paths = [
      {
        label: "Tableau de bord",
        to:
          "/declaration/" +
          declarationAnnee +
          "/" +
          declarationEtablissement +
          PATH_DASHBOARD,
      },
    ];
  }

  if (
    userInfo.droits.length > 0 &&
    userInfo.droits[0].authProfile === AuthDroitDtoAuthProfileEnum.LECTEUR
  ) {
    return [
      {
        label: "Déclarations",
        to: "/lecteur/declarations",
      },
      ...paths,
    ];
  }

  if (userInfo.droits.length > 0) {
    switch (userInfo.droits[0].authProfile) {
      case AuthDroitDtoAuthProfileEnum.SUPER_ADMIN:
      case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR_BQA:
      case AuthDroitDtoAuthProfileEnum.ADMINISTRATEUR:
      case AuthDroitDtoAuthProfileEnum.GESTIONNAIRE: {
        return [
          {
            label: "Accueil",
            to: "/accueil",
          },
          {
            label: "Déclarations",
            to: "/inspecteur/declarations",
          },
          ...paths,
        ];
      }
      case AuthDroitDtoAuthProfileEnum.PRESTATAIRE: {
        return [
          {
            label: "Déclarations",
            to: "/verificateur/declarations",
          },
          ...paths,
        ];
      }
    }
  }
  return paths;
};
