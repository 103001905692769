import { TechniqueGlobalValues } from "./utils";
import { Errors } from "common/form/utils";
import { requiredMessage } from "common/declarant/formik/formikMessages";

export const preventSubmitWithErrors = (
  values: TechniqueGlobalValues
): Errors<TechniqueGlobalValues> => {
  const errors: Errors<TechniqueGlobalValues> = {};

  if (values.estEPRTR) {
    if (values.principalEPRTR === null) {
      errors.principalEPRTR = requiredMessage;
    } else if (values.activitesEPRTR.length !== 0) {
      const currentPrincipalEPRTR = values.principalEPRTR;
      if (
        values.activitesEPRTR.find(
          act => act.uuid === currentPrincipalEPRTR.uuid
        )
      ) {
        errors.principalEPRTR = `Vous ne pouvez pas sélectionner ${currentPrincipalEPRTR.nomActivite} comme activité principale et secondaire.`;
      }
    }
  }

  return errors;
};
