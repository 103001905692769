import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/styles";
import { DASHBOARD_BORDER_GREY, SECTION_TITLE_GREY } from "theme";
import { useDeclaration24Now } from "../../../versionedElements/declarationHooks24Now";
import { AlcModeleDownloader } from "./AlcModeleDownloader";

const useStyles = makeStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  container: {
    border: `${DASHBOARD_BORDER_GREY} solid 1px`,
    padding: "20px",
    color: SECTION_TITLE_GREY,
    "&>p": {
      marginBottom: "20px",
    },
    marginBottom: "20px",
  },
  bold: {
    fontWeight: "bold",
  },
});

const BlocAlcDescription = (): ReactElement => {
  const classes = useStyles();
  const declaration = useDeclaration24Now();
  return (
    <div className={classes.container}>
      <p>
        <span className={classes.bold}>
          Rapport de déclaration des niveaux d’activité (ALC) :
        </span>
      </p>
      <p>
        Suite aux modifications de calendrier apportées par la directive
        2023/959, il n’y a plus lieu de déposer d’ALC préliminaire, car aucune
        allocation {declaration.annee + 1} ne sera versée au 28 février.
      </p>
      <p>
        Tout dépôt d’ALC doit se faire dans la section « RAPPORT DE DECLARATION
        DES NIVEAUX D’ACTIVITE (VERIFIE) », avec la validation par un
        vérificateur qui doit intervenir avant le 31/03.
      </p>

      {/*<p>*/}
      {/*  <span className={classes.bold}>*/}
      {/*    Le dépôt d'un rapport ALC dans la section RAPPORT DE DÉCLARATION DES*/}
      {/*    NIVEAUX D'ACTIVITÉ (VÉRIFIÉ) est obligatoire, ainsi que la validation*/}
      {/*    par un vérificateur*/}
      {/*  </span>{" "}*/}
      {/*  qui doit intervenir avant le 31/03.*/}
      {/*</p>*/}

      <p>
        ATTENTION : Il est impératif de{" "}
        <span className={classes.bold}>
          repartir de la dernière version validée du rapport ALC vérifié de
          l'année précédente.{" "}
        </span>
        Celui-ci est disponible sur le lien ci-dessous, sauf en cas de fichier
        ALC 2023 non validé sur GEREP. Dans ce cas, vous devez repartir du
        dernier fichier ALC vérifié transmis au service d'inspection.
      </p>

      <p>
        Le bouton "télécharger votre rapport ALC vérifié de l'année précédente"
        vous permet d'accéder à votre fichier ALC {declaration.annee}.
      </p>
      <AlcModeleDownloader />
    </div>
  );
};

export default BlocAlcDescription;
