import React from "react";
import { makeStyles } from "@material-ui/styles";
import { BACKGROUND_LIGHT_GREY, BACKGROUND_PINK } from "theme";
import { useComments } from "./CommentStore";
import { DummyCommentButton } from "common/comment/DummyCommentButton";
import classNames from "classnames";
import {
  computeCommentButtonState,
  isPathCommented,
} from "common/comment/commentUtils";
import { useUserData } from "../../../Authenticator/UserData";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      background: BACKGROUND_LIGHT_GREY,
      borderRadius: "50%",
    },
  },
  buttonFull: {
    "&:hover": {
      background: BACKGROUND_PINK,
      borderRadius: "50%",
    },
  },
});

interface CommentProps {
  path: string;
  isAlertOrError?: boolean;
  additionalClassName?: string;
}

const CommentButton = ({
  path,
  isAlertOrError,
  additionalClassName,
}: CommentProps): React.ReactElement => {
  const commentsContext = useComments();
  const classes = useStyles();
  const { isLecteur } = useUserData();
  const isAnotherComment = isPathCommented(
    commentsContext.comments,
    path,
    !!isAlertOrError
  );

  return (
    <DummyCommentButton
      onClick={e => {
        e.preventDefault();
        commentsContext.openModal(path, !!isAlertOrError);
      }}
      state={computeCommentButtonState(isLecteur, isAnotherComment)}
      additionalClassName={classNames(
        isAnotherComment ? classes.buttonFull : classes.button,
        additionalClassName
      )}
    />
  );
};

export default CommentButton;
