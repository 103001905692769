import { makeStyles } from "@material-ui/styles";
import React, { Dispatch, SetStateAction } from "react";
import { ERROR } from "theme";
import CommonEntityButton from "common/presentational/CommonEntityButton";
import { ClassesType } from "common/utils/types";
import CommonEntityTable from "common/presentational/CommonEntityTable";
import { CovInArray } from "./utils/types";
import { createProcedeDiv } from "../utils/utils";
import {
  computeWrappedCellWithError,
  useComputeFirstLineContentCell,
} from "common/utils/computeWrappedCell";
import { computeNumberWithSeparator } from "common/utils/numberUtils";
import _ from "lodash";

const useStyles = makeStyles({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  error: {
    color: ERROR,
  },
});

interface CovArrayProps {
  covsInPage: CovInArray[];
  setCovsInPage: Dispatch<SetStateAction<CovInArray[]>>;
  setCovInModale: Dispatch<SetStateAction<CovInArray | null>>;
  setCovModaleOpen: Dispatch<SetStateAction<boolean>>;
  isValidated: boolean;
  validationCovPath: string;
}

const CovArray = ({
  covsInPage,
  setCovsInPage,
  setCovInModale,
  setCovModaleOpen,
  isValidated,
  validationCovPath,
}: CovArrayProps) => {
  const classes = useStyles();
  const computeFirstLineContentCell = useComputeFirstLineContentCell();

  function suppressCov(singleCovInPage: CovInArray) {
    setCovsInPage(elderCovs => {
      return elderCovs.filter(elderCovInPage => {
        return singleCovInPage.data.id !== elderCovInPage.data.id;
      });
    });
  }

  const computeSingleCovLine = (
    cov: CovInArray,
    classes: ClassesType<"full" | "error">
  ): React.ReactElement[] => {
    const editAction = () => {
      setCovInModale(cov);
      setCovModaleOpen(true);
    };

    const firstCellContent = computeFirstLineContentCell(
      editAction,
      cov.data.substance !== null ? cov.data.substance.label : undefined,
      isValidated,
      !_.isEmpty(cov.errors)
    );
    return [
      computeWrappedCellWithError(firstCellContent, classes),
      computeWrappedCellWithError(
        cov.data.procede !== null ? createProcedeDiv(cov.data.procede) : "",
        classes
      ),
      computeWrappedCellWithError(
        cov.data.flux !== null ? computeNumberWithSeparator(cov.data.flux) : "",
        classes
      ),
      <CommonEntityButton
        handlerEdit={editAction}
        handlerSuppress={() => {
          suppressCov(cov);
        }}
        suppressMessage={"Êtes vous sûr de vouloir supprimer cette émission ?"}
        isValidated={isValidated}
        commentPath={`${validationCovPath}/${cov.data.id}`}
      />,
    ];
  };

  const arraySubstancesLines = covsInPage.map(singleCovInPage => {
    return computeSingleCovLine(singleCovInPage, classes);
  });

  return (
    <>
      <CommonEntityTable
        header={[
          <p>Mention de danger</p>,
          <p>Procédé(s)</p>,
          <p>Émissions (kg/an)</p>,
          <p>Actions</p>,
        ]}
        lines={arraySubstancesLines}
      />
    </>
  );
};

export default CovArray;
