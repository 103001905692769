import React, { ReactElement } from "react";
import { CampaignDtoStateAllocationsEnum } from "api/gen";
import { WorkflowTarget } from "common/utils/types";
import { DeclarationProps } from "pages/CompanySpace/DeclarationApiContext/utils/types";
import { PATH_QUOTAS_ALLOCATIONS } from "common/path/path20Now";
import { useCurrentDeclarationCampaign } from "pages/CommonSpace/CampaignContext";
import { wrapWithWorkflowTargetProvider } from "../../../../../WorkflowTargetProviderContext";
import Row from "../../../../../../../common/presentational/Row";
import InReviewCommentDisplayer from "../../../../../../../common/presentational/InReviewCommentDisplayer";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import QuotaAllocationsCard from "../Cards/QuotaAllocationsCard";
import {
  useDeclaration21Now,
  useDeclarationHelpers21Now,
  useQuotaAllocationsState21Now,
} from "pages/CompanySpace/from21/toNow/versionedElements/declarationHooks21Now";
import DeclarationStatus from "pages/CompanySpace/from21/toNow/Dashboard/Components/DeclarationStatus";
import QuotasActionButtons from "pages/CompanySpace/from21/to23/Actions/Quotas/QuotasActionButtons";
import AccuseTransmissionDownloadButton from "pages/CompanySpace/from21/toNow/Actions/AccuseTransmissionDownloadButton";
import DashboardCardsContainer from "pages/CompanySpace/from21/toNow/Dashboard/Components/Container/DashboardCardsContainer";
import QuotasNextActionCard from "pages/CompanySpace/from21/to23/Dashboard/Components/NextAction/QuotasNextActionCard";

const QuotasAllocationsSubDashboard = ({
  declarationAnnee,
  declarationEtablissement,
}: DeclarationProps): ReactElement => {
  const quotasAllocationsState = useQuotaAllocationsState21Now();
  const currentCampaign = useCurrentDeclarationCampaign();
  const isQuotasAllocationsCampaignActive =
    currentCampaign.stateAllocations !== CampaignDtoStateAllocationsEnum.ENDED;

  const declaration = useDeclaration21Now();
  const declarationApi = useDeclarationHelpers21Now();
  const { declarationController } = useAuthenticatedApi();
  const allocationStateComment =
    declaration.body.workflowStatus.quotaAllocations.message;
  const allocationState = useQuotaAllocationsState21Now();
  const isInReviewState = allocationState.isDeclarationStateContaining([
    "IN_REVIEW",
  ]);
  const anonymousExportAccuseTransmission = (
    annee: number,
    etablissement: string
  ) =>
    declarationController.exportAccuseTransmissionNiveauxActiviteAsPdfUsingGET(
      annee,
      etablissement
    );
  return (
    <>
      <InReviewCommentDisplayer
        workflowTarget={WorkflowTarget.GLOBAL}
        isInReviewState={isInReviewState}
        comment={allocationStateComment}
      />
      {isInReviewState && <Row height={"19px"} />}
      <DeclarationStatus
        statusDescription={"Statut de la déclaration des niveaux d'activité :"}
        declarationStatus={quotasAllocationsState.stateMessage}
      />

      <QuotasActionButtons
        quotaState={quotasAllocationsState}
        workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        path={PATH_QUOTAS_ALLOCATIONS}
        isQuotaCampaignActive={isQuotasAllocationsCampaignActive}
      />

      <AccuseTransmissionDownloadButton
        declarationAnnee={declarationAnnee}
        declarationEtablissement={declarationEtablissement}
        isDeclarationDone={
          declarationApi.getPathProgress(PATH_QUOTAS_ALLOCATIONS) === 100
        }
        suffixFileName="niveaux_activite_"
        exportRequest={anonymousExportAccuseTransmission}
      />

      <DashboardCardsContainer>
        <QuotaAllocationsCard
          declarationAnnee={declarationAnnee}
          declarationEtablissement={declarationEtablissement}
        />
        <QuotasNextActionCard
          quotaState={quotasAllocationsState}
          workflowTarget={WorkflowTarget.QUOTA_ALLOCATIONS}
        />
      </DashboardCardsContainer>
    </>
  );
};

export default wrapWithWorkflowTargetProvider(
  QuotasAllocationsSubDashboard,
  "QUOTA_ALLOCATIONS"
);
