import React, { useCallback } from "react";
import { BlocReferentiel } from "./BlocReferentiel";
import { useAuthenticatedApi } from "Authenticator/AuthenticatedApi";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import Row from "common/presentational/Row";
import { makeStyles } from "@material-ui/styles";
import { TEXT_LIGHT_GREY } from "theme";

const useStyles = makeStyles({
  disabledText: {
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocGeographieProps {
  disabled: boolean;
}

export const BlocGeographie = ({ disabled }: BlocGeographieProps) => {
  const {
    referenceInfoGeneralController,
    referencePaysController,
  } = useAuthenticatedApi();
  const classes = useStyles();

  // Codes INSEE
  const uploadGeoCodeInseeHandler = useCallback(
    (file: File) => {
      return referenceInfoGeneralController.uploadGeoCodeInseeUsingPOST(file);
    },
    [referenceInfoGeneralController]
  );

  const downloadGeoCodeInseeHandler = useCallback(() => {
    return referenceInfoGeneralController.downloadGeoCodeInseeAsCsvUsingGET();
  }, [referenceInfoGeneralController]);

  // Départements
  const uploadDepartementHandler = useCallback(
    (file: File) => {
      return referenceInfoGeneralController.uploadDepartementsUsingPOST(file);
    },
    [referenceInfoGeneralController]
  );

  const downloadDepartementHandler = useCallback(() => {
    return referenceInfoGeneralController.downloadDepartementsAsCsvUsingGET();
  }, [referenceInfoGeneralController]);

  // Régions
  const uploadRegionHandler = useCallback(
    (file: File) => {
      return referenceInfoGeneralController.uploadRegionsUsingPOST(file);
    },
    [referenceInfoGeneralController]
  );

  const downloadRegionHandler = useCallback(() => {
    return referenceInfoGeneralController.downloadRegionsAsCsvUsingGET();
  }, [referenceInfoGeneralController]);

  // Pays
  const uploadPaysHandler = useCallback(
    (file: File) => {
      return referencePaysController.uploadPaysUsingPOST(file);
    },
    [referencePaysController]
  );

  const downloadPaysHandler = useCallback(() => {
    return referencePaysController.downloadPaysAsCsvUsingGET();
  }, [referencePaysController]);

  return (
    <DummyUnActionedBloc
      title="Géographie"
      renderContent={() => {
        return (
          <>
            {disabled && (
              <>
                <div className={classes.disabledText}>
                  Aucune campagne future à l'état prête n'a été trouvée. Vous ne
                  pouvez pas modifier les référentiels d'une campagne en cours.
                </div>
                <Row />
              </>
            )}
            <BlocReferentiel
              title="Codes INSEE"
              downloadHandler={downloadGeoCodeInseeHandler}
              filename="codes_insee.csv"
              uploadHandler={uploadGeoCodeInseeHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Départements"
              downloadHandler={downloadDepartementHandler}
              filename="departements.csv"
              uploadHandler={uploadDepartementHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Régions"
              downloadHandler={downloadRegionHandler}
              filename="regions.csv"
              uploadHandler={uploadRegionHandler}
              disabled={disabled}
            />
            <BlocReferentiel
              title="Pays"
              downloadHandler={downloadPaysHandler}
              filename="pays.csv"
              uploadHandler={uploadPaysHandler}
              disabled={disabled}
            />
          </>
        );
      }}
    />
  );
};

export default BlocGeographie;
