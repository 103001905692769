import React, { Dispatch, SetStateAction } from "react";
import CustomModal from "common/presentational/CustomModal";
import FormSingleInstallation from "./FormSingleInstallation";
import { InstallationInArray, InstallationInModale } from "./utils/types";
import { addOrModifyArray } from "common/declarant/array/utils";

interface InstallationsModaleProps {
  isOpen: boolean;
  installationInModal: InstallationInArray | null;
  setInstallationsInPage: Dispatch<SetStateAction<InstallationInArray[]>>;
  setInstallationInModale: Dispatch<SetStateAction<InstallationInArray | null>>;
  setModaleOpen: Dispatch<SetStateAction<boolean>>;
  installationsInPage: InstallationInArray[];
}

const InstallationsModale = ({
  isOpen,
  installationInModal,
  setInstallationsInPage,
  setInstallationInModale,
  setModaleOpen,
  installationsInPage,
}: InstallationsModaleProps): React.ReactElement => {
  const onClose = () => {
    setInstallationInModale(null);
    setModaleOpen(false);
  };
  return (
    <CustomModal
      isOpen={isOpen}
      contentLabel="Modale avec le formulaire d'ajout de procédés."
      onRequestClose={onClose}
    >
      <FormSingleInstallation
        installationsInPage={installationsInPage}
        installationInModal={installationInModal}
        closeFunction={onClose}
        onSubmit={(values, formikBag) => {
          const newInstallation: InstallationInModale = {
            nom: values.nom,
            heures: values.heures,
            volume: values.volume,
            unite: values.unite,
            produit: values.produit,
          };
          addOrModifyArray(
            setInstallationsInPage,
            installationInModal && installationInModal.data.id,
            newInstallation
          );
          onClose();
        }}
        initialInstallation={
          installationInModal !== null
            ? installationInModal.data
            : {
                nom: null,
                heures: null,
                volume: null,
                unite: null,
                produit: null,
              }
        }
        isEdit={installationInModal !== null}
      />
    </CustomModal>
  );
};

export default InstallationsModale;
