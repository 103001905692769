import React, { useMemo } from "react";
import { computeErrorObject } from "./utils";
import DummyDateInput from "../dumbInput/DummyDateInput";
import { DateInputFieldProps } from "../types/connectedTypes";
import { useExternalFieldNameEffect } from "../../../../libAdapter/Formik/hooks";

const DateInput = ({
  field, // { name, value, onChange, onBlur }
  form,
  additionalOnChange,
  externalValue,
  disabled,
  ...props
}: DateInputFieldProps): React.ReactElement => {
  const error = computeErrorObject({ form, field });
  const fieldValue = field.value === null ? "" : field.value;

  const externalValueAsDate: Date | null | undefined = useMemo(
    () =>
      externalValue !== undefined && externalValue !== null
        ? new Date(externalValue)
        : externalValue,
    [externalValue]
  );
  useExternalFieldNameEffect(field.name, externalValueAsDate);

  return (
    <DummyDateInput
      {...field}
      {...props}
      value={
        externalValueAsDate !== undefined ? externalValueAsDate : fieldValue
      }
      disabled={externalValue !== undefined ? true : disabled}
      error={error}
      onChange={newValue => {
        form.setFieldValue(field.name, newValue);
        form.setFieldTouched(field.name);
        if (additionalOnChange !== undefined) {
          additionalOnChange(newValue);
        }
      }}
    />
  );
};

export default DateInput;
