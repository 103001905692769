import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { LARGE_BUTTON_WIDTH, TEXT_LIGHT_GREY } from "theme";
import Row from "common/presentational/Row";
import Button from "common/button";
import EmissionMesureArray from "./EmissionMesureArray";
import EmissionMatiereArray from "./EmissionMatiereArray";
import EmissionFacteurArray from "./EmissionFacteurArray";
import EmissionModale from "./EmissionModale";
import { DummyUnActionedBloc } from "common/bloc/DummyUnActionedBloc";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import { useConfirmationModale } from "common/modale/hooks";
import {
  EmissionsInArray,
  FacteurEmissionInArray,
  MatiereEmissionInArray,
  MesureEmissionInArray,
} from "./utils/types";
import { AppareilInArray } from "../blocAppareils/utils/types";
import { CombustibleInArray } from "../blocCombustibles/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import _ from "lodash";

const useStyles = makeStyles({
  largeButton: {
    width: LARGE_BUTTON_WIDTH,
  },
  titre: {
    fontSize: "19px",
    color: TEXT_LIGHT_GREY,
  },
});

interface BlocEmissionsProps {
  installationName: string | null;
  facteursInPage: FacteurEmissionInArray[];
  setFacteursInPage: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  mesuresInPage: MesureEmissionInArray[];
  setMesuresInPage: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  matieresInPage: MatiereEmissionInArray[];
  setMatieresInPage: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  shouldDisabledFields: boolean;
  validationContentPath: string;
  substancesAir: OptionPropsWithObject<ReferenceItemPolluantDto, string>[];
  appareilsInPage: AppareilInArray[];
  combustiblesInPage: CombustibleInArray[];
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const BlocEmissions = ({
  facteursInPage,
  setFacteursInPage,
  mesuresInPage,
  setMesuresInPage,
  matieresInPage,
  setMatieresInPage,
  installationName,
  shouldDisabledFields,
  validationContentPath,
  substancesAir,
  appareilsInPage,
  combustiblesInPage,
  referentialSinglePolluants,
}: BlocEmissionsProps): React.ReactElement => {
  const classes = useStyles();

  const [emissionModaleOpen, setEmissionModaleOpen] = useState(false);

  const openConfirmationModale = useConfirmationModale();

  const [
    emissionsInModale,
    setEmissionsInModale,
  ] = useState<EmissionsInArray | null>(null);

  const compareFunction = (
    a: EmissionsInArray,
    b: EmissionsInArray
  ): number => {
    if (
      a.data.substance &&
      b.data.substance &&
      a.data.substance.object.nom !== b.data.substance.object.nom
    ) {
      return a.data.substance.object.nom < b.data.substance.object.nom ? -1 : 1;
    }

    if (
      a.data.appareils &&
      b.data.appareils &&
      a.data.appareils !== b.data.appareils
    ) {
      return a.data.appareils < b.data.appareils ? -1 : 1;
    }
    return 0;
  };

  return (
    <>
      <DummyUnActionedBloc
        title={`Déclaration des émissions de l'installation: ${installationName}`}
        renderContent={() => {
          return (
            <>
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="AJOUTER UNE ÉMISSION"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    setEmissionModaleOpen(true);
                  }}
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <span className={classes.titre}>
                Émissions calculées par facteur d'émission
              </span>
              <EmissionFacteurArray
                facteurEmissionsInArray={_.cloneDeep(facteursInPage).sort(
                  compareFunction
                )}
                setFacteurEmissionsInArray={setFacteursInPage}
                setEmissionInModale={setEmissionsInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                installationName={installationName}
                validationFacteursPath={`${validationContentPath}/emissions/facteurs`}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () =>
                        setFacteursInPage(elders => {
                          return elders.filter(
                            elder =>
                              elder.data.nameInstallation !== installationName
                          );
                        })
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <span className={classes.titre}>
                Émissions calculées par mesure
              </span>
              <EmissionMesureArray
                mesureEmissionsInArray={_.cloneDeep(mesuresInPage).sort(
                  compareFunction
                )}
                setMesureEmissionsInArray={setMesuresInPage}
                setEmissionInModale={setEmissionsInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                installationName={installationName}
                validationMesuresPath={`${validationContentPath}/emissions/mesures`}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () =>
                        setMesuresInPage(elders => {
                          return elders.filter(
                            elder =>
                              elder.data.nameInstallation !== installationName
                          );
                        })
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
              <span className={classes.titre}>
                Émissions calculées par bilan matière
              </span>
              <EmissionMatiereArray
                matiereEmissionsInArray={_.cloneDeep(matieresInPage).sort(
                  compareFunction
                )}
                setMatiereEmissionsInArray={setMatieresInPage}
                setEmissionInModale={setEmissionsInModale}
                setEmissionModaleOpen={setEmissionModaleOpen}
                isValidated={shouldDisabledFields}
                installationName={installationName}
                validationMatieresPath={`${validationContentPath}/emissions/bilans`}
              />
              <Row height={"15px"} />
              <Row additionalStyle={{ justifyContent: "flex-end" }}>
                <Button
                  text="TOUT SUPPRIMER"
                  additionalClassname={classes.largeButton}
                  onClick={() => {
                    openConfirmationModale(
                      "Êtes vous sûr de vouloir supprimer le contenu de ce tableau ?",
                      () =>
                        setMatieresInPage(elders => {
                          return elders.filter(
                            elder =>
                              elder.data.nameInstallation !== installationName
                          );
                        })
                    );
                  }}
                  isReversed
                  isDisabled={shouldDisabledFields}
                />
              </Row>
              <Row />
            </>
          );
        }}
      />
      <EmissionModale
        emissionModaleOpen={emissionModaleOpen}
        emissionInModale={emissionsInModale}
        setFacteurEmissionsInArray={setFacteursInPage}
        setMesureEmissionsInArray={setMesuresInPage}
        setMatiereEmissionsInArray={setMatieresInPage}
        setEmissionInModale={setEmissionsInModale}
        setEmissionModaleOpen={setEmissionModaleOpen}
        facteurEmissionsInArray={facteursInPage}
        matiereEmissionsInArray={matieresInPage}
        mesureEmissionsInArray={mesuresInPage}
        installationName={installationName}
        substancesAir={substancesAir}
        combustiblesInPage={combustiblesInPage}
        appareilsInPage={appareilsInPage}
        referentialSinglePolluants={referentialSinglePolluants}
      />
    </>
  );
};

export default BlocEmissions;
