import React from "react";
import FormEmission from "./FormEmission";
import {
  addOrModifyArray,
  moveAndDeleteEmission,
} from "common/declarant/array/utils";
import CustomModal from "common/presentational/CustomModal";
import { OptionPropsWithObject } from "common/form/fields/types/basicTypes";
import { ReferenceItemPolluantDto } from "api/gen";
import {
  EmissionsInArray,
  EmissionsInModale,
  FacteurEmissionInArray,
  FacteurEmissionInModale,
  MatiereEmissionInArray,
  MatiereEmissionInModale,
  MesureEmissionInArray,
  MesureEmissionInModale,
} from "./utils/types";
import { AppareilInArray } from "../blocAppareils/utils/types";
import { CombustibleInArray } from "../blocCombustibles/utils/types";
import { ReferentialSinglePolluants } from "../../utils/types";
import { MethodCombustion } from "./utils/selectPossibleValues";
import { ShouldNotHappen } from "common/utils/ShouldNotHappen";
import { defaultEmissionInModaleInitialValue } from "./utils/utils";

interface EmissionProps {
  emissionModaleOpen: boolean;
  emissionInModale: EmissionsInArray | null;
  setFacteurEmissionsInArray: React.Dispatch<
    React.SetStateAction<FacteurEmissionInArray[]>
  >;
  setMesureEmissionsInArray: React.Dispatch<
    React.SetStateAction<MesureEmissionInArray[]>
  >;
  setMatiereEmissionsInArray: React.Dispatch<
    React.SetStateAction<MatiereEmissionInArray[]>
  >;
  setEmissionInModale: React.Dispatch<
    React.SetStateAction<EmissionsInArray | null>
  >;
  setEmissionModaleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  facteurEmissionsInArray: FacteurEmissionInArray[];
  matiereEmissionsInArray: MatiereEmissionInArray[];
  mesureEmissionsInArray: MesureEmissionInArray[];
  installationName: string | null;
  substancesAir: OptionPropsWithObject<ReferenceItemPolluantDto, string>[];
  appareilsInPage: AppareilInArray[];
  combustiblesInPage: CombustibleInArray[];
  referentialSinglePolluants: ReferentialSinglePolluants;
}

const EmissionModale = ({
  emissionModaleOpen,
  emissionInModale,
  setFacteurEmissionsInArray,
  setMesureEmissionsInArray,
  setMatiereEmissionsInArray,
  setEmissionInModale,
  setEmissionModaleOpen,
  facteurEmissionsInArray,
  matiereEmissionsInArray,
  mesureEmissionsInArray,
  installationName,
  substancesAir,
  combustiblesInPage,
  appareilsInPage,
  referentialSinglePolluants,
}: EmissionProps) => {
  const onClose = () => {
    setEmissionInModale(null);
    setEmissionModaleOpen(false);
  };

  const defaultInitialValue: EmissionsInModale = defaultEmissionInModaleInitialValue;

  return (
    <CustomModal
      isOpen={emissionModaleOpen}
      contentLabel="Modale avec le formulaire d'ajout de emission de mention de danger."
      onRequestClose={onClose}
    >
      <FormEmission
        referentialSinglePolluants={referentialSinglePolluants}
        combustiblesInPage={combustiblesInPage}
        appareilsInPage={appareilsInPage}
        substancesAir={substancesAir}
        facteurEmissionsInArray={facteurEmissionsInArray}
        matiereEmissionsInArray={matiereEmissionsInArray}
        mesureEmissionsInArray={mesureEmissionsInArray}
        installationName={installationName}
        closeFunction={onClose}
        onSubmit={values => {
          if (values.methods === null) {
            throw Error("Should not happen, check validation");
          }

          if (
            values.methods.object.value === MethodCombustion.FACTEUR_EMISSION
          ) {
            const newEmission: FacteurEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              combustible: values.combustible,
              referenceCombustible: values.referenceCombustible,
              consoAnnuelle: values.consoAnnuelle,
              unite: values.unite,
              facteur: values.facteur,
              ecart: values.ecart,
              provenance: values.provenance,
              facteurOxydation: values.facteurOxydation,
              provenanceFacteurOxydation: values.provenanceFacteurOxydation,
            };
            if (
              !emissionInModale ||
              (emissionInModale &&
                emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value)
            ) {
              addOrModifyArray(
                setFacteurEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                //If Typescript undestand that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodCombustion.FACTEUR_EMISSION:
                  throw new Error("Should not happen, check conditions above");
                case MethodCombustion.MESURE:
                  moveAndDeleteEmission(
                    setFacteurEmissionsInArray,
                    setMesureEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setFacteurEmissionsInArray,
                    setMatiereEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else if (values.methods.object.value === MethodCombustion.MESURE) {
            const newEmission: MesureEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              heures: values.heures,
              debit: values.debit,
              continuDebit: values.continuDebit,
              frequenceDebit: values.frequenceDebit,
              concentrationDebit: values.concentrationDebit,
              continuConcentration: values.continuConcentration,
              frequenceConcentration: values.frequenceConcentration,
              biomasseMesure: values.biomasseMesure,
            };
            if (
              !emissionInModale ||
              (emissionInModale &&
                emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value)
            ) {
              addOrModifyArray(
                setMesureEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                //If Typescript undestand that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodCombustion.FACTEUR_EMISSION:
                  moveAndDeleteEmission(
                    setMesureEmissionsInArray,
                    setFacteurEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.MESURE:
                  throw new Error("Should not happen, check conditions above");
                case MethodCombustion.BILAN_MATIERE:
                  moveAndDeleteEmission(
                    setMesureEmissionsInArray,
                    setMatiereEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else if (
            values.methods.object.value === MethodCombustion.BILAN_MATIERE
          ) {
            const newEmission: MatiereEmissionInModale = {
              nameInstallation: values.nameInstallation,
              substance: values.substance,
              methods: values.methods,
              epuration: values.epuration,
              nature: values.nature,
              rendement: values.rendement,
              emissionsAnnuelles: values.emissionsAnnuelles,
              appareils: values.appareils,
              intrants: values.intrants,
              entrant: values.entrant,
              sortant: values.sortant,
              element: values.element,
              teneur: values.teneur,
              part: values.part,
              biomasseMatiere: values.biomasseMatiere,
            };
            if (
              !emissionInModale ||
              (emissionInModale &&
                emissionInModale.data.methods &&
                values.methods.object.value ===
                  emissionInModale.data.methods.object.value)
            ) {
              addOrModifyArray(
                setMatiereEmissionsInArray,
                emissionInModale && emissionInModale.data.id,
                newEmission
              );
            } else {
              if (emissionInModale.data.methods === null) {
                //If Typescript undestand that one day, we can delete it
                throw Error("Should not happen, OK typescript.");
              }

              switch (emissionInModale.data.methods.object.value) {
                case MethodCombustion.FACTEUR_EMISSION:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInArray,
                    setFacteurEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.MESURE:
                  moveAndDeleteEmission(
                    setMatiereEmissionsInArray,
                    setMesureEmissionsInArray,
                    emissionInModale.data.id,
                    newEmission
                  );
                  break;
                case MethodCombustion.BILAN_MATIERE:
                  throw new Error("Should not happen, check conditions above");
                default:
                  throw new ShouldNotHappen(
                    emissionInModale.data.methods.object.value
                  );
              }
            }
          } else {
            throw new ShouldNotHappen(values.methods.object.value);
          }

          onClose();
        }}
        initialEmission={
          emissionInModale !== null
            ? { ...defaultInitialValue, ...emissionInModale.data }
            : { ...defaultInitialValue, facteurOxydation: 100 }
        }
      />
    </CustomModal>
  );
};

export default EmissionModale;
