import React from "react";
import InfoBulle from "common/infoBulle/InfoBulle";
import SpaceChild from "common/presentational/SpaceChild";
import { QuotaWorkflowTarget, WorkflowTarget } from "common/utils/types";
import { DeclarationStateDtoStateEnum } from "api/gen";
import { useDeclaration21Now } from "../../versionedElements/declarationHooks21Now";

interface InfobulleGlobalActionsProps {
  target: QuotaWorkflowTarget;
}

const InfobulleGlobalActions = ({
  target,
}: InfobulleGlobalActionsProps): React.ReactElement => {
  const declaration = useDeclaration21Now();
  const isArticle27 =
    declaration.body.typeActivite.quotas.excluArticle27 ||
    declaration.body.typeActivite.quotas.excluArticle27Bis;
  const quotasState =
    target === WorkflowTarget.QUOTA_EMISSIONS
      ? declaration.body.workflowStatus.quotaEmissions.state
      : declaration.body.workflowStatus.quotaAllocations.state;
  const paveLibelle =
    target === WorkflowTarget.QUOTA_EMISSIONS
      ? "émissions"
      : "niveaux d'activité";

  return (
    <InfoBulle
      content={generateInfoBulleText(quotasState, paveLibelle, isArticle27)}
    />
  );
};

export default InfobulleGlobalActions;

const generateInfoBulleText = (
  quotaState: DeclarationStateDtoStateEnum,
  paveLibelle: string,
  isArticle27: boolean | null
) => {
  const isToSendToVerification =
    !isArticle27 &&
    (quotaState === DeclarationStateDtoStateEnum.STARTED ||
      quotaState === DeclarationStateDtoStateEnum.IN_REVIEW);
  const isSentToVerification =
    quotaState === DeclarationStateDtoStateEnum.VERIFYING;
  const isValidatedByVerification =
    quotaState === DeclarationStateDtoStateEnum.VERIFIED;
  const isSentToInspection =
    quotaState === DeclarationStateDtoStateEnum.SUBMITTED ||
    quotaState === DeclarationStateDtoStateEnum.VALIDATED;

  if (isToSendToVerification) {
    return (
      <SpaceChild>
        <p>
          En cliquant sur ce bouton, vous passerez la main au prestataire
          vérificateur {paveLibelle} qui complètera l'espace dédié à la
          vérification (il ne pourra pour autant pas modifier les informations
          que vous aurez renseignées dans votre espace).
        </p>
        <p>
          Tant que le prestataire vérificateur n'aura pas validé la section
          vérification, il vous sera toujours possible de modifier vos données
          en cliquant sur "Modifier" puis en transmettant à nouveau la page au
          prestataire vérificateur quotas.
        </p>
        <p>
          Une fois que le prestataire vérificateur quotas aura validé le pavé de
          vérification, vous pourrez transmettre la déclaration quotas à
          l'inspection pour validation.
        </p>
        <p>
          Si vous ne souhaitez pas la transmettre en l'état, il vous sera alors
          toujours possible de la modifier avant, mais toute modification
          réinitialisera le processus et nécessitera donc une nouvelle
          vérification par le prestataire vérificateur {paveLibelle} avant de
          pouvoir être transmise à l'inspection.
        </p>
        <p>
          Après transmission à l'inspection, la déclaration ne sera plus
          modifiable, sauf si vous en faites expressément la demande à
          l'inspection en cliquant sur le bouton "Demander la modification".
          Dans un tel cas, cette dernière sera en mesure d'accepter la
          modification, ce qui mettra votre déclaration quotas en révision.
        </p>
        <p>
          En l'absence de demande de modification, l'inspection pourra alors
          soit valider la déclaration quotas, soit la mettre en révision. Dans
          ce dernier cas, un nouveau cycle de déclaration par le déclarant /
          vérification par le prestataire vérificateur quotas / validation par
          l'inspection sera initié.
        </p>
      </SpaceChild>
    );
  } else if (isSentToVerification) {
    return (
      <SpaceChild>
        <p>
          Le prestataire vérificateur quotas n'a pas validé la section de
          vérification, il vous est donc toujours possible de modifier vos
          données en cliquant sur le bouton "Modifier" d'une section puis en
          transmettant à nouveau la page au prestataire vérificateur quotas.
        </p>
        <p>
          Remarque : Dans un tel cas, le vérificateur gardera toutes les
          informations qu'il avait sauvegardées dans sa section, et les
          retrouvera donc lorsque la déclaration lui sera à nouveau transmise.
        </p>
      </SpaceChild>
    );
  } else if (isValidatedByVerification) {
    return (
      <SpaceChild>
        <p>
          Vous pouvez désormais transmettre la déclaration quotas {paveLibelle}{" "}
          à l'inspection pour validation.
        </p>
        <p>
          Si vous ne souhaitez pas la transmettre en l'état, il est toujours
          possible de la modifier avant, mais toute modification réinitialisera
          le processus et nécessitera donc une nouvelle vérification par le
          prestataire vérificateur quotas {paveLibelle} avant de pouvoir être
          transmise à l'inspection.
        </p>
        <p>
          Après transmission à l'inspection, la déclaration quotas ne sera plus
          modifiable, sauf si vous en faites expressément la demande à
          l'inspection en cliquant sur le bouton "Demander la modification".
          Dans un tel cas, cette dernière sera en mesure d'accepter la
          modification, ce qui mettra votre déclaration quotas en révision.
        </p>
        <p>
          En l'absence de demande de modification, l'inspection pourra alors
          soit valider la déclaration quotas, soit la mettre en révision. Dans
          ce dernier cas, un nouveau cycle de déclaration par le déclarant /
          vérification par le prestataire vérificateur quotas / validation par
          l'inspection sera initié.
        </p>
      </SpaceChild>
    );
  } else if (isSentToInspection) {
    return (
      <SpaceChild>
        <p>
          La déclaration quotas {paveLibelle} a été transmise à l'inspection et
          n'est plus modifiable, sauf si vous en faites expressément la demande
          à l'inspection en cliquant sur le bouton "Demander la modification".
          Dans un tel cas, cette dernière sera en mesure d'accepter la
          modification, ce qui mettra votre déclaration quotas en révision.
        </p>
        <p>
          En l'absence de demande de modification, l'inspection pourra alors
          soit valider la déclaration quotas, soit la mettre en révision. Dans
          ce dernier cas, un nouveau cycle de déclaration par le déclarant /
          vérification par le prestataire vérificateur quotas / validation par
          l'inspection sera initié.
        </p>
      </SpaceChild>
    );
  }
};
